import React, { useMemo } from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { HeatmapColorScheme } from "./heatmapTypes";

const LegendContainer = styled.div`
  display: flex;
`;

const LegendBlock = styled.div<{ color: string }>`
  width: 36px;
  height: 8px;
  background-color: ${(props) => props.color};
`;

const LineContainer = styled.div`
  padding-bottom: 12px;
`;

const Line = styled.div`
  width: 1px;
  height: 12px;
  margin-left: -1px;
  background-color: ${muiColors.gray[700]};
  display: flex;
  justify-content: center;
`;

const LineText = styled(Typography)`
  && {
    position: relative;
    margin-top: 12px;
  }
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 6px;
  }
`;

type ColorSchemeLegendProps = {
  colorScheme: HeatmapColorScheme[];
  title?: string;
  className?: string;
  tooltipTitle?: string;
};

export const ColorSchemeLegend: React.FC<ColorSchemeLegendProps> = ({
  colorScheme,
  title,
  className,
  tooltipTitle,
}) => {
  const legendBlocks = useMemo(
    () =>
      colorScheme.map((color, index) => {
        return (
          <React.Fragment key={color.color ?? index}>
            <LegendBlock key={index} color={color.color ?? ""} />
            <LineContainer>
              <Line>
                <LineText variant={"body3"}>{color.maxValue}</LineText>
              </Line>
            </LineContainer>
          </React.Fragment>
        );
      }),
    [colorScheme]
  );

  return (
    <div className={className}>
      {title && <Title variant={"body3"}>{title}</Title>}
      <Tooltip title={tooltipTitle}>
        <LegendContainer>{legendBlocks}</LegendContainer>
      </Tooltip>
    </div>
  );
};
