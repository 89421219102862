import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { FixedWidth, SeverityChip } from "../styles";
import { Severity } from "../../reliability/ReliabilityTypes";
import {
  ReasonComponent,
  ViolationStatus,
} from "../../../generated/workspacesApi";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { getMinDigitsWidth } from "@/components/ClustersView/utils";

const {
  Container: ContainerLabel,
  High,
  Medium,
  Low,
} = AriaLabels.ClustersView.ViolationsCell;

const ITEM_MIN_DIGITS_WIDTH = getMinDigitsWidth(3);

const Container = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const GroupContainer = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const ViolationsGroup: React.FC<{
  violations: ReasonComponent;
  severity: Severity;
  ariaLabel?: string;
}> = ({ violations, severity, ariaLabel }) => {
  return (
    <GroupContainer aria-label={ariaLabel}>
      <SeverityChip label={severity[0]} severity={severity} size="small" />
      <FixedWidth minWidth={ITEM_MIN_DIGITS_WIDTH}>
        <Typography variant="body2">
          {violations.count.toLocaleString()}
        </Typography>
      </FixedWidth>
    </GroupContainer>
  );
};

export const ViolationsCell: React.FC<{
  violations: ViolationStatus;
  className?: string;
}> = ({ violations, className }) => {
  return (
    <Container aria-label={ContainerLabel} className={className}>
      <ViolationsGroup
        violations={violations.high}
        severity="high"
        ariaLabel={High}
      />
      <ViolationsGroup
        violations={violations.medium}
        severity="medium"
        ariaLabel={Medium}
      />
      <ViolationsGroup
        violations={violations.low}
        severity="low"
        ariaLabel={Low}
      />
    </Container>
  );
};
