import { useCallback, useMemo } from "react";

import { useReliabilityDataInUrl } from "../../../../hooks/useReliabilityDataInUrl";
import { useGetIgnoreRulesPerChecks } from "../../../../../../shared/hooks/reliability-api/violations/useChecksIgnoreRules";
import {
  CheckType,
  IgnoreRule,
} from "../../../../../../generated/reliabilityApi";

export const useIsIgnoreChecksDrawerOpen = () => {
  const [dataInUrl] = useReliabilityDataInUrl();
  return !!dataInUrl.checkType;
};

export const useGetCheckTypeInURL = (): CheckType | undefined => {
  const [dataInUrl] = useReliabilityDataInUrl();
  return dataInUrl.checkType as CheckType | undefined;
};

export const useOpenDrawer = () => {
  const [dataInUrl, setDataInUrl] = useReliabilityDataInUrl();

  return useCallback(
    (checkType: string) => {
      setDataInUrl({
        ...dataInUrl,
        checkType,
      });
    },
    [dataInUrl, setDataInUrl]
  );
};

export const useCloseDrawer = () => {
  const [dataInUrl, setDataInUrl] = useReliabilityDataInUrl();

  return useCallback(() => {
    setDataInUrl({
      ...dataInUrl,
      checkType: undefined,
    });
  }, [dataInUrl, setDataInUrl]);
};

export const useGetIgnoreRuleForCheckType = (
  checkType: CheckType | undefined
): {
  foundRules: IgnoreRule[];
  isFetching: boolean;
  error: unknown;
} => {
  const requestParams = checkType ? { checkType: [checkType] } : {};
  const {
    data: ignoreRulesData,
    isFetching,
    error,
  } = useGetIgnoreRulesPerChecks(
    requestParams,
    checkType && checkType.length > 0
  );

  return useMemo(() => {
    if (isFetching || !!error || !ignoreRulesData?.data?.ignoreRules)
      return {
        foundRules: [],
        isFetching,
        error,
      };
    const foundRules = ignoreRulesData.data.ignoreRules.filter(
      (rule) => rule.checkType === checkType
    );
    return {
      foundRules,
      isFetching,
      error,
    };
  }, [checkType, ignoreRulesData?.data?.ignoreRules, isFetching, error]);
};
