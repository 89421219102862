import React, { useCallback, useMemo } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import { useDebouncedCallback } from "use-debounce";

import { useSetFiltersDataFromOptions } from "@/components/k8sAddons/hooks/filters/useSetFiltersDataFromOptions";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

type SelectProps = Omit<
  React.ComponentProps<typeof AdvancedMultiSelect<string>>,
  "options" | "values" | "onChange" | "onClose" | "onOpen"
>;
type FiltersContainerProps = {
  filterCategory: string;
  label: string;
  selectProps?: SelectProps;
  options: MuiSelectionOption<string>[];
  disable: boolean;
};

const DEBOUNCE_TIME_MS = 200;

/** <SelectFilterHandler> must be a child of <FiltersContextProvider>
 *  options are set in URL onClose, not onChange
 * */
export const SelectFilterHandler: React.FC<FiltersContainerProps> = ({
  filterCategory,
  label,
  selectProps,
  options,
  disable,
}) => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const setFiltersDataFromOptions = useSetFiltersDataFromOptions();
  const { callback: debouncedSetFiltersDataFromOptions } = useDebouncedCallback(
    setFiltersDataFromOptions,
    DEBOUNCE_TIME_MS,
    {
      leading: false,
      trailing: true,
    }
  );

  const currentValues = useMemo<MuiSelectionOption<string>[]>(() => {
    const filtersFromUrl = filtersDataInUrl?.[filterCategory] ?? [];
    if (filtersFromUrl.length === 0) return [];
    const filter = filtersFromUrl[0];
    return filter.values.reduce<MuiSelectionOption<string>[]>((acc, value) => {
      const option = options?.find((option) => option.value === value);
      acc.push({
        label: option?.label ?? value,
        value,
      });

      return acc;
    }, []);
  }, [filterCategory, filtersDataInUrl, options]);

  const onChange = useCallback(
    (newOptions: MuiSelectionOption<string>[]) => {
      debouncedSetFiltersDataFromOptions(filterCategory, newOptions);
    },
    [debouncedSetFiltersDataFromOptions, filterCategory]
  );

  return (
    <AdvancedMultiSelect
      ariaLabel={`select filter for ${label}`}
      options={options}
      label={label}
      placeholder={"All"}
      value={currentValues}
      onChange={onChange}
      disabled={disable}
      {...selectProps}
    />
  );
};
