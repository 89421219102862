import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/Insight";
import EventGroup from "../../../../../../EventGroup";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import { NodeTerminationDataParsed } from "../../common/types";
import { useNodeEventById } from "../../../../../../EventGroup/nodeEvent/useNodeChangeEvents";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../../../../../shared/constants/systemWideServiceId";
import { groupNodeChangeEvents } from "../../../../../../EventGroup/nodeEvent/NodeChangeGroup";

export const NODE_TERMINATIONS_TOOLTIP = "node-terminations-tooltip";

export const CorrelatedNodeTerminationsTooltip: React.FC<{
  nodeTerminations: NodeTerminationDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
}> = ({ nodeTerminations, issueStartTime, setCorrelatedEvent }) => {
  const [eventId, setEventId] = useState<string>();

  const nodeTermination = useNodeEventById(eventId ?? "");
  const correlatedNodeTermination = useMemo(() => {
    return groupNodeChangeEvents(
      nodeTermination ? [nodeTermination] : [],
      SYSTEM_WIDE_SERVICE_ID
    )?.[0];
  }, [nodeTermination]);

  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedNodeTermination) {
        setCorrelatedEvent(correlatedNodeTermination);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedNodeTermination, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedNodeTermination) {
      setCorrelatedEvent(correlatedNodeTermination);
    }
  }, [correlatedNodeTermination, setCorrelatedEvent]);
  return (
    <Tooltip id={NODE_TERMINATIONS_TOOLTIP} variant="light">
      <InsightTooltipContainer
        aria-label={AriaLabels.AvailabilityTriage.NodeTerminationInsightTooltip}
      >
        {nodeTerminations.map((termination, i) => (
          <React.Fragment key={termination.id}>
            <InsightTooltipDetails>
              <Typography>Event time</Typography>
              <Typography bold>
                {formatDistance(issueStartTime, termination.startTime)} before
                current issue
              </Typography>
              <Link
                onClick={() => handleClick(termination.id)}
                aria-label={
                  AriaLabels.AvailabilityTriage
                    .NodeTerminationInsightTooltipLink
                }
              >
                View details
              </Link>
            </InsightTooltipDetails>
            {i < nodeTerminations.length - 1 && <Divider spacing="1rem" />}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
