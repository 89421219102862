import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import { DrawerPadding } from "../ignoreChecksStyles";
import { CheckType } from "../../../../../../generated/reliabilityApi";
import { getCheckLabel } from "../../../../utils/getCheckLabel";

import { useGetCheckTypeInURL } from "./ignoreChecksDrawerHooks";

const Container = styled.div`
  position: relative;
`;
const StyledTypography = styled(Typography)`
  ${DrawerPadding};
  padding-top: 32px;
  padding-bottom: 24px;
`;
const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 12px;
    top: calc(50% + 4px);
    transform: translate(0, -50%);
  }
`;

type TopSectionProps = {
  onClose: () => void;
};

const EMPTY_CHECK_TYPE = CheckType.DeprecatedApis;

export const TopSection: React.FC<TopSectionProps> = ({ onClose }) => {
  const checkType = useGetCheckTypeInURL();
  const title = getCheckLabel(checkType ?? EMPTY_CHECK_TYPE);
  if (!checkType) return null;

  return (
    <Container>
      <StyledIconButton onClick={onClose}>
        <CloseOutlined />
      </StyledIconButton>
      <StyledTypography variant={"h3"}>{title}</StyledTypography>
      <Divider />
    </Container>
  );
};
