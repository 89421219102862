import { ViolationStatus } from "../../../generated/reliabilityApi";

export const ViolationStatusToLabel: Record<ViolationStatus, string> = {
  confirmed: "Acknowledged",
  open: "Open",
  dismissed: "Dismissed",
  ignored: "Ignored",
  resolved: "Closed",
  manually_resolved: "Marked as resolved",
};
