import { AnyObject, lazy, Lazy, number, object, ValidateOptions } from "yup";

import { policyValidationErrorMessages } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { Severity } from "@/components/reliability/ReliabilityTypes";

const { getComparisonErrorMessage, identicalValuesMessage } =
  policyValidationErrorMessages;

const isValueHigherThan = (
  higherValue: number,
  lowerValue: number
): boolean => {
  return isNaN(lowerValue) || isNaN(higherValue) || higherValue > lowerValue;
};

const getTransformedNumber = () =>
  number().transform((value) => (isNaN(value) ? undefined : value));

type CompareValuesParams = {
  options: ValidateOptions<Context>;
  highNumber: number;
  lowNumber: number;
};

const compareValues = ({
  highNumber,
  lowNumber,
  options,
}: CompareValuesParams) => {
  const { isReverseSeverityOrder } = options.context ?? {};
  return isReverseSeverityOrder
    ? isValueHigherThan(lowNumber, highNumber)
    : isValueHigherThan(highNumber, lowNumber);
};

type GetErrorMessageParams = {
  higherSeverity: Severity;
  lowerSeverity: Severity;
  options: ValidateOptions<Context>;
};

const getErrorMessage = ({
  higherSeverity,
  lowerSeverity,
  options,
}: GetErrorMessageParams) => {
  const { isReverseSeverityOrder } = options.context ?? {};
  const comparative = isReverseSeverityOrder ? "lower" : "higher";
  return getComparisonErrorMessage({
    higherSeverity,
    lowerSeverity,
    comparative,
  });
};

type NumbersObject = {
  high?: number;
  medium?: number;
  low?: number;
};

interface Context extends AnyObject {
  isReverseSeverityOrder: boolean;
}

export const numericSchema: Lazy<NumbersObject> = lazy((values, options) => {
  const validationOptions = options as ValidateOptions<Context>;
  return object<Context>().shape({
    medium: getTransformedNumber()
      .test("notOneOf", identicalValuesMessage, (value) => {
        return isNaN(values.high) || value !== values.high;
      })
      .test(
        "medium not more than high",
        getErrorMessage({
          higherSeverity: "high",
          lowerSeverity: "medium",
          options: validationOptions,
        }),
        function validate(value) {
          return compareValues({
            highNumber: values.high,
            lowNumber: value as number,
            options: validationOptions,
          });
        }
      ),
    low: getTransformedNumber()
      .test("notOneOf", identicalValuesMessage, (value) => {
        return isNaN(values.high) || value !== values.high;
      })
      .test("notOneOf", identicalValuesMessage, (value) => {
        return isNaN(values.medium) || value !== values.medium;
      })
      .test(
        "low not more than high",
        getErrorMessage({
          higherSeverity: "high",
          lowerSeverity: "low",
          options: validationOptions,
        }),
        function validate(value) {
          return compareValues({
            highNumber: values.high,
            lowNumber: value as number,
            options: validationOptions,
          });
        }
      )
      .test(
        "low not more than medium",
        getErrorMessage({
          higherSeverity: "medium",
          lowerSeverity: "low",
          options: validationOptions,
        }),
        function validate(value) {
          return compareValues({
            highNumber: values.medium,
            lowNumber: value as number,
            options: validationOptions,
          });
        }
      ),
  });
});
