import React, { useEffect, useState } from "react";
import { ActionMetadataHelmShowValues } from "komodor-types/build/entities/AgentTask";
import { ActionTypes, TaskType } from "komodor-types";

import { LinesLoader } from "../../../../common/loaders/Line";
import useAgentTask from "../../../../../shared/hooks/useAgentTask/useAgentTask";

import { LazyEditor } from "@/components/common/LazyEditor";

interface ValuesEditorProps {
  chart: string;
  chartVersion: string;
  cluster: string;
  agentId: string;
  onValueChange: (value: string) => void;
}

const ValuesEditor: React.FC<ValuesEditorProps> = ({
  chart,
  chartVersion,
  cluster,
  agentId,
  onValueChange,
}) => {
  const [currentValues, setCurrentValues] = useState<string | undefined>();
  const [showValuesSent, setShowValuesSent] = useState(false);

  const metadata: ActionMetadataHelmShowValues = {
    chartName: chart,
    cluster: cluster,
    version: chartVersion,
    type: ActionTypes.HelmShowValues,
  };

  const {
    execute: showValues,
    result,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  useEffect(() => {
    if (!showValuesSent) {
      setShowValuesSent(true);
      showValues();
    }
  }, [showValues, showValuesSent]);

  useEffect(() => {
    if (result && !currentValues) {
      setCurrentValues(result as string);
      onValueChange(result as string);
    }
  }, [showValues, result, showValuesSent, currentValues, onValueChange]);

  return (
    <>
      {isFetching ? (
        <LinesLoader />
      ) : (
        <LazyEditor
          width="100%"
          height="100%"
          mode="yaml"
          value={currentValues}
          onChange={(v) => {
            setCurrentValues(v);
            onValueChange(v);
          }}
        />
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ValuesEditor;
