import React from "react";
import { palette } from "@komodorio/design-system";
import {
  AlertCircle16,
  AlertTriangle16,
  InfoCircle16,
} from "@komodorio/design-system/icons";

import {
  CustomEventResponseDataInner,
  CustomEventResponseDataInnerSeverityEnum,
} from "../../../../generated/resourcesApi";
import EventGroup from "../index";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CustomEventGroup from "./CustomEventGroup";

export const isCustomEvent = (event?: EventGroup | null): boolean =>
  !!(event && event instanceof CustomEventGroup);

type IconPropsBySeverity = {
  [key in CustomEventResponseDataInnerSeverityEnum]: {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    backgroundColor: string;
    fillColor: string;
  };
};
export const iconPropsBySeverity: IconPropsBySeverity = {
  information: {
    icon: InfoCircle16,
    backgroundColor: palette.gray["100"],
    fillColor: "616D92",
  },
  warning: {
    icon: AlertCircle16,
    backgroundColor: palette.orange["100"],
    fillColor: palette.orange["500"],
  },
  error: {
    icon: AlertTriangle16,
    backgroundColor: palette.pink["100"],
    fillColor: palette.pink["500"],
  },
};

export const eventHasSingleService = (
  event: CustomEventResponseDataInner
): boolean => {
  return (
    event.servicesNames?.length === 1 &&
    event.clusters?.length === 1 &&
    event.namespaces?.length === 1
  );
};
