import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Share16 } from "@komodorio/design-system/icons";
import React, { useState } from "react";
import styled from "styled-components";

import { ShareDesktopModal } from "./ShareDesktopModal";

const Container = styled.div`
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.6rem;
`;

const Box = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52%;
  gap 0.4rem;
  border: 1px solid ${palette.gray[400]};
  padding: 0.3rem 2.2rem;
  border-radius: 4px;
`;

const InviteText = styled(Typography)`
  color: ${palette.gray[100]};
  letter-spacing: 0.02em;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const ShareDesktopApp: React.FC = () => {
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Container>
        <Box onClick={openModal}>
          <Share16 /> <InviteText>Share</InviteText>
        </Box>
      </Container>
      <ShareDesktopModal isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};
