import { Button } from "@komodorio/design-system/deprecated";
import { Pod16 } from "@komodorio/design-system/icons";
import React, { useCallback, useState } from "react";

import { PodsSelectionDialog } from "../../common/PodsSelectionDialog";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

interface RelatedResourcesSelectorProps {
  timeWindow: TimeWindow;
}

export const PodsSelector: React.FC<RelatedResourcesSelectorProps> = ({
  timeWindow,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    toggleBackgroundScrolling();
  }, [setModalOpen]);

  const onClickAddPodEvent = useCallback(() => {
    dispatchEvent(AnalyticEvents.PodEvents.AddPodEvent_clicked);
    setModalOpen(true);
    toggleBackgroundScrolling();
  }, []);
  return (
    <>
      <Button variant="secondary" icon={Pod16} onClick={onClickAddPodEvent}>
        Add Pod Events
      </Button>
      {modalOpen && (
        <PodsSelectionDialog close={closeModal} timeWindow={timeWindow} />
      )}
    </>
  );
};

const toggleBackgroundScrolling = () => {
  if (document.body.style.overflow === "hidden") {
    document.body.style.overflow = "unset";
  } else {
    document.body.style.overflow = "hidden";
  }
};
