import { useMemo, useState } from "react";

import {
  METRICS_FULL_CONTAINER_CPU_ENDPOINT,
  METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
} from "../../shared/hooks/metrics-api/requestResponseMaps";
import { Timeframe } from "../../shared/types/TimeWindow";

import { Metrics, MetricsTimeWindow } from "./types";
import { useFullContainerMetrics } from "./useFullMetrics";
import { calculateTimeframe, timeWindowToEpochs } from "./utils";
import { useCustomMetricsParams } from "./useCustomMetricsParams";

export const usePodMetrics = (
  clusterName: string,
  namespace: string,
  podName: string,
  containerName: string,
  endTimestamp: number,
  isMetricsSupported: boolean
): Metrics => {
  const metricsRequestParams = useMemo(
    () => ({
      clusterName,
      namespace,
      podName,
      containerName,
      endTimestamp,
      pause:
        !clusterName ||
        !namespace ||
        !podName ||
        !containerName ||
        !isMetricsSupported,
    }),
    [
      clusterName,
      namespace,
      podName,
      containerName,
      endTimestamp,
      isMetricsSupported,
    ]
  );

  return useFullPodMetrics({
    ...metricsRequestParams,
    pause: metricsRequestParams.pause,
  });
};

export const useFullPodMetrics = (metricsRequestParams: {
  containerName: string;
  clusterName: string;
  namespace: string;
  podName: string;
  endTimestamp: number;
  pause: boolean;
}): Metrics => {
  const [prevTimeframe, setPrevTimeframe] = useState<MetricsTimeWindow>();

  const [timeframe, setTimeframe] = useState<MetricsTimeWindow>();

  const endDate = useMemo(
    () => new Date(metricsRequestParams.endTimestamp),
    [metricsRequestParams.endTimestamp]
  );

  const memoryOneHour = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.LastHour, endDate),
  });

  const cpuOneHour = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.LastHour, endDate),
  });

  const memoryFourHours = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last4Hours, endDate),
  });

  const cpuFourHours = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last4Hours, endDate),
  });

  const memoryOneDay = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last24Hours, endDate),
  });

  const cpuOneDay = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last24Hours, endDate),
  });

  const memoryOneWeek = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last7Days, endDate),
  });

  const cpuOneWeek = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last7Days, endDate),
  });

  const customMetricsParams = useCustomMetricsParams(prevTimeframe, timeframe);

  const memoryCustom = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_MEMORY_ENDPOINT,
    ...customMetricsParams,
  });

  const cpuCustom = useFullContainerMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_FULL_CONTAINER_CPU_ENDPOINT,
    ...customMetricsParams,
  });

  return useMemo(
    () => ({
      memoryOneHour,
      cpuOneHour,
      memoryFourHours,
      cpuFourHours,
      memoryOneDay,
      memoryOneWeek,
      cpuOneDay,
      cpuOneWeek,
      memoryCustom,
      cpuCustom,
      timeframe,
      setTimeframe: (start?: Date, end?: Date) => {
        if (
          prevTimeframe === undefined ||
          calculateTimeframe(prevTimeframe?.start, prevTimeframe?.end) !==
            calculateTimeframe(start, end)
        ) {
          setPrevTimeframe(timeframe);
        }
        if (start && end) {
          setTimeframe({ start, end });
        } else {
          setTimeframe(undefined);
        }
      },
    }),
    [
      memoryOneHour,
      cpuOneHour,
      memoryFourHours,
      cpuFourHours,
      memoryOneDay,
      memoryOneWeek,
      cpuOneDay,
      cpuOneWeek,
      memoryCustom,
      cpuCustom,
      timeframe,
      prevTimeframe,
    ]
  );
};
