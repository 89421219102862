import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { muiTheme } from "@komodorio/design-system";

import UpgradeRequired from "../../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeRequiredModalStep } from "../../../../common/UpdateAgentModal/types";
import { useGetHelmInstallationCommand } from "../../../../../shared/hooks/useGetHelmInstallationCommand";

const Container = styled.div`
  margin: 0 10px;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

export const UpgradeButtonAndModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_NETWORK_MAPPER
  );
  return (
    <Container>
      <Button variant={"contained"} onClick={() => setOpen(true)}>
        <StyledTypography variant={"h6"} color={muiTheme.palette.common.white}>
          Update Agent
        </StyledTypography>
      </Button>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={null}
          upgradeCommand={commandStr}
          desiredFeature="network mapper"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </Container>
  );
};
