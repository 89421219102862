import { useMemo } from "react";
import semver from "semver";

import { ConfigurationType } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { REQUIRED_AGENT_VERSION_HELM } from "@/shared/utils/agent/agent";
import { AgentInfoToDefaultClusterName } from "@/components/Inspection/utils/useDefaultClusterForInspection";

export const useIsAgentSupported = (
  activeAgents: AgentInfoToDefaultClusterName[],
  cluster: string | undefined
) => {
  return useMemo(() => {
    const currentAgent = activeAgents?.find((a) => a.clusterName === cluster);
    return ConfigurationType.guard(currentAgent?.configuration)
      ? semver.gte(
          currentAgent?.configuration?.appmetadata?.version ?? "0.0.0",
          REQUIRED_AGENT_VERSION_HELM
        ) &&
          currentAgent?.configuration?.enablehelm &&
          currentAgent?.configuration?.resources?.secret
      : false;
  }, [activeAgents, cluster]);
};
