import React from "react";
import {
  Status,
  StatusColor,
  StatusProps,
} from "@komodorio/design-system/komodor-ui";

import { ArgoPhases } from "./types";

const wfStatusColorMap: { [key in ArgoPhases]: StatusColor } = {
  [ArgoPhases.Succeeded]: "success",
  [ArgoPhases.Failed]: "error",
  [ArgoPhases.Error]: "error",
  [ArgoPhases.Running]: "running",
  [ArgoPhases.Pending]: "warning",
};

export const ArgoStatus: React.FC<
  { status: ArgoPhases } & Partial<StatusProps>
> = ({ status, size = "medium", variant = "outlined", uppercase = true }) => {
  if (!status) {
    return null;
  }

  const statusColor: StatusColor = wfStatusColorMap[status] || "neutral";

  return (
    <Status
      size={size}
      variant={variant}
      color={statusColor}
      label={status}
      uppercase={uppercase}
    />
  );
};
