import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../resources/node";

import { NodeMetricsTabContextProvider } from "./context/NodeMetricsTabContext";
import { NodeMetricsTabContent } from "./NodeMetricsTabContent/NodeMetricsTabContent";

interface NodeMetricsProps {
  node: Node;
}

export const NodeMetricsTab: React.FC<NodeMetricsProps> = ({ node }) => {
  return (
    <NodeMetricsTabContextProvider>
      <NodeMetricsTabContent node={node} />
    </NodeMetricsTabContextProvider>
  );
};
