import { MarkerType } from "react-flow-renderer";

export const INITIAL_X = 800;
export const INITIAL_Y = 250;
export const NODE_WIDTH = 200;
export const SPACE_BETWEEN_NODES_X = NODE_WIDTH + 200;
export const SPACE_BETWEEN_NODES_Y = 175;
export const CHILD_OFFSET_Y = 50;
export const X_TO_REDUCE = 100;
export const EDGE_MARKER_TYPE = MarkerType.ArrowClosed;
export const EDGE_MARKER_WIDTH = 30;
export const EDGE_MARKER_HEIGHT = 30;

export const GRAPH_CONTAINER_ID = "react-flow-custom-container";

export const INITIAL_NODE_ID = "initial-node-id";

export const NON_CRD_KINDS = [
  "CronJob",
  "DaemonSet",
  "Deployment",
  "Job",
  "Rollout",
  "StatefulSet",
];

export const dependenciesGraphAriaLabels = {
  graphNode: "graph-node",
  healthIndicator: {
    healthy: "healthy-node",
    unhealthy: "unhealthy-node",
  },
  tooltip: {
    kindAndName: "tooltip-kind-and-name",
    clusterAndNamespace: "tooltip-cluster-and-namespace",
    replicas: "tooltip-replicas",
    issue: "tooltip-issue",
  },
};
