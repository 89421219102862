import React, { useMemo } from "react";
import { Button, ButtonProps } from "@komodorio/design-system/deprecated";
import { Lock16 } from "@komodorio/design-system/icons";

import { useGetClustersCountSubscription } from "../../../../generated/graphql";
import {
  freemiumModalNames,
  useFreemiumModalsUpdate,
} from "../../../../shared/hooks/useFreemiumModals";
import useGetAccountPlanData from "../../useGetAccountPlanData";
import { hasReachedClusterLimit } from "../../Restrictions/clusters";

export const FreemiumClustersButton: React.FC<ButtonProps> = ({
  onClick,
  icon,
  ...rest
}) => {
  const setOpenFreemiumModal = useFreemiumModalsUpdate();
  const [{ data }] = useGetClustersCountSubscription();

  const planData = useGetAccountPlanData();

  const isBlock = useMemo(
    () =>
      hasReachedClusterLimit(
        planData?.clustersLimit,
        data?.clusters?.aggregate?.count
      ),
    [data, planData?.clustersLimit]
  );

  const onFreemiumButtonClick = () => {
    setOpenFreemiumModal &&
      setOpenFreemiumModal(freemiumModalNames.CLUSTERS_RESTRICTION);
  };
  return (
    <>
      <Button
        {...rest}
        icon={isBlock ? Lock16 : icon}
        onClick={isBlock ? onFreemiumButtonClick : onClick}
      />
    </>
  );
};
