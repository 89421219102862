import { ListIteratee, Many, sortBy } from "lodash";

import { ServiceInfo } from "../../shared/types/ServiceInfo";

export enum ServicesSortStrategy {
  Health = "Health",
  LastModified = "Last Modified",
  ServiceName = "Name",
  ServiceNamespace = "Namespace",
}

export enum JobsSortStrategy {
  Status = "Status",
  LastRun = "Last Run",
  JobName = "Name",
  JobNamespace = "Namespace",
}

// this is a duplicate of the enum at JobEventGroup.tsx, please make sure they are synced.
// importing the enum from there throws "access before initialize" error,
// and moving the enum to other place makes unit tests fail in a weird way.
enum JobState {
  completed = "Completed",
  suspended = "Suspended",
  resumed = "Resumed",
  inprogress = "Running",
  failed = "Failed",
  started = "Started",
  deleted = "Deleted",
  unknown = "Unknown",
  noRuns = "No runs yet",
}

const healthSort = (s: ServiceInfo) => s.healthy;
const lastModifiedSort = (s: ServiceInfo) => -(s.lastModified?.getTime() ?? 0);
const nameSort = (s: ServiceInfo) => s.title;
const namespaceSort = (s: ServiceInfo) => s.env;
const lastRunSort = (s: ServiceInfo) => -(s.jobLastRunTime?.getTime() ?? 0);

const JobStateSortingOrder: Map<JobState, number> = new Map([
  [JobState.failed, 0],
  [JobState.noRuns, 1],
  [JobState.unknown, 2],
  [JobState.inprogress, 3],
  [JobState.suspended, 4],
  [JobState.resumed, 5],
  [JobState.started, 6],
  [JobState.completed, 7],
  [JobState.deleted, 8],
]);
const statusSort = (s: ServiceInfo) =>
  JobStateSortingOrder.get(s.jobState) ?? 8;

// always sort by all props, but in a different order. for Jobs use status, for services use Health
const defaultServicesStrategy = [
  healthSort,
  lastModifiedSort,
  nameSort,
  namespaceSort,
];

const defaultJobsStrategy = [statusSort, lastRunSort, nameSort, namespaceSort];

const lastModifiedServiceStrategy = [
  lastModifiedSort,
  healthSort,
  nameSort,
  namespaceSort,
];
const nameServiceStrategy = [
  nameSort,
  healthSort,
  lastModifiedSort,
  namespaceSort,
];
const namespaceServiceStrategy = [
  namespaceSort,
  healthSort,
  lastModifiedSort,
  nameSort,
];

const lastRunJobStrategy = [lastRunSort, statusSort, nameSort, namespaceSort];
const nameJobStrategy = [nameSort, statusSort, lastRunSort, namespaceSort];
const namespaceJobStrategy = [namespaceSort, statusSort, lastRunSort, nameSort];

const servicesStrategies = new Map<
  ServicesSortStrategy | JobsSortStrategy,
  Many<ListIteratee<ServiceInfo>>
>([
  [ServicesSortStrategy.Health, defaultServicesStrategy],
  [ServicesSortStrategy.LastModified, lastModifiedServiceStrategy],
  [ServicesSortStrategy.ServiceName, nameServiceStrategy],
  [ServicesSortStrategy.ServiceNamespace, namespaceServiceStrategy],
]);

const jobsStrategies = new Map<
  JobsSortStrategy | ServicesSortStrategy,
  Many<ListIteratee<ServiceInfo>>
>([
  [JobsSortStrategy.Status, defaultJobsStrategy],
  [JobsSortStrategy.LastRun, lastRunJobStrategy],
  [JobsSortStrategy.JobName, nameJobStrategy],
  [JobsSortStrategy.JobNamespace, namespaceJobStrategy],
]);

export const servicesStrategyNames = Array.from(servicesStrategies.keys());
export const jobsStrategyNames = Array.from(jobsStrategies.keys());

export const useSortedServicesOrJobs = (
  services: ServiceInfo[] | undefined,
  strategyName: ServicesSortStrategy | JobsSortStrategy,
  isJobs: boolean
): ServiceInfo[] | undefined => {
  if (!services) return;
  return sortBy(
    services,
    isJobs
      ? jobsStrategies.get(strategyName) ?? defaultJobsStrategy
      : servicesStrategies.get(strategyName) ?? defaultServicesStrategy
  );
};
