import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography, Button } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { CommandLineAccess } from "./CommandLineAccess";
import { InstallKomoCliNote } from "./installKomoCli/InstallKomoCliNote";

interface PortForwardModalContentProps {
  onClose: () => void;
  agentId: string;
}

const Body = styled.div`
  margin: 0 2rem;
`;

const Content = styled.div`
  margin-top: 2rem;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const StartNewSessionModalContent: React.FC<
  PortForwardModalContentProps
> = ({ onClose, agentId }) => {
  return (
    <Body>
      <Typography variant="headline" size="small" color={palette.gray["800"]}>
        Port Forwarding
      </Typography>

      <Content>
        <Typography variant="title" size="medium" color={palette.gray["800"]}>
          Command line access
        </Typography>

        <CommandLineAccess agentId={agentId} />
        <InstallKomoCliNote />
      </Content>

      <ButtonContainer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ButtonContainer>
    </Body>
  );
};
