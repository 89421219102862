import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import { STATIC_TIME_FRAME } from "../../../constants/costOptimizationConstants";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectLastVisitTime } from "../../../store/costOptimizationStoreSelectors";
import { getEpochFromTimeFrame } from "../../../utils/costOptimizationUtils";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";

export const useRightSizingEpochs = () => {
  const lastVisitTime = useCostOptimizationStore(selectLastVisitTime);
  // currently right-sizing only supports a static time frame of "30d"
  return getEpochFromTimeFrame(STATIC_TIME_FRAME, lastVisitTime);
};

export const useRightSizingTablePaginationMode = (): boolean => {
  const { currentWorkspaceId, isWorkspaceKindBackendFiltered } =
    useWorkspaces();

  const { paginateRightSizingTable } = useOverridableFlags();

  return !!(
    (currentWorkspaceId === "" || isWorkspaceKindBackendFiltered) &&
    paginateRightSizingTable
  );
};
