export const cardTitle = "missing liveness probes";

export const contentTitle = "What it means";

export const explanationBlock1 =
  "Liveness probes are health checks used to determine if a container within a pod is running and responsive. Not setting liveness probes can result in an unhealthy state going undetected and lead to prolonged downtime or user disruption.";

export const explanationBlock2 =
  "To add a liveness probe to a container, you need to set the livenessProbe field to the container's specification within the pod definition file.";

export const explanationBlock3 =
  "For example, this liveness probe is configured to perform an HTTP GET request to the /health endpoint on port 8080 of the container:";

export const learnMoreText = "Learn more about liveness probes";

export const learnMoreUrl =
  "https://kubernetes.io/docs/tasks/configure-pod-container/configure-liveness-readiness-startup-probes";
