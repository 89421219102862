import { useMemo } from "react";

import { SyntheticSupportingData } from "@/generated/reliabilityApi";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { getSyntheticSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/SyntheticViolation/syntheticViolationUtils";

export const useSyntheticViolationSupportingData = ():
  | SyntheticSupportingData
  | undefined => {
  const { data } = useViolation();

  return useMemo(() => {
    const checkType = data?.data.violation.checkType;
    const supportingData = data?.data.violation.supportingData ?? {};

    if (!checkType) return undefined;
    return getSyntheticSupportingData(checkType, supportingData);
  }, [data?.data.violation.checkType, data?.data.violation.supportingData]);
};
