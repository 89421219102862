import { useMemo } from "react";

import {
  ListServiceAttributesForAccountQuery,
  useListServiceAttributesForAccountQuery,
  useFetchAllKomodorServiceAttributesByKeysQuery,
  FetchAllKomodorServiceAttributesByKeysQuery,
} from "../../../generated/graphql";
import { useQueryPolling } from "../../../shared/hooks/useQueryPolling";
import { ServiceAttribute } from "../../../shared/types/ServiceInfo";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  convertToKomodorServiceAttributes,
  useGetKomodorServiceAttributes,
} from "../../../shared/hooks/resources-api/client/komodor-services/useKomodorServices";
import { KomodorServiceAttributesParamsFieldsEnum } from "../../../generated/resourcesApi";

const SERVICE_ATTRIBUTES_POLLING_INTERVAL = 15000;

interface ServiceFilters {
  [index: string]: Set<string>;
}

const asStringArray = (v: unknown) => {
  if (!Array.isArray(v)) return [];
  return v.filter((e): e is string => typeof e === "string");
};

export const useServiceAttributesForAccount = (
  serviceAttsConfigs:
    | ListServiceAttributesForAccountQuery["service_attributes_for_account"]
    | undefined
): ServiceFilters | null => {
  return useMemo(() => {
    if (!serviceAttsConfigs) return null;
    const serviceFilters: ServiceFilters = {};
    serviceAttsConfigs.forEach(({ displayName, mappings }) => {
      if (!serviceFilters[displayName]) {
        serviceFilters[displayName] = new Set();
      }
      asStringArray(mappings).forEach((mapping) =>
        serviceFilters[displayName].add(mapping)
      );
    });
    return serviceFilters;
  }, [serviceAttsConfigs]);
};

export type AllServicesAttributes =
  | Record<string, ServiceAttribute[]>
  | undefined;

export const useServiceAttributesConfiguredKeys = (): string[] => {
  const [{ data: attributesKeysForAccount }] =
    useListServiceAttributesForAccountQuery();
  return useMemo(() => {
    if (
      attributesKeysForAccount?.service_attributes_for_account &&
      Array.isArray(attributesKeysForAccount.service_attributes_for_account)
    ) {
      const attributesKeys =
        attributesKeysForAccount.service_attributes_for_account.flatMap(
          (attr) => {
            return asStringArray(attr.mappings);
          }
        );
      return Array.from(new Set(attributesKeys));
    }
    return [];
  }, [attributesKeysForAccount?.service_attributes_for_account]);
};

export const useServiceAttributesConfiguredVars = (
  servicesIds: string[]
): {
  keys: string[];
  servicesIds: string[];
} => {
  const serviceAttributesKeys = useServiceAttributesConfiguredKeys();
  return useMemo(
    () => ({
      keys: serviceAttributesKeys,
      servicesIds: servicesIds,
    }),
    [serviceAttributesKeys, servicesIds]
  );
};

export type KomodorServiceAttributes =
  FetchAllKomodorServiceAttributesByKeysQuery["komodor_service_attributes"];

export const useAllServiceAttributesPolling = (
  servicesIds: string[]
): KomodorServiceAttributes | undefined => {
  const attributesVars = useServiceAttributesConfiguredVars(servicesIds);
  const { fetchAllKomodorServiceAttributesByKeysFromResourcesApi } =
    useOverridableFlags();
  const resourcesApiAttributesVars = useMemo(
    () => ({
      keys: attributesVars.keys,
      servicesIds: attributesVars.servicesIds,
      fields: [
        KomodorServiceAttributesParamsFieldsEnum.Key,
        KomodorServiceAttributesParamsFieldsEnum.Value,
        KomodorServiceAttributesParamsFieldsEnum.ServiceId,
      ],
    }),
    [attributesVars.keys, attributesVars.servicesIds]
  );

  const shouldEnableResourcesApi =
    !!(attributesVars.keys.length && attributesVars.servicesIds.length) &&
    !!fetchAllKomodorServiceAttributesByKeysFromResourcesApi;

  const { data: resourcesApiData } = useGetKomodorServiceAttributes({
    params: {
      komodorServiceAttributesParams: resourcesApiAttributesVars,
    },
    interval: SERVICE_ATTRIBUTES_POLLING_INTERVAL,
    enabled: shouldEnableResourcesApi,
  });

  const shouldPauseQuery =
    !(attributesVars.keys.length && attributesVars.servicesIds.length) ||
    !!fetchAllKomodorServiceAttributesByKeysFromResourcesApi;

  const [data] = useQueryPolling(
    useFetchAllKomodorServiceAttributesByKeysQuery({
      variables: attributesVars,
      pause: shouldPauseQuery,
    }),
    SERVICE_ATTRIBUTES_POLLING_INTERVAL,
    shouldPauseQuery
  );

  return useMemo(() => {
    if (!data && !resourcesApiData) return undefined;
    return fetchAllKomodorServiceAttributesByKeysFromResourcesApi
      ? convertToKomodorServiceAttributes(resourcesApiData)
      : data?.komodor_service_attributes;
  }, [
    data,
    resourcesApiData,
    fetchAllKomodorServiceAttributesByKeysFromResourcesApi,
  ]);
};
