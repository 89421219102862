import { Container } from "kubernetes-types/core/v1.d";
import React, { useMemo, useState } from "react";
import { Button } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { DescribeSectionDivider } from "../../common/DescribeSectionDivider";
import { DescribeTagListItem } from "../../common/DescribeTagListItem";
import { usePortForward } from "../../../../../../shared/context/PortForwardProvider";
import {
  UpdateMessage,
  useActionsNeedUpgradeAgent,
} from "../../../../../Actions/common/actionsUpgradeRequired";
import { REQUIRED_AGENT_PORT_FORWARDING } from "../../../../../../shared/utils/agent/agent";
import UpgradeRequired from "../../../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeRequiredModalStep } from "../../../../../common/UpdateAgentModal/types";
import { useGetHelmInstallationCommand } from "../../../../../../shared/hooks/useGetHelmInstallationCommand";

const PortRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerPorts: React.FC<{
  container: Container;
  agentId: string;
  cluster: string;
  showForwardButton: boolean;
}> = ({ container, agentId, cluster, showForwardButton }) => {
  const { setIsPortForwardModalOpen, setAvailablePorts } = usePortForward();
  const [showUpgradeRequired, setShowUpgradeRequired] = useState(false);
  const upgradeRequired = useActionsNeedUpgradeAgent({
    agentId,
    cluster,
    minimalSupportedVersion: REQUIRED_AGENT_PORT_FORWARDING,
    action: "portForward",
  });

  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_PORT_FORWARDING
  );

  const portsArray = useMemo(() => {
    if (!container.ports) {
      return null;
    }

    return (
      <>
        <DescribeSectionDivider title={"PORTS"} />
        {container.ports.map(({ name, containerPort, protocol = "" }) => (
          <PortRow>
            <DescribeTagListItem
              name={name || "Port"}
              value={[`${name ? name + ": " : ""}${containerPort}/${protocol}`]}
              alignName="center"
            />
            {showForwardButton && (
              <Button
                variant="secondary"
                onClick={() => {
                  if (upgradeRequired) {
                    setShowUpgradeRequired(true);
                  } else {
                    setAvailablePorts([
                      { name, port: containerPort, protocol },
                    ]);
                    setIsPortForwardModalOpen(true);
                  }
                }}
              >
                Forward
              </Button>
            )}
          </PortRow>
        ))}
        {showUpgradeRequired && (
          <UpgradeRequired
            handleClose={() => setShowUpgradeRequired(false)}
            updateMessage={UpdateMessage}
            upgradeCommand={commandStr}
            desiredFeature="port forwarding"
            initialStep={UpgradeRequiredModalStep.ShowCommand}
          />
        )}
      </>
    );
  }, [
    container.ports,
    setAvailablePorts,
    setIsPortForwardModalOpen,
    showForwardButton,
    showUpgradeRequired,
    upgradeRequired,
    commandStr,
  ]);

  return portsArray;
};
