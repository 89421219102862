import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { OverviewPage } from "../appView/sections/AppView/components/OverviewPage/OverviewPage";
import { OverviewPageOld } from "../appView/sections/AppView/components/OverviewPageOld/OverviewPageOld";
import { ClusterOverview } from "../ClustersView/ClusterOverview/ClusterOverview";
import { ClusterWorkspace, WorkspaceKind } from "../../generated/workspacesApi";
import { AppViewUrlParam } from "../appView/types/urlParams";
import { ClustersGroupOverview } from "../ClustersView/ClustersGroupOverview/ClustersGroupOverview";

import { useWorkspaces } from "./WorkspacesTopBar/hooks";

import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

export const WorkspaceOverview: React.FC = () => {
  const { id: workspaceId } = useParams<AppViewUrlParam>();
  const { workspaces } = useWorkspaces();
  const workspace = workspaces?.find((w) => w.id === workspaceId);

  useEffect(() => {
    if (workspace?.kind && workspace?.name) {
      dispatchEvent(AnalyticEvents.Workspaces.WorkspaceOverviewViewed, {
        workspaceName: workspace.name,
        workspaceKind: workspace.kind,
      });
    }
  }, [workspace?.kind, workspace?.name]);

  const { applicationOverview } = useOverridableFlags();
  const overviewPage = applicationOverview ? (
    <OverviewPage />
  ) : (
    <OverviewPageOld />
  );

  if (workspace?.kind === WorkspaceKind.Cluster) {
    const clusterName = (workspace.value as ClusterWorkspace).clusterName;
    return <ClusterOverview clusterName={clusterName} />;
  }
  if (workspace?.kind === WorkspaceKind.ClustersGroup) {
    return <ClustersGroupOverview clustersGroupId={workspace.id} />;
  }
  return overviewPage;
};
