import React, { useMemo } from "react";
import { theme, palette } from "@komodorio/design-system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import Tooltip from "@mui/material/Tooltip";
import {
  CheckCircleOutlined24,
  LightningBoltCircle24,
  LightningBoltCircleOutlined24,
  KomoBot24,
} from "@komodorio/design-system/icons";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useInvestigationModeStore } from "../../shared/store/investigationModeStore/investigationModeStore";
import {
  issueSelector,
  setStepVisitedSelector,
  stepsSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import { AriaLabels } from "../../shared/config/ariaLabels";

import { AvailabilityInvestigationCheckType } from "./availability/types";
import { useInitSteps } from "./StepsLogic";
import type { SideBarStepType } from "./types";

const stepTitleMap: {
  [key in SideBarStepType]: {
    name: string;
    ariaLabel: string;
  };
} = {
  [AvailabilityInvestigationCheckType.Introduction]: {
    name: "Introduction",
    ariaLabel: AriaLabels.InvestigationMode.IntroductionStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.Logs]: {
    name: "Container Logs",
    ariaLabel: AriaLabels.InvestigationMode.LogsStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.UnhealthyPods]: {
    name: "Unhealthy Pods",
    ariaLabel: AriaLabels.InvestigationMode.UnhealthyPodsStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.CorrelatedDeploys]: {
    name: "Correlated deploys",
    ariaLabel: AriaLabels.InvestigationMode.CorrelatedDeploysStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.RelatedServicesDeploys]: {
    name: "Related services deploys",
    ariaLabel:
      AriaLabels.InvestigationMode.CorrelatedDeploysStep.RelatedServicesDeploys,
  },

  [AvailabilityInvestigationCheckType.NodeIssues]: {
    name: "Node events",
    ariaLabel: AriaLabels.InvestigationMode.NodeEventsStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.NoisyNeighbors]: {
    name: "Noisy neighbors",
    ariaLabel: AriaLabels.InvestigationMode.NodeEventsStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.LimitDecreased]: {
    name: "Memory limit was decreased",
    ariaLabel: AriaLabels.InvestigationMode.LimitDecreasedStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.MemoryLeak]: {
    name: "Memory leak",
    ariaLabel: AriaLabels.InvestigationMode.MemoryLeakStep.StepLink,
  },
  [AvailabilityInvestigationCheckType.ReviewMemoryAllocation]: {
    name: "Review memory allocation",
    ariaLabel: AriaLabels.InvestigationMode.ReviewMemoryAllocation.StepLink,
  },
  [AvailabilityInvestigationCheckType.Dependencies]: {
    name: "Dependencies",
    ariaLabel: AriaLabels.InvestigationMode.DependenciesStep.StepLink,
  },
};

const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin-block-start: 1rem;
`;

const TooltipProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [30, -20],
      },
    },
  ],
};

const StepTypography = styled(Typography).attrs({ variant: "subtitle2" })<{
  active: boolean;
  visited: boolean;
  disabled: boolean;
}>`
  && {
    vertical-align: middle;
    ${({ active, visited, disabled }) =>
      `font-weight: ${active ? "700" : "400"};
      :hover {
        font-weight: 700;
      }
        ${
          visited && !active
            ? `
          color: ${palette.gray[500]};
          `
            : ""
        }
        ${
          disabled
            ? `
          color: ${palette.gray[500]};
          font-style: italic;
          text-decoration-line: line-through;
          `
            : ""
        }
    `}
  }
`;

const KomoBotIcon = styled(KomoBot24)`
  fill: ${palette.gray[500]};
  margin-left: 0.25rem;
  vertical-align: middle;
`;

const StyledStepIcon = ({
  active,
  completed,
}: {
  active: boolean;
  completed: boolean;
}) => {
  return (
    <>
      {active ? (
        <LightningBoltCircle24 fill={palette.darkBlue[500]} />
      ) : completed ? (
        <CheckCircleOutlined24 fill={palette.gray[500]} />
      ) : (
        <LightningBoltCircleOutlined24
          fill={palette.darkBlue[500]}
          bgColor={palette.blue[50]}
        />
      )}
    </>
  );
};

export const Steps: React.FC = () => {
  const issue = useInvestigationModeStore(issueSelector);
  const steps = useInvestigationModeStore(stepsSelector);
  const setStepVisitedInStore = useInvestigationModeStore(
    setStepVisitedSelector
  );
  const navigate = useNavigate();
  const location = useLocation();

  useInitSteps(issue);

  const [activeStepType, activeStepIndex] = useMemo(() => {
    const activeStepType = location.pathname
      .split("/")
      .pop() as AvailabilityInvestigationCheckType;
    const activeStepIndex = steps.findIndex(
      (step) => step.type === activeStepType
    );
    return [activeStepType, activeStepIndex];
  }, [location.pathname, steps]);

  if (!steps?.length) {
    return null;
  }
  return (
    <Container>
      <Typography variant="overline" color={theme.foreground.fgSubtle}>
        STEPS TO INVESTIGATE
      </Typography>
      <Stepper orientation="vertical" nonLinear activeStep={activeStepIndex}>
        {steps.map((step, index) => {
          if (!step.visible) return null;
          const completed =
            step.disabled || (activeStepIndex !== index && step.visited);
          const active = activeStepIndex === index;
          const { name, ariaLabel } = stepTitleMap[step.type];

          return (
            <Tooltip
              key={step.type}
              PopperProps={TooltipProps}
              arrow={false}
              title={
                step.disabled
                  ? step?.message ??
                    "We checked and didn't find anything suspicious"
                  : null
              }
              placement="top-end"
            >
              <Step completed={completed}>
                <StepButton
                  disabled={step.disabled}
                  onClick={() => {
                    setStepVisitedInStore(activeStepType, true);
                    navigate(step.type);
                  }}
                  aria-label={ariaLabel}
                >
                  <StepLabel StepIconComponent={StyledStepIcon}>
                    <StepTypography
                      active={active}
                      visited={step.visited}
                      disabled={step.disabled}
                    >
                      {name}
                      {step.disabled && <KomoBotIcon />}
                    </StepTypography>
                  </StepLabel>
                </StepButton>
              </Step>
            </Tooltip>
          );
        })}
      </Stepper>
    </Container>
  );
};
