import { useEffect } from "react";

import { DEFAULT_TAB } from "../constants/costOptimizationConstants";

import { useCostOptimizationDataInUrl } from "./persistentStateHooks";

export const useSetInitialTab = (): void => {
  const [{ tab }, setDataInUrl] = useCostOptimizationDataInUrl();

  useEffect(() => {
    if (!tab) {
      setDataInUrl({ tab: DEFAULT_TAB }, true);
    }
  }, [setDataInUrl, tab]);
};
