import React from "react";
import styled from "styled-components";
import { Divider } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CustomEventGroup from "../CustomEventGroup";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { HeaderSection } from "./HeaderSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { MetadataSection } from "./MetadataSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DetailsSection } from "./DetailsSection/DetailsSection";

const Container = styled.div`
  & > div:not([role="separator"]) {
    padding: 24px;
  }
`;

export type CustomEventDetailsProps = {
  event: CustomEventGroup;
  onClose?: () => void;
};
export const CustomEventDetails: React.FC<CustomEventDetailsProps> = ({
  event,
  onClose,
}) => {
  return (
    <Container>
      <HeaderSection event={event} onClose={onClose} />
      <Divider />
      <MetadataSection event={event} />
      <Divider />
      <DetailsSection event={event} />
    </Container>
  );
};
