import React from "react";
import Dialog from "@mui/material/Dialog";

export type SuspendResumeActionType = "suspend" | "resume";

type SuspendResumeCronjobModalProps = {
  open: boolean;
  onClose: (open: boolean) => void;
  actionType: SuspendResumeActionType;
  onActionComplete?: () => void;
};

export const SuspendResumeCronjobModal: React.FC<
  SuspendResumeCronjobModalProps
> = ({ open, onClose, actionType }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {actionType}
    </Dialog>
  );
};
