import { EnrichedMessage } from "../types";
import { StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";

export class CloudReadinessGate extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `.* pod readiness gate ".*(elb|cloud.google.com/load-balancer-neg-ready).*"`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }
    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          "The pod is waiting to be marked as ready by the load balancer"
        ),
      ],
    };
  }
}
