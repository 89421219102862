import { useCallback, useMemo, useState } from "react";

import { Timeframe } from "../../../../shared/types/TimeWindow";
import { millisecondsToUnixTime } from "../../../../shared/utils/timeUtils";
import { timeWindowToEpochs } from "../../../Metrics/utils";
import { MetricsFullTimeWindow } from "../../../Metrics/types";

export const useMetricsWithZoom = (
  timeWindow: MetricsFullTimeWindow,
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void
) => {
  const [zoomed, setZoomed] = useState(false);

  const { fromEpoch, toEpoch, toEpochMs } = useMemo(() => {
    if (timeWindow.timeframe === Timeframe.Custom) {
      return {
        fromEpoch: millisecondsToUnixTime(timeWindow.start.getTime()),
        toEpoch: millisecondsToUnixTime(timeWindow.end.getTime()),
        toEpochMs: timeWindow.end.getTime(),
      };
    }
    return timeWindowToEpochs(timeWindow.timeframe);
  }, [timeWindow]);

  const onZoom = useCallback((value: boolean) => {
    setZoomed(value);
  }, []);

  const graphProps = useMemo(
    () => ({
      zoomProps: { zoomed, onZoom },
      setTimeframe: (start: Date, end: Date) =>
        setTimeWindow({
          start,
          end,
          timeframe: Timeframe.Custom,
        }),
    }),
    [onZoom, setTimeWindow, zoomed]
  );

  return {
    fromEpoch,
    toEpoch,
    toEpochMs,
    graphProps,
  };
};
