import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, Link, Typography } from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
  InsightTooltipMessageContainer,
} from "../../common/Insight";
import { getColor } from "../../../../../Status";
import EventGroup from "../../../../../../EventGroup";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import { useMinifiedMonitorEventGroupById } from "../../../../../../../monitorsView/useWorkflowsRuns";
import { SameIssueInClusterInsightDataParsed } from "../../common/types";

import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import { findService } from "@/shared/utils/serviceHelpers";
import { ServiceInfo } from "@/shared/types/ServiceInfo";

export const SameIssueInClusterTooltip: React.FC<{
  issues: SameIssueInClusterInsightDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  serviceId: string;
  clusterName?: string;
}> = ({
  issues,
  issueStartTime,
  setCorrelatedEvent,
  serviceId,
  clusterName,
}) => {
  const [eventId, setEventId] = useState<string>();
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: eventId ?? "",
    serviceId,
    type: "availability",
  });
  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedIssue) {
        setCorrelatedEvent(correlatedIssue);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedIssue, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedIssue) {
      setCorrelatedEvent(correlatedIssue);
    }
  }, [correlatedIssue, setCorrelatedEvent]);

  const services: ServiceInfo[] | undefined = useKomodorServices().services;

  const allowedIssues = useMemo(() => {
    if (!clusterName) {
      return [];
    } else {
      return issues.filter((issue) =>
        findService(
          services,
          issue.resourceName,
          clusterName,
          "",
          issue.namespace
        )
      );
    }
  }, [clusterName, issues, services]);

  const countDisallowedIssues = useMemo(
    () => issues.length - allowedIssues.length,
    [allowedIssues, issues]
  );

  return (
    <>
      {allowedIssues.length > 0 && (
        <>
          <InsightTooltipContainer
            aria-label={AriaLabels.AvailabilityTriage.SameIssuesInsightTooltip}
          >
            {allowedIssues.map((issue, i) => (
              <React.Fragment key={issue.id}>
                <InsightTooltipDetails>
                  <Typography>Started</Typography>
                  <Typography bold>
                    {formatDistance(issueStartTime, issue.startTime)} before
                    current issue
                  </Typography>
                  <Typography>Namespace</Typography>
                  <Typography bold>{issue.namespace}</Typography>
                  <Typography>Service</Typography>
                  <Typography bold>{issue.resourceName}</Typography>
                  <Typography>Reason</Typography>
                  <Typography bold>{issue.reasons.join(", ")}</Typography>
                  <Typography>Status</Typography>
                  <Typography
                    variant="uppercase"
                    color={getColor(issue.status)}
                  >
                    {issue.status}
                  </Typography>
                  <Link
                    onClick={() => handleClick(issue.id)}
                    aria-label={
                      AriaLabels.AvailabilityTriage.SameIssuesInsightTooltipLink
                    }
                  >
                    View details
                  </Link>
                </InsightTooltipDetails>
                {i < allowedIssues.length - 1 && <Divider spacing="1rem" />}
              </React.Fragment>
            ))}
          </InsightTooltipContainer>
          {countDisallowedIssues > 0 && <Divider spacing="1rem 0 0 0" />}
        </>
      )}
      {countDisallowedIssues > 0 && (
        <InsightTooltipMessageContainer>
          <ErrorOutline color="primary" style={{ width: "16px" }} />
          <Typography variant="text">
            This same issue appeared on {countDisallowedIssues} other service
            {countDisallowedIssues > 1 && "s"} that you don’t have permission to
            access.
          </Typography>
        </InsightTooltipMessageContainer>
      )}
    </>
  );
};
