import React from "react";

import { RecommendationAccuracy } from "../../../../../../../../../common/RecommendationAccuracy/RecommendationAccuracy";
import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import { selectMetricsSupportAgentInfo } from "../../../../store/overviewPageStoreSelectors";
import { overviewPageInsightsAriaLabels } from "../../../../../../appViewAriaLabels";

const {
  potentialCostSaving: {
    recommendationAccuracy: recommendationAccuracyAriaLabel,
  },
} = overviewPageInsightsAriaLabels;

export const InsightsRecommendationAccuracyPrompt = () => {
  const { clustersWithInsufficientTime } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  if (clustersWithInsufficientTime.length === 0) return null;

  return (
    <RecommendationAccuracy
      clusters={{}}
      forceShowLabel={true}
      ariaLabel={recommendationAccuracyAriaLabel}
    />
  );
};
