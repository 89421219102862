import { useEffect, useMemo } from "react";

import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  addAvailableActionSelector,
  issueSelector,
  setDeployForRollbackSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { AvailableActions } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";

import { CorrelatedDeploysOutput } from "./types";
import { useDeployByServiceId } from "./useDeployByServiceId";
import { useDeploysById } from "./useDeploysById";

export const useCanRollbackDeploy = (): void => {
  const issue = useInvestigationModeStore(issueSelector);
  const addAvailableAction = useInvestigationModeStore(
    addAvailableActionSelector
  );
  const setDeployForRollback = useInvestigationModeStore(
    setDeployForRollbackSelector
  );

  const correlatedDeploys: CorrelatedDeploysOutput = issue?.results
    ?.correlatedDeploys?.output ?? {
    correlatedDeploys: [],
  };
  const [deploys] = useDeploysById(
    correlatedDeploys?.correlatedDeploys?.map((deploy) => deploy.id)
  );

  const lastDeployResponse = useDeployByServiceId({
    serviceIds: deploys?.[0]?.services ?? [],
    limit: 1,
    fields: ["id", "eventTime", "generation"],
  });

  const lastDeploy = useMemo(
    () => lastDeployResponse?.[0],
    [lastDeployResponse]
  );

  const deployForRollback = useMemo(
    () => deploys.find((deploy) => deploy.id === lastDeploy?.id),
    [deploys, lastDeploy]
  );

  useEffect(() => {
    if (
      deployForRollback &&
      deployForRollback?.generation !== undefined &&
      deployForRollback.generation > 1
    ) {
      addAvailableAction(AvailableActions.Rollback);
      setDeployForRollback(deployForRollback);
    }
  }, [
    addAvailableAction,
    deploys?.length,
    deployForRollback,
    lastDeploy,
    setDeployForRollback,
  ]);
};
