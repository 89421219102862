import { useMemo } from "react";
import { subYears } from "date-fns";

import { useGetNodesCounts } from "@/shared/hooks/accounts-service/client/customers-pricing/useGetNodesCounts";
import { allMonths } from "@/components/Settings/General/NodesCount/consts";
import { getCurrentMonthIndex } from "@/pages/organization-settings/account/UsagePage/utils";

export const useUsagePage = () => {
  const oneYearBack = useMemo(() => {
    const currentDate = new Date();
    return subYears(currentDate, 1).toISOString();
  }, []);

  const { data, isFetching, isError } = useGetNodesCounts({
    collectedFrom: String(oneYearBack.valueOf()),
  });

  // extract unique clusters
  const clusters = useMemo(() => {
    return Array.from(
      new Set(
        (data || [])
          .map((item) => item.clusterName)
          .filter((name): name is string => !!name)
      )
    );
  }, [data]);

  const currentMonthIndex = useMemo(() => getCurrentMonthIndex(), []);

  const sortedMonths = useMemo(() => {
    return currentMonthIndex === -1
      ? allMonths
      : [
          ...allMonths.slice(currentMonthIndex),
          ...allMonths.slice(0, currentMonthIndex),
        ];
  }, [currentMonthIndex]);

  return { clusters, data, isFetching, isError, sortedMonths };
};
