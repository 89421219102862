import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { Scope } from "../../../../../../../../generated/reliabilityApi";
import { ALL_SELECTOR } from "../../../../../../constants/reliabilityConstants";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import { selectAddEditIgnoreRuleDialogState } from "../../../../../../store/reliabilityStoreSelectors";
import {
  getInitialValues,
  getNamespaceOptions,
  getServiceOptions,
  toMultiOption,
} from "../addEditRuleDialogUtils";
import { useAddEditRuleContext } from "../context/useAddEditRuleContext";
import {
  ClustersMap,
  NamespaceOptionsProps,
  ServiceOptionsProps,
  UseFoundResourcesProps,
} from "../addEditRuleTypes";

const allOption = toMultiOption(ALL_SELECTOR);

const useClustersMap = () => {
  const { clustersMap } = useAddEditRuleContext();
  return clustersMap;
};

const useGetClusterOptions = (): MuiSelectionOption<string>[] => {
  const clustersMap = useClustersMap();

  return useMemo(() => {
    return [allOption, ...Object.keys(clustersMap ?? {}).map(toMultiOption)];
  }, [clustersMap]);
};

const getSelectedClusters = (
  clustersMap: ClustersMap,
  selectedClusters: string[] | undefined
) => {
  if (!selectedClusters) return [];
  const clusters = selectedClusters.includes(ALL_SELECTOR)
    ? Object.values(clustersMap)
    : selectedClusters.map((cluster) => clustersMap[cluster]);

  return clusters.filter(Boolean);
};

const useNamespaceOptions = ({
  clusters,
  isExternalScope,
}: NamespaceOptionsProps): MuiSelectionOption<string>[] => {
  const clustersMap = useClustersMap();

  return useMemo(() => {
    if (!clustersMap || !clusters.length) return [];

    const selectedClusters = getSelectedClusters(clustersMap, clusters);
    const namespaceOptions = getNamespaceOptions(selectedClusters);

    if (isExternalScope && !namespaceOptions.length) {
      // external scope was not found in local resources
      const allNamespaceOptions = getNamespaceOptions(
        Object.values(clustersMap)
      );
      return [allOption, ...allNamespaceOptions];
    }

    return [allOption, ...namespaceOptions];
  }, [clusters, clustersMap, isExternalScope]);
};

const useServiceOptions = ({
  clusters,
  namespaces,
  isExternalScope,
}: ServiceOptionsProps) => {
  const clustersMap = useClustersMap();

  return useMemo(() => {
    if (!clustersMap || !clusters.length) return [];

    const selectedClusters = getSelectedClusters(clustersMap, clusters);
    const serviceOptions = getServiceOptions(selectedClusters, namespaces);
    if (isExternalScope && !serviceOptions.length) {
      // external scope was not found in local resources
      const allServiceOptions = getServiceOptions(Object.values(clustersMap), [
        ALL_SELECTOR,
      ]);
      return [allOption, ...allServiceOptions];
    }

    return [allOption, ...serviceOptions];
  }, [clusters, clustersMap, isExternalScope, namespaces]);
};

export const useMultiSelectProps = (type: keyof Scope) => {
  const {
    selectedClusterNames,
    selectedNamespaces,
    setSelectedNamespaces,
    setSelectedServices,
    setSelectedClusterNames,
  } = useAddEditRuleContext();

  const scope = useExistingRuleScope();

  const { clusterNamesToUse, namespacesToUse, shortResourceNamesToUse } =
    useInitialValues();

  const clusterOptions = useGetClusterOptions();
  const namespaceOptions = useNamespaceOptions({
    clusters: clusterNamesToUse,
    isExternalScope: !!scope?.clusterNames,
  });

  const serviceOptions = useServiceOptions({
    clusters: clusterNamesToUse,
    namespaces: namespacesToUse,
    isExternalScope: !!scope?.shortResourceNames,
  });

  const foundClusters = useFoundResources({
    type,
    resources: clusterNamesToUse,
    options: clusterOptions,
  });
  const foundNamespaces = useFoundResources({
    type,
    resources: namespacesToUse,
    options: namespaceOptions,
  });
  const foundServices = useFoundResources({
    type,
    resources: shortResourceNamesToUse,
    options: serviceOptions,
  });

  return useMemo(() => {
    switch (type) {
      case "clusterNames": {
        return {
          options: clusterOptions,
          values: foundClusters,
          onUpdate: setSelectedClusterNames,
        };
      }
      case "namespaces": {
        return {
          options: selectedClusterNames.length ? namespaceOptions : [],
          values: selectedClusterNames.length ? foundNamespaces : [],
          onUpdate: setSelectedNamespaces,
        };
      }
      case "shortResourceNames": {
        return {
          options: selectedNamespaces.length ? serviceOptions : [],
          values: selectedNamespaces.length ? foundServices : [],
          onUpdate: setSelectedServices,
        };
      }
    }
  }, [
    clusterOptions,
    foundClusters,
    foundNamespaces,
    foundServices,
    namespaceOptions,
    selectedClusterNames,
    selectedNamespaces.length,
    serviceOptions,
    setSelectedClusterNames,
    setSelectedNamespaces,
    setSelectedServices,
    type,
  ]);
};

const useInitialValues = () => {
  const { selectedClusterNames, selectedNamespaces, selectedServices } =
    useAddEditRuleContext();
  const scope = useExistingRuleScope();

  const clusterNamesToUse = getInitialValues(
    selectedClusterNames,
    scope?.clusterNames
  );

  const namespacesToUse = getInitialValues(
    selectedNamespaces,
    scope?.namespaces
  );

  const shortResourceNamesToUse = getInitialValues(
    selectedServices,
    scope?.shortResourceNames
  );

  return {
    clusterNamesToUse,
    namespacesToUse,
    shortResourceNamesToUse,
  };
};

const useFoundResources = ({
  resources,
  type,
  options,
}: UseFoundResourcesProps): string[] => {
  const scope = useExistingRuleScope();

  return useMemo(() => {
    const result = resources.filter((service) => {
      return options.some((option) => option.value === service);
    });

    if (type === "clusterNames") {
      // don't add all selector automatically to clusterNames
      return result;
    }

    if (result.length === 0 && !scope?.[type]) {
      return [ALL_SELECTOR];
    }

    return result;
  }, [resources, scope, type, options]);
};

const useExistingRuleScope = () => {
  const { existingIgnoreRule } = useReliabilityStore(
    selectAddEditIgnoreRuleDialogState
  );

  const { scope } = existingIgnoreRule ?? {};

  return scope;
};
