import React from "react";

import { IconProps } from "../types";

export const InfraHealth24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      d="M7.5 16C7.08333 16 6.72917 16.1458 6.4375 16.4375C6.14583 16.7292 6 17.0833 6 17.5C6 17.9167 6.14583 18.2708 6.4375 18.5625C6.72917 18.8542 7.08333 19 7.5 19C7.91667 19 8.27083 18.8542 8.5625 18.5625C8.85417 18.2708 9 17.9167 9 17.5C9 17.0833 8.85417 16.7292 8.5625 16.4375C8.27083 16.1458 7.91667 16 7.5 16Z"
      fill={svgProps.fill}
    />
    <path
      d="M7.5 5C7.08333 5 6.72917 5.14583 6.4375 5.4375C6.14583 5.72917 6 6.08333 6 6.5C6 6.91667 6.14583 7.27083 6.4375 7.5625C6.72917 7.85417 7.08333 8 7.5 8C7.91667 8 8.27083 7.85417 8.5625 7.5625C8.85417 7.27083 9 6.91667 9 6.5C9 6.08333 8.85417 5.72917 8.5625 5.4375C8.27083 5.14583 7.91667 5 7.5 5Z"
      fill={svgProps.fill}
    />
    <path
      d="M10.4375 5.4375C10.7292 5.14583 11.0833 5 11.5 5C11.9167 5 12.2708 5.14583 12.5625 5.4375C12.8542 5.72917 13 6.08333 13 6.5C13 6.91667 12.8542 7.27083 12.5625 7.5625C12.2708 7.85417 11.9167 8 11.5 8C11.0833 8 10.7292 7.85417 10.4375 7.5625C10.1458 7.27083 10 6.91667 10 6.5C10 6.08333 10.1458 5.72917 10.4375 5.4375Z"
      fill={svgProps.fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2H20C20.2833 2 20.5208 2.09583 20.7125 2.2875C20.9042 2.47917 21 2.71667 21 3V10C21 10.2833 20.9042 10.5208 20.7125 10.7125C20.5208 10.9042 20.2833 11 20 11H4C3.71667 11 3.47917 10.9042 3.2875 10.7125C3.09583 10.5208 3 10.2833 3 10V3C3 2.71667 3.09583 2.47917 3.2875 2.2875C3.47917 2.09583 3.71667 2 4 2ZM5 4V9H19V4H5Z"
      fill={svgProps.fill}
    />
    <path
      d="M21 14.63C20.974 14.6084 20.9475 14.5872 20.9206 14.5665C20.4627 14.2136 19.8872 14 19.25 14C18.9685 14 18.7088 14.0395 18.471 14.109C18.3391 14.1475 18.2139 14.1952 18.0954 14.2506C17.7238 14.4241 17.4184 14.6724 17.1793 14.9449L17.1714 14.9539C17.1581 14.9692 17.145 14.9846 17.1321 15H19V16.0405C19.0764 16.0147 19.1589 16 19.25 16C19.65 16 20 16.32 20 16.75C20 16.9644 19.8546 17.2239 19.6111 17.5157L19.6049 17.5232C19.5025 17.6453 19.383 17.7731 19.25 17.9057C19.2198 17.9357 19.189 17.966 19.1575 17.9965C19.1067 18.0457 19.0541 18.0956 19 18.146V20H14.074C14.1662 20.0817 14.2607 20.164 14.357 20.247C14.6462 20.4962 14.9529 20.7519 15.2717 21.0178C15.6454 21.3294 16.0358 21.6549 16.4341 22H17.5659C17.9642 21.6549 18.3545 21.3294 18.7282 21.0178C19.047 20.752 19.3538 20.4962 19.643 20.247C20.1623 19.7996 20.625 19.3734 21 18.9478V14.63Z"
      fill={svgProps.fill}
    />
    <path
      d="M11.4636 13H4C3.71667 13 3.47917 13.0958 3.2875 13.2875C3.09583 13.4792 3 13.7167 3 14V21C3 21.2833 3.09583 21.5208 3.2875 21.7125C3.47917 21.9042 3.71667 22 4 22H13.33C12.9185 21.6514 12.5119 21.2962 12.1427 20.9395C11.9442 20.7476 11.6998 20.5399 11.4358 20.3156C11.3161 20.2138 11.1924 20.1087 11.0671 20H5V15H9.4834C9.92329 14.2145 10.6301 13.5215 11.4636 13Z"
      fill={svgProps.fill}
    />
    <path
      d="M12.6317 18.489C12.2331 17.9355 12 17.3707 12 16.75C12 16.5308 12.0265 16.3159 12.0766 16.1088C12.2543 16.1817 12.4163 16.2913 12.5625 16.4375C12.8542 16.7292 13 17.0833 13 17.5C13 17.8823 12.8772 18.212 12.6317 18.489Z"
      fill={svgProps.fill}
    />
    <path
      d="M12.6318 15C12.7639 14.8396 12.9139 14.694 13.0794 14.5665C13.5372 14.2136 14.1128 14 14.75 14C15.0315 14 15.2912 14.0395 15.529 14.109C15.6609 14.1475 15.7861 14.1952 15.9046 14.2506C16.2762 14.4241 16.5816 14.6724 16.8207 14.9449L16.8286 14.9539C16.8419 14.9692 16.855 14.9846 16.8679 15H12.6318Z"
      fill={svgProps.fill}
    />
    <path
      d="M19.25 14C18.19 14 17.44 14.56 17 15.17C16.56 14.56 15.81 14 14.75 14C13.19 14 12 15.28 12 16.75C12 18.75 14.42 20.17 17 22.5C19.58 20.17 22 18.75 22 16.75C22 15.28 20.81 14 19.25 14ZM17 19.85C15.55 18.63 14 17.45 14 16.75C14 16.32 14.35 16 14.75 16C15.06 16 15.27 16.17 15.48 16.37L17 17.8L18.52 16.37C18.73 16.17 18.94 16 19.25 16C19.65 16 20 16.32 20 16.75C20 17.45 18.45 18.63 17 19.85Z"
      fill={svgProps.fill}
    />
  </svg>
);

InfraHealth24.defaultProps = {
  fill: "currentColor",
};
