import React, { useMemo } from "react";
import { Label, ReferenceLine } from "recharts";

import { EventData } from "../types";
import { GetEventIcon } from "../graphs/EventsGraph";

export const useGraphEvents = (events: EventData[] | undefined) => {
  return useMemo(
    () =>
      events?.map((event, index) => (
        <ReferenceLine
          key={`${event.time}_${index}`}
          x={event.time}
          stroke={event.highlight ? event.color : "transparent"}
          label={
            <Label
              position="top"
              offset={50}
              content={(props) => <GetEventIcon {...props} event={event} />}
            />
          }
        />
      )),
    [events]
  );
};
