import React from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  correlatedMetricsSelector,
  isMetricsSupportedSelector,
  issueSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { Metrics } from "../../../Metrics/Metrics";
import { Container, MetricsContainer } from "../common/styles";
import {
  useIssueMetricsEndTime,
  useIssueMetricsEvents,
} from "../useCorrelatedMetrics";
import { MetricsGraphType } from "../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../metricsHooks";
import { useScrollTracking } from "../common/useScrollTracking";
import { useDDRumStartViewAndAddTiming } from "../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { MetricsTitle } from "../common/MetricsTitle";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import memoryLeakIcon1 from "./icons/leak-suspected-1.svg";
import memoryLeakIcon2 from "./icons/leak-suspected-2.svg";
import noMemoryLeakIcon1 from "./icons/leak-unsuspected-1.svg";
import noMemoryLeakIcon2 from "./icons/leak-unsuspected-2.svg";
import { MemoryLeakOutput } from "./types";
import { ImageContainer, Images, ListItem, StyledImg } from "./styles";

const MemoryLeak: React.FC = () => {
  const issue = useInvestigationModeStore(issueSelector);
  const podMetrics = useInvestigationModeStore(correlatedMetricsSelector);
  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );
  const { nextStep, goToNextStep } = useNextStep();
  const memoryLeakData: MemoryLeakOutput = issue?.results.memoryLeak
    ?.output ?? {
    hasMetrics: false,
    podName: "",
    containerName: "",
    metricsAllowed: false,
  };
  const metricsEndTime = useIssueMetricsEndTime(issue);
  const issueMetricsEvents = useIssueMetricsEvents(issue?.eventTime);
  const metricsAggType = useMetricsDefaultAggregationType();
  const elementRef = useScrollTracking<HTMLDivElement>();

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.investigationModeMemoryLeak,
    addTimingParams: {
      enable: !!issue && !!podMetrics,
    },
  });

  if (!issue) {
    return null;
  }
  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Ensure your application doesn’t have a memory leak
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          Review the memory usage graph below and read the tips for what might
          indicate a memory leak problem
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        <MetricsContainer>
          <MetricsTitle
            kind="container"
            metadata={[
              { title: "pod", value: memoryLeakData.podName },
              { title: "container", value: memoryLeakData.containerName },
            ]}
          />
          {podMetrics && (memoryLeakData.hasMetrics || !isMetricsSupported) && (
            <Metrics
              isMetricsSupported={isMetricsSupported}
              endTimestamp={metricsEndTime}
              events={issueMetricsEvents}
              metrics={podMetrics}
              graphType={MetricsGraphType.CONTAINER}
              aggregationType={metricsAggType}
              paddingInMinutes={10}
            />
          )}
        </MetricsContainer>
        <Container>
          <ImageContainer>
            <Typography variant="overline" color={muiColors.gray[600]}>
              Memory Leak
            </Typography>
            <Typography variant="overline" color={muiColors.gray[600]}>
              No Leak
            </Typography>
            <Images>
              <StyledImg src={memoryLeakIcon1} alt="memoryLeakIcon1" />
              <StyledImg src={memoryLeakIcon2} alt="memoryLeakIcon2" />
            </Images>
            <Images>
              <StyledImg src={noMemoryLeakIcon1} alt="noMemoryLeakIcon1" />
              <StyledImg src={noMemoryLeakIcon2} alt="noMemoryLeakIcon2" />
            </Images>
          </ImageContainer>
          <ListItem>
            A memory usage graph with a continuously increasing pattern can
            indicate a memory leak
          </ListItem>
          <ListItem>
            If the application demand (i.e. user traffic) has been gradually
            increasing, this may rule-out a memory leak and instead point to the
            need to increase the memory request/limit
          </ListItem>
        </Container>
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.MemoryLeakStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MemoryLeak;
