import { Skeleton } from "@komodorio/design-system/deprecated";
import React, { memo } from "react";

import { AriaLabels } from "../../shared/config/ariaLabels";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ServicesGrid } from "./ExploreKomodorServices";

export const ServicesLoader: React.FC = memo(() => (
  <ServicesGrid
    tileWidth="16rem"
    aria-label={AriaLabels.ExploreKomodorServices.Loader}
  >
    {Array.from(Array(25).keys()).map((i) => (
      <Skeleton key={i} height="12rem" width="16rem" />
    ))}
  </ServicesGrid>
));
