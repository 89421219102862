import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import {
  useAppViewsStore,
  WORKSPACE_ID_URL_PARAM,
} from "@/shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { ACCOUNT_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";

type ExcludeFromLocalStorage = {
  paramKey: string;
  originalValue?: string;
};
export type QueryStringInLocalStorageParams = {
  item: LocalStorageItem | undefined;
  toSessionStorage?: string[];
  excludeFromLocalStorage?: ExcludeFromLocalStorage[];
};

export const useQueryStringInLocalStorage = ({
  item,
  toSessionStorage,
  excludeFromLocalStorage,
}: QueryStringInLocalStorageParams): void => {
  const [prevPathname, setPrevPathname] = useState("");
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentWorkspaceId = useAppViewsStore(selectedAppViewIdSelector);
  const { accountId } = useUserMetadata();

  const [localSearch, sessionSearch] = useMemo(() => {
    const localSearchParams = new URLSearchParams(search);
    localSearchParams.delete(ACCOUNT_PARAM_KEY);
    localSearchParams.delete(WORKSPACE_ID_URL_PARAM);
    excludeFromLocalStorage?.forEach(({ paramKey }) => {
      localSearchParams.delete(paramKey);
    });
    const sessionSearchParams = new URLSearchParams();
    toSessionStorage?.forEach((paramKey) => {
      const paramVal = localSearchParams.get(paramKey);
      if (paramVal !== null) {
        localSearchParams.delete(paramKey);
        sessionSearchParams.set(paramKey, paramVal);
      }
    });
    return [localSearchParams.toString(), sessionSearchParams.toString()];
  }, [excludeFromLocalStorage, search, toSessionStorage]);

  useEffect(() => {
    if (!item) return;
    if (localSearch || sessionSearch) {
      if (localSearch) item.set(localSearch);
      if (sessionSearch) sessionStorage.setItem(item.key, sessionSearch);
    } else if (prevPathname === pathname) {
      item.remove();
      sessionStorage.removeItem(item.key);
    } else {
      const localStoredSearch = item.get();
      const sessionStoredSearch = sessionStorage.getItem(item.key);
      const newSearch =
        localStoredSearch && sessionStoredSearch
          ? `${localStoredSearch}&${sessionStoredSearch}`
          : localStoredSearch
          ? localStoredSearch
          : sessionStoredSearch;
      if (newSearch) {
        const searchParams = new URLSearchParams(newSearch);
        searchParams.set(ACCOUNT_PARAM_KEY, accountId);
        searchParams.set(WORKSPACE_ID_URL_PARAM, currentWorkspaceId ?? "null");
        excludeFromLocalStorage?.forEach(({ paramKey, originalValue }) => {
          originalValue && searchParams.set(paramKey, originalValue);
        });
        navigate(
          {
            search: searchParams.toString(),
          },
          { replace: true }
        );
      }
    }
    setPrevPathname(pathname);
  }, [
    navigate,
    item,
    pathname,
    prevPathname,
    localSearch,
    sessionSearch,
    currentWorkspaceId,
    accountId,
    excludeFromLocalStorage,
  ]);
};
