import { muiColors, palette } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { Typography } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import CardWithTag from "../../../common/Card/CardWithTag";
import { SubReasonContainerLimitReached } from "../types";

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const OOMKilledExplanation: React.FC<{
  subReason?: string;
}> = ({ subReason }) => {
  const { limitColor, tagText } =
    subReason === SubReasonContainerLimitReached
      ? { limitColor: muiColors.orange[600], tagText: "LIKELY SUSPECT" }
      : { limitColor: muiColors.gray[200], tagText: undefined };

  return (
    <>
      <Typography
        size="large"
        color={palette.gray[700]}
        style={{ whiteSpace: "break-spaces" }}
      >
        There are generally two kinds of OOMKilled errors:
      </Typography>
      <CardsContainer>
        <CardWithTag color={muiColors.gray[200]} preserveTagHeight>
          <MuiTypography variant="h4" style={{ whiteSpace: "break-spaces" }}>
            Node’s Memory Pressure
          </MuiTypography>
          <Typography
            size="large"
            color={muiColors.gray[500]}
            style={{ whiteSpace: "break-spaces" }}
          >
            This is a Node related issue where the total limits of all Pods on
            the Node exceeds the Node’s capacity and so it starts killing Pods
            based on a priority score. This is sometimes referred to as a “noisy
            neighbor” problem.
          </Typography>
        </CardWithTag>
        <CardWithTag color={limitColor} tagText={tagText} preserveTagHeight>
          <MuiTypography variant="h4" style={{ whiteSpace: "break-spaces" }}>
            Container Limit Reached
          </MuiTypography>
          <Typography
            size="large"
            color={muiColors.gray[500]}
            style={{ whiteSpace: "break-spaces" }}
          >
            This is the more common scenario where the container’s Memory Limit
            is breached. This only impacts one pod, unlike the Memory Pressure
            error, which has an impact on the node’s entire memory capacity.
          </Typography>
        </CardWithTag>
      </CardsContainer>
    </>
  );
};

export const ProbExplanation: React.FC = () => {
  return (
    <>
      <CardsContainer>
        <CardWithTag
          color={muiColors.orange[600]}
          tagText={"LIKELY SUSPECT"}
          preserveTagHeight
        >
          <MuiTypography variant="h4" style={{ whiteSpace: "break-spaces" }}>
            Application error
          </MuiTypography>
          <Typography
            size="large"
            color={muiColors.gray[500]}
            style={{ whiteSpace: "break-spaces" }}
          >
            The application running in the container is experiencing errors,
            slowdowns or another issue, causing it to fail the readiness probe
            configured for it.
          </Typography>
        </CardWithTag>
        <CardWithTag color={muiColors.gray[200]} preserveTagHeight>
          <MuiTypography variant="h4" style={{ whiteSpace: "break-spaces" }}>
            High application load
          </MuiTypography>
          <Typography
            size="large"
            color={muiColors.gray[500]}
            style={{ whiteSpace: "break-spaces" }}
          >
            An application experiencing high load may consistently fail
            readiness probes.
          </Typography>
        </CardWithTag>
        <CardWithTag color={muiColors.gray[200]} preserveTagHeight>
          <MuiTypography variant="h4" style={{ whiteSpace: "break-spaces" }}>
            Misconfigured readiness probe
          </MuiTypography>
          <Typography
            size="large"
            color={muiColors.gray[500]}
            style={{ whiteSpace: "break-spaces" }}
          >
            Improperly configured readiness probes could fail regardless of an
            application’s actual health, causing pods to be marked unready.
          </Typography>
        </CardWithTag>
      </CardsContainer>
    </>
  );
};
