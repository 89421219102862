import { podStatusToGroups } from "../../../../common/statusMapper";

export const podStatusMapper = (): {
  [k: string]: {
    bg: string;
    fg: string;
    border?: string;
  };
} =>
  Object.fromEntries(
    Object.entries(podStatusToGroups).map(([key, value]) => {
      return [
        key.toUpperCase(),
        { bg: value.backgroundColor, fg: value.fillColor },
      ];
    })
  );
