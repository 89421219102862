import React, { useState } from "react";

import RerunJobModal from "../modals/RerunJobModal/RerunJobModal";

import { ActionButtonProps } from "./types";

import Action from ".";

export const RerunJobButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  return (
    <>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={handleClick}
        Button={Button}
      >
        {open && (
          <RerunJobModal
            isModalOpen={open}
            handleClose={handleClose}
            resourceId={resource.id}
            resourceName={resource.name}
            resourceType={resource.kind}
            namespace={resource.namespace}
            cluster={resource.cluster}
            agentId={resource.agentId}
          />
        )}
      </Action>
    </>
  );
};
