import React, { ComponentProps, useMemo } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";

import { selectAriaLabels } from "../ariaLabels";
import { selectSize } from "../../../../../constants";
import { Size } from "../../../../../types";
import { lightMuiTooltipStyle } from "../../../../../styles/styles";
import IconButton from "@mui/material/IconButton";
import { Close16 } from "../../../../../icons";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { muiTheme } from "../../../../../themes";

const ACTIONS_CONTAINER_WIDTHS = {
  withClear: 68,
  withoutClear: 44,
};

const CONTAINER_STYLE = {
  width: "100%",
  border: "1px solid",
  borderColor: muiTheme.palette.grey[300],
  borderRadius: "4px",
  boxSizing: "border-box",
  backgroundColor: muiTheme.palette.common.white,
  display: "flex",
  cursor: "pointer",
  transition: "border-color 0.2s ease-in-out",

  "&:hover": {
    borderColor: muiTheme.palette.grey[600],
  },
};

type DropDownTriggerFieldProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  dropDownOpen: boolean;
  enableLineBreaks?: boolean;
  className?: string;
  size: Size;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: ComponentProps<typeof Tooltip>["placement"];
  clearable?: boolean;
  onClear?: () => void;
};

export const DropDownTriggerField: React.FC<DropDownTriggerFieldProps> = ({
  children,
  onClick,
  dropDownOpen,
  className,
  enableLineBreaks,
  size,
  disabled,
  tooltip = "",
  tooltipPlacement = "top",
  clearable = false,
  onClear,
}) => {
  const containerHeightStyling = useMemo(
    () => ({
      height: enableLineBreaks ? "auto" : `${selectSize[size]}px`,
      minHeight: enableLineBreaks ? `${selectSize[size]}px` : "unset",
    }),
    [enableLineBreaks, size]
  );

  const arrowStyle = useMemo(
    () => ({
      marginRight: "12px",
      "& > svg": {
        color: muiTheme.palette.action.active,
        transition: "transform 0.2s ease-in-out",
        transform: dropDownOpen ? "rotate(180deg)" : "rotate(0deg)",
      },
    }),
    [dropDownOpen]
  );

  const valueMaxWidth = useMemo(
    () =>
      `calc(100% - ${
        clearable
          ? ACTIONS_CONTAINER_WIDTHS.withClear
          : ACTIONS_CONTAINER_WIDTHS.withoutClear
      }px)`,
    [clearable]
  );

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClear?.();
  };

  return (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement}
      componentsProps={lightMuiTooltipStyle}
    >
      <Box component="span" sx={{ pointerEvents: "auto" }}>
        <Stack
          direction="row"
          alignItems="center"
          onClick={onClick}
          className={className}
          aria-label={selectAriaLabels.dropDownList}
          sx={{
            ...CONTAINER_STYLE,
            ...containerHeightStyling,
            pointerEvents: disabled ? "none" : "auto",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              padding: "0 4px",
              position: "relative",
              width: "100%",
              maxWidth: valueMaxWidth,
            }}
          >
            {children}
          </Stack>
          <Stack
            sx={{ marginLeft: "auto" }}
            direction="row"
            alignItems="center"
          >
            {clearable && (
              <IconButton
                aria-label={selectAriaLabels.clearButton}
                onClick={handleClear}
              >
                <Close16 />
              </IconButton>
            )}
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              sx={arrowStyle}
            >
              <ArrowDropDownIcon />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Tooltip>
  );
};

DropDownTriggerField.displayName = "DropDownTriggerField";
