import React from "react";

import { DescribePanel } from "../common/DescribeSection";
import { DescribeItem } from "../common/DescribeItem";
import { GrayTag } from "../common/DescribeTagListItem";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Secret from "../../../resources/secret";
import { DescribeMainPanelData } from "../common/DescribeMainPanelData";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { SecretData } from "./SecretData";

interface SecretDescribeTabProps {
  secret: Secret;
}

const SecretDescribeTab: React.FC<SecretDescribeTabProps> = ({ secret }) => {
  return (
    <DescribeTabContentWrapper resource={secret}>
      <DescribePanel>
        <DescribeMainPanelData resource={secret}>
          <DescribeItem name="Type">
            <GrayTag key={secret.type}>{secret.type}</GrayTag>
          </DescribeItem>
        </DescribeMainPanelData>
      </DescribePanel>

      <SecretData secretData={secret.data} />
    </DescribeTabContentWrapper>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SecretDescribeTab;
