import Resource from "../../components/ResourceView/resources/index";

export type Uid = {
  name: string;
  namespace: string;
  cluster: string;
};

export const generateUid = (resource: Uid): string => {
  const { name, namespace, cluster } = resource;
  return `${cluster};${namespace};${name}`;
};

export const deconstructUid = (uid: string): Uid => {
  const [cluster, namespace, name] = uid.split(";");
  return { name, namespace, cluster };
};

export const getUid = (resource: Resource): Uid => {
  return {
    name: resource.name,
    cluster: resource.cluster,
    namespace: resource.namespace,
  };
};
