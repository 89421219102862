import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

type MockDrawerProps = {
  open: boolean;
  onClose: () => void;
  imgSrc: string | null;
  children?: React.ReactNode;
  closeButton: React.ReactNode;
};
export const MockDrawer: React.FC<MockDrawerProps> = ({
  open,
  onClose,
  imgSrc,
  children,
  closeButton,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <Box position="relative">
        {imgSrc && <img src={imgSrc} width={864} />}
        {closeButton}
        {children}
      </Box>
    </Drawer>
  );
};
