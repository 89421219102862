import { useMemo } from "react";

import { useGroups } from "../../../hooks/reliabilityHooks";
import { ViolationsAggregationGroup } from "../../../../../generated/reliabilityApi";

export const useGetImpactGroupData = (
  impactGroup: string
): ViolationsAggregationGroup | undefined => {
  const { data } = useGroups();

  return useMemo(
    () => data?.data.groups.find((group) => group.id === impactGroup),
    [data?.data.groups, impactGroup]
  );
};
