import { useState, useMemo } from "react";

export const useMouseHover = () => {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [mouseOver, setMouseOver] = useState(false);

  return useMemo(() => {
    return {
      mousePos,
      setMousePos,
      mouseOver,
      setMouseOver,
    };
  }, [mousePos, setMousePos, mouseOver, setMouseOver]);
};
