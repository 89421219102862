import React, { useMemo } from "react";

import { Header } from "../common/Header/Header";
import { Label } from "../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../resources/deployment";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DaemonSet from "../resources/daemonset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import StatefulSet from "../resources/statefulset";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Rollout from "../resources/rollout";

import { KomodorServiceStatuses } from "./KomodorServiceStatuses";
import { useGetContainerImages } from "./utils/hooks/useGetContainerImages";
import { getFormattedImage } from "./utils/utils";
import { useGetImageLabel } from "./utils/hooks/useGetImageLabel";

interface KomodorServiceHeaderProps {
  resource: Deployment | DaemonSet | StatefulSet | Rollout;
  labels: Label[];
  leftActions?: JSX.Element;
}

export const KomodorServiceHeader: React.FC<KomodorServiceHeaderProps> = ({
  resource,
  labels,
  leftActions,
}) => {
  const containers = resource.containers;

  const resourceImages = useGetContainerImages({
    serviceId: resource.id,
  });

  const imageList = useMemo(
    () => containers?.map((container) => getFormattedImage(container.image)),
    [containers]
  );

  const images = containers?.length ? imageList : resourceImages;

  const imageLabel = useGetImageLabel(images);

  const labelsWithImages = [...labels, ...(imageLabel ? [imageLabel] : [])];

  return (
    <>
      <Header
        status={undefined}
        resource={resource}
        labels={labelsWithImages}
        disableActions={resource.isDeleted || resource.inactive}
        leftActions={leftActions}
      />
      <KomodorServiceStatuses resource={resource} />
    </>
  );
};
