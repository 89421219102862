import useGetAccountPlanData from "../useGetAccountPlanData";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { restrictionProps } from ".";

export const hasReachedClusterLimit = (
  clustersLimit: number | undefined | null,
  currentValue: number | undefined
): boolean => {
  // null means unlimited
  if (clustersLimit === null) return false;
  if (clustersLimit === undefined) return true;
  if (currentValue === undefined) return true;
  return currentValue >= clustersLimit;
};

export const useClustersRestrictionsData = (): restrictionProps => {
  const plansData = useGetAccountPlanData();
  return {
    modalTitle: "Upgrade to get unlimited clusters",
    modalContent: `You are now using a Free Plan. \n In order to get more than ${plansData?.clustersLimit} cluster integrations, \nyou have to upgrade.`,
  };
};
