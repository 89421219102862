import { ResourceTableModel } from "komodor-types";

export const INSPECTION_ERROR =
  "We were unable to fetch your data, try reloading the page";

export const INSPECTION_PERMISSIONS_ERROR =
  "We don't have the right permissions to fetch this resource yet";

export const INSPECTION_USER_PERMISSIONS_ERROR =
  "You don't have the right permissions to view this resource";
export interface InspectionResourceList {
  table: ResourceTableModel;
  emptyResult: boolean;
  fetching: boolean;
  errorMessage: string;
  isPaginating?: boolean;
}
