import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  UsersApiApiV1UsersIdPutRequest,
  User,
  apiV1UsersIdPutUrl,
} from "../../../../../generated/auth";

const UPDATE_USER_PATH = "/users/:id";

const updateUser = async (
  apiClient: AxiosInstance,
  params: UsersApiApiV1UsersIdPutRequest
): Promise<User> => {
  const { data } = await apiClient.put(
    apiV1UsersIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.updateUserRequest
  );
  return data;
};

export const useUpdateUser = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [UPDATE_USER_PATH],
    (params: UsersApiApiV1UsersIdPutRequest) => updateUser(apiClient, params)
  );
};
