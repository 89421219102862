import { useMemo } from "react";

import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useAgentInfoById } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { useActiveAgent } from "../../../shared/hooks/useAgents";
import { doesAgentVersionSupportResourceListFromATM } from "../../../shared/utils/agent/agent";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceResourceKind } from "../../../shared/types/Resource";

export const useAtmInServiceTabs = (
  resource: KomodorServiceResourceKind
): boolean | undefined => {
  const { inspectionAtm } = useOverridableFlags();
  const agentId = useActiveAgent(resource.cluster);
  const { agentProperties: agentInfo } = useAgentInfoById(
    agentId,
    resource.cluster
  );

  return useMemo(() => {
    if (!agentInfo || !resource.cluster || !agentId) return undefined;
    return (
      inspectionAtm !== false &&
      doesAgentVersionSupportResourceListFromATM(agentInfo)
    );
  }, [agentId, agentInfo, inspectionAtm, resource.cluster]);
};
