import React from "react";
import { capitalize } from "lodash";
import Typography from "@mui/material/Typography";

import { GenericAuditData } from "../types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

import { Audit } from "@/generated/graphql";

interface RbacClusterForceSyncAuditData extends GenericAuditData {
  details: {
    clusterNames: string[];
    error?: string | null;
  };
}

export class AuditRbacClusterForceSync implements AuditObject {
  readonly data: Audit;
  readonly auditData: RbacClusterForceSyncAuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as RbacClusterForceSyncAuditData;
  }

  renderDetails() {
    return (
      <>
        <Typography variant="h4">Clusters scheduled to synchronize:</Typography>
        <>
          {this.auditData.details.clusterNames.length === 0
            ? "N/A"
            : this.auditData.details.clusterNames.map((cluster, index) => (
                <Typography variant="body2" key={cluster}>
                  {cluster}
                </Typography>
              ))}
        </>
        {this.auditData.details.error && (
          <>
            <Typography variant="h4">Error during scheduling</Typography>
            <Typography variant="body2">
              {this.auditData.details.error}
            </Typography>
          </>
        )}
      </>
    );
  }

  getAffectedResource(): string {
    const resources = this.auditData?.details?.clusterNames;
    if (resources?.length > 3) {
      return `Clusters: ${resources.slice(0, 3).join(", ")} and ${
        resources.length - 3
      } more`;
    }
    return `Clusters: ${resources?.join(", ") || ""}`;
  }

  renderAffectedResources() {
    return this.getAffectedResource();
  }

  renderAction() {
    return this.auditData.category.toLowerCase().replaceAll("_", " ");
  }

  renderEventType() {
    return this.auditData.crudType.toLowerCase().replaceAll("_", " ");
  }

  renderJson(): Record<string, unknown> {
    const change = this.auditData.details?.clusterNames;
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": capitalize(this.data.crudType.toLowerCase()),
      Resource: "RBAC Cluster",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": this.getAffectedResource(),
      Change: JSON.stringify(change),
    };
  }
}
