import FetchDataLoading from "../common/loaders/LoadingState";
import { Panel } from "../Inspection/styles";
import { UnsupportedWorkspaceView } from "../k8sAddons/components/UnsupportedWorkspaceView";
import { useIsSupportedWorkspaceKind } from "../k8sAddons/hooks/useIsSupportedWorkspaceKind";
import { useAccountFeatures } from "../Settings/General/hooks/useFeatures";

import { ArgoMainTable } from "./ArgoMainTable";
import { ArgoWorkflowsFeaturePreview } from "./ArgoWorkflowsFeaturePreview";

export const ArgoWorkflowsRootView = () => {
  const { data: features, isLoading } = useAccountFeatures();
  const isSupportedWorkspace = useIsSupportedWorkspaceKind();

  if (!isSupportedWorkspace) {
    return <UnsupportedWorkspaceView />;
  }

  if (isLoading) {
    return (
      <Panel>
        <FetchDataLoading dataName={"Argo Workflows"} />
      </Panel>
    );
  }
  if (features.argoWF) {
    return <ArgoMainTable />;
  }

  return <ArgoWorkflowsFeaturePreview />;
};
