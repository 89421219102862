import React from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { useCostOptimizationStore } from "../../store/costOptimizationStore";
import { selectServiceResourceDrawer } from "../../store/costOptimizationStoreSelectors";
import { KubernetesJobResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const ArgoWorkflowKind = "ArgoWorkflows.Workflows";

const shouldShowLink = (kind: string): boolean => {
  return kind !== KubernetesJobResource.Kind && kind !== ArgoWorkflowKind;
};

type ServiceTextLinkProps = {
  id: string;
  serviceName: string;
  serviceKind: string;
};

export const ServiceTextLink: React.FC<ServiceTextLinkProps> = ({
  serviceName,
  id,
  serviceKind,
}) => {
  const { openServiceResourceDrawer } = useCostOptimizationStore(
    selectServiceResourceDrawer
  );

  const onClick = () => {
    openServiceResourceDrawer(id);
  };

  if (!id) return <>{serviceName}</>;
  if (!shouldShowLink(serviceKind)) return <>{serviceName}</>;

  return <StyledLink onClick={onClick}>{serviceName}</StyledLink>;
};
