import { Edge, Node } from "react-flow-renderer";

import {
  NON_CRD_KINDS,
  GRAPH_CONTAINER_ID,
  INITIAL_NODE_ID,
} from "../constants";
import { NodeData, NodeIds } from "../types";
import { parseKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { KomodorServicesResponseDataInner } from "../../../generated/resourcesApi";

export const classifyNodesByEdges = (
  graphEdges: Edge[],
  graphNodes: Node<NodeData>[],
  highlightedNode: Node<NodeData> | null
): { leftNodesIds: string[]; rightNodesIds: string[] } => {
  const initialNodeIds: NodeIds = { leftNodesIds: [], rightNodesIds: [] };

  const nodeIds = graphEdges.reduce((acc, edge) => {
    if (edge.target === highlightedNode?.id) {
      const sourceNode = graphNodes.find((el) => el.id === edge.source);
      if (sourceNode) acc.leftNodesIds.push(sourceNode.id);
    }
    if (edge.source === highlightedNode?.id) {
      const targetNode = graphNodes.find((el) => el.id === edge.target);
      if (targetNode) acc.rightNodesIds.push(targetNode.id);
    }
    return acc;
  }, initialNodeIds);

  return nodeIds;
};

export const getCenterPosition = (): {
  x: number;
  y: number;
} | null => {
  const flowContainer = document.querySelector(
    `#${GRAPH_CONTAINER_ID}`
  ) as HTMLElement;
  if (!flowContainer || flowContainer?.offsetHeight === 0) {
    return null;
  }
  const height = flowContainer.offsetHeight;
  const width = flowContainer.offsetWidth;

  const midX = width / 2;
  const midY = height / 3;

  const nodeElement = document.querySelectorAll(
    `[data-id="${INITIAL_NODE_ID}"]`
  )[0] as HTMLElement;

  const xOffset = nodeElement?.offsetWidth || 0;
  const yOffset = nodeElement?.offsetHeight || 0;

  return {
    x: midX - xOffset / 2,
    y: midY - yOffset / 2,
  };
};

export const removeDefaultControlButtons = (
  elements: HTMLCollectionOf<Element>
) => {
  for (let i = 0; i < 4; i++) {
    const element = elements[i];

    if (element) {
      (element as HTMLElement).style.display = "none";
    }
  }
};

export const getCrdsDataFromKomodorUids = (
  komodorUids: string[]
): KomodorServicesResponseDataInner[] | [] => {
  const parsedNodes = komodorUids.map((uid) => {
    const parsed = parseKomodorUid(uid);
    return {
      displayName: parsed?.name,
      k8sClusterName: parsed?.cluster,
      namespace: parsed?.namespace,
      kind: parsed?.kind,
    };
  });

  const CRDsData = parsedNodes.filter(
    (node) => node.kind && isCrdKind(node.kind)
  );
  return CRDsData.length > 0 ? CRDsData : [];
};

export const isCrdKind = (kind: string): boolean =>
  !NON_CRD_KINDS.includes(kind);

export const getResourceLink = (nodeData: NodeData): string => {
  return `/services/${nodeData.resourceDetails.resourceId}`;
};
