import React from "react";
import Drawer from "@mui/material/Drawer";

import { useAddonFullLiveState } from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { PageLoader } from "@/shared/components/PageLoader";
import { CertificateDrawerContent } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/CertificateDrawerContent";
import { useCommonDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/hooks/useCommonDrawerProps";
import { selectCertificate } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/certificateDrawerUtils";

export const CertificateDrawer: React.FC = () => {
  const { isLoading } = useAddonFullLiveState(selectCertificate);
  const content = isLoading ? <PageLoader /> : <CertificateDrawerContent />;
  return <Drawer {...useCommonDrawerProps()}>{content}</Drawer>;
};
