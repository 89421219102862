import { useCallback, useEffect, useRef, useState } from "react";

import useAtmGetResource, {
  AtmGetResourceResponseType,
  useParseAtmGetResourceResult,
} from "../../../../shared/hooks/ATM/requests/useAtmGetResource";
import { AtmOutputType } from "../../../../shared/hooks/ATM/useAtmRequest";
import { useInterval } from "../../../common/useInterval";

const DEFAULT_POLLING_INTERVAL = 1500;

export interface AtmRequestIntervalResult {
  execute: () => void;
  errorMessage: string;
  isFetching: boolean;
  isEmptyResult: boolean;
  data: unknown;
}

export interface AtmGetResourceIntervalParams {
  agentId: string;
  cluster: string;
  namespace: string;
  resourceName: string;
  resourceKind: string;
  outputType: AtmOutputType;
  interval?: number;
  enable?: boolean;
}

export const useAtmGetResourceInterval = ({
  agentId,
  cluster,
  namespace,
  resourceName,
  resourceKind,
  outputType,
  interval = DEFAULT_POLLING_INTERVAL,
  enable = true,
}: AtmGetResourceIntervalParams): AtmRequestIntervalResult => {
  const [atmResult, setAtmResult] = useState<AtmGetResourceResponseType>();

  const { execute, isFetching, status, errorMessage } = useAtmGetResource(
    agentId,
    cluster,
    namespace,
    resourceKind,
    resourceName,
    outputType,
    (response: AtmGetResourceResponseType) => {
      setAtmResult(response);
    }
  );

  const callback = useCallback(() => {
    if (
      enable &&
      !isFetching &&
      !errorMessage &&
      !atmResult?.failureMessage &&
      (!atmResult || atmResult.isPaginating)
    ) {
      execute();
    }
  }, [atmResult, enable, errorMessage, execute, isFetching]);

  const initialCall = useRef(false);

  useEffect(() => {
    if (!initialCall.current) {
      callback();
      initialCall.current = true;
      return;
    }
  });

  useInterval(callback, interval);

  const {
    data,
    fetching: finalFetching,
    errorMessage: finalErrorMessage,
    emptyResult,
  } = useParseAtmGetResourceResult(status, errorMessage, atmResult);

  return {
    execute,
    errorMessage: finalErrorMessage,
    isFetching: finalFetching,
    data,
    isEmptyResult: emptyResult,
  };
};
