import styled, { css } from "styled-components";

export const OverflowTextStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const OverflowTextContainer = styled.div`
  ${OverflowTextStyle};
`;
