import Typography from "@mui/material/Typography";

import { StyledOrderedList } from "../../../violationDrawerStyles";

import { EditResourcesButton } from "./EditResourcesButton";

export const getWhatDescription = (
  <Typography variant={"body2"}>
    The workload is frequently experiencing high demand which causes its HPA to
    reach its maximum replicas, potentially impacting the workload performance
    and stability.
  </Typography>
);

export const getWhyDescription = (
  <Typography variant={"body2"}>
    Reaching HPA maximum replica count can occur due to multiple reasons
    including high demand, misconfigurations or other reasons.
    <br />
    <br />
    This can lead to a poor user experience and potential business loss, as well
    as unnecessary scaling events or increased costs. Addressing those is
    essential for keeping your application running smoothly and ensuring user
    satisfaction.
  </Typography>
);

export const howDescription = (
  <>
    <Typography variant={"body2"}>
      To address the issue of frequent HPA limit hits, consider the following
      steps:
    </Typography>
    <br />
    <StyledOrderedList>
      <li>
        <Typography variant={"body2"}>
          <b>Edit HPA threshold:</b>
          <br />
          Review the scaling thresholds set in your Horizontal Pod Autoscaler
          (HPA). Adjust these thresholds to be more aligned with your
          application's actual resource needs. This might involve increasing or
          decreasing the target CPU or memory utilization percentages to trigger
          scaling events more accurately.
        </Typography>
        <br />
      </li>
      <li>
        <Typography variant={"body2"}>
          <b>Increase HPA Limit:</b>
          <br />
          If you frequently reach the upper limit of your HPA's scaling
          capabilities, you may need to increase the maximum number of pods it
          can scale to. Ensure that this limit reflects your cluster's capacity
          and the application's resource requirements.
        </Typography>
        <br />
      </li>
      <li>
        <Typography variant={"body2"}>
          <b>Configure Resources:</b>
          <br />
          Thoroughly assess your application's resource requirements. Set
          accurate resource requests and limits in your deployment and pod
          configurations. This step is crucial to prevent underutilization or
          over-utilization of resources, which can trigger scaling events
          unnecessarily or result in resource contention.
        </Typography>
        <EditResourcesButton />
      </li>
    </StyledOrderedList>
    <br />
  </>
);
