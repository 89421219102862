import { ContainerStatus } from "kubernetes-types/core/v1";
import { useMemo, useState } from "react";

import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import {
  usePodsLogsEarlyFetch,
  getUniqKey,
} from "../../common/usePodsLogsEarlyFetch";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { usePodLogsInfo } from "../../../../shared/hooks/podLogs/usePodLogsInfo";

import { useShouldFetchPodLogs } from "./useShouldFetchPodLogs";

type SelectedContainer = { value: string; label: string };

type PodContainer = {
  id: string;
  cluster: string;
  namespace: string;
  name: string;
  containerStatuses?: ContainerStatus[];
  fullObj?: Pod["fullObj"];
};

export const usePodContainerLogs = (
  pod: PodContainer,
  selectedContainer: SelectedContainer | undefined
) => {
  const { id, cluster, namespace, name, containerStatuses = [], fullObj } = pod;
  const [isPreviousLogs, setIsPreviousLogs] = useState(false);

  const earlyLogs = usePodsLogsEarlyFetch();

  const initContainerStatuses = fullObj?.status?.initContainerStatuses;

  const shouldExecuteFetchLogs = useShouldFetchPodLogs(
    containerStatuses,
    initContainerStatuses,
    selectedContainer?.value
  );

  const agentId = useActiveAgent(cluster);
  const { retries, failureMessage, requestMoreLogs, tail, logs, fetching } =
    usePodLogsInfo({
      podName: name,
      agentId,
      namespace,
      clusterName: cluster,
      controlledBy: id,
      selectedContainer,
      isPreviousLogs,
      shouldExecuteFetchLogs,
    });

  const logsToShow = useMemo(() => {
    if (
      logs.length > 0 ||
      earlyLogs?.key !== getUniqKey(cluster, namespace, name)
    ) {
      return logs;
    }
    return earlyLogs.podsLogsInfoResponse.logs;
  }, [
    cluster,
    earlyLogs?.key,
    earlyLogs?.podsLogsInfoResponse.logs,
    logs,
    name,
    namespace,
  ]);

  return {
    logs,
    logsToShow,
    retries,
    failureMessage,
    requestMoreLogs,
    tail,
    fetching,
    isPreviousLogs,
    setIsPreviousLogs,
  };
};
