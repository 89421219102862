import React from "react";
import styled from "styled-components";

import EventGroup from "../../../../../../common/EventGroup";
import Drawer from "../../../../../../common/ProcessList/EventDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useOverviewPageContext } from "../context/overviewPageContext";

const EventDetailsContainer = styled.div`
  font-size: 0.75rem;
`;

type EventItemDrawerProps = {
  event?: EventGroup;
  open: boolean;
};
export const EventItemDrawer: React.FC<EventItemDrawerProps> = ({
  open,
  event,
}: EventItemDrawerProps) => {
  const {
    eventItemDrawer: { closeEventItemDrawer },
  } = useOverviewPageContext();

  return event?.renderEventDetails ? (
    <EventDetailsContainer>
      <Drawer open={open} event={event} onClose={closeEventItemDrawer}>
        {event.renderEventDetails()}
      </Drawer>
    </EventDetailsContainer>
  ) : null;
};
