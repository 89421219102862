import React, { useEffect, useState } from "react";

import {
  ConfirmationDialog,
  DIALOG_TRANSITION_DURATION,
} from "@/components/reliability/components/pages/policies/shared/ConfirmationDialog";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";

const staticRequiredProps = { title: "", textLine1: "", actionButtonLabel: "" };

export const ConfirmationDialogListener: React.FC = () => {
  const { confirmationDialogProps, setConfirmationDialogProps } =
    usePolicyDrawerContext();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = (executeOnConfirm?: boolean) => {
    setIsOpen(false);
    executeOnConfirm && confirmationDialogProps?.onConfirm();
    setTimeout(() => {
      setConfirmationDialogProps(undefined);
    }, DIALOG_TRANSITION_DURATION);
  };

  useEffect(() => {
    if (confirmationDialogProps) {
      setIsOpen(true);
    }
  }, [confirmationDialogProps]);

  return (
    <ConfirmationDialog
      {...staticRequiredProps}
      {...confirmationDialogProps}
      open={isOpen}
      onConfirm={() => onClose(true)}
      onCancel={() => onClose(false)}
    />
  );
};
