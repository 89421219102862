export const podsListTableColumns = [
  {
    name: "name",
    clickable: false,
  },
  {
    name: "ready",
    clickable: false,
  },
  {
    name: "status",
    clickable: false,
  },
  {
    name: "restarts",
    clickable: false,
  },
  {
    name: "age",
    clickable: false,
  },
  {
    name: "node",
    clickable: false,
  },
];
