import React, { useMemo } from "react";
import useClipboard from "react-use-clipboard";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@komodorio/design-system/deprecated";
import { Check24, Copy24 } from "@komodorio/design-system/icons";

import { ZIndex } from "../../constants/zIndex";

const CopyContainer = styled.div<{
  copyOnClick: boolean;
  fixedheight: boolean;
  fixedWidth?: boolean;
}>`
  display: flex;
  background-color: ${palette.gray["100"]};
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  width: ${({ fixedWidth }) => (fixedWidth ? "468px" : "")};
  gap: 8px;
  height: ${({ fixedheight }) => (fixedheight ? "32px" : "fit-content")};
  align-items: center;
  cursor: ${(props) => (props.copyOnClick ? "pointer" : "auto")};
`;

interface CopyCodeProps {
  code: string;
  fullCommand: string;
  tooltipText?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  copyOnClick?: boolean;
  oneLiner?: boolean;
  fixedHeight?: boolean;
  fixedWidth?: boolean;
  maxWidthLimit?: boolean;
  codeTextElement?: React.ReactNode;
}

const StyledText = styled(Typography)<{
  oneLiner?: boolean;
  maxWidthLimit?: boolean;
}>`
  max-width: ${({ maxWidthLimit }) =>
    maxWidthLimit ? "440px" : "fit-content"};
  overflow: hidden;
  white-space: ${(props) => (props.oneLiner ? "nowrap" : "normal")};
  text-overflow: ${(props) => (props.oneLiner ? "ellipsis" : "clip")};
`;

export const CopyCode: React.FC<CopyCodeProps> = ({
  code,
  fullCommand,
  tooltipPosition,
  tooltipText,
  copyOnClick = false,
  oneLiner = false,
  fixedHeight = true,
  fixedWidth = true,
  maxWidthLimit = true,
  codeTextElement,
}) => {
  const [copied, copy] = useClipboard(fullCommand, { successDuration: 2000 });

  const copyButtonContent = copied ? <Check24 /> : <Copy24 onClick={copy} />;
  const tooltipTitle = useMemo(
    () => (copied ? "Copied!" : tooltipText),
    [copied, tooltipText]
  );

  return (
    <CopyContainer
      copyOnClick={copyOnClick}
      onClick={copyOnClick ? copy : undefined}
      fixedheight={fixedHeight}
      fixedWidth={fixedWidth}
    >
      {codeTextElement ?? (
        <StyledText
          variant="text"
          size={"small"}
          color={palette.gray["800"]}
          oneLiner={oneLiner}
          maxWidthLimit={maxWidthLimit}
        >
          {code}
        </StyledText>
      )}
      {tooltipText ? (
        <Tooltip
          PopperProps={{
            style: { zIndex: ZIndex.AboveModal },
          }}
          title={tooltipTitle}
          placement={tooltipPosition}
        >
          <div>{copyButtonContent}</div>
        </Tooltip>
      ) : (
        copyButtonContent
      )}
    </CopyContainer>
  );
};
