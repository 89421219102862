import { useEffect } from "react";

import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import {
  selectSetGroupState,
  selectViolationsState,
} from "../../../../../../store/reliabilityStoreSelectors";
import { useViolationsTableContext } from "../context/useViolationsTableContext";

import { useInitialGroupState } from "./useInitialGroupState";

export const useSetInitialGroupState = () => {
  const { groups } = useReliabilityStore(selectViolationsState);
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const setGroupsState = useReliabilityStore(selectSetGroupState);

  const initialGroupState = useInitialGroupState(violationsGroupBy);

  useEffect(() => {
    if (!groups[violationsGroupBy][uid ?? ""]) {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          ...initialGroupState,
        },
      });
    }
  }, [groups, uid, setGroupsState, violationsGroupBy, initialGroupState]);
};
