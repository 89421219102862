import { muiColors } from "@komodorio/design-system";
import Chip from "@mui/material/Chip";
import React from "react";
import styled from "styled-components";

import { dependenciesGraphAriaLabels } from "./constants";

const { pink, green } = muiColors;

const SmallChip = styled(Chip)`
  && {
    font-size: 12px;
    font-weight: 500;
    height: 16px;
  }
`;

const HealthChip = styled(SmallChip)<{
  isHealthy: boolean;
  noPadding?: boolean;
}>`
  && {
    border: ${(props) =>
      props.isHealthy ? `1px solid ${green[200]}` : `1px solid ${pink[200]}`};
    background-color: ${(props) => (props.isHealthy ? green[50] : pink[50])};
    color: ${(props) => (props.isHealthy ? green[700] : pink[700])};
    padding: ${(props) => (props?.noPadding ? "0px" : "2px")};
  }
`;

export const NodeHealthIndicator: React.FC<{
  isHealthy: boolean;
  noPadding?: boolean;
}> = ({ isHealthy, noPadding }) => (
  <HealthChip
    aria-label={
      dependenciesGraphAriaLabels.healthIndicator[
        isHealthy ? "healthy" : "unhealthy"
      ]
    }
    label={isHealthy ? "Healthy" : "Unhealthy"}
    isHealthy={isHealthy}
    noPadding={noPadding}
  ></HealthChip>
);
