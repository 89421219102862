import { useCallback, useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useGetUserEffectivePermissions } from "@/shared/hooks/auth-service/client/users/useGetUserEffectivePermissions";
import { EffectivePermission, User } from "@/generated/auth";
import { mapObjectsToOptions } from "@/shared/utils/selectInputOptions";

export const useEffectivePermissions = ({ user }: { user: User }) => {
  const { data, isFetching, error } = useGetUserEffectivePermissions(
    {
      id: user.id,
    },
    true,
    true
  );

  const [dataFilters, setDataFilters] = useState<{
    action: MuiSelectionOption<EffectivePermission["action"]>[];
    policyName: MuiSelectionOption<EffectivePermission["policyName"]>[];
    roleName: MuiSelectionOption<EffectivePermission["roleName"]>[];
    cluster: MuiSelectionOption<EffectivePermission["cluster"]>[];
    namespace: MuiSelectionOption<EffectivePermission["namespace"]>[];
  }>({
    action: [],
    policyName: [],
    roleName: [],
    cluster: [],
    namespace: [],
  });

  type DataFiltersKey = keyof typeof dataFilters;

  const setSelectedProperty = useCallback(
    (
      property: keyof typeof dataFilters,
      values: MuiSelectionOption<
        EffectivePermission[keyof EffectivePermission]
      >[]
    ) => {
      setDataFilters((prev) => ({
        ...prev,
        [property]: values,
      }));
    },
    [setDataFilters]
  );

  const filteredRows = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    let filteredData: EffectivePermission[] = [...data];

    Object.keys(dataFilters).forEach((key: string) => {
      if (dataFilters[key as DataFiltersKey].length === 0) {
        return;
      }
      filteredData = filteredData.filter((row) =>
        dataFilters[key as DataFiltersKey].some(
          (filter) => filter.value === row[key as DataFiltersKey]
        )
      );
    });

    return filteredData;
  }, [dataFilters, data]);

  const {
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
  } = useMemo(() => {
    if (!filteredRows?.length) {
      return {
        uniqueActionNamesOptions: [],
        uniquePolicyNamesOptions: [],
        uniqueRoleNamesOptions: [],
        uniqueClusterNamesOptions: [],
        uniqueNamespaceNamesOptions: [],
      };
    }
    return {
      uniqueActionNamesOptions: mapObjectsToOptions(
        filteredRows,
        "action"
      ) as MuiSelectionOption<EffectivePermission["action"]>[],
      uniquePolicyNamesOptions: mapObjectsToOptions(
        filteredRows,
        "policyName"
      ) as MuiSelectionOption<EffectivePermission["policyName"]>[],
      uniqueRoleNamesOptions: mapObjectsToOptions(
        filteredRows,
        "roleName"
      ) as MuiSelectionOption<EffectivePermission["roleName"]>[],
      uniqueClusterNamesOptions: mapObjectsToOptions(
        filteredRows,
        "cluster"
      ) as MuiSelectionOption<EffectivePermission["cluster"]>[],
      uniqueNamespaceNamesOptions: mapObjectsToOptions(
        filteredRows,
        "namespace"
      ) as MuiSelectionOption<EffectivePermission["namespace"]>[],
    };
  }, [filteredRows]);

  return {
    data,
    isFetching,
    error,
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
    dataFilters,
    setSelectedProperty,
    filteredRows,
  };
};
