import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { GridColDef } from "@mui/x-data-grid-pro";

import { useGetViolationChecks } from "../../../../../shared/hooks/reliability-api/violations/useGetViolationChecks";
import {
  CheckType,
  ImpactGroupIdentifier,
} from "../../../../../generated/reliabilityApi";
import { impactGroupIdentifierToText } from "../../../constants/impactGroupIdentifierToText";

import { CheckItemEntry } from "./CheckItemEntry";
import { ManageExclusionsButton } from "./ManageExclusionsButton";

import { EXCLUDED_IGNORE_RULES_CHECK_TYPES } from "@/components/reliability/components/pages/ignoreChecks/ignoreChecksConstants";

type IgnoreRulesOverviewRow = {
  checkType: CheckType;
  impactGroup: string;
  id: string;
};

export const useIgnoreRulesOverviewRows = ():
  | IgnoreRulesOverviewRow[]
  | undefined => {
  const { data } = useGetViolationChecks();

  return useMemo(() => {
    if (!data?.data?.checks) return undefined;
    return Object.values(CheckType)
      .map((checkType) => {
        const impactGroup = data.data.checks.find(
          (check) => check.type === checkType
        );

        if (!impactGroup) return undefined;
        const isGroupIdentifierFound = Object.values(
          ImpactGroupIdentifier
        ).includes(impactGroup.impactGroupId);

        if (!isGroupIdentifierFound) return undefined;

        const title =
          impactGroupIdentifierToText[impactGroup.impactGroupId].title;

        return {
          checkType,
          impactGroup: title,
          id: checkType,
        } as IgnoreRulesOverviewRow;
      })
      .filter((row): row is IgnoreRulesOverviewRow => {
        return (
          !!row && !EXCLUDED_IGNORE_RULES_CHECK_TYPES.includes(row.checkType)
        );
      });
  }, [data?.data?.checks]);
};

export const useIgnoreRulesOverviewColumns = () => {
  return useMemo<GridColDef<IgnoreRulesOverviewRow>[]>(() => {
    return [
      {
        field: "checkName",
        headerName: "Name",
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return <CheckItemEntry checkType={params.row.checkType} />;
        },
      },
      {
        field: "impactGroup",
        headerName: "Impact Group",
        width: 150,
        sortable: false,
        resizable: false,
      },
      {
        field: "",
        headerName: "",
        width: 150,
        sortable: false,
        resizable: false,
        renderCell: (params) => (
          <ManageExclusionsButton checkType={params.row.checkType} />
        ),
      },
    ];
  }, []);
};

type RowModel = { id: number };
export const useLoadingTableData = () => {
  return useMemo(() => {
    const loadingRows = Array.from({ length: 8 }).map(
      (_, index): RowModel => ({
        id: index,
      })
    );
    const loadingColumns: GridColDef<RowModel>[] = [
      {
        headerName: "Name",
        field: "name",
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" flexDirection="column" gap={"8px"}>
            <Skeleton variant={"rounded"} width={170} height={24} />
            <Skeleton variant={"rounded"} width={300} height={16} />
          </Box>
        ),
      },
      {
        field: "impactGroup",
        width: 150,
        renderCell: () => (
          <Skeleton variant={"rounded"} width={170} height={20} />
        ),
      },
      {
        field: "",
        width: 150,
        renderCell: () => (
          <Skeleton variant={"rounded"} width={125} height={30} />
        ),
      },
    ];
    return { loadingRows, loadingColumns };
  }, []);
};
