import { AccountPlan } from "komodor-types";
import { useCallback } from "react";

import { useUpdateAccountPlanMutation } from "../../generated/graphql";

type UpdateAccountPlan = (
  accountId: string,
  plan: AccountPlan
) => Promise<void>;

const useUpdateAccountPlan: () => UpdateAccountPlan = () => {
  const [, updatePlanInDB] = useUpdateAccountPlanMutation();
  return useCallback(
    async (accountId: string, plan: AccountPlan) => {
      await updatePlanInDB({ id: accountId, plan });
      window.location.reload();
    },
    [updatePlanInDB]
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useUpdateAccountPlan;
