import React, { useContext, useId } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import { muiColors, muiTheme } from "../../../themes";

import { CommandContext, VALUE_ATTR } from "./Command";

export type ItemProps = {
  kind: string;
  children: React.ReactNode;
  /**
   * A unique value for this item.
   */
  value: string;
  icon?: React.ReactNode;
  isSelected?: boolean;
  enterKeyText?: string;
};

const Root = styled.div<{ isSelected: boolean }>`
  display: grid;
  height: 64px;
  box-sizing: border-box;
  grid-column: 1 / 3;
  grid-template-columns: subgrid;
  gap: 24px;
  padding: 0px 24px;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? muiTheme.palette.grey[50] : muiTheme.palette.common.white};
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  overflow-x: hidden;
`;

const EnterKey = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${muiTheme.palette.divider};
  background-color: ${muiColors.gray[25]};
`;

export const Item = ({
  children,
  kind,
  value,
  icon,
  enterKeyText = "Enter",
}: ItemProps): JSX.Element => {
  const id = useId();
  const { selectedValue, setSelectedValue, select } =
    useContext(CommandContext);
  const isSelected = selectedValue === value;
  return (
    <Root
      id={id}
      isSelected={isSelected}
      {...{ [VALUE_ATTR]: value }}
      role="option"
      aria-selected={isSelected}
      onMouseMove={() => {
        setSelectedValue(value);
      }}
      onClick={() => {
        select(value);
      }}
    >
      <Typography
        variant="overline2"
        sx={{
          color: "text.secondary",
          paddingBlock: "4px",
          gap: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
        {kind}
      </Typography>
      <ItemContent>
        <Typography
          variant="h5"
          sx={{ flexGrow: 1, overflowX: "hidden", whiteSpace: "nowrap" }}
        >
          {children}
        </Typography>
        {isSelected && (
          <EnterKey>
            <KeyboardReturnIcon sx={{ fontSize: "16px" }} />
            <Typography variant="body2">{enterKeyText}</Typography>
          </EnterKey>
        )}
      </ItemContent>
    </Root>
  );
};
