import React, { memo, useMemo } from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { AriaLabels } from "../../../shared/config/ariaLabels";
import {
  CpuUnit,
  GraphDataPoint,
  MemoryUnit,
  TooltipItemProps,
} from "../types";

import {
  MetricsTooltipContainer,
  MetricsTooltipGrid,
} from "./metricsTooltipStyles";
import { TooltipContentEntry } from "./TooltipContentEntry";
import { TooltipDateTimeTitle } from "./TooltipDateTimeTitle";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { isTooltipDataValid, isTooltipValueValid } from "./tooltipUtils";
import { TooltipItem } from "./tooltipTypes";

interface TooltipContainerProps {
  label: number;
  dateOnly?: boolean;
  tooltipItems: TooltipItem[];
}

export const TooltipContainer: React.FC<TooltipContainerProps> = ({
  label,
  dateOnly,
  tooltipItems,
}) => {
  const tooltipRows = useMemo(
    () =>
      tooltipItems.map(
        ({ key, title, value, format, icon }) =>
          isTooltipValueValid(value) && (
            <TooltipContentEntry
              title={title}
              key={key}
              value={value}
              format={format}
              icon={icon}
            />
          )
      ),
    [tooltipItems]
  );

  return (
    <MetricsTooltipContainer
      aria-label={AriaLabels.MetricsAvailability.Tooltip}
    >
      <TooltipDateTimeTitle label={label} dateOnly={dateOnly} />
      <MetricsTooltipGrid>{tooltipRows}</MetricsTooltipGrid>
    </MetricsTooltipContainer>
  );
};

export interface Payload {
  payload: GraphDataPoint;
}

export type MetricsTooltipProps = {
  payload?: unknown[];
  label?: number;
  unit?: MemoryUnit | CpuUnit;
};

export const Tooltip = memo(
  ({
    tooltipItems,
    tooltipProps,
    dataTransformFunction,
    dateOnly,
  }: {
    tooltipItems: TooltipItemProps[];
    tooltipProps: TooltipProps<ValueType, NameType>;
    dataTransformFunction: (data: string) => { value: string; format?: string };
    dateOnly?: boolean;
  }) => {
    const { payload, label } = tooltipProps;

    const tooltipItemWithFormat = useMemo(() => {
      if (!isTooltipDataValid(tooltipProps)) return [];
      return tooltipItems.map((item) => {
        const { value, format } = dataTransformFunction(
          payload?.[0].payload?.[item.dataKey]?.toString() ?? ""
        );
        return {
          title: item.title,
          key: item.tooltipItemKey,
          value,
          format: format ?? "",
          icon: item.icon,
        };
      });
    }, [dataTransformFunction, payload, tooltipItems, tooltipProps]);

    if (!isTooltipDataValid(tooltipProps)) return null;

    return (
      <TooltipContainer
        label={label ?? 0}
        dateOnly={dateOnly}
        tooltipItems={tooltipItemWithFormat}
      />
    );
  }
);
