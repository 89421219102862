import React, { useCallback } from "react";

import { GraphDataPoint } from "../../../../../../../../../Metrics/types";
import { TooltipItem } from "../../../../../../../../../Metrics/Tooltip/tooltipTypes";
import { SingleMetricsGraphProps } from "../hpaReachedMaxTypes";
import { ColoredRequestIcon } from "../hpaReachedMaxStyles";
import {
  formatTooltipCPUTick,
  formatTooltipMemoryTick,
} from "../../../../../../../../../Metrics/utils";

type CustomTooltipEntriesParams = Pick<
  SingleMetricsGraphProps,
  "resourceType"
> & { valueToUse: number };

export const useGetCustomTooltipEntries = ({
  resourceType,
  valueToUse,
}: CustomTooltipEntriesParams) => {
  return useCallback(
    (payload: GraphDataPoint): TooltipItem[] => {
      const { value, format } =
        resourceType === "cpu"
          ? formatTooltipCPUTick(Math.round(valueToUse).toString())
          : formatTooltipMemoryTick(valueToUse.toString(), "MiB");

      return [
        {
          title: "SCALING THRESHOLD",
          key: "utilization",
          value,
          format,
          icon: <ColoredRequestIcon />,
        },
      ];
    },
    [resourceType, valueToUse]
  );
};
