import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { InstallKomoCliTabs } from "./InstallKomoCliTabs";

const BlueNote = styled.div`
  background-color: ${muiColors.blue["50"]};
  border-radius: 4px;
  border: 1px solid ${muiColors.blue["300"]};
  padding: 16px;
`;

const TextContainer = styled.div`
  max-width: 430px;
  & > * {
    display: inline;
  }
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

const KOMO_CLI_LINK = "https://github.com/komodorio/komocli";

export const InstallKomoCliNote: React.FC = () => {
  return (
    <BlueNote>
      <TextContainer>
        <Typography variant={"button"} color={muiColors.gray[800]}>
          Note:
        </Typography>
        <Typography variant={"body2"} color={muiColors.gray[800]}>
          To use the Port Forward action, you have to install the
        </Typography>{" "}
        <Link
          variant={"body2"}
          href={KOMO_CLI_LINK}
          color={muiColors.gray[800]}
          target={"_blank"}
        >
          Komodor CLI,
        </Link>
        <Typography variant={"body2"} color={muiColors.gray[800]}>
          to do so, run the following script in your terminal:
        </Typography>
      </TextContainer>
      <InstallKomoCliTabs />
    </BlueNote>
  );
};
