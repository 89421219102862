import { get } from "lodash";

import { InsightResult } from "../../generated/insights";
import {
  CardTitle,
  availabilityTitle,
  bestPracticesTitle,
} from "../appView/sections/AppView/components/OverviewPage/Insights/insightsConstants";

import {
  CpuThrottledWhatHappened,
  CpuThrottledHowToFixThis,
  IssuesAfterDeployHowToFixThis,
  DeprecatedApisHowToFixThis,
} from "./InsightsTexts";
import IssuesAfterDeployContent from "./content/IssuesAfterDeploy/IssuesAfterDeployContent";
import ThrottledCpuContent from "./content/ThrottledCPU/ThrottledCpuContent";
import {
  InsightDetailsContentProps,
  deprecatedApisDescription,
  deprecatedApisWhatHappened,
  issuesAfterDeployWhatHappened,
  throttledCpuDescription,
} from "./insightUtils";
import DeprecatedApisContent from "./content/DeprecatedApis/DeprecatedApisContent";

export const ThrottledCPU = "throttledCPU";
export const IssuesAfterDeploy = "issuesAfterDeploy";
export const DeprecatedAPIs = "deprecatedApis";

interface InsightIdentifier {
  name: string;
  cardTitle: CardTitle;
  descriptionCallback: (data: object) => string;
  whatHappenedCallback: (data: object) => string;
  howToFixThis: JSX.Element;
  content: React.FC<InsightDetailsContentProps>;

  aggregateInsightData: (results: InsightResult[]) => object;
}

export const InsightsIdentifiers: { [_: string]: InsightIdentifier } = {
  [ThrottledCPU]: {
    name: ThrottledCPU,
    cardTitle: availabilityTitle,
    descriptionCallback: (data) => throttledCpuDescription(data),
    whatHappenedCallback: (data) => CpuThrottledWhatHappened,
    howToFixThis: CpuThrottledHowToFixThis,
    content: ThrottledCpuContent,
    aggregateInsightData: (results: InsightResult[]) => {
      const containers = results.flatMap((result) => {
        return get(result.insightData, "containers", []);
      });
      return { containers };
    },
  },
  [IssuesAfterDeploy]: {
    name: IssuesAfterDeploy,
    cardTitle: availabilityTitle,
    descriptionCallback: (data) => "Spike in availability issues",
    whatHappenedCallback: (data) => issuesAfterDeployWhatHappened(data),
    howToFixThis: IssuesAfterDeployHowToFixThis,
    content: IssuesAfterDeployContent,
    aggregateInsightData: (results: InsightResult[]) => {
      return results[0].insightData ?? {};
    },
  },
  [DeprecatedAPIs]: {
    name: DeprecatedAPIs,
    cardTitle: bestPracticesTitle,
    descriptionCallback: (data) => deprecatedApisDescription(data),
    whatHappenedCallback: (data) => deprecatedApisWhatHappened(data),
    howToFixThis: DeprecatedApisHowToFixThis,
    content: DeprecatedApisContent,
    aggregateInsightData: (results: InsightResult[]) => {
      return results[0].insightData ?? {};
    },
  },
};
