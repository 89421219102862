import React, { ReactElement } from "react";
import styled from "styled-components";

import Table from "./Table";
import useDataTable from "./useDataTable";
import { Column, DataRowProps, DataTableProps } from "./types";

const Container = styled.div`
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  font-size: 0.75rem;
  border-radius: 4px;
`;

export const Overflow = styled.div`
  max-height: calc(100vh - 13.5rem);
  overflow-y: auto;
`;

export const DataRow = styled.tr<DataRowProps>`
  ${({ highlightedRows }) =>
    highlightedRows
      ? `
    :hover {
     background-color: #ebf3fc;
    }
    `
      : ``};
  ${({ disabled }) => (disabled ? `color: #8993a9;` : ``)};

  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;

function DataTable<T>({
  data,
  columns,
  getRowId,
  className,
  highlightedRows,
  onRowClicked,
}: DataTableProps<T>): ReactElement {
  const { headers, rows } = useDataTable(data, columns, getRowId);
  return (
    <Container className={className}>
      <Overflow>
        <Table>
          <thead>
            <tr>
              {headers.map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(({ id, cells, disabled }, idx) => (
              <DataRow
                key={id}
                highlightedRows={highlightedRows}
                disabled={!!disabled}
                isClickable={typeof onRowClicked === "function"}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClicked && onRowClicked(data[idx]);
                }}
              >
                {cells.map(({ id, cell }) => (
                  <td key={id}>{cell}</td>
                ))}
              </DataRow>
            ))}
          </tbody>
        </Table>
      </Overflow>
    </Container>
  );
}

export type { Column };

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DataTable;
