import { Button } from "@komodorio/design-system/deprecated";
import { IntegrationType } from "komodor-types";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { v4 as uuid4 } from "uuid";

import { contactMessage } from "../../../../constants";
import { H3 } from "../../../common/typography";
import { FormContainer } from "../CustomFormComponents";
import CustomIntegrationInstallation from "../CustomIntegrationInstallation";
import CopyCode from "../kubernetes/CopyCode";
import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";
import { ALERT_MANAGER_INTEGRATION_DOCS_LINK } from "../../../../shared/constants/docsLinks";

export const HelpText = styled.div`
  margin-top: 2.5rem;
  color: darkred;
`;

const Step = styled.li`
  font-size: 0.85rem;
  margin-block-end: 0.5rem;
`;

const PROMETHEUS_ALERT_MANAGER_ENDPOINT =
  "https://app.komodor.com/prometheus-alert-manager/event";

const PrometheusAlertManagerInstallation: React.FC<{
  closeModalCallback: () => void;
}> = ({ closeModalCallback }) => {
  const { handleSubmit } = useForm();
  const [error, setError] = useState(false);
  const installationId = useMemo(() => uuid4(), []);
  const { mutateAsync: install, isError: createInstallationError } =
    useCreateInstallation();
  const webhookPath = `"${PROMETHEUS_ALERT_MANAGER_ENDPOINT}/${installationId}"`;
  return (
    <CustomIntegrationInstallation name="Alert Manager" isForm={false}>
      <div>
        <H3>Installation Steps</H3>
        <ol>
          <Step>
            Please look at{" "}
            <a
              href={ALERT_MANAGER_INTEGRATION_DOCS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Komodor docs
            </a>
          </Step>
          <Step>
            Here is the URL for the webhook:{" "}
            <CopyCode value={webhookPath} width="32rem" />
          </Step>
        </ol>
      </div>
      <br />
      <FormContainer>
        <Button
          variant="primary"
          onClick={handleSubmit(async () => {
            await install({
              id: installationId,
              integration: IntegrationType.PROMETHEUS_ALERT_MANAGER,
              configuration: { webhookPath: webhookPath },
            });
            if (createInstallationError) {
              setError(createInstallationError);
              return;
            }
            closeModalCallback();
          })}
        >
          Install Prometheus integration
        </Button>
      </FormContainer>
      {error && (
        <HelpText>
          Error while creating Prometheus Alert Manager Integration.
          <br />
          {contactMessage}
        </HelpText>
      )}
    </CustomIntegrationInstallation>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PrometheusAlertManagerInstallation;
