import { useCallback } from "react";

import { useCreationPageContext } from "../context/CreationPageContext";
import {
  boolDictionaryToStringList,
  workspaceValueByType,
} from "../utils/creationPageUtils";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  onAppViewUpsertSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { useVerifyCreationForm } from "./useVerifyCreationForm";
import {
  useSetAppViewAndNavigate,
  useVerifyServerResponse,
} from "./creationPageHooks";

import { useEditWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useEditWorkspaces";
import { Workspace, WorkspaceKind } from "@/generated/workspacesApi";

export const useUpdateCurrentAppView = (): (() => Promise<void>) => {
  const {
    setIsSavingAppView,
    selectedServiceIdentifiers,
    appViewType,
    appName,
    description,
    selectedServiceIds,
    setErrorState,
  } = useCreationPageContext();
  const { id } = useAppViewsStore(currentAppViewSelector);
  const onAppViewUpsert = useAppViewsStore(onAppViewUpsertSelector);
  const verifyCreationForm = useVerifyCreationForm();
  const verifyServerResponse = useVerifyServerResponse();
  const setAppViewAndNavigate = useSetAppViewAndNavigate();
  const updateWorkspace = useEditWorkspace();

  return useCallback(async () => {
    if (!verifyCreationForm()) return;
    setErrorState({ appNameError: undefined });
    if (!id || !appName) return;

    const serviceIdentifiers =
      appViewType === WorkspaceKind.ServiceId
        ? boolDictionaryToStringList(selectedServiceIds)
        : selectedServiceIdentifiers ?? [];

    const newAppViewData: Workspace = {
      id,
      name: appName,
      description,
      kind: appViewType ?? WorkspaceKind.ServiceId,
      value: workspaceValueByType(serviceIdentifiers, appViewType),
    } as Workspace;

    setIsSavingAppView(true);
    await updateWorkspace
      .mutateAsync(newAppViewData)
      .catch((error) => {
        if (!verifyServerResponse(error)) {
          return;
        }
      })
      .finally(() => {
        setIsSavingAppView(false);
      });

    setAppViewAndNavigate(newAppViewData);

    onAppViewUpsert(newAppViewData);
  }, [
    appName,
    appViewType,
    description,
    id,
    onAppViewUpsert,
    selectedServiceIdentifiers,
    selectedServiceIds,
    setAppViewAndNavigate,
    setErrorState,
    setIsSavingAppView,
    updateWorkspace,
    verifyCreationForm,
    verifyServerResponse,
  ]);
};
