import { useMemo } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  MIDDLE_SECTION_MENU_ITEMS,
  NATIVE_RESOURCES_MENU_ITEMS,
  TOP_SECTION_MENU_ITEMS,
} from "@/components/AppBar/constants";
import { useDefaultClusterForInspection } from "@/components/Inspection/utils/useDefaultClusterForInspection";

export const useAppBar = () => {
  const features = useOverridableFlags();
  const defaultCluster = useDefaultClusterForInspection();

  const topSectionMenuItems = useMemo(() => {
    return TOP_SECTION_MENU_ITEMS.filter((item) => {
      if (item.features) {
        return item.features.some((feature) => features[feature]);
      }
      if (item.rejectedFeatures) {
        return item.rejectedFeatures.every((feature) => !features[feature]);
      }
      return true;
    });
  }, [features]);

  const nativeResourcesMenuItems = useMemo(() => {
    return NATIVE_RESOURCES_MENU_ITEMS.filter(({ features: groupFeatures }) =>
      !groupFeatures ? true : groupFeatures.some((feature) => features[feature])
    );
  }, [features]);

  return {
    topSectionMenuItems,
    middleSectionMenuItems: MIDDLE_SECTION_MENU_ITEMS,
    nativeResourcesMenuItems,
    shouldShowResourcesMenu: !!defaultCluster,
  };
};
