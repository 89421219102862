import React, { useCallback, useEffect, useMemo, useState } from "react";
import { noop } from "lodash";
import { Popover } from "@komodorio/design-system/deprecated";
import { useLocation } from "react-router-dom";

import Pod from "../../../ResourceView/resources/pod";
import { REQUIRED_AGENT_VERSION_POD_EXEC } from "../../../../shared/utils/agent/agent";
import { usePodExecSessionsContext } from "../../../../shared/context/PodExecSessionsProvider";
import { ActionButtonProps } from "../types";
import Action from "..";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import { extractPathFromLocation } from "../../common/utils";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { ZIndex } from "../../../../constants/zIndex";

import { ContainerOption, ContainerType } from "./types";
import { ContainerSelection } from "./ContainerSelection";
import { mapContainerToOption } from "./utils/mapPodContainerToContainerOptions";

export const ExecPodShell: React.FC<ActionButtonProps> = ({
  resource,
  Button,
}) => {
  const pod = resource as Pod;
  const location = useLocation();

  const [isContainersSelectionOpen, setContainersSelectionOpen] =
    useState(false);
  const { containerStatuses = [] } = pod;
  const initContainerStatuses = pod.fullObj?.status?.initContainerStatuses;
  const {
    addDrawerSessionId,
    setSessionProps,
    createSessionIdentifier,
    getSessionProps,
  } = usePodExecSessionsContext();

  const { dispatchEventViaBackend } = useAnalyticsApi();

  const containersOptions: ContainerOption[] = useMemo(
    () =>
      containerStatuses.map((c) =>
        mapContainerToOption(ContainerType.Regular, c)
      ),
    [containerStatuses]
  );

  const initContainersOptions: ContainerOption[] = useMemo(
    () =>
      (initContainerStatuses || []).map((c) =>
        mapContainerToOption(ContainerType.Regular, c)
      ),
    [initContainerStatuses]
  );

  const [selectedContainerName, setSelectedContainerName] = useState<
    string | undefined
  >(
    containersOptions.length + initContainersOptions.length === 1
      ? containersOptions[0].value
      : undefined
  );

  // if we have only one container, no need in the dropdown
  useEffect(() => {
    const numOfContainers =
      containersOptions.length + initContainersOptions.length;

    if (selectedContainerName || numOfContainers !== 1) {
      return;
    }

    if (containersOptions.length === 1) {
      setSelectedContainerName(containersOptions[0].value);
    } else {
      setSelectedContainerName(initContainersOptions[0].value);
    }
  }, [
    setSelectedContainerName,
    selectedContainerName,
    containersOptions,
    initContainersOptions,
  ]);

  const isEnabled = useMemo(() => {
    return [...containerStatuses, ...(initContainerStatuses || [])].some(
      (c) => c.state?.running !== undefined
    );
  }, [containerStatuses, initContainerStatuses]);

  const handleOpenDrawer = useCallback(
    (containerName: string) => {
      const sessionName = createSessionIdentifier(pod.id, containerName);

      if (containerName === getSessionProps(sessionName)?.containerName) {
        dispatchEventViaBackend(
          AnalyticEvents.PodExec.Same_Container_Selected_Twice,
          { containerName },
          true,
          false,
          [SegmentIntegrations.Hubspot]
        );
      }

      dispatchEventViaBackend(
        AnalyticEvents.Actions.Actions_fired,
        {
          type: "openPodExecSession",
          path: extractPathFromLocation(location),
        },
        true,
        false,
        [SegmentIntegrations.Hubspot]
      );

      setSessionProps(sessionName, {
        containerName,
        agentId: resource.agentId,
        cluster: resource.cluster,
        namespace: resource.namespace,
        podName: resource.name,
      });

      addDrawerSessionId(sessionName);
    },
    [
      createSessionIdentifier,
      pod.id,
      getSessionProps,
      dispatchEventViaBackend,
      location,
      setSessionProps,
      resource.agentId,
      resource.cluster,
      resource.namespace,
      resource.name,
      addDrawerSessionId,
    ]
  );

  return (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={() => {
        const numOfContainers =
          containersOptions.length + initContainersOptions.length;

        if (numOfContainers > 1) {
          setContainersSelectionOpen(true);
        } else {
          handleOpenDrawer(containersOptions[0].value);
        }
      }}
      Button={Button}
      minimalSupportedVersion={REQUIRED_AGENT_VERSION_POD_EXEC}
      disabled={!isEnabled}
      ariaLabel={AriaLabels.PodExec.OpenNewSession}
      action="podExec"
      disabledReason="This Pod has no running containers"
    >
      {!selectedContainerName && (
        <Popover
          isOpen={isContainersSelectionOpen}
          handleClose={noop}
          content={
            <ContainerSelection
              onSelect={handleOpenDrawer}
              containerOptions={containersOptions}
              initContainersOptions={initContainersOptions}
            />
          }
          onClickOutside={() => {
            setContainersSelectionOpen(false);
          }}
          children={null}
          containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
        />
      )}
    </Action>
  );
};
