import { useMutation, useQuery } from "@tanstack/react-query";

import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";
import {
  apiV1WhitelistCidrGetUrl,
  apiV1WhitelistCidrIdDeleteUrl,
  apiV1WhitelistCidrIdPutUrl,
  apiV1WhitelistCidrPostUrl,
  CIDRWhitelist,
  CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest,
  CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest,
  CIDRWhitelistApiApiV1WhitelistCidrPostRequest,
} from "@/generated/accounts";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";

const CIDR_WHITELIST_PATH = "/whitelist-cidr";
const CIDR_WHITELIST_PATH_ID = "/whitelist-cidr/:id";

export const useGetCIDRs = () => {
  const apiClient = useAccountsApiClient();
  const { accountId } = useUserMetadata();
  return useQuery([CIDR_WHITELIST_PATH], async (): Promise<CIDRWhitelist[]> => {
    return (
      await apiClient.get(
        apiV1WhitelistCidrGetUrl(
          { accountId },
          apiClient.defaults.baseURL ?? ""
        )
      )
    )?.data;
  });
};

// Will need to change it to allow bulk creation
export const useCreateCIDRs = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    [CIDR_WHITELIST_PATH],
    async (params: CIDRWhitelistApiApiV1WhitelistCidrPostRequest) => {
      await apiClient.post<CIDRWhitelist>(
        apiV1WhitelistCidrPostUrl(params, apiClient.defaults.baseURL ?? ""),
        params
      );
    }
  );
};

export const useUpdateCIDR = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    [CIDR_WHITELIST_PATH_ID],
    async (params: CIDRWhitelistApiApiV1WhitelistCidrIdPutRequest) => {
      await apiClient.put(
        apiV1WhitelistCidrIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
        params.apiV1WhitelistCidrIdPutRequest
      );
    }
  );
};

export const useDeleteCIDRs = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    [CIDR_WHITELIST_PATH_ID],
    async (params: CIDRWhitelistApiApiV1WhitelistCidrIdDeleteRequest) => {
      await apiClient.delete(
        apiV1WhitelistCidrIdDeleteUrl(params, apiClient.defaults.baseURL ?? ""),
        { data: params }
      );
    }
  );
};
