import { useMemo } from "react";

interface Props {
  yaml: string | undefined;
  wantedString: string;
}

export const useHighlightYamlLine = ({ yaml, wantedString }: Props) =>
  useMemo(() => {
    if (!yaml) return;
    const yamlAsArray = yaml.split("\n").map((line) => line.trim());

    const lineNumber = yamlAsArray.indexOf(wantedString);
    return lineNumber >= 0 ? lineNumber + 1 : undefined;
  }, [wantedString, yaml]);
