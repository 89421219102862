import React from "react";
import {
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { compact } from "lodash";

import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import {
  columnsConfig,
  useLoadingColumns,
  useTableColumns,
} from "@/components/k8sAddons/addons/externalDns/hooks/tableHooks";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { ExternalDnsRecord } from "@/generated/addonsApi";

export const Table: React.FC = () => {
  const { searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
  });

  const response = useFetchLiveStateList({
    additionalRequestFilters: compact([searchFilter]),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useLoadingColumns(),
      dataStateColumns: useTableColumns(),
      tableTitle: "External DNS(s)",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${externalDnsListPageAriaLabels.table} loading`
    : `${externalDnsListPageAriaLabels.table} content`;

  return (
    <PageTableContainer>
      <Typography variant="h4">{title}</Typography>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={(params: GridRowParams<ExternalDnsRecord>) => null}
        getRowId={(row) => row.id}
      />
    </PageTableContainer>
  );
};
