import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { EmptyState } from "../common/UpgradeAgentVersionAlert/EmptyState";

import { KomodorServiceType } from "./types";

export const KomodorServicesEmptyState: React.FC<{
  komodorServiceType: KomodorServiceType;
  isFiltering: boolean;
  onResetFilters: () => void;
}> = ({ komodorServiceType, isFiltering, onResetFilters }) => {
  return (
    <EmptyState title={`No results found`}>
      {isFiltering && (
        <Typography variant={"body2"}>
          No results match the filter criteria.Try{" "}
          <Link onClick={onResetFilters} sx={{ cursor: "pointer" }}>
            clearing your filters
          </Link>{" "}
          to see all {komodorServiceType}s.
        </Typography>
      )}
    </EmptyState>
  );
};
