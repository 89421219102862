import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { sectionGap } from "../../../../../../../../constants/reliabilityConstants";
import { ViolationSummary } from "../../../shared/ViolationSummary/ViolationSummary";
import {
  ImpactGroupType,
  K8SEOLSupportingData,
} from "../../../../../../../../../../generated/reliabilityApi";
import { parseKomodorUid } from "../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { reliabilityArialLabels } from "../../../../../../../../reliabilityArialLabels";
import { useGetViolations } from "../../../../../../../../../../shared/hooks/reliability-api/violations/useGetViolations";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

import { getSummaryItems } from "./summaryUtils";
import { UpgradeStatus } from "./upgradeStatuses";
import { ViewApiConstraintsButton } from "./ViewApiConstraintsButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
`;

const {
  k8sEol: { summary: summaryAriaLabel },
} = reliabilityArialLabels.violationDrawer;

interface Props {
  supportingData: K8SEOLSupportingData | undefined;
  komodorUid: string | undefined;
}

export const Details: React.FC<Props> = ({ supportingData, komodorUid }) => {
  const items = useMemo(
    () => getSummaryItems(supportingData),
    [supportingData]
  );

  const { cluster } = parseKomodorUid(komodorUid ?? "") ?? {};

  const { deprecatedApis, suggestedVersion } = supportingData ?? {};

  const shouldRequestViolations =
    !!cluster && (!!deprecatedApis || !suggestedVersion);

  const { data, isFetching } = useGetViolations(
    {
      pageSize: 1,
      offset: 0,
      checkType: ["deprecatedApis"],
      impactGroupType: [ImpactGroupType.Dynamic],
      status: ["open", "confirmed", "dismissed", "ignored"],
      clusterName: [cluster ?? ""],
    },
    shouldRequestViolations
  );

  const { id } = data?.data.violations[0] ?? {};

  useReportDrawerLoadingTime(!isFetching || !shouldRequestViolations);

  return (
    <Container>
      <ViolationSummary items={items} ariaLabel={summaryAriaLabel} />
      <UpgradeStatus
        deprecatedApis={deprecatedApis}
        suggestedVersion={suggestedVersion}
      />
      <ViewApiConstraintsButton id={id} />
    </Container>
  );
};
