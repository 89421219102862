import { useCallback } from "react";

import { MetricType } from "../../../../../../../../../Metrics/types";
import { formatNumber } from "../../../../../../../../../../shared/utils/formatNumber";
import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { formatTooltipMemoryTick } from "../../../../../../../../../Metrics/utils";

type UtilizationToRequestValueParams = {
  resourceType: MetricType;
  metricValue: number;
};

export const useGetMetricsRequestValue = () => {
  const { requestValues } = useHpaReachedMaxContext();

  return useCallback(
    ({ metricValue, resourceType }: UtilizationToRequestValueParams) => {
      const requestValue = requestValues[resourceType];
      if (!requestValue) return "";
      const value = (metricValue / 100) * requestValue;

      if (resourceType === "cpu") {
        return `(${formatNumber(value)} MilliCores)`;
      }

      if (resourceType === "memory") {
        const { value: memoryValue, format } = formatTooltipMemoryTick(
          value.toString(),
          "MiB"
        );
        return `(${memoryValue} ${format})`;
      }

      return "";
    },
    [requestValues]
  );
};
