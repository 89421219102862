import { useMemo } from "react";

import { PodLogLine } from "../types";
import { PodLogsDirection } from "../store/types";
import { usePodLogsStore } from "../store/podLogsStore";
import {
  selectPodLogs,
  selectPodLogsDirection,
} from "../store/podLogsStoreSelectors";

import { useLogsViewerStore } from "@/components/common/LogsViewer/store/logsViewerStore";
import { selectIsLiveTailEnabled } from "@/components/common/LogsViewer/store/logsViewerStoreSelectors";

export const useGetLogsToView = (
  lastSeenIdxWhileLiveTailWasActive: number
): PodLogLine[] => {
  const logsDirection = usePodLogsStore(selectPodLogsDirection);
  const logs = usePodLogsStore(selectPodLogs);
  const isLiveTailEnabled = useLogsViewerStore(selectIsLiveTailEnabled);

  return useMemo(() => {
    // if live tail is enabled, we want to show all logs
    // otherwise, we want to show logs that were shown while live tail was active
    // two cases:
    // 1. direction is NewestFirst: say we saw 10 logs while live tail was active, we want to show the last 10 logs, because new logs are added to the start.
    // 2. direction is EarliestFirst: say we saw 10 logs while live tail was active, we want to show the first 10 logs, because new logs are added to the end.
    const lastN = logs.slice(
      logs.length - lastSeenIdxWhileLiveTailWasActive,
      logs.length
    );
    const firstN = logs.slice(0, lastSeenIdxWhileLiveTailWasActive);
    const logsWhereLiveTailWasActive =
      logsDirection === PodLogsDirection.NewestFirst ? lastN : firstN;

    return isLiveTailEnabled ? logs : logsWhereLiveTailWasActive;
  }, [
    logsDirection,
    logs,
    lastSeenIdxWhileLiveTailWasActive,
    isLiveTailEnabled,
  ]);
};
