import React, { PropsWithChildren } from "react";

interface HoverableContainerProps {
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

export const HoverableContainer: React.FC<
  PropsWithChildren<HoverableContainerProps>
> = ({ onMouseEnter, onMouseLeave, children }) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    {children}
  </div>
);
