import React from "react";

import {
  AvailabilityResource,
  useInvestigationModeStore,
} from "../../shared/store/investigationModeStore/investigationModeStore";
import {
  availableActionsSelector,
  komodorServiceSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import { QuickActionsMenu } from "../ResourceView/common/Header/Actions/HeaderActions";

export const QuickActions: React.FC = () => {
  const resource = useInvestigationModeStore(komodorServiceSelector);
  const availableActions = useInvestigationModeStore(availableActionsSelector);

  return resource && availableActions?.length > 0 ? (
    <QuickActionsMenu
      resource={resource as AvailabilityResource}
      allowedActions={availableActions}
    />
  ) : null;
};
