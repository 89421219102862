import React, { memo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import Resource from "../../resources";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { MetricsTabContent } from "./MetricsTabContent";
import { MetricsTabContextProvider } from "./context/MetricsTabContext";
import { PodContainersRequestStatusContextProvider } from "./context/PodContainersRequestStatusContext";

type MetricsTabProps = {
  resource: Resource;
};

export const MetricsTab: React.FC<MetricsTabProps> = memo(
  ({ resource }) => {
    return (
      <MetricsTabContextProvider>
        <PodContainersRequestStatusContextProvider>
          <MetricsTabContent resource={resource as Pod} />
        </PodContainersRequestStatusContextProvider>
      </MetricsTabContextProvider>
    );
  },
  (prevProps, nextProps) => {
    const prevPodResource = prevProps.resource as Pod;
    const nextPodResource = nextProps.resource as Pod;
    return (
      prevPodResource.id === nextPodResource.id &&
      prevPodResource.resourceVersion === nextPodResource.resourceVersion
    );
  }
);
