import React from "react";
import Typography from "@mui/material/Typography";

import { CheckType } from "../../../../../generated/reliabilityApi";
import { getCheckDescription } from "../../../utils/reliabilityUtils";
import { getCheckLabel } from "../../../utils/getCheckLabel";

import { IgnoreRuleContainer } from "./ignoreChecksStyles";

type IgnoreRuleEntryProps = { checkType: CheckType };

export const CheckItemEntry: React.FC<IgnoreRuleEntryProps> = ({
  checkType,
}) => {
  const title = getCheckLabel(checkType);
  const description = getCheckDescription(checkType);

  return (
    <IgnoreRuleContainer>
      <div>
        <Typography variant={"h5"}>{title}</Typography>
        <Typography
          variant={"body3"}
          color={"text.secondary"}
          whiteSpace={"normal"}
        >
          {description}
        </Typography>
      </div>
    </IgnoreRuleContainer>
  );
};
