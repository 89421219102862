import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  GraphTitleContainer,
  RightSizingGridContainer,
  StyledPaddedCard,
} from "../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectRightSizingCPUMetricsState,
  selectRightSizingMemoryMetricsState,
} from "../../../store/costOptimizationStoreSelectors";
import { LinesLoader } from "../../../../common/loaders/Line";
import { FetchError } from "../../shared/errors/FetchError";
import {
  RightSizingMemoryMetricsState,
  RightSizingCpuMetricsState,
} from "../../../types/costOptimizationTypes";
import { graphUnits } from "../../../utils/graphUtils";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

import { MetricsGraph } from "./MetricsGraph/MetricsGraph";

const StyledLoader = styled(LinesLoader)`
  margin-right: auto;
  margin-left: auto;
`;

const MetricsGraphContainer: React.FC<{
  state: RightSizingMemoryMetricsState | RightSizingCpuMetricsState;
  unit: string;
}> = ({ state, unit }) => {
  const { loading, data, error } = state;
  return (
    <>
      {loading ? (
        <StyledLoader />
      ) : error ? (
        <FetchError />
      ) : (
        <MetricsGraph datapoints={data?.datapoints ?? []} unit={unit} />
      )}
    </>
  );
};

export const MetricsGraphs: React.FC = () => {
  const cpuState = useCostOptimizationStore(selectRightSizingCPUMetricsState);

  const memoryState = useCostOptimizationStore(
    selectRightSizingMemoryMetricsState
  );

  return (
    <RightSizingGridContainer>
      <StyledPaddedCard
        aria-label={
          costOptimizationAriaLabels.rightSizingPage.rightSizingGraph.cpuGraph
        }
      >
        <GraphTitleContainer>
          <Typography variant={"body2"}>CPU</Typography>
          <Typography variant={"body2"} color={muiColors.gray[600]}>
            (Cores)
          </Typography>
        </GraphTitleContainer>
        <MetricsGraphContainer state={cpuState} unit={graphUnits.cpu} />
      </StyledPaddedCard>
      <StyledPaddedCard
        aria-label={
          costOptimizationAriaLabels.rightSizingPage.rightSizingGraph
            .memoryGraph
        }
      >
        <GraphTitleContainer>
          <Typography variant={"body2"}>Memory</Typography>
          <Typography variant={"body2"} color={muiColors.gray[600]}>
            (GB)
          </Typography>
        </GraphTitleContainer>
        <MetricsGraphContainer state={memoryState} unit={graphUnits.memory} />
      </StyledPaddedCard>
    </RightSizingGridContainer>
  );
};
