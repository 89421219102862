import { GridSortModel } from "@mui/x-data-grid-pro";

import { ClusterProviderType } from "../../generated/workspacesApi";

import { DEFAULT_PAGINATION_MODEL } from "@/components/ClustersView/constants";
import { getDisplayingItemsText } from "@/shared/utils/tableUtils";

export const getK8sVersionShort = (k8sVersion: string): string => {
  return k8sVersion.split("+")[0].split("-")[0];
};

export const cloudProviderLabelMap: Record<ClusterProviderType, string> = {
  [ClusterProviderType.Aws]: "AWS",
  [ClusterProviderType.Azure]: "Azure",
  [ClusterProviderType.Gcp]: "Google Cloud",
  [ClusterProviderType.Openshift]: "OpenShift",
  [ClusterProviderType.Vmware]: "VMware",
  [ClusterProviderType.Oracle]: "Oracle",
  [ClusterProviderType.Vcluster]: "vCluster",
  [ClusterProviderType.K3S]: "K3s",
  [ClusterProviderType.Rancher]: "Rancher",
  [ClusterProviderType.Kubernetes]: "Additional Providers",
};

export const getPaginationModel = (
  limit: number | undefined,
  offset: number | undefined
) => {
  if (limit === undefined || offset === undefined) {
    return DEFAULT_PAGINATION_MODEL;
  }
  return {
    pageSize: limit,
    page: offset / limit,
  };
};

export const getDisplayingItemsTextByLimitAndOffset = (
  limit: number | undefined,
  offset: number | undefined,
  totalNumberOfItems: number
) => {
  const { page, pageSize } = getPaginationModel(limit, offset);

  return getDisplayingItemsText(page, pageSize, totalNumberOfItems);
};

export const fromSortModel = (sortModel: GridSortModel) =>
  sortModel
    .map((sort) => `${sort.sort === "desc" ? "-" : ""}${sort.field}`)
    .join(",");

export const toSortModel = (sorts: string): GridSortModel =>
  sorts.split(",").map((sort) => {
    return {
      field: sort.replace("-", ""),
      sort: sort.startsWith("-") ? "desc" : "asc",
    };
  });

export const getMinDigitsWidth = (digits: number) => {
  const commaWidth = 2;
  const commas = digits < 4 ? 0 : Math.floor((digits - 1) / 3);
  return `calc(${digits}ch + ${commas * commaWidth}px)`;
};
