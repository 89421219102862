import React, { useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Popover, Tag, Typography } from "@komodorio/design-system/deprecated";

import { MonitorSensor } from "../../common/types";
import Divider from "../../common/Divider";
import { flexAlignedCenter } from "../../common/styles";

const INCLUDE_COLOR = palette.green[50];
const EXCLUDE_COLOR = palette.pink[50];

const Tags = styled.div`
  ${flexAlignedCenter}
  gap: 0.25rem;
`;

const PlusTag = styled(Tag).attrs({ color: palette.white[0] })`
  padding: 3px;
  min-width: max-content;
  border: 1px solid ${palette.gray[200]};
  :hover {
    border: 1px solid ${palette.gray[500]};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 0.4rem;
`;

const createScopeEntries = (
  namespaces: string[] | undefined = [],
  annotations: string[] | undefined = [],
  labels: string[] | undefined = [],
  services: string[] | undefined = []
) => {
  const splitByFirstOccurrence = (value: string) => {
    const charIndex = value.indexOf(":");
    return [value.slice(0, charIndex), value.slice(charIndex + 1)];
  };

  return [
    ...namespaces.map((n) => ["namespace", n]),
    ...annotations.map((a) => splitByFirstOccurrence(a)),
    ...labels.map((l) => splitByFirstOccurrence(l)),
    ...services.map((s) => ["service", s]),
  ];
};

const ScopeColumn: React.FC<{ sensors: MonitorSensor[] }> = ({
  sensors: [sensor],
}) => {
  const [openList, setOpenList] = useState(false);

  const { cluster, namespaces, annotations, labels, exclude, services } =
    sensor;
  const isCustomScope = namespaces || annotations || labels || services;

  const includes = isCustomScope
    ? createScopeEntries(namespaces, annotations, labels, services)
    : [["cluster", cluster]];

  const excludes = createScopeEntries(
    exclude?.namespaces,
    exclude?.annotations,
    exclude?.labels,
    exclude?.services
  );

  const allTags = [
    ...includes.map((tag) => ({ isInclude: true, tag })),
    ...excludes.map((tag) => ({ isInclude: false, tag })),
  ];
  const preview = allTags.slice(0, 2);

  const popoverContent = (
    <div onClick={(e) => e.stopPropagation()}>
      {includes.length > 0 && (
        <List>
          <Typography variant="title">Include ({includes.length}):</Typography>
          {includes.map(([key, value]) => (
            <Tag key={`${key}:${value}`} color={INCLUDE_COLOR}>
              <Typography>
                {key}: <b>{value}</b>
              </Typography>
            </Tag>
          ))}
        </List>
      )}
      {excludes.length > 0 && (
        <>
          <Divider />
          <List>
            <Typography variant="title">
              Exclude ({excludes.length}):
            </Typography>
            {excludes.map(([key, value]) => (
              <Tag key={`${key}:${value}`} color={EXCLUDE_COLOR}>
                <Typography>
                  {key}: <b>{value}</b>
                </Typography>
              </Tag>
            ))}
          </List>
        </>
      )}
    </div>
  );

  return (
    <Tags>
      {preview.map(({ isInclude, tag: [key, value] }, i) => (
        <Tag key={i} color={isInclude ? INCLUDE_COLOR : EXCLUDE_COLOR}>
          <Typography>
            {key}: <b>{value}</b>
          </Typography>
        </Tag>
      ))}
      {allTags.length > 2 && (
        <Popover
          isOpen={openList}
          maxHeight="15.5rem"
          maxWidth="18rem"
          align="start"
          handleClose={() => setOpenList(false)}
          content={popoverContent}
        >
          <PlusTag
            onClick={(e) => {
              e.stopPropagation();
              setOpenList(!openList);
            }}
          >
            + {allTags.length - 2}
          </PlusTag>
        </Popover>
      )}
    </Tags>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ScopeColumn;
