import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import {
  METRICS_POD_CPU_ENDPOINT,
  METRICS_POD_MEMORY_ENDPOINT,
} from "../../../../shared/hooks/metrics-api/requestResponseMaps";
import { GraphContainerWithStatus } from "../../../Metrics/GraphContainerWithStatus";
import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";
import { useResourceUid } from "../../../../shared/hooks/resources/useResourceUid";
import { Uid } from "../../../../shared/utils/generateUid";
import { EnlargedMetricsGraph } from "../../../Metrics/graphs/EnlargedMetricsGraph";
import { ConditionalNoLimitLabel } from "../../../Metrics/NoLimitLabel";
import { ResourceGraph } from "../../../Metrics/graphs/ResourceGraph";

import {
  MetricsPerTypeParams,
  useMetricParams,
  useMetricsTabGraphData,
} from "./hooks/metricsTabHooks";
import {
  MetricsTabContextProvider,
  useMetricsTabContext,
} from "./context/MetricsTabContext";
import { useContainersMetricsFetchers } from "./ContainerMetricsFetcher";
import { timelineTicksNum } from "./constants";
import { PodContainersGraph } from "./PodContainersGraph";
import { getCommonGraphContainerWithStatusProps } from "./utils";
import { useReportPodMetricsTabLoadingTime } from "./hooks/datadogRumHooks";
import { MetricsTimeSelector } from "./MetricsTimeSelector";
import { usePodMetricsWithZoom } from "./hooks/usePodMetricsWithZoom";

const Container = styled.div`
  background-color: ${palette.white[0]};
  border-radius: 4px;
  padding: 1rem;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 0 2rem 0;
  > div {
    flex: 1;
    min-width: 320px;
  }
`;

type MetricsTabProps = {
  resource: Pod;
};

export const MetricsTabContent: React.FC<MetricsTabProps> = ({ resource }) => {
  const { setPodContainersNames } = useMetricsTabContext();
  const { containerStatuses } = resource;
  const containerRef = useRef<HTMLDivElement>(null);

  const isDisplayed = useIsElementInViewport({ ref: containerRef });

  useReportPodMetricsTabLoadingTime(isDisplayed);

  const metricsParams = useMetricParams(resource);

  const uid = useResourceUid(resource);

  useEffect(() => {
    setPodContainersNames(
      containerStatuses?.map((container) => container.name) ?? []
    );
  }, [containerStatuses, setPodContainersNames]);

  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const containersMetricsFetchers = useContainersMetricsFetchers(
    containerStatuses,
    { ...metricsParams, fromEpoch, toEpoch }
  );

  const isMultipleContainers = useMemo(
    () => containerStatuses && containerStatuses?.length > 1,
    [containerStatuses]
  );

  const syncId = `${resource.cluster}/${resource.namespace}/${resource.name}`;

  return (
    <Container ref={containerRef}>
      <MetricsTimeSelector />
      <GraphContainer>
        <EnlargeableMetricsTabGraph
          resource={resource}
          title={"Memory Usage"}
          metricsParams={metricsParams}
          endpoint={METRICS_POD_MEMORY_ENDPOINT}
          uid={uid}
          syncId={syncId}
        />
        <EnlargeableMetricsTabGraph
          resource={resource}
          title={"CPU Usage"}
          metricsParams={metricsParams}
          endpoint={METRICS_POD_CPU_ENDPOINT}
          uid={uid}
          syncId={syncId}
        />
      </GraphContainer>
      {isMultipleContainers && (
        <GraphContainer>
          <PodContainersGraph
            uid={uid}
            type={"memory"}
            key={"memory"}
            resource={resource}
            endTime={metricsParams.toEpochMs}
            containerStatuses={containerStatuses}
            syncId={syncId}
            graphProps={graphProps}
          />
          <PodContainersGraph
            uid={uid}
            type={"cpu"}
            key={"cpu"}
            resource={resource}
            endTime={metricsParams.toEpochMs}
            containerStatuses={containerStatuses}
            syncId={syncId}
            graphProps={graphProps}
          />
        </GraphContainer>
      )}
      {containersMetricsFetchers}
    </Container>
  );
};

type MetricsTabGraphCommonProps = {
  title: string;
  metricsParams: MetricsPerTypeParams;
  endpoint:
    | typeof METRICS_POD_MEMORY_ENDPOINT
    | typeof METRICS_POD_CPU_ENDPOINT;
  uid: Uid;
  isMultipleContainers?: boolean;
  syncId?: string;
};

const MetricsTabGraph = ({
  title,
  metricsParams,
  endpoint,
  uid,
  onEnlargeGraph,
  isMultipleContainers,
  syncId,
}: MetricsTabGraphCommonProps & { onEnlargeGraph: () => void }) => {
  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const { data, state, type } = useMetricsTabGraphData(
    { ...metricsParams, fromEpoch, toEpoch },
    endpoint
  );

  return (
    <GraphContainerWithStatus
      {...getCommonGraphContainerWithStatusProps(state)}
      title={title}
      uid={uid}
      onEnlargeGraph={onEnlargeGraph}
      info={
        <ConditionalNoLimitLabel
          data={state.metrics}
          isMultipleContainers={isMultipleContainers}
        />
      }
    >
      <ResourceGraph
        type={type}
        data={data}
        timelineTicksNum={timelineTicksNum}
        graphHeight={"15rem"}
        syncId={syncId}
        {...graphProps}
      />
    </GraphContainerWithStatus>
  );
};

const EnlargedMetricsTabGraph = ({
  resource,
  title,
  metricsParams: { toEpochMs },
  endpoint,
  uid,
  onClose,
  isMultipleContainers,
}: MetricsTabGraphCommonProps & {
  resource: Pod;
  onClose: () => void;
}) => {
  const metricsParams = useMetricParams(resource, toEpochMs);

  const { fromEpoch, toEpoch, graphProps } = usePodMetricsWithZoom();

  const { data, state, type } = useMetricsTabGraphData(
    { ...metricsParams, fromEpoch, toEpoch },
    endpoint
  );

  return (
    <EnlargedMetricsGraph
      uid={uid}
      onClose={onClose}
      title={title}
      hasGraphData={!state.loading && data.length > 0}
      error={state.error}
      loading={state.loading}
      controls={[<MetricsTimeSelector />]}
      info={
        <ConditionalNoLimitLabel
          data={state.metrics}
          isMultipleContainers={isMultipleContainers}
        />
      }
    >
      <ResourceGraph
        type={type}
        data={data}
        timelineTicksNum={timelineTicksNum}
        {...graphProps}
      />
    </EnlargedMetricsGraph>
  );
};

const EnlargeableMetricsTabGraph = ({
  resource,
  title,
  metricsParams,
  endpoint,
  uid,
  syncId,
}: MetricsTabGraphCommonProps & { resource: Pod }) => {
  const [isMetricsGraphEnlarged, setIsMetricsGraphEnlarged] = useState(false);
  const { timeWindow } = useMetricsTabContext();

  const isMultipleContainers = useMemo(
    () => resource.containerStatuses && resource.containerStatuses?.length > 1,
    [resource]
  );

  return (
    <>
      {isMetricsGraphEnlarged && (
        <MetricsTabContextProvider givenTimeWindow={timeWindow}>
          <EnlargedMetricsTabGraph
            resource={resource}
            uid={uid}
            onClose={() => setIsMetricsGraphEnlarged(false)}
            title={title}
            metricsParams={metricsParams}
            endpoint={endpoint}
          />
        </MetricsTabContextProvider>
      )}
      <MetricsTabGraph
        title={title}
        metricsParams={metricsParams}
        endpoint={endpoint}
        uid={uid}
        onEnlargeGraph={() => setIsMetricsGraphEnlarged(true)}
        isMultipleContainers={isMultipleContainers}
        syncId={syncId}
      />
    </>
  );
};
