import React, { useMemo } from "react";

import { useOverviewPageStore } from "../../store/overviewPageStore";
import { selectOverviewMetricsData } from "../../store/overviewPageStoreSelectors";
import { getFormattedDurationsFromTimestamps } from "../../Insights/shared/utils/graphUtils";
import { TimeStampDataPoints } from "../../Insights/shared/types";
import { getBucketTimeFrame } from "../../utils/overviewPageUtils";
import { useHasSufficientMetricsDataAccuracy } from "../../hooks/overviewPageHooks";
import { OptimizeScoreButton } from "../OptimizeScoreButton";
import { SingleOverviewMetricProps, DataState } from "../overviewMetricsTypes";
import { getTrendData, getTrendSign, metricsUnits } from "../utils";
import { useShouldDisplayPotentialCostSavings } from "../../Insights/insightsCards/PotentialCostSaving/costHooks";
import { getRelativeDiff } from "../../../../../../../../shared/utils/numbers/getPercentage";

import { useGenerateEmptyDataPoints } from "./useGenerateEmptyDataPoints";

export const useCostOptimizationTrendOverview =
  (): SingleOverviewMetricProps => {
    const {
      costOptimizationTrends: { data, loading, error },
    } = useOverviewPageStore(selectOverviewMetricsData);

    const hasSufficientMetricsDataAccuracy =
      useHasSufficientMetricsDataAccuracy();

    const dataState: DataState = hasSufficientMetricsDataAccuracy
      ? "complete"
      : "collecting data";

    const showLoader = loading && dataState === "complete";
    const generateEmptyDataPoints = useGenerateEmptyDataPoints();

    const shouldDisplayPotentialCostSavings =
      useShouldDisplayPotentialCostSavings();

    const {
      sumByDay,
      currentScore,
      secondDuration,
      totalDuration,
      trend,
      trendSign,
      isEmptyState,
      bucketTimeFrame,
    } = useMemo(() => {
      const sumByDay: TimeStampDataPoints =
        data?.intervals?.[0].sumByDay.concat(data.intervals[1].sumByDay) ?? [];
      const bucketTimeFrame =
        data?.intervals?.[0].bucketTimeFrame ?? getBucketTimeFrame();

      // if state is "collecting data", there will be no data. Generate empty data points array for presentation
      const dataPointsToUse = hasSufficientMetricsDataAccuracy
        ? sumByDay
        : generateEmptyDataPoints();

      const currentScore = Math.round(
        data?.intervals?.[1].optimizationScore ?? 0
      );
      const previousScore = Math.round(
        data?.intervals?.[0].optimizationScore ?? 0
      );

      const { secondDuration, totalDuration } =
        getFormattedDurationsFromTimestamps(dataPointsToUse) ?? {};

      const { percentageChange, isIncreased } = getRelativeDiff(
        previousScore,
        currentScore
      );
      const trendSign = getTrendSign(isIncreased);
      const isEmptyState = currentScore === 0 && previousScore === 0;
      return {
        sumByDay: dataPointsToUse,
        bucketTimeFrame,
        currentScore,
        secondDuration,
        totalDuration,
        trend: percentageChange,
        trendSign,
        isEmptyState,
      };
    }, [
      data?.intervals,
      generateEmptyDataPoints,
      hasSufficientMetricsDataAccuracy,
    ]);

    return useMemo(
      () => ({
        loading: showLoader,
        error,
        dataState,
        summaryData: {
          metricUnit: metricsUnits.score,
          title: "Cost optimization score",
          mainMetric: `${currentScore}%`,
          trendData: getTrendData({
            trend,
            trendSign,
            isSuccess: trendSign === "+",
          }),
          timePeriod: `vs. prev ${secondDuration}`,
          isEmptyState,
        },
        graphData: {
          title: `Trend, ${totalDuration}`,
          data: sumByDay,
          bucketTimeFrame,
        },
        actionButton: hasSufficientMetricsDataAccuracy ? (
          <OptimizeScoreButton disabled={!shouldDisplayPotentialCostSavings} />
        ) : undefined,
      }),
      [
        bucketTimeFrame,
        currentScore,
        dataState,
        error,
        hasSufficientMetricsDataAccuracy,
        isEmptyState,
        secondDuration,
        shouldDisplayPotentialCostSavings,
        showLoader,
        sumByDay,
        totalDuration,
        trend,
        trendSign,
      ]
    );
  };
