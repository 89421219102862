import React, { ComponentProps, useCallback } from "react";
import Switch from "@mui/material/Switch";

import { Operator } from "@/generated/addonsApi";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

type SwitchFieldProps = Omit<
  ComponentProps<typeof Switch>,
  "onChange" | "checked"
>;

type SwitchAsGenericFilterProps = {
  filterName: string;
  onChange?: (value: boolean) => void;
  switchProps?: SwitchFieldProps;
};

export const SwitchAsGenericFilter: React.FC<SwitchAsGenericFilterProps> = ({
  filterName,
  onChange,
  switchProps,
}) => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();

  const valueFromUrl =
    (dataInUrl?.filters?.[filterName]?.[0]?.values?.[0] ?? false) === "true";

  const setValueInUrl = useCallback(
    (checked: boolean) => {
      if (checked) {
        setDataInUrl({
          filters: {
            ...dataInUrl?.filters,
            [filterName]: [
              { values: [checked.toString()], operator: Operator.Eq },
            ],
          },
        });
      } else if (dataInUrl?.filters?.[filterName]) {
        delete dataInUrl.filters[filterName];
        setDataInUrl({
          filters: dataInUrl.filters,
        });
      }
    },
    [dataInUrl.filters, filterName, setDataInUrl]
  );

  const onChangeCb = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange && onChange(checked);
      setValueInUrl(checked);
    },
    [setValueInUrl, onChange]
  );

  return (
    <Switch {...switchProps} onChange={onChangeCb} checked={valueFromUrl} />
  );
};
