export const title = "What it means";

export const explanationBlock =
  "We have identified services within your workspace where the CPU and memory resource requests can be optimized, offering an opportunity to reduce cloud costs. By adjusting these resource requests to better align with your workspace's requirements and its availability requirements, you can achieve significant cost savings.";

export const tableExplanation =
  "The following services have potential to costs:";

export const potentialSavingColumnTooltip =
  "Recommendations for potential savings and optimized availability based on the recommended resource configuration";
