import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ImpactedServicesDetails from "./detailsComponents/ImpactedServicesDetails";

interface ImpactedServicesOutput {
  services?: string[][];
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ImpactedServicesCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.icon = check.passed ? "success" : "warn";
    this.title = "Impacted Services";
    this.description = "What is the impact of this PVC being in pending state?";
    this.action = (
      <>
        We run <GrayHighlight>kubectl describe pvc {"<PVC>"}</GrayHighlight> and
        check the pods of the deployments that mentioned in the 'Used By' field.
      </>
    );
    this.question = "Is this PVC being used by any pod?";
    this.isFinding = true;
    this.output = check.output as ImpactedServicesOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <ImpactedServicesDetails check={this} />;
  }
}
