import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import Typography from "@mui/material/Typography";

import { WorkloadMetricsContainersRestartsDataPoints } from "../../../../../../../../../generated/metricsApi";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import { GraphDataKeyIndicator } from "./ContainerRestartsStyles";

const FlexStyle = css`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  ${FlexStyle};
  gap: 8px;
  margin: 24px 50px;
`;

const TextContainer = styled.div`
  ${FlexStyle};
  gap: 4px;
`;

const {
  containerRestarts: { graphLegend: graphLegendAriaLabel },
} = reliabilityArialLabels.violationDrawer;

type GraphLegendProps = {
  containers?: WorkloadMetricsContainersRestartsDataPoints[];
};

export const GraphLegend: React.FC<GraphLegendProps> = ({ containers }) => {
  const content = useMemo(() => {
    return containers?.map((container, index) => {
      return (
        <TextContainer key={container.containerName}>
          <GraphDataKeyIndicator index={index} />
          <Typography variant={"body3"} color={"text.secondary"}>
            {container.containerName}
          </Typography>
        </TextContainer>
      );
    });
  }, [containers]);
  return <Container aria-label={graphLegendAriaLabel}>{content}</Container>;
};
