import React from "react";

import { AggregationsList } from "@/components/k8sAddons/components/AggregationsList";
import { Addon, Entity } from "@/generated/addonsApi";
import { Filters } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/Filters";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import {
  DEFAULT_CERT_MANAGER_SORT_BY_DIRECTION,
  DEFAULT_CERT_MANAGER_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { getAggregationFiltersList } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/utils/certificatePageUtils";
import { CertificatePageTable } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/CertificatePageTable";
import { PageContainer } from "@/components/k8sAddons/styles";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";
import { IfSupportedEntityForWorkspaceKind } from "@/components/k8sAddons/components/IfSupportedEntityForWorkspaceKind";

export const CertificatesPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.CertManager}
      addonEntity={Entity.Certificate}
      setInitialClustersFilter={true}
      refetchInterval={useAddOnsRefetchInterval()}
      defaultSortModel={{
        field: DEFAULT_CERT_MANAGER_SORT_BY_FIELD,
        sort: DEFAULT_CERT_MANAGER_SORT_BY_DIRECTION,
      }}
    >
      <PageContainer>
        <IfSupportedEntityForWorkspaceKind>
          <Filters />
          <AggregationsList listItemsData={getAggregationFiltersList()} />
          <CertificatePageTable />
        </IfSupportedEntityForWorkspaceKind>
      </PageContainer>
    </AddonContextProvider>
  );
};
