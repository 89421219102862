import styled, { css } from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { muiColors } from "@komodorio/design-system";

export const PagePadding = css`
  padding: 0 24px 12px 24px;
`;

export const PageContainer = styled.div`
  display: flex;
  ${PagePadding};
  gap: 32px;
  & > div:last-child {
    flex-grow: 1;
  }
`;

export const AnimatedKeyboardArrowDown = styled(KeyboardArrowDown)<{
  open: boolean;
}>`
  && {
    cursor: pointer;
    transition: transform 0.2s;
    transform: rotate(${({ open }) => (open ? "0deg" : "-90deg")});
  }
`;

export const GrayLabelBox = styled.div`
  border-radius: 4px;
  padding: 8px 16px;
  background: ${muiColors.gray[50]};
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;
