import React from "react";

import EventGroup, { EventBase } from "..";
import { blueForUI, blueForUIText } from "../../../../Colors";
import { Maybe } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CommonEventDetails from "../../ProcessList/details/CommonEventDetails/commonEventDetails";

import ConfigChangeScatterShape from "./../shapes/config.svg?react";

export interface CommonEventBase extends EventBase {
  readonly name: string;
  readonly namespace?: string;
  readonly clusterName?: Maybe<string>;
  readonly action?: string;
}

export class CommonEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigChangeScatterShape;

  readonly events: CommonEventBase[];
  readonly id;
  readonly type;
  readonly startTime;
  readonly summary;
  readonly endTime;
  readonly details = "";
  readonly serviceId;
  readonly status: string;
  readonly sendEventWhenClicked = true;

  constructor(e: CommonEventBase, type: string) {
    this.id = e.id;
    this.type = e.type;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.serviceId = e.serviceId;
    this.events = [e];
    this.status = "Completed";
    this.summary = `${e.action} ${type} - ${e.clusterName}/${e.namespace}/${e.name}`;
  }

  renderEventDetails(): JSX.Element {
    return <CommonEventDetails eventGroup={this} />;
  }
}
