import React, { useCallback, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import Paper from "@mui/material/Paper";
import {
  ChartLegend,
  ChartTooltip,
  getDisplayedChartData,
} from "@komodorio/design-system/komodor-ui";
import { muiTheme } from "@komodorio/design-system";
import { useDebouncedCallback } from "use-debounce";

import { useChartData } from "@/pages/organization-settings/account/UsagePage/StackedBarChart/useChartData";
import { NodeCount } from "@/generated/accounts";
import {
  BAR_CHART_MONTH_LABEL_NAME,
  CHART_COLORS,
} from "@/pages/organization-settings/account/UsagePage/StackedBarChart/constants";
import { Dictionary } from "@/shared/types/Dictionary";

interface NodeCountStackedBarChartProps {
  data?: NodeCount[];
  clusters: string[];
  sortedMonths: string[];
}

export const NodeCountStackedBarChart: React.FC<
  NodeCountStackedBarChartProps
> = ({ data, clusters, sortedMonths }) => {
  const { chartData, chartKeys } = useChartData({
    data,
    clusters,
    sortedMonths,
  });

  const [hideData, setHideData] = useState<Dictionary<boolean>>(
    Object.keys(clusters).reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
  );

  const [currentValueHover, setCurrentValueHover] = useState<string | null>(
    null
  );

  const { callback: debouncedSetCurrentValueHover } = useDebouncedCallback(
    setCurrentValueHover,
    200
  );

  const legendItemOnClick = useCallback(
    (key: string) => {
      const newData = { ...hideData, [key]: !hideData[key] };
      setHideData(newData);
    },
    [hideData]
  );

  const displayedData = useMemo(() => {
    if (!chartData) return [];
    return getDisplayedChartData({ chartData, hiddenSeries: hideData });
  }, [chartData, hideData]);

  const chartBars = useMemo(() => {
    return [...chartKeys]
      .reverse()
      .map((key, index) => (
        <Bar
          key={key}
          dataKey={key}
          stackId="a"
          fill={CHART_COLORS[index % CHART_COLORS.length]}
          opacity={!currentValueHover ? 1 : currentValueHover === key ? 1 : 0.2}
        />
      ));
  }, [chartKeys, currentValueHover]);

  const NodeCountLegend = useMemo(
    () => (
      <Legend
        layout="vertical"
        align="right"
        content={(props) => (
          <ChartLegend
            hide={hideData}
            setHide={legendItemOnClick}
            props={props}
            setCurrentValueHover={debouncedSetCurrentValueHover}
            direction={"column"}
            reverse
          />
        )}
        wrapperStyle={{ bottom: 10 }}
      />
    ),
    [debouncedSetCurrentValueHover, hideData, legendItemOnClick]
  );

  return (
    <Paper>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          maxBarSize={40}
          data={displayedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <RechartTooltip
            cursor={false}
            content={(props) => (
              <ChartTooltip
                data={props}
                unit={""}
                categoryLabelGetter={(item) => [item.dataKey?.toString() ?? ""]}
              />
            )}
            wrapperStyle={{ outline: "none" }}
            isAnimationActive={false}
          />
          <YAxis
            tick={{ fontSize: 10 }}
            label={{
              value: "Node Count",
              angle: -90,
              dx: -16,
              fill: muiTheme.palette.text.primary,
              fontFamily: muiTheme.typography.fontFamily,
            }}
          />
          <XAxis dataKey={BAR_CHART_MONTH_LABEL_NAME} tick={{ fontSize: 10 }} />
          {NodeCountLegend}
          {chartBars}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};
