import React from "react";
import { get } from "lodash";
import { RoleBinding as RoleBindingType } from "kubernetes-types/rbac/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";

import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { RoleBindingDetailsTab } from "../tabs/DescribeTab/RoleBinding";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class RoleBinding implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Describe];
  readonly fullObj;
  readonly reference: RoleBindingType["roleRef"];
  readonly subjects: RoleBindingType["subjects"];
  readonly defaultTab;

  constructor(cluster: string, RoleBinding: RoleBindingType, agentId: string) {
    this.agentId = agentId;
    this.id = RoleBinding.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = RoleBinding.metadata?.namespace ?? "";
    this.name = RoleBinding.metadata?.name ?? "";
    this.kind = RoleBinding.kind ?? "";
    this.labels = RoleBinding.metadata?.labels ?? {};
    this.annotations = RoleBinding.metadata?.annotations ?? {};
    this.fullObj = RoleBinding;
    this.reference = RoleBinding.roleRef;
    this.subjects = RoleBinding.subjects;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <RoleBindingDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        displayName={get(this, "fullObj.spec.names.kind")}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
