import { muiColors } from "@komodorio/design-system";
import Card from "@mui/material/Card";
import styled from "styled-components";

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 16px 24px;
  && {
    overflow: visible;
  }
`;

export const MetricsContainer = styled(Container)`
  flex-direction: column;
  align-items: stretch;
  padding: 16px 24px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TrendsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background-color: ${muiColors.orange[50]};
  border: 1px solid ${muiColors.orange[100]};
  color: ${muiColors.orange[800]};
  border-radius: 4px;
  margin: 0;
  min-width: 22.25rem;
  max-width: 30rem;
`;

export const SummaryDetails = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: start;
  grid-row-gap: 24px;
  grid-column-gap: 16px;

  & > :nth-child(odd) {
    justify-self: start;
  }
`;
