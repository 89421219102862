import React, { useState, useEffect, useMemo } from "react";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import { PodsAndLogs16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { usePodLogsInfo } from "../../../../../shared/hooks/podLogs/usePodLogsInfo";
import { MAX_RETRIES } from "../../../../../shared/hooks/podLogs/constants";
import { doesAgentVersionSupportLiveDataView } from "../../../../../shared/utils/agent/agent";
import { useAgentInfoById } from "../../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { useActiveAgent } from "../../../../../shared/hooks/useAgents";
import UpgradeRequired from "../../../UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../upgradeAgentCta/UpgradeAgentCTA";
import { links } from "../../../../integrations/installation/kubernetes/steps/InstallCommand";
import { StyledExternalLink } from "../../../../integrations/installation/sentry/steps/styles";
import ContainerPicker from "../../../LogsViewer/PodLogsBar/ContainerPicker";
import LogsViewerModal from "../../../LogsViewer/LogsViewerModal";

const StyledButton = styled(Button)`
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0rem;
`;
type PodLogsButtonProps = Omit<PodLogsModalByPodDataProps, "handleCloseModal">;

export const PodLogsButton: React.FC<PodLogsButtonProps> = ({
  clusterName,
  namespace,
  podName,
  containerNames,
  controlledBy,
}) => {
  const [openFetchLogs, setOpenFetchLogs] = useState(false);

  const handleFetchLogsClick = () => {
    dispatchEvent(AnalyticEvents.PodEventDetailsDrawer.View_Logs_Clicked);
    setOpenFetchLogs(!openFetchLogs);
  };
  return (
    <>
      <StyledButton
        size="small"
        variant="default"
        onClick={handleFetchLogsClick}
      >
        <PodsAndLogs16 />
        View Logs
      </StyledButton>
      {openFetchLogs && (
        <PodLogsModalByPodData
          clusterName={clusterName}
          namespace={namespace}
          podName={podName}
          containerNames={containerNames}
          controlledBy={controlledBy}
          handleCloseModal={() => setOpenFetchLogs(false)}
        />
      )}
    </>
  );
};

interface PodLogsModalByPodDataProps {
  clusterName: string;
  namespace: string;
  podName: string;
  containerNames: string;
  controlledBy?: string;
  handleCloseModal: () => void;
}

const updateRequiredMessage = (
  <Typography size="medium">
    An update to the Komodor agent is required to view pod logs.
    <br />
    <br />
    Learn more about{" "}
    <StyledExternalLink href={links.defaultK8sPermissions}>
      required permissions
    </StyledExternalLink>
  </Typography>
);

const PodLogsModalByPodData: React.FC<PodLogsModalByPodDataProps> = ({
  clusterName,
  namespace,
  podName,
  containerNames,
  handleCloseModal,
  controlledBy,
}) => {
  const [displayUpgradeRequired, setDisplayUpgradeRequired] = useState(false);
  const agentId = useActiveAgent(clusterName);
  const { agentProperties: agentInfo } = useAgentInfoById(agentId);
  useEffect(() => {
    if (!doesAgentVersionSupportLiveDataView(agentInfo)) {
      setDisplayUpgradeRequired(true);
    }
    setDisplayUpgradeRequired(false);
  }, [agentInfo]);

  const [container, setContainer] = useState<string>();

  const containersOptions = useMemo(() => {
    if (!containerNames) return;
    return containerNames.split(",").map((c) => ({
      label: `Container: ${c}`,
      value: c,
    }));
  }, [containerNames]);

  const selectedContainer = useMemo(
    () =>
      containersOptions?.length
        ? containersOptions?.find((o) => o.label === container) ??
          containersOptions[0]
        : { label: "", value: "" },
    [container, containersOptions]
  );

  const containerPicker = useMemo(() => {
    return (
      <ContainerPicker
        containersOptions={containersOptions ?? []}
        selectedContainer={
          selectedContainer as { label: string; value: string }
        }
        setContainer={setContainer}
        isPreviousLogs={false}
      />
    );
  }, [containersOptions, selectedContainer]);

  const {
    logs,
    retries,
    failureMessage,
    refreshCallback,
    tail,
    requestMoreLogs,
  } = usePodLogsInfo({
    podName,
    agentId,
    namespace,
    clusterName,
    controlledBy,
    selectedContainer,
  });

  if (displayUpgradeRequired) {
    return (
      <UpgradeRequired
        handleClose={() => setDisplayUpgradeRequired(false)}
        updateMessage={updateRequiredMessage}
        upgradeCommand={UpgradeCommands.UPGRADE_COMMAND}
        desiredFeature="View Pod Logs"
      />
    );
  }
  return (
    <LogsViewerModal
      isOpen
      handleCloseModal={handleCloseModal}
      onRefresh={refreshCallback}
      podLogsRes={logs ?? []}
      failureMessage={failureMessage}
      loading={(retries || 0) < MAX_RETRIES}
      podName={podName}
      containerName={selectedContainer.label}
      requestedCount={tail}
      onLoadMoreClicked={requestMoreLogs}
      containerPickerElement={containerPicker}
      clusterName={clusterName}
      namespace={namespace}
    />
  );
};
