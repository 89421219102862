import { isDevMode } from "../../../shared/utils/isDevMode";

import { useScopeAsServerRequestParam } from "./useScopeAsServerRequestParam";

export const useHasClustersScope = () => {
  const scope = useScopeAsServerRequestParam();
  return !!scope.clusters?.length;
};

export const useShouldPauseRequest = () => {
  const hasClustersScope = useHasClustersScope();
  return !hasClustersScope || isDevMode();
};
