import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";
import { DialogFeedback } from "../../../../../../../../common/DiagnosticsFeedback/DialogFeedback";
import { DiagnosticsFeedback } from "../../../../../../../../common/DiagnosticsFeedback/DiagnosticsFeedback";
import { useCreateViolationFeedback } from "../../../../../../../../../shared/hooks/reliability-api/violations/useCreateViolationFeedback";
import { useReportError } from "../../../../../../../hooks/useReportError";
import { useCurrentDrawerState } from "../../../../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../../../../shared/store/drawersStackStore/types";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const FEEDBACK_MESSAGE = "Did you find this useful?";

export const Feedback: React.FC = () => {
  const [showDialogFeedback, setShowDialogFeedback] = useState(false);
  const [value, setValue] = useState("");
  const { pathname, search } = useLocation();

  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();

  const violationId = currentDrawerState?.violationId;

  const { mutateAsync, isLoading } = useCreateViolationFeedback();

  const reportError = useReportError();

  const onDislikeClick = () => {
    setShowDialogFeedback(true);
  };

  const onClose = () => {
    setValue("");
    setShowDialogFeedback(false);
  };

  const onSubmit = async () => {
    try {
      await mutateAsync({
        id: violationId ?? "",
        body: {
          violationUrl: pathname + search,
          content: value,
          isPositive: false,
        },
      });
    } catch (e) {
      reportError(e, "createViolationFeedback");
    }
    setValue("");
    setShowDialogFeedback(false);
  };

  return (
    <FeedbackContainer>
      <DiagnosticsFeedback
        likeLabel={reliabilityArialLabels.violationDrawer.feedback.likeButton}
        dislikeLabel={
          reliabilityArialLabels.violationDrawer.feedback.dislikeButton
        }
        message={FEEDBACK_MESSAGE}
        customStyle={{ background: "white" }}
        onDislikeClick={onDislikeClick}
      />
      <DialogFeedback
        dialogLabel={
          reliabilityArialLabels.violationDrawer.feedback.feedbackDialog
        }
        submitButtonLabel={
          reliabilityArialLabels.violationDrawer.feedback.submitButton
        }
        open={showDialogFeedback}
        onClose={onClose}
        onSubmit={onSubmit}
        onValueChange={setValue}
        isLoading={isLoading}
      />
    </FeedbackContainer>
  );
};
