import React from "react";
import { palette } from "@komodorio/design-system";
import { ProgressBarComponent as ProgressBar } from "@komodorio/design-system/deprecated";

const PROGRESSBAR_HEIGHT = "0.3125rem";
const PROGRESSBAR_WIDTH = "14rem";

const getProgressBarColor = (fillPercentage: number): string => {
  switch (true) {
    case fillPercentage <= 33:
      return palette.green[500];
    case fillPercentage > 33 && fillPercentage <= 66:
      return palette.yellow[400];
    case fillPercentage >= 66:
      return palette.red[300];
    default:
      return palette.green[500];
  }
};

interface Props {
  eventsLimitPercentage: number;
}

export const EventsLimitsProgressBar: React.FC<Props> = ({
  eventsLimitPercentage,
}) => {
  const progressBarColor = getProgressBarColor(eventsLimitPercentage);

  return (
    <ProgressBar
      progressPercentage={eventsLimitPercentage}
      height={PROGRESSBAR_HEIGHT}
      width={PROGRESSBAR_WIDTH}
      isRound={true}
      lineColor={progressBarColor}
    />
  );
};
