import React, { createContext, FC, ReactNode, useContext } from "react";

import useNodesByClusterNameQuery from "./useNodesByClusterNameQuery";
import Node from "./Node";

const NodesContext = createContext<Node[] | null>(null);

interface NodesProviderProps {
  clusterName?: string;
  children?: React.ReactNode;
}

const NodesProvider: FC<NodesProviderProps> = ({ clusterName, children }) => {
  const nodes = useNodesByClusterNameQuery(clusterName);

  return (
    <NodesContext.Provider value={nodes}>{children}</NodesContext.Provider>
  );
};

const useNodesContext = (): Node[] | null => useContext(NodesContext);

const withNodesProvider = (
  children: ReactNode,
  clusterName: string | undefined
): JSX.Element => (
  <NodesProvider clusterName={clusterName}>{children}</NodesProvider>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export { NodesProvider, withNodesProvider, useNodesContext };
