/* eslint-disable max-lines */
import { useMemo } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { compact } from "lodash";
import Language from "@mui/icons-material/Language";

import { ExternalDnsRecord } from "@/generated/addonsApi";
import { getRandRange } from "@/shared/utils/getRandRange";
import { DateCell } from "@/components/k8sAddons/components/table/DateCell";
import { disableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { SyncStatus } from "@/components/k8sAddons/addons/externalDns/SyncStatus";

export const columnsConfig =
  disableLocalSortingInTableColumns<ExternalDnsRecord>({
    name: {
      field: "serviceName",
      headerName: "Name",
      flex: 1,
    },
    cluster: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    provider: {
      field: "dnsProvider",
      headerName: "DNS Provider",
      flex: 0.75,
    },
    managedRecords: {
      field: "numberManagedRecords",
      headerName: "Managed Records",
      flex: 0.75,
    },
    status: {
      field: "syncStatus",
      headerName: "Status",
      flex: 0.5,
    },
    lastSynced: {
      field: "lastSuccessfulSyncTime",
      headerName: "Last synced",
      flex: 1,
    },
  });

export const useTableColumns = (): GridColDef<ExternalDnsRecord>[] => {
  return useMemo(() => {
    return compact([
      columnsConfig.name,
      columnsConfig.cluster,
      columnsConfig.namespace,
      {
        ...columnsConfig.provider,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <Language color="action" />
              {params.row.dnsProvider}
            </Box>
          );
        },
      },
      columnsConfig.managedRecords,
      {
        ...columnsConfig.status,
        renderCell: (params) => {
          return <SyncStatus status={params.row.syncStatus} />;
        },
      },
      {
        ...columnsConfig.lastSynced,
        renderCell: (params) => {
          return params.row.lastSuccessfulSyncTime ? (
            <DateCell
              dateString={params.row.lastSuccessfulSyncTime}
              overrideOptions={{ timeZoneName: undefined }}
            />
          ) : null;
        },
      },
    ]);
  }, []);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof ExternalDnsRecord, number[]>> = {
  serviceName: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  clusterName: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  namespace: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  dnsProvider: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  numberManagedRecords: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  syncStatus: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  lastSuccessfulSyncTime: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
};

const getColumnRandWidth = (
  id: GridRowId,
  columnName: keyof ExternalDnsRecord
) => {
  const idAsNumber = parseInt(id.toString());
  const column = randRangeColumns[columnName];
  if (!column?.length) return 0;
  return column[idAsNumber % column.length];
};

const SKELETON_HEIGHT = 21;
export const useLoadingColumns = (): GridColDef<ExternalDnsRecord>[] => {
  return useMemo(
    () =>
      compact([
        {
          ...columnsConfig.name,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "serviceName")}
                height={SKELETON_HEIGHT}
              />
            );
          },
        },
        {
          ...columnsConfig.cluster,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "clusterName")}
                height={SKELETON_HEIGHT}
              />
            );
          },
        },
        {
          ...columnsConfig.namespace,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "namespace")}
              height={SKELETON_HEIGHT}
            />
          ),
        },
        {
          ...columnsConfig.provider,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "dnsProvider")}
              height={SKELETON_HEIGHT}
            />
          ),
        },
        {
          ...columnsConfig.managedRecords,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "numberManagedRecords")}
              height={SKELETON_HEIGHT}
            />
          ),
        },
        {
          ...columnsConfig.status,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "syncStatus")}
              height={SKELETON_HEIGHT}
              sx={{ borderRadius: "16px" }}
            />
          ),
        },
        {
          ...columnsConfig.lastSynced,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "lastSuccessfulSyncTime")}
              height={SKELETON_HEIGHT}
            />
          ),
        },
      ]),
    []
  );
};
