import React from "react";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";
import { MetricsTable } from "../../shared/metrics/components/MetricsTable/MetricsTable";
import { useMetricsSummaryByContainers } from "../../../hooks/useMetricsSummaryByContainers";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";

export const MissingCpuLimit: React.FC = () => {
  const { data } = useViolation();

  const {
    headerProps,
    summaryProps,
    dependentByViolationsIds,
    supportingData,
    komodorUid,
  } = useGetCommonDrawerProps(data?.data.violation);

  const { containerNames } = supportingData?.cpuLimitsMissing ?? {};

  const metricsSummaryByContainers = useMetricsSummaryByContainers({
    komodorUid,
    metricsType: "cpu",
    containerNames,
  });

  if (!data?.data.violation || !containerNames?.length) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription(containerNames.length)}
              <MetricsTable
                metrics={metricsSummaryByContainers.metrics}
                metricsType="cpu"
                category="limit"
                expectedNumOfMetrics={containerNames.length}
                isLoading={metricsSummaryByContainers.isLoading}
              />
              <ViolationsTableByIds
                title="Runtime impact"
                ids={dependentByViolationsIds}
                violationTableType={ViolationsTableType.riskAssessment}
              />
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={howDescription()}
        />
      }
    />
  );
};
