import React from "react";

import { FeaturePreviewPage } from "../../shared/components/FeaturePreviewPage";

import workflowTemplateUrl from "./assets/workflowTemplate.svg";

export const ArgoWorkflowsFeaturePreview = (): JSX.Element => {
  return (
    <FeaturePreviewPage
      title="Argo Workflows"
      tag="beta"
      note="Connect a cluster with Argo Workflows to begin using this feature"
      bullets={[
        "No setup required. Simply connect your existing cluster",
        "See all your workflows in one place",
        "Understand issues in past runs",
        "Correlate node issues to workflow failures",
      ]}
      img={
        <img src={workflowTemplateUrl} width="100%" alt="Workflow Template" />
      }
    >
      Effortlessly oversee all your Argo Workflows in one centralized hub in
      Komodor. Troubleshoot Kubernetes issues in your workflows seamlessly from
      a single dashboard, providing full visibility.
    </FeaturePreviewPage>
  );
};
