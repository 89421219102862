import { useCallback } from "react";

import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { setStoredAccountId } from "../../../shared/utils/auth0/localEnv/storedAccountId";
import { localStorageItemsToRemove } from "../LocalStorageItemsToRemove";
import { SERVICES } from "../../routes/routes";

import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { onAccountSwitch as k8sAddonsOnAccountSwitch } from "@/components/k8sAddons/utils/addonUtils";

const pathsToReroute = [`/${SERVICES}/`, "/main/resources/"];
const servicesPath = `/${SERVICES}`;

const onAccountSwitchCbs = [k8sAddonsOnAccountSwitch];

export const useAccountChooseHandler = () => {
  const { accountId: currentAccountId } = useUserMetadata();

  return useCallback(
    (account: { value: string; label: string } | undefined) => {
      if (!account || account.value === currentAccountId) return;
      dispatchEvent(AnalyticEvents.App.Account_Switcher_Select, {
        page: window.location.pathname,
        to: account.label,
      });

      setStoredAccountId(account.value);
      localStorageItemsToRemove.forEach((item) => item.remove());
      onAccountSwitchCbs.forEach((cb) => cb());

      const isPathToReroute = pathsToReroute.some((path) =>
        window.location.pathname.startsWith(path)
      );

      window.location.assign(
        isPathToReroute ? servicesPath : window.location.pathname
      );
    },
    [currentAccountId]
  );
};
