import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { useResizeObserver } from "@/shared/hooks/useResizeObserver";
import { useSyntheticViolationSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/SyntheticViolation/syntheticViolationHooks";
import { PageLoader } from "@/shared/components/PageLoader";

const IframeContainer = styled.div<{ height: number }>`
  iframe {
    width: 100%;
    height: ${({ height }) => `${height}px`};
    border: none;
  }
`;

const EVENT_SET_DIMENSIONS = "SET_DIMENSIONS";
const EVENT_SET_SCALE = "SET_SCALE";

export const SyntheticViolation: React.FC = () => {
  const { data } = useViolation();
  const { headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const [iframeHeight, setIframeHeight] = useState<number>(0);
  const [iframeWidth, setIframeWidth] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);

  const containerRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const getScale = useCallback(() => {
    if (!containerRef.current) return 0;
    const { width } = containerRef.current.getBoundingClientRect();
    return width / (iframeWidth + iframeWidth * 0.01);
  }, [iframeWidth]);

  const updateUiElements = useCallback(() => {
    if (!containerRef.current || !iframeRef.current) return;
    const scale = getScale();

    if (isNaN(scale)) return;

    iframeRef.current.contentWindow?.postMessage(
      { eventName: EVENT_SET_SCALE, payload: { scale } },
      "*"
    );
    setScale(scale);
  }, [getScale]);

  useEffect(() => {
    const onMessageReceived = (
      ev: MessageEvent<{
        eventName: string;
        payload: { height: number; width: number };
      }>
    ) => {
      if (
        ev.data?.eventName === EVENT_SET_DIMENSIONS &&
        ev.data?.payload?.height
      ) {
        setIframeHeight(ev.data.payload.height);
        setIframeWidth(ev.data.payload.width);
      }
    };

    window.addEventListener("message", onMessageReceived);

    return () => window.removeEventListener("message", onMessageReceived);
  }, [updateUiElements]);

  const { callback: debouncedUpdateUiElements } = useDebouncedCallback(
    updateUiElements,
    100
  );

  useResizeObserver(containerRef, debouncedUpdateUiElements);

  useEffect(() => {
    const newScale = getScale();
    if (!isNaN(newScale) && newScale !== scale) {
      updateUiElements();
    }
  }, [getScale, iframeWidth, scale, updateUiElements]);

  const { title, drawerHTML } = useSyntheticViolationSupportingData() ?? {};
  const hasDimensions = iframeHeight && iframeWidth;
  if (!drawerHTML) return <PageLoader />;

  return (
    <div style={{ opacity: hasDimensions ? 1 : 0 }}>
      <DrawerBase
        header={{ ...headerProps, title }}
        summary={{ data: summaryProps }}
        content={
          <IframeContainer
            height={scale * iframeHeight + iframeHeight * 0.01}
            ref={containerRef}
          >
            <iframe src={drawerHTML} ref={iframeRef} />
          </IframeContainer>
        }
      />
    </div>
  );
};
