import React from "react";

import { drawerStackIndexContext } from "./useDrawerStackIndex";

export const DrawerStackIndexProvider: React.FC<{
  drawerIndex: number;
  children: React.ReactNode;
}> = ({ drawerIndex, children }) => {
  return (
    <drawerStackIndexContext.Provider value={drawerIndex}>
      {children}
    </drawerStackIndexContext.Provider>
  );
};
