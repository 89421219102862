import { muiColors } from "@komodorio/design-system";

export const HighlightColorError = muiColors.pink[300];
export const HighlightColorWarning = muiColors.orange[200];

export type HighlightColor =
  | typeof HighlightColorError
  | typeof HighlightColorWarning;

export const ErrorCodingWords = ["error", "err"];
export const WarningCodingWords = ["warning", "warn"];
export const KeywordCodingWords = [
  "critical",
  "failed",
  "failure",
  "fail",
  "fatal",
  "timeout",
  "timed out",
  "exception",
  "unexpected",
  "unsuccessful",
  "unsuccessfully",
  "denied",
];

export const AllHighlightKeywords = [
  ...ErrorCodingWords,
  ...WarningCodingWords,
  ...KeywordCodingWords,
];

const ColorCodedWords = AllHighlightKeywords.join("|");
export const ColorCodeWordsRegex = new RegExp(
  `\\b(${ColorCodedWords})\\b`,
  "i"
);

export function numLength(n: number): number {
  return Math.ceil(Math.log10(n) + 1);
}

export function padLeadingZeros(n: number, size: number): string {
  if (size < 3) {
    size = 3;
  }
  return n.toString().padStart(size, "0");
}
