import styled from "styled-components";
import { Link } from "react-router-dom";

import { greenBrand, greenForUIText, pinkBrand } from "../../Colors";
import { TextLight } from "../common/typography";

import Dot from "./dot.svg?react";

export const healthyColor = greenBrand;
export const healthyTextColor = greenForUIText;

export const unhealthyColor = pinkBrand;

export const SpacerDot = styled(Dot)`
  margin: 0 0.3rem;
`;

export const Tags = styled.div`
  grid-area: tags;
  display: grid;
  gap: 0.25rem;
`;
export const Info = styled(TextLight)`
  grid-area: info;
  color: #9e9fa3;
`;
export const Container = styled(Link)`
  text-decoration: none;
  color: inherit;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  height: 10rem;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  border-top: 0.4rem solid ${healthyColor};
  &.unhealthy {
    border-top-color: ${unhealthyColor};
  }
  padding: 1rem;
  display: grid;
  grid-template:
    "title  icons" auto
    "status status" auto
    "info   info" auto
    ".      ." 1fr
    "tags tags" auto / 1fr auto;
  font-size: 0.75rem;
  gap: 0.5rem;
  align-items: center;
`;

export const HealthStatus = styled.div`
  display: flex;
  align-items: center;

  grid-area: status;
  font-weight: 900;
  color: ${healthyTextColor};
  ${Container}.unhealthy & {
    color: ${unhealthyColor};
  }
`;
