import React, { useState } from "react";
import { muiColors, palette } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import {
  Divider,
  MenuList,
  Popover,
  Typography,
} from "@komodorio/design-system/deprecated";
import OpenInNew from "@mui/icons-material/OpenInNew";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import styled, { css } from "styled-components";

import {
  Link,
  useDeployRelatedLinks,
} from "../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { ZIndex } from "../../constants/zIndex";
import { useInvestigationModeStore } from "../../shared/store/investigationModeStore/investigationModeStore";
import { AriaLabels } from "../../shared/config/ariaLabels";
import {
  issueSelector,
  komodorServiceSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import { showLink } from "../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/utils";
import { External_Link_Context_Enum } from "../../generated/graphql";
import { getAnnotations } from "../common/ProcessList/details/deployEvent/utils";

import { useDeployByServiceId } from "./availability/CorrelatedDeploys/useDeployByServiceId";

const StyledMenuList = styled(MenuList)`
  padding: 0.5rem 0;
  justify-content: center;
  align-content: center;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 0 0.5rem;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.2rem 0.5rem;
`;

const LinkContainer = styled.div`
  justify-content: start;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${muiColors.gray[100]};
  }
`;

const LinkIconStyle = css`
  border-radius: 0.25rem;
  background-repeat: no-repeat;
  background-position: center;
`;

const LinkIcon = styled.div<{ icon: string; big: boolean }>`
  ${LinkIconStyle};
  background-image: url(${({ icon }) => icon});
  height: 16px;
  width: 16px;
  background-size: ${({ big }) => (big ? "65%" : "100%")};
`;

export const LatestDeployLinksButton: React.FC = () => {
  const resource = useInvestigationModeStore(komodorServiceSelector);
  const issue = useInvestigationModeStore(issueSelector);

  const lastDeploys = useDeployByServiceId({
    serviceIds: resource?.id ? [resource.id] : [],
    toEpoch: issue?.eventTime?.getTime()?.toString(),
    fields: ["id", "newSpec"],
    limit: 1,
  });

  const deployRelatedLinks = useDeployRelatedLinks(
    getAnnotations(
      JSON.parse(
        (lastDeploys?.[0]?.newSpec as unknown as string) || "{}"
      ) as Record<string, unknown>
    ),
    {
      cluster: resource?.cluster,
      serviceId: resource?.id,
      namespace: resource?.namespace,
    }
  );

  return (
    <LinksButton
      links={deployRelatedLinks}
      buttonComponent={
        <Button
          variant="outlined"
          size="large"
          aria-label={AriaLabels.InvestigationMode.LinksButton}
        >
          Links
          <ArrowDropDown
            style={{
              fontSize: "16px",
              cursor: "pointer",
              marginLeft: "0.2rem",
            }}
          />
        </Button>
      }
    />
  );
};

export const LinksButton: React.FC<{
  links: Link[];
  buttonComponent: React.ReactElement;
}> = ({ links, buttonComponent }) => {
  const [showLinks, setShowLinks] = useState(false);

  const filteredLinks = links.filter((link) =>
    showLink(link, External_Link_Context_Enum.Drawer)
  );

  return (
    <>
      {filteredLinks.length > 0 ? (
        <Popover
          containerStyle={{ zIndex: ZIndex.AboveModal.toString() }}
          isOpen={showLinks}
          align={"end"}
          handleClose={() => setShowLinks(false)}
          content={<LinksMenu links={filteredLinks} />}
        >
          {React.cloneElement(buttonComponent, {
            onClick: () => {
              setShowLinks((prevShowLinks) => !prevShowLinks);
              if (buttonComponent.props.onClick) {
                buttonComponent.props.onClick();
              }
            },
          })}
        </Popover>
      ) : null}
    </>
  );
};

const LinksMenu: React.FC<{ links: Link[] }> = ({ links }) => {
  return (
    <StyledMenuList width="100%">
      <MenuHeader>
        <Typography variant={"uppercase"} style={{ paddingBottom: "4px" }}>
          Links
        </Typography>
      </MenuHeader>
      <Divider />
      <MenuContent>
        {links.map((link) => (
          <LinkContainer
            onClick={() => window.open(link.url, "_blank")}
            aria-label={AriaLabels.InvestigationMode.LinksMenuLink}
          >
            <LinkIcon icon={link.icon} big={false} />
            <Typography>{link.text}</Typography>
            <OpenInNew
              style={{
                fontSize: "16px",
                color: palette.gray["400"],
                cursor: "pointer",
              }}
            />
          </LinkContainer>
        ))}
      </MenuContent>
    </StyledMenuList>
  );
};
