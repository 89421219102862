import styled from "styled-components";
import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";

import { MetricsPreferences } from "../../../../../Metrics/MetricsPreferences/MetricsPreferences";
import { DefaultTimeframeOptions } from "../../../../../Metrics/types";
import { useStoreIsFetchingForReport } from "../../../useReportClusterOverviewLoadingTime";

import { EnlargeableClusterGraph } from "./EnlargeableClusterGraph";
import { useClusterMetrics } from "./useClusterMetrics";
import { useTransformData } from "./utils";
import { useClusterMetricsWithZoom } from "./useClusterMetricsWithZoom";
import { useClusterMetricsContext } from "./ClusterMetricsContext";

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  padding-top: 16px;
  align-items: center;
  width: fill-available;
  height: 380px;
`;

const GraphsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 340px;
  width: 100%;
  justify-content: center;
  gap: 24px;
`;

const SingleGraphContainer = styled.div`
  border-radius: 4px;
  align-items: center;
  padding: 8px;
  border: 1px solid ${muiColors.gray[200]}}
`;

export const MetricsBox: React.FC<{
  clusterName: string;
}> = ({ clusterName }) => {
  const syncId = "syncId";
  const { aggregationType, setAggregationType, timeWindow, setTimeWindow } =
    useClusterMetricsContext();

  const { fromEpoch, toEpoch, graphProps } = useClusterMetricsWithZoom();

  const requestParams = useMemo(() => {
    return {
      clusterName,
      fromEpoch,
      toEpoch,
    };
  }, [clusterName, fromEpoch, toEpoch]);

  const { hasData, loading, error, data } = useClusterMetrics(requestParams);
  useStoreIsFetchingForReport("isFetchingClusterUsageMetrics", loading);

  const transformedData = useTransformData(data);

  return (
    <Container>
      <MetricsPreferences
        aggregationSelectorAriaLabel={"aggregationToggle"}
        aggregationMetric={aggregationType}
        setAggregationMetric={setAggregationType}
        timeOptions={DefaultTimeframeOptions}
        timeWindow={timeWindow}
        setTimeWindow={setTimeWindow}
      />
      <GraphsContainer>
        <SingleGraphContainer>
          <EnlargeableClusterGraph
            title={"Memory"}
            clusterName={clusterName}
            type={"memory"}
            loading={loading}
            error={!!error}
            syncId={syncId}
            metrics={transformedData["memory"]}
            aggregationType={aggregationType}
            hasData={hasData}
            {...graphProps}
          />
        </SingleGraphContainer>
        <SingleGraphContainer>
          <EnlargeableClusterGraph
            title={"CPU"}
            clusterName={clusterName}
            type={"cpu"}
            loading={loading}
            error={!!error}
            syncId={syncId}
            metrics={transformedData["cpu"]}
            aggregationType={aggregationType}
            hasData={hasData}
            {...graphProps}
          />
        </SingleGraphContainer>
      </GraphsContainer>
    </Container>
  );
};
