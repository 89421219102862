import { useMemo } from "react";

import {
  CheckType,
  ViolationSupportingData,
} from "../../../generated/reliabilityApi";
import { getViolationTitle } from "../utils/reliabilityUtils";

export const useGetViolationTitle = (
  checkType: CheckType | undefined,
  supportingData: ViolationSupportingData | undefined
) => {
  return useMemo(
    () => getViolationTitle(checkType, supportingData),
    [checkType, supportingData]
  );
};
