import { useQuery } from "@tanstack/react-query";
import { cloneDeep, merge } from "lodash";

import { useWorkspaceQueryKey } from "../workspaces-api/useWorkspaceQueryKey";

import { QueryOptions, useK8sAddonsApiClient } from "./apiClient";

import {
  AddonsApiGetEntityListRequest,
  getEntityListUrl,
  EntityListResponse,
} from "@/generated/addonsApi";

export const GET_ADDONS_LIVE_STATE_LIST = "api/v1/addons/:addon/:entity";

export const useGetAddonLiveStateList = (
  params: AddonsApiGetEntityListRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useK8sAddonsApiClient();

  const url = getEntityListUrl(params, apiClient.defaults.baseURL ?? "");

  // Remove mostRecentDataFrom from the query key to avoid unnecessary re-fetching
  const paramsKeys = merge(cloneDeep(params), {
    getEntityListRequest: { mostRecentDataFrom: "" },
  });

  const keys = useWorkspaceQueryKey([url, paramsKeys]);

  return useQuery(
    keys,
    async () =>
      await apiClient.post<EntityListResponse>(
        getEntityListUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.getEntityListRequest }
      ),
    queryOptions
  );
};
