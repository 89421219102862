import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl } from "../common-api/request";
import { EndpointsResponseType } from "../common-api/types";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead.
 */
export const useAppInsightsAPIGet = <
  M extends { [key in keyof EndpointsResponseType]: M },
  T extends keyof EndpointsResponseMap = keyof EndpointsResponseMap
>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<M> => {
  const url = EndpointRequestPathMap[relativePath](
    parameters as never,
    getAPIBasePath()
  );
  return useApiGetWithUrl<M>(url, pause);
};

const getAPIBasePath = (): string => {
  return getAppConfig().appInsightsAPIServerURL;
};
