import { EnrichedMessage, K8sResourceRegex } from "../types";
import { KubernetesConfigMapResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesConfigMapResource";
import { K8sResourceToken, StringToken } from "../tokens";

import { getStringAfterLastDelimiter } from "./utils";
import { BaseEnricher } from "./baseEnricher";

export class ConfigMapNotFoundEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`configmap "${K8sResourceRegex}" not found`, "i"));
  }

  searchKey(): string {
    return "configmap";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          "The service is configured to use a ConfigMap which does not exist."
        ),
      ],
    };
  }
}

export class MountVolumeSetUpForConfigMapFailedEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `MountVolume.SetUp failed for volume "${K8sResourceRegex}" : configmap "${K8sResourceRegex}" not found`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "MountVolume.SetUp failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          "The service is configured to use a ConfigMap which does not exist."
        ),
      ],
    };
  }
}

export class ConfigMapCouldNotFindKeyEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(`couldn't find key (.+) in configmap ${K8sResourceRegex}`, "i")
    );
  }

  searchKey(): string {
    return "couldn't find key";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const keyName = match[1];
    const configMap = getStringAfterLastDelimiter(match[2], "/");

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("A"),
        new K8sResourceToken(
          "ConfigMap",
          KubernetesConfigMapResource.Kind,
          configMap
        ),
        new StringToken(
          `that the service was configured to use is missing the key "${keyName}".`
        ),
      ],
    };
  }
}

export class MountVolumeSetUpForConfigMapNonExistentKeyEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `MountVolume.SetUp failed for volume "${K8sResourceRegex}" : configmap "${K8sResourceRegex}" non-existent config key: (.+)`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "MountVolume.SetUp failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const configMap = getStringAfterLastDelimiter(match[2], "/");
    const keyName = match[3];

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("The volume mount setup failed because a"),
        new K8sResourceToken(
          "ConfigMap",
          KubernetesConfigMapResource.Kind,
          configMap
        ),
        new StringToken(
          `that the service was configured to use is missing the key "${keyName}".`
        ),
      ],
    };
  }
}

export class MountVolumeSetupFailedToSyncCacheEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `MountVolume.SetUp failed for volume "${K8sResourceRegex}" : failed to sync configmap cache.*`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "MountVolume.SetUp failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const volume = match[1];

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          `The volume ${volume} mount setup failed because it was unable to sync a cache key`
        ),
      ],
    };
  }
}
