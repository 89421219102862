import React, { memo } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import {
  cardConfigToDataKey,
  overviewCardsOrder,
} from "../../../../CostOptimizationView/constants/costOptimizationConstants";
import { CostAllocationSummaryResponse } from "../../../../../generated/metricsApi";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

export const Stats: React.FC<{
  data: CostAllocationSummaryResponse | undefined;
  isLoading: boolean;
}> = memo(({ data, isLoading }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
      aria-label={AriaLabels.ClusterOverview.CostSection.StatsGrid}
    >
      {overviewCardsOrder.map((dataKey) => {
        const { label, formatter, color } = cardConfigToDataKey[dataKey];
        const value = data?.[dataKey];
        return (
          <StatContainer key={dataKey}>
            <Typography variant="h5" color="text.secondary">
              {label}
            </Typography>
            {isLoading ? (
              <Skeleton
                width="206px"
                height="23px"
                sx={{ transform: "none", marginBlock: "12px" }}
              />
            ) : value ? (
              <Typography variant="h1" color={color}>
                {formatter(value)}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                color="text.disabled"
                sx={{ marginBlock: "12px" }}
              >
                N/A
              </Typography>
            )}
          </StatContainer>
        );
      })}
    </Box>
  );
});
