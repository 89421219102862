import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { JobsData } from "@/generated/workspacesApi";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { DistributionItem } from "@/components/ClustersView/components/DistributionItem";
import { AriaLabels } from "@/shared/config/ariaLabels";

const { GreenJobs, GrayJobs, RedJobs } = AriaLabels.ClustersView.JobsCell;

const JobsTooltipItem: React.FC<{
  name: string;
  count: number;
  color: string | undefined;
  ariaLabel?: string;
}> = ({ name, count, color, ariaLabel }) => {
  return (
    <>
      <DistributionItem
        statusShape="circle"
        color={color}
        ariaLabel={ariaLabel}
      >
        <Typography variant="body3" fontWeight={700}>
          {count.toLocaleString()}
        </Typography>
      </DistributionItem>
      <Typography variant="body3">{name}</Typography>
    </>
  );
};

export const JobsCell: React.FC<{ jobs: JobsData }> = ({ jobs }) => {
  const tooltipContent = (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        alignItems: "center",
        columnGap: "8px",
        paddingInline: "4px",
      }}
    >
      <JobsTooltipItem
        name="Healthy"
        count={jobs.green.count}
        color={muiColors.green[500]}
        ariaLabel={GreenJobs}
      />
      <JobsTooltipItem
        name="Pending"
        count={jobs.gray.count}
        color={muiColors.gray[400]}
        ariaLabel={GrayJobs}
      />
      <JobsTooltipItem
        name="Failed"
        count={jobs.red.count}
        color={muiColors.red[500]}
        ariaLabel={RedJobs}
      />
    </Box>
  );

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={jobs.count > 0 ? tooltipContent : undefined}
      placement="top"
    >
      <div>{jobs.count.toLocaleString()}</div>
    </Tooltip>
  );
};
