import { OptionType, Select } from "@komodorio/design-system/deprecated";
import React, { useEffect, useMemo } from "react";

import { costOptimizationAriaLabels as ariaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { ZIndex } from "../../../../../../constants/zIndex";
import { commonSelectProps } from "../../../shared/commonSelectProps";
import { useCostOptimizationStore } from "../../../../store/costOptimizationStore";
import {
  selectRightSizingFilters,
  selectSetRightSizingFilters,
} from "../../../../store/costOptimizationStoreSelectors";
import { CostStrategy } from "../../../../types/costOptimizationTypes";
import { useCostOptimizationDataInUrl } from "../../../../hooks/persistentStateHooks";
import {
  STRATEGY_VALUE_TO_LABEL,
  STRATEGY_VALUE_TO_SUBLABEL,
  STRATEGY_OPTIONS,
} from "../../../../constants/costOptimizationConstants";

export const PageSelectStrategy: React.FC = () => {
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);
  const [{ optimizationStrategy: optimizationStrategyFromUrl }, setDataInUrl] =
    useCostOptimizationDataInUrl();
  const setRightSizingFilters = useCostOptimizationStore(
    selectSetRightSizingFilters
  );

  useEffect(() => {
    const initialStrategy = optimizationStrategyFromUrl
      ? optimizationStrategyFromUrl
      : strategy;
    setRightSizingFilters({ strategy: initialStrategy });
  }, [setRightSizingFilters, optimizationStrategyFromUrl, strategy]);

  const onChange = (selected: OptionType<string>) => {
    setRightSizingFilters({
      strategy: selected.value as CostStrategy,
    });
    setDataInUrl({
      optimizationStrategy: selected.value as CostStrategy,
    });
  };

  const value = useMemo(
    () => ({
      label: STRATEGY_VALUE_TO_LABEL[strategy],
      subLabel: STRATEGY_VALUE_TO_SUBLABEL[strategy],
      value: strategy,
    }),
    [strategy]
  );

  const selectOptions = useMemo(
    () =>
      STRATEGY_OPTIONS.map((option) => ({
        label: STRATEGY_VALUE_TO_LABEL[option],
        subLabel: STRATEGY_VALUE_TO_SUBLABEL[option],
        value: option,
      })),
    []
  );

  return (
    <>
      <div>
        <Select
          listZIndex={`${ZIndex.AboveModal}`}
          {...commonSelectProps}
          aria-label={ariaLabels.rightSizingPage.strategy}
          value={value}
          placeholder={"Clusters"}
          options={selectOptions}
          onChange={onChange}
        />
      </div>
    </>
  );
};
