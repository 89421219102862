import { useCallback } from "react";
import { OperationResult } from "urql";

import {
  CreateWorkflowConfigurationMutation,
  useCreateWorkflowConfigurationMutation,
} from "../../../generated/graphql";
import { WorkflowConfigType, WorkflowConfiguration } from "../common/types";

type ConfigDataState = Omit<
  WorkflowConfiguration,
  "createdAt" | "updatedAt" | "active" | "type"
> & { type?: WorkflowConfigType };

export type CreateWorkflowConfigurationResponse = (
  config: ConfigDataState
) => Promise<OperationResult<CreateWorkflowConfigurationMutation>>;

export const useCreateWorkflowConfiguration =
  (): CreateWorkflowConfigurationResponse => {
    const [, createWorkflowConfiguration] =
      useCreateWorkflowConfigurationMutation();

    return useCallback(
      (config: ConfigDataState) => {
        const params = {
          id: config.id,
          name: config.name,
          type: config.type ?? "",
          sensors: config.sensors,
          variables: config.variables,
          sinks: config.sinks,
          sinksOptions: config.sinksOptions,
        };
        return createWorkflowConfiguration(params);
      },
      [createWorkflowConfiguration]
    );
  };
