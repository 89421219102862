import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { AlertTriangle16 } from "@komodorio/design-system/icons";
import { TooltipWrapper } from "react-tooltip";

import WorkflowIssueEventGroup from "../../../../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WORKFLOW_CONFIG_TYPES } from "../../../../../../../common/EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../../../../../monitorsView/common/types";
import { useOpenIssueInEventItemDrawer } from "../issueItemHooks";
import { useOpenIssueByResource } from "../../../../../../../OpenIssues/useOpenIssuesByResource";
import { useOpenIssueTooltip } from "../../../../../../../OpenIssues/useOpenIssueTooltip";
import { ServiceInfo } from "../../../../../../../../shared/types/ServiceInfo";

import { AvailabilityActionButton } from "./actionButtons/AvailabilityActionButton";

const Container = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 4px;
  border-radius: 4px;
  & > span {
    display: inherit;
    align-items: inherit;
    flex-grow: 1;
  }
`;

const Circle = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const ActionButtonContainer = styled.div`
  margin-left: auto;
`;

const Title = styled(Typography)`
  && {
    margin-right: 8px;
  }
`;

type IssueInfoProps = {
  workflowIssue: WorkflowIssueEventGroup;
  serviceInfo?: ServiceInfo;
};

const getDesignSchemePerIssueType = (workflowType: WorkflowConfigType) => {
  // currently only availability is supported
  return {
    backgroundColor: muiColors.pink[25] ?? "",
    iconCircleColor: muiColors.pink[100] ?? "",
    icon: <AlertTriangle16 fill={muiColors.pink[500]} />,
  };
};

const getActionButtonPerIssueType = (
  workflowIssue: WorkflowIssueEventGroup
) => {
  // currently only availability is supported
  return <AvailabilityActionButton workflowIssue={workflowIssue} />;
};

export const IssueInfo: React.FC<IssueInfoProps> = ({
  workflowIssue,
  serviceInfo,
}) => {
  const { workflowType, summary } = workflowIssue;
  const type = WORKFLOW_CONFIG_TYPES[workflowType];
  const { backgroundColor, iconCircleColor, icon } =
    getDesignSchemePerIssueType(workflowType);

  const openIssueInEventItemDrawer = useOpenIssueInEventItemDrawer();
  const openIssue = useOpenIssueByResource(
    workflowIssue.clusterName,
    workflowIssue.namespace,
    serviceInfo?.kind ?? "",
    serviceInfo?.title ?? "",
    workflowIssue.serviceId
  );

  const onViewDetailsClick = () => {
    openIssueInEventItemDrawer(workflowIssue);
  };

  const [tooltip, tooltipId] = useOpenIssueTooltip(
    openIssue,
    serviceInfo,
    onViewDetailsClick,
    workflowIssue.serviceId
  );

  return (
    <Container color={backgroundColor}>
      <TooltipWrapper tooltipId={tooltipId}>
        <Circle color={iconCircleColor}>{icon}</Circle>
        <Title variant={"h6"}>{`${type} -`}</Title>
        <Typography variant={"body3"}>{summary}</Typography>
        {tooltip}
      </TooltipWrapper>
      <ActionButtonContainer>
        {getActionButtonPerIssueType(workflowIssue)}
      </ActionButtonContainer>
    </Container>
  );
};
