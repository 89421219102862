import {
  MuiSelectionOption,
  MultiSelectPopover,
} from "@komodorio/design-system/komodor-ui";
import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { useCallback, useMemo, useState } from "react";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import ViewWeekSharpIcon from "@mui/icons-material/ViewWeekSharp";

import { ColumnField } from "@/components/ClustersView/components/ClustersList/types";
import { availableColumnsForClustersTable } from "@/components/ClustersView/components/ClustersList/availableColumnsForClustersTable";

interface ManageColumnsButtonProps {
  columns: ColumnField[];
  columnsVisibility: GridColumnVisibilityModel | undefined;
  setColumnsVisibility: (
    newColumnsVisibility: GridColumnVisibilityModel
  ) => void;
  sx?: SxProps<Theme>;
}

export const ManageColumnsButton: React.FC<ManageColumnsButtonProps> = ({
  columns,
  columnsVisibility,
  setColumnsVisibility,
  sx,
}) => {
  const [columnsAnchorEl, setColumnsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const options = useMemo(
    () =>
      columns
        .filter((c) => c !== "clusterName")
        .map((c) => {
          const { field, headerName } =
            availableColumnsForClustersTable.find(
              (availableColumn) => availableColumn.field === c
            ) ?? {};
          return { label: headerName ?? "", value: field ?? "" };
        }),
    [columns]
  );

  const value = useMemo(() => {
    if (!columnsVisibility || !Object.keys(columnsVisibility).length) {
      return options;
    }
    return options.filter((option) => columnsVisibility[option.value] ?? true);
  }, [columnsVisibility, options]);

  const onChange = useCallback(
    (selectedOptions: MuiSelectionOption<string>[]) => {
      setColumnsVisibility(
        options.reduce((acc, { value }) => {
          acc[value] = selectedOptions.some((option) => option.value === value);
          return acc;
        }, {} as GridColumnVisibilityModel)
      );
    },
    [options, setColumnsVisibility]
  );

  return (
    <>
      <Button
        startIcon={<ViewWeekSharpIcon />}
        sx={sx}
        onClick={(e) => setColumnsAnchorEl(e.currentTarget)}
      >
        Columns
      </Button>
      <MultiSelectPopover
        anchorEl={columnsAnchorEl}
        onClose={() => setColumnsAnchorEl(null)}
        options={options}
        value={value}
        onChange={onChange}
        texts={{
          searchPlaceholder: "Search",
        }}
        selectPopoverProps={{
          width: "auto",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "right" },
        }}
      />
    </>
  );
};
