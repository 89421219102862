import React, { useEffect, useMemo, useRef } from "react";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { EditPolicyButton } from "../PolicyDrawerHeader/EditPolicyButton";
import { CreatePolicyButton } from "../PolicyDrawerHeader/CreatePolicyButton";
import { useOnCloseDrawerWithConfirmation } from "../hooks/useOnCloseDrawerWithConfirmation";

const StyledPaper = styled(Paper)`
  && {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${muiColors.gray[200]};
    padding: 16px 24px;
    gap: 8px;
  }
`;

export const PolicyDrawerFooter: React.FC = () => {
  const { policyId, isReadOnlyMode, setSubmitFormRef } =
    usePolicyDrawerContext();
  const onCloseDrawerWithConfirmation = useOnCloseDrawerWithConfirmation();
  const divRef = useRef<HTMLDivElement>(null);

  const isEditPolicyMode = policyId !== undefined;

  const actionButtonContent = useMemo(() => {
    if (isReadOnlyMode) return null;
    return isEditPolicyMode ? <EditPolicyButton /> : <CreatePolicyButton />;
  }, [isEditPolicyMode, isReadOnlyMode]);

  useEffect(() => {
    if (divRef.current) {
      setSubmitFormRef(divRef);
    }
  }, [setSubmitFormRef]);

  return (
    <StyledPaper ref={divRef}>
      <Button variant="outlined" onClick={onCloseDrawerWithConfirmation}>
        Cancel
      </Button>
      {actionButtonContent}
    </StyledPaper>
  );
};
