import React from "react";
import useClipboard from "react-use-clipboard";
import ContentCopy from "@mui/icons-material/ContentCopy";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { OverflowTextStyle } from "../../../../../../../../../../../shared/styles/styles";

const Container = styled.div`
  display: flex;
  gap: 8px;
  max-width: 100%;
  align-items: center;
`;
const Text = styled(Typography)`
  ${OverflowTextStyle};
  flex-grow: 1;
`;

const NoApiContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CopyContainer = styled(Tooltip).attrs({ placement: "top", arrow: true })`
  cursor: pointer;
`;

export const CopyApi: React.FC<{ api: string }> = ({ api }) => {
  return (
    <Container>
      <Text variant="body2" color="text.primary" noWrap>
        {api}
      </Text>
      <CopyApiButton api={api} />
    </Container>
  );
};

export const CopyApiButton: React.FC<{ api: string }> = ({ api }) => {
  const [copied, copy] = useClipboard(api, { successDuration: 1_500 });
  return (
    <CopyContainer title={copied ? "copied!" : "copy"}>
      <ContentCopy fontSize="small" color={"primary"} onClick={copy} />
    </CopyContainer>
  );
};

export const NoApiAlert: React.FC = () => {
  return (
    <NoApiContainer>
      <Typography variant="body2" color="text.primary">
        N/A
      </Typography>
      <Tooltip title="No replacement API available" placement="top">
        <InfoOutlined fontSize="small" color="disabled" />
      </Tooltip>
    </NoApiContainer>
  );
};
