import React, { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { cleanupKomodorLocalStorage } from "../../shared/utils/localStorageSettings";
import { getAppConfig } from "../../shared/config/appConfig";
import { LinesLoader } from "../common/loaders/Line";
import { FlexColumns } from "../common/FlexContainers";
import { H2 } from "../common/typography";

export const BackgroundContainer = styled(FlexColumns)`
  width: 100vw;
  height: calc(100vh - 45px); // AppBar == 45px height
  align-items: center;
  justify-items: center;
  padding-top: 20%;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useLogout: () => () => void = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return useCallback(() => {
    cleanupKomodorLocalStorage();
    logout({
      returnTo: getAppConfig().authRedirectUri,
    });
    setTimeout(() => navigate("/", { replace: true }), 1000); // the delay is for the 'Logging out...' component to not just disappear
  }, [logout, navigate]);
};

export const LogoutScene: React.FC = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <BackgroundContainer>
      <H2>Logging out...</H2>
      <LinesLoader marginTop={"10px"} />
    </BackgroundContainer>
  );
};
