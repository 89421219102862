import React from "react";
import { ReferenceLine } from "recharts";
import styled from "styled-components";
import { Close16 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";

import { grayReferenceLine } from "../../../../Colors";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";
import {
  LineGroup,
  LineGroupLabel,
} from "@/components/common/EventsChart/TimelineChart/types";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const LineLabel = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  margin-inline-start: 8px;
  width: max-content;
  white-space: nowrap;
`;

interface ReferenceLineLabelProps {
  viewBox: {
    x: number;
    y: number;
  };
}
const renderLabel =
  ({
    label,
    chartConfig,
    hoveredLabel,
    setHoveredLabel,
    isFirst,
    removeSwimlane,
  }: {
    label: LineGroupLabel | LineGroupLabel[];
    chartConfig: TimelineChartConfig;
    hoveredLabel?: string;
    setHoveredLabel?: (label: string | undefined) => void;
    isFirst?: boolean;
    removeSwimlane?: (label: string) => void;
  }) =>
  ({ viewBox }: ReferenceLineLabelProps) => {
    const labels = Array.isArray(label) ? label : [label];
    const hoveredName = labels.at(-1)?.name ?? "";
    return (
      <foreignObject
        x={viewBox.x}
        y={viewBox.y - chartConfig.swimlaneLabelYOffset}
        overflow="visible"
      >
        <div
          style={{
            display: "flex",
            width: "max-content",
            paddingTop: isFirst ? "4px" : undefined,
            userSelect: "none",
          }}
          onMouseEnter={() => {
            setHoveredLabel?.(hoveredName);
          }}
          onMouseLeave={() => {
            setHoveredLabel?.(undefined);
          }}
        >
          <LineLabel>
            {labels.map(({ prefix, name }, i) => (
              <>
                {prefix && <Typography variant="h6">{prefix}</Typography>}
                <Typography variant="body3" color="text.secondary">
                  {name}
                </Typography>
                {i < labels.length - 1 && (
                  <Typography variant="body3" color="text.secondary">
                    |
                  </Typography>
                )}
              </>
            ))}
            <Close16
              style={{
                visibility:
                  hoveredName && hoveredLabel === hoveredName
                    ? undefined
                    : "hidden",
              }}
              onClick={() => removeSwimlane?.(hoveredName)}
            />
          </LineLabel>
        </div>
      </foreignObject>
    );
  };

const createReferenceLines = ({
  lineGroups,
  hoveredLabel,
  setHoveredLabel,
  chartConfig,
  removeSwimlane,
}: {
  lineGroups: LineGroup[];
  hoveredLabel?: string;
  setHoveredLabel?: (label: string | undefined) => void;
  chartConfig: TimelineChartConfig;
  removeSwimlane?: (label: string) => void;
}): JSX.Element[] => {
  return lineGroups.map(({ label }, index) => {
    const key = Array.isArray(label) ? label.at(-1)?.name : label.name;
    const isFirstRenderedLine = index === lineGroups.length - 1;
    return (
      <ReferenceLine
        key={key}
        stroke={grayReferenceLine}
        pointerEvents="none"
        y={index + 1}
        label={renderLabel({
          label,
          hoveredLabel,
          setHoveredLabel,
          chartConfig,
          isFirst: isFirstRenderedLine,
          removeSwimlane,
        })}
      />
    );
  });
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default createReferenceLines;
