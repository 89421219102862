export const description = `Achieve comprehensive insights into ExternalDNS with Komodor for effective DNS management across your clusters. Ensure your DNS records are consistently updated and synchronized with your providers, while easily monitoring performance and troubleshooting sync issues to prevent disruptions.`;
export const includedFeatures = [
  "Seamlessly integrate ExternalDNS across all of your clusters",
  "Gain unified visibility into all of your ExternalDNS instances, managed records, and affected services",
  "Easily identify and troubleshoot unsynced records",
];

export const ariaLabels = {
  container: "External DNS coming soon",
};
