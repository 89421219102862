import React, { useCallback } from "react";

import { usePodExecSessionsContext } from "../../shared/context/PodExecSessionsProvider";
import { useHandleClosePodExecDrawer } from "../Actions/buttons/ExecPodShell/utils/useHandleClosePodExecDrawer";

import { PodExecDrawer } from "./PodExecDrawer";
import { PodExecMinimizedSessions } from "./PodExecMinimizedSessions";

export const PodExecSessions: React.FC = () => {
  const {
    openDrawerIds,
    removeDrawerSessionId,
    addDrawerSessionId,
    addMinimizedSessionId,
    setSessionProps,
    getSessionProps,
    openMinimizedIds,
    removeMinimizedSessionId,
  } = usePodExecSessionsContext();
  const closeExecSession = useHandleClosePodExecDrawer();

  const onClosePodExecDrawer = useCallback(
    (id: string) => {
      removeDrawerSessionId(id);
      removeMinimizedSessionId(id);
      setSessionProps(id, { containerName: undefined });
      closeExecSession(id);
    },
    [
      removeDrawerSessionId,
      removeMinimizedSessionId,
      setSessionProps,
      closeExecSession,
    ]
  );

  const onCloseMinimizedPodExec = useCallback(
    (id: string) => {
      setSessionProps(id, { containerName: undefined });
      removeMinimizedSessionId(id);
      removeDrawerSessionId(id);
      closeExecSession(id);
    },
    [
      setSessionProps,
      removeMinimizedSessionId,
      removeDrawerSessionId,
      closeExecSession,
    ]
  );

  const onPodExecMinimized = useCallback(
    (id: string) => {
      removeDrawerSessionId(id);
      addMinimizedSessionId(id);
    },
    [removeDrawerSessionId, addMinimizedSessionId]
  );

  const onMaximize = useCallback(
    (id: string) => {
      addDrawerSessionId(id);
      removeMinimizedSessionId(id);
    },
    [addDrawerSessionId, removeMinimizedSessionId]
  );

  return (
    <>
      {openDrawerIds.map((id) => {
        const { containerName, ...rest } = getSessionProps(id);
        return (
          <PodExecDrawer
            key={id}
            identifier={id}
            open={true}
            onClose={() => onClosePodExecDrawer(id)}
            onMinimize={() => onPodExecMinimized(id)}
            {...rest}
            containerName={containerName as string}
          />
        );
      })}
      <PodExecMinimizedSessions
        sessionIds={openMinimizedIds}
        onMaximize={onMaximize}
        onCloseSession={onCloseMinimizedPodExec}
      />
    </>
  );
};
