import Typography from "@mui/material/Typography";

import {
  StyledUnorderedList,
  TextContainer,
} from "../../../violationDrawerStyles";

export const getWhyDescription = () => (
  <TextContainer>
    Automatic certificate renewal is crucial for maintaining security,
    availability, and compliance by ensuring certificates are always valid, thus
    preventing potential vulnerabilities and service disruptions. It also
    enhances operational efficiency and user trust by eliminating the risk of
    expired certificates.
  </TextContainer>
);

export const expiredHowToFix = () => (
  <div>
    <Typography variant={"body2"}>
      If a certificate has passed its renewal date and was not renewed
      automatically, investigate the cause. Common reasons for renewal failures
      include:
    </Typography>
    <StyledUnorderedList $customPaddingLeft="24px">
      <li>
        <Typography variant={"body2"}>Misconfigured cert-manager</Typography>
      </li>
      <li>
        <Typography variant={"body2"}>Insufficient permissions</Typography>
      </li>
      <li>
        <Typography variant={"body2"}>
          Issues with the Certificate Issuer (e.g., ACME challenges not being
          completed)
        </Typography>
      </li>
    </StyledUnorderedList>
    <TextContainer>
      <TextContainer>
        Once you've identified the issue, you can{" "}
        <b>manually trigger a renewal</b> by deleting the CertificateRequest
        resource associated with the expired certificate. Cert-manager will
        automatically create a new CertificateRequest to renew the certificate.
      </TextContainer>
    </TextContainer>
  </div>
);

export const aboutToExpireHowToFix = () => (
  <div>
    <Typography variant={"body2"}>
      If a certificate is approaching its expiration date, you can take
      proactive steps to avoid service disruptions:
    </Typography>
    <StyledUnorderedList $customPaddingLeft="24px">
      <li>
        <TextContainer>
          <b>Check cert-manager Configuration & permissions:</b> Ensure that the
          cert-manager is configured correctly to renew the certificate before
          expiration and has the necessary permissions to do so.
        </TextContainer>
      </li>
      <li>
        <TextContainer>
          <b>Inspect the Certificate Issuer:</b> Ensure there are no issues with
          the Certificate Issuer, such as pending ACME challenges or unreachable
          endpoints.
        </TextContainer>
      </li>
      <li>
        <TextContainer>
          <b>Monitor for Automatic Renewal:</b> Cert-manager is designed to
          automatically renew certificates before they expire. Ensure there are
          no errors in the logs or pending CertificateRequest resources that
          could prevent this process.
        </TextContainer>
      </li>
    </StyledUnorderedList>
    <TextContainer>
      <TextContainer>
        By addressing potential issues early, you can avoid any lapse in
        certificate coverage and ensure continuous service availability.
      </TextContainer>
    </TextContainer>
  </div>
);
