import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { useHasChangedFormValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasChangedFormValues";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { useTransformValuesAndSubmit } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useTransformValuesAndSubmit";
import { useCanSubmitForm } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useCanSubmitForm";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const { saveButton: saveButtonAriaLabel } =
  reliabilityArialLabels.policies.policiesDrawer;

export const EditPolicyButton: React.FC = () => {
  const hasChangedFormValues = useHasChangedFormValues();
  const { isSubmittingForm } = usePolicyDrawerContext();
  const transformValuesAndSubmit = useTransformValuesAndSubmit();
  const canSubmitForm = useCanSubmitForm();

  const onSaveClick = () => {
    transformValuesAndSubmit("update");
  };

  const disabled = !canSubmitForm || !hasChangedFormValues;

  return (
    <LoadingButton
      variant={"contained"}
      color={"primary"}
      disabled={disabled}
      loading={isSubmittingForm}
      onClick={onSaveClick}
      aria-label={saveButtonAriaLabel}
    >
      Save Changes
    </LoadingButton>
  );
};
