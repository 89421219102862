import axios from "axios";
import { useMemo } from "react";

import { useRequestHeaders } from "../common-api/headers";
import { getAppConfig } from "../../config/appConfig";

import { useReportAxiosError } from "@/components/reliability/hooks/useReportAxiosError";

export const useEmailDigestApiClient = () => {
  const headers = useRequestHeaders();
  const reportAxiosError = useReportAxiosError();

  const client = useMemo(
    () =>
      axios.create({
        baseURL: getAppConfig().emailDigestAPIServerURL,
        headers,
      }),
    [headers]
  );

  client.interceptors.response.use(undefined, (error) => {
    reportAxiosError(error);
    return Promise.reject(error);
  });

  return client;
};
