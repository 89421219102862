import React, { useMemo } from "react";
import { Button, ButtonProps } from "@komodorio/design-system/deprecated";
import { Lock16 } from "@komodorio/design-system/icons";

import { useGetUsersCountSubscription } from "../../../../generated/graphql";
import {
  freemiumModalNames,
  useFreemiumModalsUpdate,
} from "../../../../shared/hooks/useFreemiumModals";
import { restrictionsNames } from "../../Restrictions";
import useGetAccountPlanData from "../../useGetAccountPlanData";
import { hasReachedUserLimit } from "../../Restrictions/users";
import { useIsTrialEnded } from "../../../../shared/hooks/useUserMetadata/useUserMetadata";

export const FreemiumUsersButton: React.FC<ButtonProps> = ({
  onClick,
  icon,
  ...rest
}) => {
  const setOpenFreemiumModal = useFreemiumModalsUpdate();
  const [{ data }] = useGetUsersCountSubscription();

  const planData = useGetAccountPlanData();
  const isTrialEnded = useIsTrialEnded();

  const isBlock = useMemo(
    () =>
      hasReachedUserLimit(
        planData?.usersLimit,
        data?.[restrictionsNames.USERS]?.aggregate?.count,
        isTrialEnded
      ),
    [data, planData?.usersLimit, isTrialEnded]
  );

  const onFreemiumButtonClick = () => {
    setOpenFreemiumModal &&
      setOpenFreemiumModal(freemiumModalNames.USERS_RESTRICTION);
  };
  return (
    <>
      <Button
        {...rest}
        icon={isBlock ? Lock16 : icon}
        onClick={isBlock ? onFreemiumButtonClick : onClick}
      />
    </>
  );
};
