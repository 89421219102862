import React from "react";
import { Line } from "recharts";
import { muiColors } from "@komodorio/design-system";

import { sharedStraightLineProps } from "../../constants";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { formatNumber } from "../../../../shared/utils/formatNumber";
import { TooltipItemIcon } from "../TooltipItemIcon";

export const PODS_COUNT_DATA_KEY = "podsCount";

export const formatCountTick = (tick: string): string => {
  const num = parseFloat(tick);
  return formatNumber(num);
};

export const podsCountTooltipItems = [
  {
    dataKey: PODS_COUNT_DATA_KEY,
    title: "number of pods",
    tooltipItemKey: "numberOfPods",
    icon: (
      <TooltipItemIcon
        color={muiColors.indigo[400]}
        ariaLabel={AriaLabels.MetricsAvailability.CountGraph.Icon}
      />
    ),
  },
];

export const usePodsCountLine = () => {
  return (
    <Line
      aria-label={AriaLabels.MetricsAvailability.CountGraph.CountLine}
      stroke={muiColors.indigo[400]}
      dataKey={PODS_COUNT_DATA_KEY}
      {...sharedStraightLineProps}
    />
  );
};
