import React, { useMemo } from "react";
import { Dictionary } from "lodash";

import { NamespaceSelector } from "./NamespaceSelector";
import { LabelSelector } from "./LabelSelector";
import { labelStringToKeyValue } from "./utils";

import {
  LabelsWorkspace,
  NamespacesWorkspace,
  Workspace,
  WorkspaceKind,
} from "@/generated/workspacesApi";

interface ComparandSelectorProps {
  comparandType: WorkspaceKind | undefined;
  scopingOptions: ScopingOptions;
  selectedWorkspace: Workspace | undefined;
  onWorkspaceUpdate: (workspace: Workspace) => void;
}
export interface ScopingOptions {
  namespaces?: Set<string>;
  labels?: Dictionary<Set<string>>;
}
export const ComparandSelector: React.FC<ComparandSelectorProps> = ({
  comparandType,
  scopingOptions,
  selectedWorkspace,
  onWorkspaceUpdate,
}) => {
  const selectedNamespace = useMemo(() => {
    if (
      selectedWorkspace?.kind == WorkspaceKind.Namespace &&
      (selectedWorkspace?.value as NamespacesWorkspace)?.namespaces?.length > 0
    ) {
      // We only support one namespace for now.
      return (selectedWorkspace?.value as NamespacesWorkspace).namespaces[0];
    }
    return undefined;
  }, [selectedWorkspace?.kind, selectedWorkspace?.value]);
  const selectedLabel = useMemo(() => {
    if (
      selectedWorkspace?.kind == WorkspaceKind.Label &&
      (selectedWorkspace?.value as LabelsWorkspace)?.labels?.length > 0
    ) {
      // We only support one label for now.
      return labelStringToKeyValue(
        (selectedWorkspace?.value as LabelsWorkspace).labels[0]
      );
    }
    return undefined;
  }, [selectedWorkspace?.kind, selectedWorkspace?.value]);
  switch (comparandType) {
    case WorkspaceKind.Namespace:
      return (
        <NamespaceSelector
          namespaces={scopingOptions.namespaces ?? new Set()}
          selectedNamespace={selectedNamespace}
          onNamespaceSelection={(namespace) => {
            const newConfigSelection = {
              ...selectedWorkspace,
              value: {
                namespaces: [namespace],
              },
            } as Workspace;

            onWorkspaceUpdate(newConfigSelection);
          }}
        />
      );

    case WorkspaceKind.Label:
      return (
        <LabelSelector
          labels={scopingOptions?.labels ?? {}}
          selectedLabel={selectedLabel ?? { key: "", value: "" }}
          onLabelSelection={({ key, value }) => {
            const newConfigSelection = {
              ...selectedWorkspace,
              value: {
                labels: [`{"${key}":"${value}"}`],
              },
            } as Workspace;

            onWorkspaceUpdate(newConfigSelection);
          }}
        />
      );

    default:
      return null;
  }
};
