import React, { createContext, useCallback, useContext, useState } from "react";

import {
  MetricsAggregationType,
  MetricsFullTimeWindow,
} from "../../../../../Metrics/types";
import { Timeframe } from "../../../../../../shared/types/TimeWindow";

export type ClusterMetricsContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  aggregationType: MetricsAggregationType;
  setAggregationType: (aggregationType: MetricsAggregationType) => void;
};

const initialState: ClusterMetricsContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 1)),
    end: new Date(),
    timeframe: Timeframe.LastHour,
  },
  setTimeWindow: () => undefined,
  aggregationType: MetricsAggregationType.Avg,
  setAggregationType: () => undefined,
};

const ClusterMetricsContext =
  createContext<ClusterMetricsContextState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useClusterMetricsContext = (): ClusterMetricsContextState =>
  useContext(ClusterMetricsContext);

const { timeWindow: initialTimeWindow } = initialState;

export const ClusterMetricsContextProvider: React.FC<{
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
}> = ({ children, givenTimeWindow }) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );
  const [aggregationType, setAggregationType] = useState(
    MetricsAggregationType.Avg
  );

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
    },
    [timeWindow]
  );

  const contextState: ClusterMetricsContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
    aggregationType,
    setAggregationType,
  };

  return (
    <ClusterMetricsContext.Provider value={contextState}>
      {children}
    </ClusterMetricsContext.Provider>
  );
};
