import { Schema, validate, ValidatorResult } from "jsonschema";

import { RbacActionRule } from "./types";

const policySchema: Schema = {
  type: "array",
  minItems: 1,
  items: {
    type: "object",
    required: ["verbs", "apiGroups", "resources"],
    additionalProperties: false,
    properties: {
      verbs: {
        type: "array",
        minItems: 1,
        items: {
          type: "string",
          oneOf: [
            {
              enum: [
                "*",
                "create",
                "get",
                "list",
                "watch",
                "update",
                "patch",
                "delete",
                "deletecollection",
              ],
            },
          ],
        },
      },
      apiGroups: {
        type: "array",
        minItems: 1,
        items: { type: "string" },
      },
      resources: {
        type: "array",
        minItems: 1,
        items: { type: "string" },
      },
    },
  },
};

export const isValidPolicy = (json: unknown): ValidatorResult => {
  return validate(json, policySchema);
};

export const validateJsonRules = (
  stringRules: string
): { parsedRules?: RbacActionRule[]; jsonError?: string } => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(stringRules);
    const { valid, errors } = isValidPolicy(parsedJson);
    if (!valid) {
      throw new Error(errors.map((e) => e.stack + ".").join(" "));
    }
  } catch (err) {
    return { jsonError: (err as Error).message };
  }
  return { parsedRules: parsedJson };
};
export const validateRules = (rules: RbacActionRule[]) => {
  const { errors } = isValidPolicy(rules);
  return errors;
};

export const validateActionName = (name: string | undefined) =>
  !name ? "Please fill action name" : undefined;
