import React, { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { PopoverProps } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { HiddenContainer } from "./HiddenContainer";

const POPOVER_DISTANCE_FROM_TRIGGER = 10;

type MouseEnterPopOverProps = {
  triggerElContent: React.ReactNode;
  popoverContent: React.ReactNode;
  popoverProps?: PopoverProps;
};

export const MouseEnterPopover: React.FC<MouseEnterPopOverProps> = ({
  popoverContent,
  triggerElContent,
  popoverProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isHoveringPopoverContent = useRef(false);
  const [popoverContentHeight, setPopoverContentHeight] = useState(0);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    !isHoveringPopoverContent.current && setAnchorEl(null);
  };

  // add delay to popover close to allow user to transition from trigger to popover content
  const { callback: debouncedHandlePopoverClose } = useDebouncedCallback(
    handlePopoverClose,
    250
  );

  const onPopoverEnter = () => {
    isHoveringPopoverContent.current = true;
  };

  const onPopoverLeave = () => {
    isHoveringPopoverContent.current = false;
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!popoverContentHeight) {
    return (
      <>
        {triggerElContent}
        <HiddenContainer
          popoverContent={popoverContent}
          setPopoverContentHeight={setPopoverContentHeight}
        />
      </>
    );
  }

  return (
    <>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={debouncedHandlePopoverClose}
      >
        {triggerElContent}
      </div>

      <Popover
        aria-label={"popover content"}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: popoverContentHeight + POPOVER_DISTANCE_FROM_TRIGGER,
          horizontal: "center",
        }}
        {...popoverProps}
        sx={{
          pointerEvents: "none",
        }}
      >
        <Paper
          variant={"outlined"}
          onMouseEnter={onPopoverEnter}
          onMouseLeave={onPopoverLeave}
          style={{ pointerEvents: "auto" }}
        >
          {popoverContent}
        </Paper>
      </Popover>
    </>
  );
};
