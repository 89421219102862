import SearchOffIcon from "@mui/icons-material/SearchOff";
import { muiColors } from "@komodorio/design-system";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";

import { AriaLabels } from "@/shared/config/ariaLabels";

export type ResourceNotFoundProps = {
  onClose?: () => void;
};

export const ResourceNotFound: React.FC<ResourceNotFoundProps> = ({
  onClose,
}) => {
  return (
    <Box
      sx={{ backgroundColor: muiColors.common.white }}
      display="flex"
      height="100%"
      width="100%"
      alignItems="flex-start"
      flexDirection="column"
    >
      {onClose ? (
        <Box padding="16px 16px">
          <IconButton
            aria-label={AriaLabels.ResourceView.DrawerCloseButton}
            onClick={onClose}
            sx={{ padding: "0" }}
          >
            <Close />
          </IconButton>
        </Box>
      ) : null}
      <Box
        sx={{ backgroundColor: muiColors.gray[25] }}
        display="flex"
        flexDirection="row"
        width="100%"
        gap="16px"
        padding="12px 0px"
        justifyContent="center"
        alignItems="center"
        borderTop={`1px solid ${muiColors.gray[200]}`}
        borderBottom={`1px solid ${muiColors.gray[200]}`}
      >
        <Box
          padding="14px"
          sx={{ background: muiColors.gray[50] }}
          borderRadius="4px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SearchOffIcon sx={{ color: muiColors.gray[500] }} fontSize="small" />
        </Box>
        <Typography variant="h3" color={muiColors.gray[700]}>
          Resource not found
        </Typography>
      </Box>
    </Box>
  );
};
