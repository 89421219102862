import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Select, Typography } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HelmChart from "../helmChart";
import {
  CopyButtonsContainer,
  HelmTabContainer,
  Z_INDEX_ABOVE_MODAL,
} from "../drawers/styles";
import { manifestToMap, MAX_ELEMENTS_WITH_SCROLL } from "../utils";
import { dataDogViewNames } from "../../../../shared/constants/datadog";
import { useAgentSupportSecretRedaction } from "../hooks/useAgentSupportSecretRedaction";

import { GenericResource } from "./ResourceTab/ResourcesTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ManifestsCompare from "./components/ManifestsCompare";
import RevisionComparer from "./components/RevisionComparer";
import ShowChangesSwitcher from "./components/ChangesOnlySwitcher";
import { EmptyState } from "./components/EmptyData";
import { CopyButton } from "./components/CopyButton";
import { useReportTabLoadingToDatadog } from "./helmChartTabsHooks";

import { LazyEditor } from "@/components/common/LazyEditor";

export interface ManifestsTabProps {
  resource: HelmChart;
  onRevisionChangeCallback?: (revision: string) => void;
  initialRevision?: string;
  title?: string;
}
const StyledDiv = styled.div`
  max-height: 100%;
  overflow: auto;
`;
export const Panel = styled.div`
  border-radius: 4px;
  text-align: start;
  background-color: ${palette.white[0]};
  width: 100%;
  height: 100%;
`;
export const Selectors = styled.div`
  display: grid;
  grid-template-columns: max-content max-content auto;
  align-items: center;
  column-gap: 1.5rem;
`;

const TitleCopyButtonsContainer = styled(CopyButtonsContainer)`
  padding: 1rem 0 0 1.5rem;
`;

const ManifestsContainer = styled.div`
  dispaly: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getManifestTitle = (name?: string, kind?: string): JSX.Element => {
  return (
    <div>
      <Typography variant="title" size="large" color={palette.gray[800]}>
        <b>{name}</b>
      </Typography>
      <Typography size="large" color={palette.gray[600]}>
        {kind}
      </Typography>
    </div>
  );
};
const ManifestsTab: React.FC<ManifestsTabProps> = ({
  resource,
  onRevisionChangeCallback,
  initialRevision,
  title,
}) => {
  const [compareToRevision, setCompareToRevision] = useState<{
    revision: string;
    id: string;
  }>();

  const shouldDecodeManifest = useAgentSupportSecretRedaction(resource.cluster);

  const [selectedResource, setSelectedResource] = useState<string>();
  const [showChangesOnly, setShowChangesOnly] = useState(true);

  const onRevisionChange = useCallback(
    (value?: string) => {
      setCompareToRevision(
        resource.revisions.find((r) => r.revision === value)
      );
      onRevisionChangeCallback?.(value ?? "");
    },
    [onRevisionChangeCallback, resource.revisions]
  );

  useEffect(() => {
    if (!compareToRevision && initialRevision) {
      onRevisionChange(initialRevision);
    }
  }, [compareToRevision, initialRevision, onRevisionChange]);

  const currentManifests = useMemo(() => {
    return manifestToMap(resource.parsedManifests, !!shouldDecodeManifest);
  }, [resource.parsedManifests, shouldDecodeManifest]);

  const manifestsOptions = useMemo(() => {
    return (
      resource.parsedManifests?.map((m) => {
        const manifestTyped = m as GenericResource;
        return {
          label: `${manifestTyped.metadata.name}.yaml (${manifestTyped.kind})`,
          value: manifestTyped.kind + "/" + manifestTyped.metadata.name,
        };
      }) ?? []
    );
  }, [resource.parsedManifests]);

  useEffect(() => {
    if (!selectedResource) {
      setSelectedResource(manifestsOptions?.[0]?.value);
    }
  }, [manifestsOptions, selectedResource]);

  const allManifestDivs = useMemo(() => {
    return Array.from(currentManifests.entries()).map(
      ([resourceKey, manifestValues]) => (
        <StyledDiv id={resourceKey}>
          <TitleCopyButtonsContainer>
            {getManifestTitle(manifestValues?.name, manifestValues?.kind)}
            <CopyButton
              tooltipText={"Copy manifest to clipboard"}
              value={
                currentManifests.get(resourceKey ?? "")?.manifest as string
              }
            />
          </TitleCopyButtonsContainer>
          <LazyEditor
            style={{ borderColor: "transparent" }}
            width="100%"
            readOnly
            value={manifestValues.manifest.replace(/\n$/, "")}
            maxLines={Infinity}
            fontSize="14px"
          />
        </StyledDiv>
      )
    );
  }, [currentManifests]);

  useEffect(() => {
    if (
      allManifestDivs.length < MAX_ELEMENTS_WITH_SCROLL &&
      selectedResource &&
      selectedResource !== manifestsOptions?.[0]?.value
    ) {
      const element = document.getElementById(selectedResource);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [allManifestDivs.length, manifestsOptions, selectedResource]);

  const divRef = useRef<HTMLDivElement>(null);
  const { onFetchingStateChange } = useReportTabLoadingToDatadog({
    viewName: dataDogViewNames.resourceViewHelmManifestsTab,
    divRef,
    compareToRevision,
  });

  const content = useMemo(
    () =>
      Array.from(currentManifests.entries()).length > 0 ? (
        <ManifestsContainer>
          <Selectors>
            <Select
              label="Jump to"
              size="medium"
              options={manifestsOptions}
              value={manifestsOptions.find((o) => o.value === selectedResource)}
              onChange={(o) => setSelectedResource(o.value)}
              width="20rem"
              listZIndex={Z_INDEX_ABOVE_MODAL}
              listMaxHeight={"20rem"}
            />
            {resource.revisions.length > 1 && (
              <RevisionComparer
                revisions={resource.revisions.map((r) => r.revision)}
                currentRevision={resource.currentRevision}
                onRevisionChange={onRevisionChange}
                initialRevision={initialRevision}
                title={title}
              />
            )}
            {resource.revisions.length > 1 &&
              compareToRevision !== undefined && (
                <ShowChangesSwitcher
                  showChangesOnly={showChangesOnly}
                  setShowChangesOnly={setShowChangesOnly}
                />
              )}
          </Selectors>
          <HelmTabContainer>
            {compareToRevision ? (
              <ManifestsCompare
                currentManifests={currentManifests}
                revisionToCompare={compareToRevision}
                showOnlyChanges={showChangesOnly}
                currentRevision={resource.currentRevision}
                selectedResource={selectedResource ?? ""}
                onFetchingStateChange={onFetchingStateChange}
                resource={resource}
              />
            ) : (
              <Panel>
                {allManifestDivs.length < MAX_ELEMENTS_WITH_SCROLL
                  ? allManifestDivs
                  : allManifestDivs.find(
                      (elem) => elem.props.id === selectedResource
                    )}
              </Panel>
            )}
          </HelmTabContainer>
        </ManifestsContainer>
      ) : (
        <EmptyState text={"There are no manifests to show"} />
      ),
    [
      allManifestDivs,
      compareToRevision,
      currentManifests,
      initialRevision,
      manifestsOptions,
      onFetchingStateChange,
      onRevisionChange,
      resource,
      selectedResource,
      showChangesOnly,
      title,
    ]
  );

  return <div ref={divRef}>{content}</div>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ManifestsTab;
