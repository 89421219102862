import styled from "styled-components";

import { ComparisonSelection } from "./ComparisonSelection";
import { ServicesComparisonContextProvider } from "./ServicesSelectionContext";
import { ComparisonContent } from "./ComparisonContent";

import Divider from "@/components/monitorsView/common/Divider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-block-start: 24px;
`;

export const DriftAnalysisView: React.FC = () => {
  return (
    <ServicesComparisonContextProvider>
      <Container>
        <ComparisonSelection />
        <Divider />
        <ComparisonContent />
      </Container>
    </ServicesComparisonContextProvider>
  );
};
