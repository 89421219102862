import React, { useEffect, useMemo } from "react";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectAllocationFilters,
  selectSetAllocationFilters,
} from "../../../store/costOptimizationStoreSelectors";
import {
  AllocationFiltersGroupBy,
  AllocationFiltersTimeWindow,
} from "../../../types/costOptimizationTypes";
import { commonSelectProps } from "../../shared/commonSelectProps";
import { costOptimizationAriaLabels as ariaLabels } from "../../../constants/costOptimizationAriaLabels";
import { useCostOptimizationDataInUrl } from "../../../hooks/persistentStateHooks";
import { useAllocationSetFilterState } from "../hooks/allocationHooks";
import { initialState } from "../../../store/initialState";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const groupByOptions: AllocationFiltersTimeWindow[] = [
  "30d",
  "14d",
  "7d",
  "24h",
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const groupByValueToLabel: Record<AllocationFiltersTimeWindow, string> =
  {
    "30d": "Past 30 days",
    "14d": "Past 14 days",
    "7d": "Past 7 days",
    "24h": "Yesterday",
  };

const defaultValue = {
  label: groupByValueToLabel[initialState.allocationFilters.timeWindow],
  value: initialState.allocationFilters.timeWindow,
};

export const TimeWindowFilter: React.FC = () => {
  const setFilterState = useAllocationSetFilterState();
  const [{ allocationFilters = {} }] = useCostOptimizationDataInUrl();
  const timeWindowFromUrl = useMemo(() => {
    return allocationFilters?.timeWindow;
  }, [allocationFilters]);
  const { timeWindow } = useCostOptimizationStore(selectAllocationFilters);
  const setAllocationFilters = useCostOptimizationStore(
    selectSetAllocationFilters
  );

  useEffect(() => {
    const initialTimeWindow = timeWindowFromUrl
      ? timeWindowFromUrl
      : timeWindow;
    setAllocationFilters({ timeWindow: initialTimeWindow });
  }, [setAllocationFilters, timeWindowFromUrl, timeWindow]);

  const onChange = (option?: MuiSelectionOption<string>) => {
    option &&
      setFilterState("timeWindow", option.value as AllocationFiltersGroupBy);
  };

  const selectOptions = useMemo(
    () =>
      groupByOptions.map((option) => ({
        label: groupByValueToLabel[option],
        value: option,
      })),
    []
  );

  const value = useMemo(
    () => ({
      label: groupByValueToLabel[timeWindow],
      value: timeWindow,
    }),
    [timeWindow]
  );

  return (
    <div>
      <SingleSelect
        {...commonSelectProps}
        ariaLabel={ariaLabels.allocationPage.filters.timeWindow}
        value={value}
        options={selectOptions}
        onChange={onChange}
        label={"Time Frame:"}
        defaultValue={defaultValue}
      />
    </div>
  );
};
