import React, { useMemo } from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledChip = styled(Chip)`
  &&&& {
    height: auto;
    .MuiChip-label {
      white-space: normal;
    }
  }
`;

type ChipsFlexContainerProps = {
  values: string[];
};

export const ChipsFlexContainer: React.FC<ChipsFlexContainerProps> = ({
  values,
}) => {
  const content = useMemo(() => {
    return values.map((value) => <StyledChip key={value} label={value} />);
  }, [values]);
  return <Container>{content}</Container>;
};
