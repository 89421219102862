import { muiColors } from "@komodorio/design-system";
import styled, { css } from "styled-components";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const S1 = styled.div`
  font-family: Roboto Slab, sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 59px;
  letter-spacing: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const S2 = styled.div`
  font-family: Roboto Slab, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const S3 = styled.div`
  font-family: Roboto Slab, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H1 = styled.div`
  font-family: Roboto Slab, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H2 = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
`;

export const H2Poppins = styled.h2`
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  margin-block: 0;
  padding-block: 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H3 = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
`;

export const H3Poppins = styled.h3`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const h3TabsStyle = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
`;

export const H3Tabs = styled.div`
  ${h3TabsStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const h3LightStyle = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
`;

export const H3Light = styled.div`
  ${h3LightStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const h4Style = css`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0;
`;
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H4 = styled.div`
  ${h4Style}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const h4LightStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
`;

export const H4Light = styled.div`
  ${h4LightStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const textBolderStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
`;

export const TextBolder = styled.span`
  ${textBolderStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const textStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
`;

export const Text = styled.span`
  ${textStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const textRegularStyle = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
`;

export const TextRegular = styled.span`
  ${textRegularStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const textSmallRegularStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
`;

export const TextSmallRegular = styled.span`
  ${textRegularStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const textLightStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
`;

export const TextLight = styled.span`
  ${textLightStyle}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const linkStyle = css`
  color: ${muiColors.indigo[500]};
  font-weight: 400;
  text-decoration: underline;
  font-size: 0.75rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H5 = styled.h5`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  size: 0.875rem;
  line-height: 1.25rem;
`;
