import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  User,
  apiV1UsersBulkUpdatePutUrl,
  UpdateUserBulkRequest,
} from "../../../../../generated/auth";

const BULK_UPDATE_USER_PATH = "/users/bulk-update";

const bulkUpdateUser = async (
  apiClient: AxiosInstance,
  params: UpdateUserBulkRequest
): Promise<User> => {
  const { data } = await apiClient.put(
    apiV1UsersBulkUpdatePutUrl(
      { updateUserBulkRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useBulkUpdateUser = () => {
  const apiClient = useAuthApiClient();
  return useMutation([BULK_UPDATE_USER_PATH], (params: UpdateUserBulkRequest) =>
    bulkUpdateUser(apiClient, params)
  );
};
