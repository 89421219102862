import { useMemo } from "react";

import { useFilterObjectListFromUrl } from "@/shared/hooks/filtersDataInUrl/useFilterObjectListFromUrl";
import { GenericFilter } from "@/generated/addonsApi";
import { filterCategoryToGenericFilter } from "@/components/k8sAddons/utils/addonUtils";

export const useFilterListFromUrlAsGenericFilters = (
  filterCategory?: string
) => {
  const filtersList = useFilterObjectListFromUrl(filterCategory);

  return useMemo<GenericFilter[]>(() => {
    return filterCategoryToGenericFilter(filtersList);
  }, [filtersList]);
};
