import React, { useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { muiColors, theme } from "@komodorio/design-system";
import { Loader16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { FetchingFade } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/ImpactTile";
import {
  isFetchingKomodorServiceSelector,
  issueSelector,
  komodorServiceSelector,
} from "../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useInvestigationModeStore } from "../../../shared/store/investigationModeStore/investigationModeStore";
import { sleep } from "../../../shared/utils/sleep";

const ReplicasGrid = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  align-self: flex-start;
`;
const { gray } = muiColors;

const ReplicasTile: React.FC = () => {
  const issue = useInvestigationModeStore(issueSelector);

  const resource = useInvestigationModeStore(komodorServiceSelector);
  const isFetchingResource = useInvestigationModeStore(
    isFetchingKomodorServiceSelector
  );
  const closed = !!issue?.closedAt;

  const podsHealthCheckOutput = useMemo(() => {
    return (
      issue?.results.unhealthyPods?.output.lastSnapshot ??
      issue?.results.unhealthyPods.output.firstSnapshot
    );
  }, [
    issue?.results.unhealthyPods.output.firstSnapshot,
    issue?.results.unhealthyPods.output.lastSnapshot,
  ]);
  const { readyReplicas, desiredReplicas } = useMemo(() => {
    if (closed && podsHealthCheckOutput) {
      return {
        readyReplicas: podsHealthCheckOutput?.readyReplicas ?? 0,
        desiredReplicas: podsHealthCheckOutput?.desiredReplicas ?? 0,
      };
    }
    return {
      readyReplicas: resource?.replicas?.ready,
      desiredReplicas: resource?.replicas?.desired,
    };
  }, [closed, podsHealthCheckOutput, resource]);
  const isDeleted = (resource?.isDeleted || resource?.inactive) ?? false;
  const isLoading = useIssueLiveLoading(
    isFetchingResource && !isDeleted,
    issue?.closedAt
  );
  return (
    <>
      <Typography variant={"overline2"} color={theme.foreground.fgSubtle}>
        REPLICAS
      </Typography>
      <Tooltip
        placement={"right-end"}
        title={`${readyReplicas} out of ${desiredReplicas} desired replicas ${
          issue?.closedAt
            ? "were available when the issue closed"
            : "are currently available"
        }`}
      >
        <ReplicasGrid>
          {isLoading ? <Loader16 color={theme.icon} /> : null}
          <FetchingFade showAnimation={isLoading}>
            <Typography
              variant={"h5"}
              color={gray[850]}
            >{`${readyReplicas}/${desiredReplicas}`}</Typography>
          </FetchingFade>
        </ReplicasGrid>
      </Tooltip>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ReplicasTile;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useIssueLiveLoading = (
  loading: boolean,
  closedAt?: Date | null
) => {
  const [longerLoading, setLongerLoading] = useState(false);
  useEffect(() => {
    if (loading && closedAt === null) {
      setLongerLoading(true);
      sleep(3000).then(() => setLongerLoading(false));
    }
  }, [closedAt, loading]);

  return longerLoading;
};
