import React from "react";
import styled from "styled-components";
import { IconButton } from "@komodorio/design-system/deprecated";
import { Close24 } from "@komodorio/design-system/icons";

import { Label } from "../../../../ResourceView/resources";
import { Title } from "../../../../ResourceView/common/Header/Title/Title";
import { Summary } from "../../../../ResourceView/common/Header/Summary/Summary";

interface UpgradeHeaderProps {
  title: string;
  icon?: React.FC;
  labels?: Label[];
  onClose?: () => void;
}

const Container = styled.div`
  display: flex;
  padding-block: 0.5rem;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`;
const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const HelmDrawerHeader: React.FC<UpgradeHeaderProps> = ({
  title,
  icon,
  labels,
  onClose,
}) => {
  const Icon = icon ?? null;
  return (
    <Container>
      <FlexContainer>
        {onClose && <IconButton icon={Close24} noBorder onClick={onClose} />}
        <Title displayName={title} status={undefined} />
      </FlexContainer>
      <FlexContainer>
        {!!Icon && <Icon />}
        {!!labels && <Summary labels={labels} />}
      </FlexContainer>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default HelmDrawerHeader;
