import styled from "styled-components";
import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { Typography } from "@komodorio/design-system/deprecated";
import { min, max } from "lodash";

import { AriaLabels } from "../../shared/config/ariaLabels";

import { GraphDataPoint } from "./types";
import { LegendLabelTextColor } from "./styles";

export interface SummaryProps {
  data: GraphDataPoint[];
  formatter: (tick: string) => { value: string; format: string };
  timeframe?: { start: Date; end: Date };
}

export interface SummaryValueProps {
  title: string;
  value: string;
  format: string;
  label: string;
}

export const ValueContainer = styled.div`
  display: flex;
  column-gap: 0.2rem;
  align-items: baseline;
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.75rem;
`;

export const Summary: React.FC<SummaryProps> = (props) => {
  const currentData = useMemo(
    () =>
      props.data?.filter((d) => {
        if (!props.timeframe) return true;
        return (
          d.time >= props.timeframe.start.getTime() &&
          d.time <= props.timeframe.end.getTime()
        );
      }),
    [props.data, props.timeframe]
  );

  const usage = useMemo(
    () =>
      currentData
        ?.map((d) => d.usageBytes)
        .filter((d) => d !== undefined && d !== null) as number[],
    [currentData]
  );

  const p90 = useMemo(
    () =>
      currentData
        ?.map((d) => d.p90)
        .filter((d) => d !== undefined && d !== null) as number[],
    [currentData]
  );

  const p95 = useMemo(
    () =>
      currentData
        ?.map((d) => d.p95)
        .filter((d) => d !== undefined && d !== null) as number[],
    [currentData]
  );

  const maxValues = useMemo(
    () =>
      currentData
        ?.map((d) => d.max)
        .filter((d) => d !== undefined && d !== null) as number[],
    [currentData]
  );

  const minUsage = useMemo(
    () => props.formatter(min(usage)?.toString() ?? ""),
    [props, usage]
  );
  const maxUsage = useMemo(
    () =>
      props.formatter(
        max(maxValues)?.toString() ?? max(usage)?.toString() ?? ""
      ),
    [props, maxValues, usage]
  );
  const p90Usage = useMemo(
    () =>
      props.formatter(
        max(p90)?.toString() ?? calculatePercentile(usage, 90).toString()
      ),
    [p90, props, usage]
  );
  const p95Usage = useMemo(
    () =>
      props.formatter(
        max(p95)?.toString() ?? calculatePercentile(usage, 95).toString()
      ),
    [p95, props, usage]
  );

  return usage?.length ? (
    <SummaryContainer
      aria-label={AriaLabels.MetricsAvailability.Summary.Summary}
    >
      <SummaryValue
        label={AriaLabels.MetricsAvailability.Summary.Min}
        title="min"
        value={minUsage.value}
        format={minUsage.format}
      />
      <SummaryValue
        label={AriaLabels.MetricsAvailability.Summary.Max}
        title="max"
        value={maxUsage.value}
        format={maxUsage.format}
      />
      <SummaryValue
        label={AriaLabels.MetricsAvailability.Summary.P90}
        title="p90"
        value={p90Usage.value}
        format={p90Usage.format}
      />
      <SummaryValue
        label={AriaLabels.MetricsAvailability.Summary.P95}
        title="p95"
        value={p95Usage.value}
        format={p95Usage.format}
      />
    </SummaryContainer>
  ) : null;
};

const SummaryValue: React.FC<SummaryValueProps> = (props) => {
  return (
    <div aria-label={props.label}>
      <Typography variant="uppercase" color={LegendLabelTextColor}>
        {props.title}
      </Typography>
      <ValueContainer>
        <MuiTypography variant="body0">{props.value}</MuiTypography>
        <Typography variant="uppercase" color={muiColors.gray[500]}>
          {props.format}
        </Typography>
      </ValueContainer>
    </div>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const calculatePercentile = (
  data: number[],
  percentile: number
): number => {
  const sortedData = [...data].sort((a, b) => a - b);
  const index = (percentile / 100) * (sortedData.length - 1);

  if (Number.isInteger(index)) {
    return sortedData[index];
  } else {
    const lower = Math.floor(index);
    const upper = Math.ceil(index);
    const fraction = index - lower;

    return (1 - fraction) * sortedData[lower] + fraction * sortedData[upper];
  }
};
