import React, { useState } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { useGetClusters } from "../../shared/hooks/workspaces-api/clusters/useGetClusters";
import { AriaLabels } from "../../shared/config/ariaLabels";
import KubernetesInstallationModal from "../integrations/installation/kubernetes/KubernetesInstallationModal";
import { ClustersSummaryFilters } from "../../generated/workspacesApi";
import {
  useGetClustersFilters,
  useGetClustersTotalCount,
} from "../../shared/hooks/workspaces-api/clusters/useGetClustersFilters";
import { PageLoader } from "../../shared/components/PageLoader";
import { CLUSTERS_FILTERS_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { useDDRumViewAndTimingListener } from "../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../shared/constants/datadog";
import { useWorkspaces } from "../workspaces/WorkspacesTopBar/hooks";
import { EmptyTableResults } from "../common/table/EmptyResults";
import { WorkspaceGenericEmptyState } from "../workspaces/WorkspaceGenericEmptyState";

import { ClusterFilters } from "./components/ClusterFilters";
import { getDisplayingItemsTextByLimitAndOffset } from "./utils";
import {
  CLUSTERS_VIEW_COLUMNS,
  DEFAULT_PAGINATION_PARAMS,
  DEFAULT_SORTING_PARAMS,
} from "./constants";
import { ClustersEmptyState } from "./components/ClustersEmptyState";
import { PARTIAL_CLUSTERS_PERMISSIONS_MESSAGE } from "./ClusterOverview/constants";
import {
  useCanViewFullClustersList,
  useCanViewClustersList,
} from "./ClusterOverview/permissionsHooks";
import { PartialListMessage } from "./components/PartialListMessage";
import { ClustersList } from "./components/ClustersList/ClustersList";

import PathNotFound from "@/components/404/PathNotFound";
import { ManageColumnsButton } from "@/components/ClustersView/components/ManageColumnsButton";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-block-start: 24px;
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  row-gap: 8px;
  padding-bottom: 24px;
`;

const Space = styled.div`
  height: 21px;
`;

export const ClustersView: React.FC = () => {
  const [isInstallationModalOpen, setIsInstallationModalOpen] = useState(false);
  const [filters, setFilters] =
    useStringifiedStateInSearchParams<ClustersSummaryFilters>(
      CLUSTERS_FILTERS_PARAM_KEY
    );
  const [columnsVisibility, setColumnsVisibility] =
    useState<GridColumnVisibilityModel>({ agentVersion: false });

  const { data: canViewClustersList, isLoading: isLoadingCanViewClustersList } =
    useCanViewClustersList();
  const {
    data: canViewFullClustersList,
    isLoading: isLoadingCanViewFullClustersList,
  } = useCanViewFullClustersList();

  const {
    data,
    isFetching: isLoadingClusters,
    isInitialLoading: isInitialLoadingClusters,
  } = useGetClusters(
    {
      filter: {
        ...DEFAULT_PAGINATION_PARAMS,
        ...DEFAULT_SORTING_PARAMS,
        ...filters,
      },
    },
    { staleTime: 0, keepPreviousData: true }
  );
  const clusters = data?.data ?? [];
  const totalResultsCount = data?.meta?.total ?? 0;
  const { data: clustersTotalCount, isFetching: isLoadingTotalCount } =
    useGetClustersTotalCount();
  const { data: filtersData, isLoading: isLoadingFilters } =
    useGetClustersFilters(
      {
        filter: filters ?? {},
      },
      { staleTime: 0 }
    );

  const isFetching =
    isLoadingCanViewClustersList ||
    isLoadingCanViewFullClustersList ||
    isLoadingClusters ||
    isLoadingTotalCount ||
    isLoadingFilters;
  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.clustersListView,
    addTimingParams: {
      enable: !isFetching,
    },
  });

  const isEmpty = !isLoadingTotalCount && clustersTotalCount <= 0;

  const {
    isLoading: isLoadingWorkspace,
    currentWorkspace,
    isWorkspaceKindBackendFiltered,
    isClusterWorkspace,
  } = useWorkspaces();

  const [version, setVersion] = useState(0);
  const resetFilters = () => {
    setVersion((version) => version + 1);
    setFilters(null);
  };

  if (
    isLoadingCanViewClustersList ||
    isLoadingTotalCount ||
    isLoadingWorkspace ||
    isInitialLoadingClusters
  ) {
    return <PageLoader />;
  }

  if (!canViewClustersList) {
    return <PathNotFound />;
  }

  if (!isWorkspaceKindBackendFiltered && currentWorkspace !== undefined) {
    return <WorkspaceGenericEmptyState />;
  }

  if (isEmpty) {
    return (
      <>
        <ClustersEmptyState
          onActionClick={() => setIsInstallationModalOpen(true)}
        />
        <KubernetesInstallationModal
          closeModalCallback={() => setIsInstallationModalOpen(false)}
          isOpen={isInstallationModalOpen}
          showWelcomeStep={false}
        />
      </>
    );
  }

  return (
    <ResponsiveLayout>
      <Container>
        {!canViewFullClustersList && (
          <Alert variant="outlined" severity="info" color="running">
            {PARTIAL_CLUSTERS_PERMISSIONS_MESSAGE}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ClusterFilters
            key={version}
            filtersData={filtersData}
            filters={filters}
            setFilters={setFilters}
          />
          {currentWorkspace === undefined && (
            <Button
              variant="outlined"
              sx={{ height: "fit-content" }}
              onClick={() => setIsInstallationModalOpen(true)}
              aria-label={AriaLabels.ClustersView.AddClusterButton}
            >
              Connect new cluster
            </Button>
          )}
          <KubernetesInstallationModal
            closeModalCallback={() => setIsInstallationModalOpen(false)}
            isOpen={isInstallationModalOpen}
            showWelcomeStep={false}
          />
        </Box>
        <ListContainer>
          {isLoadingClusters ? (
            <Space />
          ) : (
            <Typography variant="h5" color="text.secondary">
              {getDisplayingItemsTextByLimitAndOffset(
                filters?.limit,
                filters?.offset,
                totalResultsCount
              )}
            </Typography>
          )}
          <ManageColumnsButton
            columns={CLUSTERS_VIEW_COLUMNS}
            columnsVisibility={columnsVisibility}
            setColumnsVisibility={setColumnsVisibility}
            sx={{ width: "fit-content", justifySelf: "end" }}
          />
          <Paper
            variant="elevation"
            elevation={1}
            sx={{ padding: "16px", gridColumn: "1/3" }}
          >
            <ClustersList
              clusters={clusters}
              isLoadingClusters={isLoadingClusters}
              filters={filters}
              setFilters={setFilters}
              totalResultsCount={totalResultsCount}
              columns={CLUSTERS_VIEW_COLUMNS}
              columnsVisibility={columnsVisibility}
              dataGridProps={{
                slots: {
                  noRowsOverlay: () => (
                    <EmptyTableResults onClearFilters={resetFilters} />
                  ),
                  ...(isClusterWorkspace ? { footer: () => null } : {}),
                },
              }}
            />
            {isWorkspaceKindBackendFiltered && <PartialListMessage />}
          </Paper>
        </ListContainer>
      </Container>
    </ResponsiveLayout>
  );
};
