import { IntegrationType } from "komodor-types";
import { useCallback } from "react";

import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";
import { Installation } from "../../../../generated/accounts";

type Result = Promise<Installation>;
type CreateLaunchDarklyInstallation = (
  id: string,
  webhookPath: string
) => Result;

export const useLaunchDarklyInstallation =
  (): CreateLaunchDarklyInstallation => {
    const { mutateAsync: install } = useCreateInstallation();
    return useCallback(
      (id, webhookPath) => {
        const configuration = { webhookPath };
        const integration = IntegrationType.LAUNCHDARKLY;
        return install({ id, integration, configuration });
      },
      [install]
    );
  };
