import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.25rem 0 0.5rem 0;
  border-top: 1px solid ${palette.gray[800]};
`;

export const Footer: React.FC<PropsWithChildren> = ({ children }) => (
  <Container>{children}</Container>
);
