import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { isUndefined, uniq } from "lodash";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { KubernetesCRResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRResource";
import { useInspectionData } from "../../../Inspection/utils/useGetInspectionData";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CRD from "../../resources/crd";
import { DescribeLoader } from "../DescribeTab/common/DescribeLoader";
import { Table } from "../../../../shared/components/Table/Table";
import { CRError } from "../../../Inspection/CRDs/components/CRError";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";
import Autocomplete, {
  SuggestionType,
} from "../../../common/controls/Autocomplete";
import { StyledInput } from "../../../servicesView/ExploreKomodorServices";

import { getColumnNamesWithNameAsFirst } from "./getColumnNamesWithNameAsFirst";

const TableContainer = styled.div`
  margin-top: 1rem;
`;

const StyledText = styled(Typography)`
  margin: 10px 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 38px;
`;

const MAX_ROWS_TO_DISPLAY = 10;

interface CRDResourcesTabProps {
  resource: CRD;
}

export const CRDResourcesTab: React.FC<CRDResourcesTabProps> = ({
  resource,
}) => {
  const { agentId, cluster } = resource;
  const { inspectionAtm } = useOverridableFlags();
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [namespace, setNamespace] = useState<string | undefined>();

  const [shouldWatchCRs, setShouldWatchCRs] = useState(true);
  const { resourceList, isLiveDataSupported } = useInspectionData({
    agentId,
    agentInfo,
    cluster,
    kubernetesResource: { ...KubernetesCRResource, Kind: resource.name },
    shouldStop: !inspectionAtm || !shouldWatchCRs,
  });

  const columns = useMemo(
    () => getColumnNamesWithNameAsFirst(resourceList.rows),
    [resourceList.rows]
  );

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const { isNamespaced, namespacesOptions } = useMemo(() => {
    const isNamespaced = resource?.fullObj?.spec?.scope === "Namespaced";
    const namespaces = isNamespaced
      ? uniq(resourceList.rows.map((row) => row.namespace))
      : [];
    return {
      isNamespaced,
      namespacesOptions: namespaces.map<SuggestionType<string>>(
        (namespace) => ({
          label: namespace,
          value: namespace,
        })
      ),
    };
  }, [resource?.fullObj?.spec?.scope, resourceList.rows]);

  useEffect(() => {
    if (resourceList.errorMessage || resourceList.emptyResult) {
      setShouldWatchCRs(false);
    }
  }, [resourceList.errorMessage, setShouldWatchCRs, resourceList.emptyResult]);

  const retryInspectionSession = useCallback(() => {
    setShouldWatchCRs(true);
  }, [setShouldWatchCRs]);

  const filteredTableResults = useMemo(
    () =>
      resourceList.rows.filter(
        (row) =>
          (isUndefined(namespace) || row.namespace === namespace) &&
          Object.values(row).some((rowValue) => rowValue.includes(searchTerm))
      ),
    [namespace, resourceList.rows, searchTerm]
  );

  if (!inspectionAtm || !isLiveDataSupported) {
    return null;
  }

  if (resourceList.errorMessage || resourceList.emptyResult) {
    return (
      <CRError
        errorMessage={resourceList.errorMessage}
        onRetry={retryInspectionSession}
        crName={resource.name}
      />
    );
  }

  if (!resource.id || resourceList.fetching) return <DescribeLoader />;

  const numOfDisplayedRows = Math.min(
    filteredTableResults.length,
    MAX_ROWS_TO_DISPLAY
  );

  return (
    <>
      <FilterContainer>
        <StyledInput
          width="228px"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={AriaLabels.Inspection.CRD.Resources.Input}
          aria-label={AriaLabels.Inspection.CRD.Resources.Input}
        />
        {isNamespaced && (
          <Autocomplete
            fieldname="namespace"
            placeholder="choose namespace"
            suggestions={namespacesOptions}
            onSelectedChange={(namespace) => setNamespace(namespace)}
          />
        )}
      </FilterContainer>
      <StyledText variant="text">
        Displaying <strong>1-{numOfDisplayedRows}</strong> of{" "}
        <strong>{filteredTableResults.length}</strong> items
      </StyledText>
      <TableContainer>
        <Table
          data={filteredTableResults}
          columns={columns}
          sortable={false}
          onRowClickOverride={(row) => {
            pushDrawer({
              drawerType: DrawerType.ResourceDrawerByData,
              cluster: cluster,
              namespace: row.namespace ?? "",
              resourceType: resource.name,
              resourceName: row.name,
              buildPreloadResource: true,
              additionalData: {
                isCustomResource: true,
              },
            });
          }}
          pageSize={MAX_ROWS_TO_DISPLAY}
          ariaLabel={AriaLabels.Inspection.CRD.Resources.Table}
        />
      </TableContainer>
    </>
  );
};
