import { useMemo } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";

import {
  CertificateFullRelatedResources,
  CertificateRelatedResource,
  StatusCondition,
} from "@/generated/addonsApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ResourceStatus } from "@/components/k8sAddons/LiveStateDrawer/components/ResourceLinks/ResourceStatus";
import { Dictionary } from "@/shared/types/Dictionary";

const emptyNameWidths = [180, 110, 130];
const emptyKindWidths = [40, 110, 50];

const filterUnwantedResources = (value: [string, unknown]) => {
  const resourceKey = value[0] as keyof CertificateFullRelatedResources;
  const resourceValue = value[1] as CertificateRelatedResource;
  return resourceKey !== "workloads" && resourceValue.komodorUid;
};

type CertificateRelatedResourceRow = {
  id: number;
  kind: keyof CertificateFullRelatedResources;
  name: string;
  status: StatusCondition | undefined;
};

export const useCertificateRelatedResourceRows = (
  relatedResources: CertificateFullRelatedResources | undefined
) => {
  return useMemo<CertificateRelatedResourceRow[]>(() => {
    if (!relatedResources) return [];

    return Object.entries(relatedResources)
      .filter(filterUnwantedResources)
      .map(([key, value], index) => {
        const resourceData = value as CertificateRelatedResource;
        const { name = "" } = parseKomodorUid(resourceData.komodorUid) ?? {};

        return {
          id: index,
          kind: key as keyof CertificateFullRelatedResources,
          name,
          status: resourceData.status,
        };
      });
  }, [relatedResources]);
};

export const columnsConfig: Dictionary<GridColDef<CertificateRelatedResource>> =
  {
    name: {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    kind: {
      field: "kind",
      headerName: "Kind",
      flex: 1,
    },
    status: {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  };

export const useCertificateRelatedResourceColumns = () => {
  return [
    {
      ...columnsConfig.name,
    },
    {
      ...columnsConfig.kind,
    },
    {
      ...columnsConfig.status,
      renderCell: (
        params: GridRenderCellParams<CertificateRelatedResourceRow>
      ) => {
        return <ResourceStatus status={params.value} />;
      },
    },
  ];
};

export const useCertificateRelatedResourceEmptyColumns = () => [
  {
    ...columnsConfig.name,
    renderCell: (
      params: GridRenderCellParams<CertificateRelatedResourceRow>
    ) => {
      return (
        <Skeleton
          sx={{ fontSize: "21px" }}
          width={
            emptyNameWidths[(params.id as number) % emptyNameWidths.length]
          }
        />
      );
    },
  },
  {
    ...columnsConfig.kind,
    renderCell: (
      params: GridRenderCellParams<CertificateRelatedResourceRow>
    ) => {
      return (
        <Skeleton
          sx={{ fontSize: "21px" }}
          width={
            emptyKindWidths[(params.id as number) % emptyKindWidths.length]
          }
        />
      );
    },
  },
  {
    ...columnsConfig.status,
    renderCell: () => {
      return <Skeleton sx={{ fontSize: "21px" }} width={42} />;
    },
  },
];
