import { Kubernetes } from "@komodorio/design-system/icons";

export const KubernetesEndpointSliceResource = {
  NameInK8S: "endpointslices",
  Group: "network",
  NavBarName: "Endpoint Slices",
  PresentName: "Endpoint Slices",
  Namespaced: true,
  UnnecessaryColumns: ["ports", "AddressType"],
  Kind: "EndpointSlice",
  Icon: Kubernetes,
  hasLabels: true,
};
