import styled from "styled-components";
import { Button, Input, Typography } from "@komodorio/design-system/deprecated";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { CopyCode } from "../../../CopyCode";

import {
  ENABLE_PERMISSIONS_COMMAND,
  REQUEST_FROM_S3_COMMAND,
  createRunCommand,
} from "./const";
const StyledCard = styled.div`
  margin-top: 15.8px;
  margin-left: 12px;
`;

const HorizontalPaddedDiv = styled.div<{ padding: number }>`
  padding: ${({ padding }) => padding}px 0;
  display: flex;
`;

const HorizontalPaddedBottomDiv = styled.div<{ padding: number }>`
  padding-bottom: ${({ padding }) => padding}px 0;
  display: flex;
`;
const VerticalPaddedDiv = styled.div<{ padding: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ padding }) => padding}px 0;
`;

function validateInput(input: string) {
  return /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/.test(input);
}

export const ArgocdForm: React.FC<{
  onFormSubmit: (inputValue: string) => void;
}> = ({ onFormSubmit }) => {
  const [inputValue, setInputValue] = useState("argocd");
  const [renderedInputValue, setRenderedInputValue] = useState("");
  const [apiKeyValue, setApiKeyValue] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDisableButton(!validateInput(event.target.value.toString()));
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    const apiKey: string = uuidv4();
    setApiKeyValue(apiKey);
    setRenderedInputValue(inputValue);
    setDisableButton(true);
    onFormSubmit(apiKey);
  };

  return (
    <div>
      <Typography variant={"text"} size={"medium"}>
        Enter the namespace where your Argo CD is deployed and click the button
        to generate the install commands.
      </Typography>

      <HorizontalPaddedDiv padding={4}>
        <div>
          <Typography>Namespace</Typography>
          <Input
            size={"medium"}
            onChange={handleInputChange}
            defaultValue={"argocd"}
          />
        </div>
        <StyledCard>
          <Button
            variant="primary"
            size={"medium"}
            disabled={disableButton}
            onClick={handleSubmit}
          >
            Generate Command
          </Button>
        </StyledCard>
      </HorizontalPaddedDiv>
      <HorizontalPaddedBottomDiv padding={2000}>
        <InfoOutlined color="disabled"></InfoOutlined>
        <Typography size={"medium"}>
          The Namespace should be the location where Argo CD services and
          application resources live. Default value is argocd
        </Typography>
      </HorizontalPaddedBottomDiv>
      <HorizontalPaddedDiv padding={20} />
      <VerticalPaddedDiv padding={20}>
        <CopyCode
          code={
            renderedInputValue && apiKeyValue ? REQUEST_FROM_S3_COMMAND : ""
          }
          fullCommand={
            renderedInputValue && apiKeyValue ? REQUEST_FROM_S3_COMMAND : ""
          }
          fixedWidth={false}
          maxWidthLimit={false}
        />
        <CopyCode
          code={
            renderedInputValue && apiKeyValue ? ENABLE_PERMISSIONS_COMMAND : ""
          }
          fullCommand={
            renderedInputValue && apiKeyValue ? ENABLE_PERMISSIONS_COMMAND : ""
          }
          fixedWidth={false}
          maxWidthLimit={false}
        />
        <CopyCode
          code={
            renderedInputValue && apiKeyValue
              ? createRunCommand(renderedInputValue, apiKeyValue)
              : ""
          }
          fullCommand={
            renderedInputValue && apiKeyValue
              ? createRunCommand(renderedInputValue, apiKeyValue)
              : ""
          }
          fixedWidth={false}
          maxWidthLimit={false}
        />
      </VerticalPaddedDiv>
    </div>
  );
};
