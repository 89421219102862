import { muiColors } from "@komodorio/design-system";

import getChangesSummary from "../../../../../../../../../../common/EventGroup/deployEvent/getChangesSummary";
import { filterDiffKeys } from "../../../../../../../../../../common/EventGroup/deployEvent/getSpecsForDiff";
import { Deploy } from "../../../../../../../../../../../generated/resourcesApi";
import { Dictionary } from "../../../../../../../../../../../shared/types/Dictionary";

export const deployStatusToTitle: Dictionary<{ title: string; color: string }> =
  {
    Successful: { title: "Complete", color: muiColors.teal[900] ?? "" },
    Failure: { title: "Failed", color: muiColors.pink[700] ?? "" },
    "In Progress": { title: "In Progress", color: muiColors.teal[900] ?? "" },
    Started: { title: "Started", color: muiColors.teal[900] ?? "" },
  };

export const getDeployChanges = (deployEvent: Deploy) => {
  if (deployEvent.oldSpec && deployEvent.newSpec) {
    return Object.keys(deployEvent.oldSpec).length &&
      Object.keys(deployEvent.newSpec).length
      ? getChangesSummary(
          filterDiffKeys(deployEvent?.deploymentDiffKeys ?? [])
        ).replace("Changes: ", "")
      : "First version";
  }
  return "";
};
