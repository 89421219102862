import React, { useState } from "react";
import styled from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Plus16 } from "@komodorio/design-system/icons";
import { TooltipWrapper } from "react-tooltip";

import { AnalyticEvents } from "../../../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../../../shared/hooks/analytics";
import { External_Link_Context_Enum } from "../../../../../../../generated/graphql";
import { useHasPermissions } from "../../../../../../../shared/hooks/useUserMetadata/rbac";
import { DEFAULT_TOOLTIP } from "../../../../../../../shared/constants/tooltipIds";

import AddLinkModal from "./AddLinkModal";

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: fit-content;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  color: ${theme.foreground.fgDarkBlue};

  ${({ disabled }) =>
    disabled
      ? `cursor: not-allowed;
         color: ${theme.foreground.fgDisabled};
        `
      : `:hover {
    background-color: ${palette.gray[50]};
    border-radius: 0.25rem;
  }`}
`;

const StyledTypography = styled(Typography)`
  color: inherit;
`;

const AddLinkButton: React.FC<{
  serviceId?: string;
  serviceDisplayName?: string;
  setShowAddLink: React.Dispatch<React.SetStateAction<boolean>>;
  context: External_Link_Context_Enum;
}> = ({ serviceId, serviceDisplayName, setShowAddLink, context }) => {
  const { canManageIntegrations } = useHasPermissions();
  const disabled = !canManageIntegrations;

  return (
    <TooltipWrapper
      tooltipId={DEFAULT_TOOLTIP}
      content={disabled ? "Only admins can add custom links" : undefined}
      place="right"
    >
      <Container
        onClick={() => {
          if (disabled) return;
          dispatchEvent(AnalyticEvents.Links.Add_External_Link_Clicked, {
            serviceId: serviceId,
            serviceName: serviceDisplayName,
            linkContext: context,
          });
          setShowAddLink(true);
        }}
        disabled={disabled}
        data-e2e-selector="externalLink-add-button"
      >
        <Plus16 />
        <StyledTypography>Add external link</StyledTypography>
      </Container>
    </TooltipWrapper>
  );
};

export const AddLink: React.FC<{
  context: External_Link_Context_Enum;
  k8sClusterName?: string;
  namespace?: string;
  serviceId?: string;
  serviceDisplayName?: string;
}> = ({
  context,
  k8sClusterName,
  namespace,
  serviceId,
  serviceDisplayName,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <AddLinkButton
        serviceId={serviceId}
        serviceDisplayName={serviceDisplayName}
        setShowAddLink={setShowModal}
        context={context}
      />
      <AddLinkModal
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        context={context}
        k8sClusterName={k8sClusterName}
        namespace={namespace}
        serviceId={serviceId}
      />
    </>
  );
};
