import { TextContainer } from "../../../violationDrawerStyles";

export const howDescription =
  "Reconfigure resources - based on recommendation logic";

export const getWhatDescription = (numOfContainers: number | undefined) =>
  !numOfContainers ? null : (
    <TextContainer>
      <b>
        {numOfContainers} container{numOfContainers === 1 ? "" : "s"}
      </b>{" "}
      experienced <b>high Requests to Limits ratio</b> in the last 7 days
    </TextContainer>
  );

export const getWhyDescription = () => (
  <TextContainer>
    Overprovisioning in Kubernetes refers to the practice of allocating more
    resources (CPU and memory) to a container or pod than it actually needs to
    run its workloads efficiently.
    <br /> <br />
    While overprovisioning can be a useful strategy, it also comes with some
    dangers:
    <br />
    1. <b>Wasted resources:</b> Resources that are allocated but not actively
    used are wasted.
    <br /> 2. <b>Increased Costs:</b> Paying for unused (allocated) resources.
    <br />
    3. <b> Cluster Scalability:</b> Overprovisioning can limit the scalability
    of your Kubernetes cluster since you may prematurely run out of available
    resources.
    <br /> 4. <b>Noisy Neighbors:</b> Overprovisioning one application can
    negatively impact neighboring applications if there are limited resources
    available.
  </TextContainer>
);
