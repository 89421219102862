import { useCallback, useState } from "react";
import { v4 as uuid4 } from "uuid";

import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import { selectAddEditIgnoreRuleDialogState } from "../../../../../../store/reliabilityStoreSelectors";
import { useAddEditRuleContext } from "../context/useAddEditRuleContext";
import { IgnoreRule } from "../../../../../../../../generated/reliabilityApi";
import { filterAllSelectionFromList } from "../addEditRuleDialogUtils";
import {
  useInsertNewIgnoreRule,
  useInvalidateIgnoreRulesCache,
  useOnRuleChange,
  useUpdateExistingIgnoreRule,
} from "../../../../../../hooks/ignoreRulesHooks";

export const useCreateIgnoreRule = () => {
  const { checkType, existingIgnoreRule } = useReliabilityStore(
    selectAddEditIgnoreRuleDialogState
  );
  const { selectedClusterNames, selectedNamespaces, selectedServices } =
    useAddEditRuleContext();

  return useCallback((): IgnoreRule | undefined => {
    if (!checkType) {
      return undefined;
    }

    return {
      checkType,
      id: existingIgnoreRule?.id ?? uuid4(),
      scope: {
        clusterNames: filterAllSelectionFromList(selectedClusterNames),
        namespaces: filterAllSelectionFromList(selectedNamespaces),
        shortResourceNames: filterAllSelectionFromList(selectedServices),
      },
    };
  }, [
    checkType,
    existingIgnoreRule?.id,
    selectedClusterNames,
    selectedNamespaces,
    selectedServices,
  ]);
};

export const useAddOrEditRule = () => {
  const { existingIgnoreRule, checkType } = useReliabilityStore(
    selectAddEditIgnoreRuleDialogState
  );
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const insertNewIgnoreRule = useInsertNewIgnoreRule();
  const updateExistingIgnoreRule = useUpdateExistingIgnoreRule();
  const invalidateIgnoreRulesCache = useInvalidateIgnoreRulesCache();
  const onRuleChange = useOnRuleChange();

  const executeMutation = useCallback(
    async (ignoreRule: IgnoreRule) => {
      const mutation = existingIgnoreRule
        ? updateExistingIgnoreRule
        : insertNewIgnoreRule;

      setIsSendingRequest(true);
      const { error } = await mutation(ignoreRule);

      setIsSendingRequest(false);
      checkType && (await invalidateIgnoreRulesCache([checkType]));
      await onRuleChange();
      return { error };
    },
    [
      existingIgnoreRule,
      updateExistingIgnoreRule,
      insertNewIgnoreRule,
      checkType,
      invalidateIgnoreRulesCache,
      onRuleChange,
    ]
  );

  return { executeMutation, isSendingRequest };
};
