import React from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { muiColors, muiTheme } from "@komodorio/design-system";

import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";

const SIZE = 24;

export const ProfileIcon: React.FC = () => {
  const { userId } = useUserMetadata();
  const { picture } = useUserLoginInfo() || {};
  const { data: user } = useGetUserById({ id: userId });
  const { displayName, email } = user ?? {};

  const name = displayName || email;

  return (
    <Avatar
      sx={{ backgroundColor: muiColors.blue[500], width: SIZE, height: SIZE }}
      src={picture}
      alt={name}
    >
      <Typography variant="body1" color={muiTheme.palette.common.white}>
        {name?.[0].toUpperCase()}
      </Typography>
    </Avatar>
  );
};
