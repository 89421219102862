import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import React from "react";
import { capitalize } from "@mui/material";

import { SummaryLoader } from "../Summary";

import { CheckCategory } from "@/generated/reliabilityApi";
import { AriaLabels } from "@/shared/config/ariaLabels";

export type SummaryChipProps = {
  category: CheckCategory;
  isLoading: boolean;
  issuesCount: number;
  risksCount: number;
};

// eslint-disable-next-line
const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const SummaryChip: React.FC<SummaryChipProps> = ({
  category,
  isLoading,
  issuesCount,
  risksCount,
}) => {
  if (!isLoading && issuesCount === 0 && risksCount === 0) {
    return null;
  }

  return isLoading ? (
    <SummaryLoader width="306px" />
  ) : (
    <Chip
      aria-label={AriaLabels.ClusterOverview.HealthSection.SummaryChip}
      label={
        <Typography variant="body3" color="error.dark">
          {capitalize(category)}:{" "}
          <Typography variant="body3" color="info.dark" display="inline">
            {formatter.format(issuesCount)} Realtime Issues,{" "}
            {formatter.format(risksCount)} Reliability Risks
          </Typography>
        </Typography>
      }
    />
  );
};
