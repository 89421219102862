import React from "react";

import { useViolation } from "../../../hooks/useViolation";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import {
  useGetClusterSummaryData,
  useGetHeaderProps,
} from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";

import {
  getHowDescription,
  getWhatDescription,
  getWhyDescription,
} from "./texts";
import { Details } from "./Details/Details";

export const K8sEOL: React.FC = () => {
  const { data } = useViolation();

  const violation = data?.data.violation;

  const supportingData = violation?.supportingData.kubernetesEndOfLife;

  const headerProps = useGetHeaderProps(violation);
  const summaryProps = useGetClusterSummaryData({
    komodorUid: violation?.komodorUid,
    clusterProvider: supportingData?.clusterProvider,
    policyName: violation?.policyName,
    policyId: violation?.policyId,
  });

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription({
                version: supportingData?.clusterVersionShort,
                clusterProvider: supportingData?.clusterProvider,
                eolDate: supportingData?.eolDate,
              })}
              <Details
                supportingData={supportingData}
                komodorUid={violation?.komodorUid}
              />
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={getHowDescription()}
        />
      }
    />
  );
};
