import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { ColorIndication } from "../../../../../utils/graphUtils";
import { CostsChartsLegend } from "../../../../../types/costOptimizationTypes";
import { LegendItem } from "../../../../shared/graph/Legend";

const LegendContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  padding: 5px;
  justify-content: center;
`;

export const CustomLegend: React.FC<CostsChartsLegend> = ({
  hide,
  setHide,
  props,
}) => {
  const { payload } = props;
  const content = useMemo(
    () => (
      <>
        {payload?.map((legendItem) => {
          return (
            <LegendItem
              key={legendItem.value}
              onClick={() => setHide(legendItem.value)}
            >
              <ColorIndication
                color={legendItem.color}
                isunselected={hide[legendItem.value]}
              />
              <Typography variant="caption" color={muiColors.gray[700]}>
                {legendItem.value}
              </Typography>
            </LegendItem>
          );
        })}
      </>
    ),
    [hide, payload, setHide]
  );

  return <LegendContainer>{content}</LegendContainer>;
};
