import { addMinutes, endOfDay, startOfDay } from "date-fns";

import { now } from "@/components/k8sAddons/constants/time";

export const toUtc = (date: Date): Date => {
  return addMinutes(date, -1 * date.getTimezoneOffset());
};

export const getSevenDaysFromEndOfDay = (): Date => {
  return toUtc(endOfDay(now + 7 * 24 * 60 * 60 * 1000));
};

export const getThirtyDaysFromEndOfDay = (): Date => {
  return toUtc(endOfDay(now + 30 * 24 * 60 * 60 * 1000));
};

export const getStartOfDayInUtc = (date: Date = new Date()) =>
  toUtc(startOfDay(date));
