import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  APIKey,
  ApiKeysApiApiV1ApiKeysIdDeleteRequest,
  apiV1ApiKeysIdDeleteUrl,
} from "../../../../../generated/auth";

const DELETE_API_KEY_PATH = "/api-keys/:id";

const deleteApiKey = async (
  apiClient: AxiosInstance,
  params: ApiKeysApiApiV1ApiKeysIdDeleteRequest
): Promise<APIKey> => {
  const { data } = await apiClient.delete(
    apiV1ApiKeysIdDeleteUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useDeleteApiKey = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [DELETE_API_KEY_PATH],
    (params: ApiKeysApiApiV1ApiKeysIdDeleteRequest) =>
      deleteApiKey(apiClient, params)
  );
};
