import { subDays } from "date-fns";

import {
  BucketTimeFrame,
  OverviewPageTimeWindow,
} from "../types/overviewPageTypes";

export const timeWindowToDate = (
  tw: OverviewPageTimeWindow,
  now: Date
): Date => {
  switch (tw) {
    case "14d":
      return subDays(now, 14);
    case "7d":
      return subDays(now, 7);
  }
};

// currently only 24h is supported
export const getBucketTimeFrame = (): BucketTimeFrame => "24h";
