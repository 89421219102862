import React, { useState } from "react";

import ChartRollbackDrawer from "../../../Inspection/Helm/drawers/ChartRollbackDrawer";
import HelmChart from "../../../Inspection/Helm/helmChart";
import { ActionButtonProps } from "../types";
import Action from "../index";
import { useHasPermissions } from "../../../../shared/hooks/useUserMetadata/rbac";

export const RollbackHelmChartButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
}) => {
  const [open, setOpen] = useState(false);
  const revisions = (resource as HelmChart).revisions;

  const { canManageHelm } = useHasPermissions();

  return canManageHelm ? (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={() => setOpen(true)}
      Button={Button}
      disabled={!revisions || revisions?.length <= 1}
    >
      <ChartRollbackDrawer
        onClose={() => setOpen(false)}
        open={open}
        chart={resource as HelmChart}
      />
    </Action>
  ) : null;
};
