import { useMemo } from "react";

import {
  FetchPodLogsQuery,
  useFetchPodLogsQuery,
} from "../../../../../../generated/graphql";
import { useQueryRefreshWithVariables } from "../../../../../../shared/hooks/useQueryWithVariables";
import { ContainerLog, WorkloadHealthyPodsOutput } from "../types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { OpinionatedSummary } from "./AvailabilitySummaryUtils";
import { parseIssueSummary } from "./utils";

type PodsSummaryReturnType = {
  errorMessages: (string | undefined)[];
  customMessages: string[];
  logsSummary: ContainerLog[];
  opinionatedSummary: OpinionatedSummary;
  data: FetchPodLogsQuery | null;
  fetching: boolean;
};

export const usePodsSummary = (
  opinionatedSummary: OpinionatedSummary,
  container: ContainerLog | null
): PodsSummaryReturnType => {
  const errorMessages = useMemo(() => {
    return opinionatedSummary
      .filter((summary) => summary.errorMessage)
      .map((summary) => summary.errorMessage);
  }, [opinionatedSummary]);
  const customMessages = useMemo(() => {
    return [
      ...new Set(
        opinionatedSummary
          .filter((summary) => summary.customMessage)
          .map((summary) => summary.customMessage)
      ),
    ];
  }, [opinionatedSummary]);
  const logsSummary = useMemo(() => {
    return opinionatedSummary.reduce<ContainerLog[]>((accumulator, summary) => {
      return summary?.containersLogs
        ? accumulator.concat(summary.containersLogs)
        : accumulator;
    }, []);
  }, [opinionatedSummary]);

  const taskVariables = useMemo(() => {
    return container?.logTaskId ? { taskId: container.logTaskId } : undefined;
  }, [container?.logTaskId]);

  const [data, , fetching] = useQueryRefreshWithVariables(
    useFetchPodLogsQuery,
    taskVariables ?? null
  );

  return {
    opinionatedSummary,
    errorMessages,
    customMessages,
    logsSummary,
    data,
    fetching,
  };
};

export const useOpinionatedSummary = (
  podsCheckOutput: WorkloadHealthyPodsOutput | undefined
): OpinionatedSummary =>
  useMemo(() => {
    if (!podsCheckOutput) {
      return [];
    }
    const summary = podsCheckOutput?.issueSummary;

    return summary?.slice(0, 2).map((r) => parseIssueSummary(r)) ?? [];
  }, [podsCheckOutput]);
