import React from "react";
import styled from "styled-components";

import { H2 } from "../typography";

import { LinesLoader } from "./Line";

const Container = styled.div`
  width: 20rem;
  margin: auto;
  margin-top: 10%;
`;

const LoadingMessage = styled(H2)`
  text-align: center;
  margin-top: 1.5rem;
`;

const FetchDataLoading: React.FC<{ dataName: string }> = ({ dataName }) => (
  <Container data-e2e-selector={"loader"}>
    <LinesLoader />
    <LoadingMessage>
      Hold tight!
      <br />
      <br />
      {`We’re fetching the ${dataName} you requested`}
    </LoadingMessage>
  </Container>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default FetchDataLoading;
