import React, { useEffect, useMemo, useState } from "react";

import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import EventGroup from "../common/EventGroup";
import groupEvents from "../common/EventGroup/groupEvents";
import {
  EventPagesMap,
  EventsPagination,
  splitToTimeWindow,
} from "../common/EventsPagination/EventsPagination";
import { createTimeWindowKey, TimeWindow } from "../../shared/types/TimeWindow";
import { SYSTEM_WIDE_SERVICE_ID } from "../../shared/constants/systemWideServiceId";
import { useFilterEventGroupsByAppView } from "../../shared/hooks/appViews/appViewFilterHooks";

interface EventsPagination {
  paginationElement: JSX.Element[];
  allEventGroups: EventGroup[] | undefined;
  timeWindowsLength: number;
  numTimeWindowsFetched: number;
  isFetching: boolean;
}

const useEventsPagination = (fullTimeWindow: TimeWindow): EventsPagination => {
  const [eventPagesMap, setEventPagesMap] = useState<EventPagesMap>({
    fullTimeWindow,
    pages: {},
  });
  const filterEventGroupsByAppView = useFilterEventGroupsByAppView();

  useEffect(
    () => setEventPagesMap({ fullTimeWindow, pages: {} }),
    [fullTimeWindow]
  );

  const flags = useOverridableFlags();
  const pageSizeHours = Number(flags.eventsPagePaginationHoursChunk);

  const pageTimeWindows = useMemo(
    () => splitToTimeWindow(eventPagesMap.fullTimeWindow, pageSizeHours),
    [eventPagesMap.fullTimeWindow, pageSizeHours]
  );

  const timeWindowsLength = pageTimeWindows.length;
  const numTimeWindowsFetched = Object.keys(eventPagesMap.pages).length;
  const isFetching = numTimeWindowsFetched !== timeWindowsLength;

  const { loadEventPagesSerially } = flags;
  const timeWindowsToFetch = useMemo(() => {
    if (!loadEventPagesSerially) return pageTimeWindows;
    return pageTimeWindows.slice(0, numTimeWindowsFetched + 1);
  }, [loadEventPagesSerially, pageTimeWindows, numTimeWindowsFetched]);

  const paginationElement = useMemo(
    () =>
      timeWindowsToFetch.map((tw) => (
        <EventsPagination
          pageTimeWindow={tw}
          setEventPagesMap={setEventPagesMap}
          key={createTimeWindowKey(tw)}
        />
      )),
    [timeWindowsToFetch]
  );

  const allEventGroups = useMemo(() => {
    if (isFetching) return;

    const eventGroups = groupEvents(
      Object.values(eventPagesMap.pages),
      SYSTEM_WIDE_SERVICE_ID,
      flags
    );
    return filterEventGroupsByAppView(eventGroups);
  }, [isFetching, eventPagesMap.pages, flags, filterEventGroupsByAppView]);

  return {
    paginationElement,
    allEventGroups,
    timeWindowsLength,
    numTimeWindowsFetched,
    isFetching,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useEventsPagination;
