import { muiColors } from "@komodorio/design-system";
import { Button, Alert } from "@komodorio/design-system/deprecated";
import { subDays } from "date-fns";
import { sortBy } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { DayPicker, SelectRangeEventHandler } from "react-day-picker";
import "react-day-picker/dist/style.css";
import styled from "styled-components";

import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";
import { TimeWindow } from "../../../shared/types/TimeWindow";

import TimeInput, { TimeInputRef } from "./TimeInput";

const Container = styled.div`
  border-left: 1px solid #ced5e8;

  .rdp {
    font-size: 14px;
    --rdp-cell-size: 32px;
    --rdp-accent-color: ${muiColors.indigo[500]};
  }
`;

const TimePicker = styled.div`
  display: grid;
  grid-template: "fromLabel from toLabel to" auto / auto auto auto auto;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-top: 1px solid #ced5e8;
`;

const ApplyContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0.5rem;
  border-top: 1px solid #ced5e8;
  align-items: center;
  gap: 10px;
`;

const AlertContainer = styled.div`
  flex-grow: 1;
  & > div {
    gap: 0.5rem;
    height: auto;
    padding: 1px;
    & > div {
      font-size: 0.75rem;
      &:first-child {
        padding: 6px;
      }
    }
  }
`;

const maxDate = new Date();
const minDate = subDays(maxDate, 30);

const useFormattedDate = (d: Date) => {
  const { formatToParts } = useDateFormatter({
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return useMemo(() => {
    const parts = Object.fromEntries(
      formatToParts(d).map(({ type, value }) => [type, value])
    );
    const { year, month, day } = parts;
    return new Date(+year, +month - 1, +day, d.getHours(), d.getMinutes());
  }, [d, formatToParts]);
};

interface CustomRangeSelectorProps {
  warning?: string;
  initialTimeWindow: TimeWindow;
  onApply: (tw: Partial<TimeWindow>) => void;
}
const CustomRangeSelector: React.FC<CustomRangeSelectorProps> = ({
  warning,
  initialTimeWindow,
  onApply,
}) => {
  const initialFrom = useFormattedDate(initialTimeWindow.start);
  const initialTo = useFormattedDate(initialTimeWindow.end);

  const [staged, setStaged] = useState({
    lastSelected: "to",
    range: {
      from: initialFrom,
      to: initialTo,
    },
  });

  const fromRef = useRef<TimeInputRef>(null);
  const toRef = useRef<TimeInputRef>(null);

  const onDayClick = useCallback<SelectRangeEventHandler>((_range, day) => {
    setStaged((current) => {
      if (current.lastSelected === "to") {
        const fromDay = new Date(day);
        fromDay.setHours(0, 0, 0, 0);
        fromRef.current?.setValue(fromDay);
        return { lastSelected: "from", range: { from: fromDay, to: day } };
      }
      const range = sortBy([current.range.from, day], (d) => d.getTime());
      const toDay = new Date(range[1]);
      toDay.setHours(23, 59, 59, 999);
      toRef.current?.setValue(toDay);
      return { lastSelected: "to", range: { from: range[0], to: toDay } };
    });
  }, []);

  return (
    <>
      <Container>
        <DayPicker
          mode="range"
          selected={staged.range}
          onSelect={onDayClick}
          fromDate={minDate}
          toDate={maxDate}
        />
        <TimePicker>
          <label htmlFor="customTimeFrom">From:</label>
          <TimeInput
            id="customTimeFrom"
            ref={fromRef}
            defaultValue={staged.range.from}
          />
          <label htmlFor="customTimeTo">To:</label>
          <TimeInput
            id="customTimeTo"
            ref={toRef}
            defaultValue={staged.range.to}
          />
        </TimePicker>
      </Container>
      <ApplyContainer>
        <Button
          variant="primary"
          onClick={() => {
            const start = fromRef.current?.getValue(staged.range.from);
            const end = toRef.current?.getValue(staged.range.to);
            if (start && end) {
              onApply({ start, end });
            }
          }}
        >
          Apply
        </Button>
        {warning && (
          <AlertContainer>
            <Alert variant={"warning"}>{warning}</Alert>
          </AlertContainer>
        )}
      </ApplyContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CustomRangeSelector;
