import React from "react";

import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { InsightCard } from "../../shared/components/InsightCard";
import { insightCardTitles } from "../../insightsConstants";
import {
  useMemoizedDataBreakdownPerInterval,
  useOpenInsightsDrawer,
} from "../../shared/hooks/appInisghtsHooks";

import { FailedDeploysModalContent } from "./FailedDeploysModalContent";
import {
  useComparisonLinesGraph,
  useDataBreakdownDescription,
} from "./failedDeplysHooks";

export const ID = "Failed Deploys";

const shouldDisplayFailedDeploys = (
  firstIntervalTotal?: number,
  secondIntervalTotal?: number
) => {
  if (firstIntervalTotal === undefined || secondIntervalTotal === undefined)
    return false;
  return (
    (firstIntervalTotal === 0 && secondIntervalTotal > 0) ||
    (secondIntervalTotal === 0 && firstIntervalTotal > 0) ||
    Math.abs(firstIntervalTotal / secondIntervalTotal) > 0.1
  );
};

export const FailedDeploys: React.FC = () => {
  const {
    failedDeployments: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const openInsightsDrawer = useOpenInsightsDrawer();

  const dataPointsBreakdown = useMemoizedDataBreakdownPerInterval(
    data?.sumByDay
  );

  const description = useDataBreakdownDescription(dataPointsBreakdown);

  const comparisonLinesGraph = useComparisonLinesGraph({
    data: dataPointsBreakdown,
    displayMode: "compact",
  });

  if (
    !loading &&
    !shouldDisplayFailedDeploys(
      dataPointsBreakdown?.firstTotal,
      dataPointsBreakdown?.secondTotal
    )
  ) {
    return null;
  }

  const { title, icon } = insightCardTitles.failedDeployments;

  const onCardClick = () => {
    openInsightsDrawer({
      title: description,
      component: (
        <FailedDeploysModalContent
          dataPointsBreakdownPerIntervals={dataPointsBreakdown}
        />
      ),
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={description}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"oneDescriptionLineAndGraph"}
    >
      {comparisonLinesGraph}
    </InsightCard>
  );
};
