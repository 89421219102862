import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Dictionary } from "@/shared/types/Dictionary";
import { useGetDefaultConfigurationsPerFieldName } from "@/components/reliability/components/pages/policies/policiesHooks";

type FieldRegistrationByCheckedStatusProps = {
  isChecked: boolean;
  configurationNamesPerInput: string[];
};

export const useFieldRegistrationByCheckedStatus = ({
  configurationNamesPerInput,
  isChecked,
}: FieldRegistrationByCheckedStatusProps) => {
  const latestValues = useRef<Dictionary<string>>({});
  const { register, getValues, unregister, setValue } = useFormContext();
  const getDefaultConfigurations = useGetDefaultConfigurationsPerFieldName();

  useEffect(() => {
    if (isChecked) {
      configurationNamesPerInput.forEach((name) => {
        const registeredValue = getValues(name);
        if (registeredValue) {
          return;
        }

        if (latestValues.current[name]) {
          // restore latest value before unchecking the field's checkbox
          setValue(name, latestValues.current[name]);
          delete latestValues.current[name];
        } else {
          // set default value if it's the first time the field is checked
          const defaultValue = getDefaultConfigurations(name);
          setValue(name, defaultValue);
        }

        register(name);
      });
    } else {
      configurationNamesPerInput.forEach((name) => {
        // save latest value to restore it when the checkbox is checked again
        latestValues.current[name] = getValues(name);
        unregister(name);
      });
    }
  }, [
    configurationNamesPerInput,
    getDefaultConfigurations,
    getValues,
    isChecked,
    register,
    setValue,
    unregister,
  ]);

  return latestValues.current;
};
