import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { DataGridPro } from "@mui/x-data-grid-pro";

import {
  useEmptyColumns,
  usePoliciesTableColumns,
} from "./hooks/tableColumnHooks";
import {
  POLICIES_TABLE_LOADING_STATE_ROWS,
  POLICIES_TABLE_ROW_HEIGHT,
} from "./policiesTableConstants";
import { NoResultsFound } from "./NoResultsFound";
import { useSortedPolicies } from "./hooks/useSortedPolicies";
import { useAllPoliciesTableContext } from "./context/useAllPoliciesTableContext";

import { useGetAllPolicies } from "@/shared/hooks/reliability-api/policies/usePolicies";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { PolicyListItem } from "@/generated/reliabilityApi";
import { generateEmptyRows } from "@/shared/utils/tableUtils";

const Container = styled.div<{
  $isEmptyResults: boolean;
  $isUpdatingPolicy: boolean;
}>`
  position: relative;
  &&& {
    .MuiDataGrid-root {
      background: white;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02),
        0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 5px -1px rgba(0, 0, 0, 0.1);
    }
    .MuiDataGrid-virtualScroller {
      overflow: hidden;
    }
    .MuiDataGrid-row {
      border-top: 1px solid ${muiColors.gray[200]};
      box-sizing: border-box;
      opacity: ${({ $isEmptyResults }) => ($isEmptyResults ? 0 : 1)};
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-columnHeader:nth-child(2) {
      padding-left: 0;
    }
    .priority-cell {
      padding: 0;
      outline-offset: 0;
    }
  }
`;

const {
  policies: {
    policiesTable: {
      stateLoading: stateLoadingAriaLabel,
      stateEmpty: stateEmptyAriaLabel,
      stateResults: stateResultsAriaLabel,
    },
  },
} = reliabilityArialLabels;

type AllPoliciesTableProps = {
  searchTerm?: string;
  onClearSearchTerm?: () => void;
};

export const AllPoliciesTable: React.FC<AllPoliciesTableProps> = ({
  searchTerm,
  onClearSearchTerm,
}) => {
  const { data: policiesData, isFetching, error } = useGetAllPolicies();
  const { isUpdatingPolicy } = useAllPoliciesTableContext();
  const sortedPolicies = useSortedPolicies(policiesData?.data?.policies);
  const columns = usePoliciesTableColumns();
  const emptyColumns = useEmptyColumns();
  const isFetchingResults = isFetching && !sortedPolicies;

  const filteredResultsBySearchTerm = useMemo(() => {
    if (!searchTerm || !sortedPolicies) return sortedPolicies;

    return sortedPolicies.filter((policy) => {
      const resultsFromScope = policy.scope.clusterRegexes?.find((cluster) => {
        return cluster.toLowerCase().includes(searchTerm.toLowerCase());
      });
      const resultsFromName = policy.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      return resultsFromScope || resultsFromName;
    });
  }, [sortedPolicies, searchTerm]);

  const isEmptyResults = !error && filteredResultsBySearchTerm?.length === 0;
  const hasNoResults = isEmptyResults || !!error;

  const columnsToUse =
    isFetchingResults || isEmptyResults ? emptyColumns : columns;

  const ariaLabelToUse = useMemo(() => {
    if (isFetchingResults) return stateLoadingAriaLabel;
    if (hasNoResults) return stateEmptyAriaLabel;
    return stateResultsAriaLabel;
  }, [hasNoResults, isFetchingResults]);

  const rowsToUse =
    isFetchingResults || hasNoResults
      ? generateEmptyRows<PolicyListItem>(POLICIES_TABLE_LOADING_STATE_ROWS)
      : filteredResultsBySearchTerm;

  return (
    <Container
      $isEmptyResults={isEmptyResults}
      $isUpdatingPolicy={isUpdatingPolicy}
      aria-label={ariaLabelToUse}
    >
      <DataGridPro
        rowHeight={POLICIES_TABLE_ROW_HEIGHT}
        rows={rowsToUse ?? []}
        columns={columnsToUse}
        disableColumnMenu
        disableColumnReorder={true}
        disableColumnResize={true}
        autoHeight={true}
      />
      <>
        {hasNoResults && (
          <NoResultsFound onClearSearchTerm={onClearSearchTerm} />
        )}
      </>
    </Container>
  );
};
