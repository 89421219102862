import React, { useMemo } from "react";
import { Line } from "recharts";

import {
  getLastNDays,
  timeWindowToEpochs,
  transformData,
  transformDataPoints,
} from "../../../../Metrics/utils";
import {
  CustomGraphDataPoint,
  DataPoint,
  GraphDataPoint,
  MetricsData,
  MetricType,
} from "../../../../Metrics/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../../resources/pod";
import DeletedPod, { DELETED_POD_KIND } from "../../../resources/deletedPod";
import { deletedPodTimeWindowToEpochs } from "../../../../Metrics/useDeletedPodMetrics";
import { PartialMetricsParams, podContainersType } from "../types";
import { useMetricsTabContext } from "../context/MetricsTabContext";
import { podContainerTypeToLabel } from "../utils";
import { podContainersLineColors } from "../constants";
import {
  METRICS_POD_CPU_ENDPOINT,
  METRICS_POD_MEMORY_ENDPOINT,
} from "../../../../../shared/hooks/metrics-api/requestResponseMaps";
import { useMetrics } from "../../../../Metrics/useMetrics";
import { OptimizationStrategy } from "../../../../../generated/metricsApi";
import { Timeframe } from "../../../../../shared/types/TimeWindow";
import { millisecondsToUnixTime } from "../../../../../shared/utils/timeUtils";

export type MetricsPerTypeParams = PartialMetricsParams & {
  fromEpoch: number;
  toEpoch: number;
  toEpochMs: number;
};

export const useTransformMetricsData = (
  metricsData: MetricsData,
  timeWindow: Timeframe,
  recommendationStrategy?: OptimizationStrategy
): GraphDataPoint[] => {
  return useMemo(() => {
    const fullData = transformData(metricsData, recommendationStrategy);
    if (timeWindow === Timeframe.Last24Hours) {
      return getLastNDays(fullData, 1);
    }
    return fullData;
  }, [metricsData, recommendationStrategy, timeWindow]);
};

export const useMetricParams = (
  resource: Pod,
  endTime?: number
): MetricsPerTypeParams => {
  const { cluster, namespace, name, containerStatuses, kind } = resource;
  const container = containerStatuses?.[0];
  const { timeWindow } = useMetricsTabContext();
  const { fromEpoch, toEpoch, toEpochMs } = useMemo(() => {
    if (timeWindow.timeframe === Timeframe.Custom) {
      return {
        fromEpoch: millisecondsToUnixTime(timeWindow.start.getTime()),
        toEpoch: millisecondsToUnixTime(timeWindow.end.getTime()),
        toEpochMs: timeWindow.end.getTime(),
      };
    }

    if (kind === DELETED_POD_KIND) {
      const deletedPodEpochs = deletedPodTimeWindowToEpochs(
        timeWindow.timeframe,
        resource as unknown as DeletedPod
      );
      return {
        fromEpoch: deletedPodEpochs.fromEpoch,
        toEpoch: deletedPodEpochs.toEpoch,
        toEpochMs: deletedPodEpochs.toEpoch,
      };
    }

    return timeWindowToEpochs(
      timeWindow.timeframe,
      endTime ? new Date(endTime) : undefined
    );
  }, [endTime, kind, resource, timeWindow]);

  return useMemo(
    () => ({
      clusterName: cluster,
      namespace: namespace,
      podName: name,
      containerName: containerStatuses?.[0]?.name ?? "",
      fromEpoch,
      toEpoch,
      toEpochMs,
      endTimestamp: endTime ?? new Date().getTime(),
      pause: !container?.name,
      ...(containerStatuses?.length && {
        expectedPodContainersNum: containerStatuses?.length,
      }),
    }),
    [
      cluster,
      container?.name,
      containerStatuses,
      fromEpoch,
      name,
      namespace,
      toEpoch,
      toEpochMs,
      endTime,
    ]
  );
};

type TransformSingleMetricDataParams = {
  metricsData: DataPoint[];
  timeWindow: Timeframe;
  name: string;
};

export const useTransformSingleMetricData = ({
  name,
  metricsData,
  timeWindow,
}: TransformSingleMetricDataParams): CustomGraphDataPoint[] => {
  return useMemo(() => {
    const fullData = transformDataPoints(metricsData, name);
    if (timeWindow === Timeframe.Last24Hours) {
      return getLastNDays(fullData, 1) as CustomGraphDataPoint[];
    }
    return fullData;
  }, [metricsData, timeWindow, name]);
};

export const useChartLinesFromContainerNames = (
  type: podContainersType
): React.ReactNode[] => {
  const { podContainersNames } = useMetricsTabContext();

  return useMemo(() => {
    const ariaLabelTitle = podContainerTypeToLabel(type);
    return podContainersNames.map((containerName, idx) => {
      return (
        <Line
          aria-label={`${ariaLabelTitle} usage for ${containerName}`}
          type="stepAfter"
          stroke={podContainersLineColors[idx % podContainersNames.length]}
          dataKey={containerName}
          dot={false}
          strokeWidth="2"
          strokeLinejoin="bevel"
          strokeLinecap="round"
          isAnimationActive={false}
          key={containerName}
        />
      );
    });
  }, [podContainersNames, type]);
};

export const useMetricsTabGraphData = (
  metricsParams: MetricsPerTypeParams,
  endpoint: typeof METRICS_POD_MEMORY_ENDPOINT | typeof METRICS_POD_CPU_ENDPOINT
) => {
  const { timeWindow } = useMetricsTabContext();

  const state = useMetrics({
    ...metricsParams,
    endpoint,
  });

  const type: MetricType =
    endpoint === METRICS_POD_MEMORY_ENDPOINT ? "memory" : "cpu";

  const data = useTransformMetricsData(state.metrics, timeWindow.timeframe);

  return { type, data, state };
};
