import React from "react";
import { ConfigMap as ConfigMapType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { CM } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ConfigMapDescribeTab } from "../tabs/DescribeTab/configMap/ConfigMapDescribeTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ConfigMap implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Edit, AvailableActions.Delete];
  readonly creationTimestamp;
  readonly data;
  readonly fullObj;
  readonly defaultTab;

  constructor(cluster: string, configMap: ConfigMapType, agentId: string) {
    this.agentId = agentId;
    this.id = configMap.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = configMap.metadata?.namespace ?? "";
    this.name = configMap.metadata?.name ?? "";
    this.kind = configMap.kind ?? "";
    this.labels = configMap.metadata?.labels ?? {};
    this.annotations = configMap.metadata?.annotations ?? {};
    this.icon = CM;
    this.creationTimestamp = configMap.metadata?.creationTimestamp ?? "";
    this.data = configMap.data ?? {};
    this.fullObj = configMap;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ConfigMapDescribeTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
