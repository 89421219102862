import styled from "styled-components";
import React from "react";
import Warning from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

const color = muiColors.pink[700];

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const EmptySummaryItem: React.FC = () => (
  <Container>
    <Warning fontSize="small" htmlColor={color} />
    <Typography variant="body2" color={color}>
      Not configured
    </Typography>
  </Container>
);
