import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { compareDesc } from "date-fns";

import PodEventsTable from "../../../common/table/PodEventsTable/PodEventsTable";
import { usePodDescribeEvents } from "../DescribeTab/pod/usePodDescribeEvents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
`;

export const DeletedPodEventsTab: React.FC<{
  podName: string;
  namespace: string;
  clusterName: string;
}> = ({ podName, namespace, clusterName }) => {
  const podNativeEvents = usePodDescribeEvents(
    clusterName,
    namespace,
    podName,
    false
  );

  const formatedEvents = useMemo(() => {
    if (!podNativeEvents) return [];
    return podNativeEvents
      ?.map((event) => ({
        ...event,
        type: event.severityType,
      }))
      .sort((a, b) =>
        compareDesc(new Date(a.lastTimestamp), new Date(b.lastTimestamp))
      );
  }, [podNativeEvents]);

  if (!podNativeEvents) return null;

  return (
    <Container>
      {podNativeEvents?.length > 0 && (
        <Typography variant="h5" color={muiColors.gray[800]}>
          Pod Events ({podNativeEvents?.length})
        </Typography>
      )}
      <PodEventsTable podEvents={formatedEvents} isHeightRestricted={false} />
    </Container>
  );
};
