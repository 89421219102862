import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { blueBrand } from "../../../../../Colors";
import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { TimeWindowProps } from "../../../../../shared/types/TimeWindow";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { ZIndex } from "../../../../../constants/zIndex";

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  visibility: ${({ visible }) => (visible ? undefined : "hidden")};
  font-size: 14px;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em 1.25em;
  background-color: ${blueBrand};
  color: white;
  box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.3);
  border-radius: 1.5em;
  cursor: pointer;
  z-index: ${ZIndex.AboveTimelineChart};
`;

const NewEventsAlert: React.FC<{ visible: boolean } & TimeWindowProps> = ({
  visible,
  timeWindow,
  setTimeWindow,
}) => {
  const onClick = useCallback(() => {
    dispatchEvent(AnalyticEvents.ToastHeaders.NewEventAlert_Click);
    setTimeWindow({ timeframe: timeWindow.timeframe });
  }, [setTimeWindow, timeWindow.timeframe]);
  useEffect(() => {
    if (visible) {
      dispatchEvent(AnalyticEvents.ToastHeaders.NewEventAlert_View);
    }
  }, [visible]);
  return (
    <Container visible={visible} onClick={onClick}>
      New events are available. <u>Update</u>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NewEventsAlert;
