import React, { useEffect, useMemo, useRef, useState } from "react";
import { palette } from "@komodorio/design-system";
import { Input, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { isEqual, sortBy } from "lodash";

import { Table } from "../../../../../../../shared/components/Table/Table";
import { useKomodorServices } from "../../../../../../../shared/hooks/useKomodorServices";
import FilterBar from "../../../../../../../shared/components/FilterBar/FilterBar";
import { useServicesTableFilters } from "../hooks/useServicesTableFilters";
import { SelectedRowsOnlySwitch } from "../../../../../../../shared/components/SelectedRowsOnlySwitch";
import Selected from "../../../../../../../shared/components/FilterBar/Interfaces/Selected";
import { FILTERS_BAR_FILTERS_PARAM_KEY } from "../../../../../../../shared/config/urlSearchParamsKeys";
import { useCreationPageContext } from "../context/CreationPageContext";
import { Dictionary } from "../../../../../../../shared/types/Dictionary";

import { intoTableRow } from "./utils";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const FlexContainer = styled.div`
  display: flex;
`;

const GappedColumnFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.5rem;
`;

const SpacedFlexContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 15rem auto;
  grid-gap: 0 1.5rem;
  grid-template-areas: "sidebar main";
  margin-block-end: 32px;
  width: 100%;
`;

export const Sidebar = styled.div`
  grid-area: sidebar;
  align-self: start;
`;

const columns = [
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Kind",
    accessor: "kind",
  },
  {
    header: "Cluster",
    accessor: "cluster",
  },
  {
    header: "Namespace",
    accessor: "namespace",
  },
];

const MemoizedTable = React.memo(Table);

const rowIdCalculationOverride = (service: Dictionary<string>) => service.id;

export const ServicesTable: React.FC = () => {
  const {
    selectedServiceIds,
    setSelectedServiceIds,
    errorState: { servicesSelectedError },
    setErrorState,
  } = useCreationPageContext();
  const fetchedServices = useKomodorServices().unscopedServices;
  const [services, setServices] = useState(fetchedServices);
  const [showOnlySelectedServices, setShowOnlySelectedServices] =
    useState(false);
  const [servicesSearchTerm, setServicesSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] =
    useStringifiedStateInSearchParams<Selected>(FILTERS_BAR_FILTERS_PARAM_KEY);
  const errorTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // clear error if user has selected services
    if (servicesSelectedError && Object.keys(selectedServiceIds).length) {
      setErrorState({ servicesSelectedError: undefined });
    }
  }, [selectedServiceIds, servicesSelectedError, setErrorState]);

  useEffect(() => {
    if (servicesSelectedError) {
      errorTextRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [servicesSelectedError]);

  useEffect(() => {
    if (
      !unorderedArraysEqual(
        services?.map(({ id }) => id),
        fetchedServices?.map(({ id }) => id)
      )
    ) {
      setServices(fetchedServices);
    }
  }, [fetchedServices, services]);

  const toggleOnlySelectedServicesDisplay = () => {
    setShowOnlySelectedServices((prevState) => !prevState);
  };

  const handleServiceSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServicesSearchTerm(event.target.value);
  };

  const desiredFilterCategories = ["Clusters", "Namespaces", "Kind"];

  const { filterCategories, filteredServices } = useServicesTableFilters(
    services,
    servicesSearchTerm,
    showOnlySelectedServices,
    selectedServiceIds,
    selectedFilters,
    setSelectedFilters,
    desiredFilterCategories
  );

  const rows = useMemo(() => {
    return filteredServices?.map(intoTableRow) ?? [];
  }, [filteredServices]);

  const rowSelection = useMemo(
    () => ({
      selectedRows: selectedServiceIds,
      setSelectedRows: setSelectedServiceIds,
    }),
    [selectedServiceIds, setSelectedServiceIds]
  );

  return (
    <GridContainer>
      <Sidebar>
        <FilterBar
          title="Search"
          inputFilter={
            <Input
              placeholder="Name or label"
              value={servicesSearchTerm}
              onChange={handleServiceSearchTermChange}
            />
          }
          isLoading={!filterCategories}
          categories={filterCategories ?? []}
        />
      </Sidebar>
      <GappedColumnFlexContainer>
        <SpacedFlexContainer>
          <Typography>
            Showing {filteredServices?.length ?? 0} services
          </Typography>
          <SelectedRowsOnlySwitch
            checked={showOnlySelectedServices}
            onToggle={toggleOnlySelectedServicesDisplay}
            selectedRowsNumber={Object.keys(selectedServiceIds).length}
          />
        </SpacedFlexContainer>
        <MemoizedTable
          data={rows}
          columns={columns}
          rowSelection={rowSelection}
          pageSize={10}
          rowIdCalculationOverride={rowIdCalculationOverride}
        />
        <div ref={errorTextRef}>
          {servicesSelectedError && (
            <Typography variant={"text"} color={palette.pink["500"]}>
              {servicesSelectedError}
            </Typography>
          )}
        </div>
      </GappedColumnFlexContainer>
    </GridContainer>
  );
};

const unorderedArraysEqual = <T,>(
  array: Array<T> | undefined,
  otherArray: Array<T> | undefined
) => isEqual(sortBy(array), sortBy(otherArray));
