import { useMemo } from "react";

import { SameIssueInClusterInsightDataParsed } from "../../common/types";
import { useQueryWithVariables } from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useIssuesClosedAtByIdsQuery } from "../../../../../../../../generated/graphql";
import { WorkflowIssueStatus } from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

export const useUpdatedSameIssuesInsight = (
  issuesInsights: SameIssueInClusterInsightDataParsed[]
): SameIssueInClusterInsightDataParsed[] => {
  const variables = useMemo(
    () =>
      issuesInsights.length > 0
        ? { eventIds: issuesInsights.map((e) => e.id) }
        : null,
    [issuesInsights]
  );

  const issuesClosedAt = useQueryWithVariables(
    useIssuesClosedAtByIdsQuery,
    variables
  );
  return useMemo(() => {
    return issuesInsights.map((issue) => {
      const issueClosedAt = issuesClosedAt?.workflow_results?.find(
        (issueUpdated) => issue.id === issueUpdated.id
      )?.closedAt;
      return {
        ...issue,
        status: issueClosedAt
          ? WorkflowIssueStatus.closed
          : issueClosedAt === null
          ? WorkflowIssueStatus.open
          : issue.status,
      };
    });
  }, [issuesClosedAt?.workflow_results, issuesInsights]);
};

export const useSameIssueInClusterInsight = (
  sameIssuesInClusterInsights: SameIssueInClusterInsightDataParsed[]
) => {
  const sameIssuesInClusterInsightsUpdated = useUpdatedSameIssuesInsight(
    sameIssuesInClusterInsights
  );
  const issuesLength = sameIssuesInClusterInsightsUpdated.length;

  return {
    shouldShow: issuesLength > 0,
    text: `The same issue appeared on ${issuesLength} other service${
      issuesLength > 1 ? "s" : ""
    } in the cluster within 2 hours before the issue started`,
    sameIssuesInClusterInsightsUpdated,
  };
};
