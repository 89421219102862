import {
  Datapoint,
  WorkloadMetricsContainers,
  WorkloadMetricsDataPoints,
} from "../../../../../../../../../../generated/metricsApi";
import {
  cpuUnit,
  getValue,
  shortVariation,
} from "../../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";
import { CommonApiResponse } from "../../../../../../../../../../shared/types/commonApiResponse";
import { ThrottledCPUSupportingDataContainersInner } from "../../../../../../../../../../generated/reliabilityApi";

type GetSummaryItemsProps = {
  throttledHours: number | undefined;
  measuredHours: number | undefined;
  requests: number | undefined;
  limits: number | undefined;
};

export const getSummaryItems = ({
  requests,
  limits,
  measuredHours,
  throttledHours,
}: GetSummaryItemsProps) => {
  if (requests === undefined || limits === undefined) return [];

  const shortThrottledHours = shortVariation(throttledHours ?? 0);
  const shortMeasuredHours = shortVariation(measuredHours ?? 0);

  return [
    {
      label: "Requests",
      value: getValue(requests, cpuUnit),
    },
    {
      label: "Limits",
      value: getValue(limits, cpuUnit),
    },
    ...(throttledHours && measuredHours
      ? [
          {
            label: "Time throttled",
            value: `${shortThrottledHours}/${shortMeasuredHours} hours (${shortVariation(
              (shortThrottledHours / shortMeasuredHours) * 100
            )}%)`,
          },
        ]
      : []),
  ];
};

const getLatestValidMetric = (datapoints: Datapoint[]) => {
  for (let i = datapoints.length - 1; i >= 0; i--) {
    const metric = datapoints[i];
    if (metric && metric.value > 0) {
      return metric.value;
    }
  }

  return 0;
};

export const getLatestRequestsAndLimits = (
  dataPoints: WorkloadMetricsDataPoints | undefined
) => {
  if (!dataPoints) return { latestRequests: 0, latestLimits: 0 };
  const latestRequests = getLatestValidMetric(dataPoints.request);
  const latestLimits = getLatestValidMetric(dataPoints.limit);
  return { latestRequests, latestLimits };
};

type GetSummaryItemsFromMetrics = {
  cpuRes: CommonApiResponse<WorkloadMetricsContainers>;
  containerData: ThrottledCPUSupportingDataContainersInner;
};

export const getSummaryItemsFromMetrics = ({
  cpuRes,
  containerData,
}: GetSummaryItemsFromMetrics) => {
  const {
    throttled_hours,
    measured_hours,
    container: containerName,
  } = containerData;
  const correspondingWorkloadCpuMetrics = cpuRes?.data?.containers?.find(
    (c) => c.containerName === containerName
  );

  const { latestRequests, latestLimits } = getLatestRequestsAndLimits(
    correspondingWorkloadCpuMetrics?.dataPoints
  );

  if (!latestLimits || !latestRequests) return [];

  return getSummaryItems({
    limits: latestLimits,
    requests: latestRequests,
    measuredHours: measured_hours,
    throttledHours: throttled_hours,
  });
};
