import React from "react";
import Drawer from "@mui/material/Drawer";

import { DrawerContent } from "./hooks/useGetDrawer";

const TRANSITION_DURATION = 250;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ViolationsDrawer: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      transitionDuration={TRANSITION_DURATION}
      PaperProps={{
        sx: { width: "60%", minWidth: "864px" },
      }}
      disableEnforceFocus
    >
      <DrawerContent />
    </Drawer>
  );
};
