import React from "react";
import { OptionType } from "@komodorio/design-system/deprecated";
import { uniqBy } from "lodash";
import { SingleSelect } from "@komodorio/design-system/komodor-ui";

import { defaultOptions } from "../../../shared/hooks/useDateFormatter";
import { useTimezone } from "../../../shared/context/TimezoneProvider";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

const getTimezoneLabel = (tz: string) => {
  const dateParts = new Map(
    new Intl.DateTimeFormat("en", {
      ...defaultOptions,
      timeZone: tz === "default" ? undefined : tz,
    })
      .formatToParts(new Date())
      .map(({ type, value }) => [type, value])
  );
  return `${dateParts
    .get("timeZoneName")
    ?.replace("GMT", "UTC")}: ${dateParts.get("hour")}:${dateParts.get(
    "minute"
  )} ${dateParts.get("dayPeriod")} (${dateParts.get("month")} ${dateParts.get(
    "day"
  )})`;
};

const options: OptionType<string>[] = uniqBy(
  [
    "default",
    "UTC",
    "Etc/GMT+12",
    "Etc/GMT+11",
    "Etc/GMT+10",
    "Etc/GMT+9",
    "Etc/GMT+8",
    "Etc/GMT+7",
    "Etc/GMT+6",
    "Etc/GMT+5",
    "Etc/GMT+4",
    "Etc/GMT+3",
    "Etc/GMT+2",
    "Etc/GMT+1",
    "Etc/GMT-1",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
  ].map((tz) => ({ label: getTimezoneLabel(tz), value: tz })),
  "label"
);

interface TimezoneSelectProps {
  width?: string;
  selectPopoverProps?: {
    height: string;
    width: string;
  };
}

const DEFAULT_WIDTH = "232px";
const DEFAULT_POPOVER_HEIGHT = "200px";
const DEFAULT_POPOVER_WIDTH = "232px";

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  width,
  selectPopoverProps,
}) => {
  const { timezone, setTimezone } = useTimezone();

  return (
    <SingleSelect
      size="medium"
      width={width || DEFAULT_WIDTH}
      label="Display timezone"
      selectPopoverProps={{
        height: selectPopoverProps?.height || DEFAULT_POPOVER_HEIGHT,
        width: selectPopoverProps?.width || DEFAULT_POPOVER_WIDTH,
      }}
      options={options}
      value={options.find((o) => o.value === timezone)}
      onChange={(selected) => {
        if (selected) {
          dispatchEvent(AnalyticEvents.TimezoneSelector.Select, {
            from: timezone,
            to: selected,
          });
          setTimezone(selected.value);
        }
      }}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TimezoneSelect;
