import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";
import {
  MonitorApiApiV1TypeIssuesGetRequest,
  MonitorsResponse,
  apiV1TypeIssuesGetUrl,
} from "../../../../../generated/monitorsApi";

const MONITOR_ISSUE_TYPE_PATH = "/:type/issues";

const fetchMonitorIssuesByType = async (
  apiClient: AxiosInstance,
  params: MonitorApiApiV1TypeIssuesGetRequest
): Promise<MonitorsResponse> => {
  const { data } = await apiClient.get(
    apiV1TypeIssuesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetMonitorIssuesByType = (
  params: MonitorApiApiV1TypeIssuesGetRequest,
  enabled?: boolean
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [MONITOR_ISSUE_TYPE_PATH, params],
    () => fetchMonitorIssuesByType(apiClient, params),
    {
      enabled,
    }
  );
};
