import React, { createContext, useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";

import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import { selectAddEditIgnoreRuleDialogState } from "../../../../../../store/reliabilityStoreSelectors";
import { AddEditRuleContextState, ClustersMap } from "../addEditRuleTypes";

const initialState: AddEditRuleContextState = {
  selectedClusterNames: [],
  selectedNamespaces: [],
  selectedServices: [],
  clustersMap: undefined,
  setSelectedClusterNames: () => undefined,
  setSelectedNamespaces: () => undefined,
  setSelectedServices: () => undefined,
  setClustersMap: () => undefined,
};

export const AddEditRuleContext =
  createContext<AddEditRuleContextState>(initialState);

const {
  selectedClusterNames: initialSelectedClusterNames,
  selectedNamespaces: initialSelectedNamespaces,
  selectedServices: initialSelectedServices,
  clustersMap: initialClustersMap,
} = initialState;

export const AddEditRuleContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isOpen } = useReliabilityStore(selectAddEditIgnoreRuleDialogState);

  const [clustersMap, setClustersMap] = useState<ClustersMap | undefined>(
    initialClustersMap
  );

  const [selectedClusterNames, setSelectedClusterNames] = useState<string[]>(
    initialSelectedClusterNames
  );
  const [selectedNamespaces, setSelectedNamespaces] = useState<string[]>(
    initialSelectedNamespaces
  );
  const [selectedServices, setSelectedServices] = useState<string[]>(
    initialSelectedServices
  );

  const onSetSelectedClusterNames = useCallback(
    (clusterNames: string[]) => {
      !isEqual(clusterNames, selectedClusterNames) &&
        setSelectedClusterNames(clusterNames);
    },
    [selectedClusterNames]
  );

  const onSetSelectedNamespaces = useCallback(
    (namespaces: string[]) => {
      !isEqual(namespaces, selectedNamespaces) &&
        setSelectedNamespaces(namespaces);
    },
    [selectedNamespaces]
  );

  const onSetSelectedServices = useCallback(
    (services: string[]) => {
      !isEqual(services, selectedServices) && setSelectedServices(services);
    },
    [selectedServices]
  );

  const onSetClustersMap = useCallback(
    (newClustersMap: ClustersMap | undefined) => {
      setClustersMap((prev) => {
        if (!prev && newClustersMap) {
          return newClustersMap;
        }
        return prev;
      });
    },
    []
  );

  const resetState = useCallback(() => {
    setSelectedClusterNames(initialSelectedClusterNames);
    setSelectedNamespaces(initialSelectedNamespaces);
    setSelectedServices(initialSelectedServices);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen, resetState]);

  return (
    <AddEditRuleContext.Provider
      value={{
        selectedClusterNames,
        selectedNamespaces,
        selectedServices,
        clustersMap,
        setSelectedClusterNames: onSetSelectedClusterNames,
        setSelectedNamespaces: onSetSelectedNamespaces,
        setSelectedServices: onSetSelectedServices,
        setClustersMap: onSetClustersMap,
      }}
    >
      {children}
    </AddEditRuleContext.Provider>
  );
};
