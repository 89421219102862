import React from "react";
import styled from "styled-components";
import { SearchField } from "@komodorio/design-system/komodor-ui";

import { checkboxOptionsAriaLabels } from "../ariaLabels";

const Container = styled.div`
  && {
    margin-bottom: 8px;
    .searchFieldContainer {
      background-color: transparent;
    }
  }
`;

const searchFieldAriaLabel = checkboxOptionsAriaLabels.searchField;

type SearchFieldContainerProps = {
  onSearch: (searchValue: string) => void;
  searchTerm: string;
};

export const SearchFieldContainer: React.FC<SearchFieldContainerProps> = ({
  onSearch,
  searchTerm,
}) => {
  const onSearchChange = (searchValue: string) => {
    onSearch(searchValue);
  };

  return (
    <Container>
      <SearchField
        value={searchTerm}
        onSearch={onSearchChange}
        placeholder={"Search"}
        classNames={{ container: "searchFieldContainer" }}
        showCloseIcon={true}
        ariaLabel={searchFieldAriaLabel}
      />
    </Container>
  );
};
