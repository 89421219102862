import { HealthType } from "../hooks/useHealthType";

const workloadsHealthPrefix = "|workloads_health";
const infrastructureHealthPrefix = "|infrastructure_health";

const reliabilityPrefix = "reliability";
const overview = "overview";
const violationsDrawer = "violation_drawer";

export const violationsGroupByImpactGroup = `${reliabilityPrefix}|${overview}|group_by_impact_group`;
export const violationsGroupByCheckType = `${reliabilityPrefix}|${overview}|group_by_check_type`;
export const violationsGroupByCluster = `${reliabilityPrefix}|${overview}|group_by_cluster`;
export const violationsGroupByNone = `${reliabilityPrefix}|${overview}|group_by_none`;
export const realTimeIssuesGroup = `${reliabilityPrefix}|real_time_issues_group`;
export const historyTab = `${reliabilityPrefix}|${violationsDrawer}|history`;

export const expandedImpactGroup = "impact_group_violation_list";

export const getViolationDrawerViewName = (checkType: string) =>
  `${reliabilityPrefix}|${violationsDrawer}|${checkType}`;

const healthTypeToViewName = {
  [HealthType.WORKLOAD]: workloadsHealthPrefix,
  [HealthType.INFRA]: infrastructureHealthPrefix,
  [HealthType.NOT_SPLIT]: "",
};

export const getExpandedImpactGroupViewName = (healthType: HealthType) => {
  const healthPrefix = healthTypeToViewName[healthType];

  return `${reliabilityPrefix}${healthPrefix}|${expandedImpactGroup}`;
};
