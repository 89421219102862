import React from "react";
import styled from "styled-components";
import { muiColors, palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { AriaLabels } from "../config/ariaLabels";
import CopyCode from "../../components/integrations/installation/kubernetes/CopyCode";
import { UpgradeCommands } from "../../components/common/upgradeAgentCta/UpgradeAgentCTA";
import { useGetHelmInstallationCommands } from "../hooks/useGetHelmInstallationCommand";
import { UPGRADE_DOCS_LINK } from "../constants/docsLinks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { unsupportedClustersContainerAriaLabel } from "./UpgradeButtonAndModalForUnsupportedClusters";

const NotUsingHelmContainer = styled.div`
  color: ${palette.gray[600]};
`;

const StyledUl = styled.ul`
  margin: 0;
  padding-inline-start: 12px;
`;

const StyledUpdateAgentMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  padding: 16px;
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  &&:before {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #000000;
    margin-right: 8px;
    border-radius: 50%;
    content: " ";
  }
`;

export type MultipleClustersUpgradeRequiredProps = {
  handleClose: () => void;
  unsupportedClusters: string[];
  upgradeCommand: UpgradeCommands;
  desiredFeature: string;
};

const UpdateCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MultipleClustersUpgradeRequired: React.FC<
  MultipleClustersUpgradeRequiredProps
> = ({ handleClose, unsupportedClusters, upgradeCommand, desiredFeature }) => {
  const clustersKeyword = `cluster${unsupportedClusters.length > 1 ? "s" : ""}`;
  const commands =
    useGetHelmInstallationCommands(upgradeCommand, unsupportedClusters) ?? [];

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Typography variant="h3" aria-label={AriaLabels.updateAgent.command}>
          Update agent
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <UpdateCards>
          <Typography variant="body2">
            {unsupportedClusters.length} {clustersKeyword} require an agent
            update to support <strong>{desiredFeature}</strong>
          </Typography>
          {commands.map((command) => (
            <UpdateAgentDetails
              key={command.agentVersion}
              desiredFeature={desiredFeature}
              agentVersion={command.agentVersion}
              upgradeCommand={command.helmCommand}
              unsupportedClusters={command.clusterNames}
            />
          ))}
          <NotUsingHelmContainer>
            <Typography variant="body2">
              Not using helm?
              <br />
              Read about other ways to install our agent in our{" "}
              <a rel="noreferrer" target="_blank" href={UPGRADE_DOCS_LINK}>
                Installation Guide
              </a>
            </Typography>
          </NotUsingHelmContainer>
        </UpdateCards>
      </DialogContent>
    </Dialog>
  );
};

const UpdateAgentDetails: React.FC<{
  agentVersion: string | undefined;
  desiredFeature: string;
  upgradeCommand: string;
  unsupportedClusters: string[];
}> = ({
  agentVersion,
  upgradeCommand,
  unsupportedClusters,
  desiredFeature,
}) => {
  const unsupportedClustersList = unsupportedClusters.map((cluster) => (
    <StyledLi key={cluster}>
      <Typography variant="body2">{cluster}</Typography>
    </StyledLi>
  ));
  if (!unsupportedClustersList.length) {
    return null;
  }

  return (
    <StyledUpdateAgentMessage>
      <Typography variant={"h4"}>
        Clusters running Komodor Agent {agentVersion}
      </Typography>
      <StyledUl aria-label={unsupportedClustersContainerAriaLabel}>
        {unsupportedClustersList}
      </StyledUl>
      <div>
        <Typography variant={"body2"} sx={{ marginBottom: "4px" }}>
          Run the following command to update the Komodor Agent to support{" "}
          <strong>{desiredFeature}</strong>
        </Typography>
        <CopyCode value={upgradeCommand} width="34rem" />
      </div>
    </StyledUpdateAgentMessage>
  );
};
