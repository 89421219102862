import { FlattenSimpleInterpolation } from "styled-components";

import {
  DynamicChecksConfigurationsTableColumnsStyle,
  ConfigurationTableWithEnabledColumn,
  StaticChecksConfigurationsTableColumnsStyle,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerStyles";

export const getTableStyle = (
  isDynamic: boolean,
  showEnabled?: boolean
): FlattenSimpleInterpolation => {
  if (showEnabled) {
    return ConfigurationTableWithEnabledColumn;
  }
  if (isDynamic) {
    return DynamicChecksConfigurationsTableColumnsStyle;
  }
  return StaticChecksConfigurationsTableColumnsStyle;
};
