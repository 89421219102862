import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { ResourceServiceColumn, ServiceColumn } from "../../types";

import { ServiceTextLink } from "./ServiceTextLink";

export const getGenericServiceColumns = <
  T extends ServiceColumn
>(): GridColDef<T>[] => [
  {
    field: "serviceName",
    headerName: "Service name",
    flex: 2,
    renderCell: (params) => {
      return <ServiceTextLink {...params.row} />;
    },
  },
  { field: "cluster", headerName: "Cluster", flex: 1 },
  { field: "namespace", headerName: "Namespace", flex: 1 },
];

export const getServiceRows = (
  columns: ResourceServiceColumn[]
): ServiceColumn[] => {
  return columns.map((column) => ({
    id: column.komodorServiceId,
    serviceName: column.komodorServiceName,
    cluster: column.cluster,
    namespace: column.namespace,
  }));
};
