import React from "react";
import styled from "styled-components";

import { useCurrentAppViewContext } from "../../../../../context/currentAppViewContext";

import { HistogramItemContainer } from "./HistogramItemContainer";
import { useAvailabilityIssuesAsHistogramData } from "./histogram-container-hooks";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  width: 100%;
`;

export const HistogramsContainer: React.FC = () => {
  const { timeWindow } = useCurrentAppViewContext();
  const { eventGroups, fetching } = useAvailabilityIssuesAsHistogramData();

  return (
    <StyledContainer>
      <HistogramItemContainer
        data={eventGroups}
        fetching={fetching}
        histogramProps={{ totalBars: 40 }}
        timeWindow={timeWindow}
        title={"Availability issues"}
      />
    </StyledContainer>
  );
};
