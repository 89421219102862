import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

import {
  DialogContainer,
  DialogFooter,
  StyledLoadingButton,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const DisableFormDialog: React.FC<{
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onDisable: () => void;
}> = ({ open, onClose, onDisable, isLoading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContainer
        aria-label={
          AriaLabels.SettingsPage.AccountProfile.BetaFeatures
            .RbacClusterSyncDisableForm
        }
      >
        <Typography variant={"h3"}>Disable RBAC Cluster Sync?</Typography>
        <Typography variant={"body2"}>
          Disabling RBAC Cluster Sync will disable it for all users in this
          account.
          <br />
          Any existing roles and cluster roles already synchronized in your
          cluster will still remain available if a user already downloaded his
          service account.{" "}
          {/*TODO: WE SHOULD MAKE SURE TO CLEANUP WHEN THIS FEATURE IS DISABLED. THIS TEXT IS BAD!*/}
        </Typography>
        <DialogFooter>
          <Button
            variant={"text"}
            size={"large"}
            sx={{
              width: "5rem",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <StyledLoadingButton loading={isLoading} onClick={onDisable}>
            Disable
          </StyledLoadingButton>
        </DialogFooter>
      </DialogContainer>
    </Dialog>
  );
};
