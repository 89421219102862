import React from "react";

import { DescribeTextItemWithResourceDrawer } from "../OwnerResourcesLinks/DescribeTextItemWithResourceDrawer";
import { DescribeTextItem } from "../common/DescribeItem";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PVCDescribeTabProps } from "./PVCDescribeTab";
import {
  usePVRelatedResource,
  useSCRelatedResource,
} from "./usePVCRelatedResources";

const volumeNameLabel = "Volume Name";
const storageClassLabel = "Storage Class";

export const PVCRelatedResources: React.FC<PVCDescribeTabProps> = ({
  resource,
}) => {
  const pvResource = usePVRelatedResource({ resource });
  const scResource = useSCRelatedResource({ resource });

  const pvDescribeContent = pvResource ? (
    <DescribeTextItemWithResourceDrawer
      resource={pvResource}
      label={volumeNameLabel}
    />
  ) : (
    <DescribeTextItem name={volumeNameLabel} value={resource.volumeName} />
  );

  const scDescribeContent = scResource ? (
    <DescribeTextItemWithResourceDrawer
      resource={scResource}
      label={storageClassLabel}
    />
  ) : (
    <DescribeTextItem name={storageClassLabel} value={resource.storageClass} />
  );

  return (
    <>
      {pvDescribeContent}
      {scDescribeContent}
    </>
  );
};
