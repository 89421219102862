import {
  AttachVolumeFailedGeneral,
  AttachVolumeFailedNodeData,
  MountVolumeSetUpFailedEnricher,
  MultiAttachVolumeErrorEnricher,
  MultiAttachVolumeExclusiveErrorEnricher,
  PodDidntTriggerScaleUp,
} from "./enrichers/volumeEnrichers";
import {
  ConfigMapCouldNotFindKeyEnricher,
  ConfigMapNotFoundEnricher,
  MountVolumeSetupFailedToSyncCacheEnricher,
  MountVolumeSetUpForConfigMapFailedEnricher,
  MountVolumeSetUpForConfigMapNonExistentKeyEnricher,
} from "./enrichers/configMapEnrichers";
import { Store } from "./store";
import {
  MountVolumeSetUpForSecretFailedEnricher,
  SecretCouldNotFindKeyEnricher,
  SecretNotFoundEnricher,
} from "./enrichers/secretEnrichers";
import {
  LivenessProbeFailedEnricher,
  ReadinessProbeFailedEnricher,
  StartupProbeFailedEnricher,
} from "./enrichers/probesEnrichers";
import {
  NodeDiskPressure,
  NodeLowOnResource,
  NodeMemoryPressure,
  NodeNotReadyEnricher,
  PodRejectedNodeAffinity,
  PodRejectedNodeDidntHaveResources,
  PodRejectedNodeDiskPressure,
  PodRejectedNodeMemoryPressure,
  PodRejectedNodeShuttingDown,
  PodTerminatedNodeShuttingDown,
} from "./enrichers/nodeEnrichers";
import { ImagePullBackoff } from "./enrichers/imageEnrichers";
import { InternalErrorFailedWebhook } from "./enrichers/internalErrorEnrichers";
import { ErrorLookingServiceAccount } from "./enrichers/permissionsEnrichers";
import { CloudReadinessGate } from "./enrichers/readinessGateEnrichers";

const initialEnrichers = [
  new MountVolumeSetUpFailedEnricher(),
  new MultiAttachVolumeErrorEnricher(),
  new ConfigMapNotFoundEnricher(),
  new MountVolumeSetUpForConfigMapFailedEnricher(),
  new ConfigMapCouldNotFindKeyEnricher(),
  new MountVolumeSetUpForConfigMapNonExistentKeyEnricher(),
  new SecretNotFoundEnricher(),
  new SecretCouldNotFindKeyEnricher(),
  new MountVolumeSetUpForSecretFailedEnricher(),
  new ReadinessProbeFailedEnricher(),
  new LivenessProbeFailedEnricher(),
  new StartupProbeFailedEnricher(),
  new NodeNotReadyEnricher(),
  new NodeLowOnResource(),
  new NodeMemoryPressure(),
  new NodeDiskPressure(),
  new PodRejectedNodeDiskPressure(),
  new PodRejectedNodeMemoryPressure(),
  new PodRejectedNodeShuttingDown(),
  new PodRejectedNodeAffinity(),
  new PodTerminatedNodeShuttingDown(),
  new PodRejectedNodeDidntHaveResources(),
  new ImagePullBackoff(),
  new MultiAttachVolumeExclusiveErrorEnricher(),
  new InternalErrorFailedWebhook(),
  new PodDidntTriggerScaleUp(),
  new ErrorLookingServiceAccount(),
  new CloudReadinessGate(),
  new MountVolumeSetupFailedToSyncCacheEnricher(),
  new AttachVolumeFailedNodeData(),
  new AttachVolumeFailedGeneral(),
];

export const messageEnrichers = Store.getInstance(initialEnrichers);
