import React, { useState, useEffect, useCallback } from "react";
import { theme } from "@komodorio/design-system";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import { ArrowRight16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { SignupLoader, SpinnerLoading } from "../loaders/SignupLoader";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { runDesktopAgent } from "../../../shared/context/electron/installElectronAgent";
import { useIsLoadingAgentStatuses } from "../../../shared/context/electron/hooks/useIsLoadingAgentStatuses";
import { useIsDesktopAppReady } from "../../../shared/context/electron/hooks/useIsDesktopAppReady";
import { getDesktopUserClusters } from "../../../shared/context/electron/getDesktopUserClusters";
import { useCreateInstallation } from "../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";

import ClustersSelectors from "./clustersSelectors";
import { useElectronClusters } from "./useDesktopAppClusters";

const Container = styled.div`
  display: grid;
  height: 100vh;
  z-index: 10000;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.background.bgGrayLight};
  grid-template-rows: 1fr 6rem;
`;

const ContinueContainer = styled.div`
  background: ${theme.background.bgWhite};
  border: 1px solid ${theme.border.borderGray};
  box-shadow: 0px 2px 8px 1px rgba(22, 24, 31, 0.15);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const StyledButton = styled(Button)`
  flex-direction: row-reverse;
  padding: 8px 12px;
`;

const ContinueButton: React.FC<{
  handleClick: () => void;
  disabled: boolean;
  loading: boolean;
}> = ({ handleClick, disabled, loading }) => {
  return (
    <ContinueContainer>
      <StyledButton
        variant="primary"
        onClick={handleClick}
        disabled={disabled || loading}
        icon={ArrowRight16}
      >
        {loading ? "Loading" : "Continue"}
        {loading ? (
          <SpinnerLoading color="white" width="20px" height="20px">
            <div />
            <div />
          </SpinnerLoading>
        ) : null}
      </StyledButton>
      <Typography size="small" color={theme.foreground.fgSubtle}>
        *Komodor is a SaaS platform, and your data will be sent and stored in
        the cloud
      </Typography>
    </ContinueContainer>
  );
};

const ClustersSelection: React.FC = () => {
  const desktopAppReady = useIsDesktopAppReady();
  const agentClusters = useElectronClusters();
  const [selectedContexts, setSelectedContexts] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync: createInstallation } = useCreateInstallation();
  const isLoadingDesktopAgents = useIsLoadingAgentStatuses();

  useEffect(() => {
    if (agentClusters)
      dispatchEvent(AnalyticEvents.Desktop.Identified_Clusters);
  }, [agentClusters]);

  useEffect(() => {
    setIsDisabled(selectedContexts.length === 0);
  }, [selectedContexts]);

  useEffect(() => {
    setIsLoading(isLoadingDesktopAgents);
  }, [isLoadingDesktopAgents]);

  const handleClicked = useCallback(async () => {
    if (!agentClusters) return;
    setIsLoading(true);
    setIsDisabled(true);
    dispatchEvent(AnalyticEvents.Desktop.Choose_Clusters);
    await Promise.all(
      selectedContexts.map((clusterContext, index) =>
        runDesktopAgent(
          createInstallation,
          clusterContext,
          agentClusters?.[clusterContext],
          index
        )
      )
    );
  }, [agentClusters, createInstallation, selectedContexts]);

  const [showLoader, setShowLoader] = useState<boolean>(true);
  useEffect(() => {
    if (!desktopAppReady || !agentClusters) return;
    getDesktopUserClusters().then((userClusters) => {
      if (!userClusters) return;
      setShowLoader(false);
    });
  }, [desktopAppReady, agentClusters]);

  if (showLoader) return <SignupLoader />;

  return (
    <Container>
      <ClustersSelectors
        clusters={agentClusters}
        isLoading={isLoading}
        setSelectedContexts={setSelectedContexts}
      />
      <ContinueButton
        loading={isLoading}
        handleClick={handleClicked}
        disabled={isDisabled}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ClustersSelection;
