import { QueryKey } from "@tanstack/react-query";

import { selectedAppViewIdSelector } from "../../store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";

export function useWorkspaceQueryKey(
  keys: QueryKey,
  { skip = false }: { skip?: boolean } | undefined = { skip: false }
) {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  if (skip) {
    return keys;
  }
  return [...keys, selectedAppViewId] as const;
}
