import React, { useEffect, useMemo } from "react";

import { SNAPSHOT_KEY } from "../../../../shared/config/urlSearchParamsKeys";

import SnapshotTabs, { SnapshotTab } from "./SnapshotTabs";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export function useSnapshotTabs<T extends { startTime: string }>(
  firstSnapshot: T | undefined,
  lastSnapshot: T | undefined | null,
  preferFirstSnapshot?: boolean
): [T | undefined, React.ReactNode] {
  const [selectedSnapshot, setSelectedSnapshot] =
    useStateInSearchParams(SNAPSHOT_KEY);
  useEffect(() => {
    if (preferFirstSnapshot && !selectedSnapshot) {
      setSelectedSnapshot(SnapshotTab.firstSnapshot, true);
    } else if (lastSnapshot && !selectedSnapshot) {
      setSelectedSnapshot(SnapshotTab.latestSnapshot, true);
    }
  }, [
    lastSnapshot,
    setSelectedSnapshot,
    selectedSnapshot,
    preferFirstSnapshot,
  ]);
  const selectedSnapshotResult = useMemo(() => {
    if (selectedSnapshot === SnapshotTab.latestSnapshot) {
      return lastSnapshot ?? undefined;
    } else {
      return firstSnapshot;
    }
  }, [firstSnapshot, lastSnapshot, selectedSnapshot]);

  return [
    selectedSnapshotResult,
    selectedSnapshot && lastSnapshot ? (
      <SnapshotTabs
        setSelectedSnapshot={setSelectedSnapshot}
        selectedSnapshot={selectedSnapshot}
        firstTimestamp={firstSnapshot?.startTime}
        lastTimestamp={lastSnapshot?.startTime}
      />
    ) : null,
  ];
}
