import { Dictionary } from "../../../../../shared/types/Dictionary";
import { ServiceIdentifierSyntaxError } from "../../../types/ServiceIdentifierSyntaxError";

export const tryParseLabel = (string: string): Dictionary<string> => {
  try {
    const label = JSON.parse(string);

    return label;
  } catch (error) {
    if (error instanceof SyntaxError) {
      throw new ServiceIdentifierSyntaxError(
        "Invalid syntax in service identifier of type label: " + string
      );
    }
  }

  return {};
};
