import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  PodNativeEventsResponse,
  apiV1WorkloadsPodsNativeEventsSearchGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const WORKLOADS_PODS_NATIVE_EVENT_SEARCH_PATH =
  "/workloads/pods/native-events/search";

const fetchPodNativeEvents = async (
  apiClient: AxiosInstance,
  params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest
): Promise<PodNativeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsPodsNativeEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetPodNativeEvents = (
  params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_PODS_NATIVE_EVENT_SEARCH_PATH, params],
    () => fetchPodNativeEvents(apiClient, params),
    {
      enabled,
    }
  );
};

export const useGetPodNativeEventsPaginated = (
  params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useInfiniteQuery(
    [WORKLOADS_PODS_NATIVE_EVENT_SEARCH_PATH, params],
    ({ pageParam }) =>
      fetchPodNativeEvents(apiClient, { ...params, afterId: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (lastPage.meta?.hasNext) {
          return lastPage.meta?.afterId;
        }
        return undefined;
      },
    }
  );
};
