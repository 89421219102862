import { HelmChartSummary } from "komodor-types";
import { useMemo } from "react";

import { HelmChartByNameParams } from "./types";

export const useHelmChartByName = ({
  chartName,
  cluster,
  namespace,
}: HelmChartByNameParams): HelmChartSummary => {
  const helmChartSummary = useMemo(() => {
    return {
      secretId: "",
      cluster: cluster,
      name: chartName,
      namespace: namespace ?? "",
      revision: "",
      version: "",
      status: "",
      lastUpdated: "",
      description: "",
    };
  }, [chartName, cluster, namespace]);

  return helmChartSummary;
};
