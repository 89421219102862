import { IntegrationType } from "komodor-types";
import { useCallback } from "react";

import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";
import { Installation } from "../../../../generated/accounts";

type Result = Promise<Installation>;
type CreateSentryInstallation = (id: string, secret: string) => Result;
type CreateSentryInstallationWithToken = (
  id: string,
  secret: string,
  apiToken: string,
  domain: string,
  orgName: string
) => Result;

export const useSentryInstallation = (): CreateSentryInstallation => {
  const { mutateAsync: install } = useCreateInstallation();
  return useCallback(
    (id, secret) => {
      const integration = IntegrationType.SENTRY;
      const configuration = { secret };
      return install({ id, integration, configuration });
    },
    [install]
  );
};

export const useSentryInstallationWithToken =
  (): CreateSentryInstallationWithToken => {
    const { mutateAsync: install } = useCreateInstallation();
    return useCallback(
      (id, secret, apiToken, domain, orgName) => {
        const integration = IntegrationType.SENTRY;
        const configuration: Record<string, string> = {
          secret,
          domain: domain,
        };
        if (apiToken) {
          configuration.token = apiToken;
        }
        if (orgName) {
          configuration.orgName = orgName;
        }
        return install({ id, integration, configuration });
      },
      [install]
    );
  };
