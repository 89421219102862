import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import filterBar from "../assets/filter-bar-3.png";
import slice1 from "../assets/applications-1.png";
import slice2 from "../assets/applications-2.png";
import slice2Hover from "../assets/applications-2-hover.png";
import slice3 from "../assets/applications-3.png";
import { WORKSPACE_ROUTE } from "../../routes/routes";

const FilterBarImage = styled.img`
  object-fit: scale-down;
  width: 90%;
  height: 50%;
  margin: 0 0 12px 60px;
`;
const MainImage = styled.img`
  object-fit: scale-down;
  width: 90%;
  height: 50%;
  margin: 0 60px;
`;

export const ApplicationsTab: React.FC = () => {
  const [hover, setHover] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <FilterBarImage src={filterBar} />
      <MainImage src={slice1} />
      <MainImage
        src={hover ? slice2Hover : slice2}
        style={{
          cursor: "pointer",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          navigate(
            `${WORKSPACE_ROUTE}/6e2a5a00-a5db-441c-a49a-974e29bc01a7?accountId=fe52cf0b-42da-4ae1-a8db-776e0f1335a0`
          );
        }}
      />
      <MainImage src={slice3} />
    </div>
  );
};
