/* tslint:disable */
/* eslint-disable */
/**
 * Komodor Cost API
 * This is an API that serve cost related resources and calculations
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface GetConfigurationResponse
 */
export interface GetConfigurationResponse {
    /**
     * 
     * @type {Array<GetConfigurationResponseConfigurationsInner>}
     * @memberof GetConfigurationResponse
     */
    'configurations': Array<GetConfigurationResponseConfigurationsInner>;
}
        /**
 * 
 * @export
 * @interface GetConfigurationResponseConfigurationsInner
 */
export interface GetConfigurationResponseConfigurationsInner {
    /**
     * 
     * @type {string}
     * @memberof GetConfigurationResponseConfigurationsInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {UpdateConfigurationRequestConfiguration}
     * @memberof GetConfigurationResponseConfigurationsInner
     */
    'configuration'?: UpdateConfigurationRequestConfiguration;
}
        /**
 * 
 * @export
 * @interface UpdateConfigurationRequest
 */
export interface UpdateConfigurationRequest {
    /**
     * 
     * @type {UpdateConfigurationRequestConfiguration}
     * @memberof UpdateConfigurationRequest
     */
    'configuration': UpdateConfigurationRequestConfiguration;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConfigurationRequest
     */
    'clusterNames': Array<string>;
}
        /**
 * 
 * @export
 * @interface UpdateConfigurationRequestConfiguration
 */
export interface UpdateConfigurationRequestConfiguration {
    /**
     * 
     * @type {number}
     * @memberof UpdateConfigurationRequestConfiguration
     */
    'monthlyCpuCost': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateConfigurationRequestConfiguration
     */
    'monthlyMemoryCost': number;
}
    
        /**
* Get the user cost config
* @summary Get the user cost config
* @param params CostApiApiV1ConfigGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigGetUrl = (params: CostApiApiV1ConfigGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/config`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Update the user cost config
* @summary Upsert the user cost config
* @param params CostApiApiV1ConfigPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigPostUrl = (params: CostApiApiV1ConfigPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/config`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ConfigGet operation in CostApi.
* @export
* @interface CostApiApiV1ConfigGetRequest
*/
export interface CostApiApiV1ConfigGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof CostApiApiV1ConfigGet
    */
    readonly clusterNames?: Array<string>

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof CostApiApiV1ConfigGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1ConfigPost operation in CostApi.
* @export
* @interface CostApiApiV1ConfigPostRequest
*/
export interface CostApiApiV1ConfigPostRequest {
    /**
    * 
    * @type {UpdateConfigurationRequest}
    * @memberof CostApiApiV1ConfigPost
    */
    readonly updateConfigurationRequest?: UpdateConfigurationRequest
}


    
