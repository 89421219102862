import Resource from "../../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../../resources/job";
import CronJob from "../../resources/cronjob";

export const isJob = (resource: Resource): resource is Job =>
  resource.kind === "Job";

export const isCronJob = (resource: Resource): resource is CronJob =>
  resource.kind === "CronJob";
