import { HPA } from "@komodorio/design-system/icons";

import { KubernetesResource } from "./KubernetesResource";
import { AvailableActions } from "./AvailableActions";

export const KubernetesHPAResource: KubernetesResource = {
  NameInK8S: "horizontalpodautoscalers",
  Group: "configuration",
  NavBarName: "HPA",
  PresentName: "Horizontal Pod Autoscaling",
  Namespaced: true,
  UnnecessaryColumns: ["Reference", "Targets"],
  OverrideHeaders: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Replicas" },
    { name: "Minpods" },
    { name: "Maxpods" },
    { name: "Age" },
    { name: "Status Conditions" },
  ],
  SupportedActions: [AvailableActions.Edit],
  Kind: "HorizontalPodAutoscaler",
  Icon: HPA,
  hasLabels: true,
};
