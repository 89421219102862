import { theme, muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Divider } from "@komodorio/design-system/deprecated";
import Info from "@mui/icons-material/Info";
import React from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  textColor?: string;
  infoText?: string;
}

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  colunm-gap: 0.25rem;

  &::before,
  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid ${theme.divider};
    margin: auto;
  }

  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
`;

const InfoIcon = styled(Info)`
  && {
    height: 1rem;
    fill: ${muiColors.gray[300]};
  }
`;

export const DescribeSectionDivider: React.FC<Props> = ({
  title,
  textColor = muiColors.gray[600],
  infoText,
}) => {
  if (!title) {
    return <StyledDivider />;
  }

  return (
    <Container>
      <Typography variant="h6" color={textColor}>
        {title}
      </Typography>
      {infoText && (
        <Tooltip title={infoText} arrow={false}>
          <InfoIcon />
        </Tooltip>
      )}
    </Container>
  );
};
