import { Dictionary } from "../../../shared/types/Dictionary";
import {
  ImpactGroupType,
  ViolationCountBy,
  ViolationFilterOperator,
  ViolationStatus,
} from "../../../generated/reliabilityApi";

import { defaultViolationStatuses } from "./reliabilityConstants";
import { ViolationStatusToLabel } from "./violationStatusToLabel";
import { impactGroupTypeToLabel } from "./impactGroupTypeToLabel";

import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export type TimeWindow = "1d" | "7d" | "14d" | "30d" | "all";
type TimeWindowOption = {
  label: string;
  value: TimeWindow;
};
export const timeWindowOptions: TimeWindowOption[] = [
  { label: "Last 24 hours", value: "1d" },
  { label: "Last 7 days", value: "7d" },
  { label: "Last 14 days", value: "14d" },
  { label: "Last 30 days", value: "30d" },
  { label: "All time", value: "all" },
];
export const defaultTimeWindow: TimeWindow = "all";

export const defaultSelectedViolationFilters: Dictionary<FilterOptionInUrl[]> =
  {
    [ViolationCountBy.Status]: [
      {
        values: defaultViolationStatuses,
        operator: ViolationFilterOperator.Include,
      },
    ],
    [ViolationCountBy.CreatedAt]: [
      {
        values: [defaultTimeWindow],
        operator: ViolationFilterOperator.Include,
      },
    ],
  };
export const staticViolationFiltersOptions: Partial<
  Record<ViolationCountBy, string[]>
> = {
  [ViolationCountBy.Status]: [
    ViolationStatus.Open,
    ViolationStatus.Confirmed,
    ViolationStatus.Resolved,
  ],
  [ViolationCountBy.ImpactGroupType]: [
    ImpactGroupType.Dynamic,
    ImpactGroupType.Realtime,
  ],
};

export const filterValueToLabel: Partial<
  Record<ViolationCountBy, Dictionary<string>>
> = {
  [ViolationCountBy.Status]: ViolationStatusToLabel,
  [ViolationCountBy.ImpactGroupType]: impactGroupTypeToLabel,
};
