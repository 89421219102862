import React from "react";
import { PolicyRule } from "kubernetes-types/rbac/v1.d";

import { DescribeTextItem } from "./DescribeItem";
import { DescribePanel, DescribeSection } from "./DescribeSection";

export const RoleRules: React.FC<{ rules?: PolicyRule[] }> = ({ rules }) =>
  rules && rules.length ? (
    <DescribeSection
      title={`Rules (${rules.length})`}
      expandByDefault
      ariaLabel="Role rules"
    >
      {rules.map((rule) => (
        <DescribePanel key={rule.apiGroups?.toString()}>
          <DescribeTextItem
            name="Resources"
            value={rule.resources?.join(", ")}
          />
          <DescribeTextItem name="Verbs" value={rule.verbs.join(", ")} />
          <DescribeTextItem name="API Groups" value={rule.apiGroups} />
        </DescribePanel>
      ))}
    </DescribeSection>
  ) : null;
