import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import common from "@mui/material/colors/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const REALTIME_TITLE = "Failed Workloads";
const RELIABILITY_RISKS_TITLE = "Risks";

const REALTIME_SUBTITLE = "realtime issues";
const RELIABILITY_SUBTITLE = "reliability risks";

const Container = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  border: 1px solid ${muiColors.gray[300]};
  background-color: ${common.white};
`;

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Icon = styled(CheckCircleIcon)`
  && {
    color: ${muiColors.green[500]};
  }
`;

const {
  sections: { emptyState: emptyStateAriaLabels },
} = reliabilityArialLabels;

export const ReliabilityHealthManagementEmptyState: React.FC<{
  isRealtimeGroup: boolean;
}> = ({ isRealtimeGroup }) => {
  const title = isRealtimeGroup ? REALTIME_TITLE : RELIABILITY_RISKS_TITLE;
  const subtitle = isRealtimeGroup ? REALTIME_SUBTITLE : RELIABILITY_SUBTITLE;

  return (
    <Container aria-label={emptyStateAriaLabels}>
      <Typography variant="h4"> {title} </Typography>
      <Divider orientation="vertical" />
      <SubtitleContainer>
        <Icon />
        <Typography variant="body2">
          There are no {subtitle} issues matching your filter
        </Typography>
      </SubtitleContainer>
    </Container>
  );
};
