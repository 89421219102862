import React from "react";

import { OldEmptyState } from "./OldEmptyState";
import { ReliabilityHealthManagementEmptyState } from "./ReliabilityHealthManagementEmptyState";

import { ImpactGroupType } from "@/generated/reliabilityApi";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";

export const NoViolationsEmptyState: React.FC<{
  impactGroupType: ImpactGroupType | undefined;
}> = ({ impactGroupType }) => {
  const { reliabilityHealthManagement } = useOverridableFlags();
  const isBestPracticesTab = useIsBestPracticesTab();

  const isReliabilityHealthManagementView =
    Boolean(reliabilityHealthManagement) && !isBestPracticesTab;

  const isRealtimeGroup = impactGroupType === ImpactGroupType.Realtime;

  return isReliabilityHealthManagementView ? (
    <ReliabilityHealthManagementEmptyState isRealtimeGroup={isRealtimeGroup} />
  ) : (
    <OldEmptyState />
  );
};
