import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  OptionType,
  Typography,
  MultiSelect,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { Form, FormError, Z_INDEX_ABOVE_MODAL } from "../styles";
import { useFormValidations } from "../../../shared/context/ValidationsProvider";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import Checkbox from "../../common/controls/Checkbox";
import { RbacRole } from "../../../generated/auth";
import { useGetRbacPolicies } from "../../../shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { useCreateRbacRole } from "../../../shared/hooks/auth-service/client/rbacRoles/useCreateRbacRole";
import { useUpdateRbacRole } from "../../../shared/hooks/auth-service/client/rbacRoles/useUpdateRbacRole";

type RoleForm = {
  name: string;
  policies: OptionType<string>[];
  isDefault: boolean;
};

const defaultValue = { name: "", policies: [], isDefault: false };

const validateRoleName = (name: string | undefined) =>
  !name ? "Please fill role name" : undefined;
const validateRolePolicies = (policies: OptionType<string>[] | undefined) =>
  !policies?.length ? "Please select policies" : undefined;

const DefaultRoleCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RoleFormModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  role?: RbacRole;
  refreshRoles: () => void;
  readOnly?: boolean;
}> = ({ open, handleClose, role, refreshRoles, readOnly }) => {
  const [value, setValue] = useState<RoleForm>(defaultValue);
  const { errors, setError } = useFormValidations();

  useEffect(() => {
    if (role) {
      setValue({
        name: role.name,
        policies:
          role.rbacPolicies?.map((policy) => ({
            label: policy.name,
            value: policy.id,
          })) ?? [],
        isDefault: role.isDefault,
      });
    }
  }, [role]);

  const { data: allPolicies = [] } = useGetRbacPolicies();
  const policyOptions = useMemo(
    () => allPolicies.map((p) => ({ label: p.name, value: p.id })),
    [allPolicies]
  );
  const { mutateAsync: addRole, isLoading: addLoading } = useCreateRbacRole();
  const { mutateAsync: editRole, isLoading: editLoading } = useUpdateRbacRole();

  const handleSave = async () => {
    const { name, policies, isDefault } = value;
    const [nameError, policiesError] = [
      validateRoleName(name),
      validateRolePolicies(policies),
    ];
    setError("name", nameError);
    setError("policies", policiesError);
    if (nameError || policiesError) {
      return;
    }
    const newPolicyIds = policies.map((r) => r.value);
    let result;
    try {
      if (role) {
        result = await editRole({
          id: role.id,
          updateRbacRoleRequest: {
            name: name.trim(),
            rbacPolicyIds: newPolicyIds,
            isDefault,
          },
        });
      } else {
        result = await addRole({
          name: name.trim(),
          rbacPolicyIds: newPolicyIds,
          isDefault,
        });
      }
    } catch (e) {
      setError(
        "general",
        "Encountered an issue while saving the role. Please retry or contact support for assistance."
      );
      return;
    }
    dispatchEvent(
      role ? AnalyticEvents.Settings.EditRole : AnalyticEvents.Settings.AddRole,
      {
        roleName: result.name,
        policies: result.rbacPolicies?.map((p) => p.name),
      }
    );
    refreshRoles();
    handleClose();
  };

  const loading = editLoading || addLoading;

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>
        <Typography variant="headline">
          {!readOnly && (role ? "Edit" : "Add")} Role{" "}
          {readOnly && "(read only)"}
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Form width="20rem">
          <Input
            size="medium"
            label="Role name"
            value={value.name}
            onChange={(e) => {
              setValue({ ...value, name: e.target.value });
              setError("name", validateRoleName(e.target.value));
            }}
            errorMessage={errors["name"]}
            disabled={readOnly}
          />
          <MultiSelect
            size="medium"
            width="20rem"
            label="Policies"
            listMaxHeight="14rem"
            listZIndex={Z_INDEX_ABOVE_MODAL}
            options={policyOptions}
            value={value.policies}
            onChange={(selected) => {
              setValue({ ...value, policies: selected });
              setError("policies", validateRolePolicies(selected));
            }}
            errorMessage={errors["policies"]}
            disabled={readOnly}
          />
          <DefaultRoleCheckbox>
            <Typography variant="text" size="medium">
              Set as default role for new users
            </Typography>
            <Checkbox
              id="default-role"
              fieldName="Default role"
              checked={value.isDefault}
              handleCheckboxChange={() => {
                setValue({ ...value, isDefault: !value.isDefault });
              }}
              disabled={readOnly}
            />
          </DefaultRoleCheckbox>
          <FormError errMsg={errors["general"]} />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={loading || readOnly}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RoleFormModal;
