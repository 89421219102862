import React, { useMemo } from "react";
import { Legend } from "recharts";

import { MetricsGraphContainer } from "../../../../../../../../../../../../Metrics/graphs/MetricsGraphContainer";
import {
  formatGraphMemoryTick,
  transformDataPoints,
} from "../../../../../../../../../../../../Metrics/utils";
import { AriaLabels } from "../../../../../../../../../../../../../shared/config/ariaLabels";
import {
  EventData,
  MetricsAggregationType,
  metricsTypeToName,
} from "../../../../../../../../../../../../Metrics/types";
import { GraphContainerWithStatus } from "../../../../../../../../../../../../Metrics/GraphContainerWithStatus";
import { NoisyNeighborMetrics } from "../useMetricsData";
import { MetricsWithAggregations } from "../../../../../../../../../../../../Metrics/useNodeMetrics";
import { Tooltip } from "../../../../../../../../../../../../Metrics/Tooltip/Tooltip";
import { GenericLegend } from "../../../../../../../../../../../../Metrics/GenericLegend/GenericLegend";

import {
  AvailableMetrics,
  getLines,
  getRelevantLegendItems,
  getTooltipItems,
  mergeMetrics,
} from "./utils";

const AGGREGATION = MetricsAggregationType.Max;

interface Props {
  data: NoisyNeighborMetrics;
  events: EventData[];
}

export const Graph: React.FC<Props> = ({ data, events }) => {
  const { graphReadyData, availableMetrics } = useMemo(() => {
    const convertedAggregation = metricsTypeToName[
      AGGREGATION
    ] as keyof MetricsWithAggregations;

    const nodeUsage = transformDataPoints(
      data.nodeUsage.data?.[convertedAggregation] ?? [],
      "nodeUsage"
    );
    const nodeCapacity = transformDataPoints(
      data.nodeCapacity.data?.[convertedAggregation] ?? [],
      "nodeCapacity"
    );
    const workloadUsage = transformDataPoints(
      data.workloadUsage.data?.[convertedAggregation] ?? [],
      "workloadUsage"
    );

    const availableMetrics: AvailableMetrics = {
      nodeUsage: nodeUsage.length > 0,
      nodeCapacity: nodeCapacity.length > 0,
      workloadUsage: workloadUsage.length > 0,
    };

    return {
      graphReadyData: mergeMetrics({ nodeUsage, nodeCapacity, workloadUsage }),
      availableMetrics,
    };
  }, [data]);

  const legendItems = useMemo(
    () => getRelevantLegendItems(availableMetrics),
    [availableMetrics]
  );

  return (
    <GraphContainerWithStatus
      uid={undefined}
      hasGraphData={graphReadyData.length > 0}
      error={
        data.nodeUsage.error ||
        data.nodeCapacity.error ||
        data.workloadUsage.error
      }
      loading={
        data.nodeUsage.loading &&
        data.nodeCapacity.loading &&
        data.workloadUsage.loading
      }
      showDivider
      title="Memory usage"
      graphHeight="300px"
    >
      <MetricsGraphContainer
        tooltipContent={(tooltipProps) => {
          return (
            <Tooltip
              tooltipItems={getTooltipItems()}
              tooltipProps={tooltipProps}
              dataTransformFunction={(tick) => ({
                value: formatGraphMemoryTick(tick, "MiB"),
              })}
            />
          );
        }}
        ariaLabels={AriaLabels.MetricsAvailability.MemoryGraph}
        tickFormatter={(tick) => formatGraphMemoryTick(tick, "MiB")}
        label={"MiB"}
        data={graphReadyData}
        events={events}
      >
        {getLines()}
        <Legend content={<GenericLegend items={legendItems} />} />
      </MetricsGraphContainer>
    </GraphContainerWithStatus>
  );
};
