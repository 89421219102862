import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { StyledPaddedCard } from "../../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../../store/costOptimizationStore";
import { selectRightSizingFilters } from "../../../../store/costOptimizationStoreSelectors";

import { SelectStrategyLabel } from "./SelectStrategyLabel";
import { PageSelectStrategy } from "./PageSelectStrategy";

export const StyledStrategyCard = styled(StyledPaddedCard)`
  gap: 12px;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  padding: 16px;
`;

export const SelectStrategy: React.FC = () => {
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);

  return (
    <StyledStrategyCard>
      <Typography variant={"h5"}>Optimization Strategy</Typography>
      <PageSelectStrategy />
      <SelectStrategyLabel strategy={strategy} />
    </StyledStrategyCard>
  );
};
