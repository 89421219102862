import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { useOpenDrawer } from "../../../../hooks/drawerHooks";

const StyledButton = styled(Button)`
  width: 150px;
`;

interface Props {
  id: string | undefined;
}

export const ViewApiConstraintsButton: React.FC<Props> = ({ id }) => {
  const openDrawer = useOpenDrawer();

  if (!id) return null;

  return (
    <StyledButton variant="contained" onClick={() => openDrawer(id)}>
      View API constraints
    </StyledButton>
  );
};
