import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import workloadHealthPage from "../assets/Screen - Workload Health.png";
import serviceHighResiliencyDrawer from "../assets/Drawer - Workload Health - Degraded Service - Reseliency.png";
import cascadingFailureDrawer from "../assets/Drawer - Workload Health - Cascading - Reseliency.png";
import { clustersDemoRoutes } from "../constants";
import { ClickableArea, CloseButtonArea, RelativeContainer } from "../styles";

import { TopBar } from "./TopBar";
import { MockDrawer } from "./MockDrawer";

import { DEMO } from "@/components/routes/routes";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  top: ${({ top }) => top};
  width: 72.9%;
  height: 3.5%;
  left: 23.9%;
`;

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

export const WorkloadHealthPage: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);

  return (
    <RelativeContainer>
      <TopBar page="workloadHealth" />
      <img src={workloadHealthPage} alt={"workload health page"} />
      <SelectableRow
        top={"17.7%"}
        onClick={() => navigate(clustersDemoRoutes.eventsPullerService)}
      />
      <SelectableRow
        top={"21.1%"}
        onClick={() =>
          navigate(
            `/${DEMO}/v3/workflows/${clustersDemoRoutes.wfLaneExtractorRegression}`
          )
        }
      />
      <SelectableRow
        top={"24.5%"}
        onClick={() =>
          navigate(
            `/${DEMO}/v3/workflows/${clustersDemoRoutes.wfIcpMapGenerator}`
          )
        }
      />
      <SelectableRow
        top={"31.3%"}
        onClick={() => navigate(clustersDemoRoutes.correlationEngineService)}
      />
      <SelectableRow
        top={"56%"}
        onClick={() => setDrawerImgSrc(cascadingFailureDrawer)}
      />
      <SelectableRow
        top={"81%"}
        onClick={() => setDrawerImgSrc(serviceHighResiliencyDrawer)}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            top="18px"
            left="15px"
            onClick={() => setDrawerImgSrc(null)}
          />
        }
      />
    </RelativeContainer>
  );
};
