import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { useFetchPodLogsQuery } from "../../../generated/graphql";
import { AnalyticEvents } from "../../config/analyticsEvents";
import { notifyDDError } from "../exceptionManagement";
import { useQueryRefreshWithVariables } from "../useQueryWithVariables";
import useAgentTaskExecution from "../useAgentTaskExecution/useAgentTaskExecution";
import { dispatchEvent } from "../analytics";
import { useOverridableFlags } from "../../context/featureFlags/OverridableFlags";
import { useIsFetchingPodLogs } from "../../../components/ResourceView/tabs/LogsTab/useIsFetchingPodLogs";

import { MAX_RETRIES, POD_LOGS_TIMEOUT, logsCommandOptions } from "./constants";
import {
  PodLogLine,
  PodLogsInfoRequestProps,
  PodLogsInfoResponseProps,
  PodLogsMode,
} from "./types";

export const useGetPodLogs = ({
  podName,
  agentId,
  namespace,
  clusterName,
  controlledBy,
  selectedContainer,
  isPreviousLogs = false,
  shouldExecuteFetchLogs = true,
}: PodLogsInfoRequestProps): PodLogsInfoResponseProps => {
  const [triggerManually, setTriggerManually] = useState(false);
  const [retries, setRetries] = useState(0);
  const [startMeasureLatency, setStartMeasureLatency] = useState<number>();
  const [logs, setLogs] = useState<PodLogLine[]>([]);
  const [oldPodName, setOldPodName] = useState<string>();
  const [prevSelectedContainerLabel, setPrevSelectedContainerLabel] = useState<
    string | undefined
  >(selectedContainer?.label);
  const [previouslySelectedPreviousLogs, setPreviouslySelectedPreviousLogs] =
    useState(isPreviousLogs);
  const { podLogsFetchLogsOnlyIfExists, podsLogsTail } = useOverridableFlags();
  const preventFetching =
    podLogsFetchLogsOnlyIfExists && !shouldExecuteFetchLogs;
  const customErrorMessage = preventFetching ? "ContainerCreating" : "";

  useEffect(() => {
    if (
      oldPodName !== podName ||
      prevSelectedContainerLabel !== selectedContainer?.label ||
      previouslySelectedPreviousLogs !== isPreviousLogs
    ) {
      setOldPodName(podName);
      setPrevSelectedContainerLabel(selectedContainer?.label);
      setPreviouslySelectedPreviousLogs(isPreviousLogs);
      setLogs([]);
    }
  }, [
    podName,
    oldPodName,
    selectedContainer?.label,
    prevSelectedContainerLabel,
    previouslySelectedPreviousLogs,
    isPreviousLogs,
  ]);

  const [moreLogs, setMoreLogs] = useState(0);
  const requestMoreLogs = useCallback(() => {
    setMoreLogs(moreLogs + 100);
  }, [moreLogs]);

  const tail = useMemo(
    () => _.toNumber(podsLogsTail) + moreLogs,
    [moreLogs, podsLogsTail]
  );

  const executeTaskVars = useMemo(() => {
    const preventFetchingForMissingContainerName =
      preventFetching && !selectedContainer?.value;
    if (
      !agentId ||
      !podName ||
      !shouldExecuteFetchLogs ||
      preventFetchingForMissingContainerName
    ) {
      return null;
    }

    setTriggerManually(true);
    setRetries(0);
    return {
      agentId: agentId,
      type: "pod-logs",
      data: {
        ...Object.assign(logsCommandOptions, { tail }),
        podName: podName,
        namespace: namespace,
        containerName: selectedContainer?.value ?? "",
        previous: isPreviousLogs ?? false,
      },
    };
  }, [
    agentId,
    podName,
    shouldExecuteFetchLogs,
    tail,
    namespace,
    selectedContainer?.value,
    isPreviousLogs,
    preventFetching,
  ]);

  const [
    fetchPayloadVars,
    failureMessage,
    executeTaskCallback,
    setTaskTimeoutCallback,
  ] = useAgentTaskExecution(
    executeTaskVars,
    triggerManually,
    setTriggerManually
  );

  const [podLogsRes, refresh, fetching] = useQueryRefreshWithVariables(
    useFetchPodLogsQuery,
    fetchPayloadVars
  );

  const isFetching = useIsFetchingPodLogs({
    dataLength: podLogsRes?.pod_logs.length,
    taskId: fetchPayloadVars?.taskId,
    fetching,
  });

  useEffect(() => {
    if (podLogsRes?.pod_logs.length || failureMessage) {
      if (podLogsRes?.pod_logs && podLogsRes.pod_logs.length > 0) {
        setLogs(podLogsRes.pod_logs);
      }
      return;
    }

    if (retries > MAX_RETRIES) {
      setTaskTimeoutCallback();
      return;
    }

    const timeout = setTimeout(() => {
      if (!podLogsRes?.pod_logs.length) {
        refresh();
      }
      setRetries(retries + 1);
    }, POD_LOGS_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [failureMessage, podLogsRes, refresh, retries, setTaskTimeoutCallback]);

  const refreshCallback = useCallback(() => {
    setRetries(0);
    executeTaskCallback();
    refresh();
  }, [executeTaskCallback, refresh]);

  const errorMessage = `Failed to retrieve pods with agent task, retires: ${retries}, failureMessage: ${failureMessage}`;
  useEffect(() => {
    if (!startMeasureLatency || performance.now() - startMeasureLatency < 100)
      return;
    const metadata = {
      latency: performance.now() - startMeasureLatency,
      serviceId: controlledBy,
      clusterName: clusterName,
      podName: podName,
    };
    if (retries > MAX_RETRIES) {
      notifyDDError({
        name: "Timeout retrieving pod logs",
        message: errorMessage,
      });
      dispatchEvent(AnalyticEvents.PodView.Pod_Logs_Timeout, metadata);
    } else if (podLogsRes) {
      if (failureMessage) {
        notifyDDError({
          name: "Error retrieving pod logs",
          message: errorMessage,
        });
        dispatchEvent(AnalyticEvents.PodView.Pod_Logs_Failure, metadata);
      } else {
        setStartMeasureLatency(0);
        dispatchEvent(AnalyticEvents.PodView.Pod_Logs_E2E_Latency, metadata);
      }
    }
  }, [
    podName,
    podLogsRes,
    failureMessage,
    retries,
    clusterName,
    startMeasureLatency,
    controlledBy,
    errorMessage,
  ]);

  return {
    retries,
    failureMessage: customErrorMessage || failureMessage,
    refreshCallback,
    requestMoreLogs,
    tail,
    logs,
    fetching: isFetching,
    mode: PodLogsMode.ByDemand,
  };
};
