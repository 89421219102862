import { useEffect } from "react";

import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { ScaleTargetRef } from "../hpaReachedMaxTypes";

type UseDeployEventsParams = {
  hpaUid: string | undefined;
  scaleTargetRef: ScaleTargetRef | undefined;
};

export const useSetServiceUidFromHpaData = ({
  hpaUid,
  scaleTargetRef,
}: UseDeployEventsParams) => {
  const { setServiceKomodorUid, serviceKomodorUid } = useHpaReachedMaxContext();

  const { cluster = "", namespace = "" } = parseKomodorUid(hpaUid ?? "") ?? {};
  const { name = "", kind = "" } = scaleTargetRef ?? {};

  const serviceUid = buildKomodorUid({
    clusterName: cluster,
    namespace,
    resourceName: name,
    kind,
  });
  const hasServiceUid = !!kind && !!cluster && !!namespace && !!name;

  useEffect(() => {
    if (!serviceKomodorUid && hasServiceUid) {
      setServiceKomodorUid(serviceUid);
    }
  }, [
    cluster,
    hasServiceUid,
    kind,
    name,
    namespace,
    serviceKomodorUid,
    serviceUid,
    setServiceKomodorUid,
  ]);
};
