import React from "react";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import EmptyStateSVG from "../../shared/assets/komo.svg?react";
import isElectronContext from "../../shared/context/electron/isElectronContext";
import { EmptyStateContainer } from "../servicesView/AgentNotInstalledState";

const ClickableTypography = styled(Typography).attrs({
  role: "button",
  "data-e2e-selector": "upgrade-agent-button",
  color: theme.foreground.fgDarkBlue,
  variant: "title",
})`
  cursor: pointer;
`;

interface AgentNotSupportedProps {
  onUpgradeClicked?: () => void;
  title: string;
}

export const AgentNotSupported: React.FC<AgentNotSupportedProps> = ({
  onUpgradeClicked,
  title,
}) => {
  const isTextClickable = onUpgradeClicked !== undefined;

  if (isElectronContext()) return null;

  return (
    <EmptyStateContainer>
      <EmptyStateSVG />
      <Typography variant="title" data-e2e-selector="inspection-empty-state">
        {title}
      </Typography>

      {isTextClickable ? (
        <ClickableTypography onClick={onUpgradeClicked}>
          Upgrade agent
        </ClickableTypography>
      ) : (
        <Typography variant="title" bold>
          Please upgrade to the latest version
        </Typography>
      )}
    </EmptyStateContainer>
  );
};
