import styled from "styled-components";

import { useIsServiceScope } from "./hooks/useIsServiceScope";
import { useShouldShowSearchField } from "./hooks/useShouldShowSearchField";
import { useShouldShowChecksTypeSelector } from "./hooks/useShouldShowChecksTypeSelector";
import { SearchFieldHandler } from "./SearchFieldHandler/SearchFieldHandler";
import { ChecksSelector } from "./filterSelectors/ChecksSelector";
import { StatusesSelector } from "./filterSelectors/StatusesSelector";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const ViolationsTableFilters: React.FC = () => {
  const isServiceScope = useIsServiceScope();
  const shouldShowSearchField = useShouldShowSearchField();
  const shouldShowChecksTypeSelector = useShouldShowChecksTypeSelector();

  return (
    <Container>
      {shouldShowSearchField && <SearchFieldHandler />}
      {shouldShowChecksTypeSelector && <ChecksSelector />}
      {isServiceScope && <StatusesSelector />}
    </Container>
  );
};
