import { add, formatDuration, intervalToDuration, parseISO } from "date-fns";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { User, RbacRole, RbacUserRole } from "../../../generated/auth";

import { RoleWithUserExpiration } from "./types";

import {
  formatDistanceLocale,
  timeTokens,
} from "@/components/Settings/Users/const";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "@/components/Settings/Roles/Roles";

export const validateUserRoles = (
  roles: MuiSelectionOption<string>[] | undefined
) => (!roles?.length ? "Please select at least one role" : undefined);

export const validateUserTempRoles = (
  role: MuiSelectionOption<string> | undefined
) =>
  !role?.value
    ? "Please select role or remove temporary role section"
    : undefined;

export const getFilteredUserRoles = (u: User, temporaryRoles: boolean) => {
  if (!u.rbacRoles) {
    return [];
  }
  return (u.rbacRoles as RbacRole[])
    .map((rbacRole) => {
      const r = u.rbacUserRoles?.find(
        (rbacUserRole: RbacUserRole) => rbacUserRole.roleId === rbacRole.id
      );
      if (r) {
        return { ...rbacRole, expiration: r.expiration };
      }
      return null;
    })
    .filter((role): role is RoleWithUserExpiration => role !== null)
    .filter((role) =>
      temporaryRoles ? role.expiration !== null : role.expiration === null
    );
};

const getShortLocale = () => ({
  formatDistance: (token: timeTokens, count: string) =>
    formatDistanceLocale[token]?.replace("{{count}}", count),
});
export const getTimeLeft = (targetDate: string) => {
  const duration = intervalToDuration({
    start: parseISO(targetDate),
    end: new Date(),
  });
  return formatDuration(duration, {
    format: ["years", "months", "days", "hours", "minutes"],
    locale: getShortLocale(),
  });
};

export const getNextDay = () => {
  return add(new Date(), { days: 1 });
};

export const isOnlyOneAccountAdmin = (users: User[] | undefined) =>
  Boolean(
    users &&
      users.filter((u) =>
        u.rbacRoles?.some((role) => role.name === ACCOUNT_ADMIN_RBAC_ROLE)
      ).length === 1
  );

export const sortByNameLength = (a: RbacRole, b: RbacRole) =>
  a.name.length - b.name.length || a.name.localeCompare(b.name);
