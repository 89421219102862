import { ViolationSupportingData } from "../../../../../../../../../generated/reliabilityApi";

export const getCpuThrottledTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const throttledFractions = supportingData?.throttledCPU?.containers.map(
    (container) => container.throttled_fraction ?? 0
  );

  const maxFraction = Math.round(Math.max(...(throttledFractions ?? [])) * 100);
  return `CPU throttled for ${maxFraction}% of time`;
};
