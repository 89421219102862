import React, { useState } from "react";
import styled from "styled-components";

import { H3Tabs } from "../../../../../common/typography";
import Header from "../../../../../monitorsView/common/Header";
import Divider from "../../../../../monitorsView/common/Divider";
import { Content } from "../../../../../monitorsView/monitorsConfiguration/common/styles";
import Footer from "../../../../../monitorsView/common/Footer";
import { useSentryInstallationWithToken } from "../../useSentryInstallation";
import { blackGreyText } from "../../../../../../Colors";
import { IntegrationSteps } from "../utils";
import { StyledContainer } from "../styles";
import { contactMessage } from "../../../../../../constants";
import { DEFAULT_SENTRY_ADDRESS } from "../../../../../common/ProcessList/details/SentryEventDetails";

import { GenericInstruction } from "./Instructions";

export const errorMessage = "secret is required";

const headerTitle = "Install Sentry Integration";

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem;
`;

const StyledHeadLineText = styled(H3Tabs)`
  color: ${blackGreyText};
`;

const HelpText = styled.div`
  margin-top: 2.5rem;
  color: darkred;
`;

const SentryConfigTokens: React.FC<{
  closeModalCallback: () => void;
  setCurStep: React.Dispatch<React.SetStateAction<number>>;
  curStep: number;
  numOfSteps: number;
  setFinishInstallation: React.Dispatch<React.SetStateAction<boolean>>;
  installationId: string;
}> = ({
  closeModalCallback,
  setCurStep,
  curStep,
  numOfSteps,
  setFinishInstallation,
  installationId,
}) => {
  const [apiToken, setApiToken] = useState("");
  const [domain, setDomain] = useState(DEFAULT_SENTRY_ADDRESS);
  const [orgName, setOrgName] = useState("");

  const [secret, setSecret] = useState("");
  const [secretValid, setSecretValid] = useState(true);

  const [error, setError] = useState(false);
  const install = useSentryInstallationWithToken();

  const validateSentryConfig = () => {
    const valid = secret.length >= 1;
    setSecretValid(valid);
    (async function () {
      if (valid) {
        try {
          await install(installationId, secret, apiToken, domain, orgName);
        } catch (e) {
          setError(true);
          return;
        }
        setFinishInstallation(true);
      }
    })();
  };

  return (
    <StyledContainer>
      <Header
        closeModalCallback={closeModalCallback}
        title={IntegrationSteps.SentryConfigureTokens}
        currentStepNumber={curStep}
        totalNumberOfSteps={numOfSteps}
        headerTitle={headerTitle}
      />
      <Divider />
      <Content>
        <RowContainer>
          <StyledHeadLineText>
            Provide the Sentry Client Secret and API Token below.
          </StyledHeadLineText>
        </RowContainer>
        <GenericInstruction
          value={secret}
          setValue={setSecret}
          valueValid={secretValid}
          handleChange={() => {
            setSecretValid(true);
            setError(false);
          }}
          text={"Sentry Client Secret"}
          placeholder={"secret"}
          number={1}
        />
        <GenericInstruction
          value={apiToken}
          setValue={setApiToken}
          valueValid={true}
          handleChange={() => {
            return;
          }}
          text={"Sentry API Token"}
          placeholder={"API token"}
          number={2}
        />
        <GenericInstruction
          value={domain}
          setValue={setDomain}
          valueValid={true}
          handleChange={() => {
            return;
          }}
          text={"Sentry Domain"}
          placeholder={DEFAULT_SENTRY_ADDRESS}
          number={3}
        />
        <GenericInstruction
          value={orgName}
          setValue={setOrgName}
          valueValid={true}
          handleChange={() => {
            return;
          }}
          text={"Sentry organization name"}
          placeholder="organization name"
          number={4}
        />
        {error && (
          <HelpText>
            Error while creating Sentry Integration.
            <br />
            {contactMessage}
          </HelpText>
        )}
      </Content>
      <Divider />
      <Footer
        backData={{
          label: "Back",
          action: () => {
            setCurStep(curStep - 1);
          },
        }}
        nextData={{
          label: "Continue",
          action: () => {
            validateSentryConfig();
          },
        }}
      />
    </StyledContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SentryConfigTokens;
