import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";

import {
  TooltipHourColor,
  TooltipValuesColor,
  TooltipValuesFormatColor,
  ValueContainer,
} from "./metricsTooltipStyles";

type TooltipContentEntryProps = {
  value: string;
  format: string;
  title: string;
  icon: React.ReactNode;
};

export const TooltipContentEntry: React.FC<TooltipContentEntryProps> = ({
  icon,
  format,
  value,
  title,
}) => {
  return (
    <>
      <ValueContainer>
        <Typography size="medium" bold color={TooltipValuesColor}>
          {value}
        </Typography>
        <Typography variant="uppercase" color={TooltipValuesFormatColor}>
          {format}
        </Typography>
      </ValueContainer>
      {icon}
      <Typography bold color={TooltipHourColor}>
        {title}
      </Typography>
    </>
  );
};
