import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import styled, { css } from "styled-components";

export const PaddedCard = styled(Card)`
  && {
    padding: 1rem;
  }
`;

export const ColorIndication = styled.div`
  height: 4px;
  width: 0.5rem;
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

const TextStyle = css`
  margin-bottom: 10px;
  color: ${muiColors.gray[800]};
`;

export const InsightsDrawerTitle = styled(Typography).attrs({
  variant: "h4",
})`
  && {
    ${TextStyle};
  }
`;

export const InsightsDrawerContent = styled(Typography).attrs({
  variant: "body2",
})`
  && {
    ${TextStyle};
  }
`;

export const InsightDrawerActionButton = styled(Button).attrs({
  variant: "outlined",
})`
  && {
    padding: 4px 8px;
    white-space: nowrap;
  }
`;
