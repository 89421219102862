import React, { useCallback } from "react";
import noop from "lodash/noop";

import { useKeydown } from "@/shared/hooks/useKeydown";

type KeyDownCallback = (e: KeyboardEvent) => void;

type InspectionViewContextType = {
  setKeyDownCallback: (cb: KeyDownCallback) => void;
  focusOnTable?: () => void;
  tbodyRef: React.RefObject<HTMLTableSectionElement>;
};

const InitialState: InspectionViewContextType = {
  setKeyDownCallback: noop,
  focusOnTable: noop,
  tbodyRef: { current: null },
};

export const InspectionViewContext =
  React.createContext<InspectionViewContextType>(InitialState);

export const InspectionViewProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [keyDownCallback, setKeyDownCallback] =
    React.useState<KeyDownCallback>(noop);
  const tbodyRef = React.useRef<HTMLTableSectionElement>(null);

  useKeydown({ cb: keyDownCallback, disableListener: false });

  const focusOnTable = useCallback(() => {
    if (!tbodyRef?.current) return;

    const firstRow = tbodyRef.current.childNodes[0];
    (firstRow as HTMLTableRowElement)?.focus();
  }, [tbodyRef]);

  return (
    <InspectionViewContext.Provider
      value={{ setKeyDownCallback, tbodyRef, focusOnTable }}
    >
      {children}
    </InspectionViewContext.Provider>
  );
};
