import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  visibility: hidden;
`;

type HiddenContainerProps = {
  popoverContent: React.ReactNode;
  setPopoverContentHeight: React.Dispatch<React.SetStateAction<number>>;
};

export const HiddenContainer: React.FC<HiddenContainerProps> = ({
  setPopoverContentHeight,
  popoverContent,
}) => {
  const popoverContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (popoverContentRef.current?.clientHeight) {
      setPopoverContentHeight(popoverContentRef.current.clientHeight);
    }
  }, [setPopoverContentHeight]);

  return (
    <Container>
      <div ref={popoverContentRef}>{popoverContent}</div>
    </Container>
  );
};
