import React, { SyntheticEvent, useCallback, useMemo, useRef } from "react";
import { Customized } from "recharts";

export type ChartEvent = { chartX?: number } | null;
interface ZoomComponentProps {
  key?: string;
  xAxisMap?: { domain: number[] }[];
  offset?: { left: number; top: number; width: number; height: number };
}

const getRelativeValue = (
  av: number,
  a1: number,
  a2: number,
  b1: number,
  b2: number
) => (av * (b2 - b1) + b1 * a2 - a1 * b2) / (a2 - a1);

const useZoom = (
  onChange: (start: number, end: number) => void,
  className?: string
): {
  onMouseDown: (ce: ChartEvent, e: SyntheticEvent) => void;
  onMouseMove: (ce: ChartEvent) => void;
  onMouseUp: () => void;
  zoom: JSX.Element;
} => {
  const zoomRef = useRef<SVGRectElement>(null);

  const getDomainValue = useRef<((v: number) => number) | null>(null);
  const selected = useRef(-1);
  const onMouseDown = useCallback(
    (chartEvent: ChartEvent, { target }: SyntheticEvent) => {
      if (
        target instanceof SVGElement &&
        target.classList.contains(className ?? "recharts-surface") &&
        chartEvent?.chartX != null
      ) {
        selected.current = chartEvent.chartX;
      }
    },
    [className]
  );
  const onMouseMove = useCallback((chartEvent: ChartEvent) => {
    if (
      selected.current === -1 ||
      chartEvent?.chartX == null ||
      !zoomRef.current
    ) {
      return;
    }
    if (chartEvent.chartX > selected.current) {
      zoomRef.current.x.baseVal.value = selected.current;
      zoomRef.current.width.baseVal.value =
        chartEvent.chartX - selected.current;
    } else {
      zoomRef.current.x.baseVal.value = chartEvent.chartX;
      zoomRef.current.width.baseVal.value =
        selected.current - chartEvent.chartX;
    }
  }, []);
  const onMouseUp = useCallback(() => {
    if (selected.current === -1) {
      return;
    }
    selected.current = -1;
    if (!zoomRef.current) {
      return;
    }

    const offsetV1 = zoomRef.current.x.baseVal.value;
    const offsetV2 = offsetV1 + zoomRef.current.width.baseVal.value;

    zoomRef.current.width.baseVal.value = 0;
    if (offsetV2 - offsetV1 === 0) {
      return;
    }
    if (getDomainValue.current) {
      const start = getDomainValue.current(offsetV1);
      const end = getDomainValue.current(offsetV2);
      onChange(start, end);
    }
  }, [onChange]);
  const ZoomComponent = useCallback(
    ({ xAxisMap, offset }: ZoomComponentProps) => {
      const domain = xAxisMap?.[0].domain;
      if (!xAxisMap || !offset || !Array.isArray(domain)) {
        return null;
      }
      const [domainStart, domainEnd] = domain;
      const offsetStart = offset.left;
      const offsetEnd = offset.left + offset.width;
      if (
        domainStart == null ||
        domainEnd == null ||
        offsetStart == null ||
        offsetEnd == null
      ) {
        getDomainValue.current = null;
      } else {
        getDomainValue.current = (v) =>
          getRelativeValue(v, offsetStart, offsetEnd, domainStart, domainEnd);
      }
      return (
        <rect
          ref={zoomRef}
          y={offset.top}
          height={offset.height}
          width="0"
          fill="#ccc"
          fillOpacity="0.5"
        />
      );
    },
    []
  );
  const zoom = useMemo(() => {
    return <Customized key="zoom" component={ZoomComponent} />;
  }, [ZoomComponent]);
  return { onMouseDown, onMouseMove, onMouseUp, zoom };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useZoom;
