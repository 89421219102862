import React from "react";

import { AvailabilityIssuesTableColumn } from "../../shared/types";
import { InsightDrawerActionButton } from "../../shared/styles";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectOpenServiceResourceDrawer } from "../../../store/overviewPageStoreSelectors";
import {
  useSetEventsTabEventTypeFilter,
  useSetTimeFrameInEventsTabFilters,
} from "../../shared/hooks/eventsTabHooks";
import { Timeframe } from "../../../../../../../../../shared/types/TimeWindow";
import { WORKFLOW_CONFIG_TYPES } from "../../../../../../../../common/EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../../../../../../monitorsView/common/types";
import { overviewPageInsightsAriaLabels } from "../../../../../appViewAriaLabels";

type ViewIssuesButtonProps = {
  rowData: AvailabilityIssuesTableColumn;
};

const availabilityIssues =
  WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Availability];

const {
  availabilityIssues: { viewIssues: viewIssuesAriaLabel },
} = overviewPageInsightsAriaLabels;

export const ViewIssuesButton: React.FC<ViewIssuesButtonProps> = ({
  rowData,
}) => {
  const setEventsTabFilters = useSetEventsTabEventTypeFilter();
  const setTimeFrameInEventsTabFilters = useSetTimeFrameInEventsTabFilters(
    Timeframe.Last7Days
  );

  const openServiceResourceDrawer = useOverviewPageStore(
    selectOpenServiceResourceDrawer
  );
  const onClick = () => {
    openServiceResourceDrawer(rowData.id);
    setEventsTabFilters(availabilityIssues);
    setTimeFrameInEventsTabFilters();
  };

  return (
    <InsightDrawerActionButton
      onClick={onClick}
      aria-label={viewIssuesAriaLabel}
    >
      View issues
    </InsightDrawerActionButton>
  );
};
