import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { useBuildKomodorUidForKomodorService } from "../../shared/hooks/resources-api/resourcesAPIUtils";
import {
  DeployEventsResponse,
  EventsApiApiV1DeploysEventsSearchGetRequest,
} from "../../generated/resourcesApi";
import { useResourcesApiClient } from "../../shared/hooks/resources-api/client/apiClient";
import { DEPLOY_EVENTS_SEARCH } from "../../shared/hooks/resources-api/requestResponseMaps";
import { fetchLatestDeployUntil } from "../../shared/hooks/resources-api/client/events/useSearchDeployEvents";

import {
  StaticPreventionWorkflowResult,
  useStaticPreventionWorkflowConfiguration,
  useStaticPreventionWorkflowResult,
} from "./useStaticPreventionWorkflow";
import { getResultsFromPolaris } from "./PolarisWrapper";
import {
  getWorkflowConfigurationNameByServiceName,
  StaticPreventionBasicConfiguration,
  StaticPreventionResult,
} from "./StaticPreventionUtils";

export interface StaticPreventionResults {
  workflowResults: StaticPreventionWorkflowResult;
  configurationResults: [
    StaticPreventionBasicConfiguration,
    () => void,
    string
  ];
  lastDeploy: DeployEventsResponse | undefined;
  parsedPolarisResults: StaticPreventionResult | null;
}
export const useStaticPrevention = (
  serviceId?: string
): StaticPreventionResults => {
  const configurationName = useMemo(
    () => getWorkflowConfigurationNameByServiceName(serviceId ?? ""),
    [serviceId]
  );

  const komodorUid = useBuildKomodorUidForKomodorService(serviceId ?? "");
  const params =
    useMemo<EventsApiApiV1DeploysEventsSearchGetRequest | null>(() => {
      if (!komodorUid) return null;

      // CU-86byb97n3 - The time should be according to the audit time
      const toEpoch = new Date().valueOf();
      const fromEpoch = toEpoch - 1000 * 60 * 60 * 24 * 30; //30 days
      return {
        komodorUids: [komodorUid],
        order: "DESC",
        limit: 1,
        fields: ["id", "newSpec", "deploymentName"],
        fromEpoch: fromEpoch.toString(),
        toEpoch: toEpoch.toString(),
      };
    }, [komodorUid]);
  const apiClient = useResourcesApiClient();
  const { data } = useQuery([DEPLOY_EVENTS_SEARCH, params], () =>
    fetchLatestDeployUntil(apiClient, params)
  );
  const [workflowConfiguration, refreshConfiguration, isFetchingConfiguration] =
    useStaticPreventionWorkflowConfiguration(configurationName);

  const workflowResults = useStaticPreventionWorkflowResult(serviceId);
  const parsedPolarisResults = useMemo(() => {
    if (
      isFetchingConfiguration ||
      !workflowResults ||
      workflowResults.length === 0 ||
      !workflowConfiguration
    ) {
      return null;
    }
    return getResultsFromPolaris(
      workflowResults[0].results,
      workflowConfiguration
    );
  }, [isFetchingConfiguration, workflowConfiguration, workflowResults]);

  return {
    workflowResults,
    configurationResults: [
      workflowConfiguration,
      refreshConfiguration,
      configurationName,
    ],
    lastDeploy: data,
    parsedPolarisResults,
  };
};
