import React from "react";

import Action from "../Actions/buttons";
import {
  SupportedResourceKindEnum,
  usePortForward,
} from "../../shared/context/PortForwardProvider";
import { REQUIRED_AGENT_VERSION_FOR_LIVE_VIEWS } from "../../shared/utils/agent/agent";
import { AriaLabels } from "../../shared/config/ariaLabels";
import { ActionButtonProps } from "../Actions/buttons/types";
import { useSetPortForwardingData } from "../ResourceView/tabs/DescribeTab/utils/useSetPortForwardingData";
import Pod from "../ResourceView/resources/pod";
import Service from "../ResourceView/resources/service";
import { KubernetesPodsResource } from "../Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const StartPortForwardingActionButton: React.FC<ActionButtonProps> = ({
  Button,
  resource,
}) => {
  const { setIsPortForwardModalOpen } = usePortForward();
  useSetPortForwardingData(
    resource as Pod | Service,
    resource.kind === KubernetesPodsResource.Kind
      ? SupportedResourceKindEnum.Pod
      : SupportedResourceKindEnum.Service
  );

  return (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={() => setIsPortForwardModalOpen(true)}
      Button={Button}
      minimalSupportedVersion={REQUIRED_AGENT_VERSION_FOR_LIVE_VIEWS}
      ariaLabel={AriaLabels.PortForwarding.OpenNewSession}
      action="portForward"
    />
  );
};
