import { WorkloadMetricsContainerSummary } from "@/generated/metricsApi";

export type MetricsType = "cpu" | "memory";

export type Category = "limit" | "requests";

export interface MetricsTableProps {
  metrics: WorkloadMetricsContainerSummary[];
  metricsType: MetricsType;
  category: Category;
  expectedNumOfMetrics: number;
  isLoading: boolean;
}

export const MetricRowKeys = {
  id: "id",
  name: "name",
  avg: "avg",
  p99: "p99",
  max: "max",
} as const;

export type MetricRows = {
  [MetricRowKeys.id]: string;
  [MetricRowKeys.name]: string;
  [MetricRowKeys.avg]: number;
  [MetricRowKeys.p99]: number;
  [MetricRowKeys.max]: number;
};
