import styled from "styled-components";

type JustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";

type AlignItems = "stretch" | "flex-start" | "flex-end" | "center" | "baseline";

type AlignContent =
  | "normal"
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "stretch";

const Flexbox = styled.div<{
  column?: boolean;
  justifyContent?: JustifyContent;
  alignContent?: AlignContent;
  alignItems?: AlignItems;
  gap?: string;
}>`
  display: flex;
  ${({ column }) => (column ? "flex-direction: column;" : "")}
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ""};
  ${({ alignContent }) =>
    alignContent ? `align-content: ${alignContent};` : ""}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : "")}
  width: fit-content;
  ${({ gap }) => (gap ? `gap: ${gap};` : "")}
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Flexbox;
