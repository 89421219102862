import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  isAppViewDataComplete,
  useGetAppViewData,
  useResetStateOnUnmountEffect,
} from "../../hooks/overviewPageHooks";
import { PageLoader } from "../../../../../../shared/components/PageLoader";
import { AppViewUrlParam } from "../../../../types/urlParams";
import { useAppViewsStore } from "../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  errorStateSelector,
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { CardsContainer } from "./cardsContent/CardsContainer/CardsContainer";
import { OverviewPageHeader } from "./OverviewPageHeader/OverviewPageHeader";
import { HistogramsContainer } from "./HistogramsContainer/HistogramsContainer";
import { OverviewPageContextProvider } from "./context/overviewPageContext";

const ContentContainer = styled.div`
  padding: 0 24px;
`;

export const OverviewPageOld: React.FC = () => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const error = useAppViewsStore(errorStateSelector);

  const getAppViewData = useGetAppViewData();
  const isAppViewDataReady = isAppViewDataComplete(currentAppView);
  useResetStateOnUnmountEffect();
  const { id } = useParams<AppViewUrlParam>();
  const initialLoad = useRef(false);

  useEffect(() => {
    if (!initialLoad.current && id && !selectedAppViewId) {
      setSelectedAppViewId(id);
    }
    initialLoad.current = true;
  }, [currentAppView, id, selectedAppViewId, setSelectedAppViewId]);

  useEffect(() => {
    getAppViewData();
  }, [getAppViewData]);

  const OverviewPageContent = (
    <OverviewPageContextProvider>
      <OverviewPageHeader />
      <ContentContainer>
        <HistogramsContainer />
        <CardsContainer />
      </ContentContainer>
    </OverviewPageContextProvider>
  );
  if (error) {
    /* TODO: Error state (design required)*/
    return <>error state</>;
  }
  return <>{isAppViewDataReady ? OverviewPageContent : <PageLoader />}</>;
};
