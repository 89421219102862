import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

export const useReportLoadingState = () => {
  const { loadingState } = useHpaReachedMaxContext();

  const isLoadingComplete = Object.values(loadingState).every(
    (loading) => !loading
  );
  useReportDrawerLoadingTime(isLoadingComplete);
};
