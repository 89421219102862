import React from "react";

import { IconProps } from "../types";

export const InfoCircle24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7C11 7.26522 11.1054 7.51957 11.2929 7.70711C11.4804 7.89464 11.7348 8 12 8C12.2652 8 12.5196 7.89464 12.7071 7.70711ZM10.2197 10.2197C10.079 10.3603 10 10.5511 10 10.75C10 10.9489 10.079 11.1397 10.2197 11.2803C10.3603 11.421 10.5511 11.5 10.75 11.5H11.5V15.5H10.75C10.5511 15.5 10.3603 15.579 10.2197 15.7197C10.079 15.8603 10 16.0511 10 16.25C10 16.4489 10.079 16.6397 10.2197 16.7803C10.3603 16.921 10.5511 17 10.75 17H13.75C13.9489 17 14.1397 16.921 14.2803 16.7803C14.421 16.6397 14.5 16.4489 14.5 16.25C14.5 16.0511 14.421 15.8603 14.2803 15.7197C14.1397 15.579 13.9489 15.5 13.75 15.5H13V10.75C13 10.5511 12.921 10.3603 12.7803 10.2197C12.6397 10.079 12.4489 10 12.25 10H10.75C10.5511 10 10.3603 10.079 10.2197 10.2197Z"
      />
    </svg>
  );
};

InfoCircle24.defaultProps = {
  fill: "currentColor",
};
