import React from "react";

export type IgnoreCheckRuleOption = "service" | "namespace" | "cluster" | "all";

export const radioLabels: Record<
  IgnoreCheckRuleOption,
  { title: React.ReactNode; subtitle?: string }
> = {
  service: {
    title: (
      <>
        Ignore this <b>Service</b>
      </>
    ),
  },
  namespace: {
    title: (
      <>
        Ignore this <b>Namespace</b>
      </>
    ),
  },
  cluster: {
    title: (
      <>
        Ignore this <b>Cluster</b>
      </>
    ),
  },
  all: {
    title: <>Always ignore this check</>,
    subtitle: "The check will not be performed anymore",
  },
};
