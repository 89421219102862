import React, { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import { get } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Resource, { Label } from "../../resources";

import { Title } from "./Title/Title";
import { Summary } from "./Summary/Summary";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { HeaderActions } from "./Actions/HeaderActions";

interface HeaderProps {
  resource: Resource;
  labels: Label[];
  status?: JSX.Element;
  disableActions?: boolean;
  displayName?: string;
  leftActions?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  padding-block: 0.5rem;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`;

const HeadContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  height: 48px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LeftActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Header: React.FC<HeaderProps> = memo(
  ({
    resource,
    labels,
    status,
    disableActions,
    leftActions,
    displayName = resource.name,
  }) => {
    const Icon = resource.icon;

    const headerActionsRef = useRef(
      <HeaderActions resource={resource} disableAll={disableActions} />
    );

    const availableVersions = get(resource, "availableVersions");
    useEffect(() => {
      headerActionsRef.current = (
        <HeaderActions resource={resource} disableAll={disableActions} />
      );
    }, [disableActions, resource, availableVersions]);

    return (
      <Container data-e2e-selector="serviceHeader">
        <HeadContainer>
          <FlexContainer>
            {leftActions && (
              <LeftActionsContainer>{leftActions}</LeftActionsContainer>
            )}
            <Title displayName={displayName} status={status} />
          </FlexContainer>
          {headerActionsRef.current}
        </HeadContainer>
        <FlexContainer>
          {Icon && <Icon />}
          <Summary labels={labels} />
        </FlexContainer>
      </Container>
    );
  }
);
