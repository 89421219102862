import { useCallback, useState } from "react";

import { useResizeObserver } from "../../../../shared/hooks/useResizeObserver";

export const useOnContainerResizeHeight = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const [containerHeight, setContentHeight] = useState<number>(0);

  const onContainerResize = useCallback(() => {
    if (!containerRef.current) return;
    setContentHeight((containerRef.current.clientHeight || 0) - 5);
  }, [containerRef]);

  useResizeObserver(containerRef, onContainerResize);

  return containerHeight;
};
