import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useActiveAgent } from "../../../../../../../../../shared/hooks/useAgents";
import { useDrawersStackStore } from "../../../../../../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../../../../../../shared/store/drawersStackStore/types";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  justify-content: space-between;
  min-height: 32px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type DetailsTitleProps = {
  name?: string;
  hpaUid?: string;
};

export const DetailsTitle: React.FC<DetailsTitleProps> = ({ name, hpaUid }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const { cluster } = parseKomodorUid(hpaUid ?? "") ?? {};
  const agentId = useActiveAgent(cluster) ?? "";

  const openDrawer = (uid: string) => {
    const {
      kind = "",
      cluster = "",
      namespace = "",
      name = "",
    } = parseKomodorUid(uid ?? "") ?? {};
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: cluster,
      namespace: namespace,
      resourceType: kind,
      resourceName: name,
    });
  };

  return (
    <Container>
      <TextContainer>
        <Typography variant={"body1"}>Horizontal Pod Autoscaler:</Typography>
        {!name && <Skeleton width={"180px"} height={"24px"} />}
        {!!name && <Typography variant={"h4"}>{name}</Typography>}
      </TextContainer>
      {agentId && (
        <Button variant={"text"} onClick={() => openDrawer(hpaUid ?? "")}>
          View HPA
        </Button>
      )}
    </Container>
  );
};
