import { CheckType } from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";

const checkTypeToNameMap: Dictionary<string> = {
  [CheckType.UnhealthyService]: "Service",
  [CheckType.UnhealthyWorkflow]: "Workflow",
  [CheckType.FailedJob]: "Job",
  [CheckType.UnhealthyNode]: "Node",
  [CheckType.UnhealthyPvc]: "PVC",
};

export const getNameFromCheckType = (checkType: CheckType): string =>
  checkTypeToNameMap[checkType] ?? checkType;

const minutesInMS = 1000 * 60;
const hoursInMS = minutesInMS * 60;
const daysInMS = hoursInMS * 24;

export const formatDurationFromNow = (timestampInSecondes: number): string => {
  const now = Date.now();
  const timestampInMilliseconds = timestampInSecondes * 1000;
  const difference = now - timestampInMilliseconds;

  const days = Math.floor(difference / daysInMS);
  const hours = Math.floor((difference % daysInMS) / hoursInMS);
  const minutes = Math.floor((difference % hoursInMS) / minutesInMS);

  let result = "";
  let withDays = false;

  if (days > 0) {
    result += `${days}d `;
    withDays = true;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 && !withDays) {
    result += `${minutes}m`;
  }

  return result.trim();
};
