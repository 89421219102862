import React, { createContext, useCallback, useState } from "react";

import { MetricType } from "../../../../../../../../../Metrics/types";
import { LoadingStateIds } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabConstants";

type RequestValues = Record<MetricType, number | undefined>;
type LoadingState = {
  hpaData: boolean;
  [LoadingStateIds.workLoadMemory]: boolean;
  [LoadingStateIds.workLoadCPU]: boolean;
};

export type HpaReachedMaxContextState = {
  serviceKomodorUid: string | undefined;
  setServiceKomodorUid: (serviceKomodorUid: string | undefined) => void;
  requestValues: RequestValues;
  setRequestValues: (val: Partial<RequestValues>) => void;
  loadingState: LoadingState;
  setLoadingState: (val: Partial<LoadingState>) => void;
};

const initialState: HpaReachedMaxContextState = {
  serviceKomodorUid: undefined,
  requestValues: { cpu: undefined, memory: undefined },
  setServiceKomodorUid: () => undefined,
  setRequestValues: () => undefined,
  loadingState: {
    hpaData: true,
    [LoadingStateIds.workLoadMemory]: true,
    [LoadingStateIds.workLoadCPU]: true,
  },
  setLoadingState: () => undefined,
};

export const HpaReachedMaxContext =
  createContext<HpaReachedMaxContextState>(initialState);

export const HpaReachedMaxContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [serviceKomodorUid, setServiceKomodorUid] = useState(
    initialState.serviceKomodorUid
  );
  const [requestValues, setRequestValues] = useState<RequestValues>(
    initialState.requestValues
  );
  const [loadingState, setLoadingState] = useState<LoadingState>(
    initialState.loadingState
  );

  const onSetRequestValues = useCallback((val: Partial<RequestValues>) => {
    setRequestValues((prev) => ({ ...prev, ...val }));
  }, []);

  const onSetLoadingState = useCallback((val: Partial<LoadingState>) => {
    setLoadingState((prev) => ({ ...prev, ...val }));
  }, []);

  const state: HpaReachedMaxContextState = {
    serviceKomodorUid,
    setServiceKomodorUid,
    requestValues,
    setRequestValues: onSetRequestValues,
    loadingState,
    setLoadingState: onSetLoadingState,
  };

  return (
    <HpaReachedMaxContext.Provider value={state}>
      {children}
    </HpaReachedMaxContext.Provider>
  );
};
