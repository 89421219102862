import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

export const StyledTypography = styled(Typography)`
  && {
    color: ${muiColors.pink[600]};
  }
`;

const { errorText } = reliabilityArialLabels.policies.policiesDrawer;

type HelperTextTypographyProps = {
  errorMessage: string | undefined;
};

export const HelperTextTypography: React.FC<HelperTextTypographyProps> = ({
  errorMessage,
}) => {
  if (!errorMessage) return null;
  return (
    <StyledTypography variant={"body3"} aria-label={errorText}>
      {errorMessage}
    </StyledTypography>
  );
};
