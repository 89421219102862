import { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { useRecommendationsModalContext } from "../../context/useRecommendationsModalContext";

import { ResourceInput } from "./ResourceInput";

const RecommendationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 240px;
`;

interface ResourceRecommendationsProps {
  containerName: string;
  type: "cpu" | "memory";
  currentValue: number;
  recommendedValue: number;
}

const resourceProps = {
  memory: { title: "Memory", unit: "MiB" },
  cpu: { title: "CPU", unit: "millicores" },
};

export const ResourceRecommendations: React.FC<
  ResourceRecommendationsProps
> = ({ containerName, type, currentValue, recommendedValue }) => {
  const { updateContainer, getContainer } = useRecommendationsModalContext();
  const { title, unit } = resourceProps[type];

  const currentContainer = useMemo(
    () => getContainer(containerName),
    [containerName, getContainer]
  );

  return (
    <RecommendationsContainer>
      <Typography variant="h5" color="text.primary">
        {title}
      </Typography>
      <ResourceInput
        label="Requests"
        unit={unit}
        resource={currentContainer?.resources}
        resourceType={type}
        type="requests"
        onChange={(value: number | null) =>
          updateContainer({
            containerName,
            variant: "requests",
            resource: type,
            value,
          })
        }
        ResourceMetricsData={{
          currentValue,
          recommendedValue,
        }}
      />
      <ResourceInput
        label="Limits"
        unit={unit}
        resource={currentContainer?.resources}
        resourceType={type}
        type="limits"
        onChange={(value: number | null) =>
          updateContainer({
            containerName,
            variant: "limits",
            resource: type,
            value,
          })
        }
      />
    </RecommendationsContainer>
  );
};
