import React from "react";
import { Taint } from "kubernetes-types/core/v1.d";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertCircleOutlined16 } from "@komodorio/design-system/icons";

import { Table } from "../../../../../shared/components/Table/Table";
import { Dictionary } from "../../../../../shared/types/Dictionary";
import { DescribeTableItems } from "../common/DescribeItem";

import { taintsColumns } from "./constants";

export const MissingTaintsContainer = styled.div`
  display: flex;
  background: white;
  overflow-x: auto;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  height: fit-content;
  padding: 0.75rem 0.75rem;
  color: ${palette.gray[600]};
  gap: 8px;
`;

export const NodeTaints: React.FC<{ taints: Taint[] }> = ({ taints }) => (
  <DescribeTableItems name={"Taints"}>
    {taints.length ? (
      <Table
        data={taints.map(stringifyTaintValues)}
        columns={taintsColumns}
        sortable={false}
      />
    ) : (
      <MissingTaints />
    )}
  </DescribeTableItems>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const stringifyTaintValues = (taint: Taint): Dictionary<string> => ({
  ...taint,
  timeAdded: taint.timeAdded?.toString() ?? "",
});

const MissingTaints: React.FC = () => (
  <MissingTaintsContainer>
    <AlertCircleOutlined16 />
    <Typography variant="text" size="small" bold color={palette.gray[600]}>
      The node has no taints
    </Typography>
  </MissingTaintsContainer>
);
