import { isBefore } from "date-fns";
import { useMemo } from "react";

import { TimeWindow } from "../../shared/types/TimeWindow";

import useEventsLimit from "./useEventsLimit";
import useTiersVisible from "./useTiersVisible";

export const useIsTimewindowInAccountLimit = (
  timeWindow: TimeWindow | null
): boolean | undefined => {
  const limitedTimeWindows = useEventsLimit();
  const isTiersVisible = useTiersVisible();

  return useMemo(() => {
    if (!isTiersVisible) return false;
    if (!timeWindow) return false;
    if (!limitedTimeWindows) return undefined;

    const { eventsLimitEpoch } = limitedTimeWindows;
    if (!eventsLimitEpoch) return false;
    const reachedLimitDate = new Date(eventsLimitEpoch);
    return isBefore(reachedLimitDate, timeWindow.end);
  }, [isTiersVisible, limitedTimeWindows, timeWindow]);
};
