import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import Autocomplete from "../../common/controls/Autocomplete";
import {
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "../../Inspection/filters/InspectionResourceListSearch";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

const MonitorsListClusterFilter: React.FC<{
  clusters: string[];
  cluster: string | undefined;
  setCluster: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ clusters, cluster, setCluster }) => {
  const { control, watch } = useForm();
  const watchCluster = watch("cluster", cluster);
  const suggestions = useMemo(() => {
    return [
      { label: "ALL", value: "" },
      ...clusters.map((c) => ({ label: c, value: c })),
    ];
  }, [clusters]);

  return (
    <InspectionFilterContainer>
      <InspectionFilterDiv>Cluster</InspectionFilterDiv>
      <Controller
        name="cluster"
        control={control}
        defaultValue={watchCluster}
        render={() => (
          <Autocomplete
            fieldname="cluster"
            placeholder="choose cluster"
            defaultSelected={suggestions[0]}
            suggestions={suggestions}
            onSelectedChange={(value) => {
              if (value !== cluster) {
                setCluster(value);
                dispatchEvent(AnalyticEvents.MonitorsView.Filter_Selected, {
                  filterCategory: "cluster",
                  filterOption: value,
                });
              }
            }}
          />
        )}
      />
    </InspectionFilterContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorsListClusterFilter;
