import { useCallback, useRef } from "react";

interface OnHover {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const useOnDelayedHover = (
  handler: () => void,
  delay: number
): OnHover => {
  const ref = useRef(-1);
  const onMouseEnter = useCallback(() => {
    ref.current = window.setTimeout(handler, delay);
  }, [delay, handler]);
  const onMouseLeave = useCallback(() => {
    window.clearTimeout(ref.current);
  }, []);
  return { onMouseEnter, onMouseLeave };
};
