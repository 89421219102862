import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const defaultOpenTransitionTime = 500;
const defaultCloseTransitionTime = 250;
const defaultMaxContentHeight = 5000;

const Container = styled.div<{
  open: boolean;
  openTransitionTime: number;
  closeTransitionTime: number;
  maxHeight: number;
}>`
  overflow: hidden;
  cursor: default;
  transition: max-height
    ${({ open, openTransitionTime, closeTransitionTime }) =>
      open ? openTransitionTime : closeTransitionTime}ms;
  transition-timing-function: ${({ open }) =>
    open ? "ease-in-out" : "ease-out"};
  max-height: ${({ open, maxHeight }) => (open ? `${maxHeight}px` : "0px")};
`;

const HiddenContainer = styled.div`
  visibility: hidden;
  position: absolute;
`;

type ExpandableContentContainerProps = {
  isExpanded: boolean;
  children: React.ReactNode;
  openTransitionTime?: number;
  closeTransitionTime?: number;
  autoDetectContentHeight?: boolean;
};

export const ExpandableContentContainer: React.FC<
  ExpandableContentContainerProps
> = ({
  isExpanded,
  children,
  openTransitionTime,
  closeTransitionTime,
  autoDetectContentHeight = true,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [maxContentHeight, setMaxContentHeight] = useState(
    defaultMaxContentHeight
  );
  const [hasSetContentHeight, setHasSetContentHeight] = useState(false);
  const shouldSetContentHeight =
    autoDetectContentHeight && !hasSetContentHeight;

  const containerRef = useRef<HTMLDivElement>(null);

  const openTransitionTimeToUse =
    openTransitionTime || defaultOpenTransitionTime;
  const closeTransitionTimeToUse =
    closeTransitionTime || defaultCloseTransitionTime;

  useEffect(() => {
    if (isExpanded) {
      setShowContent(true);
      return;
    }

    const timeoutId = setTimeout(() => {
      setShowContent(false);
    }, closeTransitionTimeToUse);
    return () => clearTimeout(timeoutId);
  }, [isExpanded, closeTransitionTimeToUse]);

  useEffect(() => {
    if (shouldSetContentHeight && containerRef.current) {
      setTimeout(() => {
        if (!containerRef.current) return;
        setMaxContentHeight(containerRef.current.clientHeight);
        setHasSetContentHeight(true);
      }, 1);
    }
  }, [shouldSetContentHeight]);

  return (
    <Container
      open={isExpanded}
      openTransitionTime={openTransitionTimeToUse}
      closeTransitionTime={closeTransitionTimeToUse}
      maxHeight={maxContentHeight}
    >
      {showContent && children}
      {shouldSetContentHeight && (
        <HiddenContainer ref={containerRef}>{children}</HiddenContainer>
      )}
    </Container>
  );
};
