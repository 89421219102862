import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";

import {
  ALL_TAB_VALUE,
  IssuesTableContextProvider,
} from "../pages/violations/ViolationsBreakdown/RealtimeSection/context/IssuesTableContext";
import {
  useEmptyColumns,
  useIssuesColumns,
} from "../pages/violations/ViolationsBreakdown/RealtimeSection/hooks/useIssuesColumns";
import { useOpenIssueDrawer } from "../pages/violations/ViolationsBreakdown/RealtimeSection/hooks/useOpenIssueDrawer";
import { useIssuesTableContext } from "../pages/violations/ViolationsBreakdown/RealtimeSection/context/useIssuesTableContext";
import { tableProps } from "../pages/violations/ViolationsBreakdown/violationsBreakdownUtils";
import { IssuesTabs } from "../pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTable/IssuesTabs/IssuesTabs";

import { RealtimeIssuesDrawerProps } from "./types";

import { useRowsCount } from "@/components/reliability/components/shared/table/useRowsCount";
import {
  BasicViolation,
  CheckCategory,
  CheckType,
  ImpactGroupType,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { generateEmptyRows } from "@/shared/utils/tableUtils";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { useRealtimeIssues } from "@/components/ClustersView/ClusterOverview/components/HealthSection/hooks";

export type Props = RealtimeIssuesDrawerProps & {
  open: boolean;
  onClose: () => void;
};

export const RealtimeIssuesDrawer: React.FC<Props> = ({
  open,
  onClose,
  checkCategory,
  checkTypes,
  initialCheckType,
  clusters,
}) => {
  const workloadIssuesStats = useRealtimeIssues(checkCategory, clusters);

  const breakdownByCheckType = workloadIssuesStats.groups.reduce(
    (acc, group) => {
      acc[group.checkType] = group.check;
      return acc;
    },
    {} as Record<CheckType, number>
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={5}
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          padding: "16px",
          width: "60%",
        },
      }}
    >
      <Box display="flex" gap="8px" marginBottom="24px">
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <Close />
        </IconButton>
        <Typography variant="h3">Realtime Issues</Typography>
      </Box>
      <IssuesTableContextProvider
        initialTabValue={initialCheckType}
        issuesBreakdown={breakdownByCheckType}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ paddingInline: "16px" }}
        >
          <IssuesTabs />
          <OpenIssuesTable
            checkCategory={checkCategory}
            checkTypes={checkTypes}
            clusters={clusters}
          />
        </Box>
      </IssuesTableContextProvider>
    </Drawer>
  );
};

const OpenIssuesTable: React.FC<{
  checkCategory: CheckCategory;
  checkTypes: CheckType[];
  clusters?: string[];
}> = ({ checkCategory, checkTypes, clusters }) => {
  const tab = useIssuesTableContext().tabValue;
  const { paginationModel, setPaginationModel } = useIssuesTableContext();
  const { data, isFetching, error } = useGetViolations({
    checkType: tab === ALL_TAB_VALUE ? checkTypes : [tab],
    checkCategory: [checkCategory],
    pageSize: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    impactGroupType: [ImpactGroupType.Realtime],
    clusterName: clusters,
    status: [ViolationStatus.Open, ViolationStatus.Confirmed],
  });
  const rowsCount = useRowsCount(data?.data?.totalResults);

  const onRowClickHandler = useOpenIssueDrawer();

  const hasNoResults = data?.data.violations?.length === 0 || !!error;

  const rowsToUse =
    isFetching || hasNoResults
      ? generateEmptyRows<BasicViolation>(paginationModel.pageSize)
      : data?.data.violations;

  const columns = useIssuesColumns();
  const emptyColumns = useEmptyColumns();

  const columnsToUse = isFetching || hasNoResults ? emptyColumns : columns;

  return (
    <DataGridPro
      {...tableProps}
      rows={rowsToUse ?? []}
      onRowClick={onRowClickHandler}
      columns={columnsToUse}
      rowCount={rowsCount}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
};
