import React from "react";
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalContent,
  Button,
  Typography,
} from "@komodorio/design-system/deprecated";

import { useFreemiumModalsUpdate } from "../../../../shared/hooks/useFreemiumModals";
import { openPlansPage } from "../../../../shared/utils/freemium";

export const LimitedCapabilitiesModal: React.FC<{
  modalTitle: string;
  modalContent: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ modalTitle, modalContent, onClick }) => {
  const setFreemiumModal = useFreemiumModalsUpdate();

  const closeModal = () => {
    setFreemiumModal && setFreemiumModal(null);
  };

  return (
    <Modal isOpen={true} width="26.25rem" onRequestClose={closeModal}>
      <ModalHeader>
        <Typography variant="headline" size="small">
          {modalTitle}
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Typography variant="text" size="medium">
          {modalContent}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button variant="primary" onClick={onClick || openPlansPage}>
          {onClick ? "Contact Us" : "Upgrade"}
        </Button>
      </ModalActions>
    </Modal>
  );
};
