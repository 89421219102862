import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacCustomK8sActionsPostUrl,
  RbacCustomK8sAction,
  CreateRbacCustomK8sActionPayload,
} from "../../../../../generated/auth";

const RBAC_POLICY_ACTIONS_PATH = "/rbac-custom-k8s-actions";

const createRbacCustomK8sAction = async (
  apiClient: AxiosInstance,
  params: CreateRbacCustomK8sActionPayload
): Promise<RbacCustomK8sAction> => {
  const { data } = await apiClient.post(
    apiV1RbacCustomK8sActionsPostUrl(
      { createRbacCustomK8sActionPayload: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCreateRbacCustomK8sAction = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [RBAC_POLICY_ACTIONS_PATH],
    (params: CreateRbacCustomK8sActionPayload) =>
      createRbacCustomK8sAction(apiClient, params)
  );
};
