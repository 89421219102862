import React from "react";
import { palette } from "@komodorio/design-system";
import {
  Divider,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";

import { InsightTooltipContainer, InsightTooltipDetails } from "../../Insight";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DemoImpactedServicesData } from "../DemoInsights";

export const DEMO_IMPACTED_SERVICES_TOOLTIP = "demo-impacted-services-tooltip";

export const ImpactedServicesTooltip: React.FC<{
  demoImpactedServicesData: DemoImpactedServicesData[];
}> = ({ demoImpactedServicesData }) => {
  return (
    <Tooltip id={DEMO_IMPACTED_SERVICES_TOOLTIP} variant="light">
      <InsightTooltipContainer>
        {demoImpactedServicesData.map((demoService, i) => (
          <React.Fragment key={demoService.service}>
            <InsightTooltipDetails>
              <Typography>Started</Typography>
              <Typography bold>
                {demoService.started} before current issue
              </Typography>
              <Typography>Namespace</Typography>
              <Typography bold>{demoService.namespace}</Typography>
              <Typography>Service</Typography>
              <Typography bold>{demoService.service}</Typography>
              <Typography>Reason</Typography>
              <Typography bold>{demoService.reason}</Typography>
              <Typography>Status</Typography>
              <Typography variant="uppercase" color={palette.pink[600]}>
                {demoService.status}
              </Typography>
            </InsightTooltipDetails>
            {i < demoImpactedServicesData.length - 1 && (
              <Divider spacing="1rem" />
            )}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
