import React, { useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Divider from "@mui/material/Divider";

import { CertManagerRoutes } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { CertManagerPageRoutes } from "@/components/k8sAddons/addons/CertManager/certManagerTypes";

const Container = styled.div`
  margin: 8px 0 16px 0;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: -1px;
  }
`;

export const CertManagerTabs: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isRouteSelected = (route: CertManagerPageRoutes) =>
    location.pathname.includes(route);

  const tabValue = useMemo(() => {
    if (location.pathname.includes(CertManagerRoutes.controllers))
      return CertManagerRoutes.controllers;

    return CertManagerRoutes.certificates;
  }, [location.pathname]);

  const onTabChange = (_: React.SyntheticEvent, newValue: string) => {
    if (isRouteSelected(newValue as CertManagerPageRoutes)) return;
    navigate(newValue);
  };

  return (
    <Container>
      <TabContext value={tabValue}>
        <TabList onChange={onTabChange}>
          <Tab
            label="Certificates"
            value={CertManagerRoutes.certificates}
            aria-label={`${CertManagerRoutes.certificates} tab`}
          />
          <Tab
            label="Controllers"
            value={CertManagerRoutes.controllers}
            aria-label={`${CertManagerRoutes.controllers} tab`}
          />
        </TabList>
      </TabContext>
      <StyledDivider />
    </Container>
  );
};
