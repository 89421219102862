import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { Dictionary } from "../../types/Dictionary";
import {
  AppViewsResources,
  Resource,
  ResourcesClassState,
  ResourcesTags,
  ServiceKindState,
  UpdateResourcesPayload,
} from "../../../components/appView/sections/AppView/types/appViewsResourcesTypes";

export const resourcesClassState: ResourcesClassState = {
  configmaps: {},
  secrets: {},
  persistentvolumeclaims: {},
};

export const resourceServiceKinds: ServiceKindState = {
  deployments: {},
  statefulsets: {},
  daemonsets: {},
  rollouts: {},
  jobs: {},
  cronjobs: {},
};

export type AppViewsResourcesState = {
  resources: AppViewsResources;
  isLoadingResources: boolean;
  tags: ResourcesTags;
  clusters: Set<string>;
};

export type AppViewsResourcesActions = {
  updateResources: (payload: UpdateResourcesPayload) => void;
  setTags: (tags: ResourcesTags) => void;
  setClusters: (clusters: Set<string>) => void;
  resetResources: (options?: { isLoadingResources: boolean }) => void;
};

export type AppViewsResourcesStore = AppViewsResourcesState &
  AppViewsResourcesActions;

export const initialState: AppViewsResourcesState = {
  resources: {
    ...resourcesClassState,
    ...resourceServiceKinds,
    horizontalpodautoscalers: {},
    pods: {},
    nodes: {},
    ingresses: {},
    services: {},
  },
  isLoadingResources: true,
  tags: {
    cluster: new Set(),
    namespace: new Set(),
  },
  clusters: new Set(),
};

export const useAppViewsResourcesStore = create(
  immer<AppViewsResourcesStore>((set) => ({
    ...initialState,
    updateResources: (payload) => {
      const { type, resources } = payload;
      set((state) => {
        state.resources[type] = resources.reduce<Dictionary<Resource>>(
          (acc, resource) => ({
            ...acc,
            [resource.id]: resource,
          }),
          {}
        );

        const hasResources = Object.values(state.resources).some((resource) => {
          return Object.values(resource).length > 0;
        });
        state.isLoadingResources = !hasResources;
      });
    },
    setTags: (tags) => {
      set((state) => {
        state.tags = tags;
      });
    },
    setClusters: (clusters) => {
      set((state) => {
        state.clusters = clusters;
      });
    },
    resetResources: (options?: { isLoadingResources: boolean }) => {
      set((state) => {
        state.resources = initialState.resources;
        state.isLoadingResources =
          options !== undefined
            ? options.isLoadingResources
            : initialState.isLoadingResources;
        state.tags = initialState.tags;
        state.clusters = initialState.clusters;
      });
    },
  }))
);

export const resetAppViewsResourcesStore = (): void => {
  const { resources, isLoadingResources, tags, clusters } = initialState;
  useAppViewsResourcesStore.setState({
    isLoadingResources,
    tags,
    clusters,
    resources,
  });
};
