import { useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { KOMODOR_LOADING_TIME } from "../../../../../../../shared/constants/datadog";
import {
  getViolationDrawerViewName,
  historyTab,
} from "../../../../../constants/dataDogReporting";
import { useCurrentDrawerState } from "../../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../../shared/store/drawersStackStore/types";
import { ViolationDrawerValue } from "../violationDrawerTypes";

import { useGetCurrentTab } from "./useGetCurrentTab";

export const useReportDrawerStartView = () => {
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();
  const currentDrawerTab = useGetCurrentTab();
  const lastTabReported = useRef<ViolationDrawerValue | null>(null);

  useEffect(() => {
    if (lastTabReported.current === currentDrawerTab) {
      return;
    }
    lastTabReported.current = currentDrawerTab;
    if (currentDrawerTab === "history") {
      datadogRum.startView(historyTab);
      return;
    }

    if (currentDrawerState?.checkType) {
      datadogRum.startView(
        getViolationDrawerViewName(currentDrawerState.checkType)
      );
    }
  }, [currentDrawerState?.checkType, currentDrawerTab]);
};

export const useReportDrawerLoadingTime = (loadingIsComplete: boolean) => {
  const hasReportedLoadingTime = useRef(false);
  useEffect(() => {
    if (hasReportedLoadingTime.current) return;
    if (loadingIsComplete) {
      // if response is cached, add timout to prevent loading time from being reported before start view
      hasReportedLoadingTime.current = true;
      setTimeout(() => {
        datadogRum.addTiming(KOMODOR_LOADING_TIME);
      }, 0);
    }
  }, [loadingIsComplete]);
};
