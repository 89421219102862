import { Dictionary } from "@/shared/types/Dictionary";
import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { useFiltersInUrlContext } from "@/shared/context/filtersInUrlContext/useFiltersInUrlContext";

export type FilterOptionInUrl = {
  values: string[];
  operator: string;
};

export type FiltersStateInUrl = {
  filters?: Dictionary<FilterOptionInUrl[]>;
};

export const useStateInUrl = () => {
  const { urlParamKey } = useFiltersInUrlContext();
  return useStateInUrlAsJSON<FiltersStateInUrl>(urlParamKey);
};
