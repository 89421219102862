import { useCallback } from "react";

import {
  SocketState,
  useWebSocketContext,
} from "../../../shared/context/WebSocketsProvider";
import { getTerminalInstance } from "../terminalInstance";
import {
  SocketMessageType,
  SocketMessagePayload,
} from "../../../shared/sockets/types";
import { extractPayloadFromMessage } from "../../../shared/sockets/utils";

import { useTerminalResize } from "./useTerminalResize";

interface Args {
  identifier: string;
  closeKeepAliveSession: () => void;
}

type UseHandleIncomingSocketMessage = (
  message: MessageEvent<SocketMessagePayload>
) => void;

export const useHandleIncomingSocketMessage = ({
  identifier,
  closeKeepAliveSession,
}: Args): UseHandleIncomingSocketMessage => {
  const sockets = useWebSocketContext();
  const onTerminalResized = useTerminalResize(identifier);

  const handleIncomingMessage = useCallback(
    (message: MessageEvent<SocketMessagePayload>) => {
      const {
        messageType,
        sessionId: lastReceivedSessionId,
        data,
      } = extractPayloadFromMessage(message);

      const { sessionId } = sockets.getSocketContext(identifier) || {};

      switch (messageType) {
        case SocketMessageType.Ack:
          if (lastReceivedSessionId && !sessionId) {
            sockets.addContextToSocket(identifier, {
              sessionId: lastReceivedSessionId,
              keepAliveState: SocketState.Alive,
            });

            const existingTerminal = getTerminalInstance(identifier);
            existingTerminal &&
              onTerminalResized({
                cols: existingTerminal.cols,
                rows: existingTerminal.rows,
              });
          }

          break;

        case SocketMessageType.Stdout:
          if (data && data.out) {
            const terminal = getTerminalInstance(identifier);

            terminal?.write(data.out);
          }

          break;

        case SocketMessageType.Error:
          if (data && data.errorMessage) {
            const terminal = getTerminalInstance(identifier);

            terminal?.write("Received error: ");
            terminal?.write(data.errorMessage);
            terminal?.writeln("");
          }

          break;

        case SocketMessageType.Termination:
          // eslint-disable-next-line no-case-declarations
          const terminal = getTerminalInstance(identifier);

          if (terminal) {
            terminal.options.disableStdin = true;
            terminal.options.cursorBlink = false;

            terminal.writeln("");
            terminal.write("The session was terminated: ");
            if (data?.exitMessage) {
              terminal.write(data.exitMessage);
            }

            terminal.writeln("");
          }

          closeKeepAliveSession();
          sockets.addContextToSocket(identifier, {
            keepAliveState: SocketState.Died,
          });

          break;
      }
    },
    [sockets, identifier, closeKeepAliveSession, onTerminalResized]
  );

  return handleIncomingMessage;
};
