import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { NodesCountsApiApiV1NodesCountsGetRequest } from "accounts-api";

import { useAccountsApiClient } from "@/shared/hooks/accounts-service/client/apiClient";
import { apiV1NodesCountsGetUrl, NodeCount } from "@/generated/accounts";

const NODES_COUNT_PATH = "/nodes-counts";

const getNodesCounts = async (
  apiClient: AxiosInstance,
  params: NodesCountsApiApiV1NodesCountsGetRequest = {}
): Promise<NodeCount[]> => {
  const { data } = await apiClient.get(
    apiV1NodesCountsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNodesCounts = (
  params?: NodesCountsApiApiV1NodesCountsGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAccountsApiClient();
  return useQuery(
    [NODES_COUNT_PATH, params],
    () => getNodesCounts(apiClient, params),
    {
      enabled,
    }
  );
};
