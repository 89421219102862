import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { TimeWindow } from "@komodorio/design-system/komodor-ui";

import {
  EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest,
  PdbEventsResponse,
  PdbEventsResponseDataInner,
  apiV1ConfigurationsPdbsEventsSearchGetUrl,
} from "../../../../../../generated/resourcesApi";
import { useDataForFlag } from "../../../../useDataForFlag";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";
import { CONFIGURATION_PDBS_EVENT_SEARCH } from "../../../requestResponseMaps";
import { useResourcesApiClient } from "../../apiClient";

const fetchPdbEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest
): Promise<PdbEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1ConfigurationsPdbsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const usePdbEvents = (
  showPdbEvent: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">
): PdbEventsResponseDataInner[] | null => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([
    CONFIGURATION_PDBS_EVENT_SEARCH,
    timeWindow,
  ]);
  const { data } = useQuery(
    keys,
    () =>
      fetchPdbEvents(apiClient, {
        fromEpoch: timeWindow.start.getTime().toString(),
        toEpoch: timeWindow.end.getTime().toString(),
        fields: [
          "id",
          "name",
          "namespace",
          "clusterName",
          "eventTime",
          "services",
          "action",
        ],
      }),
    { enabled: showPdbEvent === true }
  );

  return useDataForFlag(showPdbEvent, data?.data);
};
