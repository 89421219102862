import {
  FilterOutMetricsMapper,
  MetricType,
  MetricName,
  MetricsAggregationType,
  metricsTypeToName,
  STATIC_METRICS,
} from "../../../Metrics/types";

export const getTitle = (type: MetricType) =>
  type === "cpu" ? "Cpu Usage" : "Memory Usage";

export const getLineList = (
  selectedAggregationMetric: MetricsAggregationType,
  filterOutMetricsMapper?: FilterOutMetricsMapper
): MetricName[] => {
  const aggregationMetricsToName = metricsTypeToName[selectedAggregationMetric];

  if (!aggregationMetricsToName) return STATIC_METRICS;

  const metricsList = [...STATIC_METRICS, aggregationMetricsToName];

  return filterOutMetricsMapper
    ? metricsList.filter((metric) => !filterOutMetricsMapper[metric])
    : metricsList;
};
