import { muiColors } from "@komodorio/design-system";
import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Warning from "@mui/icons-material/Warning";

import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { CloudProvider } from "../../../../shared/components/CloudProvider/CloudProvider";
import { getK8sVersionShort } from "../../utils";
import {
  AdditionalInfo,
  ClusterSummary,
  ClusterProviderType,
} from "../../../../generated/workspacesApi";

import { UpgradeCommandButton } from "@/components/ClustersView/ClusterOverview/components/UpgradeCommandButton";

const ClusterNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

const Container = styled.div`
  padding-block: 16px;
  background-color: ${muiColors.common.white};
`;

const EOLIndication = ({
  approachingEOL,
  reachedEOL,
}: {
  approachingEOL: AdditionalInfo["approachingEol"];
  reachedEOL: AdditionalInfo["reachedEol"];
}) => {
  if (!approachingEOL && !reachedEOL) {
    return null;
  }

  return (
    <>
      <Warning
        sx={{ width: "20px", height: "20px", color: muiColors.pink[600] }}
      />
      <Typography variant="h5" sx={{ color: muiColors.pink[600] }}>
        {approachingEOL ? "Approaching EOL" : "Reached EOL"}
      </Typography>
    </>
  );
};

interface ClusterViewHeaderProps {
  cluster: ClusterSummary;
}
export const ClusterViewHeader: React.FC<ClusterViewHeaderProps> = ({
  cluster,
}) => {
  return (
    <Container>
      <ResponsiveLayout>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
          <ClusterNameContainer>
            <Typography variant="h3" color="text.primary">
              {cluster.clusterName}
            </Typography>
            {!cluster.k8sVersion && <UpgradeCommandButton />}
          </ClusterNameContainer>
          {cluster.k8sVersion && (
            <Box sx={{ display: "flex", columnGap: "32px" }}>
              <Metadata
                title="Provider"
                value={
                  <CloudProvider
                    clusterProviderType={
                      cluster.provider as ClusterProviderType
                    }
                  />
                }
              />
              <Metadata
                title="Kubernetes Version"
                value={
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" color="text.primary">
                      {getK8sVersionShort(cluster.k8sVersion)}
                    </Typography>
                    <EOLIndication
                      approachingEOL={cluster.additionalInfo.approachingEol}
                      reachedEOL={cluster.additionalInfo.reachedEol}
                    />
                  </Box>
                }
              />
              {cluster.region && (
                <Metadata title="Region" value={cluster.region} />
              )}
            </Box>
          )}
        </Box>
      </ResponsiveLayout>
    </Container>
  );
};
