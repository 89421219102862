import React from "react";
import { Button } from "@komodorio/design-system/deprecated";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GenericErrorMessage } from "../../../../../../shared/components/GenericErrorMessage";

const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100vh;
`;

export const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ColumnFlexContainer>
      <GenericErrorMessage />
      <Button
        variant="primary"
        size="small"
        onClick={() => {
          navigate("/");
        }}
      >
        Back to services
      </Button>
    </ColumnFlexContainer>
  );
};
