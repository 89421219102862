import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";

import {
  AvailabilityRunWithResults,
  IStep,
} from "../../../components/InvestigationMode/types";
import {
  AvailabilityInvestigationCheckType,
  AvailabilityInvestigationIssueType,
} from "../../../components/InvestigationMode/availability/types";
import { Metrics } from "../../../components/Metrics/types";
import { Deploy } from "../../../generated/monitorsApi";
import { AvailableActions } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { MemoryAllocationData } from "../../../components/InvestigationMode/availability/ReviewMemoryAllocation/ReviewMemoryAllocationDataFetch";
import Deployment from "../../../components/ResourceView/resources/deployment";
import StatefulSet from "../../../components/ResourceView/resources/statefulset";
import DaemonSet from "../../../components/ResourceView/resources/daemonset";
import Rollout from "../../../components/ResourceView/resources/rollout";

export type AvailabilityResource =
  | Deployment
  | StatefulSet
  | DaemonSet
  | Rollout;
export type InvestigationModeState = {
  issue: AvailabilityRunWithResults | undefined;
  lastUrl: string | undefined;
  komodorService: AvailabilityResource | undefined;
  isFetchingKomodorService: boolean;
  steps: IStep[];
  correlatedMetrics: Metrics | undefined;
  isMetricsSupported: boolean | null;
  showResourceDrawerCallback: (() => void) | undefined;
  showResourceDrawer: boolean;
  showTriageDrawer: boolean;
  deployForRollback: Deploy | undefined;
  availableActions: AvailableActions[];
  reviewMemoryAllocationData: MemoryAllocationData | undefined;
  noisyNeighborsNodesMetrics: Map<string, Metrics> | undefined;
  issueType: AvailabilityInvestigationIssueType | undefined;
};

export type InvestigationModeActions = {
  setIssue: (issue: AvailabilityRunWithResults | undefined) => void;
  setLastUrl: (url: string | undefined) => void;
  setKomodorService: (komodorService: AvailabilityResource) => void;
  setIsFetchingKomodorService: (isFetching: boolean) => void;
  setSteps: (steps: IStep[]) => void;
  setStepVisited: (
    stepType: AvailabilityInvestigationCheckType,
    visited: boolean
  ) => void;
  setCorrelatedMetrics: (metrics: Metrics) => void;
  setIsMetricsSupported: (isMetricsSupported: boolean | null) => void;
  setShowResourceDrawerCallback: (callback: () => void) => void;
  setShowResourceDrawer: (showResourceDrawer: boolean) => void;
  setShowTriageDrawer: (showTriageDrawerSelector: boolean) => void;
  setDeployForRollback: (deployForRollback: Deploy | undefined) => void;
  setAvailableActions: (availableActions: AvailableActions[]) => void;
  addAvailableAction: (availableAction: AvailableActions) => void;
  setReviewMemoryAllocation: (data: MemoryAllocationData) => void;
  setNoisyNeighborsNodesMetrics: (nodesMetrics: Map<string, Metrics>) => void;
  setIssueType: (issueType: AvailabilityInvestigationIssueType) => void;
};

export type InvestigationModeStore = InvestigationModeState &
  InvestigationModeActions;

export const initialState: InvestigationModeState = {
  issue: undefined,
  lastUrl: undefined,
  komodorService: undefined,
  isFetchingKomodorService: false,
  steps: [],
  correlatedMetrics: undefined,
  isMetricsSupported: false,
  showResourceDrawerCallback: undefined,
  showResourceDrawer: false,
  deployForRollback: undefined,
  availableActions: [],
  reviewMemoryAllocationData: undefined,
  noisyNeighborsNodesMetrics: undefined,
  showTriageDrawer: false,
  issueType: undefined,
};

export const useInvestigationModeStore = create(
  persist(
    immer<InvestigationModeStore>((set, get) => ({
      ...initialState,
      setIssue: (issue: AvailabilityRunWithResults | undefined) => {
        set({ issue });
      },
      setLastUrl: (url: string | undefined) => {
        set({ lastUrl: url });
      },
      setKomodorService: (komodorService: AvailabilityResource | undefined) => {
        set({ komodorService });
      },
      setIsFetchingKomodorService: (isFetchingKomodorService: boolean) => {
        set({ isFetchingKomodorService });
      },
      setSteps: (steps: IStep[]) => {
        set({ steps });
      },
      setStepVisited: (
        stepType: AvailabilityInvestigationCheckType,
        visited: boolean
      ) => {
        set((state) => {
          const stepIndex = state.steps.findIndex(
            (step) => step.type === stepType
          );
          if (state.steps[stepIndex]) {
            state.steps[stepIndex].visited = visited;
          }
        });
      },
      setCorrelatedMetrics: (metrics: Metrics) => {
        set({ correlatedMetrics: metrics });
      },
      setIsMetricsSupported: (isMetricsSupported: boolean | null) => {
        set({ isMetricsSupported });
      },
      setShowResourceDrawerCallback: (callback: () => void) => {
        set({ showResourceDrawerCallback: callback });
      },
      setShowResourceDrawer: (showResourceDrawer: boolean) => {
        set({ showResourceDrawer });
      },
      setDeployForRollback: (deployForRollback: Deploy | undefined) => {
        set({ deployForRollback });
      },
      setAvailableActions: (availableActions: AvailableActions[]) => {
        set({ availableActions });
      },
      addAvailableAction: (availableAction: AvailableActions) => {
        set((state) => {
          state.availableActions = [
            ...new Set(state.availableActions.concat(availableAction)),
          ];
        });
      },
      setReviewMemoryAllocation: (data: MemoryAllocationData) => {
        set({ reviewMemoryAllocationData: data });
      },
      setNoisyNeighborsNodesMetrics: (nodesMetrics: Map<string, Metrics>) => {
        set({ noisyNeighborsNodesMetrics: nodesMetrics });
      },
      setShowTriageDrawer: (showTriageDrawer: boolean) => {
        set({ showTriageDrawer });
      },
      setIssueType: (issueType: AvailabilityInvestigationIssueType) => {
        set({ issueType });
      },
    })),
    {
      name: "@komodor/investigationModeState",
      partialize: (state) => ({ lastUrl: state.lastUrl }),
    }
  )
);

export const resetInvestigationModeStore = (): void => {
  useInvestigationModeStore.setState({
    ...initialState,
  });
};
