import React, { createContext, useContext } from "react";

import { ClustersConnectionsStatus } from "../electronApiContext";
import { useDesktopClustersConnection } from "../hooks/useDesktopClustersConnection";

const DesktopClustersConnectionContext =
  createContext<ClustersConnectionsStatus | null>(null);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useDesktopClustersConnectionContext =
  (): ClustersConnectionsStatus | null => {
    return useContext(DesktopClustersConnectionContext);
  };

export const DesktopClustersConnectionProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const desktopClustersConnection = useDesktopClustersConnection();
  return (
    <DesktopClustersConnectionContext.Provider
      value={desktopClustersConnection}
    >
      {children}
    </DesktopClustersConnectionContext.Provider>
  );
};
