import React, { useState } from "react";
import { get } from "lodash";

import DescribeModal from "../modals/DescribeModal/DescribeModal";

import { ActionButtonProps } from "./types";

export const DescribeButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);
  const [startMeasureLatency, setStartMeasureLatency] = useState<
    number | undefined
  >(0);
  const lastDeployEndDate = get(resource, "lastDeployEndDate");

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  return (
    <>
      {startMeasureLatency !== undefined && (
        <>
          <Button onClick={handleClick} />
          <DescribeModal
            isOpen={open}
            handleClose={handleClose}
            resourceId={resource.id}
            resourceName={resource.name}
            resourceType={resource.kind}
            namespace={resource.namespace}
            cluster={resource.cluster}
            agentId={resource.agentId}
            startMeasureLatency={startMeasureLatency}
            setStartMeasureLatency={setStartMeasureLatency}
            lastDeployEndDate={lastDeployEndDate}
          />
        </>
      )}
    </>
  );
};
