import React, { useMemo } from "react";
import { Folder16 } from "@komodorio/design-system/icons";

import { AppBarTitle } from "@/components/AppBar/constants";
import { HelmLink } from "@/components/AppBar/links/Helm";
import { K8sAddonCertManager } from "@/components/AppBar/links/K8sAddonCertManager";
import { LinkGroup } from "@/components/AppBar/linkGroups/LinkGroup";
import { WorkflowsClustersDemoV3Link } from "@/components/AppBar/links/ClustersDemoV3";
import { WorkflowsLink } from "@/components/AppBar/links/Workflows";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  AddonsDemoCertManagerLink,
  AddonsDemoExternalDNSLink,
  AddonsDemoNodeAutoscalersLink,
} from "@/components/AppBar/links/AddonsDemo";
import { ExternalDNSLink } from "@/components/AppBar/links/ExternalDNS";
import { NodeAutoscalersLink } from "@/components/AppBar/links/NodeAutoscalers";

export const K8sAddonsMenu = () => {
  const { showWorkflows, showClustersPageDemoV3, showCertManagerAddon } =
    useOverridableFlags();

  const WorkflowsLinkToRender = useMemo(() => {
    if (showClustersPageDemoV3) {
      return WorkflowsClustersDemoV3Link;
    }
    if (showWorkflows) {
      return WorkflowsLink;
    }

    return () => null;
  }, [showClustersPageDemoV3, showWorkflows]);

  return (
    <LinkGroup
      title={AppBarTitle.K8sAddons}
      icon={<Folder16 />}
      e2eSelector="k8sAddonsExpandableSectionTitle"
      alwaysExpanded
    >
      {Boolean(showClustersPageDemoV3) && (
        <>
          <AddonsDemoNodeAutoscalersLink />
          <AddonsDemoExternalDNSLink />
          <AddonsDemoCertManagerLink />
        </>
      )}
      <HelmLink />
      {showClustersPageDemoV3 === false && !!showCertManagerAddon && (
        <K8sAddonCertManager />
      )}
      <WorkflowsLinkToRender />
      {!showClustersPageDemoV3 && (
        <>
          <ExternalDNSLink />
          <NodeAutoscalersLink />
        </>
      )}
    </LinkGroup>
  );
};
