import {
  StyledOrderedList,
  TextContainer,
} from "../../../violationDrawerStyles";

export const getWhatDescription = () => (
  <TextContainer>The following containers CPU was throttled:</TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Setting improper resource requests in Kubernetes is a problem. If requests
    are misconfigured, Kubernetes can't distribute resources efficiently. This
    can lead to problems like resource shortages, making applications
    unexpectedly slow or even causing crashes across your cluster.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    <StyledOrderedList>
      <li>
        <TextContainer>
          <b>Adjust Resource Requests and Limits</b> - Review the resource
          requests and limits set for your pods, adjust the requests and limits
          configurations workload requirements and usage.
        </TextContainer>
        <br />
      </li>
      <li>
        <TextContainer>
          <b>Horizontal Pod Autoscaling (HPA)</b> - Consider using Horizontal
          Pod Autoscaling to automatically adjust the number of replicas based
          on resource usage. This can help distribute the workload more
          efficiently. If you are already using an HPA, consider increasing the
          number of maximum replicas.
        </TextContainer>
        <br />
      </li>
    </StyledOrderedList>
  </TextContainer>
);
