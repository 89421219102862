import React from "react";

import { AppBarLink } from "./AppBarLink";
import { PageName, usePages } from "./pages";

import { AriaLabels } from "@/shared/config/ariaLabels";

const name = PageName.WorkloadHealth;

export const WorkloadHealth: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      ariaLabel={AriaLabels.AppBar.WorkloadHealth}
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
    />
  ) : null;
};
