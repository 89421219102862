import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../generated/metricsApi";

export interface GetRecommendationByStrategyResult {
  cpuRecommendation: number;
  memoryRecommendation: number;
}

export const getRecommendationByStrategy = (
  containerRecommendation: RightSizingCostSummaryByService,
  strategy: OptimizationStrategy
): GetRecommendationByStrategyResult => {
  switch (strategy) {
    case OptimizationStrategy.Moderate:
      return {
        cpuRecommendation:
          containerRecommendation.cpuMiliCoreRecommendationModerate,
        memoryRecommendation:
          containerRecommendation.memoryRequestMiBRecommendationModerate,
      };

    case OptimizationStrategy.Aggressive:
      return {
        cpuRecommendation:
          containerRecommendation.cpuMiliCoreRecommendationAggressive,
        memoryRecommendation:
          containerRecommendation.memoryRequestMiBRecommendationAggressive,
      };

    case OptimizationStrategy.Conservative:
      return {
        cpuRecommendation:
          containerRecommendation.cpuMiliCoreRecommendationConservative,
        memoryRecommendation:
          containerRecommendation.memoryRequestMiBRecommendationConservative,
      };
  }
};
