import React from "react";
import styled from "styled-components";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { IgnoreRulesTableOverrides } from "../ignoreChecksStyles";
import {
  useIgnoreRulesOverviewColumns,
  useIgnoreRulesOverviewRows,
  useLoadingTableData,
} from "../ignoreChecksOverviewHooks";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";

const TableContainer = styled.div`
  background-color: white;
  width: 100%;

  && {
    ${IgnoreRulesTableOverrides};

    .MuiDataGrid-columnHeader {
      background-color: white;
    }
  }
`;

const {
  ignoreChecks: { overviewTable: overviewTableAriaLabel },
} = reliabilityArialLabels;

const commonTableProps = {
  hideFooter: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  disableColumnFilter: true,
  rowHeight: 78,
};

export const IgnoreChecksOverviewTable: React.FC = () => {
  const rows = useIgnoreRulesOverviewRows();
  const columns = useIgnoreRulesOverviewColumns();

  return (
    <TableContainer>
      {!rows?.length ? (
        <LoadingTable />
      ) : (
        <DataGridPro
          columns={columns}
          rows={rows}
          {...commonTableProps}
          aria-label={overviewTableAriaLabel}
        />
      )}
    </TableContainer>
  );
};

const LoadingTable: React.FC = () => {
  const { loadingColumns, loadingRows } = useLoadingTableData();
  return (
    <DataGridPro
      aria-label={`${overviewTableAriaLabel} loading`}
      columns={loadingColumns}
      rows={loadingRows}
      {...commonTableProps}
    />
  );
};
