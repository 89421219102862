import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../../generated/metricsApi";
import { RecommendationChange } from "../RecommendationChange/RecommendationChange";
import { RowsContent } from "../../types";
import { costOptimizationAriaLabels } from "../../../../../constants/costOptimizationAriaLabels";

import { getChangeSummary } from "./getChangeSummary";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  background-color: ${muiColors.gray[50]};
`;

const SubtitlesPlacement = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  align-items: center;
  padding: 0px 16px 0px 48px;
`;

const ContainersGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: inherit;
`;

const Subtitle = styled(Typography).attrs({ variant: "body2" })``;

const TotalSubtitle = styled(Subtitle).attrs({ align: "right" })``;

const {
  rightSizingPage: {
    recommendationModal: {
      rowsContainer: rowsContainerAriaLabel,
      containers: {
        recommendationsSummary: recommendationsSummaryAriaLabel,
        containerGroup: containerGroupAriaLabel,
      },
    },
  },
} = costOptimizationAriaLabels;

interface Props {
  containers: RowsContent;
  containersRecommendationsData: RightSizingCostSummaryByService[] | undefined;
  strategy: OptimizationStrategy;
}

export const ContainersWrapper: React.FC<Props> = ({
  containers,
  containersRecommendationsData,
  strategy,
}) => {
  const totalData = useMemo(
    () => getChangeSummary(containersRecommendationsData, strategy),
    [containersRecommendationsData, strategy]
  );

  if (!totalData) return <Container>{containers}</Container>;

  const { previousCpu, previousMemory, newCpu, newMemory } = totalData;
  return (
    <Container aria-label={rowsContainerAriaLabel}>
      <SubtitlesPlacement>
        <Subtitle>Container</Subtitle>
        <Subtitle>CPU Request</Subtitle>
        <Subtitle>Memory Requests</Subtitle>
      </SubtitlesPlacement>
      <ContainersGroup aria-label={containerGroupAriaLabel}>
        {containers}
      </ContainersGroup>
      <SubtitlesPlacement aria-label={recommendationsSummaryAriaLabel}>
        <TotalSubtitle>Total:</TotalSubtitle>
        <RecommendationChange
          unit="millicores"
          current={previousCpu}
          recommendation={newCpu}
          shouldShowRecommendation={newCpu < previousCpu}
        />
        <RecommendationChange
          unit="MiB"
          current={previousMemory}
          recommendation={newMemory}
          shouldShowRecommendation={newMemory < previousMemory}
        />
      </SubtitlesPlacement>
    </Container>
  );
};
