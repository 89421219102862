import { muiColors } from "@komodorio/design-system";

import { HeatmapColorScheme } from "./heatmapTypes";

export const defaultHeatmapColorScheme: HeatmapColorScheme[] = [
  { maxValue: 0, color: muiColors.gray[100] },
  { maxValue: 20, color: muiColors.orange[100] },
  { maxValue: 40, color: muiColors.orange[300] },
  { maxValue: 60, color: muiColors.orange[600] },
  { maxValue: 80, color: muiColors.red[700] },
  { maxValue: 100, color: muiColors.red[800] },
];
