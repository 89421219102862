import semver from "semver";

export const versionGt = (v1: string, v2: string): boolean => {
  return semver.valid(v1) && semver.valid(v2)
    ? semver.gt(v1, v2)
    : !isNaN(Number(v1)) && !isNaN(Number(v2))
    ? Number(v1) > Number(v2)
    : v1.localeCompare(v2) === 1;
};

export const versionEq = (v1: string, v2: string): boolean => {
  return semver.valid(v1) && semver.valid(v2)
    ? semver.eq(v1, v2)
    : !isNaN(Number(v1)) && !isNaN(Number(v2))
    ? Number(v1) === Number(v2)
    : v1.localeCompare(v2) === 0;
};

export const versionLt = (v1: string, v2: string): boolean => {
  return semver.valid(v1) && semver.valid(v2)
    ? semver.lt(v1, v2)
    : !isNaN(Number(v1)) && !isNaN(Number(v2))
    ? Number(v1) < Number(v2)
    : v1.localeCompare(v2) === -1;
};
