/* eslint-disable new-cap */
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useState } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  InfraHealth24,
  WorkloadHealth24,
  Health24,
} from "@komodorio/design-system/icons";

import { SectionAccordion } from "../../styles";
import { SectionAccordionSummary } from "../SectionAccordionSummary";
import { SectionTitle } from "../SectionTitle";

import {
  HEALTH_SECTION_EMPTY_MESSAGE,
  HEALTH_SECTION_INFRA_EMPTY_MESSAGE,
  HEALTH_SECTION_TITLE,
  HEALTH_SECTION_WORKLOADS_EMPTY_MESSAGE,
} from "./constants";
import { ImpactGroupStatsSection } from "./ImpactGroupStatsSection";
import { IssuesBarChart } from "./IssuesBarChart";
import { ReliablityRisksSection } from "./ReliablityRisksSection";
import {
  useIssuesOverTimeData,
  useRealtimeIssues,
  useReliabilityRisks,
} from "./hooks";
import { InfraRealtimeIssuesSection } from "./InfraRealtimeIssuesSection";
import { SummaryChip } from "./SummaryChip";
import { WorkloadRealtimeIssuesSection } from "./WorkloadRealtimeIssuesSection";

import { CheckCategory, ImpactGroupType } from "@/generated/reliabilityApi";
import { INFRA_HEALTH, WORKLOAD_HEALTH } from "@/components/routes/routes";
import { AriaLabels } from "@/shared/config/ariaLabels";

const StyledSectionTitle = styled(SectionTitle)`
  margin-top: 8px;
`;

const StyledIssuesBarChart = styled(IssuesBarChart)`
  margin-top: 8px;
`;

export const HealthSection: React.FC<{ clusters?: string[] }> = ({
  clusters,
}) => {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const workloadIssuesStats = useRealtimeIssues(
    CheckCategory.Workload,
    clusters
  );
  const workloadRelibilityRisks = useReliabilityRisks(
    CheckCategory.Workload,
    clusters
  );
  const infraIssuesStats = useRealtimeIssues(
    CheckCategory.Infrastructure,
    clusters
  );
  const infraRelibilityRisks = useReliabilityRisks(
    CheckCategory.Infrastructure,
    clusters
  );
  const workloadRealtimeOverTime = useIssuesOverTimeData(
    ImpactGroupType.Realtime,
    CheckCategory.Workload,
    clusters
  );
  const workloadRisksOverTime = useIssuesOverTimeData(
    ImpactGroupType.Dynamic,
    CheckCategory.Workload,
    clusters
  );
  const infraRealtimeOverTime = useIssuesOverTimeData(
    ImpactGroupType.Realtime,
    CheckCategory.Infrastructure,
    clusters
  );
  const infraRisksOverTime = useIssuesOverTimeData(
    ImpactGroupType.Dynamic,
    CheckCategory.Infrastructure,
    clusters
  );

  const isLoadingWorkloadData =
    workloadIssuesStats.isLoading || workloadRelibilityRisks.isLoading;
  const isLoadingInfraData =
    infraIssuesStats.isLoading || infraRelibilityRisks.isLoading;

  const isWorkloadsStatsEmpty =
    workloadRelibilityRisks.isEmpty && workloadIssuesStats.isEmpty;
  const isWorkloadGraphDataEmpty =
    !workloadRealtimeOverTime.isLoading &&
    workloadRealtimeOverTime.graphPoints.length === 0 &&
    !workloadRisksOverTime.isLoading &&
    workloadRisksOverTime.graphPoints.length === 0;

  const isInfraStatsEmpty =
    infraRelibilityRisks.isEmpty && infraIssuesStats.isEmpty;
  const isInfraGraphDataEmpty =
    !infraRealtimeOverTime.isLoading &&
    infraRealtimeOverTime.graphPoints.length === 0 &&
    !infraRisksOverTime.isLoading &&
    infraRisksOverTime.graphPoints.length === 0;

  const noData = isWorkloadsStatsEmpty && isInfraStatsEmpty;

  const totalWorkloadIssues =
    (workloadIssuesStats?.numOfViolations ?? 0) +
    (workloadRelibilityRisks.numOfViolations ?? 0);
  const totalInfraIssues =
    (infraRelibilityRisks.numOfViolations ?? 0) +
    (infraIssuesStats.numOfViolations ?? 0);

  return (
    <SectionAccordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={false}
    >
      <SectionAccordionSummary
        title={HEALTH_SECTION_TITLE}
        icon={<Health24 />}
        isExpanded={expanded}
        isLoading={false}
        isEmpty={noData}
        emptyMessage={HEALTH_SECTION_EMPTY_MESSAGE}
      >
        <Box display="flex" justifyContent="flex-end" flexGrow="1" gap="4px">
          <SummaryChip
            category={CheckCategory.Workload}
            isLoading={isLoadingWorkloadData}
            issuesCount={workloadIssuesStats.numOfViolations ?? 0}
            risksCount={workloadRelibilityRisks.numOfViolations ?? 0}
          />
          <SummaryChip
            category={CheckCategory.Infrastructure}
            isLoading={isLoadingInfraData}
            issuesCount={infraIssuesStats.numOfViolations ?? 0}
            risksCount={infraRelibilityRisks.numOfViolations ?? 0}
          />
        </Box>
      </SectionAccordionSummary>
      {noData ? null : (
        <AccordionDetails>
          <StyledSectionTitle
            title={
              "Workload Health" +
              (totalWorkloadIssues > 0
                ? ` (${Intl.NumberFormat().format(totalWorkloadIssues)})`
                : "")
            }
            emptyMessage={HEALTH_SECTION_WORKLOADS_EMPTY_MESSAGE}
            isEmpty={isWorkloadsStatsEmpty}
            icon={<WorkloadHealth24 />}
            isExpandable={false}
          >
            <Button
              aria-label={
                AriaLabels.ClusterOverview.HealthSection.Workloads.ViewAllButton
              }
              variant="outlined"
              size="small"
              onClick={() => navigate(`/${WORKLOAD_HEALTH}`)}
            >
              View All
            </Button>
          </StyledSectionTitle>
          {isWorkloadsStatsEmpty && isWorkloadGraphDataEmpty ? null : (
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="20px">
              <ImpactGroupStatsSection>
                <WorkloadRealtimeIssuesSection clusters={clusters} />
                <ReliablityRisksSection
                  checkCategory={CheckCategory.Workload}
                  clusters={clusters}
                />
              </ImpactGroupStatsSection>
              {isWorkloadsStatsEmpty ? null : (
                <IssuesBarChart
                  checkCategory={CheckCategory.Workload}
                  clusters={clusters}
                />
              )}
            </Box>
          )}
          <Divider
            variant="fullWidth"
            sx={{ marginBlock: "24px", marginInline: "-16px" }}
          />
          <StyledSectionTitle
            title={
              "Infrastructure Health" +
              (totalInfraIssues > 0
                ? ` (${Intl.NumberFormat().format(totalInfraIssues)})`
                : "")
            }
            emptyMessage={HEALTH_SECTION_INFRA_EMPTY_MESSAGE}
            isEmpty={isInfraStatsEmpty}
            icon={<InfraHealth24 />}
            isExpandable={false}
          >
            <Button
              aria-label={
                AriaLabels.ClusterOverview.HealthSection.Infra.ViewAllButton
              }
              variant="outlined"
              size="small"
              onClick={() => navigate(`/${INFRA_HEALTH}`)}
            >
              View All
            </Button>
          </StyledSectionTitle>
          {isInfraStatsEmpty && isInfraGraphDataEmpty ? null : (
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="20px">
              <ImpactGroupStatsSection>
                <InfraRealtimeIssuesSection clusters={clusters} />
                <ReliablityRisksSection
                  checkCategory={CheckCategory.Infrastructure}
                  clusters={clusters}
                />
              </ImpactGroupStatsSection>
              {isInfraStatsEmpty ? null : (
                <StyledIssuesBarChart
                  checkCategory={CheckCategory.Infrastructure}
                  clusters={clusters}
                />
              )}
            </Box>
          )}
        </AccordionDetails>
      )}
    </SectionAccordion>
  );
};
