import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import { User } from "@/generated/auth";
import {
  getFilteredUserRoles,
  getTimeLeft,
  sortByNameLength,
} from "@/components/Settings/Users/utils";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { ROLES_CELL_WIDTH } from "@/components/Settings/Users/const";
import { calculateFittingRoles } from "@/components/Settings/Users/utils/calculateFittingRoles";

export const MAX_VISIBLE_ROLES = 2;
export const EXTRA_ROLES_LABEL = "hidden roles";
export const LABEL_PREFIX = "Role";

const PADDING_WIDTH = 10;

export const createFilteredRoleGetter = (
  user: User,
  filterTemporaryRoles: boolean
) => {
  const filteredRoles = getFilteredUserRoles(user, filterTemporaryRoles)?.sort(
    sortByNameLength
  );

  const fitItemsAmount = calculateFittingRoles(
    filteredRoles,
    ROLES_CELL_WIDTH - PADDING_WIDTH * 2
  );

  const displayedRoles = filteredRoles?.slice(0, fitItemsAmount) ?? [];
  const hiddenRoles = filteredRoles?.slice(fitItemsAmount) ?? [];
  const tooltipContent = hiddenRoles.map((role) => role.name).join("\n");
  const rolesLeft = hiddenRoles.length;

  return (
    <Grid container columns={3} columnGap={1}>
      {displayedRoles.map((role) => (
        <Chip
          key={role.name}
          aria-label={`${LABEL_PREFIX}-role.name`}
          label={
            filterTemporaryRoles
              ? `${role.name} (expires in ${getTimeLeft(role.expiration)})`
              : role.name
          }
        />
      ))}
      {rolesLeft > 0 && (
        <Tooltip title={tooltipContent} componentsProps={lightMuiTooltipStyle}>
          <Chip
            key={EXTRA_ROLES_LABEL}
            aria-label={EXTRA_ROLES_LABEL}
            label={`+${rolesLeft}`}
          />
        </Tooltip>
      )}
    </Grid>
  );
};
