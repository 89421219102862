import React, { useMemo } from "react";
import styled from "styled-components";

import {
  CheckType,
  ContainerUsageRequestData,
} from "../../../../../../../../../../generated/reliabilityApi";
import { ReliabilityCollapsibleContainer } from "../../../shared/metrics/components/ReliabilityCollapsibleContainer/ReliabilityCollapsibleContainer";
import { getCollapsibleContainerProps } from "../../../../violationDrawerUtils";
import { MetricsSummary } from "../../../shared/metrics/components/MetricsSummary/MetricsSummary";
import { MetricsGraph } from "../../../shared/metrics/components/MetricsGraph/MetricsGraph";
import { reliabilityArialLabels } from "../../../../../../../../reliabilityArialLabels";
import { sectionGap } from "../../../../../../../../constants/reliabilityConstants";
import { MetricsAggregationType } from "../../../../../../../../../Metrics/types";
import { unionContainersData } from "../underProvisionedUtils";
import { MetricsContentContainer } from "../../../../violationDrawerStyles";
import { useGetServiceMetrics } from "../../../../hooks/useGetServiceMetrics";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

import { getCpuSummaryCb, getMemorySummaryCb } from "./summaryUtils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

const {
  violationDrawer: { graphsContainer: graphsContainerAriaLabel },
} = reliabilityArialLabels;

interface Props {
  komodorUid: string;
  containersData: ContainerUsageRequestData[] | undefined;
  checkType: CheckType;
}

export const Details: React.FC<Props> = ({
  komodorUid,
  containersData,
  checkType,
}) => {
  const { memoryRes, cpuRes, memorySummaryRes, cpuSummaryRes } =
    useGetServiceMetrics(komodorUid);

  const isFetchingData =
    memoryRes.loading ||
    cpuRes.loading ||
    memorySummaryRes.loading ||
    cpuSummaryRes.loading;

  useReportDrawerLoadingTime(!isFetchingData);

  const containers = useMemo(
    () => unionContainersData(containersData),
    [containersData]
  );

  const content = useMemo(
    () =>
      Object.entries(containers).map(([containerName, containerData], idx) => (
        <ReliabilityCollapsibleContainer
          key={containerName}
          {...getCollapsibleContainerProps({
            name: containerName,
            index: idx,
            checkType,
          })}
          content={
            <MetricsContentContainer>
              {containerData.memory && (
                <>
                  <MetricsSummary
                    title="Memory analysis"
                    res={memorySummaryRes}
                    getSummaryItems={getMemorySummaryCb(containerData.memory)}
                    containerName={containerName}
                  />
                  <MetricsGraph
                    komodorUid={komodorUid}
                    res={memoryRes}
                    type={"memory"}
                    containerName={containerName}
                    wantedAggregation={MetricsAggregationType.P99}
                    graphTitle=""
                  />
                </>
              )}
              {containerData.cpu && (
                <>
                  <MetricsSummary
                    title="CPU analysis"
                    res={cpuSummaryRes}
                    getSummaryItems={getCpuSummaryCb(containerData.cpu)}
                    containerName={containerName}
                  />
                  <MetricsGraph
                    komodorUid={komodorUid}
                    res={cpuRes}
                    type={"cpu"}
                    containerName={containerName}
                    wantedAggregation={MetricsAggregationType.P99}
                    graphTitle=""
                  />
                </>
              )}
            </MetricsContentContainer>
          }
        />
      )),
    [
      checkType,
      containers,
      cpuRes,
      cpuSummaryRes,
      memoryRes,
      memorySummaryRes,
      komodorUid,
    ]
  );

  return <Container aria-label={graphsContainerAriaLabel}>{content}</Container>;
};
