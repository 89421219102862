import { useCallback, useRef, useState } from "react";

import { useHaveRequestFiltersChanged } from "@/components/k8sAddons/hooks/filters/useHaveRequestFiltersChanged";

export const useMostRecentUpdateRequestParam = (
  relevantFilterNames: string[]
) => {
  const [mostRecentDataFrom, setMostRecentDataFrom] = useState<
    string | undefined
  >(undefined);
  const haveRequestFiltersChanged = useHaveRequestFiltersChanged();
  const lastHaveRelevantFiltersChanged = useRef(false);

  const updateMostRecentDataFrom = useCallback(
    (newMostRecentUpdate: string | undefined) => {
      if (newMostRecentUpdate !== mostRecentDataFrom)
        setMostRecentDataFrom(newMostRecentUpdate);
    },
    [mostRecentDataFrom]
  );

  const getMostRecentDataFromToUse = useCallback(() => {
    const { haveRelevantFiltersChanged, haveAnyFiltersChanged } =
      haveRequestFiltersChanged({
        relevantFilterNames,
      });

    if (!haveAnyFiltersChanged) {
      const haveRelevantFiltersChanged = lastHaveRelevantFiltersChanged.current;
      return haveRelevantFiltersChanged ? undefined : mostRecentDataFrom;
    }

    lastHaveRelevantFiltersChanged.current = haveRelevantFiltersChanged;

    return haveRelevantFiltersChanged ? undefined : mostRecentDataFrom;
  }, [mostRecentDataFrom, relevantFilterNames, haveRequestFiltersChanged]);

  return { updateMostRecentDataFrom, getMostRecentDataFromToUse };
};
