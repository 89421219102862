import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { greenForUIText, pinkBrand } from "../../../../../Colors";
import {
  ConditionVariables,
  MonitorSensor,
  ScopeValues,
} from "../../../common/types";

import ScopeTypeButton from "./ScopeTypeButton";
import SelectorsGroup from "./SelectorsGroup";

export const ScopesContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

type InitialSensor = {
  includes: ScopeValues;
  excludes: ScopeValues;
  scope: string;
};

const ScopeSection: React.FC<{
  id: string;
  sensor: MonitorSensor;
  selectorTypes: string[];
  handleChange: (sensor: MonitorSensor) => void;
}> = ({ id, sensor, selectorTypes, handleChange }) => {
  const [initialSensors, setInitialSensors] = useState<InitialSensor>();
  const [scope, setScope] = useState<string>();

  useEffect(() => {
    if (sensor.cluster && !scope) {
      setScope(
        sensor.namespaces ||
          sensor.annotations ||
          sensor.labels ||
          sensor.services
          ? "Custom scope"
          : "Cluster"
      );
    }
  }, [scope, sensor]);

  const includes = useMemo(() => {
    const tmp: ScopeValues = {};
    if (sensor.condition) {
      tmp.condition = sensor.condition;
    }
    if (sensor.namespaces) {
      tmp.namespaces = sensor.namespaces;
    }
    if (sensor.annotations) {
      tmp.annotations = sensor.annotations;
    }
    if (sensor.labels) {
      tmp.labels = sensor.labels;
    }
    if (sensor.services) {
      tmp.services = sensor.services;
    }
    return tmp;
  }, [sensor]);

  const excludes = useMemo(() => {
    return sensor.exclude ? sensor.exclude : {};
  }, [sensor]);

  useEffect(() => {
    if (
      !initialSensors &&
      Object.keys(includes).length &&
      Object.keys(excludes).length &&
      scope !== undefined
    ) {
      setInitialSensors({
        includes,
        excludes,
        scope,
      });
    }
  }, [excludes, includes, initialSensors, scope]);

  const handleScopeChange = (scope: string) => {
    setScope(scope);
    if (initialSensors) {
      if (initialSensors.scope === scope) {
        const includes =
          scope === "Cluster"
            ? {
                namespaces: [],
                labels: [],
                annotations: [],
                services: [],
                condition: ConditionVariables.OR,
              }
            : initialSensors.includes;
        handleChange({
          cluster: sensor.cluster,
          ...includes,
          exclude: initialSensors.excludes,
        });
      } else {
        handleChange({ cluster: sensor.cluster });
      }
    }
  };

  const handleChangeIncludes = (values: ScopeValues) => {
    handleChange({
      cluster: sensor.cluster,
      ...values,
      exclude: sensor.exclude,
    });
  };

  const handleChangeExcludes = (values: ScopeValues) => {
    handleChange({ ...sensor, exclude: values });
  };

  return (
    <>
      <ScopesContainer>
        <ScopeTypeButton
          mainText={"Cluster"}
          subText={"The rule will monitor the entire cluster"}
          handleScopeChange={handleScopeChange}
          checked={scope === "Cluster" || !scope}
        />
        <ScopeTypeButton
          mainText={"Custom scope"}
          subText={"Select a specific scope to monitor"}
          handleScopeChange={handleScopeChange}
          checked={scope === "Custom scope"}
        />
      </ScopesContainer>
      <br />
      {scope === "Custom scope" && (
        <>
          <SelectorsGroup
            id={`${id}-include`}
            options={selectorTypes}
            title={"Include: "}
            buttonText="Add"
            buttonColor={greenForUIText}
            values={
              Object.keys(includes).length ? includes : { namespaces: [""] }
            }
            handleChange={handleChangeIncludes}
            minSelectors={1}
            cluster={sensor.cluster}
          />
          <br />
        </>
      )}
      <SelectorsGroup
        id={`${id}-exclude`}
        options={selectorTypes}
        title={"Exclude: "}
        buttonText="Add"
        buttonColor={pinkBrand}
        values={excludes}
        handleChange={handleChangeExcludes}
        minSelectors={0}
        cluster={sensor.cluster}
      />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ScopeSection;
