import { ServiceInfo } from "../../../../../../../shared/types/ServiceInfo";
import { Service } from "../../../../../types/Service";
import { FilterBarCategory } from "../../../../../../../shared/components/FilterBar/FilterBar";

export const intoTableRow = (service: ServiceInfo): Service => ({
  id: service.id,
  name: service.title,
  kind: service.kind ?? "",
  cluster: service.k8sCluster,
  namespace: service.env,
});

export const serviceNameIncludes =
  (searchTerm: string): ((service: ServiceInfo) => boolean) =>
  (service: ServiceInfo) =>
    service.title.includes(searchTerm);

export const isInEitherCluster =
  (clusters: string[] | undefined): ((service: ServiceInfo) => boolean) =>
  (service: ServiceInfo) =>
    clusters && clusters.length ? clusters.includes(service.k8sCluster) : true;

export const isInEitherNamespace =
  (namespaces: string[] | undefined): ((service: ServiceInfo) => boolean) =>
  (service: ServiceInfo) =>
    namespaces && namespaces.length ? namespaces.includes(service.env) : true;

export const isOfEitherKind =
  (kinds: string[] | undefined): ((service: ServiceInfo) => boolean) =>
  (service: ServiceInfo) =>
    kinds && kinds.length ? kinds.includes(service.kind ?? "") : true;

export const getCheckedValuesIn = (category: FilterBarCategory): string[] =>
  category.values.filter((value) => value.checked).map((value) => value.value);

export const isAmong =
  (selectedServiceIds: Record<string, boolean>) =>
  (service: ServiceInfo): boolean =>
    selectedServiceIds[service.id];
