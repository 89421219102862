import React, { useMemo } from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { isEmpty } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomEventDetailsProps } from "../CustomEventDetails";
import { SectionContainer } from "../styles";

import { LazyEditor } from "@/components/common/LazyEditor";

export const DetailsSection: React.FC<CustomEventDetailsProps> = ({
  event: { eventDetails },
}) => {
  const stringDetails = useMemo(() => {
    if (!eventDetails || isEmpty(eventDetails)) {
      return;
    }
    try {
      return JSON.stringify(eventDetails, null, 2);
    } catch (e) {
      return;
    }
  }, [eventDetails]);

  if (!stringDetails) {
    return null;
  }

  return (
    <SectionContainer aria-label={"custom event details"}>
      <Typography color={palette.gray[500]} size={"small"} bold>
        DETAILS
      </Typography>
      <LazyEditor
        width="100%"
        height="320px"
        readOnly
        mode="json"
        value={stringDetails}
      />
    </SectionContainer>
  );
};
