import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useGetFetchedPolicyData } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetFetchedPolicyData";
import { isDefaultPolicy } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/isDefaultPolicy";
import { BasePolicy, PolicyListItem } from "@/generated/reliabilityApi";

export const useIsEditDefaultPolicyMode = (
  policy?: BasePolicy | PolicyListItem
): boolean => {
  const { data } = useGetFetchedPolicyData();
  const { reliabilityEditDefaultPolicy } = useOverridableFlags();
  if (!reliabilityEditDefaultPolicy) {
    return false;
  }

  return isDefaultPolicy(policy || data?.data.policy);
};
