import { useCallback, useEffect, useRef, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { useCostOptimizationStore } from "../store/costOptimizationStore";
import {
  selectAllocationFilters,
  selectAllocationMetricsState,
  selectAllocationStatsState,
  selectAllocationSummaryState,
  selectRightSizingCPUMetricsState,
  selectRightSizingMemoryMetricsState,
  selectRightSizingStatsState,
  selectRightSizingSummaryState,
} from "../store/costOptimizationStoreSelectors";
import { KOMODOR_LOADING_TIME } from "../../../shared/constants/datadog";
import {
  getDatadogStartViewName,
  STATIC_TIME_FRAME,
} from "../constants/costOptimizationConstants";
import { useRightSizingRows } from "../components/RightSizingPage/hooks/rightSizingPaginatedTableHooks";

import { useCostOptimizationDataInUrl } from "./persistentStateHooks";

type StorePropState = {
  loading: boolean;
  data?: unknown;
  error?: unknown;
};

type UseIsDataLoaded = {
  state: StorePropState;
  resetState: boolean;
};

const useIsDataLoaded = ({ state, resetState }: UseIsDataLoaded) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    setIsDataLoaded(!!state.data || !!state.error);
  }, [state.data, state.error, state.loading]);

  useEffect(() => {
    if (resetState) {
      setIsDataLoaded(false);
    }
  }, [resetState]);

  return isDataLoaded;
};

const useGetViewName = () => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  const allocationFilters = useCostOptimizationStore(selectAllocationFilters);

  return useCallback(() => {
    let filters: string;
    if (tab === "allocation") {
      filters = `${allocationFilters.groupBy}|${allocationFilters.timeWindow}`;
    } else {
      filters = `${STATIC_TIME_FRAME}`;
    }

    return `${getDatadogStartViewName(tab)}|${filters}`;
  }, [allocationFilters.groupBy, allocationFilters.timeWindow, tab]);
};

export const useReportLoadTime = (): void => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  const getViewName = useGetViewName();

  const {
    isAllocationSummaryDataLoaded,
    isAllocationMetricsDataLoaded,
    isAllocationStatsDataLoaded,
  } = useAllocationDataIsLoading();

  const {
    isRightSizingSummaryDataLoaded,
    isCpuDataLoaded,
    isMemoryDataLoaded,
    isRightSizingStatsDataLoaded,
    isRightSizingRowsDataLoaded,
  } = useRightSizingDataIsLoading();

  const allocationDataIsLoading =
    !isAllocationSummaryDataLoaded ||
    !isAllocationMetricsDataLoaded ||
    !isAllocationStatsDataLoaded;
  const rightSizingDataIsLoading =
    !isCpuDataLoaded ||
    !isMemoryDataLoaded ||
    !isRightSizingStatsDataLoaded ||
    !(isRightSizingSummaryDataLoaded || isRightSizingRowsDataLoaded);

  const dataIsLoading =
    (tab === "allocation" && allocationDataIsLoading) ||
    (tab === "rightSizing" && rightSizingDataIsLoading);

  const onViewStart = useCallback(() => {
    datadogRum.startView(getViewName());
  }, [getViewName]);

  const onViewLoaded = useCallback(() => {
    datadogRum.addTiming(KOMODOR_LOADING_TIME);
  }, []);

  useOnStartView({ dataIsLoading, onViewStart, onViewLoaded });
};

type OnStartViewParams = {
  dataIsLoading: boolean;
  onViewStart: () => void;
  onViewLoaded: () => void;
};

const useOnStartView = ({
  dataIsLoading,
  onViewStart,
  onViewLoaded,
}: OnStartViewParams) => {
  const dataIsLoadingRef = useRef<boolean>();
  useEffect(() => {
    if (dataIsLoadingRef.current !== dataIsLoading) {
      dataIsLoadingRef.current = dataIsLoading;
      if (dataIsLoading) {
        onViewStart();
      } else {
        onViewLoaded();
      }
    }
  }, [dataIsLoading, onViewLoaded, onViewStart]);
};

const useAllocationDataIsLoading = () => {
  const [{ tab }] = useCostOptimizationDataInUrl();

  const allocationSummaryData = useCostOptimizationStore(
    selectAllocationSummaryState
  );
  const allocationMetricsData = useCostOptimizationStore(
    selectAllocationMetricsState
  );
  const allocationStatsData = useCostOptimizationStore(
    selectAllocationStatsState
  );
  const isAllocationSummaryDataLoaded = useIsDataLoaded({
    state: allocationSummaryData,
    resetState: tab === "rightSizing",
  });
  const isAllocationMetricsDataLoaded = useIsDataLoaded({
    state: allocationMetricsData,
    resetState: tab === "rightSizing",
  });
  const isAllocationStatsDataLoaded = useIsDataLoaded({
    state: allocationStatsData,
    resetState: tab === "rightSizing",
  });

  return {
    isAllocationSummaryDataLoaded,
    isAllocationMetricsDataLoaded,
    isAllocationStatsDataLoaded,
  };
};

const useRightSizingDataIsLoading = () => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  const rightSizingCpuState = useCostOptimizationStore(
    selectRightSizingCPUMetricsState
  );

  const rightSizingMemoryState = useCostOptimizationStore(
    selectRightSizingMemoryMetricsState
  );
  const rightSizingSummaryState = useCostOptimizationStore(
    selectRightSizingSummaryState
  );
  const rightSizingStatsState = useCostOptimizationStore(
    selectRightSizingStatsState
  );

  const { data, isLoading, error } = useRightSizingRows(false);

  const isCpuDataLoaded = useIsDataLoaded({
    state: rightSizingCpuState,
    resetState: tab === "allocation",
  });
  const isMemoryDataLoaded = useIsDataLoaded({
    state: rightSizingMemoryState,
    resetState: tab === "allocation",
  });
  const isRightSizingSummaryDataLoaded = useIsDataLoaded({
    state: rightSizingSummaryState,
    resetState: tab === "allocation",
  });
  const isRightSizingStatsDataLoaded = useIsDataLoaded({
    state: rightSizingStatsState,
    resetState: tab === "allocation",
  });

  const isRightSizingRowsDataLoaded = useIsDataLoaded({
    state: { data, loading: isLoading, error },
    resetState: false,
  });

  return {
    isCpuDataLoaded,
    isMemoryDataLoaded,
    isRightSizingSummaryDataLoaded,
    isRightSizingStatsDataLoaded,
    isRightSizingRowsDataLoaded,
  };
};
