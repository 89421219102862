import { capitalize } from "lodash";
import { format } from "date-fns";

import {
  ViolationHistoryItem,
  ViolationStatus,
} from "../../../../../../../../../generated/reliabilityApi";

const violationStatusToVerb: Record<ViolationStatus, string> = {
  open: "opened",
  confirmed: "acknowledged",
  ignored: "ignored",
  dismissed: "dismissed",
  resolved: "resolved",
  manually_resolved: "manually resolved",
};

export const getViolationDescription = (
  violation: ViolationHistoryItem,
  prevViolation?: ViolationHistoryItem
): string => {
  if (!prevViolation) {
    return `Violation created`;
  }
  if (prevViolation.status !== violation.status) {
    if (
      prevViolation.status === "manually_resolved" &&
      violation.status === "open"
    ) {
      return `Violation reopened`;
    }
    return `Violation ${violationStatusToVerb[violation.status]}`;
  }
  if (prevViolation.severity !== violation.severity) {
    return `Severity changed: ${capitalize(prevViolation.severity)} to ${
      violation.severity
    }`;
  }

  return "";
};

export const formatTimestamp = (timestamp: number): string =>
  format(timestamp * 1000, "MMM d, yyyy, h:mm:ss a");

export const formatUserEmail = (userEmail: string): string => `by ${userEmail}`;
