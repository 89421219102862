import { KubernetesRbacPolicyRuleV1Inner } from "../../../../generated/auth";

import { API_GROUPS_CORE_API_GROUP } from "@/components/Settings/Actions/Form/constants";

// This is a type alias to make it easier to change in case the generated type changes
export type RbacActionRule = KubernetesRbacPolicyRuleV1Inner;

export type ActionFormData = {
  name: string;
  description: string;
  rules: RbacActionRule[];
};

export const defaultRules: RbacActionRule[] = [
  {
    apiGroups: [API_GROUPS_CORE_API_GROUP],
    resources: [],
    verbs: [],
  },
];

export const defaultValue: ActionFormData = {
  name: "",
  description: "",
  rules: defaultRules,
};

export const rbacRuleVerbsList = [
  "get",
  "list",
  "watch",
  "create",
  "update",
  "patch",
  "delete",
  "deletecollection",
];

export type ApiGroupOption = { label: string; value: string };
export const isApiGroupOption = (value: unknown): value is ApiGroupOption => {
  return (
    typeof value === "object" &&
    value !== null &&
    "label" in value &&
    "value" in value
  );
};
