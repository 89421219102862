import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { PolicyListItem } from "@/generated/reliabilityApi";
import { NumberTextField } from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerConfigurations/NumberTextField";
import { useEscape } from "@/shared/hooks/useEscape";
import { useClickOutside } from "@/shared/hooks/useClickOutside";
import { PRIORITY_CELL_COLUMN_WIDTH } from "@/components/reliability/components/pages/policies/AllPoliciesTable/policiesTableConstants";
import { useAllPoliciesTableContext } from "@/components/reliability/components/pages/policies/AllPoliciesTable/context/useAllPoliciesTableContext";
import {
  useIsPriorityAvailable,
  useUpdatePolicyPriority,
} from "@/components/reliability/components/pages/policies/AllPoliciesTable/hooks/useUpdatePolicyPriorityHooks";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div<{ isEditing: boolean; isError: boolean }>`
  position: absolute;
  width: ${PRIORITY_CELL_COLUMN_WIDTH}px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.2s;

  border-color: ${({ isEditing, isError }) => {
    if (isError) return muiColors.pink[600];
    if (isEditing) return muiColors.indigo[500];
    return "transparent";
  }};
`;

const StyledDoubleClickDiv = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const DisabledHitArea = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

const StyledNumberTextField = styled(NumberTextField)`
  && {
    fieldset {
      border: none;
    }
  }
`;

const {
  policies: {
    policiesTable: {
      doubleClickCell: doubleClickAriaLabel,
      priorityCell: priorityCellAriaLabel,
    },
  },
} = reliabilityArialLabels;

export const PriorityCell: React.FC<PolicyListItem> = ({
  isDefaultPolicy,
  priority,
  ...policy
}) => {
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const [isEditingPriority, setIsEditingPriority] = useState(false);
  const [value, setValue] = useState<number>(priority);
  const [isPriorityError, setIsPriorityError] = useState<boolean>(false);
  const { isUpdatingPolicy, setIsUpdatingPolicy } =
    useAllPoliciesTableContext();

  useEffect(() => {
    setValue(priority);
  }, [priority]);

  const isPriorityAvailable = useIsPriorityAvailable();
  const updatePolicyPriority = useUpdatePolicyPriority();

  const resetState = () => {
    setIsEditingPriority(false);
    setValue(priority);
    setIsPriorityError(false);
  };

  const onDoubleClick = () => {
    setIsEditingPriority(true);
  };

  useEscape(resetState);
  useClickOutside(inputFieldRef, resetState);

  useEffect(() => {
    if (isEditingPriority) {
      inputFieldRef.current?.focus();
    }
  }, [isEditingPriority]);

  const onPriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPriority = Number(event.target.value);
    setValue(newPriority);
    const isNewPriorityValid = isPriorityAvailable(newPriority, priority);
    setIsPriorityError(!isNewPriorityValid);
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === "Enter" && !isPriorityError) {
      if (value === priority) {
        resetState();
        return;
      }

      setIsEditingPriority(false);
      setIsUpdatingPolicy(true);
      try {
        await updatePolicyPriority(policy.id, { priority: value });
      } catch (error) {
        resetState();
      }
      setIsUpdatingPolicy(false);
    }
  };

  const shouldRenderDoubleClick = useMemo(() => {
    return !isEditingPriority && !isDefaultPolicy;
  }, [isDefaultPolicy, isEditingPriority]);

  const getNumberTextField = () => (
    <StyledNumberTextField
      value={value}
      onChange={onPriorityChange}
      onKeyDown={onKeyDown}
      ref={inputFieldRef}
      allowNegative={false}
      allowDecimal={false}
      disabled={isUpdatingPolicy}
      inputProps={{
        "aria-label": priorityCellAriaLabel,
      }}
    />
  );

  const content = shouldRenderDoubleClick ? (
    <StyledDoubleClickDiv
      onDoubleClick={onDoubleClick}
      disabled={isUpdatingPolicy}
      aria-label={doubleClickAriaLabel}
    >
      <DisabledHitArea>{getNumberTextField()}</DisabledHitArea>
    </StyledDoubleClickDiv>
  ) : (
    <>{getNumberTextField()}</>
  );

  return (
    <Container isEditing={isEditingPriority} isError={isPriorityError}>
      {content}
    </Container>
  );
};
