import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useResourcesApiClient } from "../apiClient";
import {
  AggregationsApiApiV1AggregationsKomodorServicesGetRequest,
  KomodorServiceAggregationsResponse,
  apiV1AggregationsKomodorServicesGetUrl,
} from "../../../../../generated/resourcesApi";

export const KOMODOR_SERVICES_AGGREGATIONS_PATH =
  "/aggregations/komodor-services";

const fetchKomodorServicesAggregations = async (
  apiClient: AxiosInstance,
  params: AggregationsApiApiV1AggregationsKomodorServicesGetRequest
): Promise<KomodorServiceAggregationsResponse> => {
  const { data } = await apiClient.get(
    apiV1AggregationsKomodorServicesGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetKomodorServicesAggregations = (
  params: AggregationsApiApiV1AggregationsKomodorServicesGetRequest,
  options?: {
    enabled?: boolean;
    staleTime?: number;
    keepPreviousData?: boolean;
  }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [KOMODOR_SERVICES_AGGREGATIONS_PATH, params],
    () => fetchKomodorServicesAggregations(apiClient, params),
    options
  );
};
