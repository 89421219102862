import { ResourceTableModelRow } from "komodor-types";

import { useDeletedPodById } from "../Inspection/historicalFetch/useResourceListFromDatabase";

interface DeletedPodsData {
  id: string;
  isDeletedPod: boolean;
}

interface DeletedPodsResult {
  data: ResourceTableModelRow[];
  isFetching: boolean;
  error: string;
}

export const useDeletedPodsData = ({
  id,
  isDeletedPod,
}: DeletedPodsData): DeletedPodsResult => {
  const { data, fetching, errorMessage } = useDeletedPodById(
    id,
    !isDeletedPod || !id
  );

  return { data, isFetching: fetching, error: errorMessage };
};
