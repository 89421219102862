import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  Agent,
  AgentsApiApiV1AgentsGetRequest,
  apiV1AgentsGetUrl,
} from "@/generated/agents";
import { useAgentsApiClient } from "@/shared/hooks/agents-api/apiClient";
import { agentResponse } from "@/shared/hooks/agents-api/utils";

const AGENTS_PATH = "/api/v1/agents";

export const getAgents = async (
  apiClient: AxiosInstance,
  params: AgentsApiApiV1AgentsGetRequest
): Promise<agentResponse> => {
  const { data } = await apiClient.get(
    apiV1AgentsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return filterActiveInactiveAgents(data);
};

export const useAgentServicePolling = (
  refetchInterval: number,
  pause = false
) => {
  const apiClient = useAgentsApiClient();
  return useQuery(
    [AGENTS_PATH],
    () => getAgents(apiClient, { sort: "-lastSeenAlive" }),
    {
      refetchInterval: refetchInterval,
      enabled: !pause,
    }
  );
};

export const filterActiveInactiveAgents = (agents: Agent[]): agentResponse => {
  const response: agentResponse = { activeAgents: [], inactiveAgents: [] };
  if (!agents || agents.length === 0) {
    return response;
  }

  agents.forEach((agent) => {
    if (agent.inactive) {
      response.inactiveAgents.push(agent);
    } else {
      response.activeAgents.push(agent);
    }
  });
  return response;
};
