import React, { useMemo } from "react";
import { isEmpty } from "lodash";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { ChipsFlexContainer } from "@/components/k8sAddons/LiveStateDrawer/components/ChipsFlexContainer";
import { Dictionary } from "@/shared/types/Dictionary";

type AccordionValuesAsChipsListProps = {
  title: string;
  values: Dictionary<string> | undefined;
  ariaLabel?: string;
};

export const AccordionValuesAsChipsList: React.FC<
  AccordionValuesAsChipsListProps
> = ({ title, values, ariaLabel }) => {
  const valuesAsList = useMemo(() => {
    if (!values) return [];
    return Object.entries(values).map(([key, value]) => `${key}: ${value}`);
  }, [values]);

  if (!values || isEmpty(values)) return null;

  return (
    <AccordionWrapper
      title={`${title} (${valuesAsList.length})`}
      accordionContent={<ChipsFlexContainer values={valuesAsList} />}
      ariaLabel={ariaLabel}
    />
  );
};
