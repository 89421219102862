import React from "react";

import Resource from "../../../../resources";
import { EnlargedMetricsGraph } from "../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { WorkloadMetricsPreferences } from "../WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { NoLimitLabel } from "../../../../../Metrics/NoLimitLabel";
import { AdditionalProps } from "../../types";
import { ResourceGraph } from "../../../../../Metrics/graphs/ResourceGraph";
import { getTitle } from "../../workloadMetricsTabUtils";
import { MetricsGraphProps, MetricType } from "../../../../../Metrics/types";

import { useWorkloadMetrics } from "./useWorkloadMetrics";

type EnlargedWorkloadGraphProps = {
  resource: Resource;
  endTime: number;
  onClose: () => void;
  type: MetricType;
  metricGraphProps?: Partial<MetricsGraphProps>;
  additionalProps?: AdditionalProps["enlargedGraphProps"];
  children?: React.ReactNode;
};

export const EnlargedWorkloadGraph: React.FC<EnlargedWorkloadGraphProps> = ({
  resource,
  endTime,
  onClose,
  type,
  additionalProps,
  metricGraphProps,
  children,
}) => {
  const title = additionalProps?.title ?? getTitle(type);

  const { loading, error, hasGraphData, uid, graphProps, hasLimit } =
    useWorkloadMetrics({ type, resource, endTime });

  return (
    <EnlargedMetricsGraph
      title={title}
      controls={additionalProps?.controls ?? [<WorkloadMetricsPreferences />]}
      onClose={onClose}
      uid={uid}
      hasGraphData={hasGraphData}
      loading={loading}
      error={error}
      info={!hasLimit ? <NoLimitLabel /> : null}
      {...additionalProps}
    >
      <ResourceGraph
        type={type}
        {...graphProps}
        {...metricGraphProps}
        data={graphProps.data}
        children={children}
      />
    </EnlargedMetricsGraph>
  );
};
