import React, { useEffect, useState } from "react";

import { DataPointsBreakdownPerIntervals } from "../../utils/getDataPointsBreakdownPerIntervals";

type MaxLineWidthParams = {
  data?: DataPointsBreakdownPerIntervals;
  linesContainerRef: React.RefObject<HTMLDivElement>;
  line2ContainerRef: React.RefObject<HTMLDivElement>;
};

export const useGetMaxLineWidth = ({
  data,
  linesContainerRef,
  line2ContainerRef,
}: MaxLineWidthParams): number => {
  const [maxLineWidth, setMaxLineWidth] = useState<number>(0);

  useEffect(() => {
    const { firstIntervalPercentage, secondIntervalPercentage } = data ?? {};
    if (maxLineWidth || !firstIntervalPercentage || !secondIntervalPercentage)
      return;
    const linesContainerWidth = linesContainerRef.current?.clientWidth || 0;
    const line2MaxWidth = line2ContainerRef.current?.clientWidth || 0;
    const maxLineWidthRatio = line2MaxWidth / linesContainerWidth;

    setMaxLineWidth(maxLineWidthRatio);
  }, [data, line2ContainerRef, linesContainerRef, maxLineWidth]);

  return maxLineWidth;
};
