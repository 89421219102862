import React from "react";

import { costOptimizationAriaLabels } from "../../constants/costOptimizationAriaLabels";
import { RecommendationAccuracy } from "../../../common/RecommendationAccuracy/RecommendationAccuracy";
import { useGetClustersEarliestRowTime } from "../../hooks/earliestRowTimeHooks";

export const RecommendationAccuracyPrompt: React.FC = () => {
  const clustersWithDates = useGetClustersEarliestRowTime();
  if (!clustersWithDates) return null;
  return (
    <RecommendationAccuracy
      clusters={clustersWithDates}
      ariaLabel={costOptimizationAriaLabels.recommendationsAccuracy}
    />
  );
};
