import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import { isEqual } from "lodash";
import {
  DisabledItemsTitle,
  MenuListContainer,
  StickyContainer,
} from "components/komodor-ui/Select/AdvancedMultiSelect/advancedMultiSelectStyles";
import { ItemSelectionMenu } from "components/komodor-ui/Select/AdvancedMultiSelect/components/ItemSelectionMenu";
import {
  useGetDisabledItems,
  useGetEnabledItems,
  useGetOptionsBySearchTerm,
} from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/advancedMultiSelectHooks";
import { useControlAutoFocus } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useControlAutoFocus";
import { useIsMenuHiddenByTopContent } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useIsMenuHiddenByTopContent";
import { useOnItemSelect } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useOnItemSelect";
import { SearchBar } from "components/komodor-ui/Select/shared/components/SearchBar/SearchBar";
import { SelectPopover } from "components/komodor-ui/Select/shared/components/SelectPopOver";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { gray } from "themes/colors";
import { MultiSelectPopoverProps } from "components/komodor-ui/Select/AdvancedMultiSelect/multiSelectTypes";
import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import { popoverTransitionDuration } from "index";
import { VirtualizedMultiSelectMenuList } from "../AdvancedMultiSelect/VirtualizedMultiSelectMenuList";

const MENU_LIST_PADDING = "16px";
const SEARCH_BAR_HEIGHT = "42px";

export const MultiSelectPopover = <T extends MuiSelectionOptionValue>({
  anchorEl,
  value,
  options,
  onChange,
  onClose,
  renderDisabledOptions,
  texts,
  classNames,
  selectPopoverProps,
  enableSelectAll = true,
  enableSearch = true,
  enableFreeSolo = false,
  customOptionElement,
  enableVirtualization = false,
  virtualizerProps,
}: MultiSelectPopoverProps<T>) => {
  const [localValue, setLocalValue] = useState(value ?? []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [freeSoloOptions, setFreeSoloOptions] = useState<
    MuiSelectionOption<T>[]
  >([]);
  const menuListRef = useRef<HTMLUListElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    value && setLocalValue(value);
  }, [value]);

  const { shouldAutoFocusFirstItem, setShouldAutoFocusFirstItem } =
    useControlAutoFocus(open);

  const { isMenuHiddenByTopContent, handleScroll } =
    useIsMenuHiddenByTopContent(menuListRef);

  const { enabledOptions, disabledOptions } = useGetOptionsBySearchTerm({
    options: [...options, ...freeSoloOptions],
    searchTerm,
    freeSolo: enableFreeSolo,
  });

  const onChangeLocalValue = useCallback(
    (selectedOptions: MuiSelectionOption<T>[]) => {
      setLocalValue(selectedOptions);
      onChange?.(selectedOptions);
    },
    [onChange]
  );

  useEffect(() => {
    if (open) return;
    const selectedFreeSoloOptions = freeSoloOptions.filter((option) =>
      localValue.find((opt) => opt.value === option.value)
    );
    !isEqual(freeSoloOptions, selectedFreeSoloOptions) &&
      setFreeSoloOptions(selectedFreeSoloOptions);
  }, [freeSoloOptions, open, localValue]);

  const handleClose = () => {
    setTimeout(() => {
      setSearchTerm("");
    }, popoverTransitionDuration);
    onClose();
  };

  const onItemSelect = useOnItemSelect({
    options,
    freeSolo: enableFreeSolo,
    freeSoloOptions,
    selectedOptions: localValue,
    onSetSelectedOptions: onChangeLocalValue,
    setFreeSoloOptions,
  });

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      onChangeLocalValue(enabledOptions);
    } else {
      onChangeLocalValue([]);
    }
  };

  const enabledItems = useGetEnabledItems({
    enabledOptions: enabledOptions,
    selectedOptions: localValue,
    onItemSelect,
    customOptionElement: customOptionElement,
  });

  const disabledItems = useGetDisabledItems({
    disabledOptions,
    pause: !!renderDisabledOptions,
    customOptionElement,
    selectedOptions: localValue,
  });

  return (
    <SelectPopover
      anchorEl={anchorEl}
      onClose={handleClose}
      onScroll={handleScroll}
      {...selectPopoverProps}
    >
      <div onScroll={handleScroll}>
        <StickyContainer $showShadow={isMenuHiddenByTopContent}>
          {enableSearch && (
            <SearchBar
              searchValue={searchTerm}
              onSearch={onSearchTermChange}
              className={classNames?.searchBar}
              searchPlaceholder={texts?.searchPlaceholder}
              onFocus={() => setShouldAutoFocusFirstItem(false)}
            />
          )}
          {enableSelectAll && (
            <ItemSelectionMenu
              totalSelected={localValue.length}
              totalOptions={enabledOptions.length}
              onSelectAll={onSelectAll}
              texts={texts}
            />
          )}
        </StickyContainer>
        <MenuListContainer>
          {enableVirtualization ? (
            <VirtualizedMultiSelectMenuList
              options={enabledOptions}
              selectedOptions={localValue}
              onItemSelect={onItemSelect}
              className={classNames?.enabledItems}
              ariaLabel={selectAriaLabels.enabledOptions}
              customOptionElement={customOptionElement}
              autoFocusItem={shouldAutoFocusFirstItem}
              maxHeight={
                selectPopoverProps.maxHeight
                  ? `calc(${
                      selectPopoverProps.maxHeight
                    } - ${MENU_LIST_PADDING}${
                      enableSearch ? ` - ${SEARCH_BAR_HEIGHT}` : ""
                    })`
                  : undefined
              }
              virtualizerProps={virtualizerProps}
            />
          ) : (
            <MenuList
              className={classNames?.enabledItems}
              aria-label={selectAriaLabels.enabledOptions}
              ref={menuListRef}
              autoFocusItem={shouldAutoFocusFirstItem}
            >
              {enabledItems}
            </MenuList>
          )}

          {!!disabledOptions.length && (
            <>
              <Divider />
              <DisabledItemsTitle variant={"h6"} color={gray[600]}>
                {texts?.hiddenItemsTitle}
              </DisabledItemsTitle>
              {enableVirtualization && !renderDisabledOptions ? (
                <VirtualizedMultiSelectMenuList
                  options={disabledOptions}
                  selectedOptions={localValue}
                  onItemSelect={onItemSelect}
                  className={classNames?.disabledItems}
                  ariaLabel={selectAriaLabels.disabledOptions}
                  customOptionElement={customOptionElement}
                  maxHeight={
                    selectPopoverProps.maxHeight
                      ? `calc(${
                          selectPopoverProps.maxHeight
                        } - ${MENU_LIST_PADDING}${
                          enableSearch ? ` - ${SEARCH_BAR_HEIGHT}` : ""
                        })`
                      : undefined
                  }
                  virtualizerProps={virtualizerProps}
                />
              ) : (
                <MenuList
                  className={classNames?.disabledItems}
                  aria-label={selectAriaLabels.disabledOptions}
                >
                  {renderDisabledOptions
                    ? renderDisabledOptions(disabledOptions)
                    : disabledItems}
                </MenuList>
              )}
            </>
          )}
        </MenuListContainer>
      </div>
    </SelectPopover>
  );
};
