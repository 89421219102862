import { formatISO, subDays } from "date-fns";

import {
  CostApiApiV1CostEarliestRowsTimeGetRequest,
  EarliestRowsTimeResponse,
} from "../../../../generated/metricsApi";
import { MINIMUM_DAYS_FOR_ACCURACY } from "../../../../shared/utils/metricsAgentInfoAccuracyUtils";

const getISODate = (date: Date) => formatISO(date);

const dates = Array.from({ length: 10 }).map((_, idx) => {
  if (idx === 4) return getISODate(new Date());
  if (idx % 2 === 0)
    return getISODate(subDays(new Date(), MINIMUM_DAYS_FOR_ACCURACY + 1));
  return getISODate(subDays(new Date(), MINIMUM_DAYS_FOR_ACCURACY - 1));
});

export const generateEarliestRowTime = ({
  clusterNames,
}: CostApiApiV1CostEarliestRowsTimeGetRequest):
  | EarliestRowsTimeResponse
  | { clusters: undefined } => {
  const mockData = clusterNames.reduce((acc, curr, idx) => {
    if (idx === 0 && clusterNames.length > 1) return acc;
    return {
      ...acc,
      [curr]: {
        earliestRecordTimeInHourlyNodesTable: dates[idx % dates.length],
      },
    };
  }, {});

  return { clusters: clusterNames.length ? mockData : undefined };
};
