import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  CreateRbacUserRoleRequest,
  RbacRole,
  apiV1RbacUserRolesPostUrl,
} from "../../../../../generated/auth";

const createRbacUserRole = async (
  apiClient: AxiosInstance,
  params: CreateRbacUserRoleRequest
): Promise<RbacRole> => {
  const { data } = await apiClient.post(
    apiV1RbacUserRolesPostUrl(
      { createRbacUserRoleRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCreateRbacUserRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation((params: CreateRbacUserRoleRequest) =>
    createRbacUserRole(apiClient, params)
  );
};
