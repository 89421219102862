import { useMemo } from "react";

import { notifyDDError } from "../../../../shared/hooks/exceptionManagement";
import EventGroup from "../../EventGroup";

export type Legend = Map<string, number>;

export type LineDot = {
  events: { [color: string]: EventGroup[] };
  x: number;
};

export const NUM_OF_TIME_BUCKETS = 12;

const fillBarsWithEvent = (
  barsData: LineDot[],
  eventStartIndex: number,
  eventEndIndex: number,
  e: EventGroup
): void => {
  const eventFillColor = e.fillColor;

  for (
    let i = eventStartIndex;
    i <= eventEndIndex && i < barsData.length;
    i++
  ) {
    const bucket = barsData[i];
    if (!bucket) {
      notifyDDError({
        name: "While showing events on timeline, didn't find bucket in index",
        message: `Didn't find bars index: ${i}`,
      });
      continue;
    }
    if (!bucket.events[eventFillColor]) {
      bucket.events[eventFillColor] = [];
    }
    bucket.events[eventFillColor].push(e);
  }
};

const createBuckets = (
  events: EventGroup[],
  start: Date,
  end: Date
): LineDot[] => {
  const min = start.getTime();
  const max = end.getTime();
  const step = (max - min) / NUM_OF_TIME_BUCKETS || 1; // Avoid 0, it create `undefined` later
  const barsData: LineDot[] = Array.from(
    { length: NUM_OF_TIME_BUCKETS },
    (_, index) => ({
      events: {},
      x: min + step * (index + 0.5),
    })
  );
  const barsDataMaxIndex = barsData.length - 1;

  for (const e of events) {
    const time = e.startTime.getTime();
    const endTime = e.endTime.getTime();

    const startIndex = Math.min(
      Math.max(Math.floor((time - min) / step), 0),
      barsDataMaxIndex
    );

    const endIndex =
      e.isCompleted === false
        ? barsDataMaxIndex
        : Math.min(Math.floor((endTime - min) / step), barsDataMaxIndex);

    fillBarsWithEvent(barsData, startIndex, endIndex, e);
  }

  return barsData;
};

const useLineChartEvents = (
  eventGroups: EventGroup[],
  start: Date,
  end: Date
): LineDot[] =>
  useMemo(
    () => createBuckets(eventGroups, start, end),
    [eventGroups, start, end]
  );

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useLineChartEvents;
