import React, { useRef, useState } from "react";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Minus16 } from "@komodorio/design-system/icons";
import { parseISO } from "date-fns";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import type * as CSS from "csstype";

import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";

import { StatusTag } from "./StatusTag";

const MAX_HEIGHT = 88;
export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding-block: 0.375rem;
  column-gap: 1rem;
`;
const Value = styled.div<{ showAll: boolean }>`
  overflow: hidden;
  ${({ showAll }) => !showAll && `max-height: ${MAX_HEIGHT}px;`}
`;
const ShowAllButton = styled(Typography)`
  color: ${theme.foreground.fgDarkBlue};
  width: max-content;
  margin-block-start: 0.25rem;
  :hover {
    cursor: pointer;
    text-decoration-line: underline;
  }
`;

const StyledTypography = styled(Typography)<{ isLink: boolean }>`
  && {
    display: inline;
    cursor: ${({ isLink }) => (isLink ? `pointer` : `auto`)};
    text-decoration: ${({ isLink }) => (isLink ? `underline` : `auto`)};
    color: ${({ isLink }) => (isLink ? palette.darkBlue[500] : `auto`)};
  }
`;

const StyledName = styled(Typography)<{ alignName?: CSS.Property.AlignSelf }>`
  align-self: ${({ alignName }) => alignName || "auto"};
`;

export const DescribeItem: React.FC<{
  name: string;
  children?: React.ReactNode;
  alignName?: CSS.Property.AlignSelf;
}> = ({ name, children, alignName }) => {
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = (containerRef?.current?.clientHeight ?? 0) > MAX_HEIGHT;

  return (
    <Row>
      <StyledName alignName={alignName} variant="title">
        {name}:
      </StyledName>
      <div>
        <Value showAll={showAll}>
          <div ref={containerRef}>{children}</div>
        </Value>
        {(isOverflow || showAll) && (
          <ShowAllButton onClick={() => setShowAll((prev) => !prev)}>
            {showAll ? "Show less" : "Show all"}
          </ShowAllButton>
        )}
      </div>
    </Row>
  );
};

type DescribeTextItemProps = {
  name: string;
  value?: string | number | React.ReactNode | undefined;
  onValueClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const DescribeTextItem: React.FC<DescribeTextItemProps> = ({
  name,
  value,
  onValueClick,
}) => {
  const ariaLabel = onValueClick
    ? "describe item text link"
    : "describe item text";
  return (
    <DescribeItem name={name}>
      <StyledTypography
        isLink={!!onValueClick}
        onClick={onValueClick}
        aria-label={ariaLabel}
      >
        {value ?? <Minus16 />}
      </StyledTypography>
    </DescribeItem>
  );
};

const Link = styled(NavLink)`
  text-decoration: none;
`;

export const DescribeLinkItem: React.FC<{
  name: string;
  value: string;
  navTo: string;
}> = ({ name, value, navTo }) => (
  <DescribeItem name={name}>
    <Link to={navTo}>
      <Typography color={theme.foreground.fgDarkBlue}>{value}</Typography>
    </Link>
  </DescribeItem>
);

export const DescribeStatusItem: React.FC<{
  name: string;
  value: string | number | undefined;
  statusesMapper?: { [key: string]: { bg: string; fg: string } };
}> = ({ name, value, statusesMapper }) => (
  <DescribeItem name={name}>
    {value ? (
      <StatusTag value={value} mapOverride={statusesMapper} />
    ) : (
      <Minus16 />
    )}
  </DescribeItem>
);

export const DescribeDataItem: React.FC<{
  name: string;
  children?: React.ReactNode;
}> = ({ name, children }) => (
  <DescribeItem name={name}>
    <Typography>{children}</Typography>
  </DescribeItem>
);

export const DescribeDateItem: React.FC<{
  name: string;
  value: string | undefined;
}> = ({ name, value }) => {
  const { format } = useDateFormatter();

  return (
    <DescribeItem name={name}>
      <Typography>{value ? format(parseISO(value)) : <Minus16 />}</Typography>
    </DescribeItem>
  );
};

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: white;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
`;

export const DescribeTitle = styled(Typography).attrs({
  variant: "title",
  size: "medium",
})`
  margin-block-end: 1rem;
`;

export const DescribeTableItems: React.FC<{
  name: string;
  children?: React.ReactNode;
}> = ({ name, children }) => (
  <RowsContainer>
    <DescribeTitle>{name}</DescribeTitle>
    {children}
  </RowsContainer>
);
