import { DataGridProPropsWithoutDefaultValue } from "@mui/x-data-grid-pro/models/dataGridProProps";

import { getFixedNumber } from "../../../../../shared/utils/formatCurrency";
import { CostAllocationSummary } from "../../../../../generated/metricsApi";

import { getHasKind } from "./utils";

export const getAllocationCsvHeaders = (
  rows: DataGridProPropsWithoutDefaultValue["rows"]
): Record<string, string> => {
  if (!rows || rows.length === 0) return {};

  const namespaceHeader =
    rows[0].namespace !== "" ? { namespace: "Namespace" } : null;

  const serviceHeader =
    rows[0].komodorServiceName !== ""
      ? { komodorServiceName: "Service" }
      : null;

  const kindHeader = getHasKind(rows as CostAllocationSummary[])
    ? { komodorServiceKind: "Kind" }
    : null;

  const constantCostHeaders = {
    shareOfCost: "Share of Allocated Cost",
    cpuRequestCoreHours: "CPU hours (Cores)",
    memoryRequestGBHours: "Memory hours (GBs)",
    totalCost: "Total Cost (USD)",
    optimizationScore: "Optimization Score",
    potentialSaving: "Potential Saving (USD)",
  };

  return {
    ...{ clusterName: "Cluster" },
    ...namespaceHeader,
    ...serviceHeader,
    ...kindHeader,
    ...constantCostHeaders,
  };
};

export const getValue = (
  category: keyof CostAllocationSummary,
  row: CostAllocationSummary
) => {
  const value = row[category as keyof typeof row];

  if (!value) return "-";

  if (typeof value === "string") return value;

  switch (category) {
    case "shareOfCost":
      return `${value.toFixed(2)}%`;
    case "cpuRequestCoreHours":
      return `${getFixedNumber(value)}`;
    case "memoryRequestGBHours":
      return `${getFixedNumber(value)}`;
    case "totalCost":
      return `${getFixedNumber(value)}`;
    case "optimizationScore":
      return `${value.toFixed(2)}%`;
    case "potentialSaving":
      return `${getFixedNumber(value)}`;
    default:
      return `${value}`;
  }
};

export const sortRows = (rows?: CostAllocationSummary[]) => {
  if (!rows) return;
  return rows.sort((a, b) => b.totalCost - a.totalCost);
};
