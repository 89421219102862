import { RightSizingCostSummaryResponse } from "../../../../generated/metricsApi";

import { getRandRange } from "./getRandRange";
import { generateMockCostSummaryRows } from "./mockCostRightSizingRows";
type GenerateMockCostRightSizingSummaryParams = {
  rowsLength?: number;
  clusterNames?: string[];
  randomize?: boolean;
};

export const generateMockCostRightSizingSummary = (
  params?: GenerateMockCostRightSizingSummaryParams
): RightSizingCostSummaryResponse => {
  const { clusterNames, randomize = true, rowsLength = 50 } = params || {};

  const currentCostPerMonth = Math.floor(Math.random() * 100000);
  const monthlySaving = randomize
    ? getRandRange(currentCostPerMonth * 0.1, currentCostPerMonth * 0.5)
    : currentCostPerMonth * 0.5;

  const yearlySaving = monthlySaving * 12;

  const unallocatedResourcesPrice = getRandRange(
    monthlySaving * 0.3,
    monthlySaving * 0.7
  );

  const rows = generateMockCostSummaryRows(rowsLength, clusterNames);

  return {
    currentCostPerMonth,
    monthlySaving,
    yearlySaving,
    unallocatedResourcesPrice,
    rows,
  };
};
