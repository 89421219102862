import { useMemo } from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";

import { Dictionary } from "@/shared/types/Dictionary";

export const disableLocalSortingInTableColumns = <T extends GridValidRowModel>(
  columns: Dictionary<GridColDef<T>>
): Dictionary<GridColDef<T>> => {
  return Object.keys(columns).reduce<Dictionary<GridColDef<T>>>((acc, key) => {
    acc[key] = {
      ...columns[key],
      // disable local data sorting in table
      sortComparator: () => 0,
    };
    return acc;
  }, {});
};

export const useDisableLocalSortingInTableColumns = <
  T extends GridValidRowModel
>(
  columns: Dictionary<GridColDef<T>>
): Dictionary<GridColDef<T>> => {
  return useMemo(
    () => disableLocalSortingInTableColumns<T>(columns),
    [columns]
  );
};
