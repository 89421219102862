import React, { ReactElement } from "react";

import { TimeWindow } from "../../../../../../shared/types/TimeWindow";
import {
  KubernetesConfigMapResource,
  KubernetesHPAResource,
  KubernetesIngressResource,
  KubernetesPodsResource,
  KubernetesPVCResource,
  KubernetesResource,
  KubernetesSecretsResource,
  KubernetesServiceResource,
} from "../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import {
  RELATED_RESOURCES_PODS_PARAM_KEY,
  RELATED_RESOURCES_HPAS_PARAM_KEY,
  RELATED_RESOURCES_CONFIGMAPS_PARAM_KEY,
  RELATED_RESOURCES_SERVICES_PARAM_KEY,
  RELATED_RESOURCES_SECRETS_PARAM_KEY,
  RELATED_RESOURCES_PVCS_PARAM_KEY,
  RELATED_RESOURCES_INGRESSES_PARAM_KEY,
  RESOURCE_PREFIX,
} from "../../../../../../shared/config/urlSearchParamsKeys";

import { HpasListTable, PodsListTable } from "./ResourceListTables";
import { SelfSupplyingResourceListTableProps } from "./types";
import { ServicesListTable } from "./ResourceListTables/ServicesListTable";
import { useServicesEventsFetch } from "./graphql/useServicesEventsFetch";
import { ConfigMapsListTable } from "./ResourceListTables/ConfigMapsListTable";
import { SecretsListTable } from "./ResourceListTables/SecretsListTable";
import { PvcsListTable } from "./ResourceListTables/PvcsListTable";
import { IngressListTable } from "./ResourceListTables/IngressListTable";
import { useIngressEventsFetch } from "./graphql/useIngressEventsFetch";
import { usePodsEventsFetch } from "./graphql/usePodEventsFetch";
import { useHpasEventsFetch } from "./graphql/useHpasEventsFetch";
import { useConfigMapsEventsFetch } from "./graphql/useConfigMapsEventsFetch";
import { useSecretsEventsFetch } from "./graphql/useSecretsEventsFetch";

export interface RelatedResourceParameters {
  renderTable: (props: SelfSupplyingResourceListTableProps) => ReactElement;
  useFetchEvents: (
    resourceNames: string[] | undefined,
    clusterName: string | undefined,
    namespace: string | undefined,
    timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
    disableFetch?: boolean
  ) => [unknown[] | undefined, boolean];
  searchParamKey: (resourceViewKind: string) => string;
}

type RelatedResources = Map<KubernetesResource, RelatedResourceParameters>;

export const relatedResources: RelatedResources = new Map();

relatedResources.set(KubernetesPodsResource, {
  renderTable: (props) => <PodsListTable {...props} />,
  useFetchEvents: usePodsEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_PODS_PARAM_KEY}`,
});

relatedResources.set(KubernetesHPAResource, {
  renderTable: (props) => <HpasListTable {...props} />,
  useFetchEvents: useHpasEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_HPAS_PARAM_KEY}`,
});

relatedResources.set(KubernetesConfigMapResource, {
  renderTable: (props) => <ConfigMapsListTable {...props} />,
  useFetchEvents: useConfigMapsEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_CONFIGMAPS_PARAM_KEY}`,
});

relatedResources.set(KubernetesServiceResource, {
  renderTable: (props) => <ServicesListTable {...props} />,
  useFetchEvents: useServicesEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_SERVICES_PARAM_KEY}`,
});
relatedResources.set(KubernetesSecretsResource, {
  renderTable: (props) => <SecretsListTable {...props} />,
  useFetchEvents: useSecretsEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_SECRETS_PARAM_KEY}`,
});

relatedResources.set(KubernetesPVCResource, {
  renderTable: (props) => <PvcsListTable {...props} />,
  useFetchEvents: () => [undefined, false], //wrong logic previously looked at PVC issues instead of events, will do nothing till fixed
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_PVCS_PARAM_KEY}`,
});

relatedResources.set(KubernetesIngressResource, {
  renderTable: (props) => <IngressListTable {...props} />,
  useFetchEvents: useIngressEventsFetch,
  searchParamKey: (resourceViewKind: string) =>
    `${RESOURCE_PREFIX}${resourceViewKind}-${RELATED_RESOURCES_INGRESSES_PARAM_KEY}`,
});
