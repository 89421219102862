import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type IntercomEffectsState = {
  unreadMessagesCount: number;
};

export type IntercomEffectsActions = {
  setUnreadMessagesCount: (count: number) => void;
};

export type IntercomEffectsStore = IntercomEffectsState &
  IntercomEffectsActions;

export const initialState: IntercomEffectsState = {
  unreadMessagesCount: 0,
};

export const useIntercomEffectsStore = create(
  immer<IntercomEffectsStore>((set, get) => ({
    ...initialState,
    setUnreadMessagesCount: (count: number) => {
      set(() => ({
        unreadMessagesCount: count,
      }));
    },
  }))
);
