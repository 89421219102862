import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { Title } from "../../../violationDrawerStyles";

import { RELEVANT_ISSUES } from "./constants";
import { useFetchNodeTerminationEvents } from "./hooks/useFetchNodeTerminationEvents";

import {
  ProcessList,
  ProcessListSortKeys,
} from "@/components/common/ProcessList";
import { LinesLoader } from "@/components/common/loaders/Line";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { useWorkflowIssuesQuery } from "@/components/common/EventGroup/workflowIssues/useWorkflowIssues";
import { CorrelatedIssueSupportingData } from "@/generated/reliabilityApi";
import { useSharedProcessListProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useSharedProcessListProps";

type CorrelatedEventsProps = {
  komodorUid: string;
  fromEpoch: number;
  toEpoch: number;
  correlatedIssues: CorrelatedIssueSupportingData[];
  correlatedNodeEventIds: string[];
};

const EventsContainer = styled.div`
  background-color: ${muiColors.gray["50"]};
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 16px;
  margin-top: 8px;
`;

export const CorrelatedEvents: React.FC<CorrelatedEventsProps> = ({
  komodorUid,
  fromEpoch,
  toEpoch,
  correlatedIssues,
  correlatedNodeEventIds,
}) => {
  const {
    eventId,
    setEventId,
    setIntentId,
    timeWindow,
    setTimeWindow,
    serviceIds,
  } = useSharedProcessListProps({
    komodorUids: [komodorUid],
    fromEpoch,
    toEpoch,
  });

  const { nodeTerminationsGroups, isFetchingNodeTerminations } =
    useFetchNodeTerminationEvents({
      komodorUid,
      correlatedNodeEventIds,
      timeWindow,
      serviceId: serviceIds[0],
    });

  const [issues, isLoadingIssues] = useWorkflowIssuesQuery(
    timeWindow,
    [serviceIds[0]],
    RELEVANT_ISSUES
  );
  const issuesEventGroups = useMemo(() => {
    let flatIssues = issues?.flat() ?? [];
    const correlatedIssueIds = correlatedIssues.map((issue) => issue.id);
    flatIssues = flatIssues.filter((issue) =>
      correlatedIssueIds.includes(issue.id)
    );

    return groupWorkflowIssuesEvents(flatIssues, serviceIds[0]);
  }, [issues, serviceIds, correlatedIssues]);

  const eventGroups = useMemo(
    () => [...nodeTerminationsGroups, ...issuesEventGroups],
    [issuesEventGroups, nodeTerminationsGroups]
  );

  const isFetching = isFetchingNodeTerminations || isLoadingIssues;

  if (isFetching) {
    return <LinesLoader />;
  }

  return (
    <>
      <Title>Related Events({eventGroups.length})</Title>
      {eventGroups.length > 0 && (
        <EventsContainer>
          <ProcessList
            eventId={eventId}
            setEventId={setEventId}
            setIntentId={setIntentId}
            highlightedEvents={[]}
            showServiceColumn={false}
            eventGroups={eventGroups}
            showMoreEventsPanel={true}
            defaultSortKey={ProcessListSortKeys.endtime}
            timeWindow={timeWindow}
            setTimeWindow={(tw) => {
              setTimeWindow({ ...timeWindow, ...tw });
            }}
          />
        </EventsContainer>
      )}
    </>
  );
};
