import { Label } from "recharts";
import React from "react";

import { HeatmapGraphProps } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/heatmapTypes";
import { defaultHeatmapColorScheme } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/defaultHeatmapColorScheme";
import { unixTimeToMilliseconds } from "../../../../../../../../../../../shared/utils/timeUtils";
import { Datapoint } from "../../../../../../../../../../../generated/metricsApi";
import { useViolation } from "../../../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../../../hooks/drawerContentHooks";
import { useGetCorrelatedDeployEvents } from "../../../../shared/metrics/hooks/useGetCorrelatedDeployEvents";

import { formatFullDate } from "./utils";
import { CustomHeatmapGraphTooltip } from "./CustomHeatmapGraphTooltip";

export const useHeatmapGraphProps = (
  dataPoints: Datapoint[] | undefined
): HeatmapGraphProps | undefined => {
  const graphData = dataPoints?.map((dp) => ({
    time: unixTimeToMilliseconds(dp.timestampMs),
    value: dp.value,
  }));

  const { data: violationData } = useViolation();
  const { komodorUid } = useGetCommonDrawerProps(violationData?.data.violation);
  const events = useGetCorrelatedDeployEvents({
    komodorUid: komodorUid ?? "",
    graphDataPoints: graphData,
  });

  if (!graphData) return undefined;

  const firstTick = formatFullDate(graphData[0].time);
  const lastTick = formatFullDate(graphData[graphData.length - 1].time);

  return {
    data: graphData,
    colorScheme: defaultHeatmapColorScheme,
    events,
    tooltipProps: {
      cursor: true,
      content: <CustomHeatmapGraphTooltip />,
    },
    xAxisProps: {
      dataKey: "time",
      tick: false,
      axisLine: false,
      children: [
        <Label
          value={firstTick}
          position={"left"}
          offset={-105}
          fontSize={12}
        />,
        <Label
          value={lastTick}
          position={"right"}
          offset={-105}
          fontSize={12}
        />,
      ],
    },
  };
};
