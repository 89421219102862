import { formatDuration, intervalToDuration } from "date-fns";
import { useCallback, useMemo } from "react";

import { useTimezone } from "../context/TimezoneProvider";

export const defaultOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
  timeZoneName: "short",
};
const formatDistanceLocale: Record<string, string> = {
  xSeconds: "counts",
  xMinutes: "countm",
  xHours: "counth",
  xDays: "countd",
  xWeeks: "countw",
};
export const shortEnLocale: Locale = {
  formatDistance: (token, count) =>
    formatDistanceLocale[token].replace("count", count),
};

function dateIsValid(date: Date): boolean {
  return !Number.isNaN(new Date(date).getTime());
}

export const useDateFormatter = (
  overrideOptions?: Intl.DateTimeFormatOptions
): Omit<Intl.DateTimeFormat, "formatRange" | "formatRangeToParts"> => {
  const { timezone } = useTimezone();
  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat("en", {
        ...defaultOptions,
        ...overrideOptions,
        timeZone: timezone === "default" ? undefined : timezone,
      }),
    [overrideOptions, timezone]
  );
  const format = useCallback(
    (date: Date) =>
      dateIsValid(date)
        ? dateFormatter.format(date).replace("GMT", "UTC")
        : dateFormatter.format(undefined), // In case is invalid the app collapse on RangeError
    [dateFormatter]
  );
  const formatToParts = useCallback(
    (date: Date) => {
      const parts = dateFormatter.formatToParts(date);
      const timeZoneNameIndex = parts.findIndex(
        (part) => part.type === "timeZoneName"
      );
      parts[timeZoneNameIndex]?.value.replace("GMT", "UTC");
      return parts;
    },
    [dateFormatter]
  );
  return {
    format,
    formatToParts,
    resolvedOptions: dateFormatter.resolvedOptions,
  };
};

interface DurationFormatter {
  formatShortDuration: (start: Date, end: Date) => string;
}
export const useDurationFormatter = (): DurationFormatter => {
  const formatShortDuration = useCallback((start: Date, end: Date) => {
    const duration = intervalToDuration({
      start,
      end,
    });
    return formatDuration(duration, {
      format: ["weeks", "days", "hours", "minutes", "seconds"],
      locale: shortEnLocale,
    });
  }, []);
  return {
    formatShortDuration,
  };
};
