import {
  apiV1ConfigGetUrl,
  apiV1ConfigPostUrl,
  CostApiApiV1ConfigGetRequest,
  GetConfigurationResponse,
  UpdateConfigurationRequest,
} from "../../../generated/komodorCost";

export const COST_CONFIG_GET_ENDPOINT = "/config";
export const COST_CONFIG_POST_ENDPOINT = "/config‎";

export const EndpointRequestPathMap = {
  [COST_CONFIG_GET_ENDPOINT]: apiV1ConfigGetUrl,
  [COST_CONFIG_POST_ENDPOINT]: apiV1ConfigPostUrl,
};

export type EndpointsResponseMap = {
  [COST_CONFIG_GET_ENDPOINT]: GetConfigurationResponse;
  [COST_CONFIG_POST_ENDPOINT]: GetConfigurationResponse;
};

export type EndpointsRequestMap = {
  [COST_CONFIG_GET_ENDPOINT]: CostApiApiV1ConfigGetRequest;
  [COST_CONFIG_POST_ENDPOINT]: UpdateConfigurationRequest;
};
