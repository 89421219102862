import { cloneDeep, get, unset } from "lodash";
import { Typography } from "@komodorio/design-system/deprecated";
import React, { useMemo, useRef } from "react";
import YAML from "yaml";

import Resource from "../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResource } from "../useResource";
import { ResourceResultType } from "../types";
import { useIsElementInViewport } from "../../../shared/hooks/useIntersectionObserver";
import {
  ReportEagerLoadingStateParams,
  useReportEagerLoadingState,
} from "../../../shared/hooks/datadog-rum/useReportEagerLoadingState";
import { dataDogViewNames } from "../../../shared/constants/datadog";

import { DescribeLoader } from "./DescribeTab/common/DescribeLoader";
import { DescribeContainer } from "./DescribeTab/common/DescribeSection";

import { LazyEditor } from "@/components/common/LazyEditor";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useResourceObjectAsYaml = (
  fullObj: unknown,
  options?: Options
) => {
  return useMemo(() => {
    if (!options?.dropManagedFields) {
      return YAML.stringify(fullObj, { indent: 2 });
    }

    const clonedResource = cloneDeep(fullObj);
    if (get(clonedResource, "metadata.managedFields")) {
      unset(
        clonedResource as Record<string, unknown>,
        "metadata.managedFields"
      );
    }

    return YAML.stringify(clonedResource, { indent: 2 });
  }, [options?.dropManagedFields, fullObj]);
};

type Options = {
  dropManagedFields?: boolean;
};
interface ResourceYAMLTabProps {
  resource: Resource & { fullObj?: unknown };
  options?: Options;
}

type ExtendedResourceYAMLTabProps = ResourceYAMLTabProps & {
  isDisplayed: boolean;
};

export const ResourceYAMLTabWithData: React.FC<
  ExtendedResourceYAMLTabProps
> = ({ resource, options, isDisplayed }) => {
  const stringifiedResourceAsYaml = useResourceObjectAsYaml(
    resource.fullObj,
    options
  );

  const reportParams: ReportEagerLoadingStateParams = useMemo(
    () => ({
      isDisplayed,
      loadingState: { yaml: !resource.id },
      viewName: dataDogViewNames.resourceViewYamlTab,
    }),
    [isDisplayed, resource.id]
  );

  useReportEagerLoadingState(reportParams);

  if (!resource.id) return <DescribeLoader />;

  const title = `${resource.kind} YAML`;
  if (!stringifiedResourceAsYaml) {
    return (
      <DescribeContainer aria-label={title}>
        <p>{title} is not available.</p>
      </DescribeContainer>
    );
  }

  return (
    <DescribeContainer aria-label={title}>
      <LazyEditor
        width="100%"
        height="80vh"
        readOnly
        value={stringifiedResourceAsYaml}
        mode="yaml"
      />
    </DescribeContainer>
  );
};

export const ResourceYAMLTabWithoutData: React.FC<
  ExtendedResourceYAMLTabProps
> = ({ resource: resourceWithoutData, options, isDisplayed }) => {
  const {
    agentId,
    cluster,
    namespace,
    kind: resourceType,
    name: resourceName,
  } = resourceWithoutData ?? {};
  const { resource, failureMessage } = useResource({
    agentId,
    cluster,
    namespace,
    resourceName,
    resourceType,
    preferredResultType: ResourceResultType.RawResource,
  });

  if (!resource && !failureMessage) {
    return <DescribeLoader />;
  }

  if (failureMessage) {
    return <Typography>{failureMessage}</Typography>;
  }

  return resource ? (
    <ResourceYAMLTabWithData
      resource={resource}
      options={options}
      isDisplayed={isDisplayed}
    />
  ) : null;
};

export const ResourceYAMLTab: React.FC<ResourceYAMLTabProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useIsElementInViewport({ ref: divRef });

  return (
    <div ref={divRef}>
      {props.resource.fullObj ? (
        <ResourceYAMLTabWithData {...props} isDisplayed={isDisplayed} />
      ) : (
        <ResourceYAMLTabWithoutData {...props} isDisplayed={isDisplayed} />
      )}
    </div>
  );
};
