import { useCallback } from "react";

import { useDefaultPolicyConfigurations } from "@/components/reliability/components/pages/policies/policiesHooks";
import { transformConfigurationsToCheckedState } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";
import { CheckType } from "@/generated/reliabilityApi";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";

export const useAddSingleCheckState = () => {
  const defaultConfigurations = useDefaultPolicyConfigurations();
  const { setSingleControlCheckedState } = usePolicyDrawerContext();

  return useCallback(
    (checkType: CheckType) => {
      if (defaultConfigurations) {
        const configurationsAsCheckedState =
          transformConfigurationsToCheckedState(
            defaultConfigurations,
            checkType
          );
        // set checked/unchecked state for this check type
        configurationsAsCheckedState &&
          setSingleControlCheckedState(configurationsAsCheckedState);
      }
    },
    [defaultConfigurations, setSingleControlCheckedState]
  );
};
