import { useMemo } from "react";

import {
  ViolationsGroupBy,
  ViolationsTableConfiguration,
} from "../../../ReliabilityTypes";

export const useScope = (scope: ViolationsTableConfiguration) => {
  return useMemo(() => {
    const hasImpactGroupId = !!scope.impactGroupId?.length;
    const groupBy: ViolationsGroupBy = hasImpactGroupId ? "Impact" : "None";

    return {
      hasImpactGroupId,
      groupBy,
    };
  }, [scope.impactGroupId]);
};
