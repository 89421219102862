import React from "react";

import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import { selectRightSizingRecommendationModal } from "../../../../store/overviewPageStoreSelectors";

import { AppsRightSizingRecommendationModal } from "./AppsRightSizingRecommendationModal";

export const AppsRecommendationModalContainer: React.FC = () => {
  const { rowId } = useOverviewPageStore(selectRightSizingRecommendationModal);
  return <AppsRightSizingRecommendationModal rowId={rowId} />;
};
