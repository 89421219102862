import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuList from "@mui/material/MenuList";

import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import { SearchBar } from "components/komodor-ui/Select/shared/components/SearchBar/SearchBar";
import { SelectPopover } from "components/komodor-ui/Select/shared/components/SelectPopOver";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { placeholderOptionsValue } from "components/komodor-ui/Select/SingleSelect/singleSelectConstants";
import { SingleSelectPopoverProps } from "components/komodor-ui/Select/SingleSelect/singleSelectTypes";
import { SelectItem } from "components/komodor-ui/Select/SingleSelectPopover/SingleSelectItem";
import { VirtualizedMenuList } from "components/komodor-ui/Select/SingleSelectPopover/VirtualizedMenuList";

const MENU_LIST_PADDING = "16px";
const SEARCH_BAR_HEIGHT = "42px";

export const SingleSelectPopover = <T extends MuiSelectionOptionValue>({
  anchorEl,
  options,
  value,
  defaultValue,
  onChange,
  onClose,
  texts,
  classNames,
  enableSearch = false,
  customOptionElement,
  menuListTrailingElement,
  selectPopoverProps,
  enableVirtualization = false,
  virtualizerProps,
}: SingleSelectPopoverProps<T>) => {
  const [localValue, setLocalValue] = useState(defaultValue || value);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onItemSelect = useCallback(
    (value: T) => {
      let selectedOption: MuiSelectionOption<T> | undefined = undefined;
      if (value !== placeholderOptionsValue) {
        selectedOption = options.find((option) => option.value === value);
      }
      setLocalValue(selectedOption);
      onChange?.(selectedOption);
      setSearchTerm("");
      onClose?.();
    },
    [onChange, options]
  );

  const handleClose = useCallback(() => {
    setSearchTerm("");
    onClose?.();
  }, []);

  const filteredOptions = useMemo(
    () =>
      options
        .filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort(
          (a, b) =>
            a.label.toLowerCase().indexOf(searchTerm.toLowerCase()) -
            b.label.toLowerCase().indexOf(searchTerm.toLowerCase())
        ),
    [options, searchTerm]
  );

  const onSearchTermChange = (searchString: string) =>
    setSearchTerm(searchString);

  return (
    <SelectPopover
      anchorEl={anchorEl}
      onClose={handleClose}
      {...selectPopoverProps}
    >
      <div>
        {enableSearch && (
          <SearchBar
            searchValue={searchTerm}
            onSearch={onSearchTermChange}
            className={classNames?.searchBar}
            searchPlaceholder={texts?.searchPlaceholder}
          />
        )}
        {enableVirtualization ? (
          <VirtualizedMenuList
            options={filteredOptions}
            selectedOption={localValue}
            onItemSelect={onItemSelect}
            className={classNames?.menuList}
            customOptionElement={customOptionElement}
            menuListTrailingElement={menuListTrailingElement}
            maxHeight={
              selectPopoverProps.maxHeight
                ? `calc(${selectPopoverProps.maxHeight} - ${MENU_LIST_PADDING}${
                    enableSearch ? ` - ${SEARCH_BAR_HEIGHT}` : ""
                  })`
                : undefined
            }
            virtualizerProps={virtualizerProps}
          />
        ) : (
          <MenuList
            className={classNames?.menuList}
            aria-label={selectAriaLabels.menuList}
          >
            {filteredOptions.map((option) => {
              return (
                <SelectItem
                  key={option.value}
                  option={option}
                  selectedOption={localValue}
                  onSelect={onItemSelect}
                  customOptionElement={customOptionElement}
                />
              );
            })}
            {menuListTrailingElement}
          </MenuList>
        )}
      </div>
    </SelectPopover>
  );
};
