import { useQuery } from "@tanstack/react-query";

import {
  apiV1ConfigurationsHpasEventsSearchGetUrl,
  EventsApiApiV1ConfigurationsHpasEventsIdGetRequest,
  EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest,
  HpaEventsResponse,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";

export const GET_HPA_EVENTS_PATH = "/api/v1/configurations/hpas/events/:id";
export const GET_HPAS_EVENT_SEARCH = "/configurations/hpas/events/search";

type UseHpaEventsByIdParams =
  EventsApiApiV1ConfigurationsHpasEventsIdGetRequest & {
    enabled?: boolean;
  };

export const useHpaEventsById = (params: UseHpaEventsByIdParams) => {
  const apiClient = useResourcesApiClient();

  return useQuery(
    [GET_HPA_EVENTS_PATH, params],
    async () =>
      await apiClient.get<HpaEventsResponse>(
        apiV1ConfigurationsHpasEventsSearchGetUrl(
          params,
          apiClient.defaults.baseURL ?? ""
        )
      )
  );
};

type UseHpaEventsSearchParams =
  EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest & {
    enabled?: boolean;
  };

const searchHpaEvents = async (
  apiClient: ReturnType<typeof useResourcesApiClient>,
  params: UseHpaEventsSearchParams
): Promise<HpaEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1ConfigurationsHpasEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useSearchHpaEvents = (params: UseHpaEventsSearchParams) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([GET_HPAS_EVENT_SEARCH, params]);
  return useQuery(keys, async () => searchHpaEvents(apiClient, params));
};
