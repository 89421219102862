import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { parseKomodorUid } from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { ServicePath } from "./ServicePath";
import { IgnoreCheckRuleOption, radioLabels } from "./ignoreCheckRuleContants";

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: 16px;
    }
    & > span:first-child {
      padding-top: 0;
    }
  }
`;

type IgnoreCheckViolationOptionsProps = {
  komodorUid: string;
  onOptionSelected: (option: IgnoreCheckRuleOption) => void;
  value?: IgnoreCheckRuleOption;
};

export const IgnoreCheckViolationOptions: React.FC<
  IgnoreCheckViolationOptionsProps
> = ({ komodorUid, onOptionSelected, value }) => {
  const { cluster, namespace, name } = parseKomodorUid(komodorUid) ?? {};

  const getLabel = (value: React.ReactNode) => (
    <Typography variant={"body2"}>{value}</Typography>
  );

  const getFormControlLabel = (
    label: JSX.Element,
    value: IgnoreCheckRuleOption
  ) => (
    <StyledFormControlLabel label={label} control={<Radio />} value={value} />
  );

  const serviceLabel = (
    <>
      {getLabel(radioLabels.service.title)}
      <ServicePath serviceName={name} namespace={namespace} cluster={cluster} />
    </>
  );

  const namespaceLabel = (
    <>
      {getLabel(radioLabels.namespace.title)}
      <ServicePath namespace={namespace} cluster={cluster} />
    </>
  );

  const clusterLabel = (
    <>
      {getLabel(radioLabels.cluster.title)}
      <ServicePath cluster={cluster} />
    </>
  );

  const ignoreEntireRuleLabel = (
    <>
      {getLabel(radioLabels.all.title)}
      <Typography variant={"body3"} color={"text.secondary"}>
        {getLabel(radioLabels.all.subtitle)}
      </Typography>
    </>
  );

  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={(_, value) =>
          onOptionSelected(value as IgnoreCheckRuleOption)
        }
      >
        {getFormControlLabel(serviceLabel, "service")}
        {getFormControlLabel(namespaceLabel, "namespace")}
        {getFormControlLabel(clusterLabel, "cluster")}
        {getFormControlLabel(ignoreEntireRuleLabel, "all")}
      </RadioGroup>
    </FormControl>
  );
};
