import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Status } from "@komodorio/design-system/komodor-ui";

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ONGOING = "Ongoing";
const ISSUES_TITLE = "Issues";
const DEPLOYMENTS_TITLE = "Deploys";
const UNHEALTHY_SERVICES = "unhealthy services";

const getTitle = (issuesNum: number, deploysNum: number) => {
  const conjunction = issuesNum > 0 && deploysNum > 0 ? " & " : "";
  return `${ONGOING} ${issuesNum > 0 ? ISSUES_TITLE : ""}${conjunction}${
    deploysNum > 0 ? DEPLOYMENTS_TITLE : ""
  }`;
};

type IssuesHeaderProps = {
  issuesNum: number;
  deploysNum: number;
  unhealthyServicesNum: number;
};

export const IssuesHeader: React.FC<IssuesHeaderProps> = ({
  issuesNum,
  deploysNum,
  unhealthyServicesNum,
}) => {
  const hasIssues = issuesNum > 0;
  const hasDeploys = deploysNum > 0;
  const title = useMemo(
    () => getTitle(issuesNum, deploysNum),
    [issuesNum, deploysNum]
  );
  if (!hasIssues && !hasDeploys) return null;

  return (
    <Container>
      <Typography variant={"h4"}>{title}</Typography>
      <StatusContainer>
        {unhealthyServicesNum > 0 && (
          <Status
            label={`${unhealthyServicesNum} ${UNHEALTHY_SERVICES}`}
            color={"error"}
          />
        )}
        {deploysNum > 0 && (
          <Status
            label={`${deploysNum} ${ONGOING} deployments`}
            color={"running"}
          />
        )}
      </StatusContainer>
    </Container>
  );
};
