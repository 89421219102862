import React, { useEffect } from "react";
import styled from "styled-components";

import {
  DateTimeSelector,
  defaultOptions,
} from "../../../../../../../common/DateTimeSelector";
import {
  SetTimeWindow,
  TimeWindow,
} from "../../../../../../../../shared/types/TimeWindow";
import {
  initialState as initialAppViewState,
  useCurrentAppViewContext,
} from "../../../../../../context/currentAppViewContext";
import getUpdatedTimeWindow from "../../../../../../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { timeWindowToDate } from "../../../../utils/timeWindow";
import { useAppViewsStore } from "../../../../../../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import {
  useUpdatePersistentTimeWindow,
  usePersistentTimeWindowEffect,
} from "./timeSelectorHooks";

const timeOptions = defaultOptions.slice();

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

type OverviewPageTimeSelectorProps = {
  className?: string;
};

export const OverviewPageTimeSelector: React.FC<
  OverviewPageTimeSelectorProps
> = ({ className }) => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const { setTimeWindow, timeWindow } = useCurrentAppViewContext();
  const persistentTimeWindow = usePersistentTimeWindowEffect();
  const updatePersistentTimeWindow = useUpdatePersistentTimeWindow();

  useEffect(() => {
    if (
      !persistentTimeWindow ||
      persistentTimeWindow.id !== selectedAppViewId
    ) {
      setTimeWindow(initialAppViewState.timeWindow);
    } else {
      const { timeWindow } = persistentTimeWindow;
      timeWindow && setTimeWindow(timeWindowToDate(timeWindow));
    }
  }, [persistentTimeWindow, selectedAppViewId, setTimeWindow]);

  const onSetTimeWindow: SetTimeWindow = (tw: Partial<TimeWindow>) => {
    const newTimeWindow: TimeWindow = getUpdatedTimeWindow(timeWindow, tw);
    updatePersistentTimeWindow(newTimeWindow);
  };

  return (
    <Container>
      <DateTimeSelector
        timeWindow={timeWindow}
        setTimeWindow={onSetTimeWindow}
        options={timeOptions}
        className={className}
        forceRightAlign={false}
        showLabel
        showControls
      />
    </Container>
  );
};
