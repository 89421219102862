import { CheckType, ViolationSupportingData } from "@/generated/reliabilityApi";

const getRealtimeSupportingData = (
  checkType: CheckType,
  supportingData: ViolationSupportingData | undefined
) => {
  switch (checkType) {
    case CheckType.UnhealthyNode:
      return supportingData?.unhealthyNode;
    case CheckType.UnhealthyPvc:
      return supportingData?.unhealthyPVC;
    case CheckType.UnhealthyService:
      return supportingData?.unhealthyService;
    case CheckType.UnhealthyWorkflow:
      return supportingData?.unhealthyWorkflow;
    case CheckType.FailedJob:
      return supportingData?.failedJob;
    default:
      return undefined;
  }
};

export const getRealtimeIssueTitle = (
  checkType: CheckType,
  supportingData: ViolationSupportingData | undefined
) => {
  const realtimeSupportingData = getRealtimeSupportingData(
    checkType,
    supportingData
  );

  return realtimeSupportingData?.title;
};
