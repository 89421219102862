import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAiApiClient } from "../apiClient";
import {
  SessionsBody,
  apiV1SessionsPostUrl,
} from "../../../../../generated/aiApi";

import { SESSIONS_PATH } from "./useGetSession";

const postSession = async (apiClient: AxiosInstance, body: SessionsBody) => {
  const { data } = await apiClient.post(
    apiV1SessionsPostUrl(
      { sessionsBody: body ?? {} },
      apiClient.defaults.baseURL ?? ""
    ),
    body ?? {}
  );

  return data;
};

export const usePostSession = (
  body: SessionsBody,
  options?: { onMutate?: () => void }
) => {
  const apiClient = useAiApiClient();
  return useMutation(
    [SESSIONS_PATH, body],
    () => postSession(apiClient, body),
    options
  );
};
