import React, { useRef } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { DataPointsBreakdownPerIntervals } from "../../utils/getDataPointsBreakdownPerIntervals";
import { overviewPageInsightsAriaLabels } from "../../../../../../appViewAriaLabels";

import { getColorScheme } from "./comparisonLinesGraphUtils";
import { useGetMaxLineWidth } from "./comparisonLinesGraphHooks";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div:last-child {
    flex-grow: 1;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LineContainer = styled.div`
  height: 4px;
  margin-left: 6px;
  margin-right: 4px;
`;

const Line = styled.div<{ color: string }>`
  height: 100%;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

const SmallTypography = styled(Typography)`
  ${({ variant }) => variant === "body3" && `&&{font-size:11px;}`}
`;

const TrendTypography = styled(SmallTypography)`
  && {
    margin-left: 4px;
  }
`;

const { linesGraph: linesGraphAriaLabel } = overviewPageInsightsAriaLabels;

export type ComparisonLineGraphsProps = {
  data?: DataPointsBreakdownPerIntervals;
  displayMode: "compact" | "full";
};

export const ComparisonLinesGraph: React.FC<ComparisonLineGraphsProps> = ({
  data,
  displayMode,
}) => {
  const linesContainerRef = useRef<HTMLDivElement>(null);
  const line2ContainerRef = useRef<HTMLDivElement>(null);
  const maxLineWidth = useGetMaxLineWidth({
    data,
    linesContainerRef,
    line2ContainerRef,
  });

  if (!data) return null;

  const {
    firstInterval,
    secondInterval,
    firstTotal,
    secondTotal,
    firstIntervalPercentage,
    secondIntervalPercentage,
    trendPercentage,
  } = data;

  const typographyVariant = displayMode === "compact" ? "body3" : "body2";
  const { color1, color2, diffSign } = getColorScheme(
    firstIntervalPercentage < secondIntervalPercentage
  );

  const firstLineBasis = !maxLineWidth
    ? 100
    : firstIntervalPercentage * maxLineWidth;

  const secondLineBasis = !maxLineWidth
    ? 100
    : secondIntervalPercentage * maxLineWidth;

  return (
    <Container aria-label={linesGraphAriaLabel}>
      <div>
        <SmallTypography
          variant={typographyVariant}
          color={muiColors.gray[600]}
        >
          {firstInterval}
        </SmallTypography>
        <SmallTypography
          variant={typographyVariant}
          color={muiColors.gray[600]}
        >
          {secondInterval}
        </SmallTypography>
      </div>
      <div ref={linesContainerRef}>
        <FlexContainer>
          <LineContainer
            style={{
              flexBasis: `${firstLineBasis}%`,
            }}
          >
            <Line color={color1 ?? ""} />
          </LineContainer>
          <SmallTypography
            variant={typographyVariant}
            color={muiColors.gray[700]}
          >
            {firstTotal}
          </SmallTypography>
        </FlexContainer>
        <FlexContainer>
          <LineContainer
            ref={line2ContainerRef}
            style={{
              flexBasis: `${secondLineBasis}%`,
            }}
          >
            <Line color={color2 ?? ""} />
          </LineContainer>

          <FlexContainer>
            <SmallTypography
              variant={typographyVariant}
              color={muiColors.gray[700]}
            >
              {secondTotal}
            </SmallTypography>
            <TrendTypography
              variant={typographyVariant}
              color={color2}
            >{`${diffSign}${trendPercentage}%`}</TrendTypography>
          </FlexContainer>
        </FlexContainer>
      </div>
    </Container>
  );
};
