import React from "react";
import Button from "@mui/material/Button";

import { CheckType } from "../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../reliabilityArialLabels";
import { getCheckLabel } from "../../../utils/getCheckLabel";

import { useOpenDrawer } from "./IgnoreChecksDrawer/ignoreChecksDrawerHooks";

const {
  ignoreChecks: { manageExclusions: manageExclusionsAriaLabel },
} = reliabilityArialLabels;

type ManageExclusionsButtonProps = {
  checkType: CheckType;
};

export const ManageExclusionsButton: React.FC<ManageExclusionsButtonProps> = ({
  checkType,
}) => {
  const openDrawer = useOpenDrawer();
  const title = getCheckLabel(checkType);
  const ariaLabel = `${manageExclusionsAriaLabel} ${title}`;
  return (
    <Button
      variant={"outlined"}
      onClick={() => openDrawer(checkType)}
      aria-label={ariaLabel}
    >
      Manage exclusions
    </Button>
  );
};
