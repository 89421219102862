import DeployEventGroup from "../../../../../../../../common/EventGroup/deployEvent/DeployEventGroup";

export const getDeployDetails = (deployEvent: DeployEventGroup) => {
  const { clusterName, namespace, serviceId } = deployEvent.events[0];
  const slicedServiceId = serviceId.split(`${namespace}.`).at(-1);

  return {
    clusterName,
    namespace,
    slicedServiceId,
  };
};
