import { useMemo } from "react";
import { toNumber } from "lodash";

import { Args as UseInitiateSocketSession } from "../../../sockets/useInitiateSocketSession";
import { logsCommandOptions } from "../constants";
import { useOverridableFlags } from "../../../context/featureFlags/OverridableFlags";

interface Args {
  containerName?: string;
  podName: string;
  namespace: string;
  sinceTime?: string;
  isPreviousLogs?: boolean;
}

export const useGetLivePodLogsInitData = ({
  containerName,
  podName,
  namespace,
  sinceTime,
  isPreviousLogs,
}: Args) => {
  const { podsLogsTail } = useOverridableFlags();

  return useMemo((): UseInitiateSocketSession["initData"] => {
    if (!containerName || !podName) {
      return {};
    }

    return {
      podName,
      containerName,
      namespace,
      podLogOptions: {
        tailLines: podsLogsTail
          ? toNumber(podsLogsTail)
          : logsCommandOptions.tail,
        follow: true,
        sinceTime,
        previous: Boolean(isPreviousLogs),
      },
    };
  }, [
    containerName,
    namespace,
    podName,
    podsLogsTail,
    sinceTime,
    isPreviousLogs,
  ]);
};
