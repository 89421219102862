import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";

import { IgnoreRule } from "../../../../../../../generated/reliabilityApi";
import { GrayLabelBox } from "../../../../../ReliabilityStyles";
import { StyledArrow } from "../../ignoreChecksStyles";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";

import { IgnoreChecksTextWithTooltip } from "./IgnoreChecksTextWithTooltip";
import { useDeleteRule } from "./hooks/useDeleteRule";

const Container = styled.div`
  padding: 16px 24px;
  width: 600px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const StyledGrayLabelBox = styled(GrayLabelBox)`
  margin: 8px 0 16px 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const {
  ignoreChecks: { deleteButton: deleteButtonAriaLabel },
} = reliabilityArialLabels;

type DeleteRuleDialogProps = {
  ignoreRule: IgnoreRule;
  open: boolean;
  onClose: () => void;
};

export const DeleteRuleDialog: React.FC<DeleteRuleDialogProps> = ({
  ignoreRule,
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, reRender] = useState(false);
  const deleteRule = useDeleteRule();

  const handleDeleteRule = async () => {
    setIsLoading(true);
    await deleteRule(ignoreRule);
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    // workaround for div refs in <IgnoreChecksTextWithTooltip> having 0 width on first render
    reRender(open);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <Container>
        <Title variant={"h3"}>Remove exception?</Title>
        <Typography variant={"body2"} color={"text.secondary"}>
          Are you sure you want to remove the following exception from the check
          policy:
        </Typography>
        <StyledGrayLabelBox>
          <ParseIgnoreRule ignoreRule={ignoreRule} />
        </StyledGrayLabelBox>
        <Footer>
          <Button variant={"outlined"} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            onClick={handleDeleteRule}
            loading={isLoading}
            aria-label={deleteButtonAriaLabel}
          >
            Remove exception
          </LoadingButton>
        </Footer>
      </Container>
    </Dialog>
  );
};

const ParsedIgnoreRuleContainer = styled.div`
  display: flex;
  & > div {
    max-width: 150px;
    white-space: nowrap;
    width: fit-content;
  }
`;

const ParseIgnoreRule: React.FC<{ ignoreRule: IgnoreRule }> = ({
  ignoreRule,
}) => {
  return (
    <ParsedIgnoreRuleContainer>
      <IgnoreChecksTextWithTooltip
        ignoreRule={ignoreRule}
        scopeType={"clusterNames"}
      />
      <StyledArrow />
      <IgnoreChecksTextWithTooltip
        ignoreRule={ignoreRule}
        scopeType={"namespaces"}
      />
      <StyledArrow />
      <IgnoreChecksTextWithTooltip
        ignoreRule={ignoreRule}
        scopeType={"shortResourceNames"}
      />
    </ParsedIgnoreRuleContainer>
  );
};
