import React, { useEffect } from "react";

import { SelfSupplyingResourceListTableProps } from "../types";
import ResourceListTable from "../../../../../../common/ResourceListTable/ResourceListTable";
import { useNamesExtractionFromService } from "../../../../../../../shared/hooks/useNamesExtractionFromService";
import { generateUid } from "../../../../../../../shared/utils/generateUid";
import { PvcNamesExtractor } from "../../../../../../../shared/utils/ExtractNamesFromService/PvcNamesExtractor";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";
import { useRelatedResourcesStore } from "../../../../../../../shared/store/relatedResourcesStore/relatedResourcesStore";
import { setFetchingStateSelector } from "../../../../../../../shared/store/relatedResourcesStore/relatedResourcesSelectors";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

export const PvcsListTable: React.FC<SelfSupplyingResourceListTableProps> = ({
  serviceId,
  namespace,
  cluster,
  searchTerm,
  rowSelection,
  showOnlySelectedRows,
  ...props
}) => {
  const [pvcNames, refreshPvcNames, isLoading] = useNamesExtractionFromService(
    serviceId,
    new PvcNamesExtractor()
  );
  const pvcs = pvcNames.map((pvcName) => ({
    uid: generateUid({ name: pvcName, namespace: namespace ?? "", cluster }),
    name: pvcName,
  }));

  const filteredPvcs = filterResourceTableBySearchTerm(pvcs, searchTerm);

  const unselectableRowNames = getUnselectableRowNames(pvcs, rowSelection);

  const setFetchingState = useRelatedResourcesStore(setFetchingStateSelector);

  useEffect(() => {
    setFetchingState({
      key: "isFetchingPvcTable",
      value: isLoading,
    });
  }, [isLoading, setFetchingState]);

  return (
    <ResourceListTable
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredPvcs, rowSelection?.selectedRowNames ?? [])
          : filteredPvcs
      }
      cluster={cluster}
      refreshResults={refreshPvcNames}
      overridingColumns={overridingColumns}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
];
