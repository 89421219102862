import React, { memo, useMemo } from "react";
import { get } from "lodash";

import { formatTooltipCPUTick, formatTooltipMemoryTick } from "../utils";
import {
  CapacityIcon,
  LimitIcon,
  RequestIcon,
  UsageIcon,
  RecommendationIcon,
  AllocatableIcon,
} from "../Legend";
import {
  CpuUnit,
  GraphDataPoint,
  MemoryUnit,
  MetricName,
  MetricsAggregationType,
} from "../types";
import {
  getColorByIndex,
  isMultilineItem,
  splitStringToItem,
} from "../graphs/graphUtils";

import { useTooltipMetricsAnalytics } from "./useTooltipMetricsAnalytics";
import { isTooltipDataValid } from "./tooltipUtils";
import { TooltipContainer } from "./Tooltip";
import { TooltipItem } from "./tooltipTypes";

type ResourceTooltipProps = {
  payload: GraphDataPoint;
  label: number;
  dataTransformFunction: (data: string) => { value: string; format: string };
  aggregationType?: MetricsAggregationType;
  lineNames?: MetricName[];
  getCustomTooltipEntries?: (payload: GraphDataPoint) => TooltipItem[];
};

const ResourceTooltip = memo(
  ({
    payload,
    label,
    dataTransformFunction,
    aggregationType,
    lineNames,
    getCustomTooltipEntries,
  }: ResourceTooltipProps) => {
    const wantedPayload = useMemo(() => {
      if (!lineNames) return payload;
      return Object.keys(payload).reduce<GraphDataPoint>(
        (acc, key) => {
          if (lineNames.includes(key as MetricName)) {
            acc[key as MetricName] = payload[key as MetricName];
          }
          return acc;
        },
        { time: 0 }
      );
    }, [lineNames, payload]);

    const { value: usageValue, format: usageFormat } = useMemo(() => {
      if (!aggregationType || aggregationType === MetricsAggregationType.Avg) {
        return dataTransformFunction(
          wantedPayload?.usageBytes?.toString() ?? ""
        );
      }
      return dataTransformFunction("");
    }, [aggregationType, dataTransformFunction, wantedPayload?.usageBytes]);

    const { value: p90, format: p90Format } = useMemo(() => {
      if (aggregationType === MetricsAggregationType.P90) {
        return dataTransformFunction(wantedPayload?.p90?.toString() ?? "");
      }
      return dataTransformFunction("");
    }, [aggregationType, dataTransformFunction, wantedPayload?.p90]);

    const { value: p95, format: p95Format } = useMemo(() => {
      if (aggregationType === MetricsAggregationType.P95) {
        return dataTransformFunction(wantedPayload?.p95?.toString() ?? "");
      }
      return dataTransformFunction("");
    }, [aggregationType, dataTransformFunction, wantedPayload?.p95]);

    const { value: p99, format: p99Format } = useMemo(() => {
      if (aggregationType === MetricsAggregationType.P99) {
        return dataTransformFunction(wantedPayload?.p99?.toString() ?? "");
      }
      return dataTransformFunction("");
    }, [aggregationType, dataTransformFunction, wantedPayload?.p99]);

    const { value: max, format: maxFormat } = useMemo(() => {
      if (aggregationType === MetricsAggregationType.Max) {
        return dataTransformFunction(wantedPayload?.max?.toString() ?? "");
      }
      return dataTransformFunction("");
    }, [aggregationType, dataTransformFunction, wantedPayload?.max]);

    const multiLineItemsKeys = useMemo(() => {
      const payloadKeys = Object.keys(wantedPayload);
      return payloadKeys.filter((key) => isMultilineItem(key));
    }, [wantedPayload]);

    const multiLineItems = useMemo(
      () =>
        multiLineItemsKeys.map((rawKey) => {
          const item = splitStringToItem(rawKey);
          const rawValue =
            aggregationType?.toLowerCase() === item.metric?.toLowerCase()
              ? get(wantedPayload, rawKey, "")
              : "";

          const { value, format } = dataTransformFunction(rawValue);
          return {
            title: `${aggregationType} of ${item.name}`,
            key: rawKey,
            value,
            format,
            icon: <UsageIcon fillColor={getColorByIndex(item.index)} />,
          };
        }),
      [
        aggregationType,
        dataTransformFunction,
        multiLineItemsKeys,
        wantedPayload,
      ]
    );

    const { value: capacityValue, format: capacityFormat } =
      dataTransformFunction(wantedPayload?.capacityBytes?.toString() ?? "");
    const { value: requestValue, format: requestFormat } =
      dataTransformFunction(wantedPayload?.requestBytes?.toString() ?? "");
    const { value: allocatableValue, format: allocatableFormat } =
      dataTransformFunction(wantedPayload?.allocatableBytes?.toString() ?? "");
    const { value: limitValue, format: limitFormat } = dataTransformFunction(
      wantedPayload?.limitBytes?.toString() ?? ""
    );
    const { value: recommendationValue, format: recommendationFormat } =
      dataTransformFunction(
        wantedPayload?.recommendationBytes?.toString() ?? ""
      );

    useTooltipMetricsAnalytics();

    return (
      <TooltipContainer
        label={label}
        tooltipItems={[
          ...multiLineItems,
          ...(getCustomTooltipEntries?.(wantedPayload) ?? []),
          {
            title: "USAGE",
            key: "usage",
            value: usageValue,
            format: usageFormat,
            icon: <UsageIcon />,
          },
          {
            title: "RECOMMENDATION",
            key: "moderate",
            value: recommendationValue,
            format: recommendationFormat,
            icon: <RecommendationIcon />,
          },
          {
            title: "P90",
            key: "p90",
            value: p90,
            format: p90Format,
            icon: <UsageIcon />,
          },
          {
            title: "P95",
            key: "p95",
            value: p95,
            format: p95Format,
            icon: <UsageIcon />,
          },
          {
            title: "P99",
            key: "p99",
            value: p99,
            format: p99Format,
            icon: <UsageIcon />,
          },
          {
            title: "MAX",
            key: "max",
            value: max,
            format: maxFormat,
            icon: <UsageIcon />,
          },
          {
            title: "CAPACITY",
            key: "capacity",
            value: capacityValue,
            format: capacityFormat,
            icon: <CapacityIcon />,
          },
          {
            title: "ALLOCATABLE",
            key: "allocatable",
            value: allocatableValue,
            format: allocatableFormat,
            icon: <AllocatableIcon />,
          },
          {
            title: "REQUEST",
            key: "request",
            value: requestValue,
            format: requestFormat,
            icon: <RequestIcon />,
          },
          {
            title: "LIMIT",
            key: "limit",
            value: limitValue,
            format: limitFormat,
            icon: <LimitIcon />,
          },
        ]}
      />
    );
  }
);

export interface Payload {
  payload: GraphDataPoint;
}

export type MetricsTooltipProps = Pick<
  ResourceTooltipProps,
  "aggregationType" | "lineNames" | "getCustomTooltipEntries"
> & {
  payload?: unknown[];
  label?: number;
  unit?: MemoryUnit | CpuUnit;
};

type MetricsChartTooltipProps = MetricsTooltipProps & {
  dataTransformFunction: (data: string) => { value: string; format: string };
};

export const MetricsChartTooltip: React.FC<MetricsChartTooltipProps> = (
  props
) => {
  if (!isTooltipDataValid(props)) return null;

  return (
    <ResourceTooltip
      payload={(props.payload?.[0] as Payload).payload}
      label={props.label ?? 0}
      dataTransformFunction={props.dataTransformFunction}
      getCustomTooltipEntries={props.getCustomTooltipEntries}
      aggregationType={props.aggregationType}
      lineNames={props.lineNames}
    />
  );
};

export const MemoryMetricsChartTooltip: React.FC<MetricsTooltipProps> = (
  props
) => {
  return (
    <MetricsChartTooltip
      {...props}
      dataTransformFunction={(tick: string) =>
        formatTooltipMemoryTick(tick, props.unit as MemoryUnit)
      }
    />
  );
};

export const CPUMetricsChartTooltip: React.FC<MetricsTooltipProps> = (
  props
) => {
  return (
    <MetricsChartTooltip
      {...props}
      dataTransformFunction={(tick: string) =>
        formatTooltipCPUTick(tick, props.unit as CpuUnit)
      }
    />
  );
};
