import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";

import { AppViewUrlParam } from "../../../../../types/urlParams";
import { useCreationPageContext } from "../context/CreationPageContext";
import {
  isAppViewDataComplete,
  useGetAppViewData,
} from "../../../hooks/overviewPageHooks";
import { stringListToDictionary } from "../utils/creationPageUtils";
import { CurrentAppView } from "../../../../../types/CurrentAppView";
import { getOverviewPageRoute } from "../../../utils/getRoutes";
import { useGetServiceIdsByServiceIdentifier } from "../../../hooks/appViewsHooks";
import {
  currentAppViewSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import { useAppViewsResourcesStore } from "../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { selectResetResources } from "../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { AppErrors } from "../types/creationPageTypes";

import { useSetNameError } from "./useVerifyCreationForm";

export const useIsEditMode = (): boolean => {
  const { id } = useParams<AppViewUrlParam>();
  return !!id;
};

export const usePopulateDataInEditModeEffect = (): void => {
  const dataWasSet = useRef<boolean>(false);
  const isEditMode = useIsEditMode();
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const { setDescription, setAppName, setSelectedServiceIds } =
    useCreationPageContext();
  const getAppViewData = useGetAppViewData();
  const isAppViewDataReady = isAppViewDataComplete(currentAppView);
  const getServiceIdsByServiceIdentifier =
    useGetServiceIdsByServiceIdentifier();

  useEffect(() => {
    if (isEditMode && isAppViewDataReady && !dataWasSet.current) {
      const { name, description, value, kind } = currentAppView;
      const serviceIds = getServiceIdsByServiceIdentifier(value, kind);
      if (!serviceIds) return;
      dataWasSet.current = true;
      setDescription(description || "");
      setAppName(name || "");

      setSelectedServiceIds(stringListToDictionary(serviceIds || []));
    }
  }, [
    currentAppView,
    getAppViewData,
    getServiceIdsByServiceIdentifier,
    isAppViewDataReady,
    isEditMode,
    setAppName,
    setDescription,
    setSelectedServiceIds,
  ]);
};

export const useResetCreationPageStateEffect = (): void => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const { resetState } = useCreationPageContext();
  const isEditMode = useIsEditMode();
  const isAppViewDataReady = isAppViewDataComplete(currentAppView);

  const stateWasReset = useRef<boolean>(false);
  useEffect(() => {
    if (!isEditMode && isAppViewDataReady && !stateWasReset.current) {
      // creationPage is displayed with data, but we're not in 'edit' mode
      stateWasReset.current = true;
      resetState();
    }
  }, [isAppViewDataReady, isEditMode, resetState]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useVerifyServerResponse = (): ((error?: any) => boolean) => {
  const { setErrorState } = useCreationPageContext();
  const setNameError = useSetNameError();

  return useCallback(
    (error?) => {
      if (!error) return true;
      if ((error.response?.status || 0) === 409) {
        setNameError(AppErrors.NameIsNotUnique);
      } else {
        setErrorState({ savingAppViewError: true });
      }
      return false;
    },
    [setErrorState, setNameError]
  );
};

export const useSetAppViewAndNavigate = (): ((
  appView: CurrentAppView
) => void) => {
  const navigate = useNavigate();
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const resetResources = useAppViewsResourcesStore(selectResetResources);

  return useCallback(
    (appView: CurrentAppView) => {
      const appViewId = appView.id || undefined;
      setSelectedAppViewId(appViewId || undefined);
      resetResources();
      appViewId && navigate(getOverviewPageRoute(appViewId));
    },
    [navigate, resetResources, setSelectedAppViewId]
  );
};
