import React, {
  Children,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

const HeightContainer = styled.div`
  height: 100%;
`;

type InfiniteScrollerContainerProps = PropsWithChildren<{
  scrollSize?: number;
}>;

export const InfiniteScrollerContainer: React.FC<
  InfiniteScrollerContainerProps
> = ({
  children,
  scrollSize: initialScrollSize = 20,
}: InfiniteScrollerContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollSize, setScrollSize] = useState<number>(initialScrollSize);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const childrenAsArray = Children.toArray(children);
  const dataLength = childrenAsArray.length;
  const partialChildren = Children.toArray(children)?.slice(0, scrollSize);

  useEffect(() => {
    if (dataLength && containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, [dataLength]);

  const onNext = () => {
    setScrollSize(scrollSize + initialScrollSize);
  };

  if (!children || !dataLength) return null;
  // get dynamic container height, then render InfiniteScroll
  if (!containerHeight) return <HeightContainer ref={containerRef} />;

  return (
    <InfiniteScroll
      dataLength={scrollSize}
      loader={null}
      next={onNext}
      hasMore={partialChildren.length < dataLength}
      height={containerHeight}
    >
      {partialChildren}
    </InfiniteScroll>
  );
};
