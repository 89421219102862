// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  UPGRADE_COMMANDS,
  UpgradeCommands,
} from "../../components/common/upgradeAgentCta/UpgradeAgentCTA";

import useAgents, {
  AgentType,
  findActiveAgentByClusterName,
} from "./useAgents";
import { Configuration } from "./useAgentInfo/useAgentInfo";

const UNKNOWN_COMMAND =
  "That's a shame. We failed to construct an upgrade command. Please contact us";
export const useGetHelmInstallationCommand = (
  upgradeCommand: UpgradeCommands
): string => {
  return UPGRADE_COMMANDS[upgradeCommand] || UNKNOWN_COMMAND;
};

type AgentUpgradeCommand = {
  agentVersion: string | undefined;
  clusterNames: string[];
  helmCommand: string;
};

const filterDefinedAgentsTypes = (
  agentType: AgentType | undefined
): agentType is AgentType => agentType !== undefined;

export const useGetHelmInstallationCommands = (
  upgradeCommand: UpgradeCommands,
  clusterNames: string[]
): AgentUpgradeCommand[] | undefined => {
  const allAgents = useAgents();

  if (!allAgents) {
    return undefined;
  }

  const agentTypes = clusterNames.map((clusterName) =>
    findActiveAgentByClusterName(allAgents, clusterName)
  );
  const chartVersions = new Set(
    clusterNames
      .map((clusterName) =>
        findActiveAgentByClusterName(allAgents, clusterName)
      )
      .filter(filterDefinedAgentsTypes)
      .map((agent) => (agent?.configuration as Configuration).chartversion)
  );

  const getClusterNamesByChartVersion = (chartVersion: string | undefined) =>
    agentTypes
      .filter(filterDefinedAgentsTypes)
      .filter(
        (agentType) =>
          (agentType?.configuration as Configuration).chartversion ===
          chartVersion
      );

  return Array.from(chartVersions.values()).map((chartVersion) => {
    const clusters = getClusterNamesByChartVersion(chartVersion);
    return {
      agentVersion: chartVersion,
      clusterNames: clusters.map((agent) => agent?.clusterName),
      helmCommand: UPGRADE_COMMANDS[upgradeCommand] || UNKNOWN_COMMAND,
    } as AgentUpgradeCommand; // TODO: get someone to validate this is fine
  });
};
