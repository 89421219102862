import React from "react";
import { IconProps } from "@komodorio/design-system/icons";

export const BlastIcon: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M11.5429 11.1825L16 8.18927L11.6001 7.19139L12.7715 2.06933L9.17133 4.89645L7.5 4L6.51417 5.4287L1 0.000244141L4.5 8L0 8.95413L4.0572 10.9828L1.91432 16.4705L6.62862 13.4773L8.11427 17.0693L9.60005 13.6437L12.5713 14.841L11.5429 11.1825Z"
        fill="#FFAD33"
      />
    </svg>
  );
};
