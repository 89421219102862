import React from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useWorkloadContainerGraph } from "../../hooks/useWorkloadContainerGraph";
import { EnlargedMetricsGraph } from "../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { WorkloadMetricsPreferences } from "../WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { NoLimitPerOneContainerLabel } from "../../../../../Metrics/NoLimitLabel";
import { ResourceGraph } from "../../../../../Metrics/graphs/ResourceGraph";
import Resource from "../../../../resources";
import { MetricType } from "../../../../../Metrics/types";
import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { useFetchContainersData } from "../../hooks/useFetchContainersData";

import { ContainerSelector } from "./ContainerSelector";

type EnlargedContainerGraphProps = {
  resource: Resource;
  type: MetricType;
  initialWantedContainer: MuiSelectionOption<string> | undefined;
  endTime: number;
  onClose: () => void;
};
export const EnlargedContainerGraph: React.FC<EnlargedContainerGraphProps> = ({
  resource,
  type,
  initialWantedContainer,
  endTime,
  onClose,
}) => {
  const { initialLoadTime } = useWorkloadMetricsTabContext();
  const { containers, wantedContainer, setWantedContainer } =
    useFetchContainersData({ resource, initialWantedContainer, endTime });
  const { loading, error, hasGraphData, uid, graphProps, hasLimit } =
    useWorkloadContainerGraph({
      resource,
      type,
      wantedContainer,
      endTime: endTime || initialLoadTime,
    });

  const title = type === "cpu" ? "Cpu Usage" : "Memory Usage";

  return (
    <EnlargedMetricsGraph
      title={title}
      controls={[
        <ContainerSelector
          containers={containers}
          wantedContainer={wantedContainer}
          onChange={setWantedContainer}
        />,
        <WorkloadMetricsPreferences />,
      ]}
      info={
        !loading && !error && !hasLimit ? <NoLimitPerOneContainerLabel /> : null
      }
      onClose={onClose}
      uid={uid}
      hasGraphData={hasGraphData}
      loading={loading}
      error={error}
    >
      <ResourceGraph type={type} {...graphProps} data={graphProps.data} />
    </EnlargedMetricsGraph>
  );
};
