import React, { useMemo } from "react";

import { MonitorData } from "../../generated/monitorsApi";
import { ServiceInfo } from "../../shared/types/ServiceInfo";
import { WorkflowConfigType } from "../monitorsView/common/types";
import EventGroup from "../common/EventGroup";
import { ZIndex } from "../../constants/zIndex";
import { useMinifiedMonitorEventGroupById } from "../monitorsView/useWorkflowsRuns";
import WorkflowIssueEventGroup from "../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { SYSTEM_WIDE_SERVICE_ID } from "../../shared/constants/systemWideServiceId";
import { AriaLabels } from "../../shared/config/ariaLabels";

import {
  OpenAvailabilityIssueAlertTooltip,
  OPEN_AVAILABILITY_ISSUE_ALERT_TOOLTIP,
} from "./tooltips/OpenAvailabilityIssueAlertTooltip";
import {
  OpenJobIssueAlertTooltip,
  OPEN_JOB_ISSUE_ALERT_TOOLTIP,
} from "./tooltips/OpenJobIssueAlertTooltip";
import {
  OpenNodeIssueAlertTooltip,
  OPEN_NODE_ISSUE_ALERT_TOOLTIP,
} from "./tooltips/OpenNodeIssueAlertTooltip";
import {
  OpenPVCIssueAlertTooltip,
  OPEN_PVC_ISSUE_ALERT_TOOLTIP,
} from "./tooltips/OpenPVCIssueAlertTooltip";
import {
  OpenPodIssueAlertTooltip,
  OPEN_POD_ISSUE_ALERT_TOOLTIP,
} from "./tooltips/OpenPodIssueAlertTooltip";

export const useOpenIssueTooltip = (
  openIssue: MonitorData | undefined,
  service: ServiceInfo | undefined,
  setCorrelatedEvent: (event: EventGroup | null) => void,
  rowId = "",
  isFirstCell = false
): [React.ReactNode, string, WorkflowIssueEventGroup | undefined, string] => {
  const zIndex = isFirstCell ? ZIndex.AboveAppBar : undefined;
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: openIssue?.id ?? "",
    serviceId: service?.id ?? SYSTEM_WIDE_SERVICE_ID,
    type: openIssue?.type,
  });

  return useMemo(() => {
    if (!openIssue || (service && service.healthy))
      return [undefined, "", undefined, ""];
    switch (openIssue.type) {
      case WorkflowConfigType.Availability: {
        const tooltipId = `${OPEN_AVAILABILITY_ISSUE_ALERT_TOOLTIP}-${rowId}`;
        return [
          <OpenAvailabilityIssueAlertTooltip
            tooltipId={tooltipId}
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            service={service}
            zIndex={zIndex}
          />,
          tooltipId,
          correlatedIssue,
          AriaLabels.OpenIssue.AvailabilityIssueTooltipLink,
        ];
      }
      case WorkflowConfigType.Workflow:
      case WorkflowConfigType.Pod: {
        const tooltipId = `${OPEN_POD_ISSUE_ALERT_TOOLTIP}-${rowId}`;
        return [
          <OpenPodIssueAlertTooltip
            tooltipId={tooltipId}
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            zIndex={zIndex}
          />,
          tooltipId,
          correlatedIssue,
          AriaLabels.OpenIssue.PodIssueTooltipLink,
        ];
      }
      case WorkflowConfigType.NodeIssue: {
        const tooltipId = `${OPEN_NODE_ISSUE_ALERT_TOOLTIP}-${rowId}`;
        return [
          <OpenNodeIssueAlertTooltip
            tooltipId={tooltipId}
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            zIndex={zIndex}
          />,
          tooltipId,
          correlatedIssue,
          AriaLabels.OpenIssue.NodeIssueTooltipLink,
        ];
      }
      case WorkflowConfigType.PVC: {
        const tooltipId = `${OPEN_PVC_ISSUE_ALERT_TOOLTIP}-${rowId}`;
        return [
          <OpenPVCIssueAlertTooltip
            tooltipId={tooltipId}
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            zIndex={zIndex}
          />,
          tooltipId,
          correlatedIssue,
          AriaLabels.OpenIssue.PVCIssueTooltipLink,
        ];
      }
      case WorkflowConfigType.CronJob:
      case WorkflowConfigType.Job: {
        const tooltipId = `${OPEN_JOB_ISSUE_ALERT_TOOLTIP}-${rowId}`;
        return [
          <OpenJobIssueAlertTooltip
            tooltipId={tooltipId}
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            zIndex={zIndex}
          />,
          tooltipId,
          correlatedIssue,
          AriaLabels.OpenIssue.JobIssueTooltipLink,
        ];
      }
      default:
        return [undefined, "", undefined, ""];
    }
  }, [correlatedIssue, openIssue, rowId, service, setCorrelatedEvent, zIndex]);
};
