import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { KomoBot24 } from "@komodorio/design-system/icons";

import { RichSessionData } from "../common/useEnrichedSession";

import { DetailsContainer, ResultLineContainer, ResultTitle } from "./styles";

const ProblemShortContainer = styled.div`
  background-color: ${muiColors.indigo[25]};
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  gap: 8px;
`;

export const WhatHappened: React.FC<{ session: RichSessionData }> = ({
  session,
}) => {
  return (
    <ResultLineContainer>
      <ResultTitle>What Happened:</ResultTitle>
      <DetailsContainer>
        {session.problemShort && (
          <ProblemShortContainer>
            <KomoBot24 color={muiColors.indigo[500]} />
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {session.problemShort}
            </Typography>
          </ProblemShortContainer>
        )}
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          <ol
            style={{
              margin: "0",
              listStylePosition: "inside",
              padding: "0",
            }}
          >
            {session.whatHappened?.map((line, i) => (
              <li
                style={{
                  padding: "5.5px 0",
                }}
                key={i}
              >
                {line}
              </li>
            ))}
          </ol>
        </Typography>
      </DetailsContainer>
    </ResultLineContainer>
  );
};
