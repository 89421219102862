import React from "react";
import styled from "styled-components";

import { blueBrand } from "../../Colors";

import emptyStateSVGUrl from "./emptystate.svg";
import { KomodorServiceType } from "./types";

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: Poppins;
  align-content: center;
  justify-content: center;
  justify-self: center;
  align-items: center;
  margin-top: 6rem;
  gap: 1rem;
`;

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${blueBrand};
  width: 15rem;
  height: 2.5rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
`;

const H3 = styled.div`
  color: #3d4048;
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: bold;
`;

const H4 = styled.div`
  flex-direction: column;
  color: #3d4048;
  font-family: Roboto;
  width: 16rem;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
`;

export const AgentNotInstalledState: React.FC<{
  komodorServiceType: KomodorServiceType;
  setOpen: (open: boolean) => void;
}> = ({ komodorServiceType, setOpen }) => {
  return (
    <EmptyStateContainer>
      <img src={emptyStateSVGUrl} />
      <H3 data-e2e-selector="empty-state-title">
        No {komodorServiceType}s... yet
      </H3>
      <H4>
        Install the Komodor cluster agent to view and monitor your{" "}
        {komodorServiceType}s
      </H4>
      <Button
        data-e2e-selector="add-k8s-cluster-button"
        onClick={() => setOpen(true)}
      >
        Add a Kubernetes cluster
      </Button>
    </EmptyStateContainer>
  );
};
