import { isEmpty, sortBy, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { dispatchEvent } from "../../../shared/hooks/analytics";
import {
  AllServicesAttributes,
  useServiceAttributesForAccount,
} from "../../servicesView/servicesAttributes/service-attributes-fetch-hook";
import { useListServiceAttributesForAccountQuery } from "../../../generated/graphql";
import EventGroup from "../../common/EventGroup";
import { pushToMap } from "../../common/EventGroup/groupEvents";
import Selected from "../../../shared/components/FilterBar/Interfaces/Selected";
import { updateSelected } from "../../../shared/components/FilterBar/utils/utils";
import useFollowingState from "../../../shared/hooks/useFollowingState";
import { intersection } from "../../../shared/utils/collections/collections";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { FILTERS_BAR_FILTERS_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { KOMODOR_SERVICES_SEARCH } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { KomodorServicesResponseDataInner } from "../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../../shared/hooks/resources-api/client/apiClient";
import { useWorkspaceQueryKey } from "../../../shared/hooks/workspaces-api/useWorkspaceQueryKey";
import { fetchKomodorServices } from "../../../shared/hooks/resources-api/client/komodor-services/useKomodorServices";

import EventsFiltersCategory from "./EventsFiltersCategory";
import eventsViewFiltersSelectors, {
  IndependentFilter,
  selectWithoutService,
} from "./eventsViewFiltersSelectors";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

interface EventsFilter {
  categories: EventsFiltersCategory[];
  filteredEventGroups: EventGroup[];
  selected: Selected;
  setSelected: (selected: Selected) => void;
  clear: (() => void) | undefined;
  isFetching: boolean;
}
interface FilterEventGroups {
  categoriesData: {
    name: string;
    values: { value: string; count: number }[];
  }[];
  filteredEventGroups: EventGroup[];
  isFetching: boolean;
}

interface ServiceMapResult {
  services: Map<string, KomodorServicesResponseDataInner>;
  isFetching: boolean;
}

export const useServiceMap = (timeWindow: TimeWindow): ServiceMapResult => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([KOMODOR_SERVICES_SEARCH, timeWindow]);
  const { data, isLoading } = useQuery(keys, async () =>
    fetchKomodorServices(apiClient, {
      fields: [
        "id",
        "isDeleted",
        "k8sClusterName",
        "namespace",
        "displayName",
        "kind",
      ],
      deletedFromEpoch: timeWindow.start.getTime().toString(),
      deletedToEpoch: timeWindow.end.getTime().toString(),
    })
  );
  return useMemo(() => {
    return {
      services: new Map(data?.data?.map((s) => [s.id ?? "", s])),
      isFetching: isLoading,
    };
  }, [data?.data, isLoading]);
};

const useAttributesForAccount = () => {
  const [{ data: serviceAttsConfigs }] =
    useListServiceAttributesForAccountQuery();
  return useServiceAttributesForAccount(
    serviceAttsConfigs?.service_attributes_for_account
  );
};

export const useFilteredEventGroups = (
  selected: Selected,
  eventGroups: EventGroup[],
  allServiceAttributes: AllServicesAttributes,
  timeWindow: TimeWindow
): FilterEventGroups => {
  const { services, isFetching } = useServiceMap(timeWindow);
  const attributesForAccount = useAttributesForAccount();
  const fullFilters = useMemo(() => {
    if (!attributesForAccount) return eventsViewFiltersSelectors;
    const filtersFromAttributes = Object.keys(attributesForAccount)
      .sort()
      .map((name) => ({
        name: name,
        label: name,
        select: function* (
          _g: EventGroup,
          service: KomodorServicesResponseDataInner | undefined
        ) {
          if (!allServiceAttributes || !service) return;
          const serviceAttributes = allServiceAttributes[service.id ?? ""];
          const serviceAttributeValue = serviceAttributes?.find(({ key }) =>
            attributesForAccount[name].has(key)
          )?.value;
          if (serviceAttributeValue) {
            yield serviceAttributeValue;
          }
        },
      }));
    const filters = [...eventsViewFiltersSelectors];
    filters.splice(3, 0, ...filtersFromAttributes);
    return uniqBy(filters, (f) => f.name);
  }, [allServiceAttributes, attributesForAccount]);
  const options = useMemo(() => {
    if (!services || !eventGroups.length) {
      return [];
    }
    const filtersNotRelatedOnService = Object.values(IndependentFilter).map(
      (f) => f.toString()
    );

    return fullFilters.map(({ name, select }) => {
      const eventsPerValue = new Map<string, EventGroup[]>();
      eventGroups.forEach((g) => {
        const service = services.get(g.serviceId);
        const values =
          service || filtersNotRelatedOnService.includes(name)
            ? select(g, service)
            : selectWithoutService(g, name);
        for (const v of values) {
          pushToMap(eventsPerValue, v, g);
        }
      });
      const values = Array.from(eventsPerValue, ([value, eventGroups]) => {
        return { value, eventGroups };
      });
      return { name, values };
    });
  }, [eventGroups, services, fullFilters]);

  const eventGroupsPerCategory = useMemo(() => {
    return options
      .filter(({ name }) => selected?.[name])
      .map(({ name, values }) => ({
        category: name,
        eventGroups: new Set(
          values
            .filter((v) => selected?.[name]?.[v.value])
            .flatMap((v) => v.eventGroups)
        ),
      }));
  }, [options, selected]);

  const filteredEventGroups = useMemo(() => {
    return eventGroupsPerCategory.length
      ? Array.from(
          intersection(eventGroupsPerCategory.map((c) => c.eventGroups))
        )
      : eventGroups;
  }, [eventGroups, eventGroupsPerCategory]);
  const categoriesData = useMemo(() => {
    const all = options.map(({ name, values }) => {
      const otherCategories = eventGroupsPerCategory.filter(
        ({ category }) => category !== name
      );
      const otherEventGroups = otherCategories.length
        ? intersection(otherCategories.map(({ eventGroups }) => eventGroups))
        : null;
      const mapped = values.map(({ value, eventGroups }) => ({
        value,
        count: otherEventGroups
          ? intersection([new Set(eventGroups), otherEventGroups]).size
          : eventGroups.length,
      }));
      return { name, values: mapped };
    });
    return all;
  }, [options, eventGroupsPerCategory]);

  return {
    categoriesData,
    filteredEventGroups,
    isFetching,
  };
};

const useEventsFilters = (
  eventGroups: EventGroup[],
  allServiceAttributes: AllServicesAttributes,
  timeWindow: TimeWindow
): EventsFilter => {
  const [selected, setSelected] = useStringifiedStateInSearchParams<Selected>(
    FILTERS_BAR_FILTERS_PARAM_KEY
  );
  // Push calculation to the next render cycle to avoid blocking the UI after selection
  const delayedSelected = useFollowingState(selected);

  const { categoriesData, filteredEventGroups, isFetching } =
    useFilteredEventGroups(
      delayedSelected,
      eventGroups,
      allServiceAttributes,
      timeWindow
    );
  const categories = useMemo(() => {
    const all = categoriesData.map(({ name, values }) => {
      const selectedForCategory = new Set(Object.keys(selected?.[name] ?? {}));
      values.forEach(({ value }) => selectedForCategory.delete(value));
      const mapped = values
        .map(({ value, count }) => ({
          value,
          count,
          checked: selected?.[name]?.[value] ?? false,
          onChange: () => setSelected(updateSelected(selected, name, value)),
        }))
        .filter(({ checked, count }) => count > 0 || checked);
      selectedForCategory.forEach((value) => {
        mapped.push({
          value,
          onChange: () => {
            setSelected(updateSelected(selected, name, value));
          },
          checked: true,
          count: 0,
        });
      });
      const sorted = sortBy(mapped, (v) => v.value);
      return { name, values: sorted };
    });
    return all.filter((c) => c.values.length);
  }, [categoriesData, selected, setSelected]);
  const clear = useCallback(() => {
    setSelected(null);
    dispatchEvent(AnalyticEvents.EventsView.Filter_ClearAll);
  }, [setSelected]);

  return {
    categories,
    filteredEventGroups,
    selected,
    setSelected,
    clear: !isEmpty(selected) ? clear : undefined,
    isFetching,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useEventsFilters;
