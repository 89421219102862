import { Line } from "recharts";
import { muiColors } from "@komodorio/design-system";

import { CustomGraphDataPoint } from "../../../../../../../../../../../../Metrics/types";
import { reliabilityArialLabels } from "../../../../../../../../../../../reliabilityArialLabels";
import { sharedStraightLineProps } from "../../../../../../../../../../../../Metrics/constants";
import { TooltipItemIcon } from "../../../../../../../../../../../../Metrics/graphEntity/TooltipItemIcon";
import { LegendItem } from "../../../../../../../../../../../../Metrics/GenericLegend/GenericLegend";

type mergeMetricsProps = {
  nodeUsage: CustomGraphDataPoint[];
  nodeCapacity: CustomGraphDataPoint[];
  workloadUsage: CustomGraphDataPoint[];
};

type MergedItem = {
  time: number;
  nodeUsage: number | null;
  nodeCapacity: number | null;
  workloadUsage: number | null;
};

export type AvailableMetrics = {
  nodeUsage: boolean;
  nodeCapacity: boolean;
  workloadUsage: boolean;
};

export const mergeMetrics = ({
  nodeUsage,
  nodeCapacity,
  workloadUsage,
}: mergeMetricsProps) => {
  const combinedArray = [...nodeUsage, ...nodeCapacity, ...workloadUsage];

  const groupedByTime = combinedArray.reduce<Record<number, MergedItem>>(
    (acc, item) => {
      const { time, ...rest } = item;
      acc[time] = acc[time] || {
        time,
        nodeUsage: null,
        nodeCapacity: null,
        workloadUsage: null,
      };
      Object.assign(acc[time], rest);
      return acc;
    },
    {}
  );

  return Object.values(groupedByTime).sort((a, b) => a.time - b.time);
};

const graphItems = {
  nodeUsage: {
    key: "nodeUsage",
    label: "Node memory usage",
    color: muiColors.blue[500],
  },
  nodeCapacity: {
    key: "nodeCapacity",
    label: "Node memory capacity",
    color: muiColors.orange[600],
  },
  workloadUsage: {
    key: "workloadUsage",
    label: "Noisy service memory usage",
    color: muiColors.red[900],
  },
};

export const getLines = () => {
  return (
    <>
      <Line
        aria-label={
          reliabilityArialLabels.violationDrawer.noisyNeighbors.graphs.nodeUsage
        }
        stroke={graphItems.nodeUsage.color}
        dataKey={graphItems.nodeUsage.key}
        {...sharedStraightLineProps}
      />
      <Line
        aria-label={
          reliabilityArialLabels.violationDrawer.noisyNeighbors.graphs
            .nodeCapacity
        }
        stroke={graphItems.nodeCapacity.color}
        dataKey={graphItems.nodeCapacity.key}
        {...sharedStraightLineProps}
      />
      <Line
        aria-label={
          reliabilityArialLabels.violationDrawer.noisyNeighbors.graphs
            .workloadUsage
        }
        stroke={graphItems.workloadUsage.color}
        dataKey={graphItems.workloadUsage.key}
        {...sharedStraightLineProps}
      />
    </>
  );
};

export const getTooltipItems = () => [
  {
    dataKey: graphItems.nodeUsage.key,
    title: graphItems.nodeUsage.label,
    tooltipItemKey: graphItems.nodeUsage.key,
    icon: <TooltipItemIcon color={graphItems.nodeUsage.color} />,
  },
  {
    dataKey: graphItems.nodeCapacity.key,
    title: graphItems.nodeCapacity.label,
    tooltipItemKey: graphItems.nodeCapacity.key,
    icon: <TooltipItemIcon color={graphItems.nodeCapacity.color} />,
  },
  {
    dataKey: graphItems.workloadUsage.key,
    title: graphItems.workloadUsage.label,
    tooltipItemKey: graphItems.workloadUsage.key,
    icon: <TooltipItemIcon color={graphItems.workloadUsage.color} />,
  },
];

export const getRelevantLegendItems = (
  availableMetrics: AvailableMetrics
): LegendItem[] => {
  return Object.keys(availableMetrics).map((graphItemKey) => {
    const graphItem = graphItems[graphItemKey as keyof typeof graphItems];
    return {
      color: graphItem.color ?? "",
      label: graphItem.label,
    };
  });
};
