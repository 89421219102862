import { useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { VictimInstances } from "../../../../../../../../../../../../../generated/reliabilityApi";

import { WorkloadLink } from "./WorkloadLink";

export const useGetWorkloadsTableColumns = () =>
  useMemo(
    () => [
      {
        field: "komodorUid",
        headerName: "Affected workload",
        flex: 1,
        renderCell: (params: GridRenderCellParams<VictimInstances>) => (
          <WorkloadLink komodorUid={params.value} />
        ),
      },
      { field: "kind", headerName: "Kind", flex: 1 },
      {
        field: "totalVictims",
        headerName: "# Terminated pods",
        flex: 1,
      },
    ],
    []
  );
