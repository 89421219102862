import { useEffect, useState } from "react";

import {
  ViolationsGroupBy,
  ViolationsTableConfiguration,
} from "../../../ReliabilityTypes";
import { useReliabilityStore } from "../../../store/reliabilityStore";
import { selectSetGroupState } from "../../../store/reliabilityStoreSelectors";

import { CheckType, ViolationStatus } from "@/generated/reliabilityApi";
import { defaultViolationStatuses } from "@/components/reliability/constants/reliabilityConstants";

type SetExternalScopeParams = {
  uid: string;
  groupBy: ViolationsGroupBy;
  scope: ViolationsTableConfiguration;
};

export const useSetExternalScopeInStore = ({
  scope,
  groupBy,
  uid,
}: SetExternalScopeParams) => {
  const setGroupsState = useReliabilityStore(selectSetGroupState);
  const shouldUpdateStore = !!scope.checkType?.length;
  const [hasUpdatedStore, setHasUpdatedStore] = useState(!shouldUpdateStore);

  useEffect(() => {
    if (hasUpdatedStore) return;
    setGroupsState({
      uid,
      groupBy,
      newState: {
        checkTypes: (scope.checkType as CheckType[]) ?? [],
        statuses:
          (scope.status as ViolationStatus[]) ?? defaultViolationStatuses,
      },
    });

    setHasUpdatedStore(true);
  }, [
    groupBy,
    hasUpdatedStore,
    scope.checkType,
    scope.status,
    setGroupsState,
    uid,
  ]);

  return {
    isUpdated: hasUpdatedStore,
  };
};
