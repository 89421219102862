import { useEffect, useMemo } from "react";

import { useReportEagerLoadingState } from "../../../../../shared/hooks/datadog-rum/useReportEagerLoadingState";
import { useResourceViewStore } from "../../../../../shared/store/resourceViewStore/resourceViewStore";
import { fetchingStateSelector } from "../../../../../shared/store/resourceViewStore/resourceViewSelectors";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

export const useReportLoadingTimeToDataDog = (
  isDisplayed: boolean,
  timeWindow: TimeWindow
) => {
  const fetchingState = useResourceViewStore(fetchingStateSelector);

  const loadingState = useMemo(() => {
    if (fetchingState.isFetchingEvents === undefined) {
      return { isFetchingEvents: true };
    }
    return { isFetchingEvents: fetchingState.isFetchingEvents };
  }, [fetchingState]);

  const { resetTiming } = useReportEagerLoadingState({
    isDisplayed,
    viewName: dataDogViewNames.getResourceViewEventsTab(timeWindow.timeframe),
    loadingState,
  });

  useEffect(() => {
    resetTiming();
  }, [resetTiming, timeWindow]);
};
