export interface AppConfig {
  // General
  env: string;
  version: string;
  useHttps: boolean;

  // Authentication
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  authRedirectUri: string;

  // API URLs
  serverUrl: string;
  resourcesAPIServerURL: string;
  atmServerURL: string;
  podExecWsUrl: string;
  metricsAPIServerURL: string;
  monitorsAPIServerURL: string;
  komodorCostAPIServerURL: string;
  insightsAPIServerURL: string;
  appInsightsAPIServerURL: string;
  analyticsApiUrl: string;
  komodorApiUrl: string;
  pagerDutyRedirectUrl: string;
  accountsServiceAPIServerURL: string;
  aiServiceAPIServerURL: string;
  reliabilityAPIServerURL: string;
  addonsAPIServiceServerURL: string;
  authServiceAPIServerURL: string;
  agentsServiceAPIServerURL: string;
  searchAPIServerURL: string;
  workspacesAPIServerURL: string;
  emailDigestAPIServerURL: string;

  // Analytics & Third party tools
  launchDarklyClient?: string;
  datadogRumToken?: string;
  datadogRumAppId?: string;
  segmentWriteKey?: string;
  hbsptFormId?: string;
  hbsptPortalId?: string;
  zendeskScriptUrl?: string;
}

class GlobalConfig {
  /*
   * This is a singleton class that is used to store the global config.
   * Because the config is loaded asynchronously (in index.tsx), we need to make sure that we don't try to access it before it is loaded.
   * You should always access the config from method calls, never in the context of the file root.
   * To prevent silent errors in these situations, we update the global config to use getter and setter with checks.
   */
  config?: AppConfig;
  configInitialized = false;

  public get(): AppConfig {
    if (this.configInitialized && this.config !== undefined) {
      return this.config;
    }
    throw new Error("Global config has not been defined yet.");
  }

  public set(value: AppConfig): void {
    this.config = { ...value };
    this.configInitialized = true;
  }
}

// eslint-disable-next-line
// @ts-ignore
export const globalConfigUrl =
  import.meta.env.VITE_GLOBAL_CONFIG_URL || "/config/config.json"; // Has to be under a subdirectory for the k8s volume mount to not override the whole /var/www public dir
export const globalConfig = new GlobalConfig();
export const getAppConfig = (): AppConfig => globalConfig.get();
