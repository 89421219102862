import React from "react";
import styled from "styled-components";

import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../../generated/metricsApi";
import { CollapsibleContainer } from "../../../../../../../shared/components/CollapsibleContainer/CollapsibleContainer";

import { ContainerTitle } from "./ContainerTitle";
import { ContainerContent } from "./ContainerContent";
import { getRecommendationData } from "./getRecommendationData";

const CollapsibleContainerWrapper = styled.div`
  background-color: #fff;
`;

interface Props {
  initialOpen: boolean;
  container: RightSizingCostSummaryByService;
  strategy: OptimizationStrategy;
}

export const RecommendationContainer: React.FC<Props> = ({
  initialOpen,
  container,
  strategy,
}) => {
  const recommendationData = getRecommendationData(container, strategy);
  return (
    <CollapsibleContainerWrapper>
      <CollapsibleContainer
        title={<ContainerTitle recommendation={recommendationData} />}
        content={<ContainerContent recommendationData={recommendationData} />}
        initialOpen={initialOpen}
        ariaLabel={`${recommendationData.containerName} content`}
      />
    </CollapsibleContainerWrapper>
  );
};
