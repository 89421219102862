import { palette } from "@komodorio/design-system";
import React from "react";
import styled from "styled-components";

import { ZIndex } from "../../../../constants/zIndex";

import { ExpandedContainersSection } from "./ExpandedContainersSection";
import { ContainerOption } from "./types";

interface ContainerSelectionProps {
  onSelect: (selection: string) => void;
  containerOptions: ContainerOption[];
  initContainersOptions: ContainerOption[];
}

const Container = styled.div`
  margin: 1rem;
  background-color: ${palette.white[0]};
  z-index: ${ZIndex.AboveMuiDrawer};
`;

const Spacer = styled.div`
  height: 15px;
`;

export const ContainerSelection: React.FC<ContainerSelectionProps> = ({
  onSelect,
  containerOptions,
  initContainersOptions,
}) => {
  return (
    <Container role="listbox" aria-label="pod exec container selection">
      <ExpandedContainersSection
        containers={initContainersOptions}
        onSelect={onSelect}
      />
      <Spacer />
      <ExpandedContainersSection
        containers={containerOptions}
        onSelect={onSelect}
      />
    </Container>
  );
};
