import React from "react";
import { TooltipWrapper } from "react-tooltip";
import { Link } from "@komodorio/design-system/deprecated";

import { ServiceInfo } from "../../../../../shared/types/ServiceInfo";
import { KubernetesResource } from "../../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import EventGroup from "../../../EventGroup";
import { useOpenIssueByResource } from "../../../../OpenIssues/useOpenIssuesByResource";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CellWithIssue, IssueAlertIcon } from "..";
import {
  isDeploymentSTSOrDS,
  isJobFn,
} from "../../../../Inspection/InspectionViews/common";
import { useOpenIssueTooltip } from "../../../../OpenIssues/useOpenIssueTooltip";

interface NameCellProps {
  clusterName: string;
  namespace: string | undefined;
  resourceType: KubernetesResource;
  resourceName: string;
  service: ServiceInfo | undefined;
  cellName: string;
  cellData: string;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  rowId: string;
}
export const NameCell: React.FC<NameCellProps> = ({
  clusterName,
  namespace,
  resourceType,
  resourceName,
  service,
  cellName,
  cellData,
  setCorrelatedEvent,
  rowId,
}) => {
  const openIssue = useOpenIssueByResource(
    clusterName,
    namespace,
    resourceType.Kind,
    resourceName,
    service?.id
  );

  const [tooltip, tooltipId, correlatedIssue, ariaLabel] = useOpenIssueTooltip(
    openIssue,
    service,
    setCorrelatedEvent,
    rowId,
    true
  );

  const shouldShowIssueAlertIcon =
    !isDeploymentSTSOrDS(resourceType.Kind) && !isJobFn(resourceType.Kind);

  return (
    <td key={cellName} aria-label="inspection name">
      <TooltipWrapper tooltipId={tooltipId}>
        <CellWithIssue>
          {shouldShowIssueAlertIcon && openIssue && (
            <>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  if (correlatedIssue) setCorrelatedEvent(correlatedIssue);
                }}
                aria-label={ariaLabel}
              >
                <IssueAlertIcon />
              </Link>
              {tooltip}
            </>
          )}
          {cellData}
        </CellWithIssue>
      </TooltipWrapper>
    </td>
  );
};
