import React, { useState } from "react";
import styled from "styled-components";
import { upperFirst } from "lodash";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { formatNumber } from "../../../../../../../shared/utils/formatNumber";
import { ContainerResources } from "../../types";
import { isInvalidResourceValues } from "../../utils/validateResourceValues";

const ResourceInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const RecommendationSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Input = styled(OutlinedInput)`
  && {
    font-size: 14px;
  }
`;

interface ResourceInputProps {
  label: string;
  unit: string;
  type: keyof ContainerResources;
  resourceType: "cpu" | "memory";
  resource: ContainerResources | undefined;
  onChange: (value: number | null) => void;
  ResourceMetricsData?: { currentValue: number; recommendedValue: number };
}

export const ResourceInput: React.FC<ResourceInputProps> = ({
  label,
  unit,
  type,
  resourceType,
  resource,
  onChange,
  ResourceMetricsData,
}) => {
  const [isInvalidTyping, setIsInvalidTyping] = useState(false);

  const { currentValue, recommendedValue } = ResourceMetricsData ?? {};
  const upperUnit = upperFirst(unit);

  const handleChange = (newValue: string) => {
    const isNum = /^\d+$/.test(newValue);
    if (isNum || newValue === "") {
      onChange(newValue ? Number(newValue) : null);
      if (isInvalidTyping) {
        setIsInvalidTyping(false);
      }
    } else if (!isInvalidTyping) {
      setIsInvalidTyping(true);
    }
  };

  const isRequestHigherThanLimit = isInvalidResourceValues(
    resource,
    resourceType
  );

  const isError = isInvalidTyping || isRequestHigherThanLimit;

  const shouldShowNoRecommendation =
    ResourceMetricsData &&
    ResourceMetricsData.currentValue <= ResourceMetricsData.recommendedValue;

  return (
    <ResourceInputContainer aria-label={`${resourceType} input container`}>
      <Typography variant="body2" color="text.primary">
        {label}
      </Typography>
      <Input
        size="small"
        value={resource?.[type]?.[resourceType] ?? ""}
        onChange={(event) => handleChange(event.target.value)}
        endAdornment={
          <Typography variant="body3" color="text.secondary">
            {unit}
          </Typography>
        }
        error={isError}
      />
      {isInvalidTyping && (
        <Typography variant="body3" color="error">
          Only numbers (0-9) are allowed
        </Typography>
      )}
      {isRequestHigherThanLimit && (
        <Typography variant="body3" color="error">
          Requests cannot be higher than limits
        </Typography>
      )}
      {ResourceMetricsData &&
        (shouldShowNoRecommendation ? (
          <Typography variant="body2" color="text.secondary">
            No recommendation
          </Typography>
        ) : (
          <>
            <Typography variant="body2" color="text.secondary">
              Recommended:
            </Typography>
            <RecommendationSummary>
              <Typography variant="h5" color="text.primary">
                {formatNumber(Number(currentValue))} {upperUnit}
              </Typography>
              <ArrowForward color="action" />
              <Typography variant="h5" color={muiColors.teal[700]}>
                {formatNumber(Number(recommendedValue))} {upperUnit}
              </Typography>
            </RecommendationSummary>
          </>
        ))}
    </ResourceInputContainer>
  );
};
