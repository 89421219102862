import styled from "styled-components";
import React from "react";

import { DescribePanel } from "./DescribeSection";
import { DescribeTitle } from "./DescribeItem";

const DescribeDataContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const DescribeData: React.FC<{
  title?: string;
  children: React.ReactNode;
}> = ({ title = "Data", children }) => (
  <DescribePanel>
    <DescribeTitle>{title}</DescribeTitle>
    <DescribeDataContainer>{children}</DescribeDataContainer>
  </DescribePanel>
);
