import React, { useMemo, useState } from "react";
import { muiTheme } from "@komodorio/design-system";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import { useIssuesOverTimeData } from "./hooks";

import { CheckCategory, ImpactGroupType } from "@/generated/reliabilityApi";
import { GraphContainerWithStatus } from "@/components/Metrics/GraphContainerWithStatus";
import {
  GenericLegend,
  LegendColorIndicator,
  LegendItem,
} from "@/components/Metrics/GenericLegend/GenericLegend";
import { Tooltip } from "@/components/Metrics/Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "@/components/Metrics/utils";
import { AriaLabels } from "@/shared/config/ariaLabels";

const FencedBarChart = styled(GraphContainerWithStatus)`
  border: 1px solid ${muiTheme.palette.divider};
  border-radius: 4px;
`;

const LegendContent = styled(GenericLegend)`
  margin-left: 0;
  top: 0;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    gap: 0px;
    padding: 0px;
    background-color: ${muiTheme.palette.common.white};
    border: 1px solid ${muiTheme.palette.divider};
    height: 22px;
    & .MuiButtonBase-root {
      padding: 0px 12px;
    }
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  &.MuiButtonBase-root.MuiToggleButton-root {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    &:not(:last-child) {
      border-right: 1px solid ${muiTheme.palette.divider};
    }
    &:first-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:last-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  &.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
    box-shadow: none;
    background-color: ${muiTheme.palette.primary.main}0A;
    color: ${muiTheme.palette.primary.main};
  }
`;

type IssuesBarChartProps = {
  className?: string;
  checkCategory: CheckCategory;
  clusters?: string[];
};

export const IssuesBarChart: React.FC<IssuesBarChartProps> = ({
  className,
  checkCategory,
  clusters,
}) => {
  const realtime = useIssuesOverTimeData(
    ImpactGroupType.Realtime,
    checkCategory,
    clusters
  );
  const dynamic = useIssuesOverTimeData(
    ImpactGroupType.Dynamic,
    checkCategory,
    clusters
  );

  const [impactGroupType, setImpactGroupType] = useState<ImpactGroupType>(
    ImpactGroupType.Realtime
  );
  const chosenImpactGroup = useMemo((): {
    type: ImpactGroupType;
    data: ReturnType<typeof useIssuesOverTimeData>;
  } => {
    if (
      impactGroupType === ImpactGroupType.Realtime &&
      !realtime.isLoading &&
      realtime.graphPoints.length === 0
    ) {
      return {
        data: dynamic,
        type: ImpactGroupType.Dynamic,
      };
    }
    if (
      impactGroupType === ImpactGroupType.Dynamic &&
      !dynamic.isLoading &&
      dynamic.graphPoints.length === 0
    ) {
      return {
        data: realtime,
        type: ImpactGroupType.Realtime,
      };
    }
    return {
      data: impactGroupType === ImpactGroupType.Realtime ? realtime : dynamic,
      type: impactGroupType,
    };
  }, [dynamic, impactGroupType, realtime]);

  const legendItems = useMemo(() => {
    const items = new Set<LegendItem>();
    Object.values(chosenImpactGroup.data.graphPoints).forEach((point) => {
      Object.entries(point).forEach(([key, value]) => {
        if (value > 0) {
          const item = chosenImpactGroup.data.issueTypes[key];
          if (item) {
            items.add(item);
          }
        }
      });
    });
    return Array.from(items);
  }, [chosenImpactGroup.data.graphPoints, chosenImpactGroup.data.issueTypes]);

  const ariaLabels =
    checkCategory === CheckCategory.Workload
      ? AriaLabels.ClusterOverview.HealthSection.Workloads
      : AriaLabels.ClusterOverview.HealthSection.Infra;

  return (
    <Box
      gridColumn="2"
      display="flex"
      flexDirection="column"
      gap="8px"
      className={className}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
      >
        <Typography variant="h5">
          Issues Over Time{" "}
          <Typography
            variant="body2"
            color="text.secondary"
            display="inline"
            sx={{ marginLeft: "8px" }}
          >
            Last 7 days
          </Typography>
        </Typography>
        <StyledToggleButtonGroup
          disabled={realtime.isLoading || dynamic.isLoading}
          value={chosenImpactGroup.type}
          size="small"
          exclusive
          onChange={(_, value) => Boolean(value) && setImpactGroupType(value)}
        >
          <StyledToggleButton
            aria-label={ariaLabels.GraphIssuesButton}
            value={ImpactGroupType.Realtime}
            disabled={realtime.isLoading || realtime.graphPoints.length === 0}
          >
            Realtime Issues
          </StyledToggleButton>
          <StyledToggleButton
            aria-label={ariaLabels.GraphRisksButton}
            value={ImpactGroupType.Dynamic}
            disabled={dynamic.isLoading || dynamic.graphPoints.length === 0}
          >
            Reliability Risks
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <FencedBarChart
        uid={undefined}
        hasGraphData={chosenImpactGroup.data.graphPoints.length > 0}
        error={chosenImpactGroup.data.error}
        loading={chosenImpactGroup.data.isLoading}
        graphHeight="192px"
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chosenImpactGroup.data.graphPoints}
            margin={{
              top: 16,
              right: 16,
              left: 16,
              bottom: 16,
            }}
          >
            <CartesianGrid vertical={false} />
            <YAxis
              width={24}
              tick={{ fontSize: 10 }}
              tickLine={false}
              axisLine={false}
              label={{ hide: true }}
            />
            <XAxis
              dataKey="time"
              domain={["dataMin", "dataMax"]}
              tickFormatter={(value) =>
                new Date(value).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })
              }
              tick={{ fontSize: 10 }}
              interval="preserveStartEnd"
            />
            <RechartsTooltip
              cursor={false}
              content={(props) => (
                <Tooltip
                  dateOnly={true}
                  tooltipProps={props}
                  dataTransformFunction={defaultFormatTooltipTick}
                  tooltipItems={Object.entries(
                    chosenImpactGroup.data.issueTypes
                  ).map(([key, item]) => ({
                    title: item.label,
                    dataKey: key,
                    tooltipItemKey: key,
                    icon: <LegendColorIndicator color={item.color} />,
                  }))}
                />
              )}
              wrapperStyle={{ outline: "none" }}
              isAnimationActive={false}
            />
            <Legend
              layout="horizontal"
              align="left"
              verticalAlign="bottom"
              content={<LegendContent items={legendItems} />}
            />
            {Object.entries(chosenImpactGroup.data.issueTypes).map(
              ([key, item]) => (
                <Bar
                  key={key}
                  stackId="a"
                  dataKey={key}
                  fill={item.color}
                  barSize={9.33}
                  radius={[2, 2, 0, 0]}
                  animationDuration={0}
                />
              )
            )}
          </BarChart>
        </ResponsiveContainer>
      </FencedBarChart>
    </Box>
  );
};
