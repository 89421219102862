import {
  Button,
  IconButton,
  Select,
} from "@komodorio/design-system/deprecated";
import { Trash16 } from "@komodorio/design-system/icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import React, { useEffect, useMemo } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

import { User } from "../../../generated/auth";
import { Z_INDEX_ABOVE_MODAL } from "../styles";
import { useFormValidations } from "../../../shared/context/ValidationsProvider";

import { validateUserTempRoles } from "./utils";
import { reactDispatchType, UserForm } from "./types";

import { blackGreyText } from "@/Colors";
import { useGetRoleWithUserExpiration } from "@/components/Settings/Users/hooks";
import {
  INVALID_DATE_PICKET_ERROR,
  MAX_DATE_PICKET_ERROR,
  PAST_DATE_PICKET_ERROR,
} from "@/components/Settings/Users/const";

export const TemporaryRolesModal: React.FC<{
  roleOptions: {
    label: string;
    value: string;
    disabled: boolean | undefined;
  }[];
  setValue: reactDispatchType<UserForm>;
  value: UserForm;
  timeValue: Date | null;
  setTimeValue: reactDispatchType<Date | null>;
  setShowAddTemporaryRoleUi: reactDispatchType<boolean>;
  showAddTemporaryRoleUi: boolean;
  user?: User;
}> = ({
  roleOptions,
  value,
  setValue,
  timeValue,
  setTimeValue,
  setShowAddTemporaryRoleUi,
  showAddTemporaryRoleUi,
  user,
}) => {
  const { errors, setError } = useFormValidations();
  const { temporaryRoles } = useGetRoleWithUserExpiration(user);
  const temporaryRole = temporaryRoles?.[0];

  const handleShowTempRoleUiClicked = () => {
    setShowAddTemporaryRoleUi(true);
  };
  const handleRemoveTempRoleUiClicked = async () => {
    setShowAddTemporaryRoleUi(false);
    setError("temp_roles", undefined);
    setError("temp_roles_date", undefined);
    setValue({
      ...value,
      tempRole: { value: "", label: "" },
    });
  };

  useEffect(() => {
    if (temporaryRole) {
      setShowAddTemporaryRoleUi(true);
    }
  }, [setShowAddTemporaryRoleUi, temporaryRole]);

  const roleOptionsFiltered = useMemo(() => {
    if (!showAddTemporaryRoleUi) return [];
    return roleOptions.filter(
      (r) => !value.roles.map((role) => role.value).includes(r.value)
    );
  }, [roleOptions, showAddTemporaryRoleUi, value.roles]);

  const getHelperText = () => {
    switch (errors["temp_roles_date"]) {
      case PAST_DATE_PICKET_ERROR:
        return "Expiration time must be in the future";
      case INVALID_DATE_PICKET_ERROR:
        return "Please enter a valid date";
      case MAX_DATE_PICKET_ERROR:
        return "Please enter a valid date";
      default:
        return errors["temp_roles_date"] || "";
    }
  };

  return (
    <>
      {!showAddTemporaryRoleUi && (
        <Button variant="secondary" onClick={handleShowTempRoleUiClicked}>
          Add Temporary Role
        </Button>
      )}
      {showAddTemporaryRoleUi && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <label
                style={{
                  color: blackGreyText,
                  fontSize: "14px",
                  fontFamily: "Roboto",
                }}
              >
                Temporary Role
              </label>
              <IconButton
                icon={Trash16}
                variant="danger"
                noBorder
                onClick={handleRemoveTempRoleUiClicked}
                disabled={false}
              />
            </div>
            <Select
              size="medium"
              width="20rem"
              listMaxHeight="14rem"
              listZIndex={Z_INDEX_ABOVE_MODAL}
              options={roleOptionsFiltered}
              value={value.tempRole}
              onChange={(selected) => {
                setValue({
                  ...value,
                  tempRole: {
                    value: selected.value,
                    label: selected.label,
                  },
                });
                setError("temp_roles", validateUserTempRoles(selected));
              }}
              errorMessage={errors["temp_roles"]}
              disabled={!!temporaryRole && !!value.tempRole.value}
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format={"dd/MM/yyyy"}
              label="Expiration Date"
              value={timeValue}
              onError={(newError) => {
                setError("temp_roles_date", newError?.toString());
              }}
              onChange={(selected) => {
                setTimeValue(selected);
              }}
              slotProps={{
                textField: {
                  helperText: getHelperText(),
                },
                popper: {
                  disablePortal: true,
                },
              }}
              autoFocus={true}
              disablePast={true}
            />
          </LocalizationProvider>
        </>
      )}
    </>
  );
};
