import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  MuiSelectionOption,
  SearchField,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { useGetCheckTypesFromConfigurations } from "../hooks/useGetCheckTypesFromConfigurations";
import { impactGroupIdentifierToText } from "../../../../../constants/impactGroupIdentifierToText";
import { ImpactGroupIdentifier } from "../../../../../../../generated/reliabilityApi";
import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  policies: {
    policiesDrawer: { impactGroupSelector: impactGroupSelectorAriaLabel },
  },
} = reliabilityArialLabels;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SearchFieldContainer = styled.div<{ $disabled: boolean }>`
  .search-field-container {
    pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  }
`;

export const PolicyDrawerTableTopSection: React.FC = () => {
  const { policyConfigurations, checksTableFilters, setChecksTableFilter } =
    usePolicyDrawerContext();

  const isDynamicChecksTab = useIsDynamicChecksTab();

  const checkOptions = useGetCheckTypesFromConfigurations();
  const isEmptyConfigurations = Object.keys(policyConfigurations).length === 0;

  const onSearchTermChange = (searchTerm: string) => {
    setChecksTableFilter({ searchTerm });
  };
  const onImpactGroupChange = (
    option?: MuiSelectionOption<string> | undefined
  ) => {
    setChecksTableFilter({ impactGroupId: option?.value ?? undefined });
  };

  const impactGroupValue = useMemo(() => {
    if (!checksTableFilters.impactGroupId) return undefined;
    return {
      value: checksTableFilters.impactGroupId ?? "",
      label:
        impactGroupIdentifierToText[
          checksTableFilters.impactGroupId as ImpactGroupIdentifier
        ]?.title ?? "",
    };
  }, [checksTableFilters.impactGroupId]);

  useEffect(() => {
    if (isEmptyConfigurations && checksTableFilters.impactGroupId) {
      // reset filters when configurations are empty
      setChecksTableFilter({ impactGroupId: undefined });
    }
  }, [
    checksTableFilters.impactGroupId,
    isEmptyConfigurations,
    setChecksTableFilter,
  ]);

  return (
    <Container>
      <SelectorsContainer>
        <SearchFieldContainer $disabled={isEmptyConfigurations}>
          <SearchField
            placeholder="Check name"
            value={checksTableFilters.searchTerm}
            onSearch={onSearchTermChange}
            width={"220px"}
            classNames={{
              container: "search-field-container",
            }}
          />
        </SearchFieldContainer>
        {isDynamicChecksTab && (
          <SingleSelect
            options={checkOptions}
            value={impactGroupValue}
            onChange={onImpactGroupChange}
            width="220px"
            placeholder={"All impact groups"}
            ariaLabel={impactGroupSelectorAriaLabel}
            disabled={checkOptions.length === 0}
          />
        )}
      </SelectorsContainer>
    </Container>
  );
};
