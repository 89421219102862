import { useMemo } from "react";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { PolicyConfigurations } from "../../../../../../../generated/reliabilityApi";

import { useAllCheckLabels } from "./useGetAllCheckTypes";

export const useGetAvailableCheckTypes = () => {
  const allCheckLabels = useAllCheckLabels({ sort: true });
  const { policyConfigurations } = usePolicyDrawerContext();

  return useMemo(() => {
    return allCheckLabels.filter(
      (label) =>
        !policyConfigurations[label.value as keyof PolicyConfigurations]
    );
  }, [allCheckLabels, policyConfigurations]);
};
