import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  Workspace,
  WorkspacesApiApiV1WorkspacesIdGetRequest,
  apiV1WorkspacesIdGetUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspacesApiClient } from "../apiClient";
import { convertWorkspaceToTypedWorkspace } from "../utils";

export const WORKSPACE_BY_ID_PATH = "/workspaces/:id";

const fetchWorkspaceById = async (
  apiClient: AxiosInstance,
  params: WorkspacesApiApiV1WorkspacesIdGetRequest
): Promise<Workspace> => {
  const { data } = await apiClient.get<Workspace>(
    apiV1WorkspacesIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetWorkspaceById = (
  params: WorkspacesApiApiV1WorkspacesIdGetRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useWorkspacesApiClient();
  return useQuery(
    [WORKSPACE_BY_ID_PATH, params],
    () => fetchWorkspaceById(apiClient, params),
    {
      ...options,
      select: (data) => convertWorkspaceToTypedWorkspace(data),
    }
  );
};
