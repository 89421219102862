import { TypedWorkspace } from "../../../shared/hooks/workspaces-api/types";

export const ALL_CLUSTERS = "All Clusters";

export type WorkspaceOption = {
  label: string;
  value: string;
  disabled?: boolean;
  tooltip?: string;
  editable?: boolean;
  workspace?: TypedWorkspace;
  width?: number;
  match: {
    start: number;
    end: number;
  } | null;
};

export const ALL_CLUSTERS_VALUE = {
  label: ALL_CLUSTERS,
  value: ALL_CLUSTERS,
} as WorkspaceOption;

export const NO_SELECTED_WORKSPACE_LABEL = "All Workspaces";

export const NO_RESULTS_TEXT = "No matching values found";

export const WORKSPACE_NAME_MAX_CHARS = 128;
export const FONT = "14px Roboto, sans-serif";
export const WORKSPACE_ITEM_PADDING = 80;
