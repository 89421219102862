import { Quota } from "@komodorio/design-system/icons";

export const KubernetesResourceQuotaResource = {
  NameInK8S: "resourcequotas",
  Group: "configuration",
  NavBarName: "Resource Quotas",
  PresentName: "Resource Quotas",
  Namespaced: true,
  UnnecessaryColumns: ["Request", "Limit"],
  Kind: "ResourceQuota",
  Icon: Quota,
  hasLabels: true,
};
