import { useCallback, useMemo } from "react";

import {
  TimeWindow,
  SetTimeWindow,
  Timeframe,
} from "../../../../../../shared/types/TimeWindow";
import {
  TIMEFRAME_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
} from "../../../../../../shared/config/urlSearchParamsKeys";
import { useDrawerStackIndex } from "../../../../../../shared/context/drawersStack/useDrawerStackIndex";
import { useDrawersStackStore } from "../../../../../../shared/store/drawersStackStore/drawersStackStore";
import {
  drawerAtSelector,
  setDrawerAtSelector,
} from "../../../../../../shared/store/drawersStackStore/drawersStackSelectors";

import getUpdatedTimeWindow, {
  parseTimeWindowDataToUrlData,
} from "./getUpdatedTimeWindow";
import { getTimeframeForLabel } from "./timeframeLabel";

import useTimeWindowFromParams from ".";

const timeWindowPattern = /^(\d+)-(\d+)$/;
const useTimeWindowValue = (
  drawerIndex: number | undefined,
  initialTimeWindow: TimeWindow,
  timeWindowKey: string,
  timeFrameKey: string
) => {
  const drawerState = useDrawersStackStore(drawerAtSelector(drawerIndex));
  const timeWindowFromSearchParams = (drawerState?.urlStates?.[timeWindowKey] ??
    "") as string;
  const timeframeFromSearchParams = (drawerState?.urlStates?.[timeFrameKey] ??
    "") as string;

  const timeframe = timeframeFromSearchParams
    ? getTimeframeForLabel(timeframeFromSearchParams, Timeframe.Custom)
    : initialTimeWindow.timeframe;

  return useMemo(() => {
    if (timeWindowFromSearchParams) {
      const match = timeWindowPattern.exec(timeWindowFromSearchParams);
      if (match) {
        const start = new Date(+match[1]);
        const end = new Date(+match[2]);
        return { start, end, timeframe };
      }
    }
    return getUpdatedTimeWindow(initialTimeWindow, { timeframe });
  }, [initialTimeWindow, timeWindowFromSearchParams, timeframe]);
};

const useSetTimeWindow = (
  drawerIndex: number | undefined,
  current: TimeWindow,
  timeWindowKey: string,
  timeFrameKey: string
) => {
  const setDrawerState = useDrawersStackStore(setDrawerAtSelector(drawerIndex));

  return useCallback<SetTimeWindow>(
    (updated, options?: { replace?: boolean }) => {
      const timeWindow = getUpdatedTimeWindow(current, updated);
      const { urlTimeWindow, urlTimeframe } =
        parseTimeWindowDataToUrlData(timeWindow);
      setDrawerState(
        {
          [timeWindowKey]: urlTimeWindow,
          [timeFrameKey]: urlTimeframe,
        },
        options?.replace
      );
    },
    [current, setDrawerState, timeFrameKey, timeWindowKey]
  );
};

const useDrawerTimeWindowFromParams = (
  drawerIndex: number | undefined,
  initial: TimeWindow,
  timeWindowKey: string = TIME_WINDOW_PARAM_KEY,
  timeFrameKey: string = TIMEFRAME_PARAM_KEY
): [TimeWindow, SetTimeWindow] => {
  const timeWindow = useTimeWindowValue(
    drawerIndex,
    initial,
    timeWindowKey,
    timeFrameKey
  );
  const setTimeWindow = useSetTimeWindow(
    drawerIndex,
    timeWindow,
    timeWindowKey,
    timeFrameKey
  );

  return [timeWindow, setTimeWindow];
};

export const useDrawerTimeWindowFromUrl = (
  initial: TimeWindow,
  timeWindowKey: string = TIME_WINDOW_PARAM_KEY,
  timeFrameKey: string = TIMEFRAME_PARAM_KEY
) => {
  const drawerIndex = useDrawerStackIndex();
  const drawerState = useDrawerTimeWindowFromParams(
    drawerIndex,
    initial,
    timeWindowKey,
    timeFrameKey
  );
  const searchParamsState = useTimeWindowFromParams(
    initial,
    timeWindowKey,
    timeFrameKey
  );

  return drawerIndex !== undefined ? drawerState : searchParamsState;
};
