import { useCallback, useMemo } from "react";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export function useStringifiedStateInSearchParams<T>(
  paramKey: string
): [T | null, (newValue: T | null, replace?: boolean) => void] {
  const [state, setState] = useStateInSearchParams(paramKey);
  const parsedState = useMemo<T | null>(() => {
    try {
      return JSON.parse(state || "null");
    } catch {
      return null;
    }
  }, [state]);
  const setStringifiedState = useCallback(
    (newValue: T | null, replace?: boolean) => {
      setState(newValue === null ? null : JSON.stringify(newValue), replace);
    },
    [setState]
  );
  return [parsedState, setStringifiedState];
}
