import { get } from "lodash";
import { IdentityProvider } from "komodor-types";
import { User } from "@auth0/auth0-react";

// This paths is a custom claim added in an Auth0 rule (https://auth0.com/docs/secure/tokens/json-web-tokens/create-namespaced-custom-claims)
export enum CustomClaims {
  identities = "https://komodor.com/identities",
  loginCount = "https://komodor.com/stats",
  user = "https://komodor.com/user",
}

export type Auth0Identity = {
  provider: string;
  user_id: string;
  connection: string;
  isSocial: boolean;
};

export const microsoftProvider = "windowslive";
export const githubProvider = "github";
export const GoogleProvider = "google-oauth2";
export const AzureAdProvider = "waad";
export const UsernamePasswordProvider = "auth0";
export const SamlProvider = "samlp"; // == Okta, but can be something else as well

export const fastSocialConnection = {
  google: GoogleProvider,
  github: githubProvider,
  microsoft: microsoftProvider,
};

export const fetchIdentityProvider = (user: User): IdentityProvider => {
  const identities = getAuth0UserValue<Auth0Identity[]>(
    user,
    CustomClaims.identities
  );
  if (identities === null || identities.length === 0) {
    return IdentityProvider.USERNAMEPASSWORD;
  }

  const idp = identities[0].provider;
  switch (idp) {
    case GoogleProvider:
      return IdentityProvider.GSUITE;
    case githubProvider:
      return IdentityProvider.GITHUB;
    case microsoftProvider:
      return IdentityProvider.MICROSOFT;
    case AzureAdProvider:
      return IdentityProvider.ACTIVE_DIRECTORY;
    case SamlProvider:
      return IdentityProvider.OKTA;
    case UsernamePasswordProvider:
      return IdentityProvider.USERNAMEPASSWORD;
    default:
      return IdentityProvider.USERNAMEPASSWORD;
  }
};

type Auth0LoginCount = {
  logins_count?: unknown;
};

export const fetchLoginCount = (user: User): number => {
  const loginCount = getAuth0UserValue<Auth0LoginCount>(
    user,
    CustomClaims.loginCount
  );

  return loginCount?.logins_count &&
    typeof loginCount.logins_count === "number" &&
    !isNaN(loginCount.logins_count)
    ? loginCount.logins_count
    : 0;
};

const getAuth0UserValue = <T>(user: User, keyName: string): T | null =>
  get(user, keyName, null);

export type UserData = {
  created_at: string;
  email: string;
  email_verified: boolean;
  updated_at: string;
  username: string;
};

export const fetchUserData = (user: User): UserData | null => {
  return getAuth0UserValue<UserData>(user, CustomClaims.user);
};

export const shouldVerifyEmail = (user: User): boolean => {
  if (fetchIdentityProvider(user) !== IdentityProvider.USERNAMEPASSWORD) {
    return false;
  }

  const userData = user && fetchUserData(user);
  if (userData?.email.endsWith("komodor-test.com")) return false;

  const userCreateDate = new Date(userData?.created_at || "").getTime();
  const emailVerificationFeatureReleasetDate = 1656339401020;
  return userCreateDate > emailVerificationFeatureReleasetDate;
};
