export const defaultGraphHeight = "15rem";

export const minimalEventIconsHeight = 24;

export const sharedStraightLineProps = {
  type: "stepAfter",
  dot: false,
  strokeWidth: "2",
  strokeLinejoin: "bevel",
  strokeLinecap: "round",
  isAnimationActive: false,
} as const;

export const sharedDashedLineProps = {
  type: "stepAfter",
  dot: false,
  strokeDasharray: "7 7",
  strokeWidth: "2",
  strokeLinejoin: "round",
  strokeLinecap: "round",
  isAnimationActive: false,
} as const;
