import styled from "styled-components";
import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { ContainerWithLogs } from "../../workflowRunView/CommonTypes";
import { Tag } from "../../../common/Card/CardWithTag";

import ContainerRow from "./ContainerRow";

const Containers = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 1rem;
`;

const ContainersTable: React.FC<{
  containers: ContainerWithLogs[];
  clusterName: string;
  namespace: string;
  podName: string;
  eventTime: Date | undefined;
  ariaLabel?: string;
}> = ({
  containers,
  clusterName,
  namespace,
  podName,
  eventTime,
  ariaLabel,
}) => {
  return (
    <Containers>
      {containers.map((container, index) => (
        <ContainerRow
          key={index}
          container={container}
          k8sData={{
            namespace: namespace,
            clusterName: clusterName,
          }}
          podName={podName}
          eventTime={eventTime}
          startExpanded={containers?.length === 1}
          ariaLabel={`${ariaLabel}-${index}`}
          cardTag={
            !container?.isHealthy ? (
              <Tag color={muiColors.orange[600]}>
                <Typography
                  className="tag"
                  variant="overline"
                  color={muiColors.common.white}
                  fontSize={"12px"}
                  lineHeight={"19.2px"}
                  fontWeight={500}
                >
                  failed container
                </Typography>
              </Tag>
            ) : undefined
          }
          borderColor={
            !container?.isHealthy ? muiColors.orange[600] : undefined
          }
        />
      ))}
    </Containers>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ContainersTable;
