import React from "react";
import { muiColors } from "@komodorio/design-system";
import { MuiXLicense } from "@komodorio/design-system/material-ui";

import ExternalLink from "./components/common/Link/ExternalLink";
import { App } from "./App";
import { CenteredContainer, CenteredTypography } from "./styles";

export const StrictApp = (
  <React.StrictMode>
    <MuiXLicense />
    <App />
  </React.StrictMode>
);

export const ErrorMessage = (
  <CenteredContainer>
    <CenteredTypography color={muiColors.gray["600"]}>
      An unexpected error occurred while loading the application.
      <br />
      Please contact us to report this issue at{" "}
      <ExternalLink href={"mailto:support@komodor.com"}>
        support@komodor.com
      </ExternalLink>
    </CenteredTypography>
  </CenteredContainer>
);
