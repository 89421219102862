import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1InstallationsIdPutUrl,
  Installation,
  InstallationsApiApiV1InstallationsIdPutRequest,
} from "../../../../../generated/accounts";
import { useAccountsApiClient } from "../apiClient";

export type InstallationUpdateFnAsync = (
  vars: InstallationsApiApiV1InstallationsIdPutRequest
) => Promise<Installation>;

const updateInstallation = async (
  apiClient: AxiosInstance,
  params: InstallationsApiApiV1InstallationsIdPutRequest
): Promise<Installation> => {
  const { data } = await apiClient.put(
    apiV1InstallationsIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.installationUpdateRequest
  );
  return data;
};

export const useUpdateInstallation = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    (vars: InstallationsApiApiV1InstallationsIdPutRequest) =>
      updateInstallation(apiClient, vars),
    {}
  );
};
