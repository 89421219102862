import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  SearchField,
} from "@komodorio/design-system/komodor-ui";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useDebouncedCallback } from "use-debounce";
import Tooltip from "@mui/material/Tooltip";

import { cloudProviderLabelMap } from "../utils";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import {
  ClustersFilters,
  ClustersSummaryFilters,
  ClusterProviderType,
} from "../../../generated/workspacesApi";
import { DEFAULT_PAGINATION_PARAMS } from "../constants";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

export const AgentsActiveMessage = "All agents are active";

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding-bottom: 1rem;
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
`;

const providerFormatter = (provider: string) =>
  cloudProviderLabelMap[provider as ClusterProviderType];
const toOption = (
  value: string,
  count?: number,
  formatter?: (v: string) => string
): MuiSelectionOption<string> => ({
  label:
    (formatter ? formatter(value) : value) +
    (count !== undefined ? ` (${count})` : ""),
  value: value,
});
const getOptions = (
  data: ClustersFilters | undefined,
  filterKey: "provider" | "k8sVersion" | "inactive",
  formatter?: (v: string) => string
) => {
  const values = data?.values?.find((v) => v.name === filterKey)?.values ?? [];
  return sortBy(
    values.map(({ name, count }) => toOption(name, count, formatter)),
    (o) => o.label
  );
};
const toValues = (options: MuiSelectionOption<string>[]) =>
  options.map((option) => option.value);

interface ClusterFiltersProps {
  filtersData: ClustersFilters | undefined;
  filters: ClustersSummaryFilters | null;
  setFilters: (filters: ClustersSummaryFilters) => void;
}
export const ClusterFilters: React.FC<ClusterFiltersProps> = ({
  filtersData,
  filters,
  setFilters,
}) => {
  const { clusterName, provider, k8sVersion, includeDisconnected } =
    filters ?? {};

  const handleFilterChange = (newFilters: ClustersSummaryFilters) => {
    setFilters({
      ...filters,
      ...newFilters,
      offset: DEFAULT_PAGINATION_PARAMS.offset,
    });
  };

  const [localSearchTerm, setLocalSearchTerm] = useState(clusterName ?? "");
  const { callback: debouncedSetSearchTerm } = useDebouncedCallback(
    (value: string) => {
      handleFilterChange({ clusterName: value });
    },
    250
  );

  const cloudProviderOptions = useMemo(
    () => getOptions(filtersData, "provider", providerFormatter),
    [filtersData]
  );
  const k8sVersionOptions = useMemo(
    () => getOptions(filtersData, "k8sVersion"),
    [filtersData]
  );
  const inactiveClustersOptions = useMemo(
    () => getOptions(filtersData, "inactive"),
    [filtersData]
  );

  const existInactiveClusters = useMemo(
    () => inactiveClustersOptions.find((option) => option.value === "true"),
    [inactiveClustersOptions]
  );

  return (
    <Container>
      <SearchField
        label="Search Cluster"
        placeholder="Search cluster"
        value={localSearchTerm}
        onSearch={(value) => {
          debouncedSetSearchTerm(value);
          setLocalSearchTerm(value);
        }}
        width={"15rem"}
        ariaLabel={AriaLabels.ClustersView.Filters.SearchInput}
      />
      <AdvancedMultiSelect
        label="Cloud Provider"
        placeholder={"Select cloud provider"}
        options={cloudProviderOptions}
        value={provider?.map((p) => toOption(p, undefined, providerFormatter))}
        onChange={(selectedOptions) =>
          handleFilterChange({ provider: toValues(selectedOptions) })
        }
        width="15rem"
        ariaLabel={AriaLabels.ClustersView.Filters.CloudProviderSelect}
      />
      <AdvancedMultiSelect
        label="K8s Version"
        placeholder={"Select k8s version"}
        options={k8sVersionOptions}
        value={k8sVersion?.map((v) => toOption(v))}
        onChange={(selectedOptions) =>
          handleFilterChange({ k8sVersion: toValues(selectedOptions) })
        }
        width="15rem"
        ariaLabel={AriaLabels.ClustersView.Filters.K8sVersionSelect}
      />
      <Tooltip
        title={
          !existInactiveClusters ? (
            <Typography variant="body2">{AgentsActiveMessage}</Typography>
          ) : null
        }
        componentsProps={lightMuiTooltipStyle}
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -15],
                },
              },
            ],
          },
        }}
      >
        <SwitchContainer
          aria-label={AriaLabels.ClustersView.Filters.ShowInactiveSwitchWrapper}
        >
          <Switch
            disabled={!existInactiveClusters}
            checked={includeDisconnected}
            onChange={() =>
              handleFilterChange({
                includeDisconnected: !includeDisconnected,
              })
            }
            aria-label={AriaLabels.ClustersView.Filters.ShowInactiveSwitch}
          />
          <Typography variant="body2">Show Disconnected Clusters</Typography>
        </SwitchContainer>
      </Tooltip>
    </Container>
  );
};
