import styled from "styled-components";
import Button from "@mui/material/Button";

import { LIST_PADDING } from "../../../violationDrawerStyles";
import { RequestIcon } from "../../../../../../../../Metrics/Legend";

import { UTILIZATION_METRIC_COLOR } from "./hpaReachedMaxConstants";

export const StyledButtonWithinList = styled(Button)`
  && {
    margin-top: 8px;
    margin-left: -${LIST_PADDING};
    font-size: 14px;
  }
`;

export const ColoredRequestIcon = styled(RequestIcon)`
  && {
    > rect {
      fill: ${UTILIZATION_METRIC_COLOR};
    }
  }
`;
