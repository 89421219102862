import React from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { CopyCode } from "../../CopyCode";

import { CodeTypography } from "./CodeTypography";

const BorderFirstDiv = styled.div`
  && > div {
    border: 1px solid ${muiColors.gray[200]};
  }
`;

type CustomCopyCodeProps = {
  commandAsText: string;
  fullCommand: string;
};

export const CustomCopyCode: React.FC<CustomCopyCodeProps> = ({
  commandAsText,
  fullCommand,
}) => {
  return (
    <BorderFirstDiv>
      <CopyCode
        tooltipText="Copy to clipboard"
        copyOnClick
        code={""}
        fullCommand={fullCommand}
        fixedHeight={false}
        codeTextElement={<CodeTypography>{commandAsText}</CodeTypography>}
      />
    </BorderFirstDiv>
  );
};
