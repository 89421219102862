import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  DialogContainer,
  DialogFooter,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const KlaudiaAiDisableForm: React.FC<{
  isLoading: boolean;
  onClose: () => void;
  onDisable: () => void;
}> = ({ onClose, onDisable, isLoading }) => {
  return (
    <DialogContainer
      aria-label={
        AriaLabels.SettingsPage.AccountProfile.BetaFeatures.KlaudiaAiDisableForm
      }
    >
      <Typography variant={"h3"}>Disable Klaudia AI?</Typography>
      <Typography variant={"body2"}>
        Disabling Klaudia AI will disable it for <b>all users</b> in this
        account.
      </Typography>
      <DialogFooter>
        <Button
          variant={"text"}
          size={"large"}
          sx={{
            width: "5rem",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant={"contained"}
          size={"large"}
          sx={{
            width: "9.5rem",
          }}
          onClick={onDisable}
        >
          Disable
        </LoadingButton>
      </DialogFooter>
    </DialogContainer>
  );
};
