import React from "react";
import styled, { css } from "styled-components";

import InputTitleMaxLength from "../../../../../common/controls/InputTitleMaxLength";
import Input from "../../../../../common/controls/Input";
import { FormTextInput } from "../../../../../monitorsView/monitorsConfiguration/common/styles";

import { ErrorMessage } from "./LinkModal.styles";
import { UpsertLinkErrorType } from "./utils";

const nameMaxLength = 30;

const flexStretch = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const FieldContainer = styled.div`
  ${flexStretch}
  gap: 0.5rem;
`;

const LinkInputContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface LinkInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isValidInput: boolean;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<UpsertLinkErrorType>>;
}

const StyledFormTextInput = styled(FormTextInput)`
  margin-inline: 0;
  width: auto;
`;

export const LinkInput = React.forwardRef<HTMLInputElement, LinkInputProps>(
  (
    { isValidInput, errorMessage, setErrorMessage, ...rest }: LinkInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    return (
      <FieldContainer>
        <label htmlFor="link">Link</label>
        <LinkInputContainer>
          <StyledFormTextInput
            id="link"
            name="link"
            ref={ref}
            placeholder="Paste your link here"
            isValid={isValidInput}
            style={{ flexGrow: 1 }}
            onChange={(e) => {
              rest.onChange?.(e);
              return setErrorMessage(UpsertLinkErrorType.NoError);
            }}
          />
          {!isValidInput &&
            errorMessage === UpsertLinkErrorType.LinkNotValid && (
              <ErrorMessage>Please enter valid URL.</ErrorMessage>
            )}
        </LinkInputContainer>
      </FieldContainer>
    );
  }
);

interface NameInputProps {
  nameLength?: number;
}

export const NameInput = React.forwardRef<HTMLInputElement, NameInputProps>(
  (
    {
      nameLength,
      ...rest
    }: NameInputProps & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    return (
      <FieldContainer>
        <InputTitleMaxLength length={nameLength ?? 0} maxLength={nameMaxLength}>
          <label htmlFor="name">Display name</label>
        </InputTitleMaxLength>
        <Input
          {...rest}
          type="text"
          id="name"
          name="name"
          required={true}
          ref={ref}
          placeholder="Name"
          maxLength={nameMaxLength}
        />
      </FieldContainer>
    );
  }
);
