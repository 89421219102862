import React from "react";
import { muiColors } from "@komodorio/design-system";

interface CustomLabelProps {
  x?: number;
  y?: number;
  value?: string | number;
}

export const AvgLabel: React.FC<CustomLabelProps> = ({ x, y, value }) => {
  return value ? (
    <text
      x={x}
      y={y}
      fontSize={14}
      dy={-10}
      dx={45}
      textAnchor="middle"
      fill={muiColors.gray[600]}
    >
      {value}
    </text>
  ) : null;
};

export const CurrentLabel: React.FC<CustomLabelProps> = ({ x, y, value }) => {
  return value ? (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fontSize={14}
      dx={15}
      dy={-5}
      fontFamily={"Roboto"}
      fontWeight={"400"}
      fill={muiColors.pink[300]}
    >
      {value}
    </text>
  ) : null;
};
