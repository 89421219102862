import React from "react";

import { Link } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { getLinkIcon } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/utils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NativePodEventGroup from "../../../EventGroup/NativePodEventGroup";
import { FeatureFlag } from "../../../../../shared/components/FeatureFlag";
import DrawerEventLinksSection from "../deployEvent/DrawerEventLinksSection";
import useK8sData from "../deployEvent/useK8sData";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { ContainerStatus } from "../../../EventGroup/nativePodEvent/utils";

const clusterNamesMappingMotional: { [key: string]: string } = {
  "dev-spaceship": "spaceship-dev",
  "staging-data": "data-staging",
  "production-data": "data-production",
  "production-vats": "vats-production",
  "staging-vats": "vats-staging",
  "ops-fas": "fas-ops",
  "prod-lens": "lens",
  "prod-maps": "maps-prod",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getPodLinkForMotional = (
  clusterName: string,
  firstContainerName: string
): Link[] => {
  const mappedClusterName = clusterNamesMappingMotional[clusterName];
  if (!mappedClusterName) {
    return [];
  }
  const dataSourceCluster = encodeURIComponent(mappedClusterName);
  const logGroupCluster = encodeURIComponent(
    mappedClusterName.replace("-", "/") + "/"
  );
  const url = `https://grafana.lens.motional.com/explore?orgId=1&left=%7B%22datasource%22:%22cw-${dataSourceCluster}%22,%22queries%22:%5B%7B%22id%22:%22%22,%22region%22:%22default%22,%22namespace%22:%22%22,%22refId%22:%22A%22,%22queryMode%22:%22Logs%22,%22logGroupNames%22:%5B%22${logGroupCluster}\${namespace}%2F${firstContainerName}%22%5D,%22expression%22:%22fields%20@timestamp,%20message%20%5Cn%7C%20limit%20100%22,%22statsGroups%22:%5B%5D%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D`;
  return [
    {
      icon: getLinkIcon("grafana", "grafana"),
      text: "Logs",
      url: url,
    },
  ];
};

export const MotionalPodLinks: React.FC<{
  eventGroup: NativePodEventGroup;
  containerStatus: ContainerStatus[];
}> = ({ eventGroup, containerStatus }) => {
  const lastEvent = eventGroup.events[eventGroup.events.length - 1];
  const firstContainerName = containerStatus?.[0]?.name;
  const podLink: Link[] = getPodLinkForMotional(
    eventGroup.clusterName,
    firstContainerName
  );
  const [resource] = useKomodorServiceAsResourceWithInterval(
    eventGroup.serviceId
  );
  const { specsForDiff } = useK8sData(lastEvent.id);
  const isCompleted = !!eventGroup.endTime;
  return (
    <FeatureFlag name="showMotionalPodLinks">
      {firstContainerName && (
        <DrawerEventLinksSection
          linksTemplates={podLink}
          resource={resource}
          deploySpec={specsForDiff?.newSpec}
          startTime={eventGroup.startTime}
          endTime={eventGroup.endTime}
          isCompleted={isCompleted}
          clusterName={eventGroup.clusterName}
          namespace={eventGroup.namespace}
          isPodEvent={true}
        />
      )}
    </FeatureFlag>
  );
};
