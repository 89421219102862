import React from "react";

import { CheckboxSelectionOption } from "./CheckboxSelectionOption";

export const HiddenCheckboxOption: React.FC = () => {
  return (
    <CheckboxSelectionOption
      value={"test"}
      label={"test"}
      checked={false}
      onChange={() => undefined}
    />
  );
};
