import { camelCase } from "lodash";
import { useMemo } from "react";

import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { InspectionTableHeader } from "../ResourceListTableHead";

export const useInitialInspectionHeaders = (
  rawHeaders: string[],
  resourceType: KubernetesResource
): InspectionTableHeader[] => {
  return useMemo(() => {
    if (resourceType.Headers) {
      const headers: InspectionTableHeader[] = Object.assign(
        [],
        resourceType.Headers
      );
      const filterHeaders: string[] = [];
      resourceType.Headers.forEach((h) => {
        if (!rawHeaders.some((rh) => rh === camelCase(h.name))) {
          filterHeaders.push(h.name);
        }
      });
      return headers.filter((h) => !filterHeaders.includes(h.name));
    }
    if (resourceType.OverrideHeaders) {
      return resourceType.OverrideHeaders;
    }
    return rawHeaders.map((rh) => ({
      name: rh,
      show: true,
    }));
  }, [rawHeaders, resourceType.Headers, resourceType.OverrideHeaders]);
};
