import { compact, get, union, uniq } from "lodash";

export abstract class BaseNamesExtractor {
  abstract extractNamesFromService(
    spec: Record<string, unknown> | undefined
  ): string[];
  abstract readonly resourceType: string;
  abstract readonly volumeName: string;
}

export const extractNamesFromVolumes = (
  volumes: Record<string, unknown>[] | undefined,
  relatedResourceClass: BaseNamesExtractor
): string[] => {
  return uniq(
    compact(
      volumes?.map((obj) => {
        const resourceName = get(obj, relatedResourceClass.resourceType, {});
        return get(resourceName, relatedResourceClass.volumeName);
      })
    )
  );
};

export const extractNamesFromContainers = (
  containers: Record<string, unknown>[] | undefined,
  relatedResourceClass: BaseNamesExtractor
): string[] => {
  return containers
    ? uniq(
        containers.flatMap((container) => {
          const envEntries = get(container, "env", []) as Record<
            string,
            unknown
          >[];
          const envFromEntries = get(container, "envFrom", []) as Record<
            string,
            unknown
          >[];

          const envNames = envEntries.map(
            (entry) =>
              get(
                entry,
                `valueFrom.${relatedResourceClass.resourceType}KeyRef.name`,
                ""
              ) as string
          );

          const envFromNames = envFromEntries.map(
            (entry) =>
              get(
                entry,
                `${relatedResourceClass.resourceType}Ref.name`,
                ""
              ) as string
          );

          return union(compact(envNames), compact(envFromNames));
        })
      )
    : [];
};
