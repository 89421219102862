import { useCallback } from "react";
import { ValidationError } from "yup";

import { Severity } from "@/generated/reliabilityApi";
import { useSetFieldError } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useSetFieldError";
import { numericSchema } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/numericValidationSchema";

type ValidateFieldsParams = {
  configurationName: string;
  lowNumber: number;
  mediumNumber: number;
  highNumber: number;
  isReverseSeverityOrder?: boolean;
};

export const useValidateNumericFields = () => {
  const setFieldError = useSetFieldError();
  return useCallback(
    async ({
      configurationName,
      highNumber,
      mediumNumber,
      lowNumber,
      isReverseSeverityOrder,
    }: ValidateFieldsParams) => {
      try {
        await numericSchema.validate(
          {
            high: highNumber,
            medium: mediumNumber,
            low: lowNumber,
          },
          {
            context: { isReverseSeverityOrder },
          }
        );
      } catch (err) {
        const validationError = err as ValidationError;
        setFieldError({
          configurationName,
          severity: validationError.params?.path as Severity,
          errorMessage: validationError.message,
        });
      }
    },
    [setFieldError]
  );
};
