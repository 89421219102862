import React from "react";
import styled from "styled-components";

import EventDrawer from "../../../../../../common/ProcessList/EventDrawer";
import { useOverviewPageStore } from "../store/overviewPageStore";
import {
  selectCloseEventItemDrawer,
  selectEventItemDrawer,
} from "../store/overviewPageStoreSelectors";

const EventDetailsContainer = styled.div`
  font-size: 0.75rem;
`;

export const EventItemDrawer: React.FC = () => {
  const closeEventItemDrawer = useOverviewPageStore(selectCloseEventItemDrawer);
  const { currentEventItem, eventItemDrawerOpen } = useOverviewPageStore(
    selectEventItemDrawer
  );

  const onClose = () => {
    closeEventItemDrawer();
  };

  return currentEventItem?.renderEventDetails ? (
    <EventDetailsContainer>
      <EventDrawer
        open={eventItemDrawerOpen}
        event={currentEventItem}
        onClose={onClose}
      >
        {currentEventItem.renderEventDetails(onClose)}
      </EventDrawer>
    </EventDetailsContainer>
  ) : null;
};
