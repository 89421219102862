import { useQuery } from "@tanstack/react-query";

import {
  GetViolationHistoryResponse,
  getViolationHistoryUrl,
  ViolationsApiGetViolationHistoryRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";

export const GET_VIOLATION_HISTORY_PATH = "/api/v1/violations/:id/history";

type GetViolationHistoryDataParams = ViolationsApiGetViolationHistoryRequest & {
  enable?: boolean;
};

export const useGetViolationHistory = (
  params: GetViolationHistoryDataParams
) => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [GET_VIOLATION_HISTORY_PATH, params],
    async () =>
      await apiClient.get<GetViolationHistoryResponse>(
        getViolationHistoryUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    { enabled: params.enable }
  );
};
