import React from "react";
import Button from "@mui/material/Button";

import { useEditYamlByResource } from "../../../../../../../../../../../../shared/hooks/useEditYamlByResource";

const EditYamlButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button variant="outlined" onClick={onClick}>
    Edit YAML
  </Button>
);

export const EditYamlColumnHandler: React.FC<{
  clusterName: string | undefined;
  namespace: string | undefined;
  name: string | undefined;
  kind: string | undefined;
}> = (props) => {
  return useEditYamlByResource({
    ...props,
    EditYamlButton,
  });
};
