import React from "react";
import { MultiTagInput } from "@komodorio/design-system/komodor-ui";

import { RbacActionRule } from "./types";
import { resourcesOptions } from "./resourcesOptions";

export const InputResources: React.FC<{
  rule: RbacActionRule;
  readOnly?: boolean;
  handleResourcesChange: (resources: string[]) => void;
}> = ({ rule, readOnly, handleResourcesChange }) => {
  return (
    <MultiTagInput<string>
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      size={"small"}
      sx={{ borderWidth: "1px" }}
      id="custom-action-rules-resources-input"
      placeholder="Enter a resource name - press Return to add"
      onChange={(_, newValue) => {
        if (!newValue) return;
        if (typeof newValue === "string") {
          handleResourcesChange([...rule.resources, newValue]);
        } else {
          handleResourcesChange([...newValue]);
        }
      }}
      value={rule.resources}
      options={resourcesOptions}
      readOnly={readOnly}
    />
  );
};
