import { useEffect } from "react";
import { CombinedError, UseQueryResponse } from "urql";

import { useIsTabVisible } from "./useIsTabVisible";

export function useQueryPolling<Data>(
  [result, executeQuery]: UseQueryResponse<Data>,
  interval: number,
  pause?: boolean
): [Data | undefined, boolean, CombinedError | undefined] {
  const tabVisible = useIsTabVisible();

  useEffect(() => {
    if (pause || result.fetching || !tabVisible) return;

    const timeoutId = setTimeout(
      () => executeQuery({ requestPolicy: "network-only" }),
      interval
    );
    return () => clearTimeout(timeoutId);
  }, [executeQuery, result.fetching, interval, tabVisible, pause]);

  return [result.data, result.fetching, result.error];
}
