import React, { useMemo } from "react";
import styled from "styled-components";

import EventGroup from "../../../EventGroup";
import { IssueBanner } from "../../../../IssueBanner/IssueBanner";

import { useGetCorrelatedNodeTerminations } from "./useGetCorrelatedNodeTerminations";
import { useGetCorrelatedNodeIssues } from "./useGetCorrelatedNodeIssues";
import { getNodeIssueDetails } from "./utils";

import type PodPhaseGroup from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";

const MINUTES_BEFORE_PHASE = 15;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export interface NodeIssueBannerProps {
  podPhaseGroup: PodPhaseGroup;
  nodeName: string;
  onResourceClick: (eg: EventGroup) => void;
}

const NodeIssueBanner: React.FC<{
  group: EventGroup;
  onResourceClick: (eg: EventGroup) => void;
}> = ({ group, onResourceClick }) => {
  const props = getNodeIssueDetails(group);

  return (
    <IssueBanner
      {...props}
      borderTop={false}
      onIssueClicked={() => {
        onResourceClick(group);
      }}
    />
  );
};

const correlatedNodeIssuesStartTime = (start: Date) => {
  const startTime = new Date(start);
  startTime.setMinutes(startTime.getMinutes() - MINUTES_BEFORE_PHASE);
  return startTime;
};

export const NodeIssuesSection: React.FC<NodeIssueBannerProps> = ({
  podPhaseGroup,
  nodeName,
  onResourceClick,
}) => {
  const timeWindow = useMemo(() => {
    return {
      start: correlatedNodeIssuesStartTime(podPhaseGroup.startTime),
      end: podPhaseGroup.isCompleted ? podPhaseGroup.endTime : new Date(),
    };
  }, [
    podPhaseGroup.endTime,
    podPhaseGroup.isCompleted,
    podPhaseGroup.startTime,
  ]);

  const { nodeTerminationsGroups } = useGetCorrelatedNodeTerminations(
    podPhaseGroup,
    timeWindow
  );

  const { nodeIssues } = useGetCorrelatedNodeIssues(podPhaseGroup, timeWindow);

  const relevantNodeEvents = useMemo(() => {
    return [
      ...nodeTerminationsGroups.filter(
        (group) =>
          group.nodeName === nodeName &&
          group.clusterName === podPhaseGroup.clusterName
      ),
      ...nodeIssues.filter((group) => group.resourceName === nodeName),
    ];
  }, [nodeTerminationsGroups, nodeIssues, nodeName, podPhaseGroup.clusterName]);

  if (!relevantNodeEvents?.length) {
    return null;
  }

  return (
    <Section>
      {relevantNodeEvents
        .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
        .map((group) => (
          <NodeIssueBanner group={group} onResourceClick={onResourceClick} />
        ))}
    </Section>
  );
};
