import { HPA } from "@komodorio/design-system/icons";

import { KubernetesResource } from "./KubernetesResource";
import { AvailableActions } from "./AvailableActions";

export const KubernetesHelmResource: KubernetesResource = {
  NameInK8S: "helm",
  Group: "helm",
  NavBarName: "Helm",
  PresentName: "Helm Charts",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "Helm",
  Icon: HPA,
  hasLabels: false,
  ShowStatusFilter: true,
  SupportedActions: [
    AvailableActions.UpgradeHelmChart,
    AvailableActions.RollbackHelmChart,
    AvailableActions.UninstallHelmChart,
  ],
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Revision" },
    { name: "LastUpdated", displayName: "Updated" },
    { name: "Version", displayName: "Chart & Version" },
    { name: "Status", displayName: "Chart Status" },
  ],
};
