import {
  CustomGraphDataPoint,
  AggregatedMetricsResponse,
  MetricsData,
} from "../../../Metrics/types";
import { unixTimeToMilliseconds } from "../../../../shared/utils/timeUtils";
import {
  ROUND_TO_NEAREST_SECONDS,
  roundToNearestNSeconds,
} from "../../../Metrics/utils";

import { podContainersType, TooltipPayload } from "./types";

type AddToMetricsData = {
  newMetricsList: CustomGraphDataPoint[];
  prevMetricsList: CustomGraphDataPoint[];
  containerName: string;
};

export const addToMetricsData = ({
  prevMetricsList,
  newMetricsList,
  containerName,
}: AddToMetricsData): CustomGraphDataPoint[] => {
  const copy = [...prevMetricsList];
  newMetricsList.forEach((metric) => {
    const time = unixTimeToMilliseconds(
      roundToNearestNSeconds(metric.time, ROUND_TO_NEAREST_SECONDS)
    );
    const metricDataPoint = metric[containerName];
    // if time entry already exists, update it with the new value
    const existingDataPoint = copy.find((dataPoint) => dataPoint.time === time);
    if (existingDataPoint) {
      existingDataPoint[containerName] = metricDataPoint;
    } else {
      copy.push({
        time,
        [containerName]: metricDataPoint,
      });
    }
  });
  return copy;
};

type GenerateMetricsResponseParams = {
  hasData: boolean;
  loading: boolean;
  error: boolean;
};
export const generateMetricsResponse = ({
  loading,
  error,
  hasData,
}: GenerateMetricsResponseParams): AggregatedMetricsResponse => ({
  loading: !hasData && loading,
  error: !hasData && error ? "error" : null,
  metrics: {
    usage: hasData ? [{ y: 1, x: 1 }] : [],
    request: [],
    limit: [],
  },
  paused: false,
});

export const podContainerTypeToLabel = (type: podContainersType): string => {
  return type === "cpu" ? "CPU" : "Memory";
};

export const sortTooltipPayload = (
  payload: TooltipPayload[]
): TooltipPayload[] => {
  // here we could set limit to number of items and aggregate remaining results
  return payload.sort((a, b) => a.value - b.value);
};

export const getCommonGraphContainerWithStatusProps = (
  data: AggregatedMetricsResponse
) => ({
  loading: data.loading,
  error: data.error,
  hasGraphData:
    !data.error &&
    !data.loading &&
    !data.paused &&
    data.metrics.usage.length > 0,
});

export const hasLimitMetrics = (data: MetricsData) => {
  return data.limit.some((dp) => dp.y && dp.y > 0);
};
