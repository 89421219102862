// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVC from "../../../resources/pvc";
import useResourceWithInterval from "../../../useResource";

export const usePVRelatedResource = ({ resource }: { resource: PVC }) => {
  const { agentId, cluster, volumeName } = resource;

  const { resource: pvResource } = useResourceWithInterval({
    resourceType: "PersistentVolume",
    resourceName: volumeName,
    agentId,
    namespace: "",
    cluster,
    existData: {
      kind: "PersistentVolume",
      cluster,
      metadata: {
        name: volumeName,
      },
    },
    executeMutation: true,
  });

  return pvResource;
};

export const useSCRelatedResource = ({ resource }: { resource: PVC }) => {
  const { agentId, cluster, volumeName, storageClass } = resource;

  const { resource: scResource } = useResourceWithInterval({
    resourceType: "StorageClass",
    resourceName: storageClass,
    agentId,
    namespace: "",
    cluster,
    existData: {
      kind: "StorageClass",
      cluster,
      metadata: {
        name: volumeName,
      },
    },
    executeMutation: true,
  });

  return scResource;
};
