import React from "react";
import styled from "styled-components";

const TextContainer = styled.div`
  white-space: pre-line;
  line-height: 1.5rem;
`;

export const CpuThrottledWhatHappened = `The container/s experienced CPU throttling (CPU usage reached or exceeded 99% of CPU limits) in the past 7 days.`;
export const CpuThrottledHowToFixThis: JSX.Element = (
  <TextContainer>
    <div>
      1. Set appropriate CPU resource <b>requests</b> and <b>limits</b>
    </div>
    <div>
      2. Use <b>a Horizontal Pod Autoscaler (HPA)</b>
    </div>
    <div>
      3. Optimize your application code and containers to minimize unnecessary
      CPU usage
    </div>
  </TextContainer>
);

export const IssuesAfterDeployHowToFixThis: JSX.Element = (
  <TextContainer>TODO</TextContainer>
);

export const DeprecatedApisHowToFixThis: JSX.Element = (
  <TextContainer>
    You should consider upgrading to a supported version of Kubernetes to
    continue receiving updates and support. Consult the{" "}
    <a href="https://kubernetes.io/docs/home/">
      official Kubernetes documentation
    </a>{" "}
    or contact your cloud provider's support for guidance on the upgrade
    process. If you have any questions or need assistance, please reach out to
    the appropriate team or support channel.n order to overcome this issue +
    recommendation
  </TextContainer>
);
