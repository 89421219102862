import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import SearchFilled from "@mui/icons-material/Search";
import React from "react";
import styled from "styled-components";

import {
  OperatingSystem,
  getOperatingSystem,
} from "../../../../shared/utils/os/os";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

const SearchContainer = styled.div`
  padding: 3px 3px 3px 8px;
  border: 1px solid ${muiColors.gray[500]};
  border-radius: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 4px 4px 12px 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

export const CmdKTypography = styled(Typography).attrs({
  variant: "body2",
  color: muiColors.gray[50],
  fontSize: "12px",
})`
  padding: 2px 5px;
  border-radius: 2px;
  border: 1px solid ${muiColors.gray[600]};
  background: ${muiColors.gray[700]};
`;

export const SearchButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const operatingSystem = getOperatingSystem();

  return (
    <SearchContainer
      aria-label={AriaLabels.GlobalSearch.SearchButton}
      onClick={onClick}
    >
      <SearchFilled fontSize="small" />
      <Typography
        variant={"body2"}
        color={muiColors.gray[300]}
        fontSize="14px"
        sx={{ flex: 1 }}
      >
        Go-To
      </Typography>
      <CmdKTypography>
        {operatingSystem === OperatingSystem.Windows ? "Ctrl" : "⌘"} + K
      </CmdKTypography>
    </SearchContainer>
  );
};
