import React, { useState } from "react";

import { ThrottledApplicationColumns } from "../../shared/types";
import { InsightDrawerActionButton } from "../../shared/styles";
import ConfigureModal from "../../../../../../../../Actions/modals/ConfigureModal/ConfigureModal";
import { useActiveAgent } from "../../../../../../../../../shared/hooks/useAgents";

type IncreaseLimitButtonProps = {
  rowData: ThrottledApplicationColumns;
};

export const IncreaseLimitButton: React.FC<IncreaseLimitButtonProps> = ({
  rowData,
}) => {
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(true);
  };

  const agentId = useActiveAgent(rowData.clusterName) ?? "";

  return (
    <>
      <InsightDrawerActionButton variant={"outlined"} onClick={onClick}>
        Increase limit
      </InsightDrawerActionButton>
      <ConfigureModal
        isModalOpen={open}
        handleClose={() => setOpen(false)}
        resourceId={rowData.id}
        resourceName={rowData.serviceName}
        resourceType={rowData.serviceKind || ""}
        agentId={agentId}
        cluster={rowData.clusterName}
        namespace={rowData.namespace}
        defaultContainerName={rowData.container}
        containers={rowData.containers}
      />
    </>
  );
};
