import React from "react";
import { Button, ButtonProps } from "@komodorio/design-system/deprecated";

import { restrictionsNames } from "../Restrictions";
import useIsFreeTier from "../useIsFreeTier";
import { useIsTrialEnded } from "../../../shared/hooks/useUserMetadata/useUserMetadata";

import { FreemiumUsersButton } from "./users";
import { FreemiumRolesButton } from "./roles";
import { FreemiumPoliciesButton } from "./policies";
import { FreemiumClustersButton } from "./clusters";
import { FreemiumLockButton } from "./lock";

export interface FreemiumButtonProps extends ButtonProps {
  freemiumKey: restrictionsNames;
}

const restrictedButtons = {
  users: FreemiumUsersButton,
  roles: FreemiumRolesButton,
  policies: FreemiumPoliciesButton,
  clusters: FreemiumClustersButton,
  lock: FreemiumLockButton,
};

export const ButtonSupportFreemium: React.FC<FreemiumButtonProps> = ({
  freemiumKey,
  ...buttonProps
}) => {
  const isFreeTier = useIsFreeTier();
  const isTrialEnded = useIsTrialEnded();

  if (isFreeTier || isTrialEnded) {
    const FreemiumButton = restrictedButtons[freemiumKey];
    return <FreemiumButton {...buttonProps} />;
  }

  return <Button {...buttonProps} />;
};
