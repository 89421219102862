import { useEffect, useRef } from "react";

import { EndpointsResponseType } from "../../../shared/hooks/metrics-api/requestResponseMaps";
import { useMetricsAPIGet } from "../../../shared/hooks/metrics-api/client";
import { useCostOptimizationStore } from "../store/costOptimizationStore";
import {
  selectLastVisitTime,
  selectRightSizingFilters,
} from "../store/costOptimizationStoreSelectors";

export const useMetricsApiWithRefreshLogic = <
  M extends { [key in keyof EndpointsResponseType]: M }
>(
  ...args: Parameters<typeof useMetricsAPIGet>
): ReturnType<typeof useMetricsAPIGet<M>> => {
  const res = useMetricsAPIGet<M>(...args);
  useRefreshCallLogic(res.refresh);

  return res;
};

export const useRefreshCallLogic = (refreshCallback: () => void) => {
  const lastVisitTime = useCostOptimizationStore(selectLastVisitTime);
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);
  const lastVisitTimeRef = useRef(lastVisitTime);

  useEffect(() => {
    if (lastVisitTimeRef.current !== lastVisitTime) {
      lastVisitTimeRef.current = lastVisitTime;

      refreshCallback();
    }
  }, [refreshCallback, lastVisitTime, strategy]);
};
