import React from "react";
import {
  ButtonVariant,
  ActionItemVaraint,
} from "@komodorio/design-system/deprecated";
import {
  IconProps,
  Trash16,
  Code16,
  Scale16,
  Refresh16,
  Compare16,
  Reload16,
  Cog16,
  ArrowRight16,
  ArrowDown16,
  Drain16,
  Uncordon16,
  Cordon16,
  Shell16,
  FastForward16,
  Play16,
  PauseFilled16,
} from "@komodorio/design-system/icons";

import { DeleteButton } from "../buttons/DeleteButton";
import { DrainButton } from "../buttons/DrainButton";
import { EditButton } from "../buttons/EditButton";
import { ScaleButton } from "../buttons/ScaleButton";
import { CompareButton } from "../buttons/CompareButton";
import { DescribeButton } from "../buttons/DescribeButton";
import { RollbackButton } from "../buttons/RollbackButton";
import { ConfigureButton } from "../buttons/ConfigureButton";
import { RestartButton } from "../buttons/RestartButton";
import { RerunJobButton } from "../buttons/RerunJobButton";
import { RunNowJobButton } from "../buttons/RunNowJobButton";
import { UninstallHelmChartButton } from "../buttons/helm/UninstallHelmChartButton";
import { RollbackHelmChartButton } from "../buttons/helm/RollbackHelmChartButton";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ActionButtonProps } from "../buttons/types";
import { ExecPodShell } from "../buttons/ExecPodShell";
import { StartPortForwardingActionButton } from "../../PortForward/StartPortForwardingActionButton";
import { RevertButton } from "../buttons/RevertButton";
import { CustomResourceDrawerButton } from "../buttons/CustomResourceDrawerButton";

import {
  JobSuspendButton,
  JobResumeButton,
} from "@/components/Actions/buttons/JobSuspendResumeButton";

export interface ButtonStylesProps {
  buttonVariant: ButtonVariant;
  actionItemVariant: ActionItemVaraint;
  icon?: React.FC<IconProps>;
  text: string;
  disabled?: boolean;
}

const getCustomResourceDrawerButton = (
  text: string
): {
  button: React.FC<ActionButtonProps>;
  style: ButtonStylesProps;
} => {
  return {
    button: CustomResourceDrawerButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      text: text,
    },
  };
};

export const actionsMapping: {
  [key: string]: {
    button: React.FC<ActionButtonProps>;
    style: ButtonStylesProps;
  };
} = {
  [AvailableActions.Delete]: {
    button: DeleteButton,
    style: {
      buttonVariant: "dangerOutline",
      actionItemVariant: "danger",
      icon: Trash16,
      text: "Delete",
    },
  },
  [AvailableActions.Edit]: {
    button: EditButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Code16,
      text: "Edit Yaml",
    },
  },
  [AvailableActions.FetchLogs]: {
    button: DeleteButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Trash16,
      text: "Delete",
    },
  },
  [AvailableActions.JobRerun]: {
    button: RerunJobButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: ArrowRight16,
      text: "Rerun",
    },
  },
  [AvailableActions.RunNow]: {
    button: RunNowJobButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: ArrowDown16,
      text: "Run now",
    },
  },

  [AvailableActions.Restart]: {
    button: RestartButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Refresh16,
      text: "Restart",
    },
  },
  [AvailableActions.Drain]: {
    button: DrainButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Drain16,
      text: "Drain",
    },
  },
  [AvailableActions.Configure]: {
    button: ConfigureButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Cog16,
      text: "Configure Resources",
    },
  },
  [AvailableActions.Rollback]: {
    button: RollbackButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Reload16,
      text: "Rollback",
    },
  },
  [AvailableActions.GitRevert]: {
    button: RevertButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Reload16,
      text: "Revert",
    },
  },
  [AvailableActions.Compare]: {
    button: CompareButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Compare16,
      text: "Compare",
    },
  },
  [AvailableActions.Scale]: {
    button: ScaleButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Scale16,
      text: "Scale",
    },
  },
  [AvailableActions.Describe]: {
    button: DescribeButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Code16,
      text: "Describe",
    },
  },
  [AvailableActions.UninstallHelmChart]: {
    button: UninstallHelmChartButton,
    style: {
      buttonVariant: "dangerOutline",
      actionItemVariant: "danger",
      icon: Trash16,
      text: "Uninstall",
    },
  },
  [AvailableActions.RollbackHelmChart]: {
    button: RollbackHelmChartButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Reload16,
      text: "Rollback",
    },
  },
  [AvailableActions.ExecPodShell]: {
    button: ExecPodShell,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Shell16,
      text: "Pod Shell",
    },
  },
  [AvailableActions.PortForward]: {
    button: StartPortForwardingActionButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: FastForward16,
      text: "Port Forward",
    },
  },
  [AvailableActions.ArgoWorkflowTemplateDetails]: getCustomResourceDrawerButton(
    "WorkflowTemplate details"
  ),
  [AvailableActions.ArgoClusterWorkflowTemplateDetails]:
    getCustomResourceDrawerButton("ClusterWorkflowTemplate details"),
  [AvailableActions.ArgoCronWorkflowDetails]: getCustomResourceDrawerButton(
    "CronWorkflow details"
  ),
  [AvailableActions.ResumeCronJob]: {
    button: JobResumeButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: Play16,
      text: "Resume",
    },
  },
  [AvailableActions.SuspendCronJob]: {
    button: JobSuspendButton,
    style: {
      buttonVariant: "secondary",
      actionItemVariant: "default",
      icon: PauseFilled16,
      text: "Suspend",
    },
  },
};

export const cordonUncordonStyles = (
  isNodeUnschedulable: boolean
): ButtonStylesProps => {
  return isNodeUnschedulable
    ? {
        buttonVariant: "secondary",
        actionItemVariant: "default",
        icon: Uncordon16,
        text: "Uncordon",
      }
    : {
        buttonVariant: "secondary",
        actionItemVariant: "default",
        icon: Cordon16,
        text: "Cordon",
      };
};
