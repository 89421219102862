import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";

import { ColumnField } from "@/components/ClustersView/components/ClustersList/types";

export const TABLE_HEIGHT = "630px";

export const CLUSTERS_VIEW_COLUMNS: ColumnField[] = [
  "clusterName",
  "k8sVersion",
  "agentVersion",
  "nodes",
  "allocation",
  "pods",
  "unhealthyServices",
  "violations",
  "potentialSavings",
];

export const PAGINATION_SIZE_OPTIONS = [10, 15, 25];
export const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 10,
};
export const DEFAULT_PAGINATION_PARAMS = {
  offset: DEFAULT_PAGINATION_MODEL.page,
  limit: DEFAULT_PAGINATION_MODEL.pageSize,
};

export const DEFAULT_SORTING_MODEL: GridSortModel = [
  { field: "unhealthyServices", sort: "desc" },
];
export const DEFAULT_SORTING_PARAMS = {
  sort: DEFAULT_SORTING_MODEL.map(
    (item) => `${item.sort === "desc" ? "-" : ""}${item.field}`
  ).join(","),
};

export const partialListMessage = (isClusterGroup = false) =>
  `You are currently in ${
    isClusterGroup ? "Cluster Group" : "Cluster"
  } View. To view all clusters, switch to`;
