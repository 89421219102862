import React from "react";
import styled from "styled-components";

import { TimeWindow } from "../../shared/types/TimeWindow";

import { ClearAllSelectedPodsButton, PodsSelector } from "./PodsSelector";
import { useSelectedPodsForEvents } from "./PodsSelector/SelectedPodsProvider";

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 1rem;
`;

export const PodsSelectionControls: React.FC<{
  timeWindow: TimeWindow;
}> = ({ timeWindow }) => {
  const { selectedPodUidsForEvents, setSelectedPodUidsForEvents } =
    useSelectedPodsForEvents();

  return (
    <Grid>
      <ClearAllSelectedPodsButton
        selectedPodsAmount={selectedPodUidsForEvents?.length || 0}
        onClick={() => {
          setSelectedPodUidsForEvents([]);
        }}
      />
      <PodsSelector timeWindow={timeWindow} />
    </Grid>
  );
};
