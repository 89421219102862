import React, { useMemo } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import { SeverityChip } from "../../../../../../shared/SeverityChip/SeverityChip";
import { Severity } from "../../../../../../../ReliabilityTypes";
import {
  BasicViolation,
  CheckType,
  ViolationStatus,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";
import { unixTimeToMilliseconds } from "../../../../../../../../../shared/utils/timeUtils";
import { ViolationStatusSelector } from "../../../../../../shared/ViolationStatusSelector/ViolationStatusSelector";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";
import { useDrawersStackStore } from "../../../../../../../../../shared/store/drawersStackStore/drawersStackStore";
import { popDrawerSelector } from "../../../../../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { useCurrentDrawerState } from "../../../../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../../../../shared/store/drawersStackStore/types";
import { isCheckIsStatic } from "../../../violationDrawerUtils";

import { horizontalPadding } from "./styles";

export const DateFormatString = "MMM d ,yyyy";

const Container = styled.div`
  display: flex;
  padding: 32px 24px 24px ${horizontalPadding}px;
  gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TimeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const TimeLabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 24px;
  }
`;

const {
  violationDrawer: {
    header: headerAriaLabel,
    headerDates: headerDatesAriaLabel,
    title: titleAriaLabel,
  },
} = reliabilityArialLabels;

const Time: React.FC<{ label: string; time?: string }> = ({ label, time }) => {
  return (
    <TimeLabel>
      <Typography variant={"body2"} color={"text.secondary"}>
        {label}
      </Typography>
      {time ? (
        <Typography variant={"body2"} color={"text.primary"}>
          {time}
        </Typography>
      ) : (
        <Skeleton sx={{ fontSize: "14px" }} width={100} />
      )}
    </TimeLabel>
  );
};

export interface HeaderProps {
  title?: string;
  severity?: Severity;
  firstSeen?: number;
  lastEvaluatedAt?: number;
  status?: ViolationStatus;
  komodorUid?: string;
  checkType?: CheckType;
  supportingData?: ViolationSupportingData;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  severity,
  firstSeen,
  lastEvaluatedAt,
  status,
  komodorUid,
  checkType,
  supportingData,
}) => {
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();

  const popDrawer = useDrawersStackStore(popDrawerSelector);

  const firstSeenDate = firstSeen
    ? format(new Date(unixTimeToMilliseconds(firstSeen)), DateFormatString)
    : undefined;
  const lastEvaluatedDate = lastEvaluatedAt
    ? format(
        new Date(unixTimeToMilliseconds(lastEvaluatedAt)),
        DateFormatString
      )
    : undefined;

  const hasAllViolationData =
    !!severity && !!checkType && !!komodorUid && !!supportingData;

  const violation = useMemo(() => {
    if (hasAllViolationData && currentDrawerState?.violationId) {
      return {
        id: currentDrawerState.violationId,
        severity,
        status,
        komodorUid,
        checkType,
        supportingData,
      };
    }
    return undefined;
  }, [
    hasAllViolationData,
    currentDrawerState,
    severity,
    status,
    komodorUid,
    checkType,
    supportingData,
  ]);

  const isStaticCheck = isCheckIsStatic(checkType);

  return (
    <Container aria-label={headerAriaLabel}>
      <DetailsContainer>
        <StyledIconButton size="small" onClick={() => popDrawer()}>
          <CloseOutlined />
        </StyledIconButton>
        <SummaryContainer>
          {title ? (
            <Typography variant={"h3"} aria-label={titleAriaLabel}>
              {title}{" "}
              {!isStaticCheck ? (
                severity ? (
                  <SeverityChip severity={severity} />
                ) : (
                  <Skeleton variant="rounded" width={50} height={24} />
                )
              ) : null}
            </Typography>
          ) : (
            <Skeleton variant="rounded" width={136} height={24} />
          )}
          <TimeContainer aria-label={headerDatesAriaLabel}>
            <Time label={"First seen:"} time={firstSeenDate} />
            <Divider orientation={"vertical"} />
            <Time label={"Last evaluated:"} time={lastEvaluatedDate} />
          </TimeContainer>
        </SummaryContainer>
      </DetailsContainer>
      <StatusContainer>
        {hasAllViolationData && currentDrawerState?.violationId && (
          <ViolationStatusSelector
            violation={violation as BasicViolation}
            size="large"
            violationLink={window.location.href}
          />
        )}
      </StatusContainer>
    </Container>
  );
};
