import { Container } from "komodor-types";
import { isUndefined } from "lodash";

import { quantityToScalar } from "../../../../../../shared/utils/numbers/quantityToScalar";
import { ContainersValues } from "../types";

const getFormattedResource = (
  resource:
    | {
        memory?: string | null | undefined;
        cpu?: string | null | undefined;
      }
    | undefined
) => {
  if (isUndefined(resource)) return undefined;
  return {
    ...(resource.memory && {
      //convert to Mib
      memory: Number(quantityToScalar(resource.memory)) / Math.pow(1024, 2),
    }),
    ...(resource.cpu && {
      //convert to milliCores
      cpu: Number(quantityToScalar(resource.cpu)) * 1000,
    }),
  };
};

export const formatCurrentResourceValues = (
  containers: Container[]
): ContainersValues[] => {
  return containers.map((container) => {
    return {
      ...container,
      resources: {
        ...(container.resources.limits && {
          limits: getFormattedResource(container.resources.limits),
        }),
        ...(container.resources.requests && {
          requests: getFormattedResource(container.resources.requests),
        }),
      },
    };
  });
};
