import React from "react";
import { Container } from "komodor-types";
import { Input } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import {
  HandleResourceChangeProps,
  ResourceConfigurationType,
  ResourceType,
} from "./types";

const InputContainer = styled.div`
  min-height: 3.8rem;
`;

export const ResourceInput: React.FC<{
  selectedContainer?: Container;
  resourceType: ResourceType;
  resourceConfigType: ResourceConfigurationType;
  handleResourceChange: HandleResourceChangeProps;
  selectedContainerValidation?: Container;
}> = ({
  selectedContainer,
  resourceType,
  resourceConfigType,
  handleResourceChange,
  selectedContainerValidation,
}) => {
  return (
    <InputContainer>
      <Input
        errorMessage={
          selectedContainerValidation?.resources?.[resourceConfigType]?.[
            resourceType
          ] || undefined
        }
        size="small"
        width="10rem"
        label={resourceType}
        placeholder={`${resourceType} ${resourceConfigType} not set`}
        value={
          selectedContainer?.resources?.[resourceConfigType]?.[resourceType] ??
          ""
        }
        onChange={(e) =>
          handleResourceChange(resourceConfigType, resourceType, e.target.value)
        }
      />
    </InputContainer>
  );
};
