import { OptionType } from "@komodorio/design-system/deprecated";
import React, { useMemo } from "react";

import { useCreationPageContext } from "../../../../context/CreationPageContext";

import { useMissingServiceIdentifierDetection } from "./useMissingServiceIdentifierDetection";
import { StyledSelect } from "./StyledSelect";
import { SelectContainer } from "./SelectContainer";
import { toSelectableOption } from "./utils";
import { VALUE_MISSING_ERROR } from "./constants";

interface NamespaceSelectorProps {
  namespaces: Set<string>;
  selectedNamespace?: string;
  onNamespaceSelection: (namespace: string) => void;
}

export const NamespaceSelector: React.FC<NamespaceSelectorProps> = ({
  namespaces,
  selectedNamespace,
  onNamespaceSelection,
}) => {
  const { setErrorState } = useCreationPageContext();

  const [namespaceMissing] = useMissingServiceIdentifierDetection(
    !!selectedNamespace
  );

  const namespaceOptions = useMemo(
    () => Array.from(namespaces).map(toSelectableOption),
    [namespaces]
  );

  const handleNamespaceSelection = ({
    value: namespace,
  }: OptionType<string>) => {
    onNamespaceSelection(namespace);
    setErrorState({ servicesSelectedError: undefined });
  };

  return (
    <SelectContainer>
      <StyledSelect
        options={namespaceOptions}
        value={namespaceOptions.find(
          (option) => option.value === selectedNamespace
        )}
        onChange={handleNamespaceSelection}
        searchable
        listMaxHeight="16rem"
        errorMessage={namespaceMissing ? VALUE_MISSING_ERROR : undefined}
        placeholder="Select value"
      />
    </SelectContainer>
  );
};
