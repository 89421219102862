import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TooltipWrapper } from "react-tooltip";

import { ColorIndication } from "../../../../../utils/graphUtils";
import { CostsChartsLegend } from "../../../../../types/costOptimizationTypes";
import { LegendItem } from "../../../../shared/graph/Legend";
import { Path } from "../../../../shared/graph/Path";

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 5px;
`;

const LegendItemContainer = styled.div`
  cursor: pointer;
`;

interface AllocationLegendProps extends CostsChartsLegend {
  setCurrentValueHover: (value: string | null) => void;
}

export const ChartLegend: React.FC<AllocationLegendProps> = ({
  hide,
  setHide,
  props,
  setCurrentValueHover,
}) => {
  const { payload } = props;
  const content = useMemo(
    () =>
      payload ? (
        <>
          {[...payload].reverse().map((legendItem) => {
            const isHide = hide[legendItem.value];

            return (
              <Tooltip
                id={legendItem.value}
                key={legendItem.value}
                placement="top"
                title={<Path value={legendItem.value} isHighlighted={false} />}
              >
                <LegendItemContainer
                  key={legendItem.value}
                  onMouseEnter={() =>
                    isHide ? null : setCurrentValueHover(legendItem.value)
                  }
                  onMouseLeave={() => setCurrentValueHover(null)}
                >
                  <TooltipWrapper tooltipId={legendItem.value}>
                    <LegendItem onClick={() => setHide(legendItem.value)}>
                      <ColorIndication
                        color={legendItem.color}
                        isunselected={isHide}
                      />
                      <Typography
                        variant="caption"
                        color={`${muiColors.gray[700]}`}
                      >
                        <Path value={legendItem.value} isLimitedPath />
                      </Typography>
                    </LegendItem>
                  </TooltipWrapper>
                </LegendItemContainer>
              </Tooltip>
            );
          })}
        </>
      ) : null,
    [hide, payload, setCurrentValueHover, setHide]
  );

  return <LegendContainer>{content}</LegendContainer>;
};
