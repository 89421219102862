import EventGroup from "../../EventGroup";
import {
  AnalyticEvents,
  dispatchEvent,
} from "../../../../shared/hooks/analytics";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import createEventsLine from "./createEventsLine";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";
import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";

interface useEventsOnLineProps {
  swimlanesPerPage: LineGroup[];
  start: Date;
  end: Date;
  onMouseEnter: (id: string) => void;
  onMouseLeave: (id: string) => void;
  onClick: (id: string) => void;
  chartWidth: number;
  chartConfig: TimelineChartConfig;
  highlightedId?: string;
}

export const useEventsOnLine = ({
  swimlanesPerPage,
  start,
  end,
  onMouseEnter,
  onMouseLeave,
  onClick,
  chartWidth,
  chartConfig,
  highlightedId,
}: useEventsOnLineProps) => {
  const highlightSort = (events: EventGroup[]) => {
    if (!highlightedId || !events.map((e) => e.id).includes(highlightedId)) {
      return events.sort(
        (a, b) => a.startTime.getTime() - b.startTime.getTime()
      );
    }
    return events.sort((a, b) => {
      if (a.id === highlightedId) {
        return 1;
      }
      if (b.id === highlightedId) {
        return -1;
      }
      return 0;
    });
  };

  return swimlanesPerPage
    .map((line, index) => {
      return highlightSort(line.events).map((eventGroup) => {
        return createEventsLine(
          eventGroup,
          index + 1,
          start,
          end,
          () => {
            onMouseEnter(eventGroup.id);
          },
          () => {
            onMouseLeave(eventGroup.id);
          },
          eventGroup.renderEventDetails
            ? () => {
                onClick(eventGroup.id);
                dispatchEvent(AnalyticEvents.Charts.Timeline.EventGroup_Click, {
                  page: window.location.pathname,
                  eventGroupId: eventGroup.id,
                });
              }
            : undefined,
          highlightedId,
          chartWidth,
          chartConfig
        );
      });
    })
    .flat();
};
