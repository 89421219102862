import React from "react";

import { DescribeDateItem, DescribeTextItem } from "./common/DescribeItem";
import { DescribePanel } from "./common/DescribeSection";
import { DescribeKeyValueItem } from "./common/DescribeTagListItem";
import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

// eslint-disable-next-line import/no-cycle
import CertManagerOrder from "@/components/ResourceView/resources/certManagerOrder";

interface Props {
  resource: CertManagerOrder;
}

export const CertManagerOrderDetailsTab: React.FC<Props> = ({ resource }) => {
  const fullObj = resource.fullObj;
  const { creationTimestamp, name, labels, annotations } =
    fullObj.metadata ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        {fullObj.status?.state && (
          <DescribeTextItem name="State" value={fullObj.status.state} />
        )}
      </DescribePanel>
    </DescribeTabContentWrapper>
  );
};
