import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const ResultTitle = styled(Typography).attrs({
  variant: "body2",
  color: "text.secondary",
  marginBottom: "8px",
  width: "10rem",
})``;

export const ResultLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;
`;

export const ShortPanel = styled.div<{ center: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  ${({ center }) => center && "align-items: center; justify-content: center;"}
`;

export const DetailsContainer = styled.div`
  flex: 1;
`;
