import { useQuery } from "@tanstack/react-query";

import {
  getAllChecksUrl,
  GetAllChecksResponse,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";

export const GET_VIOLATION_CHECKS_PATH = "/api/v1/checks";

export const useGetViolationChecks = () => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [GET_VIOLATION_CHECKS_PATH],
    async () =>
      await apiClient.get<GetAllChecksResponse>(
        getAllChecksUrl({}, apiClient.defaults.baseURL ?? "")
      )
  );
};
