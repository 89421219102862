import React, { useCallback, useState } from "react";
import { muiColors } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "styled-components";

import { costOptimizationAriaLabels } from "../../constants/costOptimizationAriaLabels";

import { useFetchSettingsConfigData } from "./costOptimizationSettingsHooks";
import { SettingsForm } from "./SettingsForm";

const StyledContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${muiColors.gray[400]};
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  cursor: pointer;
`;

const StyledDialogContainer = styled.div`
  background-color: ${muiColors.gray[50]};
  max-width: 900px;
  min-width: 400px;
  width: 100%;
`;

const StyledTopSection = styled.div`
  background: white;
  padding: 32px;
  border-bottom: 1px solid ${muiColors.gray[200]};
`;

export const CostOptimizationSettings: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);

  useFetchSettingsConfigData();
  const closeModal = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <StyledContainer>
      <IconButton
        onClick={() => setShowDialog(true)}
        aria-label={costOptimizationAriaLabels.settings.button}
      >
        <StyledSettingsIcon />
      </IconButton>
      <Dialog
        open={showDialog}
        maxWidth={false}
        onClose={() => setShowDialog(false)}
      >
        <StyledDialogContainer>
          <StyledTopSection>
            <Typography variant={"h2"}>Cost Settings</Typography>
          </StyledTopSection>
          <SettingsForm onClose={closeModal} />
        </StyledDialogContainer>
      </Dialog>
    </StyledContainer>
  );
};
