import React from "react";
import styled from "styled-components";

import { PagePadding } from "../../../ReliabilityStyles";

import { IgnoreChecksOverview } from "./IgnoreChecksOverview";
import { IgnoreChecksDrawer } from "./IgnoreChecksDrawer/IgnoreChecksDrawer";

const StyledContainer = styled.div`
  ${PagePadding};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IgnoreChecksPage: React.FC = () => {
  return (
    <StyledContainer>
      <IgnoreChecksOverview />
      <IgnoreChecksDrawer />
    </StyledContainer>
  );
};
