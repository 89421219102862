import React, { ReactNode } from "react";

export function addWBR(text: string): ReactNode {
  const parts = text.split("/");
  const first = parts.shift();
  const last = parts.pop();
  const nodes: ReactNode[] = [
    first,
    ...parts.flatMap((p, index) => [<wbr key={index} />, "/", p]),
  ];
  if (last) {
    const tagIndex = last.lastIndexOf(":");
    if (tagIndex === -1) {
      nodes.push(<wbr key={parts.length + 2} />, "/", last);
    } else {
      const beforeTag = last.substring(0, tagIndex);
      const tag = last.substring(tagIndex + 1);
      nodes.push(
        <wbr key={parts.length + 3} />,
        "/",
        beforeTag,
        <wbr key={parts.length + 4} />,
        ":",
        tag
      );
    }
  }
  return nodes;
}
