export const appViewAriaLabels = {
  dynamicScopeSelector: "workspace dynamic scope selector",
  appViewSelector: "workspace selector",
  applicationsScopingErrorPage: {
    errorMessage: "workspace scoping error message",
  },
  overviewPage: {
    resourcesInventory: {
      itemsContainer: "resource items container",
      unhealthyResources: "unhealthy resources",
    },
    insights: {
      availabilityIssues: {
        viewIssues: "view issues",
      },
      bestPracticesMissingResourcesLimits: {
        editResources: "edit resources",
      },
      bestPracticesSecurityFailed: {
        viewIssues: "view issues",
      },
      failedDeploys: {
        viewRecent: "view recent deploys",
      },
      potentialCostSaving: {
        openRecommendation: "open recommendation",
        recommendationAccuracy: "workspace insight recommendation accuracy",
      },
      insightsDrawer: {
        close: "close insights drawer",
      },
      linesGraph: "lines graph",
    },
    overviewMetrics: {
      availabilityIssues: "overview availability issues",
      costOptimizationTrend: "overview cost optimization trend",
      contentUnavailable: "content is not available",
      mainMetric: "main metric trend",
    },
    header: {
      actionButton: "workspace actions button",
      actionsMenu: "actions menu",
    },
  },
};

export const overviewPageInsightsAriaLabels =
  appViewAriaLabels.overviewPage.insights;

export const overviewMetricsAriaLabels =
  appViewAriaLabels.overviewPage.overviewMetrics;
