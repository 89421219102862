import { muiColors } from "@komodorio/design-system";
import { Line } from "recharts";

import {
  sharedDashedLineProps,
  sharedStraightLineProps,
} from "@/components/Metrics/constants";
import { LegendItem } from "@/components/Metrics/GenericLegend/GenericLegend";
import { TooltipItemIcon } from "@/components/Metrics/graphEntity/TooltipItemIcon";
import { MetricsGraphContainerProps } from "@/components/Metrics/types";
import { Tooltip } from "@/components/Metrics/Tooltip/Tooltip";

export const enum ReplicasOverTimeGraphDataKeys {
  ReadyReplicas = "readyReplicas",
  DesiredReplicas = "desiredReplicas",
}

const dataKeyToStroke: Record<ReplicasOverTimeGraphDataKeys, string> = {
  [ReplicasOverTimeGraphDataKeys.ReadyReplicas]: muiColors.blue[500] || "",
  [ReplicasOverTimeGraphDataKeys.DesiredReplicas]: muiColors.gray["600"] || "",
};

export const getLines = () => {
  return (
    <>
      <Line
        dataKey={ReplicasOverTimeGraphDataKeys.DesiredReplicas}
        stroke={dataKeyToStroke[ReplicasOverTimeGraphDataKeys.DesiredReplicas]}
        {...sharedDashedLineProps}
      />
      <Line
        stroke={dataKeyToStroke[ReplicasOverTimeGraphDataKeys.ReadyReplicas]}
        dataKey={ReplicasOverTimeGraphDataKeys.ReadyReplicas}
        {...sharedStraightLineProps}
      />
    </>
  );
};

export const tooltipContent: MetricsGraphContainerProps["tooltipContent"] = (
  tooltipProps
) => {
  return (
    <Tooltip
      tooltipItems={tooltipItems}
      tooltipProps={tooltipProps}
      dataTransformFunction={(tick) => ({
        value: tick,
      })}
    />
  );
};

const tooltipItems = [
  {
    dataKey: ReplicasOverTimeGraphDataKeys.ReadyReplicas,
    title: "ready replicas",
    tooltipItemKey: "replicas",
    icon: (
      <TooltipItemIcon
        color={dataKeyToStroke[ReplicasOverTimeGraphDataKeys.ReadyReplicas]}
      />
    ),
  },
  {
    dataKey: ReplicasOverTimeGraphDataKeys.DesiredReplicas,
    title: "desired replicas",
    tooltipItemKey: "desiredReplicas",
    icon: (
      <TooltipItemIcon
        color={dataKeyToStroke[ReplicasOverTimeGraphDataKeys.DesiredReplicas]}
      />
    ),
  },
];

export const legendItems: LegendItem[] = [
  {
    color: dataKeyToStroke[ReplicasOverTimeGraphDataKeys.ReadyReplicas],
    label: "Replicas",
  },
];
