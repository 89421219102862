import { useCallback } from "react";

import { useHasChangedFormValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasChangedFormValues";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { useHasEnteredFormValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasEnteredFormValues";
import { discardChangesDialogTexts } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";

export const useOnCloseDrawerWithConfirmation = () => {
  const { onClose, policyId, setConfirmationDialogProps, isReadOnlyMode } =
    usePolicyDrawerContext();
  const hasChangedFormValues = useHasChangedFormValues();
  const hasEnteredFormValues = useHasEnteredFormValues();

  const showConfirmationDialog = useCallback(() => {
    setConfirmationDialogProps({
      ...discardChangesDialogTexts,
      onConfirm: onClose,
    });
  }, [onClose, setConfirmationDialogProps]);

  return useCallback(() => {
    if (isReadOnlyMode) return onClose();
    if (policyId === undefined) {
      if (hasEnteredFormValues()) {
        return showConfirmationDialog();
      }
      return onClose();
    }
    if (hasChangedFormValues) {
      return showConfirmationDialog();
    }
    onClose();
  }, [
    hasChangedFormValues,
    hasEnteredFormValues,
    isReadOnlyMode,
    onClose,
    policyId,
    showConfirmationDialog,
  ]);
};
