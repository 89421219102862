import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { blackGreyText } from "../../Colors";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../shared/hooks/analytics";
import { useAllActiveAgentInfos } from "../../shared/hooks/useAgentInfo/useAgentInfo";
import { useKomodorServices } from "../../shared/hooks/useKomodorServices";
import LinkToDocs from "../common/LinkToDocs";
import { LinesLoader } from "../common/loaders/Line";
import ResponsiveLayout from "../common/ResponsiveLayout";
import { Text, H2Poppins } from "../common/typography";
import { UpgradeCommands } from "../common/upgradeAgentCta/UpgradeAgentCTA";
import CopyCode from "../integrations/installation/kubernetes/CopyCode";
import ExploreKomodorServices from "../servicesView/ExploreKomodorServices";
import { doesAgentVersionSupportJobs } from "../../shared/utils/agent/agent";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useJobsPageFiltersLocalStorageContext } from "../../shared/context/JobsPageFiltersLocalStorageProvider";
import { TopLinearLoader } from "../servicesView";
import { useGetHelmInstallationCommand } from "../../shared/hooks/useGetHelmInstallationCommand";
import { KomodorServiceType } from "../servicesView/types";

import PaginatedExploreJobs from "./pagination/PaginatedExploreJobs";

import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";

const Unavailable = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 1rem;
  width: 32rem;
  margin: 30vh auto;
  text-align: center;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  color: ${blackGreyText};
`;

const JobsView: React.FC = () => {
  const jobs = useKomodorServices().jobs;
  const { serverSideFiltersForJobsView } = useOverridableFlags();
  const storedJobsViewQS = useJobsPageFiltersLocalStorageContext();

  useQueryStringInLocalStorage({ item: storedJobsViewQS });

  const agents = useAllActiveAgentInfos();
  const isJobsDataAvailable = useMemo(
    () => agents?.some((agentProps) => doesAgentVersionSupportJobs(agentProps)),
    [agents]
  );

  useEffect(() => {
    if (!isJobsDataAvailable) {
      dispatchEvent(AnalyticEvents.JobsView.JobsView_NotAvailable);
    }
  }, [isJobsDataAvailable]);

  const [showLinearLoader, setShowLinearLoader] = useState(false);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_JOB_EVENTS
  );
  const docsStr = useGetHelmInstallationCommand(UpgradeCommands.UPGRADE_DOCS);
  return (
    <>
      <TopLinearLoader isLoading={showLinearLoader} />
      <ResponsiveLayout>
        {isJobsDataAvailable === undefined ? (
          <Unavailable>
            <LinesLoader />
          </Unavailable>
        ) : !isJobsDataAvailable ? (
          <Unavailable>
            <H2Poppins>Jobs not available</H2Poppins>
            <div>
              <StyledText>
                Update the Komodor agent to view and monitor your job
              </StyledText>
              <CopyCode value={commandStr} width="32rem" />
            </div>
            <LinkToDocs docsUrl={docsStr} />
          </Unavailable>
        ) : serverSideFiltersForJobsView ? (
          <PaginatedExploreJobs setShowLinearLoader={setShowLinearLoader} />
        ) : (
          <ExploreKomodorServices
            komodorServices={jobs}
            type={KomodorServiceType.job}
          />
        )}
      </ResponsiveLayout>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default JobsView;
