import React from "react";
import styled from "styled-components";
import { IconButton, Typography } from "@komodorio/design-system/deprecated";
import { Close24 } from "@komodorio/design-system/icons";

import { CustomEventsForm } from "./CustomEventsForm";
import { CustomEventsLink } from "./CustomEventsLink";

type CustomEventsInstallationProps = { closeModalCallback: () => void };

const Container = styled.div`
  width: 456px;
`;

const CloseButton = styled(IconButton).attrs({ icon: Close24, noBorder: true })`
  position: absolute;
  margin-inline: 1rem;
  right: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const CustomEventsInstallation: React.FC<
  CustomEventsInstallationProps
> = ({ closeModalCallback }: CustomEventsInstallationProps) => {
  return (
    <Container>
      <HeaderContainer>
        <Typography variant={"headline"} size={"small"}>
          Generate API key
        </Typography>
        <CloseButton onClick={closeModalCallback} />
      </HeaderContainer>
      <CustomEventsForm />
      <CustomEventsLink />
    </Container>
  );
};
