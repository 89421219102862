import React, { useCallback } from "react";
import { palette } from "@komodorio/design-system";
import { Link } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";

import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { ColumnProperties } from "../TableProperties";
import { useNodesContext } from "../../../Inspection/NodesProvider";
import { getStatusGroup } from "../../../common/statusMapper";
import { KubernetesNodesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import EventGroup from "../../EventGroup";
import { useOpenIssueTooltip } from "../../../OpenIssues/useOpenIssueTooltip";
import { useOpenIssueByResource } from "../../../OpenIssues/useOpenIssuesByResource";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CellWithIssue, IssueAlertIcon } from ".";

const CustomBackgroundDiv = styled.div<{ color: string }>`
  ${({ color }) =>
    `background-color: ${color};
    border: 4px solid ${color};
  border-top: 2px solid ${color};
  border-bottom: 2px solid ${color};`}
  width: min-content;
  border-radius: 4px;
  text-transform: capitalize;
  &.deleted {
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    gap: 4px;

    background: ${palette.gray[50]};

    border: 1px solid ${palette.gray[500]};
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
  }
`;

interface NodeDataCellProps {
  cellName: string;
  cellData: string;
  clusterName: string;
  properties: ColumnProperties;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  rowId: string;
}

export const NodeDataCell: React.FC<NodeDataCellProps> = ({
  cellName,
  cellData,
  clusterName,
  properties,
  setCorrelatedEvent,
  rowId,
}) => {
  const nodes = useNodesContext();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const nodeName = cellData;
  const onNodeClick = useCallback(() => {
    dispatchEvent(
      AnalyticEvents.Inspection.Inspection_pods_node_describe_clicked,
      { resource: KubernetesNodesResource.NavBarName }
    );
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: clusterName,
      namespace: "",
      resourceType: KubernetesNodesResource.Kind,
      resourceName: nodeName,
    });
  }, [clusterName, nodeName, pushDrawer]);

  const openIssue = useOpenIssueByResource(
    clusterName,
    undefined,
    KubernetesNodesResource.Kind,
    nodeName
  );
  const [tooltip, tooltipId, correlatedIssue, ariaLabel] = useOpenIssueTooltip(
    openIssue,
    undefined,
    setCorrelatedEvent,
    rowId
  );

  return (
    <td key={cellName}>
      <TooltipWrapper tooltipId={tooltipId}>
        <CellWithIssue>
          {openIssue && (
            <>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  if (correlatedIssue) setCorrelatedEvent(correlatedIssue);
                }}
                aria-label={ariaLabel}
              >
                <IssueAlertIcon />
              </Link>
              {tooltip}
            </>
          )}
          {properties.clickable ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {!!nodes?.length && (
                <CustomBackgroundDiv
                  data-testid="pods-table-node-status-indicator"
                  color={
                    getStatusGroup(
                      nodes
                        ?.find((node) => node.getName() === nodeName)
                        ?.getStatus() ?? "",
                      "nodes"
                    ).backgroundColor
                  }
                  style={{ marginRight: "4px" }}
                />
              )}

              <Link
                onClick={(event) => {
                  event.stopPropagation();
                  onNodeClick();
                }}
              >
                {cellData}
              </Link>
            </div>
          ) : (
            cellData
          )}
        </CellWithIssue>
      </TooltipWrapper>
    </td>
  );
};
