import { useMutation } from "@tanstack/react-query";

import {
  PostViolationFeedbackResponse,
  postViolationFeedbackUrl,
  ViolationsApiPostViolationFeedbackRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";

export const CREATE_VIOLATION_FEEDBACK_PATH = "/api/v1/violations/:id/feedback";

export const useCreateViolationFeedback = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [CREATE_VIOLATION_FEEDBACK_PATH],
    async (
      params: ViolationsApiPostViolationFeedbackRequest
    ): Promise<PostViolationFeedbackResponse> => {
      return await apiClient.post(
        postViolationFeedbackUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.body }
      );
    }
  );
};
