import React from "react";

import { HighlightedText } from "../../../../components/common/HighlightedText";
import Selected from "../Interfaces/Selected";
import { updateSelected } from "../utils/utils";
import ExcludeCheckbox from "../../../../components/common/controls/ExceptCheckbox";
import { AnalyticEvents, dispatchEvent } from "../../../hooks/analytics";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CategoryProps, Value } from "./Category";

export const filterByTerm = (values: Value[], term: string) => {
  if (!term) return values;
  const lowerCaseTerm = term.toLowerCase();
  return values.filter((v) => v.value.toLowerCase().includes(lowerCaseTerm));
};

const getHighlightedValue = (v: Value, filterTerm: string) => (
  <>
    <HighlightedText text={v.value} term={filterTerm} /> ({v.count})
  </>
);

type ToCheckboxParams = {
  category: string;
  filterTerm: string;
  invertFilter: (name: string) => void;
  selected: Selected;
  setSelected: (newValue: Selected, replace?: boolean) => void;
} & Pick<CategoryProps, "renderCheckboxFieldName">;

export const categoryUtils =
  ({
    category,
    filterTerm,
    invertFilter,
    selected,
    setSelected,
    renderCheckboxFieldName,
  }: ToCheckboxParams) =>
  (v: Value) => {
    const onClick = () => {
      setSelected(updateSelected(selected, category, v.value));
    };
    const fieldNameRenderer = renderCheckboxFieldName ?? getHighlightedValue;

    return (
      <ExcludeCheckbox
        key={v.value}
        id={`${category}:${v.value}`}
        fieldName={fieldNameRenderer(v, filterTerm)}
        checked={v.checked || (selected?.[category]?.[v.value] ?? false)}
        handleCheckboxClick={onClick}
        handleExcludeClick={() => {
          dispatchEvent(AnalyticEvents.EventsView.Exclude_Filter_Clicked);
          invertFilter(v.value);
        }}
      />
    );
  };
