import React from "react";
import { capitalize } from "lodash";

import { useGetDescribeButtonProps } from "./hooks/useGetDescribeButtonProps";

import { DrawerTopSection } from "@/components/k8sAddons/LiveStateDrawer/components/DrawerTopSection";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { MetadataSection } from "@/components/k8sAddons/LiveStateDrawer/components/MetadataSection";
import {
  useMetadataItems,
  useTopSectionProps,
} from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useHeaderProps";
import { DrawerTabs } from "@/components/k8sAddons/LiveStateDrawer/components/DrawerTabs";
import { CertificateDetails } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/CertificateDetails";
import { CertificateYAML } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateYAML";
import {
  DrawerMainContainer,
  DrawerTopSectionLine,
} from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { InvestigationSectionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/InvestigationSectionWrapper";
import { CertificateStatusEnum } from "@/generated/addonsApi";
import { DescribeButton } from "@/components/Actions/buttons/DescribeButton";

export const CertificateDrawerContent: React.FC = () => {
  const { title, status, color } = useTopSectionProps();
  const metadataItems = useMetadataItems();
  const isUnhealthyStatus =
    status === CertificateStatusEnum.Expired ||
    status === CertificateStatusEnum.Failed;

  const { actionItem, resource } = useGetDescribeButtonProps();

  return (
    <DrawerMainContainer
      aria-label={certificatesPageAriaLabels.drawer.mainContainer}
    >
      <DrawerTopSection title={title}>
        <DrawerTopSectionLine>
          <AddonStatusChip
            color={color}
            label={capitalize(status)}
            size={"large"}
          />
          <DescribeButton Button={actionItem} resource={resource} />
        </DrawerTopSectionLine>
      </DrawerTopSection>
      <InvestigationSectionWrapper
        resourceType={"Certificate"}
        isHealthy={!isUnhealthyStatus}
      />
      <MetadataSection items={metadataItems} />
      <DrawerTabs
        tabInfo={[
          { label: "Details", value: "Details", page: <CertificateDetails /> },
          { label: "YAML", value: "YAML", page: <CertificateYAML /> },
        ]}
      />
    </DrawerMainContainer>
  );
};
