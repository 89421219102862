import { MarketplaceActivationState } from "../../hooks/marketplace/localState";

export const createMarketplaceActivationUrl = (
  activationState: MarketplaceActivationState
) => {
  const callbackUrl = new URL(activationState["x-callback-url"]);
  callbackUrl.searchParams.set(
    "connection_id",
    activationState["x-marketplace-connection-id"]
  );

  return callbackUrl.toString();
};
