import React, { useMemo } from "react";
import styled from "styled-components";

import { sectionGap } from "../../../../../../../../constants/reliabilityConstants";
import { ReliabilityCollapsibleContainer } from "../../../shared/metrics/components/ReliabilityCollapsibleContainer/ReliabilityCollapsibleContainer";
import { MetricsSummary } from "../../../shared/metrics/components/MetricsSummary/MetricsSummary";
import { MetricsGraph } from "../../../shared/metrics/components/MetricsGraph/MetricsGraph";
import { CheckType } from "../../../../../../../../../../generated/reliabilityApi";
import { getCollapsibleContainerProps } from "../../../../violationDrawerUtils";
import { reliabilityArialLabels } from "../../../../../../../../reliabilityArialLabels";
import { MetricsAggregationType } from "../../../../../../../../../Metrics/types";
import { MetricsContentContainer } from "../../../../violationDrawerStyles";
import { useGetServiceMetrics } from "../../../../hooks/useGetServiceMetrics";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

import { getCpuSummary, getMemorySummary } from "./summaryUtils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

const {
  violationDrawer: { graphsContainer: graphsContainerAriaLabel },
} = reliabilityArialLabels;

interface Props {
  komodorUid: string;
  containers: string[] | undefined;
  checkType: CheckType;
}

export const Details: React.FC<Props> = ({
  komodorUid,
  containers,
  checkType,
}) => {
  const { memoryRes, cpuRes, memorySummaryRes, cpuSummaryRes } =
    useGetServiceMetrics(komodorUid);

  const isFetchingData =
    memoryRes.loading ||
    cpuRes.loading ||
    memorySummaryRes.loading ||
    cpuSummaryRes.loading;

  useReportDrawerLoadingTime(!isFetchingData);

  const content = useMemo(
    () =>
      containers?.map((name, idx) => (
        <ReliabilityCollapsibleContainer
          {...getCollapsibleContainerProps({ name, index: idx, checkType })}
          content={
            <MetricsContentContainer>
              <MetricsSummary
                title="Memory analysis"
                res={memorySummaryRes}
                getSummaryItems={getMemorySummary}
                containerName={name}
              />
              <MetricsGraph
                komodorUid={komodorUid}
                res={memoryRes}
                type={"memory"}
                containerName={name}
                wantedAggregation={MetricsAggregationType.P99}
                graphTitle=""
              />
              <MetricsSummary
                title="CPU analysis"
                res={cpuSummaryRes}
                getSummaryItems={getCpuSummary}
                containerName={name}
              />
              <MetricsGraph
                komodorUid={komodorUid}
                res={cpuRes}
                type={"cpu"}
                containerName={name}
                wantedAggregation={MetricsAggregationType.P99}
                graphTitle=""
              />
            </MetricsContentContainer>
          }
        />
      )),
    [
      checkType,
      containers,
      cpuRes,
      cpuSummaryRes,
      komodorUid,
      memoryRes,
      memorySummaryRes,
    ]
  );

  return <Container aria-label={graphsContainerAriaLabel}>{content}</Container>;
};
