import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { MemoryVictim } from "../../../../../../../../../../../generated/reliabilityApi";
import { getMibNumber } from "../../../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";
import { formatNumber } from "../../../../../../../../../../../shared/utils/formatNumber";

const formatOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 0,
};

export const useGetAffectedNodesColumns = (): GridColDef<MemoryVictim>[] =>
  useMemo(
    () => [
      { field: "node", headerName: "Node", flex: 1 },
      {
        field: "resources",
        headerName: "# Victims",
        flex: 1,
        valueGetter: (params) => params.row.totalVictims,
      },
      {
        field: "serviceUsageBytes",
        headerName: "Noisy service  Mem usage / node capacity",
        flex: 1,
        valueGetter: (params) => {
          const { serviceUsageBytes, nodeCapacityBytes } = params.row;
          const serviceUsageMib = getMibNumber(serviceUsageBytes);
          const nodeCapacityMib = getMibNumber(nodeCapacityBytes);
          const percentage = (serviceUsageMib / nodeCapacityMib) * 100;

          const serviceUsageMibFormatted = formatNumber(
            serviceUsageMib,
            formatOptions
          );
          const nodeCapacityMibFormatted = formatNumber(
            nodeCapacityMib,
            formatOptions
          );

          const percentageFormatted = formatNumber(percentage, formatOptions);

          return `${serviceUsageMibFormatted}MiB/${nodeCapacityMibFormatted}MiB (${percentageFormatted}%)`;
        },
      },
    ],
    []
  );
