import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import { ReliabilityRoutes } from "../../../constants/reliabilityConstants";
import { PagePadding } from "../../../ReliabilityStyles";

import { AllPolicies } from "./AllPolicies";

const Container = styled.div`
  ${PagePadding};
`;

export const PoliciesPage: React.FC = () => {
  const { showReliabilityPolicies } = useOverridableFlags();
  const navigate = useNavigate();

  if (!showReliabilityPolicies) {
    navigate(ReliabilityRoutes.violations);
  }
  return (
    <Container>
      <AllPolicies />
    </Container>
  );
};
