import React, { ChangeEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import styled from "styled-components";
import { TextInput } from "@komodorio/design-system/komodor-ui";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import isCidr from "is-cidr";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { Close24 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";

import { CIDRWhitelist } from "@/generated/accounts";
import { useUpdateCIDR } from "@/shared/hooks/accounts-service/client/cidrWhitelist/useCIDR";
import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { whitelistAriaLabels } from "@/components/Settings/whitelistCIDR/whitelistAriaLabels";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";

interface CIDREditModalProps {
  open: boolean;
  cidr: CIDRWhitelist | null;
  onClose: () => void;
  refreshCIDRs: () => void;
}

const StyledContainer = styled.div`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }
`;

const CIDREditModal: React.FC<CIDREditModalProps> = ({
  open,
  cidr,
  onClose,
  refreshCIDRs,
}) => {
  const { errors, setError } = useFormValidations();
  const { mutateAsync: editCIDR } = useUpdateCIDR();
  const { accountId } = useUserMetadata();
  const defaultCidr: CIDRWhitelist = {
    id: "",
    accountId: accountId,
    cidr: "",
    description: "",
  };
  const [value, setValue] = React.useState<CIDRWhitelist>(cidr ?? defaultCidr);

  const validateCIDR = (cidr: string, index: string) => {
    setError(String(index), isCidr(cidr) ? undefined : "Your CIDR is invalid.");
  };

  const handleCIDRChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof CIDRWhitelist
  ) => {
    setValue({ ...value, [field]: event.target.value });
  };
  const handleClose = () => {
    setError("cidr", undefined);
    setValue(cidr ?? defaultCidr);
    onClose();
  };
  const handleSave = async () => {
    const payload = {
      id: value.id,
      apiV1WhitelistCidrIdPutRequest: {
        cidr: value.cidr,
        description: value.description,
      },
    };
    try {
      await editCIDR(payload);
      refreshCIDRs();
      onClose();
    } catch (error) {
      setError("cidr", "Your CIDR is invalid.");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-label={whitelistAriaLabels.modalEditCIDR}
    >
      <DialogTitle>
        <Typography variant="h4">Edit CIDR</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close24 fontSize="inherit" />
      </IconButton>
      <Divider />
      <DialogContent>
        <StyledContainer>
          <TextInput
            size="medium"
            label={"CIDR"}
            placeholder="e.g. 192.168.0.0/16"
            value={value.cidr}
            onChange={(e) => {
              handleCIDRChange(e, "cidr");
              validateCIDR(e.target.value, "cidr");
            }}
            error={!!errors["cidr"]}
            helperText={errors["cidr"]}
            fullWidth
          />
          <TextInput
            size="medium"
            label={"Description (optional)"}
            placeholder="e.g. vpn"
            value={value.description}
            onChange={(e) => handleCIDRChange(e, "description")}
            fullWidth
            helperText={errors["cidr"] ? " " : undefined}
          />
        </StyledContainer>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CIDREditModal;
