import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import { ExtendedViolation } from "../../../../../../../../../generated/reliabilityApi";

import { useHpaData, useScalingMetricsAsRecord } from "./hooks/useHpaData";
import { DetailsTitle } from "./DetailsTitle";
import { MetricsDetailsSkeleton } from "./MetricsDetailsSkeleton";
import { useMetricsDetails } from "./hooks/useMetricsDetails";
import { MetricsGraphs } from "./MetricsGraphs/MetricsGraphs";
import { useReportLoadingState } from "./hooks/useReportLoadingState";

const Container = styled.div`
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  padding: 16px;
`;

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 32px;
  gap: 32px;
  & > div {
    flex-grow: 1;
    width: 50%;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin-top: 24px;
  }
`;

type DetailsProps = {
  violation: ExtendedViolation;
};

export const Details: React.FC<DetailsProps> = ({ violation }) => {
  const { hpaUid } = violation.supportingData?.HPAMax ?? {};
  const { hpaData, loading, errorState } = useHpaData(
    hpaUid,
    violation.lastEvaluatedAt
  );

  useReportLoadingState();

  const { hpaMetrics, metricsContent } = useMetricsDetails(hpaData);

  const scalingMetricsAsRecord = useScalingMetricsAsRecord(
    hpaData?.scalingMetrics
  );

  const loaderContent = (
    <>
      <MetricsDetailsSkeleton size={"small"} />
      <br />
      <MetricsDetailsSkeleton size={"large"} />
    </>
  );

  // TODO: Add a more meaningful error state
  if (errorState) return null;

  const showMinMaxAlert =
    Number.isInteger(hpaData?.minReplicas) &&
    Number.isInteger(hpaData?.maxReplicas) &&
    hpaData?.minReplicas === hpaData?.maxReplicas;

  return (
    <Container>
      <DetailsTitle name={hpaData?.hpaName} hpaUid={hpaUid} />
      {hpaMetrics}
      {showMinMaxAlert && (
        <StyledAlert severity="warning">
          <Typography variant={"body2"}>
            Your HPA is configured with identical Min and Max values, due to
            that your deployment will not scale
          </Typography>
        </StyledAlert>
      )}
      {loading && loaderContent}
      {!!metricsContent?.length && (
        <FlexContainer>{metricsContent}</FlexContainer>
      )}

      <MetricsGraphs scalingMetricsAsRecord={scalingMetricsAsRecord} />
    </Container>
  );
};
