import React from "react";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { CheckType } from "../../../../../../../../../generated/reliabilityApi";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import {
  getHowDescription,
  getWhatDescription,
  getWhyDescription,
} from "./texts";
import { Details } from "./Details/Details";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const UnderProvisioned: React.FC = () => {
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  const { data } = useViolation();

  const { headerProps, summaryProps, supportingData, komodorUid } =
    useGetCommonDrawerProps(data?.data.violation);

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription()}
              <Details
                komodorUid={komodorUid ?? ""}
                containersData={
                  supportingData?.underProvisionedWorkloads?.containersData
                }
                checkType={CheckType.UnderProvisionedWorkloads}
              />
              {!!reliabilityBestPracticesTab && (
                <ViolationsTableByIds
                  title="Related best practice violations"
                  ids={data.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              )}
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={getHowDescription()}
        />
      }
    />
  );
};
