import React from "react";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

export const MediumBoldTypography: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Typography
    variant={"text"}
    size={"medium"}
    bold
    color={theme.foreground.fgSubtle}
  >
    {children}
  </Typography>
);
