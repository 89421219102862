import React from "react";
import Tooltip from "@mui/material/Tooltip";

import { useInstallationClusters } from "./useInstallationClusters";
import { GenericInfo, Info } from "./Info";

export const KubernetesIntegrationClusters: GenericInfo = ({
  installation,
}) => {
  const installationClusters = useInstallationClusters(installation.id);
  if (installationClusters.length === 0) {
    return null;
  }
  if (installationClusters.length === 1) {
    return <Info label="Cluster name">{installationClusters[0].name}</Info>;
  }
  const tooltipClusters = installationClusters
    .slice(1)
    .map((cluster) => <div key={cluster.name}>{cluster.name}</div>);
  return (
    <Info label={"Cluster names"}>
      <>
        {installationClusters[0].name},{" "}
        <Tooltip title={tooltipClusters} placement="top" arrow={false}>
          <span>+{installationClusters.length - 1}</span>
        </Tooltip>
      </>
    </Info>
  );
};
