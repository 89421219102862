import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import { useOverviewPageStore } from "../../store/overviewPageStore";
import { selectOpenServiceResourceDrawer } from "../../store/overviewPageStoreSelectors";

const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;
`;

const RightArrow = styled(ArrowForwardIos)`
  && {
    height: 10px;
    padding: 0 10px;
    fill: ${muiColors.gray[500]};
  }
`;

const ClickableBoldText = styled(Typography)`
  font-weight: 700;
  cursor: pointer;
`;

type ResourceLocationProps = {
  clusterName?: string;
  namespace?: string;
  resourceName: string;
  serviceId: string;
};

const TextWithArrow: React.FC<{ name: string }> = ({ name }) => {
  return (
    <FlexContainer>
      <Typography variant={"body3"} color={muiColors.gray[500]}>
        {name}
      </Typography>
      <RightArrow />
    </FlexContainer>
  );
};

export const ResourceLocation: React.FC<ResourceLocationProps> = ({
  clusterName,
  namespace,
  resourceName,
  serviceId,
}) => {
  const openServiceResourceDrawer = useOverviewPageStore(
    selectOpenServiceResourceDrawer
  );
  const onClick = () => {
    openServiceResourceDrawer(serviceId);
  };

  const content = (
    <>
      {clusterName && <TextWithArrow name={clusterName} />}
      {namespace && <TextWithArrow name={namespace} />}
      <ClickableBoldText variant={"h6"} role="button" onClick={onClick}>
        {resourceName}
      </ClickableBoldText>
    </>
  );

  return <FlexContainer>{content}</FlexContainer>;
};
