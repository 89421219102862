import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { DagView } from "@/components/k8sAddons/addons/workflows/DagView";
import { DagsListPage } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsListPage";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { WORKFLOW_ID_PARAM } from "@/components/routes/routes";
import { WorkflowsTabs } from "@/components/k8sAddons/addons/workflows/WorkflowsTabs";
import { WorkflowsRoutes } from "@/components/k8sAddons/addons/workflows/types";
import { ArgoWorkflowsRootView } from "@/components/argoWorkflowsView/ArgoWorkflowsRootView";
import ArgoWorkflowView from "@/components/argoWorkflowsView/ArgoWorkflowView";
import { useShouldShowArgoWorkflows } from "@/components/argoWorkflowsView/useShouldShowArgoWorkflows";
import {
  useShouldShowAirflow,
  useShouldShowCustomWorkflows,
} from "@/components/k8sAddons/addons/workflows/hooks/shouldShowWorkflowsHooks";
import { PageLoader } from "@/shared/components/PageLoader";
import { WorkflowsEmptyState } from "@/components/k8sAddons/addons/workflows/WorkflowsEmptyState";

export const Workflows: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const showArgoWorkflows = useShouldShowArgoWorkflows();
  const [showAirflow, isLoadingShouldShowAirflow] = useShouldShowAirflow();
  const [showCustomWorkflows, isLoadingShouldShowCustomWorkflows] =
    useShouldShowCustomWorkflows();

  const isLoadingPage =
    isLoadingShouldShowAirflow || isLoadingShouldShowCustomWorkflows;

  const tabValue = useMemo(() => {
    if (location.pathname.includes(WorkflowsRoutes.airflow)) {
      return WorkflowsRoutes.airflow;
    }
    if (location.pathname.includes(WorkflowsRoutes.argo)) {
      return WorkflowsRoutes.argo;
    }
    return WorkflowsRoutes.custom;
  }, [location.pathname]);

  const defaultRedirectUrl = showAirflow
    ? WorkflowsRoutes.airflow
    : showArgoWorkflows
    ? WorkflowsRoutes.argo
    : WorkflowsRoutes.custom;

  useEffect(() => {
    if (
      !isLoadingPage &&
      ((tabValue === WorkflowsRoutes.airflow && !showAirflow) ||
        (tabValue === WorkflowsRoutes.argo && !showArgoWorkflows))
    ) {
      navigate(defaultRedirectUrl);
    }
  }, [
    defaultRedirectUrl,
    isLoadingPage,
    navigate,
    showAirflow,
    showArgoWorkflows,
    tabValue,
  ]);

  const tabsProps = useMemo(
    () => ({
      tabValue,
      engines: {
        airflow: showAirflow ?? false,
        argo: showArgoWorkflows,
        custom: true,
      },
    }),
    [showAirflow, showArgoWorkflows, tabValue]
  );

  if (isLoadingPage) {
    return <PageLoader />;
  }

  return (
    <FiltersContextProvider
      urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
      searchTypeFilterOperator={Operator.Like}
    >
      <Routes>
        <Route
          path={WorkflowsRoutes.airflow}
          element={
            <WorkflowsTabs {...tabsProps}>
              <DagsListPage />
            </WorkflowsTabs>
          }
        />
        <Route
          path={WorkflowsRoutes.argo}
          element={
            <WorkflowsTabs {...tabsProps}>
              <ArgoWorkflowsRootView />
            </WorkflowsTabs>
          }
        />
        <Route
          path={WorkflowsRoutes.custom}
          element={
            <WorkflowsTabs {...tabsProps}>
              {showCustomWorkflows ? (
                <DagsListPage isCustomEngines />
              ) : (
                <WorkflowsEmptyState />
              )}
            </WorkflowsTabs>
          }
        />
        <Route
          path={`${WorkflowsRoutes.airflow}/:${WORKFLOW_ID_PARAM}`}
          element={<DagView />}
        />
        <Route
          path={`${WorkflowsRoutes.argo}/:${WORKFLOW_ID_PARAM}`}
          element={<ArgoWorkflowView />}
        />
        <Route
          path={`${WorkflowsRoutes.custom}/:${WORKFLOW_ID_PARAM}`}
          element={<DagView />}
        />
        <Route
          path="*"
          element={<Navigate to={defaultRedirectUrl} replace />}
        />
      </Routes>
    </FiltersContextProvider>
  );
};
