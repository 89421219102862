import { useMemo } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { capitalize } from "lodash";
import Skeleton from "@mui/material/Skeleton";

import { Certificate } from "@/generated/addonsApi";
import { TimeElapsedCell } from "@/components/k8sAddons/components/table/TimeElapsedCell/TimeElapsedCell";
import { AgeCell } from "@/components/k8sAddons/components/table/AgeCell/AgeCell";
import { getTypographySchemeByTimeElapsed } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/utils/certificatePageUtils";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { getRandRange } from "@/shared/utils/getRandRange";
import { useDisableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";

export const useColumnsConfig = () =>
  useDisableLocalSortingInTableColumns<Certificate>({
    name: {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    clusterName: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    issuerName: {
      field: "issuerName",
      headerName: "Issuer",
      flex: 1,
    },
    age: {
      field: "age",
      headerName: "Age",
      maxWidth: 100,
    },
    expiration: {
      field: "expiration",
      headerName: "Expiration time",
      minWidth: 130,
    },
    status: {
      field: "status",
      headerName: "Status",
      width: 130,
    },
  });

export const useCertificateTableColumns = (): GridColDef<Certificate>[] => {
  const columnsConfig = useColumnsConfig();
  return useMemo(() => {
    return [
      { ...columnsConfig.name },
      { ...columnsConfig.clusterName },
      { ...columnsConfig.namespace },
      { ...columnsConfig.issuerName },
      {
        ...columnsConfig.age,
        renderCell: (params) => {
          return <AgeCell age={params.row.age} includeDateUnits={["y", "d"]} />;
        },
      },
      {
        ...columnsConfig.expiration,
        renderCell: (params) => {
          return (
            <TimeElapsedCell
              targetDateString={params.value}
              getTypographyScheme={getTypographySchemeByTimeElapsed}
            />
          );
        },
      },
      {
        ...columnsConfig.status,
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <AddonStatusChip
              color={certificateStatusToColor[status]}
              label={capitalize(params.value)}
            />
          );
        },
      },
    ];
  }, [
    columnsConfig.age,
    columnsConfig.clusterName,
    columnsConfig.expiration,
    columnsConfig.issuerName,
    columnsConfig.name,
    columnsConfig.namespace,
    columnsConfig.status,
  ]);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof Certificate, number[]>> = {
  name: [getRandRange(50, 150), getRandRange(50, 150), getRandRange(50, 150)],
  clusterName: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  namespace: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  issuerName: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  age: [getRandRange(40, 60), getRandRange(40, 60), getRandRange(40, 60)],
  expiration: [
    getRandRange(50, 100),
    getRandRange(50, 100),
    getRandRange(50, 100),
  ],
  status: [getRandRange(50, 100), getRandRange(50, 100), getRandRange(50, 100)],
};

const getColumnRandWidth = (id: GridRowId, columnName: keyof Certificate) => {
  const idAsNumber = parseInt(id.toString());
  const column = randRangeColumns[columnName];
  if (!column) return 0;
  return column[idAsNumber % column.length];
};

export const useCertificateLoadingColumns = (): GridColDef<Certificate>[] => {
  const columnsConfig = useColumnsConfig();
  return useMemo(
    () => [
      {
        ...columnsConfig.name,
        renderCell: ({ id }) => {
          return (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "name")}
              height={21}
            />
          );
        },
      },
      {
        ...columnsConfig.clusterName,
        renderCell: ({ id }) => {
          return (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "clusterName")}
              height={21}
            />
          );
        },
      },
      {
        ...columnsConfig.namespace,
        renderCell: ({ id }) => (
          <Skeleton
            variant={"rounded"}
            width={getColumnRandWidth(id, "namespace")}
            height={21}
          />
        ),
      },
      {
        ...columnsConfig.issuerName,
        renderCell: ({ id }) => (
          <Skeleton
            variant={"rounded"}
            width={getColumnRandWidth(id, "issuerName")}
            height={21}
          />
        ),
      },
      {
        ...columnsConfig.age,
        renderCell: ({ id }) => (
          <Skeleton
            variant={"rounded"}
            width={getColumnRandWidth(id, "age")}
            height={21}
          />
        ),
      },
      {
        ...columnsConfig.expiration,
        renderCell: ({ id }) => (
          <Skeleton
            variant={"rounded"}
            width={getColumnRandWidth(id, "expiration")}
            height={21}
          />
        ),
      },
      {
        ...columnsConfig.status,
        renderCell: ({ id }) => (
          <Skeleton
            variant={"rounded"}
            width={getColumnRandWidth(id, "status")}
            height={20}
            sx={{ borderRadius: "16px" }}
          />
        ),
      },
    ],
    [
      columnsConfig.age,
      columnsConfig.clusterName,
      columnsConfig.expiration,
      columnsConfig.issuerName,
      columnsConfig.name,
      columnsConfig.namespace,
      columnsConfig.status,
    ]
  );
};
