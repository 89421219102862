import React from "react";
import styled from "styled-components";

import { StyledPage } from "../../styles/CostOptimizationStyledComponents";

import tableImg from "./RiskMock/table.svg";
import table1Img from "./RiskMock/table-1.svg";
import frame1 from "./RiskMock/frame1.svg";
import frame2 from "./RiskMock/frame2.svg";
import frame3 from "./RiskMock/frame3.svg";
import frame4 from "./RiskMock/frame4.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-right: 140px;
  padding-left: 140px;
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
  cursor: pointer;
`;

const Frame = styled.img`
  width: 25%;
  max-width: 100%;
`;

const TableImage = styled.img`
  width: 100%;
  margin-left: 10px;
`;

export const RiskPageMock: React.FC = () => {
  return (
    <StyledPage>
      <Container>
        <RowContainer>
          <Frame src={frame1} alt="frame1" />
          <Frame src={frame2} alt="frame2" />
          <Frame src={frame3} alt="frame3" />
          <Frame src={frame4} alt="frame4" />
        </RowContainer>
        <TableImage src={tableImg} alt="table" />
        <TableImage src={table1Img} alt="table1" />
      </Container>
    </StyledPage>
  );
};
