import { useGetPolicyData } from "@/shared/hooks/reliability-api/policies/usePolicies";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";

export const useGetFetchedPolicyData = () => {
  const { policyId } = usePolicyDrawerContext();
  return useGetPolicyData(
    {
      id: policyId ?? "",
    },
    { enabled: !!policyId }
  );
};
