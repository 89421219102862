import styled from "styled-components";

import { blueForUI, blueForUIText } from "../../../Colors";

export const BlueLabel = styled.span`
  align-items: center;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 0.3rem 0.5rem;
  white-space: nowrap;

  max-width: fit-content;

  color: ${blueForUIText};
  background: ${blueForUI};
  svg {
    margin-right: 0.3rem;
    height: 1em;
  }
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default BlueLabel;

export const BlackLabel = styled(BlueLabel)`
  display: flex;
  color: black;
`;
