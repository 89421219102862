import { useMemo } from "react";

import { PolicyListItem } from "../../../../../../../generated/reliabilityApi";
import { orderPoliciesByPriority } from "../policiesTableUtils";

export const useSortedPolicies = (policies: PolicyListItem[] | undefined) => {
  return useMemo(() => {
    if (!policies) return undefined;
    return orderPoliciesByPriority(policies);
  }, [policies]);
};
