import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  apiV1HelmReposGetUrl,
  HelmRepo,
  HelmReposApiApiV1HelmReposGetRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const HELM_REPOS_PATH = "/helm-repos";

const getHelmRepos = async (
  apiClient: AxiosInstance,
  params: HelmReposApiApiV1HelmReposGetRequest
): Promise<HelmRepo[]> => {
  const { data } = await apiClient.get(
    apiV1HelmReposGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetHelmRepos = (
  params: HelmReposApiApiV1HelmReposGetRequest
) => {
  const apiClient = useResourcesApiClient();
  const queryResult = useQuery([HELM_REPOS_PATH, params], () =>
    getHelmRepos(apiClient, params)
  );
  return {
    data: queryResult.data,
    isFetching: queryResult.isFetching,
    isLoading: queryResult.isLoading,
    error: queryResult.error,
    refresh: queryResult.refetch,
  };
};
