import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { VictimInstances } from "../../../../../../../../../../../../../generated/reliabilityApi";

import { useGetWorkloadsTableColumns } from "./useGetWorkloadsTableColumns";

interface Props {
  data: VictimInstances[];
}

export const WorkloadsTable: React.FC<Props> = ({ data }) => {
  return (
    <DataGridPro
      columns={useGetWorkloadsTableColumns()}
      rows={data}
      getRowId={(row) => row.komodorUid}
      hideFooter
    />
  );
};
