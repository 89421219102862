import { noop } from "lodash";

import { sleep } from "./sleep";

interface Args<T> {
  func: () => Promise<T>;
  retries: number;
  exponentialBackoff?: boolean;
}

const SECOND = 1000;

export const retry = async <T>({
  func,
  retries,
  exponentialBackoff = false,
}: Args<T>): Promise<T> => {
  let attempts = 1;

  while (attempts <= retries) {
    try {
      const res = await func();
      return res;
    } catch (error) {
      attempts++;
      if (exponentialBackoff) {
        await sleep(Math.pow(2, attempts) * SECOND).catch(noop);
      }
    }
  }

  throw new Error("The number of attempts reached");
};
