import React from "react";
import Box from "@mui/material/Box";
import { muiTheme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { IconProps } from "@komodorio/design-system/icons";

interface AgentDrawerSectionHeaderProps {
  Icon: React.FC<IconProps>;
  title: string;
}

export const AgentDrawerSectionHeader = ({
  Icon,
  title,
}: AgentDrawerSectionHeaderProps) => (
  <Stack direction="row" spacing={1} alignItems="center" columnGap={1}>
    <Box
      sx={{
        padding: "8px",
        backgroundColor: muiTheme.palette.grey[100],
        borderRadius: "50%",
        display: "flex",
        width: "fit-content",
      }}
      alignItems="center"
    >
      <Icon color={muiTheme.palette.primary.main} />
    </Box>
    <Typography variant="h5">{title}</Typography>
  </Stack>
);
