import React, { useMemo } from "react";
import styled from "styled-components";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { muiColors } from "@komodorio/design-system";

import { LiveStateDrawerStateInUrl } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerTypes";
import { LIVE_STATE_DRAWER_URL_STATE } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerConstants";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledTabList = styled(TabList)`
  && {
    border-bottom: 1px solid ${muiColors.gray[200]};
    padding: 0 16px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  && {
    padding: 0;
    flex-grow: 1;
  }
`;

type DrawerTabInfo = {
  label: string;
  value: string;
  page: React.ReactNode;
};

type DrawerTabsProps = {
  tabInfo: DrawerTabInfo[];
  className?: string;
};

export const DrawerTabs: React.FC<DrawerTabsProps> = ({
  tabInfo,
  className,
}) => {
  const [drawerStateInUrl, setDrawerStateInUrl] =
    useDrawerUrlState<LiveStateDrawerStateInUrl>(LIVE_STATE_DRAWER_URL_STATE);

  const tabs = useMemo(() => {
    return tabInfo.map((tab) => (
      <Tab key={tab.value} label={tab.label} value={tab.value} />
    ));
  }, [tabInfo]);

  const tabPanels = useMemo(() => {
    return tabInfo.map((tab) => (
      <StyledTabPanel key={tab.value} value={tab.value}>
        {tab.page}
      </StyledTabPanel>
    ));
  }, [tabInfo]);

  const currentTab = drawerStateInUrl?.currentTab ?? tabInfo[0].value;

  return (
    <Container className={className}>
      <TabContext value={currentTab}>
        <StyledTabList
          onChange={(event, newValue) => {
            setDrawerStateInUrl({
              currentTab: newValue,
            });
          }}
        >
          {tabs}
        </StyledTabList>
        {tabPanels}
      </TabContext>
    </Container>
  );
};
