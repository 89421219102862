import React from "react";
import styled from "styled-components";

import { useOpenReliabilityDrawer } from "../ReliabilitySection/reliabilitySectionHooks";

import { CheckTypeStatBox } from "./CheckTypeStatBox";
import { StatsSection } from "./StatsSection";
import { useReliabilityRisks } from "./hooks";
import { getViolationCounts } from "./utils";

import { CheckCategory } from "@/generated/reliabilityApi";
import { ViolationsCell } from "@/components/ClustersView/components/ViolationsCell";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

const StyledViolationsCell = styled(ViolationsCell)`
  padding-top: 6px;
  padding-bottom: 5px;
`;

export type ReliablityRisksSectionProps = {
  checkCategory: CheckCategory;
  clusters?: string[];
};

export const ReliablityRisksSection: React.FC<ReliablityRisksSectionProps> = ({
  checkCategory,
  clusters,
}) => {
  const { groups, isEmpty, isLoading, error } = useReliabilityRisks(
    checkCategory,
    clusters
  );

  const openReliabilityDrawer = useOpenReliabilityDrawer();

  if (error || isEmpty) {
    return null;
  }

  return (
    <StatsSection title="Reliability Risks">
      {groups.map(({ label, group, impactGroupId }) => {
        const violations = group ? getViolationCounts(group) : null;
        return (
          <CheckTypeStatBox
            key={label}
            ariaLabel={
              checkCategory === CheckCategory.Workload
                ? AriaLabels.ClusterOverview.HealthSection.Workloads
                    .ReliabilityRisksTile
                : AriaLabels.ClusterOverview.HealthSection.Infra
                    .ReliabilityRisksTile
            }
            isLoading={isLoading}
            title={label}
            value={
              violations && violations.count > 0 ? (
                <StyledViolationsCell violations={violations} />
              ) : null
            }
            onClick={
              group
                ? () => {
                    dispatchEvent(
                      AnalyticEvents.ClusterOverview.HealthSection.TileClicked,
                      { label, category: checkCategory }
                    );
                    openReliabilityDrawer({
                      impactGroupId,
                      clusters,
                      checkCategory: [checkCategory],
                    });
                  }
                : undefined
            }
          />
        );
      })}
    </StatsSection>
  );
};
