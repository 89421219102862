import { useEffect } from "react";

type Props = {
  fetchedIsPaginating: boolean | undefined;
  setIsPaginating: (isPaginating: boolean) => void;
};

export const useSetIsPaginating = ({
  fetchedIsPaginating,
  setIsPaginating,
}: Props) => {
  useEffect(() => {
    if (fetchedIsPaginating !== undefined) {
      setIsPaginating(fetchedIsPaginating);
    }
  }, [fetchedIsPaginating, setIsPaginating]);
};
