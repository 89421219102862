import { TimeStampDataPoint } from "../../../types/overviewPageTypes";

import { getMiddleIndex } from "./utils";

export type DateFormat = "abbreviated" | "full";
type FormattedDatesFromTimestampsReturnParams = {
  firstDuration?: string;
  secondDuration?: string;
  totalDuration?: string;
};

export const getFormattedDurationsFromTimestamps = (
  tsDataPoints: TimeStampDataPoint[] = [],
  dateFormat: DateFormat = "abbreviated"
): FormattedDatesFromTimestampsReturnParams | undefined => {
  if (!tsDataPoints || tsDataPoints.length <= 1) return;

  const firstDuration = getMiddleIndex(tsDataPoints);
  const secondDuration = tsDataPoints.length - firstDuration;
  const totalDuration = tsDataPoints.length;

  // this assumes bucketTimeFrame is 24h
  const durationFormat = dateFormat === "abbreviated" ? "d" : " days";
  return {
    firstDuration: `${firstDuration}${durationFormat}`,
    secondDuration: `${secondDuration}${durationFormat}`,
    totalDuration: `${totalDuration}${durationFormat}`,
  };
};

export const getGraphTimeStamps = (tsDataPoints?: TimeStampDataPoint[]) => {
  if (!tsDataPoints?.length) return [];
  return [tsDataPoints[0].ts, tsDataPoints[tsDataPoints.length - 1].ts];
};
