import { useEffect } from "react";
import { isEqual } from "lodash";

import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../generated/metricsApi";
import { ContainersValues } from "../types";
import { getRecommendationByStrategy } from "../utils/getRecommendationsByStrategy";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";

interface Props {
  containers: ContainersValues[];
  strategy: OptimizationStrategy;
  containersRecommendations?: RightSizingCostSummaryByService[];
}

export const addRecommendationsValue = ({
  containers,
  containersRecommendations,
  strategy,
}: Props): ContainersValues[] => {
  if (!containersRecommendations) return containers;

  return containers.map((container) => {
    const recommendation = containersRecommendations.find(
      (recommendation) => recommendation.containerName === container.name
    );

    if (!recommendation) return container;

    const { cpuRecommendation, memoryRecommendation } =
      getRecommendationByStrategy(recommendation, strategy);

    const currentCpu = container.resources.requests?.cpu;
    const currentMemory = container.resources.requests?.memory;

    return {
      ...container,
      resources: {
        ...container.resources,
        requests: {
          cpu:
            !currentCpu || cpuRecommendation < currentCpu
              ? cpuRecommendation
              : currentCpu,
          memory:
            !currentMemory || memoryRecommendation < currentMemory
              ? memoryRecommendation
              : currentMemory,
        },
      },
    };
  });
};

export const useAddRecommendations = (props: Omit<Props, "containers">) => {
  const { originalContainers, setContainers } =
    useRecommendationsModalContext();
  useEffect(() => {
    const containersWithRecommendations = addRecommendationsValue({
      containers: originalContainers,
      strategy: props.strategy,
      containersRecommendations: props.containersRecommendations,
    });

    if (!isEqual(containersWithRecommendations, originalContainers)) {
      setContainers(containersWithRecommendations);
    }
  }, [
    props.strategy,
    props.containersRecommendations,
    originalContainers,
    setContainers,
  ]);
};
