import { useCallback } from "react";

export const useGetTagValueToDisplay = (): ((
  originalTagValue: string,
  wantedHighlightString: string,
  labelLength: number,
  maxLength: number
) => string) => {
  return useCallback(
    (
      originalTagValue: string,
      wantedHighlightString: string,
      labelLength: number,
      maxLength: number
    ): string => {
      const limitTagValueLength = maxLength - labelLength;
      if (originalTagValue.length <= limitTagValueLength)
        return originalTagValue;

      const firstCharPositionInTagValue = originalTagValue.indexOf(
        wantedHighlightString
      );

      if (firstCharPositionInTagValue === 0)
        return `${originalTagValue.substring(0, limitTagValueLength)}...`;

      if (wantedHighlightString.length > limitTagValueLength)
        return `...${originalTagValue.substring(
          firstCharPositionInTagValue,
          firstCharPositionInTagValue + limitTagValueLength
        )}...`;

      const lastCharPositionInTagValue =
        firstCharPositionInTagValue + wantedHighlightString.length - 1;

      if (lastCharPositionInTagValue === originalTagValue.length - 1)
        return `...${originalTagValue.substring(
          originalTagValue.length - 1 - limitTagValueLength,
          originalTagValue.length
        )}`;

      const baseTagValueString = originalTagValue.substring(
        firstCharPositionInTagValue,
        firstCharPositionInTagValue + limitTagValueLength
      );

      let newTagValueString = baseTagValueString;

      if (baseTagValueString.length <= limitTagValueLength) {
        newTagValueString = `...
          ${originalTagValue.substring(
            firstCharPositionInTagValue -
              (limitTagValueLength - newTagValueString.length),
            firstCharPositionInTagValue + limitTagValueLength
          )}`;
      }

      if (!originalTagValue.endsWith(baseTagValueString))
        newTagValueString = `${newTagValueString}...`;

      return newTagValueString;
    },
    []
  );
};
