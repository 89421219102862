import React, { useMemo } from "react";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
  MuiSelectionProps,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import { cloneDeep, get, set } from "lodash";

import { useIsEmailDigestFormDisabled } from "./useIsEmailDigestFormDisabled";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";

type ContentSelectProps = {
  options: MuiSelectionOption<MuiSelectionOptionValue>[];
  contextKey: string;
  selectProps?: MuiSelectionProps;
};

export const SelectController: React.FC<ContentSelectProps> = ({
  contextKey,
  options,
  selectProps,
}) => {
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const value: string = get(context, contextKey) ?? "";

  const valueInOptions = useMemo(
    () => options.find((option) => option.value === value) || options[0],
    [options, value]
  );

  const onSelectChange = (
    value?: MuiSelectionOption<MuiSelectionOptionValue>
  ) => {
    if (!value) return;
    const newData = set(cloneDeep(context), contextKey, value.value);
    setConfigurationData(newData);
  };

  const isEmailDigestFormDisabled = useIsEmailDigestFormDisabled();

  return (
    <SingleSelect
      value={valueInOptions}
      options={options}
      onChange={onSelectChange}
      disabled={isEmailDigestFormDisabled}
      {...selectProps}
    />
  );
};
