import queryString from "query-string";
import { isEqual } from "lodash";

import { AllocationFiltersGroupBy } from "../types/costOptimizationTypes";
import { ApiV1CostAllocationOverTimeGetScopeParameter } from "../../../generated/metricsApi";
import { CommonApiResponse } from "../../../shared/types/commonApiResponse";

export type CostFiltersRequestParams = {
  fromEpoch?: number;
  toEpoch?: number;
  groupBy?: AllocationFiltersGroupBy;
  scope?: ApiV1CostAllocationOverTimeGetScopeParameter;
};

type Param = string | number | object | string[] | null | undefined;

export const areRequestParamsEqualToCurrentFilters = (
  res: CommonApiResponse<object>,
  filters: CostFiltersRequestParams
) => {
  const parsedRequestParams = queryString.parseUrl(res.dataRequestParams).query;

  if (!res.dataRequestParams) return true;

  return Object.entries(filters).every(([key, value]) => {
    if (typeof value === "object") {
      return Object.keys(value).every((nestedKey) => {
        const nestedValue = value[nestedKey as keyof typeof value];
        const nestedReqParam = parsedRequestParams[`${key}.${nestedKey}`];
        return compareValues(nestedValue, nestedReqParam);
      });
    }
    return compareValues(value, parsedRequestParams[key]);
  });
};

const compareValues = (value: Param, requestParam: Param) => {
  if (typeof value === "number") {
    return value.toString() === requestParam;
  }
  if (Array.isArray(value)) {
    // array with one value is sent as a string in request params
    if (value.length === 1) return value[0] === requestParam;
    return isEqual(value, requestParam);
  }
  return value === requestParam;
};
