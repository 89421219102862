import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

import icpGenerator from "../../assets/Screen - WF - icp-map-generator.png";
import drawerWfFailResources from "../../assets/Drawer - WF - fail-resources.png";
import {
  ClickableArea,
  CloseButtonArea,
  RelativeContainer,
} from "../../styles";
import { TopBar } from "../TopBar";

const BackButtonArea = styled(ClickableArea)`
  width: 3%;
  height: 4%;
  top: 8.3%;
  left: 1.6%;
  border-radius: 4px;
`;

export const IcpGeneratorPage: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);

  return (
    <RelativeContainer>
      <TopBar page="workflows" />
      <img src={icpGenerator} alt={"lane extractor page"} />
      <Issue1Button onClick={() => setDrawerImgSrc(drawerWfFailResources)} />
      <Issue2Button onClick={() => setDrawerImgSrc(drawerWfFailResources)} />
      <BackButtonArea onClick={() => navigate(`../`, { relative: "path" })} />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            onClick={() => setDrawerImgSrc(null)}
            top="16px"
            left="24px"
          />
        }
      />
    </RelativeContainer>
  );
};

const Issue1Button = styled(ClickableArea)`
  width: 20.7%;
  height: 2.7%;
  top: 51%;
  left: 45.2%;
  border-radius: 4px;
`;
const Issue2Button = styled(ClickableArea)`
  width: 20%;
  height: 2.7%;
  top: 56.8%;
  left: 45.2%;
  border-radius: 4px;
`;

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

type MockDrawerProps = {
  open: boolean;
  onClose: () => void;
  imgSrc: string | null;
  children?: React.ReactNode;
  closeButton?: React.ReactNode;
};
export const MockDrawer: React.FC<MockDrawerProps> = ({
  open,
  onClose,
  imgSrc,
  children,
  closeButton,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      {imgSrc && <img src={imgSrc} width={907} />}
      {closeButton}
      {children}
    </Drawer>
  );
};
