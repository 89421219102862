import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { useRestartReasonsBreakdownColumns } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ContainerRestarts/containerRestartsHooks";
import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ContainerRestarts/ContainerRestartsStyles";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const containerAriaLabel =
  reliabilityArialLabels.violationDrawer.containerRestarts
    .restartReasonsBreakdown;

export const RestartReasonsBreakdown: React.FC = () => {
  const { data } = useViolation();
  const { supportingData } = useGetCommonDrawerProps(data?.data.violation);

  const { restartReasons } = supportingData?.restartingContainers ?? {};

  const columns = useRestartReasonsBreakdownColumns();
  const rows = restartReasons?.breakdown ?? [];

  if (!restartReasons || restartReasons.breakdown.length === 0) return null;

  return (
    <SectionContainer aria-label={containerAriaLabel}>
      <Typography variant="h4">Restart reasons breakdown</Typography>
      <DataGridPro
        rows={rows}
        columns={columns}
        getRowId={(row) => `${row.message}-${row.percent}`}
        hideFooter
      />
    </SectionContainer>
  );
};
