import React from "react";
import { get } from "lodash";
import { InfoCircleOutlined24, Team24 } from "@komodorio/design-system/icons";

import k8sIcon from "../../../integrations/management/logos/kubernetes.svg";
import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import eventsIcon from "../../ProcessList/assets/events.svg";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import srcCodeIcon from "./assets/srcCode.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import { DeployDiff } from "./eventUtils";
import { CommandText, usePreparedEvents } from "./common";

interface ScaleEventDetailsProps {
  eventGroup: ActionEventGroup;
}

export const ScaleEventDetails: React.FC<ScaleEventDetailsProps> = ({
  eventGroup,
}) => {
  const {
    events,
    cluster,
    namespace,
    resourceType,
    resourceName,
    metadata,
    email,
    actionResult,
  } = eventGroup;

  const preparedEvents = usePreparedEvents(events);

  const title = `Scaled ${
    get(metadata, "data.currValue") < get(metadata, "data.newValue")
      ? "up"
      : "down"
  } ${resourceType} ${resourceName} ${get(metadata, "data.currValue")} to ${get(
    metadata,
    "data.newValue"
  )}`;

  const command = `kubectl --namespace ${namespace} scale --replicas=${get(
    metadata,
    "data.newValue"
  )} ${resourceType} ${resourceName}`;

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title} />
      <EventDetailsSection
        icon={srcCodeIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Command"
      >
        <CommandText>{command}</CommandText>
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={k8sIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Kubernetes"
      >
        <DeployDiff
          namespace={namespace}
          clusterName={cluster}
          deploymentName={resourceName}
          eventTimeRangeStart={events[0].eventTime.toISOString()}
        />
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
