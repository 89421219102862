export type PodLogLine = {
  logTime: string;
  message?: string | null;
  isEvent?: boolean;
};

export interface PodLogsInfoResponseProps {
  retries?: number;
  failureMessage: string;
  refreshCallback?: () => void;
  requestMoreLogs?: () => void;
  tail: number;
  logs: PodLogLine[];
  fetching: boolean;
  mode: PodLogsMode;
}

export interface PodLogsInfoRequestProps {
  podName: string;
  agentId?: string | null;
  namespace: string;
  clusterName: string;
  controlledBy?: string;
  selectedContainer: { label: string; value: string } | undefined;
  isPreviousLogs?: boolean;
  shouldExecuteFetchLogs?: boolean;
}

export enum PodLogsMode {
  Live = "live",
  ByDemand = "by-demand",
}
