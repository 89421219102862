import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import { useResourcesApiClient } from "../../apiClient";

import {
  EventsApiApiV1WorkloadsJobsEventsIdGetRequest,
  JobEventsResponse,
  apiV1WorkloadsJobsEventsIdGetUrl,
} from "@/generated/resourcesApi";

const WORKLOADS_JOBS_EVENTS_ID = "/workloads/jobs/events/:id";

const fetchJobEventById = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsJobsEventsIdGetRequest
): Promise<JobEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsJobsEventsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useJobEventById = (
  params: EventsApiApiV1WorkloadsJobsEventsIdGetRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_JOBS_EVENTS_ID, params],
    () => fetchJobEventById(apiClient, params),
    options
  );
};

export const useJobEventsByIds = (
  params: EventsApiApiV1WorkloadsJobsEventsIdGetRequest[],
  options?: { enabled?: boolean }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_JOBS_EVENTS_ID, params],
    () =>
      Promise.all(params.map((param) => fetchJobEventById(apiClient, param))),
    options
  );
};
