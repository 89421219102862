import { useMemo } from "react";

import { useAllActiveAgentInfos } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportSecretRedaction } from "../../../../shared/utils/agent/agent";

export const useAgentSupportSecretRedaction = (clusterName: string) => {
  const agents = useAllActiveAgentInfos();

  const isAgentSupportSecretRedaction = useMemo(() => {
    const agentInfo = agents?.find(
      (agent) => agent.clusterName === clusterName
    );

    return !!(agentInfo && doesAgentVersionSupportSecretRedaction(agentInfo));
  }, [agents, clusterName]);

  return agents && isAgentSupportSecretRedaction;
};
