import { useCreationPageContext } from "../../../../context/CreationPageContext";
import { AppErrors } from "../../../../types/creationPageTypes";

export const useMissingServiceIdentifierDetection = (
  ...valuesExist: boolean[]
): boolean[] => {
  const {
    errorState: { servicesSelectedError },
  } = useCreationPageContext();

  if (servicesSelectedError === AppErrors.NoServiceIdentifiersSelected) {
    return valuesExist.map((valueExists) => !valueExists);
  }

  return valuesExist.map(() => false);
};
