export const DEMO_ACCOUNT_ID = "fe52cf0b-42da-4ae1-a8db-776e0f1335a0";

export const contactMessage =
  "Please contact your account manager at Komodor for assistance.";

export const PAGER_DUTY_CLIENT_ID =
  "dc3a9e2db2b6ab0c5887d1fe1c033d4a0182889dd4e0fde473af35d957b69a3c";

export const APP_BAR_HEIGHT_IN_REM = 3.2;
export const newAppBarWidth = 15;

export const K8S_INTEGRATION_MODAL_INITIAL_OPEN =
  "@komodor/kubernetesIntegrationModalInitialOpen";
