import React from "react";
import { Wrench16, Gauge16 } from "@komodorio/design-system/icons";
import MonetizationOnOutlined from "@mui/icons-material/MonetizationOnOutlined";

import { AppInsightsApiData } from "../types/overviewPageTypes";

export const BODY_SCROLLBAR_WIDTH = 15;
export const INSIGHTS_DRAWER_TRANSITION_DURATION = 300;
export const INSIGHTS_DRAWER_EASING_FUNCTION = "ease-in-out";
export const MIN_INSIGHTS_DRAWER_WIDTH = 760;
export const MAX_INSIGHTS_DRAWER_WIDTH = 1400;
export const MAX_INSIGHTS_DRAWER_CONTENT_WIDTH = 720;
export const INSIGHTS_DRAWER_PADDING = 30;
export const TOTAL_THROTTLED_TIME_THRESHOLD = 1;

export const INSIGHT_CARD_ID = "insight-card";

export type CardTitle = {
  title: string;
  icon: React.FC;
};

export const bestPracticesTitle: CardTitle = {
  title: "Best Practices",
  icon: Wrench16,
};

export const availabilityTitle: CardTitle = {
  title: "Availability",
  icon: Gauge16,
};

const costsTitle: CardTitle = {
  title: "Costs",
  icon: MonetizationOnOutlined, //temp icon
};

export const insightCardTitles: Record<keyof AppInsightsApiData, CardTitle> = {
  bestPracticesLivenessProbesMissing: bestPracticesTitle,
  bestPracticesMissingRequestsOrLimits: bestPracticesTitle,
  securityBestPractices: bestPracticesTitle,
  availabilityIssues: availabilityTitle,
  failedDeployments: availabilityTitle,
  throttledApplications: availabilityTitle,
  potentialCostSavings: costsTitle,
};
