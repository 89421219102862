import React, { useCallback, useMemo, useState } from "react";
import { ComposedChart, Tooltip, XAxis, YAxis } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import TablePagination from "@mui/material/TablePagination";
import styled from "styled-components";

import { EventsChartParams } from "../types";
import { getTickComponent } from "../ticks";
import useZoom from "../useZoom";
import useTicks from "../ticks/useTicks";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { ZIndex } from "../../../../constants/zIndex";

import createReferenceLines from "./createReferenceLines";
import { EventsChartTooltip } from "./EventTooltip";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useEventsOnLine } from "./useEventsOnLine";
import { useSwimlanesPagination } from "./useSwimlanesPagination";
import { useDynamicChartHeight } from "./useDynamicChartHeight";

import { useTimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";
import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";

export const NUM_OF_TICKS = 10;
const chartMargin = { top: 10, right: 0, bottom: 7, left: 0 };

const Container = styled.div<{ height?: string; zIndex?: number }>`
  position: relative;
  z-index: ${({ zIndex }) => zIndex ?? ZIndex.TimelineChart};
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: ${({ height }) => height ?? "100%"};

  width: 100%;
  display: grid;
  grid-template-rows: auto max-content;

  .recharts-tooltip-wrapper {
    z-index: 1;
  }
`;

const Chart = styled.div`
  height: 100%;
  width: 100%;
`;

const Footer = styled.div`
  background-color: white;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
`;

interface TimelineChartProps extends Omit<EventsChartParams, "eventGroups"> {
  swimlanes: LineGroup[];
}

export const TimelineChart: React.FC<TimelineChartProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  swimlanes,
  timeWindow: { start, end },
  setTimeWindow,
  setPageDefaultTimeWindow,
  setIsZoomChanged,
  highlightedId,
  removeSwimlane,
  removableSwimlaneLabels,
  xAxisPosition = "bottom",
  zIndex,
  hideXAxis = false,
  paginateSwimlanes = false,
}) => {
  const [chartWidth, setChartWidth] = useState(0);

  const [hoveredLabel, setHoveredLabel] = useState<string | undefined>("");
  const handleHoveredLabelChange = useCallback(
    (label: string | undefined) => {
      if (!label || removableSwimlaneLabels?.includes(label)) {
        setHoveredLabel(label);
      }
    },
    [removableSwimlaneLabels]
  );

  const ticks = useTicks(start, end, NUM_OF_TICKS);
  const tickPadding = (end.getTime() - start.getTime()) / NUM_OF_TICKS / 4;

  const chartConfig = useTimelineChartConfig({
    numOfSwimlanes: swimlanes.length,
  });

  const {
    swimlanesPerPage,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    shouldShowPagination,
  } = useSwimlanesPagination(
    swimlanes,
    paginateSwimlanes,
    setTimeWindow,
    setPageDefaultTimeWindow
  );

  const referenceLines = useMemo(
    () =>
      createReferenceLines({
        lineGroups: swimlanesPerPage,
        hoveredLabel,
        setHoveredLabel: handleHoveredLabelChange,
        chartConfig,
        removeSwimlane,
      }),
    [
      chartConfig,
      swimlanesPerPage,
      handleHoveredLabelChange,
      hoveredLabel,
      removeSwimlane,
    ]
  );

  const eventsOnLines = useEventsOnLine({
    swimlanesPerPage,
    start,
    end,
    onMouseEnter,
    onMouseLeave,
    onClick,
    chartWidth,
    chartConfig,
    highlightedId,
  });

  const onZoomChange = useCallback(
    (startTime: number, endTime: number) => {
      setTimeWindow({
        start: new Date(startTime),
        end: new Date(endTime),
      });
      setIsZoomChanged?.(true);
      dispatchEvent(AnalyticEvents.Charts.Timeline.Zoom_Change, {
        page: window.location.pathname,
      });
    },
    [setIsZoomChanged, setTimeWindow]
  );
  const { onMouseDown, onMouseMove, onMouseUp, zoom } = useZoom(onZoomChange);

  const TickComponent = getTickComponent(start, end);

  const chartHeight = useDynamicChartHeight(
    swimlanesPerPage.length,
    shouldShowPagination,
    hideXAxis,
    chartConfig
  );

  return (
    <Container zIndex={zIndex} height={chartHeight}>
      <Chart>
        <AutoSizer onResize={(size) => setChartWidth(size.width)}>
          {({ width, height }) => (
            <ComposedChart
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseUp={onMouseUp}
              margin={chartMargin}
              width={width}
              height={height}
            >
              <XAxis
                interval={0}
                dataKey="x"
                type="number"
                tick={<TickComponent />}
                domain={[
                  start.getTime() - tickPadding,
                  end.getTime() + tickPadding,
                ]}
                scale="linear"
                ticks={ticks}
                allowDataOverflow
                orientation={xAxisPosition}
                hide={hideXAxis}
              />
              <YAxis
                hide={true}
                dataKey="y"
                type="number"
                domain={[0.65, swimlanesPerPage.length + 0.65]}
              />
              {referenceLines}
              {eventsOnLines}
              {zoom}
              <Tooltip
                wrapperStyle={{
                  outline: "none",
                  pointerEvents: "auto",
                }}
                isAnimationActive={false}
                content={EventsChartTooltip}
                cursor={false}
              />
            </ComposedChart>
          )}
        </AutoSizer>
      </Chart>
      {shouldShowPagination && (
        <Footer>
          <TablePagination
            count={totalRows}
            page={page}
            onPageChange={(_e, value) => {
              setPage(value);
              setIsZoomChanged?.(false);
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setIsZoomChanged?.(false);
            }}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </Footer>
      )}
    </Container>
  );
};
