import { useMemo } from "react";

const MAX_NUM_OF_LARGE_SWIMLANES = 3;

export interface TimelineChartConfig {
  swimlaneHeight: number;
  swimlaneLabelYOffset: number;
  eventDotWidth: number;
  eventDotIconSize: number;
  eventStrokeWidth: number;
  eventLabelFontSize: number;
  eventLabelXOffset: number;
}

interface Props {
  numOfSwimlanes: number;
}
export const useTimelineChartConfig = ({
  numOfSwimlanes,
}: Props): TimelineChartConfig =>
  useMemo(() => {
    if (numOfSwimlanes <= MAX_NUM_OF_LARGE_SWIMLANES) {
      return {
        swimlaneHeight: 70,
        swimlaneLabelYOffset: 40,
        eventDotWidth: 28,
        eventDotIconSize: 16,
        eventStrokeWidth: 28,
        eventLabelFontSize: 12,
        eventLabelXOffset: 14,
      };
    }
    return {
      swimlaneHeight: 38,
      swimlaneLabelYOffset: 28,
      eventDotWidth: 16,
      eventDotIconSize: 12,
      eventStrokeWidth: 16,
      eventLabelFontSize: 11,
      eventLabelXOffset: 10,
    };
  }, [numOfSwimlanes]);
