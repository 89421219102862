import { formatNumber } from "../../../../../../../../../shared/utils/formatNumber";

export const explanationTitle = "What it means";
export const explanationBlock1 =
  "More deploys have failed to complete their rollouts recently. Generally, Kubernetes will attempt to perform a rolling update, and will cancel the rollout if it fails.";

export const explanationBlock2 =
  "This can be caused by many reasons including configuration errors, resource constraints, image issues or network issues.";

export const explanationBlock3 =
  "Failed deployments can cause additional wasted resources, operational overhead, and in some cases impact service availability.";

export const breakdownTitle = "What happened";

export const getDescription = (
  trendPercentage: number,
  isIncreasing: boolean
) =>
  `Failed deploys ${isIncreasing ? "increased" : "decreased"} by ${formatNumber(
    trendPercentage
  )}%`;

export const getFailedDeploysNumDetected = (
  intervalInDays: string,
  totalIssues: number
) => `Failed deploys detected in the past ${intervalInDays}: ${totalIssues}`;

type BreakDownTextParams = {
  isIncreasing: boolean;
  trendPercentage: string;
  firstTotal: number;
  secondTotal: number;
};

export const getBreakDownText = ({
  isIncreasing,
  trendPercentage,
  firstTotal,
  secondTotal,
}: BreakDownTextParams) => {
  if (firstTotal === 0)
    return `The total number of failed rolling updates to resources of type Deployment increased from 0 to ${secondTotal}`;

  return `The total number of failed rolling updates to resources of type Deployment ${
    isIncreasing ? "increased" : "decreased"
  } by ${trendPercentage}% from ${firstTotal} to ${secondTotal}`;
};
