import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import { convertWorkspaceToTypedWorkspace } from "../utils";

import { WORKSPACES_PATH } from "./useGetWorkspaces";

import {
  Workspace,
  WorkspacesApiApiV1WorkspacesPostRequest,
  apiV1WorkspacesPostUrl,
} from "@/generated/workspacesApi";

export const CREATE_WORKSPACE_PATH = "/workspaces/:id";

const createWorkspace = async (
  apiClient: AxiosInstance,
  variables: WorkspacesApiApiV1WorkspacesPostRequest
): Promise<Workspace> => {
  const { data } = await apiClient.post<Workspace>(
    apiV1WorkspacesPostUrl(variables, apiClient.defaults.baseURL ?? ""),
    variables.workspace
  );

  return data;
};

export const useCreateWorkspace = () => {
  const apiClient = useWorkspacesApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: Workspace) => {
      const workspace = await createWorkspace(apiClient, {
        workspace: variables,
      });
      return convertWorkspaceToTypedWorkspace(workspace);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([WORKSPACES_PATH]);
    },
  });
};
