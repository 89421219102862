import React from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import { Alert } from "../Alert/Alert";

import { updateAgentAriaLabels } from "./ariaLabels";

type UpgradeAgentVersionAlertProps = {
  title?: string;
  severity: "warning" | "info";
  text: string;
  upgradeButton: React.ReactNode;
  padding?: string;
  showCloseButton?: boolean;
};

export const UpgradeAgentVersionAlert: React.FC<
  UpgradeAgentVersionAlertProps
> = ({ title, severity, text, upgradeButton, padding, showCloseButton }) => {
  const icon = severity === "info" ? InfoOutlined : WarningAmberOutlined;
  return (
    <Alert
      severity={severity}
      title={title ?? "Agent Update Required"}
      text={text}
      icon={icon as React.FC}
      ctaButton={upgradeButton}
      ariaLabel={updateAgentAriaLabels.agentUpdateAlert.container}
      padding={padding}
      showCloseButton={showCloseButton}
    />
  );
};
