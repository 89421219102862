import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useMemo } from "react";

import { UseGeneralEventsParams } from "../../../../../components/common/EventsPagination/useGeneralEvents";
import {
  EventsApiApiV1EventsGeneralGetRequest,
  GeneralEventsResponse,
  apiV1EventsGeneralGetUrl,
} from "../../../../../generated/resourcesApi";
import { mapGeneralResponseToImpactfulEvents } from "../../../../../components/common/EventsPagination/useGeneralResponseToImpactfulEvents";
import { useResourcesApiClient } from "../apiClient";
import { GENERAL_EVENTS } from "../../requestResponseMaps";
import { useWorkspaces } from "../../../../../components/workspaces/WorkspacesTopBar/hooks";
import { useWorkspaceQueryKey } from "../../../workspaces-api/useWorkspaceQueryKey";

const fetchGeneralEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1EventsGeneralGetRequest
): Promise<GeneralEventsResponse> => {
  const { data } = await apiClient.get<GeneralEventsResponse>(
    apiV1EventsGeneralGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGeneralEvents = ({
  timeWindow,
  komodorUid,
}: UseGeneralEventsParams) => {
  const { isLoading, isWorkspaceKindBackendFiltered } = useWorkspaces();
  const skipAppViewInHeaders = !isLoading && !isWorkspaceKindBackendFiltered;
  const apiClient = useResourcesApiClient(skipAppViewInHeaders);
  const generalEventsParams: EventsApiApiV1EventsGeneralGetRequest = useMemo(
    () => ({
      fromEpoch: timeWindow.start.getTime().toString(),
      toEpoch: timeWindow.end.getTime().toString(),
      komodorUids: komodorUid ? [komodorUid] : undefined,
    }),
    [komodorUid, timeWindow.end, timeWindow.start]
  );
  const keys = useWorkspaceQueryKey(
    [GENERAL_EVENTS, generalEventsParams, skipAppViewInHeaders],
    { skip: skipAppViewInHeaders }
  );

  return useQuery(
    keys,
    async () => {
      const response = await fetchGeneralEvents(apiClient, generalEventsParams);
      return mapGeneralResponseToImpactfulEvents(response);
    },
    { enabled: !isLoading }
  );
};
