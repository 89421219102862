import { CartesianViewBox } from "recharts/types/util/types";
import { Props as LabelProps } from "recharts/types/component/Label";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { useRef, useState, MouseEvent } from "react";

import { EventData } from "../types";
import { ZIndex } from "../../../constants/zIndex";

const ICON_SIZE = 14;
const TOP_OFFSET = 24;

export const GetEventIcon = ({
  event,
  ...labelProps
}: LabelProps & { event: EventData }): JSX.Element => {
  const x = ((labelProps.viewBox as CartesianViewBox).x ?? 0) - ICON_SIZE / 2;
  const y = ((labelProps.viewBox as CartesianViewBox).y ?? 0) - TOP_OFFSET;
  const transform = `translate(${x} ${y})`;
  const [iconRef, setIconRef] = useState<SVGElement | null>(null);
  const [open, setOpen] = useState(false);

  const isOnPopover = useRef(false);
  const isOnIcon = useRef(false);

  const { icon, popoverContent } = event;
  const closePopoverTimeout = popoverContent?.isInteractive ? 500 : 0;

  const closePopoverWithTimeout = (forcedTimeout?: number) => {
    const timeout =
      forcedTimeout !== undefined ? forcedTimeout : closePopoverTimeout;

    // allow the user to move the mouse from the icon to the popover
    setTimeout(() => {
      !isOnPopover.current && !isOnIcon.current && setOpen(false);
    }, timeout);
  };

  const onClickCapture = (e: MouseEvent<HTMLDivElement>) => {
    if (popoverContent?.shouldCloseOnClickCapture?.(e)) {
      isOnPopover.current = false;
      isOnIcon.current = false;
      closePopoverWithTimeout(1);
    }
  };

  if (!icon) return <></>;

  const Icon = icon;
  const isContentString = typeof popoverContent?.element === "string";

  const group = (
    <g
      transform={transform}
      ref={setIconRef}
      onMouseOver={() => {
        isOnIcon.current = true;
        setOpen(true);
      }}
      onMouseOut={() => {
        isOnIcon.current = false;
        closePopoverWithTimeout();
      }}
      pointerEvents={"bounding-box"}
      cursor={"pointer"}
    >
      <Icon width={ICON_SIZE} height={ICON_SIZE} color={event.color} />
    </g>
  );
  return (
    <>
      <Popper
        open={open}
        onMouseEnter={() => {
          isOnPopover.current = true;
        }}
        onMouseLeave={() => {
          isOnPopover.current = false;
          closePopoverWithTimeout();
        }}
        onClickCapture={onClickCapture}
        sx={{ zIndex: ZIndex.AboveModal }}
        anchorEl={iconRef}
        placement="top-start"
        hidden={!popoverContent?.element}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: ({ popper }: { popper: { width: number } }) => [
                -popper.width / 2 + ICON_SIZE / 2,
                8,
              ],
            },
          },
        ]}
      >
        <Paper sx={{ padding: "8px" }} elevation={4}>
          {isContentString ? (
            <Typography variant="body2">{popoverContent?.element}</Typography>
          ) : (
            popoverContent?.element
          )}
        </Paper>
      </Popper>
      {group}
    </>
  );
};
