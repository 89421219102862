import { useMemo } from "react";

import Resource from "../ResourceView/resources";
import { KomodorUidParams } from "../../shared/hooks/resources-api/resourcesAPIUtils";
import ArgoWorkflowTemplate, {
  ARGO_WORKFLOW_TEMPLATE_KIND,
} from "../ResourceView/resources/argoWorkflowTemplate";
import ArgoClusterWorkflowTemplate, {
  ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND,
} from "../ResourceView/resources/argoClusterWorkflowTemplate";
import { useArgoWorkflowsStore } from "../../shared/store/argoWorkflowsStore/argoWorkflowsStore";
import { argoWorkflowsGroupsSelector } from "../../shared/store/argoWorkflowsStore/argoWorkflowsSelectors";

export const useDefaultArgoWorkflowTemplate = (
  parsedArgoWorkflowKomodorUid: KomodorUidParams | undefined
) => {
  const argoWorkflowGroups = useArgoWorkflowsStore(argoWorkflowsGroupsSelector);

  return useMemo<Resource | undefined>(() => {
    if (parsedArgoWorkflowKomodorUid) {
      if (parsedArgoWorkflowKomodorUid.kind === ARGO_WORKFLOW_TEMPLATE_KIND) {
        const wftGroup =
          argoWorkflowGroups?.workflowTemplates?.[
            parsedArgoWorkflowKomodorUid.namespace
          ][parsedArgoWorkflowKomodorUid.name];
        if (wftGroup?.length) {
          return getDefaultWorkflowTemplate(parsedArgoWorkflowKomodorUid);
        }
      }
      if (
        parsedArgoWorkflowKomodorUid.kind ===
        ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND
      ) {
        const cwftGroup =
          argoWorkflowGroups?.clusterWorkflowTemplates?.[
            parsedArgoWorkflowKomodorUid.name
          ];
        if (cwftGroup?.length) {
          return getDefaultClusterWorkflowTemplate(
            parsedArgoWorkflowKomodorUid
          );
        }
      }
    }
    return undefined;
  }, [
    argoWorkflowGroups?.clusterWorkflowTemplates,
    argoWorkflowGroups?.workflowTemplates,
    parsedArgoWorkflowKomodorUid,
  ]);
};

const getDefaultWorkflowTemplate = (
  parsedArgoWorkflowKomodorUid: KomodorUidParams
) => {
  return new ArgoWorkflowTemplate(parsedArgoWorkflowKomodorUid.cluster, {
    apiVersion: "",
    kind: "argoproj.io/WorkflowTemplate",
    metadata: {
      annotations: {},
      creationTimestamp: "",
      generateName: "",
      generation: 0,
      labels: {},
      name: parsedArgoWorkflowKomodorUid.name,
      namespace: parsedArgoWorkflowKomodorUid.namespace,
      resourceVersion: "",
      uid: "",
    },
    spec: {
      entrypoint: "",
      templates: [],
    },
  });
};

const getDefaultClusterWorkflowTemplate = (
  parsedArgoWorkflowKomodorUid: KomodorUidParams
) => {
  return new ArgoClusterWorkflowTemplate(parsedArgoWorkflowKomodorUid.cluster, {
    apiVersion: "",
    kind: "argoproj.io/ClusterWorkflowTemplate",
    metadata: {
      annotations: {},
      creationTimestamp: "",
      generateName: "",
      generation: 0,
      labels: {},
      name: parsedArgoWorkflowKomodorUid.name,
      namespace: parsedArgoWorkflowKomodorUid.namespace,
      resourceVersion: "",
      uid: "",
    },
    spec: {
      entrypoint: "",
      templates: [],
    },
  });
};
