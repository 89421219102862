import React from "react";

import { useRect } from "@/shared/hooks/useRect";

export const usePageWidth = () => {
  const [blockRect, ref] = useRect<HTMLDivElement>();
  const [width, setWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (blockRect) {
      setWidth(blockRect.width);
    }
  }, [blockRect]);

  return {
    ref,
    width,
  };
};
