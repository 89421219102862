import React from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

import { trialEndCounterHeight } from "../../AppBanners";
import { useBanner } from "../../../shared/hooks/useBanner";
import { WORKFLOW_CONFIG_TYPES } from "../EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../monitorsView/common/types";
import { isCustomEvent } from "../EventGroup/customEvent/utils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NodeEventGroupTypes } from "../EventGroup/nodeEvent/NodeChangeGroup";

import { CloseButton, CopyButton } from "./DrawerActions";

import { IEventDrawer } from "@/components/common/ProcessList/types";

interface EventDrawerProps extends IEventDrawer {
  open: boolean;
  onClose: () => void;
}

const withBannerDrawerStyle = {
  height: `calc(100% - ${trialEndCounterHeight} - 2px)`,
  top: "unset",
  bottom: "0",
};

const Content = styled.div`
  padding-top: 1rem;
  font-size: 0.75rem;
  height: 100%;
`;

const EventDrawer: React.FC<EventDrawerProps> = ({
  open,
  onClose,
  event,
  children,
  ariaLabel,
}) => {
  const isBannerAppears = useBanner();
  const { type: eventType } = event || {};

  const excludeDrawerActions =
    Object.values(NodeEventGroupTypes).includes(eventType ?? "") ||
    eventType === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Availability] ||
    eventType === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Pod] ||
    eventType === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Workflow] ||
    isCustomEvent(event);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "60%",
          display: "unset",
          ...(isBannerAppears && withBannerDrawerStyle),
        },
      }}
      data-e2e-selector="drawer"
      aria-label={ariaLabel}
    >
      {!excludeDrawerActions && (
        <>
          <CloseButton onClose={onClose} />
          <CopyButton />
        </>
      )}
      <Content>{children}</Content>
    </Drawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EventDrawer;
