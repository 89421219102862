import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";

import { DistributionItem } from "@/components/ClustersView/components/DistributionItem";
import { FixedWidth } from "@/components/ClustersView/styles";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

export const DistributionItemWithTooltip: React.FC<{
  count: number;
  name: string;
  statusColor: string | undefined;
  minWidth: string;
  ariaLabel?: string;
}> = ({ count, name, statusColor, minWidth, ariaLabel }) => {
  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={
        <Typography variant="body3">
          {count.toLocaleString()} {name}
        </Typography>
      }
      placement="top"
    >
      <div aria-label={ariaLabel}>
        <DistributionItem statusShape="circle" color={statusColor}>
          <FixedWidth minWidth={minWidth}>{count.toLocaleString()}</FixedWidth>
        </DistributionItem>
      </div>
    </Tooltip>
  );
};
