import React from "react";

import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { MetricsPreferences } from "../../../../../Metrics/MetricsPreferences/MetricsPreferences";
import { workloadsMetricsAriaLabels } from "../../ariaLabel";
import { Timeframe } from "../../../../../../shared/types/TimeWindow";

export const WorkloadMetricsPreferences: React.FC = () => {
  const {
    timeWindow,
    setTimeWindow,
    selectedAggregationMetric,
    setSelectedAggregationMetric,
  } = useWorkloadMetricsTabContext();

  return (
    <MetricsPreferences
      aggregationSelectorAriaLabel={
        workloadsMetricsAriaLabels.aggregationToggle
      }
      aggregationMetric={selectedAggregationMetric}
      setAggregationMetric={setSelectedAggregationMetric}
      timeOptions={[Timeframe.Last24Hours, Timeframe.Last7Days]}
      timeWindow={timeWindow}
      setTimeWindow={setTimeWindow}
    />
  );
};
