import React, { createContext, useContext, useState } from "react";
import { isEqual } from "lodash";

import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import {
  KomodorServiceAttributes,
  useServiceAttributesConfiguredKeys,
} from "./service-attributes-fetch-hook";
import ServiceAttributesPerformQueries from "./attributes-parallel-fetch-hook";
import ServiceAttributesPerformLocalAggregation from "./attributes-local-aggregation-hook";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";

const attributesKeysSetThatDontRequireFetching = ["cluster", "namespace"];

const ServiceAttributesContext = createContext<
  KomodorServiceAttributes | undefined
>(undefined);

export const ServiceAttributesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isAnonymousUser = useIsAnonymousUser();
  const { webServiceAttributesEnableLocalAggregationForClusterNamespace } =
    useOverridableFlags();
  const serviceAttributesKeys = useServiceAttributesConfiguredKeys();

  const [allServicesAttributes, setAllServicesAttributes] =
    useState<KomodorServiceAttributes>();

  if (isAnonymousUser) {
    return (
      <ServiceAttributesContext.Provider value={allServicesAttributes}>
        {children}
      </ServiceAttributesContext.Provider>
    );
  }

  if (
    webServiceAttributesEnableLocalAggregationForClusterNamespace &&
    isEqual(serviceAttributesKeys, attributesKeysSetThatDontRequireFetching)
  ) {
    return (
      <ServiceAttributesContext.Provider value={allServicesAttributes}>
        <ServiceAttributesPerformLocalAggregation
          setKomodorServiceAttributes={setAllServicesAttributes}
        />
        {children}
      </ServiceAttributesContext.Provider>
    );
  }

  return (
    <ServiceAttributesContext.Provider value={allServicesAttributes}>
      <ServiceAttributesPerformQueries
        setKomodorServiceAttributes={setAllServicesAttributes}
      />
      {children}
    </ServiceAttributesContext.Provider>
  );
};

const useAllServicesAttributes = (): KomodorServiceAttributes | undefined =>
  useContext(ServiceAttributesContext);

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default useAllServicesAttributes;
