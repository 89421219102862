import React from "react";

import { IconProps } from "../types";

export const Trash24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.75V4H21.25C21.4489 4 21.6397 4.07902 21.7803 4.21967C21.921 4.36032 22 4.55109 22 4.75C22 4.94891 21.921 5.13968 21.7803 5.28033C21.6397 5.42098 21.4489 5.5 21.25 5.5H2.75C2.55109 5.5 2.36032 5.42098 2.21967 5.28033C2.07902 5.13968 2 4.94891 2 4.75C2 4.55109 2.07902 4.36032 2.21967 4.21967C2.36032 4.07902 2.55109 4 2.75 4H8V2.75C8 1.784 8.784 1 9.75 1H14.25C15.216 1 16 1.784 16 2.75ZM9.5 2.75C9.5 2.6837 9.52634 2.62011 9.57322 2.57322C9.62011 2.52634 9.6837 2.5 9.75 2.5H14.25C14.3163 2.5 14.3799 2.52634 14.4268 2.57322C14.4737 2.62011 14.5 2.6837 14.5 2.75V4H9.5V2.75Z"
      />
      <path d="M4.99702 8.17797C4.98847 8.07923 4.96044 7.98317 4.91454 7.89533C4.86864 7.80749 4.80578 7.72962 4.72961 7.66622C4.65343 7.60282 4.56544 7.55514 4.47073 7.52595C4.37602 7.49676 4.27646 7.48663 4.17781 7.49614C4.07916 7.50565 3.98338 7.53463 3.89599 7.58138C3.8086 7.62814 3.73135 7.69175 3.6687 7.76854C3.60604 7.84534 3.55923 7.93378 3.53097 8.02878C3.5027 8.12377 3.49354 8.22342 3.50402 8.32197L4.91602 20.92C4.95825 21.3527 5.16003 21.7541 5.48205 22.0462C5.80407 22.3383 6.22328 22.5 6.65802 22.5H17.342C17.7769 22.5 18.1963 22.3381 18.5183 22.0458C18.8403 21.7536 19.042 21.3518 19.084 20.919L20.497 8.32197C20.5161 8.12386 20.4557 7.92627 20.3291 7.77268C20.2026 7.61908 20.0201 7.52207 19.822 7.50297C19.6239 7.48388 19.4263 7.54427 19.2727 7.67085C19.1191 7.79744 19.0221 7.97986 19.003 8.17797L17.591 20.774C17.5851 20.8359 17.5562 20.8933 17.5102 20.9351C17.4642 20.9769 17.4042 21 17.342 21H6.65802C6.59584 21 6.53589 20.9769 6.48985 20.9351C6.44381 20.8933 6.41499 20.8359 6.40902 20.774L4.99702 8.17797Z" />
      <path d="M9.49605 9.54115C9.40295 9.50887 9.30439 9.49524 9.20603 9.50106C9.10765 9.5068 9.01038 9.53187 8.9217 9.57484C8.83302 9.6178 8.75367 9.67782 8.6882 9.75147C8.62273 9.82511 8.57241 9.91094 8.54012 10.004C8.50783 10.0971 8.49421 10.1957 8.50003 10.2941L9.00003 16.7941C9.0117 16.9928 9.10185 17.1788 9.25066 17.3111C9.39947 17.4435 9.59475 17.5112 9.79353 17.4996C9.99231 17.4879 10.1783 17.3977 10.3106 17.2489C10.4429 17.1001 10.5107 16.9048 10.499 16.7061L9.99903 10.2061C9.99329 10.1077 9.96821 10.0114 9.92525 9.92273C9.88228 9.83405 9.82226 9.75471 9.74862 9.68923C9.67497 9.62376 9.58915 9.57344 9.49605 9.54115Z" />
      <path d="M15.3106 9.7512C15.4429 9.90001 15.5107 10.0953 15.499 10.2941L14.998 16.7941C14.9864 16.9927 14.8963 17.1786 14.7475 17.3108C14.5988 17.443 14.4037 17.5107 14.205 17.4991C14.0064 17.4874 13.8205 17.3973 13.6883 17.2486C13.5561 17.0999 13.4884 16.9047 13.5 16.7061L14 10.2061C14.0117 10.0073 14.1019 9.82128 14.2507 9.68897C14.3995 9.55666 14.5947 9.48889 14.7935 9.50056C14.9923 9.51223 15.1783 9.60239 15.3106 9.7512Z" />
    </svg>
  );
};

Trash24.defaultProps = {
  fill: "currentColor",
};
