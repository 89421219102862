import {
  RightSizingCostDataPoints,
  RightSizingCostDataPoint,
} from "../../../../generated/metricsApi";

function getRandomNumber(min: number, max: number): number {
  const randomDecimal = Math.random();

  const randomNumber = randomDecimal * (max - min + 1) + min;

  return Math.floor(randomNumber);
}

export const generateMockCostRightSizingData = (
  count = 30
): RightSizingCostDataPoints => {
  let timestamp = 1_681_508_000;

  const mockCostRightSizingDataPoints = Array.from({
    length: count,
  }).map<RightSizingCostDataPoint>((_, idx) => {
    timestamp += 86400;

    return {
      timestampEpoch: timestamp,
      usage: getRandomNumber(20, 40),
      request: getRandomNumber(90, 100),
      recommendation: getRandomNumber(35, 45),
    };
  });

  return {
    datapoints: mockCostRightSizingDataPoints,
  };
};
