import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  User,
  UsersApiApiV1UsersGetRequest,
  apiV1UsersGetUrl,
} from "../../../../../generated/auth";

const USERS_PATH = "/users";

const fetchUsers = async (
  apiClient: AxiosInstance,
  params: UsersApiApiV1UsersGetRequest = {}
): Promise<User[]> => {
  const { data } = await apiClient.get(
    apiV1UsersGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetUsers = (
  params?: UsersApiApiV1UsersGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery([USERS_PATH, params], () => fetchUsers(apiClient, params), {
    enabled,
  });
};
