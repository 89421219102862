import React from "react";
import styled, { css } from "styled-components";

import { TextRegular } from "../../../../../components/common/typography";
import Divider from "../../../../monitorsView/common/Divider";
import Header from "../../../../monitorsView/common/Header";
import { blackGreyText, gray2, gray9 } from "../../../../../Colors";

import { StyledExternalLink, StyledFinishContainer } from "./styles";
import { integrationLinks } from "./utils";
import complete from "./assets/complete.svg";

const MiddleHorizontalStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  padding: 2rem;
`;

const TopSectionStyle = styled.div`
  ${MiddleHorizontalStyle}
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

const CompleteIcon = styled.div`
  background-image: url(${complete});
  background-repeat: no-repeat;
  width: 3.85rem;
  height: 3.85rem;
`;

const Title = styled.span`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: ${gray2};
`;

const CenteredTextRegular = styled(TextRegular)`
  text-align: center;
`;

const TopSection: React.FC = () => {
  return (
    <TopSectionStyle>
      <CompleteIcon />
      <Title>
        Sentry integration was added
        <br />
        to Komodor successfully
      </Title>
    </TopSectionStyle>
  );
};

const BottomSectionStyle = styled.div`
  ${MiddleHorizontalStyle}
  margin-top: 1.5rem;
  gap: 1rem;
`;

const VariablesContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const VariableStyle = styled.span`
  background-color: ${gray9};
  color: ${blackGreyText};
  padding: 4px;
`;

const BottomSection: React.FC = () => {
  return (
    <BottomSectionStyle>
      <CenteredTextRegular>
        To map Sentry issues and your services, add the following <br />
        variables to your deployments.
      </CenteredTextRegular>
      <VariablesContainer>
        <VariableStyle>SENTRY_DSN</VariableStyle>
        <VariableStyle>SENTRY_enviroment</VariableStyle>
      </VariablesContainer>
      <CenteredTextRegular>
        Not sure how?{" "}
        <StyledExternalLink
          color={gray2}
          href={integrationLinks.annotationsGuide}
        >
          Here’s our annotations guide
        </StyledExternalLink>
      </CenteredTextRegular>
    </BottomSectionStyle>
  );
};

const SuccessScreen: React.FC = () => {
  return (
    <Container>
      <TopSection />
      <Divider />
      <BottomSection />
    </Container>
  );
};

const FinishSetup: React.FC<{
  closeModalCallback: () => void;
}> = ({ closeModalCallback }) => {
  return (
    <StyledFinishContainer>
      <Header closeModalCallback={closeModalCallback} title={""} />
      <Divider />
      <SuccessScreen />
    </StyledFinishContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default FinishSetup;
