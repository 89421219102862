import { useMemo } from "react";

import { defaultSelectedViolationFilters } from "../constants/violationFiltersConstants";

import { useGetImpactGroupType } from "./useSelectedTab";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  ImpactGroupType,
  ViolationCountBy,
  ViolationFilterOperator,
} from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

const getImpactGroupTypeFilter = (impactGroupType: ImpactGroupType) => {
  const values = [
    impactGroupType,
    ...(impactGroupType === ImpactGroupType.Dynamic
      ? [ImpactGroupType.Realtime]
      : []),
  ];

  return {
    [ViolationCountBy.ImpactGroupType]: [
      {
        values,
        operator: ViolationFilterOperator.Include,
      },
    ],
  };
};

export const useDefaultFilters = (): Dictionary<FilterOptionInUrl[]> => {
  const impactGroupTypeByTab = useGetImpactGroupType();
  const { reliabilityHealthManagement } = useOverridableFlags();

  return useMemo(
    () => ({
      ...defaultSelectedViolationFilters,
      ...(reliabilityHealthManagement
        ? getImpactGroupTypeFilter(impactGroupTypeByTab)
        : {}),
    }),
    [impactGroupTypeByTab, reliabilityHealthManagement]
  );
};
