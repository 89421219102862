import React from "react";
import { InfoCircleOutlined24, Team24 } from "@komodorio/design-system/icons";
import {
  ActionMetadataBase,
  ActionTypes,
  ActionMetadataHelmChartInstall,
  ActionMetadataHelmChartRevert,
  ActionMetadataHelmChartUninstall,
  ActionMetadataHelmRepoAdd,
} from "komodor-types";

import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import eventsIcon from "../../ProcessList/assets/events.svg";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import srcCodeIcon from "./assets/srcCode.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import { CommandText, usePreparedEvents } from "./common";

interface HelmEventDetailsProps {
  eventGroup: ActionEventGroup;
}

interface HelmEventTitleAndCommand {
  title: string;
  command: string;
}

export const HelmEventsDetails: React.FC<HelmEventDetailsProps> = ({
  eventGroup,
}) => {
  const { events, metadata, email, actionResult } = eventGroup;

  const preparedEvents = usePreparedEvents(events);

  const typeToTitleCommand: Record<string, HelmEventTitleAndCommand> = {
    [ActionTypes.RevertHelmChart]: {
      title: `Helm rollback`,
      command: `helm rollback ${
        (metadata as ActionMetadataHelmChartRevert)?.chartName
      } ${(metadata as ActionMetadataHelmChartRevert)?.revision} --namespace ${
        (metadata as ActionMetadataHelmChartRevert)?.namespace
      }`,
    },
    [ActionTypes.InstallHelmChart]: {
      title: `Helm upgrade`,
      command: `helm upgrade --install ${
        (metadata as ActionMetadataHelmChartInstall)?.chartName
      } bitnami/nginx --create-namespace --namespace ${
        (metadata as ActionMetadataHelmChartInstall)?.namespace
      }`,
    },
    [ActionTypes.UninstallHelmChart]: {
      title: `Helm uninstall`,
      command: `helm uninstall ${
        (metadata as ActionMetadataHelmChartUninstall)?.chartName
      } --namespace ${
        (metadata as ActionMetadataHelmChartUninstall)?.namespace
      }`,
    },
    [ActionTypes.AddHelmRepo]: {
      title: `Helm repo add`,
      command: `helm repo add ${
        (metadata as ActionMetadataHelmRepoAdd)?.repoName
      } ${(metadata as ActionMetadataHelmRepoAdd)?.repoUrl}`,
    },
    [ActionTypes.RemoveHelmRepo]: {
      title: `Helm repo remove`,
      command: `helm repo remove ${
        (metadata as ActionMetadataHelmRepoAdd)?.repoName
      }`,
    },
  };

  const title = typeToTitleCommand[(metadata as ActionMetadataBase).type].title;
  const command =
    typeToTitleCommand[(metadata as ActionMetadataBase).type].command;

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title} />
      <EventDetailsSection
        icon={srcCodeIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Command"
      >
        <CommandText>{command}</CommandText>
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
