import React from "react";
import styled from "styled-components";

import { StyledPaddedCard } from "../../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../../store/costOptimizationStore";
import { selectRightSizingStatsState } from "../../../../store/costOptimizationStoreSelectors";
import { FetchError } from "../../../shared/errors/FetchError";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { CenteredLoader } from "../../../../../../shared/components/CenteredLoader";

import { PotentialSavingPeriod } from "./PotentialSavingPeriod";

export const StyledPotentialSavingsCard = styled(StyledPaddedCard)`
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
`;

const Container = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: 165px;
`;

export const PotentialSavings: React.FC = () => {
  const { loading, data, error } = useCostOptimizationStore(
    selectRightSizingStatsState
  );

  return (
    <StyledPotentialSavingsCard
      aria-label={
        costOptimizationAriaLabels.rightSizingPage.summaryCards.savingCard.card
      }
    >
      {loading ? (
        <Container>
          <CenteredLoader />
        </Container>
      ) : error ? (
        <Container>
          <FetchError />
        </Container>
      ) : (
        <>
          <PotentialSavingPeriod
            amount={data?.monthlySaving}
            variant="monthly"
          />
          <PotentialSavingPeriod amount={data?.yearlySaving} variant="yearly" />
        </>
      )}
    </StyledPotentialSavingsCard>
  );
};
