import { SocketsManager } from "../../../context/WebSocketsProvider";
import { ONE_SECOND } from "../../../sockets/constants/keepAlive";
import { MAX_RETRIES } from "../constants";
import { PodLogLine } from "../types";

import { systemMessage } from "./constants";

interface Args {
  retryCount: number;
  setLogs: (logs: PodLogLine[], isSystemMessageLogs: boolean) => void;
  sockets: SocketsManager;
  identifier: string;
  askForRetry: () => void;
}

export const buildEventLog = (message: string): PodLogLine => ({
  logTime: new Date().toISOString(),
  isEvent: true,
  message,
});

export const handleUnknownTerminationReason = ({
  retryCount,
  setLogs,
  sockets,
  identifier,
  askForRetry,
}: Args) => {
  if (retryCount === 0) {
    setLogs([buildEventLog(systemMessage.logStreamStopped)], true);
  }

  if (retryCount < MAX_RETRIES) {
    // Exponential backoff with starting time of 2 seconds
    const backoffTime = Math.pow(retryCount || 2, 2) * ONE_SECOND;
    setTimeout(() => {
      sockets.removeSocket(identifier, false);
      askForRetry();
    }, backoffTime);
  } else {
    setLogs([buildEventLog(systemMessage.maxRetriesExceeded)], true);
  }

  sockets.addContextToSocket(identifier, {
    retryCount: retryCount + 1,
    sessionId: undefined,
  });
};
