import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";

import externalDNSPreviewUrl from "../assets/external-dns-preview.svg";

import { FeaturePreviewPage } from "@/shared/components/FeaturePreviewPage";
import {
  ariaLabels,
  description,
  includedFeatures,
} from "@/pages/coming-soon/external-dns/constants";

export const ExternalDNSComingSoon: React.FC = () => {
  return (
    <FeaturePreviewPage
      ariaLabel={ariaLabels.container}
      title="External DNS"
      bullets={includedFeatures}
      bulletsHeader="Feature Highlights"
      img={
        <img
          src={externalDNSPreviewUrl}
          width="100%"
          alt="External DNS Preview"
        />
      }
      tag="soon"
      overrideTagLabel="Coming Soon"
      joinButton={true}
    >
      <Stack>
        <Typography variant="body1" color={muiTheme.palette.text.secondary}>
          {description}
        </Typography>
      </Stack>
    </FeaturePreviewPage>
  );
};
