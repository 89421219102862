import React from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import { APIDeprecationsSupportingDataDeprecatedAPIsInner } from "../../../../../../../../../../../generated/reliabilityApi";

import { CopyApi, NoApiAlert } from "./ApiColumnValue";

export const getColumns =
  (): GridColDef<APIDeprecationsSupportingDataDeprecatedAPIsInner>[] => [
    { field: "kind", headerName: "Kind", flex: 1 },
    {
      field: "resources",
      headerName: "Resources count",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.resources.length,
    },
    {
      field: "deprecatedInVersion",
      headerName: "Deprecated in",
      flex: 1,
    },
    { field: "removedInVersion", headerName: "Removed in", flex: 1 },
    {
      field: "replacementApi",
      headerName: "API replacement",
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? <CopyApi api={params.value} /> : <NoApiAlert />,
    },
  ];
