import IconButton from "@mui/material/IconButton";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import Tooltip from "@mui/material/Tooltip";

import { useShouldShowChatSupport } from "@/components/SupportTools/Intercom/IntercomHooks";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const ChatButton: React.FC = () => {
  const onClick = () => {
    window.zE?.("messenger", "open");
  };

  const shouldShowChatSupport = useShouldShowChatSupport();

  if (!shouldShowChatSupport) {
    return null;
  }

  const { TopBar } = AriaLabels;

  return (
    <Tooltip title="Chat Support">
      <IconButton aria-label={TopBar.ChatButton} onClick={onClick}>
        <ChatBubbleOutline
          sx={{
            fontSize: "24px",
            cursor: "pointer",
          }}
          data-testid="chat-icon"
        />
      </IconButton>
    </Tooltip>
  );
};
