import { useMemo } from "react";

import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { useScopeFilters } from "../../../../../../hooks/useScopeFilters";
import {
  Severity,
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "../../../../../../../../generated/reliabilityApi";
import { allowedStatusesSelectorValues } from "../../../../../../constants/reliabilityConstants";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import { selectViolationsState } from "../../../../../../store/reliabilityStoreSelectors";
import { useTimeWindowToEpoch } from "../../../../../../hooks/useTimeWindowToEpoch";
import { useIsScopedFiltersMode } from "../../../../../../hooks/useIsScopedFiltersMode";

import { useGetClusterNamesParam } from "./useGetClusterNamesParam";
import { useInitialGroupState } from "./useInitialGroupState";
import { useGetCheckTypesParam } from "./useGetCheckTypesParam";

import { useGetImpactGroupType } from "@/components/reliability/hooks/useSelectedTab";
import { useCheckCategory } from "@/components/reliability/hooks/useCheckCategory";
import { useRelevantImpactGroupType } from "@/components/reliability/hooks/useRelevantImpactGroupType";

export const useViolationsRequestParams = (
  scope?: keyof ViolationsApiGetViolationsRequest
) => {
  const { uid, tabValue, violationsGroupBy, impactGroupIdentifier } =
    useViolationsTableContext();
  const isScopedFiltersMode = useIsScopedFiltersMode();

  const { groups } = useReliabilityStore(selectViolationsState);
  const timeWindowToEpoch = useTimeWindowToEpoch();

  const initialGroupState = useInitialGroupState(violationsGroupBy);

  const impactGroupTypeByTab = useGetImpactGroupType();

  const {
    checkTypes,
    searchTerm,
    statuses: deprecatedStatuses,
    paginationModel,
  } = groups[violationsGroupBy][uid ?? ""] ?? initialGroupState;

  const impactGroupId =
    violationsGroupBy === "Impact" ? impactGroupIdentifier : undefined;

  const {
    impactGroupType,
    namespace,
    resourceType,
    status,
    shortResourceName,
    createdAt,
    komodorUid,
  } = useScopeFilters();

  const clusterNames = useGetClusterNamesParam();
  const checkTypeParam = useGetCheckTypesParam(checkTypes);

  const severities = useMemo(() => {
    if (scope === "severity") return undefined;
    return tabValue === "all" ? undefined : [tabValue as Severity];
  }, [scope, tabValue]);

  const statusesToUse = useMemo<ViolationStatus[]>(() => {
    if (!isScopedFiltersMode) return (status ?? []) as ViolationStatus[];
    return deprecatedStatuses?.length === 0
      ? allowedStatusesSelectorValues
      : deprecatedStatuses;
  }, [deprecatedStatuses, isScopedFiltersMode, status]);

  const checkCategory = useCheckCategory();

  const epochs = timeWindowToEpoch(createdAt?.[0]);

  const paginationModelToUse =
    paginationModel ?? initialGroupState.paginationModel;

  const impactGroupsToUse = useRelevantImpactGroupType({
    impactGroupTypeFilter: impactGroupType,
    impactGroupTypeByTab,
  });

  return useMemo<ViolationsApiGetViolationsRequest>(() => {
    return {
      pageSize: paginationModelToUse.pageSize,
      offset: paginationModelToUse.page * paginationModelToUse.pageSize,
      checkType: checkTypeParam,
      status: statusesToUse,
      severity: severities,
      checkCategory,
      impactGroupType: impactGroupsToUse,
      ...(impactGroupId && { impactGroupId: [impactGroupId] }),
      ...(searchTerm && { shortResourceNameSearchTerm: searchTerm }),
      ...(shortResourceName?.length && {
        shortResourceName,
      }),
      ...(namespace?.length && { namespace }),
      ...(clusterNames?.length && { clusterName: clusterNames }),
      ...(resourceType?.length && { resourceType }),
      ...(!!epochs && { ...epochs }),
      ...(komodorUid?.length && { komodorUid }),
    };
  }, [
    paginationModelToUse.pageSize,
    paginationModelToUse.page,
    checkTypeParam,
    statusesToUse,
    severities,
    checkCategory,
    impactGroupsToUse,
    impactGroupId,
    searchTerm,
    shortResourceName,
    namespace,
    clusterNames,
    resourceType,
    epochs,
    komodorUid,
  ]);
};
