import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import {
  Button,
  Divider,
  Input,
  MultiSelect,
  OptionType,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Check16, Close16, Loader16 } from "@komodorio/design-system/icons";
import { ActionTypes, TaskType } from "komodor-types";
import { ActionMetadataHelmRepoAdd } from "komodor-types/build/entities/AgentTask";

import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { WhiteContainer } from "../../../ResourceView/common/resourceUtils";
import { StyledDrawer } from "../../../ResourceView/ResourceDrawer";
import { useMultiClusterAgentTask } from "../../../../shared/hooks/useAgentTask/useAgentTask";
import {
  useFormValidations,
  ValidationsProvider,
} from "../../../../shared/context/ValidationsProvider";
import { invalidateChartRevisionsCache } from "../utils";
import { useCreateHelmRepo } from "../../../../shared/hooks/resources-api/client/helmRepos/useCreateHelmRepo";

import { ButtonsContainer, Z_INDEX_ABOVE_MODAL } from "./styles";
import HelmDrawerHeader from "./upgrade/ChartUpgradeHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto 43fr auto 3fr;
  height: 100%;
`;
const StyledHeader = styled(HelmDrawerHeader)`
  width: 100%;
`;
const StyledMainContent = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 2rem;
  margin-top: 2rem;
`;
const StyledButtonsContainer = styled(ButtonsContainer)`
  width: 100%;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const urlRegex =
  /^(https?:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const validateRepoUrl = (url: string | undefined) => {
  if (!url) {
    return "Please enter repo URL";
  }
  if (!urlRegex.test(url)) {
    return "Invalid repo URL";
  }
  return;
};

const cannotReachUrlMessage =
  "We verify whether the Repo is accessible from a public network";

export interface RepoAddDrawerProps {
  open: boolean;
  onClose: () => void;
  refresh: () => void;
  clustersList: string[];
}

const RepoAddDrawerContent: React.FC<{
  onClose: () => void;
  refresh: () => void;
  clustersList: string[];
}> = ({ onClose, clustersList, refresh }) => {
  const [clusterValue, setClustersValue] = useState<OptionType<string>[]>();
  const [repoName, setRepoName] = useState<string>();
  const [repoUrl, setRepoUrl] = useState<string>();
  const { mutateAsync: createHelmRepo } = useCreateHelmRepo();
  const {
    execute: executeMultiClusterAgentTask,
    isFetching: isRunningAgentTask,
  } = useMultiClusterAgentTask();
  const { errors, setError } = useFormValidations();

  const createRepos = useCallback(
    async (clusters: string[], repoName: string, repoUrl: string) => {
      for (const clusterName of clusters) {
        const helmRepo = await createHelmRepo({
          clusterName,
          repoName,
          repoUrl,
        });
        if (!helmRepo) {
          // Do nothing
        }
      }
      const metadata: ActionMetadataHelmRepoAdd = {
        cluster: "",
        repoName,
        repoUrl,
        type: ActionTypes.AddHelmRepo,
      };
      await executeMultiClusterAgentTask(
        TaskType.ACTION_COMMAND,
        metadata,
        clusters
      );
    },
    [createHelmRepo, executeMultiClusterAgentTask]
  );

  const [testWebhookSuccess, setTestWebhookSuccess] = useState<
    boolean | null
  >();
  const handleTestWebhook = async () => {
    setTestWebhookSuccess(null);
    const urlError = validateRepoUrl(repoUrl);
    if (urlError) {
      setTestWebhookSuccess(false);
      setError("url", urlError);
      return;
    }
    setError("url", undefined);
    try {
      await fetch(repoUrl ?? "", {
        mode: "no-cors",
        method: "HEAD",
        headers: { "Content-Type": "application/json" },
      });
      setTestWebhookSuccess(true);
    } catch (e) {
      setTestWebhookSuccess(false);
      setError("url", cannotReachUrlMessage);
    }
  };

  const clusterOptions = clustersList.map((c) => {
    return { label: c, value: c };
  });

  return (
    <Grid aria-label="helm add-repo">
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledHeader title={"Add Helm Repository"} onClose={onClose} />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <ResponsiveLayout>
        <StyledMainContent>
          <Typography variant={"text"} size={"large"}>
            Connect your repositories to enable upgrading Helm charts directly
            from Komodor
          </Typography>
          <Input
            label="Name"
            size="medium"
            width="20rem"
            placeholder="enter a repo name"
            onChange={(e) => setRepoName(e.target.value)}
          />
          <Flex>
            <Input
              label="URL"
              size="medium"
              width="20rem"
              placeholder="enter the URL for the repo"
              onChange={(e) => setRepoUrl(e.target.value)}
              errorMessage={errors["url"]}
            />
            <Button size="small" onClick={handleTestWebhook}>
              {testWebhookSuccess === true ? (
                <Check16 color={theme.foreground.fgGreen} />
              ) : testWebhookSuccess === false ? (
                <Close16 color={theme.foreground.fgPink} />
              ) : null}
              {testWebhookSuccess === null ? "Testing..." : "Test Repo URL"}
            </Button>
          </Flex>
          <MultiSelect
            label="Cluster(s)"
            width="20rem"
            size="medium"
            placeholder="Select the Cluster(s) to install the repo on"
            value={clusterValue}
            options={clusterOptions}
            onChange={(v) => setClustersValue(v)}
            listZIndex={Z_INDEX_ABOVE_MODAL}
          />
        </StyledMainContent>
      </ResponsiveLayout>
      <Divider />
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledButtonsContainer>
            <Button
              size="small"
              variant="primary"
              onClick={async () => {
                if (
                  !isRunningAgentTask &&
                  clusterValue &&
                  repoName &&
                  repoUrl
                ) {
                  const urlError = validateRepoUrl(repoUrl);
                  if (urlError) {
                    return setError("url", urlError);
                  }
                  setError("url", undefined);
                  await createRepos(
                    clusterValue.map((c) => c.value),
                    repoName,
                    repoUrl
                  );
                  invalidateChartRevisionsCache();
                  refresh();
                  onClose();
                }
              }}
              disabled={!(clusterValue && repoName && repoUrl)}
            >
              {isRunningAgentTask ? <Loader16 /> : "Save"}
            </Button>
            <Button size="small" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </StyledButtonsContainer>
        </ResponsiveLayout>
      </WhiteContainer>
    </Grid>
  );
};

const RepoAddDrawer: React.FC<RepoAddDrawerProps> = (props) => {
  return (
    <StyledDrawer
      open={props.open}
      width={"25%"}
      onOverlayClick={props.onClose}
      onEscKeyPress={props.onClose}
      zIndex={1}
    >
      <ValidationsProvider>
        <RepoAddDrawerContent
          clustersList={props.clustersList}
          onClose={props.onClose}
          refresh={props.refresh}
        />
      </ValidationsProvider>
    </StyledDrawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RepoAddDrawer;
