import React from "react";
import { muiColors } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { ChevronRight16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { NodeData } from "./types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const ResourceNodeTitle: React.FC<
  Pick<NodeData, "cluster" | "namespace" | "name">
> = ({ cluster, namespace, name }) => {
  return (
    <Container>
      <StyledTypography color={muiColors.gray[500]}>
        {`${cluster}`}
        <ChevronRight16 />
        {`${namespace}`}
        <ChevronRight16 />
      </StyledTypography>
      <Typography size="large" bold>
        {name}
      </Typography>
    </Container>
  );
};
