import { getAppConfig } from "../../config/appConfig";

export const requestAgentTaskManager = (
  path: string,
  authorization: string,
  body: string
): Promise<Response> => {
  return fetch(path, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
    },
    body,
  });
};

export function getATMResourcesURL(): string {
  const serverBaseURL = getAppConfig().atmServerURL ?? "";
  return serverBaseURL + "/resources";
}
export function getATMResourcesTriggerURL(): string {
  const serverBaseURL = getAppConfig().atmServerURL ?? "";
  return serverBaseURL + "/resource/trigger";
}
export function getATMGetResourceURL(): string {
  const serverBaseURL = getAppConfig().atmServerURL ?? "";
  return serverBaseURL + "/resource";
}
