import React from "react";

import { CheckTypeStatBox } from "./CheckTypeStatBox";
import { StatsSection } from "./StatsSection";
import { useRealtimeIssues } from "./hooks";
import { infraRealtimeIssueTypes } from "./constants";

import { CheckCategory, CheckType } from "@/generated/reliabilityApi";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

export const InfraRealtimeIssuesSection: React.FC<{ clusters?: string[] }> = ({
  clusters,
}) => {
  const { groups, isLoading, error, isEmpty } = useRealtimeIssues(
    CheckCategory.Infrastructure,
    clusters
  );
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (error || isEmpty) {
    return null;
  }

  return (
    <StatsSection title="Realtime Issues">
      {groups.map(({ label, check, checkType, icon }) => (
        <CheckTypeStatBox
          ariaLabel={
            AriaLabels.ClusterOverview.HealthSection.Infra.ReliabilityRisksTile
          }
          key={label}
          title={label}
          isLoading={isLoading}
          value={groups ? check : 0}
          icon={icon}
          onClick={() => {
            dispatchEvent(
              AnalyticEvents.ClusterOverview.HealthSection.TileClicked,
              { label, category: CheckCategory.Infrastructure }
            );
            pushDrawer({
              drawerType: DrawerType.RealtimeIssuesDrawer,
              checkTypes: Object.keys(infraRealtimeIssueTypes) as CheckType[],
              initialCheckType: checkType,
              checkCategory: CheckCategory.Infrastructure,
              clusters,
            });
          }}
        />
      ))}
    </StatsSection>
  );
};
