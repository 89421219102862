export const whitelistAriaLabels = {
  viewCIDRs: "Whitelist View",
  searchCIDRs: "Search CIDRs",
  addCIDR: "Add CIDR Button",
  tableCIDR: "CIDR Table",
  emptyTablePlaceholder: "Empty Table Placeholder",
  rowCIDR: "CIDR Row",
  rowEditCIDR: "Editable CIDR Row",
  rowEditDescription: "Editable Description Row",
  rowDeleteButton: "Delete Row Button",
  modalCreateCIDR: "Whitelist Create Modal",
  modalEditCIDR: "Whitelist Edit Modal",
  dialogDeleteCIDR: "CIDR Delete Dialog",
};
