import React from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { formatPercentage } from "../../../../../shared/utils/formatPercentage";
import { formatCurrency } from "../../../../../shared/utils/formatCurrency";
import {
  DETAILS_COLOR,
  cardConfigToDataKey,
} from "../../../constants/costOptimizationConstants";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectAllocationStatsState } from "../../../store/costOptimizationStoreSelectors";
import { FetchError } from "../../shared/errors/FetchError";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { CenteredLoader } from "../../../../../shared/components/CenteredLoader";
import {
  OverviewCardDataKey,
  OverviewCardDetails,
} from "../../../types/costOptimizationTypes";

const StyledCard = styled(Card)`
  padding: 24px 16px;
  flex: 1;
  min-width: 300px;
  width: 33%;
`;

const ContentContainer = styled.div`
  margin-top: 1rem;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

type CardProps = {
  dataKey: OverviewCardDataKey;
};

const {
  totalOptimizationScore: optimizationLabel,
  totalPotentialSaving: savingsLabel,
  totalCost: costLabel,
  totalUnallocatedCost: unallocatedLabel,
} = costOptimizationAriaLabels.allocationPage.overviewCards;

const ariaLabelPerDataKey: Record<OverviewCardDataKey, string> = {
  totalCost: costLabel,
  totalPotentialSaving: savingsLabel,
  totalOptimizationScore: optimizationLabel,
};

const ariaLabelPerDetailsKey: Record<OverviewCardDetails, string> = {
  totalUnallocatedCost: unallocatedLabel,
};

export const SingleCard: React.FC<CardProps> = ({ dataKey }) => {
  const { loading, data, error } = useCostOptimizationStore(
    selectAllocationStatsState
  );

  const { label, formatter, color, details } = cardConfigToDataKey[dataKey];
  const value = data?.[dataKey];
  const detailsValue = details ? data?.[details] : null;

  const content = (
    <Typography variant={"h1"} color={color}>
      {value ? formatter(value) : "N/A"}
      {value && detailsValue && details ? (
        <Typography
          variant={"h6"}
          color={DETAILS_COLOR}
          aria-label={ariaLabelPerDetailsKey[details]}
        >
          {formatCurrency(detailsValue)} (
          {formatPercentage((detailsValue / value) * 100)}) unallocated
        </Typography>
      ) : null}
    </Typography>
  );

  return (
    <StyledCard key={label} aria-label={ariaLabelPerDataKey[dataKey]}>
      <Typography variant={"h5"}>{label}</Typography>
      <ContentContainer>
        {loading ? <CenteredLoader /> : error ? <FetchError /> : content}
      </ContentContainer>
    </StyledCard>
  );
};
