import { ResourceType } from "../types/appViewsResourcesTypes";
import {
  jobTypes,
  serviceTypes,
} from "../components/OverviewPageOld/cardsContent/ResourcesInventory/constants/resourceTypeToName";
import { KubernetesResource } from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import {
  FilterCategories,
  HealthStatus,
} from "../../../../../shared/components/FilterBar/constants";
import {
  FILTERS_BAR_FILTERS_PARAM_KEY,
  INSPECTION_STATUS_PARAM_KEY,
} from "../../../../../shared/config/urlSearchParamsKeys";
import {
  POD_TERMINATED_FAILURE_REASONS,
  POD_WAITING_FAILURE_REASON,
} from "../../../../../shared/constants/podStatus";
import { NodeStatuses } from "../../../../Inspection/NodesProvider/NodeStatus";

export const isServiceType = (type: ResourceType): boolean => {
  return serviceTypes.includes(type);
};
export const isJobType = (type: ResourceType): boolean => {
  return jobTypes.includes(type);
};

type GetServicePathParams = {
  type: ResourceType;
  isUnhealthy?: boolean;
  kubernetesResource?: KubernetesResource;
};
export const getServiceFiltersSearchParams = ({
  type,
  isUnhealthy,
  kubernetesResource,
}: GetServicePathParams): string => {
  const searchParams = new URLSearchParams();
  if (!kubernetesResource && type !== "rollouts") return "";

  const resourceKind = kubernetesResource?.Kind || "Rollout";

  const filters = {
    [FilterCategories.kind]: { [resourceKind]: true },
    ...(isUnhealthy && {
      [FilterCategories.health]: { [HealthStatus.unhealthy]: "true" },
    }),
  };
  searchParams.set(FILTERS_BAR_FILTERS_PARAM_KEY, JSON.stringify(filters));
  return searchParams.toString();
};

export const getUnhealthySearchParamsForPods = (): string => {
  const searchParams = new URLSearchParams();
  searchParams.set(
    INSPECTION_STATUS_PARAM_KEY,
    JSON.stringify([
      ...POD_WAITING_FAILURE_REASON,
      ...POD_TERMINATED_FAILURE_REASONS,
    ])
  );
  return searchParams.toString();
};

export const getUnhealthySearchParamsForNodes = (): string => {
  const searchParams = new URLSearchParams();
  searchParams.set(
    INSPECTION_STATUS_PARAM_KEY,
    JSON.stringify([NodeStatuses.notReady])
  );
  return searchParams.toString();
};

export const getUnhealthySearchParamsForResource = (
  type: ResourceType
): string => {
  switch (type) {
    case "nodes":
      return getUnhealthySearchParamsForNodes();
    case "pods":
      return getUnhealthySearchParamsForPods();
  }
  return "";
};
