import { useMemo } from "react";

import { useAgentServicePolling } from "@/shared/hooks/agents-api/agents/useAgentsPolling";

export const useIsAgentConnected = (): boolean | null => {
  const { data } = useAgentServicePolling(1000 * 15); // 15 seconds
  return useMemo(() => {
    if (!data) return null;
    return data.activeAgents.length !== 0 || data.inactiveAgents.length !== 0;
  }, [data]);
};
