import { useQuery } from "@tanstack/react-query";

import {
  COST_RIGHT_SIZING_ROWS_ENDPOINT,
  EndpointRequestPathMap,
} from "../requestResponseMaps";
import {
  CostApiApiV1CostRightSizingRowsGetRequest,
  RightSizingCostRowsResponse,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

type UseGetRightSizingRowsParams = {
  params: CostApiApiV1CostRightSizingRowsGetRequest;
  pageIndex: number;
  enabled?: boolean;
};

export const useGetRightSizingRows = ({
  params,
  pageIndex,
  enabled,
}: UseGetRightSizingRowsParams) => {
  const apiClient = useMetricsApiClient();
  const url = EndpointRequestPathMap[COST_RIGHT_SIZING_ROWS_ENDPOINT](
    params,
    apiClient.defaults.baseURL ?? ""
  );

  return useQuery(
    [COST_RIGHT_SIZING_ROWS_ENDPOINT, params, pageIndex],
    async () => await apiClient.get<RightSizingCostRowsResponse>(url),
    { enabled: enabled }
  );
};
