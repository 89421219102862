import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";
import React from "react";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  text-align: center;
`;

const ERROR_COLOR = muiColors.gray[700];
export const FetchError: React.FC = () => (
  <ErrorContainer>
    <AlertCircleOutlined24 color={ERROR_COLOR} />
    <Typography variant="body2" color={ERROR_COLOR}>
      There was an error loading the data
    </Typography>
  </ErrorContainer>
);
