import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import {
  DeleteWorkspaceResponse,
  WorkspacesApiApiV1WorkspacesIdDeleteRequest,
  apiV1WorkspacesIdDeleteUrl,
} from "../../../../generated/workspacesApi";

import { WORKSPACES_PATH } from "./useGetWorkspaces";

const deleteWorkspace = async (
  apiClient: AxiosInstance,
  body: WorkspacesApiApiV1WorkspacesIdDeleteRequest
) => {
  const { data } = await apiClient.delete<DeleteWorkspaceResponse>(
    apiV1WorkspacesIdDeleteUrl(body, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useDeleteWorkspace = () => {
  const apiClient = useWorkspacesApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: WorkspacesApiApiV1WorkspacesIdDeleteRequest) =>
      deleteWorkspace(apiClient, body),
    onSuccess: () => queryClient.invalidateQueries([WORKSPACES_PATH]),
  });
};
