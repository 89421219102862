export const getRelativeDiff = (
  firstValue: number,
  secondValue: number
): { percentageChange: string; isIncreased: boolean } => {
  if (firstValue === 0) {
    return {
      percentageChange: "0",
      isIncreased: true,
    };
  }

  const precentageChange = ((secondValue - firstValue) / firstValue) * 100;
  return {
    percentageChange: Math.round(Math.abs(precentageChange)).toString(),
    isIncreased: precentageChange > 0,
  };
};
