import { useMemo } from "react";

import { ServiceInfo } from "../../shared/types/ServiceInfo";

export const useTextFilteredServices = (
  services: ServiceInfo[] | undefined,
  term: string | null
): ServiceInfo[] | undefined => {
  return useMemo(() => {
    if (!term) {
      return services;
    }
    const lowerCaseTerm = term.toLowerCase();
    return services?.filter((serviceInfo) => {
      const titleAndTags = [
        serviceInfo.title.toLowerCase() ?? "",
        ...serviceInfo.tags.map((tag) => `${tag.label}: ${tag.value}`),
      ];
      return titleAndTags.some((t) => t.includes(lowerCaseTerm));
    });
  }, [services, term]);
};
