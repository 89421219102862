import React, { PropsWithChildren, useContext, useState } from "react";

type AppViewActionsState = {
  deletionDialogOpen: boolean;
  setDeletionDialogOpen: (open: boolean) => void;
};

const initialAppViewActionsState = {
  deletionDialogOpen: false,
  setDeletionDialogOpen: () => undefined,
};

const AppViewActionsStateContext = React.createContext<AppViewActionsState>(
  initialAppViewActionsState
);

export const AppViewActionsStateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);

  return (
    <AppViewActionsStateContext.Provider
      value={{ deletionDialogOpen, setDeletionDialogOpen }}
    >
      {children}
    </AppViewActionsStateContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useAppViewActionsStateContext = (): AppViewActionsState =>
  useContext(AppViewActionsStateContext);
