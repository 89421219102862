import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { Close24 } from "@komodorio/design-system/icons";

import { dispatchEvent } from "../../../shared/hooks/analytics";
import {
  CheckCategory,
  CheckRawReultWithSpecLevel,
  StaticPreventionBasicConfiguration,
  StaticPreventionResult,
} from "../StaticPreventionUtils";
import { useCreateWorkflowConfigurationMutation } from "../../../generated/graphql";
import { WorkflowTypeOutsideWorkflowTab } from "../../monitorsView/common/types";
import { getStaticPreventionConfigurationVariables } from "../useStaticPreventionWorkflow";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { DeployEventsResponse } from "../../../generated/resourcesApi";

import StaticPreventionModalContent from "./StaticPreventionModalContent";
import { StaticPreventionTitle } from "./StaticPreventionTitle";

export const ModalInsetFromTop = "130px";

const TopContainer = styled.div`
  display: grid;
  padding: 1rem 2rem;
  height: 690px;
  width: 1024px;
  grid-template-rows: 1.2fr 12fr;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface StaticPreventionModalProps {
  cluster: string;
  namespace: string;
  name: string;
  serviceName: string;
  isOpen: boolean;
  modalCloseCallback: () => void;
  parsedResults: StaticPreventionResult;
  workflowConfiguration: StaticPreventionBasicConfiguration;
  configurationName: string;
  refreshConfiguration: () => void;
  lastDeploy: DeployEventsResponse;
  defaultFilter?: CheckCategory;
}
const StaticPreventionModal: React.FC<StaticPreventionModalProps> = ({
  cluster,
  namespace,
  name,
  serviceName,
  isOpen,
  modalCloseCallback,
  parsedResults,
  workflowConfiguration,
  configurationName,
  refreshConfiguration,
  lastDeploy,
  defaultFilter,
}) => {
  const [, createWorkflowConfiguration] =
    useCreateWorkflowConfigurationMutation();

  const configData = useMemo(
    () => ({
      id: workflowConfiguration.id,
      name: configurationName,
      sensors: [
        {
          service: serviceName,
        },
      ],
      type: WorkflowTypeOutsideWorkflowTab.StaticPrevention,
    }),
    [configurationName, serviceName, workflowConfiguration.id]
  );
  const ignoreCallback = useCallback(
    (check: CheckRawReultWithSpecLevel, expirationDate: string) => {
      if (!configData) return;
      const variables = getStaticPreventionConfigurationVariables(
        check,
        true,
        workflowConfiguration,
        expirationDate
      );
      createWorkflowConfiguration({ ...configData, variables });
      dispatchEvent(AnalyticEvents.ServiceView.StaticPrevention.Ignore, {
        checkID: check.ID,
      });
      refreshConfiguration();
    },
    [
      configData,
      workflowConfiguration,
      createWorkflowConfiguration,
      refreshConfiguration,
    ]
  );
  const unignoreCallback = useCallback(
    (check: CheckRawReultWithSpecLevel) => {
      if (!configData) return;
      const variables = getStaticPreventionConfigurationVariables(
        check,
        false,
        workflowConfiguration
      );
      createWorkflowConfiguration({ ...configData, variables });
      dispatchEvent(AnalyticEvents.ServiceView.StaticPrevention.Unignore, {
        checkID: check.ID,
      });
      refreshConfiguration();
    },
    [
      configData,
      createWorkflowConfiguration,
      refreshConfiguration,
      workflowConfiguration,
    ]
  );

  return (
    <Dialog open={isOpen} maxWidth={false} onClose={modalCloseCallback}>
      <TopContainer data-e2e-selector={"best-practices-modal"}>
        <HeaderContainer>
          <StaticPreventionTitle
            cluster={cluster}
            namespace={namespace}
            name={name}
          />
          <ActionsContainer>
            <IconButton onClick={modalCloseCallback}>
              <Close24 color={muiColors.gray[700]} />
            </IconButton>
          </ActionsContainer>
        </HeaderContainer>
        <StaticPreventionModalContent
          parsedResults={parsedResults}
          ignoreCallback={ignoreCallback}
          unignoreCallback={unignoreCallback}
          lastDeploy={lastDeploy}
          defaultFilter={defaultFilter}
        />
      </TopContainer>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StaticPreventionModal;
