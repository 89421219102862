import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { GET_VIOLATIONS_PATH } from "../../../shared/hooks/reliability-api/violations/useGetViolations";
import { GET_GROUPS_PATH } from "../../../shared/hooks/reliability-api/groups/useGetGroups";
import { GET_VIOLATIONS_FILTER_PATH } from "../../../shared/hooks/reliability-api/filters/useGetViolationsFilter";

export const useClearViolationsCache = () => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries([GET_VIOLATIONS_PATH]);
    await queryClient.invalidateQueries([GET_GROUPS_PATH]);
    await queryClient.invalidateQueries([GET_VIOLATIONS_FILTER_PATH]);
  }, [queryClient]);
};
