import DeployEventGroup from "../../../../common/EventGroup/deployEvent/DeployEventGroup";

export const getServiceIdFromDeployEventGroup = (
  deployEvent: DeployEventGroup
): string | undefined => {
  const { clusterName, namespace, serviceId } = deployEvent.events[0];
  const slicedServiceId = serviceId.split(`${namespace}.`).at(-1);
  return slicedServiceId
    ? `${clusterName}/${namespace}/${slicedServiceId}`
    : undefined;
};
