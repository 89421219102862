import { theme } from "@komodorio/design-system";
import {
  ActionItem,
  IconButton,
  MenuList,
  Popover,
  Typography,
} from "@komodorio/design-system/deprecated";
import {
  Close24,
  Cog16,
  Ellipsis16,
  InfoCircleOutlined16,
} from "@komodorio/design-system/icons";
import React, { useState } from "react";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";

import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import { DEFAULT_TOOLTIP } from "../../../../../../../../shared/constants/tooltipIds";
import { WorkflowRunWithResults } from "../../../../../../../monitorsView/useWorkflowsRuns";
import { OpinionatedSummary } from "../../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/AvailabilitySummaryUtils";
import { IssueStatusTag } from "../IssueStatusTag";
import { Header, Section, Title } from "../styles";
import {
  IssueReasonTooltip,
  ISSUE_REASON_TOOLTIP,
} from "../../tooltips/IssueReasonTooltip";
import { ZIndex } from "../../../../../../../../constants/zIndex";
import { WORKFLOW_CONFIG_TYPES } from "../../../../../../EventGroup/workflowIssues/constants";

export const MANAGE_MONITORS_DENIED_MESSAGE =
  "You do not have permissions to edit Komodor monitors";

const Reason = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

const IconTooltipWrapper = styled(TooltipWrapper)`
  height: 16px;
`;

const HeaderSectionRoot = styled(Section)`
  padding-top: 0;
`;

export const HeaderSection: React.FC<{
  wfRun: WorkflowRunWithResults;
  reasons: string[];
  secondSubtitle?: JSX.Element;
  subtitle?: string;
  canManageMonitors?: boolean;
  onEditMonitorClick?: () => void;
  onClose: () => void;
}> = ({
  wfRun,
  reasons,
  secondSubtitle,
  subtitle,
  canManageMonitors,
  onEditMonitorClick,
  onClose,
}) => {
  const [openActions, setOpenActions] = useState(false);

  const closed = !!wfRun?.closedAt;

  return (
    <HeaderSectionRoot>
      <IconButton
        icon={Close24}
        noBorder
        onClick={onClose}
        aria-label={AriaLabels.EventDrawer.CloseButton}
      />
      <Header>
        <Title>
          <Typography variant="headline" bold>
            {WORKFLOW_CONFIG_TYPES[wfRun.type]}
          </Typography>
          <Typography variant="headline">
            - {Array.from(new Set(reasons)).join(", ")}
          </Typography>
        </Title>
        <IssueStatusTag isClosed={closed} />
        {subtitle !== undefined && (
          <Typography size="medium" color={theme.foreground.fgSubtle}>
            {subtitle}
          </Typography>
        )}
      </Header>
      <div>
        <Popover
          isOpen={openActions}
          content={
            <MenuList>
              <TooltipWrapper
                content={
                  !canManageMonitors
                    ? MANAGE_MONITORS_DENIED_MESSAGE
                    : undefined
                }
                tooltipId={DEFAULT_TOOLTIP}
              >
                <ActionItem
                  disabled={!canManageMonitors}
                  icon={Cog16}
                  onClick={() => {
                    onEditMonitorClick?.();
                    setOpenActions(false);
                  }}
                  aria-label={
                    AriaLabels.AvailabilityTriage.EditMonitorActionItem
                  }
                >
                  Edit monitor
                </ActionItem>
              </TooltipWrapper>
            </MenuList>
          }
          handleClose={() => setOpenActions(false)}
          containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
        >
          <IconButton
            icon={Ellipsis16}
            size="medium"
            onClick={() => setOpenActions(true)}
          />
        </Popover>
      </div>
    </HeaderSectionRoot>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getReasonSubtitleComponentByIssueSummary = (
  opinionatedSummary: OpinionatedSummary
) => {
  return (
    <>
      {opinionatedSummary.length > 0 && (
        <Reason>
          Error Reason:
          {opinionatedSummary.map((summary, index) => (
            <Reason key={index}>
              {summary.reason}
              <IconTooltipWrapper
                tooltipId={`${summary.reason}-${ISSUE_REASON_TOOLTIP}`}
              >
                <InfoCircleOutlined16 />
              </IconTooltipWrapper>
              <IssueReasonTooltip summary={summary} />
            </Reason>
          ))}
        </Reason>
      )}
    </>
  );
};
