import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { useIsBestPracticesTab } from "../../hooks/useSelectedTab";
import { reliabilityArialLabels } from "../../reliabilityArialLabels";

import { useCheckboxOptionsHandler } from "./filtersStateHandler/useCheckboxOptionsHandler";
import { useGetCheckboxOptionsData } from "./filtersStateHandler/useGetCheckboxOptionsData";
import { useSetDefaultFiltersInUrl } from "./filtersStateHandler/useSetDefaultFiltersInUrl";
import { ResetAllButton } from "./ResetAllButton";
import { FILTERS_SECTION_WIDTH } from "./filterSectionConstants";
import { useSetDefaultFiltersOnWorkspaceChange } from "./filtersStateHandler/useSetDefaultFiltersOnWorkspaceChange";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const Container = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width}px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const useShowCategoryFilter = () => {
  const { reliabilityHealthManagement } = useOverridableFlags();
  const isBestPracticesTab = useIsBestPracticesTab();

  return reliabilityHealthManagement && !isBestPracticesTab;
};

export const FiltersSection: React.FC = () => {
  useSetDefaultFiltersOnWorkspaceChange();
  useSetDefaultFiltersInUrl();
  const impactGroupTypeOptions = useGetCheckboxOptionsData("impactGroupType");
  const statusOptions = useGetCheckboxOptionsData("status");
  const clustersOptions = useGetCheckboxOptionsData("clusterName");
  const namespaceOptions = useGetCheckboxOptionsData("namespace");
  const resourceTypeOptions = useGetCheckboxOptionsData("resourceType");
  const shortResourceNameOptions =
    useGetCheckboxOptionsData("shortResourceName");

  const showShowCategoryFilter = useShowCategoryFilter();

  const checkboxOptionsHandler = useCheckboxOptionsHandler({
    checkboxHandlerPropsList: [
      ...(showShowCategoryFilter ? [impactGroupTypeOptions] : []),
      statusOptions,
      clustersOptions,
      namespaceOptions,
      resourceTypeOptions,
      shortResourceNameOptions,
    ],
  });

  return (
    <Container
      aria-label={reliabilityArialLabels.filters.filtersContainer}
      $width={FILTERS_SECTION_WIDTH}
    >
      <HeaderContainer>
        <Typography variant={"h4"}>Filters</Typography>
        <ResetAllButton />
      </HeaderContainer>
      {checkboxOptionsHandler}
    </Container>
  );
};
