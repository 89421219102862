import { SENTRY_INTEGRATION_DOCS_LINK } from "../../../../../shared/constants/docsLinks";

export const integrationLinks = {
  sentryWeb: "https://sentry.io/settings/",
  sentryWebDocs:
    "https://docs.sentry.io/product/integrations/integration-platform/",
  annotationsGuide: SENTRY_INTEGRATION_DOCS_LINK,
};

export enum IntegrationSteps {
  SentrySetup = "Create new sentry internal integration",
  SentryConfigureTokens = "Tokens & Secrets",
}
