import { Skeleton } from "@komodorio/design-system/deprecated";
import React, { memo } from "react";
import styled from "styled-components";

import { getRandomInt } from "../../utils/helpers";

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const FilterBarLoader: React.FC = memo(() => (
  <LoaderContainer aria-busy="true" role="progressbar">
    <Item>
      <Skeleton height="0.75rem" width={`${getRandomInt(30, 70)}%`} />
      <Skeleton height="2rem" width="100%" />
      {Array.from(Array(5).keys()).map((i) => (
        <Skeleton key={i} height="0.75rem" width={`${getRandomInt(30, 90)}%`} />
      ))}
    </Item>
    <Item>
      <Skeleton height="0.75rem" width={`${getRandomInt(30, 70)}%`} />
      <Skeleton height="2rem" width="100%" />
      {Array.from(Array(5).keys()).map((i) => (
        <Skeleton key={i} height="0.75rem" width={`${getRandomInt(30, 90)}%`} />
      ))}
    </Item>
    <Item>
      <Skeleton height="0.75rem" width={`${getRandomInt(30, 70)}%`} />
      <Skeleton height="2rem" width="100%" />
      {Array.from(Array(5).keys()).map((i) => (
        <Skeleton key={i} height="0.75rem" width={`${getRandomInt(30, 90)}%`} />
      ))}
    </Item>
    <Item>
      <Skeleton height="0.75rem" width={`${getRandomInt(30, 70)}%`} />
      <Skeleton height="2rem" width="100%" />
      {Array.from(Array(5).keys()).map((i) => (
        <Skeleton key={i} height="0.75rem" width={`${getRandomInt(30, 90)}%`} />
      ))}
    </Item>
  </LoaderContainer>
));
