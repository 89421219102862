import React from "react";
import styled, { css } from "styled-components";

import { TextRegular } from "../../../../common/typography";
import { UpsideTriangle } from "../../../../common/Shape";
import Overlay from "../../../../common/Overlay";

import { StyledExternalLink } from "./styles";
import { integrationLinks } from "./utils";

const GuideContinarStyle = css`
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
  position: absolute;
  background-color: white;
  z-index: 18;
  border-radius: 0.25rem;
`;

const GuideContainer = styled.div<{ open: boolean }>`
  ${GuideContinarStyle}
  transform: translate(0, -107%);
  left: -5%;
  display: ${({ open }) => (open ? undefined : "none")};
`;

const WebhookGuideImage = styled.img<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  object-fit: contain;
`;

const Container = styled.div`
  display: grid;
  padding: 1.5rem;
  gap: 1rem;
`;

const TextWrapper = styled.div`
  max-width: 15.75rem;
  overflow-wrap: break-word;
`;

const BottomTriangle: React.FC = () => {
  return (
    <div
      style={{
        marginLeft: "2rem",
        marginBottom: "-0.9rem",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <UpsideTriangle width={"1rem"} height={"1rem"} color={"white"} />
    </div>
  );
};

export const SentryImageGuide: React.FC<{
  imageSource: string;
  maxHeight: string;
}> = ({ imageSource, maxHeight }) => {
  return (
    <>
      <Container>
        <WebhookGuideImage src={imageSource} maxHeight={maxHeight} />
        <TextWrapper>
          <TextRegular>
            Still not sure?{"  "}
            <StyledExternalLink href={integrationLinks.annotationsGuide}>
              Here’s our Sentry integration Documentation
            </StyledExternalLink>
          </TextRegular>
        </TextWrapper>
      </Container>
      <BottomTriangle />
    </>
  );
};

const GuideTooltip: React.FC<{
  show: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}> = ({ show, handleClose, children }) => {
  return (
    <>
      <Overlay
        data-testid="guide-tooltip-outside"
        open={show}
        onClick={() => {
          handleClose();
        }}
      />
      <GuideContainer open={show} data-testid="guide-tooltip">
        {children}
      </GuideContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default GuideTooltip;
