import React, { useCallback, useState } from "react";
import { Button } from "@komodorio/design-system/deprecated";

import { runDesktopAgent } from "../../../shared/context/electron/installElectronAgent";
import { useCreateInstallation } from "../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";

export const TrackButton: React.FC<{
  clusterName: string;
  validClusterName: string;
  portIndex: number | null;
}> = ({ clusterName, validClusterName, portIndex }) => {
  const { mutateAsync: createInstallation } = useCreateInstallation();
  const [executingTrack, setExecutingTrack] = useState(false);

  const onClick = useCallback(async () => {
    setExecutingTrack(true);
    await runDesktopAgent(
      createInstallation,
      clusterName,
      validClusterName,
      portIndex ?? 0
    );
  }, [createInstallation, clusterName, validClusterName, portIndex]);

  return (
    <Button
      variant="primary"
      size="small"
      onClick={onClick}
      disabled={executingTrack}
    >
      Track
    </Button>
  );
};
