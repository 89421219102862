import React, { useCallback } from "react";

import { parseKomodorUid } from "../../../../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetServiceIdFromKomodorUid } from "../../../../../../../../../../../../../shared/hooks/useGetServiceIdFromKomodorUid";
import { ServiceTextLink } from "../../../../../../../../../../../../../shared/components/ServiceTextLink";
import { useDrawersStackStore } from "../../../../../../../../../../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../../../../../../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../../../../../../../../../../shared/store/drawersStackStore/types";

export const WorkloadLink: React.FC<{ komodorUid: string }> = ({
  komodorUid,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { serviceId, serviceKind } =
    useGetServiceIdFromKomodorUid(komodorUid) ?? {};

  const { name = "" } = parseKomodorUid(komodorUid) ?? {};

  const onServiceLinkClick = useCallback(
    (serviceId: string) => {
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByServiceId,
        serviceId,
      });
    },
    [pushDrawer]
  );

  if (!serviceId || !serviceKind) return <>{name}</>;

  return (
    <ServiceTextLink
      serviceId={serviceId}
      serviceKind={serviceKind}
      serviceName={name}
      onClick={onServiceLinkClick}
    />
  );
};
