import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Cursor, Flex, Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import {
  NodeIssueDataParsed,
  NodeTerminationDataParsed,
} from "../../common/types";

import {
  NODE_TERMINATIONS_TOOLTIP,
  CorrelatedNodeTerminationsTooltip,
} from "./CorrelatedNodeTerminationsTooltip";
import {
  NODE_ISSUES_TOOLTIP,
  CorrelatedNodeIssuesTooltip,
} from "./CorrelatedNodeIssuesTooltip";

export const CorrelatedNodeEventsInsight: React.FC<{
  latestNodeIssuesInsights: NodeIssueDataParsed[];
  latestNodeTerminationsInsights: NodeTerminationDataParsed[];
  setCorrelatedEvent: (event: EventGroup | null) => void;
  startTime: Date;
  serviceId: string;
}> = ({
  latestNodeIssuesInsights,
  latestNodeTerminationsInsights,
  setCorrelatedEvent,
  startTime,
  serviceId,
}) => {
  const nodeIssuesLength = latestNodeIssuesInsights.length;
  const nodeTerminationsLength = latestNodeTerminationsInsights.length;

  return (
    <InsightContainer>
      <Insight>
        <Cursor cursor="default">Correlated node events detected -</Cursor>

        {nodeIssuesLength > 0 && (
          <TooltipWrapper tooltipId={NODE_ISSUES_TOOLTIP}>
            <Flex>
              {nodeIssuesLength}
              <DashedText>
                node issue{nodeIssuesLength > 1 ? "s" : ""}
              </DashedText>
            </Flex>
          </TooltipWrapper>
        )}
        {nodeTerminationsLength > 0 && nodeIssuesLength > 0 && ", "}
        {nodeTerminationsLength > 0 && (
          <TooltipWrapper tooltipId={NODE_TERMINATIONS_TOOLTIP}>
            <Flex>
              {nodeTerminationsLength}
              <DashedText>
                node termination{nodeTerminationsLength > 1 ? "s" : ""}
              </DashedText>
            </Flex>
          </TooltipWrapper>
        )}
      </Insight>
      <CorrelatedNodeIssuesTooltip
        nodeIssues={latestNodeIssuesInsights}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
        serviceId={serviceId}
      />
      <CorrelatedNodeTerminationsTooltip
        nodeTerminations={latestNodeTerminationsInsights}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
      />
    </InsightContainer>
  );
};
