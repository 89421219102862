import semver from "semver";

import { ClusterData } from "../../hooks/useGetAllClusters/useGetAllClusters";

import { REQUIRED_AGENT_VERSION_METRICS } from "./agent";

export const doesAgentVersionSupportMetrics = (
  cluster: ClusterData
): boolean => {
  const agentVersion = cluster.version;
  const metricsEnabled = cluster.daemonSetEnabled;
  return !!(
    metricsEnabled &&
    agentVersion &&
    semver.gte(agentVersion, REQUIRED_AGENT_VERSION_METRICS)
  );
};
