import React from "react";

import { CreationPageContextProvider } from "./context/CreationPageContext";
import { CreationPageContent } from "./CreationPageContent/CreationPageContent";

export const CreationPage: React.FC = () => {
  return (
    <CreationPageContextProvider>
      <CreationPageContent />
    </CreationPageContextProvider>
  );
};
