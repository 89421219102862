import React from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";
import { FixedWidth } from "../styles";
import { PodData, ReasonComponent } from "../../../generated/workspacesApi";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";

import { DistributionItem } from "./DistributionItem";
import { UpgradeClusterCta } from "./UpgradeCommandCta";

import { getMinDigitsWidth } from "@/components/ClustersView/utils";

const ITEM_MIN_DIGITS_WIDTH = getMinDigitsWidth(5);

const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 4px;
  padding: 8px;
`;

const Container = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const PodsGroup: React.FC<{
  pods: ReasonComponent;
  name: string;
  statusColor: string | undefined;
  titleColor?: string;
  ariaLabel?: string;
}> = ({ pods, name, statusColor, titleColor, ariaLabel }) => {
  const tooltipContent = (
    <TooltipContainer>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, gridColumn: "1 / -1" }}
        color={titleColor ?? statusColor}
      >
        {name}
      </Typography>
      {pods.reasons
        .sort((a, b) => a.reason.localeCompare(b.reason))
        .map(({ reason, count }) => (
          <>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {count.toLocaleString()}
            </Typography>
            <Typography variant="body3">{reason}</Typography>
          </>
        ))}
    </TooltipContainer>
  );

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={pods.count > 0 ? tooltipContent : undefined}
      placement="top"
    >
      <div aria-label={ariaLabel}>
        <DistributionItem statusShape="circle" color={statusColor}>
          <FixedWidth minWidth={ITEM_MIN_DIGITS_WIDTH}>
            {pods.count.toLocaleString()}
          </FixedWidth>
        </DistributionItem>
      </div>
    </Tooltip>
  );
};

export const PodsCell: React.FC<{
  pods: PodData;
  isPodsEnabled: boolean;
}> = ({ pods, isPodsEnabled }) => {
  if (!isPodsEnabled) return <UpgradeClusterCtaPods />;
  if (!pods.count) return null;

  return (
    <Container>
      <PodsGroup
        pods={pods.green}
        name="Normal"
        statusColor={muiColors.green[500]}
        titleColor={muiColors.green[700]}
        ariaLabel={AriaLabels.ClustersView.PodsCell.GreenPods}
      />
      <PodsGroup
        pods={pods.yellow}
        name="Warning"
        statusColor={muiColors.orange[400]}
        titleColor={muiColors.orange[500]}
        ariaLabel={AriaLabels.ClustersView.PodsCell.YellowPods}
      />
      <PodsGroup
        pods={pods.red}
        name="Error"
        statusColor={muiColors.red[500]}
        titleColor={muiColors.red[700]}
        ariaLabel={AriaLabels.ClustersView.PodsCell.RedPods}
      />
    </Container>
  );
};

export const UpgradeClusterCtaPods: React.FC = () => {
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.NEW_UPGRADE_COMMAND
  );
  return (
    <UpgradeClusterCta command={command} desiredFeature="pod's statuses" />
  );
};
