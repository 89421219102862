import { UserRole } from "komodor-types";
import { useCallback } from "react";

import { useAddUserMutation } from "../../../generated/graphql";
import { usePrivilegedOptions } from "../../../shared/hooks/roles/usePrivilegedOptions";

export type ActionStatusResult = {
  success: boolean;
  errorMessage: string | undefined;
  key?: string;
};

export const useAddUser = (): [
  (
    params: {
      displayName: string;
      email: string;
      rbacRoleIds: string[];
    },
    handleFailed?: (email: string) => void
  ) => Promise<ActionStatusResult>,
  boolean
] => {
  const privilegedOptions = usePrivilegedOptions();
  const [{ fetching }, addUser] = useAddUserMutation();
  return [
    useCallback(
      async (params, handleFailed) => {
        const { error, data } = await addUser(
          { ...params, role: UserRole.User },
          {
            fetchOptions: privilegedOptions?.context.fetchOptions,
          }
        );
        const res = {
          success: !!data?.createUser?.success,
          errorMessage: error?.message,
        };
        if (handleFailed && !res.success) {
          handleFailed(params.email);
        }
        return res;
      },
      [privilegedOptions?.context.fetchOptions, addUser]
    ),
    fetching,
  ];
};
