import React, { useCallback } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ClusterRoleBinding from "../../resources/clusterRoleBinding";
import { KubernetesClusterRolesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRolesResource";
import { KubernetesServiceAccountsResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceAccountsResource";
import { ResourceDrawerByData } from "../../ResourceDrawer";

import { DescribeDateItem, DescribeTextItem } from "./common/DescribeItem";
import { DescribePanel } from "./common/DescribeSection";
import { DescribeKeyValueItem } from "./common/DescribeTagListItem";
import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import {
  RoleBindingReference,
  RoleBinding as RoleBindingList,
} from "./common/RoleBindingsList";
import { useReferenceResourceDrawerData } from "./utils/useReferenceResourceDrawerData";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

interface ClusterRoleBindingDetailsTabProps {
  resource: ClusterRoleBinding;
}

export const ClusterRoleBindingDetailsTab: React.FC<
  ClusterRoleBindingDetailsTabProps
> = ({ resource }) => {
  const {
    openResourceDrawerIfNeeded,
    resourceDrawerData,
    clearResourceDrawerData,
  } = useReferenceResourceDrawerData();

  const { cluster = "", fullObj: clusterRoleBinding } = resource;

  const onPossibleClusterRoleClicked = useCallback(
    ({ name, kind }: { name: string; kind: string }) =>
      openResourceDrawerIfNeeded({
        actualResourceKind: kind,
        resourceName: name,
        targetResourceKind: KubernetesClusterRolesResource.Kind,
      }),
    [openResourceDrawerIfNeeded]
  );

  const onPossibleServiceAccountClicked = useCallback(
    ({ name, kind }: { name: string; kind: string }) =>
      openResourceDrawerIfNeeded({
        actualResourceKind: kind,
        resourceName: name,
        targetResourceKind: KubernetesServiceAccountsResource.Kind,
      }),
    [openResourceDrawerIfNeeded]
  );

  const { creationTimestamp, name, labels, annotations } =
    clusterRoleBinding.metadata ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}
      </DescribePanel>

      <RoleBindingReference
        data={[clusterRoleBinding.roleRef]}
        onRowClicked={onPossibleClusterRoleClicked}
      />

      <RoleBindingList
        data={clusterRoleBinding.subjects}
        onRowClicked={onPossibleServiceAccountClicked}
      />

      {resourceDrawerData && (
        <ResourceDrawerByData
          cluster={cluster}
          namespace=""
          resourceType={resourceDrawerData.resourceType}
          resourceName={resourceDrawerData.resourceName}
          open
          onClose={clearResourceDrawerData}
        />
      )}
    </DescribeTabContentWrapper>
  );
};
