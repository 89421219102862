import React, { useMemo } from "react";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import { DataGridProPropsWithoutDefaultValue } from "@mui/x-data-grid-pro/models/dataGridProProps";

import { useTableInitialSorting } from "../../../hooks/tableHooks";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectSetSharedCostFilters,
  selectSharedCostFilters,
} from "../../../store/costOptimizationStoreSelectors";
import {
  CsvExport,
  CsvExportProps,
} from "../../../../../shared/components/CsvExport/CsvExport";
import { DataGridContainer } from "../../styles/CostOptimizationStyledComponents";

import { CustomToolbar } from "./CustomToolbar";

import { EmptyTableResults } from "@/components/common/table/EmptyResults";

const PAGINATION_SIZE_OPTIONS = [5, 10, 20];
const DEFAULT_PAGE_SIZE = PAGINATION_SIZE_OPTIONS[1];

type DataGridTableProps = {
  rows: DataGridProPropsWithoutDefaultValue["rows"];
  columns: DataGridProPropsWithoutDefaultValue["columns"];
  csvProps: CsvExportProps;
};

export const DataGridTable: React.FC<DataGridTableProps> = ({
  rows,
  columns,
  csvProps,
}) => {
  const initialSortingField = useTableInitialSorting();
  const setSharedCostFilters = useCostOptimizationStore(
    selectSetSharedCostFilters
  );
  const { table } = useCostOptimizationStore(selectSharedCostFilters);

  const initialFilterState = useMemo<GridFilterModel>(() => {
    return table?.filter ? { items: [table.filter] } : { items: [] };
  }, [table?.filter]);

  const onFilterChange = (model: GridFilterModel): void => {
    if (!model.items[0]) {
      return setSharedCostFilters({
        table: { filter: undefined },
      });
    }
    const { field, operator, value } = model.items[0];
    setSharedCostFilters({
      table: { filter: { field, operator, value } },
    });
  };

  const resetTableFilters = () => {
    onFilterChange({
      items: [],
    });
  };

  return (
    <DataGridContainer>
      <DataGridPro
        rows={rows}
        columns={columns}
        pageSizeOptions={PAGINATION_SIZE_OPTIONS}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_PAGE_SIZE,
            },
          },
          sorting: {
            sortModel: [{ field: initialSortingField, sort: "desc" }],
          },
        }}
        onFilterModelChange={onFilterChange}
        filterModel={initialFilterState}
        slots={{
          toolbar: () => (
            <CustomToolbar
              exportButton={
                <CsvExport
                  fileName={csvProps.fileName}
                  data={csvProps.data}
                  ariaLabel={csvProps.ariaLabel}
                />
              }
              showExport={!!csvProps?.data?.length}
            />
          ),
          noRowsOverlay: () => <EmptyTableResults />,
          noResultsOverlay: () => (
            <EmptyTableResults onClearFilters={resetTableFilters} />
          ),
        }}
        pagination
      />
    </DataGridContainer>
  );
};
