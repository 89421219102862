import { KubernetesResource } from "./KubernetesResource";

export const KubernetesRolesResource: KubernetesResource = {
  NameInK8S: "roles",
  Group: "access-control",
  NavBarName: "Roles",
  PresentName: "Roles",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "Role",
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Labels", hide: true },
    { name: "Annotations", hide: true },
  ],
  hasLabels: false,
};
