import { useMemo } from "react";

import { useGetViolationData } from "../../../../../../../shared/hooks/reliability-api/violations/useGetViolationData";
import { useCurrentDrawerState } from "../../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../../shared/store/drawersStackStore/types";

export const useViolation = () => {
  const requestParams = useViolationDataRequestParams();
  return useGetViolationData(requestParams);
};

export const useViolationDataRequestParams = () => {
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();

  return useMemo(
    () => ({
      id: currentDrawerState?.violationId ?? "",
      enable: !!currentDrawerState?.violationId,
    }),
    [currentDrawerState?.violationId]
  );
};
