import styled, { css } from "styled-components";
import { palette } from "@komodorio/design-system";

import { gray12, pinkBrand, gray10 } from "../../../../Colors";
import Input from "../../../common/controls/Input";
import dropdownArrow from "../../../common/controls/Select/dropdownArrow";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 22rem auto 4rem;
  overflow-y: auto;
`;

export const RowFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const SpacedRowFieldContainer = styled(RowFieldContainer)`
  justify-content: space-between;
`;

export const Content = styled.div`
  padding: 2rem;
`;

export const StyledRowFieldContainer = styled(SpacedRowFieldContainer)`
  align-self: start;
  margin: 0.5rem 0 1.8rem;
`;

const commonStyle = css`
  font-family: Roboto;
  line-height: 20px;
  font-weight: normal;
`;
export const lightStyle = css`
  ${commonStyle}
  font-size: 14px;
`;

export const smallLightStyle = css`
  ${commonStyle}
  font-size: 12px;
`;

export const smallLightShortStyle = css`
  ${commonStyle}
  font-size: 12px;
  line-height: 16px;
`;

export const SmallLightShortText = styled.div`
  ${smallLightShortStyle}
`;

export const SmallLightShortTextSpan = styled.span`
  ${smallLightShortStyle}
`;

export const GraySmallLightShortText = styled(SmallLightShortTextSpan)`
  color: ${gray10};
`;
export const boldStyle = css`
  ${commonStyle}
  font-weight: bold;
  font-size: 14px;
`;

export const BoldStyleText = styled.div`
  ${boldStyle}
`;

export const smallBoldStyle = css`
  ${commonStyle}
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const titleStyle = css`
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
`;

export const rowContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Bar = styled.div<{
  isEnabled?: boolean;
  isValid?: boolean;
}>`
  display: flex;
  padding: 0.2rem 0.5rem;
  align-items: center;
  border: 1px solid #c9cbcf;
  border-radius: 0.25rem;
  flex-wrap: wrap;
  min-height: 1.8rem;
  cursor: pointer;
  ${dropdownArrow}
  ${({ isEnabled }) =>
    !isEnabled &&
    `border: 1px dashed #c9cbcf;
    color: ${gray12};
    pointer-events: none;
    `}
    ${({ isValid }) =>
    isValid
      ? ""
      : `border: 1px solid ${palette.pink[500]}; &:focus{border: 1px solid ${palette.pink[500]};}`}
`;

export const ListComponentWrapper = styled.div`
  position: absolute;
  z-index: 20;
  right: 0;
  left: 0;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export const WorkflowConfigModalStyle = {
  minHeight: "30rem",
  width: "37rem",
  borderRadius: "1.5rem",
  padding: "0rem",
  border: "0px",
  overflow: "hidden",
};

export const Spacer = styled.div`
  padding-bottom: 1.25rem;
`;

export const FormTextInput = styled(Input)<{
  isValid?: boolean;
  width?: string;
  height?: string;
}>`
  font-weight: normal;
  margin-inline: 0.4rem;
  ${({ isValid }) =>
    isValid
      ? ""
      : `border: 1px solid ${pinkBrand}; &:focus{border: 1px solid ${pinkBrand};}`}
  ${({ width }) => (width ? `width: ${width}rem;` : `width: 7rem;`)}
  ${({ height }) => (height ? `height: ${height}rem;` : `height: 0.75rem;`)}
`;

export const Label = styled.div`
  ${lightStyle}
`;

export const ErrorMessage = styled.div`
  ${lightStyle}
  height: 1rem;
  padding-top: 0.3rem;
  color: ${pinkBrand};
`;

export const FloatingContainer = styled.div`
  padding-top: 0.25rem;
  float: right;
`;
