import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { orderBy } from "lodash";
import { datadogRum } from "@datadog/browser-rum";

import {
  dataDogViewNames,
  KOMODOR_LOADING_TIME,
} from "../../../../../shared/constants/datadog";

interface ResourceStatus {
  status: string;
  statusMessage: string;
}

export interface Carp {
  status?: {
    phase?: string;
    message?: string;
    conditions?: {
      type: string;
      status: string;
      message: string;
      lastTransitionTime: Date;
    }[];
  };
}

export const useParseResourceStatusForHelm = (
  data: Carp,
  emptyResult: boolean,
  isSupported: boolean,
  errorMessage?: string
): ResourceStatus => {
  return useMemo(() => {
    if (!isSupported) {
      return {
        status: "Unknown",
        statusMessage: errorMessage || "",
      };
    }
    if (emptyResult) {
      return {
        status: "Not Found",
        statusMessage: errorMessage ?? "Could not find resource",
      };
    }
    if (
      data?.status?.phase &&
      ["Active", "Error"].includes(data?.status?.phase)
    ) {
      return {
        status: data?.status?.phase,
        statusMessage: data?.status?.message ?? "",
      };
    }
    if (
      !data?.status?.phase &&
      data?.status?.conditions &&
      data?.status?.conditions.length > 0
    ) {
      const availableCondition = data.status.conditions.find(
        (c) => c.type === "Available"
      );
      if (availableCondition) {
        return {
          status: availableCondition.type,
          statusMessage: availableCondition.message,
        };
      }
      const orderedConditions = orderBy(
        data.status.conditions,
        "lastTransitionTime",
        "desc"
      );
      const lastCondition = orderedConditions[orderedConditions.length - 1];
      return {
        status:
          lastCondition.status === "False"
            ? "Not" + lastCondition.type
            : lastCondition.type,
        statusMessage: lastCondition.message,
      };
    }
    return {
      status: "Exists",
      statusMessage: "",
    };
  }, [
    data?.status?.conditions,
    data?.status?.message,
    data?.status?.phase,
    emptyResult,
    errorMessage,
    isSupported,
  ]);
};

export const useReportLoadingToDatadog = (hasResourcesToLoad: boolean) => {
  const [rowLoadingState, setRowLoadingState] = useState<
    Record<number, boolean>
  >({});

  const onRowLoadingStateChange = useCallback(
    (index: number, isLoading: boolean) => {
      setRowLoadingState((prevState) => ({
        ...prevState,
        [index]: isLoading,
      }));
    },
    []
  );

  const hasReportedStartView = useRef<{
    hasResourcesToLoad: boolean | undefined;
  }>({ hasResourcesToLoad: undefined });

  const hasResourcesFetching = useMemo(() => {
    if (!hasResourcesToLoad) return false;
    const rowLoadingStateValues = Object.values(rowLoadingState);
    if (!rowLoadingStateValues.length) {
      return true;
    }
    return rowLoadingStateValues.some((v) => v);
  }, [hasResourcesToLoad, rowLoadingState]);

  useEffect(() => {
    if (
      hasReportedStartView.current.hasResourcesToLoad !== hasResourcesToLoad
    ) {
      datadogRum.startView(dataDogViewNames.resourceViewHelmTab);
      hasReportedStartView.current.hasResourcesToLoad = hasResourcesToLoad;
    }
  }, [hasResourcesToLoad]);

  useEffect(() => {
    if (!hasResourcesToLoad || !hasResourcesFetching) {
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
    }
  }, [hasResourcesFetching, hasResourcesToLoad]);

  return {
    onRowLoadingStateChange,
  };
};
