import React, { useMemo, useState } from "react";
import ShowChart from "@mui/icons-material/ShowChart";
import { muiColors } from "@komodorio/design-system";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { SectionAccordion } from "../../styles";
import { SectionAccordionSummary } from "../SectionAccordionSummary";
import {
  JobsData,
  NodeStatus,
  PodData,
  ServiceData,
} from "../../../../../generated/workspacesApi";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { Summary } from "../Summary";
import { getRoutePathFromKubernetesResource } from "../../../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { KubernetesPodsResource } from "../../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPodsResource";
import { KubernetesNodesResource } from "../../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";
import { JOBS_ROUTE, SERVICES_ROUTE } from "../../../../routes/routes";
import { UpgradeClusterCtaPods } from "../../../components/PodsCell";

import {
  INVENTORY_SECTION_DISABLED_MESSAGE,
  INVENTORY_SECTION_TITLE,
} from "./constants";
import { DistributionBox } from "./DistributionBox";
import { MetricsBox } from "./Metrics/MetricsBox";
import { ClusterMetricsContextProvider } from "./Metrics/ClusterMetricsContext";

import { useCanViewFullCluster } from "@/components/ClustersView/ClusterOverview/permissionsHooks";
import { useStoreIsFetchingForReport } from "@/components/ClustersView/ClusterOverview/useReportClusterOverviewLoadingTime";

const { Pods, Nodes, Services, Jobs } =
  AriaLabels.ClusterOverview.InventorySection.Distributions;

export const InventorySection: React.FC<{
  clusterName: string;
  pods: PodData;
  nodes: NodeStatus;
  services: ServiceData;
  jobs: JobsData;
  isClusterInactive: boolean;
  isPodsEnabled: boolean;
  shouldShowLoader?: boolean;
}> = ({
  clusterName,
  pods,
  nodes,
  services,
  jobs,
  isClusterInactive,
  isPodsEnabled,
  shouldShowLoader = false,
}) => {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();
  const { data: canViewFullCluster, isLoading: isLoadingCanViewFullCluster } =
    useCanViewFullCluster(clusterName);
  useStoreIsFetchingForReport(
    "isFetchingPermissionsForInventory",
    isLoadingCanViewFullCluster
  );

  const stats = [
    { label: "Nodes", value: nodes.count },
    { label: "Services", value: services.count },
    { label: "Jobs", value: jobs.count },
    { label: "Pods", value: isPodsEnabled ? pods.count : "N/A" },
  ];

  const nodesDistribution = useMemo(() => {
    const { ready, notReady } = nodes;
    return [
      {
        label: "Ready",
        value: ready,
        color: muiColors.green[500],
      },
      {
        label: "Not Ready",
        value: notReady,
        color: muiColors.red[500],
      },
    ];
  }, [nodes]);

  const podsDistribution = useMemo(() => {
    const { green, yellow, red } = pods;
    return [
      {
        label: "Normal",
        value: green.count,
        color: muiColors.green[500],
      },
      {
        label: "Warning",
        value: yellow.count,
        color: muiColors.orange[400],
      },
      {
        label: "Error",
        value: red.count,
        color: muiColors.red[500],
      },
    ];
  }, [pods]);

  const servicesDistribution = useMemo(() => {
    const { healthy, unhealthy } = services;
    return [
      {
        label: "Healthy",
        value: healthy.count,
        color: muiColors.green[500],
      },
      {
        label: "Unhealthy",
        value: unhealthy.count,
        color: muiColors.red[500],
      },
    ];
  }, [services]);

  const jobsDistribution = useMemo(() => {
    const { green, gray, red } = jobs;
    return [
      {
        label: "Healthy",
        value: green.count,
        color: muiColors.green[500],
      },
      {
        label: "Pending",
        value: gray.count,
        color: muiColors.gray[400],
      },
      {
        label: "Failed",
        value: red.count,
        color: muiColors.red[500],
      },
    ];
  }, [jobs]);

  const podsUpgradeCta = useMemo(() => {
    if (isPodsEnabled || pods.count > 0) {
      return undefined;
    }
    return <UpgradeClusterCtaPods />;
  }, [isPodsEnabled, pods.count]);

  const isLoading = isLoadingCanViewFullCluster || shouldShowLoader;
  const isDisabled =
    isClusterInactive || (!isLoadingCanViewFullCluster && !canViewFullCluster);
  const isExpanded = !isDisabled && expanded;

  return (
    <SectionAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={isDisabled}
    >
      <SectionAccordionSummary
        title={INVENTORY_SECTION_TITLE}
        icon={<ShowChart sx={{ color: muiColors.gray[500] }} />}
        isExpanded={isExpanded}
        isLoading={isLoading}
        isDisabled={isDisabled}
        disabledMessage={INVENTORY_SECTION_DISABLED_MESSAGE}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Summary stats={stats} isLoading={isLoading} />
        </Box>
      </SectionAccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", columnGap: "8px" }}>
          <DistributionBox
            name="Nodes"
            total={nodes.count}
            distribution={nodesDistribution}
            onClick={() =>
              navigate(
                getRoutePathFromKubernetesResource(
                  KubernetesNodesResource,
                  clusterName
                )
              )
            }
            ariaLabel={Nodes}
            isLoading={isLoading}
          />
          <DistributionBox
            name="Services"
            total={services.count}
            distribution={servicesDistribution}
            onClick={() => navigate(SERVICES_ROUTE)}
            ariaLabel={Services}
            isLoading={isLoading}
          />
          <DistributionBox
            name="Jobs"
            total={jobs.count}
            distribution={jobsDistribution}
            onClick={() => navigate(JOBS_ROUTE)}
            ariaLabel={Jobs}
            isLoading={isLoading}
          />
          <DistributionBox
            name="Pods"
            total={pods.count}
            distribution={podsDistribution}
            upgradeCta={podsUpgradeCta}
            onClick={() =>
              navigate(
                getRoutePathFromKubernetesResource(
                  KubernetesPodsResource,
                  clusterName
                )
              )
            }
            ariaLabel={Pods}
            isLoading={isLoading}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            justifyContent: "end",
          }}
          aria-label={
            AriaLabels.ClusterOverview.InventorySection.Metrics.Container
          }
        >
          <ClusterMetricsContextProvider>
            <MetricsBox clusterName={clusterName} />
          </ClusterMetricsContextProvider>
        </Box>
      </AccordionDetails>
    </SectionAccordion>
  );
};
