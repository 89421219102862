import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  CanIDoInScopeResponse,
  ApiV1AuthCanIDoInScopePostRequest,
  apiV1AuthCanIDoInPartialScopePostUrl,
} from "../../../../../generated/auth";

export const CAN_I_DO_IN_PARTIAL_SCOPE_PATH = "/can-i-do-in-partial-scope";

const canIDoInPartialScope = async (
  apiClient: AxiosInstance,
  params: ApiV1AuthCanIDoInScopePostRequest
): Promise<CanIDoInScopeResponse> => {
  const { data } = await apiClient.post(
    apiV1AuthCanIDoInPartialScopePostUrl(
      {
        apiV1AuthCanIDoInScopePostRequest: params,
      },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCanIDoInPartialScope = (
  params: ApiV1AuthCanIDoInScopePostRequest,
  options?: { enabled: boolean }
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [CAN_I_DO_IN_PARTIAL_SCOPE_PATH, params],
    () => canIDoInPartialScope(apiClient, params),
    options
  );
};
