import { useEffect, useRef } from "react";

import { useResetAllFilters } from "./useResetAllFilters";

import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "@/shared/store/appViewsStore/appViewsStore";

export const useSetDefaultFiltersOnWorkspaceChange = () => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const lastAppView = useRef<string | undefined>(selectedAppViewId);

  const resetAllFilters = useResetAllFilters();

  return useEffect(() => {
    if (lastAppView.current !== selectedAppViewId) {
      lastAppView.current = selectedAppViewId;
      resetAllFilters();
    }
  }, [selectedAppViewId, resetAllFilters]);
};
