import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeSchedulableDetails from "./detailsComponents/NodeSchedulableDetails";

interface NodeSchedulableOutput {
  taints: {
    taint: string;
    effects: string[];
  }[];
  isConditionsOk: boolean;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeSchedulableCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.icon = check.passed ? "success" : "warn";
    this.title = "Taints";
    this.description = `Taints and tolerations are a flexible way to prevent scheduling of pods on nodes or evict pods that shouldn't be running, we check for taints with 'NoSchedule' or 'NoExecute' effects which make the node unschedulable or evict pods that do not tolerate these taints.`;
    this.action = (
      <>
        We ran <GrayHighlight>kubectl describe {`<NODE>`}</GrayHighlight> to
        view the node taints.
      </>
    );
    this.question = "Is the node schedulable?";
    this.output = check.output as NodeSchedulableOutput;
    this.shortOutput = check.passed
      ? "The node has no taints"
      : this.output?.taints?.length
      ? "The node is unschedulable"
      : !this.output?.isConditionsOk
      ? "The node could become unschedulable"
      : "The node has no taints";
    this.isFinding = true;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeSchedulableDetails check={this} />;
  }
}
