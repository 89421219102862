import React from "react";

import FetchDataLoading from "../../../common/loaders/LoadingState";
import { KubernetesResource } from "../../inspectionConfiguration/SupportedResourcesTypes";
import { Panel } from "../../styles";

export const CRDsPageLoading: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  return (
    <Panel>
      <FetchDataLoading
        dataName={
          kubernetesResource.PresentName ?? kubernetesResource.NameInK8S
        }
      />
    </Panel>
  );
};
