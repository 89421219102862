import React from "react";
import { useLocation } from "react-router-dom";

import { useAppViewsStore } from "../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  appViewIdSelector,
  fetchAppViewServicesStateSelector,
} from "../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getEditAppViewRoute } from "../../utils/getRoutes";
import {
  CLUSTERS_ROUTE,
  CREATE_WORKSPACE_ROUTE,
} from "../../../../../routes/routes";

import { FetchErrorMessage } from "./FetchErrorMessage";
import { NoServicesFoundMessage } from "./NoServicesFoundMessage";

type ApplicationsScopingErrorPageProps = {
  children: React.ReactNode;
};

export const ApplicationsScopingErrorPage: React.FC<
  ApplicationsScopingErrorPageProps
> = ({ children }) => {
  const location = useLocation();

  const { noServicesFound, hasFetchError } = useAppViewsStore(
    fetchAppViewServicesStateSelector
  );
  const appViewId = useAppViewsStore(appViewIdSelector);

  const isCreationPageRoute = location.pathname.includes(
    CREATE_WORKSPACE_ROUTE
  );
  const isEditPageRoute = location.pathname.includes(
    getEditAppViewRoute(appViewId ?? "")
  );
  const isClustersPageRoute = location.pathname.includes(CLUSTERS_ROUTE);

  const shouldShowContent =
    !isCreationPageRoute && !isEditPageRoute && !isClustersPageRoute;
  if (shouldShowContent) {
    if (hasFetchError) return <FetchErrorMessage />;
    if (noServicesFound) return <NoServicesFoundMessage />;
  }

  return <>{children}</>;
};
