import { parseISO } from "date-fns";

import { Network_PoliciesFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";

import { CommonEventBase, CommonEventGroup } from "./commonEvents";
import { networkPolicyType } from "./types";

const toNetworkPolicyEvent = (
  e: Network_PoliciesFragment,
  serviceId: string
): CommonEventBase => ({
  id: e.id,
  serviceId,
  action: "",
  eventTime: parseISO(e.eventTime),
  type: networkPolicyType,
  name: e.name,
  namespace: e.namespace,
  clusterName: e.clusterName,
});

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NetworkPolicyEventGroup extends CommonEventGroup {
  constructor(e: CommonEventBase) {
    super(e, "Network Policy");
  }
}

export const groupNetworkPolicyEvents = (
  events: Network_PoliciesFragment[],
  serviceId: string
): NetworkPolicyEventGroup[] =>
  mapAndSort(withServices(events, serviceId), toNetworkPolicyEvent).map(
    (e) => new NetworkPolicyEventGroup(e)
  );
