import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { capitalize } from "lodash";
import { TooltipWrapper } from "react-tooltip";

import { H4Light, textRegularStyle, textStyle } from "../../common/typography";
import {
  CheckRawReultWithSpecLevel,
  SeverityColorMap,
  StaticPreventionSeverity,
} from "../StaticPreventionUtils";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import IgnoreIcon from "../../common/ProcessList/assets/ignore.svg?react";
import EyeIcon from "../../common/ProcessList/assets/eye.svg?react";
import CloseTabArrow from "../../common/ProcessList/assets/closeTabArrow.svg?react";
import OpenTabArrow from "../../common/ProcessList/assets/openTabArrow.svg?react";
import { blackGreyText, gray13, gray14 } from "../../../Colors";
import IgnoreTimePicker from "../IgnoreTimePicker";
import {
  buildSpecLevelAndCheckId,
  PolarisSupportedChecks,
} from "../PolarisWrapper";
import { notifyDDError } from "../../../shared/hooks/exceptionManagement";
import { useRect } from "../../../shared/hooks/useRect";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import StaticPreventionCheckContent from "./StaticPreventionCheckContent";

const SeverityContainer = styled.div<{ backgroundColor: string }>`
  ${textStyle};
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 3.25rem;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Container = styled.div`
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
`;

const StyledIgnoreIcon = styled(({ ignorePopupOpen, ...props }) => (
  <IgnoreIcon {...props} />
))<{
  ignorePopupOpen: boolean;
}>`
  border: ${gray13} 1px solid;
  border-radius: 4px;
  :hover {
    background-color: ${gray14};
    cursor: pointer;
  }
  ${({ ignorePopupOpen }) =>
    ignorePopupOpen ? `background-color: ${gray14};` : null}
`;

const LeftContainer = styled.div<{ ignorePopupOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  cursor: pointer;
  ${({ ignorePopupOpen }) => (ignorePopupOpen ? "pointer-events: none;" : null)}
`;

const MessageContainer = styled.div`
  ${textRegularStyle};
  color: ${blackGreyText};
  flex-grow: 1;
`;

const Text = styled(H4Light)`
  color: ${blackGreyText};
`;

const UnignoreButton = styled.div`
  display: flex;
  border: ${gray13} 1px solid;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 6rem;
  align-items: center;
  height: 1.75rem;
  justify-content: space-evenly;

  :hover {
    cursor: pointer;
    background-color: ${gray14};
  }
`;

const IgnoreTopContainer = styled.div`
  display: flex;
`;

const StyledOpenTabArrow = styled(OpenTabArrow)`
  padding-top: 0.5rem;
`;

const ArrowsContainer = styled.div`
  width: 14px;
  height: 14px;
`;

const StaticPreventionCheckCard: React.FC<{
  checkResult: CheckRawReultWithSpecLevel;
  uniqueSpecLevel: string;
  ignoreCallback?: (
    check: CheckRawReultWithSpecLevel,
    expirationDate: string
  ) => void;
  unignoreCallback?: (check: CheckRawReultWithSpecLevel) => void;
  ignoreTimePickerId?: string;
  setIgnoreTimePickerId?: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  checkResult,
  uniqueSpecLevel,
  ignoreTimePickerId,
  ignoreCallback,
  unignoreCallback,
  setIgnoreTimePickerId,
}) => {
  const specLevelAndCheckId = buildSpecLevelAndCheckId(
    uniqueSpecLevel,
    checkResult.ID
  );
  const updateIgnoreCallback = useCallback(
    (expiration: string) => {
      ignoreCallback?.(checkResult, expiration);
    },
    [checkResult, ignoreCallback]
  );
  const [expiration, setExpiration] = useState<string>("forever");
  const [showMore, setShowMore] = useState(false);
  const [blockRect, ref] = useRect<HTMLDivElement>(ignoreTimePickerId);
  const extendCheckContentCallback = useCallback(() => {
    setShowMore(!showMore);
    if (showMore) {
      dispatchEvent(
        AnalyticEvents.ServiceView.StaticPrevention.Extend_Check_Content,
        {
          checkId: checkResult.ID,
        }
      );
    }
  }, [checkResult.ID, showMore]);

  const hasCheckId = useMemo(() => {
    if (checkResult.ID in PolarisSupportedChecks) {
      return true;
    }
    notifyDDError(
      new Error("This check doesn't support in static prevention polaris")
    );
    return false;
  }, [checkResult.ID]);
  if (!hasCheckId) {
    return null;
  }

  const severity = unignoreCallback
    ? StaticPreventionSeverity.ignored
    : checkResult.Success
    ? StaticPreventionSeverity.passed
    : checkResult.Severity;

  const severityTitle =
    severity === StaticPreventionSeverity.danger
      ? StaticPreventionSeverity.critical
      : severity;

  return (
    <>
      <Container
        ref={ref}
        data-e2e-selector={"best-practices-modal-check-card"}
      >
        <LeftContainer
          ignorePopupOpen={ignoreTimePickerId !== ""}
          onClick={extendCheckContentCallback}
        >
          <ArrowsContainer>
            {showMore ? <StyledOpenTabArrow /> : <CloseTabArrow />}
          </ArrowsContainer>
          <SeverityContainer backgroundColor={SeverityColorMap[severity]}>
            {capitalize(severityTitle)}
          </SeverityContainer>
          <MessageContainer>
            {PolarisSupportedChecks[checkResult.ID].title}
          </MessageContainer>
        </LeftContainer>
        {unignoreCallback ? (
          <UnignoreButton onClick={() => unignoreCallback(checkResult)}>
            <EyeIcon />
            <Text>Unignore</Text>
          </UnignoreButton>
        ) : (
          <IgnoreTopContainer
            data-e2e-selector={"best-practices-modal-check-card-ignore"}
          >
            <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content="Ignore">
              <StyledIgnoreIcon
                ignorePopupOpen={ignoreTimePickerId === specLevelAndCheckId}
                onClick={() => {
                  ignoreTimePickerId === specLevelAndCheckId
                    ? setIgnoreTimePickerId?.("")
                    : setIgnoreTimePickerId?.(specLevelAndCheckId);
                }}
              />
            </TooltipWrapper>
            {ignoreTimePickerId === specLevelAndCheckId &&
              setIgnoreTimePickerId &&
              blockRect && (
                <IgnoreTimePicker
                  blockRectY={blockRect.y}
                  expiration={expiration}
                  setExpiration={setExpiration}
                  setIgnoreTimePickerId={setIgnoreTimePickerId}
                  ignoreCallback={updateIgnoreCallback}
                />
              )}
          </IgnoreTopContainer>
        )}
      </Container>
      {showMore && <StaticPreventionCheckContent checkId={checkResult.ID} />}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StaticPreventionCheckCard;
