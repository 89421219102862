import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { ArrowRight16 } from "@komodorio/design-system/icons";

import { formatNumber } from "../../../../../shared/utils/formatNumber";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrowRight16 = styled(ArrowRight16)`
  width: 12px;
  height: 12px;
  margin: 0 7px;
`;

type ValueToValueCellProps = {
  value1: number;
  value2: number;
  isAbbreviated?: boolean;
};

const shouldShowNoChange = (value1: number, value2: number) => {
  return value1 <= value2;
};

const NoChange: React.FC = () => (
  <Typography variant="body2" color={muiColors.gray[600]}>
    no change
  </Typography>
);

export const ValueToValueCell: React.FC<ValueToValueCellProps> = ({
  value1,
  value2,
  isAbbreviated = false,
}) => {
  const numberOptions = useMemo(() => {
    return isAbbreviated
      ? {
          maximumFractionDigits: 0,
        }
      : undefined;
  }, [isAbbreviated]);
  if (shouldShowNoChange(value1, value2)) return <NoChange />;

  return (
    <Container>
      <Typography color={muiColors.gray[600]} variant="body2">
        {formatNumber(value1)}
      </Typography>
      <StyledArrowRight16 color={muiColors.teal[700]} />
      <Typography color={muiColors.gray[900]} variant="body2">
        {formatNumber(value2, numberOptions)}
      </Typography>
    </Container>
  );
};
