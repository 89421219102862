import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  apiV1WorkloadsPodsNativeEventsSearchGetUrl,
  PodNativeEventsResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const WORKLOADS_PODS_NATIVE_EVENT_SEARCH_PATH =
  "/workloads/pods/native-events/search";

const fetchNativePodEvents = async (
  apiClient: AxiosInstance,
  params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest
): Promise<PodNativeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsPodsNativeEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetNativePodEvents = (
  params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_PODS_NATIVE_EVENT_SEARCH_PATH, params],
    () => fetchNativePodEvents(apiClient, params),
    {
      enabled,
    }
  );
};
