import React from "react";

import { useEditYamlByResource } from "../../../../../../../../../shared/hooks/useEditYamlByResource";
import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { useHpaReachedMaxContext } from "./context/useHpaReachedMaxContext";
import { StyledButtonWithinList } from "./hpaReachedMaxStyles";

const EditYamlButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <StyledButtonWithinList variant="outlined" onClick={onClick}>
    Edit resources
  </StyledButtonWithinList>
);

export const EditResourcesButton: React.FC = () => {
  const { serviceKomodorUid } = useHpaReachedMaxContext();

  const { kind, cluster, namespace, name } =
    parseKomodorUid(serviceKomodorUid ?? "") ?? {};

  return useEditYamlByResource({
    kind,
    clusterName: cluster,
    namespace,
    name,
    EditYamlButton,
  });
};
