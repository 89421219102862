import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { KOMODOR_LOADING_TIME } from "../../../../shared/constants/datadog";
import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";

type ReportManifestTabLoadingToDatadogParams = {
  viewName: string;
  divRef: RefObject<HTMLDivElement>;
  compareToRevision:
    | {
        revision: string;
      }
    | undefined;
};

export const useReportTabLoadingToDatadog = ({
  divRef,
  compareToRevision,
  viewName,
}: ReportManifestTabLoadingToDatadogParams) => {
  const isDisplayed = useIsElementInViewport({ ref: divRef });
  const [isFetching, setIsFetching] = useState(false);
  const revisionNum = useRef<null | undefined | string>(null);

  const onFetchingStateChange = useCallback((value: boolean) => {
    setIsFetching(value);
  }, []);

  useEffect(() => {
    if (!isDisplayed) {
      revisionNum.current = null;
      return;
    }
    if (compareToRevision?.revision !== revisionNum.current) {
      revisionNum.current = compareToRevision?.revision;
      datadogRum.startView(viewName);
      if (!revisionNum.current) {
        datadogRum.addTiming(KOMODOR_LOADING_TIME);
      }
      return;
    }

    if (!isFetching) {
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
    }
  }, [compareToRevision?.revision, isDisplayed, isFetching, viewName]);

  return {
    onFetchingStateChange,
  };
};
