import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacUserRole,
  apiV1RbacUserRolesPutUrl,
  UpdateRbacUserRoleRequest,
} from "../../../../../generated/auth";

const updateRbacUserRole = async (
  apiClient: AxiosInstance,
  params: UpdateRbacUserRoleRequest
): Promise<RbacUserRole> => {
  const { data } = await apiClient.put(
    apiV1RbacUserRolesPutUrl(
      {
        updateRbacUserRoleRequest: params,
      },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useUpdateRbacUserRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation((params: UpdateRbacUserRoleRequest) =>
    updateRbacUserRole(apiClient, params)
  );
};
