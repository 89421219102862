import { ResourceTableModelRow } from "komodor-types/build/entities/AgentTask";
import { intersection, union } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { InspectionResourceListResults } from "./useParseResourceList";

export const filterResultsListByColumn = (
  output: InspectionResourceListResults[],
  filterValues: string[],
  filterIndex: number
): InspectionResourceListResults[] => {
  return output.filter((result) =>
    filterValues.includes(result.data[filterIndex])
  );
};

export const filterResourceTableByColumn = (
  rows: ResourceTableModelRow[],
  filterValues: string[],
  keyName: string
): ResourceTableModelRow[] => {
  rows = rows.filter((result) => filterValues.includes(result[keyName]));
  return rows;
};

export const filterResourceTableByLabels = (
  rows: ResourceTableModelRow[],
  labelValues: string[],
  matchExactly: boolean
): ResourceTableModelRow[] => {
  if (!rows.every((row) => row.labels)) return rows;
  if (matchExactly) {
    rows = rows.filter((row) => {
      const labels = row.labels.split(",");
      return union(labels, labelValues).length <= labels.length;
    });
    return rows;
  }
  rows = rows.filter(
    (row) => intersection(row.labels.split(","), labelValues).length > 0
  );
  return rows;
};

export const filterResourceTableBySearchTerm = (
  rows: ResourceTableModelRow[],
  searchTerm: string
): ResourceTableModelRow[] =>
  rows.filter((row) =>
    Object.values(row).some((value) => {
      return (
        typeof value === "string" &&
        value?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
  );
