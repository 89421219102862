import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";
import { Skeleton } from "@komodorio/design-system/deprecated";

const StyledSkeleton = styled(Skeleton)`
  && {
    border-radius: 4px;
  }
`;

const SkeletonWithMargin = styled(StyledSkeleton)`
  margin-top: 16px;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export type SkeletonFormation =
  | "oneDescriptionLine"
  | "twoDescriptionLines"
  | "oneDescriptionLineAndGraph"
  | "twoDescriptionLinesAndGraph";

type SkeletonId = "title" | "small" | "medium" | "large";

const skeletonFormations: Record<SkeletonFormation, SkeletonId[]> = {
  oneDescriptionLine: ["title", "medium"],
  twoDescriptionLines: ["title", "medium", "small"],
  oneDescriptionLineAndGraph: ["title", "medium", "large"],
  twoDescriptionLinesAndGraph: ["title", "medium", "small", "large"],
};

const skeletonsDictionary: Record<SkeletonId, ReactElement> = {
  title: (
    <StyledSkeleton
      height={"10px"}
      width={"120px"}
      key="InsightCardSkeleton-title"
    />
  ),
  small: (
    <StyledSkeleton
      height={"14px"}
      width={"120px"}
      key="InsightCardSkeleton-small"
    />
  ),
  medium: (
    <StyledSkeleton
      height={"14px"}
      width={"210px"}
      key="InsightCardSkeleton-medium"
    />
  ),
  large: (
    <SkeletonWithMargin
      height={"45px"}
      width={"230px"}
      key="InsightCardSkeleton-large"
    />
  ),
};

type InsightCardSkeletonProps = {
  formation: SkeletonFormation;
};

export const InsightCardSkeleton: React.FC<InsightCardSkeletonProps> = ({
  formation,
}: InsightCardSkeletonProps) => {
  const content = useMemo(() => {
    const formationArray = skeletonFormations[formation];
    return formationArray.map((num) => {
      const entry = num as keyof typeof skeletonsDictionary;
      return skeletonsDictionary[entry];
    });
  }, [formation]);

  return <SkeletonContainer>{content}</SkeletonContainer>;
};
