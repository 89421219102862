// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Payload, MetricsTooltipProps } from "./Tooltip";

export const isTooltipDataValid = (props: MetricsTooltipProps): boolean => {
  const { payload, label } = props;
  return (
    label != null &&
    payload != null &&
    payload.length > 0 &&
    !!(payload[0] as Payload)?.payload
  );
};

export const isTooltipValueValid = (value: string): boolean => {
  const withoutCommas = value.replaceAll(",", "");
  return !isNaN(Number(withoutCommas));
};
