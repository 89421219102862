import React from "react";
import { muiColors, palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { outlinedInputClasses } from "@mui/material";
import styled from "styled-components";
import { Status } from "@komodorio/design-system/komodor-ui";

import { containerLogs } from "./types";

const StyledSelect = styled(Select)`
  && {
    .MuiSelect-select {
      padding: 0.5rem 0.75rem;
      max-width: 13.5rem;
    }
    & .${outlinedInputClasses.notchedOutline} {
      border: 1px solid ${muiColors.gray[500]};
    }
    &:hover .${outlinedInputClasses.notchedOutline} {
      border: 1px solid ${muiColors.gray[500]};
    }
    &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
      border: 1px solid ${muiColors.gray[500]};
    }
  }
`;

const ContainerMenuItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const StyledSelectTypography = styled(Typography).attrs({
  variant: "body2",
})`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MenuItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 3rem;
  width: 100%;
  align-items: center;
`;
const MenuItemColumn = styled.div`
  justify-self: end;
  && {
    .MuiSelect-select {
      padding: 0.5rem 0.75rem;
      max-width: 13.5rem;
    }
  }
`;

export const PodsSelect: React.FC<{
  selectedValue: string | null;
  setValue: (value: string | null, replace?: boolean | undefined) => void;
  children?: React.ReactNode;
}> = ({ selectedValue, setValue, children }) => {
  return (
    <StyledSelect
      onChange={(event) => {
        const value = event.target.value;

        if (typeof value === "string") {
          setValue(value);
        }
      }}
      MenuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        MenuListProps: {
          sx: {
            paddingBottom: "0",
          },
        },
      }}
      value={selectedValue}
      renderValue={() => (
        <StyledSelectTypography>{selectedValue}</StyledSelectTypography>
      )}
    >
      {children}
    </StyledSelect>
  );
};
export const ContainersSelect: React.FC<{
  selectedValue: string | null;
  setValue: (value: string | null, replace?: boolean) => void;
  containers: containerLogs[];
  isHealthy: boolean;
  children?: React.ReactNode;
}> = ({ selectedValue, setValue, containers, isHealthy, children }) => {
  return (
    <StyledSelect
      MenuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
      }}
      onChange={(event) => {
        const value = event.target.value;
        if (typeof value === "string") {
          const container =
            containers.find(
              (c) => c.containerName === value && c.isLogsExist
            ) ?? containers.find((c) => c.isLogsExist);
          setValue(container?.containerName ?? null);
        }
      }}
      value={selectedValue}
      renderValue={() => (
        <ContainerMenuItemDiv>
          <StyledSelectTypography>{selectedValue}</StyledSelectTypography>
          {!isHealthy && (
            <Status color={"warning"} label={"Failed container"}></Status>
          )}
        </ContainerMenuItemDiv>
      )}
    >
      {children}
    </StyledSelect>
  );
};
export const PodMenuItem: React.FC<{
  podName: string;
  phase: string;
  unhealthyContainersNumber: number;
}> = ({ podName, phase, unhealthyContainersNumber }) => {
  return (
    <MenuItemContainer>
      <Typography variant={"body1"} color={palette.black[0]}>
        {podName}
      </Typography>
      <MenuItemColumn>
        {phase && <Status color={"error"} label={phase}></Status>}
      </MenuItemColumn>
      <Typography variant="body2" color={muiColors.gray[500]}>
        {unhealthyContainersNumber} failed containers
      </Typography>
    </MenuItemContainer>
  );
};
export const ContainerMenuItem: React.FC<{
  containerName: string;
  isHealthy: boolean;
}> = ({ containerName, isHealthy }) => {
  return (
    <MenuItemContainer>
      <Typography variant={"body1"} color={palette.black[0]}>
        {containerName}
      </Typography>
      <MenuItemColumn>
        {!isHealthy && (
          <Status color={"warning"} label={"Failed container"}></Status>
        )}
      </MenuItemColumn>
    </MenuItemContainer>
  );
};
