import React from "react";
import Typography from "@mui/material/Typography";
import { uniqBy } from "lodash";

import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";
import {
  TextContainer,
  StyledUnorderedList,
} from "../../../violationDrawerStyles";

import { formatNumber } from "@/shared/utils/formatNumber";
import { RestartReason } from "@/generated/reliabilityApi";

const {
  containerRestarts: {
    description: descriptionAriaLabel,
    whyIsItImportantSection: whyIsItImportantAriaLabel,
    howToFixSection: howToFixAriaLabel,
  },
} = reliabilityArialLabels.violationDrawer;

export const getWhatDescription = (
  containersNum: number,
  totalRestarts: number
) => (
  <TextContainer aria-label={descriptionAriaLabel}>
    <b>{containersNum} containers</b> had a total of{" "}
    <b>{formatNumber(totalRestarts)} restarts</b> in the last 7 days
  </TextContainer>
);

export const getWhyDescription = (reasons: RestartReason[]) => {
  const allReasonDescriptions = reasons
    .map((reason) => getRestartReasonDescription(reason))
    .filter(Boolean);

  // Probe issues all have the same description, filter duplicates
  const uniqueDescriptions = uniqBy(allReasonDescriptions, "title");

  return (
    <div aria-label={whyIsItImportantAriaLabel}>
      <Typography variant={"body2"}>
        Container restarts can occur for various reasons, and understanding the
        reasons behind these restarts is crucial for maintaining a healthy and
        reliable application.
        <br />
      </Typography>
      <div>
        <StyledUnorderedList>
          {uniqueDescriptions.map(
            (description = { title: "", content: "" }) => (
              <li key={description.title}>
                <Typography variant={"body2"}>
                  <>
                    <b>{description.title}</b>
                    <br />
                    {description.content}
                  </>
                </Typography>
              </li>
            )
          )}
        </StyledUnorderedList>
        <Typography variant={"body2"}>
          These issues may cause unexpected downtime and degrade performance,
          impacting the overall reliability of your services.
        </Typography>
      </div>
    </div>
  );
};

export const getRestartReasonDescription = (reason: RestartReason) => {
  switch (reason) {
    case RestartReason.ProcessExit:
      return {
        title: "Application errors:",
        content:
          "Frequent container restarts due to application errors can signal bugs or inefficiencies in your codebase.",
      };
    case RestartReason.OomKilled:
      return {
        title: "OOM kill:",
        content:
          "Out of Memory (OOM) kills indicate that your container is consuming more memory than allocated.",
      };
    case RestartReason.ProbeConnectionError:
    case RestartReason.ProbeServerError:
    case RestartReason.ProbeTimeout:
    case RestartReason.GenericProbeFailure:
      return {
        title: "Probe failures:",
        content:
          "Probe failures suggest that the application within the container is unresponsive or unhealthy.",
      };
    default:
      return undefined;
  }
};

export const getHowToFixOutput = (reason: RestartReason) => {
  switch (reason) {
    case RestartReason.ProcessExit:
      return (
        <li>
          <b>Debug your application - </b>
          review logs to identify the root cause of the crashes. Look for
          uncaught exceptions, misconfigurations, or logic errors.
        </li>
      );
    case RestartReason.OomKilled:
      return (
        <li>
          <b>Increase your memory limits</b>, and try identifying{" "}
          <b>memory leaks</b> or inefficiencies in your application and refactor
          the code to reduce memory usage.
        </li>
      );
    case RestartReason.ProbeConnectionError:
      return (
        <>
          <li>
            Ensure that service endpoints are correctly configured and
            accessible. Verify DNS settings and service names.
          </li>
          <li>
            Check if network policies or firewalls are blocking connections.
            Adjust configurations to allow necessary traffic.
          </li>
          <li>
            Ensure that the service is running and listening on the expected
            ports.
          </li>
        </>
      );
    case RestartReason.ProbeServerError:
      return (
        <>
          <li>
            <b>Check application logs</b> for stack traces or error messages
            that indicate server misconfigurations or unhandled exceptions.
          </li>
          <li>
            Monitor server load - consider using load balancers or horizontal
            scaling to manage excessive traffic.
          </li>
        </>
      );
    case RestartReason.ProbeTimeout:
      return (
        <>
          <li>
            Increase the probe’s timeout (periodSeconds) to match your
            application startup times.
          </li>
          <li>
            Consider{" "}
            <a
              href={
                "https://kubernetes.io/docs/tasks/configure-pod-container/configure-liveness-readiness-startup-probes/"
              }
            >
              using startup probes
            </a>{" "}
            to avoid containers from getting killed and interfering with
            application bootstrap.
          </li>
        </>
      );
    default:
      return (
        <li>
          <b>Review your probe configuration</b> - make sure the probe settings
          are appropriate for your application’s expected startup time and
          responsiveness.
        </li>
      );
  }
};

export const getHowDescription = (reasons: RestartReason[]) => {
  return (
    <StyledUnorderedList aria-label={howToFixAriaLabel}>
      {reasons.map((reason) => (
        <React.Fragment key={reason}>
          {getHowToFixOutput(reason)}
        </React.Fragment>
      ))}
    </StyledUnorderedList>
  );
};
