import {
  Array as RTArray,
  Null,
  Number,
  Record,
  Static,
  String,
  Boolean,
} from "runtypes";

import {
  FetchAllJobsQuery,
  FetchAllServicesQuery,
  FetchJobsByPageMutation,
  FetchServicesByPageMutation,
} from "../../generated/graphql";

export enum KomodorServiceType {
  service = "service",
  job = "job",
}

export const ServiceFromHasuraActions = Record({
  agentId: String.Or(Null),
  displayName: String,
  id: String,
  k8sClusterName: String,
  kind: String.Or(Null),
  namespace: String,
  updatedAt: String,
  desiredReplicas: Number.Or(Null).optional(),
  healthStatus: String.Or(Null).optional(),
  deployStateEventTime: String.Or(Null).optional(),
  lastDeployEndTime: String.Or(Null).optional(),
  lastDeployStartTime: String.Or(Null).optional(),
  readyReplicas: Number.Or(Null).optional(),
  schedule: String.Or(Null).optional(),
  status: String.Or(Null).optional(),
  deployStateCreatedAt: String.Or(Null).optional(),
  jobState: String.Or(Null).optional(),
  isDeleted: Boolean.optional(),
});

export type serviceFromHasuraActionsType = {
  k8sClusterName?: string;
  displayName?: string;
  id?: string;
  updatedAt?: string;
  agentId: string | null;
  kind: string | null;
  desiredReplicas?: number;
  readyReplicas?: number;
  healthStatus?: string;
  lastDeployStartTime?: string;
  namespace?: string;
  lastDeployEndTime?: string;
  deployStateEventTime?: string;
  deployStateCreatedAt?: string;
  schedule?: string;
  status?: string;
  jobState?: string;
};

export type hasuraData =
  | FetchAllServicesQuery["fetchAllServices"]
  | FetchAllJobsQuery["fetchAllJobs"]
  | FetchServicesByPageMutation["fetchServicesByPage"]
  | FetchJobsByPageMutation["fetchJobsByPage"]
  | undefined;

export const servicesFromHasuraActions = RTArray(ServiceFromHasuraActions);

export type ServiceFromHasuraActions = Static<typeof ServiceFromHasuraActions>;
