/* tslint:disable */
/* eslint-disable */
/**
 * Email Digest API
 * This is an API that exposes email digest related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AvailabilityIssuesEmailVars
 */
export interface AvailabilityIssuesEmailVars {
    /**
     * 
     * @type {number}
     * @memberof AvailabilityIssuesEmailVars
     */
    'newCount': number;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityIssuesEmailVars
     */
    'totalCount': number;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityIssuesEmailVars
     */
    'viewAllLink': string;
    /**
     * 
     * @type {Array<EmailVarEventRow>}
     * @memberof AvailabilityIssuesEmailVars
     */
    'issues': Array<EmailVarEventRow>;
}


/**
 * Check if a given object implements the AvailabilityIssuesEmailVars interface.
 */
export function instanceOfAvailabilityIssuesEmailVars(value: object): value is AvailabilityIssuesEmailVars {
    if (!('newCount' in value) || value['newCount'] === undefined) return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('viewAllLink' in value) || value['viewAllLink'] === undefined) return false;
    if (!('issues' in value) || value['issues'] === undefined) return false;
    return true;
}

export function AvailabilityIssuesEmailVarsFromJSON(json: any): AvailabilityIssuesEmailVars {
    return AvailabilityIssuesEmailVarsFromJSONTyped(json, false);
}

export function AvailabilityIssuesEmailVarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityIssuesEmailVars {
    if (json == null) {
        return json;
    }
    return {
        
        'newCount': json['newCount'],
        'totalCount': json['totalCount'],
        'viewAllLink': json['viewAllLink'],
        'issues': ((json['issues'] as Array<any>).map(EmailVarEventRowFromJSON)),
    };
}

export function AvailabilityIssuesEmailVarsToJSON(value?: AvailabilityIssuesEmailVars | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newCount': value['newCount'],
        'totalCount': value['totalCount'],
        'viewAllLink': value['viewAllLink'],
        'issues': ((value['issues'] as Array<any>).map(EmailVarEventRowToJSON)),
    };
}

        
/**
 * 
 * @export
 */
export const DigestFrequency = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;
export type DigestFrequency = typeof DigestFrequency[keyof typeof DigestFrequency];


export function instanceOfDigestFrequency(value: any): boolean {
    for (const key in DigestFrequency) {
        if (Object.prototype.hasOwnProperty.call(DigestFrequency, key)) {
            if (DigestFrequency[key as keyof typeof DigestFrequency] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DigestFrequencyFromJSON(json: any): DigestFrequency {
    return DigestFrequencyFromJSONTyped(json, false);
}

export function DigestFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestFrequency {
    return json as DigestFrequency;
}

export function DigestFrequencyToJSON(value?: DigestFrequency | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface EmailDigestConfiguration
 */
export interface EmailDigestConfiguration {
    /**
     * 
     * @type {EmailDigestConfigurationScope}
     * @memberof EmailDigestConfiguration
     */
    'scope': EmailDigestConfigurationScope;
    /**
     * 
     * @type {DigestFrequency}
     * @memberof EmailDigestConfiguration
     */
    'frequency': DigestFrequency;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfiguration
     */
    'isSubscribed': boolean;
    /**
     * 
     * @type {EmailDigestConfigurationContent}
     * @memberof EmailDigestConfiguration
     */
    'content': EmailDigestConfigurationContent;
}




/**
 * Check if a given object implements the EmailDigestConfiguration interface.
 */
export function instanceOfEmailDigestConfiguration(value: object): value is EmailDigestConfiguration {
    if (!('scope' in value) || value['scope'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('isSubscribed' in value) || value['isSubscribed'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function EmailDigestConfigurationFromJSON(json: any): EmailDigestConfiguration {
    return EmailDigestConfigurationFromJSONTyped(json, false);
}

export function EmailDigestConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
        'scope': EmailDigestConfigurationScopeFromJSON(json['scope']),
        'frequency': DigestFrequencyFromJSON(json['frequency']),
        'isSubscribed': json['isSubscribed'],
        'content': EmailDigestConfigurationContentFromJSON(json['content']),
    };
}

export function EmailDigestConfigurationToJSON(value?: EmailDigestConfiguration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scope': EmailDigestConfigurationScopeToJSON(value['scope']),
        'frequency': DigestFrequencyToJSON(value['frequency']),
        'isSubscribed': value['isSubscribed'],
        'content': EmailDigestConfigurationContentToJSON(value['content']),
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationContent
 */
export interface EmailDigestConfigurationContent {
    /**
     * 
     * @type {EmailDigestConfigurationIssuesContent}
     * @memberof EmailDigestConfigurationContent
     */
    'issues': EmailDigestConfigurationIssuesContent;
    /**
     * 
     * @type {EmailDigestConfigurationIssuesContent}
     * @memberof EmailDigestConfigurationContent
     */
    'failedDeploys': EmailDigestConfigurationIssuesContent;
    /**
     * 
     * @type {EmailDigestConfigurationReliabilityContent}
     * @memberof EmailDigestConfigurationContent
     */
    'reliability': EmailDigestConfigurationReliabilityContent;
}


/**
 * Check if a given object implements the EmailDigestConfigurationContent interface.
 */
export function instanceOfEmailDigestConfigurationContent(value: object): value is EmailDigestConfigurationContent {
    if (!('issues' in value) || value['issues'] === undefined) return false;
    if (!('failedDeploys' in value) || value['failedDeploys'] === undefined) return false;
    if (!('reliability' in value) || value['reliability'] === undefined) return false;
    return true;
}

export function EmailDigestConfigurationContentFromJSON(json: any): EmailDigestConfigurationContent {
    return EmailDigestConfigurationContentFromJSONTyped(json, false);
}

export function EmailDigestConfigurationContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationContent {
    if (json == null) {
        return json;
    }
    return {
        
        'issues': EmailDigestConfigurationIssuesContentFromJSON(json['issues']),
        'failedDeploys': EmailDigestConfigurationIssuesContentFromJSON(json['failedDeploys']),
        'reliability': EmailDigestConfigurationReliabilityContentFromJSON(json['reliability']),
    };
}

export function EmailDigestConfigurationContentToJSON(value?: EmailDigestConfigurationContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issues': EmailDigestConfigurationIssuesContentToJSON(value['issues']),
        'failedDeploys': EmailDigestConfigurationIssuesContentToJSON(value['failedDeploys']),
        'reliability': EmailDigestConfigurationReliabilityContentToJSON(value['reliability']),
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationFailedDeploysContent
 */
export interface EmailDigestConfigurationFailedDeploysContent {
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationFailedDeploysContent
     */
    'isEnabled': boolean;
}


/**
 * Check if a given object implements the EmailDigestConfigurationFailedDeploysContent interface.
 */
export function instanceOfEmailDigestConfigurationFailedDeploysContent(value: object): value is EmailDigestConfigurationFailedDeploysContent {
    if (!('isEnabled' in value) || value['isEnabled'] === undefined) return false;
    return true;
}

export function EmailDigestConfigurationFailedDeploysContentFromJSON(json: any): EmailDigestConfigurationFailedDeploysContent {
    return EmailDigestConfigurationFailedDeploysContentFromJSONTyped(json, false);
}

export function EmailDigestConfigurationFailedDeploysContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationFailedDeploysContent {
    if (json == null) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
    };
}

export function EmailDigestConfigurationFailedDeploysContentToJSON(value?: EmailDigestConfigurationFailedDeploysContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isEnabled': value['isEnabled'],
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationIssuesContent
 */
export interface EmailDigestConfigurationIssuesContent {
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationIssuesContent
     */
    'isEnabled': boolean;
}


/**
 * Check if a given object implements the EmailDigestConfigurationIssuesContent interface.
 */
export function instanceOfEmailDigestConfigurationIssuesContent(value: object): value is EmailDigestConfigurationIssuesContent {
    if (!('isEnabled' in value) || value['isEnabled'] === undefined) return false;
    return true;
}

export function EmailDigestConfigurationIssuesContentFromJSON(json: any): EmailDigestConfigurationIssuesContent {
    return EmailDigestConfigurationIssuesContentFromJSONTyped(json, false);
}

export function EmailDigestConfigurationIssuesContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationIssuesContent {
    if (json == null) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
    };
}

export function EmailDigestConfigurationIssuesContentToJSON(value?: EmailDigestConfigurationIssuesContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isEnabled': value['isEnabled'],
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationReliabilityContent
 */
export interface EmailDigestConfigurationReliabilityContent {
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationReliabilityContent
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {EmailDigestConfigurationReliabilityContentViolations}
     * @memberof EmailDigestConfigurationReliabilityContent
     */
    'violations'?: EmailDigestConfigurationReliabilityContentViolations;
}


/**
 * Check if a given object implements the EmailDigestConfigurationReliabilityContent interface.
 */
export function instanceOfEmailDigestConfigurationReliabilityContent(value: object): value is EmailDigestConfigurationReliabilityContent {
    if (!('isEnabled' in value) || value['isEnabled'] === undefined) return false;
    return true;
}

export function EmailDigestConfigurationReliabilityContentFromJSON(json: any): EmailDigestConfigurationReliabilityContent {
    return EmailDigestConfigurationReliabilityContentFromJSONTyped(json, false);
}

export function EmailDigestConfigurationReliabilityContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationReliabilityContent {
    if (json == null) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'violations': json['violations'] == null ? undefined : EmailDigestConfigurationReliabilityContentViolationsFromJSON(json['violations']),
    };
}

export function EmailDigestConfigurationReliabilityContentToJSON(value?: EmailDigestConfigurationReliabilityContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isEnabled': value['isEnabled'],
        'violations': EmailDigestConfigurationReliabilityContentViolationsToJSON(value['violations']),
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationReliabilityContentViolations
 */
export interface EmailDigestConfigurationReliabilityContentViolations {
    /**
     * 
     * @type {EmailDigestConfigurationReliabilityContentViolationsSeverities}
     * @memberof EmailDigestConfigurationReliabilityContentViolations
     */
    'severities'?: EmailDigestConfigurationReliabilityContentViolationsSeverities;
}


/**
 * Check if a given object implements the EmailDigestConfigurationReliabilityContentViolations interface.
 */
export function instanceOfEmailDigestConfigurationReliabilityContentViolations(value: object): value is EmailDigestConfigurationReliabilityContentViolations {
    return true;
}

export function EmailDigestConfigurationReliabilityContentViolationsFromJSON(json: any): EmailDigestConfigurationReliabilityContentViolations {
    return EmailDigestConfigurationReliabilityContentViolationsFromJSONTyped(json, false);
}

export function EmailDigestConfigurationReliabilityContentViolationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationReliabilityContentViolations {
    if (json == null) {
        return json;
    }
    return {
        
        'severities': json['severities'] == null ? undefined : EmailDigestConfigurationReliabilityContentViolationsSeveritiesFromJSON(json['severities']),
    };
}

export function EmailDigestConfigurationReliabilityContentViolationsToJSON(value?: EmailDigestConfigurationReliabilityContentViolations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'severities': EmailDigestConfigurationReliabilityContentViolationsSeveritiesToJSON(value['severities']),
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationReliabilityContentViolationsSeverities
 */
export interface EmailDigestConfigurationReliabilityContentViolationsSeverities {
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationReliabilityContentViolationsSeverities
     */
    'high'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationReliabilityContentViolationsSeverities
     */
    'medium'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDigestConfigurationReliabilityContentViolationsSeverities
     */
    'low'?: boolean;
}


/**
 * Check if a given object implements the EmailDigestConfigurationReliabilityContentViolationsSeverities interface.
 */
export function instanceOfEmailDigestConfigurationReliabilityContentViolationsSeverities(value: object): value is EmailDigestConfigurationReliabilityContentViolationsSeverities {
    return true;
}

export function EmailDigestConfigurationReliabilityContentViolationsSeveritiesFromJSON(json: any): EmailDigestConfigurationReliabilityContentViolationsSeverities {
    return EmailDigestConfigurationReliabilityContentViolationsSeveritiesFromJSONTyped(json, false);
}

export function EmailDigestConfigurationReliabilityContentViolationsSeveritiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationReliabilityContentViolationsSeverities {
    if (json == null) {
        return json;
    }
    return {
        
        'high': json['high'] == null ? undefined : json['high'],
        'medium': json['medium'] == null ? undefined : json['medium'],
        'low': json['low'] == null ? undefined : json['low'],
    };
}

export function EmailDigestConfigurationReliabilityContentViolationsSeveritiesToJSON(value?: EmailDigestConfigurationReliabilityContentViolationsSeverities | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'high': value['high'],
        'medium': value['medium'],
        'low': value['low'],
    };
}

        /**
 * 
 * @export
 * @interface EmailDigestConfigurationScope
 */
export interface EmailDigestConfigurationScope {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDigestConfigurationScope
     */
    'clusters'?: Array<string>;
}


/**
 * Check if a given object implements the EmailDigestConfigurationScope interface.
 */
export function instanceOfEmailDigestConfigurationScope(value: object): value is EmailDigestConfigurationScope {
    return true;
}

export function EmailDigestConfigurationScopeFromJSON(json: any): EmailDigestConfigurationScope {
    return EmailDigestConfigurationScopeFromJSONTyped(json, false);
}

export function EmailDigestConfigurationScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDigestConfigurationScope {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'] == null ? undefined : json['clusters'],
    };
}

export function EmailDigestConfigurationScopeToJSON(value?: EmailDigestConfigurationScope | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
    };
}

        /**
 * 
 * @export
 * @interface EmailVarEventRow
 */
export interface EmailVarEventRow {
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVarEventRow
     */
    'link': string;
}


/**
 * Check if a given object implements the EmailVarEventRow interface.
 */
export function instanceOfEmailVarEventRow(value: object): value is EmailVarEventRow {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('serviceName' in value) || value['serviceName'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('link' in value) || value['link'] === undefined) return false;
    return true;
}

export function EmailVarEventRowFromJSON(json: any): EmailVarEventRow {
    return EmailVarEventRowFromJSONTyped(json, false);
}

export function EmailVarEventRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVarEventRow {
    if (json == null) {
        return json;
    }
    return {
        
        'duration': json['duration'] == null ? undefined : json['duration'],
        'clusterName': json['clusterName'],
        'namespace': json['namespace'],
        'serviceName': json['serviceName'],
        'description': json['description'],
        'link': json['link'],
    };
}

export function EmailVarEventRowToJSON(value?: EmailVarEventRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'duration': value['duration'],
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'serviceName': value['serviceName'],
        'description': value['description'],
        'link': value['link'],
    };
}

        /**
 * 
 * @export
 * @interface EmailVars
 */
export interface EmailVars {
    /**
     * 
     * @type {string}
     * @memberof EmailVars
     */
    'accountDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVars
     */
    'accountId': string;
    /**
     * 
     * @type {DigestFrequency}
     * @memberof EmailVars
     */
    'frequency': DigestFrequency;
    /**
     * 
     * @type {string}
     * @memberof EmailVars
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailVars
     */
    'lastDigestDate': string;
    /**
     * 
     * @type {ReliabilityEmailVars}
     * @memberof EmailVars
     */
    'reliability': ReliabilityEmailVars;
    /**
     * 
     * @type {AvailabilityIssuesEmailVars}
     * @memberof EmailVars
     */
    'availabilityIssues': AvailabilityIssuesEmailVars;
    /**
     * 
     * @type {FailedDeployEventsEmailVars}
     * @memberof EmailVars
     */
    'failedDeployEvents': FailedDeployEventsEmailVars;
}




/**
 * Check if a given object implements the EmailVars interface.
 */
export function instanceOfEmailVars(value: object): value is EmailVars {
    if (!('accountDisplayName' in value) || value['accountDisplayName'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('lastDigestDate' in value) || value['lastDigestDate'] === undefined) return false;
    if (!('reliability' in value) || value['reliability'] === undefined) return false;
    if (!('availabilityIssues' in value) || value['availabilityIssues'] === undefined) return false;
    if (!('failedDeployEvents' in value) || value['failedDeployEvents'] === undefined) return false;
    return true;
}

export function EmailVarsFromJSON(json: any): EmailVars {
    return EmailVarsFromJSONTyped(json, false);
}

export function EmailVarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVars {
    if (json == null) {
        return json;
    }
    return {
        
        'accountDisplayName': json['accountDisplayName'],
        'accountId': json['accountId'],
        'frequency': DigestFrequencyFromJSON(json['frequency']),
        'userName': json['userName'],
        'lastDigestDate': json['lastDigestDate'],
        'reliability': ReliabilityEmailVarsFromJSON(json['reliability']),
        'availabilityIssues': AvailabilityIssuesEmailVarsFromJSON(json['availabilityIssues']),
        'failedDeployEvents': FailedDeployEventsEmailVarsFromJSON(json['failedDeployEvents']),
    };
}

export function EmailVarsToJSON(value?: EmailVars | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountDisplayName': value['accountDisplayName'],
        'accountId': value['accountId'],
        'frequency': DigestFrequencyToJSON(value['frequency']),
        'userName': value['userName'],
        'lastDigestDate': value['lastDigestDate'],
        'reliability': ReliabilityEmailVarsToJSON(value['reliability']),
        'availabilityIssues': AvailabilityIssuesEmailVarsToJSON(value['availabilityIssues']),
        'failedDeployEvents': FailedDeployEventsEmailVarsToJSON(value['failedDeployEvents']),
    };
}

        /**
 * 
 * @export
 * @interface FailedDeployEventsEmailVars
 */
export interface FailedDeployEventsEmailVars {
    /**
     * 
     * @type {number}
     * @memberof FailedDeployEventsEmailVars
     */
    'newCount': number;
    /**
     * 
     * @type {number}
     * @memberof FailedDeployEventsEmailVars
     */
    'totalCount': number;
    /**
     * 
     * @type {string}
     * @memberof FailedDeployEventsEmailVars
     */
    'viewAllLink': string;
    /**
     * 
     * @type {Array<EmailVarEventRow>}
     * @memberof FailedDeployEventsEmailVars
     */
    'events'?: Array<EmailVarEventRow>;
}


/**
 * Check if a given object implements the FailedDeployEventsEmailVars interface.
 */
export function instanceOfFailedDeployEventsEmailVars(value: object): value is FailedDeployEventsEmailVars {
    if (!('newCount' in value) || value['newCount'] === undefined) return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('viewAllLink' in value) || value['viewAllLink'] === undefined) return false;
    return true;
}

export function FailedDeployEventsEmailVarsFromJSON(json: any): FailedDeployEventsEmailVars {
    return FailedDeployEventsEmailVarsFromJSONTyped(json, false);
}

export function FailedDeployEventsEmailVarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailedDeployEventsEmailVars {
    if (json == null) {
        return json;
    }
    return {
        
        'newCount': json['newCount'],
        'totalCount': json['totalCount'],
        'viewAllLink': json['viewAllLink'],
        'events': json['events'] == null ? undefined : ((json['events'] as Array<any>).map(EmailVarEventRowFromJSON)),
    };
}

export function FailedDeployEventsEmailVarsToJSON(value?: FailedDeployEventsEmailVars | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newCount': value['newCount'],
        'totalCount': value['totalCount'],
        'viewAllLink': value['viewAllLink'],
        'events': value['events'] == null ? undefined : ((value['events'] as Array<any>).map(EmailVarEventRowToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface NewViolationsChecksBreakdownInner
 */
export interface NewViolationsChecksBreakdownInner {
    /**
     * 
     * @type {string}
     * @memberof NewViolationsChecksBreakdownInner
     */
    'impactGroup': string;
    /**
     * 
     * @type {ReliabilityViolationsSeveritiesBreakdown}
     * @memberof NewViolationsChecksBreakdownInner
     */
    'severitiesBreakdown': ReliabilityViolationsSeveritiesBreakdown;
    /**
     * 
     * @type {Array<ReliabilityCheckBreakdown>}
     * @memberof NewViolationsChecksBreakdownInner
     */
    'checks': Array<ReliabilityCheckBreakdown>;
}


/**
 * Check if a given object implements the NewViolationsChecksBreakdownInner interface.
 */
export function instanceOfNewViolationsChecksBreakdownInner(value: object): value is NewViolationsChecksBreakdownInner {
    if (!('impactGroup' in value) || value['impactGroup'] === undefined) return false;
    if (!('severitiesBreakdown' in value) || value['severitiesBreakdown'] === undefined) return false;
    if (!('checks' in value) || value['checks'] === undefined) return false;
    return true;
}

export function NewViolationsChecksBreakdownInnerFromJSON(json: any): NewViolationsChecksBreakdownInner {
    return NewViolationsChecksBreakdownInnerFromJSONTyped(json, false);
}

export function NewViolationsChecksBreakdownInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewViolationsChecksBreakdownInner {
    if (json == null) {
        return json;
    }
    return {
        
        'impactGroup': json['impactGroup'],
        'severitiesBreakdown': ReliabilityViolationsSeveritiesBreakdownFromJSON(json['severitiesBreakdown']),
        'checks': ((json['checks'] as Array<any>).map(ReliabilityCheckBreakdownFromJSON)),
    };
}

export function NewViolationsChecksBreakdownInnerToJSON(value?: NewViolationsChecksBreakdownInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'impactGroup': value['impactGroup'],
        'severitiesBreakdown': ReliabilityViolationsSeveritiesBreakdownToJSON(value['severitiesBreakdown']),
        'checks': ((value['checks'] as Array<any>).map(ReliabilityCheckBreakdownToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ReliabilityCheckBreakdown
 */
export interface ReliabilityCheckBreakdown {
    /**
     * 
     * @type {string}
     * @memberof ReliabilityCheckBreakdown
     */
    'checkTypeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ReliabilityCheckBreakdown
     */
    'link': string;
}


/**
 * Check if a given object implements the ReliabilityCheckBreakdown interface.
 */
export function instanceOfReliabilityCheckBreakdown(value: object): value is ReliabilityCheckBreakdown {
    if (!('checkTypeDescription' in value) || value['checkTypeDescription'] === undefined) return false;
    if (!('link' in value) || value['link'] === undefined) return false;
    return true;
}

export function ReliabilityCheckBreakdownFromJSON(json: any): ReliabilityCheckBreakdown {
    return ReliabilityCheckBreakdownFromJSONTyped(json, false);
}

export function ReliabilityCheckBreakdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReliabilityCheckBreakdown {
    if (json == null) {
        return json;
    }
    return {
        
        'checkTypeDescription': json['checkTypeDescription'],
        'link': json['link'],
    };
}

export function ReliabilityCheckBreakdownToJSON(value?: ReliabilityCheckBreakdown | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkTypeDescription': value['checkTypeDescription'],
        'link': value['link'],
    };
}

        /**
 * 
 * @export
 * @interface ReliabilityEmailVars
 */
export interface ReliabilityEmailVars {
    /**
     * 
     * @type {ReliabilityEmailVarsNewViolations}
     * @memberof ReliabilityEmailVars
     */
    'newViolations': ReliabilityEmailVarsNewViolations;
    /**
     * 
     * @type {number}
     * @memberof ReliabilityEmailVars
     */
    'totalViolationsCount': number;
    /**
     * 
     * @type {Array<NewViolationsChecksBreakdownInner>}
     * @memberof ReliabilityEmailVars
     */
    'newViolationsChecksBreakdown': Array<NewViolationsChecksBreakdownInner>;
    /**
     * 
     * @type {string}
     * @memberof ReliabilityEmailVars
     */
    'viewAllLink': string;
}


/**
 * Check if a given object implements the ReliabilityEmailVars interface.
 */
export function instanceOfReliabilityEmailVars(value: object): value is ReliabilityEmailVars {
    if (!('newViolations' in value) || value['newViolations'] === undefined) return false;
    if (!('totalViolationsCount' in value) || value['totalViolationsCount'] === undefined) return false;
    if (!('newViolationsChecksBreakdown' in value) || value['newViolationsChecksBreakdown'] === undefined) return false;
    if (!('viewAllLink' in value) || value['viewAllLink'] === undefined) return false;
    return true;
}

export function ReliabilityEmailVarsFromJSON(json: any): ReliabilityEmailVars {
    return ReliabilityEmailVarsFromJSONTyped(json, false);
}

export function ReliabilityEmailVarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReliabilityEmailVars {
    if (json == null) {
        return json;
    }
    return {
        
        'newViolations': ReliabilityEmailVarsNewViolationsFromJSON(json['newViolations']),
        'totalViolationsCount': json['totalViolationsCount'],
        'newViolationsChecksBreakdown': ((json['newViolationsChecksBreakdown'] as Array<any>).map(NewViolationsChecksBreakdownInnerFromJSON)),
        'viewAllLink': json['viewAllLink'],
    };
}

export function ReliabilityEmailVarsToJSON(value?: ReliabilityEmailVars | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newViolations': ReliabilityEmailVarsNewViolationsToJSON(value['newViolations']),
        'totalViolationsCount': value['totalViolationsCount'],
        'newViolationsChecksBreakdown': ((value['newViolationsChecksBreakdown'] as Array<any>).map(NewViolationsChecksBreakdownInnerToJSON)),
        'viewAllLink': value['viewAllLink'],
    };
}

        /**
 * 
 * @export
 * @interface ReliabilityEmailVarsNewViolations
 */
export interface ReliabilityEmailVarsNewViolations {
    /**
     * 
     * @type {ReliabilityViolationsSeveritiesBreakdown}
     * @memberof ReliabilityEmailVarsNewViolations
     */
    'newViolationsSeveritiesBreakdown': ReliabilityViolationsSeveritiesBreakdown;
    /**
     * 
     * @type {number}
     * @memberof ReliabilityEmailVarsNewViolations
     */
    'totalCount': number;
}


/**
 * Check if a given object implements the ReliabilityEmailVarsNewViolations interface.
 */
export function instanceOfReliabilityEmailVarsNewViolations(value: object): value is ReliabilityEmailVarsNewViolations {
    if (!('newViolationsSeveritiesBreakdown' in value) || value['newViolationsSeveritiesBreakdown'] === undefined) return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    return true;
}

export function ReliabilityEmailVarsNewViolationsFromJSON(json: any): ReliabilityEmailVarsNewViolations {
    return ReliabilityEmailVarsNewViolationsFromJSONTyped(json, false);
}

export function ReliabilityEmailVarsNewViolationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReliabilityEmailVarsNewViolations {
    if (json == null) {
        return json;
    }
    return {
        
        'newViolationsSeveritiesBreakdown': ReliabilityViolationsSeveritiesBreakdownFromJSON(json['newViolationsSeveritiesBreakdown']),
        'totalCount': json['totalCount'],
    };
}

export function ReliabilityEmailVarsNewViolationsToJSON(value?: ReliabilityEmailVarsNewViolations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newViolationsSeveritiesBreakdown': ReliabilityViolationsSeveritiesBreakdownToJSON(value['newViolationsSeveritiesBreakdown']),
        'totalCount': value['totalCount'],
    };
}

        /**
 * 
 * @export
 * @interface ReliabilityViolationsSeveritiesBreakdown
 */
export interface ReliabilityViolationsSeveritiesBreakdown {
    /**
     * 
     * @type {number}
     * @memberof ReliabilityViolationsSeveritiesBreakdown
     */
    'high': number;
    /**
     * 
     * @type {number}
     * @memberof ReliabilityViolationsSeveritiesBreakdown
     */
    'medium': number;
    /**
     * 
     * @type {number}
     * @memberof ReliabilityViolationsSeveritiesBreakdown
     */
    'low': number;
}


/**
 * Check if a given object implements the ReliabilityViolationsSeveritiesBreakdown interface.
 */
export function instanceOfReliabilityViolationsSeveritiesBreakdown(value: object): value is ReliabilityViolationsSeveritiesBreakdown {
    if (!('high' in value) || value['high'] === undefined) return false;
    if (!('medium' in value) || value['medium'] === undefined) return false;
    if (!('low' in value) || value['low'] === undefined) return false;
    return true;
}

export function ReliabilityViolationsSeveritiesBreakdownFromJSON(json: any): ReliabilityViolationsSeveritiesBreakdown {
    return ReliabilityViolationsSeveritiesBreakdownFromJSONTyped(json, false);
}

export function ReliabilityViolationsSeveritiesBreakdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReliabilityViolationsSeveritiesBreakdown {
    if (json == null) {
        return json;
    }
    return {
        
        'high': json['high'],
        'medium': json['medium'],
        'low': json['low'],
    };
}

export function ReliabilityViolationsSeveritiesBreakdownToJSON(value?: ReliabilityViolationsSeveritiesBreakdown | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'high': value['high'],
        'medium': value['medium'],
        'low': value['low'],
    };
}

    
        /**
* Get a user configuration for the personal email digest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getEmailDigestConfigurationForUserUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/user/configuration`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Create or update a user configuration for the personal email digest
* @param params UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const upsertEmailDigestConfigurationForUserUrl = (params: UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest, baseUrl: string): string => {
    // verify required parameter 'body' is not null or undefined
    assertParamExists('upsertEmailDigestConfigurationForUser', 'body', params.body)
    const localVarPath = `/api/v1/user/configuration`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for upsertEmailDigestConfigurationForUser operation in UserConfigurationsApi.
* @export
* @interface UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest
*/
export interface UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest {
    /**
    * 
    * @type {EmailDigestConfiguration}
    * @memberof UserConfigurationsApiUpsertEmailDigestConfigurationForUser
    */
    readonly body: EmailDigestConfiguration
}


    
