import React, { useEffect } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { useParams } from "react-router-dom";

import {
  AvailabilityResource,
  useInvestigationModeStore,
} from "../../shared/store/investigationModeStore/investigationModeStore";
import {
  setIsFetchingKomodorServiceSelector,
  setIssueSelector,
  setIssueTypeSelector,
  setKomodorServiceSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import { useKomodorServiceAsResourceWithInterval } from "../ResourceView/useKomodorServiceAsResource";

import { SideBar } from "./SideBar";
import { Page } from "./Page";
import { HEIGHT, fullPageStyle } from "./styles";
import { useAvailabilityMonitorById } from "./availability/useAvailabilityMonitorById";
import { useCorrelatedMetrics } from "./availability/useCorrelatedMetrics";
import { useCanRollbackDeploy } from "./availability/CorrelatedDeploys/useDeploys";
import { useCanConfigureAction } from "./availability/ReviewMemoryAllocation/hooks";
import ReviewMemoryAllocationDataFetch from "./availability/ReviewMemoryAllocation/ReviewMemoryAllocationDataFetch";
import NoisyNeighborsDataFetch from "./availability/NoisyNeighbors/NoisyNeighborsDataFetch";
import { getReasonType } from "./StepsLogic";

const Layout = styled.div`
  ${fullPageStyle};
  height: ${HEIGHT};
  display: grid;
  grid-template-columns: min-content auto;
  background-color: ${palette.white[0]};
`;

export const InvestigationMode: React.FC = () => {
  const { runId = "" } = useParams<{ runId: string }>();
  const issueRun = useAvailabilityMonitorById(runId);
  const [resource, isFetching] = useKomodorServiceAsResourceWithInterval(
    issueRun?.services?.[0] ?? ""
  );

  const setIssueInStore = useInvestigationModeStore(setIssueSelector);
  const setKomodorServiceInStore = useInvestigationModeStore(
    setKomodorServiceSelector
  );
  const setIsFetchingKomodorService = useInvestigationModeStore(
    setIsFetchingKomodorServiceSelector
  );
  const setIssueTypeInStore = useInvestigationModeStore(setIssueTypeSelector);

  useEffect(() => {
    if (issueRun) {
      setIssueInStore(issueRun);
    }
  }, [issueRun, setIssueInStore]);

  useEffect(() => {
    if (resource?.kind) {
      setKomodorServiceInStore(resource as AvailabilityResource);
      setIsFetchingKomodorService(isFetching);
    }
  }, [
    isFetching,
    resource,
    setIsFetchingKomodorService,
    setKomodorServiceInStore,
  ]);

  useEffect(() => {
    if (issueRun) {
      setIssueTypeInStore(
        getReasonType(
          issueRun?.results?.introduction?.output?.firstSnapshot?.issueType,
          issueRun?.results?.introduction?.output?.firstSnapshot?.subReason
        )
      );
    }
  }, [issueRun, setIssueTypeInStore]);

  useCorrelatedMetrics(issueRun);
  useCanRollbackDeploy();
  useCanConfigureAction();

  return (
    <Layout>
      <NoisyNeighborsDataFetch issue={issueRun} />
      <ReviewMemoryAllocationDataFetch
        issue={issueRun}
        agentId={resource?.agentId}
      />
      <SideBar />
      <Page />
    </Layout>
  );
};
