import React from "react";
import { capitalize, get } from "lodash";
import { diff } from "json-diff-ts";

import { Audit } from "../../../generated/graphql";
import { AuditData } from "../types";
import {
  AuditUserAffectedResource,
  RbacUserRoleAuditDetails,
} from "../components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class UserRoleAudit implements AuditObject {
  readonly data: Audit;
  readonly auditData: AuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as AuditData;
  }

  renderDetails() {
    return <RbacUserRoleAuditDetails data={this.auditData} />;
  }

  renderAffectedResources() {
    const userId =
      this.auditData.details?.new?.userId ||
      this.auditData.details?.old?.userId;
    return (
      <AuditUserAffectedResource userId={userId as string} />
    ) as React.ReactNode;
  }

  renderAction() {
    return "Update user roles";
  }

  renderEventType() {
    return "Update";
  }

  renderJson(): Record<string, unknown> {
    const userName = get(this.data.additionalDetails, "userName");
    const roleName = get(this.data.additionalDetails, "roleName");
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": "Update",
      Resource: "User roles",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": userName,
      Change: JSON.stringify(
        diff(this.auditData.details.new, this.auditData.details.old)
          .map((o) => {
            if (o.value) {
              return {
                type: o.type,
                change: {
                  userName: userName,
                  roleName: roleName,
                },
              };
            }
            return null;
          })
          .filter((o) => o !== null)
      ),
    };
  }
}
