import { palette } from "@komodorio/design-system";
import { Tabs, Typography, Divider } from "@komodorio/design-system/deprecated";
import OpenInFull from "@mui/icons-material/OpenInFull";
import styled from "styled-components";
import React from "react";

import { AriaLabels } from "../../shared/config/ariaLabels";
import { Timeframe } from "../../shared/types/TimeWindow";

import {
  MetricsAggregationType,
  MetricsKind,
  MetricsTimeWindow,
} from "./types";
import { MetricsControls } from "./MetricsControls";

const MinimizedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const Padding = styled.div`
  padding: 1rem 1.5rem 0 1.5rem;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledOpenIcon = styled(OpenInFull)`
  && {
    width: 20px;
    color: ${palette.gray["400"]};
    cursor: pointer;
  }
  :hover {
    color: ${palette.blue["600"]};
  }
`;

const MinimizedHeaderTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledDivider = styled(Divider)`
  border-bottom: 2px solid ${palette.darkBlue[500]};
`;

const AvailabilityIssuesLabels = {
  [Timeframe.Last30Minutes]: "30 minutes",
  [Timeframe.LastHour]: "1 hour",
  [Timeframe.Last4Hours]: "4 hours",
  [Timeframe.Last24Hours]: "24 hours",
  [Timeframe.Last48Hours]: "48 hours",
  [Timeframe.Last7Days]: "7 days",
  [Timeframe.Last30Days]: "30 days",
  [Timeframe.Custom]: "Custom dates",
};

export const MetricsHeader: React.FC<{
  tabs: {
    label: MetricsKind;
  }[];
  selectedTabIndex: number | undefined;
  setSelectedTab: React.Dispatch<React.SetStateAction<MetricsKind>>;
  metricsTimeframe: Timeframe;
  onMetricsTimeframeChange: (timeframe: Timeframe | undefined) => void;
  isThereDataBefore24hours: boolean;
  aggregationValue: string;
  handleAggregationChange: (value: MetricsAggregationType) => void;
  timeWindow: MetricsTimeWindow;
}> = ({
  tabs,
  selectedTabIndex,
  setSelectedTab,
  isThereDataBefore24hours,
  aggregationValue,
  handleAggregationChange,
  metricsTimeframe,
  onMetricsTimeframeChange,
  timeWindow,
}) => {
  return (
    <Padding>
      <Header>
        <Tabs
          tabs={tabs}
          value={selectedTabIndex}
          onChange={(selectedTabIndex) => {
            setSelectedTab(tabs[selectedTabIndex].label);
          }}
        />
        <MetricsControls
          aggregationValue={aggregationValue}
          handleAggregationChange={handleAggregationChange}
          isThereDataBefore24hours={isThereDataBefore24hours}
          timeWindow={timeWindow}
          metricsTimeframe={metricsTimeframe}
          onMetricsTimeframeChange={onMetricsTimeframeChange}
          labels={AvailabilityIssuesLabels}
        />
      </Header>
    </Padding>
  );
};

export const MetricsMinimizedHeader: React.FC<{
  setMinimized: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<MetricsKind>>;
}> = ({ setMinimized, setSelectedTab }) => {
  return (
    <MinimizedHeader>
      <MinimizedHeaderTabs>
        <div>
          <Typography title="true" size="medium" style={{ padding: "10px" }}>
            {MetricsKind.Memory}
          </Typography>
          <StyledDivider />
        </div>
        <Typography
          title="true"
          size="medium"
          onClick={() => {
            setMinimized(false);
            setSelectedTab(MetricsKind.CPU);
          }}
          style={{ cursor: "pointer" }}
        >
          {MetricsKind.CPU}
        </Typography>
      </MinimizedHeaderTabs>
      <StyledOpenIcon
        onClick={() => setMinimized(false)}
        aria-label={AriaLabels.Metrics.CollapsibleGraphExtendButton}
      />
    </MinimizedHeader>
  );
};
