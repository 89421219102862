import React, { useState, useCallback, useMemo, useRef } from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { muiColors } from "@komodorio/design-system";
import { datadogRum } from "@datadog/browser-rum";

import { useRequestKubeconfig } from "../../../../shared/hooks/auth-service/client/kubeconfig/useRequestKubeconfig";
import {
  DDError,
  notifyDDError,
} from "../../../../shared/hooks/exceptionManagement";

import { AnalyticEvents } from "@/shared/config/analyticsEvents";

const HiddenLink = styled.a`
  display: none;
`;

export const StyledDownloadKubeconfigButton = styled(Button).attrs({
  variant: "contained",
  size: "medium",
  color: "primary",
  startIcon: <DownloadIcon />,
})`
  border: 1px solid ${muiColors.gray["200"]};
  border-radius: 4px;
  padding: 6px 12px;

  height: 2rem;
  width: 12rem;

  align-self: end;
`;

export const OpenKubeconfigDownloadClusterSelectionButton = styled(
  Button
).attrs({ variant: "outlined", color: "primary", size: "medium" })`
  border: 1px solid ${muiColors.gray["200"]};
  border-radius: 4px;
  padding: 6px 12px;

  height: 2rem;
  width: 8rem;

  align-self: end;
`;

const filename = "kubeconfig.yaml";
const notificationDuration = 5000;
const successNotificationMessage = "kubeconfig.yaml downloaded successfully!";
export const errorNotificationMessage =
  "Failed to download the Kubeconfig file, please try again or contact our support.";

export const DownloadKubeconfigButton: React.FC<{
  clusters: string[];
  onDownloaded: (args: { success: boolean }) => void;
}> = ({ clusters, onDownloaded }) => {
  const [noDataErr, setNoDataErr] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const {
    mutateAsync: requestKubeconfigFile,
    isLoading,
    isError,
  } = useRequestKubeconfig();

  const onDownloadClicked = useCallback(async () => {
    datadogRum.addAction(AnalyticEvents.RbacClusterSync.DownloadKubeconfig, {
      clusters,
    });

    try {
      const data = await requestKubeconfigFile({ clusters });
      if (!data) {
        setNoDataErr(true);
        return;
      }
      setNoDataErr(false);
      if (downloadLinkRef.current) {
        const blob = new Blob([data], { type: "application/x-yaml" });
        downloadLinkRef.current.href = URL.createObjectURL(blob);
        downloadLinkRef.current.download = filename;
        downloadLinkRef.current.click();
        onDownloaded({ success: true });
      }
    } catch (e) {
      notifyDDError(e as DDError, { params: { clusters } });
      onDownloaded({ success: false });
    } finally {
      setSnackbarOpen(true);
    }
  }, [clusters, requestKubeconfigFile, onDownloaded]);

  const onCloseSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, [setSnackbarOpen]);

  const snackbarAction = useMemo(() => {
    return (
      <IconButton
        size={"small"}
        aria-label={"close"}
        onClick={onCloseSnackbar}
        color={"inherit"}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }, [onCloseSnackbar]);

  return (
    <React.Fragment>
      <StyledDownloadKubeconfigButton
        disabled={isLoading || clusters.length === 0}
        endIcon={isLoading ? <CircularProgress size={14} /> : null}
        onClick={onDownloadClicked}
      >
        <HiddenLink ref={downloadLinkRef} />
        Download Kubeconfig
      </StyledDownloadKubeconfigButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={notificationDuration}
        onClose={onCloseSnackbar}
        action={snackbarAction}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={
          noDataErr || isError
            ? errorNotificationMessage
            : successNotificationMessage
        }
      />
    </React.Fragment>
  );
};
