import { useGetAllFiltersFromUrl } from "../components/FiltersSection/filtersStateHandler/useGetAllFiltersFromUrl";

import { useIsScopedFiltersMode } from "./useIsScopedFiltersMode";

export const useIsFiltersStateSet = () => {
  const isScopedFiltersMode = useIsScopedFiltersMode();
  const filters = useGetAllFiltersFromUrl();

  return (
    isScopedFiltersMode ||
    Object.values(filters).some((filter) => filter && filter.length > 0)
  );
};
