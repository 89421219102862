import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { PoliciesApiGetPolicyRequest } from "@/generated/reliabilityApi";
import { RELIABILITY_POLICIES_PATH } from "@/shared/hooks/reliability-api/policies/usePolicies";

export const useClearPolicyDataFromQueryCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (params: PoliciesApiGetPolicyRequest) => {
      queryClient.removeQueries({
        queryKey: [RELIABILITY_POLICIES_PATH, params],
        exact: true,
      });
    },
    [queryClient]
  );
};
