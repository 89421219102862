import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual } from "lodash";

import { Dictionary } from "../../../../../shared/types/Dictionary";
import { MetricsFullTimeWindow } from "../../../../Metrics/types";
import { Timeframe } from "../../../../../shared/types/TimeWindow";

export type NodeMetricsTabContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  graphLoadingState: Dictionary<boolean>;
  setGraphLoadingState: (id: string, loading: boolean) => void;
};

const initialState: NodeMetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 4)),
    end: new Date(),
    timeframe: Timeframe.Last4Hours,
  },
  setTimeWindow: () => undefined,
  graphLoadingState: {},
  setGraphLoadingState: () => undefined,
};

const NodeMetricsTabContext =
  createContext<NodeMetricsTabContextState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useNodeMetricsTabContext = (): NodeMetricsTabContextState =>
  useContext(NodeMetricsTabContext);

const {
  timeWindow: initialTimeWindow,
  graphLoadingState: initialGraphLoadingState,
} = initialState;

export const NodeMetricsTabContextProvider: React.FC<{
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
}> = ({ children, givenTimeWindow }) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );
  const [graphLoadingState, setGraphLoadingState] = useState<
    Dictionary<boolean>
  >(initialGraphLoadingState);

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
    },
    [timeWindow]
  );

  const onSetGraphLoadingState = useCallback(
    (id: string, loading: boolean) => {
      const newState = { ...graphLoadingState, [id]: loading };
      if (!isEqual(newState, graphLoadingState)) {
        setGraphLoadingState({ ...graphLoadingState, [id]: loading });
      }
    },
    [graphLoadingState]
  );

  const contextState: NodeMetricsTabContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
    graphLoadingState,
    setGraphLoadingState: onSetGraphLoadingState,
  };

  return (
    <NodeMetricsTabContext.Provider value={contextState}>
      {children}
    </NodeMetricsTabContext.Provider>
  );
};
