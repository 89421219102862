import React, { useMemo, useState } from "react";
import { diff, flattenChangeset } from "json-diff-ts";
import { muiColors, palette } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Divider,
  Modal,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { capitalize } from "lodash";
import { IFlatChange } from "json-diff-ts/lib/jsonDiff";

import { ObjectDiffAsYaml } from "../../../common/ObjectDiff";
import { Change } from "../../../common/EventGroup/deployEvent/getK8sDiff";
import Resource from "../../../ResourceView/resources";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { jsonToYaml } from "../../../../shared/utils/yaml/yaml";

import {
  DiffSection,
  SectionFooter,
  SimpleContainer,
  SectionContainer,
  SectionContent,
  SectionHeader,
} from "./styles";

import { LazyEditor } from "@/components/common/LazyEditor";

const PREVIEW_LIMIT = 3;

type K8sResourceDiffSectionProps = {
  changes: IFlatChange[];
  limit?: number;
};

const DiffEntryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 0.3rem;
  > * {
    margin: 0 0.25rem 0.25rem 0;
  }
  margin: 0 -0.25rem -0.25rem 0;
  &:nth-child(n + 2) {
    margin-block-start: 0.5rem;
  }
`;

const Label = styled.div`
  padding: 0.125rem 0.25rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const From = styled(Label)`
  background-color: ${muiColors.pink[50]};
  text-decoration-line: line-through;
`;

const To = styled(Label)`
  background-color: ${muiColors.green[50]};
`;

interface DiffEntryProps {
  change: Change;
}

export const DiffEntry: React.FC<DiffEntryProps> = ({
  change: { name, previous, current },
}) => {
  return (
    <DiffEntryContainer>
      <Typography variant={"code1"} color={muiColors.gray[600]}>
        {name}:{" "}
      </Typography>
      <Typography variant={"code1"} color={palette.black["0"]}>
        {previous && <From title={previous}>{previous}</From>}
      </Typography>
      <Typography variant={"code1"} color={palette.black["0"]}>
        {current && <To title={current}>{current}</To>}
      </Typography>
    </DiffEntryContainer>
  );
};

export const K8sDiffSection: React.FC<K8sResourceDiffSectionProps> = ({
  changes,
  limit = PREVIEW_LIMIT,
}) => {
  if (!changes.length) {
    return null;
  }

  return (
    <SimpleContainer>
      <DiffSection>
        {changes.slice(0, limit).map((c) => (
          <DiffEntry
            key={c.path}
            change={{
              name: c.key,
              previous: JSON.stringify(c.oldValue),
              current: JSON.stringify(c.value),
            }}
          />
        ))}
      </DiffSection>
    </SimpleContainer>
  );
};

export interface K8sDiffProps {
  resource: Resource;
  oldSpec: Record<string, unknown>;
  newSpec: Record<string, unknown>;
  filterChangesMethod?: (changes: IFlatChange[]) => IFlatChange[];
}

export const K8sDiff: React.FC<K8sDiffProps> = ({
  resource,
  newSpec,
  oldSpec,
  filterChangesMethod,
}) => {
  const [openDiff, setOpenDiff] = useState(false);

  const changes = flattenChangeset(diff(oldSpec, newSpec));

  const filteredChanges = useMemo(() => {
    return filterChangesMethod ? filterChangesMethod(changes) : changes;
  }, [changes, filterChangesMethod]);

  const additionalChanges = useMemo(() => {
    if (!(Object.keys(oldSpec).length && Object.keys(newSpec).length)) {
      return 0;
    }
    return changes.length > 0
      ? changes.length - Math.min(PREVIEW_LIMIT, filteredChanges.length)
      : 0;
  }, [changes.length, filteredChanges.length, newSpec, oldSpec]);

  return (
    <SectionContainer>
      <SectionHeader>
        <Typography variant={"h6"}>{resource?.name}</Typography>
        <Typography variant={"h6"} color={muiColors.gray[500]}>
          ({capitalize(resource?.kind)})
        </Typography>
      </SectionHeader>
      <SectionContent>
        {Object.keys(oldSpec).length && Object.keys(newSpec).length ? (
          <K8sDiffSection changes={filteredChanges} limit={PREVIEW_LIMIT} />
        ) : (
          <LazyEditor
            width="100%"
            height="15rem"
            readOnly
            mode="yaml"
            value={jsonToYaml(JSON.stringify(newSpec))}
          />
        )}
      </SectionContent>
      <SimpleContainer>
        <Divider />
        <SectionFooter>
          <Button
            onClick={() => setOpenDiff(true)}
            style={{ padding: 0 }}
            aria-label={
              AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
                .K8sDiffShowAllChanges
            }
          >
            {additionalChanges > 0
              ? ` + ${additionalChanges} changes `
              : "Show all changes"}
          </Button>
          <Modal isOpen={openDiff} onRequestClose={() => setOpenDiff(false)}>
            <ModalHeader />
            <ModalContent>
              <ObjectDiffAsYaml oldObj={oldSpec} newObj={newSpec} />
            </ModalContent>
          </Modal>
        </SectionFooter>
      </SimpleContainer>
    </SectionContainer>
  );
};
