export function getRandomInt(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export const median = (array: number[]): number => {
  array.sort((a, b) => b - a);
  const length = array.length;
  if (length % 2 === 0) {
    return (array[length / 2] + array[length / 2 - 1]) / 2;
  } else {
    return array[Math.floor(length / 2)];
  }
};
