import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined,
  isResourceDeleted: boolean
): IStep => {
  const reviewMemoryAllocationResult = issue?.results?.reviewMemoryAllocation;

  return {
    type: AvailabilityInvestigationCheckType.ReviewMemoryAllocation,
    visible: !!reviewMemoryAllocationResult,
    visited: false,
    disabled: isResourceDeleted,
    message: isResourceDeleted
      ? "This step is not available as this service was deleted"
      : undefined,
  };
};
