import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { ScrollArea } from "@mantine/core";

const Container = styled.div`
  flex-grow: 1;
`;

export const Body: React.FC<PropsWithChildren> = ({ children }) => (
  <ScrollArea
    style={{ height: "100%" }}
    styles={() => ({
      scrollbar: {
        "&, &:hover": {
          background: "transparent",
        },
        '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
          backgroundColor: "white",
          opacity: 0.5,
        },
      },
    })}
  >
    <Container>{children}</Container>
  </ScrollArea>
);
