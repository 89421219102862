import React, { ReactNode } from "react";
import styled from "styled-components";

import { Header, HeaderProps } from "./Header";
import { Summary, SummaryProps } from "./Summary";
import { ContentTabs } from "./ContentTabs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  header: HeaderProps;
  summary: SummaryProps;
  content: ReactNode;
}

export const DrawerBase: React.FC<Props> = ({ header, summary, content }) => {
  return (
    <Container>
      <Header {...header} />
      <Summary {...summary} />
      <ContentTabs overviewContent={content} />
    </Container>
  );
};
