import { muiTheme } from "@komodorio/design-system";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";

import { Alert } from "../../../common/Alert/Alert";
import KubernetesInstallationModal from "../../../integrations/installation/kubernetes/KubernetesInstallationModal";
import { costOptimizationAriaLabels } from "../../constants/costOptimizationAriaLabels";

import { description, title } from "./texts";

export const NoClustersDetectedAlert: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Alert
        severity={"warning"}
        title={title}
        text={description}
        icon={InfoOutlined as React.FC}
        ctaButton={
          <Button onClick={() => setIsModalOpen(true)} variant="contained">
            <Typography variant={"h6"} color={muiTheme.palette.common.white}>
              Add Cluster
            </Typography>
          </Button>
        }
        ariaLabel={costOptimizationAriaLabels.NoClustersDetectedAlert.container}
      />
      <KubernetesInstallationModal
        closeModalCallback={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </>
  );
};
