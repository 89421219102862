import React, { createContext, useCallback, useContext, useState } from "react";

import {
  CustomGraphDataPoint,
  MetricsFullTimeWindow,
} from "../../../../Metrics/types";
import { addToMetricsData } from "../utils";
import { Timeframe } from "../../../../../shared/types/TimeWindow";

export type MetricsTabContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  podContainersMemoryUsage: CustomGraphDataPoint[];
  podContainersCpuUsage: CustomGraphDataPoint[];
  setPodContainersMemoryUsage: (
    usageMetrics: CustomGraphDataPoint[],
    containerName: string
  ) => void;
  setPodContainersCpuUsage: (
    usageMetrics: CustomGraphDataPoint[],
    containerName: string
  ) => void;
  podContainersNames: string[];
  setPodContainersNames: (names: string[]) => void;
};

const initialState: MetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 1)),
    end: new Date(),
    timeframe: Timeframe.LastHour,
  },
  setTimeWindow: () => undefined,
  podContainersMemoryUsage: [],
  podContainersCpuUsage: [],
  podContainersNames: [],
  setPodContainersMemoryUsage: () => undefined,
  setPodContainersCpuUsage: () => undefined,
  setPodContainersNames: () => undefined,
};

const MetricsTabContext = createContext<MetricsTabContextState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useMetricsTabContext = (): MetricsTabContextState =>
  useContext(MetricsTabContext);

const {
  timeWindow: initialTimeWindow,
  podContainersMemoryUsage: initialContainersMemoryUsage,
  podContainersCpuUsage: initialContainersCpuUsage,
  podContainersNames: initialPodContainersNames,
} = initialState;

export const MetricsTabContextProvider: React.FC<{
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
  givenPodContainersNames?: string[];
}> = ({ children, givenTimeWindow, givenPodContainersNames }) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );
  const [podContainersMemoryUsage, setPodContainersMemoryUsage] = useState<
    CustomGraphDataPoint[]
  >(initialContainersMemoryUsage);
  const [podContainersCpuUsage, setPodContainersCpuUsage] = useState<
    CustomGraphDataPoint[]
  >(initialContainersCpuUsage);
  const [podContainersNames, setPodContainersNames] = useState<string[]>(
    givenPodContainersNames ?? initialPodContainersNames
  );

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
      setPodContainersMemoryUsage(initialContainersMemoryUsage);
      setPodContainersCpuUsage(initialContainersCpuUsage);
    },
    [timeWindow]
  );

  const onSetPodContainersMemoryUsage = useCallback(
    (metrics: CustomGraphDataPoint[], containerName: string) => {
      setPodContainersMemoryUsage((prev) => {
        return addToMetricsData({
          containerName,
          newMetricsList: metrics,
          prevMetricsList: prev,
        });
      });
    },
    []
  );

  const onSetPodContainersCpuUsage = useCallback(
    (metrics: CustomGraphDataPoint[], containerName: string) => {
      setPodContainersCpuUsage((prev) => {
        return addToMetricsData({
          containerName,
          newMetricsList: metrics,
          prevMetricsList: prev,
        });
      });
    },
    []
  );

  const contextState: MetricsTabContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
    podContainersMemoryUsage,
    podContainersCpuUsage,
    setPodContainersMemoryUsage: onSetPodContainersMemoryUsage,
    setPodContainersCpuUsage: onSetPodContainersCpuUsage,
    podContainersNames,
    setPodContainersNames,
  };

  return (
    <MetricsTabContext.Provider value={contextState}>
      {children}
    </MetricsTabContext.Provider>
  );
};
