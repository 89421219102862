import { FILTERS_BAR_FILTERS_PARAM_KEY } from "../../../../../shared/config/urlSearchParamsKeys";
import { SERVICES_ROUTE } from "../../../../routes/routes";

export const UNHEALTHY_SERVICES_SECTION_TITLE = "Unhealthy Services";
export const UNHEALTHY_SERVICES_SECTION_EMPTY_MESSAGE =
  "All the services are healthy";
export const UNHEALTHY_SERVICES_SECTION_TABLE_TITLE = "Top Unhealthy Services";
export const UNHEALTHY_SERVICES_SECTION_METRICS_TITLE =
  "Availability Issues Over Time";

export const UNHEALTHY_SERVICES_SECTION_VIEW_ALL_PATH = `${SERVICES_ROUTE}?${FILTERS_BAR_FILTERS_PARAM_KEY}=${JSON.stringify(
  { Health: { Unhealthy: true } }
)}`;

export const BOX_HEIGHT = "208px";
