import React from "react";

import { WorkflowDag as WorkflowDagType } from "@/generated/addonsApi";
import WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import { useAddonFullLiveState } from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { PageLoader } from "@/shared/components/PageLoader";
import { ResourceDrawer } from "@/components/ResourceView/ResourceDrawer";
import { useLiveStateDrawerContext } from "@/components/k8sAddons/LiveStateDrawer/context/useLiveStateDrawerContext";
import { selectWorkflowDag } from "@/components/k8sAddons/addons/workflows/DagDrawer/dagDrawerUtils";

export const DagDrawer: React.FC = () => {
  const { isLoading, data } = useAddonFullLiveState(selectWorkflowDag);

  const { onClose } = useLiveStateDrawerContext();

  if (isLoading) {
    return <PageLoader />;
  }

  const resource =
    !isLoading && data
      ? new WorkflowDag(data?.data as WorkflowDagType)
      : undefined;

  return (
    <ResourceDrawer resource={resource} open={!!resource} onClose={onClose} />
  );
};
