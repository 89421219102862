import React from "react";
import { Button, ButtonProps } from "@komodorio/design-system/deprecated";
import { Lock16 } from "@komodorio/design-system/icons";

import {
  freemiumModalNames,
  useFreemiumModalsUpdate,
} from "../../../../shared/hooks/useFreemiumModals";

export const FreemiumLockButton: React.FC<ButtonProps> = ({ ...rest }) => {
  const setOpenFreemiumModal = useFreemiumModalsUpdate();

  const onFreemiumButtonClick = () => {
    setOpenFreemiumModal &&
      setOpenFreemiumModal(freemiumModalNames.LOCK_RESTRICTION);
  };
  return (
    <>
      <Button {...rest} icon={Lock16} onClick={onFreemiumButtonClick} />
    </>
  );
};
