import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { UpgradeAgentVersionAlert } from "../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { updateArgoWorkflowsWarningText } from "../common/UpgradeAgentVersionAlert/texts";
import UpgradeRequired from "../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeRequiredModalStep } from "../common/UpdateAgentModal/types";

const Container = styled.div`
  margin: 0 10px;
`;

const AlertContainer = styled.div`
  padding: 1rem;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

const UpgradeButtonAndModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Button variant={"contained"} onClick={() => setOpen(true)}>
        <StyledTypography variant={"h6"}>Update Agent</StyledTypography>
      </Button>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={null}
          upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_ARGO_WORKFLOWS}
          desiredFeature="argo workflows"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </Container>
  );
};

export const UpdateArgoWorkflowAlert: React.FC = () => (
  <AlertContainer>
    <UpgradeAgentVersionAlert
      upgradeButton={<UpgradeButtonAndModal />}
      text={updateArgoWorkflowsWarningText}
      severity={"warning"}
    />
  </AlertContainer>
);
