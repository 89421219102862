import React from "react";

import { UpgradeAgentVersionAlert } from "../../UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { LocalStorageItem } from "../../../../shared/utils/localStorageSettings";
import { UpgradeButtonAndModalForUnsupportedClusters } from "../../../../shared/components/UpgradeButtonAndModalForUnsupportedClusters";
import { UpgradeCommands } from "../../upgradeAgentCta/UpgradeAgentCTA";
import { usePermanentHideAlertByString } from "../../UpgradeAgentVersionAlert/usePermanentHideAlertByString";
import { PermanentHideAlert } from "../../UpgradeAgentVersionAlert/PermanentHideAlert";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

import { useShouldShowLogsUpgradeAgentAlert } from "./useShouldShowLogsUpgradeAgentAlert";

const hideUpgradeAgentLiveLogsLS = new LocalStorageItem(
  "upgradeAgentLiveLogsAlert"
);

interface Props {
  clusterName: string;
}

export const LogsUpgradeAgentAlert: React.FC<Props> = ({ clusterName }) => {
  const { hideAlertVal, setHideAlertVal } = usePermanentHideAlertByString({
    localStorageItem: hideUpgradeAgentLiveLogsLS,
    key: clusterName,
  });

  const shouldShowLogsUpgradeAgentAlert =
    useShouldShowLogsUpgradeAgentAlert(clusterName);

  if (!shouldShowLogsUpgradeAgentAlert || hideAlertVal) return null;

  return (
    <UpgradeAgentVersionAlert
      upgradeButton={
        <>
          <PermanentHideAlert
            setHide={setHideAlertVal}
            ariaLabel={
              AriaLabels.Alerts.UpgradeAgent.PermanentHideButton.HideByKey
            }
          />
          <UpgradeButtonAndModalForUnsupportedClusters
            unsupportedClusters={[clusterName]}
            upgradeCommand={UpgradeCommands.NEW_UPGRADE_COMMAND}
            desiredFeature="live tail"
          />
        </>
      }
      title="Log tail is now available!"
      text="An agent upgrade is required to enable logs tail"
      severity={"warning"}
      showCloseButton={true}
    />
  );
};
