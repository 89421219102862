import React from "react";

import { Timeframe } from "../../../../shared/types/TimeWindow";
import { MetricsControlsSelector } from "../../common/Metrics/MetricsControlsSelector";

import { useMetricsTabContext } from "./context/MetricsTabContext";

export const MetricsTimeSelector: React.FC = () => {
  const { timeWindow, setTimeWindow } = useMetricsTabContext();

  return (
    <MetricsControlsSelector
      setTimeWindow={setTimeWindow}
      timeWindow={timeWindow}
      options={[
        Timeframe.LastHour,
        Timeframe.Last4Hours,
        Timeframe.Last24Hours,
        Timeframe.Last7Days,
      ]}
    />
  );
};
