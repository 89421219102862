import { Record, Array as RTArray, String } from "runtypes";

import { notifyDDError } from "../../exceptionManagement";
import { PodLogLine } from "../types";

const PodLogLinesGuard = RTArray(
  Record({
    logTime: String,
    message: String,
  })
);

export const tryParseLogs = (logs: string): PodLogLine[] => {
  if (logs === "") {
    return [];
  }

  try {
    const parsed = JSON.parse(logs);
    return PodLogLinesGuard.guard(parsed) ? (parsed as PodLogLine[]) : [];
  } catch (error) {
    notifyDDError(error as Error, {
      failure: "Failed to parse pod logs",
      logs,
    });
    return [];
  }
};
