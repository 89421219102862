import React from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

export const NotApplicable: React.FC = () => {
  return (
    <Typography variant="body2" color={muiColors.pink[700]}>
      n/a
    </Typography>
  );
};
