import { useCallback } from "react";

import { useWebSocketContext } from "../context/WebSocketsProvider";
import { generateSocketPayload } from "../utils/generateSocketPayload";

import { SocketMessageType } from "./types";

export const useCloseSocketSessionById = (): ((id: string) => void) => {
  const sockets = useWebSocketContext();

  return useCallback(
    (id: string) => {
      if (!id) return;

      const activeSocket = sockets.getSocket(id);

      if (activeSocket) {
        const { sessionId } = sockets.getSocketContext(id) || {};

        try {
          sessionId &&
            activeSocket.send(
              JSON.stringify(
                generateSocketPayload({
                  messageType: SocketMessageType.Termination,
                  sessionId,
                })
              )
            );
        } catch {
          /* no-op */
        }
      }

      sockets.removeSocket(id);
    },
    [sockets]
  );
};
