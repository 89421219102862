import React, { Component, ErrorInfo, ReactNode } from "react";

import { notifyDDError } from "../../shared/hooks/exceptionManagement";

import { ErrorFallbackComponent } from "./ErrorFallbackComponent";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  // Currently not possible to create Error Boundaries with React Hooks
  // due to the use of componentDidCatch and getDerivedStateFromError
  // https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack ?? undefined;
    renderingError.cause = error;

    notifyDDError(renderingError);
    // eslint-disable-next-line no-console
    console.error("[Error Boundary] Uncaught error:", error, errorInfo);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <ErrorFallbackComponent
          resetHasError={() => this.setState({ hasError: false })}
        />
      );
    }
    return this.props.children;
  }
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
