import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GraphContentContainer = styled.div`
  flex-grow: 1;
`;

type GraphContainerWithChildrenProps = {
  graphContent: React.ReactNode;
  children: React.ReactNode;
};

export const GraphContainerWithChildren: React.FC<
  GraphContainerWithChildrenProps
> = ({ graphContent, children }) => {
  return (
    <Container>
      <GraphContentContainer>{graphContent}</GraphContentContainer>
      {children}
    </Container>
  );
};
