import { useMemo } from "react";

import { TimeWindow } from "../../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useWorkflowsFlags } from "../../monitorsView/common/availableWorkflows";
import { useResourceView } from "../../ResourceView/ResourceProvider";
import { useJobEventsByServices } from "../../../shared/hooks/resources-api/client/events/jobs/useJobEvents";
import useWorkflowIssues from "../EventGroup/workflowIssues/useWorkflowIssues";
import { isKindJobs } from "../../servicesView";
import { useFetchCustomServiceEvent } from "../EventGroup/customEvent/fetchCustomEventsHooks";
import { useAgentDowntimeRawData } from "../EventGroup/agentDowntimeEvent/useAgentDowntimeRawData";
import { useShouldShowAgentDowntime } from "../EventGroup/agentDowntimeEvent/useShouldShowAgentDowntime";
import { useNodeTerminationsByOwnerKomodorUidsFromAPI } from "../EventGroup/nodeEvent/useNodeChangeEvents";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { EventsWindow } from "./EventsPagination";
import { useGeneralEvents } from "./useGeneralEvents";
import { useRelatedResourcesEvents } from "./useRelatedResourcesEvents";

const useEventsFromPg = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  komodorUid: string
) => {
  return useGeneralEvents({
    timeWindow,
    komodorUid,
  });
};

const useServiceViewEvents = (
  serviceId: string,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  clusterName?: string | undefined,
  namespace?: string | undefined,
  selectedResourceNamesForEvents?: { [key: string]: string[] }
): EventsWindow | undefined => {
  const { showJobEventsDeepDive } = useOverridableFlags();

  const resource = useResourceView();
  const serviceIds = useMemo(() => {
    return [serviceId];
  }, [serviceId]);

  const komodorUid = useMemo(
    () =>
      buildKomodorUid({
        clusterName: resource.cluster,
        namespace: resource.namespace,
        kind: resource.kind,
        resourceName: resource.name,
      }),
    [resource]
  );

  const eventsFromPg = useEventsFromPg(timeWindow, komodorUid);

  const wfFlags = useWorkflowsFlags();

  const showAgentDowntimeEvent = useShouldShowAgentDowntime();
  const agentDowntimeEvents = useAgentDowntimeRawData(
    showAgentDowntimeEvent,
    timeWindow,
    clusterName
  );

  const {
    nativePodEvents,
    groupedNativePodEvents,
    hpaEvents,
    configMapEvents,
    kubernetesServiceEvents,
    ingressEvents,
    secretEvents,
  } = useRelatedResourcesEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resource.kind
  );

  const customEvents = useFetchCustomServiceEvent({
    serviceId,
    namespace,
    clusterName,
    resourceViewKind: resource.kind,
    timeWindow,
  });

  const KomodorUidsImpactedBynodeTermination = useMemo(
    () => [komodorUid],
    [komodorUid]
  );
  const [nodeTerminations] = useNodeTerminationsByOwnerKomodorUidsFromAPI(
    KomodorUidsImpactedBynodeTermination,
    timeWindow
  );
  const [workflowIssues] = useWorkflowIssues(timeWindow, serviceIds);
  const wfResults = useMemo(
    () =>
      workflowIssues?.filter(
        (result) => result?.type && wfFlags[result.type]
      ) ?? [],
    [wfFlags, workflowIssues]
  );
  const jobQuery = useJobEventsByServices(
    showJobEventsDeepDive && isKindJobs(resource.kind),
    timeWindow,
    serviceIds
  );

  return useMemo(() => {
    if (!eventsFromPg) return undefined;

    const impactfulEvents = {
      ...eventsFromPg,
      event_secret: [...eventsFromPg.event_secret, ...secretEvents],
    };

    return wfResults && jobQuery
      ? {
          impactfulEvents,
          nativePodEvents,
          nodeEvents: [],
          configMapEvents,
          kubernetesServiceEvents,
          agentDowntimeEvents: agentDowntimeEvents ?? [],
          ingressEvents,
          workflowResults: wfResults,
          jobEvents: jobQuery,
          hpaEvents,
          pdbEvents: [],
          nodeCreatedEvents: [],
          nodeTerminatedEvents: nodeTerminations ?? [],
          customEvents: customEvents,
          groupedPodEvents: groupedNativePodEvents ?? [],
        }
      : undefined;
  }, [
    eventsFromPg,
    secretEvents,
    wfResults,
    jobQuery,
    nativePodEvents,
    configMapEvents,
    kubernetesServiceEvents,
    agentDowntimeEvents,
    ingressEvents,
    hpaEvents,
    nodeTerminations,
    customEvents,
    groupedNativePodEvents,
  ]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useServiceViewEvents;
