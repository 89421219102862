import { useMemo } from "react";

import { FilterCategory } from "@/shared/hooks/filtersDataInUrl/filtersStateHandlerTypes";
import { useFiltersInUrlContext } from "@/shared/context/filtersInUrlContext/useFiltersInUrlContext";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

/** get all relevant filters per filtersCategory
 *  to get all possible filters, selfFilterCategory should be undefined
 **/
export const useFilterObjectListFromUrl = (
  selfFilterCategory?: string
): FilterCategory[] => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const { searchTypeFilterOperator } = useFiltersInUrlContext();

  return useMemo(() => {
    return Object.entries(filtersDataInUrl).reduce<FilterCategory[]>(
      (acc, [category, filtersList]) => {
        const relevantFilters = filtersList.filter((filter) => {
          if (filter.values.length === 0) return false;
          const isSearch = filter.operator === searchTypeFilterOperator;
          const isFilteringAllOtherCategories =
            category !== selfFilterCategory && !isSearch;
          const isFilteringSelf = isSearch && category === selfFilterCategory;
          return isFilteringAllOtherCategories || isFilteringSelf;
        });

        relevantFilters.forEach((filter) => {
          acc.push({
            category: category,
            values: filter.values,
            operator: filter.operator,
          });
        });

        return acc;
      },
      []
    );
  }, [selfFilterCategory, filtersDataInUrl, searchTypeFilterOperator]);
};
