import React from "react";
import styled from "styled-components";

import { InsightsDrawerTitle } from "../../styles";

const PaddedTitle = styled(InsightsDrawerTitle)`
  && {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

type ServicesTableTitleProps = {
  title?: string;
};

export const ServicesTableTitle: React.FC<ServicesTableTitleProps> = ({
  title,
}) => {
  const titleText = title || "Affected services";
  return <PaddedTitle>{titleText}</PaddedTitle>;
};
