import { palette } from "@komodorio/design-system";
import { Handle, Position } from "react-flow-renderer";
import styled from "styled-components";

export const NodeSourceHandle = styled(Handle).attrs({
  type: "source",
  position: Position.Right,
  style: { pointerEvents: "none" },
})`
  background: white;
  border: 2px solid ${palette.gray[500]};
  border-radius: 1rem;
  pointer-events: none;
`;
