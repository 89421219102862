import { sub } from "date-fns";

import DeletedPod from "../ResourceView/resources/deletedPod";
import { Timeframe } from "../../shared/types/TimeWindow";

import { getDuration } from "./utils";
import { TimeEpochs } from "./types";

export const deletedPodTimeWindowToEpochs = (
  timeWindow: Timeframe,
  deletedPod: DeletedPod
): TimeEpochs => {
  const deletedPodTime = new Date(
    deletedPod.fullObj.metadata?.deletionTimestamp as string
  );
  const duration = getDuration(timeWindow);

  const fromEpoch = Math.round(sub(deletedPodTime, duration).getTime() / 1000);
  const toEpoch = Math.round(deletedPodTime.getTime() / 1000);

  return { fromEpoch, toEpoch };
};
