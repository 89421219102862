import React from "react";
import Stack from "@mui/material/Stack";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { KlaudiaAi } from "@/components/Settings/General/BetaFeatures/KlaudiaAi/KlaudiaAi";
import { RbacClusterSync } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterSync";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

export const FeaturesPage: React.FC = () => {
  const { canManageFeatures } = useHasPermissions();
  const { klaudiaAi } = useOverridableFlags();

  const features = [];

  if (!canManageFeatures) {
    return null;
  }

  if (klaudiaAi) {
    features.push(<KlaudiaAi key="KlaudiaAI" />);
  }
  features.push(<RbacClusterSync key="RBACCluserSync" />);

  if (features.length === 0) {
    return null;
  }

  return (
    <SettingsViewVerticalLayout title="Features">
      <Stack direction="row" spacing={2}>
        {features}
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
