export const costOptimizationAriaLabels = {
  allocationPage: {
    filters: {
      groupBy: "Group By selector",
      scope: "Scope selector",
      timeWindow: "Time window selector",
    },
    overviewCards: {
      container: "Overview cards container",
      totalCost: "Total cost card",
      totalOptimizationScore: "Total optimization score card",
      totalPotentialSaving: "Total potential saving card",
      totalUnallocatedCost: "Unallocated cost",
    },
    allocationGraph: {
      container: "allocation metrics graph",
      xAxis: "allocation graph xAxis",
      yAxis: "allocation graph yAxis",
    },
    allocationTable: {
      container: "allocation table",
      savingButton: "Table saving button",
      exportButton: "export allocation table button",
    },
  },
  rightSizingPage: {
    recommendationModal: {
      container: "right sizing modal",
      containerCard: "right sizing modal container card",
      containersLoader: "right sizing recommendation modal containers loader",
      containersError: "right sizing recommendation modal containers error",
      rowsContainer: "entries container",
      containerName: "container name",
      serviceDetails: {
        detailsServiceKindAndName: "service kind and name",
        detailsServiceNamespace: "service namespace",
        detailsServiceCluster: "service cluster",
      },
      savings: {
        monthly: "monthly savings",
        yearly: "yearly savings",
      },
      containers: {
        containerGroup: "container group",
        recommendationsSummary: "containers recommendations summary",
        cpuRecommendation: "cpu recommendation",
        memoryRecommendation: "memory recommendation",
        currentRequest: "current request",
        recommendedRequest: "recommended request",
      },
      buttons: {
        updateButton: "update button",
        cancelButton: "cancel button",
        resetButton: "reset button",
      },
    },
    table: {
      table: "Right sizing table",
      savingButton: "Table saving button",
      exportButton: "export right sizing table button",
    },
    paginatedTable: {
      table: "Right sizing paginated table",
      tableContent: "Right sizing paginated table content",
      exportButton: "export right sizing table button",
    },
    summaryCards: {
      savingCard: {
        card: "Monthly And Yearly savings card",
        monthly: "Monthly saving card",
        yearly: "Yearly saving card",
      },
      potentialCostComparison: {
        savings: "savings percent",
        beforeAmount: "before amount",
        afterAmount: "after amount",
        card: "Potential cost comparison card",
      },
    },
    strategy: "Optimization strategy selector",
    strategyWrapper: "select an option",
    rightSizingGraph: {
      cpuGraph: "right sizing graph cpu",
      memoryGraph: "right sizing graph memory",
      xAxis: "right sizing graph xAxis",
      yAxis: "right sizing graph yAxis",
    },
  },
  settings: {
    button: "open settings modal",
  },
  agentUpdateRequiredPreview: {
    container: "agent update is required",
  },
  collectingDataAlert: {
    container: "collecting data alert",
  },
  NoClustersDetectedAlert: {
    container: "no clusters detected alert",
  },
  recommendationsAccuracy: "recommendations accuracy",
  errors: {
    fetchErrorContainer: "error container",
  },
};
