import { palette, theme } from "@komodorio/design-system";

export const graphHeight = "15rem";
export const timelineTicksNum = 4;

export const podContainersLineColors = [
  palette.darkBlue[400],
  palette.blue[400],
  palette.purple[300],
  palette.orange[600],
  palette.pink[800],
  theme.foreground.fgPrimary,
];
