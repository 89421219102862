import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import semver from "semver";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { muiColors } from "@komodorio/design-system";

import { VersionSummary } from "../VersionsTimeline/types";
import { getVersion } from "../DetailsUtils";

import { getColumns } from "./utils";
import { APIsTable } from "./APIsTable";

type TableMethod = "all" | "deprecated" | "removed";

const TABLE_CHANGE_DELAY = 1_000;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const VersionSelectorContainer = styled.div`
  dsplay: flex;
  flex-direction: column;
  gap: 8px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

interface Props {
  followingVersions: VersionSummary[] | undefined;
  selectedVersion: string | undefined;
  setSelectedVersion: (version: string) => void;
  clusterName: string | undefined;
}

const getMuiSelectionOption = (
  version: string
): MuiSelectionOption<string> => ({
  value: version,
  label: version.replace("v", "Version "),
});

export const VersionsAPIs: React.FC<Props> = ({
  followingVersions,
  selectedVersion,
  setSelectedVersion,
  clusterName,
}) => {
  const [tableMethod, setTableMethod] = useState<TableMethod>("all");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, TABLE_CHANGE_DELAY);
    return () => clearTimeout(timer);
  }, [selectedVersion]);

  const versionsOptions = useMemo(
    () =>
      followingVersions?.map<MuiSelectionOption<string>>((version) =>
        getMuiSelectionOption(version.version)
      ),
    [followingVersions]
  );

  const currentVersion = useMemo(
    () =>
      followingVersions?.find((version) => version.version === selectedVersion),
    [followingVersions, selectedVersion]
  );

  const { deprecatedAPIsInVersion, removedAPIsInVersion } = useMemo(() => {
    const currentApi = getVersion(selectedVersion ?? "");
    const deprecatedAPIsInVersion = currentVersion?.apis.filter(
      (api) =>
        semver.gte(currentApi, getVersion(api?.deprecatedInVersion ?? "")) &&
        semver.lt(currentApi, getVersion(api?.removedInVersion ?? ""))
    );

    const removedAPIsInVersion = currentVersion?.apis.filter((api) =>
      semver.gte(currentApi, getVersion(api?.removedInVersion ?? ""))
    );

    return {
      deprecatedAPIsInVersion,
      removedAPIsInVersion,
    };
  }, [currentVersion?.apis, selectedVersion]);

  const { apis = [] } = currentVersion ?? {};

  const apisToShow =
    tableMethod === "deprecated"
      ? deprecatedAPIsInVersion
      : tableMethod === "removed"
      ? removedAPIsInVersion
      : apis;

  return (
    <Container>
      <VersionSelectorContainer>
        <Typography variant="body2" color="text.primary">
          Select the version you want to migrate to, to view the APIs that need
          to be replaced
        </Typography>
        <SingleSelect
          options={versionsOptions ?? []}
          value={getMuiSelectionOption(selectedVersion ?? "")}
          onChange={(option) => setSelectedVersion(option?.value ?? "")}
          width="220px"
        />
      </VersionSelectorContainer>
      <div>
        <Tabs
          value={tableMethod}
          onChange={(event, newValue) => setTableMethod(newValue)}
        >
          <Tab label={`All (${apis.length})`} value={"all"} />
          <Tab
            label={`Removed APIs (${removedAPIsInVersion?.length ?? 0})`}
            value="removed"
          />
          <Tab
            label={`Deprecated APIs (${deprecatedAPIsInVersion?.length ?? 0})`}
            value="deprecated"
          />
        </Tabs>
        {isLoading ? (
          <Skeleton variant="rounded" height={300} />
        ) : apisToShow?.length ? (
          <APIsTable
            rows={apisToShow ?? []}
            columns={getColumns()}
            clusterName={clusterName}
          />
        ) : (
          <EmptyStateContainer>
            <Typography variant="h5" color="text.secondary">
              No {tableMethod} API found
            </Typography>
          </EmptyStateContainer>
        )}
      </div>
    </Container>
  );
};
