import React from "react";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import { ContextMenu } from "@komodorio/design-system/komodor-ui";
import { NavLink } from "react-router-dom";

import clusters from "../assets/Workspace bar - Clusters.png";
import overview from "../assets/Workspace bar - Overview.png";
import workflows from "../assets/Workspace Bar - Workflows.png";
import workloadHealth from "../assets/Workspace bar - Workload Health.png";
import infraHealth from "../assets/Workspace bar - Infrastructure Health.png";
import certs from "../assets/Workspace bar - Cert.png";
import externalDNS from "../assets/Workspace bar - External DNS.png";
import nodeAutoscalers from "../assets/Workspace Bar - Node Autoscalers.png";
import drift from "../assets/Workspace Bar - Drift.png";
import workspaceSelector from "../assets/Workspaces Menu.png";
import { ClickableArea, RelativeContainer } from "../styles";

import { useSettings } from "@/components/TopBar/hooks/useSettings";

const WorkspacesSelector = styled(ClickableArea)`
  width: 17.5%;
  height: 79%;
  left: 1.8%;
  top: 5.2%;
`;

const SettingsButton = styled(ClickableArea)`
  width: 2.2%;
  height: 79%;
  right: 5.8%;
  top: 5.2%;
`;

export const Menu = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 440px;
  & > img {
    width: 100%;
    user-select: none;
  }
`;

const IMAGES = {
  clusters,
  overview,
  workflows,
  workloadHealth,
  infraHealth,
  certs,
  externalDNS,
  nodeAutoscalers,
  drift,
};

export type TopBarProps = {
  page: keyof typeof IMAGES;
};

export const TopBar: React.FC<TopBarProps> = ({ page }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { items } = useSettings();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <RelativeContainer>
      <img src={IMAGES[page]} />
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Menu>
          <img src={workspaceSelector} />
        </Menu>
      </Popover>
      <ContextMenu
        id="organization settings"
        tooltip="Organization settings"
        items={items}
        LinkComponent={NavLink}
        buttonComponent={SettingsButton}
      />
      <WorkspacesSelector onClick={handleClick} />
    </RelativeContainer>
  );
};
