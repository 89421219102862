import React from "react";
import { MultiTagInput } from "@komodorio/design-system/komodor-ui";

import { ApiGroupOption, isApiGroupOption, RbacActionRule } from "./types";
import { apiGroupsOptions } from "./apiGroupsOptions";

export const InputApiGroup: React.FC<{
  readOnly?: boolean;
  handleApiGroupsChange: (apiGroups: string[]) => void;
  rule: RbacActionRule;
}> = ({ handleApiGroupsChange, readOnly, rule }) => {
  const onApiGroupInputChange = (
    _: React.SyntheticEvent,
    newValue:
      | NonNullable<string | ApiGroupOption>
      | (string | ApiGroupOption)[]
      | null
  ) => {
    if (!newValue) return;
    if (typeof newValue === "string") {
      handleApiGroupsChange([newValue]);
    }
    if (Array.isArray(newValue)) {
      handleApiGroupsChange([
        ...newValue.map((v) => {
          if (isApiGroupOption(v)) {
            return v.value;
          }
          return v as string;
        }),
      ]);
    }
  };

  return (
    <MultiTagInput<ApiGroupOption>
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      size={"small"}
      sx={{ borderWidth: "1px" }}
      id="custom-action-rules-apigroups-input"
      placeholder="Enter an API Group name - press Return to add"
      onChange={onApiGroupInputChange}
      value={rule.apiGroups}
      options={apiGroupsOptions}
      getOptionLabel={(option) => {
        if (typeof option === "string") return option;
        return (option as ApiGroupOption).label;
      }}
      readOnly={readOnly}
    />
  );
};
