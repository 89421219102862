import React from "react";
import { Route } from "react-router-dom";

import {
  getRoutePathFromKubernetesResource,
  SupportedKubernetesResources,
} from "@/components/Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { RedirectToResourceInspectionPage } from "@/components/Inspection/InspectionViewsRouter/RedirectToResourceInspectionPage";
import { ResourceToInspectionPage } from "@/components/Inspection/InspectionViewsRouter/ResourceToInspectionPage";

export const getInspectionViews = ({
  desktopClustersProblem,
}: {
  desktopClustersProblem: boolean;
}) => {
  if (desktopClustersProblem) {
    return [];
  }

  return Object.values(SupportedKubernetesResources).map((s, index) => {
    return [
      <Route
        key={index + "_" + s.NameInK8S}
        path={`${getRoutePathFromKubernetesResource(s, "")}/*`}
        element={<RedirectToResourceInspectionPage resource={s} />}
      />,
      <Route
        key={index + "_" + s.NameInK8S}
        path={`${getRoutePathFromKubernetesResource(s)}/*`}
        element={<ResourceToInspectionPage resource={s} />}
      />,
    ];
  });
};
