import React, { ReactNode } from "react";
import styled from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import { appViewAriaLabels } from "../../../../appViewAriaLabels";

const parentPaddingInRem = 1;

const ItemsTypeContainer = styled.div`
  background-color: ${theme.background.bgGrayLight};
  width: calc(100% + ${parentPaddingInRem}rem * 2);
  min-height: 2rem;
  margin-left: -${parentPaddingInRem}rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;
const ItemsTypeTitle = styled(Typography)`
  color: ${palette.gray["600"]};
`;

type ResourceItemsTypeContainerProps = {
  title: string;
  children: ReactNode;
};

export const ResourceItemsTypeContainer: React.FC<
  ResourceItemsTypeContainerProps
> = ({ title, children }: ResourceItemsTypeContainerProps) => {
  return (
    <div>
      <ItemsTypeContainer>
        <ItemsTypeTitle variant={"uppercase"}>{title}</ItemsTypeTitle>
      </ItemsTypeContainer>
      <div
        aria-label={
          appViewAriaLabels.overviewPage.resourcesInventory.itemsContainer
        }
      >
        {children}
      </div>
    </div>
  );
};
