import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import {
  ActionMetadataScaleService,
  ActionTypes,
  TaskType,
} from "komodor-types";
import { useLocation } from "react-router-dom";
import pluralize from "pluralize";

import { FormTextInput } from "../../../monitorsView/monitorsConfiguration/common/styles";
import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import { extractPathFromLocation } from "../../common/utils";
import { ErrorMessage } from "../../common/ErrorMessage";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface ScaleModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  desiredReplicas: number;
  controlledBy?: string;
  afterEffect?: () => void;
}

const ScaleModal: React.FC<ScaleModalProps> = ({
  isModalOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  desiredReplicas,
  controlledBy,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location = useLocation();

  const [isValidInput, setIsValidInput] = useState(true);
  const oldReplicaNumber = desiredReplicas;
  const [newReplicas, setNewReplicas] = useState(oldReplicaNumber);
  const handleValueChange = (input: string) => {
    const num = Number(input);
    if (!isNaN(num)) {
      setNewReplicas(Number(input));
    }
    setIsValidInput(validateInput(num));
  };

  const validateInput = (input: number) => {
    return input >= 0 && input <= 1000;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValidInput && event.key === "Enter") {
      executeAndReport();
      handleClose();
    }
  };

  const metadata: ActionMetadataScaleService = {
    namespace: namespace,
    cluster: cluster,
    serviceId: resourceId,
    type: ActionTypes.Scale,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    controlledBy: controlledBy ?? "",
    data: {
      currValue: oldReplicaNumber,
      newValue: newReplicas,
    },
  };

  const { execute, result, deniedObject, isFetching } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );
  const analytics = useAnalyticsApi();
  const executeAndReport = () => {
    setShowErrorMessage(false);
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: "scale",
        path: extractPathFromLocation(location),
        resourceType: resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  };

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  useEffect(() => {
    if (result && !deniedObject) {
      afterEffect?.();
      handleClose();
    }
  }, [afterEffect, deniedObject, handleClose, result]);

  useEffect(() => {
    if (deniedObject) {
      setShowErrorMessage(true);
    }
  }, [deniedObject]);
  useEffect(() => {
    if (actionSent && !isFetching && !deniedObject) {
      handleClose();
    }
  }, [isFetching, handleClose, deniedObject, actionSent]);

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <Modal isOpen={isModalOpen} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Scale: {resourceName}</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Current replicas number: {oldReplicaNumber}
          <br />
          <Container>
            Desired number of replicas:
            <FormTextInput
              aria-selected={true}
              width="2"
              autoFocus
              isValid={isValidInput}
              value={newReplicas}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleValueChange(e.target.value)
              }
              onKeyPress={handleKeyPress}
            />
          </Container>
        </Typography>
        {showErrorMessage && deniedObject !== undefined && (
          <ErrorMessage reason={deniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={!isValidInput || isFetching}
          variant="primary"
          size="small"
          onClick={() => {
            executeAndReport();
            setActionSent(true);
          }}
        >
          {actionSent && isFetching ? "Scaling..." : "Scale"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ScaleModal;
