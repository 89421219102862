import styled, { css } from "styled-components";
import { muiTheme, palette } from "@komodorio/design-system";

import { ZIndex } from "../../constants/zIndex";

export const HEIGHT = "100vh";
const PAGE_HEADER_HEIGHT = "16px";

export const fullPageStyle = css`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.AboveAppBar};
`;

const PADDING_BLOCK = 1;
const PADDING_INLINE = 1.5;

export const layoutPanelStyle = css`
  padding: ${PADDING_BLOCK}rem ${PADDING_INLINE}rem;
  height: calc(${HEIGHT} - ${PADDING_BLOCK * 2}rem);
`;

export const StepContainer = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: calc(${HEIGHT} - ${PAGE_HEADER_HEIGHT});
`;
export const StepHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 80ch;
  padding-bottom: 16px;
  padding-left: 24px;
`;
export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: ${palette.gray[50]};
  border: 1px solid ${palette.gray[200]};
  overflow: auto;
  padding: 1.5rem;
  box-shadow: inset 0px 0px 8px rgba(2, 7, 24, 0.08);
`;
export const StepFooter = styled.div`
  display: flex;
  padding: 8px 24px;
  width: 100%;
  background-color: ${muiTheme.palette.common.white};
`;
