import React from "react";

import { useCostOptimizationStore } from "../../store/costOptimizationStore";
import { selectServiceResourceDrawer } from "../../store/costOptimizationStoreSelectors";
import { ResourceDrawerByServiceId } from "../../../ResourceView/ResourceDrawer";

export const ServiceResourceDrawer: React.FC = () => {
  const { resourceDrawerServiceId, closeServiceResourceDrawer } =
    useCostOptimizationStore(selectServiceResourceDrawer);

  const onClose = () => {
    closeServiceResourceDrawer();
  };

  return (
    <>
      {resourceDrawerServiceId && (
        <ResourceDrawerByServiceId
          open
          onClose={onClose}
          serviceId={resourceDrawerServiceId}
        />
      )}
    </>
  );
};
