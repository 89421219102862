import { uniq, compact } from "lodash";
import { ResourceTableModelRow } from "komodor-types";

import { ArgoWorkflowTableData } from "../types";

const uniqValuesFromTable = (
  column: string,
  rows: ResourceTableModelRow[]
): string[] => compact(uniq(rows.flatMap((r) => r[column]))).sort();

interface BuildUniqueValuesResult {
  allTypes: string[];
  allStatuses: string[];
}

export const buildUniqueValues = (
  data: ArgoWorkflowTableData[]
): BuildUniqueValuesResult => {
  return {
    allTypes: uniqValuesFromTable(
      "type",
      data as unknown as ResourceTableModelRow[]
    ),
    allStatuses: uniqValuesFromTable(
      "status",
      data as unknown as ResourceTableModelRow[]
    ),
  };
};
