import { useMemo } from "react";

import { parseTriageData } from "../../../../../../monitorsView/useWorkflowsRuns";
import { TriageDataDb } from "../common/types";

import { useIssueReasonInsight } from "./issueReasonInsight/useIssueReasonInsight";
import { useNoisyNeighborsInsight } from "./noisyNeighborsInsight/useNoisyNeighborsInsight";
import { useLatestDeploysInsight } from "./latestDeploysInsight/useLatestDeploysInsight";
import { useRecentLimitDecreaseInsight } from "./recentLimitDecreasedinsight/useRecentLimitDecreaseInsight";
import { useManualActionsInsight } from "./manualActionsInsight/useManualActionsInsight";
import { useCorrelatedNodeEventsInsight } from "./correlatedNodeEventsInsight/useCorrelatedNodeEventsInsight";
import { useSameIssueInClusterInsight } from "./sameIssueInClusterInsight/useSameIssueInClusterInsight";

export const useInsights = (issueTriageData: TriageDataDb) => {
  const parsedTriageData = useMemo(
    () => parseTriageData(issueTriageData),
    [issueTriageData]
  );

  const issueReasonInsight = useIssueReasonInsight(
    parsedTriageData.issueType,
    parsedTriageData.subReason
  );
  const nosiyNeighborsInsight = useNoisyNeighborsInsight(
    parsedTriageData.isNoisyNeighborsDetected
  );
  const latestDeploysInsight = useLatestDeploysInsight(
    parsedTriageData.latestDeploysInsights
  );
  const recentLimitDecreaseInsight = useRecentLimitDecreaseInsight(
    parsedTriageData.limitDecreaseInsight
  );
  const latestManualActionsInsight = useManualActionsInsight(
    parsedTriageData.latestManualActionsInsights
  );
  const correlatedNodeEventsInsight = useCorrelatedNodeEventsInsight(
    parsedTriageData.latestNodeIssuesInsights,
    parsedTriageData.latestNodeTerminationsInsights
  );
  const sameIssueInClusterInsight = useSameIssueInClusterInsight(
    parsedTriageData.sameIssuesInClusterInsights
  );

  return useMemo(
    () =>
      [
        issueReasonInsight,
        nosiyNeighborsInsight,
        latestDeploysInsight,
        recentLimitDecreaseInsight,
        latestManualActionsInsight,
        correlatedNodeEventsInsight,
        sameIssueInClusterInsight,
      ]
        .filter((insight) => insight.shouldShow)
        .map((insight) => insight.text),
    [
      issueReasonInsight,
      nosiyNeighborsInsight,
      latestDeploysInsight,
      recentLimitDecreaseInsight,
      latestManualActionsInsight,
      correlatedNodeEventsInsight,
      sameIssueInClusterInsight,
    ]
  );
};
