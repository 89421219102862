import React from "react";
import {
  Workflows16,
  Gauge16,
  Kubernetes16,
} from "@komodorio/design-system/icons";

import { clustersDemoRoutes } from "../../ClustersDemoViewV3/constants";
import { DEMO } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

export const ClustersDemoV3Link: React.FC = () => {
  return (
    <AppBarLink
      text="Clusters"
      route={`${DEMO}/v3/${clustersDemoRoutes.clusters}`}
      icon={<Kubernetes16 />}
      end={true}
    />
  );
};

export const WorkflowsClustersDemoV3Link: React.FC = () => {
  return (
    <AppBarLink
      text="Workflows"
      route={`${DEMO}/v3/${clustersDemoRoutes.workflows}`}
      icon={<Workflows16 />}
    />
  );
};

export const ClusterOverviewClustersDemoV3Link: React.FC = () => {
  return (
    <AppBarLink
      text="Overview"
      route={`${DEMO}/v3/${clustersDemoRoutes.clusters}/${clustersDemoRoutes.clusterOverview}`}
      icon={<Gauge16 />}
    />
  );
};
