import { isEqual } from "lodash";
import { useMemo } from "react";
import { UseSubscriptionArgs, UseSubscriptionResponse } from "urql";

export function useSubscriptionWithVariables<Data, Variables>(
  useOriginalSubscription: (
    args: Omit<UseSubscriptionArgs<Variables>, "query">
  ) => UseSubscriptionResponse<Data, Variables>,
  variables: Variables | null
): Data | null {
  const context = useMemo(() => ({ variables }), [variables]);

  const [{ data, fetching, operation }] = useOriginalSubscription({
    variables: variables ?? undefined,
    context: context,
    pause: !variables,
  });
  const correctData =
    data &&
    variables &&
    !fetching &&
    isEqual(operation?.context.variables, variables)
      ? data
      : null;
  return correctData;
}
