import styled from "styled-components";

import { Text, textRegularStyle } from "../../../../../common/typography";
import { pinkBrand } from "../../../../../../Colors";
import { StyledExternalLink } from "../../../../../integrations/installation/sentry/steps/styles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const customModalStyles = {
  content: {
    boxShadow: "0px 0.375rem 1.5rem rgba(22, 24, 31, 0.3)",
    borderRadius: "1rem",
    top: "50%",
    left: "50%",
    display: "block",
    padding: "1rem 2rem",
    width: "28rem",
  },
};

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 4.5rem;
`;

export const SmallExternalLink = styled(StyledExternalLink)`
  ${textRegularStyle}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
`;

export const ErrorMessage = styled(Text)`
  color: ${pinkBrand};
`;
