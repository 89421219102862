import React from "react";

import { Cursor, Insight, InsightContainer } from "../../common/Insight";

export const IssueReasonInsight: React.FC = () => {
  return (
    <InsightContainer>
      <Insight isCursorPointer={false}>
        <Cursor cursor="default">Container's limit reached</Cursor>
      </Insight>
    </InsightContainer>
  );
};
