import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";
import styled from "styled-components";

const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GappedColumnFlexContainer = styled(ColumnFlexContainer)`
  gap: 1rem;
`;

export const GenericErrorMessage: React.FC = () => (
  <GappedColumnFlexContainer>
    <AlertCircleOutlined24
      color={palette.gray["400"]}
      width="56px"
      height="56px"
    />
    <ColumnFlexContainer>
      <Typography size="medium" variant="title" color={palette.gray["800"]}>
        Oops! Something went wrong.
      </Typography>
      <Typography size="small" color={palette.gray["600"]}>
        Try reloading the page
      </Typography>
    </ColumnFlexContainer>
  </GappedColumnFlexContainer>
);
