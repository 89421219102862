import React from "react";
import { Link } from "react-router-dom";
import { VerticalTabsMenu } from "@komodorio/design-system/komodor-ui";

import { useSettings } from "@/components/TopBar/hooks/useSettings";
import { WORKSPACES_TOP_BAR_HEIGHT } from "@/components/TopBar/TopBar";

export const SettingsViewVerticalLayout: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => {
  const { items, currentTab } = useSettings();

  return (
    <VerticalTabsMenu
      title={title}
      items={items}
      currentTab={currentTab}
      linkComponent={Link}
      height={`calc(100% - ${WORKSPACES_TOP_BAR_HEIGHT} - 1px)`}
    >
      {children}
    </VerticalTabsMenu>
  );
};
