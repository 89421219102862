import React from "react";

import { useFormattedDuration } from "@/components/k8sAddons/components/table/DurationCell/useFormattedDuration";

export const DurationCell: React.FC<{ durationSeconds: number }> = ({
  durationSeconds,
}) => {
  const duration = useFormattedDuration(durationSeconds);

  return duration;
};
