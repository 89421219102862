import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material";
import { muiColors } from "@komodorio/design-system";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const tooltipStyle = {
  tooltip: {
    sx: {
      bgcolor: muiColors.common.white,
      boxShadow:
        "0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.1)",
    },
  },
};

type TooltipPropsWithoutTitle = Omit<TooltipProps, "title">;

export const AskAdminTooltip: React.FC<TooltipPropsWithoutTitle> = ({
  children,
  ...rest
}) => {
  const content = (
    <Container>
      <Typography variant="h4" color={muiColors.common.black}>
        Automate root cause detection with Komodor AI
      </Typography>
      <Typography variant="body2" color={muiColors.common.black}>
        Contact your Komodor account admin to enable this feature.
      </Typography>
    </Container>
  );

  return (
    <Tooltip
      arrow={false}
      componentsProps={tooltipStyle}
      {...rest}
      title={content}
      children={children}
    />
  );
};
