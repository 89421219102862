import { useCallback, useEffect, useMemo } from "react";

import { ServiceInfo } from "../../../../../shared/types/ServiceInfo";
import { useKomodorServices } from "../../../../../shared/hooks/useKomodorServices";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  selectedAppViewIdSelector,
  serviceIdsSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import {
  initialState as appViewsResourcesInitialState,
  useAppViewsResourcesStore,
} from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import {
  selectSetClusters,
  selectSetTags,
  selectUpdateResources,
} from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { Dictionary } from "../../../../../shared/types/Dictionary";
import { Resource, ResourcesTags } from "../types/appViewsResourcesTypes";
import { getServiceIdentification } from "../../../../../shared/utils/appViews/getServiceIdentification";
import { isServiceHealthy } from "../utils/resourcesHealthStatus";
import { KubernetesServiceTypeEnum } from "../../../../../shared/types/ServiceVertex";
import { serviceKindToStateType } from "../components/OverviewPageOld/cardsContent/ResourcesInventory/constants/serviceKindToStateType";
import {
  NamespacesWorkspace,
  WorkspaceKind,
} from "../../../../../generated/workspacesApi";
import { useWorkspaces } from "../../../../workspaces/WorkspacesTopBar/hooks";

export const useGetServiceByServiceId = (): ((
  serviceId: string
) => ServiceInfo | undefined) => {
  const komodorServices = useKomodorServices().all;

  return useCallback(
    (serviceId: string) => {
      return komodorServices?.find(
        (komodorService) => komodorService.id === serviceId
      );
    },
    [komodorServices]
  );
};

export const usePopulateServiceKinds = (): void => {
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const updateResources = useAppViewsResourcesStore(selectUpdateResources);
  const komodorServices = useKomodorServices().all;
  const { isWorkspaceKindBackendFiltered } = useWorkspaces();
  const foundServicesPerKind = useMemo<
    Dictionary<Resource[]> | undefined
  >(() => {
    const initialState: Dictionary<Resource[]> = {};
    return komodorServices
      ?.filter((ks) => serviceIds.some((id) => id === ks.id))
      .reduce((acc, curr) => {
        if (!curr.kind) return acc;
        const dataToSave: Resource = {
          name: curr.title,
          id: getServiceIdentification(curr),
          isHealthy: isServiceHealthy(curr),
        };
        if (!acc[curr.kind]) {
          acc[curr.kind] = [dataToSave];
        } else {
          acc[curr.kind].push(dataToSave);
        }

        return acc;
      }, initialState);
  }, [komodorServices, serviceIds]);

  useEffect(() => {
    if (!foundServicesPerKind || isWorkspaceKindBackendFiltered) {
      return;
    }

    Object.entries(foundServicesPerKind).forEach(([kind, services]) => {
      const serviceState =
        serviceKindToStateType[kind as KubernetesServiceTypeEnum];
      if (!serviceState) return;
      updateResources({
        type: serviceState,
        resources: services,
      });
    });
  }, [foundServicesPerKind, updateResources, isWorkspaceKindBackendFiltered]);
};

export const usePopulateResourceTags = () => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const {
    isWorkspaceKindBackendFiltered,
    isNamespaceWorkspace,
    currentWorkspace,
  } = useWorkspaces();
  const setTags = useAppViewsResourcesStore(selectSetTags);
  const { all: komodorServices, scopeHasBeenLoaded } = useKomodorServices();
  const supportedTagLabels = Object.keys(appViewsResourcesInitialState.tags);
  useEffect(() => {
    const initialTags: ResourcesTags = {
      cluster: new Set(),
      namespace: new Set(),
    };
    if (isNamespaceWorkspace) {
      const namespaces = (currentWorkspace?.value as NamespacesWorkspace)
        .namespaces;
      namespaces.forEach((namespace) => initialTags.namespace.add(namespace));
      setTags(initialTags);
      return;
    }

    if (
      isWorkspaceKindBackendFiltered ||
      !selectedAppViewId ||
      !scopeHasBeenLoaded
    ) {
      return;
    }

    const foundTags = komodorServices?.reduce<ResourcesTags>((acc, curr) => {
      curr.tags.forEach((tag) => {
        if (supportedTagLabels.includes(tag.label)) {
          acc[tag.label as keyof ResourcesTags].add(tag.value);
        }
      });
      return acc;
    }, initialTags);

    foundTags && setTags(foundTags);
  }, [
    currentWorkspace?.value,
    isNamespaceWorkspace,
    isWorkspaceKindBackendFiltered,
    komodorServices,
    scopeHasBeenLoaded,
    selectedAppViewId,
    setTags,
    supportedTagLabels,
  ]);
};

export const usePopulateResourceClusters = () => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const setClusters = useAppViewsResourcesStore(selectSetClusters);
  const { currentWorkspace } = useWorkspaces();

  const { all: komodorServices, scopeHasBeenLoaded } = useKomodorServices();
  useEffect(() => {
    const emptySet = new Set<string>();
    if (currentWorkspace?.kind === WorkspaceKind.Cluster) {
      const singleCluster = emptySet.add(currentWorkspace.value.clusterName);
      setClusters(singleCluster);
      return;
    }
    if (currentWorkspace?.kind === WorkspaceKind.ClustersGroup) {
      const workspaceClusters = new Set(currentWorkspace.value.clusters ?? []);
      setClusters(workspaceClusters);
      return;
    }
    if (!selectedAppViewId || !scopeHasBeenLoaded || !komodorServices) return;
    const clusters = komodorServices.reduce<Set<string>>(
      (acc, { k8sCluster }) => {
        k8sCluster && acc.add(k8sCluster);
        return acc;
      },
      emptySet
    );
    setClusters(clusters);
  }, [
    currentWorkspace,
    komodorServices,
    scopeHasBeenLoaded,
    selectedAppViewId,
    setClusters,
  ]);
};
