import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { TimelineBox } from "./TimelineBox";
import { VersionSummary } from "./types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  overflow-x: auto;
`;

const BoxesContainer = styled.div`
  display: flex;
  gap: 4px;
`;

interface Props {
  currentVersion: string | undefined;
  followingVersions: VersionSummary[] | undefined;
  selectedVersion: string | undefined;
  setSelectedVersion: (version: string) => void;
}

export const VersionsTimeline: React.FC<Props> = ({
  currentVersion,
  followingVersions,
  selectedVersion,
  setSelectedVersion,
}) => {
  const content = useMemo(() => {
    const followingVersionsBoxes = followingVersions?.map((version) => {
      const variant =
        version.removed > 0
          ? "removed"
          : version.deprecated > 0
          ? "deprecated"
          : "safe";

      return (
        <TimelineBox
          key={version.version}
          variant={variant}
          version={version.version}
          apis={
            variant === "deprecated"
              ? version.deprecated
              : variant === "removed"
              ? version.removed
              : undefined
          }
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
      );
    });
    return [
      <TimelineBox
        key={currentVersion}
        variant="current"
        selectedVersion={selectedVersion}
        version={currentVersion ?? ""}
      />,
      ...(followingVersionsBoxes ?? []),
    ];
  }, [currentVersion, followingVersions, selectedVersion, setSelectedVersion]);

  return (
    <Container>
      <Typography variant="h5" color="text.secondary">
        Kubernetes versions timeline
      </Typography>
      <BoxesContainer>{content}</BoxesContainer>
    </Container>
  );
};
