import { BaseWorkflowCheck } from "../../../../monitorsView/useWorkflowsRuns";
import { AvailabilityInvestigationCheckType } from "../../types";
import { IntroductionOutput } from "../../Introduction/types";
import { UnhealthyPodsOutput } from "../../UnhealthyPods/types";
import {
  getExplanationCategory,
  OOMKilledReason,
  updateUnhealthyReasonSnapshot,
} from "../../Introduction/utils";

export const getPreferredSnapshot = (
  checks: BaseWorkflowCheck[],
  demoOomKilled?: boolean
) => {
  const introInfo =
    (checks.find(
      (c) => c.type === AvailabilityInvestigationCheckType.Introduction
    )?.output as IntroductionOutput) ?? undefined;
  const unhealthyPods =
    (checks.find(
      (c) => c.type === AvailabilityInvestigationCheckType.UnhealthyPods
    )?.output as UnhealthyPodsOutput) ?? undefined;

  const firstSnapshot = updateUnhealthyReasonSnapshot(
    introInfo?.firstSnapshot,
    unhealthyPods?.firstSnapshot
  );
  const lastSnapshot = updateUnhealthyReasonSnapshot(
    introInfo?.lastSnapshot,
    unhealthyPods?.lastSnapshot
  );
  const preferFirstSnapshot = firstSnapshot?.reasons?.some(
    (reason: string) =>
      getExplanationCategory(reason, demoOomKilled) === OOMKilledReason ||
      !lastSnapshot
  );
  return {
    firstSnapshot,
    lastSnapshot,
    preferFirstSnapshot,
  };
};
