export const PodPhase = {
  Pending: "Pending",
  NotReady: "NotReady",
  Ready: "Ready",
  Succeeded: "Succeeded",
  Failed: "Failed",
  Deleted: "Deleted",
};

export interface StatusCondition {
  type: string;
  status: string;
  message?: string;
  lastTransitionTime: Date;
}
