import { muiColors } from "@komodorio/design-system";
import { ChevronRight16 } from "@komodorio/design-system/icons";
import Button from "@mui/material/Button";
import styled from "styled-components";

export const IssueBannerContainer = styled.div<{
  bgColor: string | undefined;
  borderTop?: boolean;
}>`
  display: flex;
  padding: 12px 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor ?? muiColors.pink[25]};
  cursor: pointer;
  ${({ borderTop = true }) =>
    borderTop && `border-top: 1px solid ${muiColors.gray[200]};`}
  border-bottom: 1px solid ${muiColors.gray[200]};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

type IssueBannerProps = {
  text: React.ReactNode;
  icon: React.ReactNode;
  bgColor: string | undefined;
  borderTop?: boolean;
  ariaLabel: string;
  onIssueClicked: () => void;
};

export const IssueBanner = ({
  text,
  icon,
  bgColor,
  borderTop,
  ariaLabel,
  onIssueClicked,
}: IssueBannerProps) => {
  return (
    <IssueBannerContainer
      aria-label={ariaLabel}
      bgColor={bgColor}
      borderTop={borderTop}
      onClick={() => {
        onIssueClicked();
      }}
    >
      <SubContainer>
        {icon}
        {text}
      </SubContainer>
      <SubContainer>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={() => {
            onIssueClicked();
          }}
          endIcon={<ChevronRight16 />}
        >
          View issue
        </Button>
      </SubContainer>
    </IssueBannerContainer>
  );
};
