import React from "react";
import styled from "styled-components";

import costOptimizationMockupUrl from "../../assets/CostOptimizationMockup.svg";
import mockFrameUrl from "../../assets/mockFrame.svg";
import { UpgradeAgentVersionAlert } from "../../../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { costOptimizationAriaLabels } from "../../constants/costOptimizationAriaLabels";
import { updateAgentVersionWarningText } from "../../../common/UpgradeAgentVersionAlert/texts";
import { CollectingDataAlert } from "../CollectingDataAlert/CollectingDataAlert";
import { useSupportedAgentVersions } from "../../hooks/useSupportedAgentVersions";
import { UpgradeButtonAndModal } from "../UpgradeAgentVersionPrompt/UpgradeButtonAndModal";
import { NoClustersDetectedAlert } from "../NoClustersDetectedAlert/NoClustersDetectedAlert";
import { FeaturePreviewPage } from "../../../../shared/components/FeaturePreviewPage";

import { StaticPreviewDescription } from "./StaticPreviewDescription";
import { includedFeatures } from "./texts";

const StyledContainer = styled.div`
  margin: 24px 48px;
  display: flex;
  flex-direction: column;
`;

const CostOptimizationMockupContainer = styled.div`
  position: relative;
  height: fit-content;
`;

const StyledMockFrame = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-10.5%);
`;

interface CostOptimizationStaticPreviewProps {
  shouldShowNoClusterDetected: boolean;
  isFreemiumAccount: boolean;
}

export const CostOptimizationStaticPreview: React.FC<
  CostOptimizationStaticPreviewProps
> = ({ shouldShowNoClusterDetected, isFreemiumAccount }) => {
  const { unsupportedClusters } = useSupportedAgentVersions();
  return (
    <div
      aria-label={
        costOptimizationAriaLabels.agentUpdateRequiredPreview.container
      }
    >
      {!isFreemiumAccount && (
        <StyledContainer>
          {!!unsupportedClusters.length && (
            <UpgradeAgentVersionAlert
              upgradeButton={
                <UpgradeButtonAndModal
                  unsupportedClusters={unsupportedClusters}
                />
              }
              text={updateAgentVersionWarningText}
              severity={"warning"}
            />
          )}
          {shouldShowNoClusterDetected && <NoClustersDetectedAlert />}
          <CollectingDataAlert />
        </StyledContainer>
      )}
      <FeaturePreviewPage
        title="Cost Optimization"
        bullets={includedFeatures}
        img={
          <CostOptimizationMockupContainer>
            <img
              src={costOptimizationMockupUrl}
              width="100%"
              alt="Cost Optimization Preview"
            />
            <StyledMockFrame src={mockFrameUrl} />
          </CostOptimizationMockupContainer>
        }
        tag="new!"
      >
        <StaticPreviewDescription isFreemiumAccount={isFreemiumAccount} />
      </FeaturePreviewPage>
    </div>
  );
};
