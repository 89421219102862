import { get } from "lodash";
import { Dictionary, String } from "runtypes";
import { ResourceTableModelRow } from "komodor-types";

import { generateUid } from "../utils/generateUid";

import { useLatestDeployNewSpec } from "./useLatestDeployNewSpec";

export const useRelatedServicesFilter = (
  serviceId: string | undefined,
  cluster: string,
  namespace: string | undefined,
  servicesRows: ResourceTableModelRow[]
): { refresh: () => void; rows: ResourceTableModelRow[] } => {
  const { newSpec, refresh } = useLatestDeployNewSpec(serviceId);

  const rows = filterOnlyToRelatedServices(
    servicesRows,
    namespace,
    cluster,
    newSpec
  );

  return { refresh, rows };
};

export const filterOnlyToRelatedServices = (
  allRows: ResourceTableModelRow[],
  namespace: string | undefined,
  clusterName: string,
  newSpec: unknown
): ResourceTableModelRow[] => {
  const matchLabels = get(newSpec, "spec.selector.matchLabels", {});
  if (!Dictionary(String, String).guard(matchLabels)) {
    return [];
  }

  const relatesServices = allRows.filter((row) => {
    const serviceSelector = row?.selector ?? "";
    if (serviceSelector === "<none>") return false;

    if (row?.namespace !== namespace) {
      return false;
    }

    const serviceSelectors = serviceSelector.split(",");
    for (const selector of serviceSelectors) {
      const [key, valueInServiceSelector] = selector.split("=");
      // all labels in service selector label need to be exists in newSpec matchLabels
      // TODO: we need to support match expression
      const valueInMatchLabels = matchLabels[key];
      if (valueInMatchLabels !== valueInServiceSelector) {
        return false;
      }
    }

    return true;
  });

  return relatesServices.map((row) => {
    const uid = generateUid({
      name: row?.name ?? "",
      namespace: namespace ?? "",
      cluster: clusterName,
    });
    return { uid, ...row };
  });
};
