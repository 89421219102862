import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useKomodorServices } from "../../shared/hooks/useKomodorServices";
import { useServicesPageFiltersLocalStorageContext } from "../../shared/context/ServicesPageFiltersLocalStorageProvider";

import ExploreKomodorServices from "./ExploreKomodorServices";
import PaginatedExploreServices from "./pagination/PaginatedExploreServices";
import { KomodorServiceType } from "./types";

import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";

export const TopLinearLoader = styled.div<{ isLoading: boolean }>`
  border-top: 4px solid ${theme.foreground.fgDarkBlue};
  position: sticky;
  top: 0;
  width: 0;
  ${({ isLoading }) => isLoading && "width: 95%; transition: width 10s;"};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isKindJobs = (kind: string | null | undefined): boolean =>
  ["Job", "CronJob"].includes(kind ?? "");

const ServicesView: React.FC = () => {
  const services = useKomodorServices().services;
  const { serverSideFiltersForServicesView } = useOverridableFlags();
  const storedServicesViewQS = useServicesPageFiltersLocalStorageContext();

  useQueryStringInLocalStorage({ item: storedServicesViewQS });

  const [showLinearLoader, setShowLinearLoader] = useState(false);
  return (
    <>
      <TopLinearLoader isLoading={showLinearLoader} />
      <ResponsiveLayout>
        {serverSideFiltersForServicesView ? (
          <PaginatedExploreServices setShowLinearLoader={setShowLinearLoader} />
        ) : (
          <ExploreKomodorServices
            komodorServices={services}
            type={KomodorServiceType.service}
          />
        )}
      </ResponsiveLayout>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ServicesView;
