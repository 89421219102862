/* eslint-disable max-lines */
import React from "react";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Deploy } from "@komodorio/design-system/icons";
import { parseISO } from "date-fns";
import { Container } from "kubernetes-types/core/v1.d";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodsTab from "../tabs/PodsTab/PodsTab";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { isServiceHealthy } from "../../../shared/utils/serviceHelpers";
import {
  extractReplicasFromService,
  extractLastModifyFromService,
} from "../../../shared/utils/postgresMigrationUtils";
import EventsTab from "../tabs/EventsTab/EventsTab";
import InfoTab from "../tabs/InfoTab/InfoTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceHeader } from "../headers/KomodorServiceHeader";
import NodesTab from "../tabs/NodesTab/NodesTab";
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
import { SERVICES } from "../../routes/routes";
import ServiceLogsTab from "../tabs/ServiceLogsTab/ServiceLogsTab";
import { WorkloadMetricsTab } from "../tabs/WorkloadMetricsTab/WorkloadMetricsTab";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { isCurrentlyDeploying } from "./utils";

import Resource, { KomodorServiceType, ResourceTab, ResourceTabName } from ".";

export interface RolloutType {
  apiVersion: string;
  kind: string;
  metadata: {
    annotations: {
      [key: string]: string;
    };
    labels: {
      [key: string]: string;
    };
    name: string;
    namespace: string;
    resourceVersion: string;
    uid: string;
  };
  spec: {
    progressDeadlineSeconds: number;
    replicas: number;
    selector: {
      matchLabels: {
        [key: string]: string;
      };
    };
    strategy: Record<string, unknown>;
    template: {
      metadata: {
        annotations: {
          [key: string]: string;
        };
        labels: {
          [key: string]: string;
        };
      };
      spec: {
        containers: Container[];
        serviceAccountName: string;
      };
    };
  };
}

export interface RolloutProps {
  cluster?: string;
  rollout?: RolloutType;
  agentId?: string;
  featureFlags?: Record<string, unknown>;
  komodorService?: KomodorServiceType;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class Rollout implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly icon = Deploy;
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Nodes },
    { label: ResourceTabName.Info },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [
    AvailableActions.Describe,
    AvailableActions.Edit,
    AvailableActions.Compare,
  ];
  readonly deletedAt;
  readonly healthy;
  readonly replicas;
  readonly selector;
  readonly containers;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployEndDate;
  readonly lastDeployStartDate;
  readonly datadogData;
  readonly sentryData;
  readonly isDeleted;
  readonly inactive;
  readonly defaultTab;
  readonly fullObj?: unknown;
  readonly featureFlags?: Record<string, unknown>;

  constructor({
    komodorService,
    cluster,
    rollout,
    agentId,
    featureFlags,
  }: RolloutProps) {
    this.containers = rollout?.spec?.template?.spec?.containers;

    if (komodorService) {
      this.agentId = komodorService?.agentId ?? "";
      this.id = komodorService.id;
      this.cluster = komodorService.k8sClusterName;
      this.namespace = komodorService.namespace;
      this.name = komodorService.displayName;
      this.kind = komodorService.kind ?? "";
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.healthy = isServiceHealthy(
        komodorService.health_state?.healthStatus
      );
      this.replicas = extractReplicasFromService(komodorService);
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = komodorService.k8s_metadata?.selector as
        | LabelSelector
        | undefined;
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      const currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.currentlyDeploying = currentlyDeploying;
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;
      this.lastDeployStartDate = lastDeployStartTime
        ? parseISO(lastDeployStartTime)
        : undefined;
      this.isDeleted = komodorService.isDeleted;
      this.inactive = komodorService.inactive;
      this.drawerTabs = [
        { label: ResourceTabName.Events },
        {
          label: ResourceTabName.Logs,
          disabled: this.isDeleted || this.inactive,
        },
        {
          label: ResourceTabName.Pods,
          disabled: this.isDeleted || this.inactive,
        },
        {
          label: ResourceTabName.Nodes,
          disabled: this.isDeleted || this.inactive,
        },
        { label: ResourceTabName.Info },
        { label: ResourceTabName.Metrics },
        { label: ResourceTabName.Yaml },
      ];
      this.deletedAt = komodorService.deletedAt
        ? parseISO(komodorService.deletedAt)
        : undefined;
      this.defaultTab = 0;
    } else {
      this.agentId = agentId ?? "";
      this.fullObj = rollout;
      this.id = rollout?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = rollout?.metadata?.namespace ?? "";
      this.name = rollout?.metadata?.name ?? "";
      this.kind = rollout?.kind ?? "";
      this.labels = rollout?.metadata?.labels ?? {};
      this.annotations = rollout?.metadata?.annotations ?? {};
      this.selector = rollout?.spec?.selector;
      this.defaultTab = 0;
    }
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    let i = 0;
    const getNextIndex = () => i++;

    return (
      <>
        <TabPanel
          aria-label={AriaLabels.ResourceView.EventsTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <EventsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.LogsTab}
          value={selectedTab}
          index={getNextIndex()}
        >
          <ServiceLogsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.PodsTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <PodsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.NodesTab}
          eager
          value={selectedTab}
          index={getNextIndex()}
        >
          <NodesTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <InfoTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.MetricsTab}
          value={selectedTab}
          index={getNextIndex()}
          eager
        >
          <WorkloadMetricsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <KomodorServiceHeader
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }

  getFullScreenUrl(): string {
    return `/${SERVICES}/${this.id}`;
  }
}
