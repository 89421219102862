import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { ChangeEvent, useState } from "react";
import isCidr from "is-cidr";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";

import {
  CIDRWhitelist,
  CIDRWhitelistApiApiV1WhitelistCidrPostRequest,
} from "@/generated/accounts";
import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { WhitelistRow } from "@/components/Settings/whitelistCIDR/Form/WhitelistRow";
import { useCreateCIDRs } from "@/shared/hooks/accounts-service/client/cidrWhitelist/useCIDR";
import { whitelistAriaLabels } from "@/components/Settings/whitelistCIDR/whitelistAriaLabels";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";

const CIDRCreateModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  refreshCIDRs: () => void;
}> = ({ open, handleClose, refreshCIDRs }) => {
  const { accountId } = useUserMetadata();
  const defaultCIDR = {
    id: "",
    accountId: accountId,
    cidr: "",
    description: undefined,
  };
  const [values, setValues] = useState<CIDRWhitelist[]>([defaultCIDR]);
  const { errors, setError } = useFormValidations();
  const { mutateAsync: createCIDR } = useCreateCIDRs();

  const handleCIDRChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof CIDRWhitelist
  ) => {
    setValues((currentValues) =>
      currentValues.map((value, i) => {
        if (i === index) {
          return { ...value, [field]: event.target.value };
        }
        return value;
      })
    );
  };
  const validateCIDR = (cidr: string, index: number) => {
    setError(
      String(index),
      isCidr(cidr) ? undefined : "Your CIDR is invalid, please check it again"
    );
  };

  const addCIDRGroup = () => {
    setValues((currentValues) => [...currentValues, defaultCIDR]);
  };

  const removeCIDRGroup = (index: number) => {
    setValues(values.filter((_, i) => i !== index));
    setError(String(index), undefined);
  };

  const handlePreClose = () => {
    setValues([defaultCIDR]);
    setError("cidr", undefined);
    handleClose();
  };

  const handleSave = async () => {
    const payload = {
      cidrs: values.map((value) => ({
        cidr: value.cidr,
        description: value.description,
      })),
    };
    try {
      await createCIDR(
        payload as CIDRWhitelistApiApiV1WhitelistCidrPostRequest
      );
      refreshCIDRs();
      handlePreClose();
    } catch (error) {
      setError(
        "cidr",
        "Failed to save CIDR, please check again that all your CIDRs are valid"
      );
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { minWidth: "660px", height: "450px" },
      }}
      aria-label={whitelistAriaLabels.modalCreateCIDR}
    >
      <DialogTitle sx={{ paddingInLine: "32px" }}>
        <Typography variant="h3">CIDR Whitelist</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        {values.map((value: CIDRWhitelist, index: number) => (
          <WhitelistRow
            key={value.id}
            index={index}
            values={value}
            handleCIDRChange={handleCIDRChange}
            validator={validateCIDR}
            errors={errors}
            removeCIDRGroup={removeCIDRGroup}
            isLast={values.length === 1}
          />
        ))}
        <Button onClick={addCIDRGroup}>+ Add CIDR Block </Button>
      </DialogContent>
      {errors.cidr && (
        <FormHelperText variant={"outlined"} error>
          {errors.cidr}
        </FormHelperText>
      )}
      <Divider />
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          paddingInline: "24px",
          paddingBottom: "16px",
          marginTop: "auto",
        }}
      >
        <Button color="primary" variant="outlined" onClick={handlePreClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CIDRCreateModal;
