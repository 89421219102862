import React from "react";

import { InsightDrawerActionButton } from "../../shared/styles";
import { AvailabilityIssuesTableColumn } from "../../shared/types";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectOpenServiceResourceDrawer } from "../../../store/overviewPageStoreSelectors";
import { Timeframe } from "../../../../../../../../../shared/types/TimeWindow";
import {
  useSetEventsTabEventTypeFilter,
  useSetTimeFrameInEventsTabFilters,
} from "../../shared/hooks/eventsTabHooks";
import { WORKFLOW_CONFIG_TYPES } from "../../../../../../../../common/EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../../../../../../monitorsView/common/types";
import { overviewPageInsightsAriaLabels } from "../../../../../appViewAriaLabels";

const {
  failedDeploys: { viewRecent: viewRecentAriaLabel },
} = overviewPageInsightsAriaLabels;

type ViewRecentDeploysButtonProps = {
  rowData: AvailabilityIssuesTableColumn;
};

const deployIssues = WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Deploy];

export const ViewRecentDeploysButton: React.FC<
  ViewRecentDeploysButtonProps
> = ({ rowData }) => {
  const openServiceResourceDrawer = useOverviewPageStore(
    selectOpenServiceResourceDrawer
  );
  const setEventsTabFilters = useSetEventsTabEventTypeFilter();
  const setTimeFrameInEventsTabFilters = useSetTimeFrameInEventsTabFilters(
    Timeframe.Last7Days
  );

  const onClick = () => {
    openServiceResourceDrawer(rowData.id);
    setEventsTabFilters(deployIssues);
    setTimeFrameInEventsTabFilters();
  };

  return (
    <InsightDrawerActionButton
      onClick={onClick}
      aria-label={viewRecentAriaLabel}
    >
      View recent deploys
    </InsightDrawerActionButton>
  );
};
