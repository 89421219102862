import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import useResourceWithInterval from "../../ResourceView/useResource";
import Resource from "../../ResourceView/resources";
import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { EnrichedResourceTooltip } from "./EnrichedResourceTooltip";
import { useLastResourceStatus } from "./useLastResourceStatus";

export interface EnrichedResourceProps {
  value: string;
  resourceName: string;
  resourceKind: string;
  namespace: string;
  cluster: string;
  agentId: string;
  onClick?: (resource: Resource) => void;
}

export const EnrichedResource: React.FC<EnrichedResourceProps> = ({
  value,
  agentId,
  resourceName,
  resourceKind,
  namespace,
  cluster,
  onClick,
}) => {
  const { resource } = useResourceWithInterval({
    agentId,
    resourceType: resourceKind,
    resourceName: resourceName,
    namespace,
    cluster,
  });

  const lastResourceState = useLastResourceStatus({
    resourceKind,
    resourceName,
    namespace,
    cluster,
  });

  return (
    <Tooltip
      title={
        resource || lastResourceState ? (
          <EnrichedResourceTooltip
            resource={resource}
            lastResourceStatus={
              lastResourceState
                ? {
                    name: resourceName,
                    kind: resourceKind,
                    status: lastResourceState.status,
                    isDeleted: lastResourceState.isDeleted,
                  }
                : undefined
            }
          />
        ) : undefined
      }
      componentsProps={lightMuiTooltipStyle}
      placement="top"
      arrow={false}
    >
      <Typography
        aria-label={`${AriaLabels.InvestigationMode.EnrichedResource}-${resourceKind}`}
        onClick={resource ? () => onClick?.(resource) : undefined}
        variant={"body1"}
        sx={
          resource || lastResourceState
            ? {
                cursor: resource ? "pointer" : "default",
                textDecoration: "underline",
              }
            : undefined
        }
      >
        {value}
      </Typography>
    </Tooltip>
  );
};
