import React from "react";

import { pinkBrand } from "../../../Colors";

export const Triangle: React.FC<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = pinkBrand, width = "0.85rem", height = "0.33rem" }) => (
  <svg
    viewBox="0 0 500 500"
    preserveAspectRatio="none"
    height={height}
    width={width}
  >
    <polygon points="250,0 0,500 500,500" fill={color} />
  </svg>
);

export const UpsideTriangle: React.FC<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = pinkBrand, width = "0.85rem", height = "0.33rem" }) => (
  <svg
    viewBox="0 0 500 500"
    preserveAspectRatio="none"
    height={height}
    width={width}
  >
    <polygon points="0,0 250,500 500,0" fill={color} />
  </svg>
);
