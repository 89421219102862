import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { ContentCard } from "../ContentCard/ContentCard";
import { RecentDeploys } from "../RecentDeploys/RecentDeploys";
import { ResourcesInventory } from "../ResourcesInventory/ResourcesInventory";
import { OpenIssues } from "../OpenIssues/OpenIssues";
import {
  useDeployEventsPath,
  useOpenIssueEventsPath,
} from "../../../../hooks/overviewPageHooks";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const CardsWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2.5rem 0;
`;

export const CardsContainer: React.FC = () => {
  const openIssueEventsPath = useOpenIssueEventsPath();
  const deployEventsPath = useDeployEventsPath();

  return (
    <Container>
      <CardsWrapper>
        <ContentCard
          title={"Resources inventory"}
          content={
            <div>
              <ResourcesInventory />
            </div>
          }
        />
        <ContentCard
          title={"Open issues"}
          headerActionButton={
            <Button href={openIssueEventsPath} target="_blank">
              View all events
            </Button>
          }
          content={<OpenIssues />}
        />
        <ContentCard
          title={"Recent Deploys"}
          headerActionButton={
            <Button href={deployEventsPath} target="_blank">
              View all deploys
            </Button>
          }
          content={<RecentDeploys />}
        />
      </CardsWrapper>
    </Container>
  );
};
