import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1InstallationsGithubPostUrl,
  GithubInstallationCreateRequest,
  InstallationsApiApiV1InstallationsGithubPostRequest,
  SuccessResponse,
} from "../../../../../../generated/accounts";
import { useAccountsApiClient } from "../../apiClient";

const createGithubInstallation = async (
  apiClient: AxiosInstance,
  params: InstallationsApiApiV1InstallationsGithubPostRequest
): Promise<SuccessResponse> => {
  const { data } = await apiClient.post(
    apiV1InstallationsGithubPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.githubInstallationCreateRequest
  );
  return data;
};

export const useCreateGithubInstallation = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    (vars: GithubInstallationCreateRequest) =>
      createGithubInstallation(apiClient, {
        githubInstallationCreateRequest: vars,
      }),
    {}
  );
};
