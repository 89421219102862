import { AllocationTableColumns } from "../../../../CostOptimizationView/types/tableTypes";

export const _30_DAYS_MS = 1000 * 60 * 60 * 24 * 30;

export const costSectionTableColumns = (
  isSingleCluster: boolean
): AllocationTableColumns[] => [
  isSingleCluster ? "namespace" : "clusterName",
  "shareOfCost",
  "totalCost",
  "optimizationScore",
  "potentialSaving",
];

export const COST_SECTION_TITLE = "Cost Optimization";
export const COST_SECTION_EMPTY_MESSAGE =
  "No cost optimization suggestions are available to you";
export const costSectionTableTitle = (isSingleCluster: boolean) =>
  `Top Saving Opportunities by ${isSingleCluster ? "Namespace" : "Cluster"}`;
