import React from "react";

import { NodeData } from "../../../../ResourceDependenciesPage/types";
import { MonitorData } from "../../../../../generated/monitorsApi";

import { NodeTooltipContent } from "./NodeTooltipContent";
import { TooltipWrapper } from "./TooltipWrapper";
import { useCalculateTooltipPlacement } from "./useCalculateTooltipPlacement";

export const GraphNodeTooltip: React.FC<{
  tooltipId: string;
  nodeData: NodeData;
  openIssue: MonitorData | undefined;
  setIsTooltipOpen: (isOpen: boolean) => void;
}> = ({ tooltipId, nodeData, setIsTooltipOpen, openIssue }) => {
  const tooltipPosition = useCalculateTooltipPlacement(nodeData);
  return (
    <TooltipWrapper
      position={tooltipPosition}
      tooltipId={tooltipId}
      setIsTooltipOpen={setIsTooltipOpen}
    >
      <NodeTooltipContent openIssue={openIssue} nodeData={nodeData} />
    </TooltipWrapper>
  );
};
