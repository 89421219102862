import React, { createContext, ReactNode, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

import { ISSUES_TABLE_PAGE_SIZE_OPTIONS } from "../RealtimeConstants";

import { Dictionary } from "@/shared/types/Dictionary";
import { CheckType } from "@/generated/reliabilityApi";

export type AllTabType = "all";
export const ALL_TAB_VALUE: AllTabType = "all";

export type TabsType = CheckType | AllTabType;

type IssuesTableContextState = {
  tabValue: TabsType;
  setTabValue: (tabValue: TabsType) => void;
  issuesBreakdown: Dictionary<number> | undefined;
  paginationModel: GridPaginationModel;
  setPaginationModel: (paginationModel: GridPaginationModel) => void;
};

const initialState: IssuesTableContextState = {
  tabValue: ALL_TAB_VALUE,
  setTabValue: () => undefined,
  issuesBreakdown: undefined,
  paginationModel: {
    pageSize: ISSUES_TABLE_PAGE_SIZE_OPTIONS[0],
    page: 0,
  },
  setPaginationModel: () => undefined,
};

export const IssuesTableContext =
  createContext<IssuesTableContextState>(initialState);

type IssuesTableContextProviderProps = {
  children: ReactNode;
  issuesBreakdown: Dictionary<number> | undefined;
  initialTabValue?: TabsType;
};

export const IssuesTableContextProvider: React.FC<
  IssuesTableContextProviderProps
> = ({ children, issuesBreakdown, initialTabValue }) => {
  const [tabValue, setTabValue] = useState<TabsType>(
    initialTabValue ?? initialState.tabValue
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialState.paginationModel
  );

  const contextState: IssuesTableContextState = {
    tabValue,
    setTabValue,
    issuesBreakdown,
    paginationModel,
    setPaginationModel,
  };

  return (
    <IssuesTableContext.Provider value={contextState}>
      {children}
    </IssuesTableContext.Provider>
  );
};
