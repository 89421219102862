import React, { useMemo, useState } from "react";

import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { GraphContainerWithStatus } from "../../../../../../../../Metrics/GraphContainerWithStatus";
import { Uid } from "../../../../../../../../../shared/utils/generateUid";
import { GraphContainerWithChildren } from "../../../../../../../../Metrics/GraphContainerWithChildren";
import { EnlargedMetricsGraph } from "../../../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { useReportDrawerLoadingTime } from "../../../hooks/useReportDrawerLoadingTime";
import { ContainerRestartsSupportingDataContainersInner } from "../../../../../../../../../generated/reliabilityApi";
import { BarChart } from "../../../../../../../../Metrics/graphs/BarChart";
import { useGetCorrelatedDeployEvents } from "../../shared/metrics/hooks/useGetCorrelatedDeployEvents";
import { useIsViewDeployEventDetailsClicked } from "../../shared/metrics/hooks/useIsViewDeployEventDetailsClicked";

import {
  useFetchContainerRestartsData,
  useGraphElementsForContainerRestarts,
} from "./containerRestartsHooks";
import { GraphLegend } from "./GraphLegend";
import { getOnlyRestartingContainersMetricsData } from "./containerRestartsUtils";

type DetailsProps = {
  komodorUid: string;
  containers: ContainerRestartsSupportingDataContainersInner[];
};

export const Details: React.FC<DetailsProps> = ({ komodorUid, containers }) => {
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const { data, isFetching, error, fetchStatus } =
    useFetchContainerRestartsData(komodorUid);

  useReportDrawerLoadingTime(fetchStatus === "idle");

  const {
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const uid: Uid = {
    cluster,
    namespace,
    name,
  };

  const containersData = useMemo(
    () =>
      getOnlyRestartingContainersMetricsData(
        containers,
        data?.data?.containers
      ),
    [containers, data?.data?.containers]
  );

  const { dataPoints, tooltipItems, bars } =
    useGraphElementsForContainerRestarts(containersData);

  const events = useGetCorrelatedDeployEvents({
    komodorUid: komodorUid,
    graphDataPoints: dataPoints,
  });

  const shouldClosePopoverContentOnClickCapture =
    useIsViewDeployEventDetailsClicked();

  const graphContainerContent = (
    <BarChart
      data={dataPoints ?? []}
      bars={bars ?? []}
      tooltipItems={tooltipItems}
      yAxisLabel="Restarts"
      yAxisProps={{ allowDecimals: false }}
      events={events}
    />
  );

  const graphContainerProps = {
    uid,
    title: "Container restarts (last 7d)",
    hasGraphData: !error && !isFetching && !!data?.data?.containers?.length,
    error: error ? "Something went wrong" : null,
    loading: isFetching,
  };

  return (
    <div>
      <GraphContainerWithStatus
        {...graphContainerProps}
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      >
        {graphContainerContent}
      </GraphContainerWithStatus>
      <GraphLegend containers={containersData} />
      {isGraphEnlarged && (
        <EnlargedMetricsGraph
          {...graphContainerProps}
          shouldCloseOnClickCapture={shouldClosePopoverContentOnClickCapture}
          onClose={() => setIsGraphEnlarged(false)}
        >
          <GraphContainerWithChildren graphContent={graphContainerContent}>
            <GraphLegend containers={containersData} />
          </GraphContainerWithChildren>
        </EnlargedMetricsGraph>
      )}
    </div>
  );
};
