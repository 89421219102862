import { muiTheme } from "@komodorio/design-system";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

import { AriaLabels } from "@/shared/config/ariaLabels";

export type StatBoxProps = {
  title: string;
  value?: React.ReactNode;
  icon?: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  ariaLabel: string;
};

const StyledIcon = styled.div`
  color: ${muiTheme.palette.primary.light};
  display: flex;
`;

export const CheckTypeStatBox: React.FC<StatBoxProps> = ({
  title,
  value,
  icon,
  isLoading = false,
  onClick,
  ariaLabel,
}) => {
  if (!isLoading && !value) {
    return null;
  }

  return (
    <Box
      border="1px solid"
      borderColor="divider"
      borderRadius="4px"
      padding="8px"
      display="flex"
      flexDirection="column"
      gap="2px"
      flex="0 1 180px"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      aria-label={ariaLabel}
    >
      <Typography variant="body3" color="text.secondary">
        {title}
      </Typography>
      <Box display="flex" gap="4px" alignItems="center">
        {icon != null ? <StyledIcon>{icon}</StyledIcon> : null}
        {isLoading ? (
          <Skeleton
            variant="text"
            aria-label={AriaLabels.ClusterOverview.HealthSection.StatsLoader}
            sx={{ fontSize: muiTheme.typography.h2.fontSize }}
            width="100%"
          />
        ) : typeof value === "string" || typeof value === "number" ? (
          <Typography variant="h2">{value?.toLocaleString()}</Typography>
        ) : (
          value
        )}
      </Box>
    </Box>
  );
};
