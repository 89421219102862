import { DrawerProps } from "@mui/material/Drawer/Drawer";

import { useLiveStateDrawerContext } from "@/components/k8sAddons/LiveStateDrawer/context/useLiveStateDrawerContext";

export const useCommonDrawerProps = (): DrawerProps => {
  const { onClose, open } = useLiveStateDrawerContext();

  return {
    onClose,
    open,
    anchor: "right",
    PaperProps: {
      sx: { width: "60%", minWidth: "890px" },
    },
  };
};
