import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { NewWindow16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { CUSTOM_EVENTS_DOCS_LINK } from "../../../../shared/constants/docsLinks";

const Container = styled.div`
  padding: 1rem 0;
`;

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: none;

  svg {
    width: 14px;
    height: 14px;
  }

  & > * {
    color: ${palette.darkBlue[500]};
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${palette.darkBlue[500]};
  }
`;

export const CustomEventsLink: React.FC = () => {
  return (
    <Container>
      <Typography size={"medium"}>
        Custom events allow users to enrich the Komodor timeline with external
        events. Read more here:
      </Typography>
      <StyledLink
        href={CUSTOM_EVENTS_DOCS_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <NewWindow16 />
        <Typography size={"medium"}>Custom events documentation</Typography>
      </StyledLink>
    </Container>
  );
};
