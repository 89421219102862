import isElectronContext from "../../../../shared/context/electron/isElectronContext";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

export const useShouldShowAgentDowntime = (): boolean => {
  const { showAgentDowntimeEvent, showAgentDowntimeEventDesktop } =
    useOverridableFlags();

  if (isElectronContext() && showAgentDowntimeEventDesktop) {
    return true;
  }
  return !!showAgentDowntimeEvent;
};
