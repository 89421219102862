import { SEARCH_TYPE_FILTER_OPERATOR } from "../filterSectionConstants";
import {
  ViolationCountBy,
  ViolationsFilterGroupValue,
} from "../../../../../generated/reliabilityApi";
import {
  filterValueToLabel,
  staticViolationFiltersOptions,
} from "../../../constants/violationFiltersConstants";

import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

type FilterOutSearchOptionsOutput = {
  filters: FilterOptionInUrl[];
  searchOptions: FilterOptionInUrl[];
};

export const separateFilterTypes = (filters: FilterOptionInUrl[]) => {
  return filters.reduce<FilterOutSearchOptionsOutput>(
    (acc, filter) => {
      if (filter.operator !== SEARCH_TYPE_FILTER_OPERATOR) {
        acc.filters.push(filter);
      } else {
        acc.searchOptions.push(filter);
      }
      return acc;
    },
    {
      filters: [],
      searchOptions: [],
    }
  );
};

export const convertFilterValueToLabel = (
  filterCategory: ViolationCountBy,
  value: string
) => {
  return filterValueToLabel[filterCategory]?.[value] || value;
};

export const sortFiltersByDefaultValues = (
  filterCategory: ViolationCountBy,
  filtersGroup: ViolationsFilterGroupValue[]
) => {
  const staticOptions = staticViolationFiltersOptions[filterCategory];
  if (staticOptions) {
    return filtersGroup.sort((a, b) => {
      if (staticOptions.indexOf(a.key) === -1) {
        return 1;
      }
      if (staticOptions.indexOf(b.key) === -1) {
        return -1;
      }
      return staticOptions.indexOf(a.key) - staticOptions.indexOf(b.key);
    });
  }
  return filtersGroup;
};
