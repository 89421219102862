import styled from "styled-components";

import { StrictExternalLink } from "../../../../common/Link/ExternalLink";
import { Container } from "../../../../monitorsView/monitorsConfiguration/common/styles";
import { TextRegular } from "../../../../common/typography";

export const StyledExternalLink = styled(StrictExternalLink)`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledContainer = styled(Container)`
  grid-template-rows: auto auto 30rem auto 5rem;
  overflow: visible;
`;

export const StyledFinishContainer = styled(Container)`
  grid-template-rows: auto auto 37rem;
`;

export const StyledLinkText = styled(TextRegular)`
  color: #707583;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
`;
