import styled from "styled-components";

import WorkflowIssueEventGroup, {
  groupWorkflowIssuesEvents,
} from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";

import { StandalonePodIssueBanner } from "./StandalonePodIssueBanner";
import { useOpenIssuesForPod } from "./useOpenIssuesForPod";
import { AvailabilityIssueBanner } from "./AvailabilityIssueBanner";

const Container = styled.div`
  padding-bottom: 16px;
`;

const bannerForIssue = (issueEventGroup: WorkflowIssueEventGroup) => {
  switch (issueEventGroup.workflowType) {
    case WorkflowConfigType.Availability:
      return (
        <AvailabilityIssueBanner
          key={issueEventGroup.id}
          issueEventGroup={issueEventGroup}
        />
      );
    case WorkflowConfigType.Workflow:
    case WorkflowConfigType.Pod:
      return (
        <StandalonePodIssueBanner
          key={issueEventGroup.id}
          issueEventGroup={issueEventGroup}
        />
      );
    default:
      return null;
  }
};

type IssueBannersForPodProps = {
  pod: Pod;
};
export const IssueBannersForPod: React.FC<IssueBannersForPodProps> = ({
  pod,
}) => {
  const { openIssues } = useOpenIssuesForPod(pod);
  if (!openIssues.length) {
    return null;
  }

  const openIssuesEventGroups = groupWorkflowIssuesEvents(openIssues, pod.id);
  if (!openIssuesEventGroups.length) {
    return null;
  }

  return (
    <Container>
      {openIssuesEventGroups.map((eg) => bannerForIssue(eg))}
    </Container>
  );
};
