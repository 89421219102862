import { IconButton } from "@komodorio/design-system/deprecated";
import { Copy16 } from "@komodorio/design-system/icons";
import React from "react";
import { TooltipWrapper } from "react-tooltip";
import useClipboard from "react-use-clipboard";

import { DEFAULT_TOOLTIP } from "../../../../../shared/constants/tooltipIds";

export const CopyButton: React.FC<{
  value: string;
  tooltipText: string;
}> = ({ value, tooltipText }) => {
  const [copied, copy] = useClipboard(value, { successDuration: 3000 });
  return (
    <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content={tooltipText}>
      <IconButton
        icon={Copy16}
        onClick={copy}
        disabled={copied}
        noBorder={true}
      />
    </TooltipWrapper>
  );
};
