import React from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const Container = styled.div`
  padding: 16px 24px;
  width: calc(600px - 48px);
`;

const FooterContainer = styled.div`
  display: flex;
  margin-top: 18px;
  gap: 8px;
  justify-content: flex-end;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;
const StyledText = styled(Typography)`
  && {
    margin-bottom: 24px;
  }
`;

export const DIALOG_TRANSITION_DURATION = 250;

export type ConfirmationDialogStaticProps = {
  title: string;
  textLine1: React.ReactElement | string;
  textLine2?: React.ReactElement | string;
  actionButtonLabel: string;
  cancelButtonAriaLabel?: string;
};
export type ConfirmationDialogProps = ConfirmationDialogStaticProps & {
  open: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  textLine1,
  textLine2,
  actionButtonLabel,
  cancelButtonAriaLabel,
  isLoading,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={onCancel}
      transitionDuration={DIALOG_TRANSITION_DURATION}
    >
      <Container>
        <StyledTitle variant="h3">{title}</StyledTitle>
        <StyledText variant="body2">{textLine1}</StyledText>
        <StyledText variant="body2">{textLine2}</StyledText>
        <FooterContainer>
          <Button
            variant={"text"}
            onClick={onCancel}
            aria-label={cancelButtonAriaLabel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            color={"error"}
            size={"medium"}
            onClick={onConfirm}
            loading={isLoading}
            aria-label={actionButtonLabel}
          >
            {actionButtonLabel}
          </LoadingButton>
        </FooterContainer>
      </Container>
    </Dialog>
  );
};
