import React, { createContext, useCallback, useState } from "react";
import { isEqual } from "lodash";

import {
  MetricsAggregationType,
  MetricsFullTimeWindow,
} from "../../../../Metrics/types";
import { Dictionary } from "../../../../../shared/types/Dictionary";
import { Timeframe } from "../../../../../shared/types/TimeWindow";

export type WorkloadMetricsTabContextState = {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  initialLoadTime: number;
  setInitialLoadTime: (time: number) => void;
  selectedAggregationMetric: MetricsAggregationType;
  setSelectedAggregationMetric: (metric: MetricsAggregationType) => void;
  graphLoadingState: Dictionary<boolean>;
  setGraphLoadingState: (id: string, loading: boolean) => void;
};

const initialState: WorkloadMetricsTabContextState = {
  timeWindow: {
    start: new Date(new Date().setHours(new Date().getHours() - 24)),
    end: new Date(),
    timeframe: Timeframe.Last24Hours,
  },
  setTimeWindow: () => undefined,
  initialLoadTime: 0,
  setInitialLoadTime: () => undefined,
  selectedAggregationMetric: MetricsAggregationType.Avg,
  setSelectedAggregationMetric: () => undefined,
  graphLoadingState: {},
  setGraphLoadingState: () => undefined,
};

export const WorkloadMetricsTabContext =
  createContext<WorkloadMetricsTabContextState>(initialState);

const {
  timeWindow: initialTimeWindow,
  selectedAggregationMetric: initialSelectedMetric,
  graphLoadingState: initialGraphLoadingState,
  initialLoadTime: initialInitialLoadTime,
} = initialState;

type WorkloadMetricsTabContextProviderProps = {
  children: React.ReactNode;
  givenTimeWindow?: MetricsFullTimeWindow;
  givenAggregationMetric?: MetricsAggregationType;
  onLoadingStateChange?: (loadingState: Dictionary<boolean>) => void;
};

export const WorkloadMetricsTabContextProvider: React.FC<
  WorkloadMetricsTabContextProviderProps
> = ({
  children,
  givenTimeWindow,
  givenAggregationMetric,
  onLoadingStateChange,
}) => {
  const [timeWindow, setTimeWindow] = useState<MetricsFullTimeWindow>(
    givenTimeWindow ?? initialTimeWindow
  );
  const [selectedAggregationMetric, setSelectedAggregationMetric] =
    useState<MetricsAggregationType>(
      givenAggregationMetric ?? initialSelectedMetric
    );
  const [graphLoadingState, setGraphLoadingState] = useState<
    Dictionary<boolean>
  >(initialGraphLoadingState);
  const [initialLoadTime, setInitialLoadTime] = useState<number>(
    initialInitialLoadTime
  );

  const onSetTimeWindow = useCallback(
    (tw: Partial<MetricsFullTimeWindow>) => {
      setTimeWindow({
        ...timeWindow,
        ...tw,
      });
    },
    [timeWindow]
  );

  const onSetGraphLoadingState = useCallback(
    (id: string, loading: boolean) => {
      const newState = { ...graphLoadingState, [id]: loading };
      if (!isEqual(newState, graphLoadingState)) {
        setGraphLoadingState(newState);
        onLoadingStateChange?.(newState);
      }
    },
    [graphLoadingState, onLoadingStateChange]
  );

  const contextState: WorkloadMetricsTabContextState = {
    timeWindow: timeWindow,
    setTimeWindow: onSetTimeWindow,
    initialLoadTime,
    setInitialLoadTime,
    selectedAggregationMetric,
    setSelectedAggregationMetric,
    graphLoadingState,
    setGraphLoadingState: onSetGraphLoadingState,
  };

  return (
    <WorkloadMetricsTabContext.Provider value={contextState}>
      {children}
    </WorkloadMetricsTabContext.Provider>
  );
};
