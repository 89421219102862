import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "@komodorio/design-system/komodor-ui";

import { Scope } from "../../../../../../../generated/reliabilityApi";
import { ALL_SELECTOR } from "../../../../../constants/reliabilityConstants";

import { useMultiSelectProps } from "./hooks/useMultiSelectProps";
import {
  isAllOptionSelected,
  optionsToValue,
  toMultiOption,
  toMultiOptions,
} from "./addEditRuleDialogUtils";
import { selectorLabelByType } from "./addEditRuleConstants";

type ResourceMultiSelectProps = {
  type: keyof Scope;
  disabled: boolean;
};

export const ResourceMultiSelect: React.FC<ResourceMultiSelectProps> = ({
  type,
  disabled,
}) => {
  const userChangedOption = useRef(false);
  const { options, values, onUpdate } = useMultiSelectProps(type);
  const [currentOptions, setCurrentOptions] = useState<
    MuiSelectionOption<MuiSelectionOptionValue>[]
  >(toMultiOptions(values));
  const isSelectOpen = useRef(false);

  useEffect(() => {
    if (!userChangedOption.current) {
      onUpdate(values);
      if (currentOptions.length === 0 && values.length > 0) {
        setCurrentOptions(toMultiOptions(values));
      }
    }

    if (options.length === 0 && currentOptions.length > 0) {
      setCurrentOptions([]);
      userChangedOption.current = false;
    }
  }, [currentOptions.length, onUpdate, options.length, values]);

  useEffect(() => {
    const availableOptions = currentOptions.filter((currentOption) => {
      return options.find((option) => option.value === currentOption.value);
    });

    if (availableOptions.length < currentOptions.length) {
      setCurrentOptions(availableOptions);
      onUpdate(optionsToValue(availableOptions) as string[]);
    }
  }, [currentOptions, onUpdate, options]);

  const handleOnChange = useCallback(
    (options: MuiSelectionOption<MuiSelectionOptionValue>[]) => {
      userChangedOption.current = true;
      const allOptionSelected = isAllOptionSelected(options);
      if (allOptionSelected) {
        setCurrentOptions([toMultiOption(ALL_SELECTOR)]);
      } else {
        setCurrentOptions(options);
      }

      if (!isSelectOpen.current) {
        onUpdate(optionsToValue(options) as string[]);
      }
    },
    [onUpdate]
  );

  const handleOnClose = useCallback(() => {
    isSelectOpen.current = false;
    onUpdate(optionsToValue(currentOptions) as string[]);
  }, [currentOptions, onUpdate]);

  const handleOnOpen = useCallback(() => {
    isSelectOpen.current = true;
  }, []);

  const optionsToUse = useMemo(() => {
    const allOptionSelected = isAllOptionSelected(currentOptions);
    if (!allOptionSelected) {
      return options;
    }
    return options.map((option) => ({
      ...option,
      disabled: option.value !== ALL_SELECTOR,
      selected: option.value === ALL_SELECTOR,
    }));
  }, [currentOptions, options]);

  const sortedOptions = useMemo(() => {
    return optionsToUse.sort((a, b) => a.label.localeCompare(b.label));
  }, [optionsToUse]);

  return (
    <AdvancedMultiSelect
      value={currentOptions}
      options={sortedOptions}
      onChange={handleOnChange}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      label={selectorLabelByType[type]}
      width={"270px"}
      disabled={options.length === 0 || disabled}
      ariaLabel={`selector for ${selectorLabelByType[type]}`}
    />
  );
};
