import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";

import { StatusCondition } from "../../../EventGroup/groupedPodEvent/types";
import { SectionName } from "../WorkflowIssueEventDetails/triage/common/styles";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ConditionsContainer } from "./LatestConditions";

export const StatusConditions: React.FC<{
  title: string;
  statusConditions: StatusCondition[] | undefined;
}> = ({ statusConditions, title }) => {
  if (!statusConditions) {
    return null;
  }

  return (
    <div>
      <SectionName>{title}</SectionName>
      <ConditionsContainer>
        <Typography variant={"body2"}>Type</Typography>
        <Typography variant={"body2"}>Status</Typography>
        <Typography variant={"body2"}>Message</Typography>
        {statusConditions.map((condition) => (
          <>
            <Typography key={condition.type} variant={"body2"}>
              {condition.type}
            </Typography>
            <Typography
              key={condition.status}
              color={
                condition.status === "False"
                  ? muiColors.pink[500]
                  : muiColors.gray[800]
              }
              variant={"body2"}
            >
              {condition.status}
            </Typography>
            <Typography key={condition.message} variant={"body2"}>
              {condition.message ?? "-"}
            </Typography>
          </>
        ))}
      </ConditionsContainer>
    </div>
  );
};
