import { useCallback } from "react";

import { useDeleteIgnoreRule } from "../../../../../../../../shared/hooks/reliability-api/violations/useChecksIgnoreRules";
import {
  useOnRuleChange,
  useUpdateIgnoreRulesCache,
} from "../../../../../../hooks/ignoreRulesHooks";
import { useReportError } from "../../../../../../hooks/useReportError";
import { IgnoreRule } from "../../../../../../../../generated/reliabilityApi";

export const useDeleteRule = () => {
  const { mutateAsync } = useDeleteIgnoreRule();
  const updateIgnoreRulesCache = useUpdateIgnoreRulesCache();
  const onRuleChange = useOnRuleChange();
  const reportError = useReportError();

  return useCallback(
    async (rule: IgnoreRule) => {
      try {
        await mutateAsync({
          id: rule.id,
        });
        updateIgnoreRulesCache(rule, "delete");
      } catch (e) {
        reportError(e, "deleteIgnoreRule");
      }

      await onRuleChange();
    },
    [mutateAsync, onRuleChange, reportError, updateIgnoreRulesCache]
  );
};
