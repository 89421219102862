import React, { useRef, useMemo } from "react";
import ReactFlow, {
  Edge,
  Node,
  ReactFlowProvider,
  Controls,
  ControlButton,
  useReactFlow,
} from "react-flow-renderer";
import styled from "styled-components";

import plusIcon from "../monitorsView/assets/zoom-plus.svg";
import minusIcon from "../monitorsView/assets/zoom-minus.svg";
import {
  useMutationObserver,
  MutationObserverParams,
} from "../../shared/hooks/useMutationObserver";

import { CustomInputNode } from "./CustomInputNode";
import { GRAPH_CONTAINER_ID, INITIAL_X, INITIAL_Y } from "./constants";
import { removeDefaultControlButtons } from "./utils/graphUtils";

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledControlButton = styled(ControlButton)<{ isFirstChild?: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.85rem 1rem;
  border-bottom: none;
  font-size: 12px;

  :hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  ${({ isFirstChild }) =>
    isFirstChild &&
    `
    border-radius: 4px 4px 0 0;
  `}

  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const FlowControls: React.FC<{
  containerRef: React.RefObject<HTMLDivElement>;
}> = ({ containerRef }) => {
  const { zoomIn, zoomOut, setViewport } = useReactFlow();
  const initViewport = { x: INITIAL_X, y: INITIAL_Y, zoom: 1 };

  const mutationParams: MutationObserverParams<HTMLDivElement> = useMemo(
    () => ({
      ref: containerRef,
      callback: (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            const controlsElement = document.getElementsByClassName(
              "react-flow__controls-button"
            );
            removeDefaultControlButtons(controlsElement);
            break;
          }
        }
      },
      options: {
        childList: true,
        subtree: true,
      },
    }),
    [containerRef]
  );

  useMutationObserver(mutationParams);

  return (
    <Controls
      draggable
      id="react-flow-controls-container"
      style={{
        bottom: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <StyledControlButton isFirstChild onClick={() => zoomIn()}>
        <img src={plusIcon} alt="plus" />
      </StyledControlButton>
      <StyledControlButton onClick={() => zoomOut()}>
        <img src={minusIcon} alt="minus" />
      </StyledControlButton>
      <StyledControlButton onClick={() => setViewport(initViewport)}>
        Reset
      </StyledControlButton>
    </Controls>
  );
};

export const DependenciesMap: React.FC<{
  nodes: Node[];
  edges: Edge[];
}> = ({ nodes, edges }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container id={GRAPH_CONTAINER_ID} ref={containerRef}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{
            customInput: CustomInputNode,
          }}
        >
          <FlowControls containerRef={containerRef} />
        </ReactFlow>
      </ReactFlowProvider>
    </Container>
  );
};
