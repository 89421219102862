import React, { useState } from "react";
import styled from "styled-components";
import { Button, Popover } from "@komodorio/design-system/deprecated";
import { Ellipsis16 } from "@komodorio/design-system/icons";

import { appViewAriaLabels } from "../../../../appViewAriaLabels";

import { AppViewActionsStateProvider } from "./internal/AppViewActionsStateProvider";
import { ActionsMenu } from "./internal/ActionsMenu";
import { AppViewDeletionDialog } from "./internal/AppViewDeletionDialog";

const SmallButton = styled(Button)`
  padding: 5px;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const {
  overviewPage: {
    header: { actionButton: actionButtonAriaLabel },
  },
} = appViewAriaLabels;

export const AppViewActionsButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <AppViewActionsStateProvider>
      <Popover
        isOpen={open}
        handleClose={onClose}
        content={<ActionsMenu closeMenu={onClose} />}
      >
        <SmallButton
          variant={"default"}
          size={"large"}
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
          icon={Ellipsis16}
          aria-label={actionButtonAriaLabel}
        />
      </Popover>
      <AppViewDeletionDialog />
    </AppViewActionsStateProvider>
  );
};
