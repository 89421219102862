import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Metadata } from "@komodorio/design-system/komodor-ui";

const Capitalized = styled.span`
  text-transform: capitalize;
`;

type MetricsTitleProps = {
  kind: string;
  metadata: {
    title: string;
    value: string;
    tooltipTitle?: string;
    onValueClick?: () => void;
  }[];
};

const Container = styled.div``;
const MetadataItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const MetricsTitle = ({ kind, metadata }: MetricsTitleProps) => {
  return (
    <Container>
      <Typography variant="h3">
        <Capitalized>{kind}</Capitalized> metrics
      </Typography>
      <MetadataItems>
        {metadata.map((item) => (
          <Metadata
            key={item.title}
            size="small"
            title={item.title}
            value={item.value}
            tooltipProps={{ title: item.tooltipTitle }}
            onValueClick={item.onValueClick}
          />
        ))}
      </MetadataItems>
    </Container>
  );
};
