import { MessageEnricher, IStore } from "./types";

export class TrieNode {
  children: Map<string, TrieNode> = new Map();
  enrichers: MessageEnricher[] = [];
}

export class Trie implements IStore<MessageEnricher> {
  root: TrieNode = new TrieNode();

  insert(elem: MessageEnricher): void {
    let node: TrieNode = this.root;

    if (elem.searchKey() === "") {
      node.enrichers.push(elem);
      return;
    }

    for (const char of elem.searchKey()) {
      if (!node.children.has(char)) {
        node.children.set(char, new TrieNode());
      }
      node = node.children.get(char) ?? new TrieNode();
    }

    node.enrichers.push(elem);
  }

  get(message: string): MessageEnricher[] {
    let node: TrieNode = this.root;
    let enrichers: MessageEnricher[] = [];

    for (const char of message) {
      node = node.children.get(char) ?? new TrieNode();
      enrichers.push(...(node.enrichers || []));
    }

    if (enrichers.length === 0) {
      enrichers = this.root.enrichers || [];
    }

    return enrichers;
  }
}
