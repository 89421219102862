import { useCallback } from "react";

import { eventFilterDefinitions } from "../../../../../../../../ResourceView/tabs/EventsTab/content/filters/eventFiltersDefinitions";
import { useFilterState } from "../../../../../../../../ResourceView/tabs/EventsTab/content/filters/useFilteredEvents";
import useTimeWindowFromURL from "../../../../../../../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL";
import { getInitialTimeWindow } from "../../../../../utils/timeWindow";
import {
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
} from "../../../../../../../../../shared/config/urlSearchParamsKeys";
import { NonCustomTimeframe } from "../../../../../../../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { defaultOptions as timeframes } from "../../../../../../../../common/DateTimeSelector";

const eventTypeSelectorKey =
  eventFilterDefinitions.find((filter) => filter.title === "Event type")
    ?.selectorKey ?? "";

export const useSetEventsTabEventTypeFilter = (): ((
  filterType: string
) => void) => {
  const [, setState] = useFilterState();
  return useCallback(
    (filterType: string) => {
      setState({ [eventTypeSelectorKey]: [filterType] });
    },
    [setState]
  );
};

export const useSetTimeFrameInEventsTabFilters = (
  timeFrame: NonCustomTimeframe
) => {
  const [, setTimeWindow] = useTimeWindowFromURL(
    getInitialTimeWindow(timeFrame),
    EVENTS_TAB_TIME_WINDOW,
    EVENTS_TAB_TIMEFRAME
  );
  return useCallback(() => {
    setTimeWindow({
      timeframe: timeframes[timeFrame],
    });
  }, [setTimeWindow, timeFrame]);
};
