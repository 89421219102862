import { toNumber } from "lodash";
import { useMemo } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
export const LOG_ANALYZER_MAX_LINES = 50;

export const useLogsMaxLinesNumber = () => {
  const { podsLogsTail } = useOverridableFlags();

  return useMemo(
    () => (podsLogsTail ? toNumber(podsLogsTail) : LOG_ANALYZER_MAX_LINES),
    [podsLogsTail]
  );
};
