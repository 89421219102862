import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl, useAPIPost } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead.
 */
export const useKomodorCostAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<EndpointsResponseMap[T]> => {
  const url = EndpointRequestPathMap[relativePath](
    parameters as never,
    getAPIBasePath()
  );
  return useApiGetWithUrl<EndpointsResponseMap[T]>(url, pause);
};

/**
 * @deprecated use react-query instead.
 */
export const useKomodorCostAPIPost = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  reqBody: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<EndpointsResponseMap[T]> => {
  const url = EndpointRequestPathMap[relativePath](
    relativePath as never,
    getAPIBasePath()
  ) as keyof EndpointsResponseMap;
  return useAPIPost<EndpointsResponseMap[T]>(url, reqBody, pause, "");
};

const getAPIBasePath = (): string => {
  return getAppConfig().komodorCostAPIServerURL ?? "";
};
