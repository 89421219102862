import { muiColors } from "@komodorio/design-system";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useCallback, useMemo, useState } from "react";

import { PATTERN_REGEX } from "./constants";
import { StyledTextField } from "./styles";

import { AriaLabels } from "@/shared/config/ariaLabels";

type ClustersPreviewListProps = {
  patterns: string[];
  onChange: (pattern: string, index: number) => void;
  onRemove: (index: number) => void;
  onReportErrors: (hasErrors: boolean) => void;
  onPatternBlur: (pattern: string) => void;
};
export function ClustersGroupPatternFields({
  patterns,
  onChange,
  onRemove,
  onReportErrors,
  onPatternBlur,
}: ClustersPreviewListProps) {
  const hasPatternError = (pattern: string) => {
    return PATTERN_REGEX.test(pattern) === false;
  };

  const [errors, setErrors] = useState<Record<number, boolean>>({});

  const hasAnyError = useMemo(() => {
    return patterns.some(hasPatternError);
  }, [patterns]);

  const submitPattern = useCallback(
    (pattern: string, i: number) => {
      setErrors((current) => {
        const newErrors = { ...current };
        newErrors[i] = pattern === "" || hasPatternError(pattern);
        return newErrors;
      });
      onReportErrors(hasAnyError);
      onPatternBlur(pattern);
    },
    [hasAnyError, onPatternBlur, onReportErrors]
  );

  const patternFields = useMemo(
    () =>
      patterns.map((pattern, i) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            gap="4px"
            alignItems="flex-start"
            key={`pattern-${i}`}
          >
            <StyledTextField
              inputProps={{
                "aria-label":
                  AriaLabels.Workspaces.ClusterGroupForm.PatternInput,
              }}
              size="small"
              value={pattern}
              placeholder="e.g. *prod*"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  submitPattern(pattern, i);
                }
              }}
              onChange={(e) => {
                onChange(e.target.value, i);
              }}
              error={errors[i] === true}
              helperText={errors[i] === true ? "The pattern is invalid" : null}
              onBlur={() => submitPattern(pattern, i)}
            />
            <Typography
              variant="body2"
              sx={{
                width: "32px",
                height: "32px",
                color: muiColors.gray[400],
                display: "grid",
                placeItems: "center",
              }}
            >
              or
            </Typography>
            {i === 0 ? null : (
              <IconButton
                sx={{ padding: "6px", height: "32px", width: "32px" }}
                onClick={() => {
                  setErrors(() => {
                    const newErrors = { ...errors };
                    delete newErrors[i];
                    return newErrors;
                  });
                  onRemove(i);
                }}
              >
                <Close
                  sx={{
                    fontSize: "20px",
                    color: muiColors.gray[500],
                  }}
                />
              </IconButton>
            )}
          </Box>
        );
      }),
    [errors, onChange, onRemove, patterns, submitPattern]
  );
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {patternFields}
    </Box>
  );
}
