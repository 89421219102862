import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { RbacCustomK8sAction } from "../../../generated/auth";

import { Action } from "./common";

export const RbacActionTypesOptions: MuiSelectionOption<string>[] = [
  {
    value: "komodor",
    label: "Komodor",
  },
  {
    value: "kubernetes",
    label: "K8s",
  },
  {
    value: "komodor-and-kubernetes",
    label: "Komodor & K8s",
  },
];

export const RbacActionTypes: Record<string, string> =
  RbacActionTypesOptions.reduce((acc, { value, label }) => {
    (acc as { [key: string]: string })[value] = label;
    return acc;
  }, {});

export const ReadOnlyActionTypes = ["komodor", "komodor-and-kubernetes"];

export const isCustomK8sAction = (
  action: Action
): action is RbacCustomK8sAction => {
  // Custom actions have an account ID - global actions do not
  return (action as RbacCustomK8sAction).accountId !== undefined;
};
