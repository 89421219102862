import { AriaLabels } from "../../../shared/config/ariaLabels";
import { dispatchEvent } from "../../../shared/hooks/analytics";

import { MessageEnricher, IStore, EnrichedMessage, IEnricher } from "./types";
import { Trie } from "./trie";

export class Store implements IStore<MessageEnricher>, IEnricher {
  private static instance: Store | undefined;
  private store: Trie;

  private constructor() {
    this.store = new Trie();
  }

  public static getInstance(enrichers?: MessageEnricher[]): Store {
    if (!Store.instance) {
      Store.instance = new Store();
      if (enrichers) {
        Store.instance.init(enrichers);
      }
    }
    return Store.instance;
  }

  insert(enricher: MessageEnricher): void {
    this.store.insert(enricher);
  }

  get(message: string): MessageEnricher[] {
    return this.store.get(message);
  }

  enrich(message: string): EnrichedMessage {
    const enrichers = this.get(message);
    if (enrichers.length > 0) {
      for (const enricher of enrichers) {
        const enriched = enricher.enrich(message);
        if (enriched) {
          dispatchEvent(AriaLabels.InvestigationMode.EnrichedMessage, {
            originalMessage: message,
          });
          return enriched;
        }
      }
    }
    return {
      originalMessage: message,
      messageTokens: [],
      hasPrecedenceOverAIInvestigation: false,
    };
  }

  private init(enrichers: MessageEnricher[]) {
    enrichers.forEach((enricher) => this.insert(enricher));
  }

  public static reset() {
    Store.instance = undefined;
  }
}
