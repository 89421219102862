import { useMemo } from "react";

import { useCertificateData } from "./useCertificateData";

import { useActiveAgent } from "@/shared/hooks/useAgents";
import { getButton } from "@/components/Actions/common/getActionButtonStyle";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import CertManagerCertificate, {
  CertManagerCertificateType,
} from "@/components/ResourceView/resources/certManagerCertificate";

export const useGetDescribeButtonProps = () => {
  const { data: certificateData } = useCertificateData();
  const clusterName = certificateData?.data.object.header.clusterName || "";
  const agentId = useActiveAgent(clusterName) ?? "";

  return useMemo(() => {
    const resource = new CertManagerCertificate(
      clusterName,
      (certificateData?.data.json || {}) as CertManagerCertificateType,
      agentId
    );
    const actionItem = getButton(
      AvailableActions.Describe,
      false, // disableAll,
      resource
    );

    return { actionItem, resource };
  }, [agentId, certificateData?.data.json, clusterName]);
};
