import React, { useState } from "react";
import styled from "styled-components";
import Link from "@mui/material/Link";

import { K8sDiff } from "../../../EventGroup/deployEvent/getK8sDiff";
import { Specs } from "../../../EventGroup/deployEvent/getSpecsForDiff";

import K8sDiffModal from "./K8sDiffModal";

const Gap = styled.div`
  margin-block-start: 0.8rem;
`;

interface K8sDiffLinkProps {
  k8sDiff: K8sDiff | undefined;
  specsForDiff: Specs | null;
  versionFrom: string;
  versionTo: string;
  serviceName: string;
  clusterName?: string;
  namespace?: string;
  isFirstDeployment?: boolean;
}
const K8sDiffLink: React.FC<K8sDiffLinkProps> = ({
  k8sDiff,
  specsForDiff,
  versionFrom,
  versionTo,
  serviceName,
  clusterName,
  namespace,
  isFirstDeployment,
}) => {
  const [showK8sDiff, setShowK8sDiff] = useState(false);
  const { changes, hiddenCount } = k8sDiff ?? {
    changes: [],
    hiddenCount: 0,
  };

  return (
    <>
      {!isFirstDeployment && <Gap />}
      <Link onClick={() => setShowK8sDiff(true)} sx={{ cursor: "pointer" }}>
        {isFirstDeployment
          ? "View YAML"
          : `View all ${changes.length + hiddenCount} changes on diff`}
      </Link>
      <K8sDiffModal
        open={showK8sDiff}
        onClose={() => setShowK8sDiff(false)}
        specs={specsForDiff}
        versionFrom={versionFrom}
        versionTo={versionTo}
        serviceName={serviceName}
        clusterName={clusterName}
        namespace={namespace}
        isFirstDeployment={isFirstDeployment}
      />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default K8sDiffLink;
