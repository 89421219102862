import {
  MetricsApiGetWorkloadContainersMetricsRequest,
  WorkloadMetricsContainers,
} from "../../../../../../../../../../generated/metricsApi";
import { useMetricsAPIGet } from "../../../../../../../../../../shared/hooks/metrics-api/client";
import { METRICS_WORKLOAD_CONTAINERS_ENDPOINT } from "../../../../../../../../../../shared/hooks/metrics-api/requestResponseMaps";

/** @deprecated use useGetWorkloadContainersMetrics instead */
export const useFetchContainersMetricsData = (
  params: MetricsApiGetWorkloadContainersMetricsRequest,
  pause: boolean
) => {
  return useMetricsAPIGet<WorkloadMetricsContainers>(
    METRICS_WORKLOAD_CONTAINERS_ENDPOINT,
    params,
    pause
  );
};
