import { useMemo } from "react";

import { useDesktopAgentsStatusContext } from "../desktopAgentsProvider/desktopAgentsStatusesProvider";
import isElectronContext from "../isElectronContext";

export const useShowNoClustersTracked = (): boolean => {
  const agentStatuses = useDesktopAgentsStatusContext();

  const agentStatusValues = useMemo(() => {
    return Object.values(agentStatuses ?? {});
  }, [agentStatuses]);

  return useMemo(() => {
    if (!isElectronContext() || agentStatusValues.length) return false;
    return !agentStatusValues.length;
  }, [agentStatusValues]);
};
