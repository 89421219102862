import { useMemo } from "react";
import { Deploy24 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { useGetDeployEventsByViolationEpochs } from "../../../../hooks/useGetDeployEventsByViolationEpochs";
import { findClosestElementsInList } from "../../../../../../../../../../shared/utils/findClosestElementsInList";
import {
  EventData,
  GraphDataPoint,
} from "../../../../../../../../../Metrics/types";
import { DeployEventPopoverContent } from "../components/DeployEventPopoverContent/DeployEventPopoverContent";

import { useIsViewDeployEventDetailsClicked } from "./useIsViewDeployEventDetailsClicked";

type GetCorrelatedDeployEventsParams = {
  graphDataPoints?: GraphDataPoint[];
  komodorUid: string;
};

export const useGetCorrelatedDeployEvents = ({
  graphDataPoints,
  komodorUid,
}: GetCorrelatedDeployEventsParams) => {
  const { data: deploysData } = useGetDeployEventsByViolationEpochs(komodorUid);

  const shouldClosePopoverContentOnClickCapture =
    useIsViewDeployEventDetailsClicked();

  return useMemo(() => {
    const deploysDataAsTimeStamp = deploysData?.data.data.map((deploy) => ({
      ...deploy,
      time: new Date(deploy.eventTime).getTime(),
    }));

    if (graphDataPoints && deploysDataAsTimeStamp) {
      const closestElementsInList = findClosestElementsInList({
        targetArr: graphDataPoints,
        targetNumProperty: "time",
        inputArr: deploysDataAsTimeStamp ?? [],
        inputNumProperty: "time",
      });
      return Object.entries(closestElementsInList).map<EventData>(
        ([_, { targetElement, inputElement }]) => ({
          time: targetElement.time,
          icon: Deploy24,
          popoverContent: {
            element: <DeployEventPopoverContent deployEvent={inputElement} />,
            isInteractive: true,
            shouldCloseOnClickCapture: shouldClosePopoverContentOnClickCapture,
          },
          color:
            inputElement.status === "Failure"
              ? muiColors.pink[600]
              : muiColors.green[700],
          highlight: false,
        })
      );
    }

    return undefined;
  }, [
    deploysData?.data.data,
    graphDataPoints,
    shouldClosePopoverContentOnClickCapture,
  ]);
};
