import { groupBy } from "lodash";
import { useMemo } from "react";

import { StaticPreventionResult } from "../../StaticPreventionUtils";

export const useCategories = (parsedResults: StaticPreventionResult) => {
  return useMemo(
    () =>
      Object.keys(
        groupBy(
          [...parsedResults.ignoredChecks, ...parsedResults.checks],
          "Category"
        )
      ),
    [parsedResults.checks, parsedResults.ignoredChecks]
  );
};
