import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EventsApiApiV1NodesEventsIdGetRequest,
  NodeEventsResponse,
  apiV1NodesEventsIdGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const NODES_EVENTS_ID_PATH = "/nodes/events/:id";

const fetchNodeEventById = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1NodesEventsIdGetRequest
): Promise<NodeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1NodesEventsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNodeEventById = (
  params: EventsApiApiV1NodesEventsIdGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [NODES_EVENTS_ID_PATH, params],
    () => fetchNodeEventById(apiClient, params),
    {
      enabled,
    }
  );
};
