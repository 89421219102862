import React from "react";
import styled from "styled-components";

import { LinesLoader } from "../../components/common/loaders/Line";

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <LinesLoader marginTop={"0"} />
    </LoaderContainer>
  );
};
