import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EventsApiApiV1WorkloadsPodsEventsSearchGetRequest,
  PodEventsResponse,
  apiV1WorkloadsPodsEventsSearchGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const WORKLOADS_PODS_EVENT_SEARCH_PATH = "/workloads/pods/events/search";

const fetchPodEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsPodsEventsSearchGetRequest
): Promise<PodEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsPodsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetPodEvents = (
  params: EventsApiApiV1WorkloadsPodsEventsSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_PODS_EVENT_SEARCH_PATH, params],
    () => fetchPodEvents(apiClient, params),
    {
      enabled,
    }
  );
};
