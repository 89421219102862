import React, { useMemo } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

import {
  columnsGroupingModel,
  metricsTableContentColumns,
  getEmptyColumns,
} from "./metricsTableColumns";
import { MetricRowKeys, MetricRows, MetricsTableProps } from "./types";

const emptyRows = [
  { id: "empty1" },
  { id: "empty2" },
  { id: "empty3" },
] as MetricRows[];

export const MetricsTable: React.FC<MetricsTableProps> = ({
  metrics,
  metricsType,
  category,
  expectedNumOfMetrics,
  isLoading,
}) => {
  const rows = useMemo(() => {
    return metrics.map<MetricRows>((metric) => {
      return {
        [MetricRowKeys.id]: metric.containerName,
        [MetricRowKeys.name]: metric.containerName,
        [MetricRowKeys.avg]: metric.summary.avgUtilization,
        [MetricRowKeys.p99]: metric.summary.p99Utilization,
        [MetricRowKeys.max]: metric.summary.maxUtilization,
      };
    });
  }, [metrics]);

  const columns = metricsTableContentColumns(metricsType, category);
  const columnsGrouping = columnsGroupingModel(metricsType);

  const emptyColumns = getEmptyColumns(metricsType, category);

  const loadingRows = emptyRows.slice(0, expectedNumOfMetrics);

  if (!metrics.length && !isLoading) return null;

  return (
    <DataGridPro
      experimentalFeatures={{ columnGrouping: true }}
      rows={isLoading ? loadingRows : rows}
      columns={isLoading ? emptyColumns : columns}
      columnGroupingModel={columnsGrouping}
      disableColumnReorder
      disableColumnResize
      disableColumnMenu
      hideFooter
    />
  );
};
