import { useMemo } from "react";

import { CurrentService } from "../useCurrentService";
import { mapPgServiceToServiceMetadata } from "../../utils/postgresMigrationUtils";
import { KubernetesPodsResource } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useLabelSelector } from "../../utils/podUtils";
import { useActiveAgent } from "../useAgents";

import useAtmResourcesListLiveResult, {
  ListResourcesResult,
} from "./useAtmResourcesListLiveResult";

type GetPodAtmResourcesParams = {
  currentService: CurrentService;
  namespace?: string;
  agentId?: string;
  cluster?: string;
};

export const useGetPodAtmResources = ({
  currentService,
  namespace,
  cluster,
  agentId,
}: GetPodAtmResourcesParams): ListResourcesResult => {
  const selector = currentService
    ? mapPgServiceToServiceMetadata(currentService)?.selector
    : undefined;
  const labelSelector = useLabelSelector(selector);

  const namespaces = useMemo(
    () =>
      namespace
        ? [namespace]
        : currentService?.namespace
        ? [currentService.namespace]
        : undefined,
    [currentService?.namespace, namespace]
  );

  const currentCluster = useMemo(
    () => cluster ?? currentService?.k8sClusterName ?? "",
    [cluster, currentService?.k8sClusterName]
  );
  const activeAgent = useActiveAgent(currentCluster) ?? "";

  return useAtmResourcesListLiveResult({
    agentId: agentId ?? activeAgent,
    cluster: currentCluster,
    namespaces,
    labelSelector,
    resourceKind: KubernetesPodsResource.Kind,
  });
};
