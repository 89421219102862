import React from "react";
import Typography from "@mui/material/Typography";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DeployEventGroup from "../../../EventGroup/deployEvent/DeployEventGroup";

import { useImageChanges } from "./useImageChanges";

export const HeaderSummary: React.FC<{
  spec: Record<string, unknown> | undefined;
  eventGroup: DeployEventGroup;
}> = ({ spec, eventGroup }) => {
  const event = eventGroup.events[eventGroup.events.length - 1];
  const images = useImageChanges(spec, event);
  const testId = "deploy-header-summary";

  return (
    <Typography
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      data-testid={testId}
      variant={"body3"}
    >
      {eventGroup.generation === 1 ? (
        <>Deployed new service</>
      ) : images.length === 0 ? (
        <>No image changes</>
      ) : images.length === 1 ? (
        <>
          Image updated to: <strong>{images[0]}</strong>
        </>
      ) : (
        <>
          Image updated to: <strong>{images[0]}</strong> (+{images.length - 1}{" "}
          other image changes)
        </>
      )}
    </Typography>
  );
};
