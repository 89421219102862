import { format } from "date-fns";
import styled from "styled-components";

import { AllocationFiltersTimeWindow } from "../types/costOptimizationTypes";

export const ColorIndication = styled.div<{ isunselected?: boolean }>`
  height: 0.625rem;
  width: 0.625rem;
  border: 2px solid ${(props) => props.color};
  ${(props) => !props.isunselected && `background-color: ${props.color};`};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const graphUnits = {
  price: "$",
  cpu: "Cores",
  memory: "GB",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getDateKey = (
  seconds: number,
  timeWindow?: AllocationFiltersTimeWindow
): string => {
  const ms = seconds * 1000;
  if (timeWindow === "24h") return `${format(new Date(ms), "HH")}:00`;

  return format(new Date(ms), "MMM d");
};
