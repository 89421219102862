import React from "react";
import styled from "styled-components";

import { Commit } from "../../../../../../shared/types/git";
import { DetailsExternalLink } from "../../common";

import { GitType } from "./GetGitTypeAndProviderData";

const PR_MESSAGE_REGEX = /\(#[0-9]*\)/;
const cleanGitMessage = (message: string) => {
  const prMessage = message.split(PR_MESSAGE_REGEX);
  if (prMessage?.length) {
    return prMessage[0].trimEnd();
  }
  return message;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getText: (commit: Commit, prPrefix: string) => string = (
  commit: Commit,
  prPrefix: string
) =>
  cleanGitMessage(commit.message) +
  (commit.pr_num
    ? ` (${prPrefix}${commit.pr_num})`
    : ` (${commit.sha.slice(0, 5)})`);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isGitTypeNeedInstallation: (gitType: GitType) => boolean = (
  gitType
) => gitType === GitType.Github || gitType === GitType.Gitlab;

const Author = styled.div`
  font-weight: 500;
  color: #8a8d91;
`;

interface GitChangeComponentProps {
  href: string;
  title: string;
  authorName: string;
  id: number;
}

export const GitChangeComponent: React.FC<GitChangeComponentProps> = ({
  href,
  title,
  authorName,
  id,
}) => {
  return (
    <>
      <div>
        <DetailsExternalLink
          noUnderline
          key={id}
          color="#3d4048"
          href={href}
          title={title}
        />
      </div>
      <Author>By {authorName}</Author>
    </>
  );
};
