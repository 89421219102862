import React from "react";
import { Quantity } from "kubernetes-types/api/resource.d";

import { DescribeTableItems } from "../common/DescribeItem";
import { Table } from "../../../../../shared/components/Table/Table";
import {
  formatQuantityWithoutUnits,
  formatQuantityWithPrettyBinaryUnits,
} from "../../../../../shared/utils/formatQuantity";

import { resourcesColumns } from "./constants";

type NodeResource = { [name: string]: Quantity };

const formatResourcesFields = (
  nodeResource: NodeResource,
  nodeResourceName: string
): NodeResource => {
  const cloneNodeResource = { ...nodeResource };
  cloneNodeResource.resourceFieldType = nodeResourceName;
  cloneNodeResource.cpu = nodeResource.cpu
    ? formatQuantityWithoutUnits(nodeResource.cpu)
    : "";
  cloneNodeResource.memory = nodeResource.memory
    ? formatQuantityWithPrettyBinaryUnits(nodeResource.memory)
    : "";
  cloneNodeResource[`ephemeral-storage`] = nodeResource[`ephemeral-storage`]
    ? formatQuantityWithPrettyBinaryUnits(nodeResource[`ephemeral-storage`])
    : "";
  return cloneNodeResource;
};

export const NodeResources: React.FC<{
  capacity: NodeResource;
  allocatable: NodeResource;
}> = ({ capacity, allocatable }) => {
  const clonedCapacity = formatResourcesFields(capacity, "Capacity");
  const clonedAllocatable = formatResourcesFields(allocatable, "Allocatable");
  return (
    <DescribeTableItems name="Resources">
      <Table
        data={[clonedCapacity, clonedAllocatable]}
        columns={resourcesColumns}
        sortable={false}
      />
    </DescribeTableItems>
  );
};
