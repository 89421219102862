import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import type { AxiosResponse } from "axios";
import { UseQueryResult } from "@tanstack/react-query";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import {
  BasicViolation,
  GetViolationsResponse,
} from "../../../../../../../../generated/reliabilityApi";
import {
  useEmptyColumns,
  useViolationsTableColumns,
} from "../hooks/violationTableHooks";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { useOpenDrawerFromDataGrid } from "../../../ViolationsDrawer/hooks/drawerHooks";
import { useInitialGroupState } from "../hooks/useInitialGroupState";
import { tableProps } from "../../violationsBreakdownUtils";

import { EmptyTableResults } from "./EmptyTableResults";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import {
  selectSetGroupState,
  selectViolationsState,
} from "@/components/reliability/store/reliabilityStoreSelectors";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { generateEmptyRows } from "@/shared/utils/tableUtils";
import { useRowsCount } from "@/components/reliability/components/shared/table/useRowsCount";

type StyledComponentsProps = {
  $isEmptyResults: boolean;
};

const EmptyResultsStyle = css<StyledComponentsProps>`
  opacity: ${({ $isEmptyResults }) => ($isEmptyResults ? 0 : 1)};
  pointer-events: ${({ $isEmptyResults }) =>
    $isEmptyResults ? "none" : "auto"};
`;

const Container = styled.div<StyledComponentsProps>`
  position: relative;
  && {
    .MuiDataGrid-footerContainer {
      ${EmptyResultsStyle};
    }
    .MuiDataGrid-row {
      ${EmptyResultsStyle};
    }
  }
`;

const StyledDataGridPro = styled(DataGridPro)`
  && {
    .MuiDataGrid-row:hover {
      cursor: pointer;
    }
  }
`;

type CustomPaginationModel = {
  customPaginationModel: GridPaginationModel;
  setCustomPaginationModel: (newPaginationModel: GridPaginationModel) => void;
};

type ViolationsTableProps = {
  violationsRes: UseQueryResult<AxiosResponse<GetViolationsResponse>>;
  containerWidth?: number;
  customPaginationObj?: CustomPaginationModel;
  violationTableType?: ViolationsTableType;
  hideFooter?: boolean;
};

export const ViolationsTable: React.FC<ViolationsTableProps> = ({
  violationsRes,
  containerWidth,
  customPaginationObj,
  violationTableType,
  hideFooter = false,
}) => {
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const isBestPracticeTab = useIsBestPracticesTab();

  const { customPaginationModel, setCustomPaginationModel } =
    customPaginationObj ?? {};

  const { groups } = useReliabilityStore(selectViolationsState);
  const setGroupsState = useReliabilityStore(selectSetGroupState);

  const initialGroupState = useInitialGroupState(violationsGroupBy);

  const { data: violationsData, isFetching, error } = violationsRes;
  const rowsCount = useRowsCount(violationsData?.data?.totalResults);

  const paginationModel = useMemo(() => {
    if (customPaginationModel) {
      return customPaginationModel;
    }
    return (
      groups[violationsGroupBy][uid ?? ""]?.paginationModel ??
      initialGroupState.paginationModel
    );
  }, [
    customPaginationModel,
    groups,
    initialGroupState.paginationModel,
    uid,
    violationsGroupBy,
  ]);

  const violationTableTypeByTab: ViolationsTableType =
    violationTableType ??
    (isBestPracticeTab
      ? ViolationsTableType.impactAnalysis
      : ViolationsTableType.riskAssessment);

  const columns = useViolationsTableColumns({
    violationTableType: violationTableTypeByTab,
  });
  const emptyColumns = useEmptyColumns({
    violationTableType: violationTableTypeByTab,
  });

  const isEmptyResults = violationsData?.data.violations?.length === 0;
  const hasNoResults = isEmptyResults || !!error;

  const columnsToUse = isFetching || hasNoResults ? emptyColumns : columns;

  const rowsToUse =
    isFetching || hasNoResults
      ? generateEmptyRows<BasicViolation>(paginationModel.pageSize)
      : violationsData?.data.violations;

  const setPaginationModel = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          paginationModel: newPaginationModel,
        },
      });
    },
    [setGroupsState, uid, violationsGroupBy]
  );

  const rowClickHandler = useOpenDrawerFromDataGrid();
  return (
    <Container
      $isEmptyResults={hasNoResults}
      style={containerWidth ? { width: containerWidth } : {}}
    >
      <StyledDataGridPro
        {...tableProps}
        rows={rowsToUse ?? []}
        onRowClick={rowClickHandler}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowsCount}
        hideFooter={hideFooter}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setCustomPaginationModel ?? setPaginationModel}
      />
      <>{hasNoResults && <EmptyTableResults isError={!!error} />}</>
    </Container>
  );
};
