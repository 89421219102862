import React from "react";
import { cloneDeep, get, set } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import { useIsEmailDigestFormDisabled } from "./useIsEmailDigestFormDisabled";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { DataKeyToLabel } from "@/components/Settings/email/emailSettingsConstants";
import { StyledCheckbox } from "@/components/Settings/email/emailSettingsStyles";
import { emailSettingsAriaLabels } from "@/components/Settings/email/emailSettingsAriaLabels";

type CheckboxControllerProps = {
  contextKey: string;
};

export const CheckboxController: React.FC<CheckboxControllerProps> = ({
  contextKey,
}) => {
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const value = get(context, contextKey) ?? false;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    const newContent = set(cloneDeep(context), contextKey, newValue);
    setConfigurationData(newContent);
  };

  const label: string = get(DataKeyToLabel, contextKey) ?? "";
  const ariaLabel = get(emailSettingsAriaLabels, contextKey) ?? "";
  const isEmailDigestFormDisabled = useIsEmailDigestFormDisabled();

  return (
    <FormControlLabel
      label={<Typography variant={"body2"}>{label}</Typography>}
      control={
        <StyledCheckbox
          checked={value}
          onChange={onChange}
          aria-label={`${ariaLabel} checked: ${value}`}
          disabled={isEmailDigestFormDisabled}
        />
      }
    />
  );
};
