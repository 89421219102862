import React from "react";
import { capitalize } from "lodash";

import { GenericAuditData } from "../types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

import { Audit } from "@/generated/graphql";
import { LazyEditor } from "@/components/common/LazyEditor";

export class AuditGenericDefault implements AuditObject {
  readonly data: Audit;
  readonly auditData: GenericAuditData;
  readonly stringifiedDetails: string;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as GenericAuditData;
    this.stringifiedDetails = JSON.stringify(
      this.auditData.details ?? {},
      null,
      2
    );
  }

  renderDetails() {
    return (
      <LazyEditor
        width="100%"
        readOnly
        value={this.stringifiedDetails}
        maxLines={100}
        fontSize="14px"
      />
    );
  }

  getAffectedResource(): string {
    return "Open for more details";
  }

  renderAffectedResources() {
    return this.getAffectedResource();
  }

  renderAction() {
    return capitalize(
      this.auditData.category.toLowerCase().replaceAll("_", " ")
    );
  }

  renderEventType() {
    return capitalize(
      this.auditData.crudType.toLowerCase().replaceAll("_", " ")
    );
  }

  renderJson(): Record<string, unknown> {
    const change = this.auditData.details?.clusterNames;
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": this.renderEventType(),
      Resource: this.renderEventType(),
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": this.getAffectedResource(),
      Change: JSON.stringify(change),
    };
  }
}
