import React, { useEffect, useRef } from "react";

export type NullableNumber = number | null;

/** TODO: move this file, useSessionStorage and useTimeout to shared/hooks*/
export const useInterval: (
  callback: () => void,
  delay: NullableNumber
) => void = (callback: () => void, delay: NullableNumber) => {
  const savedCallback: React.MutableRefObject<() => void> = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
};
