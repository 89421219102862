import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import ExternalLink from "../../common/Link/ExternalLink";
import useUserMetadata from "../../../shared/hooks/useUserMetadata/useUserMetadata";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem 5rem;
  text-align: center;
  row-gap: 0.5rem;
`;

export const AccountDisabledModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isKomodorAdmin } = useUserMetadata();

  return (
    <Dialog
      open={isOpen}
      onClose={
        isKomodorAdmin
          ? () => {
              setIsOpen(false);
            }
          : undefined
      }
    >
      <DialogContent>
        <Container>
          <Typography variant="h2">Account disabled</Typography>
          <Typography variant="body1">
            Your account has been disabled - Contact us at <br />
            <ExternalLink href={"mailto:info@komodor.io"}>
              info@komodor.io
            </ExternalLink>
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
