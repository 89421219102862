import React from "react";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import {
  Message,
  MessageActions,
  MessageContent,
  MessageHeader,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { openPlansPage } from "../../../shared/utils/freemium";

const MessageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 99999;
  width: fit-content;
  border-radius: 4px;
  box-shadow: 0px 6px 24px rgba(22, 24, 31, 0.3);
`;

const StyledMessageContent = styled(MessageContent)`
  margin-top: 1rem;
  padding-block: 0;
`;

const StyledMessage = styled(Message)`
  border-radius: 50px;
`;

const CenteredMessageHeader = styled(MessageHeader)`
  text-align: center;
`;

const LimitedEventsMessage: React.FC<{
  eventsTotalSum: number;
  eventsLimit: number;
}> = ({ eventsLimit }) => {
  const formattedLimitNumber = new Intl.NumberFormat().format(eventsLimit);

  const getMissingEventsMessage = () => {
    return `Monthly events quota reached`;
  };

  return (
    <>
      <MessageContainer>
        <StyledMessage width="32rem" height="6.5rem">
          <CenteredMessageHeader>
            <Typography variant="headline" size="small">
              {getMissingEventsMessage()}
            </Typography>
          </CenteredMessageHeader>
          <StyledMessageContent>
            <Typography variant="text" size="medium">
              {`You can view up to ${formattedLimitNumber} events each month with your current plan`}
            </Typography>
          </StyledMessageContent>
          <MessageActions>
            <Button onClick={openPlansPage} variant="primary">
              Upgrade
            </Button>
          </MessageActions>
        </StyledMessage>
      </MessageContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LimitedEventsMessage;
