import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

import { DisplayMode, PolicyConfigurationsTabs } from "../policyDrawerTypes";

import { CheckType, PolicyConfigurations } from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";
import { ConfirmationDialogProps } from "@/components/reliability/components/pages/policies/shared/ConfirmationDialog";

type ChecksTableFilters = {
  searchTerm: string;
  impactGroupId: string;
};

type ConfirmationDialogContextProps = Omit<
  ConfirmationDialogProps,
  "open" | "onCancel"
>;

type CheckTypeConfigurationRef = Partial<
  Record<CheckType, React.RefObject<HTMLDivElement>>
>;

export type ConfigurationControlCheckedState = Dictionary<boolean>;

export type PolicyDrawerContextState = {
  policyConfigurations: PolicyConfigurations;
  setSinglePolicyConfiguration: (
    policyConfigurations: PolicyConfigurations
  ) => void;
  configurationsTab: PolicyConfigurationsTabs;
  setConfigurationsTab: (tab: PolicyConfigurationsTabs) => void;
  setAllPolicyConfigurations: (
    policyConfigurations: PolicyConfigurations
  ) => void;
  checksTableFilters: ChecksTableFilters;
  setChecksTableFilter: (searchFilters: Partial<ChecksTableFilters>) => void;
  deletePolicyConfiguration: (policy: keyof PolicyConfigurations) => void;
  onClose: () => void;
  configurationsControlCheckedState: ConfigurationControlCheckedState;
  setSingleControlCheckedState: (
    newExpandedState: ConfigurationControlCheckedState
  ) => void;
  setAllControlCheckedState: (
    configurationControlExpandedState: ConfigurationControlCheckedState
  ) => void;
  isSubmittingForm: boolean;
  setIsSubmittingForm: (isSubmitting: boolean) => void;
  policyId: string | undefined;
  confirmationDialogProps: ConfirmationDialogContextProps | undefined;
  setConfirmationDialogProps: (
    confirmationDialogProps?: ConfirmationDialogContextProps
  ) => void;
  isReadOnlyMode: boolean;
  checkTypeConfigurationRefs: CheckTypeConfigurationRef;
  setCheckTypeConfigurationRef: (
    checkType: CheckType,
    ref: React.RefObject<HTMLDivElement> | null
  ) => void;
  formDetailsRef: React.RefObject<HTMLFormElement> | undefined;
  setFormDetailsRef: (ref: React.RefObject<HTMLFormElement>) => void;
  submitFormRef: React.RefObject<HTMLDivElement> | undefined;
  setSubmitFormRef: (ref: React.RefObject<HTMLDivElement>) => void;
};

const initialState: PolicyDrawerContextState = {
  policyConfigurations: {},
  setSinglePolicyConfiguration: () => undefined,
  configurationsTab: PolicyConfigurationsTabs.dynamic,
  setConfigurationsTab: () => undefined,
  checksTableFilters: {
    searchTerm: "",
    impactGroupId: "",
  },
  setChecksTableFilter: () => undefined,
  deletePolicyConfiguration: () => undefined,
  setAllPolicyConfigurations: () => undefined,
  onClose: () => undefined,
  configurationsControlCheckedState: {},
  setSingleControlCheckedState: () => undefined,
  setAllControlCheckedState: () => undefined,
  isSubmittingForm: false,
  setIsSubmittingForm: () => undefined,
  policyId: undefined,
  confirmationDialogProps: undefined,
  setConfirmationDialogProps: () => undefined,
  isReadOnlyMode: false,
  checkTypeConfigurationRefs: {},
  setCheckTypeConfigurationRef: () => undefined,
  formDetailsRef: undefined,
  setFormDetailsRef: () => undefined,
  submitFormRef: undefined,
  setSubmitFormRef: () => undefined,
};

export const PolicyDrawerContext =
  createContext<PolicyDrawerContextState>(initialState);

const {
  policyConfigurations: initialIsPolicyConfigurations,
  checksTableFilters: initialChecksTableFilters,
  configurationsControlCheckedState: initialConfigurationsControlExpandedState,
  isSubmittingForm: initialIsSubmittingForm,
  confirmationDialogProps: initialConfirmationDialogProps,
} = initialState;

type PolicyDrawerContextProviderProps = PropsWithChildren<{
  onClose: () => void;
  policyId: string | undefined;
  mode: DisplayMode;
}>;

export const PolicyDrawerContextProvider: React.FC<
  PolicyDrawerContextProviderProps
> = ({ children, onClose, policyId, mode }) => {
  const [policyConfigurations, setPolicyConfigurations] =
    useState<PolicyConfigurations>(initialIsPolicyConfigurations);
  const [checksTableFilters, setChecksTableFilters] =
    useState<ChecksTableFilters>(initialChecksTableFilters);
  const [
    configurationsControlCheckedState,
    setConfigurationsControlCheckedState,
  ] = useState<ConfigurationControlCheckedState>(
    initialConfigurationsControlExpandedState
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(
    initialIsSubmittingForm
  );
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<
    ConfirmationDialogContextProps | undefined
  >(initialConfirmationDialogProps);
  const [configurationsTab, setConfigurationsTab] =
    useState<PolicyConfigurationsTabs>(initialState.configurationsTab);

  const [checkTypeConfigurationRefs, setCheckTypeConfigurationRefs] =
    useState<CheckTypeConfigurationRef>({});

  const [formDetailsRef, setFormDetailsRef] =
    useState<React.RefObject<HTMLFormElement>>();

  const [submitFormRef, setSubmitFormRef] =
    useState<React.RefObject<HTMLDivElement>>();

  const setSinglePolicyConfiguration = (
    policyConfigurations: PolicyConfigurations
  ) => {
    setPolicyConfigurations((prev) => ({
      ...prev,
      ...policyConfigurations,
    }));
  };

  const setChecksTableFilter = useCallback(
    (searchFilters: Partial<ChecksTableFilters>) => {
      setChecksTableFilters((prev) => ({
        ...prev,
        ...searchFilters,
      }));
    },
    []
  );

  const deletePolicyConfiguration = useCallback(
    (policy: keyof PolicyConfigurations) => {
      setPolicyConfigurations((prev) => {
        const newPolicyConfigurations = { ...prev };
        delete newPolicyConfigurations[policy];
        return newPolicyConfigurations;
      });
    },
    []
  );

  const setSingleControlCheckedState = useCallback(
    (checkedState: Dictionary<boolean>) => {
      setConfigurationsControlCheckedState((prev) => ({
        ...prev,
        ...checkedState,
      }));
    },
    []
  );

  const setCheckTypeConfigurationRef = useCallback(
    (checkType: CheckType, ref: React.RefObject<HTMLDivElement> | null) => {
      setCheckTypeConfigurationRefs((prev) => ({
        ...prev,
        [checkType]: ref,
      }));
    },
    []
  );

  const contextState: PolicyDrawerContextState = {
    policyConfigurations,
    configurationsTab,
    setConfigurationsTab,
    setSinglePolicyConfiguration,
    setAllPolicyConfigurations: setPolicyConfigurations,
    checksTableFilters,
    setChecksTableFilter,
    deletePolicyConfiguration,
    onClose,
    policyId,
    configurationsControlCheckedState,
    setSingleControlCheckedState,
    setAllControlCheckedState: setConfigurationsControlCheckedState,
    isSubmittingForm,
    setIsSubmittingForm,
    confirmationDialogProps,
    setConfirmationDialogProps,
    isReadOnlyMode: mode === "view",
    checkTypeConfigurationRefs,
    setCheckTypeConfigurationRef,
    formDetailsRef,
    setFormDetailsRef,
    submitFormRef,
    setSubmitFormRef,
  };

  return (
    <PolicyDrawerContext.Provider value={contextState}>
      {children}
    </PolicyDrawerContext.Provider>
  );
};
