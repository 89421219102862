import { Node } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";
import { MetricsColumns } from "../../metrics/buildMetricFromResult";

import { AvailableActions } from "./AvailableActions";
import { KubernetesResource } from "./KubernetesResource";
import { UnschedulableNode } from "./constants";

export const KubernetesNodesResource: KubernetesResource = {
  NameInK8S: "nodes",
  Group: "nodes",
  NavBarName: "Nodes",
  PresentName: "Nodes",
  Namespaced: false,
  ShowStatusFilter: true,
  CustomColumns: [UnschedulableNode],
  UnnecessaryColumns: [
    "External-IP",
    "Internal-IP",
    "OS-image",
    "Kernel-version",
    "Container-runtime",
    "Unschedulable",
    "Namespace",
  ],
  DefaultSort: {
    ColumnName: "status",
    Direction: Direction.up,
  },
  CustomSortOrder: {
    status: {
      NetworkUnavailable: 0,
      NotReady: 0,
      DiskPressure: 1,
      MemoryPressure: 1,
      PIDPressure: 1,
      Ready: 2,
    },
  },
  SupportedActions: [AvailableActions.CordonUncordon, AvailableActions.Drain],
  Kind: "Node",
  Icon: Node,
  Headers: [
    {
      name: "Name",
    },
    {
      name: "Age",
    },
    {
      name: "Roles",
    },
    {
      name: "Status",
    },
    {
      name: "Version",
    },
    {
      name: MetricsColumns.CpuUsageColumn.toString(),
      hide: true,
      displayName: "CPU",
    },
    {
      name: MetricsColumns.MemoryUsageColumn.toString(),
      hide: true,
      displayName: "Memory",
    },
    {
      name: MetricsColumns.DiskUsageColumn.toString(),
      hide: true,
      displayName: "Disk",
    },
    {
      name: MetricsColumns.CpuUtilizationColumn.toString(),
      displayName: "%CPU",
    },
    {
      name: MetricsColumns.MemoryUtilizationColumn.toString(),
      displayName: "%MEM",
    },
    {
      name: MetricsColumns.DiskUtilizationColumn.toString(),
      displayName: "%Disk",
    },
  ],
  hasLabels: true,
};
