import axios from "axios";
import { useMemo } from "react";

import { getAppConfig } from "../../../config/appConfig";
import { useRequestHeaders } from "../../common-api/headers";

export const getSearchAPIBasePath = (): string => {
  const serverBaseURL = getAppConfig().searchAPIServerURL;
  return serverBaseURL;
};

export const useSearchApiClient = (skipAppViewInHeaders?: boolean) => {
  const headers = useRequestHeaders({ skipAppViewInHeaders });
  return useMemo(
    () =>
      axios.create({
        baseURL: getSearchAPIBasePath(),
        headers,
      }),
    [headers]
  );
};
