import styled from "styled-components";
import { theme, palette } from "@komodorio/design-system";
import { Typography, Button } from "@komodorio/design-system/deprecated";
import { AlertTriangleOutlined16 } from "@komodorio/design-system/icons";
import React, { useState } from "react";

export interface ActionResultProps {
  message: string;
  success: boolean;
}

const MAX_HEIGHT = "10rem";

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${theme.foreground.fgPink};
  max-height: ${MAX_HEIGHT};
  min-height: 1rem;
  display: flex;
  padding: 4px 16px 4px 4px;
  gap: 0.75rem;
  align-items: center;
  max-width: 97%;
  margin-top: 0.5rem;
`;

const IconContainer = styled.div`
  background: ${palette.pink[50]};
  border-radius: 2px;
  padding: 6px 12px;
`;

const MessageContainer = styled.div`
  overflow: auto;
  max-height: ${MAX_HEIGHT};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

const maxMessageLength = 120;

export const ActionResult: React.FC<ActionResultProps> = ({ message }) => {
  const [showExpandedMessage, setShowExpandedMessage] = useState(false);

  return (
    <Container>
      <IconContainer>
        <AlertTriangleOutlined16 color={palette.pink["500"]} />
      </IconContainer>
      {message ? (
        <>
          <Typography variant={"title"} size={"small"}>
            Failure message
          </Typography>
          <MessageContainer>
            <Typography color={theme.foreground.fgPrimary}>
              {showExpandedMessage
                ? message
                : `${message.substring(0, maxMessageLength)}${
                    message.length > maxMessageLength ? "..." : ""
                  }`}
            </Typography>
            {message.length > maxMessageLength ? (
              <Button
                onClick={() =>
                  setShowExpandedMessage((prevState) => !prevState)
                }
              >
                View more
              </Button>
            ) : null}
          </MessageContainer>
        </>
      ) : (
        <Typography color={theme.foreground.fgPrimary}>
          Something went wrong, couldn't complete the action.
        </Typography>
      )}
    </Container>
  );
};
