import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

type SecurityFailedProps = {
  rowData: GridRenderCellParams;
};

const getRowText = (value: number, basicText: string) =>
  `${value} ${basicText}${value > 1 ? "s" : ""}`;

const EmptyValue: React.FC = () => <Typography variant="body3">-</Typography>;

export const CriticalIssueCell: React.FC<SecurityFailedProps> = ({
  rowData,
}) => {
  if (!rowData.value) return <EmptyValue />;

  return (
    <Typography variant="body3" color={muiColors.pink[600]}>
      {getRowText(rowData.value, "critical issue")}
    </Typography>
  );
};

export const WarningCell: React.FC<SecurityFailedProps> = ({ rowData }) => {
  if (!rowData.value) return <EmptyValue />;

  return (
    <Typography variant="body3" color={muiColors.gray[800]}>
      {getRowText(rowData.value, "warning")}
    </Typography>
  );
};
