import Typography from "@mui/material/Typography";
import { parseISO } from "date-fns";
import React from "react";
import { IconButton } from "@komodorio/design-system/deprecated";
import { ChevronRight16 } from "@komodorio/design-system/icons";

import { useTimezone } from "@/shared/context/TimezoneProvider";
import AuditObject from "@/components/Audit/details/AuditObject";
import { Column } from "@/components/common/table/types";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { Capitalized } from "@/components/Audit/styles";

export const useAuditColumnDefinition = ({
  setOpenDrawer,
}: {
  setOpenDrawer(a: AuditObject): void;
}) => {
  const { timeZoneName } = useTimezone();
  const { format } = useDateFormatter({ timeZoneName: undefined });

  const columns: Column<AuditObject>[] = React.useMemo(
    () => [
      {
        name: `Timestamp (${timeZoneName})`,
        selector: (a) =>
          (
            <Capitalized variant="body3">
              {format(parseISO(a.data.eventTimestamp ?? ""))}
            </Capitalized>
          ) as React.ReactNode,
      },
      {
        name: "Event type",
        selector: (a) =>
          (
            <Capitalized variant="body3">{a.renderEventType()}</Capitalized>
          ) as React.ReactNode,
      },
      {
        name: "Action",
        selector: (a) =>
          (
            <Capitalized variant="body3">{a.renderAction()}</Capitalized>
          ) as React.ReactNode,
      },
      {
        name: "Affected resources",
        selector: (a) =>
          (
            <Typography variant="body3">
              {a.renderAffectedResources()}
            </Typography>
          ) as React.ReactNode,
      },
      {
        name: "User",
        selector: (a) =>
          (
            <Typography variant="body3">{a.data?.user?.email}</Typography>
          ) as React.ReactNode,
      },
      {
        name: "Status",
        selector: (a) =>
          (
            <Capitalized variant="body3">{a.data.status}</Capitalized>
          ) as React.ReactNode,
      },
      {
        name: "",
        selector: (a) => (
          <IconButton
            icon={ChevronRight16}
            onClick={() => setOpenDrawer(a)}
            noBorder
          />
        ),
      },
    ],
    [format, setOpenDrawer, timeZoneName]
  );

  return {
    columns,
  };
};
