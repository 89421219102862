import Resource from "../../../resources";

export type OwnerMetadata = {
  kind?: string;
  name?: string;
  apiVersion?: string;
};

export type OwnerResourcesParams = {
  controlledResource: Resource;
  ownerMetadata: OwnerMetadata | undefined;
  onFetchingStateChange?: (isFetching: boolean) => void;
};

export type CRDOptions = {
  resourceType?: string;
  namespace?: string;
  existData?: { isCustomResource: boolean };
};
const WORKFLOW_KIND = "Workflow" as const;
type CRDControlledResourceKind = typeof WORKFLOW_KIND; // Currently only Workflow is supported

export const isCRDControlledResourceKind = (
  kind: string | undefined
): kind is CRDControlledResourceKind => {
  return kind === WORKFLOW_KIND;
};
