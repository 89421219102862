import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { getGraphTimeStamps } from "../../Insights/shared/utils/graphUtils";
import { overviewMetricsAriaLabels } from "../../../../appViewAriaLabels";
import { SingleOverviewMetricProps } from "../overviewMetricsTypes";
import { OverviewMetricsGraph } from "../OverviewMetricsGraph";
import {
  OverviewMetricsTitle,
  ScoreOverviewMetricsTitle,
} from "../OverviewMetricsStyles";

import { Container } from "./styles";
import { LoadingComponent } from "./LoadingComponent";
import { AgentUpdatePrompt } from "./AgentUpdatePrompt";
import { DisabledMetricsComponent } from "./DisabledMetricsComponent";

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SquareContainer = styled.div`
  padding: 16px;
  min-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${muiColors.gray[300]};
`;

const MainMetric = styled(Typography)`
  padding-right: 10px;
  && {
    line-height: initial;
  }
`;

const TypographyWithWhiteSpace = styled(Typography)`
  white-space: nowrap;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const {
  mainMetric: mainMetricAriaLabel,
  contentUnavailable: contentUnavailableAriaLabel,
} = overviewMetricsAriaLabels;

export const SingleOverviewMetric: React.FC<SingleOverviewMetricProps> = ({
  summaryData,
  graphData,
  loading,
  actionButton,
  isDisabled,
  dataState,
  error,
  ...rest
}) => {
  const { mainMetric, title, trendData, timePeriod, metricUnit } = summaryData;
  const graphTimeStamps = useMemo(
    () => getGraphTimeStamps(graphData.data),
    [graphData.data]
  );

  if (error) return null;

  if (loading) {
    return <LoadingComponent {...rest} />;
  }

  const isCompleteData = dataState !== "collecting data";
  const fullDataContent = (
    <>
      <MainMetric variant={"h1"} aria-label={mainMetricAriaLabel}>
        {mainMetric}
      </MainMetric>
      <div>
        <Typography variant={"h5"} color={trendData.color}>
          {trendData.trend}
        </Typography>
        <TypographyWithWhiteSpace variant={"body3"} color={muiColors.gray[500]}>
          {timePeriod}
        </TypographyWithWhiteSpace>
      </div>
    </>
  );

  const incompleteDataTitle = (
    <Typography color={muiColors.gray[500]} variant={"h4"}>
      Collecting data
    </Typography>
  );

  const enabledMetricsContent = (
    <>
      <ScoreOverviewMetricsTitle>{title}</ScoreOverviewMetricsTitle>
      {isCompleteData ? (
        <MetricsContainer>{fullDataContent}</MetricsContainer>
      ) : (
        incompleteDataTitle
      )}
    </>
  );

  const metricsDataContent = isDisabled ? (
    <DisabledMetricsComponent title={title} />
  ) : (
    enabledMetricsContent
  );

  const ariaLabel = isDisabled ? contentUnavailableAriaLabel : undefined;

  return (
    <Container {...rest}>
      <SquareContainer aria-label={ariaLabel}>
        {metricsDataContent}
      </SquareContainer>
      <VerticalDivider />

      {!isDisabled && (
        <SquareContainer>
          <FlexContainer>
            <OverviewMetricsTitle color={muiColors.gray[500]}>
              {graphData.title}
            </OverviewMetricsTitle>
            {actionButton}
          </FlexContainer>
          <OverviewMetricsGraph
            data={graphData.data}
            xAxisTicks={graphTimeStamps}
            unit={metricUnit}
            dataState={dataState}
          />
        </SquareContainer>
      )}
      {isDisabled && <AgentUpdatePrompt />}
    </Container>
  );
};
