import React, { useEffect, useMemo } from "react";
import { Skeleton, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

import { AtmOutputType } from "../../../../../shared/hooks/ATM/useAtmRequest";
import {
  getRoutePathFromKubernetesResource,
  SupportedKubernetesResources,
} from "../../../inspectionConfiguration/SupportedKubernetesResources";
import { RESOURCE_DRAWER_POSTFIX } from "../../../../../shared/config/urlSearchParamsKeys";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { getK8sResourceStatusByChartStatus } from "../../helmChart";
import {
  ResourceStatusEnum,
  StatusTag,
} from "../../../../ResourceView/headers/common";
import { KubernetesCRDResource } from "../../../inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { useAtmGetResourceInterval } from "../../hooks/useAtmGetResourceInterval";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { GenericResource, ShowResourceColumns } from "./ResourcesTab";
import { Carp, useParseResourceStatusForHelm } from "./helmResourceTabTabHooks";
import { LinkableDataItem } from "./LinkableDataItem";

const Row = styled.tr<{ clickable?: boolean }>`
  background-color: ${palette.white[0]};
  border-radius: 0.25rem;
  ${({ clickable = false }) =>
    clickable
      ? `cursor: pointer; 
  :hover {
    background-color: #ebf3fc;
  }`
      : `cursor: not-allowed;`}
`;

interface RowProps {
  index: number;
  k8sResource: GenericResource;
  onClickCallback: () => void;
  agentId: string;
  cluster: string;
  namespace: string;
  showColumns?: ShowResourceColumns;
  onLoadingStateChange: (index: number, isLoading: boolean) => void;
}

export const ResourceRow: React.FC<RowProps> = ({
  index,
  k8sResource,
  onClickCallback,
  agentId,
  cluster,
  namespace,
  showColumns,
  onLoadingStateChange,
}) => {
  const isSupportedResource: boolean = useMemo(() => {
    return !!SupportedKubernetesResources.find(
      (r) => r.Kind.toLowerCase() === k8sResource?.kind?.toLowerCase()
    );
  }, [k8sResource?.kind]);

  const route = useMemo(() => {
    const kubernetesResource = SupportedKubernetesResources.find(
      (r) => r.Kind.toLowerCase() === k8sResource?.kind.toLowerCase()
    );

    if (kubernetesResource) {
      const openDrawer = `${kubernetesResource.Kind}${RESOURCE_DRAWER_POSTFIX}={"show":true,"resource":"${k8sResource.metadata.name}","resourceName":"${kubernetesResource.NameInK8S}","namespace":"${namespace}","isDeletedPod":false}`;
      return `${getRoutePathFromKubernetesResource(
        kubernetesResource,
        cluster
      )}?${openDrawer}`;
    }
    return "";
  }, [cluster, k8sResource?.kind, k8sResource.metadata.name, namespace]);

  const hasStatusAndResourceSupported = useMemo(() => {
    return (
      !!(showColumns?.status || showColumns?.statusMessage) &&
      isSupportedResource
    );
  }, [isSupportedResource, showColumns?.status, showColumns?.statusMessage]);

  const interval = 5000;

  const {
    data,
    isFetching: fetching,
    errorMessage: finalErrorMessage,
    isEmptyResult: emptyResult,
  } = useAtmGetResourceInterval({
    agentId,
    cluster,
    namespace,
    resourceName: k8sResource?.metadata?.name ?? "",
    resourceKind: k8sResource?.kind ?? "",
    outputType: AtmOutputType.Json,
    enable: hasStatusAndResourceSupported,
    interval,
  });

  const { status: resourceStatus, statusMessage } =
    useParseResourceStatusForHelm(
      data as Carp,
      emptyResult,
      isSupportedResource,
      finalErrorMessage
    );

  const calculatedStatus = useMemo(() => {
    return getK8sResourceStatusByChartStatus(resourceStatus);
  }, [resourceStatus]);

  const isClickable = useMemo(() => {
    return (
      !!SupportedKubernetesResources.find(
        (r) =>
          r.Kind === k8sResource?.kind &&
          calculatedStatus === ResourceStatusEnum.Healthy
      ) &&
      k8sResource?.kind.toLowerCase() !==
        KubernetesCRDResource.Kind.toLowerCase()
    );
  }, [calculatedStatus, k8sResource?.kind]);

  useEffect(() => {
    if (isSupportedResource) {
      onLoadingStateChange(index, fetching);
    }
  }, [fetching, index, isSupportedResource, onLoadingStateChange]);

  return (
    <Row
      key={index}
      onClick={isClickable ? onClickCallback : undefined}
      clickable={isClickable}
    >
      <LinkableDataItem
        route={route}
        isClickable={isClickable}
        onClickCallback={onClickCallback}
      >
        {!!showColumns?.type && (
          <Typography>{k8sResource?.kind ?? ""}</Typography>
        )}
      </LinkableDataItem>
      <LinkableDataItem
        route={route}
        isClickable={isClickable}
        onClickCallback={onClickCallback}
      >
        {!!showColumns?.name && (
          <Typography>{k8sResource?.metadata?.name ?? ""}</Typography>
        )}
      </LinkableDataItem>
      {!!showColumns?.status && (
        <>
          {fetching && isSupportedResource ? (
            <td>
              <Skeleton height={"1rem"} width={"3rem"} />
            </td>
          ) : (
            <LinkableDataItem
              route={route}
              isClickable={isClickable}
              onClickCallback={onClickCallback}
            >
              <StatusTag status={calculatedStatus} size="small">
                {resourceStatus}
              </StatusTag>
            </LinkableDataItem>
          )}
        </>
      )}
      {showColumns?.statusMessage && (
        <>
          {fetching && isSupportedResource ? (
            <td>
              <Skeleton height={"1rem"} width={"10rem"} />
            </td>
          ) : (
            <LinkableDataItem
              route={route}
              isClickable={isClickable}
              onClickCallback={onClickCallback}
            >
              <Typography>{statusMessage}</Typography>
            </LinkableDataItem>
          )}
        </>
      )}
    </Row>
  );
};
