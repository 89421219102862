import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export type StatsSectionProps = {
  title: string;
  children: React.ReactNode;
};

export const StatsSection: React.FC<StatsSectionProps> = ({
  title,
  children,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Typography variant="h5" lineHeight="24px">
        {title}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap="16px">
        {children}
      </Box>
    </Box>
  );
};
