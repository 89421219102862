import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider } from "@komodorio/design-system/deprecated";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { capitalize } from "lodash";
import styled from "styled-components";

import { GitCompare } from "../../../../shared/types/git";
import useFilteredGitCompares from "../../../common/ProcessList/details/deployEvent/useFilteredGitCompares";
import useInstallationSubscription from "../../../integrations/management/installed/useInstallationSubscription";
import {
  getGitProviderData,
  getGitTypeFinder,
  GitProviders,
} from "../../../common/ProcessList/details/deployEvent/Git/GetGitTypeAndProviderData";
import Resource from "../../../ResourceView/resources";
import { getText } from "../../../common/ProcessList/details/deployEvent/Git/GitSectionUtils";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

import {
  SectionContainer,
  SectionContent,
  SimpleContainer,
  SectionHeader,
  SectionFooter,
} from "./styles";

const GitPrUrl = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: row;
`;

export const GitDiff: React.FC<{
  resource: Resource;
  gitCompares: GitCompare[];
}> = ({ resource, gitCompares }) => {
  const filteredGitCompares = useFilteredGitCompares(gitCompares);
  const installations = useInstallationSubscription();
  const annotations = resource?.annotations as
    | Record<string, string>
    | undefined;
  const gitTypeFinder = useMemo(
    () => getGitTypeFinder(gitCompares, installations, annotations),
    [annotations, gitCompares, installations]
  );

  const gitType = useMemo(
    () => GitProviders.get(gitTypeFinder?.type),
    [gitTypeFinder.type]
  );

  const srcControlLink = useMemo(() => {
    return (gitCompares?.[0] as GitCompare)?.url;
  }, [gitCompares]);

  if (!gitType) {
    return null;
  }

  return (
    <SectionContainer>
      <SectionHeader>
        <img
          alt="git provider icon"
          src={gitType.icon}
          width={16}
          height={16}
        />
        <Typography variant={"h6"}>{capitalize(gitType.name)}</Typography>
        <Typography variant={"h6"} color={muiColors.gray[500]}>
          (app)
        </Typography>
      </SectionHeader>
      <SectionContent>
        {filteredGitCompares?.length
          ? filteredGitCompares?.map((gitCompare) => {
              const gitProvider = getGitProviderData(gitCompare);
              const commitUrl =
                GitProviders.get(gitTypeFinder?.type)?.commitUrl ?? (() => "");
              const prPrefix =
                GitProviders.get(gitTypeFinder?.type)?.prPrefix ?? "";
              return gitProvider.diff?.map((commit, index) => {
                if (!commit) {
                  return null;
                }
                return (
                  <GitChange
                    key={commit.pr_url}
                    id={index}
                    href={commit.pr_url ?? commitUrl(commit)}
                    title={getText(commit, prPrefix)}
                    authorName={commit.author_name}
                  />
                );
              });
            })
          : null}
      </SectionContent>
      {srcControlLink && (
        <SimpleContainer>
          <Divider />
          <SectionFooter>
            <Button
              variant={"text"}
              size={"small"}
              style={{ padding: 0 }}
              onClick={() => window.open(srcControlLink, "_blank")}
              aria-label={
                AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
                  .GitDiffViewDiffLink
              }
            >
              View diff
              <OpenInNew
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                }}
              />
            </Button>
          </SectionFooter>
        </SimpleContainer>
      )}
    </SectionContainer>
  );
};

interface GitChangeComponentProps {
  href: string;
  title: string;
  authorName: string;
  id: number;
}

const GitChange: React.FC<GitChangeComponentProps> = ({
  href,
  title,
  authorName,
  id,
}) => {
  return (
    <div key={id}>
      <GitPrUrl
        onClick={() => window.open(href, "_blank")}
        style={{ cursor: "pointer" }}
        aria-label={
          AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
            .GitDiffPrLink
        }
      >
        <Typography
          variant={"body3"}
          sx={{ textDecoration: "underline", fontWeight: "medium" }}
        >
          {title}
        </Typography>
        <OpenInNew
          style={{
            fontSize: "16px",
            color: muiColors.gray[600],
          }}
        />
      </GitPrUrl>
      <Typography variant={"body3"} color={muiColors.gray[600]}>
        by {authorName}
      </Typography>
    </div>
  );
};
