export const HEALTHY = "HEALTHY";

export const UNHEALTHY = "UNHEALTHY";

export const UNHEALTHY_POD_PHASE = ["Failed", "Unkown"];

export const POD_WAITING_FAILURE_REASON = [
  "Init:CrashLoopBackOff",
  "CrashLoopBackOff",
  "ErrImagePull",
  "ImagePullBackOff",
  "CreateContainerConfigError",
  "CreateContainerError",
  "InvalidImageName",
];

export const POD_TERMINATED_FAILURE_REASONS = [
  "OOMKilled",
  "Init:Error",
  "Error",
  "ContainerCannotRun",
  "DeadlineExceeded",
  "Evicted",
];
