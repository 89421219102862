import { useEffect } from "react";

import { useChannelConfigurationSubscription } from "../../generated/graphql";
import { notifyDDError } from "../../shared/hooks/exceptionManagement";

import { ChannelConfiguration } from "./useFetchNotificationConfiguration";

export const useFetchChannelConfiguration = ():
  | ChannelConfiguration[]
  | undefined => {
  const [{ data, error }] = useChannelConfigurationSubscription();
  useEffect(() => {
    if (error) {
      notifyDDError(error);
    }
  }, [error]);
  return data?.channel_configuration as ChannelConfiguration[] | undefined;
};
