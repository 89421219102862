import { parseISO, differenceInMilliseconds } from "date-fns";
import { useMemo } from "react";
import { orderBy, partition } from "lodash";

import { useDurationFormatter } from "../../shared/hooks/useDateFormatter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ARGO_WORKFLOW_KIND } from "../ResourceView/resources/argoWorkflow";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ARGO_CRON_WORKFLOW_KIND } from "../ResourceView/resources/argoCronWorkflow";
import { ARGO_WORKFLOW_TEMPLATE_KIND } from "../ResourceView/resources/argoWorkflowTemplate";
import { ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND } from "../ResourceView/resources/argoClusterWorkflowTemplate";

import {
  ArgoPhases,
  ArgoWorkflowParsedData,
  ArgoWorkflowRawData,
  ArgoWorkflowTableData,
} from "./types";

import { formatDuration, isValidDate } from "@/shared/utils/dateUtils";

export type ArgoWorkflowsGroups = {
  cronWorkflows: {
    [namespace: string]: Record<string, ArgoWorkflowParsedData[]>;
  };
  workflowTemplates: {
    [namespace: string]: Record<string, ArgoWorkflowParsedData[]>;
  };
  clusterWorkflowTemplates: Record<string, ArgoWorkflowParsedData[]>;
  standaloneWorkflows: ArgoWorkflowParsedData[];
};

export const POD_EVENTS_RETENTION_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 days
export const useParsedArgoWorkflowsFromATM = (
  workflows: ArgoWorkflowRawData[],
  cluster: string
): ArgoWorkflowParsedData[] => {
  const { formatShortDuration } = useDurationFormatter();
  const currentDate = new Date();

  return workflows
    .map((workflow) => ({
      ...workflow,
      cluster: cluster,
      status: workflow["status"] as ArgoPhases,
      startedAt: isValidDate(workflow["startedAt"])
        ? parseISO(workflow["startedAt"])
        : null,
      finishedAt: isValidDate(workflow["finishedAt"])
        ? parseISO(workflow["finishedAt"])
        : null,
      deletedAt: isValidDate(workflow["deletedAt"])
        ? parseISO(workflow["deletedAt"])
        : null,
      duration: formatDuration(
        formatShortDuration,
        workflow["startedAt"],
        workflow["finishedAt"]
      ),
      type: ARGO_WORKFLOW_KIND,
    }))
    .filter(
      (workflow) =>
        !workflow.finishedAt ||
        differenceInMilliseconds(currentDate, workflow.finishedAt) <
          POD_EVENTS_RETENTION_IN_MS
    );
};

const sortWorkflowOwnerGroup = (workflows: ArgoWorkflowParsedData[]) => {
  const [completedWorkflows, runningWorkflows] = partition(
    workflows,
    (wf) => !!wf.finishedAt
  );
  return [
    ...orderBy(runningWorkflows, [(wf) => wf.startedAt], ["desc"]),
    ...orderBy(completedWorkflows, [(wf) => wf.finishedAt], ["desc"]),
  ];
};

export const useArgoWorkflowsGroupedByOwner = (
  workflows: ArgoWorkflowRawData[],
  cluster: string
) => {
  const parsedWorkflows = useParsedArgoWorkflowsFromATM(workflows, cluster);

  return useMemo<ArgoWorkflowsGroups>(() => {
    const groups: ArgoWorkflowsGroups = {
      cronWorkflows: {},
      workflowTemplates: {},
      clusterWorkflowTemplates: {},
      standaloneWorkflows: [],
    };

    for (const resource of parsedWorkflows) {
      if (resource.from && resource.cronScope === "CronWorkflow") {
        const { from, namespace } = resource;
        groups.cronWorkflows[namespace] = groups.cronWorkflows[namespace] || {};
        groups.cronWorkflows[namespace][from] =
          (groups.cronWorkflows[namespace] &&
            groups.cronWorkflows[namespace][from]) ||
          [];
        groups.cronWorkflows[namespace][from].push(resource);
      } else if (!resource.clusterScope && resource.template) {
        const { template, namespace } = resource;
        groups.workflowTemplates[namespace] =
          groups.workflowTemplates[namespace] || {};
        groups.workflowTemplates[namespace][template] =
          (groups.workflowTemplates[namespace] &&
            groups.workflowTemplates[namespace][template]) ||
          [];
        groups.workflowTemplates[namespace][template].push(resource);
      } else if (resource.clusterScope === "true" && resource.template) {
        groups.clusterWorkflowTemplates[resource.template] =
          groups.clusterWorkflowTemplates[resource.template] || [];
        groups.clusterWorkflowTemplates[resource.template].push(resource);
      } else {
        groups.standaloneWorkflows.push(resource);
      }
    }

    // sort groups
    groups.standaloneWorkflows = sortWorkflowOwnerGroup(
      groups.standaloneWorkflows
    );
    groups.cronWorkflows = Object.fromEntries(
      Object.entries(groups.cronWorkflows).map(([namespace, workflows]) => [
        namespace,
        Object.fromEntries(
          Object.entries(workflows).map(([name, workflows]) => [
            name,
            sortWorkflowOwnerGroup(workflows),
          ])
        ),
      ])
    );
    groups.workflowTemplates = Object.fromEntries(
      Object.entries(groups.workflowTemplates).map(([namespace, workflows]) => [
        namespace,
        Object.fromEntries(
          Object.entries(workflows).map(([name, workflows]) => [
            name,
            sortWorkflowOwnerGroup(workflows),
          ])
        ),
      ])
    );
    groups.clusterWorkflowTemplates = Object.fromEntries(
      Object.entries(groups.clusterWorkflowTemplates).map(
        ([name, workflows]) =>
          [name, sortWorkflowOwnerGroup(workflows)] as [
            string,
            ArgoWorkflowParsedData[]
          ]
      )
    );

    return groups;
  }, [parsedWorkflows]);
};

const createWorkflowTableRow = (
  workflow: ArgoWorkflowParsedData
): ArgoWorkflowTableData => ({
  id: workflow.id,
  name: workflow.name,
  cluster: workflow.cluster,
  namespace: workflow.namespace,
  status: workflow.status,
  startedAt: workflow.startedAt,
  finishedAt: workflow.finishedAt,
  duration: workflow.duration,
  type: workflow.type,
  runs: 1,
});

export const useArgoWorkflowsTableRows = (
  argoWorkflowsGroups: ArgoWorkflowsGroups
): ArgoWorkflowTableData[] => {
  return [
    ...argoWorkflowsGroups.standaloneWorkflows.map(createWorkflowTableRow),
    ...Object.entries(argoWorkflowsGroups.cronWorkflows).flatMap(
      ([namespace, workflows]) =>
        Object.entries(workflows).map(([name, workflows]) => ({
          ...createWorkflowTableRow(workflows[0]),
          name,
          namespace,
          type: ARGO_CRON_WORKFLOW_KIND,
          runs: workflows.length,
        }))
    ),
    ...Object.entries(argoWorkflowsGroups.workflowTemplates).flatMap(
      ([namespace, workflows]) =>
        Object.entries(workflows).map(([name, workflows]) => ({
          ...createWorkflowTableRow(workflows[0]),
          name,
          namespace,
          type: ARGO_WORKFLOW_TEMPLATE_KIND,
          runs: workflows.length,
        }))
    ),
    ...Object.entries(argoWorkflowsGroups.clusterWorkflowTemplates).flatMap(
      ([name, workflows]) => ({
        ...createWorkflowTableRow(workflows[0]),
        name: name,
        type: ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND,
        runs: workflows.length,
      })
    ),
  ];
};
