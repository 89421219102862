import { useMemo } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { capitalize } from "lodash";

import { CertificateController } from "@/generated/addonsApi";
import { getRandRange } from "@/shared/utils/getRandRange";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { certificateControllerStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { VersionRangeCell } from "@/components/k8sAddons/components/table/VersionRangeCell";
import { useDisableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";

export const useColumnsConfig = () =>
  useDisableLocalSortingInTableColumns<CertificateController>({
    name: {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    clusterName: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    version: {
      field: "version",
      headerName: "Controller version",
      minWidth: 150,
    },
    supportedK8sVersions: {
      field: "supportedK8sVersions",
      headerName: "Supported K8s versions",
      flex: 1,
    },
    status: {
      field: "status",
      headerName: "Status",
      width: 130,
    },
  });

export const useCertificateControllerTableColumns =
  (): GridColDef<CertificateController>[] => {
    const columnsConfig = useColumnsConfig();
    return useMemo(() => {
      return [
        { ...columnsConfig.name },
        { ...columnsConfig.clusterName },
        { ...columnsConfig.namespace },
        { ...columnsConfig.version },
        {
          ...columnsConfig.supportedK8sVersions,
          renderCell: (params) => {
            const { min, max } = params.row.supportedK8sVersions;
            return <VersionRangeCell fromVersion={min} toVersion={max} />;
          },
        },
        {
          ...columnsConfig.status,
          renderCell: (params) => {
            const status = params.row.status;
            return (
              <AddonStatusChip
                color={certificateControllerStatusToColor[status]}
                label={capitalize(status)}
              />
            );
          },
        },
      ];
    }, [
      columnsConfig.clusterName,
      columnsConfig.name,
      columnsConfig.namespace,
      columnsConfig.status,
      columnsConfig.supportedK8sVersions,
      columnsConfig.version,
    ]);
  };

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof CertificateController, number[]>> =
  {
    name: [getRandRange(50, 150), getRandRange(50, 150), getRandRange(50, 150)],
    clusterName: [
      getRandRange(50, 150),
      getRandRange(50, 150),
      getRandRange(50, 150),
    ],
    namespace: [
      getRandRange(50, 150),
      getRandRange(50, 150),
      getRandRange(50, 150),
    ],
    version: [getRandRange(50, 60), getRandRange(50, 60), getRandRange(50, 60)],
    supportedK8sVersions: [
      getRandRange(30, 40),
      getRandRange(30, 40),
      getRandRange(30, 40),
    ],

    status: [
      getRandRange(50, 100),
      getRandRange(50, 100),
      getRandRange(50, 100),
    ],
  };

const getColumnRandWidth = (
  id: GridRowId,
  columnName: keyof CertificateController
) => {
  const idAsNumber = parseInt(id.toString());
  const column = randRangeColumns[columnName];
  if (!column) return 0;
  return column[idAsNumber % column.length];
};

export const useCertificateControllerLoadingColumns =
  (): GridColDef<CertificateController>[] => {
    const columnsConfig = useColumnsConfig();
    return useMemo(
      () => [
        {
          ...columnsConfig.name,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "name")}
                height={21}
              />
            );
          },
        },
        {
          ...columnsConfig.clusterName,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "clusterName")}
                height={21}
              />
            );
          },
        },
        {
          ...columnsConfig.namespace,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "namespace")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.version,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "version")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.supportedK8sVersions,
          renderCell: ({ id }) => (
            <Box display={"flex"} gap={"8px"}>
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "supportedK8sVersions")}
                height={21}
              />
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "supportedK8sVersions")}
                height={21}
              />
            </Box>
          ),
        },
        {
          ...columnsConfig.status,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "status")}
              height={20}
              sx={{ borderRadius: "16px" }}
            />
          ),
        },
      ],
      [
        columnsConfig.clusterName,
        columnsConfig.name,
        columnsConfig.namespace,
        columnsConfig.status,
        columnsConfig.supportedK8sVersions,
        columnsConfig.version,
      ]
    );
  };
