import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../../generated/metricsApi";
import { RecommendationData } from "../../types";
import { getRecommendationByStrategy } from "../../utils/getRecommendationsByStrategy";

export const getRecommendationData = (
  container: RightSizingCostSummaryByService,
  strategy: OptimizationStrategy
): RecommendationData => {
  const { cpuRequestMiliCore: currentCpu, memoryRequestMiB: currentMemory } =
    container;

  const { cpuRecommendation, memoryRecommendation } =
    getRecommendationByStrategy(container, strategy);

  const shouldShowCpuRecommendation = cpuRecommendation < currentCpu;
  const shouldShowMemoryRecommendation = memoryRecommendation < currentMemory;

  return {
    containerName: container.containerName,
    shouldShowCpuRecommendation,
    shouldShowMemoryRecommendation,
    cpuRecommendation,
    memoryRecommendation,
    currentCpu,
    currentMemory,
    strategy,
  };
};
