import axios from "axios";
import { useMemo } from "react";

import { getAppConfig } from "../../config/appConfig";
import { useRequestHeaders } from "../common-api/headers";
import { useReportAxiosError } from "../../../components/reliability/hooks/useReportAxiosError";

const getWorkspacesAPIBasePath = (): string => {
  return getAppConfig().workspacesAPIServerURL;
};

export const useWorkspacesApiClient = () => {
  const headers = useRequestHeaders();
  const reportAxiosError = useReportAxiosError();

  const client = useMemo(
    () =>
      axios.create({
        baseURL: getWorkspacesAPIBasePath(),
        headers,
      }),
    [headers]
  );

  client.interceptors.response.use(undefined, (error) => {
    reportAxiosError(error);
    return Promise.reject(error);
  });

  return client;
};
