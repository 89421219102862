export const getTextWidth = (() => {
  // Create canvas once in closure
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Could not get canvas 2D context");
  }

  // Cache for text measurements
  const cache = new Map<string, number>();

  return (text: string, font: string): number => {
    // Create cache key combining text and font
    const cacheKey = `${text}-${font}`;

    // Return cached result if available
    if (cache.has(cacheKey)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return cache.get(cacheKey)!;
    }

    // Measure text if not in cache
    context.font = font;
    const { width } = context.measureText(text);

    // Cache the result
    cache.set(cacheKey, width);
    return width;
  };
})();
