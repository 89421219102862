import { useMemo, useCallback } from "react";
import styled from "styled-components";

import { useActiveAgent } from "../../../../../shared/hooks/useAgents";
import { pushDrawerSelector } from "../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "../../../../../shared/store/drawersStackStore/drawersStackStore";
import {
  KubernetesNodesResource,
  KubernetesPVCResource,
} from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import useResourceWithInterval from "../../../../ResourceView/useResource";
import { WorkflowConfigType } from "../../../../monitorsView/common/types";
import { DrawerType } from "../../../../../shared/store/drawersStackStore/types";
import { GrayHighlight } from "../../../../monitorsView/common/styles";

type LinkParams = {
  namespace: string;
  clusterName: string;
  resourceName: string;
  type: WorkflowConfigType;
};

const StyledLink = styled(GrayHighlight)`
  ${({ onClick }) =>
    onClick &&
    `
           cursor: pointer;
           text-decoration: underline;
    `}
`;

export const ResourceLink: React.FC<LinkParams> = ({
  namespace,
  clusterName,
  resourceName,
  type,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const agentId = useActiveAgent(clusterName ?? "");
  const resourceKind = useMemo(() => {
    if (type === WorkflowConfigType.NodeIssue)
      return KubernetesNodesResource.Kind;
    if (type === WorkflowConfigType.PVC) return KubernetesPVCResource.Kind;
    return "";
  }, [type]);
  const { resource } = useResourceWithInterval({
    agentId: agentId ?? "",
    resourceType: resourceKind,
    resourceName,
    namespace,
    cluster: clusterName,
  });
  const onResourceClick = useCallback(() => {
    if (!resource) return;
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: clusterName,
      namespace,
      resourceType: resourceKind,
      resourceName,
    });
  }, [
    clusterName,
    namespace,
    pushDrawer,
    resource,
    resourceKind,
    resourceName,
  ]);
  return <StyledLink onClick={onResourceClick}>{resourceName}</StyledLink>;
};
