import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { ifNotProp } from "styled-tools";
import Chip from "@mui/material/Chip";

import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../../shared/types";
import { Size } from "../../../../../../types";

import {
  useGetMaxContainerWidth,
  useSetMaxChildrenInContainer,
} from "./chipsContainerHooks";

const ITEM_GAP = 6;

const Container = styled.div<{ breaklines?: boolean; width: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${ITEM_GAP}px;};
  ${({ width }) => ({ maxWidth: width ? `${width}px` : "unset" })};
  ${ifNotProp(
    "breaklines",
    css`
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    `
  )} 
`;

const ChipsWrapper = styled.div<{ breaklines?: boolean }>`
  flex-wrap: ${({ breaklines }) => (breaklines ? "wrap" : "nowrap")};
  display: flex;
  align-items: center;
  gap: ${ITEM_GAP}px;
  max-width: inherit;
  position: relative;
`;

const HiddenWidthContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
`;

const HiddenChipsWrapper = styled(ChipsWrapper)`
  position: fixed;
  visibility: hidden;
  top: -100px;
`;

const StyledChip = styled(Chip)<{ maxwidth?: number }>`
  && {
    font-size: 12px;
    margin-right: 2px;
    ${({ maxwidth }) => maxwidth && `max-width: ${maxwidth}px`};
  }
`;

type ChipsContainerProps<T extends MuiSelectionOptionValue> = {
  selectedItems: MuiSelectionOption<T>[];
  onRemoveItem: (value: T) => void;
  enableLineBreaks?: boolean;
  size: Size;
  className?: string;
};

export const ChipsContainer = <T extends MuiSelectionOptionValue>({
  enableLineBreaks,
  onRemoveItem,
  selectedItems,
  size,
  className,
}: ChipsContainerProps<T>) => {
  const chipSize = size === "large" ? "medium" : size;
  const [hiddenContentChipWidth, setHiddenContentChipWidth] =
    useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenContentRef = useRef<HTMLDivElement>(null);
  const chipRef = useRef<HTMLDivElement>(null);
  const maxWidth = useGetMaxContainerWidth(containerRef);

  useEffect(() => {
    if (!hiddenContentChipWidth && chipRef.current) {
      setHiddenContentChipWidth(chipRef.current.clientWidth);
    }
  }, [hiddenContentChipWidth]);

  const maxChildren = useSetMaxChildrenInContainer({
    containerEl: containerRef.current,
    hiddenContentEl: hiddenContentRef.current,
    hiddenContentChipWidth: hiddenContentChipWidth,
    selectedItems,
    enableLineBreaks,
  });

  const getChipsList = useCallback(
    (list: MuiSelectionOption<T>[]) => {
      const maxSize = maxWidth - hiddenContentChipWidth - ITEM_GAP;
      return list.map((item) => {
        return (
          <StyledChip
            key={item.value}
            label={item.label}
            onDelete={() => onRemoveItem(item.value)}
            size={chipSize}
            maxwidth={maxSize}
          />
        );
      });
    },
    [hiddenContentChipWidth, maxWidth, onRemoveItem, chipSize]
  );

  const fullContent = useMemo(() => {
    return getChipsList(selectedItems);
  }, [getChipsList, selectedItems]);

  const slicedContent = useMemo(() => {
    const itemsNumToUse = maxChildren
      ? selectedItems.slice(0, maxChildren)
      : selectedItems;
    return getChipsList(itemsNumToUse);
  }, [getChipsList, maxChildren, selectedItems]);

  const hiddenChildrenNum = !maxChildren
    ? 0
    : selectedItems.length - maxChildren;

  return (
    <>
      <HiddenChipsWrapper ref={hiddenContentRef}>
        {fullContent}
      </HiddenChipsWrapper>
      <Container
        width={maxWidth}
        className={className}
        breaklines={!!enableLineBreaks}
      >
        <ChipsWrapper breaklines={enableLineBreaks}>
          {slicedContent}
        </ChipsWrapper>

        {hiddenChildrenNum > 0 && (
          <StyledChip label={`+${hiddenChildrenNum}`} size={chipSize} />
        )}

        {!hiddenContentChipWidth && (
          <StyledChip
            label="+99"
            ref={chipRef}
            size={chipSize}
            style={{ visibility: "hidden" }}
          />
        )}
      </Container>
      <HiddenWidthContainer ref={containerRef} />
    </>
  );
};
