import { theme } from "@komodorio/design-system";
import { uniq } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";

import { useFormValidations } from "../../../../shared/context/ValidationsProvider";
import { FormError } from "../../styles";

import { RbacActionRule, rbacRuleVerbsList } from "./types";
import { HeaderWithTitleAndButtons } from "./RuleHeader";
import { InputApiGroup } from "./RuleApiGroupInput";
import { InputResources } from "./RuleResourcesInput";

import {
  API_GROUPS_ALL_API_GROUP,
  API_GROUPS_CORE_API_GROUP,
} from "@/components/Settings/Actions/Form/constants";

const RuleContainer = styled.div`
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
`;

const RuleContent = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  gap: 1rem 2rem;
  align-items: center;
  padding: 1rem 1rem 1rem 2.5rem;
`;

const getApiGroupsToStore = (apiGroups: string[]) => {
  if (!apiGroups.length) {
    return [API_GROUPS_CORE_API_GROUP];
  }

  if (apiGroups.includes(API_GROUPS_ALL_API_GROUP)) {
    return [API_GROUPS_ALL_API_GROUP];
  }

  return uniq(apiGroups);
};

export const Rule: React.FC<{
  index: number;
  rule: RbacActionRule;
  onChange: (s: RbacActionRule, i: number) => void;
  onDuplicate: (s: RbacActionRule) => void;
  onDelete?: (i: number) => void;
  readOnly?: boolean;
}> = ({ index, rule, onChange, onDuplicate, onDelete, readOnly }) => {
  const [open, setOpen] = useState(true);
  const { errors, setError } = useFormValidations();

  const handleCheckingVerb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentVerbs = {
      ...rule.verbs.reduce((acc, verb) => ({ ...acc, [verb]: true }), {}),
    };
    const newCheckedVerbs = {
      ...(rule.verbs.includes("*")
        ? rbacRuleVerbsList.reduce(
            (acc, verb) => ({ ...acc, [verb]: true }),
            {}
          )
        : currentVerbs),
      [event.target.name]: event.target.checked,
    } as { [key: string]: boolean };

    const checkedVerbsList = Object.keys(newCheckedVerbs).filter(
      (key) => newCheckedVerbs[key] === true
    );
    if (rbacRuleVerbsList.every((verb) => checkedVerbsList.includes(verb))) {
      handleVerbsChange(["*"]);
    } else {
      handleVerbsChange(checkedVerbsList);
    }
  };

  const handleApiGroupsChange = (apiGroups: string[]) => {
    onChange({ ...rule, apiGroups: getApiGroupsToStore(apiGroups) }, index);
  };
  const handleResourcesChange = (resources: string[]) => {
    const all = resources.find((a) => a === "*");
    onChange({ ...rule, resources: all ? [all] : resources }, index);
    setError(
      `instance[${index}].resources`,
      resources.length < 1 ? "Please select at least one resource" : undefined
    );
  };
  const handleVerbsChange = (verbs: string[]) => {
    const all = verbs.find((a) => a === "*");
    onChange({ ...rule, verbs: all ? [all] : verbs }, index);
    setError(
      `instance[${index}].verbs`,
      verbs.length < 1 ? "Please select at least one verb" : undefined
    );
  };

  const toggleAllVerbs = () => {
    const allVerbs = rbacRuleVerbsList;
    const allChecked =
      allVerbs.every((verb) => rule.verbs.includes(verb)) ||
      rule.verbs.includes("*");
    handleVerbsChange(allChecked ? [] : ["*"]);
  };

  return (
    <RuleContainer>
      <HeaderWithTitleAndButtons
        open={open}
        setOpen={setOpen}
        rule={rule}
        index={index}
        readOnly={readOnly}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
      />
      {open && (
        <>
          <Divider orientation="horizontal" />
          <RuleContent>
            <Typography variant={"body2"}>API Group</Typography>
            <InputApiGroup
              readOnly={readOnly}
              handleApiGroupsChange={handleApiGroupsChange}
              rule={rule}
            />
            <Typography variant={"body2"}>Resource(s)</Typography>
            <InputResources
              rule={rule}
              readOnly={readOnly}
              handleResourcesChange={handleResourcesChange}
            />
            <Typography variant={"body2"}>
              Verbs
              <Button
                size="small"
                variant="text"
                disabled={readOnly}
                onClick={toggleAllVerbs}
              >
                (toggle all)
              </Button>
            </Typography>
            <FormGroup row>
              {rbacRuleVerbsList.map((verb) => (
                <FormControlLabel
                  key={verb}
                  control={
                    <Checkbox
                      checked={
                        rule.verbs.includes(verb) || rule.verbs.includes("*")
                      }
                      onChange={handleCheckingVerb}
                      name={verb}
                      disabled={readOnly}
                    />
                  }
                  label={verb}
                />
              ))}
            </FormGroup>
          </RuleContent>
        </>
      )}
      <FormError
        errMsg={
          errors[`instance[${index}].apiGroups`] ||
          errors[`instance[${index}].resources`] ||
          errors[`instance[${index}].verbs`]
        }
        isBoxStyle
      />
    </RuleContainer>
  );
};
