import React from "react";
import styled from "styled-components";

import { ImpactAggregations } from "../pages/violations/ViolationsBreakdown/ImpactAggregations/ImpactAggregations";
import { useViolationsTableContext } from "../pages/violations/ViolationsBreakdown/ViolationTableWithContext/context/useViolationsTableContext";

import { useGetImpactGroupData } from "./hooks/useGetImpactGroupData";

const StyledImpactAggregations = styled(ImpactAggregations)`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const ScopedGroupAggregations: React.FC = () => {
  const { uid, impactGroupIdentifier } = useViolationsTableContext();
  const currentGroup = useGetImpactGroupData(impactGroupIdentifier ?? "");
  if (!currentGroup?.impactDataAggregations) return null;

  return (
    <StyledImpactAggregations
      groupIdentifier={uid ?? ""}
      impactDataAggregations={currentGroup.impactDataAggregations}
    />
  );
};
