import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const NAMES_MAX_LENGTH = 15;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PathBreadCrumb = styled(Typography)`
  && {
    margin-right: 5px;
  }
`;

const BreadCrumb: React.FC<{ text: string; isHighlighted: boolean }> = ({
  text,
  isHighlighted,
}) => {
  return (
    <PathBreadCrumb
      variant="body2"
      color={isHighlighted ? muiColors.gray[500] : undefined}
    >
      {text}
    </PathBreadCrumb>
  );
};

const getText = (text: string, isLimited: boolean) => {
  if (!isLimited) return text;
  return text.length <= NAMES_MAX_LENGTH
    ? text
    : `${text.slice(0, NAMES_MAX_LENGTH)}...`;
};

export const Path: React.FC<{
  value: string;
  isLimitedPath?: boolean;
  isHighlighted?: boolean;
}> = ({ value, isLimitedPath = false, isHighlighted = true }) => {
  const pathElements = value.split(":");
  const mainPathElement = pathElements.pop();

  const pathContent = useMemo(
    () =>
      pathElements.length
        ? pathElements.map((pathElement, index) => (
            <>
              <BreadCrumb
                key={pathElement + index}
                text={getText(pathElement, isLimitedPath)}
                isHighlighted={isHighlighted}
              />
              <BreadCrumb
                key={pathElement + index}
                text=" > "
                isHighlighted={isHighlighted}
              />
            </>
          ))
        : null,
    [isHighlighted, isLimitedPath, pathElements]
  );

  if (!mainPathElement) return null;

  return (
    <Container>
      {pathContent}
      <BreadCrumb isHighlighted={false} text={mainPathElement} />
    </Container>
  );
};
