import { useEffect, useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import { AlertTriangle16, Deploy24 } from "@komodorio/design-system/icons";
import { min } from "lodash";

import { useInvestigationModeStore } from "../../../shared/store/investigationModeStore/investigationModeStore";
import {
  setCorrelatedMetricsSelector,
  setIsMetricsSupportedSelector,
} from "../../../shared/store/investigationModeStore/investigationModeSelectors";
import { usePodMetrics } from "../../Metrics/usePodMetrics";
import { AvailabilityRunWithResults } from "../types";
import { EventData } from "../../Metrics/types";
import { DeployStatusEnum } from "../../common/EventGroup/deployEvent/getDeployStatus";
import { Deploy } from "../../../generated/monitorsApi";
import { useIsMetricsDrawerSupported } from "../../Metrics/metricsHooks";

const issuesTooltipText = "Container Crashed";
const deployTooltipText = "Deploy Started";

export const useCorrelatedMetrics = (
  issue: AvailabilityRunWithResults | null
): void => {
  const setCorrelatedMetrics = useInvestigationModeStore(
    setCorrelatedMetricsSelector
  );
  const isMetricsSupported = useIsMetricsDrawerSupported(issue?.cluster ?? "");
  const setIsMetricsSupported = useInvestigationModeStore(
    setIsMetricsSupportedSelector
  );

  useEffect(() => {
    setIsMetricsSupported(isMetricsSupported);
  }, [isMetricsSupported, setIsMetricsSupported]);

  const metricsEndTime = useIssueMetricsEndTime(issue);
  const { containerName, podName } = useFailedContainerFromIssue(issue);
  const fetchedMetrics = usePodMetrics(
    issue?.cluster ?? "",
    issue?.namespace ?? "",
    podName,
    containerName,
    metricsEndTime,
    !!isMetricsSupported
  );

  useEffect(() => {
    if (fetchedMetrics) {
      setCorrelatedMetrics(fetchedMetrics);
    }
  }, [fetchedMetrics, setCorrelatedMetrics]);
};

const useFailedContainerFromIssue = (
  issue: AvailabilityRunWithResults | null
): {
  containerName: string;
  podName: string;
} => {
  return useMemo(() => {
    const failedContainerName =
      issue?.results?.correlatedDeploys?.output?.failedContainerName ??
      issue?.results?.limitDecreased?.output?.containerName ??
      issue?.results?.memoryLeak?.output?.containerName ??
      "";
    const failedPodName =
      issue?.results?.correlatedDeploys?.output?.failedPodName ??
      issue?.results?.limitDecreased?.output?.podName ??
      issue?.results?.memoryLeak?.output?.podName ??
      "";
    return {
      containerName: failedContainerName,
      podName: failedPodName,
    };
  }, [
    issue?.results?.correlatedDeploys?.output?.failedContainerName,
    issue?.results?.correlatedDeploys?.output?.failedPodName,
    issue?.results?.limitDecreased?.output?.containerName,
    issue?.results?.limitDecreased?.output?.podName,
    issue?.results?.memoryLeak?.output?.containerName,
    issue?.results?.memoryLeak?.output?.podName,
  ]);
};

export const usePodMetricsEndTime = (eventTime: number): number => {
  return useMemo(() => {
    const eventTimeDate = new Date(eventTime);
    const eventTimeWithOffset = eventTimeDate.setHours(
      eventTimeDate.getHours() + 12
    );
    const currentTime = Date.now();
    return min([eventTimeWithOffset, currentTime]) ?? currentTime;
  }, [eventTime]);
};

export const useIssueMetricsEndTime = (
  issue: AvailabilityRunWithResults | null | undefined
): number => {
  return usePodMetricsEndTime(new Date(issue?.eventTime ?? "").getTime());
};

export const useIssueMetricsEvents = (
  issueTime: Date | undefined
): EventData[] =>
  useMemo(() => {
    if (!issueTime) {
      return [];
    }
    return [
      {
        time: issueTime.getTime(),
        icon: AlertTriangle16,
        popoverContent: {
          element: issuesTooltipText,
        },
        color: muiColors.pink[500],
        highlight: true,
      },
    ];
  }, [issueTime]);

export const useGetDeployMetricsEvents = (
  issue: AvailabilityRunWithResults | null | undefined,
  deploys: Deploy[]
) => {
  const issueMetricsEvents = useIssueMetricsEvents(issue?.eventTime);

  return useMemo(() => {
    if (!issue) {
      return [];
    }
    return [
      ...deploys.map((deploy): EventData => {
        return {
          time: new Date(deploy.eventTime ?? "").getTime(),
          icon: Deploy24,
          popoverContent: {
            element: deployTooltipText,
          },
          color:
            deploy.status === DeployStatusEnum.Failed
              ? muiColors.pink[500]
              : undefined,
          highlight: false,
        };
      }),
      ...issueMetricsEvents,
    ];
  }, [deploys, issue, issueMetricsEvents]);
};
