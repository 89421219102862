import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1ClustersOverviewGetUrl,
  ClusterOverviewResponseInner,
  ClustersApiApiV1ClustersOverviewGetRequest,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_CLUSTERS_OVERVIEW_PATH = "/clusters/overview";

const fetchClustersOverviewMetrics = async (
  apiClient: AxiosInstance,
  params: ClustersApiApiV1ClustersOverviewGetRequest
): Promise<ClusterOverviewResponseInner> => {
  const { data } = await apiClient.get(
    apiV1ClustersOverviewGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetClustersOverviewMetrics = (
  params: ClustersApiApiV1ClustersOverviewGetRequest,
  options?: { enabled?: boolean; staleTime?: number }
) => {
  const apiClient = useMetricsApiClient();

  return useQuery(
    [GET_CLUSTERS_OVERVIEW_PATH, params],
    () => fetchClustersOverviewMetrics(apiClient, params),
    options
  );
};
