import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 16px;
  }
`;

const StyledTypography = styled(Typography).attrs({
  variant: "body3",
  color: "text.secondary",
})``;

const StyledArrow = styled(ChevronRight).attrs({
  color: "action",
})``;

type ServicePathProps = {
  serviceName?: string;
  namespace?: string;
  cluster?: string;
};

export const ServicePath: React.FC<ServicePathProps> = ({
  serviceName,
  namespace,
  cluster,
}) => {
  return (
    <Container>
      {cluster && <StyledTypography>{cluster}</StyledTypography>}
      {namespace && (
        <>
          <StyledArrow />
          <StyledTypography>{namespace}</StyledTypography>
        </>
      )}
      {serviceName && (
        <>
          <StyledArrow />
          <StyledTypography> {serviceName}</StyledTypography>
        </>
      )}
    </Container>
  );
};
