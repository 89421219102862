export const intersection = <T>(sets: Set<T>[]): Set<T> => {
  const sortedBySize = [...sets].sort((s1, s2) => s1.size - s2.size);
  const base = new Set(sortedBySize[0]);
  sortedBySize.slice(1).forEach((s) => {
    base.forEach((e) => {
      if (!s.has(e)) base.delete(e);
    });
  });
  return base;
};
