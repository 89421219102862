import React, { ReactNode, useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeployEvent } from "../../../EventGroup/deployEvent/DeployEventGroup";
import EventDetailsSection from "../components/EventDetailsSection";
import eventsIcon from "../../assets/events.svg";
import EventsGrid from "../components/EventsGrid";
import { addWBR } from "../../../../../shared/utils/addWBR";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function addWbrForStatus(statusMessage: string): ReactNode {
  if (!statusMessage.startsWith("Back-off pulling image")) {
    return statusMessage;
  }
  return addWBR(statusMessage);
}
const deployAgentInactivityStatus = "inactive-agent";
const deployAgentInactivityStatusMessage =
  "Closed due to Komodor agent inactivity";
interface EventsSectionProps {
  events: DeployEvent[];
}
const EventsSection: React.FC<EventsSectionProps> = ({ events }) => {
  const prepared = useMemo(() => {
    const generation = events[0].getGeneration();
    if (events[0].endEventTime) {
      const e = events[0];
      return [
        {
          eventTime: e.eventTime,
          status: "start",
          details: `deploy generation:${generation} started`,
        },
        {
          eventTime: e.endEventTime as Date,
          status: e.status,
          details:
            e._event.status === "Failure" && e._event.statusReason
              ? e._event.statusReason
              : e._event.statusReason === deployAgentInactivityStatus
              ? deployAgentInactivityStatusMessage
              : `deploy generation:${generation} ${e.status.toLowerCase()}`,
        },
      ];
    }
    return events
      .map((e) => {
        const result = {
          eventTime: e.eventTime,
          status: e.status,
          details:
            e._event.status === "Failure" && e._event.statusReason
              ? e._event.statusReason
              : `deploy generation:${generation} ${e.status.toLowerCase()}`,
        };
        return result;
      })
      .flat();
  }, [events]);
  return (
    <EventDetailsSection
      icon={eventsIcon}
      iconDimensions={{ height: "50%", width: "50%" }}
      title="Events"
    >
      <EventsGrid events={prepared} />
    </EventDetailsSection>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EventsSection;
