import { ReactElement } from "react";
import ReactModal from "react-modal";
import { createRoot } from "react-dom/client";
import "./index.css";

import "./shared/context/electron/electronApiContext";
import { globalConfig, globalConfigUrl } from "./shared/config/appConfig";
import { ErrorMessage, StrictApp } from "./SafeApp";
import { LoadingApp } from "./LoadingApp";

ReactModal.setAppElement("#root");
const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<LoadingApp />);

// see https://vitejs.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

async function deferRender() {
  if (
    !import.meta.env.DEV ||
    import.meta.env.VITE_ENABLE_MSW_MOCKS !== "true"
  ) {
    return;
  }

  const { worker } = await import("./test/browser.js");

  return worker.start({ onUnhandledRequest: "bypass" });
}

fetch(globalConfigUrl)
  .then(async (response) => {
    const data = await response
      .clone() // clone the response so that we can use the body multiple times. See https://developer.mozilla.org/en-US/docs/Web/API/Response/clone
      .json();
    globalConfig.set(data);
    return StrictApp;
  })
  .catch((e) => {
    return ErrorMessage;
  })
  .then(async (reactElement: ReactElement) => {
    await deferRender();
    return reactElement;
  })
  .then((reactElement: ReactElement) => {
    root.render(reactElement);
  });
