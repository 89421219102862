import styled from "styled-components";
import Link from "@mui/material/Link";

import { EmptyState } from "../common/UpgradeAgentVersionAlert/EmptyState";

import { ALL_CLUSTERS } from "./WorkspacesTopBar/constants";
import { useNavigateToWorkspace } from "./WorkspacesTopBar/hooks";

import { AriaLabels } from "@/shared/config/ariaLabels";

const EmptyStateContainer = styled.div`
  display: grid;
`;

type WorkspaceGenericEmptyStateProps = {
  title?: React.ReactNode;
};

const StyledEmptyState = styled(EmptyState)`
  && {
    width: 100%;
  }
`;

export const WorkspaceGenericEmptyState: React.FC<
  WorkspaceGenericEmptyStateProps
> = ({ title }) => {
  const navigateToWorkspace = useNavigateToWorkspace();
  const navigateToAllClusters = () => navigateToWorkspace(undefined);

  return (
    <EmptyStateContainer
      aria-label={AriaLabels.ClustersView.NonClusterWorkspaceEmptyState}
    >
      <StyledEmptyState
        title={
          title ?? (
            <>
              You are viewing a specific workspace.
              <br />
              Switch to "{ALL_CLUSTERS}" to see the full list.
            </>
          )
        }
      >
        <Link
          color="primary"
          underline="none"
          sx={{ cursor: "pointer", fontSize: "14px" }}
          onClick={navigateToAllClusters}
        >
          Switch to {ALL_CLUSTERS.toLowerCase()}
        </Link>
      </StyledEmptyState>
    </EmptyStateContainer>
  );
};
