import React from "react";

import {
  formatCurrency,
  getFixedNumber,
} from "../../../shared/utils/formatCurrency";

export const PotentialSavingsCell: React.FC<{
  potentialSavings: number;
}> = ({ potentialSavings }) => {
  return (
    <>
      {getFixedNumber(potentialSavings) <= 0
        ? "$0"
        : formatCurrency(potentialSavings)}
    </>
  );
};
