import React, { useEffect, useMemo, useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { Props } from "recharts/types/component/Legend";

import { RightSizingCostDataPoint } from "../../../../../../generated/metricsApi";
import { SAVINGS_COLOR } from "../../../../constants/costOptimizationConstants";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { getDateKey } from "../../../../utils/graphUtils";
import { ChartTooltip } from "../../../shared/graph/ChartTooltip";
import { Dictionary } from "../../../../../../shared/types/Dictionary";

import { CustomLegend } from "./Legend/Legend";

const MIN_WIDTH_WITH_1_INTERVAL = 580;

interface MetricsGraphProps {
  datapoints: RightSizingCostDataPoint[];
  unit: string;
}

const Container = styled.div`
  height: 228px;
  width: 100%;
`;

const getChartData = (data: RightSizingCostDataPoint[]) => {
  const sortedChartData = [...data].sort(
    (a, b) => a.timestampEpoch - b.timestampEpoch
  );

  return sortedChartData?.map((chartItem) => {
    return {
      ...chartItem,
      timestampEpoch: getDateKey(chartItem.timestampEpoch),
    };
  });
};

const { yAxis: yAxisLabel, xAxis: xAxisLabel } =
  costOptimizationAriaLabels.rightSizingPage.rightSizingGraph;

export const MetricsGraph: React.FC<MetricsGraphProps> = ({
  datapoints,
  unit,
}) => {
  const [hideData, setHideData] = useState<Dictionary<boolean>>();
  const [chartInterval, setChartInterval] = useState<number>(1);

  const fullChartData = useMemo(() => {
    if (!datapoints.length) return;
    return getChartData(datapoints);
  }, [datapoints]);

  useEffect(() => {
    if (fullChartData)
      setHideData(
        Object.keys(fullChartData[0]).reduce(
          (acc, cur) => ({ ...acc, [cur]: false }),
          {}
        )
      );
  }, [fullChartData]);

  const legendItemOnClick = (key: string) => {
    if (hideData) {
      const newData = { ...hideData, [key]: !hideData[key] };
      setHideData(newData);
    }
  };

  const chartData = useMemo(() => {
    if (hideData && fullChartData) {
      return fullChartData.map((chartElement) => {
        const fullElementData = { ...chartElement } as {
          [key: string]: number | string | null;
        };
        Object.keys(hideData).forEach((e) => {
          if (hideData[e]) {
            fullElementData[e] = null;
          }
        });
        return fullElementData;
      });
    }
    return;
  }, [fullChartData, hideData]);

  const onResize = (width: number) => {
    if (width <= MIN_WIDTH_WITH_1_INTERVAL) {
      setChartInterval(2);
    } else {
      setChartInterval(1);
    }
  };

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%" onResize={onResize}>
        <ComposedChart
          data={chartData ?? []}
          margin={{
            top: 20,
            right: 20,
          }}
        >
          <CartesianGrid stroke={muiColors.gray[300]} vertical={false} />
          {hideData && (
            <>
              <Tooltip
                content={(props) => <ChartTooltip data={props} unit={unit} />}
                wrapperStyle={{ outline: "none" }}
                isAnimationActive={false}
              />
              <Legend
                content={(props) => (
                  <CustomLegend
                    props={props as Props}
                    setHide={legendItemOnClick}
                    hide={hideData}
                  />
                )}
                align="center"
              />
            </>
          )}
          <Bar dataKey="usage" barSize={20} fill={muiColors.teal[100]} />
          <Line
            type="monotone"
            dataKey="request"
            dot={false}
            strokeWidth={2}
            stroke={muiColors.gray[700]}
            strokeDasharray="8 8"
          />
          <Line
            type="monotone"
            dataKey="recommendation"
            dot={false}
            strokeWidth={2}
            stroke={SAVINGS_COLOR}
          />
          <XAxis
            aria-label={xAxisLabel}
            dataKey="timestampEpoch"
            tick={{ fontSize: 10 }}
            interval={chartInterval}
          />
          <YAxis
            aria-label={yAxisLabel}
            domain={["auto", "dataMax + 20"]}
            tick={{ fontSize: 10 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
};
