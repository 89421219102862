import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

export const useCurrentFavoriteAccountAsOptions = (
  parsedFavoriteAccounts: string[],
  unsortedAccounts: { id: string; name: string }[] | undefined
) =>
  useMemo(
    () =>
      unsortedAccounts?.reduce<MuiSelectionOption<string>[]>((acc, account) => {
        if (parsedFavoriteAccounts.includes(account.name)) {
          acc.push({
            value: account.id,
            label: account.name,
          });
        }
        return acc;
      }, []),
    [parsedFavoriteAccounts, unsortedAccounts]
  );
