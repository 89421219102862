import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import {
  AddExternalLinkMutationVariables,
  useDeleteExternalLinkMutation,
} from "../../../../../../../generated/graphql";
import { gray13 } from "../../../../../../../Colors";
import { FormContainer, ButtonContainer } from "../LinkModal.styles";
import { UpsertLinkErrorType, ModalErrorMessage, LinkModal } from "../utils";
import LinkWarnings from "../LinkWarnings";
import { TextRegular } from "../../../../../../common/typography";
import { usePrivilegedOptions } from "../../../../../../../shared/hooks/roles/usePrivilegedOptions";

const DeleteFormContainer = styled(FormContainer)`
  margin-bottom: 2.5rem;
`;

export type FormValues = {
  name: string;
  link: string;
};

const LinkWarningsContainer = styled.div`
  border: 1px solid ${gray13};
  box-sizing: border-box;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
`;

const BoldText = styled.div`
  margin-top: 0.25rem;
  font-weight: 700;
`;

const DeleteLinkForm: React.FC<{
  handleClose: () => void;
  linkId: string;
  linkInformation: AddExternalLinkMutationVariables;
}> = ({ handleClose, linkId, linkInformation }) => {
  const [errorMessage, setErrorMessage] = useState<UpsertLinkErrorType>(
    UpsertLinkErrorType.NoError
  );
  const { displayName, link, k8sClusterName, namespace } = linkInformation;

  const [, deleteLink] = useDeleteExternalLinkMutation();
  const privilegedOptions = usePrivilegedOptions();

  const deleteLinkOnSubmit = async () => {
    const { error } = await deleteLink(
      {
        id: linkId,
      },
      { fetchOptions: privilegedOptions?.context.fetchOptions }
    );
    if (error) {
      setErrorMessage(UpsertLinkErrorType.DeleteLinkError);
      return;
    }
    handleClose();
  };

  return (
    <div>
      <DeleteFormContainer>
        <TextRegular>
          Are you sure you want to delete the external link:
          <BoldText>
            This is {displayName} ({link})
          </BoldText>
        </TextRegular>
        {(k8sClusterName || namespace) && (
          <LinkWarningsContainer>
            <LinkWarnings clusterName={k8sClusterName} namespace={namespace} />
          </LinkWarningsContainer>
        )}
        <ModalErrorMessage
          errorMessage={errorMessage}
          expectedType={UpsertLinkErrorType.DeleteLinkError}
        />
      </DeleteFormContainer>

      <ButtonContainer>
        <Button variant="primary" onClick={deleteLinkOnSubmit}>
          Delete Link
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </ButtonContainer>
    </div>
  );
};

const DeleteLinkModal: React.FC<{
  show: boolean;
  close: () => void;
  linkId: string;
  linkInformation: AddExternalLinkMutationVariables;
}> = ({ show, close, linkId, linkInformation }) => (
  <LinkModal title="Delete Link" show={show} handleClose={close}>
    <DeleteLinkForm
      handleClose={close}
      linkId={linkId}
      linkInformation={linkInformation}
    />
  </LinkModal>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeleteLinkModal;
