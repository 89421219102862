import { Button } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import { blackGreyText, gray13 } from "../../Colors";
import { Divider } from "../common/ProcessList/details/common";
import { H3Light } from "../common/typography";
import { ONE_DAY_IN_MS } from "../../shared/constants/time";

import { ModalInsetFromTop } from "./Modal";

const StyledH3 = styled(H3Light)`
  font-size: 16px;
  font-family: Poppins;
`;

const Container = styled.div<{ blockRectY: number; insetFromTop: string }>`
  position: absolute;
  top: calc(
    ${({ blockRectY, insetFromTop }) => `${blockRectY}px - ${insetFromTop}`} -
      190px
  );
  margin-left: -15rem;
  background: white;
  width: 14.25rem;
  height: 14.75rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border: ${gray13} 1px solid;
  border-radius: 4px;
`;

const IGNORE_RADIO_OPTIONS = {
  "14": "14 days",
  "30": "30 days",
  "90": "90 days",
  forever: "Ignore forever",
};

const SwitchLabel = styled.label`
  line-height: 2rem;
  font-size: 14px;
  color: ${blackGreyText};
  text-align: center;
  cursor: pointer;
`;

const RadioButtonAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;
`;

const TitleAndRadioButtonsContainer = styled.div`
  padding: 1rem;
  padding-bottom: 0.3rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.7rem;
  justify-content: end;
  gap: 1rem;
`;

function calculateExerationDate(id: string): string {
  const days = +id;
  if (!days) return "forever";
  return new Date(new Date().getTime() + days * ONE_DAY_IN_MS).toISOString();
}
interface IgnoreTimePickerProps {
  blockRectY: number;
  expiration: string;
  setExpiration: React.Dispatch<React.SetStateAction<string>>;
  setIgnoreTimePickerId: React.Dispatch<React.SetStateAction<string>>;
  ignoreCallback: (expiration: string) => void;
}

const IgnoreTimePicker: React.FC<IgnoreTimePickerProps> = ({
  blockRectY,
  expiration,
  setExpiration,
  setIgnoreTimePickerId,
  ignoreCallback,
}) => {
  return (
    <Container
      blockRectY={blockRectY}
      insetFromTop={ModalInsetFromTop}
      data-e2e-selector={"best-practices-modal-check-card-ignore-popup"}
    >
      <TitleAndRadioButtonsContainer>
        <StyledH3>Ignore for:</StyledH3>
        <br />
        <>
          {Object.entries(IGNORE_RADIO_OPTIONS).map(([id, text], key) => {
            return (
              <RadioButtonAndLabelContainer
                key={key}
                onClick={() => setExpiration(id)}
              >
                <input
                  type="radio"
                  name="switch"
                  value={text}
                  checked={expiration === id}
                  onChange={() => setExpiration(id)}
                />
                <SwitchLabel>{text}</SwitchLabel>
              </RadioButtonAndLabelContainer>
            );
          })}
        </>
      </TitleAndRadioButtonsContainer>
      <Divider />
      <ButtonsContainer>
        <Button size="small" onClick={() => setIgnoreTimePickerId("")}>
          cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            setIgnoreTimePickerId("");
            ignoreCallback(calculateExerationDate(expiration));
          }}
        >
          Ignore
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default IgnoreTimePicker;
