import { useCallback, useEffect, useRef } from "react";

import {
  TimeWindowAsNumber,
  useAppViewDataInUrl,
  useAppViewStateInLocalStorage,
} from "../../../../hooks/appViewPersistentState";
import { TimeWindow } from "../../../../../../../../shared/types/TimeWindow";
import { timeWindowToNumber } from "../../../../utils/timeWindow";

type PersistentTimeWindowReturnType = {
  timeWindow: TimeWindowAsNumber | undefined;
  id: string;
};

export const usePersistentTimeWindowEffect = ():
  | PersistentTimeWindowReturnType
  | undefined => {
  const [
    { timeWindow: timeWindowInLocalStorage, id: idInLocalStorage },
    setAppViewInLocalStorage,
  ] = useAppViewStateInLocalStorage();
  const [{ timeWindow: timeWindowInUrl, id: idInUrl }, setAppViewInUrl] =
    useAppViewDataInUrl();
  const persistentTimeWindow = useRef<PersistentTimeWindowReturnType>();

  useEffect(() => {
    if (timeWindowInLocalStorage) {
      if (timeWindowInLocalStorage !== timeWindowInUrl) {
        persistentTimeWindow.current = {
          timeWindow: timeWindowInLocalStorage,
          id: idInLocalStorage,
        };
        setAppViewInUrl({
          timeWindow: timeWindowInLocalStorage,
        });
      }
      return;
    }
    if (timeWindowInUrl) {
      persistentTimeWindow.current = {
        timeWindow: timeWindowInUrl,
        id: idInUrl,
      };
      setAppViewInLocalStorage({
        timeWindow: timeWindowInUrl,
      });
      return;
    }
    persistentTimeWindow.current = undefined;
  }, [
    idInLocalStorage,
    idInUrl,
    setAppViewInLocalStorage,
    setAppViewInUrl,
    timeWindowInLocalStorage,
    timeWindowInUrl,
  ]);

  return persistentTimeWindow.current;
};

export const useUpdatePersistentTimeWindow = (): ((
  timeWindow: TimeWindow
) => void) => {
  const [, setAppViewInLocalStorage] = useAppViewStateInLocalStorage();
  const [, setAppViewInUrl] = useAppViewDataInUrl();

  return useCallback(
    (timeWindow: TimeWindow) => {
      const timeWindowAsNumber = timeWindowToNumber(timeWindow);
      setAppViewInLocalStorage({ timeWindow: timeWindowAsNumber });
      setAppViewInUrl({ timeWindow: timeWindowAsNumber });
    },
    [setAppViewInLocalStorage, setAppViewInUrl]
  );
};
