import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { roundValueAsNumber } from "../../../utils/utils";

interface CustomLabelProps {
  x?: number;
  y?: number;
  value?: string | number;
  color?: string;
}

const Text = styled.text`
  font-size: 14px;
`;

export const CustomLabel: React.FC<CustomLabelProps> = ({
  x,
  y,
  value,
  color = muiColors.gray[700],
}) => {
  const content = useMemo(
    () => (value ? `Avg:${roundValueAsNumber(value)}/day` : ""),
    [value]
  );

  return value ? (
    <Text x={x} y={y} dy={-10} dx={45} textAnchor="middle" fill={color}>
      {content}
    </Text>
  ) : null;
};
