import React, { useState } from "react";
import styled from "styled-components";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import { CustomCopyCode } from "./CustomCopyCode";

const StyledPanel = styled(TabPanel)`
  && {
    padding: 0;
  }
`;

type TabLabel = "Mac / Linux" | "Windows";
type TabValue = "macLinux" | "windows";

const tabLabels: Record<string, { label: TabLabel; value: TabValue }> = {
  mac: { label: "Mac / Linux", value: "macLinux" },
  windows: { label: "Windows", value: "windows" },
};

const installKomoCliCommandLinux = `/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/komodorio/komocli/HEAD/scripts/install.sh)"`;
const installKomoCliCommandWindows = `Invoke-Expression (Invoke-WebRequest -Uri "https://raw.githubusercontent.com/komodorio/komocli/HEAD/scripts/install.ps1").Content`;

// text with spaces for proper line breaks presentation
const installKomoCliCommandLinuxAsText = `/bin/bash -c "$(curl -fsSL https:// raw.githubusercontent.com/komodorio/komocli/HEAD/ scripts/install.sh)"`;
const installKomoCliCommandWindowsAsText = `Invoke-Expression (Invoke-WebRequest -Uri "https://raw.githubusercontent.com/komodorio/ komocli/HEAD/scripts/install.ps1").Content`;

export const InstallKomoCliTabs: React.FC = () => {
  const [tab, setTab] = useState<TabValue>("macLinux");
  const { mac: macLabel, windows: windowsLabel } = tabLabels;

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as TabValue);
  };

  return (
    <div>
      <TabContext value={tab}>
        <TabList onChange={onTabChange}>
          <Tab label={macLabel.label} value={macLabel.value} />
          <Tab label={windowsLabel.label} value={windowsLabel.value} />
        </TabList>
        <StyledPanel value={macLabel.value}>
          <CustomCopyCode
            fullCommand={installKomoCliCommandLinux}
            commandAsText={installKomoCliCommandLinuxAsText}
          />
        </StyledPanel>
        <StyledPanel value={windowsLabel.value}>
          <CustomCopyCode
            fullCommand={installKomoCliCommandWindows}
            commandAsText={installKomoCliCommandWindowsAsText}
          />
        </StyledPanel>
      </TabContext>
    </div>
  );
};
