import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { ServiceData } from "../../../generated/workspacesApi";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { DistributionItemWithTooltip } from "@/components/ClustersView/components/DistributionItemWithTooltip";
import { getMinDigitsWidth } from "@/components/ClustersView/utils";

const { HealthyServices, UnhealthyServices } =
  AriaLabels.ClustersView.ServicesCell;

const ITEM_MIN_DIGITS_WIDTH = getMinDigitsWidth(4);

const Container = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

export const ServicesCell: React.FC<{ services: ServiceData }> = ({
  services,
}) => {
  return (
    <Container>
      <DistributionItemWithTooltip
        count={services.healthy.count}
        name="Healthy"
        statusColor={muiColors.green[500]}
        ariaLabel={HealthyServices}
        minWidth={ITEM_MIN_DIGITS_WIDTH}
      />
      <DistributionItemWithTooltip
        count={services.unhealthy.count}
        name="Unhealthy"
        statusColor={muiColors.red[500]}
        ariaLabel={UnhealthyServices}
        minWidth={ITEM_MIN_DIGITS_WIDTH}
      />
    </Container>
  );
};
