import { useMemo, useState } from "react";
import { parseISO } from "date-fns";

import { KubernetesAgentInfo } from "@/components/AgentsView/types";
import { useAgentServicePolling } from "@/shared/hooks/agents-api/agents/useAgentsPolling";

export const useAgentsView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<
    KubernetesAgentInfo | undefined
  >(undefined);
  const [isRestartAgentModalOpen, setIsRestartAgentModalOpen] = useState(false);
  const [isRestartFailed, setIsRestartFailed] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

  const { data } = useAgentServicePolling(1000 * 15); // 15 seconds
  const orderedActiveAgents = useMemo(() => {
    if (!data?.activeAgents) return [];
    return data?.activeAgents.sort(
      (agent1, agent2) =>
        parseISO(agent2.lastSeenAlive).getTime() -
        parseISO(agent1.lastSeenAlive).getTime()
    );
  }, [data?.activeAgents]);

  const orderedInactiveAgents = useMemo(() => {
    if (!data?.inactiveAgents) return [];
    return data?.inactiveAgents.sort(
      (agent1, agent2) =>
        parseISO(agent2.lastSeenAlive).getTime() -
        parseISO(agent1.lastSeenAlive).getTime()
    );
  }, [data?.inactiveAgents]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleRestartAgentModalClose = () => {
    setIsRestartAgentModalOpen(false);
  };

  const handleFailure = () => {
    setIsRestartFailed(true);
  };

  const handleExecuting = (executing: boolean) => {
    setIsExecuting(executing);
  };

  const onRestartAgentClick = (agent: KubernetesAgentInfo) => {
    setSelectedAgent(agent);
    setIsRestartAgentModalOpen(true);
  };

  const onRowClick = (agent: KubernetesAgentInfo) => {
    setSelectedAgent(agent);
    if (isRestartAgentModalOpen) {
      return;
    }
    setIsModalOpen(true);
  };
  return {
    isModalOpen,
    isRestartAgentModalOpen,
    isRestartFailed,
    isExecuting,
    orderedActiveAgents,
    orderedInactiveAgents,
    handleModalClose,
    handleRestartAgentModalClose,
    handleFailure,
    handleExecuting,
    onRestartAgentClick,
    onRowClick,
    selectedAgent,
  };
};
