import { useMemo } from "react";
import { DataGridProPropsWithoutDefaultValue } from "@mui/x-data-grid-pro/models/dataGridProProps";

import { CsvData } from "./types";

interface UseCsvDataProps<T extends object> {
  getHeaders: (
    rows: DataGridProPropsWithoutDefaultValue["rows"]
  ) => Record<string, string>;
  getValue: (category: keyof T, row: T) => string | number | undefined;
  rows?: DataGridProPropsWithoutDefaultValue["rows"];
}

export const getCsvData = <T extends object>({
  getHeaders,
  getValue,
  rows,
}: UseCsvDataProps<T>): CsvData => {
  if (!rows) return [];
  const headers = getHeaders(rows);
  const data = rows.map((row) => {
    return Object.keys(headers).reduce((acc, key) => {
      const headerName = headers[key];
      const value = getValue(key as keyof T, row);

      if (!headerName || !value) return acc;

      return { ...acc, [headerName]: value };
    }, {});
  });

  return data;
};

export const useCsvData = <T extends object>({
  getHeaders,
  getValue,
  rows,
}: UseCsvDataProps<T>): CsvData => {
  return useMemo(
    () => getCsvData({ getHeaders, getValue, rows }),
    [getHeaders, getValue, rows]
  );
};
