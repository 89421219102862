import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1ZendeskAuthGetUrl,
  ZendeskJWTResponse,
} from "../../../../../generated/auth";

const ZENDESK_JWT_PATH = "/zendesk/auth";

export const fetchZendeskJwt = async (
  apiClient: AxiosInstance
): Promise<ZendeskJWTResponse> => {
  const { data } = await apiClient.get(
    apiV1ZendeskAuthGetUrl(apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useFetchZendeskJwt = (enabled?: boolean) => {
  const apiClient = useAuthApiClient();
  return useQuery([ZENDESK_JWT_PATH], () => fetchZendeskJwt(apiClient), {
    enabled,
  });
};
