import React from "react";
import Box from "@mui/material/Box";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Check from "@mui/icons-material/Check";
import { muiColors } from "@komodorio/design-system";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const UnhealthyServicesCell: React.FC<{
  unhealthyServices: number;
  unhealthyServicesViolations: number;
}> = ({ unhealthyServices, unhealthyServicesViolations }) => {
  const { reliabilityHealthManagement } = useOverridableFlags();

  if (reliabilityHealthManagement) {
    if (!unhealthyServicesViolations)
      return <Check sx={{ color: muiColors.teal[900] }} />;
    return (
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
        <WarningAmberOutlined sx={{ fill: muiColors.pink[700] }} />
        {unhealthyServicesViolations.toLocaleString()}
      </Box>
    );
  }
  if (!unhealthyServices) return <Check sx={{ color: muiColors.teal[900] }} />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
      <WarningAmberOutlined sx={{ fill: muiColors.pink[700] }} />
      {unhealthyServices.toLocaleString()}
    </Box>
  );
};
