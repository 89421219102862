import { EnrichedMessage, K8sResourceRegex } from "../types";
import { KubernetesPVCResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVCResource";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPodsResource";
import { K8sResourceToken, NodeResourceToken, StringToken } from "../tokens";
import { KubernetesPVResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVResource";
import { KubernetesNodesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";

import { BaseEnricher } from "./baseEnricher";
import { getStringAfterLastDelimiter } from "./utils";

export class MountVolumeSetUpFailedEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `MountVolume.SetUp failed for volume "${K8sResourceRegex}" : couldn't propagate object cache: timed out waiting for the condition`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "MountVolume.SetUp failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const pv = getStringAfterLastDelimiter(match[1], "/");

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("Failed to mount volume"),
        new K8sResourceToken(pv, KubernetesPVResource.Kind, pv),
      ],
    };
  }
}

export class MultiAttachVolumeErrorEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Multi-Attach error for volume "${K8sResourceRegex}"(?: Volume is already used by pod\\(s\\) ${K8sResourceRegex}|$)`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "Multi-Attach error for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);

    if (!match) {
      return null;
    }

    const pv = getStringAfterLastDelimiter(match[1], "/");
    const pod = match[2] ? getStringAfterLastDelimiter(match[2], "/") : null;

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new K8sResourceToken("The PV", KubernetesPVResource.Kind, pv),
        new StringToken("could not be mounted because"),
        pod
          ? new K8sResourceToken(
              "another pod",
              KubernetesPodsResource.Kind,
              pod
            )
          : new StringToken("another pod"),
        new StringToken("is using it."),
      ],
    };
  }
}

export class MultiAttachVolumeExclusiveErrorEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Multi-Attach error for volume "${K8sResourceRegex}" Volume is already exclusively attached to one node and can't be attached to another`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "Multi-Attach error for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);

    if (!match) {
      return null;
    }

    const pv = getStringAfterLastDelimiter(match[1], "/");

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new K8sResourceToken("A volume", KubernetesPVResource.Kind, pv),
        new StringToken(
          "requested by this service is already attached to a node and cannot be attached to"
        ),
        new NodeResourceToken("another node,", errorMessage),
        new StringToken("at the same time"),
      ],
    };
  }
}

export class PodDidntTriggerScaleUp extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `pod didn't trigger scale-up:(.*)persistentvolumeclaim "${K8sResourceRegex}" not found`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "pod didn't trigger scale-up";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);

    if (!match) {
      return null;
    }

    const pvc = getStringAfterLastDelimiter(match[2], "/");

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          "The scaling-up process for the pod was not initiated because the associated"
        ),
        new K8sResourceToken("PVC", KubernetesPVCResource.Kind, pvc),
        new StringToken("was not found"),
      ],
    };
  }
}

export class AttachVolumeFailedGeneral extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `AttachVolume.Attach failed for volume "${K8sResourceRegex}" .*`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "AttachVolume.Attach failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);

    if (!match) {
      return null;
    }

    const pv = getStringAfterLastDelimiter(match[1], "/");

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new K8sResourceToken("A volume", KubernetesPVResource.Kind, pv),
        new StringToken("could not be attached due to a problem"),
      ],
    };
  }
}

export class AttachVolumeFailedNodeData extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `AttachVolume.Attach failed for volume "${K8sResourceRegex}".* Could not attach volume "${K8sResourceRegex}".*to node "${K8sResourceRegex}".*`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "AttachVolume.Attach failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);

    if (!match) {
      return null;
    }

    const pvc = getStringAfterLastDelimiter(match[1], "/");
    const volume = getStringAfterLastDelimiter(match[2], "/");
    const node = getStringAfterLastDelimiter(match[3], "/");

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("Failed to attach the"),
        new K8sResourceToken("PVC", KubernetesPVCResource.Kind, pvc),
        new StringToken("/"),
        new K8sResourceToken("volume", KubernetesPVResource.Kind, volume),
        new StringToken("to"),
        new K8sResourceToken("the node", KubernetesNodesResource.Kind, node),
      ],
    };
  }
}
