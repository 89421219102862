import React from "react";
import styled from "styled-components";

import { StyledPaper } from "../../shared/styles";
import WorkflowIssueEventGroup from "../../../../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { ServiceInfo } from "../../../../../../../../shared/types/ServiceInfo";

import { ResourceLocation } from "./ResourceLocation";
import { IssueInfo } from "./IssueInfo";

const Container = styled(StyledPaper)`
  && {
    margin-bottom: 10px;
    padding: 10px;
  }
`;

type SingleIssueItemProps = {
  workflowIssue: WorkflowIssueEventGroup;
  serviceInfo?: ServiceInfo;
};

export const SingleIssueItem: React.FC<SingleIssueItemProps> = ({
  workflowIssue,
  serviceInfo,
}) => {
  return (
    <Container>
      <ResourceLocation {...workflowIssue} />
      <IssueInfo workflowIssue={workflowIssue} serviceInfo={serviceInfo} />
    </Container>
  );
};
