import {
  FetchingStateProps,
  RelatedResourcesStore,
} from "./relatedResourcesStore";

// getters
export const fetchingStateSelector = (
  state: RelatedResourcesStore
): FetchingStateProps => state.fetchingState;

// setters
export const setFetchingStateSelector = (
  state: RelatedResourcesStore
): ((fetchingState: {
  key: keyof FetchingStateProps;
  value: boolean;
}) => void) => state.setFetchingState;

export const resetFetchingStateSelector = (
  state: RelatedResourcesStore
): (() => void) => state.resetFetchingState;
