import { useEffect, useMemo } from "react";
import { isEqual } from "lodash";

import { useCostOptimizationStore } from "../store/costOptimizationStore";
import {
  selectClustersEarliestRowTime,
  selectSetClustersEarliestRowTime,
} from "../store/costOptimizationStoreSelectors";
import { useGetClusterNames } from "../../../shared/hooks/useGetAllClusters/useGetAllClusters";
import { useFetchMetricsEarliestRowTime } from "../../../shared/hooks/useFetchMetricsEarliestRowTime";
import { Dictionary } from "../../../shared/types/Dictionary";
import { isDevMode } from "../../../shared/utils/isDevMode";
import { useClusterOptionsByAppView } from "../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { flattenEarliestRowsTimeResponse } from "../../../shared/utils/metricsAgentInfoAccuracyUtils";

import { useScopeAsServerRequestParam } from "./useScopeAsServerRequestParam";

export const hasClustersEarliestRowTimeChanged = (
  currentData: Dictionary<string> | undefined,
  newData: Dictionary<string> | undefined
): boolean => !isEqual(currentData, newData);

export const useInitialClustersEarliestRowTime = () => {
  const currentEarliestRowTime = useCostOptimizationStore(
    selectClustersEarliestRowTime
  );
  const setClustersEarliestRowTime = useCostOptimizationStore(
    selectSetClustersEarliestRowTime
  );

  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const clusterNames = useGetClusterNames(clusterOptions);

  const { data } = useFetchMetricsEarliestRowTime(clusterNames);

  const clustersEarliestRowTime = useMemo(() => {
    return flattenEarliestRowsTimeResponse(data?.clusters);
  }, [data]);

  useEffect(() => {
    if (
      hasClustersEarliestRowTimeChanged(
        currentEarliestRowTime.clusters,
        clustersEarliestRowTime
      )
    )
      setClustersEarliestRowTime({ clusters: clustersEarliestRowTime });
  }, [
    clustersEarliestRowTime,
    currentEarliestRowTime,
    setClustersEarliestRowTime,
  ]);
};

export const useGetClustersEarliestRowTime = () => {
  const scope = useScopeAsServerRequestParam();
  const { clusters } = useCostOptimizationStore(selectClustersEarliestRowTime);

  return useMemo(() => {
    if (!clusters) return;
    return (scope?.clusters ?? []).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: clusters[curr],
      }),
      {}
    );
  }, [clusters, scope?.clusters]);
};
