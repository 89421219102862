import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipWrapper } from "react-tooltip";
import { Link } from "@komodorio/design-system/deprecated";

import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { RELATED_RESOURCES_PODS_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import { ServiceInfo } from "../../../../shared/types/ServiceInfo";
import EventGroup from "../../EventGroup";
import { useOpenIssueTooltip } from "../../../OpenIssues/useOpenIssueTooltip";
import { useOpenIssueByResource } from "../../../OpenIssues/useOpenIssuesByResource";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CellWithIssue, isDataNone, IssueAlertIcon } from ".";

interface ControlledByDataCellProps {
  cellName: string;
  cellData: string;
  service: ServiceInfo | undefined;
  resourceName: string;
  resourceType: KubernetesResource;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  rowId: string;
}

const ControlledByDataCell: React.FC<ControlledByDataCellProps> = ({
  cellName,
  cellData,
  service,
  resourceName,
  resourceType,
  setCorrelatedEvent,
  rowId,
}) => {
  const navigate = useNavigate();
  const openIssue = useOpenIssueByResource(
    undefined,
    undefined,
    service?.kind ?? "",
    undefined,
    service?.id
  );
  const [tooltip, tooltipId, correlatedIssue, ariaLabel] = useOpenIssueTooltip(
    openIssue,
    service,
    setCorrelatedEvent,
    rowId
  );
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onControlledByClick = useCallback(() => {
    dispatchEvent(
      AnalyticEvents.Inspection.Inspection_resource_list_controlled_by_clicked,
      { resource: resourceType.NavBarName }
    );

    if (service?.id) {
      return pushDrawer({
        drawerType: DrawerType.ResourceDrawerByServiceId,
        serviceId: service.id,
      });
    }

    const searchParams =
      resourceType.NameInK8S === "pods"
        ? `${RELATED_RESOURCES_PODS_PARAM_KEY}=${resourceName}&timeframe=30-minutes`
        : "";

    navigate({
      pathname: `/${
        ["job", "cronjob"].includes(service?.kind?.toLowerCase() ?? "")
          ? "jobs"
          : "services"
      }/`,
      search: searchParams,
    });
  }, [
    navigate,
    pushDrawer,
    resourceName,
    resourceType.NameInK8S,
    resourceType.NavBarName,
    service?.id,
    service?.kind,
  ]);

  const linkable = !(isDataNone(cellData) || !service);
  return (
    <td data-e2e-selector={"controlledByColumn"} key={cellName}>
      <TooltipWrapper tooltipId={tooltipId}>
        <CellWithIssue>
          {openIssue && (
            <>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  if (correlatedIssue) setCorrelatedEvent(correlatedIssue);
                }}
                aria-label={ariaLabel}
              >
                <IssueAlertIcon />
              </Link>
              {tooltip}
            </>
          )}
          {linkable ? (
            <Link
              onClick={(event) => {
                event.stopPropagation();
                onControlledByClick();
              }}
            >
              {cellData}
            </Link>
          ) : (
            cellData
          )}
        </CellWithIssue>
      </TooltipWrapper>
    </td>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ControlledByDataCell;
