import { Number, Record, Static, String, Array as RTArray } from "runtypes";

export const ThrottledCPURecord = Record({
  containers: RTArray(
    Record({
      fraction: Number,
      pod: String,
      container: String,
      hours: Number,
    })
  ),
});
export type ThrottledCPUType = Static<typeof ThrottledCPURecord>;
