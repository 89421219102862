import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export const recommendationsPadding = css`
  padding: 16px 24px;
`;

export const StyledInfoOutlinedIcon = styled(InfoOutlined).attrs({
  fontSize: "small",
})`
  color: ${muiColors.gray[500]};
`;
