import { palette } from "@komodorio/design-system";
import { Terminal } from "xterm";

const getNew = () =>
  new Terminal({
    convertEol: true,
    fontFamily: "Roboto Mono",
    fontSize: 12,
    fontWeight: 400,
    cursorBlink: true,
    rightClickSelectsWord: true,
    theme: {
      background: palette.gray["900"],
    },
  });

const map = new Map<string, Terminal>();

export const tryAddNewTerminalInstance = (id: string): Terminal | undefined => {
  if (map.has(id)) {
    return undefined;
  }

  const newTerminal = getNew();
  map.set(id, newTerminal);

  return newTerminal;
};

export const removeTerminalInstance = (id: string): void => {
  const terminal = getTerminalInstance(id);

  map.delete(id);
  terminal?.dispose();
};

export const getTerminalInstance = (id: string): Terminal | undefined =>
  map.get(id);
