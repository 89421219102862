import React from "react";
import { Drawer } from "@komodorio/design-system/deprecated";

import { AuditDetailsContent } from "./AuditDetailsContent";
import AuditObject from "./details/AuditObject";

export const AuditDrawer: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  obj: AuditObject;
}> = ({ isOpen, handleClose, obj }) => {
  return (
    <>
      <Drawer
        open={isOpen}
        direction="right"
        width="40rem"
        zIndex={1}
        onOverlayClick={handleClose}
        onEscKeyPress={handleClose}
      >
        <AuditDetailsContent obj={obj} />
      </Drawer>
    </>
  );
};
