import React, { useEffect } from "react";

import { useConfigMapsByServiceId } from "../graphql";
import { SelfSupplyingResourceListTableProps } from "../types";
import ResourceListTable from "../../../../../../common/ResourceListTable/ResourceListTable";
import { filterResourceTableBySearchTerm } from "../../../../../../Inspection/utils/filterResultsList";
import { useRelatedResourcesStore } from "../../../../../../../shared/store/relatedResourcesStore/relatedResourcesStore";
import { setFetchingStateSelector } from "../../../../../../../shared/store/relatedResourcesStore/relatedResourcesSelectors";

import {
  getUnselectableRowNames,
  onlySelected,
} from "./utils/resourceListTablesUtils";

type ConfigMapListTableProps = SelfSupplyingResourceListTableProps;

export const ConfigMapsListTable: React.FC<ConfigMapListTableProps> = ({
  serviceId,
  cluster,
  rowSelection,
  namespace,
  eventsDetectionTimeframe,
  searchTerm,
  showOnlySelectedRows = false,
  ...props
}) => {
  const {
    rows = [],
    isLoading,
    refresh = () => Function.prototype(),
  } = useConfigMapsByServiceId(
    cluster,
    namespace,
    eventsDetectionTimeframe,
    serviceId
  );

  const filteredRows = filterResourceTableBySearchTerm(rows, searchTerm);
  const unselectableRowNames = getUnselectableRowNames(rows, rowSelection);

  const setFetchingState = useRelatedResourcesStore(setFetchingStateSelector);

  useEffect(() => {
    setFetchingState({
      key: "isFetchingConfigMapsTable",
      value: !!isLoading,
    });
  }, [isLoading, setFetchingState]);

  return (
    <ResourceListTable
      cluster={cluster}
      rows={
        showOnlySelectedRows
          ? onlySelected(filteredRows, rowSelection?.selectedRowNames ?? [])
          : filteredRows
      }
      refreshResults={refresh}
      rowSelection={
        rowSelection ? { ...rowSelection, unselectableRowNames } : undefined
      }
      overridingColumns={overridingColumns}
      {...props}
    />
  );
};

const overridingColumns = [
  {
    name: "name",
    clickable: false,
  },
  {
    name: "namespace",
    clickable: false,
  },
];
