import React from "react";
import styled from "styled-components";

import Checkbox from "../../../../../../common/controls/Checkbox";

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledCheckBox = styled(Checkbox)`
  label {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ClusterNamespaceCheckbox: React.FC<{
  acceptedCluster: boolean;
  setAcceptedCluster: React.Dispatch<React.SetStateAction<boolean>>;
  acceptedNamespace: boolean;
  setAcceptedNamespace: React.Dispatch<React.SetStateAction<boolean>>;
  serviceCluster?: string;
  serviceNamespace?: string;
}> = ({
  acceptedCluster,
  setAcceptedCluster,
  acceptedNamespace,
  setAcceptedNamespace,
  serviceCluster,
  serviceNamespace,
}) => {
  return (
    <CheckboxContainer>
      <StyledCheckBox
        id="addExternalLinkCluster"
        fieldName={`Add this link to all services in this cluster ('${serviceCluster}')`}
        checked={acceptedCluster}
        handleCheckboxChange={() => setAcceptedCluster(!acceptedCluster)}
      />
      <StyledCheckBox
        id="addExternalLinkNamespace"
        fieldName={`Add this link to all services in this namespace ('${serviceNamespace}')`}
        checked={acceptedNamespace}
        handleCheckboxChange={() => setAcceptedNamespace(!acceptedNamespace)}
      />
    </CheckboxContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ClusterNamespaceCheckbox;
