import { useOpenIssues } from "../../shared/hooks/useOpenIssues";
import { KubernetesNodesResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";
import { KubernetesDaemonSetResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesDeploymentResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
import { KubernetesStatefulSetResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { WorkflowConfigType } from "../monitorsView/common/types";
import { KubernetesPVCResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVCResource";
import { KubernetesJobResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { KubernetesCronJobResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { MonitorData } from "../../generated/monitorsApi";
import { KubernetesPodsResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPodsResource";
import {
  buildCronJobIssueKey,
  buildIssueKey,
  buildNodeIssueKey,
} from "../../shared/hooks/monitors-api/client/issues/useGetMonitorIssues";

export const useOpenIssueByResource = (
  cluster = "",
  namespace = "",
  kind: string,
  name = "",
  serviceId = ""
): MonitorData | undefined => {
  const { data: openIssuesByType } = useOpenIssues();

  switch (kind) {
    case KubernetesDeploymentResource.Kind:
    case KubernetesDaemonSetResource.Kind:
    case KubernetesStatefulSetResource.Kind:
      return openIssuesByType?.[WorkflowConfigType.Availability]?.[
        serviceId
      ]?.[0];
    case KubernetesJobResource.Kind:
      return (openIssuesByType?.[WorkflowConfigType.Job]?.[serviceId] ??
        openIssuesByType?.[WorkflowConfigType.CronJob]?.[
          buildCronJobIssueKey(serviceId, name)
        ])?.[0];
    case KubernetesCronJobResource.Kind:
      return Object.entries(
        openIssuesByType?.[WorkflowConfigType.CronJob] ?? {}
      ).find(([key]) => key.startsWith(serviceId))?.[1]?.[0];
    case KubernetesNodesResource.Kind:
      return openIssuesByType?.[WorkflowConfigType.NodeIssue]?.[
        buildNodeIssueKey(cluster, name)
      ]?.[0];
    case KubernetesPodsResource.Kind:
      return (openIssuesByType?.[WorkflowConfigType.Pod] ||
        openIssuesByType?.[WorkflowConfigType.Workflow])?.[
        buildIssueKey(cluster, namespace, name)
      ]?.[0];
    case KubernetesPVCResource.Kind:
      return openIssuesByType?.[WorkflowConfigType.PVC]?.[
        buildIssueKey(cluster, namespace, name)
      ]?.[0];
    default:
      return undefined;
  }
};
