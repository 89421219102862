import { MutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAiApiClient } from "../apiClient";
import {
  AnalyzeLogResponse,
  AnalyzeLogRequest,
  apiV1LogsAnalyzePostUrl,
} from "../../../../../generated/aiApi";

const ANALYZE_LOG_PATH = "/investigator/logs/analyze";

const analyzeLog = async (
  apiClient: AxiosInstance,
  params: AnalyzeLogRequest
): Promise<AnalyzeLogResponse> => {
  const { data } = await apiClient.post(
    apiV1LogsAnalyzePostUrl(
      {
        analyzeLogRequest: params,
      },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const usePostAnalyzeLog = (
  params: AnalyzeLogRequest,
  options?: {
    onMutate: MutationOptions<AnalyzeLogResponse>["onMutate"];
    onSettled: MutationOptions<AnalyzeLogResponse>["onSettled"];
  }
) => {
  const apiClient = useAiApiClient();
  return useMutation(
    [ANALYZE_LOG_PATH, params],
    () => analyzeLog(apiClient, params),
    options
  );
};
