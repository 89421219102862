import { muiColors, muiPalette } from "@komodorio/design-system";

import { Dictionary } from "@/shared/types/Dictionary";

const IgnoredWarningEvents: Dictionary<boolean> = {
  DNSConfigForming: true,
};

export const isWarning = (eventType: string, reason: string) => {
  return eventType === "Warning" && !IgnoredWarningEvents?.[reason];
};

export const getTextColor = (isWarning: boolean): string => {
  return isWarning ? muiColors.pink[700] ?? "" : muiPalette.text?.primary ?? "";
};
