const saveDesktopUserCredentials = (
  mail: string,
  id: string,
  password: string,
  installationId: string
): void => {
  window.electronAPI?.saveCredentials(mail, id, password, installationId);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default saveDesktopUserCredentials;
