import { useMemo } from "react";

import { DeployIssueDataParsed } from "../../common/types";
import { useOverridableFlags } from "../../../../../../../../shared/context/featureFlags/OverridableFlags";
import {
  filterRullIfHpaInRelatedResources,
  isAutomaticDeployByChangeType,
} from "../../../../../../EventGroup/deployEvent/DeployEventGroup";
import { useQueryWithVariables } from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useDeployInsightsStatusesQuery } from "../../../../../../../../generated/graphql";
import getDeployStatus from "../../../../../../EventGroup/deployEvent/getDeployStatus";

export const useFilteredDeployInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
): DeployIssueDataParsed[] => {
  const { hpaEventsInRelatedResources, replicasOnlyAsAutomaticDeploys } =
    useOverridableFlags();
  return useMemo(() => {
    const filterdAutomaticDeploys = latestDeploysInsights.filter(
      (deploy) =>
        !isAutomaticDeployByChangeType(
          deploy.changeType,
          replicasOnlyAsAutomaticDeploys
        )
    );
    return hpaEventsInRelatedResources
      ? filterdAutomaticDeploys.filter((event) =>
          filterRullIfHpaInRelatedResources(event.changeType)
        )
      : filterdAutomaticDeploys;
  }, [
    hpaEventsInRelatedResources,
    latestDeploysInsights,
    replicasOnlyAsAutomaticDeploys,
  ]);
};

export const useUpdatedDeployInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
): DeployIssueDataParsed[] => {
  const variables = useMemo(
    () =>
      latestDeploysInsights.length > 0
        ? { eventIds: latestDeploysInsights.map((e) => e.id) }
        : null,
    [latestDeploysInsights]
  );

  const deploysStatuses = useQueryWithVariables(
    useDeployInsightsStatusesQuery,
    variables
  );
  return useMemo(() => {
    return latestDeploysInsights.map(
      (deploy) => ({
        ...deploy,
        status: getDeployStatus(
          deploysStatuses?.event_deploy?.find(
            (status) => status.id === deploy.id
          )?.status ?? deploy.status
        ),
      }),
      []
    );
  }, [deploysStatuses?.event_deploy, latestDeploysInsights]);
};

export const useLatestDeploysInsight = (
  latestDeploysInsights: DeployIssueDataParsed[]
) => {
  const deployInsightsFiltered = useFilteredDeployInsight(
    latestDeploysInsights
  );
  const latestDeployInsightsUpdated = useUpdatedDeployInsight(
    deployInsightsFiltered
  );
  const deploysLength = latestDeployInsightsUpdated.length;

  return {
    shouldShow: latestDeployInsightsUpdated.length > 0,
    text: `${deploysLength} correlated deploy${
      deploysLength > 1 ? "s" : ""
    } within 1 hour before the issue started`,
    latestDeployInsightsUpdated: latestDeployInsightsUpdated,
  };
};
