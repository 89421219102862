import { useEffect, useState } from "react";

import { DesktopErrorVariant } from "../../../components/DesktopApp/error/DesktopErrorPage";

import isElectronContext from "./isElectronContext";

const useIsDesktopAppError = (): DesktopErrorVariant | null => {
  const [desktopError, setDesktopError] = useState<DesktopErrorVariant | null>(
    null
  );
  useEffect(() => {
    if (!isElectronContext()) return;
    window.electronAPI?.updateDesktopError(
      (_: unknown, errorVariant: DesktopErrorVariant) => {
        setDesktopError(errorVariant);
      }
    );
  }, []);
  return desktopError;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useIsDesktopAppError;
