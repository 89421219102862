import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";

import { useOnCloseDrawerWithConfirmation } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useOnCloseDrawerWithConfirmation";
import { reliabilityDrawerTexts } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div`
  display: flex;
  gap: 16px;
`;

const { close: closeAriaLabel, header: headerAriaLabel } =
  reliabilityArialLabels.policies.policiesDrawer;

export const PolicyDrawerHeader: React.FC = () => {
  const { policyId, isReadOnlyMode } = usePolicyDrawerContext();
  const onCloseDrawerWithConfirmation = useOnCloseDrawerWithConfirmation();

  const isEditPolicyMode = policyId !== undefined;

  const getTitle = () => {
    if (isReadOnlyMode) {
      return reliabilityDrawerTexts.viewReliabilityPolicy;
    }
    if (isEditPolicyMode) {
      return reliabilityDrawerTexts.editReliabilityPolicy;
    }
    return reliabilityDrawerTexts.createNewReliabilityPolicy;
  };

  return (
    <Container aria-label={headerAriaLabel}>
      <IconButton
        size={"small"}
        aria-label={closeAriaLabel}
        color={"inherit"}
        onClick={onCloseDrawerWithConfirmation}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Typography variant={"h3"}>{getTitle()}</Typography>
    </Container>
  );
};
