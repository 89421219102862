import React, { useMemo, useState } from "react";
import { palette, muiColors } from "@komodorio/design-system";
import { Input } from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import { usePortForward } from "../../shared/context/PortForwardProvider";
import { useAuthorization } from "../../shared/hooks/roles/useAuthorization";

import { generateCommand } from "./generateCommand";
import { PORT_VALIDATION_ERROR_MESSAGE } from "./constants";
import { CustomCopyCode } from "./installKomoCli/CustomCopyCode";

interface CommandLineAccessProps {
  agentId: string;
}

const PortsContainer = styled.div`
  border: 1px solid ${palette.gray["200"]};
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const DropdownTitle = styled(Typography).attrs({
  variant: "body2",
  color: muiColors.gray["800"],
})`
  margin-bottom: 4px;
`;

const PortSelectionContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledTextWithBottomMargin = styled(Typography)`
  && {
    margin-bottom: 4px;
  }
`;

export const CommandLineAccess: React.FC<CommandLineAccessProps> = ({
  agentId,
}) => {
  const { resourceName, resourceKind, namespace, availablePorts } =
    usePortForward();

  const dropdownOptions: MuiSelectionOption<number>[] = useMemo(
    () =>
      (availablePorts ?? []).map(({ port, protocol, name }) => ({
        label: `${name ? name + ": " : ""}${port}/${protocol}`,
        value: port,
      })),
    [availablePorts]
  );

  const defaultPort = dropdownOptions[0]?.value ?? 0;
  const [destPort, setDestPort] = useState(defaultPort);
  const [localPort, setLocalPort] = useState(defaultPort);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const jwt = useAuthorization();

  const validatePortValue = (value: string): boolean => !isNaN(Number(value));

  const handlePortChange = (value: string) => {
    const numValue = Number(value);
    if (!validatePortValue(value)) {
      setErrorMessage(PORT_VALIDATION_ERROR_MESSAGE);
      return;
    }
    setErrorMessage(undefined);
    setLocalPort(numValue);
  };

  const { command, fullCommand } = useMemo(
    () =>
      generateCommand({
        localPort,
        destPort,
        agentId,
        namespace,
        resourceName,
        resourceKind,
        jwt,
      }),
    [localPort, destPort, agentId, namespace, resourceName, resourceKind, jwt]
  );

  return (
    <PortsContainer>
      <PortSelectionContainer>
        <DropdownTitle>Local port to forward from:</DropdownTitle>
        <Input
          errorMessage={errorMessage}
          color={palette.gray["800"]}
          width="228px"
          onChange={(e) => handlePortChange(e.target.value)}
          value={localPort || ""}
        />
      </PortSelectionContainer>

      <PortSelectionContainer>
        <DropdownTitle color={palette.gray["800"]}>Select a port</DropdownTitle>
        <SingleSelect
          options={dropdownOptions}
          width="228px"
          onChange={(selected) => selected && setDestPort(selected.value)}
          value={dropdownOptions.find((option) => option.value === destPort)}
        />
      </PortSelectionContainer>

      <StyledTextWithBottomMargin
        color={muiColors.gray["800"]}
        variant={"body2"}
      >
        Run the following command in your CLI:
      </StyledTextWithBottomMargin>
      <CustomCopyCode fullCommand={fullCommand} commandAsText={command} />
    </PortsContainer>
  );
};
