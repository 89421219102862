import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { AriaLabels } from "../../../shared/config/ariaLabels";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-left: 8px;
  top: 12px;
  position: relative;
`;

const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LegendColorIndicator = styled.div<{ color: string }>`
  border-radius: 2px;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color};
`;

export type LegendItem = {
  label: string;
  color: string;
};

type GenericLegendProps = {
  items: LegendItem[];
  className?: string;
};

const { name: genericLegendAriaLabel } =
  AriaLabels.MetricsAvailability.GenericLegend;

export const GenericLegend: React.FC<GenericLegendProps> = ({
  items,
  className,
}) => {
  const content = useMemo(() => {
    return items.map((item) => {
      return (
        <LegendItemContainer key={item.label}>
          <LegendColorIndicator color={item.color} />
          <Typography variant={"body3"} color={"text.secondary"}>
            {item.label}
          </Typography>
        </LegendItemContainer>
      );
    });
  }, [items]);

  return (
    <Container className={className} aria-label={genericLegendAriaLabel}>
      {content}
    </Container>
  );
};
