import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { DrawerPadding } from "../ignoreChecksStyles";
import { useSetAddEditIgnoreRuleDialogState } from "../../../../hooks/reliabilityHooks";
import { ErrorContainer } from "../../../shared/ErrorContainer";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { useHasPermissions } from "../../../../../../shared/hooks/useUserMetadata/rbac";

import {
  useGetCheckTypeInURL,
  useGetIgnoreRuleForCheckType,
} from "./ignoreChecksDrawerHooks";
import { IgnoreChecksTable } from "./IgnoreChecksTable/IgnoreChecksTable";
import { EmptyResultsMessage } from "./IgnoreChecksTable/EmptyResultsMessage";
import { AddEditRuleDialog } from "./AddEditRuleDialog/AddEditRuleDialog";

const {
  ignoreChecks: { addExceptionButton: addExceptionAriaLabel },
} = reliabilityArialLabels;

const Container = styled.div`
  ${DrawerPadding};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

const StyledLinesLoader = styled(LinesLoader)`
  margin-top: 32px;
`;

export const IgnoreList: React.FC = () => {
  const checkType = useGetCheckTypeInURL();
  const { isFetching, foundRules, error } =
    useGetIgnoreRuleForCheckType(checkType);

  const shouldShowEmptyMessage =
    !isFetching && !error && foundRules.length === 0;

  const setDialogState = useSetAddEditIgnoreRuleDialogState();
  const openDialog = () => setDialogState({ isOpen: true, checkType });

  const { canManageReliability } = useHasPermissions();

  if (!checkType) return <StyledLinesLoader />;

  return (
    <Container>
      <TopSection>
        <Typography variant={"h4"}>Ignore list</Typography>
        <Tooltip
          title={"missing Add exception permissions"}
          placement={"left"}
          disableHoverListener={canManageReliability}
        >
          <span>
            <Button
              variant={"contained"}
              onClick={openDialog}
              aria-label={addExceptionAriaLabel}
              disabled={!canManageReliability}
            >
              Add exception
            </Button>
          </span>
        </Tooltip>
      </TopSection>
      <FlexContainer>
        {isFetching && <StyledLinesLoader />}
        {shouldShowEmptyMessage && <EmptyResultsMessage type={"no data"} />}
        {!!error && <ErrorContainer />}
        {!!foundRules.length && <IgnoreChecksTable ignoreRules={foundRules} />}
      </FlexContainer>

      <AddEditRuleDialog />
    </Container>
  );
};
