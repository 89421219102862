import React, { useMemo } from "react";
import { CheckCircleOutlined24 } from "@komodorio/design-system/icons";

import { EventItem } from "../EventItem/EventItem";
import { CardContentDisplayHandler } from "../CardContentDisplayHandler/CardContentDisplayHandler";
import { useOpenWorkflowIssues } from "../../../../hooks/overviewPageHooks";

export const OpenIssues: React.FC = () => {
  const { eventGroups, fetching } = useOpenWorkflowIssues();

  const content = useMemo(
    () =>
      eventGroups.map((event) => <EventItem event={event} key={event.id} />),
    [eventGroups]
  );

  return (
    <CardContentDisplayHandler
      fetching={fetching}
      isEmptyState={content?.length === 0}
      emptyStateDisplay={{
        icon: <CheckCircleOutlined24 />,
        dataType: "open issues",
      }}
    >
      {content}
    </CardContentDisplayHandler>
  );
};
