import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { HeatmapGraph } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/HeatmapGraph";
import { ColorSchemeLegend } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/ColorSchemeLegend";
import { defaultHeatmapColorScheme } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/defaultHeatmapColorScheme";
import { CpuThrottlingDataPointsPerContainer } from "../../../../../../../../../../../generated/metricsApi";
import { minimalEventIconsHeight } from "../../../../../../../../../../Metrics/constants";

import { useHeatmapGraphProps } from "./useHeatmapGraphProps";
import { HeatmapGraphLoader } from "./HeatmapGraphLoader";

const GRAPH_HEIGHT = 50;

const GraphAreaContainer = styled.div`
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${muiColors.gray[200]};
`;

const HeatmapGraphContainer = styled.div<{ $hasEvents: boolean }>`
  height: ${({ $hasEvents }) =>
    $hasEvents
      ? `${GRAPH_HEIGHT + minimalEventIconsHeight}px`
      : `${GRAPH_HEIGHT}px`};
`;

const BelowGraphContainer = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const tooltipTitle =
  "The percentage (%) of the hour in which the containers were throttled (i.e. the container was throttled 20% of the hour)";

type HeatMapGraphElementsProps = PropsWithChildren<{
  container?: CpuThrottlingDataPointsPerContainer;
}>;

export const HeatMapGraphElements: React.FC<HeatMapGraphElementsProps> = ({
  container,
  children,
}) => {
  const heatMapGraphProps = useHeatmapGraphProps(container?.dataPoints);
  const hasEvents = !!heatMapGraphProps?.events?.length;
  return (
    <GraphAreaContainer>
      <HeatmapGraphContainer $hasEvents={hasEvents}>
        {heatMapGraphProps && <HeatmapGraph {...heatMapGraphProps} />}
        {!heatMapGraphProps && <HeatmapGraphLoader />}
      </HeatmapGraphContainer>
      <BelowGraphContainer>
        <ColorSchemeLegend
          colorScheme={defaultHeatmapColorScheme}
          title={"Time throttled (hourly %)"}
          tooltipTitle={tooltipTitle}
        />
        {children}
      </BelowGraphContainer>
    </GraphAreaContainer>
  );
};
