import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAiApiClient } from "../apiClient";
import {
  SessionGetResponse,
  apiV1SessionsGetUrl,
  DefaultApiApiV1SessionsGetRequest,
  DefaultApiApiV1SessionsIdGetRequest,
  apiV1SessionsIdGetUrl,
} from "../../../../../generated/aiApi";

export const SESSIONS_PATH = "/sessions";

const fetchSession = async (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1SessionsGetRequest
) => {
  const { data } = await apiClient.get<SessionGetResponse>(
    apiV1SessionsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

const fetchSessionById = async (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1SessionsIdGetRequest
) => {
  const { data } = await apiClient.get<SessionGetResponse>(
    apiV1SessionsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const getSessionQuery = (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1SessionsGetRequest
) => {
  return {
    queryKey: [SESSIONS_PATH, params],
    queryFn: () => {
      return fetchSession(apiClient, params);
    },
  };
};

export const getSessionByIdQuery = (
  apiClient: AxiosInstance,
  params: DefaultApiApiV1SessionsIdGetRequest
) => {
  return {
    queryKey: [SESSIONS_PATH, params],
    queryFn: () => {
      return fetchSessionById(apiClient, params);
    },
  };
};

export const useGetLatestSessionForResource = (
  params: DefaultApiApiV1SessionsGetRequest,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
    staleTime?: number;
    cacheTime?: number;
  }
) => {
  const apiClient = useAiApiClient();
  const query = getSessionQuery(apiClient, params);
  return useQuery(query.queryKey, query.queryFn, options);
};

export const useGetSessionById = (
  params: DefaultApiApiV1SessionsIdGetRequest,
  options?: {
    enabled?: boolean;
  }
) => {
  const apiClient = useAiApiClient();
  const query = getSessionByIdQuery(apiClient, params);
  return useQuery(query.queryKey, query.queryFn, options);
};
