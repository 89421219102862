import { SetStateAction } from "react";
import styled from "styled-components";

import { RbacCustomK8sAction, RbacPolicyAction } from "../../../generated/auth";

export const PreservedHeight = styled.div<{ size?: string }>`
  height: ${({ size }) => size ?? "4.75rem"};
`;

export type SetStateFn<T> = React.Dispatch<SetStateAction<T | undefined>>;
export type SetStateFnRequired<T> = React.Dispatch<SetStateAction<T>>;

export type Action = RbacPolicyAction | RbacCustomK8sAction;
