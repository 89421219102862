import React from "react";
import styled from "styled-components";

import { AvailabilityRunWithResults } from "../../types";
import { Container } from "../common/styles";

import { AvailabilityResource } from "@/shared/store/investigationModeStore/investigationModeStore";
import { InvestigationContent } from "@/components/ResourceView/AiInvestigation/common/InvestigationContent";
import { useResourceAiInvestigation } from "@/components/ResourceView/AiInvestigation/common/useResourceAiInvestigation";
import { InvestigationTitle } from "@/components/ResourceView/AiInvestigation/common/InvestigationTitle";
import { ResultTitle } from "@/components/ResourceView/AiInvestigation/pod/styles";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import { EVENTS_ROUTE } from "@/components/routes/routes";

export interface KlaudiaSessionSectionProps {
  service: AvailabilityResource;
  wfRun: AvailabilityRunWithResults;
  sessionId?: string;
}

const Root = styled(Container)`
  gap: 0;
  align-items: unset;
`;

const StyledInvestigationContent = styled(InvestigationContent)`
  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  ${ResultTitle} {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const AiInvestigationSection: React.FC<KlaudiaSessionSectionProps> = ({
  wfRun,
  service,
}) => {
  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.EventDrawerById,
      eventIds: [wfRun.id],
      eventType: MonitorType.Availability,
      serviceId: service.id,
    },
    requestId: wfRun.id,
    resource: service,
    isHealthy: false,
    flowType: "Availability Issue Introduction",
  });

  if (!enabled) {
    return null;
  }

  return (
    <Root>
      <InvestigationTitle
        titleProps={{ variant: "h5" }}
        richSession={richSession}
        onShareClicked={() => onShareClicked(EVENTS_ROUTE)}
      />
      <StyledInvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        showBorder={false}
      />
    </Root>
  );
};
