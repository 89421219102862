import React, { useMemo } from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ResourceType } from "../../../../types/appViewsResourcesTypes";
import { useGetRouteForResourceType } from "../../../../hooks/overviewPageHooks";
import { useAppViewsResourcesStore } from "../../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { gap } from "../../shared/styles";

import { ResourceTypeToName } from "./constants/resourceTypeToName";
import { UnhealthyItems } from "./UnhealthyItems";

type ResourceItemProps = {
  type: ResourceType;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding-right: ${gap};
`;

const StyledLink = styled(Link)<{ $disabled?: boolean }>`
  &:any-link {
    display: flex;
    gap: 5px;
    align-items: center;
    color: ${palette.darkBlue["500"]};
    text-decoration: none;
    ${({ $disabled }) => $disabled && "pointer-events: none;"}
`;

export const ResourceItem: React.FC<ResourceItemProps> = ({ type }) => {
  const foundResources = useAppViewsResourcesStore(
    (state) => state.resources[type]
  );

  const getRouteForResourceType = useGetRouteForResourceType();
  const foundResourcesKeys = Object.keys(foundResources);
  const unhealthyCount = useMemo(
    () =>
      foundResourcesKeys.reduce<number>((acc, currKey) => {
        const currentResource = foundResources[currKey];
        const unhealthy = currentResource.isHealthy === false;
        return acc + (unhealthy ? 1 : 0);
      }, 0),
    [foundResources, foundResourcesKeys]
  );

  const total = foundResourcesKeys.length;
  if (total === 0) return null;

  const hasUnhealthyResources = unhealthyCount > 0;
  const resourceRoute = getRouteForResourceType(type);

  return (
    <Container>
      <Typography variant="text" size="small">
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          to={resourceRoute ?? "/"}
          $disabled={!resourceRoute}
        >{`${total} ${ResourceTypeToName[type]}`}</StyledLink>
      </Typography>
      {hasUnhealthyResources && (
        <UnhealthyItems type={type} count={unhealthyCount} />
      )}
    </Container>
  );
};
