import React from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { useNavigate } from "react-router-dom";

import logo from "../../shared/assets/logo.svg";
import { AriaLabels } from "../../shared/config/ariaLabels";

import { layoutPanelStyle } from "./styles";
import { Steps } from "./Steps";
import { IssueSummary } from "./IssueSummary/IssueSummary";

const Container = styled.div`
  ${layoutPanelStyle};
  display: grid;
  grid-template-rows: max-content max-content auto;
  border-right: 1px solid ${theme.divider};
  row-gap: 1rem;
`;

const Logo = styled.img.attrs({ src: logo, alt: "Komodor" })`
  width: 8rem;
  height: 2rem;
  cursor: pointer;
`;

export const SideBar: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Logo
        onClick={() => navigate("/")}
        aria-label={AriaLabels.InvestigationMode.KomodorLogo}
      />
      <IssueSummary />
      <Steps />
    </Container>
  );
};
