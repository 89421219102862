import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1KomodorServicesPodsMapPostUrl,
  KomodorServicePodMapParams,
  KomodorServicePodsMapResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

export const KOMODOR_SERVICES_PODS_MAP_PATH = "/komodor-services/pods-map";

const podMapPodToService = async (
  apiClient: AxiosInstance,
  params: KomodorServicePodMapParams
): Promise<KomodorServicePodsMapResponse> => {
  const { data } = await apiClient.post(
    apiV1KomodorServicesPodsMapPostUrl(
      { komodorServicePodMapParams: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useMapPodToService = (
  params: KomodorServicePodMapParams,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [KOMODOR_SERVICES_PODS_MAP_PATH, params],
    () => podMapPodToService(apiClient, params),
    {
      enabled,
    }
  );
};
