import { getAppConfig } from "../../../config/appConfig";
import { LocalStorageItem } from "../../localStorageSettings";

const storedDevUserRole = new LocalStorageItem("currentDevUserRole");
const storedDevUserPlan = new LocalStorageItem("currentDevAccountPlan");

export const getStoredDevUserRole = (): string | null => {
  const devUserRole = storedDevUserRole.get();
  if (devUserRole) {
    return devUserRole;
  }
  if (!devUserRole && getAppConfig().env === "development") {
    storedDevUserRole.set("komodor-admin");
    return "komodor-admin";
  }
  return null;
};

export const getStoredDevAccountPlan = (): string | null => {
  const devAccountPlan = storedDevUserPlan.get();
  if (devAccountPlan != null) {
    return devAccountPlan;
  }
  if (!devAccountPlan && getAppConfig().env === "development") {
    storedDevUserPlan.set("");
    return "";
  }
  return null;
};
