import { Filters } from "komodor-types";
import { useCallback, useState } from "react";

import { useFetchServicesFiltersMutation } from "../../../generated/graphql";
import { FilterBarCategory } from "../../../shared/components/FilterBar/FilterBar";

import {
  calcServicesLength,
  CheckedFilters,
  createFilterBarCategories,
} from "./utils";

const useServicesFilters = (): {
  categories: FilterBarCategory[] | undefined;
  servicesLength: number;
  fetching: boolean;
  updateFilters: (
    checkedFilters: CheckedFilters,
    textFilter: string | null
  ) => Promise<void>;
} => {
  const [{ fetching }, fetchServicesFilters] =
    useFetchServicesFiltersMutation();
  const [categories, setCategories] = useState<FilterBarCategory[]>();
  const [servicesLength, setServicesLength] = useState(0);

  const updateFilters = useCallback(
    async (checkedFilters: CheckedFilters, textFilter: string | null) => {
      const { data } = await fetchServicesFilters({
        checkedFilters,
        textFilter: textFilter ?? "",
      });
      const filters = data?.fetchServicesFilters?.filters as Filters;
      setCategories(createFilterBarCategories(filters, checkedFilters));
      setServicesLength(calcServicesLength(filters, checkedFilters));
    },
    [fetchServicesFilters, setServicesLength]
  );

  return {
    categories,
    servicesLength,
    fetching,
    updateFilters,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useServicesFilters;
