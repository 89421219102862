import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";
import { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ActionEvent } from "./ActionsEventGroup";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const usePreparedEvents = (
  events: ActionEvent[]
): {
  eventTime: Date;
  status: string;
  details: string;
}[] =>
  useMemo(
    () =>
      events.map((e) => ({
        eventTime: e.eventTime,
        status: e.status,
        details: e.message,
      })),
    [events]
  );

export const SubTitle = styled(Typography).attrs({
  variant: "title",
  size: "large",
})`
  margin-block-start: 0.75rem;
`;

export const CommandText = styled(Typography).attrs({
  size: "small",
  mono: true,
})`
  background: #f4f7fa;
  border: 1px solid #dcdddf;
  border-radius: 0.25rem;
  padding: 0.5rem;
  display: flex;
  line-height: 25px;
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  justify-content: start;
  gap: 0.75rem;
  > :nth-child(2n + 2) {
    font-weight: bold;
  }
`;
