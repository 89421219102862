import { AllocationCostDataPoints } from "../../../../generated/metricsApi";
import { AllocationFiltersTimeWindow } from "../../types/costOptimizationTypes";

import { getRandRange } from "./getRandRange";

const NUMBER_OF_DIFFERENT_RESOURCES = 9;

const clusterNames = Array.from(
  { length: NUMBER_OF_DIFFERENT_RESOURCES },
  (_, i) => `cluster-${i}`
);

const namespacesNames = Array.from(
  { length: NUMBER_OF_DIFFERENT_RESOURCES },
  (_, i) => `namespace-${i}`
);

const servicesNames = Array.from(
  { length: NUMBER_OF_DIFFERENT_RESOURCES },
  (_, i) => `service-${i}`
);

const initResponseSkeleton = (timeScope = "d", numberOfElements: number) => {
  const secondsInDay = 86_400;
  const isDayScope = timeScope === "h";
  const startDate = new Date(
    Date.now() -
      (isDayScope
        ? secondsInDay * 1000
        : numberOfElements * secondsInDay * 1000)
  );

  const jumpSecondes = isDayScope ? 3_600 : secondsInDay;
  const res: { [key: string]: { [key: string]: number } } = {};

  let timeKey = Math.round(startDate.getTime() / 1000);
  for (let i = 0; i < numberOfElements; i++) {
    const key = String(timeKey);
    res[key] = { a: 4 };
    timeKey += jumpSecondes;
  }

  return res;
};

const getKeys = (groupBy: string) => {
  const arrayOfKeys = [];
  for (let i = 0; i < NUMBER_OF_DIFFERENT_RESOURCES; i++) {
    const namespaceAddition =
      groupBy !== "clusterName" ? `:${namespacesNames[i]}` : "";

    const serviceAddition =
      groupBy === "komodorServiceName" ? `:${servicesNames[i]}` : "";

    arrayOfKeys.push(
      `${clusterNames[i]}${namespaceAddition}${serviceAddition}`
    );
  }
  arrayOfKeys.push("Others");
  return arrayOfKeys;
};

export const generateMockCostAllocationMetrics = (
  timeWindow: AllocationFiltersTimeWindow,
  groupBy = "clusterName"
): AllocationCostDataPoints => {
  const timeScope = timeWindow.match(/[a-z]+/gi)?.join("");
  const numberOfElements = Number(timeWindow.match(/\d+/g)?.join(""));
  const respone = initResponseSkeleton(timeScope, numberOfElements);
  const keys = getKeys(groupBy);

  for (const property in respone) {
    respone[property] = keys.reduce(
      (acc, value) => ({ ...acc, [value]: getRandRange(1, 10) }),
      {}
    );
  }

  return { datapoints: respone };
};
