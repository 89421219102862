import React from "react";
import styled from "styled-components";
import yaml from "js-yaml";

import { KubernetesServiceTypeEnum } from "../../../../../shared/types/ServiceVertex";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeployEvent } from "../../../EventGroup/deployEvent/DeployEventGroup";
import EventDetailsSection from "../components/EventDetailsSection";
import strategyTitleIcon from "../../assets/strategy-title.svg";
import blueGreenIcon from "../../assets/bluegreen.svg";
import canaryIcon from "../../assets/canary.svg";
import recreateIcon from "../../assets/recreate.svg";
import rollingUpdateIcon from "../../assets/rolling-update.svg";
import {
  blackGreyText,
  cyan,
  grayBorder,
  lightBlue,
  lightOrange,
  lightPurple,
} from "../../../../../Colors";
import { Text } from "../../../../common/typography";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum StrategyType {
  BlueGreen,
  Canary,
  Recreate,
  RollingUpdate,
}

const PREVIEW_LIMIT_LINES = 6;

export const StrategyTypes = new Map([
  [
    StrategyType.BlueGreen,
    {
      icon: blueGreenIcon,
      name: "BlueGreen",
      color: lightBlue,
    },
  ],
  [
    StrategyType.Canary,
    {
      icon: canaryIcon,
      name: "Canary",
      color: lightOrange,
    },
  ],
  [
    StrategyType.Recreate,
    {
      icon: recreateIcon,
      name: "Recreate",
      color: cyan,
    },
  ],
  [
    StrategyType.RollingUpdate,
    {
      icon: rollingUpdateIcon,
      name: "RollingUpdate",
      color: lightPurple,
    },
  ],
]);

export const StrategyContainer = styled.div<{
  marginTop?: string;
  backgroundColor: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${blackGreyText};
  border-radius: 0.25rem;
  height: 1.4rem;
  width: fit-content;
  margin-top: ${({ marginTop }) => marginTop || ""};
  padding: 0 0.3rem;
`;

export const StrategyTypeIcon = styled.div<{ icon: string }>`
  margin-right: 0.2rem;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-size: 0.75rem 0.75rem;
  background-position: center;
  height: 1rem;
  width: 1rem;
`;

export const StrategyTypeText = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const StrategyYamlContainer = styled.div<{ showMore?: boolean }>`
  width: fit-content;
  overflow-wrap: break-word;
  overflow: hidden;
  max-height: ${({ showMore: longText }) => (longText ? "9rem" : "4.9rem")};
`;

const ExtraPadding = styled.div`
  padding: 0.5rem;
  border: 1px solid ${grayBorder};
  border-radius: 0.25rem;
  margin-top: 0.5rem;
`;

const StrategyText = styled(Text)`
  word-break: break-all;
  font-family: "Roboto Mono";
  font-size: 12px;
  line-height: 16px;
`;

const StyledPre = styled.pre`
  margin-top: 0.1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
`;

interface StrategySectionProps {
  event: DeployEvent;
  kubernetesKind: string;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getStrategyType = (
  lines: string[],
  kubernetesKind: string
): StrategyType | null => {
  const strategyTypes = new Map([
    ["BlueGreen", StrategyType.BlueGreen],
    ["Canary", StrategyType.Canary],
    ["Recreate", StrategyType.Recreate],
    ["RollingUpdate", StrategyType.RollingUpdate],
  ]);

  const currentStrategyType =
    kubernetesKind === KubernetesServiceTypeEnum.Rollout
      ? (lines[0].charAt(0).toUpperCase() + lines[0].slice(1)).split(":")[0]
      : lines[1].split(":")[0];

  return strategyTypes.get(currentStrategyType) ?? null;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getStrategyIcon = (strategyType: StrategyType): string => {
  return StrategyTypes.get(strategyType)?.icon ?? "";
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getStrategyColor = (strategyType: StrategyType): string => {
  return StrategyTypes.get(strategyType)?.color ?? "";
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getStrategyName = (strategyType: StrategyType): string => {
  return StrategyTypes.get(strategyType)?.name ?? "";
};

const StrategySection: React.FC<StrategySectionProps> = ({
  event,
  kubernetesKind,
}) => {
  const yamlText = yaml.dump(event.strategy);
  const lines = yamlText.split(/\r\n|\r|\n/);
  const strategyType = getStrategyType(lines, kubernetesKind);

  if (strategyType === null) {
    return null;
  }

  return (
    <>
      <EventDetailsSection
        icon={strategyTitleIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Strategy"
      >
        <StrategyContainer backgroundColor={getStrategyColor(strategyType)}>
          <StrategyTypeIcon icon={getStrategyIcon(strategyType)} />
          <StrategyTypeText>{getStrategyName(strategyType)}</StrategyTypeText>
        </StrategyContainer>
        <ExtraPadding>
          <StrategyYamlContainer showMore={lines.length <= PREVIEW_LIMIT_LINES}>
            <StrategyText>
              <StyledPre>{yamlText}</StyledPre>
            </StrategyText>
          </StrategyYamlContainer>
        </ExtraPadding>
      </EventDetailsSection>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StrategySection;
