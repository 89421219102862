import React, { useCallback, useState } from "react";

import { useResizeObserver } from "../../../../shared/hooks/useResizeObserver";

export const useOnContainerResizeWidth = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const [containerWidth, setContentWidth] = useState<number>(0);

  const onContainerResize = useCallback(() => {
    if (!containerRef.current) return;
    setContentWidth((containerRef.current.clientWidth || 0) - 5);
  }, [containerRef]);

  useResizeObserver(containerRef, onContainerResize);

  return containerWidth;
};
