import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { palette } from "@komodorio/design-system";
import { Lock16 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";

import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { ButtonLinkStyle } from "../internal/styles";
import { FeatureTag, TagText } from "../../../shared/components/FeatureTag";
import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";

const NO_PERMISSIONS_MESSAGE = "Access Denied due to lack of permission";

const StyledNavLink = styled(NavLink)<{
  $indentationLevel?: 0 | 1 | 2;
  $isDisabled?: boolean;
}>`
  font-family: Roboto, serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  && {
    ${({ $isDisabled }) =>
      $isDisabled &&
      `
      cursor: default;
      pointer-events: none;
      color: ${palette.gray[300]};
    `}
  }

  &.active {
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.08);
    border-left: 0.25rem solid ${palette.darkBlue[500]};
    padding-left: 1.25rem;
    ${({ $indentationLevel }) =>
      $indentationLevel === 1
        ? `
      margin-left: -1rem;
      padding-left: 2.25rem;
    `
        : $indentationLevel === 2
        ? `
      margin-left: -3rem;
      padding-left: 4.25rem;
    `
        : ""}
  }
  ${ButtonLinkStyle}
`;

const RightIcon = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

interface AppBarLinkProps {
  text: string;
  icon?: JSX.Element;
  route?: string;
  indentationLevel?: 0 | 1 | 2;
  tag?: TagText;
  end?: boolean;
  locked?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
}

const SLOTS = {
  popper: {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -24],
        },
      },
    ],
  },
};

export const AppBarLink: React.FC<AppBarLinkProps> = ({
  text,
  icon,
  route = "",
  indentationLevel,
  tag,
  locked = false,
  disabled = locked,
  end = false,
  ariaLabel,
}) => {
  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={
        locked ? (
          <Typography variant="body2">{NO_PERMISSIONS_MESSAGE}</Typography>
        ) : undefined
      }
      placement="right"
      slotProps={SLOTS}
    >
      <div>
        <StyledNavLink
          $indentationLevel={indentationLevel}
          $isDisabled={disabled}
          to={route}
          onClick={() => {
            dispatchEvent(AnalyticEvents.AppBar.AppBar_Item_Clicked, {
              name: text,
              route: route,
            });
          }}
          end={end}
          aria-label={ariaLabel}
        >
          {icon}
          {text}
          {tag && <FeatureTag tag={tag} />}
          {locked && (
            <RightIcon aria-label={AnalyticEvents.AppBar.AppBar_Locked}>
              <Lock16 />
            </RightIcon>
          )}
        </StyledNavLink>
      </div>
    </Tooltip>
  );
};
