import {
  SinglePointOfFailureSupportingDataCorrelatedViolationsInner,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";

import { checkTypeToDescription } from "@/components/reliability/constants/checkTypeToDescription";

const getRootCause = (
  correlatedViolations: SinglePointOfFailureSupportingDataCorrelatedViolationsInner[]
) => {
  return correlatedViolations
    .map((v) => checkTypeToDescription[v.type])
    .join(" | ");
};

export const getViolationsTableTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const baseTitle = "Single Point of Failure";
  if (!supportingData?.singlePointOfFailure?.correlatedViolations) {
    return baseTitle;
  }

  const correlatedViolations =
    supportingData.singlePointOfFailure.correlatedViolations;

  if (correlatedViolations.length === 0) {
    return baseTitle;
  }

  return `${baseTitle} - ${getRootCause(correlatedViolations)}`;
};
