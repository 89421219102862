export const description = `Unlock full visibility into node autoscaling within Komodor. Easily track, manage, and troubleshoot autoscaler actions to ensure your infrastructure scales optimally, enhancing both cost efficiency and performance across clusters.`;
export const includedFeatures = [
  "Automatic detection of Cluster Autoscaler &  Karpenter",
  "Ensure high reliability with detailed insights into scaling activities and their impact on your application, correlated with Kubernetes issues for improved troubleshooting.",
  "Unlock new optimization opportunities - identify underutilized nodes, inefficient scaling configurations, and opportunities to reduce infrastructure costs.",
  "Analyze your autoscaler events & configuration for streamlined cross-cluster management and improved decision-making.",
];

export const ariaLabels = {
  container: "Node Autoscalers coming soon",
};
