import { useCallback, useEffect, useRef, useState } from "react";

import { useIsTabVisible } from "../../../shared/hooks/useIsTabVisible";

const MAX_STALE_TIME = 30 * 60 * 1000; // 30 minutes

export const useIsStaleResults = () => {
  const tabIsVisible = useIsTabVisible();
  const timeElapsedSinceLastRequest = useRef<Date | undefined>();
  const [isStale, setIsStale] = useState(false);

  const reset = useCallback(() => {
    setIsStale(false);
    timeElapsedSinceLastRequest.current = undefined;
  }, []);

  useEffect(() => {
    if (!tabIsVisible) {
      timeElapsedSinceLastRequest.current = new Date();
    } else if (timeElapsedSinceLastRequest.current) {
      const endTime = new Date();
      const elapsed =
        endTime.getTime() - timeElapsedSinceLastRequest.current.getTime();
      if (elapsed > MAX_STALE_TIME) {
        setIsStale(true);
      }
    }
  }, [tabIsVisible]);

  return { isStale, reset };
};
