import React from "react";
import styled from "styled-components";

import { Change } from "../../../EventGroup/deployEvent/getK8sDiff";
import { greenForUI, pinkForUI } from "../../../../../Colors";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  align-items: center;
  margin: 0 -0.25rem -0.25rem 0;

  > * {
    margin: 0 0.25rem 0.25rem 0;
  }

  &:nth-child(n + 2) {
    margin-block-start: 0.5rem;
  }
`;

const Label = styled.div`
  padding: 0.125rem 0.25rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const From = styled(Label)`
  background-color: ${pinkForUI};
  text-decoration-line: line-through;
`;

const To = styled(Label)`
  background-color: ${greenForUI};
`;

interface DiffEntryProps {
  change: Change;
}

const DiffEntry: React.FC<DiffEntryProps> = ({
  change: { name, previous, current },
}) => {
  return (
    <Container>
      <div>{name}</div>
      {previous && <From title={previous}>{previous}</From>}
      {current && <To title={current}>{current}</To>}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DiffEntry;
