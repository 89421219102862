import axios from "axios";
import { useCallback, useRef } from "react";

import { Dictionary } from "../../../shared/types/Dictionary";
import { notifyDDError } from "../../../shared/hooks/exceptionManagement";

export const useReportAxiosError = () => {
  const errorsRef = useRef<Dictionary<boolean>>({});

  return useCallback((error: unknown) => {
    if (axios.isAxiosError(error) && error.config?.url) {
      const requestKey = `${error.config.method}-${error.config.url}-${error.config.data}`;
      if (!errorsRef.current[requestKey]) {
        errorsRef.current[requestKey] = true;
        notifyDDError(error as Error);
      }
    }
  }, []);
};
