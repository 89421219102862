import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { formatNumber } from "../../../../../../shared/utils/formatNumber";
import { useReliabilityStore } from "../../../../store/reliabilityStore";
import { selectViolationsState } from "../../../../store/reliabilityStoreSelectors";
import { useGroups } from "../../../../hooks/reliabilityHooks";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { getGroupsTotalViolationsTexts } from "../../../../utils/reliabilityUtils";
import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";

import { useGetTotalViolationsFromGroups } from "./ViolationsGroup/groupsHooks";

import { useSelectedTab } from "@/components/reliability/hooks/useSelectedTab";

const { totalResults } = reliabilityArialLabels;

const GroupsTotalViolations: React.FC = () => {
  const { data } = useGroups();
  const groupBy = useGetGroupBy();
  const selectedTab = useSelectedTab();
  const { totalViolations, totalGroups } = useGetTotalViolationsFromGroups(
    data?.data?.groups
  );

  const { groupText, suffixText } = useMemo(
    () => getGroupsTotalViolationsTexts({ groupBy, selectedTab }),
    [groupBy, selectedTab]
  );

  if (totalGroups === 0 || !groupText) return null;

  return (
    <Typography variant={"body2"}>
      Showing {""}
      {totalGroups && (
        <>
          <b>{totalGroups}</b> {groupText} with{" "}
        </>
      )}
      <b>{formatNumber(totalViolations)}</b> {suffixText}
    </Typography>
  );
};

const UngroupedTotalViolations: React.FC = () => {
  const { ungroupedTotalViolationsCount } = useReliabilityStore(
    selectViolationsState
  );
  const selectedTab = useSelectedTab();

  const { suffixText } = useMemo(
    () => getGroupsTotalViolationsTexts({ selectedTab }),
    [selectedTab]
  );

  if (ungroupedTotalViolationsCount === undefined) return null;

  return (
    <Typography variant={"body2"}>
      Showing {""}
      <b>{formatNumber(ungroupedTotalViolationsCount)}</b> {suffixText}
    </Typography>
  );
};

export const TotalViolations: React.FC = () => {
  const groupBy = useGetGroupBy();

  return (
    <div aria-label={totalResults}>
      {groupBy === "None" ? (
        <UngroupedTotalViolations />
      ) : (
        <GroupsTotalViolations />
      )}
    </div>
  );
};
