import { ViolationsAggregationGroup } from "../../../../../../../generated/reliabilityApi";
import { getTotalViolationsFromCountDict } from "../../../../../utils/reliabilityUtils";

export const useGetTotalViolationsFromGroups = (
  groups?: ViolationsAggregationGroup[]
) => {
  if (!groups) return { totalGroups: 0, totalViolations: 0 };

  const totalGroups = groups.length;
  const totalViolations = groups.reduce(
    (acc, curr) =>
      acc +
      (curr?.breakdowns
        ? getTotalViolationsFromCountDict(Object.values(curr?.breakdowns)[0])
        : 0),
    0
  );

  return { totalGroups, totalViolations };
};
