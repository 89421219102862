import React, { useState } from "react";
import Button from "@mui/material/Button";

import { AriaLabels } from "@/shared/config/ariaLabels";
import UpgradeRequired, {
  UpgradeRequiredModalStep,
} from "@/components/common/UpdateAgentModal/upgradeRequired";
import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";
import { UpgradeCommands } from "@/components/common/upgradeAgentCta/UpgradeAgentCTA";

export const UpgradeCommandButton: React.FC = () => {
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.NEW_UPGRADE_COMMAND
  );
  const desiredFeature = "kubernetes metadata";
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        aria-label={AriaLabels.ClusterOverview.Header.UpgradeAgentButton}
        onClick={() => setOpen(true)}
      >
        Upgrade Agent
      </Button>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={null}
          upgradeCommand={command}
          desiredFeature={desiredFeature}
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </>
  );
};
