import React, { useMemo } from "react";
import styled from "styled-components";

import { InsightDrawerActionButton } from "../../shared/styles";
import { CostSavingColumns } from "../../shared/types";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import {
  selectAppInsightsData,
  selectSetRightSizingRecommendationModal,
} from "../../../store/overviewPageStoreSelectors";
import { overviewPageInsightsAriaLabels } from "../../../../../appViewAriaLabels";
import { formatCurrencyIfSmallerThanOne } from "../../../../../../../../../shared/utils/formatCurrency";

const {
  potentialCostSaving: { openRecommendation: openRecommendationAriaLabel },
} = overviewPageInsightsAriaLabels;

type PotentialSavingButtonProps = {
  rowData: CostSavingColumns;
};

const StyledButton = styled(InsightDrawerActionButton)`
  width: 100%;
`;

export const PotentialSavingButton: React.FC<PotentialSavingButtonProps> = ({
  rowData,
}) => {
  const openRecommendationModal = useOverviewPageStore(
    selectSetRightSizingRecommendationModal
  );
  const {
    potentialCostSavings: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const rowId = useMemo(() => {
    const row = data?.rows?.find((row) => row.komodorServiceId === rowData.id);

    if (!row) {
      return undefined;
    }

    return row.id;
  }, [data?.rows, rowData.id]);

  return (
    <StyledButton
      onClick={() => {
        openRecommendationModal({ rowId });
      }}
      aria-label={openRecommendationAriaLabel}
    >
      Save {formatCurrencyIfSmallerThanOne(rowData.potentialSavings)}
    </StyledButton>
  );
};
