import React from "react";

import { AddEditRuleContextProvider } from "./context/AddEditRuleContext";
import { AddEditRuleDialogContent } from "./AddEditRuleDialogContent";

export const AddEditRuleDialog: React.FC = () => {
  return (
    <AddEditRuleContextProvider>
      <AddEditRuleDialogContent />
    </AddEditRuleContextProvider>
  );
};
