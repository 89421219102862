import React from "react";
import styled from "styled-components";

import { INSPECTION_LABELS_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import StyledMultiSelect from "../../monitorsView/common/StyledMultiSelect";

import {
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "./InspectionResourceListSearch";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const MultiSelectContainerInspection = styled.div`
  background-color: white;
  font-size: 12px;
`;

const Container = styled(InspectionFilterContainer)`
  flex: 1 1 auto;
`;

const InspectionResourceListLabelsFilter: React.FC<{
  items: string[];
}> = ({ items }) => {
  const [selectedLabels, setSelectedLabels] = useStringifiedStateInSearchParams<
    string[]
  >(INSPECTION_LABELS_PARAM_KEY);

  return (
    <Container>
      <InspectionFilterDiv>Labels</InspectionFilterDiv>
      <MultiSelectContainerInspection>
        <StyledMultiSelect
          placeholder="Select labels"
          width={"100%"}
          options={items}
          values={selectedLabels ?? []}
          onChange={(labels) =>
            setSelectedLabels(labels.length ? labels : null)
          }
          isEnabled={true}
        />
      </MultiSelectContainerInspection>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionResourceListLabelsFilter;
