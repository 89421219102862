import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { PageRoutes } from "../ReliabilityTypes";
import { ReliabilityRoutes } from "../constants/reliabilityConstants";
import { getIsBestPracticesTab } from "../utils/reliabilityUtils";

import { ImpactGroupType } from "@/generated/reliabilityApi";

export const useSelectedTab = () => {
  const location = useLocation();

  const isRouteSelected = useCallback(
    (route: PageRoutes) => location.pathname.includes(route),
    [location.pathname]
  );

  const tabValue = useMemo<PageRoutes>(() => {
    if (isRouteSelected(ReliabilityRoutes.policies))
      return ReliabilityRoutes.policies;
    if (isRouteSelected(ReliabilityRoutes.checks))
      return ReliabilityRoutes.checks;
    if (isRouteSelected(ReliabilityRoutes.bestPractices))
      return ReliabilityRoutes.bestPractices;
    return ReliabilityRoutes.violations;
  }, [isRouteSelected]);

  return tabValue;
};

export const useIsBestPracticesTab = (): boolean => {
  const selectedTab = useSelectedTab();
  return getIsBestPracticesTab(selectedTab);
};

export const useGetImpactGroupType = (
  overrideImpactGroupType?: ImpactGroupType
): ImpactGroupType => {
  const selectedTab = useSelectedTab();

  if (overrideImpactGroupType) return overrideImpactGroupType;

  return selectedTab === "violations"
    ? ImpactGroupType.Dynamic
    : ImpactGroupType.Static;
};
