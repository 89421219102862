import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

export const HeatmapGraphLoader: React.FC = () => {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height="20px" />
      <FlexContainer>
        <Skeleton variant="rectangular" width="100px" height="20px" />
        <Skeleton variant="rectangular" width="100px" height="20px" />
      </FlexContainer>
    </>
  );
};
