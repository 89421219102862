import { PodLogLine } from "../types";

export type PodLogsStoreState = {
  logs: PodLogLine[];
  direction: PodLogsDirection;
};

export enum PodLogsDirection {
  NewestFirst = "newestFirst",
  EarliestFirst = "earliestFirst",
}

export type PodLogsStoreActions = {
  resetLogs: () => void;
  addLogs: (logs: PodLogLine[]) => void;
  setDirection: (direction: PodLogsDirection) => void;
};

export type PodLogsStore = PodLogsStoreState & PodLogsStoreActions;
