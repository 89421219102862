import React from "react";

import { blueForUIText, gray9 } from "../../../Colors";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const cleanPercentage = (percentage: number): number => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

interface CircleInterface {
  color: string;
  percentage: number;
  radius?: number;
}

export const Circle = (props: CircleInterface): JSX.Element => {
  const r = props.radius ?? 80;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - props.percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? props.color : ""} // remove color as 0% sets full circumference
      strokeWidth={"0.9rem"}
      strokeLinecap={"round"}
      strokeDasharray={circ}
      strokeDashoffset={props.percentage ? strokePct : 0}
      dominantBaseline={"central"}
    ></circle>
  );
};

interface MiddleTextProps {
  text: string;
  size?: string;
}
export const MiddleText = (props: MiddleTextProps): JSX.Element => {
  return (
    <text
      x={"53%"}
      y={"51%"}
      strokeWidth={"10rem"}
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={props.size ?? "1.7rem"}
    >
      {props.text}
    </text>
  );
};

interface CircleLoaderProps {
  percentage: number;
}

const CircleLoader: React.FC<CircleLoaderProps> = ({ percentage }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={200} height={200}>
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle color={gray9} percentage={100} />
        <Circle color={blueForUIText} percentage={pct} />
      </g>
      <MiddleText text={percentage.toString() + "%"} />
    </svg>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CircleLoader;
