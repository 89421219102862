import { useCallback, useMemo } from "react";

import { SEARCH_TYPE_FILTER_OPERATOR } from "../filterSectionConstants";

import { separateFilterTypes } from "./filterStateHadlerUtils";

import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useOnSearchTerm = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();
  const filtersData = useMemo(
    () => dataInUrl.filters || {},
    [dataInUrl.filters]
  );

  return useCallback(
    (filterCategory: string, searchTerm: string) => {
      const { filters: currentFiltersWithNoSearch } = separateFilterTypes(
        filtersData[filterCategory] || []
      );
      setDataInUrl({
        filters: {
          ...filtersData,
          [filterCategory]: [
            ...currentFiltersWithNoSearch,
            ...(searchTerm.length
              ? [
                  {
                    operator: SEARCH_TYPE_FILTER_OPERATOR,
                    values: [searchTerm],
                  },
                ]
              : []),
          ],
        },
      });
    },
    [filtersData, setDataInUrl]
  );
};
