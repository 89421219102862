const defaultOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

// use this for displaying numbers in a compact format
// e.g. 1.2k 2.5M 3.4B etc.
export const compactOptions: Intl.NumberFormatOptions = {
  notation: "compact",
  maximumFractionDigits: 1,
};

export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
  }).format(value);
};

export const getTwoDecimalsNumber = (value: number): number =>
  Math.round(100 * value) / 100;
