import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const TinyTypography = styled(Typography)`
  && {
    font-size: 8px;
  }
`;

export const DotSeparator: React.FC = () => {
  return (
    <TinyTypography variant={"body3"} color={"text.secondary"}>
      {"\u25Cf"}
    </TinyTypography>
  );
};
