import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import {
  ClustersSummaryBody,
  ClustersSummaryResponse,
  apiV1ClustersSummarySearchPostUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspaceQueryKey } from "../useWorkspaceQueryKey";

export const CLUSTERS_PATH = "/clusters-summary/search";

const fetchClusters = async (
  apiClient: AxiosInstance,
  body?: ClustersSummaryBody
) => {
  const { data } = await apiClient.post<ClustersSummaryResponse>(
    apiV1ClustersSummarySearchPostUrl(
      { clustersSummaryBody: body ?? {} },
      apiClient.defaults.baseURL ?? ""
    ),
    body ?? {}
  );

  return data;
};

export const useGetClusters = (
  body?: ClustersSummaryBody,
  options?: {
    enabled?: boolean;
    staleTime?: number;
    keepPreviousData?: boolean;
  }
) => {
  const apiClient = useWorkspacesApiClient();
  const keys = useWorkspaceQueryKey([CLUSTERS_PATH, body]);
  return useQuery(keys, () => fetchClusters(apiClient, body), options);
};
