import React from "react";
import styled from "styled-components";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInfoOutlined = styled(InfoOutlined)`
  margin-right: 5px;
`;

export const AgentUpdatePrompt: React.FC = () => {
  return (
    <Container>
      <StyledInfoOutlined color={"action"} />
      <Typography variant={"body2"} color={"text.secondary"}>
        Agent update is required
      </Typography>
    </Container>
  );
};
