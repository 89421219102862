import { IntegrationType } from "komodor-types";
import { useCallback } from "react";

import { Installation } from "../../../../generated/accounts";
import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";

type Result = Promise<Installation>;
type CreateGrafanaInstallation = (id: string, webhookPath: string) => Result;

export const useGrafanaInstallation = (): CreateGrafanaInstallation => {
  const { mutateAsync: install } = useCreateInstallation();
  return useCallback(
    (id, webhookPath) => {
      const configuration = { webhookPath };
      const integration = IntegrationType.GRAFANA_ALERTS;
      return install({ id, integration, configuration });
    },
    [install]
  );
};
