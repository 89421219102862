interface DockerImageNameParts {
  domain: string;
  path: string;
  tag: string;
  digest: string;
}

const regexp = /(?:([^/]+:\d+|[^/]+\.[^/]+)\/)?([^:@]+)(?::([^@]+))?(?:@(.+))?/;
const parseDockerImageName = (fullImageName: string): DockerImageNameParts => {
  const result = regexp.exec(fullImageName);
  const [, domain = "", path = "", tag = "", digest = ""] = result ?? [];
  return { domain, path, tag, digest };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default parseDockerImageName;
