/* eslint-disable max-lines */
import { useMemo } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { compact } from "lodash";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { WorkflowDag, WorkflowRun } from "@/generated/addonsApi";
import { getRandRange } from "@/shared/utils/getRandRange";
import { DateCell } from "@/components/k8sAddons/components/table/DateCell";
import { WorkflowStatus } from "@/components/k8sAddons/addons/workflows/WorkflowStatus";
import { DurationCell } from "@/components/k8sAddons/components/table/DurationCell";
import { WorkflowEngineIcon } from "@/components/k8sAddons/addons/workflows/WorkflowEngineIcon";
import { disableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { WorkflowEngineType } from "@/components/k8sAddons/addons/workflows/types";

export const columnsConfig = disableLocalSortingInTableColumns<WorkflowDag>({
  name: {
    field: "dagId",
    headerName: "Name",
    flex: 1,
  },
  workflowEngine: {
    field: "engine",
    headerName: "Workflow Engine",
    flex: 0.75,
  },
  cluster: {
    field: "clusterName",
    headerName: "Cluster",
    flex: 0.75,
  },
  namespace: {
    field: "namespace",
    headerName: "Namespace",
    flex: 0.75,
  },
  runs: {
    field: "runs",
    headerName: "Runs",
    flex: 0.5,
    width: 100,
  },
  started: {
    field: "lastRunStartedAt",
    headerName: "Started",
    flex: 1,
  },
  duration: {
    field: "lastRunDurationSec",
    headerName: "Duration",
    flex: 0.5,
  },
  issues: {
    field: "lastRunHasIssues",
    headerName: "Issues",
    flex: 0.5,
    width: 100,
  },
  status: {
    field: "lastRunStatus",
    headerName: "Status",
    flex: 0.5,
  },
});

export const useDagsTableColumns = ({
  isCustomEngines,
}: {
  isCustomEngines: boolean;
}): GridColDef<WorkflowDag>[] => {
  return useMemo(() => {
    return compact([
      columnsConfig.name,
      isCustomEngines
        ? {
            ...columnsConfig.workflowEngine,
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "8px",
                    textTransform: "capitalize",
                  }}
                >
                  <WorkflowEngineIcon
                    engineType={params.row.workflowEngine as WorkflowEngineType}
                  />
                  {params.row.workflowEngine}
                </Box>
              );
            },
          }
        : null,
      columnsConfig.cluster,
      columnsConfig.namespace,
      columnsConfig.runs,
      {
        ...columnsConfig.started,
        renderCell: (params) => {
          return (
            <DateCell
              dateString={params.row.lastRun.startedAt}
              overrideOptions={{ timeZoneName: undefined }}
            />
          );
        },
      },
      {
        ...columnsConfig.duration,
        renderCell: (params) => {
          return (
            <DurationCell durationSeconds={params.row.lastRun.durationSec} />
          );
        },
      },
      {
        ...columnsConfig.issues,
        renderCell: (params) => {
          return params.row.lastRun.hasIssues ? (
            <WarningAmberOutlined sx={{ fill: muiColors.pink[700] }} />
          ) : null;
        },
      },
      {
        ...columnsConfig.status,
        renderHeader: (params) => (
          <Box display="flex" columnGap="4px" alignItems="center">
            {params.colDef.headerName}
            <LightTooltip
              slotProps={{
                popper: { sx: { maxWidth: "160px" } },
              }}
              placement="top"
              title="The status of the workflow updates every 10 minutes."
            >
              <InfoOutlined fontSize="small" color="action" />
            </LightTooltip>
          </Box>
        ),
        renderCell: (params) => {
          return (
            <WorkflowStatus status={params.row.lastRun.status} size="small" />
          );
        },
      },
    ]);
  }, [isCustomEngines]);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<
  Record<keyof WorkflowDag | keyof WorkflowRun, number[]>
> = {
  dagId: [getRandRange(50, 150), getRandRange(50, 150), getRandRange(50, 150)],
  workflowEngine: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  clusterName: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  namespace: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  runs: [getRandRange(50, 150), getRandRange(50, 150), getRandRange(50, 150)],
  startedAt: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  durationSec: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  hasIssues: [
    getRandRange(50, 150),
    getRandRange(50, 150),
    getRandRange(50, 150),
  ],
  status: [getRandRange(50, 150), getRandRange(50, 150), getRandRange(50, 150)],
};

const getColumnRandWidth = (
  id: GridRowId,
  columnName: keyof WorkflowDag | keyof WorkflowRun
) => {
  const idAsNumber = parseInt(id.toString());
  const column = randRangeColumns[columnName];
  if (!column) return 0;
  return column[idAsNumber % column.length];
};

export const useDagsLoadingColumns = ({
  isCustomEngines,
}: {
  isCustomEngines: boolean;
}): GridColDef<WorkflowDag>[] => {
  return useMemo(
    () =>
      compact([
        {
          ...columnsConfig.name,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "dagId")}
                height={21}
              />
            );
          },
        },
        isCustomEngines
          ? {
              ...columnsConfig.workflowEngine,
              renderCell: ({ id }) => (
                <Skeleton
                  variant={"rounded"}
                  width={getColumnRandWidth(id, "workflowEngine")}
                  height={21}
                />
              ),
            }
          : null,
        {
          ...columnsConfig.cluster,
          renderCell: ({ id }) => {
            return (
              <Skeleton
                variant={"rounded"}
                width={getColumnRandWidth(id, "clusterName")}
                height={21}
              />
            );
          },
        },
        {
          ...columnsConfig.namespace,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "namespace")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.runs,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "runs")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.started,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "startedAt")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.duration,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "durationSec")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.issues,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "hasIssues")}
              height={21}
            />
          ),
        },
        {
          ...columnsConfig.status,
          renderCell: ({ id }) => (
            <Skeleton
              variant={"rounded"}
              width={getColumnRandWidth(id, "status")}
              height={20}
              sx={{ borderRadius: "16px" }}
            />
          ),
        },
      ]),
    [isCustomEngines]
  );
};
