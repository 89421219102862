import React, { useMemo, useState } from "react";
import { Box } from "@mantine/core";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import {
  DataGridPro,
  GridCellParams,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";

import {
  generateMetricsResponse,
  getCommonGraphContainerWithStatusProps,
} from "../../../ResourceView/tabs/MetricsTab/utils";
import { GraphContainerWithStatus } from "../../../Metrics/GraphContainerWithStatus";
import MultiLineCpuGraph from "../../../Metrics/graphs/MultiLineCpuGraph";
import { MetricsAggregationType } from "../../../Metrics/types";
import { transformData } from "../../../Metrics/utils";
import { ConditionalNoLimitLabel } from "../../../Metrics/NoLimitLabel";
import { getColorByIndex } from "../../../Metrics/graphs/graphUtils";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PodDetailsWithMetrics, RowDetails } from "./ContainersTableMetrics";
import { columns } from "./DetailContainerContentColumns";

const DEFAULT_PAGE_SIZE = 5;

const TopContainer = styled.div`
  background-color: white;
  padding: 1rem 0.5rem;
`;

const Container = styled.div`
  padding: 0.75rem 1rem;
  margin: 0 2rem;
  background-color: white;
  border: 1px solid ${palette.gray[100]};
  border-radius: 4px;
`;

function filterPodDetails(item: PodDetailsWithMetrics): boolean {
  const cpuOneWeek = item.metrics.cpuOneWeek;
  return (
    !cpuOneWeek.error &&
    !cpuOneWeek.loading &&
    !cpuOneWeek.paused &&
    cpuOneWeek.metrics.usage.length > 0
  );
}

const DetailContainerContent: React.FC<{ row: RowDetails }> = ({
  row: rowProp,
}) => {
  const firstItemsIds = useMemo(
    () =>
      rowProp.podsDetails.slice(0, DEFAULT_PAGE_SIZE).map((item) => item.pod),
    [rowProp.podsDetails]
  );

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(firstItemsIds);

  const items = useMemo(
    () =>
      rowProp.podsDetails.filter((podDetail) =>
        rowSelectionModel.includes(podDetail.pod)
      ),
    [rowProp.podsDetails, rowSelectionModel]
  );

  const filteredItems = useMemo(() => {
    return items.filter(filterPodDetails).map((podDetails) => ({
      name: podDetails.pod,
      data: transformData(podDetails.metrics.cpuOneWeek.metrics),
      index: rowSelectionModel.findIndex((rowIdx) => rowIdx === podDetails.pod),
    }));
  }, [items, rowSelectionModel]);

  const statusData = useMemo(() => {
    const hasData = filteredItems.length > 0;
    const loading = items.some((item) => item.metrics.cpuOneWeek.loading);
    return generateMetricsResponse({
      hasData,
      loading,
      error: false,
    });
  }, [filteredItems.length, items]);

  const uidParams = useMemo(
    () => ({
      cluster: rowProp.cluster,
      namespace: rowProp.namespace,
      name: rowProp.containerName,
    }),
    [rowProp]
  );

  return (
    <TopContainer>
      <Container>
        <GraphContainerWithStatus
          {...getCommonGraphContainerWithStatusProps(statusData)}
          title={"CPU Usage"}
          showDivider={false}
          uid={uidParams}
          info={
            <ConditionalNoLimitLabel
              data={statusData.metrics}
              isMultipleContainers={false}
            />
          }
        >
          <MultiLineCpuGraph
            items={filteredItems}
            disableZoom
            timelineTicksNum={6}
            aggregationType={MetricsAggregationType.Max}
            showLinesList={["limitBytes", "requestBytes", "usageBytes"]}
          />
        </GraphContainerWithStatus>
        <br />
        <Box
          sx={{
            "& .selection0 .MuiCheckbox-root": {
              color: getColorByIndex(0),
            },
            "& .selection1 .MuiCheckbox-root": {
              color: getColorByIndex(1),
            },
            "& .selection2 .MuiCheckbox-root": {
              color: getColorByIndex(2),
            },
            "& .selection3 .MuiCheckbox-root": {
              color: getColorByIndex(3),
            },
            "& .selection4 .MuiCheckbox-root": {
              color: getColorByIndex(4),
            },
          }}
        >
          <DataGridPro
            rows={rowProp.podsDetails}
            columns={columns}
            getRowId={(u) => u.pod}
            isRowSelectable={(params: GridRowParams) => {
              if (rowSelectionModel.includes(params.id)) {
                return true;
              }
              return rowSelectionModel.length < DEFAULT_PAGE_SIZE;
            }}
            pageSizeOptions={[
              DEFAULT_PAGE_SIZE,
              DEFAULT_PAGE_SIZE * 2,
              DEFAULT_PAGE_SIZE * 3,
            ]}
            getCellClassName={(params: GridCellParams) => {
              const index = rowSelectionModel.findIndex(
                (rowIdx) => rowIdx === params.id
              );
              if (index < DEFAULT_PAGE_SIZE) {
                return "selection" + index.toString();
              }
              return "";
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: DEFAULT_PAGE_SIZE,
                },
              },
            }}
            checkboxSelection
            pagination
          />
        </Box>
      </Container>
    </TopContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DetailContainerContent;
