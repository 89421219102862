import React, { useEffect, useState } from "react";
import { palette, theme } from "@komodorio/design-system";
import { Tag, Typography } from "@komodorio/design-system/deprecated";
import { CheckCircleOutlined24 } from "@komodorio/design-system/icons";

const TIME_TO_SHOW_MESSAGE = 4000;

export const ActionFiredMessage: React.FC<{
  locationType?: "modal" | "drawer";
  isFetching: boolean;
}> = ({ locationType = "modal", isFetching }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!isFetching) return;
    const timer = setTimeout(() => setShowMessage(true), TIME_TO_SHOW_MESSAGE);
    return () => clearTimeout(timer);
  }, [isFetching]);

  return showMessage ? (
    <Tag color={palette.green[50]}>
      <CheckCircleOutlined24 fill={theme.foreground.fgGreen} />
      <div>
        <Typography size="medium" bold>
          Action successfully sent. This may take a few minutes
        </Typography>
        <Typography>You can safely close this {locationType}</Typography>
      </div>
    </Tag>
  ) : (
    <></>
  );
};
