export interface ArgoWorkflowRawData {
  id: string;
  namespace: string;
  name: string;
  status: string;
  startedAt: string;
  finishedAt: string;
  deletedAt: string;
  clusterScope?: string;
  cronScope?: string;
  template?: string;
  from?: string;
}

export interface ArgoWorkflowParsedData {
  id: string;
  cluster: string;
  namespace: string;
  name: string;
  type: string;
  status: ArgoPhases;
  startedAt: Date | null;
  finishedAt: Date | null;
  deletedAt: Date | null;
  duration: string;
  clusterScope?: string;
  cronScope?: string;
  template?: string;
  from?: string;
}

export interface IArgoWorkflowTableData {
  [key: string]: string;
}

export interface ArgoWorkflowTableData {
  id: string;
  name: string;
  cluster: string;
  namespace: string;
  status: ArgoPhases;
  startedAt: Date | null;
  finishedAt: Date | null;
  duration: string;
  type: string;
  runs: number;
}

export enum ArgoPhases {
  Pending = "Pending",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
  Error = "Error",
}
