import React, { AriaAttributes } from "react";
import styled from "styled-components";

import { StyledLoader } from "../../shared/styles";

import { Container } from "./styles";

const MIN_WIDTH = 400;

const LoadingContainer = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 106px;
    min-width: ${MIN_WIDTH}px;
  }
`;

export const LoadingComponent: React.FC<AriaAttributes> = (props) => {
  return (
    <Container {...props}>
      <LoadingContainer>
        <StyledLoader />
      </LoadingContainer>
    </Container>
  );
};
