import { useMemo, useEffect } from "react";

import { ImpactfulEventsQuery } from "../../../generated/graphql";
import { useJobEventsByUid } from "../../../shared/hooks/resources-api/client/events/jobs/useJobEvents";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { useResourceView } from "../../ResourceView/ResourceProvider";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useNodeTerminationsByOwnerKomodorUidsFromAPI } from "../EventGroup/nodeEvent/useNodeChangeEvents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../../ResourceView/resources/job";

import { EventsWindow } from "./EventsPagination";

interface JobsEventsPaginationProps {
  index: number;
  timeWindow: Pick<TimeWindow, "start" | "end">;
  jobUid: string;
  setEventWindowMap: (page: EventsWindow, index: number) => void;
}

const defaultValues: EventsWindow = {
  impactfulEvents: {} as ImpactfulEventsQuery,
  nativePodEvents: [],
  configMapEvents: [],
  workflowResults: [],
  jobEvents: [],
  hpaEvents: [],
  pdbEvents: [],
  nodeCreatedEvents: [],
  nodeTerminatedEvents: [],
  customEvents: [],
  groupedPodEvents: [],
};

export const JobsEventsPagination: React.FC<JobsEventsPaginationProps> = ({
  timeWindow,
  index,
  setEventWindowMap,
  jobUid,
}) => {
  const events = useJobEventsByUid(timeWindow, jobUid);
  const resource = useResourceView();
  const komodorUid = useMemo(() => {
    const job = resource as Job;
    if (!job?.controlledBy || !job?.ownerReferences?.[0]?.kind)
      return undefined;
    return buildKomodorUid({
      clusterName: job.cluster,
      namespace: job.namespace,
      kind: job.ownerReferences?.[0].kind,
      resourceName: job.controlledBy,
    });
  }, [resource]);
  const KomodorUidsImpactedBynodeTermination = useMemo(
    () => (komodorUid ? [komodorUid] : []),
    [komodorUid]
  );
  const [nodeTerminations] = useNodeTerminationsByOwnerKomodorUidsFromAPI(
    KomodorUidsImpactedBynodeTermination,
    timeWindow
  );
  const page: EventsWindow = useMemo(() => {
    return {
      ...defaultValues,
      jobEvents: events ?? [],
      nodeTerminatedEvents: nodeTerminations ?? [],
    };
  }, [events, nodeTerminations]);

  useEffect(() => {
    if (page) {
      setEventWindowMap(page, index);
    }
  }, [page, index, setEventWindowMap]);

  return null;
};
