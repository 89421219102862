import React from "react";

import {
  restrictionModalsContentMap,
  restrictionsNames,
} from "../../Restrictions";
import { LimitedCapabilitiesModal } from "../common/LimitedCapabilitiesModal";

export const RestrictionAchievedModal: React.FC<{
  freemiumKey: restrictionsNames;
}> = ({ freemiumKey }) => {
  const { modalTitle, modalContent, onClick } =
    restrictionModalsContentMap[freemiumKey]();

  return (
    <LimitedCapabilitiesModal
      modalTitle={modalTitle}
      modalContent={modalContent}
      onClick={onClick}
    />
  );
};
