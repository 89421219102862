/* eslint-disable max-lines */
import Button from "@mui/material/Button";
import MuiTypography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import { Typography } from "@komodorio/design-system/deprecated";
import ViewTimelineOutlined from "@mui/icons-material/ViewTimelineOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { addMilliseconds } from "date-fns";

import { ProcessList } from "../../../common/ProcessList";
import { Timeframe, TimeWindow } from "../../../../shared/types/TimeWindow";
import {
  ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE,
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
} from "../../../../shared/config/urlSearchParamsKeys";
import { EmptyState } from "../../../Inspection/Helm/tabs/components/EmptyData";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoWorkflow from "../../resources/argoWorkflow";
import { useNodeTerminationsByOwnerKomodorUidsFromAPIQuery } from "../../../common/EventGroup/nodeEvent/useNodeChangeEvents";
import { groupNodeChangeEvents } from "../../../common/EventGroup/nodeEvent/NodeChangeGroup";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { groupNativePodEvents } from "../../../common/EventGroup/NativePodEventGroup";
import { useWorkflowIssuesQuery } from "../../../common/EventGroup/workflowIssues/useWorkflowIssues";
import { groupWorkflowIssuesEvents } from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
import { buildServiceId } from "../../../../shared/utils/serviceHelpers";
import useUserMetadata from "../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { WORKFLOW_CONFIG_TYPES } from "../../../common/EventGroup/workflowIssues/constants";
import { LinesLoader } from "../../../common/loaders/Line";
import { useNativePodEventsPaginationByOwnerUid } from "../../../common/EventGroup/nativePodEvent/useNativePodEventsPagination";
import { usePodPhasesPaginationByOwnerUid } from "../../../common/EventGroup/groupedPodEvent/useGroupedPodPhasesPagination";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { groupGroupedPodEvents } from "../../../common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { ZIndex } from "../../../../constants/zIndex";
import { useDDRumViewAndTimingListener } from "../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import useChartCallbacks from "./content/useChartCallbacks";

import { useDrawerTimeWindowFromUrl } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/useDrawerTimeWindowFromUrl";
import { EventsTimelineChart } from "@/components/common/EventsChart/TimelineChart/EventsTimelineChart";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

const MIN_DATE = new Date(0);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    display: flex;
    column-gap: 0.5rem;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const LeftSideGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
`;

const Panel = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
`;

enum ViewToggle {
  Timeline = "timeline",
  List = "list",
}

const ArgoWFEventsTab: React.FC<{ resource: ArgoWorkflow }> = ({
  resource,
}) => {
  const { groupPodsByPhases } = useOverridableFlags();
  const [highlightedEvents, setHighlightedEvents] = useState([] as string[]);
  const [showOnlyPodsWithIssues, setShowOnlyPodsWithIssues] = useState(false);
  const [eventId, setEventId] = useDrawerUrlState<string>(
    EVENTS_TAB_PREFIX + EVENT_PARAM_KEY
  );
  const [intentId, setIntentId] = useState<string | null>();

  const [viewToggle, setViewToggle] =
    useStringifiedStateInSearchParams<ViewToggle>(
      ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE
    );

  useEffect(() => {
    if (!viewToggle) {
      setViewToggle(ViewToggle.Timeline, true);
    }
  }, [setViewToggle, viewToggle]);

  const ownerKomodorUids = useMemo(() => {
    return [
      buildKomodorUid({
        clusterName: resource.cluster,
        namespace: resource.namespace,
        kind: resource.komodorKind,
        resourceName: resource.name,
      }),
    ];
  }, [resource]);

  const { accountName } = useUserMetadata();

  const serviceIds = useMemo(
    () => [
      buildServiceId(
        accountName,
        resource.cluster,
        resource.namespace,
        resource.name
      ),
    ],
    [accountName, resource.cluster, resource.name, resource.namespace]
  );

  const workflowTimeWindow = useMemo(
    () => ({
      timeframe: Timeframe.Custom,
      start: resource.startedAt ?? MIN_DATE,
      end: resource.finishedAt
        ? addMilliseconds(resource.finishedAt, 1)
        : new Date(),
    }),
    [resource]
  );

  const [nodeTerminations, isLoadingNodeTerminations] =
    useNodeTerminationsByOwnerKomodorUidsFromAPIQuery(
      ownerKomodorUids,
      workflowTimeWindow
    );
  const nodeTerminationsGroups = groupNodeChangeEvents(
    nodeTerminations?.flatMap((e) => e) ?? [],
    serviceIds[0]
  );

  const [issues, isLoadingIssues] = useWorkflowIssuesQuery(
    workflowTimeWindow,
    serviceIds
  );
  const issuesEventGroups = groupWorkflowIssuesEvents(
    issues?.flatMap((e) => e) ?? [],
    serviceIds[0]
  );

  const { nativePodEvents, isLoading: isLoadingNativePodEvents } =
    useNativePodEventsPaginationByOwnerUid(
      resource.id,
      resource.cluster,
      resource.namespace,
      !groupPodsByPhases,
      workflowTimeWindow.start,
      workflowTimeWindow.end
    );
  const nodeIssues = issuesEventGroups.filter(
    (issue) =>
      issue.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.NodeIssue]
  );
  const nativePodEventGroups = groupNativePodEvents(
    nativePodEvents?.flatMap((e) => e) ?? [],
    serviceIds[0],
    nodeIssues
  );

  const { podPhases, isLoading: isLoadingPodPhases } =
    usePodPhasesPaginationByOwnerUid(
      resource.id,
      resource.cluster,
      resource.namespace,
      !!groupPodsByPhases,
      workflowTimeWindow.start,
      workflowTimeWindow.end
    );

  const podPhasesGroups = useMemo(
    () =>
      groupGroupedPodEvents(podPhases?.flatMap((e) => e) ?? [], serviceIds[0]),
    [podPhases, serviceIds]
  );

  const podIssues = useMemo(
    () =>
      issuesEventGroups.filter(
        (issue) =>
          issue.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Pod] ||
          issue.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Workflow]
      ),
    [issuesEventGroups]
  );

  const podEventGroups = useMemo(() => {
    const eventGroups = groupPodsByPhases
      ? podPhasesGroups ?? []
      : nativePodEventGroups ?? [];
    if (!showOnlyPodsWithIssues) {
      return [...podIssues, ...eventGroups];
    }

    const podNames = podIssues.map((issue) => issue.resourceName);
    const filteredPodEvents = groupPodsByPhases
      ? podPhasesGroups?.filter((e) => podNames.includes(e.podName))
      : nativePodEventGroups?.filter((e) => podNames.includes(e.podName));
    return [
      ...(filteredPodEvents ?? []),
      ...podIssues.filter((e) => podNames.includes(e.resourceName)),
    ];
  }, [
    groupPodsByPhases,
    nativePodEventGroups,
    podIssues,
    podPhasesGroups,
    showOnlyPodsWithIssues,
  ]);

  const clusterLevelEventGroups = useMemo(
    () => [...nodeTerminationsGroups, ...nodeIssues],
    [nodeIssues, nodeTerminationsGroups]
  );

  const eventGroups = useMemo(
    () => [...clusterLevelEventGroups, ...podEventGroups],
    [clusterLevelEventGroups, podEventGroups]
  );

  const { onChartMouseEnter, onChartMouseLeave, onChartClick } =
    useChartCallbacks(setHighlightedEvents, setEventId);

  const [isZoomChanged, setIsZoomChanged] = useState<boolean>(false);
  const [pageDefaultTimeWindow, setPageDefaultTimeWindow] =
    useState<Partial<TimeWindow>>(workflowTimeWindow);
  const [timeWindow, setTimeWindow] = useDrawerTimeWindowFromUrl(
    workflowTimeWindow,
    EVENTS_TAB_TIME_WINDOW,
    EVENTS_TAB_TIMEFRAME
  );

  const onResetZoom = () => {
    setTimeWindow(pageDefaultTimeWindow);
    setIsZoomChanged(false);
  };

  const isFetching =
    isLoadingNodeTerminations ||
    isLoadingIssues ||
    (groupPodsByPhases ? isLoadingPodPhases : isLoadingNativePodEvents);
  const isEmpty = eventGroups.length === 0;

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.argoWorkflowsViewEventsTab,
    addTimingParams: {
      enable: !isFetching,
    },
  });

  return (
    <Container data-e2e-selector="argo-events-tab">
      <Header>
        <LeftSideGroup>
          <Typography
            variant="title"
            size="large"
            data-e2e-selector="argo-events-title"
          >
            Events {eventGroups ? `(${eventGroups.length})` : null}
          </Typography>
          {podIssues.length > 0 && (
            <SwitchContainer>
              <Switch
                checked={showOnlyPodsWithIssues}
                onChange={() => {
                  setShowOnlyPodsWithIssues((prevState) => !prevState);
                }}
                aria-label={
                  AriaLabels.ArgoWorkflows.ShowOnlyPodsWithErrorsToggle
                }
              />
              <MuiTypography variant={"body2"}>
                Show only pods with errors
              </MuiTypography>
            </SwitchContainer>
          )}
          {viewToggle === ViewToggle.Timeline && isZoomChanged && (
            <Button onClick={onResetZoom}>Reset zoom</Button>
          )}
        </LeftSideGroup>
        <ToggleButtonGroup
          exclusive
          onChange={(_e, value) => value && setViewToggle(value)}
          value={viewToggle}
        >
          <StyledToggleButton
            value={ViewToggle.Timeline}
            data-e2e-selector="argo-events-timeline-button"
          >
            <ViewTimelineOutlined sx={{ width: 16, height: 16 }} />
            <Typography>Timeline</Typography>
          </StyledToggleButton>
          <StyledToggleButton
            value={ViewToggle.List}
            data-e2e-selector="argo-events-list-button"
          >
            <ListAltOutlined sx={{ width: 16, height: 16 }} />
            <Typography>List</Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Header>
      {isFetching && isEmpty ? (
        <LinesLoader />
      ) : isEmpty ? (
        <EmptyState text="No events" />
      ) : (
        <>
          <Panel show={viewToggle === ViewToggle.Timeline}>
            <EventsTimelineChart
              data-e2e-selector="events-timeline-chart"
              onMouseEnter={onChartMouseEnter}
              onMouseLeave={onChartMouseLeave}
              onClick={onChartClick}
              eventGroups={clusterLevelEventGroups}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              setPageDefaultTimeWindow={setPageDefaultTimeWindow}
              setIsZoomChanged={setIsZoomChanged}
              xAxisPosition={"top"}
              zIndex={ZIndex.AboveTimelineChart}
            />
            <EventsTimelineChart
              onMouseEnter={onChartMouseEnter}
              onMouseLeave={onChartMouseLeave}
              onClick={onChartClick}
              eventGroups={podEventGroups}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              setPageDefaultTimeWindow={setPageDefaultTimeWindow}
              setIsZoomChanged={setIsZoomChanged}
              highlightedId={intentId ?? undefined}
              paginateSwimlanes
              hideXAxis
            />
          </Panel>
          <Panel show={viewToggle === ViewToggle.List}>
            <ProcessList
              highlightedEvents={highlightedEvents}
              showServiceColumn={serviceIds.length > 1}
              eventGroups={eventGroups ?? []}
              showMoreEventsPanel={true}
              eventId={eventId}
              setEventId={setEventId}
              setIntentId={setIntentId}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
            />
          </Panel>
        </>
      )}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ArgoWFEventsTab;
