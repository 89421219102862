import { intervalToDuration, subHours } from "date-fns";

import {
  BucketTimeFrame,
  TimeStampDataPoint,
} from "../../../components/OverviewPage/types/overviewPageTypes";
import { AvailabilityIssuesResponse } from "../../../../../../../generated/applicationInsights";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  generateMockAvailabilityIssuesTrends,
  getAdjustedSumByDay,
} from "./mockAppInsightsAvailabilityIssues";

export const getTotalDays = (fromEpoch: number, toEpoch: number) =>
  (intervalToDuration({
    start: new Date(fromEpoch),
    end: new Date(toEpoch),
  }).days || 13) + 1;

type GetDateIntervalsParams = {
  endTime: number;
  intervalsNum: number;
  bucketTimeFrame: BucketTimeFrame;
};

export const getDateIntervals = ({
  intervalsNum,
  bucketTimeFrame,
  endTime,
}: GetDateIntervalsParams) => {
  const hoursByBucketFrame = getHoursByBucketFrame(bucketTimeFrame);
  return Array.from({ length: intervalsNum }).map((_, i) => {
    const timeToSubtract = intervalsNum - i;
    return subHours(endTime, hoursByBucketFrame * timeToSubtract).getTime() + 1;
  });
};

const getHoursByBucketFrame = (bucketFrame: BucketTimeFrame) => {
  switch (bucketFrame) {
    case "24h":
      return 24;
    default:
      return 24;
  }
};

type GenerateMockAvailabilityIssuesParams = {
  fromEpoch: number;
  toEpoch: number;
};

export const generateEmptyTimeStampDataPoints = (
  params: GenerateMockAvailabilityIssuesParams
): TimeStampDataPoint[] => {
  const { sumByDay } = generateMockAvailabilityIssuesTrends(params);
  return sumByDay.map(({ ts }) => ({ ts, value: undefined }));
};

const serviceKinds = [
  "Deployment",
  "StatefulSet",
  "DaemonSet",
  "Job",
  "CronJob",
];

export const getServiceKind = (i = 0): string => {
  return serviceKinds[i % serviceKinds.length];
};

export const mockSumByDayWithNoDataInFirstEpoch = (
  availabilityIssuesResponse: AvailabilityIssuesResponse
) => {
  const daysWithNoData = 7;
  // create sumByDay data with 0 issues in first epoch
  return getAdjustedSumByDay({
    currentSumByDay: availabilityIssuesResponse.sumByDay,
    daysWithNoData,
  });
};
