import React from "react";

import Recommendations from "../../../../common/Recommendations";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import { GrayHighlight, UnderlinedButton } from "../../../../common/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeNetworkAvailableCheck from "../NodeNetworkAvailableCheck";

import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";

const NodeNetworkAvailableDetails: React.FC<{
  check: NodeNetworkAvailableCheck;
  setSelectedCheckType: StateInSearchParamsType[1];
}> = ({ check, setSelectedCheckType }) => {
  if (!check.output) {
    return null;
  }
  if (check.passed) {
    return check.output.reason && check.output.message ? (
      <>
        <br />
        <BoldGrayText>Message</BoldGrayText>
        <GrayHighlight>{`${check.output.reason}, ${check.output.message}`}</GrayHighlight>
        <br />
      </>
    ) : null;
  }

  return (
    <>
      <br />
      <BoldGrayText>Error Message</BoldGrayText>
      <GrayHighlight>{`${check.output.reason}, ${check.output.message}`}</GrayHighlight>
      <br />
      <br />
      <Recommendations>
        View the{" "}
        <UnderlinedButton
          onClick={() => setSelectedCheckType("node-system-pods-healthy")}
        >
          system pods check
        </UnderlinedButton>{" "}
        and{" "}
        <UnderlinedButton onClick={() => setSelectedCheckType("node-ready")}>
          node readiness check
        </UnderlinedButton>{" "}
        to learn more
      </Recommendations>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeNetworkAvailableDetails;
