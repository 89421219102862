import { useEffect, useRef } from "react";

import { useGetEmailDigestConfiguration } from "@/shared/hooks/email-digest/useEmailDigestConfiguration";
import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";

export const useSetDataFromServer = () => {
  const { data } = useGetEmailDigestConfiguration();
  const { setConfigurationData } = useEmailSettingsContext();
  const hasSetData = useRef(false);

  useEffect(() => {
    if (hasSetData.current) return;
    if (data?.data) {
      setConfigurationData(data.data);
      hasSetData.current = true;
    }
  }, [data, setConfigurationData]);

  return { isDataSet: hasSetData.current };
};
