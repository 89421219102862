import { useEffect, useMemo, useState } from "react";
import {
  ActionMetadataGetResource,
  ActionTypes,
  TaskType,
} from "komodor-types";

import { CostRightSizingSummary } from "../../../../../../generated/metricsApi";
import { useActiveAgent } from "../../../../../../shared/hooks/useAgents";
import useAgentTask from "../../../../../../shared/hooks/useAgentTask/useAgentTask";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";
import { formatCurrentResourceValues } from "../utils/formatCurrentResourceValues";
import { parseContainersResult } from "../../../../../../shared/utils/actions/parseContainersResult";
import { isDevMode } from "../../../../../../shared/utils/isDevMode";
import { mockContainerResources } from "../../../../__tests__/mockData/mockRecommendationsModalData";

const ROLLOUT_TYPE = "Rollout";

export const useSaveContainersResources = (data: CostRightSizingSummary) => {
  const [requestSentCount, setRequestSentCount] = useState(0);
  const { containers, isContainersLoaded, setInitialContainers } =
    useRecommendationsModalContext();

  const {
    clusterName,
    namespace,
    service,
    komodorServiceKind,
    komodorServiceId,
  } = data;
  const agentId = useActiveAgent(clusterName) ?? "";

  //patch to handle the bug rollout detected as deployment
  const shouldReExecuteWithRolloutType = useMemo(
    () =>
      requestSentCount === 1 &&
      komodorServiceKind === "Deployment" &&
      !containers.length,
    [containers.length, komodorServiceKind, requestSentCount]
  );

  const getContainersResourcesMetadata =
    useMemo<ActionMetadataGetResource>(() => {
      return {
        cluster: clusterName,
        namespace: namespace,
        serviceId: komodorServiceId,
        resourceType: shouldReExecuteWithRolloutType
          ? ROLLOUT_TYPE
          : komodorServiceKind,
        resourceName: service,
        isContainerResourcesOnly: true,
        type: ActionTypes.GetResource,
      };
    }, [
      clusterName,
      komodorServiceId,
      komodorServiceKind,
      namespace,
      service,
      shouldReExecuteWithRolloutType,
    ]);

  const { execute: executeGetContainers, result: getContainersResult } =
    useAgentTask(
      agentId,
      TaskType.ACTION_COMMAND,
      getContainersResourcesMetadata
    );

  useEffect(() => {
    if (!requestSentCount || shouldReExecuteWithRolloutType) {
      executeGetContainers();
      setRequestSentCount((prev) => prev + 1);
    }
  }, [
    containers.length,
    executeGetContainers,
    requestSentCount,
    shouldReExecuteWithRolloutType,
  ]);

  const parsedContainers = useMemo(
    () => parseContainersResult(getContainersResult),
    [getContainersResult]
  );

  const shouldInitContainers =
    !isContainersLoaded && (isDevMode() || parsedContainers);

  useEffect(() => {
    if (shouldInitContainers) {
      const containers = parsedContainers ?? mockContainerResources();
      const formattedContainers = formatCurrentResourceValues(containers);
      setInitialContainers(formattedContainers);
    }
  }, [
    getContainersResult,
    setInitialContainers,
    isContainersLoaded,
    shouldInitContainers,
    parsedContainers,
  ]);
};
