import React from "react";
import { Button } from "@komodorio/design-system/deprecated";

import { LoadingSpinnerIcon } from "../../../shared/components/LoadingSpinnerIcon";

interface DisabledRunButtonProps {
  text?: string;
}

export const DisabledRunButton: React.FC<DisabledRunButtonProps> = ({
  text = "",
}) => (
  <Button variant="primary" size="small" disabled>
    {text}
    <LoadingSpinnerIcon />
  </Button>
);
