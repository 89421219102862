import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ConnectionFailureEmptyStateErrorProps } from "./ConnectionFailureEmptyStateErrorPage";
import { titles } from "./constants";

const getTitle = (noClustersTracked: boolean) => {
  if (noClustersTracked) {
    return titles.noClustersTracked;
  }
  return titles.failedClustersConnection;
};

export const ErrorTitle: React.FC<ConnectionFailureEmptyStateErrorProps> = ({
  noClustersTracked,
}) => {
  return (
    <Typography variant="headline" size="small" color={palette.gray[800]}>
      {getTitle(noClustersTracked)}
    </Typography>
  );
};
