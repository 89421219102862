import { useEffect, useState } from "react";
import { CombinedError } from "urql";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { Record, String, Literal } from "runtypes";

import { useCreateGithubInstallationMutation } from "../../../../generated/graphql";
import { useCreateGithubInstallation } from "../../../../shared/hooks/accounts-service/client/installations/github/useCreateGithubInstallation";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

const Query = Record({
  installation_id: String.withConstraint((s) => Number.isInteger(+s)),
  setup_action: Literal("install"),
});
const parseQuery = () => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return Query.guard(query) ? query : null;
};

const extractErrorCode = (error?: CombinedError) =>
  error?.graphQLErrors?.[0].message ?? "unknown";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default (): string | null => {
  const {
    createGithubInstallationOnAccountsApi:
      createGithubInstallationOnAccountsApiFF,
  } = useOverridableFlags();
  const [, createInstallation] = useCreateGithubInstallationMutation();
  const { mutateAsync: createInstallationAccountsApiClient } =
    useCreateGithubInstallation();
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const query = parseQuery();
      if (!query) {
        return setErrorCode("query");
      }

      const installationId = +query.installation_id;

      if (!createGithubInstallationOnAccountsApiFF) {
        const result = await createInstallation({
          installationId,
        });
        if (result.error) {
          return setErrorCode(extractErrorCode(result.error));
        }
      } else {
        const result = await createInstallationAccountsApiClient({
          installationId: installationId.toString(),
        });
        if (!result.success) {
          return setErrorCode("failed creating installation");
        }
      }
      navigate("/main/integration", { replace: true });
    })();
  }, [
    createGithubInstallationOnAccountsApiFF,
    createInstallation,
    createInstallationAccountsApiClient,
    navigate,
  ]);

  return errorCode;
};
