import React, { useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
  Typography,
  Button,
  ModalProps,
} from "@komodorio/design-system/deprecated";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AppViewUrlParam } from "../../../../types/urlParams";
import { getOverviewPageRoute } from "../../utils/getRoutes";

import { useIsEditMode } from "./hooks/creationPageHooks";

interface CancellationDialogProps {
  isOpen: ModalProps["isOpen"];
  close: ModalProps["onRequestClose"];
}

export const CancellationDialog: React.FC<CancellationDialogProps> = ({
  isOpen,
  close,
}) => {
  const location = useLocation();
  const origin = useRef<string | undefined>(location.state?.origin);
  const navigate = useNavigate();
  const { id } = useParams<AppViewUrlParam>();
  const isEditMode = useIsEditMode();

  const handleCancellation = () => {
    if (isEditMode && id) {
      navigate(getOverviewPageRoute(id));
    } else if (origin.current?.includes("app.komodor")) {
      navigate(-1);
    } else {
      navigate({ pathname: "/" }); // Go to home page.
    }
  };

  const title = !isEditMode
    ? "Are you sure you want to cancel the workspace creation process?"
    : "Are you sure you want to cancel the workspace editing process?";

  return (
    <Modal onRequestClose={close} isOpen={isOpen} width="25rem">
      <ModalHeader>
        <Typography variant="headline">Cancel workspace setup?</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography>{title}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={close} size="small" variant="secondary">
          No
        </Button>
        <Button onClick={handleCancellation} size="small" variant="primary">
          Yes, cancel setup
        </Button>
      </ModalActions>
    </Modal>
  );
};
