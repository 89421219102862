import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  apiV1RbacClusterStatusGetUrl,
  RbacClusterStatus,
  RbacClusterStatusApiApiV1RbacClusterStatusGetRequest,
} from "@/generated/auth";

const RBAC_CLUSTER_STATUS_PATH = "/rbac-cluster-status";

const getRbacClusterStatuses = async (
  apiClient: AxiosInstance,
  params: RbacClusterStatusApiApiV1RbacClusterStatusGetRequest
): Promise<RbacClusterStatus[]> => {
  const { data } = await apiClient.get(
    apiV1RbacClusterStatusGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacClusterStatuses = (
  params?: RbacClusterStatusApiApiV1RbacClusterStatusGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_CLUSTER_STATUS_PATH, params],
    () => getRbacClusterStatuses(apiClient, params ?? {}),
    {
      enabled,
    }
  );
};
