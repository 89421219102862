import React, { useMemo } from "react";

import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../../store/overviewPageStoreSelectors";
import { ComparisonGraph } from "../../../shared/components/ComparisonGraph/ComparisonGraph";
import { getMiddleIndex } from "../../../shared/utils/utils";
import { useMemoizedDataBreakdownPerInterval } from "../../../shared/hooks/appInisghtsHooks";

export const useSplitToGraphData = (isMaximized: boolean) => {
  const {
    availabilityIssues: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const middleIndex = useMemo(
    () => getMiddleIndex(data?.sumByDay ?? []),
    [data?.sumByDay]
  );

  const graphsData = useMemo(() => {
    return {
      firstIntervalDataPoints: data?.sumByDay?.slice(0, middleIndex),
      secondIntervalDataPoints: data?.sumByDay?.slice(middleIndex),
    };
  }, [data?.sumByDay, middleIndex]);

  const { firstIntervalAverage = 0, secondIntervalAverage = 0 } =
    useMemoizedDataBreakdownPerInterval(data?.sumByDay) ?? {};

  if (!data) return null;
  return (
    <ComparisonGraph
      previousDatapoints={graphsData.firstIntervalDataPoints ?? []}
      previousAvg={firstIntervalAverage}
      currentDatapoints={graphsData.secondIntervalDataPoints ?? []}
      currentAvg={secondIntervalAverage}
      isMaximized={isMaximized}
    />
  );
};
