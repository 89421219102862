import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { LinesLoader } from "../../../../../../../common/loaders/Line";
import { InfiniteScrollerContainer } from "../InfiniteScrollerContainer";

const IconContainer = styled.div`
  color: ${palette.gray["400"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  & :nth-child(2) {
    margin-top: 1rem;
  }
`;

const LargeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

type CardContentDisplayHandlerProps = {
  fetching: boolean;
  isEmptyState: boolean;
  emptyStateDisplay: {
    icon: React.ReactNode;
    dataType: string;
  };
  children?: React.ReactNode;
};

export const CardContentDisplayHandler: React.FC<
  CardContentDisplayHandlerProps
> = ({
  fetching,
  isEmptyState,
  children,
  emptyStateDisplay: { dataType, icon },
}: CardContentDisplayHandlerProps) => {
  const emptyStateContent = (
    <IconContainer>
      <LargeIcon>{icon}</LargeIcon>
      <Typography variant={"text"} size={"medium"} color={palette.gray["600"]}>
        {`There are no ${dataType} to show`}
      </Typography>
    </IconContainer>
  );

  return (
    <>
      {fetching ? (
        <LinesLoader />
      ) : isEmptyState ? (
        <div>{emptyStateContent}</div>
      ) : (
        <InfiniteScrollerContainer>{children}</InfiniteScrollerContainer>
      )}
    </>
  );
};
