import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";

export const CREATE_FREE_ACCOUNT_ROUTE = "/create-free-account";

export const isCreateFreeAccountLocalStorage = new LocalStorageItem(
  "isFreemiumAccount"
);

export const setIsFreemiumAccount = (path: string): void =>
  isCreateFreeAccountLocalStorage.set(
    `${path.includes(CREATE_FREE_ACCOUNT_ROUTE)}`
  );
export const getIsFreemiumAccount = (): boolean =>
  isCreateFreeAccountLocalStorage.get() === "true";
