import { useEffect, useRef, useState } from "react";

type IsFetchingPodLogsParams = {
  taskId?: string;
  dataLength?: number;
  fetching: boolean;
};

export const useIsFetchingPodLogs = ({
  dataLength,
  taskId,
  fetching,
}: IsFetchingPodLogsParams) => {
  const [isFetching, setIsFetching] = useState(true);
  const lastTaskId = useRef(taskId);

  useEffect(() => {
    const newFetching = fetching || dataLength === 0 || !taskId;
    if (lastTaskId.current === taskId && newFetching) return;
    lastTaskId.current = taskId;
    setIsFetching(newFetching);
  }, [taskId, fetching, dataLength]);

  return isFetching;
};
