import { getAppConfig } from "../../config/appConfig";
import { useAPIGet, useAPIPost } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointsRequestMap,
  EndpointsResponseMap,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead. see implemantations in services/web/src/shared/hooks/resources-api/client
 */
export const useResourcesAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<EndpointsResponseMap[T]> => {
  return useAPIGet<EndpointsResponseMap[T]>(
    relativePath,
    parameters,
    pause,
    getAPIBasePath()
  );
};

/**
 * @deprecated use react-query instead. see implemantations in services/web/src/shared/hooks/resources-api/client
 */
export const useResourcesAPIPost = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  reqBody: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<EndpointsResponseMap[T]> => {
  return useAPIPost<EndpointsResponseMap[T]>(
    relativePath,
    reqBody,
    pause,
    getAPIBasePath()
  );
};

const getAPIBasePath = (): string => {
  const serverBaseURL = getAppConfig().resourcesAPIServerURL;
  return serverBaseURL + "/api/v1";
};
