import { ContainerResources } from "../types";

export const isInvalidResourceValues = (
  resources: ContainerResources | undefined,
  resource: "memory" | "cpu"
) => {
  const requestsValue = resources?.requests?.[resource];
  const limitsValue = resources?.limits?.[resource];

  return !!(requestsValue && limitsValue && requestsValue > limitsValue);
};
