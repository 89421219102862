import React, { useState, ReactElement } from "react";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import { reliabilityArialLabels } from "../../../components/reliability/reliabilityArialLabels";

const DEFAULT_PADDING = 16;

const Container = styled.div<{ padding: number }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
  padding: ${({ padding }) => padding}px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const TitleCover = styled.div<{ padding: number }>`
  height: calc(100% + ${({ padding }) => padding * 2}px);
  width: calc(100% + ${({ padding }) => padding * 2}px);
  position: absolute;
  top: -${({ padding }) => padding}px;
  left: -${({ padding }) => padding}px;
  cursor: pointer;
`;

const ContentContainer = styled(Collapse)<{ padding: number }>`
  & .MuiCollapse-wrapperInner {
    padding-top: ${({ padding }) => padding}px;
  }
`;

const AnimatedKeyboardArrowDown = styled(KeyboardArrowDown)<{
  open: boolean;
}>`
  && {
    cursor: pointer;
    transition: transform 0.2s;
    transform: rotate(${({ open }) => (open ? "0deg" : "-90deg")});
  }
`;

const headerAriaLabel = reliabilityArialLabels.collapsibleContainer.header;

export interface CollapsibleContainerProps {
  title: ReactElement;
  content: ReactElement;
  actionSection?: ReactElement;
  initialOpen?: boolean;
  padding?: number;
  onOpenChange?: (isOpen: boolean) => void;
  ariaLabel?: string;
  className?: string;
}
export const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  title,
  content,
  actionSection,
  initialOpen = false,
  padding = DEFAULT_PADDING,
  onOpenChange,
  ariaLabel,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const onClick = () => {
    setIsOpen(!isOpen);
    onOpenChange?.(!isOpen);
  };

  return (
    <Container
      aria-label={`${ariaLabel} ${isOpen ? "open" : ""}`}
      padding={padding}
      className={className}
    >
      <TitleContainer>
        <Header onClick={onClick} aria-label={headerAriaLabel}>
          <AnimatedKeyboardArrowDown open={isOpen} />
          {title}
          <TitleCover padding={padding} />
        </Header>
        {actionSection}
      </TitleContainer>
      <ContentContainer
        in={isOpen}
        padding={padding}
        timeout="auto"
        unmountOnExit
      >
        {content}
      </ContentContainer>
    </Container>
  );
};
