import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";

interface PodProps {
  containerName?: string;
  namespace: string;
  podName: string;
  agentId: string;
  cluster: string;
}

interface PodExecSessionsManager {
  openDrawerIds: string[];
  openMinimizedIds: string[];
  addDrawerSessionId: (id: string) => void;
  removeDrawerSessionId: (id: string) => void;
  addMinimizedSessionId: (id: string) => void;
  removeMinimizedSessionId: (id: string) => void;
  setSessionProps: (id: string, props: Partial<PodProps>) => void;
  getSessionProps: (id: string) => PodProps;
  createSessionIdentifier: (podId: string, containerName: string) => string;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const podExecSessionsCtx = createContext<PodExecSessionsManager>({
  openDrawerIds: [],
  openMinimizedIds: [],
  addDrawerSessionId: () => undefined,
  removeDrawerSessionId: () => undefined,
  addMinimizedSessionId: () => undefined,
  removeMinimizedSessionId: () => undefined,
  getSessionProps: () => ({} as PodProps),
  setSessionProps: () => undefined,
  createSessionIdentifier: () => "",
});

const arrayRemove = (arr: string[], idToRemove: string) => {
  const idIdx = arr.findIndex((existingId) => existingId === idToRemove);

  if (idIdx > -1) {
    const cloned = [...arr];
    cloned.splice(idIdx, 1);

    return cloned;
  }

  return arr;
};

export const PodExecSessionsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [openDrawerIds, setOpenDrawerIds] = useState<string[]>([]);
  const [openMinimizedIds, setOpenMinimizedIds] = useState<string[]>([]);
  const [props, setProps] = useState<Record<string, PodProps>>({});

  const addDrawerSessionId = useCallback(
    (newId: string) => {
      setOpenDrawerIds((existing) => {
        return existing.includes(newId) ? existing : [...existing, newId];
      });
    },
    [setOpenDrawerIds]
  );

  const removeDrawerSessionId = useCallback(
    (idToRemove: string) => {
      setOpenDrawerIds((existing) => arrayRemove(existing, idToRemove));
    },
    [setOpenDrawerIds]
  );

  const addMinimizedSessionId = useCallback(
    (newId: string) => {
      setOpenMinimizedIds((existing) => {
        return existing.includes(newId) ? existing : [...existing, newId];
      });
    },
    [setOpenMinimizedIds]
  );

  const removeMinimizedSessionId = useCallback(
    (idToRemove: string) => {
      setOpenMinimizedIds((existing) => arrayRemove(existing, idToRemove));
    },
    [setOpenMinimizedIds]
  );

  const setSessionProps = useCallback(
    (id: string, props: Partial<PodProps>) => {
      setProps((prev) => ({
        ...prev,
        [id]: { ...(prev[id] || {}), ...props },
      }));
    },
    [setProps]
  );

  const getSessionProps = useCallback((id: string) => props[id], [props]);

  const createSessionIdentifier = useCallback(
    (podId: string, containerName: string) => {
      return podId + "-" + containerName;
    },
    []
  );

  const context: PodExecSessionsManager = useMemo(
    () => ({
      openDrawerIds,
      openMinimizedIds,
      addDrawerSessionId,
      removeDrawerSessionId,
      addMinimizedSessionId,
      removeMinimizedSessionId,
      setSessionProps,
      getSessionProps,
      createSessionIdentifier,
    }),
    [
      addDrawerSessionId,
      addMinimizedSessionId,
      openDrawerIds,
      openMinimizedIds,
      removeDrawerSessionId,
      removeMinimizedSessionId,
      setSessionProps,
      getSessionProps,
      createSessionIdentifier,
    ]
  );

  return (
    <podExecSessionsCtx.Provider value={context}>
      {children}
    </podExecSessionsCtx.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const usePodExecSessionsContext = (): PodExecSessionsManager => {
  return useContext(podExecSessionsCtx);
};
