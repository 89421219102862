import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { Button, Drawer } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";

import { isSandbox } from "../../../shared/utils/sandbox";
import { WorkflowConfigType, WorkflowConfiguration } from "../common/types";
import xIcon from "../assets/x.svg";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { LightText } from "../common/fonts";
import {
  useFormValidations,
  ValidationsProvider,
} from "../../../shared/context/ValidationsProvider";
import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import { useCreateWorkflowConfiguration } from "./useCreateWorkflowConfiguration";
import {
  RuleStepSectionContainer,
  Section,
} from "./common/RuleStepSectionContainer";
import RuleTypeSection from "./common/RuleTypeSection";
import PVCRuleSections, { pvcDefaultRule } from "./PVCRuleSections";
import NodeRuleSections from "./NodeRuleSections";
import AvailabilityRuleSections from "./AvailabilityRuleSections";
import JobRuleSections from "./JobRuleSections";
import CronJobRuleSections from "./CronJobRuleSections";
import DeployRuleSections from "./DeployRuleSections";
import PodRuleSections from "./PodRuleSections";

import WorkflowRuleSections from "@/components/monitorsView/monitorsConfiguration/WorkflowRuleSections";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const CloseButton = styled.img.attrs({ src: xIcon, alt: "close" })`
  cursor: pointer;
  align-self: start;
  padding-top: 0.25rem;
`;

const HeaderSection = styled(Section)`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #3b3d45;
  margin-top: 0.5rem;
`;

const SubmitSection = styled(Section)`
  margin-top: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

interface MonitorRuleDrawerProps {
  isOpen: boolean;
  initRule: WorkflowConfiguration | undefined;
  isTypeStatic: boolean;
  handleClose: () => void;
  refreshMonitors: () => void;
}

const MonitorRuleDrawer: React.FC<MonitorRuleDrawerProps> = ({
  isOpen,
  initRule,
  isTypeStatic,
  handleClose,
  refreshMonitors,
}) => {
  return (
    <Drawer
      open={isOpen}
      direction="right"
      width="48rem"
      onOverlayClick={handleClose}
      onEscKeyPress={handleClose}
    >
      {isOpen && (
        <ValidationsProvider>
          <DrawerContent
            initRule={initRule}
            isTypeStatic={isTypeStatic}
            handleClose={handleClose}
            refreshMonitors={refreshMonitors}
          />
        </ValidationsProvider>
      )}
    </Drawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorRuleDrawer;

const ConfigRuleByType: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  switch (rule.type) {
    case WorkflowConfigType.PVC:
      return <PVCRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.NodeIssue:
      return <NodeRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.Availability:
      return <AvailabilityRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.Job:
      return <JobRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.CronJob:
      return <CronJobRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.Deploy:
      return <DeployRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.Pod:
      return <PodRuleSections rule={rule} setRule={setRule} />;
    case WorkflowConfigType.Workflow:
      return <WorkflowRuleSections rule={rule} setRule={setRule} />;
    default:
      return null;
  }
};

const DrawerContent: React.FC<Omit<MonitorRuleDrawerProps, "isOpen">> = ({
  initRule,
  isTypeStatic,
  handleClose,
  refreshMonitors,
}) => {
  const [submitRequested, setSubmitRequested] = useState(false);
  const [rule, setRule] = useState<WorkflowConfiguration>(pvcDefaultRule);
  const { errors } = useFormValidations();
  const isConfigurationValid = Object.values(errors).every(
    (err) => err === undefined
  );

  const createWorkflowConfiguration = useCreateWorkflowConfiguration();

  useEffect(() => {
    if (initRule) {
      setRule(initRule);
    }
  }, [initRule]);

  const analytics = useAnalyticsApi();
  const reportAnalytics = useCallback(
    (newRule: WorkflowConfiguration) => {
      initRule
        ? dispatchEvent(AnalyticEvents.MonitorsView.Rule_Modified, {
            type: newRule.type,
            duration: newRule.variables?.duration,
            minAvailable: newRule.variables?.minAvailable,
          })
        : dispatchEvent(AnalyticEvents.MonitorsView.Rule_Created, {
            type: newRule.type,
            duration: newRule.variables?.duration,
            minAvailable: newRule.variables?.minAvailable,
          });

      const newSinks = (newRule.sinks as Record<string, string[]>) ?? {};
      const oldSinks = (initRule?.sinks as Record<string, string[]>) ?? {};

      Object.keys(newSinks).forEach((k) => {
        if (newSinks[k] && !oldSinks[k]) {
          analytics.dispatchEventViaBackend(
            AnalyticEvents.MonitorsView.Sink_Configured,
            {
              type: k,
            },
            true
          );
        }
      });
      Object.keys(oldSinks).forEach((k) => {
        if (oldSinks[k] && !newSinks[k]) {
          dispatchEvent(AnalyticEvents.MonitorsView.Sink_Removed, { type: k });
        }
      });
    },
    [analytics, initRule]
  );

  useEffect(() => {
    if (submitRequested && rule) {
      reportAnalytics(rule);
      (async () => {
        await createWorkflowConfiguration({
          ...rule,
          id: rule.id ? rule.id : uuidv4(),
        });
        handleClose();
        refreshMonitors();
        setSubmitRequested(false);
      })();
    }
  }, [
    createWorkflowConfiguration,
    submitRequested,
    rule,
    handleClose,
    refreshMonitors,
    reportAnalytics,
  ]);

  return (
    <Container>
      <HeaderSection topDivider={false}>
        <div>
          <LightText fontSize="16px">
            Cluster: {rule.sensors[0].cluster}
          </LightText>
          <HeaderTitle>{rule.id ? "Edit Rule" : "New Rule"}</HeaderTitle>
        </div>
        <CloseButton onClick={handleClose} />
      </HeaderSection>
      <RuleStepSectionContainer sectionNumber={1} title="Rule Type:">
        <RuleTypeSection
          currentCluster={rule.sensors[0].cluster}
          currentType={rule.type}
          setRule={setRule}
          isStatic={isTypeStatic}
        />
      </RuleStepSectionContainer>
      <ConfigRuleByType rule={rule} setRule={setRule} />
      <SubmitSection>
        <ButtonsContainer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <TooltipWrapper
            tooltipId={DEFAULT_TOOLTIP}
            content={
              isSandbox()
                ? "This option is disabled in sandbox version"
                : undefined
            }
          >
            <Button
              variant="primary"
              disabled={submitRequested || !isConfigurationValid || isSandbox()}
              onClick={() => setSubmitRequested(true)}
            >
              {submitRequested ? "Saving..." : "Save Monitor"}
            </Button>
          </TooltipWrapper>
        </ButtonsContainer>
      </SubmitSection>
    </Container>
  );
};
