import React, { useRef } from "react";

import Resource from "../../../resources";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

import { useReportDescribeTabToDatadog } from "./hooks/useReportDescribeTabToDatadog";
import { DescribeLoader } from "./DescribeLoader";
import { DescribeContainer } from "./DescribeSection";

type DescribeTabContentWrapperProps = {
  children: React.ReactNode;
  resource: Resource;
};

export const DescribeTabContentWrapper: React.FC<
  DescribeTabContentWrapperProps
> = ({ resource, children }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useReportDescribeTabToDatadog({
    viewName: dataDogViewNames.getResourceViewDescribeTab(resource.kind),
    resourceId: resource.id,
    divRef,
  });

  return (
    <div ref={divRef}>
      {!resource.id && <DescribeLoader />}
      {resource.id && <DescribeContainer>{children}</DescribeContainer>}
    </div>
  );
};
