import React from "react";

import Resource from "../../../resources";

import { DescribeDateItem, DescribeTextItem } from "./DescribeItem";
import { DescribeLabelsAndAnnotations } from "./DescribeLabelsAndAnnotations";

type PanelResource = Resource & {
  creationTimestamp?: string;
};

export const DescribeMainPanelData: React.FC<{
  resource: PanelResource;
  children?: React.ReactNode;
}> = ({ resource, children }) => (
  <>
    <DescribeTextItem name="Name" value={resource.name} />
    <DescribeTextItem name="Cluster" value={resource.cluster} />
    <DescribeTextItem name="Namespace" value={resource.namespace} />
    {resource.creationTimestamp && (
      <DescribeDateItem name="Start Time" value={resource.creationTimestamp} />
    )}
    {children}
    <DescribeLabelsAndAnnotations
      labels={resource.labels}
      annotations={resource.annotations}
    />
  </>
);
