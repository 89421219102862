import React from "react";
import { palette } from "@komodorio/design-system";
import {
  Tag,
  Typography,
  TypographySize,
  TypographyVariant,
  TagVariant,
} from "@komodorio/design-system/deprecated";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum ResourceStatusEnum {
  Pending = "Pending",
  Healthy = "Healthy",
  Failed = "Failed",
  Unknown = "Unknown",
  Warning = "Warning",
  Deleted = "Deleted",
  Unhealthy = "Unhealthy",
}
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getColorByStatus = (
  status: ResourceStatusEnum
): { background: string; fillColor: string; tile: string } => {
  switch (status) {
    case ResourceStatusEnum.Pending:
      return {
        background: palette.blue[100],
        fillColor: palette.blue[900],
        tile: palette.gray[500],
      };
    case ResourceStatusEnum.Healthy:
      return {
        background: palette.green[100],
        fillColor: palette.green[900],
        tile: palette.green[700],
      };
    case ResourceStatusEnum.Unhealthy:
      return {
        background: palette.pink[200],
        fillColor: palette.pink[700],
        tile: palette.pink[600],
      };
    case ResourceStatusEnum.Failed:
      return {
        background: palette.pink[100],
        fillColor: palette.pink[700],
        tile: palette.pink[600],
      };
    case ResourceStatusEnum.Unknown:
      return {
        background: palette.gray[100],
        fillColor: palette.gray[700],
        tile: palette.gray[500],
      };
    case ResourceStatusEnum.Warning:
      return {
        background: palette.yellow[200],
        fillColor: palette.orange[800],
        tile: palette.yellow[500],
      };
    default:
      return {
        background: palette.gray[100],
        fillColor: palette.gray[500],
        tile: palette.gray[700],
      };
  }
};
export const StatusTag: React.FC<{
  status: ResourceStatusEnum;
  children: React.ReactNode;
  size?: TypographySize;
  tagVariant?: TagVariant;
  typographyVariant?: TypographyVariant;
}> = ({
  status,
  size = "medium",
  children,
  typographyVariant = "title",
  tagVariant = "square",
}) => (
  <Tag
    color={getColorByStatus(status).background}
    data-e2e-selector="serviceHeader-label-health"
    variant={tagVariant}
  >
    <Typography
      bold
      color={getColorByStatus(status).fillColor}
      variant={typographyVariant}
      size={size}
    >
      {children}
    </Typography>
  </Tag>
);
