import React, { createContext, PropsWithChildren, useContext } from "react";

import { LocalStorageItem } from "../utils/localStorageSettings";
import { EXPLORE_SERVICES_FILTERS_KEY } from "../config/urlSearchParamsKeys";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedServicesViewQS = new LocalStorageItem(
  EXPLORE_SERVICES_FILTERS_KEY
);

const ServicesPageFiltersLocalStorageContext =
  createContext<LocalStorageItem>(storedServicesViewQS);

export const ServicesPageFiltersLocalStorageProvider: React.FC<
  PropsWithChildren
> = ({ children }) => (
  <ServicesPageFiltersLocalStorageContext.Provider value={storedServicesViewQS}>
    {children}
  </ServicesPageFiltersLocalStorageContext.Provider>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useServicesPageFiltersLocalStorageContext = (): LocalStorageItem =>
  useContext(ServicesPageFiltersLocalStorageContext);
