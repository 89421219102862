import React, { ReactNode } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectInsightsDrawer } from "../../../store/overviewPageStoreSelectors";
import { IconProps } from "../../../types/overviewPageTypes";
import {
  DynamicContentContainer,
  InsightCardTitle,
  InsightContainer,
  StyledArrowIcon,
  StyledIconContainer,
  StyledInsightCardTitle,
} from "../../../../../../../../Insights/InsightsCard";
import { INSIGHT_CARD_ID } from "../../insightsConstants";

import {
  InsightCardSkeleton,
  SkeletonFormation,
} from "./InsightCardSkeleton/InsightCardSkeleton";

const ClickCaptureContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  && {
    pointer-events: auto;
  }
`;

const StyledInsightContainer = styled(InsightContainer)`
  & > * {
    pointer-events: none;
  }
`;

type InsightCardProps = {
  id: string;
  title: string;
  icon: React.FC<IconProps>;
  description: string;
  loading: boolean;
  loadingSkeletonFormation: SkeletonFormation;
  onClick?: () => void;
  children?: ReactNode;
  ariaLabel?: string;
};

export const InsightCard: React.FC<InsightCardProps> = ({
  id,
  title,
  icon,
  description,
  onClick,
  children,
  ariaLabel,
  loading,
  loadingSkeletonFormation,
}) => {
  const { insightsDrawerContent } =
    useOverviewPageStore(selectInsightsDrawer) || {};

  const { id: selectedId } = insightsDrawerContent || {};

  const Icon = icon;

  const loadingContent = (
    <InsightCardSkeleton formation={loadingSkeletonFormation} />
  );

  const content = (
    <>
      <div>
        <InsightCardTitle>
          <StyledIconContainer>
            <Icon />
          </StyledIconContainer>
          <StyledInsightCardTitle
            variant={"overline2"}
            color={muiColors.gray[500]}
          >
            {title}
          </StyledInsightCardTitle>
        </InsightCardTitle>
        <Typography variant={"h5"}>{description}</Typography>
        {children && (
          <DynamicContentContainer>{children}</DynamicContentContainer>
        )}
      </div>
      <StyledArrowIcon />
    </>
  );

  const label = !loading
    ? `insight card for ${ariaLabel}`
    : `loading content for ${ariaLabel}`;

  return (
    <StyledInsightContainer
      onClick={onClick}
      selected={selectedId === id}
      $loading={loading}
      {...(ariaLabel && { "aria-label": label })}
    >
      {loading ? loadingContent : content}
      <ClickCaptureContainer id={INSIGHT_CARD_ID} />
    </StyledInsightContainer>
  );
};
