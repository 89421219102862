import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { INFRA_HEALTH, WORKLOAD_HEALTH } from "@/components/routes/routes";

export const HealthType = {
  WORKLOAD: "workload",
  INFRA: "infrastructure",
  NOT_SPLIT: "not-split-reliability",
} as const;

export type HealthType = (typeof HealthType)[keyof typeof HealthType];

export const useHealthType = (): HealthType => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (pathname.includes(WORKLOAD_HEALTH)) return HealthType.WORKLOAD;
    if (pathname.includes(INFRA_HEALTH)) return HealthType.INFRA;

    return HealthType.NOT_SPLIT;
  }, [pathname]);
};

export const useIsOldReliability = (): boolean => {
  return useHealthType() === HealthType.NOT_SPLIT;
};
