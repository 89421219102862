import React from "react";
import styled from "styled-components";

import {
  defaultOptions as timeframes,
  defaultLabels as timeframeLabels,
} from "../../../../../common/DateTimeSelector";
import {
  Timeframe,
  TimeWindowProps,
} from "../../../../../../shared/types/TimeWindow";
import Komo from "../../../../../../shared/assets/komo.svg?react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5rem 0.5rem;
  width: 20.3rem;
  margin: auto;
  color: #3d4048;
`;

const BigText = styled.span`
  font-weight: bold;
  font-size: 1.25rem;
`;

const SmallText = styled.span`
  font-weight: 500;
  font-size: 0.85rem;
`;

const LinkText = styled.span`
  color: #007aff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const EmptyState: React.FC<TimeWindowProps> = ({
  timeWindow,
  setTimeWindow,
}) => {
  const currentTimeframeIndex = timeframes.findIndex(
    (tf) => timeWindow.timeframe === tf
  );

  return (
    <Container>
      <Komo />
      <BigText data-e2e-selector="no-events-title">There are no events</BigText>
      <SmallText>
        Try adjusting your filters to have events displayed or{" "}
        {timeframes[currentTimeframeIndex] === Timeframe.Last7Days ||
        timeframes[currentTimeframeIndex] === Timeframe.Custom ? (
          "changing your time range to a custom time range"
        ) : (
          <LinkText
            onClick={() =>
              setTimeWindow({
                timeframe: timeframes[currentTimeframeIndex + 1],
              })
            }
          >
            changing your time range to the{" "}
            {timeframeLabels[
              timeframes[currentTimeframeIndex + 1]
            ]?.toLowerCase()}
          </LinkText>
        )}
        .
      </SmallText>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EmptyState;
