import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  GraphTitleContainer,
  PaddedCard,
} from "../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectAllocationFilters,
  selectAllocationMetricsState,
} from "../../../store/costOptimizationStoreSelectors";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { FetchError } from "../../shared/errors/FetchError";
import { CenteredLoader } from "../../../../../shared/components/CenteredLoader";

import { AllocationStackedBarChart } from "./AllocationStackedBarChart/AllocationStackedBarChart";
import { getBarChartData } from "./utils/utils";

const ContentContainer = styled.div`
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { container: containerLabel } =
  costOptimizationAriaLabels.allocationPage.allocationGraph;

export const AllocationGraph: React.FC = () => {
  const { loading, data } = useCostOptimizationStore(
    selectAllocationMetricsState
  );

  const { timeWindow } = useCostOptimizationStore(selectAllocationFilters);

  const chartData = useMemo(
    () => getBarChartData(data, timeWindow),
    [data, timeWindow]
  );

  return (
    <PaddedCard aria-label={containerLabel}>
      <GraphTitleContainer>
        <Typography variant={"body2"}>Cost Allocation</Typography>
        <Typography variant={"body2"} color={muiColors.gray[600]}>
          (USD)
        </Typography>
      </GraphTitleContainer>
      <ContentContainer>
        {loading ? (
          <CenteredLoader />
        ) : chartData ? (
          <AllocationStackedBarChart
            data={chartData.data}
            chartKeys={chartData.chartKeys}
          />
        ) : (
          <FetchError />
        )}
      </ContentContainer>
    </PaddedCard>
  );
};
