import React, { useMemo, useState } from "react";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";

import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../shared/styles";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { getGenericServiceColumns } from "../../shared/components/InsightsDataTable/insightDataTableUtils";
import { InsightsDataTable } from "../../shared/components/InsightsDataTable/InsightsDataTable";
import { ServicesTableTitle } from "../../shared/components/InsightsDataTable/ServicesTableTitle";
import { ServiceColumn } from "../../shared/types";
import { IssuesTrendAllOfAffectedServicesInner } from "../../../../../../../../../generated/applicationInsights";
import { DataPointsBreakdownPerIntervals } from "../../shared/utils/getDataPointsBreakdownPerIntervals";
import { getGenericTableColumnRow } from "../../shared/utils/utils";

import { ViewRecentDeploysButton } from "./ViewRecentDeploysButton";
import {
  breakdownTitle,
  explanationBlock1,
  explanationBlock2,
  explanationBlock3,
  explanationTitle,
} from "./FailedDeploysTexts";
import { useBreakdownDataContent, useDrawerInfo } from "./failedDeplysHooks";

type ServiceColumnWithCount = ServiceColumn & {
  count: number;
};

const customServiceColumns =
  getGenericServiceColumns<ServiceColumnWithCount>().concat([
    {
      field: "count",
      headerName: "# failed (7d)",
      flex: 1.1,
      align: "center",
      maxWidth: 80,
    },
    {
      field: "actionButton",
      headerName: "",
      flex: 2,
      align: "right",
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <ViewRecentDeploysButton rowData={params.row} />
      ),
    },
  ]);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getServiceRowsWithCount = (
  columns: IssuesTrendAllOfAffectedServicesInner[]
): ServiceColumnWithCount[] => {
  return columns.map((column) => ({
    ...getGenericTableColumnRow(column),
    count: column.count,
  }));
};

type FailedDeploysModalContentProps = {
  dataPointsBreakdownPerIntervals?: DataPointsBreakdownPerIntervals;
};

export const FailedDeploysModalContent: React.FC<
  FailedDeploysModalContentProps
> = ({ dataPointsBreakdownPerIntervals }) => {
  const {
    failedDeployments: { data },
  } = useOverviewPageStore(selectAppInsightsData);
  const drawerInfo = useDrawerInfo(dataPointsBreakdownPerIntervals);

  const rows = useMemo(() => {
    if (!data?.affectedServices) return [];
    return getServiceRowsWithCount(data.affectedServices);
  }, [data]);

  const breakDownDataContent = useBreakdownDataContent({
    drawerInfo,
    dataPointsBreakdownPerIntervals,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "count",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  return (
    <div>
      <InsightsDrawerTitle>{explanationTitle}</InsightsDrawerTitle>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock1}
      </InsightsDrawerContent>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock2}
      </InsightsDrawerContent>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock3}
      </InsightsDrawerContent>
      <InsightsDrawerTitle>{breakdownTitle}</InsightsDrawerTitle>
      {breakDownDataContent}
      <ServicesTableTitle />
      <InsightsDataTable
        columns={customServiceColumns}
        rows={rows}
        props={{
          sortModel,
          onSortModelChange: onSortModelChange,
        }}
      />
    </div>
  );
};
