import { palette } from "@komodorio/design-system";
import { Handle, Position } from "react-flow-renderer";
import styled from "styled-components";

export const NodeTargetHandle = styled(Handle).attrs({
  type: "target",
  position: Position.Left,
  style: { pointerEvents: "none" },
})`
  background: ${palette.gray[500]};
  width: 3px;
  height: 8px;
  border-radius: unset;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;
