import React from "react";
import useClipboard from "react-use-clipboard";
import Button from "@mui/material/Button";

interface Props {
  yaml: string | undefined;
}

export const CopyYamlButton: React.FC<Props> = ({ yaml }) => {
  const [copied, copy] = useClipboard(yaml ?? "", { successDuration: 2_000 });

  return (
    <Button onClick={copy} disabled={copied || !yaml}>
      Copy YAML
    </Button>
  );
};
