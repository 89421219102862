import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";
import {
  MonitorsApiApiV1IssuesOverTimeGetRequest,
  OpenIssuesResponse,
  apiV1IssuesOverTimeGetUrl,
} from "../../../../../generated/monitorsApi";

export const ISSUES_OVER_TIME_PATH = "/issues-over-time";

const fetchIssuesOverTime = async (
  apiClient: AxiosInstance,
  params: MonitorsApiApiV1IssuesOverTimeGetRequest
): Promise<OpenIssuesResponse> => {
  const { data } = await apiClient.get(
    apiV1IssuesOverTimeGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetIssuesOverTime = (
  params: MonitorsApiApiV1IssuesOverTimeGetRequest,
  options?: { enabled?: boolean; staleTime?: number }
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [ISSUES_OVER_TIME_PATH, params],
    () => fetchIssuesOverTime(apiClient, params),
    options
  );
};
