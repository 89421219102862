import React from "react";
import { subDays } from "date-fns";

import {
  CheckType,
  ClusterProviderType,
} from "../../../../../../generated/reliabilityApi";
import {
  millisecondsToUnixTime,
  unixTimeToMilliseconds,
} from "../../../../../../shared/utils/timeUtils";
import { getCheckLabel } from "../../../../utils/getCheckLabel";
import { CloudProvider } from "../../../../../../shared/components/CloudProvider/CloudProvider";

import { CollapsibleContainerProps } from "./components/shared/metrics/components/ReliabilityCollapsibleContainer/ReliabilityCollapsibleContainer";

export const getClusterProviderIcon = (
  clusterProvider: ClusterProviderType
) => {
  return <CloudProvider clusterProviderType={clusterProvider} />;
};

type GetCollapsibleContainerProps = {
  index: number;
  checkType: CheckType;
  name: string;
};

type CollapsibleContainerOutput = Pick<
  CollapsibleContainerProps,
  "initialOpen" | "ariaLabel" | "containerName"
>;

export const getCollapsibleContainerProps = ({
  checkType,
  index,
  name,
}: GetCollapsibleContainerProps): CollapsibleContainerOutput => {
  const initialOpen = index === 0;
  const ariaLabel = `${getCheckLabel(checkType)} container ${name}`;

  return { initialOpen, ariaLabel, containerName: name };
};

export type TimeUnit = "seconds" | "milliseconds";
type EpochsFromLastEvaluatedAtOutput = {
  fromEpoch: number;
  toEpoch: number;
};

const days = 7;
export const getEpochsFromLastEvaluatedAt = (
  lastEvaluatedAtSeconds: number,
  epochsOutput: TimeUnit = "seconds"
): EpochsFromLastEvaluatedAtOutput => {
  const lastEvaluatedAtMs = unixTimeToMilliseconds(lastEvaluatedAtSeconds);
  const fromEpochMs = subDays(
    unixTimeToMilliseconds(lastEvaluatedAtMs),
    days
  ).getTime();

  if (epochsOutput === "milliseconds") {
    return {
      fromEpoch: fromEpochMs,
      toEpoch: lastEvaluatedAtMs,
    };
  }

  return {
    fromEpoch: millisecondsToUnixTime(fromEpochMs),
    toEpoch: lastEvaluatedAtSeconds,
  };
};

export const isCheckIsStatic = (checkType: CheckType | undefined) => {
  switch (checkType) {
    case CheckType.MissingPdb:
    case CheckType.MissingTopologySpreadConstraint:
    case CheckType.DeploymentMissingReplicas:
    case CheckType.HpaMinAvailability:
    case CheckType.ReadinessProbeMissing:
    case CheckType.PriorityClassNotSet:
    case CheckType.MissingHpa:
    case CheckType.CpuLimitsMissing:
    case CheckType.LivenessProbeMissing:
    case CheckType.MemoryRequestsMissing:
    case CheckType.MemoryLimitsMissing:
    case CheckType.CpuRequestsMissing:
      return true;
    default:
      return false;
  }
};
