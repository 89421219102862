import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { useTransformValuesAndSubmit } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useTransformValuesAndSubmit";
import { useCanSubmitForm } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useCanSubmitForm";

export const CreatePolicyButton: React.FC = () => {
  const { isSubmittingForm } = usePolicyDrawerContext();
  const canSubmitForm = useCanSubmitForm();

  const transformFormValuesAndSubmit = useTransformValuesAndSubmit();

  const onCreatePolicyClick = () => {
    transformFormValuesAndSubmit("create");
  };

  return (
    <LoadingButton
      variant={"contained"}
      color={"primary"}
      disabled={!canSubmitForm}
      loading={isSubmittingForm}
      onClick={onCreatePolicyClick}
      aria-label={"Create Reliability Policy"}
    >
      Create Reliability Policy
    </LoadingButton>
  );
};
