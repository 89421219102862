import React, { useMemo, useState } from "react";

import { useIsElementInViewport } from "../../../../../../shared/hooks/useIntersectionObserver";
import { useReportEagerLoadingState } from "../../../../../../shared/hooks/datadog-rum/useReportEagerLoadingState";

type ReportDescribeTabToDatadogParams = {
  viewName: string;
  divRef: React.RefObject<HTMLDivElement>;
  hasOwnerResources?: boolean;
  resourceId: string;
};
export const useReportDescribeTabToDatadog = ({
  viewName,
  divRef,
  hasOwnerResources,
  resourceId,
}: ReportDescribeTabToDatadogParams) => {
  const isDisplayed = useIsElementInViewport({ ref: divRef });
  const [ownerResourcesLoading, setOwnerResourcesLoading] = useState(true);

  const stateParams = useMemo(
    () => ({
      viewName,
      isDisplayed,
      loadingState: {
        resource: !resourceId,
        ...(hasOwnerResources && { ownerResources: ownerResourcesLoading }),
      },
    }),
    [
      hasOwnerResources,
      isDisplayed,
      ownerResourcesLoading,
      resourceId,
      viewName,
    ]
  );

  useReportEagerLoadingState(stateParams);

  return { setOwnerResourcesLoading };
};
