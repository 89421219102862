import React from "react";

import { SelfSupplyingResourceListTableProps } from "../types";

import { PodsListTableAtm } from "./PodsListTables";

type PodsListTableProps = SelfSupplyingResourceListTableProps & {
  agentId?: string;
  showDeletedPods?: boolean;
};

export const PodsListTable: React.FC<PodsListTableProps> = ({
  agentId,
  cluster,
  ...props
}) => {
  agentId = agentId ?? "";
  return <PodsListTableAtm agentId={agentId} cluster={cluster} {...props} />;
};
