import React from "react";
import { Tooltip } from "react-tooltip";

import { ZIndex } from "../../../../../constants/zIndex";

interface TooltipWrapperProps {
  tooltipId: string;
  setIsTooltipOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  position: "top" | "right" | "bottom" | "left";
}

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  tooltipId,
  setIsTooltipOpen,
  children,
  position,
}) => (
  <div
    onMouseEnter={() => setIsTooltipOpen(true)}
    onMouseLeave={() => setIsTooltipOpen(false)}
  >
    <Tooltip
      delayHide={200}
      noArrow
      id={tooltipId}
      variant="light"
      place={position}
      positionStrategy="fixed"
      style={{
        zIndex: ZIndex.AboveModal,
        pointerEvents: "auto",
      }}
    >
      {children}
    </Tooltip>
  </div>
);
