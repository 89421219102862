import { useCallback, useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { useActiveAgent } from "../useAgents";
import { useBuildKomodorUidForKomodorService } from "../resources-api/resourcesAPIUtils";
import { useResourcesAPIPost } from "../resources-api/client";
import {
  INGRESS_STATE,
  KOMODOR_SERVICES_RELATED_RESOURCES,
} from "../resources-api/requestResponseMaps";
import { generateUid } from "../../utils/generateUid";
import { IngressState } from "../../../generated/resourcesApi";

export const useIngressResources = (
  serviceId: string | undefined,
  cluster: string | undefined
): {
  ingresses: ResourceTableModelRow[];
  refresh: () => void;
  isLoading?: boolean;
  error?: string;
} => {
  const uid = useBuildKomodorUidForKomodorService(serviceId ?? "");
  const {
    data: ingressesKomodorUids,
    refresh: refreshFromAPI,
    loading: loadingFromRelatedResources,
    error: errorFromRelatedResources,
  } = useResourcesAPIPost(
    KOMODOR_SERVICES_RELATED_RESOURCES,
    {
      fields: ["IngressUids"],
      komodorUids: [uid ?? ""],
    },
    !uid
  );

  const agentId = useActiveAgent(cluster);

  const {
    data: rawIngresses,
    loading: loadingFromIngressState,
    error: errorFromIngressState,
    refresh: refreshIngressState,
  } = useResourcesAPIPost(
    INGRESS_STATE,
    {
      komodorUids: ingressesKomodorUids?.IngressUids ?? [],
      agentId: agentId ?? "",
      fields: ["wide"],
    },
    !ingressesKomodorUids || !agentId
  );

  const ingresses = useMemo(
    () =>
      rawIngresses?.data?.wide?.map((ingress) =>
        convertApiDataIntoRow(ingress, cluster ?? "")
      ) ?? [],
    [cluster, rawIngresses?.data?.wide]
  );

  const refresh = useCallback(() => {
    refreshFromAPI();
    refreshIngressState();
  }, [refreshFromAPI, refreshIngressState]);

  const isLoading = loadingFromRelatedResources || loadingFromIngressState;
  const error = errorFromRelatedResources ?? errorFromIngressState ?? undefined;

  return {
    ingresses,
    refresh,
    isLoading,
    error,
  };
};

const convertApiDataIntoRow = (
  ingress: IngressState,
  cluster: string
): ResourceTableModelRow => {
  const { name, namespace } = ingress;
  return {
    uid: generateUid({ name, namespace: namespace ?? "", cluster }),
    name,
    namespace: namespace ?? "",
  };
};
