import { useEffect, useMemo, useState } from "react";

import {
  LastAgentInfo,
  generateMockAgentsInfo,
} from "../../../test/mockData/generateMockAgentsInfo";
import { useAppViewsResourcesStore } from "../../store/appViewsResourcesStore/appViewsResourcesStore";
import { selectClusters } from "../../store/appViewsResourcesStore/appViewsResourcesStoreSelectors";

import { ClusterData, useGetAllClusters } from "./useGetAllClusters";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";

const useGetMockClusterOptions = (isDevMode?: boolean) => {
  const [mockActiveAgents, setMockActiveAgents] = useState<LastAgentInfo[]>([]);

  useEffect(() => {
    const fetchMockActiveAgents = async () => {
      const mockActiveAgents = await generateMockAgentsInfo(10, 1000);
      setMockActiveAgents(mockActiveAgents);
    };

    if (isDevMode) {
      fetchMockActiveAgents();
    }
  }, [isDevMode]);

  return mockActiveAgents;
};

// get cluster options filtered by app view
// use mock data in devMode
export const useClusterOptionsByAppView = (
  isDevMode?: boolean
): ClusterData[] => {
  const clusterOptions = useGetAllClusters({ shouldUpdate: false });
  const mockActiveAgents = useGetMockClusterOptions(isDevMode);
  const appViewClusters = useAppViewsResourcesStore(selectClusters);
  const { resolvedClusterWorkspace, resolvedClustersGroupWorkspace } =
    useWorkspaces();

  const mockClusterOptions: ClusterData[] = useMemo(
    () =>
      mockActiveAgents.map((agent) => ({
        clusterName: agent.clusterName,
        version: agent.configuration.appmetadata?.version,
        daemonSetEnabled: agent.configuration.daemon?.enabled,
      })),
    [mockActiveAgents]
  );

  return useMemo(() => {
    const res = !isDevMode ? clusterOptions : mockClusterOptions;
    if (resolvedClusterWorkspace) {
      const found = res.find(
        (c) => c.clusterName === resolvedClusterWorkspace.value.clusterName
      );
      return [found].filter(Boolean) as ClusterData[];
    }

    if (resolvedClustersGroupWorkspace) {
      const clusters = resolvedClustersGroupWorkspace.value.clusters ?? [];
      if (clusters.length === 0) {
        return res;
      }
      return res.filter((c) => clusters.includes(c.clusterName));
    }

    if (appViewClusters.size > 0 && !isDevMode) {
      return res.filter((cluster) => appViewClusters.has(cluster.clusterName));
    }

    return res;
  }, [
    appViewClusters,
    clusterOptions,
    isDevMode,
    mockClusterOptions,
    resolvedClusterWorkspace,
    resolvedClustersGroupWorkspace,
  ]);
};
