import { PodLogsDirection, PodLogsStore } from "./types";

// currently the store of the live logs only, but once we deprecated the old logs so the name will be OK
export const initialState: PodLogsStore = {
  logs: [],
  direction: PodLogsDirection.NewestFirst, // TODO: Yossi to change that to be the opposite
  resetLogs: () => undefined,
  addLogs: () => undefined,
  setDirection: () => undefined,
};
