import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest } from "auth-api";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacCustomK8sActionsGetUrl,
  RbacCustomK8sAction,
  RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest,
} from "../../../../../generated/auth";

const RBAC_POLICY_ACTIONS_PATH = "/rbac-custom-k8s-actions";

const fetchRbacCustomK8sActions = async (
  apiClient: AxiosInstance,
  params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsGetRequest = {}
): Promise<RbacCustomK8sAction[]> => {
  const { data } = await apiClient.get(
    apiV1RbacCustomK8sActionsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacCustomK8sActions = (
  params: RbacPolicyActionsApiApiV1RbacPolicyActionsGetRequest = {},
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_POLICY_ACTIONS_PATH],
    () => fetchRbacCustomK8sActions(apiClient, params),
    {
      enabled,
    }
  );
};
