import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";
import { SectionTitle } from "../../../shared/SectionTitle/SectionTitle";

import { GroupBySelector } from "./GroupBySelector";
import { TotalViolations } from "./TotalViolations";
import { ViolationTableWithContext } from "./ViolationTableWithContext/ViolationTableWithContext";
import { GroupedViolations } from "./GroupedViolations";
import { TimeWindowSelector } from "./TimeWindowSelector";

import { useIsOldReliability } from "@/components/reliability/hooks/useHealthType";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const TOOLTIP_TEXT =
  "These risks are monitored at intervals of up to a day, capturing patterns and trends that may signal potential issues. This approach helps identify recurring problems or gradual changes that could compromise the long-term health and stability of your environment";

const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledViolationTable = styled(ViolationTableWithContext)`
  padding: 16px;
  background: white;
  border-radius: 4px;
`;

const SelectorsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const violationTableUid = uuidv4();

const {
  sections: { reliabilityRisks: reliabilityRisksSectionAriaLabel },
} = reliabilityArialLabels;

export const ViolationsBreakdown: React.FC = () => {
  const groupBy = useGetGroupBy();
  const isOldReliability = useIsOldReliability();

  return (
    <div aria-label={reliabilityRisksSectionAriaLabel}>
      {!isOldReliability && (
        <SectionTitle title="Reliability Risks" tooltipText={TOOLTIP_TEXT} />
      )}
      <TopSectionContainer>
        <TotalViolations />
        <SelectorsContainer>
          <GroupBySelector />
          {isOldReliability && <TimeWindowSelector />}
        </SelectorsContainer>
      </TopSectionContainer>
      {groupBy === "None" ? (
        <StyledViolationTable
          violationsGroupBy="None"
          uid={violationTableUid}
        />
      ) : (
        <GroupedViolations />
      )}
    </div>
  );
};
