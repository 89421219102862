import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";

import { ErrorMessageModal } from "./ErrorMessageModal";

import { ResourceStatusEnum } from "@/components/ResourceView/headers/common";

const Container = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
`;

const StyledCheckCircle = styled(CheckCircle)`
  && {
    color: ${muiColors.green[700]};
  }
`;

interface Props {
  description: string | undefined;
  status: ResourceStatusEnum;
}

export const Description: React.FC<Props> = ({ description, status }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  if (
    (status !== ResourceStatusEnum.Healthy &&
      status !== ResourceStatusEnum.Failed) ||
    !description
  )
    return null;

  return (
    <Container>
      {status === ResourceStatusEnum.Healthy ? (
        <>
          <StyledCheckCircle fontSize="small" />
          <Typography variant="body2">{description}</Typography>
        </>
      ) : (
        <>
          <Button
            color="error"
            startIcon={<ErrorOutline />}
            onClick={() => setShowErrorModal(true)}
          >
            View error message
          </Button>
          {showErrorModal && (
            <ErrorMessageModal
              isOpen={showErrorModal}
              onClose={() => setShowErrorModal(false)}
              description={description}
            />
          )}
        </>
      )}
    </Container>
  );
};
