import { useMemo } from "react";
import { groupBy } from "lodash";

import { useGetPodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetPodEvents";
import { PodEventsResponseDataInner } from "../../../../generated/resourcesApi";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";

const PODS_BATCH = 20;

export interface PodsUidsPage {
  podsUids: string[];
  firstEventTime: Date;
  lastEventTime: Date;
}

export const useGetPodsBatchesFromOwnerUid = (
  uid: string | undefined,
  cluster: string,
  namespace: string,
  fromEpoch?: Date,
  toEpoch?: Date
): [PodsUidsPage[] | undefined, boolean] => {
  const { data: eventsFromAPI, isLoading } = useGetPodEvents(
    {
      ownerUid: uid,
      fields: ["podName", "eventTime"],
      fromEpoch: fromEpoch?.getTime().toString(),
      toEpoch: toEpoch?.getTime().toString(),
      order: "ASC",
    },
    !!uid && !!fromEpoch && !!toEpoch
  );

  return useMemo(
    () => [
      eventsFromAPI
        ? podsBatchesByEventTime(
            eventsFromAPI.data,
            cluster,
            namespace,
            toEpoch
          )
        : undefined,
      isLoading,
    ],
    [cluster, eventsFromAPI, isLoading, namespace, toEpoch]
  );
};

export const podsBatchesByEventTime = (
  pods: PodEventsResponseDataInner[],
  cluster: string,
  namespace: string,
  toEpoch?: Date
): PodsUidsPage[] => {
  const groupedPodsByName = groupBy(pods, (pod) => pod.podName);
  const pages: PodsUidsPage[] = [];
  const podsNames = [...new Set(pods.flatMap((e) => e.podName as string))];

  for (let i = 0; i < podsNames.length; i += PODS_BATCH) {
    const podsNamesForPage = podsNames.slice(i, i + PODS_BATCH);
    const podsUidsForPage = podsNamesForPage.map((podName) =>
      buildKomodorUid({
        clusterName: cluster,
        namespace: namespace,
        kind: KubernetesPodsResource.Kind,
        resourceName: podName,
      })
    );
    const firstEventTime = new Date(
      groupedPodsByName[podsNamesForPage[0]][0].eventTime ?? ""
    );
    const lastEventTime = toEpoch ?? new Date();
    pages.push({ podsUids: podsUidsForPage, firstEventTime, lastEventTime });
  }
  return pages;
};
