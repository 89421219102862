import { useEffect, useState } from "react";

import { ClustersConnectionsStatus } from "../electronApiContext";
import isElectronContext from "../isElectronContext";

export const useDesktopClustersConnection =
  (): ClustersConnectionsStatus | null => {
    const [clustersConnectionState, setClustersConnectionState] =
      useState<ClustersConnectionsStatus | null>(null);

    // handle the state when reloading the page
    useEffect(() => {
      if (!isElectronContext()) return;
      // check if the function is available
      if (window.electronAPI?.getClustersConnectionEncoded === undefined)
        return;
      const fetchClustersConnection = async () => {
        const initClustersConnectionEncoded =
          await window.electronAPI?.getClustersConnectionEncoded();
        if (
          clustersConnectionState === null &&
          initClustersConnectionEncoded !== undefined
        ) {
          const initClustersConnection = JSON.parse(
            initClustersConnectionEncoded
          );
          setClustersConnectionState(initClustersConnection);
        }
      };
      fetchClustersConnection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!isElectronContext()) return;
      window.electronAPI?.updateDesktopClustersConnection(
        (_: unknown, clustersConnectionEncoded: string) => {
          const isDepplyEqual =
            JSON.stringify(clustersConnectionState) ===
            clustersConnectionEncoded;
          if (isDepplyEqual) return;
          setClustersConnectionState(JSON.parse(clustersConnectionEncoded));
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return clustersConnectionState;
  };
