import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import { CostOptimizationConfigUpdateState } from "../../types/costOptimizationTypes";

import { useResetUpdateConfigState } from "./costOptimizationSettingsHooks";
import { UPDATE_CONFIG_ERROR_MESSAGE } from "./constants";

const StyledTopSection = styled.div`
  background: white;
  padding: 32px;
  border-top: 1px solid ${muiColors.gray[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

type SettingsFooterProps = {
  onCancel: () => void;
  state: CostOptimizationConfigUpdateState;
  enableSubmit: boolean;
};
const StyledTypography = styled(Typography)<{ opacity: number }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};
`;

export const SettingsFooter: React.FC<SettingsFooterProps> = ({
  state,
  onCancel,
  enableSubmit,
}) => {
  const resetUpdateConfigState = useResetUpdateConfigState();
  const [hasError, setHasError] = useState(false);

  const { loading, error } = state;

  const resetErrorState = useCallback(() => {
    setHasError(false);
    resetUpdateConfigState();
  }, [resetUpdateConfigState]);

  useEffect(() => {
    if (!error) {
      return;
    }
    setHasError(true);
    const timeoutId = setTimeout(() => {
      resetErrorState();
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [error, resetErrorState]);

  useEffect(() => {
    return () => {
      resetErrorState();
    };
  }, [resetErrorState]);

  return (
    <StyledTopSection>
      <StyledTypography color={"error"} opacity={hasError ? 1 : 0}>
        {UPDATE_CONFIG_ERROR_MESSAGE}
      </StyledTypography>
      <ButtonsContainer>
        <Button variant={"text"} size={"large"} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant={"contained"}
          size={"large"}
          type={"submit"}
          loading={loading}
          disabled={!enableSubmit}
        >
          Apply Changes
        </LoadingButton>
      </ButtonsContainer>
    </StyledTopSection>
  );
};
