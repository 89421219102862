import { useCallback } from "react";

import { useOverviewPageStore } from "../store/overviewPageStore";
import { selectOpenEventItemDrawer } from "../store/overviewPageStoreSelectors";
import WorkflowIssueEventGroup from "../../../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";

export const useOpenIssueInEventItemDrawer = () => {
  const openEventItemDrawer = useOverviewPageStore(selectOpenEventItemDrawer);

  return useCallback(
    (workflowIssue: WorkflowIssueEventGroup) => {
      openEventItemDrawer(workflowIssue);
    },
    [openEventItemDrawer]
  );
};
