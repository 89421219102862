import React, { useEffect, useMemo, useRef } from "react";
import { ContainerStatus } from "kubernetes-types/core/v1";

import { useMetricsPerType } from "../../../Metrics/useMetrics";
import {
  METRICS_CONTAINER_CPU_ENDPOINT,
  METRICS_CONTAINER_MEMORY_ENDPOINT,
} from "../../../../shared/hooks/metrics-api/requestResponseMaps";

import {
  MetricsPerTypeParams,
  useTransformSingleMetricData,
} from "./hooks/metricsTabHooks";
import { useMetricsTabContext } from "./context/MetricsTabContext";
import { podContainersType } from "./types";
import { usePodContainersRequestStatusContext } from "./context/PodContainersRequestStatusContext";

type ContainerMetricsFetcherProps = {
  metricsParams: MetricsPerTypeParams;
  type: podContainersType;
};

export const ContainerMetricsFetcher: React.FC<
  ContainerMetricsFetcherProps
> = ({ metricsParams, type }) => {
  const { timeWindow, setPodContainersMemoryUsage, setPodContainersCpuUsage } =
    useMetricsTabContext();
  const { updatePodContainerState } = usePodContainersRequestStatusContext();

  const hasUpdatedMetrics = useRef(false);

  const endpoint =
    type === "memory"
      ? METRICS_CONTAINER_MEMORY_ENDPOINT
      : METRICS_CONTAINER_CPU_ENDPOINT;

  const { metrics, loading, error } = useMetricsPerType({
    ...metricsParams,
    endpoint,
    pause: hasUpdatedMetrics.current,
    type: "usage",
  });

  const transformedData = useTransformSingleMetricData({
    metricsData: metrics ?? [],
    timeWindow: timeWindow.timeframe,
    name: metricsParams.containerName,
  });

  useEffect(() => {
    updatePodContainerState(type, {
      [metricsParams.containerName]: {
        isLoading: loading,
        hasError: !!error,
      },
    });
  }, [
    error,
    loading,
    metricsParams.containerName,
    type,
    updatePodContainerState,
  ]);

  useEffect(() => {
    if (hasUpdatedMetrics.current || !metrics?.length) {
      return;
    }
    hasUpdatedMetrics.current = true;

    const updaterFunc =
      type === "memory"
        ? setPodContainersMemoryUsage
        : setPodContainersCpuUsage;

    updaterFunc(transformedData, metricsParams.containerName);
  }, [
    transformedData,
    metrics,
    metricsParams.containerName,
    setPodContainersCpuUsage,
    setPodContainersMemoryUsage,
    type,
    loading,
    error,
  ]);

  useEffect(() => {
    hasUpdatedMetrics.current = false;
  }, [timeWindow]);

  return null;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useContainersMetricsFetchers = (
  containerStatuses: ContainerStatus[] | undefined,
  metricsParams: MetricsPerTypeParams
) =>
  useMemo(
    () =>
      containerStatuses?.map((container) => {
        const params = { ...metricsParams, containerName: container.name };
        return (
          <React.Fragment key={container.name}>
            <ContainerMetricsFetcher
              type={"memory"}
              metricsParams={params}
              key={`memory-${container.name}`}
            />
            <ContainerMetricsFetcher
              type={"cpu"}
              metricsParams={params}
              key={`cpu-${container.name}`}
            />
          </React.Fragment>
        );
      }),
    [containerStatuses, metricsParams]
  );
