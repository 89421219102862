export const mockContainerResources = () => [
  {
    name: "container-name-2",
    resources: {
      limits: { cpu: "1", memory: "1Gi" },
      requests: { cpu: "1", memory: "1Gi" },
    },
  },
  {
    name: "container-name-1",
    resources: {},
  },
  {
    name: "container-name-0",
    resources: {
      requests: { cpu: "1", memory: "1Gi" },
    },
  },
];
