import { useCanIDoInPartialScope } from "../../../shared/hooks/auth-service/client/auth/useCanIDoInPartialScope";
import { useCanIDoInScope } from "../../../shared/hooks/auth-service/client/auth/useCanIDoInScope";

export const useCanViewCluster = (clusterName: string) => {
  const data = useCanIDoInPartialScope(
    {
      action: "view:all",
      scope: [{ cluster: clusterName }],
    },
    { enabled: !!clusterName }
  );

  return { ...data, data: data.data?.canDo };
};

export const useCanViewFullCluster = (clusterName: string) => {
  const data = useCanIDoInScope(
    {
      action: "view:all",
      scope: [{ cluster: clusterName }],
    },
    { enabled: !!clusterName }
  );

  return { ...data, data: data.data?.canDo };
};

export const useCanViewClustersList = (options?: { enabled: boolean }) => {
  const data = useCanIDoInPartialScope(
    {
      action: "view:all",
      scope: [{ cluster: "*" }],
    },
    options
  );

  return { ...data, data: data.data?.canDo };
};

export const useCanViewFullClustersList = () => {
  const data = useCanIDoInScope({
    action: "view:all",
    scope: [{ cluster: "*" }],
  });

  return { ...data, data: data.data?.canDo };
};
