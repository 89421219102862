import { filterCategoryValues } from "./filtersStateHandlerConstants";

import {
  GenericViolationFilterCategory,
  ViolationCountBy,
  ViolationFilterOperator,
  ViolationsApiGetViolationsRequest,
} from "@/generated/reliabilityApi";

const filterCategoryToUse = (
  filterCategory: keyof ViolationsApiGetViolationsRequest
): {
  filterCategory: ViolationCountBy;
  operator: ViolationFilterOperator;
} => {
  let operator: ViolationFilterOperator = "include",
    category = filterCategory as ViolationCountBy;

  if (filterCategory === "createdFromEpoch") {
    category = "createdAt";
    operator = ViolationFilterOperator.GreaterThan;
  }

  if (filterCategory === "createdToEpoch") {
    category = "createdAt";
    operator = ViolationFilterOperator.LessThan;
  }
  if (filterCategory === "shortResourceNameSearchTerm") {
    category = "shortResourceName";
    operator = ViolationFilterOperator.Like;
  }

  return {
    filterCategory: category,
    operator,
  };
};

export const violationRequestParamsAsGenericViolationFilterCategory = (
  obj: ViolationsApiGetViolationsRequest
) => {
  return Object.entries(obj).reduce<GenericViolationFilterCategory[]>(
    (acc, [category, filter]) => {
      const { filterCategory, operator } = filterCategoryToUse(
        category as keyof ViolationsApiGetViolationsRequest
      );

      const isFilterCategory = filterCategoryValues.includes(filterCategory);
      if (!isFilterCategory || !filter || filter.length === 0) {
        return acc;
      }

      const values = Array.isArray(filter) ? filter : [filter];
      acc.push({
        category: filterCategory as ViolationCountBy,
        operator,
        value: values,
      });

      return acc;
    },
    []
  );
};
