import { useMemo } from "react";

import { useCostOptimizationStore } from "../store/costOptimizationStore";
import { selectSharedCostFilters } from "../store/costOptimizationStoreSelectors";
import { ApiV1CostAllocationOverTimeGetScopeParameter } from "../../../generated/metricsApi";

export const useScopeAsServerRequestParam =
  (): ApiV1CostAllocationOverTimeGetScopeParameter => {
    const { scope } = useCostOptimizationStore(selectSharedCostFilters);

    return useMemo(
      () => ({
        clusters: scope.clusters.map((cluster) => cluster.clusterName),
      }),
      [scope]
    );
  };
