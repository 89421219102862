import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { muiColors } from "@komodorio/design-system";

import {
  POLICIES_TABLE_LOADING_STATE_ROWS,
  POLICIES_TABLE_ROW_HEIGHT,
} from "./policiesTableConstants";

const Container = styled.div<{ $height: number }>`
  position: absolute;
  top: ${POLICIES_TABLE_ROW_HEIGHT}px;
  left: 0;
  box-sizing: border-box;
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${muiColors.gray[300]};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

type NoResultsFoundProps = {
  onClearSearchTerm?: () => void;
};

export const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  onClearSearchTerm,
}) => {
  const height = POLICIES_TABLE_LOADING_STATE_ROWS * POLICIES_TABLE_ROW_HEIGHT;

  return (
    <Container $height={height}>
      <FlexContainer>
        <Typography variant={"body1"}>No results found.</Typography>
        <Button variant={"text"} onClick={onClearSearchTerm}>
          Clear search
        </Button>
      </FlexContainer>
    </Container>
  );
};
