import React, { memo } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";

import { useGetTopAvailabilityIssues } from "../../../../../shared/hooks/monitors-api/client/issues/useGetTopAvailabilityIssues";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { EmptyState } from "../../../../common/UpgradeAgentVersionAlert/EmptyState";
import { useStoreIsFetchingForReport } from "../../useReportClusterOverviewLoadingTime";

import { IssueRow, IssueRowSkeleton } from "./IssueRow";
import { BOX_HEIGHT } from "./constants";

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, auto) minmax(10px, auto) minmax(
      10px,
      min-content
    );
  grid-template-rows: auto;
  column-gap: 24px;
`;

const TopIssuesTableLoader = memo(
  ({ numOfIssues }: { numOfIssues: number }) => (
    <span
      aria-label={
        AriaLabels.ClusterOverview.UnhealthyServicesSection.IssuesTableLoader
      }
    >
      {new Array(numOfIssues).fill(null).map((_, i) => (
        <IssueRowSkeleton key={i} />
      ))}
    </span>
  )
);

const StyledIssueRow = styled(IssueRow)`
  display: grid;
  grid-template-columns: subgrid;
`;

interface TopIssuesTable {
  clusters: string[];
  isSingleCluster: boolean;
  shouldShowLoader?: boolean;
}
export const TopIssuesTable: React.FC<TopIssuesTable> = ({
  clusters,
  isSingleCluster,
  shouldShowLoader = false,
}) => {
  const { data, isLoading } = useGetTopAvailabilityIssues(
    {
      clusterNames: clusters,
      count: 3,
    },
    { staleTime: 0 }
  );
  useStoreIsFetchingForReport("isFetchingTopUnhealthyServices", isLoading);

  const topAvailabilityIssues = data?.data;

  if (isLoading || shouldShowLoader) {
    return <TopIssuesTableLoader numOfIssues={3} />;
  }

  if (!topAvailabilityIssues?.length) {
    return (
      <Box sx={{ height: BOX_HEIGHT }}>
        <EmptyState title="This data is unavailable" />
      </Box>
    );
  }

  return (
    <Container>
      {topAvailabilityIssues.map((issue) => (
        <StyledIssueRow
          key={issue.id}
          issue={issue}
          showClusterName={!isSingleCluster}
        />
      ))}
    </Container>
  );
};
