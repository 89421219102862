import { muiColors, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

interface Props {
  message: string;
  children?: React.ReactNode;
}

const LogDivider = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  colunm-gap: 0.25rem;

  &::before,
  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid ${theme.divider};
    border-bottom-color: ${muiColors.orange[500]};

    margin: auto;
  }
`;

const Text = styled(Typography).attrs({
  variant: "body1",
})`
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${muiColors.orange[500]};
`;

export const LogsDivider: React.FC<Props> = ({ message }) => {
  return (
    <LogDivider>
      <Text color={muiColors.orange[200]}>{message}</Text>
    </LogDivider>
  );
};
