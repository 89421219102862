import React from "react";
import styled from "styled-components";

import { blueForUIText } from "../../../Colors";
import { H3Tabs } from "../typography";

const TabsContainer = styled.div<{ noLeftMargin?: boolean }>`
  display: flex;
  ${({ noLeftMargin }) => !noLeftMargin && "margin-inline-start: 1rem;"}
`;
const Tab = styled(H3Tabs)<{ selected: boolean; isNotClickable: boolean }>`
  margin-inline-end: 2rem;
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "700" : "500")};
  border-bottom: 4px solid
    ${({ selected }) => (selected ? blueForUIText : "inherit")};
  ${({ isNotClickable }) =>
    isNotClickable
      ? `pointer-events: none;
         opacity: 0.3;`
      : ""}
`;

const TabButtons: React.FC<{
  tabs: string[];
  clickableTabs?: string[];
  currentTabIndex: number;
  onTabClick: (selectedTab: number) => void;
  noLeftMargin?: boolean;
  e2eSelector?: string;
}> = ({
  tabs,
  clickableTabs,
  currentTabIndex,
  onTabClick,
  noLeftMargin,
  e2eSelector,
}) => {
  return (
    <TabsContainer noLeftMargin={noLeftMargin} data-e2e-selector={e2eSelector}>
      {tabs.map((t, i) => (
        <Tab
          key={i}
          selected={currentTabIndex === i}
          isNotClickable={clickableTabs ? !clickableTabs.includes(t) : false}
          onClick={() => onTabClick(i)}
        >
          {t}
        </Tab>
      ))}
    </TabsContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TabButtons;
