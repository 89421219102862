import React from "react";

import TextButton from "../../common/TextButton";

interface ClearAllSelectedPodsButtonProps {
  selectedPodsAmount: number;
  onClick: () => void;
}

export const ClearAllSelectedPodsButton: React.FC<
  ClearAllSelectedPodsButtonProps
> = ({ selectedPodsAmount, onClick }) => (
  <TextButton
    onClick={onClick}
  >{`Clear all (${selectedPodsAmount})`}</TextButton>
);
