import { ServiceFromHasuraActions } from "../types";

export const buildLastModifyFromService = (
  service: ServiceFromHasuraActions
): Date => {
  if (service.lastDeployStartTime) {
    if (service.lastDeployEndTime) {
      return service.lastDeployStartTime > service.lastDeployEndTime
        ? new Date(service.lastDeployStartTime)
        : new Date(service.lastDeployEndTime);
    }
    return new Date(service.lastDeployStartTime);
  }

  if (service.deployStateEventTime && service.deployStateCreatedAt) {
    return service.deployStateEventTime > service.deployStateCreatedAt
      ? new Date(service.deployStateCreatedAt)
      : new Date(service.deployStateEventTime);
  }

  return new Date(service.updatedAt);
};
