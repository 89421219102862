import { useState } from "react";

import { useInterval } from "../../../../components/common/useInterval";

export const useIsDesktopAppReady = (): boolean | undefined => {
  const [isDesktopAppReady, setIsDesktopAppReady] = useState<boolean>();
  useInterval(() => {
    async function updateDesktopAppReady() {
      const envReady = await window.electronAPI?.isDesktopAppReady();
      setIsDesktopAppReady(envReady);
    }
    updateDesktopAppReady();
  }, 1_000);
  return isDesktopAppReady;
};
