import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";
import { ContainerStatus, ContainerState } from "kubernetes-types/core/v1.d";

import { SectionName } from "../WorkflowIssueEventDetails/triage/common/styles";

export const ContainerStatusContainer = styled.div`
  display: grid;
  grid-template-columns: 8rem 20rem;
  column-gap: 1rem;
  justify-content: start;
  padding-top: 1rem;
  /* Target the first child element and make it bold */
  & > :nth-child(-n + 2) {
    font-weight: bold;
  }
`;

export const ContainerStatusWrapper: React.FC<{
  containerStatuses: ContainerStatus[] | undefined;
}> = ({ containerStatuses }) => {
  if (!containerStatuses) {
    return null;
  }
  return (
    <div>
      <SectionName>container statuses</SectionName>
      <ContainerStatusContainer>
        <Typography variant={"body3"}>Name</Typography>
        <Typography variant={"body3"}>Status</Typography>
        {containerStatuses.map((containerStatus) => (
          <>
            <Typography variant={"body3"}>{containerStatus.name}</Typography>
            <Typography variant={"body3"}>
              {getMessage(containerStatus)}
            </Typography>
          </>
        ))}
      </ContainerStatusContainer>
    </div>
  );
};

const getMessage = (containerStatus: ContainerStatus): string => {
  const state = containerStatus.state as ContainerState;
  if (state.running) {
    return "Running";
  } else if (state.terminated) {
    return containerStatus.state?.terminated?.reason ?? "Terminated";
  } else if (state.waiting) {
    return containerStatus.state?.waiting?.reason ?? "Waiting";
  } else {
    return "Unknown";
  }
};
