import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

import { podLogs } from "./types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined
): IStep => {
  const { firstSnapshot, lastSnapshot } = issue?.results?.logs?.output ?? {};
  const isLogsExist =
    (firstSnapshot?.isLogsExist ?? false) ||
    (lastSnapshot?.isLogsExist ?? false) ||
    checkPodsContainersForLogs(firstSnapshot?.pods ?? []) ||
    checkPodsContainersForLogs(lastSnapshot?.pods ?? []);
  return {
    type: AvailabilityInvestigationCheckType.Logs,
    visible: true,
    visited: false,
    disabled: !isLogsExist,
    message: firstSnapshot?.isPodLogsAllowed
      ? undefined
      : "Logs are not available for this cluster",
  };
};

const checkPodsContainersForLogs = (pods: podLogs[]) =>
  pods.some((pod) =>
    pod?.containers
      ? pod.containers.some((container) => container.isLogsExist)
      : false
  );
