import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  CustomEventResponse,
  EventsApiApiV1CustomEventsSearchGetRequest,
  apiV1CustomEventsSearchGetUrl,
} from "../../../../../../generated/resourcesApi";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";
import { CUSTOM_EVENT_SEARCH } from "../../../requestResponseMaps";
import { useResourcesApiClient } from "../../apiClient";

const fetchCustomEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1CustomEventsSearchGetRequest
): Promise<CustomEventResponse> => {
  const { data } = await apiClient.get(
    apiV1CustomEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useCustomEvents = (
  params: EventsApiApiV1CustomEventsSearchGetRequest,
  options: { enabled?: boolean } = { enabled: true }
) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([CUSTOM_EVENT_SEARCH, params]);
  return useQuery(keys, () => fetchCustomEvents(apiClient, params), options);
};
