import { getAppConfig } from "../config/appConfig";

import { SocketMessagePayload } from "./types";

interface Args {
  agentId: string;
  query?: Record<string, string>;
  authorization: string | null;
}

export function getWebSocketsHubURL({ agentId, query, authorization }: Args): {
  host: string;
  path: string;
} {
  // TODO: rename the app config URL to something more generic
  const wsUrl = getAppConfig().podExecWsUrl;

  if (getAppConfig().env === "development") {
    const authorizationStr = authorization
      ? authorization
      : "authorization is missing";
    query = { authorization: authorizationStr, ...query };
  }

  const qsParams = new URLSearchParams(query).toString();
  const qs = qsParams ? `?${qsParams}` : "";

  return {
    host: wsUrl ?? "",
    path: `/${agentId}${qs}`,
  };
}

export const extractPayloadFromMessage = (
  message: MessageEvent<SocketMessagePayload>
): SocketMessagePayload => {
  return typeof message.data !== "string"
    ? message.data
    : JSON.parse(message.data);
};
