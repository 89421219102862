import React from "react";

import { Link, TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = (serviceName: string) => (
  <TextContainer>
    The deployment {serviceName} does not have a HPA (Horizontal Pod Autoscaler)
    configured.
  </TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Configuring a Horizontal Pod Autoscaler (HPA) is crucial for dynamically
    adjusting the number of pod replicas in a Kubernetes cluster based on
    observed CPU utilization or other metrics, ensuring optimal resource
    utilization and application performance. This helps maintain application
    reliability during varying loads, preventing both over-provisioning and
    resource exhaustion.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Define a HPA to guarantee optimal resource utilization and application
    performance by dynamically adjusting pod replicas based on real-time demand.
  </TextContainer>
);

export const getSecondHowDescription = () => (
  <TextContainer>
    This HPA scales the my-app deployment within the default namespace,
    maintaining between 2 and 10 replicas based on CPU utilization, targeting an
    average utilization of 50%.
    <br />
    You can find the full explanation on HPAs and how to configure them, in the{" "}
    <Link href="https://kubernetes.io/docs/tasks/run-application/horizontal-pod-autoscale/">
      Kubernetes documentation
    </Link>
    .
  </TextContainer>
);

export const yamlExample = `apiVersion: autoscaling/v2
kind: HorizontalPodAutoscaler
metadata:
  name: my-app-hpa
  namespace: default
spec:
  scaleTargetRef:
    apiVersion: apps/v1
    kind: Deployment
    name: my-app
  minReplicas: 2
  maxReplicas: 10
  metrics:
  - type: Resource
    resource:
      name: cpu
      target:
        type: Utilization
        averageUtilization: 50`;
