import React, { useMemo } from "react";
import { get } from "lodash";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HPA from "../../../resources/hpa";
import { DescribePanel } from "../common/DescribeSection";
import {
  DescribeDateItem,
  DescribeTextItem,
  DescribeTableItems,
} from "../common/DescribeItem";
import { DescribeKeyValueItem } from "../common/DescribeTagListItem";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";
import { DescribeConditions } from "../common/DescribeConditions";
import { HpaCondition } from "../common/HpaCondition";

import { mergeMetrics, getMetricSummaryText } from "./utils";

const PaddingItems = styled.div`
  padding-inline-start: 2rem;
`;

interface HPADescribeTabProps {
  resource: HPA;
}

export const HPADescribeTab: React.FC<HPADescribeTabProps> = ({ resource }) => {
  const metrics = useMemo(() => {
    if (!resource || !resource.fullObj) {
      return undefined;
    }

    const hpa = resource.fullObj;

    const mergedMetrics = mergeMetrics(
      get(hpa, "status.currentMetrics"),
      get(hpa, "spec.metrics")
    );

    return {
      mergedMetrics,
      names: Object.keys(mergedMetrics),
    };
  }, [resource]);

  const hpa = resource.fullObj;
  const { creationTimestamp, name, labels, annotations, namespace } =
    hpa.metadata ?? {};

  const conditions = get(hpa, "status.conditions") as HpaCondition[];

  const { maxReplicas, minReplicas, scaleTargetRef } = hpa.spec ?? {};
  const { currentReplicas, desiredReplicas } = hpa.status ?? {};
  const isCurrentReplicasDefined = typeof currentReplicas === "number";
  const isDesiredReplicasDefined = typeof desiredReplicas === "number";

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeTextItem name="Namespace" value={namespace} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        <DescribeTextItem name="Min replicas" value={minReplicas} />
        <DescribeTextItem name="Max replicas" value={maxReplicas} />

        {isCurrentReplicasDefined && isDesiredReplicasDefined ? (
          <DescribeTextItem
            name="Deployment pods"
            value={`${currentReplicas} current / ${desiredReplicas} desired`}
          />
        ) : (
          <DescribeTextItem name="Deployment pods" />
        )}

        {scaleTargetRef ? (
          <DescribeTextItem
            name="Reference"
            value={`${scaleTargetRef.kind}/${scaleTargetRef.name}`}
          />
        ) : (
          <DescribeTextItem name="Reference" />
        )}
        <DescribeConditions conditions={conditions} />
      </DescribePanel>

      {metrics && metrics.names && (
        <DescribeTableItems name="Metrics">
          <PaddingItems>
            {metrics.names.map((m) => (
              <DescribeTextItem
                name={m}
                value={getMetricSummaryText(metrics.mergedMetrics[m])}
              />
            ))}
          </PaddingItems>
        </DescribeTableItems>
      )}
    </DescribeTabContentWrapper>
  );
};
