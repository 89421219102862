import React from "react";
import {
  ActionItem,
  Divider,
  MenuList,
} from "@komodorio/design-system/deprecated";
import { Pencil16, Trash16 } from "@komodorio/design-system/icons";
import { useNavigate } from "react-router-dom";

import { selectedAppViewIdSelector } from "../../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getEditAppViewRoute } from "../../../../../utils/getRoutes";
import { useAppViewsStore } from "../../../../../../../../../shared/store/appViewsStore/appViewsStore";
import { appViewAriaLabels } from "../../../../../appViewAriaLabels";

import { useAppViewActionsStateContext } from "./AppViewActionsStateProvider";

const {
  overviewPage: {
    header: { actionsMenu: actionsMenuAriaLabel },
  },
} = appViewAriaLabels;

export const ActionsMenu: React.FC<{ closeMenu: () => void }> = ({
  closeMenu,
}) => {
  const navigate = useNavigate();
  const { setDeletionDialogOpen } = useAppViewActionsStateContext();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);

  const navigateToEditPage = () => {
    closeMenu();
    if (selectedAppViewId) {
      navigate(getEditAppViewRoute(selectedAppViewId));
    }
  };

  const openDeletionDialog = () => {
    closeMenu();
    setDeletionDialogOpen(true);
  };

  return (
    <MenuList aria-label={actionsMenuAriaLabel}>
      <ActionItem icon={Pencil16} onClick={navigateToEditPage}>
        Edit workspace
      </ActionItem>
      <Divider spacing="4px" />
      <ActionItem icon={Trash16} variant="danger" onClick={openDeletionDialog}>
        Delete workspace
      </ActionItem>
    </MenuList>
  );
};
