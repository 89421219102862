import React, { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SearchField } from "@komodorio/design-system/komodor-ui";

import { FILTER_UPDATE_DELAY } from "../../../../../../constants/reliabilityConstants";
import { reliabilityArialLabels } from "../../../../../../reliabilityArialLabels";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import {
  selectSetGroupState,
  selectViolationsState,
} from "../../../../../../store/reliabilityStoreSelectors";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { useInitialGroupState } from "../hooks/useInitialGroupState";

const {
  violationsTable: { searchFilter: searchFilterAriaLabel },
} = reliabilityArialLabels;

export const SearchFieldHandler: React.FC = () => {
  const { uid, violationsGroupBy } = useViolationsTableContext();

  const { groups } = useReliabilityStore(selectViolationsState);

  const initialGroupState = useInitialGroupState(violationsGroupBy);

  const searchTerm =
    groups[violationsGroupBy][uid ?? ""]?.searchTerm ??
    initialGroupState.searchTerm;

  const setGroupState = useReliabilityStore(selectSetGroupState);

  const [localSearchTerm, setLocalSearchTerms] = useState<string>(searchTerm);
  const { callback: debouncedOnSearch } = useDebouncedCallback(
    (searchValue: string) =>
      setGroupState({
        groupBy: violationsGroupBy,
        uid: uid ?? "",
        newState: { searchTerm: searchValue },
      }),
    FILTER_UPDATE_DELAY
  );
  const onSearchChange = useCallback(
    (searchValue: string) => {
      setLocalSearchTerms(searchValue);
      debouncedOnSearch(searchValue);
    },
    [debouncedOnSearch]
  );

  useEffect(() => {
    setLocalSearchTerms(searchTerm);
  }, [searchTerm]);

  return (
    <SearchField
      onSearch={onSearchChange}
      value={localSearchTerm}
      width={"300px"}
      placeholder={"Search services"}
      ariaLabel={searchFilterAriaLabel}
    />
  );
};
