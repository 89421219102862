import React, { useRef } from "react";
import { Skeleton } from "@komodorio/design-system/deprecated";
import Divider from "@mui/material/Divider";
import styled from "styled-components";

import ResourceListTable from "../../../common/ResourceListTable/ResourceListTable";
import {
  KubernetesNodesResource,
  KubernetesPodsResource,
} from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useAtmInServiceTabs } from "../useAtmInServiceTabs";
import { Panel } from "../../../Inspection/styles";
import { LinesLoader } from "../../../common/loaders/Line";
import { useNodesByServiceLiveData } from "../../../../shared/hooks/useNodes/useNodes";
import { useNodesATM } from "../../../../shared/hooks/useNodes/useNodesATM";
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";
import { Filters } from "../common/Filters";
import { filterInspectionResourcesBySearchParams } from "../../../Inspection/filters/useInspectionFilters";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import {
  LABEL_FILTER_TAB_PARAM_KEY,
  NODES_TAB_PREFIX,
  SEARCH_TERM_PARAM_KEY,
  STATUS_FILTER_TAB_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";
import { useReportEagerLoadingState } from "../../../../shared/hooks/datadog-rum/useReportEagerLoadingState";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

const Padding = styled.div`
  padding-bottom: 1rem;
`;

interface NodesTabProps {
  resource: KomodorServiceResourceKind;
}

interface NodesTabContentProps extends NodesTabProps {
  agentId: string;
}

const NodesTab: React.FC<NodesTabProps> = ({ resource }) => {
  const agentId = useActiveAgent(resource.cluster);
  const shouldUseAtm = useAtmInServiceTabs(resource);
  if (shouldUseAtm === undefined || !agentId) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return shouldUseAtm ? (
    <NodesContentAtm agentId={agentId} resource={resource} />
  ) : (
    <NodesContentOld agentId={agentId} resource={resource} />
  );
};

const NodesContentOld: React.FC<NodesTabContentProps> = ({
  resource,
  agentId,
}) => {
  const nodes = useNodesByServiceLiveData(resource, agentId);

  return (
    <>
      {nodes ? (
        <ResourceListTable
          rows={nodes}
          resourceType={KubernetesNodesResource}
          cluster={resource.cluster}
          refreshResults={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      ) : (
        <NodeNoContent />
      )}
    </>
  );
};

const NodesContentAtm: React.FC<NodesTabContentProps> = ({
  resource,
  agentId,
}) => {
  const { rows: nodes, fetching } = useNodesATM(resource, agentId);
  const divRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useIsElementInViewport({ ref: divRef });
  useReportEagerLoadingState({
    isDisplayed,
    loadingState: { nodes: fetching },
    viewName: dataDogViewNames.resourceViewNodesTab,
  });

  const searchTermState = useDrawerUrlState<string>(
    NODES_TAB_PREFIX + SEARCH_TERM_PARAM_KEY
  );
  const statusFilterState = useDrawerUrlState<string[]>(
    NODES_TAB_PREFIX + STATUS_FILTER_TAB_PARAM_KEY
  );
  const labelFilterState = useDrawerUrlState<string[]>(
    NODES_TAB_PREFIX + LABEL_FILTER_TAB_PARAM_KEY
  );
  const filteredTableResults = filterInspectionResourcesBySearchParams({
    resourceList: {
      rows: nodes ?? [],
      fetching: false,
      errorMessage: "",
      emptyResult: nodes?.length === 0,
    },
    kubernetesResource: KubernetesPodsResource,
    filterSearchTerm: searchTermState[0],
    filterStatus: statusFilterState[0],
    filterLabels: labelFilterState[0],
  });

  return nodes ? (
    <div ref={divRef}>
      {nodes.length > 1 && (
        <Padding>
          <Filters
            resourceList={nodes}
            searchTermState={searchTermState}
            statusFilterState={statusFilterState}
            labelFilterState={labelFilterState}
            ariaLabel={AriaLabels.ResourceView.NodesTab.Filters}
          />
          <Divider />
        </Padding>
      )}
      <ResourceListTable
        rows={filteredTableResults}
        resourceType={KubernetesNodesResource}
        cluster={resource.cluster}
        refreshResults={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    </div>
  ) : (
    <NodeNoContent />
  );
};

const NodeNoContent: React.FC = () => {
  return (
    <div>
      <Skeleton height={"2rem"} width={"100%"} />
      <br />
      <Skeleton height={"2rem"} width={"100%"} />
      <br />
      <Skeleton height={"2rem"} width={"100%"} />
      <br />
      <Skeleton height={"2rem"} width={"100%"} />
    </div>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodesTab;
