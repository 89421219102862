import { v4 as uuidv4 } from "uuid";

export const MAX_JOB_NAME_LENGTH = 62;

const getShortUuid = (): string => {
  const uuid = uuidv4();
  const formattedUuid = uuid.replace(/-/g, "");
  return trimString(formattedUuid, 8);
};

const trimString = (str: string, length: number): string => {
  if (str.length > length) {
    return str.substring(0, length);
  }
  return str;
};

const composeJobName = (jobName: string, shortUuid: string): string => {
  return `${jobName}-${shortUuid}`;
};

const trimJobName = (
  newJobName: string,
  originalJobName: string,
  shortUuid: string
): string => {
  const lenToTrim = newJobName.length - MAX_JOB_NAME_LENGTH;
  const trimmedJobName = trimString(
    originalJobName,
    originalJobName.length - lenToTrim
  );
  return composeJobName(trimmedJobName, shortUuid);
};

export const getJobName = (originalJobName: string): string => {
  const shortUuid = getShortUuid();
  const newJobName = composeJobName(originalJobName, shortUuid);
  if (newJobName.length <= MAX_JOB_NAME_LENGTH) {
    return newJobName;
  }
  return trimJobName(newJobName, originalJobName, shortUuid);
};
