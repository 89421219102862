import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import { ViolationsGroupBy } from "../../../../../ReliabilityTypes";

export const VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS = [5, 10, 50];
export const VIOLATIONS_TABLE_ROW_HEIGHT = 58;
export const VIOLATIONS_TABLE_HEADER_HEIGHT = 56;

export const getInitialPageSize = (compactView: boolean) => {
  return compactView
    ? VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[0]
    : VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[1];
};

export const getInitialPaginationModel = (
  violationsGroupBy: ViolationsGroupBy
): GridPaginationModel => {
  const compactView = violationsGroupBy !== "None";
  return { page: 0, pageSize: getInitialPageSize(compactView) };
};
