import React from "react";
import styled from "styled-components";
import { Subject } from "kubernetes-types/rbac/v1.d";
import { theme } from "@komodorio/design-system";

import DataTable, { Column } from "../../../../common/table/DataTable";

import { DescribeSection } from "./DescribeSection";

const DataTableStyle = styled.span`
  .data-table {
    border: 1px solid ${theme.border.borderGray};
    border-radius: 4px;
    box-shadow: unset;
  }
`;

export type TableRowData = Subject;

interface RoleBindingsListProps {
  title: string;
  data?: TableRowData[];
  columns: Column<TableRowData>[];
  expandByDefault?: boolean;
  onRowClicked?: (row: TableRowData) => void;
}

export const RBACBindingsList: React.FC<RoleBindingsListProps> = ({
  columns,
  title,
  data,
  expandByDefault = true,
  onRowClicked,
}) =>
  data && data.length ? (
    <DescribeSection
      title={title}
      ariaLabel={`${title} list`}
      expandByDefault={expandByDefault}
    >
      <DataTableStyle>
        <DataTable
          data={data}
          columns={columns}
          getRowId={(u) => u.name}
          highlightedRows={false}
          className="data-table"
          onRowClicked={onRowClicked}
        />
      </DataTableStyle>
    </DescribeSection>
  ) : null;

const referenceColumns: Column<TableRowData>[] = [
  { name: "Kind", selector: (u) => u.kind },
  { name: "Name", selector: (u) => u.name },
  { name: "API Group", selector: (u) => u.apiGroup },
];

export const RoleBindingReference: React.FC<
  Pick<RoleBindingsListProps, "data" | "onRowClicked">
> = ({ data, onRowClicked }) => (
  <RBACBindingsList
    title="References"
    data={data}
    columns={referenceColumns}
    onRowClicked={onRowClicked}
  />
);

const bindingColumns: Column<TableRowData>[] = [
  { name: "Type", selector: (u) => u.kind },
  { name: "Name", selector: (u) => u.name },
  { name: "Namespace", selector: (u) => u.namespace },
];

export const RoleBinding: React.FC<
  Pick<RoleBindingsListProps, "data" | "onRowClicked">
> = ({ data, onRowClicked }) => (
  <RBACBindingsList
    title="Bindings"
    data={data}
    columns={bindingColumns}
    onRowClicked={onRowClicked}
  />
);
