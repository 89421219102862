import React, { useCallback, useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { ViolationStatus } from "../../../../../../../../generated/reliabilityApi";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { allowedStatusesSelectorValues } from "../../../../../../constants/reliabilityConstants";
import { reliabilityArialLabels } from "../../../../../../reliabilityArialLabels";
import { ViolationStatusToLabel } from "../../../../../../constants/violationStatusToLabel";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import {
  selectSetGroupState,
  selectViolationsState,
} from "../../../../../../store/reliabilityStoreSelectors";

import { MultiSelectContainer } from "./MultiSelectContainer";

const allStatuses = Object.values(allowedStatusesSelectorValues);

const {
  violationsTable: { statusFilter: statusFilterAriaLabel },
} = reliabilityArialLabels;

export const StatusesSelector: React.FC = () => {
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const { groups } = useReliabilityStore(selectViolationsState);

  const statuses = useMemo(
    () => groups[violationsGroupBy][uid ?? ""]?.statuses ?? [],
    [groups, uid, violationsGroupBy]
  );

  const setGroupsState = useReliabilityStore(selectSetGroupState);

  const options = useMemo<MuiSelectionOption<string>[]>(() => {
    return (
      allStatuses.map((status) => {
        return {
          label: ViolationStatusToLabel[status],
          value: status,
        };
      }) ?? []
    );
  }, []);

  const onChange = useCallback(
    (options: MuiSelectionOption<string>[]) => {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          statuses: options.map((option) => option.value as ViolationStatus),
        },
      });
    },
    [uid, setGroupsState, violationsGroupBy]
  );

  const currentValues = useMemo(() => {
    if (!statuses) return [];
    return statuses.map((status) => {
      return {
        label: ViolationStatusToLabel[status],
        value: status,
      };
    });
  }, [statuses]);

  return (
    <MultiSelectContainer
      options={options}
      value={currentValues}
      placeholder={"All statuses"}
      width={"175px"}
      onChange={onChange}
      ariaLabel={statusFilterAriaLabel}
      selectPopoverProps={{
        width: "200px",
      }}
    />
  );
};
