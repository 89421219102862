import { RefObject, useCallback, useEffect, useMemo, useState } from "react";

import { useIsElementInViewport } from "../../hooks/useIntersectionObserver";

import { OPTION_MARGIN } from "./checkboxHandlerConstants";
import {
  CheckboxOptionsHandlerProps,
  CollapsibleHeaderActionButtonProps,
  Option,
} from "./checkboxOptionsHandlerTypes";

type CalculateMaxHeightParams = {
  hiddenOptionRef: RefObject<HTMLDivElement>;
} & Pick<
  CheckboxOptionsHandlerProps,
  "maxHeightInPixels" | "maxHeightByOptionsNum"
>;

export const useCalculateMaxHeight = ({
  hiddenOptionRef,
  maxHeightByOptionsNum,
  maxHeightInPixels,
}: CalculateMaxHeightParams) => {
  const [maxHeight, setMaxHeight] = useState<number | undefined>(
    maxHeightInPixels
  );
  const shouldCalculateMaxHeight = !maxHeight && !!maxHeightByOptionsNum;
  useEffect(() => {
    if (shouldCalculateMaxHeight && hiddenOptionRef.current) {
      const optionHeight = hiddenOptionRef.current.clientHeight;
      const calculatedMaxHeight =
        (optionHeight + OPTION_MARGIN) * maxHeightByOptionsNum - OPTION_MARGIN;
      setMaxHeight(calculatedMaxHeight);
    }
  }, [
    maxHeight,
    maxHeightByOptionsNum,
    hiddenOptionRef,
    shouldCalculateMaxHeight,
  ]);

  return { maxHeight, shouldCalculateMaxHeight };
};

type GetHeaderActionProps = Pick<
  CheckboxOptionsHandlerProps,
  "options" | "onChange"
> & {
  filteredOptions: Option[];
  defaultSelectedOptions?: string[];
};

type GetHeaderActionPropsOutput = Omit<
  CollapsibleHeaderActionButtonProps,
  "searchTerm" | "isCollapseOpen"
>;
export const useGetHeaderActionProps = ({
  options,
  defaultSelectedOptions,
  filteredOptions,
  onChange,
}: GetHeaderActionProps): GetHeaderActionPropsOutput => {
  const hasChangedOptions = useMemo(() => {
    return options.some((option) => {
      const hasNoneDefaultSelectedOptions =
        option.checked && !defaultSelectedOptions?.includes(option.value);
      const hasDefaultSelectedOptionsUnchecked =
        !option.checked && defaultSelectedOptions?.includes(option.value);
      return (
        hasNoneDefaultSelectedOptions || hasDefaultSelectedOptionsUnchecked
      );
    });
  }, [defaultSelectedOptions, options]);

  const filteredOptionsNum = filteredOptions.length;

  const onReset = useCallback(() => {
    const updatedOptions = options.map((option) => {
      const isDefaultSelected = !!defaultSelectedOptions?.includes(
        option.value
      );
      return { ...option, checked: isDefaultSelected };
    });
    onChange(updatedOptions);
  }, [defaultSelectedOptions, onChange, options]);

  const onSelectAll = useCallback(() => {
    const updatedOptions = options.map((option) => {
      if (
        filteredOptions.find(
          (filteredOption) => filteredOption.value === option.value
        )
      ) {
        return { ...option, checked: true };
      }
      return option;
    });
    onChange(updatedOptions);
  }, [filteredOptions, onChange, options]);

  return { hasChangedOptions, onReset, onSelectAll, filteredOptionsNum };
};

type ShouldDisplaySearchFieldParams = {
  optionsContainerRef: RefObject<HTMLDivElement>;
  checkBoxOptions: JSX.Element[];
};

export const useShouldDisplaySearchField = ({
  optionsContainerRef,
  checkBoxOptions,
}: ShouldDisplaySearchFieldParams) => {
  const isDisplayed = useIsElementInViewport({ ref: optionsContainerRef });
  return useMemo(() => {
    if (
      !optionsContainerRef.current ||
      (!checkBoxOptions.length && !isDisplayed)
    )
      return;
    return (
      optionsContainerRef.current.scrollHeight >
      optionsContainerRef.current.clientHeight
    );
  }, [optionsContainerRef, checkBoxOptions, isDisplayed]);
};
