import React from "react";

import { IconProps } from "../types";

export const Code24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3189 18.1471C10.2575 18.5568 10.5398 18.9386 10.9495 19C11.3591 19.0614 11.741 18.7791 11.8024 18.3695L13.6305 5.85291C13.6919 5.44327 13.4096 5.06142 13 5.00002C12.5904 4.93863 12.2085 5.22093 12.1471 5.63057L10.3189 18.1471ZM7.52934 16.7993C7.38871 16.9398 7.19809 17.0187 6.99934 17.0187C6.80059 17.0187 6.60997 16.9398 6.46934 16.7993L2.21934 12.5493C2.07889 12.4087 2 12.2181 2 12.0193C2 11.8206 2.07889 11.63 2.21934 11.4893L6.46934 7.23934C6.538 7.16566 6.6208 7.10655 6.7128 7.06556C6.8048 7.02457 6.90412 7.00253 7.00482 7.00075C7.10552 6.99898 7.20555 7.0175 7.29894 7.05522C7.39233 7.09294 7.47716 7.14909 7.54838 7.2203C7.6196 7.29152 7.67574 7.37636 7.71346 7.46975C7.75118 7.56313 7.76971 7.66316 7.76793 7.76386C7.76616 7.86457 7.74411 7.96388 7.70312 8.05588C7.66213 8.14788 7.60303 8.23068 7.52934 8.29934L3.80934 12.0193L7.52934 15.7393C7.66979 15.88 7.74868 16.0706 7.74868 16.2693C7.74868 16.4681 7.66979 16.6587 7.52934 16.7993ZM16.5087 16.7994C16.6494 16.9398 16.84 17.0187 17.0387 17.0187C17.2375 17.0187 17.4281 16.9398 17.5687 16.7994L21.8187 12.5494C21.9592 12.4087 22.0381 12.2181 22.0381 12.0194C22.0381 11.8206 21.9592 11.63 21.8187 11.4894L17.5687 7.23936C17.5001 7.16567 17.4173 7.10657 17.3253 7.06558C17.2333 7.02459 17.134 7.00255 17.0332 7.00077C16.9325 6.99899 16.8325 7.01752 16.7391 7.05524C16.6457 7.09296 16.5609 7.1491 16.4897 7.22032C16.4185 7.29154 16.3623 7.37638 16.3246 7.46976C16.2869 7.56315 16.2684 7.66318 16.2701 7.76388C16.2719 7.86459 16.294 7.9639 16.3349 8.0559C16.3759 8.1479 16.435 8.2307 16.5087 8.29936L20.2287 12.0194L16.5087 15.7394C16.3683 15.88 16.2894 16.0706 16.2894 16.2694C16.2894 16.4681 16.3683 16.6587 16.5087 16.7994Z"
      />
    </svg>
  );
};

Code24.defaultProps = {
  fill: "currentColor",
};
