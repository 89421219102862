import React, { useEffect, useMemo } from "react";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { AllocationFiltersGroupBy } from "../../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectAllocationFilters,
  selectSetAllocationFilters,
} from "../../../store/costOptimizationStoreSelectors";
import { commonSelectProps } from "../../shared/commonSelectProps";
import { costOptimizationAriaLabels as ariaLabels } from "../../../constants/costOptimizationAriaLabels";
import { useCostOptimizationDataInUrl } from "../../../hooks/persistentStateHooks";
import { useAllocationSetFilterState } from "../hooks/allocationHooks";
import { GROUP_BY_FILTER_NAME_TO_LABEL } from "../../../constants/costOptimizationConstants";
import { initialState } from "../../../store/initialState";

const groupByOptions: AllocationFiltersGroupBy[] = [
  "clusterName",
  "namespace",
  "komodorServiceName",
];

const defaultValue = {
  label: GROUP_BY_FILTER_NAME_TO_LABEL[initialState.allocationFilters.groupBy],
  value: initialState.allocationFilters.groupBy,
};

export const GroupByFilter: React.FC = () => {
  const setFilterState = useAllocationSetFilterState();
  const [{ allocationFilters = {} }] = useCostOptimizationDataInUrl();
  const groupByFromUrl = useMemo(() => {
    return allocationFilters?.groupBy;
  }, [allocationFilters]);

  const { groupBy } = useCostOptimizationStore(selectAllocationFilters);

  const setAllocationFilters = useCostOptimizationStore(
    selectSetAllocationFilters
  );

  useEffect(() => {
    const initialGroupBy = groupByFromUrl ? groupByFromUrl : groupBy;
    setAllocationFilters({ groupBy: initialGroupBy });
  }, [setAllocationFilters, groupByFromUrl, groupBy]);

  const onChange = (option?: MuiSelectionOption<string>) => {
    option &&
      setFilterState("groupBy", option.value as AllocationFiltersGroupBy);
  };

  const selectOptions = useMemo(
    () =>
      groupByOptions.map((option) => ({
        label: GROUP_BY_FILTER_NAME_TO_LABEL[option],
        value: option,
      })),
    []
  );

  const value = useMemo(
    () => ({
      label: GROUP_BY_FILTER_NAME_TO_LABEL[groupBy],
      value: groupBy,
    }),
    [groupBy]
  );

  return (
    <div>
      <SingleSelect
        {...commonSelectProps}
        ariaLabel={ariaLabels.allocationPage.filters.groupBy}
        value={value}
        options={selectOptions}
        onChange={onChange}
        label={"Group By:"}
        defaultValue={defaultValue}
      />
    </div>
  );
};
