import React, { useMemo } from "react";

import { separateFilterTypes } from "./filterStateHadlerUtils";
import { useOnSearchTerm } from "./useOnSearchTerm";
import { useOnOptionChange } from "./useOnOptionChange";

import { CheckboxOptionsHandler } from "@/shared/components/CheckboxOptionsHandler/components/CheckboxOptionsHandler";
import {
  CheckboxOptionsHandlerProps,
  Option,
} from "@/shared/components/CheckboxOptionsHandler/checkboxOptionsHandlerTypes";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

export type CheckboxOptionsWithFilterCategory = CheckboxOptionsHandlerProps & {
  filterCategory: string;
};

type CheckboxOptionsHandlerParams = {
  checkboxHandlerPropsList: CheckboxOptionsWithFilterCategory[];
};

export const useCheckboxOptionsHandler = ({
  checkboxHandlerPropsList,
}: CheckboxOptionsHandlerParams) => {
  const filtersData = useFiltersDataInUrl();

  const onChange = useOnOptionChange();
  const onSearch = useOnSearchTerm();

  return useMemo(() => {
    return checkboxHandlerPropsList.map((checkboxHandlerProps) => {
      let optionsToUse = checkboxHandlerProps.options;
      const { filterCategory } = checkboxHandlerProps;
      const filtersFromUrl = filtersData?.[filterCategory] ?? [];
      const { searchOptions, filters: filtersWithNoSearch } =
        separateFilterTypes(filtersFromUrl);
      const hasSelectedOptions = !!filtersWithNoSearch.length;
      if (hasSelectedOptions) {
        optionsToUse = optionsToUse.map((option) => {
          return {
            ...option,
            checked: !!filtersWithNoSearch.find(
              (filter) => filter.values.includes(option.value) ?? false
            ),
          };
        });
      }
      const currentSearchTerm = searchOptions[0]?.values[0] || "";
      return (
        <CheckboxOptionsHandler
          {...checkboxHandlerProps}
          options={optionsToUse}
          onChange={(options: Option[]) => onChange(filterCategory, options)}
          onSearchChange={(searchTerm: string) =>
            onSearch(filterCategory, searchTerm)
          }
          searchTerm={currentSearchTerm}
          key={filterCategory}
        />
      );
    });
  }, [checkboxHandlerPropsList, filtersData, onChange, onSearch]);
};
