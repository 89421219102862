import React, { ComponentProps } from "react";

import { EditButton } from "../../components/Actions/buttons/EditButton";
import useResourceWithInterval from "../../components/ResourceView/useResource";
import { ResourceResultType } from "../../components/ResourceView/types";

import { useActiveAgent } from "./useAgents";

type EditYamlByResourceParams = {
  clusterName: string | undefined;
  namespace: string | undefined;
  name: string | undefined;
  kind: string | undefined;
  EditYamlButton: ComponentProps<typeof EditButton>["Button"];
};

export const useEditYamlByResource = ({
  kind,
  clusterName,
  namespace,
  name,
  EditYamlButton,
}: EditYamlByResourceParams) => {
  const agentId = useActiveAgent(clusterName) ?? "";

  const { resource } = useResourceWithInterval({
    agentId,
    cluster: clusterName ?? "",
    namespace: namespace ?? "",
    resourceName: name ?? "",
    resourceType: kind ?? "",
    executeMutation:
      !!agentId && !!clusterName && !!namespace && !!name && !!kind,
    preferredResultType: ResourceResultType.RawResource,
  });

  if (!namespace || !name || !resource) return null;
  return <EditButton resource={resource} Button={EditYamlButton} />;
};
