import React, { ReactNode } from "react";
import styled from "styled-components";

import { useOverviewPageStore } from "../../store/overviewPageStore";
import {
  selectInsightsDrawer,
  selectPageTransition,
} from "../../store/overviewPageStoreSelectors";
import {
  INSIGHTS_DRAWER_TRANSITION_DURATION,
  INSIGHTS_DRAWER_EASING_FUNCTION,
} from "../insightsConstants";

const Container = styled.div<{ open: boolean; to: number }>`
  position: relative;
  transition: right ${INSIGHTS_DRAWER_TRANSITION_DURATION}ms
    ${INSIGHTS_DRAWER_EASING_FUNCTION};
  right: ${({ open, to }) => (open ? `${to}px` : 0)};
`;

export const TransitionContainer: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { insightsDrawerOpen } = useOverviewPageStore(selectInsightsDrawer);
  const currentPageTransition = useOverviewPageStore(selectPageTransition);

  return (
    <Container open={insightsDrawerOpen} to={currentPageTransition}>
      {children}
    </Container>
  );
};
