import pluralize from "pluralize";

import {
  CertificateExpirationData,
  ViolationSupportingData,
} from "@/generated/reliabilityApi";

type ExpirationsByDays = {
  expired: CertificateExpirationData[];
  isExpiringToday: CertificateExpirationData[];
  willExpire: CertificateExpirationData[];
};

export const groupByExpirationDays = (
  certificates: CertificateExpirationData[]
) => {
  return certificates.reduce<ExpirationsByDays>(
    (acc, curr) => {
      if (curr.daysToExpiration < 0) {
        acc.expired.push(curr);
      } else if (curr.daysToExpiration === 0) {
        acc.isExpiringToday.push(curr);
      } else {
        acc.willExpire.push(curr);
      }
      return acc;
    },
    {
      expired: [],
      isExpiringToday: [],
      willExpire: [],
    }
  );
};

const getHasExpiredText = (
  isSingleCertificateExpired: boolean,
  expired: CertificateExpirationData[]
) => {
  if (!expired?.length) return;

  if (isSingleCertificateExpired) {
    return `certificate has expired ${Math.abs(
      expired[0].daysToExpiration
    )} days ago`;
  }
  return `has ${expired.length} expired ${pluralize(
    "certificate",
    expired.length
  )}`;
};

const getTodayText = (
  isSingleCertificateExpired: boolean,
  today: CertificateExpirationData[]
) => {
  if (!today?.length) return;

  if (isSingleCertificateExpired) {
    return `certificate expires today`;
  }
  return `has ${today.length} ${pluralize(
    "certificate",
    today.length
  )} expiring today`;
};

const getWillExpireText = (
  isSingleCertificateExpired: boolean,
  willExpire: CertificateExpirationData[]
) => {
  if (!willExpire?.length) return;

  if (isSingleCertificateExpired) {
    return `certificate will expire in ${willExpire[0].daysToExpiration} days`;
  }
  return `has ${willExpire.length} ${pluralize(
    "certificate",
    willExpire.length
  )} that ${willExpire.length > 1 ? "are" : "is"} about to expire`;
};

const getSuffixText = (totalParts: number, currentIndex: number) => {
  if (totalParts === 1 || currentIndex === totalParts - 1) {
    return "";
  }

  if (currentIndex === totalParts - 2) {
    return " and ";
  }
  return ", ";
};

export const getCertificateExpirationTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const { certificates } = supportingData?.certificateExpiration ?? {};
  const isSingleCertificateExpired = certificates?.length === 1;

  const groups = groupByExpirationDays(certificates ?? []);

  const { expired, isExpiringToday, willExpire } = groups;

  const titleParts = [
    getHasExpiredText(isSingleCertificateExpired, expired),
    getTodayText(isSingleCertificateExpired, isExpiringToday),
    getWillExpireText(isSingleCertificateExpired, willExpire),
  ].filter((part) => !!part);

  return `The service ${titleParts
    .map((text, index) => `${text}${getSuffixText(titleParts.length, index)}`)
    .join("")}`;
};

export const getIsAboutToExpire = (firstCertificateDaysToExpiration: number) =>
  firstCertificateDaysToExpiration >= 0;
