import { Permissions } from "@/shared/constants/permissions";

export enum TabOptions {
  monitors = "monitors",
  policies = "policies",
  ignoreChecks = "ignore-checks",
}

type HealthSettingsTab = {
  value: TabOptions;
  label: string;
  permissions: string[];
  tooltip: string;
};

export const HEALTH_SETTINGS_TABS: HealthSettingsTab[] = [
  {
    value: TabOptions.monitors,
    label: "Realtime Monitors",
    permissions: [Permissions.canManageMonitors],
    tooltip:
      "The following permissions is needed to view this section:\n" +
      "‘manage:monitors’",
  },
  {
    value: TabOptions.policies,
    label: "Reliability Policies",
    permissions: [Permissions.canManageReliabilityPolicies],
    tooltip:
      "The following permissions is needed to view this section:\n" +
      "‘manage:reliability-policies’",
  },
  {
    value: TabOptions.ignoreChecks,
    label: "Ignored Checks",
    permissions: [Permissions.canManageReliability],
    tooltip:
      "The following permissions is needed to view this section:\n" +
      "‘manage:reliability’",
  },
];
