import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type RelatedResourcesState = {
  fetchingState: {
    isFetchingPodsTable: boolean | undefined;
    isFetchingPvcTable: boolean | undefined;
    isFetchingConfigMapsTable: boolean | undefined;
    isFetchingSecretsTable: boolean | undefined;
    isFetchingHpaTable: boolean | undefined;
    isFetchingServicesTable: boolean | undefined;
    isFetchingIngressesTable: boolean | undefined;
  };
};

export type FetchingStateProps = RelatedResourcesState["fetchingState"];

export type RelatedResourcesActions = {
  setFetchingState: (fetchingState: {
    key: keyof FetchingStateProps;
    value: boolean;
  }) => void;
  resetFetchingState: () => void;
};

export type RelatedResourcesStore = RelatedResourcesState &
  RelatedResourcesActions;

export const initialState: RelatedResourcesState = {
  fetchingState: {
    isFetchingPodsTable: undefined,
    isFetchingPvcTable: undefined,
    isFetchingConfigMapsTable: undefined,
    isFetchingSecretsTable: undefined,
    isFetchingHpaTable: undefined,
    isFetchingServicesTable: undefined,
    isFetchingIngressesTable: undefined,
  },
};

export const useRelatedResourcesStore = create(
  immer<RelatedResourcesStore>((set, get) => ({
    ...initialState,
    setFetchingState: (fetchingState: {
      key: keyof FetchingStateProps;
      value: boolean;
    }) => {
      set((state) => ({
        fetchingState: {
          ...state.fetchingState,
          [fetchingState.key]: fetchingState.value,
        },
      }));
    },
    resetFetchingState: () => {
      set(() => ({
        fetchingState: {
          ...initialState.fetchingState,
        },
      }));
    },
  }))
);

export const resetRelatedResourcesStore = (): void => {
  useRelatedResourcesStore.setState({
    ...initialState,
  });
};
