import React from "react";

import { LiveStateDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerTypes";
import { useAddonEntityDrawer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerHooks";
import { LiveStateDrawerContextProvider } from "@/components/k8sAddons/LiveStateDrawer/context/LiveStateDrawerContext";

type Props = LiveStateDrawerProps & {
  open: boolean;
  onClose: () => void;
};

export const LiveStateDrawer: React.FC<Props> = ({
  onClose,
  open,
  entity,
  addon,
  ...rest
}) => {
  return (
    <LiveStateDrawerContextProvider
      open={open}
      onClose={onClose}
      addonType={addon}
      addonEntity={entity}
      {...rest}
    >
      {useAddonEntityDrawer(addon, entity)}
    </LiveStateDrawerContextProvider>
  );
};
