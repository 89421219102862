import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import useGetAccountPlanData from "../useGetAccountPlanData";
import useEventsLimit from "../useEventsLimit";
import { openPlansPage } from "../../../shared/utils/freemium";

import { EventsLimitsProgressBar } from "./EventLimitsProgressBar/EventsLimitsProgressBar";

const Container = styled.div`
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.6rem;
`;

const InfoContainer = styled.div`
  width: 14rem;
  display: flex;
  justify-content: space-between;
`;

const EventsLimitInfoContainer = styled.div`
  width: 13rem;
  display: flex;
  justify-content: start;
  gap: 0.2rem;
`;

const UpgradeButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  text-decoration: underline;
`;

export const EventsLimitsInfo: React.FC = () => {
  const eventsLimitsData = useEventsLimit();
  const eventsTotalSum = Number(eventsLimitsData?.eventsTotalSum) || 0;

  const planData = useGetAccountPlanData();
  const eventsLimit = planData?.eventsLimit;

  const eventsLimitPercentage = !eventsLimit
    ? 0
    : Math.floor((eventsTotalSum / eventsLimit) * 100);

  return (
    <>
      <Container>
        <EventsLimitsProgressBar
          eventsLimitPercentage={eventsLimitPercentage}
        />
        <InfoContainer>
          <EventsLimitInfoContainer>
            <Typography variant="text" color={palette.white[0]}>
              <>
                {eventsTotalSum?.toLocaleString()}/
                {eventsLimit?.toLocaleString()}
              </>
            </Typography>
            <Typography variant="text" color={palette.gray[300]}>
              monthly events
            </Typography>
          </EventsLimitInfoContainer>
          <UpgradeButton onClick={openPlansPage}>Upgrade!</UpgradeButton>
        </InfoContainer>
      </Container>
    </>
  );
};
