import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { muiColors } from "@komodorio/design-system";

import { DistributionItem } from "../../../components/DistributionItem";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { formatNumber } from "../../../../../shared/utils/formatNumber";

const { Loader, Total, Item } =
  AriaLabels.ClusterOverview.InventorySection.DistributionBox;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1px auto;
  column-gap: 12px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]}};
  align-items: center;
  width: fill-available;
  height: 72px;

  ${({ onClick }) =>
    onClick &&
    `:hover {
    cursor: pointer;
    background-color: ${muiColors.gray[50]};
  }`}
`;

interface DistributionBoxProps {
  name: string;
  total: number;
  distribution: { label: string; value: number; color: string | undefined }[];
  onClick: () => void;
  isLoading?: boolean;
  ariaLabel?: string;
  isError?: boolean;
  upgradeCta?: React.ReactElement;
}
export const DistributionBox: React.FC<DistributionBoxProps> = ({
  name,
  total,
  distribution,
  onClick,
  isLoading,
  ariaLabel,
  isError = false,
  upgradeCta,
}) => {
  if (isLoading) {
    return (
      <Container aria-label={ariaLabel}>
        <Skeleton
          width="48px"
          height="23px"
          sx={{ transform: "none" }}
          aria-label={Loader}
        />
        <Divider orientation="vertical" />
        <Skeleton width="100%" height="23px" sx={{ transform: "none" }} />
      </Container>
    );
  }
  if (isError || upgradeCta) {
    return (
      <Container aria-label={ariaLabel}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" aria-label={Total}>
            N/A
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {name}
          </Typography>
        </Box>
        {upgradeCta && (
          <>
            <Divider orientation="vertical" />
            <Box sx={{ display: "flex", columnGap: "8px" }}>
              <Typography variant="h4">{upgradeCta}</Typography>
            </Box>
          </>
        )}
      </Container>
    );
  }

  return (
    <Container onClick={total ? onClick : undefined} aria-label={ariaLabel}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h2" aria-label={Total}>
          {formatNumber(total)}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {name}
        </Typography>
      </Box>
      <Divider orientation="vertical" />
      <Box sx={{ display: "flex", columnGap: "8px" }}>
        {distribution.map(({ label, value, color }) => (
          <Box key={label} sx={{ display: "flex", flexDirection: "column" }}>
            <DistributionItem
              statusShape="circle"
              color={color}
              ariaLabel={Item}
            >
              <Typography variant="h6">{formatNumber(value)}</Typography>
            </DistributionItem>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ marginLeft: "10px" }}
            >
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};
