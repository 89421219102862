import React, { createContext, useContext } from "react";

import {
  GetPlansDataQuery,
  useGetPlansDataQuery,
} from "../../../generated/graphql";

type PlansDataOutput = GetPlansDataQuery | undefined;
const useGetPlansData = (): PlansDataOutput => {
  const [{ data }] = useGetPlansDataQuery();
  return data;
};

const PlansDataContext = createContext<PlansDataOutput>(undefined);

export const PlansDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const plansData = useGetPlansData();
  return (
    <PlansDataContext.Provider value={plansData}>
      {children}
    </PlansDataContext.Provider>
  );
};

const usePlansData = (): PlansDataOutput => useContext(PlansDataContext);

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default usePlansData;
