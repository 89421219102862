import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { PageRoutes, ViolationsGroupBy } from "../ReliabilityTypes";

import { useSelectedTab } from "./useSelectedTab";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const staticOptionsLabel = {
  CheckType: "Best practice",
  Cluster: "Cluster",
  None: "None",
};

const staticOptions: MuiSelectionOption<ViolationsGroupBy>[] = [
  { value: "CheckType", label: staticOptionsLabel.CheckType },
  { value: "Cluster", label: staticOptionsLabel.Cluster },
  { value: "None", label: staticOptionsLabel.None },
];

const dynamicOptions: MuiSelectionOption<ViolationsGroupBy>[] = [
  { value: "Impact", label: "Impact" },
  { value: "Cluster", label: "Cluster" },
  { value: "CheckType", label: "Check type" },
  { value: "None", label: "None" },
];

type TabOptionsMapperType = Partial<
  Record<PageRoutes, MuiSelectionOption<ViolationsGroupBy>[]>
>;
const tabOptionsMapper: TabOptionsMapperType = {
  violations: dynamicOptions,
  "best-practices": staticOptions,
};

export const useGetGroupByOptions =
  (): MuiSelectionOption<ViolationsGroupBy>[] => {
    const selectedTab = useSelectedTab();
    const { reliabilityBestPracticesTab } = useOverridableFlags();

    const options = tabOptionsMapper[selectedTab] || [];

    // needed until we delete 'reliabilityBestPracticesTab' flag
    if (!reliabilityBestPracticesTab)
      return options.filter((option) => option.value !== "CheckType");

    return options;
  };
