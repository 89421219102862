import { useCallback } from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";

import { ALLOCATION_TABLE_COLUMNS } from "../constants/costOptimizationConstants";
import { useCostOptimizationStore } from "../store/costOptimizationStore";
import { selectSetRightSizingRecommendationModal } from "../store/costOptimizationStoreSelectors";
import { CostAllocationSummary } from "../../../generated/metricsApi";
import { COST_OPTIMIZATION_ROUTE } from "../../routes/routes";

import { useCostOptimizationDataInUrl } from "./persistentStateHooks";

const hasServiceScope = (params: GridCellParams) => {
  return params.row["service"] || params.row.scopeKey === "Service";
};

export const useOnTableCellClick = () => {
  const openRecommendationModal = useCostOptimizationStore(
    selectSetRightSizingRecommendationModal
  );
  const [{ tab }, setDataInUrl] = useCostOptimizationDataInUrl();
  const navigate = useNavigate();

  return useCallback(
    (params: GridCellParams) => {
      const isInCostPage = !!tab;
      if (params.field === ALLOCATION_TABLE_COLUMNS.potentialSaving.field) {
        if (tab === "rightSizing" || hasServiceScope(params)) {
          openRecommendationModal({ rowId: params.row.id });
        } else {
          if (!isInCostPage) {
            navigate(COST_OPTIMIZATION_ROUTE);
          }
          setDataInUrl(
            {
              tab: "rightSizing",
              sharedFilters: {
                scope: { clusters: [params.row.clusterName] },
              },
            },
            !isInCostPage
          );
        }
      }
    },
    [navigate, openRecommendationModal, setDataInUrl, tab]
  );
};

export const useTableInitialSorting = (): keyof CostAllocationSummary => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  return tab === "rightSizing" ? "potentialSaving" : "totalCost";
};
