/* eslint-disable max-lines */
import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { Input, TabPanel, Tabs } from "@komodorio/design-system/deprecated";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { Form, FormError, StyledDialog } from "../../styles";
import { useFormValidations } from "../../../../shared/context/ValidationsProvider";
import { PreservedHeight } from "../common";
import {
  RbacCustomK8sAction,
  RbacPolicyAction,
} from "../../../../generated/auth";
import { ReadOnlyActionTypes } from "../actionTypes";
import { useCreateRbacCustomK8sAction } from "../../../../shared/hooks/auth-service/client/rbacActions/useCreateRbacK8sCustomActions";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { useUpdateRbacCustomK8sAction } from "../../../../shared/hooks/auth-service/client/rbacActions/useUpdateRbacK8sCustomAction";

import {
  validateActionName,
  validateJsonRules,
  validateRules,
} from "./validations";
import { VisualEditor } from "./VisualEditor";
import { ActionFormData, defaultRules, defaultValue } from "./types";

import { LazyEditor } from "@/components/common/LazyEditor";

const alertKomodorOnlyAction =
  'This action is "Komodor only" and does not include Kubernetes actions';
const alertReadOnlyAction =
  "Komodor actions are view only and cannot be edited";

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const sendAnalyticEventForAction = (
  editingExisting: boolean,
  actionName: string,
  editorType: string,
  success: boolean
) => {
  dispatchEvent(
    editingExisting
      ? AnalyticEvents.Settings.EditAction
      : AnalyticEvents.Settings.AddAction,
    {
      actionName,
      editorType,
      success: success,
    }
  );
};

const getTabs = (isValid: boolean) => [
  { label: "Visual Editor", disabled: !isValid },
  { label: "JSON" },
];

type ActionFormModalProps = {
  open: boolean;
  onClose: () => void;
  refetchCustomActions: () => void;
  readOnly?: boolean;
  element?: RbacCustomK8sAction | RbacPolicyAction;
};

export const ActionFormModal: React.FC<ActionFormModalProps> = ({
  open,
  onClose,
  readOnly,
  refetchCustomActions,
  element,
}) => {
  const { mutateAsync: createAction, isLoading: createLoading } =
    useCreateRbacCustomK8sAction();
  const { mutateAsync: updateAction, isLoading: updateLoading } =
    useUpdateRbacCustomK8sAction();

  const [value, setValue] = useState<ActionFormData>(
    element
      ? {
          name: element.action,
          description: element.description ?? "",
          rules: element.k8sRuleset ?? [],
        }
      : defaultValue
  );
  const { errors, setError, deleteError } = useFormValidations();

  const [selectedTab, setSelectedTab] = useState(0);
  const [stringRules, setStringRules] = useState(
    element
      ? JSON.stringify(element.k8sRuleset, null, 2)
      : JSON.stringify(defaultRules, null, 2)
  );

  const { callback: debouncedValidateAndParseJson } = useDebouncedCallback(
    (v) => {
      const { jsonError, parsedRules } = validateJsonRules(v);
      setError("json", jsonError);
      if (parsedRules) {
        setValue({ ...value, rules: parsedRules });
      }
    },
    750
  );

  const validateFormInputs = (): boolean => {
    const curTab = tabs[selectedTab].label;
    const { name, rules } = value;
    const nameError = validateActionName(name);
    setError("name", nameError);
    if (nameError) {
      return false;
    }

    if (curTab === "Visual Editor") {
      const rulesErrors = validateRules(rules);
      rulesErrors.forEach((e) =>
        setError(e.property, `Please select ${e.path.at(-1)}`)
      );
      if (rulesErrors.length || nameError) {
        return false;
      }
    } else {
      const { jsonError } = validateJsonRules(stringRules);
      setError("json", jsonError);
      if (jsonError || nameError) {
        return false;
      }
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateFormInputs()) {
      return;
    }

    const curTab = tabs[selectedTab].label;
    const { name, description, rules } = value;

    let result;
    try {
      if (element) {
        result = await updateAction({
          id: (element as RbacCustomK8sAction).id,
          updateRbacCustomK8sActionPayload: {
            description: description?.trim(),
            k8sRuleset: rules,
          },
        });
      } else {
        result = await createAction({
          action: name.trim(),
          description: description?.trim(),
          k8sRuleset: rules,
        });
      }
    } catch (e) {
      setError(
        "general",
        "Encountered an issue while saving the action. Please retry or contact support for assistance."
      );
      return;
    } finally {
      sendAnalyticEventForAction(!!element, name, curTab, !!result);
    }
    refetchCustomActions();
    onClose();
  };

  const tabs = getTabs(!errors["json"]);
  const handleTabChange = (tabIndex: number) => {
    if (tabs[tabIndex].label === "JSON") {
      setStringRules(JSON.stringify(value.rules, null, 2));
    } else {
      deleteError((k) => k.startsWith(`instance`));
    }
    deleteError("general");
    setSelectedTab(tabIndex);
  };

  const loading = createLoading || updateLoading;
  const isKomodorActionType = element?.type === "komodor";
  const isReadOnly =
    readOnly || ReadOnlyActionTypes.includes(element?.type ?? "");

  return (
    <StyledDialog
      open={open}
      onClose={(_, reason) => {
        reason !== "backdropClick" && onClose();
      }}
      keepSpaceForEditor={!isKomodorActionType}
    >
      <DialogTitle variant="h3" sx={{ paddingInline: "32px" }}>
        {!isReadOnly && (element ? "Edit" : "Add")} Action
        {isReadOnly ? " (read only)" : null}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingInline: "24px",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ marginBottom: "16px" }}>
          {isReadOnly && (
            <Alert severity="info" sx={{ marginBottom: "24px" }}>
              {isKomodorActionType
                ? alertKomodorOnlyAction
                : alertReadOnlyAction}
            </Alert>
          )}
          <PreservedHeight>
            <Input
              size="medium"
              label="Action name"
              value={value.name}
              placeholder="Action name"
              onChange={(e) => {
                setValue({ ...value, name: e.target.value });
                setError("name", validateActionName(e.target.value));
              }}
              errorMessage={errors["name"]}
              disabled={isReadOnly}
            />
          </PreservedHeight>
          <PreservedHeight>
            <Input
              size="medium"
              label="Description (optional)"
              value={value.description}
              placeholder="Describe your custom action"
              onChange={(e) => {
                setValue({ ...value, description: e.target.value });
              }}
              disabled={isReadOnly}
            />
          </PreservedHeight>
        </Box>
        {!isKomodorActionType && (
          <Form>
            <div>
              <Tabs
                tabs={tabs}
                value={selectedTab}
                onChange={handleTabChange}
              />
              <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
            </div>
            <TabPanel value={selectedTab} index={0}>
              <EditorContainer>
                <VisualEditor
                  value={value.rules}
                  onChange={async (v) => setValue({ ...value, rules: v })}
                  readOnly={isReadOnly}
                />
                <FormError errMsg={errors["general"]} isBoxStyle />
              </EditorContainer>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <EditorContainer>
                <LazyEditor
                  width="100%"
                  height="40vh"
                  mode="json"
                  value={stringRules}
                  onChange={(v) => {
                    setStringRules(v);
                    debouncedValidateAndParseJson(v);
                  }}
                  readOnly={isReadOnly}
                />
                <FormError
                  errMsg={errors["json"] || errors["general"]}
                  isBoxStyle
                />
              </EditorContainer>
            </TabPanel>
          </Form>
        )}
      </DialogContent>
      <DialogActions
        style={{ alignSelf: "flex-end" }}
        sx={{ paddingInline: "24px", paddingBottom: "16px" }}
      >
        <Button color="primary" variant="outlined" onClick={onClose}>
          {isReadOnly ? "Close" : "Cancel"}
        </Button>
        {!isKomodorActionType && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={loading || isReadOnly}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};
