import React from "react";
import { theme } from "@komodorio/design-system";
import { Tooltip, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { median } from "../../../../../../../shared/utils/helpers";
import { formatTimeInterval } from "../../../../../../../shared/utils/dateUtils";
import { TriageIssueDataParsed } from "../common/types";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const calcTrend = (newNumber: number, oldNumber: number): number => {
  const percentageDiff = ((newNumber - oldNumber) / oldNumber) * 100;

  return Math.round(percentageDiff);
};

export const ISSUE_DURATION_TOOLTIP = "issue-duration-tooltip";

const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  column-gap: 0.25rem;
`;

export const IssueDurationTooltip: React.FC<{
  sevenDaysIssues: TriageIssueDataParsed[];
}> = ({ sevenDaysIssues }) => {
  const durations = sevenDaysIssues.map((issue) =>
    Math.abs(issue.endTime.getTime() - issue.startTime.getTime())
  );
  const [currentDuration] = durations.slice(-1);
  const previousDurations = durations.slice(0, -1);

  if (!previousDurations.length) {
    return null;
  }
  const medianDuration = median(previousDurations);
  const formattedMedianDuration = formatTimeInterval(medianDuration);
  const trend = calcTrend(currentDuration, medianDuration);
  return (
    <Tooltip id={ISSUE_DURATION_TOOLTIP} variant="light" place="bottom">
      <TooltipContainer>
        <Typography size="medium">Duration is</Typography>
        <Typography
          size="medium"
          bold
          color={
            trend >= 0 ? theme.foreground.fgPink : theme.foreground.fgGreen
          }
        >
          {Math.abs(trend)}%
        </Typography>
        <Typography size="medium">
          {trend >= 0 ? "more" : "less"} than the 7-day median of{" "}
        </Typography>
        <Typography size="medium" bold>
          {formattedMedianDuration}
        </Typography>
      </TooltipContainer>
    </Tooltip>
  );
};
