export enum SocketMessageType {
  PodExecInit = "pod_exec_init",
  PodLogsInit = "pod_logs_init",
  Stdin = "stdin",
  Stdout = "stdout",
  Termination = "termination",
  KeepAlive = "keep-alive",
  Error = "error",
  PodExecTerminalResize = "terminal-size",
  Ack = "ack",
}

type SocketMessageKeys =
  | "namespace"
  | "pod"
  | "containerName"
  | "command"
  | "stdin"
  | "input"
  | "out"
  | "exitMessage"
  | "exitReason"
  | "processExitCode"
  | "ackedMessageID"
  | "errorMessage"
  | "resize";

export interface SocketMessagePayload {
  messageId?: string;
  sessionId?: string;
  messageType: SocketMessageType;
  data?: Record<SocketMessageKeys, string>;
  errorCode?: number;
  errorMessage?: string;
  timestamp: string;
}
