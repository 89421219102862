import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type VersionRangeCellProps = {
  fromVersion: string;
  toVersion: string;
  maxDecimalPlaces?: number;
};

export const VersionRangeCell: React.FC<VersionRangeCellProps> = ({
  fromVersion,
  toVersion,
  maxDecimalPlaces = 2,
}) => {
  const from = parseFloat(fromVersion).toFixed(maxDecimalPlaces);
  const to = parseFloat(toVersion).toFixed(maxDecimalPlaces);
  return (
    <Container>
      <Typography variant={"body2"}>{from}</Typography>
      <Typography variant={"body2"}>→</Typography>
      <Typography variant={"body2"}>{to}</Typography>
    </Container>
  );
};
