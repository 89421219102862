/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo } from "react";
import MuiTypography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Replay from "@mui/icons-material/Replay";
import { muiColors } from "@komodorio/design-system";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { useAccountFeatures } from "../../../Settings/General/hooks/useFeatures";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { DiagnosticsFeedback } from "../../DiagnosticsFeedback/DiagnosticsFeedback";
import { useIsDemoService } from "../../../InvestigationMode/StepsLogic";
import { setIsAnalyzingLogs } from "../store/logsViewerStoreSelectors";
import { useLogsViewerStore } from "../store/logsViewerStore";
import { usePostAnalyzeLog } from "../../../../shared/hooks/ai-api/client/logs/usePostAnalyzeLog";

import { sendLogAnalysisStateEvent } from "./analytics";
import { useLogsMaxLinesNumber } from "./useLogsMaxLinesNumber";

import AiBanner from "@/components/ResourceView/AiInvestigation/common/AiBanner";
import EnableAiBanner from "@/components/ResourceView/AiInvestigation/common/EnableAiBanner";
import { StyledButton } from "@/components/ResourceView/AiInvestigation/common/styles";
import { AnalyticEvents } from "@/shared/hooks/analytics";

export const CantFindErrorMessage =
  "Our analysis could not find anything in particular in these log messages.";

export interface LogsAiAnalyzerProps {
  clusterName: string;
  namespace: string;
  podName: string;
  containerName: string;
  numberOfLines: number;
  log: string;
  runAutoAnalyze?: boolean;
}

export const LogsAiAnalyzerDelimiter = "|&|";

export const LogsAiAnalyzer: React.FC<LogsAiAnalyzerProps> = ({
  clusterName,
  namespace,
  podName,
  containerName,
  numberOfLines,
  log,
  runAutoAnalyze,
}) => {
  const { demoLogsUi } = useOverridableFlags();
  const isDemoService = useIsDemoService();
  const { klaudiaAi } = useOverridableFlags();
  const { data: features } = useAccountFeatures();
  const klaudiaEnabled = features.klaudiaAi;

  const [analyzed, setAnalyzed] = useState(false);
  const [logsChanged, setLogsChanged] = useState(false);

  useEffect(() => {
    setLogsChanged(true);
  }, [log]);

  const {
    isLoading: analyzing,
    data: analyzedData,
    mutate: analyzeLog,
    error: analyzeLogError,
    status: analyzeLogStatus,
  } = usePostAnalyzeLog(
    {
      podName,
      containerName,
      log,
    },
    {
      onMutate: () => {
        sendLogAnalysisStateEvent(
          AnalyticEvents.KlaudiaAi.Investigation,
          resource,
          undefined,
          undefined
        );
      },
      onSettled: (data, error) => {
        sendLogAnalysisStateEvent(
          AnalyticEvents.KlaudiaAi.Investigation,
          resource,
          data,
          error
        );
      },
    }
  );

  const resource = useMemo(() => {
    return {
      cluster: clusterName,
      namespace: namespace,
      name: podName,
      kind: "container",
      container: containerName,
    };
  }, [clusterName, containerName, namespace, podName]);

  const setIsAnalyzingLogsCB = useLogsViewerStore(setIsAnalyzingLogs);

  const handleAnalyze = () => {
    setAnalyzed(false);
    analyzeLog();
    setIsAnalyzingLogsCB(true);
  };

  useEffect(() => {
    if (klaudiaEnabled && runAutoAnalyze && log !== "") {
      setAnalyzed(false);
      analyzeLog();
      setIsAnalyzingLogsCB(true);
    }
  }, [runAutoAnalyze, klaudiaEnabled, log, setIsAnalyzingLogsCB, analyzeLog]);

  useEffect(() => {
    if (analyzeLogStatus === "success" || analyzeLogStatus === "error") {
      setLogsChanged(false);
      setAnalyzed(true);
      setIsAnalyzingLogsCB(false);
    }
  }, [
    analyzeLogError,
    analyzeLogStatus,
    analyzedData,
    resource,
    setIsAnalyzingLogsCB,
  ]);

  const logsMaxLinesNumber = useLogsMaxLinesNumber();
  if (!klaudiaAi || log === "") {
    return null;
  }

  if (demoLogsUi && isDemoService) {
    return null;
  }

  return klaudiaEnabled ? (
    <>
      <AiBanner
        title="AI Log Analysis"
        isLoading={analyzing}
        rightHeader={
          !analyzing && !analyzeLogError ? (
            <StyledButton
              variant={"outlined"}
              size={"medium"}
              onClick={handleAnalyze}
              disabled={analyzed && !logsChanged}
            >
              Analyze now
            </StyledButton>
          ) : analyzing ? (
            <StyledButton disabled={true} variant={"outlined"} size={"medium"}>
              Analyzing
            </StyledButton>
          ) : analyzed && analyzeLogError ? (
            <>
              <MuiTypography variant={"body2"} color={muiColors.red[800]}>
                There was an error handling your request
              </MuiTypography>
              <StyledButton
                startIcon={<Replay sx={{ transform: "scaleX(-1)" }} />}
                variant={"outlined"}
                size={"medium"}
                onClick={handleAnalyze}
              >
                Try again
              </StyledButton>
            </>
          ) : null
        }
        body={
          analyzed && !analyzeLogError ? (
            analyzedData?.isProblemFound ? (
              <>
                <MuiTypography
                  variant={"body2"}
                  aria-label={AriaLabels.LogsViewer.LogsAi.RootCause}
                >
                  <span style={{ fontWeight: "bold" }}>Root cause:</span>{" "}
                  {analyzedData?.rootCause}
                  <br />
                  <span
                    style={{ fontWeight: "bold" }}
                    aria-label={AriaLabels.LogsViewer.LogsAi.Solution}
                  >
                    Possible solution:
                  </span>{" "}
                  {analyzedData?.solution}
                </MuiTypography>
                {numberOfLines > logsMaxLinesNumber && (
                  <Alert
                    aria-label={AriaLabels.LogsViewer.LogsAi.MaxLinesAlert}
                    variant="outlined"
                    severity="info"
                    icon={
                      <InfoOutlined
                        sx={{
                          color: muiColors.blue[600],
                          backgroundColor: muiColors.blue[50],
                          fontSize: "16px",
                        }}
                      />
                    }
                    sx={{
                      color: muiColors.blue[600],
                      background: muiColors.common.white,
                      borderColor: muiColors.blue[300],
                      padding: "0.25rem 0.5rem 0.25rem 0.25rem",
                      "& .MuiAlert-icon": {
                        backgroundColor: muiColors.blue[50],
                      },
                    }}
                  >
                    <MuiTypography variant={"body2"}>
                      Response is based on the last {logsMaxLinesNumber} lines
                      in the log
                    </MuiTypography>
                  </Alert>
                )}
                <DiagnosticsFeedback
                  likeLabel={AriaLabels.LogsViewer.LogsAi.FeedBackLike}
                  dislikeLabel={AriaLabels.LogsViewer.LogsAi.FeedBackDislike}
                  message={"Did this help you diagnose the issue?"}
                  customStyle={{ background: muiColors.blue[50] }}
                />
              </>
            ) : (
              <MuiTypography variant={"body2"}>
                {CantFindErrorMessage}
              </MuiTypography>
            )
          ) : null
        }
      />
    </>
  ) : (
    <EnableAiBanner title="AI Log Analysis" />
  );
};
