import Box from "@mui/material/Box";
import React from "react";

export const SmallIcon = ({ children }: { children: React.ReactNode }) => (
  <Box
    width={16}
    height={16}
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="text.secondary"
  >
    {children}
  </Box>
);
