// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { restrictionProps } from ".";

export const useRolesRestrictionsData = (): restrictionProps => {
  return {
    modalTitle: "Upgrade to get unlimited roles",
    modalContent: `You are now using a Free Plan. In order to get more roles, you have to upgrade.`,
  };
};
