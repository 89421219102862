interface PortForwardModalArgs {
  localPort?: number;
  destPort?: number;
  agentId?: string;
  namespace?: string;
  resourceName?: string;
  resourceKind?: string;
  jwt: string | null;
}

const formatResourceKindToKomocliSyntax = (resourceKind: string): string =>
  resourceKind.toLowerCase();

export const generateCommand = ({
  localPort = 1010,
  destPort = 1010,
  agentId = "<agentId>",
  namespace = "<namespace>",
  resourceName = "<resourceName>",
  resourceKind = "<resourceKind>",
  jwt,
}: PortForwardModalArgs): {
  command: string;
  fullCommand: string;
} => {
  const command = `komocli port-forward ${formatResourceKindToKomocliSyntax(
    resourceKind
  )}/${resourceName} ${localPort}:${destPort} --namespace=${namespace} --cluster=${agentId}`;
  const jwtTokenWithoutBearerPart = jwt?.split(" ")[1] || jwt;

  const truncatedCommand = `${command.slice(0, 90)}...`;
  return {
    command: truncatedCommand,
    fullCommand: jwtTokenWithoutBearerPart
      ? `${command} --token=${jwtTokenWithoutBearerPart}`
      : command,
  };
};
