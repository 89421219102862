import React, { Suspense, lazy, memo } from "react";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";

import { LinesLoader } from "../common/loaders/Line";

const Terminal = lazy(() =>
  import("./Terminal").then((module) => ({ default: module.Terminal }))
);
import { usePodExec } from "./hooks/usePodExec";

const Container = styled.div`
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
`;

const TerminalContainer = styled.div`
  width: 100%;
  height: 95%;
  border-radius: 5px;
`;

const LoaderContainer = styled.div`
  padding-top: 2rem;
  text-align: center;
`;

export interface PodExecDrawerContentProps {
  containerName: string;
  namespace: string;
  podName: string;
  agentId: string;
  cluster: string;
  identifier: string;
}

export const PodExecDrawerContent: React.FC<PodExecDrawerContentProps> = memo(
  ({ containerName, agentId, cluster, namespace, podName, identifier }) => {
    const { onInputChanged, isReconnecting } = usePodExec({
      agentId,
      cluster,
      containerName,
      namespace,
      podName,
      identifier,
    });

    return (
      <Container>
        <TerminalContainer>
          {isReconnecting ? (
            <LoaderContainer>
              <Typography size="medium" variant="headline">
                Connection lost. Attempting to reconnect...
              </Typography>
              <LinesLoader />
            </LoaderContainer>
          ) : (
            <Suspense
              fallback={
                <LoaderContainer>
                  <LinesLoader />
                </LoaderContainer>
              }
            >
              <Terminal identifier={identifier} onInput={onInputChanged} />
            </Suspense>
          )}
        </TerminalContainer>
      </Container>
    );
  }
);
