import { useMetricsParams } from "../components/shared/metrics/hooks/useMetricsParams";
import { useFetchContainersMetricsData } from "../components/shared/metrics/hooks/useFetchContainersMetricsData";
import { useFetchContainersSummary } from "../components/shared/metrics/hooks/useFetchContainersSummary";

export const useGetServiceMetrics = (komodorUid: string) => {
  const { params, isInvalidParams } = useMetricsParams(komodorUid);

  const memoryRes = useFetchContainersMetricsData(
    { ...params, kind: "memory" },
    isInvalidParams
  );
  const cpuRes = useFetchContainersMetricsData(
    { ...params, kind: "cpu" },
    isInvalidParams
  );

  const memorySummaryRes = useFetchContainersSummary(
    { ...params, kind: "memory" },
    isInvalidParams
  );

  const cpuSummaryRes = useFetchContainersSummary(
    { ...params, kind: "cpu" },
    isInvalidParams
  );

  return {
    memoryRes,
    cpuRes,
    memorySummaryRes,
    cpuSummaryRes,
  };
};
