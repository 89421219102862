import styled from "styled-components";

import { blueForUIText } from "../../../Colors";

const ExternalLink = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})<{
  color?: string;
}>`
  color: ${({ color }) => color ?? blueForUIText};
`;

export const StrictExternalLink = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer noopener",
})<{
  color?: string;
}>`
  color: ${({ color }) => color ?? blueForUIText};
`;

export const ExternalLinkHoverUnderline = styled(ExternalLink)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ExternalLink;
