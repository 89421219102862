import React, { useState } from "react";
import styled from "styled-components";

import { ActionButtonProps } from "@/components/Actions/buttons/types";
import { SuspendResumeCronjobModal } from "@/components/Actions/modals/SuspendResumeCronjobModal";
import Action from "@/components/Actions/buttons/index";

const Container = styled.div``;

export const JobSuspendButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Container>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={() => setOpenModal(true)}
        Button={Button}
      />
      <SuspendResumeCronjobModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        actionType="suspend"
      />
    </Container>
  );
};

export const JobResumeButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Container>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={() => setOpenModal(true)}
        Button={Button}
      />
      <SuspendResumeCronjobModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        actionType="resume"
      />
    </Container>
  );
};
