import { useQuery } from "@tanstack/react-query";

import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import {
  apiV1SearchGetUrl,
  SearchResponse,
} from "../../../generated/searchApi/api";

import { useSearchApiClient } from "./client/apiClient";

const ROOT_URL = "/";

export const useSearchAPIGet = (
  query: string,
  workspaceId: string | undefined = undefined,
  {
    pause = false,
    staleTime = 0,
    skipAppViewInHeaders,
  }: { pause: boolean; staleTime: number; skipAppViewInHeaders?: boolean }
) => {
  const apiClient = useSearchApiClient(skipAppViewInHeaders);
  const res = useQuery(
    [ROOT_URL, query, workspaceId, skipAppViewInHeaders],
    async () => {
      const res = await apiClient.get<SearchResponse>(
        apiV1SearchGetUrl({ query }, apiClient.defaults.baseURL ?? "")
      );
      if (res.data.results.length === 0) {
        dispatchEvent(AnalyticEvents.GlobalSearch.NoResults, { query });
      }
      return res;
    },
    {
      enabled: !pause,
      staleTime: staleTime,
    }
  );
  return {
    loading: res.isLoading,
    error: res.error as string,
    data: res.data?.data,
  };
};
