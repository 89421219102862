import { CRD } from "@komodorio/design-system/icons";

export const KubernetesCRResource = {
  NameInK8S: "none",
  Group: "crds",
  NavBarName: "Resources",
  PresentName: "Custom Resources instances",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "CustomResource",
  Icon: CRD,
  hasLabels: true,
  ignoreNavigation: true,
  isCustomResource: true,
};
