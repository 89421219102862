import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

import {
  FormDetailsFields,
  InputFields,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";

const detailFields: FormDetailsFields[] = [
  "name",
  "description",
  "priority",
  "scope.clusterRegexes",
];

export const useHasEnteredFormValues = () => {
  const { getValues } = useFormContext<InputFields>();

  return useCallback(() => {
    // changing values and then restoring them to the initial state, or not changing the default values,
    // will result in formState.isDirty being true. Check manually
    const allValues = getValues();
    return detailFields.some((field) => {
      return (
        !!allValues[field as keyof InputFields] ||
        Object.keys(allValues.configurations ?? {}).length > 0
      );
    });
  }, [getValues]);
};
