import { useCallback } from "react";
import { OperationResult } from "urql";
import { ChannelType } from "komodor-types";

import {
  CreateChannelConfigurationMutation,
  useCreateChannelConfigurationMutation,
} from "../../generated/graphql";

import {
  ChannelConfiguration,
  GenericWebhookConfiguration,
} from "./useFetchNotificationConfiguration";

export type CreateChannelConfigurationResponse = (
  config: ChannelConfiguration
) => Promise<OperationResult<CreateChannelConfigurationMutation>>;

export type CreateGenericWebhookResponse = (
  config: GenericWebhookConfiguration
) => Promise<OperationResult<CreateChannelConfigurationMutation>>;

export const useInsertChannelConfiguration =
  (): CreateChannelConfigurationResponse => {
    const [, createChannelConfiguration] =
      useCreateChannelConfigurationMutation();

    return useCallback(
      (config: ChannelConfiguration) => {
        const params = {
          type: config.type,
          configuration: config.configuration,
        };
        return createChannelConfiguration(params);
      },
      [createChannelConfiguration]
    );
  };

export const useInsertGenericWebhook = (): CreateGenericWebhookResponse => {
  const [, createChannelConfiguration] =
    useCreateChannelConfigurationMutation();

  return useCallback(
    (configuration: GenericWebhookConfiguration) => {
      const params = {
        type: ChannelType.GenericWebhook,
        configuration,
      };
      return createChannelConfiguration(params);
    },
    [createChannelConfiguration]
  );
};
