import pluralize from "pluralize";

import { TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = (containersName: string[]) => {
  const numOfContainers = containersName.length;
  return (
    <TextContainer>
      {`${numOfContainers} ${pluralize(
        "container",
        numOfContainers
      )} of this workload are missing a Readiness probes`}
      <ul>
        {containersName.map((containerName) => (
          <li key={containerName}>{containerName}</li>
        ))}
      </ul>
    </TextContainer>
  );
};

export const getWhyDescription = () => (
  <TextContainer>
    <b>Readiness Probe:</b> This probe is used to determine if a container is
    ready to start accepting traffic. If the readiness probe fails, the
    container is removed from the service endpoints until it passes. This helps
    to ensure that only fully functional containers receive traffic.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Add a <b>readinessProbe</b> configuration to your container specification in
    the pod's YAML manifest.
  </TextContainer>
);
