import { WorkloadMetricsSummary } from "../../../../../../../../../../generated/metricsApi";
import { ViolationSummaryItem } from "../../../shared/ViolationSummary/ViolationSummary";
import {
  cpuUnit,
  getMibNumber,
  getRatio,
  getValue,
  memoryUnit,
} from "../../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";

export const getMemorySummary = (
  summary: WorkloadMetricsSummary | undefined
): ViolationSummaryItem[] => {
  if (!summary) return [];

  const requestValue = getMibNumber(summary?.latestRequests);
  const limitValue = getMibNumber(summary?.latestLimits);

  return [
    {
      label: "Requests",
      value: getValue(requestValue, memoryUnit),
    },
    {
      label: "Limits",
      value: getValue(limitValue, memoryUnit),
    },
    {
      label: "Request to Limit ratio",
      value: getRatio({ firstValue: requestValue, secondValue: limitValue }),
    },
    {
      label: "P99 usage",
      value: getValue(getMibNumber(summary.p99Utilization), memoryUnit),
    },
  ];
};

export const getCpuSummary = (
  summary: WorkloadMetricsSummary | undefined
): ViolationSummaryItem[] => {
  if (!summary) return [];

  const requestValue = summary.latestRequests;
  const limitValue = summary.latestLimits;

  return [
    {
      label: "Requests",
      value: getValue(requestValue, cpuUnit),
    },
    {
      label: "Limits",
      value: getValue(limitValue, cpuUnit),
    },
    {
      label: "Request to Limit ratio",
      value: getRatio({ firstValue: requestValue, secondValue: limitValue }),
    },
    {
      label: "P99 usage",
      value: getValue(summary.p99Utilization, cpuUnit),
    },
  ];
};
