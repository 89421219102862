import { useMemo } from "react";

import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import EventNodeShape from "../../monitorsView/assets/eventNode.svg?react";
import EventStorageShape from "../../monitorsView/assets/eventStorage.svg?react";
import EventWorkloadShape from "../../monitorsView/assets/eventWorkload.svg?react";
import JobScatterShape from "../../common/EventGroup/shapes/job.svg?react";
import DeployScatterShape from "../../common/EventGroup/shapes/deploy.svg?react";
import {
  AVAILABILITY_MONITOR_DOCS_LINK,
  CRONJOB_MONITOR_DOCS_LINK,
  DEPLOY_MONITOR_DOCS_LINK,
  JOB_MONITOR_DOCS_LINK,
  MONITORS_DOCS_LINK,
  NODE_MONITOR_DOCS_LINK,
  PVC_MONITOR_DOCS_LINK,
} from "../../../shared/constants/docsLinks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowTemplate, WorkflowConfigType, AvailableSinks } from "./types";

const AvailableWorkflows: Record<WorkflowConfigType, WorkflowTemplate> = {
  [WorkflowConfigType.PVC]: {
    title: "PVC Detector",
    shortDescription:
      "Detects PVC resources in Pending state and provides insights about the issue.",
    longDescription:
      "This workflow will be triggered when a PVC reaches a Pending state for the defined duration (defaults to 60 seconds), it will perform checks to provide additional context regarding the PVC issue. ",
    configurationType: WorkflowConfigType.PVC,
    endpoint: "pvc",
    checksDescription: [
      "PVC creation, utilization, and readiness issues",
      "Volume provisioner related issues",
      "PVC spec change",
      "Identify the impact on your services",
    ],
    requiredAgentVersion: "0.1.72",
    triggerConditions: "A Persistent Volume Claim is in bound state",
    icon: EventStorageShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: PVC_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.NodeIssue]: {
    title: "Node Detector",
    shortDescription:
      "Detects faulty Node Conditions and provides additional context regarding the issue.",
    longDescription:
      "This workflow will be triggered when one or more of a node Conditions report an issue (e.g node is not Ready or Memory/Disk/PID Pressure occurs) for the defined duration (defaults to 60 seconds), it will perform checks to provide additional context regarding the issue.",
    configurationType: WorkflowConfigType.NodeIssue,
    endpoint: "node-issue",
    checksDescription: [
      "Is the node ready?",
      "Is the node overcommitted?",
      "Is the node under pressure?",
      "Are system Pods healthy?",
      "Is the network available?",
      "Are Pods being evicted?",
      "Are user pods healthy?",
      "Is the node schedulable?",
      "Node overall resource consumption including top 5 pod consumers (requires metric-server installed)",
    ],
    requiredAgentVersion: "0.1.72",
    triggerConditions: "A Node condition is unhealthy",
    icon: EventNodeShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: NODE_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.Availability]: {
    title: "Availability Detector",
    shortDescription:
      "Monitor your workloads health (number of available replicas) and discover the root cause of your pods failures.",
    longDescription:
      "Monitor your workloads health (number of available replicas) and discover the root cause of your pods failures.",
    configurationType: WorkflowConfigType.Availability,
    endpoint: "availability",
    checksDescription: [
      "Pods health",
      "Workload latest deploy changes",
      "Workload describe",
    ],
    requiredAgentVersion: "0.1.78",
    triggerConditions: "Pods fail",
    icon: EventWorkloadShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: AVAILABILITY_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.Job]: {
    title: "Job Detector",
    shortDescription:
      "Detects failed Jobs executions and provides insights about the issue.",
    longDescription:
      "This workflow will be triggered when a job fails execution ",
    configurationType: WorkflowConfigType.Job,
    endpoint: "job",
    checksDescription: [],
    requiredAgentVersion: "0.1.72",
    triggerConditions: "A job failed execution",
    icon: JobScatterShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: JOB_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.CronJob]: {
    title: "CronJob Detector",
    shortDescription:
      "Detects failed Jobs executions and provides insights about the issue.",
    longDescription:
      "This workflow will be triggered when a job fails execution ",
    configurationType: WorkflowConfigType.CronJob,
    endpoint: "cronJob",
    checksDescription: [],
    requiredAgentVersion: "0.1.72",
    triggerConditions: "A job failed execution",
    icon: JobScatterShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: CRONJOB_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.Deploy]: {
    title: "Deploy Detector",
    shortDescription: "Detects workload deploys and provides insights.",
    longDescription: "This monitor will be triggered when a deploy occours ",
    configurationType: WorkflowConfigType.Deploy,
    endpoint: "deploy",
    checksDescription: ["Pods health"],
    requiredAgentVersion: "0.1.78",
    triggerConditions: "A Deploy occours, workload generation has changed",
    icon: DeployScatterShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: DEPLOY_MONITOR_DOCS_LINK,
  },
  [WorkflowConfigType.Pod]: {
    title: "Pod Detector",
    shortDescription: "Standalone pod failed",
    longDescription:
      "Monitor failures of Pods that have no controller (i.e. Deployment, StatefulSet, DaemonSet, Job). For example, you can monitor failed Argo Workflow Pods.",
    configurationType: WorkflowConfigType.Pod,
    endpoint: "pod",
    displayName: "Standalone Pod",
    checksDescription: ["Pod health"],
    requiredAgentVersion: "0.1.78",
    triggerConditions: "A standalone pod is not ready",
    icon: EventWorkloadShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: MONITORS_DOCS_LINK,
  },
  [WorkflowConfigType.Workflow]: {
    title: "Workflow Pod Detector",
    shortDescription: "Workflow pod failed",
    longDescription:
      "Monitor failures of Pods that related to workflows. For example, you can monitor failed Argo Workflow Pods.",
    configurationType: WorkflowConfigType.Workflow,
    endpoint: "pod",
    displayName: "Workflow Pod",
    checksDescription: ["Pod health"],
    requiredAgentVersion: "0.2.84",
    triggerConditions: "A workflow pod is not ready",
    icon: EventWorkloadShape,
    sinks: [
      AvailableSinks.Slack,
      AvailableSinks.Teams,
      AvailableSinks.Opsgenie,
      AvailableSinks.PagerDuty,
      AvailableSinks.GenericWebhook,
    ],
    docsLink: MONITORS_DOCS_LINK,
  },
};

export const useWorkflowsFlags = (): Record<string, boolean> => {
  const flags = useOverridableFlags();

  return useMemo(() => {
    return Object.fromEntries(
      Object.values(AvailableWorkflows).map((workflow) => [
        workflow.configurationType,
        workflow.configurationType === WorkflowConfigType.Job ||
        workflow.configurationType === WorkflowConfigType.CronJob
          ? flags[`showJobsMonitor`] !== false
          : workflow.configurationType === WorkflowConfigType.Workflow
          ? flags[`workflowMonitor`] !== false
          : flags[`showWorkflowType${workflow.configurationType}`] !== false,
      ])
    );
  }, [flags]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AvailableWorkflows;
