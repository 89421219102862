import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined
): IStep => {
  const memoryLeakResult = issue?.results?.memoryLeak?.output;
  return {
    type: AvailabilityInvestigationCheckType.MemoryLeak,
    visible:
      !(memoryLeakResult?.metricsAllowed ?? true) ||
      (memoryLeakResult?.hasMetrics ?? false),
    visited: false,
    disabled: false,
  };
};
