import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

const ShouldOpenPodsHealthContext = createContext<boolean>(false);

const ShouldOpenPodsHealthUpdateContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useShouldOpenPodsHealth = (): boolean => {
  return useContext(ShouldOpenPodsHealthContext);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useShouldOpenPodsHealthUpdate = ():
  | Dispatch<SetStateAction<boolean>>
  | undefined => {
  return useContext(ShouldOpenPodsHealthUpdateContext);
};

export const ShouldOpenPodsHealthProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [shouldOpenPodsHealth, setShouldOpenPodsHealth] =
    useState<boolean>(false);

  return (
    <ShouldOpenPodsHealthContext.Provider value={shouldOpenPodsHealth}>
      <ShouldOpenPodsHealthUpdateContext.Provider
        value={setShouldOpenPodsHealth}
      >
        {children}
      </ShouldOpenPodsHealthUpdateContext.Provider>
    </ShouldOpenPodsHealthContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default useShouldOpenPodsHealth;
