import React from "react";

import { ApplicationScopingErrorMessage } from "./ApplicationScopingErrorMessage";
import { StyledLink } from "./applicationsScopingErrorStyles";
import { fetchErrorCta, title } from "./applicationScopingErrorTexts";

const { firstPart, secondPart } = fetchErrorCta;
export const FetchErrorMessage: React.FC = () => {
  const onClick = () => {
    window.location.reload();
  };

  return (
    <ApplicationScopingErrorMessage
      title={title}
      cta={
        <>
          {firstPart} <StyledLink onClick={onClick}>{secondPart}</StyledLink>
        </>
      }
    />
  );
};
