import { cloneDeep } from "lodash";
import { useEffect } from "react";

export const useSetWindowsFlags = (flags: Record<string, unknown>): void => {
  useEffect(() => {
    window.komodor = Object.assign(window.komodor || {}, {
      flags: cloneDeep(flags),
    });
  }, [flags]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useSetWindowsFlags;
