import { CertManagerPageRoutes } from "@/components/k8sAddons/addons/CertManager/certManagerTypes";
import {
  CertificateFullRelatedResources,
  CertificateFullStatus,
} from "@/generated/addonsApi";

export const CertManagerRoutes: Record<CertManagerPageRoutes, string> = {
  certificates: "certificates",
  controllers: "controllers",
};

export const certificateDrawerRelatedResourcesOrder: (keyof CertificateFullRelatedResources)[] =
  ["issuer", "certificateRequest", "order", "challenge", "secret", "workloads"];

export const certificateDrawerStatusOrder: (keyof CertificateFullStatus)[] = [
  "notBefore",
  "notAfter",
  "renewalTime",
  "conditions",
];

export const RelatedResourceToTitle: Record<
  keyof CertificateFullRelatedResources,
  string
> = {
  issuer: "Issuer",
  certificateRequest: "Certificate Request",
  order: "Order",
  challenge: "Challenge",
  secret: "Secret",
  workloads: "Workloads",
};

export const CertificateFullStatusToTitle: Record<
  keyof CertificateFullStatus,
  string
> = {
  notBefore: "Start time",
  renewalTime: "Renewal time",
  notAfter: "Expiration time",
  conditions: "Conditions",
};
