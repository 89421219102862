import React from "react";
import { capitalize } from "lodash";
import { InfoCircleOutlined24, Team24 } from "@komodorio/design-system/icons";

import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import eventsIcon from "../../ProcessList/assets/events.svg";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import eventPodIcon from "./assets/eventPod.svg";
import srcCodeIcon from "./assets/srcCode.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import {
  DetailsGrid,
  CommandText,
  SubTitle,
  usePreparedEvents,
} from "./common";

interface CordonUncordonEventDetailsProps {
  eventGroup: ActionEventGroup;
}

export const CordonUncordonEventDetails: React.FC<
  CordonUncordonEventDetailsProps
> = ({ eventGroup }) => {
  const { events, cluster, resourceName, actionType, email, actionResult } =
    eventGroup;
  const preparedEvents = usePreparedEvents(events);
  const command = `kubectl ${actionType} ${resourceName}`;
  const resourceDetails = [
    {
      title: `Node name:`,
      data: resourceName,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={`${capitalize(actionType)} node`}>
        <SubTitle>{`${cluster}/${resourceName}`}</SubTitle>
      </EventDetailsTitle>
      <EventDetailsSection
        icon={srcCodeIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Command"
      >
        <CommandText>{command}</CommandText>
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventPodIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Node"
      >
        <DetailsGrid>
          {resourceDetails.map(
            (e, i) =>
              e && (
                <React.Fragment key={i}>
                  <div>{e.title}</div>
                  <div>{e.data}</div>
                </React.Fragment>
              )
          )}
        </DetailsGrid>
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
