import React from "react";
import { TooltipWrapper } from "react-tooltip";

import {
  SuspiciousLogsTooltip,
  SUSPICIOUS_LOGS_TOOLTIP,
} from "../Tooltips/SuspiciousLogsTooltip";
import { BoldText, LightningBoltDemoInsight } from "../common";
import { WorkflowRunWithResults } from "../../../../../../../../monitorsView/useWorkflowsRuns";
import WorkflowIssueEventGroup from "../../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

export const SuspiciousLogsInsight: React.FC<{
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
}> = ({ wfRun, eventGroup }) => {
  return (
    <>
      <TooltipWrapper tooltipId={SUSPICIOUS_LOGS_TOOLTIP}>
        <LightningBoltDemoInsight>
          We found 3 <BoldText>suspicious log lines</BoldText>
        </LightningBoltDemoInsight>
      </TooltipWrapper>
      <SuspiciousLogsTooltip wfRun={wfRun} eventGroup={eventGroup} />
    </>
  );
};
