import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const Container = styled.legend`
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ImpactAggregationsTitle: React.FC = () => {
  return (
    <Container>
      <Typography variant={"h5"}>Impact</Typography>
    </Container>
  );
};
