import {
  ThrottledAppContainersResponse,
  ThrottledAppContainersResponseAffectedContainersInner,
} from "../../../../../../../generated/metricsApi";
import { getBucketTimeFrame } from "../../../components/OverviewPage/utils/overviewPageUtils";

import { getDateIntervals, getServiceKind, getTotalDays } from "./testUtils";

const generateMockServices = (
  daysToSimulate: number,
  intervals: number[]
): ThrottledAppContainersResponse => {
  let count = 0;
  const throttledServices = Array.from({ length: 12 }).map((_, i) => {
    if (i % 2 === 0) {
      count++;
    }

    const useLongName = Math.random() > 0.7;

    const serviceData: ThrottledAppContainersResponseAffectedContainersInner = {
      komodorServiceId: `service-id-${count}`,
      komodorServiceName: `service-name-${count}${
        !useLongName ? "" : "-with-a-very-long-name"
      }`,
      cluster: `test-cluster`,
      namespace: `test-namespace`,
      containerName: `container-name-${i}${
        !useLongName ? "" : "-with-a-very-long-name"
      }`,
      limits: {
        cpu: Math.round(Math.random() * 100),
        memory: Math.round(Math.random() * 100),
      },
      requests: {
        cpu: Math.round(Math.random() * 100),
        memory: Math.round(Math.random() * 100),
      },
      komodorServiceKind: getServiceKind(i),
      aboveThrottleLimitTotal: 0,
    };

    return serviceData;
  });

  return {
    affectedContainers: throttledServices,
  };
};

type GenerateMockFailedDeploymentsParams = {
  fromEpoch: number;
  toEpoch: number;
};
export const generateMockThrottledApplications = ({
  fromEpoch,
  toEpoch,
}: GenerateMockFailedDeploymentsParams): ThrottledAppContainersResponse => {
  const totalDays = getTotalDays(fromEpoch, toEpoch);
  const intervals = getDateIntervals({
    intervalsNum: totalDays,
    endTime: toEpoch,
    bucketTimeFrame: getBucketTimeFrame(),
  });
  return generateMockServices(totalDays, intervals);
};
