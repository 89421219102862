import { Edge, Node } from "react-flow-renderer";

import { getInitialGraphData } from "./getInitialGraphData";
import { NetworkGraphDataParams, NodeData } from "./types";
import { createGraphEdges } from "./utils/edgeUtils";
import { classifyNodesByEdges } from "./utils/graphUtils";
import {
  createGraphNodes,
  exendNodeWithPosition,
  getNormalizedDependencyNodes,
  fitMapViewToDrawerArea,
} from "./utils/nodeUtils";

export const getNetworkGraphData = ({
  graphData,
  nodesData,
  resource,
  uidToServiceMap,
}: NetworkGraphDataParams):
  | { nodes: Node<NodeData>[]; edges: Edge[] }
  | undefined => {
  if (
    !graphData?.nodes?.length ||
    !graphData?.edges?.length ||
    !nodesData.length
  ) {
    return getInitialGraphData(resource);
  }

  const graphEdges: Edge[] = createGraphEdges(graphData.edges, uidToServiceMap);
  const graphNodes: Node<NodeData>[] = createGraphNodes(
    graphData.nodes,
    graphData.edges,
    resource,
    uidToServiceMap
  );
  const highlightedNode =
    graphNodes.find((node) => node.data.isHighlighted) || null;

  const { leftNodesIds, rightNodesIds } = classifyNodesByEdges(
    graphEdges,
    graphNodes,
    highlightedNode
  );

  const updatedGraphNodes = graphNodes.map((node) =>
    exendNodeWithPosition({
      node,
      highlightedNode,
      leftNodesIds,
      rightNodesIds,
    })
  );

  const normalizedGraphNodes = getNormalizedDependencyNodes(
    resource,
    updatedGraphNodes
  ).map(fitMapViewToDrawerArea);

  return {
    nodes: normalizedGraphNodes,
    edges: graphEdges,
  };
};
