import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

interface CardProps {
  children?: React.ReactNode;
  color?: string;
  tagText?: string;
  shadow?: boolean;
  preserveTagHeight?: boolean;
}

const StyledCard = styled(Card)<{
  hasTag: boolean;
  color?: string;
  shadow?: boolean;
}>`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  gap: 0.625rem;
  ${({ color }) => ` border: 1px solid ${color};`}
  ${({ hasTag }) =>
    hasTag &&
    `&.MuiPaper-root {
      &.MuiCard-root {
        border-radius: 0px 4px 4px 4px;
      }
    }`}
  ${({ shadow }) =>
    !shadow &&
    `&.MuiPaper-root {
      &.MuiCard-root {
        box-shadow: 0px 0px;
      }
    }`}
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;
`;
export const Tag = styled.div<{
  color: string | undefined;
}>`
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
  width: fit-content;
  min-height: 1.7rem;
  ${({ color }) => `
    background-color: ${color};`}
  border-radius: 4px 4px 0px 0px;
`;

const CardWithTag: React.FC<CardProps> = ({
  children,
  color,
  tagText,
  shadow = false,
  preserveTagHeight = false,
}) => {
  return (
    <CardContainer>
      {preserveTagHeight && (
        <Tag color={tagText !== undefined ? color : ""}>
          <Typography
            className="tag"
            variant="overline"
            color={muiColors.common.white}
            fontSize={"12px"}
            lineHeight={"19.2px"}
            fontWeight={500}
          >
            {tagText}
          </Typography>
        </Tag>
      )}

      <StyledCard hasTag={tagText !== undefined} color={color} shadow={shadow}>
        {children}
      </StyledCard>
    </CardContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CardWithTag;
