import React, { useMemo } from "react";
import { Status } from "@komodorio/design-system/komodor-ui";
import Grid from "@mui/material/Grid";
import { noop } from "lodash";
import { parseISO } from "date-fns";

import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { APIKeyCell } from "@/pages/organization-settings/account/AgentsPage/components";
import { AgentDrawerKeyValue } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerKeyValue";
import { Kubernetes_Agent_Info } from "@/generated/graphql";
import { agentDataGetters } from "@/pages/organization-settings/account/AgentsPage/gridHelpers";
import { MARGIN_LEFT } from "@/pages/organization-settings/account/AgentsPage/components/constants";

export const AgentDrawerDetails: React.FC<{ agent: Kubernetes_Agent_Info }> = ({
  agent,
}) => {
  const { format } = useDateFormatter();
  const { createdAt, lastSeenAlive } = useMemo(() => {
    return {
      createdAt: agent ? format(parseISO(agent.createdAt)) : "",
      lastSeenAlive: agent ? format(parseISO(agent.lastSeenAlive)) : "",
    };
  }, [agent, format]);
  return (
    <Grid
      container
      sx={{ marginLeft: MARGIN_LEFT, width: "auto" }}
      rowGap={2}
      columnGap={2}
      columns={2}
    >
      <AgentDrawerKeyValue
        title="Status"
        ValueComponent={
          <Status
            color={agent.inactive ? "error" : "success"}
            label={agent.inactive ? "Inactive" : "Active"}
          />
        }
        value={agent.clusterName || ""}
      />
      <AgentDrawerKeyValue
        title="Cluster Name"
        value={agent.clusterName || ""}
      />
      <AgentDrawerKeyValue
        title="Agent Version"
        value={agentDataGetters.getAgentVersion({
          row: agent,
        })}
      />
      <AgentDrawerKeyValue title="Agent ID" value={agent.id || ""} />
      <AgentDrawerKeyValue title="Created At" value={createdAt} />
      <AgentDrawerKeyValue
        title="Last Heartbeat Recieved"
        value={lastSeenAlive}
      />
      <AgentDrawerKeyValue
        title="Chart Version"
        value={agentDataGetters.getChartVersion({
          row: agent,
        })}
      />
      <AgentDrawerKeyValue
        title="K8s Version"
        value={agentDataGetters.getK8sVersion({
          row: agent,
        })}
      />
      <AgentDrawerKeyValue
        title="API Key"
        value={agent.installationId}
        ValueComponent={
          <APIKeyCell row={agent} visibility="visible" refetch={noop} />
        }
      />
    </Grid>
  );
};
