import { useMemo } from "react";

import { Deploy } from "../../../../generated/monitorsApi";
import { useGetDeploys } from "../../../../shared/hooks/monitors-api/client/deploys/useGetDeploys";

export const useDeploysById = (ids: string[]): [Deploy[], boolean] => {
  const { data, isFetching } = useGetDeploys(
    {
      ids: ids,
      fields: [
        "id",
        "eventTime",
        "endEventTime",
        "updatedAt",
        "status",
        "versionTo",
        "versionFrom",
        "failed_container",
        "gitCompare",
        "strategy",
        "namespace",
        "clusterName",
        "generation",
        "oldSpec",
        "newSpec",
        "deploymentDiffKeys",
        "clusterName",
        "namespace",
        "services",
        "kubernetesConfigChanges",
        "deploymentName",
      ],
    },
    ids.length > 0
  );
  return useMemo(
    () => [data?.data || [], isFetching],
    [data?.data, isFetching]
  );
};
