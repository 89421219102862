import React from "react";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";

import { gray13 } from "../../../../../../../Colors";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import { currentAppViewSelector } from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { OverviewPageTimeSelector } from "./OverviewPageTimeSelector/OverviewPageTimeSelector";
import { AppViewActionsButton } from "./AppViewActions/AppViewActionsButton";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";

const Container = styled.div`
  display: flex;
  padding: 14px 20px;
  max-height: 100px;
  background: #ffffff;
  border-bottom: 1px solid ${gray13};
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: stretch;
  align-self: flex-start;
`;

const Separator = styled.div`
  width: 1px;
  background: ${gray13};
`;

const StyledTimeWindowContainer = styled.div`
  .dateTimeSelector {
    border: 1px solid ${gray13};
  }
`;

const StyledTextContainer = styled.div`
  width: 560px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  > div:nth-child(2) {
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const OverviewPageHeader: React.FC = () => {
  const { name, description } = useAppViewsStore(currentAppViewSelector);
  const { canManageWorkspaces } = useHasPermissions();

  return (
    <Container>
      <StyledTextContainer>
        <Typography variant="headline">{name}</Typography>
        {description && (
          <Typography variant="text" size={"small"}>
            {description}
          </Typography>
        )}
      </StyledTextContainer>

      <ButtonsContainer>
        <StyledTimeWindowContainer>
          <OverviewPageTimeSelector className={"dateTimeSelector"} />
        </StyledTimeWindowContainer>
        {canManageWorkspaces ? (
          <>
            <Separator />
            <AppViewActionsButton />
          </>
        ) : null}
      </ButtonsContainer>
    </Container>
  );
};
