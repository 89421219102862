import { useCallback } from "react";
import { IntegrationType } from "komodor-types";

import { useCreateInstallation } from "../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";
import { useUpdateInstallation } from "../../../shared/hooks/accounts-service/client/installations/useUpdateInstallation";

export type BaseInstallation<T> = (
  configuration: T,
  id?: string
) => Promise<unknown>;

export function useUpsertInstallation<T = void>(
  integration: IntegrationType
): BaseInstallation<T> {
  const { mutateAsync: install } = useCreateInstallation();
  const { mutateAsync: update } = useUpdateInstallation();
  return useCallback(
    (configuration, id?) => {
      return id
        ? update({
            id,
            installationUpdateRequest: {
              configuration: configuration as unknown as object,
            },
          })
        : install({
            configuration: configuration as unknown as object,
            integration,
          });
    },
    [install, integration, update]
  );
}

export function useUpsertInstallationWithType<T = void>(
  integrationType: string
): BaseInstallation<T> {
  const { mutateAsync: install } = useCreateInstallation();
  const { mutateAsync: update } = useUpdateInstallation();
  return useCallback(
    (configuration, id?) => {
      return id
        ? update({
            id,
            installationUpdateRequest: {
              configuration: configuration as unknown as object,
            },
          })
        : install({
            configuration: configuration as unknown as object,
            integration: integrationType,
          });
    },
    [install, integrationType, update]
  );
}
