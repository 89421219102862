import Tooltip from "@mui/material/Tooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { formatDistanceStrict } from "date-fns";
import { useMemo, useState } from "react";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { muiPalette } from "@komodorio/design-system";
import { TextInput } from "@komodorio/design-system/komodor-ui";
import { useDebounce } from "use-debounce";

import { RbacClusterStatusComputedData } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/rows";
import { getCustomDateLocale } from "@/shared/utils/dateUtils";
import {
  ErrorTooltip,
  FullWidthContainerAndChildDiv,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";

export const IsSyncedCell: React.FC<
  GridRenderCellParams<
    RbacClusterStatusComputedData,
    boolean,
    boolean,
    GridTreeNodeWithRender
  >
> = (params) => {
  const isSynced = params.row.isSynced;

  const formattedLastSyncedDate = useMemo(() => {
    if (!params.row.lastUpdatedCluster) {
      return "N/A";
    }
    return formatDistanceStrict(params.row.lastUpdatedCluster, new Date(), {
      addSuffix: true,
      locale: getCustomDateLocale(),
    });
  }, [params.row.lastUpdatedCluster]);

  const formattedLastChangesDate = useMemo(() => {
    if (!params.row.lastUpdatedKomodorRbac) {
      return "N/A";
    }
    return formatDistanceStrict(params.row.lastUpdatedKomodorRbac, new Date(), {
      addSuffix: true,
      locale: getCustomDateLocale(),
    });
  }, [params.row.lastUpdatedKomodorRbac]);

  const tooltipTitle = isSynced ? (
    <>
      Last synced: <b>{formattedLastSyncedDate}</b>
    </>
  ) : (
    <>
      Out of sync since: <b>{formattedLastChangesDate}</b>
      <br />
      Reason: <b>{params.row.error ?? "Waiting for sync..."}</b>
    </>
  );

  return (
    <Tooltip title={tooltipTitle}>
      {isSynced ? (
        <CheckCircleOutlineIcon htmlColor={muiPalette.success?.main} />
      ) : (
        <HighlightOffIcon htmlColor={muiPalette.error?.main} />
      )}
    </Tooltip>
  );
};

export interface ApiServerUrlEditState {
  onApiServerUrlChange: (clusterName: string, value: string) => void;
  setHasValidationError: (value: boolean) => void;
}

export const ApiServerUrlCell: React.FC<
  GridRenderCellParams<
    RbacClusterStatusComputedData,
    boolean,
    boolean,
    GridTreeNodeWithRender
  > &
    ApiServerUrlEditState
> = (params) => {
  const [error, setError] = useState<string | null>(null);
  const [validateInput] = useDebounce((value: string) => {
    if (value === "" || URL.canParse(value)) {
      setError(null);
      params.setHasValidationError(false);
      return;
    }
    setError("This field must be a valid URL.");
    params.setHasValidationError(true);
  }, 300);

  const updateError = params.row.updateError;
  const tooltipMessage = updateError ? updateError : error;
  const hasError = !!updateError || !!error;

  return (
    <FullWidthContainerAndChildDiv>
      <ErrorTooltip
        title={tooltipMessage}
        open={hasError}
        placement={"left"}
        arrow={false}
      >
        <TextInput
          fullWidth={true}
          value={params.value}
          inputRef={params.focusElementRef}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            const newValue = e.target.value;
            validateInput(newValue);
            params.onApiServerUrlChange(params.row.clusterName, newValue);
            params.row.apiServerUrl = newValue;
          }}
          error={hasError}
        />
      </ErrorTooltip>
    </FullWidthContainerAndChildDiv>
  );
};
