import Typography from "@mui/material/Typography";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { SxProps, Theme } from "@mui/material";

import { ClusterSummary } from "../../generated/workspacesApi";
import { Severity } from "../reliability/ReliabilityTypes";
import { SeverityToColorScheme } from "../reliability/constants/reliabilityConstants";

export const Padding = styled.div<{ left?: string; right?: string }>`
  padding-left: ${(props) => props.left ?? 0};
  padding-right: ${(props) => props.right ?? 0};
`;

export const FixedWidth = styled.div<{ minWidth: string }>`
  min-width: ${({ minWidth }) => minWidth};
`;

export const HeaderText = ({ children }: { children?: React.ReactNode }) => (
  <Typography variant="h5" sx={{ textWrap: "initial" }}>
    {children}
  </Typography>
);

export const ColumnHeader = (
  params: GridColumnHeaderParams<ClusterSummary>
) => <HeaderText>{params.colDef.headerName}</HeaderText>;

export const SeverityChip = styled(Chip)<{ severity: Severity }>`
  && {
    background-color: ${({ severity }) =>
      SeverityToColorScheme[severity]?.backgroundColor};
    color: ${({ severity }) => SeverityToColorScheme[severity]?.color};
    text-transform: capitalize;
    > span {
      font-weight: 500;
    }
  }
`;

export const Loader = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
  >
    <LinesLoader />
  </Box>
);
