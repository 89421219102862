import React, { ReactElement } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

import { gray10, blueBrand } from "../../../Colors";

import nextPage from "./assets/next-page.svg";
import prevPage from "./assets/prev-page.svg";
import nextPageDisabled from "./assets/next-page-disabled.svg";
import prevPageDisabled from "./assets/prev-page-disabled.svg";

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  gap: 1rem;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  color: ${gray10};
  font-size: 12px;
  margin-right: 2.5rem;

  > li {
    cursor: pointer;
  }

  .next.disabled {
    background-image: url(${nextPageDisabled});
  }

  .previous.disabled {
    background-image: url(${prevPageDisabled});
  }

  .selected {
    color: ${blueBrand};
    font-weight: 700;
  }

  .next {
    height: 0.877rem;
    width: 0.5rem;
    color: rgba(0, 0, 0, 0);
    background-image: url(${nextPage});
    background-repeat: no-repeat;
  }

  .previous {
    height: 0.877rem;
    width: 0.5rem;
    color: rgba(0, 0, 0, 0);
    background-image: url(${prevPage});
    background-repeat: no-repeat;
  }
`;

export interface PaginationProps {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
}

export const Pagination = ({
  page,
  setPage,
  totalPages,
}: PaginationProps): ReactElement => (
  <StyledReactPaginate
    breakLabel="..."
    nextLabel=">"
    onPageChange={(event) => {
      setPage(event.selected);
    }}
    pageRangeDisplayed={3}
    marginPagesDisplayed={1}
    pageCount={totalPages}
    previousLabel="<"
    renderOnZeroPageCount={() => null}
    forcePage={page}
  />
);
