import { EnrichedMessage } from "../types";
import { K8sResourceToken, StringToken } from "../tokens";
import { KubernetesServiceResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceResource";

import { BaseEnricher } from "./baseEnricher";

export class InternalErrorFailedWebhook extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Internal error occurred: failed calling webhook "(\\S+)": .+ no endpoints available for service "(\\S+)"`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "Internal error occurred: failed calling webhook";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const service = match[2];

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          `A failure occurred in calling a webhook, as there are no available endpoints for`
        ),
        new K8sResourceToken(
          "the service",
          KubernetesServiceResource.Kind,
          service
        ),
      ],
    };
  }
}
