import { usePVCEvents } from "../../../shared/hooks/resources-api/client/events/pvcEvents/usePVCEvents";
import { useConfigMapEvents } from "../../../shared/hooks/resources-api/client/events/configMapEvents/useConfigMapEvents";
import { useSecretEvents } from "../../../shared/hooks/resources-api/client/events/secretEvents/useSecretEvents";
import {
  KubernetesConfigMapResource,
  KubernetesPVCResource,
  KubernetesSecretsResource,
} from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import {
  buildKomodorUid,
  DESC,
} from "../../../shared/hooks/resources-api/resourcesAPIUtils";

interface DefaultEventProps {
  komodorUids: string[];
  order: "ASC" | "DESC";
  limit: number;
}

export interface LastResourceStatusProps {
  resourceName: string;
  resourceKind: string;
  namespace: string;
  cluster: string;
}
export const useLastResourceStatus = ({
  resourceName,
  resourceKind,
  namespace,
  cluster,
}: LastResourceStatusProps) => {
  const props: DefaultEventProps = {
    komodorUids: [
      buildKomodorUid({
        kind: resourceKind,
        namespace,
        resourceName,
        clusterName: cluster,
      }),
    ],
    order: DESC,
    limit: 1,
  };

  const { data: pvcEvents } = usePVCEvents({
    ...props,
    enabled: !!resourceName && resourceKind === KubernetesPVCResource.Kind,
  });
  const { data: configMapEvents } = useConfigMapEvents({
    ...props,
    enabled:
      !!resourceName && resourceKind === KubernetesConfigMapResource.Kind,
  });
  const { data: secretEvents } = useSecretEvents({
    ...props,
    enabled: !!resourceName && resourceKind === KubernetesSecretsResource.Kind,
  });

  return {
    isDeleted:
      pvcEvents?.data?.[0]?.isDeleted ||
      configMapEvents?.data?.[0]?.action === "Delete" ||
      secretEvents?.data?.[0]?.action === "Delete",
    status: pvcEvents?.data?.[0]?.phase || "",
  };
};
