import { useMemo } from "react";
import { get, isEqual } from "lodash";
import { useFormContext } from "react-hook-form";

import { InputFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { severitiesList } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import {
  BasePolicy,
  CheckType,
  PolicyConfigurations,
} from "@/generated/reliabilityApi";
import { useGetFetchedPolicyData } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetFetchedPolicyData";

const excludedKeys: (keyof BasePolicy)[] = ["createdAt", "updatedAt", "id"];

export const useHasChangedFormValues = () => {
  const { getValues, watch } = useFormContext<InputFields>();
  const { data } = useGetFetchedPolicyData();

  const watchers = watch();

  return useMemo(() => {
    if (!data?.data.policy || !watchers) {
      return false;
    }

    const currentValues = getValues();

    if (!Object.keys(currentValues).length) return false;

    if (
      !isEnabledStateEqual(
        currentValues.configurations,
        data.data.policy.configurations
      )
    ) {
      return true;
    }

    return Object.entries(data.data.policy).some(([key, policyValue]) => {
      if (excludedKeys.includes(key as keyof BasePolicy)) {
        return false;
      }

      // key not found in currentValues
      if (currentValues[key as keyof InputFields] === undefined) {
        return true;
      }

      if (key !== "configurations") {
        return !isEqual(currentValues[key as keyof InputFields], policyValue);
      }

      return severitiesList.some((severity) => {
        return Object.keys(data.data.policy.configurations).some(
          (checkType) => {
            const configNames = getConfigurationFieldNamesByCheckTypeAsList(
              checkType as CheckType,
              severity
            );

            return configNames.some((path) => {
              const currentValue = get(currentValues, path);
              const policyValue = get(data.data.policy, path);
              return !isEqual(currentValue, policyValue);
            });
          }
        );
      });
    });
  }, [data?.data.policy, getValues, watchers]);
};

const isEnabledStateEqual = (
  currentConfigurations: PolicyConfigurations,
  fetchedConfigurations: PolicyConfigurations
) => {
  if (!currentConfigurations || !fetchedConfigurations) {
    return true;
  }
  const currentCheckTypes = Object.keys(currentConfigurations);
  const fetchedCheckTypes = Object.keys(fetchedConfigurations);

  return (
    currentCheckTypes.length === fetchedCheckTypes.length &&
    currentCheckTypes.every((checkType) => {
      const key = checkType as keyof PolicyConfigurations;
      return (
        currentConfigurations[key]?.enabled ===
        fetchedConfigurations[key]?.enabled
      );
    })
  );
};
