export const aOrAn = (word: string): string => {
  const firstLetter = word.charAt(0);
  const aOrAn = ["a", "e", "i", "o", "u"].includes(firstLetter) ? "an" : "a";
  return `${aOrAn} ${word}`;
};

export const aOrAnForInvestigationErrors = (word: string): string => {
  const firstLetter = word.charAt(0).toLowerCase();
  const aOrAn = ["a", "e", "i", "o", "u"].includes(firstLetter)
    ? word.toLowerCase().startsWith("oom")
      ? "a"
      : "an"
    : "a";
  return `${aOrAn} ${word}`;
};

export const shortenString = (
  str: string,
  numCharsBeforeDiv: number,
  numCharsAfterDiv: number,
  div = "..."
): string => {
  if (str.length <= numCharsBeforeDiv + numCharsAfterDiv) {
    return str;
  } else {
    const firstChars = str.slice(0, numCharsBeforeDiv);
    const lastChars = str.slice(-numCharsAfterDiv);
    return `${firstChars}${div}${lastChars}`;
  }
};
