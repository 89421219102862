import { ResourceTableModelRow } from "komodor-types";

export interface Properties {
  overrideRowClick?: (row: ResourceTableModelRow) => void;
  columns: ColumnsProperties;
}

export interface ColumnProperties {
  /**
   * The name of the column, i.e. its header.
   */
  name: string;

  /**
   * If `true`, the contents of all cells within said row will be clickable.
   */
  clickable: boolean;
}

export interface ColumnsProperties {
  data: ColumnProperties[];
}

export function findColumnProperties(
  props: ColumnsProperties,
  name: string
): ColumnProperties {
  const c = props.data.find((c) => c.name === name);
  if (!c) {
    return {
      name: "",
      clickable: false,
    };
  }
  return c;
}
