import styled from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Check16, Copy16 } from "@komodorio/design-system/icons";
import React from "react";
import useClipboard from "react-use-clipboard";

import { MediumBoldTypography } from "./MediumBoldTypography";

const GrayContainer = styled.div`
  background-color: ${palette.gray[100]};
  border: 1px solid ${palette.gray[200]};
  padding: 5px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  min-height: 20px;
  & > div:first-child {
    flex: 1;
  }
`;

const CopyIcon = styled(Copy16)`
  fill: ${theme.foreground.fgSubtle};
  :hover {
    fill: ${theme.foreground.fgDarkBlue};
  }
`;

export const ApiKeyGenerator: React.FC<{ generatedApiKey: string }> = ({
  generatedApiKey,
}) => {
  const [copied, copy] = useClipboard(generatedApiKey, {
    successDuration: 3000,
  });

  const getIcon = () => {
    if (!generatedApiKey.trim()) {
      return null;
    }
    return copied ? (
      <Check16 fill={theme.border.borderGreen} />
    ) : (
      <CopyIcon onClick={copy} />
    );
  };

  return (
    <GrayContainer>
      <MediumBoldTypography>{generatedApiKey}</MediumBoldTypography>
      {getIcon()}
    </GrayContainer>
  );
};
