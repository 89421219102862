import { ReliabilityStore } from "../ReliabilityTypes";

// getters
export const selectViolationsState = (state: ReliabilityStore) =>
  state.violationsState;

export const selectAddEditIgnoreRuleDialogState = (state: ReliabilityStore) =>
  state.addEditIgnoreRuleDialogState;
export const selectReliabilityNotifications = (state: ReliabilityStore) =>
  state.reliabilityNotifications;
export const selectInitialTime = (state: ReliabilityStore) => state.initialTime;

// setters
export const selectSetUngroupedTotalViolationsCount = (
  state: ReliabilityStore
) => state.setUngroupedTotalViolationsCount;
export const selectSetGroupExpandedState = (state: ReliabilityStore) =>
  state.setGroupExpandedState;
export const selectSetGroupState = (state: ReliabilityStore) =>
  state.setGroupState;
export const selectSetAddEditIgnoreRuleDialogState = (
  state: ReliabilityStore
) => state.setAddEditIgnoreRuleDialogState;
export const selectSetReliabilityNotification = (state: ReliabilityStore) =>
  state.setReliabilityNotification;
export const selectRemoveReliabilityNotification = (state: ReliabilityStore) =>
  state.removeReliabilityNotification;
