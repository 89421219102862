import React from "react";
import Typography from "@mui/material/Typography";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";
import { Details } from "./Details/Details";

export const HighRequestsToLimitRatioDrawer: React.FC = () => {
  const { data } = useViolation();

  const { supportingData, komodorUid, headerProps, summaryProps } =
    useGetCommonDrawerProps(data?.data.violation);

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription(
                supportingData?.requestsLimitsRatio?.containers.length
              )}{" "}
              <Details
                komodorUid={komodorUid ?? ""}
                containers={supportingData?.requestsLimitsRatio?.containers}
                checkType={data.data.violation?.checkType}
              />
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={
            <Typography variant="body2" color="text.primary">
              {howDescription}
            </Typography>
          }
        />
      }
    />
  );
};
