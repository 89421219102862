import React, { useMemo, useState } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import { capitalize } from "lodash";

import { useSetIsPaginating } from "../../hooks/useSetIsPaginating";

import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";
import { useRefetchIntervalFromContext } from "@/components/k8sAddons/hooks/useRefetchIntervalFromContext";
import { useIsFilterFetchingData } from "@/components/k8sAddons/components/AddonFilterHandler/addonFilterHandlerHooks";
import { SelectFilterHandler } from "@/shared/components/urlFilters/SelectFilterHandler";
import { GenericFilter } from "@/generated/addonsApi";
import { useShouldRequestBeEnabled } from "@/components/k8sAddons/hooks/useShouldRequestBeEnabled";

type SelectProps = Omit<
  React.ComponentProps<typeof AdvancedMultiSelect<string>>,
  "options" | "values" | "onChange" | "onClose" | "onOpen"
>;
type FiltersContainerProps = {
  filterCategory: string;
  label: string;
  additionalFilters?: GenericFilter[];
  capitalizeOptions?: boolean;
  selectProps?: SelectProps;
};

export const AddonFilterHandler: React.FC<FiltersContainerProps> = ({
  filterCategory,
  label,
  additionalFilters = [],
  capitalizeOptions = false,
  selectProps,
}) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity } = useAddonContext();
  const refetchInterval = useRefetchIntervalFromContext(isPaginating);

  const genericFiltersList =
    useFilterListFromUrlAsGenericFilters(filterCategory);

  const filters = [...genericFiltersList, ...additionalFilters];

  const { data, isLoading, isFetching } = useGetAddonAggregations(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityAggregationsRequest: {
        groupBy: filterCategory,
        aggregation: "count",
        filter: filters,
      },
    },
    {
      keepPreviousData: true,
      refetchInterval,
      enabled: useShouldRequestBeEnabled(),
    }
  );

  useSetIsPaginating({
    fetchedIsPaginating: data?.data.isPaginating,
    setIsPaginating,
  });

  const { isFetchingData } = useIsFilterFetchingData({
    filterCategory,
    isFetching,
  });

  const options = useMemo<MuiSelectionOption<string>[]>(() => {
    return (
      data?.data.items?.map((item) => ({
        value: item.key ?? "",
        label: capitalizeOptions ? capitalize(item.key) : item.key ?? "",
      })) ?? []
    );
  }, [capitalizeOptions, data?.data.items]);

  return (
    <SelectFilterHandler
      filterCategory={filterCategory}
      label={label}
      selectProps={selectProps}
      options={options}
      disable={isLoading || isFetchingData}
    />
  );
};
