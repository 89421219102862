import React, { useMemo } from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { min } from "date-fns";

import { Alert } from "../../../common/Alert/Alert";
import { costOptimizationAriaLabels } from "../../constants/costOptimizationAriaLabels";
import { useCostOptimizationStore } from "../../store/costOptimizationStore";
import { selectClustersEarliestRowTime } from "../../store/costOptimizationStoreSelectors";
import { ClustersEarliestRowTime } from "../../types/costOptimizationTypes";
import { getIsNewCluster } from "../../../../shared/utils/metricsAgentInfoAccuracyUtils";

import { description, title } from "./texts";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getDatesFromEarliestRowsTimeResponse = (
  clusters: ClustersEarliestRowTime | undefined
) => {
  if (!clusters?.clusters) return [];
  return Object.values(clusters.clusters).map((date) => new Date(date));
};

export const CollectingDataAlert: React.FC = () => {
  const { clusters } = useCostOptimizationStore(selectClustersEarliestRowTime);

  const shouldShowAlert = useMemo(() => {
    if (!clusters || Object.keys(clusters).length === 0) {
      return false;
    }
    const dates = getDatesFromEarliestRowsTimeResponse({ clusters: clusters });

    const earliestDate = min(dates);

    return getIsNewCluster(earliestDate);
  }, [clusters]);

  if (!shouldShowAlert) return null;

  return (
    <Alert
      severity={"info"}
      title={title}
      text={description}
      icon={InfoOutlined as React.FC}
      ariaLabel={costOptimizationAriaLabels.collectingDataAlert.container}
    />
  );
};
