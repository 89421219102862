import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import {
  ColorIndication,
  PaddedCard,
} from "../../OverviewPage/Insights/shared/styles";

interface CustomTooltipContainerProps {
  tooltipTitle: string;
  payload: Payload<ValueType, NameType>[];
  labelFormat: (label: string) => string;
  valueFormat: (value: number) => string;
}

const Card = styled(PaddedCard)`
  display: flex;
  flex-direction: column;
  min-width: 6rem;
`;

const ChartTooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryContainer = styled(Container)`
  gap: 6px;
`;

const ValueContainer = styled(Container)`
  gap: 0.2rem;
  margin-left: 5px;
`;

export const CustomTooltipContainer: React.FC<CustomTooltipContainerProps> = ({
  tooltipTitle,
  payload,
  labelFormat,
  valueFormat,
}) => {
  const content = useMemo(
    () =>
      payload.map((resourceRow) => {
        return (
          <ChartTooltipItem key={resourceRow.dataKey}>
            <CategoryContainer>
              <ColorIndication color={resourceRow.color} />
              {labelFormat(resourceRow.name as string)}
            </CategoryContainer>
            <ValueContainer>
              <Typography variant="body2">
                {valueFormat(resourceRow.value as number)}
              </Typography>
            </ValueContainer>
          </ChartTooltipItem>
        );
      }),
    [labelFormat, payload, valueFormat]
  );

  return (
    <Card>
      <Typography variant="h4">{tooltipTitle}</Typography>
      {content}
    </Card>
  );
};
