import { useMemo } from "react";

import { KOMODOR_SERVICES_SEARCH } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesAPIGet } from "../../../shared/hooks/resources-api/client";
import {
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  KomodorServicesResponse,
} from "../../../generated/resourcesApi";
import { mockNodesData1 } from "../__tests__/hugh_demo_data/mockNodesData/mockNodesData1";
import { mockNodesData2 } from "../__tests__/hugh_demo_data/mockNodesData/mockNodesData2";
import { mockNodesData3 } from "../__tests__/hugh_demo_data/mockNodesData/mockNodesData3";
import { getCrdsDataFromKomodorUids } from "../utils/graphUtils";

const mockDataMap = [
  {
    komodorUid: "Deployment|production|hugh|correlation-engine",
    data: mockNodesData1,
  },
  {
    komodorUid: "Deployment|production|ofer|correlation-engine",
    data: mockNodesData2,
  },
  {
    komodorUid: "Deployment|production|demo|correlation-engine",
    data: mockNodesData3,
  },
];

export const useNetworkMapNodesData = (
  komodorUids: string[]
): KomodorServicesResponse => {
  const queryVars = useMemo(
    (): KomodorServicesApiApiV1KomodorServicesSearchGetRequest => ({
      komodorUids,
      fields: [
        "health_state",
        "k8sClusterName",
        "displayName",
        "kind",
        "namespace",
        "deploy_state",
      ],
    }),
    [komodorUids]
  );
  const {
    data = {
      data: [],
    },
  } = useResourcesAPIGet(
    KOMODOR_SERVICES_SEARCH,
    queryVars,
    komodorUids.length === 0
  );

  const dataWithCRDs = useMemo(() => {
    const additionalCRDNode = getCrdsDataFromKomodorUids(komodorUids);
    return { data: [...(data.data || []), ...additionalCRDNode] };
  }, [data.data, komodorUids]);

  const dataToReturn =
    mockDataMap.find((mockData) =>
      komodorUids.find((uid) => uid === mockData.komodorUid)
    )?.data || dataWithCRDs;

  return dataToReturn;
};
