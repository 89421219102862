export const mockKomodorServices = (len = 10) => {
  return Array.from({ length: len }, (_, i) => {
    const useLongName = Math.random() > 0.7;

    return {
      komodorServiceId: `service-id-${i}`,
      komodorServiceName: `service-name-${i}${
        !useLongName ? "" : "-with-a-very-long-name"
      }`,
      cluster: `test-cluster${!useLongName ? "" : "-with-a-very-long-name"}`,
      namespace: `test-namespace${
        !useLongName ? "" : "-with-a-very-long-name"
      }`,
    };
  });
};
