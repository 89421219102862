import { capitalize, camelCase } from "lodash";
import { useMemo } from "react";

import useAccount from "../useAccount/useAccount";
import { useIsRbacEnabledByAccountId } from "../useIsRbacEnabledByAccountId/useIsRbacEnabledByAccountId";
import type { QueryState as RbacEnabledQueryState } from "../useIsRbacEnabledByAccountId/useIsRbacEnabledByAccountId";

import useUserMetadata from "./useUserMetadata";

const defaultPermissions = [
  "manage:users",
  "manage:monitors",
  "manage:integrations",
  "manage:helm",
  "manage:features",
  "manage:reliability",
  "manage:reliability-policies",
  "manage:workspaces",
  "manage:account-access",
  "manage:usage",
  "manage:agents",
  "manage:kubeconfig",
  "get:kubeconfig",
  "view:audit",
  "view:namespaces",
];

export const useHasPermissions = (): Record<string, boolean> => {
  const userMetadata = useUserMetadata();
  const account = useAccount(userMetadata.accountId);

  return useMemo(() => {
    let allowedActions: string[] | undefined;
    if (!account?.isRbacEnabled || userMetadata.isKomodorAdmin) {
      allowedActions = defaultPermissions;
    } else {
      allowedActions = userMetadata.allowedActions;
    }

    // If '*' is present, return all permissions as true
    if (allowedActions?.includes("*")) {
      return new Proxy(
        {},
        {
          get: () => true,
        }
      );
    }
    return (
      allowedActions
        ?.map((a) => {
          const splitted = a.split(":");
          if (splitted.length === 2) {
            return camelCase(
              `can${capitalize(splitted[0])}${capitalize(splitted[1])}`
            );
          }
          return "";
        })
        .reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {}
    );
  }, [
    account?.isRbacEnabled,
    userMetadata.allowedActions,
    userMetadata.isKomodorAdmin,
  ]);
};

export const useRbacEnabled = (): boolean | undefined => {
  const userMetadata = useUserMetadata();
  const account = useAccount(userMetadata.accountId);
  return account?.isRbacEnabled;
};

export const useRbacEnabledQuery = (): RbacEnabledQueryState => {
  const userMetadata = useUserMetadata();

  return useIsRbacEnabledByAccountId(userMetadata.accountId);
};
