import React from "react";
import { useParams } from "react-router-dom";

import ResourceView from "../ResourceView/ResourceView";
import useResourceWithInterval from "../ResourceView/useResource";
import { useActiveAgent } from "../../shared/hooks/useAgents";
import { LinesLoader } from "../common/loaders/Line";
import { parseKomodorUid } from "../../shared/hooks/resources-api/resourcesAPIUtils";

import { useDefaultArgoWorkflowTemplate } from "./useDefaultArgoWorkflowTemplate";
import { ArgoWorkflowNotFound } from "./ArgoWorkflowNotFound";

import { WORKFLOW_ID_PARAM } from "@/components/routes/routes";

const ArgoWorkflowView: React.FC = () => {
  const { komodorWorkflowId } = useParams<{
    [WORKFLOW_ID_PARAM]: string;
  }>();
  const parsedArgoWorkflowKomodorUid = parseKomodorUid(komodorWorkflowId ?? "");

  const agentId = useActiveAgent(parsedArgoWorkflowKomodorUid?.cluster) ?? "";

  const { resource, isFetching } = useResourceWithInterval({
    agentId,
    cluster: parsedArgoWorkflowKomodorUid?.cluster ?? "",
    namespace: parsedArgoWorkflowKomodorUid?.namespace ?? "",
    resourceName: parsedArgoWorkflowKomodorUid?.name ?? "",
    resourceType: parsedArgoWorkflowKomodorUid?.kind ?? "",
    executeMutation: !!parsedArgoWorkflowKomodorUid && agentId !== "",
  });

  const defaultResource = useDefaultArgoWorkflowTemplate(
    parsedArgoWorkflowKomodorUid
  );

  return isFetching ? (
    <LinesLoader />
  ) : resource ? (
    <ResourceView resource={resource} />
  ) : defaultResource ? (
    <ResourceView resource={defaultResource} />
  ) : (
    <ArgoWorkflowNotFound />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ArgoWorkflowView;
