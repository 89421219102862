import { useEffect } from "react";

import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useDefaultFilters } from "@/components/reliability/hooks/useDefaultFilters";

export const useSetDefaultFiltersInUrl = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();
  const defaultSelectedViolationFilters = useDefaultFilters();

  useEffect(() => {
    if (dataInUrl.filters === undefined) {
      setDataInUrl({ filters: defaultSelectedViolationFilters }, true);
    }
  }, [dataInUrl.filters, defaultSelectedViolationFilters, setDataInUrl]);
};
