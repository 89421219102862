export enum AvailableActions {
  Edit = "edit",
  FetchLogs = "fetchLogs",
  Delete = "delete",
  JobRerun = "jobRerun",
  RunNow = "runNow",
  Scale = "scale",
  Restart = "restart",
  CordonUncordon = "cordonUncordon",
  Drain = "drain",
  Configure = "configure",
  Rollback = "rollback",
  Compare = "compare",
  Describe = "describe",
  UpgradeHelmChart = "upgradeHelmChart",
  UninstallHelmChart = "uninstallHelmChart",
  RollbackHelmChart = "rollbackHelmChart",
  UpgradeHelmRepo = "upgradeHelmRepo",
  ExecPodShell = "execPodShell",
  PortForward = "portForward",
  GitRevert = "gitRevert",
  ArgoWorkflowTemplateDetails = "WorkflowTemplateDetails",
  ArgoClusterWorkflowTemplateDetails = "ClusterWorkflowTemplateDetails",
  ArgoCronWorkflowDetails = "CronWorkflowDetails",
  SuspendCronJob = "suspendCronJob",
  ResumeCronJob = "resumeCronJob",
}
