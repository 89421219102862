import React, { useRef } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { PolicyListItem } from "../../../../../../../generated/reliabilityApi";
import { EllipsisContainerWithTooltip } from "../../../../../../../shared/components/EllipsisContainerWithTooltip";
import { OverflowTextStyle } from "../../../../../../../shared/styles/styles";
import { useHasTextEllipsis } from "../../../../../../../shared/hooks/useHasTextEllipsis";

const Container = styled.div`
  ${OverflowTextStyle};
`;

const TypographyWithTextEllipsis = styled(Typography)`
  && {
    ${OverflowTextStyle};
  }
`;

export const NameCell: React.FC<PolicyListItem> = ({ name, description }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const showTooltip = useHasTextEllipsis(descriptionRef);

  return (
    <Container>
      <EllipsisContainerWithTooltip tooltipValue={name}>
        {name}
      </EllipsisContainerWithTooltip>
      <Tooltip
        title={showTooltip ? description : undefined}
        placement={"top-start"}
      >
        <TypographyWithTextEllipsis
          variant={"body3"}
          color={"text.secondary"}
          ref={descriptionRef}
        >
          {description}
        </TypographyWithTextEllipsis>
      </Tooltip>
    </Container>
  );
};
