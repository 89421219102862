import {
  Number,
  Record,
  Static,
  Boolean,
  String,
  Array as RTArray,
} from "runtypes";

export const IssuesAfterDeployRecord = Record({
  day: String,
  num_days: Number,
  count_triggered: Boolean,
  duration_triggered: Boolean,
  count_norm: Number,
  count_current: Number,
  duration_norm: Number,
  duration_current: Number,
  deployment_ids: RTArray(String),
});
export type IssuesAfterDeployType = Static<typeof IssuesAfterDeployRecord>;
