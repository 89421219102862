import React, { useEffect, useState } from "react";
import { palette } from "@komodorio/design-system";
import { ChevronRight16, Placeholder16 } from "@komodorio/design-system/icons";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { ButtonLinkStyle } from "../internal/styles";

const UnselectableButtonLink = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${ButtonLinkStyle}
`;

const LinkGroupTitle = styled(Typography).attrs({
  variant: "text",
  size: "medium",
  color: palette.white[0],
})<{ expanded: boolean }>`
  ${({ expanded }) => (expanded ? "font-weight: 700" : "")}
`;

const LinkGroupIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1rem;
`;

const Arrow = styled(ChevronRight16)<{
  $groupExpanded: boolean;
}>`
  transition: transform 0.1s;
  transform: ${({ $groupExpanded }) =>
    $groupExpanded ? "rotate(90deg)" : "rotate(0deg)"};
`;

const ExpandingContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? "1000px" : "0")};
  transition: max-height 0.4s;
  padding-left: 1rem;
`;

const LinkGroupIcon: React.FC<{ icon: JSX.Element; expanded: boolean }> = ({
  icon,
  expanded,
}) => (
  <LinkGroupIconContainer>
    <Arrow $groupExpanded={expanded} /> {icon}
  </LinkGroupIconContainer>
);

interface LinkGroupProps extends React.PropsWithChildren {
  title: string;
  icon?: JSX.Element;
  e2eSelector?: string;
  alwaysExpanded?: boolean;
}

export const LinkGroup: React.FC<LinkGroupProps> = ({
  title,
  icon,
  e2eSelector,
  children,
  alwaysExpanded = false,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(alwaysExpanded || false);

  useEffect(() => {
    if (!alwaysExpanded) {
      setExpanded(
        location.pathname.includes(title.toLowerCase().replaceAll(" ", "-"))
      );
    }
  }, [alwaysExpanded, location, title]);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <div data-e2e-selector={e2eSelector}>
      <UnselectableButtonLink role="button" onClick={toggleExpanded}>
        <LinkGroupIcon icon={icon ?? <Placeholder16 />} expanded={expanded} />
        <LinkGroupTitle expanded={expanded}>{title}</LinkGroupTitle>
      </UnselectableButtonLink>
      <ExpandingContainer expanded={expanded}>
        {expanded && children}
      </ExpandingContainer>
    </div>
  );
};
