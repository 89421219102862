import { getFixedNumber } from "../../../../../shared/utils/formatCurrency";
import { CostRightSizingSummary } from "../../../../../generated/metricsApi";

export const getRightSizingCsvHeaders = (): Record<string, string> => {
  return {
    clusterName: "Cluster",
    namespace: "Namespace",
    service: "Service",
    komodorServiceKind: "Kind",
    cpuRequestCores: "CPU requests (Cores)",
    cpuRecommendation: "CPU recommendation (Cores)",
    memoryRequestsMiB: "Memory requests (GB)",
    memoryRecommendation: "Memory recommendation (GB)",
    optimizationScore: "Optimization Score",
    potentialSaving: "Potential Saving (USD)",
  };
};

const getRecommendation = (request: number, recommendation: number): number => {
  if (request < recommendation) return request;
  return recommendation;
};

export const getValue = (
  category: keyof CostRightSizingSummary,
  row: Omit<CostRightSizingSummary, "shareOfCost">
) => {
  const value = row[category as keyof typeof row];

  if (typeof value === "string") {
    if (!value) return "-";
    return value;
  }

  switch (category) {
    case "cpuRequestCores":
      return `${getFixedNumber(value)}`;
    case "cpuRecommendation":
      return `${getFixedNumber(
        getRecommendation(row["cpuRequestCores"], value)
      )}`;
    case "memoryRequestsMiB":
      return `${getFixedNumber(value)}`;
    case "memoryRecommendation":
      return `${getFixedNumber(
        getRecommendation(row["memoryRequestsMiB"], value)
      )}`;
    case "optimizationScore":
      return `${value.toFixed(2)}%`;
    case "potentialSaving":
      return `${getFixedNumber(value)}`;
    default:
      return `${value}`;
  }
};

export const sortRows = (rows?: CostRightSizingSummary[]) => {
  if (!rows) return;
  return [...rows].sort((a, b) => b.potentialSaving - a.potentialSaving);
};
