import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacRolesApiApiV1RbacRolesGetRequest,
  RbacRole,
  apiV1RbacRolesGetUrl,
} from "../../../../../generated/auth";

const RBAC_ROLES_PATH = "/rbac-roles";

const fetchRbacRoles = async (
  apiClient: AxiosInstance,
  params: RbacRolesApiApiV1RbacRolesGetRequest = {}
): Promise<RbacRole[]> => {
  const { data } = await apiClient.get(
    apiV1RbacRolesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacRoles = (
  params?: RbacRolesApiApiV1RbacRolesGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_ROLES_PATH, params],
    () => fetchRbacRoles(apiClient, params),
    {
      enabled,
    }
  );
};
