import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import React, { createContext, useState } from "react";

import { ServicesComparisonTabs } from "./constants";

const initialServicesComparisonState: ServicesComparisonState = {
  baselineServiceId: undefined,
  comparedServicesIds: [],
  setBaselineServiceId: () => undefined,
  setComparedServicesIds: () => undefined,
  selectedTab: ServicesComparisonTabs.Attributes,
  setSelectedTab: () => undefined,
};

export type ServicesComparisonState = {
  baselineServiceId: MuiSelectionOption<string> | undefined;
  comparedServicesIds: MuiSelectionOption<string>[] | undefined;
  setBaselineServiceId: (val: MuiSelectionOption<string> | undefined) => void;
  setComparedServicesIds: (
    val: MuiSelectionOption<string>[] | undefined
  ) => void;
  selectedTab: ServicesComparisonTabs;
  setSelectedTab: (val: ServicesComparisonTabs) => void;
};

export const ServicesComparisonContext = createContext<ServicesComparisonState>(
  initialServicesComparisonState
);

export const ServicesComparisonContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [baselineServiceId, setBaselineServiceId] = useState<
    MuiSelectionOption<string> | undefined
  >(undefined);
  const [comparedServicesIds, setComparedServicesIds] =
    useState<MuiSelectionOption<string>[]>();
  const [selectedTab, setSelectedTab] = useState<ServicesComparisonTabs>(
    ServicesComparisonTabs.Attributes
  );

  const contextState: ServicesComparisonState = {
    baselineServiceId,
    comparedServicesIds,
    setBaselineServiceId,
    setComparedServicesIds,
    selectedTab,
    setSelectedTab,
  };

  return (
    <ServicesComparisonContext.Provider value={contextState}>
      {children}
    </ServicesComparisonContext.Provider>
  );
};
