import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";

import { DisabledInput } from "@/components/Settings/email/DisabledInput";
import { useGetAllClusterNamesAsOptions } from "@/shared/hooks/useGetAllClusterNamesAsOptions";
import { CheckboxController } from "@/components/Settings/email/CheckboxController";
import { CheckboxGroupController } from "@/components/Settings/email/CheckboxGroupController";
import { SelectController } from "@/components/Settings/email/SelectController";
import { MultiSelectController } from "@/components/Settings/email/MultiSelectSelectController";
import { frequencySelectOptions } from "@/components/Settings/email/emailSettingsConstants";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";
import { useSetDataFromServer } from "@/components/Settings/email/emailSettingsHooks";
import { SubmitButton } from "@/components/Settings/email/SubmitButton";
import { NotificationSnackbar } from "@/components/Settings/email/NotificationSnackbar";
import { emailSettingsAriaLabels } from "@/components/Settings/email/emailSettingsAriaLabels";
import { SwitchController } from "@/components/Settings/email/SwitchController";

const {
  mainContainer: mainContainerAriaLabel,
  isSubscribed: isSubscribedAriaLabel,
  emailAddress: emailAddressAriaLabel,
  frequency: frequencyAriaLabel,
  scope: scopeAriaLabel,
} = emailSettingsAriaLabels;

const INPUT_WIDTH = 270;
const INPUT_PADDING = 24;

const Container = styled(Paper)`
  padding: 16px 24px;
`;

const LoaderContainer = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ContentContainer = styled.div`
  min-width: 700px;
`;

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDisabledInput = styled(DisabledInput)`
  input {
    width: ${INPUT_WIDTH - INPUT_PADDING}px;
  }
`;

export const EmailSettingsContent: React.FC = () => {
  const [showAlert, setShowAlert] = useState(true);
  const allClusterNamesAsOptions = useGetAllClusterNamesAsOptions();

  const userEmail = useUserLoginInfo()?.email ?? "";

  const { isDataSet } = useSetDataFromServer();

  if (!isDataSet)
    return (
      <LoaderContainer>
        <LinesLoader />
      </LoaderContainer>
    );

  return (
    <Container aria-label={mainContainerAriaLabel}>
      <ContentContainer>
        <TopSection>
          <Typography variant={"h4"}>Komodor Digest</Typography>
          <SwitchController
            dataKey={"isSubscribed"}
            ariaLabel={isSubscribedAriaLabel}
          />
        </TopSection>
        <Stack spacing={2}>
          {showAlert && (
            <Alert
              variant={"standard"}
              severity="info"
              color="running"
              onClose={() => setShowAlert(false)}
            >
              NOTE: Email settings are personal. Any selections you make are for
              your user only.
            </Alert>
          )}

          <StyledDisabledInput
            label={"Email address"}
            value={userEmail}
            helperText={"The digest will be sent to this email address"}
            ariaLabel={emailAddressAriaLabel}
          />
          <SelectController
            options={frequencySelectOptions}
            contextKey={"frequency"}
            selectProps={{
              label: "Send me the digest:",
              width: `${INPUT_WIDTH}px`,
              ariaLabel: frequencyAriaLabel,
            }}
          />
          <div>
            <Typography variant={"body2"}>Digest scope:</Typography>
            <MultiSelectController
              options={allClusterNamesAsOptions}
              contextKey={"scope.clusters"}
              selectProps={{
                placeholder: "select cluster",
                enableFreeSolo: true,
                ariaLabel: scopeAriaLabel,
              }}
            />
          </div>

          <CheckboxesContainer>
            <Typography variant={"body2"}>Digest content:</Typography>

            <CheckboxGroupController
              groupKey={"content.reliability.isEnabled"}
              childrenContextKey={"content.reliability.violations.severities"}
            >
              <CheckboxController
                contextKey={"content.reliability.violations.severities.high"}
              />
              <CheckboxController
                contextKey={"content.reliability.violations.severities.medium"}
              />
              <CheckboxController
                contextKey={"content.reliability.violations.severities.low"}
              />
            </CheckboxGroupController>
            <CheckboxController
              contextKey={"content.failedDeploys.isEnabled"}
            />
            <CheckboxController contextKey={"content.issues.isEnabled"} />
          </CheckboxesContainer>
        </Stack>

        <Stack spacing={2}>
          <Typography variant={"body2"}>
            If you don’t have any violations, availability issues, or failed
            deploys we wont bother you with an empty mail :)
          </Typography>
          <SubmitButton />
        </Stack>
      </ContentContainer>
      <NotificationSnackbar />
    </Container>
  );
};
