import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { SeverityChip } from "../../../shared/SeverityChip/SeverityChip";
import { Severity } from "../../../../ReliabilityTypes";
import {
  getSortedSeveritiesEntries,
  getTotalViolationsFromCountDict,
} from "../../../../utils/reliabilityUtils";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { formatNumber } from "../../../../../../shared/utils/formatNumber";

import { Dictionary } from "@/shared/types/Dictionary";

const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const {
  severities: { container: severitiesContainerArialLabel },
} = reliabilityArialLabels;

type SeveritiesProps = {
  severities: Dictionary<number> | undefined;
};

export const Severities: React.FC<SeveritiesProps> = ({ severities }) => {
  const totalViolations = useMemo(
    () => getTotalViolationsFromCountDict(severities ?? {}),
    [severities]
  );

  const getSeverityChip = (severity: string, count: number) => (
    <SeverityChip
      severity={severity as Severity}
      count={count}
      key={severity}
    />
  );

  const severityChips = useMemo(
    () =>
      getSortedSeveritiesEntries(severities ?? {}).map(([severity, count]) =>
        getSeverityChip(severity, count ?? 0)
      ),

    [severities]
  );

  if (!severities) return null;

  return (
    <Container aria-label={severitiesContainerArialLabel}>
      <Typography variant={"body2"}>
        {formatNumber(totalViolations)} violations
      </Typography>
      {severityChips}
    </Container>
  );
};
