import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { useReliabilityDataInUrl } from "../../../../hooks/useReliabilityDataInUrl";
import { ViolationCountBy } from "../../../../../../generated/reliabilityApi";
import {
  defaultTimeWindow,
  timeWindowOptions,
} from "../../../../constants/violationFiltersConstants";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const timeWindowFilterKey = ViolationCountBy.CreatedAt;

const getLabelByValue = (value: string) => {
  return (
    timeWindowOptions.find((option) => option.value === value)?.label ?? ""
  );
};

export const TimeWindowSelector: React.FC<{ withLabel?: boolean }> = ({
  withLabel = true,
}) => {
  const [dataInUrl, setDataInUrl] = useReliabilityDataInUrl();
  const urlFilters = useMemo(
    () => dataInUrl.filters ?? {},
    [dataInUrl.filters]
  );

  const currentValue = useMemo<MuiSelectionOption<string>>(() => {
    const valueToUse =
      urlFilters[timeWindowFilterKey]?.[0].values[0] ??
      timeWindowOptions[0].value;

    return {
      value: valueToUse,
      label: getLabelByValue(valueToUse),
    };
  }, [urlFilters]);

  const options = useMemo(() => {
    return timeWindowOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));
  }, []);

  const onChange = useCallback(
    (selectedOption?: MuiSelectionOption<string>) => {
      if (!selectedOption) return;
      setDataInUrl({
        filters: {
          ...urlFilters,
          [timeWindowFilterKey]: [
            { values: [selectedOption.value], operator: "include" },
          ],
        },
      });
    },
    [setDataInUrl, urlFilters]
  );

  useEffect(() => {
    if (!getLabelByValue(currentValue.value)) {
      onChange({
        value: defaultTimeWindow,
        label: getLabelByValue(defaultTimeWindow),
      });
    }
  }, [currentValue.value, onChange, options]);

  return (
    <Container>
      {withLabel && <Typography variant={"body2"}>Created:</Typography>}
      <SingleSelect
        options={options}
        width="200px"
        onChange={onChange}
        value={currentValue}
        ariaLabel={reliabilityArialLabels.timeWindowSelector}
      />
    </Container>
  );
};
