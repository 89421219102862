import styled from "styled-components";

export const TopContainer = styled.div`
  color: #3d4048;
`;

export const Container = styled.div`
  margin: 24px 0;
  min-height: 85vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 5fr;
  gap: 1rem 2rem;
`;

export const FiltersContainer = styled.div`
  grid-row: span 2;
  display: grid;
  gap: 1rem;
  align-content: start;
`;

export const Panel = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
