import React from "react";
import { muiPalette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import styled from "styled-components";

import { OverflowTextStyle } from "../../../shared/styles/styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AssociationContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ChevronRightIcon = styled(ChevronRight).attrs({
  fontSize: "inherit",
})`
  color: ${muiPalette.action?.active};
`;

const AssociationItem = styled(Typography).attrs({ variant: "body3" })`
  color: ${muiPalette.text?.secondary};
  ${OverflowTextStyle}
  max-width: 40%;
`;

const Title = styled(Typography).attrs({ variant: "h4" })`
  color: ${muiPalette.text?.primary};
  ${OverflowTextStyle}
  max-width: 95%;
`;

interface StaticPreventionTitleProps {
  cluster: string;
  namespace: string;
  name: string;
}

export const StaticPreventionTitle: React.FC<StaticPreventionTitleProps> = ({
  cluster,
  namespace,
  name,
}) => {
  return (
    <Container>
      <AssociationContainer>
        <AssociationItem>{cluster}</AssociationItem>
        <ChevronRightIcon />
        <AssociationItem>{namespace}</AssociationItem>
        <ChevronRightIcon />
      </AssociationContainer>
      <Title>{name}</Title>
    </Container>
  );
};
