import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";

import {
  AggregationsListItem,
  AggregationsListItemProps,
  DIVIDER_CLASS_NAME,
} from "@/components/k8sAddons/components/AggregationsListItem";
import { useResizeObserver } from "@/shared/hooks/useResizeObserver";
import { k8sAriaLabels } from "@/components/k8sAddons/k8sAriaLabels";

const Container = styled(Paper)`
  width: 100%;
`;

const PaddingContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 16px;
  align-self: center;
  justify-content: space-between;
  & > div {
    flex: 1 1 0;
  }
`;

const {
  dataAggregations: { container: aggregationContainerAriaLabel },
} = k8sAriaLabels;

type AggregationsListProps = {
  listItemsData: AggregationsListItemProps[];
};

export const AggregationsList: React.FC<AggregationsListProps> = ({
  listItemsData,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const aggregationItems = useMemo(() => {
    return listItemsData.map((filter) => {
      return <AggregationsListItem {...filter} key={filter.title} />;
    });
  }, [listItemsData]);

  const onResize = useCallback(() => {
    const aggregationElements = containerRef.current?.children;
    if (!aggregationElements) return;
    [...aggregationElements].forEach((element, idx) => {
      const elementY = element.getBoundingClientRect().y;
      const nextElement = aggregationElements[idx + 1];
      if (!nextElement) return;
      const nextElementY = nextElement.getBoundingClientRect().y;
      if (nextElementY > elementY) {
        element.classList.remove(DIVIDER_CLASS_NAME);
      } else {
        element.classList.add(DIVIDER_CLASS_NAME);
      }
    });
  }, []);

  useResizeObserver(containerRef, onResize);

  return (
    <Container aria-label={aggregationContainerAriaLabel}>
      <PaddingContainer ref={containerRef}>{aggregationItems}</PaddingContainer>
    </Container>
  );
};
