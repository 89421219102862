type DownloadFileParams = {
  data: string;
  fileName: string;
  blobType: string;
};

export const downloadFile = ({
  fileName,
  data,
  blobType,
}: DownloadFileParams) => {
  const blob = new Blob([data], {
    type: blobType,
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.click();
};
