import React from "react";
import { theme } from "@komodorio/design-system";
import { Tag, Typography, TagProps } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import type * as CSS from "csstype";

import { DescribeItem } from "./DescribeItem";

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.75rem;
  word-break: break-all;
`;

export const GrayTag: React.FC<TagProps> = (props) => {
  return (
    <Tag {...props} color={theme.background.bgGray}>
      <Typography>{props.children}</Typography>
    </Tag>
  );
};

export const DescribeTagListItem: React.FC<{
  name: string;
  value: string[];
  alignName?: CSS.Property.AlignSelf;
}> = ({ name, value, alignName }) => {
  return (
    <DescribeItem name={name} alignName={alignName}>
      <Tags>
        {value.map((v) => (
          <GrayTag key={v}>{v}</GrayTag>
        ))}
      </Tags>
    </DescribeItem>
  );
};

export const DescribeKeyValueItem: React.FC<{
  name: string;
  value: { [key: string]: string };
}> = ({ name, value }) => {
  return (
    <DescribeItem name={name}>
      <Tags>
        {Object.entries(value).map(([key, val]) => (
          <GrayTag key={key}>
            {key}={val}
          </GrayTag>
        ))}
      </Tags>
    </DescribeItem>
  );
};
