import { SC } from "@komodorio/design-system/icons";

import { AvailableActions } from "./AvailableActions";

export const KubernetesStorageClassResource = {
  NameInK8S: "storageclasses",
  Group: "storage",
  NavBarName: "Storage Classes",
  PresentName: "Storage Classes",
  Namespaced: false,
  UnnecessaryColumns: ["volumebindingmode", "allowvolumeexpansion"],
  SupportedActions: [AvailableActions.Delete],
  Kind: "StorageClass",
  Icon: SC,
  hasLabels: true,
};
