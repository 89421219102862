import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../../shared/hooks/analytics";

export function sendAnalyticsFromRelatedResources(
  oldSelectedResourceUids: { [_: string]: string[] },
  newSelectedResourceUids: { [_: string]: string[] }
): void {
  const diff: { [_: string]: number } = {};
  Object.entries(newSelectedResourceUids).forEach(
    ([resourceType, selectedUids]) => {
      diff[resourceType] = selectedUids.length;
    }
  );

  Object.entries(oldSelectedResourceUids).forEach(
    ([resourceType, selectedUids]) => {
      const currentValue = diff[resourceType];
      diff[resourceType] = currentValue
        ? currentValue - selectedUids.length
        : -selectedUids.length;
    }
  );

  Object.entries(diff).forEach(([resourceType, amount]) => {
    if (amount === 0) return;
    const metric =
      amount > 0
        ? AnalyticEvents.ServiceView.RelatedResources
            .RelatedResource_AddResources
        : AnalyticEvents.ServiceView.RelatedResources
            .RelatedResource_RemoveResources;
    dispatchEvent(metric, { resourceType, amount: Math.abs(amount) });
  });
}
