import { isBefore } from "date-fns";
import { useMemo } from "react";

import EventGroup from "../common/EventGroup";

import useEventsLimit from "./useEventsLimit";
import useTiersVisible from "./useTiersVisible";

// null means user didn't reached limit, undefined means we are still fetching
const useEventsReachedLimitAt = (): Date | null | undefined => {
  const eventsLimits = useEventsLimit();
  const tiersVisible = useTiersVisible();
  if (!tiersVisible) return null;
  if (!eventsLimits) return undefined;
  const { eventsLimitEpoch } = eventsLimits;
  if (!eventsLimitEpoch) return null;
  return new Date(eventsLimitEpoch);
};

const useLimitedEvents = (
  eventGroups: EventGroup[] | undefined
): EventGroup[] | undefined => {
  const eventsReachedLimitAt = useEventsReachedLimitAt();
  const limitedEvents = useMemo(() => {
    if (eventsReachedLimitAt === undefined) return undefined;
    if (eventsReachedLimitAt === null) return eventGroups;
    if (!eventGroups) return undefined;
    return eventGroups?.filter((e) =>
      isBefore(e.startTime, eventsReachedLimitAt)
    );
  }, [eventGroups, eventsReachedLimitAt]);
  return limitedEvents;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useLimitedEvents;
