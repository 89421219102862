import { useEffect } from "react";

import useAgents from "../../hooks/useAgents";

import isElectronContext from "./isElectronContext";

const useUpdateAgentConnectionStatus = (): void => {
  const agents = useAgents();
  const activeAgentsName = agents?.activeAgents.map(
    (agent) => agent.clusterName
  );

  useEffect(() => {
    if (!isElectronContext()) return;
    window.electronAPI?.updateAgentsConnectionStatus?.(activeAgentsName);
  }, [activeAgentsName]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useUpdateAgentConnectionStatus;
