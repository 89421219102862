import {
  ConfigFormInputError,
  CostOptimizationConfig,
} from "../../types/costOptimizationTypes";

export const inputFieldsOrder: (keyof CostOptimizationConfig)[] = [
  //"allocateIdleResources",
  "monthlyCpuCost",
  "monthlyMemoryCost",
  //"monthlySharedOverhead",
];

export const configPropToText: Record<
  keyof CostOptimizationConfig,
  { title: string; subtitle: string; textLabel?: string }
> = {
  /*allocateIdleResources: {
    title: "Allocate Idle Resources",
    subtitle: "Distribute unallocated Kubernetes resources across tenants",
  },*/
  monthlyCpuCost: {
    title: "Monthly CPU Cost",
    subtitle: "Price per CPU unit for calculating costs",
    textLabel: "CPU Cost",
  },
  monthlyMemoryCost: {
    title: "Monthly Memory Cost",
    subtitle: "Price per GB of RAM for calculating costs",
    textLabel: "Memory Cost",
  },
  /*monthlySharedOverhead: {
    title: "Monthly Shared Overhead",
    subtitle: "Shared overhead costs to spread across Kubernetes resources",
    textLabel: "Shared Overhead",
  },*/
};

export const ConfigFormErrorMessages: Record<ConfigFormInputError, string> = {
  empty: "This field is required",
  invalid: "Value must be greater than 0",
};

export const ALL_CLUSTER_NAMES_SELECTOR = "*";

export const UPDATE_CONFIG_ERROR_MESSAGE = "An error has occurred";

export const UNALLOCATED_BAR_TOOLTIP = "Unallocated resources";
