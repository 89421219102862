import { LabelSelector } from "kubernetes-types/meta/v1";

const K8S_CONTROLLER_UID_LABEL_SELECTOR_KEY = "controller-uid";

export const getJobControllerUid = (
  jobLabelSelector: LabelSelector | undefined
): string | undefined => {
  return Object.entries(jobLabelSelector?.matchLabels ?? {}).find(([key]) =>
    key.includes(K8S_CONTROLLER_UID_LABEL_SELECTOR_KEY)
  )?.[1];
};
