import {
  AvailabilityInvestigationCheckType,
  AvailabilityInvestigationIssueType,
} from "../../../components/InvestigationMode/availability/types";
import {
  AvailabilityRunWithResults,
  IStep,
} from "../../../components/InvestigationMode/types";
import { Metrics } from "../../../components/Metrics/types";
import { Deploy } from "../../../generated/monitorsApi";
import { AvailableActions } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { MemoryAllocationData } from "../../../components/InvestigationMode/availability/ReviewMemoryAllocation/ReviewMemoryAllocationDataFetch";

import {
  AvailabilityResource,
  InvestigationModeStore,
} from "./investigationModeStore";

// getters
export const issueSelector = (
  state: InvestigationModeStore
): AvailabilityRunWithResults | undefined => state.issue;

export const stepsSelector = (state: InvestigationModeStore): IStep[] =>
  state.steps;

export const lastUrlSelector = (
  state: InvestigationModeStore
): string | undefined => state.lastUrl;

export const komodorServiceSelector = (
  state: InvestigationModeStore
): AvailabilityResource | undefined => state.komodorService;

export const isFetchingKomodorServiceSelector = (
  state: InvestigationModeStore
): boolean => state.isFetchingKomodorService;

export const correlatedMetricsSelector = (
  state: InvestigationModeStore
): Metrics | undefined => state.correlatedMetrics;

export const isMetricsSupportedSelector = (
  state: InvestigationModeStore
): boolean | null => state.isMetricsSupported;

export const showResourceDrawerSelector = (
  state: InvestigationModeStore
): boolean => state.showResourceDrawer;

export const deployForRollbackSelector = (
  state: InvestigationModeStore
): Deploy | undefined => state.deployForRollback;

export const availableActionsSelector = (
  state: InvestigationModeStore
): AvailableActions[] => state.availableActions;

export const reviewMemoryAllocationSelector = (
  state: InvestigationModeStore
): MemoryAllocationData | undefined => state.reviewMemoryAllocationData;

export const noisyNeighborsNodesMetricsSelector = (
  state: InvestigationModeStore
): Map<string, Metrics> | undefined => state.noisyNeighborsNodesMetrics;
export const showTriageDrawerSelector = (
  state: InvestigationModeStore
): boolean => state.showTriageDrawer;

export const issueTypeSelector = (
  state: InvestigationModeStore
): AvailabilityInvestigationIssueType | undefined => state.issueType;

// setters
export const setIssueSelector = (
  state: InvestigationModeStore
): ((issue: AvailabilityRunWithResults | undefined) => void) => state.setIssue;

export const setLastUrlSelector = (
  state: InvestigationModeStore
): ((issue: string | undefined) => void) => state.setLastUrl;

export const setKomodorServiceSelector = (
  state: InvestigationModeStore
): ((komodorService: AvailabilityResource) => void) => state.setKomodorService;

export const setIsFetchingKomodorServiceSelector = (
  state: InvestigationModeStore
): ((isFetchingKomodorService: boolean) => void) =>
  state.setIsFetchingKomodorService;

export const setStepsSelector = (
  state: InvestigationModeStore
): ((steps: IStep[]) => void) => state.setSteps;

export const setStepVisitedSelector = (
  state: InvestigationModeStore
): ((stepType: AvailabilityInvestigationCheckType, visited: boolean) => void) =>
  state.setStepVisited;

export const setCorrelatedMetricsSelector = (
  state: InvestigationModeStore
): ((metrics: Metrics) => void) => state.setCorrelatedMetrics;

export const setIsMetricsSupportedSelector = (
  state: InvestigationModeStore
): ((isMetricsSupported: boolean | null) => void) =>
  state.setIsMetricsSupported;

export const setShowResourceDrawerSelector = (
  state: InvestigationModeStore
): ((showResourceDrawer: boolean) => void) => state.setShowResourceDrawer;

export const setDeployForRollbackSelector = (
  state: InvestigationModeStore
): ((deployForRollback: Deploy) => void) => state.setDeployForRollback;

export const setAvailableActionsSelector = (
  state: InvestigationModeStore
): ((availableActions: AvailableActions[]) => void) =>
  state.setAvailableActions;

export const addAvailableActionSelector = (
  state: InvestigationModeStore
): ((availableAction: AvailableActions) => void) => state.addAvailableAction;

export const setReviewMemoryAllocationSelector = (
  state: InvestigationModeStore
): ((data: MemoryAllocationData) => void) => state.setReviewMemoryAllocation;

export const setNoisyNeighborsNodesMetricsSelector = (
  state: InvestigationModeStore
): ((data: Map<string, Metrics>) => void) =>
  state.setNoisyNeighborsNodesMetrics;

export const setShowTriageDrawerSelector = (
  state: InvestigationModeStore
): ((showTriageDrawer: boolean) => void) => state.setShowTriageDrawer;

export const setIssueTypeSelector = (
  state: InvestigationModeStore
): ((issueType: AvailabilityInvestigationIssueType) => void) =>
  state.setIssueType;
