import { palette, theme } from "@komodorio/design-system";
import { Tag, Typography } from "@komodorio/design-system/deprecated";
import { Radar16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

const TagContent = styled.div`
  display: flex;
  column-gap: 0.3rem;
  align-items: center;
`;
export const IssueStatusTag: React.FC<{ isClosed: boolean }> = ({
  isClosed,
}) => {
  const color = isClosed
    ? {
        bg: theme.background.bgGray,
        fg: theme.foreground.fgPrimary,
      }
    : {
        bg: theme.background.bgPinkLight,
        fg: palette.pink[700],
      };
  return (
    <Tag color={color.bg} variant="round">
      <Typography variant="uppercase" color={color.fg}>
        <TagContent>
          {!isClosed && <Radar16 />}
          {isClosed ? "closed" : "open"}
        </TagContent>
      </Typography>
    </Tag>
  );
};
