import React from "react";
import { useNavigate } from "react-router-dom";

import { getEditAppViewRoute } from "../../utils/getRoutes";
import { useAppViewsStore } from "../../../../../../shared/store/appViewsStore/appViewsStore";
import { appViewIdSelector } from "../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { ApplicationScopingErrorMessage } from "./ApplicationScopingErrorMessage";
import { StyledLink } from "./applicationsScopingErrorStyles";
import { noServicesFound, title } from "./applicationScopingErrorTexts";

export const NoServicesFoundMessage: React.FC = () => {
  const navigate = useNavigate();
  const appViewId = useAppViewsStore(appViewIdSelector);

  const onClick = () => {
    appViewId && navigate(getEditAppViewRoute(appViewId));
  };

  return (
    <ApplicationScopingErrorMessage
      title={title}
      subtitle={noServicesFound}
      cta={
        <>
          Try{" "}
          <StyledLink onClick={onClick}>
            setting the application’s scope
          </StyledLink>
        </>
      }
    />
  );
};
