import { useCurrentDrawerState } from "../../../../../../../shared/context/drawersStack/helpers";
import { useGetViolationHistory } from "../../../../../../../shared/hooks/reliability-api/violations/useGetViolationHistory";
import { ViolationsDrawerType } from "../../../../../../../shared/store/drawersStackStore/types";

export const useViolationHistory = () => {
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();

  return useGetViolationHistory({
    id: currentDrawerState?.violationId ?? "",
    enable: !!currentDrawerState?.violationId,
  });
};
