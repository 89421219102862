import styled from "styled-components";

import { useGetViolationsFilter } from "../../../shared/hooks/reliability-api/filters/useGetViolationsFilter";
import { SeverityChip } from "../../reliability";
import {
  ViolationCountBy,
  ViolationFilterOperator,
} from "../../../generated/reliabilityApi";
import { defaultViolationStatuses } from "../../reliability/constants/reliabilityConstants";
import { useDrawersStackStore } from "../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../shared/store/drawersStackStore/types";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { useCount } from "../../reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/violationTableHooks";

import {
  StatusColumn,
  StatusContainer,
  StatusRow,
  StatusTitle,
} from "./HeaderStyles";

const SeveritiesContainer = styled.div`
  display: flex;
  gap: 8px;
`;

interface Props {
  komodorUid: string;
}

export const KomodorServiceReliability: React.FC<Props> = ({ komodorUid }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const { data } = useGetViolationsFilter({
    countBy: "severity",
    postViolationsCountRequestBody: {
      filterBy: [
        {
          category: ViolationCountBy.KomodorUid,
          value: [komodorUid],
          operator: ViolationFilterOperator.Include,
        },
        {
          category: ViolationCountBy.Status,
          value: defaultViolationStatuses,
          operator: ViolationFilterOperator.Include,
        },
        {
          category: ViolationCountBy.ImpactGroupType,
          value: ["dynamic"],
          operator: ViolationFilterOperator.Include,
        },
      ],
    },
  });

  const severities = useCount(data?.data);

  if (!severities) return null;

  const { high, medium, low } = severities;

  return (
    <StatusContainer
      clickable
      onClick={() => {
        pushDrawer({
          drawerType: DrawerType.ViolationsDrawerByScope,
          scope: {
            komodorUid: [komodorUid],
          },
        });
      }}
      aria-label={AriaLabels.ResourceView.Header.Reliability.StatusBox}
    >
      <StatusColumn>
        <StatusRow>
          <StatusTitle>RELIABILITY VIOLATIONS</StatusTitle>
        </StatusRow>
        <SeveritiesContainer>
          {!!high && <SeverityChip severity={"high"} count={high} />}
          {!!medium && <SeverityChip severity={"medium"} count={medium} />}
          {!!low && <SeverityChip severity={"low"} count={low} />}
        </SeveritiesContainer>
      </StatusColumn>
    </StatusContainer>
  );
};
