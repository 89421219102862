import { useMemo } from "react";

import { HealthType, useHealthType } from "./useHealthType";

import { CheckCategory } from "@/generated/reliabilityApi";

export const useCheckCategory = (): CheckCategory[] => {
  const healthType = useHealthType();
  return useMemo(() => {
    switch (healthType) {
      case HealthType.WORKLOAD:
        return [CheckCategory.Workload];
      case HealthType.INFRA:
        return [CheckCategory.Infrastructure];
      default:
        return [CheckCategory.Workload, CheckCategory.Infrastructure];
    }
  }, [healthType]);
};
