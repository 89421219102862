import datadogIcon from "../../../../../integrations/management/logos/datadog.svg";
import sentryIcon from "../../../../../integrations/management/logos/sentry.svg";
import opsgenieIcon from "../../../../../integrations/management/logos/opsgenie.svg";
import pagerdutyIcon from "../../../../../integrations/management/logos/pagerduty.svg";
import teamcityIcon from "../../../../../integrations/management/logos/teamcity.svg";
import jiraIcon from "../../../../../integrations/management/logos/jira.svg";
import Resource from "../../../../resources";

import logzIcon from "./assets/logz.svg";
import jenkinsIcon from "./assets/jenkins.svg";
import grafanaIcon from "./assets/grafana.svg";
import logrocketIcon from "./assets/logrocket.svg";
import linkIcon from "./assets/link.svg";

export const defaultIconKey = "default";

const linkIcons: { [key: string]: string } = {
  datadog: datadogIcon,
  sentry: sentryIcon,
  jenkins: jenkinsIcon,
  opsgenie: opsgenieIcon,
  grafana: grafanaIcon,
  logz: logzIcon,
  pagerduty: pagerdutyIcon,
  logrocket: logrocketIcon,
  teamcity: teamcityIcon,
  jira: jiraIcon,
  atlassian: jiraIcon,
  default: linkIcon,
};

export const getLinkIconKey = (url: string, text: string): string => {
  let hostname: string;
  try {
    hostname = new URL(url).hostname;
  } catch {
    hostname = "";
  }
  return (
    Object.keys(linkIcons).find(
      (linkType) => hostname.includes(linkType) || text.includes(linkType)
    ) ?? defaultIconKey
  );
};

export const getLinkIcon = (url: string, text: string): string => {
  const linkImageKey = getLinkIconKey(url, text);
  return linkIcons[linkImageKey];
};

export interface ServiceLinkInfo {
  cluster?: string;
  serviceId?: string;
  namespace?: string;
}

export const getServiceInfo = (
  resource: Resource | undefined
): ServiceLinkInfo => {
  return {
    cluster: resource?.cluster,
    serviceId: resource?.id,
    namespace: resource?.namespace,
  };
};
