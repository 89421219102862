import React, { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import DeployEventGroup, {
  groupDeployEvents,
} from "../../../../../../../common/EventGroup/deployEvent/DeployEventGroup";
import { CardContentDisplayHandler } from "../CardContentDisplayHandler/CardContentDisplayHandler";
import { ArrowCircleIcon } from "../emptyStateIcons/ArrowCircle";
import { useOverviewPageStore } from "../../store/overviewPageStore";
import { selectTimeWindowIntervals } from "../../store/overviewPageStoreSelectors";
import { EventItemDrawer } from "../../EventItemDrawer/EventItemDrawer";
import { useResourcesAPIGet } from "../../../../../../../../shared/hooks/resources-api/client";
import { DEPLOY_EVENTS_SEARCH } from "../../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { DESC } from "../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { EventItem } from "./EventItem/EventItem";

export const MAX_EVENTS = 20;

export const RecentDeploys: React.FC = () => {
  const timeWindowIntervals = useOverviewPageStore(selectTimeWindowIntervals);
  const startTime = timeWindowIntervals[0].fromEpoch;
  const endTime = timeWindowIntervals[1].toEpoch;
  const [deployEvents, setDeployEvents] = useState<DeployEventGroup[]>();

  const variables = useMemo(() => {
    if (startTime === endTime) return undefined;
    return {
      fromEpoch: new Date(startTime).getTime().toString(),
      toEpoch: new Date(endTime).getTime().toString(),
      filterOutAutoDeploys: true,
    };
  }, [endTime, startTime]);

  const { data, loading } = useResourcesAPIGet(DEPLOY_EVENTS_SEARCH, {
    ...variables,
    order: DESC,
    limit: MAX_EVENTS,
  });

  useEffect(() => {
    if (!data) return;

    const deploys: DeployEventGroup[] = groupDeployEvents(data.data, {});
    const sortedByEndDateDescending = deploys
      .sort((a, b) => b.endTime.getTime() - a.endTime.getTime())
      .filter((deployEvent) => deployEvent.endTime.getTime() >= startTime);

    if (!isEqual(sortedByEndDateDescending, deployEvents)) {
      setDeployEvents(sortedByEndDateDescending);
    }
  }, [data, deployEvents, startTime]);

  const content = useMemo(
    () =>
      deployEvents?.map((deployEvent) => {
        return <EventItem event={deployEvent} key={deployEvent.id} />;
      }),
    [deployEvents]
  );

  return (
    <>
      <CardContentDisplayHandler
        fetching={loading}
        isEmptyState={content?.length === 0}
        emptyStateDisplay={{
          icon: <ArrowCircleIcon />,
          dataType: "recent deploys",
        }}
      >
        {content}
      </CardContentDisplayHandler>
      <EventItemDrawer />
    </>
  );
};
