import React from "react";

import { GrayHighlight } from "../../../../common/styles";
import { BoldGrayText } from "../../CheckDetailsDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeOtherConditionFailureCheck from "../NodeOtherConditionFailureCheck";

const NodeOtherConditionFailureDetails: React.FC<{
  check: NodeOtherConditionFailureCheck;
}> = ({ check }) => {
  if (!check.output || check.passed) {
    return null;
  }

  return (
    <>
      {check.output.conditions.map((c) => (
        <>
          <br />
          <BoldGrayText>"{c.type}" Condition Error Message</BoldGrayText>
          <GrayHighlight>{`${c.reason}, ${c.message}`}</GrayHighlight>
        </>
      ))}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeOtherConditionFailureDetails;
