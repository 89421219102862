import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { getMetricsGraphTitle } from "../hpaReachedMaxUtils";
import { MetricType } from "../../../../../../../../../Metrics/types";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type MetricsGraphTitleProps = {
  metricsType: MetricType;
};

export const MetricsGraphTitle: React.FC<MetricsGraphTitleProps> = ({
  metricsType,
}) => {
  return (
    <Container>
      <Typography variant="h6" color="text.secondary">
        {getMetricsGraphTitle(metricsType)}
      </Typography>
    </Container>
  );
};
