import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import { muiColors } from "@komodorio/design-system";

const Content = styled.div`
  width: 836px;
  height: 406px;
  background-color: ${muiColors.indigo[900]};
  border-radius: 4px;
  padding: 8px;
  overflow: auto;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  description: string;
}

export const ErrorMessageModal: React.FC<Props> = ({
  isOpen,
  onClose,
  description,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Typography variant="h3">Error message</Typography>
      </DialogTitle>
      <DialogContent>
        <Content>
          <Typography variant="body2" color={muiColors.gray[100]}>
            {description}
          </Typography>
        </Content>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
