import { useMemo } from "react";

import { NodeCount as GeneratedNodeCount } from "@/generated/accounts";
import {
  allMonths,
  RowData,
  TOTAL_ROW_ID,
} from "@/components/Settings/General/NodesCount/consts";
import { getColumnGroupingModel } from "@/components/Settings/General/NodesCount/utils";
import { getCurrentMonthIndex } from "@/pages/organization-settings/account/UsagePage/utils";

const emptyClusterData = allMonths.reduce(
  (acc: { [key: string]: number }, month) => {
    acc[month] = 0;
    return acc;
  },
  {}
);

export const useTableData = (
  clusters: (string | undefined)[],
  data?: GeneratedNodeCount[]
): { [clusterName: string]: { [month: string]: number } } => {
  return useMemo(() => {
    // Create a matrix for the table
    const tableData: { [clusterName: string]: { [month: string]: number } } =
      {};

    clusters.forEach((clusterName) => {
      if (!clusterName) return;
      tableData[clusterName] = {};
      allMonths.forEach((month) => {
        tableData[clusterName][month] = 0;
      });
    });

    if (!data) return tableData;

    // Populate the matrix with data
    data.forEach((item) => {
      if (!item.clusterName) return;

      const date = new Date(String(item.createdAt));
      const month = allMonths[date.getUTCMonth()];
      if (!item.nodeCount) return;
      if (!tableData[item.clusterName]) {
        tableData[item.clusterName] = { ...emptyClusterData };
      }
      tableData[item.clusterName][month] += item.nodeCount;
    });

    return tableData;
  }, [clusters, data]);
};

interface UseClustersTable {
  clusters: string[];
  sortedMonths: string[];
  data?: GeneratedNodeCount[];
}

export const useClustersTable = ({
  clusters,
  sortedMonths,
  data,
}: UseClustersTable) => {
  const tableData = useTableData(clusters, data);

  const monthSums = useMemo(() => {
    const monthSums: { [month: string]: number } = {};

    allMonths.forEach((month) => {
      monthSums[month] = clusters.reduce(
        (sum, cluster) => sum + (tableData[String(cluster)]?.[month] || 0),
        0
      );
    });

    return monthSums;
  }, [clusters, tableData]);

  const { rows, totalRow } = useMemo(() => {
    const rows = clusters.map((cluster, index) => {
      const row: RowData = { id: String(index), cluster: cluster };
      sortedMonths.forEach((month) => {
        row[month] = tableData[cluster][month];
      });
      return row;
    });

    const totalRow: RowData = {
      id: TOTAL_ROW_ID,
      cluster: "TOTAL",
      ...Object.fromEntries(
        sortedMonths.map((month) => [month, monthSums[month]])
      ),
    };

    return { rows, totalRow };
  }, [clusters, monthSums, sortedMonths, tableData]);

  const currentMonthIndex = getCurrentMonthIndex();

  const columnGroupingModel = useMemo(() => {
    return getColumnGroupingModel(sortedMonths, allMonths[currentMonthIndex]);
  }, [sortedMonths, currentMonthIndex]);

  return {
    rows,
    totalRow,
    columnGroupingModel,
  };
};
