import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  CreateRbacPolicyRequest,
  RbacPolicy,
  apiV1RbacPoliciesPostUrl,
} from "../../../../../generated/auth";

const CREATE_RBAC_POLICY_PATH = "/rbac-policies";

const createRbacPolicy = async (
  apiClient: AxiosInstance,
  params: CreateRbacPolicyRequest
): Promise<RbacPolicy> => {
  const { data } = await apiClient.post(
    apiV1RbacPoliciesPostUrl(
      { createRbacPolicyRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCreateRbacPolicy = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [CREATE_RBAC_POLICY_PATH],
    (params: CreateRbacPolicyRequest) => createRbacPolicy(apiClient, params)
  );
};
