import {
  NoisyNeighborSupportingData,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";

export const getNoisyNeighborTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  return `Noisy neighbor - ${supportingData?.noisyNeighbor?.totalVictims} victims`;
};

export const getNoisyNeighborAffectedDeploymentsAndJobs = (
  supportingData: NoisyNeighborSupportingData | undefined
) => {
  const deployments = new Set<string>();
  const jobs = new Set<string>();
  supportingData?.memoryVictims.forEach((memoryVictim) => {
    memoryVictim.nodeVictims.forEach((nodeVictim) => {
      if (nodeVictim.kind === "Deployment") {
        deployments.add(nodeVictim.komodorUid);
      } else if (nodeVictim.kind === "Job") {
        jobs.add(nodeVictim.komodorUid);
      }
    });
  });
  return {
    numOfDeployments: deployments.size,
    numOfJobs: jobs.size,
  };
};
