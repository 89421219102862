import { generateMockCostRightSizingSummary } from "../../../../../../CostOptimizationView/__tests__/mockData/mockCostRightSizingSummary";
import { RightSizingCostSummaryResponse } from "../../../../../../../generated/metricsApi";

export const mockPotentialSavingsResponse =
  (): RightSizingCostSummaryResponse => {
    const mockData = generateMockCostRightSizingSummary();
    mockData.rows = mockData.rows.map((row) => {
      const useLongName = Math.random() > 0.7;
      const longNameSuffix = "-with-a-very-long-name";

      if (!useLongName) return row;
      return {
        ...row,
        namespace: `${row.namespace}${longNameSuffix}`,
        service: `${row.service}${longNameSuffix}`,
      };
    });
    return mockData;
  };
