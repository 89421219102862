import { theme, palette, muiTheme } from "@komodorio/design-system";
import { ChevronDown16, ChevronRight16 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import styled from "styled-components";

export const DescribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-block-end: 2.5rem;
  color: ${theme.foreground.fgPrimary};
`;

export const DescribePanel = styled.div`
  background-color: ${palette.white[0]};
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  padding: 0.5rem 1rem;
`;

const Section = styled.div``;
const SectionTitle = styled.div<{ disabled?: boolean }>`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-block-end: 0.5rem;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const DescribeSection: React.FC<{
  className?: string;
  classes?: {
    content?: string;
  };
  title?: JSX.Element | string;
  collapsible?: boolean;
  disabled?: boolean;
  expandByDefault?: boolean;
  children?: React.ReactNode;
  ariaLabel?: string;
}> = ({
  className,
  classes,
  title,
  expandByDefault = false,
  children,
  ariaLabel,
  collapsible = true,
  disabled = false,
}) => {
  const [expanded, setExpanded] = useState(
    collapsible === false || expandByDefault
  );

  return (
    <Section aria-label={ariaLabel} className={className}>
      {title && (
        <SectionTitle
          disabled={disabled}
          onClick={
            collapsible && !disabled ? () => setExpanded(!expanded) : undefined
          }
        >
          {collapsible ? (
            expanded ? (
              <ChevronDown16
                color={disabled ? muiTheme.palette.action.disabled : undefined}
              />
            ) : (
              <ChevronRight16
                color={disabled ? muiTheme.palette.action.disabled : undefined}
              />
            )
          ) : null}
          {typeof title === "string" ? (
            <Typography variant="h5">{title}</Typography>
          ) : (
            title
          )}
        </SectionTitle>
      )}
      {expanded && (
        <SectionContent className={classes?.content}>{children}</SectionContent>
      )}
    </Section>
  );
};
