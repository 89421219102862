import React from "react";

import { AppBarLink } from "./AppBarLink";
import { PageName, usePages } from "./pages";

const name = PageName.Reliability;

export const Reliability: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
    />
  ) : null;
};
