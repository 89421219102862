import { useContext } from "react";

import {
  AiInvestigationsSessionsCtx,
  AiInvestigationsSessionsManager,
} from "./AiInvestigationsProvider";

export const useAiInvestigations = (): AiInvestigationsSessionsManager => {
  return useContext(AiInvestigationsSessionsCtx);
};
