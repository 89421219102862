import React, { useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material";

import { OverflowTextContainer } from "../styles/styles";
import { useHasTextEllipsis } from "../hooks/useHasTextEllipsis";

type EllipsisContainerWithTooltipProps = {
  tooltipValue: string;
  children?: React.ReactNode;
  tooltipProps?: TooltipProps;
};

export const EllipsisContainerWithTooltip: React.FC<
  EllipsisContainerWithTooltipProps
> = ({ tooltipValue, children, tooltipProps }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const showTooltip = useHasTextEllipsis(containerRef);

  return (
    <Tooltip
      title={showTooltip ? tooltipValue : undefined}
      placement="top-start"
      {...tooltipProps}
    >
      <OverflowTextContainer ref={containerRef}>
        {children ?? tooltipValue}
      </OverflowTextContainer>
    </Tooltip>
  );
};
