import { useMemo } from "react";
import { HistogramProps } from "@komodorio/design-system/komodor-ui";

import { useWorkflowIssuesEvents } from "../../../hooks/overviewPageHooks";

export const useAvailabilityIssuesAsHistogramData = (): {
  eventGroups: HistogramProps["data"];
  fetching: boolean;
} => {
  const { fetching, eventGroups } = useWorkflowIssuesEvents();
  const mappedAvailabilityIssues = useMemo(() => {
    return (
      eventGroups?.map((eventGroup) => ({
        startTime: eventGroup.startTime,
        endTime: eventGroup.endTime,
        groupId: eventGroup.serviceId,
      })) || []
    );
  }, [eventGroups]);

  return { eventGroups: mappedAvailabilityIssues, fetching };
};
