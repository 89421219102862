import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Dictionary } from "lodash";
import { TooltipWrapper } from "react-tooltip";

import { gray10, gray9 } from "../../../Colors";
import Input from "../../common/controls/Input";
import {
  Empty,
  Placeholder,
} from "../../common/controls/Select/SingleSmartSelect";
import Overlay from "../../common/Overlay";
import { linkStyle } from "../../common/typography";
import {
  Bar,
  lightStyle,
  ListComponentWrapper,
  rowContainerStyle,
  smallLightStyle,
} from "../monitorsConfiguration/common/styles";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import { CloseIcon } from "./Header";

export const Container = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width ?? "100%"};
`;

const PlaceholderEnabled = styled.label<{ isEnabled: boolean }>`
  cursor: pointer;
  ${({ isEnabled }) => (isEnabled ? "color:#707583;" : "color:#B3B6BC;")}
`;

const ListContainer = styled.div`
  display: flex;
`;

const Search = styled(Input)`
  color: ${gray10};
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e1e2e5;
  ${lightStyle};
  color: #3b3d45;
  border-radius: 0.25rem 0.25rem 0 0;
`;

const SelectInfo = styled.div`
  padding: 0.2rem 0.5rem;
  ${rowContainerStyle};
  justify-content: space-between;
`;

const SelectAll = styled.div`
  ${linkStyle}
  ${smallLightStyle}
  text-decoration: none;
  cursor: pointer;
`;

const SelectedNumber = styled.div`
  ${smallLightStyle}
`;
const OptionLabel = styled.div`
  ${lightStyle};
  cursor: pointer;
`;

const SelectOption = styled.div`
  padding: 0.4rem 0.2rem;
  ${rowContainerStyle}
`;
const StyledCheckBox = styled.input`
  margin: 0.2rem 0.6rem 0.2rem 0.4rem;
  cursor: pointer;
`;

const StyledList = styled.div`
  max-height: 8.6rem;
  overflow-y: auto;
`;

const SelectedTile = styled.div`
  background-color: ${gray9};
  padding: 0.2rem 0.5rem;
  margin: 0 2px;
  border-radius: 4px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  max-width: 70%;
  ${lightStyle}
`;

const TextWrapper = styled.div`
  max-width: 80%;
  overflow: hidden;
`;
const SmallCloseIcon = styled(CloseIcon)`
  height: 0.7rem;
  width: 0.7rem;
  margin-right: 0.3rem;
  margin-left: 0.8rem;
`;
const ExtraValuesSelected = styled.span`
  padding-inline: 0.5rem;
`;
const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

const SELECTED_VALUES_ON_BAR = 1;
const MAX_ITEMS_TO_DISPLAY = 10;

const StyledMultiSelect: React.FC<{
  width: string;
  placeholder: string;
  values: string[];
  options: string[];
  optionsDisplayValues?: Dictionary<string>;
  isEnabled: boolean;
  onChange: (values: string[]) => void;
  isValid?: boolean;
  ariaLabel?: string;
}> = ({
  width,
  placeholder,
  values,
  options,
  optionsDisplayValues,
  onChange,
  isEnabled,
  isValid = true,
  ariaLabel,
}) => {
  const [open, setOpen] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const handleCheckboxChange = (isChecked: boolean, option: string) => {
    if (isChecked) {
      onChange(values.filter((v) => v !== option));
    } else {
      onChange([...values, option]);
    }
  };

  const filteredOptions = useMemo(
    () =>
      options.filter((o) =>
        o?.toLowerCase().includes(searchPhrase.toLowerCase())
      ),
    [options, searchPhrase]
  );
  const filteredValues = useMemo(
    () =>
      values.filter((o) =>
        o?.toLowerCase().includes(searchPhrase.toLowerCase())
      ),
    [values, searchPhrase]
  );

  const handleSelectDeselectAll = (isAllSelected: boolean) => {
    if (isAllSelected) {
      onChange(values.filter((value) => !filteredValues.includes(value)));
    } else {
      onChange([
        ...filteredOptions.filter((option) => !filteredValues.includes(option)),
        ...values,
      ]);
    }
  };
  const handleBarClicked = () => {
    setOpen(true);
    setSearchPhrase("");
  };
  const isExtraValuesChosen = values.length > SELECTED_VALUES_ON_BAR;
  const isAllSelected = filteredValues.length === filteredOptions.length;
  return (
    <Container width={width}>
      <Bar
        aria-disabled={!isEnabled}
        onClick={handleBarClicked}
        isEnabled={isEnabled}
        className={"bar"}
        isValid={isValid}
        aria-label={ariaLabel}
      >
        {values.length ? (
          <>
            {values
              .slice(0, SELECTED_VALUES_ON_BAR)
              .map((selectedValue, index) => {
                return (
                  <SelectedTile key={index}>
                    <TextWrapper>{selectedValue}</TextWrapper>
                    <SmallCloseIcon
                      onClick={(e) => {
                        stopPropagation(e);
                        handleCheckboxChange(true, selectedValue);
                      }}
                    />
                  </SelectedTile>
                );
              })}
            <TooltipWrapper
              tooltipId={DEFAULT_TOOLTIP}
              content={
                isExtraValuesChosen
                  ? values
                      .slice(SELECTED_VALUES_ON_BAR, MAX_ITEMS_TO_DISPLAY)
                      .join(",\n")
                  : undefined
              }
            >
              <ExtraValuesSelected>
                {isExtraValuesChosen && (
                  <>+{values.length - SELECTED_VALUES_ON_BAR}</>
                )}
              </ExtraValuesSelected>
            </TooltipWrapper>
          </>
        ) : (
          <PlaceholderEnabled isEnabled={isEnabled}>
            {placeholder}
          </PlaceholderEnabled>
        )}
      </Bar>
      {open && (
        <>
          <Overlay open onClick={() => setOpen(false)} />
          <ListComponentWrapper>
            <ListContainer>
              <Search
                value={searchPhrase}
                placeholder="Search"
                onChange={(e) => setSearchPhrase(e.target.value)}
                autoFocus
              />
            </ListContainer>
            {filteredOptions.length ? (
              <SelectInfo>
                <SelectAll
                  aria-label={
                    isAllSelected ? "Deselect all button" : "Select all button"
                  }
                  onClick={() => handleSelectDeselectAll(isAllSelected)}
                >
                  {isAllSelected
                    ? `Deselect All (${filteredOptions.length})`
                    : `Select All (${filteredOptions.length})`}
                </SelectAll>
                <SelectedNumber>{values.length} Selected</SelectedNumber>
              </SelectInfo>
            ) : (
              <Empty>
                <Placeholder>No options</Placeholder>
              </Empty>
            )}
            <StyledList>
              {filteredOptions.map((o, i) => {
                const isChecked = values.includes(o);
                return (
                  <SelectOption
                    aria-label={`select option: ${o}`}
                    key={i}
                    onClick={() => handleCheckboxChange(isChecked, o)}
                  >
                    <StyledCheckBox
                      type={"checkbox"}
                      id={"id"}
                      checked={isChecked}
                      readOnly
                    />
                    <OptionLabel>
                      {optionsDisplayValues ? optionsDisplayValues[o] : o}
                    </OptionLabel>
                  </SelectOption>
                );
              })}
            </StyledList>
          </ListComponentWrapper>
        </>
      )}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StyledMultiSelect;
