import { useEffect } from "react";

import {
  FetchingStateProps,
  useClusterOverviewStore,
} from "../../../shared/store/clusterOverviewStore/clusterOverviewStore";
import {
  fetchingStateSelector,
  resetFetchingStateSelector,
  setFetchingStateSelector,
} from "../../../shared/store/clusterOverviewStore/clusterOverviewSelectors";
import { useReportDDRumWithMultipleFetchings } from "../../../shared/hooks/datadog-rum/useReportDDRumWithMultipleFetchings";
import { dataDogViewNames } from "../../../shared/constants/datadog";

export const useReportClusterOverviewLoadingTime = () => {
  const fetchingState = useClusterOverviewStore(fetchingStateSelector);
  const resetFetchingState = useClusterOverviewStore(
    resetFetchingStateSelector
  );

  useEffect(() => {
    return () => resetFetchingState();
  }, [resetFetchingState]);

  useReportDDRumWithMultipleFetchings(
    fetchingState,
    dataDogViewNames.clusterOverview
  );
};

export const useStoreIsFetchingForReport = (
  fetchingStateKey: keyof FetchingStateProps,
  fetchingStateValue: boolean
) => {
  const setFetchingState = useClusterOverviewStore(setFetchingStateSelector);

  useEffect(() => {
    setFetchingState({ key: fetchingStateKey, value: fetchingStateValue });
  }, [fetchingStateKey, fetchingStateValue, setFetchingState]);
};
