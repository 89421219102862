import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

import { CertificateFullStatus } from "@/generated/addonsApi";
import { tooltipStatusTexts } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/tooltipStatusTexts";
import { CertificateFullStatusToTitle } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const {
  status: { info: infoAriaLabel },
} = certificatesPageAriaLabels.drawer;

type StatusTitleWithTooltipProps = {
  status: keyof CertificateFullStatus;
};

export const StatusTitleWithTooltip: React.FC<StatusTitleWithTooltipProps> = ({
  status,
}) => {
  return (
    <Container>
      <Typography variant={"h5"} color={"text.secondary"}>
        {CertificateFullStatusToTitle[status]}
      </Typography>
      <Tooltip title={tooltipStatusTexts[status]} placement={"top"}>
        <InfoOutlined
          fontSize={"small"}
          color={"action"}
          aria-label={infoAriaLabel}
        />
      </Tooltip>
    </Container>
  );
};
