import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import PathNotFound from "../404/PathNotFound";
import ResourceView from "../ResourceView/ResourceView";
import { dispatchEvent } from "../../shared/hooks/analytics";
import useResourceWithInterval from "../ResourceView/useResource";
import {
  parseKomodorUid,
  useBuildKomodorUidForService,
} from "../../shared/hooks/resources-api/resourcesAPIUtils";
import { useActiveAgent } from "../../shared/hooks/useAgents";

type ServiceParams = {
  serviceId?: string;
};
const useServiceParams = (): ServiceParams => {
  return useParams<ServiceParams>();
};

export const ServiceView: React.FC = () => {
  const { serviceId } = useServiceParams();
  const komodorUID = useBuildKomodorUidForService(serviceId ?? "");

  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUID ?? "") ?? {};

  const agentId = useActiveAgent(cluster) ?? "";

  const resourceParams = useMemo(
    () => ({
      agentId,
      resourceType: kind,
      resourceName: name,
      cluster,
      namespace,
      executeMutation: !!agentId && !!komodorUID,
    }),
    [agentId, cluster, kind, komodorUID, name, namespace]
  );

  const { resource, isFetching, clearState } =
    useResourceWithInterval(resourceParams);

  useEffect(() => {
    dispatchEvent(AnalyticEvents.ServiceView.View, { serviceId });
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceParams]);

  return !resource ? (
    !isFetching ? (
      <PathNotFound />
    ) : null
  ) : (
    <ResourceView resource={resource} />
  );
};
