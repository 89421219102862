import Box from "@mui/material/Box";
import React from "react";

export type ImpactGroupStatsSectionProps = {
  children: React.ReactNode;
};

export const ImpactGroupStatsSection: React.FC<
  ImpactGroupStatsSectionProps
> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" gap="20px">
      {children}
    </Box>
  );
};
