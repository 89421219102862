import { compact, get } from "lodash";

// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { MonitorData } from "../../../../generated/monitorsApi";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
import { useOpenIssues } from "../../../../shared/hooks/useOpenIssues";
import { buildIssueKey } from "../../../../shared/hooks/monitors-api/client/issues/useGetMonitorIssues";
import { buildKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useMapPodToService } from "../../../../shared/hooks/resources-api/client/komodor-services/useMapPodToService";
import { KomodorServicePodMapParamsOwnerTypeEnum } from "../../../../generated/resourcesApi";
import { useGetServiceIdFromKomodorUid } from "../../../../shared/hooks/useGetServiceIdFromKomodorUid";

export const useOpenIssuesForPod = (
  pod: Pod
): { openIssues: MonitorData[]; isLoading: boolean } => {
  const { data: openIssuesByType, isLoading: isLoadingOpenIssues } =
    useOpenIssues();

  const podUid = buildKomodorUid({
    resourceName: pod.name,
    clusterName: pod.cluster,
    namespace: pod.namespace,
    kind: pod.kind,
  });
  const { data: podServicesMap, isLoading: isLoadingOwnerMappping } =
    useMapPodToService({
      podsKomodorUids: [podUid],
      ownerType: KomodorServicePodMapParamsOwnerTypeEnum.WithoutRootOwner,
    });
  const rootOwnerKomodorUid = get(podServicesMap?.data, podUid);
  const { serviceId: rootOwnerServiceId } =
    useGetServiceIdFromKomodorUid(rootOwnerKomodorUid, false) ?? {};

  const openAvailabilityIssue = rootOwnerServiceId
    ? openIssuesByType?.[WorkflowConfigType.Availability]?.[
        rootOwnerServiceId
      ]?.[0]
    : undefined;

  const openPodIssue = (openIssuesByType?.[WorkflowConfigType.Pod] ||
    openIssuesByType?.[WorkflowConfigType.Workflow])?.[
    buildIssueKey(pod.cluster, pod.namespace, pod.name)
  ]?.[0];

  return {
    openIssues: compact([openAvailabilityIssue ?? openPodIssue]),
    isLoading: isLoadingOpenIssues || isLoadingOwnerMappping,
  };
};
