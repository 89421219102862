import Popper from "@mui/material/Popper";
import React from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { ZIndex } from "../../../constants/zIndex";

const POPPER_OFFSET = 8;
const POPPER_OFFSCREEN_OFFSET = 56;

/**
 * This popper will be attached to the mouse position and avoid going offscreen.
 */
export const SmartPopper = <TValue extends ValueType, TName extends NameType>({
  open,
  tooltipContent,
  mousePos,
  boundingRect,
  ...rest
}: {
  open: boolean;
  tooltipContent: React.FC | React.ComponentClass | React.ReactElement;
  mousePos: { x: number; y: number };
  boundingRect:
    | { x: number; y: number; height: number; width: number }
    | undefined;
} & TooltipProps<TValue, TName>) => {
  const isInsideWindow = boundingRect
    ? mousePos.x >= boundingRect.x &&
      mousePos.x <= boundingRect.x + boundingRect.width &&
      mousePos.y >= boundingRect.y &&
      mousePos.y <= boundingRect.y + boundingRect.height
    : false;

  if (!isInsideWindow) {
    return null;
  }

  const element = React.isValidElement(tooltipContent)
    ? React.cloneElement(tooltipContent, rest)
    : // see https://github.com/recharts/recharts/blob/master/src/component/Tooltip.tsx#L35
      // eslint-disable-next-line
      React.createElement<any>(
        tooltipContent as React.FC | React.ComponentClass,
        rest
      );

  return (
    <Popper
      open={open}
      sx={{ zIndex: ZIndex.AboveModal }}
      anchorEl={window.document.body}
      placement="top-start"
      popperOptions={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: ({ popper }: { popper: { width: number } }) => [
                mousePos.x + popper.width > window.innerWidth
                  ? mousePos.x - popper.width - POPPER_OFFSCREEN_OFFSET
                  : mousePos.x + POPPER_OFFSET,
                -mousePos.y,
              ],
            },
          },
        ],
      }}
    >
      {element}
    </Popper>
  );
};
