import React from "react";
import { ButtonProps } from "@mui/material";

import {
  UpgradeButtonAndModalForUnsupportedClusters,
  UpgradeButtonAndModalForUnsupportedClustersProps,
} from "../../../../shared/components/UpgradeButtonAndModalForUnsupportedClusters";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";

type UpgradeButtonAndModalProps = {
  buttonVariant?: ButtonProps["variant"];
} & Pick<
  UpgradeButtonAndModalForUnsupportedClustersProps,
  "unsupportedClusters"
>;

export const UpgradeButtonAndModal: React.FC<UpgradeButtonAndModalProps> = ({
  buttonVariant,
  unsupportedClusters,
}) => {
  return (
    <UpgradeButtonAndModalForUnsupportedClusters
      buttonVariant={buttonVariant}
      unsupportedClusters={unsupportedClusters}
      upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_METRICS}
      desiredFeature="metrics"
    />
  );
};
