import { Kubernetes } from "@komodorio/design-system/icons";

export const KubernetesPDBResource = {
  NameInK8S: "poddisruptionbudgets",
  Group: "configuration",
  NavBarName: "PDB",
  PresentName: "Pod Disruption Budget",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "PodDisruptionBudget",
  Icon: Kubernetes,
  hasLabels: true,
};
