import { useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { KubernetesCronJobResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const useAvailableCronjobActions = (
  resourceType: KubernetesResource,
  rowResource: ResourceTableModelRow
) => {
  const { showSuspendResumeAction } = useOverridableFlags();

  return useMemo(() => {
    if (
      !showSuspendResumeAction ||
      resourceType.NameInK8S !== KubernetesCronJobResource.NameInK8S
    ) {
      return {
        showSuspendCronjobAction: false,
        showResumeCronjobAction: false,
      };
    }

    return {
      showSuspendCronjobAction: rowResource.suspend === "False",
      showResumeCronjobAction: rowResource.suspend === "True",
    };
  }, [showSuspendResumeAction, resourceType, rowResource.suspend]);
};
