import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacPoliciesApiApiV1RbacPoliciesIdPutRequest,
  RbacPolicy,
  apiV1RbacPoliciesIdPutUrl,
} from "../../../../../generated/auth";

const UPDATE_RBAC_POLICY_PATH = "/rbac-policies/:id";

const updateRbacPolicy = async (
  apiClient: AxiosInstance,
  params: RbacPoliciesApiApiV1RbacPoliciesIdPutRequest
): Promise<RbacPolicy> => {
  const { data } = await apiClient.put(
    apiV1RbacPoliciesIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.updateRbacPolicyRequest
  );
  return data;
};

export const useUpdateRbacPolicy = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [UPDATE_RBAC_POLICY_PATH],
    (params: RbacPoliciesApiApiV1RbacPoliciesIdPutRequest) =>
      updateRbacPolicy(apiClient, params)
  );
};
