import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import {
  AllocationCostMetricsState,
  AllocationFiltersState,
  AllocationSummaryState,
  CostOptimizationConfigState,
  RightSizingFiltersState,
  RightSizingRecommendationsModalState,
  RightSizingSummaryState,
  SharedCostFiltersState,
  RightSizingCpuMetricsState,
  RightSizingMemoryMetricsState,
  RightSizingCostMetricsState,
  RightSizingRecommendationsStrategiesState,
  RightSizingRecommendationsState,
  CostOptimizationConfigFetchState,
  CostOptimizationConfigUpdateState,
  ClustersEarliestRowTime,
  ServiceResourceDrawerState,
  AllocationCostStatsState,
  RightSizingStatsState,
  RightSizingPaginatedTableState,
} from "../types/costOptimizationTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { initialState } from "./initialState";

export type CostOptimizationState = {
  allocationFilters: AllocationFiltersState;
  rightSizingFilters: RightSizingFiltersState;
  sharedCostFilters: SharedCostFiltersState;
  clustersEarliestRowTime: ClustersEarliestRowTime;
  allocationSummaryState: AllocationSummaryState;
  allocationMetricsState: AllocationCostMetricsState;
  rightSizingSummaryState: RightSizingSummaryState;
  rightSizingMetricsState: RightSizingCostMetricsState;
  configState: CostOptimizationConfigState;
  rightSizingRecommendationsModalState: RightSizingRecommendationsModalState;
  rightSizingRecommendationsStrategiesState: RightSizingRecommendationsStrategiesState;
  rightSizingRecommendationsState: RightSizingRecommendationsState;
  serviceResourceDrawerState: ServiceResourceDrawerState;
  lastVisitTime: number;
  allocationStatsState: AllocationCostStatsState;
  rightSizingStatsState: RightSizingStatsState;
  rightSizingPaginatedTableState: RightSizingPaginatedTableState;
};

export type CostOptimizationActions = {
  setSharedCostFilters: (filters: Partial<SharedCostFiltersState>) => void;
  setClustersEarliestRowTime: (clusters: ClustersEarliestRowTime) => void;
  setAllocationFilters: (filters: Partial<AllocationFiltersState>) => void;
  setAllocationSummaryState: (state: AllocationSummaryState) => void;
  setAllocationMetricsState: (state: AllocationCostMetricsState) => void;
  setRightSizingCPUMetricsState: (state: RightSizingCpuMetricsState) => void;
  setRightSizingMemoryMetricsState: (
    state: RightSizingMemoryMetricsState
  ) => void;
  setRightSizingFilters: (filters: Partial<RightSizingFiltersState>) => void;
  setRightSizingSummaryState: (state: RightSizingSummaryState) => void;
  setRightSizingRecommendationModal: (
    state: RightSizingRecommendationsModalState
  ) => void;
  setRightSizingRecommendationsState: (
    state: RightSizingRecommendationsState
  ) => void;
  setRightSizingRecommendationsStrategiesState: (
    state: RightSizingRecommendationsStrategiesState
  ) => void;
  setConfigState: (state: Partial<CostOptimizationConfigState>) => void;
  setConfigFetchState: (
    fetchState: Partial<CostOptimizationConfigFetchState>
  ) => void;
  setConfigUpdateState: (
    updateState: CostOptimizationConfigUpdateState
  ) => void;
  setLastVisitTime: (newDateStamp: number) => void;
  serviceResourceDrawerState: {
    openServiceResourceDrawer: (serviceId: string) => void;
    closeServiceResourceDrawer: () => void;
  };
  setAllocationStatsState: (state: AllocationCostStatsState) => void;
  setRightSizingStatsState: (state: RightSizingStatsState) => void;
  setRightSizingPaginatedTableState: (
    state: Partial<RightSizingPaginatedTableState>
  ) => void;
};

export type CostOptimizationStore = CostOptimizationState &
  CostOptimizationActions;

export const useCostOptimizationStore = create(
  immer<CostOptimizationStore>((set) => ({
    ...initialState,
    setAllocationFilters: (filters: Partial<AllocationFiltersState>) => {
      set((state) => ({
        allocationFilters: {
          ...state.allocationFilters,
          ...filters,
        },
      }));
    },
    setRightSizingFilters: (filters: Partial<RightSizingFiltersState>) => {
      set((state) => ({
        rightSizingFilters: {
          ...state.rightSizingFilters,
          ...filters,
        },
      }));
    },
    setSharedCostFilters: (filters: Partial<SharedCostFiltersState>) => {
      set((state) => ({
        sharedCostFilters: {
          ...state.sharedCostFilters,
          ...filters,
        },
      }));
    },
    setClustersEarliestRowTime: (clusters: ClustersEarliestRowTime) => {
      set({ clustersEarliestRowTime: clusters });
    },
    setAllocationSummaryState: (summaryState: AllocationSummaryState) => {
      set({ allocationSummaryState: summaryState });
    },
    setAllocationMetricsState: (metricsState: AllocationCostMetricsState) => {
      set({ allocationMetricsState: metricsState });
    },
    setRightSizingSummaryState: (summaryState: RightSizingSummaryState) => {
      set({ rightSizingSummaryState: summaryState });
    },
    setAllocationStatsState: (metricsState: AllocationCostStatsState) => {
      set({ allocationStatsState: metricsState });
    },
    setRightSizingStatsState: (metricsState: RightSizingStatsState) => {
      set({ rightSizingStatsState: metricsState });
    },
    setRightSizingCPUMetricsState: (
      metricsState: RightSizingCpuMetricsState
    ) => {
      set((state) => ({
        rightSizingMetricsState: {
          ...state.rightSizingMetricsState,
          cpu: metricsState,
        },
      }));
    },
    setRightSizingMemoryMetricsState: (
      metricsState: RightSizingMemoryMetricsState
    ) => {
      set((state) => ({
        rightSizingMetricsState: {
          ...state.rightSizingMetricsState,
          memory: metricsState,
        },
      }));
    },
    setConfigFetchState: (
      fetchState: Partial<CostOptimizationConfigFetchState>
    ) => {
      set((state) => ({
        configState: {
          updateState: state.configState.updateState,
          fetchState: {
            ...state.configState.fetchState,
            ...fetchState,
          },
        },
      }));
    },
    setConfigUpdateState: (updateState: CostOptimizationConfigUpdateState) => {
      set((state) => ({
        configState: {
          fetchState: state.configState.fetchState,
          updateState: {
            ...state.configState.updateState,
            ...updateState,
          },
        },
      }));
    },
    setConfigState: (configState: Partial<CostOptimizationConfigState>) => {
      set((state) => ({
        configState: { ...state.configState, ...configState },
      }));
    },
    setRightSizingRecommendationModal: (
      modalState: RightSizingRecommendationsModalState
    ) => {
      set({ rightSizingRecommendationsModalState: modalState });
    },
    setLastVisitTime: (newDateStamp: number) => {
      set({ lastVisitTime: newDateStamp });
    },
    setRightSizingRecommendationsState: (
      recommendationsState: RightSizingRecommendationsState
    ) => {
      set({
        rightSizingRecommendationsState: {
          ...recommendationsState,
        },
      });
    },
    setRightSizingRecommendationsStrategiesState: (
      strategiesState: RightSizingRecommendationsStrategiesState
    ) => {
      set((state) => ({
        rightSizingRecommendationsStrategiesState: {
          ...state.rightSizingRecommendationsStrategiesState,
          ...strategiesState,
        },
      }));
    },
    setRightSizingPaginatedTableState: (
      paginatedTableState: Partial<RightSizingPaginatedTableState>
    ) => {
      set((state) => ({
        rightSizingPaginatedTableState: {
          ...state.rightSizingPaginatedTableState,
          ...paginatedTableState,
        },
      }));
    },
    serviceResourceDrawerState: {
      ...initialState.serviceResourceDrawerState,
      openServiceResourceDrawer: (serviceId: string) => {
        set((state) => ({
          serviceResourceDrawerState: {
            ...state.serviceResourceDrawerState,
            resourceDrawerServiceId: serviceId,
          },
        }));
      },
      closeServiceResourceDrawer: () => {
        set((state) => ({
          serviceResourceDrawerState: {
            ...state.serviceResourceDrawerState,
            resourceDrawerServiceId: undefined,
          },
        }));
      },
    },
  }))
);

export const resetCostOptimizationStore = (): void => {
  const {
    allocationSummaryState,
    allocationFilters,
    allocationMetricsState,
    allocationStatsState,
    rightSizingSummaryState,
    rightSizingMetricsState,
    rightSizingStatsState,
    rightSizingPaginatedTableState,
  } = initialState;
  useCostOptimizationStore.setState({
    allocationSummaryState,
    allocationFilters,
    allocationMetricsState,
    allocationStatsState,
    rightSizingSummaryState,
    rightSizingMetricsState,
    rightSizingStatsState,
    rightSizingPaginatedTableState,
    serviceResourceDrawerState: {
      ...useCostOptimizationStore.getState().serviceResourceDrawerState,
      resourceDrawerServiceId: undefined,
    },
  });
};
