import React from "react";
import styled from "styled-components";

import { gray10 } from "../../../../../Colors";
import { TextRegular, Text } from "../../../../common/typography";

import { WelcomePadding, StepContent } from "./steps.styles";
const StyledStepContent = styled(StepContent)`
  ${WelcomePadding}
  gap: 1.5rem;
`;

const StyledText = styled(Text)`
  color: ${gray10};
`;

const GetStarted: React.FC = () => (
  <StyledStepContent>
    <div>
      <TextRegular>
        Follow the installation wizard to deploy Komodor’s Agent* on your
        cluster in less than 5 minutes.
      </TextRegular>
      <ul>
        <li>
          <Text>
            <strong>1.</strong> Click on ‘Get Started’
          </Text>
        </li>
        <li>
          <Text>
            <strong>2.</strong> Provide cluster name
          </Text>
        </li>
        <li>
          <Text>
            <strong>3.</strong> Copy-paste the Helm command to your CLI
          </Text>
        </li>
        <li>
          <Text>
            <strong>4.</strong> Start integrating your favorite tools to enrich
            your Komodor experience
          </Text>
        </li>
      </ul>
      <br />
      <StyledText>
        (The agent is only granted permissions to list and read specific
        resources on your Kubernetes clusters, such as pods, services,
        deployments, etc.)
      </StyledText>
    </div>
  </StyledStepContent>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default GetStarted;
