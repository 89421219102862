import React from "react";
import { useNavigate } from "react-router-dom";

import { LocalStorageItem } from "../../utils/localStorageSettings";

import {
  MarketplaceActivationStateKey_CallbackUrl,
  MarketplaceActivationStateKey_MarketplaceConnectionId,
  MarketplaceActivationStateKey_Origin,
} from "./consts";

const storedMarketplaceActivationState = new LocalStorageItem(
  "marketplace_activation_state"
);

export interface MarketplaceActivationState {
  [MarketplaceActivationStateKey_Origin]: string;
  [MarketplaceActivationStateKey_MarketplaceConnectionId]: string;
  [MarketplaceActivationStateKey_CallbackUrl]: string;
}

function isMarketplaceActivationState(
  value: unknown
): value is MarketplaceActivationState {
  if (typeof value !== "object" || value === null) return false;

  const state = value as MarketplaceActivationState;

  return (
    typeof state[MarketplaceActivationStateKey_Origin] === "string" &&
    typeof state[MarketplaceActivationStateKey_MarketplaceConnectionId] ===
      "string" &&
    typeof state[MarketplaceActivationStateKey_CallbackUrl] === "string"
  );
}

export const getMarketplaceActivationState =
  (): MarketplaceActivationState | null => {
    const value = storedMarketplaceActivationState.get();
    if (!value) return null;

    try {
      const state = JSON.parse(value);
      if (!isMarketplaceActivationState(state)) return null;
      return state;
    } catch (e) {
      return null;
    }
  };

export const useSetMarketplaceActivationState: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const value = {
    [MarketplaceActivationStateKey_Origin]: urlParams.get(
      MarketplaceActivationStateKey_Origin
    ),
    [MarketplaceActivationStateKey_MarketplaceConnectionId]: urlParams.get(
      MarketplaceActivationStateKey_MarketplaceConnectionId
    ),
    [MarketplaceActivationStateKey_CallbackUrl]: urlParams.get(
      MarketplaceActivationStateKey_CallbackUrl
    ),
  };
  if (!isMarketplaceActivationState(value)) return null;
  storedMarketplaceActivationState.set(JSON.stringify(value));
  urlParams.delete(MarketplaceActivationStateKey_Origin);
  urlParams.delete(MarketplaceActivationStateKey_MarketplaceConnectionId);
  urlParams.delete(MarketplaceActivationStateKey_CallbackUrl);
  navigate({ search: urlParams.toString() }, { replace: true });

  return null;
};

export const clearMarketplaceActivationState = (): void =>
  storedMarketplaceActivationState.remove();
