import { useQuery } from "@tanstack/react-query";

import {
  getWorkloadContainersRestartsUrl,
  MetricsApiGetWorkloadContainersRestartsRequest,
  WorkloadMetricsContainersRestarts,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_WORKLOAD_CONTAINERS_RESTART_PATH =
  "/api/v1/workload/containers/restarts";

export const useGetWorkloadContainerRestarts = (
  params: MetricsApiGetWorkloadContainersRestartsRequest,
  enabled?: boolean
) => {
  const apiClient = useMetricsApiClient();
  const url = getWorkloadContainersRestartsUrl(
    params,
    apiClient.defaults.baseURL ?? ""
  );

  return useQuery(
    [GET_WORKLOAD_CONTAINERS_RESTART_PATH, params],
    async () => await apiClient.get<WorkloadMetricsContainersRestarts>(url),
    { enabled: enabled }
  );
};
