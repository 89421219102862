import React, { useMemo, useState } from "react";
import { omit } from "lodash";
import { diff, flattenChangeset } from "json-diff-ts";
import { palette } from "@komodorio/design-system";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { ArrowRight16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import DiffEntry from "../common/ProcessList/details/deployEvent/DiffEntry";
import { ObjectDiffAsYaml } from "../common/ObjectDiff";

import { AuditData } from "./types";

const DiffButton = styled(Typography).attrs({
  underline: true,
  bold: true,
})`
  cursor: pointer;
  color: ${palette.blue[500]};
  display: flex;
  gap: 0.25rem;
  width: fit-content;
  align-items: center;
`;

export const DefaultAuditDetails: React.FC<{ data: AuditData }> = ({
  data,
}) => {
  const PREVIEW_LIMIT = 8;
  const [openDiff, setOpenDiff] = useState(false);

  const excludedFields = [
    "createdAt",
    "updatedAt",
    "id",
    "accountId",
    "userId",
    "isVerified",
    "allowedAccounts",
    "showInWeb",
  ];
  const oldObj = omit(data?.details?.old, excludedFields);
  const newObj = omit(data?.details?.new, excludedFields);

  const changes = flattenChangeset(diff(oldObj, newObj));

  if (!changes.length) {
    return null;
  }

  return (
    <>
      <Typography size="medium">
        {changes.slice(0, PREVIEW_LIMIT).map((c) => (
          <DiffEntry
            key={c.path}
            change={{
              name: c.key,
              previous: JSON.stringify(c.oldValue),
              current: JSON.stringify(c.value),
            }}
          />
        ))}
      </Typography>
      <br />
      <DiffButton onClick={() => setOpenDiff(true)}>
        View all {changes.length} changes on diff <ArrowRight16 />
      </DiffButton>
      <Modal
        width="50rem"
        isOpen={openDiff}
        onRequestClose={() => setOpenDiff(false)}
      >
        <ModalHeader />
        <ModalContent>
          <ObjectDiffAsYaml oldObj={oldObj} newObj={newObj} />
        </ModalContent>
      </Modal>
    </>
  );
};

const resourceMapping = {
  cluster: {
    title: "cluster",
    getData: (data: AuditData) => data.details.resource?.cluster,
  },
  namespace: {
    title: "namespace",
    getData: (data: AuditData) => data.details.resource?.namespace,
  },
  resourceName: {
    title: "resource name",
    getData: (data: AuditData) => data.details.resource?.resourceName,
  },
  result: {
    title: "result",
    getData: (data: AuditData) =>
      `${data.details?.code} ${data.details?.message}`,
  },
};

function getResourceDetails(data: AuditData) {
  return Object.values(resourceMapping)
    .map(({ title, getData }) => ({
      title,
      data: getData(data),
    }))
    .filter(Boolean);
}

export const ManualActionCommon: React.FC<{ data: AuditData }> = ({ data }) => {
  const resourceDetails = useMemo(() => getResourceDetails(data), [data]);
  return (
    <DetailsGrid>
      {resourceDetails.map((e, i) => (
        <React.Fragment key={e.title}>
          <div>{e.title}</div>
          <div>{e.data}</div>
        </React.Fragment>
      ))}
    </DetailsGrid>
  );
};

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  justify-content: start;
  gap: 0.75rem;
  > :nth-child(2n + 2) {
    font-weight: bold;
  }
`;
