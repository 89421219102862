import styled from "styled-components";

import { StyledPaper } from "../../shared/styles";

const MIN_WIDTH = 400;
const MIN_HEIGHT = 104;

export const Container = styled(StyledPaper)`
  && {
    display: flex;
    padding: 0;
    flex: 1 1 0;
    min-width: ${MIN_WIDTH}px;
    min-height: ${MIN_HEIGHT}px;
    & > div:last-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;
