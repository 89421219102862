import retryElectronSetupEnv from "./retryElectronSetupEnv";

const useRetryDesktopSetup = (): {
  retrySetup: () => void;
} => {
  const retrySetup = async () => {
    (async () => {
      retryElectronSetupEnv();
    })();
  };

  return { retrySetup };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useRetryDesktopSetup;
