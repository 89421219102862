import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { useDefaultClusterForInspection } from "@/components/Inspection/utils/useDefaultClusterForInspection";
import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { getRoutePathFromKubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedKubernetesResources";

export const RedirectToResourceInspectionPage: React.FC<{
  resource: KubernetesResource;
}> = ({ resource }) => {
  const defaultCluster = useDefaultClusterForInspection();
  const location = useLocation();
  return defaultCluster ? (
    <Navigate
      to={{
        pathname: getRoutePathFromKubernetesResource(resource, defaultCluster),
        search: location.search,
      }}
    />
  ) : (
    <LinesLoader />
  );
};
