import { useEffect, useState } from "react";

export const useDistanceFromWindowBottomCalculator = (): number => {
  const [distanceFromWindowBottom, setDistanceFromWindowBottom] = useState(
    document.body.offsetHeight
  );
  useEffect(() => {
    const handleScroll = () => {
      setDistanceFromWindowBottom(
        Math.abs(
          window.innerHeight + window.scrollY - document.body.offsetHeight
        )
      );
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return distanceFromWindowBottom;
};
