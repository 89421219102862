import React from "react";

import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { AppBarLink } from "./AppBarLink";
import { PageName, usePages } from "./pages";

const { WorkspaceOverview, ClusterOverview } = AriaLabels.AppBar;

const name = PageName.Overview;

export const OverviewLink: React.FC = () => {
  const pages = usePages();
  const page = pages[name];

  const { isClusterWorkspace } = useWorkspaces();

  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
      locked={page.locked}
      ariaLabel={isClusterWorkspace ? ClusterOverview : WorkspaceOverview}
    />
  ) : null;
};
