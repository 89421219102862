import { generateSocketPayload } from "../utils/generateSocketPayload";

import { isSocketReadyToSend } from "./isSocketReadyToSend";
import { SocketMessageType } from "./types";

type InitiatePodExecSessionParams = {
  connection: WebSocket;
  onInitialized: () => void;
  sessionId?: string;
  resolve: (value: void) => void;
  reject: () => void;
  initData?: Record<string, string | number>;
  messageType: SocketMessageType;
};

export const handleConnectionOnOpen = ({
  connection,
  onInitialized,
  sessionId,
  resolve,
  reject,
  initData: data,
  messageType,
}: InitiatePodExecSessionParams): void => {
  connection.onopen = () => {
    const isReady = isSocketReadyToSend(connection);

    if (isReady) {
      resolve();
      !sessionId && // The existence of session id meaning we have an existing session, so init message is not required.
        connection.send(
          JSON.stringify(
            generateSocketPayload({
              messageType,
              data,
            })
          )
        );
    } else {
      reject();
    }

    onInitialized();
  };
};
