export const shadowCenteredModalStyles = {
  overlay: {
    zIndex: 10000,
    background: "rgb(0,0,0,0)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: "1rem 2rem",
    top: "unset",
    left: "unset",
    right: "unset",
    bottom: "unset",
    boxShadow: "0px 6px 24px rgba(22, 24, 31, 0.3)",
    borderRadius: "1rem",
    border: "unset",
  },
};
