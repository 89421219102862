import React from "react";
import styled from "styled-components";

import { useCostOptimizationStore } from "../../../../store/costOptimizationStore";
import { selectRightSizingStatsState } from "../../../../store/costOptimizationStoreSelectors";
import { StyledPaddedCard } from "../../../styles/CostOptimizationStyledComponents";
import { FetchError } from "../../../shared/errors/FetchError";
import { CenteredLoader } from "../../../../../../shared/components/CenteredLoader";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { isCostComparisonDataInvalid } from "../../utils/isCostComparisonDataInvalid";

import { PotentialCostComparisonBars } from "./PotentialCostComparisonBars";

const Container = styled(StyledPaddedCard)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  min-width: 445px;
  & > div {
    min-height: inherit;
  }
`;

export const PotentialCostComparison = () => {
  const { loading, data, error } = useCostOptimizationStore(
    selectRightSizingStatsState
  );

  const currentCostPerMonth = data?.currentCostPerMonth ?? 0;
  const monthlySaving = data?.monthlySaving ?? 0;
  const unallocatedResourcesPrice = data?.unallocatedResourcesPrice ?? 0;

  const isDataInvalid = isCostComparisonDataInvalid(data, error);

  const content = loading ? (
    <Container>
      <CenteredLoader />
    </Container>
  ) : isDataInvalid ? (
    <Container>
      <FetchError />
    </Container>
  ) : (
    <PotentialCostComparisonBars
      before={currentCostPerMonth}
      monthlySaving={monthlySaving}
      unallocatedPrice={unallocatedResourcesPrice}
    />
  );

  return (
    <Wrapper
      aria-label={
        costOptimizationAriaLabels.rightSizingPage.summaryCards
          .potentialCostComparison.card
      }
    >
      {content}
    </Wrapper>
  );
};
