import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { AnimatedKeyboardArrowDown } from "../../../../ReliabilityStyles";
import { ExpandableContentContainer } from "../../../shared/ExpandableContentContainer";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { ViolationsAggregationGroup } from "../../../../../../generated/reliabilityApi";

const Container = styled(Card)`
  cursor: pointer;
`;

const InteractiveSectionContainer = styled.div`
  cursor: pointer;
  padding: 8px 0;
`;

const PaddedDiv = styled.div<{ $paddingBottom: boolean }>`
  padding: 0 16px ${({ $paddingBottom }) => ($paddingBottom ? 24 : 0)}px 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AnimatedArrow = styled(AnimatedKeyboardArrowDown)`
  && {
    width: 20px;
    height: 20px;
    padding: 6px;
    margin-right: 8px;
  }
`;

const {
  groups: {
    singleGroup: singleGroupArialLabel,
    Title: groupTitleArialLabel,
    expandHitArea: expandHitAreaArialLabel,
  },
} = reliabilityArialLabels;

type Props = ViolationsAggregationGroup & {
  onExpandClick: () => void;
  isOpen: boolean;
  title: string;
  cardContent: ReactElement;
  closeCardPaddingBottom?: boolean;
  summaryElement?: ReactElement;
  additionalCardContent?: ReactElement;
};

export const GroupCard: React.FC<Props> = ({
  id,
  onExpandClick,
  isOpen,
  title,
  closeCardPaddingBottom = false,
  summaryElement,
  additionalCardContent,
  cardContent,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const ariaLabel = `${singleGroupArialLabel} ${id} ${isOpen ? "open" : ""}`;

  return (
    <Container elevation={isHovering ? 8 : 1} aria-label={ariaLabel}>
      <InteractiveSectionContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onExpandClick}
        aria-label={expandHitAreaArialLabel}
      >
        <PaddedDiv $paddingBottom={closeCardPaddingBottom}>
          <HeaderContainer>
            <AnimatedArrow open={isOpen} color="action" />
            <Typography variant={"h4"} aria-label={groupTitleArialLabel}>
              {title}
            </Typography>
            {summaryElement}
          </HeaderContainer>
          {additionalCardContent}
        </PaddedDiv>
      </InteractiveSectionContainer>
      <ExpandableContentContainer
        isExpanded={isOpen}
        autoDetectContentHeight={false}
      >
        <PaddedDiv $paddingBottom>{cardContent}</PaddedDiv>
      </ExpandableContentContainer>
    </Container>
  );
};
