import React from "react";
import { LoadBalancerIngress } from "kubernetes-types/core/v1.d";

import { DescribeData } from "../common/DescribeData";
import { Table } from "../../../../../shared/components/Table/Table";

export const LoadBalancerIngressPoints: React.FC<{
  lbIngress: Array<LoadBalancerIngress>;
}> = ({ lbIngress }) =>
  lbIngress && lbIngress.length ? (
    <DescribeData title={"Load Balancer Ingress Points"}>
      {
        <Table
          data={lbIngress.map(getLbIngressData)}
          columns={LB_INGRESS_POINTS_COLUMNS}
          sortable={false}
        />
      }
    </DescribeData>
  ) : null;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const LB_INGRESS_POINTS_COLUMNS = [
  {
    header: "Hostname",
    accessor: "hostname",
  },
  {
    header: "IP",
    accessor: "ip",
  },
];

const getLbIngressData = (lbIngress: LoadBalancerIngress) => {
  return {
    hostname: lbIngress.hostname ?? "",
    ip: lbIngress.ip ?? "---",
  };
};
