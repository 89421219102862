import React, { useMemo, useState } from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../resources/deployment";
import DaemonSet from "../resources/daemonset";
import StatefulSet from "../resources/statefulset";
import EventDrawer from "../../common/ProcessList/EventDrawer";
import DeployEventGroup from "../../common/EventGroup/deployEvent/DeployEventGroup";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import Rollout from "../resources/rollout";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";

import { KomodorServiceHealthAvailability } from "./KomodorServiceHealthAvailability";
import { KomodorServiceHealthDeploy } from "./KomodorServiceHealthDeploy";
import { KomodorServiceReliability } from "./KomodorServiceReliability";

const enum ServiceStatus {
  "DELETED" = "DELETED",
  "INACTIVE" = "INACTIVE",
  "HEALTHY" = "HEALTHY",
  "UNHEALTHY" = "UNHEALTHY",
}

const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 1rem 0;
  align-items: flex-start;
  font-size: 0.75rem;
  overflow-x: auto;
`;

interface KomodorServiceHealthProps {
  resource: Deployment | DaemonSet | StatefulSet | Rollout;
}

export const KomodorServiceStatuses: React.FC<KomodorServiceHealthProps> = ({
  resource,
}) => {
  const [openMonitor, setOpenMonitor] = useState(false);
  const [eventMonitor, setEventMonitor] = useState<
    DeployEventGroup | WorkflowIssueEventGroup | undefined
  >(undefined);

  const status = useMemo(() => {
    return resource.isDeleted
      ? ServiceStatus.DELETED
      : resource.inactive
      ? ServiceStatus.INACTIVE
      : resource.healthy
      ? ServiceStatus.HEALTHY
      : ServiceStatus.UNHEALTHY;
  }, [resource.healthy, resource.inactive, resource.isDeleted]);

  const isActiveService = useMemo(
    () => ![ServiceStatus.DELETED, ServiceStatus.INACTIVE].includes(status),
    [status]
  );

  const uid = buildKomodorUid({
    kind: resource.kind,
    clusterName: resource.cluster,
    namespace: resource.namespace,
    resourceName: resource.name,
  });

  return (
    <FlexContainer>
      {resource.healthy !== undefined && (
        <KomodorServiceHealthAvailability
          resource={resource}
          setOpenMonitor={setOpenMonitor}
          setEventMonitor={setEventMonitor}
        />
      )}
      {isActiveService && (
        <KomodorServiceHealthDeploy
          resource={resource}
          setOpenMonitor={setOpenMonitor}
          setEventMonitor={setEventMonitor}
        />
      )}
      {uid && <KomodorServiceReliability komodorUid={uid} />}
      <EventDrawer
        open={openMonitor}
        onClose={() => setOpenMonitor(false)}
        event={eventMonitor}
        ariaLabel={AriaLabels.ResourceView.Header.ServiceHealth.IssueDrawer}
      >
        {eventMonitor?.renderEventDetails(() => setOpenMonitor(false)) ?? null}
      </EventDrawer>
    </FlexContainer>
  );
};
