import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import styled from "styled-components";

const successColor = muiColors.teal[900];
const errorColor = muiColors.pink[700];

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Description = styled(Typography).attrs({ variant: "h5" })``;

export const UpgradeStatus: React.FC<{
  deprecatedApis: number | undefined;
  suggestedVersion: string | undefined;
}> = ({ deprecatedApis, suggestedVersion }) => {
  const isOkToUpgrade = !deprecatedApis && suggestedVersion;
  const Icon = isOkToUpgrade ? CheckCircle : Error;
  const color = isOkToUpgrade ? successColor : errorColor;

  const text = isOkToUpgrade
    ? `Ready for upgrade to version ${suggestedVersion}. No API constraints detected`
    : "Deprecated and Removed APIs are in use.";

  return (
    <Container>
      <Icon fontSize="small" style={{ color }} />
      <Description color={color}>{text}</Description>
    </Container>
  );
};
