import {
  AWS,
  Azure,
  GoogleCloud,
  Kubernetes,
  OpenShift,
  Rancher,
  Vmware,
  K3s,
  VCluster,
  Oracle,
} from "@komodorio/design-system/icons";

import { ClusterProviderType } from "../../../generated/workspacesApi";
import { AriaLabels } from "../../config/ariaLabels";

export const CloudProvider: React.FC<{
  clusterProviderType: ClusterProviderType;
}> = ({ clusterProviderType }) => {
  switch (clusterProviderType) {
    case ClusterProviderType.Aws:
      return <AWS aria-label={AriaLabels.CloudProviderIcon.AWS} />;
    case ClusterProviderType.Azure:
      return <Azure aria-label={AriaLabels.CloudProviderIcon.Azure} />;
    case ClusterProviderType.Gcp:
      return (
        <GoogleCloud aria-label={AriaLabels.CloudProviderIcon.GoogleCloud} />
      );
    case ClusterProviderType.Openshift:
      return <OpenShift aria-label={AriaLabels.CloudProviderIcon.OpenShift} />;
    case ClusterProviderType.Vmware:
      return <Vmware aria-label={AriaLabels.CloudProviderIcon.Vmware} />;
    case ClusterProviderType.Oracle:
      return <Oracle aria-label={AriaLabels.CloudProviderIcon.Oracle} />;
    case ClusterProviderType.Vcluster:
      return <VCluster aria-label={AriaLabels.CloudProviderIcon.Vcluster} />;
    case ClusterProviderType.K3S:
      return <K3s aria-label={AriaLabels.CloudProviderIcon.K3S} />;
    case ClusterProviderType.Rancher:
      return <Rancher aria-label={AriaLabels.CloudProviderIcon.Rancher} />;
    case ClusterProviderType.Kubernetes:
    default:
      return (
        <Kubernetes aria-label={AriaLabels.CloudProviderIcon.Kubernetes} />
      );
  }
};
