import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { LazyEditor } from "@/components/common/LazyEditor";

const DEFAULT_HEIGHT = "230px";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  background-color: ${muiColors.gray[50]};
  outline: 1px solid ${muiColors.gray[200]};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const StyledAceEditorContainer = styled.div`
  .ace_editor {
    border-radius: 0;
  }
  .ace_editor .ace_marker-layer .ace_active-line {
    background: none;
  }
`;

type YamlEditorProps = {
  yaml: string | undefined;
  highlightLine?: number;
  height?: string;
  width?: string;
};

type Props = YamlEditorProps & {
  title: string;
  additionalContent?: JSX.Element;
};

export const YamlEditor: React.FC<YamlEditorProps> = ({
  yaml,
  highlightLine,
  height = DEFAULT_HEIGHT,
  width = "100%",
}) => {
  return (
    <StyledAceEditorContainer>
      <LazyEditor
        height={height}
        width={width}
        readOnly
        value={yaml}
        mode="yaml"
        showGutter={false}
        highlightLines={
          highlightLine
            ? [{ from: highlightLine, to: highlightLine }]
            : undefined
        }
        lineToScroll={highlightLine}
      />
    </StyledAceEditorContainer>
  );
};

export const YamlView: React.FC<Props> = ({
  title,
  height,
  yaml,
  highlightLine,
  additionalContent,
}) => {
  if (!yaml) return null;

  return (
    <Container>
      <Header>
        <Typography variant="h5">{title}</Typography>
        {additionalContent}
      </Header>
      <YamlEditor yaml={yaml} highlightLine={highlightLine} height={height} />
    </Container>
  );
};
