import styled from "styled-components";

export const MainContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GraphsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
