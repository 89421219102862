import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacRole,
  RbacRolesApiApiV1RbacRolesIdDeleteRequest,
  apiV1RbacRolesIdDeleteUrl,
} from "../../../../../generated/auth";

const DELETE_RBAC_ROLE_PATH = "/rbac-roles/:id";

const deleteRbacRole = async (
  apiClient: AxiosInstance,
  params: RbacRolesApiApiV1RbacRolesIdDeleteRequest
): Promise<RbacRole> => {
  const { data } = await apiClient.delete(
    apiV1RbacRolesIdDeleteUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useDeleteRbacRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [DELETE_RBAC_ROLE_PATH],
    (params: RbacRolesApiApiV1RbacRolesIdDeleteRequest) =>
      deleteRbacRole(apiClient, params)
  );
};
