import React, { useMemo, useState } from "react";
import {
  Health24,
  Helm24,
  Workflows24,
  Workloads24,
} from "@komodorio/design-system/icons";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";

import { SectionAccordion } from "../../styles";
import { SectionAccordionSummary } from "../SectionAccordionSummary";

import { ADDONS_SECTION_TITLE } from "./constants";
import { AddonCard } from "./AddonCard";
import { AddonsChip } from "./AddonsChip";

import {
  CheckType,
  ViolationCountBy,
  ViolationFilterOperator,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import {
  K8S_ADDONS_CERT_MANAGER_ROUTE,
  K8S_ADDONS_WORKFLOWS_ROUTE,
} from "@/components/routes/routes";
import { useGetViolationsFilter } from "@/shared/hooks/reliability-api/filters/useGetViolationsFilter";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { getRoutePathFromKubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { KubernetesHelmResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { useAppBarStateContext } from "@/components/AppBar/internal/appBarStateContext";

export const AddonsSection: React.FC<{ clusters?: string[] }> = ({
  clusters,
}) => {
  const [expanded, setExpanded] = useState(true);
  const { data, isLoading } = useGetViolationsFilter({
    countBy: ViolationCountBy.CheckType,
    postViolationsCountRequestBody: {
      filterBy: [
        {
          category: ViolationCountBy.Status,
          operator: ViolationFilterOperator.Include,
          value: [ViolationStatus.Open, ViolationStatus.Confirmed],
        },
        {
          category: ViolationCountBy.CheckType,
          operator: ViolationFilterOperator.Include,
          value: [CheckType.CertificateExpiration, CheckType.UnhealthyWorkflow],
        },
        ...(clusters !== undefined
          ? [
              {
                category: ViolationCountBy.ClusterName,
                operator: ViolationFilterOperator.Include,
                value: clusters,
              },
            ]
          : []),
      ],
    },
  });
  const count = useMemo(() => {
    return data?.data.items.reduce((acc, item) => acc + item.value, 0) || 0;
  }, [data?.data.items]);

  const appBarStateContext = useAppBarStateContext();
  const defaultCluster = appBarStateContext.defaultCluster ?? "";

  const helmRoute = getRoutePathFromKubernetesResource(
    KubernetesHelmResource,
    clusters?.[0] ?? defaultCluster
  );

  return (
    <SectionAccordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={false}
    >
      <SectionAccordionSummary
        title={ADDONS_SECTION_TITLE}
        icon={<Health24 />}
        isExpanded={expanded}
        isLoading={false}
        isEmpty={false}
        emptyMessage="All the services are healthy"
      >
        <Box
          display="flex"
          flexGrow={1}
          alignSelf="flex-end"
          justifyContent="flex-end"
          alignItems="center"
          height="100%"
        >
          <AddonsChip count={count} isLoading={isLoading} />
        </Box>
      </SectionAccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="row" gap="16px">
          <AddonCard
            ariaLabel={AriaLabels.ClusterOverview.AddonsSection.CertManager}
            label="Cert Manager"
            count={
              data?.data.items.find(
                (item) => item.key === CheckType.CertificateExpiration
              )?.value || 0
            }
            icon={Workloads24}
            isLoading={isLoading}
            path={K8S_ADDONS_CERT_MANAGER_ROUTE}
          />
          <AddonCard
            ariaLabel={AriaLabels.ClusterOverview.AddonsSection.Workflows}
            label="Workflows"
            count={
              data?.data.items.find(
                (item) => item.key === CheckType.UnhealthyWorkflow
              )?.value || 0
            }
            icon={Workflows24}
            isLoading={isLoading}
            path={K8S_ADDONS_WORKFLOWS_ROUTE}
          />
          <AddonCard
            ariaLabel={AriaLabels.ClusterOverview.AddonsSection.Workflows}
            label="Helm"
            count={undefined}
            icon={Helm24}
            isLoading={isLoading}
            path={helmRoute}
          />
        </Box>
      </AccordionDetails>
    </SectionAccordion>
  );
};
