import styled from "styled-components";

import { FlexColumns } from "../../common/FlexContainers";
import { pinkBrand } from "../../../Colors";

import BackgroundVectors from "./bg_blurry.svg";

export const BackgroundContainer = styled(FlexColumns)`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundVectors});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    filter: blur(50px);
    z-index: -1; /* Ensures the pseudo-element is behind the content */
  }
`;

export const CenteredContainer = styled(FlexColumns)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.85rem;
  background: white;
  border-radius: 8px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const PageBottom = styled.div`
  margin-top: auto;
  width: 100%;
`;

export const ErrorText = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  color: ${pinkBrand};
  padding-top: 0.5rem;
`;
