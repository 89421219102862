import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import React from "react";

const TRAILING_BREADCRUMB = "";

export const ItemWithBreadcrumbs = ({
  children,
  breadcrumbs,
}: {
  children: React.ReactNode;
  breadcrumbs: (string | undefined)[];
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Breadcrumbs
      allowOverflow={false}
      breadcrumbs={[
        ...breadcrumbs.filter((it): it is string => Boolean(it)),
        TRAILING_BREADCRUMB,
      ]}
    />
    {children}
  </Box>
);
