import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiColors, muiTheme } from "@komodorio/design-system";
import styled from "styled-components";
import { useState } from "react";

import EventDetailsSection from "@/components/common/ProcessList/details/components/EventDetailsSection";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import type DeployEventGroup from "@/components/common/EventGroup/deployEvent/DeployEventGroup";
import Resource from "@/components/ResourceView/resources";
import { useResourceAiInvestigation } from "@/components/ResourceView/AiInvestigation/common/useResourceAiInvestigation";
import { InvestigationTitle } from "@/components/ResourceView/AiInvestigation/common/InvestigationTitle";
import { InvestigationContent } from "@/components/ResourceView/AiInvestigation/common/InvestigationContent";
import Arrow from "@/components/common/ProcessList/Arrow";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";

const StyledInvestigationContent = styled(InvestigationContent)`
  padding-inline: 0;
`;

const StyledInvestigationTitle = styled(InvestigationTitle)`
  cursor: pointer;
`;

const StyledArrow = styled(Arrow)`
  margin-left: 12px;
`;

export const InvestigationSection: React.FC<{
  resource: Resource;
  eventGroup: DeployEventGroup;
}> = ({ resource, eventGroup }) => {
  const { klaudiaAiFailedDeployDrawer } = useOverridableFlags();
  const [open, setOpen] = useState(true);
  const isHealthy = eventGroup.status !== "failed";
  const {
    enabled,
    richSession,
    isError,
    isLoading,
    onRetryCallback,
    onShareClicked,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.EventDrawerById,
      eventIds: eventGroup.events.map((event) => event.id),
      eventType: MonitorType.Deploy,
      serviceId: eventGroup.serviceId,
    },
    requestId: eventGroup.id,
    resource,
    isHealthy,
    flowType: "Failed Deploy",
  });

  if (!enabled || !klaudiaAiFailedDeployDrawer || (isHealthy && !richSession)) {
    return null;
  }

  return (
    <EventDetailsSection
      icon={() => (
        <MagicAi24
          color={muiTheme.palette.primary.main}
          width={20}
          height={20}
        />
      )}
      title={
        <StyledInvestigationTitle
          onClick={() => setOpen((open) => !open)}
          showIcon={false}
          richSession={richSession}
          onShareClicked={onShareClicked}
          titleProps={{ variant: "h4", color: muiColors.gray[500] }}
          extraContent={
            richSession?.isComplete && richSession.isNoProblemFound ? null : (
              <StyledArrow
                width="12px"
                color={muiTheme.palette.text.primary}
                direction={open ? "down" : "right"}
              />
            )
          }
        />
      }
    >
      {open ? (
        <StyledInvestigationContent
          richSession={richSession}
          isError={isError}
          onRetryCallback={onRetryCallback}
          isLoading={isLoading}
          showBorder={false}
        />
      ) : null}
    </EventDetailsSection>
  );
};
