import { ManualActionInsightDataParsed } from "../../common/types";

export const useManualActionsInsight = (
  latestManualActionsInsights: ManualActionInsightDataParsed[]
) => {
  const actionsLength = latestManualActionsInsights.length;

  return {
    shouldShow: actionsLength > 0,
    text: `${actionsLength} correlated manual action${
      actionsLength > 1 ? "s" : ""
    } ${
      actionsLength > 1 ? "were" : "was"
    } performed within 2 hours before the issue started`,
  };
};
