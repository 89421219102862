import React, { useEffect, useMemo } from "react";

import { WORKFLOW_RUN_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { LinesLoader } from "../common/loaders/Line";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useResourceWithInterval, {
  UseResourceParams,
} from "../ResourceView/useResource";
import { useActiveAgent } from "../../shared/hooks/useAgents";
import { parseKomodorUid } from "../../shared/hooks/resources-api/resourcesAPIUtils";

import { FetchError } from "./EmptyStates";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export const ArgoWorkflowOwnerContent: React.FC<{
  cluster: string;
  selectedTab: number;
}> = ({ cluster, selectedTab }) => {
  const [selectedRunId] = useStateInSearchParams(WORKFLOW_RUN_PARAM_KEY);

  const agentId = useActiveAgent(cluster) ?? "";

  const resourceParams: UseResourceParams = useMemo(() => {
    const {
      cluster = "",
      namespace = "",
      name = "",
      kind = "",
    } = parseKomodorUid(selectedRunId ?? "") ?? {};

    return {
      agentId,
      cluster,
      namespace,
      resourceName: name,
      resourceType: kind,
      executeMutation: selectedRunId && agentId ? true : false,
    };
  }, [agentId, selectedRunId]);

  const { resource, failureMessage, clearState } =
    useResourceWithInterval(resourceParams);

  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceParams]);

  return !resource && !failureMessage ? (
    <LinesLoader />
  ) : failureMessage ? (
    <FetchError />
  ) : resource ? (
    resource.renderDrawerContent(selectedTab)
  ) : null;
};
