import React, { useCallback, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Props } from "recharts/types/component/Legend";
import { useDebouncedCallback } from "use-debounce";

import { BarChartData, BAR_CHART_TIME_LABEL_NAME } from "../utils/utils";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { graphUnits } from "../../../../utils/graphUtils";
import { ChartTooltip } from "../../../shared/graph/ChartTooltip";
import { Dictionary } from "../../../../../../shared/types/Dictionary";
import { useCostOptimizationStore } from "../../../../store/costOptimizationStore";
import { selectAllocationFilters } from "../../../../store/costOptimizationStoreSelectors";

import { ChartLegend } from "./Legend/Legend";
import { colors } from "./colors";

const Container = styled.div`
  height: 20.5rem;
  width: 100%;
`;

const { yAxis: yAxisLabel, xAxis: xAxisLabel } =
  costOptimizationAriaLabels.allocationPage.allocationGraph;

export const AllocationStackedBarChart: React.FC<{
  data: BarChartData[];
  chartKeys: string[];
}> = ({ data, chartKeys }) => {
  const { timeWindow } = useCostOptimizationStore(selectAllocationFilters);
  const [hideData, setHideData] = useState<Dictionary<boolean>>(
    Object.keys(data[0]).reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
  );
  const [currentValueHover, setCurrentValueHover] = useState<string | null>(
    null
  );

  const { callback: debouncedSetCurrentValueHover } = useDebouncedCallback(
    setCurrentValueHover,
    200
  );

  const legendItemOnClick = useCallback(
    (key: string) => {
      const newData = { ...hideData, [key]: !hideData[key] };
      setHideData(newData);
    },
    [hideData]
  );

  const chartData = useMemo(
    () =>
      data.map((chartElement) => {
        const fullElementData: {
          [x: string]: string | number | null;
        } = { ...chartElement };
        Object.keys(hideData).forEach((e) => {
          if (hideData[e]) {
            fullElementData[e] = null;
          }
        });
        return fullElementData;
      }),
    [data, hideData]
  );

  const chartBars = useMemo(() => {
    return [...chartKeys]
      .reverse()
      .map((key, index) => (
        <Bar
          key={key}
          dataKey={key}
          stackId="a"
          fill={colors[index % colors.length]}
          opacity={!currentValueHover ? 1 : currentValueHover === key ? 1 : 0.2}
        />
      ));
  }, [chartKeys, currentValueHover]);

  const AllocationLegend = useMemo(
    () => (
      <Legend
        layout="vertical"
        align="right"
        content={(props) => (
          <ChartLegend
            hide={hideData}
            setHide={legendItemOnClick}
            props={props as Props}
            setCurrentValueHover={debouncedSetCurrentValueHover}
          />
        )}
        wrapperStyle={{ bottom: 10 }}
      />
    ),
    [debouncedSetCurrentValueHover, hideData, legendItemOnClick]
  );

  const xAxisInterval = useMemo(() => {
    if (timeWindow === "30d" || timeWindow === "24h") return 1;
    return 0;
  }, [timeWindow]);

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          maxBarSize={40}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <RechartTooltip
            cursor={false}
            content={(props) => (
              <ChartTooltip data={props} unit={graphUnits.price} />
            )}
            wrapperStyle={{ outline: "none" }}
            isAnimationActive={false}
          />
          {AllocationLegend}
          {chartBars}
          <YAxis aria-label={yAxisLabel} tick={{ fontSize: 10 }} />
          <XAxis
            dataKey={BAR_CHART_TIME_LABEL_NAME}
            aria-label={xAxisLabel}
            tick={{ fontSize: 10 }}
            interval={xAxisInterval}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};
