import styled from "styled-components";

export const RelativeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  & > img {
    width: 100%;
    user-select: none;
  }
`;

export const ClickableArea = styled.div`
  position: absolute;
  cursor: pointer;
`;

export const CloseButtonArea = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 33px;
  left: 22.2px;
  border-radius: 4px;
`;

export const SelectableRow = styled(ClickableArea)<{
  height: string;
  top: string;
}>`
  width: 96.35%;
  height: ${({ height }) => height};
  left: 1.8%;
  top: ${({ top }) => top};
`;

export const ClickableCard = styled(ClickableArea)<{
  width: string;
  height: string;
  left?: string;
  right?: string;
  top: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
`;

export const CloseButton = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 20px;
  left: 20px;
  border-radius: 4px;
`;
