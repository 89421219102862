import { useEffect } from "react";

type UseKeydownProps = {
  cb: (e: KeyboardEvent) => void;
  disableListener?: boolean;
};
export const useKeydown = ({ cb, disableListener }: UseKeydownProps) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      cb(e);
    };
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [cb]);

  useEffect(() => {
    if (disableListener) {
      document.removeEventListener("keydown", cb);
    } else {
      document.addEventListener("keydown", cb);
    }
  }, [cb, disableListener]);
};
