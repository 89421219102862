import React from "react";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { YamlView } from "../../shared/YamlView/YamlView";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";
import { CopyYamlButton } from "../../shared/YamlView/components/CopyYamlButton";

import {
  getSecondHowDescription,
  getWhatDescription,
  getWhyDescription,
  howDescription,
  yamlExample,
} from "./texts";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";

export const PodPriority: React.FC = () => {
  const { data } = useViolation();

  const { headerProps, summaryProps, dependentByViolationsIds } =
    useGetCommonDrawerProps(data?.data.violation);

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription()}
              <ViolationsTableByIds
                title="Runtime impact"
                ids={dependentByViolationsIds}
                violationTableType={ViolationsTableType.riskAssessment}
              />
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={
            <>
              {howDescription()}
              <YamlView
                height="155px"
                title="example-priorityClass.yaml"
                yaml={yamlExample}
                additionalContent={<CopyYamlButton yaml={yamlExample} />}
              />
              {getSecondHowDescription()}
            </>
          }
        />
      }
    />
  );
};
