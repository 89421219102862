import React from "react";
import styled from "styled-components";

import { gray9 } from "../../../../Colors";

export const Pill = styled.span`
  padding: 0.25rem 0.75rem;
  width: fit-content;
  height: fit-content;
  background: ${gray9};
  border-radius: 100px;
`;

type ReasonTagProps = {
  reason: string;
  count: number;
};

export const ReasonTag = ({ reason, count }: ReasonTagProps) => {
  return (
    <div>
      <Pill>{reason}</Pill>
      {count > 1 ? <span> &times; {count}</span> : null}
    </div>
  );
};
