import styled from "styled-components";

import Input from "../../common/controls/Input";
import Select from "../../common/controls/Select";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    color: #3e4658;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;
  }
`;

export const FormInputGroup = styled.div`
  margin-bottom: 1.25rem;
`;

export const FormTextInput = styled(Input)<{ width?: string }>`
  ${({ width }) => `width: ${width ?? "18.5"}rem;`}
  height: 1rem;

  margin-top: 0.25rem;
`;

export const FormSelectInput = styled(Select)`
  width: 19.65rem;
  height: 2.25rem;

  margin-top: 0.25rem;
`;

export const FormSubmit = styled(Input).attrs({
  type: "submit",
})`
  background-color: #007aff;
  border-radius: 2px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;

  margin-top: 3rem;
  min-width: 12rem;
  padding: 0.5rem 1.5rem;
  align-self: center;
`;
