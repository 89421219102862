import React from "react";

interface Props {
  color: string | undefined;
  ariaLabel?: string;
}

export const TooltipItemIcon: React.FC<Props> = ({ color, ariaLabel }) => {
  return (
    <svg
      width="18"
      height="5"
      viewBox="0 0 18 5"
      fill="none"
      aria-label={ariaLabel}
    >
      <rect y="0.816406" width="18" height="4" rx="2" fill={color} />
    </svg>
  );
};
