import React, { useMemo } from "react";
import Link from "@mui/material/Link";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../shared/styles";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import {
  getGenericServiceColumns,
  getServiceRows,
} from "../../shared/components/InsightsDataTable/insightDataTableUtils";
import { InsightsDataTable } from "../../shared/components/InsightsDataTable/InsightsDataTable";
import { ServicesTableTitle } from "../../shared/components/InsightsDataTable/ServicesTableTitle";
import { ViewIssueButton } from "../shared/bestPractices/ViewIssueButton";

import {
  explanationBlock1,
  explanationBlock2,
  explanationTitle,
  learnMoreText,
  learnMoreUrl,
} from "./missingResourcesLimitsTexts";

const servicesColumnsWithButton = getGenericServiceColumns().concat({
  field: "actionButton",
  headerName: "",
  flex: 1,
  renderCell: (params: GridRenderCellParams) => (
    <ViewIssueButton rowData={params.row} defaultFilter="Efficiency" />
  ),
});

export const BestPracticesMissingResourcesLimitsModalContent: React.FC = () => {
  const {
    bestPracticesMissingRequestsOrLimits: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const rows = useMemo(() => {
    if (!data?.rows) return [];
    return getServiceRows(data.rows);
  }, [data]);

  return (
    <div>
      <InsightsDrawerTitle>{explanationTitle}</InsightsDrawerTitle>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock1}
      </InsightsDrawerContent>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock2}
      </InsightsDrawerContent>
      <Link variant={"body2"} href={learnMoreUrl} target={"_blank"}>
        {learnMoreText}
      </Link>
      <ServicesTableTitle />
      <InsightsDataTable columns={servicesColumnsWithButton} rows={rows} />
    </div>
  );
};
