import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { ContentCard } from "../ContentCard/ContentCard";
import { ResourcesInventory } from "../ResourcesInventory/ResourcesInventory";
import { RecentDeploys } from "../RecentDeploys/RecentDeploys";
import { useDeployEventsPath } from "../../../../hooks/overviewPageHooks";
import { gap } from "../../shared/styles";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const CardsWrapper = styled.div`
  display: flex;
  gap: ${gap};
  justify-content: center;
  flex-wrap: wrap;
  margin: 2.5rem 0;
  width: 100%;
  & > div {
    flex: 1;
  }
`;

export const CardsContainer: React.FC = () => {
  const deployEventsPath = useDeployEventsPath();
  return (
    <Container>
      <CardsWrapper>
        <ContentCard
          title={"Resources inventory"}
          content={<ResourcesInventory />}
        />
        <ContentCard
          title={"Recent Deploys"}
          headerActionButton={
            <Button href={deployEventsPath} target="_blank">
              View all deploys
            </Button>
          }
          content={<RecentDeploys />}
          needsScrolling={true}
        />
      </CardsWrapper>
    </Container>
  );
};
