import React from "react";
import { muiColors, muiTheme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import CircularProgress from "@mui/material/CircularProgress";

import { TEXT_FIELD_WIDTH } from "./styles";

const Preview = styled.div`
  background-color: ${muiColors.common.white};
  border: 1px solid ${muiColors.gray[300]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 220px;
  width: ${TEXT_FIELD_WIDTH};
`;

const EmptyPreview = styled(Preview)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div``;

type ClustersPreviewListProps = {
  clusters: string[];
  isLoading: boolean;
};

export function ClustersPreviewList({
  clusters,
  isLoading,
}: ClustersPreviewListProps) {
  return (
    <Root>
      <Typography variant="body2" sx={{ marginBottom: "3px" }} display="inline">
        Clusters included preview
        {!isLoading && clusters.length > 0 ? (
          <Typography
            variant="body3"
            color={muiTheme.palette.text.secondary}
            display="inline"
          >
            {" "}
            ({clusters.length} Clusters)
          </Typography>
        ) : isLoading ? (
          <CircularProgress
            size={10}
            sx={{ marginLeft: "6px", color: muiColors.gray[400] }}
          />
        ) : null}
      </Typography>
      {clusters.length === 0 || isLoading ? (
        <EmptyPreview>
          {isLoading ? (
            <LinesLoader />
          ) : (
            <Typography variant="body3" color={muiTheme.palette.text.secondary}>
              No Clusters Included
            </Typography>
          )}
        </EmptyPreview>
      ) : (
        <Preview>
          {clusters.map((c) => (
            <Box
              borderBottom={`1px solid ${muiColors.gray[200]}`}
              padding="4.5px 8px"
            >
              <Typography key={c} variant="body2">
                {c}
              </Typography>
            </Box>
          ))}
        </Preview>
      )}
    </Root>
  );
}
