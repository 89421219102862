import React, { useState } from "react";
import styled from "styled-components";

import { isExternalLink, Link } from "../links/useKomodorAnnotations";
import { blackGreyText, gray10, gray14 } from "../../../../../../Colors";
import TrashIcon from "../../../../../common/SVGs/trash.svg?react";
import EditIcon from "../../../../../common/SVGs/edit.svg?react";
import { External_Link_Context_Enum } from "../../../../../../generated/graphql";
import { dispatchEvent } from "../../../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";

import EditLinkModal from "./EditLinkModal";
import DeleteLinkModal from "./DeleteLinkModal";

const StyledEditIcon = styled(EditIcon)`
  height: 1rem;
`;

const StyledDeleteIcon = styled(TrashIcon)`
  height: 1rem;
`;

const LinkEditButtons = styled.div`
  visibility: hidden;
  display: flex;
  gap: 0.65rem;
  padding: 0.2rem;
  cursor: pointer;
  align-items: center;
`;

const Button = styled.div`
  color: ${gray10};
  :hover {
    color: ${blackGreyText};
  }
`;

const Container = styled.div<{ hoverAvailable: boolean }>`
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  ${({ hoverAvailable }) =>
    hoverAvailable
      ? `:hover {
    background-color: ${gray14};
    border-radius: 0.25rem;
  }
  &:hover > ${LinkEditButtons} {
    visibility: visible;
  }`
      : ""}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isEditableLink = (
  link: Link,
  hasPermissions: boolean
): boolean => {
  return hasPermissions && isExternalLink(link);
};

const EditableExternalLink: React.FC<{
  link: Link;
  isEditable: boolean;
  context: External_Link_Context_Enum;
  children?: React.ReactNode;
}> = ({ link, isEditable, context, children }) => {
  const [showModalEditLink, setShowModalEditLink] = useState(false);
  const [showModalDeleteLink, setShowModalDeleteLink] = useState(false);
  const linkInformation = {
    displayName: link.text || "",
    link: link.url,
    k8sClusterName: link.externalLinkProps?.k8sClusterName,
    namespace: link.externalLinkProps?.namespace,
    serviceId: link.externalLinkProps?.serviceId || "",
  };

  return (
    <Container hoverAvailable={isEditable} data-e2e-selector="link-container">
      {children}
      {isEditable && (
        <LinkEditButtons>
          <Button
            onClick={() => {
              dispatchEvent(AnalyticEvents.Links.Edit_External_Link_Clicked, {
                linkContext: context,
              });
              setShowModalEditLink(true);
            }}
            data-e2e-selector="externalLink-edit-button"
          >
            <StyledEditIcon />
          </Button>
          <Button
            onClick={() => {
              dispatchEvent(AnalyticEvents.Links.Delete_External_Link_Clicked, {
                linkContext: context,
              });
              setShowModalDeleteLink(true);
            }}
            data-e2e-selector="externalLink-delete-button"
          >
            <StyledDeleteIcon />
          </Button>
          <EditLinkModal
            show={showModalEditLink}
            close={() => {
              setShowModalEditLink(false);
            }}
            linkId={link.externalLinkProps?.linkId || ""}
            linkInformation={linkInformation}
          />
          <DeleteLinkModal
            show={showModalDeleteLink}
            close={() => {
              setShowModalDeleteLink(false);
            }}
            linkId={link.externalLinkProps?.linkId || ""}
            linkInformation={linkInformation}
          />
        </LinkEditButtons>
      )}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EditableExternalLink;
