import styled from "styled-components";

import { colorPalette } from "./containerRestartsContants";

export const GraphDataKeyIndicator = styled.div<{ index: number }>`
  width: 8px;
  height: 8px;
  background-color: ${({ index }) => colorPalette[index % colorPalette.length]};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;
