import React, { useCallback, useState } from "react";

import ScaleModal from "../modals/ScaleModal/ScaleModal";
import Deployment from "../../ResourceView/resources/deployment";
import StatefulSet from "../../ResourceView/resources/statefulset";

import { ActionButtonProps } from "./types";

import Action from ".";

export const ScaleButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = useCallback(() => {
    setOpen(false);
    setIsActionActive?.(false);
  }, [setIsActionActive]);

  return (
    <>
      {(resource instanceof Deployment || resource instanceof StatefulSet) && (
        <Action
          agentId={resource.agentId}
          cluster={resource.cluster}
          onClick={handleClick}
          Button={Button}
        >
          {open && (
            <ScaleModal
              isModalOpen={open}
              handleClose={handleClose}
              resourceId={resource.id}
              resourceName={resource.name}
              resourceType={resource.kind}
              namespace={resource.namespace}
              cluster={resource.cluster}
              agentId={resource.agentId}
              desiredReplicas={resource.replicas?.desired ?? 0}
            />
          )}
        </Action>
      )}
    </>
  );
};
