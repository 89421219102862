import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertTriangleOutlined16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

import { Label } from "../../ResourceView/resources";
import { FlexColumns, FlexRows } from "../FlexContainers";

const WarnInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  border: 1px solid ${palette.pink[500]};
  border-radius: 4px;
  padding: 0.4rem;
  margin: 0 0.5rem;
  align-items: center;
`;

const IconBackground = styled.div`
  background: ${palette.pink[50]};
  border-radius: 2px;
  padding: 0.5rem;
  align-self: stretch;
`;

const TitleContainer = styled.span`
  width: 6rem;
  display: inline-block;
`;
const WarningArea: React.FC<{ labels: Label[] }> = ({ labels }) => {
  return (
    <>
      <WarnInfo>
        <IconBackground>
          <AlertTriangleOutlined16
            color={palette.pink[500]}
            height="max-content"
          />
        </IconBackground>
        <FlexColumns>
          {labels &&
            labels.map(
              (label) =>
                label.value && (
                  <FlexRows>
                    <Typography key={label.name} variant="title" size="small">
                      <TitleContainer>{label.name}:</TitleContainer>{" "}
                    </Typography>
                    <Typography key={label.name} variant="text" size="small">
                      {label.value}
                    </Typography>
                  </FlexRows>
                )
            )}
        </FlexColumns>
      </WarnInfo>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WarningArea;
