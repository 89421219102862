import { useMemo } from "react";

import { useMetricsAPIGet } from "../../shared/hooks/metrics-api/client";
import { FullContainerMetricsDataPoints } from "../../generated/metricsApi";

import {
  AggregatedMetricsResponse,
  FullMetricsData,
  MetricsRequestParams,
} from "./types";
import { toDataPoint } from "./useMetrics";

type FullMetricsPerTypeRequestParams = MetricsRequestParams;

export type FullMetricsPerTypeResponse = {
  loading: boolean;
  error: string | null;
  metrics?: FullMetricsData;
};

export const useFormatFullMetricsResponse = (
  data: FullContainerMetricsDataPoints | undefined
): FullMetricsData | undefined =>
  useMemo(() => {
    if (!data) return;

    const metrics: FullMetricsData = {
      limit: [],
      request: [],
      usage: [],
      p90: [],
      p95: [],
      p99: [],
      max: [],
    };

    metrics.limit = data.limit?.map(toDataPoint);
    metrics.request = data.request?.map(toDataPoint);
    metrics.usage = data.avgUtilization?.map(toDataPoint);
    metrics.p90 = data.p90Utilization?.map(toDataPoint);
    metrics.p95 = data.p95Utilization?.map(toDataPoint);
    metrics.p99 = data.p99Utilization?.map(toDataPoint);
    metrics.max = data.maxUtilization?.map(toDataPoint);
    return metrics;
  }, [data]);

export const useFullMetricsPerType = ({
  endpoint,
  endTimestamp,
  clusterName,
  namespace,
  podName,
  containerName,
  pause,
  ...rest
}: FullMetricsPerTypeRequestParams): FullMetricsPerTypeResponse => {
  const { loading, data, error } =
    useMetricsAPIGet<FullContainerMetricsDataPoints>(
      endpoint,
      {
        clusterName,
        namespace,
        podName,
        containerName,
        endTime: endTimestamp,
        ...rest,
      },
      pause
    );

  const metrics = useFormatFullMetricsResponse(data);

  return {
    loading,
    error,
    metrics,
  };
};

export const useFullContainerMetrics = (
  params: FullMetricsPerTypeRequestParams
): AggregatedMetricsResponse => {
  const { pause = false } = params;
  const { loading, metrics, error } = useFullMetricsPerType(params);

  return useMemo(() => {
    return {
      paused: pause,
      loading,
      error,
      metrics: {
        limit: metrics?.limit ?? [],
        request: metrics?.request ?? [],
        usage: metrics?.usage ?? [],
        p90: metrics?.p90 ?? [],
        p95: metrics?.p95 ?? [],
        p99: metrics?.p99 ?? [],
        max: metrics?.max ?? [],
      },
    };
  }, [loading, metrics, error, pause]);
};
