import { useDrawerStackIndex } from "@/shared/context/drawersStack/useDrawerStackIndex";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import {
  drawerAtSelector,
  setDrawerAtSelector,
} from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useIsOldDrawerStateMechanism } from "@/shared/context/drawersStack/useIsOldDrawerStateMechanism";
import { useResourceView } from "@/components/ResourceView/ResourceProvider";
import { RESOURCE_PREFIX } from "@/shared/config/urlSearchParamsKeys";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export function useDrawerUrlState<T>(
  paramKey: string
): [T | null, (newValue: T | null, replace?: boolean) => void] {
  const drawerIndex = useDrawerStackIndex();
  const drawerState = useDrawersStackStore(drawerAtSelector(drawerIndex));
  const setDrawerState = useDrawersStackStore(setDrawerAtSelector(drawerIndex));
  const isOldDrawerStateMechanism = useIsOldDrawerStateMechanism();
  const resource = useResourceView();
  const uniqueParamKey = isOldDrawerStateMechanism
    ? `${RESOURCE_PREFIX}${resource.kind}-${paramKey}`
    : paramKey;
  const [searchParamsState, setSearchParamsState] =
    useStringifiedStateInSearchParams<T>(uniqueParamKey);

  if (drawerIndex !== undefined && drawerState) {
    return [
      (drawerState.urlStates?.[paramKey] ?? null) as T | null,
      (newValue: T | null, replace?: boolean) =>
        setDrawerState(
          {
            [paramKey]: newValue,
          },
          replace
        ),
    ];
  }
  return [searchParamsState, setSearchParamsState];
}
