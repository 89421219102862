import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import clustersPage from "../assets/Screen - Clusters List.png";
import { clustersDemoRoutes } from "../constants";
import { ClickableArea, RelativeContainer } from "../styles";

import { TopBar } from "./TopBar";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  width: 96.4%;
  height: 8.1%;
  top: ${({ top }) => top};
  left: 1.7%;
`;

export const ClustersPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <TopBar page="clusters" />
      <img src={clustersPage} alt={"clusters page"} />
      <SelectableRow
        top={"54.1%"}
        onClick={() => navigate(clustersDemoRoutes.clusterOverview)}
      />
    </RelativeContainer>
  );
};
