import { ServiceInfo } from "../../../shared/types/ServiceInfo";

type Service = Pick<
  ServiceInfo,
  "id" | "title" | "k8sCluster" | "env" | "healthy"
>;
type PartialService = Partial<Service>;

interface YamlFile {
  name: string;
  content: string;
}

interface ServiceYamlFile extends YamlFile {
  serviceHealthy: boolean;
}

interface HasPrevPath {
  prevPath: string;
}

const hasPrevPath = (object: unknown): object is HasPrevPath =>
  (object as HasPrevPath) !== undefined;

export type { Service, PartialService, YamlFile, ServiceYamlFile, HasPrevPath };

export { hasPrevPath };
