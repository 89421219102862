import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useMemo } from "react";

import { useServicesComparisonContext } from "./useServicesComparisonContext";
import { ServicesComparisonTabs } from "./constants";

const COMPARISON_TABS = [
  {
    label: "Attributes Comparison",
    value: ServicesComparisonTabs.Attributes,
    ariaLabel: " ",
  },
  {
    label: "Metrics Comparison",
    value: ServicesComparisonTabs.Metrics,
    ariaLabel: " ",
  },
];

const useTabsList = () =>
  useMemo(
    () =>
      COMPARISON_TABS.map(({ label, value, ariaLabel }) => (
        <Tab aria-label={ariaLabel} key={value} label={label} value={value} />
      )),
    []
  );
export const ComparisonTabs: React.FC = () => {
  const { selectedTab, setSelectedTab } = useServicesComparisonContext();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ServicesComparisonTabs) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  const TabsList = useTabsList();

  return (
    <Tabs value={selectedTab} onChange={handleChange}>
      {TabsList}
    </Tabs>
  );
};
