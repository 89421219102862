import { SVC } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";
import { KubernetesResource } from "./KubernetesResource";

export const KubernetesServiceResource: KubernetesResource = {
  NameInK8S: "services",
  Group: "network",
  NavBarName: "K8s Services",
  PresentName: "Kubernetes Services",
  Namespaced: true,
  UnnecessaryColumns: [],
  SupportedActions: [
    AvailableActions.Edit,
    AvailableActions.Delete,
    AvailableActions.PortForward,
  ],
  Kind: "Service",
  Icon: SVC,
  Headers: [
    { name: "Age" },
    { name: "Name" },
    { name: "Namespace" },
    { name: "Type" },
    { name: "Ports" },
    { name: "Selector" },
    { name: "Cluster IP" },
    { name: "External IP" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
