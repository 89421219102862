import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { MetadataItemContent } from "../../../violationDrawerTypes";

const SummaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetadataItem: React.FC<MetadataItemContent> = ({
  label,
  value,
}) => {
  return (
    <SummaryItemContainer>
      <Typography
        variant={"overline2"}
        color={"text.secondary"}
        sx={{ textTransform: "uppercase" }}
      >
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography variant={"body2"} color={"text.primary"}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </SummaryItemContainer>
  );
};
