import { Static, Record, String, Boolean, Array } from "runtypes";

import { pinkForUI2, yellowForUI, greenForUI, gray13 } from "../../Colors";

export type CheckCategory = "Security" | "Reliability" | "Efficiency";

export const CheckRawResult = Record({
  ID: String,
  Message: String,
  Success: Boolean,
  Severity: String,
  Category: String,
});
export type CheckRawResult = Static<typeof CheckRawResult>;

export type SpecLevel = "spec" | "pod" | "container";
export type CheckRawReultWithSpecLevel = CheckRawResult & {
  SpecName: string;
  SpecLevel: SpecLevel;
};

export const IgnoreCheckProps = Record({
  id: String,
  specLevel: Record({
    type: String,
    name: String,
  }),
  ignore: Boolean,
  expirationDate: String,
});

export type IgnoreCheckProps = Static<typeof IgnoreCheckProps>;

export const StaticPreventionConfigurationVariables = Record({
  staticPrevention: Record({
    polaris: Record({
      ignoredChecks: Array(IgnoreCheckProps),
    }),
  }),
});
export type StaticPreventionConfigurationVariables = Static<
  typeof StaticPreventionConfigurationVariables
>;

export interface StaticPreventionResult {
  checks: CheckRawReultWithSpecLevel[];
  ignoredChecks: CheckRawReultWithSpecLevel[];
  failedToParse: boolean;
}

export enum StaticPreventionSeverity {
  danger = "danger",
  warning = "warning",
  passed = "passed",
  ignored = "ignored",
  critical = "critical",
}

export const SeverityColorMap: { [_: string]: string } = {
  [StaticPreventionSeverity.danger.toString()]: pinkForUI2,
  [StaticPreventionSeverity.warning.toString()]: yellowForUI,
  [StaticPreventionSeverity.passed.toString()]: greenForUI,
  [StaticPreventionSeverity.ignored.toString()]: gray13,
};

export interface StaticPreventionBasicConfiguration {
  id: string;
  variables: StaticPreventionConfigurationVariables;
}

export const getWorkflowConfigurationNameByServiceName = (
  displayName: string
): string => `static-prevention-${displayName ?? ""}`;
