import { useCallback, useEffect, useState } from "react";

import { useNamespacesFilter } from "@/components/Inspection/InspectionViews/inpectionViewsHooks";

export const useSetInitialNamespace = (
  namespaces: string[],
  isAwaitingNamespacesChange: boolean
) => {
  const [selectedNamespaces, setSelectedNamespaces] = useNamespacesFilter();
  const [initialNamespace, setInitialNamespace] = useState<string | undefined>(
    undefined
  );

  const resetInitialNamespace = useCallback(() => {
    setInitialNamespace(undefined);
    setSelectedNamespaces([]);
  }, [setSelectedNamespaces]);

  useEffect(() => {
    if (isAwaitingNamespacesChange) {
      return;
    }
    if (!namespaces.length) {
      setInitialNamespace(undefined);
      return;
    }

    if (initialNamespace) {
      return;
    }
    // select namespace from session user selection
    if (selectedNamespaces.length) {
      setSelectedNamespaces([selectedNamespaces[0]]);
      setInitialNamespace(selectedNamespaces[0]);
      return;
    }
    // select namespace from list
    setSelectedNamespaces([namespaces[0]]);
    setInitialNamespace(namespaces[0]);
  }, [
    initialNamespace,
    isAwaitingNamespacesChange,
    namespaces,
    resetInitialNamespace,
    selectedNamespaces,
    setSelectedNamespaces,
  ]);

  return {
    initialNamespace,
    resetInitialNamespace,
  };
};
