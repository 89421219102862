import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { Status } from "./Status";
import { useGetCertificateData } from "./useGetCertificateData";

import { CertificateExpirationData } from "@/generated/reliabilityApi";

export const useCertificatesRows = (
  certificateExpirationData: CertificateExpirationData[] | undefined
) => {
  useGetCertificateData(
    certificateExpirationData?.[0].certificateUid ?? "",
    certificateExpirationData?.[0].certificateName ?? ""
  );

  return certificateExpirationData ?? [];
};

export const useCertificatesColumns = () => {
  return [
    { field: "certificateName", headerName: "Certificate", flex: 1 },
    {
      field: "daysToExpiration",
      headerName: "Expiring in",
      flex: 1,
      renderCell: (params: GridRenderCellParams<CertificateExpirationData>) => {
        const daysToExpiration = params.value;
        return (
          <Typography
            variant="body2"
            color={daysToExpiration < 0 ? "error.dark" : "info"}
          >
            {daysToExpiration}d
          </Typography>
        );
      },
    },
    {
      field: "certificateUid",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<CertificateExpirationData>) => {
        const { certificateUid, certificateName } = params.row;
        return <Status uid={certificateUid} name={certificateName} />;
      },
    },
  ];
};
