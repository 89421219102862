import React, { useMemo } from "react";

import { crdsDataContext } from "./useCRDsData";

export const CRDsDataProvider: React.FC<{
  agentId: string;
  cluster: string;
  children: React.ReactNode;
}> = ({ children, ...rest }) => {
  const value = useMemo(
    () => ({
      ...rest,
    }),
    [rest]
  );

  return (
    <crdsDataContext.Provider value={value}>
      {children}
    </crdsDataContext.Provider>
  );
};
