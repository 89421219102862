import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RowContainer = styled.div<{
  center?: boolean;
  fixedWidth?: boolean;
}>`
  align-self: ${({ center }) => (center ? "center" : "flex-start")};
  ${({ fixedWidth }) => fixedWidth && `width: 15.625rem`};
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  max-width: 15.625rem;
  white-space: nowrap;
  overflow: hidden;
`;
const Metadata: React.FC<{
  title: string;
  value: string;
  subValue?: string;
  boldValue?: boolean;
  center?: boolean;
  tooltipMessage?: string;
  fixedWidth?: boolean;
  showValueTooltip?: boolean;
  onValueClick?: () => void;
}> = ({
  title,
  value,
  boldValue = false,
  center = false,
  subValue = "",
  tooltipMessage = "",
  fixedWidth = false,
  showValueTooltip = false,
  onValueClick,
}) => {
  return (
    <Container>
      <RowContainer>
        <Typography
          variant="overline2"
          color={muiColors.gray[600]}
          fontSize="11px"
          style={{ textTransform: "uppercase" }}
        >
          {title}
        </Typography>
        {tooltipMessage && (
          <Tooltip arrow={false} title={tooltipMessage}>
            <InfoOutlined color="disabled" fontSize="inherit" />
          </Tooltip>
        )}
      </RowContainer>
      <RowContainer center={center} fixedWidth={fixedWidth}>
        <Tooltip title={showValueTooltip ? value : undefined} arrow={false}>
          <Typography
            variant={boldValue ? "h4" : "body1"}
            sx={{
              cursor: onValueClick ? "pointer" : "default",
              textDecoration: onValueClick ? "underline" : "none",
            }}
            fontSize="16px"
            textOverflow="ellipsis"
            overflow="hidden"
            onClick={onValueClick}
          >
            {value}
          </Typography>
        </Tooltip>

        {subValue && (
          <Typography
            variant="h6"
            fontSize="12px"
            color={muiColors.gray[500]}
            style={{ alignSelf: "flex-end" }}
          >
            {subValue}
          </Typography>
        )}
      </RowContainer>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Metadata;
