import { OptionType } from "@komodorio/design-system/deprecated";

export const toSelectableOption = (string: string): OptionType<string> => ({
  label: string,
  value: string,
});
export const labelStringToKeyValue = (labelString: string) => {
  const json = JSON.parse(labelString);
  return {
    key: Object.keys(json)[0],
    value: json[Object.keys(json)[0]],
  };
};
