import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  apiV1KomodorServicesFetchJobsFiltersPostUrl,
  KomodorServiceFetchJobsFiltersResponse,
  KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const KOMODOR_SERVICES_JOBS_FILTER_FETCH =
  "/komodor-services/fetch-jobs-filters";

const fetchJobsFilters = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest
): Promise<KomodorServiceFetchJobsFiltersResponse> => {
  const { data } = await apiClient.post(
    apiV1KomodorServicesFetchJobsFiltersPostUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    ),
    params.komodorServiceFetchJobsFiltersParams
  );
  return data;
};

export const usePostKomodorServiceFetchJobsFilters = () => {
  const apiClient = useResourcesApiClient();
  return useMutation(
    [KOMODOR_SERVICES_JOBS_FILTER_FETCH],
    (
      params: KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest
    ) => fetchJobsFilters(apiClient, params)
  );
};
