import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { palette } from "@komodorio/design-system";

import { ClusterForRbacSync } from "../utils/rbacClusterStatusSync";

import { LTRBox } from "./containers";

export type ClusterListItemProps = {
  cluster: ClusterForRbacSync;
  onClick: (cluster: string) => void;

  disabled?: boolean;
  errorMessage?: string;
};

export const AvailableClusterItem: React.FC<ClusterListItemProps> = ({
  cluster,
  onClick,
  disabled,
  errorMessage,
}) => {
  return (
    <LTRBox>
      {errorMessage && (
        <Tooltip title={errorMessage} placement={"top"}>
          <ErrorOutline sx={{ marginRight: "6px", color: palette.gray[400] }} />
        </Tooltip>
      )}
      <ListItemButton
        disableGutters
        onClick={() => onClick(cluster.name)}
        disabled={disabled}
      >
        <ListItemText>
          <Typography
            variant={"body2"}
            sx={{ color: disabled ? palette.gray[400] : "inherit" }}
          >
            {cluster.name}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </LTRBox>
  );
};

export const SelectedClusterItem: React.FC<ClusterListItemProps> = ({
  cluster,
  onClick,
  disabled,
}) => {
  return (
    <ListItem
      disableGutters
      component={"div"}
      onClick={() => onClick(cluster.name)}
      disabled={disabled}
    >
      <Chip
        size={"medium"}
        label={cluster.name}
        onDelete={() => onClick(cluster.name)}
        disabled={disabled}
      />
    </ListItem>
  );
};
