import React from "react";
import styled from "styled-components";
import { LightningBolt16 } from "@komodorio/design-system/icons";

import WorkflowIssueEventGroup from "../../../../../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { useOpenIssueInEventItemDrawer } from "../../issueItemHooks";

import { NarrowButton } from "./actionButtonStyles";

const Container = styled.div`
  display: flex;
  gap: 2px;
  white-space: nowrap;
  align-items: center;
`;

type AvailabilityActionButtonProps = {
  workflowIssue: WorkflowIssueEventGroup;
};

export const AvailabilityActionButton: React.FC<
  AvailabilityActionButtonProps
> = ({ workflowIssue }) => {
  const openIssueInEventItemDrawer = useOpenIssueInEventItemDrawer();

  const onClick = () => {
    openIssueInEventItemDrawer(workflowIssue);
  };

  return (
    <NarrowButton variant={"text"} onClick={onClick}>
      <Container>
        <LightningBolt16 />
        More info
      </Container>
    </NarrowButton>
  );
};
