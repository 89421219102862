import * as React from "react";

import type { IconProps } from "../icons/types";

export const KomodorLogo = (props: IconProps) => (
  <svg
    width={153}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.694 12.52h-3.7l-5.588 5.404V6.857h-2.792v18.274h2.792v-4.283l2.212-1.958 4.415 6.24h3.435l-5.854-8.04 5.08-4.57ZM5.337 6.878l-2.29 2.366v13.513l2.29 2.355h21.09l2.29-2.355V9.233l-2.29-2.356H5.338Zm.6 3.476.895-.9H24.96l.861.901V21.65l-.861.902H6.83l-.894-.9v-11.3Zm15.46 4.234c0 1.719-.964 3.112-2.153 3.112-1.19 0-2.154-1.393-2.154-3.112 0-1.718.964-3.111 2.154-3.111s2.154 1.393 2.154 3.111ZM12.844 17.7c1.19 0 2.153-1.394 2.153-3.112 0-1.718-.964-3.111-2.154-3.111s-2.154 1.393-2.154 3.111c0 1.718.965 3.112 2.154 3.112Zm65.487-3.767 1.407-1.412h4.977l2.234 2.245v10.366h-2.792v-9.554l-.773-.759h-3.7l-1.007.993v9.32h-2.797v-9.554l-.773-.759h-3.219l-1.488 1.487v8.826h-2.797V12.52h2.635v1.724l1.677-1.725h5.03l1.386 1.412Zm-25.272.833 2.234-2.245h7.639l2.234 2.245v8.12l-2.234 2.246h-7.639l-2.234-2.245v-8.12Zm8.542 8.068.746-.759h.005v-6.497l-.746-.759h-4.977l-.747.759v6.497l.742.759H61.6Zm67.704-10.313-2.235 2.245v8.12l2.235 2.246h7.638l2.235-2.245v-8.12l-2.235-2.246h-7.638Zm7.054 9.554-.747.759h-4.976l-.747-.759v-6.497l.747-.759h4.976l.747.759v6.497Zm7.89-7.675 1.862-1.879h3.223v2.612h-3.435l-1.488 1.464v8.535h-2.796V12.52h2.634v1.878ZM91.616 12.52l-2.235 2.245v8.12l2.235 2.246h16.509l2.234-2.245v-8.12l-2.234-2.246h-16.51Zm15.92 9.554-.747.759H92.942l-.746-.759v-6.497l.746-.759h13.847l.747.759v6.497Zm12.79-9.554 1.542 1.566V6.857h2.792v18.274h-2.792v-1.54l-1.542 1.54h-5.323l-2.234-2.245v-8.12l2.234-2.245h5.323Zm.054 10.313 1.488-1.46v-5.068l-1.488-1.486h-4.046l-.773.758v6.497l.773.759h4.046Z"
      fill="#fff"
    />
  </svg>
);
