import { useMutation } from "@tanstack/react-query";

import {
  GetUpdateViolationResponse,
  updateViolationUrl,
  ViolationsApiUpdateViolationRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";
import { useViolationsRequestParams } from "../../../../components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/useViolationsRequestParams";

export const UPDATE_VIOLATION_PATH = "/api/v1/violations/:id";

export const useUpdateViolation = () => {
  const violationsRequestParams = useViolationsRequestParams();

  const apiClient = useReliabilityApiClient();
  return useMutation(
    [UPDATE_VIOLATION_PATH, violationsRequestParams],
    async (
      params: ViolationsApiUpdateViolationRequest
    ): Promise<GetUpdateViolationResponse> => {
      return await apiClient.put(
        updateViolationUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.body }
      );
    }
  );
};
