import { sortBy } from "lodash";
import React, { useMemo } from "react";

import { groupEventsByLine } from "@/components/common/EventsChart/TimelineChart/groupEventsByLine";
import { TimelineChart } from "@/components/common/EventsChart/TimelineChart/TimelineChart";
import { EventsChartParams } from "@/components/common/EventsChart/types";
import { useKomodorServices } from "@/shared/hooks/useKomodorServices";

export const EventsTimelineChart: React.FC<EventsChartParams> = ({
  eventGroups,
  ...props
}) => {
  const services = useKomodorServices().all;

  const eventsByLine = useMemo(() => {
    return groupEventsByLine(
      sortBy(eventGroups, (e) => e.startTime.getTime()),
      services
    );
  }, [eventGroups, services]);

  return <TimelineChart swimlanes={eventsByLine} {...props} />;
};
