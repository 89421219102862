import { useEffect, useState } from "react";

import { useLogsViewerStore } from "../store/logsViewerStore";
import {
  selectIsAnalyzingLogs,
  selectIsLiveTailEnabledBySystem,
  setLiveTailEnabledBySystem,
} from "../store/logsViewerStoreSelectors";

interface UseAutoFreezeLiveLogsScreen {
  isNewestLogLineDisplayed: boolean;
  logsLength: number;
}

export const useAutoFreezeLiveLogsScreen = ({
  isNewestLogLineDisplayed,
  logsLength,
}: UseAutoFreezeLiveLogsScreen) => {
  const [isFreezingTheScreen, setIsFreezingTheScreen] = useState(false);
  const setLiveTailEnabledBySystemCB = useLogsViewerStore(
    setLiveTailEnabledBySystem
  );
  const isAnalyzingLogs = useLogsViewerStore(selectIsAnalyzingLogs);
  const isLiveTailEnabledBySystem = useLogsViewerStore(
    selectIsLiveTailEnabledBySystem
  );

  // handle freezing the screen on live logs
  useEffect(() => {
    if (!isNewestLogLineDisplayed && logsLength) {
      if (isFreezingTheScreen) {
        return;
      }

      // if the user scrolled toward the oldest, we should freeze the screen
      setLiveTailEnabledBySystemCB(false);
      setIsFreezingTheScreen(true);
      return;
    }

    // if the user scrolled toward the newest, we should unfreeze the screen
    if (!isAnalyzingLogs && !isLiveTailEnabledBySystem && isFreezingTheScreen) {
      setLiveTailEnabledBySystemCB(true);
      setIsFreezingTheScreen(false);
    }

    // reset state - if the user interrupt the auto-freeze
    if (isFreezingTheScreen && !isAnalyzingLogs && isLiveTailEnabledBySystem) {
      setIsFreezingTheScreen(false);
    }
  }, [
    isNewestLogLineDisplayed,
    isAnalyzingLogs,
    setLiveTailEnabledBySystemCB,
    isLiveTailEnabledBySystem,
    isFreezingTheScreen,
    logsLength,
  ]);
};
