import { BaseNamesExtractor } from "../utils/ExtractNamesFromService/BaseNamesExtractor";

import { useLastEventDeploySpec } from "./useLastEventDeploySpec";

export const useNamesExtractionFromService = (
  serviceId: string | undefined,
  relatedResourceClass: BaseNamesExtractor
): [names: string[], refresh: () => void, isLoading: boolean] => {
  const { newSpec, refresh } = useLastEventDeploySpec({
    serviceId,
  });

  const resourceNames = relatedResourceClass.extractNamesFromService(newSpec);

  return [resourceNames, refresh, !newSpec];
};
