import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportCustomResourceInspection } from "../../../../shared/utils/agent/agent";

export const useIsAgentSupportedCRDsFromAtm = (
  clusterName: string
): { isAgentSupported: boolean; fetching: boolean } => {
  const agentId = useActiveAgent(clusterName) ?? "";
  const { agentProperties: agentInfo, fetching } = useAgentInfoById(agentId);

  return {
    isAgentSupported:
      doesAgentVersionSupportCustomResourceInspection(agentInfo),
    fetching,
  };
};
