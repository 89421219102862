import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1AccountsIdGetUrl,
  Account,
  AccountsApiApiV1AccountsIdGetRequest,
} from "../../../../../generated/accounts";
import { useAccountsApiClient } from "../apiClient";
import useUserMetadata from "../../../useUserMetadata/useUserMetadata";

const ACCOUNT_PATH = "/:id";

const getAccount = async (
  apiClient: AxiosInstance,
  params: AccountsApiApiV1AccountsIdGetRequest
): Promise<Account> => {
  const { data } = await apiClient.get(
    apiV1AccountsIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAccount = (enabled?: boolean) => {
  const apiClient = useAccountsApiClient();
  const { accountId } = useUserMetadata();
  const params = { id: accountId };
  return useQuery([ACCOUNT_PATH, params], () => getAccount(apiClient, params), {
    enabled,
  });
};
