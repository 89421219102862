import React from "react";

import { AppBarLink } from "./AppBarLink";

import { PageName, usePages } from "@/components/AppBar/links/pages";
import { AriaLabels } from "@/shared/config/ariaLabels";

const name = PageName.Topology;

const { ExpertOverview: ariaLabel } = AriaLabels.AppBar;

export const ExpertOverview: React.FC = () => {
  const pages = usePages();
  const page = pages[name];

  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
      locked={page.locked}
      ariaLabel={ariaLabel}
    />
  ) : null;
};
