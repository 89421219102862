import React from "react";

import { UpgradeAgentVersionAlert } from "../../../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { useSupportedAgentVersions } from "../../hooks/useSupportedAgentVersions";
import { updateAgentVersionInfoText } from "../../../common/UpgradeAgentVersionAlert/texts";
import { LocalStorageItem } from "../../../../shared/utils/localStorageSettings";
import { usePermanentHideAlert } from "../../../common/UpgradeAgentVersionAlert/usePermanentHideAlert";

import { UpgradeButtonAndModal } from "./UpgradeButtonAndModal";

const hideUpgradeAgentCostLS = new LocalStorageItem("hideUpgradeAgentCost");

export const UpgradeAgentVersionPrompt: React.FC = () => {
  const { unsupportedClusters } = useSupportedAgentVersions();
  const { hideAlertComponent, hideAlertVal } = usePermanentHideAlert(
    hideUpgradeAgentCostLS
  );

  if (!unsupportedClusters.length || hideAlertVal === "true") return null;
  return (
    <UpgradeAgentVersionAlert
      upgradeButton={
        <>
          {hideAlertComponent}
          <UpgradeButtonAndModal unsupportedClusters={unsupportedClusters} />
        </>
      }
      text={updateAgentVersionInfoText}
      severity={"warning"}
      showCloseButton
    />
  );
};
