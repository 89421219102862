import { useMemo } from "react";

import {
  useAgentInfoById,
  AgentProperties,
} from "../../../shared/hooks/useAgentInfo/useAgentInfo";

export const useNeedUpgradeAgent = (
  agentId: string | undefined,
  cluster: string | undefined,
  agentSupportCheck: (agentProps: AgentProperties | null) => boolean
): boolean => {
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  return useMemo(() => {
    return !agentSupportCheck(agentInfo);
  }, [agentInfo, agentSupportCheck]);
};
