import React from "react";
import { capitalize } from "lodash";

import { Audit } from "../../../generated/graphql";
import { AuditData } from "../types";
import { ManualActionCommon } from "../AuditDetailsCommon";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class PodExecAudit implements AuditObject {
  readonly data: Audit;
  readonly auditData: AuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as AuditData;
  }

  renderDetails() {
    return <ManualActionCommon data={this.auditData} />;
  }

  renderAffectedResources() {
    const serviceId = this.auditData?.details?.resource?.serviceId;
    return serviceId || null;
  }

  renderAction() {
    return this.auditData.details?.action;
  }

  renderEventType() {
    return "Exec";
  }

  renderJson(): Record<string, unknown> {
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": "Exec",
      Resource: "PodShell",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": this.auditData?.details?.resource?.serviceId,
      Change: JSON.stringify(this.auditData.details),
    };
  }
}
