import React from "react";
import styled from "styled-components";
import { Typography, Skeleton } from "@komodorio/design-system/deprecated";

import { AriaLabels } from "../../../../../shared/config/ariaLabels";

interface TitleProps {
  displayName: string | undefined;
  status: JSX.Element | undefined;
  className?: string;
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const TitleText = styled(Typography).attrs({
  variant: "headline",
  size: "small",
})``;

export const Title: React.FC<TitleProps> = ({
  displayName,
  status,
  className,
}) => {
  return (
    <Container className={className}>
      {displayName ? (
        <TitleText
          data-e2e-selector="serviceHeader-name"
          aria-label={AriaLabels.ResourceView.Header.Title}
        >
          {displayName}
        </TitleText>
      ) : (
        <Skeleton height="1.5rem" width="8rem" />
      )}
      {status}
    </Container>
  );
};
