import {
  AllocationCostMetricsState,
  AllocationFiltersState,
  AllocationSummaryState,
  CostOptimizationConfigState,
  RightSizingFiltersState,
  RightSizingRecommendationsModalState,
  RightSizingSummaryState,
  RightSizingCpuMetricsState,
  RightSizingMemoryMetricsState,
  SharedCostFiltersState,
  RightSizingRecommendationsStrategiesState,
  RightSizingRecommendationsState,
  CostOptimizationConfigFetchState,
  CostOptimizationConfigUpdateState,
  ClustersEarliestRowTime,
  AllocationCostStatsState,
  RightSizingStatsState,
  RightSizingPaginatedTableState,
} from "../types/costOptimizationTypes";

import { CostOptimizationStore } from "./costOptimizationStore";

// getters
export const selectAllocationFilters = (
  state: CostOptimizationStore
): AllocationFiltersState => state.allocationFilters;
export const selectRightSizingFilters = (
  state: CostOptimizationStore
): RightSizingFiltersState => state.rightSizingFilters;
export const selectAllocationSummaryState = (
  state: CostOptimizationStore
): AllocationSummaryState => state.allocationSummaryState;
export const selectSharedCostFilters = (
  state: CostOptimizationStore
): SharedCostFiltersState => state.sharedCostFilters;
export const selectClustersEarliestRowTime = (
  state: CostOptimizationStore
): ClustersEarliestRowTime => state.clustersEarliestRowTime;
export const selectAllocationMetricsState = (
  state: CostOptimizationStore
): AllocationCostMetricsState => state.allocationMetricsState;
export const selectRightSizingSummaryState = (
  state: CostOptimizationStore
): RightSizingSummaryState => state.rightSizingSummaryState;
export const selectRightSizingCPUMetricsState = (
  state: CostOptimizationStore
): RightSizingCpuMetricsState => state.rightSizingMetricsState.cpu;
export const selectRightSizingMemoryMetricsState = (
  state: CostOptimizationStore
): RightSizingMemoryMetricsState => state.rightSizingMetricsState.memory;
export const selectConfigState = (
  state: CostOptimizationStore
): CostOptimizationConfigState => state.configState;
export const selectRightSizingRecommendationModal = (
  state: CostOptimizationStore
): RightSizingRecommendationsModalState =>
  state.rightSizingRecommendationsModalState;
export const selectRightSizingRecommendationsStrategiesState = (
  state: CostOptimizationStore
): RightSizingRecommendationsStrategiesState =>
  state.rightSizingRecommendationsStrategiesState;
export const selectRightSizingRecommendationsState = (
  state: CostOptimizationStore
): RightSizingRecommendationsState => state.rightSizingRecommendationsState;
export const selectLastVisitTime = (state: CostOptimizationStore): number =>
  state.lastVisitTime;
export const selectServiceResourceDrawer = (state: CostOptimizationStore) =>
  state.serviceResourceDrawerState;
export const selectAllocationStatsState = (
  state: CostOptimizationStore
): AllocationCostStatsState => state.allocationStatsState;
export const selectRightSizingStatsState = (
  state: CostOptimizationStore
): RightSizingStatsState => state.rightSizingStatsState;
export const selectRightSizingPaginatedTableState = (
  state: CostOptimizationStore
): RightSizingPaginatedTableState => state.rightSizingPaginatedTableState;

// setters
export const selectSetSharedCostFilters = (
  state: CostOptimizationStore
): ((filters: Partial<SharedCostFiltersState>) => void) =>
  state.setSharedCostFilters;
export const selectSetClustersEarliestRowTime = (
  state: CostOptimizationStore
): ((clustersEarliestRowTime: ClustersEarliestRowTime) => void) =>
  state.setClustersEarliestRowTime;
export const selectSetAllocationSummaryState = (
  state: CostOptimizationStore
): ((state: AllocationSummaryState) => void) => state.setAllocationSummaryState;
export const selectSetAllocationFilters = (
  state: CostOptimizationStore
): ((filters: Partial<AllocationFiltersState>) => void) =>
  state.setAllocationFilters;
export const selectSetRightSizingFilters = (
  state: CostOptimizationStore
): ((filters: Partial<RightSizingFiltersState>) => void) =>
  state.setRightSizingFilters;
export const selectSetAllocationMetricsState = (
  state: CostOptimizationStore
): ((state: AllocationCostMetricsState) => void) =>
  state.setAllocationMetricsState;
export const selectSetRightSizingSummaryState = (
  state: CostOptimizationStore
): ((state: RightSizingSummaryState) => void) =>
  state.setRightSizingSummaryState;
export const selectSetRightSizingCPUMetricsState = (
  state: CostOptimizationStore
): ((state: RightSizingCpuMetricsState) => void) =>
  state.setRightSizingCPUMetricsState;
export const selectSetRightSizingMemoryMetricsState = (
  state: CostOptimizationStore
): ((state: RightSizingMemoryMetricsState) => void) =>
  state.setRightSizingMemoryMetricsState;
export const selectSetConfigFetchState = (
  state: CostOptimizationStore
): ((state: Partial<CostOptimizationConfigFetchState>) => void) =>
  state.setConfigFetchState;
export const selectSetConfigUpdateState = (
  state: CostOptimizationStore
): ((state: CostOptimizationConfigUpdateState) => void) =>
  state.setConfigUpdateState;
export const selectSetRightSizingRecommendationModal = (
  state: CostOptimizationStore
): ((filters: RightSizingRecommendationsModalState) => void) =>
  state.setRightSizingRecommendationModal;
export const selectSetRightSizingModalStrategies = (
  state: CostOptimizationStore
): ((strategies: RightSizingRecommendationsStrategiesState) => void) =>
  state.setRightSizingRecommendationsStrategiesState;
export const selectSetRightSizingRecommendationsState = (
  state: CostOptimizationStore
): ((state: RightSizingRecommendationsState) => void) =>
  state.setRightSizingRecommendationsState;
export const selectSetLastVisitTime = (
  state: CostOptimizationStore
): ((newDateStamp: number) => void) => state.setLastVisitTime;
export const selectOpenServiceResourceDrawer = (state: CostOptimizationStore) =>
  state.serviceResourceDrawerState.openServiceResourceDrawer;
export const selectSetAllocationStatsState = (
  state: CostOptimizationStore
): ((state: AllocationCostStatsState) => void) => state.setAllocationStatsState;
export const selectSetRightSizingStatsState = (
  state: CostOptimizationStore
): ((state: RightSizingStatsState) => void) => state.setRightSizingStatsState;
export const selectSetRightSizingPaginatedTableState = (
  state: CostOptimizationStore
): ((state: Partial<RightSizingPaginatedTableState>) => void) =>
  state.setRightSizingPaginatedTableState;
