export interface MetricsAgentTaskData {
  end: number;
  resourceType: string;
  queryName: string;
  start?: number;
  resourceName?: string;
  namespace?: string;
}

export const buildPrometheusMetricsTaskForPods = (
  endTimeInUnix: number
): MetricsAgentTaskData[] => {
  const resourceType = "cluster";

  return [
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "podsCpuUsage",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "podsMemoryUsage",
    },
  ];
};

export const buildPrometheusMetricsTaskForNodes = (
  endTimeInUnix: number
): MetricsAgentTaskData[] => {
  const resourceType = "node";

  return [
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "cpuUsage",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "cpuAllocatableCapacity",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "memoryUsage",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "memoryAllocatableCapacity",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "fsUsage",
    },
    {
      end: endTimeInUnix,
      resourceType,
      queryName: "fsSize",
    },
  ];
};
