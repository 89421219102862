import React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes } from "react-router-dom";

import { CertManagerTabs } from "@/components/k8sAddons/addons/CertManager/CertManagerTabs";
import { CertManagerRoutes } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { CertificatesPage } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/CertificatesPage";
import { ControllersPage } from "@/components/k8sAddons/addons/CertManager/ControllersPage/ControllersPage";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";

const horizontalMargin = 24;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${horizontalMargin * 2}px);
  margin: 0 ${horizontalMargin}px 8px ${horizontalMargin}px;
`;

export const CertManager: React.FC = () => {
  return (
    <Container>
      <FiltersContextProvider
        urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
        searchTypeFilterOperator={Operator.Like}
      >
        <CertManagerTabs />
        <Routes>
          <Route
            path={CertManagerRoutes.certificates}
            element={<CertificatesPage />}
          />
          <Route
            path={CertManagerRoutes.controllers}
            element={<ControllersPage />}
          />
          <Route
            path="*"
            element={<Navigate to={CertManagerRoutes.certificates} replace />}
          />
        </Routes>
      </FiltersContextProvider>
    </Container>
  );
};
