import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Box = styled.div<{
  color: string;
  clickable: boolean;
  bordercolor: string;
  selected: boolean;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => props.color};
  ${(props) => props.clickable && "cursor: pointer;"}
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.selected ? props.bordercolor : "transparent")};
  &:hover {
    border: 1px solid ${(props) => props.bordercolor};
  }
`;

const CurrentVersionIndicator = styled.div`
  height: 5px;
  width: 10px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(10px);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
`;

const mapper = {
  current: {
    color: muiColors.gray[50],
    textColor: muiColors.gray[800],
    text: "Current version",
  },
  safe: {
    color: muiColors.teal[50],
    textColor: muiColors.teal[900],
    text: "Safe to upgrade",
  },
  deprecated: {
    color: muiColors.orange[50],
    textColor: muiColors.orange[700],
    text: "Deprecated APIs",
  },
  removed: {
    color: muiColors.pink[25],
    textColor: muiColors.pink[700],
    text: "Removed APIs",
  },
};

type TimelineBoxProps = {
  variant: "current" | "safe" | "deprecated" | "removed";
  version: string;
  apis?: number;
  selectedVersion: string | undefined;
  setSelectedVersion?: (version: string) => void;
};

export const TimelineBox: React.FC<TimelineBoxProps> = ({
  variant,
  version,
  apis,
  selectedVersion,
  setSelectedVersion,
}) => {
  const boxStyles =
    variant === "current"
      ? mapper.current
      : variant === "safe"
      ? mapper.safe
      : variant === "deprecated"
      ? {
          ...mapper.deprecated,
          text: `${mapper.deprecated.text} (${apis})`,
        }
      : { ...mapper.removed, text: `${mapper.removed.text} (${apis})` };

  const isClickable = !!setSelectedVersion;

  return (
    <Container>
      <Box
        color={boxStyles.color ?? ""}
        clickable={isClickable}
        bordercolor={(isClickable ? boxStyles.textColor : "transparent") ?? ""}
        selected={selectedVersion === version}
        onClick={() =>
          setSelectedVersion ? setSelectedVersion(version) : null
        }
      >
        <Typography
          variant={variant === "current" ? "h5" : "body2"}
          color={boxStyles.textColor}
        >
          {version}
        </Typography>
        <Typography
          variant="body3"
          color={boxStyles.textColor}
          textAlign="center"
        >
          {boxStyles.text}
        </Typography>
      </Box>
      {variant === "current" && <CurrentVersionIndicator />}
    </Container>
  );
};
