import React from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { LabelSelectorRequirement } from "kubernetes-types/meta/v1.d";

import { DescribeSectionDivider } from "../common/DescribeSectionDivider";
import { DescribeKeyValueItem } from "../common/DescribeTagListItem";
import { DescribeItem } from "../common/DescribeItem";
import { Table } from "../../../../../shared/components/Table/Table";
import { Dictionary } from "../../../../../shared/types/Dictionary";

const TableContainer = styled.div`
  width: 60%;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const MATCH_EXPRESSIONS_COLUMNS = [
  {
    header: "Key",
    accessor: "key",
  },
  {
    header: "Operator",
    accessor: "operator",
  },
  {
    header: "Values",
    accessor: "value",
  },
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const stringifyExpressionValues = (
  expression: Array<LabelSelectorRequirement>
): Dictionary<string>[] => {
  return expression.map(({ key, values, operator }) => {
    return {
      key,
      operator,
      value: values?.join(", ") ?? "",
    };
  });
};

export const PVCSelectors: React.FC<{
  matchLabels: {
    [key: string]: string;
  };
  matchExpressions: LabelSelectorRequirement[];
}> = ({ matchLabels, matchExpressions }) => {
  if (isEmpty(matchLabels) && isEmpty(matchExpressions)) return null;

  return (
    <>
      <DescribeSectionDivider title={"SELECTORS"} />
      <DescribeKeyValueItem name="Match Labels" value={matchLabels} />
      <DescribeItem name={"Status"}>
        <TableContainer>
          <Table
            data={stringifyExpressionValues(matchExpressions)}
            columns={MATCH_EXPRESSIONS_COLUMNS}
            sortable={false}
            fullWidth={false}
          />
        </TableContainer>
      </DescribeItem>
    </>
  );
};
