import { noop } from "lodash";
import React, { createContext, useContext, useMemo } from "react";

import { LocalStorageItem } from "../utils/localStorageSettings";

import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

interface TimezoneContext {
  timezone: string;
  timeZoneName: string;
  setTimezone: (newTz: string) => void;
}

const defaultValue = {
  timezone: "default",
  timeZoneName: "",
  setTimezone: noop,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const timezoneContext =
  createContext<Required<TimezoneContext>>(defaultValue);

const storedTimezone = new LocalStorageItem("timezone");
export const TimezoneProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [timezone, setTimezone] = useStateInLocalStorage(
    storedTimezone,
    defaultValue.timezone
  );

  const timeZoneName = useMemo(
    () =>
      new Intl.DateTimeFormat("en", {
        timeZone: timezone === "default" ? undefined : timezone,
        timeZoneName: "short",
      })
        .formatToParts(Date.now())
        .find((part) => part.type === "timeZoneName")
        ?.value.replace("GMT", "UTC") ?? "",
    [timezone]
  );

  const value = useMemo(
    () => ({
      timezone,
      timeZoneName,
      setTimezone,
    }),
    [timezone, timeZoneName, setTimezone]
  );

  return (
    <timezoneContext.Provider value={value}>
      {children}
    </timezoneContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useTimezone = (): TimezoneContext => useContext(timezoneContext);
