import { useMemo } from "react";

import { TimeWindow } from "@/shared/types/TimeWindow";
import { groupNodeChangeEvents } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";
import { useNodeTerminationsByOwnerKomodorUidsFromAPIQuery } from "@/components/common/EventGroup/nodeEvent/useNodeChangeEvents";

interface UseFetchNodeTerminationEventsArgs {
  komodorUid: string;
  serviceId: string;
  correlatedNodeEventIds: string[];
  timeWindow: TimeWindow;
}

export const useFetchNodeTerminationEvents = ({
  komodorUid,
  correlatedNodeEventIds,
  timeWindow,
  serviceId,
}: UseFetchNodeTerminationEventsArgs) => {
  const ownerKomodorUids = useMemo(() => {
    const shouldFetchNodeTerminations = correlatedNodeEventIds.length > 0;
    return shouldFetchNodeTerminations ? [komodorUid] : [];
  }, [correlatedNodeEventIds.length, komodorUid]);

  const [nodeTerminations, , isFetching] =
    useNodeTerminationsByOwnerKomodorUidsFromAPIQuery(
      ownerKomodorUids,
      timeWindow
    );
  const nodeTerminationsGroups = useMemo(() => {
    let flatNodeTerminations = nodeTerminations?.flat() ?? [];

    flatNodeTerminations = flatNodeTerminations.filter((terminationEvent) =>
      correlatedNodeEventIds.includes(terminationEvent.id)
    );

    return groupNodeChangeEvents(flatNodeTerminations, serviceId);
  }, [nodeTerminations, serviceId, correlatedNodeEventIds]);

  return {
    nodeTerminationsGroups,
    isFetchingNodeTerminations: isFetching,
  };
};
