import { format } from "date-fns";

import { GenericTableColumnRowParams } from "../types";
import {
  compactOptions,
  formatNumber,
} from "../../../../../../../../../shared/utils/formatNumber";

export const getDateKey = (ms: number): string => format(new Date(ms), "MMM d");

export const getGenericTableColumnRow = (
  params: GenericTableColumnRowParams
) => ({
  id: params.komodorServiceId,
  serviceName: params.komodorServiceName,
  cluster: params.cluster,
  namespace: params.namespace,
});

export const getMiddleIndex = (arr: unknown[]) =>
  Math.ceil((arr.length - 1) / 2);

export const roundValueAsNumber = (val: string | number) =>
  Math.round(Number(val));

export const formatCompactNumber = (num: number): string =>
  formatNumber(Math.round(num), compactOptions);
