import { isEqual } from "lodash";

export type ServerRes = {
  data?: unknown;
  loading: boolean;
  error: string | null;
};

/* run serverRes equality check minus the refresh function */
export const hasServerResChanged = (
  data1: ServerRes,
  data2: ServerRes
): boolean => {
  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
  } = data1;
  const { data: newData, loading: newLoading, error: newError } = data2;

  return !isEqual(
    { data: currentData, loading: currentLoading, error: currentError },
    { data: newData, loading: newLoading, error: newError }
  );
};
