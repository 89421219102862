import { useMemo } from "react";

import { TimeWindow } from "../../../shared/types/TimeWindow";
import { EventsApiApiV1EventsGeneralGetRequest } from "../../../generated/resourcesApi";
import { useResourcesAPIGet } from "../../../shared/hooks/resources-api/client";
import { GENERAL_EVENTS } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { ImpactfulEventsQuery } from "../../../generated/graphql";

import { useGeneralResponseToImpactfulEvents } from "./useGeneralResponseToImpactfulEvents";

export type UseGeneralEventsParams = {
  timeWindow: Pick<TimeWindow, "start" | "end">;
  komodorUid?: string | undefined;
};

export const useGeneralEvents = ({
  timeWindow,
  komodorUid,
}: UseGeneralEventsParams): ImpactfulEventsQuery | undefined => {
  const generalEventsParams: EventsApiApiV1EventsGeneralGetRequest = useMemo(
    () => ({
      fromEpoch: timeWindow.start.getTime().toString(),
      toEpoch: timeWindow.end.getTime().toString(),
      komodorUids: komodorUid ? [komodorUid] : undefined,
    }),
    [komodorUid, timeWindow.end, timeWindow.start]
  );

  const generalEvents = useResourcesAPIGet(GENERAL_EVENTS, generalEventsParams);

  return useGeneralResponseToImpactfulEvents(generalEvents.data);
};
