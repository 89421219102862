import React from "react";
import Typography from "@mui/material/Typography";

import { useGetRbacRoleById } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoleById";

const useAuditRoleAffectedResource = (roleId: string): string => {
  const { data: role } = useGetRbacRoleById({ id: roleId });
  return role?.name ?? "";
};

export const AuditRoleAffectedResource: React.FC<{
  roleId: string;
}> = ({ roleId }) => {
  const ar = useAuditRoleAffectedResource(roleId);
  return <Typography variant="body1">{ar}</Typography>;
};
