import React from "react";
import { parseISO } from "date-fns";
import { SentryIssueEventData } from "komodor-types";

import { Event_Sentry_Issue } from "../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import SentryEventDetails from "../ProcessList/details/SentryEventDetails";
import { pinkBrand, pinkForUI } from "../../../Colors";

import BugScatterShape from "./shapes/bug.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "./groupEvents";

import EventGroup, { EventBase } from ".";

export const sentryType = "Sentry";

type SentryEvent = EventBase &
  Omit<Event_Sentry_Issue, keyof EventBase | "additionalData"> & {
    additionalData?: SentryIssueEventData;
  };

const toSentryEvent = (
  e: Event_Sentry_Issue,
  serviceId: string
): SentryEvent => ({
  ...e,
  id: `${serviceId}:${e.id}`,
  serviceId,
  type: sentryType,
  eventTime: parseISO(e.eventTime),
  additionalData: e.additionalData as SentryIssueEventData,
});

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class SentryEventGroup implements EventGroup {
  readonly backgroundColor = pinkForUI;
  readonly fillColor = pinkBrand;
  readonly icon = BugScatterShape;

  readonly events: SentryEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details = "";
  readonly status;
  readonly serviceId;

  constructor(e: SentryEvent) {
    this.id = e.id;
    this.type = e.type;
    this.summary = e.summary;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.status = e.additionalData?.status ?? "";
    this.serviceId = e.serviceId;
    this.events = [e];
  }

  renderEventDetails(): JSX.Element {
    return <SentryEventDetails eventGroup={this} />;
  }
}

export const groupSentryEvents = (
  events: Event_Sentry_Issue[],
  serviceId: string
): SentryEventGroup[] =>
  mapAndSort(withServices(events, serviceId), toSentryEvent).map(
    (e) => new SentryEventGroup(e)
  );
