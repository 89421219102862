import { omit, pick } from "lodash";
import { Location } from "react-router-dom";
import { Job } from "kubernetes-types/batch/v1.d";

export const extractPathFromLocation = (location: Location): string => {
  return location.pathname.match(/\/main\/([a-zA-Z-_]+)/)?.[1] ?? "";
};

export const isFaultyResultMessage = (result: string): boolean => {
  const errorRegex = /\berror\b(?=[:\s]|$)/i;
  return errorRegex.test(result);
};

export const omitFields = (jobObj: Job): Partial<Job> => {
  let o = pick(jobObj, ["kind", "spec", "metadata", "apiVersion"]);
  o = omit(o, ["spec.selector"]);
  o = omit(o, ["spec.metadata.labels.controller-uid"]);
  o = omit(o, ["spec.template.metadata.labels.controller-uid"]);
  o = omit(o, [
    "spec.template.metadata.labels['batch.kubernetes.io/controller-uid']",
  ]);
  o = omit(o, ["metadata.labels.controller-uid"]);
  o = omit(o, ["metadata.managedFields"]);
  o = omit(o, [
    "metadata.annotations['kubectl.kubernetes.io/last-applied-configuration']",
  ]);
  return o;
};
