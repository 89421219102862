import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { Folder16 } from "@komodorio/design-system/icons";

import { CrdsLink } from "@/components/AppBar/links/CRDs";
import { LinkGroup } from "@/components/AppBar/linkGroups/LinkGroup";
import { NodesLink } from "@/components/AppBar/links/Nodes";
import { useAppBar } from "@/components/AppBar/useAppBar";
import { NamespacesLink } from "@/components/AppBar/links/Namespaces";

export const ResourcesMenu = () => {
  const { nativeResourcesMenuItems, shouldShowResourcesMenu } = useAppBar();

  const nativeResourcesContent = useMemo(
    () =>
      nativeResourcesMenuItems.map(({ header, icon, selector, items }) => (
        <LinkGroup
          key={header}
          title={header}
          icon={icon}
          e2eSelector={selector}
        >
          <Box paddingLeft={"16px"}>
            {items.map(({ Component, name }, index) => (
              <Component key={`${name}-${index}`} />
            ))}
          </Box>
        </LinkGroup>
      )),
    [nativeResourcesMenuItems]
  );

  if (!shouldShowResourcesMenu) {
    return null;
  }

  return (
    <LinkGroup
      title="Native Resources"
      icon={<Folder16 />}
      e2eSelector="appBarInspectionButtons"
      alwaysExpanded
    >
      <NodesLink />
      {nativeResourcesContent}
      <CrdsLink />
      <NamespacesLink />
    </LinkGroup>
  );
};
