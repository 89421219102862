import React, { useState } from "react";

import getK8sDiff from "../../../EventGroup/deployEvent/getK8sDiff";
import Modal from "../../../Modal";
import { ObjectDiffAsYaml } from "../../../ObjectDiff";
import { Action } from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import DiffEntry from "../deployEvent/DiffEntry";
import k8sIcon from "../../../../integrations/management/logos/kubernetes.svg";
import { PREVIEW_LIMIT } from "../deployEvent/K8sSection";

const DiffSection: React.FC<{
  summary: string;
  oldSpec: Record<string, string>;
  newSpec: Record<string, string>;
}> = ({ summary, oldSpec, newSpec }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const k8sDiff = getK8sDiff(oldSpec, newSpec);
  const { changes, hiddenCount } = k8sDiff;

  return (
    <>
      <EventDetailsSection
        title="Changes"
        icon={k8sIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
      >
        {changes.length
          ? changes
              .slice(0, PREVIEW_LIMIT)
              .map((c) => <DiffEntry key={c.name} change={c} />)
          : null}
        <br />
        <Action onClick={() => setModalOpen(true)}>
          View all {changes.length + hiddenCount} changes on diff
        </Action>
      </EventDetailsSection>
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        title={summary}
      >
        <ObjectDiffAsYaml oldObj={oldSpec} newObj={newSpec} />
      </Modal>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DiffSection;
