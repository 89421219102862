import { useMemo, useState } from "react";
import {
  ActionMetadataHelmGetChartHistory,
  ActionTypes,
  TaskType,
} from "komodor-types/src/entities/AgentTask";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";

import { HelmChartSummaryWithAge, HelmChartsResponse } from "./types";
import { convertHelmChartsAgentDataToHelmChartsSummaryWithAge } from "./utils";

type HelmChartRevisionsParams = {
  agentId: string;
  cluster: string;
  namespace: string;
  chartName: string;
};

export const useHelmChartRevisions = ({
  agentId,
  cluster,
  namespace,
  chartName,
}: HelmChartRevisionsParams): HelmChartsResponse<HelmChartSummaryWithAge> => {
  const [errorMessage, setErrorMessage] = useState("");

  const metadata: ActionMetadataHelmGetChartHistory = useMemo(
    () => ({
      cluster: cluster,
      chartName: chartName,
      namespace: namespace,
      type: ActionTypes.HelmGetChartHistory,
    }),
    [cluster, chartName, namespace]
  );

  const {
    execute: getHelmGetChartHistory,
    failureMessage,
    result,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const data = useMemo(() => {
    if (failureMessage) {
      setErrorMessage(failureMessage);
      return;
    }
    if (!result) return;

    return convertHelmChartsAgentDataToHelmChartsSummaryWithAge(
      result,
      cluster,
      setErrorMessage,
      namespace,
      chartName
    );
  }, [cluster, namespace, chartName, failureMessage, result]);

  return {
    execute: getHelmGetChartHistory,
    errorMessage,
    isFetching,
    data,
  };
};
