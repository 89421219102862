import { useMemo } from "react";

import { useFetchContainersSummary } from "../components/shared/metrics/hooks/useFetchContainersSummary";
import { useMetricsParams } from "../components/shared/metrics/hooks/useMetricsParams";

interface Props {
  komodorUid: string | undefined;
  metricsType: "memory" | "cpu";
  containerNames: string[] | undefined;
}

export const useMetricsSummaryByContainers = ({
  komodorUid,
  metricsType,
  containerNames,
}: Props) => {
  const { params, isInvalidParams } = useMetricsParams(komodorUid ?? "");

  const summaryRes = useFetchContainersSummary(
    { ...params, kind: metricsType },
    !komodorUid || isInvalidParams
  );

  const relevantMetrics = useMemo(() => {
    if (!summaryRes.data || !containerNames?.length) return [];
    return summaryRes.data.containers.filter((container) =>
      containerNames.includes(container.containerName)
    );
  }, [containerNames, summaryRes.data]);

  return {
    isLoading: summaryRes.loading,
    metrics: relevantMetrics,
  };
};
