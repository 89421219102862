import React, { FormEventHandler, useCallback, useState } from "react";
import styled from "styled-components";

import { blueBrand, gray4 } from "../../../../Colors";
import { useEscape } from "../../../hooks/useEscape";
import Input from "../../../../components/common/controls/Input";
import Overlay from "../../../../components/common/Overlay";
import { H3, textStyle } from "../../../../components/common/typography";

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 1.5em;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.35rem;
  padding: 1rem;
  background-color: white;
  ${textStyle};
  z-index: 20;
  width: max-content;
`;

const Form = styled.form`
  display: grid;
  gap: 0.5rem;
`;

const StyledInput = styled(Input)`
  font-weight: normal;
  width: 15rem;
`;

const Submit = styled.input.attrs({ type: "submit" })`
  justify-self: end;
  margin-top: 1rem;
  border: none;
  background-color: ${blueBrand};
  border-radius: 2px;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  &:active {
    filter: saturate(0.8);
  }
  &:disabled {
    background-color: ${gray4};
  }
`;

const SaveFilterPopup: React.FC<{
  onSave: (name: string) => Promise<void>;
  onClose: () => void;
}> = ({ onSave, onClose }) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  useEscape(onClose);
  const onSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (saving) return;
      setSaving(true);
      if (!name) return;
      onSave(name).then(onClose);
    },
    [name, onClose, onSave, saving]
  );
  return (
    <>
      <Overlay open onClick={onClose} />
      <Container>
        <Form onSubmit={onSubmit}>
          <H3>Filter Name</H3>
          <StyledInput
            autoFocus
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <Submit value="Add Filter" disabled={saving || !name} />
        </Form>
      </Container>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SaveFilterPopup;
