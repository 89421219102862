import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  CreateRbacRoleRequest,
  RbacRole,
  apiV1RbacRolesPostUrl,
} from "../../../../../generated/auth";

const CREATE_RBAC_ROLE_PATH = "/rbac-roles";

const createRbacRole = async (
  apiClient: AxiosInstance,
  params: CreateRbacRoleRequest
): Promise<RbacRole> => {
  const { data } = await apiClient.post(
    apiV1RbacRolesPostUrl(
      { createRbacRoleRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useCreateRbacRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation([CREATE_RBAC_ROLE_PATH], (params: CreateRbacRoleRequest) =>
    createRbacRole(apiClient, params)
  );
};
