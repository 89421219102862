import { InspectionStore } from "./inspectionStore";

//getters
export const selectedNamespacesSelector = (state: InspectionStore): string[] =>
  state.selectedNamespaces;

//setters
export const setNamespacesSelector = (
  state: InspectionStore
): ((namespaces: string[]) => void) => state.setSelectedNamespaces;
