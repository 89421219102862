import { CostAllocationStatsResponse } from "../../../../generated/metricsApi";

import { getRandRange } from "./getRandRange";

export const generateMockCostAllocationStats =
  (): CostAllocationStatsResponse => {
    return {
      totalCost: getRandRange(100000, 200000),
      totalPotentialSaving: getRandRange(20000, 600000),
      totalOptimizationScore: getRandRange(10, 90),
      totalUnallocatedCost: getRandRange(1000, 2000),
    };
  };
