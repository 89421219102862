import { AgentType } from "@/shared/hooks/useAgents";
import { doesAgentVersionSupportRbacClusterSync } from "@/shared/utils/agent/agent";
import {
  agentInfoToProperties,
  parseAgentInfo,
} from "@/shared/hooks/useAgentInfo/useAgentInfo";
import { RbacClusterStatus } from "@/generated/auth";

export type RbacClusterSyncFeatureFlag = {
  allowUsersDownload: boolean;
  syncClusters: boolean;
  syncTimeoutMinute: number;
};

export type ClusterForRbacSync = {
  name: string;
  syncError: string | null;
  isSynced: boolean;
  requiresAgentUpgrade: boolean;
  hasNoPoliciesMatchingScope: boolean;
};

type agentToClusterForRbacSyncParams = {
  agent: AgentType;
  rbacClusterStatuses: RbacClusterStatus[];
};
export const agentToClusterForRbacSync = ({
  agent,
  rbacClusterStatuses,
}: agentToClusterForRbacSyncParams): ClusterForRbacSync => {
  const clusterStatus = rbacClusterStatuses.find(
    (status) => status.clusterName === agent.clusterName
  );
  return agentAndClusterStatusToClusterForRbacSync({ agent, clusterStatus });
};

export const agentAndClusterStatusToClusterForRbacSync = ({
  agent,
  clusterStatus,
}: {
  agent?: AgentType;
  clusterStatus?: RbacClusterStatus;
}): ClusterForRbacSync => {
  if (!agent && !clusterStatus) {
    return {
      name: "",
      syncError: null,
      isSynced: false,
      requiresAgentUpgrade: false,
      hasNoPoliciesMatchingScope: false,
    };
  }
  if (!agent && clusterStatus) {
    return {
      name: clusterStatus.clusterName,
      syncError: clusterStatus.error,
      isSynced: false,
      requiresAgentUpgrade: false,
      hasNoPoliciesMatchingScope: false,
    };
  }
  if (agent && !clusterStatus) {
    const agentInfo = parseAgentInfo(agent);
    const agentProps = agentInfoToProperties(agentInfo);
    const versionSupported = doesAgentVersionSupportRbacClusterSync(agentProps);

    return {
      name: agent.clusterName,
      syncError: null,
      isSynced: false,
      requiresAgentUpgrade: !versionSupported,
      hasNoPoliciesMatchingScope: false,
    };
  }

  agent = agent as AgentType; // if we got here, agent is not undefined
  const agentInfo = parseAgentInfo(agent);
  const agentProps = agentInfoToProperties(agentInfo);
  const versionSupported = doesAgentVersionSupportRbacClusterSync(agentProps);

  const isSyncedWithCluster = Boolean(
    (clusterStatus &&
      clusterStatus.lastUpdatedKomodorRbac &&
      clusterStatus.lastUpdatedCluster &&
      new Date(clusterStatus.lastUpdatedCluster).getTime() >=
        new Date(clusterStatus.lastUpdatedKomodorRbac).getTime()) ||
      (!clusterStatus?.lastUpdatedKomodorRbac &&
        clusterStatus?.lastUpdatedCluster)
  );

  return {
    name: agent.clusterName,
    syncError: clusterStatus?.error ?? null,
    isSynced: isSyncedWithCluster,
    requiresAgentUpgrade: !versionSupported,
    hasNoPoliciesMatchingScope: clusterStatus === undefined,
  };
};

export const filterValidClusters = (
  clusters: ClusterForRbacSync[]
): ClusterForRbacSync[] => clusters.filter(isClusterValid);

export const isClusterValid = (cluster: ClusterForRbacSync): boolean =>
  !cluster.requiresAgentUpgrade &&
  !cluster.hasNoPoliciesMatchingScope &&
  !cluster.syncError;

export const filterClustersRequiringUpdate = (
  clusters: ClusterForRbacSync[]
): ClusterForRbacSync[] =>
  clusters.filter((cluster) => cluster.requiresAgentUpgrade);

export const filterClustersOutOfSyncOrWithError = (
  clusters: ClusterForRbacSync[]
): ClusterForRbacSync[] =>
  clusters.filter(
    (cluster) =>
      !cluster.requiresAgentUpgrade && (!cluster.isSynced || cluster.syncError)
  );
