import React, { useRef } from "react";

import Resource from "../../../resources";
import {
  GraphsContainer,
  MainContainer,
  MetricsContainer,
} from "../../MetricsTab/styles";
import { useReportTabLoadingTime } from "../hooks/useReportTabLoadingTime";
import { LoadingStateIds } from "../workloadMetricsTabConstants";
import { useSetInitialLoadTime } from "../hooks/useSetInitialLoadTime";
import { useIsElementInViewport } from "../../../../../shared/hooks/useIntersectionObserver";

import { WorkloadMetricsPreferences } from "./WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { MetricsPerContainer } from "./MetricsPerContainer/MetricsPerContainer";
import { WorkloadGraph } from "./graphs/WorkloadGraph";

interface WorkloadMetricsTabContentProps {
  resource: Resource;
}

export const WorkloadMetricsTabContent: React.FC<
  WorkloadMetricsTabContentProps
> = ({
  resource = { cluster: "", name: "", namespace: "", kind: "" } as Resource,
}) => {
  const { kind } = resource;
  const divRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useIsElementInViewport({ ref: divRef });

  useReportTabLoadingTime(kind, isDisplayed);
  useSetInitialLoadTime();

  const syncId = `${resource.cluster}/${resource.namespace}/${resource.name}`;

  return (
    <MetricsContainer ref={divRef}>
      <MainContainer>
        <WorkloadMetricsPreferences />
        <GraphsContainer>
          <WorkloadGraph
            resource={resource}
            loadingStateId={LoadingStateIds.workLoadMemory}
            type={"memory"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
          <WorkloadGraph
            resource={resource}
            loadingStateId={LoadingStateIds.workLoadCPU}
            type={"cpu"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
        </GraphsContainer>
      </MainContainer>
      <MetricsPerContainer resource={resource} id={syncId} />
    </MetricsContainer>
  );
};
