import React from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

export const Section: React.FC<{
  title: string;
  children: React.ReactNode;
  ariaLabel?: string;
}> = ({ title, children, ariaLabel }) => {
  return (
    <>
      <Typography
        aria-label={`${ariaLabel}-title`}
        sx={{ fontWeight: 500, textTransform: "uppercase" }}
        variant={"overline"}
        color={muiColors.gray[500]}
      >
        {title}
      </Typography>
      <div aria-label={`${ariaLabel}-content`}>{children}</div>
    </>
  );
};
