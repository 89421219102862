import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { DropDownTriggerField } from "../shared/components/DropDownTriggerField";
import { SelectContainer } from "../shared/styledComponents";
import { selectAriaLabels } from "../shared/ariaLabels";
import { Label } from "../shared/components/Label";
import { defaultSelectSize } from "../../../../constants";

import { ChipsContainer } from "./components/ChipsContainer/ChipsContainer";
import { PlaceholderText } from "./components/PlaceholderText";
import { AdvancedMultiSelectProps } from "./multiSelectTypes";

import { MultiSelectPopover } from "../MultiSelectPopover/MultiSelectPopover";
import { DEFAULT_ANCHOR_ORIGIN } from "../shared/constants";

export const AdvancedMultiSelect = <T extends MuiSelectionOptionValue>({
  value,
  placeholder,
  label,
  size = defaultSelectSize,
  width,
  minWidth,
  enableLineBreaks,
  disabled,
  classNames,
  ariaLabel = selectAriaLabels.multiSelect,
  onChange,
  onOpen,
  onClose,
  selectPopoverProps,
  showTooltip = false,
  tooltipPlacement,
  ...rest
}: AdvancedMultiSelectProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [localValue, setLocalValue] = useState(value ?? []);

  useEffect(() => {
    value && setLocalValue(value);
  }, [value]);

  const onOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const onClosePopover = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const onChangeLocalValue = useCallback(
    (selectedOptions: MuiSelectionOption<T>[]) => {
      setLocalValue(selectedOptions);
      onChange?.(selectedOptions);
    },
    [onChange]
  );

  const tooltipValue = useMemo(() => {
    if (!showTooltip) return "";
    return localValue.map((option) => option.label).join("\n");
  }, [showTooltip, localValue]);

  return (
    <SelectContainer
      aria-label={ariaLabel}
      width={width}
      minWidth={minWidth}
      disabled={disabled}
      ref={containerRef}
      aria-disabled={disabled}
    >
      <Label value={label} className={classNames?.label} />
      <DropDownTriggerField
        onClick={onOpenPopover}
        dropDownOpen={Boolean(anchorEl)}
        enableLineBreaks={enableLineBreaks}
        className={classNames?.dropDownTriggerField}
        size={size}
        disabled={disabled}
        tooltip={tooltipValue}
        tooltipPlacement={tooltipPlacement}
      >
        <PlaceholderText
          placeholder={placeholder}
          hasOptionSelected={!!localValue.length}
        />
        <ChipsContainer
          onRemoveItem={(itemValue) =>
            onChangeLocalValue(localValue.filter((o) => o.value !== itemValue))
          }
          selectedItems={localValue}
          enableLineBreaks={enableLineBreaks}
          size={size}
          className={classNames?.chipsContainer}
        />
      </DropDownTriggerField>
      <MultiSelectPopover
        anchorEl={anchorEl}
        onClose={onClosePopover}
        value={localValue}
        onChange={onChangeLocalValue}
        selectPopoverProps={{
          container: containerRef.current,
          anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
          ...selectPopoverProps,
        }}
        {...rest}
      />
    </SelectContainer>
  );
};
