import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import styled from "styled-components";

import {
  ConfigFormValidationState,
  CostOptimizationConfig,
} from "../../types/costOptimizationTypes";

import { ConfigFormErrorMessages, configPropToText } from "./constants";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

const StyledTextField = styled(TextField)`
  min-width: 220px;
  // disable arrows on number input
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  min-width: 220px;
  justify-content: flex-end;
`;

const TextContainer = styled.div`
  margin-right: 24px;
`;

type SettingFieldProps = {
  settingsData: CostOptimizationConfig;
  validationState: ConfigFormValidationState;
  settingProp: keyof CostOptimizationConfig;
  onChange: (key: keyof CostOptimizationConfig, val: number | boolean) => void;
};

export const SettingField: React.FC<SettingFieldProps> = ({
  settingProp,
  onChange,
  settingsData,
  validationState,
}) => {
  const value = settingsData[settingProp];
  const isTextField = typeof value === "number"; // will show a redundant warning until allocateIdleResources is added to the config

  const { title, subtitle, textLabel } = configPropToText[settingProp];
  const errorState = validationState[settingProp];
  const onTextValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(settingProp, parseFloat(e.target.value ?? "0"));
  };

  const onSwitchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange(settingProp, checked);
  };

  const helperText = errorState?.error
    ? ConfigFormErrorMessages[errorState.error]
    : undefined;

  const valueToDisplay = isTextField && isNaN(value) ? "" : value;

  const interactiveElement = isTextField ? (
    <StyledTextField
      label={textLabel}
      value={valueToDisplay}
      onChange={onTextValueChange}
      fullWidth
      error={!!errorState?.error}
      helperText={helperText}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      aria-label={`value for ${textLabel}`}
    />
  ) : (
    <Switch checked={value} onChange={onSwitchValueChange} />
  );

  return (
    <FlexContainer>
      <TextContainer>
        <Typography variant={"h4"}>{title}</Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          {subtitle}
        </Typography>
      </TextContainer>
      <InputFieldContainer>{interactiveElement}</InputFieldContainer>
    </FlexContainer>
  );
};
