import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { OptionType, Select } from "@komodorio/design-system/deprecated";
import { isEmpty, startCase } from "lodash";

import { useIsEditMode } from "../../hooks/creationPageHooks";
import { useCreationPageContext } from "../../context/CreationPageContext";
import { currentAppViewSelector } from "../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../../../../shared/store/appViewsStore/appViewsStore";
import { AppErrors } from "../../types/creationPageTypes";
import { appViewAriaLabels } from "../../../../appViewAriaLabels";

import {
  getServiceIdentifierTypeBy,
  useGetAggregatedServicesScopes,
} from "./appViewScopingHooks";
import { PaddedTypography } from "./ScopingPredicateConstructor/internal/PaddedTypography";
import { ComparandSelector } from "./ScopingPredicateConstructor/internal/ComparandSelector";
import { VALUE_MISSING_ERROR } from "./ScopingPredicateConstructor/internal/constants";

import {
  LabelsWorkspace,
  NamespacesWorkspace,
  Workspace,
  WorkspaceKind,
} from "@/generated/workspacesApi";
const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
`;

export const AppViewScopeHandler: React.FC = () => {
  const { setAppViewType, setServiceIdentifiers } = useCreationPageContext();
  const aggregatedServicesScopes = useGetAggregatedServicesScopes();
  const currentWorkspaces = useAppViewsStore(currentAppViewSelector);
  const isEditMode = useIsEditMode();

  const {
    errorState: { servicesSelectedError },
    setErrorState,
  } = useCreationPageContext();

  const [selectedWorkspaceKind, setSelectedWorkspaceKind] =
    useState<WorkspaceKind>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>();

  const handleWorkspaceUpdate = (updatedWorkspace: Workspace) => {
    if (updatedWorkspace.kind) {
      setAppViewType(updatedWorkspace.kind);
    }

    switch (updatedWorkspace.kind) {
      case WorkspaceKind.Namespace:
        setServiceIdentifiers(
          (updatedWorkspace?.value as NamespacesWorkspace)?.namespaces ?? []
        );
        break;

      case WorkspaceKind.Label:
        setServiceIdentifiers(
          (updatedWorkspace.value as LabelsWorkspace)?.labels ?? []
        );
        break;

      default:
        setServiceIdentifiers([]);
        return;
    }
  };
  const handleServiceIdentifierTypeSelection = (
    selectedOption: OptionType<WorkspaceKind>
  ) => {
    setSelectedWorkspaceKind(selectedOption.value);

    const updatedWorkspace = {
      kind: selectedOption.value,
    } as Workspace;
    setSelectedWorkspace(updatedWorkspace);
    handleWorkspaceUpdate(updatedWorkspace);
    setErrorState({ servicesSelectedError: undefined });
  };

  const serviceIdentifierTypeOptions = useMemo(() => {
    if (!aggregatedServicesScopes) return [];
    return Object.entries(aggregatedServicesScopes)
      .filter(([, values]) => !isEmpty(values))
      .map(([serviceProperty]) => getServiceIdentifierTypeBy(serviceProperty))
      .map((serviceIdentifierType: WorkspaceKind) => {
        return {
          label: startCase(serviceIdentifierType),
          value: serviceIdentifierType,
        };
      });
  }, [aggregatedServicesScopes]);

  useEffect(() => {
    const workspaceKind = serviceIdentifierTypeOptions.find(
      (option) => option.value === currentWorkspaces.kind
    )?.value;

    if (isEditMode && currentWorkspaces.kind && currentWorkspaces.value) {
      setSelectedWorkspaceKind(workspaceKind);
      setSelectedWorkspace(currentWorkspaces);
    }
  }, [
    serviceIdentifierTypeOptions,
    isEditMode,
    currentWorkspaces.kind,
    currentWorkspaces.value,
    currentWorkspaces,
  ]);
  if (!aggregatedServicesScopes) return null;

  return (
    <Container aria-label={appViewAriaLabels.dynamicScopeSelector}>
      <PaddedTypography size="medium">where</PaddedTypography>
      <Select
        width="12rem"
        options={serviceIdentifierTypeOptions}
        onChange={handleServiceIdentifierTypeSelection}
        value={{
          label: startCase(selectedWorkspaceKind),
          value: selectedWorkspaceKind ?? WorkspaceKind.Namespace,
        }}
        placeholder="Select"
        errorMessage={
          servicesSelectedError === AppErrors.NoServiceIdentifierTypeSelected
            ? VALUE_MISSING_ERROR
            : undefined
        }
      />
      {!!selectedWorkspaceKind && startCase(selectedWorkspaceKind) && (
        <PaddedTypography size="medium">is</PaddedTypography>
      )}
      <ComparandSelector
        comparandType={selectedWorkspaceKind}
        scopingOptions={aggregatedServicesScopes}
        selectedWorkspace={selectedWorkspace}
        onWorkspaceUpdate={(workspace: Workspace) => {
          setSelectedWorkspace(workspace);
          handleWorkspaceUpdate(workspace);
        }}
      />
    </Container>
  );
};
