import { muiColors, muiPalette } from "@komodorio/design-system";

import { Dictionary } from "../../../../../../../shared/types/Dictionary";

import { TrendData } from "./overviewMetricsTypes";

type TickFormatterParams = {
  tick?: string;
  timestampIndexDictionary: Dictionary<string>;
};

export type TrendSign = "+" | "-";

export const tickFormatter = ({
  tick,
  timestampIndexDictionary,
}: TickFormatterParams) => {
  if (!tick) return "";

  return timestampIndexDictionary[tick] || "";
};

export const metricsUnits = { issues: "issues", score: "score" };

export const getTrendSign = (isIncreased: boolean): TrendSign =>
  isIncreased ? "+" : "-";

const ColorsIndication = {
  improvement: muiColors.teal[600],
  degradation: muiColors.pink[600],
};

const getTrendColor = (isSuccess: boolean) =>
  isSuccess
    ? ColorsIndication.improvement ?? ""
    : ColorsIndication.degradation ?? "";

type GetTrendDataParams = {
  trend: string;
  trendSign: TrendSign;
  isSuccess: boolean;
};

export const getTrendData = ({
  trend,
  trendSign,
  isSuccess,
}: GetTrendDataParams): TrendData => {
  if (trend === "0")
    return { trend: "no change", color: muiPalette?.text?.secondary ?? "" };

  return {
    trend: `${trendSign}${trend}%`,
    color: getTrendColor(isSuccess),
  };
};
