import { muiColors } from "@komodorio/design-system";
import React from "react";
import styled from "styled-components";

interface RowContainerProps {
  noBorder?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
}

const Container = styled.div<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ noBorder }) =>
    noBorder ? "none" : `1px solid ${muiColors.gray[200]}`};
  padding: 12px 0;
  gap: 20px;
`;

export const RowContainer: React.FC<RowContainerProps> = ({
  noBorder,
  children,
  ...props
}) => (
  <Container noBorder={noBorder} {...props}>
    {children}
  </Container>
);
