import { MetricsAggregationType } from "../types";

export const selectableMetrics: MetricsAggregationType[] = [
  MetricsAggregationType.Avg,
  MetricsAggregationType.P90,
  MetricsAggregationType.P95,
  MetricsAggregationType.P99,
  MetricsAggregationType.Max,
];

export const metricsDataToLabel: Partial<
  Record<MetricsAggregationType, string>
> = {
  [MetricsAggregationType.P90]: "P90",
  [MetricsAggregationType.P95]: "P95",
  [MetricsAggregationType.P99]: "P99",
  [MetricsAggregationType.Avg]: "Avg.",
  [MetricsAggregationType.Max]: "Max",
};
