import React from "react";

import { LogoIconProps } from "../types";

export const Airflow: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Airflow logo"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <g clip-path="url(#clip0_1249_8644)">
        <path
          d="M13.6999 17.4H18.4999L10.8199 11C10.7145 13.3246 11.7378 15.5987 13.6999 17.4Z"
          fill="url(#paint0_radial_1249_8644)"
        />
        <path
          d="M4.1001 14.1999V18.9999L10.5001 11.3199C10.391 11.3149 10.2824 11.3125 10.1735 11.3125C7.96354 11.3126 5.81679 12.3299 4.1001 14.1999Z"
          fill="url(#paint1_radial_1249_8644)"
        />
        <path
          d="M2.5 4.6001L10.18 11.0001C10.2854 8.6756 9.26206 6.40135 7.3 4.6001H2.5Z"
          fill="url(#paint2_radial_1249_8644)"
        />
        <path
          d="M16.9 3L10.5 10.6799C12.8246 10.7854 15.0987 9.762 16.9 7.79994V3Z"
          fill="url(#paint3_radial_1249_8644)"
        />
        <path
          d="M10.5001 11C9.78267 12.8597 9.89105 14.7554 10.8201 16.6001C11.7801 17.08 12.5801 17.4 13.7001 17.4C11.738 15.5987 10.7147 13.3246 10.8201 11H10.5001Z"
          fill="url(#paint4_radial_1249_8644)"
        />
        <path
          d="M4.9001 11.3201C4.42016 12.2802 4.1001 13.0801 4.1001 14.2001C5.90129 12.238 8.17554 11.2147 10.5001 11.3201V11.0001C9.6911 10.688 8.87547 10.5322 8.0601 10.5322C7.00097 10.5323 5.94235 10.7952 4.9001 11.3201Z"
          fill="url(#paint5_radial_1249_8644)"
        />
        <path
          d="M10.18 11.0001H10.5C11.2174 9.14041 11.1091 7.24466 10.18 5.4001C9.22005 4.9201 8.42011 4.6001 7.30005 4.6001C9.26211 6.40135 10.2855 8.6756 10.18 11.0001Z"
          fill="url(#paint6_radial_1249_8644)"
        />
        <path
          d="M10.5 10.6798V10.9999C12.3597 11.7172 14.2554 11.6089 16.1 10.6798C16.58 9.71993 16.9 8.91993 16.9 7.7998C15.0987 9.76187 12.8246 10.7853 10.5 10.6798Z"
          fill="url(#paint7_radial_1249_8644)"
        />
        <path
          d="M6.97998 13.4001L10.82 16.6001C9.89092 14.7554 9.78254 12.8597 10.5 11.0001C10.389 10.9631 10.2589 10.9453 10.1151 10.9453C9.01517 10.9454 7.12148 11.985 6.97998 13.4001Z"
          fill="url(#paint8_radial_1249_8644)"
        />
        <path
          d="M4.8999 11.32C6.74453 10.3909 8.64021 10.2826 10.4999 11C10.8199 10.04 9.6999 7.63998 8.0999 7.47998L4.8999 11.32Z"
          fill="url(#paint9_radial_1249_8644)"
        />
        <path
          d="M10.4999 10.9999C11.4599 11.3199 13.8599 10.1999 14.0199 8.5999L10.1799 5.3999C11.109 7.24446 11.2173 9.14021 10.4999 10.9999Z"
          fill="url(#paint10_radial_1249_8644)"
        />
        <path
          d="M10.5 11.0002C10.18 11.9603 11.3 14.3602 12.9 14.5202L16.1 10.6802C14.2554 11.6092 12.3597 11.7176 10.5 11.0002Z"
          fill="url(#paint11_radial_1249_8644)"
        />
        <path
          d="M10.5 10.6001C10.721 10.6001 10.9 10.7792 10.9 11C10.9 11.221 10.721 11.4001 10.5 11.4001C10.2792 11.4001 10.1001 11.221 10.1001 11C10.1001 10.7792 10.2792 10.6001 10.5 10.6001Z"
          fill="black"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.42061 1373.69) scale(1967.98 1638.4)"
        >
          <stop stop-color="#00C812" />
          <stop offset="1" stop-color="#006E00" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-4.41413 4.332) rotate(90) scale(1638.41 1967.97)"
        >
          <stop stop-color="#56AFFD" />
          <stop offset="1" stop-color="#04A3FF" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1635.79 1.66797) rotate(180) scale(1967.98 1638.4)"
        >
          <stop stop-color="#DF1F00" />
          <stop offset="1" stop-color="#560D00" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1640.91 -1367.57) rotate(-90) scale(1638.41 1967.96)"
        >
          <stop stop-color="#00FFFD" />
          <stop offset="1" stop-color="#00DAFF" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.0356 1649.41) scale(938.975 1638.4)"
        >
          <stop stop-color="#008200" />
          <stop offset="1" stop-color="#007500" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-5.03287 4.6002) rotate(90) scale(1638.4 938.975)"
        >
          <stop stop-color="#3166D4" />
          <stop offset="1" stop-color="#1A509E" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-941.15 1.4) rotate(180) scale(938.975 1638.4)"
        >
          <stop stop-color="#8E1400" />
          <stop offset="1" stop-color="#4B0B00" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(946.298 -1643.28) rotate(-90) scale(1638.39 938.982)"
        >
          <stop stop-color="#0090C4" />
          <stop offset="1" stop-color="#005D8C" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.97998 1458.54) scale(983.04 1447.61)"
        >
          <stop stop-color="#00CD1C" />
          <stop offset="1" stop-color="#00E950" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1.98 5.39984) rotate(90) scale(1447.61 983.041)"
        >
          <stop stop-color="#B1C8FD" />
          <stop offset="1" stop-color="#7EC3FD" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-988.095 0.599989) rotate(180) scale(983.04 1447.61)"
        >
          <stop stop-color="#E12A00" />
          <stop offset="1" stop-color="#B62F00" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_1249_8644"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(993.253 -1452.43) rotate(-90) scale(1447.59 983.057)"
        >
          <stop stop-color="#00B6DD" />
          <stop offset="1" stop-color="#00FFFF" />
        </radialGradient>
        <clipPath id="clip0_1249_8644">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2.5 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
