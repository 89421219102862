import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { palette } from "@komodorio/design-system";
import { Link } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";
import { ResourceTableModelRow } from "komodor-types";

import { isMetricsColumn } from "../../../Inspection/metrics/buildMetricFromResult";
import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ColumnProperties } from "../TableProperties";
import {
  INSPECTION_LABELS_PARAM_KEY,
  INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import EventGroup from "../../EventGroup";
import { OpenIssueAlertIcon } from "../../../OpenIssues/IndicationIconStyles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useFindService } from "../../../../shared/hooks/useFindService";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ControlledByDataCell from "./ControlledByDataCell";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NodeDataCell } from "./NodeDataCell";
import { MetricsCell } from "./MetricsCell";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NameCell } from "./NameCell/NameCell";
import { HealthCell } from "./HealthCell";
import { StatusCell } from "./StatusCell/StatusCell";
import { StatusConditionsCell } from "./StatusCell/StatusConditionsCell";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const CellWithIssue = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  &:hover .issue-alert-icon {
    cursor: pointer;
    color: ${palette.pink[700]};
  }
`;

export const IssueAlertIcon = styled(OpenIssueAlertIcon).attrs({
  cursor: "pointer",
  className: "issue-alert-icon",
})``;

const ShortenText = styled.div<{ customWidth?: number }>`
  width: ${({ customWidth = 100 }) => customWidth + "px"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isDataNone = (cellData: string): boolean => cellData === "<none>";

interface ResourceListTableDataCellProps {
  cellName: string;
  resourceType: KubernetesResource;
  resourceName: string;
  cellData: string;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  properties: ColumnProperties;
  clusterName: string;
  namespace?: string;
  rowId: string;
  controlledByRoot?: string;
  row?: ResourceTableModelRow;
}

const ResourceListTableDataCell: React.FC<ResourceListTableDataCellProps> = ({
  cellName,
  resourceType,
  resourceName,
  cellData,
  setCorrelatedEvent,
  properties,
  clusterName,
  namespace,
  rowId,
  controlledByRoot,
  row,
}) => {
  const navigate = useNavigate();

  const service = useFindService({
    value: resourceName,
    clusterName,
    pageName: resourceType.NavBarName,
    namespace,
    controlledByRoot,
  });

  const controlledByServiceInfo = useMemo(() => {
    if (cellName === "controlledBy") {
      return service;
    }
    return undefined;
  }, [cellName, service]);

  const [, setFilterLabels] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_LABELS_PARAM_KEY
  );
  const [, setFilterLabelsExactMatch] =
    useStringifiedStateInSearchParams<boolean>(
      INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY
    );
  const onPodSelectorClick = useCallback(() => {
    navigate({
      pathname: `/main/resources/workloads/pods/${clusterName}`,
    });
    setFilterLabels(cellData?.split(",") ?? []);
    setFilterLabelsExactMatch(true);
  }, [
    navigate,
    clusterName,
    setFilterLabels,
    cellData,
    setFilterLabelsExactMatch,
  ]);

  if (cellName === "status") {
    return (
      <StatusCell
        cellName={cellName}
        cellData={cellData}
        resourceType={resourceType}
        service={service}
        setCorrelatedEvent={setCorrelatedEvent}
        rowId={rowId}
      />
    );
  }

  if (cellName === "health") {
    return (
      <HealthCell
        cellName={cellName}
        cellData={cellData}
        resourceType={resourceType}
        service={service}
        setCorrelatedEvent={setCorrelatedEvent}
        rowId={rowId}
      />
    );
  }

  if (cellName === "name") {
    return (
      <NameCell
        clusterName={clusterName}
        namespace={namespace}
        resourceType={resourceType}
        resourceName={resourceName}
        service={service}
        cellName={cellName}
        cellData={cellData}
        setCorrelatedEvent={setCorrelatedEvent}
        rowId={rowId}
      />
    );
  }
  if (["podSelector", "selector"].includes(cellName)) {
    const linkable = !isDataNone(cellData);
    return (
      <td key={cellName}>
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content={cellData}>
          {linkable ? (
            <Link onClick={onPodSelectorClick}>
              <ShortenText>{cellData}</ShortenText>
            </Link>
          ) : (
            <ShortenText>{cellData}</ShortenText>
          )}
        </TooltipWrapper>
      </td>
    );
  }

  if (cellName === "controlledBy") {
    return (
      <ControlledByDataCell
        cellName={cellName}
        cellData={cellData}
        service={controlledByServiceInfo}
        resourceName={resourceName}
        resourceType={resourceType}
        setCorrelatedEvent={setCorrelatedEvent}
        rowId={rowId}
      />
    );
  }

  if (cellName === "node") {
    return (
      <NodeDataCell
        clusterName={clusterName}
        cellName={cellName}
        cellData={cellData}
        properties={{ ...properties, clickable: !isDataNone(cellData) }}
        setCorrelatedEvent={setCorrelatedEvent}
        rowId={rowId}
      />
    );
  }

  if (cellName === "statusConditions") {
    return <StatusConditionsCell cellName={cellName} row={row} />;
  }
  if (isMetricsColumn(cellName)) {
    return <MetricsCell cellData={cellData} cellColumn={cellName} />;
  }

  if (cellName === "images") {
    return (
      <td key={cellName}>
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content={cellData}>
          <ShortenText customWidth={200} key={cellName}>
            {cellData}
          </ShortenText>
        </TooltipWrapper>
      </td>
    );
  }

  return <td key={cellName}>{cellData}</td>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceListTableDataCell;
