import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { RichSessionData } from "../common/useEnrichedSession";

import { DetailsContainer, ResultLineContainer, ResultTitle } from "./styles";

const EvidenceContainer = styled.div`
  background-color: ${muiColors.gray[800]};
  padding: 4px 16px;
  border-radius: 4px;
  white-space: pre;
  overflow: auto;
  max-height: 10rem;

  & .MuiLink-root {
    color: ${muiColors.blue[300]};
  }
`;

const RelatedEvidenceContainer = styled(DetailsContainer)`
  overflow-x: auto;
`;

export const RelatedEvidence: React.FC<{ session: RichSessionData }> = ({
  session,
}) => {
  return session.evidence ? (
    <ResultLineContainer>
      <ResultTitle>Related Evidence:</ResultTitle>
      <RelatedEvidenceContainer>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "8px",
          }}
        >
          From: {session.evidenceSource}
        </Typography>
        <EvidenceContainer>
          <Typography variant="code1" color={muiColors.gray[100]}>
            {session.evidence}
          </Typography>
        </EvidenceContainer>
      </RelatedEvidenceContainer>
    </ResultLineContainer>
  ) : null;
};
