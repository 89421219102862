import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Metadata as MetadataItem,
  MetadataProps,
} from "@komodorio/design-system/komodor-ui";

import { liveStateDrawerAriaLabels } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerAriaLabels";

const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  gap: 32px;
`;

type MetadataSectionProps = {
  items: MetadataProps[];
  className?: string;
};

export const MetadataSection: React.FC<MetadataSectionProps> = ({
  items,
  className,
}) => {
  const content = useMemo(() => {
    return items.map((item) => {
      return <MetadataItem {...item} key={item.title} />;
    });
  }, [items]);
  return (
    <Container
      className={className}
      aria-label={liveStateDrawerAriaLabels.metadataSection}
    >
      {content}
    </Container>
  );
};
