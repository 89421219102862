import React from "react";
import styled from "styled-components";

import { pinkBrand } from "../../../../../../../Colors";
import { Text } from "../../../../../typography";
import { Triangle, UpsideTriangle } from "../../../../../Shape";

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: transparent;
`;

const StyledText = styled(Text)<{
  wrapText?: boolean;
  color?: string;
  maxWidth?: string;
}>`
  width: max-content;
  max-width: 21rem;
  background-color: ${({ color }) => color ?? pinkBrand};
  color: #ffffff;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  ${({ wrapText }) =>
    wrapText
      ? `overflow-wrap: break-word;
  max-width: 11rem;`
      : ""}
  ${({ maxWidth }) => (maxWidth ? `max-width:${maxWidth};` : "")}
`;

type TriangleX = "left" | "center" | "right";

const getJustify = (x: TriangleX) => {
  if (x === "center") return "center";
  if (x === "left") return "flex-start";
  return "flex-end";
};

const CustomTooltip: React.FC<{
  className?: string;
  triangleX?: TriangleX;
  triangleMargin?: string;
  triangleWidth?: string;
  triangleHeight?: string;
  wrapText?: boolean;
  upside?: boolean;
  color?: string;
  showTriangle?: boolean;
  maxWidth?: string;
  children?: React.ReactNode;
}> = ({
  className,
  triangleX = "left",
  triangleMargin,
  triangleWidth,
  triangleHeight,
  wrapText = false,
  children,
  upside,
  color,
  showTriangle = true,
  maxWidth,
}) => (
  <TooltipContainer className={className} style={{ width: "max-content" }}>
    {showTriangle && !upside && (
      <div
        style={{
          margin: `0 ${triangleMargin ?? "1.52rem"}`,
          marginBottom: "-1px",
          display: "flex",
          justifyContent: getJustify(triangleX),
        }}
      >
        <Triangle width={triangleWidth} height={triangleHeight} color={color} />
      </div>
    )}
    <StyledText wrapText={wrapText} color={color} maxWidth={maxWidth}>
      {children}
    </StyledText>
    {showTriangle && upside && (
      <div
        style={{
          margin: `0 ${triangleMargin ?? "1.52rem"}`,
          marginBottom: "-1px",
          display: "flex",
          justifyContent: getJustify(triangleX),
        }}
      >
        <UpsideTriangle
          width={triangleWidth}
          height={triangleHeight}
          color={color}
        />
      </div>
    )}
  </TooltipContainer>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CustomTooltip;
