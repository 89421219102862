import React, { useCallback, useState } from "react";

import { ConfirmationDialog } from "../../shared/ConfirmationDialog";
import { getDeleteDialogTexts } from "../../allPoliciesConstants";
import { useReportError } from "../../../../../hooks/useReportError";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectSetReliabilityNotification } from "@/components/reliability/store/reliabilityStoreSelectors";
import { reliabilitySuccessMessages } from "@/components/reliability/constants/reliabilityConstants";
import {
  useDeletePolicy,
  useGetAllPolicies,
} from "@/shared/hooks/reliability-api/policies/usePolicies";
import { PolicyListItem } from "@/generated/reliabilityApi";

type Status = "success" | "error" | "fetching" | "idle";

export const useOnDeletePolicy = () => {
  const { mutateAsync } = useDeletePolicy();
  const { refetch } = useGetAllPolicies();
  const [status, setStatus] = useState<Status>("idle");
  const reportError = useReportError();
  const setNotification = useReliabilityStore(selectSetReliabilityNotification);

  const deletePolicy = useCallback(
    async (policyId: string) => {
      try {
        setStatus("fetching");
        await mutateAsync({ id: policyId });
        await refetch();
        setStatus("success");
        setNotification({
          message: reliabilitySuccessMessages.policyDeleted,
          severity: "info",
        });
      } catch (e) {
        reportError(e, "deletePolicy");
        setStatus("error");
      }
    },
    [mutateAsync, refetch, reportError, setNotification]
  );

  return { status, deletePolicy };
};

type DeleteConfirmationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  policyListItem: PolicyListItem;
};

export const useDeletePolicyConfirmationDialog = ({
  isOpen,
  onClose,
  policyListItem,
}: DeleteConfirmationDialogProps) => {
  const { deletePolicy, status } = useOnDeletePolicy();

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={() => deletePolicy(policyListItem.id)}
      onCancel={() => onClose()}
      isLoading={status === "fetching"}
      {...getDeleteDialogTexts(policyListItem.name)}
    />
  );
};
