import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const NoRowsOverlay = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Typography variant="body2" color="text.secondary">
        No data available
      </Typography>
    </Box>
  );
};
