import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

import Overlay, { DarkenOverlay } from "../../../common/Overlay";
import { shadowCenteredModalStyles } from "../../../common/Modal/modal.styles";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

import KubernetesInstallation from "./KubernetesInstallation";
import BlurBackground from "./assets/background.jpg";

const BackgroundOverlay = styled(Overlay)`
  background-image: url(${BlurBackground});
  z-index: 1000;
  background-repeat: no-repeat;
  @media screen and (min-width: 1920px) {
    background-size: cover;
  }
`;

const KubernetesInstallationModal: React.FC<{
  closeModalCallback: () => void;
  isOpen: boolean;
  showWelcomeStep?: boolean;
}> = ({ closeModalCallback, isOpen, showWelcomeStep = true }) => {
  const { lockInstallFirstAgent } = useOverridableFlags();
  return (
    <>
      {lockInstallFirstAgent ? (
        <BackgroundOverlay open={isOpen} />
      ) : (
        <DarkenOverlay open={isOpen} />
      )}

      <Modal
        isOpen={isOpen}
        style={shadowCenteredModalStyles}
        onRequestClose={
          lockInstallFirstAgent ? () => null : () => closeModalCallback()
        }
      >
        <KubernetesInstallation
          closeModalCallback={closeModalCallback}
          showWelcomeStep={showWelcomeStep}
        />
      </Modal>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default KubernetesInstallationModal;
