// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { restrictionProps } from ".";

export const useLockRestrictionsData = (): restrictionProps => {
  return {
    modalTitle: "Upgrade to install integration",
    modalContent: `This integration is not supported in your current plan. In order to install this integration, you have to upgrade.`,
    onClick: () => {
      window.open("https://komodor.chilipiper.com/book/me/timi-petrov");
    },
  };
};
