import { useOverridableFlags } from "../../context/featureFlags/OverridableFlags";
import { doesAgentVersionSupportLivePodLogs } from "../../utils/agent/agent";
import { useAllActiveAgentInfos } from "../useAgentInfo/useAgentInfo";

export const useShouldUseLivePodsLogs = (clusterName: string) => {
  const { useLivePodLogs } = useOverridableFlags();
  const agents = useAllActiveAgentInfos();
  const agentInfo = agents?.find((agent) => agent.clusterName === clusterName);

  return (
    Boolean(useLivePodLogs) &&
    Boolean(agentInfo && doesAgentVersionSupportLivePodLogs(agentInfo))
  );
};
