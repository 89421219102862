import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import { DeployIssueDataParsed } from "../../common/types";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";

import {
  LatestDeploysTooltip,
  LATEST_DEPLOYS_TOOLTIP,
} from "./LatestDeploysTooltip";

export const LatestDeploysInsight: React.FC<{
  latestDeployInsights: DeployIssueDataParsed[];
  startTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
}> = ({ latestDeployInsights, setCorrelatedEvent, startTime }) => {
  const deploysLength = latestDeployInsights.length;

  return (
    <InsightContainer>
      <TooltipWrapper tooltipId={LATEST_DEPLOYS_TOOLTIP}>
        <Insight>
          {deploysLength} correlated{" "}
          <DashedText>deploy{deploysLength > 1 ? "s" : ""}</DashedText> within 1
          hour before the issue started
        </Insight>
      </TooltipWrapper>
      <LatestDeploysTooltip
        deploys={latestDeployInsights}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
        tooltipId={LATEST_DEPLOYS_TOOLTIP}
        ariaLabel={AriaLabels.AvailabilityTriage.DeployInsightTooltip}
        ariaLabelLink={AriaLabels.AvailabilityTriage.DeployInsightTooltipLink}
      />
    </InsightContainer>
  );
};
