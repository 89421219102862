import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacUserRolesRoleIdUserIdDeleteUrl,
  RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest,
} from "../../../../../generated/auth";

const deleteRbacUserRole = async (
  apiClient: AxiosInstance,
  params: RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest
): Promise<string> => {
  const { data } = await apiClient.delete(
    apiV1RbacUserRolesRoleIdUserIdDeleteUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useDeleteRbacUserRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    (params: RbacUserRolesApiApiV1RbacUserRolesRoleIdUserIdDeleteRequest) =>
      deleteRbacUserRole(apiClient, params)
  );
};
