import React, { useCallback } from "react";
import { Legend, Line } from "recharts";

import { sharedStraightLineProps } from "../../../../../Metrics/constants";
import {
  MemoryUnit,
  MetricName,
  MetricsGraphProps,
  MetricType,
} from "../../../../../Metrics/types";
import {
  CPUMetricsChartTooltip,
  MemoryMetricsChartTooltip,
} from "../../../../../Metrics/Tooltip/ResourceTooltip";
import {
  formatGraphCPUTick,
  formatGraphMemoryTick,
} from "../../../../../Metrics/utils";
import { MetricsGraphContainer } from "../../../../../Metrics/graphs/MetricsGraphContainer";
import { AriaLabels } from "../../../../../../shared/config/ariaLabels";
import {
  MetricsAllocatableColor,
  MetricsCapacityColor,
  MetricsUsageColor,
} from "../../../../../Metrics/styles";
import { GraphContainerWithStatus } from "../../../../../Metrics/GraphContainerWithStatus";
import { GraphProps } from "../../../../../ResourceView/tabs/NodeMetricsTab/NodeMetricsTabContent/MetricsPreferences/types";
import { GenericLegend } from "../../../../../Metrics/GenericLegend/GenericLegend";
import { clusterLegendItems } from "../../../../../Metrics/GenericLegend/genericLegendConstants";

import { useFindMaxValue } from "./utils";

const ONE_TIB = 1024 * 1024 * 1024 * 1024;

const { CpuGraph, MemoryGraph } =
  AriaLabels.ClusterOverview.InventorySection.Metrics;

type ClusterGraphProps = MetricsGraphProps & {
  title: string;
  type: MetricType;
  hasData: boolean;
  loading?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  onEnlargeGraph?: () => void;
  graphProps?: GraphProps;
};

export const ClusterGraph: React.FC<ClusterGraphProps> = ({
  title,
  type,
  hasData,
  loading,
  error,
  ...props
}) => {
  const maxValue = useFindMaxValue(props.data);
  const memoryUnit: MemoryUnit = maxValue && maxValue > ONE_TIB ? "TiB" : "GiB";
  const cpuUnit = "Cores";
  const isCpu = type === "cpu";
  const label = isCpu ? cpuUnit : memoryUnit;
  const ariaLabels = isCpu ? CpuGraph : MemoryGraph;

  const TooltipComponent = isCpu
    ? CPUMetricsChartTooltip
    : MemoryMetricsChartTooltip;

  const tickFormatter = useCallback(
    (tick: string) => {
      return isCpu
        ? formatGraphCPUTick(tick, cpuUnit)
        : formatGraphMemoryTick(tick, memoryUnit);
    },
    [isCpu, memoryUnit]
  );

  const lineNames: MetricName[] = [
    "usageBytes",
    "capacityBytes",
    "allocatableBytes",
  ];

  return (
    <GraphContainerWithStatus
      title={title}
      onEnlargeGraph={props.onEnlargeGraph}
      error={error}
      hasGraphData={hasData}
      loading={!!loading}
      uid={undefined}
      {...props}
    >
      <MetricsGraphContainer
        ariaLabels={ariaLabels}
        {...props}
        tooltipContent={
          <TooltipComponent
            unit={isCpu ? cpuUnit : "GiB"}
            aggregationType={props.aggregationType}
            lineNames={lineNames}
            getCustomTooltipEntries={props.getCustomTooltipEntries}
          />
        }
        tickFormatter={tickFormatter}
        label={label}
      >
        <Line
          aria-label={ariaLabels.UsageLine}
          stroke={MetricsUsageColor}
          dataKey="usageBytes"
          {...sharedStraightLineProps}
        />
        <Line
          aria-label={ariaLabels.CapacityLine}
          stroke={MetricsCapacityColor}
          dataKey="capacityBytes"
          {...sharedStraightLineProps}
          connectNulls={true}
        />
        <Line
          aria-label={ariaLabels.AllocatableLine}
          stroke={MetricsAllocatableColor}
          dataKey="allocatableBytes"
          {...sharedStraightLineProps}
          connectNulls={true}
        />
        <Legend content={<GenericLegend items={clusterLegendItems} />} />
        {props.children}
      </MetricsGraphContainer>
    </GraphContainerWithStatus>
  );
};
