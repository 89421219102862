import { EnrichedMessage } from "../types";
import { StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";

export class ImagePullBackoff extends BaseEnricher {
  constructor() {
    super(new RegExp(`Back-off pulling image "(\\S+)",`, "i"));
  }

  searchKey(): string {
    return `Back-off pulling image`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          `The image could not be pulled, and Kubernetes is waiting between retries`
        ),
      ],
    };
  }
}
