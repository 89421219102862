import { useMemo } from "react";
import { groupBy } from "lodash";

import { MonitorType } from "@/generated/monitorsApi";
import { CorrelatedIssueSupportingData } from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  violationDrawer: {
    singlePointOfFailure: {
      whatHappenedSummary: {
        availabilityIssue: availabilityIssueAriaLabel,
        nodeTerminations: nodeTerminationsAriaLabel,
        unhealthyMinutes: unhealthyMinutesAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

export type SummaryItem = {
  title: string;
  value: number | string;
  ariaLabel: string;
};

export const useSummaryItems = (
  correlatedIssues: CorrelatedIssueSupportingData[] | undefined,
  unavailableMinutes: number | undefined
) =>
  useMemo<SummaryItem[]>(() => {
    const groups = groupBy(correlatedIssues, "issueType");

    return [
      ...(groups[MonitorType.Availability]?.length
        ? [
            {
              title: "Availability issues",
              value: groups[MonitorType.Availability].length,
              ariaLabel: availabilityIssueAriaLabel,
            },
          ]
        : []),
      ...(groups[MonitorType.Node]?.length
        ? [
            {
              title: "Node terminations",
              value: groups[MonitorType.Node].length,
              ariaLabel: nodeTerminationsAriaLabel,
            },
          ]
        : []),
      ...(unavailableMinutes && unavailableMinutes > 0
        ? [
            {
              title: "Service unavailable",
              value: `${unavailableMinutes?.toLocaleString()} min`,
              ariaLabel: unhealthyMinutesAriaLabel,
            },
          ]
        : []),
    ];
  }, [correlatedIssues, unavailableMinutes]);
