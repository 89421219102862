/* eslint-disable max-lines */
import React, { useMemo } from "react";
import {
  Pod16,
  Nodes16,
  Applications16,
  Boxes16,
  IconProps,
  Kubernetes16,
  Workflows16,
} from "@komodorio/design-system/icons";
import SpaceDashboardOutlined from "@mui/icons-material/SpaceDashboardOutlined";
import { muiColors } from "@komodorio/design-system";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Status } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { SearchResult } from "../../../../generated/searchApi/api";
import { Page, PageName, usePages } from "../../links/pages";
import { JOBS, SERVICES } from "../../../routes/routes";
import {
  closeAllDrawersSelector,
  pushDrawerSelector,
} from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import {
  DrawerStatePush,
  DrawerType,
} from "../../../../shared/store/drawersStackStore/types";
import {
  KubernetesNodesResource,
  KubernetesPodsResource,
} from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { DELETED_POD_KIND } from "../../../ResourceView/resources/deletedPod";
import { sleep } from "../../../../shared/utils/sleep";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { daysHumanDurationLastTwoUnits } from "../../../../shared/utils/dateUtils";
import { getOverviewPageRoute } from "../../../appView/sections/AppView/utils/getRoutes";

import { ItemWithBreadcrumbs } from "./ItemWithBreadcrumbs";
import { SmallIcon } from "./SmallIcon";
import { DeletedResourceNameContainer } from "./DeletedResourceNameContainer";

import { WORKFLOW_RUN_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { getWorkflowUrl } from "@/components/AppBar/Search/utils";

type WorkspaceItem = (
  setWorkpace: (appViewId: string | undefined) => void
) => ItemMapping;
export type ItemMappingGetter = ItemMapping | WorkspaceItem;

export type ItemMapping = {
  icon: React.ReactNode | ((item: SearchResult) => React.ReactNode);
  content: (item: SearchResult) => React.ReactNode;
  onClick?: (item: SearchResult) => void;
};

export function isWorkspaceItemMapping(
  mapping: ItemMappingGetter,
  kind: string
): mapping is WorkspaceItem {
  return (
    typeof mapping === "function" && ["workspace", "cluster"].includes(kind)
  );
}

export const useItemMappings = (onClose: () => void) => {
  const navigate = useNavigate();
  const pages = usePages();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const closeAllDrawers = useDrawersStackStore(closeAllDrawersSelector);
  return useMemo(
    () =>
      itemMappings({
        navigate,
        pages,
        onClose,
        pushDrawer,
        closeAllDrawers,
      }),
    [closeAllDrawers, navigate, onClose, pages, pushDrawer]
  );
};

const itemMappings = ({
  navigate,
  pages,
  onClose,
  pushDrawer,
  closeAllDrawers,
}: {
  navigate: NavigateFunction;
  pages: Record<PageName, Page>;
  onClose: () => void;
  pushDrawer: (
    drawersState: DrawerStatePush,
    replaceUrl?: boolean | undefined
  ) => void;
  closeAllDrawers: (replaceUrl?: boolean | undefined) => void;
}): Record<string, ItemMappingGetter> => {
  const komodorServiceItem: (
    type: string,
    icon: React.ReactNode
  ) => ItemMapping = (type, icon) => {
    return {
      icon: <SmallIcon>{icon}</SmallIcon>,
      content: (item) => (
        <ItemWithBreadcrumbs
          breadcrumbs={[item.clusterName ?? "", item.namespace ?? ""]}
        >
          {item.name}
        </ItemWithBreadcrumbs>
      ),
      onClick: (item) => {
        onClose();
        navigate(`${type}/${encodeURIComponent(item.id)}`);
      },
    };
  };
  const pagesNames = Object.keys(pages);

  return {
    page: {
      icon: (item) => {
        const name = item.name as PageName;
        let Icon: React.FC<IconProps> = () => (
          <Applications16 width={16} height={14} />
        );
        if (pagesNames.includes(name)) {
          const icon = pages[name].icon;
          if (icon) {
            Icon = icon;
          }
        }
        return (
          <SmallIcon>
            <Icon fill={muiColors.gray[500]} width={16} height={14} />
          </SmallIcon>
        );
      },
      content: (item) => {
        const pageName = item.name as PageName;
        const isPageExists = pagesNames.includes(pageName);
        const shouldShow =
          isPageExists && pages[pageName].shouldShow && !pages[pageName].locked;
        return shouldShow ? item.name : null;
      },
      onClick: (item) => {
        onClose();
        if (
          [PageName.DocsAndSupport, PageName.WhatsNew].includes(
            item.name as PageName
          )
        ) {
          window.open(pages[item.name as PageName].url);
        } else {
          navigate(pages[item.name as PageName].url);
        }
      },
    },
    node: {
      icon: (
        <SmallIcon>
          <Nodes16 width={13} height={13.71} />
        </SmallIcon>
      ),
      content: (item) => (
        <ItemWithBreadcrumbs breadcrumbs={[item.clusterName ?? ""]}>
          {item.name}
        </ItemWithBreadcrumbs>
      ),
      onClick: async (item) => {
        onClose();
        closeAllDrawers(true);
        navigate(
          {
            pathname:
              pages[PageName.Nodes].urlWithCluster?.(item.clusterName ?? "") ??
              "",
          },
          { replace: true }
        );
        await sleep(100);
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster: item.clusterName ?? "",
          resourceType: KubernetesNodesResource.Kind,
          resourceName: item.name,
        });
      },
    },
    deployment: komodorServiceItem(
      SERVICES,
      <Applications16 width={16} height={14} />
    ),
    statefulset: komodorServiceItem(
      SERVICES,
      <Applications16 width={16} height={14} />
    ),
    daemonset: komodorServiceItem(
      SERVICES,
      <Applications16 width={16} height={14} />
    ),
    rollout: komodorServiceItem(
      SERVICES,
      <Applications16 width={16} height={14} />
    ),
    job: komodorServiceItem(JOBS, <Boxes16 width={16} height={14} />),
    cronjob: komodorServiceItem(JOBS, <Boxes16 width={16} height={14} />),
    pod: {
      icon: (
        <SmallIcon>
          <Pod16 width={13} height={13.71} />
        </SmallIcon>
      ),
      content: (item) => {
        return (
          <ItemWithBreadcrumbs
            breadcrumbs={[item.clusterName ?? "", item.namespace ?? ""]}
          >
            <DeletedResourceNameContainer
              aria-label={AriaLabels.GlobalSearch.ItemTitleContainer}
            >
              {item.name}
              {item.isDeleted && item.deletedAt && (
                <>
                  <Status
                    label={`Deleted (${daysHumanDurationLastTwoUnits(
                      new Date(item.deletedAt)
                    )} ago)`}
                    color={"neutral"}
                  />
                </>
              )}
            </DeletedResourceNameContainer>
          </ItemWithBreadcrumbs>
        );
      },
      onClick: async (item) => {
        onClose();
        closeAllDrawers(true);
        navigate(
          {
            pathname:
              pages[PageName.Pods].urlWithCluster?.(item.clusterName ?? "") ??
              "",
          },
          { replace: true }
        );
        if (item.isDeleted && !item.deletedPodId) {
          return;
        }
        await sleep(100);
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster: item.clusterName ?? "",
          namespace: item.namespace ?? "",
          resourceType: item.isDeleted
            ? DELETED_POD_KIND
            : KubernetesPodsResource.Kind,
          buildPreloadResource: item.isDeleted,
          additionalData:
            item.isDeleted && item.deletedPodId
              ? {
                  id: item.deletedPodId,
                  calculatedStatus: "Deleted",
                }
              : undefined,
          resourceName: item.name,
        });
      },
    },
    workspace: (setWorkspaceId: (appViewId: string | undefined) => void) => ({
      icon: <SpaceDashboardOutlined sx={{ fontSize: "16px" }} />,
      content: (item) => item.name,
      onClick: (item) => {
        onClose();
        setWorkspaceId(item.id);
        navigate(getOverviewPageRoute(item.id));
      },
    }),
    cluster: (setWorkspaceId: (appViewId: string | undefined) => void) => ({
      icon: (
        <SmallIcon>
          <Kubernetes16 />
        </SmallIcon>
      ),
      content: (item) => item.name,
      onClick: (item) => {
        onClose();
        const workspaceId = `cluster-${item.name}`;
        setWorkspaceId(workspaceId);
        navigate(
          pages[PageName.Overview].locked
            ? pages[PageName.Services].url
            : getOverviewPageRoute(workspaceId)
        );
      },
    }),
    workflow: {
      icon: (
        <SmallIcon>
          <Workflows16 width={13} height={13.71} />
        </SmallIcon>
      ),
      content: (item) => (
        <ItemWithBreadcrumbs
          breadcrumbs={[item.clusterName ?? "", item.namespace ?? ""]}
        >
          {item.name}
        </ItemWithBreadcrumbs>
      ),
      onClick: (item) => {
        onClose();
        navigate(
          pages[PageName.Workflows].locked || !item.workflow
            ? pages[PageName.Services].url
            : getWorkflowUrl(item.workflow.komodorWorkflowId)
        );
      },
    },
    ["workflow-run"]: {
      icon: (
        <SmallIcon>
          <Workflows16 width={13} height={13.71} />
        </SmallIcon>
      ),
      content: (item) => (
        <ItemWithBreadcrumbs
          breadcrumbs={[
            item.clusterName ?? "",
            item.namespace ?? "",
            item.workflowRun?.dagId ?? "",
          ]}
        >
          <Typography
            variant="h5"
            display="block"
            sx={{
              flexGrow: 1,
              overflowX: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {item.name}
          </Typography>
        </ItemWithBreadcrumbs>
      ),
      onClick: (item) => {
        onClose();
        navigate(
          pages[PageName.Workflows].locked || !item.workflowRun
            ? pages[PageName.Services].url
            : `${getWorkflowUrl(
                item.workflowRun.komodorWorkflowId
              )}?${WORKFLOW_RUN_PARAM_KEY}=${item.workflowRun.lastRunRunId}`
        );
      },
    },
  };
};
