import { useMemo } from "react";

import { useUpdatedDeployInsight } from "../latestDeploysInsight/useLatestDeploysInsight";
import { DeployIssueDataParsed } from "../../common/types";

export const useRecentLimitDecreaseInsight = (
  limitDecreaseInsight: DeployIssueDataParsed | null
) => {
  const vars = useMemo(
    () => (limitDecreaseInsight ? [limitDecreaseInsight] : []),
    [limitDecreaseInsight]
  );
  const limitDecreaseInsightUpdated = useUpdatedDeployInsight(vars)[0];

  return {
    shouldShow: !!limitDecreaseInsightUpdated,
    text: "Correlated recent limit decrease was found prior the issue",
    limitDecreaseInsightUpdated,
  };
};
