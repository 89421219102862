import { useCallback, useState } from "react";

interface DrawerData {
  resourceType: string;
  resourceName: string;
}

interface OpenResourceDrawerIfNeededParams {
  actualResourceKind: string;
  targetResourceKind: string;
  resourceName: string;
}

interface UseReferenceResourceDrawerDataResult {
  resourceDrawerData: DrawerData | undefined;
  openResourceDrawerIfNeeded: (args: OpenResourceDrawerIfNeededParams) => void;
  clearResourceDrawerData: () => void;
}

export const useReferenceResourceDrawerData =
  (): UseReferenceResourceDrawerDataResult => {
    const [resourceDrawerData, setResourceDrawerData] = useState<
      DrawerData | undefined
    >();

    const openResourceDrawerIfNeeded = useCallback(
      ({
        actualResourceKind,
        resourceName,
        targetResourceKind,
      }: OpenResourceDrawerIfNeededParams) => {
        if (actualResourceKind === targetResourceKind) {
          setResourceDrawerData({
            resourceType: targetResourceKind,
            resourceName,
          });
        }
      },
      []
    );

    const clearResourceDrawerData = useCallback(
      () => setResourceDrawerData(undefined),
      []
    );

    return {
      resourceDrawerData,
      openResourceDrawerIfNeeded,
      clearResourceDrawerData,
    };
  };
