import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useActiveAgents } from "../../../shared/hooks/useAgents";
import { useFilterClusterNamesByAppViews } from "../../../shared/hooks/appViews/appViewFilterHooks";

const useClustersAsOptionsType = (): MuiSelectionOption<string>[] => {
  const activeAgents = useActiveAgents();
  const filterClusterNamesByAppViews = useFilterClusterNamesByAppViews();
  return useMemo(() => {
    const clusterNames = activeAgents.map(({ clusterName }) => clusterName);
    return filterClusterNamesByAppViews(clusterNames).map((clusterName) => ({
      label: clusterName,
      value: clusterName,
    }));
  }, [activeAgents, filterClusterNamesByAppViews]);
};

type FilteredClustersReturnType = {
  filteredClusterSuggestions: MuiSelectionOption<string>[];
  clusterToUse: string;
};

export const useFilteredClusters = (
  cluster: string
): FilteredClustersReturnType => {
  const filteredClusterSuggestions = useClustersAsOptionsType();
  const clusterToUse = useMemo(() => {
    const clusterExistsInOptions = filteredClusterSuggestions.find(
      ({ value }) => value === cluster
    );
    return clusterExistsInOptions || !filteredClusterSuggestions.length
      ? cluster
      : filteredClusterSuggestions[0].value;
  }, [cluster, filteredClusterSuggestions]);

  return {
    filteredClusterSuggestions,
    clusterToUse,
  };
};
