import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";

export const AgentDrawerKeyValue: React.FC<{
  title: string;
  value: string;
  ValueComponent?: React.ReactNode;
}> = ({ title, value, ValueComponent }) => {
  return (
    <Grid container direction={"row"} columnGap={2} alignItems="end">
      <Grid item sx={{ marginLeft: 0, width: "160px" }}>
        <Typography variant="body2" color={muiTheme.palette.text.secondary}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        {ValueComponent ? (
          ValueComponent
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
