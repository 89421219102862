import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { unreadMessagesCountSelector } from "../store/intercomEffectsStore/intercomEffectsSelectors";
import { useIntercomEffectsStore } from "../store/intercomEffectsStore/intercomEffectsStore";
import { useOverridableFlags } from "../context/featureFlags/OverridableFlags";

export const useIntercomCustomEffects = () => {
  const unreadMessagesCount = useIntercomEffectsStore(
    unreadMessagesCountSelector
  );
  const { autoOpenIntercomOnUnreadMessage } = useOverridableFlags();
  const { showMessages } = useIntercom();

  useEffect(() => {
    if (autoOpenIntercomOnUnreadMessage && unreadMessagesCount > 0) {
      showMessages();
    }
  }, [autoOpenIntercomOnUnreadMessage, showMessages, unreadMessagesCount]);
};
