import {
  Code,
  TextContainer,
  TextElementContainer,
} from "../../../violationDrawerStyles";

export const getWhatDescription = () => (
  <TextElementContainer>
    <TextContainer>Komodor saw that the</TextContainer>
    <Code>spec.topologySpreadConstraints</Code>
    <TextContainer>is not configured.</TextContainer>
  </TextElementContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Without topology spread constraints, pods might be scheduled on the same
    node or zone, increasing the risk of a SPOF.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Define topology spread constraints to distribute pods across different nodes
    or zones, enhancing fault tolerance.
  </TextContainer>
);

export const yamlExample = `apiVersion: v1
kind: Pod
metadata:
  name: example-pod
spec:
  # Configure a topology spread constraint
  topologySpreadConstraints:
    - maxSkew: <integer>
      minDomains: <integer> # optional
      topologyKey: <string>
      whenUnsatisfiable: <string>
      labelSelector: <object>
      matchLabelKeys: <list> # optional; beta since v1.27
      nodeAffinityPolicy: [Honor|Ignore] # optional; beta since v1.26
      nodeTaintsPolicy: [Honor|Ignore] # optional; beta since v1.26
  ### other Pod fields go here`;
