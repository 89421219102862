/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Tag,
  Typography,
} from "@komodorio/design-system/deprecated";
import { theme } from "@komodorio/design-system";
import {
  ActionMetadataEditResource,
  ActionTypes,
  TaskType,
} from "komodor-types";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import pluralize from "pluralize";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import {
  extractPathFromLocation,
  isFaultyResultMessage,
} from "../../common/utils";
import { LinesLoader } from "../../../common/loaders/Line";
import { ErrorMessage } from "../../common/ErrorMessage";
import { Result, WIDTH } from "../../common/styles";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import { useFindService } from "../../../../shared/hooks/useFindService";

import {
  cleanResourceYaml,
  getErrorMessage,
  validateYaml,
  yamlToJson,
} from "./utils";
import DiscardDialog from "./DiscardDialog";

import { LazyEditor } from "@/components/common/LazyEditor";

const HEIGHT = "30.5rem";

const Loader = styled(LinesLoader)`
  margin-block-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${WIDTH};
  height: ${HEIGHT};
`;

const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-inline: 2rem;
`;

interface EditModalProps {
  open: boolean;
  handleClose: () => void;
  agentId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  afterEffect?: () => void;
}

const EditModal: React.FC<EditModalProps> = ({
  open,
  handleClose,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  afterEffect,
}) => {
  const location = useLocation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [value, setValue] = useState("");
  const [originalYaml, setOriginalYaml] = useState("");
  const [getWasSent, setGetWasSent] = useState(false);
  const [actionSent, setActionSent] = useState(false);
  const [yamlErrorMessage, setYamlErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const service = useFindService({
    value: resourceName,
    clusterName: cluster,
    pageName: resourceType,
    namespace,
  });

  const metadata: ActionMetadataEditResource = {
    namespace: namespace,
    cluster: cluster,
    serviceId: service?.id ?? "",
    type: ActionTypes.EditResource,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    data: {
      oldJson: yamlToJson(originalYaml),
      newJson: yamlToJson(value),
      preparedJson: value,
    },
  };

  const {
    execute: editResource,
    failureMessage: editFailureMessage,
    deniedObject: editDeniedObject,
    result: editResult,
    resetAgentTask: resetEditResource,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const {
    execute: getResource,
    failureMessage: getFailureMessage,
    deniedObject: getDeniedObject,
    result: getResult,
    resetAgentTask: resetGetResource,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, {
    namespace: namespace,
    cluster: cluster,
    type: ActionTypes.GetResource,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    usedForOtherAction: `edit:${resourceType}`,
  });

  const clearState = () => {
    resetGetResource();
    resetEditResource();
    setShowErrorMessage(false);
    setValue("");
    setOriginalYaml("");
    setGetWasSent(false);
    setActionSent(false);
    setYamlErrorMessage("");
    setErrorMessage("");
  };

  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName, resourceType, namespace, cluster, agentId]);

  useEffect(() => {
    if (getDeniedObject || editDeniedObject) {
      setShowErrorMessage(true);
      setActionSent(false);
    }
  }, [editDeniedObject, getDeniedObject]);

  useEffect(() => {
    if (!getWasSent) {
      getResource();
      setGetWasSent(true);
    }
  }, [getResource, getWasSent]);

  const setNewYamlValue = useCallback((val: string) => {
    const cleanYaml = cleanResourceYaml(val);
    setOriginalYaml(cleanYaml);
    setValue(cleanYaml);
  }, []);

  useEffect(() => {
    if (getResult && !value) {
      setNewYamlValue(getResult as string);
    }
  }, [getResult, setNewYamlValue, value]);

  useEffect(() => {
    if (editResult && actionSent) {
      if (isFaultyResultMessage(editResult as string)) {
        setErrorMessage(editResult as string);
        setActionSent(false);
      } else {
        afterEffect?.();
        handleClose();
        setActionSent(false);
        setNewYamlValue(value);
      }
    }
  }, [
    actionSent,
    afterEffect,
    editResult,
    getResult,
    handleClose,
    setNewYamlValue,
    value,
  ]);

  const analytics = useAnalyticsApi();
  const handleSave = () => {
    try {
      validateYaml(originalYaml, value);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      return;
    }
    if (yamlErrorMessage) {
      setErrorMessage(yamlErrorMessage);
      setYamlErrorMessage("");
      return;
    }
    editResource();
    setActionSent(true);
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: "editResource",
        path: extractPathFromLocation(location),
        resourceType: resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  };

  const isTextEdited = originalYaml !== value;
  const closeModalText = isTextEdited ? "Discard changes" : "Cancel";

  const handleCloseWithoutSave = () => {
    if (isTextEdited) {
      setOpenDialog(true);
    } else {
      handleClose();
    }
  };

  const errorOutput = errorMessage || editFailureMessage || getFailureMessage;

  return (
    <Modal isOpen={open} onRequestClose={handleCloseWithoutSave}>
      <ModalHeader>
        <Typography variant="headline">
          Edit {resourceType}/{resourceName}
        </Typography>
      </ModalHeader>
      <Tags>
        <Tag>
          <Typography>
            Cluster: <b>{cluster}</b>
          </Typography>
        </Tag>
        <Tag>
          <Typography>
            Namespace: <b>{namespace}</b>
          </Typography>
        </Tag>
      </Tags>
      <ModalContent>
        {!value ? (
          <Loader />
        ) : (
          <LazyEditor
            width={WIDTH}
            height={HEIGHT}
            value={value}
            onChange={(v) => setValue(v)}
          />
        )}
        {errorOutput ? (
          <Result>
            <Typography color={theme.foreground.fgPink}>
              {errorOutput}
            </Typography>
          </Result>
        ) : null}
        {showErrorMessage && getDeniedObject !== undefined && (
          <ErrorMessage reason={getDeniedObject} />
        )}
        {showErrorMessage && editDeniedObject !== undefined && (
          <ErrorMessage reason={editDeniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleCloseWithoutSave}>
          {closeModalText}
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!!getFailureMessage || !isTextEdited || actionSent}
        >
          {actionSent ? "Applying..." : "Apply"}
        </Button>
      </ModalActions>
      <DiscardDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleDiscard={() => {
          setOpenDialog(false);
          setValue(originalYaml);
          handleClose();
        }}
      />
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EditModal;
