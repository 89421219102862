import React from "react";
import Button from "@mui/material/Button";
import MenuBook from "@mui/icons-material/MenuBook";

import { FeaturePreviewPage } from "@/shared/components/FeaturePreviewPage";
import workflowTemplateUrl from "@/components/argoWorkflowsView/assets/workflowTemplate.svg";
import { WORKFLOWS_DOCS_LINK } from "@/shared/constants/docsLinks";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const WorkflowsEmptyState: React.FC = () => {
  return (
    <FeaturePreviewPage
      title="Workflows"
      bullets={[
        "Simply connect your existing workflows engine",
        "See all your workflows in one place",
        "Understand issues in past runs",
        "Correlate node issues to workflow failures",
      ]}
      img={
        <img src={workflowTemplateUrl} width="100%" alt="Workflow Template" />
      }
      action={
        <Button
          startIcon={<MenuBook />}
          href={WORKFLOWS_DOCS_LINK}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={AriaLabels.Workflows.DocsButton}
        >
          Go to the documentation
        </Button>
      }
    >
      Effortlessly manage and monitor all your workflows in one centralized hub
      in Komodor. Whether you are using Argo Workflows, Airflow, Kubeflow or
      MLFlow. You can now gain full visibility and seamlessly troubleshoot
      Kubernetes issues to improve across your stack efficiency and reliability
    </FeaturePreviewPage>
  );
};
