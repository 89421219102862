import React, { useMemo } from "react";
import { TooltipProps } from "recharts/types/component/Tooltip";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { format } from "date-fns";

import { formatNumber } from "../../../../../../../shared/utils/formatNumber";
import { CustomTooltipContainer } from "../../shared/graph/TooltipContainer";

import { metricsUnits } from "./utils";

interface CustomTooltipProps {
  data: TooltipProps<ValueType, NameType>;
  unit: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ data, unit }) => {
  const tooltipTitle = useMemo(
    () => (data.label ? format(new Date(data.label), "MMM d") : ""),
    [data.label]
  );
  if (!data.payload) return null;

  const getValue = (value: number) => {
    return `${formatNumber(value)}${unit === metricsUnits.score ? "%" : ""}`;
  };

  const getLabel = (label: string) => unit;

  return (
    <CustomTooltipContainer
      payload={data.payload}
      labelFormat={getLabel}
      valueFormat={getValue}
      tooltipTitle={tooltipTitle}
    />
  );
};
