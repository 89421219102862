import Typography from "@mui/material/Typography";
import { muiColors, muiPalette } from "@komodorio/design-system";
import styled from "styled-components";

import { DetailsContainer, ResultLineContainer, ResultTitle } from "./styles";

import { StrictExternalLink } from "@/components/common/Link/ExternalLink";

const FeedbackContainer = styled(DetailsContainer)`
  border-top: 1px solid ${muiPalette.divider};
  padding-top: 10px;
`;

export const Feedback: React.FC = () => {
  return (
    <ResultLineContainer>
      <ResultTitle />
      <FeedbackContainer>
        <Typography fontSize="14px">
          Was this helpful? share your{" "}
          <StrictExternalLink
            href="https://forms.gle/CX5SkttsH5rj2RJV8"
            color={muiColors.blue[600]}
            style={{ textDecoration: "none" }}
          >
            feedback
          </StrictExternalLink>
        </Typography>
      </FeedbackContainer>
    </ResultLineContainer>
  );
};
