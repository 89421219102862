import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

type InputAdornmentWithTypographyProps = { value: string };

export const InputAdornmentWithTypography: React.FC<
  InputAdornmentWithTypographyProps
> = ({ value }) => {
  return (
    <InputAdornment position="end">
      <Typography color={muiColors.gray[400]} variant={"body2"}>
        {value}
      </Typography>
    </InputAdornment>
  );
};
