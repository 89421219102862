import React from "react";
import Drawer from "@mui/material/Drawer";
import { muiTheme } from "@komodorio/design-system";

import { ariaLabels } from "@/components/Settings/Users/const";

interface UserPermissionsDrawerProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const UserPermissionsDrawer: React.FC<UserPermissionsDrawerProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: "60vw",
          height: "100%",
          backgroundColor: muiTheme.palette.background.default,
        },
        "aria-label": ariaLabels.userPermissionsDrawer.container,
      }}
    >
      {children}
    </Drawer>
  );
};
