import { useCallback, useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { KubernetesServiceResource } from "../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

import { useActiveAgent } from "./useAgents";
import { useRelatedServicesFilter } from "./relatedServices";
import useAtmResourcesListLiveResult from "./ATM/useAtmResourcesListLiveResult";
import { Status } from "./ATM/useAtmRequest";
import { AtmResourceListResponseType } from "./ATM/requests/useAtmListResources";

type K8sResourcesParams = {
  cluster: string;
  serviceId?: string;
  namespace?: string;
};

type K8sResourcesPayload = {
  result?: AtmResourceListResponseType;
  isLoading: boolean;
  rows: ResourceTableModelRow[];
  refresh: () => void;
};

export const useK8sResources = ({
  cluster,
  serviceId,
  namespace,
}: K8sResourcesParams): K8sResourcesPayload => {
  const agentId = useActiveAgent(cluster);
  const {
    result,
    refresh: refreshFromAtm,
    status: serivcesQueryStatus,
  } = useAtmResourcesListLiveResult({
    resourceKind: KubernetesServiceResource.Kind,
    agentId: agentId ?? "",
    cluster,
  });

  const { rows, refresh: refreshFromDeploy } = useRelatedServicesFilter(
    serviceId,
    cluster,
    namespace,
    result?.data ?? []
  );

  const refresh = useCallback(() => {
    refreshFromAtm();
    refreshFromDeploy();
  }, [refreshFromAtm, refreshFromDeploy]);

  const isLoading =
    serivcesQueryStatus === Status.Initializing ||
    (!!result?.isPaginating && rows.length === 0);

  return useMemo(
    () => ({
      result,
      isLoading,
      rows,
      refresh,
    }),
    [isLoading, refresh, result, rows]
  );
};
