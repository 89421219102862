import { Kubernetes } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";
import { KubernetesResource } from "./KubernetesResource";

export const KubernetesIngressResource: KubernetesResource = {
  NameInK8S: "ingresses",
  Group: "network",
  NavBarName: "Ingresses",
  PresentName: "Ingresses",
  Namespaced: true,
  UnnecessaryColumns: ["Class"],
  SupportedActions: [AvailableActions.Delete],
  Kind: "Ingress",
  Icon: Kubernetes,
  Headers: [
    { name: "Age" },
    { name: "Namespace" },
    { name: "Name" },
    { name: "Hosts" },
    { name: "Ports" },
    { name: "Address" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  hasLabels: true,
};
