// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import prettyBytes from "pretty-bytes";

type ExtractedQuantity = {
  value: string;
  unit: string;
};

export const extractQuantity = (quantity: string): ExtractedQuantity => {
  const split = quantity.split(/(\d+)(\w*)/);
  const [, value, unit] = split;
  return {
    value,
    unit,
  };
};

export const getQuantityInBytes = (
  extractedQuantity: ExtractedQuantity
): number => {
  if (!extractedQuantity.unit) {
    return parseInt(extractedQuantity.value);
  }
  switch (extractedQuantity.unit) {
    case "Ki":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 1);
    case "Mi":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 2);
    case "Gi":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 3);
    case "Ti":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 4);
    case "Pi":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 5);
    case "Ei":
      return parseInt(extractedQuantity.value) * Math.pow(1024, 6);
    case "m":
      return parseInt(extractedQuantity.value) / 1000;
    case "k":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 1);
    case "M":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 2);
    case "G":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 3);
    case "T":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 4);
    case "P":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 5);
    case "E":
      return parseInt(extractedQuantity.value) * Math.pow(1000, 6);
    default:
      return parseInt(extractedQuantity.value);
  }
};

export const formatQuantityWithPrettyBinaryUnits = (
  quantity: string
): string => {
  const extractedQuantity = extractQuantity(quantity);
  const bytes = getQuantityInBytes(extractedQuantity);
  if (isNaN(bytes)) {
    return quantity;
  }
  return prettyBytes(bytes, { binary: true });
};

export const formatQuantityWithoutUnits = (quantity: string): string => {
  const extractedQuantity = extractQuantity(quantity);
  return String(getQuantityInBytes(extractedQuantity));
};

export const formatQuantityToGB = (quantity: string): string => {
  const extractedQuantity = extractQuantity(quantity);
  const bytes = getQuantityInBytes(extractedQuantity);
  const gb = bytes / Math.pow(1024, 3);
  return `${gb.toFixed(0)}`;
};
