import { get, uniq } from "lodash";

import { getCardTitleDescription } from "../appView/sections/AppView/components/OverviewPage/Insights/insightsCards/ThrottledApplications/ThrottledApplicationsTexts";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../ResourceView/resources/deployment";
import DaemonSet from "../ResourceView/resources/daemonset";
import StatefulSet from "../ResourceView/resources/statefulset";

const NO_DATA = "no data for that insight";

export interface InsightDetailsContentProps {
  insightData: unknown;
  resource: Deployment | DaemonSet | StatefulSet;
}

export function throttledCpuDescription(insightData?: object): string {
  const containers: unknown[] = get(insightData, "containers", []);
  const containersNames = uniq(
    containers.map((container) => get(container, "container", ""))
  );
  if (!containersNames.length) {
    return NO_DATA;
  }

  return getCardTitleDescription(containersNames.length);
}

export function issuesAfterDeployWhatHappened(insightData?: object): string {
  const currentIssuesCount = get(insightData, "count_current", 0);
  const normalIssuesCount = Math.floor(get(insightData, "count_norm", 0));
  const numberOfDays = get(insightData, "num_days", 0);
  if (!currentIssuesCount || !numberOfDays) {
    return NO_DATA;
  }

  return `The total count of Availability Issues today spiked to ${currentIssuesCount}, while the average number of such issues in the last ${numberOfDays} day${
    numberOfDays > 0 ? "s" : ""
  } was ${normalIssuesCount}.`;
}

export function deprecatedApisDescription(insightData?: object): string {
  const kind = get(insightData, "kind", "");
  if (!kind) {
    return NO_DATA;
  }
  return `The ${kind} API will be deprecated in 23 days`;
}

export function deprecatedApisWhatHappened(insightData?: object): string {
  const kind = get(insightData, "kind", "");
  if (!kind) {
    return NO_DATA;
  }
  return `The API for the ${kind} is deprecated and is no longer be supported `;
}
