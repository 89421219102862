import { useMutation, useQuery } from "@tanstack/react-query";

import {
  DeleteIgnoreRulesResponse,
  deleteIgnoreRuleUrl,
  GetAllIgnoreRulesPerChecksResponse,
  getIgnoreRulesPerCheckTypesUrl,
  IgnoreRulesApiDeleteIgnoreRuleRequest,
  IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest,
  IgnoreRulesApiInsertIgnoreRuleRequest,
  IgnoreRulesApiUpdateIgnoreRuleRequest,
  InsertIgnoreRulesResponse,
  insertIgnoreRuleUrl,
  updateIgnoreRuleUrl,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";

export const GET_CHECKS_IGNORE_RULES_PATH = "/api/v1/checks/ignore-rules";
export const UPDATE_CHECKS_IGNORE_RULES_PATH =
  "/api/v1/checks/ignore-rules/:id";

export const useGetIgnoreRulesPerChecks = (
  params: IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest,
  enable?: boolean
) => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [GET_CHECKS_IGNORE_RULES_PATH, params.checkType],
    async () =>
      await apiClient.get<GetAllIgnoreRulesPerChecksResponse>(
        getIgnoreRulesPerCheckTypesUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    { enabled: enable }
  );
};

export const useInsertIgnoreRule = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [GET_CHECKS_IGNORE_RULES_PATH],
    async (params: IgnoreRulesApiInsertIgnoreRuleRequest) =>
      await apiClient.post<InsertIgnoreRulesResponse>(
        insertIgnoreRuleUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.body }
      )
  );
};

export const useUpdateIgnoreRule = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [UPDATE_CHECKS_IGNORE_RULES_PATH],
    async (params: IgnoreRulesApiUpdateIgnoreRuleRequest) =>
      await apiClient.put<InsertIgnoreRulesResponse>(
        updateIgnoreRuleUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.body }
      )
  );
};

export const useDeleteIgnoreRule = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [UPDATE_CHECKS_IGNORE_RULES_PATH],
    async (params: IgnoreRulesApiDeleteIgnoreRuleRequest) =>
      await apiClient.delete<DeleteIgnoreRulesResponse>(
        deleteIgnoreRuleUrl(params, apiClient.defaults.baseURL ?? "")
      )
  );
};
