import React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import ReadMore from "@mui/icons-material/ReadMore";
import { Pencil16, Reload16 } from "@komodorio/design-system/icons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { User } from "@/generated/auth";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { UserRow, usersTableActions } from "@/components/Settings/Users/types";
import { ariaLabels } from "@/components/Settings/Users/const";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DELETE_ICON_STYLE } from "@/components/Settings/constants";

type ActionsCellProps = {
  params: UserRow;
} & usersTableActions;

export const ActionsCell: React.FC<ActionsCellProps> = ({
  params,
  onEdit,
  onRestore,
  onDelete,
  onViewPermissions,
}) => {
  const { effectivePermissions } = useOverridableFlags();
  const { canManageUsers } = useHasPermissions();
  const { userId } = useUserMetadata();

  const actions = React.useMemo(() => {
    const disabled = params.id === userId;
    if (params.row.deletedAt) {
      return [
        <Tooltip
          title="Restore user"
          key="restore-user"
          componentsProps={lightMuiTooltipStyle}
        >
          <IconButton
            aria-label={ariaLabels.table.actionsCell.restore}
            disabled={disabled}
            onClick={() => onRestore(params.row as User)}
          >
            <Reload16 />
          </IconButton>
        </Tooltip>,
      ];
    }

    return [
      ...(effectivePermissions
        ? [
            <Tooltip
              title="View Permissions"
              componentsProps={lightMuiTooltipStyle}
              key="view-permissions"
            >
              <IconButton
                aria-label={ariaLabels.table.actionsCell.viewPermissions}
                onClick={() => onViewPermissions(params.row as User)}
              >
                <ReadMore height={16} width={16} />
              </IconButton>
            </Tooltip>,
          ]
        : []),
      <Tooltip
        title="Edit User"
        componentsProps={lightMuiTooltipStyle}
        key="edit-user"
      >
        <IconButton
          aria-label={ariaLabels.table.actionsCell.edit}
          onClick={() => onEdit(params.row as User)}
        >
          <Pencil16 />
        </IconButton>
      </Tooltip>,
      <Tooltip
        title="Delete User"
        key="delete-user"
        componentsProps={lightMuiTooltipStyle}
      >
        <IconButton
          aria-label={ariaLabels.table.actionsCell.delete}
          color="error"
          onClick={() => onDelete(params.row as User)}
          disabled={disabled}
        >
          <DeleteOutlineIcon sx={DELETE_ICON_STYLE} fontSize="small" />
        </IconButton>
      </Tooltip>,
    ];
  }, [
    onDelete,
    onEdit,
    onRestore,
    params,
    userId,
    onViewPermissions,
    effectivePermissions,
  ]);

  if (!canManageUsers) {
    return null;
  }

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      aria-label={ariaLabels.table.actionsCell.container}
    >
      {params.row.hovered && <Stack direction="row">{actions}</Stack>}
    </Stack>
  );
};
