import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";

import CardWithTag from "../../../common/Card/CardWithTag";
import { TrendsContainer } from "../common/styles";
import Metadata from "../common/Metadata";
import useResourceWithInterval from "../../../ResourceView/useResource";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import {
  issueSelector,
  komodorServiceSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";

import { NoisyPod } from "./types";
import { Container, DetailsContainer, FlexContainer } from "./styles";
const MemoryUsageExplanation =
  "The maximum share of the node's allocatable memory used by this Pod during the 5 minutes leading up to the issue.";
const PriorityExplanation =
  "Priority indicates the importance of a Pod relative to other Pods. Pods with a lower score may be evicted to free up capacity for higher priority Pods. Priority scores are determined by the PriorityClass defined on the workload.";

const QosBestEffort =
  "Kubernetes automatically sets 3 levels of availability guarantees based on the resource requests and limits in a Pod. BestEffort has no predetermined resource limits so it may consume a significant amount, which could lead it to become a ‘noisy neighbor’.";
const QosBurstable =
  "Kubernetes automatically sets 3 levels of availability guarantees based on the resource requests and limits in a Pod. Burstable enables Pods to use more resources than they initially requested, which could lead it to become a ‘noisy neighbor’.";
export const StyledLi = styled.li`
  margin-left: 1rem;
`;
const NoisyPodRow: React.FC<{ pod: NoisyPod }> = ({ pod }) => {
  const issue = useInvestigationModeStore(issueSelector);
  const agentId = useActiveAgent(issue?.cluster ?? "") ?? "";
  const serviceResource = useInvestigationModeStore(komodorServiceSelector);

  const podParams = useMemo(() => {
    return {
      resourceType: "Pod",
      resourceName: pod.podName,
      cluster: issue?.cluster ?? "",
      namespace: pod.namespace,
      agentId,
    };
  }, [pod.podName, pod.namespace, issue?.cluster, agentId]);
  const { resource: podResource } = useResourceWithInterval(podParams);
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const qosMessage = useMemo(() => {
    if (pod.qos === "BestEffort") {
      return QosBestEffort;
    }
    if (pod.qos === "Burstable") {
      return QosBurstable;
    }
    return undefined;
  }, [pod.qos]);
  return (
    <CardWithTag
      tagText={pod.isVictim ? "Victim" : undefined}
      preserveTagHeight={pod.isVictim}
      color={pod.isVictim ? muiColors.gray[600] : muiColors.orange[600]}
      shadow
    >
      <FlexContainer>
        <Typography
          variant="h4"
          onClick={
            podResource
              ? () =>
                  pushDrawer({
                    drawerType: DrawerType.ResourceDrawerByData,
                    ...podParams,
                  })
              : undefined
          }
          sx={{
            cursor: podResource ? "pointer" : "default",
            textDecoration: podResource ? "underline" : "none",
          }}
        >
          {pod.podName}
        </Typography>
        <Container>
          <DetailsContainer>
            {pod.rootOwnerKind && pod.rootOwnerName && (
              <Metadata
                showValueTooltip
                title={pod.rootOwnerKind}
                value={
                  pod.rootOwnerName === "OrphanPod"
                    ? "None (Orphan)"
                    : pod.rootOwnerName
                }
                fixedWidth
                onValueClick={
                  serviceResource
                    ? () =>
                        pushDrawer({
                          drawerType: DrawerType.ResourceDrawerByData,
                          cluster: serviceResource.cluster,
                          namespace: serviceResource.namespace,
                          resourceType: serviceResource.kind,
                          resourceName: serviceResource.name,
                        })
                    : undefined
                }
              />
            )}
            <Metadata
              showValueTooltip
              title="Namespace"
              value={pod.namespace}
              fixedWidth
            />
            <Metadata
              title="Memory usage"
              value={pod.hasMemory ? pod.usagePercent.toString() + "%" : "-"}
              boldValue
              tooltipMessage={MemoryUsageExplanation}
            />
            <Metadata
              title="Priority score"
              value={pod.priority.toString()}
              tooltipMessage={PriorityExplanation}
              boldValue
            />
            {pod.qos && (
              <Metadata
                title="QOS"
                value={pod.qos}
                tooltipMessage={qosMessage}
                boldValue
              />
            )}
          </DetailsContainer>
          {pod.trends && (
            <TrendsContainer>
              {pod.trends.noLimitsTrend && (
                <StyledLi>
                  <Typography variant="body2">
                    Container {pod.trends.noLimitsTrend.containerName} does not
                    have a memory limit
                  </Typography>
                </StyledLi>
              )}
              {pod.trends.usageExceedsRequestsTrend && (
                <StyledLi>
                  <Typography variant="body2">
                    Container{" "}
                    {pod.trends.usageExceedsRequestsTrend.containerName} was
                    using +
                    {pod.trends.usageExceedsRequestsTrend.usagePercentExceeded}{" "}
                    more than it's memory request
                  </Typography>
                </StyledLi>
              )}
            </TrendsContainer>
          )}
        </Container>
      </FlexContainer>
    </CardWithTag>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NoisyPodRow;
