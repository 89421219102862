import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { ViolationTableWithContext } from "../ViolationTableWithContext/ViolationTableWithContext";
import {
  ImpactGroupIdentifier,
  ViolationsAggregationGroup,
} from "../../../../../../../generated/reliabilityApi";
import { GroupByOptions } from "../../../../../ReliabilityTypes";
import { Severities } from "../Severities";
import { ImpactAggregations } from "../ImpactAggregations/ImpactAggregations";
import { ViolationsTableInternalState } from "../ViolationTableWithContext/context/ViolationsTableContext";
import { DependentViolations } from "../../DependentViolations";
import { GroupCard } from "../GroupCard";

import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { Dictionary } from "@/shared/types/Dictionary";

const StyledViolationTable = styled(ViolationTableWithContext)`
  margin-top: 16px;
`;

type Props = ViolationsAggregationGroup & {
  isOpen: boolean;
  title: string;
  onExpandClick: () => void;
  groupBy: GroupByOptions;
};

export const ViolationsGroupCard: React.FC<Props> = ({
  breakdowns,
  impactDataAggregations,
  id,
  title,
  isOpen,
  onExpandClick,
  groupBy,
}) => {
  const isBestPracticeTab = useIsBestPracticesTab();
  const [countDict, setCountDict] = useState<Dictionary<number> | undefined>(
    isBestPracticeTab
      ? breakdowns?.["hasDependentViolations"]
      : breakdowns?.["severity"]
  );

  const onViolationsTableStateChange = useCallback(
    ({ count }: ViolationsTableInternalState) => {
      if (count) {
        setCountDict(count);
      }
    },
    []
  );

  const showImpactAggregations = !!impactDataAggregations && !isBestPracticeTab;

  const summaryElement = isBestPracticeTab ? (
    <DependentViolations dependecies={countDict} />
  ) : (
    <Severities severities={countDict} />
  );

  const closedCardExtraContent = showImpactAggregations ? (
    <ImpactAggregations
      impactDataAggregations={impactDataAggregations}
      groupIdentifier={id}
    />
  ) : undefined;

  return (
    <GroupCard
      id={id}
      title={title}
      onExpandClick={onExpandClick}
      isOpen={isOpen}
      closeCardPaddingBottom={showImpactAggregations}
      summaryElement={summaryElement}
      additionalCardContent={closedCardExtraContent}
      cardContent={
        <StyledViolationTable
          uid={id}
          violationsGroupBy={groupBy}
          impactGroupIdentifier={id as ImpactGroupIdentifier}
          onInternalStateChange={onViolationsTableStateChange}
        />
      }
    />
  );
};
