import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { get, set } from "lodash";

import {
  InputFields,
  SubmitPolicyType,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import {
  BasePolicy,
  CheckType,
  PolicyConfigurations,
} from "@/generated/reliabilityApi";
import { useGetFetchedPolicyData } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetFetchedPolicyData";
import { useSubmitPolicy } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useSubmitPolicy";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import {
  severitiesList,
  supportedStaticCheckTypes,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { Dictionary } from "@/shared/types/Dictionary";
import { getCheckTypeEnabledField } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getCheckTypeEnabledField";

export const useTransformValuesAndSubmit = () => {
  const { getValues } = useFormContext<InputFields>();
  const { data } = useGetFetchedPolicyData();
  const submitPolicy = useSubmitPolicy();
  const transformValues = useTransformValues();

  return useCallback(
    (type: SubmitPolicyType) => {
      const policy: BasePolicy = {
        createdAt: data?.data.policy.createdAt ?? 0, // should be removed
        updatedAt: data?.data.policy.updatedAt ?? 0, // should be removed
        id: data?.data.policy.id ?? "", // should be removed
        ...getValues(),
        configurations: transformValues(),
        priority: Number(getValues("priority")),
      };

      submitPolicy(type, { policy });
    },
    [
      data?.data.policy.createdAt,
      data?.data.policy.id,
      data?.data.policy.updatedAt,
      getValues,
      submitPolicy,
      transformValues,
    ]
  );
};

const useTransformValues = () => {
  const { policyConfigurations } = usePolicyDrawerContext();
  const { getValues } = useFormContext<InputFields>();

  return useCallback((): PolicyConfigurations => {
    const values = getValues();
    const configurationsObj = Object.keys(policyConfigurations).reduce<
      Dictionary<object>
    >((acc, checkType) => {
      severitiesList.forEach((severity) => {
        const configurationFieldNames =
          getConfigurationFieldNamesByCheckTypeAsList(
            checkType as CheckType,
            severity
          );

        configurationFieldNames.forEach((configurationFieldName) => {
          const value = get(values, configurationFieldName);
          // only set value if it's not empty
          if (value !== "" && value !== undefined) {
            set(acc, configurationFieldName, value);
          }
        });
        const enabled = get(
          values,
          getCheckTypeEnabledField(checkType as CheckType)
        );
        set(acc, getCheckTypeEnabledField(checkType as CheckType), enabled);
      });

      return acc;
    }, {});

    populateStaticChecks(configurationsObj);

    return configurationsObj.configurations;
  }, [getValues, policyConfigurations]);
};

const populateStaticChecks = (definedConfigurations: Dictionary<object>) => {
  supportedStaticCheckTypes.forEach((checkType) => {
    if (!get(definedConfigurations, `configurations.${checkType}`)) {
      set(definedConfigurations, `configurations.${checkType}.enabled`, false);
    }
  });
};
