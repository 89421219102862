import React, { useState } from "react";
import styled from "styled-components";

import infraHealthPage from "../assets/Screen - Infrastructure Health.png";
import noisyNeighborDrawer from "../assets/Drawer - InfraHealth - Node pressure - Noisy Neighbor.png";
import nodeTerminationDrawer from "../assets/Drawer - Autoscaler - Termination Impact Drawer.png";
import certExpiredDrawer from "../assets/Drawer - Cert Expire.png";
import externalDNSDrawer from "../assets/External DNS Violation Drawer.png";
import clusterEOLDrawer from "../assets/Drawer - InfraHealth - Cluster Updrades - EOL.png";
import upgradeAPIDrawer from "../assets/Drawer - InfraHealth - Cluster Updrades - API.png";
import { ClickableArea, CloseButtonArea, RelativeContainer } from "../styles";

import { TopBar } from "./TopBar";
import { MockDrawer } from "./MockDrawer";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  top: ${({ top }) => top};
  width: 72.9%;
  height: 3%;
  left: 23.9%;
`;

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  width: 36px;
  height: 36px;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

const ClickableDrawerRow = styled(ClickableArea)`
  width: 95%;
  height: 4.5%;
  top: 49.5%;
  left: 2.5%;
`;

export const InfraHealthPage: React.FC = () => {
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondDrawerImgSrc, setSecondDrawerImgSrc] = useState<string | null>(
    null
  );

  return (
    <RelativeContainer>
      <TopBar page="infraHealth" />
      <img src={infraHealthPage} alt={"infra health page"} />
      <SelectableRow
        top={"38.7%"}
        onClick={() => setDrawerImgSrc(noisyNeighborDrawer)}
      />
      <SelectableRow
        top={"59.8%"}
        onClick={() => setDrawerImgSrc(nodeTerminationDrawer)}
      />
      <SelectableRow
        top={"62.8%"}
        onClick={() => setDrawerImgSrc(certExpiredDrawer)}
      />
      <SelectableRow
        top={"65.8%"}
        onClick={() => setDrawerImgSrc(externalDNSDrawer)}
      />
      <SelectableRow
        top={"87%"}
        onClick={() => setDrawerImgSrc(clusterEOLDrawer)}
      />
      <SelectableRow
        top={"90%"}
        onClick={() => setDrawerImgSrc(upgradeAPIDrawer)}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            top="20px"
            left="15px"
            onClick={() => setDrawerImgSrc(null)}
          />
        }
      >
        {drawerImgSrc === clusterEOLDrawer && (
          <ClickableDrawerRow
            onClick={() => setSecondDrawerImgSrc(upgradeAPIDrawer)}
          />
        )}
      </MockDrawer>
      <MockDrawer
        open={secondDrawerImgSrc !== null}
        imgSrc={secondDrawerImgSrc}
        onClose={() => setSecondDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            top="20px"
            left="15px"
            onClick={() => setSecondDrawerImgSrc(null)}
          />
        }
      />
    </RelativeContainer>
  );
};
