import React from "react";
import styled from "styled-components";

import EventDetailsSection from "../components/EventDetailsSection";
import filesChangedIcon from "../../assets/files-changed.svg";
import dockerFileIcon from "../../assets/docker.svg";
import documentFileIcon from "../../assets/document.svg";
import gradleFileIcon from "../../assets/gradle.svg";
import jsonFileIcon from "../../assets/json.svg";
import lockFileIcon from "../../assets/lock.svg";
import makeFileIcon from "../../assets/makefile.svg";
import nugetFileIcon from "../../assets/nuget.svg";
import pythonFileIcon from "../../assets/python.svg";
import helmFileIcon from "../../assets/helm.svg";
import xmlFileIcon from "../../assets/xml.svg";
import yamlFileIcon from "../../assets/yaml.svg";
import goModFileIcon from "../../assets/go-mod.svg";
import { TrackedFile, File, Commit } from "../../../../../shared/types/git";
import IntegrationLink from "../components/LinkSection/IntegrationLink";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
`;

const FileIcons: { [key: string]: string } = {
  "Chart.yaml": helmFileIcon,
  "values.yaml": helmFileIcon,
  "requirements.txt": pythonFileIcon,
  "build.gradle": gradleFileIcon,
  "go.mod": goModFileIcon,
  Dockerfile: dockerFileIcon,
  Makefile: makeFileIcon,
  Pipfile: pythonFileIcon,
};

const FileExtensionIcons: { [key: string]: string } = {
  yaml: yamlFileIcon,
  xml: xmlFileIcon,
  json: jsonFileIcon,
  lock: lockFileIcon,
  py: pythonFileIcon,
  nuspec: nugetFileIcon,
};

interface TrackedFilesPropsFromGitComp {
  commitDiff: Commit[] | undefined;
}

interface TrackedFilesProps {
  trackedFiles: Array<TrackedFile>;
}

// After converting all old data from 'gitComp' column to 'trackedFiles' can be deleted
const TrackedFilesSectionFromGitComp: React.FC<
  TrackedFilesPropsFromGitComp
> = ({ commitDiff }) => {
  if (!commitDiff?.some((commit) => commit.files?.length)) return null;
  return (
    <EventDetailsSection
      icon={filesChangedIcon}
      iconDimensions={{ height: "50%", width: "50%" }}
      title="Tracked Files"
      info="Komodor observes and highlights important file changes, such as dependency files, dockerfiles and infra files."
    >
      <Container>
        {getLastTrackedFiles(commitDiff).map((f) => (
          <IntegrationLink
            key={f.url}
            icon={
              FileIcons[f.filename] ??
              FileExtensionIcons[
                f.filename.slice(f.filename.lastIndexOf(".") + 1)
              ] ??
              documentFileIcon
            }
            title={f.full_path}
            href={f.url}
          />
        ))}
      </Container>
    </EventDetailsSection>
  );
};

export const TrackedFilesSection: React.FC<TrackedFilesProps> = ({
  trackedFiles,
}) => {
  return (
    <EventDetailsSection
      icon={filesChangedIcon}
      iconDimensions={{ height: "50%", width: "50%" }}
      title="Tracked Files"
      info="Komodor observes and highlights important file changes, such as dependency files, dockerfiles and infra files."
    >
      <Container>
        {trackedFiles?.flatMap((file) => (
          <IntegrationLink
            icon={
              FileIcons[file.filename] ??
              FileExtensionIcons[
                file.filename.slice(file.filename.lastIndexOf(".") + 1)
              ] ??
              documentFileIcon
            }
            key={file.url}
            title={file.full_path}
            href={file.url}
          />
        ))}
      </Container>
    </EventDetailsSection>
  );
};

const getLastTrackedFiles = (commitDiff: Commit[]): File[] => {
  const trackedFiles: File[] = [];
  const tempCommitDiff: Commit[] = JSON.parse(JSON.stringify(commitDiff));
  tempCommitDiff.reverse().forEach((commit) => {
    commit.files?.forEach((file) => {
      if (!trackedFiles.some((t) => t.full_path === file.full_path)) {
        trackedFiles.push(file);
      }
    });
  });
  return trackedFiles;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TrackedFilesSectionFromGitComp;
