import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import { DialogProps } from "@mui/material";

import {
  BasicViolation,
  IgnoreRule,
} from "../../../../../../generated/reliabilityApi";
import { useGetIgnoreRulesPerChecks } from "../../../../../../shared/hooks/reliability-api/violations/useChecksIgnoreRules";
import { useInsertNewIgnoreRule } from "../../../../hooks/ignoreRulesHooks";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { getCheckLabel } from "../../../../utils/getCheckLabel";

import { IgnoreCheckViolationFooter } from "./IgnoreCheckViolationFooter";
import { IgnoreCheckRuleOption } from "./ignoreCheckRuleContants";
import { OptionsFetchingState } from "./OptionsFetchingState";
import { IgnoreCheckViolationOptions } from "./IgnoreCheckViolationOptions";
import {
  createRuleFromOption,
  getFooterButtonsState,
} from "./ignoreRulesUtils";

const Container = styled.div`
  padding: 24px 40px;
  min-width: 600px;
`;

const OptionsContainer = styled.div`
  margin-top: 24px;
  min-height: 230px;
`;

const StyledTitle = styled(Typography).attrs({
  variant: "h3",
})`
  && {
    margin-bottom: 16px;
  }
`;

const { ignoreCheckDialogContainer: dialogContainerLabel } =
  reliabilityArialLabels.ignoreChecksDialog;

type IgnoreCheckViolationDialogProps = DialogProps & {
  violation: BasicViolation;
  onClose: () => void;
};

export const IgnoreCheckViolationDialog: React.FC<
  IgnoreCheckViolationDialogProps
> = ({ violation, onClose, open, ...dialogProps }) => {
  const { komodorUid, checkType } = violation;
  const checkLabel = getCheckLabel(checkType);
  const { isLoading } = useGetIgnoreRulesPerChecks(
    { checkType: [checkType] },
    open
  );

  const [selectedIgnoreRule, setSelectedIgnoreRule] = useState<
    IgnoreCheckRuleOption | undefined
  >();
  const [isUpdatingRule, setIsUpdatingRule] = useState(false);

  const insertNewIgnoreRule = useInsertNewIgnoreRule();

  const onCloseDrawer = useCallback(() => {
    setSelectedIgnoreRule(undefined);
    onClose();
  }, [onClose]);

  const onIgnore = async () => {
    if (!selectedIgnoreRule) {
      return onCloseDrawer();
    }

    const newRule: IgnoreRule = createRuleFromOption({
      komodorUid,
      selectedOption: selectedIgnoreRule,
      violationCheckType: violation.checkType,
    });

    setIsUpdatingRule(true);
    await insertNewIgnoreRule(newRule);
    setIsUpdatingRule(false);
    onCloseDrawer();
  };

  const footerButtonsState = getFooterButtonsState({
    isUpdatingRule,
    selectedIgnoreRuleOption: selectedIgnoreRule,
  });

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onCloseDrawer}
      {...dialogProps}
    >
      <Container aria-label={dialogContainerLabel}>
        <StyledTitle>Ignore</StyledTitle>
        <Typography variant={"body1"}>
          Ignore the violations for the check:
        </Typography>
        <Typography variant={"body1"}>
          <b>{checkLabel}</b>
        </Typography>
        <OptionsContainer>
          {isLoading ? (
            <OptionsFetchingState />
          ) : (
            <IgnoreCheckViolationOptions
              komodorUid={komodorUid}
              value={selectedIgnoreRule}
              onOptionSelected={setSelectedIgnoreRule}
            />
          )}
        </OptionsContainer>

        <IgnoreCheckViolationFooter
          onClose={onCloseDrawer}
          onIgnore={onIgnore}
          state={footerButtonsState}
        />
      </Container>
    </Dialog>
  );
};
