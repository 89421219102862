import { useCallback, useState } from "react";

import { KubernetesResource } from "../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const useRowSelection = (resourceTypes: KubernetesResource[]) => {
  const [selectedRowUidsByResourceType, setSelectedRowUidsByResourceType] =
    useState<Map<KubernetesResource, string[]>>(
      new Map(resourceTypes.map((resourceType) => [resourceType, []]))
    );

  const toggleRowSelection = useCallback(
    (resourceType: KubernetesResource, selectedRowUid: string) => {
      if (!selectedRowUid) {
        return;
      }
      setSelectedRowUidsByResourceType((prevSelectedRowUidsByResourceType) => {
        const selectedRowUids =
          prevSelectedRowUidsByResourceType.get(resourceType) ?? [];

        if (selectedRowUids.includes(selectedRowUid)) {
          return new Map([
            ...Array.from(prevSelectedRowUidsByResourceType),
            [
              resourceType,
              selectedRowUids.filter((rowName) => rowName !== selectedRowUid),
            ],
          ]);
        } else {
          return new Map([
            ...Array.from(prevSelectedRowUidsByResourceType),
            [resourceType, [...selectedRowUids, selectedRowUid]],
          ]);
        }
      });
    },
    []
  );

  const clearSelectionByResourceType = useCallback(
    (currentResourceType: KubernetesResource) => {
      setSelectedRowUidsByResourceType((prevSelectedRowUidsByResourceType) => {
        return new Map([
          ...Array.from(prevSelectedRowUidsByResourceType),
          [currentResourceType, []],
        ]);
      });
    },
    []
  );

  return {
    selectedRowUidsByResourceType,
    setSelectedRowUidsByResourceType,
    toggleRowSelection,
    clearSelectionByResourceType,
  };
};
