import React, { useEffect, useState } from "react";
import { muiColors } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Access24,
  Cost24,
  Group24,
  History24,
  Support24,
  UnlimitedClusters24,
} from "@komodorio/design-system/icons";
import styled from "styled-components";

import useUserMetadata from "../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";

import { CalendarIcon } from "./assets/CalendarIcon";

const ContentContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DetailItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ListIconWrapper = styled.div`
  background-color: ${muiColors.indigo[50]};
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
`;

const CalendarIconContainer = styled.div`
  margin-top: 3rem;
  padding-left: 3rem;
`;

const premiumAdvantages = [
  {
    icon: <Group24 fill={muiColors.indigo[500]} />,
    text: "Unlimited Users",
  },
  {
    icon: <UnlimitedClusters24 fill={muiColors.indigo[500]} />,
    text: "Unlimited Nodes & Clusters",
  },
  {
    icon: <History24 fill={muiColors.indigo[500]} />,
    text: "Full Events History",
  },
  {
    icon: <Access24 fill={muiColors.indigo[500]} />,
    text: "Access Control & Security",
  },
  {
    icon: <Cost24 fill={muiColors.indigo[500]} />,
    text: "Cost Optimization",
  },
  {
    icon: <Support24 fill={muiColors.indigo[500]} />,
    text: "24/7 Live support",
  },
];

export const TrialExpiredModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isKomodorAdmin } = useUserMetadata();
  useEffect(() => {
    dispatchEvent(AnalyticEvents.UserManagement.TrialEndedModalOpened);
  }, []);
  return (
    <Dialog
      open={isOpen}
      onClose={
        isKomodorAdmin
          ? () => {
              setIsOpen(false);
            }
          : undefined
      }
    >
      <DialogTitle>
        <Typography variant="h2">Your trial has expired</Typography>
        <Typography variant="body0" paddingTop={"1rem"}>
          But your Kubernetes journey hasn't! <br />
          When you’re ready to upgrade to a paid plan contact us to enjoy the
          full Komodor experience:
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ContentContainer>
          <DetailsContainer>
            {premiumAdvantages.map((advantage, index) => (
              <DetailItem key={index}>
                <ListIconWrapper>{advantage.icon}</ListIconWrapper>
                <Typography variant="body2">{advantage.text}</Typography>
              </DetailItem>
            ))}
          </DetailsContainer>
          <CalendarIconContainer>
            <CalendarIcon />
          </CalendarIconContainer>
        </ContentContainer>
      </DialogContent>
      <DialogActions style={{ columnGap: "0.5rem" }}>
        <Button
          onClick={() => {
            dispatchEvent(
              AnalyticEvents.UserManagement.TrialEndedModalContactUsButtonClick
            );
          }}
          color="primary"
          variant="contained"
          style={{ fontSize: "14px" }}
          href="https://komodor.chilipiper.com/book/account-owner1"
        >
          Contact Us
        </Button>
      </DialogActions>
    </Dialog>
  );
};
