import React, { useContext, useEffect, useMemo, useState } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { getAppConfig } from "../config/appConfig";
import {
  useInitDatadogRumAnalytics,
  useInithubspotForm,
  useInitSegmentAnalytics,
} from "../hooks/analytics";
import { identifyTypes } from "../types/identifyTypes";
import { useUserLoginInfo } from "../hooks/useUserMetadata/useUserLoginInfo";
import { useHasPermissions } from "../hooks/useUserMetadata/rbac";
import { useShouldEnableIntercom } from "../../components/SupportTools/Intercom/IntercomHooks";

interface AnalyticsContext {
  datadogRum: boolean;
  intercom: boolean;
  segment: boolean;
}
const defaultContextValue: AnalyticsContext = {
  datadogRum: false,
  intercom: false,
  segment: false,
};
const shouldLaunchIntercom = () => {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return query.launchIntercom === "true";
};
const analyticsContext = React.createContext(defaultContextValue);
export const IdentifiedAnalyticsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [identified, setIdentified] =
    useState<AnalyticsContext>(defaultContextValue);
  const location = useLocation();
  const user = useUserLoginInfo();
  const permissions = useHasPermissions();

  const {
    boot: bootIntercom,
    update: updateIntercom,
    showNewMessage,
  } = useIntercom();
  const datadogRumReady = useInitDatadogRumAnalytics();
  const segmentReady = useInitSegmentAnalytics(
    location,
    identifyTypes.backend_and_client
  );
  const hubspotReady = useInithubspotForm();
  useEffect(() => {
    setIdentified((prevState) => ({ ...prevState, hubspot: hubspotReady }));
  }, [hubspotReady]);
  useEffect(() => {
    setIdentified((prevState) => ({ ...prevState, segment: segmentReady }));
  }, [segmentReady]);
  useEffect(() => {
    setIdentified((prevState) => ({
      ...prevState,
      datadogRum: datadogRumReady,
    }));
  }, [datadogRumReady]);

  const shouldEnableIntercom = useShouldEnableIntercom();

  useEffect(() => {
    if (user?.email && permissions) {
      if (user.email.endsWith("komodor-test.com")) return;
      bootIntercom({
        hideDefaultLauncher: !shouldEnableIntercom,
        name: user.name,
        email: user.email,
        customAttributes: {
          canManageUsers: permissions.canManageUsers,
          canManageIntegrations: permissions.canManageIntegrations,
          canManageFeatures: permissions.canManageFeatures,
          canManageMonitors: permissions.canManageMonitors,
        },
      });
      setIdentified((prevState) => ({ ...prevState, intercom: true }));
      if (shouldLaunchIntercom()) {
        showNewMessage();
      }
    }
  }, [bootIntercom, permissions, shouldEnableIntercom, showNewMessage, user]);

  useEffect(() => {
    // This allows new messages to be received on page updates
    // the `location` dependency is the important one.
    if (getAppConfig().env === "production") {
      updateIntercom();
    }
  }, [updateIntercom, location]);
  const value = useMemo(() => ({ ...identified }), [identified]);
  return (
    <analyticsContext.Provider value={value}>
      {children}
    </analyticsContext.Provider>
  );
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useIdentifiedAnalytics = (): AnalyticsContext =>
  useContext(analyticsContext);
