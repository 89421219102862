import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1ConfigurationsSecretsEventsSearchGetUrl,
  EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest,
  SecretEventsResponse,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";

const GET_SECRET_EVENTS_SEARCH = "/api/v1/configurations/secrets/events/search";

type UseSecretEventsSearchParams =
  EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest & {
    enabled?: boolean;
  };

const fetchSecretEvents = async (
  apiClient: AxiosInstance,
  params: UseSecretEventsSearchParams
): Promise<SecretEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1ConfigurationsSecretsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useSecretEvents = (params: UseSecretEventsSearchParams) => {
  const apiClient = useResourcesApiClient();

  return useQuery(
    [GET_SECRET_EVENTS_SEARCH, params],
    () => fetchSecretEvents(apiClient, params),
    {
      enabled: params.enabled,
    }
  );
};
