import { sortBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { grayAppBackground } from "../../../../../../../Colors";
import { MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY } from "../../../../../../../shared/config/urlSearchParamsKeys";
import {
  OptionType,
  SingleSmartSelect,
} from "../../../../../../common/controls/Select/SingleSmartSelect";
import {
  PodsEvents,
  ContainerSectionFull,
} from "../../../../../common/availabilityComponents";
import { SmallPadding } from "../../../../../common/styles";
import {
  GraySmallLightShortText,
  SmallLightShortText,
  RowFieldContainer,
} from "../../../../../monitorsConfiguration/common/styles";
import { PodOutputWithLogs } from "../../../../CommonTypes";
import { BoldGrayText } from "../../../CheckDetailsDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadHealthyPodsCheck, {
  CustomPodReason,
} from "../../WorkloadHealthyPodsCheck";
import SnapshotInfoDetail from "../../../../../common/availabilityComponents/SnapshotInfoDetail";
import AvailabilityUpgradeAgent from "../../../../../common/availabilityComponents/AvailabilityUpgradeAgent";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const PodOptionsSelection = styled(SingleSmartSelect)`
  background-color: ${grayAppBackground};
  margin: 0.25rem 0rem;
  cursor: pointer;
`;

const MarginTop = styled.div`
  margin-block-start: 0.4rem;
`;

const Margin = styled.div`
  margin: 0 0.2rem 0 0.7rem;
`;
export type PodOption = OptionType<PodOutputWithLogs>;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const podSelectEnrichment = (
  option: OptionType<unknown>
): JSX.Element => {
  const podOptionValue = (option as PodOption).value;
  return (
    <SmallLightShortText>
      <MarginTop />
      <RowFieldContainer>
        {podOptionValue?.phase && (
          <div>
            <GraySmallLightShortText>Phase:</GraySmallLightShortText>
            <SmallPadding>{podOptionValue.phase}</SmallPadding>
          </div>
        )}
        {podOptionValue?.calculatedReason && (
          <div>
            <GraySmallLightShortText>Reason:</GraySmallLightShortText>
            <SmallPadding>{podOptionValue.calculatedReason}</SmallPadding>
          </div>
        )}
        {podOptionValue?.nodeName && (
          <div>
            <GraySmallLightShortText>Node:</GraySmallLightShortText>
            <SmallPadding>{podOptionValue.nodeName}</SmallPadding>
          </div>
        )}
      </RowFieldContainer>
    </SmallLightShortText>
  );
};

const WorkflowPodsHealthy: React.FC<{
  check: WorkloadHealthyPodsCheck;
}> = ({ check }) => {
  const checkOutput = useMemo(() => check.output, [check.output]);
  const pods = useMemo(() => {
    return checkOutput?.pods ?? [];
  }, [checkOutput?.pods]);

  const [selectedPodName, setSelectedPodName] = useStateInSearchParams(
    MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY
  );
  const unhealthyPods = useMemo(
    () =>
      sortBy(
        pods.filter((p) => !p.isHealthy),
        (p) => p?.calculatedReason === CustomPodReason.Evicted
      ),
    [pods]
  );

  const selectedPod = useMemo(
    () =>
      unhealthyPods.find((p) => p.name === selectedPodName) ?? unhealthyPods[0],
    [selectedPodName, unhealthyPods]
  );

  const podsOptions = useMemo<PodOption[]>(() => {
    return unhealthyPods.map((pod) => ({
      label: pod.name,
      value: pod,
    }));
  }, [unhealthyPods]);
  const onPodSelectCallback = useCallback(
    (selected: OptionType<unknown>) => {
      setSelectedPodName((selected as PodOption).value.name);
    },
    [setSelectedPodName]
  );

  return (
    <Margin>
      {check.output?.eventTime && (
        <SnapshotInfoDetail eventTime={check.output?.eventTime} />
      )}
      {(!check.output?.isPodEventAllowed ||
        check.output?.isPodLogsAllowed === false) && (
        <>
          <AvailabilityUpgradeAgent />
          <br />
        </>
      )}
      {pods.length === 0
        ? checkOutput?.failureMessage
          ? checkOutput?.failureMessage
          : check.shortOutput
        : null}

      {selectedPod && (
        <>
          <BoldGrayText>Unhealthy pod</BoldGrayText>
          <PodOptionsSelection
            aria-label="pod-health pod-select"
            options={podsOptions}
            placeholder={"Select pod"}
            value={{ value: selectedPod, label: selectedPod.name }}
            onChange={onPodSelectCallback}
            enrichLabelElement={podSelectEnrichment}
          />
          <br />
          <PodsEvents pod={selectedPod} />
          <br />
          <ContainerSectionFull
            containers={selectedPod.containers}
            podName={selectedPod.name}
            clusterName={check.output.clusterName ?? ""}
            namespace={check.output.namespace ?? ""}
            eventTime={check.output?.eventTime}
          />
        </>
      )}
    </Margin>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WorkflowPodsHealthy;
