import { ColumnField } from "@/components/ClustersView/components/ClustersList/types";

export const LOADER_HEIGHT = "300px";

export const CLUSTERS_GROUP_INVENTORY_SECTION_COLUMNS: ColumnField[] = [
  "clusterName",
  "region",
  "k8sVersion",
  "allocation",
  "nodes",
  "services",
  "jobs",
  "pods",
];
