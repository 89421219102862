import { useMemo } from "react";

import { useGetClustersOverviewMetrics } from "../../../../../../shared/hooks/metrics-api/client/useGetClustersOverviewMetrics";
import { WorkloadMetricsDataPoints } from "../../../../../../generated/metricsApi";

type ClusterMetricsParams = {
  clusterName: string;
  fromEpoch: number;
  toEpoch: number;
};

export const useClusterMetrics = ({
  clusterName,
  fromEpoch,
  toEpoch,
}: ClusterMetricsParams) => {
  const { data, isLoading, error } = useGetClustersOverviewMetrics(
    {
      clusterName,
      fromEpoch,
      toEpoch,
    },
    { enabled: !!clusterName, staleTime: 0 }
  );

  const hasData = !!data?.avg?.memoryUsageBytes?.length;

  return {
    loading: isLoading,
    error,
    data,
    hasData,
  };
};

export const useHasGraphData = (data?: WorkloadMetricsDataPoints) => {
  return useMemo(() => {
    if (!data) return false;
    return Object.values(data).some((dataPoints) => dataPoints?.length > 0);
  }, [data]);
};
