import { useState } from "react";

import externalDNSImgSrc from "../../assets/Screen - ExternalDNS.png";
import { TopBar } from "../TopBar";
import clusterDevEu2ExternalDNSDrawer from "../../assets/External DNS Violation Drawer.png";
import { CloseButton, RelativeContainer, SelectableRow } from "../../styles";

import { MockDrawer } from "./MockDrawer";

export const ExternalDNSView: React.FC = () => {
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  return (
    <RelativeContainer>
      <TopBar page="externalDNS" />
      <img src={externalDNSImgSrc}></img>
      <SelectableRow
        height="5.8%"
        top="27.8%"
        onClick={() => {
          setDrawerImgSrc(clusterDevEu2ExternalDNSDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={<CloseButton onClick={() => setDrawerImgSrc(null)} />}
      />
    </RelativeContainer>
  );
};
