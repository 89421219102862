import React, { memo, useCallback } from "react";
import styled from "styled-components";
import cn from "classnames";
import { formatDistanceToNow } from "date-fns";
import { TooltipWrapper } from "react-tooltip";

import { dispatchEvent } from "../../shared/hooks/analytics";
import { HighlightedText } from "../common/HighlightedText";
import { S2 } from "../common/typography";
import Deploy from "../../components/servicesView/deploy.svg?react";
import ServiceTileTag from "../../components/servicesView/ServiceTileTag/ServiceTileTag";
import { ServiceInfo } from "../../shared/types/ServiceInfo";
import { useDateFormatter } from "../../shared/hooks/useDateFormatter";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { DEFAULT_TOOLTIP } from "../../shared/constants/tooltipIds";

import { Container, HealthStatus, SpacerDot, Info, Tags } from "./styles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Icons = styled.div`
  grid-area: icons;
  svg {
    height: 1rem;
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Replicas = styled.span`
  color: #9e9fa3;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Title = styled(S2)`
  grid-area: title;
  word-break: break-word;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const ServiceTile: React.FC<{
  service: ServiceInfo;
  filterTerm: string;
}> = ({
  service: {
    id,
    healthy,
    currentlyDeploying,
    title,
    tags,
    lastModified,
    replicas,
  },
  filterTerm,
}) => {
  const { format } = useDateFormatter();
  const clickHandler = useCallback(() => {
    dispatchEvent(AnalyticEvents.ExploreServicesView.ServiceTile_Click, {
      serviceName: title,
      healthy,
    });
  }, [healthy, title]);
  return (
    <Container
      to={`/main/deep-dive/${encodeURIComponent(id)}`}
      onClick={clickHandler}
      className={cn({ unhealthy: !healthy })}
      data-e2e-selector={"serviceTile"}
      id={`serviceTile-${id}`}
      key={id}
    >
      <Title>
        <HighlightedText text={title} term={filterTerm} />
      </Title>
      <HealthStatus data-e2e-selector={"serviceTileHealthStatus"}>
        {healthy ? "HEALTHY" : "UNHEALTHY"}
        {replicas ? (
          <>
            <SpacerDot />
            <Replicas data-e2e-selector={"serviceTileReplicas"}>
              {replicas.ready}/{replicas.desired}
            </Replicas>
          </>
        ) : null}
      </HealthStatus>
      <Icons data-e2e-selector={"serviceTileIcons"}>
        {currentlyDeploying && (
          <Deploy data-e2e-selector={"serviceTileDeployInProgressIcon"} />
        )}
      </Icons>
      <TooltipWrapper
        content={format(lastModified)}
        tooltipId={DEFAULT_TOOLTIP}
        place="bottom"
      >
        <Info data-e2e-selector={"serviceTileInfo"}>
          Modified {formatDistanceToNow(lastModified, { addSuffix: true })}
        </Info>
      </TooltipWrapper>
      <Tags data-e2e-selector={"serviceTileTags"}>
        {tags.map((tag) => (
          <ServiceTileTag
            key={`${tag.label}: ${tag.value}`}
            tag={tag}
            filterTerm={filterTerm}
          />
        ))}
      </Tags>
    </Container>
  );
};

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default memo(ServiceTile);
