import { useResourcesAPIGet } from "../../../../shared/hooks/resources-api/client";
import { WORKLOADS_CRON_JOBS_EVENT_SEARCH } from "../../../../shared/hooks/resources-api/requestResponseMaps";
import {
  Create,
  DESC,
  Update,
  useBuildKomodorUidForJob,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";

const useCronJobNewSpec = (
  resourceId: string
): { cronJobObj: unknown; fetching: boolean } => {
  const komodorUid = useBuildKomodorUidForJob(resourceId);

  const { data: cronJob, loading } = useResourcesAPIGet(
    WORKLOADS_CRON_JOBS_EVENT_SEARCH,
    {
      limit: 1,
      order: DESC,
      komodorUids: [komodorUid ?? ""],
      fields: ["newObj"],
      actions: [Update, Create],
    },
    !komodorUid
  );

  const cronJobObj = cronJob?.data?.[0].newObj;
  return { cronJobObj, fetching: loading };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useCronJobNewSpec;
