export enum FilterCategories {
  health = "Health",
  ongoing = "On going",
  clusters = "Clusters",
  namespaces = "Namespaces",
  status = "Status",
  kind = "Kind",
}

export enum HealthStatus {
  healthy = "Healthy",
  unhealthy = "Unhealthy",
}
