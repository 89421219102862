import React from "react";
import { get } from "lodash";
import { CustomResourceDefinition } from "kubernetes-types/apiextensions/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { CRD as crdIcon } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CRDDetailsTab } from "../tabs/CRD/CRDDetailsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CRDResourcesTab } from "../tabs/CRD/CRDResourcesTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class CRD implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly displayName;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Resources },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [];
  readonly group: string;
  readonly fullObj;
  readonly defaultTab;

  constructor(cluster: string, crd: CustomResourceDefinition, agentId: string) {
    this.agentId = agentId;
    this.id = crd.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = crd.metadata?.namespace ?? "";
    this.name = crd.metadata?.name ?? "";
    this.displayName = get(crd, "spec.names.kind");
    this.kind = crd.kind ?? "";
    this.labels = crd.metadata?.labels ?? {};
    this.annotations = crd.metadata?.annotations ?? {};
    this.icon = crdIcon;
    this.group = crd.spec?.group ?? "";
    this.fullObj = crd;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <CRDDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <CRDResourcesTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={2}>
          <ResourceYAMLTab
            resource={this}
            options={{ dropManagedFields: true }}
          />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        displayName={this.displayName}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "group", value: this.group },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
