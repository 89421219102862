import React from "react";
import Typography from "@mui/material/Typography";

import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { ChipsFlexContainer } from "@/components/k8sAddons/LiveStateDrawer/components/ChipsFlexContainer";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";

export const SpecSection: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const dnsNames = certificateData?.data?.object.spec.dnsNames;

  if (!dnsNames) return null;
  return (
    <AccordionWrapper
      title={"Spec"}
      ariaLabel={certificatesPageAriaLabels.drawer.sections.spec}
      accordionContent={
        <AccordionContentItemContainer>
          <Typography variant={"h5"} color={"text.secondary"}>
            DNS names
          </Typography>
          <ChipsFlexContainer values={dnsNames} />
        </AccordionContentItemContainer>
      }
    />
  );
};
