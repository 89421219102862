import React from "react";
import { CustomResourceColumnDefinition } from "kubernetes-types/apiextensions/v1.d";
import jp from "jsonpath";

import { notifyDDError } from "../../../../../shared/hooks/exceptionManagement";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomResourceBasic } from "../../../resources/customResource";
import { DescribeTextItem } from "../../DescribeTab/common/DescribeItem";
import { DescribeSectionDivider } from "../../DescribeTab/common/DescribeSectionDivider";

interface Props {
  additionalPrinterColumns?: CustomResourceColumnDefinition[];
  customResource: CustomResourceBasic;
}

const reportOnErr = (name: string, jsonPath: string) => {
  notifyDDError(
    new Error(
      "CR resource drawer - failed to parse additionalPrinterColumn path"
    ),
    {
      details: {
        name,
        jsonPath,
      },
    }
  );
};

/*
    additionalPrinterColumns are possible additional columns that might exists on CR based on the CRD
    their values are not promised, so we might have empty values. Thus, we filter out undefined and check the value
    is actually placed.
*/
export const AdditionalPrinterColumns: React.FC<Props> = ({
  additionalPrinterColumns,
  customResource,
}) => {
  return Array.isArray(additionalPrinterColumns) &&
    additionalPrinterColumns.length ? (
    <>
      <DescribeSectionDivider title="ADDITIONAL PRINTER COLUMNS" />
      {additionalPrinterColumns
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, jsonPath }) => {
          let mappedValue: string | undefined;
          try {
            mappedValue = jp
              .query(customResource, jsonPath.substring(1))
              .at(0) as string | undefined;

            if (!mappedValue) {
              reportOnErr(name, jsonPath);

              return undefined;
            }
          } catch (error) {
            reportOnErr(name, jsonPath);

            return undefined;
          }

          return (
            <DescribeTextItem key={name} name={name} value={mappedValue} />
          );
        })
        .filter(Boolean)}
    </>
  ) : null;
};
