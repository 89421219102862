import React, { useMemo } from "react";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";
import styled from "styled-components";

import { MetricsAggregationType } from "../types";

import { metricsDataToLabel, selectableMetrics } from "./constants";

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 14px;
  }
`;

const StyledSelect = styled(MuiSelect<string>)`
  && {
    font-size: 14px;
  }
`;

interface Props {
  ariaLabel: string;
  aggregationMetric: MetricsAggregationType;
  setAggregationMetric: (metric: MetricsAggregationType) => void;
}

const AggregationSelectorStyle = {
  fontSize: "12px",
  height: "32px",
};

export const MetricsAggregationSelector: React.FC<Props> = ({
  ariaLabel,
  aggregationMetric,
  setAggregationMetric,
}) => {
  const onChange = (e: SelectChangeEvent<string>) => {
    setAggregationMetric(e.target.value as MetricsAggregationType);
  };

  const options = useMemo(() => {
    return selectableMetrics.map((metric) => {
      const label = metricsDataToLabel[metric];
      return (
        <StyledMenuItem value={metric} key={metric}>
          {label ?? metric}
        </StyledMenuItem>
      );
    });
  }, []);

  return (
    <StyledSelect
      size={"small"}
      value={aggregationMetric}
      onChange={onChange}
      aria-label={ariaLabel}
      sx={AggregationSelectorStyle}
    >
      {options}
    </StyledSelect>
  );
};
