import React from "react";

import { OverviewPageTitle } from "../OverviewPageTitle";

import { AvailabilityIssues } from "./insightsCards/AvailabilityIssues/AvailabilityIssues";
import { BestPracticesMissingResourcesLimits } from "./insightsCards/BestPracticesMissingResourcesLimits/BestPracticesMissingResourcesLimits";
import { BestPracticesLivenessProbes } from "./insightsCards/BestPracticesLivenessProbes/BestPracticesLivenessProbes";
import { BestPracticesSecurityFailed } from "./insightsCards/BestPracticesSecurityFailed/BestPracticesSecurityFailed";
import { FailedDeploys } from "./insightsCards/FailedDeploys/FailedDeploys";
import { PotentialCostSaving } from "./insightsCards/PotentialCostSaving/PotentialCostSaving";
import { ThrottledApplications } from "./insightsCards/ThrottledApplications/ThrottledApplications";
import { InsightsUnavailablePrompt } from "./insightsCards/InsightsUnavailablePrompt/InsightsUnavailablePrompt";

export const Insights = React.forwardRef<HTMLDivElement>(
  (props, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
      <div ref={ref}>
        <OverviewPageTitle title={"Insights"} />
        <InsightsUnavailablePrompt />
        <AvailabilityIssues />
        <BestPracticesMissingResourcesLimits />
        <BestPracticesLivenessProbes />
        <BestPracticesSecurityFailed />
        <FailedDeploys />
        <PotentialCostSaving />
        <ThrottledApplications />
      </div>
    );
  }
);
