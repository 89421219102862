import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useCreationPageContext } from "../context/CreationPageContext";
import {
  boolDictionaryToStringList,
  workspaceValueByType,
} from "../utils/creationPageUtils";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  onAppViewUpsertSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { useVerifyCreationForm } from "./useVerifyCreationForm";
import {
  useSetAppViewAndNavigate,
  useVerifyServerResponse,
} from "./creationPageHooks";

import { WORKSPACES_PATH } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaces";
import { Workspace, WorkspaceKind } from "@/generated/workspacesApi";
import { useCreateWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useCreateWorkspace";

export const useSaveAppView = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const {
    selectedServiceIds,
    selectedServiceIdentifiers,
    appViewType,
    appName,
    setErrorState,
    description,
    setIsSavingAppView,
  } = useCreationPageContext();
  const onAppViewUpsert = useAppViewsStore(onAppViewUpsertSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);

  const verifyCreationForm = useVerifyCreationForm();
  const verifyServerResponse = useVerifyServerResponse();
  const setAppViewAndNavigate = useSetAppViewAndNavigate();

  const createWorkspace = useCreateWorkspace();

  return useCallback(async () => {
    if (!verifyCreationForm()) return;
    setErrorState({ appNameError: undefined });

    const serviceIdentifiers =
      (appViewType === WorkspaceKind.ServiceId
        ? boolDictionaryToStringList(selectedServiceIds)
        : selectedServiceIdentifiers) ?? [];

    setIsSavingAppView(true);

    const result = await createWorkspace
      .mutateAsync({
        id: "",
        name: appName,
        description: description,
        kind: appViewType,
        value: workspaceValueByType(serviceIdentifiers, appViewType),
      } as Workspace)
      .catch((error) => {
        if (!verifyServerResponse(error)) {
          return;
        }
      })
      .finally(() => {
        setIsSavingAppView(false);
      });

    const newAppView: Workspace = {
      ...result,
    } as Workspace;

    setAppViewAndNavigate(newAppView);

    onAppViewUpsert(newAppView);
    queryClient.invalidateQueries([WORKSPACES_PATH]);
    setSelectedAppViewId(result?.id);
  }, [
    verifyCreationForm,
    setErrorState,
    appViewType,
    selectedServiceIds,
    selectedServiceIdentifiers,
    setIsSavingAppView,
    createWorkspace,
    appName,
    description,
    verifyServerResponse,
    setAppViewAndNavigate,
    onAppViewUpsert,
    queryClient,
    setSelectedAppViewId,
  ]);
};
