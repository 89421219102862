import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { useCallback, useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";

import { CustomServiceSelection } from "./CustomServiceSelection";
import {
  useBaselineServiceOptions,
  useComparedServicesOptions,
} from "./useComparedServicesOptions";
import { useServicesComparisonContext } from "./useServicesComparisonContext";

import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";

const Container = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
`;

export const ComparisonSelection: React.FC = () => {
  const services = useKomodorServices().servicesAsDictionary.services;
  const {
    baselineServiceId,
    comparedServicesIds,
    setBaselineServiceId,
    setComparedServicesIds,
  } = useServicesComparisonContext();

  const comparisonServicesOptions = useComparedServicesOptions(
    comparedServicesIds,
    services,
    baselineServiceId
  );

  const baselineServicesOptions: MuiSelectionOption<string>[] =
    useBaselineServiceOptions(baselineServiceId, services);

  const optionToCustomElement = useCallback(
    (option: MuiSelectionOption<string>) => {
      const service = services[option.value];
      return (
        <CustomServiceSelection
          clusterName={service.k8sCluster}
          namespace={service.env}
          serviceName={service.title}
        />
      );
    },
    [services]
  );

  const optionToCustomElementMultiSelect = useCallback(
    (option: MuiSelectionOption<string>, isChecked?: boolean) => {
      const service = services[option.value];
      return (
        <>
          <Checkbox checked={isChecked} />
          <CustomServiceSelection
            clusterName={service.k8sCluster}
            namespace={service.env}
            serviceName={service.title}
          />
        </>
      );
    },
    [services]
  );

  const servicesLength = useMemo(
    () => Object.keys(services).length,
    [services]
  );

  return (
    <ResponsiveLayout>
      <Container>
        <SingleSelect
          options={baselineServicesOptions}
          placeholder="Select Comparison Baseline"
          label="Comparison Baseline"
          onChange={(value) => setBaselineServiceId(value)}
          value={baselineServiceId}
          width="360px"
          minWidth="360px"
          enableSearch
          customOptionElement={optionToCustomElement}
          disableBlankOption
          disabled={servicesLength == 0}
          selectPopoverProps={{
            maxHeight: "544px",
            width: "480px",
          }}
          enableVirtualization
        />
        <AdvancedMultiSelect
          options={comparisonServicesOptions}
          label="Comparison Services"
          onChange={(value) => setComparedServicesIds(value)}
          placeholder="Select Comparison Services"
          minWidth="220px"
          width="100%"
          enableSelectAll={false}
          value={comparedServicesIds}
          customOptionElement={optionToCustomElementMultiSelect}
          disabled={servicesLength == 0 || !baselineServiceId}
          enableVirtualization
          selectPopoverProps={{
            maxHeight: "544px",
          }}
        />
      </Container>
    </ResponsiveLayout>
  );
};
