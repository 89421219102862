import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import TrendingFlat from "@mui/icons-material/TrendingFlat";
import { muiColors } from "@komodorio/design-system";

import { formatNumber } from "../../../../../../../shared/utils/formatNumber";
import { costOptimizationAriaLabels } from "../../../../../constants/costOptimizationAriaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ResourceValue = styled(Typography).attrs({ variant: "body2" })``;

const CurrentBeforeRecommendationsValue = styled(ResourceValue)`
  text-decoration: line-through;
`;

const Arrow = styled(TrendingFlat)`
  && {
    fill: ${muiColors.gray[800]};
  }
`;

const {
  rightSizingPage: {
    recommendationModal: {
      containers: {
        currentRequest: currentRequestAriaLabel,
        recommendedRequest: recommendedRequestAriaLabel,
      },
    },
  },
} = costOptimizationAriaLabels;

interface Props {
  unit: string;
  current: number;
  recommendation: number;
  shouldShowRecommendation: boolean;
  ariaLabel?: string;
}

export const RecommendationChange: React.FC<Props> = ({
  unit,
  current,
  recommendation,
  shouldShowRecommendation,
  ariaLabel,
}) => {
  const Current = shouldShowRecommendation
    ? CurrentBeforeRecommendationsValue
    : ResourceValue;

  return (
    <Container aria-label={ariaLabel}>
      <Current aria-label={currentRequestAriaLabel}>
        {formatNumber(current)} {unit}
      </Current>
      {shouldShowRecommendation && (
        <>
          <Arrow />
          <Typography aria-label={recommendedRequestAriaLabel} variant="body2">
            {formatNumber(recommendation)} {unit}
          </Typography>
        </>
      )}
    </Container>
  );
};
