import React, { useMemo } from "react";
import styled from "styled-components";

import { PaddedCard } from "../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectAllocationSummaryState } from "../../../store/costOptimizationStoreSelectors";
import { DataGridTable } from "../../shared/tableComponents/DataGrid";
import { FetchError } from "../../shared/errors/FetchError";
import { CenteredLoader } from "../../../../../shared/components/CenteredLoader";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { useCsvData } from "../../../../../shared/components/CsvExport/useCsvData";
import { CostAllocationSummary } from "../../../../../generated/metricsApi";
import { getTodayDate } from "../../../../../shared/utils/dateUtils";

import { useAllocationTableColumns } from "./allocationTableHooks";
import { getAllocationCsvHeaders, getValue, sortRows } from "./csv";

const ContentContainer = styled.div`
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  & > div {
    width: 100%;
    min-width: 800px;
  }
`;

export const AllocationTable: React.FC = () => {
  const { loading, data, error } = useCostOptimizationStore(
    selectAllocationSummaryState
  );

  const columns = useAllocationTableColumns(data?.rows);

  const csvRows = useMemo(
    () => sortRows([...(data?.rows ?? [])]),
    [data?.rows]
  );

  const csvData = useCsvData<CostAllocationSummary>({
    getHeaders: getAllocationCsvHeaders,
    getValue,
    rows: csvRows,
  });

  const content = loading ? (
    <CenteredLoader />
  ) : error ? (
    <FetchError />
  ) : (
    <DataGridTable
      rows={data?.rows ?? []}
      columns={columns}
      csvProps={{
        data: csvData,
        fileName: `cost-allocation-${getTodayDate()}`,
        ariaLabel:
          costOptimizationAriaLabels.allocationPage.allocationTable
            .exportButton,
      }}
    />
  );

  return (
    <PaddedCard
      aria-label={
        costOptimizationAriaLabels.allocationPage.allocationTable.container
      }
    >
      <ContentContainer>{content}</ContentContainer>
    </PaddedCard>
  );
};
