import { Button, Divider } from "@komodorio/design-system/deprecated";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import { parseISO } from "date-fns";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { AvailabilityCheckType } from "../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { WorkflowRunWithResults } from "../../../../monitorsView/useWorkflowsRuns";
import {
  PREVIOUS_SUFFIX,
  WorkloadHealthyPodsOutput,
  WorkloadInfoOutput,
} from "../../../../monitorsView/workflowRunView/checks/availability/types";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
import WorkflowCheck from "../../../../monitorsView/workflowRunView/checks";
import AvailabilitySummary from "../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/AvailabilitySummary";

import useShouldOpenPodsHealth from "./useShouldOpenPodsHealth";
import EventDetailsCheckCard from "./EventDetailsCheckCard";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ChecksTitle, SimpleButtonsContainer, SimpleSection } from ".";

const AvailabilityButton = styled(Button)<{ checked: boolean }>`
  border: solid ${({ checked }) => (checked ? "2px #3B3D45" : "1px #9195A1")};
  box-shadow: ${({ checked }) =>
    checked ? "0px 2px 8px 1px rgba(22, 24, 31, 0.15)" : "unset"};
`;
export const AvailabilityDrawerData: React.FC<{
  allChecks: WorkflowCheck[];
  wfRun: WorkflowRunWithResults;
  serviceId: string;
}> = ({ allChecks, wfRun, serviceId }) => {
  const [showPreviousOccurrence, setShowPreviousOccurrence] = useState(false);
  const currentAvailabilityChecks = useMemo(() => {
    return allChecks.filter((c) => !c.type.includes(PREVIOUS_SUFFIX));
  }, [allChecks]);
  const previousAvailabilityChecks = useMemo(() => {
    return allChecks.filter((c) => c.type.includes(PREVIOUS_SUFFIX));
  }, [allChecks]);
  const checksToShow = useMemo(
    () =>
      showPreviousOccurrence
        ? previousAvailabilityChecks
        : currentAvailabilityChecks,
    [
      currentAvailabilityChecks,
      previousAvailabilityChecks,
      showPreviousOccurrence,
    ]
  );
  const shouldOpenPodsHealth = useShouldOpenPodsHealth();

  return (
    <>
      {previousAvailabilityChecks.find(
        (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthyPrevious
      ) !== undefined && (
        <>
          <ChecksTitle>Occurrences</ChecksTitle>
          <SimpleSection>
            <SimpleButtonsContainer>
              <IssueRunTile
                infoOutput={
                  currentAvailabilityChecks.find(
                    (c) => c.type === AvailabilityCheckType.WorkloadInfo
                  )?.output as WorkloadInfoOutput
                }
                startTime={wfRun.eventTime.toISOString()}
                onClickCallback={() => setShowPreviousOccurrence(false)}
                isChecked={!showPreviousOccurrence}
                title={"Latest"}
              />
              <IssueRunTile
                infoOutput={
                  currentAvailabilityChecks.find(
                    (c) => c.type === AvailabilityCheckType.WorkloadInfoPrevious
                  )?.output as WorkloadInfoOutput
                }
                startTime={wfRun.eventTime.toISOString()}
                onClickCallback={() => setShowPreviousOccurrence(true)}
                isChecked={showPreviousOccurrence}
                title={"First"}
              />
              <br />
              <br />
            </SimpleButtonsContainer>
          </SimpleSection>
        </>
      )}
      <Divider />
      <ChecksTitle>Checks & Findings</ChecksTitle>
      <div>
        <AvailabilitySummary
          reasons={wfRun.reasons}
          checks={checksToShow}
          serviceId={serviceId}
          wfEventTime={wfRun?.eventTime}
          wfClosedAt={wfRun?.closedAt}
          showPrevious={showPreviousOccurrence}
        />
        {checksToShow.map((c, i) => (
          <React.Fragment key={i}>
            {[
              AvailabilityCheckType.WorkloadPodsHealthyPrevious,
              AvailabilityCheckType.WorkloadPodsHealthy,
            ].includes(c.type as AvailabilityCheckType) ? (
              !(c.output as WorkloadHealthyPodsOutput).failureMessage && (
                <EventDetailsCheckCard
                  check={c}
                  isStartOpen={
                    c.type === AvailabilityCheckType.WorkloadPodsHealthy &&
                    shouldOpenPodsHealth
                  }
                />
              )
            ) : (
              <EventDetailsCheckCard check={c} />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const IssueRunTile: React.FC<{
  infoOutput: WorkloadInfoOutput;
  startTime: string;
  onClickCallback: () => void;
  isChecked: boolean;
  title: string;
}> = ({ infoOutput, onClickCallback, isChecked, startTime, title }) => {
  const { format } = useDateFormatter();

  return (
    <AvailabilityButton
      checked={isChecked}
      variant={"default"}
      size={"large"}
      width={"justify-content"}
      onClick={onClickCallback}
    >
      <Metadata
        title={format(parseISO(infoOutput?.infoTime ?? startTime))}
        value={title}
        align={"left"}
      />
    </AvailabilityButton>
  );
};
