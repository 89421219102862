import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { ResourceLinks } from "@/components/k8sAddons/LiveStateDrawer/components/ResourceLinks/ResourceLinks";
import {
  CertificateFullRelatedResources,
  CertificateRelatedResource,
} from "@/generated/addonsApi";
import {
  certificateDrawerRelatedResourcesOrder,
  RelatedResourceToTitle,
} from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";

export const RelatedResourcesSection: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const relatedResources = certificateData?.data?.object.relatedResources;

  const relatedResourceContent = useMemo(() => {
    if (!relatedResources) return null;
    return (
      <>
        {Object.entries(relatedResources)
          .filter(([_, value]) => {
            return Array.isArray(value)
              ? filterKomodorUids(value)
              : filterKomodorUids([value]);
          })
          .sort((a, b) => {
            const key1 = a[0] as keyof CertificateFullRelatedResources;
            const key2 = b[0] as keyof CertificateFullRelatedResources;
            return (
              certificateDrawerRelatedResourcesOrder.indexOf(key1) -
              certificateDrawerRelatedResourcesOrder.indexOf(key2)
            );
          })
          .map(([key, value]) => {
            return (
              <AccordionContentItemContainer key={key}>
                <Typography variant={"h5"} color={"text.secondary"}>
                  {
                    RelatedResourceToTitle[
                      key as keyof CertificateFullRelatedResources
                    ]
                  }
                </Typography>
                <ResourceLinks
                  relatedResources={Array.isArray(value) ? value : [value]}
                />
              </AccordionContentItemContainer>
            );
          })}
      </>
    );
  }, [relatedResources]);

  return (
    <AccordionWrapper
      title={"Related resources"}
      accordionContent={relatedResourceContent}
      ariaLabel={certificatesPageAriaLabels.drawer.sections.relatedResources}
    />
  );
};

const filterKomodorUids = (relatedResources: CertificateRelatedResource[]) => {
  const populatedRelatedResources = relatedResources.filter(
    (relatedResource) => relatedResource.komodorUid.length > 0
  );
  return populatedRelatedResources.length > 0;
};
