import { useMemo } from "react";

import { useDesktopAgentsStatusContext } from "../desktopAgentsProvider/desktopAgentsStatusesProvider";
import isElectronContext from "../isElectronContext";

import { AgentStatus } from "./../getClusterStatus";

export const useShowConnectionFailureEmptyState = (): boolean => {
  const agentStatuses = useDesktopAgentsStatusContext();
  const agentStatusValues = Object.values(agentStatuses ?? {});

  return useMemo(() => {
    if (!isElectronContext() || !agentStatusValues.length) return false;
    return !agentStatusValues.includes(AgentStatus.CONNECTED);
  }, [agentStatusValues]);
};
