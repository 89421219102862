import React, { useEffect } from "react";

import { useCreateSilentLoginMutation } from "../../../generated/graphql";
import getDesktopUserCredentials from "../../../shared/context/electron/getDesktopUserCredentials";

export const SilentLogin: React.FC<{
  setAccessToken: (newAccessToken: string | null) => void;
}> = ({ setAccessToken }) => {
  const [, executeLogin] = useCreateSilentLoginMutation();

  useEffect(() => {
    const getUserCredentials = async () => {
      const userCredentials = await getDesktopUserCredentials();
      if (!userCredentials) return;
      const res = await executeLogin({
        mail: userCredentials.email,
        password: userCredentials.password,
        id: userCredentials.id,
      });
      setAccessToken(res?.data?.desktopSilentLogin?.jwt ?? null);
    };

    getUserCredentials().catch((err) => {
      throw new Error(err);
    });
  }, [executeLogin, setAccessToken]);
  return <div />;
};
