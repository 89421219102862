import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";

import Input from "../../common/controls/Input";
import { H4, textStyle } from "../../common/typography";
import { gray13, gray10 } from "../../../Colors";
import { INSPECTION_SEARCH_TERM_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { InspectionViewContext } from "@/components/Inspection/InspectionViewContext";
import { isFocusOnFilterKeysCombination } from "@/components/Inspection/utils/isFocusOnFilterKeysCombination";

export const InspectionFilterContainer = styled.div`
  gap: 0.3rem;
  display: grid;
  width: 14rem;
  height: 3.5rem;
`;

export const InspectionFilterDiv = styled(H4)`
  color: ${gray10};
`;

export const InputContainer = styled.div<{ inputHeight?: string }>`
  > input {
    height: ${({ inputHeight }) => inputHeight || "1.2rem"};
    width: -webkit-fill-available;
  }
`;

const StyledInput = styled(Input)`
  ${textStyle};
  background: white;
  border: 1 solid ${gray13};
`;

export const InspectionResourceListSearch: React.FC<{
  resourceName: string;
  labelText?: string;
}> = ({ resourceName, labelText }) => {
  const [filterSearchTerm, setFilterSearchTerm] = useStateInSearchParams(
    INSPECTION_SEARCH_TERM_PARAM_KEY
  );
  const myRef = React.useRef<HTMLInputElement>(null);

  const { setKeyDownCallback, focusOnTable } = useContext(
    InspectionViewContext
  );

  const onSearchKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isFocusOnFilterKeysCombination(e)) {
        if (document.activeElement === myRef.current) return;
        myRef.current?.focus();
        e.preventDefault();
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();
        focusOnTable?.();
      }
    },
    [myRef, focusOnTable]
  );

  useEffect(() => {
    setKeyDownCallback?.(() => {
      return onSearchKeydown;
    });
  }, [setKeyDownCallback, onSearchKeydown]);

  return (
    <InspectionFilterContainer>
      <InspectionFilterDiv>
        {labelText || `Search ${resourceName.toLowerCase()}`}
      </InspectionFilterDiv>
      <InputContainer>
        <StyledInput
          ref={myRef}
          placeholder="Search by name, labels etc."
          data-e2e-selector={"inspection-search-filter"}
          value={filterSearchTerm ?? ""}
          onChange={(c) => setFilterSearchTerm(c.target.value || null)}
        />
      </InputContainer>
    </InspectionFilterContainer>
  );
};
