import { AlertColor } from "@mui/material/Alert/Alert";
import { GridPaginationModel } from "@mui/x-data-grid";

import {
  CheckType,
  IgnoreRule,
  ViolationStatus,
  ViolationCountBy,
} from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";

export type AddEditIgnoreRuleDialogAction = {
  isOpen: boolean;
  checkType?: CheckType;
  existingIgnoreRule?: IgnoreRule;
};

export type PageRoutes =
  | "violations"
  | "checks"
  | "policies"
  | "best-practices";

export type ViolationsGroupBy = "None" | "Impact" | "Cluster" | "CheckType";

export type Severity = "high" | "medium" | "low";

export type TabValue = Severity | "all";

export type ColorScheme = {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
};

export type ReliabilityErrorPayload = {
  action: string;
  message: string;
};

export type ReliabilityError =
  | "deleteIgnoreRule"
  | "addIgnoreRule"
  | "editIgnoreRule"
  | "updateViolationStatus"
  | "createViolationFeedback"
  | "deletePolicy"
  | "createPolicy"
  | "fetchPolicyData";

type AddEditIgnoreRuleDialogState = {
  isOpen: boolean;
  checkType?: CheckType;
  existingIgnoreRule?: IgnoreRule;
};

export type Group = {
  isExpanded: boolean;
  statuses: ViolationStatus[];
  checkTypes: CheckType[];
  searchTerm: string;
  paginationModel: GridPaginationModel;
};

export type GroupByOptions = Exclude<ViolationsGroupBy, "None">;

export type ViolationsState = {
  groups: Record<ViolationsGroupBy, Dictionary<Group>>;
  ungroupedTotalViolationsCount: number | undefined;
};

export type GroupsState = {
  groupBy: ViolationsGroupBy;
  uid: string;
  newState: Partial<Group>;
};

export type ReliabilityNotification = {
  message: string;
  severity: AlertColor;
};

export type ReliabilityStoreState = {
  violationsState: ViolationsState;
  addEditIgnoreRuleDialogState: AddEditIgnoreRuleDialogState;
  reliabilityNotifications: ReliabilityNotification[];
  initialTime: number;
  hasSubscribedToAppViewChanges: boolean;
};

type ReliabilityStoreActions = {
  setTopSectionIsShown: (isShown: boolean) => void;
  setUngroupedTotalViolationsCount: (count: number | undefined) => void;
  setGroupExpandedState: (groupBy: GroupByOptions, groupId: string) => void;
  setGroupState: (groupState: GroupsState) => void;
  setAddEditIgnoreRuleDialogState: (
    state: AddEditIgnoreRuleDialogAction
  ) => void;
  setReliabilityNotification: (notification: ReliabilityNotification) => void;
  removeReliabilityNotification: (
    notification: ReliabilityNotification
  ) => void;
  setHasSubscribedToAppViewChanges: (hasSubscribed: boolean) => void;
};

export type ReliabilityStore = ReliabilityStoreState & ReliabilityStoreActions;

export type ReliabilityFilterCategories = Partial<
  Record<ViolationCountBy, string[]>
>;

export type ViolationsTableConfiguration = Partial<
  Record<keyof ReliabilityFilterCategories, string[]>
>;

export const ViolationsTableType = {
  riskAssessment: "RiskAssessment",
  impactAnalysis: "ImpactAnalysis",
} as const;

export type ViolationsTableType =
  (typeof ViolationsTableType)[keyof typeof ViolationsTableType];
