import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Metadata, Status } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { muiColors } from "@komodorio/design-system";

import { Deploy } from "../../../../generated/monitorsApi";
import { Tag } from "../../../common/Card/CardWithTag";

import { DeployCard } from "./DeployCard";
import { CardPadding } from "./styles";

const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  gap: 0.5rem;
`;

interface RelatedServicesHeaderProps {
  title: string;
  count: number;
  onExpand: (value: boolean) => void;
}

export const UpstreamTag = "upstream dependency";
export const DownstreamTag = "downstream dependency";
export const UpstreamRelatedDeploysTitle = "In related upstream services";
export const DownstreamRelatedDeploysTitle = "In related downstream services";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CardHeader: React.FC<{
  name: string;
  namespace: string;
}> = ({ name, namespace }) => {
  return (
    <CardHeaderContainer>
      <Metadata title="deployment" value={name}></Metadata>
      <Metadata title="namespace" value={namespace}></Metadata>
    </CardHeaderContainer>
  );
};

const RelatedServicesHeader: React.FC<RelatedServicesHeaderProps> = ({
  title,
  count,
  onExpand,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <HeaderContainer
      data-testid={`related-services-header-${title}`}
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        onExpand(!isExpanded);
        setIsExpanded(!isExpanded);
      }}
    >
      <IconButton size="small">
        <ExpandMore
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
      <Typography variant="h3" color={muiColors.gray[800]}>
        {title}
      </Typography>
      <Status
        color={"neutral"}
        label={`${count} dependencies`}
        size={"small"}
      />
    </HeaderContainer>
  );
};

const RelatedServicesSection: React.FC<{
  deploys: Deploy[];
  title: string;
  tag: string;
}> = ({ deploys, title, tag }) => {
  const [expanded, setExpanded] = useState(false);

  const sortedDeploys = useMemo(() => {
    return deploys.sort(
      (a, b) =>
        new Date(b.eventTime ?? "").getTime() -
        new Date(a.eventTime ?? "").getTime()
    );
  }, [deploys]);

  return (
    <SectionContainer>
      <RelatedServicesHeader
        title={title}
        count={sortedDeploys.length}
        onExpand={(val: boolean) => {
          setExpanded(val);
        }}
      />
      {expanded &&
        sortedDeploys.map((deploy) => (
          <CardPadding>
            <Tag color={muiColors.gray[600]}>
              <Typography
                className="tag"
                variant="overline"
                color={muiColors.common.white}
                fontSize={"12px"}
                lineHeight={"19.2px"}
                fontWeight={500}
              >
                {tag}
              </Typography>
            </Tag>
            <DeployCard
              customHeader={
                deploy.deploymentName && deploy.namespace ? (
                  <CardHeader
                    name={deploy.deploymentName ?? ""}
                    namespace={deploy.namespace ?? ""}
                  />
                ) : null
              }
              deploy={deploy}
              isCollapsed={true}
              canRollback={false}
            />
          </CardPadding>
        ))}
    </SectionContainer>
  );
};

const RelatedServicesDeploysCards: React.FC<{
  upstream: Deploy[];
  downstream: Deploy[];
}> = ({ upstream, downstream }) => {
  return (
    <Container>
      {upstream.length > 0 && (
        <RelatedServicesSection
          deploys={upstream}
          tag={UpstreamTag}
          title={UpstreamRelatedDeploysTitle}
        />
      )}
      {downstream.length > 0 && (
        <RelatedServicesSection
          deploys={downstream}
          tag={DownstreamTag}
          title={DownstreamRelatedDeploysTitle}
        />
      )}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RelatedServicesDeploysCards;
