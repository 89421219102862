import React, { useMemo } from "react";
import styled from "styled-components";
import {
  ComposedChart,
  Line,
  LabelList,
  Bar,
  XAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { muiColors } from "@komodorio/design-system";

import { SumByDayDataSumByDayInner } from "../../../../../../../../../../generated/applicationInsights";
import { getDateKey } from "../../utils/utils";

import { CustomLabel } from "./CustomLabel/CustomLabel";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomLegend } from "./CustomLegend/CustomLegend";
import { MinimizedView } from "./MinimizedView/MinimizedView";
import { CustomTooltip } from "./CustomTooltip/CustomTooltip";

export const PREVIOUS_AVG_TEXT = "previousAvg";
export const CURRENT_AVG_TEXT = "currentAvg";

const PREVIOUS_AVG_LABEL = "previousAvgLabel";
const CURRENT_AVG_LABEL = "currentAvgLabel";
const DATE_KEY = "dateKey";

interface ComparisonGraphProps {
  previousDatapoints: SumByDayDataSumByDayInner[];
  previousAvg: number;
  currentDatapoints: SumByDayDataSumByDayInner[];
  currentAvg: number;
  isMaximized: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

export const ComparisonGraph: React.FC<ComparisonGraphProps> = ({
  previousDatapoints,
  previousAvg,
  currentDatapoints,
  currentAvg,
  isMaximized,
}) => {
  const data = useMemo(() => {
    const fullPreviousDataPoints = previousDatapoints.map(
      (datapoint, index) => {
        return {
          ...datapoint,
          [DATE_KEY]: getDateKey(datapoint.ts),
          [PREVIOUS_AVG_TEXT]: previousAvg,
          [PREVIOUS_AVG_LABEL]: index === 0 ? previousAvg : null,
        };
      }
    );

    const fullCurrentDataPoints = currentDatapoints.map((datapoint, index) => {
      return {
        ...datapoint,
        [DATE_KEY]: getDateKey(datapoint.ts),
        [CURRENT_AVG_TEXT]: currentAvg,
        [CURRENT_AVG_LABEL]: index === 0 ? currentAvg : null,
      };
    });

    return [...fullPreviousDataPoints, ...fullCurrentDataPoints];
  }, [currentAvg, currentDatapoints, previousAvg, previousDatapoints]);

  const thisTimeColor = useMemo(() => {
    const color =
      currentAvg > previousAvg ? muiColors.orange[500] : muiColors.teal[600];
    return color ?? "";
  }, [currentAvg, previousAvg]);

  const content = useMemo(
    () => (
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {isMaximized && (
            <Tooltip content={(props) => <CustomTooltip data={props} />} />
          )}
          {isMaximized ? (
            <Legend align="left" content={<CustomLegend />} />
          ) : (
            <Legend
              content={
                <MinimizedView
                  previousDatapoints={previousDatapoints}
                  currentDatapoints={currentDatapoints}
                />
              }
            />
          )}
          <Bar
            dataKey="value"
            barSize={isMaximized ? 30 : 12}
            fill={muiColors.gray[200]}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey={PREVIOUS_AVG_TEXT}
            stroke={muiColors.gray[500]}
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
          >
            {isMaximized && (
              <LabelList
                dataKey={PREVIOUS_AVG_LABEL}
                content={<CustomLabel />}
                position="top"
              />
            )}
          </Line>
          <Line
            type="monotone"
            dataKey={CURRENT_AVG_TEXT}
            stroke={thisTimeColor}
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
          >
            {isMaximized && (
              <LabelList
                dataKey={CURRENT_AVG_LABEL}
                content={<CustomLabel color={thisTimeColor} />}
                position="top"
              />
            )}
          </Line>
          <XAxis
            dataKey={DATE_KEY}
            tick={{ fontSize: 12 }}
            hide={!isMaximized}
          />
        </ComposedChart>
      </ResponsiveContainer>
    ),
    [currentDatapoints, data, isMaximized, previousDatapoints, thisTimeColor]
  );

  return <Container>{content}</Container>;
};
