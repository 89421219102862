import React from "react";
import styled from "styled-components";

import { blueForUI, blueForUIText } from "../../../../../Colors";

const Container = styled.div`
  display: inline-block;
  background-color: ${blueForUI};
  color: ${blueForUIText};
  padding: 0.25em 0.5em;
  border-radius: 0.4em;
  margin: 0 0.25rem 0.25rem 0;
  font-weight: bold;
`;

export const TagsContainer = styled.div`
  margin: 0 -0.25rem -0.25rem 0;
`;

interface LabeledTagProps {
  label: string;
  value: string;
}

const LabeledTag: React.FC<LabeledTagProps> = ({ label, value }) => (
  <Container>
    {label !== value ? `${label}: ` : null}
    {value}
  </Container>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LabeledTag;
