import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CR from "../../../resources/customResource";
import {
  DescribeDateItem,
  DescribeTextItem,
} from "../../DescribeTab/common/DescribeItem";
import { DescribeLoader } from "../../DescribeTab/common/DescribeLoader";
import {
  DescribeContainer,
  DescribePanel,
} from "../../DescribeTab/common/DescribeSection";
import { DescribeSectionDivider } from "../../DescribeTab/common/DescribeSectionDivider";
import { DescribeKeyValueItem } from "../../DescribeTab/common/DescribeTagListItem";

import { useGetCRDPrinterColumns } from "./useGetCRDPrinterColumns";
import { AdditionalPrinterColumns } from "./AdditionalPrinterColumns";

interface CRetailsTabProps {
  resource: CR;
}

export const CustomResourceDetailsTab: React.FC<CRetailsTabProps> = ({
  resource,
}) => {
  const { agentId, cluster, crdName } = resource;
  const additionalPrinterColumns = useGetCRDPrinterColumns({
    agentId,
    cluster,
    crdName,
  });

  if (!resource.id) return <DescribeLoader />;

  const cr = resource.fullObj;
  const { creationTimestamp, name, annotations, namespace, labels } =
    cr.metadata ?? {};

  return (
    <DescribeContainer>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />
        <DescribeTextItem name="Namespace" value={namespace} />

        <DescribeSectionDivider />

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        {labels && <DescribeKeyValueItem name="Labels" value={labels} />}

        <AdditionalPrinterColumns
          customResource={cr}
          additionalPrinterColumns={additionalPrinterColumns}
        />
      </DescribePanel>
    </DescribeContainer>
  );
};
