import { useMemo } from "react";

import { GraphDataPoint } from "../types";

export const INVALID_DATAPOINT_VALUE = -1;

const isInvalidDatapoint = (value: number): boolean => {
  return value === INVALID_DATAPOINT_VALUE;
};

export const useAvoidNoData = (
  datapoints: GraphDataPoint[] | undefined
): GraphDataPoint[] | undefined => {
  return useMemo(() => {
    if (!datapoints) {
      return undefined;
    }

    return datapoints.map((datapoint) => {
      const values = Object.entries(datapoint).map(([key, value]) => [
        key,
        isInvalidDatapoint(value) ? null : value,
      ]) as unknown as [keyof GraphDataPoint, number | null][];
      return Object.fromEntries(values) as unknown as GraphDataPoint;
    });
  }, [datapoints]);
};
