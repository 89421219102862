import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Close from "@mui/icons-material/Close";
import React, { MouseEvent, useMemo } from "react";
import styled from "styled-components";

import { Uid } from "../../../shared/utils/generateUid";
import EmptyStateGraph, { EmptyStateStatus } from "../EmptyStateGraph";
import { useIsMetricsDrawerSupported } from "../metricsHooks";
import { getEmptyGraphStatus } from "../utils";

export type EnlargedMetricsGraphProps = {
  uid: Uid | undefined;
  onClose: () => void;
  title: string | React.ReactNode;
  controls?: React.ReactNode[];
  children: React.ReactNode;
  info?: React.ReactNode;
  hasGraphData: boolean;
  error: unknown | null;
  loading: boolean;
  shouldCloseOnClickCapture?: (e: MouseEvent<HTMLDivElement>) => boolean;
};

const InfoContainer = styled.div`
  display: flex;
  padding: 0 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const EnlargedMetricsGraph = ({
  uid,
  onClose,
  title,
  controls,
  children,
  info,
  hasGraphData,
  loading,
  error,
  shouldCloseOnClickCapture,
}: EnlargedMetricsGraphProps) => {
  const breadcrumbs = useMemo(() => {
    if (!uid) {
      return [];
    }
    return [uid.cluster, uid.namespace, uid.name].filter(Boolean);
  }, [uid]);

  const isMetricsSupported = useIsMetricsDrawerSupported(uid?.cluster);

  const status: EmptyStateStatus = getEmptyGraphStatus({
    data: { loading, error },
    isMetricsSupported,
    isOverRetention: false, // TODO: add shared util isOverRetention()
  });

  const onClickCapture = (e: MouseEvent<HTMLDivElement>) => {
    if (shouldCloseOnClickCapture?.(e)) {
      setTimeout(onClose, 1);
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xl"
      onClose={onClose}
      onClickCapture={onClickCapture}
    >
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="column" flexGrow={1}>
            {breadcrumbs.length > 0 && (
              <Breadcrumbs
                separator={
                  <ChevronRight
                    fontSize="small"
                    sx={{ width: 16, height: 16 }}
                  />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs.map((breadcrumb) => (
                  <Typography
                    key={breadcrumb}
                    variant="body3"
                    color="text.secondary"
                  >
                    {breadcrumb}
                  </Typography>
                ))}
              </Breadcrumbs>
            )}
            <Typography variant="h3" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          </Box>
          <Box display="flex" gap="8px">
            {controls}
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ height: "500px", padding: "24px", alignContent: "flex-start" }}
        dividers={true}
      >
        {info && <InfoContainer>{info}</InfoContainer>}
        {!hasGraphData ? (
          <EmptyStateGraph status={status} excludeAxes graphHeight={"500px"} />
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  );
};
