import React, { useEffect } from "react";
import styled from "styled-components";

import { ScalingMetric } from "../hpaReachedMaxTypes";
import { WorkloadMetricsTabContextProvider } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/context/WorkloadMetricsTabContext";
import { Timeframe } from "../../../../../../../../../../shared/types/TimeWindow";
import {
  MetricsFullTimeWindow,
  MetricType,
} from "../../../../../../../../../Metrics/types";
import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { LoadingStateIds } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabConstants";

import { SingleMetricsGraph } from "./SingleMetricsGraph";

const Container = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 32px;
  & > div {
    flex-grow: 1;
    width: 50%;
  }
`;

type MetricsGraphsProps = {
  scalingMetricsAsRecord: Record<MetricType, ScalingMetric> | undefined;
};

const defaultTimeWindow: MetricsFullTimeWindow = {
  start: new Date(),
  end: new Date(),
  timeframe: Timeframe.Last7Days,
};

export const MetricsGraphs: React.FC<MetricsGraphsProps> = ({
  scalingMetricsAsRecord,
}) => {
  const { setLoadingState } = useHpaReachedMaxContext();
  const { cpu, memory } = scalingMetricsAsRecord ?? {};
  const hasCpu = !!cpu && !!cpu.value;
  const hasMemory = !!memory && !!memory.value;

  useEffect(() => {
    if (!hasCpu) {
      setLoadingState({ [LoadingStateIds.workLoadCPU]: false });
    }
    if (!hasMemory) {
      setLoadingState({ [LoadingStateIds.workLoadMemory]: false });
    }
  }, [hasCpu, hasMemory, setLoadingState]);

  return (
    <Container>
      {hasCpu && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={defaultTimeWindow}
          onLoadingStateChange={setLoadingState}
        >
          <SingleMetricsGraph {...cpu} />
        </WorkloadMetricsTabContextProvider>
      )}
      {hasMemory && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={defaultTimeWindow}
          onLoadingStateChange={setLoadingState}
        >
          <SingleMetricsGraph {...memory} />
        </WorkloadMetricsTabContextProvider>
      )}
    </Container>
  );
};
