import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { MultiSelect, OptionType } from "@komodorio/design-system/deprecated";

import { Variables } from "../../common/types";
import {
  FormTextInput,
  ErrorMessage,
  Label,
  lightStyle,
} from "../common/styles";
import { useFormValidations } from "../../../../shared/context/ValidationsProvider";
import { validateDuration } from "../common/TriggerConditionsSection";
import {
  All_CATEGORIES_CHAR,
  AvailabilityCategories,
} from "../AvailabilityRuleSections";
import {
  getCategoriesFromOptions,
  validateCategories,
} from "../AvailabilityRuleSections/AvailabilityTriggersSections";
import { ZIndex } from "../../../../constants/zIndex";
import { InfoLink } from "../../../common/InfoLink";
import { AVAILABILITY_MONITOR_DOCS_LINK } from "../../../../shared/constants/docsLinks";

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 0.4rem;
`;
const Container = styled(FlexRow)`
  ${lightStyle};
`;
const podTriggerText = (
  <>
    Standalone pod is <b>not ready</b> for more than
  </>
);
const MarginInline = styled.div`
  margin-inline: 0.4rem;
`;

export const PodTriggerConditionsSection: React.FC<{
  id: string;
  variables: Variables | undefined;
  handleChange: (variables: Variables) => void;
}> = ({ id, variables, handleChange }) => {
  const [stringDuration, setStringDuration] = useState("");
  useEffect(() => {
    if (variables?.duration) {
      setStringDuration(variables.duration.toString() ?? "");
    }
  }, [variables?.duration]);
  const { errors, setError } = useFormValidations();

  const [durationValidationKey, categoriesValidationKey] = [
    `${id}-duration`,
    `${id}-categories`,
  ];
  const durationError = errors[durationValidationKey];
  const categoriesError = errors[categoriesValidationKey];

  const handleDurationChange = (value: string) => {
    setStringDuration(value);
    const error = validateDuration(value);
    if (!error) {
      handleChange({ ...variables, duration: Number(value) });
    }
    setError(id, error);
  };
  const categories = useMemo(() => {
    return variables?.categories
      ? variables?.categories
      : AvailabilityCategories.map((c) => c.value);
  }, [variables?.categories]);

  const categoriesOptions = useMemo(() => {
    if (categories.some((c) => c === All_CATEGORIES_CHAR)) {
      return AvailabilityCategories.map((o) => ({
        ...o,
        disabled: o.value !== All_CATEGORIES_CHAR,
      }));
    }
    return AvailabilityCategories;
  }, [categories]);

  const handleCategoriesChange = useCallback(
    (selectedOptions: OptionType<string>[]) => {
      const errorMessage = validateCategories(selectedOptions);
      handleChange({
        ...variables,
        categories: getCategoriesFromOptions(selectedOptions),
      });
      setError(categoriesValidationKey, errorMessage);
    },
    [categoriesValidationKey, handleChange, setError, variables]
  );

  return (
    <>
      <Container>
        <Label>{podTriggerText}</Label>
        <FormTextInput
          autoFocus
          isValid={!durationError}
          value={stringDuration}
          placeholder={"e.g. 30"}
          onChange={(e) => handleDurationChange(e.target.value)}
        />
        seconds,{" "}
        <FlexRow>
          for categories:
          <MarginInline>
            <MultiSelect
              size="medium"
              width="17rem"
              value={categoriesOptions.filter((o) =>
                categories.includes(o.value)
              )}
              placeholder={"Select categories"}
              options={categoriesOptions}
              onChange={handleCategoriesChange}
              listMaxHeight="20rem"
              listZIndex={ZIndex.AboveEventDrawer.toString()}
            />
          </MarginInline>
        </FlexRow>
        <InfoLink
          url={AVAILABILITY_MONITOR_DOCS_LINK}
          text={"What are categories?"}
        />
      </Container>
      <ErrorMessage>{durationError || categoriesError}</ErrorMessage>
    </>
  );
};
