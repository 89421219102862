import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

export const ImageContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 0rem 1rem;
`;
export const StyledImg = styled.img`
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 2.6px;
  padding: 0.5rem;
  width: 5rem;
  height: 3rem;
`;
export const Images = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const ListItem = styled(Typography).attrs({
  color: muiColors.gray[700],
  size: "large",
})`
  display: list-item;
  margin-left: 2rem;
  whitespace: break-spaces;
`;
