import React from "react";
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

import { ViolationHistoryItem } from "../../../../../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import {
  formatTimestamp,
  formatUserEmail,
  getViolationDescription,
} from "./historyUtils";
import { DotSeparator } from "./DotSeparator";
import { ContentContainer, StyledTimelineDot } from "./historyStyles";

const {
  violationDrawer: {
    history: { historyItemTextContent: historyItemAriaLabel },
  },
} = reliabilityArialLabels;

export const komodorUser = "Komodor";

type HistoryItemProps = {
  item: ViolationHistoryItem;
  prevItem: ViolationHistoryItem | undefined;
};

export const HistoryItem: React.FC<HistoryItemProps> = ({ item, prevItem }) => {
  const description = getViolationDescription(item, prevItem);
  return (
    <TimelineItem>
      <TimelineSeparator>
        <StyledTimelineDot />
        {!!prevItem && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <ContentContainer aria-label={historyItemAriaLabel}>
          <Typography variant={"body2"}>
            {formatTimestamp(item.modifiedAt)}
          </Typography>
          <DotSeparator />
          <Typography variant={"h5"}>{description}</Typography>
          <DotSeparator />
          <Typography variant={"body2"} color={"text.secondary"}>
            {formatUserEmail(item.userEmail || komodorUser)}
          </Typography>
        </ContentContainer>
      </TimelineContent>
    </TimelineItem>
  );
};
