/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { muiTheme } from "@komodorio/design-system";
import { sortBy } from "lodash";

import { WorkspaceKind } from "../../../generated/workspacesApi";
import {
  TypedClusterWorkspace,
  TypedClustersGroupWorkspace,
  TypedWorkspace,
} from "../../../shared/hooks/workspaces-api/types";
import {
  WORKSPACE_ID_URL_PARAM,
  useAppViewsStore,
} from "../../../shared/store/appViewsStore/appViewsStore";
import { storedLastAppViewsData } from "../../../shared/store/appViewsStore/constants";
import {
  INSPECTION_ROUTE,
  SERVICES,
  SERVICE_ID_PARAM,
  WORKSPACE_ROUTE,
} from "../../routes/routes";
import { useGetWorkspaces } from "../../../shared/hooks/workspaces-api/workspaces/useGetWorkspaces";
import {
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getOverviewPageRoute } from "../../appView/sections/AppView/utils/getRoutes";
import { storedLastCluster } from "../../Inspection/utils/localStorage";

import {
  ALL_CLUSTERS_VALUE,
  FONT,
  WORKSPACE_ITEM_PADDING,
  WorkspaceOption,
} from "./constants";

import { useGetWorkspaceById } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaceById";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { getTextWidth } from "@/shared/utils/getTextWidth";

export const useWorkspaces = (): {
  currentWorkspaceId: string | undefined;
  currentWorkspace: TypedWorkspace | undefined;
  workspaces: TypedWorkspace[] | undefined;
  isLoading: boolean;
  resolvedClusterWorkspace: TypedClusterWorkspace | undefined;
  resolvedClustersGroupWorkspace: TypedClustersGroupWorkspace | undefined;
  isClusterWorkspace: boolean;
  isClustersGroupWorkspace: boolean;
  isNamespaceWorkspace: boolean;
  isWorkspaceKindBackendFiltered: boolean;
  error: unknown;
} => {
  const currentWorkspaceId = useAppViewsStore(selectedAppViewIdSelector);
  const { data: workspaces, isLoading, error } = useGetWorkspaces();
  const { data: extraWorkspaceData } = useGetWorkspaceById(
    { id: currentWorkspaceId ?? "" },
    {
      enabled: currentWorkspaceId !== undefined,
    }
  );

  const workspacesWithExtraData = useMemo(() => {
    return workspaces?.map((w) =>
      w.id === extraWorkspaceData?.id ? extraWorkspaceData : w
    );
  }, [extraWorkspaceData, workspaces]);

  const currentWorkspace = useMemo(() => {
    if (isLoading || !workspacesWithExtraData) {
      return undefined;
    }

    return workspacesWithExtraData.find(
      (workspace) => workspace.id === currentWorkspaceId
    );
  }, [currentWorkspaceId, isLoading, workspacesWithExtraData]);

  const isClusterWorkspace = useMemo(() => {
    return currentWorkspace?.kind === WorkspaceKind.Cluster;
  }, [currentWorkspace]);

  const isClustersGroupWorkspace = useMemo(() => {
    return currentWorkspace?.kind === WorkspaceKind.ClustersGroup;
  }, [currentWorkspace]);

  const isNamespaceWorkspace = useMemo(() => {
    return currentWorkspace?.kind === WorkspaceKind.Namespace;
  }, [currentWorkspace]);

  const isWorkspaceKindBackendFiltered = useMemo(() => {
    return (
      isClusterWorkspace || isClustersGroupWorkspace || isNamespaceWorkspace
    );
  }, [isClusterWorkspace, isClustersGroupWorkspace, isNamespaceWorkspace]);

  const resolvedClusterWorkspace: TypedClusterWorkspace | undefined =
    !isLoading && isClusterWorkspace && currentWorkspace
      ? (currentWorkspace as TypedClusterWorkspace)
      : undefined;

  const resolvedClustersGroupWorkspace:
    | TypedClustersGroupWorkspace
    | undefined =
    !isLoading && isClustersGroupWorkspace && currentWorkspace
      ? (currentWorkspace as TypedClustersGroupWorkspace)
      : undefined;

  return {
    currentWorkspaceId,
    currentWorkspace,
    workspaces: workspacesWithExtraData,
    isLoading,
    resolvedClusterWorkspace,
    resolvedClustersGroupWorkspace,
    isClusterWorkspace,
    isClustersGroupWorkspace,
    isNamespaceWorkspace,
    isWorkspaceKindBackendFiltered,
    error,
  };
};

export const useNavigateToWorkspace = () => {
  const location = useLocation();
  const inServicePage = useMatch(`${SERVICES}/:${SERVICE_ID_PARAM}`);
  const navigate = useNavigate();
  const setSelectedWorkspaceId = useAppViewsStore(setSelectedAppViewIdSelector);
  return useCallback(
    (workspace?: TypedWorkspace) => {
      const searchParams = new URLSearchParams();
      setSelectedWorkspaceId(workspace?.id);

      if (workspace?.id) {
        storedLastAppViewsData.set(JSON.stringify({ id: workspace.id }));
        searchParams.set(WORKSPACE_ID_URL_PARAM, workspace.id);
      } else {
        storedLastAppViewsData.remove();
        searchParams.delete(WORKSPACE_ID_URL_PARAM);
      }

      if (inServicePage && workspace?.id) {
        navigate({
          pathname: `${WORKSPACE_ROUTE}/${workspace.id}`,
          search: searchParams.toString(),
        });
        return;
      }

      if (
        workspace?.kind === WorkspaceKind.Cluster &&
        location.pathname.startsWith(INSPECTION_ROUTE)
      ) {
        storedLastCluster.set(workspace.value.clusterName);
        navigate({
          pathname: `${location.pathname}/../${workspace.value.clusterName}`,
          search: searchParams.toString(),
        });
        return;
      }

      if (
        workspace?.kind === WorkspaceKind.ClustersGroup &&
        location.pathname.startsWith(INSPECTION_ROUTE)
      ) {
        if (workspace.value.clusters && workspace.value.clusters.length > 0) {
          const cluster = workspace.value.clusters[0];
          storedLastCluster.set(cluster);
          navigate({
            pathname: `${location.pathname}/../${cluster}`,
            search: searchParams.toString(),
          });
          return;
        }

        storedLastCluster.remove();
        navigate(
          {
            pathname: `../`,
            search: searchParams.toString(),
          },
          { relative: "path" }
        );
        return;
      }

      if (location.pathname.startsWith(WORKSPACE_ROUTE)) {
        if (workspace) {
          navigate({
            pathname: getOverviewPageRoute(workspace.id),
            search: searchParams.toString(),
          });
        } else {
          navigate({ pathname: "/", search: searchParams.toString() });
        }
        return;
      }

      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [inServicePage, location.pathname, navigate, setSelectedWorkspaceId]
  );
};

export const useWorkspaceOptions = (
  query: string,
  workspaces: TypedWorkspace[] | undefined
) => {
  const { canManageWorkspaces } = useHasPermissions();
  const toOption = useCallback(
    (w: TypedWorkspace): WorkspaceOption => {
      let options: Pick<
        WorkspaceOption,
        "label" | "tooltip" | "disabled" | "editable"
      > = {
        label: w.name,
        disabled: false,
        tooltip: undefined,
        editable: canManageWorkspaces,
      };
      switch (w.kind) {
        case WorkspaceKind.Cluster:
          options.label = w.value.clusterName;
          options.editable = false;
          break;
        case WorkspaceKind.ClustersGroup:
          options = {
            label: `${w.name} (${w.value.authorizedClusters})`,
            disabled: w.value.authorizedClusters === 0,
            tooltip:
              w.value.authorizedClusters === 0
                ? "The cluster group is unavailable due to lack of clusters"
                : undefined,
            editable: canManageWorkspaces,
          };
          break;
        default:
          break;
      }
      const matchIdx =
        options.label?.toLowerCase().indexOf(query.toLowerCase()) ?? -1;
      return {
        ...options,
        width: getTextWidth(options.label, FONT) + WORKSPACE_ITEM_PADDING,
        value: w.id,
        workspace: w,
        match:
          matchIdx === -1
            ? null
            : { start: matchIdx, end: matchIdx + query.length },
      };
    },
    [canManageWorkspaces, query]
  );

  const filteredOptions = useMemo(() => {
    const options = sortBy(
      workspaces?.map(toOption),
      ({ disabled }) => disabled,
      ({ label }) => label
    );

    return options?.filter((w) => w.match !== null) ?? [];
  }, [toOption, workspaces]);

  const clusterGroupsOptions = useMemo(
    () =>
      filteredOptions.filter(
        (w) => w.workspace?.kind === WorkspaceKind.ClustersGroup
      ) ?? [],
    [filteredOptions]
  );
  const clusterOptions = useMemo(() => {
    const options =
      filteredOptions.filter(
        (w) =>
          w.workspace?.kind === WorkspaceKind.Cluster ||
          w.value === ALL_CLUSTERS_VALUE.value
      ) ?? [];
    return options;
  }, [filteredOptions]);
  const workspacesOptions = useMemo(
    () =>
      filteredOptions.filter(
        (w) =>
          w.workspace !== undefined &&
          (
            [
              WorkspaceKind.Label,
              WorkspaceKind.Namespace,
              WorkspaceKind.ServiceId,
            ] as WorkspaceKind[]
          ).includes(w.workspace.kind)
      ) ?? [],
    [filteredOptions]
  );

  return useMemo(
    () => ({
      clusterOptions,
      clusterGroupsOptions,
      workspacesOptions,
      filteredOptions,
    }),
    [clusterGroupsOptions, clusterOptions, filteredOptions, workspacesOptions]
  );
};

export const useTabs = ({
  numClusters,
  numWorkspaces,
  numClusterGroups,
}: {
  numClusters: number;
  numWorkspaces: number;
  numClusterGroups: number;
}) => {
  return useMemo(
    () => [
      {
        ...ALL_CLUSTERS_VALUE,
        sx: {
          "&.MuiTab-root.MuiButtonBase-root": {
            alignItems: "flex-start",
            padding: "9px 16px",
            color: muiTheme.palette.primary.main,
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      {
        value: "clusters",
        label: `Clusters (${numClusters})`,
      },
      {
        value: "clusters_group",
        label: `Cluster Groups (${numClusterGroups})`,
      },
      {
        value: "workspaces",
        label: `Workspaces (${numWorkspaces})`,
      },
    ],
    [numClusterGroups, numClusters, numWorkspaces]
  );
};

export const useSyncCurrentWorkspaceIdToUrlIfMissing = () => {
  const currentWorkspaceId = useAppViewsStore(selectedAppViewIdSelector);
  const setCurrentWorkspaceId = useAppViewsStore(setSelectedAppViewIdSelector);

  const [workspaceIdInUrl, setWorkspaceIdInUrl] = useStateInSearchParams(
    WORKSPACE_ID_URL_PARAM
  );
  useEffect(() => {
    if (!workspaceIdInUrl) {
      setWorkspaceIdInUrl(currentWorkspaceId ?? "null", true);
    } else {
      setCurrentWorkspaceId(
        workspaceIdInUrl === "null" ? undefined : workspaceIdInUrl
      );
    }
  }, [
    currentWorkspaceId,
    setCurrentWorkspaceId,
    setWorkspaceIdInUrl,
    workspaceIdInUrl,
  ]);
};

export const useIsClusterValidInCurrentWorkspace = (
  cluster: string | undefined
): boolean => {
  const { resolvedClusterWorkspace, resolvedClustersGroupWorkspace } =
    useWorkspaces();
  if (cluster && resolvedClusterWorkspace) {
    return cluster === resolvedClusterWorkspace.value.clusterName;
  }

  if (cluster && resolvedClustersGroupWorkspace) {
    return (resolvedClustersGroupWorkspace.value.clusters ?? []).includes(
      cluster
    );
  }

  return true;
};
