import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { H4 } from "../../typography";
import { usePodLogsStore } from "../../../../shared/hooks/podLogs/store/podLogsStore";
import { resetPodLogs } from "../../../../shared/hooks/podLogs/store/podLogsStoreSelectors";
import { buildIdentifier } from "../../../../shared/hooks/podLogs/useGetLivePodLogs/buildIdentifier";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { useCloseSocketSessionById } from "../../../../shared/sockets/useCloseSocketById";

const Toggle = styled.div<{ direction: "left" | "right"; selected: boolean }>`
  border: 1px solid ${palette.blue[500]};
  border-radius: ${({ direction }) =>
    direction === "right" ? "0 4px 4px 0" : "4px 0 0 4px"};
  padding: 0.5rem;
  color: ${palette.blue[500]};
  ${({ selected }) =>
    selected &&
    `
        background-color: ${palette.blue[500]};
        color: white;
    `}
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
`;

interface PreviousLogsProps {
  pod: {
    name: string;
    id: string;
    cluster: string;
    namespace: string;
  };
  setIsPreviousLogs: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviousLogs: boolean;
  containerName?: string;
}

const PreviousLogs: React.FC<PreviousLogsProps> = ({
  pod,
  setIsPreviousLogs,
  isPreviousLogs,
  containerName,
}) => {
  const resetLogsInStore = usePodLogsStore(resetPodLogs);
  const closeSocketSession = useCloseSocketSessionById();
  const agentId = useActiveAgent(pod.cluster);

  const disconnectSocket = () => {
    const identifier = buildIdentifier({
      agentId,
      namespace: pod.namespace,
      podName: pod.name,
      containerName,
    });

    closeSocketSession(identifier);
  };

  const onToggle = (isPrevious: boolean) => {
    setIsPreviousLogs(isPrevious);
    resetLogsInStore();
    disconnectSocket();

    if (isPrevious) {
      dispatchEvent(AnalyticEvents.PodViewV2.Logs_Previous_Requested, {
        podName: pod.name,
        serviceId: pod.id,
        cluster: pod.cluster,
        namespace: pod.namespace,
      });
    }
  };

  return (
    <Container>
      <Toggle
        direction="left"
        selected={isPreviousLogs}
        onClick={() => onToggle(true)}
      >
        <H4>Previous</H4>
      </Toggle>
      <Toggle
        direction="right"
        selected={!isPreviousLogs}
        onClick={() => onToggle(false)}
      >
        <H4>Current</H4>
      </Toggle>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PreviousLogs;
