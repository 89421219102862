import { defaultOptions } from "../../../../../../shared/utils/formatCurrency";

export const formatPotentialSavings = (
  value: number | undefined,
  options?: Intl.NumberFormatOptions
): string => {
  if (value === undefined) {
    return "N/A";
  }
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
    style: "currency",
    notation: value > 99999 ? "compact" : "standard",
  }).format(value);
};
