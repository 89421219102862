import React, { ReactElement } from "react";
import { palette } from "@komodorio/design-system";
import { Typography, Select } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  > span {
    color: ${palette.gray[600]};
  }
`;
const PageSizes = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
];

export const PageSize = ({
  value,
  onChange,
}: {
  value: number;
  onChange(size: number): void;
}): ReactElement => {
  return (
    <Container>
      <Typography>Show</Typography>
      <Select
        style={{ maxWidth: "fit-content" }}
        value={
          PageSizes.find((option) => option.value === value) ?? PageSizes[0]
        }
        options={PageSizes}
        onChange={(selected) => {
          onChange(selected.value);
        }}
      />
      <Typography>per page</Typography>
    </Container>
  );
};
