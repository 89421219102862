import React, { useMemo } from "react";
import { get } from "lodash";
import { LaunchDarkly } from "@komodorio/design-system/icons";

import { EventDetailsContainer, DetailsExternalLink } from "../common";
import EventGroup from "../../../EventGroup";
import EventDetailsTitle from "../components/EventDetailsTitle";
import EventDetailsSection from "../components/EventDetailsSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LaunchDarklyEvent } from "../../../EventGroup/LaunchDarkly";

import { LaunchDarklyChanges } from "./LaunchDarklyChanges";
import { LaunchDarklyDetails } from "./LaunchDarklyDetails";
import { LaunchDarklyInfo } from "./LaunchDarklyInfo";

export interface LaunchdarklyEventGroups extends EventGroup {
  events: LaunchDarklyEvent[];
}
export interface LaunchdarklyDetailsProps {
  eventGroup: LaunchdarklyEventGroups;
}

export const LaunchdarklyDetails: React.FC<LaunchdarklyDetailsProps> = ({
  eventGroup,
}) => {
  const title = useMemo(
    () => `${eventGroup.events[0].name}: ${eventGroup.summary}`,
    [eventGroup]
  );

  const e = eventGroup.events[0];
  const link = `https://app.launchdarkly.com${get(
    e,
    "target._links.site.href"
  )}`;
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title}></EventDetailsTitle>
      <LaunchDarklyDetails event={e} />
      <LaunchDarklyChanges event={e} />
      <LaunchDarklyInfo event={e} />
      <EventDetailsSection title="Links" icon={LaunchDarkly}>
        <DetailsExternalLink
          href={link}
          title={`View '${e.name}' on Launchdarkly`}
        />
      </EventDetailsSection>
    </EventDetailsContainer>
  );
};
