import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { EventsApiApiV1DeploysEventsSearchGetRequest } from "../../generated/resourcesApi";

import { useBuildKomodorUidForKomodorService } from "./resources-api/resourcesAPIUtils";
import { useResourcesApiClient } from "./resources-api/client/apiClient";
import { fetchLatestDeployUntil } from "./resources-api/client/events/useSearchDeployEvents";
import { DEPLOY_EVENTS_SEARCH } from "./resources-api/requestResponseMaps";

export const useLatestDeployNewSpec = (
  serviceId: string | undefined
): { newSpec: unknown; refresh: () => void } => {
  const komodorUid = useBuildKomodorUidForKomodorService(serviceId ?? "");
  const params: EventsApiApiV1DeploysEventsSearchGetRequest | null =
    useMemo(() => {
      if (!komodorUid) return null;
      return {
        komodorUids: [komodorUid],
        order: "DESC",
        limit: 1,
        fields: ["id", "newSpec", "deploymentName"],
      };
    }, [komodorUid]);
  const apiClient = useResourcesApiClient();
  const { data, refetch } = useQuery([DEPLOY_EVENTS_SEARCH, params], () =>
    fetchLatestDeployUntil(apiClient, params)
  );

  const newSpec = useMemo(
    () =>
      data?.data?.[0]?.newSpec &&
      JSON.parse(data?.data?.[0]?.newSpec as unknown as string),
    [data?.data]
  );

  return { newSpec, refresh: refetch };
};
