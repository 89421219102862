import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

export enum SandboxStatusType {
  needSignup,
  needInstall,
  finishSetup,
}

export const getAccountStatus = (
  isActiveAgents: boolean,
  isAuthenticated: boolean
): SandboxStatusType => {
  if (!isAuthenticated) {
    return SandboxStatusType.needSignup;
  }
  if (!isActiveAgents) {
    return SandboxStatusType.needInstall;
  }
  return SandboxStatusType.finishSetup;
};

interface SandboxStatusProps {
  title: string;
  ctaText: string;
  event: string;
}

export enum Titles {
  needSignUp = "Sign up to start using Komodor with your environment",
  needInstall = "Install Komodor agent to start using Komodor with your environment",
  finishSetup = "You have Komodor installed! Good for you!",
}

export const SandboxAccountStatuses: {
  [key in SandboxStatusType]: SandboxStatusProps;
} = {
  [SandboxStatusType.needSignup]: {
    title: Titles.needSignUp,
    ctaText: "Get started",
    event: AnalyticEvents.UserManagement.SandboxCTAS.SandboxCTASignupClick,
  },
  [SandboxStatusType.needInstall]: {
    title: Titles.needInstall,
    ctaText: "Install Komodor",
    event:
      AnalyticEvents.UserManagement.SandboxCTAS.SandboxCTAInstallAgentClick,
  },
  [SandboxStatusType.finishSetup]: {
    title: Titles.finishSetup,
    ctaText: "Go to My Account",
    event:
      AnalyticEvents.UserManagement.SandboxCTAS.SandboxCTABackToKomodorClick,
  },
};
