import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { WorkloadMetricsContainers } from "../../../../../../generated/metricsApi";

export const useGetContainerOptions = (
  data?: WorkloadMetricsContainers
): MuiSelectionOption<string>[] | undefined =>
  useMemo(() => {
    if (!data) return;
    return data.containers.map((container) => {
      const name = container.containerName;
      return { value: name, label: name };
    });
  }, [data]);

export const useGetDatapoints = (
  wantedContainer: MuiSelectionOption<string> | undefined,
  res: WorkloadMetricsContainers | undefined
) =>
  useMemo(() => {
    if (!res || !wantedContainer) return;
    return res.containers.find(
      (container) => container.containerName === wantedContainer.value
    )?.dataPoints;
  }, [res, wantedContainer]);
