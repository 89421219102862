import React from "react";
import { Container, ContainerStatus } from "kubernetes-types/core/v1.d";
import { get } from "lodash";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";

import { DescribePanel } from "../../common/DescribeSection";
import { DescribeTextItem } from "../../common/DescribeItem";
import { DescribeTagListItem } from "../../common/DescribeTagListItem";
import { DescribeExpandedItem } from "../../common/DescribeExpandedItem";
import { StatusTag } from "../../common/StatusTag";
import { ReasonTag } from "../../common/ReasonTag";
import { DescribeSectionDivider } from "../../common/DescribeSectionDivider";

import { ContainerProbes } from "./ContainerProbes";
import { ContainerMounts } from "./ContainerMounts";
import { ContainerResources } from "./ContainerResources";
import { ContainerPorts } from "./ContainerPorts";

const ExpandedContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ExpandedItem = styled.div`
  display: flex;
  gap: 0.3rem;
`;

export const Containers: React.FC<{
  containers: Container[];
  statuses: ContainerStatus[] | undefined;
  agentId: string;
  cluster: string;
  isDeletedPod: boolean;
}> = ({ containers, statuses, agentId, cluster, isDeletedPod }) => {
  return (
    <>
      {containers.map((container) => {
        const releventState = statuses?.find(
          (s) => s.name === container.name
        )?.state;
        const state = Object.keys(releventState ?? {})[0];
        const reason =
          releventState?.terminated?.reason || releventState?.waiting?.reason;
        return (
          <DescribePanel key={container.name}>
            <DescribeExpandedItem
              title={
                <ExpandedContainer>
                  <Typography variant="title">{container.name}</Typography>
                  <ExpandedItem>
                    <Typography variant="text">Status:</Typography>
                    <StatusTag value={state} />
                  </ExpandedItem>
                  {reason && (
                    <ExpandedItem>
                      <Typography variant="text">Reason:</Typography>
                      <ReasonTag value={reason} />
                    </ExpandedItem>
                  )}
                </ExpandedContainer>
              }
            >
              <DescribeTextItem name="Image" value={container.image} />
              <DescribeTextItem
                name="ImagePullPolicy"
                value={container.imagePullPolicy}
              />
              {container.env?.length ? (
                <DescribeTagListItem
                  name="Environment"
                  value={container.env?.map(({ name, value, valueFrom }) => {
                    let hiddenValue;
                    if (valueFrom) {
                      const valueFromKey = Object.keys(valueFrom)[0];
                      const { name, key } = get(valueFrom, valueFromKey, {});
                      hiddenValue = valueFromKey;
                      if (name && key) hiddenValue += `(${name}.${key})`;
                    }
                    return `${name}: ${value ?? hiddenValue ?? "-"}`;
                  })}
                />
              ) : undefined}

              {container.command && (
                <DescribeTextItem
                  name="Command"
                  value={container.command.join(" ")}
                />
              )}

              <ContainerPorts
                container={container}
                agentId={agentId}
                cluster={cluster}
                showForwardButton={!isDeletedPod}
              />
              <ContainerResources container={container} />
              <ContainerMounts container={container} />
              <ContainerProbes container={container} />

              {container.args && (
                <>
                  <DescribeSectionDivider title={"ARGUMENTS"} />
                  <DescribeTextItem
                    name="Arguments"
                    value={container.args.join(" ")}
                  />
                </>
              )}
            </DescribeExpandedItem>
          </DescribePanel>
        );
      })}
    </>
  );
};
