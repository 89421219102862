import React from "react";
import Drawer from "@mui/material/Drawer";
import styled from "styled-components";

import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";

import {
  useCloseDrawer,
  useIsIgnoreChecksDrawerOpen,
} from "./ignoreChecksDrawerHooks";
import { TopSection } from "./TopSection";
import { Description } from "./Description";
import { IgnoreList } from "./IgnoreList";

const Container = styled.div`
  width: 864px;
`;

const {
  ignoreChecks: { drawerContainer: drawerContainerAriaLabel },
} = reliabilityArialLabels;

export const IgnoreChecksDrawer: React.FC = () => {
  const isOpen = useIsIgnoreChecksDrawerOpen();
  const closeCb = useCloseDrawer();

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeCb}>
      <Container aria-label={drawerContainerAriaLabel}>
        <TopSection onClose={closeCb} />
        <Description />
        <IgnoreList />
      </Container>
    </Drawer>
  );
};
