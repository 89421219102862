import { CpuUnit, MemoryUnit } from "../../Metrics/types";
import { ResourceUtilization } from "../../../generated/workspacesApi";

const BYTES_IN_MIB = 1048576; // 1024 * 1024
const BYTES_IN_GIB = 1073741824; // 1024 * 1024 * 1024
const BYTES_IN_TIB = 1099511627776; // 1024 * 1024 * 1024 * 1024
const MILLICORES_IN_CORE = 1000;

export const getMemoryUnit = (values: number[]): MemoryUnit => {
  if (values.some((value) => value >= BYTES_IN_TIB)) {
    return "TiB";
  } else if (values.some((value) => value >= BYTES_IN_GIB)) {
    return "GiB";
  } else {
    return "MiB";
  }
};

export const getCPUUnit = (values: number[]): CpuUnit => {
  if (values.some((value) => value >= MILLICORES_IN_CORE)) {
    return "Cores";
  } else {
    return "MilliCores";
  }
};

export const getValueBasedOnUnit = (
  unit: MemoryUnit | CpuUnit,
  valueInBytesOrMilliCores: number
) => {
  switch (unit) {
    case "TiB":
      return (valueInBytesOrMilliCores / BYTES_IN_TIB).toFixed(2);
    case "GiB":
      return (valueInBytesOrMilliCores / BYTES_IN_GIB).toFixed(2);
    case "MiB":
      return (valueInBytesOrMilliCores / BYTES_IN_MIB).toFixed(2);
    case "Cores":
      return (valueInBytesOrMilliCores / MILLICORES_IN_CORE).toFixed(2);
    case "MilliCores":
      return valueInBytesOrMilliCores;
  }
};

export const getTooltipUnits = (resources: ResourceUtilization) => {
  const memoryUnit = getMemoryUnit([
    resources.memory.capacity,
    resources.memory.allocated,
  ]);
  const memoryAllocated = getValueBasedOnUnit(
    memoryUnit,
    resources.memory.allocated
  );
  const memoryCapacity = getValueBasedOnUnit(
    memoryUnit,
    resources.memory.capacity
  );
  const cpuUnit = getCPUUnit([resources.cpu.capacity, resources.cpu.allocated]);
  const cpuAllocated = getValueBasedOnUnit(cpuUnit, resources.cpu.allocated);
  const cpuCapacity = getValueBasedOnUnit(cpuUnit, resources.cpu.capacity);

  return {
    memoryUnit,
    memoryAllocated,
    memoryCapacity,
    cpuUnit,
    cpuAllocated,
    cpuCapacity,
  };
};
