import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Props } from "recharts/types/component/Legend";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AmountOrDuration } from "../IssuesAfterDeploy/IssuesAfterDeployGraphChart";
import {
  AVG_TEXT,
  LAST_VALUE_KEY,
  VALUE_KEY,
} from "../IssuesAfterDeploy/IssuesAfterDeployGraph";

const LegendContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const LegendItemContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ColorIndication = styled.div`
  height: 4px;
  width: 8px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

type CustomLegendProps = Props & {
  numDays: number;
  type: AmountOrDuration;
};

export const CustomLegend: React.FC<CustomLegendProps> = ({
  numDays,
  type,
  ...props
}) => {
  const valueMapper: Record<string, string> = useMemo(() => {
    return {
      [LAST_VALUE_KEY]: "Spike",
      [AVG_TEXT]: `Last ${numDays}d average`,
      [VALUE_KEY]: type === "amount" ? "Issues" : "Average duration",
    };
  }, [numDays, type]);
  const { payload } = props;

  const content = useMemo(
    () =>
      payload?.map((legendItem) => (
        <LegendItemContainer key={legendItem.value}>
          <ColorIndication color={legendItem.color} />
          <Typography variant="caption" color={muiColors.gray[600]}>
            {valueMapper[legendItem.value]}
          </Typography>
        </LegendItemContainer>
      )),
    [payload, valueMapper]
  );

  return <LegendContainer>{content}</LegendContainer>;
};
