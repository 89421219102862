import React, { useCallback, useEffect } from "react";

import {
  Variables,
  WorkflowConfigType,
  WorkflowConfiguration,
  MonitorSensor,
  SinksOptions,
} from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";
import { ALL_CATEGORIES } from "../AvailabilityRuleSections";
import NotifyOnCategories from "../common/SinkSection/NotifyOnCategories";

import { PodTriggerConditionsSection } from "./PodTriggerConditionsSection";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const PodSelectorTypes = ["namespaces", "annotations", "labels"];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const podDefaultRule: WorkflowConfiguration = {
  id: "",
  name: "",
  createdAt: new Date(),
  type: WorkflowConfigType.Pod,
  active: true,
  sensors: [{ cluster: "" }],
  sinksOptions: {
    notifyOn: ALL_CATEGORIES,
  },
  variables: {
    duration: 120,
    categories: ALL_CATEGORIES,
  },
};

const PodRuleSections: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = useCallback(
    (sensor: MonitorSensor) => {
      setRule((prev) => ({
        ...prev,
        sensors: [sensor],
      }));
    },
    [setRule]
  );
  const handleVariablesChange = useCallback(
    (variables: Variables) => {
      setRule((prev) => ({
        ...prev,
        variables,
      }));
    },
    [setRule]
  );
  const handleNotifyOnChange = useCallback(
    (sinksOptions: SinksOptions) => {
      setRule((prev) => ({
        ...prev,
        sinksOptions,
      }));
    },
    [setRule]
  );
  useEffect(() => {
    if (!rule?.variables?.categories) {
      handleVariablesChange({
        ...rule?.variables,
        categories: ALL_CATEGORIES,
      });
    }
  }, [handleVariablesChange, rule?.variables]);

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <PodTriggerConditionsSection
          id="pod-conditions"
          variables={rule.variables}
          handleChange={handleVariablesChange}
        />
      </RuleStepSectionContainer>
      <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
        <ScopeSection
          id="scope"
          sensor={rule.sensors[0] as MonitorSensor}
          selectorTypes={PodSelectorTypes}
          handleChange={handleSensorChange}
        />
      </RuleStepSectionContainer>
      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <NotifyOnCategories rule={rule} handleChange={handleNotifyOnChange} />

          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PodRuleSections;
