import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { InfoCircleOutlined24 } from "@komodorio/design-system/icons";

const Container = styled.div`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${palette.gray["200"]};
  border-left: 0.4rem solid ${palette.blue["500"]};
  padding: 0.5rem;
  align-items: center;
  display: flex;
  gap: 1rem;
`;
const InfoSection: React.FC<{
  title?: string;
  details: JSX.Element | string;
}> = ({ title, details }) => {
  return (
    <Container>
      <InfoCircleOutlined24 color={palette.blue["500"]} />
      <div>
        {title && (
          <Typography size="medium" variant="title" color={palette.gray["800"]}>
            {title}
          </Typography>
        )}

        <Typography size="small" color={palette.gray["800"]}>
          {details}
        </Typography>
      </div>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InfoSection;
