import React from "react";
import { parseISO } from "date-fns";

import { blueForUI, blueForUIText } from "../../../../Colors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ConfigMapDetails } from "../../ProcessList/details/ConfigMapDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";
import EventGroup, { EventBase } from "..";
import ConfigMapScatterShape from "../shapes/config.svg?react";
import { ConfigMapEventsResponseDataInner } from "../../../../generated/resourcesApi";

export type ConfigMapEvent = EventBase &
  Omit<ConfigMapEventsResponseDataInner, keyof EventBase>;

const configMapChangeType = "Configmap Update";

const toConfigMapEvent = (
  e: ConfigMapEventsResponseDataInner,
  serviceId: string
): ConfigMapEvent => {
  return {
    ...e,
    type: configMapChangeType,
    eventTime: parseISO(e.eventTime ?? ""),
    serviceId,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ConfigMapEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigMapScatterShape;

  readonly events: ConfigMapEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly serviceId;

  constructor(e: ConfigMapEvent) {
    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = configMapChangeType;
    this.summary = `${e.action} ConfigMap - ${e.clusterName}/${e.namespace}/${e.configMapName}`;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.details = "";
    this.status = "Completed";
    this.serviceId = e.serviceId;
  }

  renderEventDetails(): JSX.Element {
    return <ConfigMapDetails eventGroup={this} />;
  }
}
export const groupConfigMapEvents = (
  serviceId: string,
  events?: ConfigMapEventsResponseDataInner[]
): ConfigMapEventGroup[] => {
  if (!events) return [];
  return mapAndSort(withServices(events, serviceId), toConfigMapEvent)
    .filter((e) => !e.k8sDefault)
    .map((e) => new ConfigMapEventGroup(e));
};
