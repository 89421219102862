import { useMemo } from "react";

import { NETWORK_MAP } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesAPIGet } from "../../../shared/hooks/resources-api/client";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Resource } from "../../ResourceView/tabs/DependenciesTab/DependenciesTab";
import { NetworkMapGraphResponse } from "../../../generated/resourcesApi";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { mockNodes1 } from "../__tests__/hugh_demo_data/mockNodes/mockNodes1";
import { mockNodes2 } from "../__tests__/hugh_demo_data/mockNodes/mockNodes2";
import { mockNodes3 } from "../__tests__/hugh_demo_data/mockNodes/mockNodes3";
// import { mockNodesEventPuller } from "../__tests__/itiel_demo_data/mockNodes/mockNodes1";

const mockDataMap: Record<string, NetworkMapGraphResponse> = {
  "Deployment|production|hugh|correlation-engine": mockNodes1,
  "Deployment|production|ofer|correlation-engine": mockNodes2,
  "Deployment|production|demo|correlation-engine": mockNodes3,
  // "Deployment|production|demo|events-puller-service": mockNodesEventPuller,
};

type NetworkMapNodesOutput = {
  data: NetworkMapGraphResponse;
  loading: boolean;
};

export const useNetworkMapNodes = (
  resource: Resource
): NetworkMapNodesOutput => {
  const flags = useOverridableFlags();
  const { networkMapperInterval = 5 } = flags;

  const komodoUid = useMemo(
    () =>
      buildKomodorUid({
        kind: resource.kind,
        clusterName: resource.cluster,
        namespace: resource.namespace,
        resourceName: resource.name,
      }),
    [resource.cluster, resource.kind, resource.name, resource.namespace]
  );

  const queryVars = useMemo(
    () => ({
      clusterNames: [resource.cluster],
      komodorUids: [komodoUid],
      interval: `${networkMapperInterval}m`,
    }),
    [komodoUid, networkMapperInterval, resource.cluster]
  );

  const {
    data = {
      nodes: [],
    },
    loading,
  } = useResourcesAPIGet(NETWORK_MAP, queryVars, false);

  const dataToReturn = mockDataMap[komodoUid] ?? data;

  return { data: dataToReturn, loading };
};
