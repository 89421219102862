import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";

import ExternalLink from "../../../common/Link/ExternalLink";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TrialExpiredNonAccountOwnerModal: React.FC = () => {
  return (
    <Dialog open={true}>
      <Container>
        <DialogTitle>
          <Typography variant="h2">Your trial has expired</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please contact your account admin or our support at
            <br />
            <ExternalLink href={"mailto:info@komodor.io"}>
              info@komodor.io
            </ExternalLink>
          </Typography>
        </DialogContent>
      </Container>
    </Dialog>
  );
};
