import { Container as KubernetesContainer } from "kubernetes-types/core/v1.d";
import React, { useEffect, useMemo, useState } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  isMetricsSupportedSelector,
  setReviewMemoryAllocationSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { Metrics } from "../../../Metrics/types";
import { usePodMetrics } from "../../../Metrics/usePodMetrics";
import { AvailabilityRunWithResults } from "../../types";

import {
  useWorkloadContainers,
  useWorkloadPods,
  useLatestGenerationLongestLivingPodName,
} from "./hooks";

export type ContainerData = {
  podName: string;
  containerInfo: KubernetesContainer;
  containerMetrics?: Metrics;
  metricsEndTime: number;
};
export type MemoryAllocationData = {
  containersData: ContainerData[];
  isMetricsSupported: boolean;
};

const ReviewMemoryAllocationDataFetch: React.FC<{
  issue: AvailabilityRunWithResults | null;
  agentId: string | undefined;
}> = ({ issue, agentId }) => {
  const setReviewMemoryAllocationInStore = useInvestigationModeStore(
    setReviewMemoryAllocationSelector
  );

  const [containersData, setContainersData] = useState<ContainerData[]>();
  const [containersMetricsData, setContainersMetricsData] =
    useState<Map<string, Metrics>>();

  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );
  const [metricsEndTime, setMetricsEndTime] = useState<number>(Date.now());
  useEffect(() => {
    setMetricsEndTime(Date.now());
  }, []);
  const komodorUid =
    issue?.results?.reviewMemoryAllocation?.output?.komodorUid ?? "";
  const resourceKind =
    issue?.results?.reviewMemoryAllocation?.output?.resourceKind ?? "";

  const containers = useWorkloadContainers({
    workloadUid: komodorUid,
    resourceKind: resourceKind,
    agentId: agentId ?? "",
  });
  const pods = useWorkloadPods(komodorUid);
  const chosenPodName = useLatestGenerationLongestLivingPodName(
    pods,
    resourceKind
  );

  useEffect(() => {
    if (containers.length > 0) {
      setContainersData(
        containers.map((container) => {
          return {
            podName: chosenPodName,
            containerInfo: container,
            metricsEndTime,
          };
        })
      );
    }
  }, [containers, metricsEndTime, chosenPodName]);

  const containersResult = useMemo(() => {
    if (!containersData || containersData.length === 0) {
      return undefined;
    }
    return containersData.map((containerData) => {
      const containerMetrics = containersMetricsData?.get(
        containerData.containerInfo.name
      );
      return {
        ...containerData,
        containerMetrics: containerMetrics,
      };
    });
  }, [containersData, containersMetricsData]);

  useEffect(() => {
    if (containersResult) {
      setReviewMemoryAllocationInStore({
        containersData: containersResult,
        isMetricsSupported: !!isMetricsSupported,
      });
    }
  }, [containersResult, isMetricsSupported, setReviewMemoryAllocationInStore]);

  const metricsFetchElement = useMemo(() => {
    if (!containersData) {
      return null;
    }
    return containersData.map((containerData, index) => (
      <MetricsFetchLoop
        key={index}
        clusterName={issue?.cluster ?? ""}
        namespace={issue?.namespace ?? ""}
        podName={chosenPodName}
        containerName={containerData.containerInfo.name}
        isMetricsSupported={!!isMetricsSupported}
        metricsEndTime={metricsEndTime}
        setContainersMetricsData={setContainersMetricsData}
      />
    ));
  }, [
    chosenPodName,
    containersData,
    isMetricsSupported,
    issue?.cluster,
    issue?.namespace,
    metricsEndTime,
  ]);

  return <>{metricsFetchElement}</>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ReviewMemoryAllocationDataFetch;

export const MetricsFetchLoop: React.FC<{
  clusterName: string;
  namespace: string;
  podName: string;
  containerName: string;
  isMetricsSupported: boolean;
  metricsEndTime: number;
  setContainersMetricsData: React.Dispatch<
    React.SetStateAction<Map<string, Metrics> | undefined>
  >;
}> = ({
  clusterName,
  namespace,
  podName,
  containerName,
  isMetricsSupported,
  metricsEndTime,
  setContainersMetricsData,
}) => {
  const containerMetrics = usePodMetrics(
    clusterName,
    namespace,
    podName,
    containerName,
    metricsEndTime,
    isMetricsSupported
  );
  useEffect(() => {
    if (containerMetrics) {
      setContainersMetricsData((prev) => {
        if (!prev) {
          return new Map([[containerName, containerMetrics]]);
        }
        const updatedMap = new Map(prev);
        updatedMap.set(containerName, containerMetrics);
        return updatedMap;
      });
    }
  }, [containerMetrics, containerName, setContainersMetricsData]);
  return null;
};
