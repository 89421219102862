import React, { useEffect, useMemo } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { diff, flattenChangeset } from "json-diff-ts";

import { CopyButtonsContainer } from "../../drawers/styles";
import { useHelmChartInfo } from "../../hooks/useHelmChartInfo";
import { LinesLoader } from "../../../../common/loaders/Line";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { getManifestTitle } from "../ManifestsTab";
import { diffStyles } from "../../../../common/ObjectDiff";
import {
  ManifestsMap,
  manifestToMap,
  MAX_ELEMENTS_WITH_SCROLL,
} from "../../utils";
import HelmChart from "../../helmChart";
import { useAgentSupportSecretRedaction } from "../../hooks/useAgentSupportSecretRedaction";

import { CopyButton } from "./CopyButton";
import { EmptyState } from "./EmptyData";

type ManifestsCompareProps = {
  revisionToCompare?: { revision: string; id: string };
  currentManifests: ManifestsMap;
  showOnlyChanges: boolean;
  currentRevision: string;
  selectedResource: string;
  onFetchingStateChange: (isFetching: boolean) => void;
  resource: HelmChart;
};
const ManifestsCompare: React.FC<ManifestsCompareProps> = ({
  showOnlyChanges,
  currentManifests,
  revisionToCompare,
  currentRevision,
  selectedResource,
  onFetchingStateChange,
  resource,
}) => {
  const { data: compareChart, isFetching: isFetchingInfo } = useHelmChartInfo(
    resource.agentId,
    resource.cluster,
    resource.namespace,
    revisionToCompare?.id ?? ""
  );

  const shouldDecodeManifest = useAgentSupportSecretRedaction(resource.cluster);

  useEffect(() => {
    onFetchingStateChange(isFetchingInfo);
  }, [isFetchingInfo, onFetchingStateChange, revisionToCompare?.revision]);

  const currentManifestsArray = useMemo(
    () => Array.from(currentManifests.entries()),
    [currentManifests]
  );
  const isShowAllManifests = useMemo(() => {
    return currentManifestsArray?.length < MAX_ELEMENTS_WITH_SCROLL;
  }, [currentManifestsArray?.length]);
  const comparableManifests = useMemo(() => {
    return manifestToMap(compareChart?.parsedManifest, !!shouldDecodeManifest);
  }, [compareChart?.parsedManifest, shouldDecodeManifest]);
  const manifestsEntries = useMemo(
    () =>
      currentManifestsArray.filter(([resourceKey, manifestValues]) => {
        const changes = flattenChangeset(
          diff(
            manifestValues.manifest,
            comparableManifests.get(resourceKey)?.manifest
          )
        );
        return showOnlyChanges ? changes.length > 0 : true;
      }),
    [comparableManifests, currentManifestsArray, showOnlyChanges]
  );
  const restOfComparableManifests = useMemo(() => {
    const restKeys = Array.from(comparableManifests.keys()).filter(
      (k) => !currentManifests.has(k)
    );
    return new Map(restKeys.map((key) => [key, comparableManifests.get(key)]));
  }, [comparableManifests, currentManifests]);

  const diffsArray = useMemo(() => {
    return manifestsEntries.length ? (
      manifestsEntries.map(([resourceKey, manifestValues], index) => (
        <div id={resourceKey}>
          <br />
          <ReactDiffViewer
            newValue={comparableManifests.get(resourceKey)?.manifest}
            oldValue={manifestValues.manifest}
            rightTitle={
              <>
                {revisionToCompare && (!isShowAllManifests || index === 0) && (
                  <Typography
                    variant="title"
                    size="medium"
                    color={palette.gray[800]}
                  >
                    Revision #{revisionToCompare.revision}
                  </Typography>
                )}
                {comparableManifests.has(resourceKey) ? (
                  <CopyButtonsContainer>
                    {getManifestTitle(
                      comparableManifests.get(resourceKey)?.name,
                      comparableManifests.get(resourceKey)?.kind
                    )}
                    <CopyButton
                      value={
                        comparableManifests.get(resourceKey ?? "")
                          ?.manifest as string
                      }
                      tooltipText={"Copy manifest to clipboard"}
                    />
                  </CopyButtonsContainer>
                ) : undefined}
              </>
            }
            leftTitle={
              <>
                {(!isShowAllManifests || index === 0) && (
                  <Typography
                    variant="title"
                    size="medium"
                    color={palette.gray[800]}
                  >
                    Revision #{currentRevision}
                  </Typography>
                )}
                <CopyButtonsContainer>
                  {getManifestTitle(manifestValues.name, manifestValues.kind)}
                  <CopyButton
                    value={
                      currentManifests.get(resourceKey ?? "")
                        ?.manifest as string
                    }
                    tooltipText={"Copy manifest to clipboard"}
                  />
                </CopyButtonsContainer>
              </>
            }
            splitView
            disableWordDiff
            showDiffOnly={showOnlyChanges}
            styles={diffStyles}
          />
        </div>
      ))
    ) : (
      <EmptyState text={"There are no changes to show"} />
    );
  }, [
    comparableManifests,
    currentManifests,
    currentRevision,
    isShowAllManifests,
    manifestsEntries,
    revisionToCompare,
    showOnlyChanges,
  ]);

  const getDiffsArray = () => {
    if (isShowAllManifests) {
      return diffsArray;
    }
    if (Array.isArray(diffsArray)) {
      return (
        (diffsArray as JSX.Element[]).find(
          (elem) => elem.props.id === selectedResource
        ) ?? <EmptyState text={"There are no changes to show"} />
      );
    }

    return <EmptyState text={"There are no changes to show"} />;
  };

  return (
    <>
      {isFetchingInfo ? (
        <LinesLoader />
      ) : (
        <>
          {getDiffsArray()}
          {restOfComparableManifests.size > 0 && (
            <>
              {Array.from(restOfComparableManifests.entries()).map(
                ([, restManifestValues]) => (
                  <ReactDiffViewer
                    oldValue={restManifestValues?.manifest}
                    newValue={undefined}
                    splitView
                    disableWordDiff
                    showDiffOnly={showOnlyChanges}
                    rightTitle={getManifestTitle(
                      restManifestValues?.name,
                      restManifestValues?.kind
                    )}
                  />
                )
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ManifestsCompare;
