import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  NameType,
  Payload,
  ValueType,
  TooltipProps,
} from "recharts/types/component/DefaultTooltipContent";
import { Path } from "./Path";
import { muiColors } from "../../../../themes";
import { ColorIndication } from "./ColorIndication";
import { getTooltipValue } from "../utils";
import Paper from "@mui/material/Paper";

type OverridableStyles = "valueContainer" | "itemContainer";

interface ChartTooltipProps {
  data: TooltipProps<ValueType, NameType>;
  unit: string;
  unitPlacement?: "left" | "right";
  categoryLabelGetter: (payloadItem: Payload<ValueType, NameType>) => string[];
  styles?: Record<OverridableStyles, React.CSSProperties>;
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  data,
  unit,
  unitPlacement = "right",
  categoryLabelGetter,
  styles = {},
}) => {
  const content = useMemo(
    () =>
      data.payload?.map((payloadItem: Payload<ValueType, NameType>) => {
        const categoryLabelItems = categoryLabelGetter(payloadItem);
        return (
          <Stack
            key={payloadItem.dataKey}
            alignItems="center"
            direction="row"
            sx={styles.itemContainer}
            justifyContent="space-between"
          >
            <Stack alignItems="center" columnGap="6px" direction="row">
              <ColorIndication color={payloadItem.color} $clear={false} />
              <Path value={categoryLabelItems} isLimitedPath />
            </Stack>
            <Stack
              alignItems="baseline"
              columnGap="3px"
              marginLeft="5px"
              direction={unitPlacement === "left" ? "row" : "row-reverse"}
              sx={styles.valueContainer}
            >
              <Typography variant="caption" color={muiColors.gray[500]}>
                {unit}
              </Typography>
              <Typography variant="body2">
                {getTooltipValue(payloadItem.value ?? "")}
              </Typography>
            </Stack>
          </Stack>
        );
      }),
    [data.payload, unit, unitPlacement, categoryLabelGetter]
  );

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "192px",
        padding: "16px",
      }}
    >
      <Typography variant="h4">{data.label}</Typography>
      {content}
    </Paper>
  );
};
