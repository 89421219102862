import { useMemo } from "react";

import { useFindInstalledAgentSubscription } from "../../../../../generated/graphql";
import { useSubscriptionWithVariables } from "../../../../../shared/hooks/useSubscriptionWithVariables";

const useIsAgentInstalled = (apiKey: string | null): boolean => {
  const variables = useMemo(() => (apiKey ? { apiKey } : null), [apiKey]);
  const data = useSubscriptionWithVariables(
    useFindInstalledAgentSubscription,
    variables
  );

  if (!data) return false;
  if (data.kubernetes_agent_info.length === 1) return true;

  return false;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useIsAgentInstalled;
