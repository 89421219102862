import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { DrawerPadding } from "../ignoreChecksStyles";
import { checkTypeToDescription } from "../../../../constants/checkTypeToDescription";

import { useGetCheckTypeInURL } from "./ignoreChecksDrawerHooks";

const TextContainer = styled.div`
  ${DrawerPadding};
  padding-top: 24px;
  padding-bottom: 24px;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Description: React.FC = () => {
  const checkType = useGetCheckTypeInURL();

  if (!checkType) return null;

  const description = checkTypeToDescription[checkType];

  return (
    <div>
      <TextContainer>
        <Typography variant={"h4"}>Description</Typography>
        <Typography variant={"body2"}>{description}</Typography>
      </TextContainer>
      <Divider />
    </div>
  );
};
