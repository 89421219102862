import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { RecommendationData } from "../../types";
import { RecommendationChange } from "../RecommendationChange/RecommendationChange";
import { costOptimizationAriaLabels } from "../../../../../constants/costOptimizationAriaLabels";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const {
  containerName: containerNameAriaLabel,
  containers: {
    cpuRecommendation: cpuRecommendationAriaLabel,
    memoryRecommendation: memoryRecommendationAriaLabel,
  },
} = costOptimizationAriaLabels.rightSizingPage.recommendationModal;

interface Props {
  recommendation: RecommendationData;
}

export const ContainerTitle: React.FC<Props> = ({
  recommendation: {
    containerName,
    shouldShowCpuRecommendation,
    shouldShowMemoryRecommendation,
    currentCpu,
    currentMemory,
    cpuRecommendation,
    memoryRecommendation,
  },
}) => {
  return (
    <Container>
      <Typography aria-label={containerNameAriaLabel} variant="h5">
        {containerName}
      </Typography>
      <RecommendationChange
        unit="millicores"
        current={currentCpu}
        recommendation={cpuRecommendation}
        shouldShowRecommendation={shouldShowCpuRecommendation}
        ariaLabel={cpuRecommendationAriaLabel}
      />
      <RecommendationChange
        unit="MiB"
        current={currentMemory}
        recommendation={memoryRecommendation}
        shouldShowRecommendation={shouldShowMemoryRecommendation}
        ariaLabel={memoryRecommendationAriaLabel}
      />
    </Container>
  );
};
