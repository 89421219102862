import { palette } from "@komodorio/design-system";
import {
  Button,
  IconButton,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Close24, ChevronDown16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

import { podExecSesssionTitle } from "./MinimizedRowSharedComponents";

interface PodExecDrawerHeaderProps {
  onClose: () => void;
  onMinimize: () => void;
  containerName: string;
  podName: string;
}

const HeadContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: ${palette.white[0]};
  justify-content: space-between;
  padding: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  padding-inline-start: 0.5rem;
`;

const ScreenSizeActions = styled.div`
  display: flex;
  padding-inline-end: 0.5rem;
  gap: 0.5rem;
`;

const LeftContainer = styled.div`
  display: flex;
`;

export const PodExecDrawerHeader: React.FC<PodExecDrawerHeaderProps> = ({
  onClose,
  onMinimize,
  containerName,
  podName,
}) => (
  <HeadContainer>
    <LeftContainer>
      {onClose && (
        <IconButton
          icon={Close24}
          noBorder
          onClick={onClose}
          aria-label="close pod exec drawer"
        />
      )}
      <TitleContainer>
        <Typography variant="headline" size="small">
          {podExecSesssionTitle(podName, containerName)}
        </Typography>
      </TitleContainer>
    </LeftContainer>

    <ScreenSizeActions>
      <Button
        variant="secondary"
        onClick={onMinimize}
        icon={ChevronDown16}
        aria-label="minimize pod exec drawer"
      >
        Minimize
      </Button>
    </ScreenSizeActions>
  </HeadContainer>
);
