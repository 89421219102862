import axios from "axios";
import { useMemo } from "react";

import { getAppConfig } from "../../../config/appConfig";
import { useRequestHeaders } from "../../common-api/headers";

const getAuthServiceAPIBasePath = (): string => {
  return getAppConfig().authServiceAPIServerURL;
};

export const useAuthApiClient = () => {
  const headers = useRequestHeaders();
  return useMemo(
    () =>
      axios.create({
        baseURL: getAuthServiceAPIBasePath(),
        headers,
      }),
    [headers]
  );
};
