import styled from "styled-components";

export const TextButton = styled.div`
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  padding: 0.5rem;
  width: fit-content;
  :hover {
    text-decoration: underline;
  }
`;
