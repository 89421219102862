import { useCallback } from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

import { Severity } from "@/components/reliability/ReliabilityTypes";
import { getConfigurationNamesByCheckType } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";

type SetFieldErrorParams = {
  severity: Severity;
  configurationName: string;
  errorMessage: string;
};

export const useSetFieldError = () => {
  const { setError, formState } = useFormContext();

  return useCallback(
    ({ errorMessage, severity, configurationName }: SetFieldErrorParams) => {
      const fieldIdentifier = getConfigurationNamesByCheckType(
        configurationName,
        severity
      );

      const currentFieldError = get(formState.errors, fieldIdentifier);
      if (!currentFieldError) {
        setError(fieldIdentifier, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    [formState.errors, setError]
  );
};
