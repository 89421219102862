import React, { PropsWithChildren } from "react";

import { MetricsGraphContainerProps, TooltipItemProps } from "../types";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { formatCountTick } from "../graphEntity/podsCount/podsCount";
import { Tooltip } from "../Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "../utils";

import { MetricsGraphContainer } from "./MetricsGraphContainer";

type CountGraphProps = Omit<
  MetricsGraphContainerProps & {
    tooltipItems: TooltipItemProps[];
    tooltipDateOnly?: boolean;
  },
  "tickFormatter" | "tooltipContent" | "ariaLabels"
>;

export const CountGraph: React.FC<PropsWithChildren<CountGraphProps>> = ({
  ...props
}) => {
  return (
    <MetricsGraphContainer
      tickFormatter={formatCountTick}
      tooltipContent={(tooltipProps) => (
        <Tooltip
          tooltipProps={tooltipProps}
          tooltipItems={props.tooltipItems}
          dataTransformFunction={defaultFormatTooltipTick}
          dateOnly={props.tooltipDateOnly}
        />
      )}
      ariaLabels={AriaLabels.MetricsAvailability.CountGraph}
      {...props}
    >
      {props.children}
    </MetricsGraphContainer>
  );
};
