import { useCallback } from "react";

import DeployEventGroup, {
  DeployEvent,
} from "../../../../../../../../../../common/EventGroup/deployEvent/DeployEventGroup";
import { useViolation } from "../../../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../../../hooks/drawerContentHooks";

import { useGetServiceIdFromKomodorUid } from "@/shared/hooks/useGetServiceIdFromKomodorUid";
import { Deploy } from "@/generated/resourcesApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const useOpenDeployEventDrawer = (deployEvent: Deploy) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { data } = useViolation();
  const { komodorUid } = useGetCommonDrawerProps(data?.data.violation);
  const { serviceId = "" } =
    useGetServiceIdFromKomodorUid(komodorUid, false) ?? {};

  return useCallback(() => {
    const deployEventClass = new DeployEvent(deployEvent, serviceId, false);
    const deployEventAsEventGroup = new DeployEventGroup([deployEventClass]);
    pushDrawer({
      drawerType: DrawerType.EventDrawer,
      event: deployEventAsEventGroup,
      children: deployEventAsEventGroup.renderEventDetails(),
    });
  }, [deployEvent, pushDrawer, serviceId]);
};
