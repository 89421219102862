import React from "react";
import styled from "styled-components";

import { H4Light } from "./typography";

const H4Lighter = styled(H4Light)`
  color: #6e7a96;
  margin-top: 0.5rem;
`;

const LinkToDocs: React.FC<{ docsUrl: string }> = ({ docsUrl }) => {
  return (
    <H4Lighter>
      Not sure how to do this?{" "}
      <a rel="noreferrer" target="_blank" href={docsUrl}>
        Link to documentation
      </a>
    </H4Lighter>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LinkToDocs;
