import React from "react";
import {
  Status,
  StatusColor,
  StatusProps,
} from "@komodorio/design-system/komodor-ui";

import { WorkflowStatus as WorkflowStatusType } from "@/generated/addonsApi";

const wfStatusColorMap: { [key in WorkflowStatusType]: StatusColor } = {
  [WorkflowStatusType.Completed]: "success",
  [WorkflowStatusType.Running]: "running",
};

export const WorkflowStatus: React.FC<
  { status: WorkflowStatusType } & Partial<StatusProps>
> = ({ status, size = "medium", variant = "outlined", uppercase = true }) => {
  if (!status) {
    return null;
  }

  const statusColor: StatusColor = wfStatusColorMap[status] || "neutral";

  return (
    <Status
      size={size}
      variant={variant}
      color={statusColor}
      label={status}
      uppercase={uppercase}
    />
  );
};
