import { ResourceTableModelRow } from "komodor-types";
import React from "react";
import styled from "styled-components";

import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import ResourceActionsList from "../../../Inspection/ResourceActionsList";
import { KubernetesCRDResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";

import { ResourcesLinkCell } from "./ResourcesLinkCell";

const LastTdContainer = styled.td`
  width: 1rem;
`;

interface LastCellProps {
  clusterName: string;
  resourceType: KubernetesResource;
  agentId: string | undefined;
  refreshResults: () => void;
  includeActionsCell: boolean;
  isDeletedPod: string;
  row: ResourceTableModelRow;
}

export const LastCell: React.FC<LastCellProps> = ({
  agentId,
  clusterName,
  includeActionsCell,
  isDeletedPod,
  refreshResults,
  resourceType,
  row,
}) => {
  const shouldRenderActionsCell =
    includeActionsCell &&
    resourceType.SupportedActions &&
    agentId &&
    !isDeletedPod;

  const shouldRenderResourcesLinkCell =
    resourceType.Kind === KubernetesCRDResource.Kind;

  if (shouldRenderActionsCell) {
    return (
      <LastTdContainer onClick={(e) => e.stopPropagation()}>
        <ResourceActionsList
          clusterName={clusterName}
          resource={row}
          resourceType={resourceType}
          agentId={agentId}
          refreshResults={refreshResults}
        />
      </LastTdContainer>
    );
  }

  if (shouldRenderResourcesLinkCell) {
    return <ResourcesLinkCell resourceType={resourceType} row={row} />;
  }

  return <LastTdContainer />;
};
