import { useScopeFilters } from "../../../../../../hooks/useScopeFilters";
import { useViolationsTableContext } from "../context/useViolationsTableContext";

export const useGetClusterNamesParam = (): string[] | undefined => {
  const { uid, violationsGroupBy } = useViolationsTableContext();
  const { clusterName } = useScopeFilters();

  // when we group by cluster, the uid represents the cluster name
  return violationsGroupBy === "Cluster" && uid ? [uid] : clusterName;
};
