import { useCallback, useEffect, useMemo, useRef } from "react";
import { noop } from "lodash";

import { selectedAppViewIdSelector } from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { Timeframe } from "../../../../../shared/types/TimeWindow";
import { APP_VIEWS_PARAM_KEY } from "../../../../../shared/config/urlSearchParamsKeys";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import { storedLastAppViewsData } from "../../../../../shared/store/appViewsStore/constants";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useIsInAppViewsPath } from "./appViewsHooks";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

export type TimeWindowAsNumber = {
  start: number;
  end: number;
  timeframe: Timeframe;
};

export type AppViewPersistentState = {
  id: string;
  timeWindow: TimeWindowAsNumber;
};

const useGetCurrentStateAsJSON = (state?: string | null) => {
  return useMemo<AppViewPersistentState>(() => {
    let parsedState;
    try {
      parsedState = state ? JSON.parse(state) : {};
    } catch (e) {
      parsedState = {};
    }
    return parsedState;
  }, [state]);
};
export const useAppViewStateInLocalStorage = (): [
  AppViewPersistentState,
  (
    appViewData: Partial<AppViewPersistentState>,
    resetExistingData?: boolean
  ) => void
] => {
  const [appViewInLocalStorage, setAppViewInLocalStorage] =
    useStateInLocalStorage(storedLastAppViewsData, "");

  const currentState = useGetCurrentStateAsJSON(appViewInLocalStorage);

  const onSetAppViewInLocalStorage = useCallback(
    (
      appViewData: Partial<AppViewPersistentState>,
      resetExistingData?: boolean
    ) => {
      try {
        const state = JSON.stringify({
          ...(!resetExistingData && currentState),
          ...appViewData,
        });
        setAppViewInLocalStorage(state);
      } catch (e) {
        noop();
      }
    },
    [currentState, setAppViewInLocalStorage]
  );
  return [currentState, onSetAppViewInLocalStorage];
};

export const useAppViewDataInUrl = (): [
  AppViewPersistentState,
  (
    appViewData: Partial<AppViewPersistentState> | null,
    replace?: boolean
  ) => void
] => {
  const [appViewDataInUrl, setAppViewDataInUrl] =
    useStateInSearchParams(APP_VIEWS_PARAM_KEY);

  const currentState = useGetCurrentStateAsJSON(appViewDataInUrl);

  const onSetAppViewDataInUrl = useCallback(
    (
      appViewData: Partial<AppViewPersistentState> | null,
      replace?: boolean
    ) => {
      try {
        const state =
          appViewData !== null
            ? JSON.stringify({
                ...currentState,
                ...appViewData,
              })
            : null;
        setAppViewDataInUrl(state, replace);
      } catch (e) {
        noop();
      }
    },
    [currentState, setAppViewDataInUrl]
  );

  return [currentState, onSetAppViewDataInUrl];
};

export const useSetAppViewIdInLocalStorage = (): ((id?: string) => void) => {
  const [{ id: appViewId }, setAppViewInLocalStorage] =
    useAppViewStateInLocalStorage();
  return useCallback(
    (id?: string) => {
      if (appViewId !== id) {
        setAppViewInLocalStorage({ id }, true);
      }
    },
    [appViewId, setAppViewInLocalStorage]
  );
};

export const useRemoveAppViewIdFromLocalStorage = (): (() => void) => {
  return useCallback(() => {
    storedLastAppViewsData.remove();
  }, []);
};

export const useAppViewIdFromLocalStorage = (): string | null => {
  const [{ id: appViewIdInUrl }, setAppViewDataInUrl] = useAppViewDataInUrl();
  const [{ id: appViewIdInLocalStorage }, setAppViewInLocalStorage] =
    useAppViewStateInLocalStorage();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const appViewIdToReturn = useRef<string | null>(appViewIdInLocalStorage);
  const isInAppViewsPath = useIsInAppViewsPath();

  useEffect(() => {
    if (appViewIdInLocalStorage) {
      if (!selectedAppViewId) return;
      if (!appViewIdInUrl || appViewIdInUrl !== appViewIdInLocalStorage) {
        appViewIdToReturn.current = appViewIdInLocalStorage;
      }
    } else {
      appViewIdToReturn.current = null;
    }
  }, [
    appViewIdInUrl,
    appViewIdInLocalStorage,
    isInAppViewsPath,
    setAppViewDataInUrl,
    setAppViewInLocalStorage,
    selectedAppViewId,
  ]);

  return appViewIdToReturn.current;
};
