/* eslint-disable max-lines */
import { capitalize } from "lodash";

import { EnrichedMessage } from "../types";
import { NodeResourceToken, StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";

export class NodeNotReadyEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`Node is not ready`, "i"));
  }

  searchKey(): string {
    return "Node is not ready";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("A node", errorMessage),
        new StringToken("is having issues so one or more Pods can't be run."),
      ],
    };
  }
}

export class NodeLowOnResource extends BaseEnricher {
  constructor() {
    super(new RegExp(`The node was low on resource: (\\S+)\\.`, "i"));
  }

  searchKey(): string {
    return "The node was low on resource";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const resource = match[1];

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("The node", errorMessage),
        new StringToken(`was low on ${resource}.`),
      ],
    };
  }
}

export class NodeDiskPressure extends BaseEnricher {
  constructor() {
    super(new RegExp(`The node had condition: \\[DiskPressure]\\.`, "i"));
  }

  searchKey(): string {
    return `The node had condition: [DiskPressure].`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("The node", errorMessage),
        new StringToken(
          `chosen for pods to run on lacks the required memory to run them`
        ),
      ],
    };
  }
}

export class NodeMemoryPressure extends BaseEnricher {
  constructor() {
    super(new RegExp(`The node had condition: \\[MemoryPressure]\\.`, "i"));
  }

  searchKey(): string {
    return `The node had condition: [MemoryPressure].`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("A node", errorMessage),
        new StringToken(
          `has evicted one or more pods because it is low on memory`
        ),
      ],
    };
  }
}

export class PodRejectedNodeDiskPressure extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Pod was rejected: The node had condition: \\[DiskPressure]\\.`,
        "i"
      )
    );
  }

  searchKey(): string {
    return `Pod was rejected: The node had condition: [DiskPressure].`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("The node", errorMessage),
        new StringToken(
          `chosen for pods to run on lacks the required memory to run them`
        ),
      ],
    };
  }
}

export class PodRejectedNodeMemoryPressure extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Pod was rejected: The node had condition: \\[MemoryPressure]\\.`,
        "i"
      )
    );
  }

  searchKey(): string {
    return `Pod was rejected: The node had condition: [MemoryPressure].`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new NodeResourceToken("A node", errorMessage),
        new StringToken(
          `has evicted one or more pods because it is low on memory`
        ),
      ],
    };
  }
}

export class PodRejectedNodeAffinity extends BaseEnricher {
  constructor() {
    super(new RegExp(`Pod was rejected: Predicate NodeAffinity failed`, "i"));
  }

  searchKey(): string {
    return `Pod was rejected: Predicate NodeAffinity failed`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("One or more pods were scheduled to"),
        new NodeResourceToken("a node", errorMessage),
        new StringToken(`that no longer meets their affinity criteria`),
      ],
    };
  }
}

export class PodRejectedNodeShuttingDown extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Pod was rejected: Pod was rejected as the node is shutting down.`,
        "i"
      )
    );
  }

  searchKey(): string {
    return `Pod was rejected: Pod was rejected as the node is shutting down.`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("One or more pods were scheduled to"),
        new NodeResourceToken("a node", errorMessage),
        new StringToken(`that is being shut down`),
      ],
    };
  }
}

export class PodTerminatedNodeShuttingDown extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Pod was terminated in response to imminent node shutdown.`,
        "i"
      )
    );
  }

  searchKey(): string {
    return `Pod was terminated in response to imminent node shutdown.`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("One or more pods were terminated because"),
        new NodeResourceToken("the node", errorMessage),
        new StringToken(`it was running on was being shut down.`),
      ],
    };
  }
}

export class PodRejectedNodeDidntHaveResources extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `Pod was rejected: Node didn't have enough resource: (\\S+),`,
        "i"
      )
    );
  }

  searchKey(): string {
    return `Pod was rejected: Node didn't have enough resource`;
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const resource = match[1];

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          `The service is requesting ${
            resource === "cpu" ? "CPU" : capitalize(resource)
          } resources, but`
        ),
        new NodeResourceToken("the node", errorMessage),
        new StringToken(`does not have enough left`),
      ],
    };
  }
}
