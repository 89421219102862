import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Scalar _Any */
  _Any: any;
  app_view_serviceidentifiertype_enum: any;
  bigint: any;
  citext: string;
  jsonb: unknown;
  timestamp: string;
  timestamptz: string;
  uuid: string;
};


export type AgentCrashReport = {
  __typename?: 'AgentCrashReport';
  data: Scalars['jsonb'];
};

export type AgentCrashReportList = {
  __typename?: 'AgentCrashReportList';
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AgentTaskInput = {
  agentId: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
};

export type AgentTaskOutput = {
  __typename?: 'AgentTaskOutput';
  failureMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['jsonb']>;
  success: Scalars['Boolean'];
  taskId: Scalars['String'];
  timeToCompleteInMs: Scalars['Int'];
  timeToResultInMs: Scalars['Int'];
  timeToStartInMs: Scalars['Int'];
};

export type AllServicesOutput = {
  __typename?: 'AllServicesOutput';
  services: Scalars['jsonb'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type BucketsOutput = {
  __typename?: 'BucketsOutput';
  sumEvents: Scalars['Int'];
  tablesBatches: Scalars['jsonb'];
};

export type CreateAccountInput = {
  accountName: Scalars['String'];
  displayName: Scalars['String'];
  identityProvider: Scalars['String'];
  plan: Scalars['String'];
  userEmail: Scalars['citext'];
};

export type CreateAccountOutput = {
  __typename?: 'CreateAccountOutput';
  accountId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type CreatePagerDutyInstallationOutput = {
  __typename?: 'CreatePagerDutyInstallationOutput';
  installationId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateUserInput = {
  displayName: Scalars['String'];
  inviteeMail: Scalars['String'];
  rbacRoleIds: Array<Maybe<Scalars['String']>>;
  role: Scalars['String'];
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  success: Scalars['Boolean'];
};

export type EventsOutput = {
  __typename?: 'EventsOutput';
  rawEvents: Scalars['jsonb'];
};

export type FilterCategoriesOutput = {
  __typename?: 'FilterCategoriesOutput';
  result: Scalars['jsonb'];
};

export type FilterValuesOutput = {
  __typename?: 'FilterValuesOutput';
  result: Scalars['jsonb'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type JobsByPageOutput = {
  __typename?: 'JobsByPageOutput';
  services: Scalars['jsonb'];
};

export type JobsFiltersOutput = {
  __typename?: 'JobsFiltersOutput';
  filters: Scalars['jsonb'];
};

export type OpsgenieValidateResponse = {
  __typename?: 'OpsgenieValidateResponse';
  valid: Scalars['Boolean'];
};

export type Output = {
  __typename?: 'Output';
  channelId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ResendEmailVerificationInput = {
  mail: Scalars['String'];
};

export type ResendEmailVerificationOutput = {
  __typename?: 'ResendEmailVerificationOutput';
  success: Scalars['Boolean'];
};

export type ResourcesMapInput = {
  agentId: Scalars['String'];
  namespace: Scalars['String'];
};

export type ResourcesMapOutput = {
  __typename?: 'ResourcesMapOutput';
  result?: Maybe<Scalars['jsonb']>;
  success: Scalars['Boolean'];
};

export type RespondersOutput = {
  __typename?: 'RespondersOutput';
  responders: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ServicesByPageOutput = {
  __typename?: 'ServicesByPageOutput';
  services: Scalars['jsonb'];
};

export type ServicesFiltersOutput = {
  __typename?: 'ServicesFiltersOutput';
  filters: Scalars['jsonb'];
};

export type SilentLoginInput = {
  id: Scalars['String'];
  mail: Scalars['String'];
  password: Scalars['String'];
};

export type SilentLoginOutput = {
  __typename?: 'SilentLoginOutput';
  jwt: Scalars['String'];
};

export type SilentSignupInput = {
  id: Scalars['String'];
  installationId: Scalars['String'];
  mail: Scalars['String'];
  password: Scalars['String'];
};

export type SilentSignupOutput = {
  __typename?: 'SilentSignupOutput';
  jwt: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** A union of all types that use the @key directive */
export type _Entity = Account | Account_Domain_Mapping | Account_Event_Limits | Agent_Task | Agent_Task_Result | Api_Key | App_View | Audit | Channel_Configuration | Event_Actions | Event_Config_Map | Event_Cron_Job | Event_Datadog_Monitor | Event_Datadog_Tracing | Event_Deploy | Event_Hpa | Event_Ingress | Event_Job | Event_Komodor_Alert | Event_Kubernetes_Service_Resource | Event_Launchdarkly | Event_Limit_Ranges | Event_Monitor | Event_Network_Policies | Event_Node | Event_Opsgenie | Event_Pagerduty | Event_Pdb | Event_Resource_Quota | Event_Secret | Event_Sentry_Issue | Event_Stale_Kubernetes_Agent | Event_Topology | Event_Workload | External_Link | External_Link_Context | Helm_Repo | Installation | Komodor_Filter_Settings | Komodor_Service | Komodor_Service_Attributes | Komodor_Service_Datadog_Data | Komodor_Service_Deploy_State | Komodor_Service_Health_State | Komodor_Service_K8s_Metadata | Komodor_Service_Sentry_Data | Komodor_Service_Static_Prevention_State | Kubernetes_Agent_Info | Node_Status_Agent_Task | Plan | Pod_List | Pod_Logs | Rbac_Policy | Rbac_Policy_Action | Rbac_Policy_Actions | Rbac_Role | Rbac_Role_Policy | Rbac_User_Role | Role | Service_Attributes_For_Account | Service_Description | User | Workflow_Configuration | Workflow_Results;

export type _Service = {
  __typename?: '_Service';
  /** SDL representation of schema */
  sdl: Scalars['String'];
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  disabled: Scalars['Boolean'];
  displayName?: Maybe<Scalars['String']>;
  features: Scalars['jsonb'];
  id: Scalars['uuid'];
  identityProvider: Scalars['String'];
  inPoc: Scalars['Boolean'];
  /** An array relationship */
  installations: Array<Installation>;
  /** An aggregate relationship */
  installations_aggregate: Installation_Aggregate;
  isRbacEnabled: Scalars['Boolean'];
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt: Scalars['timestamp'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "account" */
export type AccountFeaturesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountInstallationsArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountInstallations_AggregateArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountUsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  avg?: Maybe<Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
  stddev?: Maybe<Account_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Sum_Fields>;
  var_pop?: Maybe<Account_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Var_Samp_Fields>;
  variance?: Maybe<Account_Variance_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Account_Append_Input = {
  features?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Account_Avg_Fields = {
  __typename?: 'account_avg_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: Maybe<Array<Account_Bool_Exp>>;
  _not?: Maybe<Account_Bool_Exp>;
  _or?: Maybe<Array<Account_Bool_Exp>>;
  acquisitionChannel?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  features?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identityProvider?: Maybe<String_Comparison_Exp>;
  inPoc?: Maybe<Boolean_Comparison_Exp>;
  installations?: Maybe<Installation_Bool_Exp>;
  installations_aggregate?: Maybe<Installation_Aggregate_Bool_Exp>;
  isRbacEnabled?: Maybe<Boolean_Comparison_Exp>;
  logsRetentionDays?: Maybe<Int_Comparison_Exp>;
  marketplaceConnectionId?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  origin?: Maybe<String_Comparison_Exp>;
  plan?: Maybe<String_Comparison_Exp>;
  purchaseDate?: Maybe<Timestamptz_Comparison_Exp>;
  salesforceAccountId?: Maybe<String_Comparison_Exp>;
  trialEndAt?: Maybe<Timestamp_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  users?: Maybe<User_Bool_Exp>;
  users_aggregate?: Maybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_54115ee388cdb6d86bb4bf5b2ea = 'PK_54115ee388cdb6d86bb4bf5b2ea',
  /** unique or primary key constraint on columns "name" */
  Uq_414d4052f22837655ff312168cb = 'UQ_414d4052f22837655ff312168cb'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Account_Delete_At_Path_Input = {
  features?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Account_Delete_Elem_Input = {
  features?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Account_Delete_Key_Input = {
  features?: Maybe<Scalars['String']>;
};

/** columns and relationships of "account_domain_mapping" */
export type Account_Domain_Mapping = {
  __typename?: 'account_domain_mapping';
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  domain: Scalars['String'];
  enforceIdpOnly?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  idpType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "account_domain_mapping" */
export type Account_Domain_Mapping_Aggregate = {
  __typename?: 'account_domain_mapping_aggregate';
  aggregate?: Maybe<Account_Domain_Mapping_Aggregate_Fields>;
  nodes: Array<Account_Domain_Mapping>;
};

/** aggregate fields of "account_domain_mapping" */
export type Account_Domain_Mapping_Aggregate_Fields = {
  __typename?: 'account_domain_mapping_aggregate_fields';
  avg?: Maybe<Account_Domain_Mapping_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Domain_Mapping_Max_Fields>;
  min?: Maybe<Account_Domain_Mapping_Min_Fields>;
  stddev?: Maybe<Account_Domain_Mapping_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Domain_Mapping_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Domain_Mapping_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Domain_Mapping_Sum_Fields>;
  var_pop?: Maybe<Account_Domain_Mapping_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Domain_Mapping_Var_Samp_Fields>;
  variance?: Maybe<Account_Domain_Mapping_Variance_Fields>;
};


/** aggregate fields of "account_domain_mapping" */
export type Account_Domain_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Domain_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Domain_Mapping_Avg_Fields = {
  __typename?: 'account_domain_mapping_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_domain_mapping". All fields are combined with a logical 'AND'. */
export type Account_Domain_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Account_Domain_Mapping_Bool_Exp>>;
  _not?: Maybe<Account_Domain_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Account_Domain_Mapping_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  connectionName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  domain?: Maybe<String_Comparison_Exp>;
  enforceIdpOnly?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  idpType?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_domain_mapping" */
export enum Account_Domain_Mapping_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_4cf298c4bd24a6f19529305de7b = 'PK_4cf298c4bd24a6f19529305de7b'
}

/** input type for incrementing numeric columns in table "account_domain_mapping" */
export type Account_Domain_Mapping_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "account_domain_mapping" */
export type Account_Domain_Mapping_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  domain?: Maybe<Scalars['String']>;
  enforceIdpOnly?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  idpType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Account_Domain_Mapping_Max_Fields = {
  __typename?: 'account_domain_mapping_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  idpType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Account_Domain_Mapping_Min_Fields = {
  __typename?: 'account_domain_mapping_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  idpType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "account_domain_mapping" */
export type Account_Domain_Mapping_Mutation_Response = {
  __typename?: 'account_domain_mapping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Domain_Mapping>;
};

/** on_conflict condition type for table "account_domain_mapping" */
export type Account_Domain_Mapping_On_Conflict = {
  constraint: Account_Domain_Mapping_Constraint;
  update_columns?: Array<Account_Domain_Mapping_Update_Column>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};

/** Ordering options when selecting data from "account_domain_mapping". */
export type Account_Domain_Mapping_Order_By = {
  accountId?: Maybe<Order_By>;
  connectionName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  enforceIdpOnly?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idpType?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: account_domain_mapping */
export type Account_Domain_Mapping_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "account_domain_mapping" */
export enum Account_Domain_Mapping_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ConnectionName = 'connectionName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  EnforceIdpOnly = 'enforceIdpOnly',
  /** column name */
  Id = 'id',
  /** column name */
  IdpType = 'idpType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "account_domain_mapping" */
export type Account_Domain_Mapping_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  domain?: Maybe<Scalars['String']>;
  enforceIdpOnly?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  idpType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Account_Domain_Mapping_Stddev_Fields = {
  __typename?: 'account_domain_mapping_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Domain_Mapping_Stddev_Pop_Fields = {
  __typename?: 'account_domain_mapping_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Domain_Mapping_Stddev_Samp_Fields = {
  __typename?: 'account_domain_mapping_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account_domain_mapping" */
export type Account_Domain_Mapping_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Domain_Mapping_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Domain_Mapping_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  connectionName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  domain?: Maybe<Scalars['String']>;
  enforceIdpOnly?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  idpType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Account_Domain_Mapping_Sum_Fields = {
  __typename?: 'account_domain_mapping_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "account_domain_mapping" */
export enum Account_Domain_Mapping_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ConnectionName = 'connectionName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  EnforceIdpOnly = 'enforceIdpOnly',
  /** column name */
  Id = 'id',
  /** column name */
  IdpType = 'idpType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Account_Domain_Mapping_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Account_Domain_Mapping_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Domain_Mapping_Set_Input>;
  where: Account_Domain_Mapping_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Domain_Mapping_Var_Pop_Fields = {
  __typename?: 'account_domain_mapping_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Domain_Mapping_Var_Samp_Fields = {
  __typename?: 'account_domain_mapping_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Domain_Mapping_Variance_Fields = {
  __typename?: 'account_domain_mapping_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "account_event_limits" */
export type Account_Event_Limits = {
  __typename?: 'account_event_limits';
  accountId: Scalars['uuid'];
  endedCollectingAt: Scalars['timestamptz'];
  eventsLimit: Scalars['Int'];
  id: Scalars['uuid'];
  reachedLimitAt: Scalars['timestamptz'];
  startedCollectingAt: Scalars['timestamptz'];
  sumEvents: Scalars['Int'];
};

/** aggregated selection of "account_event_limits" */
export type Account_Event_Limits_Aggregate = {
  __typename?: 'account_event_limits_aggregate';
  aggregate?: Maybe<Account_Event_Limits_Aggregate_Fields>;
  nodes: Array<Account_Event_Limits>;
};

/** aggregate fields of "account_event_limits" */
export type Account_Event_Limits_Aggregate_Fields = {
  __typename?: 'account_event_limits_aggregate_fields';
  avg?: Maybe<Account_Event_Limits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Event_Limits_Max_Fields>;
  min?: Maybe<Account_Event_Limits_Min_Fields>;
  stddev?: Maybe<Account_Event_Limits_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Event_Limits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Event_Limits_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Event_Limits_Sum_Fields>;
  var_pop?: Maybe<Account_Event_Limits_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Event_Limits_Var_Samp_Fields>;
  variance?: Maybe<Account_Event_Limits_Variance_Fields>;
};


/** aggregate fields of "account_event_limits" */
export type Account_Event_Limits_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Event_Limits_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Event_Limits_Avg_Fields = {
  __typename?: 'account_event_limits_avg_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_event_limits". All fields are combined with a logical 'AND'. */
export type Account_Event_Limits_Bool_Exp = {
  _and?: Maybe<Array<Account_Event_Limits_Bool_Exp>>;
  _not?: Maybe<Account_Event_Limits_Bool_Exp>;
  _or?: Maybe<Array<Account_Event_Limits_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  endedCollectingAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventsLimit?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reachedLimitAt?: Maybe<Timestamptz_Comparison_Exp>;
  startedCollectingAt?: Maybe<Timestamptz_Comparison_Exp>;
  sumEvents?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_event_limits" */
export enum Account_Event_Limits_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB5f292139eb3f258abafb51da29 = 'PK_b5f292139eb3f258abafb51da29'
}

/** input type for incrementing numeric columns in table "account_event_limits" */
export type Account_Event_Limits_Inc_Input = {
  eventsLimit?: Maybe<Scalars['Int']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_event_limits" */
export type Account_Event_Limits_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  endedCollectingAt?: Maybe<Scalars['timestamptz']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  reachedLimitAt?: Maybe<Scalars['timestamptz']>;
  startedCollectingAt?: Maybe<Scalars['timestamptz']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Account_Event_Limits_Max_Fields = {
  __typename?: 'account_event_limits_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  endedCollectingAt?: Maybe<Scalars['timestamptz']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  reachedLimitAt?: Maybe<Scalars['timestamptz']>;
  startedCollectingAt?: Maybe<Scalars['timestamptz']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Account_Event_Limits_Min_Fields = {
  __typename?: 'account_event_limits_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  endedCollectingAt?: Maybe<Scalars['timestamptz']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  reachedLimitAt?: Maybe<Scalars['timestamptz']>;
  startedCollectingAt?: Maybe<Scalars['timestamptz']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "account_event_limits" */
export type Account_Event_Limits_Mutation_Response = {
  __typename?: 'account_event_limits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Event_Limits>;
};

/** on_conflict condition type for table "account_event_limits" */
export type Account_Event_Limits_On_Conflict = {
  constraint: Account_Event_Limits_Constraint;
  update_columns?: Array<Account_Event_Limits_Update_Column>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};

/** Ordering options when selecting data from "account_event_limits". */
export type Account_Event_Limits_Order_By = {
  accountId?: Maybe<Order_By>;
  endedCollectingAt?: Maybe<Order_By>;
  eventsLimit?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reachedLimitAt?: Maybe<Order_By>;
  startedCollectingAt?: Maybe<Order_By>;
  sumEvents?: Maybe<Order_By>;
};

/** primary key columns input for table: account_event_limits */
export type Account_Event_Limits_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account_event_limits" */
export enum Account_Event_Limits_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  EndedCollectingAt = 'endedCollectingAt',
  /** column name */
  EventsLimit = 'eventsLimit',
  /** column name */
  Id = 'id',
  /** column name */
  ReachedLimitAt = 'reachedLimitAt',
  /** column name */
  StartedCollectingAt = 'startedCollectingAt',
  /** column name */
  SumEvents = 'sumEvents'
}

/** input type for updating data in table "account_event_limits" */
export type Account_Event_Limits_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  endedCollectingAt?: Maybe<Scalars['timestamptz']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  reachedLimitAt?: Maybe<Scalars['timestamptz']>;
  startedCollectingAt?: Maybe<Scalars['timestamptz']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Account_Event_Limits_Stddev_Fields = {
  __typename?: 'account_event_limits_stddev_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Event_Limits_Stddev_Pop_Fields = {
  __typename?: 'account_event_limits_stddev_pop_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Event_Limits_Stddev_Samp_Fields = {
  __typename?: 'account_event_limits_stddev_samp_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account_event_limits" */
export type Account_Event_Limits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Event_Limits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Event_Limits_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  endedCollectingAt?: Maybe<Scalars['timestamptz']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  reachedLimitAt?: Maybe<Scalars['timestamptz']>;
  startedCollectingAt?: Maybe<Scalars['timestamptz']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Account_Event_Limits_Sum_Fields = {
  __typename?: 'account_event_limits_sum_fields';
  eventsLimit?: Maybe<Scalars['Int']>;
  sumEvents?: Maybe<Scalars['Int']>;
};

/** update columns of table "account_event_limits" */
export enum Account_Event_Limits_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  EndedCollectingAt = 'endedCollectingAt',
  /** column name */
  EventsLimit = 'eventsLimit',
  /** column name */
  Id = 'id',
  /** column name */
  ReachedLimitAt = 'reachedLimitAt',
  /** column name */
  StartedCollectingAt = 'startedCollectingAt',
  /** column name */
  SumEvents = 'sumEvents'
}

export type Account_Event_Limits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Account_Event_Limits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Event_Limits_Set_Input>;
  where: Account_Event_Limits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Event_Limits_Var_Pop_Fields = {
  __typename?: 'account_event_limits_var_pop_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Event_Limits_Var_Samp_Fields = {
  __typename?: 'account_event_limits_var_samp_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Event_Limits_Variance_Fields = {
  __typename?: 'account_event_limits_variance_fields';
  eventsLimit?: Maybe<Scalars['Float']>;
  sumEvents?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "account" */
export type Account_Inc_Input = {
  logsRetentionDays?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  identityProvider?: Maybe<Scalars['String']>;
  inPoc?: Maybe<Scalars['Boolean']>;
  installations?: Maybe<Installation_Arr_Rel_Insert_Input>;
  isRbacEnabled?: Maybe<Scalars['Boolean']>;
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  users?: Maybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identityProvider?: Maybe<Scalars['String']>;
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identityProvider?: Maybe<Scalars['String']>;
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Account_On_Conflict>;
};

/** on_conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: Maybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  acquisitionChannel?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  features?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identityProvider?: Maybe<Order_By>;
  inPoc?: Maybe<Order_By>;
  installations_aggregate?: Maybe<Installation_Aggregate_Order_By>;
  isRbacEnabled?: Maybe<Order_By>;
  logsRetentionDays?: Maybe<Order_By>;
  marketplaceConnectionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  plan?: Maybe<Order_By>;
  purchaseDate?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  trialEndAt?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  users_aggregate?: Maybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Account_Prepend_Input = {
  features?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  AcquisitionChannel = 'acquisitionChannel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityProvider = 'identityProvider',
  /** column name */
  InPoc = 'inPoc',
  /** column name */
  IsRbacEnabled = 'isRbacEnabled',
  /** column name */
  LogsRetentionDays = 'logsRetentionDays',
  /** column name */
  MarketplaceConnectionId = 'marketplaceConnectionId',
  /** column name */
  Name = 'name',
  /** column name */
  Origin = 'origin',
  /** column name */
  Plan = 'plan',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  TrialEndAt = 'trialEndAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  identityProvider?: Maybe<Scalars['String']>;
  inPoc?: Maybe<Scalars['Boolean']>;
  isRbacEnabled?: Maybe<Scalars['Boolean']>;
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Account_Stddev_Fields = {
  __typename?: 'account_stddev_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Stddev_Pop_Fields = {
  __typename?: 'account_stddev_pop_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Stddev_Samp_Fields = {
  __typename?: 'account_stddev_samp_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account" */
export type Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Stream_Cursor_Value_Input = {
  acquisitionChannel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  identityProvider?: Maybe<Scalars['String']>;
  inPoc?: Maybe<Scalars['Boolean']>;
  isRbacEnabled?: Maybe<Scalars['Boolean']>;
  logsRetentionDays?: Maybe<Scalars['Int']>;
  marketplaceConnectionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  trialEndAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Account_Sum_Fields = {
  __typename?: 'account_sum_fields';
  logsRetentionDays?: Maybe<Scalars['Int']>;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  AcquisitionChannel = 'acquisitionChannel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityProvider = 'identityProvider',
  /** column name */
  InPoc = 'inPoc',
  /** column name */
  IsRbacEnabled = 'isRbacEnabled',
  /** column name */
  LogsRetentionDays = 'logsRetentionDays',
  /** column name */
  MarketplaceConnectionId = 'marketplaceConnectionId',
  /** column name */
  Name = 'name',
  /** column name */
  Origin = 'origin',
  /** column name */
  Plan = 'plan',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  TrialEndAt = 'trialEndAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Account_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Account_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Var_Pop_Fields = {
  __typename?: 'account_var_pop_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Var_Samp_Fields = {
  __typename?: 'account_var_samp_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Variance_Fields = {
  __typename?: 'account_variance_fields';
  logsRetentionDays?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "agent_task" */
export type Agent_Task = {
  __typename?: 'agent_task';
  accountId: Scalars['uuid'];
  agentId: Scalars['String'];
  createdAt: Scalars['timestamp'];
  data: Scalars['jsonb'];
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  state: Scalars['String'];
  tracingMetadata: Scalars['jsonb'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "agent_task" */
export type Agent_TaskDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "agent_task" */
export type Agent_TaskMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "agent_task" */
export type Agent_TaskTracingMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "agent_task" */
export type Agent_Task_Aggregate = {
  __typename?: 'agent_task_aggregate';
  aggregate?: Maybe<Agent_Task_Aggregate_Fields>;
  nodes: Array<Agent_Task>;
};

/** aggregate fields of "agent_task" */
export type Agent_Task_Aggregate_Fields = {
  __typename?: 'agent_task_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agent_Task_Max_Fields>;
  min?: Maybe<Agent_Task_Min_Fields>;
};


/** aggregate fields of "agent_task" */
export type Agent_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agent_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agent_Task_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  tracingMetadata?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "agent_task". All fields are combined with a logical 'AND'. */
export type Agent_Task_Bool_Exp = {
  _and?: Maybe<Array<Agent_Task_Bool_Exp>>;
  _not?: Maybe<Agent_Task_Bool_Exp>;
  _or?: Maybe<Array<Agent_Task_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  deletionScheduledAt?: Maybe<Timestamp_Comparison_Exp>;
  failureMessage?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tracingMetadata?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "agent_task" */
export enum Agent_Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_0c37b15dd842ad1ab744c2ad67a = 'PK_0c37b15dd842ad1ab744c2ad67a'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agent_Task_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
  tracingMetadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agent_Task_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
  tracingMetadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agent_Task_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  tracingMetadata?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "agent_task" */
export type Agent_Task_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  tracingMetadata?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Agent_Task_Max_Fields = {
  __typename?: 'agent_task_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Agent_Task_Min_Fields = {
  __typename?: 'agent_task_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "agent_task" */
export type Agent_Task_Mutation_Response = {
  __typename?: 'agent_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Task>;
};

/** input type for inserting object relation for remote table "agent_task" */
export type Agent_Task_Obj_Rel_Insert_Input = {
  data: Agent_Task_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Agent_Task_On_Conflict>;
};

/** on_conflict condition type for table "agent_task" */
export type Agent_Task_On_Conflict = {
  constraint: Agent_Task_Constraint;
  update_columns?: Array<Agent_Task_Update_Column>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "agent_task". */
export type Agent_Task_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  deletionScheduledAt?: Maybe<Order_By>;
  failureMessage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tracingMetadata?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: agent_task */
export type Agent_Task_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agent_Task_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  tracingMetadata?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "agent_task_result" */
export type Agent_Task_Result = {
  __typename?: 'agent_task_result';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  result?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  task?: Maybe<Agent_Task>;
  taskId: Scalars['uuid'];
  type: Scalars['String'];
};


/** columns and relationships of "agent_task_result" */
export type Agent_Task_ResultResultArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "agent_task_result" */
export type Agent_Task_Result_Aggregate = {
  __typename?: 'agent_task_result_aggregate';
  aggregate?: Maybe<Agent_Task_Result_Aggregate_Fields>;
  nodes: Array<Agent_Task_Result>;
};

/** aggregate fields of "agent_task_result" */
export type Agent_Task_Result_Aggregate_Fields = {
  __typename?: 'agent_task_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agent_Task_Result_Max_Fields>;
  min?: Maybe<Agent_Task_Result_Min_Fields>;
};


/** aggregate fields of "agent_task_result" */
export type Agent_Task_Result_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agent_Task_Result_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agent_Task_Result_Append_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "agent_task_result". All fields are combined with a logical 'AND'. */
export type Agent_Task_Result_Bool_Exp = {
  _and?: Maybe<Array<Agent_Task_Result_Bool_Exp>>;
  _not?: Maybe<Agent_Task_Result_Bool_Exp>;
  _or?: Maybe<Array<Agent_Task_Result_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  result?: Maybe<Jsonb_Comparison_Exp>;
  task?: Maybe<Agent_Task_Bool_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "agent_task_result" */
export enum Agent_Task_Result_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkC517f66ce64b901dafacde3e187 = 'PK_c517f66ce64b901dafacde3e187'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agent_Task_Result_Delete_At_Path_Input = {
  result?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agent_Task_Result_Delete_Elem_Input = {
  result?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agent_Task_Result_Delete_Key_Input = {
  result?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "agent_task_result" */
export type Agent_Task_Result_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  task?: Maybe<Agent_Task_Obj_Rel_Insert_Input>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Agent_Task_Result_Max_Fields = {
  __typename?: 'agent_task_result_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Agent_Task_Result_Min_Fields = {
  __typename?: 'agent_task_result_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "agent_task_result" */
export type Agent_Task_Result_Mutation_Response = {
  __typename?: 'agent_task_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Task_Result>;
};

/** on_conflict condition type for table "agent_task_result" */
export type Agent_Task_Result_On_Conflict = {
  constraint: Agent_Task_Result_Constraint;
  update_columns?: Array<Agent_Task_Result_Update_Column>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "agent_task_result". */
export type Agent_Task_Result_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  task?: Maybe<Agent_Task_Order_By>;
  taskId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: agent_task_result */
export type Agent_Task_Result_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agent_Task_Result_Prepend_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "agent_task_result" */
export enum Agent_Task_Result_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "agent_task_result" */
export type Agent_Task_Result_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "agent_task_result" */
export type Agent_Task_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Task_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agent_Task_Result_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "agent_task_result" */
export enum Agent_Task_Result_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  Type = 'type'
}

export type Agent_Task_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Agent_Task_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Agent_Task_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Agent_Task_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Agent_Task_Result_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Agent_Task_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Agent_Task_Result_Set_Input>;
  where: Agent_Task_Result_Bool_Exp;
};

/** select columns of table "agent_task" */
export enum Agent_Task_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  DeletionScheduledAt = 'deletionScheduledAt',
  /** column name */
  FailureMessage = 'failureMessage',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  State = 'state',
  /** column name */
  TracingMetadata = 'tracingMetadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "agent_task" */
export type Agent_Task_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  tracingMetadata?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "agent_task" */
export type Agent_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agent_Task_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  failureMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  tracingMetadata?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "agent_task" */
export enum Agent_Task_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  DeletionScheduledAt = 'deletionScheduledAt',
  /** column name */
  FailureMessage = 'failureMessage',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  State = 'state',
  /** column name */
  TracingMetadata = 'tracingMetadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Agent_Task_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Agent_Task_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Agent_Task_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Agent_Task_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Agent_Task_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Agent_Task_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Agent_Task_Set_Input>;
  where: Agent_Task_Bool_Exp;
};

/** columns and relationships of "api_key" */
export type Api_Key = {
  __typename?: 'api_key';
  accountId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "api_key" */
export type Api_Key_Aggregate = {
  __typename?: 'api_key_aggregate';
  aggregate?: Maybe<Api_Key_Aggregate_Fields>;
  nodes: Array<Api_Key>;
};

/** aggregate fields of "api_key" */
export type Api_Key_Aggregate_Fields = {
  __typename?: 'api_key_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Api_Key_Max_Fields>;
  min?: Maybe<Api_Key_Min_Fields>;
};


/** aggregate fields of "api_key" */
export type Api_Key_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Api_Key_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "api_key". All fields are combined with a logical 'AND'. */
export type Api_Key_Bool_Exp = {
  _and?: Maybe<Array<Api_Key_Bool_Exp>>;
  _not?: Maybe<Api_Key_Bool_Exp>;
  _or?: Maybe<Array<Api_Key_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_key" */
export enum Api_Key_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB1bd840641b8acbaad89c3d8d11 = 'PK_b1bd840641b8acbaad89c3d8d11'
}

/** input type for inserting data into table "api_key" */
export type Api_Key_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Api_Key_Max_Fields = {
  __typename?: 'api_key_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Api_Key_Min_Fields = {
  __typename?: 'api_key_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "api_key" */
export type Api_Key_Mutation_Response = {
  __typename?: 'api_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Key>;
};

/** on_conflict condition type for table "api_key" */
export type Api_Key_On_Conflict = {
  constraint: Api_Key_Constraint;
  update_columns?: Array<Api_Key_Update_Column>;
  where?: Maybe<Api_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "api_key". */
export type Api_Key_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: api_key */
export type Api_Key_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "api_key" */
export enum Api_Key_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "api_key" */
export type Api_Key_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "api_key" */
export type Api_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Key_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "api_key" */
export enum Api_Key_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

export type Api_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Api_Key_Set_Input>;
  where: Api_Key_Bool_Exp;
};

/** columns and relationships of "app_view" */
export type App_View = {
  __typename?: 'app_view';
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "app_view" */
export type App_ViewServiceIdentifiersArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "app_view" */
export type App_ViewServiceIdsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "app_view" */
export type App_View_Aggregate = {
  __typename?: 'app_view_aggregate';
  aggregate?: Maybe<App_View_Aggregate_Fields>;
  nodes: Array<App_View>;
};

/** aggregate fields of "app_view" */
export type App_View_Aggregate_Fields = {
  __typename?: 'app_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_View_Max_Fields>;
  min?: Maybe<App_View_Min_Fields>;
};


/** aggregate fields of "app_view" */
export type App_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_View_Append_Input = {
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "app_view". All fields are combined with a logical 'AND'. */
export type App_View_Bool_Exp = {
  _and?: Maybe<Array<App_View_Bool_Exp>>;
  _not?: Maybe<App_View_Bool_Exp>;
  _or?: Maybe<Array<App_View_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  authorId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  serviceIdentifierType?: Maybe<App_View_Serviceidentifiertype_Enum_Comparison_Exp>;
  serviceIdentifiers?: Maybe<Jsonb_Comparison_Exp>;
  serviceIds?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_view" */
export enum App_View_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB8d0286791567afaae045eb3025 = 'PK_b8d0286791567afaae045eb3025',
  /** unique or primary key constraint on columns "accountId" */
  IdxAccountidLowerName = 'idx_accountid_lower_name'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_View_Delete_At_Path_Input = {
  serviceIdentifiers?: Maybe<Array<Scalars['String']>>;
  serviceIds?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_View_Delete_Elem_Input = {
  serviceIdentifiers?: Maybe<Scalars['Int']>;
  serviceIds?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_View_Delete_Key_Input = {
  serviceIdentifiers?: Maybe<Scalars['String']>;
  serviceIds?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "app_view" */
export type App_View_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_View_Max_Fields = {
  __typename?: 'app_view_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_View_Min_Fields = {
  __typename?: 'app_view_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_view" */
export type App_View_Mutation_Response = {
  __typename?: 'app_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_View>;
};

/** on_conflict condition type for table "app_view" */
export type App_View_On_Conflict = {
  constraint: App_View_Constraint;
  update_columns?: Array<App_View_Update_Column>;
  where?: Maybe<App_View_Bool_Exp>;
};

/** Ordering options when selecting data from "app_view". */
export type App_View_Order_By = {
  accountId?: Maybe<Order_By>;
  authorId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  serviceIdentifierType?: Maybe<Order_By>;
  serviceIdentifiers?: Maybe<Order_By>;
  serviceIds?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: app_view */
export type App_View_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_View_Prepend_Input = {
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "app_view" */
export enum App_View_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceIdentifierType = 'serviceIdentifierType',
  /** column name */
  ServiceIdentifiers = 'serviceIdentifiers',
  /** column name */
  ServiceIds = 'serviceIds',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "app_view_serviceidentifiertype_enum". All fields are combined with logical 'AND'. */
export type App_View_Serviceidentifiertype_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _gt?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _gte?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _in?: Maybe<Array<Scalars['app_view_serviceidentifiertype_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _lte?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _neq?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  _nin?: Maybe<Array<Scalars['app_view_serviceidentifiertype_enum']>>;
};

/** input type for updating data in table "app_view" */
export type App_View_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "app_view" */
export type App_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_View_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  serviceIdentifierType?: Maybe<Scalars['app_view_serviceidentifiertype_enum']>;
  serviceIdentifiers?: Maybe<Scalars['jsonb']>;
  serviceIds?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "app_view" */
export enum App_View_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceIdentifierType = 'serviceIdentifierType',
  /** column name */
  ServiceIdentifiers = 'serviceIdentifiers',
  /** column name */
  ServiceIds = 'serviceIds',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type App_View_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<App_View_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<App_View_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<App_View_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<App_View_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<App_View_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<App_View_Set_Input>;
  where: App_View_Bool_Exp;
};

/** columns and relationships of "audit" */
export type Audit = {
  __typename?: 'audit';
  /** An object relationship */
  account: Account;
  accountId: Scalars['uuid'];
  additionalDetails?: Maybe<Scalars['jsonb']>;
  category: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  crudType: Scalars['String'];
  details: Scalars['jsonb'];
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "audit" */
export type AuditAdditionalDetailsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "audit" */
export type AuditDetailsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "audit" */
export type Audit_Aggregate = {
  __typename?: 'audit_aggregate';
  aggregate?: Maybe<Audit_Aggregate_Fields>;
  nodes: Array<Audit>;
};

/** aggregate fields of "audit" */
export type Audit_Aggregate_Fields = {
  __typename?: 'audit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audit_Max_Fields>;
  min?: Maybe<Audit_Min_Fields>;
};


/** aggregate fields of "audit" */
export type Audit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Append_Input = {
  additionalDetails?: Maybe<Scalars['jsonb']>;
  details?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "audit". All fields are combined with a logical 'AND'. */
export type Audit_Bool_Exp = {
  _and?: Maybe<Array<Audit_Bool_Exp>>;
  _not?: Maybe<Audit_Bool_Exp>;
  _or?: Maybe<Array<Audit_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  additionalDetails?: Maybe<Jsonb_Comparison_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  crudType?: Maybe<String_Comparison_Exp>;
  details?: Maybe<Jsonb_Comparison_Exp>;
  eventTimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit" */
export enum Audit_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1d3d120ddaf7bc9b1ed68ed463a = 'PK_1d3d120ddaf7bc9b1ed68ed463a'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Delete_At_Path_Input = {
  additionalDetails?: Maybe<Array<Scalars['String']>>;
  details?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Delete_Elem_Input = {
  additionalDetails?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Delete_Key_Input = {
  additionalDetails?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "audit" */
export type Audit_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountId?: Maybe<Scalars['uuid']>;
  additionalDetails?: Maybe<Scalars['jsonb']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crudType?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['jsonb']>;
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audit_Max_Fields = {
  __typename?: 'audit_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crudType?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Audit_Min_Fields = {
  __typename?: 'audit_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crudType?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "audit" */
export type Audit_Mutation_Response = {
  __typename?: 'audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit>;
};

/** on_conflict condition type for table "audit" */
export type Audit_On_Conflict = {
  constraint: Audit_Constraint;
  update_columns?: Array<Audit_Update_Column>;
  where?: Maybe<Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "audit". */
export type Audit_Order_By = {
  account?: Maybe<Account_Order_By>;
  accountId?: Maybe<Order_By>;
  additionalDetails?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crudType?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  eventTimestamp?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: audit */
export type Audit_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Prepend_Input = {
  additionalDetails?: Maybe<Scalars['jsonb']>;
  details?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "audit" */
export enum Audit_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AdditionalDetails = 'additionalDetails',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrudType = 'crudType',
  /** column name */
  Details = 'details',
  /** column name */
  EventTimestamp = 'eventTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "audit" */
export type Audit_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  additionalDetails?: Maybe<Scalars['jsonb']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crudType?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['jsonb']>;
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audit" */
export type Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  additionalDetails?: Maybe<Scalars['jsonb']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crudType?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['jsonb']>;
  eventTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "audit" */
export enum Audit_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AdditionalDetails = 'additionalDetails',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrudType = 'crudType',
  /** column name */
  Details = 'details',
  /** column name */
  EventTimestamp = 'eventTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Audit_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Audit_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Audit_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Audit_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Audit_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Audit_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audit_Set_Input>;
  where: Audit_Bool_Exp;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "channel_configuration" */
export type Channel_Configuration = {
  __typename?: 'channel_configuration';
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "channel_configuration" */
export type Channel_ConfigurationConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "channel_configuration" */
export type Channel_Configuration_Aggregate = {
  __typename?: 'channel_configuration_aggregate';
  aggregate?: Maybe<Channel_Configuration_Aggregate_Fields>;
  nodes: Array<Channel_Configuration>;
};

/** aggregate fields of "channel_configuration" */
export type Channel_Configuration_Aggregate_Fields = {
  __typename?: 'channel_configuration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Channel_Configuration_Max_Fields>;
  min?: Maybe<Channel_Configuration_Min_Fields>;
};


/** aggregate fields of "channel_configuration" */
export type Channel_Configuration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Channel_Configuration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channel_Configuration_Append_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "channel_configuration". All fields are combined with a logical 'AND'. */
export type Channel_Configuration_Bool_Exp = {
  _and?: Maybe<Array<Channel_Configuration_Bool_Exp>>;
  _not?: Maybe<Channel_Configuration_Bool_Exp>;
  _or?: Maybe<Array<Channel_Configuration_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  configuration?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_configuration" */
export enum Channel_Configuration_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA081762c2f0f4fe8c586be219db = 'PK_a081762c2f0f4fe8c586be219db'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channel_Configuration_Delete_At_Path_Input = {
  configuration?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channel_Configuration_Delete_Elem_Input = {
  configuration?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channel_Configuration_Delete_Key_Input = {
  configuration?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "channel_configuration" */
export type Channel_Configuration_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Channel_Configuration_Max_Fields = {
  __typename?: 'channel_configuration_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Channel_Configuration_Min_Fields = {
  __typename?: 'channel_configuration_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "channel_configuration" */
export type Channel_Configuration_Mutation_Response = {
  __typename?: 'channel_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Configuration>;
};

/** on_conflict condition type for table "channel_configuration" */
export type Channel_Configuration_On_Conflict = {
  constraint: Channel_Configuration_Constraint;
  update_columns?: Array<Channel_Configuration_Update_Column>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_configuration". */
export type Channel_Configuration_Order_By = {
  accountId?: Maybe<Order_By>;
  configuration?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: channel_configuration */
export type Channel_Configuration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channel_Configuration_Prepend_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "channel_configuration" */
export enum Channel_Configuration_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "channel_configuration" */
export type Channel_Configuration_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "channel_configuration" */
export type Channel_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Configuration_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "channel_configuration" */
export enum Channel_Configuration_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Channel_Configuration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Channel_Configuration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Channel_Configuration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Channel_Configuration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Channel_Configuration_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Channel_Configuration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Channel_Configuration_Set_Input>;
  where: Channel_Configuration_Bool_Exp;
};


/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};

export type CloseMonitorOutput = {
  __typename?: 'closeMonitorOutput';
  success: Scalars['Boolean'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "event_actions" */
export type Event_Actions = {
  __typename?: 'event_actions';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['jsonb']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "event_actions" */
export type Event_ActionsActionArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_actions" */
export type Event_ActionsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_actions" */
export type Event_ActionsRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_actions" */
export type Event_ActionsResultArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_actions" */
export type Event_ActionsServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_actions" */
export type Event_Actions_Aggregate = {
  __typename?: 'event_actions_aggregate';
  aggregate?: Maybe<Event_Actions_Aggregate_Fields>;
  nodes: Array<Event_Actions>;
};

/** aggregate fields of "event_actions" */
export type Event_Actions_Aggregate_Fields = {
  __typename?: 'event_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Actions_Max_Fields>;
  min?: Maybe<Event_Actions_Min_Fields>;
};


/** aggregate fields of "event_actions" */
export type Event_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Actions_Append_Input = {
  action?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_actions". All fields are combined with a logical 'AND'. */
export type Event_Actions_Bool_Exp = {
  _and?: Maybe<Array<Event_Actions_Bool_Exp>>;
  _not?: Maybe<Event_Actions_Bool_Exp>;
  _or?: Maybe<Array<Event_Actions_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<Jsonb_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  result?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_actions" */
export enum Event_Actions_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_3c7ab9ea713fc9c3c7b10953aa9 = 'PK_3c7ab9ea713fc9c3c7b10953aa9'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Actions_Delete_At_Path_Input = {
  action?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  result?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Actions_Delete_Elem_Input = {
  action?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Actions_Delete_Key_Input = {
  action?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_actions" */
export type Event_Actions_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['jsonb']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Event_Actions_Max_Fields = {
  __typename?: 'event_actions_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Event_Actions_Min_Fields = {
  __typename?: 'event_actions_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "event_actions" */
export type Event_Actions_Mutation_Response = {
  __typename?: 'event_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Actions>;
};

/** on_conflict condition type for table "event_actions" */
export type Event_Actions_On_Conflict = {
  constraint: Event_Actions_Constraint;
  update_columns?: Array<Event_Actions_Update_Column>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "event_actions". */
export type Event_Actions_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: event_actions */
export type Event_Actions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Actions_Prepend_Input = {
  action?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_actions" */
export enum Event_Actions_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Result = 'result',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "event_actions" */
export type Event_Actions_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['jsonb']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "event_actions" */
export type Event_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Actions_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['jsonb']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  result?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "event_actions" */
export enum Event_Actions_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Result = 'result',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Event_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Actions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Actions_Set_Input>;
  where: Event_Actions_Bool_Exp;
};

/** columns and relationships of "event_config_map" */
export type Event_Config_Map = {
  __typename?: 'event_config_map';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  agentId?: Maybe<Scalars['uuid']>;
  clusterName: Scalars['String'];
  configMapName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  k8sDefault: Scalars['Boolean'];
  namespace: Scalars['String'];
  newData: Scalars['jsonb'];
  oldData: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_config_map" */
export type Event_Config_MapNewDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_config_map" */
export type Event_Config_MapOldDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_config_map" */
export type Event_Config_MapRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_config_map" */
export type Event_Config_MapServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_config_map" */
export type Event_Config_Map_Aggregate = {
  __typename?: 'event_config_map_aggregate';
  aggregate?: Maybe<Event_Config_Map_Aggregate_Fields>;
  nodes: Array<Event_Config_Map>;
};

/** aggregate fields of "event_config_map" */
export type Event_Config_Map_Aggregate_Fields = {
  __typename?: 'event_config_map_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Config_Map_Max_Fields>;
  min?: Maybe<Event_Config_Map_Min_Fields>;
};


/** aggregate fields of "event_config_map" */
export type Event_Config_Map_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Config_Map_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Config_Map_Append_Input = {
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_config_map". All fields are combined with a logical 'AND'. */
export type Event_Config_Map_Bool_Exp = {
  _and?: Maybe<Array<Event_Config_Map_Bool_Exp>>;
  _not?: Maybe<Event_Config_Map_Bool_Exp>;
  _or?: Maybe<Array<Event_Config_Map_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  agentId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  configMapName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  k8sDefault?: Maybe<Boolean_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newData?: Maybe<Jsonb_Comparison_Exp>;
  oldData?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_config_map" */
export enum Event_Config_Map_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA999934698e37561d896a1c38b7 = 'PK_a999934698e37561d896a1c38b7'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Config_Map_Delete_At_Path_Input = {
  newData?: Maybe<Array<Scalars['String']>>;
  oldData?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Config_Map_Delete_Elem_Input = {
  newData?: Maybe<Scalars['Int']>;
  oldData?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Config_Map_Delete_Key_Input = {
  newData?: Maybe<Scalars['String']>;
  oldData?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_config_map" */
export type Event_Config_Map_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configMapName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Config_Map_Max_Fields = {
  __typename?: 'event_config_map_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configMapName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Config_Map_Min_Fields = {
  __typename?: 'event_config_map_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configMapName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_config_map" */
export type Event_Config_Map_Mutation_Response = {
  __typename?: 'event_config_map_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Config_Map>;
};

/** on_conflict condition type for table "event_config_map" */
export type Event_Config_Map_On_Conflict = {
  constraint: Event_Config_Map_Constraint;
  update_columns?: Array<Event_Config_Map_Update_Column>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};

/** Ordering options when selecting data from "event_config_map". */
export type Event_Config_Map_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  configMapName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  k8sDefault?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newData?: Maybe<Order_By>;
  oldData?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_config_map */
export type Event_Config_Map_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Config_Map_Prepend_Input = {
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_config_map" */
export enum Event_Config_Map_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  ConfigMapName = 'configMapName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  K8sDefault = 'k8sDefault',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_config_map" */
export type Event_Config_Map_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configMapName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_config_map" */
export type Event_Config_Map_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Config_Map_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Config_Map_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configMapName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_config_map" */
export enum Event_Config_Map_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  ConfigMapName = 'configMapName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  K8sDefault = 'k8sDefault',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Config_Map_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Config_Map_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Config_Map_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Config_Map_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Config_Map_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Config_Map_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Config_Map_Set_Input>;
  where: Event_Config_Map_Bool_Exp;
};

/** columns and relationships of "event_cron_job" */
export type Event_Cron_Job = {
  __typename?: 'event_cron_job';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newObj: Scalars['jsonb'];
  oldObj: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  schedule: Scalars['String'];
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['jsonb'];
  summary: Scalars['String'];
  suspend: Scalars['Boolean'];
  uid: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_cron_job" */
export type Event_Cron_JobNewObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_cron_job" */
export type Event_Cron_JobOldObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_cron_job" */
export type Event_Cron_JobRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_cron_job" */
export type Event_Cron_JobServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_cron_job" */
export type Event_Cron_JobStatusArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_cron_job" */
export type Event_Cron_Job_Aggregate = {
  __typename?: 'event_cron_job_aggregate';
  aggregate?: Maybe<Event_Cron_Job_Aggregate_Fields>;
  nodes: Array<Event_Cron_Job>;
};

/** aggregate fields of "event_cron_job" */
export type Event_Cron_Job_Aggregate_Fields = {
  __typename?: 'event_cron_job_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Cron_Job_Max_Fields>;
  min?: Maybe<Event_Cron_Job_Min_Fields>;
};


/** aggregate fields of "event_cron_job" */
export type Event_Cron_Job_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Cron_Job_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Cron_Job_Append_Input = {
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_cron_job". All fields are combined with a logical 'AND'. */
export type Event_Cron_Job_Bool_Exp = {
  _and?: Maybe<Array<Event_Cron_Job_Bool_Exp>>;
  _not?: Maybe<Event_Cron_Job_Bool_Exp>;
  _or?: Maybe<Array<Event_Cron_Job_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newObj?: Maybe<Jsonb_Comparison_Exp>;
  oldObj?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  schedule?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  suspend?: Maybe<Boolean_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_cron_job" */
export enum Event_Cron_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB9602ffb5510caa655d1c60e525 = 'PK_b9602ffb5510caa655d1c60e525'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Cron_Job_Delete_At_Path_Input = {
  newObj?: Maybe<Array<Scalars['String']>>;
  oldObj?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Cron_Job_Delete_Elem_Input = {
  newObj?: Maybe<Scalars['Int']>;
  oldObj?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Cron_Job_Delete_Key_Input = {
  newObj?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_cron_job" */
export type Event_Cron_Job_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  schedule?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  suspend?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Cron_Job_Max_Fields = {
  __typename?: 'event_cron_job_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Cron_Job_Min_Fields = {
  __typename?: 'event_cron_job_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_cron_job" */
export type Event_Cron_Job_Mutation_Response = {
  __typename?: 'event_cron_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Cron_Job>;
};

/** on_conflict condition type for table "event_cron_job" */
export type Event_Cron_Job_On_Conflict = {
  constraint: Event_Cron_Job_Constraint;
  update_columns?: Array<Event_Cron_Job_Update_Column>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "event_cron_job". */
export type Event_Cron_Job_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newObj?: Maybe<Order_By>;
  oldObj?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  schedule?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  suspend?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_cron_job */
export type Event_Cron_Job_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Cron_Job_Prepend_Input = {
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_cron_job" */
export enum Event_Cron_Job_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Suspend = 'suspend',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_cron_job" */
export type Event_Cron_Job_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  schedule?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  suspend?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_cron_job" */
export type Event_Cron_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Cron_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Cron_Job_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  schedule?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  suspend?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_cron_job" */
export enum Event_Cron_Job_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Suspend = 'suspend',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Cron_Job_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Cron_Job_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Cron_Job_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Cron_Job_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Cron_Job_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Cron_Job_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Cron_Job_Set_Input>;
  where: Event_Cron_Job_Bool_Exp;
};

/** columns and relationships of "event_datadog_monitor" */
export type Event_Datadog_Monitor = {
  __typename?: 'event_datadog_monitor';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  envFromInstallation: Scalars['String'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  monitorTags: Scalars['jsonb'];
  properties: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_datadog_monitor" */
export type Event_Datadog_MonitorMonitorTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_datadog_monitor" */
export type Event_Datadog_MonitorPropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_datadog_monitor" */
export type Event_Datadog_MonitorServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_datadog_monitor" */
export type Event_Datadog_Monitor_Aggregate = {
  __typename?: 'event_datadog_monitor_aggregate';
  aggregate?: Maybe<Event_Datadog_Monitor_Aggregate_Fields>;
  nodes: Array<Event_Datadog_Monitor>;
};

/** aggregate fields of "event_datadog_monitor" */
export type Event_Datadog_Monitor_Aggregate_Fields = {
  __typename?: 'event_datadog_monitor_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Datadog_Monitor_Max_Fields>;
  min?: Maybe<Event_Datadog_Monitor_Min_Fields>;
};


/** aggregate fields of "event_datadog_monitor" */
export type Event_Datadog_Monitor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Datadog_Monitor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Datadog_Monitor_Append_Input = {
  monitorTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_datadog_monitor". All fields are combined with a logical 'AND'. */
export type Event_Datadog_Monitor_Bool_Exp = {
  _and?: Maybe<Array<Event_Datadog_Monitor_Bool_Exp>>;
  _not?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
  _or?: Maybe<Array<Event_Datadog_Monitor_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  envFromInstallation?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  monitorTags?: Maybe<Jsonb_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_datadog_monitor" */
export enum Event_Datadog_Monitor_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkEfc7f1d89058960c618414f355a = 'PK_efc7f1d89058960c618414f355a'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Datadog_Monitor_Delete_At_Path_Input = {
  monitorTags?: Maybe<Array<Scalars['String']>>;
  properties?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Datadog_Monitor_Delete_Elem_Input = {
  monitorTags?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Datadog_Monitor_Delete_Key_Input = {
  monitorTags?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_datadog_monitor" */
export type Event_Datadog_Monitor_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  envFromInstallation?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  monitorTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Datadog_Monitor_Max_Fields = {
  __typename?: 'event_datadog_monitor_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  envFromInstallation?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Datadog_Monitor_Min_Fields = {
  __typename?: 'event_datadog_monitor_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  envFromInstallation?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_datadog_monitor" */
export type Event_Datadog_Monitor_Mutation_Response = {
  __typename?: 'event_datadog_monitor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Datadog_Monitor>;
};

/** on_conflict condition type for table "event_datadog_monitor" */
export type Event_Datadog_Monitor_On_Conflict = {
  constraint: Event_Datadog_Monitor_Constraint;
  update_columns?: Array<Event_Datadog_Monitor_Update_Column>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};

/** Ordering options when selecting data from "event_datadog_monitor". */
export type Event_Datadog_Monitor_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  envFromInstallation?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  monitorTags?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_datadog_monitor */
export type Event_Datadog_Monitor_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Datadog_Monitor_Prepend_Input = {
  monitorTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_datadog_monitor" */
export enum Event_Datadog_Monitor_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EnvFromInstallation = 'envFromInstallation',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  MonitorTags = 'monitorTags',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_datadog_monitor" */
export type Event_Datadog_Monitor_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  envFromInstallation?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  monitorTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_datadog_monitor" */
export type Event_Datadog_Monitor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Datadog_Monitor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Datadog_Monitor_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  envFromInstallation?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  monitorTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_datadog_monitor" */
export enum Event_Datadog_Monitor_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EnvFromInstallation = 'envFromInstallation',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  MonitorTags = 'monitorTags',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Datadog_Monitor_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Datadog_Monitor_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Datadog_Monitor_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Datadog_Monitor_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Datadog_Monitor_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Datadog_Monitor_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Datadog_Monitor_Set_Input>;
  where: Event_Datadog_Monitor_Bool_Exp;
};

/** columns and relationships of "event_datadog_tracing" */
export type Event_Datadog_Tracing = {
  __typename?: 'event_datadog_tracing';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  datadogEnv: Scalars['String'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  rawEventId?: Maybe<Scalars['String']>;
  serviceDependencies: Scalars['jsonb'];
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_datadog_tracing" */
export type Event_Datadog_TracingServiceDependenciesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_datadog_tracing" */
export type Event_Datadog_TracingServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_datadog_tracing" */
export type Event_Datadog_Tracing_Aggregate = {
  __typename?: 'event_datadog_tracing_aggregate';
  aggregate?: Maybe<Event_Datadog_Tracing_Aggregate_Fields>;
  nodes: Array<Event_Datadog_Tracing>;
};

/** aggregate fields of "event_datadog_tracing" */
export type Event_Datadog_Tracing_Aggregate_Fields = {
  __typename?: 'event_datadog_tracing_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Datadog_Tracing_Max_Fields>;
  min?: Maybe<Event_Datadog_Tracing_Min_Fields>;
};


/** aggregate fields of "event_datadog_tracing" */
export type Event_Datadog_Tracing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Datadog_Tracing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Datadog_Tracing_Append_Input = {
  serviceDependencies?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_datadog_tracing". All fields are combined with a logical 'AND'. */
export type Event_Datadog_Tracing_Bool_Exp = {
  _and?: Maybe<Array<Event_Datadog_Tracing_Bool_Exp>>;
  _not?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
  _or?: Maybe<Array<Event_Datadog_Tracing_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  datadogEnv?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  serviceDependencies?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_datadog_tracing" */
export enum Event_Datadog_Tracing_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB760974e2ed22e28a0ed5530b96 = 'PK_b760974e2ed22e28a0ed5530b96'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Datadog_Tracing_Delete_At_Path_Input = {
  serviceDependencies?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Datadog_Tracing_Delete_Elem_Input = {
  serviceDependencies?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Datadog_Tracing_Delete_Key_Input = {
  serviceDependencies?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_datadog_tracing" */
export type Event_Datadog_Tracing_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceDependencies?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Datadog_Tracing_Max_Fields = {
  __typename?: 'event_datadog_tracing_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Datadog_Tracing_Min_Fields = {
  __typename?: 'event_datadog_tracing_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_datadog_tracing" */
export type Event_Datadog_Tracing_Mutation_Response = {
  __typename?: 'event_datadog_tracing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Datadog_Tracing>;
};

/** on_conflict condition type for table "event_datadog_tracing" */
export type Event_Datadog_Tracing_On_Conflict = {
  constraint: Event_Datadog_Tracing_Constraint;
  update_columns?: Array<Event_Datadog_Tracing_Update_Column>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};

/** Ordering options when selecting data from "event_datadog_tracing". */
export type Event_Datadog_Tracing_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  datadogEnv?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  serviceDependencies?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_datadog_tracing */
export type Event_Datadog_Tracing_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Datadog_Tracing_Prepend_Input = {
  serviceDependencies?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_datadog_tracing" */
export enum Event_Datadog_Tracing_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatadogEnv = 'datadogEnv',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ServiceDependencies = 'serviceDependencies',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_datadog_tracing" */
export type Event_Datadog_Tracing_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceDependencies?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_datadog_tracing" */
export type Event_Datadog_Tracing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Datadog_Tracing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Datadog_Tracing_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceDependencies?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_datadog_tracing" */
export enum Event_Datadog_Tracing_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatadogEnv = 'datadogEnv',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ServiceDependencies = 'serviceDependencies',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Datadog_Tracing_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Datadog_Tracing_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Datadog_Tracing_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Datadog_Tracing_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Datadog_Tracing_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Datadog_Tracing_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Datadog_Tracing_Set_Input>;
  where: Event_Datadog_Tracing_Bool_Exp;
};

/** columns and relationships of "event_deploy" */
export type Event_Deploy = {
  __typename?: 'event_deploy';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  deploymentName: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompare: Scalars['jsonb'];
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isEmptyDeployment: Scalars['Boolean'];
  kubernetesConfigChanges: Scalars['jsonb'];
  kubernetesKind: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  previousDeploymentId: Scalars['String'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  versionFrom: Scalars['String'];
  versionTo: Scalars['String'];
};


/** columns and relationships of "event_deploy" */
export type Event_DeployDeploymentDiffKeysArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployGitCompareArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployKubernetesConfigChangesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployStrategyArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_deploy" */
export type Event_DeployTrackedFilesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_deploy" */
export type Event_Deploy_Aggregate = {
  __typename?: 'event_deploy_aggregate';
  aggregate?: Maybe<Event_Deploy_Aggregate_Fields>;
  nodes: Array<Event_Deploy>;
};

/** aggregate fields of "event_deploy" */
export type Event_Deploy_Aggregate_Fields = {
  __typename?: 'event_deploy_aggregate_fields';
  avg?: Maybe<Event_Deploy_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Deploy_Max_Fields>;
  min?: Maybe<Event_Deploy_Min_Fields>;
  stddev?: Maybe<Event_Deploy_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Deploy_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Deploy_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Deploy_Sum_Fields>;
  var_pop?: Maybe<Event_Deploy_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Deploy_Var_Samp_Fields>;
  variance?: Maybe<Event_Deploy_Variance_Fields>;
};


/** aggregate fields of "event_deploy" */
export type Event_Deploy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Deploy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Deploy_Append_Input = {
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  gitCompare?: Maybe<Scalars['jsonb']>;
  kubernetesConfigChanges?: Maybe<Scalars['jsonb']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Event_Deploy_Avg_Fields = {
  __typename?: 'event_deploy_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "event_deploy". All fields are combined with a logical 'AND'. */
export type Event_Deploy_Bool_Exp = {
  _and?: Maybe<Array<Event_Deploy_Bool_Exp>>;
  _not?: Maybe<Event_Deploy_Bool_Exp>;
  _or?: Maybe<Array<Event_Deploy_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  changeType?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deploymentDiffKeys?: Maybe<Jsonb_Comparison_Exp>;
  deploymentName?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  endEventTime?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  failed_container?: Maybe<String_Comparison_Exp>;
  generation?: Maybe<Int_Comparison_Exp>;
  gitCompare?: Maybe<Jsonb_Comparison_Exp>;
  gitCompareType?: Maybe<String_Comparison_Exp>;
  gitRevision?: Maybe<String_Comparison_Exp>;
  gitStatus?: Maybe<String_Comparison_Exp>;
  gitStatusReason?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  isEmptyDeployment?: Maybe<Boolean_Comparison_Exp>;
  kubernetesConfigChanges?: Maybe<Jsonb_Comparison_Exp>;
  kubernetesKind?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  previousDeploymentId?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  resourceVersion?: Maybe<Bigint_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  statusMessage?: Maybe<String_Comparison_Exp>;
  statusReason?: Maybe<String_Comparison_Exp>;
  strategy?: Maybe<Jsonb_Comparison_Exp>;
  trackedFiles?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  versionFrom?: Maybe<String_Comparison_Exp>;
  versionTo?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_deploy" */
export enum Event_Deploy_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_647c53125c515fc4a38e67c6391 = 'PK_647c53125c515fc4a38e67c6391'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Deploy_Delete_At_Path_Input = {
  deploymentDiffKeys?: Maybe<Array<Scalars['String']>>;
  gitCompare?: Maybe<Array<Scalars['String']>>;
  kubernetesConfigChanges?: Maybe<Array<Scalars['String']>>;
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  strategy?: Maybe<Array<Scalars['String']>>;
  trackedFiles?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Deploy_Delete_Elem_Input = {
  deploymentDiffKeys?: Maybe<Scalars['Int']>;
  gitCompare?: Maybe<Scalars['Int']>;
  kubernetesConfigChanges?: Maybe<Scalars['Int']>;
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['Int']>;
  trackedFiles?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Deploy_Delete_Key_Input = {
  deploymentDiffKeys?: Maybe<Scalars['String']>;
  gitCompare?: Maybe<Scalars['String']>;
  kubernetesConfigChanges?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  trackedFiles?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_deploy" */
export type Event_Deploy_Inc_Input = {
  duration?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_deploy" */
export type Event_Deploy_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  deploymentName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompare?: Maybe<Scalars['jsonb']>;
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEmptyDeployment?: Maybe<Scalars['Boolean']>;
  kubernetesConfigChanges?: Maybe<Scalars['jsonb']>;
  kubernetesKind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  previousDeploymentId?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  versionFrom?: Maybe<Scalars['String']>;
  versionTo?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Deploy_Max_Fields = {
  __typename?: 'event_deploy_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kubernetesKind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  previousDeploymentId?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  versionFrom?: Maybe<Scalars['String']>;
  versionTo?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Deploy_Min_Fields = {
  __typename?: 'event_deploy_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kubernetesKind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  previousDeploymentId?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  versionFrom?: Maybe<Scalars['String']>;
  versionTo?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_deploy" */
export type Event_Deploy_Mutation_Response = {
  __typename?: 'event_deploy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Deploy>;
};

/** on_conflict condition type for table "event_deploy" */
export type Event_Deploy_On_Conflict = {
  constraint: Event_Deploy_Constraint;
  update_columns?: Array<Event_Deploy_Update_Column>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};

/** Ordering options when selecting data from "event_deploy". */
export type Event_Deploy_Order_By = {
  accountId?: Maybe<Order_By>;
  changeType?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deploymentDiffKeys?: Maybe<Order_By>;
  deploymentName?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endEventTime?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  failed_container?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  gitCompare?: Maybe<Order_By>;
  gitCompareType?: Maybe<Order_By>;
  gitRevision?: Maybe<Order_By>;
  gitStatus?: Maybe<Order_By>;
  gitStatusReason?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isEmptyDeployment?: Maybe<Order_By>;
  kubernetesConfigChanges?: Maybe<Order_By>;
  kubernetesKind?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  previousDeploymentId?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  resourceVersion?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusMessage?: Maybe<Order_By>;
  statusReason?: Maybe<Order_By>;
  strategy?: Maybe<Order_By>;
  trackedFiles?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  versionFrom?: Maybe<Order_By>;
  versionTo?: Maybe<Order_By>;
};

/** primary key columns input for table: event_deploy */
export type Event_Deploy_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Deploy_Prepend_Input = {
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  gitCompare?: Maybe<Scalars['jsonb']>;
  kubernetesConfigChanges?: Maybe<Scalars['jsonb']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_deploy" */
export enum Event_Deploy_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ChangeType = 'changeType',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeploymentDiffKeys = 'deploymentDiffKeys',
  /** column name */
  DeploymentName = 'deploymentName',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndEventTime = 'endEventTime',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FailedContainer = 'failed_container',
  /** column name */
  Generation = 'generation',
  /** column name */
  GitCompare = 'gitCompare',
  /** column name */
  GitCompareType = 'gitCompareType',
  /** column name */
  GitRevision = 'gitRevision',
  /** column name */
  GitStatus = 'gitStatus',
  /** column name */
  GitStatusReason = 'gitStatusReason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmptyDeployment = 'isEmptyDeployment',
  /** column name */
  KubernetesConfigChanges = 'kubernetesConfigChanges',
  /** column name */
  KubernetesKind = 'kubernetesKind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  PreviousDeploymentId = 'previousDeploymentId',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  StatusMessage = 'statusMessage',
  /** column name */
  StatusReason = 'statusReason',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  TrackedFiles = 'trackedFiles',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VersionFrom = 'versionFrom',
  /** column name */
  VersionTo = 'versionTo'
}

/** input type for updating data in table "event_deploy" */
export type Event_Deploy_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  deploymentName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompare?: Maybe<Scalars['jsonb']>;
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEmptyDeployment?: Maybe<Scalars['Boolean']>;
  kubernetesConfigChanges?: Maybe<Scalars['jsonb']>;
  kubernetesKind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  previousDeploymentId?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  versionFrom?: Maybe<Scalars['String']>;
  versionTo?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Event_Deploy_Stddev_Fields = {
  __typename?: 'event_deploy_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Deploy_Stddev_Pop_Fields = {
  __typename?: 'event_deploy_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Deploy_Stddev_Samp_Fields = {
  __typename?: 'event_deploy_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event_deploy" */
export type Event_Deploy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Deploy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Deploy_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  changeType?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentDiffKeys?: Maybe<Scalars['jsonb']>;
  deploymentName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endEventTime?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failed_container?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  gitCompare?: Maybe<Scalars['jsonb']>;
  gitCompareType?: Maybe<Scalars['String']>;
  gitRevision?: Maybe<Scalars['String']>;
  gitStatus?: Maybe<Scalars['String']>;
  gitStatusReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEmptyDeployment?: Maybe<Scalars['Boolean']>;
  kubernetesConfigChanges?: Maybe<Scalars['jsonb']>;
  kubernetesKind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  previousDeploymentId?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['jsonb']>;
  trackedFiles?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  versionFrom?: Maybe<Scalars['String']>;
  versionTo?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Event_Deploy_Sum_Fields = {
  __typename?: 'event_deploy_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** update columns of table "event_deploy" */
export enum Event_Deploy_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ChangeType = 'changeType',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeploymentDiffKeys = 'deploymentDiffKeys',
  /** column name */
  DeploymentName = 'deploymentName',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndEventTime = 'endEventTime',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FailedContainer = 'failed_container',
  /** column name */
  Generation = 'generation',
  /** column name */
  GitCompare = 'gitCompare',
  /** column name */
  GitCompareType = 'gitCompareType',
  /** column name */
  GitRevision = 'gitRevision',
  /** column name */
  GitStatus = 'gitStatus',
  /** column name */
  GitStatusReason = 'gitStatusReason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmptyDeployment = 'isEmptyDeployment',
  /** column name */
  KubernetesConfigChanges = 'kubernetesConfigChanges',
  /** column name */
  KubernetesKind = 'kubernetesKind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  PreviousDeploymentId = 'previousDeploymentId',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  StatusMessage = 'statusMessage',
  /** column name */
  StatusReason = 'statusReason',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  TrackedFiles = 'trackedFiles',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VersionFrom = 'versionFrom',
  /** column name */
  VersionTo = 'versionTo'
}

export type Event_Deploy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Deploy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Deploy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Deploy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Deploy_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Event_Deploy_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Deploy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Deploy_Set_Input>;
  where: Event_Deploy_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Deploy_Var_Pop_Fields = {
  __typename?: 'event_deploy_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Deploy_Var_Samp_Fields = {
  __typename?: 'event_deploy_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Deploy_Variance_Fields = {
  __typename?: 'event_deploy_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "event_hpa" */
export type Event_Hpa = {
  __typename?: 'event_hpa';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  namespace: Scalars['String'];
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  newSpec: Scalars['jsonb'];
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_hpa" */
export type Event_HpaNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_hpa" */
export type Event_HpaOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_hpa" */
export type Event_HpaRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_hpa" */
export type Event_HpaServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_hpa" */
export type Event_Hpa_Aggregate = {
  __typename?: 'event_hpa_aggregate';
  aggregate?: Maybe<Event_Hpa_Aggregate_Fields>;
  nodes: Array<Event_Hpa>;
};

/** aggregate fields of "event_hpa" */
export type Event_Hpa_Aggregate_Fields = {
  __typename?: 'event_hpa_aggregate_fields';
  avg?: Maybe<Event_Hpa_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Hpa_Max_Fields>;
  min?: Maybe<Event_Hpa_Min_Fields>;
  stddev?: Maybe<Event_Hpa_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Hpa_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Hpa_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Hpa_Sum_Fields>;
  var_pop?: Maybe<Event_Hpa_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Hpa_Var_Samp_Fields>;
  variance?: Maybe<Event_Hpa_Variance_Fields>;
};


/** aggregate fields of "event_hpa" */
export type Event_Hpa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Hpa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Hpa_Append_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Event_Hpa_Avg_Fields = {
  __typename?: 'event_hpa_avg_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "event_hpa". All fields are combined with a logical 'AND'. */
export type Event_Hpa_Bool_Exp = {
  _and?: Maybe<Array<Event_Hpa_Bool_Exp>>;
  _not?: Maybe<Event_Hpa_Bool_Exp>;
  _or?: Maybe<Array<Event_Hpa_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  lastScaleTime?: Maybe<Timestamptz_Comparison_Exp>;
  maxReplicas?: Maybe<Int_Comparison_Exp>;
  minReplicas?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newDesiredReplicas?: Maybe<Int_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldDesiredReplicas?: Maybe<Int_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_hpa" */
export enum Event_Hpa_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkFd69ed025f8be8e9e7590c50e1c = 'PK_fd69ed025f8be8e9e7590c50e1c'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Hpa_Delete_At_Path_Input = {
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Hpa_Delete_Elem_Input = {
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Hpa_Delete_Key_Input = {
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_hpa" */
export type Event_Hpa_Inc_Input = {
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "event_hpa" */
export type Event_Hpa_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Hpa_Max_Fields = {
  __typename?: 'event_hpa_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Hpa_Min_Fields = {
  __typename?: 'event_hpa_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_hpa" */
export type Event_Hpa_Mutation_Response = {
  __typename?: 'event_hpa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Hpa>;
};

/** on_conflict condition type for table "event_hpa" */
export type Event_Hpa_On_Conflict = {
  constraint: Event_Hpa_Constraint;
  update_columns?: Array<Event_Hpa_Update_Column>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};

/** Ordering options when selecting data from "event_hpa". */
export type Event_Hpa_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastScaleTime?: Maybe<Order_By>;
  maxReplicas?: Maybe<Order_By>;
  minReplicas?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newDesiredReplicas?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldDesiredReplicas?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_hpa */
export type Event_Hpa_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Hpa_Prepend_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_hpa" */
export enum Event_Hpa_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  LastScaleTime = 'lastScaleTime',
  /** column name */
  MaxReplicas = 'maxReplicas',
  /** column name */
  MinReplicas = 'minReplicas',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewDesiredReplicas = 'newDesiredReplicas',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldDesiredReplicas = 'oldDesiredReplicas',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_hpa" */
export type Event_Hpa_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Hpa_Stddev_Fields = {
  __typename?: 'event_hpa_stddev_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Hpa_Stddev_Pop_Fields = {
  __typename?: 'event_hpa_stddev_pop_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Hpa_Stddev_Samp_Fields = {
  __typename?: 'event_hpa_stddev_samp_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event_hpa" */
export type Event_Hpa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Hpa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Hpa_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastScaleTime?: Maybe<Scalars['timestamptz']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Hpa_Sum_Fields = {
  __typename?: 'event_hpa_sum_fields';
  maxReplicas?: Maybe<Scalars['Int']>;
  minReplicas?: Maybe<Scalars['Int']>;
  newDesiredReplicas?: Maybe<Scalars['Int']>;
  oldDesiredReplicas?: Maybe<Scalars['Int']>;
};

/** update columns of table "event_hpa" */
export enum Event_Hpa_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  LastScaleTime = 'lastScaleTime',
  /** column name */
  MaxReplicas = 'maxReplicas',
  /** column name */
  MinReplicas = 'minReplicas',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewDesiredReplicas = 'newDesiredReplicas',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldDesiredReplicas = 'oldDesiredReplicas',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Hpa_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Hpa_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Hpa_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Hpa_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Hpa_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Event_Hpa_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Hpa_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Hpa_Set_Input>;
  where: Event_Hpa_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Hpa_Var_Pop_Fields = {
  __typename?: 'event_hpa_var_pop_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Hpa_Var_Samp_Fields = {
  __typename?: 'event_hpa_var_samp_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Hpa_Variance_Fields = {
  __typename?: 'event_hpa_variance_fields';
  maxReplicas?: Maybe<Scalars['Float']>;
  minReplicas?: Maybe<Scalars['Float']>;
  newDesiredReplicas?: Maybe<Scalars['Float']>;
  oldDesiredReplicas?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "event_ingress" */
export type Event_Ingress = {
  __typename?: 'event_ingress';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  kubernetesServiceResourceNames: Scalars['jsonb'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules: Scalars['jsonb'];
  services?: Maybe<Scalars['jsonb']>;
  summary: Scalars['String'];
  tls?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_ingress" */
export type Event_IngressKubernetesServiceResourceNamesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressRulesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_ingress" */
export type Event_IngressTlsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_ingress" */
export type Event_Ingress_Aggregate = {
  __typename?: 'event_ingress_aggregate';
  aggregate?: Maybe<Event_Ingress_Aggregate_Fields>;
  nodes: Array<Event_Ingress>;
};

/** aggregate fields of "event_ingress" */
export type Event_Ingress_Aggregate_Fields = {
  __typename?: 'event_ingress_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Ingress_Max_Fields>;
  min?: Maybe<Event_Ingress_Min_Fields>;
};


/** aggregate fields of "event_ingress" */
export type Event_Ingress_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Ingress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Ingress_Append_Input = {
  kubernetesServiceResourceNames?: Maybe<Scalars['jsonb']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  tls?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_ingress". All fields are combined with a logical 'AND'. */
export type Event_Ingress_Bool_Exp = {
  _and?: Maybe<Array<Event_Ingress_Bool_Exp>>;
  _not?: Maybe<Event_Ingress_Bool_Exp>;
  _or?: Maybe<Array<Event_Ingress_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  kubernetesServiceResourceNames?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  rules?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  tls?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_ingress" */
export enum Event_Ingress_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_21a8068de69abe30b28e90f50cd = 'PK_21a8068de69abe30b28e90f50cd'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Ingress_Delete_At_Path_Input = {
  kubernetesServiceResourceNames?: Maybe<Array<Scalars['String']>>;
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  rules?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  tls?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Ingress_Delete_Elem_Input = {
  kubernetesServiceResourceNames?: Maybe<Scalars['Int']>;
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  tls?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Ingress_Delete_Key_Input = {
  kubernetesServiceResourceNames?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  tls?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_ingress" */
export type Event_Ingress_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kubernetesServiceResourceNames?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  tls?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Ingress_Max_Fields = {
  __typename?: 'event_ingress_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Ingress_Min_Fields = {
  __typename?: 'event_ingress_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_ingress" */
export type Event_Ingress_Mutation_Response = {
  __typename?: 'event_ingress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Ingress>;
};

/** on_conflict condition type for table "event_ingress" */
export type Event_Ingress_On_Conflict = {
  constraint: Event_Ingress_Constraint;
  update_columns?: Array<Event_Ingress_Update_Column>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};

/** Ordering options when selecting data from "event_ingress". */
export type Event_Ingress_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kubernetesServiceResourceNames?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  rules?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  tls?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_ingress */
export type Event_Ingress_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Ingress_Prepend_Input = {
  kubernetesServiceResourceNames?: Maybe<Scalars['jsonb']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  tls?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_ingress" */
export enum Event_Ingress_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  KubernetesServiceResourceNames = 'kubernetesServiceResourceNames',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Rules = 'rules',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Tls = 'tls',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_ingress" */
export type Event_Ingress_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kubernetesServiceResourceNames?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  tls?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_ingress" */
export type Event_Ingress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Ingress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Ingress_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kubernetesServiceResourceNames?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  tls?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_ingress" */
export enum Event_Ingress_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  KubernetesServiceResourceNames = 'kubernetesServiceResourceNames',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Rules = 'rules',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Tls = 'tls',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Ingress_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Ingress_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Ingress_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Ingress_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Ingress_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Ingress_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Ingress_Set_Input>;
  where: Event_Ingress_Bool_Exp;
};

/** columns and relationships of "event_job" */
export type Event_Job = {
  __typename?: 'event_job';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newObj: Scalars['jsonb'];
  oldObj: Scalars['jsonb'];
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector: Scalars['jsonb'];
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['jsonb'];
  subEvents?: Maybe<Scalars['jsonb']>;
  uid: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_job" */
export type Event_JobNewObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobOldObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobSelectorArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobStatusArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_job" */
export type Event_JobSubEventsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_job" */
export type Event_Job_Aggregate = {
  __typename?: 'event_job_aggregate';
  aggregate?: Maybe<Event_Job_Aggregate_Fields>;
  nodes: Array<Event_Job>;
};

/** aggregate fields of "event_job" */
export type Event_Job_Aggregate_Fields = {
  __typename?: 'event_job_aggregate_fields';
  avg?: Maybe<Event_Job_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Job_Max_Fields>;
  min?: Maybe<Event_Job_Min_Fields>;
  stddev?: Maybe<Event_Job_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Job_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Job_Sum_Fields>;
  var_pop?: Maybe<Event_Job_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Job_Var_Samp_Fields>;
  variance?: Maybe<Event_Job_Variance_Fields>;
};


/** aggregate fields of "event_job" */
export type Event_Job_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Job_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Job_Append_Input = {
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  subEvents?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Event_Job_Avg_Fields = {
  __typename?: 'event_job_avg_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "event_job". All fields are combined with a logical 'AND'. */
export type Event_Job_Bool_Exp = {
  _and?: Maybe<Array<Event_Job_Bool_Exp>>;
  _not?: Maybe<Event_Job_Bool_Exp>;
  _or?: Maybe<Array<Event_Job_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  backoffLimit?: Maybe<Int_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  cronJobUid?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newObj?: Maybe<Jsonb_Comparison_Exp>;
  oldObj?: Maybe<Jsonb_Comparison_Exp>;
  podRestartPolicy?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  selector?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Jsonb_Comparison_Exp>;
  subEvents?: Maybe<Jsonb_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_job" */
export enum Event_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_05038580fea75fe0becc1812be1 = 'PK_05038580fea75fe0becc1812be1'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Job_Delete_At_Path_Input = {
  newObj?: Maybe<Array<Scalars['String']>>;
  oldObj?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  selector?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  subEvents?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Job_Delete_Elem_Input = {
  newObj?: Maybe<Scalars['Int']>;
  oldObj?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  selector?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  subEvents?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Job_Delete_Key_Input = {
  newObj?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subEvents?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_job" */
export type Event_Job_Inc_Input = {
  backoffLimit?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "event_job" */
export type Event_Job_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  subEvents?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Job_Max_Fields = {
  __typename?: 'event_job_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Job_Min_Fields = {
  __typename?: 'event_job_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_job" */
export type Event_Job_Mutation_Response = {
  __typename?: 'event_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Job>;
};

/** on_conflict condition type for table "event_job" */
export type Event_Job_On_Conflict = {
  constraint: Event_Job_Constraint;
  update_columns?: Array<Event_Job_Update_Column>;
  where?: Maybe<Event_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "event_job". */
export type Event_Job_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  backoffLimit?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  cronJobUid?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newObj?: Maybe<Order_By>;
  oldObj?: Maybe<Order_By>;
  podRestartPolicy?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  selector?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subEvents?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_job */
export type Event_Job_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Job_Prepend_Input = {
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  subEvents?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_job" */
export enum Event_Job_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  BackoffLimit = 'backoffLimit',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CronJobUid = 'cronJobUid',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  PodRestartPolicy = 'podRestartPolicy',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Selector = 'selector',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  SubEvents = 'subEvents',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_job" */
export type Event_Job_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  subEvents?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Job_Stddev_Fields = {
  __typename?: 'event_job_stddev_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Job_Stddev_Pop_Fields = {
  __typename?: 'event_job_stddev_pop_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Job_Stddev_Samp_Fields = {
  __typename?: 'event_job_stddev_samp_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event_job" */
export type Event_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Job_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  backoffLimit?: Maybe<Scalars['Int']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cronJobUid?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  podRestartPolicy?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  subEvents?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Job_Sum_Fields = {
  __typename?: 'event_job_sum_fields';
  backoffLimit?: Maybe<Scalars['Int']>;
};

/** update columns of table "event_job" */
export enum Event_Job_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  BackoffLimit = 'backoffLimit',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CronJobUid = 'cronJobUid',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  PodRestartPolicy = 'podRestartPolicy',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Selector = 'selector',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  SubEvents = 'subEvents',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Job_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Job_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Job_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Job_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Job_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Event_Job_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Job_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Job_Set_Input>;
  where: Event_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Job_Var_Pop_Fields = {
  __typename?: 'event_job_var_pop_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Job_Var_Samp_Fields = {
  __typename?: 'event_job_var_samp_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Job_Variance_Fields = {
  __typename?: 'event_job_variance_fields';
  backoffLimit?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "event_komodor_alert" */
export type Event_Komodor_Alert = {
  __typename?: 'event_komodor_alert';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId: Scalars['String'];
  alertPriority: Scalars['String'];
  alertSeverity: Scalars['String'];
  alertSource: Scalars['String'];
  alertTags: Scalars['jsonb'];
  alertType: Scalars['String'];
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  properties: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "event_komodor_alert" */
export type Event_Komodor_AlertAlertTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_komodor_alert" */
export type Event_Komodor_AlertAssigneesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_komodor_alert" */
export type Event_Komodor_AlertPropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_komodor_alert" */
export type Event_Komodor_AlertServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_komodor_alert" */
export type Event_Komodor_Alert_Aggregate = {
  __typename?: 'event_komodor_alert_aggregate';
  aggregate?: Maybe<Event_Komodor_Alert_Aggregate_Fields>;
  nodes: Array<Event_Komodor_Alert>;
};

/** aggregate fields of "event_komodor_alert" */
export type Event_Komodor_Alert_Aggregate_Fields = {
  __typename?: 'event_komodor_alert_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Komodor_Alert_Max_Fields>;
  min?: Maybe<Event_Komodor_Alert_Min_Fields>;
};


/** aggregate fields of "event_komodor_alert" */
export type Event_Komodor_Alert_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Komodor_Alert_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Komodor_Alert_Append_Input = {
  alertTags?: Maybe<Scalars['jsonb']>;
  assignees?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_komodor_alert". All fields are combined with a logical 'AND'. */
export type Event_Komodor_Alert_Bool_Exp = {
  _and?: Maybe<Array<Event_Komodor_Alert_Bool_Exp>>;
  _not?: Maybe<Event_Komodor_Alert_Bool_Exp>;
  _or?: Maybe<Array<Event_Komodor_Alert_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  actor?: Maybe<String_Comparison_Exp>;
  alertCycleId?: Maybe<String_Comparison_Exp>;
  alertPriority?: Maybe<String_Comparison_Exp>;
  alertSeverity?: Maybe<String_Comparison_Exp>;
  alertSource?: Maybe<String_Comparison_Exp>;
  alertTags?: Maybe<Jsonb_Comparison_Exp>;
  alertType?: Maybe<String_Comparison_Exp>;
  assignees?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_komodor_alert" */
export enum Event_Komodor_Alert_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA4e743f0b59807d6f6e9c7540ee = 'PK_a4e743f0b59807d6f6e9c7540ee'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Komodor_Alert_Delete_At_Path_Input = {
  alertTags?: Maybe<Array<Scalars['String']>>;
  assignees?: Maybe<Array<Scalars['String']>>;
  properties?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Komodor_Alert_Delete_Elem_Input = {
  alertTags?: Maybe<Scalars['Int']>;
  assignees?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Komodor_Alert_Delete_Key_Input = {
  alertTags?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_komodor_alert" */
export type Event_Komodor_Alert_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId?: Maybe<Scalars['String']>;
  alertPriority?: Maybe<Scalars['String']>;
  alertSeverity?: Maybe<Scalars['String']>;
  alertSource?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  alertType?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Komodor_Alert_Max_Fields = {
  __typename?: 'event_komodor_alert_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId?: Maybe<Scalars['String']>;
  alertPriority?: Maybe<Scalars['String']>;
  alertSeverity?: Maybe<Scalars['String']>;
  alertSource?: Maybe<Scalars['String']>;
  alertType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Komodor_Alert_Min_Fields = {
  __typename?: 'event_komodor_alert_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId?: Maybe<Scalars['String']>;
  alertPriority?: Maybe<Scalars['String']>;
  alertSeverity?: Maybe<Scalars['String']>;
  alertSource?: Maybe<Scalars['String']>;
  alertType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_komodor_alert" */
export type Event_Komodor_Alert_Mutation_Response = {
  __typename?: 'event_komodor_alert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Komodor_Alert>;
};

/** on_conflict condition type for table "event_komodor_alert" */
export type Event_Komodor_Alert_On_Conflict = {
  constraint: Event_Komodor_Alert_Constraint;
  update_columns?: Array<Event_Komodor_Alert_Update_Column>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};

/** Ordering options when selecting data from "event_komodor_alert". */
export type Event_Komodor_Alert_Order_By = {
  accountId?: Maybe<Order_By>;
  actor?: Maybe<Order_By>;
  alertCycleId?: Maybe<Order_By>;
  alertPriority?: Maybe<Order_By>;
  alertSeverity?: Maybe<Order_By>;
  alertSource?: Maybe<Order_By>;
  alertTags?: Maybe<Order_By>;
  alertType?: Maybe<Order_By>;
  assignees?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: event_komodor_alert */
export type Event_Komodor_Alert_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Komodor_Alert_Prepend_Input = {
  alertTags?: Maybe<Scalars['jsonb']>;
  assignees?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_komodor_alert" */
export enum Event_Komodor_Alert_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  AlertCycleId = 'alertCycleId',
  /** column name */
  AlertPriority = 'alertPriority',
  /** column name */
  AlertSeverity = 'alertSeverity',
  /** column name */
  AlertSource = 'alertSource',
  /** column name */
  AlertTags = 'alertTags',
  /** column name */
  AlertType = 'alertType',
  /** column name */
  Assignees = 'assignees',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "event_komodor_alert" */
export type Event_Komodor_Alert_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId?: Maybe<Scalars['String']>;
  alertPriority?: Maybe<Scalars['String']>;
  alertSeverity?: Maybe<Scalars['String']>;
  alertSource?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  alertType?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_komodor_alert" */
export type Event_Komodor_Alert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Komodor_Alert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Komodor_Alert_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertCycleId?: Maybe<Scalars['String']>;
  alertPriority?: Maybe<Scalars['String']>;
  alertSeverity?: Maybe<Scalars['String']>;
  alertSource?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  alertType?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "event_komodor_alert" */
export enum Event_Komodor_Alert_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  AlertCycleId = 'alertCycleId',
  /** column name */
  AlertPriority = 'alertPriority',
  /** column name */
  AlertSeverity = 'alertSeverity',
  /** column name */
  AlertSource = 'alertSource',
  /** column name */
  AlertTags = 'alertTags',
  /** column name */
  AlertType = 'alertType',
  /** column name */
  Assignees = 'assignees',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type Event_Komodor_Alert_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Komodor_Alert_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Komodor_Alert_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Komodor_Alert_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Komodor_Alert_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Komodor_Alert_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Komodor_Alert_Set_Input>;
  where: Event_Komodor_Alert_Bool_Exp;
};

/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource = {
  __typename?: 'event_kubernetes_service_resource';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newData: Scalars['jsonb'];
  oldData: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector: Scalars['jsonb'];
  services?: Maybe<Scalars['jsonb']>;
  summary: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_ResourceNewDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_ResourceOldDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_ResourceRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_ResourceSelectorArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_ResourceServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Aggregate = {
  __typename?: 'event_kubernetes_service_resource_aggregate';
  aggregate?: Maybe<Event_Kubernetes_Service_Resource_Aggregate_Fields>;
  nodes: Array<Event_Kubernetes_Service_Resource>;
};

/** aggregate fields of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Aggregate_Fields = {
  __typename?: 'event_kubernetes_service_resource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Kubernetes_Service_Resource_Max_Fields>;
  min?: Maybe<Event_Kubernetes_Service_Resource_Min_Fields>;
};


/** aggregate fields of "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Kubernetes_Service_Resource_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Kubernetes_Service_Resource_Append_Input = {
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_kubernetes_service_resource". All fields are combined with a logical 'AND'. */
export type Event_Kubernetes_Service_Resource_Bool_Exp = {
  _and?: Maybe<Array<Event_Kubernetes_Service_Resource_Bool_Exp>>;
  _not?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
  _or?: Maybe<Array<Event_Kubernetes_Service_Resource_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newData?: Maybe<Jsonb_Comparison_Exp>;
  oldData?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  selector?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_kubernetes_service_resource" */
export enum Event_Kubernetes_Service_Resource_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_34494622c86c8d331e605647f0c = 'PK_34494622c86c8d331e605647f0c'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Kubernetes_Service_Resource_Delete_At_Path_Input = {
  newData?: Maybe<Array<Scalars['String']>>;
  oldData?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  selector?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Kubernetes_Service_Resource_Delete_Elem_Input = {
  newData?: Maybe<Scalars['Int']>;
  oldData?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  selector?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Kubernetes_Service_Resource_Delete_Key_Input = {
  newData?: Maybe<Scalars['String']>;
  oldData?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Kubernetes_Service_Resource_Max_Fields = {
  __typename?: 'event_kubernetes_service_resource_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Kubernetes_Service_Resource_Min_Fields = {
  __typename?: 'event_kubernetes_service_resource_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Mutation_Response = {
  __typename?: 'event_kubernetes_service_resource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Kubernetes_Service_Resource>;
};

/** on_conflict condition type for table "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_On_Conflict = {
  constraint: Event_Kubernetes_Service_Resource_Constraint;
  update_columns?: Array<Event_Kubernetes_Service_Resource_Update_Column>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};

/** Ordering options when selecting data from "event_kubernetes_service_resource". */
export type Event_Kubernetes_Service_Resource_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newData?: Maybe<Order_By>;
  oldData?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  selector?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_kubernetes_service_resource */
export type Event_Kubernetes_Service_Resource_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Kubernetes_Service_Resource_Prepend_Input = {
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_kubernetes_service_resource" */
export enum Event_Kubernetes_Service_Resource_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Selector = 'selector',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_kubernetes_service_resource" */
export type Event_Kubernetes_Service_Resource_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Kubernetes_Service_Resource_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Kubernetes_Service_Resource_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_kubernetes_service_resource" */
export enum Event_Kubernetes_Service_Resource_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Selector = 'selector',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Kubernetes_Service_Resource_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Kubernetes_Service_Resource_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Kubernetes_Service_Resource_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Kubernetes_Service_Resource_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Kubernetes_Service_Resource_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Kubernetes_Service_Resource_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Kubernetes_Service_Resource_Set_Input>;
  where: Event_Kubernetes_Service_Resource_Bool_Exp;
};

/** columns and relationships of "event_launchdarkly" */
export type Event_Launchdarkly = {
  __typename?: 'event_launchdarkly';
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  currentVersion: Scalars['jsonb'];
  description?: Maybe<Scalars['String']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previousVersion: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  target: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_launchdarkly" */
export type Event_LaunchdarklyCurrentVersionArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_launchdarkly" */
export type Event_LaunchdarklyPreviousVersionArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_launchdarkly" */
export type Event_LaunchdarklyServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_launchdarkly" */
export type Event_LaunchdarklyTargetArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_launchdarkly" */
export type Event_Launchdarkly_Aggregate = {
  __typename?: 'event_launchdarkly_aggregate';
  aggregate?: Maybe<Event_Launchdarkly_Aggregate_Fields>;
  nodes: Array<Event_Launchdarkly>;
};

/** aggregate fields of "event_launchdarkly" */
export type Event_Launchdarkly_Aggregate_Fields = {
  __typename?: 'event_launchdarkly_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Launchdarkly_Max_Fields>;
  min?: Maybe<Event_Launchdarkly_Min_Fields>;
};


/** aggregate fields of "event_launchdarkly" */
export type Event_Launchdarkly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Launchdarkly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Launchdarkly_Append_Input = {
  currentVersion?: Maybe<Scalars['jsonb']>;
  previousVersion?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  target?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_launchdarkly". All fields are combined with a logical 'AND'. */
export type Event_Launchdarkly_Bool_Exp = {
  _and?: Maybe<Array<Event_Launchdarkly_Bool_Exp>>;
  _not?: Maybe<Event_Launchdarkly_Bool_Exp>;
  _or?: Maybe<Array<Event_Launchdarkly_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  currentVersion?: Maybe<Jsonb_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  member?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  previousVersion?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  target?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_launchdarkly" */
export enum Event_Launchdarkly_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_91eeb309aebd7d6e621b07db55a = 'PK_91eeb309aebd7d6e621b07db55a'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Launchdarkly_Delete_At_Path_Input = {
  currentVersion?: Maybe<Array<Scalars['String']>>;
  previousVersion?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  target?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Launchdarkly_Delete_Elem_Input = {
  currentVersion?: Maybe<Scalars['Int']>;
  previousVersion?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Launchdarkly_Delete_Key_Input = {
  currentVersion?: Maybe<Scalars['String']>;
  previousVersion?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_launchdarkly" */
export type Event_Launchdarkly_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentVersion?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previousVersion?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Launchdarkly_Max_Fields = {
  __typename?: 'event_launchdarkly_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Launchdarkly_Min_Fields = {
  __typename?: 'event_launchdarkly_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_launchdarkly" */
export type Event_Launchdarkly_Mutation_Response = {
  __typename?: 'event_launchdarkly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Launchdarkly>;
};

/** on_conflict condition type for table "event_launchdarkly" */
export type Event_Launchdarkly_On_Conflict = {
  constraint: Event_Launchdarkly_Constraint;
  update_columns?: Array<Event_Launchdarkly_Update_Column>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};

/** Ordering options when selecting data from "event_launchdarkly". */
export type Event_Launchdarkly_Order_By = {
  accountId?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  currentVersion?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  member?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  previousVersion?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  target?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_launchdarkly */
export type Event_Launchdarkly_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Launchdarkly_Prepend_Input = {
  currentVersion?: Maybe<Scalars['jsonb']>;
  previousVersion?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  target?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_launchdarkly" */
export enum Event_Launchdarkly_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentVersion = 'currentVersion',
  /** column name */
  Description = 'description',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Member = 'member',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousVersion = 'previousVersion',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_launchdarkly" */
export type Event_Launchdarkly_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentVersion?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previousVersion?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_launchdarkly" */
export type Event_Launchdarkly_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Launchdarkly_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Launchdarkly_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentVersion?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previousVersion?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_launchdarkly" */
export enum Event_Launchdarkly_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentVersion = 'currentVersion',
  /** column name */
  Description = 'description',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Member = 'member',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousVersion = 'previousVersion',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Launchdarkly_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Launchdarkly_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Launchdarkly_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Launchdarkly_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Launchdarkly_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Launchdarkly_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Launchdarkly_Set_Input>;
  where: Event_Launchdarkly_Bool_Exp;
};

/** columns and relationships of "event_limit_ranges" */
export type Event_Limit_Ranges = {
  __typename?: 'event_limit_ranges';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_limit_ranges" */
export type Event_Limit_RangesNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_limit_ranges" */
export type Event_Limit_RangesOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_limit_ranges" */
export type Event_Limit_RangesRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_limit_ranges" */
export type Event_Limit_RangesServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_limit_ranges" */
export type Event_Limit_Ranges_Aggregate = {
  __typename?: 'event_limit_ranges_aggregate';
  aggregate?: Maybe<Event_Limit_Ranges_Aggregate_Fields>;
  nodes: Array<Event_Limit_Ranges>;
};

/** aggregate fields of "event_limit_ranges" */
export type Event_Limit_Ranges_Aggregate_Fields = {
  __typename?: 'event_limit_ranges_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Limit_Ranges_Max_Fields>;
  min?: Maybe<Event_Limit_Ranges_Min_Fields>;
};


/** aggregate fields of "event_limit_ranges" */
export type Event_Limit_Ranges_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Limit_Ranges_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Limit_Ranges_Append_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_limit_ranges". All fields are combined with a logical 'AND'. */
export type Event_Limit_Ranges_Bool_Exp = {
  _and?: Maybe<Array<Event_Limit_Ranges_Bool_Exp>>;
  _not?: Maybe<Event_Limit_Ranges_Bool_Exp>;
  _or?: Maybe<Array<Event_Limit_Ranges_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_limit_ranges" */
export enum Event_Limit_Ranges_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_9432c530fa136f961d5cb972f9b = 'PK_9432c530fa136f961d5cb972f9b'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Limit_Ranges_Delete_At_Path_Input = {
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Limit_Ranges_Delete_Elem_Input = {
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Limit_Ranges_Delete_Key_Input = {
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_limit_ranges" */
export type Event_Limit_Ranges_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Limit_Ranges_Max_Fields = {
  __typename?: 'event_limit_ranges_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Limit_Ranges_Min_Fields = {
  __typename?: 'event_limit_ranges_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_limit_ranges" */
export type Event_Limit_Ranges_Mutation_Response = {
  __typename?: 'event_limit_ranges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Limit_Ranges>;
};

/** on_conflict condition type for table "event_limit_ranges" */
export type Event_Limit_Ranges_On_Conflict = {
  constraint: Event_Limit_Ranges_Constraint;
  update_columns?: Array<Event_Limit_Ranges_Update_Column>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};

/** Ordering options when selecting data from "event_limit_ranges". */
export type Event_Limit_Ranges_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_limit_ranges */
export type Event_Limit_Ranges_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Limit_Ranges_Prepend_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_limit_ranges" */
export enum Event_Limit_Ranges_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_limit_ranges" */
export type Event_Limit_Ranges_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_limit_ranges" */
export type Event_Limit_Ranges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Limit_Ranges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Limit_Ranges_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_limit_ranges" */
export enum Event_Limit_Ranges_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Limit_Ranges_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Limit_Ranges_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Limit_Ranges_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Limit_Ranges_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Limit_Ranges_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Limit_Ranges_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Limit_Ranges_Set_Input>;
  where: Event_Limit_Ranges_Bool_Exp;
};

/** columns and relationships of "event_monitor" */
export type Event_Monitor = {
  __typename?: 'event_monitor';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  involvedObject?: Maybe<Scalars['jsonb']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_monitor" */
export type Event_MonitorInvolvedObjectArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_monitor" */
export type Event_MonitorMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_monitor" */
export type Event_MonitorServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_monitor" */
export type Event_Monitor_Aggregate = {
  __typename?: 'event_monitor_aggregate';
  aggregate?: Maybe<Event_Monitor_Aggregate_Fields>;
  nodes: Array<Event_Monitor>;
};

/** aggregate fields of "event_monitor" */
export type Event_Monitor_Aggregate_Fields = {
  __typename?: 'event_monitor_aggregate_fields';
  avg?: Maybe<Event_Monitor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Monitor_Max_Fields>;
  min?: Maybe<Event_Monitor_Min_Fields>;
  stddev?: Maybe<Event_Monitor_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Monitor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Monitor_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Monitor_Sum_Fields>;
  var_pop?: Maybe<Event_Monitor_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Monitor_Var_Samp_Fields>;
  variance?: Maybe<Event_Monitor_Variance_Fields>;
};


/** aggregate fields of "event_monitor" */
export type Event_Monitor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Monitor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Monitor_Append_Input = {
  involvedObject?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Event_Monitor_Avg_Fields = {
  __typename?: 'event_monitor_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "event_monitor". All fields are combined with a logical 'AND'. */
export type Event_Monitor_Bool_Exp = {
  _and?: Maybe<Array<Event_Monitor_Bool_Exp>>;
  _not?: Maybe<Event_Monitor_Bool_Exp>;
  _or?: Maybe<Array<Event_Monitor_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  count?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  firstTimeStamp?: Maybe<Timestamptz_Comparison_Exp>;
  groupId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  involvedObject?: Maybe<Jsonb_Comparison_Exp>;
  issueId?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  lastTimeStamp?: Maybe<Timestamptz_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  objectName?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_monitor" */
export enum Event_Monitor_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_56ff44177d7ec64fb78b7965b72 = 'PK_56ff44177d7ec64fb78b7965b72'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Monitor_Delete_At_Path_Input = {
  involvedObject?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Monitor_Delete_Elem_Input = {
  involvedObject?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Monitor_Delete_Key_Input = {
  involvedObject?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_monitor" */
export type Event_Monitor_Inc_Input = {
  count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "event_monitor" */
export type Event_Monitor_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  involvedObject?: Maybe<Scalars['jsonb']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Monitor_Max_Fields = {
  __typename?: 'event_monitor_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Monitor_Min_Fields = {
  __typename?: 'event_monitor_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_monitor" */
export type Event_Monitor_Mutation_Response = {
  __typename?: 'event_monitor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Monitor>;
};

/** on_conflict condition type for table "event_monitor" */
export type Event_Monitor_On_Conflict = {
  constraint: Event_Monitor_Constraint;
  update_columns?: Array<Event_Monitor_Update_Column>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};

/** Ordering options when selecting data from "event_monitor". */
export type Event_Monitor_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  firstTimeStamp?: Maybe<Order_By>;
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  involvedObject?: Maybe<Order_By>;
  issueId?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  lastTimeStamp?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  objectName?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_monitor */
export type Event_Monitor_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Monitor_Prepend_Input = {
  involvedObject?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_monitor" */
export enum Event_Monitor_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FirstTimeStamp = 'firstTimeStamp',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  InvolvedObject = 'involvedObject',
  /** column name */
  IssueId = 'issueId',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastTimeStamp = 'lastTimeStamp',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ObjectName = 'objectName',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Reason = 'reason',
  /** column name */
  Services = 'services',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_monitor" */
export type Event_Monitor_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  involvedObject?: Maybe<Scalars['jsonb']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Monitor_Stddev_Fields = {
  __typename?: 'event_monitor_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Monitor_Stddev_Pop_Fields = {
  __typename?: 'event_monitor_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Monitor_Stddev_Samp_Fields = {
  __typename?: 'event_monitor_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event_monitor" */
export type Event_Monitor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Monitor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Monitor_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  firstTimeStamp?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  involvedObject?: Maybe<Scalars['jsonb']>;
  issueId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastTimeStamp?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  namespace?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Monitor_Sum_Fields = {
  __typename?: 'event_monitor_sum_fields';
  count?: Maybe<Scalars['Int']>;
};

/** update columns of table "event_monitor" */
export enum Event_Monitor_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FirstTimeStamp = 'firstTimeStamp',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  InvolvedObject = 'involvedObject',
  /** column name */
  IssueId = 'issueId',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastTimeStamp = 'lastTimeStamp',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ObjectName = 'objectName',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Reason = 'reason',
  /** column name */
  Services = 'services',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Monitor_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Monitor_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Monitor_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Monitor_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Monitor_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Event_Monitor_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Monitor_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Monitor_Set_Input>;
  where: Event_Monitor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Monitor_Var_Pop_Fields = {
  __typename?: 'event_monitor_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Monitor_Var_Samp_Fields = {
  __typename?: 'event_monitor_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Monitor_Variance_Fields = {
  __typename?: 'event_monitor_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "event_network_policies" */
export type Event_Network_Policies = {
  __typename?: 'event_network_policies';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_network_policies" */
export type Event_Network_PoliciesNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_network_policies" */
export type Event_Network_PoliciesOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_network_policies" */
export type Event_Network_PoliciesRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_network_policies" */
export type Event_Network_PoliciesServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_network_policies" */
export type Event_Network_Policies_Aggregate = {
  __typename?: 'event_network_policies_aggregate';
  aggregate?: Maybe<Event_Network_Policies_Aggregate_Fields>;
  nodes: Array<Event_Network_Policies>;
};

/** aggregate fields of "event_network_policies" */
export type Event_Network_Policies_Aggregate_Fields = {
  __typename?: 'event_network_policies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Network_Policies_Max_Fields>;
  min?: Maybe<Event_Network_Policies_Min_Fields>;
};


/** aggregate fields of "event_network_policies" */
export type Event_Network_Policies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Network_Policies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Network_Policies_Append_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_network_policies". All fields are combined with a logical 'AND'. */
export type Event_Network_Policies_Bool_Exp = {
  _and?: Maybe<Array<Event_Network_Policies_Bool_Exp>>;
  _not?: Maybe<Event_Network_Policies_Bool_Exp>;
  _or?: Maybe<Array<Event_Network_Policies_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_network_policies" */
export enum Event_Network_Policies_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_57358ff6961d6c8035157206361 = 'PK_57358ff6961d6c8035157206361'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Network_Policies_Delete_At_Path_Input = {
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Network_Policies_Delete_Elem_Input = {
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Network_Policies_Delete_Key_Input = {
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_network_policies" */
export type Event_Network_Policies_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Network_Policies_Max_Fields = {
  __typename?: 'event_network_policies_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Network_Policies_Min_Fields = {
  __typename?: 'event_network_policies_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_network_policies" */
export type Event_Network_Policies_Mutation_Response = {
  __typename?: 'event_network_policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Network_Policies>;
};

/** on_conflict condition type for table "event_network_policies" */
export type Event_Network_Policies_On_Conflict = {
  constraint: Event_Network_Policies_Constraint;
  update_columns?: Array<Event_Network_Policies_Update_Column>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "event_network_policies". */
export type Event_Network_Policies_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_network_policies */
export type Event_Network_Policies_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Network_Policies_Prepend_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_network_policies" */
export enum Event_Network_Policies_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_network_policies" */
export type Event_Network_Policies_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_network_policies" */
export type Event_Network_Policies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Network_Policies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Network_Policies_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_network_policies" */
export enum Event_Network_Policies_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Network_Policies_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Network_Policies_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Network_Policies_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Network_Policies_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Network_Policies_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Network_Policies_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Network_Policies_Set_Input>;
  where: Event_Network_Policies_Bool_Exp;
};

/** columns and relationships of "event_node" */
export type Event_Node = {
  __typename?: 'event_node';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocatable?: Maybe<Scalars['jsonb']>;
  allocationType?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['jsonb']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  diskPressure: Scalars['String'];
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  instanceType?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure: Scalars['String'];
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  nodeName: Scalars['String'];
  nodeUid?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  ready: Scalars['String'];
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  zone?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeAllocatableArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeCapacityArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeNewObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeNodeInfoArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeOldObjArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_node" */
export type Event_NodeTaintsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_node" */
export type Event_Node_Aggregate = {
  __typename?: 'event_node_aggregate';
  aggregate?: Maybe<Event_Node_Aggregate_Fields>;
  nodes: Array<Event_Node>;
};

/** aggregate fields of "event_node" */
export type Event_Node_Aggregate_Fields = {
  __typename?: 'event_node_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Node_Max_Fields>;
  min?: Maybe<Event_Node_Min_Fields>;
};


/** aggregate fields of "event_node" */
export type Event_Node_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Node_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Node_Append_Input = {
  allocatable?: Maybe<Scalars['jsonb']>;
  capacity?: Maybe<Scalars['jsonb']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_node". All fields are combined with a logical 'AND'. */
export type Event_Node_Bool_Exp = {
  _and?: Maybe<Array<Event_Node_Bool_Exp>>;
  _not?: Maybe<Event_Node_Bool_Exp>;
  _or?: Maybe<Array<Event_Node_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  allocatable?: Maybe<Jsonb_Comparison_Exp>;
  allocationType?: Maybe<String_Comparison_Exp>;
  capacity?: Maybe<Jsonb_Comparison_Exp>;
  cloudProvider?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  diskPressure?: Maybe<String_Comparison_Exp>;
  diskPressureMessage?: Maybe<String_Comparison_Exp>;
  diskPressureReason?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  instanceType?: Maybe<String_Comparison_Exp>;
  isDeleted?: Maybe<Boolean_Comparison_Exp>;
  lastHeartbeatTime?: Maybe<Timestamptz_Comparison_Exp>;
  memoryPressure?: Maybe<String_Comparison_Exp>;
  memoryPressureMessage?: Maybe<String_Comparison_Exp>;
  memoryPressureReason?: Maybe<String_Comparison_Exp>;
  newObj?: Maybe<Jsonb_Comparison_Exp>;
  nodeCreationTime?: Maybe<Timestamptz_Comparison_Exp>;
  nodeInfo?: Maybe<Jsonb_Comparison_Exp>;
  nodeName?: Maybe<String_Comparison_Exp>;
  nodeUid?: Maybe<String_Comparison_Exp>;
  oldObj?: Maybe<Jsonb_Comparison_Exp>;
  os?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  ready?: Maybe<String_Comparison_Exp>;
  readyMessage?: Maybe<String_Comparison_Exp>;
  readyReason?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  taints?: Maybe<Jsonb_Comparison_Exp>;
  terminationReason?: Maybe<String_Comparison_Exp>;
  unschedulable?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_node" */
export enum Event_Node_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_89030eeec07f57f8d3ee9bcc557 = 'PK_89030eeec07f57f8d3ee9bcc557'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Node_Delete_At_Path_Input = {
  allocatable?: Maybe<Array<Scalars['String']>>;
  capacity?: Maybe<Array<Scalars['String']>>;
  newObj?: Maybe<Array<Scalars['String']>>;
  nodeInfo?: Maybe<Array<Scalars['String']>>;
  oldObj?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  taints?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Node_Delete_Elem_Input = {
  allocatable?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  newObj?: Maybe<Scalars['Int']>;
  nodeInfo?: Maybe<Scalars['Int']>;
  oldObj?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  taints?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Node_Delete_Key_Input = {
  allocatable?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['String']>;
  nodeInfo?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  taints?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_node" */
export type Event_Node_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocatable?: Maybe<Scalars['jsonb']>;
  allocationType?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['jsonb']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  diskPressure?: Maybe<Scalars['String']>;
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure?: Maybe<Scalars['String']>;
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  nodeName?: Maybe<Scalars['String']>;
  nodeUid?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  ready?: Maybe<Scalars['String']>;
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Node_Max_Fields = {
  __typename?: 'event_node_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocationType?: Maybe<Scalars['String']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  diskPressure?: Maybe<Scalars['String']>;
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure?: Maybe<Scalars['String']>;
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeName?: Maybe<Scalars['String']>;
  nodeUid?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  ready?: Maybe<Scalars['String']>;
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Node_Min_Fields = {
  __typename?: 'event_node_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocationType?: Maybe<Scalars['String']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  diskPressure?: Maybe<Scalars['String']>;
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure?: Maybe<Scalars['String']>;
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeName?: Maybe<Scalars['String']>;
  nodeUid?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  ready?: Maybe<Scalars['String']>;
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_node" */
export type Event_Node_Mutation_Response = {
  __typename?: 'event_node_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Node>;
};

/** on_conflict condition type for table "event_node" */
export type Event_Node_On_Conflict = {
  constraint: Event_Node_Constraint;
  update_columns?: Array<Event_Node_Update_Column>;
  where?: Maybe<Event_Node_Bool_Exp>;
};

/** Ordering options when selecting data from "event_node". */
export type Event_Node_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  allocatable?: Maybe<Order_By>;
  allocationType?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  cloudProvider?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  diskPressure?: Maybe<Order_By>;
  diskPressureMessage?: Maybe<Order_By>;
  diskPressureReason?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instanceType?: Maybe<Order_By>;
  isDeleted?: Maybe<Order_By>;
  lastHeartbeatTime?: Maybe<Order_By>;
  memoryPressure?: Maybe<Order_By>;
  memoryPressureMessage?: Maybe<Order_By>;
  memoryPressureReason?: Maybe<Order_By>;
  newObj?: Maybe<Order_By>;
  nodeCreationTime?: Maybe<Order_By>;
  nodeInfo?: Maybe<Order_By>;
  nodeName?: Maybe<Order_By>;
  nodeUid?: Maybe<Order_By>;
  oldObj?: Maybe<Order_By>;
  os?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  ready?: Maybe<Order_By>;
  readyMessage?: Maybe<Order_By>;
  readyReason?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  taints?: Maybe<Order_By>;
  terminationReason?: Maybe<Order_By>;
  unschedulable?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zone?: Maybe<Order_By>;
};

/** primary key columns input for table: event_node */
export type Event_Node_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Node_Prepend_Input = {
  allocatable?: Maybe<Scalars['jsonb']>;
  capacity?: Maybe<Scalars['jsonb']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_node" */
export enum Event_Node_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  Allocatable = 'allocatable',
  /** column name */
  AllocationType = 'allocationType',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CloudProvider = 'cloudProvider',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiskPressure = 'diskPressure',
  /** column name */
  DiskPressureMessage = 'diskPressureMessage',
  /** column name */
  DiskPressureReason = 'diskPressureReason',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceType = 'instanceType',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastHeartbeatTime = 'lastHeartbeatTime',
  /** column name */
  MemoryPressure = 'memoryPressure',
  /** column name */
  MemoryPressureMessage = 'memoryPressureMessage',
  /** column name */
  MemoryPressureReason = 'memoryPressureReason',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  NodeCreationTime = 'nodeCreationTime',
  /** column name */
  NodeInfo = 'nodeInfo',
  /** column name */
  NodeName = 'nodeName',
  /** column name */
  NodeUid = 'nodeUid',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  Os = 'os',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Ready = 'ready',
  /** column name */
  ReadyMessage = 'readyMessage',
  /** column name */
  ReadyReason = 'readyReason',
  /** column name */
  Region = 'region',
  /** column name */
  Services = 'services',
  /** column name */
  Taints = 'taints',
  /** column name */
  TerminationReason = 'terminationReason',
  /** column name */
  Unschedulable = 'unschedulable',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Zone = 'zone'
}

/** input type for updating data in table "event_node" */
export type Event_Node_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocatable?: Maybe<Scalars['jsonb']>;
  allocationType?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['jsonb']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  diskPressure?: Maybe<Scalars['String']>;
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure?: Maybe<Scalars['String']>;
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  nodeName?: Maybe<Scalars['String']>;
  nodeUid?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  ready?: Maybe<Scalars['String']>;
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_node" */
export type Event_Node_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Node_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Node_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  allocatable?: Maybe<Scalars['jsonb']>;
  allocationType?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['jsonb']>;
  cloudProvider?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  diskPressure?: Maybe<Scalars['String']>;
  diskPressureMessage?: Maybe<Scalars['String']>;
  diskPressureReason?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastHeartbeatTime?: Maybe<Scalars['timestamptz']>;
  memoryPressure?: Maybe<Scalars['String']>;
  memoryPressureMessage?: Maybe<Scalars['String']>;
  memoryPressureReason?: Maybe<Scalars['String']>;
  newObj?: Maybe<Scalars['jsonb']>;
  nodeCreationTime?: Maybe<Scalars['timestamptz']>;
  nodeInfo?: Maybe<Scalars['jsonb']>;
  nodeName?: Maybe<Scalars['String']>;
  nodeUid?: Maybe<Scalars['String']>;
  oldObj?: Maybe<Scalars['jsonb']>;
  os?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  ready?: Maybe<Scalars['String']>;
  readyMessage?: Maybe<Scalars['String']>;
  readyReason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  taints?: Maybe<Scalars['jsonb']>;
  terminationReason?: Maybe<Scalars['String']>;
  unschedulable?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** update columns of table "event_node" */
export enum Event_Node_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  Allocatable = 'allocatable',
  /** column name */
  AllocationType = 'allocationType',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CloudProvider = 'cloudProvider',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiskPressure = 'diskPressure',
  /** column name */
  DiskPressureMessage = 'diskPressureMessage',
  /** column name */
  DiskPressureReason = 'diskPressureReason',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceType = 'instanceType',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastHeartbeatTime = 'lastHeartbeatTime',
  /** column name */
  MemoryPressure = 'memoryPressure',
  /** column name */
  MemoryPressureMessage = 'memoryPressureMessage',
  /** column name */
  MemoryPressureReason = 'memoryPressureReason',
  /** column name */
  NewObj = 'newObj',
  /** column name */
  NodeCreationTime = 'nodeCreationTime',
  /** column name */
  NodeInfo = 'nodeInfo',
  /** column name */
  NodeName = 'nodeName',
  /** column name */
  NodeUid = 'nodeUid',
  /** column name */
  OldObj = 'oldObj',
  /** column name */
  Os = 'os',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Ready = 'ready',
  /** column name */
  ReadyMessage = 'readyMessage',
  /** column name */
  ReadyReason = 'readyReason',
  /** column name */
  Region = 'region',
  /** column name */
  Services = 'services',
  /** column name */
  Taints = 'taints',
  /** column name */
  TerminationReason = 'terminationReason',
  /** column name */
  Unschedulable = 'unschedulable',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Zone = 'zone'
}

export type Event_Node_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Node_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Node_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Node_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Node_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Node_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Node_Set_Input>;
  where: Event_Node_Bool_Exp;
};

/** columns and relationships of "event_opsgenie" */
export type Event_Opsgenie = {
  __typename?: 'event_opsgenie';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertTags: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  eventType: Scalars['String'];
  id: Scalars['String'];
  incidentUrl: Scalars['String'];
  properties: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  summary: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_opsgenie" */
export type Event_OpsgenieAlertTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_opsgenie" */
export type Event_OpsgeniePropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_opsgenie" */
export type Event_OpsgenieServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_opsgenie" */
export type Event_Opsgenie_Aggregate = {
  __typename?: 'event_opsgenie_aggregate';
  aggregate?: Maybe<Event_Opsgenie_Aggregate_Fields>;
  nodes: Array<Event_Opsgenie>;
};

/** aggregate fields of "event_opsgenie" */
export type Event_Opsgenie_Aggregate_Fields = {
  __typename?: 'event_opsgenie_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Opsgenie_Max_Fields>;
  min?: Maybe<Event_Opsgenie_Min_Fields>;
};


/** aggregate fields of "event_opsgenie" */
export type Event_Opsgenie_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Opsgenie_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Opsgenie_Append_Input = {
  alertTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_opsgenie". All fields are combined with a logical 'AND'. */
export type Event_Opsgenie_Bool_Exp = {
  _and?: Maybe<Array<Event_Opsgenie_Bool_Exp>>;
  _not?: Maybe<Event_Opsgenie_Bool_Exp>;
  _or?: Maybe<Array<Event_Opsgenie_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  actor?: Maybe<String_Comparison_Exp>;
  alertTags?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  eventType?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  incidentUrl?: Maybe<String_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  serviceName?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_opsgenie" */
export enum Event_Opsgenie_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_19534d7b043873a5a6d6cb7831f = 'PK_19534d7b043873a5a6d6cb7831f'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Opsgenie_Delete_At_Path_Input = {
  alertTags?: Maybe<Array<Scalars['String']>>;
  properties?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Opsgenie_Delete_Elem_Input = {
  alertTags?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Opsgenie_Delete_Key_Input = {
  alertTags?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_opsgenie" */
export type Event_Opsgenie_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Opsgenie_Max_Fields = {
  __typename?: 'event_opsgenie_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Opsgenie_Min_Fields = {
  __typename?: 'event_opsgenie_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_opsgenie" */
export type Event_Opsgenie_Mutation_Response = {
  __typename?: 'event_opsgenie_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Opsgenie>;
};

/** on_conflict condition type for table "event_opsgenie" */
export type Event_Opsgenie_On_Conflict = {
  constraint: Event_Opsgenie_Constraint;
  update_columns?: Array<Event_Opsgenie_Update_Column>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};

/** Ordering options when selecting data from "event_opsgenie". */
export type Event_Opsgenie_Order_By = {
  accountId?: Maybe<Order_By>;
  actor?: Maybe<Order_By>;
  alertTags?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incidentUrl?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  serviceName?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_opsgenie */
export type Event_Opsgenie_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Opsgenie_Prepend_Input = {
  alertTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_opsgenie" */
export enum Event_Opsgenie_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  AlertTags = 'alertTags',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentUrl = 'incidentUrl',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ServiceName = 'serviceName',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_opsgenie" */
export type Event_Opsgenie_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_opsgenie" */
export type Event_Opsgenie_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Opsgenie_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Opsgenie_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  alertTags?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_opsgenie" */
export enum Event_Opsgenie_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  AlertTags = 'alertTags',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentUrl = 'incidentUrl',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ServiceName = 'serviceName',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Opsgenie_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Opsgenie_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Opsgenie_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Opsgenie_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Opsgenie_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Opsgenie_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Opsgenie_Set_Input>;
  where: Event_Opsgenie_Bool_Exp;
};

/** columns and relationships of "event_pagerduty" */
export type Event_Pagerduty = {
  __typename?: 'event_pagerduty';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  eventType: Scalars['String'];
  id: Scalars['String'];
  incidentKey: Scalars['String'];
  incidentUrl: Scalars['String'];
  pdServiceName: Scalars['String'];
  pdTags: Scalars['jsonb'];
  properties: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  summary: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_pagerduty" */
export type Event_PagerdutyAssigneesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pagerduty" */
export type Event_PagerdutyPdTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pagerduty" */
export type Event_PagerdutyPropertiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pagerduty" */
export type Event_PagerdutyServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_pagerduty" */
export type Event_Pagerduty_Aggregate = {
  __typename?: 'event_pagerduty_aggregate';
  aggregate?: Maybe<Event_Pagerduty_Aggregate_Fields>;
  nodes: Array<Event_Pagerduty>;
};

/** aggregate fields of "event_pagerduty" */
export type Event_Pagerduty_Aggregate_Fields = {
  __typename?: 'event_pagerduty_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Pagerduty_Max_Fields>;
  min?: Maybe<Event_Pagerduty_Min_Fields>;
};


/** aggregate fields of "event_pagerduty" */
export type Event_Pagerduty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Pagerduty_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Pagerduty_Append_Input = {
  assignees?: Maybe<Scalars['jsonb']>;
  pdTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_pagerduty". All fields are combined with a logical 'AND'. */
export type Event_Pagerduty_Bool_Exp = {
  _and?: Maybe<Array<Event_Pagerduty_Bool_Exp>>;
  _not?: Maybe<Event_Pagerduty_Bool_Exp>;
  _or?: Maybe<Array<Event_Pagerduty_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  actor?: Maybe<String_Comparison_Exp>;
  assignees?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  eventType?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  incidentKey?: Maybe<String_Comparison_Exp>;
  incidentUrl?: Maybe<String_Comparison_Exp>;
  pdServiceName?: Maybe<String_Comparison_Exp>;
  pdTags?: Maybe<Jsonb_Comparison_Exp>;
  properties?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_pagerduty" */
export enum Event_Pagerduty_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB1b560c3478d0c05df0ad4db644 = 'PK_b1b560c3478d0c05df0ad4db644'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Pagerduty_Delete_At_Path_Input = {
  assignees?: Maybe<Array<Scalars['String']>>;
  pdTags?: Maybe<Array<Scalars['String']>>;
  properties?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Pagerduty_Delete_Elem_Input = {
  assignees?: Maybe<Scalars['Int']>;
  pdTags?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Pagerduty_Delete_Key_Input = {
  assignees?: Maybe<Scalars['String']>;
  pdTags?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_pagerduty" */
export type Event_Pagerduty_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentKey?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  pdServiceName?: Maybe<Scalars['String']>;
  pdTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Pagerduty_Max_Fields = {
  __typename?: 'event_pagerduty_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentKey?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  pdServiceName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Pagerduty_Min_Fields = {
  __typename?: 'event_pagerduty_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentKey?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  pdServiceName?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_pagerduty" */
export type Event_Pagerduty_Mutation_Response = {
  __typename?: 'event_pagerduty_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Pagerduty>;
};

/** on_conflict condition type for table "event_pagerduty" */
export type Event_Pagerduty_On_Conflict = {
  constraint: Event_Pagerduty_Constraint;
  update_columns?: Array<Event_Pagerduty_Update_Column>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};

/** Ordering options when selecting data from "event_pagerduty". */
export type Event_Pagerduty_Order_By = {
  accountId?: Maybe<Order_By>;
  actor?: Maybe<Order_By>;
  assignees?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incidentKey?: Maybe<Order_By>;
  incidentUrl?: Maybe<Order_By>;
  pdServiceName?: Maybe<Order_By>;
  pdTags?: Maybe<Order_By>;
  properties?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_pagerduty */
export type Event_Pagerduty_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Pagerduty_Prepend_Input = {
  assignees?: Maybe<Scalars['jsonb']>;
  pdTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_pagerduty" */
export enum Event_Pagerduty_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  Assignees = 'assignees',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentKey = 'incidentKey',
  /** column name */
  IncidentUrl = 'incidentUrl',
  /** column name */
  PdServiceName = 'pdServiceName',
  /** column name */
  PdTags = 'pdTags',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_pagerduty" */
export type Event_Pagerduty_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentKey?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  pdServiceName?: Maybe<Scalars['String']>;
  pdTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_pagerduty" */
export type Event_Pagerduty_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Pagerduty_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Pagerduty_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  actor?: Maybe<Scalars['String']>;
  assignees?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incidentKey?: Maybe<Scalars['String']>;
  incidentUrl?: Maybe<Scalars['String']>;
  pdServiceName?: Maybe<Scalars['String']>;
  pdTags?: Maybe<Scalars['jsonb']>;
  properties?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_pagerduty" */
export enum Event_Pagerduty_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Actor = 'actor',
  /** column name */
  Assignees = 'assignees',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentKey = 'incidentKey',
  /** column name */
  IncidentUrl = 'incidentUrl',
  /** column name */
  PdServiceName = 'pdServiceName',
  /** column name */
  PdTags = 'pdTags',
  /** column name */
  Properties = 'properties',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Pagerduty_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Pagerduty_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Pagerduty_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Pagerduty_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Pagerduty_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Pagerduty_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Pagerduty_Set_Input>;
  where: Event_Pagerduty_Bool_Exp;
};

/** columns and relationships of "event_pdb" */
export type Event_Pdb = {
  __typename?: 'event_pdb';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_pdb" */
export type Event_PdbNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pdb" */
export type Event_PdbOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pdb" */
export type Event_PdbRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_pdb" */
export type Event_PdbServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_pdb" */
export type Event_Pdb_Aggregate = {
  __typename?: 'event_pdb_aggregate';
  aggregate?: Maybe<Event_Pdb_Aggregate_Fields>;
  nodes: Array<Event_Pdb>;
};

/** aggregate fields of "event_pdb" */
export type Event_Pdb_Aggregate_Fields = {
  __typename?: 'event_pdb_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Pdb_Max_Fields>;
  min?: Maybe<Event_Pdb_Min_Fields>;
};


/** aggregate fields of "event_pdb" */
export type Event_Pdb_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Pdb_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Pdb_Append_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_pdb". All fields are combined with a logical 'AND'. */
export type Event_Pdb_Bool_Exp = {
  _and?: Maybe<Array<Event_Pdb_Bool_Exp>>;
  _not?: Maybe<Event_Pdb_Bool_Exp>;
  _or?: Maybe<Array<Event_Pdb_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_pdb" */
export enum Event_Pdb_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_24f2685e33a3e16c0ec99987296 = 'PK_24f2685e33a3e16c0ec99987296'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Pdb_Delete_At_Path_Input = {
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Pdb_Delete_Elem_Input = {
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Pdb_Delete_Key_Input = {
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_pdb" */
export type Event_Pdb_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Pdb_Max_Fields = {
  __typename?: 'event_pdb_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Pdb_Min_Fields = {
  __typename?: 'event_pdb_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_pdb" */
export type Event_Pdb_Mutation_Response = {
  __typename?: 'event_pdb_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Pdb>;
};

/** on_conflict condition type for table "event_pdb" */
export type Event_Pdb_On_Conflict = {
  constraint: Event_Pdb_Constraint;
  update_columns?: Array<Event_Pdb_Update_Column>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};

/** Ordering options when selecting data from "event_pdb". */
export type Event_Pdb_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_pdb */
export type Event_Pdb_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Pdb_Prepend_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_pdb" */
export enum Event_Pdb_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_pdb" */
export type Event_Pdb_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_pdb" */
export type Event_Pdb_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Pdb_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Pdb_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_pdb" */
export enum Event_Pdb_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Pdb_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Pdb_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Pdb_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Pdb_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Pdb_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Pdb_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Pdb_Set_Input>;
  where: Event_Pdb_Bool_Exp;
};

/** columns and relationships of "event_resource_quota" */
export type Event_Resource_Quota = {
  __typename?: 'event_resource_quota';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  newSpec: Scalars['jsonb'];
  oldSpec: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_resource_quota" */
export type Event_Resource_QuotaNewSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_resource_quota" */
export type Event_Resource_QuotaOldSpecArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_resource_quota" */
export type Event_Resource_QuotaRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_resource_quota" */
export type Event_Resource_QuotaServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_resource_quota" */
export type Event_Resource_Quota_Aggregate = {
  __typename?: 'event_resource_quota_aggregate';
  aggregate?: Maybe<Event_Resource_Quota_Aggregate_Fields>;
  nodes: Array<Event_Resource_Quota>;
};

/** aggregate fields of "event_resource_quota" */
export type Event_Resource_Quota_Aggregate_Fields = {
  __typename?: 'event_resource_quota_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Resource_Quota_Max_Fields>;
  min?: Maybe<Event_Resource_Quota_Min_Fields>;
};


/** aggregate fields of "event_resource_quota" */
export type Event_Resource_Quota_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Resource_Quota_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Resource_Quota_Append_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_resource_quota". All fields are combined with a logical 'AND'. */
export type Event_Resource_Quota_Bool_Exp = {
  _and?: Maybe<Array<Event_Resource_Quota_Bool_Exp>>;
  _not?: Maybe<Event_Resource_Quota_Bool_Exp>;
  _or?: Maybe<Array<Event_Resource_Quota_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newSpec?: Maybe<Jsonb_Comparison_Exp>;
  oldSpec?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_resource_quota" */
export enum Event_Resource_Quota_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_154a4836b51d532644605b4c915 = 'PK_154a4836b51d532644605b4c915'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Resource_Quota_Delete_At_Path_Input = {
  newSpec?: Maybe<Array<Scalars['String']>>;
  oldSpec?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Resource_Quota_Delete_Elem_Input = {
  newSpec?: Maybe<Scalars['Int']>;
  oldSpec?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Resource_Quota_Delete_Key_Input = {
  newSpec?: Maybe<Scalars['String']>;
  oldSpec?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_resource_quota" */
export type Event_Resource_Quota_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Resource_Quota_Max_Fields = {
  __typename?: 'event_resource_quota_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Resource_Quota_Min_Fields = {
  __typename?: 'event_resource_quota_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_resource_quota" */
export type Event_Resource_Quota_Mutation_Response = {
  __typename?: 'event_resource_quota_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Resource_Quota>;
};

/** on_conflict condition type for table "event_resource_quota" */
export type Event_Resource_Quota_On_Conflict = {
  constraint: Event_Resource_Quota_Constraint;
  update_columns?: Array<Event_Resource_Quota_Update_Column>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};

/** Ordering options when selecting data from "event_resource_quota". */
export type Event_Resource_Quota_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newSpec?: Maybe<Order_By>;
  oldSpec?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_resource_quota */
export type Event_Resource_Quota_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Resource_Quota_Prepend_Input = {
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_resource_quota" */
export enum Event_Resource_Quota_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_resource_quota" */
export type Event_Resource_Quota_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_resource_quota" */
export type Event_Resource_Quota_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Resource_Quota_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Resource_Quota_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  newSpec?: Maybe<Scalars['jsonb']>;
  oldSpec?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_resource_quota" */
export enum Event_Resource_Quota_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewSpec = 'newSpec',
  /** column name */
  OldSpec = 'oldSpec',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Resource_Quota_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Resource_Quota_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Resource_Quota_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Resource_Quota_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Resource_Quota_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Resource_Quota_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Resource_Quota_Set_Input>;
  where: Event_Resource_Quota_Bool_Exp;
};

/** columns and relationships of "event_secret" */
export type Event_Secret = {
  __typename?: 'event_secret';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  agentId?: Maybe<Scalars['uuid']>;
  clusterName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  k8sDefault: Scalars['Boolean'];
  namespace: Scalars['String'];
  newData: Scalars['jsonb'];
  oldData: Scalars['jsonb'];
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  secretName: Scalars['String'];
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_secret" */
export type Event_SecretHelmDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_secret" */
export type Event_SecretHelmSummaryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_secret" */
export type Event_SecretNewDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_secret" */
export type Event_SecretOldDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_secret" */
export type Event_SecretRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_secret" */
export type Event_SecretServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_secret" */
export type Event_Secret_Aggregate = {
  __typename?: 'event_secret_aggregate';
  aggregate?: Maybe<Event_Secret_Aggregate_Fields>;
  nodes: Array<Event_Secret>;
};

/** aggregate fields of "event_secret" */
export type Event_Secret_Aggregate_Fields = {
  __typename?: 'event_secret_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Secret_Max_Fields>;
  min?: Maybe<Event_Secret_Min_Fields>;
};


/** aggregate fields of "event_secret" */
export type Event_Secret_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Secret_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Secret_Append_Input = {
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_secret". All fields are combined with a logical 'AND'. */
export type Event_Secret_Bool_Exp = {
  _and?: Maybe<Array<Event_Secret_Bool_Exp>>;
  _not?: Maybe<Event_Secret_Bool_Exp>;
  _or?: Maybe<Array<Event_Secret_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  agentId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  helmData?: Maybe<Jsonb_Comparison_Exp>;
  helmSummary?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  k8sDefault?: Maybe<Boolean_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  newData?: Maybe<Jsonb_Comparison_Exp>;
  oldData?: Maybe<Jsonb_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  secretName?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_secret" */
export enum Event_Secret_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF6b6cefa54bf6940d72877d3979 = 'PK_f6b6cefa54bf6940d72877d3979'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Secret_Delete_At_Path_Input = {
  helmData?: Maybe<Array<Scalars['String']>>;
  helmSummary?: Maybe<Array<Scalars['String']>>;
  newData?: Maybe<Array<Scalars['String']>>;
  oldData?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Secret_Delete_Elem_Input = {
  helmData?: Maybe<Scalars['Int']>;
  helmSummary?: Maybe<Scalars['Int']>;
  newData?: Maybe<Scalars['Int']>;
  oldData?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Secret_Delete_Key_Input = {
  helmData?: Maybe<Scalars['String']>;
  helmSummary?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['String']>;
  oldData?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_secret" */
export type Event_Secret_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  secretName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Secret_Max_Fields = {
  __typename?: 'event_secret_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  secretName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Secret_Min_Fields = {
  __typename?: 'event_secret_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  secretName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_secret" */
export type Event_Secret_Mutation_Response = {
  __typename?: 'event_secret_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Secret>;
};

/** on_conflict condition type for table "event_secret" */
export type Event_Secret_On_Conflict = {
  constraint: Event_Secret_Constraint;
  update_columns?: Array<Event_Secret_Update_Column>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};

/** Ordering options when selecting data from "event_secret". */
export type Event_Secret_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  helmData?: Maybe<Order_By>;
  helmSummary?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  k8sDefault?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  newData?: Maybe<Order_By>;
  oldData?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  secretName?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_secret */
export type Event_Secret_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Secret_Prepend_Input = {
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_secret" */
export enum Event_Secret_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  HelmData = 'helmData',
  /** column name */
  HelmSummary = 'helmSummary',
  /** column name */
  Id = 'id',
  /** column name */
  K8sDefault = 'k8sDefault',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  SecretName = 'secretName',
  /** column name */
  Services = 'services',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_secret" */
export type Event_Secret_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  secretName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_secret" */
export type Event_Secret_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Secret_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Secret_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  helmData?: Maybe<Scalars['jsonb']>;
  helmSummary?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  k8sDefault?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['jsonb']>;
  oldData?: Maybe<Scalars['jsonb']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  secretName?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_secret" */
export enum Event_Secret_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  HelmData = 'helmData',
  /** column name */
  HelmSummary = 'helmSummary',
  /** column name */
  Id = 'id',
  /** column name */
  K8sDefault = 'k8sDefault',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  NewData = 'newData',
  /** column name */
  OldData = 'oldData',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  SecretName = 'secretName',
  /** column name */
  Services = 'services',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Secret_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Secret_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Secret_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Secret_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Secret_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Secret_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Secret_Set_Input>;
  where: Event_Secret_Bool_Exp;
};

/** columns and relationships of "event_sentry_issue" */
export type Event_Sentry_Issue = {
  __typename?: 'event_sentry_issue';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  actor?: Maybe<Scalars['String']>;
  additionalData: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  kind: Scalars['String'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_sentry_issue" */
export type Event_Sentry_IssueAdditionalDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_sentry_issue" */
export type Event_Sentry_IssueServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_sentry_issue" */
export type Event_Sentry_Issue_Aggregate = {
  __typename?: 'event_sentry_issue_aggregate';
  aggregate?: Maybe<Event_Sentry_Issue_Aggregate_Fields>;
  nodes: Array<Event_Sentry_Issue>;
};

/** aggregate fields of "event_sentry_issue" */
export type Event_Sentry_Issue_Aggregate_Fields = {
  __typename?: 'event_sentry_issue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Sentry_Issue_Max_Fields>;
  min?: Maybe<Event_Sentry_Issue_Min_Fields>;
};


/** aggregate fields of "event_sentry_issue" */
export type Event_Sentry_Issue_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Sentry_Issue_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Sentry_Issue_Append_Input = {
  additionalData?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_sentry_issue". All fields are combined with a logical 'AND'. */
export type Event_Sentry_Issue_Bool_Exp = {
  _and?: Maybe<Array<Event_Sentry_Issue_Bool_Exp>>;
  _not?: Maybe<Event_Sentry_Issue_Bool_Exp>;
  _or?: Maybe<Array<Event_Sentry_Issue_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  actor?: Maybe<String_Comparison_Exp>;
  additionalData?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_sentry_issue" */
export enum Event_Sentry_Issue_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF3d1d4b08ceb51de3e2a1a20c20 = 'PK_f3d1d4b08ceb51de3e2a1a20c20'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Sentry_Issue_Delete_At_Path_Input = {
  additionalData?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Sentry_Issue_Delete_Elem_Input = {
  additionalData?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Sentry_Issue_Delete_Key_Input = {
  additionalData?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_sentry_issue" */
export type Event_Sentry_Issue_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<Scalars['String']>;
  additionalData?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Sentry_Issue_Max_Fields = {
  __typename?: 'event_sentry_issue_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Sentry_Issue_Min_Fields = {
  __typename?: 'event_sentry_issue_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_sentry_issue" */
export type Event_Sentry_Issue_Mutation_Response = {
  __typename?: 'event_sentry_issue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Sentry_Issue>;
};

/** on_conflict condition type for table "event_sentry_issue" */
export type Event_Sentry_Issue_On_Conflict = {
  constraint: Event_Sentry_Issue_Constraint;
  update_columns?: Array<Event_Sentry_Issue_Update_Column>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};

/** Ordering options when selecting data from "event_sentry_issue". */
export type Event_Sentry_Issue_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  actor?: Maybe<Order_By>;
  additionalData?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_sentry_issue */
export type Event_Sentry_Issue_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Sentry_Issue_Prepend_Input = {
  additionalData?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_sentry_issue" */
export enum Event_Sentry_Issue_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  Actor = 'actor',
  /** column name */
  AdditionalData = 'additionalData',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_sentry_issue" */
export type Event_Sentry_Issue_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<Scalars['String']>;
  additionalData?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_sentry_issue" */
export type Event_Sentry_Issue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Sentry_Issue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Sentry_Issue_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  actor?: Maybe<Scalars['String']>;
  additionalData?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_sentry_issue" */
export enum Event_Sentry_Issue_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  Actor = 'actor',
  /** column name */
  AdditionalData = 'additionalData',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Sentry_Issue_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Sentry_Issue_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Sentry_Issue_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Sentry_Issue_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Sentry_Issue_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Sentry_Issue_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Sentry_Issue_Set_Input>;
  where: Event_Sentry_Issue_Bool_Exp;
};

/** columns and relationships of "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent = {
  __typename?: 'event_stale_kubernetes_agent';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
  kind: Scalars['String'];
  lastSeenAlive: Scalars['timestamp'];
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_AgentServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Aggregate = {
  __typename?: 'event_stale_kubernetes_agent_aggregate';
  aggregate?: Maybe<Event_Stale_Kubernetes_Agent_Aggregate_Fields>;
  nodes: Array<Event_Stale_Kubernetes_Agent>;
};

/** aggregate fields of "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Aggregate_Fields = {
  __typename?: 'event_stale_kubernetes_agent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Stale_Kubernetes_Agent_Max_Fields>;
  min?: Maybe<Event_Stale_Kubernetes_Agent_Min_Fields>;
};


/** aggregate fields of "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Stale_Kubernetes_Agent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Stale_Kubernetes_Agent_Append_Input = {
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_stale_kubernetes_agent". All fields are combined with a logical 'AND'. */
export type Event_Stale_Kubernetes_Agent_Bool_Exp = {
  _and?: Maybe<Array<Event_Stale_Kubernetes_Agent_Bool_Exp>>;
  _not?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
  _or?: Maybe<Array<Event_Stale_Kubernetes_Agent_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  inactive?: Maybe<Boolean_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  lastSeenAlive?: Maybe<Timestamp_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_stale_kubernetes_agent" */
export enum Event_Stale_Kubernetes_Agent_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_5d375f73c9f49ba22bf3a3b91bc = 'PK_5d375f73c9f49ba22bf3a3b91bc'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Stale_Kubernetes_Agent_Delete_At_Path_Input = {
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Stale_Kubernetes_Agent_Delete_Elem_Input = {
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Stale_Kubernetes_Agent_Delete_Key_Input = {
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Stale_Kubernetes_Agent_Max_Fields = {
  __typename?: 'event_stale_kubernetes_agent_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Stale_Kubernetes_Agent_Min_Fields = {
  __typename?: 'event_stale_kubernetes_agent_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rawEventId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Mutation_Response = {
  __typename?: 'event_stale_kubernetes_agent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Stale_Kubernetes_Agent>;
};

/** on_conflict condition type for table "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_On_Conflict = {
  constraint: Event_Stale_Kubernetes_Agent_Constraint;
  update_columns?: Array<Event_Stale_Kubernetes_Agent_Update_Column>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "event_stale_kubernetes_agent". */
export type Event_Stale_Kubernetes_Agent_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inactive?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  lastSeenAlive?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_stale_kubernetes_agent */
export type Event_Stale_Kubernetes_Agent_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Stale_Kubernetes_Agent_Prepend_Input = {
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_stale_kubernetes_agent" */
export enum Event_Stale_Kubernetes_Agent_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastSeenAlive = 'lastSeenAlive',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_stale_kubernetes_agent" */
export type Event_Stale_Kubernetes_Agent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Stale_Kubernetes_Agent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Stale_Kubernetes_Agent_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rawEventId?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_stale_kubernetes_agent" */
export enum Event_Stale_Kubernetes_Agent_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastSeenAlive = 'lastSeenAlive',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  Services = 'services',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Stale_Kubernetes_Agent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Stale_Kubernetes_Agent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Stale_Kubernetes_Agent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Stale_Kubernetes_Agent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Stale_Kubernetes_Agent_Set_Input>;
  where: Event_Stale_Kubernetes_Agent_Bool_Exp;
};

/** columns and relationships of "event_topology" */
export type Event_Topology = {
  __typename?: 'event_topology';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  details?: Maybe<Scalars['String']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  kind: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_topology" */
export type Event_TopologyRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_topology" */
export type Event_TopologyServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_topology" */
export type Event_Topology_Aggregate = {
  __typename?: 'event_topology_aggregate';
  aggregate?: Maybe<Event_Topology_Aggregate_Fields>;
  nodes: Array<Event_Topology>;
};

/** aggregate fields of "event_topology" */
export type Event_Topology_Aggregate_Fields = {
  __typename?: 'event_topology_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Topology_Max_Fields>;
  min?: Maybe<Event_Topology_Min_Fields>;
};


/** aggregate fields of "event_topology" */
export type Event_Topology_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Topology_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Topology_Append_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_topology". All fields are combined with a logical 'AND'. */
export type Event_Topology_Bool_Exp = {
  _and?: Maybe<Array<Event_Topology_Bool_Exp>>;
  _not?: Maybe<Event_Topology_Bool_Exp>;
  _or?: Maybe<Array<Event_Topology_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  details?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_topology" */
export enum Event_Topology_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_97bab4a2f9d2704d2c47928723b = 'PK_97bab4a2f9d2704d2c47928723b'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Topology_Delete_At_Path_Input = {
  rbacResources?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Topology_Delete_Elem_Input = {
  rbacResources?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Topology_Delete_Key_Input = {
  rbacResources?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_topology" */
export type Event_Topology_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Topology_Max_Fields = {
  __typename?: 'event_topology_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Topology_Min_Fields = {
  __typename?: 'event_topology_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_topology" */
export type Event_Topology_Mutation_Response = {
  __typename?: 'event_topology_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Topology>;
};

/** on_conflict condition type for table "event_topology" */
export type Event_Topology_On_Conflict = {
  constraint: Event_Topology_Constraint;
  update_columns?: Array<Event_Topology_Update_Column>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};

/** Ordering options when selecting data from "event_topology". */
export type Event_Topology_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_topology */
export type Event_Topology_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Topology_Prepend_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_topology" */
export enum Event_Topology_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_topology" */
export type Event_Topology_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "event_topology" */
export type Event_Topology_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Topology_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Topology_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_topology" */
export enum Event_Topology_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Topology_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Topology_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Topology_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Topology_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Topology_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Topology_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Topology_Set_Input>;
  where: Event_Topology_Bool_Exp;
};

/** columns and relationships of "event_workload" */
export type Event_Workload = {
  __typename?: 'event_workload';
  accountId?: Maybe<Scalars['uuid']>;
  action: Scalars['String'];
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas: Scalars['Int'];
  eventTime: Scalars['timestamptz'];
  generation?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  kind: Scalars['String'];
  namespace: Scalars['String'];
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas: Scalars['Int'];
  resourceName: Scalars['String'];
  resourceVersion: Scalars['bigint'];
  services?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "event_workload" */
export type Event_WorkloadServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_workload" */
export type Event_Workload_Aggregate = {
  __typename?: 'event_workload_aggregate';
  aggregate?: Maybe<Event_Workload_Aggregate_Fields>;
  nodes: Array<Event_Workload>;
};

/** aggregate fields of "event_workload" */
export type Event_Workload_Aggregate_Fields = {
  __typename?: 'event_workload_aggregate_fields';
  avg?: Maybe<Event_Workload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Workload_Max_Fields>;
  min?: Maybe<Event_Workload_Min_Fields>;
  stddev?: Maybe<Event_Workload_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Workload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Workload_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Workload_Sum_Fields>;
  var_pop?: Maybe<Event_Workload_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Workload_Var_Samp_Fields>;
  variance?: Maybe<Event_Workload_Variance_Fields>;
};


/** aggregate fields of "event_workload" */
export type Event_Workload_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Workload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Workload_Append_Input = {
  services?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Event_Workload_Avg_Fields = {
  __typename?: 'event_workload_avg_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "event_workload". All fields are combined with a logical 'AND'. */
export type Event_Workload_Bool_Exp = {
  _and?: Maybe<Array<Event_Workload_Bool_Exp>>;
  _not?: Maybe<Event_Workload_Bool_Exp>;
  _or?: Maybe<Array<Event_Workload_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  action?: Maybe<String_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creationTimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  deployId?: Maybe<String_Comparison_Exp>;
  desiredReplicas?: Maybe<Int_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  generation?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rawEventId?: Maybe<String_Comparison_Exp>;
  readyReplicas?: Maybe<Int_Comparison_Exp>;
  resourceName?: Maybe<String_Comparison_Exp>;
  resourceVersion?: Maybe<Bigint_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_workload" */
export enum Event_Workload_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_139f3243f07568d1f2755b34e7b = 'PK_139f3243f07568d1f2755b34e7b'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Workload_Delete_At_Path_Input = {
  services?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Workload_Delete_Elem_Input = {
  services?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Workload_Delete_Key_Input = {
  services?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_workload" */
export type Event_Workload_Inc_Input = {
  desiredReplicas?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['Int']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_workload" */
export type Event_Workload_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Workload_Max_Fields = {
  __typename?: 'event_workload_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Workload_Min_Fields = {
  __typename?: 'event_workload_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_workload" */
export type Event_Workload_Mutation_Response = {
  __typename?: 'event_workload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Workload>;
};

/** on_conflict condition type for table "event_workload" */
export type Event_Workload_On_Conflict = {
  constraint: Event_Workload_Constraint;
  update_columns?: Array<Event_Workload_Update_Column>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};

/** Ordering options when selecting data from "event_workload". */
export type Event_Workload_Order_By = {
  accountId?: Maybe<Order_By>;
  action?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  creationTimestamp?: Maybe<Order_By>;
  deployId?: Maybe<Order_By>;
  desiredReplicas?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rawEventId?: Maybe<Order_By>;
  readyReplicas?: Maybe<Order_By>;
  resourceName?: Maybe<Order_By>;
  resourceVersion?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: event_workload */
export type Event_Workload_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Workload_Prepend_Input = {
  services?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_workload" */
export enum Event_Workload_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreationTimestamp = 'creationTimestamp',
  /** column name */
  DeployId = 'deployId',
  /** column name */
  DesiredReplicas = 'desiredReplicas',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ReadyReplicas = 'readyReplicas',
  /** column name */
  ResourceName = 'resourceName',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Services = 'services',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event_workload" */
export type Event_Workload_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Workload_Stddev_Fields = {
  __typename?: 'event_workload_stddev_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Workload_Stddev_Pop_Fields = {
  __typename?: 'event_workload_stddev_pop_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Workload_Stddev_Samp_Fields = {
  __typename?: 'event_workload_stddev_samp_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event_workload" */
export type Event_Workload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Workload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Workload_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  action?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creationTimestamp?: Maybe<Scalars['timestamptz']>;
  deployId?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rawEventId?: Maybe<Scalars['String']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Workload_Sum_Fields = {
  __typename?: 'event_workload_sum_fields';
  desiredReplicas?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['Int']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** update columns of table "event_workload" */
export enum Event_Workload_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Action = 'action',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreationTimestamp = 'creationTimestamp',
  /** column name */
  DeployId = 'deployId',
  /** column name */
  DesiredReplicas = 'desiredReplicas',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RawEventId = 'rawEventId',
  /** column name */
  ReadyReplicas = 'readyReplicas',
  /** column name */
  ResourceName = 'resourceName',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Services = 'services',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Event_Workload_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Event_Workload_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Event_Workload_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Event_Workload_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Event_Workload_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Event_Workload_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Event_Workload_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Event_Workload_Set_Input>;
  where: Event_Workload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Workload_Var_Pop_Fields = {
  __typename?: 'event_workload_var_pop_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Workload_Var_Samp_Fields = {
  __typename?: 'event_workload_var_samp_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Workload_Variance_Fields = {
  __typename?: 'event_workload_variance_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "external_link" */
export type External_Link = {
  __typename?: 'external_link';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  displayName: Scalars['String'];
  entireAccount: Scalars['Boolean'];
  externalLinkContextValue?: Maybe<External_Link_Context_Enum>;
  id: Scalars['uuid'];
  k8sClusterName?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "external_link" */
export type External_Link_Aggregate = {
  __typename?: 'external_link_aggregate';
  aggregate?: Maybe<External_Link_Aggregate_Fields>;
  nodes: Array<External_Link>;
};

/** aggregate fields of "external_link" */
export type External_Link_Aggregate_Fields = {
  __typename?: 'external_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<External_Link_Max_Fields>;
  min?: Maybe<External_Link_Min_Fields>;
};


/** aggregate fields of "external_link" */
export type External_Link_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<External_Link_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "external_link". All fields are combined with a logical 'AND'. */
export type External_Link_Bool_Exp = {
  _and?: Maybe<Array<External_Link_Bool_Exp>>;
  _not?: Maybe<External_Link_Bool_Exp>;
  _or?: Maybe<Array<External_Link_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  entireAccount?: Maybe<Boolean_Comparison_Exp>;
  externalLinkContextValue?: Maybe<External_Link_Context_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  k8sClusterName?: Maybe<String_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  serviceId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_link" */
export enum External_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_836d0aae2645c5c36e463daf7e7 = 'PK_836d0aae2645c5c36e463daf7e7'
}

/** columns and relationships of "external_link_context" */
export type External_Link_Context = {
  __typename?: 'external_link_context';
  value: Scalars['String'];
};

/** aggregated selection of "external_link_context" */
export type External_Link_Context_Aggregate = {
  __typename?: 'external_link_context_aggregate';
  aggregate?: Maybe<External_Link_Context_Aggregate_Fields>;
  nodes: Array<External_Link_Context>;
};

/** aggregate fields of "external_link_context" */
export type External_Link_Context_Aggregate_Fields = {
  __typename?: 'external_link_context_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<External_Link_Context_Max_Fields>;
  min?: Maybe<External_Link_Context_Min_Fields>;
};


/** aggregate fields of "external_link_context" */
export type External_Link_Context_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<External_Link_Context_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "external_link_context". All fields are combined with a logical 'AND'. */
export type External_Link_Context_Bool_Exp = {
  _and?: Maybe<Array<External_Link_Context_Bool_Exp>>;
  _not?: Maybe<External_Link_Context_Bool_Exp>;
  _or?: Maybe<Array<External_Link_Context_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_link_context" */
export enum External_Link_Context_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkF13d9760d31dfca46b7f09d583c = 'PK_f13d9760d31dfca46b7f09d583c'
}

export enum External_Link_Context_Enum {
  Drawer = 'drawer',
  Service = 'service'
}

/** Boolean expression to compare columns of type "external_link_context_enum". All fields are combined with logical 'AND'. */
export type External_Link_Context_Enum_Comparison_Exp = {
  _eq?: Maybe<External_Link_Context_Enum>;
  _in?: Maybe<Array<External_Link_Context_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<External_Link_Context_Enum>;
  _nin?: Maybe<Array<External_Link_Context_Enum>>;
};

/** input type for inserting data into table "external_link_context" */
export type External_Link_Context_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type External_Link_Context_Max_Fields = {
  __typename?: 'external_link_context_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type External_Link_Context_Min_Fields = {
  __typename?: 'external_link_context_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "external_link_context" */
export type External_Link_Context_Mutation_Response = {
  __typename?: 'external_link_context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<External_Link_Context>;
};

/** on_conflict condition type for table "external_link_context" */
export type External_Link_Context_On_Conflict = {
  constraint: External_Link_Context_Constraint;
  update_columns?: Array<External_Link_Context_Update_Column>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};

/** Ordering options when selecting data from "external_link_context". */
export type External_Link_Context_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: external_link_context */
export type External_Link_Context_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "external_link_context" */
export enum External_Link_Context_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "external_link_context" */
export type External_Link_Context_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "external_link_context" */
export type External_Link_Context_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: External_Link_Context_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type External_Link_Context_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "external_link_context" */
export enum External_Link_Context_Update_Column {
  /** column name */
  Value = 'value'
}

export type External_Link_Context_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<External_Link_Context_Set_Input>;
  where: External_Link_Context_Bool_Exp;
};

/** input type for inserting data into table "external_link" */
export type External_Link_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  entireAccount?: Maybe<Scalars['Boolean']>;
  externalLinkContextValue?: Maybe<External_Link_Context_Enum>;
  id?: Maybe<Scalars['uuid']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type External_Link_Max_Fields = {
  __typename?: 'external_link_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type External_Link_Min_Fields = {
  __typename?: 'external_link_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "external_link" */
export type External_Link_Mutation_Response = {
  __typename?: 'external_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<External_Link>;
};

/** on_conflict condition type for table "external_link" */
export type External_Link_On_Conflict = {
  constraint: External_Link_Constraint;
  update_columns?: Array<External_Link_Update_Column>;
  where?: Maybe<External_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "external_link". */
export type External_Link_Order_By = {
  accountId?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  entireAccount?: Maybe<Order_By>;
  externalLinkContextValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  k8sClusterName?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  serviceId?: Maybe<Order_By>;
};

/** primary key columns input for table: external_link */
export type External_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "external_link" */
export enum External_Link_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EntireAccount = 'entireAccount',
  /** column name */
  ExternalLinkContextValue = 'externalLinkContextValue',
  /** column name */
  Id = 'id',
  /** column name */
  K8sClusterName = 'k8sClusterName',
  /** column name */
  Link = 'link',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ServiceId = 'serviceId'
}

/** input type for updating data in table "external_link" */
export type External_Link_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  entireAccount?: Maybe<Scalars['Boolean']>;
  externalLinkContextValue?: Maybe<External_Link_Context_Enum>;
  id?: Maybe<Scalars['uuid']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "external_link" */
export type External_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: External_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type External_Link_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  entireAccount?: Maybe<Scalars['Boolean']>;
  externalLinkContextValue?: Maybe<External_Link_Context_Enum>;
  id?: Maybe<Scalars['uuid']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

/** update columns of table "external_link" */
export enum External_Link_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EntireAccount = 'entireAccount',
  /** column name */
  ExternalLinkContextValue = 'externalLinkContextValue',
  /** column name */
  Id = 'id',
  /** column name */
  K8sClusterName = 'k8sClusterName',
  /** column name */
  Link = 'link',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ServiceId = 'serviceId'
}

export type External_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<External_Link_Set_Input>;
  where: External_Link_Bool_Exp;
};

export type FetchAllSlackChannelsOutput = {
  __typename?: 'fetchAllSlackChannelsOutput';
  channels: Array<Maybe<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type FetchEventsLimitsOutput = {
  __typename?: 'fetchEventsLimitsOutput';
  eventsCountStartEpoch?: Maybe<Scalars['Int']>;
  eventsLimitEpoch?: Maybe<Scalars['Int']>;
  eventsTotalSum?: Maybe<Scalars['Int']>;
};

export type GithubRevertOutput = {
  __typename?: 'githubRevertOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "helm_repo" */
export type Helm_Repo = {
  __typename?: 'helm_repo';
  accountId: Scalars['uuid'];
  clusterName: Scalars['String'];
  id: Scalars['uuid'];
  repoName: Scalars['String'];
  repoUrl: Scalars['String'];
};

/** aggregated selection of "helm_repo" */
export type Helm_Repo_Aggregate = {
  __typename?: 'helm_repo_aggregate';
  aggregate?: Maybe<Helm_Repo_Aggregate_Fields>;
  nodes: Array<Helm_Repo>;
};

/** aggregate fields of "helm_repo" */
export type Helm_Repo_Aggregate_Fields = {
  __typename?: 'helm_repo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Helm_Repo_Max_Fields>;
  min?: Maybe<Helm_Repo_Min_Fields>;
};


/** aggregate fields of "helm_repo" */
export type Helm_Repo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Helm_Repo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "helm_repo". All fields are combined with a logical 'AND'. */
export type Helm_Repo_Bool_Exp = {
  _and?: Maybe<Array<Helm_Repo_Bool_Exp>>;
  _not?: Maybe<Helm_Repo_Bool_Exp>;
  _or?: Maybe<Array<Helm_Repo_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  repoName?: Maybe<String_Comparison_Exp>;
  repoUrl?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "helm_repo" */
export enum Helm_Repo_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_3ca634b236ad1b9e8895848d320 = 'PK_3ca634b236ad1b9e8895848d320'
}

/** input type for inserting data into table "helm_repo" */
export type Helm_Repo_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  repoName?: Maybe<Scalars['String']>;
  repoUrl?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Helm_Repo_Max_Fields = {
  __typename?: 'helm_repo_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  repoName?: Maybe<Scalars['String']>;
  repoUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Helm_Repo_Min_Fields = {
  __typename?: 'helm_repo_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  repoName?: Maybe<Scalars['String']>;
  repoUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "helm_repo" */
export type Helm_Repo_Mutation_Response = {
  __typename?: 'helm_repo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Helm_Repo>;
};

/** on_conflict condition type for table "helm_repo" */
export type Helm_Repo_On_Conflict = {
  constraint: Helm_Repo_Constraint;
  update_columns?: Array<Helm_Repo_Update_Column>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};

/** Ordering options when selecting data from "helm_repo". */
export type Helm_Repo_Order_By = {
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  repoName?: Maybe<Order_By>;
  repoUrl?: Maybe<Order_By>;
};

/** primary key columns input for table: helm_repo */
export type Helm_Repo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "helm_repo" */
export enum Helm_Repo_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Id = 'id',
  /** column name */
  RepoName = 'repoName',
  /** column name */
  RepoUrl = 'repoUrl'
}

/** input type for updating data in table "helm_repo" */
export type Helm_Repo_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  repoName?: Maybe<Scalars['String']>;
  repoUrl?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "helm_repo" */
export type Helm_Repo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Helm_Repo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Helm_Repo_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  repoName?: Maybe<Scalars['String']>;
  repoUrl?: Maybe<Scalars['String']>;
};

/** update columns of table "helm_repo" */
export enum Helm_Repo_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Id = 'id',
  /** column name */
  RepoName = 'repoName',
  /** column name */
  RepoUrl = 'repoUrl'
}

export type Helm_Repo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Helm_Repo_Set_Input>;
  where: Helm_Repo_Bool_Exp;
};

/** columns and relationships of "installation" */
export type Installation = {
  __typename?: 'installation';
  /** An object relationship */
  account: Account;
  accountId: Scalars['uuid'];
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamp'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  integration: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lookupId?: Maybe<Scalars['String']>;
  showInWeb: Scalars['Boolean'];
  updatedAt: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "installation" */
export type InstallationConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "installation" */
export type Installation_Aggregate = {
  __typename?: 'installation_aggregate';
  aggregate?: Maybe<Installation_Aggregate_Fields>;
  nodes: Array<Installation>;
};

export type Installation_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Installation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Installation_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Installation_Aggregate_Bool_Exp_Count>;
};

export type Installation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Installation_Select_Column_Installation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Installation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Installation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Installation_Select_Column_Installation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Installation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Installation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Installation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Installation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "installation" */
export type Installation_Aggregate_Fields = {
  __typename?: 'installation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Installation_Max_Fields>;
  min?: Maybe<Installation_Min_Fields>;
};


/** aggregate fields of "installation" */
export type Installation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Installation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "installation" */
export type Installation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Installation_Max_Order_By>;
  min?: Maybe<Installation_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Installation_Append_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "installation" */
export type Installation_Arr_Rel_Insert_Input = {
  data: Array<Installation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Installation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "installation". All fields are combined with a logical 'AND'. */
export type Installation_Bool_Exp = {
  _and?: Maybe<Array<Installation_Bool_Exp>>;
  _not?: Maybe<Installation_Bool_Exp>;
  _or?: Maybe<Array<Installation_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  configuration?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  integration?: Maybe<String_Comparison_Exp>;
  isDeleted?: Maybe<Boolean_Comparison_Exp>;
  lookupId?: Maybe<String_Comparison_Exp>;
  showInWeb?: Maybe<Boolean_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "installation" */
export enum Installation_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF0cd0b17a45357b5e1da1da1680 = 'PK_f0cd0b17a45357b5e1da1da1680',
  /** unique or primary key constraint on columns "integration", "lookupId" */
  UqCbfdbb26d7abf67e45095ee17d2 = 'UQ_cbfdbb26d7abf67e45095ee17d2'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Installation_Delete_At_Path_Input = {
  configuration?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Installation_Delete_Elem_Input = {
  configuration?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Installation_Delete_Key_Input = {
  configuration?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "installation" */
export type Installation_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lookupId?: Maybe<Scalars['String']>;
  showInWeb?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Installation_Max_Fields = {
  __typename?: 'installation_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration?: Maybe<Scalars['String']>;
  lookupId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "installation" */
export type Installation_Max_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration?: Maybe<Order_By>;
  lookupId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Installation_Min_Fields = {
  __typename?: 'installation_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration?: Maybe<Scalars['String']>;
  lookupId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "installation" */
export type Installation_Min_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration?: Maybe<Order_By>;
  lookupId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "installation" */
export type Installation_Mutation_Response = {
  __typename?: 'installation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Installation>;
};

/** on_conflict condition type for table "installation" */
export type Installation_On_Conflict = {
  constraint: Installation_Constraint;
  update_columns?: Array<Installation_Update_Column>;
  where?: Maybe<Installation_Bool_Exp>;
};

/** Ordering options when selecting data from "installation". */
export type Installation_Order_By = {
  account?: Maybe<Account_Order_By>;
  accountId?: Maybe<Order_By>;
  configuration?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration?: Maybe<Order_By>;
  isDeleted?: Maybe<Order_By>;
  lookupId?: Maybe<Order_By>;
  showInWeb?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: installation */
export type Installation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Installation_Prepend_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "installation" */
export enum Installation_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LookupId = 'lookupId',
  /** column name */
  ShowInWeb = 'showInWeb',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "installation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "installation" */
export enum Installation_Select_Column_Installation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ShowInWeb = 'showInWeb'
}

/** select "installation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "installation" */
export enum Installation_Select_Column_Installation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ShowInWeb = 'showInWeb'
}

/** input type for updating data in table "installation" */
export type Installation_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lookupId?: Maybe<Scalars['String']>;
  showInWeb?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "installation" */
export type Installation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Installation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Installation_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lookupId?: Maybe<Scalars['String']>;
  showInWeb?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "installation" */
export enum Installation_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LookupId = 'lookupId',
  /** column name */
  ShowInWeb = 'showInWeb',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Installation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Installation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Installation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Installation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Installation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Installation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Installation_Set_Input>;
  where: Installation_Bool_Exp;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "komodor_filter_settings" */
export type Komodor_Filter_Settings = {
  __typename?: 'komodor_filter_settings';
  accountId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  filterData: Scalars['jsonb'];
  filterName: Scalars['String'];
  filterSettingType: Scalars['String'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};


/** columns and relationships of "komodor_filter_settings" */
export type Komodor_Filter_SettingsFilterDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_filter_settings" */
export type Komodor_Filter_Settings_Aggregate = {
  __typename?: 'komodor_filter_settings_aggregate';
  aggregate?: Maybe<Komodor_Filter_Settings_Aggregate_Fields>;
  nodes: Array<Komodor_Filter_Settings>;
};

/** aggregate fields of "komodor_filter_settings" */
export type Komodor_Filter_Settings_Aggregate_Fields = {
  __typename?: 'komodor_filter_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Filter_Settings_Max_Fields>;
  min?: Maybe<Komodor_Filter_Settings_Min_Fields>;
};


/** aggregate fields of "komodor_filter_settings" */
export type Komodor_Filter_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Filter_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Filter_Settings_Append_Input = {
  filterData?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "komodor_filter_settings". All fields are combined with a logical 'AND'. */
export type Komodor_Filter_Settings_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Filter_Settings_Bool_Exp>>;
  _not?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Filter_Settings_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  filterData?: Maybe<Jsonb_Comparison_Exp>;
  filterName?: Maybe<String_Comparison_Exp>;
  filterSettingType?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_filter_settings" */
export enum Komodor_Filter_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_3e3a070e25a04d51a6c99a6dfe2 = 'PK_3e3a070e25a04d51a6c99a6dfe2',
  /** unique or primary key constraint on columns "filterSettingType", "filterName", "accountId" */
  Uq_390c163593da3aaaae48735b39d = 'UQ_390c163593da3aaaae48735b39d'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Komodor_Filter_Settings_Delete_At_Path_Input = {
  filterData?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Komodor_Filter_Settings_Delete_Elem_Input = {
  filterData?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Komodor_Filter_Settings_Delete_Key_Input = {
  filterData?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "komodor_filter_settings" */
export type Komodor_Filter_Settings_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filterData?: Maybe<Scalars['jsonb']>;
  filterName?: Maybe<Scalars['String']>;
  filterSettingType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Komodor_Filter_Settings_Max_Fields = {
  __typename?: 'komodor_filter_settings_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filterName?: Maybe<Scalars['String']>;
  filterSettingType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Komodor_Filter_Settings_Min_Fields = {
  __typename?: 'komodor_filter_settings_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filterName?: Maybe<Scalars['String']>;
  filterSettingType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "komodor_filter_settings" */
export type Komodor_Filter_Settings_Mutation_Response = {
  __typename?: 'komodor_filter_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Filter_Settings>;
};

/** on_conflict condition type for table "komodor_filter_settings" */
export type Komodor_Filter_Settings_On_Conflict = {
  constraint: Komodor_Filter_Settings_Constraint;
  update_columns?: Array<Komodor_Filter_Settings_Update_Column>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_filter_settings". */
export type Komodor_Filter_Settings_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  filterData?: Maybe<Order_By>;
  filterName?: Maybe<Order_By>;
  filterSettingType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_filter_settings */
export type Komodor_Filter_Settings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Filter_Settings_Prepend_Input = {
  filterData?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "komodor_filter_settings" */
export enum Komodor_Filter_Settings_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilterData = 'filterData',
  /** column name */
  FilterName = 'filterName',
  /** column name */
  FilterSettingType = 'filterSettingType',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "komodor_filter_settings" */
export type Komodor_Filter_Settings_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filterData?: Maybe<Scalars['jsonb']>;
  filterName?: Maybe<Scalars['String']>;
  filterSettingType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "komodor_filter_settings" */
export type Komodor_Filter_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Filter_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Filter_Settings_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filterData?: Maybe<Scalars['jsonb']>;
  filterName?: Maybe<Scalars['String']>;
  filterSettingType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "komodor_filter_settings" */
export enum Komodor_Filter_Settings_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilterData = 'filterData',
  /** column name */
  FilterName = 'filterName',
  /** column name */
  FilterSettingType = 'filterSettingType',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Komodor_Filter_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Komodor_Filter_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Komodor_Filter_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Komodor_Filter_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Komodor_Filter_Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Komodor_Filter_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Filter_Settings_Set_Input>;
  where: Komodor_Filter_Settings_Bool_Exp;
};

/** columns and relationships of "komodor_service" */
export type Komodor_Service = {
  __typename?: 'komodor_service';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  currentConfigId?: Maybe<Scalars['String']>;
  /** An object relationship */
  datadog_data?: Maybe<Komodor_Service_Datadog_Data>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deploy_state?: Maybe<Komodor_Service_Deploy_State>;
  displayName: Scalars['String'];
  /** An object relationship */
  health_state?: Maybe<Komodor_Service_Health_State>;
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  k8sClusterName: Scalars['String'];
  /** An object relationship */
  k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata>;
  kind?: Maybe<Scalars['String']>;
  kubernetes_agent_info?: Maybe<Kubernetes_Agent_Info>;
  lastEventTime: Scalars['timestamptz'];
  namespace: Scalars['String'];
  qos?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  sentry_data?: Maybe<Komodor_Service_Sentry_Data>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "komodor_service" */
export type Komodor_ServiceRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_service" */
export type Komodor_Service_Aggregate = {
  __typename?: 'komodor_service_aggregate';
  aggregate?: Maybe<Komodor_Service_Aggregate_Fields>;
  nodes: Array<Komodor_Service>;
};

/** aggregate fields of "komodor_service" */
export type Komodor_Service_Aggregate_Fields = {
  __typename?: 'komodor_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Max_Fields>;
  min?: Maybe<Komodor_Service_Min_Fields>;
};


/** aggregate fields of "komodor_service" */
export type Komodor_Service_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Append_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "komodor_service_attributes" */
export type Komodor_Service_Attributes = {
  __typename?: 'komodor_service_attributes';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  keySource: Scalars['String'];
  /** An object relationship */
  komodor_service?: Maybe<Komodor_Service>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_service_attributes" */
export type Komodor_Service_Attributes_Aggregate = {
  __typename?: 'komodor_service_attributes_aggregate';
  aggregate?: Maybe<Komodor_Service_Attributes_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Attributes>;
};

/** aggregate fields of "komodor_service_attributes" */
export type Komodor_Service_Attributes_Aggregate_Fields = {
  __typename?: 'komodor_service_attributes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Attributes_Max_Fields>;
  min?: Maybe<Komodor_Service_Attributes_Min_Fields>;
};


/** aggregate fields of "komodor_service_attributes" */
export type Komodor_Service_Attributes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Attributes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "komodor_service_attributes". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Attributes_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Attributes_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Attributes_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  keySource?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  serviceId?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_attributes" */
export enum Komodor_Service_Attributes_Constraint {
  /** unique or primary key constraint on columns "key", "accountId", "serviceId" */
  IdxA2f26f35f3a8ff5761995e5117 = 'IDX_a2f26f35f3a8ff5761995e5117',
  /** unique or primary key constraint on columns "id" */
  Pk_3c8a3f9fd7f93ddf717b84d8756 = 'PK_3c8a3f9fd7f93ddf717b84d8756'
}

/** input type for inserting data into table "komodor_service_attributes" */
export type Komodor_Service_Attributes_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Komodor_Service_Attributes_Max_Fields = {
  __typename?: 'komodor_service_attributes_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Komodor_Service_Attributes_Min_Fields = {
  __typename?: 'komodor_service_attributes_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "komodor_service_attributes" */
export type Komodor_Service_Attributes_Mutation_Response = {
  __typename?: 'komodor_service_attributes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Attributes>;
};

/** on_conflict condition type for table "komodor_service_attributes" */
export type Komodor_Service_Attributes_On_Conflict = {
  constraint: Komodor_Service_Attributes_Constraint;
  update_columns?: Array<Komodor_Service_Attributes_Update_Column>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_attributes". */
export type Komodor_Service_Attributes_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  keySource?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  serviceId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_attributes */
export type Komodor_Service_Attributes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "komodor_service_attributes" */
export enum Komodor_Service_Attributes_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  KeySource = 'keySource',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "komodor_service_attributes" */
export type Komodor_Service_Attributes_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "komodor_service_attributes" */
export type Komodor_Service_Attributes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Attributes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Attributes_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "komodor_service_attributes" */
export enum Komodor_Service_Attributes_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  KeySource = 'keySource',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type Komodor_Service_Attributes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Attributes_Set_Input>;
  where: Komodor_Service_Attributes_Bool_Exp;
};

/** Boolean expression to filter rows from the table "komodor_service". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  currentConfigId?: Maybe<String_Comparison_Exp>;
  datadog_data?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  deploy_state?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  health_state?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  inactive?: Maybe<Boolean_Comparison_Exp>;
  isDeleted?: Maybe<Boolean_Comparison_Exp>;
  k8sClusterName?: Maybe<String_Comparison_Exp>;
  k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  lastEventTime?: Maybe<Timestamptz_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  qos?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  sentry_data?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service" */
export enum Komodor_Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkAfc8c4574e5b6f8169bdf7db6f3 = 'PK_afc8c4574e5b6f8169bdf7db6f3'
}

/** columns and relationships of "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data = {
  __typename?: 'komodor_service_datadog_data';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  komodor_service: Komodor_Service;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Aggregate = {
  __typename?: 'komodor_service_datadog_data_aggregate';
  aggregate?: Maybe<Komodor_Service_Datadog_Data_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Datadog_Data>;
};

/** aggregate fields of "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Aggregate_Fields = {
  __typename?: 'komodor_service_datadog_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Datadog_Data_Max_Fields>;
  min?: Maybe<Komodor_Service_Datadog_Data_Min_Fields>;
};


/** aggregate fields of "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Datadog_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "komodor_service_datadog_data". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Datadog_Data_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Datadog_Data_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Datadog_Data_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  datadogEnv?: Maybe<String_Comparison_Exp>;
  datadogServiceName?: Maybe<String_Comparison_Exp>;
  datadogVersion?: Maybe<String_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_datadog_data" */
export enum Komodor_Service_Datadog_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_90391b73c3f151f5524399cb9f1 = 'PK_90391b73c3f151f5524399cb9f1',
  /** unique or primary key constraint on columns "id" */
  Uq_90391b73c3f151f5524399cb9f1 = 'UQ_90391b73c3f151f5524399cb9f1'
}

/** input type for inserting data into table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Komodor_Service_Datadog_Data_Max_Fields = {
  __typename?: 'komodor_service_datadog_data_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_Datadog_Data_Min_Fields = {
  __typename?: 'komodor_service_datadog_data_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Mutation_Response = {
  __typename?: 'komodor_service_datadog_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Datadog_Data>;
};

/** input type for inserting object relation for remote table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Obj_Rel_Insert_Input = {
  data: Komodor_Service_Datadog_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_Datadog_Data_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_On_Conflict = {
  constraint: Komodor_Service_Datadog_Data_Constraint;
  update_columns?: Array<Komodor_Service_Datadog_Data_Update_Column>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_datadog_data". */
export type Komodor_Service_Datadog_Data_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  datadogEnv?: Maybe<Order_By>;
  datadogServiceName?: Maybe<Order_By>;
  datadogVersion?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_datadog_data */
export type Komodor_Service_Datadog_Data_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "komodor_service_datadog_data" */
export enum Komodor_Service_Datadog_Data_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatadogEnv = 'datadogEnv',
  /** column name */
  DatadogServiceName = 'datadogServiceName',
  /** column name */
  DatadogVersion = 'datadogVersion',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "komodor_service_datadog_data" */
export type Komodor_Service_Datadog_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Datadog_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Datadog_Data_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datadogEnv?: Maybe<Scalars['String']>;
  datadogServiceName?: Maybe<Scalars['String']>;
  datadogVersion?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "komodor_service_datadog_data" */
export enum Komodor_Service_Datadog_Data_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatadogEnv = 'datadogEnv',
  /** column name */
  DatadogServiceName = 'datadogServiceName',
  /** column name */
  DatadogVersion = 'datadogVersion',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_Datadog_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Datadog_Data_Set_Input>;
  where: Komodor_Service_Datadog_Data_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Komodor_Service_Delete_At_Path_Input = {
  rbacResources?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Komodor_Service_Delete_Elem_Input = {
  rbacResources?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Komodor_Service_Delete_Key_Input = {
  rbacResources?: Maybe<Scalars['String']>;
};

/** columns and relationships of "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State = {
  __typename?: 'komodor_service_deploy_state';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  komodor_service: Komodor_Service;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_StateCurrentNotificationConfigArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Aggregate = {
  __typename?: 'komodor_service_deploy_state_aggregate';
  aggregate?: Maybe<Komodor_Service_Deploy_State_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Deploy_State>;
};

/** aggregate fields of "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Aggregate_Fields = {
  __typename?: 'komodor_service_deploy_state_aggregate_fields';
  avg?: Maybe<Komodor_Service_Deploy_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Deploy_State_Max_Fields>;
  min?: Maybe<Komodor_Service_Deploy_State_Min_Fields>;
  stddev?: Maybe<Komodor_Service_Deploy_State_Stddev_Fields>;
  stddev_pop?: Maybe<Komodor_Service_Deploy_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Komodor_Service_Deploy_State_Stddev_Samp_Fields>;
  sum?: Maybe<Komodor_Service_Deploy_State_Sum_Fields>;
  var_pop?: Maybe<Komodor_Service_Deploy_State_Var_Pop_Fields>;
  var_samp?: Maybe<Komodor_Service_Deploy_State_Var_Samp_Fields>;
  variance?: Maybe<Komodor_Service_Deploy_State_Variance_Fields>;
};


/** aggregate fields of "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Deploy_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Deploy_State_Append_Input = {
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Komodor_Service_Deploy_State_Avg_Fields = {
  __typename?: 'komodor_service_deploy_state_avg_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "komodor_service_deploy_state". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Deploy_State_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Deploy_State_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Deploy_State_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  currentNotificationConfig?: Maybe<Jsonb_Comparison_Exp>;
  deploymentStatus?: Maybe<String_Comparison_Exp>;
  desiredReplicas?: Maybe<Int_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  lastDeployEndTime?: Maybe<Timestamptz_Comparison_Exp>;
  lastDeployStartTime?: Maybe<Timestamptz_Comparison_Exp>;
  readyReplicas?: Maybe<Int_Comparison_Exp>;
  resourceVersion?: Maybe<Bigint_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_deploy_state" */
export enum Komodor_Service_Deploy_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_981e6b43a0b648c110c41503882 = 'PK_981e6b43a0b648c110c41503882',
  /** unique or primary key constraint on columns "id" */
  Uq_981e6b43a0b648c110c41503882 = 'UQ_981e6b43a0b648c110c41503882'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Komodor_Service_Deploy_State_Delete_At_Path_Input = {
  currentNotificationConfig?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Komodor_Service_Deploy_State_Delete_Elem_Input = {
  currentNotificationConfig?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Komodor_Service_Deploy_State_Delete_Key_Input = {
  currentNotificationConfig?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Inc_Input = {
  desiredReplicas?: Maybe<Scalars['Int']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Komodor_Service_Deploy_State_Max_Fields = {
  __typename?: 'komodor_service_deploy_state_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_Deploy_State_Min_Fields = {
  __typename?: 'komodor_service_deploy_state_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Mutation_Response = {
  __typename?: 'komodor_service_deploy_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Deploy_State>;
};

/** input type for inserting object relation for remote table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Obj_Rel_Insert_Input = {
  data: Komodor_Service_Deploy_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_Deploy_State_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_On_Conflict = {
  constraint: Komodor_Service_Deploy_State_Constraint;
  update_columns?: Array<Komodor_Service_Deploy_State_Update_Column>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_deploy_state". */
export type Komodor_Service_Deploy_State_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  currentNotificationConfig?: Maybe<Order_By>;
  deploymentStatus?: Maybe<Order_By>;
  desiredReplicas?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  lastDeployEndTime?: Maybe<Order_By>;
  lastDeployStartTime?: Maybe<Order_By>;
  readyReplicas?: Maybe<Order_By>;
  resourceVersion?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_deploy_state */
export type Komodor_Service_Deploy_State_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Deploy_State_Prepend_Input = {
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "komodor_service_deploy_state" */
export enum Komodor_Service_Deploy_State_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentNotificationConfig = 'currentNotificationConfig',
  /** column name */
  DeploymentStatus = 'deploymentStatus',
  /** column name */
  DesiredReplicas = 'desiredReplicas',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  LastDeployEndTime = 'lastDeployEndTime',
  /** column name */
  LastDeployStartTime = 'lastDeployStartTime',
  /** column name */
  ReadyReplicas = 'readyReplicas',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Komodor_Service_Deploy_State_Stddev_Fields = {
  __typename?: 'komodor_service_deploy_state_stddev_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Komodor_Service_Deploy_State_Stddev_Pop_Fields = {
  __typename?: 'komodor_service_deploy_state_stddev_pop_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Komodor_Service_Deploy_State_Stddev_Samp_Fields = {
  __typename?: 'komodor_service_deploy_state_stddev_samp_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "komodor_service_deploy_state" */
export type Komodor_Service_Deploy_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Deploy_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Deploy_State_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentNotificationConfig?: Maybe<Scalars['jsonb']>;
  deploymentStatus?: Maybe<Scalars['String']>;
  desiredReplicas?: Maybe<Scalars['Int']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastDeployEndTime?: Maybe<Scalars['timestamptz']>;
  lastDeployStartTime?: Maybe<Scalars['timestamptz']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Komodor_Service_Deploy_State_Sum_Fields = {
  __typename?: 'komodor_service_deploy_state_sum_fields';
  desiredReplicas?: Maybe<Scalars['Int']>;
  readyReplicas?: Maybe<Scalars['Int']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** update columns of table "komodor_service_deploy_state" */
export enum Komodor_Service_Deploy_State_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentNotificationConfig = 'currentNotificationConfig',
  /** column name */
  DeploymentStatus = 'deploymentStatus',
  /** column name */
  DesiredReplicas = 'desiredReplicas',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  LastDeployEndTime = 'lastDeployEndTime',
  /** column name */
  LastDeployStartTime = 'lastDeployStartTime',
  /** column name */
  ReadyReplicas = 'readyReplicas',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_Deploy_State_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Komodor_Service_Deploy_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Komodor_Service_Deploy_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Komodor_Service_Deploy_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Komodor_Service_Deploy_State_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Komodor_Service_Deploy_State_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Komodor_Service_Deploy_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Deploy_State_Set_Input>;
  where: Komodor_Service_Deploy_State_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Komodor_Service_Deploy_State_Var_Pop_Fields = {
  __typename?: 'komodor_service_deploy_state_var_pop_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Komodor_Service_Deploy_State_Var_Samp_Fields = {
  __typename?: 'komodor_service_deploy_state_var_samp_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Komodor_Service_Deploy_State_Variance_Fields = {
  __typename?: 'komodor_service_deploy_state_variance_fields';
  desiredReplicas?: Maybe<Scalars['Float']>;
  readyReplicas?: Maybe<Scalars['Float']>;
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "komodor_service_health_state" */
export type Komodor_Service_Health_State = {
  __typename?: 'komodor_service_health_state';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  healthStatus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  komodor_service: Komodor_Service;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "komodor_service_health_state" */
export type Komodor_Service_Health_State_Aggregate = {
  __typename?: 'komodor_service_health_state_aggregate';
  aggregate?: Maybe<Komodor_Service_Health_State_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Health_State>;
};

/** aggregate fields of "komodor_service_health_state" */
export type Komodor_Service_Health_State_Aggregate_Fields = {
  __typename?: 'komodor_service_health_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Health_State_Max_Fields>;
  min?: Maybe<Komodor_Service_Health_State_Min_Fields>;
};


/** aggregate fields of "komodor_service_health_state" */
export type Komodor_Service_Health_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Health_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "komodor_service_health_state". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Health_State_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Health_State_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Health_State_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  healthStatus?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_health_state" */
export enum Komodor_Service_Health_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD194d24210d55023c8a9986317d = 'PK_d194d24210d55023c8a9986317d',
  /** unique or primary key constraint on columns "id" */
  UqD194d24210d55023c8a9986317d = 'UQ_d194d24210d55023c8a9986317d'
}

/** input type for inserting data into table "komodor_service_health_state" */
export type Komodor_Service_Health_State_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  healthStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Komodor_Service_Health_State_Max_Fields = {
  __typename?: 'komodor_service_health_state_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  healthStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_Health_State_Min_Fields = {
  __typename?: 'komodor_service_health_state_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  healthStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service_health_state" */
export type Komodor_Service_Health_State_Mutation_Response = {
  __typename?: 'komodor_service_health_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Health_State>;
};

/** input type for inserting object relation for remote table "komodor_service_health_state" */
export type Komodor_Service_Health_State_Obj_Rel_Insert_Input = {
  data: Komodor_Service_Health_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_Health_State_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service_health_state" */
export type Komodor_Service_Health_State_On_Conflict = {
  constraint: Komodor_Service_Health_State_Constraint;
  update_columns?: Array<Komodor_Service_Health_State_Update_Column>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_health_state". */
export type Komodor_Service_Health_State_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  healthStatus?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_health_state */
export type Komodor_Service_Health_State_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "komodor_service_health_state" */
export enum Komodor_Service_Health_State_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  HealthStatus = 'healthStatus',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "komodor_service_health_state" */
export type Komodor_Service_Health_State_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  healthStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "komodor_service_health_state" */
export type Komodor_Service_Health_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Health_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Health_State_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  healthStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "komodor_service_health_state" */
export enum Komodor_Service_Health_State_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  HealthStatus = 'healthStatus',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_Health_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Health_State_Set_Input>;
  where: Komodor_Service_Health_State_Bool_Exp;
};

/** input type for inserting data into table "komodor_service" */
export type Komodor_Service_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentConfigId?: Maybe<Scalars['String']>;
  datadog_data?: Maybe<Komodor_Service_Datadog_Data_Obj_Rel_Insert_Input>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  deploy_state?: Maybe<Komodor_Service_Deploy_State_Obj_Rel_Insert_Input>;
  displayName?: Maybe<Scalars['String']>;
  health_state?: Maybe<Komodor_Service_Health_State_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Obj_Rel_Insert_Input>;
  kind?: Maybe<Scalars['String']>;
  lastEventTime?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qos?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  sentry_data?: Maybe<Komodor_Service_Sentry_Data_Obj_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata = {
  __typename?: 'komodor_service_k8s_metadata';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  annotations: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  /** An object relationship */
  komodor_service: Komodor_Service;
  labels: Scalars['jsonb'];
  phase?: Maybe<Scalars['String']>;
  podLabels: Scalars['jsonb'];
  pvcNames: Scalars['jsonb'];
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  selector: Scalars['jsonb'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_MetadataAnnotationsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_MetadataLabelsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_MetadataPodLabelsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_MetadataPvcNamesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_MetadataSelectorArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Aggregate = {
  __typename?: 'komodor_service_k8s_metadata_aggregate';
  aggregate?: Maybe<Komodor_Service_K8s_Metadata_Aggregate_Fields>;
  nodes: Array<Komodor_Service_K8s_Metadata>;
};

/** aggregate fields of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Aggregate_Fields = {
  __typename?: 'komodor_service_k8s_metadata_aggregate_fields';
  avg?: Maybe<Komodor_Service_K8s_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_K8s_Metadata_Max_Fields>;
  min?: Maybe<Komodor_Service_K8s_Metadata_Min_Fields>;
  stddev?: Maybe<Komodor_Service_K8s_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Komodor_Service_K8s_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Komodor_Service_K8s_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Komodor_Service_K8s_Metadata_Sum_Fields>;
  var_pop?: Maybe<Komodor_Service_K8s_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Komodor_Service_K8s_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Komodor_Service_K8s_Metadata_Variance_Fields>;
};


/** aggregate fields of "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_K8s_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_K8s_Metadata_Append_Input = {
  annotations?: Maybe<Scalars['jsonb']>;
  labels?: Maybe<Scalars['jsonb']>;
  podLabels?: Maybe<Scalars['jsonb']>;
  pvcNames?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Komodor_Service_K8s_Metadata_Avg_Fields = {
  __typename?: 'komodor_service_k8s_metadata_avg_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "komodor_service_k8s_metadata". All fields are combined with a logical 'AND'. */
export type Komodor_Service_K8s_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_K8s_Metadata_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_K8s_Metadata_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  annotations?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  jobState?: Maybe<String_Comparison_Exp>;
  k8sUid?: Maybe<String_Comparison_Exp>;
  kind?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  labels?: Maybe<Jsonb_Comparison_Exp>;
  phase?: Maybe<String_Comparison_Exp>;
  podLabels?: Maybe<Jsonb_Comparison_Exp>;
  pvcNames?: Maybe<Jsonb_Comparison_Exp>;
  replicaSetName?: Maybe<String_Comparison_Exp>;
  resourceVersion?: Maybe<Bigint_Comparison_Exp>;
  schedule?: Maybe<String_Comparison_Exp>;
  selector?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_k8s_metadata" */
export enum Komodor_Service_K8s_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_267967d6d5afbf535628d4fbbf0 = 'PK_267967d6d5afbf535628d4fbbf0',
  /** unique or primary key constraint on columns "id" */
  Uq_267967d6d5afbf535628d4fbbf0 = 'UQ_267967d6d5afbf535628d4fbbf0'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Komodor_Service_K8s_Metadata_Delete_At_Path_Input = {
  annotations?: Maybe<Array<Scalars['String']>>;
  labels?: Maybe<Array<Scalars['String']>>;
  podLabels?: Maybe<Array<Scalars['String']>>;
  pvcNames?: Maybe<Array<Scalars['String']>>;
  selector?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Komodor_Service_K8s_Metadata_Delete_Elem_Input = {
  annotations?: Maybe<Scalars['Int']>;
  labels?: Maybe<Scalars['Int']>;
  podLabels?: Maybe<Scalars['Int']>;
  pvcNames?: Maybe<Scalars['Int']>;
  selector?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Komodor_Service_K8s_Metadata_Delete_Key_Input = {
  annotations?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  podLabels?: Maybe<Scalars['String']>;
  pvcNames?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Inc_Input = {
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  annotations?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  labels?: Maybe<Scalars['jsonb']>;
  phase?: Maybe<Scalars['String']>;
  podLabels?: Maybe<Scalars['jsonb']>;
  pvcNames?: Maybe<Scalars['jsonb']>;
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Komodor_Service_K8s_Metadata_Max_Fields = {
  __typename?: 'komodor_service_k8s_metadata_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_K8s_Metadata_Min_Fields = {
  __typename?: 'komodor_service_k8s_metadata_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Mutation_Response = {
  __typename?: 'komodor_service_k8s_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_K8s_Metadata>;
};

/** input type for inserting object relation for remote table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Obj_Rel_Insert_Input = {
  data: Komodor_Service_K8s_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_K8s_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_On_Conflict = {
  constraint: Komodor_Service_K8s_Metadata_Constraint;
  update_columns?: Array<Komodor_Service_K8s_Metadata_Update_Column>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_k8s_metadata". */
export type Komodor_Service_K8s_Metadata_Order_By = {
  accountId?: Maybe<Order_By>;
  annotations?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobState?: Maybe<Order_By>;
  k8sUid?: Maybe<Order_By>;
  kind?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  labels?: Maybe<Order_By>;
  phase?: Maybe<Order_By>;
  podLabels?: Maybe<Order_By>;
  pvcNames?: Maybe<Order_By>;
  replicaSetName?: Maybe<Order_By>;
  resourceVersion?: Maybe<Order_By>;
  schedule?: Maybe<Order_By>;
  selector?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_k8s_metadata */
export type Komodor_Service_K8s_Metadata_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_K8s_Metadata_Prepend_Input = {
  annotations?: Maybe<Scalars['jsonb']>;
  labels?: Maybe<Scalars['jsonb']>;
  podLabels?: Maybe<Scalars['jsonb']>;
  pvcNames?: Maybe<Scalars['jsonb']>;
  selector?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "komodor_service_k8s_metadata" */
export enum Komodor_Service_K8s_Metadata_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Annotations = 'annotations',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobState = 'jobState',
  /** column name */
  K8sUid = 'k8sUid',
  /** column name */
  Kind = 'kind',
  /** column name */
  Labels = 'labels',
  /** column name */
  Phase = 'phase',
  /** column name */
  PodLabels = 'podLabels',
  /** column name */
  PvcNames = 'pvcNames',
  /** column name */
  ReplicaSetName = 'replicaSetName',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Selector = 'selector',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  annotations?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['jsonb']>;
  phase?: Maybe<Scalars['String']>;
  podLabels?: Maybe<Scalars['jsonb']>;
  pvcNames?: Maybe<Scalars['jsonb']>;
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Komodor_Service_K8s_Metadata_Stddev_Fields = {
  __typename?: 'komodor_service_k8s_metadata_stddev_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Komodor_Service_K8s_Metadata_Stddev_Pop_Fields = {
  __typename?: 'komodor_service_k8s_metadata_stddev_pop_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Komodor_Service_K8s_Metadata_Stddev_Samp_Fields = {
  __typename?: 'komodor_service_k8s_metadata_stddev_samp_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "komodor_service_k8s_metadata" */
export type Komodor_Service_K8s_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_K8s_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_K8s_Metadata_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  annotations?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobState?: Maybe<Scalars['String']>;
  k8sUid?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['jsonb']>;
  phase?: Maybe<Scalars['String']>;
  podLabels?: Maybe<Scalars['jsonb']>;
  pvcNames?: Maybe<Scalars['jsonb']>;
  replicaSetName?: Maybe<Scalars['String']>;
  resourceVersion?: Maybe<Scalars['bigint']>;
  schedule?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Komodor_Service_K8s_Metadata_Sum_Fields = {
  __typename?: 'komodor_service_k8s_metadata_sum_fields';
  resourceVersion?: Maybe<Scalars['bigint']>;
};

/** update columns of table "komodor_service_k8s_metadata" */
export enum Komodor_Service_K8s_Metadata_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Annotations = 'annotations',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobState = 'jobState',
  /** column name */
  K8sUid = 'k8sUid',
  /** column name */
  Kind = 'kind',
  /** column name */
  Labels = 'labels',
  /** column name */
  Phase = 'phase',
  /** column name */
  PodLabels = 'podLabels',
  /** column name */
  PvcNames = 'pvcNames',
  /** column name */
  ReplicaSetName = 'replicaSetName',
  /** column name */
  ResourceVersion = 'resourceVersion',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Selector = 'selector',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_K8s_Metadata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Komodor_Service_K8s_Metadata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Komodor_Service_K8s_Metadata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Komodor_Service_K8s_Metadata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Komodor_Service_K8s_Metadata_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Komodor_Service_K8s_Metadata_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Komodor_Service_K8s_Metadata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_K8s_Metadata_Set_Input>;
  where: Komodor_Service_K8s_Metadata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Komodor_Service_K8s_Metadata_Var_Pop_Fields = {
  __typename?: 'komodor_service_k8s_metadata_var_pop_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Komodor_Service_K8s_Metadata_Var_Samp_Fields = {
  __typename?: 'komodor_service_k8s_metadata_var_samp_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Komodor_Service_K8s_Metadata_Variance_Fields = {
  __typename?: 'komodor_service_k8s_metadata_variance_fields';
  resourceVersion?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Komodor_Service_Max_Fields = {
  __typename?: 'komodor_service_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentConfigId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastEventTime?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qos?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_Min_Fields = {
  __typename?: 'komodor_service_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentConfigId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastEventTime?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qos?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service" */
export type Komodor_Service_Mutation_Response = {
  __typename?: 'komodor_service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service>;
};

/** input type for inserting object relation for remote table "komodor_service" */
export type Komodor_Service_Obj_Rel_Insert_Input = {
  data: Komodor_Service_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service" */
export type Komodor_Service_On_Conflict = {
  constraint: Komodor_Service_Constraint;
  update_columns?: Array<Komodor_Service_Update_Column>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service". */
export type Komodor_Service_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  currentConfigId?: Maybe<Order_By>;
  datadog_data?: Maybe<Komodor_Service_Datadog_Data_Order_By>;
  deletedAt?: Maybe<Order_By>;
  deploy_state?: Maybe<Komodor_Service_Deploy_State_Order_By>;
  displayName?: Maybe<Order_By>;
  health_state?: Maybe<Komodor_Service_Health_State_Order_By>;
  id?: Maybe<Order_By>;
  inactive?: Maybe<Order_By>;
  isDeleted?: Maybe<Order_By>;
  k8sClusterName?: Maybe<Order_By>;
  k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Order_By>;
  kind?: Maybe<Order_By>;
  lastEventTime?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  qos?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  sentry_data?: Maybe<Komodor_Service_Sentry_Data_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service */
export type Komodor_Service_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Prepend_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "komodor_service" */
export enum Komodor_Service_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentConfigId = 'currentConfigId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  K8sClusterName = 'k8sClusterName',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastEventTime = 'lastEventTime',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Qos = 'qos',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data = {
  __typename?: 'komodor_service_sentry_data';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  komodor_service: Komodor_Service;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Aggregate = {
  __typename?: 'komodor_service_sentry_data_aggregate';
  aggregate?: Maybe<Komodor_Service_Sentry_Data_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Sentry_Data>;
};

/** aggregate fields of "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Aggregate_Fields = {
  __typename?: 'komodor_service_sentry_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Sentry_Data_Max_Fields>;
  min?: Maybe<Komodor_Service_Sentry_Data_Min_Fields>;
};


/** aggregate fields of "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Sentry_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "komodor_service_sentry_data". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Sentry_Data_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Sentry_Data_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Sentry_Data_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  sentryEnv?: Maybe<String_Comparison_Exp>;
  sentryProjectId?: Maybe<String_Comparison_Exp>;
  sentryServiceName?: Maybe<String_Comparison_Exp>;
  sentryVersion?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_sentry_data" */
export enum Komodor_Service_Sentry_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB6f45f668d54bd75362b9498579 = 'PK_b6f45f668d54bd75362b9498579',
  /** unique or primary key constraint on columns "id" */
  UqB6f45f668d54bd75362b9498579 = 'UQ_b6f45f668d54bd75362b9498579'
}

/** input type for inserting data into table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Komodor_Service_Sentry_Data_Max_Fields = {
  __typename?: 'komodor_service_sentry_data_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Komodor_Service_Sentry_Data_Min_Fields = {
  __typename?: 'komodor_service_sentry_data_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Mutation_Response = {
  __typename?: 'komodor_service_sentry_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Sentry_Data>;
};

/** input type for inserting object relation for remote table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Obj_Rel_Insert_Input = {
  data: Komodor_Service_Sentry_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Komodor_Service_Sentry_Data_On_Conflict>;
};

/** on_conflict condition type for table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_On_Conflict = {
  constraint: Komodor_Service_Sentry_Data_Constraint;
  update_columns?: Array<Komodor_Service_Sentry_Data_Update_Column>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_sentry_data". */
export type Komodor_Service_Sentry_Data_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  sentryEnv?: Maybe<Order_By>;
  sentryProjectId?: Maybe<Order_By>;
  sentryServiceName?: Maybe<Order_By>;
  sentryVersion?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_sentry_data */
export type Komodor_Service_Sentry_Data_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "komodor_service_sentry_data" */
export enum Komodor_Service_Sentry_Data_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  SentryEnv = 'sentryEnv',
  /** column name */
  SentryProjectId = 'sentryProjectId',
  /** column name */
  SentryServiceName = 'sentryServiceName',
  /** column name */
  SentryVersion = 'sentryVersion',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "komodor_service_sentry_data" */
export type Komodor_Service_Sentry_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Sentry_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Sentry_Data_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sentryEnv?: Maybe<Scalars['String']>;
  sentryProjectId?: Maybe<Scalars['String']>;
  sentryServiceName?: Maybe<Scalars['String']>;
  sentryVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "komodor_service_sentry_data" */
export enum Komodor_Service_Sentry_Data_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  SentryEnv = 'sentryEnv',
  /** column name */
  SentryProjectId = 'sentryProjectId',
  /** column name */
  SentryServiceName = 'sentryServiceName',
  /** column name */
  SentryVersion = 'sentryVersion',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_Sentry_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Sentry_Data_Set_Input>;
  where: Komodor_Service_Sentry_Data_Bool_Exp;
};

/** input type for updating data in table "komodor_service" */
export type Komodor_Service_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentConfigId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastEventTime?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qos?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State = {
  __typename?: 'komodor_service_static_prevention_state';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  cluster: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  eventTime: Scalars['timestamptz'];
  failedChecks?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  /** An object relationship */
  komodor_service: Komodor_Service;
  namespace: Scalars['String'];
  rbacResources?: Maybe<Scalars['jsonb']>;
  results: Scalars['jsonb'];
  resultsSummary: Scalars['jsonb'];
  shortResourceName: Scalars['String'];
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_StateFailedChecksArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_StateRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_StateResultsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_StateResultsSummaryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_StateWorkflowConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Aggregate = {
  __typename?: 'komodor_service_static_prevention_state_aggregate';
  aggregate?: Maybe<Komodor_Service_Static_Prevention_State_Aggregate_Fields>;
  nodes: Array<Komodor_Service_Static_Prevention_State>;
};

/** aggregate fields of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Aggregate_Fields = {
  __typename?: 'komodor_service_static_prevention_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Komodor_Service_Static_Prevention_State_Max_Fields>;
  min?: Maybe<Komodor_Service_Static_Prevention_State_Min_Fields>;
};


/** aggregate fields of "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Komodor_Service_Static_Prevention_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Static_Prevention_State_Append_Input = {
  failedChecks?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "komodor_service_static_prevention_state". All fields are combined with a logical 'AND'. */
export type Komodor_Service_Static_Prevention_State_Bool_Exp = {
  _and?: Maybe<Array<Komodor_Service_Static_Prevention_State_Bool_Exp>>;
  _not?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
  _or?: Maybe<Array<Komodor_Service_Static_Prevention_State_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  cluster?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  failedChecks?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  komodor_service?: Maybe<Komodor_Service_Bool_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  results?: Maybe<Jsonb_Comparison_Exp>;
  resultsSummary?: Maybe<Jsonb_Comparison_Exp>;
  shortResourceName?: Maybe<String_Comparison_Exp>;
  sourceEventId?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  workflowConfiguration?: Maybe<Jsonb_Comparison_Exp>;
  workflowConfigurationId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "komodor_service_static_prevention_state" */
export enum Komodor_Service_Static_Prevention_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_0ba63e49d79daf79eae80079c3d = 'PK_0ba63e49d79daf79eae80079c3d'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Komodor_Service_Static_Prevention_State_Delete_At_Path_Input = {
  failedChecks?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<Scalars['String']>>;
  resultsSummary?: Maybe<Array<Scalars['String']>>;
  workflowConfiguration?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Komodor_Service_Static_Prevention_State_Delete_Elem_Input = {
  failedChecks?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['Int']>;
  resultsSummary?: Maybe<Scalars['Int']>;
  workflowConfiguration?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Komodor_Service_Static_Prevention_State_Delete_Key_Input = {
  failedChecks?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  resultsSummary?: Maybe<Scalars['String']>;
  workflowConfiguration?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failedChecks?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  komodor_service?: Maybe<Komodor_Service_Obj_Rel_Insert_Input>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Komodor_Service_Static_Prevention_State_Max_Fields = {
  __typename?: 'komodor_service_static_prevention_state_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Komodor_Service_Static_Prevention_State_Min_Fields = {
  __typename?: 'komodor_service_static_prevention_state_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Mutation_Response = {
  __typename?: 'komodor_service_static_prevention_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Komodor_Service_Static_Prevention_State>;
};

/** on_conflict condition type for table "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_On_Conflict = {
  constraint: Komodor_Service_Static_Prevention_State_Constraint;
  update_columns?: Array<Komodor_Service_Static_Prevention_State_Update_Column>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};

/** Ordering options when selecting data from "komodor_service_static_prevention_state". */
export type Komodor_Service_Static_Prevention_State_Order_By = {
  accountId?: Maybe<Order_By>;
  cluster?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  failedChecks?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodor_service?: Maybe<Komodor_Service_Order_By>;
  namespace?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  results?: Maybe<Order_By>;
  resultsSummary?: Maybe<Order_By>;
  shortResourceName?: Maybe<Order_By>;
  sourceEventId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  workflowConfiguration?: Maybe<Order_By>;
  workflowConfigurationId?: Maybe<Order_By>;
};

/** primary key columns input for table: komodor_service_static_prevention_state */
export type Komodor_Service_Static_Prevention_State_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Komodor_Service_Static_Prevention_State_Prepend_Input = {
  failedChecks?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "komodor_service_static_prevention_state" */
export enum Komodor_Service_Static_Prevention_State_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FailedChecks = 'failedChecks',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Results = 'results',
  /** column name */
  ResultsSummary = 'resultsSummary',
  /** column name */
  ShortResourceName = 'shortResourceName',
  /** column name */
  SourceEventId = 'sourceEventId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkflowConfiguration = 'workflowConfiguration',
  /** column name */
  WorkflowConfigurationId = 'workflowConfigurationId'
}

/** input type for updating data in table "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failedChecks?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "komodor_service_static_prevention_state" */
export type Komodor_Service_Static_Prevention_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Static_Prevention_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Static_Prevention_State_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  failedChecks?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** update columns of table "komodor_service_static_prevention_state" */
export enum Komodor_Service_Static_Prevention_State_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  FailedChecks = 'failedChecks',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Results = 'results',
  /** column name */
  ResultsSummary = 'resultsSummary',
  /** column name */
  ShortResourceName = 'shortResourceName',
  /** column name */
  SourceEventId = 'sourceEventId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkflowConfiguration = 'workflowConfiguration',
  /** column name */
  WorkflowConfigurationId = 'workflowConfigurationId'
}

export type Komodor_Service_Static_Prevention_State_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Komodor_Service_Static_Prevention_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Komodor_Service_Static_Prevention_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Komodor_Service_Static_Prevention_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Static_Prevention_State_Set_Input>;
  where: Komodor_Service_Static_Prevention_State_Bool_Exp;
};

/** Streaming cursor of the table "komodor_service" */
export type Komodor_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Komodor_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Komodor_Service_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentConfigId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  k8sClusterName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lastEventTime?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qos?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "komodor_service" */
export enum Komodor_Service_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentConfigId = 'currentConfigId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  K8sClusterName = 'k8sClusterName',
  /** column name */
  Kind = 'kind',
  /** column name */
  LastEventTime = 'lastEventTime',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Qos = 'qos',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Komodor_Service_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Komodor_Service_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Komodor_Service_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Komodor_Service_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Komodor_Service_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Komodor_Service_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Komodor_Service_Set_Input>;
  where: Komodor_Service_Bool_Exp;
};

/** columns and relationships of "kubernetes_agent_info" */
export type Kubernetes_Agent_Info = {
  __typename?: 'kubernetes_agent_info';
  /** An object relationship */
  account: Account;
  accountId: Scalars['uuid'];
  clusterName: Scalars['String'];
  configuration: Scalars['jsonb'];
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  inactive: Scalars['Boolean'];
  installationId: Scalars['uuid'];
  lastSeenAlive: Scalars['timestamp'];
  rbacResources?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "kubernetes_agent_info" */
export type Kubernetes_Agent_InfoConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "kubernetes_agent_info" */
export type Kubernetes_Agent_InfoRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Aggregate = {
  __typename?: 'kubernetes_agent_info_aggregate';
  aggregate?: Maybe<Kubernetes_Agent_Info_Aggregate_Fields>;
  nodes: Array<Kubernetes_Agent_Info>;
};

/** aggregate fields of "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Aggregate_Fields = {
  __typename?: 'kubernetes_agent_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kubernetes_Agent_Info_Max_Fields>;
  min?: Maybe<Kubernetes_Agent_Info_Min_Fields>;
};


/** aggregate fields of "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kubernetes_Agent_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Kubernetes_Agent_Info_Append_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "kubernetes_agent_info". All fields are combined with a logical 'AND'. */
export type Kubernetes_Agent_Info_Bool_Exp = {
  _and?: Maybe<Array<Kubernetes_Agent_Info_Bool_Exp>>;
  _not?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
  _or?: Maybe<Array<Kubernetes_Agent_Info_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  configuration?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inactive?: Maybe<Boolean_Comparison_Exp>;
  installationId?: Maybe<Uuid_Comparison_Exp>;
  lastSeenAlive?: Maybe<Timestamp_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "kubernetes_agent_info" */
export enum Kubernetes_Agent_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_0c792b34aa3f75fbb41d1e0e69c = 'PK_0c792b34aa3f75fbb41d1e0e69c'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Kubernetes_Agent_Info_Delete_At_Path_Input = {
  configuration?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Kubernetes_Agent_Info_Delete_Elem_Input = {
  configuration?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Kubernetes_Agent_Info_Delete_Key_Input = {
  configuration?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  inactive?: Maybe<Scalars['Boolean']>;
  installationId?: Maybe<Scalars['uuid']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Kubernetes_Agent_Info_Max_Fields = {
  __typename?: 'kubernetes_agent_info_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  installationId?: Maybe<Scalars['uuid']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Kubernetes_Agent_Info_Min_Fields = {
  __typename?: 'kubernetes_agent_info_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  installationId?: Maybe<Scalars['uuid']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Mutation_Response = {
  __typename?: 'kubernetes_agent_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kubernetes_Agent_Info>;
};

/** on_conflict condition type for table "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_On_Conflict = {
  constraint: Kubernetes_Agent_Info_Constraint;
  update_columns?: Array<Kubernetes_Agent_Info_Update_Column>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "kubernetes_agent_info". */
export type Kubernetes_Agent_Info_Order_By = {
  account?: Maybe<Account_Order_By>;
  accountId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  configuration?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inactive?: Maybe<Order_By>;
  installationId?: Maybe<Order_By>;
  lastSeenAlive?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
};

/** primary key columns input for table: kubernetes_agent_info */
export type Kubernetes_Agent_Info_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Kubernetes_Agent_Info_Prepend_Input = {
  configuration?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "kubernetes_agent_info" */
export enum Kubernetes_Agent_Info_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  InstallationId = 'installationId',
  /** column name */
  LastSeenAlive = 'lastSeenAlive',
  /** column name */
  RbacResources = 'rbacResources'
}

/** input type for updating data in table "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  inactive?: Maybe<Scalars['Boolean']>;
  installationId?: Maybe<Scalars['uuid']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "kubernetes_agent_info" */
export type Kubernetes_Agent_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kubernetes_Agent_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kubernetes_Agent_Info_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  clusterName?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  inactive?: Maybe<Scalars['Boolean']>;
  installationId?: Maybe<Scalars['uuid']>;
  lastSeenAlive?: Maybe<Scalars['timestamp']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "kubernetes_agent_info" */
export enum Kubernetes_Agent_Info_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  InstallationId = 'installationId',
  /** column name */
  LastSeenAlive = 'lastSeenAlive',
  /** column name */
  RbacResources = 'rbacResources'
}

export type Kubernetes_Agent_Info_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Kubernetes_Agent_Info_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Kubernetes_Agent_Info_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Kubernetes_Agent_Info_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Kubernetes_Agent_Info_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Kubernetes_Agent_Info_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Kubernetes_Agent_Info_Set_Input>;
  where: Kubernetes_Agent_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  closeMonitor?: Maybe<CloseMonitorOutput>;
  createGithubInstallation?: Maybe<Output>;
  createNewUserAccount?: Maybe<CreateAccountOutput>;
  createPagerDutyInstallation?: Maybe<CreatePagerDutyInstallationOutput>;
  createSlackInstallation?: Maybe<Output>;
  createUser?: Maybe<CreateUserOutput>;
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "account_domain_mapping" */
  delete_account_domain_mapping?: Maybe<Account_Domain_Mapping_Mutation_Response>;
  /** delete single row from the table: "account_domain_mapping" */
  delete_account_domain_mapping_by_pk?: Maybe<Account_Domain_Mapping>;
  /** delete data from the table: "account_event_limits" */
  delete_account_event_limits?: Maybe<Account_Event_Limits_Mutation_Response>;
  /** delete single row from the table: "account_event_limits" */
  delete_account_event_limits_by_pk?: Maybe<Account_Event_Limits>;
  /** delete data from the table: "agent_task" */
  delete_agent_task?: Maybe<Agent_Task_Mutation_Response>;
  /** delete single row from the table: "agent_task" */
  delete_agent_task_by_pk?: Maybe<Agent_Task>;
  /** delete data from the table: "agent_task_result" */
  delete_agent_task_result?: Maybe<Agent_Task_Result_Mutation_Response>;
  /** delete single row from the table: "agent_task_result" */
  delete_agent_task_result_by_pk?: Maybe<Agent_Task_Result>;
  /** delete data from the table: "api_key" */
  delete_api_key?: Maybe<Api_Key_Mutation_Response>;
  /** delete single row from the table: "api_key" */
  delete_api_key_by_pk?: Maybe<Api_Key>;
  /** delete data from the table: "app_view" */
  delete_app_view?: Maybe<App_View_Mutation_Response>;
  /** delete single row from the table: "app_view" */
  delete_app_view_by_pk?: Maybe<App_View>;
  /** delete data from the table: "audit" */
  delete_audit?: Maybe<Audit_Mutation_Response>;
  /** delete single row from the table: "audit" */
  delete_audit_by_pk?: Maybe<Audit>;
  /** delete data from the table: "channel_configuration" */
  delete_channel_configuration?: Maybe<Channel_Configuration_Mutation_Response>;
  /** delete single row from the table: "channel_configuration" */
  delete_channel_configuration_by_pk?: Maybe<Channel_Configuration>;
  /** delete data from the table: "event_actions" */
  delete_event_actions?: Maybe<Event_Actions_Mutation_Response>;
  /** delete single row from the table: "event_actions" */
  delete_event_actions_by_pk?: Maybe<Event_Actions>;
  /** delete data from the table: "event_config_map" */
  delete_event_config_map?: Maybe<Event_Config_Map_Mutation_Response>;
  /** delete single row from the table: "event_config_map" */
  delete_event_config_map_by_pk?: Maybe<Event_Config_Map>;
  /** delete data from the table: "event_cron_job" */
  delete_event_cron_job?: Maybe<Event_Cron_Job_Mutation_Response>;
  /** delete single row from the table: "event_cron_job" */
  delete_event_cron_job_by_pk?: Maybe<Event_Cron_Job>;
  /** delete data from the table: "event_datadog_monitor" */
  delete_event_datadog_monitor?: Maybe<Event_Datadog_Monitor_Mutation_Response>;
  /** delete single row from the table: "event_datadog_monitor" */
  delete_event_datadog_monitor_by_pk?: Maybe<Event_Datadog_Monitor>;
  /** delete data from the table: "event_datadog_tracing" */
  delete_event_datadog_tracing?: Maybe<Event_Datadog_Tracing_Mutation_Response>;
  /** delete single row from the table: "event_datadog_tracing" */
  delete_event_datadog_tracing_by_pk?: Maybe<Event_Datadog_Tracing>;
  /** delete data from the table: "event_deploy" */
  delete_event_deploy?: Maybe<Event_Deploy_Mutation_Response>;
  /** delete single row from the table: "event_deploy" */
  delete_event_deploy_by_pk?: Maybe<Event_Deploy>;
  /** delete data from the table: "event_hpa" */
  delete_event_hpa?: Maybe<Event_Hpa_Mutation_Response>;
  /** delete single row from the table: "event_hpa" */
  delete_event_hpa_by_pk?: Maybe<Event_Hpa>;
  /** delete data from the table: "event_ingress" */
  delete_event_ingress?: Maybe<Event_Ingress_Mutation_Response>;
  /** delete single row from the table: "event_ingress" */
  delete_event_ingress_by_pk?: Maybe<Event_Ingress>;
  /** delete data from the table: "event_job" */
  delete_event_job?: Maybe<Event_Job_Mutation_Response>;
  /** delete single row from the table: "event_job" */
  delete_event_job_by_pk?: Maybe<Event_Job>;
  /** delete data from the table: "event_komodor_alert" */
  delete_event_komodor_alert?: Maybe<Event_Komodor_Alert_Mutation_Response>;
  /** delete single row from the table: "event_komodor_alert" */
  delete_event_komodor_alert_by_pk?: Maybe<Event_Komodor_Alert>;
  /** delete data from the table: "event_kubernetes_service_resource" */
  delete_event_kubernetes_service_resource?: Maybe<Event_Kubernetes_Service_Resource_Mutation_Response>;
  /** delete single row from the table: "event_kubernetes_service_resource" */
  delete_event_kubernetes_service_resource_by_pk?: Maybe<Event_Kubernetes_Service_Resource>;
  /** delete data from the table: "event_launchdarkly" */
  delete_event_launchdarkly?: Maybe<Event_Launchdarkly_Mutation_Response>;
  /** delete single row from the table: "event_launchdarkly" */
  delete_event_launchdarkly_by_pk?: Maybe<Event_Launchdarkly>;
  /** delete data from the table: "event_limit_ranges" */
  delete_event_limit_ranges?: Maybe<Event_Limit_Ranges_Mutation_Response>;
  /** delete single row from the table: "event_limit_ranges" */
  delete_event_limit_ranges_by_pk?: Maybe<Event_Limit_Ranges>;
  /** delete data from the table: "event_monitor" */
  delete_event_monitor?: Maybe<Event_Monitor_Mutation_Response>;
  /** delete single row from the table: "event_monitor" */
  delete_event_monitor_by_pk?: Maybe<Event_Monitor>;
  /** delete data from the table: "event_network_policies" */
  delete_event_network_policies?: Maybe<Event_Network_Policies_Mutation_Response>;
  /** delete single row from the table: "event_network_policies" */
  delete_event_network_policies_by_pk?: Maybe<Event_Network_Policies>;
  /** delete data from the table: "event_node" */
  delete_event_node?: Maybe<Event_Node_Mutation_Response>;
  /** delete single row from the table: "event_node" */
  delete_event_node_by_pk?: Maybe<Event_Node>;
  /** delete data from the table: "event_opsgenie" */
  delete_event_opsgenie?: Maybe<Event_Opsgenie_Mutation_Response>;
  /** delete single row from the table: "event_opsgenie" */
  delete_event_opsgenie_by_pk?: Maybe<Event_Opsgenie>;
  /** delete data from the table: "event_pagerduty" */
  delete_event_pagerduty?: Maybe<Event_Pagerduty_Mutation_Response>;
  /** delete single row from the table: "event_pagerduty" */
  delete_event_pagerduty_by_pk?: Maybe<Event_Pagerduty>;
  /** delete data from the table: "event_pdb" */
  delete_event_pdb?: Maybe<Event_Pdb_Mutation_Response>;
  /** delete single row from the table: "event_pdb" */
  delete_event_pdb_by_pk?: Maybe<Event_Pdb>;
  /** delete data from the table: "event_resource_quota" */
  delete_event_resource_quota?: Maybe<Event_Resource_Quota_Mutation_Response>;
  /** delete single row from the table: "event_resource_quota" */
  delete_event_resource_quota_by_pk?: Maybe<Event_Resource_Quota>;
  /** delete data from the table: "event_secret" */
  delete_event_secret?: Maybe<Event_Secret_Mutation_Response>;
  /** delete single row from the table: "event_secret" */
  delete_event_secret_by_pk?: Maybe<Event_Secret>;
  /** delete data from the table: "event_sentry_issue" */
  delete_event_sentry_issue?: Maybe<Event_Sentry_Issue_Mutation_Response>;
  /** delete single row from the table: "event_sentry_issue" */
  delete_event_sentry_issue_by_pk?: Maybe<Event_Sentry_Issue>;
  /** delete data from the table: "event_stale_kubernetes_agent" */
  delete_event_stale_kubernetes_agent?: Maybe<Event_Stale_Kubernetes_Agent_Mutation_Response>;
  /** delete single row from the table: "event_stale_kubernetes_agent" */
  delete_event_stale_kubernetes_agent_by_pk?: Maybe<Event_Stale_Kubernetes_Agent>;
  /** delete data from the table: "event_topology" */
  delete_event_topology?: Maybe<Event_Topology_Mutation_Response>;
  /** delete single row from the table: "event_topology" */
  delete_event_topology_by_pk?: Maybe<Event_Topology>;
  /** delete data from the table: "event_workload" */
  delete_event_workload?: Maybe<Event_Workload_Mutation_Response>;
  /** delete single row from the table: "event_workload" */
  delete_event_workload_by_pk?: Maybe<Event_Workload>;
  /** delete data from the table: "external_link" */
  delete_external_link?: Maybe<External_Link_Mutation_Response>;
  /** delete single row from the table: "external_link" */
  delete_external_link_by_pk?: Maybe<External_Link>;
  /** delete data from the table: "external_link_context" */
  delete_external_link_context?: Maybe<External_Link_Context_Mutation_Response>;
  /** delete single row from the table: "external_link_context" */
  delete_external_link_context_by_pk?: Maybe<External_Link_Context>;
  /** delete data from the table: "helm_repo" */
  delete_helm_repo?: Maybe<Helm_Repo_Mutation_Response>;
  /** delete single row from the table: "helm_repo" */
  delete_helm_repo_by_pk?: Maybe<Helm_Repo>;
  /** delete data from the table: "installation" */
  delete_installation?: Maybe<Installation_Mutation_Response>;
  /** delete single row from the table: "installation" */
  delete_installation_by_pk?: Maybe<Installation>;
  /** delete data from the table: "komodor_filter_settings" */
  delete_komodor_filter_settings?: Maybe<Komodor_Filter_Settings_Mutation_Response>;
  /** delete single row from the table: "komodor_filter_settings" */
  delete_komodor_filter_settings_by_pk?: Maybe<Komodor_Filter_Settings>;
  /** delete data from the table: "komodor_service" */
  delete_komodor_service?: Maybe<Komodor_Service_Mutation_Response>;
  /** delete data from the table: "komodor_service_attributes" */
  delete_komodor_service_attributes?: Maybe<Komodor_Service_Attributes_Mutation_Response>;
  /** delete single row from the table: "komodor_service_attributes" */
  delete_komodor_service_attributes_by_pk?: Maybe<Komodor_Service_Attributes>;
  /** delete single row from the table: "komodor_service" */
  delete_komodor_service_by_pk?: Maybe<Komodor_Service>;
  /** delete data from the table: "komodor_service_datadog_data" */
  delete_komodor_service_datadog_data?: Maybe<Komodor_Service_Datadog_Data_Mutation_Response>;
  /** delete single row from the table: "komodor_service_datadog_data" */
  delete_komodor_service_datadog_data_by_pk?: Maybe<Komodor_Service_Datadog_Data>;
  /** delete data from the table: "komodor_service_deploy_state" */
  delete_komodor_service_deploy_state?: Maybe<Komodor_Service_Deploy_State_Mutation_Response>;
  /** delete single row from the table: "komodor_service_deploy_state" */
  delete_komodor_service_deploy_state_by_pk?: Maybe<Komodor_Service_Deploy_State>;
  /** delete data from the table: "komodor_service_health_state" */
  delete_komodor_service_health_state?: Maybe<Komodor_Service_Health_State_Mutation_Response>;
  /** delete single row from the table: "komodor_service_health_state" */
  delete_komodor_service_health_state_by_pk?: Maybe<Komodor_Service_Health_State>;
  /** delete data from the table: "komodor_service_k8s_metadata" */
  delete_komodor_service_k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Mutation_Response>;
  /** delete single row from the table: "komodor_service_k8s_metadata" */
  delete_komodor_service_k8s_metadata_by_pk?: Maybe<Komodor_Service_K8s_Metadata>;
  /** delete data from the table: "komodor_service_sentry_data" */
  delete_komodor_service_sentry_data?: Maybe<Komodor_Service_Sentry_Data_Mutation_Response>;
  /** delete single row from the table: "komodor_service_sentry_data" */
  delete_komodor_service_sentry_data_by_pk?: Maybe<Komodor_Service_Sentry_Data>;
  /** delete data from the table: "komodor_service_static_prevention_state" */
  delete_komodor_service_static_prevention_state?: Maybe<Komodor_Service_Static_Prevention_State_Mutation_Response>;
  /** delete single row from the table: "komodor_service_static_prevention_state" */
  delete_komodor_service_static_prevention_state_by_pk?: Maybe<Komodor_Service_Static_Prevention_State>;
  /** delete data from the table: "kubernetes_agent_info" */
  delete_kubernetes_agent_info?: Maybe<Kubernetes_Agent_Info_Mutation_Response>;
  /** delete single row from the table: "kubernetes_agent_info" */
  delete_kubernetes_agent_info_by_pk?: Maybe<Kubernetes_Agent_Info>;
  /** delete data from the table: "node_status_agent_task" */
  delete_node_status_agent_task?: Maybe<Node_Status_Agent_Task_Mutation_Response>;
  /** delete single row from the table: "node_status_agent_task" */
  delete_node_status_agent_task_by_pk?: Maybe<Node_Status_Agent_Task>;
  /** delete data from the table: "plan" */
  delete_plan?: Maybe<Plan_Mutation_Response>;
  /** delete single row from the table: "plan" */
  delete_plan_by_pk?: Maybe<Plan>;
  /** delete data from the table: "pod_list" */
  delete_pod_list?: Maybe<Pod_List_Mutation_Response>;
  /** delete single row from the table: "pod_list" */
  delete_pod_list_by_pk?: Maybe<Pod_List>;
  /** delete data from the table: "pod_logs" */
  delete_pod_logs?: Maybe<Pod_Logs_Mutation_Response>;
  /** delete single row from the table: "pod_logs" */
  delete_pod_logs_by_pk?: Maybe<Pod_Logs>;
  /** delete data from the table: "rbac_policy" */
  delete_rbac_policy?: Maybe<Rbac_Policy_Mutation_Response>;
  /** delete data from the table: "rbac_policy_action" */
  delete_rbac_policy_action?: Maybe<Rbac_Policy_Action_Mutation_Response>;
  /** delete single row from the table: "rbac_policy_action" */
  delete_rbac_policy_action_by_pk?: Maybe<Rbac_Policy_Action>;
  /** delete data from the table: "rbac_policy_action" */
  delete_rbac_policy_actions?: Maybe<Rbac_Policy_Actions_Mutation_Response>;
  /** delete single row from the table: "rbac_policy_action" */
  delete_rbac_policy_actions_by_pk?: Maybe<Rbac_Policy_Actions>;
  /** delete single row from the table: "rbac_policy" */
  delete_rbac_policy_by_pk?: Maybe<Rbac_Policy>;
  /** delete data from the table: "rbac_role" */
  delete_rbac_role?: Maybe<Rbac_Role_Mutation_Response>;
  /** delete single row from the table: "rbac_role" */
  delete_rbac_role_by_pk?: Maybe<Rbac_Role>;
  /** delete data from the table: "rbac_role_policy" */
  delete_rbac_role_policy?: Maybe<Rbac_Role_Policy_Mutation_Response>;
  /** delete single row from the table: "rbac_role_policy" */
  delete_rbac_role_policy_by_pk?: Maybe<Rbac_Role_Policy>;
  /** delete data from the table: "rbac_user_role" */
  delete_rbac_user_role?: Maybe<Rbac_User_Role_Mutation_Response>;
  /** delete single row from the table: "rbac_user_role" */
  delete_rbac_user_role_by_pk?: Maybe<Rbac_User_Role>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "service_attributes_for_account" */
  delete_service_attributes_for_account?: Maybe<Service_Attributes_For_Account_Mutation_Response>;
  /** delete single row from the table: "service_attributes_for_account" */
  delete_service_attributes_for_account_by_pk?: Maybe<Service_Attributes_For_Account>;
  /** delete data from the table: "service_description" */
  delete_service_description?: Maybe<Service_Description_Mutation_Response>;
  /** delete single row from the table: "service_description" */
  delete_service_description_by_pk?: Maybe<Service_Description>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "workflow_configuration" */
  delete_workflow_configuration?: Maybe<Workflow_Configuration_Mutation_Response>;
  /** delete single row from the table: "workflow_configuration" */
  delete_workflow_configuration_by_pk?: Maybe<Workflow_Configuration>;
  /** delete data from the table: "workflow_results" */
  delete_workflow_results?: Maybe<Workflow_Results_Mutation_Response>;
  /** delete single row from the table: "workflow_results" */
  delete_workflow_results_by_pk?: Maybe<Workflow_Results>;
  desktopSilentLogin?: Maybe<SilentLoginOutput>;
  desktopSilentSignup?: Maybe<SilentSignupOutput>;
  executeAgentTask?: Maybe<AgentTaskOutput>;
  fetchAllSlackChannels?: Maybe<FetchAllSlackChannelsOutput>;
  fetchJobsByPage?: Maybe<JobsByPageOutput>;
  fetchJobsFilters?: Maybe<JobsFiltersOutput>;
  fetchServicesByPage?: Maybe<ServicesByPageOutput>;
  fetchServicesFilters?: Maybe<ServicesFiltersOutput>;
  getResourcesMap?: Maybe<ResourcesMapOutput>;
  githubRevert?: Maybe<GithubRevertOutput>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert data into the table: "account_domain_mapping" */
  insert_account_domain_mapping?: Maybe<Account_Domain_Mapping_Mutation_Response>;
  /** insert a single row into the table: "account_domain_mapping" */
  insert_account_domain_mapping_one?: Maybe<Account_Domain_Mapping>;
  /** insert data into the table: "account_event_limits" */
  insert_account_event_limits?: Maybe<Account_Event_Limits_Mutation_Response>;
  /** insert a single row into the table: "account_event_limits" */
  insert_account_event_limits_one?: Maybe<Account_Event_Limits>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "agent_task" */
  insert_agent_task?: Maybe<Agent_Task_Mutation_Response>;
  /** insert a single row into the table: "agent_task" */
  insert_agent_task_one?: Maybe<Agent_Task>;
  /** insert data into the table: "agent_task_result" */
  insert_agent_task_result?: Maybe<Agent_Task_Result_Mutation_Response>;
  /** insert a single row into the table: "agent_task_result" */
  insert_agent_task_result_one?: Maybe<Agent_Task_Result>;
  /** insert data into the table: "api_key" */
  insert_api_key?: Maybe<Api_Key_Mutation_Response>;
  /** insert a single row into the table: "api_key" */
  insert_api_key_one?: Maybe<Api_Key>;
  /** insert data into the table: "app_view" */
  insert_app_view?: Maybe<App_View_Mutation_Response>;
  /** insert a single row into the table: "app_view" */
  insert_app_view_one?: Maybe<App_View>;
  /** insert data into the table: "audit" */
  insert_audit?: Maybe<Audit_Mutation_Response>;
  /** insert a single row into the table: "audit" */
  insert_audit_one?: Maybe<Audit>;
  /** insert data into the table: "channel_configuration" */
  insert_channel_configuration?: Maybe<Channel_Configuration_Mutation_Response>;
  /** insert a single row into the table: "channel_configuration" */
  insert_channel_configuration_one?: Maybe<Channel_Configuration>;
  /** insert data into the table: "event_actions" */
  insert_event_actions?: Maybe<Event_Actions_Mutation_Response>;
  /** insert a single row into the table: "event_actions" */
  insert_event_actions_one?: Maybe<Event_Actions>;
  /** insert data into the table: "event_config_map" */
  insert_event_config_map?: Maybe<Event_Config_Map_Mutation_Response>;
  /** insert a single row into the table: "event_config_map" */
  insert_event_config_map_one?: Maybe<Event_Config_Map>;
  /** insert data into the table: "event_cron_job" */
  insert_event_cron_job?: Maybe<Event_Cron_Job_Mutation_Response>;
  /** insert a single row into the table: "event_cron_job" */
  insert_event_cron_job_one?: Maybe<Event_Cron_Job>;
  /** insert data into the table: "event_datadog_monitor" */
  insert_event_datadog_monitor?: Maybe<Event_Datadog_Monitor_Mutation_Response>;
  /** insert a single row into the table: "event_datadog_monitor" */
  insert_event_datadog_monitor_one?: Maybe<Event_Datadog_Monitor>;
  /** insert data into the table: "event_datadog_tracing" */
  insert_event_datadog_tracing?: Maybe<Event_Datadog_Tracing_Mutation_Response>;
  /** insert a single row into the table: "event_datadog_tracing" */
  insert_event_datadog_tracing_one?: Maybe<Event_Datadog_Tracing>;
  /** insert data into the table: "event_deploy" */
  insert_event_deploy?: Maybe<Event_Deploy_Mutation_Response>;
  /** insert a single row into the table: "event_deploy" */
  insert_event_deploy_one?: Maybe<Event_Deploy>;
  /** insert data into the table: "event_hpa" */
  insert_event_hpa?: Maybe<Event_Hpa_Mutation_Response>;
  /** insert a single row into the table: "event_hpa" */
  insert_event_hpa_one?: Maybe<Event_Hpa>;
  /** insert data into the table: "event_ingress" */
  insert_event_ingress?: Maybe<Event_Ingress_Mutation_Response>;
  /** insert a single row into the table: "event_ingress" */
  insert_event_ingress_one?: Maybe<Event_Ingress>;
  /** insert data into the table: "event_job" */
  insert_event_job?: Maybe<Event_Job_Mutation_Response>;
  /** insert a single row into the table: "event_job" */
  insert_event_job_one?: Maybe<Event_Job>;
  /** insert data into the table: "event_komodor_alert" */
  insert_event_komodor_alert?: Maybe<Event_Komodor_Alert_Mutation_Response>;
  /** insert a single row into the table: "event_komodor_alert" */
  insert_event_komodor_alert_one?: Maybe<Event_Komodor_Alert>;
  /** insert data into the table: "event_kubernetes_service_resource" */
  insert_event_kubernetes_service_resource?: Maybe<Event_Kubernetes_Service_Resource_Mutation_Response>;
  /** insert a single row into the table: "event_kubernetes_service_resource" */
  insert_event_kubernetes_service_resource_one?: Maybe<Event_Kubernetes_Service_Resource>;
  /** insert data into the table: "event_launchdarkly" */
  insert_event_launchdarkly?: Maybe<Event_Launchdarkly_Mutation_Response>;
  /** insert a single row into the table: "event_launchdarkly" */
  insert_event_launchdarkly_one?: Maybe<Event_Launchdarkly>;
  /** insert data into the table: "event_limit_ranges" */
  insert_event_limit_ranges?: Maybe<Event_Limit_Ranges_Mutation_Response>;
  /** insert a single row into the table: "event_limit_ranges" */
  insert_event_limit_ranges_one?: Maybe<Event_Limit_Ranges>;
  /** insert data into the table: "event_monitor" */
  insert_event_monitor?: Maybe<Event_Monitor_Mutation_Response>;
  /** insert a single row into the table: "event_monitor" */
  insert_event_monitor_one?: Maybe<Event_Monitor>;
  /** insert data into the table: "event_network_policies" */
  insert_event_network_policies?: Maybe<Event_Network_Policies_Mutation_Response>;
  /** insert a single row into the table: "event_network_policies" */
  insert_event_network_policies_one?: Maybe<Event_Network_Policies>;
  /** insert data into the table: "event_node" */
  insert_event_node?: Maybe<Event_Node_Mutation_Response>;
  /** insert a single row into the table: "event_node" */
  insert_event_node_one?: Maybe<Event_Node>;
  /** insert data into the table: "event_opsgenie" */
  insert_event_opsgenie?: Maybe<Event_Opsgenie_Mutation_Response>;
  /** insert a single row into the table: "event_opsgenie" */
  insert_event_opsgenie_one?: Maybe<Event_Opsgenie>;
  /** insert data into the table: "event_pagerduty" */
  insert_event_pagerduty?: Maybe<Event_Pagerduty_Mutation_Response>;
  /** insert a single row into the table: "event_pagerduty" */
  insert_event_pagerduty_one?: Maybe<Event_Pagerduty>;
  /** insert data into the table: "event_pdb" */
  insert_event_pdb?: Maybe<Event_Pdb_Mutation_Response>;
  /** insert a single row into the table: "event_pdb" */
  insert_event_pdb_one?: Maybe<Event_Pdb>;
  /** insert data into the table: "event_resource_quota" */
  insert_event_resource_quota?: Maybe<Event_Resource_Quota_Mutation_Response>;
  /** insert a single row into the table: "event_resource_quota" */
  insert_event_resource_quota_one?: Maybe<Event_Resource_Quota>;
  /** insert data into the table: "event_secret" */
  insert_event_secret?: Maybe<Event_Secret_Mutation_Response>;
  /** insert a single row into the table: "event_secret" */
  insert_event_secret_one?: Maybe<Event_Secret>;
  /** insert data into the table: "event_sentry_issue" */
  insert_event_sentry_issue?: Maybe<Event_Sentry_Issue_Mutation_Response>;
  /** insert a single row into the table: "event_sentry_issue" */
  insert_event_sentry_issue_one?: Maybe<Event_Sentry_Issue>;
  /** insert data into the table: "event_stale_kubernetes_agent" */
  insert_event_stale_kubernetes_agent?: Maybe<Event_Stale_Kubernetes_Agent_Mutation_Response>;
  /** insert a single row into the table: "event_stale_kubernetes_agent" */
  insert_event_stale_kubernetes_agent_one?: Maybe<Event_Stale_Kubernetes_Agent>;
  /** insert data into the table: "event_topology" */
  insert_event_topology?: Maybe<Event_Topology_Mutation_Response>;
  /** insert a single row into the table: "event_topology" */
  insert_event_topology_one?: Maybe<Event_Topology>;
  /** insert data into the table: "event_workload" */
  insert_event_workload?: Maybe<Event_Workload_Mutation_Response>;
  /** insert a single row into the table: "event_workload" */
  insert_event_workload_one?: Maybe<Event_Workload>;
  /** insert data into the table: "external_link" */
  insert_external_link?: Maybe<External_Link_Mutation_Response>;
  /** insert data into the table: "external_link_context" */
  insert_external_link_context?: Maybe<External_Link_Context_Mutation_Response>;
  /** insert a single row into the table: "external_link_context" */
  insert_external_link_context_one?: Maybe<External_Link_Context>;
  /** insert a single row into the table: "external_link" */
  insert_external_link_one?: Maybe<External_Link>;
  /** insert data into the table: "helm_repo" */
  insert_helm_repo?: Maybe<Helm_Repo_Mutation_Response>;
  /** insert a single row into the table: "helm_repo" */
  insert_helm_repo_one?: Maybe<Helm_Repo>;
  /** insert data into the table: "installation" */
  insert_installation?: Maybe<Installation_Mutation_Response>;
  /** insert a single row into the table: "installation" */
  insert_installation_one?: Maybe<Installation>;
  /** insert data into the table: "komodor_filter_settings" */
  insert_komodor_filter_settings?: Maybe<Komodor_Filter_Settings_Mutation_Response>;
  /** insert a single row into the table: "komodor_filter_settings" */
  insert_komodor_filter_settings_one?: Maybe<Komodor_Filter_Settings>;
  /** insert data into the table: "komodor_service" */
  insert_komodor_service?: Maybe<Komodor_Service_Mutation_Response>;
  /** insert data into the table: "komodor_service_attributes" */
  insert_komodor_service_attributes?: Maybe<Komodor_Service_Attributes_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_attributes" */
  insert_komodor_service_attributes_one?: Maybe<Komodor_Service_Attributes>;
  /** insert data into the table: "komodor_service_datadog_data" */
  insert_komodor_service_datadog_data?: Maybe<Komodor_Service_Datadog_Data_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_datadog_data" */
  insert_komodor_service_datadog_data_one?: Maybe<Komodor_Service_Datadog_Data>;
  /** insert data into the table: "komodor_service_deploy_state" */
  insert_komodor_service_deploy_state?: Maybe<Komodor_Service_Deploy_State_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_deploy_state" */
  insert_komodor_service_deploy_state_one?: Maybe<Komodor_Service_Deploy_State>;
  /** insert data into the table: "komodor_service_health_state" */
  insert_komodor_service_health_state?: Maybe<Komodor_Service_Health_State_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_health_state" */
  insert_komodor_service_health_state_one?: Maybe<Komodor_Service_Health_State>;
  /** insert data into the table: "komodor_service_k8s_metadata" */
  insert_komodor_service_k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_k8s_metadata" */
  insert_komodor_service_k8s_metadata_one?: Maybe<Komodor_Service_K8s_Metadata>;
  /** insert a single row into the table: "komodor_service" */
  insert_komodor_service_one?: Maybe<Komodor_Service>;
  /** insert data into the table: "komodor_service_sentry_data" */
  insert_komodor_service_sentry_data?: Maybe<Komodor_Service_Sentry_Data_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_sentry_data" */
  insert_komodor_service_sentry_data_one?: Maybe<Komodor_Service_Sentry_Data>;
  /** insert data into the table: "komodor_service_static_prevention_state" */
  insert_komodor_service_static_prevention_state?: Maybe<Komodor_Service_Static_Prevention_State_Mutation_Response>;
  /** insert a single row into the table: "komodor_service_static_prevention_state" */
  insert_komodor_service_static_prevention_state_one?: Maybe<Komodor_Service_Static_Prevention_State>;
  /** insert data into the table: "kubernetes_agent_info" */
  insert_kubernetes_agent_info?: Maybe<Kubernetes_Agent_Info_Mutation_Response>;
  /** insert a single row into the table: "kubernetes_agent_info" */
  insert_kubernetes_agent_info_one?: Maybe<Kubernetes_Agent_Info>;
  /** insert data into the table: "node_status_agent_task" */
  insert_node_status_agent_task?: Maybe<Node_Status_Agent_Task_Mutation_Response>;
  /** insert a single row into the table: "node_status_agent_task" */
  insert_node_status_agent_task_one?: Maybe<Node_Status_Agent_Task>;
  /** insert data into the table: "plan" */
  insert_plan?: Maybe<Plan_Mutation_Response>;
  /** insert a single row into the table: "plan" */
  insert_plan_one?: Maybe<Plan>;
  /** insert data into the table: "pod_list" */
  insert_pod_list?: Maybe<Pod_List_Mutation_Response>;
  /** insert a single row into the table: "pod_list" */
  insert_pod_list_one?: Maybe<Pod_List>;
  /** insert data into the table: "pod_logs" */
  insert_pod_logs?: Maybe<Pod_Logs_Mutation_Response>;
  /** insert a single row into the table: "pod_logs" */
  insert_pod_logs_one?: Maybe<Pod_Logs>;
  /** insert data into the table: "rbac_policy" */
  insert_rbac_policy?: Maybe<Rbac_Policy_Mutation_Response>;
  /** insert data into the table: "rbac_policy_action" */
  insert_rbac_policy_action?: Maybe<Rbac_Policy_Action_Mutation_Response>;
  /** insert a single row into the table: "rbac_policy_action" */
  insert_rbac_policy_action_one?: Maybe<Rbac_Policy_Action>;
  /** insert data into the table: "rbac_policy_action" */
  insert_rbac_policy_actions?: Maybe<Rbac_Policy_Actions_Mutation_Response>;
  /** insert a single row into the table: "rbac_policy_action" */
  insert_rbac_policy_actions_one?: Maybe<Rbac_Policy_Actions>;
  /** insert a single row into the table: "rbac_policy" */
  insert_rbac_policy_one?: Maybe<Rbac_Policy>;
  /** insert data into the table: "rbac_role" */
  insert_rbac_role?: Maybe<Rbac_Role_Mutation_Response>;
  /** insert a single row into the table: "rbac_role" */
  insert_rbac_role_one?: Maybe<Rbac_Role>;
  /** insert data into the table: "rbac_role_policy" */
  insert_rbac_role_policy?: Maybe<Rbac_Role_Policy_Mutation_Response>;
  /** insert a single row into the table: "rbac_role_policy" */
  insert_rbac_role_policy_one?: Maybe<Rbac_Role_Policy>;
  /** insert data into the table: "rbac_user_role" */
  insert_rbac_user_role?: Maybe<Rbac_User_Role_Mutation_Response>;
  /** insert a single row into the table: "rbac_user_role" */
  insert_rbac_user_role_one?: Maybe<Rbac_User_Role>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "service_attributes_for_account" */
  insert_service_attributes_for_account?: Maybe<Service_Attributes_For_Account_Mutation_Response>;
  /** insert a single row into the table: "service_attributes_for_account" */
  insert_service_attributes_for_account_one?: Maybe<Service_Attributes_For_Account>;
  /** insert data into the table: "service_description" */
  insert_service_description?: Maybe<Service_Description_Mutation_Response>;
  /** insert a single row into the table: "service_description" */
  insert_service_description_one?: Maybe<Service_Description>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "workflow_configuration" */
  insert_workflow_configuration?: Maybe<Workflow_Configuration_Mutation_Response>;
  /** insert a single row into the table: "workflow_configuration" */
  insert_workflow_configuration_one?: Maybe<Workflow_Configuration>;
  /** insert data into the table: "workflow_results" */
  insert_workflow_results?: Maybe<Workflow_Results_Mutation_Response>;
  /** insert a single row into the table: "workflow_results" */
  insert_workflow_results_one?: Maybe<Workflow_Results>;
  resendEmailVerification?: Maybe<ResendEmailVerificationOutput>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update data of the table: "account_domain_mapping" */
  update_account_domain_mapping?: Maybe<Account_Domain_Mapping_Mutation_Response>;
  /** update single row of the table: "account_domain_mapping" */
  update_account_domain_mapping_by_pk?: Maybe<Account_Domain_Mapping>;
  /** update multiples rows of table: "account_domain_mapping" */
  update_account_domain_mapping_many?: Maybe<Array<Maybe<Account_Domain_Mapping_Mutation_Response>>>;
  /** update data of the table: "account_event_limits" */
  update_account_event_limits?: Maybe<Account_Event_Limits_Mutation_Response>;
  /** update single row of the table: "account_event_limits" */
  update_account_event_limits_by_pk?: Maybe<Account_Event_Limits>;
  /** update multiples rows of table: "account_event_limits" */
  update_account_event_limits_many?: Maybe<Array<Maybe<Account_Event_Limits_Mutation_Response>>>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<Account_Mutation_Response>>>;
  /** update data of the table: "agent_task" */
  update_agent_task?: Maybe<Agent_Task_Mutation_Response>;
  /** update single row of the table: "agent_task" */
  update_agent_task_by_pk?: Maybe<Agent_Task>;
  /** update multiples rows of table: "agent_task" */
  update_agent_task_many?: Maybe<Array<Maybe<Agent_Task_Mutation_Response>>>;
  /** update data of the table: "agent_task_result" */
  update_agent_task_result?: Maybe<Agent_Task_Result_Mutation_Response>;
  /** update single row of the table: "agent_task_result" */
  update_agent_task_result_by_pk?: Maybe<Agent_Task_Result>;
  /** update multiples rows of table: "agent_task_result" */
  update_agent_task_result_many?: Maybe<Array<Maybe<Agent_Task_Result_Mutation_Response>>>;
  /** update data of the table: "api_key" */
  update_api_key?: Maybe<Api_Key_Mutation_Response>;
  /** update single row of the table: "api_key" */
  update_api_key_by_pk?: Maybe<Api_Key>;
  /** update multiples rows of table: "api_key" */
  update_api_key_many?: Maybe<Array<Maybe<Api_Key_Mutation_Response>>>;
  /** update data of the table: "app_view" */
  update_app_view?: Maybe<App_View_Mutation_Response>;
  /** update single row of the table: "app_view" */
  update_app_view_by_pk?: Maybe<App_View>;
  /** update multiples rows of table: "app_view" */
  update_app_view_many?: Maybe<Array<Maybe<App_View_Mutation_Response>>>;
  /** update data of the table: "audit" */
  update_audit?: Maybe<Audit_Mutation_Response>;
  /** update single row of the table: "audit" */
  update_audit_by_pk?: Maybe<Audit>;
  /** update multiples rows of table: "audit" */
  update_audit_many?: Maybe<Array<Maybe<Audit_Mutation_Response>>>;
  /** update data of the table: "channel_configuration" */
  update_channel_configuration?: Maybe<Channel_Configuration_Mutation_Response>;
  /** update single row of the table: "channel_configuration" */
  update_channel_configuration_by_pk?: Maybe<Channel_Configuration>;
  /** update multiples rows of table: "channel_configuration" */
  update_channel_configuration_many?: Maybe<Array<Maybe<Channel_Configuration_Mutation_Response>>>;
  /** update data of the table: "event_actions" */
  update_event_actions?: Maybe<Event_Actions_Mutation_Response>;
  /** update single row of the table: "event_actions" */
  update_event_actions_by_pk?: Maybe<Event_Actions>;
  /** update multiples rows of table: "event_actions" */
  update_event_actions_many?: Maybe<Array<Maybe<Event_Actions_Mutation_Response>>>;
  /** update data of the table: "event_config_map" */
  update_event_config_map?: Maybe<Event_Config_Map_Mutation_Response>;
  /** update single row of the table: "event_config_map" */
  update_event_config_map_by_pk?: Maybe<Event_Config_Map>;
  /** update multiples rows of table: "event_config_map" */
  update_event_config_map_many?: Maybe<Array<Maybe<Event_Config_Map_Mutation_Response>>>;
  /** update data of the table: "event_cron_job" */
  update_event_cron_job?: Maybe<Event_Cron_Job_Mutation_Response>;
  /** update single row of the table: "event_cron_job" */
  update_event_cron_job_by_pk?: Maybe<Event_Cron_Job>;
  /** update multiples rows of table: "event_cron_job" */
  update_event_cron_job_many?: Maybe<Array<Maybe<Event_Cron_Job_Mutation_Response>>>;
  /** update data of the table: "event_datadog_monitor" */
  update_event_datadog_monitor?: Maybe<Event_Datadog_Monitor_Mutation_Response>;
  /** update single row of the table: "event_datadog_monitor" */
  update_event_datadog_monitor_by_pk?: Maybe<Event_Datadog_Monitor>;
  /** update multiples rows of table: "event_datadog_monitor" */
  update_event_datadog_monitor_many?: Maybe<Array<Maybe<Event_Datadog_Monitor_Mutation_Response>>>;
  /** update data of the table: "event_datadog_tracing" */
  update_event_datadog_tracing?: Maybe<Event_Datadog_Tracing_Mutation_Response>;
  /** update single row of the table: "event_datadog_tracing" */
  update_event_datadog_tracing_by_pk?: Maybe<Event_Datadog_Tracing>;
  /** update multiples rows of table: "event_datadog_tracing" */
  update_event_datadog_tracing_many?: Maybe<Array<Maybe<Event_Datadog_Tracing_Mutation_Response>>>;
  /** update data of the table: "event_deploy" */
  update_event_deploy?: Maybe<Event_Deploy_Mutation_Response>;
  /** update single row of the table: "event_deploy" */
  update_event_deploy_by_pk?: Maybe<Event_Deploy>;
  /** update multiples rows of table: "event_deploy" */
  update_event_deploy_many?: Maybe<Array<Maybe<Event_Deploy_Mutation_Response>>>;
  /** update data of the table: "event_hpa" */
  update_event_hpa?: Maybe<Event_Hpa_Mutation_Response>;
  /** update single row of the table: "event_hpa" */
  update_event_hpa_by_pk?: Maybe<Event_Hpa>;
  /** update multiples rows of table: "event_hpa" */
  update_event_hpa_many?: Maybe<Array<Maybe<Event_Hpa_Mutation_Response>>>;
  /** update data of the table: "event_ingress" */
  update_event_ingress?: Maybe<Event_Ingress_Mutation_Response>;
  /** update single row of the table: "event_ingress" */
  update_event_ingress_by_pk?: Maybe<Event_Ingress>;
  /** update multiples rows of table: "event_ingress" */
  update_event_ingress_many?: Maybe<Array<Maybe<Event_Ingress_Mutation_Response>>>;
  /** update data of the table: "event_job" */
  update_event_job?: Maybe<Event_Job_Mutation_Response>;
  /** update single row of the table: "event_job" */
  update_event_job_by_pk?: Maybe<Event_Job>;
  /** update multiples rows of table: "event_job" */
  update_event_job_many?: Maybe<Array<Maybe<Event_Job_Mutation_Response>>>;
  /** update data of the table: "event_komodor_alert" */
  update_event_komodor_alert?: Maybe<Event_Komodor_Alert_Mutation_Response>;
  /** update single row of the table: "event_komodor_alert" */
  update_event_komodor_alert_by_pk?: Maybe<Event_Komodor_Alert>;
  /** update multiples rows of table: "event_komodor_alert" */
  update_event_komodor_alert_many?: Maybe<Array<Maybe<Event_Komodor_Alert_Mutation_Response>>>;
  /** update data of the table: "event_kubernetes_service_resource" */
  update_event_kubernetes_service_resource?: Maybe<Event_Kubernetes_Service_Resource_Mutation_Response>;
  /** update single row of the table: "event_kubernetes_service_resource" */
  update_event_kubernetes_service_resource_by_pk?: Maybe<Event_Kubernetes_Service_Resource>;
  /** update multiples rows of table: "event_kubernetes_service_resource" */
  update_event_kubernetes_service_resource_many?: Maybe<Array<Maybe<Event_Kubernetes_Service_Resource_Mutation_Response>>>;
  /** update data of the table: "event_launchdarkly" */
  update_event_launchdarkly?: Maybe<Event_Launchdarkly_Mutation_Response>;
  /** update single row of the table: "event_launchdarkly" */
  update_event_launchdarkly_by_pk?: Maybe<Event_Launchdarkly>;
  /** update multiples rows of table: "event_launchdarkly" */
  update_event_launchdarkly_many?: Maybe<Array<Maybe<Event_Launchdarkly_Mutation_Response>>>;
  /** update data of the table: "event_limit_ranges" */
  update_event_limit_ranges?: Maybe<Event_Limit_Ranges_Mutation_Response>;
  /** update single row of the table: "event_limit_ranges" */
  update_event_limit_ranges_by_pk?: Maybe<Event_Limit_Ranges>;
  /** update multiples rows of table: "event_limit_ranges" */
  update_event_limit_ranges_many?: Maybe<Array<Maybe<Event_Limit_Ranges_Mutation_Response>>>;
  /** update data of the table: "event_monitor" */
  update_event_monitor?: Maybe<Event_Monitor_Mutation_Response>;
  /** update single row of the table: "event_monitor" */
  update_event_monitor_by_pk?: Maybe<Event_Monitor>;
  /** update multiples rows of table: "event_monitor" */
  update_event_monitor_many?: Maybe<Array<Maybe<Event_Monitor_Mutation_Response>>>;
  /** update data of the table: "event_network_policies" */
  update_event_network_policies?: Maybe<Event_Network_Policies_Mutation_Response>;
  /** update single row of the table: "event_network_policies" */
  update_event_network_policies_by_pk?: Maybe<Event_Network_Policies>;
  /** update multiples rows of table: "event_network_policies" */
  update_event_network_policies_many?: Maybe<Array<Maybe<Event_Network_Policies_Mutation_Response>>>;
  /** update data of the table: "event_node" */
  update_event_node?: Maybe<Event_Node_Mutation_Response>;
  /** update single row of the table: "event_node" */
  update_event_node_by_pk?: Maybe<Event_Node>;
  /** update multiples rows of table: "event_node" */
  update_event_node_many?: Maybe<Array<Maybe<Event_Node_Mutation_Response>>>;
  /** update data of the table: "event_opsgenie" */
  update_event_opsgenie?: Maybe<Event_Opsgenie_Mutation_Response>;
  /** update single row of the table: "event_opsgenie" */
  update_event_opsgenie_by_pk?: Maybe<Event_Opsgenie>;
  /** update multiples rows of table: "event_opsgenie" */
  update_event_opsgenie_many?: Maybe<Array<Maybe<Event_Opsgenie_Mutation_Response>>>;
  /** update data of the table: "event_pagerduty" */
  update_event_pagerduty?: Maybe<Event_Pagerduty_Mutation_Response>;
  /** update single row of the table: "event_pagerduty" */
  update_event_pagerduty_by_pk?: Maybe<Event_Pagerduty>;
  /** update multiples rows of table: "event_pagerduty" */
  update_event_pagerduty_many?: Maybe<Array<Maybe<Event_Pagerduty_Mutation_Response>>>;
  /** update data of the table: "event_pdb" */
  update_event_pdb?: Maybe<Event_Pdb_Mutation_Response>;
  /** update single row of the table: "event_pdb" */
  update_event_pdb_by_pk?: Maybe<Event_Pdb>;
  /** update multiples rows of table: "event_pdb" */
  update_event_pdb_many?: Maybe<Array<Maybe<Event_Pdb_Mutation_Response>>>;
  /** update data of the table: "event_resource_quota" */
  update_event_resource_quota?: Maybe<Event_Resource_Quota_Mutation_Response>;
  /** update single row of the table: "event_resource_quota" */
  update_event_resource_quota_by_pk?: Maybe<Event_Resource_Quota>;
  /** update multiples rows of table: "event_resource_quota" */
  update_event_resource_quota_many?: Maybe<Array<Maybe<Event_Resource_Quota_Mutation_Response>>>;
  /** update data of the table: "event_secret" */
  update_event_secret?: Maybe<Event_Secret_Mutation_Response>;
  /** update single row of the table: "event_secret" */
  update_event_secret_by_pk?: Maybe<Event_Secret>;
  /** update multiples rows of table: "event_secret" */
  update_event_secret_many?: Maybe<Array<Maybe<Event_Secret_Mutation_Response>>>;
  /** update data of the table: "event_sentry_issue" */
  update_event_sentry_issue?: Maybe<Event_Sentry_Issue_Mutation_Response>;
  /** update single row of the table: "event_sentry_issue" */
  update_event_sentry_issue_by_pk?: Maybe<Event_Sentry_Issue>;
  /** update multiples rows of table: "event_sentry_issue" */
  update_event_sentry_issue_many?: Maybe<Array<Maybe<Event_Sentry_Issue_Mutation_Response>>>;
  /** update data of the table: "event_stale_kubernetes_agent" */
  update_event_stale_kubernetes_agent?: Maybe<Event_Stale_Kubernetes_Agent_Mutation_Response>;
  /** update single row of the table: "event_stale_kubernetes_agent" */
  update_event_stale_kubernetes_agent_by_pk?: Maybe<Event_Stale_Kubernetes_Agent>;
  /** update multiples rows of table: "event_stale_kubernetes_agent" */
  update_event_stale_kubernetes_agent_many?: Maybe<Array<Maybe<Event_Stale_Kubernetes_Agent_Mutation_Response>>>;
  /** update data of the table: "event_topology" */
  update_event_topology?: Maybe<Event_Topology_Mutation_Response>;
  /** update single row of the table: "event_topology" */
  update_event_topology_by_pk?: Maybe<Event_Topology>;
  /** update multiples rows of table: "event_topology" */
  update_event_topology_many?: Maybe<Array<Maybe<Event_Topology_Mutation_Response>>>;
  /** update data of the table: "event_workload" */
  update_event_workload?: Maybe<Event_Workload_Mutation_Response>;
  /** update single row of the table: "event_workload" */
  update_event_workload_by_pk?: Maybe<Event_Workload>;
  /** update multiples rows of table: "event_workload" */
  update_event_workload_many?: Maybe<Array<Maybe<Event_Workload_Mutation_Response>>>;
  /** update data of the table: "external_link" */
  update_external_link?: Maybe<External_Link_Mutation_Response>;
  /** update single row of the table: "external_link" */
  update_external_link_by_pk?: Maybe<External_Link>;
  /** update data of the table: "external_link_context" */
  update_external_link_context?: Maybe<External_Link_Context_Mutation_Response>;
  /** update single row of the table: "external_link_context" */
  update_external_link_context_by_pk?: Maybe<External_Link_Context>;
  /** update multiples rows of table: "external_link_context" */
  update_external_link_context_many?: Maybe<Array<Maybe<External_Link_Context_Mutation_Response>>>;
  /** update multiples rows of table: "external_link" */
  update_external_link_many?: Maybe<Array<Maybe<External_Link_Mutation_Response>>>;
  /** update data of the table: "helm_repo" */
  update_helm_repo?: Maybe<Helm_Repo_Mutation_Response>;
  /** update single row of the table: "helm_repo" */
  update_helm_repo_by_pk?: Maybe<Helm_Repo>;
  /** update multiples rows of table: "helm_repo" */
  update_helm_repo_many?: Maybe<Array<Maybe<Helm_Repo_Mutation_Response>>>;
  /** update data of the table: "installation" */
  update_installation?: Maybe<Installation_Mutation_Response>;
  /** update single row of the table: "installation" */
  update_installation_by_pk?: Maybe<Installation>;
  /** update multiples rows of table: "installation" */
  update_installation_many?: Maybe<Array<Maybe<Installation_Mutation_Response>>>;
  /** update data of the table: "komodor_filter_settings" */
  update_komodor_filter_settings?: Maybe<Komodor_Filter_Settings_Mutation_Response>;
  /** update single row of the table: "komodor_filter_settings" */
  update_komodor_filter_settings_by_pk?: Maybe<Komodor_Filter_Settings>;
  /** update multiples rows of table: "komodor_filter_settings" */
  update_komodor_filter_settings_many?: Maybe<Array<Maybe<Komodor_Filter_Settings_Mutation_Response>>>;
  /** update data of the table: "komodor_service" */
  update_komodor_service?: Maybe<Komodor_Service_Mutation_Response>;
  /** update data of the table: "komodor_service_attributes" */
  update_komodor_service_attributes?: Maybe<Komodor_Service_Attributes_Mutation_Response>;
  /** update single row of the table: "komodor_service_attributes" */
  update_komodor_service_attributes_by_pk?: Maybe<Komodor_Service_Attributes>;
  /** update multiples rows of table: "komodor_service_attributes" */
  update_komodor_service_attributes_many?: Maybe<Array<Maybe<Komodor_Service_Attributes_Mutation_Response>>>;
  /** update single row of the table: "komodor_service" */
  update_komodor_service_by_pk?: Maybe<Komodor_Service>;
  /** update data of the table: "komodor_service_datadog_data" */
  update_komodor_service_datadog_data?: Maybe<Komodor_Service_Datadog_Data_Mutation_Response>;
  /** update single row of the table: "komodor_service_datadog_data" */
  update_komodor_service_datadog_data_by_pk?: Maybe<Komodor_Service_Datadog_Data>;
  /** update multiples rows of table: "komodor_service_datadog_data" */
  update_komodor_service_datadog_data_many?: Maybe<Array<Maybe<Komodor_Service_Datadog_Data_Mutation_Response>>>;
  /** update data of the table: "komodor_service_deploy_state" */
  update_komodor_service_deploy_state?: Maybe<Komodor_Service_Deploy_State_Mutation_Response>;
  /** update single row of the table: "komodor_service_deploy_state" */
  update_komodor_service_deploy_state_by_pk?: Maybe<Komodor_Service_Deploy_State>;
  /** update multiples rows of table: "komodor_service_deploy_state" */
  update_komodor_service_deploy_state_many?: Maybe<Array<Maybe<Komodor_Service_Deploy_State_Mutation_Response>>>;
  /** update data of the table: "komodor_service_health_state" */
  update_komodor_service_health_state?: Maybe<Komodor_Service_Health_State_Mutation_Response>;
  /** update single row of the table: "komodor_service_health_state" */
  update_komodor_service_health_state_by_pk?: Maybe<Komodor_Service_Health_State>;
  /** update multiples rows of table: "komodor_service_health_state" */
  update_komodor_service_health_state_many?: Maybe<Array<Maybe<Komodor_Service_Health_State_Mutation_Response>>>;
  /** update data of the table: "komodor_service_k8s_metadata" */
  update_komodor_service_k8s_metadata?: Maybe<Komodor_Service_K8s_Metadata_Mutation_Response>;
  /** update single row of the table: "komodor_service_k8s_metadata" */
  update_komodor_service_k8s_metadata_by_pk?: Maybe<Komodor_Service_K8s_Metadata>;
  /** update multiples rows of table: "komodor_service_k8s_metadata" */
  update_komodor_service_k8s_metadata_many?: Maybe<Array<Maybe<Komodor_Service_K8s_Metadata_Mutation_Response>>>;
  /** update multiples rows of table: "komodor_service" */
  update_komodor_service_many?: Maybe<Array<Maybe<Komodor_Service_Mutation_Response>>>;
  /** update data of the table: "komodor_service_sentry_data" */
  update_komodor_service_sentry_data?: Maybe<Komodor_Service_Sentry_Data_Mutation_Response>;
  /** update single row of the table: "komodor_service_sentry_data" */
  update_komodor_service_sentry_data_by_pk?: Maybe<Komodor_Service_Sentry_Data>;
  /** update multiples rows of table: "komodor_service_sentry_data" */
  update_komodor_service_sentry_data_many?: Maybe<Array<Maybe<Komodor_Service_Sentry_Data_Mutation_Response>>>;
  /** update data of the table: "komodor_service_static_prevention_state" */
  update_komodor_service_static_prevention_state?: Maybe<Komodor_Service_Static_Prevention_State_Mutation_Response>;
  /** update single row of the table: "komodor_service_static_prevention_state" */
  update_komodor_service_static_prevention_state_by_pk?: Maybe<Komodor_Service_Static_Prevention_State>;
  /** update multiples rows of table: "komodor_service_static_prevention_state" */
  update_komodor_service_static_prevention_state_many?: Maybe<Array<Maybe<Komodor_Service_Static_Prevention_State_Mutation_Response>>>;
  /** update data of the table: "kubernetes_agent_info" */
  update_kubernetes_agent_info?: Maybe<Kubernetes_Agent_Info_Mutation_Response>;
  /** update single row of the table: "kubernetes_agent_info" */
  update_kubernetes_agent_info_by_pk?: Maybe<Kubernetes_Agent_Info>;
  /** update multiples rows of table: "kubernetes_agent_info" */
  update_kubernetes_agent_info_many?: Maybe<Array<Maybe<Kubernetes_Agent_Info_Mutation_Response>>>;
  /** update data of the table: "node_status_agent_task" */
  update_node_status_agent_task?: Maybe<Node_Status_Agent_Task_Mutation_Response>;
  /** update single row of the table: "node_status_agent_task" */
  update_node_status_agent_task_by_pk?: Maybe<Node_Status_Agent_Task>;
  /** update multiples rows of table: "node_status_agent_task" */
  update_node_status_agent_task_many?: Maybe<Array<Maybe<Node_Status_Agent_Task_Mutation_Response>>>;
  /** update data of the table: "plan" */
  update_plan?: Maybe<Plan_Mutation_Response>;
  /** update single row of the table: "plan" */
  update_plan_by_pk?: Maybe<Plan>;
  /** update multiples rows of table: "plan" */
  update_plan_many?: Maybe<Array<Maybe<Plan_Mutation_Response>>>;
  /** update data of the table: "pod_list" */
  update_pod_list?: Maybe<Pod_List_Mutation_Response>;
  /** update single row of the table: "pod_list" */
  update_pod_list_by_pk?: Maybe<Pod_List>;
  /** update multiples rows of table: "pod_list" */
  update_pod_list_many?: Maybe<Array<Maybe<Pod_List_Mutation_Response>>>;
  /** update data of the table: "pod_logs" */
  update_pod_logs?: Maybe<Pod_Logs_Mutation_Response>;
  /** update single row of the table: "pod_logs" */
  update_pod_logs_by_pk?: Maybe<Pod_Logs>;
  /** update multiples rows of table: "pod_logs" */
  update_pod_logs_many?: Maybe<Array<Maybe<Pod_Logs_Mutation_Response>>>;
  /** update data of the table: "rbac_policy" */
  update_rbac_policy?: Maybe<Rbac_Policy_Mutation_Response>;
  /** update data of the table: "rbac_policy_action" */
  update_rbac_policy_action?: Maybe<Rbac_Policy_Action_Mutation_Response>;
  /** update single row of the table: "rbac_policy_action" */
  update_rbac_policy_action_by_pk?: Maybe<Rbac_Policy_Action>;
  /** update multiples rows of table: "rbac_policy_action" */
  update_rbac_policy_action_many?: Maybe<Array<Maybe<Rbac_Policy_Action_Mutation_Response>>>;
  /** update data of the table: "rbac_policy_action" */
  update_rbac_policy_actions?: Maybe<Rbac_Policy_Actions_Mutation_Response>;
  /** update single row of the table: "rbac_policy_action" */
  update_rbac_policy_actions_by_pk?: Maybe<Rbac_Policy_Actions>;
  /** update multiples rows of table: "rbac_policy_action" */
  update_rbac_policy_actions_many?: Maybe<Array<Maybe<Rbac_Policy_Actions_Mutation_Response>>>;
  /** update single row of the table: "rbac_policy" */
  update_rbac_policy_by_pk?: Maybe<Rbac_Policy>;
  /** update multiples rows of table: "rbac_policy" */
  update_rbac_policy_many?: Maybe<Array<Maybe<Rbac_Policy_Mutation_Response>>>;
  /** update data of the table: "rbac_role" */
  update_rbac_role?: Maybe<Rbac_Role_Mutation_Response>;
  /** update single row of the table: "rbac_role" */
  update_rbac_role_by_pk?: Maybe<Rbac_Role>;
  /** update multiples rows of table: "rbac_role" */
  update_rbac_role_many?: Maybe<Array<Maybe<Rbac_Role_Mutation_Response>>>;
  /** update data of the table: "rbac_role_policy" */
  update_rbac_role_policy?: Maybe<Rbac_Role_Policy_Mutation_Response>;
  /** update single row of the table: "rbac_role_policy" */
  update_rbac_role_policy_by_pk?: Maybe<Rbac_Role_Policy>;
  /** update multiples rows of table: "rbac_role_policy" */
  update_rbac_role_policy_many?: Maybe<Array<Maybe<Rbac_Role_Policy_Mutation_Response>>>;
  /** update data of the table: "rbac_user_role" */
  update_rbac_user_role?: Maybe<Rbac_User_Role_Mutation_Response>;
  /** update single row of the table: "rbac_user_role" */
  update_rbac_user_role_by_pk?: Maybe<Rbac_User_Role>;
  /** update multiples rows of table: "rbac_user_role" */
  update_rbac_user_role_many?: Maybe<Array<Maybe<Rbac_User_Role_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "service_attributes_for_account" */
  update_service_attributes_for_account?: Maybe<Service_Attributes_For_Account_Mutation_Response>;
  /** update single row of the table: "service_attributes_for_account" */
  update_service_attributes_for_account_by_pk?: Maybe<Service_Attributes_For_Account>;
  /** update multiples rows of table: "service_attributes_for_account" */
  update_service_attributes_for_account_many?: Maybe<Array<Maybe<Service_Attributes_For_Account_Mutation_Response>>>;
  /** update data of the table: "service_description" */
  update_service_description?: Maybe<Service_Description_Mutation_Response>;
  /** update single row of the table: "service_description" */
  update_service_description_by_pk?: Maybe<Service_Description>;
  /** update multiples rows of table: "service_description" */
  update_service_description_many?: Maybe<Array<Maybe<Service_Description_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "workflow_configuration" */
  update_workflow_configuration?: Maybe<Workflow_Configuration_Mutation_Response>;
  /** update single row of the table: "workflow_configuration" */
  update_workflow_configuration_by_pk?: Maybe<Workflow_Configuration>;
  /** update multiples rows of table: "workflow_configuration" */
  update_workflow_configuration_many?: Maybe<Array<Maybe<Workflow_Configuration_Mutation_Response>>>;
  /** update data of the table: "workflow_results" */
  update_workflow_results?: Maybe<Workflow_Results_Mutation_Response>;
  /** update single row of the table: "workflow_results" */
  update_workflow_results_by_pk?: Maybe<Workflow_Results>;
  /** update multiples rows of table: "workflow_results" */
  update_workflow_results_many?: Maybe<Array<Maybe<Workflow_Results_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCloseMonitorArgs = {
  workflowRunId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateGithubInstallationArgs = {
  installationId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCreateNewUserAccountArgs = {
  params?: Maybe<CreateAccountInput>;
};


/** mutation root */
export type Mutation_RootCreatePagerDutyInstallationArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateSlackInstallationArgs = {
  code: Scalars['String'];
  redirect_uri: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  params: CreateUserInput;
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Domain_MappingArgs = {
  where: Account_Domain_Mapping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Domain_Mapping_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Event_LimitsArgs = {
  where: Account_Event_Limits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Event_Limits_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agent_TaskArgs = {
  where: Agent_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agent_Task_ResultArgs = {
  where: Agent_Task_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agent_Task_Result_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Api_KeyArgs = {
  where: Api_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Api_Key_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_ViewArgs = {
  where: App_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_View_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AuditArgs = {
  where: Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Channel_ConfigurationArgs = {
  where: Channel_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_ActionsArgs = {
  where: Event_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Config_MapArgs = {
  where: Event_Config_Map_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Config_Map_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Cron_JobArgs = {
  where: Event_Cron_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Cron_Job_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Datadog_MonitorArgs = {
  where: Event_Datadog_Monitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Datadog_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Datadog_TracingArgs = {
  where: Event_Datadog_Tracing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Datadog_Tracing_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_DeployArgs = {
  where: Event_Deploy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Deploy_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_HpaArgs = {
  where: Event_Hpa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Hpa_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_IngressArgs = {
  where: Event_Ingress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Ingress_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_JobArgs = {
  where: Event_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Job_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Komodor_AlertArgs = {
  where: Event_Komodor_Alert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Komodor_Alert_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Kubernetes_Service_ResourceArgs = {
  where: Event_Kubernetes_Service_Resource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Kubernetes_Service_Resource_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_LaunchdarklyArgs = {
  where: Event_Launchdarkly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Launchdarkly_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Limit_RangesArgs = {
  where: Event_Limit_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Limit_Ranges_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_MonitorArgs = {
  where: Event_Monitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Network_PoliciesArgs = {
  where: Event_Network_Policies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Network_Policies_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_NodeArgs = {
  where: Event_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Node_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_OpsgenieArgs = {
  where: Event_Opsgenie_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Opsgenie_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_PagerdutyArgs = {
  where: Event_Pagerduty_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Pagerduty_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_PdbArgs = {
  where: Event_Pdb_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Pdb_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Resource_QuotaArgs = {
  where: Event_Resource_Quota_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Resource_Quota_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_SecretArgs = {
  where: Event_Secret_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Secret_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Sentry_IssueArgs = {
  where: Event_Sentry_Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Sentry_Issue_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Stale_Kubernetes_AgentArgs = {
  where: Event_Stale_Kubernetes_Agent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Stale_Kubernetes_Agent_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_TopologyArgs = {
  where: Event_Topology_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Topology_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_WorkloadArgs = {
  where: Event_Workload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Workload_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_External_LinkArgs = {
  where: External_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_External_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_External_Link_ContextArgs = {
  where: External_Link_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_External_Link_Context_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Helm_RepoArgs = {
  where: Helm_Repo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Helm_Repo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InstallationArgs = {
  where: Installation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Installation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Filter_SettingsArgs = {
  where: Komodor_Filter_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Filter_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_ServiceArgs = {
  where: Komodor_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_AttributesArgs = {
  where: Komodor_Service_Attributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Attributes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Datadog_DataArgs = {
  where: Komodor_Service_Datadog_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Datadog_Data_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Deploy_StateArgs = {
  where: Komodor_Service_Deploy_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Deploy_State_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Health_StateArgs = {
  where: Komodor_Service_Health_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Health_State_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_K8s_MetadataArgs = {
  where: Komodor_Service_K8s_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_K8s_Metadata_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Sentry_DataArgs = {
  where: Komodor_Service_Sentry_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Sentry_Data_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Static_Prevention_StateArgs = {
  where: Komodor_Service_Static_Prevention_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Komodor_Service_Static_Prevention_State_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kubernetes_Agent_InfoArgs = {
  where: Kubernetes_Agent_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kubernetes_Agent_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Node_Status_Agent_TaskArgs = {
  where: Node_Status_Agent_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Node_Status_Agent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PlanArgs = {
  where: Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_By_PkArgs = {
  accountPlan: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Pod_ListArgs = {
  where: Pod_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pod_List_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Pod_LogsArgs = {
  where: Pod_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pod_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_PolicyArgs = {
  where: Rbac_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Policy_ActionArgs = {
  where: Rbac_Policy_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Policy_Action_By_PkArgs = {
  action: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Policy_ActionsArgs = {
  where: Rbac_Policy_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Policy_Actions_By_PkArgs = {
  action: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Policy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_RoleArgs = {
  where: Rbac_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Role_PolicyArgs = {
  where: Rbac_Role_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_Role_Policy_By_PkArgs = {
  policyId: Scalars['uuid'];
  roleId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rbac_User_RoleArgs = {
  where: Rbac_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rbac_User_Role_By_PkArgs = {
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Service_Attributes_For_AccountArgs = {
  where: Service_Attributes_For_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Attributes_For_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Service_DescriptionArgs = {
  where: Service_Description_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Description_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Workflow_ConfigurationArgs = {
  where: Workflow_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workflow_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Workflow_ResultsArgs = {
  where: Workflow_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workflow_Results_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDesktopSilentLoginArgs = {
  params: SilentLoginInput;
};


/** mutation root */
export type Mutation_RootDesktopSilentSignupArgs = {
  params: SilentSignupInput;
};


/** mutation root */
export type Mutation_RootExecuteAgentTaskArgs = {
  params?: Maybe<AgentTaskInput>;
};


/** mutation root */
export type Mutation_RootFetchAllSlackChannelsArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFetchJobsByPageArgs = {
  checkedFilters?: Maybe<Scalars['jsonb']>;
  page: Scalars['Int'];
  sortStrategy?: Maybe<Scalars['String']>;
  textFilter?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFetchJobsFiltersArgs = {
  checkedFilters?: Maybe<Scalars['jsonb']>;
  filterType?: Maybe<Scalars['String']>;
  textFilter?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFetchServicesByPageArgs = {
  checkedFilters?: Maybe<Scalars['jsonb']>;
  page: Scalars['Int'];
  sortStrategy?: Maybe<Scalars['String']>;
  textFilter?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFetchServicesFiltersArgs = {
  checkedFilters?: Maybe<Scalars['jsonb']>;
  textFilter?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGetResourcesMapArgs = {
  params?: Maybe<ResourcesMapInput>;
};


/** mutation root */
export type Mutation_RootGithubRevertArgs = {
  cluster: Scalars['String'];
  deployName: Scalars['String'];
  gitCompareUrl: Scalars['String'];
  namespace: Scalars['String'];
  serviceId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Domain_MappingArgs = {
  objects: Array<Account_Domain_Mapping_Insert_Input>;
  on_conflict?: Maybe<Account_Domain_Mapping_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Domain_Mapping_OneArgs = {
  object: Account_Domain_Mapping_Insert_Input;
  on_conflict?: Maybe<Account_Domain_Mapping_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Event_LimitsArgs = {
  objects: Array<Account_Event_Limits_Insert_Input>;
  on_conflict?: Maybe<Account_Event_Limits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Event_Limits_OneArgs = {
  object: Account_Event_Limits_Insert_Input;
  on_conflict?: Maybe<Account_Event_Limits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agent_TaskArgs = {
  objects: Array<Agent_Task_Insert_Input>;
  on_conflict?: Maybe<Agent_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agent_Task_OneArgs = {
  object: Agent_Task_Insert_Input;
  on_conflict?: Maybe<Agent_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agent_Task_ResultArgs = {
  objects: Array<Agent_Task_Result_Insert_Input>;
  on_conflict?: Maybe<Agent_Task_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agent_Task_Result_OneArgs = {
  object: Agent_Task_Result_Insert_Input;
  on_conflict?: Maybe<Agent_Task_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_KeyArgs = {
  objects: Array<Api_Key_Insert_Input>;
  on_conflict?: Maybe<Api_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_Key_OneArgs = {
  object: Api_Key_Insert_Input;
  on_conflict?: Maybe<Api_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_ViewArgs = {
  objects: Array<App_View_Insert_Input>;
  on_conflict?: Maybe<App_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_View_OneArgs = {
  object: App_View_Insert_Input;
  on_conflict?: Maybe<App_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuditArgs = {
  objects: Array<Audit_Insert_Input>;
  on_conflict?: Maybe<Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_OneArgs = {
  object: Audit_Insert_Input;
  on_conflict?: Maybe<Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_ConfigurationArgs = {
  objects: Array<Channel_Configuration_Insert_Input>;
  on_conflict?: Maybe<Channel_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Configuration_OneArgs = {
  object: Channel_Configuration_Insert_Input;
  on_conflict?: Maybe<Channel_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_ActionsArgs = {
  objects: Array<Event_Actions_Insert_Input>;
  on_conflict?: Maybe<Event_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Actions_OneArgs = {
  object: Event_Actions_Insert_Input;
  on_conflict?: Maybe<Event_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Config_MapArgs = {
  objects: Array<Event_Config_Map_Insert_Input>;
  on_conflict?: Maybe<Event_Config_Map_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Config_Map_OneArgs = {
  object: Event_Config_Map_Insert_Input;
  on_conflict?: Maybe<Event_Config_Map_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Cron_JobArgs = {
  objects: Array<Event_Cron_Job_Insert_Input>;
  on_conflict?: Maybe<Event_Cron_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Cron_Job_OneArgs = {
  object: Event_Cron_Job_Insert_Input;
  on_conflict?: Maybe<Event_Cron_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Datadog_MonitorArgs = {
  objects: Array<Event_Datadog_Monitor_Insert_Input>;
  on_conflict?: Maybe<Event_Datadog_Monitor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Datadog_Monitor_OneArgs = {
  object: Event_Datadog_Monitor_Insert_Input;
  on_conflict?: Maybe<Event_Datadog_Monitor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Datadog_TracingArgs = {
  objects: Array<Event_Datadog_Tracing_Insert_Input>;
  on_conflict?: Maybe<Event_Datadog_Tracing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Datadog_Tracing_OneArgs = {
  object: Event_Datadog_Tracing_Insert_Input;
  on_conflict?: Maybe<Event_Datadog_Tracing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_DeployArgs = {
  objects: Array<Event_Deploy_Insert_Input>;
  on_conflict?: Maybe<Event_Deploy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Deploy_OneArgs = {
  object: Event_Deploy_Insert_Input;
  on_conflict?: Maybe<Event_Deploy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_HpaArgs = {
  objects: Array<Event_Hpa_Insert_Input>;
  on_conflict?: Maybe<Event_Hpa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Hpa_OneArgs = {
  object: Event_Hpa_Insert_Input;
  on_conflict?: Maybe<Event_Hpa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_IngressArgs = {
  objects: Array<Event_Ingress_Insert_Input>;
  on_conflict?: Maybe<Event_Ingress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Ingress_OneArgs = {
  object: Event_Ingress_Insert_Input;
  on_conflict?: Maybe<Event_Ingress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_JobArgs = {
  objects: Array<Event_Job_Insert_Input>;
  on_conflict?: Maybe<Event_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Job_OneArgs = {
  object: Event_Job_Insert_Input;
  on_conflict?: Maybe<Event_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Komodor_AlertArgs = {
  objects: Array<Event_Komodor_Alert_Insert_Input>;
  on_conflict?: Maybe<Event_Komodor_Alert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Komodor_Alert_OneArgs = {
  object: Event_Komodor_Alert_Insert_Input;
  on_conflict?: Maybe<Event_Komodor_Alert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Kubernetes_Service_ResourceArgs = {
  objects: Array<Event_Kubernetes_Service_Resource_Insert_Input>;
  on_conflict?: Maybe<Event_Kubernetes_Service_Resource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Kubernetes_Service_Resource_OneArgs = {
  object: Event_Kubernetes_Service_Resource_Insert_Input;
  on_conflict?: Maybe<Event_Kubernetes_Service_Resource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_LaunchdarklyArgs = {
  objects: Array<Event_Launchdarkly_Insert_Input>;
  on_conflict?: Maybe<Event_Launchdarkly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Launchdarkly_OneArgs = {
  object: Event_Launchdarkly_Insert_Input;
  on_conflict?: Maybe<Event_Launchdarkly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Limit_RangesArgs = {
  objects: Array<Event_Limit_Ranges_Insert_Input>;
  on_conflict?: Maybe<Event_Limit_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Limit_Ranges_OneArgs = {
  object: Event_Limit_Ranges_Insert_Input;
  on_conflict?: Maybe<Event_Limit_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_MonitorArgs = {
  objects: Array<Event_Monitor_Insert_Input>;
  on_conflict?: Maybe<Event_Monitor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Monitor_OneArgs = {
  object: Event_Monitor_Insert_Input;
  on_conflict?: Maybe<Event_Monitor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Network_PoliciesArgs = {
  objects: Array<Event_Network_Policies_Insert_Input>;
  on_conflict?: Maybe<Event_Network_Policies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Network_Policies_OneArgs = {
  object: Event_Network_Policies_Insert_Input;
  on_conflict?: Maybe<Event_Network_Policies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_NodeArgs = {
  objects: Array<Event_Node_Insert_Input>;
  on_conflict?: Maybe<Event_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Node_OneArgs = {
  object: Event_Node_Insert_Input;
  on_conflict?: Maybe<Event_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OpsgenieArgs = {
  objects: Array<Event_Opsgenie_Insert_Input>;
  on_conflict?: Maybe<Event_Opsgenie_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Opsgenie_OneArgs = {
  object: Event_Opsgenie_Insert_Input;
  on_conflict?: Maybe<Event_Opsgenie_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_PagerdutyArgs = {
  objects: Array<Event_Pagerduty_Insert_Input>;
  on_conflict?: Maybe<Event_Pagerduty_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Pagerduty_OneArgs = {
  object: Event_Pagerduty_Insert_Input;
  on_conflict?: Maybe<Event_Pagerduty_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_PdbArgs = {
  objects: Array<Event_Pdb_Insert_Input>;
  on_conflict?: Maybe<Event_Pdb_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Pdb_OneArgs = {
  object: Event_Pdb_Insert_Input;
  on_conflict?: Maybe<Event_Pdb_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Resource_QuotaArgs = {
  objects: Array<Event_Resource_Quota_Insert_Input>;
  on_conflict?: Maybe<Event_Resource_Quota_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Resource_Quota_OneArgs = {
  object: Event_Resource_Quota_Insert_Input;
  on_conflict?: Maybe<Event_Resource_Quota_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_SecretArgs = {
  objects: Array<Event_Secret_Insert_Input>;
  on_conflict?: Maybe<Event_Secret_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Secret_OneArgs = {
  object: Event_Secret_Insert_Input;
  on_conflict?: Maybe<Event_Secret_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Sentry_IssueArgs = {
  objects: Array<Event_Sentry_Issue_Insert_Input>;
  on_conflict?: Maybe<Event_Sentry_Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Sentry_Issue_OneArgs = {
  object: Event_Sentry_Issue_Insert_Input;
  on_conflict?: Maybe<Event_Sentry_Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Stale_Kubernetes_AgentArgs = {
  objects: Array<Event_Stale_Kubernetes_Agent_Insert_Input>;
  on_conflict?: Maybe<Event_Stale_Kubernetes_Agent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Stale_Kubernetes_Agent_OneArgs = {
  object: Event_Stale_Kubernetes_Agent_Insert_Input;
  on_conflict?: Maybe<Event_Stale_Kubernetes_Agent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_TopologyArgs = {
  objects: Array<Event_Topology_Insert_Input>;
  on_conflict?: Maybe<Event_Topology_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Topology_OneArgs = {
  object: Event_Topology_Insert_Input;
  on_conflict?: Maybe<Event_Topology_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_WorkloadArgs = {
  objects: Array<Event_Workload_Insert_Input>;
  on_conflict?: Maybe<Event_Workload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Workload_OneArgs = {
  object: Event_Workload_Insert_Input;
  on_conflict?: Maybe<Event_Workload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_External_LinkArgs = {
  objects: Array<External_Link_Insert_Input>;
  on_conflict?: Maybe<External_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_External_Link_ContextArgs = {
  objects: Array<External_Link_Context_Insert_Input>;
  on_conflict?: Maybe<External_Link_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_External_Link_Context_OneArgs = {
  object: External_Link_Context_Insert_Input;
  on_conflict?: Maybe<External_Link_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_External_Link_OneArgs = {
  object: External_Link_Insert_Input;
  on_conflict?: Maybe<External_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Helm_RepoArgs = {
  objects: Array<Helm_Repo_Insert_Input>;
  on_conflict?: Maybe<Helm_Repo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Helm_Repo_OneArgs = {
  object: Helm_Repo_Insert_Input;
  on_conflict?: Maybe<Helm_Repo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InstallationArgs = {
  objects: Array<Installation_Insert_Input>;
  on_conflict?: Maybe<Installation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Installation_OneArgs = {
  object: Installation_Insert_Input;
  on_conflict?: Maybe<Installation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Filter_SettingsArgs = {
  objects: Array<Komodor_Filter_Settings_Insert_Input>;
  on_conflict?: Maybe<Komodor_Filter_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Filter_Settings_OneArgs = {
  object: Komodor_Filter_Settings_Insert_Input;
  on_conflict?: Maybe<Komodor_Filter_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_ServiceArgs = {
  objects: Array<Komodor_Service_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_AttributesArgs = {
  objects: Array<Komodor_Service_Attributes_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Attributes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Attributes_OneArgs = {
  object: Komodor_Service_Attributes_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Attributes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Datadog_DataArgs = {
  objects: Array<Komodor_Service_Datadog_Data_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Datadog_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Datadog_Data_OneArgs = {
  object: Komodor_Service_Datadog_Data_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Datadog_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Deploy_StateArgs = {
  objects: Array<Komodor_Service_Deploy_State_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Deploy_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Deploy_State_OneArgs = {
  object: Komodor_Service_Deploy_State_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Deploy_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Health_StateArgs = {
  objects: Array<Komodor_Service_Health_State_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Health_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Health_State_OneArgs = {
  object: Komodor_Service_Health_State_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Health_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_K8s_MetadataArgs = {
  objects: Array<Komodor_Service_K8s_Metadata_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_K8s_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_K8s_Metadata_OneArgs = {
  object: Komodor_Service_K8s_Metadata_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_K8s_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_OneArgs = {
  object: Komodor_Service_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Sentry_DataArgs = {
  objects: Array<Komodor_Service_Sentry_Data_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Sentry_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Sentry_Data_OneArgs = {
  object: Komodor_Service_Sentry_Data_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Sentry_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Static_Prevention_StateArgs = {
  objects: Array<Komodor_Service_Static_Prevention_State_Insert_Input>;
  on_conflict?: Maybe<Komodor_Service_Static_Prevention_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Komodor_Service_Static_Prevention_State_OneArgs = {
  object: Komodor_Service_Static_Prevention_State_Insert_Input;
  on_conflict?: Maybe<Komodor_Service_Static_Prevention_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kubernetes_Agent_InfoArgs = {
  objects: Array<Kubernetes_Agent_Info_Insert_Input>;
  on_conflict?: Maybe<Kubernetes_Agent_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kubernetes_Agent_Info_OneArgs = {
  object: Kubernetes_Agent_Info_Insert_Input;
  on_conflict?: Maybe<Kubernetes_Agent_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Node_Status_Agent_TaskArgs = {
  objects: Array<Node_Status_Agent_Task_Insert_Input>;
  on_conflict?: Maybe<Node_Status_Agent_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Node_Status_Agent_Task_OneArgs = {
  object: Node_Status_Agent_Task_Insert_Input;
  on_conflict?: Maybe<Node_Status_Agent_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlanArgs = {
  objects: Array<Plan_Insert_Input>;
  on_conflict?: Maybe<Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_OneArgs = {
  object: Plan_Insert_Input;
  on_conflict?: Maybe<Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pod_ListArgs = {
  objects: Array<Pod_List_Insert_Input>;
  on_conflict?: Maybe<Pod_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pod_List_OneArgs = {
  object: Pod_List_Insert_Input;
  on_conflict?: Maybe<Pod_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pod_LogsArgs = {
  objects: Array<Pod_Logs_Insert_Input>;
  on_conflict?: Maybe<Pod_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pod_Logs_OneArgs = {
  object: Pod_Logs_Insert_Input;
  on_conflict?: Maybe<Pod_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_PolicyArgs = {
  objects: Array<Rbac_Policy_Insert_Input>;
  on_conflict?: Maybe<Rbac_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Policy_ActionArgs = {
  objects: Array<Rbac_Policy_Action_Insert_Input>;
  on_conflict?: Maybe<Rbac_Policy_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Policy_Action_OneArgs = {
  object: Rbac_Policy_Action_Insert_Input;
  on_conflict?: Maybe<Rbac_Policy_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Policy_ActionsArgs = {
  objects: Array<Rbac_Policy_Actions_Insert_Input>;
  on_conflict?: Maybe<Rbac_Policy_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Policy_Actions_OneArgs = {
  object: Rbac_Policy_Actions_Insert_Input;
  on_conflict?: Maybe<Rbac_Policy_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Policy_OneArgs = {
  object: Rbac_Policy_Insert_Input;
  on_conflict?: Maybe<Rbac_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_RoleArgs = {
  objects: Array<Rbac_Role_Insert_Input>;
  on_conflict?: Maybe<Rbac_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Role_OneArgs = {
  object: Rbac_Role_Insert_Input;
  on_conflict?: Maybe<Rbac_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Role_PolicyArgs = {
  objects: Array<Rbac_Role_Policy_Insert_Input>;
  on_conflict?: Maybe<Rbac_Role_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_Role_Policy_OneArgs = {
  object: Rbac_Role_Policy_Insert_Input;
  on_conflict?: Maybe<Rbac_Role_Policy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_User_RoleArgs = {
  objects: Array<Rbac_User_Role_Insert_Input>;
  on_conflict?: Maybe<Rbac_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rbac_User_Role_OneArgs = {
  object: Rbac_User_Role_Insert_Input;
  on_conflict?: Maybe<Rbac_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Attributes_For_AccountArgs = {
  objects: Array<Service_Attributes_For_Account_Insert_Input>;
  on_conflict?: Maybe<Service_Attributes_For_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Attributes_For_Account_OneArgs = {
  object: Service_Attributes_For_Account_Insert_Input;
  on_conflict?: Maybe<Service_Attributes_For_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_DescriptionArgs = {
  objects: Array<Service_Description_Insert_Input>;
  on_conflict?: Maybe<Service_Description_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Description_OneArgs = {
  object: Service_Description_Insert_Input;
  on_conflict?: Maybe<Service_Description_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_ConfigurationArgs = {
  objects: Array<Workflow_Configuration_Insert_Input>;
  on_conflict?: Maybe<Workflow_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_Configuration_OneArgs = {
  object: Workflow_Configuration_Insert_Input;
  on_conflict?: Maybe<Workflow_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_ResultsArgs = {
  objects: Array<Workflow_Results_Insert_Input>;
  on_conflict?: Maybe<Workflow_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_Results_OneArgs = {
  object: Workflow_Results_Insert_Input;
  on_conflict?: Maybe<Workflow_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootResendEmailVerificationArgs = {
  params: ResendEmailVerificationInput;
};


/** mutation root */
export type Mutation_RootUpdate_AccountArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _inc?: Maybe<Account_Inc_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_By_PkArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _inc?: Maybe<Account_Inc_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Domain_MappingArgs = {
  _inc?: Maybe<Account_Domain_Mapping_Inc_Input>;
  _set?: Maybe<Account_Domain_Mapping_Set_Input>;
  where: Account_Domain_Mapping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Domain_Mapping_By_PkArgs = {
  _inc?: Maybe<Account_Domain_Mapping_Inc_Input>;
  _set?: Maybe<Account_Domain_Mapping_Set_Input>;
  pk_columns: Account_Domain_Mapping_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Domain_Mapping_ManyArgs = {
  updates: Array<Account_Domain_Mapping_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Event_LimitsArgs = {
  _inc?: Maybe<Account_Event_Limits_Inc_Input>;
  _set?: Maybe<Account_Event_Limits_Set_Input>;
  where: Account_Event_Limits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Event_Limits_By_PkArgs = {
  _inc?: Maybe<Account_Event_Limits_Inc_Input>;
  _set?: Maybe<Account_Event_Limits_Set_Input>;
  pk_columns: Account_Event_Limits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Event_Limits_ManyArgs = {
  updates: Array<Account_Event_Limits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ManyArgs = {
  updates: Array<Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_TaskArgs = {
  _append?: Maybe<Agent_Task_Append_Input>;
  _delete_at_path?: Maybe<Agent_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Agent_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Agent_Task_Delete_Key_Input>;
  _prepend?: Maybe<Agent_Task_Prepend_Input>;
  _set?: Maybe<Agent_Task_Set_Input>;
  where: Agent_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_Task_By_PkArgs = {
  _append?: Maybe<Agent_Task_Append_Input>;
  _delete_at_path?: Maybe<Agent_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Agent_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Agent_Task_Delete_Key_Input>;
  _prepend?: Maybe<Agent_Task_Prepend_Input>;
  _set?: Maybe<Agent_Task_Set_Input>;
  pk_columns: Agent_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_Task_ManyArgs = {
  updates: Array<Agent_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_Task_ResultArgs = {
  _append?: Maybe<Agent_Task_Result_Append_Input>;
  _delete_at_path?: Maybe<Agent_Task_Result_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Agent_Task_Result_Delete_Elem_Input>;
  _delete_key?: Maybe<Agent_Task_Result_Delete_Key_Input>;
  _prepend?: Maybe<Agent_Task_Result_Prepend_Input>;
  _set?: Maybe<Agent_Task_Result_Set_Input>;
  where: Agent_Task_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_Task_Result_By_PkArgs = {
  _append?: Maybe<Agent_Task_Result_Append_Input>;
  _delete_at_path?: Maybe<Agent_Task_Result_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Agent_Task_Result_Delete_Elem_Input>;
  _delete_key?: Maybe<Agent_Task_Result_Delete_Key_Input>;
  _prepend?: Maybe<Agent_Task_Result_Prepend_Input>;
  _set?: Maybe<Agent_Task_Result_Set_Input>;
  pk_columns: Agent_Task_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agent_Task_Result_ManyArgs = {
  updates: Array<Agent_Task_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Api_KeyArgs = {
  _set?: Maybe<Api_Key_Set_Input>;
  where: Api_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Key_By_PkArgs = {
  _set?: Maybe<Api_Key_Set_Input>;
  pk_columns: Api_Key_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Key_ManyArgs = {
  updates: Array<Api_Key_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_ViewArgs = {
  _append?: Maybe<App_View_Append_Input>;
  _delete_at_path?: Maybe<App_View_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_View_Delete_Elem_Input>;
  _delete_key?: Maybe<App_View_Delete_Key_Input>;
  _prepend?: Maybe<App_View_Prepend_Input>;
  _set?: Maybe<App_View_Set_Input>;
  where: App_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_View_By_PkArgs = {
  _append?: Maybe<App_View_Append_Input>;
  _delete_at_path?: Maybe<App_View_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_View_Delete_Elem_Input>;
  _delete_key?: Maybe<App_View_Delete_Key_Input>;
  _prepend?: Maybe<App_View_Prepend_Input>;
  _set?: Maybe<App_View_Set_Input>;
  pk_columns: App_View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_View_ManyArgs = {
  updates: Array<App_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuditArgs = {
  _append?: Maybe<Audit_Append_Input>;
  _delete_at_path?: Maybe<Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Delete_Key_Input>;
  _prepend?: Maybe<Audit_Prepend_Input>;
  _set?: Maybe<Audit_Set_Input>;
  where: Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_By_PkArgs = {
  _append?: Maybe<Audit_Append_Input>;
  _delete_at_path?: Maybe<Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Delete_Key_Input>;
  _prepend?: Maybe<Audit_Prepend_Input>;
  _set?: Maybe<Audit_Set_Input>;
  pk_columns: Audit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_ManyArgs = {
  updates: Array<Audit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_ConfigurationArgs = {
  _append?: Maybe<Channel_Configuration_Append_Input>;
  _delete_at_path?: Maybe<Channel_Configuration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Channel_Configuration_Delete_Elem_Input>;
  _delete_key?: Maybe<Channel_Configuration_Delete_Key_Input>;
  _prepend?: Maybe<Channel_Configuration_Prepend_Input>;
  _set?: Maybe<Channel_Configuration_Set_Input>;
  where: Channel_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Configuration_By_PkArgs = {
  _append?: Maybe<Channel_Configuration_Append_Input>;
  _delete_at_path?: Maybe<Channel_Configuration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Channel_Configuration_Delete_Elem_Input>;
  _delete_key?: Maybe<Channel_Configuration_Delete_Key_Input>;
  _prepend?: Maybe<Channel_Configuration_Prepend_Input>;
  _set?: Maybe<Channel_Configuration_Set_Input>;
  pk_columns: Channel_Configuration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Configuration_ManyArgs = {
  updates: Array<Channel_Configuration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ActionsArgs = {
  _append?: Maybe<Event_Actions_Append_Input>;
  _delete_at_path?: Maybe<Event_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Actions_Delete_Key_Input>;
  _prepend?: Maybe<Event_Actions_Prepend_Input>;
  _set?: Maybe<Event_Actions_Set_Input>;
  where: Event_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Actions_By_PkArgs = {
  _append?: Maybe<Event_Actions_Append_Input>;
  _delete_at_path?: Maybe<Event_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Actions_Delete_Key_Input>;
  _prepend?: Maybe<Event_Actions_Prepend_Input>;
  _set?: Maybe<Event_Actions_Set_Input>;
  pk_columns: Event_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Actions_ManyArgs = {
  updates: Array<Event_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Config_MapArgs = {
  _append?: Maybe<Event_Config_Map_Append_Input>;
  _delete_at_path?: Maybe<Event_Config_Map_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Config_Map_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Config_Map_Delete_Key_Input>;
  _prepend?: Maybe<Event_Config_Map_Prepend_Input>;
  _set?: Maybe<Event_Config_Map_Set_Input>;
  where: Event_Config_Map_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Config_Map_By_PkArgs = {
  _append?: Maybe<Event_Config_Map_Append_Input>;
  _delete_at_path?: Maybe<Event_Config_Map_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Config_Map_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Config_Map_Delete_Key_Input>;
  _prepend?: Maybe<Event_Config_Map_Prepend_Input>;
  _set?: Maybe<Event_Config_Map_Set_Input>;
  pk_columns: Event_Config_Map_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Config_Map_ManyArgs = {
  updates: Array<Event_Config_Map_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Cron_JobArgs = {
  _append?: Maybe<Event_Cron_Job_Append_Input>;
  _delete_at_path?: Maybe<Event_Cron_Job_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Cron_Job_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Cron_Job_Delete_Key_Input>;
  _prepend?: Maybe<Event_Cron_Job_Prepend_Input>;
  _set?: Maybe<Event_Cron_Job_Set_Input>;
  where: Event_Cron_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Cron_Job_By_PkArgs = {
  _append?: Maybe<Event_Cron_Job_Append_Input>;
  _delete_at_path?: Maybe<Event_Cron_Job_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Cron_Job_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Cron_Job_Delete_Key_Input>;
  _prepend?: Maybe<Event_Cron_Job_Prepend_Input>;
  _set?: Maybe<Event_Cron_Job_Set_Input>;
  pk_columns: Event_Cron_Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Cron_Job_ManyArgs = {
  updates: Array<Event_Cron_Job_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_MonitorArgs = {
  _append?: Maybe<Event_Datadog_Monitor_Append_Input>;
  _delete_at_path?: Maybe<Event_Datadog_Monitor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Datadog_Monitor_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Datadog_Monitor_Delete_Key_Input>;
  _prepend?: Maybe<Event_Datadog_Monitor_Prepend_Input>;
  _set?: Maybe<Event_Datadog_Monitor_Set_Input>;
  where: Event_Datadog_Monitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_Monitor_By_PkArgs = {
  _append?: Maybe<Event_Datadog_Monitor_Append_Input>;
  _delete_at_path?: Maybe<Event_Datadog_Monitor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Datadog_Monitor_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Datadog_Monitor_Delete_Key_Input>;
  _prepend?: Maybe<Event_Datadog_Monitor_Prepend_Input>;
  _set?: Maybe<Event_Datadog_Monitor_Set_Input>;
  pk_columns: Event_Datadog_Monitor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_Monitor_ManyArgs = {
  updates: Array<Event_Datadog_Monitor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_TracingArgs = {
  _append?: Maybe<Event_Datadog_Tracing_Append_Input>;
  _delete_at_path?: Maybe<Event_Datadog_Tracing_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Datadog_Tracing_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Datadog_Tracing_Delete_Key_Input>;
  _prepend?: Maybe<Event_Datadog_Tracing_Prepend_Input>;
  _set?: Maybe<Event_Datadog_Tracing_Set_Input>;
  where: Event_Datadog_Tracing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_Tracing_By_PkArgs = {
  _append?: Maybe<Event_Datadog_Tracing_Append_Input>;
  _delete_at_path?: Maybe<Event_Datadog_Tracing_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Datadog_Tracing_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Datadog_Tracing_Delete_Key_Input>;
  _prepend?: Maybe<Event_Datadog_Tracing_Prepend_Input>;
  _set?: Maybe<Event_Datadog_Tracing_Set_Input>;
  pk_columns: Event_Datadog_Tracing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Datadog_Tracing_ManyArgs = {
  updates: Array<Event_Datadog_Tracing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_DeployArgs = {
  _append?: Maybe<Event_Deploy_Append_Input>;
  _delete_at_path?: Maybe<Event_Deploy_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Deploy_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Deploy_Delete_Key_Input>;
  _inc?: Maybe<Event_Deploy_Inc_Input>;
  _prepend?: Maybe<Event_Deploy_Prepend_Input>;
  _set?: Maybe<Event_Deploy_Set_Input>;
  where: Event_Deploy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Deploy_By_PkArgs = {
  _append?: Maybe<Event_Deploy_Append_Input>;
  _delete_at_path?: Maybe<Event_Deploy_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Deploy_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Deploy_Delete_Key_Input>;
  _inc?: Maybe<Event_Deploy_Inc_Input>;
  _prepend?: Maybe<Event_Deploy_Prepend_Input>;
  _set?: Maybe<Event_Deploy_Set_Input>;
  pk_columns: Event_Deploy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Deploy_ManyArgs = {
  updates: Array<Event_Deploy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_HpaArgs = {
  _append?: Maybe<Event_Hpa_Append_Input>;
  _delete_at_path?: Maybe<Event_Hpa_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Hpa_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Hpa_Delete_Key_Input>;
  _inc?: Maybe<Event_Hpa_Inc_Input>;
  _prepend?: Maybe<Event_Hpa_Prepend_Input>;
  _set?: Maybe<Event_Hpa_Set_Input>;
  where: Event_Hpa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Hpa_By_PkArgs = {
  _append?: Maybe<Event_Hpa_Append_Input>;
  _delete_at_path?: Maybe<Event_Hpa_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Hpa_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Hpa_Delete_Key_Input>;
  _inc?: Maybe<Event_Hpa_Inc_Input>;
  _prepend?: Maybe<Event_Hpa_Prepend_Input>;
  _set?: Maybe<Event_Hpa_Set_Input>;
  pk_columns: Event_Hpa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Hpa_ManyArgs = {
  updates: Array<Event_Hpa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_IngressArgs = {
  _append?: Maybe<Event_Ingress_Append_Input>;
  _delete_at_path?: Maybe<Event_Ingress_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Ingress_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Ingress_Delete_Key_Input>;
  _prepend?: Maybe<Event_Ingress_Prepend_Input>;
  _set?: Maybe<Event_Ingress_Set_Input>;
  where: Event_Ingress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Ingress_By_PkArgs = {
  _append?: Maybe<Event_Ingress_Append_Input>;
  _delete_at_path?: Maybe<Event_Ingress_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Ingress_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Ingress_Delete_Key_Input>;
  _prepend?: Maybe<Event_Ingress_Prepend_Input>;
  _set?: Maybe<Event_Ingress_Set_Input>;
  pk_columns: Event_Ingress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Ingress_ManyArgs = {
  updates: Array<Event_Ingress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_JobArgs = {
  _append?: Maybe<Event_Job_Append_Input>;
  _delete_at_path?: Maybe<Event_Job_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Job_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Job_Delete_Key_Input>;
  _inc?: Maybe<Event_Job_Inc_Input>;
  _prepend?: Maybe<Event_Job_Prepend_Input>;
  _set?: Maybe<Event_Job_Set_Input>;
  where: Event_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Job_By_PkArgs = {
  _append?: Maybe<Event_Job_Append_Input>;
  _delete_at_path?: Maybe<Event_Job_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Job_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Job_Delete_Key_Input>;
  _inc?: Maybe<Event_Job_Inc_Input>;
  _prepend?: Maybe<Event_Job_Prepend_Input>;
  _set?: Maybe<Event_Job_Set_Input>;
  pk_columns: Event_Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Job_ManyArgs = {
  updates: Array<Event_Job_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Komodor_AlertArgs = {
  _append?: Maybe<Event_Komodor_Alert_Append_Input>;
  _delete_at_path?: Maybe<Event_Komodor_Alert_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Komodor_Alert_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Komodor_Alert_Delete_Key_Input>;
  _prepend?: Maybe<Event_Komodor_Alert_Prepend_Input>;
  _set?: Maybe<Event_Komodor_Alert_Set_Input>;
  where: Event_Komodor_Alert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Komodor_Alert_By_PkArgs = {
  _append?: Maybe<Event_Komodor_Alert_Append_Input>;
  _delete_at_path?: Maybe<Event_Komodor_Alert_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Komodor_Alert_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Komodor_Alert_Delete_Key_Input>;
  _prepend?: Maybe<Event_Komodor_Alert_Prepend_Input>;
  _set?: Maybe<Event_Komodor_Alert_Set_Input>;
  pk_columns: Event_Komodor_Alert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Komodor_Alert_ManyArgs = {
  updates: Array<Event_Komodor_Alert_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Kubernetes_Service_ResourceArgs = {
  _append?: Maybe<Event_Kubernetes_Service_Resource_Append_Input>;
  _delete_at_path?: Maybe<Event_Kubernetes_Service_Resource_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Kubernetes_Service_Resource_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Kubernetes_Service_Resource_Delete_Key_Input>;
  _prepend?: Maybe<Event_Kubernetes_Service_Resource_Prepend_Input>;
  _set?: Maybe<Event_Kubernetes_Service_Resource_Set_Input>;
  where: Event_Kubernetes_Service_Resource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Kubernetes_Service_Resource_By_PkArgs = {
  _append?: Maybe<Event_Kubernetes_Service_Resource_Append_Input>;
  _delete_at_path?: Maybe<Event_Kubernetes_Service_Resource_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Kubernetes_Service_Resource_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Kubernetes_Service_Resource_Delete_Key_Input>;
  _prepend?: Maybe<Event_Kubernetes_Service_Resource_Prepend_Input>;
  _set?: Maybe<Event_Kubernetes_Service_Resource_Set_Input>;
  pk_columns: Event_Kubernetes_Service_Resource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Kubernetes_Service_Resource_ManyArgs = {
  updates: Array<Event_Kubernetes_Service_Resource_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_LaunchdarklyArgs = {
  _append?: Maybe<Event_Launchdarkly_Append_Input>;
  _delete_at_path?: Maybe<Event_Launchdarkly_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Launchdarkly_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Launchdarkly_Delete_Key_Input>;
  _prepend?: Maybe<Event_Launchdarkly_Prepend_Input>;
  _set?: Maybe<Event_Launchdarkly_Set_Input>;
  where: Event_Launchdarkly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Launchdarkly_By_PkArgs = {
  _append?: Maybe<Event_Launchdarkly_Append_Input>;
  _delete_at_path?: Maybe<Event_Launchdarkly_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Launchdarkly_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Launchdarkly_Delete_Key_Input>;
  _prepend?: Maybe<Event_Launchdarkly_Prepend_Input>;
  _set?: Maybe<Event_Launchdarkly_Set_Input>;
  pk_columns: Event_Launchdarkly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Launchdarkly_ManyArgs = {
  updates: Array<Event_Launchdarkly_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Limit_RangesArgs = {
  _append?: Maybe<Event_Limit_Ranges_Append_Input>;
  _delete_at_path?: Maybe<Event_Limit_Ranges_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Limit_Ranges_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Limit_Ranges_Delete_Key_Input>;
  _prepend?: Maybe<Event_Limit_Ranges_Prepend_Input>;
  _set?: Maybe<Event_Limit_Ranges_Set_Input>;
  where: Event_Limit_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Limit_Ranges_By_PkArgs = {
  _append?: Maybe<Event_Limit_Ranges_Append_Input>;
  _delete_at_path?: Maybe<Event_Limit_Ranges_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Limit_Ranges_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Limit_Ranges_Delete_Key_Input>;
  _prepend?: Maybe<Event_Limit_Ranges_Prepend_Input>;
  _set?: Maybe<Event_Limit_Ranges_Set_Input>;
  pk_columns: Event_Limit_Ranges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Limit_Ranges_ManyArgs = {
  updates: Array<Event_Limit_Ranges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_MonitorArgs = {
  _append?: Maybe<Event_Monitor_Append_Input>;
  _delete_at_path?: Maybe<Event_Monitor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Monitor_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Monitor_Delete_Key_Input>;
  _inc?: Maybe<Event_Monitor_Inc_Input>;
  _prepend?: Maybe<Event_Monitor_Prepend_Input>;
  _set?: Maybe<Event_Monitor_Set_Input>;
  where: Event_Monitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Monitor_By_PkArgs = {
  _append?: Maybe<Event_Monitor_Append_Input>;
  _delete_at_path?: Maybe<Event_Monitor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Monitor_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Monitor_Delete_Key_Input>;
  _inc?: Maybe<Event_Monitor_Inc_Input>;
  _prepend?: Maybe<Event_Monitor_Prepend_Input>;
  _set?: Maybe<Event_Monitor_Set_Input>;
  pk_columns: Event_Monitor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Monitor_ManyArgs = {
  updates: Array<Event_Monitor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Network_PoliciesArgs = {
  _append?: Maybe<Event_Network_Policies_Append_Input>;
  _delete_at_path?: Maybe<Event_Network_Policies_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Network_Policies_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Network_Policies_Delete_Key_Input>;
  _prepend?: Maybe<Event_Network_Policies_Prepend_Input>;
  _set?: Maybe<Event_Network_Policies_Set_Input>;
  where: Event_Network_Policies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Network_Policies_By_PkArgs = {
  _append?: Maybe<Event_Network_Policies_Append_Input>;
  _delete_at_path?: Maybe<Event_Network_Policies_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Network_Policies_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Network_Policies_Delete_Key_Input>;
  _prepend?: Maybe<Event_Network_Policies_Prepend_Input>;
  _set?: Maybe<Event_Network_Policies_Set_Input>;
  pk_columns: Event_Network_Policies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Network_Policies_ManyArgs = {
  updates: Array<Event_Network_Policies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_NodeArgs = {
  _append?: Maybe<Event_Node_Append_Input>;
  _delete_at_path?: Maybe<Event_Node_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Node_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Node_Delete_Key_Input>;
  _prepend?: Maybe<Event_Node_Prepend_Input>;
  _set?: Maybe<Event_Node_Set_Input>;
  where: Event_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Node_By_PkArgs = {
  _append?: Maybe<Event_Node_Append_Input>;
  _delete_at_path?: Maybe<Event_Node_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Node_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Node_Delete_Key_Input>;
  _prepend?: Maybe<Event_Node_Prepend_Input>;
  _set?: Maybe<Event_Node_Set_Input>;
  pk_columns: Event_Node_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Node_ManyArgs = {
  updates: Array<Event_Node_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_OpsgenieArgs = {
  _append?: Maybe<Event_Opsgenie_Append_Input>;
  _delete_at_path?: Maybe<Event_Opsgenie_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Opsgenie_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Opsgenie_Delete_Key_Input>;
  _prepend?: Maybe<Event_Opsgenie_Prepend_Input>;
  _set?: Maybe<Event_Opsgenie_Set_Input>;
  where: Event_Opsgenie_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Opsgenie_By_PkArgs = {
  _append?: Maybe<Event_Opsgenie_Append_Input>;
  _delete_at_path?: Maybe<Event_Opsgenie_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Opsgenie_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Opsgenie_Delete_Key_Input>;
  _prepend?: Maybe<Event_Opsgenie_Prepend_Input>;
  _set?: Maybe<Event_Opsgenie_Set_Input>;
  pk_columns: Event_Opsgenie_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Opsgenie_ManyArgs = {
  updates: Array<Event_Opsgenie_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_PagerdutyArgs = {
  _append?: Maybe<Event_Pagerduty_Append_Input>;
  _delete_at_path?: Maybe<Event_Pagerduty_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Pagerduty_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Pagerduty_Delete_Key_Input>;
  _prepend?: Maybe<Event_Pagerduty_Prepend_Input>;
  _set?: Maybe<Event_Pagerduty_Set_Input>;
  where: Event_Pagerduty_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Pagerduty_By_PkArgs = {
  _append?: Maybe<Event_Pagerduty_Append_Input>;
  _delete_at_path?: Maybe<Event_Pagerduty_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Pagerduty_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Pagerduty_Delete_Key_Input>;
  _prepend?: Maybe<Event_Pagerduty_Prepend_Input>;
  _set?: Maybe<Event_Pagerduty_Set_Input>;
  pk_columns: Event_Pagerduty_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Pagerduty_ManyArgs = {
  updates: Array<Event_Pagerduty_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_PdbArgs = {
  _append?: Maybe<Event_Pdb_Append_Input>;
  _delete_at_path?: Maybe<Event_Pdb_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Pdb_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Pdb_Delete_Key_Input>;
  _prepend?: Maybe<Event_Pdb_Prepend_Input>;
  _set?: Maybe<Event_Pdb_Set_Input>;
  where: Event_Pdb_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Pdb_By_PkArgs = {
  _append?: Maybe<Event_Pdb_Append_Input>;
  _delete_at_path?: Maybe<Event_Pdb_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Pdb_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Pdb_Delete_Key_Input>;
  _prepend?: Maybe<Event_Pdb_Prepend_Input>;
  _set?: Maybe<Event_Pdb_Set_Input>;
  pk_columns: Event_Pdb_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Pdb_ManyArgs = {
  updates: Array<Event_Pdb_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Resource_QuotaArgs = {
  _append?: Maybe<Event_Resource_Quota_Append_Input>;
  _delete_at_path?: Maybe<Event_Resource_Quota_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Resource_Quota_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Resource_Quota_Delete_Key_Input>;
  _prepend?: Maybe<Event_Resource_Quota_Prepend_Input>;
  _set?: Maybe<Event_Resource_Quota_Set_Input>;
  where: Event_Resource_Quota_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Resource_Quota_By_PkArgs = {
  _append?: Maybe<Event_Resource_Quota_Append_Input>;
  _delete_at_path?: Maybe<Event_Resource_Quota_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Resource_Quota_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Resource_Quota_Delete_Key_Input>;
  _prepend?: Maybe<Event_Resource_Quota_Prepend_Input>;
  _set?: Maybe<Event_Resource_Quota_Set_Input>;
  pk_columns: Event_Resource_Quota_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Resource_Quota_ManyArgs = {
  updates: Array<Event_Resource_Quota_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_SecretArgs = {
  _append?: Maybe<Event_Secret_Append_Input>;
  _delete_at_path?: Maybe<Event_Secret_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Secret_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Secret_Delete_Key_Input>;
  _prepend?: Maybe<Event_Secret_Prepend_Input>;
  _set?: Maybe<Event_Secret_Set_Input>;
  where: Event_Secret_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Secret_By_PkArgs = {
  _append?: Maybe<Event_Secret_Append_Input>;
  _delete_at_path?: Maybe<Event_Secret_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Secret_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Secret_Delete_Key_Input>;
  _prepend?: Maybe<Event_Secret_Prepend_Input>;
  _set?: Maybe<Event_Secret_Set_Input>;
  pk_columns: Event_Secret_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Secret_ManyArgs = {
  updates: Array<Event_Secret_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sentry_IssueArgs = {
  _append?: Maybe<Event_Sentry_Issue_Append_Input>;
  _delete_at_path?: Maybe<Event_Sentry_Issue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Sentry_Issue_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Sentry_Issue_Delete_Key_Input>;
  _prepend?: Maybe<Event_Sentry_Issue_Prepend_Input>;
  _set?: Maybe<Event_Sentry_Issue_Set_Input>;
  where: Event_Sentry_Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sentry_Issue_By_PkArgs = {
  _append?: Maybe<Event_Sentry_Issue_Append_Input>;
  _delete_at_path?: Maybe<Event_Sentry_Issue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Sentry_Issue_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Sentry_Issue_Delete_Key_Input>;
  _prepend?: Maybe<Event_Sentry_Issue_Prepend_Input>;
  _set?: Maybe<Event_Sentry_Issue_Set_Input>;
  pk_columns: Event_Sentry_Issue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sentry_Issue_ManyArgs = {
  updates: Array<Event_Sentry_Issue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Stale_Kubernetes_AgentArgs = {
  _append?: Maybe<Event_Stale_Kubernetes_Agent_Append_Input>;
  _delete_at_path?: Maybe<Event_Stale_Kubernetes_Agent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Key_Input>;
  _prepend?: Maybe<Event_Stale_Kubernetes_Agent_Prepend_Input>;
  _set?: Maybe<Event_Stale_Kubernetes_Agent_Set_Input>;
  where: Event_Stale_Kubernetes_Agent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Stale_Kubernetes_Agent_By_PkArgs = {
  _append?: Maybe<Event_Stale_Kubernetes_Agent_Append_Input>;
  _delete_at_path?: Maybe<Event_Stale_Kubernetes_Agent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Stale_Kubernetes_Agent_Delete_Key_Input>;
  _prepend?: Maybe<Event_Stale_Kubernetes_Agent_Prepend_Input>;
  _set?: Maybe<Event_Stale_Kubernetes_Agent_Set_Input>;
  pk_columns: Event_Stale_Kubernetes_Agent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Stale_Kubernetes_Agent_ManyArgs = {
  updates: Array<Event_Stale_Kubernetes_Agent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_TopologyArgs = {
  _append?: Maybe<Event_Topology_Append_Input>;
  _delete_at_path?: Maybe<Event_Topology_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Topology_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Topology_Delete_Key_Input>;
  _prepend?: Maybe<Event_Topology_Prepend_Input>;
  _set?: Maybe<Event_Topology_Set_Input>;
  where: Event_Topology_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Topology_By_PkArgs = {
  _append?: Maybe<Event_Topology_Append_Input>;
  _delete_at_path?: Maybe<Event_Topology_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Topology_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Topology_Delete_Key_Input>;
  _prepend?: Maybe<Event_Topology_Prepend_Input>;
  _set?: Maybe<Event_Topology_Set_Input>;
  pk_columns: Event_Topology_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Topology_ManyArgs = {
  updates: Array<Event_Topology_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_WorkloadArgs = {
  _append?: Maybe<Event_Workload_Append_Input>;
  _delete_at_path?: Maybe<Event_Workload_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Workload_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Workload_Delete_Key_Input>;
  _inc?: Maybe<Event_Workload_Inc_Input>;
  _prepend?: Maybe<Event_Workload_Prepend_Input>;
  _set?: Maybe<Event_Workload_Set_Input>;
  where: Event_Workload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Workload_By_PkArgs = {
  _append?: Maybe<Event_Workload_Append_Input>;
  _delete_at_path?: Maybe<Event_Workload_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Workload_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Workload_Delete_Key_Input>;
  _inc?: Maybe<Event_Workload_Inc_Input>;
  _prepend?: Maybe<Event_Workload_Prepend_Input>;
  _set?: Maybe<Event_Workload_Set_Input>;
  pk_columns: Event_Workload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Workload_ManyArgs = {
  updates: Array<Event_Workload_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_External_LinkArgs = {
  _set?: Maybe<External_Link_Set_Input>;
  where: External_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_External_Link_By_PkArgs = {
  _set?: Maybe<External_Link_Set_Input>;
  pk_columns: External_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_External_Link_ContextArgs = {
  _set?: Maybe<External_Link_Context_Set_Input>;
  where: External_Link_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_External_Link_Context_By_PkArgs = {
  _set?: Maybe<External_Link_Context_Set_Input>;
  pk_columns: External_Link_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_External_Link_Context_ManyArgs = {
  updates: Array<External_Link_Context_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_External_Link_ManyArgs = {
  updates: Array<External_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Helm_RepoArgs = {
  _set?: Maybe<Helm_Repo_Set_Input>;
  where: Helm_Repo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Helm_Repo_By_PkArgs = {
  _set?: Maybe<Helm_Repo_Set_Input>;
  pk_columns: Helm_Repo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Helm_Repo_ManyArgs = {
  updates: Array<Helm_Repo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InstallationArgs = {
  _append?: Maybe<Installation_Append_Input>;
  _delete_at_path?: Maybe<Installation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Installation_Delete_Elem_Input>;
  _delete_key?: Maybe<Installation_Delete_Key_Input>;
  _prepend?: Maybe<Installation_Prepend_Input>;
  _set?: Maybe<Installation_Set_Input>;
  where: Installation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Installation_By_PkArgs = {
  _append?: Maybe<Installation_Append_Input>;
  _delete_at_path?: Maybe<Installation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Installation_Delete_Elem_Input>;
  _delete_key?: Maybe<Installation_Delete_Key_Input>;
  _prepend?: Maybe<Installation_Prepend_Input>;
  _set?: Maybe<Installation_Set_Input>;
  pk_columns: Installation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Installation_ManyArgs = {
  updates: Array<Installation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Filter_SettingsArgs = {
  _append?: Maybe<Komodor_Filter_Settings_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Filter_Settings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Filter_Settings_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Filter_Settings_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Filter_Settings_Prepend_Input>;
  _set?: Maybe<Komodor_Filter_Settings_Set_Input>;
  where: Komodor_Filter_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Filter_Settings_By_PkArgs = {
  _append?: Maybe<Komodor_Filter_Settings_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Filter_Settings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Filter_Settings_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Filter_Settings_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Filter_Settings_Prepend_Input>;
  _set?: Maybe<Komodor_Filter_Settings_Set_Input>;
  pk_columns: Komodor_Filter_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Filter_Settings_ManyArgs = {
  updates: Array<Komodor_Filter_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_ServiceArgs = {
  _append?: Maybe<Komodor_Service_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Service_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Set_Input>;
  where: Komodor_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_AttributesArgs = {
  _set?: Maybe<Komodor_Service_Attributes_Set_Input>;
  where: Komodor_Service_Attributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Attributes_By_PkArgs = {
  _set?: Maybe<Komodor_Service_Attributes_Set_Input>;
  pk_columns: Komodor_Service_Attributes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Attributes_ManyArgs = {
  updates: Array<Komodor_Service_Attributes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_By_PkArgs = {
  _append?: Maybe<Komodor_Service_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Service_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Set_Input>;
  pk_columns: Komodor_Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Datadog_DataArgs = {
  _set?: Maybe<Komodor_Service_Datadog_Data_Set_Input>;
  where: Komodor_Service_Datadog_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Datadog_Data_By_PkArgs = {
  _set?: Maybe<Komodor_Service_Datadog_Data_Set_Input>;
  pk_columns: Komodor_Service_Datadog_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Datadog_Data_ManyArgs = {
  updates: Array<Komodor_Service_Datadog_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Deploy_StateArgs = {
  _append?: Maybe<Komodor_Service_Deploy_State_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Deploy_State_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Deploy_State_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Deploy_State_Delete_Key_Input>;
  _inc?: Maybe<Komodor_Service_Deploy_State_Inc_Input>;
  _prepend?: Maybe<Komodor_Service_Deploy_State_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Deploy_State_Set_Input>;
  where: Komodor_Service_Deploy_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Deploy_State_By_PkArgs = {
  _append?: Maybe<Komodor_Service_Deploy_State_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Deploy_State_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Deploy_State_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Deploy_State_Delete_Key_Input>;
  _inc?: Maybe<Komodor_Service_Deploy_State_Inc_Input>;
  _prepend?: Maybe<Komodor_Service_Deploy_State_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Deploy_State_Set_Input>;
  pk_columns: Komodor_Service_Deploy_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Deploy_State_ManyArgs = {
  updates: Array<Komodor_Service_Deploy_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Health_StateArgs = {
  _set?: Maybe<Komodor_Service_Health_State_Set_Input>;
  where: Komodor_Service_Health_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Health_State_By_PkArgs = {
  _set?: Maybe<Komodor_Service_Health_State_Set_Input>;
  pk_columns: Komodor_Service_Health_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Health_State_ManyArgs = {
  updates: Array<Komodor_Service_Health_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_K8s_MetadataArgs = {
  _append?: Maybe<Komodor_Service_K8s_Metadata_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_K8s_Metadata_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_K8s_Metadata_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_K8s_Metadata_Delete_Key_Input>;
  _inc?: Maybe<Komodor_Service_K8s_Metadata_Inc_Input>;
  _prepend?: Maybe<Komodor_Service_K8s_Metadata_Prepend_Input>;
  _set?: Maybe<Komodor_Service_K8s_Metadata_Set_Input>;
  where: Komodor_Service_K8s_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_K8s_Metadata_By_PkArgs = {
  _append?: Maybe<Komodor_Service_K8s_Metadata_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_K8s_Metadata_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_K8s_Metadata_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_K8s_Metadata_Delete_Key_Input>;
  _inc?: Maybe<Komodor_Service_K8s_Metadata_Inc_Input>;
  _prepend?: Maybe<Komodor_Service_K8s_Metadata_Prepend_Input>;
  _set?: Maybe<Komodor_Service_K8s_Metadata_Set_Input>;
  pk_columns: Komodor_Service_K8s_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_K8s_Metadata_ManyArgs = {
  updates: Array<Komodor_Service_K8s_Metadata_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_ManyArgs = {
  updates: Array<Komodor_Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Sentry_DataArgs = {
  _set?: Maybe<Komodor_Service_Sentry_Data_Set_Input>;
  where: Komodor_Service_Sentry_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Sentry_Data_By_PkArgs = {
  _set?: Maybe<Komodor_Service_Sentry_Data_Set_Input>;
  pk_columns: Komodor_Service_Sentry_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Sentry_Data_ManyArgs = {
  updates: Array<Komodor_Service_Sentry_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Static_Prevention_StateArgs = {
  _append?: Maybe<Komodor_Service_Static_Prevention_State_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Static_Prevention_State_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Service_Static_Prevention_State_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Static_Prevention_State_Set_Input>;
  where: Komodor_Service_Static_Prevention_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Static_Prevention_State_By_PkArgs = {
  _append?: Maybe<Komodor_Service_Static_Prevention_State_Append_Input>;
  _delete_at_path?: Maybe<Komodor_Service_Static_Prevention_State_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Elem_Input>;
  _delete_key?: Maybe<Komodor_Service_Static_Prevention_State_Delete_Key_Input>;
  _prepend?: Maybe<Komodor_Service_Static_Prevention_State_Prepend_Input>;
  _set?: Maybe<Komodor_Service_Static_Prevention_State_Set_Input>;
  pk_columns: Komodor_Service_Static_Prevention_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Komodor_Service_Static_Prevention_State_ManyArgs = {
  updates: Array<Komodor_Service_Static_Prevention_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kubernetes_Agent_InfoArgs = {
  _append?: Maybe<Kubernetes_Agent_Info_Append_Input>;
  _delete_at_path?: Maybe<Kubernetes_Agent_Info_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Kubernetes_Agent_Info_Delete_Elem_Input>;
  _delete_key?: Maybe<Kubernetes_Agent_Info_Delete_Key_Input>;
  _prepend?: Maybe<Kubernetes_Agent_Info_Prepend_Input>;
  _set?: Maybe<Kubernetes_Agent_Info_Set_Input>;
  where: Kubernetes_Agent_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kubernetes_Agent_Info_By_PkArgs = {
  _append?: Maybe<Kubernetes_Agent_Info_Append_Input>;
  _delete_at_path?: Maybe<Kubernetes_Agent_Info_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Kubernetes_Agent_Info_Delete_Elem_Input>;
  _delete_key?: Maybe<Kubernetes_Agent_Info_Delete_Key_Input>;
  _prepend?: Maybe<Kubernetes_Agent_Info_Prepend_Input>;
  _set?: Maybe<Kubernetes_Agent_Info_Set_Input>;
  pk_columns: Kubernetes_Agent_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kubernetes_Agent_Info_ManyArgs = {
  updates: Array<Kubernetes_Agent_Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Node_Status_Agent_TaskArgs = {
  _append?: Maybe<Node_Status_Agent_Task_Append_Input>;
  _delete_at_path?: Maybe<Node_Status_Agent_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Node_Status_Agent_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Node_Status_Agent_Task_Delete_Key_Input>;
  _prepend?: Maybe<Node_Status_Agent_Task_Prepend_Input>;
  _set?: Maybe<Node_Status_Agent_Task_Set_Input>;
  where: Node_Status_Agent_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Node_Status_Agent_Task_By_PkArgs = {
  _append?: Maybe<Node_Status_Agent_Task_Append_Input>;
  _delete_at_path?: Maybe<Node_Status_Agent_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Node_Status_Agent_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Node_Status_Agent_Task_Delete_Key_Input>;
  _prepend?: Maybe<Node_Status_Agent_Task_Prepend_Input>;
  _set?: Maybe<Node_Status_Agent_Task_Set_Input>;
  pk_columns: Node_Status_Agent_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Node_Status_Agent_Task_ManyArgs = {
  updates: Array<Node_Status_Agent_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlanArgs = {
  _inc?: Maybe<Plan_Inc_Input>;
  _set?: Maybe<Plan_Set_Input>;
  where: Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_By_PkArgs = {
  _inc?: Maybe<Plan_Inc_Input>;
  _set?: Maybe<Plan_Set_Input>;
  pk_columns: Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_ManyArgs = {
  updates: Array<Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_ListArgs = {
  _append?: Maybe<Pod_List_Append_Input>;
  _delete_at_path?: Maybe<Pod_List_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pod_List_Delete_Elem_Input>;
  _delete_key?: Maybe<Pod_List_Delete_Key_Input>;
  _prepend?: Maybe<Pod_List_Prepend_Input>;
  _set?: Maybe<Pod_List_Set_Input>;
  where: Pod_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_List_By_PkArgs = {
  _append?: Maybe<Pod_List_Append_Input>;
  _delete_at_path?: Maybe<Pod_List_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pod_List_Delete_Elem_Input>;
  _delete_key?: Maybe<Pod_List_Delete_Key_Input>;
  _prepend?: Maybe<Pod_List_Prepend_Input>;
  _set?: Maybe<Pod_List_Set_Input>;
  pk_columns: Pod_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_List_ManyArgs = {
  updates: Array<Pod_List_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_LogsArgs = {
  _append?: Maybe<Pod_Logs_Append_Input>;
  _delete_at_path?: Maybe<Pod_Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pod_Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Pod_Logs_Delete_Key_Input>;
  _prepend?: Maybe<Pod_Logs_Prepend_Input>;
  _set?: Maybe<Pod_Logs_Set_Input>;
  where: Pod_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_Logs_By_PkArgs = {
  _append?: Maybe<Pod_Logs_Append_Input>;
  _delete_at_path?: Maybe<Pod_Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pod_Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Pod_Logs_Delete_Key_Input>;
  _prepend?: Maybe<Pod_Logs_Prepend_Input>;
  _set?: Maybe<Pod_Logs_Set_Input>;
  pk_columns: Pod_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pod_Logs_ManyArgs = {
  updates: Array<Pod_Logs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_PolicyArgs = {
  _append?: Maybe<Rbac_Policy_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Set_Input>;
  where: Rbac_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_ActionArgs = {
  _append?: Maybe<Rbac_Policy_Action_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Action_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Action_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Action_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Action_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Action_Set_Input>;
  where: Rbac_Policy_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_Action_By_PkArgs = {
  _append?: Maybe<Rbac_Policy_Action_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Action_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Action_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Action_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Action_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Action_Set_Input>;
  pk_columns: Rbac_Policy_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_Action_ManyArgs = {
  updates: Array<Rbac_Policy_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_ActionsArgs = {
  _append?: Maybe<Rbac_Policy_Actions_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Actions_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Actions_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Actions_Set_Input>;
  where: Rbac_Policy_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_Actions_By_PkArgs = {
  _append?: Maybe<Rbac_Policy_Actions_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Actions_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Actions_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Actions_Set_Input>;
  pk_columns: Rbac_Policy_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_Actions_ManyArgs = {
  updates: Array<Rbac_Policy_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_By_PkArgs = {
  _append?: Maybe<Rbac_Policy_Append_Input>;
  _delete_at_path?: Maybe<Rbac_Policy_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rbac_Policy_Delete_Elem_Input>;
  _delete_key?: Maybe<Rbac_Policy_Delete_Key_Input>;
  _prepend?: Maybe<Rbac_Policy_Prepend_Input>;
  _set?: Maybe<Rbac_Policy_Set_Input>;
  pk_columns: Rbac_Policy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Policy_ManyArgs = {
  updates: Array<Rbac_Policy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_RoleArgs = {
  _set?: Maybe<Rbac_Role_Set_Input>;
  where: Rbac_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Role_By_PkArgs = {
  _set?: Maybe<Rbac_Role_Set_Input>;
  pk_columns: Rbac_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Role_ManyArgs = {
  updates: Array<Rbac_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Role_PolicyArgs = {
  _set?: Maybe<Rbac_Role_Policy_Set_Input>;
  where: Rbac_Role_Policy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Role_Policy_By_PkArgs = {
  _set?: Maybe<Rbac_Role_Policy_Set_Input>;
  pk_columns: Rbac_Role_Policy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_Role_Policy_ManyArgs = {
  updates: Array<Rbac_Role_Policy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_User_RoleArgs = {
  _set?: Maybe<Rbac_User_Role_Set_Input>;
  where: Rbac_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_User_Role_By_PkArgs = {
  _set?: Maybe<Rbac_User_Role_Set_Input>;
  pk_columns: Rbac_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rbac_User_Role_ManyArgs = {
  updates: Array<Rbac_User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _append?: Maybe<Role_Append_Input>;
  _delete_at_path?: Maybe<Role_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Role_Delete_Elem_Input>;
  _delete_key?: Maybe<Role_Delete_Key_Input>;
  _prepend?: Maybe<Role_Prepend_Input>;
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _append?: Maybe<Role_Append_Input>;
  _delete_at_path?: Maybe<Role_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Role_Delete_Elem_Input>;
  _delete_key?: Maybe<Role_Delete_Key_Input>;
  _prepend?: Maybe<Role_Prepend_Input>;
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Attributes_For_AccountArgs = {
  _append?: Maybe<Service_Attributes_For_Account_Append_Input>;
  _delete_at_path?: Maybe<Service_Attributes_For_Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Attributes_For_Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Attributes_For_Account_Delete_Key_Input>;
  _prepend?: Maybe<Service_Attributes_For_Account_Prepend_Input>;
  _set?: Maybe<Service_Attributes_For_Account_Set_Input>;
  where: Service_Attributes_For_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Attributes_For_Account_By_PkArgs = {
  _append?: Maybe<Service_Attributes_For_Account_Append_Input>;
  _delete_at_path?: Maybe<Service_Attributes_For_Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Attributes_For_Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Attributes_For_Account_Delete_Key_Input>;
  _prepend?: Maybe<Service_Attributes_For_Account_Prepend_Input>;
  _set?: Maybe<Service_Attributes_For_Account_Set_Input>;
  pk_columns: Service_Attributes_For_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Attributes_For_Account_ManyArgs = {
  updates: Array<Service_Attributes_For_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_DescriptionArgs = {
  _append?: Maybe<Service_Description_Append_Input>;
  _delete_at_path?: Maybe<Service_Description_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Description_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Description_Delete_Key_Input>;
  _prepend?: Maybe<Service_Description_Prepend_Input>;
  _set?: Maybe<Service_Description_Set_Input>;
  where: Service_Description_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Description_By_PkArgs = {
  _append?: Maybe<Service_Description_Append_Input>;
  _delete_at_path?: Maybe<Service_Description_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Description_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Description_Delete_Key_Input>;
  _prepend?: Maybe<Service_Description_Prepend_Input>;
  _set?: Maybe<Service_Description_Set_Input>;
  pk_columns: Service_Description_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Description_ManyArgs = {
  updates: Array<Service_Description_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_ConfigurationArgs = {
  _append?: Maybe<Workflow_Configuration_Append_Input>;
  _delete_at_path?: Maybe<Workflow_Configuration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Workflow_Configuration_Delete_Elem_Input>;
  _delete_key?: Maybe<Workflow_Configuration_Delete_Key_Input>;
  _prepend?: Maybe<Workflow_Configuration_Prepend_Input>;
  _set?: Maybe<Workflow_Configuration_Set_Input>;
  where: Workflow_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Configuration_By_PkArgs = {
  _append?: Maybe<Workflow_Configuration_Append_Input>;
  _delete_at_path?: Maybe<Workflow_Configuration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Workflow_Configuration_Delete_Elem_Input>;
  _delete_key?: Maybe<Workflow_Configuration_Delete_Key_Input>;
  _prepend?: Maybe<Workflow_Configuration_Prepend_Input>;
  _set?: Maybe<Workflow_Configuration_Set_Input>;
  pk_columns: Workflow_Configuration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Configuration_ManyArgs = {
  updates: Array<Workflow_Configuration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_ResultsArgs = {
  _append?: Maybe<Workflow_Results_Append_Input>;
  _delete_at_path?: Maybe<Workflow_Results_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Workflow_Results_Delete_Elem_Input>;
  _delete_key?: Maybe<Workflow_Results_Delete_Key_Input>;
  _prepend?: Maybe<Workflow_Results_Prepend_Input>;
  _set?: Maybe<Workflow_Results_Set_Input>;
  where: Workflow_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Results_By_PkArgs = {
  _append?: Maybe<Workflow_Results_Append_Input>;
  _delete_at_path?: Maybe<Workflow_Results_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Workflow_Results_Delete_Elem_Input>;
  _delete_key?: Maybe<Workflow_Results_Delete_Key_Input>;
  _prepend?: Maybe<Workflow_Results_Prepend_Input>;
  _set?: Maybe<Workflow_Results_Set_Input>;
  pk_columns: Workflow_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Results_ManyArgs = {
  updates: Array<Workflow_Results_Updates>;
};

/** columns and relationships of "node_status_agent_task" */
export type Node_Status_Agent_Task = {
  __typename?: 'node_status_agent_task';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  agentId: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  taskId: Scalars['uuid'];
};


/** columns and relationships of "node_status_agent_task" */
export type Node_Status_Agent_TaskRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "node_status_agent_task" */
export type Node_Status_Agent_TaskStatusArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "node_status_agent_task" */
export type Node_Status_Agent_Task_Aggregate = {
  __typename?: 'node_status_agent_task_aggregate';
  aggregate?: Maybe<Node_Status_Agent_Task_Aggregate_Fields>;
  nodes: Array<Node_Status_Agent_Task>;
};

/** aggregate fields of "node_status_agent_task" */
export type Node_Status_Agent_Task_Aggregate_Fields = {
  __typename?: 'node_status_agent_task_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Node_Status_Agent_Task_Max_Fields>;
  min?: Maybe<Node_Status_Agent_Task_Min_Fields>;
};


/** aggregate fields of "node_status_agent_task" */
export type Node_Status_Agent_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Node_Status_Agent_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Node_Status_Agent_Task_Append_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "node_status_agent_task". All fields are combined with a logical 'AND'. */
export type Node_Status_Agent_Task_Bool_Exp = {
  _and?: Maybe<Array<Node_Status_Agent_Task_Bool_Exp>>;
  _not?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
  _or?: Maybe<Array<Node_Status_Agent_Task_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Jsonb_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "node_status_agent_task" */
export enum Node_Status_Agent_Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkE189a238225e6918b07c78c4295 = 'PK_e189a238225e6918b07c78c4295'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Node_Status_Agent_Task_Delete_At_Path_Input = {
  rbacResources?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Node_Status_Agent_Task_Delete_Elem_Input = {
  rbacResources?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Node_Status_Agent_Task_Delete_Key_Input = {
  rbacResources?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "node_status_agent_task" */
export type Node_Status_Agent_Task_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Node_Status_Agent_Task_Max_Fields = {
  __typename?: 'node_status_agent_task_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Node_Status_Agent_Task_Min_Fields = {
  __typename?: 'node_status_agent_task_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "node_status_agent_task" */
export type Node_Status_Agent_Task_Mutation_Response = {
  __typename?: 'node_status_agent_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Node_Status_Agent_Task>;
};

/** on_conflict condition type for table "node_status_agent_task" */
export type Node_Status_Agent_Task_On_Conflict = {
  constraint: Node_Status_Agent_Task_Constraint;
  update_columns?: Array<Node_Status_Agent_Task_Update_Column>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "node_status_agent_task". */
export type Node_Status_Agent_Task_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

/** primary key columns input for table: node_status_agent_task */
export type Node_Status_Agent_Task_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Node_Status_Agent_Task_Prepend_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "node_status_agent_task" */
export enum Node_Status_Agent_Task_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'taskId'
}

/** input type for updating data in table "node_status_agent_task" */
export type Node_Status_Agent_Task_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "node_status_agent_task" */
export type Node_Status_Agent_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Node_Status_Agent_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Node_Status_Agent_Task_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "node_status_agent_task" */
export enum Node_Status_Agent_Task_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'taskId'
}

export type Node_Status_Agent_Task_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Node_Status_Agent_Task_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Node_Status_Agent_Task_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Node_Status_Agent_Task_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Node_Status_Agent_Task_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Node_Status_Agent_Task_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Node_Status_Agent_Task_Set_Input>;
  where: Node_Status_Agent_Task_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "plan" */
export type Plan = {
  __typename?: 'plan';
  accountPlan: Scalars['String'];
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "plan" */
export type Plan_Aggregate = {
  __typename?: 'plan_aggregate';
  aggregate?: Maybe<Plan_Aggregate_Fields>;
  nodes: Array<Plan>;
};

/** aggregate fields of "plan" */
export type Plan_Aggregate_Fields = {
  __typename?: 'plan_aggregate_fields';
  avg?: Maybe<Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plan_Max_Fields>;
  min?: Maybe<Plan_Min_Fields>;
  stddev?: Maybe<Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Plan_Sum_Fields>;
  var_pop?: Maybe<Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Plan_Var_Samp_Fields>;
  variance?: Maybe<Plan_Variance_Fields>;
};


/** aggregate fields of "plan" */
export type Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Plan_Avg_Fields = {
  __typename?: 'plan_avg_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "plan". All fields are combined with a logical 'AND'. */
export type Plan_Bool_Exp = {
  _and?: Maybe<Array<Plan_Bool_Exp>>;
  _not?: Maybe<Plan_Bool_Exp>;
  _or?: Maybe<Array<Plan_Bool_Exp>>;
  accountPlan?: Maybe<String_Comparison_Exp>;
  clustersLimit?: Maybe<Int_Comparison_Exp>;
  eventsLimit?: Maybe<Int_Comparison_Exp>;
  nodesLimit?: Maybe<Int_Comparison_Exp>;
  usersLimit?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan" */
export enum Plan_Constraint {
  /** unique or primary key constraint on columns "accountPlan" */
  Pk_1a72e7e48d881bd6dbac0c3b096 = 'PK_1a72e7e48d881bd6dbac0c3b096'
}

/** input type for incrementing numeric columns in table "plan" */
export type Plan_Inc_Input = {
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan" */
export type Plan_Insert_Input = {
  accountPlan?: Maybe<Scalars['String']>;
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Plan_Max_Fields = {
  __typename?: 'plan_max_fields';
  accountPlan?: Maybe<Scalars['String']>;
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Plan_Min_Fields = {
  __typename?: 'plan_min_fields';
  accountPlan?: Maybe<Scalars['String']>;
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "plan" */
export type Plan_Mutation_Response = {
  __typename?: 'plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan>;
};

/** on_conflict condition type for table "plan" */
export type Plan_On_Conflict = {
  constraint: Plan_Constraint;
  update_columns?: Array<Plan_Update_Column>;
  where?: Maybe<Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "plan". */
export type Plan_Order_By = {
  accountPlan?: Maybe<Order_By>;
  clustersLimit?: Maybe<Order_By>;
  eventsLimit?: Maybe<Order_By>;
  nodesLimit?: Maybe<Order_By>;
  usersLimit?: Maybe<Order_By>;
};

/** primary key columns input for table: plan */
export type Plan_Pk_Columns_Input = {
  accountPlan: Scalars['String'];
};

/** select columns of table "plan" */
export enum Plan_Select_Column {
  /** column name */
  AccountPlan = 'accountPlan',
  /** column name */
  ClustersLimit = 'clustersLimit',
  /** column name */
  EventsLimit = 'eventsLimit',
  /** column name */
  NodesLimit = 'nodesLimit',
  /** column name */
  UsersLimit = 'usersLimit'
}

/** input type for updating data in table "plan" */
export type Plan_Set_Input = {
  accountPlan?: Maybe<Scalars['String']>;
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Plan_Stddev_Fields = {
  __typename?: 'plan_stddev_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Plan_Stddev_Pop_Fields = {
  __typename?: 'plan_stddev_pop_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Plan_Stddev_Samp_Fields = {
  __typename?: 'plan_stddev_samp_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "plan" */
export type Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Stream_Cursor_Value_Input = {
  accountPlan?: Maybe<Scalars['String']>;
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Plan_Sum_Fields = {
  __typename?: 'plan_sum_fields';
  clustersLimit?: Maybe<Scalars['Int']>;
  eventsLimit?: Maybe<Scalars['Int']>;
  nodesLimit?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

/** update columns of table "plan" */
export enum Plan_Update_Column {
  /** column name */
  AccountPlan = 'accountPlan',
  /** column name */
  ClustersLimit = 'clustersLimit',
  /** column name */
  EventsLimit = 'eventsLimit',
  /** column name */
  NodesLimit = 'nodesLimit',
  /** column name */
  UsersLimit = 'usersLimit'
}

export type Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Plan_Set_Input>;
  where: Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Var_Pop_Fields = {
  __typename?: 'plan_var_pop_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Plan_Var_Samp_Fields = {
  __typename?: 'plan_var_samp_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Plan_Variance_Fields = {
  __typename?: 'plan_variance_fields';
  clustersLimit?: Maybe<Scalars['Float']>;
  eventsLimit?: Maybe<Scalars['Float']>;
  nodesLimit?: Maybe<Scalars['Float']>;
  usersLimit?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pod_list" */
export type Pod_List = {
  __typename?: 'pod_list';
  accountId?: Maybe<Scalars['uuid']>;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  podsSpec?: Maybe<Scalars['jsonb']>;
  taskId: Scalars['uuid'];
};


/** columns and relationships of "pod_list" */
export type Pod_ListPodsSpecArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pod_list" */
export type Pod_List_Aggregate = {
  __typename?: 'pod_list_aggregate';
  aggregate?: Maybe<Pod_List_Aggregate_Fields>;
  nodes: Array<Pod_List>;
};

/** aggregate fields of "pod_list" */
export type Pod_List_Aggregate_Fields = {
  __typename?: 'pod_list_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pod_List_Max_Fields>;
  min?: Maybe<Pod_List_Min_Fields>;
};


/** aggregate fields of "pod_list" */
export type Pod_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pod_List_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pod_List_Append_Input = {
  podsSpec?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pod_list". All fields are combined with a logical 'AND'. */
export type Pod_List_Bool_Exp = {
  _and?: Maybe<Array<Pod_List_Bool_Exp>>;
  _not?: Maybe<Pod_List_Bool_Exp>;
  _or?: Maybe<Array<Pod_List_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  podsSpec?: Maybe<Jsonb_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pod_list" */
export enum Pod_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_8e5c57b6dd5b0a6247c1384cb89 = 'PK_8e5c57b6dd5b0a6247c1384cb89'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pod_List_Delete_At_Path_Input = {
  podsSpec?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pod_List_Delete_Elem_Input = {
  podsSpec?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pod_List_Delete_Key_Input = {
  podsSpec?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "pod_list" */
export type Pod_List_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  podsSpec?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pod_List_Max_Fields = {
  __typename?: 'pod_list_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Pod_List_Min_Fields = {
  __typename?: 'pod_list_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "pod_list" */
export type Pod_List_Mutation_Response = {
  __typename?: 'pod_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pod_List>;
};

/** on_conflict condition type for table "pod_list" */
export type Pod_List_On_Conflict = {
  constraint: Pod_List_Constraint;
  update_columns?: Array<Pod_List_Update_Column>;
  where?: Maybe<Pod_List_Bool_Exp>;
};

/** Ordering options when selecting data from "pod_list". */
export type Pod_List_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  podsSpec?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

/** primary key columns input for table: pod_list */
export type Pod_List_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pod_List_Prepend_Input = {
  podsSpec?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pod_list" */
export enum Pod_List_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PodsSpec = 'podsSpec',
  /** column name */
  TaskId = 'taskId'
}

/** input type for updating data in table "pod_list" */
export type Pod_List_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  podsSpec?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pod_list" */
export type Pod_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pod_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pod_List_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  podsSpec?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "pod_list" */
export enum Pod_List_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PodsSpec = 'podsSpec',
  /** column name */
  TaskId = 'taskId'
}

export type Pod_List_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pod_List_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pod_List_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pod_List_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pod_List_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pod_List_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pod_List_Set_Input>;
  where: Pod_List_Bool_Exp;
};

/** columns and relationships of "pod_logs" */
export type Pod_Logs = {
  __typename?: 'pod_logs';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  agentId: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  logTime: Scalars['timestamp'];
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId: Scalars['uuid'];
};


/** columns and relationships of "pod_logs" */
export type Pod_LogsRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pod_logs" */
export type Pod_Logs_Aggregate = {
  __typename?: 'pod_logs_aggregate';
  aggregate?: Maybe<Pod_Logs_Aggregate_Fields>;
  nodes: Array<Pod_Logs>;
};

/** aggregate fields of "pod_logs" */
export type Pod_Logs_Aggregate_Fields = {
  __typename?: 'pod_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pod_Logs_Max_Fields>;
  min?: Maybe<Pod_Logs_Min_Fields>;
};


/** aggregate fields of "pod_logs" */
export type Pod_Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pod_Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pod_Logs_Append_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pod_logs". All fields are combined with a logical 'AND'. */
export type Pod_Logs_Bool_Exp = {
  _and?: Maybe<Array<Pod_Logs_Bool_Exp>>;
  _not?: Maybe<Pod_Logs_Bool_Exp>;
  _or?: Maybe<Array<Pod_Logs_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logTime?: Maybe<Timestamp_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pod_logs" */
export enum Pod_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA408fb45738699664cf4bf2a175 = 'PK_a408fb45738699664cf4bf2a175'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pod_Logs_Delete_At_Path_Input = {
  rbacResources?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pod_Logs_Delete_Elem_Input = {
  rbacResources?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pod_Logs_Delete_Key_Input = {
  rbacResources?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "pod_logs" */
export type Pod_Logs_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  logTime?: Maybe<Scalars['timestamp']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pod_Logs_Max_Fields = {
  __typename?: 'pod_logs_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  logTime?: Maybe<Scalars['timestamp']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Pod_Logs_Min_Fields = {
  __typename?: 'pod_logs_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  logTime?: Maybe<Scalars['timestamp']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "pod_logs" */
export type Pod_Logs_Mutation_Response = {
  __typename?: 'pod_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pod_Logs>;
};

/** on_conflict condition type for table "pod_logs" */
export type Pod_Logs_On_Conflict = {
  constraint: Pod_Logs_Constraint;
  update_columns?: Array<Pod_Logs_Update_Column>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "pod_logs". */
export type Pod_Logs_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logTime?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

/** primary key columns input for table: pod_logs */
export type Pod_Logs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pod_Logs_Prepend_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pod_logs" */
export enum Pod_Logs_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LogTime = 'logTime',
  /** column name */
  Message = 'message',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  TaskId = 'taskId'
}

/** input type for updating data in table "pod_logs" */
export type Pod_Logs_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  logTime?: Maybe<Scalars['timestamp']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pod_logs" */
export type Pod_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pod_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pod_Logs_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  logTime?: Maybe<Scalars['timestamp']>;
  message?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "pod_logs" */
export enum Pod_Logs_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LogTime = 'logTime',
  /** column name */
  Message = 'message',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  TaskId = 'taskId'
}

export type Pod_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pod_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pod_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pod_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pod_Logs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pod_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pod_Logs_Set_Input>;
  where: Pod_Logs_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** query _Entity union */
  _entities?: Maybe<_Entity>;
  _service: _Service;
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_domain_mapping" */
  account_domain_mapping: Array<Account_Domain_Mapping>;
  /** fetch aggregated fields from the table: "account_domain_mapping" */
  account_domain_mapping_aggregate: Account_Domain_Mapping_Aggregate;
  /** fetch data from the table: "account_domain_mapping" using primary key columns */
  account_domain_mapping_by_pk?: Maybe<Account_Domain_Mapping>;
  /** fetch data from the table: "account_event_limits" */
  account_event_limits: Array<Account_Event_Limits>;
  /** fetch aggregated fields from the table: "account_event_limits" */
  account_event_limits_aggregate: Account_Event_Limits_Aggregate;
  /** fetch data from the table: "account_event_limits" using primary key columns */
  account_event_limits_by_pk?: Maybe<Account_Event_Limits>;
  /** fetch data from the table: "agent_task" */
  agent_task: Array<Agent_Task>;
  /** fetch aggregated fields from the table: "agent_task" */
  agent_task_aggregate: Agent_Task_Aggregate;
  /** fetch data from the table: "agent_task" using primary key columns */
  agent_task_by_pk?: Maybe<Agent_Task>;
  /** fetch data from the table: "agent_task_result" */
  agent_task_result: Array<Agent_Task_Result>;
  /** fetch aggregated fields from the table: "agent_task_result" */
  agent_task_result_aggregate: Agent_Task_Result_Aggregate;
  /** fetch data from the table: "agent_task_result" using primary key columns */
  agent_task_result_by_pk?: Maybe<Agent_Task_Result>;
  /** fetch data from the table: "api_key" */
  api_key: Array<Api_Key>;
  /** fetch aggregated fields from the table: "api_key" */
  api_key_aggregate: Api_Key_Aggregate;
  /** fetch data from the table: "api_key" using primary key columns */
  api_key_by_pk?: Maybe<Api_Key>;
  /** fetch data from the table: "app_view" */
  app_view: Array<App_View>;
  /** fetch aggregated fields from the table: "app_view" */
  app_view_aggregate: App_View_Aggregate;
  /** fetch data from the table: "app_view" using primary key columns */
  app_view_by_pk?: Maybe<App_View>;
  /** fetch data from the table: "audit" */
  audit: Array<Audit>;
  /** fetch aggregated fields from the table: "audit" */
  audit_aggregate: Audit_Aggregate;
  /** fetch data from the table: "audit" using primary key columns */
  audit_by_pk?: Maybe<Audit>;
  /** fetch data from the table: "channel_configuration" */
  channel_configuration: Array<Channel_Configuration>;
  /** fetch aggregated fields from the table: "channel_configuration" */
  channel_configuration_aggregate: Channel_Configuration_Aggregate;
  /** fetch data from the table: "channel_configuration" using primary key columns */
  channel_configuration_by_pk?: Maybe<Channel_Configuration>;
  /** fetch data from the table: "event_actions" */
  event_actions: Array<Event_Actions>;
  /** fetch aggregated fields from the table: "event_actions" */
  event_actions_aggregate: Event_Actions_Aggregate;
  /** fetch data from the table: "event_actions" using primary key columns */
  event_actions_by_pk?: Maybe<Event_Actions>;
  /** fetch data from the table: "event_config_map" */
  event_config_map: Array<Event_Config_Map>;
  /** fetch aggregated fields from the table: "event_config_map" */
  event_config_map_aggregate: Event_Config_Map_Aggregate;
  /** fetch data from the table: "event_config_map" using primary key columns */
  event_config_map_by_pk?: Maybe<Event_Config_Map>;
  /** fetch data from the table: "event_cron_job" */
  event_cron_job: Array<Event_Cron_Job>;
  /** fetch aggregated fields from the table: "event_cron_job" */
  event_cron_job_aggregate: Event_Cron_Job_Aggregate;
  /** fetch data from the table: "event_cron_job" using primary key columns */
  event_cron_job_by_pk?: Maybe<Event_Cron_Job>;
  /** fetch data from the table: "event_datadog_monitor" */
  event_datadog_monitor: Array<Event_Datadog_Monitor>;
  /** fetch aggregated fields from the table: "event_datadog_monitor" */
  event_datadog_monitor_aggregate: Event_Datadog_Monitor_Aggregate;
  /** fetch data from the table: "event_datadog_monitor" using primary key columns */
  event_datadog_monitor_by_pk?: Maybe<Event_Datadog_Monitor>;
  /** fetch data from the table: "event_datadog_tracing" */
  event_datadog_tracing: Array<Event_Datadog_Tracing>;
  /** fetch aggregated fields from the table: "event_datadog_tracing" */
  event_datadog_tracing_aggregate: Event_Datadog_Tracing_Aggregate;
  /** fetch data from the table: "event_datadog_tracing" using primary key columns */
  event_datadog_tracing_by_pk?: Maybe<Event_Datadog_Tracing>;
  /** fetch data from the table: "event_deploy" */
  event_deploy: Array<Event_Deploy>;
  /** fetch aggregated fields from the table: "event_deploy" */
  event_deploy_aggregate: Event_Deploy_Aggregate;
  /** fetch data from the table: "event_deploy" using primary key columns */
  event_deploy_by_pk?: Maybe<Event_Deploy>;
  /** fetch data from the table: "event_hpa" */
  event_hpa: Array<Event_Hpa>;
  /** fetch aggregated fields from the table: "event_hpa" */
  event_hpa_aggregate: Event_Hpa_Aggregate;
  /** fetch data from the table: "event_hpa" using primary key columns */
  event_hpa_by_pk?: Maybe<Event_Hpa>;
  /** fetch data from the table: "event_ingress" */
  event_ingress: Array<Event_Ingress>;
  /** fetch aggregated fields from the table: "event_ingress" */
  event_ingress_aggregate: Event_Ingress_Aggregate;
  /** fetch data from the table: "event_ingress" using primary key columns */
  event_ingress_by_pk?: Maybe<Event_Ingress>;
  /** fetch data from the table: "event_job" */
  event_job: Array<Event_Job>;
  /** fetch aggregated fields from the table: "event_job" */
  event_job_aggregate: Event_Job_Aggregate;
  /** fetch data from the table: "event_job" using primary key columns */
  event_job_by_pk?: Maybe<Event_Job>;
  /** fetch data from the table: "event_komodor_alert" */
  event_komodor_alert: Array<Event_Komodor_Alert>;
  /** fetch aggregated fields from the table: "event_komodor_alert" */
  event_komodor_alert_aggregate: Event_Komodor_Alert_Aggregate;
  /** fetch data from the table: "event_komodor_alert" using primary key columns */
  event_komodor_alert_by_pk?: Maybe<Event_Komodor_Alert>;
  /** fetch data from the table: "event_kubernetes_service_resource" */
  event_kubernetes_service_resource: Array<Event_Kubernetes_Service_Resource>;
  /** fetch aggregated fields from the table: "event_kubernetes_service_resource" */
  event_kubernetes_service_resource_aggregate: Event_Kubernetes_Service_Resource_Aggregate;
  /** fetch data from the table: "event_kubernetes_service_resource" using primary key columns */
  event_kubernetes_service_resource_by_pk?: Maybe<Event_Kubernetes_Service_Resource>;
  /** fetch data from the table: "event_launchdarkly" */
  event_launchdarkly: Array<Event_Launchdarkly>;
  /** fetch aggregated fields from the table: "event_launchdarkly" */
  event_launchdarkly_aggregate: Event_Launchdarkly_Aggregate;
  /** fetch data from the table: "event_launchdarkly" using primary key columns */
  event_launchdarkly_by_pk?: Maybe<Event_Launchdarkly>;
  /** fetch data from the table: "event_limit_ranges" */
  event_limit_ranges: Array<Event_Limit_Ranges>;
  /** fetch aggregated fields from the table: "event_limit_ranges" */
  event_limit_ranges_aggregate: Event_Limit_Ranges_Aggregate;
  /** fetch data from the table: "event_limit_ranges" using primary key columns */
  event_limit_ranges_by_pk?: Maybe<Event_Limit_Ranges>;
  /** fetch data from the table: "event_monitor" */
  event_monitor: Array<Event_Monitor>;
  /** fetch aggregated fields from the table: "event_monitor" */
  event_monitor_aggregate: Event_Monitor_Aggregate;
  /** fetch data from the table: "event_monitor" using primary key columns */
  event_monitor_by_pk?: Maybe<Event_Monitor>;
  /** fetch data from the table: "event_network_policies" */
  event_network_policies: Array<Event_Network_Policies>;
  /** fetch aggregated fields from the table: "event_network_policies" */
  event_network_policies_aggregate: Event_Network_Policies_Aggregate;
  /** fetch data from the table: "event_network_policies" using primary key columns */
  event_network_policies_by_pk?: Maybe<Event_Network_Policies>;
  /** fetch data from the table: "event_node" */
  event_node: Array<Event_Node>;
  /** fetch aggregated fields from the table: "event_node" */
  event_node_aggregate: Event_Node_Aggregate;
  /** fetch data from the table: "event_node" using primary key columns */
  event_node_by_pk?: Maybe<Event_Node>;
  /** fetch data from the table: "event_opsgenie" */
  event_opsgenie: Array<Event_Opsgenie>;
  /** fetch aggregated fields from the table: "event_opsgenie" */
  event_opsgenie_aggregate: Event_Opsgenie_Aggregate;
  /** fetch data from the table: "event_opsgenie" using primary key columns */
  event_opsgenie_by_pk?: Maybe<Event_Opsgenie>;
  /** fetch data from the table: "event_pagerduty" */
  event_pagerduty: Array<Event_Pagerduty>;
  /** fetch aggregated fields from the table: "event_pagerduty" */
  event_pagerduty_aggregate: Event_Pagerduty_Aggregate;
  /** fetch data from the table: "event_pagerduty" using primary key columns */
  event_pagerduty_by_pk?: Maybe<Event_Pagerduty>;
  /** fetch data from the table: "event_pdb" */
  event_pdb: Array<Event_Pdb>;
  /** fetch aggregated fields from the table: "event_pdb" */
  event_pdb_aggregate: Event_Pdb_Aggregate;
  /** fetch data from the table: "event_pdb" using primary key columns */
  event_pdb_by_pk?: Maybe<Event_Pdb>;
  /** fetch data from the table: "event_resource_quota" */
  event_resource_quota: Array<Event_Resource_Quota>;
  /** fetch aggregated fields from the table: "event_resource_quota" */
  event_resource_quota_aggregate: Event_Resource_Quota_Aggregate;
  /** fetch data from the table: "event_resource_quota" using primary key columns */
  event_resource_quota_by_pk?: Maybe<Event_Resource_Quota>;
  /** fetch data from the table: "event_secret" */
  event_secret: Array<Event_Secret>;
  /** fetch aggregated fields from the table: "event_secret" */
  event_secret_aggregate: Event_Secret_Aggregate;
  /** fetch data from the table: "event_secret" using primary key columns */
  event_secret_by_pk?: Maybe<Event_Secret>;
  /** fetch data from the table: "event_sentry_issue" */
  event_sentry_issue: Array<Event_Sentry_Issue>;
  /** fetch aggregated fields from the table: "event_sentry_issue" */
  event_sentry_issue_aggregate: Event_Sentry_Issue_Aggregate;
  /** fetch data from the table: "event_sentry_issue" using primary key columns */
  event_sentry_issue_by_pk?: Maybe<Event_Sentry_Issue>;
  /** fetch data from the table: "event_stale_kubernetes_agent" */
  event_stale_kubernetes_agent: Array<Event_Stale_Kubernetes_Agent>;
  /** fetch aggregated fields from the table: "event_stale_kubernetes_agent" */
  event_stale_kubernetes_agent_aggregate: Event_Stale_Kubernetes_Agent_Aggregate;
  /** fetch data from the table: "event_stale_kubernetes_agent" using primary key columns */
  event_stale_kubernetes_agent_by_pk?: Maybe<Event_Stale_Kubernetes_Agent>;
  /** fetch data from the table: "event_topology" */
  event_topology: Array<Event_Topology>;
  /** fetch aggregated fields from the table: "event_topology" */
  event_topology_aggregate: Event_Topology_Aggregate;
  /** fetch data from the table: "event_topology" using primary key columns */
  event_topology_by_pk?: Maybe<Event_Topology>;
  /** fetch data from the table: "event_workload" */
  event_workload: Array<Event_Workload>;
  /** fetch aggregated fields from the table: "event_workload" */
  event_workload_aggregate: Event_Workload_Aggregate;
  /** fetch data from the table: "event_workload" using primary key columns */
  event_workload_by_pk?: Maybe<Event_Workload>;
  /** fetch data from the table: "external_link" */
  external_link: Array<External_Link>;
  /** fetch aggregated fields from the table: "external_link" */
  external_link_aggregate: External_Link_Aggregate;
  /** fetch data from the table: "external_link" using primary key columns */
  external_link_by_pk?: Maybe<External_Link>;
  /** fetch data from the table: "external_link_context" */
  external_link_context: Array<External_Link_Context>;
  /** fetch aggregated fields from the table: "external_link_context" */
  external_link_context_aggregate: External_Link_Context_Aggregate;
  /** fetch data from the table: "external_link_context" using primary key columns */
  external_link_context_by_pk?: Maybe<External_Link_Context>;
  fetchAllJobs?: Maybe<AllServicesOutput>;
  fetchAllServices?: Maybe<AllServicesOutput>;
  fetchEvents?: Maybe<EventsOutput>;
  fetchEventsLimits?: Maybe<FetchEventsLimitsOutput>;
  fetchEventsTimeline?: Maybe<BucketsOutput>;
  /** fetchFilterCategories */
  fetchFilterCategories?: Maybe<FilterCategoriesOutput>;
  fetchFilterValues?: Maybe<FilterValuesOutput>;
  fetchOpsgenieResponders?: Maybe<RespondersOutput>;
  getAgentCrashReport?: Maybe<AgentCrashReport>;
  /** fetch data from the table: "helm_repo" */
  helm_repo: Array<Helm_Repo>;
  /** fetch aggregated fields from the table: "helm_repo" */
  helm_repo_aggregate: Helm_Repo_Aggregate;
  /** fetch data from the table: "helm_repo" using primary key columns */
  helm_repo_by_pk?: Maybe<Helm_Repo>;
  /** fetch data from the table: "installation" */
  installation: Array<Installation>;
  /** fetch aggregated fields from the table: "installation" */
  installation_aggregate: Installation_Aggregate;
  /** fetch data from the table: "installation" using primary key columns */
  installation_by_pk?: Maybe<Installation>;
  /** fetch data from the table: "komodor_filter_settings" */
  komodor_filter_settings: Array<Komodor_Filter_Settings>;
  /** fetch aggregated fields from the table: "komodor_filter_settings" */
  komodor_filter_settings_aggregate: Komodor_Filter_Settings_Aggregate;
  /** fetch data from the table: "komodor_filter_settings" using primary key columns */
  komodor_filter_settings_by_pk?: Maybe<Komodor_Filter_Settings>;
  /** fetch data from the table: "komodor_service" */
  komodor_service: Array<Komodor_Service>;
  /** fetch aggregated fields from the table: "komodor_service" */
  komodor_service_aggregate: Komodor_Service_Aggregate;
  /** fetch data from the table: "komodor_service_attributes" */
  komodor_service_attributes: Array<Komodor_Service_Attributes>;
  /** fetch aggregated fields from the table: "komodor_service_attributes" */
  komodor_service_attributes_aggregate: Komodor_Service_Attributes_Aggregate;
  /** fetch data from the table: "komodor_service_attributes" using primary key columns */
  komodor_service_attributes_by_pk?: Maybe<Komodor_Service_Attributes>;
  /** fetch data from the table: "komodor_service" using primary key columns */
  komodor_service_by_pk?: Maybe<Komodor_Service>;
  /** fetch data from the table: "komodor_service_datadog_data" */
  komodor_service_datadog_data: Array<Komodor_Service_Datadog_Data>;
  /** fetch aggregated fields from the table: "komodor_service_datadog_data" */
  komodor_service_datadog_data_aggregate: Komodor_Service_Datadog_Data_Aggregate;
  /** fetch data from the table: "komodor_service_datadog_data" using primary key columns */
  komodor_service_datadog_data_by_pk?: Maybe<Komodor_Service_Datadog_Data>;
  /** fetch data from the table: "komodor_service_deploy_state" */
  komodor_service_deploy_state: Array<Komodor_Service_Deploy_State>;
  /** fetch aggregated fields from the table: "komodor_service_deploy_state" */
  komodor_service_deploy_state_aggregate: Komodor_Service_Deploy_State_Aggregate;
  /** fetch data from the table: "komodor_service_deploy_state" using primary key columns */
  komodor_service_deploy_state_by_pk?: Maybe<Komodor_Service_Deploy_State>;
  /** fetch data from the table: "komodor_service_health_state" */
  komodor_service_health_state: Array<Komodor_Service_Health_State>;
  /** fetch aggregated fields from the table: "komodor_service_health_state" */
  komodor_service_health_state_aggregate: Komodor_Service_Health_State_Aggregate;
  /** fetch data from the table: "komodor_service_health_state" using primary key columns */
  komodor_service_health_state_by_pk?: Maybe<Komodor_Service_Health_State>;
  /** fetch data from the table: "komodor_service_k8s_metadata" */
  komodor_service_k8s_metadata: Array<Komodor_Service_K8s_Metadata>;
  /** fetch aggregated fields from the table: "komodor_service_k8s_metadata" */
  komodor_service_k8s_metadata_aggregate: Komodor_Service_K8s_Metadata_Aggregate;
  /** fetch data from the table: "komodor_service_k8s_metadata" using primary key columns */
  komodor_service_k8s_metadata_by_pk?: Maybe<Komodor_Service_K8s_Metadata>;
  /** fetch data from the table: "komodor_service_sentry_data" */
  komodor_service_sentry_data: Array<Komodor_Service_Sentry_Data>;
  /** fetch aggregated fields from the table: "komodor_service_sentry_data" */
  komodor_service_sentry_data_aggregate: Komodor_Service_Sentry_Data_Aggregate;
  /** fetch data from the table: "komodor_service_sentry_data" using primary key columns */
  komodor_service_sentry_data_by_pk?: Maybe<Komodor_Service_Sentry_Data>;
  /** fetch data from the table: "komodor_service_static_prevention_state" */
  komodor_service_static_prevention_state: Array<Komodor_Service_Static_Prevention_State>;
  /** fetch aggregated fields from the table: "komodor_service_static_prevention_state" */
  komodor_service_static_prevention_state_aggregate: Komodor_Service_Static_Prevention_State_Aggregate;
  /** fetch data from the table: "komodor_service_static_prevention_state" using primary key columns */
  komodor_service_static_prevention_state_by_pk?: Maybe<Komodor_Service_Static_Prevention_State>;
  /** fetch data from the table: "kubernetes_agent_info" */
  kubernetes_agent_info: Array<Kubernetes_Agent_Info>;
  /** fetch aggregated fields from the table: "kubernetes_agent_info" */
  kubernetes_agent_info_aggregate: Kubernetes_Agent_Info_Aggregate;
  /** fetch data from the table: "kubernetes_agent_info" using primary key columns */
  kubernetes_agent_info_by_pk?: Maybe<Kubernetes_Agent_Info>;
  listAgentCrashReports?: Maybe<AgentCrashReportList>;
  /** fetch data from the table: "node_status_agent_task" */
  node_status_agent_task: Array<Node_Status_Agent_Task>;
  /** fetch aggregated fields from the table: "node_status_agent_task" */
  node_status_agent_task_aggregate: Node_Status_Agent_Task_Aggregate;
  /** fetch data from the table: "node_status_agent_task" using primary key columns */
  node_status_agent_task_by_pk?: Maybe<Node_Status_Agent_Task>;
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch aggregated fields from the table: "plan" */
  plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table: "pod_list" */
  pod_list: Array<Pod_List>;
  /** fetch aggregated fields from the table: "pod_list" */
  pod_list_aggregate: Pod_List_Aggregate;
  /** fetch data from the table: "pod_list" using primary key columns */
  pod_list_by_pk?: Maybe<Pod_List>;
  /** fetch data from the table: "pod_logs" */
  pod_logs: Array<Pod_Logs>;
  /** fetch aggregated fields from the table: "pod_logs" */
  pod_logs_aggregate: Pod_Logs_Aggregate;
  /** fetch data from the table: "pod_logs" using primary key columns */
  pod_logs_by_pk?: Maybe<Pod_Logs>;
  /** fetch data from the table: "rbac_policy" */
  rbac_policy: Array<Rbac_Policy>;
  /** fetch data from the table: "rbac_policy_action" */
  rbac_policy_action: Array<Rbac_Policy_Action>;
  /** fetch aggregated fields from the table: "rbac_policy_action" */
  rbac_policy_action_aggregate: Rbac_Policy_Action_Aggregate;
  /** fetch data from the table: "rbac_policy_action" using primary key columns */
  rbac_policy_action_by_pk?: Maybe<Rbac_Policy_Action>;
  /** fetch data from the table: "rbac_policy_action" */
  rbac_policy_actions: Array<Rbac_Policy_Actions>;
  /** fetch aggregated fields from the table: "rbac_policy_action" */
  rbac_policy_actions_aggregate: Rbac_Policy_Actions_Aggregate;
  /** fetch data from the table: "rbac_policy_action" using primary key columns */
  rbac_policy_actions_by_pk?: Maybe<Rbac_Policy_Actions>;
  /** fetch aggregated fields from the table: "rbac_policy" */
  rbac_policy_aggregate: Rbac_Policy_Aggregate;
  /** fetch data from the table: "rbac_policy" using primary key columns */
  rbac_policy_by_pk?: Maybe<Rbac_Policy>;
  /** fetch data from the table: "rbac_role" */
  rbac_role: Array<Rbac_Role>;
  /** fetch aggregated fields from the table: "rbac_role" */
  rbac_role_aggregate: Rbac_Role_Aggregate;
  /** fetch data from the table: "rbac_role" using primary key columns */
  rbac_role_by_pk?: Maybe<Rbac_Role>;
  /** fetch data from the table: "rbac_role_policy" */
  rbac_role_policy: Array<Rbac_Role_Policy>;
  /** fetch aggregated fields from the table: "rbac_role_policy" */
  rbac_role_policy_aggregate: Rbac_Role_Policy_Aggregate;
  /** fetch data from the table: "rbac_role_policy" using primary key columns */
  rbac_role_policy_by_pk?: Maybe<Rbac_Role_Policy>;
  /** fetch data from the table: "rbac_user_role" */
  rbac_user_role: Array<Rbac_User_Role>;
  /** fetch aggregated fields from the table: "rbac_user_role" */
  rbac_user_role_aggregate: Rbac_User_Role_Aggregate;
  /** fetch data from the table: "rbac_user_role" using primary key columns */
  rbac_user_role_by_pk?: Maybe<Rbac_User_Role>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "service_attributes_for_account" */
  service_attributes_for_account: Array<Service_Attributes_For_Account>;
  /** fetch aggregated fields from the table: "service_attributes_for_account" */
  service_attributes_for_account_aggregate: Service_Attributes_For_Account_Aggregate;
  /** fetch data from the table: "service_attributes_for_account" using primary key columns */
  service_attributes_for_account_by_pk?: Maybe<Service_Attributes_For_Account>;
  /** fetch data from the table: "service_description" */
  service_description: Array<Service_Description>;
  /** fetch aggregated fields from the table: "service_description" */
  service_description_aggregate: Service_Description_Aggregate;
  /** fetch data from the table: "service_description" using primary key columns */
  service_description_by_pk?: Maybe<Service_Description>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  validateOpsgenieApiKey?: Maybe<OpsgenieValidateResponse>;
  /** fetch data from the table: "workflow_configuration" */
  workflow_configuration: Array<Workflow_Configuration>;
  /** fetch aggregated fields from the table: "workflow_configuration" */
  workflow_configuration_aggregate: Workflow_Configuration_Aggregate;
  /** fetch data from the table: "workflow_configuration" using primary key columns */
  workflow_configuration_by_pk?: Maybe<Workflow_Configuration>;
  /** fetch data from the table: "workflow_results" */
  workflow_results: Array<Workflow_Results>;
  /** fetch aggregated fields from the table: "workflow_results" */
  workflow_results_aggregate: Workflow_Results_Aggregate;
  /** fetch data from the table: "workflow_results" using primary key columns */
  workflow_results_by_pk?: Maybe<Workflow_Results>;
};


export type Query_Root_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type Query_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_Domain_MappingArgs = {
  distinct_on?: Maybe<Array<Account_Domain_Mapping_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Domain_Mapping_Order_By>>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};


export type Query_RootAccount_Domain_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Domain_Mapping_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Domain_Mapping_Order_By>>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};


export type Query_RootAccount_Domain_Mapping_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccount_Event_LimitsArgs = {
  distinct_on?: Maybe<Array<Account_Event_Limits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Event_Limits_Order_By>>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};


export type Query_RootAccount_Event_Limits_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Event_Limits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Event_Limits_Order_By>>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};


export type Query_RootAccount_Event_Limits_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAgent_TaskArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Order_By>>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};


export type Query_RootAgent_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Order_By>>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};


export type Query_RootAgent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAgent_Task_ResultArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Result_Order_By>>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};


export type Query_RootAgent_Task_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Result_Order_By>>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};


export type Query_RootAgent_Task_Result_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApi_KeyArgs = {
  distinct_on?: Maybe<Array<Api_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Key_Order_By>>;
  where?: Maybe<Api_Key_Bool_Exp>;
};


export type Query_RootApi_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Key_Order_By>>;
  where?: Maybe<Api_Key_Bool_Exp>;
};


export type Query_RootApi_Key_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_ViewArgs = {
  distinct_on?: Maybe<Array<App_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_View_Order_By>>;
  where?: Maybe<App_View_Bool_Exp>;
};


export type Query_RootApp_View_AggregateArgs = {
  distinct_on?: Maybe<Array<App_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_View_Order_By>>;
  where?: Maybe<App_View_Bool_Exp>;
};


export type Query_RootApp_View_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuditArgs = {
  distinct_on?: Maybe<Array<Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Order_By>>;
  where?: Maybe<Audit_Bool_Exp>;
};


export type Query_RootAudit_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Order_By>>;
  where?: Maybe<Audit_Bool_Exp>;
};


export type Query_RootAudit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChannel_ConfigurationArgs = {
  distinct_on?: Maybe<Array<Channel_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Configuration_Order_By>>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};


export type Query_RootChannel_Configuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Configuration_Order_By>>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};


export type Query_RootChannel_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEvent_ActionsArgs = {
  distinct_on?: Maybe<Array<Event_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Actions_Order_By>>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};


export type Query_RootEvent_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Actions_Order_By>>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};


export type Query_RootEvent_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEvent_Config_MapArgs = {
  distinct_on?: Maybe<Array<Event_Config_Map_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Config_Map_Order_By>>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};


export type Query_RootEvent_Config_Map_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Config_Map_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Config_Map_Order_By>>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};


export type Query_RootEvent_Config_Map_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Cron_JobArgs = {
  distinct_on?: Maybe<Array<Event_Cron_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Cron_Job_Order_By>>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};


export type Query_RootEvent_Cron_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Cron_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Cron_Job_Order_By>>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};


export type Query_RootEvent_Cron_Job_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Datadog_MonitorArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Monitor_Order_By>>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};


export type Query_RootEvent_Datadog_Monitor_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Monitor_Order_By>>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};


export type Query_RootEvent_Datadog_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Datadog_TracingArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Tracing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Tracing_Order_By>>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};


export type Query_RootEvent_Datadog_Tracing_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Tracing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Tracing_Order_By>>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};


export type Query_RootEvent_Datadog_Tracing_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_DeployArgs = {
  distinct_on?: Maybe<Array<Event_Deploy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Deploy_Order_By>>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};


export type Query_RootEvent_Deploy_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Deploy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Deploy_Order_By>>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};


export type Query_RootEvent_Deploy_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_HpaArgs = {
  distinct_on?: Maybe<Array<Event_Hpa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Hpa_Order_By>>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};


export type Query_RootEvent_Hpa_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Hpa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Hpa_Order_By>>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};


export type Query_RootEvent_Hpa_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_IngressArgs = {
  distinct_on?: Maybe<Array<Event_Ingress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Ingress_Order_By>>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};


export type Query_RootEvent_Ingress_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Ingress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Ingress_Order_By>>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};


export type Query_RootEvent_Ingress_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_JobArgs = {
  distinct_on?: Maybe<Array<Event_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Job_Order_By>>;
  where?: Maybe<Event_Job_Bool_Exp>;
};


export type Query_RootEvent_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Job_Order_By>>;
  where?: Maybe<Event_Job_Bool_Exp>;
};


export type Query_RootEvent_Job_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Komodor_AlertArgs = {
  distinct_on?: Maybe<Array<Event_Komodor_Alert_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Komodor_Alert_Order_By>>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};


export type Query_RootEvent_Komodor_Alert_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Komodor_Alert_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Komodor_Alert_Order_By>>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};


export type Query_RootEvent_Komodor_Alert_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Kubernetes_Service_ResourceArgs = {
  distinct_on?: Maybe<Array<Event_Kubernetes_Service_Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Kubernetes_Service_Resource_Order_By>>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};


export type Query_RootEvent_Kubernetes_Service_Resource_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Kubernetes_Service_Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Kubernetes_Service_Resource_Order_By>>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};


export type Query_RootEvent_Kubernetes_Service_Resource_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_LaunchdarklyArgs = {
  distinct_on?: Maybe<Array<Event_Launchdarkly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Launchdarkly_Order_By>>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};


export type Query_RootEvent_Launchdarkly_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Launchdarkly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Launchdarkly_Order_By>>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};


export type Query_RootEvent_Launchdarkly_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Limit_RangesArgs = {
  distinct_on?: Maybe<Array<Event_Limit_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Limit_Ranges_Order_By>>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};


export type Query_RootEvent_Limit_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Limit_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Limit_Ranges_Order_By>>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};


export type Query_RootEvent_Limit_Ranges_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_MonitorArgs = {
  distinct_on?: Maybe<Array<Event_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Monitor_Order_By>>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};


export type Query_RootEvent_Monitor_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Monitor_Order_By>>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};


export type Query_RootEvent_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Network_PoliciesArgs = {
  distinct_on?: Maybe<Array<Event_Network_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Network_Policies_Order_By>>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};


export type Query_RootEvent_Network_Policies_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Network_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Network_Policies_Order_By>>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};


export type Query_RootEvent_Network_Policies_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_NodeArgs = {
  distinct_on?: Maybe<Array<Event_Node_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Node_Order_By>>;
  where?: Maybe<Event_Node_Bool_Exp>;
};


export type Query_RootEvent_Node_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Node_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Node_Order_By>>;
  where?: Maybe<Event_Node_Bool_Exp>;
};


export type Query_RootEvent_Node_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_OpsgenieArgs = {
  distinct_on?: Maybe<Array<Event_Opsgenie_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Opsgenie_Order_By>>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};


export type Query_RootEvent_Opsgenie_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Opsgenie_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Opsgenie_Order_By>>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};


export type Query_RootEvent_Opsgenie_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_PagerdutyArgs = {
  distinct_on?: Maybe<Array<Event_Pagerduty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pagerduty_Order_By>>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};


export type Query_RootEvent_Pagerduty_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Pagerduty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pagerduty_Order_By>>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};


export type Query_RootEvent_Pagerduty_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_PdbArgs = {
  distinct_on?: Maybe<Array<Event_Pdb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pdb_Order_By>>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};


export type Query_RootEvent_Pdb_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Pdb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pdb_Order_By>>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};


export type Query_RootEvent_Pdb_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Resource_QuotaArgs = {
  distinct_on?: Maybe<Array<Event_Resource_Quota_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Resource_Quota_Order_By>>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};


export type Query_RootEvent_Resource_Quota_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Resource_Quota_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Resource_Quota_Order_By>>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};


export type Query_RootEvent_Resource_Quota_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_SecretArgs = {
  distinct_on?: Maybe<Array<Event_Secret_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Secret_Order_By>>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};


export type Query_RootEvent_Secret_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Secret_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Secret_Order_By>>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};


export type Query_RootEvent_Secret_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Sentry_IssueArgs = {
  distinct_on?: Maybe<Array<Event_Sentry_Issue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sentry_Issue_Order_By>>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};


export type Query_RootEvent_Sentry_Issue_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sentry_Issue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sentry_Issue_Order_By>>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};


export type Query_RootEvent_Sentry_Issue_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Stale_Kubernetes_AgentArgs = {
  distinct_on?: Maybe<Array<Event_Stale_Kubernetes_Agent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Stale_Kubernetes_Agent_Order_By>>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};


export type Query_RootEvent_Stale_Kubernetes_Agent_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Stale_Kubernetes_Agent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Stale_Kubernetes_Agent_Order_By>>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};


export type Query_RootEvent_Stale_Kubernetes_Agent_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_TopologyArgs = {
  distinct_on?: Maybe<Array<Event_Topology_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Topology_Order_By>>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};


export type Query_RootEvent_Topology_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Topology_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Topology_Order_By>>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};


export type Query_RootEvent_Topology_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_WorkloadArgs = {
  distinct_on?: Maybe<Array<Event_Workload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Workload_Order_By>>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};


export type Query_RootEvent_Workload_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Workload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Workload_Order_By>>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};


export type Query_RootEvent_Workload_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootExternal_LinkArgs = {
  distinct_on?: Maybe<Array<External_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Order_By>>;
  where?: Maybe<External_Link_Bool_Exp>;
};


export type Query_RootExternal_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<External_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Order_By>>;
  where?: Maybe<External_Link_Bool_Exp>;
};


export type Query_RootExternal_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternal_Link_ContextArgs = {
  distinct_on?: Maybe<Array<External_Link_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Context_Order_By>>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};


export type Query_RootExternal_Link_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<External_Link_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Context_Order_By>>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};


export type Query_RootExternal_Link_Context_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFetchAllJobsArgs = {
  fetchJobsFromMaterializedView: Scalars['Boolean'];
  includeAnnotations?: Maybe<Scalars['Boolean']>;
  includeLabels?: Maybe<Scalars['Boolean']>;
};


export type Query_RootFetchAllServicesArgs = {
  fetchServicesFromMaterializedView: Scalars['Boolean'];
  includeAnnotations?: Maybe<Scalars['Boolean']>;
  includeLabels?: Maybe<Scalars['Boolean']>;
};


export type Query_RootFetchEventsArgs = {
  epochEnd?: Maybe<Scalars['bigint']>;
  epochStart?: Maybe<Scalars['bigint']>;
  filters?: Maybe<Scalars['jsonb']>;
  flags?: Maybe<Scalars['jsonb']>;
  ignoreEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type Query_RootFetchEventsTimelineArgs = {
  buckets?: Maybe<Scalars['Int']>;
  epochEnd?: Maybe<Scalars['bigint']>;
  epochStart?: Maybe<Scalars['bigint']>;
  filters?: Maybe<Scalars['jsonb']>;
  flags?: Maybe<Scalars['jsonb']>;
  ignoreEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootFetchFilterValuesArgs = {
  epochEnd?: Maybe<Scalars['bigint']>;
  epochStart?: Maybe<Scalars['bigint']>;
  filters?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
  flags?: Maybe<Scalars['jsonb']>;
};


export type Query_RootGetAgentCrashReportArgs = {
  key: Scalars['String'];
};


export type Query_RootHelm_RepoArgs = {
  distinct_on?: Maybe<Array<Helm_Repo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Helm_Repo_Order_By>>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};


export type Query_RootHelm_Repo_AggregateArgs = {
  distinct_on?: Maybe<Array<Helm_Repo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Helm_Repo_Order_By>>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};


export type Query_RootHelm_Repo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInstallationArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


export type Query_RootInstallation_AggregateArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


export type Query_RootInstallation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKomodor_Filter_SettingsArgs = {
  distinct_on?: Maybe<Array<Komodor_Filter_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Filter_Settings_Order_By>>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};


export type Query_RootKomodor_Filter_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Filter_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Filter_Settings_Order_By>>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};


export type Query_RootKomodor_Filter_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKomodor_ServiceArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Order_By>>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};


export type Query_RootKomodor_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Order_By>>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};


export type Query_RootKomodor_Service_AttributesArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Attributes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Attributes_Order_By>>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};


export type Query_RootKomodor_Service_Attributes_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Attributes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Attributes_Order_By>>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};


export type Query_RootKomodor_Service_Attributes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKomodor_Service_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_Datadog_DataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Datadog_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Datadog_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};


export type Query_RootKomodor_Service_Datadog_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Datadog_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Datadog_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};


export type Query_RootKomodor_Service_Datadog_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_Deploy_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Deploy_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Deploy_State_Order_By>>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Deploy_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Deploy_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Deploy_State_Order_By>>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Deploy_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_Health_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Health_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Health_State_Order_By>>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Health_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Health_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Health_State_Order_By>>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Health_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_K8s_MetadataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_K8s_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_K8s_Metadata_Order_By>>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};


export type Query_RootKomodor_Service_K8s_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_K8s_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_K8s_Metadata_Order_By>>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};


export type Query_RootKomodor_Service_K8s_Metadata_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_Sentry_DataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Sentry_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Sentry_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};


export type Query_RootKomodor_Service_Sentry_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Sentry_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Sentry_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};


export type Query_RootKomodor_Service_Sentry_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKomodor_Service_Static_Prevention_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Static_Prevention_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Static_Prevention_State_Order_By>>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Static_Prevention_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Static_Prevention_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Static_Prevention_State_Order_By>>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};


export type Query_RootKomodor_Service_Static_Prevention_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootKubernetes_Agent_InfoArgs = {
  distinct_on?: Maybe<Array<Kubernetes_Agent_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kubernetes_Agent_Info_Order_By>>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};


export type Query_RootKubernetes_Agent_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Kubernetes_Agent_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kubernetes_Agent_Info_Order_By>>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};


export type Query_RootKubernetes_Agent_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootListAgentCrashReportsArgs = {
  accountName: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
};


export type Query_RootNode_Status_Agent_TaskArgs = {
  distinct_on?: Maybe<Array<Node_Status_Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Node_Status_Agent_Task_Order_By>>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};


export type Query_RootNode_Status_Agent_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Node_Status_Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Node_Status_Agent_Task_Order_By>>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};


export type Query_RootNode_Status_Agent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlanArgs = {
  distinct_on?: Maybe<Array<Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Order_By>>;
  where?: Maybe<Plan_Bool_Exp>;
};


export type Query_RootPlan_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Order_By>>;
  where?: Maybe<Plan_Bool_Exp>;
};


export type Query_RootPlan_By_PkArgs = {
  accountPlan: Scalars['String'];
};


export type Query_RootPod_ListArgs = {
  distinct_on?: Maybe<Array<Pod_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_List_Order_By>>;
  where?: Maybe<Pod_List_Bool_Exp>;
};


export type Query_RootPod_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Pod_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_List_Order_By>>;
  where?: Maybe<Pod_List_Bool_Exp>;
};


export type Query_RootPod_List_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPod_LogsArgs = {
  distinct_on?: Maybe<Array<Pod_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_Logs_Order_By>>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};


export type Query_RootPod_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Pod_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_Logs_Order_By>>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};


export type Query_RootPod_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRbac_PolicyArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Order_By>>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};


export type Query_RootRbac_Policy_ActionArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Action_Order_By>>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};


export type Query_RootRbac_Policy_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Action_Order_By>>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};


export type Query_RootRbac_Policy_Action_By_PkArgs = {
  action: Scalars['String'];
};


export type Query_RootRbac_Policy_ActionsArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Actions_Order_By>>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};


export type Query_RootRbac_Policy_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Actions_Order_By>>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};


export type Query_RootRbac_Policy_Actions_By_PkArgs = {
  action: Scalars['String'];
};


export type Query_RootRbac_Policy_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Order_By>>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};


export type Query_RootRbac_Policy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRbac_RoleArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Order_By>>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};


export type Query_RootRbac_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Order_By>>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};


export type Query_RootRbac_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRbac_Role_PolicyArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


export type Query_RootRbac_Role_Policy_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


export type Query_RootRbac_Role_Policy_By_PkArgs = {
  policyId: Scalars['uuid'];
  roleId: Scalars['uuid'];
};


export type Query_RootRbac_User_RoleArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


export type Query_RootRbac_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


export type Query_RootRbac_User_Role_By_PkArgs = {
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Query_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootService_Attributes_For_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Attributes_For_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Attributes_For_Account_Order_By>>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};


export type Query_RootService_Attributes_For_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Attributes_For_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Attributes_For_Account_Order_By>>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};


export type Query_RootService_Attributes_For_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootService_DescriptionArgs = {
  distinct_on?: Maybe<Array<Service_Description_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Description_Order_By>>;
  where?: Maybe<Service_Description_Bool_Exp>;
};


export type Query_RootService_Description_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Description_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Description_Order_By>>;
  where?: Maybe<Service_Description_Bool_Exp>;
};


export type Query_RootService_Description_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootValidateOpsgenieApiKeyArgs = {
  apiKey: Scalars['String'];
};


export type Query_RootWorkflow_ConfigurationArgs = {
  distinct_on?: Maybe<Array<Workflow_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Configuration_Order_By>>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};


export type Query_RootWorkflow_Configuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Configuration_Order_By>>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};


export type Query_RootWorkflow_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWorkflow_ResultsArgs = {
  distinct_on?: Maybe<Array<Workflow_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Results_Order_By>>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};


export type Query_RootWorkflow_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Results_Order_By>>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};


export type Query_RootWorkflow_Results_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "rbac_policy" */
export type Rbac_Policy = {
  __typename?: 'rbac_policy';
  accountId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  statements: Scalars['jsonb'];
  tags: Scalars['jsonb'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "rbac_policy" */
export type Rbac_PolicyStatementsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "rbac_policy" */
export type Rbac_PolicyTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "rbac_policy_action" */
export type Rbac_Policy_Action = {
  __typename?: 'rbac_policy_action';
  action: Scalars['String'];
  description: Scalars['String'];
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "rbac_policy_action" */
export type Rbac_Policy_ActionK8sRulesetArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rbac_policy_action" */
export type Rbac_Policy_Action_Aggregate = {
  __typename?: 'rbac_policy_action_aggregate';
  aggregate?: Maybe<Rbac_Policy_Action_Aggregate_Fields>;
  nodes: Array<Rbac_Policy_Action>;
};

/** aggregate fields of "rbac_policy_action" */
export type Rbac_Policy_Action_Aggregate_Fields = {
  __typename?: 'rbac_policy_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_Policy_Action_Max_Fields>;
  min?: Maybe<Rbac_Policy_Action_Min_Fields>;
};


/** aggregate fields of "rbac_policy_action" */
export type Rbac_Policy_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_Policy_Action_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Action_Append_Input = {
  k8sRuleset?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "rbac_policy_action". All fields are combined with a logical 'AND'. */
export type Rbac_Policy_Action_Bool_Exp = {
  _and?: Maybe<Array<Rbac_Policy_Action_Bool_Exp>>;
  _not?: Maybe<Rbac_Policy_Action_Bool_Exp>;
  _or?: Maybe<Array<Rbac_Policy_Action_Bool_Exp>>;
  action?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  k8sRuleset?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_policy_action" */
export enum Rbac_Policy_Action_Constraint {
  /** unique or primary key constraint on columns "action" */
  PkA117df710581e57295feac315e2 = 'PK_a117df710581e57295feac315e2'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rbac_Policy_Action_Delete_At_Path_Input = {
  k8sRuleset?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rbac_Policy_Action_Delete_Elem_Input = {
  k8sRuleset?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rbac_Policy_Action_Delete_Key_Input = {
  k8sRuleset?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "rbac_policy_action" */
export type Rbac_Policy_Action_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rbac_Policy_Action_Max_Fields = {
  __typename?: 'rbac_policy_action_max_fields';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rbac_Policy_Action_Min_Fields = {
  __typename?: 'rbac_policy_action_min_fields';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rbac_policy_action" */
export type Rbac_Policy_Action_Mutation_Response = {
  __typename?: 'rbac_policy_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_Policy_Action>;
};

/** on_conflict condition type for table "rbac_policy_action" */
export type Rbac_Policy_Action_On_Conflict = {
  constraint: Rbac_Policy_Action_Constraint;
  update_columns?: Array<Rbac_Policy_Action_Update_Column>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_policy_action". */
export type Rbac_Policy_Action_Order_By = {
  action?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  k8sRuleset?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_policy_action */
export type Rbac_Policy_Action_Pk_Columns_Input = {
  action: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Action_Prepend_Input = {
  k8sRuleset?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "rbac_policy_action" */
export enum Rbac_Policy_Action_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Description = 'description',
  /** column name */
  K8sRuleset = 'k8sRuleset',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "rbac_policy_action" */
export type Rbac_Policy_Action_Set_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "rbac_policy_action" */
export type Rbac_Policy_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_Policy_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_Policy_Action_Stream_Cursor_Value_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "rbac_policy_action" */
export enum Rbac_Policy_Action_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Description = 'description',
  /** column name */
  K8sRuleset = 'k8sRuleset',
  /** column name */
  Type = 'type'
}

export type Rbac_Policy_Action_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Rbac_Policy_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Rbac_Policy_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Rbac_Policy_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Rbac_Policy_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Rbac_Policy_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_Policy_Action_Set_Input>;
  where: Rbac_Policy_Action_Bool_Exp;
};

/** columns and relationships of "rbac_policy_action" */
export type Rbac_Policy_Actions = {
  __typename?: 'rbac_policy_actions';
  action: Scalars['String'];
  description: Scalars['String'];
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "rbac_policy_action" */
export type Rbac_Policy_ActionsK8sRulesetArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rbac_policy_action" */
export type Rbac_Policy_Actions_Aggregate = {
  __typename?: 'rbac_policy_actions_aggregate';
  aggregate?: Maybe<Rbac_Policy_Actions_Aggregate_Fields>;
  nodes: Array<Rbac_Policy_Actions>;
};

/** aggregate fields of "rbac_policy_action" */
export type Rbac_Policy_Actions_Aggregate_Fields = {
  __typename?: 'rbac_policy_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_Policy_Actions_Max_Fields>;
  min?: Maybe<Rbac_Policy_Actions_Min_Fields>;
};


/** aggregate fields of "rbac_policy_action" */
export type Rbac_Policy_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_Policy_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Actions_Append_Input = {
  k8sRuleset?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "rbac_policy_action". All fields are combined with a logical 'AND'. */
export type Rbac_Policy_Actions_Bool_Exp = {
  _and?: Maybe<Array<Rbac_Policy_Actions_Bool_Exp>>;
  _not?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
  _or?: Maybe<Array<Rbac_Policy_Actions_Bool_Exp>>;
  action?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  k8sRuleset?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_policy_action" */
export enum Rbac_Policy_Actions_Constraint {
  /** unique or primary key constraint on columns "action" */
  PkA117df710581e57295feac315e2 = 'PK_a117df710581e57295feac315e2'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rbac_Policy_Actions_Delete_At_Path_Input = {
  k8sRuleset?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rbac_Policy_Actions_Delete_Elem_Input = {
  k8sRuleset?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rbac_Policy_Actions_Delete_Key_Input = {
  k8sRuleset?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "rbac_policy_action" */
export type Rbac_Policy_Actions_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rbac_Policy_Actions_Max_Fields = {
  __typename?: 'rbac_policy_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rbac_Policy_Actions_Min_Fields = {
  __typename?: 'rbac_policy_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rbac_policy_action" */
export type Rbac_Policy_Actions_Mutation_Response = {
  __typename?: 'rbac_policy_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_Policy_Actions>;
};

/** on_conflict condition type for table "rbac_policy_action" */
export type Rbac_Policy_Actions_On_Conflict = {
  constraint: Rbac_Policy_Actions_Constraint;
  update_columns?: Array<Rbac_Policy_Actions_Update_Column>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_policy_action". */
export type Rbac_Policy_Actions_Order_By = {
  action?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  k8sRuleset?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_policy_action */
export type Rbac_Policy_Actions_Pk_Columns_Input = {
  action: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Actions_Prepend_Input = {
  k8sRuleset?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "rbac_policy_action" */
export enum Rbac_Policy_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Description = 'description',
  /** column name */
  K8sRuleset = 'k8sRuleset',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "rbac_policy_action" */
export type Rbac_Policy_Actions_Set_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "rbac_policy_actions" */
export type Rbac_Policy_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_Policy_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_Policy_Actions_Stream_Cursor_Value_Input = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  k8sRuleset?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "rbac_policy_action" */
export enum Rbac_Policy_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Description = 'description',
  /** column name */
  K8sRuleset = 'k8sRuleset',
  /** column name */
  Type = 'type'
}

export type Rbac_Policy_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Rbac_Policy_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Rbac_Policy_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Rbac_Policy_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Rbac_Policy_Actions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Rbac_Policy_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_Policy_Actions_Set_Input>;
  where: Rbac_Policy_Actions_Bool_Exp;
};

/** aggregated selection of "rbac_policy" */
export type Rbac_Policy_Aggregate = {
  __typename?: 'rbac_policy_aggregate';
  aggregate?: Maybe<Rbac_Policy_Aggregate_Fields>;
  nodes: Array<Rbac_Policy>;
};

/** aggregate fields of "rbac_policy" */
export type Rbac_Policy_Aggregate_Fields = {
  __typename?: 'rbac_policy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_Policy_Max_Fields>;
  min?: Maybe<Rbac_Policy_Min_Fields>;
};


/** aggregate fields of "rbac_policy" */
export type Rbac_Policy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_Policy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Append_Input = {
  statements?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "rbac_policy". All fields are combined with a logical 'AND'. */
export type Rbac_Policy_Bool_Exp = {
  _and?: Maybe<Array<Rbac_Policy_Bool_Exp>>;
  _not?: Maybe<Rbac_Policy_Bool_Exp>;
  _or?: Maybe<Array<Rbac_Policy_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  statements?: Maybe<Jsonb_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_policy" */
export enum Rbac_Policy_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD04bace5e14659794d6b053a0a4 = 'PK_d04bace5e14659794d6b053a0a4',
  /** unique or primary key constraint on columns "name", "accountId" */
  IdxRbacPolicyUniqueAccountAndName = 'idx_rbac_policy_unique_account_and_name'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rbac_Policy_Delete_At_Path_Input = {
  statements?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rbac_Policy_Delete_Elem_Input = {
  statements?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rbac_Policy_Delete_Key_Input = {
  statements?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "rbac_policy" */
export type Rbac_Policy_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  statements?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rbac_Policy_Max_Fields = {
  __typename?: 'rbac_policy_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Rbac_Policy_Min_Fields = {
  __typename?: 'rbac_policy_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rbac_policy" */
export type Rbac_Policy_Mutation_Response = {
  __typename?: 'rbac_policy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_Policy>;
};

/** input type for inserting object relation for remote table "rbac_policy" */
export type Rbac_Policy_Obj_Rel_Insert_Input = {
  data: Rbac_Policy_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rbac_Policy_On_Conflict>;
};

/** on_conflict condition type for table "rbac_policy" */
export type Rbac_Policy_On_Conflict = {
  constraint: Rbac_Policy_Constraint;
  update_columns?: Array<Rbac_Policy_Update_Column>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_policy". */
export type Rbac_Policy_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  statements?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_policy */
export type Rbac_Policy_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rbac_Policy_Prepend_Input = {
  statements?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "rbac_policy" */
export enum Rbac_Policy_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Statements = 'statements',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rbac_policy" */
export type Rbac_Policy_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  statements?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "rbac_policy" */
export type Rbac_Policy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_Policy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_Policy_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  statements?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "rbac_policy" */
export enum Rbac_Policy_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Statements = 'statements',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rbac_Policy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Rbac_Policy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Rbac_Policy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Rbac_Policy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Rbac_Policy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Rbac_Policy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_Policy_Set_Input>;
  where: Rbac_Policy_Bool_Exp;
};

/** columns and relationships of "rbac_role" */
export type Rbac_Role = {
  __typename?: 'rbac_role';
  accountId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  rbac_role_policies: Array<Rbac_Role_Policy>;
  /** An aggregate relationship */
  rbac_role_policies_aggregate: Rbac_Role_Policy_Aggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "rbac_role" */
export type Rbac_RoleRbac_Role_PoliciesArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


/** columns and relationships of "rbac_role" */
export type Rbac_RoleRbac_Role_Policies_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};

/** aggregated selection of "rbac_role" */
export type Rbac_Role_Aggregate = {
  __typename?: 'rbac_role_aggregate';
  aggregate?: Maybe<Rbac_Role_Aggregate_Fields>;
  nodes: Array<Rbac_Role>;
};

/** aggregate fields of "rbac_role" */
export type Rbac_Role_Aggregate_Fields = {
  __typename?: 'rbac_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_Role_Max_Fields>;
  min?: Maybe<Rbac_Role_Min_Fields>;
};


/** aggregate fields of "rbac_role" */
export type Rbac_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rbac_role". All fields are combined with a logical 'AND'. */
export type Rbac_Role_Bool_Exp = {
  _and?: Maybe<Array<Rbac_Role_Bool_Exp>>;
  _not?: Maybe<Rbac_Role_Bool_Exp>;
  _or?: Maybe<Array<Rbac_Role_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isDefault?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  rbac_role_policies?: Maybe<Rbac_Role_Policy_Bool_Exp>;
  rbac_role_policies_aggregate?: Maybe<Rbac_Role_Policy_Aggregate_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_role" */
export enum Rbac_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_08c0a2609753e395bf239506853 = 'PK_08c0a2609753e395bf239506853'
}

/** input type for inserting data into table "rbac_role" */
export type Rbac_Role_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  rbac_role_policies?: Maybe<Rbac_Role_Policy_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rbac_Role_Max_Fields = {
  __typename?: 'rbac_role_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Rbac_Role_Min_Fields = {
  __typename?: 'rbac_role_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rbac_role" */
export type Rbac_Role_Mutation_Response = {
  __typename?: 'rbac_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_Role>;
};

/** input type for inserting object relation for remote table "rbac_role" */
export type Rbac_Role_Obj_Rel_Insert_Input = {
  data: Rbac_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rbac_Role_On_Conflict>;
};

/** on_conflict condition type for table "rbac_role" */
export type Rbac_Role_On_Conflict = {
  constraint: Rbac_Role_Constraint;
  update_columns?: Array<Rbac_Role_Update_Column>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_role". */
export type Rbac_Role_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDefault?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  rbac_role_policies_aggregate?: Maybe<Rbac_Role_Policy_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_role */
export type Rbac_Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "rbac_role_policy" */
export type Rbac_Role_Policy = {
  __typename?: 'rbac_role_policy';
  policyId: Scalars['uuid'];
  /** An object relationship */
  rbac_policy: Rbac_Policy;
  roleId: Scalars['uuid'];
};

/** aggregated selection of "rbac_role_policy" */
export type Rbac_Role_Policy_Aggregate = {
  __typename?: 'rbac_role_policy_aggregate';
  aggregate?: Maybe<Rbac_Role_Policy_Aggregate_Fields>;
  nodes: Array<Rbac_Role_Policy>;
};

export type Rbac_Role_Policy_Aggregate_Bool_Exp = {
  count?: Maybe<Rbac_Role_Policy_Aggregate_Bool_Exp_Count>;
};

export type Rbac_Role_Policy_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Rbac_Role_Policy_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rbac_role_policy" */
export type Rbac_Role_Policy_Aggregate_Fields = {
  __typename?: 'rbac_role_policy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_Role_Policy_Max_Fields>;
  min?: Maybe<Rbac_Role_Policy_Min_Fields>;
};


/** aggregate fields of "rbac_role_policy" */
export type Rbac_Role_Policy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rbac_role_policy" */
export type Rbac_Role_Policy_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rbac_Role_Policy_Max_Order_By>;
  min?: Maybe<Rbac_Role_Policy_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rbac_role_policy" */
export type Rbac_Role_Policy_Arr_Rel_Insert_Input = {
  data: Array<Rbac_Role_Policy_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rbac_Role_Policy_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rbac_role_policy". All fields are combined with a logical 'AND'. */
export type Rbac_Role_Policy_Bool_Exp = {
  _and?: Maybe<Array<Rbac_Role_Policy_Bool_Exp>>;
  _not?: Maybe<Rbac_Role_Policy_Bool_Exp>;
  _or?: Maybe<Array<Rbac_Role_Policy_Bool_Exp>>;
  policyId?: Maybe<Uuid_Comparison_Exp>;
  rbac_policy?: Maybe<Rbac_Policy_Bool_Exp>;
  roleId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_role_policy" */
export enum Rbac_Role_Policy_Constraint {
  /** unique or primary key constraint on columns "policyId", "roleId" */
  PkA5c85282e4ff2879d2fdf370961 = 'PK_a5c85282e4ff2879d2fdf370961'
}

/** input type for inserting data into table "rbac_role_policy" */
export type Rbac_Role_Policy_Insert_Input = {
  policyId?: Maybe<Scalars['uuid']>;
  rbac_policy?: Maybe<Rbac_Policy_Obj_Rel_Insert_Input>;
  roleId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rbac_Role_Policy_Max_Fields = {
  __typename?: 'rbac_role_policy_max_fields';
  policyId?: Maybe<Scalars['uuid']>;
  roleId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rbac_role_policy" */
export type Rbac_Role_Policy_Max_Order_By = {
  policyId?: Maybe<Order_By>;
  roleId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rbac_Role_Policy_Min_Fields = {
  __typename?: 'rbac_role_policy_min_fields';
  policyId?: Maybe<Scalars['uuid']>;
  roleId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rbac_role_policy" */
export type Rbac_Role_Policy_Min_Order_By = {
  policyId?: Maybe<Order_By>;
  roleId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rbac_role_policy" */
export type Rbac_Role_Policy_Mutation_Response = {
  __typename?: 'rbac_role_policy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_Role_Policy>;
};

/** on_conflict condition type for table "rbac_role_policy" */
export type Rbac_Role_Policy_On_Conflict = {
  constraint: Rbac_Role_Policy_Constraint;
  update_columns?: Array<Rbac_Role_Policy_Update_Column>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_role_policy". */
export type Rbac_Role_Policy_Order_By = {
  policyId?: Maybe<Order_By>;
  rbac_policy?: Maybe<Rbac_Policy_Order_By>;
  roleId?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_role_policy */
export type Rbac_Role_Policy_Pk_Columns_Input = {
  policyId: Scalars['uuid'];
  roleId: Scalars['uuid'];
};

/** select columns of table "rbac_role_policy" */
export enum Rbac_Role_Policy_Select_Column {
  /** column name */
  PolicyId = 'policyId',
  /** column name */
  RoleId = 'roleId'
}

/** input type for updating data in table "rbac_role_policy" */
export type Rbac_Role_Policy_Set_Input = {
  policyId?: Maybe<Scalars['uuid']>;
  roleId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rbac_role_policy" */
export type Rbac_Role_Policy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_Role_Policy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_Role_Policy_Stream_Cursor_Value_Input = {
  policyId?: Maybe<Scalars['uuid']>;
  roleId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rbac_role_policy" */
export enum Rbac_Role_Policy_Update_Column {
  /** column name */
  PolicyId = 'policyId',
  /** column name */
  RoleId = 'roleId'
}

export type Rbac_Role_Policy_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_Role_Policy_Set_Input>;
  where: Rbac_Role_Policy_Bool_Exp;
};

/** select columns of table "rbac_role" */
export enum Rbac_Role_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rbac_role" */
export type Rbac_Role_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "rbac_role" */
export type Rbac_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_Role_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "rbac_role" */
export enum Rbac_Role_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rbac_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_Role_Set_Input>;
  where: Rbac_Role_Bool_Exp;
};

/** columns and relationships of "rbac_user_role" */
export type Rbac_User_Role = {
  __typename?: 'rbac_user_role';
  expiration?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  rbac_role: Rbac_Role;
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "rbac_user_role" */
export type Rbac_User_Role_Aggregate = {
  __typename?: 'rbac_user_role_aggregate';
  aggregate?: Maybe<Rbac_User_Role_Aggregate_Fields>;
  nodes: Array<Rbac_User_Role>;
};

export type Rbac_User_Role_Aggregate_Bool_Exp = {
  count?: Maybe<Rbac_User_Role_Aggregate_Bool_Exp_Count>;
};

export type Rbac_User_Role_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Rbac_User_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rbac_user_role" */
export type Rbac_User_Role_Aggregate_Fields = {
  __typename?: 'rbac_user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rbac_User_Role_Max_Fields>;
  min?: Maybe<Rbac_User_Role_Min_Fields>;
};


/** aggregate fields of "rbac_user_role" */
export type Rbac_User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rbac_user_role" */
export type Rbac_User_Role_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rbac_User_Role_Max_Order_By>;
  min?: Maybe<Rbac_User_Role_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rbac_user_role" */
export type Rbac_User_Role_Arr_Rel_Insert_Input = {
  data: Array<Rbac_User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rbac_User_Role_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rbac_user_role". All fields are combined with a logical 'AND'. */
export type Rbac_User_Role_Bool_Exp = {
  _and?: Maybe<Array<Rbac_User_Role_Bool_Exp>>;
  _not?: Maybe<Rbac_User_Role_Bool_Exp>;
  _or?: Maybe<Array<Rbac_User_Role_Bool_Exp>>;
  expiration?: Maybe<Timestamptz_Comparison_Exp>;
  rbac_role?: Maybe<Rbac_Role_Bool_Exp>;
  roleId?: Maybe<Uuid_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rbac_user_role" */
export enum Rbac_User_Role_Constraint {
  /** unique or primary key constraint on columns "userId", "roleId" */
  Pk_5f2963c661cd89f3b841faf9c3d = 'PK_5f2963c661cd89f3b841faf9c3d'
}

/** input type for inserting data into table "rbac_user_role" */
export type Rbac_User_Role_Insert_Input = {
  expiration?: Maybe<Scalars['timestamptz']>;
  rbac_role?: Maybe<Rbac_Role_Obj_Rel_Insert_Input>;
  roleId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rbac_User_Role_Max_Fields = {
  __typename?: 'rbac_user_role_max_fields';
  expiration?: Maybe<Scalars['timestamptz']>;
  roleId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rbac_user_role" */
export type Rbac_User_Role_Max_Order_By = {
  expiration?: Maybe<Order_By>;
  roleId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rbac_User_Role_Min_Fields = {
  __typename?: 'rbac_user_role_min_fields';
  expiration?: Maybe<Scalars['timestamptz']>;
  roleId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rbac_user_role" */
export type Rbac_User_Role_Min_Order_By = {
  expiration?: Maybe<Order_By>;
  roleId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rbac_user_role" */
export type Rbac_User_Role_Mutation_Response = {
  __typename?: 'rbac_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rbac_User_Role>;
};

/** on_conflict condition type for table "rbac_user_role" */
export type Rbac_User_Role_On_Conflict = {
  constraint: Rbac_User_Role_Constraint;
  update_columns?: Array<Rbac_User_Role_Update_Column>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "rbac_user_role". */
export type Rbac_User_Role_Order_By = {
  expiration?: Maybe<Order_By>;
  rbac_role?: Maybe<Rbac_Role_Order_By>;
  roleId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: rbac_user_role */
export type Rbac_User_Role_Pk_Columns_Input = {
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "rbac_user_role" */
export enum Rbac_User_Role_Select_Column {
  /** column name */
  Expiration = 'expiration',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "rbac_user_role" */
export type Rbac_User_Role_Set_Input = {
  expiration?: Maybe<Scalars['timestamptz']>;
  roleId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rbac_user_role" */
export type Rbac_User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rbac_User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rbac_User_Role_Stream_Cursor_Value_Input = {
  expiration?: Maybe<Scalars['timestamptz']>;
  roleId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rbac_user_role" */
export enum Rbac_User_Role_Update_Column {
  /** column name */
  Expiration = 'expiration',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UserId = 'userId'
}

export type Rbac_User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rbac_User_Role_Set_Input>;
  where: Rbac_User_Role_Bool_Exp;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  id: Scalars['String'];
  permissions: Scalars['jsonb'];
};


/** columns and relationships of "role" */
export type RolePermissionsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Role_Append_Input = {
  permissions?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: Maybe<Array<Role_Bool_Exp>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Role_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  permissions?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB36bcfe02fc8de3c57a8b2391c2 = 'PK_b36bcfe02fc8de3c57a8b2391c2'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Role_Delete_At_Path_Input = {
  permissions?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Role_Delete_Elem_Input = {
  permissions?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Role_Delete_Key_Input = {
  permissions?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  id?: Maybe<Order_By>;
  permissions?: Maybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Role_Prepend_Input = {
  permissions?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Permissions = 'permissions'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Permissions = 'permissions'
}

export type Role_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Role_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Role_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Role_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Role_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Role_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};

/** columns and relationships of "service_attributes_for_account" */
export type Service_Attributes_For_Account = {
  __typename?: 'service_attributes_for_account';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  displayName: Scalars['String'];
  id: Scalars['uuid'];
  isServiceGroup: Scalars['Boolean'];
  keyName: Scalars['String'];
  keySource: Scalars['String'];
  mappings: Scalars['jsonb'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "service_attributes_for_account" */
export type Service_Attributes_For_AccountMappingsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "service_attributes_for_account" */
export type Service_Attributes_For_Account_Aggregate = {
  __typename?: 'service_attributes_for_account_aggregate';
  aggregate?: Maybe<Service_Attributes_For_Account_Aggregate_Fields>;
  nodes: Array<Service_Attributes_For_Account>;
};

/** aggregate fields of "service_attributes_for_account" */
export type Service_Attributes_For_Account_Aggregate_Fields = {
  __typename?: 'service_attributes_for_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Service_Attributes_For_Account_Max_Fields>;
  min?: Maybe<Service_Attributes_For_Account_Min_Fields>;
};


/** aggregate fields of "service_attributes_for_account" */
export type Service_Attributes_For_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Attributes_For_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Service_Attributes_For_Account_Append_Input = {
  mappings?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "service_attributes_for_account". All fields are combined with a logical 'AND'. */
export type Service_Attributes_For_Account_Bool_Exp = {
  _and?: Maybe<Array<Service_Attributes_For_Account_Bool_Exp>>;
  _not?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
  _or?: Maybe<Array<Service_Attributes_For_Account_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isServiceGroup?: Maybe<Boolean_Comparison_Exp>;
  keyName?: Maybe<String_Comparison_Exp>;
  keySource?: Maybe<String_Comparison_Exp>;
  mappings?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_attributes_for_account" */
export enum Service_Attributes_For_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_65afb16b49c219826f5b2fb5fb7 = 'PK_65afb16b49c219826f5b2fb5fb7',
  /** unique or primary key constraint on columns "type", "accountId", "keyName" */
  Uq_13c00340a7d960d9bcfbb1a7743 = 'UQ_13c00340a7d960d9bcfbb1a7743'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Service_Attributes_For_Account_Delete_At_Path_Input = {
  mappings?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Service_Attributes_For_Account_Delete_Elem_Input = {
  mappings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Service_Attributes_For_Account_Delete_Key_Input = {
  mappings?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "service_attributes_for_account" */
export type Service_Attributes_For_Account_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isServiceGroup?: Maybe<Scalars['Boolean']>;
  keyName?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  mappings?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Service_Attributes_For_Account_Max_Fields = {
  __typename?: 'service_attributes_for_account_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  keyName?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Service_Attributes_For_Account_Min_Fields = {
  __typename?: 'service_attributes_for_account_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  keyName?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "service_attributes_for_account" */
export type Service_Attributes_For_Account_Mutation_Response = {
  __typename?: 'service_attributes_for_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Attributes_For_Account>;
};

/** on_conflict condition type for table "service_attributes_for_account" */
export type Service_Attributes_For_Account_On_Conflict = {
  constraint: Service_Attributes_For_Account_Constraint;
  update_columns?: Array<Service_Attributes_For_Account_Update_Column>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "service_attributes_for_account". */
export type Service_Attributes_For_Account_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isServiceGroup?: Maybe<Order_By>;
  keyName?: Maybe<Order_By>;
  keySource?: Maybe<Order_By>;
  mappings?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: service_attributes_for_account */
export type Service_Attributes_For_Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Service_Attributes_For_Account_Prepend_Input = {
  mappings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "service_attributes_for_account" */
export enum Service_Attributes_For_Account_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  IsServiceGroup = 'isServiceGroup',
  /** column name */
  KeyName = 'keyName',
  /** column name */
  KeySource = 'keySource',
  /** column name */
  Mappings = 'mappings',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "service_attributes_for_account" */
export type Service_Attributes_For_Account_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isServiceGroup?: Maybe<Scalars['Boolean']>;
  keyName?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  mappings?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "service_attributes_for_account" */
export type Service_Attributes_For_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Attributes_For_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Attributes_For_Account_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isServiceGroup?: Maybe<Scalars['Boolean']>;
  keyName?: Maybe<Scalars['String']>;
  keySource?: Maybe<Scalars['String']>;
  mappings?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "service_attributes_for_account" */
export enum Service_Attributes_For_Account_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  IsServiceGroup = 'isServiceGroup',
  /** column name */
  KeyName = 'keyName',
  /** column name */
  KeySource = 'keySource',
  /** column name */
  Mappings = 'mappings',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Service_Attributes_For_Account_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Service_Attributes_For_Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Service_Attributes_For_Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Service_Attributes_For_Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Service_Attributes_For_Account_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Service_Attributes_For_Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Attributes_For_Account_Set_Input>;
  where: Service_Attributes_For_Account_Bool_Exp;
};

/** columns and relationships of "service_description" */
export type Service_Description = {
  __typename?: 'service_description';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  agentId: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId: Scalars['uuid'];
};


/** columns and relationships of "service_description" */
export type Service_DescriptionRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "service_description" */
export type Service_Description_Aggregate = {
  __typename?: 'service_description_aggregate';
  aggregate?: Maybe<Service_Description_Aggregate_Fields>;
  nodes: Array<Service_Description>;
};

/** aggregate fields of "service_description" */
export type Service_Description_Aggregate_Fields = {
  __typename?: 'service_description_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Service_Description_Max_Fields>;
  min?: Maybe<Service_Description_Min_Fields>;
};


/** aggregate fields of "service_description" */
export type Service_Description_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Description_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Service_Description_Append_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "service_description". All fields are combined with a logical 'AND'. */
export type Service_Description_Bool_Exp = {
  _and?: Maybe<Array<Service_Description_Bool_Exp>>;
  _not?: Maybe<Service_Description_Bool_Exp>;
  _or?: Maybe<Array<Service_Description_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  agentId?: Maybe<String_Comparison_Exp>;
  clusterName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_description" */
export enum Service_Description_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_88449084f3f33b9553dbbcc5015 = 'PK_88449084f3f33b9553dbbcc5015'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Service_Description_Delete_At_Path_Input = {
  rbacResources?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Service_Description_Delete_Elem_Input = {
  rbacResources?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Service_Description_Delete_Key_Input = {
  rbacResources?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "service_description" */
export type Service_Description_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Service_Description_Max_Fields = {
  __typename?: 'service_description_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Service_Description_Min_Fields = {
  __typename?: 'service_description_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "service_description" */
export type Service_Description_Mutation_Response = {
  __typename?: 'service_description_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Description>;
};

/** on_conflict condition type for table "service_description" */
export type Service_Description_On_Conflict = {
  constraint: Service_Description_Constraint;
  update_columns?: Array<Service_Description_Update_Column>;
  where?: Maybe<Service_Description_Bool_Exp>;
};

/** Ordering options when selecting data from "service_description". */
export type Service_Description_Order_By = {
  accountId?: Maybe<Order_By>;
  agentId?: Maybe<Order_By>;
  clusterName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

/** primary key columns input for table: service_description */
export type Service_Description_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Service_Description_Prepend_Input = {
  rbacResources?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "service_description" */
export enum Service_Description_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  TaskId = 'taskId'
}

/** input type for updating data in table "service_description" */
export type Service_Description_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "service_description" */
export type Service_Description_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Description_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Description_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  agentId?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "service_description" */
export enum Service_Description_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AgentId = 'agentId',
  /** column name */
  ClusterName = 'clusterName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  TaskId = 'taskId'
}

export type Service_Description_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Service_Description_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Service_Description_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Service_Description_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Service_Description_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Service_Description_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Description_Set_Input>;
  where: Service_Description_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_domain_mapping" */
  account_domain_mapping: Array<Account_Domain_Mapping>;
  /** fetch aggregated fields from the table: "account_domain_mapping" */
  account_domain_mapping_aggregate: Account_Domain_Mapping_Aggregate;
  /** fetch data from the table: "account_domain_mapping" using primary key columns */
  account_domain_mapping_by_pk?: Maybe<Account_Domain_Mapping>;
  /** fetch data from the table in a streaming manner: "account_domain_mapping" */
  account_domain_mapping_stream: Array<Account_Domain_Mapping>;
  /** fetch data from the table: "account_event_limits" */
  account_event_limits: Array<Account_Event_Limits>;
  /** fetch aggregated fields from the table: "account_event_limits" */
  account_event_limits_aggregate: Account_Event_Limits_Aggregate;
  /** fetch data from the table: "account_event_limits" using primary key columns */
  account_event_limits_by_pk?: Maybe<Account_Event_Limits>;
  /** fetch data from the table in a streaming manner: "account_event_limits" */
  account_event_limits_stream: Array<Account_Event_Limits>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
  /** fetch data from the table: "agent_task" */
  agent_task: Array<Agent_Task>;
  /** fetch aggregated fields from the table: "agent_task" */
  agent_task_aggregate: Agent_Task_Aggregate;
  /** fetch data from the table: "agent_task" using primary key columns */
  agent_task_by_pk?: Maybe<Agent_Task>;
  /** fetch data from the table: "agent_task_result" */
  agent_task_result: Array<Agent_Task_Result>;
  /** fetch aggregated fields from the table: "agent_task_result" */
  agent_task_result_aggregate: Agent_Task_Result_Aggregate;
  /** fetch data from the table: "agent_task_result" using primary key columns */
  agent_task_result_by_pk?: Maybe<Agent_Task_Result>;
  /** fetch data from the table in a streaming manner: "agent_task_result" */
  agent_task_result_stream: Array<Agent_Task_Result>;
  /** fetch data from the table in a streaming manner: "agent_task" */
  agent_task_stream: Array<Agent_Task>;
  /** fetch data from the table: "api_key" */
  api_key: Array<Api_Key>;
  /** fetch aggregated fields from the table: "api_key" */
  api_key_aggregate: Api_Key_Aggregate;
  /** fetch data from the table: "api_key" using primary key columns */
  api_key_by_pk?: Maybe<Api_Key>;
  /** fetch data from the table in a streaming manner: "api_key" */
  api_key_stream: Array<Api_Key>;
  /** fetch data from the table: "app_view" */
  app_view: Array<App_View>;
  /** fetch aggregated fields from the table: "app_view" */
  app_view_aggregate: App_View_Aggregate;
  /** fetch data from the table: "app_view" using primary key columns */
  app_view_by_pk?: Maybe<App_View>;
  /** fetch data from the table in a streaming manner: "app_view" */
  app_view_stream: Array<App_View>;
  /** fetch data from the table: "audit" */
  audit: Array<Audit>;
  /** fetch aggregated fields from the table: "audit" */
  audit_aggregate: Audit_Aggregate;
  /** fetch data from the table: "audit" using primary key columns */
  audit_by_pk?: Maybe<Audit>;
  /** fetch data from the table in a streaming manner: "audit" */
  audit_stream: Array<Audit>;
  /** fetch data from the table: "channel_configuration" */
  channel_configuration: Array<Channel_Configuration>;
  /** fetch aggregated fields from the table: "channel_configuration" */
  channel_configuration_aggregate: Channel_Configuration_Aggregate;
  /** fetch data from the table: "channel_configuration" using primary key columns */
  channel_configuration_by_pk?: Maybe<Channel_Configuration>;
  /** fetch data from the table in a streaming manner: "channel_configuration" */
  channel_configuration_stream: Array<Channel_Configuration>;
  /** fetch data from the table: "event_actions" */
  event_actions: Array<Event_Actions>;
  /** fetch aggregated fields from the table: "event_actions" */
  event_actions_aggregate: Event_Actions_Aggregate;
  /** fetch data from the table: "event_actions" using primary key columns */
  event_actions_by_pk?: Maybe<Event_Actions>;
  /** fetch data from the table in a streaming manner: "event_actions" */
  event_actions_stream: Array<Event_Actions>;
  /** fetch data from the table: "event_config_map" */
  event_config_map: Array<Event_Config_Map>;
  /** fetch aggregated fields from the table: "event_config_map" */
  event_config_map_aggregate: Event_Config_Map_Aggregate;
  /** fetch data from the table: "event_config_map" using primary key columns */
  event_config_map_by_pk?: Maybe<Event_Config_Map>;
  /** fetch data from the table in a streaming manner: "event_config_map" */
  event_config_map_stream: Array<Event_Config_Map>;
  /** fetch data from the table: "event_cron_job" */
  event_cron_job: Array<Event_Cron_Job>;
  /** fetch aggregated fields from the table: "event_cron_job" */
  event_cron_job_aggregate: Event_Cron_Job_Aggregate;
  /** fetch data from the table: "event_cron_job" using primary key columns */
  event_cron_job_by_pk?: Maybe<Event_Cron_Job>;
  /** fetch data from the table in a streaming manner: "event_cron_job" */
  event_cron_job_stream: Array<Event_Cron_Job>;
  /** fetch data from the table: "event_datadog_monitor" */
  event_datadog_monitor: Array<Event_Datadog_Monitor>;
  /** fetch aggregated fields from the table: "event_datadog_monitor" */
  event_datadog_monitor_aggregate: Event_Datadog_Monitor_Aggregate;
  /** fetch data from the table: "event_datadog_monitor" using primary key columns */
  event_datadog_monitor_by_pk?: Maybe<Event_Datadog_Monitor>;
  /** fetch data from the table in a streaming manner: "event_datadog_monitor" */
  event_datadog_monitor_stream: Array<Event_Datadog_Monitor>;
  /** fetch data from the table: "event_datadog_tracing" */
  event_datadog_tracing: Array<Event_Datadog_Tracing>;
  /** fetch aggregated fields from the table: "event_datadog_tracing" */
  event_datadog_tracing_aggregate: Event_Datadog_Tracing_Aggregate;
  /** fetch data from the table: "event_datadog_tracing" using primary key columns */
  event_datadog_tracing_by_pk?: Maybe<Event_Datadog_Tracing>;
  /** fetch data from the table in a streaming manner: "event_datadog_tracing" */
  event_datadog_tracing_stream: Array<Event_Datadog_Tracing>;
  /** fetch data from the table: "event_deploy" */
  event_deploy: Array<Event_Deploy>;
  /** fetch aggregated fields from the table: "event_deploy" */
  event_deploy_aggregate: Event_Deploy_Aggregate;
  /** fetch data from the table: "event_deploy" using primary key columns */
  event_deploy_by_pk?: Maybe<Event_Deploy>;
  /** fetch data from the table in a streaming manner: "event_deploy" */
  event_deploy_stream: Array<Event_Deploy>;
  /** fetch data from the table: "event_hpa" */
  event_hpa: Array<Event_Hpa>;
  /** fetch aggregated fields from the table: "event_hpa" */
  event_hpa_aggregate: Event_Hpa_Aggregate;
  /** fetch data from the table: "event_hpa" using primary key columns */
  event_hpa_by_pk?: Maybe<Event_Hpa>;
  /** fetch data from the table in a streaming manner: "event_hpa" */
  event_hpa_stream: Array<Event_Hpa>;
  /** fetch data from the table: "event_ingress" */
  event_ingress: Array<Event_Ingress>;
  /** fetch aggregated fields from the table: "event_ingress" */
  event_ingress_aggregate: Event_Ingress_Aggregate;
  /** fetch data from the table: "event_ingress" using primary key columns */
  event_ingress_by_pk?: Maybe<Event_Ingress>;
  /** fetch data from the table in a streaming manner: "event_ingress" */
  event_ingress_stream: Array<Event_Ingress>;
  /** fetch data from the table: "event_job" */
  event_job: Array<Event_Job>;
  /** fetch aggregated fields from the table: "event_job" */
  event_job_aggregate: Event_Job_Aggregate;
  /** fetch data from the table: "event_job" using primary key columns */
  event_job_by_pk?: Maybe<Event_Job>;
  /** fetch data from the table in a streaming manner: "event_job" */
  event_job_stream: Array<Event_Job>;
  /** fetch data from the table: "event_komodor_alert" */
  event_komodor_alert: Array<Event_Komodor_Alert>;
  /** fetch aggregated fields from the table: "event_komodor_alert" */
  event_komodor_alert_aggregate: Event_Komodor_Alert_Aggregate;
  /** fetch data from the table: "event_komodor_alert" using primary key columns */
  event_komodor_alert_by_pk?: Maybe<Event_Komodor_Alert>;
  /** fetch data from the table in a streaming manner: "event_komodor_alert" */
  event_komodor_alert_stream: Array<Event_Komodor_Alert>;
  /** fetch data from the table: "event_kubernetes_service_resource" */
  event_kubernetes_service_resource: Array<Event_Kubernetes_Service_Resource>;
  /** fetch aggregated fields from the table: "event_kubernetes_service_resource" */
  event_kubernetes_service_resource_aggregate: Event_Kubernetes_Service_Resource_Aggregate;
  /** fetch data from the table: "event_kubernetes_service_resource" using primary key columns */
  event_kubernetes_service_resource_by_pk?: Maybe<Event_Kubernetes_Service_Resource>;
  /** fetch data from the table in a streaming manner: "event_kubernetes_service_resource" */
  event_kubernetes_service_resource_stream: Array<Event_Kubernetes_Service_Resource>;
  /** fetch data from the table: "event_launchdarkly" */
  event_launchdarkly: Array<Event_Launchdarkly>;
  /** fetch aggregated fields from the table: "event_launchdarkly" */
  event_launchdarkly_aggregate: Event_Launchdarkly_Aggregate;
  /** fetch data from the table: "event_launchdarkly" using primary key columns */
  event_launchdarkly_by_pk?: Maybe<Event_Launchdarkly>;
  /** fetch data from the table in a streaming manner: "event_launchdarkly" */
  event_launchdarkly_stream: Array<Event_Launchdarkly>;
  /** fetch data from the table: "event_limit_ranges" */
  event_limit_ranges: Array<Event_Limit_Ranges>;
  /** fetch aggregated fields from the table: "event_limit_ranges" */
  event_limit_ranges_aggregate: Event_Limit_Ranges_Aggregate;
  /** fetch data from the table: "event_limit_ranges" using primary key columns */
  event_limit_ranges_by_pk?: Maybe<Event_Limit_Ranges>;
  /** fetch data from the table in a streaming manner: "event_limit_ranges" */
  event_limit_ranges_stream: Array<Event_Limit_Ranges>;
  /** fetch data from the table: "event_monitor" */
  event_monitor: Array<Event_Monitor>;
  /** fetch aggregated fields from the table: "event_monitor" */
  event_monitor_aggregate: Event_Monitor_Aggregate;
  /** fetch data from the table: "event_monitor" using primary key columns */
  event_monitor_by_pk?: Maybe<Event_Monitor>;
  /** fetch data from the table in a streaming manner: "event_monitor" */
  event_monitor_stream: Array<Event_Monitor>;
  /** fetch data from the table: "event_network_policies" */
  event_network_policies: Array<Event_Network_Policies>;
  /** fetch aggregated fields from the table: "event_network_policies" */
  event_network_policies_aggregate: Event_Network_Policies_Aggregate;
  /** fetch data from the table: "event_network_policies" using primary key columns */
  event_network_policies_by_pk?: Maybe<Event_Network_Policies>;
  /** fetch data from the table in a streaming manner: "event_network_policies" */
  event_network_policies_stream: Array<Event_Network_Policies>;
  /** fetch data from the table: "event_node" */
  event_node: Array<Event_Node>;
  /** fetch aggregated fields from the table: "event_node" */
  event_node_aggregate: Event_Node_Aggregate;
  /** fetch data from the table: "event_node" using primary key columns */
  event_node_by_pk?: Maybe<Event_Node>;
  /** fetch data from the table in a streaming manner: "event_node" */
  event_node_stream: Array<Event_Node>;
  /** fetch data from the table: "event_opsgenie" */
  event_opsgenie: Array<Event_Opsgenie>;
  /** fetch aggregated fields from the table: "event_opsgenie" */
  event_opsgenie_aggregate: Event_Opsgenie_Aggregate;
  /** fetch data from the table: "event_opsgenie" using primary key columns */
  event_opsgenie_by_pk?: Maybe<Event_Opsgenie>;
  /** fetch data from the table in a streaming manner: "event_opsgenie" */
  event_opsgenie_stream: Array<Event_Opsgenie>;
  /** fetch data from the table: "event_pagerduty" */
  event_pagerduty: Array<Event_Pagerduty>;
  /** fetch aggregated fields from the table: "event_pagerduty" */
  event_pagerduty_aggregate: Event_Pagerduty_Aggregate;
  /** fetch data from the table: "event_pagerduty" using primary key columns */
  event_pagerduty_by_pk?: Maybe<Event_Pagerduty>;
  /** fetch data from the table in a streaming manner: "event_pagerduty" */
  event_pagerduty_stream: Array<Event_Pagerduty>;
  /** fetch data from the table: "event_pdb" */
  event_pdb: Array<Event_Pdb>;
  /** fetch aggregated fields from the table: "event_pdb" */
  event_pdb_aggregate: Event_Pdb_Aggregate;
  /** fetch data from the table: "event_pdb" using primary key columns */
  event_pdb_by_pk?: Maybe<Event_Pdb>;
  /** fetch data from the table in a streaming manner: "event_pdb" */
  event_pdb_stream: Array<Event_Pdb>;
  /** fetch data from the table: "event_resource_quota" */
  event_resource_quota: Array<Event_Resource_Quota>;
  /** fetch aggregated fields from the table: "event_resource_quota" */
  event_resource_quota_aggregate: Event_Resource_Quota_Aggregate;
  /** fetch data from the table: "event_resource_quota" using primary key columns */
  event_resource_quota_by_pk?: Maybe<Event_Resource_Quota>;
  /** fetch data from the table in a streaming manner: "event_resource_quota" */
  event_resource_quota_stream: Array<Event_Resource_Quota>;
  /** fetch data from the table: "event_secret" */
  event_secret: Array<Event_Secret>;
  /** fetch aggregated fields from the table: "event_secret" */
  event_secret_aggregate: Event_Secret_Aggregate;
  /** fetch data from the table: "event_secret" using primary key columns */
  event_secret_by_pk?: Maybe<Event_Secret>;
  /** fetch data from the table in a streaming manner: "event_secret" */
  event_secret_stream: Array<Event_Secret>;
  /** fetch data from the table: "event_sentry_issue" */
  event_sentry_issue: Array<Event_Sentry_Issue>;
  /** fetch aggregated fields from the table: "event_sentry_issue" */
  event_sentry_issue_aggregate: Event_Sentry_Issue_Aggregate;
  /** fetch data from the table: "event_sentry_issue" using primary key columns */
  event_sentry_issue_by_pk?: Maybe<Event_Sentry_Issue>;
  /** fetch data from the table in a streaming manner: "event_sentry_issue" */
  event_sentry_issue_stream: Array<Event_Sentry_Issue>;
  /** fetch data from the table: "event_stale_kubernetes_agent" */
  event_stale_kubernetes_agent: Array<Event_Stale_Kubernetes_Agent>;
  /** fetch aggregated fields from the table: "event_stale_kubernetes_agent" */
  event_stale_kubernetes_agent_aggregate: Event_Stale_Kubernetes_Agent_Aggregate;
  /** fetch data from the table: "event_stale_kubernetes_agent" using primary key columns */
  event_stale_kubernetes_agent_by_pk?: Maybe<Event_Stale_Kubernetes_Agent>;
  /** fetch data from the table in a streaming manner: "event_stale_kubernetes_agent" */
  event_stale_kubernetes_agent_stream: Array<Event_Stale_Kubernetes_Agent>;
  /** fetch data from the table: "event_topology" */
  event_topology: Array<Event_Topology>;
  /** fetch aggregated fields from the table: "event_topology" */
  event_topology_aggregate: Event_Topology_Aggregate;
  /** fetch data from the table: "event_topology" using primary key columns */
  event_topology_by_pk?: Maybe<Event_Topology>;
  /** fetch data from the table in a streaming manner: "event_topology" */
  event_topology_stream: Array<Event_Topology>;
  /** fetch data from the table: "event_workload" */
  event_workload: Array<Event_Workload>;
  /** fetch aggregated fields from the table: "event_workload" */
  event_workload_aggregate: Event_Workload_Aggregate;
  /** fetch data from the table: "event_workload" using primary key columns */
  event_workload_by_pk?: Maybe<Event_Workload>;
  /** fetch data from the table in a streaming manner: "event_workload" */
  event_workload_stream: Array<Event_Workload>;
  /** fetch data from the table: "external_link" */
  external_link: Array<External_Link>;
  /** fetch aggregated fields from the table: "external_link" */
  external_link_aggregate: External_Link_Aggregate;
  /** fetch data from the table: "external_link" using primary key columns */
  external_link_by_pk?: Maybe<External_Link>;
  /** fetch data from the table: "external_link_context" */
  external_link_context: Array<External_Link_Context>;
  /** fetch aggregated fields from the table: "external_link_context" */
  external_link_context_aggregate: External_Link_Context_Aggregate;
  /** fetch data from the table: "external_link_context" using primary key columns */
  external_link_context_by_pk?: Maybe<External_Link_Context>;
  /** fetch data from the table in a streaming manner: "external_link_context" */
  external_link_context_stream: Array<External_Link_Context>;
  /** fetch data from the table in a streaming manner: "external_link" */
  external_link_stream: Array<External_Link>;
  /** fetch data from the table: "helm_repo" */
  helm_repo: Array<Helm_Repo>;
  /** fetch aggregated fields from the table: "helm_repo" */
  helm_repo_aggregate: Helm_Repo_Aggregate;
  /** fetch data from the table: "helm_repo" using primary key columns */
  helm_repo_by_pk?: Maybe<Helm_Repo>;
  /** fetch data from the table in a streaming manner: "helm_repo" */
  helm_repo_stream: Array<Helm_Repo>;
  /** fetch data from the table: "installation" */
  installation: Array<Installation>;
  /** fetch aggregated fields from the table: "installation" */
  installation_aggregate: Installation_Aggregate;
  /** fetch data from the table: "installation" using primary key columns */
  installation_by_pk?: Maybe<Installation>;
  /** fetch data from the table in a streaming manner: "installation" */
  installation_stream: Array<Installation>;
  /** fetch data from the table: "komodor_filter_settings" */
  komodor_filter_settings: Array<Komodor_Filter_Settings>;
  /** fetch aggregated fields from the table: "komodor_filter_settings" */
  komodor_filter_settings_aggregate: Komodor_Filter_Settings_Aggregate;
  /** fetch data from the table: "komodor_filter_settings" using primary key columns */
  komodor_filter_settings_by_pk?: Maybe<Komodor_Filter_Settings>;
  /** fetch data from the table in a streaming manner: "komodor_filter_settings" */
  komodor_filter_settings_stream: Array<Komodor_Filter_Settings>;
  /** fetch data from the table: "komodor_service" */
  komodor_service: Array<Komodor_Service>;
  /** fetch aggregated fields from the table: "komodor_service" */
  komodor_service_aggregate: Komodor_Service_Aggregate;
  /** fetch data from the table: "komodor_service_attributes" */
  komodor_service_attributes: Array<Komodor_Service_Attributes>;
  /** fetch aggregated fields from the table: "komodor_service_attributes" */
  komodor_service_attributes_aggregate: Komodor_Service_Attributes_Aggregate;
  /** fetch data from the table: "komodor_service_attributes" using primary key columns */
  komodor_service_attributes_by_pk?: Maybe<Komodor_Service_Attributes>;
  /** fetch data from the table in a streaming manner: "komodor_service_attributes" */
  komodor_service_attributes_stream: Array<Komodor_Service_Attributes>;
  /** fetch data from the table: "komodor_service" using primary key columns */
  komodor_service_by_pk?: Maybe<Komodor_Service>;
  /** fetch data from the table: "komodor_service_datadog_data" */
  komodor_service_datadog_data: Array<Komodor_Service_Datadog_Data>;
  /** fetch aggregated fields from the table: "komodor_service_datadog_data" */
  komodor_service_datadog_data_aggregate: Komodor_Service_Datadog_Data_Aggregate;
  /** fetch data from the table: "komodor_service_datadog_data" using primary key columns */
  komodor_service_datadog_data_by_pk?: Maybe<Komodor_Service_Datadog_Data>;
  /** fetch data from the table in a streaming manner: "komodor_service_datadog_data" */
  komodor_service_datadog_data_stream: Array<Komodor_Service_Datadog_Data>;
  /** fetch data from the table: "komodor_service_deploy_state" */
  komodor_service_deploy_state: Array<Komodor_Service_Deploy_State>;
  /** fetch aggregated fields from the table: "komodor_service_deploy_state" */
  komodor_service_deploy_state_aggregate: Komodor_Service_Deploy_State_Aggregate;
  /** fetch data from the table: "komodor_service_deploy_state" using primary key columns */
  komodor_service_deploy_state_by_pk?: Maybe<Komodor_Service_Deploy_State>;
  /** fetch data from the table in a streaming manner: "komodor_service_deploy_state" */
  komodor_service_deploy_state_stream: Array<Komodor_Service_Deploy_State>;
  /** fetch data from the table: "komodor_service_health_state" */
  komodor_service_health_state: Array<Komodor_Service_Health_State>;
  /** fetch aggregated fields from the table: "komodor_service_health_state" */
  komodor_service_health_state_aggregate: Komodor_Service_Health_State_Aggregate;
  /** fetch data from the table: "komodor_service_health_state" using primary key columns */
  komodor_service_health_state_by_pk?: Maybe<Komodor_Service_Health_State>;
  /** fetch data from the table in a streaming manner: "komodor_service_health_state" */
  komodor_service_health_state_stream: Array<Komodor_Service_Health_State>;
  /** fetch data from the table: "komodor_service_k8s_metadata" */
  komodor_service_k8s_metadata: Array<Komodor_Service_K8s_Metadata>;
  /** fetch aggregated fields from the table: "komodor_service_k8s_metadata" */
  komodor_service_k8s_metadata_aggregate: Komodor_Service_K8s_Metadata_Aggregate;
  /** fetch data from the table: "komodor_service_k8s_metadata" using primary key columns */
  komodor_service_k8s_metadata_by_pk?: Maybe<Komodor_Service_K8s_Metadata>;
  /** fetch data from the table in a streaming manner: "komodor_service_k8s_metadata" */
  komodor_service_k8s_metadata_stream: Array<Komodor_Service_K8s_Metadata>;
  /** fetch data from the table: "komodor_service_sentry_data" */
  komodor_service_sentry_data: Array<Komodor_Service_Sentry_Data>;
  /** fetch aggregated fields from the table: "komodor_service_sentry_data" */
  komodor_service_sentry_data_aggregate: Komodor_Service_Sentry_Data_Aggregate;
  /** fetch data from the table: "komodor_service_sentry_data" using primary key columns */
  komodor_service_sentry_data_by_pk?: Maybe<Komodor_Service_Sentry_Data>;
  /** fetch data from the table in a streaming manner: "komodor_service_sentry_data" */
  komodor_service_sentry_data_stream: Array<Komodor_Service_Sentry_Data>;
  /** fetch data from the table: "komodor_service_static_prevention_state" */
  komodor_service_static_prevention_state: Array<Komodor_Service_Static_Prevention_State>;
  /** fetch aggregated fields from the table: "komodor_service_static_prevention_state" */
  komodor_service_static_prevention_state_aggregate: Komodor_Service_Static_Prevention_State_Aggregate;
  /** fetch data from the table: "komodor_service_static_prevention_state" using primary key columns */
  komodor_service_static_prevention_state_by_pk?: Maybe<Komodor_Service_Static_Prevention_State>;
  /** fetch data from the table in a streaming manner: "komodor_service_static_prevention_state" */
  komodor_service_static_prevention_state_stream: Array<Komodor_Service_Static_Prevention_State>;
  /** fetch data from the table in a streaming manner: "komodor_service" */
  komodor_service_stream: Array<Komodor_Service>;
  /** fetch data from the table: "kubernetes_agent_info" */
  kubernetes_agent_info: Array<Kubernetes_Agent_Info>;
  /** fetch aggregated fields from the table: "kubernetes_agent_info" */
  kubernetes_agent_info_aggregate: Kubernetes_Agent_Info_Aggregate;
  /** fetch data from the table: "kubernetes_agent_info" using primary key columns */
  kubernetes_agent_info_by_pk?: Maybe<Kubernetes_Agent_Info>;
  /** fetch data from the table in a streaming manner: "kubernetes_agent_info" */
  kubernetes_agent_info_stream: Array<Kubernetes_Agent_Info>;
  /** fetch data from the table: "node_status_agent_task" */
  node_status_agent_task: Array<Node_Status_Agent_Task>;
  /** fetch aggregated fields from the table: "node_status_agent_task" */
  node_status_agent_task_aggregate: Node_Status_Agent_Task_Aggregate;
  /** fetch data from the table: "node_status_agent_task" using primary key columns */
  node_status_agent_task_by_pk?: Maybe<Node_Status_Agent_Task>;
  /** fetch data from the table in a streaming manner: "node_status_agent_task" */
  node_status_agent_task_stream: Array<Node_Status_Agent_Task>;
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch aggregated fields from the table: "plan" */
  plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table in a streaming manner: "plan" */
  plan_stream: Array<Plan>;
  /** fetch data from the table: "pod_list" */
  pod_list: Array<Pod_List>;
  /** fetch aggregated fields from the table: "pod_list" */
  pod_list_aggregate: Pod_List_Aggregate;
  /** fetch data from the table: "pod_list" using primary key columns */
  pod_list_by_pk?: Maybe<Pod_List>;
  /** fetch data from the table in a streaming manner: "pod_list" */
  pod_list_stream: Array<Pod_List>;
  /** fetch data from the table: "pod_logs" */
  pod_logs: Array<Pod_Logs>;
  /** fetch aggregated fields from the table: "pod_logs" */
  pod_logs_aggregate: Pod_Logs_Aggregate;
  /** fetch data from the table: "pod_logs" using primary key columns */
  pod_logs_by_pk?: Maybe<Pod_Logs>;
  /** fetch data from the table in a streaming manner: "pod_logs" */
  pod_logs_stream: Array<Pod_Logs>;
  /** fetch data from the table: "rbac_policy" */
  rbac_policy: Array<Rbac_Policy>;
  /** fetch data from the table: "rbac_policy_action" */
  rbac_policy_action: Array<Rbac_Policy_Action>;
  /** fetch aggregated fields from the table: "rbac_policy_action" */
  rbac_policy_action_aggregate: Rbac_Policy_Action_Aggregate;
  /** fetch data from the table: "rbac_policy_action" using primary key columns */
  rbac_policy_action_by_pk?: Maybe<Rbac_Policy_Action>;
  /** fetch data from the table in a streaming manner: "rbac_policy_action" */
  rbac_policy_action_stream: Array<Rbac_Policy_Action>;
  /** fetch data from the table: "rbac_policy_action" */
  rbac_policy_actions: Array<Rbac_Policy_Actions>;
  /** fetch aggregated fields from the table: "rbac_policy_action" */
  rbac_policy_actions_aggregate: Rbac_Policy_Actions_Aggregate;
  /** fetch data from the table: "rbac_policy_action" using primary key columns */
  rbac_policy_actions_by_pk?: Maybe<Rbac_Policy_Actions>;
  /** fetch data from the table in a streaming manner: "rbac_policy_action" */
  rbac_policy_actions_stream: Array<Rbac_Policy_Actions>;
  /** fetch aggregated fields from the table: "rbac_policy" */
  rbac_policy_aggregate: Rbac_Policy_Aggregate;
  /** fetch data from the table: "rbac_policy" using primary key columns */
  rbac_policy_by_pk?: Maybe<Rbac_Policy>;
  /** fetch data from the table in a streaming manner: "rbac_policy" */
  rbac_policy_stream: Array<Rbac_Policy>;
  /** fetch data from the table: "rbac_role" */
  rbac_role: Array<Rbac_Role>;
  /** fetch aggregated fields from the table: "rbac_role" */
  rbac_role_aggregate: Rbac_Role_Aggregate;
  /** fetch data from the table: "rbac_role" using primary key columns */
  rbac_role_by_pk?: Maybe<Rbac_Role>;
  /** fetch data from the table: "rbac_role_policy" */
  rbac_role_policy: Array<Rbac_Role_Policy>;
  /** fetch aggregated fields from the table: "rbac_role_policy" */
  rbac_role_policy_aggregate: Rbac_Role_Policy_Aggregate;
  /** fetch data from the table: "rbac_role_policy" using primary key columns */
  rbac_role_policy_by_pk?: Maybe<Rbac_Role_Policy>;
  /** fetch data from the table in a streaming manner: "rbac_role_policy" */
  rbac_role_policy_stream: Array<Rbac_Role_Policy>;
  /** fetch data from the table in a streaming manner: "rbac_role" */
  rbac_role_stream: Array<Rbac_Role>;
  /** fetch data from the table: "rbac_user_role" */
  rbac_user_role: Array<Rbac_User_Role>;
  /** fetch aggregated fields from the table: "rbac_user_role" */
  rbac_user_role_aggregate: Rbac_User_Role_Aggregate;
  /** fetch data from the table: "rbac_user_role" using primary key columns */
  rbac_user_role_by_pk?: Maybe<Rbac_User_Role>;
  /** fetch data from the table in a streaming manner: "rbac_user_role" */
  rbac_user_role_stream: Array<Rbac_User_Role>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "service_attributes_for_account" */
  service_attributes_for_account: Array<Service_Attributes_For_Account>;
  /** fetch aggregated fields from the table: "service_attributes_for_account" */
  service_attributes_for_account_aggregate: Service_Attributes_For_Account_Aggregate;
  /** fetch data from the table: "service_attributes_for_account" using primary key columns */
  service_attributes_for_account_by_pk?: Maybe<Service_Attributes_For_Account>;
  /** fetch data from the table in a streaming manner: "service_attributes_for_account" */
  service_attributes_for_account_stream: Array<Service_Attributes_For_Account>;
  /** fetch data from the table: "service_description" */
  service_description: Array<Service_Description>;
  /** fetch aggregated fields from the table: "service_description" */
  service_description_aggregate: Service_Description_Aggregate;
  /** fetch data from the table: "service_description" using primary key columns */
  service_description_by_pk?: Maybe<Service_Description>;
  /** fetch data from the table in a streaming manner: "service_description" */
  service_description_stream: Array<Service_Description>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "workflow_configuration" */
  workflow_configuration: Array<Workflow_Configuration>;
  /** fetch aggregated fields from the table: "workflow_configuration" */
  workflow_configuration_aggregate: Workflow_Configuration_Aggregate;
  /** fetch data from the table: "workflow_configuration" using primary key columns */
  workflow_configuration_by_pk?: Maybe<Workflow_Configuration>;
  /** fetch data from the table in a streaming manner: "workflow_configuration" */
  workflow_configuration_stream: Array<Workflow_Configuration>;
  /** fetch data from the table: "workflow_results" */
  workflow_results: Array<Workflow_Results>;
  /** fetch aggregated fields from the table: "workflow_results" */
  workflow_results_aggregate: Workflow_Results_Aggregate;
  /** fetch data from the table: "workflow_results" using primary key columns */
  workflow_results_by_pk?: Maybe<Workflow_Results>;
  /** fetch data from the table in a streaming manner: "workflow_results" */
  workflow_results_stream: Array<Workflow_Results>;
};


export type Subscription_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_Domain_MappingArgs = {
  distinct_on?: Maybe<Array<Account_Domain_Mapping_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Domain_Mapping_Order_By>>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};


export type Subscription_RootAccount_Domain_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Domain_Mapping_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Domain_Mapping_Order_By>>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};


export type Subscription_RootAccount_Domain_Mapping_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccount_Domain_Mapping_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Domain_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Account_Domain_Mapping_Bool_Exp>;
};


export type Subscription_RootAccount_Event_LimitsArgs = {
  distinct_on?: Maybe<Array<Account_Event_Limits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Event_Limits_Order_By>>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};


export type Subscription_RootAccount_Event_Limits_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Event_Limits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Event_Limits_Order_By>>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};


export type Subscription_RootAccount_Event_Limits_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_Event_Limits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Event_Limits_Stream_Cursor_Input>>;
  where?: Maybe<Account_Event_Limits_Bool_Exp>;
};


export type Subscription_RootAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Stream_Cursor_Input>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAgent_TaskArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Order_By>>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};


export type Subscription_RootAgent_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Order_By>>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};


export type Subscription_RootAgent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAgent_Task_ResultArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Result_Order_By>>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};


export type Subscription_RootAgent_Task_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Agent_Task_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agent_Task_Result_Order_By>>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};


export type Subscription_RootAgent_Task_Result_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAgent_Task_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Agent_Task_Result_Stream_Cursor_Input>>;
  where?: Maybe<Agent_Task_Result_Bool_Exp>;
};


export type Subscription_RootAgent_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Agent_Task_Stream_Cursor_Input>>;
  where?: Maybe<Agent_Task_Bool_Exp>;
};


export type Subscription_RootApi_KeyArgs = {
  distinct_on?: Maybe<Array<Api_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Key_Order_By>>;
  where?: Maybe<Api_Key_Bool_Exp>;
};


export type Subscription_RootApi_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Key_Order_By>>;
  where?: Maybe<Api_Key_Bool_Exp>;
};


export type Subscription_RootApi_Key_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApi_Key_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Api_Key_Stream_Cursor_Input>>;
  where?: Maybe<Api_Key_Bool_Exp>;
};


export type Subscription_RootApp_ViewArgs = {
  distinct_on?: Maybe<Array<App_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_View_Order_By>>;
  where?: Maybe<App_View_Bool_Exp>;
};


export type Subscription_RootApp_View_AggregateArgs = {
  distinct_on?: Maybe<Array<App_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_View_Order_By>>;
  where?: Maybe<App_View_Bool_Exp>;
};


export type Subscription_RootApp_View_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<App_View_Stream_Cursor_Input>>;
  where?: Maybe<App_View_Bool_Exp>;
};


export type Subscription_RootAuditArgs = {
  distinct_on?: Maybe<Array<Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Order_By>>;
  where?: Maybe<Audit_Bool_Exp>;
};


export type Subscription_RootAudit_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Order_By>>;
  where?: Maybe<Audit_Bool_Exp>;
};


export type Subscription_RootAudit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audit_Stream_Cursor_Input>>;
  where?: Maybe<Audit_Bool_Exp>;
};


export type Subscription_RootChannel_ConfigurationArgs = {
  distinct_on?: Maybe<Array<Channel_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Configuration_Order_By>>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};


export type Subscription_RootChannel_Configuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Configuration_Order_By>>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};


export type Subscription_RootChannel_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChannel_Configuration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Channel_Configuration_Stream_Cursor_Input>>;
  where?: Maybe<Channel_Configuration_Bool_Exp>;
};


export type Subscription_RootEvent_ActionsArgs = {
  distinct_on?: Maybe<Array<Event_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Actions_Order_By>>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};


export type Subscription_RootEvent_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Actions_Order_By>>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};


export type Subscription_RootEvent_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvent_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Actions_Stream_Cursor_Input>>;
  where?: Maybe<Event_Actions_Bool_Exp>;
};


export type Subscription_RootEvent_Config_MapArgs = {
  distinct_on?: Maybe<Array<Event_Config_Map_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Config_Map_Order_By>>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};


export type Subscription_RootEvent_Config_Map_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Config_Map_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Config_Map_Order_By>>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};


export type Subscription_RootEvent_Config_Map_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Config_Map_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Config_Map_Stream_Cursor_Input>>;
  where?: Maybe<Event_Config_Map_Bool_Exp>;
};


export type Subscription_RootEvent_Cron_JobArgs = {
  distinct_on?: Maybe<Array<Event_Cron_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Cron_Job_Order_By>>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Cron_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Cron_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Cron_Job_Order_By>>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Cron_Job_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Cron_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Cron_Job_Stream_Cursor_Input>>;
  where?: Maybe<Event_Cron_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_MonitorArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Monitor_Order_By>>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_Monitor_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Monitor_Order_By>>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Datadog_Monitor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Datadog_Monitor_Stream_Cursor_Input>>;
  where?: Maybe<Event_Datadog_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_TracingArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Tracing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Tracing_Order_By>>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_Tracing_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Datadog_Tracing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Datadog_Tracing_Order_By>>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};


export type Subscription_RootEvent_Datadog_Tracing_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Datadog_Tracing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Datadog_Tracing_Stream_Cursor_Input>>;
  where?: Maybe<Event_Datadog_Tracing_Bool_Exp>;
};


export type Subscription_RootEvent_DeployArgs = {
  distinct_on?: Maybe<Array<Event_Deploy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Deploy_Order_By>>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};


export type Subscription_RootEvent_Deploy_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Deploy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Deploy_Order_By>>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};


export type Subscription_RootEvent_Deploy_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Deploy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Deploy_Stream_Cursor_Input>>;
  where?: Maybe<Event_Deploy_Bool_Exp>;
};


export type Subscription_RootEvent_HpaArgs = {
  distinct_on?: Maybe<Array<Event_Hpa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Hpa_Order_By>>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};


export type Subscription_RootEvent_Hpa_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Hpa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Hpa_Order_By>>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};


export type Subscription_RootEvent_Hpa_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Hpa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Hpa_Stream_Cursor_Input>>;
  where?: Maybe<Event_Hpa_Bool_Exp>;
};


export type Subscription_RootEvent_IngressArgs = {
  distinct_on?: Maybe<Array<Event_Ingress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Ingress_Order_By>>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};


export type Subscription_RootEvent_Ingress_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Ingress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Ingress_Order_By>>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};


export type Subscription_RootEvent_Ingress_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Ingress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Ingress_Stream_Cursor_Input>>;
  where?: Maybe<Event_Ingress_Bool_Exp>;
};


export type Subscription_RootEvent_JobArgs = {
  distinct_on?: Maybe<Array<Event_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Job_Order_By>>;
  where?: Maybe<Event_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Job_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Job_Order_By>>;
  where?: Maybe<Event_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Job_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Job_Stream_Cursor_Input>>;
  where?: Maybe<Event_Job_Bool_Exp>;
};


export type Subscription_RootEvent_Komodor_AlertArgs = {
  distinct_on?: Maybe<Array<Event_Komodor_Alert_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Komodor_Alert_Order_By>>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};


export type Subscription_RootEvent_Komodor_Alert_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Komodor_Alert_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Komodor_Alert_Order_By>>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};


export type Subscription_RootEvent_Komodor_Alert_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Komodor_Alert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Komodor_Alert_Stream_Cursor_Input>>;
  where?: Maybe<Event_Komodor_Alert_Bool_Exp>;
};


export type Subscription_RootEvent_Kubernetes_Service_ResourceArgs = {
  distinct_on?: Maybe<Array<Event_Kubernetes_Service_Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Kubernetes_Service_Resource_Order_By>>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};


export type Subscription_RootEvent_Kubernetes_Service_Resource_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Kubernetes_Service_Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Kubernetes_Service_Resource_Order_By>>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};


export type Subscription_RootEvent_Kubernetes_Service_Resource_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Kubernetes_Service_Resource_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Kubernetes_Service_Resource_Stream_Cursor_Input>>;
  where?: Maybe<Event_Kubernetes_Service_Resource_Bool_Exp>;
};


export type Subscription_RootEvent_LaunchdarklyArgs = {
  distinct_on?: Maybe<Array<Event_Launchdarkly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Launchdarkly_Order_By>>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};


export type Subscription_RootEvent_Launchdarkly_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Launchdarkly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Launchdarkly_Order_By>>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};


export type Subscription_RootEvent_Launchdarkly_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Launchdarkly_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Launchdarkly_Stream_Cursor_Input>>;
  where?: Maybe<Event_Launchdarkly_Bool_Exp>;
};


export type Subscription_RootEvent_Limit_RangesArgs = {
  distinct_on?: Maybe<Array<Event_Limit_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Limit_Ranges_Order_By>>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};


export type Subscription_RootEvent_Limit_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Limit_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Limit_Ranges_Order_By>>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};


export type Subscription_RootEvent_Limit_Ranges_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Limit_Ranges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Limit_Ranges_Stream_Cursor_Input>>;
  where?: Maybe<Event_Limit_Ranges_Bool_Exp>;
};


export type Subscription_RootEvent_MonitorArgs = {
  distinct_on?: Maybe<Array<Event_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Monitor_Order_By>>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Monitor_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Monitor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Monitor_Order_By>>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Monitor_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Monitor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Monitor_Stream_Cursor_Input>>;
  where?: Maybe<Event_Monitor_Bool_Exp>;
};


export type Subscription_RootEvent_Network_PoliciesArgs = {
  distinct_on?: Maybe<Array<Event_Network_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Network_Policies_Order_By>>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};


export type Subscription_RootEvent_Network_Policies_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Network_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Network_Policies_Order_By>>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};


export type Subscription_RootEvent_Network_Policies_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Network_Policies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Network_Policies_Stream_Cursor_Input>>;
  where?: Maybe<Event_Network_Policies_Bool_Exp>;
};


export type Subscription_RootEvent_NodeArgs = {
  distinct_on?: Maybe<Array<Event_Node_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Node_Order_By>>;
  where?: Maybe<Event_Node_Bool_Exp>;
};


export type Subscription_RootEvent_Node_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Node_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Node_Order_By>>;
  where?: Maybe<Event_Node_Bool_Exp>;
};


export type Subscription_RootEvent_Node_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Node_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Node_Stream_Cursor_Input>>;
  where?: Maybe<Event_Node_Bool_Exp>;
};


export type Subscription_RootEvent_OpsgenieArgs = {
  distinct_on?: Maybe<Array<Event_Opsgenie_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Opsgenie_Order_By>>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};


export type Subscription_RootEvent_Opsgenie_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Opsgenie_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Opsgenie_Order_By>>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};


export type Subscription_RootEvent_Opsgenie_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Opsgenie_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Opsgenie_Stream_Cursor_Input>>;
  where?: Maybe<Event_Opsgenie_Bool_Exp>;
};


export type Subscription_RootEvent_PagerdutyArgs = {
  distinct_on?: Maybe<Array<Event_Pagerduty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pagerduty_Order_By>>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};


export type Subscription_RootEvent_Pagerduty_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Pagerduty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pagerduty_Order_By>>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};


export type Subscription_RootEvent_Pagerduty_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Pagerduty_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Pagerduty_Stream_Cursor_Input>>;
  where?: Maybe<Event_Pagerduty_Bool_Exp>;
};


export type Subscription_RootEvent_PdbArgs = {
  distinct_on?: Maybe<Array<Event_Pdb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pdb_Order_By>>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};


export type Subscription_RootEvent_Pdb_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Pdb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Pdb_Order_By>>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};


export type Subscription_RootEvent_Pdb_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Pdb_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Pdb_Stream_Cursor_Input>>;
  where?: Maybe<Event_Pdb_Bool_Exp>;
};


export type Subscription_RootEvent_Resource_QuotaArgs = {
  distinct_on?: Maybe<Array<Event_Resource_Quota_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Resource_Quota_Order_By>>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};


export type Subscription_RootEvent_Resource_Quota_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Resource_Quota_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Resource_Quota_Order_By>>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};


export type Subscription_RootEvent_Resource_Quota_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Resource_Quota_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Resource_Quota_Stream_Cursor_Input>>;
  where?: Maybe<Event_Resource_Quota_Bool_Exp>;
};


export type Subscription_RootEvent_SecretArgs = {
  distinct_on?: Maybe<Array<Event_Secret_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Secret_Order_By>>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};


export type Subscription_RootEvent_Secret_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Secret_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Secret_Order_By>>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};


export type Subscription_RootEvent_Secret_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Secret_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Secret_Stream_Cursor_Input>>;
  where?: Maybe<Event_Secret_Bool_Exp>;
};


export type Subscription_RootEvent_Sentry_IssueArgs = {
  distinct_on?: Maybe<Array<Event_Sentry_Issue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sentry_Issue_Order_By>>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};


export type Subscription_RootEvent_Sentry_Issue_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sentry_Issue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sentry_Issue_Order_By>>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};


export type Subscription_RootEvent_Sentry_Issue_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Sentry_Issue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Sentry_Issue_Stream_Cursor_Input>>;
  where?: Maybe<Event_Sentry_Issue_Bool_Exp>;
};


export type Subscription_RootEvent_Stale_Kubernetes_AgentArgs = {
  distinct_on?: Maybe<Array<Event_Stale_Kubernetes_Agent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Stale_Kubernetes_Agent_Order_By>>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};


export type Subscription_RootEvent_Stale_Kubernetes_Agent_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Stale_Kubernetes_Agent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Stale_Kubernetes_Agent_Order_By>>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};


export type Subscription_RootEvent_Stale_Kubernetes_Agent_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Stale_Kubernetes_Agent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Stale_Kubernetes_Agent_Stream_Cursor_Input>>;
  where?: Maybe<Event_Stale_Kubernetes_Agent_Bool_Exp>;
};


export type Subscription_RootEvent_TopologyArgs = {
  distinct_on?: Maybe<Array<Event_Topology_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Topology_Order_By>>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};


export type Subscription_RootEvent_Topology_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Topology_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Topology_Order_By>>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};


export type Subscription_RootEvent_Topology_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Topology_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Topology_Stream_Cursor_Input>>;
  where?: Maybe<Event_Topology_Bool_Exp>;
};


export type Subscription_RootEvent_WorkloadArgs = {
  distinct_on?: Maybe<Array<Event_Workload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Workload_Order_By>>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};


export type Subscription_RootEvent_Workload_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Workload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Workload_Order_By>>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};


export type Subscription_RootEvent_Workload_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Workload_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Event_Workload_Stream_Cursor_Input>>;
  where?: Maybe<Event_Workload_Bool_Exp>;
};


export type Subscription_RootExternal_LinkArgs = {
  distinct_on?: Maybe<Array<External_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Order_By>>;
  where?: Maybe<External_Link_Bool_Exp>;
};


export type Subscription_RootExternal_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<External_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Order_By>>;
  where?: Maybe<External_Link_Bool_Exp>;
};


export type Subscription_RootExternal_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternal_Link_ContextArgs = {
  distinct_on?: Maybe<Array<External_Link_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Context_Order_By>>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};


export type Subscription_RootExternal_Link_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<External_Link_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<External_Link_Context_Order_By>>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};


export type Subscription_RootExternal_Link_Context_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootExternal_Link_Context_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<External_Link_Context_Stream_Cursor_Input>>;
  where?: Maybe<External_Link_Context_Bool_Exp>;
};


export type Subscription_RootExternal_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<External_Link_Stream_Cursor_Input>>;
  where?: Maybe<External_Link_Bool_Exp>;
};


export type Subscription_RootHelm_RepoArgs = {
  distinct_on?: Maybe<Array<Helm_Repo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Helm_Repo_Order_By>>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};


export type Subscription_RootHelm_Repo_AggregateArgs = {
  distinct_on?: Maybe<Array<Helm_Repo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Helm_Repo_Order_By>>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};


export type Subscription_RootHelm_Repo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHelm_Repo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Helm_Repo_Stream_Cursor_Input>>;
  where?: Maybe<Helm_Repo_Bool_Exp>;
};


export type Subscription_RootInstallationArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


export type Subscription_RootInstallation_AggregateArgs = {
  distinct_on?: Maybe<Array<Installation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Installation_Order_By>>;
  where?: Maybe<Installation_Bool_Exp>;
};


export type Subscription_RootInstallation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInstallation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Installation_Stream_Cursor_Input>>;
  where?: Maybe<Installation_Bool_Exp>;
};


export type Subscription_RootKomodor_Filter_SettingsArgs = {
  distinct_on?: Maybe<Array<Komodor_Filter_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Filter_Settings_Order_By>>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};


export type Subscription_RootKomodor_Filter_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Filter_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Filter_Settings_Order_By>>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};


export type Subscription_RootKomodor_Filter_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKomodor_Filter_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Filter_Settings_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Filter_Settings_Bool_Exp>;
};


export type Subscription_RootKomodor_ServiceArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Order_By>>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Order_By>>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_AttributesArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Attributes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Attributes_Order_By>>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Attributes_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Attributes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Attributes_Order_By>>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Attributes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKomodor_Service_Attributes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Attributes_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Attributes_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Datadog_DataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Datadog_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Datadog_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Datadog_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Datadog_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Datadog_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Datadog_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Datadog_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Datadog_Data_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Datadog_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Deploy_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Deploy_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Deploy_State_Order_By>>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Deploy_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Deploy_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Deploy_State_Order_By>>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Deploy_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Deploy_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Deploy_State_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Deploy_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Health_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Health_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Health_State_Order_By>>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Health_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Health_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Health_State_Order_By>>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Health_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Health_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Health_State_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Health_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_K8s_MetadataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_K8s_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_K8s_Metadata_Order_By>>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_K8s_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_K8s_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_K8s_Metadata_Order_By>>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_K8s_Metadata_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_K8s_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_K8s_Metadata_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_K8s_Metadata_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Sentry_DataArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Sentry_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Sentry_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Sentry_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Sentry_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Sentry_Data_Order_By>>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Sentry_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Sentry_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Sentry_Data_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Sentry_Data_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Static_Prevention_StateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Static_Prevention_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Static_Prevention_State_Order_By>>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Static_Prevention_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Komodor_Service_Static_Prevention_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Komodor_Service_Static_Prevention_State_Order_By>>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_Static_Prevention_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootKomodor_Service_Static_Prevention_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Static_Prevention_State_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Static_Prevention_State_Bool_Exp>;
};


export type Subscription_RootKomodor_Service_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Komodor_Service_Stream_Cursor_Input>>;
  where?: Maybe<Komodor_Service_Bool_Exp>;
};


export type Subscription_RootKubernetes_Agent_InfoArgs = {
  distinct_on?: Maybe<Array<Kubernetes_Agent_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kubernetes_Agent_Info_Order_By>>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};


export type Subscription_RootKubernetes_Agent_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Kubernetes_Agent_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kubernetes_Agent_Info_Order_By>>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};


export type Subscription_RootKubernetes_Agent_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKubernetes_Agent_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Kubernetes_Agent_Info_Stream_Cursor_Input>>;
  where?: Maybe<Kubernetes_Agent_Info_Bool_Exp>;
};


export type Subscription_RootNode_Status_Agent_TaskArgs = {
  distinct_on?: Maybe<Array<Node_Status_Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Node_Status_Agent_Task_Order_By>>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};


export type Subscription_RootNode_Status_Agent_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Node_Status_Agent_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Node_Status_Agent_Task_Order_By>>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};


export type Subscription_RootNode_Status_Agent_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNode_Status_Agent_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Node_Status_Agent_Task_Stream_Cursor_Input>>;
  where?: Maybe<Node_Status_Agent_Task_Bool_Exp>;
};


export type Subscription_RootPlanArgs = {
  distinct_on?: Maybe<Array<Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Order_By>>;
  where?: Maybe<Plan_Bool_Exp>;
};


export type Subscription_RootPlan_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Order_By>>;
  where?: Maybe<Plan_Bool_Exp>;
};


export type Subscription_RootPlan_By_PkArgs = {
  accountPlan: Scalars['String'];
};


export type Subscription_RootPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Plan_Stream_Cursor_Input>>;
  where?: Maybe<Plan_Bool_Exp>;
};


export type Subscription_RootPod_ListArgs = {
  distinct_on?: Maybe<Array<Pod_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_List_Order_By>>;
  where?: Maybe<Pod_List_Bool_Exp>;
};


export type Subscription_RootPod_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Pod_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_List_Order_By>>;
  where?: Maybe<Pod_List_Bool_Exp>;
};


export type Subscription_RootPod_List_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPod_List_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pod_List_Stream_Cursor_Input>>;
  where?: Maybe<Pod_List_Bool_Exp>;
};


export type Subscription_RootPod_LogsArgs = {
  distinct_on?: Maybe<Array<Pod_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_Logs_Order_By>>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};


export type Subscription_RootPod_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Pod_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pod_Logs_Order_By>>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};


export type Subscription_RootPod_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPod_Logs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pod_Logs_Stream_Cursor_Input>>;
  where?: Maybe<Pod_Logs_Bool_Exp>;
};


export type Subscription_RootRbac_PolicyArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Order_By>>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_ActionArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Action_Order_By>>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Action_Order_By>>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_Action_By_PkArgs = {
  action: Scalars['String'];
};


export type Subscription_RootRbac_Policy_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_Policy_Action_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_Policy_Action_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_ActionsArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Actions_Order_By>>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Actions_Order_By>>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_Actions_By_PkArgs = {
  action: Scalars['String'];
};


export type Subscription_RootRbac_Policy_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_Policy_Actions_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_Policy_Actions_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Policy_Order_By>>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_Policy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRbac_Policy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_Policy_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_RoleArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Order_By>>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};


export type Subscription_RootRbac_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Order_By>>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};


export type Subscription_RootRbac_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRbac_Role_PolicyArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_Role_Policy_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_Role_Policy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_Role_Policy_Order_By>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_Role_Policy_By_PkArgs = {
  policyId: Scalars['uuid'];
  roleId: Scalars['uuid'];
};


export type Subscription_RootRbac_Role_Policy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_Role_Policy_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_Role_Policy_Bool_Exp>;
};


export type Subscription_RootRbac_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_Role_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_Role_Bool_Exp>;
};


export type Subscription_RootRbac_User_RoleArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


export type Subscription_RootRbac_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


export type Subscription_RootRbac_User_Role_By_PkArgs = {
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Subscription_RootRbac_User_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rbac_User_Role_Stream_Cursor_Input>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Role_Stream_Cursor_Input>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootService_Attributes_For_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Attributes_For_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Attributes_For_Account_Order_By>>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};


export type Subscription_RootService_Attributes_For_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Attributes_For_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Attributes_For_Account_Order_By>>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};


export type Subscription_RootService_Attributes_For_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootService_Attributes_For_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Attributes_For_Account_Stream_Cursor_Input>>;
  where?: Maybe<Service_Attributes_For_Account_Bool_Exp>;
};


export type Subscription_RootService_DescriptionArgs = {
  distinct_on?: Maybe<Array<Service_Description_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Description_Order_By>>;
  where?: Maybe<Service_Description_Bool_Exp>;
};


export type Subscription_RootService_Description_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Description_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Description_Order_By>>;
  where?: Maybe<Service_Description_Bool_Exp>;
};


export type Subscription_RootService_Description_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootService_Description_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Description_Stream_Cursor_Input>>;
  where?: Maybe<Service_Description_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Stream_Cursor_Input>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootWorkflow_ConfigurationArgs = {
  distinct_on?: Maybe<Array<Workflow_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Configuration_Order_By>>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};


export type Subscription_RootWorkflow_Configuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Configuration_Order_By>>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};


export type Subscription_RootWorkflow_Configuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWorkflow_Configuration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Workflow_Configuration_Stream_Cursor_Input>>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};


export type Subscription_RootWorkflow_ResultsArgs = {
  distinct_on?: Maybe<Array<Workflow_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Results_Order_By>>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};


export type Subscription_RootWorkflow_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workflow_Results_Order_By>>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};


export type Subscription_RootWorkflow_Results_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWorkflow_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Workflow_Results_Stream_Cursor_Input>>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  acceptedTos: Scalars['Boolean'];
  /** An object relationship */
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  allowedAccounts?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamp'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName: Scalars['String'];
  email: Scalars['citext'];
  id: Scalars['uuid'];
  isKomodorAdmin: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  rbac_user_roles: Array<Rbac_User_Role>;
  /** An aggregate relationship */
  rbac_user_roles_aggregate: Rbac_User_Role_Aggregate;
  role?: Maybe<Scalars['String']>;
  /** An object relationship */
  roleByRole?: Maybe<Role>;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "user" */
export type UserAllowedAccountsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "user" */
export type UserRbac_User_RolesArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserRbac_User_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Rbac_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rbac_User_Role_Order_By>>;
  where?: Maybe<Rbac_User_Role_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  allowedAccounts?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  acceptedTos?: Maybe<Boolean_Comparison_Exp>;
  account?: Maybe<Account_Bool_Exp>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  allowedAccounts?: Maybe<Jsonb_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  email?: Maybe<Citext_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isKomodorAdmin?: Maybe<Boolean_Comparison_Exp>;
  isVerified?: Maybe<Boolean_Comparison_Exp>;
  lastLoginAt?: Maybe<Timestamptz_Comparison_Exp>;
  rbac_user_roles?: Maybe<Rbac_User_Role_Bool_Exp>;
  rbac_user_roles_aggregate?: Maybe<Rbac_User_Role_Aggregate_Bool_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  roleByRole?: Maybe<Role_Bool_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkCace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760',
  /** unique or primary key constraint on columns "email", "accountId" */
  UqUserAccountEmail = 'UQ_user_account_email'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  allowedAccounts?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  allowedAccounts?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  allowedAccounts?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  acceptedTos?: Maybe<Scalars['Boolean']>;
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountId?: Maybe<Scalars['uuid']>;
  allowedAccounts?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  isKomodorAdmin?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  rbac_user_roles?: Maybe<Rbac_User_Role_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<Role_Obj_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastLoginAt?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  accountId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastLoginAt?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  acceptedTos?: Maybe<Order_By>;
  account?: Maybe<Account_Order_By>;
  accountId?: Maybe<Order_By>;
  allowedAccounts?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isKomodorAdmin?: Maybe<Order_By>;
  isVerified?: Maybe<Order_By>;
  lastLoginAt?: Maybe<Order_By>;
  rbac_user_roles_aggregate?: Maybe<Rbac_User_Role_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
  roleByRole?: Maybe<Role_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  allowedAccounts?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AcceptedTos = 'acceptedTos',
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AllowedAccounts = 'allowedAccounts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsKomodorAdmin = 'isKomodorAdmin',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  LastLoginAt = 'lastLoginAt',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AcceptedTos = 'acceptedTos',
  /** column name */
  IsKomodorAdmin = 'isKomodorAdmin',
  /** column name */
  IsVerified = 'isVerified'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AcceptedTos = 'acceptedTos',
  /** column name */
  IsKomodorAdmin = 'isKomodorAdmin',
  /** column name */
  IsVerified = 'isVerified'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  acceptedTos?: Maybe<Scalars['Boolean']>;
  accountId?: Maybe<Scalars['uuid']>;
  allowedAccounts?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  isKomodorAdmin?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  acceptedTos?: Maybe<Scalars['Boolean']>;
  accountId?: Maybe<Scalars['uuid']>;
  allowedAccounts?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  isKomodorAdmin?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AcceptedTos = 'acceptedTos',
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AllowedAccounts = 'allowedAccounts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsKomodorAdmin = 'isKomodorAdmin',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  LastLoginAt = 'lastLoginAt',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "workflow_configuration" */
export type Workflow_Configuration = {
  __typename?: 'workflow_configuration';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['uuid']>;
  active: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  sensors: Scalars['jsonb'];
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  variables?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "workflow_configuration" */
export type Workflow_ConfigurationSensorsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_configuration" */
export type Workflow_ConfigurationSinksArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_configuration" */
export type Workflow_ConfigurationSinksOptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_configuration" */
export type Workflow_ConfigurationVariablesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "workflow_configuration" */
export type Workflow_Configuration_Aggregate = {
  __typename?: 'workflow_configuration_aggregate';
  aggregate?: Maybe<Workflow_Configuration_Aggregate_Fields>;
  nodes: Array<Workflow_Configuration>;
};

/** aggregate fields of "workflow_configuration" */
export type Workflow_Configuration_Aggregate_Fields = {
  __typename?: 'workflow_configuration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Workflow_Configuration_Max_Fields>;
  min?: Maybe<Workflow_Configuration_Min_Fields>;
};


/** aggregate fields of "workflow_configuration" */
export type Workflow_Configuration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Configuration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Configuration_Append_Input = {
  sensors?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workflow_configuration". All fields are combined with a logical 'AND'. */
export type Workflow_Configuration_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Configuration_Bool_Exp>>;
  _not?: Maybe<Workflow_Configuration_Bool_Exp>;
  _or?: Maybe<Array<Workflow_Configuration_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isDeleted?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sensors?: Maybe<Jsonb_Comparison_Exp>;
  sinks?: Maybe<Jsonb_Comparison_Exp>;
  sinksOptions?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  variables?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow_configuration" */
export enum Workflow_Configuration_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA2427d553f85de4c78dfc7a72a0 = 'PK_a2427d553f85de4c78dfc7a72a0'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workflow_Configuration_Delete_At_Path_Input = {
  sensors?: Maybe<Array<Scalars['String']>>;
  sinks?: Maybe<Array<Scalars['String']>>;
  sinksOptions?: Maybe<Array<Scalars['String']>>;
  variables?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workflow_Configuration_Delete_Elem_Input = {
  sensors?: Maybe<Scalars['Int']>;
  sinks?: Maybe<Scalars['Int']>;
  sinksOptions?: Maybe<Scalars['Int']>;
  variables?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workflow_Configuration_Delete_Key_Input = {
  sensors?: Maybe<Scalars['String']>;
  sinks?: Maybe<Scalars['String']>;
  sinksOptions?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "workflow_configuration" */
export type Workflow_Configuration_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sensors?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Workflow_Configuration_Max_Fields = {
  __typename?: 'workflow_configuration_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Workflow_Configuration_Min_Fields = {
  __typename?: 'workflow_configuration_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "workflow_configuration" */
export type Workflow_Configuration_Mutation_Response = {
  __typename?: 'workflow_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Configuration>;
};

/** on_conflict condition type for table "workflow_configuration" */
export type Workflow_Configuration_On_Conflict = {
  constraint: Workflow_Configuration_Constraint;
  update_columns?: Array<Workflow_Configuration_Update_Column>;
  where?: Maybe<Workflow_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow_configuration". */
export type Workflow_Configuration_Order_By = {
  accountId?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDeleted?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sensors?: Maybe<Order_By>;
  sinks?: Maybe<Order_By>;
  sinksOptions?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  variables?: Maybe<Order_By>;
};

/** primary key columns input for table: workflow_configuration */
export type Workflow_Configuration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Configuration_Prepend_Input = {
  sensors?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "workflow_configuration" */
export enum Workflow_Configuration_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  Sensors = 'sensors',
  /** column name */
  Sinks = 'sinks',
  /** column name */
  SinksOptions = 'sinksOptions',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Variables = 'variables'
}

/** input type for updating data in table "workflow_configuration" */
export type Workflow_Configuration_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sensors?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "workflow_configuration" */
export type Workflow_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Configuration_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sensors?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "workflow_configuration" */
export enum Workflow_Configuration_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  Sensors = 'sensors',
  /** column name */
  Sinks = 'sinks',
  /** column name */
  SinksOptions = 'sinksOptions',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Variables = 'variables'
}

export type Workflow_Configuration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Workflow_Configuration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Workflow_Configuration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Workflow_Configuration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Workflow_Configuration_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Workflow_Configuration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Configuration_Set_Input>;
  where: Workflow_Configuration_Bool_Exp;
};

/** columns and relationships of "workflow_results" */
export type Workflow_Results = {
  __typename?: 'workflow_results';
  account?: Maybe<Account>;
  accountId: Scalars['uuid'];
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventTime?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons: Scalars['jsonb'];
  resourceId: Scalars['String'];
  resourceUid?: Maybe<Scalars['String']>;
  results: Scalars['jsonb'];
  resultsSummary: Scalars['jsonb'];
  services?: Maybe<Scalars['jsonb']>;
  shortResourceName: Scalars['String'];
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  triageData: Scalars['jsonb'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsOpenMonitorAlertsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsRbacResourcesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsReasonsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsResultsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsResultsSummaryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsTriageDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "workflow_results" */
export type Workflow_ResultsWorkflowConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "workflow_results" */
export type Workflow_Results_Aggregate = {
  __typename?: 'workflow_results_aggregate';
  aggregate?: Maybe<Workflow_Results_Aggregate_Fields>;
  nodes: Array<Workflow_Results>;
};

/** aggregate fields of "workflow_results" */
export type Workflow_Results_Aggregate_Fields = {
  __typename?: 'workflow_results_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Workflow_Results_Max_Fields>;
  min?: Maybe<Workflow_Results_Min_Fields>;
};


/** aggregate fields of "workflow_results" */
export type Workflow_Results_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Results_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Results_Append_Input = {
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  triageData?: Maybe<Scalars['jsonb']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workflow_results". All fields are combined with a logical 'AND'. */
export type Workflow_Results_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Results_Bool_Exp>>;
  _not?: Maybe<Workflow_Results_Bool_Exp>;
  _or?: Maybe<Array<Workflow_Results_Bool_Exp>>;
  accountId?: Maybe<Uuid_Comparison_Exp>;
  closeReason?: Maybe<String_Comparison_Exp>;
  closedAt?: Maybe<Timestamptz_Comparison_Exp>;
  cluster?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  komodorUid?: Maybe<String_Comparison_Exp>;
  namespace?: Maybe<String_Comparison_Exp>;
  openMonitorAlerts?: Maybe<Jsonb_Comparison_Exp>;
  rbacResources?: Maybe<Jsonb_Comparison_Exp>;
  reasons?: Maybe<Jsonb_Comparison_Exp>;
  resourceId?: Maybe<String_Comparison_Exp>;
  resourceUid?: Maybe<String_Comparison_Exp>;
  results?: Maybe<Jsonb_Comparison_Exp>;
  resultsSummary?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  shortResourceName?: Maybe<String_Comparison_Exp>;
  sourceEventId?: Maybe<String_Comparison_Exp>;
  sourceEventType?: Maybe<String_Comparison_Exp>;
  triageData?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  workflowConfiguration?: Maybe<Jsonb_Comparison_Exp>;
  workflowConfigurationId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow_results" */
export enum Workflow_Results_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB8a9d16db04810198aaa4348a26 = 'PK_b8a9d16db04810198aaa4348a26',
  /** unique or primary key constraint on columns "resourceId" */
  IdxResourceIdClosedAt = 'idx_resource_id_closed_at'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workflow_Results_Delete_At_Path_Input = {
  openMonitorAlerts?: Maybe<Array<Scalars['String']>>;
  rbacResources?: Maybe<Array<Scalars['String']>>;
  reasons?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<Scalars['String']>>;
  resultsSummary?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
  triageData?: Maybe<Array<Scalars['String']>>;
  workflowConfiguration?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workflow_Results_Delete_Elem_Input = {
  openMonitorAlerts?: Maybe<Scalars['Int']>;
  rbacResources?: Maybe<Scalars['Int']>;
  reasons?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['Int']>;
  resultsSummary?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
  triageData?: Maybe<Scalars['Int']>;
  workflowConfiguration?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workflow_Results_Delete_Key_Input = {
  openMonitorAlerts?: Maybe<Scalars['String']>;
  rbacResources?: Maybe<Scalars['String']>;
  reasons?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  resultsSummary?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
  triageData?: Maybe<Scalars['String']>;
  workflowConfiguration?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "workflow_results" */
export type Workflow_Results_Insert_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons?: Maybe<Scalars['jsonb']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUid?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  triageData?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Workflow_Results_Max_Fields = {
  __typename?: 'workflow_results_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUid?: Maybe<Scalars['String']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Workflow_Results_Min_Fields = {
  __typename?: 'workflow_results_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUid?: Maybe<Scalars['String']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workflow_results" */
export type Workflow_Results_Mutation_Response = {
  __typename?: 'workflow_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Results>;
};

/** on_conflict condition type for table "workflow_results" */
export type Workflow_Results_On_Conflict = {
  constraint: Workflow_Results_Constraint;
  update_columns?: Array<Workflow_Results_Update_Column>;
  where?: Maybe<Workflow_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow_results". */
export type Workflow_Results_Order_By = {
  accountId?: Maybe<Order_By>;
  closeReason?: Maybe<Order_By>;
  closedAt?: Maybe<Order_By>;
  cluster?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  eventTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  komodorUid?: Maybe<Order_By>;
  namespace?: Maybe<Order_By>;
  openMonitorAlerts?: Maybe<Order_By>;
  rbacResources?: Maybe<Order_By>;
  reasons?: Maybe<Order_By>;
  resourceId?: Maybe<Order_By>;
  resourceUid?: Maybe<Order_By>;
  results?: Maybe<Order_By>;
  resultsSummary?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  shortResourceName?: Maybe<Order_By>;
  sourceEventId?: Maybe<Order_By>;
  sourceEventType?: Maybe<Order_By>;
  triageData?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  workflowConfiguration?: Maybe<Order_By>;
  workflowConfigurationId?: Maybe<Order_By>;
};

/** primary key columns input for table: workflow_results */
export type Workflow_Results_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Results_Prepend_Input = {
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons?: Maybe<Scalars['jsonb']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  triageData?: Maybe<Scalars['jsonb']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "workflow_results" */
export enum Workflow_Results_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CloseReason = 'closeReason',
  /** column name */
  ClosedAt = 'closedAt',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  KomodorUid = 'komodorUid',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  OpenMonitorAlerts = 'openMonitorAlerts',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Reasons = 'reasons',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUid = 'resourceUid',
  /** column name */
  Results = 'results',
  /** column name */
  ResultsSummary = 'resultsSummary',
  /** column name */
  Services = 'services',
  /** column name */
  ShortResourceName = 'shortResourceName',
  /** column name */
  SourceEventId = 'sourceEventId',
  /** column name */
  SourceEventType = 'sourceEventType',
  /** column name */
  TriageData = 'triageData',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkflowConfiguration = 'workflowConfiguration',
  /** column name */
  WorkflowConfigurationId = 'workflowConfigurationId'
}

/** input type for updating data in table "workflow_results" */
export type Workflow_Results_Set_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons?: Maybe<Scalars['jsonb']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUid?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  triageData?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "workflow_results" */
export type Workflow_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Results_Stream_Cursor_Value_Input = {
  accountId?: Maybe<Scalars['uuid']>;
  closeReason?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['timestamptz']>;
  cluster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  komodorUid?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  openMonitorAlerts?: Maybe<Scalars['jsonb']>;
  rbacResources?: Maybe<Scalars['jsonb']>;
  reasons?: Maybe<Scalars['jsonb']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUid?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['jsonb']>;
  resultsSummary?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
  shortResourceName?: Maybe<Scalars['String']>;
  sourceEventId?: Maybe<Scalars['String']>;
  sourceEventType?: Maybe<Scalars['String']>;
  triageData?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workflowConfiguration?: Maybe<Scalars['jsonb']>;
  workflowConfigurationId?: Maybe<Scalars['String']>;
};

/** update columns of table "workflow_results" */
export enum Workflow_Results_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CloseReason = 'closeReason',
  /** column name */
  ClosedAt = 'closedAt',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventTime = 'eventTime',
  /** column name */
  Id = 'id',
  /** column name */
  KomodorUid = 'komodorUid',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  OpenMonitorAlerts = 'openMonitorAlerts',
  /** column name */
  RbacResources = 'rbacResources',
  /** column name */
  Reasons = 'reasons',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUid = 'resourceUid',
  /** column name */
  Results = 'results',
  /** column name */
  ResultsSummary = 'resultsSummary',
  /** column name */
  Services = 'services',
  /** column name */
  ShortResourceName = 'shortResourceName',
  /** column name */
  SourceEventId = 'sourceEventId',
  /** column name */
  SourceEventType = 'sourceEventType',
  /** column name */
  TriageData = 'triageData',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkflowConfiguration = 'workflowConfiguration',
  /** column name */
  WorkflowConfigurationId = 'workflowConfigurationId'
}

export type Workflow_Results_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Workflow_Results_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Workflow_Results_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Workflow_Results_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Workflow_Results_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Workflow_Results_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Results_Set_Input>;
  where: Workflow_Results_Bool_Exp;
};

export type FetchServiceDescribeQueryVariables = Exact<{
  taskId?: Maybe<Scalars['uuid']>;
}>;


export type FetchServiceDescribeQuery = (
  { __typename?: 'query_root' }
  & { service_description: Array<(
    { __typename?: 'service_description' }
    & Pick<Service_Description, 'description'>
  )> }
);

export type ExecuteGithubRevertMutationVariables = Exact<{
  gitCompareUrl: Scalars['String'];
  serviceId: Scalars['String'];
  namespace: Scalars['String'];
  cluster: Scalars['String'];
  deployName: Scalars['String'];
}>;


export type ExecuteGithubRevertMutation = (
  { __typename?: 'mutation_root' }
  & { githubRevert?: Maybe<(
    { __typename?: 'githubRevertOutput' }
    & Pick<GithubRevertOutput, 'success'>
  )> }
);

export type FetchAuditQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAuditQuery = (
  { __typename?: 'query_root' }
  & { audit: Array<(
    { __typename?: 'audit' }
    & Pick<Audit, 'id' | 'category' | 'crudType' | 'details' | 'additionalDetails' | 'status' | 'eventTimestamp'>
    & { user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'email' | 'accountId'>
    )>, account: (
      { __typename?: 'account' }
      & Pick<Account, 'id'>
    ) }
  )> }
);

export type CreateSilentLoginMutationVariables = Exact<{
  mail: Scalars['String'];
  password: Scalars['String'];
  id: Scalars['String'];
}>;


export type CreateSilentLoginMutation = (
  { __typename?: 'mutation_root' }
  & { desktopSilentLogin?: Maybe<(
    { __typename?: 'SilentLoginOutput' }
    & Pick<SilentLoginOutput, 'jwt'>
  )> }
);

export type CreateSilentSignupMutationVariables = Exact<{
  mail: Scalars['String'];
  password: Scalars['String'];
  id: Scalars['String'];
  installationId: Scalars['String'];
}>;


export type CreateSilentSignupMutation = (
  { __typename?: 'mutation_root' }
  & { desktopSilentSignup?: Maybe<(
    { __typename?: 'SilentSignupOutput' }
    & Pick<SilentSignupOutput, 'jwt'>
  )> }
);

export type GetClustersCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetClustersCountSubscription = (
  { __typename?: 'subscription_root' }
  & { clusters: (
    { __typename?: 'installation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'installation_aggregate_fields' }
      & Pick<Installation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetUsersCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetUsersCountSubscription = (
  { __typename?: 'subscription_root' }
  & { users: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type FetchEventsLimitsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchEventsLimitsQuery = (
  { __typename?: 'query_root' }
  & { fetchEventsLimits?: Maybe<(
    { __typename?: 'fetchEventsLimitsOutput' }
    & Pick<FetchEventsLimitsOutput, 'eventsCountStartEpoch' | 'eventsLimitEpoch' | 'eventsTotalSum'>
  )> }
);

export type GetPlansDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlansDataQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Plan, 'accountPlan' | 'clustersLimit' | 'eventsLimit' | 'nodesLimit' | 'usersLimit'>
  )> }
);

export type GetPlanDataQueryVariables = Exact<{
  accountPlan?: Maybe<Scalars['String']>;
}>;


export type GetPlanDataQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Plan, 'accountPlan' | 'clustersLimit' | 'eventsLimit' | 'nodesLimit' | 'usersLimit'>
  )> }
);

export type UpdateAccountPlanMutationVariables = Exact<{
  id: Scalars['uuid'];
  plan: Scalars['String'];
}>;


export type UpdateAccountPlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'plan'>
  )> }
);

export type GettingStartedGetAgentsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedGetAgentsSubscription = (
  { __typename?: 'subscription_root' }
  & { agents: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'id'>
  )> }
);

export type GettingStartedGetExternalLinksSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedGetExternalLinksSubscription = (
  { __typename?: 'subscription_root' }
  & { external_link: Array<(
    { __typename?: 'external_link' }
    & Pick<External_Link, 'id'>
  )> }
);

export type GettingStartedGetGitIntegrationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedGetGitIntegrationSubscription = (
  { __typename?: 'subscription_root' }
  & { gitIntegration: Array<(
    { __typename?: 'installation' }
    & Pick<Installation, 'id'>
  )> }
);

export type GettingStartedtGetMonitorSinkSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedtGetMonitorSinkSubscription = (
  { __typename?: 'subscription_root' }
  & { monitorSink: Array<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'id'>
  )> }
);

export type GettingStartedGetSlackIntegrationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedGetSlackIntegrationSubscription = (
  { __typename?: 'subscription_root' }
  & { slackIntegration: Array<(
    { __typename?: 'installation' }
    & Pick<Installation, 'id'>
  )> }
);

export type GettingStartedtGetUsersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedtGetUsersSubscription = (
  { __typename?: 'subscription_root' }
  & { users: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )> }
);

export type FetchAgentTaskResultQueryVariables = Exact<{
  taskId?: Maybe<Scalars['uuid']>;
}>;


export type FetchAgentTaskResultQuery = (
  { __typename?: 'query_root' }
  & { agent_task_result: Array<(
    { __typename?: 'agent_task_result' }
    & Pick<Agent_Task_Result, 'result'>
  )> }
);

export type FetchKomodorServiceQueryVariables = Exact<{
  serviceId?: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  inactive?: Maybe<Scalars['Boolean']>;
  includeDeletedServices?: Maybe<Scalars['Boolean']>;
  includeInactiveServices?: Maybe<Scalars['Boolean']>;
}>;


export type FetchKomodorServiceQuery = (
  { __typename?: 'query_root' }
  & { komodor_service: Array<(
    { __typename?: 'komodor_service' }
    & Komodor_ServiceFragment
  )> }
);

export type Komodor_ServiceFragment = (
  { __typename?: 'komodor_service' }
  & Pick<Komodor_Service, 'id' | 'accountId' | 'agentId' | 'inactive' | 'isDeleted' | 'k8sClusterName' | 'namespace' | 'displayName' | 'kind' | 'updatedAt' | 'createdAt' | 'deletedAt'>
  & { k8s_metadata?: Maybe<(
    { __typename?: 'komodor_service_k8s_metadata' }
    & Pick<Komodor_Service_K8s_Metadata, 'id' | 'annotations' | 'podLabels' | 'labels' | 'replicaSetName' | 'updatedAt' | 'createdAt' | 'eventTime' | 'k8sUid' | 'selector' | 'pvcNames' | 'status' | 'schedule' | 'jobState'>
  )>, deploy_state?: Maybe<(
    { __typename?: 'komodor_service_deploy_state' }
    & Pick<Komodor_Service_Deploy_State, 'id' | 'lastDeployEndTime' | 'lastDeployStartTime' | 'readyReplicas' | 'desiredReplicas' | 'updatedAt' | 'createdAt' | 'eventTime'>
  )>, datadog_data?: Maybe<(
    { __typename?: 'komodor_service_datadog_data' }
    & Pick<Komodor_Service_Datadog_Data, 'id' | 'datadogEnv' | 'datadogServiceName' | 'datadogVersion' | 'updatedAt' | 'createdAt' | 'eventTime'>
  )>, health_state?: Maybe<(
    { __typename?: 'komodor_service_health_state' }
    & Pick<Komodor_Service_Health_State, 'id' | 'healthStatus' | 'updatedAt' | 'createdAt' | 'eventTime'>
  )>, sentry_data?: Maybe<(
    { __typename?: 'komodor_service_sentry_data' }
    & Pick<Komodor_Service_Sentry_Data, 'id' | 'sentryEnv' | 'sentryProjectId' | 'sentryServiceName' | 'sentryVersion' | 'updatedAt' | 'createdAt' | 'eventTime'>
  )> }
);

export type DeployFragment = (
  { __typename?: 'event_deploy' }
  & Pick<Event_Deploy, 'deploymentDiffKeys' | 'eventTime' | 'endEventTime' | 'updatedAt' | 'gitCompare' | 'id' | 'isEmptyDeployment' | 'services' | 'status' | 'statusMessage' | 'statusReason' | 'versionFrom' | 'versionTo' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace' | 'deploymentName'>
);

export type LatestServiceDeployQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['jsonb']>;
  replicasOnly?: Maybe<Scalars['String']>;
}>;


export type LatestServiceDeployQuery = (
  { __typename?: 'query_root' }
  & { event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & DeployFragment
  )> }
);

export type IssueFragment = (
  { __typename?: 'workflow_results' }
  & Pick<Workflow_Results, 'id' | 'type' | 'shortResourceName' | 'sourceEventId' | 'createdAt' | 'closedAt' | 'updatedAt' | 'closeReason' | 'services' | 'workflowConfiguration' | 'resultsSummary' | 'cluster' | 'namespace' | 'reasons' | 'eventTime'>
);

export type OpenServiceAvailabilityIssueQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['jsonb']>;
}>;


export type OpenServiceAvailabilityIssueQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & IssueFragment
  )> }
);

export type AddExternalLinkMutationVariables = Exact<{
  displayName: Scalars['String'];
  link: Scalars['String'];
  k8sClusterName?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
  externalLinkContextValue?: Maybe<External_Link_Context_Enum>;
}>;


export type AddExternalLinkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_external_link_one?: Maybe<(
    { __typename?: 'external_link' }
    & Pick<External_Link, 'id'>
  )> }
);

export type DeleteExternalLinkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteExternalLinkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_external_link_by_pk?: Maybe<(
    { __typename?: 'external_link' }
    & Pick<External_Link, 'id'>
  )> }
);

export type FetchExternalLinksQueryVariables = Exact<{
  k8sClusterName?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
}>;


export type FetchExternalLinksQuery = (
  { __typename?: 'query_root' }
  & { external_link: Array<(
    { __typename?: 'external_link' }
    & Pick<External_Link, 'id' | 'externalLinkContextValue' | 'displayName' | 'link' | 'serviceId' | 'k8sClusterName' | 'namespace'>
  )> }
);

export type UpdateExternalLinksMutationVariables = Exact<{
  id: Scalars['uuid'];
  displayName: Scalars['String'];
  link: Scalars['String'];
}>;


export type UpdateExternalLinksMutation = (
  { __typename?: 'mutation_root' }
  & { update_external_link_by_pk?: Maybe<(
    { __typename?: 'external_link' }
    & Pick<External_Link, 'id'>
  )> }
);

export type FetchPodLogsQueryVariables = Exact<{
  taskId?: Maybe<Scalars['uuid']>;
}>;


export type FetchPodLogsQuery = (
  { __typename?: 'query_root' }
  & { pod_logs: Array<(
    { __typename?: 'pod_logs' }
    & Pick<Pod_Logs, 'logTime' | 'message'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  displayName: Scalars['String'];
  email: Scalars['String'];
  role: Scalars['String'];
  rbacRoleIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type AddUserMutation = (
  { __typename?: 'mutation_root' }
  & { createUser?: Maybe<(
    { __typename?: 'CreateUserOutput' }
    & Pick<CreateUserOutput, 'success'>
  )> }
);

export type FetchStaticPreventionResultForServiceQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['String']>;
}>;


export type FetchStaticPreventionResultForServiceQuery = (
  { __typename?: 'query_root' }
  & { komodor_service_static_prevention_state: Array<(
    { __typename?: 'komodor_service_static_prevention_state' }
    & Pick<Komodor_Service_Static_Prevention_State, 'results'>
  )> }
);

export type GetDeployEventsQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
  serviceIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetDeployEventsQuery = (
  { __typename?: 'query_root' }
  & { event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & DeployFragment
  )> }
);

export type DeleteAppViewByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAppViewByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_app_view_by_pk?: Maybe<(
    { __typename?: 'app_view' }
    & Pick<App_View, 'id'>
  )> }
);

export type FetchAppViewByIdQueryVariables = Exact<{
  uid?: Maybe<Scalars['uuid']>;
}>;


export type FetchAppViewByIdQuery = (
  { __typename?: 'query_root' }
  & { app_view: Array<(
    { __typename?: 'app_view' }
    & Pick<App_View, 'updatedAt' | 'serviceIdentifiers' | 'name' | 'id' | 'description' | 'createdAt' | 'authorId' | 'accountId' | 'serviceIdentifierType'>
  )> }
);

export type FetchDeployEventQueryVariables = Exact<{
  eventTimeRangeStart?: Maybe<Scalars['timestamptz']>;
  clusterName?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  deploymentName?: Maybe<Scalars['String']>;
}>;


export type FetchDeployEventQuery = (
  { __typename?: 'query_root' }
  & { event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & Pick<Event_Deploy, 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec'>
  )> }
);

export type JobFragment = (
  { __typename?: 'event_job' }
  & Pick<Event_Job, 'id' | 'uid' | 'name' | 'eventTime' | 'status' | 'cronJobUid' | 'services' | 'action' | 'selector' | 'clusterName' | 'namespace' | 'subEvents'>
);

export type ImpactfulJobEventsQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ImpactfulJobEventsQuery = (
  { __typename?: 'query_root' }
  & { event_job: Array<(
    { __typename?: 'event_job' }
    & JobFragment
  )> }
);

export type ImpactfulWorkflowIssuesByTypeQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}>;


export type ImpactfulWorkflowIssuesByTypeQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & IssueFragment
  )> }
);

export type ImpactfulWorkflowIssuesByTypesQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ImpactfulWorkflowIssuesByTypesQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & IssueFragment
  )> }
);

export type OpenImpactfulWorkflowIssuesByTypesQueryVariables = Exact<{
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}>;


export type OpenImpactfulWorkflowIssuesByTypesQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & IssueFragment
  )> }
);

export type FetchWorkflowMinifiedRunByIdQueryVariables = Exact<{
  runId?: Maybe<Scalars['uuid']>;
}>;


export type FetchWorkflowMinifiedRunByIdQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & IssueFragment
  )> }
);

export type Komodor_AlertFragment = (
  { __typename?: 'event_komodor_alert' }
  & Pick<Event_Komodor_Alert, 'accountId' | 'actor' | 'alertCycleId' | 'alertPriority' | 'alertSeverity' | 'alertSource' | 'alertTags' | 'alertType' | 'assignees' | 'createdAt' | 'eventTime' | 'id' | 'properties' | 'services' | 'status' | 'title' | 'updatedAt' | 'url'>
);

export type Sentry_IssueFragment = (
  { __typename?: 'event_sentry_issue' }
  & Pick<Event_Sentry_Issue, 'accountId' | 'action' | 'actor' | 'additionalData' | 'createdAt' | 'eventTime' | 'id' | 'kind' | 'services' | 'summary' | 'updatedAt'>
);

export type PagerdutyFragment = (
  { __typename?: 'event_pagerduty' }
  & Pick<Event_Pagerduty, 'accountId' | 'actor' | 'assignees' | 'createdAt' | 'eventTime' | 'eventType' | 'id' | 'incidentKey' | 'incidentUrl' | 'pdServiceName' | 'pdTags' | 'properties' | 'services' | 'status' | 'summary' | 'updatedAt'>
);

export type TopologyFragment = (
  { __typename?: 'event_topology' }
  & Pick<Event_Topology, 'accountId' | 'createdAt' | 'details' | 'eventTime' | 'id' | 'kind' | 'services' | 'updatedAt' | 'clusterName' | 'namespace'>
);

export type SecretFragment = (
  { __typename?: 'event_secret' }
  & Pick<Event_Secret, 'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'secretName' | 'k8sDefault' | 'services'>
);

export type LaunchdarklyFragment = (
  { __typename?: 'event_launchdarkly' }
  & Pick<Event_Launchdarkly, 'kind' | 'member' | 'summary' | 'name' | 'description' | 'eventTime' | 'currentVersion' | 'previousVersion' | 'comment' | 'createdAt' | 'target' | 'id' | 'services'>
);

export type ActionFragment = (
  { __typename?: 'event_actions' }
  & Pick<Event_Actions, 'id' | 'eventTime' | 'action' | 'result' | 'services' | 'taskId' | 'metadata' | 'status'>
  & { user?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'email'>
  )> }
);

export type Limit_RangesFragment = (
  { __typename?: 'event_limit_ranges' }
  & Pick<Event_Limit_Ranges, 'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services'>
);

export type Network_PoliciesFragment = (
  { __typename?: 'event_network_policies' }
  & Pick<Event_Network_Policies, 'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services'>
);

export type Kubernetes_Service_ResourceFragment = (
  { __typename?: 'event_kubernetes_service_resource' }
  & Pick<Event_Kubernetes_Service_Resource, 'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'action'>
);

export type IngressFragment = (
  { __typename?: 'event_ingress' }
  & Pick<Event_Ingress, 'id' | 'name' | 'namespace' | 'action' | 'clusterName' | 'eventTime' | 'services'>
);

export type Resource_QuotaFragment = (
  { __typename?: 'event_resource_quota' }
  & Pick<Event_Resource_Quota, 'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services'>
);

export type ImpactfulEventsQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ImpactfulEventsQuery = (
  { __typename?: 'query_root' }
  & { event_komodor_alert: Array<(
    { __typename?: 'event_komodor_alert' }
    & Komodor_AlertFragment
  )>, event_sentry_issue: Array<(
    { __typename?: 'event_sentry_issue' }
    & Sentry_IssueFragment
  )>, event_pagerduty: Array<(
    { __typename?: 'event_pagerduty' }
    & PagerdutyFragment
  )>, event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & DeployFragment
  )>, event_topology: Array<(
    { __typename?: 'event_topology' }
    & TopologyFragment
  )>, event_secret: Array<(
    { __typename?: 'event_secret' }
    & SecretFragment
  )>, event_launchdarkly: Array<(
    { __typename?: 'event_launchdarkly' }
    & LaunchdarklyFragment
  )>, event_actions: Array<(
    { __typename?: 'event_actions' }
    & ActionFragment
  )>, event_limit_ranges: Array<(
    { __typename?: 'event_limit_ranges' }
    & Limit_RangesFragment
  )>, event_network_policies: Array<(
    { __typename?: 'event_network_policies' }
    & Network_PoliciesFragment
  )>, event_kubernetes_service_resource: Array<(
    { __typename?: 'event_kubernetes_service_resource' }
    & Kubernetes_Service_ResourceFragment
  )>, event_ingress: Array<(
    { __typename?: 'event_ingress' }
    & IngressFragment
  )>, event_resource_quota: Array<(
    { __typename?: 'event_resource_quota' }
    & Resource_QuotaFragment
  )> }
);

export type InvestigateEventsQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
}>;


export type InvestigateEventsQuery = (
  { __typename?: 'query_root' }
  & { event_komodor_alert: Array<(
    { __typename?: 'event_komodor_alert' }
    & Komodor_AlertFragment
  )>, event_sentry_issue: Array<(
    { __typename?: 'event_sentry_issue' }
    & Sentry_IssueFragment
  )>, event_pagerduty: Array<(
    { __typename?: 'event_pagerduty' }
    & PagerdutyFragment
  )>, event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & DeployFragment
  )>, event_topology: Array<(
    { __typename?: 'event_topology' }
    & TopologyFragment
  )>, event_secret: Array<(
    { __typename?: 'event_secret' }
    & SecretFragment
  )>, event_launchdarkly: Array<(
    { __typename?: 'event_launchdarkly' }
    & LaunchdarklyFragment
  )>, event_actions: Array<(
    { __typename?: 'event_actions' }
    & ActionFragment
  )>, event_limit_ranges: Array<(
    { __typename?: 'event_limit_ranges' }
    & Limit_RangesFragment
  )>, event_network_policies: Array<(
    { __typename?: 'event_network_policies' }
    & Network_PoliciesFragment
  )>, event_kubernetes_service_resource: Array<(
    { __typename?: 'event_kubernetes_service_resource' }
    & Kubernetes_Service_ResourceFragment
  )>, event_ingress: Array<(
    { __typename?: 'event_ingress' }
    & IngressFragment
  )>, event_resource_quota: Array<(
    { __typename?: 'event_resource_quota' }
    & Resource_QuotaFragment
  )> }
);

export type FetchNodeStatusQueryVariables = Exact<{
  taskId?: Maybe<Scalars['uuid']>;
}>;


export type FetchNodeStatusQuery = (
  { __typename?: 'query_root' }
  & { node_status_agent_task: Array<(
    { __typename?: 'node_status_agent_task' }
    & Pick<Node_Status_Agent_Task, 'status'>
  )> }
);

export type CountNodeWorkflowConfigurationQueryVariables = Exact<{
  clusterName?: Maybe<Scalars['String']>;
}>;


export type CountNodeWorkflowConfigurationQuery = (
  { __typename?: 'query_root' }
  & { workflow_configuration_aggregate: (
    { __typename?: 'workflow_configuration_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'workflow_configuration_aggregate_fields' }
      & Pick<Workflow_Configuration_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CountNodeWorkflowResultsQueryVariables = Exact<{
  nodeName?: Maybe<Scalars['String']>;
  clusterName?: Maybe<Scalars['String']>;
}>;


export type CountNodeWorkflowResultsQuery = (
  { __typename?: 'query_root' }
  & { workflow_results_aggregate: (
    { __typename?: 'workflow_results_aggregate' }
    & { nodes: Array<(
      { __typename?: 'workflow_results' }
      & Pick<Workflow_Results, 'closedAt' | 'id'>
    )> }
  ) }
);

export type IssuesClosedAtByIdsQueryVariables = Exact<{
  eventIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type IssuesClosedAtByIdsQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & Pick<Workflow_Results, 'id' | 'closedAt'>
  )> }
);

export type DeployInsightsStatusesQueryVariables = Exact<{
  eventIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DeployInsightsStatusesQuery = (
  { __typename?: 'query_root' }
  & { event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & Pick<Event_Deploy, 'id' | 'status'>
  )> }
);

export type DeployInsightByIdQueryVariables = Exact<{
  eventId?: Scalars['String'];
}>;


export type DeployInsightByIdQuery = (
  { __typename?: 'query_root' }
  & { event_deploy: Array<(
    { __typename?: 'event_deploy' }
    & DeployFragment
  )> }
);

export type ManualActionInsightByIdQueryVariables = Exact<{
  eventId?: Maybe<Scalars['uuid']>;
}>;


export type ManualActionInsightByIdQuery = (
  { __typename?: 'query_root' }
  & { event_actions: Array<(
    { __typename?: 'event_actions' }
    & ActionFragment
  )> }
);

export type InsertFilterMutationVariables = Exact<{
  filterData?: Maybe<Scalars['jsonb']>;
  filterName?: Maybe<Scalars['String']>;
  filterType?: Maybe<Scalars['String']>;
}>;


export type InsertFilterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_komodor_filter_settings_one?: Maybe<(
    { __typename?: 'komodor_filter_settings' }
    & Pick<Komodor_Filter_Settings, 'id'>
  )> }
);

export type DeleteFilterMutationVariables = Exact<{
  filterId?: Maybe<Scalars['uuid']>;
}>;


export type DeleteFilterMutation = (
  { __typename?: 'mutation_root' }
  & { delete_komodor_filter_settings?: Maybe<(
    { __typename?: 'komodor_filter_settings_mutation_response' }
    & Pick<Komodor_Filter_Settings_Mutation_Response, 'affected_rows'>
  )> }
);

export type SavedFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedFiltersQuery = (
  { __typename?: 'query_root' }
  & { komodor_filter_settings: Array<(
    { __typename?: 'komodor_filter_settings' }
    & Pick<Komodor_Filter_Settings, 'id' | 'filterName' | 'filterData' | 'filterSettingType'>
  )> }
);

export type CreateGithubInstallationMutationVariables = Exact<{
  installationId: Scalars['Int'];
}>;


export type CreateGithubInstallationMutation = (
  { __typename?: 'mutation_root' }
  & { createGithubInstallation?: Maybe<(
    { __typename?: 'Output' }
    & Pick<Output, 'success'>
  )> }
);

export type InitAttributesConfigurationsMutationVariables = Exact<{ [key: string]: never; }>;


export type InitAttributesConfigurationsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_attributes_for_account?: Maybe<(
    { __typename?: 'service_attributes_for_account_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_attributes_for_account' }
      & Pick<Service_Attributes_For_Account, 'id'>
    )> }
  )> }
);

export type FindInstalledAgentSubscriptionVariables = Exact<{
  apiKey: Scalars['uuid'];
}>;


export type FindInstalledAgentSubscription = (
  { __typename?: 'subscription_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'id'>
  )> }
);

export type CreatePagerDutyInstallationMutationVariables = Exact<{
  refreshToken?: Scalars['String'];
  accessToken?: Scalars['String'];
}>;


export type CreatePagerDutyInstallationMutation = (
  { __typename?: 'mutation_root' }
  & { createPagerDutyInstallation?: Maybe<(
    { __typename?: 'CreatePagerDutyInstallationOutput' }
    & Pick<CreatePagerDutyInstallationOutput, 'success' | 'installationId'>
  )> }
);

export type CreateSlackInstallationMutationVariables = Exact<{
  code: Scalars['String'];
  redirect_uri: Scalars['String'];
}>;


export type CreateSlackInstallationMutation = (
  { __typename?: 'mutation_root' }
  & { createSlackInstallation?: Maybe<(
    { __typename?: 'Output' }
    & Pick<Output, 'success' | 'channelId'>
  )> }
);

export type InstallationsPerAccountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InstallationsPerAccountSubscription = (
  { __typename?: 'subscription_root' }
  & { installation: Array<(
    { __typename?: 'installation' }
    & Pick<Installation, 'id' | 'integration' | 'createdAt' | 'configuration' | 'lookupId' | 'showInWeb' | 'deletedAt'>
    & { user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'email' | 'displayName'>
      & { _id: User['email'] }
    )> }
  )> }
);

export type RemoveWorkflowConfigurationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveWorkflowConfigurationMutation = (
  { __typename?: 'mutation_root' }
  & { update_workflow_configuration_by_pk?: Maybe<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'id'>
  )> }
);

export type UpdateActiveWorkflowConfigurationMutationVariables = Exact<{
  id: Scalars['uuid'];
  active: Scalars['Boolean'];
}>;


export type UpdateActiveWorkflowConfigurationMutation = (
  { __typename?: 'mutation_root' }
  & { update_workflow_configuration_by_pk?: Maybe<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'id'>
  )> }
);

export type FetchWorkflowConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchWorkflowConfigurationsQuery = (
  { __typename?: 'query_root' }
  & { workflow_configuration: Array<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'active' | 'name' | 'sensors' | 'sinks' | 'type' | 'variables' | 'id' | 'sinksOptions' | 'createdAt' | 'updatedAt'>
  )> }
);

export type FetchWorkflowConfigurationByIdQueryVariables = Exact<{
  configId?: Maybe<Scalars['uuid']>;
}>;


export type FetchWorkflowConfigurationByIdQuery = (
  { __typename?: 'query_root' }
  & { workflow_configuration: Array<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'active' | 'createdAt' | 'updatedAt' | 'id' | 'name' | 'sensors' | 'sinks' | 'sinksOptions' | 'variables' | 'type'>
  )> }
);

export type FetchWorkflowConfigurationByNameQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type FetchWorkflowConfigurationByNameQuery = (
  { __typename?: 'query_root' }
  & { workflow_configuration: Array<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'active' | 'createdAt' | 'id' | 'name' | 'sensors' | 'sinks' | 'variables' | 'type'>
  )> }
);

export type FetchWorkflowsRunsQueryVariables = Exact<{
  from?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['timestamptz']>;
}>;


export type FetchWorkflowsRunsQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & Pick<Workflow_Results, 'id' | 'sourceEventId' | 'type' | 'createdAt' | 'eventTime' | 'shortResourceName' | 'workflowConfiguration' | 'closedAt' | 'closeReason' | 'resultsSummary' | 'cluster' | 'namespace' | 'reasons'>
  )> }
);

export type FetchWorkflowRunByIdQueryVariables = Exact<{
  runId?: Maybe<Scalars['uuid']>;
}>;


export type FetchWorkflowRunByIdQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & Pick<Workflow_Results, 'createdAt' | 'eventTime' | 'id' | 'results' | 'shortResourceName' | 'type' | 'workflowConfiguration' | 'closedAt' | 'closeReason' | 'services' | 'resultsSummary' | 'cluster' | 'namespace' | 'reasons' | 'triageData'>
  )> }
);

export type FetchWorkflowRunBySensorIdQueryVariables = Exact<{
  sourceEventId?: Scalars['String'];
}>;


export type FetchWorkflowRunBySensorIdQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & Pick<Workflow_Results, 'id' | 'sourceEventId' | 'type' | 'results' | 'createdAt' | 'eventTime' | 'shortResourceName' | 'closedAt' | 'closeReason' | 'resultsSummary' | 'cluster' | 'namespace' | 'workflowConfiguration' | 'reasons' | 'triageData'>
  )> }
);

export type FetchWorkflowRunBySensorIdsQueryVariables = Exact<{
  sourceEventIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FetchWorkflowRunBySensorIdsQuery = (
  { __typename?: 'query_root' }
  & { workflow_results: Array<(
    { __typename?: 'workflow_results' }
    & Pick<Workflow_Results, 'id' | 'sourceEventId' | 'type' | 'results' | 'createdAt' | 'eventTime' | 'shortResourceName' | 'closedAt' | 'closeReason' | 'resultsSummary' | 'cluster' | 'namespace' | 'workflowConfiguration' | 'reasons' | 'triageData'>
  )> }
);

export type FetchAllSlackChannelsMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type FetchAllSlackChannelsMutation = (
  { __typename?: 'mutation_root' }
  & { fetchAllSlackChannels?: Maybe<(
    { __typename?: 'fetchAllSlackChannelsOutput' }
    & Pick<FetchAllSlackChannelsOutput, 'success' | 'channels'>
  )> }
);

export type FetchOpsgenieRespondersQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchOpsgenieRespondersQuery = (
  { __typename?: 'query_root' }
  & { fetchOpsgenieResponders?: Maybe<(
    { __typename?: 'RespondersOutput' }
    & Pick<RespondersOutput, 'success' | 'responders'>
  )> }
);

export type ValidateOpsgenieApiKeyQueryVariables = Exact<{
  apiKey: Scalars['String'];
}>;


export type ValidateOpsgenieApiKeyQuery = (
  { __typename?: 'query_root' }
  & { validateOpsgenieApiKey?: Maybe<(
    { __typename?: 'OpsgenieValidateResponse' }
    & Pick<OpsgenieValidateResponse, 'valid'>
  )> }
);

export type CreateWorkflowConfigurationMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: Scalars['String'];
  sensors: Scalars['jsonb'];
  variables?: Maybe<Scalars['jsonb']>;
  sinks?: Maybe<Scalars['jsonb']>;
  sinksOptions?: Maybe<Scalars['jsonb']>;
}>;


export type CreateWorkflowConfigurationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_workflow_configuration_one?: Maybe<(
    { __typename?: 'workflow_configuration' }
    & Pick<Workflow_Configuration, 'id'>
  )> }
);

export type ChannelConfigurationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChannelConfigurationSubscription = (
  { __typename?: 'subscription_root' }
  & { channel_configuration: Array<(
    { __typename?: 'channel_configuration' }
    & Pick<Channel_Configuration, 'id' | 'type' | 'configuration'>
  )> }
);

export type CreateChannelConfigurationMutationVariables = Exact<{
  configuration?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
}>;


export type CreateChannelConfigurationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_channel_configuration?: Maybe<(
    { __typename?: 'channel_configuration_mutation_response' }
    & { returning: Array<(
      { __typename?: 'channel_configuration' }
      & Pick<Channel_Configuration, 'id'>
    )> }
  )> }
);

export type FetchAllServicesQueryVariables = Exact<{
  fetchServicesFromMaterializedView?: Maybe<Scalars['Boolean']>;
  includeLabels?: Maybe<Scalars['Boolean']>;
  includeAnnotations?: Maybe<Scalars['Boolean']>;
}>;


export type FetchAllServicesQuery = (
  { __typename?: 'query_root' }
  & { fetchAllServices?: Maybe<(
    { __typename?: 'AllServicesOutput' }
    & Pick<AllServicesOutput, 'services'>
  )> }
);

export type FetchServicesByPageMutationVariables = Exact<{
  checkedFilters?: Maybe<Scalars['jsonb']>;
  textFilter?: Maybe<Scalars['String']>;
  sortStrategy?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FetchServicesByPageMutation = (
  { __typename?: 'mutation_root' }
  & { fetchServicesByPage?: Maybe<(
    { __typename?: 'ServicesByPageOutput' }
    & Pick<ServicesByPageOutput, 'services'>
  )> }
);

export type FetchServicesFiltersMutationVariables = Exact<{
  checkedFilters?: Maybe<Scalars['jsonb']>;
  textFilter?: Maybe<Scalars['String']>;
}>;


export type FetchServicesFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { fetchServicesFilters?: Maybe<(
    { __typename?: 'ServicesFiltersOutput' }
    & Pick<ServicesFiltersOutput, 'filters'>
  )> }
);

export type FetchAllJobsQueryVariables = Exact<{
  fetchJobsFromMaterializedView?: Maybe<Scalars['Boolean']>;
  includeLabels?: Maybe<Scalars['Boolean']>;
  includeAnnotations?: Maybe<Scalars['Boolean']>;
}>;


export type FetchAllJobsQuery = (
  { __typename?: 'query_root' }
  & { fetchAllJobs?: Maybe<(
    { __typename?: 'AllServicesOutput' }
    & Pick<AllServicesOutput, 'services'>
  )> }
);

export type FetchJobsByPageMutationVariables = Exact<{
  checkedFilters?: Maybe<Scalars['jsonb']>;
  textFilter?: Maybe<Scalars['String']>;
  sortStrategy?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FetchJobsByPageMutation = (
  { __typename?: 'mutation_root' }
  & { fetchJobsByPage?: Maybe<(
    { __typename?: 'JobsByPageOutput' }
    & Pick<JobsByPageOutput, 'services'>
  )> }
);

export type FetchJobsFiltersMutationVariables = Exact<{
  filterType?: Maybe<Scalars['String']>;
  checkedFilters?: Maybe<Scalars['jsonb']>;
  textFilter?: Maybe<Scalars['String']>;
}>;


export type FetchJobsFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { fetchJobsFilters?: Maybe<(
    { __typename?: 'JobsFiltersOutput' }
    & Pick<JobsFiltersOutput, 'filters'>
  )> }
);

export type ListServiceAttributesForAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type ListServiceAttributesForAccountQuery = (
  { __typename?: 'query_root' }
  & { service_attributes_for_account: Array<(
    { __typename?: 'service_attributes_for_account' }
    & Pick<Service_Attributes_For_Account, 'displayName' | 'keyName' | 'mappings'>
  )> }
);

export type FetchAllKomodorServiceAttributesByKeysQueryVariables = Exact<{
  keys?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  servicesIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FetchAllKomodorServiceAttributesByKeysQuery = (
  { __typename?: 'query_root' }
  & { komodor_service_attributes: Array<(
    { __typename?: 'komodor_service_attributes' }
    & Pick<Komodor_Service_Attributes, 'key' | 'serviceId' | 'value'>
  )> }
);

export type CreateNewUserAccountMutationVariables = Exact<{
  userDisplayName: Scalars['String'];
  userEmail: Scalars['citext'];
  accountName: Scalars['String'];
  identityProvider: Scalars['String'];
  plan: Scalars['String'];
}>;


export type CreateNewUserAccountMutation = (
  { __typename?: 'mutation_root' }
  & { createNewUserAccount?: Maybe<(
    { __typename?: 'CreateAccountOutput' }
    & Pick<CreateAccountOutput, 'success' | 'userId' | 'accountId'>
  )> }
);

export type AccountByIdQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;


export type AccountByIdQuery = (
  { __typename?: 'query_root' }
  & { account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id' | 'name' | 'identityProvider' | 'isRbacEnabled' | 'createdAt' | 'inPoc'>
  )> }
);

export type FetchLastAgentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchLastAgentInfoQuery = (
  { __typename?: 'query_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'clusterName' | 'configuration' | 'inactive' | 'lastSeenAlive'>
  )> }
);

export type FetchAgentInfoByIdQueryVariables = Exact<{
  agentId?: Maybe<Scalars['uuid']>;
}>;


export type FetchAgentInfoByIdQuery = (
  { __typename?: 'query_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'clusterName' | 'configuration' | 'inactive' | 'lastSeenAlive'>
  )> }
);

export type FetchAgentInfoByClusterSubscriptionVariables = Exact<{
  agentId: Scalars['uuid'];
}>;


export type FetchAgentInfoByClusterSubscription = (
  { __typename?: 'subscription_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'clusterName' | 'configuration' | 'inactive' | 'lastSeenAlive'>
  )> }
);

export type FetchAllActiveAgentInfosQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllActiveAgentInfosQuery = (
  { __typename?: 'query_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'clusterName' | 'configuration' | 'inactive' | 'lastSeenAlive'>
  )> }
);

export type FetchAgentsInfoForDowntimeEventsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamp']>;
  endDate?: Maybe<Scalars['timestamp']>;
}>;


export type FetchAgentsInfoForDowntimeEventsQuery = (
  { __typename?: 'query_root' }
  & { kubernetes_agent_info: Array<(
    { __typename?: 'kubernetes_agent_info' }
    & Pick<Kubernetes_Agent_Info, 'clusterName' | 'createdAt' | 'lastSeenAlive'>
  )> }
);

export type ExecuteAgentTaskMutationVariables = Exact<{
  agentId: Scalars['String'];
  type: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
}>;


export type ExecuteAgentTaskMutation = (
  { __typename?: 'mutation_root' }
  & { executeAgentTask?: Maybe<(
    { __typename?: 'AgentTaskOutput' }
    & Pick<AgentTaskOutput, 'success' | 'result' | 'failureMessage' | 'taskId' | 'timeToStartInMs' | 'timeToCompleteInMs' | 'timeToResultInMs'>
  )> }
);

export type GetAgentTaskStatusSubscriptionVariables = Exact<{
  taskId?: Maybe<Scalars['uuid']>;
}>;


export type GetAgentTaskStatusSubscription = (
  { __typename?: 'subscription_root' }
  & { agent_task: Array<(
    { __typename?: 'agent_task' }
    & Pick<Agent_Task, 'state' | 'failureMessage'>
  )> }
);

export type InsertAgentTaskMutationVariables = Exact<{
  agentId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['jsonb']>;
  deletionScheduledAt?: Maybe<Scalars['timestamp']>;
  metadata?: Maybe<Scalars['jsonb']>;
}>;


export type InsertAgentTaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_agent_task_one?: Maybe<(
    { __typename?: 'agent_task' }
    & Pick<Agent_Task, 'id'>
  )> }
);

export type SetAgentTaskStateMutationVariables = Exact<{
  id: Scalars['uuid'];
  state: Scalars['String'];
}>;


export type SetAgentTaskStateMutation = (
  { __typename?: 'mutation_root' }
  & { update_agent_task?: Maybe<(
    { __typename?: 'agent_task_mutation_response' }
    & Pick<Agent_Task_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetAccountByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountByIdQuery = (
  { __typename?: 'query_root' }
  & { account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'isRbacEnabled'>
  )> }
);

export const Komodor_ServiceFragmentDoc = gql`
    fragment komodor_service on komodor_service {
  id
  accountId
  agentId
  inactive
  isDeleted
  k8sClusterName
  namespace
  displayName
  kind
  updatedAt
  createdAt
  deletedAt
  k8s_metadata {
    id
    annotations
    podLabels
    labels
    replicaSetName
    updatedAt
    createdAt
    eventTime
    k8sUid
    selector
    pvcNames
    status
    schedule
    jobState
  }
  deploy_state {
    id
    lastDeployEndTime
    lastDeployStartTime
    readyReplicas
    desiredReplicas
    updatedAt
    createdAt
    eventTime
  }
  datadog_data {
    id
    datadogEnv
    datadogServiceName
    datadogVersion
    updatedAt
    createdAt
    eventTime
  }
  health_state {
    id
    healthStatus
    updatedAt
    createdAt
    eventTime
  }
  sentry_data {
    id
    sentryEnv
    sentryProjectId
    sentryServiceName
    sentryVersion
    updatedAt
    createdAt
    eventTime
  }
}
    `;
export const DeployFragmentDoc = gql`
    fragment deploy on event_deploy {
  deploymentDiffKeys
  eventTime
  endEventTime
  updatedAt
  gitCompare
  id
  isEmptyDeployment
  services
  status
  statusMessage
  statusReason
  versionFrom
  versionTo
  changeType
  generation
  trackedFiles
  failed_container
  strategy
  clusterName
  namespace
  deploymentName
}
    `;
export const IssueFragmentDoc = gql`
    fragment issue on workflow_results {
  id
  type
  shortResourceName
  sourceEventId
  createdAt
  closedAt
  updatedAt
  closeReason
  services
  workflowConfiguration
  resultsSummary
  cluster
  namespace
  reasons
  eventTime
}
    `;
export const JobFragmentDoc = gql`
    fragment job on event_job {
  id
  uid
  name
  eventTime
  status
  cronJobUid
  services
  action
  selector
  clusterName
  namespace
  subEvents
}
    `;
export const Komodor_AlertFragmentDoc = gql`
    fragment komodor_alert on event_komodor_alert {
  accountId
  actor
  alertCycleId
  alertPriority
  alertSeverity
  alertSource
  alertTags
  alertType
  assignees
  createdAt
  eventTime
  id
  properties
  services
  status
  title
  updatedAt
  url
}
    `;
export const Sentry_IssueFragmentDoc = gql`
    fragment sentry_issue on event_sentry_issue {
  accountId
  action
  actor
  additionalData
  createdAt
  eventTime
  id
  kind
  services
  summary
  updatedAt
}
    `;
export const PagerdutyFragmentDoc = gql`
    fragment pagerduty on event_pagerduty {
  accountId
  actor
  assignees
  createdAt
  eventTime
  eventType
  id
  incidentKey
  incidentUrl
  pdServiceName
  pdTags
  properties
  services
  status
  summary
  updatedAt
}
    `;
export const TopologyFragmentDoc = gql`
    fragment topology on event_topology {
  accountId
  createdAt
  details
  eventTime
  id
  kind
  services
  updatedAt
  clusterName
  namespace
}
    `;
export const SecretFragmentDoc = gql`
    fragment secret on event_secret {
  action
  clusterName
  eventTime
  namespace
  id
  secretName
  k8sDefault
  services
}
    `;
export const LaunchdarklyFragmentDoc = gql`
    fragment launchdarkly on event_launchdarkly {
  kind
  member
  summary
  name
  description
  eventTime
  currentVersion
  previousVersion
  comment
  createdAt
  target
  id
  services
}
    `;
export const ActionFragmentDoc = gql`
    fragment action on event_actions {
  id
  eventTime
  action
  result
  services
  taskId
  metadata
  status
  user {
    email
  }
}
    `;
export const Limit_RangesFragmentDoc = gql`
    fragment limit_ranges on event_limit_ranges {
  id
  name
  namespace
  clusterName
  eventTime
  services
}
    `;
export const Network_PoliciesFragmentDoc = gql`
    fragment network_policies on event_network_policies {
  id
  name
  namespace
  clusterName
  eventTime
  services
}
    `;
export const Kubernetes_Service_ResourceFragmentDoc = gql`
    fragment kubernetes_service_resource on event_kubernetes_service_resource {
  id
  name
  namespace
  clusterName
  eventTime
  services
  action
}
    `;
export const IngressFragmentDoc = gql`
    fragment ingress on event_ingress {
  id
  name
  namespace
  action
  clusterName
  eventTime
  services
}
    `;
export const Resource_QuotaFragmentDoc = gql`
    fragment resource_quota on event_resource_quota {
  id
  name
  namespace
  clusterName
  eventTime
  services
}
    `;
export const FetchServiceDescribeDocument = gql`
    query fetchServiceDescribe($taskId: uuid) {
  service_description(where: {taskId: {_eq: $taskId}}) {
    description
  }
}
    `;

export function useFetchServiceDescribeQuery(options: Omit<Urql.UseQueryArgs<FetchServiceDescribeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchServiceDescribeQuery>({ query: FetchServiceDescribeDocument, ...options });
};
export const ExecuteGithubRevertDocument = gql`
    mutation executeGithubRevert($gitCompareUrl: String!, $serviceId: String!, $namespace: String!, $cluster: String!, $deployName: String!) {
  githubRevert(
    gitCompareUrl: $gitCompareUrl
    serviceId: $serviceId
    namespace: $namespace
    cluster: $cluster
    deployName: $deployName
  ) {
    success
  }
}
    `;

export function useExecuteGithubRevertMutation() {
  return Urql.useMutation<ExecuteGithubRevertMutation, ExecuteGithubRevertMutationVariables>(ExecuteGithubRevertDocument);
};
export const FetchAuditDocument = gql`
    query fetchAudit {
  audit {
    id
    category
    crudType
    details
    additionalDetails
    status
    eventTimestamp
    user {
      email
      accountId
    }
    account {
      id
    }
  }
}
    `;

export function useFetchAuditQuery(options: Omit<Urql.UseQueryArgs<FetchAuditQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAuditQuery>({ query: FetchAuditDocument, ...options });
};
export const CreateSilentLoginDocument = gql`
    mutation createSilentLogin($mail: String!, $password: String!, $id: String!) {
  desktopSilentLogin(params: {mail: $mail, password: $password, id: $id}) {
    jwt
  }
}
    `;

export function useCreateSilentLoginMutation() {
  return Urql.useMutation<CreateSilentLoginMutation, CreateSilentLoginMutationVariables>(CreateSilentLoginDocument);
};
export const CreateSilentSignupDocument = gql`
    mutation createSilentSignup($mail: String!, $password: String!, $id: String!, $installationId: String!) {
  desktopSilentSignup(
    params: {mail: $mail, password: $password, id: $id, installationId: $installationId}
  ) {
    jwt
  }
}
    `;

export function useCreateSilentSignupMutation() {
  return Urql.useMutation<CreateSilentSignupMutation, CreateSilentSignupMutationVariables>(CreateSilentSignupDocument);
};
export const GetClustersCountDocument = gql`
    subscription getClustersCount {
  clusters: installation_aggregate(
    where: {integration: {_eq: "kubernetes"}, _and: {isDeleted: {_eq: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useGetClustersCountSubscription<TData = GetClustersCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetClustersCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetClustersCountSubscription, TData>) {
  return Urql.useSubscription<GetClustersCountSubscription, TData, GetClustersCountSubscriptionVariables>({ query: GetClustersCountDocument, ...options }, handler);
};
export const GetUsersCountDocument = gql`
    subscription getUsersCount {
  users: user_aggregate(where: {deletedAt: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

export function useGetUsersCountSubscription<TData = GetUsersCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetUsersCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetUsersCountSubscription, TData>) {
  return Urql.useSubscription<GetUsersCountSubscription, TData, GetUsersCountSubscriptionVariables>({ query: GetUsersCountDocument, ...options }, handler);
};
export const FetchEventsLimitsDocument = gql`
    query fetchEventsLimits {
  fetchEventsLimits {
    eventsCountStartEpoch
    eventsLimitEpoch
    eventsTotalSum
  }
}
    `;

export function useFetchEventsLimitsQuery(options: Omit<Urql.UseQueryArgs<FetchEventsLimitsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchEventsLimitsQuery>({ query: FetchEventsLimitsDocument, ...options });
};
export const GetPlansDataDocument = gql`
    query getPlansData {
  plan {
    accountPlan
    clustersLimit
    eventsLimit
    nodesLimit
    usersLimit
  }
}
    `;

export function useGetPlansDataQuery(options: Omit<Urql.UseQueryArgs<GetPlansDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetPlansDataQuery>({ query: GetPlansDataDocument, ...options });
};
export const GetPlanDataDocument = gql`
    query getPlanData($accountPlan: String) {
  plan(where: {accountPlan: {_eq: $accountPlan}}) {
    accountPlan
    clustersLimit
    eventsLimit
    nodesLimit
    usersLimit
  }
}
    `;

export function useGetPlanDataQuery(options: Omit<Urql.UseQueryArgs<GetPlanDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetPlanDataQuery>({ query: GetPlanDataDocument, ...options });
};
export const UpdateAccountPlanDocument = gql`
    mutation updateAccountPlan($id: uuid!, $plan: String!) {
  update_account_by_pk(
    pk_columns: {id: $id}
    _set: {plan: $plan, trialEndAt: null}
  ) {
    plan
  }
}
    `;

export function useUpdateAccountPlanMutation() {
  return Urql.useMutation<UpdateAccountPlanMutation, UpdateAccountPlanMutationVariables>(UpdateAccountPlanDocument);
};
export const GettingStartedGetAgentsDocument = gql`
    subscription gettingStartedGetAgents {
  agents: kubernetes_agent_info(limit: 1) {
    id
  }
}
    `;

export function useGettingStartedGetAgentsSubscription<TData = GettingStartedGetAgentsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedGetAgentsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedGetAgentsSubscription, TData>) {
  return Urql.useSubscription<GettingStartedGetAgentsSubscription, TData, GettingStartedGetAgentsSubscriptionVariables>({ query: GettingStartedGetAgentsDocument, ...options }, handler);
};
export const GettingStartedGetExternalLinksDocument = gql`
    subscription gettingStartedGetExternalLinks {
  external_link(limit: 1) {
    id
  }
}
    `;

export function useGettingStartedGetExternalLinksSubscription<TData = GettingStartedGetExternalLinksSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedGetExternalLinksSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedGetExternalLinksSubscription, TData>) {
  return Urql.useSubscription<GettingStartedGetExternalLinksSubscription, TData, GettingStartedGetExternalLinksSubscriptionVariables>({ query: GettingStartedGetExternalLinksDocument, ...options }, handler);
};
export const GettingStartedGetGitIntegrationDocument = gql`
    subscription gettingStartedGetGitIntegration {
  gitIntegration: installation(
    limit: 1
    where: {_or: [{integration: {_eq: "github"}}, {integration: {_eq: "gitlab"}}]}
  ) {
    id
  }
}
    `;

export function useGettingStartedGetGitIntegrationSubscription<TData = GettingStartedGetGitIntegrationSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedGetGitIntegrationSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedGetGitIntegrationSubscription, TData>) {
  return Urql.useSubscription<GettingStartedGetGitIntegrationSubscription, TData, GettingStartedGetGitIntegrationSubscriptionVariables>({ query: GettingStartedGetGitIntegrationDocument, ...options }, handler);
};
export const GettingStartedtGetMonitorSinkDocument = gql`
    subscription gettingStartedtGetMonitorSink {
  monitorSink: workflow_configuration(
    limit: 1
    where: {sinks: {_neq: {}}, _and: {isDeleted: {_eq: false}}}
  ) {
    id
  }
}
    `;

export function useGettingStartedtGetMonitorSinkSubscription<TData = GettingStartedtGetMonitorSinkSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedtGetMonitorSinkSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedtGetMonitorSinkSubscription, TData>) {
  return Urql.useSubscription<GettingStartedtGetMonitorSinkSubscription, TData, GettingStartedtGetMonitorSinkSubscriptionVariables>({ query: GettingStartedtGetMonitorSinkDocument, ...options }, handler);
};
export const GettingStartedGetSlackIntegrationDocument = gql`
    subscription gettingStartedGetSlackIntegration {
  slackIntegration: installation(limit: 1, where: {integration: {_eq: "slack"}}) {
    id
  }
}
    `;

export function useGettingStartedGetSlackIntegrationSubscription<TData = GettingStartedGetSlackIntegrationSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedGetSlackIntegrationSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedGetSlackIntegrationSubscription, TData>) {
  return Urql.useSubscription<GettingStartedGetSlackIntegrationSubscription, TData, GettingStartedGetSlackIntegrationSubscriptionVariables>({ query: GettingStartedGetSlackIntegrationDocument, ...options }, handler);
};
export const GettingStartedtGetUsersDocument = gql`
    subscription gettingStartedtGetUsers {
  users: user(limit: 2, where: {deletedAt: {_is_null: true}}) {
    id
  }
}
    `;

export function useGettingStartedtGetUsersSubscription<TData = GettingStartedtGetUsersSubscription>(options: Omit<Urql.UseSubscriptionArgs<GettingStartedtGetUsersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GettingStartedtGetUsersSubscription, TData>) {
  return Urql.useSubscription<GettingStartedtGetUsersSubscription, TData, GettingStartedtGetUsersSubscriptionVariables>({ query: GettingStartedtGetUsersDocument, ...options }, handler);
};
export const FetchAgentTaskResultDocument = gql`
    query fetchAgentTaskResult($taskId: uuid) {
  agent_task_result(where: {taskId: {_eq: $taskId}}) {
    result
  }
}
    `;

export function useFetchAgentTaskResultQuery(options: Omit<Urql.UseQueryArgs<FetchAgentTaskResultQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAgentTaskResultQuery>({ query: FetchAgentTaskResultDocument, ...options });
};
export const FetchKomodorServiceDocument = gql`
    query FetchKomodorService($serviceId: String! = "", $isDeleted: Boolean = false, $inactive: Boolean = false, $includeDeletedServices: Boolean = false, $includeInactiveServices: Boolean = false) {
  komodor_service(
    where: {isDeleted: {_in: [$isDeleted, $includeDeletedServices]}, inactive: {_in: [$inactive, $includeInactiveServices]}, id: {_eq: $serviceId}}
  ) {
    ...komodor_service
  }
}
    ${Komodor_ServiceFragmentDoc}`;

export function useFetchKomodorServiceQuery(options: Omit<Urql.UseQueryArgs<FetchKomodorServiceQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchKomodorServiceQuery>({ query: FetchKomodorServiceDocument, ...options });
};
export const LatestServiceDeployDocument = gql`
    query LatestServiceDeploy($serviceId: jsonb = "", $replicasOnly: String = "") {
  event_deploy(
    order_by: {eventTime: desc}
    limit: 1
    where: {services: {_contains: $serviceId}, _or: [{changeType: {_is_null: true}}, {changeType: {_nin: ["empty_deployment", $replicasOnly]}}], isEmptyDeployment: {_eq: false}}
  ) {
    ...deploy
  }
}
    ${DeployFragmentDoc}`;

export function useLatestServiceDeployQuery(options: Omit<Urql.UseQueryArgs<LatestServiceDeployQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<LatestServiceDeployQuery>({ query: LatestServiceDeployDocument, ...options });
};
export const OpenServiceAvailabilityIssueDocument = gql`
    query OpenServiceAvailabilityIssue($serviceId: jsonb = "") {
  workflow_results(
    where: {_and: [{closedAt: {_is_null: true}}, {type: {_eq: "availability"}}, {services: {_contains: $serviceId}}]}
    order_by: {eventTime: desc}
    limit: 1
  ) {
    ...issue
  }
}
    ${IssueFragmentDoc}`;

export function useOpenServiceAvailabilityIssueQuery(options: Omit<Urql.UseQueryArgs<OpenServiceAvailabilityIssueQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<OpenServiceAvailabilityIssueQuery>({ query: OpenServiceAvailabilityIssueDocument, ...options });
};
export const AddExternalLinkDocument = gql`
    mutation addExternalLink($displayName: String!, $link: String!, $k8sClusterName: String, $namespace: String, $serviceId: String!, $externalLinkContextValue: external_link_context_enum = service) {
  insert_external_link_one(
    object: {displayName: $displayName, link: $link, k8sClusterName: $k8sClusterName, namespace: $namespace, serviceId: $serviceId, externalLinkContextValue: $externalLinkContextValue}
  ) {
    id
  }
}
    `;

export function useAddExternalLinkMutation() {
  return Urql.useMutation<AddExternalLinkMutation, AddExternalLinkMutationVariables>(AddExternalLinkDocument);
};
export const DeleteExternalLinkDocument = gql`
    mutation deleteExternalLink($id: uuid!) {
  delete_external_link_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteExternalLinkMutation() {
  return Urql.useMutation<DeleteExternalLinkMutation, DeleteExternalLinkMutationVariables>(DeleteExternalLinkDocument);
};
export const FetchExternalLinksDocument = gql`
    query fetchExternalLinks($k8sClusterName: String, $namespace: String, $serviceId: String) {
  external_link(
    where: {_or: [{k8sClusterName: {_eq: $k8sClusterName}}, {namespace: {_eq: $namespace}}, {serviceId: {_eq: $serviceId}}, {entireAccount: {_eq: true}}]}
  ) {
    id
    externalLinkContextValue
    displayName
    link
    serviceId
    k8sClusterName
    namespace
  }
}
    `;

export function useFetchExternalLinksQuery(options: Omit<Urql.UseQueryArgs<FetchExternalLinksQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchExternalLinksQuery>({ query: FetchExternalLinksDocument, ...options });
};
export const UpdateExternalLinksDocument = gql`
    mutation updateExternalLinks($id: uuid!, $displayName: String!, $link: String!) {
  update_external_link_by_pk(
    pk_columns: {id: $id}
    _set: {displayName: $displayName, link: $link}
  ) {
    id
  }
}
    `;

export function useUpdateExternalLinksMutation() {
  return Urql.useMutation<UpdateExternalLinksMutation, UpdateExternalLinksMutationVariables>(UpdateExternalLinksDocument);
};
export const FetchPodLogsDocument = gql`
    query fetchPodLogs($taskId: uuid) {
  pod_logs(where: {taskId: {_eq: $taskId}}, order_by: {logTime: desc}) {
    logTime
    message
  }
}
    `;

export function useFetchPodLogsQuery(options: Omit<Urql.UseQueryArgs<FetchPodLogsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchPodLogsQuery>({ query: FetchPodLogsDocument, ...options });
};
export const AddUserDocument = gql`
    mutation addUser($displayName: String!, $email: String!, $role: String!, $rbacRoleIds: [String]!) {
  createUser(
    params: {displayName: $displayName, inviteeMail: $email, role: $role, rbacRoleIds: $rbacRoleIds}
  ) {
    success
  }
}
    `;

export function useAddUserMutation() {
  return Urql.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument);
};
export const FetchStaticPreventionResultForServiceDocument = gql`
    query fetchStaticPreventionResultForService($serviceId: String) {
  komodor_service_static_prevention_state(where: {id: {_eq: $serviceId}}) {
    results
  }
}
    `;

export function useFetchStaticPreventionResultForServiceQuery(options: Omit<Urql.UseQueryArgs<FetchStaticPreventionResultForServiceQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchStaticPreventionResultForServiceQuery>({ query: FetchStaticPreventionResultForServiceDocument, ...options });
};
export const GetDeployEventsDocument = gql`
    query getDeployEvents($from: timestamptz, $to: timestamptz, $serviceIds: [String!]) {
  event_deploy(
    where: {services: {_has_keys_any: $serviceIds}, eventTime: {_gt: $from, _lte: $to}, isEmptyDeployment: {_eq: false}, kubernetesKind: {_nin: ["Job", "CronJob"]}, _or: [{changeType: {_is_null: true}}, {changeType: {_nin: ["empty_deployment", "replicas_only"]}}]}
  ) {
    ...deploy
  }
}
    ${DeployFragmentDoc}`;

export function useGetDeployEventsQuery(options: Omit<Urql.UseQueryArgs<GetDeployEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetDeployEventsQuery>({ query: GetDeployEventsDocument, ...options });
};
export const DeleteAppViewByIdDocument = gql`
    mutation DeleteAppViewById($id: uuid!) {
  delete_app_view_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteAppViewByIdMutation() {
  return Urql.useMutation<DeleteAppViewByIdMutation, DeleteAppViewByIdMutationVariables>(DeleteAppViewByIdDocument);
};
export const FetchAppViewByIdDocument = gql`
    query fetchAppViewById($uid: uuid) {
  app_view(where: {id: {_eq: $uid}}) {
    updatedAt
    serviceIdentifiers
    name
    id
    description
    createdAt
    authorId
    accountId
    serviceIdentifierType
  }
}
    `;

export function useFetchAppViewByIdQuery(options: Omit<Urql.UseQueryArgs<FetchAppViewByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAppViewByIdQuery>({ query: FetchAppViewByIdDocument, ...options });
};
export const FetchDeployEventDocument = gql`
    query fetchDeployEvent($eventTimeRangeStart: timestamptz = "", $clusterName: String, $namespace: String, $deploymentName: String) {
  event_deploy(
    where: {clusterName: {_eq: $clusterName}, namespace: {_eq: $namespace}, deploymentName: {_eq: $deploymentName}, eventTime: {_gte: $eventTimeRangeStart}}
    order_by: {eventTime: asc}
    limit: 1
  ) {
    versionFrom
    versionTo
    services
    newSpec
    oldSpec
  }
}
    `;

export function useFetchDeployEventQuery(options: Omit<Urql.UseQueryArgs<FetchDeployEventQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchDeployEventQuery>({ query: FetchDeployEventDocument, ...options });
};
export const ImpactfulJobEventsDocument = gql`
    query ImpactfulJobEvents($from: timestamptz, $to: timestamptz, $services: [String!]) {
  event_job(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...job
  }
}
    ${JobFragmentDoc}`;

export function useImpactfulJobEventsQuery(options: Omit<Urql.UseQueryArgs<ImpactfulJobEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImpactfulJobEventsQuery>({ query: ImpactfulJobEventsDocument, ...options });
};
export const ImpactfulWorkflowIssuesByTypeDocument = gql`
    query ImpactfulWorkflowIssuesByType($from: timestamptz, $to: timestamptz, $services: [String!], $type: String) {
  workflow_results(
    where: {eventTime: {_gte: $from, _lte: $to}, type: {_eq: $type}, services: {_has_keys_any: $services}}
  ) {
    ...issue
  }
}
    ${IssueFragmentDoc}`;

export function useImpactfulWorkflowIssuesByTypeQuery(options: Omit<Urql.UseQueryArgs<ImpactfulWorkflowIssuesByTypeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImpactfulWorkflowIssuesByTypeQuery>({ query: ImpactfulWorkflowIssuesByTypeDocument, ...options });
};
export const ImpactfulWorkflowIssuesByTypesDocument = gql`
    query ImpactfulWorkflowIssuesByTypes($from: timestamptz, $to: timestamptz, $services: [String!], $types: [String!]) {
  workflow_results(
    where: {eventTime: {_gte: $from, _lte: $to}, type: {_in: $types}, services: {_has_keys_any: $services}}
  ) {
    ...issue
  }
}
    ${IssueFragmentDoc}`;

export function useImpactfulWorkflowIssuesByTypesQuery(options: Omit<Urql.UseQueryArgs<ImpactfulWorkflowIssuesByTypesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImpactfulWorkflowIssuesByTypesQuery>({ query: ImpactfulWorkflowIssuesByTypesDocument, ...options });
};
export const OpenImpactfulWorkflowIssuesByTypesDocument = gql`
    query OpenImpactfulWorkflowIssuesByTypes($services: [String!], $type: String) {
  workflow_results(
    where: {closedAt: {_is_null: true}, type: {_eq: $type}, services: {_has_keys_any: $services}}
  ) {
    ...issue
  }
}
    ${IssueFragmentDoc}`;

export function useOpenImpactfulWorkflowIssuesByTypesQuery(options: Omit<Urql.UseQueryArgs<OpenImpactfulWorkflowIssuesByTypesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<OpenImpactfulWorkflowIssuesByTypesQuery>({ query: OpenImpactfulWorkflowIssuesByTypesDocument, ...options });
};
export const FetchWorkflowMinifiedRunByIdDocument = gql`
    query fetchWorkflowMinifiedRunById($runId: uuid = "") {
  workflow_results(where: {id: {_eq: $runId}}) {
    ...issue
  }
}
    ${IssueFragmentDoc}`;

export function useFetchWorkflowMinifiedRunByIdQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowMinifiedRunByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowMinifiedRunByIdQuery>({ query: FetchWorkflowMinifiedRunByIdDocument, ...options });
};
export const ImpactfulEventsDocument = gql`
    query ImpactfulEvents($from: timestamptz, $to: timestamptz, $services: [String!]) {
  event_komodor_alert(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...komodor_alert
  }
  event_sentry_issue(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...sentry_issue
  }
  event_pagerduty(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...pagerduty
  }
  event_deploy(
    where: {services: {_has_keys_any: $services}, _and: {_or: [{eventTime: {_gt: $from, _lte: $to}}, {endEventTime: {_gt: $from, _lte: $to}}]}}
  ) {
    ...deploy
  }
  event_topology(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...topology
  }
  event_secret(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...secret
  }
  event_launchdarkly(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gt: $from, _lte: $to}}}
  ) {
    ...launchdarkly
  }
  event_actions(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...action
  }
  event_limit_ranges(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...limit_ranges
  }
  event_network_policies(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...network_policies
  }
  event_kubernetes_service_resource(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...kubernetes_service_resource
  }
  event_ingress(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...ingress
  }
  event_resource_quota(
    where: {services: {_has_keys_any: $services}, _and: {eventTime: {_gte: $from, _lte: $to}}}
  ) {
    ...resource_quota
  }
}
    ${Komodor_AlertFragmentDoc}
${Sentry_IssueFragmentDoc}
${PagerdutyFragmentDoc}
${DeployFragmentDoc}
${TopologyFragmentDoc}
${SecretFragmentDoc}
${LaunchdarklyFragmentDoc}
${ActionFragmentDoc}
${Limit_RangesFragmentDoc}
${Network_PoliciesFragmentDoc}
${Kubernetes_Service_ResourceFragmentDoc}
${IngressFragmentDoc}
${Resource_QuotaFragmentDoc}`;

export function useImpactfulEventsQuery(options: Omit<Urql.UseQueryArgs<ImpactfulEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImpactfulEventsQuery>({ query: ImpactfulEventsDocument, ...options });
};
export const InvestigateEventsDocument = gql`
    query InvestigateEvents($from: timestamptz, $to: timestamptz) {
  event_komodor_alert(where: {eventTime: {_gt: $from, _lte: $to}}) {
    ...komodor_alert
  }
  event_sentry_issue(where: {eventTime: {_gt: $from, _lte: $to}}) {
    ...sentry_issue
  }
  event_pagerduty(where: {eventTime: {_gt: $from, _lte: $to}}) {
    ...pagerduty
  }
  event_deploy(
    where: {eventTime: {_gt: $from, _lte: $to}, isEmptyDeployment: {_eq: false}, kubernetesKind: {_nin: ["Job", "CronJob"]}, _or: [{changeType: {_is_null: true}}, {changeType: {_nin: ["empty_deployment", "replicas_only"]}}]}
  ) {
    ...deploy
  }
  event_topology(
    where: {eventTime: {_gt: $from, _lte: $to}, kind: {_nin: ["new_job", "new_cronjob"]}}
  ) {
    ...topology
  }
  event_secret(where: {eventTime: {_gt: $from, _lte: $to}}) {
    ...secret
  }
  event_launchdarkly(where: {eventTime: {_gt: $from, _lte: $to}}) {
    ...launchdarkly
  }
  event_actions(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...action
  }
  event_limit_ranges(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...limit_ranges
  }
  event_network_policies(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...network_policies
  }
  event_kubernetes_service_resource(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...kubernetes_service_resource
  }
  event_ingress(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...ingress
  }
  event_resource_quota(where: {eventTime: {_gte: $from, _lte: $to}}) {
    ...resource_quota
  }
}
    ${Komodor_AlertFragmentDoc}
${Sentry_IssueFragmentDoc}
${PagerdutyFragmentDoc}
${DeployFragmentDoc}
${TopologyFragmentDoc}
${SecretFragmentDoc}
${LaunchdarklyFragmentDoc}
${ActionFragmentDoc}
${Limit_RangesFragmentDoc}
${Network_PoliciesFragmentDoc}
${Kubernetes_Service_ResourceFragmentDoc}
${IngressFragmentDoc}
${Resource_QuotaFragmentDoc}`;

export function useInvestigateEventsQuery(options: Omit<Urql.UseQueryArgs<InvestigateEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<InvestigateEventsQuery>({ query: InvestigateEventsDocument, ...options });
};
export const FetchNodeStatusDocument = gql`
    query fetchNodeStatus($taskId: uuid) {
  node_status_agent_task(where: {taskId: {_eq: $taskId}}) {
    status
  }
}
    `;

export function useFetchNodeStatusQuery(options: Omit<Urql.UseQueryArgs<FetchNodeStatusQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchNodeStatusQuery>({ query: FetchNodeStatusDocument, ...options });
};
export const CountNodeWorkflowConfigurationDocument = gql`
    query countNodeWorkflowConfiguration($clusterName: String) {
  workflow_configuration_aggregate(
    limit: 1
    where: {sensors: {_contains: [{cluster: $clusterName}]}, type: {_eq: "node"}, active: {_eq: true}, isDeleted: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountNodeWorkflowConfigurationQuery(options: Omit<Urql.UseQueryArgs<CountNodeWorkflowConfigurationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CountNodeWorkflowConfigurationQuery>({ query: CountNodeWorkflowConfigurationDocument, ...options });
};
export const CountNodeWorkflowResultsDocument = gql`
    query countNodeWorkflowResults($nodeName: String, $clusterName: String) {
  workflow_results_aggregate(
    where: {cluster: {_eq: $clusterName}, shortResourceName: {_eq: $nodeName}, type: {_eq: "node"}}
    distinct_on: closedAt
  ) {
    nodes {
      closedAt
      id
    }
  }
}
    `;

export function useCountNodeWorkflowResultsQuery(options: Omit<Urql.UseQueryArgs<CountNodeWorkflowResultsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CountNodeWorkflowResultsQuery>({ query: CountNodeWorkflowResultsDocument, ...options });
};
export const IssuesClosedAtByIdsDocument = gql`
    query IssuesClosedAtByIds($eventIds: [uuid!]) {
  workflow_results(where: {id: {_in: $eventIds}}) {
    id
    closedAt
  }
}
    `;

export function useIssuesClosedAtByIdsQuery(options: Omit<Urql.UseQueryArgs<IssuesClosedAtByIdsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<IssuesClosedAtByIdsQuery>({ query: IssuesClosedAtByIdsDocument, ...options });
};
export const DeployInsightsStatusesDocument = gql`
    query DeployInsightsStatuses($eventIds: [String!]) {
  event_deploy(where: {id: {_in: $eventIds}}) {
    id
    status
  }
}
    `;

export function useDeployInsightsStatusesQuery(options: Omit<Urql.UseQueryArgs<DeployInsightsStatusesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DeployInsightsStatusesQuery>({ query: DeployInsightsStatusesDocument, ...options });
};
export const DeployInsightByIdDocument = gql`
    query DeployInsightById($eventId: String! = "") {
  event_deploy(where: {id: {_eq: $eventId}}) {
    ...deploy
  }
}
    ${DeployFragmentDoc}`;

export function useDeployInsightByIdQuery(options: Omit<Urql.UseQueryArgs<DeployInsightByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DeployInsightByIdQuery>({ query: DeployInsightByIdDocument, ...options });
};
export const ManualActionInsightByIdDocument = gql`
    query ManualActionInsightById($eventId: uuid = "") {
  event_actions(where: {id: {_eq: $eventId}}) {
    ...action
  }
}
    ${ActionFragmentDoc}`;

export function useManualActionInsightByIdQuery(options: Omit<Urql.UseQueryArgs<ManualActionInsightByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ManualActionInsightByIdQuery>({ query: ManualActionInsightByIdDocument, ...options });
};
export const InsertFilterDocument = gql`
    mutation insertFilter($filterData: jsonb, $filterName: String, $filterType: String) {
  insert_komodor_filter_settings_one(
    object: {filterName: $filterName, filterSettingType: $filterType, filterData: $filterData}
  ) {
    id
  }
}
    `;

export function useInsertFilterMutation() {
  return Urql.useMutation<InsertFilterMutation, InsertFilterMutationVariables>(InsertFilterDocument);
};
export const DeleteFilterDocument = gql`
    mutation deleteFilter($filterId: uuid) {
  delete_komodor_filter_settings(where: {id: {_eq: $filterId}}) {
    affected_rows
  }
}
    `;

export function useDeleteFilterMutation() {
  return Urql.useMutation<DeleteFilterMutation, DeleteFilterMutationVariables>(DeleteFilterDocument);
};
export const SavedFiltersDocument = gql`
    query savedFilters {
  komodor_filter_settings {
    id
    filterName
    filterData
    filterSettingType
  }
}
    `;

export function useSavedFiltersQuery(options: Omit<Urql.UseQueryArgs<SavedFiltersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SavedFiltersQuery>({ query: SavedFiltersDocument, ...options });
};
export const CreateGithubInstallationDocument = gql`
    mutation CreateGithubInstallation($installationId: Int!) {
  createGithubInstallation(installationId: $installationId) {
    success
  }
}
    `;

export function useCreateGithubInstallationMutation() {
  return Urql.useMutation<CreateGithubInstallationMutation, CreateGithubInstallationMutationVariables>(CreateGithubInstallationDocument);
};
export const InitAttributesConfigurationsDocument = gql`
    mutation initAttributesConfigurations {
  insert_service_attributes_for_account(
    objects: [{keyName: "cluster", displayName: "Clusters", mappings: ["cluster"], type: "general", keySource: "general", isServiceGroup: true}, {keyName: "namespace", displayName: "Namespaces", mappings: ["namespace"], type: "general", keySource: "general", isServiceGroup: true}]
    on_conflict: {constraint: UQ_13c00340a7d960d9bcfbb1a7743, update_columns: []}
  ) {
    returning {
      id
    }
  }
}
    `;

export function useInitAttributesConfigurationsMutation() {
  return Urql.useMutation<InitAttributesConfigurationsMutation, InitAttributesConfigurationsMutationVariables>(InitAttributesConfigurationsDocument);
};
export const FindInstalledAgentDocument = gql`
    subscription findInstalledAgent($apiKey: uuid!) {
  kubernetes_agent_info(where: {installationId: {_eq: $apiKey}}, limit: 1) {
    id
  }
}
    `;

export function useFindInstalledAgentSubscription<TData = FindInstalledAgentSubscription>(options: Omit<Urql.UseSubscriptionArgs<FindInstalledAgentSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<FindInstalledAgentSubscription, TData>) {
  return Urql.useSubscription<FindInstalledAgentSubscription, TData, FindInstalledAgentSubscriptionVariables>({ query: FindInstalledAgentDocument, ...options }, handler);
};
export const CreatePagerDutyInstallationDocument = gql`
    mutation createPagerDutyInstallation($refreshToken: String! = "", $accessToken: String! = "") {
  createPagerDutyInstallation(
    accessToken: $accessToken
    refreshToken: $refreshToken
  ) {
    success
    installationId
  }
}
    `;

export function useCreatePagerDutyInstallationMutation() {
  return Urql.useMutation<CreatePagerDutyInstallationMutation, CreatePagerDutyInstallationMutationVariables>(CreatePagerDutyInstallationDocument);
};
export const CreateSlackInstallationDocument = gql`
    mutation CreateSlackInstallation($code: String!, $redirect_uri: String!) {
  createSlackInstallation(code: $code, redirect_uri: $redirect_uri) {
    success
    channelId
  }
}
    `;

export function useCreateSlackInstallationMutation() {
  return Urql.useMutation<CreateSlackInstallationMutation, CreateSlackInstallationMutationVariables>(CreateSlackInstallationDocument);
};
export const InstallationsPerAccountDocument = gql`
    subscription installationsPerAccount {
  installation(where: {isDeleted: {_eq: false}}) {
    id
    integration
    createdAt
    configuration
    lookupId
    showInWeb
    deletedAt
    user {
      _id: email
      email
      displayName
    }
  }
}
    `;

export function useInstallationsPerAccountSubscription<TData = InstallationsPerAccountSubscription>(options: Omit<Urql.UseSubscriptionArgs<InstallationsPerAccountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<InstallationsPerAccountSubscription, TData>) {
  return Urql.useSubscription<InstallationsPerAccountSubscription, TData, InstallationsPerAccountSubscriptionVariables>({ query: InstallationsPerAccountDocument, ...options }, handler);
};
export const RemoveWorkflowConfigurationDocument = gql`
    mutation removeWorkflowConfiguration($id: uuid!) {
  update_workflow_configuration_by_pk(
    pk_columns: {id: $id}
    _set: {isDeleted: true}
  ) {
    id
  }
}
    `;

export function useRemoveWorkflowConfigurationMutation() {
  return Urql.useMutation<RemoveWorkflowConfigurationMutation, RemoveWorkflowConfigurationMutationVariables>(RemoveWorkflowConfigurationDocument);
};
export const UpdateActiveWorkflowConfigurationDocument = gql`
    mutation updateActiveWorkflowConfiguration($id: uuid!, $active: Boolean!) {
  update_workflow_configuration_by_pk(
    pk_columns: {id: $id}
    _set: {active: $active}
  ) {
    id
  }
}
    `;

export function useUpdateActiveWorkflowConfigurationMutation() {
  return Urql.useMutation<UpdateActiveWorkflowConfigurationMutation, UpdateActiveWorkflowConfigurationMutationVariables>(UpdateActiveWorkflowConfigurationDocument);
};
export const FetchWorkflowConfigurationsDocument = gql`
    query fetchWorkflowConfigurations {
  workflow_configuration(where: {isDeleted: {_eq: false}}) {
    active
    name
    sensors
    sinks
    type
    variables
    id
    sinksOptions
    createdAt
    updatedAt
  }
}
    `;

export function useFetchWorkflowConfigurationsQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowConfigurationsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowConfigurationsQuery>({ query: FetchWorkflowConfigurationsDocument, ...options });
};
export const FetchWorkflowConfigurationByIdDocument = gql`
    query fetchWorkflowConfigurationById($configId: uuid = "") {
  workflow_configuration(
    where: {id: {_eq: $configId}, _and: {isDeleted: {_eq: false}}}
  ) {
    active
    createdAt
    updatedAt
    id
    name
    sensors
    sinks
    sinksOptions
    variables
    type
  }
}
    `;

export function useFetchWorkflowConfigurationByIdQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowConfigurationByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowConfigurationByIdQuery>({ query: FetchWorkflowConfigurationByIdDocument, ...options });
};
export const FetchWorkflowConfigurationByNameDocument = gql`
    query fetchWorkflowConfigurationByName($name: String) {
  workflow_configuration(
    where: {name: {_eq: $name}, isDeleted: {_eq: false}, type: {_eq: "static_prevention"}}
  ) {
    active
    createdAt
    id
    name
    sensors
    sinks
    variables
    type
  }
}
    `;

export function useFetchWorkflowConfigurationByNameQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowConfigurationByNameQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowConfigurationByNameQuery>({ query: FetchWorkflowConfigurationByNameDocument, ...options });
};
export const FetchWorkflowsRunsDocument = gql`
    query fetchWorkflowsRuns($from: timestamptz, $to: timestamptz) {
  workflow_results(
    where: {eventTime: {_gte: $from, _lte: $to}, type: {_nin: ["OOMKilled", "static_prevention"]}}
    order_by: {eventTime: desc}
  ) {
    id
    sourceEventId
    type
    createdAt
    eventTime
    shortResourceName
    workflowConfiguration
    closedAt
    closeReason
    resultsSummary
    cluster
    namespace
    reasons
  }
}
    `;

export function useFetchWorkflowsRunsQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowsRunsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowsRunsQuery>({ query: FetchWorkflowsRunsDocument, ...options });
};
export const FetchWorkflowRunByIdDocument = gql`
    query fetchWorkflowRunById($runId: uuid = "") {
  workflow_results(where: {id: {_eq: $runId}}) {
    createdAt
    eventTime
    id
    results
    shortResourceName
    type
    workflowConfiguration
    closedAt
    closeReason
    services
    resultsSummary
    cluster
    namespace
    reasons
    triageData
  }
}
    `;

export function useFetchWorkflowRunByIdQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowRunByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowRunByIdQuery>({ query: FetchWorkflowRunByIdDocument, ...options });
};
export const FetchWorkflowRunBySensorIdDocument = gql`
    query fetchWorkflowRunBySensorId($sourceEventId: String! = "") {
  workflow_results(where: {sourceEventId: {_eq: $sourceEventId}}) {
    id
    sourceEventId
    type
    results
    createdAt
    eventTime
    shortResourceName
    closedAt
    closeReason
    resultsSummary
    cluster
    namespace
    workflowConfiguration
    reasons
    triageData
  }
}
    `;

export function useFetchWorkflowRunBySensorIdQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowRunBySensorIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowRunBySensorIdQuery>({ query: FetchWorkflowRunBySensorIdDocument, ...options });
};
export const FetchWorkflowRunBySensorIdsDocument = gql`
    query fetchWorkflowRunBySensorIds($sourceEventIds: [String!]) {
  workflow_results(where: {sourceEventId: {_in: $sourceEventIds}}) {
    id
    sourceEventId
    type
    results
    createdAt
    eventTime
    shortResourceName
    closedAt
    closeReason
    resultsSummary
    cluster
    namespace
    workflowConfiguration
    reasons
    triageData
  }
}
    `;

export function useFetchWorkflowRunBySensorIdsQuery(options: Omit<Urql.UseQueryArgs<FetchWorkflowRunBySensorIdsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchWorkflowRunBySensorIdsQuery>({ query: FetchWorkflowRunBySensorIdsDocument, ...options });
};
export const FetchAllSlackChannelsDocument = gql`
    mutation fetchAllSlackChannels($token: String!) {
  fetchAllSlackChannels(token: $token) {
    success
    channels
  }
}
    `;

export function useFetchAllSlackChannelsMutation() {
  return Urql.useMutation<FetchAllSlackChannelsMutation, FetchAllSlackChannelsMutationVariables>(FetchAllSlackChannelsDocument);
};
export const FetchOpsgenieRespondersDocument = gql`
    query fetchOpsgenieResponders {
  fetchOpsgenieResponders {
    success
    responders
  }
}
    `;

export function useFetchOpsgenieRespondersQuery(options: Omit<Urql.UseQueryArgs<FetchOpsgenieRespondersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchOpsgenieRespondersQuery>({ query: FetchOpsgenieRespondersDocument, ...options });
};
export const ValidateOpsgenieApiKeyDocument = gql`
    query validateOpsgenieApiKey($apiKey: String!) {
  validateOpsgenieApiKey(apiKey: $apiKey) {
    valid
  }
}
    `;

export function useValidateOpsgenieApiKeyQuery(options: Omit<Urql.UseQueryArgs<ValidateOpsgenieApiKeyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ValidateOpsgenieApiKeyQuery>({ query: ValidateOpsgenieApiKeyDocument, ...options });
};
export const CreateWorkflowConfigurationDocument = gql`
    mutation CreateWorkflowConfiguration($id: uuid!, $name: String!, $type: String!, $sensors: jsonb!, $variables: jsonb, $sinks: jsonb, $sinksOptions: jsonb) {
  insert_workflow_configuration_one(
    object: {id: $id, name: $name, type: $type, active: true, sensors: $sensors, variables: $variables, sinks: $sinks, sinksOptions: $sinksOptions}
    on_conflict: {constraint: PK_a2427d553f85de4c78dfc7a72a0, update_columns: [name, sensors, sinks, variables, active, sinksOptions]}
  ) {
    id
  }
}
    `;

export function useCreateWorkflowConfigurationMutation() {
  return Urql.useMutation<CreateWorkflowConfigurationMutation, CreateWorkflowConfigurationMutationVariables>(CreateWorkflowConfigurationDocument);
};
export const ChannelConfigurationDocument = gql`
    subscription ChannelConfiguration {
  channel_configuration {
    id
    type
    configuration
  }
}
    `;

export function useChannelConfigurationSubscription<TData = ChannelConfigurationSubscription>(options: Omit<Urql.UseSubscriptionArgs<ChannelConfigurationSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ChannelConfigurationSubscription, TData>) {
  return Urql.useSubscription<ChannelConfigurationSubscription, TData, ChannelConfigurationSubscriptionVariables>({ query: ChannelConfigurationDocument, ...options }, handler);
};
export const CreateChannelConfigurationDocument = gql`
    mutation CreateChannelConfiguration($configuration: jsonb = "", $type: String = "") {
  insert_channel_configuration(
    objects: {type: $type, configuration: $configuration}
  ) {
    returning {
      id
    }
  }
}
    `;

export function useCreateChannelConfigurationMutation() {
  return Urql.useMutation<CreateChannelConfigurationMutation, CreateChannelConfigurationMutationVariables>(CreateChannelConfigurationDocument);
};
export const FetchAllServicesDocument = gql`
    query fetchAllServices($fetchServicesFromMaterializedView: Boolean = false, $includeLabels: Boolean = false, $includeAnnotations: Boolean = false) {
  fetchAllServices(
    fetchServicesFromMaterializedView: $fetchServicesFromMaterializedView
    includeLabels: $includeLabels
    includeAnnotations: $includeAnnotations
  ) {
    services
  }
}
    `;

export function useFetchAllServicesQuery(options: Omit<Urql.UseQueryArgs<FetchAllServicesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAllServicesQuery>({ query: FetchAllServicesDocument, ...options });
};
export const FetchServicesByPageDocument = gql`
    mutation fetchServicesByPage($checkedFilters: jsonb = {}, $textFilter: String = "", $sortStrategy: String, $page: Int = 1) {
  fetchServicesByPage(
    checkedFilters: $checkedFilters
    textFilter: $textFilter
    sortStrategy: $sortStrategy
    page: $page
  ) {
    services
  }
}
    `;

export function useFetchServicesByPageMutation() {
  return Urql.useMutation<FetchServicesByPageMutation, FetchServicesByPageMutationVariables>(FetchServicesByPageDocument);
};
export const FetchServicesFiltersDocument = gql`
    mutation fetchServicesFilters($checkedFilters: jsonb = {}, $textFilter: String = "") {
  fetchServicesFilters(checkedFilters: $checkedFilters, textFilter: $textFilter) {
    filters
  }
}
    `;

export function useFetchServicesFiltersMutation() {
  return Urql.useMutation<FetchServicesFiltersMutation, FetchServicesFiltersMutationVariables>(FetchServicesFiltersDocument);
};
export const FetchAllJobsDocument = gql`
    query fetchAllJobs($fetchJobsFromMaterializedView: Boolean = false, $includeLabels: Boolean = false, $includeAnnotations: Boolean = false) {
  fetchAllJobs(
    fetchJobsFromMaterializedView: $fetchJobsFromMaterializedView
    includeLabels: $includeLabels
    includeAnnotations: $includeAnnotations
  ) {
    services
  }
}
    `;

export function useFetchAllJobsQuery(options: Omit<Urql.UseQueryArgs<FetchAllJobsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAllJobsQuery>({ query: FetchAllJobsDocument, ...options });
};
export const FetchJobsByPageDocument = gql`
    mutation fetchJobsByPage($checkedFilters: jsonb = {}, $textFilter: String = "", $sortStrategy: String, $page: Int = 1) {
  fetchJobsByPage(
    checkedFilters: $checkedFilters
    textFilter: $textFilter
    sortStrategy: $sortStrategy
    page: $page
  ) {
    services
  }
}
    `;

export function useFetchJobsByPageMutation() {
  return Urql.useMutation<FetchJobsByPageMutation, FetchJobsByPageMutationVariables>(FetchJobsByPageDocument);
};
export const FetchJobsFiltersDocument = gql`
    mutation fetchJobsFilters($filterType: String, $checkedFilters: jsonb = {}, $textFilter: String = "") {
  fetchJobsFilters(
    filterType: $filterType
    checkedFilters: $checkedFilters
    textFilter: $textFilter
  ) {
    filters
  }
}
    `;

export function useFetchJobsFiltersMutation() {
  return Urql.useMutation<FetchJobsFiltersMutation, FetchJobsFiltersMutationVariables>(FetchJobsFiltersDocument);
};
export const ListServiceAttributesForAccountDocument = gql`
    query listServiceAttributesForAccount {
  service_attributes_for_account(where: {isServiceGroup: {_eq: true}}) {
    displayName
    keyName
    mappings
  }
}
    `;

export function useListServiceAttributesForAccountQuery(options: Omit<Urql.UseQueryArgs<ListServiceAttributesForAccountQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ListServiceAttributesForAccountQuery>({ query: ListServiceAttributesForAccountDocument, ...options });
};
export const FetchAllKomodorServiceAttributesByKeysDocument = gql`
    query fetchAllKomodorServiceAttributesByKeys($keys: [String!] = [], $servicesIds: [String!] = []) {
  komodor_service_attributes(
    where: {key: {_in: $keys}, serviceId: {_in: $servicesIds}}
    order_by: {eventTime: desc}
  ) {
    key
    serviceId
    value
  }
}
    `;

export function useFetchAllKomodorServiceAttributesByKeysQuery(options: Omit<Urql.UseQueryArgs<FetchAllKomodorServiceAttributesByKeysQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAllKomodorServiceAttributesByKeysQuery>({ query: FetchAllKomodorServiceAttributesByKeysDocument, ...options });
};
export const CreateNewUserAccountDocument = gql`
    mutation createNewUserAccount($userDisplayName: String!, $userEmail: citext!, $accountName: String!, $identityProvider: String!, $plan: String!) {
  createNewUserAccount(
    params: {userEmail: $userEmail, displayName: $userDisplayName, accountName: $accountName, identityProvider: $identityProvider, plan: $plan}
  ) {
    success
    userId
    accountId
  }
}
    `;

export function useCreateNewUserAccountMutation() {
  return Urql.useMutation<CreateNewUserAccountMutation, CreateNewUserAccountMutationVariables>(CreateNewUserAccountDocument);
};
export const AccountByIdDocument = gql`
    query accountById($accountId: uuid!) {
  account_by_pk(id: $accountId) {
    id
    name
    identityProvider
    isRbacEnabled
    createdAt
    inPoc
  }
}
    `;

export function useAccountByIdQuery(options: Omit<Urql.UseQueryArgs<AccountByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AccountByIdQuery>({ query: AccountByIdDocument, ...options });
};
export const FetchLastAgentInfoDocument = gql`
    query fetchLastAgentInfo {
  kubernetes_agent_info(order_by: {createdAt: desc}, limit: 1) {
    clusterName
    configuration
    inactive
    lastSeenAlive
  }
}
    `;

export function useFetchLastAgentInfoQuery(options: Omit<Urql.UseQueryArgs<FetchLastAgentInfoQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchLastAgentInfoQuery>({ query: FetchLastAgentInfoDocument, ...options });
};
export const FetchAgentInfoByIdDocument = gql`
    query fetchAgentInfoById($agentId: uuid) {
  kubernetes_agent_info(where: {id: {_eq: $agentId}}) {
    clusterName
    configuration
    inactive
    lastSeenAlive
  }
}
    `;

export function useFetchAgentInfoByIdQuery(options: Omit<Urql.UseQueryArgs<FetchAgentInfoByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAgentInfoByIdQuery>({ query: FetchAgentInfoByIdDocument, ...options });
};
export const FetchAgentInfoByClusterDocument = gql`
    subscription fetchAgentInfoByCluster($agentId: uuid!) {
  kubernetes_agent_info(where: {id: {_eq: $agentId}}) {
    clusterName
    configuration
    inactive
    lastSeenAlive
  }
}
    `;

export function useFetchAgentInfoByClusterSubscription<TData = FetchAgentInfoByClusterSubscription>(options: Omit<Urql.UseSubscriptionArgs<FetchAgentInfoByClusterSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<FetchAgentInfoByClusterSubscription, TData>) {
  return Urql.useSubscription<FetchAgentInfoByClusterSubscription, TData, FetchAgentInfoByClusterSubscriptionVariables>({ query: FetchAgentInfoByClusterDocument, ...options }, handler);
};
export const FetchAllActiveAgentInfosDocument = gql`
    query fetchAllActiveAgentInfos {
  kubernetes_agent_info(where: {inactive: {_eq: false}}) {
    clusterName
    configuration
    inactive
    lastSeenAlive
  }
}
    `;

export function useFetchAllActiveAgentInfosQuery(options: Omit<Urql.UseQueryArgs<FetchAllActiveAgentInfosQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAllActiveAgentInfosQuery>({ query: FetchAllActiveAgentInfosDocument, ...options });
};
export const FetchAgentsInfoForDowntimeEventsDocument = gql`
    query fetchAgentsInfoForDowntimeEvents($startDate: timestamp, $endDate: timestamp) {
  kubernetes_agent_info(
    where: {lastSeenAlive: {_gte: $startDate, _lte: $endDate}}
    order_by: [{clusterName: desc}, {createdAt: desc}]
  ) {
    clusterName
    createdAt
    lastSeenAlive
  }
}
    `;

export function useFetchAgentsInfoForDowntimeEventsQuery(options: Omit<Urql.UseQueryArgs<FetchAgentsInfoForDowntimeEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FetchAgentsInfoForDowntimeEventsQuery>({ query: FetchAgentsInfoForDowntimeEventsDocument, ...options });
};
export const ExecuteAgentTaskDocument = gql`
    mutation executeAgentTask($agentId: String!, $type: String!, $metadata: jsonb) {
  executeAgentTask(params: {agentId: $agentId, type: $type, metadata: $metadata}) {
    success
    result
    failureMessage
    taskId
    timeToStartInMs
    timeToCompleteInMs
    timeToResultInMs
  }
}
    `;

export function useExecuteAgentTaskMutation() {
  return Urql.useMutation<ExecuteAgentTaskMutation, ExecuteAgentTaskMutationVariables>(ExecuteAgentTaskDocument);
};
export const GetAgentTaskStatusDocument = gql`
    subscription getAgentTaskStatus($taskId: uuid) {
  agent_task(where: {id: {_eq: $taskId}}) {
    state
    failureMessage
  }
}
    `;

export function useGetAgentTaskStatusSubscription<TData = GetAgentTaskStatusSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetAgentTaskStatusSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetAgentTaskStatusSubscription, TData>) {
  return Urql.useSubscription<GetAgentTaskStatusSubscription, TData, GetAgentTaskStatusSubscriptionVariables>({ query: GetAgentTaskStatusDocument, ...options }, handler);
};
export const InsertAgentTaskDocument = gql`
    mutation InsertAgentTask($agentId: String = "", $type: String = "", $data: jsonb = "", $deletionScheduledAt: timestamp = "now()", $metadata: jsonb = "") {
  insert_agent_task_one(
    object: {agentId: $agentId, data: $data, metadata: $metadata, state: "initiated", type: $type, deletionScheduledAt: $deletionScheduledAt}
  ) {
    id
  }
}
    `;

export function useInsertAgentTaskMutation() {
  return Urql.useMutation<InsertAgentTaskMutation, InsertAgentTaskMutationVariables>(InsertAgentTaskDocument);
};
export const SetAgentTaskStateDocument = gql`
    mutation setAgentTaskState($id: uuid!, $state: String!) {
  update_agent_task(_set: {state: $state}, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;

export function useSetAgentTaskStateMutation() {
  return Urql.useMutation<SetAgentTaskStateMutation, SetAgentTaskStateMutationVariables>(SetAgentTaskStateDocument);
};
export const GetAccountByIdDocument = gql`
    query GetAccountById($id: uuid!) {
  account_by_pk(id: $id) {
    isRbacEnabled
  }
}
    `;

export function useGetAccountByIdQuery(options: Omit<Urql.UseQueryArgs<GetAccountByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAccountByIdQuery>({ query: GetAccountByIdDocument, ...options });
};