import React from "react";
import styled from "styled-components";
import { Divider, OptionType } from "@komodorio/design-system/deprecated";

import { KubernetesResource } from "../../inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListResponse } from "../../InspectionViews/common";
import { InspectionFilters } from "../../filters/InspectionFilters";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
  padding-inline-start: 0;
  gap: 1rem;
  height: 6.5rem;

  & > *:first-child {
    padding-bottom: 0;
    height: 3rem;
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;

interface Props {
  kubernetesResource: KubernetesResource;
  resourceList: ResourceListResponse;
  selectedCluster?: string;
  onClusterChange: (clusterName: string) => void;
  namespaces?: string[];
  clustersOptions: OptionType<string>[];
}

const CRD_SEARCH_LABEL = "Search definition";

export const CRDsFilters: React.FC<Props> = (props) => {
  return (
    <Container>
      <InspectionFilters
        {...props}
        labels={{
          search: CRD_SEARCH_LABEL,
        }}
      />
      <StyledDivider spacing="1rem" />
    </Container>
  );
};
