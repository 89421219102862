import React from "react";
import { theme } from "@komodorio/design-system";
import { Tooltip, Typography } from "@komodorio/design-system/deprecated";

import { FlexRows } from "../../../../../FlexContainers";

export const PAST_24_HOURS_TOOLTIP = "past-24-hours-occurrence-tooltip";
export const PAST_WEEK_HOURS_TOOLTIP = "past-week-occurrence-tooltip";

export const OccurencesTooltip: React.FC<{
  percentage: number;
  text: string;
  tooltipId: string;
}> = ({ percentage, text, tooltipId }) => {
  return (
    <Tooltip id={tooltipId} variant="light" place="bottom">
      <Typography
        size="medium"
        bold
        color={
          percentage >= 0 ? theme.foreground.fgPink : theme.foreground.fgGreen
        }
      >
        <FlexRows>
          {Math.abs(percentage)}%
          <Typography size="medium" style={{ marginInlineStart: "0.4rem" }}>
            {percentage >= 0 ? " increase" : " decrease"} in occurrences of{" "}
          </Typography>
        </FlexRows>
      </Typography>

      <Typography size="medium">
        {`the same issue in the past ${text}`}
      </Typography>
      <Typography size="medium">{`compared to the previous ${text}`}</Typography>
    </Tooltip>
  );
};
