import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@komodorio/design-system/deprecated";

import { usePrivilegedOptions } from "../../../../../../../shared/hooks/roles/usePrivilegedOptions";
import {
  AddExternalLinkMutationVariables,
  useUpdateExternalLinksMutation,
} from "../../../../../../../generated/graphql";
import { blueBrand } from "../../../../../../../Colors";
import {
  FormContainer,
  SmallExternalLink,
  ButtonContainer,
} from "../LinkModal.styles";
import { NameInput, LinkInput } from "../inputs";
import { UpsertLinkErrorType, ModalErrorMessage, LinkModal } from "../utils";
import LinkWarnings from "../LinkWarnings";
import { ANNOTATIONS_DOCS_LINK } from "../../../../../../../shared/constants/docsLinks";

import editLinkSubmitHandler from "./editLinkSubmitHandler";

export type FormValues = {
  name: string;
  link: string;
};

const EditLinkForm: React.FC<{
  handleClose: () => void;
  linkId: string;
  linkInformation: AddExternalLinkMutationVariables;
}> = ({ handleClose, linkId, linkInformation }) => {
  const [errorMessage, setErrorMessage] = useState<UpsertLinkErrorType>(
    UpsertLinkErrorType.NoError
  );
  const {
    displayName,
    link: deafultLink,
    k8sClusterName,
    namespace,
  } = linkInformation;
  const { register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      name: displayName,
      link: deafultLink,
    },
  });

  const isValidLink = errorMessage?.length === 0;
  const name = watch("name", "");
  const link = watch("link", "");

  const updateLinkMutation = useUpdateExternalLinksMutation();
  const privilegedOptions = usePrivilegedOptions();

  return (
    <form>
      <FormContainer>
        <NameInput
          nameLength={name.length}
          {...register("name", { required: true })}
        />
        <LinkInput
          errorMessage={errorMessage}
          {...register("link", { required: true })}
          isValidInput={isValidLink}
          setErrorMessage={setErrorMessage}
        />
        <LinkWarnings clusterName={k8sClusterName} namespace={namespace} />
        <SmallExternalLink color={blueBrand} href={ANNOTATIONS_DOCS_LINK}>
          Learn how to create dynamic links
        </SmallExternalLink>
        <ModalErrorMessage
          errorMessage={errorMessage}
          expectedType={UpsertLinkErrorType.AddLinkError}
        />
      </FormContainer>
      <ButtonContainer>
        <Button
          variant="primary"
          onClick={handleSubmit(
            editLinkSubmitHandler(
              updateLinkMutation,
              linkId,
              handleClose,
              setErrorMessage,
              privilegedOptions
            )
          )}
          disabled={name.length === 0 || link.length === 0}
        >
          Save Link
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </ButtonContainer>
    </form>
  );
};

const EditLinkModal: React.FC<{
  show: boolean;
  close: () => void;
  linkId: string;
  linkInformation: AddExternalLinkMutationVariables;
}> = ({ show, close, linkId, linkInformation }) => (
  <LinkModal title="Edit Link" show={show} handleClose={close}>
    <EditLinkForm
      handleClose={close}
      linkId={linkId}
      linkInformation={linkInformation}
    />
  </LinkModal>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EditLinkModal;
