import { useEffect } from "react";

import { setPodLogsDirection } from "../store/podLogsStoreSelectors";
import { usePodLogsStore } from "../store/podLogsStore";
import { PodLogsDirection } from "../store/types";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useSyncLogsDirection = () => {
  const setPodLogsDirectionCB = usePodLogsStore(setPodLogsDirection);
  const { showLiveLogsEarliestToNewest } = useOverridableFlags();

  useEffect(() => {
    const direction = showLiveLogsEarliestToNewest
      ? PodLogsDirection.EarliestFirst
      : PodLogsDirection.NewestFirst;

    setPodLogsDirectionCB(direction);
  }, [setPodLogsDirectionCB, showLiveLogsEarliestToNewest]);
};
