import { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LogEvent } from "../LogsViewer";
import { PodLogLine } from "../../../../shared/hooks/podLogs/types";

export function useMapEventToLogs(
  logs: PodLogLine[],
  events: LogEvent[] | undefined
) {
  return useMemo(() => {
    const eventDictionary: Record<number, LogEvent> = {};
    if (logs.length === 0 || !events) {
      return eventDictionary;
    }

    const timestamps = logs.map((log) => Date.parse(log.logTime + "Z"));

    events?.forEach((event) => {
      let closestTimestampIndex = -1;
      for (let i = 0; i < timestamps.length; i++) {
        if (timestamps[i] < event.eventTime.getTime()) {
          closestTimestampIndex = i;
          break;
        }
      }
      if (closestTimestampIndex !== -1) {
        eventDictionary[closestTimestampIndex] = event;
      }
    });
    return eventDictionary;
  }, [events, logs]);
}
