import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Skeleton } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import appRedScoreIllustrationUrl from "./assets/mock-app-green-score.svg";
import appGreenScoreIllustrationUrl from "./assets/mock-app-red-score.svg";

const Container = styled.div`
  height: 204px;
  width: 100%;
  margin-bottom: 10px;
`;

const LOADING_TIME = 1000;

export const MockAppScore: React.FC = () => {
  const [toggle, setToggle] = useState(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIME);
  }, []);

  const doToggle = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setToggle((v) => !v);
      setIsLoading(false);
    }, LOADING_TIME);
  }, []);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton height="100%" width="100%" />;
    }

    return toggle ? (
      <img src={appRedScoreIllustrationUrl} onClick={doToggle} />
    ) : (
      <img src={appGreenScoreIllustrationUrl} onClick={doToggle} />
    );
  }, [loading, doToggle, toggle]);

  return <Container>{content}</Container>;
};
