import { theme } from "@komodorio/design-system";
import {
  Divider,
  IconButton,
  MultiSelect,
  OptionType,
  Typography,
} from "@komodorio/design-system/deprecated";
import {
  ChevronDown16,
  ChevronRight16,
  Trash16,
} from "@komodorio/design-system/icons";
import { Statement as StatementType } from "komodor-types";
import { remove } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ifProp } from "styled-tools";

import { Z_INDEX_ABOVE_MODAL } from "../../styles";

import { Field } from "./common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { defaultStatements } from "./PolicyFormModal";
import ResourcesFields from "./ResourcesStatementField";

import { RbacPolicyType } from "@/generated/auth";
import { useFormValidations } from "@/shared/context/ValidationsProvider";

const StatementContainer = styled.div`
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
`;

const StatementHeader = styled.div<{ open: boolean }>`
  ${ifProp("open", `background-color: ${theme.background.bgGray};`)}
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const StatementContent = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 1rem 2rem;
  align-items: center;
  padding: 1rem 1rem 1rem 2.5rem;
`;

const Span = styled.span`
  color: ${theme.foreground.fgSubtle};
  height: 1rem;
`;

export const Statement: React.FC<{
  index: number;
  statement: StatementType;
  onChange: (s: StatementType, i: number) => void;
  onDelete?: (i: number) => void;
  actionsOptions: OptionType<string>[];
  clustersOptions: OptionType<string>[];
  namespacesOptions: (cluster: string) => OptionType<string>[];
  readOnly?: boolean;
  policyType: RbacPolicyType;
}> = ({
  index,
  statement,
  onChange,
  onDelete,
  actionsOptions,
  clustersOptions,
  namespacesOptions,
  readOnly,
  policyType,
}) => {
  const [open, setOpen] = useState(true);
  const { errors, setError, deleteError } = useFormValidations();

  const isActionsEditDisabled = useMemo(() => {
    if (policyType === RbacPolicyType.DynamicTag) {
      if (!readOnly) {
        return false;
      }
    }

    return readOnly;
  }, [readOnly, policyType]);

  const isEditDisabled = useMemo(() => {
    if (policyType === RbacPolicyType.DynamicTag) {
      return true;
    }

    return readOnly;
  }, [readOnly, policyType]);

  const handleActionsChange = (actions: string[]) => {
    const All = actions.find((a) => a === "*");
    onChange({ ...statement, actions: All ? [All] : actions }, index);
    setError(
      `instance[${index}].actions`,
      actions.length < 1 ? "Please select actions" : undefined
    );
  };
  const handleClusterChange = (cluster: string, resourceIndex: number) => {
    onChange(
      {
        ...statement,
        resources: resources.map((r, i) => {
          if (i === resourceIndex) {
            return { ...r, cluster };
          }
          return r;
        }),
      },
      index
    );
    deleteError(`instance[${index}].resources[${resourceIndex}].cluster`);
  };
  const handleNamespacesChange = (
    namespaces: string[],
    resourceIndex: number
  ) => {
    onChange(
      {
        ...statement,
        resources: resources.map((r, i) => {
          if (i === resourceIndex) {
            return { ...r, namespaces };
          }
          return r;
        }),
      },
      index
    );
  };
  const handleNamespacePatternChange = (
    namespacePattern: string,
    resourceIndex: number
  ) => {
    onChange(
      {
        ...statement,
        resources: resources.map((r, i) => {
          if (i === resourceIndex) {
            return { ...r, namespacePattern };
          }
          return r;
        }),
      },
      index
    );

    setError(
      `instance[${index}].resources[${resourceIndex}].namespacePattern`,
      /^[a-zA-Z0-9*-]*\*+[a-zA-Z0-9*-]*$/.test(namespacePattern)
        ? undefined
        : "Only alphanumeric characters, hyphens and wildcards(*) are allowed. At least 1 wildcard is required."
    );
  };
  const handleAddResource = () => {
    onChange(
      {
        ...statement,
        resources: [...statement.resources, defaultStatements[0].resources[0]],
      },
      index
    );
  };
  const handleDeleteResource = (resourceIndex: number) => {
    onChange(
      {
        ...statement,
        resources: remove(statement.resources, (_, i) => i !== resourceIndex),
      },
      index
    );
    deleteError((k) =>
      k.startsWith(`instance[${index}].resources[${resourceIndex}]`)
    );
  };

  const { actions, resources } = statement;
  const updatedActionsOptions = useMemo(() => {
    if (actions.some((a) => a === "*")) {
      return actionsOptions.map((o) => ({ ...o, disabled: o.value !== "*" }));
    }
    return actionsOptions;
  }, [actions, actionsOptions]);

  return (
    <StatementContainer>
      <StatementHeader open={open}>
        <Span>
          {open ? (
            <ChevronDown16 onClick={() => setOpen(false)} />
          ) : (
            <ChevronRight16 onClick={() => setOpen(true)} />
          )}
        </Span>
        <Typography variant="title" size="medium">
          Statement {index + 1}
        </Typography>
        <span />
        <span>
          {onDelete && (
            <IconButton
              icon={Trash16}
              noBorder
              onClick={() => onDelete(index)}
              disabled={isEditDisabled}
            />
          )}
        </span>
      </StatementHeader>
      {open && (
        <>
          <Divider />
          <StatementContent>
            <Typography>
              Actions <b>{actions.length}</b>
            </Typography>
            <Field>
              <MultiSelect
                width="32.5rem"
                listMaxHeight="14rem"
                listZIndex={Z_INDEX_ABOVE_MODAL}
                tagsColor={theme.background.bgBlueLight}
                enableDeleteTags
                enableBreakLines
                options={updatedActionsOptions}
                placeholder="Add actions"
                value={updatedActionsOptions.filter((o) =>
                  actions.includes(o.value)
                )}
                onChange={(v) =>
                  handleActionsChange(v.map(({ value }) => value))
                }
                errorMessage={errors[`instance[${index}].actions`]}
                disabled={isActionsEditDisabled}
              />
            </Field>
            <Typography>
              Resources <b>{resources.length}</b>
            </Typography>
            <ResourcesFields
              policyType={policyType}
              resources={resources}
              clustersOptions={clustersOptions}
              namespacesOptions={namespacesOptions}
              index={index}
              errors={errors}
              isEditDisabled={isEditDisabled}
              handleClusterChange={handleClusterChange}
              handleNamespacesChange={handleNamespacesChange}
              handleNamespacePatternChange={handleNamespacePatternChange}
              handleAddResource={handleAddResource}
              handleDeleteResource={handleDeleteResource}
            />
          </StatementContent>
        </>
      )}
    </StatementContainer>
  );
};
