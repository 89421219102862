import React, { useCallback, useMemo } from "react";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

type AccountsListItemsProps = {
  favoriteAccounts: MuiSelectionOption<string>[];
  options: MuiSelectionOption<string>[];
  onSelect: (value: MuiSelectionOption<string>) => void;
  searchTerm?: string;
};

export const AccountsListItems: React.FC<AccountsListItemsProps> = ({
  favoriteAccounts,
  options,
  onSelect,
}) => {
  const getMenuItem = useCallback(
    (option: MuiSelectionOption<string>) => (
      <MenuItem
        key={option.value}
        onClick={() => onSelect(option)}
        data-e2e-selector="account-switcher-option"
        data-account={option.label}
      >
        <ListItemText>
          <Typography variant={"body3"}>{option.label}</Typography>
        </ListItemText>
      </MenuItem>
    ),
    [onSelect]
  );

  return useMemo(
    () => (
      <>
        {favoriteAccounts.map(getMenuItem)}
        {!!favoriteAccounts.length && <Divider variant="middle" />}
        {options.map(getMenuItem)}
      </>
    ),
    [favoriteAccounts, getMenuItem, options]
  );
};
