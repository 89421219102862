import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { TypographyProps } from "@mui/material";
import styled from "styled-components";

interface ResourceAttributeProps {
  topContent?: string | React.ReactNode;
  bottomContent?: string | React.ReactNode;
  fontSize?: string;
  color?: string;
  variant?: TypographyProps["variant"];
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-self: flex-start;
`;

export const ResourceAttribute: React.FC<ResourceAttributeProps> = ({
  topContent,
  bottomContent,
  variant = "body1",
  fontSize = "11px",
  color = muiColors.gray[600],
  children,
}) => (
  <Container>
    {topContent && (
      <Typography variant={variant as TypographyProps["variant"]}>
        {topContent}
      </Typography>
    )}
    {bottomContent && (
      <Typography color={color} fontSize={fontSize} variant="overline2">
        {bottomContent}
      </Typography>
    )}
    {children}
  </Container>
);
