import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { WORKSPACE_ROUTE } from "./routes";

export const WorkspaceRedirect = () => {
  const location = useLocation();
  const pathname = location.pathname.replace("/app-view", "");

  return (
    <Navigate
      to={`${WORKSPACE_ROUTE}${pathname}${location.search}`}
      replace
      state={location}
    />
  );
};
