import React from "react";
import Tooltip from "@mui/material/Tooltip";

import { Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import { SameIssueInClusterInsightDataParsed } from "../../common/types";

import { SameIssueInClusterTooltip } from "./SameIssueInClusterTooltip";

import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

export const SameIssueInClusterInsight: React.FC<{
  sameIssuesInClusterInsights: SameIssueInClusterInsightDataParsed[];
  setCorrelatedEvent: (event: EventGroup | null) => void;
  startTime: Date;
  serviceId: string;
  clusterName?: string;
}> = ({
  sameIssuesInClusterInsights,
  setCorrelatedEvent,
  startTime,
  serviceId,
  clusterName,
}) => {
  const issuesLength = sameIssuesInClusterInsights.length;
  const tooltipContent = (
    <SameIssueInClusterTooltip
      issues={sameIssuesInClusterInsights}
      issueStartTime={startTime}
      setCorrelatedEvent={setCorrelatedEvent}
      serviceId={serviceId}
      clusterName={clusterName}
    />
  );
  return (
    <Tooltip componentsProps={lightMuiTooltipStyle} title={tooltipContent}>
      <InsightContainer>
        <Insight>
          The same issue appeared on {issuesLength} other{" "}
          <DashedText>
            service
            {issuesLength > 1 ? "s" : ""}
          </DashedText>{" "}
          in the cluster within 2 hours before the issue started
        </Insight>
      </InsightContainer>
    </Tooltip>
  );
};
