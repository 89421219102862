import { KomodorServiceType } from "../../components/servicesView/types";
import { Timeframe } from "../types/TimeWindow";
import { defaultLabels } from "../../components/common/DateTimeSelector";

export const KOMODOR_LOADING_TIME = "komodor_loading_time";

const toSnakeCase = (str: string) =>
  str
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");

const getTimeWindowLabel = (timeframe: Timeframe) =>
  toSnakeCase(defaultLabels[timeframe]);

export const dataDogViewNames = {
  getExplorePage: (type: KomodorServiceType, dataIsLoaded: boolean) =>
    `explore_${type}s_page_${!dataIsLoaded ? "initial" : "non_initial"}`,
  getEventDrawerByType: (type: string) => `event_drawer_${toSnakeCase(type)}`,
  getResourceViewDescribeTab: (kind: string) =>
    `resource_view_${kind}_describe_tab`,
  getResourceViewEventsTab: (timeframe: Timeframe) =>
    `resource_view_events_tab|${getTimeWindowLabel(timeframe)}`,
  getInspectionViewPage: (nameInK8S: string) => `inspection|${nameInK8S}`,
  getPodMetricsDrawer: (timeframe: Timeframe) =>
    `pod_metrics_drawer|${getTimeWindowLabel(timeframe)}`,
  getMetricsTabDrawerByResource: (resourceName: string, timeframe: Timeframe) =>
    `${resourceName}|metrics_drawer|${getTimeWindowLabel(timeframe)}`,
  argoWorkflowsViewMainTable: "argo_workflows_view_main_table",
  argoWorkflowsViewEventsTab: "argo_workflows_view_events_tab",
  workflowDagViewEventsTab: "workflow_dag_view_events_tab",
  eventDrawerDeploy: "event_drawer_deploy",
  eventDrawerJob: "event_drawer_job",
  eventDrawerPodGroup: "event_drawer_pod_phase",
  eventDrawerPodIssue: "event_drawer_standalone_pod_issue",
  eventsView: "events_view",
  investigationModeIntroduction: "investigation_mode_introduction_step",
  investigationModeCorrelatedDeploys:
    "investigation_mode_correlated_deploys_step",
  investigationModeDependencies:
    "investigation_mode_correlated_dependencies_step",
  investigationModeLimitDecreased: "investigation_mode_limit_decreased_step",
  investigationModeMemoryLeak: "investigation_mode_memory_leak_step",
  investigationModeNodeIssues: "investigation_mode_node_issues_step",
  investigationModeNoisyNeighbors: "investigation_mode_noisy_neighbors_step",
  investigationModeUnhealthyPods: "investigation_mode_unhealthy_pods_step",
  investigationModeLogs: "investigation_mode_logs_step",
  investigationModeReviewMemoryAllocation:
    "investigation_mode_review_memory_allocation_step",
  monitorsViewManageMonitors: "monitors_view_manage_monitors",
  paginatedExploreJobs: "paginated_explore_jobs_view",
  paginatedExploreServices: "paginated_explore_services_view",
  relatedResourcesModal: "related_resources_modal",
  resourceViewPodEventsTab: "resource_view_pod_events_tab",
  resourceViewYamlTab: "resource_view_YAML_tab",
  resourceViewPodDescribeTab: "resource_view_pod_describe_tab",
  resourceViewDependenciesTab: "resource_view_dependencies_tab",
  resourceViewNodesTab: "resource_view_nodes_tab",
  resourceViewInfoTab: "resource_view_info_tab",
  resourceViewLogsTab: "resource_view_logs_tab",
  resourceViewPodsTab: "resource_view_pods_tab",
  resourceViewHelmManifestsTab: "resource_view_helm_manifests_tab",
  resourceViewHelmTab: "resource_view_helm_tab",
  workspaceOverview: "workspace_overview",
  clustersListView: "clusters_list_view",
  clusterOverview: "cluster_overview",
};
