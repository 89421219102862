import styled from "styled-components";

import { blueBrand } from "../../../../Colors";
import { textBolderStyle } from "../../../common/typography";

const FilterLabel = styled.div`
  padding: 0.5rem;
  border-radius: 2px;
  background-color: #dde5fb;
  color: ${blueBrand};
  ${textBolderStyle}
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default FilterLabel;
