import React from "react";
import Typography from "@mui/material/Typography";

import { useViolation } from "../../../hooks/useViolation";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import { HowDescription, WhyDescription } from "./texts";
import { Details } from "./Details/Details";
import { NoisyNeighborMetadata } from "./NoisyNeighborMetadata";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";

export const NoisyNeighbor: React.FC = () => {
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  const { data } = useViolation();

  const { supportingData, headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const noisyNeighborSupportingData = supportingData?.noisyNeighbor;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              <NoisyNeighborMetadata
                supportingData={noisyNeighborSupportingData}
              />
              <Typography variant="h4" color="text.primary">
                Noisy containers
              </Typography>
              <Details
                supportingData={noisyNeighborSupportingData}
                komodorUid={data?.data.violation?.komodorUid}
              />
              {!!reliabilityBestPracticesTab && (
                <ViolationsTableByIds
                  title="Related best practice violations"
                  ids={data?.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              )}
            </>
          }
          whyDescription={<WhyDescription />}
          howDescription={<HowDescription />}
        />
      }
    />
  );
};
