import { useQuery } from "@tanstack/react-query";

import {
  ViolationsApiGetViolationsFilterRequest,
  ViolationsFilterGroupByGeneric,
  getViolationsFilterUrl,
} from "../../../../generated/reliabilityApi";
import { QueryOptions, useReliabilityApiClient } from "../apiClient";
import { useWorkspaceQueryKey } from "../../workspaces-api/useWorkspaceQueryKey";

export const GET_VIOLATIONS_FILTER_PATH = "/api/v1/violations/count/:countBy";

export const useGetViolationsFilter = (
  params: ViolationsApiGetViolationsFilterRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useReliabilityApiClient();

  const keys = useWorkspaceQueryKey([GET_VIOLATIONS_FILTER_PATH, params]);
  return useQuery(
    keys,
    async () =>
      await apiClient.post<ViolationsFilterGroupByGeneric>(
        getViolationsFilterUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.postViolationsCountRequestBody }
      ),
    { ...queryOptions }
  );
};
