import React from "react";
import { capitalize } from "lodash";
import { diff } from "json-diff-ts";

import { Audit } from "../../../generated/graphql";
import { AuditData } from "../types";
import { DefaultAuditDetails } from "../AuditDetailsCommon";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class UserAudit implements AuditObject {
  readonly data: Audit;
  readonly auditData: AuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as AuditData;
  }

  renderDetails() {
    return <DefaultAuditDetails data={this.auditData} />;
  }

  renderAffectedResources() {
    return (this.auditData.details.new?.email ||
      this.auditData.details.old?.email) as string;
  }

  renderAction() {
    return `${this.data.crudType.toLowerCase()} user`;
  }

  renderEventType() {
    return this.data.crudType.toLowerCase();
  }

  renderJson(): Record<string, unknown> {
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": capitalize(this.data.crudType.toLowerCase()),
      Resource: "User",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource":
        this.auditData.details.new?.email || this.auditData.details.old?.email,
      Change: JSON.stringify(
        diff(this.auditData.details.new, this.auditData.details.old)
          .map((o) => {
            if (o.value) {
              return {
                type: o.type,
                key: o.key,
                oldValue: o.oldValue,
                newValue: o.value,
              };
            }
            return null;
          })
          .filter((o) => o !== null)
      ),
    };
  }
}
