import { cloneDeepWith } from "lodash";

const isNamedEntry = (
  e: Record<string, unknown> | null | undefined
): e is { name: string } => !!e && typeof e.name === "string";

const getNormalizedK8s = (
  spec: Record<string, unknown>
): Record<string, unknown> =>
  cloneDeepWith(spec, (v) => {
    if (Array.isArray(v) && v.every(isNamedEntry)) {
      return getNormalizedK8s(
        Object.fromEntries(v.map(({ name, ...value }) => [name, value]))
      );
    }
    return;
  });

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default getNormalizedK8s;
