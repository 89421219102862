// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { restrictionProps } from ".";

export const hasReachedPoliciesLimit = (
  currentValue: number | undefined
): boolean => {
  if (!currentValue) return false;
  if (currentValue >= 1) {
    return true;
  }
  return false;
};

export const usePoliciesRestrictionsData = (): restrictionProps => {
  return {
    modalTitle: "Upgrade to get unlimited policies",
    modalContent: `You are now using a Free Plan. \n In order to get more policies, \nyou have to upgrade.`,
  };
};
