import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { GroupCard } from "../../GroupCard";
import { getCardTexts } from "../../ViolationsGroup/getCardTexts";
import { IssuesTable } from "../IssuesTable/IssuesTable";
import { IssuesTableContextProvider } from "../context/IssuesTableContext";
import { IssuesTabs } from "../IssuesTable/IssuesTabs/IssuesTabs";

import { ViolationsAggregationGroup } from "@/generated/reliabilityApi";
import { getTotalViolationsFromCountDict } from "@/components/reliability/utils/reliabilityUtils";

export const TOTAL_SUFFIX = "Issues";

const TotalIssuesContainer = styled.div`
  margin-left: auto;
`;

type Props = {
  group: ViolationsAggregationGroup;
};

export const IssuesGroup: React.FC<Props> = ({ group }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { id } = group;
  const { title } = getCardTexts(id, "Impact");

  const breakdownByCheckType = group.breakdowns?.["checkType"];

  const totalIssues = useMemo(() => {
    return getTotalViolationsFromCountDict(breakdownByCheckType ?? {});
  }, [breakdownByCheckType]);

  return (
    <GroupCard
      id={id}
      onExpandClick={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
      title={title}
      summaryElement={
        <TotalIssuesContainer>
          <Typography variant="body2">
            {totalIssues} {TOTAL_SUFFIX}
          </Typography>
        </TotalIssuesContainer>
      }
      cardContent={
        <IssuesTableContextProvider issuesBreakdown={breakdownByCheckType}>
          <IssuesTabs />
          <IssuesTable />
        </IssuesTableContextProvider>
      }
    />
  );
};
