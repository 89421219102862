import { useEffect, useMemo } from "react";

import {
  SupportedResourceKindEnum,
  usePortForward,
} from "../../../../../shared/context/PortForwardProvider";
import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../../resources/pod";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Service from "../../../resources/service";
import { getAvailablePortsFromResource } from "../../../../Inspection/utils/getAvailablePortsFromResource";

export const useSetPortForwardingData = (
  resource: Pod | Service,
  resourceType: SupportedResourceKindEnum
): void => {
  const { portForwardingUi } = useOverridableFlags();
  const {
    setAvailablePorts,
    setNamespace,
    setResourceKind,
    setResourceName,
    setClusterName,
  } = usePortForward();
  const availablePorts = useMemo(
    () => getAvailablePortsFromResource(resourceType, resource.fullObj),
    [resource.fullObj, resourceType]
  );

  useEffect(() => {
    if (!portForwardingUi) return;

    setNamespace(resource.namespace);
    setClusterName(resource.cluster);
    setResourceKind(resourceType);
    setResourceName(resource.name);
    setAvailablePorts(availablePorts);
  }, [
    availablePorts,
    portForwardingUi,
    resource.cluster,
    resource.fullObj,
    resource.name,
    resource.namespace,
    resourceType,
    setAvailablePorts,
    setClusterName,
    setNamespace,
    setResourceKind,
    setResourceName,
  ]);
};
