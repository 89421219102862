import React, { useMemo } from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertTriangleOutlined16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ResourceType } from "../../../../types/appViewsResourcesTypes";
import { useGetRouteForResourceType } from "../../../../hooks/overviewPageHooks";
import { useAppViewsResourcesStore } from "../../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";

import { ResourceTypeToName } from "./constants/resourceTypeToName";

type ResourceItemProps = {
  type: ResourceType;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const StyledLink = styled(Link)<{ $disabled?: boolean }>`
  &:any-link {
    display: flex;
    gap: 5px;
    align-items: center;
    color: ${palette.darkBlue["500"]};
    text-decoration: none;
    ${({ $disabled }) => $disabled && "pointer-events: none;"}
`;

export const ResourceItem: React.FC<ResourceItemProps> = ({ type }) => {
  const foundResources = useAppViewsResourcesStore(
    (state) => state.resources[type]
  );
  const getRouteForResourceType = useGetRouteForResourceType();
  const foundResourcesKeys = Object.keys(foundResources);
  const unhealthyCount = useMemo(
    () =>
      foundResourcesKeys.reduce<number>((acc, currKey) => {
        const currentResource = foundResources[currKey];
        const unhealthy = currentResource.isHealthy === false;
        return acc + (unhealthy ? 1 : 0);
      }, 0),
    [foundResources, foundResourcesKeys]
  );

  const total = foundResourcesKeys.length;
  if (total === 0) return null;

  const hasUnhealthyResources = unhealthyCount > 0;
  const resourceRoute = getRouteForResourceType(type);
  const unhealthyResourceRoute = hasUnhealthyResources
    ? getRouteForResourceType(type, true)
    : undefined;

  return (
    <Container>
      <Typography variant="text" size="small">
        <StyledLink
          to={resourceRoute ?? "/"}
          $disabled={!resourceRoute}
        >{`${total} ${ResourceTypeToName[type]}`}</StyledLink>
      </Typography>
      {hasUnhealthyResources && (
        <StyledLink
          to={unhealthyResourceRoute ?? "/"}
          $disabled={!unhealthyResourceRoute}
          data-testid="unhealthy-status"
        >
          <Typography>{unhealthyCount}</Typography>
          <AlertTriangleOutlined16 color={palette.pink["500"]} />
        </StyledLink>
      )}
    </Container>
  );
};
