import { useMemo } from "react";
import { isAfter } from "date-fns";
import { JobStatus } from "kubernetes-types/batch/v1";

import {
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  KomodorServicesResponse,
} from "../../generated/resourcesApi";
import { isLabeled, ServiceInfo, Tag } from "../../shared/types/ServiceInfo";
import { isServiceHealthy } from "../../shared/utils/serviceHelpers";
import { extractJobsStatusandLastRunTime } from "../../shared/utils/extractJobsStatusandLastRunTime";
import { convertToJobState } from "../../shared/types/job";
import { ServiceReplicas } from "../../shared/types/ServiceVertex";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";

import { buildLastModifyFromService } from "./serviceFetchHooks/buildLastModifyFromService";
import { ServiceFromHasuraActions, hasuraData } from "./types";

const sharedFields: KomodorServicesApiApiV1KomodorServicesSearchGetRequest["fields"] =
  [
    "id",
    "accountId",
    "agentId",
    "inactive",
    "isDeleted",
    "k8sClusterName",
    "namespace",
    "displayName",
    "kind",
    "updatedAt",
    "createdAt",
  ];

const getSharedFields = (
  includeLabels?: boolean,
  includeAnnotations?: boolean
) => {
  const fields: KomodorServicesApiApiV1KomodorServicesSearchGetRequest["fields"] =
    sharedFields;
  if (includeLabels) fields.push("k8s_metadata.labels");
  if (includeAnnotations) fields.push("k8s_metadata.annotations");
  return fields;
};

export const useGetJobsFields = (
  includeLabels?: boolean,
  includeAnnotations?: boolean
) => {
  const { fetchJobsStatusesAsJson } = useOverridableFlags();

  return useMemo(() => {
    const fields: KomodorServicesApiApiV1KomodorServicesSearchGetRequest["fields"] =
      [
        ...getSharedFields(includeLabels, includeAnnotations),
        "k8s_metadata",
        "k8s_metadata.jobState",
        ...(fetchJobsStatusesAsJson ? [] : (["k8s_metadata.status"] as const)),
        "k8s_metadata.schedule",
      ];
    return fields;
  }, [includeAnnotations, includeLabels, fetchJobsStatusesAsJson]);
};

export const useGetServicesFields = (
  includeLabels?: boolean,
  includeAnnotations?: boolean
) => {
  return useMemo(() => {
    const fields: KomodorServicesApiApiV1KomodorServicesSearchGetRequest["fields"] =
      [
        ...getSharedFields(includeLabels, includeAnnotations),
        "deploy_state",
        "deploy_state.lastDeployEndTime",
        "deploy_state.lastDeployStartTime",
        "deploy_state.readyReplicas",
        "deploy_state.desiredReplicas",
        "deploy_state.eventTime",
        "deploy_state.createdAt",
        "health_state",
        "health_state.healthStatus",
      ];
    if (includeLabels || includeAnnotations) fields.push("k8s_metadata");
    return fields;
  }, [includeAnnotations, includeLabels]);
};

export type ServicesWithLastUpdatedAt = {
  lastUpdatedAt: string;
  services: ServiceInfo[];
};

export const convertResourcesApiKomodorServiceResponseToServiceInfo = (
  komodorServicesData: KomodorServicesResponse | undefined
): ServicesWithLastUpdatedAt | undefined => {
  if (!komodorServicesData?.data) return;
  return komodorServicesData?.data?.reduce<ServicesWithLastUpdatedAt>(
    (acc, service) => {
      const serviceResponseAsServiceFromHasuraActionsType = {
        ...service,
        agentId: service?.agentId || null,
        kind: service?.kind || null,
        labels: service?.k8s_metadata?.labels,
        annotations: service?.k8s_metadata?.annotations,
        schedule: service?.k8s_metadata?.schedule,
        status: service?.k8s_metadata?.status,
        jobState: service?.k8s_metadata?.jobState,
        desiredReplicas: service?.deploy_state?.desiredReplicas,
        healthStatus: service?.health_state?.healthStatus,
        deployStateEventTime: service?.deploy_state?.eventTime,
        lastDeployEndTime: service?.deploy_state?.lastDeployEndTime,
        lastDeployStartTime: service?.deploy_state?.lastDeployStartTime,
        readyReplicas: service?.deploy_state?.readyReplicas,
        deployStateCreatedAt: service?.deploy_state?.createdAt,
      } as ServiceFromHasuraActions;

      const serviceAsServiceInfo = convertToServiceInfo(
        serviceResponseAsServiceFromHasuraActionsType
      );
      acc.services.push(serviceAsServiceInfo);

      if (
        service.updatedAt &&
        (!acc.lastUpdatedAt ||
          isAfter(new Date(service.updatedAt), new Date(acc.lastUpdatedAt)))
      ) {
        acc.lastUpdatedAt = service.updatedAt;
      }

      return acc;
    },
    {
      services: [],
      lastUpdatedAt: "",
    }
  );
};

export const processDataFromHasuraAction = (
  data: hasuraData
): ServiceInfo[] | undefined => {
  if (!data || !data.services) return undefined;
  return processServices(data.services as ServiceFromHasuraActions[]);
};

const processServices = (data: ServiceFromHasuraActions[]) => {
  return data.map(convertToServiceInfo);
};

const convertToServiceInfo = (
  service: ServiceFromHasuraActions & { statusJson?: JobStatus }
): ServiceInfo => {
  const lastModified = buildLastModifyFromService(service);
  const tags: Tag[] = [];
  if (service.schedule) {
    tags.push({ label: "schedule", value: service.schedule });
  }
  if (service.k8sClusterName) {
    tags.push({ label: "cluster", value: service.k8sClusterName });
  }
  if (service.namespace) {
    tags.push({ label: "namespace", value: service.namespace });
  }

  const replicas = buildReplicasFromService(service);
  const healthy = isServiceHealthy(service.healthStatus);

  const { jobLastRunTime, jobState } = extractJobsStatusandLastRunTime(
    service.status ?? "",
    service.statusJson
  );

  const currentlyDeploying =
    service.lastDeployStartTime && service.lastDeployEndTime
      ? service.lastDeployStartTime > service.lastDeployEndTime
      : false;

  return {
    id: service.id,
    title: service.displayName,
    healthy,
    currentlyDeploying,
    replicas,
    lastModified,
    tags,
    env: service.namespace,
    k8sCluster: service.k8sClusterName,
    kind: service.kind,
    agentId: service?.agentId ?? undefined,
    isDeleted: service.isDeleted ?? false,
    jobState: service.jobState ? convertToJobState(service.jobState) : jobState,
    jobLastRunTime,
    labels: isLabeled(service) ? service.labels : undefined,
  };
};

function buildReplicasFromService(
  service: ServiceFromHasuraActions
): ServiceReplicas | undefined {
  if (
    service.readyReplicas !== undefined &&
    service.desiredReplicas !== undefined &&
    service.readyReplicas !== null &&
    service.desiredReplicas !== null
  ) {
    return {
      ready: service.readyReplicas,
      desired: service.desiredReplicas,
    };
  }
  return undefined;
}
