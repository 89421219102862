import { useMemo } from "react";
import { AxiosResponse } from "axios";
import { keyBy, merge } from "lodash";

import { ReplicasOverTimeGraphDataKeys } from "../ReplicasOverTimeGraph/GraphParts";

import {
  CustomGraphDataPoint,
  MetricsGraphProps,
} from "@/components/Metrics/types";
import { transformDataPoints } from "@/components/Metrics/utils";
import {
  Datapoint,
  WorkloadReplicasStatusResponse,
} from "@/generated/metricsApi";

const mergeReplicaDataPoints = (
  readyReplicas: CustomGraphDataPoint[],
  desiredReplicas: CustomGraphDataPoint[]
) => {
  const merged = merge(
    keyBy(readyReplicas, "time"),
    keyBy(desiredReplicas, "time")
  );

  return Object.values(merged).sort((a, b) => a.time - b.time);
};

const dataPointsToGraphData = (dataPoint: Datapoint, _idx: number) => ({
  x: dataPoint.timestampMs,
  y: dataPoint.value,
});

export const useGetGraphData = (
  data: AxiosResponse<WorkloadReplicasStatusResponse, unknown> | undefined
) => {
  return useMemo((): MetricsGraphProps["data"] => {
    const readyReplicas = transformDataPoints(
      (data?.data?.ready ?? []).map(dataPointsToGraphData),
      ReplicasOverTimeGraphDataKeys.ReadyReplicas
    );

    const desiredReplicas = transformDataPoints(
      (data?.data?.desired ?? []).map(dataPointsToGraphData),
      ReplicasOverTimeGraphDataKeys.DesiredReplicas
    );

    return mergeReplicaDataPoints(readyReplicas, desiredReplicas);
  }, [data]);
};
