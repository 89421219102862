import { format } from "date-fns";

const TIME_FORMAT = "LLL dd, HH:mm a";

export const formatFullDate = (value: number): string => {
  return format(new Date(value), TIME_FORMAT);
};
export const formatHour = (value: number): string => {
  return format(new Date(value), "HH:mm");
};
