import React, { createContext, useContext, useEffect, useState } from "react";

import { AgentsRunningStatus } from "../electronApiContext";

const useDesktopAgentStatus = (): AgentsRunningStatus | null => {
  const [agentsStatusesState, setAgentsStatusesState] =
    useState<AgentsRunningStatus | null>(null);

  useEffect(() => {
    if (!window.electronAPI?.getAgentsStatusEncoded) return;
    window.electronAPI.getAgentsStatusEncoded().then((agentStatusesEncoded) => {
      setAgentsStatusesState(JSON.parse(agentStatusesEncoded));
    });
  }, []);

  useEffect(() => {
    if (!window.electronAPI?.updateDesktopAgentsStatus) return;
    window.electronAPI.updateDesktopAgentsStatus(
      (_: unknown, agentsStatusesEncoded: string) => {
        setAgentsStatusesState(JSON.parse(agentsStatusesEncoded));
      }
    );
  }, []);

  return agentsStatusesState;
};

const DesktopAgentsStatusesContext = createContext<AgentsRunningStatus | null>(
  null
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useDesktopAgentsStatusContext = (): AgentsRunningStatus | null => {
  return useContext(DesktopAgentsStatusesContext);
};

export const DesktopAgentsStatusesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const desktopAgentsStatues = useDesktopAgentStatus();
  return (
    <DesktopAgentsStatusesContext.Provider value={desktopAgentsStatues}>
      {children}
    </DesktopAgentsStatusesContext.Provider>
  );
};
