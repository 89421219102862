import { ResourceType } from "../../../../../types/appViewsResourcesTypes";

export const ResourceTypeToName: Record<ResourceType, string> = {
  horizontalpodautoscalers: "HPA",
  persistentvolumeclaims: "PVC",
  configmaps: "ConfigMaps",
  secrets: "Secrets",
  ingresses: "Ingresses",
  pods: "Pods",
  deployments: "Deployments",
  statefulsets: "Stateful sets",
  daemonsets: "Daemonsets",
  rollouts: "Rollouts",
  jobs: "Jobs",
  cronjobs: "CronJobs",
  services: "K8s services",
  nodes: "Nodes",
};

export const serviceTypes: ResourceType[] = [
  "deployments",
  "statefulsets",
  "daemonsets",
  "rollouts",
];
export const jobTypes: ResourceType[] = ["jobs", "cronjobs"];

export const selectedResourceTypes: ResourceType[] = [
  ...serviceTypes,
  ...jobTypes,
];

export const discoveredResourceTypes: ResourceType[] = [
  "nodes",
  "pods",
  "configmaps",
  "persistentvolumeclaims",
  "services",
  "ingresses",
  "secrets",
  "horizontalpodautoscalers",
];
