import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest,
  apiV1RbacCustomK8sActionsActionRbacPoliciesGetUrl,
} from "../../../../../generated/auth";

const RBAC_POLICY_ACTIONS_PATH =
  "/api/v1/rbac-custom-k8s-actions/:action/rbac-policies";

const fetchActionUsedByPoliciesRbacCustomK8sActions = async (
  apiClient: AxiosInstance,
  params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest
): Promise<string[]> => {
  const { data } = await apiClient.get(
    apiV1RbacCustomK8sActionsActionRbacPoliciesGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetActionUsedByPoliciesRbacCustomK8sActions = (
  params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsActionRbacPoliciesGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_POLICY_ACTIONS_PATH.replace(":action", params.action)],
    () => fetchActionUsedByPoliciesRbacCustomK8sActions(apiClient, params),
    {
      enabled,
    }
  );
};
