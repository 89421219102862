import React, { useRef } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import { v4 as uuidv4 } from "uuid";

import { ViolationsTableContextProvider } from "../pages/violations/ViolationsBreakdown/ViolationTableWithContext/context/ViolationsTableContext";
import { ImpactGroupIdentifier } from "../../../../generated/reliabilityApi";

import { ViolationsDrawerByScopeHeader } from "./ViolationsDrawerByScopeHeader";
import { ViolationsDrawerByScopeContent } from "./ViolationsDrawerByScopeContent";
import { IReliabilityByScope } from "./violationsDrawerByScopeTypes";
import { useScope } from "./hooks/useScope";
import { useSetExternalScopeInStore } from "./hooks/useSetExternalScopeInStore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
`;

type Props = IReliabilityByScope & {
  open: boolean;
  onClose: () => void;
};

export const ViolationsDrawerByScope: React.FC<Props> = ({
  open,
  onClose,
  scope,
  additionalConfig,
}) => {
  const uuid = useRef(uuidv4());
  const { groupBy } = useScope(scope);

  const { isUpdated } = useSetExternalScopeInStore({
    uid: uuid.current,
    groupBy,
    scope,
  });

  if (!isUpdated) return null;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: { width: "60%", minWidth: "907px" },
      }}
    >
      <Container>
        <ViolationsTableContextProvider
          scope={scope}
          uid={uuid.current}
          impactGroupIdentifier={
            scope.impactGroupId?.[0] as ImpactGroupIdentifier
          }
          paginationModel={additionalConfig?.paginationModel}
          violationsGroupBy={groupBy}
        >
          <ViolationsDrawerByScopeHeader
            scope={scope}
            additionalConfig={additionalConfig}
            onClose={onClose}
          />
          <ViolationsDrawerByScopeContent
            scope={scope}
            additionalConfig={additionalConfig}
          />
        </ViolationsTableContextProvider>
      </Container>
    </Drawer>
  );
};
