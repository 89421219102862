import React, { memo } from "react";
import { muiColors } from "@komodorio/design-system";

import { CostAllocationSummaryResponse } from "../../../../../generated/metricsApi";
import {
  cardConfigToDataKey,
  overviewCardsOrder,
} from "../../../../CostOptimizationView/constants/costOptimizationConstants";
import { CostAllocationStatsResponseField } from "../../../../CostOptimizationView/types/costOptimizationTypes";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { Summary } from "../Summary";

export const StatsSummary: React.FC<{
  data: CostAllocationSummaryResponse | undefined;
  isLoading: boolean;
}> = memo(({ data, isLoading }) => {
  const stats = overviewCardsOrder.map((dataKey) => {
    const { label, formatter } = cardConfigToDataKey[dataKey];
    const value = data?.[dataKey];
    const color =
      dataKey === CostAllocationStatsResponseField.totalPotentialSaving
        ? muiColors.green[800]
        : undefined;
    return { label, value: value ? formatter(value) : "N/A", color };
  });

  return (
    <Summary
      stats={stats}
      isLoading={isLoading}
      ariaLabel={AriaLabels.ClusterOverview.CostSection.StatsSummary}
    />
  );
});
