import React from "react";

import { isSandbox } from "../../shared/utils/sandbox";

import SandboxAccountBanner from "./SandboxAccountBanner/SandboxAccountBanner";

const trialCounter = "@komodor/trialCounter";
export const trialEndCounterHeight = "3rem";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isTrialEndBannerOpen = sessionStorage.getItem(trialCounter);

const AppBanners: React.FC = () => {
  return (
    <>
      {isSandbox() && <SandboxAccountBanner height={trialEndCounterHeight} />}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AppBanners;
