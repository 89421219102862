import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";

import { getLabelTextByWorkspace } from "./utils";

type AllWorkspacesToggleProps = {
  searchInAllWorkspaces: boolean;
  onClick: () => void;
};

const Root = styled.div`
  padding: 11px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / 3;
`;

export function AllWorkspacesToggle({
  searchInAllWorkspaces,
  onClick,
}: AllWorkspacesToggleProps) {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const { isClusterWorkspace, isClustersGroupWorkspace, currentWorkspace } =
    useWorkspaces();

  if (!selectedAppViewId) {
    return null;
  }

  return (
    <Root>
      <Typography variant="body3" color="text.secondary">
        Showing results for{" "}
        {isClusterWorkspace
          ? "Cluster"
          : isClustersGroupWorkspace
          ? "Cluster Group"
          : "Workspace"}
        :{" "}
        <Box color="text.primary" fontWeight="bold" display="inline">
          {searchInAllWorkspaces
            ? getLabelTextByWorkspace(isClusterWorkspace)
            : currentWorkspace?.name}
        </Box>
      </Typography>
      <FormControlLabel
        tabIndex={-1}
        control={
          <Switch
            checked={searchInAllWorkspaces}
            onChange={() => onClick()}
            sx={{ marginLeft: "8px" }}
            size="small"
          />
        }
        aria-label={AriaLabels.GlobalSearch.AllWorkspacesToggle}
        labelPlacement="start"
        label={
          <Typography variant="body3" color="text.secondary">
            Search all{" "}
            {isClusterWorkspace || isClustersGroupWorkspace
              ? "clusters"
              : "workspaces"}
          </Typography>
        }
      />
    </Root>
  );
}
