import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useResourcesApiClient } from "../apiClient";
import {
  apiV1DeploysEventsSearchGetUrl,
  DeployEventsResponse,
  EventsApiApiV1DeploysEventsSearchGetRequest,
} from "../../../../../generated/resourcesApi";

export const GET_DEPLOY_EVENTS_SEARCH_PATH = "/api/v1/deploys/events/search";

export type UseDeployEventsSearchParams =
  EventsApiApiV1DeploysEventsSearchGetRequest & {
    enabled?: boolean;
  };

export const useSearchDeployEvents = (params: UseDeployEventsSearchParams) => {
  const apiClient = useResourcesApiClient();

  return useQuery(
    [GET_DEPLOY_EVENTS_SEARCH_PATH, params],
    async () =>
      await apiClient.get<DeployEventsResponse>(
        apiV1DeploysEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    { enabled: params.enabled }
  );
};

export const fetchLatestDeployUntil = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1DeploysEventsSearchGetRequest | null
): Promise<DeployEventsResponse> => {
  if (!params) {
    return { data: [] };
  }
  const { data } = await apiClient.get(
    apiV1DeploysEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};
