import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Divider } from "../../common/styles";
import {
  gray10,
  gray4,
  greenForUI,
  pinkForUI,
  yellowForUI,
} from "../../../../Colors";
import flagIcon from "../../assets/flag.svg";
import successIcon from "../../assets/success.svg";
import warnIcon from "../../assets/warn.svg";
import infoIcon from "../../assets/info.svg";
import Arrow from "../../../common/ProcessList/Arrow";
import { linkStyle, textBolderStyle } from "../../../common/typography";
import DownArrowIcon from "../../../common/ProcessList/assets/down-arrow.svg?react";

const onHoverBackgroundColor = "#007AFF0A";

export const Container = styled.div<{
  isSelected?: boolean;
  isHover?: boolean;
}>`
  display: grid;
  grid-template-columns: 3rem auto 0.75rem;
  align-items: center;
  background-color: white;
  padding: 1rem;
  cursor: pointer;
  ${({ isHover, isSelected }) =>
    isHover || isSelected
      ? `background-color: ${onHoverBackgroundColor}`
      : "background-color: white"};
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${gray10};
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const StyledLink = styled(Link)`
  ${linkStyle}
  margin: 0.1rem 0;
`;
export const MoreInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShowMoreToggle = styled.div`
  display: flex;
  align-items: center;
  ${textBolderStyle}
  color: ${gray4};
  margin-block-start: 1rem;
  cursor: pointer;
  width: max-content;
`;

export const ArrowIcon = styled(({ reverse, ...props }) => (
  <DownArrowIcon {...props} />
))<{ reverse: boolean }>`
  margin-inline-end: 0.3rem;
  ${({ reverse }) => reverse && "transform: rotate(180deg)"};
`;
export type CheckIconType = "success" | "fail" | "warn" | "info";
export const CheckIcon = styled.div<{ icon: CheckIconType }>`
  padding: 0.5rem;
  border-radius: 1.25rem;
  width: 1.125rem;
  height: 1.125rem;

  ${({ icon }) => {
    switch (icon) {
      case "success":
        return `background-image: url(${successIcon}); background-color: ${greenForUI};`;
      case "fail":
        return `background-image: url(${flagIcon}); background-color: ${pinkForUI};`;
      case "warn":
        return `background-image: url(${warnIcon}); background-color: ${yellowForUI};`;
      case "info":
        return `background-image: url(${infoIcon}); background-color: #DCDDDF;`;
    }
  }}

  background-repeat: no-repeat;
  background-position: center;
`;

interface CheckCardProps {
  title: string;
  subtitle: string | JSX.Element;
  icon: CheckIconType;
  isSelected?: boolean;
  onClick?: () => void;
}

const CheckCard: React.FC<CheckCardProps> = ({
  title,
  subtitle,
  icon,
  isSelected,
  onClick,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <>
      <Container
        isSelected={isSelected || false}
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        onClick={onClick}
        isHover={isHover}
      >
        <CheckIcon icon={icon} />
        <div>
          <Title>{title.toUpperCase()}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
        <Arrow width="0.75rem" color={gray10} direction="right" />
      </Container>
      <Divider />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CheckCard;
