import React, { useMemo } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";

import {
  selectRightSizingFilters,
  selectRightSizingRecommendationsState,
  selectRightSizingRecommendationsStrategiesState,
  selectSetRightSizingModalStrategies,
  selectSetRightSizingRecommendationModal,
  selectSetRightSizingRecommendationsState,
} from "../../../store/costOptimizationStoreSelectors";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { useGetRowData } from "../RightSizingTable/rightSizingTableHooks";
import { RecommendationsModal } from "../../shared/RecommendationsModal/RecommendationsModal";
import { useRowsContent } from "../../shared/RecommendationsModal/useRowsContent";
import { initialState } from "../../../store/initialState";
import { CostStrategy } from "../../../types/costOptimizationTypes";
import { useGetRowStrategy } from "../hooks/rightSizingHooks";
import { getMonthlyAmount } from "../../../utils/costOptimizationUtils";

import {
  useReportLoadTime,
  useFetchRightSizingRecommendationData,
} from "./rightSizingRecommendationModalHooks";

export const RightSizingRecommendationModal: React.FC<{
  rowId: string | undefined;
}> = ({ rowId }) => {
  useFetchRightSizingRecommendationData();

  const { data, error, loading, refresh } = useCostOptimizationStore(
    selectRightSizingRecommendationsState
  );

  const setModalOptions = useCostOptimizationStore(
    selectSetRightSizingRecommendationModal
  );

  const setRightSizingRecommendationsState = useCostOptimizationStore(
    selectSetRightSizingRecommendationsState
  );

  const recommendationStrategies = useCostOptimizationStore(
    selectRightSizingRecommendationsStrategiesState
  );

  const setRightSizingRecommendationsStrategiesState = useCostOptimizationStore(
    selectSetRightSizingModalStrategies
  );

  const { strategy: pageStrategy } = useCostOptimizationStore(
    selectRightSizingFilters
  );

  useReportLoadTime(data, !!rowId);

  const handleClose = () => {
    if (!rowId) return;
    setRightSizingRecommendationsStrategiesState({ [rowId]: pageStrategy });
    setModalOptions({ rowId: undefined });
    setRightSizingRecommendationsState(
      initialState.rightSizingRecommendationsState
    );
  };

  const rowData = useGetRowData(rowId);

  const modalStrategy = useGetRowStrategy(rowId);

  const rowsContent = useRowsContent({
    loading,
    error,
    strategy: modalStrategy,
    data,
    refresh,
  });

  const potentialSaving = useMemo(
    () => getMonthlyAmount({ rowData, recommendationStrategies }),
    [recommendationStrategies, rowData]
  );

  const onChangeStrategy = (rowId: string, selected: OptionType<string>) => {
    setRightSizingRecommendationsStrategiesState({
      [rowId]: selected.value as CostStrategy,
    });
  };

  return (
    <RecommendationsModal
      modalStrategy={modalStrategy}
      containersData={data?.rows}
      onChangeStrategy={onChangeStrategy}
      rowsContent={rowsContent}
      open={!!rowId}
      handleClose={handleClose}
      rowData={rowData}
      isLoading={loading}
      potentialSaving={potentialSaving}
    />
  );
};
