import { Refresh24 } from "@komodorio/design-system/icons";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

export const RefreshButton = styled(IconButton).attrs({
  icon: Refresh24,
  noBorder: true,
})`
  position: absolute;
  margin-inline: 1rem;
  right: 2rem;
`;
