import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { useFilteredConfigurations } from "../hooks/useFilteredConfigurations";
import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";
import { useSupportedCheckTypesByTab } from "../hooks/useSupportedCheckTypesByTab";

import { AddCheckPrompt } from "./AddCheckPrompt";
import { ConfigurationItem } from "./ConfigurationItem";
import { AddCheckButton } from "./AddCheckButton";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { CheckType } from "@/generated/reliabilityApi";
import { getTableStyle } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getTableStyle";
import { useIsEditDefaultPolicyMode } from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerConfigurations/useIsEditDefaultPolicyMode";
import { useIsDefaultPolicy } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useIsDefaultPolicy";

const {
  policies: {
    policiesDrawer: { configurationsList: configurationsListAriaLabel },
  },
} = reliabilityArialLabels;

const Container = styled.div`
  margin-top: 16px;
  border: 1px solid ${muiColors.gray[300]};
  border-radius: 4px;
`;

const Header = styled.div`
  border-bottom: 1px solid ${muiColors.gray[300]};
  padding: 8px 16px;
`;

const ContentMargin = styled.div<{
  $isDynamic: boolean;
  $showEnabled?: boolean;
}>`
  ${({ $isDynamic, $showEnabled }) => getTableStyle($isDynamic, $showEnabled)}
`;

const Footer = styled.div`
  padding: 8px 16px;
`;

export const CheckConfigurationsTable: React.FC = () => {
  const { policyConfigurations, isReadOnlyMode } = usePolicyDrawerContext();
  const filteredConfigurations = useFilteredConfigurations();
  const isDynamicChecksTab = useIsDynamicChecksTab();
  const isEditDefaultPolicyMode = useIsEditDefaultPolicyMode();
  const showEnabledSwitch = isEditDefaultPolicyMode;
  const supportedCheckTypes = useSupportedCheckTypesByTab();
  const policyIsDefault = useIsDefaultPolicy();
  const isEmptyConfigurations = useMemo(
    () =>
      Object.keys(policyConfigurations).filter((configuration) =>
        supportedCheckTypes.includes(configuration as CheckType)
      ).length === 0,
    [policyConfigurations, supportedCheckTypes]
  );

  const checkItems = useMemo(() => {
    return filteredConfigurations.map((item) => {
      return <ConfigurationItem checkType={item} key={item} />;
    });
  }, [filteredConfigurations]);

  const showFooter = !policyIsDefault && !isReadOnlyMode;

  return (
    <Container aria-label={configurationsListAriaLabel}>
      <Header>
        <ContentMargin
          $isDynamic={isDynamicChecksTab}
          $showEnabled={isEditDefaultPolicyMode}
        >
          {(isDynamicChecksTab || showEnabledSwitch) && <div />}
          <Typography variant={"h5"}>Name</Typography>
          {showEnabledSwitch && <Typography variant={"h5"}>Enabled</Typography>}
          <Typography variant={"h5"}>
            {isDynamicChecksTab ? "Impact group" : "Category"}
          </Typography>
          <div />
        </ContentMargin>
      </Header>
      {isEmptyConfigurations && <AddCheckPrompt />}
      {checkItems}
      {showFooter && (
        <Footer>
          <AddCheckButton buttonProps={{ variant: "text" }} />
        </Footer>
      )}
    </Container>
  );
};
