import { muiColors } from "@komodorio/design-system";

import { SeverityColor } from "@/components/k8sAddons/types";

export const ColorScheme: Record<SeverityColor, string> = {
  error: muiColors.pink[700] ?? "",
  warning: muiColors.orange[700] ?? "",
  success: muiColors.teal[900] ?? "",
  info: muiColors.gray[800] ?? "",
};
