import React from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { KubernetesJobResource } from "../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const ArgoWorkflowKind = "ArgoWorkflows.Workflows";

const shouldShowLink = (kind: string): boolean => {
  return kind !== KubernetesJobResource.Kind && kind !== ArgoWorkflowKind;
};

type ServiceTextLinkProps = {
  serviceId: string;
  serviceName: string;
  serviceKind: string;
  onClick: (serviceId: string) => void;
  className?: string;
};

export const ServiceTextLink: React.FC<ServiceTextLinkProps> = ({
  serviceId,
  serviceName,
  serviceKind,
  onClick,
  className,
}) => {
  if (!serviceId) return <>{serviceName}</>;
  if (!shouldShowLink(serviceKind)) return <>{serviceName}</>;

  return (
    <StyledLink onClick={() => onClick(serviceId)} className={className}>
      {serviceName}
    </StyledLink>
  );
};
