import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";

export const ariaLabels = {
  clusters: "Clusters",
  apiKeys: "API Keys",
  agentsStatus: "Agents Status",
  copyApiKey: "Copy API Key",
  deleteApiKey: "Delete API Key",
  apiKeyCell: "API Key Cell",
  addCluster: "Add Cluster",
  showInactiveAgents: "Show Inactive Agents",
  table: "Agents table",
  drawer: "Agent Drawer",
};

export const API_KEY_ACTIONS_CLASSNAME = "api-key-actions";

export const DEFAULT_SORTING_MODEL: GridSortModel = [
  {
    field: "inactive",
    sort: "asc",
  },
];

export const INITIAL_COLUMN_VISIBILITY = {
  createdAt: false,
  lastSeenAlive: false,
  K8sVersion: false,
};

export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 10,
};

export const UNTOUCHABLE_COLUMNS = ["inactive", "clusterName", "id", "actions"];
