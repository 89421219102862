import * as React from "react";
import styled from "styled-components";

import { H3 } from "../../../common/typography";

const Container = styled.div`
  margin-bottom: 1.5rem;
  width: 20rem;
`;

const List = styled.ol`
  padding-left: 1em;
`;

const Step = styled.li`
  font-size: 0.85rem;
`;

const NewrelicAlertInstallationInstructions: React.FC = () => {
  return (
    <Container>
      <H3>Installation Steps</H3>
      <List>
        <Step>
          Create API and Application keys on{" "}
          <a
            href="https://one.newrelic.com/launcher/api-keys-ui.launcher"
            target="_blank"
            rel="noreferrer"
          >
            Newrelic API Settings
          </a>
        </Step>
        <Step>Insert the keys in the fields below</Step>
      </List>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NewrelicAlertInstallationInstructions;
