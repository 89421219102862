import { ResourceTableModelRow } from "komodor-types";
import { get, merge, toNumber } from "lodash";
import { useMemo } from "react";

import {
  KubernetesNodesResource,
  KubernetesPodsResource,
  KubernetesResource,
} from "../inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListResponse } from "../InspectionViews/common";

import {
  buildMetricsForNode,
  buildMetricsForPodWithRequestAndLimits,
} from "./buildMetricFromResult";
import { MetricsTaskResult } from "./metricsTaskResultParser";

const useAppendMetricsToResourceList = (
  resourceList: ResourceListResponse,
  metricsResult: MetricsTaskResult | null,
  isFetchingMetrics: boolean,
  errorBringingMetrics: string | null,
  kubernetesResource: KubernetesResource
): ResourceListResponse => {
  const appendMetrics = useMemo(() => {
    if (!metricsResult) {
      return false;
    }
    if (resourceList.fetching || isFetchingMetrics) {
      resourceList.fetching = true;
      return false;
    }

    if (
      errorBringingMetrics ||
      resourceList.emptyResult ||
      resourceList.errorMessage
    ) {
      return false;
    }
    return true;
  }, [errorBringingMetrics, isFetchingMetrics, metricsResult, resourceList]);

  return useMemo(() => {
    if (!appendMetrics) {
      return resourceList;
    }
    const updatedRows = resourceList.rows.map((row) => {
      return merge(
        row,
        appendMetricsByRow(row, kubernetesResource, metricsResult)
      );
    });
    return {
      ...resourceList,
      rows: updatedRows,
    };
  }, [appendMetrics, kubernetesResource, metricsResult, resourceList]);
};

const appendMetricsByRow = (
  row: ResourceTableModelRow,
  kubernetesResource: KubernetesResource,
  metricsResult: MetricsTaskResult | null
) => {
  const name = get(row, "name", null);
  if (!name) {
    return {};
  }
  if (kubernetesResource.NameInK8S === KubernetesNodesResource.NameInK8S) {
    return buildMetricsForNode(name, metricsResult);
  } else if (
    kubernetesResource.NameInK8S === KubernetesPodsResource.NameInK8S
  ) {
    return buildMetricsForPodWithRequestAndLimits(
      name,
      metricsResult,
      toNumber(row["cpuLimit"]),
      toNumber(row["memoryLimit"]),
      toNumber(row["cpuRequest"]),
      toNumber(row["memoryRequest"])
    );
  }
  return {};
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAppendMetricsToResourceList;
