import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";

import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../shared/styles";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { ServicesTableTitle } from "../../shared/components/InsightsDataTable/ServicesTableTitle";
import { InsightsDataTable } from "../../shared/components/InsightsDataTable/InsightsDataTable";
import { getGenericServiceColumns } from "../../shared/components/InsightsDataTable/insightDataTableUtils";
import {
  AvailabilityIssuesColumn,
  AvailabilityIssuesTableColumn,
  ThrottledApplicationColumns,
} from "../../shared/types";
import { DataPointsBreakdownPerIntervals } from "../../shared/utils/getDataPointsBreakdownPerIntervals";
import { getGenericTableColumnRow } from "../../shared/utils/utils";
import { useOpenIssueEventsPath } from "../../../../../hooks/overviewPageHooks";

import {
  decreasedExplanationBlock1,
  explanationTitle1,
  explanationTitle2,
  increasedExplanationBlock1,
  getExplanationBlock2,
} from "./AvailabilityIssuesTexts";
import { ViewIssuesButton } from "./ViewIssuesButton";
import { useSplitToGraphData } from "./hooks/useSplitToGraphData";

const GraphContainer = styled.div`
  height: 191px;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getIssuesRows = (
  columns: AvailabilityIssuesColumn[]
): AvailabilityIssuesTableColumn[] => {
  return columns.map((column) => ({
    ...getGenericTableColumnRow(column),
    count: column.count,
  }));
};

const issuesColumnsWithButton = getGenericServiceColumns<
  AvailabilityIssuesTableColumn & ThrottledApplicationColumns
>().concat([
  {
    field: "count",
    headerName: "Issues in last 7d",
    flex: 1,
    maxWidth: 80,
  },
  {
    field: "actionButton",
    headerName: "",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <ViewIssuesButton rowData={params.row} />
    ),
  },
]);

type AvailabilityIssuesDrawerProps = {
  dataPointsBreakdown?: DataPointsBreakdownPerIntervals;
};

export const AvailabilityIssuesDrawer: React.FC<
  AvailabilityIssuesDrawerProps
> = ({ dataPointsBreakdown }) => {
  const {
    availabilityIssues: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const {
    firstTotal = 0,
    secondTotal = 0,
    trendPercentage = "",
    isIncreased = false,
  } = dataPointsBreakdown ?? {};

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "count",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const graph = useSplitToGraphData(true);
  // this would use the default time frame from CurrentAppViewContext which is not used in this component
  // this functionality needs to be refactored
  const openIssueEventsPath = useOpenIssueEventsPath();

  const rows = useMemo(() => {
    if (!data?.affectedServices) return [];
    return getIssuesRows(data.affectedServices);
  }, [data]);

  if (!data) return null;

  const explanationBlock = getExplanationBlock2({
    isIncreased,
    current: secondTotal,
    previous: firstTotal,
    trendPercentage: trendPercentage,
  });

  return (
    <div>
      <InsightsDrawerTitle>{explanationTitle1}</InsightsDrawerTitle>
      <InsightsDrawerContent>
        {isIncreased ? increasedExplanationBlock1 : decreasedExplanationBlock1}
      </InsightsDrawerContent>
      <InsightsDrawerTitle>{explanationTitle2}</InsightsDrawerTitle>
      <InsightsDrawerContent>{explanationBlock}</InsightsDrawerContent>
      <GraphContainer>{graph}</GraphContainer>
      <Button
        onClick={() => {
          window.open(openIssueEventsPath, "_blank", "noopener noreferrer");
        }}
        variant="outlined"
      >
        View recent issues
      </Button>
      <ServicesTableTitle />
      <InsightsDataTable
        columns={issuesColumnsWithButton}
        rows={rows}
        props={{
          sortModel,
          onSortModelChange: onSortModelChange,
        }}
      />
    </div>
  );
};
