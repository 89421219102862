import { parseISO } from "date-fns";
import { ResourceTableModelRow } from "komodor-types";
import { chain, map } from "lodash";

export interface Controller {
  name: string;
  rows: ResourceTableModelRow[];
  runningPods: number;
  availablePods: number;
  lastModified: Date;
}

export function buildControllerGroups(
  rows: ResourceTableModelRow[]
): Map<string, Controller> {
  return chain(rows || [])
    .groupBy("controlledBy")
    .map((rows, controller) => {
      return {
        name: controller,
        rows,
        runningPods: rows.filter((r) => r["status"] === "Running").length,
        availablePods: rows.length,
        lastModified: new Date(getEarliestTime(rows)),
      };
    })
    .sortBy((r) => r.lastModified)
    .reduce((prev, curr) => {
      prev.set(curr.name, curr);
      return prev;
    }, new Map<string, Controller>())
    .value();
}

function getEarliestTime(rows: ResourceTableModelRow[]): number {
  return Math.min(
    ...map(rows, (r) => parseISO(r["creationTimestamp"]).getTime())
  );
}
