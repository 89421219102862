/* eslint-disable max-lines */
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

import { gray4 } from "../../../Colors";
import Overlay from "../Overlay";

import Input from "./Input";
import dropdownArrow from "./Select/dropdownArrow";
import {
  Empty,
  List,
  ListContent,
  Options,
  Option,
  Placeholder,
  OptionContent,
  Text,
  Divider,
} from "./Select/SingleSmartSelect";

const Portal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const container = useRef(document.createElement("div"));
  useEffect(() => {
    const selectMenuRoot = document.getElementById("select-menu-root");
    if (!selectMenuRoot) {
      return;
    }
    const div = container.current;
    selectMenuRoot.appendChild(div);
    return () => {
      selectMenuRoot.removeChild(div);
    };
  }, []);
  return createPortal(children, container.current);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const disabledStyle = css`
  :disabled {
    background-color: white;
    color: ${gray4};
    border: 1px solid ${gray4};
    cursor: not-allowed;
  }
`;

const StyledInput = styled(Input)`
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${disabledStyle}

  &::placeholder {
    color: ${gray4};
  }

  ${dropdownArrow}
`;

const StyledOption = styled(Option)<{ highlight: boolean }>`
  background-color: ${({ highlight }) =>
    highlight ? "rgba(0, 122, 255, 0.3)" : "transparent"};
`;

const StyledList = styled(List)<{ width?: string }>`
  min-width: unset;
  width: ${({ width }) => (width ? `calc(${width} - 2px)` : "")};
  overflow: auto;
  max-height: 20rem;
  z-index: 10001;
`;

export type SuggestionType<T> = { label: string; value: T; disabled?: boolean };

export interface AutocompleteProps<T> {
  fieldname: string;
  defaultSelected?: SuggestionType<T>;
  disabled?: boolean;
  customWidth?: string;
  placeholder?: string;
  suggestions: SuggestionType<T>[];
  onSelectedChange: (value: T) => void;
  allowDeletion?: boolean;
  focus?: boolean;
}

function Autocomplete<T>({
  fieldname,
  suggestions,
  defaultSelected,
  disabled = false,
  placeholder,
  onSelectedChange,
  allowDeletion = false,
  focus = false,
}: AutocompleteProps<T>): ReactElement | null {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    SuggestionType<T>[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(defaultSelected?.label ?? "");
  const [lastSelectedLabel, setLastSelectedLabel] = useState(
    defaultSelected?.label ?? ""
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [listStyle, setListStyle] = useState<React.CSSProperties>();

  if (focus) {
    inputRef.current?.focus();
  }
  useEffect(() => {
    setFilteredSuggestions(suggestions);
    if (
      !suggestions.find((suggestion) =>
        suggestion.label.toLowerCase().includes(userInput)
      )
    ) {
      setUserInput(defaultSelected?.label ?? "");
      setLastSelectedLabel(defaultSelected?.label ?? "");
      setActiveSuggestion(0);
      return;
    }
  }, [defaultSelected?.label, suggestions, userInput]);

  useEffect(() => {
    const selected = filteredSuggestions.find(
      (fs) => fs.label.toLowerCase() === userInput.toLowerCase()
    );
    if (selected) {
      onSelectedChange(selected.value);
      setLastSelectedLabel(selected.label);
    } else if (allowDeletion && userInput === "") {
      onSelectedChange("" as unknown as T);
    }
  }, [allowDeletion, filteredSuggestions, onSelectedChange, userInput]);

  useEffect(() => {
    if (!inputRef?.current || !showSuggestions) {
      return;
    }
    const bounds = inputRef.current.getBoundingClientRect();
    setListStyle({
      top: bounds.top + inputRef.current.offsetHeight + window.scrollY,
      left: bounds.left,
    });
  }, [showSuggestions]);

  useEffect(() => {
    if (!optionsRef?.current) {
      return;
    }
    optionsRef.current
      .getElementsByTagName("button")
      [activeSuggestion]?.scrollIntoView({ block: "nearest" });
  }, [activeSuggestion]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (allowDeletion && e.currentTarget.value === "") {
        setUserInput("");
        setShowSuggestions(false);
        return;
      }

      const searchTerm = e.currentTarget.value.toLowerCase();
      const filteredSuggestions = suggestions
        .filter((suggestion) =>
          suggestion.label.toLowerCase().includes(searchTerm)
        )
        .sort(
          (a, b) => a.label.indexOf(searchTerm) - b.label.indexOf(searchTerm)
        );

      setUserInput(e.currentTarget.value);
      setFilteredSuggestions(filteredSuggestions);
      setShowSuggestions(true);
      setActiveSuggestion(0);
    },
    [allowDeletion, suggestions]
  );

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault();
    setUserInput(e.currentTarget.innerText);
    setShowSuggestions(false);
    setActiveSuggestion(0);
  }, []);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (showSuggestions && filteredSuggestions.length) {
        if (e.key === "Enter") {
          e.preventDefault();
          setUserInput(filteredSuggestions[activeSuggestion].label);
        }
        if (e.key === "Enter" || e.key === "Escape") {
          setActiveSuggestion(0);
          setShowSuggestions(false);
        }
      }
      if (e.key === "ArrowUp") {
        if (activeSuggestion === 0) {
          return setActiveSuggestion(filteredSuggestions.length - 1);
        }
        setActiveSuggestion(activeSuggestion - 1);
      } else if (e.key === "ArrowDown") {
        if (activeSuggestion === filteredSuggestions.length - 1) {
          return setActiveSuggestion(0);
        }
        setActiveSuggestion(activeSuggestion + 1);
      }
    },
    [activeSuggestion, filteredSuggestions, showSuggestions]
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    if (allowDeletion && userInput === "") {
      setShowSuggestions(false);
      return;
    }
    setUserInput(lastSelectedLabel);
    setActiveSuggestion(0);
    setShowSuggestions(false);
  }, [allowDeletion, lastSelectedLabel, userInput]);

  const [listWidth, setListWidth] = useState<string>();
  useLayoutEffect(() => {
    if (inputRef.current?.offsetWidth) {
      setListWidth(`${inputRef.current?.offsetWidth}px`);
    }
  }, []);

  const enabledOptions = useMemo(
    () => filteredSuggestions.filter((suggestion) => !suggestion.disabled),
    [filteredSuggestions]
  );
  const disabledOptions = useMemo(
    () => filteredSuggestions.filter((suggestion) => suggestion.disabled),
    [filteredSuggestions]
  );

  return (
    <>
      <StyledInput
        ref={inputRef}
        value={userInput}
        disabled={disabled}
        placeholder={placeholder}
        width={listWidth}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={(e) => e.target.select()}
        onClick={() => setShowSuggestions(true)}
      />
      {showSuggestions && (
        <Portal>
          <Overlay open onClick={() => setShowSuggestions(false)} />
          <StyledList width={listWidth} style={listStyle}>
            <ListContent>
              <Options ref={optionsRef}>
                {filteredSuggestions.length ? (
                  <>
                    {enabledOptions.map((suggestion, index) => (
                      <StyledOption
                        onMouseEnter={() => setActiveSuggestion(index)}
                        highlight={activeSuggestion === index}
                        key={suggestion.label}
                        onMouseDown={handleClick}
                        disabled={suggestion.disabled}
                      >
                        <OptionContent width={listWidth}>
                          <Text>{suggestion.label}</Text>
                        </OptionContent>
                      </StyledOption>
                    ))}
                    {disabledOptions.length > 0 ? (
                      <>
                        <Divider />
                        {disabledOptions.map((suggestion) => (
                          <StyledOption
                            key={suggestion.label}
                            disabled={true}
                            highlight={false}
                          >
                            <OptionContent width={listWidth}>
                              <Text>{suggestion.label}</Text>
                            </OptionContent>
                          </StyledOption>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Empty>
                    <Placeholder>{`no ${fieldname} found`}</Placeholder>
                  </Empty>
                )}
              </Options>
            </ListContent>
          </StyledList>
        </Portal>
      )}
    </>
  );
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Autocomplete;
