import Button from "@mui/material/Button";
import React from "react";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";

import {
  lastUrlSelector,
  showResourceDrawerSelector,
  showTriageDrawerSelector,
} from "../../shared/store/investigationModeStore/investigationModeSelectors";
import {
  resetInvestigationModeStore,
  useInvestigationModeStore,
} from "../../shared/store/investigationModeStore/investigationModeStore";
import { AriaLabels } from "../../shared/config/ariaLabels";
import { useEscape } from "../../shared/hooks/useEscape";

import { layoutPanelStyle } from "./styles";
import { LatestDeployLinksButton } from "./LinksMenu";
import { QuickActions } from "./QuickActions";

const Container = styled.div`
  ${layoutPanelStyle};
  padding-inline: 0;
  display: grid;
  grid-template-rows: max-content auto;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 8px;
  position: absolute;
  right: 40px;
  top: 24px;
`;

export const Page: React.FC = () => {
  const navigate = useNavigate();
  const lastUrl = useInvestigationModeStore(lastUrlSelector);

  const handleExit = () => {
    resetInvestigationModeStore();
    if (lastUrl) {
      navigate(lastUrl);
    } else {
      navigate("/");
    }
  };

  const showResourceDrawer = useInvestigationModeStore(
    showResourceDrawerSelector
  );
  const showTriageDrawer = useInvestigationModeStore(showTriageDrawerSelector);

  useEscape(() => {
    if (!showResourceDrawer && !showTriageDrawer) {
      handleExit();
    }
  });

  return (
    <Container>
      <Header>
        <QuickActions />
        <Button
          sx={{
            padding: "8px 12px",
            minWidth: "unset",
            lineHeight: "1",
            height: "32px",
            boxSizing: "border-box",
          }}
          variant="outlined"
          size="medium"
          onClick={handleExit}
          aria-label={AriaLabels.InvestigationMode.ExitButton}
        >
          Exit
        </Button>
        <LatestDeployLinksButton />
      </Header>
      <Outlet />
    </Container>
  );
};
