import React from "react";
import { Modal, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { LinesLoader } from "../common/loaders/Line";
import { usePortForward } from "../../shared/context/PortForwardProvider";
import { useActiveAgent } from "../../shared/hooks/useAgents";

import { StartNewSessionModalContent } from "./StartNewSessionModalContent";

const MinimalModalContent = styled.div`
  height: 50px;
  padding: 10px;
  width: 200px;
  margin-top: 28px;
`;

interface StartNewSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NO_AVAILABLE_PORTS_MESSAGE = "Could not find any available ports.";

export const StartNewSessionModal: React.FC<StartNewSessionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { availablePorts, clusterName } = usePortForward();
  const agentId = useActiveAgent(clusterName);

  if (!availablePorts || !availablePorts.length) {
    const unavailableContent = !availablePorts ? (
      <LinesLoader />
    ) : (
      <Typography>{NO_AVAILABLE_PORTS_MESSAGE}</Typography>
    );

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <MinimalModalContent>{unavailableContent}</MinimalModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <StartNewSessionModalContent
        onClose={onClose}
        agentId={agentId as string}
      />
    </Modal>
  );
};
