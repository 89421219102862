import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import {
  ClusterSummaryByNameResponse,
  ClustersApiApiV1ClustersSummaryNameGetRequest,
  apiV1ClustersSummaryNameGetUrl,
} from "../../../../generated/workspacesApi";

export const CLUSTER_BY_NAME_PATH = "/clusters-summary/:name";

const fetchClusterByName = async (
  apiClient: AxiosInstance,
  params: ClustersApiApiV1ClustersSummaryNameGetRequest
) => {
  const { data } = await apiClient.get<ClusterSummaryByNameResponse>(
    apiV1ClustersSummaryNameGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data.data;
};

export const useGetClusterByName = (
  params: ClustersApiApiV1ClustersSummaryNameGetRequest,
  options?: { enabled?: boolean; staleTime?: number }
) => {
  const apiClient = useWorkspacesApiClient();
  return useQuery(
    [CLUSTER_BY_NAME_PATH, params],
    () => fetchClusterByName(apiClient, params),
    options
  );
};
