import React, { useEffect, useMemo, useRef, useState } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";
import { isEqual } from "lodash";

import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectSetSharedCostFilters,
  selectSharedCostFilters,
} from "../../../store/costOptimizationStoreSelectors";
import { ClusterData } from "../../../../../shared/hooks/useGetAllClusters/useGetAllClusters";
import { useClusterOptionsByAppView } from "../../../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { useCostOptimizationDataInUrl } from "../../../hooks/persistentStateHooks";
import { isDevMode } from "../../../../../shared/utils/isDevMode";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  useGetMultiselectOptions,
  useGetScopeFilters,
  useSupportedClusterOptionsByAppView,
} from "./scopeFilterHooks";
import { ScopeFilter } from "./ScopeFilter";

export const agentUpdateRequiredTooltipText = "Agent update required";
export const collectingDataTooltipText =
  "Collecting data - Cluster will be available in less than 24 hours";

export const ScopeFilterContainer: React.FC = () => {
  const {
    scope: { clusters: currentClustersSelection },
  } = useCostOptimizationStore(selectSharedCostFilters);
  const setSharedCostFilters = useCostOptimizationStore(
    selectSetSharedCostFilters
  );

  const hasChangedOption = useRef(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const supportedClusters = useSupportedClusterOptionsByAppView();
  const [, setDataInUrl] = useCostOptimizationDataInUrl();
  const scopeFilters = useGetScopeFilters();
  const isSelectOpen = useRef(false);

  useEffect(() => {
    setSharedCostFilters({ scope: scopeFilters });
    setSelectedOptions(
      scopeFilters.clusters.map(({ clusterName }) => clusterName)
    );
  }, [scopeFilters, setSharedCostFilters]);

  const onChange = (options: OptionType<string>[]) => {
    const values = options.map((x) => {
      return x.value;
    });
    hasChangedOption.current = true;
    setSelectedOptions(values);

    !isSelectOpen.current && updateSharedState(values);
  };

  useEffect(() => {
    setSelectedOptions(
      currentClustersSelection.map((cluster) => cluster.clusterName)
    );
  }, [currentClustersSelection]);
  const updateSharedState = (options: string[]) => {
    const selectedOptionsAsClusterData = options.map<ClusterData>(
      (clusterName) => {
        const fullClusterData = clusterOptions.find(
          (x) => x.clusterName === clusterName
        );
        return {
          clusterName,
          version: fullClusterData?.version,
          daemonSetEnabled: fullClusterData?.daemonSetEnabled,
        };
      }
    );

    const selectedClusters = options.length
      ? selectedOptionsAsClusterData
      : supportedClusters;

    if (isEqual(selectedClusters, currentClustersSelection)) {
      return;
    }
    if (options.length === 0) {
      hasChangedOption.current = false;
    }

    setDataInUrl({
      sharedFilters: {
        scope: {
          clusters: selectedClusters.map(
            (clusterData) => clusterData.clusterName
          ),
        },
      },
    });
    setSharedCostFilters({ scope: { clusters: selectedOptionsAsClusterData } });
  };

  const handleClose = () => {
    isSelectOpen.current = false;
    // update shared state once dropdown is closed and selection has changed
    updateSharedState(selectedOptions);
  };

  const multiSelectOptions = useGetMultiselectOptions(clusterOptions);

  const values = useMemo(() => {
    const allClustersSelected =
      selectedOptions.length === supportedClusters.length &&
      supportedClusters.length > 1;

    // display no selection in initial state
    if (allClustersSelected && !hasChangedOption.current) return [];
    // convert string[] to OptionType<string>[]
    return selectedOptions.map((clusterName) => ({
      label: clusterName,
      value: clusterName,
    }));
  }, [supportedClusters.length, selectedOptions]);

  return (
    <ScopeFilter
      values={values}
      options={multiSelectOptions}
      onChange={onChange}
      onClose={handleClose}
      onOpen={() => {
        isSelectOpen.current = true;
      }}
    />
  );
};
