import {
  CostApiGetCostOptimizationTrendsRequest,
  CostOptimizationScoreTrendResponse,
  CostOptimizationScoreTrendResponseIntervalsInner,
} from "../../../../../../../generated/metricsApi";
import { TimeWindow } from "../../../components/OverviewPage/types/overviewPageTypes";
import { getRandomInt } from "../../../../../../../shared/utils/helpers";
import { getBucketTimeFrame } from "../../../components/OverviewPage/utils/overviewPageUtils";

import { getDateIntervals, getTotalDays } from "./testUtils";

const mockTrendPerInterval = (
  { fromEpoch, toEpoch }: TimeWindow,
  optimizationScore: number
): CostOptimizationScoreTrendResponseIntervalsInner => {
  const totalDays = getTotalDays(fromEpoch, toEpoch);
  const intervals = getDateIntervals({
    intervalsNum: totalDays,
    endTime: toEpoch,
    bucketTimeFrame: getBucketTimeFrame(),
  });
  const sumByDay = Array.from({ length: intervals.length }).map((_, i) => ({
    ts: intervals[i],
    value: getRandomInt(10, 100),
  }));
  return {
    optimizationScore,
    sumByDay,
    bucketTimeFrame: "24h",
  };
};

type MockCostOptimizationTrendsParams =
  CostApiGetCostOptimizationTrendsRequest & {
    randomizeCostOptimizationScore?: boolean;
  };
export const mockCostOptimizationTrends = ({
  costOptimizationInterval,
  randomizeCostOptimizationScore = true,
}: MockCostOptimizationTrendsParams): CostOptimizationScoreTrendResponse => {
  const optimizationScores = randomizeCostOptimizationScore
    ? [getRandomInt(10, 100), getRandomInt(10, 100)]
    : [50, 75];

  const intervals = Array.from({ length: costOptimizationInterval.length }).map(
    (_, i) =>
      mockTrendPerInterval(costOptimizationInterval[i], optimizationScores[i])
  );
  return {
    intervals,
  };
};
