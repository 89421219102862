import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { NodeStatus } from "../../../generated/workspacesApi";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { DistributionItemWithTooltip } from "@/components/ClustersView/components/DistributionItemWithTooltip";
import { getMinDigitsWidth } from "@/components/ClustersView/utils";

const { ReadyNodes, NotReadyNodes } = AriaLabels.ClustersView.NodesCell;

const ITEM_MIN_DIGITS_WIDTH = getMinDigitsWidth(3);

const Container = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const PreservedGroupSpace = styled.div`
  width: calc(10px + ${ITEM_MIN_DIGITS_WIDTH});
`;

export const NodesCell: React.FC<{ nodes: NodeStatus }> = ({ nodes }) => {
  if (!nodes.count) return null;

  return (
    <Container>
      {nodes.ready ? (
        <DistributionItemWithTooltip
          count={nodes.ready}
          name="Ready"
          statusColor={muiColors.green[500]}
          ariaLabel={ReadyNodes}
          minWidth={ITEM_MIN_DIGITS_WIDTH}
        />
      ) : (
        <PreservedGroupSpace />
      )}
      {nodes.notReady ? (
        <DistributionItemWithTooltip
          count={nodes.notReady}
          name="Not Ready"
          statusColor={muiColors.red[500]}
          ariaLabel={NotReadyNodes}
          minWidth={ITEM_MIN_DIGITS_WIDTH}
        />
      ) : (
        <PreservedGroupSpace />
      )}
    </Container>
  );
};
