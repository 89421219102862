import { FetchingStateProps, EventsViewStore } from "./eventsViewStore";

// getters
export const fetchingStateSelector = (
  state: EventsViewStore
): FetchingStateProps => state.fetchingState;

// setters
export const setFetchingStateSelector = (
  state: EventsViewStore
): ((fetchingState: {
  key: keyof FetchingStateProps;
  value: boolean;
}) => void) => state.setFetchingState;

export const resetFetchingStateSelector = (
  state: EventsViewStore
): (() => void) => state.resetFetchingState;
