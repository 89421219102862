import { useCallback, useLayoutEffect, useState } from "react";
import { addMilliseconds, subMilliseconds } from "date-fns";

import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";
import { SetTimeWindow, TimeWindow } from "@/shared/types/TimeWindow";

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 20;
export const TIME_PADDING = 0.02;
export const INFRA_SWIMLANE_PREFIX = "Infrastructure";

const isInfraSwimlane = ({ label }: LineGroup) =>
  (Array.isArray(label) ? label[0].prefix : label.prefix) ===
  INFRA_SWIMLANE_PREFIX;

const countSwimlanesEvents = (swimlanes: LineGroup[]) => {
  return swimlanes.reduce((acc, swimlane) => acc + swimlane.events.length, 0);
};
const getEarliestStartTime = (swimlanes: LineGroup[]) =>
  swimlanes.reduce<Date>((earliest, swimlane) => {
    const swimlaneMinStartTime = swimlane.events.reduce((min, event) => {
      return event.startTime < min ? event.startTime : min;
    }, earliest);
    return swimlaneMinStartTime < earliest ? swimlaneMinStartTime : earliest;
  }, new Date());
const getLatestEndTime = (swimlanes: LineGroup[]) =>
  swimlanes.reduce<Date>((latest, swimlane) => {
    const swimlaneMaxEndTime = swimlane.events.reduce((max, event) => {
      return event.endTime > max ? event.endTime : max;
    }, latest);
    return swimlaneMaxEndTime > latest ? swimlaneMaxEndTime : latest;
  }, new Date(0));

export const useSwimlanesPagination = (
  swimlanes: LineGroup[],
  paginateSwimlanes: boolean,
  setTimeWindow: SetTimeWindow,
  setPageDefaultTimeWindow?: (tw: Partial<TimeWindow>) => void
) => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [swimlanesPerPage, setSwimlanesPerPage] = useState<LineGroup[]>([]);

  const totalRows = swimlanes.length;
  const shouldPaginate = paginateSwimlanes && totalRows > DEFAULT_PAGE_SIZE;

  const getSwimlanesPerPage = useCallback(
    (page: number, rowsPerPage: number) => {
      let swimlanesPerPage = swimlanes;
      if (shouldPaginate) {
        const pageOffset =
          totalRows === 0 ? 0 : (page * rowsPerPage) % totalRows;
        swimlanesPerPage = swimlanes.slice(
          pageOffset,
          pageOffset + rowsPerPage
        );
      }
      const infraIndex = swimlanesPerPage.findIndex(isInfraSwimlane);
      if (infraIndex !== -1) {
        swimlanesPerPage = [
          swimlanesPerPage[infraIndex],
          ...swimlanesPerPage.slice(0, infraIndex),
          ...swimlanesPerPage.slice(infraIndex + 1),
        ];
      }
      return swimlanesPerPage.reverse();
    },
    [shouldPaginate, swimlanes, totalRows]
  );

  const getPageTimeWindow = useCallback((swimlanesPerPage: LineGroup[]) => {
    const earliestStartTime = getEarliestStartTime(swimlanesPerPage);
    const latestEndTime = getLatestEndTime(swimlanesPerPage);
    const paddingTime =
      (latestEndTime.getTime() - earliestStartTime.getTime()) * TIME_PADDING;
    return {
      start: subMilliseconds(earliestStartTime, paddingTime),
      end: addMilliseconds(latestEndTime, paddingTime),
    };
  }, []);

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      const swimlanesPerPage = getSwimlanesPerPage(newPage, rowsPerPage);
      setSwimlanesPerPage(swimlanesPerPage);
      if (shouldPaginate) {
        const pageTimeWindow = getPageTimeWindow(swimlanesPerPage);
        setTimeWindow(pageTimeWindow);
        setPageDefaultTimeWindow?.(pageTimeWindow);
      }
    },
    [
      getPageTimeWindow,
      getSwimlanesPerPage,
      rowsPerPage,
      setPageDefaultTimeWindow,
      setTimeWindow,
      shouldPaginate,
    ]
  );

  const onRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      setPage(DEFAULT_PAGE);
      setRowsPerPage(newRowsPerPage);
      const swimlanesPerPage = getSwimlanesPerPage(
        DEFAULT_PAGE,
        newRowsPerPage
      );
      setSwimlanesPerPage(swimlanesPerPage);
      const pageTimeWindow = getPageTimeWindow(swimlanesPerPage);
      setTimeWindow(pageTimeWindow);
      setPageDefaultTimeWindow?.(pageTimeWindow);
    },
    [
      getPageTimeWindow,
      getSwimlanesPerPage,
      setPageDefaultTimeWindow,
      setTimeWindow,
    ]
  );

  //initial page load
  useLayoutEffect(() => {
    if (
      page === DEFAULT_PAGE &&
      countSwimlanesEvents(
        shouldPaginate ? swimlanes.slice(0, rowsPerPage) : swimlanes
      ) !== countSwimlanesEvents(swimlanesPerPage)
    ) {
      onPageChange(DEFAULT_PAGE);
    }
  }, [
    onPageChange,
    page,
    rowsPerPage,
    shouldPaginate,
    swimlanes,
    swimlanesPerPage,
  ]);

  return {
    swimlanesPerPage,
    page,
    setPage: onPageChange,
    rowsPerPage,
    setRowsPerPage: onRowsPerPageChange,
    totalRows,
    shouldShowPagination: shouldPaginate,
  };
};
