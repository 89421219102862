import { Close24 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

import { usePodExecSessionsContext } from "../../shared/context/PodExecSessionsProvider";

import { FlexContainer, Row } from "./MinimizedRowSharedComponents";
import { MinimizedShellText } from "./MinimizedShellText";

const ClickableRow = styled(FlexContainer)`
  cursor: pointer;
`;

interface MinimizedShellRowProps {
  sessionId: string;
  onMaximize: (id: string) => void;
  onClose: (id: string) => void;
}

export const MinimizedShellRow: React.FC<MinimizedShellRowProps> = ({
  sessionId,
  onMaximize,
  onClose,
}) => {
  const { getSessionProps } = usePodExecSessionsContext();
  const { podName, containerName } = getSessionProps(sessionId);

  return (
    <Row>
      <ClickableRow
        onClick={() => onMaximize(sessionId)}
        aria-label="maximize window pod exec session"
      >
        <MinimizedShellText podName={podName} containerName={containerName} />
      </ClickableRow>
      <Close24
        color="white"
        onClick={() => onClose(sessionId)}
        aria-label="close window pod exec session"
      />
    </Row>
  );
};
