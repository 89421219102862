import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { ViolationsGroupBy } from "../../../../ReliabilityTypes";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { useReliabilityDataInUrl } from "../../../../hooks/useReliabilityDataInUrl";

import { useGetGroupByOptions } from "@/components/reliability/hooks/useGetGroupByOptions";
import {
  useDefaultGroupBy,
  useGetGroupBy,
} from "@/components/reliability/hooks/useGetGroupBy";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

export const GroupBySelector: React.FC = () => {
  const [{ groupBy }, setDataInUrl] = useReliabilityDataInUrl();

  const currentGroupBy = useGetGroupBy();
  const defaultGroupBy = useDefaultGroupBy();

  const options = useGetGroupByOptions();

  useEffect(() => {
    if (!groupBy) {
      setDataInUrl({ groupBy: defaultGroupBy }, true);
    }
  }, [defaultGroupBy, groupBy, setDataInUrl]);

  const onChange = (selectedOption?: MuiSelectionOption<string>) => {
    if (!selectedOption) return;
    setDataInUrl({ groupBy: selectedOption.value as ViolationsGroupBy });
  };

  const value = useMemo(() => {
    return options.find((option) => option.value === currentGroupBy);
  }, [currentGroupBy, options]);

  return (
    <Container>
      <Typography variant={"body2"}>Group by:</Typography>
      <SingleSelect
        options={options}
        width="200px"
        onChange={onChange}
        value={value}
        ariaLabel={reliabilityArialLabels.groupBySelector}
      />
    </Container>
  );
};
