import { User } from "@auth0/auth0-react";
import { cloneDeep } from "lodash";
import { useEffect } from "react";

const useSetWindowsEmail = (user: User | undefined): void => {
  useEffect(() => {
    window.komodor = Object.assign(window.komodor || {}, {
      userEmail: cloneDeep(user?.email),
    });
  }, [user]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useSetWindowsEmail;
