import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  ApiServerUrlUpdateResponse,
  apiV1RbacClusterStatusPostUrl,
  RbacClusterStatusApiApiV1RbacClusterStatusPostRequest,
} from "@/generated/auth";

const RBAC_POLICY_ACTIONS_PATH = "/rbac-cluster-status";

const updateRbacClusterStatusApiServerUrl = async (
  apiClient: AxiosInstance,
  params: RbacClusterStatusApiApiV1RbacClusterStatusPostRequest
): Promise<ApiServerUrlUpdateResponse> => {
  const { data } = await apiClient.post(
    apiV1RbacClusterStatusPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.apiServerUrlUpdateRequest
  );
  return data;
};

export const useUpdateRbacClusterStatusApiServerUrl = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [RBAC_POLICY_ACTIONS_PATH],
    (params: RbacClusterStatusApiApiV1RbacClusterStatusPostRequest) =>
      updateRbacClusterStatusApiServerUrl(apiClient, params)
  );
};
