import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined
): IStep => {
  const output = issue?.results?.correlatedDeploys?.output;
  const relatedServicesDeploysOutput =
    issue?.results?.relatedServicesDeploys?.output;
  const shouldShowStep =
    (output?.correlatedDeploys && output?.correlatedDeploys?.length > 0) ||
    (relatedServicesDeploysOutput?.downstreamRelatedDeploys &&
      relatedServicesDeploysOutput?.downstreamRelatedDeploys?.length > 0) ||
    (relatedServicesDeploysOutput?.upstreamRelatedDeploys &&
      relatedServicesDeploysOutput?.upstreamRelatedDeploys?.length > 0);

  return {
    type: AvailabilityInvestigationCheckType.CorrelatedDeploys,
    visible: true,
    visited: false,
    disabled: !shouldShowStep,
  };
};
