import { isEmpty } from "lodash";

import Selected from "../Interfaces/Selected";
import { dispatchEvent } from "../../../hooks/analytics";

export const updateSelected = (
  state: Selected,
  category: string,
  value: string
): Selected => {
  const selectedClone: { [name: string]: { [value: string]: boolean } } =
    state === null
      ? { [category]: {} }
      : {
          ...state,
          [category]: { ...state[category] },
        };
  if (selectedClone[category][value]) {
    delete selectedClone[category][value];
    if (isEmpty(selectedClone[category])) {
      delete selectedClone[category];
    }
    dispatchEvent("EventsView_Filter_Uncheck", { category, value });
  } else {
    selectedClone[category][value] = true;
    dispatchEvent("EventsView_Filter_Check", { category, value });
  }

  return selectedClone as Selected;
};
