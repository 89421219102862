import { muiPalette } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import styled from "styled-components";

export const VerticallyDividedBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;
`;
export const HalfWidthBox = styled(Box)`
  width: 50%;
  height: 100%;
`;
export const BorderedBox = styled(Box)`
  border: 1px solid ${muiPalette.divider};
  height: 100%;
  padding: 0.5rem 1rem;
`;
export const LTRBoxSpaced = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LTRBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
