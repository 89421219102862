import { EnrichedMessage, K8sResourceRegex } from "../types";
import { KubernetesSecretsResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesSecretsResource";
import { K8sResourceToken, StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";
import { getStringAfterLastDelimiter } from "./utils";

export class SecretNotFoundEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`secret "${K8sResourceRegex}" not found`, "i"));
  }

  searchKey(): string {
    return "secret";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const secret = getStringAfterLastDelimiter(match[1], "/");

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("The service is configured to use a"),
        new K8sResourceToken("Secret", KubernetesSecretsResource.Kind, secret),
        new StringToken("which does not exist."),
      ],
    };
  }
}

export class MountVolumeSetUpForSecretFailedEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `MountVolume.SetUp failed for volume "${K8sResourceRegex}" : secret "${K8sResourceRegex}" not found`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "MountVolume.SetUp failed for volume";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const secret = getStringAfterLastDelimiter(match[2], "/");

    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("The service is configured to use a"),
        new K8sResourceToken("Secret", KubernetesSecretsResource.Kind, secret),
        new StringToken("which does not exist."),
      ],
    };
  }
}

export class SecretCouldNotFindKeyEnricher extends BaseEnricher {
  constructor() {
    super(
      new RegExp(`couldn't find key (.+) in secret ${K8sResourceRegex}`, "i")
    );
  }

  searchKey(): string {
    return "couldn't find key";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    const keyName = match[1];
    const secret = getStringAfterLastDelimiter(match[2], "/");

    return {
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken("A"),
        new K8sResourceToken("Secret", KubernetesSecretsResource.Kind, secret),
        new StringToken(
          `that the service was configured to use is missing the key "${keyName}".`
        ),
      ],
    };
  }
}
