import { useMemo } from "react";

import { NodeData } from "../../../../ResourceDependenciesPage/types";

export const useCalculateTooltipPlacement = (nodeData: NodeData) => {
  return useMemo(() => {
    const isTarget = nodeData?.connectionPoints?.target;
    const isSource = nodeData?.connectionPoints?.source;
    if (isTarget && !isSource) {
      return "right";
    }
    if (isSource && !isTarget) {
      return "left";
    }
    if (isSource && isTarget) {
      return "bottom";
    }
    return "left";
  }, [nodeData]);
};
