import React, { useEffect } from "react";
import { TaskType } from "komodor-types";
import semver from "semver";
import MuiButton from "@mui/material/Button";
import styled from "styled-components";

import useAgentTask from "../../shared/hooks/useAgentTask/useAgentTask";

import { KubernetesAgentInfo } from "./types";

const MINIMUM_AGENT_VERSION = "0.2.23";

const VerticalCenter = styled.div`
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  text-align: center;
`;

export const RenderRestartModal: React.FC<{
  agent: KubernetesAgentInfo;
  handleClose: () => void;
  handleFailure: () => void;
  handleExecuting: (executing: boolean) => void;
}> = ({ agent, handleClose, handleFailure, handleExecuting }) => {
  const { execute, result, deniedObject, isFetching } = useAgentTask(
    agent.id,
    TaskType.RESTART_AGENT
  );
  useEffect(() => {
    if (isFetching) {
      handleExecuting(true);
    } else {
      handleExecuting(false);
    }
  }, [handleExecuting, isFetching]);

  useEffect(() => {
    if (result && !deniedObject) {
      handleClose();
    }
    if (deniedObject) {
      handleFailure();
    }
  }, [deniedObject, handleClose, result, isFetching, handleFailure]);

  if (
    !agent ||
    semver.gte(
      MINIMUM_AGENT_VERSION,
      agent.configuration.appmetadata?.version ?? ""
    )
  ) {
    return <b>Restart Not Available</b>;
  }
  return (
    <VerticalCenter>
      <MuiButton
        color={"primary"}
        size="large"
        variant={"text"}
        disabled={isFetching}
        onClick={execute}
      >
        Restart
      </MuiButton>
    </VerticalCenter>
  );
};
