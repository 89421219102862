import { AxiosResponse } from "axios";

import {
  CertificateFullFromJSON,
  EntityResourceResponse,
} from "@/generated/addonsApi";

export const selectCertificate = (
  json: AxiosResponse<EntityResourceResponse, unknown>
) => {
  return {
    type: json?.data?.type,
    // eslint-disable-next-line new-cap
    data: CertificateFullFromJSON(json?.data?.data),
  };
};
