import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { useOpenInsightsDrawer } from "../Insights/shared/hooks/appInisghtsHooks";
import { PotentialCostSavingDrawerContent } from "../Insights/insightsCards/PotentialCostSaving/PotentialCostSavingDrawerContent/PotentialCostSavingDrawerContent";
import { useOverviewPageStore } from "../store/overviewPageStore";
import { selectAppInsightsData } from "../store/overviewPageStoreSelectors";
import { useGetPotentialSavingDrawerDescription } from "../Insights/insightsCards/PotentialCostSaving/utils";
import { ID as potentialCostSavingId } from "../Insights/insightsCards/PotentialCostSaving/PotentialCostSaving";

const StyledButton = styled(Button)`
  && {
    font-size: 11px;
  }
`;

export const OptimizeScoreButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const openInsightsDrawer = useOpenInsightsDrawer();

  const {
    potentialCostSavings: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const description = useGetPotentialSavingDrawerDescription(data);

  const onClick = () => {
    openInsightsDrawer({
      title: description,
      component: <PotentialCostSavingDrawerContent />,
      ariaLabel: potentialCostSavingId,
      id: potentialCostSavingId,
    });
  };

  return (
    <StyledButton variant={"text"} onClick={onClick} disabled={disabled}>
      Optimize
    </StyledButton>
  );
};
