import { useIsAgentsInProgress } from "./useIsAgentsInProgress";
import { useIsAtleastOneAgentConnected } from "./useIsAtleastOneAgentConnected";

export const useIsLoadingAgentStatuses = (): boolean => {
  const isAgentsInProgress = useIsAgentsInProgress();
  const atleastOneAgentConnected = useIsAtleastOneAgentConnected();
  const isLoading = isAgentsInProgress && !atleastOneAgentConnected;

  return isLoading;
};
