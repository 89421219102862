import { ImpactGroupIdentifier } from "../../../../../../../../generated/reliabilityApi";
import { useViolationsTableContext } from "../context/useViolationsTableContext";

import { useIsServiceScope } from "./useIsServiceScope";

export const useShouldShowSearchField = () => {
  const { impactGroupIdentifier } = useViolationsTableContext();
  const isServiceScope = useIsServiceScope();

  return (
    !isServiceScope &&
    impactGroupIdentifier !== ImpactGroupIdentifier.EolDeprecationOutdated
  );
};
