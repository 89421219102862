import React, { useEffect, useState } from "react";

import getDesktopUserCredentials from "../../../shared/context/electron/getDesktopUserCredentials";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";

import { SilentSignup } from "./silentSignup";
import { SilentLogin } from "./silentLogin";

interface DesktopAuthProps {
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DesktopAuth: React.FC<DesktopAuthProps> = ({ setAccessToken }) => {
  const [isSignupFlow, setIsSignupFlow] = useState<boolean>();

  const setAccessTokenWithAnalytics = (newAccessToken: string | null) => {
    if (newAccessToken) {
      dispatchEvent(AnalyticEvents.Desktop.Opend);
    }

    setAccessToken(newAccessToken);
  };

  useEffect(() => {
    const getUserCredentials = async () => {
      const userCredentials = await getDesktopUserCredentials();

      if (userCredentials) {
        setIsSignupFlow(false);
        return;
      }
      setIsSignupFlow(true);
    };

    getUserCredentials().catch((err) => {
      throw new Error(err);
    });
  }, []);
  if (isSignupFlow === undefined) return <div />;

  if (isSignupFlow) return <SilentSignup setAccessToken={setAccessToken} />;

  return <SilentLogin setAccessToken={setAccessTokenWithAnalytics} />;
};
