import { ResourceTableModelRow } from "komodor-types";

import { RowSelectionController } from "../../../../../../../common/ResourceListTable/types";

export const getUnselectableRowNames = (
  rows: ResourceTableModelRow[],
  rowSelection?: RowSelectionController
): string[] | undefined => {
  if (rowSelection) {
    const { selectedRowNames, maxSelectableRows } = rowSelection;

    return selectedRowNames.length === maxSelectableRows
      ? rows
          .map(({ uid }) => uid)
          .filter((rowUid) => !selectedRowNames.includes(rowUid))
      : [];
  }
  return undefined;
};

export const onlySelected = (
  rows: ResourceTableModelRow[],
  selectedRowNames: string[]
): ResourceTableModelRow[] =>
  rows.filter(({ uid: rowUid }) => selectedRowNames.includes(rowUid));
