import React from "react";
import { parseISO } from "date-fns";
import removeMarkdown from "remove-markdown";
import { LaunchDarkly } from "@komodorio/design-system/icons";

import { LaunchdarklyFragment } from "../../../generated/graphql";
import { blueForUI, blueBrand } from "../../../Colors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LaunchdarklyDetails } from "../ProcessList/details/LaunchDarkly";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "./groupEvents";

import EventGroup, { EventBase } from ".";

export type LaunchDarklyEvent = EventBase &
  Omit<LaunchdarklyFragment, keyof EventBase>;

export const launchDarkleyType = "LaunchDarkly";

const toLaunchDarklyEvent = (
  e: LaunchdarklyFragment,
  serviceId: string
): LaunchDarklyEvent => {
  return {
    ...e,
    type: launchDarkleyType,
    eventTime: parseISO(e.eventTime),
    serviceId,
  };
};

export const gropuLaunchdarklyEvents = (
  events: LaunchdarklyFragment[],
  serviceId: string
): EventGroup[] =>
  mapAndSort(withServices(events, serviceId), toLaunchDarklyEvent).map((e) => {
    const group = {
      events: [e],
      backgroundColor: blueForUI,
      fillColor: blueBrand,
      icon: LaunchDarkly,
      id: `${e.serviceId}.${e.id}`,
      type: launchDarkleyType,
      summary: `${e.name}: ${e.summary}`,
      startTime: e.eventTime,
      endTime: e.eventTime,
      details: removeMarkdown(e.description || ""),
      serviceId: e.serviceId,
      status: "Completed",
      renderEventDetails: () => <LaunchdarklyDetails eventGroup={group} />,
    };
    return group;
  });
