import { useEffect, useMemo } from "react";

import { MetricType, CostStrategy } from "../../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectSetRightSizingSummaryState,
  selectRightSizingFilters,
  selectSetRightSizingCPUMetricsState,
  selectSetRightSizingMemoryMetricsState,
  selectRightSizingRecommendationsStrategiesState,
  selectRightSizingSummaryState,
  selectRightSizingMemoryMetricsState,
  selectRightSizingCPUMetricsState,
  selectRightSizingStatsState,
  selectSetRightSizingStatsState,
} from "../../../store/costOptimizationStoreSelectors";
import { generateMockCostRightSizingSummary } from "../../../__tests__/mockData/mockCostRightSizingSummary";
import { generateMockCostRightSizingData } from "../../../__tests__/mockData/mockCostRightSizingMetrics";
import { generateMockCostRightSizingStats } from "../../../__tests__/mockData/mockCostRightSizingStats";
import { shouldUpdateStoreWithResponse } from "../../../utils/costOptimizationUtils";
import {
  COST_RIGHT_SIZING_METRICS_ENDPOINT,
  COST_RIGHT_SIZING_SUMMARY_ENDPOINT,
  COST_RIGHT_SIZING_STATS_ENDPOINT,
} from "../../../../../shared/hooks/metrics-api/requestResponseMaps";
import {
  RightSizingCostDataPoints,
  RightSizingCostSummaryResponse,
  RightSizingCostStatsResponse,
} from "../../../../../generated/metricsApi";
import { useScopeAsServerRequestParam } from "../../../hooks/useScopeAsServerRequestParam";
import { useShouldPauseRequest } from "../../../hooks/useShouldPauseRequest";
import { isDevMode } from "../../../../../shared/utils/isDevMode";
import { hasServerResChanged } from "../../../../../shared/utils/hasServerResChanged";
import { useMetricsApiWithRefreshLogic } from "../../../hooks/useMetricsApiWithRefreshLogic";
import { useSetMockDataEffect } from "../../../hooks/useSetMockDataEffect";

import {
  useRightSizingEpochs,
  useRightSizingTablePaginationMode,
} from "./commonHooks";

export const useFetchRightSizingDataOnFiltersChange = (): void => {
  useFetchRightSizingSummaryData();
  useFetchRightSizingStatsData();
  useFetchRightSizingMetricsData("cpu");
  useFetchRightSizingMetricsData("memory");
};

export const useFetchRightSizingSummaryData = (): void => {
  const scope = useScopeAsServerRequestParam();
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);
  const isPaginationMode = useRightSizingTablePaginationMode();
  const shouldPause = useShouldPauseRequest() || isPaginationMode;

  const currentRightSizingSummaryState = useCostOptimizationStore(
    selectRightSizingSummaryState
  );
  const setRightSizingSummaryState = useCostOptimizationStore(
    selectSetRightSizingSummaryState
  );

  const { fromEpoch, toEpoch } = useRightSizingEpochs();

  const params = useMemo(
    () => ({
      strategy,
      scope,
      fromEpoch,
      toEpoch,
    }),
    [fromEpoch, toEpoch, scope, strategy]
  );

  // simulate server responses
  const mockCostRightSizingSummaryResponse = useMemo(
    () =>
      isDevMode() && params && !isPaginationMode
        ? generateMockCostRightSizingSummary({ clusterNames: scope.clusters })
        : undefined,
    [params, scope.clusters, isPaginationMode]
  );

  const res = useSetMockDataEffect<RightSizingCostSummaryResponse>({
    mockData: mockCostRightSizingSummaryResponse,
    requestParams: params,
  });

  /////////////////////////////

  const serverRes =
    useMetricsApiWithRefreshLogic<RightSizingCostSummaryResponse>(
      COST_RIGHT_SIZING_SUMMARY_ENDPOINT,
      params,
      shouldPause
    );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    if (
      shouldUpdateStoreWithResponse({
        serverRes: dataToUse,
        currentState: currentRightSizingSummaryState,
        params,
      })
    ) {
      setRightSizingSummaryState(dataToUse);
    }
  }, [
    currentRightSizingSummaryState,
    dataToUse,
    params,
    setRightSizingSummaryState,
  ]);
};

export const useFetchRightSizingMetricsData = (
  metricType: MetricType
): void => {
  const rightSizingFilters = useCostOptimizationStore(selectRightSizingFilters);
  const scope = useScopeAsServerRequestParam();
  const currentRightSizingMemoryMetricsState = useCostOptimizationStore(
    selectRightSizingMemoryMetricsState
  );
  const currentRightSizingCPUMetricsState = useCostOptimizationStore(
    selectRightSizingCPUMetricsState
  );

  const setRightSizingMemoryMetricsState = useCostOptimizationStore(
    selectSetRightSizingMemoryMetricsState
  );
  const setRightSizingCPUMetricsState = useCostOptimizationStore(
    selectSetRightSizingCPUMetricsState
  );

  const shouldPause = useShouldPauseRequest();

  const { strategy } = rightSizingFilters;
  const { fromEpoch, toEpoch } = useRightSizingEpochs();

  const params = useMemo(
    () => ({ metricType, strategy, scope, fromEpoch, toEpoch }),
    [fromEpoch, metricType, scope, strategy, toEpoch]
  );

  // simulate server responses
  const mockResponse = useMemo(() => generateMockCostRightSizingData(), []);
  const res = useSetMockDataEffect<RightSizingCostDataPoints>({
    mockData: mockResponse,
    requestParams: params,
  });
  /////////////////////////////

  const serverRes = useMetricsApiWithRefreshLogic<RightSizingCostDataPoints>(
    COST_RIGHT_SIZING_METRICS_ENDPOINT,
    params,
    shouldPause
  );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    const updaterFunc =
      metricType === "cpu"
        ? setRightSizingCPUMetricsState
        : setRightSizingMemoryMetricsState;

    const currentState =
      metricType === "cpu"
        ? currentRightSizingCPUMetricsState
        : currentRightSizingMemoryMetricsState;

    if (hasServerResChanged(dataToUse, currentState)) {
      updaterFunc(dataToUse);
    }
  }, [
    currentRightSizingCPUMetricsState,
    currentRightSizingMemoryMetricsState,
    dataToUse,
    metricType,
    setRightSizingCPUMetricsState,
    setRightSizingMemoryMetricsState,
  ]);
};

export const useGetRowStrategy = (rowId: string | undefined): CostStrategy => {
  const { strategy: pageStrategy } = useCostOptimizationStore(
    selectRightSizingFilters
  );
  const recommendationStrategies = useCostOptimizationStore(
    selectRightSizingRecommendationsStrategiesState
  );
  return useMemo(() => {
    if (!rowId) {
      return pageStrategy;
    }
    return recommendationStrategies[rowId] || pageStrategy;
  }, [rowId, pageStrategy, recommendationStrategies]);
};

export const useFetchRightSizingStatsData = (): void => {
  const scope = useScopeAsServerRequestParam();
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);
  const shouldPause = useShouldPauseRequest();

  const currentRightSizingStatsState = useCostOptimizationStore(
    selectRightSizingStatsState
  );
  const setRightSizingStatsState = useCostOptimizationStore(
    selectSetRightSizingStatsState
  );

  const { fromEpoch, toEpoch } = useRightSizingEpochs();

  const params = useMemo(
    () => ({
      strategy,
      scope,
      fromEpoch,
      toEpoch,
    }),
    [fromEpoch, toEpoch, scope, strategy]
  );

  // simulate server responses
  const mockCostRightSizingStatsResponse = useMemo(
    () =>
      isDevMode() && params ? generateMockCostRightSizingStats() : undefined,
    [params]
  );

  const res = useSetMockDataEffect<RightSizingCostStatsResponse>({
    mockData: mockCostRightSizingStatsResponse,
    requestParams: params,
  });

  /////////////////////////////

  const serverRes = useMetricsApiWithRefreshLogic<RightSizingCostStatsResponse>(
    COST_RIGHT_SIZING_STATS_ENDPOINT,
    params,
    shouldPause
  );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    if (
      shouldUpdateStoreWithResponse({
        serverRes: dataToUse,
        currentState: currentRightSizingStatsState,
        params,
      })
    ) {
      setRightSizingStatsState(dataToUse);
    }
  }, [
    currentRightSizingStatsState,
    dataToUse,
    params,
    setRightSizingStatsState,
  ]);
};
