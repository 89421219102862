import React from "react";
import { InfoCircleOutlined24, Team24 } from "@komodorio/design-system/icons";

import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import eventsIcon from "../../ProcessList/assets/events.svg";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import srcCodeIcon from "./assets/srcCode.svg";
import workloads from "./assets/blueWorkloads.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import {
  CommandText,
  SubTitle,
  DetailsGrid,
  usePreparedEvents,
} from "./common";

interface RollbackServiceEventDetailsProps {
  eventGroup: ActionEventGroup;
}

export const RollbackServiceEventDetails: React.FC<
  RollbackServiceEventDetailsProps
> = ({ eventGroup }) => {
  const {
    events,
    cluster,
    namespace,
    resourceType,
    resourceName,
    email,
    actionResult,
  } = eventGroup;

  const preparedEvents = usePreparedEvents(events);

  const title = eventGroup.status;

  const command = `kubectl -n ${namespace} rollout undo ${resourceType} ${resourceName}`;

  const serviceDetails = [
    {
      title: "Service name:",
      data: resourceName,
    },
    {
      title: "Service type:",
      data: resourceType,
    },
    {
      title: "Namespace:",
      data: namespace,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title}>
        <SubTitle>
          {`${cluster}/${namespace}/${resourceType}/${resourceName}`}
        </SubTitle>
      </EventDetailsTitle>
      <EventDetailsSection
        icon={srcCodeIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Command"
      >
        <CommandText>{command}</CommandText>
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={workloads}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Service"
      >
        <DetailsGrid>
          {serviceDetails.map((e, i) => (
            <React.Fragment key={i}>
              <div>{e.title}</div>
              <div>{e.data}</div>
            </React.Fragment>
          ))}
        </DetailsGrid>
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
