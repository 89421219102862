import { WorkloadMetricsDataPoints } from "../../../../../generated/metricsApi";
import { useWorkloadMetricsTabContext } from "../context/useWorkloadMetricsTabContext";
import { MetricsGraphProps } from "../../../../Metrics/types";
import { graphHeight, timelineTicksNum } from "../../MetricsTab/constants";
import {
  useHasGraphData,
  useTransformWorkloadMetricsData,
} from "../../../../Metrics/hooks/workload/workloadMetricsTabHooks";

import { useLinesList } from "./fetchWorkloadMetricsHooks";

export const useGraphData = (data?: WorkloadMetricsDataPoints) => {
  const { timeWindow, selectedAggregationMetric } =
    useWorkloadMetricsTabContext();

  const transformedData = useTransformWorkloadMetricsData(
    data,
    timeWindow.timeframe
  );
  const hasGraphData = useHasGraphData(data);
  const linesList = useLinesList();
  const graphProps: MetricsGraphProps = {
    data: transformedData,
    disableZoom: true,
    timelineTicksNum: timelineTicksNum,
    showLinesList: linesList,
    graphHeight,
    aggregationType: selectedAggregationMetric,
  };

  return {
    transformedData,
    hasGraphData,
    linesList,
    graphProps,
  };
};
