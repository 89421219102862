import styled, { css } from "styled-components";
import { palette } from "@komodorio/design-system";

export const textStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
`;

export const textLightStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
`;

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  ${textStyle}
  th {
    ${textLightStyle}
    padding: 0.75rem 0.75rem;
    text-align: start;
    color: #8993a9;
    white-space: nowrap;
    border-bottom: 1px solid #d2d3d6;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  td {
    padding: 0.75rem;
    border-bottom: 1px solid ${palette.gray[200]};
  }
`;
