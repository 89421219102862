import { useMemo } from "react";

import { useActiveAgents } from "../../useAgents";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import {
  ClusterWorkspace,
  ClustersGroupWorkspace,
} from "@/generated/workspacesApi";

export const useClusterSuggestions = (): { value: string; label: string }[] => {
  const activeAgents = useActiveAgents();
  const {
    currentWorkspace,
    isClusterWorkspace,
    isClustersGroupWorkspace,
    isLoading,
  } = useWorkspaces();
  return useMemo(() => {
    if (!isLoading && isClusterWorkspace) {
      const clusterName = (currentWorkspace?.value as ClusterWorkspace)
        .clusterName;
      return [{ value: clusterName, label: clusterName }];
    }
    if (!isLoading && isClustersGroupWorkspace && currentWorkspace) {
      const clusterNames =
        (currentWorkspace.value as ClustersGroupWorkspace).clusters ?? [];
      return clusterNames.map((name) => ({ value: name, label: name }));
    }
    return activeAgents.map((a) => {
      return { label: a.clusterName, value: a.clusterName };
    });
  }, [
    activeAgents,
    currentWorkspace,
    isClusterWorkspace,
    isClustersGroupWorkspace,
    isLoading,
  ]);
};
