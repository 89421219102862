import React, { ReactElement } from "react";
import styled from "styled-components";

import { useAppViewsResourcesStore } from "../../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { selectIsLoadingResources } from "../../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { LinesLoader } from "../../../../../../../common/loaders/Line";
import { ResourceType } from "../../../../types/appViewsResourcesTypes";

import { ResourceItem } from "./ResourceItem";
import {
  selectedResourceTypes,
  discoveredResourceTypes,
} from "./constants/resourceTypeToName";
import { ResourceItemsTypeContainer } from "./ResourceItemsTypeContainer";

const Container = styled.div`
  position: relative;
`;
const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
`;

export const ResourcesInventory = (): ReactElement => {
  const isLoadingResources = useAppViewsResourcesStore(
    selectIsLoadingResources
  );
  const selectedResources = selectedResourceTypes.map((resource) => (
    <ResourceItem type={resource as ResourceType} key={resource} />
  ));
  const discoveredResources = discoveredResourceTypes.map((resource) => (
    <ResourceItem type={resource as ResourceType} key={resource} />
  ));

  return (
    <Container>
      <div>
        <ResourceItemsTypeContainer title={"selected"}>
          {selectedResources}
        </ResourceItemsTypeContainer>
        <ResourceItemsTypeContainer title={"discovered"}>
          {discoveredResources}
        </ResourceItemsTypeContainer>
      </div>
      {isLoadingResources && (
        <LoaderContainer>
          <LinesLoader />
        </LoaderContainer>
      )}
    </Container>
  );
};
