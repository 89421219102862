import { GridValueGetterParams } from "@mui/x-data-grid-pro";

import { User } from "@/generated/auth";
import { createFilteredRoleGetter } from "@/components/Settings/Users/createFilteredRoleGetter";
import { ActionsCell as actionsCell } from "@/components/Settings/Users/components/ActionsCell";
import { UserRow, usersTableActions } from "@/components/Settings/Users/types";
import { ROLES_CELL_WIDTH } from "@/components/Settings/Users/const";

const getPermanentRoles = (params: Partial<GridValueGetterParams>) => {
  return createFilteredRoleGetter(params.row as User, false);
};

const getTemporaryRoles = (params: Partial<GridValueGetterParams>) => {
  return createFilteredRoleGetter(params.row as User, true);
};

const getStatus = (params: Partial<GridValueGetterParams>) => {
  return params.row.deletedAt
    ? ""
    : params.row.lastLoginAt
    ? "Active"
    : "Awaiting first login";
};

export const getColumnDefinitions = ({
  onRestore,
  onEdit,
  onDelete,
  onViewPermissions,
}: usersTableActions) => [
  {
    field: "displayName",
    headerName: "Name",
    flex: 1,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.5,
    sortable: false,
  },
  {
    field: "roles",
    headerName: "Roles",
    renderCell: getPermanentRoles,
    sortable: false,
    width: ROLES_CELL_WIDTH,
    resizable: false,
  },
  {
    field: "tempRoles",
    headerName: "Temporary Roles",
    renderCell: getTemporaryRoles,
    sortable: false,
    width: ROLES_CELL_WIDTH,
    resizable: false,
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: getStatus,
    flex: 1,
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    renderCell: (params: UserRow) => {
      return actionsCell({
        params,
        onEdit,
        onDelete,
        onRestore,
        onViewPermissions,
      });
    },
    flex: 1,
    sortable: false,
  },
];
