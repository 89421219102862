import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { blueBrand } from "../../../../../../Colors";
import { TextRegular } from "../../../../../common/typography";
import check from "../../../../installation/kubernetes/assets/check.svg";

import {
  IconStyle,
  StyledStepContent,
  H2MessageTitle,
  StyledButton,
} from "./styles";

const CheckIcon = styled.img.attrs({ src: check, alt: "Check" })`
  ${IconStyle}
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;
const ViewServicesButton = styled(StyledButton)`
  background-color: ${blueBrand};
  padding: 0.35rem 1rem;
`;

const WhiteTextRegular = styled(TextRegular)`
  color: white;
  font-weight: 500;
`;

const ClusterInstalled: React.FC<{
  clusterName: string | null;
  closeModalCallback: () => void;
}> = ({ clusterName, closeModalCallback }) => (
  <StyledStepContent data-e2e-selector="k8s-integration-cluster-installed">
    <CheckIcon />
    <H2MessageTitle>
      Cluster <b>{clusterName && `'${clusterName}'`}</b> <br />
      was added successfully
    </H2MessageTitle>
    <TextRegular>
      Now you can view and track your resources on Komodor
    </TextRegular>
    <Link to={"/main/services"}>
      <ViewServicesButton
        onClick={closeModalCallback}
        data-e2e-selector="k8s-integration-view-resources"
      >
        <WhiteTextRegular>View Resources</WhiteTextRegular>
      </ViewServicesButton>
    </Link>
  </StyledStepContent>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ClusterInstalled;
