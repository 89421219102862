import React, { ReactNode } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

export const CodeTypography: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <Typography variant={"code1"} color={muiColors.gray[800]}>
      {children}
    </Typography>
  );
};
