import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ServiceInfo } from "@/shared/types/ServiceInfo";
import { Dictionary } from "@/shared/types/Dictionary";

type GetServiceIdFromKomodorUidParams = {
  komodorUid?: string;
  serviceShouldExist?: boolean;
  accountName: string;
  servicesDic: Dictionary<ServiceInfo>;
};

export const getServiceIdFromKomodorUid = ({
  komodorUid,
  serviceShouldExist,
  accountName,
  servicesDic,
}: GetServiceIdFromKomodorUidParams) => {
  if (!komodorUid) return;
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const serviceId = `${accountName}.${cluster}-${namespace}.${name}`;

  if (servicesDic[serviceId] || !serviceShouldExist)
    return { serviceId, serviceKind: kind };

  return undefined;
};
