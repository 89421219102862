import React, { useMemo } from "react";

import { DescribeDateItem, DescribeTextItem } from "./common/DescribeItem";
import { DescribePanel } from "./common/DescribeSection";
import { DescribeKeyValueItem } from "./common/DescribeTagListItem";
import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

// eslint-disable-next-line import/no-cycle
import CertManagerCertificateRequests from "@/components/ResourceView/resources/certManagerCertificateRequests";
import { Dictionary } from "@/shared/types/Dictionary";

interface Props {
  resource: CertManagerCertificateRequests;
}

export const CertManagerCertificateRequestsDetailsTab: React.FC<Props> = ({
  resource,
}) => {
  const fullObj = resource.fullObj;
  const { creationTimestamp, name, labels, annotations } =
    fullObj.metadata ?? {};

  const usagesAsDictionary = useMemo(() => {
    if (!fullObj.spec?.usages) return null;
    return fullObj.spec.usages.reduce<Dictionary<string>>((acc, usage) => {
      acc[usage] = usage;
      return acc;
    }, {});
  }, [fullObj.spec?.usages]);

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        {usagesAsDictionary && (
          <DescribeKeyValueItem name="Usages" value={usagesAsDictionary} />
        )}
      </DescribePanel>
    </DescribeTabContentWrapper>
  );
};
