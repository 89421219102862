import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/Insight";
import { getColor } from "../../../../../Status";
import EventGroup from "../../../../../../EventGroup";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import ActionEventGroup, {
  groupActionEvents,
} from "../../../../../../EventGroup/actionsEvent/ActionsEventGroup";
import { useQueryWithVariables } from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useManualActionInsightByIdQuery } from "../../../../../../../../generated/graphql";
import { ManualActionInsightDataParsed } from "../../common/types";

export const LATEST_ACTIONS_TOOLTIP = "latest-actions-tooltip";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useManualActionEventGroupById = ({
  eventId,
}: {
  eventId?: string;
}): ActionEventGroup | undefined => {
  const variables = useMemo(() => (eventId ? { eventId } : null), [eventId]);

  const minifiedRunById = useQueryWithVariables(
    useManualActionInsightByIdQuery,
    variables
  );
  const correlatedManualActionEvent =
    minifiedRunById?.event_actions?.[0] ?? null;
  const correlatedIssueEvent = useMemo(() => {
    if (!correlatedManualActionEvent) return undefined;
    const correlatedManualActionEventEventGroups = groupActionEvents([
      correlatedManualActionEvent,
    ]);
    return correlatedManualActionEventEventGroups?.[0];
  }, [correlatedManualActionEvent]);
  return correlatedIssueEvent;
};

export const LatestManualActionsTooltip: React.FC<{
  manualActions: ManualActionInsightDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
}> = ({ manualActions, issueStartTime, setCorrelatedEvent }) => {
  const [eventId, setEventId] = useState<string>();
  const correlatedIssue = useManualActionEventGroupById({
    eventId,
  });
  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedIssue) {
        setCorrelatedEvent(correlatedIssue);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedIssue, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedIssue) {
      setCorrelatedEvent(correlatedIssue);
    }
  }, [correlatedIssue, setCorrelatedEvent]);
  return (
    <Tooltip id={LATEST_ACTIONS_TOOLTIP} variant="light">
      <InsightTooltipContainer
        aria-label={AriaLabels.AvailabilityTriage.ActionsInsightTooltip}
      >
        {manualActions.map((mAction, i) => (
          <React.Fragment key={mAction.id}>
            <InsightTooltipDetails>
              <Typography>Started</Typography>
              <Typography bold>
                {formatDistance(issueStartTime, mAction.startTime)} before
                current issue
              </Typography>
              <Typography>Change</Typography>
              <Typography bold>{mAction.summary}</Typography>
              <Typography>User</Typography>
              <Typography bold>{mAction.email}</Typography>
              <Typography>Status</Typography>
              <Typography variant="uppercase" color={getColor(mAction.status)}>
                {mAction.status}
              </Typography>
              <Link
                onClick={() => handleClick(mAction.id)}
                aria-label={
                  AriaLabels.AvailabilityTriage.ActionsInsightTooltipLink
                }
              >
                View details
              </Link>
            </InsightTooltipDetails>
            {i < manualActions.length - 1 && <Divider spacing="1rem" />}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
