import { NetworkMapGraphResponse } from "../../../../../generated/resourcesApi";

export const mockNodes1: NetworkMapGraphResponse = {
  nodes: [
    "Deployment|production|hugh|correlation-engine",
    "Deployment|production|hugh|event-puller-service",
    "Deployment|production|hugh|rest-api",
    "Deployment|production|hugh|auth",
    "Deployment|production|hugh|web-ui",
  ],
  edges: [
    {
      source: "Deployment|production|hugh|event-puller-service",
      destination: "Deployment|production|hugh|correlation-engine",
    },
    {
      source: "Deployment|production|hugh|correlation-engine",
      destination: "Deployment|production|hugh|rest-api",
    },
    {
      source: "Deployment|production|hugh|correlation-engine",
      destination: "Deployment|production|hugh|auth",
    },
    {
      source: "Deployment|production|hugh|correlation-engine",
      destination: "Deployment|production|hugh|web-ui",
    },
  ],
};
