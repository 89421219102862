import { OptionType } from "@komodorio/design-system/deprecated";
import { Container } from "komodor-types";

import { UseAgentTaskResult } from "../../../../shared/hooks/useAgentTask/useAgentTask";

export interface ConfigureModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  defaultContainerName?: string;
  containers?: Container[];
}

export const enum ResourceType {
  Cpu = "cpu",
  Memory = "memory",
}

export const enum ResourceConfigurationType {
  Requests = "requests",
  Limits = "limits",
}

export type HandleResourceChangeProps = (
  resourceConfigType: ResourceConfigurationType,
  resourceType: ResourceType,
  newValue: string
) => void;

export interface ConfigureModalContentProps extends ConfigureModalProps {
  showMessageActionSent: boolean;
  setShowMessageActionSent: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  isModalOpen: boolean;
  containers: Container[];
  actionSent: boolean;
  isError: boolean;
  isResourcesChanged: boolean;
  executeAndReport: () => void;
  options: OptionType<string>[];
  handleResourceChange: HandleResourceChangeProps;
  handleResetContainer: () => void;
  selectedContainer: Container | undefined;
  setSelectedContainer: React.Dispatch<
    React.SetStateAction<Container | undefined>
  >;
  containersInputValidation: Container[] | undefined;
  errorOutput: string;
  getContainersDeniedObject: UseAgentTaskResult["deniedObject"];
  updateContainersDeniedObject: UseAgentTaskResult["deniedObject"];
  setActionSent: React.Dispatch<React.SetStateAction<boolean>>;
}
