import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Cursor, Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import { INVESTIGATION } from "../../../../../../../routes/routes";
import { setLastUrlSelector } from "../../../../../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useInvestigationModeStore } from "../../../../../../../../shared/store/investigationModeStore/investigationModeStore";
import { AvailabilityInvestigationCheckType } from "../../../../../../../InvestigationMode/availability/types";

export const NoisyNeighborsInsight: React.FC<{ runId: string }> = ({
  runId,
}) => {
  const setLastUrlInStore = useInvestigationModeStore(setLastUrlSelector);
  const navigate = useNavigate();
  const onInsightClick = useCallback(() => {
    setLastUrlInStore(`${window.location.pathname}${window.location.search}`);
    navigate(
      `/${INVESTIGATION}/${runId}/${AvailabilityInvestigationCheckType.NoisyNeighbors}`
    );
  }, [navigate, runId, setLastUrlInStore]);

  return (
    <InsightContainer>
      <Insight isCursorPointer={false}>
        <DashedText onClick={onInsightClick}>
          <Cursor cursor="pointer">Suspected noisy neighbors</Cursor>
        </DashedText>{" "}
        were detected
      </Insight>
    </InsightContainer>
  );
};
