import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1KubeconfigsPostUrl,
  ClusterList,
} from "../../../../../generated/auth";

const KUBECONFIGS_PATH = "/kubeconfigs";

const requestKubeconfig = async (
  apiClient: AxiosInstance,
  params: ClusterList
): Promise<string> => {
  const { data } = await apiClient.post(
    apiV1KubeconfigsPostUrl(
      { clusterList: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
  return data;
};

export const useRequestKubeconfig = () => {
  const apiClient = useAuthApiClient();
  return useMutation([KUBECONFIGS_PATH], (params: ClusterList) =>
    requestKubeconfig(apiClient, params)
  );
};
