import React from "react";
import Box from "@mui/material/Box";

import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { PageContainer } from "@/components/k8sAddons/styles";
import {
  EXTERNAL_DNS_DEFAULT_SORT_BY_DIRECTION,
  EXTERNAL_DNS_DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/externalDns/constants";
import { Filters } from "@/components/k8sAddons/addons/externalDns/Filters";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { Table } from "@/components/k8sAddons/addons/externalDns/Table";
import { IfSupportedEntityForWorkspaceKind } from "@/components/k8sAddons/components/IfSupportedEntityForWorkspaceKind";

export const ExternalDNS: React.FC = () => {
  return (
    <FiltersContextProvider
      urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
      searchTypeFilterOperator={Operator.Like}
    >
      <AddonContextProvider
        addonType={Addon.ExternalDns}
        addonEntity={Entity.Operator}
        defaultSortModel={{
          field: EXTERNAL_DNS_DEFAULT_SORT_BY_FIELD,
          sort: EXTERNAL_DNS_DEFAULT_SORT_BY_DIRECTION,
        }}
      >
        <ResponsiveLayout>
          <Box sx={{ paddingBlock: "24px" }}>
            <PageContainer>
              <IfSupportedEntityForWorkspaceKind>
                <Filters />
                <Table />
              </IfSupportedEntityForWorkspaceKind>
            </PageContainer>
          </Box>
        </ResponsiveLayout>
      </AddonContextProvider>
    </FiltersContextProvider>
  );
};
