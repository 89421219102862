import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import Resource from "../../ResourceView/resources";
import { SupportedKubernetesResources } from "../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { StatusTag } from "../../ResourceView/tabs/DescribeTab/common/StatusTag";

export interface EnrichedResourceTooltipProps {
  resource?: Resource;
  lastResourceStatus?: {
    name: string;
    kind: string;
    status: string;
    isDeleted: boolean;
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: flex-start;
  gap: 8px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const EnrichedResourceTooltip: React.FC<
  EnrichedResourceTooltipProps
> = ({ resource, lastResourceStatus }) => {
  if (!resource && !lastResourceStatus) {
    return null;
  }

  const Icon =
    resource?.icon ??
    SupportedKubernetesResources.find(
      (resource) => resource.Kind === lastResourceStatus?.kind
    )?.Icon;

  const kind = resource?.kind ?? lastResourceStatus?.kind;
  const name = resource?.name ?? lastResourceStatus?.name;
  const isDeleted = lastResourceStatus?.isDeleted ?? false;

  return (
    <Container>
      <Line>
        {Icon && (
          <Icon
            style={{
              fill: muiColors.gray[500],
              width: "24px",
              height: "24px",
            }}
          />
        )}
        <Typography variant={"h5"} color={muiColors.gray[500]}>
          {kind}
        </Typography>
      </Line>
      <Line>
        <Typography
          variant={"h5"}
          color={muiColors.gray[800]}
          sx={{
            textDecoration: isDeleted ? "line-through" : "none",
          }}
        >
          {name}
        </Typography>
        {resource ? (
          resource?.renderStatus?.()
        ) : lastResourceStatus?.status ? (
          <StatusTag
            value={
              lastResourceStatus?.isDeleted
                ? "Deleted"
                : lastResourceStatus?.status
            }
          />
        ) : null}
      </Line>
    </Container>
  );
};
