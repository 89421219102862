export const getPageLabel = (
  itemOffset: number,
  pageSize: number,
  listSize: number
): string => {
  const from = listSize === 0 ? 0 : itemOffset + 1;
  const to = Math.min(itemOffset + pageSize, listSize);
  return `Displaying ${from}-${to} of
        ${listSize} items`;
};
