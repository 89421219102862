/* eslint-disable max-lines */
import React, { useMemo, useState } from "react";
import { sortBy } from "lodash";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadHealthyPodsCheck, {
  CustomPodReason,
} from "../../WorkloadHealthyPodsCheck";
import { BoldGrayText } from "../../../CheckDetailsDrawer";
import {
  SmallPadding,
  StyledFontTable,
  StyledTitle,
  LongTableContainer,
  WrappedRows,
  ColoredLogsIcon,
  NoUnderlinedLink,
} from "../../../../../common/styles";
import Arrow from "../../../../../../common/ProcessList/Arrow";
import { gray10, gray6 } from "../../../../../../../Colors";
import { PodOutputWithLogs } from "../../../../CommonTypes";
import { FlexColumns } from "../../../../../../common/FlexContainers";
import {
  GraySmallLightShortText,
  SmallLightShortText,
  RowFieldContainer,
} from "../../../../../monitorsConfiguration/common/styles";
import {
  ContainerSectionFull,
  PodsEvents,
} from "../../../../../common/availabilityComponents";
import Checkbox from "../../../../../../common/controls/Checkbox";
import { MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY } from "../../../../../../../shared/config/urlSearchParamsKeys";
import { useCurrentService } from "../../../../../../../shared/hooks/useCurrentService";
import SnapshotInfoDetail from "../../../../../common/availabilityComponents/SnapshotInfoDetail";
import AvailabilityUpgradeAgent from "../../../../../common/availabilityComponents/AvailabilityUpgradeAgent";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const PodRowContainer = styled.tr<{ isSelected?: boolean; isGrayed?: boolean }>`
  cursor: pointer;
  ${({ isSelected, isGrayed }) =>
    isSelected
      ? `background-color: #E9F7FF;`
      : isGrayed
      ? `background-color: ${gray6};`
      : "background-color: white"};
`;
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function addToMapCounter<T>(map: Map<T, number>, key: T): void {
  map.set(key, (map.get(key) ?? 0) + 1);
}
const MarginCheckBox = styled(Checkbox)`
  margin: 0.3rem 0.6rem 0.3rem 0;
`;
export const FixedWidth = styled.span`
  width: 6.4rem;
`;
const WorkloadHealthyPodsDetails: React.FC<{
  check: WorkloadHealthyPodsCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const pods = useMemo(() => {
    return checkOutput?.pods ?? [];
  }, [checkOutput?.pods]);
  const reasons = useMemo(() => {
    const map = new Map<string, number>();
    pods.forEach((p) => {
      if (p.calculatedReason) {
        addToMapCounter(map, p.calculatedReason);
      }
    });
    return Array.from(map.entries());
  }, [pods]);
  const [selectedPodName, setSelectedPodName] = useStateInSearchParams(
    MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY
  );
  const selectedPod = useMemo(
    () => pods.find((p) => p.name === selectedPodName),
    [pods, selectedPodName]
  );
  const [selectedFilters, setSelectedFilters] = useState(
    new Map(reasons.map(([reason]) => [reason, reason !== "Healthy"]))
  );
  const handleFilterChanged = (option: string) => {
    setSelectedFilters(
      new Map(selectedFilters.set(option, !selectedFilters.get(option)))
    );
  };
  const filteredPods = useMemo(() => {
    return pods.filter((p) => selectedFilters.get(p?.calculatedReason ?? ""));
  }, [pods, selectedFilters]);
  const isMessageExist = useMemo(() => {
    return filteredPods.some((p) => p?.calculatedMessage);
  }, [filteredPods]);

  const { currentService } = useCurrentService(check.output.workloadId);

  return pods.length ? (
    <>
      {selectedPod && (
        <PodDetailsModal
          pod={selectedPod}
          closeModalCallback={() => setSelectedPodName(null)}
          clusterName={currentService?.k8sClusterName}
          namespace={currentService?.namespace}
          eventTime={check.output?.eventTime}
        />
      )}
      {check.output?.eventTime && (
        <SnapshotInfoDetail eventTime={check.output?.eventTime} />
      )}
      <BoldGrayText>Pods ({pods.length})</BoldGrayText>
      <WrappedRows>
        {reasons.map(([reason, count]) => (
          <MarginCheckBox
            id={reason}
            key={reason}
            disabled={false}
            checked={selectedFilters.get(reason) ?? false}
            fieldName={`${reason} (${count})`}
            handleCheckboxChange={() => handleFilterChanged(reason)}
          />
        ))}
      </WrappedRows>
      {filteredPods.length > 0 && (
        <LongTableContainer>
          <StyledFontTable>
            <thead>
              <tr>
                <th>Pod name</th>
                <th>Phase</th>
                {filteredPods.some((p) => p?.nodeName) && <th>Node</th>}
                <th>Reason</th>
                {isMessageExist && <th>Message</th>}

                <th />
              </tr>
            </thead>
            <tbody>
              {sortBy(filteredPods, (p) => [
                p?.calculatedReason === CustomPodReason.Evicted,
                p.isHealthy,
              ]).map((pod) => (
                <PodRow
                  pod={pod}
                  isSelected={pod.name === selectedPod?.name}
                  onPodSelectCallback={() => setSelectedPodName(pod.name)}
                  showMessage={isMessageExist}
                />
              ))}
            </tbody>
          </StyledFontTable>
        </LongTableContainer>
      )}

      {(!check.output?.isPodEventAllowed ||
        check.output?.isPodLogsAllowed === false) && (
        <AvailabilityUpgradeAgent />
      )}
      <br />
      {check?.output?.workloadId && (
        <NoUnderlinedLink
          to={`/main/deep-dive/${check.output.workloadId}?podId=notselected`}
        >
          <Button variant="secondary" icon={ColoredLogsIcon}>
            Live Pods & Logs
          </Button>
        </NoUnderlinedLink>
      )}
    </>
  ) : (
    <div>
      <br />
      {checkOutput?.failureMessage
        ? checkOutput.failureMessage
        : "Could not fetch pods data or pods do not exist"}
    </div>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WorkloadHealthyPodsDetails;

const Title = styled(StyledTitle)`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

const PodMessageContainer = styled.div`
  max-height: 3rem;
  overflow: auto;
`;
const PodRow: React.FC<{
  pod: PodOutputWithLogs;
  isSelected: boolean;
  onPodSelectCallback: () => void;
  showMessage: boolean;
}> = ({ pod, isSelected, onPodSelectCallback, showMessage }) => {
  return (
    <PodRowContainer
      isSelected={isSelected}
      isGrayed={pod?.calculatedReason === CustomPodReason.Evicted}
      onClick={onPodSelectCallback}
    >
      <td>{pod.name}</td>
      <td>{pod.phase}</td>
      {pod?.nodeName && <td>{pod.nodeName}</td>}

      <td>{pod?.calculatedReason || ""}</td>
      {showMessage && (
        <td>
          {pod?.calculatedMessage && (
            <PodMessageContainer>{pod.calculatedMessage}</PodMessageContainer>
          )}
        </td>
      )}

      <td>
        <Arrow width="0.75rem" color={gray10} direction="right" />
      </td>
    </PodRowContainer>
  );
};

const PodDetailsModal: React.FC<{
  pod: PodOutputWithLogs;
  closeModalCallback: () => void;
  clusterName?: string;
  namespace?: string;
  eventTime: Date | undefined;
}> = ({ pod, closeModalCallback, clusterName, namespace, eventTime }) => {
  return (
    <Dialog
      open
      onClose={closeModalCallback}
      maxWidth={false}
      PaperProps={{ sx: { width: "50%", height: "85%" } }}
    >
      <DialogTitle>
        <FlexColumns>
          <Title>
            <b>{pod.name}</b>
            <IconButton onClick={closeModalCallback}>
              <Close />
            </IconButton>
          </Title>
          <SmallLightShortText>
            <RowFieldContainer>
              <FixedWidth>
                <GraySmallLightShortText>Phase:</GraySmallLightShortText>
                <SmallPadding>{pod.phase}</SmallPadding>
              </FixedWidth>
              <div>
                {pod.calculatedReason && (
                  <>
                    <GraySmallLightShortText>Reason:</GraySmallLightShortText>
                    <SmallPadding>{pod.calculatedReason}</SmallPadding>
                  </>
                )}
              </div>
            </RowFieldContainer>
          </SmallLightShortText>
        </FlexColumns>
      </DialogTitle>
      <DialogContent>
        <PodsEvents pod={pod} />
        <br />
        <ContainerSectionFull
          containers={pod.containers}
          clusterName={clusterName ?? ""}
          namespace={namespace ?? ""}
          podName={pod.name}
          eventTime={eventTime}
        />
      </DialogContent>
    </Dialog>
  );
};
