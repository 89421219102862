import { Typography } from "@komodorio/design-system/deprecated";
import { ResourceTableModelRow } from "komodor-types";
import React from "react";
import styled from "styled-components";

import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { linkStyle } from "../../typography";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";
import { useCRDsData } from "../../../Inspection/CRDs/pages/useCRDsData";
import { ResourceTabName } from "../../../ResourceView/resources";
import { SELECTED_TAB_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";

const LastTdWideContainer = styled.td`
  width: 6rem;
`;

const Link = styled(Typography)`
  ${linkStyle}
`;

interface ResourcesLinkCellProps {
  resourceType: KubernetesResource;
  row: ResourceTableModelRow;
}

export const ResourcesLinkCell: React.FC<ResourcesLinkCellProps> = ({
  resourceType,
  row,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { cluster } = useCRDsData();

  return (
    <LastTdWideContainer
      onClick={(e) => {
        e.stopPropagation();
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster: cluster,
          namespace: row.namespaces,
          resourceType: resourceType.Kind,
          resourceName: row.name,
          additionalData: {
            isCustomResource: true,
          },
          urlStates: {
            [SELECTED_TAB_PARAM_KEY]: ResourceTabName.Resources,
          },
        });
      }}
    >
      <Link>View resources</Link>
    </LastTdWideContainer>
  );
};
