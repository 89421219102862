import React, { CSSProperties, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import MuiIconButton from "@mui/material/IconButton";
import { styled as muiStyled } from "@mui/material";
import { muiColors } from "@komodorio/design-system";
import ThumbDown from "@mui/icons-material/ThumbDown";
import ThumbUp from "@mui/icons-material/ThumbUp";
import styled from "styled-components";

const tooltipStyle = {
  tooltip: {
    sx: {
      boxShadow: "0px 2px 8px 1px rgba(22, 24, 31, 0.15)",
      "& .MuiTooltip-arrow": {
        color: "white",
      },
    },
  },
};

const ButtonContainer = styled.div`
  display: flex;
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  background: ${muiColors.gray[50]};
  border-radius: 0.25rem;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  &.MuiSnackbarContent-root {
    padding: 0 1rem;
  }
`;

const IconButton = styled(SvgIcon).attrs({
  fontSize: "small",
})<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? muiColors.blue[600] : muiColors.gray[300]};
`;

const StyledMuiIconButton = muiStyled(MuiIconButton)({
  position: "unset",
});

export interface DiagnosticsFeedbackProps {
  likeLabel: string;
  dislikeLabel: string;
  message: string;
  customStyle?: CSSProperties;
  onDislikeClick?: () => void;
}

export const DiagnosticsFeedback: React.FC<DiagnosticsFeedbackProps> = ({
  likeLabel,
  dislikeLabel,
  message,
  customStyle,
  onDislikeClick,
}) => {
  const [selectedUp, setSelectedUp] = useState<boolean>(false);
  const [selectedDown, setSelectedDown] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  return (
    <div>
      <Container>
        <ButtonContainer style={customStyle}>
          <Typography variant="body3" color={muiColors.gray[600]}>
            {message}
          </Typography>
          <Tooltip
            componentsProps={tooltipStyle}
            placement={"top"}
            arrow={false}
            title={"Helpful"}
            data-testid="tooltip-Helpful"
          >
            <StyledMuiIconButton
              data-testid="button-Like"
              onClick={() => {
                setSelectedUp(!selectedUp);
                setSelectedDown(false);
                setShowMessage(!selectedUp);
              }}
            >
              <IconButton
                aria-label={likeLabel}
                selected={selectedUp}
                as={ThumbUp}
              />
            </StyledMuiIconButton>
          </Tooltip>
          <Tooltip
            componentsProps={tooltipStyle}
            placement={"top"}
            arrow={false}
            title={"Not helpful"}
          >
            <StyledMuiIconButton data-testid="button-Dislike">
              <IconButton
                aria-label={dislikeLabel}
                selected={selectedDown}
                onClick={() => {
                  setSelectedDown(!selectedDown);
                  setSelectedUp(false);
                  setShowMessage(!selectedDown);
                  !selectedDown && onDislikeClick && onDislikeClick();
                }}
                as={ThumbDown}
              />
            </StyledMuiIconButton>
          </Tooltip>
        </ButtonContainer>
      </Container>
      <Container>
        <Snackbar
          autoHideDuration={2000}
          open={showMessage}
          onClose={() => setShowMessage(false)}
          sx={{ position: "absolute" }}
        >
          <StyledSnackbarContent
            message="Thank you for your feedback."
            variant="elevation"
            elevation={6}
          />
        </Snackbar>
      </Container>
    </div>
  );
};
