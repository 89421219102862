import { StatusColor } from "@komodorio/design-system/komodor-ui";

import { ExternalDnsRecordSyncStatusEnum } from "@/generated/addonsApi";

export const SEARCH_TABLE_PLACEHOLDER = "Search External DNS";
export const EXTERNAL_DNS_DEFAULT_SORT_BY_FIELD = "syncStatus";
export const EXTERNAL_DNS_DEFAULT_SORT_BY_DIRECTION = "asc";

export const statusMap: Record<
  ExternalDnsRecordSyncStatusEnum,
  { label: string; color: StatusColor }
> = {
  [ExternalDnsRecordSyncStatusEnum.Synced]: {
    label: "Synced",
    color: "success",
  },
  [ExternalDnsRecordSyncStatusEnum.Unsynced]: {
    label: "Not Synced",
    color: "error",
  },
  [ExternalDnsRecordSyncStatusEnum.Unknown]: {
    label: "Unknown",
    color: "neutral",
  },
};
