import React, { useState } from "react";

import { Audit, useFetchAuditQuery } from "../../generated/graphql";
import DataTable from "../common/table/DataTable";

import { AuditDrawer } from "./AuditDrawer";
import AuditObject, { toAuditObject } from "./details/AuditObject";
import ExportCSVButton from "./csv/CSVExporterButton";

import { useAuditColumnDefinition } from "@/components/Audit/columnDefinitions";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

const AuditPage: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<AuditObject>();
  const [{ data }] = useFetchAuditQuery({});

  const auditObjects = data?.audit.map((a) => toAuditObject(a as Audit)) ?? [];

  const { columns } = useAuditColumnDefinition({ setOpenDrawer });

  const auditData =
    auditObjects !== null
      ? (auditObjects
          .filter((a) => {
            return a?.data?.user?.accountId === a?.data?.account?.id;
          })
          .sort(
            (a, b) =>
              new Date(b?.data.eventTimestamp ?? "").getTime() -
              new Date(a?.data.eventTimestamp ?? "").getTime()
          ) as AuditObject[])
      : [];

  return (
    <SettingsViewVerticalLayout title={"Audit"}>
      <ExportCSVButton data={auditData} />
      <DataTable
        data={auditData}
        columns={columns}
        getRowId={(a) => a.data.id}
        highlightedRows
      />
      {!!openDrawer && (
        <AuditDrawer
          isOpen={!!openDrawer}
          obj={openDrawer}
          handleClose={() => setOpenDrawer(undefined)}
        />
      )}
    </SettingsViewVerticalLayout>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AuditPage;
