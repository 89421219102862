import { FailedDeploymentsResponse } from "../../../../../../../generated/applicationInsights";

import { generateMockAvailabilityIssuesTrends } from "./mockAppInsightsAvailabilityIssues";

type GenerateMockFailedDeploymentsParams = {
  fromEpoch: number;
  toEpoch: number;
};
export const generateMockFailedDeploymentsTrends = (
  params: GenerateMockFailedDeploymentsParams
): FailedDeploymentsResponse => {
  return generateMockAvailabilityIssuesTrends({ ...params, randomize: false });
};
