import { ClusterProviderType } from "../../../../../../../../../generated/reliabilityApi";
import { Link, TextContainer } from "../../../violationDrawerStyles";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import { getIsEolDatePassed } from "./utils";

const {
  violationDrawer: { violationSummary: violationSummaryAriaLabel },
} = reliabilityArialLabels;

export const getWhatDescription = ({
  version,
  clusterProvider,
  eolDate,
}: {
  version: string | undefined;
  clusterProvider: ClusterProviderType | undefined;
  eolDate: string | undefined;
}) =>
  !version || !clusterProvider || !eolDate ? null : (
    <TextContainer aria-label={violationSummaryAriaLabel}>
      The Kubernetes version <b>{version}</b> provided by{" "}
      <b>{clusterProvider}</b>{" "}
      {getIsEolDatePassed(new Date(eolDate))
        ? "has reached its end of life and is no longer supported."
        : "will reach its end of life soon."}
    </TextContainer>
  );

export const getWhyDescription = () => (
  <TextContainer>
    Using an outdated version of Kubernetes poses security risks due to
    potential vulnerabilities and lack of updates. Additionally, it may result
    in compatibility issues with newer tools and applications, and hinder access
    to the latest features and community support. Regularly updating Kubernetes
    is crucial to maintaining a secure and well-supported cluster.
  </TextContainer>
);

export const getHowDescription = () => (
  <TextContainer>
    Please consider upgrading to a supported version of Kubernetes to continue
    receiving updates and support. Consult the{" "}
    <Link href="https://kubernetes.io/docs/home/">
      official Kubernetes documentation
    </Link>{" "}
    or contact your cloud provider's support for guidance on the upgrade
    process. If you have any questions or need assistance, please reach out to
    the appropriate team or support channel.
  </TextContainer>
);
