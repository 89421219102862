import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../../generated/metricsApi";
import { getRecommendationData } from "../RecommendationContainer/getRecommendationData";

export const getChangeSummary = (
  containersRecommendationsData: RightSizingCostSummaryByService[] | undefined,
  strategy: OptimizationStrategy
) =>
  containersRecommendationsData?.reduce(
    (acc, cur) => {
      const {
        shouldShowCpuRecommendation,
        shouldShowMemoryRecommendation,
        currentCpu,
        currentMemory,
        cpuRecommendation,
        memoryRecommendation,
      } = getRecommendationData(cur, strategy);

      return {
        previousCpu: acc.previousCpu + currentCpu,
        newCpu:
          acc.newCpu +
          (shouldShowCpuRecommendation ? cpuRecommendation : currentCpu),
        previousMemory: acc.previousMemory + currentMemory,
        newMemory:
          acc.newMemory +
          (shouldShowMemoryRecommendation
            ? memoryRecommendation
            : currentMemory),
      };
    },
    { previousCpu: 0, newCpu: 0, previousMemory: 0, newMemory: 0 }
  );
