import { TooltipWrapper } from "react-tooltip";
import React from "react";

import { DEFAULT_TOOLTIP } from "../../shared/constants/tooltipIds";

import {
  ContainerNameText,
  podExecSesssionTitle,
} from "./MinimizedRowSharedComponents";

interface MinimizedShellTextProps {
  podName: string;
  containerName: string | undefined;
}

export const MinimizedShellText: React.FC<MinimizedShellTextProps> = ({
  podName,
  containerName,
}) => {
  const text = podExecSesssionTitle(podName, containerName);
  return (
    <>
      <ContainerNameText>
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content={text}>
          {text}
        </TooltipWrapper>
      </ContainerNameText>
    </>
  );
};
