import pluralize from "pluralize";
import { get } from "lodash";

import { Severity } from "../../../../../ReliabilityTypes";

import { CheckType } from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";
import { severitiesList } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { FormConfigurations } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import {
  getConfigurationFieldNamesByCheckType,
  getConfigurationFieldNamesByCheckTypeAsList,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";

export const getSignByCheckType = (checkType: CheckType) => {
  switch (checkType) {
    case CheckType.DeprecatedApis:
      return "-";
    case CheckType.KubernetesEndOfLife:
    case CheckType.CertificateExpiration:
      return "<";
  }
  return ">";
};

export const getConfigurationNamesByCheckType = (
  configurationName: string,
  severity: Severity
) => {
  const isDeprecatedApi = configurationName.includes(CheckType.DeprecatedApis);
  if (!isDeprecatedApi) return `${configurationName}${severity}`;
  const { stateField, versionsAheadField } =
    getConfigurationFieldNamesByCheckType(CheckType.DeprecatedApis, severity);
  const isVersionsAhead = configurationName.includes("versionsAhead");

  return isVersionsAhead ? versionsAheadField : stateField;
};

export const convertNumberStringToNumber = (value: string) => {
  return !isNaN(Number(value)) && value !== "" ? Number(value) : value;
};

export const kubernetesEndOfLifeOptions = Array.from({ length: 12 }).map(
  (_, i) => ({
    label: `${i + 1} ${pluralize("months", i + 1)}`,
    value: `${i + 1}`,
  })
);

export const transformConfigurationsToCheckedState = (
  policy: FormConfigurations | undefined,
  filterByCheckType?: CheckType
): Dictionary<boolean> | undefined => {
  if (!policy?.configurations) return undefined;
  return Object.keys(policy?.configurations).reduce<Dictionary<boolean>>(
    (acc, curr) => {
      if (filterByCheckType && curr !== filterByCheckType) return acc;
      severitiesList.forEach((severity) => {
        const configurationFieldNamesByCheckType =
          getConfigurationFieldNamesByCheckTypeAsList(
            curr as CheckType,
            severity
          );
        configurationFieldNamesByCheckType.forEach((fieldName) => {
          acc[fieldName] = !!get(policy, fieldName);
        });
      });
      return acc;
    },
    {}
  );
};
