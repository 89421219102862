import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../../resources/node";
import { DescribePanel } from "../common/DescribeSection";
import {
  DescribeDateItem,
  DescribeTextItem,
  DescribeItem,
} from "../common/DescribeItem";
import { DescribeSectionDivider } from "../common/DescribeSectionDivider";
import { DescribeLabelsAndAnnotations } from "../common/DescribeLabelsAndAnnotations";
import { DescribeConditions } from "../common/DescribeConditions";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { NodeTaints } from "./NodeTaints";
import { NodeResources } from "./NodeResources";

import { InvestigationSection } from "@/components/ResourceView/AiInvestigation/common/InvestigationSection";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";

interface NodeDescribeTabProps {
  resource: Node;
}

const NodeDescribeTab: React.FC<NodeDescribeTabProps> = ({ resource }) => {
  const { data: features } = useAccountFeatures();
  const isAiEnabledForAccount = features.klaudiaAi;
  const { klaudiaAi, klaudiaAiNode } = useOverridableFlags();
  const node = resource.fullObj;
  const { creationTimestamp, name } = node.metadata ?? {};
  const {
    addresses = [],
    nodeInfo,
    conditions = [],
    capacity = {},
    allocatable = {},
  } = node.status ?? {};
  const {
    operatingSystem,
    osImage,
    kernelVersion,
    containerRuntimeVersion,
    kubeletVersion,
  } = nodeInfo ?? {};
  const { taints = [] } = node.spec ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      {!isAiEnabledForAccount ||
      !klaudiaAi ||
      !klaudiaAiNode ||
      resource.ready ? null : (
        <InvestigationSection
          requestId={resource.id}
          resource={resource}
          isHealthy={false}
          flowType="Unhealthy Node"
        />
      )}
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeItem name={"Status"}>{resource.renderStatus()}</DescribeItem>
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeLabelsAndAnnotations
          labels={resource.labels}
          annotations={resource.annotations}
        />

        <DescribeSectionDivider title={"SYSTEM INFORMATION"} />
        <DescribeTextItem name="OS" value={operatingSystem} />
        <DescribeTextItem name="OS Image" value={osImage} />
        <DescribeTextItem name="Kernel version" value={kernelVersion} />
        <DescribeTextItem name="Kubelet version" value={kubeletVersion} />
        <DescribeTextItem
          name="Container runtime"
          value={containerRuntimeVersion}
        />
        {addresses.length && (
          <>
            <DescribeSectionDivider title={"ADDRESSES"} />
            {addresses.map(({ type, address }) => (
              <DescribeTextItem name={type} value={address} />
            ))}
          </>
        )}

        <DescribeConditions conditions={conditions} />
      </DescribePanel>

      <NodeTaints taints={taints} />

      <NodeResources capacity={capacity} allocatable={allocatable} />
    </DescribeTabContentWrapper>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeDescribeTab;
