import { useCallback } from "react";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";

import {
  GET_CHECKS_IGNORE_RULES_PATH,
  useInsertIgnoreRule,
  useUpdateIgnoreRule,
} from "../../../shared/hooks/reliability-api/violations/useChecksIgnoreRules";
import {
  CheckType,
  GetAllIgnoreRulesPerChecksResponse,
  IgnoreRule,
} from "../../../generated/reliabilityApi";

import { useClearViolationsCache } from "./useClearViolationsCache";
import { useReportError } from "./useReportError";

type IgnoreRuleAction = "insert" | "update" | "delete";

export const useInsertNewIgnoreRule = () => {
  const { mutateAsync } = useInsertIgnoreRule();
  const onRulesChange = useUpdateCacheOnRulesChange();
  const reportError = useReportError();

  return useCallback(
    async (newRule: IgnoreRule): Promise<{ error: boolean }> => {
      let error = false;
      try {
        await mutateAsync({
          body: newRule,
        });
        onRulesChange(newRule, "insert");
      } catch (e) {
        error = true;
        reportError(e, "addIgnoreRule");
      }
      return { error };
    },
    [mutateAsync, onRulesChange, reportError]
  );
};

export const useUpdateExistingIgnoreRule = () => {
  const { mutateAsync } = useUpdateIgnoreRule();
  const onRulesChange = useUpdateCacheOnRulesChange();
  const reportError = useReportError();

  return useCallback(
    async (newRule: IgnoreRule): Promise<{ error: boolean }> => {
      let error = false;
      try {
        await mutateAsync({
          id: newRule.id,
          body: {
            id: newRule.id,
            checkType: newRule.checkType,
            scope: newRule.scope,
          },
        });
        onRulesChange(newRule, "update");
      } catch (e) {
        error = true;
        reportError(e, "editIgnoreRule");
      }

      return { error };
    },
    [mutateAsync, onRulesChange, reportError]
  );
};

const useUpdateCacheOnRulesChange = () => {
  const onRuleChange = useOnRuleChange();
  const updateIgnoreRulesCache = useUpdateIgnoreRulesCache();

  return useCallback(
    (newRule: IgnoreRule, action: IgnoreRuleAction) => {
      updateIgnoreRulesCache(newRule, action);
      onRuleChange();
    },
    [onRuleChange, updateIgnoreRulesCache]
  );
};

export const useOnRuleChange = () => {
  const refreshViolations = useClearViolationsCache();

  return useCallback(async () => {
    await refreshViolations();
  }, [refreshViolations]);
};

export const useUpdateIgnoreRulesCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (newRule: IgnoreRule, action: IgnoreRuleAction) => {
      queryClient.setQueryData<
        UseMutationResult<GetAllIgnoreRulesPerChecksResponse>
      >([GET_CHECKS_IGNORE_RULES_PATH, [newRule.checkType]], (prevData) => {
        if (!prevData?.data?.ignoreRules) return prevData;
        let updatedRules: IgnoreRule[];

        switch (action) {
          case "insert":
            updatedRules = [...prevData.data.ignoreRules, newRule];
            break;
          case "update":
            updatedRules = prevData.data.ignoreRules.map((rule) =>
              rule.id === newRule.id ? newRule : rule
            );
            break;
          case "delete":
            updatedRules = prevData.data.ignoreRules.filter(
              (rule) => rule.id !== newRule.id
            );
            break;
        }

        return {
          ...prevData,
          data: {
            ...prevData.data,
            ignoreRules: updatedRules,
          },
        };
      });
    },
    [queryClient]
  );
};

export const useInvalidateIgnoreRulesCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (checkTypes: CheckType[]) => {
      queryClient.invalidateQueries([GET_CHECKS_IGNORE_RULES_PATH, checkTypes]);
    },
    [queryClient]
  );
};
