import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";

import { NumberTextField } from "./PolicyDrawerConfigurations/NumberTextField";

export const FormFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 16px 0;
  & > *:first-child {
    flex-basis: 105px;
    min-width: 105px;
    margin-top: 6px;
  }
  & > *:last-child {
    flex-grow: 1;
  }
`;

export const DynamicChecksConfigurationsTableColumnsStyle = css`
  display: grid;
  grid-template-columns: 30px 1fr 180px 40px;
`;

export const StaticChecksConfigurationsTableColumnsStyle = css`
  display: grid;
  grid-template-columns: 1fr 180px 40px;
  cursor: default;
`;

export const ConfigurationTableWithEnabledColumn = css`
  display: grid;
  grid-template-columns: 30px 1fr 0.5fr 0.5fr;
`;

export const StyledNumberTextField = styled(NumberTextField)`
  && {
    & > div {
      padding-right: 12px;
    }
    input {
      padding: 2px 0 2px 12px;
      font-size: 14px;
    }
  }
`;

export const StyledSingleSelectContainer = styled.div<{
  isInvalid?: boolean;
}>`
  && {
    .dropDownTriggerField {
      ${({ isInvalid }) =>
        isInvalid &&
        css`
          border-color: ${muiColors.pink[600]};
        `}
    }
  }
`;

export const StyledDivider = styled(Divider)<{ $marginBottom: number }>`
  && {
    margin: 0 0 ${({ $marginBottom }) => $marginBottom}px 0;
  }
`;
