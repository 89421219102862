import { useMemo } from "react";

import { External_Link_Context_Enum } from "../../../../../../generated/graphql";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useExternalLinks } from "./useExternalLinks";
import { getLinkIcon, ServiceLinkInfo } from "./utils";

const prefix = "app.komodor.com";

export const linkAnnotations = (
  annotations: Annotations,
  annotationKey: string
): Link[] =>
  Object.entries(annotations || {})
    .filter(([key]) => key.startsWith(`${prefix}/${annotationKey}.`))
    .map(([key, url]) => {
      const text = key.slice(`${prefix}/${annotationKey}.`.length);
      return { icon: getLinkIcon(url, text), text, url };
    });

type Annotations = Record<string, string> | undefined;

export interface ExternalLinkProps {
  linkId: string;
  externalLinkContext: External_Link_Context_Enum;
  serviceId: string;
  k8sClusterName?: string;
  namespace?: string;
}

export interface Link {
  icon: string;
  text: string;
  url: string;
  externalLinkProps?: ExternalLinkProps;
}

export const isLinkContext = (
  link: Link,
  context: External_Link_Context_Enum
): boolean => {
  return link.externalLinkProps?.externalLinkContext === context;
};

export const isExternalLink = (link: Link): boolean =>
  link.externalLinkProps !== undefined;

export const useDeployRelatedLinks = (
  deployAnnotations: Annotations,
  serviceInfo: ServiceLinkInfo
): Link[] => {
  const externalLinks = useExternalLinks(
    serviceInfo.cluster,
    serviceInfo.serviceId,
    serviceInfo.namespace
  );
  return useMemo(
    () => [
      ...linkAnnotations(deployAnnotations, "deploy.link"),
      ...linkAnnotations(deployAnnotations, "deploy.job"),
      ...externalLinks,
    ],
    [deployAnnotations, externalLinks]
  );
};
