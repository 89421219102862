import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { useRecommendationsModalContext } from "../../context/useRecommendationsModalContext";
import { useFetchGraphData } from "../../hooks/metricsHooks";
import { GraphContainerWithStatus } from "../../../../../../Metrics/GraphContainerWithStatus";
import { graphHeight } from "../../constants";
import { ResourceGraph } from "../../../../../../Metrics/graphs/ResourceGraph";
import { useGetMetricsUid } from "../../hooks/useGetMetricsUid";
import { RecommendationData } from "../../types";
import {
  MetricType,
  MetricsAggregationType,
} from "../../../../../../Metrics/types";
import { EnlargedMetricsGraph } from "../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { MetricsAggregationSelector } from "../../../../../../Metrics/MetricsPreferences/MetricsAggregationSelector";
import { Uid } from "../../../../../../../shared/utils/generateUid";
import { OptimizationStrategy } from "../../../../../../../generated/metricsApi";

import { ResourceRecommendations } from "./ResourceRecommendations";

const Container = styled.div`
  display: flex;
  gap: 40px;
`;

const MetricContainer = styled.div`
  flex: 1;
`;

type EnlargedMetricsGraphContainerProps = {
  uid: Uid | undefined;
  onClose: () => void;
  title: string;
  containerName: string;
  type: MetricType;
  strategy: OptimizationStrategy;
  aggregationByStrategy: MetricsAggregationType;
};

const EnlargedMetricsGraphContainer: React.FC<
  EnlargedMetricsGraphContainerProps
> = ({
  uid,
  onClose,
  title,
  containerName,
  type,
  strategy,
  aggregationByStrategy,
}) => {
  const [aggregation, setAggregation] = useState(aggregationByStrategy);
  const { metricsParams } = useRecommendationsModalContext();

  const { graphProps, hasGraphData, error, loading } = useFetchGraphData({
    containerName,
    metricsParams,
    type,
    strategy,
    aggregation,
  });

  return (
    <EnlargedMetricsGraph
      uid={uid}
      onClose={onClose}
      title={title}
      hasGraphData={hasGraphData}
      error={error}
      loading={loading}
      controls={[
        <MetricsAggregationSelector
          ariaLabel={`${type} metrics aggregation selector`}
          aggregationMetric={aggregation}
          setAggregationMetric={setAggregation}
        />,
      ]}
    >
      <ResourceGraph type={type} {...graphProps} />
    </EnlargedMetricsGraph>
  );
};

type ResourceContainerProps = {
  type: MetricType;
  recommendationData: RecommendationData;
};

export const ResourceContainer: React.FC<ResourceContainerProps> = ({
  type,
  recommendationData,
}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const { metricsParams } = useRecommendationsModalContext();

  const {
    containerName,
    currentCpu,
    currentMemory,
    cpuRecommendation,
    memoryRecommendation,
    strategy, // TODO: strategy is drilled down extensively from useRowsContent(), consider using context
  } = recommendationData;

  const aggregationByStrategy = useMemo(() => {
    if (strategy === "moderate" || strategy === "conservative") {
      return MetricsAggregationType.P99;
    }
    return MetricsAggregationType.P95;
  }, [strategy]);

  const { graphProps, hasGraphData, error, loading } = useFetchGraphData({
    containerName,
    metricsParams,
    type,
    strategy,
    aggregation: aggregationByStrategy,
  });

  const currentDataUsage = type === "cpu" ? currentCpu : currentMemory;
  const currentRecommendation =
    type === "cpu" ? cpuRecommendation : memoryRecommendation;
  const title =
    type === "cpu" ? "CPU usage (last 30 days)" : "Memory usage (last 30 days)";

  const uid = useGetMetricsUid();

  return (
    <Container aria-label={`${type} container`}>
      <ResourceRecommendations
        containerName={containerName}
        type={type}
        currentValue={currentDataUsage}
        recommendedValue={currentRecommendation}
      />
      <MetricContainer aria-label={`${type} metrics graph`}>
        <GraphContainerWithStatus
          graphHeight={graphHeight}
          uid={uid}
          hasGraphData={hasGraphData}
          error={error}
          loading={loading}
          title={title}
          onEnlargeGraph={() => setIsEnlarged(true)}
        >
          <ResourceGraph type={type} {...graphProps} />
        </GraphContainerWithStatus>
      </MetricContainer>
      {isEnlarged && (
        <EnlargedMetricsGraphContainer
          uid={uid}
          onClose={() => setIsEnlarged(false)}
          title={title}
          containerName={containerName}
          type={type}
          strategy={strategy}
          aggregationByStrategy={aggregationByStrategy}
        />
      )}
    </Container>
  );
};
