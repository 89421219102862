import React from "react";

import { getRoundedNumber } from "../../../../../../../../../../../shared/utils/numbers/getRoundedNumber";
import { formatNumber } from "../../../../../../../../../../../shared/utils/formatNumber";

import { EmptySummaryItem } from "./EmptySummaryItem";

export const memoryUnit = "MiB";
export const cpuUnit = "MilliCores";

export const getMibNumber = (bytes: number) =>
  shortVariation(bytes / Math.pow(1024, 2));

export const shortVariation = (value: number) => getRoundedNumber(value, 2);

export const getValue = (value: number, unit: string) =>
  value > 0 ? `${formatNumber(value)} ${unit}` : <EmptySummaryItem />;

export const getRatio = ({
  firstValue,
  secondValue,
}: {
  firstValue: number;
  secondValue: number;
}) => {
  if (secondValue <= 0 || firstValue <= 0) return "N/A";

  return `1 : ${shortVariation(secondValue / firstValue)}`;
};
