import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import Input from "../../common/controls/Input";
import { textStyle } from "../../common/typography";
import { gray13 } from "../../../Colors";

import { ArgoFilterContainer, InputContainer } from "./styles";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const StyledInput = styled(Input)`
  ${textStyle};
  background: white;
  border: 1 solid ${gray13};
`;

export const ArgoSearchFilter: React.FC<{
  labelText?: string;
}> = ({ labelText }) => {
  const [filterSearchTerm, setFilterSearchTerm] = useStateInSearchParams(
    ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY
  );
  const myRef = React.useRef<HTMLInputElement>(null);

  return (
    <ArgoFilterContainer>
      <Typography variant={"h6"} color={muiColors.gray[400]}>
        Search
      </Typography>
      <InputContainer>
        <StyledInput
          ref={myRef}
          placeholder="Search by name, type etc."
          data-e2e-selector={"inspection-search-filter"}
          value={filterSearchTerm ?? ""}
          onChange={(c) => setFilterSearchTerm(c.target.value || null)}
        />
      </InputContainer>
    </ArgoFilterContainer>
  );
};
