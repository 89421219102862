import { SupportedKubernetesResources } from "../../../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { KubernetesResource } from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ResourceType } from "../types/appViewsResourcesTypes";

export const getK8sResourceFromName = (
  name: ResourceType
): KubernetesResource | undefined => {
  return SupportedKubernetesResources.find(
    (kubernetesResource) => kubernetesResource.NameInK8S === name
  );
};

export const getK8sResourceFromKind = (
  kind: string
): KubernetesResource | undefined => {
  return SupportedKubernetesResources.find(
    (kubernetesResource) => kubernetesResource.Kind === kind
  );
};
