import { useCallback } from "react";

import { useGetViolationChecks } from "../../../../../../../shared/hooks/reliability-api/violations/useGetViolationChecks";
import { CheckType } from "../../../../../../../generated/reliabilityApi";

export const useGetImpactGroupByCheckType = () => {
  const { data } = useGetViolationChecks();

  return useCallback(
    (checkType: CheckType) => {
      const checks = data?.data?.checks;
      if (!checks) return undefined;
      return (
        checks.find((check) => check.type === checkType)?.impactGroupId ||
        undefined
      );
    },
    [data?.data?.checks]
  );
};
