import React from "react";

import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { PageFiltersContainer } from "@/components/k8sAddons/styles";

const sharedFilterProps = {
  selectProps: {
    width: "200px",
  },
};

export const Filters: React.FC = () => {
  return (
    <PageFiltersContainer>
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"clusterName"}
        label={"Cluster"}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"namespace"}
        label={"Namespace"}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={"status"}
        label={"Status"}
      />
    </PageFiltersContainer>
  );
};
