import React from "react";
import Box from "@mui/material/Box";
import { muiColors } from "@komodorio/design-system";
import WarningRounded from "@mui/icons-material/WarningRounded";

import { SmartTextsJoin } from "./SmartTextsJoin";

const IssueIcon = () => (
  <Box
    sx={{
      display: "flex",
      padding: "1px 2px 3px 2px",
      borderRadius: "50%",
      backgroundColor: muiColors.pink[100],
    }}
  >
    <WarningRounded sx={{ color: muiColors.pink[600] }} />
  </Box>
);

interface IssueReasonsColProps {
  issueReasons: string[];
}
export const IssueReasonsCol: React.FC<IssueReasonsColProps> = ({
  issueReasons,
}) => {
  if (issueReasons.length === 0) {
    return null;
  }

  return (
    <>
      <IssueIcon />
      <SmartTextsJoin
        texts={issueReasons}
        icon={
          <WarningRounded
            sx={{ color: muiColors.pink[600], width: 16, height: 16 }}
          />
        }
      />
    </>
  );
};
