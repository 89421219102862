import { useMemo } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";

import { FiltersSection } from "../../FiltersSection/FiltersSection";

import { ViolationsBreakdown } from "./ViolationsBreakdown/ViolationsBreakdown";
import { RealtimeSection } from "./ViolationsBreakdown/RealtimeSection/RealtimeSection";

import {
  HealthType,
  useHealthType,
} from "@/components/reliability/hooks/useHealthType";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { useScopeFilters } from "@/components/reliability/hooks/useScopeFilters";
import { ImpactGroupType } from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const ViolationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type SectionsState = {
  realtime: boolean;
  reliabilityRisks: boolean;
};

const {
  sections: { divider: sectionsDividerAriaLabel },
} = reliabilityArialLabels;

const useSections = (): SectionsState => {
  const healthType = useHealthType();
  const isBestPracticesTab = useIsBestPracticesTab();
  const { impactGroupType } = useScopeFilters();

  return useMemo(() => {
    if (!impactGroupType?.length || isBestPracticesTab)
      return {
        realtime: healthType !== HealthType.NOT_SPLIT && !isBestPracticesTab,
        reliabilityRisks: true,
      };

    const showRealtimeByFilter = impactGroupType?.includes(
      ImpactGroupType.Realtime
    );
    const showReliabilityRisksByFilter = impactGroupType?.includes(
      ImpactGroupType.Dynamic
    );

    return {
      realtime:
        healthType !== HealthType.NOT_SPLIT &&
        !isBestPracticesTab &&
        showRealtimeByFilter,
      reliabilityRisks: showReliabilityRisksByFilter,
    };
  }, [healthType, impactGroupType, isBestPracticesTab]);
};

export const ViolationsPageContent: React.FC = () => {
  const { realtime, reliabilityRisks } = useSections();

  return (
    <>
      <FiltersSection />
      <ViolationsContainer>
        {realtime && <RealtimeSection />}
        {realtime && reliabilityRisks && (
          <Divider aria-label={sectionsDividerAriaLabel} />
        )}
        {reliabilityRisks && <ViolationsBreakdown />}
      </ViolationsContainer>
    </>
  );
};
