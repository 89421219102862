import { useCallback, useEffect, useState } from "react";

import {
  useInterval,
  NullableNumber,
} from "../../components/common/useInterval";
import {
  SocketState,
  useWebSocketContext,
} from "../context/WebSocketsProvider";
import { generateSocketPayload } from "../utils/generateSocketPayload";
import { notifyDDError } from "../hooks/exceptionManagement";

import {
  KEEP_ALIVE_INTERVAL,
  HANDLE_TIMEOUT_INTERVAL,
} from "./constants/keepAlive";
import { getSocketState } from "./getSocketState";
import { isSocketReadyToSend } from "./isSocketReadyToSend";
import { extractPayloadFromMessage } from "./utils";
import { SocketMessageType, SocketMessagePayload } from "./types";

import { retry } from "@/shared/utils/retry";

type Res = [() => void, () => void];

export const useKeepSocketSessionAlive = (socketId: string): Res => {
  const sockets = useWebSocketContext();
  const [delay, setKeepAliveDelay] =
    useState<NullableNumber>(KEEP_ALIVE_INTERVAL);
  const [handleTimeoutDelay, setHandleTimeoutDelay] = useState<NullableNumber>(
    HANDLE_TIMEOUT_INTERVAL
  );

  const refresh = useCallback(() => {
    setKeepAliveDelay(KEEP_ALIVE_INTERVAL);
    setHandleTimeoutDelay(HANDLE_TIMEOUT_INTERVAL);
  }, [setKeepAliveDelay, setHandleTimeoutDelay]);

  // react to keep-alive timeout
  useInterval(() => {
    const { lastKeepAliveAck } = sockets.getSocketContext(socketId) || {};
    const socketState = getSocketState(lastKeepAliveAck);

    if (socketState === SocketState.Alive) {
      return;
    }

    // inform everyone who interested of the health status
    sockets.addContextToSocket(socketId, {
      keepAliveState: socketState,
    });

    if (socketState === SocketState.Died) {
      setHandleTimeoutDelay(null);
    }
  }, handleTimeoutDelay);

  // send keep-alive while the session is open
  useInterval(() => {
    const connection = sockets.getSocket(socketId);
    const { sessionId, keepAliveState } =
      sockets.getSocketContext(socketId) || {};

    if (
      !connection ||
      connection.readyState !== connection.OPEN ||
      (keepAliveState && keepAliveState !== SocketState.Alive)
    ) {
      return;
    }

    try {
      if (!sessionId) {
        return;
      }
      const payload = generateSocketPayload({
        messageType: SocketMessageType.KeepAlive,
        sessionId,
      });

      connection?.send(JSON.stringify(payload));
      sockets.addContextToSocket(socketId, {
        lastKeepAliveMessageId: payload.messageId,
      });
    } catch (error) {
      notifyDDError({
        name: "failed to send keep-alive message",
        message: (error as Error).message,
      });
    }
  }, delay);

  const stop = useCallback(() => {
    setKeepAliveDelay(null);
    setHandleTimeoutDelay(null);
  }, [setKeepAliveDelay, setHandleTimeoutDelay]);

  // stop keep-alive once the session is closed
  useEffect(() => {
    let connection: WebSocket | undefined;

    const onMessage = (message: MessageEvent<SocketMessagePayload>) => {
      const { messageType, data } = extractPayloadFromMessage(message);
      const { lastKeepAliveMessageId } =
        sockets.getSocketContext(socketId) || {};

      switch (messageType) {
        case SocketMessageType.Termination:
          setKeepAliveDelay(null);
          setHandleTimeoutDelay(null);
          break;

        case SocketMessageType.Ack:
          if (data?.ackedMessageID === lastKeepAliveMessageId) {
            sockets.addContextToSocket(socketId, {
              lastKeepAliveAck: new Date(),
              keepAliveState: SocketState.Alive,
            });
          }

          break;
      }
    };

    retry({
      func: () => {
        return new Promise<void>((resolve, reject) => {
          connection = sockets.getSocket(socketId);

          if (isSocketReadyToSend(connection)) {
            connection?.addEventListener("close", stop);
            connection?.addEventListener("message", onMessage);
            resolve();
          } else {
            reject();
          }
        });
      },
      retries: 10,
      exponentialBackoff: true,
    });

    return () => {
      connection?.removeEventListener("close", stop);
      connection?.removeEventListener("message", onMessage);
    };
  }, [setKeepAliveDelay, socketId, sockets, delay, handleTimeoutDelay, stop]);

  return [refresh, stop];
};
