import React from "react";

import { Summary } from "../Summary";

export type AddonsChipProps = {
  count: number;
  isLoading: boolean;
};

export const AddonsChip: React.FC<AddonsChipProps> = ({ count, isLoading }) => {
  if (!isLoading && !count) {
    return null;
  }

  return (
    <Summary
      stats={[{ label: "Addons with Issues", value: count }]}
      isLoading={isLoading}
    />
  );
};
