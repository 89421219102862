import React from "react";

import Resource from "../../resources";

import { WorkloadMetricsTabContextProvider } from "./context/WorkloadMetricsTabContext";
import { WorkloadMetricsTabContent } from "./components/WorkloadMetricsTabContent";

interface WorkloadMetricsTabProps {
  resource: Resource;
}

export const WorkloadMetricsTab: React.FC<WorkloadMetricsTabProps> = ({
  resource,
}) => {
  return (
    <WorkloadMetricsTabContextProvider>
      <WorkloadMetricsTabContent resource={resource} />
    </WorkloadMetricsTabContextProvider>
  );
};
