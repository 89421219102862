import { useMemo } from "react";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { CheckType } from "../../../../../../../generated/reliabilityApi";
import { getCheckLabel } from "../../../../../utils/getCheckLabel";

import { useGetImpactGroupByCheckType } from "./useGetImpactGroupByCheckType";
import { useSupportedCheckTypesByTab } from "./useSupportedCheckTypesByTab";

import { useIsDynamicChecksTab } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useIsDynamicChecksTab";

export const useFilteredConfigurations = () => {
  const { policyConfigurations, checksTableFilters } = usePolicyDrawerContext();
  const getImpactGroup = useGetImpactGroupByCheckType();
  const isDynamicChecksTab = useIsDynamicChecksTab();
  const supportedCheckTypes = useSupportedCheckTypesByTab();

  return useMemo(() => {
    let items = Object.keys(policyConfigurations).filter((check) =>
      supportedCheckTypes.includes(check as CheckType)
    ) as CheckType[];

    if (checksTableFilters.searchTerm) {
      items = items.filter((checkType) => {
        const itemLabel = getCheckLabel(checkType);
        return itemLabel
          .toLowerCase()
          .includes(checksTableFilters.searchTerm.toLowerCase());
      });
    }

    // impact group select is only in dynamic checks tab
    if (checksTableFilters.impactGroupId && isDynamicChecksTab) {
      items = items.filter((checkType) => {
        const impactGroup = getImpactGroup(checkType);
        return impactGroup === checksTableFilters.impactGroupId;
      });
    }

    return items;
  }, [
    checksTableFilters.impactGroupId,
    checksTableFilters.searchTerm,
    getImpactGroup,
    policyConfigurations,
    supportedCheckTypes,
    isDynamicChecksTab,
  ]);
};
