import {
  getStartTime,
  NonCustomTimeframe,
} from "../../../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import { TimeWindowAsNumber } from "../hooks/appViewPersistentState";

export const getInitialTimeWindow = (
  timeFrame: NonCustomTimeframe
): TimeWindow => ({
  start: getStartTime[timeFrame](new Date()),
  end: new Date(),
  timeframe: timeFrame,
});

export const timeWindowToNumber = ({
  start,
  end,
  timeframe,
}: TimeWindow): TimeWindowAsNumber => ({
  start: start.getTime(),
  end: end.getTime(),
  timeframe,
});

export const timeWindowToDate = ({
  start,
  end,
  timeframe,
}: TimeWindowAsNumber): TimeWindow => ({
  start: new Date(start),
  end: new Date(end),
  timeframe,
});
