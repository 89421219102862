import React, { useMemo, useState } from "react";
import styled from "styled-components";
import ModeEdit from "@mui/icons-material/ModeEdit";
import Delete from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useDeletePolicyConfirmationDialog } from "../hooks/useDeletePolicyHooks";

import { PolicyListItem } from "@/generated/reliabilityApi";
import { useOpenPolicyDrawer } from "@/components/reliability/components/pages/policies/policiesHooks";
import { useHasWritePermission } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasWritePermission";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useIsEditDefaultPolicyMode } from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerConfigurations/useIsEditDefaultPolicyMode";
import { isDefaultPolicy } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/isDefaultPolicy";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 24px;
  & > button {
    color: ${muiColors.gray[500]};
  }
`;

const {
  policies: {
    policiesTable: {
      deletePolicy: deletePriorityAriaLabel,
      editPolicy: editPriorityAriaLabel,
      viewPolicy: viewPriorityAriaLabel,
    },
  },
} = reliabilityArialLabels;

export const TableActions: React.FC<PolicyListItem> = (policyListItem) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const hasWritePermissions = useHasWritePermission();
  const isEditDefaultPolicyMode = useIsEditDefaultPolicyMode(policyListItem);
  const deletePolicyConfirmationDialog = useDeletePolicyConfirmationDialog({
    isOpen: openDeleteDialog,
    onClose: () => setOpenDeleteDialog(false),
    policyListItem,
  });

  const policyIsDefault = isDefaultPolicy(policyListItem);
  const openPolicyDrawer = useOpenPolicyDrawer();

  const canDeletePolicy = hasWritePermissions && !policyIsDefault;
  const canViewPolicy = !hasWritePermissions || policyIsDefault;
  const canEditPolicy =
    (hasWritePermissions && !policyIsDefault) || isEditDefaultPolicyMode;

  const actionButtons = useMemo(() => {
    const actionComponents: React.ReactNode[] = [];

    if (canEditPolicy) {
      actionComponents.push(
        <Tooltip title={"Edit"} placement={"top"} key={"Edit"}>
          <IconButton
            onClick={() => openPolicyDrawer("edit", policyListItem.id)}
            aria-label={editPriorityAriaLabel}
          >
            <ModeEdit fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    }

    if (canViewPolicy) {
      actionComponents.push(
        <Tooltip title={"View"} placement={"top"} key={"View"}>
          <IconButton
            onClick={() => openPolicyDrawer("view", policyListItem.id)}
            aria-label={viewPriorityAriaLabel}
          >
            <VisibilityIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    }

    canDeletePolicy &&
      actionComponents.push(
        <React.Fragment key={"Delete"}>
          <Tooltip title={"Delete"} placement={"top"}>
            <IconButton
              onClick={() => setOpenDeleteDialog(true)}
              aria-label={deletePriorityAriaLabel}
            >
              <Delete fontSize={"medium"} />
            </IconButton>
          </Tooltip>

          {deletePolicyConfirmationDialog}
        </React.Fragment>
      );

    return actionComponents;
  }, [
    canDeletePolicy,
    canViewPolicy,
    canEditPolicy,
    deletePolicyConfirmationDialog,
    openPolicyDrawer,
    policyListItem.id,
  ]);

  return <Container>{actionButtons}</Container>;
};
