import axios from "axios";
import { useMemo } from "react";

import { useRequestHeaders } from "../common-api/headers";
import { getAppConfig } from "../../config/appConfig";

import { useReportAxiosError } from "@/components/reliability/hooks/useReportAxiosError";

export type QueryOptions = {
  enabled?: boolean;
  refetchInterval?: number;
  keepPreviousData?: boolean;
  staleTime?: number;
  cacheTime?: number;
  skipWorkspaceId?: boolean;
};

export const useK8sAddonsApiClient = (skipWorkspaceId?: boolean) => {
  const headers = useRequestHeaders({
    skipAppViewInHeaders: skipWorkspaceId,
  });
  const reportAxiosError = useReportAxiosError();

  const client = useMemo(
    () =>
      axios.create({
        baseURL: getAppConfig().addonsAPIServiceServerURL,
        headers,
      }),
    [headers]
  );

  client.interceptors.response.use(undefined, (error) => {
    reportAxiosError(error);
    return Promise.reject(error);
  });

  return client;
};
