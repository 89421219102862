import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Komodor_Service_Static_Prevention_State,
  useFetchStaticPreventionResultForServiceQuery,
  useFetchWorkflowConfigurationByNameQuery,
} from "../../generated/graphql";
import {
  useQueryWithVariables,
  useQueryRefreshWithVariables,
} from "../../shared/hooks/useQueryWithVariables";

import {
  CheckRawReultWithSpecLevel,
  IgnoreCheckProps,
  StaticPreventionBasicConfiguration,
  StaticPreventionConfigurationVariables,
} from "./StaticPreventionUtils";

export const useStaticPreventionWorkflowConfiguration = (
  configurationName: string
): [StaticPreventionBasicConfiguration, () => void, boolean] => {
  const workflowConfigurationQueryVariables = useMemo(
    () => ({ name: configurationName }),
    [configurationName]
  );
  const [workflowConfigurationResult, refresh] = useQueryRefreshWithVariables(
    useFetchWorkflowConfigurationByNameQuery,
    workflowConfigurationQueryVariables
  );

  const [workflowConfiguration, isFetching] = useMemo(() => {
    const defualtConfiguration = {
      id: uuidv4(),
      variables: { staticPrevention: { polaris: { ignoredChecks: [] } } },
    };
    return workflowConfigurationResult
      ? workflowConfigurationResult.workflow_configuration.length > 0
        ? [
            {
              id: workflowConfigurationResult.workflow_configuration[0].id,
              variables: workflowConfigurationResult.workflow_configuration[0]
                .variables as StaticPreventionConfigurationVariables,
            },
            false,
          ]
        : [defualtConfiguration, false]
      : [defualtConfiguration, true];
  }, [workflowConfigurationResult]);

  return [workflowConfiguration, refresh, isFetching];
};

export type StaticPreventionWorkflowResult =
  | ({
      __typename?: "komodor_service_static_prevention_state" | undefined;
    } & Pick<Komodor_Service_Static_Prevention_State, "results">)[]
  | null;

export const useStaticPreventionWorkflowResult = (
  serviceId: string | undefined
): StaticPreventionWorkflowResult => {
  const variables = useMemo(
    () =>
      !serviceId
        ? null
        : {
            serviceId: serviceId,
          },
    [serviceId]
  );
  const fetchResult = useQueryWithVariables(
    useFetchStaticPreventionResultForServiceQuery,
    variables
  );

  return useMemo(
    () =>
      fetchResult ? fetchResult.komodor_service_static_prevention_state : null,
    [fetchResult]
  );
};

export const getStaticPreventionConfigurationVariables: (
  check: CheckRawReultWithSpecLevel,
  ignore: boolean,
  workflowConfiguration: StaticPreventionBasicConfiguration,
  expirationDate?: string
) => StaticPreventionConfigurationVariables = (
  check,
  ignore,
  workflowConfiguration,
  expirationDate
) => {
  const ignoreCheck: IgnoreCheckProps = {
    id: check.ID,
    specLevel: {
      type: check.SpecLevel,
      name: check.SpecName,
    },
    ignore: ignore,
    expirationDate: expirationDate ?? new Date().toISOString(),
  };

  const ignoredChecks =
    workflowConfiguration.variables.staticPrevention.polaris.ignoredChecks;

  const index = ignoredChecks.findIndex(
    (ic) =>
      ic.id === ignoreCheck.id &&
      ic.specLevel.name === ignoreCheck.specLevel.name &&
      ic.specLevel.type === ignoreCheck.specLevel.type
  );
  index === -1
    ? ignoredChecks.push(ignoreCheck)
    : (ignoredChecks[index] = ignoreCheck);
  return {
    staticPrevention: {
      polaris: {
        ignoredChecks: ignoredChecks,
      },
    },
  };
};
