import { useGetWorkloadsReplicasStatusMetrics } from "@/shared/hooks/metrics-api/client/useGetWorkloadsReplicasStatusMetrics";

type UseFetchWorkloadReplicasMetricsDataArgs = {
  komodorUid: string;
  fromEpoch: number;
  toEpoch: number;
};

export const useFetchWorkloadReplicasMetricsData = ({
  komodorUid,
  fromEpoch,
  toEpoch,
}: UseFetchWorkloadReplicasMetricsDataArgs) => {
  return useGetWorkloadsReplicasStatusMetrics({
    params: {
      fromEpoch,
      toEpoch,
      komodorUid,
    },
    enabled: true,
  });
};
