import React, { useEffect, useMemo, useState } from "react";

import { ServiceColumn } from "../../../shared/types";
import { InsightDrawerActionButton } from "../../../shared/styles";
import { useStaticPrevention } from "../../../../../../../../../StaticPrevention/useStaticPrevention";
import {
  CheckCategory,
  StaticPreventionResult,
} from "../../../../../../../../../StaticPrevention/StaticPreventionUtils";
import StaticPreventionModal from "../../../../../../../../../StaticPrevention/Modal";
import { overviewPageInsightsAriaLabels } from "../../../../../../appViewAriaLabels";
import { DeployEventsResponse } from "../../../../../../../../../../generated/resourcesApi";

const {
  bestPracticesSecurityFailed: { viewIssues: viewIssuesAriaLabel },
} = overviewPageInsightsAriaLabels;

type ViewIssueButtonProps = {
  rowData: ServiceColumn;
  defaultFilter?: CheckCategory;
};

export const ViewIssueButton: React.FC<ViewIssueButtonProps> = ({
  rowData: { id, cluster, namespace, serviceName },
  defaultFilter,
}) => {
  const staticPreventionData = useStaticPrevention(id);
  const [modalOpen, setModalOpen] = useState(false);

  const [parsedResults, setParsedResults] =
    useState<StaticPreventionResult | null>(null);

  const [workflowConfiguration, refreshConfiguration, configurationName] =
    staticPreventionData.configurationResults;

  useEffect(() => {
    if (staticPreventionData.parsedPolarisResults === null) return;
    setParsedResults(staticPreventionData.parsedPolarisResults);
  }, [staticPreventionData.parsedPolarisResults]);

  const lastDeploy = staticPreventionData.lastDeploy;

  const onClick = () => {
    setModalOpen(true);
  };

  const shouldShowModal = useMemo(() => {
    return (
      modalOpen && lastDeploy && parsedResults && !parsedResults.failedToParse
    );
  }, [modalOpen, lastDeploy, parsedResults]);

  return (
    <>
      <InsightDrawerActionButton
        onClick={onClick}
        aria-label={viewIssuesAriaLabel}
      >
        View issues
      </InsightDrawerActionButton>

      {shouldShowModal && (
        <StaticPreventionModal
          cluster={cluster}
          namespace={namespace}
          name={serviceName}
          serviceName={id}
          isOpen={modalOpen}
          modalCloseCallback={() => {
            setModalOpen(false);
          }}
          parsedResults={parsedResults as StaticPreventionResult}
          workflowConfiguration={workflowConfiguration}
          configurationName={configurationName}
          refreshConfiguration={refreshConfiguration}
          lastDeploy={lastDeploy as DeployEventsResponse}
          defaultFilter={defaultFilter}
        />
      )}
    </>
  );
};
