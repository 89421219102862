/* tslint:disable */
/* eslint-disable */
/**
 * AI API
 * This is an API that exposes ai-related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AnalyzeLogRequest
 */
export interface AnalyzeLogRequest {
    /**
     * 
     * @type {string}
     * @memberof AnalyzeLogRequest
     */
    'log': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyzeLogRequest
     */
    'podName': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyzeLogRequest
     */
    'containerName': string;
}


/**
 * Check if a given object implements the AnalyzeLogRequest interface.
 */
export function instanceOfAnalyzeLogRequest(value: object): value is AnalyzeLogRequest {
    if (!('log' in value) || value['log'] === undefined) return false;
    if (!('podName' in value) || value['podName'] === undefined) return false;
    if (!('containerName' in value) || value['containerName'] === undefined) return false;
    return true;
}

export function AnalyzeLogRequestFromJSON(json: any): AnalyzeLogRequest {
    return AnalyzeLogRequestFromJSONTyped(json, false);
}

export function AnalyzeLogRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyzeLogRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'log': json['log'],
        'podName': json['podName'],
        'containerName': json['containerName'],
    };
}

export function AnalyzeLogRequestToJSON(value?: AnalyzeLogRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'log': value['log'],
        'podName': value['podName'],
        'containerName': value['containerName'],
    };
}

        /**
 * 
 * @export
 * @interface AnalyzeLogResponse
 */
export interface AnalyzeLogResponse {
    /**
     * 
     * @type {string}
     * @memberof AnalyzeLogResponse
     */
    'rootCause': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyzeLogResponse
     */
    'solution': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalyzeLogResponse
     */
    'isProblemFound': boolean;
}


/**
 * Check if a given object implements the AnalyzeLogResponse interface.
 */
export function instanceOfAnalyzeLogResponse(value: object): value is AnalyzeLogResponse {
    if (!('rootCause' in value) || value['rootCause'] === undefined) return false;
    if (!('solution' in value) || value['solution'] === undefined) return false;
    if (!('isProblemFound' in value) || value['isProblemFound'] === undefined) return false;
    return true;
}

export function AnalyzeLogResponseFromJSON(json: any): AnalyzeLogResponse {
    return AnalyzeLogResponseFromJSONTyped(json, false);
}

export function AnalyzeLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyzeLogResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'rootCause': json['rootCause'],
        'solution': json['solution'],
        'isProblemFound': json['isProblemFound'],
    };
}

export function AnalyzeLogResponseToJSON(value?: AnalyzeLogResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rootCause': value['rootCause'],
        'solution': value['solution'],
        'isProblemFound': value['isProblemFound'],
    };
}

        /**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}


/**
 * Check if a given object implements the HTTPValidationError interface.
 */
export function instanceOfHTTPValidationError(value: object): value is HTTPValidationError {
    return true;
}

export function HTTPValidationErrorFromJSON(json: any): HTTPValidationError {
    return HTTPValidationErrorFromJSONTyped(json, false);
}

export function HTTPValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): HTTPValidationError {
    if (json == null) {
        return json;
    }
    return {
        
        'detail': json['detail'] == null ? undefined : ((json['detail'] as Array<any>).map(ValidationErrorFromJSON)),
    };
}

export function HTTPValidationErrorToJSON(value?: HTTPValidationError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'detail': value['detail'] == null ? undefined : ((value['detail'] as Array<any>).map(ValidationErrorToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'container'?: string;
}


/**
 * Check if a given object implements the Resource interface.
 */
export function instanceOfResource(value: object): value is Resource {
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if (json == null) {
        return json;
    }
    return {
        
        'kind': json['kind'],
        'namespace': json['namespace'],
        'name': json['name'],
        'container': json['container'] == null ? undefined : json['container'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kind': value['kind'],
        'namespace': value['namespace'],
        'name': value['name'],
        'container': value['container'],
    };
}

        /**
 * 
 * @export
 * @interface SessionData
 */
export interface SessionData {
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionData
     */
    'isComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionData
     */
    'isStuck'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionData
     */
    'operations'?: Array<string>;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof SessionData
     */
    'queries'?: Array<Resource>;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'problemShort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'problemElaborate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionData
     */
    'whatHappened'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'evidence'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'evidenceSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    'recommendation'?: string;
}


/**
 * Check if a given object implements the SessionData interface.
 */
export function instanceOfSessionData(value: object): value is SessionData {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isComplete' in value) || value['isComplete'] === undefined) return false;
    return true;
}

export function SessionDataFromJSON(json: any): SessionData {
    return SessionDataFromJSONTyped(json, false);
}

export function SessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isComplete': json['isComplete'],
        'isStuck': json['isStuck'] == null ? undefined : json['isStuck'],
        'operations': json['operations'] == null ? undefined : json['operations'],
        'queries': json['queries'] == null ? undefined : ((json['queries'] as Array<any>).map(ResourceFromJSON)),
        'problemShort': json['problemShort'] == null ? undefined : json['problemShort'],
        'problemElaborate': json['problemElaborate'] == null ? undefined : json['problemElaborate'],
        'whatHappened': json['whatHappened'] == null ? undefined : json['whatHappened'],
        'evidence': json['evidence'] == null ? undefined : json['evidence'],
        'evidenceSource': json['evidenceSource'] == null ? undefined : json['evidenceSource'],
        'recommendation': json['recommendation'] == null ? undefined : json['recommendation'],
    };
}

export function SessionDataToJSON(value?: SessionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'isComplete': value['isComplete'],
        'isStuck': value['isStuck'],
        'operations': value['operations'],
        'queries': value['queries'] == null ? undefined : ((value['queries'] as Array<any>).map(ResourceToJSON)),
        'problemShort': value['problemShort'],
        'problemElaborate': value['problemElaborate'],
        'whatHappened': value['whatHappened'],
        'evidence': value['evidence'],
        'evidenceSource': value['evidenceSource'],
        'recommendation': value['recommendation'],
    };
}

        /**
 * 
 * @export
 * @interface SessionGetResponse
 */
export interface SessionGetResponse {
    /**
     * 
     * @type {SessionData}
     * @memberof SessionGetResponse
     */
    'session': SessionData | null;
}


/**
 * Check if a given object implements the SessionGetResponse interface.
 */
export function instanceOfSessionGetResponse(value: object): value is SessionGetResponse {
    if (!('session' in value) || value['session'] === undefined) return false;
    return true;
}

export function SessionGetResponseFromJSON(json: any): SessionGetResponse {
    return SessionGetResponseFromJSONTyped(json, false);
}

export function SessionGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionGetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'session': SessionDataFromJSON(json['session']),
    };
}

export function SessionGetResponseToJSON(value?: SessionGetResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'session': SessionDataToJSON(value['session']),
    };
}

        /**
 * 
 * @export
 * @interface SessionsBody
 */
export interface SessionsBody {
    /**
     * 
     * @type {string}
     * @memberof SessionsBody
     */
    'requestId': string;
    /**
     * 
     * @type {string}
     * @memberof SessionsBody
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof SessionsBody
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof SessionsBody
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsBody
     */
    'name': string;
}


/**
 * Check if a given object implements the SessionsBody interface.
 */
export function instanceOfSessionsBody(value: object): value is SessionsBody {
    if (!('requestId' in value) || value['requestId'] === undefined) return false;
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function SessionsBodyFromJSON(json: any): SessionsBody {
    return SessionsBodyFromJSONTyped(json, false);
}

export function SessionsBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionsBody {
    if (json == null) {
        return json;
    }
    return {
        
        'requestId': json['requestId'],
        'cluster': json['cluster'],
        'kind': json['kind'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'name': json['name'],
    };
}

export function SessionsBodyToJSON(value?: SessionsBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requestId': value['requestId'],
        'cluster': value['cluster'],
        'kind': value['kind'],
        'namespace': value['namespace'],
        'name': value['name'],
    };
}

        /**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}


/**
 * Check if a given object implements the ValidationError interface.
 */
export function instanceOfValidationError(value: object): value is ValidationError {
    if (!('msg' in value) || value['msg'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function ValidationErrorFromJSON(json: any): ValidationError {
    return ValidationErrorFromJSONTyped(json, false);
}

export function ValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationError {
    if (json == null) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'type': json['type'],
    };
}

export function ValidationErrorToJSON(value?: ValidationError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'msg': value['msg'],
        'type': value['type'],
    };
}

    
        /**
* 
* @summary Handle Find Session
* @param params DefaultApiApiV1SessionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1SessionsGetUrl = (params: DefaultApiApiV1SessionsGetRequest, baseUrl: string): string => {
    // verify required parameter 'requestId' is not null or undefined
    assertParamExists('apiV1SessionsGet', 'requestId', params.requestId)
    // verify required parameter 'cluster' is not null or undefined
    assertParamExists('apiV1SessionsGet', 'cluster', params.cluster)
    // verify required parameter 'kind' is not null or undefined
    assertParamExists('apiV1SessionsGet', 'kind', params.kind)
    // verify required parameter 'name' is not null or undefined
    assertParamExists('apiV1SessionsGet', 'name', params.name)
    const localVarPath = `/api/v1/sessions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.requestId !== undefined) {
            localVarQueryParameter['requestId'] = params.requestId;
    }
    if (params.cluster !== undefined) {
            localVarQueryParameter['cluster'] = params.cluster;
    }
    if (params.kind !== undefined) {
            localVarQueryParameter['kind'] = params.kind;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @summary Handle Get Session By ID
* @param params DefaultApiApiV1SessionsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1SessionsIdGetUrl = (params: DefaultApiApiV1SessionsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1SessionsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/sessions/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @summary Handle Start AI Investigator Session
* @param params DefaultApiApiV1SessionsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1SessionsPostUrl = (params: DefaultApiApiV1SessionsPostRequest, baseUrl: string): string => {
    // verify required parameter 'sessionsBody' is not null or undefined
    assertParamExists('apiV1SessionsPost', 'sessionsBody', params.sessionsBody)
    const localVarPath = `/api/v1/sessions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1SessionsGet operation in DefaultApi.
* @export
* @interface DefaultApiApiV1SessionsGetRequest
*/
export interface DefaultApiApiV1SessionsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof DefaultApiApiV1SessionsGet
    */
    readonly requestId: string

    /**
    * 
    * @type {string}
    * @memberof DefaultApiApiV1SessionsGet
    */
    readonly cluster: string

    /**
    * 
    * @type {string}
    * @memberof DefaultApiApiV1SessionsGet
    */
    readonly kind: string

    /**
    * 
    * @type {string}
    * @memberof DefaultApiApiV1SessionsGet
    */
    readonly name: string

    /**
    * 
    * @type {string}
    * @memberof DefaultApiApiV1SessionsGet
    */
    readonly namespace?: string
}

/**
* Request parameters for apiV1SessionsIdGet operation in DefaultApi.
* @export
* @interface DefaultApiApiV1SessionsIdGetRequest
*/
export interface DefaultApiApiV1SessionsIdGetRequest {
    /**
    * The ID of the session to retrieve
    * @type {string}
    * @memberof DefaultApiApiV1SessionsIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1SessionsPost operation in DefaultApi.
* @export
* @interface DefaultApiApiV1SessionsPostRequest
*/
export interface DefaultApiApiV1SessionsPostRequest {
    /**
    * 
    * @type {SessionsBody}
    * @memberof DefaultApiApiV1SessionsPost
    */
    readonly sessionsBody: SessionsBody

    /**
    * 
    * @type {boolean}
    * @memberof DefaultApiApiV1SessionsPost
    */
    readonly xRateLimit?: boolean
}


    
        /**
* 
* @param params LogsApiApiV1LogsAnalyzePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1LogsAnalyzePostUrl = (params: LogsApiApiV1LogsAnalyzePostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/logs/analyze`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1LogsAnalyzePost operation in LogsApi.
* @export
* @interface LogsApiApiV1LogsAnalyzePostRequest
*/
export interface LogsApiApiV1LogsAnalyzePostRequest {
    /**
    * 
    * @type {AnalyzeLogRequest}
    * @memberof LogsApiApiV1LogsAnalyzePost
    */
    readonly analyzeLogRequest?: AnalyzeLogRequest
}


    
