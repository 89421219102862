import axios from "axios";
import { useMemo } from "react";

import { getAppConfig } from "../../../config/appConfig";
import { useRequestHeaders } from "../../common-api/headers";

const getResourcesAPIBasePath = (): string => {
  const serverBaseURL = getAppConfig().resourcesAPIServerURL;
  return serverBaseURL;
};

export const useResourcesApiClient = (skipAppViewInHeaders?: boolean) => {
  const headers = useRequestHeaders({
    skipAppViewInHeaders,
  });
  return useMemo(
    () =>
      axios.create({
        baseURL: getResourcesAPIBasePath(),
        headers,
      }),
    [headers]
  );
};
