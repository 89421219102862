import {
  FetchingStateProps,
  ClusterOverviewStore,
} from "./clusterOverviewStore";

// getters
export const fetchingStateSelector = (
  state: ClusterOverviewStore
): FetchingStateProps => state.fetchingState;

// setters
export const setFetchingStateSelector = (
  state: ClusterOverviewStore
): ((fetchingState: {
  key: keyof FetchingStateProps;
  value: boolean;
}) => void) => state.setFetchingState;

export const resetFetchingStateSelector = (
  state: ClusterOverviewStore
): (() => void) => state.resetFetchingState;
