import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "../../common/controls/Autocomplete";

import { useClusterSuggestions } from "@/shared/hooks/workspaces-api/clusters/useClusterSuggestions";

export const ClusterSelector: React.FC<{
  selectedCluster: string | undefined;
  onChange: (cluster: string) => void;
}> = ({ selectedCluster, onChange: selectCluster }) => {
  const { control, watch } = useForm();
  const watchCluster = watch("cluster", selectedCluster ?? null);
  const [suggestions, setSuggestions] = useState<
    { label: string; value: string }[]
  >([]);

  const latestSuggestions = useClusterSuggestions();

  useEffect(() => {
    if (!isEqual(suggestions, latestSuggestions)) {
      setSuggestions(latestSuggestions);
    }
  }, [latestSuggestions, suggestions]);

  return suggestions.length ? (
    <Controller
      name="cluster"
      control={control}
      defaultValue={watchCluster}
      render={() => (
        <Autocomplete
          fieldname="cluster"
          placeholder="choose cluster"
          defaultSelected={
            watchCluster
              ? { label: watchCluster, value: watchCluster }
              : suggestions.at(0)
          }
          suggestions={suggestions}
          onSelectedChange={selectCluster}
        />
      )}
    />
  ) : (
    <></>
  );
};
