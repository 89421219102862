import { PodOutputWithLogs } from "../../CommonTypes";

export const PREVIOUS_SUFFIX = "previous";

export type AvailabilityCheckType =
  | "workload-info"
  | "workload-describe"
  | "workload-pods-healthy"
  | `workload-info-previous`
  | `workload-describe-previous`
  | `workload-pods-healthy-previous`;

export interface WorkloadDescribeOutput {
  serviceId: string;
  description: string;
  latestDeployTime: string;
  image: string;
  eventTime?: Date;
  isWorkloadDescribeAllowed: boolean;
}

export interface ContainerLog {
  name: string;
  logTaskId: string;
  podName: string;
}

export interface PodEvent {
  eventTime: Date;
  podName: string;
  phase: string;
  reason: string;
  message: string;
  exitCode: string;
  failedContainer: string;
  statusConditions: string;
  waitingReason: string;
  terminatedReason: string;
}

export interface AvailabilitySummaryOutput {
  reason: string;
  errorMessage?: string;
  exitCode?: string;
  containersLogs: ContainerLog[];
  count: number;
}

export interface WorkloadHealthyPodsOutput {
  pods?: PodOutputWithLogs[];
  desiredReplicas: number;
  readyReplicas: number;
  isPodEventAllowed: boolean;
  isPodLogsAllowed: boolean;
  failureMessage: string;
  issueSummary: AvailabilitySummaryOutput[];
  podsCount: number;
}

export type JobsPodsOutput = WorkloadHealthyPodsOutput;
export interface ExternalDetails {
  workloadId: string;
  eventTime?: Date;
  services?: string[];
  clusterName?: string;
  namespace?: string;
}

export interface WorkloadInfoOutput {
  latestDeploy: {
    id: string;
    clusterName: string;
    namespace: string;
    deploymentName: string;
    eventTime: string;
    endEventTime: string;
    versionFrom: string;
    versionTo: string;
    services: string[];
    generation?: number;
  };
  infoTime?: string;
  closedAt?: string;
  failedPod?: string;
}
