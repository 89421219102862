import React, { useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import {
  BODY_SCROLLBAR_WIDTH,
  INSIGHTS_DRAWER_EASING_FUNCTION,
  INSIGHTS_DRAWER_TRANSITION_DURATION,
  MAX_INSIGHTS_DRAWER_CONTENT_WIDTH,
} from "../insightsConstants";
import { useOverviewPageStore } from "../../store/overviewPageStore";
import {
  selectCloseInsightsDrawer,
  selectInsightsDrawer,
  selectSetInsightsDrawerContent,
} from "../../store/overviewPageStoreSelectors";
import { overviewPageInsightsAriaLabels } from "../../../../appViewAriaLabels";

import {
  useDocumentBodyScrollOnDrawerOpen,
  useOnClickOutside,
  useOnEscClick,
  useSetPageTransition,
} from "./insightsDrawerHooks";

const boxShadow =
  "rgba(0, 0, 0, 0.2) 0 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px";

const FixedContainer = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  transition: transform ${INSIGHTS_DRAWER_TRANSITION_DURATION}ms
    ${INSIGHTS_DRAWER_EASING_FUNCTION};
  transform: ${({ active }) => (active ? "translateX(0)" : `translateX(100%)`)};
`;

const Container = styled.div<{ active: boolean; width: number }>`
  width: ${({ width }) => width}px;
  height: 100vh;
  background-color: ${muiColors.common.white};
  top: 0;
  right: 0;
  overflow-y: auto;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${({ active }) => (!active ? "none" : `${boxShadow}`)};
`;

const paddingStyle = css`
  padding: 20px 40px;
`;
const TopAreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${muiColors.gray[200]};
  ${paddingStyle};
`;

const ComponentContainer = styled.div`
  max-width: ${MAX_INSIGHTS_DRAWER_CONTENT_WIDTH}px;
  ${paddingStyle};
`;

const StyledCloseIcon = styled(CloseOutlined)`
  && {
    color: ${muiColors.gray[500]};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const {
  insightsDrawer: { close: closeInsightsDrawerAriaLabel },
} = overviewPageInsightsAriaLabels;

export const InsightsDrawer: React.FC = () => {
  const setInsightsDrawerContent = useOverviewPageStore(
    selectSetInsightsDrawerContent
  );
  const { insightsDrawerContent, insightsDrawerOpen } =
    useOverviewPageStore(selectInsightsDrawer);
  const closeInsightsDrawer = useOverviewPageStore(selectCloseInsightsDrawer);

  const containerRef = useRef<HTMLDivElement>(null);
  const { title, component, id, ariaLabel } = insightsDrawerContent || {};

  const onClose = useCallback(
    (closedDrawerContentId: string) => {
      setTimeout(() => {
        if (id === closedDrawerContentId) {
          setInsightsDrawerContent(undefined);
        }
      }, INSIGHTS_DRAWER_TRANSITION_DURATION);
    },
    [id, setInsightsDrawerContent]
  );

  const onCloseClick = useCallback(() => {
    closeInsightsDrawer();
    id && onClose(id);
  }, [closeInsightsDrawer, id, onClose]);

  const { insightsDrawerWidth } = useSetPageTransition();

  useDocumentBodyScrollOnDrawerOpen(containerRef);

  useOnEscClick(onCloseClick);
  useOnClickOutside(containerRef, onCloseClick);

  return (
    <FixedContainer
      active={insightsDrawerOpen}
      {...(!!ariaLabel && {
        "aria-label": `insight drawer content for ${ariaLabel}`,
      })}
    >
      <Container
        ref={containerRef}
        active={insightsDrawerOpen}
        width={insightsDrawerWidth + BODY_SCROLLBAR_WIDTH}
      >
        <TopAreaContainer>
          <Typography variant={"h3"}>{title}</Typography>
          <IconButton
            aria-label={closeInsightsDrawerAriaLabel}
            onClick={onCloseClick}
          >
            <StyledCloseIcon />
          </IconButton>
        </TopAreaContainer>
        <FlexContainer>
          <ComponentContainer>{component}</ComponentContainer>
        </FlexContainer>
      </Container>
    </FixedContainer>
  );
};
