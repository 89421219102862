import { getAppConfig } from "../../../config/appConfig";
import { LocalStorageItem } from "../../localStorageSettings";

const storedDevManageRbac = new LocalStorageItem("currentManageRbac");

export const getStoredDevUserManageRbac = (): string | null => {
  const devUserRbac = storedDevManageRbac.get();
  if (devUserRbac) {
    return devUserRbac;
  }
  const defaultRbac =
    "{manage:users,manage:monitors,manage:integrations,manage:helm,manage:features,manage:reliability,manage:reliability-policies,manage:workspaces,manage:kubeconfig,*}";
  if (!devUserRbac && getAppConfig().env === "development") {
    storedDevManageRbac.set(defaultRbac);
  }
  return defaultRbac;
};
