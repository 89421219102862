import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeOverCommittedDetails from "./detailsComponents/NodeOverCommittedDetails";

export interface OverCommittedNodeOutput {
  node: string;
  cpu?: {
    limits?: OverCommittedNodeResource;
    allocatable?: OverCommittedNodeResource;
  };
  memory?: {
    limits?: OverCommittedNodeResource;
    allocatable?: OverCommittedNodeResource;
  };
}

export interface OverCommittedNodeResource {
  text: string;
  value: string;
  unit: string;
}
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeOverCommittedCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "Node Overcommitted";
    this.description = `A node is overcommitted when the sum of limits across all pods on that node exceeds available node allocatable resources. This could lead to CPU throttling or memory problems.`;
    this.action = (
      <>
        We ran <GrayHighlight>kubectl describe node {"<NODE>"}</GrayHighlight>{" "}
        and check if the total resource limits for pods on that node exceed the
        node's total capacity.
      </>
    );
    this.question = "Is this node over committed?";
    this.output = check.output as OverCommittedNodeOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeOverCommittedDetails check={this} />;
  }
}
