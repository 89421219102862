import React, { createContext, useContext, useState } from "react";

export interface AvailablePort {
  name?: string;
  port: number;
  protocol: string;
  targetPort?: number;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum SupportedResourceKindEnum {
  Deployment = "Deployment",
  Service = "Service",
  Pod = "Pod",
  Job = "Job",
  DaemonSet = "DaemonSet",
  StatefulSet = "StatefulSet",
}

interface PortForwardContext {
  isPortForwardModalOpen: boolean;
  setIsPortForwardModalOpen: (isPortForwardModalOpen: boolean) => void;
  availablePorts?: AvailablePort[];
  setAvailablePorts: (availablePorts: AvailablePort[]) => void;
  resourceName: string;
  setResourceName: (resourceName: string) => void;
  resourceKind?: string;
  setResourceKind: (resourceKind: SupportedResourceKindEnum) => void;
  namespace: string;
  setNamespace: (namespace: string) => void;
  clusterName: string;
  setClusterName: (clusterName: string) => void;
}

const initialState: PortForwardContext = {
  isPortForwardModalOpen: false,
  setIsPortForwardModalOpen: () => undefined,
  availablePorts: undefined,
  setAvailablePorts: () => undefined,
  resourceName: "",
  setResourceKind: () => undefined,
  resourceKind: "",
  setResourceName: () => undefined,
  namespace: "",
  setNamespace: () => undefined,
  clusterName: "",
  setClusterName: () => undefined,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const portForwardCtx = createContext<PortForwardContext>(initialState);

// This is a really good candidate for zustand usage.
export const PortForwardProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [availablePorts, setAvailablePorts] = useState<AvailablePort[]>();
  const [resourceKind, setResourceKind] = useState(initialState.resourceKind);
  const [resourceName, setResourceName] = useState(initialState.resourceName);
  const [clusterName, setClusterName] = useState(initialState.clusterName);
  const [namespace, setNamespace] = useState(initialState.namespace);
  const [isPortForwardModalOpen, setIsPortForwardModalOpen] = useState<boolean>(
    initialState.isPortForwardModalOpen
  );

  return (
    <portForwardCtx.Provider
      value={{
        isPortForwardModalOpen,
        setIsPortForwardModalOpen,
        setAvailablePorts,
        availablePorts,
        resourceName,
        setResourceName,
        resourceKind,
        setResourceKind,
        namespace,
        setNamespace,
        clusterName,
        setClusterName,
      }}
    >
      {children}
    </portForwardCtx.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const usePortForward = (): PortForwardContext =>
  useContext(portForwardCtx);
