import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { UpgradeButtonAndModal } from "../../../shared/components/UpgradeButtonAndModal";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectMetricsSupportAgentInfo } from "../../../store/overviewPageStoreSelectors";
import { InsightContainer } from "../../../../../../../../Insights/InsightsCard";

import {
  insightsUnavailableDescription,
  insightsUnavailableTitle,
} from "./texts";

const StyledContainer = styled(InsightContainer)`
  && {
    flex-direction: column;
    background-color: transparent;
    align-items: flex-start;
    cursor: auto;
    & > div {
      margin: 0;
    }
  }
`;

export const ID = "Insights Unavailable Prompt";

export const InsightsUnavailablePrompt: React.FC = () => {
  const { unsupportedClusters, supportedClusters } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  if (supportedClusters.length) return null;

  // show component if no clusters support metrics
  return (
    <StyledContainer
      $loading={false}
      selected={false}
      aria-label={`insight card for ${ID}`}
    >
      <Typography variant={"h5"}>{insightsUnavailableTitle}</Typography>
      <Typography variant={"body3"}>
        {insightsUnavailableDescription}
      </Typography>
      <UpgradeButtonAndModal unsupportedClusters={unsupportedClusters} />
    </StyledContainer>
  );
};
