import { theme } from "@komodorio/design-system";
import { Tag, Typography } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import KubernetesDataTag from "../../../servicesView/ServiceTileTag/ServiceTileTag";
import Resource from "../../resources";

const Data = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 1rem 2rem;
  margin-block-start: 1rem;
`;
const DataField = styled(Typography).attrs({
  bold: true,
  color: theme.foreground.fgSubtle,
})``;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.75rem;
`;

const MAX_TAG_VALUE_LENGTH = 100;

interface KubernetesDataProps {
  resource: Resource;
  children?: React.ReactNode;
}
const KubernetesData: React.FC<KubernetesDataProps> = ({
  resource,
  children,
}) => {
  const { cluster, namespace, kind, labels, annotations } = resource;
  return (
    <>
      <Typography variant="title" size="large">
        Kubernetes data
      </Typography>
      <Data>
        {children}
        <DataField>Type:</DataField>
        <Typography>
          <Tag>
            type:<b>{kind}</b>
          </Tag>
        </Typography>
        <DataField>Cluster:</DataField>
        <Typography>
          <Tag>
            cluster:<b>{cluster}</b>
          </Tag>
        </Typography>
        <DataField>Namespace:</DataField>
        <Typography>
          <Tag>
            namespace:<b>{namespace}</b>
          </Tag>
        </Typography>
        {Object.keys(annotations).length > 0 && (
          <>
            <DataField>Annotations:</DataField>
            <Typography>
              <Tags>
                {Object.entries(annotations)
                  .filter(
                    ([key]) =>
                      !key.startsWith("app.komodor.com") &&
                      !key.startsWith("app.komodor.io")
                  )
                  .map(([key, value]) => (
                    <KubernetesDataTag
                      key={key}
                      tag={{
                        label: key,
                        value: value,
                      }}
                      maxLength={MAX_TAG_VALUE_LENGTH}
                    />
                  ))}
              </Tags>
            </Typography>
          </>
        )}
        {Object.keys(labels).length > 0 && (
          <>
            <DataField>Labels:</DataField>
            <Typography>
              <Tags>
                {Object.entries(labels).map(([key, value]) => (
                  <KubernetesDataTag
                    key={key}
                    tag={{ label: key, value: value }}
                    maxLength={MAX_TAG_VALUE_LENGTH}
                  />
                ))}
              </Tags>
            </Typography>
          </>
        )}
      </Data>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default KubernetesData;
