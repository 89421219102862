/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";

import {
  CostOptimizationConfig,
  CostOptimizationConfigEntry,
  CostOptimizationConfigFetchState,
} from "../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../store/costOptimizationStore";
import {
  selectConfigState,
  selectSetConfigFetchState,
  selectSetConfigUpdateState,
  selectSetLastVisitTime,
} from "../../store/costOptimizationStoreSelectors";
import {
  useKomodorCostAPIGet,
  useKomodorCostAPIPost,
} from "../../../../shared/hooks/komodor-cost-api/client";
import {
  COST_CONFIG_GET_ENDPOINT,
  COST_CONFIG_POST_ENDPOINT,
} from "../../../../shared/hooks/komodor-cost-api/requestResponseMaps";
import {
  CostApiApiV1ConfigGetRequest,
  GetConfigurationResponse,
  GetConfigurationResponseConfigurationsInner,
  UpdateConfigurationRequest,
} from "../../../../generated/komodorCost";
import { mockConfigSettings } from "../../__tests__/mockData/mockConfigSettings";
import { initialSettingsConfiguration } from "../../store/initialState";
import { notifyDDError } from "../../../../shared/hooks/exceptionManagement";
import { isDevMode } from "../../../../shared/utils/isDevMode";
import { useSetMockDataEffect } from "../../hooks/useSetMockDataEffect";

import { ALL_CLUSTER_NAMES_SELECTOR } from "./constants";
import { getValidEntriesFromFetchedConfig } from "./costOptimizationSettingsUtils";

const isConfigFetchStateEqual = (
  currentState: Partial<CostOptimizationConfigFetchState>,
  updatedState: Partial<CostOptimizationConfigFetchState>
) => {
  const {
    data: currentData,
    error: currentError,
    loading: currentLoading,
  } = currentState;

  const { loading, data, error } = updatedState;

  return isEqual(
    { data: currentData, loading: currentLoading, error: currentError },
    { data: data, loading, error }
  );
};

const useUpdateConfigState = () => {
  const setConfigState = useCostOptimizationStore(selectSetConfigFetchState);
  const { fetchState } = useCostOptimizationStore(selectConfigState);
  const setLastVisitTime = useCostOptimizationStore(selectSetLastVisitTime);

  return useCallback(
    (
      { loading, error, data }: Partial<CostOptimizationConfigFetchState>,
      updateLastVisitTime: boolean
    ) => {
      const hasData = data?.configurations && data.configurations.length > 0;
      const mergedData = hasData
        ? { ...fetchState.data, ...data }
        : fetchState.data;
      const updatedFetchState = { data: mergedData, loading, error };
      if (isConfigFetchStateEqual(fetchState, updatedFetchState)) return;
      setConfigState(updatedFetchState);

      if (updateLastVisitTime && hasData) {
        // update lastVisitTime to trigger re-fetching of data
        setLastVisitTime(new Date().getTime());
      }
    },
    [fetchState, setConfigState, setLastVisitTime]
  );
};

export const useFetchSettingsConfigData = (): void => {
  const updateConfigState = useUpdateConfigState();
  const lastUpdate = useRef<CostOptimizationConfigFetchState>();
  const hasUpdatedDataFromServer = useRef(false);

  // simulate server responses
  const mockGetConfigResponse = useMemo(
    () => (isDevMode() ? mockConfigSettings : undefined),
    []
  );

  const params = useMemo<CostApiApiV1ConfigGetRequest>(
    () => ({ clusterNames: [ALL_CLUSTER_NAMES_SELECTOR] }),
    []
  );

  const res = useSetMockDataEffect<GetConfigurationResponse>({
    mockData: mockGetConfigResponse,
    requestParams: params,
  });
  /////////////////////////////

  const serverRes = useKomodorCostAPIGet(
    COST_CONFIG_GET_ENDPOINT,
    params,
    isDevMode() || hasUpdatedDataFromServer.current
  );

  useEffect(() => {
    const dataToUpdate = isDevMode() ? res : serverRes;
    if (
      isEqual(lastUpdate.current, dataToUpdate) ||
      hasUpdatedDataFromServer.current
    )
      return;
    lastUpdate.current = dataToUpdate;
    updateConfigState(dataToUpdate, false);

    if (dataToUpdate.data?.configurations || dataToUpdate.error) {
      // store was updated with data from the server, do not update again
      hasUpdatedDataFromServer.current = true;
    }

    if (dataToUpdate.error) {
      notifyDDError({
        name: "Komodor Cost - Fetch Settings Config Data",
        message: `Error during fetching config data: ${dataToUpdate.error}`,
      });
    }
  }, [res, serverRes, updateConfigState]);
};

export const useUpdateSettingsConfigData = (
  newConfigState: CostOptimizationConfigEntry
): (() => void) => {
  const updateConfigState = useUpdateConfigState();
  const setConfigUpdateState = useCostOptimizationStore(
    selectSetConfigUpdateState
  );
  const { updateState } = useCostOptimizationStore(selectConfigState);
  const [isUpdating, setIsUpdating] = useState(false);

  const params = useMemo<UpdateConfigurationRequest>(
    () => newConfigState,
    [newConfigState]
  );

  // simulate server responses
  const mockRes = useSetMockDataEffect<GetConfigurationResponse>({
    mockData: { configurations: [] },
    requestParams: params,
  });
  /////////////////////////////

  const {
    data: apiData,
    error: apiError,
    loading: apiLoading,
  } = useKomodorCostAPIPost(
    COST_CONFIG_POST_ENDPOINT,
    params,
    !isUpdating || isDevMode()
  );

  useEffect(() => {
    if (!isUpdating) return;
    const relevantData = isDevMode()
      ? { loading: mockRes.loading, error: mockRes.error }
      : { loading: apiLoading, error: apiError };
    if (!isEqual(relevantData, updateState)) {
      setConfigUpdateState(relevantData);
    }
    const hasValidRes =
      (!!mockRes.data || apiData) &&
      !relevantData.loading &&
      !relevantData.error;

    if (relevantData.error) {
      setIsUpdating(false);
      notifyDDError({
        name: "Komodor Cost - Update Settings Config Data",
        message: `Error during update config: ${relevantData.error}`,
      });
    }

    if (hasValidRes) {
      // update local config state
      const { clusterNames, configuration } = newConfigState;
      updateConfigState(
        {
          ...relevantData,
          data: {
            configurations: [{ clusterName: clusterNames[0], configuration }],
          },
        },
        true
      );
      setIsUpdating(false);
    }
  }, [
    apiData,
    apiError,
    apiLoading,
    isUpdating,
    mockRes,
    newConfigState,
    setConfigUpdateState,
    updateConfigState,
    updateState,
  ]);

  return useCallback(async () => {
    setIsUpdating(true);
    setConfigUpdateState({ loading: true, error: null });
  }, [setConfigUpdateState]);
};

export const useFindConfigByClusterNames = () => {
  const {
    fetchState: { data },
  } = useCostOptimizationStore(selectConfigState);
  const { configurations = [] } = data ?? {};
  return useCallback(
    (
      clusterNames: string[]
    ): GetConfigurationResponseConfigurationsInner | undefined => {
      return configurations?.find((config) => {
        return clusterNames.includes(config.clusterName ?? "");
      });
    },
    [configurations]
  );
};

export const useGetInitialConfigEntry = () => {
  const findConfigByClusterName = useFindConfigByClusterNames();
  const {
    fetchState: { data },
  } = useCostOptimizationStore(selectConfigState);
  const { configurations = [] } = data ?? {};

  return useMemo<GetConfigurationResponseConfigurationsInner>(() => {
    return (
      findConfigByClusterName([ALL_CLUSTER_NAMES_SELECTOR]) ?? configurations[0]
    );
  }, [configurations, findConfigByClusterName]);
};

export const useResetUpdateConfigState = () => {
  const setConfigUpdateState = useCostOptimizationStore(
    selectSetConfigUpdateState
  );
  return useCallback(() => {
    setConfigUpdateState({
      loading: false,
      error: null,
    });
  }, [setConfigUpdateState]);
};

export const useCloseConfigModalOnUpdateEffect = (closeModal: () => void) => {
  const { updateState } = useCostOptimizationStore(selectConfigState);
  const isRequestingUpdate = useRef(false);
  useEffect(() => {
    const { loading, error } = updateState;
    if (loading) {
      isRequestingUpdate.current = true;
    }
    if (error) {
      isRequestingUpdate.current = false;
    }
    if (isRequestingUpdate.current && !loading && !error) {
      closeModal();
    }
  }, [closeModal, updateState, updateState.loading]);
};

export const useUpdateFormStateFromFetchedConfig = (
  formState: CostOptimizationConfig,
  setFormState: (config: CostOptimizationConfig) => void
) => {
  const initialConfig = useGetInitialConfigEntry();
  const hasUpdatedFormState = useRef(false);

  useEffect(() => {
    // initial sync with fetched config
    const initialConfigValidEntries = getValidEntriesFromFetchedConfig(
      formState,
      initialConfig.configuration
    );

    const mergedConfig = {
      ...formState,
      ...initialConfigValidEntries,
    };

    if (hasUpdatedFormState.current) return;
    if (
      isEqual(formState, initialSettingsConfiguration) &&
      !isEqual(mergedConfig, formState)
    ) {
      hasUpdatedFormState.current = true;
      setFormState(mergedConfig);
    }
  }, [formState, initialConfig, setFormState]);
};
