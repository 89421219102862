import React from "react";
import styled from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Typography, Skeleton } from "@komodorio/design-system/deprecated";
import { QuestionCircle16 } from "@komodorio/design-system/icons";
import { TooltipWrapper } from "react-tooltip";

import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";
import { AgentStatus } from "../../../shared/context/electron/getClusterStatus";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusView = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const StatusSkeleton: React.FC = () => (
  <Skeleton height="1.2rem" width="4rem" />
);

const availableStatusView = {
  description: "Available",
  color: theme.foreground.fgGreen,
};

const clusterDisconnectedStatusView = {
  description: "Offline",
  color: theme.foreground.fgPink,
};

const agentStatusViews: Record<
  AgentStatus,
  { description: string; color: string }
> = {
  [AgentStatus.STARTING]: {
    description: "Connecting...",
    color: theme.foreground.fgGreen,
  },
  [AgentStatus.RUNNING]: {
    description: "Connecting...",
    color: theme.foreground.fgGreen,
  },
  [AgentStatus.CONNECTED]: {
    description: "Connected",
    color: theme.foreground.fgGreen,
  },
  [AgentStatus.FAILED_ATTEMPT]: {
    description: "Connecting...",
    color: theme.foreground.fgGreen,
  },
  [AgentStatus.FAILED]: {
    description: "Agent Failed",
    color: theme.foreground.fgPink,
  },
  [AgentStatus.TERMINATING]: {
    description: "Terminating...",
    color: theme.foreground.fgPink,
  },
  [AgentStatus.TERMINATED]: {
    description: "Terminated",
    color: theme.foreground.fgPink,
  },
};

const getStatusView = (
  isClusterConnected: boolean,
  agentStatus: AgentStatus | undefined
) => {
  if (agentStatus === AgentStatus.FAILED && !isClusterConnected) {
    return clusterDisconnectedStatusView;
  }
  if (agentStatus) return agentStatusViews[agentStatus];
  if (!isClusterConnected) return clusterDisconnectedStatusView;
  return availableStatusView;
};

const AgentStatusComponent: React.FC<{
  isClusterConnected: boolean;
  agentStatus: AgentStatus | undefined;
}> = ({ isClusterConnected, agentStatus }) => {
  const statusView = getStatusView(isClusterConnected, agentStatus);

  return (
    <Typography color={statusView.color} bold variant="text" size="medium">
      {statusView.description}
    </Typography>
  );
};

export const StatusData: React.FC<{
  isClusterConnected: boolean | undefined;
  agentStatus: AgentStatus | undefined;
  reason?: string;
}> = ({ isClusterConnected, agentStatus, reason }) => (
  <Container>
    <StatusView>
      {isClusterConnected === undefined ? (
        <StatusSkeleton />
      ) : (
        <AgentStatusComponent
          isClusterConnected={isClusterConnected}
          agentStatus={agentStatus}
        />
      )}
      {reason && (
        <TooltipWrapper
          tooltipId={DEFAULT_TOOLTIP}
          content={reason}
          place="top"
        >
          <QuestionCircle16 color={palette.gray[600]} />
        </TooltipWrapper>
      )}
    </StatusView>
    <Typography variant="uppercase" color={palette.gray[600]} bold>
      status
    </Typography>
  </Container>
);
