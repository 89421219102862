export const messages: Record<string, string> = {
  Terminated: "",
  Shutdown: "",
  Evicted: "",
  // ContainerCreating: "ContainerCreating message",
  // PodInitializing: "PodInitializing message",
  CreateContainerConfigError:
    "Pod failed to create due to a configuration error.\nCheck the related Secret/ConfigMap/Volume and validate it's configured properly",
  CreateContainerError: "",
  ContainerCannotRun: "",
  CrashLoopBackOff: "",
  ImagePullBackOff:
    "Unable to pull image.\nThis issue usually happens due to one of the following reasons:\n\t1 - Image/tag does not exist.\n\t2 - Missing permissions to access the repository.\n\t3 - Rate limit exceeded.\n\t4 - Node network issues.",
  "OOMKilled-137":
    "Pod terminated due to out of memory.\nThis could be caused by:\n\t1 - Application/container trying to consume more memory than it's limit/has memory leak.\n\t2 - Node overcommited, resources are unavailable for the Pod.",
  "OOMKilled-0":
    "Pod terminated due to out of memory.\nThis could be caused by:\n\t1 - Application/container trying to consume more memory than it's limit/has memory leak.\n\t2 - Node overcommited, resources are unavailable for the Pod.",
  "OOMKilled-1":
    "Pod terminated due to out of memory.\nThis could be caused by:\n\t1 - Application/container trying to consume more memory than it's limit/has memory leak.\n\t2 - Node overcommited, resources are unavailable for the Pod.",
  Completed: "",
  Error: "",
  ErrImagePull:
    "Unable to pull image.\nThis issue usually happens due to one of the following reasons:\n\t1 - Image/tag does not exist.\n\t2 - Missing permissions to access the repository.\n\t3 - Rate limit exceeded.\n\t4 - Node network issues.",
  ImageInspectError: "",
  ErrImageNeverPull: "",
  RegistryUnavailable: "",
  InvalidImageName:
    "Unable to pull image, image/tag does not exist, verify you are using a valid image name.",
  FailedScheduling:
    "Unable to schedule Pod due to missing infrastructure\nTo solve this issue, we suggest doing one of the following:\n\t1 - Add relevant nodes to the cluster.\n\t2 - Allow the Pod to run on other nodes by updating restrictions (nodeSelectors, Affinity rules, Tolerations).",
  FailedMount: "Failed to mount Volume/Secret/Token/ConfigMap to Pod",
  FailedAttachVolume:
    "Pod failed to start due to an issue with volume attaching.\nThis is usally caused when the volume is already attached to another Node/Pod",
  NodeNotReady: "",
  NotTriggerScaleUp:
    "Pod unable to Schedule due to lack of resources.\nPod wouldn't fit if a new node is added because one or more of the following reasons:\n\t1 - The maximum node group size reached.\n\t2 - Nodes didn't match Pod affinity.\n\t3 - Pod didn't tolerate Nodes taints.\n\t4 - Pod resources requierments wouldn't fit to any supported node.",
  Unhealthy:
    "\"When a pod or container is marked as 'unhealthy', it means it is not meeting the defined health criteria, such as failing readiness or liveness probes. This can indicate issues with the underlying application or the environment it is running in. \n" +
    "\n" +
    'Start by investigating the logs to identify the cause."',
  "Unhealthy - failed probes":
    "When a pod or container is marked as 'unhealthy', it means it is not meeting the defined health criteria, such as failing readiness or liveness probes. This can indicate issues with the underlying application or the environment it is running in. \n" +
    "\n" +
    "Start by investigating the logs to identify the cause.",
  DNSConfigForming: "",
  NetworkNotReady: "",
  FailedPreStopHook: "",
  PolicyViolation: "",
  FailedKillPod: "",
  FailedCreatePodContainer: "",
  SyncError: "",
  Preempted: "",
  ContainersNotReady: "",
  "NonZeroExitCode-1": "Container was stopped due to an application error.",
  "NonZeroExitCode-143":
    "The container received a 'SIGTERM' command, it will be gracefully terminated.\nThis could be caused by:\n\t1 - Node overcommited, Pods are being evicted to release resources.\n\t2 - Node terminated (Preempted/Scaled-down).\n\t3 - Preemption due to low priority.\n\t4 - Pod tries to consume more Memory then requested (OOMKilled).",
  "NonZeroExitCode-137":
    "The container received a 'SIGKILL' command, and will be terminated immediately.\nThis could be caused by:\n\t1 - Node overcommited, Pods are being evicted to release resources.\n\t2 - Node terminated (Preempted/Scaled-down).\n\t3 - Preemption due to low priority.\n\t4 - Pod tries to consume more Memory then requested (OOMKilled)",
  "NonZeroExitCode-139":
    "The container exited with a SIGSEV signal because the application tried to write outside its allocated memory and will be terminated immediately.\nThis could be caused by:\n\t1 - There are errors in the application causing out of scope memory access.\n\t2 -Recent changes could have increased memory utilization\n\t3 - The container and pod don't have enough resources.",
  "NonZeroExitCode-2": "",
  FailedCreate:
    "Deployment was unsuccessful, pods failed to be created. \nThis could be caused by:\n\t1 - Insufficient quota.\n\t2 - Readiness probe failures.\n\t3 - Image pull errors.\n\t4 - Insufficient permissions.\n\t5 - Limit ranges.\n\t6 - Application runtime misconfiguration.",
  ReadinessGatesNotReady:
    "Readiness gates allow us to create custom status condition types similar to PodScheduled or Initialized. \nThose conditions can then be used to evaluate Pod readiness. \nIf you are not aware of the readiness gate specified in the message, contact the application owner.",
  // PodPending: "PodPending message",
  // PodNotReady: "Pod is not ready",
};
