import React from "react";
import { Tag, Typography } from "@komodorio/design-system/deprecated";

import { greenTag, pinkTag, grayTag } from "./StatusTag";

const mapStatusToColor: { [key: string]: { bg: string; fg: string } } = {
  Completed: greenTag,
  CrashLoopBackOff: pinkTag,
  OOMKilled: pinkTag,
};

interface IProps {
  value: string | number | undefined;
  mapOverride?: { [key: string]: { bg: string; fg: string } };
}

export const ReasonTag: React.FC<IProps> = ({ value, mapOverride = {} }) => {
  if (!value) return null;
  const map = { ...mapStatusToColor, ...mapOverride };
  const color = map[value] ?? grayTag;
  return (
    <Tag color={color.bg} aria-label="reason tag">
      <Typography variant="uppercase" color={color.fg}>
        {value}
      </Typography>
    </Tag>
  );
};
