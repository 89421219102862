import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  width: 20rem;
  height: 28.25rem;
`;
const padding = css`
  padding: 1rem;
  overflow: auto;
  overflow-x: hidden;
`;

const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.borderGray};
  ${padding};
  min-height: 20px;
`;

const ContentContainer = styled.div`
  ${padding};
  padding-bottom: 0;
  padding-top: 0;
  flex-grow: 1;
`;

type ContentCardProps = {
  title: string;
  headerActionButton?: React.ReactNode;
  content: ReactNode;
};

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  headerActionButton,
  content,
}) => {
  return (
    <Card>
      <CardHeaderContainer>
        <Typography bold variant="title" size="medium">
          {title}
        </Typography>
        {headerActionButton}
      </CardHeaderContainer>
      <ContentContainer>{content}</ContentContainer>
    </Card>
  );
};
