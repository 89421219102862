import { Skeleton } from "@komodorio/design-system/deprecated";
import React, { memo } from "react";
import styled from "styled-components";

import { getRandomInt } from "../../../shared/utils/helpers";

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
`;
const LoaderTHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 6rem 0 1rem;
`;

export const ProcessListLoader: React.FC = memo(() => (
  <LoaderContainer>
    <LoaderTHead>
      {Array.from(Array(6).keys()).map((i) => (
        <Skeleton key={i} width={`${getRandomInt(3, 6)}rem`} height="0.75rem" />
      ))}
    </LoaderTHead>
    {Array.from(Array(5).keys()).map((i) => (
      <Skeleton key={i} width="100%" height="3.375rem" />
    ))}
  </LoaderContainer>
));
