import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import {
  getWhatDescription,
  getWhyDescription,
  getHowDescription,
} from "./texts";
import { getTotalContainerRestarts } from "./containerRestartsUtils";
import { Details } from "./Details";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { RestartReasonsBreakdown } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ContainerRestarts/RestartReasonsBreakdown";
import { CorrelatedEvents } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/CorrelatedEvents";
import { RestartReason } from "@/generated/reliabilityApi";

export const ContainerRestarts: React.FC = () => {
  const { data } = useViolation();
  const {
    reliabilityBestPracticesTab,
    showReliabilityRestartingContainersReasons,
  } = useOverridableFlags();
  const { supportingData, komodorUid, headerProps, summaryProps } =
    useGetCommonDrawerProps(data?.data.violation);

  const { containers, restartReasons } =
    supportingData?.restartingContainers ?? {};

  const allReasons = useMemo(
    () =>
      restartReasons?.breakdown.reduce<RestartReason[]>((acc, breakdown) => {
        breakdown.reason &&
          !acc.includes(breakdown.reason) &&
          acc.push(breakdown.reason);
        return acc;
      }, []) ?? [],
    [restartReasons?.breakdown]
  );

  const whatHappenedDescription = useMemo(() => {
    if (!containers) return null;
    const totalRestarts = getTotalContainerRestarts(containers);
    return getWhatDescription(containers.length, totalRestarts);
  }, [containers]);

  if (!data?.data.violation || !containers || !komodorUid) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {whatHappenedDescription}
              <Details komodorUid={komodorUid} containers={containers} />
              {!!showReliabilityRestartingContainersReasons && (
                <RestartReasonsBreakdown />
              )}
              <CorrelatedEvents />
              {!!reliabilityBestPracticesTab && (
                <ViolationsTableByIds
                  title="Related best practice violations"
                  ids={data?.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              )}
            </>
          }
          whyDescription={getWhyDescription(allReasons)}
          howDescription={
            <Typography variant="body2" color="text.primary">
              {getHowDescription(allReasons)}
            </Typography>
          }
        />
      }
    />
  );
};
