import React, { useCallback, useMemo } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid-pro";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Container as KubernetesContainer } from "kubernetes-types/core/v1.d";
import styled from "styled-components";

import Deployment from "../../../ResourceView/resources/deployment";
import DaemonSet from "../../../ResourceView/resources/daemonset";
import StatefulSet from "../../../ResourceView/resources/statefulset";
import { useWorkloadContainers } from "../../../InvestigationMode/availability/ReviewMemoryAllocation/hooks";
import {
  buildKomodorUid,
  buildKomodorUidFromResource,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { Metrics as MetricsType } from "../../../Metrics/types";
import { useIsMetricsDrawerSupported } from "../../../Metrics/metricsHooks";
import { usePodMetrics } from "../../../Metrics/usePodMetrics";
import { useMetricsAPIGet } from "../../../../shared/hooks/metrics-api/client";
import { THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT } from "../../../../shared/hooks/metrics-api/requestResponseMaps";
import { ThrottledContainerAggregation } from "../../../../generated/metricsApi";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import DetailContainerContent from "./DetailContainerContent";

export interface PodDetails {
  pod: string;
  fraction: number;
  hours: number;
  container: string;
}

export interface PodDetailsWithMetrics extends PodDetails {
  metrics: MetricsType;
}

export interface ContainersTableMetricsProps {
  podsDetails: PodDetails[];
  resource: Deployment | DaemonSet | StatefulSet;
  endTimestamp: number;
}

export interface RowDetails {
  containerName: string;
  cluster: string;
  namespace: string;
  podsDetails: PodDetailsWithMetrics[];
  info?: KubernetesContainer;
  timeThrottled?: number;
}

const Container = styled.div`
  background-color: white;
`;

const ContainersTableMetrics: React.FC<ContainersTableMetricsProps> = ({
  podsDetails,
  endTimestamp,
  resource,
}) => {
  const columns: GridColDef[] = [
    {
      field: "container",
      headerName: "Container",
      valueGetter: ({ row }) => row.containerName,
      flex: 1,
      sortable: false,
    },
    {
      field: "timeThrottled",
      headerName: "Time throttled (7d)",
      valueGetter: ({ row }) =>
        row.timeThrottled ? `${row.timeThrottled.toFixed(2)}%` : "No Data",
      flex: 1,
    },
    {
      field: "cpuRequestAndLimit",
      headerName: "Current CPU request / limit",
      valueGetter: ({ row }) =>
        row.info
          ? `${row.info.resources?.requests?.["cpu"] ?? "Not set"} / ${
              row.info.resources?.limits?.["cpu"] ?? "Not set"
            }`
          : "No Data",
      flex: 1,
      sortable: false,
    },
  ];

  const isMetricsSupported =
    useIsMetricsDrawerSupported(resource.cluster) || false;

  const containersInfo = useWorkloadContainers({
    workloadUid: buildKomodorUid({
      clusterName: resource?.cluster ?? "",
      namespace: resource.namespace,
      resourceName: resource.name,
      kind: resource.kind,
    }),
    resourceKind: resource.kind,
    agentId: resource.agentId,
  });

  const usePodMetricsCallback = () => {
    return usePodMetrics;
  };
  const podMetricsCallback = usePodMetricsCallback();
  const useMetricsAPICallback = () => {
    return useMetricsAPIGet<ThrottledContainerAggregation>;
  };
  const metricsAPICallback = useMetricsAPICallback();

  const containersAggregated = podsDetails.reduce<{
    [containerName: string]: Omit<RowDetails, "containerName">;
  }>((acc, podDetails) => {
    if (acc[podDetails.container] === undefined) {
      const throttledCPUData = metricsAPICallback(
        THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT,
        {
          komodorUid: buildKomodorUidFromResource(resource) ?? "",
          containerName: podDetails.container,
        }
      );

      acc[podDetails.container] = {
        cluster: resource.cluster,
        namespace: resource.namespace,
        info: containersInfo.find((c) => c.name === podDetails.container),
        timeThrottled: throttledCPUData.data?.percentage ?? NaN,
        podsDetails: [],
      };
    }

    acc[podDetails.container].podsDetails.push({
      ...podDetails,
      metrics: podMetricsCallback(
        resource.cluster,
        resource.namespace,
        podDetails.pod,
        podDetails.container,
        endTimestamp,
        isMetricsSupported
      ),
    });

    return acc;
  }, {});

  const rows: GridRowModel<RowDetails>[] = useMemo(
    () =>
      Object.entries(containersAggregated).map(([containerName, details]) => ({
        ...details,
        containerName,
      })),
    [containersAggregated]
  );

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <DetailContainerContent row={row} />, []);
  return (
    <Container>
      <DataGridPro
        rows={rows}
        columns={columns}
        getRowId={(u) => u.containerName}
        getDetailPanelContent={getDetailPanelContent}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          detailPanelCollapseIcon: KeyboardArrowUp,
        }}
        hideFooter
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ContainersTableMetrics;
