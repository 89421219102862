import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
  background: ${palette.gray["50"]};
  border: 1px solid ${palette.gray["200"]};
  border-radius: 4px;
`;

export const PendingContainerHappyState: React.FC = () => {
  return (
    <Container>
      <AlertCircleOutlined24 />
      <Typography variant="text" size={"medium"}>
        Container has not started so there aren’t any logs or metrics data to
        show
      </Typography>
    </Container>
  );
};
