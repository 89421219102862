import React, { useMemo, useState } from "react";
import { palette } from "@komodorio/design-system";
import { ChevronDown16 } from "@komodorio/design-system/icons";
import { Typography } from "@komodorio/design-system/deprecated";
import { ContainerState } from "kubernetes-types/core/v1.d";
import styled from "styled-components";

import { StatusTag } from "../../../ResourceView/tabs/DescribeTab/common/StatusTag";

import { ContainerOption, ContainerType } from "./types";

const Status: React.FC<{ state?: ContainerState }> = ({ state }) => {
  const firstChosenState = state && Object.keys(state)[0];

  if (!state || !firstChosenState) {
    return null;
  }

  return <StatusTag value={firstChosenState} />;
};

const OptionLine = styled.div<{ disabled?: boolean }>`
  padding-inline-start: 1rem;
  opacity: ${({ disabled }) => (disabled ? "70%" : "100%")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  cursor: pointer;
`;

const OptionsGroupTitleContainer = styled.div`
  display: flex;
  height: 2rem;
`;

const StyledText = styled(Typography)`
  margin-bottom: 0.25rem;
`;

const OpenCloseIcon = styled(ChevronDown16)<{ $shouldRotate?: boolean }>`
  transform: ${({ $shouldRotate }) =>
    $shouldRotate ? "rotate(-90deg)" : "none"};
`;

export const ExpandedContainersSection: React.FC<{
  containers: ContainerOption[];
  onSelect: (selection: string) => void;
}> = ({ containers, onSelect }) => {
  const titleText =
    containers.length && containers[0].containerType === ContainerType.Init
      ? "Init Containers"
      : "Containers";

  const [isContainerExpanded, setIsContainersExpanded] = useState(true);
  const containerOptions = useMemo(
    () =>
      containers.map((o) => (
        <OptionLine
          role="listitem"
          onClick={() => !o.disabled && onSelect(o.value)}
          disabled={o.disabled}
          key={o.value}
        >
          <StyledText variant="text">{o.label}</StyledText>
          <Status state={o.state} />
        </OptionLine>
      )),
    [containers, onSelect]
  );

  return (
    <>
      {containers.length > 0 && (
        <OptionsGroupTitleContainer>
          <OpenCloseIcon
            $shouldRotate={!isContainerExpanded}
            onClick={() => setIsContainersExpanded((val) => !val)}
            color={palette.gray["600"]}
            role="button"
          />
          <Typography variant="title">
            {titleText} ({containers.length})
          </Typography>
        </OptionsGroupTitleContainer>
      )}
      {isContainerExpanded && containerOptions}
    </>
  );
};
