import { useCallback } from "react";

import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useDefaultFilters } from "@/components/reliability/hooks/useDefaultFilters";

export const useResetAllFilters = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();
  const defaultSelectedViolationFilters = useDefaultFilters();

  return useCallback(() => {
    setDataInUrl({
      ...dataInUrl,
      filters: defaultSelectedViolationFilters,
    });
  }, [dataInUrl, defaultSelectedViolationFilters, setDataInUrl]);
};
