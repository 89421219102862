import { muiColors, muiTheme } from "@komodorio/design-system";
import { TextInput } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const StyledForm = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const PADDING_INLINE = "48px";

export const FormContent = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 16px ${PADDING_INLINE};
  overflow-y: auto;
`;

export const TEXT_FIELD_WIDTH = "380px";

export const StyledTextField = styled(TextInput)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    width: ${TEXT_FIELD_WIDTH};
    background-color: ${muiColors.common.white};
    font-size: 14px;
  }
  & .MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export const FormSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FormInputLabel = styled(Typography)`
  && {
    margin-bottom: 3px;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 8px 24px;
  gap: 8px;
  background: ${muiColors.common.white};
  border-top: 1px solid ${muiTheme.palette.divider};
`;
