import React from "react";

import { TabContentContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { RelatedResourcesSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/RelatedResourcesSection";
import { StatusSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/StatusSection";
import { SpecSection } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/SpecSection";
import { AccordionValuesAsChipsList } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionValuesAsChipsList";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";

const { annotations: annotationsAriaLabel, labels: labelsAriaLabel } =
  certificatesPageAriaLabels.drawer.sections;

export const CertificateDetails: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const annotations = certificateData?.data?.object.metadata.annotations;
  const labels = certificateData?.data?.object.metadata.labels;
  return (
    <TabContentContainer>
      <RelatedResourcesSection />
      <StatusSection />
      <SpecSection />
      <AccordionValuesAsChipsList
        title="Annotations"
        values={annotations}
        ariaLabel={annotationsAriaLabel}
      />
      <AccordionValuesAsChipsList
        title="Labels"
        values={labels}
        ariaLabel={labelsAriaLabel}
      />
    </TabContentContainer>
  );
};
