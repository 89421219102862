import { useEffect } from "react";

import { useWorkloadMetricsTabContext } from "../context/useWorkloadMetricsTabContext";

export const useSetInitialLoadTime = () => {
  const { setInitialLoadTime } = useWorkloadMetricsTabContext();

  useEffect(() => {
    setInitialLoadTime(Date.now());
  }, [setInitialLoadTime]);
};
