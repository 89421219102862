import React, { useCallback } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import RoleBinding from "../../resources/roleBinding";
import { KubernetesRolesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesServiceAccountsResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceAccountsResource";
import { ResourceDrawerByData } from "../../ResourceDrawer";

import { DescribeDateItem, DescribeTextItem } from "./common/DescribeItem";
import { DescribePanel } from "./common/DescribeSection";
import { DescribeKeyValueItem } from "./common/DescribeTagListItem";
import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import {
  RoleBindingReference,
  RoleBinding as RoleBindingList,
} from "./common/RoleBindingsList";
import { useReferenceResourceDrawerData } from "./utils/useReferenceResourceDrawerData";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

interface RoleBindingDetailsTabProps {
  resource: RoleBinding;
}

export const RoleBindingDetailsTab: React.FC<RoleBindingDetailsTabProps> = ({
  resource,
}) => {
  const {
    openResourceDrawerIfNeeded,
    resourceDrawerData,
    clearResourceDrawerData,
  } = useReferenceResourceDrawerData();

  const { cluster = "", fullObj: roleBinding } = resource;

  const onPossibleRoleClicked = useCallback(
    ({ name, kind }: { name: string; kind: string }) =>
      openResourceDrawerIfNeeded({
        actualResourceKind: kind,
        resourceName: name,
        targetResourceKind: KubernetesRolesResource.Kind,
      }),
    [openResourceDrawerIfNeeded]
  );

  const onPossibleServiceAccountClicked = useCallback(
    ({ name, kind }: { name: string; kind: string }) =>
      openResourceDrawerIfNeeded({
        actualResourceKind: kind,
        resourceName: name,
        targetResourceKind: KubernetesServiceAccountsResource.Kind,
      }),
    [openResourceDrawerIfNeeded]
  );

  const { creationTimestamp, name, labels, annotations } =
    roleBinding.metadata ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}
      </DescribePanel>

      <RoleBindingReference
        data={[roleBinding.roleRef]}
        onRowClicked={onPossibleRoleClicked}
      />

      <RoleBindingList
        data={roleBinding.subjects}
        onRowClicked={onPossibleServiceAccountClicked}
      />

      {resourceDrawerData && (
        <ResourceDrawerByData
          cluster={cluster}
          namespace=""
          resourceType={resourceDrawerData.resourceType}
          resourceName={resourceDrawerData.resourceName}
          open
          onClose={clearResourceDrawerData}
        />
      )}
    </DescribeTabContentWrapper>
  );
};
