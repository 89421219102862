import { ResourceTableModelRow } from "komodor-types";
import { useMemo } from "react";

import { generateUid } from "../../../../../../../shared/utils/generateUid";
import { Response } from "../types";
import { useResourcesAPIPost } from "../../../../../../../shared/hooks/resources-api/client";
import { useBuildKomodorUidForKomodorService } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import {
  HPA_STATE,
  KOMODOR_SERVICES_RELATED_RESOURCES,
} from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useActiveAgent } from "../../../../../../../shared/hooks/useAgents";
import { HorizontalPodAutoscaler } from "../../../../../../../generated/resourcesApi";

export const useHpasByServiceId = (
  serviceId: string | undefined,
  clusterName: string | undefined
): Response => {
  const { hpas, refresh, isLoading, errorMessage } = useHpaRowsFromAPI(
    serviceId,
    clusterName
  );

  return useMemo(
    () => ({
      rows: hpas,
      refresh,
      isLoading,
      errorMessage,
    }),
    [errorMessage, hpas, isLoading, refresh]
  );
};

const useHpaRowsFromAPI = (
  serviceId: string | undefined,
  clusterName: string | undefined
): {
  hpas: ResourceTableModelRow[];
  refresh: () => void;
  isLoading: boolean;
  errorMessage: string | undefined;
} => {
  const uid = useBuildKomodorUidForKomodorService(serviceId ?? "");

  const {
    data: hpasKomodorUids,
    refresh,
    loading: loadingFromRelatedResources,
    error: errorFromRelatedResources,
  } = useResourcesAPIPost(
    KOMODOR_SERVICES_RELATED_RESOURCES,
    {
      fields: ["HpaUids"],
      komodorUids: [uid ?? ""],
    },
    !uid
  );

  const agentId = useActiveAgent(clusterName);

  const {
    data: rawHpas,
    loading: loadingFromHpaState,
    error: errorFromHpaState,
  } = useResourcesAPIPost(
    HPA_STATE,
    {
      komodorUids: hpasKomodorUids?.HpaUids ?? [],
      agentId: agentId ?? "",
      fields: ["wide"],
    },
    !hpasKomodorUids || !agentId
  );

  const hpas = useMemo(
    () =>
      rawHpas?.data?.wide?.map((hpa) =>
        convertApiDataIntoRow(hpa, clusterName ?? "")
      ) ?? [],
    [clusterName, rawHpas?.data?.wide]
  );

  const errorMessage =
    errorFromRelatedResources ?? errorFromHpaState ?? undefined;
  const isLoading = loadingFromRelatedResources || loadingFromHpaState;
  return {
    hpas,
    refresh,
    isLoading,
    errorMessage,
  };
};

const convertApiDataIntoRow = (
  hpa: HorizontalPodAutoscaler,
  cluster: string
): ResourceTableModelRow => {
  const { name, namespace } = hpa;
  return {
    uid: generateUid({ name, namespace: namespace ?? "", cluster }),
    name: hpa.name,
    maxReplicas: hpa?.maxpods ?? "",
    minReplicas: hpa.minpods ?? "",
    replicas: hpa.replicas ?? "",
  };
};
