import React from "react";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import Komo from "../../../../../shared/assets/komo.svg?react";
import { EmptyStateContainer } from "../../../../servicesView/AgentNotInstalledState";

export const ClickableTypography = styled(Typography)`
  cursor: pointer;
`;

export const ReposEmptyState: React.FC<{
  setOpen?: (open: boolean) => void;
}> = ({ setOpen }) => {
  return (
    <EmptyStateContainer>
      <Komo />
      <Typography
        variant={"headline"}
        data-e2e-selector="empty-state-repo-title"
      >
        There are no Helm repositories
      </Typography>
      <ClickableTypography
        variant={"title"}
        color={theme.foreground.fgDarkBlue}
        data-e2e-selector="add-helm-repo-button"
        onClick={() => setOpen?.(true)}
      >
        Add a repository
      </ClickableTypography>
    </EmptyStateContainer>
  );
};
