import React from "react";
import styled from "styled-components";

import { ObjectDiffAsYaml } from "../../../../../common/ObjectDiff";
import { Divider, StyledText } from "../../../../common/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVCSpecChangedCheck from "../PVCSpecChangedCheck";

export const DiffContainer = styled.div`
  background-color: white;
  border: 1px solid #dcdddf;
  border-radius: 4px;
  margin-block-start: 1.5rem;
`;

const diffStyles = {
  gutter: { minWidth: 0, backgroundColor: "white" },
};

const PVCSpecChangedDetails: React.FC<{
  check: PVCSpecChangedCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const shortOutput = check.shortOutput;
  const headline =
    shortOutput === "The PVC was deleted and re-created"
      ? "PVC spec diff from last spec before deletion"
      : shortOutput === "The PVC was recently created"
      ? "New PVC spec"
      : "PVC spec diff";

  return checkOutput?.changes?.length ? (
    <DiffContainer>
      <StyledText> {headline} </StyledText>
      <Divider />
      <ObjectDiffAsYaml
        oldObj={checkOutput.changes[0].oldSpec}
        newObj={checkOutput.changes[0].newSpec}
        overridingStyles={diffStyles}
      />
    </DiffContainer>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PVCSpecChangedDetails;
