import { DateUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellTypes";

export const matchAgeWithUnits = (
  includeDateUnits: DateUnits[],
  age: string
): string => {
  return includeDateUnits.reduce((acc, unit) => {
    const regex = new RegExp(`(\\d+)${unit}`);
    const match = age.match(regex);
    if (match) {
      acc += `${parseInt(match[1])}${unit}`;
    }
    return acc;
  }, "");
};
