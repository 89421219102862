import React, { useMemo } from "react";
import styled from "styled-components";

import { useGroups } from "../../../../hooks/reliabilityHooks";
import { LoaderContainer } from "../../../shared/LoaderContainer";
import { ErrorContainer } from "../../../shared/ErrorContainer";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { GroupByOptions } from "../../../../ReliabilityTypes";
import {
  violationsGroupByCluster,
  violationsGroupByImpactGroup,
  violationsGroupByCheckType,
} from "../../../../constants/dataDogReporting";
import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";
import { NoViolationsEmptyState } from "../../../shared/GroupEmptyState/GroupEmptyState";

import { ViolationsGroup } from "./ViolationsGroup/ViolationsGroup";

import { useReportDDRumWithReactQuery } from "@/shared/hooks/datadog-rum/useReportDDRumWithReactQuery";
import {
  ImpactGroupType,
  ViolationsAggregationGroup,
} from "@/generated/reliabilityApi";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const {
  impactGroups: { mainContainer: impactGroupsMainContainer },
  clusterGroups: { mainContainer: clusterGroupsMainContainer },
  checkTypeGroups: { mainContainer: checkTypeGroupsMainContainer },
} = reliabilityArialLabels;

const groupsMapper: Record<
  GroupByOptions,
  { ariaLabel: string; viewName: string }
> = {
  Impact: {
    ariaLabel: impactGroupsMainContainer,
    viewName: violationsGroupByImpactGroup,
  },
  Cluster: {
    ariaLabel: clusterGroupsMainContainer,
    viewName: violationsGroupByCluster,
  },
  CheckType: {
    ariaLabel: checkTypeGroupsMainContainer,
    viewName: violationsGroupByCheckType,
  },
};

type GroupedViolationsProps = {
  overrideImpactGroupType?: {
    groupType: ImpactGroupType;
    ariaLabel: string;
    viewName: string;
    groupMapper: (
      group: ViolationsAggregationGroup,
      idx: number
    ) => React.ReactNode;
  };
};

export const GroupedViolations: React.FC<GroupedViolationsProps> = ({
  overrideImpactGroupType,
}) => {
  const {
    groupType: overrideGroupType,
    viewName: overrideViewName,
    ariaLabel: overrideAriaLabel,
    groupMapper,
  } = overrideImpactGroupType ?? {};
  const result = useGroups(overrideGroupType);
  const groupBy = useGetGroupBy();
  const groupByAsGroupByOptions = groupBy as GroupByOptions;
  const { data, isFetching, error } = result;

  const {
    ariaLabel = overrideAriaLabel ?? "",
    viewName = overrideViewName ?? "",
  } = groupsMapper[groupByAsGroupByOptions] ?? {};

  const content = useMemo(() => {
    if (isFetching) return <LoaderContainer />;
    if (error) return <ErrorContainer />;
    if (!data?.data) return null;

    if (data.data.groups.length === 0)
      return <NoViolationsEmptyState impactGroupType={overrideGroupType} />;

    return data.data.groups.map((group, idx) =>
      groupMapper ? (
        groupMapper(group, idx)
      ) : (
        <ViolationsGroup
          group={group}
          groupBy={groupByAsGroupByOptions}
          key={`${group.id}-${idx}`}
        />
      )
    );
  }, [
    isFetching,
    error,
    data?.data,
    overrideGroupType,
    groupMapper,
    groupByAsGroupByOptions,
  ]);

  useReportDDRumWithReactQuery({
    result,
    viewName,
  });

  return <Container aria-label={ariaLabel}>{content}</Container>;
};
