export const extractNameAndGroupFromFQDN = (
  fullName: string
): { name: string; group: string } => {
  const [name, ...group] = fullName.split(".");

  return {
    name,
    group: group.join("."),
  };
};
