import React from "react";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";

import {
  getWhyDescription,
  expiredHowToFix,
  aboutToExpireHowToFix,
} from "./texts";
import { CertificatesTable } from "./CertificatesTable/CertificatesTable";
import { getIsAboutToExpire } from "./utils";

export const CertificateExpiration: React.FC = () => {
  const { data } = useViolation();

  const { supportingData, headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const certificatesSupportingData = supportingData?.certificateExpiration;

  const isAboutToExpire = getIsAboutToExpire(
    certificatesSupportingData?.certificates[0].daysToExpiration ?? 0
  );

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <CertificatesTable supportingData={certificatesSupportingData} />
          }
          whyDescription={getWhyDescription()}
          howDescription={
            isAboutToExpire ? aboutToExpireHowToFix() : expiredHowToFix()
          }
        />
      }
    />
  );
};
