export enum TaskState {
  INITIATED = "initiated",
  IN_PROGRESS = "in-progress",
  COMPLETED = "completed",
  TIMEOUT = "timeout",
}

export enum TaskType {
  POD_LOGS = "pod-logs",
  POD_DESCRIBE = "pod-describe",
  POD_LIST = "pod-list",
  SERVICE_DESCRIBE = "service-describe",
  RESOURCE_LIST = "resource-list",
  POD_METRICS = "pod-metrics",
  NODE_METRICS = "node-metrics",
  NODE_STATUS = "node-status",
  ACTION_COMMAND = "action-command",
  FREE_COMMAND = "free-command",
  METRICS = "metrics",
  RESTART_AGENT = "restart-agent",
}

export enum TaskTypeV2 {
  ResourceListV2 = "resource-list-v2",
  EndSessionV2 = "end-session-v2",
}

export enum TaskEvent {
  STARTED = "started",
  COMPLETED = "completed",
}

export interface CommandTaskData {
  command: string;
  args: string[];
}

export interface ResourceTableModel {
  kubernetesResourceName: string;
  headersOrder: string[];
  rows: ResourceTableModelRow[];
  error: string;
}

export interface ResourceTableModelRow {
  [key: string]: string;
}

export enum ActionTypes {
  DeleteResource = "deleteResource",
  Scale = "scale",
  RestartService = "restartService",
  EditResource = "editResource",
  CreateResource = "createResource",
  GetResource = "getResource",
  JobRerun = "jobRerun",
  JobRunNow = "jobRunNow",
  Uncordon = "uncordon",
  Cordon = "cordon",
  Drain = "drain",
  RollbackService = "rollbackService",
  UpdateContainersResources = "updateContainersResources",
  GetHelmRepoSearch = "getHelmRepoSearch",
  GetHelmRepoCharts = "getHelmRepoCharts",
  UpdateHelmRepo = "updateHelmRepo",
  AddHelmRepo = "addHelmRepo",
  RemoveHelmRepo = "removeHelmRepo",
  InstallHelmChart = "installHelmChart",
  UninstallHelmChart = "uninstallHelmChart",
  RevertHelmChart = "revertHelmChart",
  HelmShowValues = "helmShowValues",
  HelmTemplate = "helmTemplate",
  HelmGetCharts = "helmGetCharts",
  HelmGetChartHistory = "helmGetChartHistory",
  Describe = "describe",
  PodShell = "podShell",
  PortForward = "portForward",
  RevertSourceControl = "revertSourceControl",
  SuspendCronJob = "suspendCronJob",
  ResumeCronJob = "resumeCronJob",
}

export const IgnoreActionTypes = [
  ActionTypes.GetResource,
  ActionTypes.GetHelmRepoSearch,
  ActionTypes.GetHelmRepoCharts,
  ActionTypes.HelmShowValues,
  ActionTypes.HelmTemplate,
  ActionTypes.AddHelmRepo,
  ActionTypes.RemoveHelmRepo,
  ActionTypes.HelmGetCharts,
  ActionTypes.HelmGetChartHistory,
];

export interface ActionMetadataBase {
  cluster: string;
  type: ActionTypes;
}

export interface ActionMetadataEditResource extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  data: {
    oldJson: unknown;
    newJson: unknown;
    preparedJson: string;
  };
}

export interface ActionMetadataGetResource extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  isContainerResourcesOnly?: boolean;
  isJsonOutput?: boolean;
}

export interface ActionMetadataDeleteResource extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  controlledBy: string;
}

export interface ActionMetadataCreateResource extends ActionMetadataBase {
  namespace: string;
  resourceType: string;
  resourceName: string;
  data: {
    newJson: unknown;
    preparedJson: string;
  };
}

export interface ActionMetadataRestartService extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  controlledBy: string;
}

export interface ActionMetadataScaleService extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  controlledBy: string;
  data: {
    currValue: number;
    newValue: number;
  };
}

export interface ActionMetadataRerunJob extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  data: {
    newJson: string;
  };
}

export interface ActionMetadataRunJob extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  data: {
    newJson: string;
  };
}

export interface ActionMetadataCordonUncordon extends ActionMetadataBase {
  resourceType: string;
  resourceName: string;
}

export interface ActionMetadataRollbackService extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
}

export interface Container {
  name: string;
  resources: {
    limits?: { memory?: string | null; cpu?: string | null };
    requests?: { memory?: string | null; cpu?: string | null };
  };
}
export interface ActionMetadataUpdateContainersResources
  extends ActionMetadataBase {
  namespace: string;
  serviceId: string;
  resourceType: string;
  resourceName: string;
  containers: string;
}

export interface ActionMetadataHelmRepoCharts extends ActionMetadataBase {
  repoName: string;
}

export interface ActionMetadataHelmRepoChartVersions
  extends ActionMetadataBase {
  chartName: string;
}

export interface ActionMetadataHelmRepoUpdate extends ActionMetadataBase {
  repoName: string;
}

export interface ActionMetadataHelmRepoAdd extends ActionMetadataBase {
  repoName: string;
  repoUrl: string;
}

export interface ActionMetadataHelmRepoRemove extends ActionMetadataBase {
  repoName: string;
}

export interface ActionMetadataHelmChartInstall extends ActionMetadataBase {
  name: string;
  chartName: string;
  namespace: string;
  version: string;
  values: string;
  dryRun?: boolean;
}

export interface ActionMetadataHelmChartUninstall extends ActionMetadataBase {
  chartName: string;
  namespace: string;
}

export interface ActionMetadataHelmChartRevert extends ActionMetadataBase {
  chartName: string;
  namespace: string;
  revision: string;
}

export interface ActionMetadataHelmShowValues extends ActionMetadataBase {
  chartName: string;
  version: string;
}

export interface ActionMetadataHelmGetChartHistory extends ActionMetadataBase {
  chartName: string;
  namespace: string;
}
