import React, { useState } from "react";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { FlexRow } from "../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/styles";
import { useDateFormatter } from "../../../../shared/hooks/useDateFormatter";
import { Container, SummaryDetails } from "../common/styles";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { ResourceDrawer } from "../../../ResourceView/ResourceDrawer";
import Resource from "../../../ResourceView/resources";
import { EnrichedMessageSection } from "../../messageEnrichment/EnrichedMessageSection";
import { Section } from "../common/Section";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

import { IntroductionSnapshotOutput } from "./types";

export const IntroductionSummary: React.FC<{
  introductionSnapshot: IntroductionSnapshotOutput;
  onWhatIsItClicked?: () => void;
}> = ({ introductionSnapshot, onWhatIsItClicked }) => {
  const issue = useInvestigationModeStore(issueSelector);
  const { format } = useDateFormatter();
  const agentId = useActiveAgent(issue?.cluster ?? "");
  const [resource, setResource] = useState<Resource | undefined>(undefined);

  return (
    <Container>
      <Typography variant="h5">Details</Typography>
      <SummaryDetails>
        <Section
          title={"reason"}
          ariaLabel={
            AriaLabels.InvestigationMode.IntroductionStep.Summary.ReasonSection
          }
        >
          <EnrichedMessageSection
            errorMessages={introductionSnapshot.errorMessages ?? []}
            reasons={introductionSnapshot.reasons ?? []}
            agentId={agentId}
            cluster={issue?.cluster ?? ""}
            namespace={issue?.namespace ?? ""}
            onClick={setResource}
            titleExtraText={
              onWhatIsItClicked ? (
                <Link
                  sx={{
                    marginLeft: "1ch",
                    fontWeight: "400",
                    cursor: "pointer",
                  }}
                  onClick={onWhatIsItClicked}
                >
                  what is it?
                </Link>
              ) : null
            }
          />
        </Section>
        <Section
          title={"where"}
          ariaLabel={
            AriaLabels.InvestigationMode.IntroductionStep.Summary.WhereSection
          }
        >
          <FlexRow>
            {introductionSnapshot.failedContainers &&
              introductionSnapshot.failedContainers?.length > 0 && (
                <Metadata
                  title={`failed container${
                    introductionSnapshot.failedContainers.length > 1 ? "s" : ""
                  }`}
                  value={introductionSnapshot.failedContainers.join(", ")}
                />
              )}
            <Metadata
              title={introductionSnapshot?.resourceKind ?? "deployment"}
              value={issue?.shortResourceName ?? ""}
            />
            <Metadata title="namespace" value={issue?.namespace ?? ""} />
            <Metadata title="cluster" value={issue?.cluster ?? ""} />
          </FlexRow>
        </Section>
        <Section
          title={"when"}
          ariaLabel={
            AriaLabels.InvestigationMode.IntroductionStep.Summary.WhenSection
          }
        >
          <FlexRow>
            {introductionSnapshot.startTime && (
              <Metadata
                title={"issue started"}
                value={format(new Date(introductionSnapshot.startTime))}
              />
            )}
            {introductionSnapshot.endTime && (
              <Metadata
                title={"issue ended"}
                value={format(new Date(introductionSnapshot.endTime))}
              />
            )}
          </FlexRow>
        </Section>
      </SummaryDetails>
      <ResourceDrawer
        open={!!resource}
        onClose={() => setResource(undefined)}
        resource={resource}
      />
    </Container>
  );
};
