import React from "react";

import { ActionResult } from "../tabs/components/ActionResult";
import { LastActionResult } from "../helmChart";

export const HelmErrorMessage: React.FC<{ result: LastActionResult }> = ({
  result,
}) => {
  if (typeof result.message === "string") {
    return <ActionResult message={result.message} success={result.success} />;
  }
  return result.message;
};
