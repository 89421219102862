import React, { useState } from "react";
import styled, { css } from "styled-components";

import ExternalLink from "../../../../../Link/ExternalLink";
import { DetailsExternalLink, Divider } from "../../../common";
import {
  PlaceHolderError,
  PlaceHolderErrorMessages,
  PlaceHolderErrorType,
} from "../CustomLink/CustomLinkUtil";
import { CUSTOM_LINKS_DOCS_LINK } from "../../../../../../../shared/constants/docsLinks";

import CustomTooltip from "./CustomTooltip";
import error from "./error.svg";
import { HoverableContainer } from "./HoverableContainer";

const Container = styled.div<{ single?: boolean }>`
  display: grid;
  grid-template-columns: max-content auto;
  padding: ${({ single }) =>
    single ? "0.75rem 1rem" : "0.3rem 1rem 0.3rem 0"};
  align-items: center;
  gap: 0.75rem;
`;

const SmallLinkContainer = styled(Container)`
  gap: 0.5rem;
  padding: 0.3rem;
`;

const LinkIconStyle = css`
  border-radius: 0.25rem;
  background-color: #f3f5f7;
  background-repeat: no-repeat;
  background-position: center;
`;

const LinkIcon = styled.div<{ icon: string; big: boolean }>`
  ${LinkIconStyle}
  background-image: url(${({ icon }) => icon});
  height: ${({ big }) => (big ? "2" : "1")}rem;
  width: ${({ big }) => (big ? "2" : "1")}rem;
  background-size: ${({ big }) => (big ? "65%" : "100%")};
`;

const ErrorIconStyle = css`
  position: relative;
  height: 1rem;
  width: 1rem;
  background-image: url(${error});
  background-repeat: no-repeat;
`;

const ErrorIcon = styled.div<{ big: boolean }>`
  ${ErrorIconStyle}
  top: ${({ big }) => (big ? "1.25" : "0.6")}rem;
  left: ${({ big }) => (big ? "1.5" : "0.55")}rem;
  ${({ big }) => (big ? "" : "background-size: 65%;")}
`;

const StyledCustomTooltip = styled(CustomTooltip)`
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
`;

const BoldText = styled.a`
  font-weight: bold;
`;

const RemoveableElement = styled.div<{ remove: boolean }>`
  display: ${({ remove }) => (remove ? "none" : "block")};
`;

export const ErrorText: React.FC<{ error: PlaceHolderError }> = ({ error }) => {
  const errorMessage = PlaceHolderErrorMessages.get(error.placeHolderError);
  switch (error.placeHolderError) {
    case PlaceHolderErrorType.ContainerNotFound:
    case PlaceHolderErrorType.YamlPathNotFound: {
      return (
        <>
          {errorMessage + " "}
          <BoldText>{error.errorValue}</BoldText>
        </>
      );
    }
    case PlaceHolderErrorType.HrefNotFound:
    case PlaceHolderErrorType.TimeInvalidFormat:
    default: {
      return (
        <>
          {errorMessage + " "}
          <ExternalLink href={CUSTOM_LINKS_DOCS_LINK} color="inherit">
            View documentation
          </ExternalLink>
        </>
      );
    }
  }
};

export const SmallIntegrationLinkView: React.FC<{
  icon: string;
  title: string;
  href?: string;
  error?: PlaceHolderError;
  dispatchLinkMetric: () => void;
}> = ({ icon, title, href = "#unavailable", error, dispatchLinkMetric }) => {
  if (error?.placeHolderError === PlaceHolderErrorType.FailedPodNotRetrieved) {
    href = "#loading";
    error = undefined;
  }
  return (
    <div>
      <SmallLinkContainer>
        <LinkIcon icon={icon} big={false}>
          {error && <ErrorIcon big={false} />}
        </LinkIcon>
        <DetailsExternalLink
          color="inherit"
          noUnderline
          href={href}
          title={title}
          big={false}
          dispatchLinkMetric={dispatchLinkMetric}
        />
      </SmallLinkContainer>
      {error && (
        <StyledCustomTooltip triangleMargin="0.74rem" wrapText={true}>
          <ErrorText error={error} />
        </StyledCustomTooltip>
      )}
    </div>
  );
};

const IntegrationLinkView: React.FC<{
  icon: string;
  title: string;
  href?: string;
  single?: boolean;
  error?: PlaceHolderError;
  noUnderLine?: boolean;
  dispatchLinkMetric?: () => void;
}> = ({
  icon,
  href = "#unavailable",
  title,
  single = false,
  error,
  noUnderLine = true,
  dispatchLinkMetric,
}) => {
  if (error?.placeHolderError === PlaceHolderErrorType.FailedPodNotRetrieved) {
    href = "#loading";
    error = undefined;
  }

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const errorMessage = error
    ? PlaceHolderErrorMessages.get(error.placeHolderError)
    : undefined;

  return (
    <div>
      <Container single={single}>
        <LinkIcon icon={icon} big>
          {error && <ErrorIcon big />}
        </LinkIcon>
        {error ? (
          <HoverableContainer
            onMouseEnter={() => {
              setShowErrorMessage(true);
            }}
            onMouseLeave={() => {
              setShowErrorMessage(false);
            }}
          >
            <DetailsExternalLink
              color="inherit"
              href={href}
              title={title}
              noUnderline
              disabled
            />
          </HoverableContainer>
        ) : (
          <DetailsExternalLink
            color="inherit"
            noUnderline={noUnderLine}
            href={href}
            title={title}
            dispatchLinkMetric={dispatchLinkMetric}
          />
        )}
      </Container>
      {errorMessage && (
        <RemoveableElement remove={!showErrorMessage}>
          {errorMessage}
        </RemoveableElement>
      )}
      {single && <Divider />}
    </div>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default IntegrationLinkView;
