import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { HealthSettingsTabs } from "./HealthSettingsTabs";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { IgnoreChecksPage, PoliciesPage } from "@/components/reliability";
import { ManageMonitorsContent } from "@/components/monitorsView/ManageMonitors/ManageMonitors";
import { TabOptions } from "@/pages/organization-settings/configurations/HealthSettingPage/constants";
import { useHealthSettingsPages } from "@/pages/organization-settings/configurations/HealthSettingPage/useHealthSettingsPages";

export const HealthSettingPage: React.FC = () => {
  const { firstAvailableTab } = useHealthSettingsPages();
  const [tabValue, setTabValue] = useState<TabOptions | undefined>(
    firstAvailableTab
  );

  if (!tabValue) {
    return (
      <SettingsViewVerticalLayout title="Health Policies">
        <Typography variant="body2">
          You do not have permission to view this page
        </Typography>
      </SettingsViewVerticalLayout>
    );
  }

  return (
    <SettingsViewVerticalLayout title="Health Policies">
      <Stack rowGap="16px">
        <HealthSettingsTabs
          value={tabValue}
          handleChange={(e, newValue) => setTabValue(newValue)}
        />
        {tabValue === TabOptions.policies && <PoliciesPage />}
        {tabValue === TabOptions.ignoreChecks && <IgnoreChecksPage />}
        {tabValue === TabOptions.monitors && <ManageMonitorsContent />}
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
