import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import {
  smallLightStyle,
  Label,
} from "../../monitorsConfiguration/common/styles";
import { BoldGrayText } from "../../workflowRunView/checks/CheckDetailsDrawer";
import {
  PodOutputWithLogs,
  ContainerWithLogs,
} from "../../workflowRunView/CommonTypes";
import PodEventsTable from "../../../common/table/PodEventsTable/PodEventsTable";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

import ContainersTable from "./ContainersTable";

export const FlexStyledContainer = styled.div`
  ${smallLightStyle};
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  width: fit-content;
`;

const ContainerLabel = styled(BoldGrayText)`
  margin-right: 1rem;
`;

export const PodsEvents: React.FC<{ pod: PodOutputWithLogs }> = ({ pod }) => {
  return (
    <>
      <BoldGrayText>
        <Typography variant="h4" color={muiColors.gray[800]}>
          Pod Events ({pod.podEvents?.length ?? 0})
        </Typography>
      </BoldGrayText>
      {pod.podEvents === null ? (
        <Label>{"No available data"}</Label>
      ) : (
        pod.podEvents.length > 0 && <PodEventsTable podEvents={pod.podEvents} />
      )}
    </>
  );
};

export const ContainerSectionFull: React.FC<{
  containers: ContainerWithLogs[];
  clusterName: string;
  namespace: string;
  podName: string;
  eventTime: Date | undefined;
  ariaLabel?: string;
}> = ({
  containers,
  clusterName,
  namespace,
  podName,
  eventTime,
  ariaLabel,
}) => {
  return (
    <>
      <ContainerLabel
        aria-label={
          AriaLabels.InvestigationMode.UnhealthyPodsStep.ContainerTitle
        }
      >
        <Typography variant="h4" color={muiColors.gray[800]}>
          Containers ({containers?.length ?? 0})
        </Typography>
      </ContainerLabel>
      <ContainersTable
        containers={containers}
        clusterName={clusterName}
        namespace={namespace}
        podName={podName}
        eventTime={eventTime}
        ariaLabel={ariaLabel}
      />
    </>
  );
};
