import { get, union } from "lodash";

import {
  BaseNamesExtractor,
  extractNamesFromVolumes,
} from "./BaseNamesExtractor";

export class PvcNamesExtractor extends BaseNamesExtractor {
  resourceType = "persistentVolumeClaim";
  volumeName = "claimName";

  extractNamesFromVolumeClaimTemplates = (
    spec: Record<string, unknown> | undefined
  ): string[] => {
    const volumeClaimTemplates = get(
      spec,
      "spec.volumeClaimTemplates"
    ) as Record<string, unknown>[];
    if (!volumeClaimTemplates) {
      return [];
    }
    const replicaNumber = Number(get(spec, "spec.replicas", 0));
    const serviceName = get(spec, "metadata.name", "");
    return volumeClaimTemplates.flatMap((template) => {
      const volumeTemplateName = get(template, "metadata.name");
      if (replicaNumber && serviceName && volumeTemplateName) {
        return Array.from(
          { length: replicaNumber },
          (_, i) => volumeTemplateName + "-" + serviceName + "-" + i
        );
      } else {
        return [];
      }
    });
  };

  extractNamesFromService(spec: Record<string, unknown> | undefined): string[] {
    const volumes = get(spec, "spec.template.spec.volumes") as Record<
      string,
      unknown
    >[];

    return union(
      this.extractNamesFromVolumeClaimTemplates(spec),
      extractNamesFromVolumes(volumes, this)
    );
  }
}
