import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  apiV1HelmReposDeleteUrl,
  DeleteHelmRepoRequest,
  HelmRepo,
  HelmReposApiApiV1HelmReposDeleteRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const deleteHelmRepo = async (
  apiClient: AxiosInstance,
  params: HelmReposApiApiV1HelmReposDeleteRequest
): Promise<HelmRepo> => {
  const { data } = await apiClient.delete(
    apiV1HelmReposDeleteUrl(params, apiClient.defaults.baseURL ?? ""),
    { data: params.deleteHelmRepoRequest }
  );
  return data;
};

export const useDeleteHelmRepo = () => {
  const apiClient = useResourcesApiClient();
  return useMutation((params: DeleteHelmRepoRequest) =>
    deleteHelmRepo(apiClient, { deleteHelmRepoRequest: params })
  );
};
