type Metadata = { annotations?: Record<string, string> } | undefined;
type Spec = {
  metadata?: Metadata;
  spec?: { template?: { metadata?: Metadata } };
};

export const getAnnotations = (
  spec: Record<string, unknown> | undefined
): Record<string, string> | undefined => {
  return {
    ...((spec?.metadata as Metadata)?.annotations ?? {}),
    ...(((spec as Spec)?.spec?.template?.metadata as Metadata)?.annotations ??
      {}),
  };
};
