import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { LockedTab } from "@komodorio/design-system/komodor-ui";

import { TabOptions } from "@/pages/organization-settings/configurations/HealthSettingPage/constants";
import { useHealthSettingsPages } from "@/pages/organization-settings/configurations/HealthSettingPage/useHealthSettingsPages";

type Props = {
  value: TabOptions;
  handleChange: (event: React.SyntheticEvent, newValue: TabOptions) => void;
};

export const HealthSettingsTabs: React.FC<Props> = ({
  value,
  handleChange,
}) => {
  const { tabs } = useHealthSettingsPages();

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      sx={{
        "& .MuiTabs-flexContainer": {
          alignItems: "center",
        },
      }}
    >
      {tabs.map((tab) =>
        tab.disabled ? (
          <LockedTab
            key={tab.value}
            title={tab.label}
            value={tab.value}
            tooltip={tab.tooltip}
            tooltipPlacement="top"
          />
        ) : (
          <Tab
            key={tab.value}
            label={
              <Typography variant="button" color="inherit">
                {tab.label}
              </Typography>
            }
            value={tab.value}
          />
        )
      )}
    </Tabs>
  );
};
