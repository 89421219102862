import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const StatusLine = styled.div<{ color: string | undefined }>`
  width: 4px;
  height: 20px;
  border-radius: 13px;
  background-color: ${({ color }) => color ?? "transparent"};
`;

const StatusCircle = styled.div<{ color: string | undefined }>`
  width: 6px;
  height: 6px;
  border-radius: 13px;
  background-color: ${({ color }) => color ?? "transparent"};
`;

export const DistributionItem: React.FC<{
  statusShape: "line" | "circle";
  color: string | undefined;
  children: React.ReactNode;
  ariaLabel?: string;
}> = ({ statusShape, color, children, ariaLabel }) => {
  const Status = statusShape === "line" ? StatusLine : StatusCircle;

  return (
    <Container aria-label={ariaLabel}>
      <Status color={color} />
      {children}
    </Container>
  );
};
