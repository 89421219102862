import React from "react";
import { Button } from "@komodorio/design-system/deprecated";
import { Check16 } from "@komodorio/design-system/icons";

import { ButtonSupportFreemium } from "@/components/Freemium/Buttons";
import { restrictionsNames } from "@/components/Freemium/Restrictions";
// Todo Lior: this is on purpose.  When we will change all the integrations to use the new design system, we will use Mui's button

interface InstallCTAProps {
  onInstallHandler: () => void;
  title: string;
  shouldLockIntegrations?: boolean;
  disabled?: boolean;
  buttonText?: string;
  showCheck?: boolean;
}

export const InstallCTA: React.FC<InstallCTAProps> = (props) => {
  const {
    onInstallHandler,
    title,
    shouldLockIntegrations,
    disabled,
    buttonText = "Install Integration",
    showCheck,
  } = props;

  if (shouldLockIntegrations) {
    return (
      <ButtonSupportFreemium
        variant="primary"
        disabled={disabled}
        freemiumKey={restrictionsNames.LOCK}
      >
        {buttonText}
      </ButtonSupportFreemium>
    );
  }

  if (title === "Kubernetes Cluster") {
    return (
      <ButtonSupportFreemium
        variant="primary"
        onClick={onInstallHandler}
        disabled={disabled}
        icon={disabled && showCheck ? Check16 : undefined}
        freemiumKey={restrictionsNames.CLUSTERS}
      >
        {buttonText}
      </ButtonSupportFreemium>
    );
  }

  return (
    <Button
      variant="primary"
      onClick={onInstallHandler}
      disabled={disabled}
      icon={disabled && showCheck ? Check16 : undefined}
    >
      {buttonText}
    </Button>
  );
};
