import { getAppConfig } from "../../config/appConfig";
import { useApiGetWithUrl } from "../common-api/request";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
  MonitorParameters,
} from "./requestResponseMaps";

/**
 * @deprecated use react-query instead. see implemantations in services/web/src/shared/hooks/monitors-api/client
 */
export const useMonitorsAPIGet = <T extends keyof EndpointsResponseMap>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<EndpointsResponseMap[T]> => {
  const url = EndpointRequestPathMap[relativePath](
    parameters as MonitorParameters,
    getAPIBasePath()
  );
  return useApiGetWithUrl<EndpointsResponseMap[T]>(url, pause);
};

const getAPIBasePath = (): string => {
  return getAppConfig().monitorsAPIServerURL ?? "";
};
