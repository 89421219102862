import React from "react";
import styled from "styled-components";

import { blackGreyText } from "../../../../../../Colors";
import { TextRegular } from "../../../../../common/typography";

import warningIcon from "./warning.svg";

const LinkWarningStart = "This link applies to all services in this";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

const LinkWarningsContainerStyle = styled(TextRegular)`
  color: ${blackGreyText};
`;

const WarningIcon = styled.div`
  width: 0.95rem;
  height: 0.8rem;
  background-image: url(${warningIcon});
  background-repeat: no-repeat;
`;

const LinkWarningsContainer: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Container>
      <WarningIcon />
      <LinkWarningsContainerStyle>{text}</LinkWarningsContainerStyle>
    </Container>
  );
};

const LinkWarnings: React.FC<{
  clusterName?: string | null;
  namespace?: string | null;
}> = ({ clusterName, namespace }) => (
  <>
    {clusterName ? (
      <LinkWarningsContainer
        text={`${LinkWarningStart} cluster ('${clusterName}')`}
      />
    ) : null}
    {namespace ? (
      <LinkWarningsContainer
        text={`${LinkWarningStart} namespace ('${namespace}')`}
      />
    ) : null}
  </>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LinkWarnings;
