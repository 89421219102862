import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

import {
  OverviewPageState,
  useOverviewPageStore,
} from "../store/overviewPageStore";
import {
  selectAppInsightsData,
  selectOverviewMetricsData,
} from "../store/overviewPageStoreSelectors";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { Dictionary } from "../../../../../../../shared/types/Dictionary";
import { useDDRumStartViewAndAddTiming } from "../../../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../../../../../shared/constants/datadog";

type LoadingStatus = {
  allAreLoading: boolean;
  allAreDoneLoading: boolean;
};

type StorePropState = {
  loading: boolean;
  data?: unknown;
  error?: string | null;
};

export const useOnOverviewStorePropLoaded = (
  storeState: Dictionary<StorePropState>,
  storeKey: keyof OverviewPageState
) => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const reportedDoneLoadingPerId = useRef<string | undefined>();
  const isInLoadingState = useRef(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  useEffect(() => {
    if (!isEqual(storeState, useOverviewPageStore.getState()[storeKey])) return;

    const { allAreLoading, allAreDoneLoading } = Object.values(
      storeState
    ).reduce<LoadingStatus>(
      (acc, value) => {
        const isLoading = value.loading;
        const isDoneLoading = !value.loading && (!!value.data || value.error);

        if (acc.allAreLoading && !isLoading) {
          acc.allAreLoading = false;
        }
        if (acc.allAreDoneLoading && !isDoneLoading) {
          acc.allAreDoneLoading = false;
        }

        return acc;
      },
      {
        allAreLoading: true,
        allAreDoneLoading: true,
      }
    );

    if (allAreLoading) {
      isInLoadingState.current = true;
    }

    if (
      allAreDoneLoading &&
      reportedDoneLoadingPerId.current !== selectedAppViewId &&
      isInLoadingState.current
    ) {
      reportedDoneLoadingPerId.current = selectedAppViewId;
      isInLoadingState.current = false;
      setIsDoneLoading(true);
    }

    return () => {
      setIsDoneLoading(false);
    };
  }, [storeState, selectedAppViewId, storeKey]);

  return isDoneLoading;
};

export const useOnPageDataLoaded = () => {
  const appInsightsData = useOverviewPageStore(selectAppInsightsData);
  const overviewMetricsData = useOverviewPageStore(selectOverviewMetricsData);

  const appInsightsDataHasLoaded = useOnOverviewStorePropLoaded(
    appInsightsData,
    "appInsightsData"
  );
  const overviewMetricsDataHasLoaded = useOnOverviewStorePropLoaded(
    overviewMetricsData,
    "overviewMetricsData"
  );

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.workspaceOverview,
    addTimingParams: {
      enable: overviewMetricsDataHasLoaded && appInsightsDataHasLoaded,
    },
  });
};
