import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { VIOLATIONS_TABLE_HEADER_HEIGHT } from "../violationTableConstants";
import { GenericErrorMessage } from "../../../../../../../../shared/components/GenericErrorMessage";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import { selectSetGroupState } from "../../../../../../store/reliabilityStoreSelectors";

const Container = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => `${height}px`};
  margin-top: ${VIOLATIONS_TABLE_HEADER_HEIGHT}px;
  max-height: 600px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > *:first-child {
    margin-top: 32px;
  }
`;

export const EmptyTableResults: React.FC<{ isError: boolean }> = ({
  isError,
}) => {
  const { uid, violationsGroupBy, setTabValue } = useViolationsTableContext();

  const setGroupState = useReliabilityStore(selectSetGroupState);

  const resetFilters = () => {
    setGroupState({
      uid: uid ?? "",
      groupBy: violationsGroupBy,
      newState: {
        searchTerm: "",
        checkTypes: [],
        statuses: [],
      },
    });

    setTabValue("all");
  };

  const [height, setHeight] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) {
      setHeight(
        containerRef.current.parentElement.clientHeight -
          VIOLATIONS_TABLE_HEADER_HEIGHT
      );
    }
  }, []);

  return (
    <Container height={height} ref={containerRef}>
      {!isError && (
        <FlexContainer>
          <Typography variant={"body1"}>No results found.</Typography>
          <Button onClick={resetFilters}>Clear filters</Button>{" "}
        </FlexContainer>
      )}
      {isError && (
        <CenterContainer>
          <GenericErrorMessage />
        </CenterContainer>
      )}
    </Container>
  );
};
