import { useCallback } from "react";

import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { AddonUrlState } from "@/components/k8sAddons/types";

export const useClearAllFilters = () => {
  const [, setDataInUrl] = useStateInUrlAsJSON<AddonUrlState>(
    K8S_ADD_ONS_FILTERS_PARAM_KEY
  );

  return useCallback(() => {
    setDataInUrl({
      filters: {},
      tableState: {},
    });
  }, [setDataInUrl]);
};
