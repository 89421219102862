import React from "react";
import { Size } from "@komodorio/design-system";
import { Status, StatusColor } from "@komodorio/design-system/komodor-ui";

import { SeverityColor } from "@/components/k8sAddons/types";

type AddonStatusProps = {
  color: SeverityColor;
  label: string;
  size?: Size;
};

const severityColorToStatusColor: Record<SeverityColor, StatusColor> = {
  success: "success",
  info: "neutral",
  warning: "warning",
  error: "error",
};

export const AddonStatusChip: React.FC<AddonStatusProps> = ({
  label,
  color,
  size = "medium",
}) => {
  return (
    <Status
      color={severityColorToStatusColor[color]}
      label={label}
      size={size}
      variant={"outlined"}
    />
  );
};
