import { useEffect, useState } from "react";

import { CommonApiResponse } from "../../../../../../../../../shared/types/commonApiResponse";
import { useReportDrawerLoadingTime } from "../../../hooks/useReportDrawerLoadingTime";

type ApiResponse = CommonApiResponse<unknown>;

export const useReportLoadingState = (
  responses: ApiResponse[],
  isInvalidParams: boolean
) => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (isInvalidParams) {
      return setLoadingComplete(true);
    }
    setLoadingComplete(responses.every((response) => !response.loading));
  }, [isInvalidParams, responses]);

  useReportDrawerLoadingTime(isLoadingComplete);
};
