import React from "react";
import styled from "styled-components";

import { TextRegular } from "../../../../../common/typography";
import { FormTextInput } from "../../../../../monitorsView/monitorsConfiguration/common/styles";
import { ErrorMessage, Grid } from "../../../../../monitorsView/common/styles";
import { blackGreyText } from "../../../../../../Colors";
import { LeftSpace } from "../../../../../common/Spaces/leftSpace";

import TokenGuideToolTip from "./TokenGuideTooltip";

export const errorMessage = "secret is required";

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem;
`;

const StyledTextRegular = styled(TextRegular)`
  color: ${blackGreyText};
`;

const FormInstructionGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

const StyledFormTextInput = styled(FormTextInput)`
  margin-left: 2rem;
  margin-inline: 0;
  width: auto;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-left: 2rem;
`;

const Instruction: React.FC<{
  number: number;
  text: string;
  children?: React.ReactNode;
}> = ({ number, text, children }) => {
  return (
    <>
      <FormInstructionGrid>
        <StyledTextRegular style={{ paddingBottom: "0.25rem" }}>
          <b>0{number}</b> <LeftSpace spaces={3}>{text}</LeftSpace>
        </StyledTextRegular>
        <TokenGuideToolTip />
      </FormInstructionGrid>
      <Grid>{children}</Grid>
    </>
  );
};

export const GenericInstruction: React.FC<{
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  valueValid: boolean;
  handleChange: () => void;
  text: string;
  placeholder: string;
  number: number;
}> = ({
  value,
  setValue,
  valueValid,
  handleChange,
  text,
  placeholder,
  number,
}) => {
  return (
    <RowContainer>
      <Instruction number={number} text={text}>
        <StyledFormTextInput
          value={value}
          placeholder={placeholder}
          isValid={valueValid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            handleChange();
          }}
          data-testid={`sentryTestInput${number}`}
        />
      </Instruction>
      {!valueValid && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </RowContainer>
  );
};
