import {
  ConfigFormValidationState,
  CostOptimizationConfig,
} from "../../types/costOptimizationTypes";

export const costOptimizationConfigToFormValidationState = (
  config: CostOptimizationConfig
): ConfigFormValidationState => {
  return Object.keys(config).reduce((acc, key) => {
    acc[key as keyof CostOptimizationConfig] = {};
    return acc;
  }, {} as ConfigFormValidationState);
};

export const getValidEntriesFromFetchedConfig = (
  currentConfig: CostOptimizationConfig,
  fetchedConfig?: object
): Partial<CostOptimizationConfig> => {
  if (!fetchedConfig) return {};
  return Object.entries(fetchedConfig).reduce<Partial<CostOptimizationConfig>>(
    (acc, [key, value]) => {
      const configKey = key as keyof CostOptimizationConfig;
      if (
        value !== undefined &&
        currentConfig[configKey] &&
        value !== currentConfig[configKey]
      ) {
        acc[configKey] = value;
      }
      return acc;
    },
    {}
  );
};
