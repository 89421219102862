import React from "react";
import { SubmitHandler } from "react-hook-form";
import { UseMutationResponse } from "urql";

import {
  UpdateExternalLinksMutation,
  UpdateExternalLinksMutationVariables,
} from "../../../../../../../generated/graphql";
import { Options } from "../../../../../../../shared/hooks/roles/usePrivilegedOptions";
import { isValidUrl, UpsertLinkErrorType, FormValues } from "../utils";

type EditLinkMutation = UseMutationResponse<
  UpdateExternalLinksMutation,
  UpdateExternalLinksMutationVariables
>;

const editLinkSubmitHandler: (
  updateLinkMutation: EditLinkMutation,
  linkId: string,
  handleClose: () => void,
  setErrorMessage: React.Dispatch<React.SetStateAction<UpsertLinkErrorType>>,
  privilegedOptions: Options | undefined
) => SubmitHandler<FormValues> =
  (
    updateLinkMutation,
    linkId,
    handleClose,
    setErrorMessage,
    privilegedOptions
  ) =>
  async (values) => {
    const { link, name } = values;
    if (!isValidUrl(link)) {
      setErrorMessage(UpsertLinkErrorType.LinkNotValid);
      return;
    }
    const [, updateLink] = updateLinkMutation;
    const { error, data } = await updateLink(
      {
        id: linkId,
        displayName: name,
        link,
      },
      { fetchOptions: privilegedOptions?.context.fetchOptions }
    );
    if (data?.update_external_link_by_pk?.id) {
      handleClose();
    }
    if (error) {
      setErrorMessage(UpsertLinkErrorType.AddLinkError);
    }
  };

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default editLinkSubmitHandler;
