import React, { useMemo } from "react";
import { diff, flattenChangeset, IFlatChange } from "json-diff-ts";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Divider,
  Modal,
  ModalContent,
  ModalHeader,
  Tag,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import YAML from "yaml";

import K8sConfig from "../../../common/EventGroup/deployEvent/K8sConfig";
import { ObjectDiffAsYaml } from "../../../common/ObjectDiff";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { CardContainer } from "../../../common/Card/CardWithTag";
import { useIsDemoService } from "../../StepsLogic";

import { DiffEntry } from "./K8SDiff";
import {
  DiffSection,
  SectionFooter,
  SectionContainer,
  SectionContent,
  SimpleContainer,
  SectionHeader,
} from "./styles";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const PREVIEW_LIMIT = 2;

interface ConfigChange {
  name: string;
  type: string;
  changes: IFlatChange[];
}

const Padding = styled.div`
  padding: 0.5rem 0 0 0;
  width: 100%;
`;

function parseJsonOrYamlValues(
  input: Record<string, string>
): Record<string, string | object> {
  const result: Record<string, string | object> = {};

  for (const key in input) {
    if (Object.hasOwnProperty.call(input, key)) {
      const value = input[key];
      try {
        // Try parsing as JSON
        result[key] = JSON.parse(value);
      } catch (jsonError) {
        try {
          // Try parsing as YAML if JSON parsing failed
          result[key] = YAML.parse(value);
        } catch (yamlError) {
          // Parsing as JSON and YAML both failed, store the value as is
          result[key] = value;
        }
      }
    }
  }
  return result;
}

export const ConfigDiff: React.FC<{
  diffs: K8sConfig[];
}> = ({ diffs }) => {
  const [openDiff, setOpenDiff] = React.useState(false);
  const isDemoService = useIsDemoService();

  const filteredDiffs = useMemo(() => {
    return diffs.filter((d) => {
      const changes = flattenChangeset(diff(d.oldData, d.newData));
      return changes.length > 0;
    });
  }, [diffs]);

  const calculatedChanges = useMemo(() => {
    const totalChanges: ConfigChange[] = [];
    filteredDiffs.forEach((d) => {
      const changes = flattenChangeset(
        diff(parseJsonOrYamlValues(d.oldData), parseJsonOrYamlValues(d.newData))
      );
      if (changes.length > 0) {
        totalChanges.push({
          name: d.name,
          type: d.type,
          changes,
        });
      }
    });
    return totalChanges;
  }, [filteredDiffs]);

  const totalChanges = useMemo(() => {
    return calculatedChanges.reduce(
      (acc, curr) => acc + curr.changes.length,
      0
    );
  }, [calculatedChanges]);

  const additionalChanges = useMemo(() => {
    return calculatedChanges?.length > 0
      ? totalChanges -
          Math.min(PREVIEW_LIMIT, calculatedChanges[0].changes.length)
      : 0;
  }, [calculatedChanges, totalChanges]);

  if (!calculatedChanges || calculatedChanges.length === 0) {
    return null;
  }

  return (
    <CardContainer>
      {isDemoService && (
        <Tag color={muiColors.orange[600]}>
          <Typography
            className="tag"
            variant="overline"
            color={muiColors.common.white}
            fontSize={"12px"}
            lineHeight={"19.2px"}
            fontWeight={500}
          >
            Possible Root Cause
          </Typography>
        </Tag>
      )}
      <SectionContainer isWarning={isDemoService}>
        <DiffSection>
          <SectionHeader>
            <Typography variant={"h6"}>{calculatedChanges[0].name}</Typography>
            <Typography variant={"h6"} color={muiColors.gray[500]}>
              ({calculatedChanges[0].type})
            </Typography>
          </SectionHeader>
          <SectionContent>
            <Padding>
              {calculatedChanges[0].changes.slice(0, PREVIEW_LIMIT).map((c) => (
                <DiffEntry
                  key={c.path}
                  change={{
                    name: c.key,
                    previous: JSON.stringify(c.oldValue),
                    current: JSON.stringify(c.value),
                  }}
                />
              ))}
            </Padding>
          </SectionContent>
        </DiffSection>
        <SimpleContainer>
          <Divider />
          <SectionFooter>
            <Button
              onClick={() => setOpenDiff(true)}
              style={{ padding: 0 }}
              aria-label={
                AriaLabels.InvestigationMode.CorrelatedDeploysStep.DeployCard
                  .ConfigDiffShowAllChanges
              }
            >
              {additionalChanges > 0
                ? ` + ${additionalChanges} changes `
                : "Show all changes"}
            </Button>
            <Modal isOpen={openDiff} onRequestClose={() => setOpenDiff(false)}>
              <ModalHeader />
              <ModalContent>
                {filteredDiffs.map((d) => (
                  <Container key={d.name}>
                    <SectionHeader>
                      <Typography variant={"h6"}>{d.name}</Typography>
                      <Typography variant={"h6"} color={muiColors.gray[500]}>
                        ({d.type})
                      </Typography>
                    </SectionHeader>
                    <ObjectDiffAsYaml oldObj={d.oldData} newObj={d.newData} />
                  </Container>
                ))}
              </ModalContent>
            </Modal>
          </SectionFooter>
        </SimpleContainer>
      </SectionContainer>
    </CardContainer>
  );
};
