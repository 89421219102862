import React, { useState } from "react";

import {
  MetricsAggregationType,
  MetricsGraphProps,
  MetricType,
} from "../../../../../Metrics/types";
import {
  dispatchEvent,
  AnalyticEvents,
} from "../../../../../../shared/hooks/analytics";

import { ClusterGraph } from "./ClusterGraph";
import { AggregatedData } from "./utils";
import { EnlargedClusterGraph } from "./EnlargedClusterGraph";
import {
  ClusterMetricsContextProvider,
  useClusterMetricsContext,
} from "./ClusterMetricsContext";

type EnlargeableClusterGraphProps = Omit<MetricsGraphProps, "data"> & {
  title: string;
  type: MetricType;
  clusterName: string;
  syncId: string;
  loading?: boolean;
  error?: boolean;
  metrics: AggregatedData["memory"] | AggregatedData["cpu"];
  hasData: boolean;
  aggregationType: MetricsAggregationType;
};

export const EnlargeableClusterGraph = ({
  clusterName,
  title,
  type,
  syncId,
  loading,
  error,
  hasData,
  aggregationType,
  metrics,
  ...props
}: EnlargeableClusterGraphProps) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const onEnlargeGraph = () => {
    setIsEnlarged(true);
    dispatchEvent(AnalyticEvents.Metrics.EnlargeGraph, {
      type,
    });
  };
  const onClose = () => setIsEnlarged(false);

  const { timeWindow } = useClusterMetricsContext();

  return isEnlarged ? (
    <ClusterMetricsContextProvider givenTimeWindow={timeWindow}>
      <EnlargedClusterGraph
        title={title}
        type={type}
        onClose={onClose}
        aggregationType={aggregationType}
        clusterName={clusterName}
      />
    </ClusterMetricsContextProvider>
  ) : (
    <ClusterGraph
      title={title}
      timelineTicksNum={8}
      onEnlargeGraph={onEnlargeGraph}
      syncId={syncId}
      type={type}
      loading={loading}
      error={error}
      data={metrics[aggregationType] ?? []}
      hasData={hasData}
      {...props}
    />
  );
};
