import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { CurrentAppViewContextProvider } from "../../context/currentAppViewContext";
import { WorkspaceOverview } from "../../../workspaces/WorkspaceOverview";
import { useWorkspaces } from "../../../workspaces/WorkspacesTopBar/hooks";
import {
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
  WORKSPACE_ID_PARAM,
} from "../../../routes/routes";

import { CreationPage } from "./components/CreationPage/CreationPage";

export const AppView: React.FC = () => {
  const { currentWorkspace } = useWorkspaces();

  return (
    <CurrentAppViewContextProvider>
      <Routes>
        <Route path={CREATE_WORKSPACE} element={<CreationPage />} />
        <Route
          path={`:${WORKSPACE_ID_PARAM}/${EDIT_WORKSPACE}`}
          element={<CreationPage />}
        />
        <Route
          path={`:${WORKSPACE_ID_PARAM}`}
          element={<WorkspaceOverview />}
        />
        <Route
          path="*"
          element={<Navigate to={currentWorkspace?.id ?? CREATE_WORKSPACE} />}
        />
      </Routes>
    </CurrentAppViewContextProvider>
  );
};
