import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import {
  Boxes16,
  IconProps,
  Nodes16,
  Storage16,
  Workflows16,
  Workloads16,
} from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { getNameFromCheckType } from "../../realtimeUtils";

import { CheckType } from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type Props = {
  checkType: CheckType;
};

const iconToCheckType: Dictionary<React.FC<IconProps>> = {
  [CheckType.UnhealthyNode]: Nodes16,
  [CheckType.UnhealthyPvc]: Storage16,
  [CheckType.UnhealthyService]: Workloads16,
  [CheckType.UnhealthyWorkflow]: Workflows16,
  [CheckType.FailedJob]: Boxes16,
};

export const TypeColumn: React.FC<Props> = ({ checkType }) => {
  const Icon = iconToCheckType[checkType];
  return (
    <Container>
      <Icon color={muiColors.pink[600]} />
      <Typography variant="h5">{getNameFromCheckType(checkType)}</Typography>
    </Container>
  );
};
