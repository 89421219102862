import { sortBy, uniqBy } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LocalStorageItem } from "@/shared/utils/localStorageSettings";
import { useIsAccountSetUp } from "@/shared/hooks/useUserMetadata/utils";
import { isSandbox } from "@/shared/utils/sandbox";
import { Kubernetes_Agent_Info } from "@/generated/graphql";
import { agentResponse } from "@/shared/hooks/agents-api/utils";
import { useAgentServicePolling } from "@/shared/hooks/agents-api/agents/useAgentsPolling";
import { Agent } from "@/generated/agents";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

export type AgentType = Pick<
  Kubernetes_Agent_Info,
  | "id"
  | "clusterName"
  | "configuration"
  | "inactive"
  | "lastSeenAlive"
  | "createdAt"
  | "installationId"
>;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isAgentsInstalledLocalStorage = new LocalStorageItem(
  "isAgentsInstalled"
);

const AgentsContext = createContext<agentResponse | undefined>(undefined);

const useSaveLocalStorageAgentsState = (
  agents: agentResponse | undefined
): void => {
  const [isAgentsInstalled, setIsAgentsInstalled] = useStateInLocalStorage(
    isAgentsInstalledLocalStorage,
    ""
  );
  useEffect(() => {
    if (isSandbox()) return;
    if (isAgentsInstalled === "true") return;
    const isActiveAgents = agents?.activeAgents?.length ? "true" : "";
    setIsAgentsInstalled(isActiveAgents);
  }, [agents, isAgentsInstalled, setIsAgentsInstalled]);
};

export const AgentsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isAccountSetUp = useIsAccountSetUp();
  const [agentsData, setAgentsData] = useState<agentResponse | undefined>();
  const { data } = useAgentServicePolling(1000 * 15, !isAccountSetUp); // 15 seconds
  // avoid rerendering when data is the same
  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(agentsData)) {
      return;
    }
    setAgentsData(data);
  }, [agentsData, data]);
  useSaveLocalStorageAgentsState(data);
  return (
    <AgentsContext.Provider value={agentsData}>
      {children}
    </AgentsContext.Provider>
  );
};

const useAgents = (): agentResponse | undefined => useContext(AgentsContext);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const findActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  if (!clusterName) {
    return undefined;
  }
  return findActiveAgentByClusterName(agents, clusterName)?.id;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const findActiveAgentByClusterName = (
  agents: agentResponse | undefined,
  clusterName: string
): Agent | undefined => {
  const agentWithTheClusterName = agents?.activeAgents.filter(
    (a: Agent) => a.clusterName === clusterName
  );
  return agentWithTheClusterName?.sort(
    (a: Agent, b: Agent) =>
      new Date(b.lastSeenAlive).getTime() - new Date(a.lastSeenAlive).getTime()
  )?.[0];
};

const useFindActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  return useMemo(() => {
    return findActiveAgent(agents, clusterName);
  }, [agents, clusterName]);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgent = (
  clusterName: string | undefined
): string | undefined => {
  const agents = useAgents();
  return useFindActiveAgent(agents, clusterName);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgentInfo = (
  clusterName: string | undefined
): Agent | undefined => {
  const agents = useAgents();
  return useMemo(() => {
    if (!clusterName) return undefined;
    return findActiveAgentByClusterName(agents, clusterName);
  }, [agents, clusterName]);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgents = (): agentResponse["activeAgents"] => {
  const allAgents = useAgents();
  return useMemo(() => {
    if (!allAgents) return [];
    return sortBy(uniqBy(allAgents.activeAgents, "clusterName"), [
      "clusterName",
    ]);
  }, [allAgents]);
};

// [CU-86bx58peb] fix fast refresh
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default useAgents;
