import { get } from "lodash";
import { useEffect, useMemo } from "react";
import {
  Container as KubernetesContainer,
  Pod,
} from "kubernetes-types/core/v1.d";

import { useResourcesAPIPost } from "../../../../shared/hooks/resources-api/client";
import {
  DAEMONSET_STATE,
  DEPLOYMENT_STATE,
  ROLLOUT_STATE,
  STATEFULSET_STATE,
} from "../../../../shared/hooks/resources-api/requestResponseMaps";
import { Json } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { KubernetesDeploymentResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  addAvailableActionSelector,
  issueSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { AvailableActions } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useGetPodsState } from "../../../../shared/hooks/resources-api/client/useGetPodsState";

export const useWorkloadContainers = ({
  workloadUid,
  resourceKind,
  agentId,
}: {
  workloadUid: string;
  resourceKind: string;
  agentId: string;
}): KubernetesContainer[] => {
  const workloadStateUrl = useMemo(() => {
    if (!resourceKind) {
      return DEPLOYMENT_STATE;
    }
    switch (resourceKind) {
      case "Deployment":
        return DEPLOYMENT_STATE;
      case "DaemonSet":
        return DAEMONSET_STATE;
      case "StatefulSet":
        return STATEFULSET_STATE;
      case "Rollout":
        return ROLLOUT_STATE;
      default:
        return DEPLOYMENT_STATE;
    }
  }, [resourceKind]);

  const { data: workloadData } = useResourcesAPIPost(
    workloadStateUrl,
    {
      agentId: agentId,
      komodorUids: [workloadUid],
      fields: [Json],
    },
    !workloadUid || !agentId
  );

  return useMemo(() => {
    if (!workloadData?.data?.json?.[0]) {
      return [];
    }
    const workloadObj = workloadData?.data?.json?.[0];
    return get(
      workloadObj,
      "spec.template.spec.containers",
      []
    ) as Array<KubernetesContainer>;
  }, [workloadData?.data?.json]);
};

export const useWorkloadPods = (workloadUid: string): Pod[] => {
  const { data } = useGetPodsState(
    {
      rootOwnerKomodorUid: workloadUid,
      fields: [Json],
    },
    !!workloadUid
  );
  return useMemo(() => {
    if (!data?.data?.json || data?.data?.json?.length === 0) {
      return [];
    }
    return data?.data?.json as Array<Pod>;
  }, [data?.data?.json]);
};

export const useLatestGenerationLongestLivingPodName = (
  pods: Pod[],
  resourceKind: string
): string => {
  return useMemo(() => {
    if (pods.length === 0 || !resourceKind) {
      return "";
    }
    const podHashFieldPath =
      resourceKind === KubernetesDeploymentResource.Kind
        ? "metadata.labels.pod-template-hash"
        : "metadata.labels.controller-revision-hash";
    const uniquePodHashes = [
      ...new Set(
        pods
          .map((pod) => get(pod, podHashFieldPath, "") as string)
          .filter((podHash) => podHash.length !== 0)
      ),
    ];

    const sortedPodsOldestFirst = pods.sort((a, b) => {
      if (!a.metadata?.creationTimestamp || !b.metadata?.creationTimestamp) {
        return 0;
      }
      return (
        Date.parse(a.metadata.creationTimestamp) -
        Date.parse(b.metadata.creationTimestamp)
      );
    });
    if (uniquePodHashes.length > 1) {
      const newestPodHash = get(
        sortedPodsOldestFirst[sortedPodsOldestFirst.length - 1],
        podHashFieldPath,
        ""
      );
      const newestGenertionLongLivedPod = sortedPodsOldestFirst.find(
        (pod) => get(pod, podHashFieldPath, "") === newestPodHash
      );
      //return the longed lived pod out of the newest pods
      return (
        newestGenertionLongLivedPod?.metadata?.name ??
        sortedPodsOldestFirst[0]?.metadata?.name ??
        ""
      );
    }
    return sortedPodsOldestFirst[0]?.metadata?.name ?? "";
  }, [pods, resourceKind]);
};

export const useCanConfigureAction = (): void => {
  const issue = useInvestigationModeStore(issueSelector);
  const addAvailableAction = useInvestigationModeStore(
    addAvailableActionSelector
  );

  useEffect(() => {
    if (issue?.results?.reviewMemoryAllocation) {
      addAvailableAction(AvailableActions.Configure);
    }
  }, [issue?.results?.reviewMemoryAllocation, addAvailableAction]);
};
