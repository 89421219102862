import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { OverviewMetricsTitle } from "../OverviewMetricsStyles";

const StyledTypography = styled(Typography)`
  && {
    line-height: 1;
  }
`;

type LoadingComponentProps = {
  title: string;
};

export const DisabledMetricsComponent: React.FC<LoadingComponentProps> = ({
  title,
}) => {
  return (
    <>
      <OverviewMetricsTitle>{title}</OverviewMetricsTitle>
      <StyledTypography variant={"h1"}>N/A</StyledTypography>
    </>
  );
};
