import { useMutation, useQuery } from "@tanstack/react-query";

import { useEmailDigestApiClient } from "@/shared/hooks/email-digest/apiClient";
import { useWorkspaceQueryKey } from "@/shared/hooks/workspaces-api/useWorkspaceQueryKey";
import {
  EmailDigestConfiguration,
  getEmailDigestConfigurationForUserUrl,
  upsertEmailDigestConfigurationForUserUrl,
  UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest,
} from "@/generated/emailDigestService";

export const GET_EMAIL_DIGEST_CONFIGURATION_PATH = "/api/v1/user/configuration";

export const useGetEmailDigestConfiguration = () => {
  const apiClient = useEmailDigestApiClient();

  const url = getEmailDigestConfigurationForUserUrl(
    apiClient.defaults.baseURL ?? ""
  );
  const keys = useWorkspaceQueryKey([url]);

  return useQuery(
    keys,
    async () => await apiClient.get<EmailDigestConfiguration>(url),
    { keepPreviousData: true }
  );
};

export const useUpsertEmailDigestConfiguration = () => {
  const apiClient = useEmailDigestApiClient();

  return useMutation(
    [GET_EMAIL_DIGEST_CONFIGURATION_PATH],
    async (
      params: UserConfigurationsApiUpsertEmailDigestConfigurationForUserRequest
    ) =>
      await apiClient.put<EmailDigestConfiguration>(
        upsertEmailDigestConfigurationForUserUrl(
          params,
          apiClient.defaults.baseURL ?? ""
        ),
        { ...params.body }
      )
  );
};
