import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { RecommendationsModalProps } from "./types";
import { recommendationsPadding } from "./styles";
import { Content } from "./components/Content";
import { Actions } from "./components/Actions";
import { RecommendationsSubmissionIndication } from "./components/RecommendationsSubmissonIndication";
import { useRecommendationsModalContext } from "./context/useRecommendationsModalContext";
import { useGetNumOfUpdatedContainers } from "./hooks/useGetNumOfUpdatedContainers";

const MODAL_WIDTH = "1080px";

const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      width: ${MODAL_WIDTH};
      height: 100%;
    }
  }
`;

const PaddedDiv = styled.div`
  ${recommendationsPadding}
`;

const DialogFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecommendationsModalContent: React.FC<
  RecommendationsModalProps
> = ({
  rowsContent,
  containersData,
  open,
  handleClose,
  rowData,
  isLoading,
  potentialSaving,
  onChangeStrategy,
  modalStrategy,
}) => {
  const { resetContext } = useRecommendationsModalContext();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isAppliedSuccessfully, setIsAppliedSuccessfully] = useState(false);

  const updatedContainersCount = useGetNumOfUpdatedContainers({
    containersData,
    modalStrategy,
  });

  const applyRecommendations = (isAppliedSuccessfully: boolean) => {
    setIsAppliedSuccessfully(isAppliedSuccessfully);
    setIsSnackbarOpen(true);
  };

  const onClose = () => {
    resetContext();
    handleClose();
  };

  return (
    <>
      <StyledDialog open={open} maxWidth={false} onClose={onClose}>
        <div>
          <PaddedDiv>
            <Typography variant="h3" color="text.primary">
              Review & Apply Recommendations
            </Typography>
          </PaddedDiv>
          <Divider />
        </div>
        <DialogContent>
          {rowData && (
            <Content
              containers={rowsContent}
              containersData={containersData}
              modalStrategy={modalStrategy}
              disabled={isLoading}
              rowId={rowData?.id}
              onChangeStrategy={onChangeStrategy}
              rowData={rowData}
              potentialSaving={potentialSaving}
            />
          )}
        </DialogContent>
        <div>
          <DialogFooter>
            <Divider />
            <Actions
              data={rowData}
              onClose={onClose}
              applyRecommendations={applyRecommendations}
              updatedContainersCount={updatedContainersCount}
            />
          </DialogFooter>
        </div>
      </StyledDialog>
      <RecommendationsSubmissionIndication
        open={isSnackbarOpen}
        setOpen={setIsSnackbarOpen}
        isAppliedSuccessfully={isAppliedSuccessfully}
      />
    </>
  );
};
