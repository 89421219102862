import { notifyDDError } from "../../../../shared/hooks/exceptionManagement";
import { getTerminalInstance } from "../../terminalInstance";

export const onReconnect = (id: string): void => {
  const terminal = getTerminalInstance(id);

  if (terminal) {
    terminal.options.disableStdin = false;
    terminal.options.cursorBlink = true;
  }
};

export const onReconnectFailure = (id: string, error: Error): void => {
  const terminal = getTerminalInstance(id);
  terminal?.writeln("Reconnection attempts limit reached.");
  notifyDDError({
    name: "pod exec - failed to reconnect",
    message: (error as Error).message,
  });
};
