import React from "react";

import {
  WorkflowConfigType,
  WorkflowConfiguration,
  MonitorSensor,
  SelectorTypes,
} from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";
import { Label } from "../common/styles";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const jobDefaultRule: WorkflowConfiguration = {
  id: "",
  name: "",
  createdAt: new Date(),
  type: WorkflowConfigType.Job,
  active: true,
  sensors: [{ cluster: "" }],
};

const JobTriggerText = (
  <>
    Alert on any <b>failed</b> job
  </>
);

const JobRuleSections: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = (sensor: MonitorSensor) => {
    setRule((prev) => ({
      ...prev,
      sensors: [sensor],
    }));
  };

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <Label>{JobTriggerText}</Label>
      </RuleStepSectionContainer>
      <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
        <ScopeSection
          id="scope"
          sensor={rule.sensors[0] as MonitorSensor}
          selectorTypes={SelectorTypes}
          handleChange={handleSensorChange}
        />
      </RuleStepSectionContainer>
      {!isElectronContext() && (
        <RuleStepSectionContainer
          sectionNumber={4}
          title="Where do you want to receive notifications? (Optional)"
        >
          <SinkSection rule={rule} setRule={setRule} />
        </RuleStepSectionContainer>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default JobRuleSections;
