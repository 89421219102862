import React, { useState } from "react";
import { muiColors } from "@komodorio/design-system";
import { Reliability24 } from "@komodorio/design-system/icons";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AccordionDetails from "@mui/material/AccordionDetails";

import { RELIABILITY_ROUTE } from "../../../../routes/routes";
import { ViolationsCell } from "../../../components/ViolationsCell";
import { SectionAccordion } from "../../styles";
import { SectionAccordionSummary } from "../SectionAccordionSummary";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { useGetViolationsFilter } from "../../../../../shared/hooks/reliability-api/filters/useGetViolationsFilter";
import { SummaryLoader } from "../Summary";

import {
  RELIABILITY_SECTION_EMPTY_MESSAGE,
  RELIABILITY_SECTION_TITLE,
} from "./constants";
import { Violations } from "./Violations";
import { useParseViolationsCounts } from "./reliabilitySectionHooks";

import {
  ImpactGroupType,
  ViolationCountBy,
  ViolationFilterOperator,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useStoreIsFetchingForReport } from "@/components/ClustersView/ClusterOverview/useReportClusterOverviewLoadingTime";

export const ReliabilitySection: React.FC<{
  clusters: string[];
}> = ({ clusters }) => {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const {
    data,
    isFetching: isLoadingViolationsCount,
    isInitialLoading,
  } = useGetViolationsFilter(
    {
      countBy: ViolationCountBy.Severity,
      postViolationsCountRequestBody: {
        filterBy: [
          {
            category: ViolationCountBy.ClusterName,
            operator: ViolationFilterOperator.Include,
            value: clusters,
          },
          {
            category: ViolationCountBy.Status,
            operator: ViolationFilterOperator.Include,
            value: [ViolationStatus.Open, ViolationStatus.Confirmed],
          },
          {
            category: ViolationCountBy.ImpactGroupType,
            operator: ViolationFilterOperator.Include,
            value: [ImpactGroupType.Dynamic],
          },
        ],
      },
    },
    { keepPreviousData: true }
  );
  useStoreIsFetchingForReport(
    "isFetchingReliabilityViolationsCount",
    isLoadingViolationsCount
  );
  const violationsStatus = useParseViolationsCounts(data?.data);

  const isEmpty = !isInitialLoading && violationsStatus.count === 0;
  const isDisabled = !isLoadingViolationsCount && isEmpty;
  const isExpanded = !isEmpty && expanded;

  return (
    <SectionAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={isDisabled}
    >
      <SectionAccordionSummary
        title={RELIABILITY_SECTION_TITLE}
        icon={<Reliability24 fill={muiColors.gray[500]} />}
        isExpanded={isExpanded}
        isLoading={isLoadingViolationsCount}
        isEmpty={isEmpty}
        emptyMessage={RELIABILITY_SECTION_EMPTY_MESSAGE}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "16px",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigate(RELIABILITY_ROUTE);
            }}
            aria-label={
              AriaLabels.ClusterOverview.ReliabilitySection.ViewAllButton
            }
          >
            View All
          </Button>
          {isLoadingViolationsCount ? (
            <SummaryLoader />
          ) : (
            <ViolationsCell violations={violationsStatus} />
          )}
        </Box>
      </SectionAccordionSummary>
      <AccordionDetails>
        <Violations
          clusters={clusters}
          shouldShowLoader={isLoadingViolationsCount}
        />
      </AccordionDetails>
    </SectionAccordion>
  );
};
