import { useLayoutEffect, useState } from "react";

type SetMaxChildrenInContainerParams = {
  containerEl?: HTMLElement | null;
  hiddenContentEl?: HTMLElement | null;
  hiddenContentChipWidth?: number;
  selectedItems: string[];
};

export const useSetMaxChildrenInContainer = ({
  containerEl,
  hiddenContentEl,
  hiddenContentChipWidth,
  selectedItems,
}: SetMaxChildrenInContainerParams) => {
  const [maxChildren, setMaxChildren] = useState<number>(0);

  useLayoutEffect(() => {
    if (!containerEl || !hiddenContentChipWidth || !hiddenContentEl) return;

    const allChildren: HTMLCollection = hiddenContentEl.children;
    const containerWidth = getAbsoluteWidth(containerEl);

    let maxChildren = 0,
      index = 0;
    for (const child of allChildren) {
      const childWidth = getAbsoluteWidth(child);
      if (childWidth > containerWidth) {
        const prevChildWidth = getAbsoluteWidth(allChildren[index - 1]);
        if (prevChildWidth + hiddenContentChipWidth > containerWidth) {
          maxChildren = Math.max(index - 1, 1);
        } else {
          maxChildren = index;
        }
        break;
      } else {
        maxChildren = index + 1;
      }
      index++;
    }
    setMaxChildren(maxChildren);
  }, [containerEl, hiddenContentChipWidth, hiddenContentEl, selectedItems]);

  return Math.max(maxChildren, 1);
};

const getAbsoluteWidth = (element: Element) => {
  if (!element) return 0;
  const box = element.getBoundingClientRect();
  return box.x + box.width;
};
