import React from "react";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { useFormContext } from "react-hook-form";

import { CheckType } from "@/generated/reliabilityApi";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { getCheckTypeEnabledField } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getCheckTypeEnabledField";

type EnabledSwitchProps = {
  checkType: CheckType;
  onChange?: (enabled: boolean) => void;
};

export const CheckEnabledSwitch: React.FC<EnabledSwitchProps> = ({
  checkType,
  onChange,
}) => {
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();

  const { watch, setValue } = useFormContext();
  const isCheckEnabled = watch(getCheckTypeEnabledField(checkType));

  const onSwitchChange = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setValue(getCheckTypeEnabledField(checkType), !isCheckEnabled);
    onChange?.(!isCheckEnabled);
  };

  if (isReadOnlyMode) {
    return (
      <Typography variant="body2">
        {isCheckEnabled ? "Enabled" : "Disabled"}
      </Typography>
    );
  }
  return (
    <div onClickCapture={onSwitchChange}>
      <Switch disabled={isSubmittingForm} checked={isCheckEnabled} />
    </div>
  );
};
