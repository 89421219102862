import { muiColors } from "@komodorio/design-system";

export const NUM_CLUSTERS_TO_DISPLAY = 5;
export const OTHERS = "Others";
export const BAR_CHART_MONTH_LABEL_NAME = "barChartMonthKey";

export const CHART_COLORS = [
  muiColors.indigo[600],
  muiColors.blue[400],
  muiColors.deepPurple[300],
  muiColors.deepPurple[100],
  muiColors.teal[400],
  muiColors.teal[100],
];
