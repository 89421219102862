import React, { useMemo, useState } from "react";
import { GridSortModel, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { getGenericServiceColumns } from "../../shared/components/InsightsDataTable/insightDataTableUtils";
import { InsightsDataTable } from "../../shared/components/InsightsDataTable/InsightsDataTable";
import { ServicesTableTitle } from "../../shared/components/InsightsDataTable/ServicesTableTitle";
import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../shared/styles";
import { SecurityColumn, SecurityColumns } from "../../shared/types";
import { getGenericTableColumnRow } from "../../shared/utils/utils";
import { ViewIssueButton } from "../shared/bestPractices/ViewIssueButton";

import { contentTitle, explanationBlock } from "./securityFailedText";
import { CriticalIssueCell, WarningCell } from "./CustomTableCells";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getSecurityRows = (
  columns: SecurityColumn[]
): SecurityColumns[] => {
  return columns.map((column) => ({
    ...getGenericTableColumnRow(column),
    criticalIssues: column.criticalIssues,
    warnings: column.warnings,
  }));
};

const servicesColumnsWithButton =
  getGenericServiceColumns<SecurityColumns>().concat(
    {
      field: "criticalIssues",
      headerName: "Critical issues",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CriticalIssueCell rowData={params} />
      ),
    },
    {
      field: "warnings",
      headerName: "Warnings",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <WarningCell rowData={params} />
      ),
    },
    {
      field: "actionButton",
      headerName: "",
      flex: 1,
      minWidth: 110,
      maxWidth: 110,
      renderCell: (params: GridRenderCellParams) => (
        <ViewIssueButton rowData={params.row} defaultFilter="Security" />
      ),
    }
  );

export const BestPracticesSecurityFailedDrawerContent: React.FC = () => {
  const {
    securityBestPractices: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const rows = useMemo(() => {
    if (!data?.rows) return [];
    return getSecurityRows(data.rows);
  }, [data]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "criticalIssues",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  return (
    <div>
      <InsightsDrawerTitle>{contentTitle}</InsightsDrawerTitle>
      <InsightsDrawerContent>{explanationBlock}</InsightsDrawerContent>
      <ServicesTableTitle />
      <InsightsDataTable
        columns={servicesColumnsWithButton}
        rows={rows}
        props={{
          sortModel,
          onSortModelChange: onSortModelChange,
        }}
      />
    </div>
  );
};
