import { useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { useGetViolationChecks } from "../../../../../../../shared/hooks/reliability-api/violations/useGetViolationChecks";
import { impactGroupIdentifierToText } from "../../../../../constants/impactGroupIdentifierToText";

import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";

export const useGetCheckTypesFromConfigurations =
  (): MuiSelectionOption<string>[] => {
    const { policyConfigurations } = usePolicyDrawerContext();
    const { data } = useGetViolationChecks();

    return useMemo(() => {
      return Object.keys(policyConfigurations).reduce<
        MuiSelectionOption<string>[]
      >((acc, checkType) => {
        const impactGroupId = data?.data?.checks.find(
          (check) => check.type === checkType
        )?.impactGroupId;
        if (
          impactGroupId &&
          impactGroupId !== ImpactGroupIdentifier.BestPractices &&
          !acc.find((option) => option.value === impactGroupId)
        ) {
          acc.push({
            value: impactGroupId,
            label: impactGroupIdentifierToText[impactGroupId]?.title ?? "",
          });
        }
        return acc;
      }, []);
    }, [data?.data?.checks, policyConfigurations]);
  };
