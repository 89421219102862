import React, { useMemo } from "react";
import styled from "styled-components";

import { ResourceLink } from "@/components/k8sAddons/LiveStateDrawer/components/ResourceLinks/ResourceLink";
import { ResourceStatus } from "@/components/k8sAddons/LiveStateDrawer/components/ResourceLinks/ResourceStatus";
import { AddonRelatedResource } from "@/components/k8sAddons/types";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const SingleLinkContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

type ResourceLinksProps = {
  relatedResources: AddonRelatedResource[];
};

export const ResourceLinks: React.FC<ResourceLinksProps> = ({
  relatedResources,
}) => {
  const content = useMemo(() => {
    return (
      <>
        {relatedResources.map((relatedResource, idx) => {
          const addComma = idx < relatedResources.length - 1;
          return (
            <SingleLinkContainer key={relatedResource.komodorUid}>
              <ResourceLink relatedResource={relatedResource} />
              <ResourceStatus status={relatedResource.status} />
              {addComma ? ", " : ""}
            </SingleLinkContainer>
          );
        })}
      </>
    );
  }, [relatedResources]);

  return <Container>{content}</Container>;
};
