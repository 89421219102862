import React from "react";

import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectRightSizingRecommendationModal } from "../../../store/costOptimizationStoreSelectors";

import { RightSizingRecommendationModal } from "./RightSizingRecommendationModal";

export const RightSizingModalContainer: React.FC = () => {
  const { rowId } = useCostOptimizationStore(
    selectRightSizingRecommendationModal
  );
  return <RightSizingRecommendationModal rowId={rowId} />;
};
