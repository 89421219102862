export const mockNodesData2 = {
  data: [
    {
      deploy_state: {
        id: "demo.production-ofer.correlation-engine",
        createdAt: "2023-07-18T11:10:00.068712Z",
        updatedAt: "2023-07-18T11:10:00.068712Z",
        lastDeployEndTime: "2023-07-18T09:06:18.645Z",
        lastDeployStartTime: "2023-07-18T09:06:18.645Z",
        eventTime: "2023-07-18T11:10:00Z",
        accountId: "",
        readyReplicas: 3,
        desiredReplicas: 3,
        currentNotificationConfig: null,
        resourceVersion: "",
        healthStatus: "HEALTHY",
      },
      displayName: "correlation-engine",
      health_state: {
        id: "demo.production-ofer.correlation-engine",
        createdAt: "2023-06-12T05:31:44.704195Z",
        updatedAt: "2023-06-12T05:31:44.704195Z",
        eventTime: "2023-06-12T05:31:26.331Z",
        healthStatus: "HEALTHY",
      },
      k8sClusterName: "production",
      kind: "Deployment",
      namespace: "ofer",
    },
    {
      deploy_state: {
        id: "demo.production-ofer.event-puller-service",
        createdAt: "2023-07-18T11:10:00.068712Z",
        updatedAt: "2023-07-18T11:10:00.068712Z",
        lastDeployEndTime: "2023-07-18T09:06:18.645Z",
        lastDeployStartTime: "2023-07-18T09:06:18.645Z",
        eventTime: "2023-07-18T11:10:00Z",
        accountId: "",
        readyReplicas: 3,
        desiredReplicas: 3,
        currentNotificationConfig: null,
        resourceVersion: "",
        healthStatus: "HEALTHY",
      },
      displayName: "event-puller-service",
      health_state: {
        id: "demo.production-ofer.event-puller-service",
        createdAt: "2023-06-12T05:31:44.704195Z",
        updatedAt: "2023-06-12T05:31:44.704195Z",
        eventTime: "2023-06-12T05:31:26.331Z",
        healthStatus: "HEALTHY",
      },
      k8sClusterName: "production",
      kind: "Deployment",
      namespace: "ofer",
    },
    {
      deploy_state: {
        id: "demo.production-ofer.rest-api",
        createdAt: "2023-07-18T11:10:00.068712Z",
        updatedAt: "2023-07-18T11:10:00.068712Z",
        lastDeployEndTime: "2023-07-18T09:06:18.645Z",
        lastDeployStartTime: "2023-07-18T09:06:18.645Z",
        eventTime: "2023-07-18T11:10:00Z",
        accountId: "",
        readyReplicas: 3,
        desiredReplicas: 3,
        currentNotificationConfig: null,
        resourceVersion: "",
        healthStatus: "HEALTHY",
      },
      displayName: "rest-api",
      health_state: {
        id: "demo.production-ofer.rest-api",
        createdAt: "2023-06-12T05:31:44.704195Z",
        updatedAt: "2023-06-12T05:31:44.704195Z",
        eventTime: "2023-06-12T05:31:26.331Z",
        healthStatus: "HEALTHY",
      },
      k8sClusterName: "production",
      kind: "Deployment",
      namespace: "ofer",
    },
    {
      deploy_state: {
        id: "demo.production-ofer.auth",
        createdAt: "2023-07-18T11:10:00.068712Z",
        updatedAt: "2023-07-18T11:10:00.068712Z",
        lastDeployEndTime: "2023-07-18T09:06:18.645Z",
        lastDeployStartTime: "2023-07-18T09:06:18.645Z",
        eventTime: "2023-07-18T11:10:00Z",
        accountId: "",
        readyReplicas: 3,
        desiredReplicas: 3,
        currentNotificationConfig: null,
        resourceVersion: "",
        healthStatus: "HEALTHY",
      },
      displayName: "auth",
      health_state: {
        id: "demo.production-ofer.auth",
        createdAt: "2023-06-12T05:31:44.704195Z",
        updatedAt: "2023-06-12T05:31:44.704195Z",
        eventTime: "2023-06-12T05:31:26.331Z",
        healthStatus: "HEALTHY",
      },
      k8sClusterName: "production",
      kind: "Deployment",
      namespace: "ofer",
    },
    {
      deploy_state: {
        id: "demo.production-ofer.web-ui",
        createdAt: "2023-07-18T11:10:00.068712Z",
        updatedAt: "2023-07-18T11:10:00.068712Z",
        lastDeployEndTime: "2023-07-18T09:06:18.645Z",
        lastDeployStartTime: "2023-07-18T09:06:18.645Z",
        eventTime: "2023-07-18T11:10:00Z",
        accountId: "",
        readyReplicas: 3,
        desiredReplicas: 3,
        currentNotificationConfig: null,
        resourceVersion: "",
        healthStatus: "HEALTHY",
      },
      displayName: "web-ui",
      health_state: {
        id: "demo.production-ofer.web-ui",
        createdAt: "2023-06-12T05:31:44.704195Z",
        updatedAt: "2023-06-12T05:31:44.704195Z",
        eventTime: "2023-06-12T05:31:26.331Z",
        healthStatus: "HEALTHY",
      },
      k8sClusterName: "production",
      kind: "Deployment",
      namespace: "ofer",
    },
  ],
};
