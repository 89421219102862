import React, { memo, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import Paper from "@mui/material/Paper";
import { muiTheme } from "@komodorio/design-system";
import { AdvancedMultiSelect } from "@komodorio/design-system/komodor-ui";

import { EffectivePermission, User } from "@/generated/auth";
import {
  useDataGridPagination,
  PAGE_SIZE_OPTIONS,
} from "@/shared/hooks/dataGrid/useDataGridPagination";
import { TooltipTextCell } from "@/components/Settings/Users/components/TooltipTextCell";
import {
  ACTIONS_ROUTE,
  POLICIES_ROUTE,
  ROLES_ROUTE,
} from "@/components/routes/routes";
import {
  RBAC_ACTION,
  RBAC_POLICY,
  RBAC_ROLE,
} from "@/shared/config/urlSearchParamsKeys";
import { ariaLabels } from "@/components/Settings/Users/const";
import { useEffectivePermissions } from "@/components/Settings/Users/hooks/useEffectivePermissions";

type URLGetter = (value: string) => string;
type ColumnsDefinitions = GridColDef<EffectivePermission> & {
  urlGetter?: URLGetter;
  accountFeature?: string;
};

const getTooltipTextCell = (params: GridRenderCellParams) => {
  const link = (params.colDef as ColumnsDefinitions).urlGetter?.(params.value);
  const accountFeature = (params.colDef as ColumnsDefinitions).accountFeature;
  return (
    <TooltipTextCell
      text={params.value as string}
      link={link}
      accountFeature={accountFeature}
    />
  );
};

const COLUMN_GAP = 4;
const FILTER_WIDTH = 220;
const COLUMN_MIN_WIDTH = FILTER_WIDTH + COLUMN_GAP;
const COLUMN_MAX_WIDTH = 360;
const FILTER_WIDTH_IN_PX = `${FILTER_WIDTH}px`;
const SELECT_POPOVER_PROPS = {
  maxHeight: "500px",
};

const COLUMNS_DEFINITIONS: ColumnsDefinitions[] = [
  {
    field: "action",
    headerName: "Action",
    renderCell: getTooltipTextCell,
    urlGetter: (action: string) => `${ACTIONS_ROUTE}?${RBAC_ACTION}=${action}`,
    accountFeature: "rbacClusterSync",
    minWidth: COLUMN_MIN_WIDTH,
    maxWidth: COLUMN_MAX_WIDTH,
  },
  {
    field: "policyName",
    headerName: "Policy",
    renderCell: getTooltipTextCell,
    urlGetter: (policy: string) => `${POLICIES_ROUTE}?${RBAC_POLICY}=${policy}`,
    minWidth: COLUMN_MIN_WIDTH,
    maxWidth: COLUMN_MAX_WIDTH,
  },
  {
    field: "roleName",
    headerName: "Role",
    renderCell: getTooltipTextCell,
    urlGetter: (role: string) => `${ROLES_ROUTE}?${RBAC_ROLE}=${role}`,
    minWidth: COLUMN_MIN_WIDTH,
    maxWidth: COLUMN_MAX_WIDTH,
  },
  {
    field: "cluster",
    headerName: "Cluster",
    renderCell: getTooltipTextCell,
    minWidth: COLUMN_MIN_WIDTH,
    maxWidth: COLUMN_MAX_WIDTH,
  },
  {
    field: "namespace",
    headerName: "Namespace",
    renderCell: getTooltipTextCell,
    minWidth: COLUMN_MIN_WIDTH,
    maxWidth: COLUMN_MAX_WIDTH,
  },
];

export const UserDrawerContent: React.FC<{ user: User }> = memo(({ user }) => {
  const {
    data,
    isFetching,
    error,
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
    dataFilters,
    setSelectedProperty,
    filteredRows,
  } = useEffectivePermissions({
    user,
  });

  const { paginationModel, handlePaginationModelChange } =
    useDataGridPagination();

  const content = useMemo(() => {
    if (error) {
      return (
        <Typography color={muiTheme.palette.error.main}>
          There was an error fetching the effective permissions
        </Typography>
      );
    }
    if (isFetching) {
      return <Typography>Loading...</Typography>;
    }

    if (!data?.length) {
      return <Typography>No effective permissions found</Typography>;
    }

    return (
      <Stack direction="column" rowGap="24px">
        <Stack direction="row" columnGap={1}>
          <AdvancedMultiSelect
            options={uniqueActionNamesOptions}
            label="Action"
            ariaLabel={ariaLabels.userPermissionsDrawer.filters.action}
            width={FILTER_WIDTH_IN_PX}
            value={dataFilters.action}
            onChange={(value) => setSelectedProperty("action", value)}
            placeholder={"Select Action"}
            selectPopoverProps={SELECT_POPOVER_PROPS}
          />
          <AdvancedMultiSelect
            options={uniquePolicyNamesOptions}
            label="Policy"
            ariaLabel={ariaLabels.userPermissionsDrawer.filters.policyName}
            width={FILTER_WIDTH_IN_PX}
            value={dataFilters.policyName}
            onChange={(value) => setSelectedProperty("policyName", value)}
            placeholder={"Select Policy"}
            selectPopoverProps={SELECT_POPOVER_PROPS}
          />
          <AdvancedMultiSelect
            options={uniqueRoleNamesOptions}
            label="Role"
            ariaLabel={ariaLabels.userPermissionsDrawer.filters.roleName}
            width={FILTER_WIDTH_IN_PX}
            value={dataFilters.roleName}
            onChange={(value) => setSelectedProperty("roleName", value)}
            placeholder={"Select Role"}
            selectPopoverProps={SELECT_POPOVER_PROPS}
          />
          <AdvancedMultiSelect
            options={uniqueClusterNamesOptions}
            label="Cluster"
            ariaLabel={ariaLabels.userPermissionsDrawer.filters.cluster}
            width={FILTER_WIDTH_IN_PX}
            value={dataFilters.cluster}
            onChange={(value) => setSelectedProperty("cluster", value)}
            placeholder={"Select Cluster"}
            selectPopoverProps={SELECT_POPOVER_PROPS}
          />
          <AdvancedMultiSelect
            options={uniqueNamespaceNamesOptions}
            label="Namespace"
            ariaLabel={ariaLabels.userPermissionsDrawer.filters.namespace}
            width={FILTER_WIDTH_IN_PX}
            value={dataFilters.namespace}
            onChange={(value) => setSelectedProperty("namespace", value)}
            placeholder={"Select Namespace"}
          />
        </Stack>
        <DataGridPro
          autoHeight
          rows={filteredRows}
          columns={COLUMNS_DEFINITIONS}
          getRowId={(row: EffectivePermission) =>
            `${row.action}.${row.policyName}.${row.roleName}.${row.cluster}.${row.namespace}`
          }
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          disableColumnMenu
          sx={{
            maxWidth: "80vw",
          }}
          aria-label={ariaLabels.userPermissionsDrawer.table}
        />
      </Stack>
    );
  }, [
    isFetching,
    error,
    data,
    dataFilters,
    paginationModel,
    handlePaginationModelChange,
    filteredRows,
    setSelectedProperty,
    uniqueActionNamesOptions,
    uniquePolicyNamesOptions,
    uniqueRoleNamesOptions,
    uniqueClusterNamesOptions,
    uniqueNamespaceNamesOptions,
  ]);

  return (
    <Paper sx={{ margin: "0 24px 24px", padding: "16px" }}>
      <Stack rowGap="16px">
        <Typography variant="h5">Effective permissions</Typography>
        {content}
      </Stack>
    </Paper>
  );
});
