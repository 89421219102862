import React, { useCallback, useEffect, useState } from "react";

import { useOverviewPageStore } from "../../store/overviewPageStore";
import {
  selectInsightsDrawer,
  selectSetPageTransition,
  selectUiElementsRect,
} from "../../store/overviewPageStoreSelectors";
import { useClickOutside } from "../../../../../../../../shared/hooks/useClickOutside";
import {
  BODY_SCROLLBAR_WIDTH,
  INSIGHT_CARD_ID,
  INSIGHTS_DRAWER_PADDING,
  MAX_INSIGHTS_DRAWER_WIDTH,
  MIN_INSIGHTS_DRAWER_WIDTH,
} from "../insightsConstants";
import { useEscape } from "../../../../../../../../shared/hooks/useEscape";
import { isModalOpen } from "../../../../../../../../shared/utils/isModalOpen";

const hideBodyScrollRule = "body::-webkit-scrollbar { display: none; }";

const getFirstCssRule = () => {
  const rule = document.styleSheets[0];
  return rule.cssRules[0]?.cssText;
};

export const useDocumentBodyScrollOnDrawerOpen = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const { insightsDrawerOpen } = useOverviewPageStore(selectInsightsDrawer);

  useEffect(() => {
    if (insightsDrawerOpen) {
      document.body.style.paddingRight = `${BODY_SCROLLBAR_WIDTH}px`;
      if (getFirstCssRule() !== hideBodyScrollRule) {
        document.styleSheets[0].insertRule(hideBodyScrollRule, 0);
      }
      containerRef.current?.scrollTo(0, 0);
    } else {
      document.body.style.paddingRight = "0px";
      if (getFirstCssRule() === hideBodyScrollRule) {
        document.styleSheets[0].deleteRule(0);
      }
    }
  }, [containerRef, insightsDrawerOpen]);
};

export const useSetPageTransition = () => {
  const [width, setWidth] = useState(0);
  const uiElementsRect = useOverviewPageStore(selectUiElementsRect);
  const setPageTransition = useOverviewPageStore(selectSetPageTransition);
  const { insightsDrawerContent } = useOverviewPageStore(selectInsightsDrawer);

  useEffect(() => {
    const { overviewContainer, pageContainer, insightsContainer } =
      uiElementsRect;
    if (
      pageContainer?.x &&
      overviewContainer?.right &&
      pageContainer?.right &&
      insightsContainer?.right
    ) {
      if (!insightsDrawerContent) {
        // free space between overview page container and insightsContainer
        const freeSpace =
          pageContainer.right -
          insightsContainer.right -
          INSIGHTS_DRAWER_PADDING;
        // max space available for moving the content
        const to = overviewContainer.right - pageContainer.x;
        // set drawer width within limits
        let newDrawerWidth: number;
        if (to < MIN_INSIGHTS_DRAWER_WIDTH) {
          newDrawerWidth = Math.max(to, MIN_INSIGHTS_DRAWER_WIDTH);
        } else {
          newDrawerWidth = Math.min(to, MAX_INSIGHTS_DRAWER_WIDTH);
        }
        setWidth(newDrawerWidth);
        // set the minimal required page transition
        setPageTransition(Math.max(newDrawerWidth - freeSpace, 0));
      }
    }
  }, [insightsDrawerContent, setPageTransition, uiElementsRect]);

  return { insightsDrawerWidth: width };
};

export const useOnEscClick = (onCloseClick: () => void) => {
  const { insightsDrawerOpen } = useOverviewPageStore(selectInsightsDrawer);

  const onEscape = useCallback(() => {
    if (insightsDrawerOpen && !isModalOpen()) {
      onCloseClick();
    }
  }, [insightsDrawerOpen, onCloseClick]);

  useEscape(onEscape);
};

export const useOnClickOutside = (
  containerRef: React.RefObject<HTMLDivElement>,
  onCloseClick: () => void
) => {
  const { insightsDrawerOpen } = useOverviewPageStore(selectInsightsDrawer);

  const onClickOutside = useCallback(
    (event: Event) => {
      if (!insightsDrawerOpen) return;
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        const target = event.target as HTMLElement;
        // do not close modal if an insight card is clicked of if a modal us open
        if (target.id === INSIGHT_CARD_ID || isModalOpen()) return;

        onCloseClick();
      }
    },
    [containerRef, insightsDrawerOpen, onCloseClick]
  );

  useClickOutside(containerRef, onClickOutside);
};
