import * as React from "react";

import type { IconProps } from "../icons/types";

export const KomobotEmptyState = (props: IconProps) => (
  <svg
    width={322}
    height={132}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={160.5} cy={91.5} r={36} fill="#DCDDDF" />
      <path
        d="M121.5 113.193V71.837l6.159-6.337h65.685l6.156 6.306v41.387l-6.156 6.307h-65.688l-6.156-6.307Z"
        fill="#fff"
        stroke="#707583"
        strokeWidth={4}
      />
      <path
        d="M148.348 86.053c0 4.418-1.791 8-4 8s-4-3.582-4-8 1.791-8 4-8 4 3.582 4 8ZM178.5 86.5c0 4.418-1.791 8-4 8s-4-3.582-4-8 1.791-8 4-8 4 3.582 4 8Z"
        fill="#707583"
      />
      <path
        d="m207.994 58.088 9.671-9.67m-6.753 19.694H223.5M197.971 55.17V45.5"
        stroke="#9195A1"
        strokeWidth={2.918}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={152.018}
        y={105.312}
        width={16}
        height={4}
        rx={2}
        fill="#707583"
      />
      <circle cx={118.3} cy={52.418} r={4} fill="#C9CBCF" />
      <circle cx={97} cy={96.765} r={7.5} fill="#C9CBCF" />
      <path
        d="m90.5 5.52 14-5.02 14 5.02m-28 0v16.172l14 7.808m-14-23.98 14 4.46m0 19.52 14-7.808V5.52m-14 23.981V9.98m14-4.46-14 4.46"
        stroke="#EFF2F5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray="4 7"
      />
      <path
        d="M253.5 99.654 265 95.5l11.5 4.154m-23 0v13.384L265 119.5m-11.5-19.846 11.5 3.692m0 16.154 11.5-6.462V99.654M265 119.5v-16.154m11.5-3.692-11.5 3.692M13.5 91.808 37 83.5l23.5 8.308m-47 0v26.769L37 131.5M13.5 91.808 37 99.192m0 32.308 23.5-12.923v-26.77M37 131.5V99.192m23.5-7.384L37 99.192"
        stroke="#DCDDDF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray="4 7"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5 .5)" d="M0 0h321v131H0z" />
      </clipPath>
    </defs>
  </svg>
);
