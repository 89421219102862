import React, { useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { muiColors } from "@komodorio/design-system";
import { useLocation, useMatch } from "react-router-dom";
import styled from "styled-components";

import { useWorkspaces } from "../workspaces/WorkspacesTopBar/hooks";
import { WorkspaceSelector } from "../workspaces/WorkspacesTopBar/WorkspaceSelector";

import {
  getBreadcrumbs,
  isWorkspaceManagementRoute,
  shouldShowWorkspaceSelector,
} from "./utils";

import { DEMO_ROUTE, WORKSPACE_ROUTE } from "@/components/routes/routes";
import { AppViewActionsButton } from "@/components/appView/sections/AppView/components/OverviewPage/OverviewPageHeader/AppViewActions/AppViewActionsButton";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { HelpButton } from "@/components/TopBar/buttons/HelpButton";
import { ChatButton } from "@/components/TopBar/buttons/ChatButton";
import { SettingsButton } from "@/components/TopBar/buttons/SettingsButton";
import { ProfileButton } from "@/components/TopBar/buttons/ProfileButton";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";

export const WORKSPACES_TOP_BAR_HEIGHT = "56px";

const StyledToolbar = styled(Toolbar)`
  && {
    column-gap: 16px;
    min-height: ${WORKSPACES_TOP_BAR_HEIGHT};
  }
`;

export const TopBar: React.FC = () => {
  const { pathname } = useLocation();
  const {
    isWorkspaceKindBackendFiltered,
    isLoading: isLoadingWorkspaces,
    currentWorkspace,
  } = useWorkspaces();

  const breadcrumbs = getBreadcrumbs(pathname);
  const hasWorkspaceSelector = shouldShowWorkspaceSelector(pathname);
  const { canManageWorkspaces } = useHasPermissions();

  const WorkspaceSelectorComponent = useMemo(() => {
    if (!hasWorkspaceSelector) {
      return null;
    }

    return <WorkspaceSelector key={currentWorkspace?.kind} />;
  }, [currentWorkspace, hasWorkspaceSelector]);

  const isWorkspaceRoute = isWorkspaceManagementRoute(pathname);
  const inDemoRoute = useMatch(DEMO_ROUTE + "/*");
  if (inDemoRoute || isWorkspaceRoute) {
    return null;
  }

  return (
    <AppBar
      position="relative"
      sx={{ backgroundColor: muiColors.common.white }}
      elevation={0}
      aria-label={AriaLabels.Workspaces.TopBar}
    >
      <StyledToolbar>
        {WorkspaceSelectorComponent}
        <Breadcrumbs
          separator={
            <Typography variant="h4" color="text.secondary">
              |
            </Typography>
          }
          sx={{ flexGrow: 1 }}
        >
          {breadcrumbs.map((breadcrumb, i) => (
            <Typography
              key={breadcrumb}
              variant={i === breadcrumbs.length - 1 ? "h4" : "body1"}
              color="text.primary"
            >
              {breadcrumb}
            </Typography>
          ))}
        </Breadcrumbs>

        {pathname.startsWith(WORKSPACE_ROUTE) &&
          !isLoadingWorkspaces &&
          !isWorkspaceKindBackendFiltered &&
          canManageWorkspaces && <AppViewActionsButton />}
        <Stack spacing={0.5} direction="row">
          <HelpButton />
          <ChatButton />
          <SettingsButton />
          <ProfileButton />
        </Stack>
      </StyledToolbar>
      <Divider />
    </AppBar>
  );
};
