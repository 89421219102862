import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

const ERROR_COLOR = muiColors.gray[700];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  text-align: center;
`;

export const FetchError: React.FC = () => (
  <Container aria-label={costOptimizationAriaLabels.errors.fetchErrorContainer}>
    <AlertCircleOutlined24 color={ERROR_COLOR} />
    <Typography variant="body2" color={ERROR_COLOR}>
      There was an error loading the data
    </Typography>
  </Container>
);
