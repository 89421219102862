import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import { muiTheme } from "@komodorio/design-system";

import { SectionTitle } from "./SectionTitle";

interface SectionAccordionSummaryProps {
  title: string;
  icon: React.ReactNode;
  isExpanded: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  isDisabled?: boolean;
  disabledMessage?: string;
  children?: React.ReactNode;
}
export const SectionAccordionSummary: React.FC<
  SectionAccordionSummaryProps
> = ({
  title,
  icon,
  isExpanded,
  isLoading,
  emptyMessage,
  isEmpty,
  isDisabled,
  disabledMessage,
  children,
}) => {
  return (
    <AccordionSummary disabled={!isLoading && (isDisabled || isEmpty)}>
      <SectionTitle
        title={title}
        icon={icon}
        isExpanded={isExpanded}
        isLoading={isLoading}
        isEmpty={isEmpty}
        emptyMessage={emptyMessage}
        isDisabled={isDisabled}
        disabledMessage={disabledMessage}
        sx={{
          title: { color: muiTheme.palette.common.black },
        }}
      >
        {children}
      </SectionTitle>
    </AccordionSummary>
  );
};
