import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useCallback, useEffect, useRef } from "react";
import { isAfter } from "date-fns";

import {
  apiV1KomodorServicesAttributesPostUrl,
  apiV1KomodorServicesSearchGetUrl,
  KomodorServiceAttributesResponse,
  KomodorServicesApiApiV1KomodorServicesAttributesPostRequest,
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  KomodorServicesResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceAttributes } from "../../../../../components/servicesView/servicesAttributes/service-attributes-fetch-hook";
import {
  convertResourcesApiKomodorServiceResponseToServiceInfo,
  ServicesWithLastUpdatedAt,
} from "../../../../../components/servicesView/ProcessServiceFetchRequests";
import { useAppViewsStore } from "../../../../store/appViewsStore/appViewsStore";
import { FetchServicesOutput } from "../../../../../components/servicesView/serviceFetchHooks/types";
import { useWorkspaces } from "../../../../../components/workspaces/WorkspacesTopBar/hooks";
import { useWorkspaceQueryKey } from "../../../workspaces-api/useWorkspaceQueryKey";
import { KOMODOR_SERVICES_SEARCH } from "../../requestResponseMaps";

const KOMODOR_SERVICE_ATTRIBUTES_PATH = `/api/v1/komodor-services/attributes`;

export type Params = Omit<
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  "updatedAtFromEpoch" | "includeDeletedAndInactive"
>;

export const useGetKomodorService = (
  params: Params,
  isFullFetch: boolean,
  overrideIncludeDeletedAndInactive?: boolean,
  interval?: number,
  enabled?: boolean
):
  | UseQueryResult<{
      servicesWithLastUpdatedAt: ServicesWithLastUpdatedAt | undefined;
      isInitialLoad: boolean;
    }> & {
      fetchMode: FetchServicesOutput["fetchMode"];
    } => {
  const { isLoading, isWorkspaceKindBackendFiltered } = useWorkspaces();
  const skipAppViewInHeaders = !isLoading && !isWorkspaceKindBackendFiltered;
  const apiClient = useResourcesApiClient(skipAppViewInHeaders);

  const transformData = useCallback(
    (komodorServicesData: KomodorServicesResponse) => {
      return convertResourcesApiKomodorServiceResponseToServiceInfo(
        komodorServicesData
      );
    },
    []
  );

  const lastUpdatedAtRef = useRef<string | undefined>(undefined);
  const keys = useWorkspaceQueryKey(
    [KOMODOR_SERVICES_SEARCH, params.kind, params, isFullFetch],
    { skip: skipAppViewInHeaders }
  );

  const { remove: removeQuery, ...result } = useQuery(
    keys,
    async () => {
      const isInitialLoad =
        lastUpdatedAtRef.current === undefined || isFullFetch;
      const response = await fetchKomodorServices(apiClient, {
        ...params,
        includeDeletedAndInactive:
          !isInitialLoad || overrideIncludeDeletedAndInactive,
        updatedAtFromEpoch:
          lastUpdatedAtRef.current === undefined || isFullFetch
            ? undefined
            : new Date(lastUpdatedAtRef.current).getTime().toString(),
      });
      const data = transformData(response);

      const newUpdatedAt = data?.lastUpdatedAt;
      if (
        newUpdatedAt &&
        (!lastUpdatedAtRef.current ||
          isAfter(new Date(newUpdatedAt), new Date(lastUpdatedAtRef.current)))
      ) {
        lastUpdatedAtRef.current = newUpdatedAt;
      }

      return {
        servicesWithLastUpdatedAt: data,
        isInitialLoad,
      };
    },
    {
      enabled: enabled && !isLoading,
      refetchInterval: enabled ? interval : undefined,
    }
  );

  useEffect(() => {
    const unsub = useAppViewsStore.subscribe(function onAppViewChanged(
      newState,
      oldState
    ) {
      if (newState.selectedAppViewId !== oldState.selectedAppViewId) {
        lastUpdatedAtRef.current = undefined;
        removeQuery();
      }
    });
    return () => unsub();
  }, [removeQuery]);
  return {
    ...result,
    remove: removeQuery,
    fetchMode: !result.data || result.data.isInitialLoad ? "full" : "diff",
  };
};

type GetKomodorServiceAttributesParams = {
  params: KomodorServicesApiApiV1KomodorServicesAttributesPostRequest;
  interval?: number;
  enabled?: boolean;
};

export const useGetKomodorServiceAttributes = ({
  params,
  interval,
  enabled,
}: GetKomodorServiceAttributesParams) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [KOMODOR_SERVICE_ATTRIBUTES_PATH, params],
    () => fetchKomodorServiceAttributes(apiClient, params),
    { enabled, refetchInterval: interval }
  );
};

export const convertToKomodorServiceAttributes = (
  data: KomodorServiceAttributesResponse | undefined
): KomodorServiceAttributes | undefined => {
  return data?.data?.map((komodorServiceAttribute) => {
    return komodorServiceAttribute as KomodorServiceAttributes[0];
  }) as KomodorServiceAttributes;
};

export const fetchKomodorServices = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesSearchGetRequest
): Promise<KomodorServicesResponse> => {
  const { data } = await apiClient.get(
    apiV1KomodorServicesSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

const fetchKomodorServiceAttributes = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesAttributesPostRequest
): Promise<KomodorServiceAttributesResponse> => {
  const { data } = await apiClient.post(
    apiV1KomodorServicesAttributesPostUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    ),
    params.komodorServiceAttributesParams
  );
  return data;
};
