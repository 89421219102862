import React from "react";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";

import { tableProps } from "../../violationsBreakdownUtils";
import { useEmptyColumns, useIssuesColumns } from "../hooks/useIssuesColumns";
import { useIssuesTableContext } from "../context/useIssuesTableContext";
import { useOpenIssueDrawer } from "../hooks/useOpenIssueDrawer";
import { useReportTableLoadingTime } from "../../ViolationTableWithContext/hooks/useReportTableLoadingTime";

import { useRealtimeIssues } from "@/components/reliability/hooks/reliabilityHooks";
import { generateEmptyRows } from "@/shared/utils/tableUtils";
import { BasicViolation } from "@/generated/reliabilityApi";
import { useRowsCount } from "@/components/reliability/components/shared/table/useRowsCount";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

export const IssuesTable: React.FC = () => {
  const { data, isFetching, error } = useRealtimeIssues();
  const { paginationModel, setPaginationModel } = useIssuesTableContext();
  const rowsCount = useRowsCount(data?.data?.totalResults);

  useReportTableLoadingTime(true);
  const openIssueDrawer = useOpenIssueDrawer();

  const onRowClickHandler = (params: GridRowParams<BasicViolation>) => {
    openIssueDrawer(params);
    dispatchEvent(AnalyticEvents.Reliability.IssuesRowClicked, {
      rowCheckType: params.row.checkType,
    });
  };

  const hasNoResults = data?.data.violations?.length === 0 || !!error;

  const rowsToUse =
    isFetching || hasNoResults
      ? generateEmptyRows<BasicViolation>(paginationModel.pageSize)
      : data?.data.violations;

  const columns = useIssuesColumns();
  const emptyColumns = useEmptyColumns();

  const columnsToUse = isFetching || hasNoResults ? emptyColumns : columns;

  const ariaLabel =
    isFetching || hasNoResults
      ? `${tableProps["aria-label"]} loading`
      : `${tableProps["aria-label"]} content`;

  return (
    <DataGridPro
      {...tableProps}
      aria-label={ariaLabel}
      rows={rowsToUse ?? []}
      onRowClick={onRowClickHandler}
      columns={columnsToUse}
      rowCount={rowsCount}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
};
