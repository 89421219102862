import React, { createContext, useContext, useState } from "react";

import { getInitialTimeWindow } from "../sections/AppView/utils/timeWindow";
import { NonCustomTimeframe } from "../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { Timeframe, TimeWindow } from "../../../shared/types/TimeWindow";

type CurrentAppViewContextState = {
  timeWindow: TimeWindow;
  setTimeWindow: (timeWindow: TimeWindow) => void;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialTimeFrame: NonCustomTimeframe = Timeframe.Last24Hours;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialState: CurrentAppViewContextState = {
  timeWindow: getInitialTimeWindow(initialTimeFrame),
  setTimeWindow: () => undefined,
};

const { timeWindow: initialTimeWindow } = initialState;

const CurrentAppViewContext =
  createContext<CurrentAppViewContextState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentAppViewContext = (): CurrentAppViewContextState =>
  useContext(CurrentAppViewContext);

export const CurrentAppViewContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [timeWindow, setTimeWindow] = useState<TimeWindow>(initialTimeWindow);

  const contextState: CurrentAppViewContextState = {
    timeWindow,
    setTimeWindow,
  };

  return (
    <CurrentAppViewContext.Provider value={contextState}>
      <div data-e2e-selector={"app-views"}>{children}</div>
    </CurrentAppViewContext.Provider>
  );
};
