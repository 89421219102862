import React, { useMemo } from "react";

import InspectionViewComponent, {
  useInspectionParams,
} from "../InspectionViews/common";
import { KubernetesSecretsResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesSecretsResource";
import { useInspectionData } from "../utils/useGetInspectionData";
import { Panel } from "../styles";
import FetchDataLoading from "../../common/loaders/LoadingState";
import {
  AgentInfo,
  agentInfoToProperties,
} from "../../../shared/hooks/useAgentInfo/useAgentInfo";

export const SecretsPage: React.FC = () => {
  const {
    cluster,
    onClusterChange,
    agentInfo: agentType,
    agentId,
  } = useInspectionParams(KubernetesSecretsResource);

  const agentInfo = useMemo(() => {
    if (!agentType) return null;
    return agentInfoToProperties(agentType as AgentInfo);
  }, [agentType]);

  const { namespacesResult, resourceList, refresh, isLiveDataSupported } =
    useInspectionData({
      agentId: agentId ?? "",
      agentInfo,
      cluster: cluster ?? "",
      kubernetesResource: KubernetesSecretsResource,
      fieldSelector: "type!=helm.sh/release.v1", // exclude helm secrets
    });

  if (!cluster || !agentId) {
    return (
      <Panel>
        <FetchDataLoading
          dataName={
            KubernetesSecretsResource.PresentName ??
            KubernetesSecretsResource.NameInK8S
          }
        />
      </Panel>
    );
  }

  return (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceList}
      kubernetesResource={KubernetesSecretsResource}
      refresh={refresh}
      namespaces={namespacesResult}
      shouldUseLiveData={isLiveDataSupported}
    />
  );
};
