import React, { useEffect } from "react";

export type MutationObserverParams<T> = {
  ref: React.RefObject<T>;
  callback: MutationCallback;
  options: MutationObserverInit;
};

/** to have this hook behave properly, memoize {options} and wrap {callback} in useCallback() **/
export const useMutationObserver = <T extends Element>({
  ref,
  options,
  callback,
}: MutationObserverParams<T>): void => {
  useEffect(() => {
    let observer: MutationObserver;
    if (ref.current) {
      observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
    }
    return () => observer?.disconnect();
  }, [callback, options, ref]);
};
