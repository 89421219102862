import React from "react";
import styled from "styled-components";

import SettingsViewLayout from "../SettingsViewLayout";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";

import AccountPlan from "./AccountPlan/AccountPlan";
import { Features } from "./BetaFeatures/Features";

const WithPadding = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const General: React.FC = () => (
  <SettingsViewLayout>
    <ValidationsProvider>
      <WithPadding>
        <Features />
        <AccountPlan />
      </WithPadding>
    </ValidationsProvider>
  </SettingsViewLayout>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default General;
