import React from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import TextField from "@mui/material/TextField";

export type NumberTextFieldProps = TextFieldProps & {
  allowNegative?: boolean;
  allowDecimal?: boolean;
  min?: number;
  max?: number;
};

/** TODO: once component is stable, move tom shared location */
export const NumberTextField = React.forwardRef<
  HTMLInputElement,
  NumberTextFieldProps
>(
  (
    { allowNegative = true, allowDecimal = true, min, max, ...props },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const sanitizeValue = (value: string) => {
      if (allowDecimal) {
        return (
          value
            // Remove anything that isn't valid in a number
            .replace(/[^\d.]/g, "")
            // Remove the last period if there is already one
            .replace(/(\..*)\.$/, "$1")
            // Remove all periods unless it is the last one
            .replace(/\.(?=.*\.)/g, "")
        );
      }
      if (allowNegative) {
        return (
          value
            .replace(/[^\d-]/g, "")
            // Remove all dashes unless it is the first character
            .replace(/(?!^)-/g, "")
        );
      }

      return value.replace(/[^\d]/g, "");
    };

    const onNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      event.target.value = sanitizeValue(value);
      const numberValue = parseFloat(value);
      if (min !== undefined && numberValue < min) {
        return;
      }
      if (max !== undefined && numberValue > max) {
        return;
      }
      return props.onChange?.(event);
    };

    return (
      <TextField
        {...props}
        inputRef={ref}
        onChange={onNumberChange}
      ></TextField>
    );
  }
);
