import React, { useState } from "react";

import ChartUninstallDrawer from "../../../Inspection/Helm/drawers/ChartUninstallDrawer";
import HelmChart from "../../../Inspection/Helm/helmChart";
import { ActionButtonProps } from "../types";
import Action from "../index";
import { useHasPermissions } from "../../../../shared/hooks/useUserMetadata/rbac";

export const UninstallHelmChartButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
}) => {
  const [open, setOpen] = useState(false);
  const { canManageHelm } = useHasPermissions();

  return canManageHelm ? (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={() => setOpen(true)}
      Button={Button}
    >
      <ChartUninstallDrawer
        onClose={() => setOpen(false)}
        open={open}
        chart={resource as HelmChart}
      />
    </Action>
  ) : null;
};
