import React from "react";
import {
  ChevronDown16,
  ChevronRight16,
  Duplicate16,
  Trash16,
} from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import { IconButton } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { ifProp } from "styled-tools";

import { RbacActionRule } from "./types";

const Span = styled.span`
  color: ${theme.foreground.fgSubtle};
  height: 1rem;
`;

const RuleHeader = styled.div<{ open: boolean }>`
  ${ifProp("open", `background-color: ${theme.background.bgGray};`)}
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const HeaderWithTitleAndButtons: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  rule: RbacActionRule;
  index: number;
  readOnly?: boolean;
  onDuplicate: (rule: RbacActionRule) => void;
  onDelete?: (index: number) => void;
}> = ({ open, rule, index, setOpen, readOnly, onDuplicate, onDelete }) => {
  return (
    <RuleHeader open={open}>
      <Span>
        {open ? (
          <ChevronDown16 onClick={() => setOpen(false)} />
        ) : (
          <ChevronRight16 onClick={() => setOpen(true)} />
        )}
      </Span>
      <Typography fontSize="medium">Rule {index + 1}</Typography>
      <span />
      <span>
        <IconButton
          icon={Duplicate16}
          noBorder
          onClick={() => onDuplicate(rule)}
          disabled={readOnly}
        />
        {onDelete && (
          <IconButton
            icon={Trash16}
            noBorder
            onClick={() => onDelete(index)}
            disabled={readOnly}
          />
        )}
      </span>
    </RuleHeader>
  );
};
