import { useCallback } from "react";

import { useCloseSocketSessionById } from "../../shared/sockets/useCloseSocketById";
import { buildIdentifier } from "../../shared/hooks/podLogs/useGetLivePodLogs/buildIdentifier";

interface Args {
  agentId?: string | null;
  namespace: string;
  podName: string;
  containerName: string;
}

export const useCloseLivePodLogsSessionIfNeeded = (): ((
  args: Args
) => void) => {
  const closeSocketSession = useCloseSocketSessionById();

  return useCallback(
    ({ agentId, containerName, namespace, podName }: Args) => {
      const identifier = buildIdentifier({
        agentId,
        namespace,
        podName,
        containerName,
      });

      closeSocketSession(identifier);
    },
    [closeSocketSession]
  );
};
