import EventGroup from "../../common/EventGroup";
import DeployEventGroup from "../../common/EventGroup/deployEvent/DeployEventGroup";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default function* selectDeployDetails(g: EventGroup): Iterable<string> {
  if (!(g instanceof DeployEventGroup)) {
    return;
  }
  if (g.status === "started") {
    yield "Deploy in progress";
  }
  if (g.status === "failed") {
    yield "Failed deploy";
  }
  const gitCompare = g.events[0].getGitCompare();
  if (gitCompare?.length) {
    yield "Git changes";
  }
  if (
    gitCompare?.some((gc) =>
      gc.commitDiff?.some((commit) => commit.files?.length)
    )
  ) {
    yield "File changes";
  }
}
