import Button from "@mui/material/Button";
import styled from "styled-components";

import { EmptyState } from "../../common/UpgradeAgentVersionAlert/EmptyState";

const EmptyStateContainer = styled.div`
  display: grid;
`;

interface ClustersEmptyState {
  onActionClick: () => void;
}
export const ClustersEmptyState: React.FC<ClustersEmptyState> = ({
  onActionClick,
}) => (
  <EmptyStateContainer>
    <EmptyState title="No clusters connected">
      <Button variant="text" color="primary" onClick={onActionClick}>
        Connect new cluster
      </Button>
    </EmptyState>
  </EmptyStateContainer>
);
