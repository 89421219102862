import React from "react";
import Box from "@mui/material/Box";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Typography, { TypographyProps } from "@mui/material/Typography";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

const StyledIcon = styled.div`
  color: ${muiColors.gray[500]};
`;

interface SectionAccordionSummaryProps {
  className?: string;
  title: string;
  icon: React.ReactNode;
  isExpandable?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  isDisabled?: boolean;
  disabledMessage?: string;
  children?: React.ReactNode;
  sx?: {
    title?: TypographyProps["sx"];
  };
}
export const SectionTitle: React.FC<SectionAccordionSummaryProps> = ({
  className,
  title,
  icon,
  isExpandable = true,
  isExpanded,
  isLoading,
  emptyMessage,
  isEmpty,
  isDisabled,
  disabledMessage,
  sx,
  children,
}) => {
  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        columnGap: "8px",
        alignItems: "center",
        width: "100%",
      }}
    >
      {isExpandable ? (
        !isLoading && (isDisabled || isEmpty) ? (
          <Box width={24} />
        ) : (
          <ChevronRight
            sx={{
              rotate: isExpanded ? "90deg" : "none",
              transition: "rotate 0.2s",
              color: muiColors.gray[500],
            }}
          />
        )
      ) : null}
      <StyledIcon>{icon}</StyledIcon>
      <Typography
        variant="h4"
        sx={{ whiteSpace: "nowrap", ...(sx?.title ?? {}) }}
      >
        {title}
      </Typography>
      {isLoading && !isExpanded ? (
        <>
          <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
          <CircularProgress size={16} />
          <Typography variant="body2" color="text.primary">
            Loading...
          </Typography>
        </>
      ) : isEmpty ? (
        <>
          <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
          <CheckCircle sx={{ color: muiColors.green[500] }} />
          <Typography variant="body2" color="text.primary">
            {emptyMessage}
          </Typography>
        </>
      ) : isDisabled ? (
        <>
          <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
          <Typography variant="body2" color="text.primary">
            {disabledMessage}
          </Typography>
        </>
      ) : (
        children
      )}
    </Box>
  );
};
