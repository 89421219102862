import React from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { CustomTooltipContainer } from "../../../appView/sections/AppView/components/shared/graph/TooltipContainer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  AVG_TEXT,
  VALUE_KEY,
} from "../IssuesAfterDeploy/IssuesAfterDeployGraph";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AmountOrDuration } from "../IssuesAfterDeploy/IssuesAfterDeployGraphChart";
import { formatNumber } from "../../../../shared/utils/formatNumber";

export const CustomTooltip: React.FC<{
  data: TooltipProps<ValueType, NameType>;
  numDays: number;
  type: AmountOrDuration;
}> = ({ data, numDays, type }) => {
  if (!data.payload) return null;

  const ChartTooltipMapper = {
    [VALUE_KEY]: type === "amount" ? "Issues" : "Average duration",
    [AVG_TEXT]: `Last ${numDays}d average`,
  };

  const getTooltipTitle = (label: string) =>
    ChartTooltipMapper[label as keyof typeof ChartTooltipMapper];
  return (
    <CustomTooltipContainer
      payload={data.payload}
      labelFormat={getTooltipTitle}
      valueFormat={formatNumber}
      tooltipTitle={data.label}
    />
  );
};
