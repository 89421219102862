import { Edge } from "react-flow-renderer";

import { NetworkMapGraphResponseEdgesInner } from "../../../generated/resourcesApi";
import {
  EDGE_MARKER_TYPE,
  EDGE_MARKER_WIDTH,
  EDGE_MARKER_HEIGHT,
} from "../constants";
import { KomodorServicesResponseMapper } from "../types";

export const createEdge = (
  edge: NetworkMapGraphResponseEdgesInner,
  uidToServiceMap: KomodorServicesResponseMapper
): Edge => {
  const sourceData = uidToServiceMap[edge.source || ""];

  const destinationData = uidToServiceMap[edge.destination || ""];

  return {
    id: `${sourceData?.displayName}-${destinationData?.displayName}`,
    source: edge.source || "",
    target: edge.destination || "",
    animated: true,
    markerEnd: {
      type: EDGE_MARKER_TYPE,
      width: EDGE_MARKER_WIDTH,
      height: EDGE_MARKER_HEIGHT,
    },
  };
};

export const createGraphEdges = (
  edges: NetworkMapGraphResponseEdgesInner[],
  uidToServiceMap: KomodorServicesResponseMapper
): Edge[] => {
  return edges.map((edge) => createEdge(edge, uidToServiceMap));
};
