import React, { memo, ReactElement } from "react";
import styled from "styled-components";

import EventGroup from "../../EventGroup";

interface DotProps {
  id: string;
  cx: number;
  cy: number;
  index: number;
  size: number;
  iconSize: number;
  eventGroup: EventGroup;
  onMouseEnter: (() => void) | undefined;
  onMouseLeave: (() => void) | undefined;
  onClick: (() => void) | undefined;
  highlighted?: boolean;
  showEndingIcon?: boolean;
  showMidEventIcon?: boolean;
}

const DotSvg = styled.svg<{ eventGroup?: EventGroup }>`
  :hover {
    filter: drop-shadow(0px 2px 8px rgba(22, 24, 31, 0.15));
    .dot-icon {
      fill: white;
    }
  }
  .highlighted-dot {
    filter: drop-shadow(0px 2px 8px rgba(22, 24, 31, 0.15));
  }
  .circle:hover {
    stroke-width: 1;
    stroke: white;
    ${({ eventGroup }) =>
      `
        fill: ${eventGroup?.fillColor};
        `}
  }
  .highlighted-circle {
    stroke-width: 1;
    stroke: white;
    ${({ eventGroup }) =>
      `
      fill: ${eventGroup?.fillColor};
      `}
  }
  .highlighted-dot-icon {
    fill: white;
  }
`;

const Dot = memo<DotProps>(
  ({
    id,
    cx,
    cy,
    index,
    size,
    iconSize,
    eventGroup,
    highlighted,
    showEndingIcon,
    showMidEventIcon,
  }) => {
    if (index > 0 && !showEndingIcon && !showMidEventIcon) {
      return null as unknown as ReactElement<SVGElement>;
    }
    const Icon = eventGroup.icon;

    const x = showMidEventIcon ? 0 : cx - size / 2;
    const y = cy - size / 2;

    return (
      <DotSvg
        eventGroup={eventGroup}
        x={x}
        y={y}
        width={size}
        height={size}
        id={highlighted ? "highlighted-dot" : ""}
        className={highlighted ? "highlighted-dot" : ""}
      >
        <Icon
          id={id}
          className={highlighted ? "highlighted-dot-icon" : "dot-icon"}
          cursor={"pointer"}
          pointerEvents="none"
          width={iconSize}
          height={iconSize}
          x={(size - iconSize) / 2}
          y={(size - iconSize) / 2}
          fill={eventGroup.fillColor}
        />
      </DotSvg>
    );
  }
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Dot;
