import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  MultiSelect,
  Typography,
} from "@komodorio/design-system/deprecated";
import { useDebouncedCallback } from "use-debounce";
import { InvitedUserSchema } from "komodor-types";
import { parseISO } from "date-fns";

import { Form, FormError, Z_INDEX_ABOVE_MODAL } from "../styles";
import { useFormValidations } from "../../../shared/context/ValidationsProvider";
import { useGetRbacRoles } from "../../../shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { User } from "../../../generated/auth";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import { useAddUser } from "./actions";
import { UserForm } from "./types";
import { getNextDay, validateUserRoles, validateUserTempRoles } from "./utils";
import { ariaLabels, GENERAL_ERROR_MESSAGE } from "./const";
import {
  useGetRolesOptions,
  useGetRoleWithUserExpiration,
  useHandleUserSave,
} from "./hooks";
import { TemporaryRolesModal } from "./TemporaryRolesModal";

const defaultValue = {
  name: "",
  email: "",
  roles: [],
  tempRole: { label: "", value: "" },
};

const validateUserName = (name: string | undefined) => {
  if (!name) {
    return "Please enter full name";
  }
  const isValid = InvitedUserSchema.pick(["displayName"]).isValidSync({
    displayName: name,
  });
  if (!isValid) {
    return "Invalid name, Please use only letters, numbers, spaces and dashes";
  }
  return;
};

const validateUserEmail = (email: string | undefined) => {
  if (!email) {
    return "Please enter email address";
  }
  const isValid = InvitedUserSchema.pick(["email"]).isValidSync({
    email,
  });
  if (!isValid) {
    return "Invalid email address";
  }
  return;
};

const UserFormModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  user?: User;
  refreshUsers: () => void;
  isOnlyOneAccountAdmin?: boolean;
}> = ({ open, handleClose, user, refreshUsers, isOnlyOneAccountAdmin }) => {
  const [value, setValue] = useState<UserForm>(defaultValue);
  const [isDefaultRolesInitiated, setIsDefaultRolesInitiated] =
    useState<boolean>(false);
  const { errors, setError } = useFormValidations();
  const [showAddTemporaryRoleUi, setShowAddTemporaryRoleUi] = useState(false);
  const [isLoadingMutation, setLoadingMutation] = useState(false);
  const [timeValue, setTimeValue] = useState<Date | null>(getNextDay());
  useMemo(() => {
    return user && user.rbacUserRoles?.find((r) => r.expiration !== null);
  }, [user]);
  const { temporaryRoles, permanentRoles } = useGetRoleWithUserExpiration(user);
  useEffect(() => {
    if (user) {
      setValue({
        name: user.displayName,
        email: user.email,
        roles:
          permanentRoles?.map((role) => ({
            label: role.name,
            value: role.id,
          })) ?? [],
        tempRole: {
          label: temporaryRoles?.[0]?.name ?? "",
          value: temporaryRoles?.[0]?.id ?? "",
        },
      });
      if (temporaryRoles?.[0]?.expiration)
        setTimeValue(parseISO(temporaryRoles?.[0]?.expiration) || new Date());
    }
  }, [permanentRoles, temporaryRoles, user]);
  const { showTemporaryRoles: showTemporaryRolesFF } = useOverridableFlags();
  const { data: allRoles = [] } = useGetRbacRoles();
  const roleOptions = useGetRolesOptions(value, !!isOnlyOneAccountAdmin);

  useEffect(() => {
    if (value.roles.length !== 0 || user || isDefaultRolesInitiated) return;
    const defaultRole = allRoles.find((r) => r.isDefault);
    if (!defaultRole) return;
    const defaultRoleId = {
      label: defaultRole.name,
      value: defaultRole.id,
    };
    setValue({ ...value, roles: [defaultRoleId] });
    setIsDefaultRolesInitiated(true);
  }, [allRoles, value, user, isDefaultRolesInitiated]);

  const [addUser, addLoading] = useAddUser();
  const handleSaveMutation = useHandleUserSave(
    value,
    timeValue,
    setLoadingMutation,
    user
  );
  const handleSave = async () => {
    const { name, email, roles, tempRole } = value;
    const [nameError, emailError, rolesError, tempRolesError] = [
      validateUserName(name),
      validateUserEmail(email),
      validateUserRoles(roles),
      showAddTemporaryRoleUi ? validateUserTempRoles(tempRole) : undefined,
    ];
    setError("name", nameError);
    setError("email", emailError);
    setError("roles", rolesError);
    setError("temp_roles", tempRolesError);
    if (
      nameError ||
      emailError ||
      rolesError ||
      tempRolesError ||
      errors["temp_roles_date"]
    ) {
      return;
    }
    const newRoleIds = roles.map((r) => r.value);

    if (user) {
      try {
        await handleSaveMutation();
      } catch (e) {
        setError("general", GENERAL_ERROR_MESSAGE);
        return;
      }
    } else {
      const result = await addUser({
        displayName: name.trim(),
        email: email.trim().toLowerCase(),
        rbacRoleIds: newRoleIds,
      });
      if (!result?.success) {
        setError(
          "general",
          result.errorMessage === "[GraphQL] User already exists in Komodor"
            ? "The user already exists in Komodor."
            : GENERAL_ERROR_MESSAGE
        );
        return;
      }
    }
    refreshUsers();
    handleClose();
  };

  const { callback: debouncedNameValidation } = useDebouncedCallback(
    (name: string) => setError("name", validateUserName(name)),
    1000
  );

  const { callback: debouncedEmailValidation } = useDebouncedCallback(
    (email: string) => setError("email", validateUserEmail(email)),
    1500
  );

  const loading = addLoading || isLoadingMutation;
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>
        <Typography variant="headline">{user ? "Edit" : "Add"} User</Typography>
      </ModalHeader>
      <ModalContent aria-label={ariaLabels.addUserModal.container}>
        <Form width="20rem">
          <Input
            size="medium"
            label="Full Name"
            value={value.name}
            onChange={(e) => {
              setValue({ ...value, name: e.target.value });
              debouncedNameValidation(e.target.value);
            }}
            errorMessage={errors["name"]}
          />
          <Input
            size="medium"
            label="Email"
            disabled={!!user}
            value={value.email}
            onChange={(e) => {
              setValue({ ...value, email: e.target.value });
              debouncedEmailValidation(e.target.value);
            }}
            errorMessage={errors["email"]}
          />
          <MultiSelect
            size="medium"
            width="20rem"
            label="Roles"
            listMaxHeight="14rem"
            listZIndex={Z_INDEX_ABOVE_MODAL}
            options={roleOptions.filter(
              (r) => r.value !== value.tempRole.value
            )}
            value={value.roles}
            onChange={(selected) => {
              setValue({ ...value, roles: selected });
              setError("roles", validateUserRoles(selected));
            }}
            errorMessage={errors["roles"]}
          />
          {!!showTemporaryRolesFF && (
            <TemporaryRolesModal
              value={value}
              setValue={setValue}
              roleOptions={roleOptions}
              timeValue={timeValue}
              setTimeValue={setTimeValue}
              user={user}
              setShowAddTemporaryRoleUi={setShowAddTemporaryRoleUi}
              showAddTemporaryRoleUi={showAddTemporaryRoleUi}
            />
          )}
          <FormError errMsg={errors["general"]} />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default UserFormModal;
