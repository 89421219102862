import Resource from "../../resources";
import { DescribeSection } from "../../tabs/DescribeTab/common/DescribeSection";
import { useResourceAiInvestigation } from "../common/useResourceAiInvestigation";
import { InvestigationTitle } from "../common/InvestigationTitle";
import { InvestigationContent } from "../common/InvestigationContent";

import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const InvestigationSection: React.FC<{
  className?: string;
  showBorder?: boolean;
  requestId: string;
  flowType: string;
  resource: Resource;
  isHealthy: boolean;
  titleProps?: {
    collapsible?: boolean;
    showIcon?: boolean;
    showTitleText?: boolean;
  };
}> = ({
  resource,
  isHealthy,
  requestId,
  titleProps,
  showBorder = true,
  className,
  flowType,
}) => {
  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    },
    requestId,
    resource,
    isHealthy,
    flowType,
  });

  if (!enabled || (isHealthy && !richSession)) {
    return null;
  }

  return (
    <DescribeSection
      className={className}
      collapsible={titleProps?.collapsible}
      disabled={richSession?.isComplete && richSession?.isNoProblemFound}
      title={
        <InvestigationTitle
          showIcon={titleProps?.showIcon}
          showTitleText={titleProps?.showTitleText}
          richSession={richSession}
          onShareClicked={onShareClicked}
        />
      }
      expandByDefault
    >
      <InvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        showBorder={showBorder}
      />
    </DescribeSection>
  );
};
