import { useGetDeletedPod } from "@/components/common/ProcessList/details/PodPhaseDetails/useGetDeletedPod";
import type DeletedPod from "@/components/ResourceView/resources/deletedPod";
import type Pod from "@/components/ResourceView/resources/pod";
import useResource from "@/components/ResourceView/useResource";
import { useActiveAgent } from "@/shared/hooks/useAgents";

export const useGetPodResource = ({
  fromEpoch,
  toEpoch,
  clusterName,
  namespace,
  podName,
  podStatus,
}: {
  fromEpoch?: string;
  toEpoch?: string;
  clusterName: string;
  namespace: string;
  podName: string;
  podStatus?: string;
}) => {
  const agentId = useActiveAgent(clusterName);

  const { resource: liveResource } = useResource({
    agentId: agentId ?? "",
    cluster: clusterName,
    namespace: namespace,
    resourceName: podName,
    resourceType: "Pod",
  });

  const deletedResource = useGetDeletedPod({
    clusterName,
    namespace,
    podName,
    fromEpoch,
    toEpoch,
    status: podStatus,
  });

  return (
    (liveResource as Pod | undefined) ||
    (deletedResource as DeletedPod | undefined)
  );
};
