import React from "react";

import { IconProps } from "../types";

export const WorkloadHealth16: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      d="M19.25 14C18.19 14 17.44 14.56 17 15.17C16.56 14.56 15.81 14 14.75 14C13.19 14 12 15.28 12 16.75C12 18.75 14.42 20.17 17 22.5C19.58 20.17 22 18.75 22 16.75C22 15.28 20.81 14 19.25 14ZM17 19.85C15.55 18.63 14 17.45 14 16.75C14 16.32 14.35 16 14.75 16C15.06 16 15.27 16.17 15.48 16.37L17 17.8L18.52 16.37C18.73 16.17 18.94 16 19.25 16C19.65 16 20 16.32 20 16.75C20 17.45 18.45 18.63 17 19.85Z"
      fill={svgProps.fill}
    />
    <path
      d="M2.5875 19.4125C2.97917 19.8042 3.45 20 4 20H11.2704C10.8106 19.4328 10.4009 18.7701 10.1778 18H4V8H20V12.0581C20.7446 12.1749 21.4225 12.463 22 12.8759V6C22 5.45 21.8042 4.97917 21.4125 4.5875C21.0208 4.19583 20.55 4 20 4H4C3.45 4 2.97917 4.19583 2.5875 4.5875C2.19583 4.97917 2 5.45 2 6V18C2 18.55 2.19583 19.0208 2.5875 19.4125Z"
      fill={svgProps.fill}
    />
    <path
      d="M22 16.75C22 15.8805 21.5836 15.0774 20.9206 14.5665C20.6508 14.3585 20.3401 14.1989 20 14.1029V16.75V18H19.1539C18.9064 18.2394 18.6187 18.4929 18.31 18.7555C18.2348 18.8195 18.1584 18.884 18.0809 18.949C17.9164 19.0871 17.7475 19.2273 17.577 19.3689C17.3855 19.5278 17.1918 19.6886 17 19.85C16.8082 19.6886 16.6146 19.5279 16.423 19.3689C16.2525 19.2273 16.0836 19.0871 15.9191 18.949C15.8416 18.884 15.7652 18.8195 15.6899 18.7555C15.3813 18.4929 15.0936 18.2393 14.8461 18H12.3265C12.6815 18.6761 13.3028 19.3171 14.074 20H19.926C21.1526 18.9139 22 17.9335 22 16.75Z"
      fill={svgProps.fill}
    />
  </svg>
);

WorkloadHealth16.defaultProps = {
  fill: "currentColor",
};
