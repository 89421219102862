import Box from "@mui/material/Box";
import { noop } from "lodash";
import React from "react";
import styled from "styled-components";

import { MetricsControls } from "../../../Metrics/MetricsControls";
import { MetricsFullTimeWindow } from "../../../Metrics/types";
import { Timeframe } from "../../../../shared/types/TimeWindow";
import { ONE_DAY_IN_MS } from "../../../../shared/constants/time";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-direction: column;
`;

export interface MetricsTimeSelectorProps {
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
  options?: Timeframe[];
}

export const MetricsControlsSelector: React.FC<MetricsTimeSelectorProps> = ({
  timeWindow,
  setTimeWindow,
  options,
}) => {
  const handleMetricsTimeframeChange = (tf: Timeframe | undefined) => {
    if (tf === Timeframe.Custom) {
      setTimeWindow({
        end: new Date(),
        start: new Date(new Date().getTime() - ONE_DAY_IN_MS),
        timeframe: tf,
      });
    } else if (tf) {
      setTimeWindow({
        end: undefined,
        start: undefined,
        timeframe: tf,
      });
    }
  };

  return (
    <Container>
      <Box>
        <MetricsControls
          defaultOptionsOverride={options}
          aggregationValue=""
          handleAggregationChange={noop}
          isThereDataBefore24hours={false}
          timeWindow={{
            start: timeWindow.start,
            end: timeWindow.end,
          }}
          metricsTimeframe={timeWindow.timeframe}
          onMetricsTimeframeChange={handleMetricsTimeframeChange}
        />
      </Box>
    </Container>
  );
};
