import { Divider } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

export const ContainerResourcesSpaced = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ContainerResources = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
`;
export const ResourceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 0.3rem;
  grid-column-gap: 3rem;
`;
export const ResourceGridTitle = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1 / 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.25rem;
`;
export const ResourceGridDivider = styled(Divider)`
  grid-column: 1 / span 2;
  grid-row: 2 / 4;
`;
