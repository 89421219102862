import { useCallback, useRef } from "react";
import { differenceWith, isEqual } from "lodash";

import { GenericFilter } from "@/generated/addonsApi";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";

type ShouldResetMostRecentFromDateParams = {
  relevantFilterNames: string[];
};

export const useHaveRequestFiltersChanged = () => {
  const filtersInUrl = useFilterListFromUrlAsGenericFilters();
  const lastRequestFilters = useRef<GenericFilter[]>(filtersInUrl);

  return useCallback(
    ({ relevantFilterNames }: ShouldResetMostRecentFromDateParams) => {
      const diff1 = differenceWith(
        lastRequestFilters.current,
        filtersInUrl,
        isEqual
      );
      const diff2 = differenceWith(
        filtersInUrl,
        lastRequestFilters.current,
        isEqual
      );

      const haveAnyFiltersChanged = !isEqual(
        lastRequestFilters.current,
        filtersInUrl
      );

      const haveRelevantFiltersChanged =
        diff1.some((filter) => relevantFilterNames.includes(filter.name)) ||
        diff2.some((filter) => relevantFilterNames.includes(filter.name));

      lastRequestFilters.current = filtersInUrl;

      return { haveRelevantFiltersChanged, haveAnyFiltersChanged };
    },
    [filtersInUrl]
  );
};
