import { AccountPlan } from "komodor-types";

import useUserMetadata from "../../../shared/hooks/useUserMetadata/useUserMetadata";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

export const useShouldEnableIntercom = () => {
  const { accountPlan } = useUserMetadata();
  const { forceShowZendeskChatbot, forceShowIntercom } = useOverridableFlags();

  return (
    !!forceShowIntercom ||
    (!forceShowZendeskChatbot &&
      (accountPlan == AccountPlan.trial || accountPlan == AccountPlan.free))
  );
};

export const useShouldShowChatSupport = () => {
  const { accountPlan } = useUserMetadata();
  const { forceShowZendeskChatbot, forceShowIntercom } = useOverridableFlags();

  return (
    !forceShowIntercom &&
    (!!forceShowZendeskChatbot ||
      (accountPlan != AccountPlan.trial && accountPlan != AccountPlan.free))
  );
};
