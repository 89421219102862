import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  width: 467px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const HorizontalContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const FullWidthContainerAndChildDiv = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    fontWeight: 600,
  },
}));

export const StyledLoadingButton = styled(
  ({ children, ...props }: LoadingButtonProps) => (
    <LoadingButton
      variant={"contained"}
      size={"large"}
      sx={{
        width: "150px",
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  )
)``;
