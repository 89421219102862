import React from "react";

import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

import { RecommendationsModalContextProvider } from "./context/RecommendationsModalContext";
import { RecommendationsModalProps } from "./types";
import { RecommendationsModalContent } from "./RecommendationsModalContent";

export const RecommendationsModal: React.FC<RecommendationsModalProps> = (
  props
) => {
  const { container: containerAriaLabel } =
    costOptimizationAriaLabels.rightSizingPage.recommendationModal;

  return (
    <div aria-label={containerAriaLabel}>
      <RecommendationsModalContextProvider>
        <RecommendationsModalContent {...props} />
      </RecommendationsModalContextProvider>
    </div>
  );
};
