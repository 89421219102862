import React, { ReactElement } from "react";

import { DrawerViolationSection } from "./OverviewContentComponents";
import { DrawerContentContainer } from "./styles";

interface Props {
  whatDescription: ReactElement;
  whyDescription: ReactElement;
  howDescription: ReactElement;
}

export const OverviewContent: React.FC<Props> = ({
  whatDescription,
  whyDescription,
  howDescription,
}) => {
  return (
    <DrawerContentContainer>
      <DrawerViolationSection type="what" content={whatDescription} />
      <DrawerViolationSection type="why" content={whyDescription} />
      <DrawerViolationSection type="how" content={howDescription} />
    </DrawerContentContainer>
  );
};
