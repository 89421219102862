import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1StoragePvcsEventsSearchGetUrl,
  EventsApiApiV1StoragePvcsEventsSearchGetRequest,
  PvcEventsResponse,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";

const GET_PVC_EVENTS_SEARCH = "/api/v1/storage/pvcs/events/search";

type UsePVCEventsSearchParams =
  EventsApiApiV1StoragePvcsEventsSearchGetRequest & {
    enabled?: boolean;
  };

const fetchPVCEvents = async (
  apiClient: AxiosInstance,
  params: UsePVCEventsSearchParams
): Promise<PvcEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1StoragePvcsEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const usePVCEvents = (params: UsePVCEventsSearchParams) => {
  const apiClient = useResourcesApiClient();

  return useQuery(
    [GET_PVC_EVENTS_SEARCH, params],
    () => fetchPVCEvents(apiClient, params),
    {
      enabled: params.enabled,
    }
  );
};
