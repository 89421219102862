import { compact, uniq } from "lodash";
import pluralize from "pluralize";

export const ChangesSummaryChangesNotAvailable = "Changes not available";
export const ChangesSummaryNoChanges = "No changes";

type ChangesDetails = {
  changes: string[];
  hiddenCount: number;
};

export const getChangesDetails = (
  changes: (string | null)[]
): ChangesDetails => {
  const relevant = compact(changes);
  const hiddenCount = changes.length - relevant.length;
  if (!relevant.length)
    return {
      changes: [],
      hiddenCount,
    };
  const knownChanges = uniq(
    relevant.map((name) => {
      const separatorIndex = name.indexOf(":");
      return separatorIndex === -1 ? name : name.substring(0, separatorIndex);
    })
  );
  return {
    changes: knownChanges,
    hiddenCount,
  };
};

export const getHiddenChangesText = (hiddenCount: number): string => {
  if (!hiddenCount) return "";
  return `${hiddenCount} ${pluralize("change", hiddenCount)}`;
};

const getChangesSummary = (changes?: (string | null)[]): string => {
  if (!changes) return ChangesSummaryChangesNotAvailable;
  if (!changes.length) return ChangesSummaryNoChanges;
  const changesDetails = getChangesDetails(changes);
  if (!changesDetails.changes.length)
    return getHiddenChangesText(changesDetails.hiddenCount);
  const knownChanges = changesDetails.changes;
  if (changesDetails.hiddenCount) {
    knownChanges.push(`+${changesDetails.hiddenCount}`);
  }
  return `Changes: ${knownChanges.join(", ")}`;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default getChangesSummary;
