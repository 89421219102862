import { parseISO } from "date-fns";

import {
  KubernetesKindsMatchingService,
  Selector,
  ServiceMetadata,
  ServiceReplicas,
} from "../types/ServiceVertex";
import { KomodorService } from "../hooks/useCurrentService";

import { extractJobsStatusandLastRunTime } from "./extractJobsStatusandLastRunTime";

export const mapPgServiceToServiceMetadata = (
  service: KomodorService
): ServiceMetadata => {
  const replicas = extractReplicasFromService(service);
  const { jobLastRunTime, jobState } = extractJobsStatusandLastRunTime(
    service.k8s_metadata?.status ?? ""
  );
  return {
    replicas,
    serviceType: (service.kind as KubernetesKindsMatchingService) || undefined,
    labels: service.k8s_metadata?.labels as Record<string, string>,
    annotations: service.k8s_metadata?.annotations as Record<string, string>,
    replicaSetName: service.k8s_metadata?.replicaSetName as string | undefined,
    k8sUid: service?.k8s_metadata?.k8sUid as string | undefined,
    selector: service.k8s_metadata?.selector as Selector | undefined,
    jobState,
    jobLastRunTime,
  };
};

export const extractReplicasFromService = (service: {
  deploy_state?: {
    readyReplicas?: number | null;
    desiredReplicas?: number | null;
  } | null;
}): ServiceReplicas | undefined => {
  if (
    service.deploy_state?.readyReplicas !== undefined &&
    service.deploy_state?.desiredReplicas !== undefined &&
    service.deploy_state?.readyReplicas !== null &&
    service.deploy_state?.desiredReplicas !== null
  ) {
    return {
      ready: service.deploy_state.readyReplicas,
      desired: service.deploy_state.desiredReplicas,
    };
  }
  return undefined;
};

export const extractLastModifyFromService = (service: {
  updatedAt: string;
  deploy_state?: {
    lastDeployEndTime?: string | null;
    lastDeployStartTime?: string | null;
    eventTime: string;
    createdAt: string;
  } | null;
}): Date => {
  if (!service.deploy_state) {
    return parseISO(service.updatedAt);
  }
  const { lastDeployEndTime, lastDeployStartTime, eventTime, createdAt } =
    service.deploy_state;
  if (lastDeployStartTime) {
    if (lastDeployEndTime) {
      return lastDeployStartTime > lastDeployEndTime
        ? parseISO(lastDeployStartTime)
        : parseISO(lastDeployEndTime);
    }
    return parseISO(lastDeployStartTime);
  }
  return eventTime > createdAt ? parseISO(createdAt) : parseISO(eventTime);
};
