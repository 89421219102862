import { useQuery } from "@tanstack/react-query";

import {
  getWorkloadCpuMetricsUrl,
  getWorkloadMemoryMetricsUrl,
  MetricsApiGetWorkloadCpuMetricsRequest,
  MetricsApiGetWorkloadMemoryMetricsRequest,
  WorkloadMetricsDataPoints,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_METRICS_WORKLOAD_MEMORY_PATH = "/workload/memory";
export const GET_METRICS_WORKLOAD_CPU_PATH = "/workload/cpu";

type GetWorkloadMetrics = {
  type: "cpu" | "memory";
  params:
    | MetricsApiGetWorkloadMemoryMetricsRequest
    | MetricsApiGetWorkloadCpuMetricsRequest;
  enabled?: boolean;
};

export const useGetWorkloadMetrics = ({
  type,
  params,
  enabled,
}: GetWorkloadMetrics) => {
  const apiClient = useMetricsApiClient();
  const url =
    type === "cpu"
      ? getWorkloadCpuMetricsUrl(params, apiClient.defaults.baseURL ?? "")
      : getWorkloadMemoryMetricsUrl(params, apiClient.defaults.baseURL ?? "");

  const queryKey =
    type === "cpu"
      ? GET_METRICS_WORKLOAD_CPU_PATH
      : GET_METRICS_WORKLOAD_MEMORY_PATH;

  return useQuery(
    [queryKey, params],
    async () => await apiClient.get<WorkloadMetricsDataPoints>(url),
    { enabled: enabled }
  );
};
