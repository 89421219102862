import { AppInsightsResponse, TimeWindow } from "../types/overviewPageTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { OverviewPageStore } from "./overviewPageStore";

export const initialResponse: AppInsightsResponse = {
  loading: true,
  error: null,
  refresh: () => undefined,
  data: undefined,
  dataRequestParams: "",
};

const timeWindow: TimeWindow = {
  fromEpoch: 0,
  toEpoch: 0,
};

export const initialState: OverviewPageStore = {
  eventItemDrawer: {
    currentEventItem: undefined,
    eventItemDrawerOpen: false,
    closeEventItemDrawer: () => undefined,
    openEventItemDrawer: () => undefined,
  },
  insightsDrawer: {
    insightsDrawerOpen: false,
    insightsDrawerContent: undefined,
    openInsightsDrawer: () => undefined,
    closeInsightsDrawer: () => undefined,
    setInsightsDrawerContent: () => undefined,
  },
  cost: {
    rightSizingSummaryState: {
      ...initialResponse,
    },
    rightSizingRecommendationsModalState: {},
    rightSizingRecommendationsStrategiesState: {},
    rightSizingRecommendationsState: { ...initialResponse },
    rightSizingFilters: {
      strategy: "moderate",
    },
    setRightSizingSummaryState: () => undefined,
    setRightSizingRecommendationModal: () => undefined,
    setRightSizingRecommendationsState: () => undefined,
    setRightSizingFilters: () => undefined,
    setRightSizingRecommendationsStrategiesState: () => undefined,
  },
  serviceResourceDrawer: {
    resourceDrawerOpen: false,
    resourceDrawerServiceId: undefined,
    closeServiceResourceDrawer: () => undefined,
    openServiceResourceDrawer: () => undefined,
  },
  timeWindowIntervals: [timeWindow, timeWindow],
  setTimeWindowIntervals: () => undefined,
  appInsightsData: {
    bestPracticesLivenessProbesMissing: { ...initialResponse },
    securityBestPractices: { ...initialResponse },
    bestPracticesMissingRequestsOrLimits: { ...initialResponse },
    availabilityIssues: { ...initialResponse },
    failedDeployments: { ...initialResponse },
    throttledApplications: { ...initialResponse },
    potentialCostSavings: { ...initialResponse },
  },
  setAppInsightsData: () => undefined,
  overviewMetricsData: {
    costOptimizationTrends: { ...initialResponse },
  },
  setOverviewMetricsData: () => undefined,
  uiElementsRect: {
    insightsContainer: undefined,
    overviewContainer: undefined,
    pageContainer: undefined,
  },
  setUiElementsRect: () => undefined,
  pageTransition: 0,
  setPageTransition: () => undefined,
  metricsSupportAgentInfo: {
    unsupportedClusters: [],
    supportedClusters: [],
    clustersWithSufficientTime: [],
    clustersWithInsufficientTime: [],
    hasSufficientDataCollectionTime: false,
    hasMinimalDataAccuracy: false,
    hasFetchedDataAccuracy: false,
  },
  setMetricsSupportAgentInfo: () => undefined,
};
