import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";
import {
  MonitorsApiApiV1TopAvailabilityIssuesGetRequest,
  MonitorsResponse,
  apiV1TopAvailabilityIssuesGetUrl,
} from "../../../../../generated/monitorsApi";

export const TOP_AVAILABILITY_ISSUES_PATH = "/top-availability-issues";

const fetchTopAvailabilityIssues = async (
  apiClient: AxiosInstance,
  params: MonitorsApiApiV1TopAvailabilityIssuesGetRequest
): Promise<MonitorsResponse> => {
  const { data } = await apiClient.get(
    apiV1TopAvailabilityIssuesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetTopAvailabilityIssues = (
  params: MonitorsApiApiV1TopAvailabilityIssuesGetRequest,
  options?: { enabled?: boolean; staleTime?: number }
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [TOP_AVAILABILITY_ISSUES_PATH, params],
    () => fetchTopAvailabilityIssues(apiClient, params),
    options
  );
};
