import React from "react";

import { UpgradeAgentVersionAlert } from "../../../../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { updateNetworkMapperWarningText } from "../../../../common/UpgradeAgentVersionAlert/texts";

import { UpgradeButtonAndModal } from "./UpgradeButtonAndModal";

export const UpdateNetworkMapperAlert: React.FC = () => (
  <UpgradeAgentVersionAlert
    upgradeButton={<UpgradeButtonAndModal />}
    text={updateNetworkMapperWarningText}
    severity={"warning"}
  />
);
