import { createContext, useContext } from "react";

interface CRDsDataContext {
  agentId: string;
  cluster: string;
}

const defaultValue = {
  agentId: "",
  cluster: "",
};

export const crdsDataContext =
  createContext<Required<CRDsDataContext>>(defaultValue);

export const useCRDsData = (): CRDsDataContext => useContext(crdsDataContext);
