import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EventsApiApiV1NodesEventsSearchGetRequest,
  NodeEventsResponse,
  apiV1NodesEventsSearchGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";
import { NODES_EVENTS_SEARCH } from "../../requestResponseMaps";

const fetchNodeEventsSearch = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1NodesEventsSearchGetRequest
): Promise<NodeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1NodesEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetNodeEventsSearch = (
  params: EventsApiApiV1NodesEventsSearchGetRequest,
  options: { enabled?: boolean } = { enabled: true }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [NODES_EVENTS_SEARCH, params],
    () => fetchNodeEventsSearch(apiClient, params),
    options
  );
};
