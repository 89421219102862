import { STS } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

export const KubernetesStatefulSetResource = {
  NameInK8S: "statefulsets",
  Group: "workloads",
  NavBarName: "StatefulSet",
  PresentName: "Stateful Set",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: [],
  SupportedActions: [
    AvailableActions.Edit,
    AvailableActions.Scale,
    AvailableActions.Restart,
    AvailableActions.Delete,
  ],
  Headers: [
    { name: "Name" },
    { name: "Health" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Ready" },
    { name: "Images" },
    { name: "Containers" },
  ],
  DefaultSort: {
    ColumnName: "Health",
    Direction: Direction.down,
  },
  Kind: "StatefulSet",
  Icon: STS,
  ShouldBackgroundTrigger: true,
  hasLabels: true,
};
