import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  INITIAL_SCROLL_SIZE,
  MAX_POPOVER_HEIGHT,
} from "./PopoverMenuListHandler";

// includes the search bar and the menu items padding
const MENU_ITEMS_PADDING = 80;

// arbitrary number to decide if we should use scroll or not, should be calculated dynamically
const MIN_ITEMS_NUM_FOR_SCROLL = 20;

type InfiniteScrollContainerProps = {
  children: React.ReactNode;
  allOptionsNum: number;
  currentOptionsNum: number;
  scrollSize: number;
  setScrollSize: (scrollSize: number) => void;
};

export const InfiniteScrollContainer: React.FC<
  InfiniteScrollContainerProps
> = ({
  children,
  allOptionsNum,
  currentOptionsNum,
  scrollSize,
  setScrollSize,
}) => {
  const loadMore = () => {
    setScrollSize(Math.min(scrollSize + INITIAL_SCROLL_SIZE, allOptionsNum));
  };

  const scrollHeight = useMemo(() => {
    return currentOptionsNum > MIN_ITEMS_NUM_FOR_SCROLL
      ? MAX_POPOVER_HEIGHT - MENU_ITEMS_PADDING
      : "min-content";
  }, [currentOptionsNum]);

  return (
    <InfiniteScroll
      dataLength={scrollSize}
      next={loadMore}
      hasMore={scrollSize < allOptionsNum}
      height={scrollHeight}
      loader={undefined}
    >
      {children}
    </InfiniteScroll>
  );
};
