import React from "react";

import { SettingsSectionLayout } from "../../SettingsSectionLayout";

import { KlaudiaAi } from "./KlaudiaAi/KlaudiaAi";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { RbacClusterSync } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterSync";
import { HorizontalContainer } from "@/components/Settings/General/BetaFeatures/componentsStyles";

export const Features: React.FC = () => {
  const { canManageFeatures } = useHasPermissions();
  const { klaudiaAi } = useOverridableFlags();

  const features = [];

  if (!canManageFeatures) {
    return null;
  }

  if (klaudiaAi) {
    features.push(<KlaudiaAi />);
  }
  features.push(<RbacClusterSync />);

  if (features.length === 0) {
    return null;
  }

  return (
    <SettingsSectionLayout title={"Features"}>
      <HorizontalContainer>{features}</HorizontalContainer>
    </SettingsSectionLayout>
  );
};
