export const getCardTitleDescription = (
  numberOfThrottledApplications: number | undefined
) => {
  if (!numberOfThrottledApplications) return "";
  return `CPU throttling detected in ${numberOfThrottledApplications} container${
    numberOfThrottledApplications === 1 ? "" : "s"
  }`;
};

export const explanationTitle1 = "What it means";

export const explanationBlock1 = `When containers reach their set CPU limits, Kubernetes will throttle them to ensure that they do not use more CPU than was assigned to them.`;

export const explanationBlock2 = `In some cases, this can lead to slow response times and other issues.`;

export const explanationTitle2 = "What happened";

export const getTableDescription = (percentage: number) =>
  `The following containers spend ${percentage}% of their time or more being throttled.`;
