import { useMemo } from "react";

import { ScalingMetric, ValueType } from "../hpaReachedMaxTypes";
import {
  extractQuantity,
  getQuantityInBytes,
} from "../../../../../../../../../../shared/utils/formatQuantity";

type AverageUtilizationValuePerMetricOutput = {
  value: number;
  unit: ValueType;
};

export const useAverageUtilizationValuePerMetric = (
  metric: ScalingMetric
): AverageUtilizationValuePerMetricOutput => {
  return useMemo(() => {
    if (metric.metricType === "percentage") {
      return { value: metric.value as number, unit: "percentage" };
    }

    const { unit, value } = extractQuantity((metric.value as string) ?? "");
    const valueInBytes = getQuantityInBytes({ value, unit });
    return { value: valueInBytes, unit: "bytes" };
  }, [metric.metricType, metric.value]);
};
