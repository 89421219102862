import React, { useMemo } from "react";
import styled from "styled-components";

import { PaddedCard } from "../../styles/CostOptimizationStyledComponents";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectRightSizingSummaryState } from "../../../store/costOptimizationStoreSelectors";
import { LinesLoader } from "../../../../common/loaders/Line";
import { DataGridTable } from "../../shared/tableComponents/DataGrid";
import { FetchError } from "../../shared/errors/FetchError";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { useCsvData } from "../../../../../shared/components/CsvExport/useCsvData";
import { CostRightSizingSummary } from "../../../../../generated/metricsApi";
import { getTodayDate } from "../../../../../shared/utils/dateUtils";

import { useRightSizingTableColumns } from "./rightSizingTableHooks";
import { getRightSizingCsvHeaders, getValue, sortRows } from "./csv";

const ContentContainer = styled.div`
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  & > div {
    width: 100%;
    min-width: 800px;
  }
`;

export const RightSizingTable: React.FC = () => {
  const { loading, data, error } = useCostOptimizationStore(
    selectRightSizingSummaryState
  );

  const columns = useRightSizingTableColumns();

  const rowsWithPotentialSavings = useMemo(
    () => data?.rows?.filter((row) => row.potentialSaving > 0),
    [data?.rows]
  );

  const csvRows = useMemo(
    () => sortRows(rowsWithPotentialSavings),
    [rowsWithPotentialSavings]
  );

  const csvData = useCsvData<CostRightSizingSummary>({
    getHeaders: getRightSizingCsvHeaders,
    getValue,
    rows: csvRows,
  });

  const content = loading ? (
    <LinesLoader marginTop={"0"} />
  ) : error ? (
    <FetchError />
  ) : (
    <DataGridTable
      rows={rowsWithPotentialSavings ?? []}
      columns={columns}
      csvProps={{
        data: csvData,
        fileName: `right-sizing-${getTodayDate()}`,
        ariaLabel:
          costOptimizationAriaLabels.rightSizingPage.table.exportButton,
      }}
    />
  );

  return (
    <PaddedCard
      aria-label={costOptimizationAriaLabels.rightSizingPage.table.table}
    >
      <ContentContainer>{content}</ContentContainer>
    </PaddedCard>
  );
};
