import { ContainerStatus } from "kubernetes-types/core/v1.d";

import { ContainerOption, ContainerType } from "../types";

export const mapContainerToOption = (
  containerType: ContainerType,
  container: ContainerStatus
): ContainerOption => ({
  label:
    containerType === ContainerType.Regular
      ? `<${container.name}>`
      : `<init_>${container.name}>`,
  value: container.name,
  containerType,
  state: container.state,
  disabled: container.state?.running === undefined,
});
