import { invert } from "lodash";

import { ResourceStatusEnum } from "../../components/ResourceView/headers/common";

export const jobType = "Job run";

export enum JobState {
  completed = "Completed",
  suspended = "Suspended",
  resumed = "Resumed",
  inprogress = "Running",
  failed = "Failed",
  started = "Started",
  deleted = "Deleted",
  noRuns = "No runs yet",
  unknown = "Unknown",
}

export interface JobCondition {
  message: string;
  reason: string;
  status: "True" | "False" | "Unknown";
  type: "Complete" | "Failed" | "Suspended";
  lastProbeTime: string;
  lastTransitionTime: string;
}

export interface JobStatusInterface {
  active: number; // The number of actively running pods
  failed: number; // The number of pods which reached phase Failed
  succeeded: number; // The number of pods which reached phase Succeeded.
  startTime: string;
  conditions: JobCondition[] | undefined;
}

export const convertToJobState = (string?: string | null): JobState => {
  if (!string) return JobState.unknown;
  const inverted = invert(JobState);
  if (Object.keys(inverted).includes(string)) {
    return JobState[inverted[string] as keyof typeof JobState];
  }
  return JobState.unknown;
};

export const convertToResourceStatusEnum = (
  jobState: string
): ResourceStatusEnum => {
  switch (jobState) {
    case JobState.completed:
    case JobState.resumed:
    case JobState.inprogress:
    case JobState.started:
      return ResourceStatusEnum.Healthy;

    case JobState.suspended:
    case JobState.deleted:
    case JobState.noRuns:
    case JobState.unknown:
      return ResourceStatusEnum.Unknown;

    case JobState.failed:
      return ResourceStatusEnum.Failed;

    default:
      return ResourceStatusEnum.Unknown;
  }
};
