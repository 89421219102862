import React from "react";
import { ActionItem, Button } from "@komodorio/design-system/deprecated";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import Resource from "../../ResourceView/resources";
import Node from "../../ResourceView/resources/node";

import {
  actionsMapping,
  ButtonStylesProps,
  cordonUncordonStyles,
} from "./actionsStyleMap";

export const getButton = (
  type: AvailableActions,
  forceDisable = false,
  resource?: Resource,
  overrideStyles?: ButtonStylesProps
): React.FC<{ onClick: () => void }> => {
  const ButtonStyle: React.FC<{
    onClick: () => void;
    disabled?: boolean;
    ariaLabel?: string;
  }> = ({ onClick, disabled = false, ariaLabel }) => {
    const isNodeUnschedulable =
      resource instanceof Node ? resource?.unschedulable : undefined;

    const styles = overrideStyles
      ? overrideStyles
      : type === AvailableActions.CordonUncordon
      ? cordonUncordonStyles(isNodeUnschedulable ?? false)
      : actionsMapping[type].style;

    return (
      <Button
        variant={styles.buttonVariant}
        icon={styles.icon}
        onClick={onClick}
        disabled={forceDisable || disabled || styles.disabled}
        aria-label={ariaLabel}
      >
        {styles.text}
      </Button>
    );
  };

  return ButtonStyle;
};

export const getActionItem = (
  type: AvailableActions,
  forceDisable = false,
  resource?: Resource
): React.FC<{ onClick: () => void }> => {
  const ButtonStyle: React.FC<{ onClick: () => void; disabled?: boolean }> = ({
    onClick,
    disabled = false,
  }) => {
    const isNodeUnschedulable =
      resource instanceof Node ? resource?.unschedulable : undefined;

    const styles =
      type === AvailableActions.CordonUncordon
        ? cordonUncordonStyles(isNodeUnschedulable ?? false)
        : actionsMapping[type].style;

    return (
      <ActionItem
        icon={styles.icon}
        onClick={onClick}
        variant={styles.actionItemVariant}
        disabled={forceDisable || disabled || styles.disabled}
      >
        {styles.text}
      </ActionItem>
    );
  };

  return ButtonStyle;
};
