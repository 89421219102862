import { css } from "styled-components";

export const ButtonLinkStyle = css`
  display: flex;
  padding-left: 1.5rem;
  gap: 1rem;
  align-items: center;
  height: 2.5rem;
  cursor: pointer;
`;
