import { Button } from "@komodorio/design-system/deprecated";
import { Plus16 } from "@komodorio/design-system/icons";
import { remove } from "lodash";
import React, { useRef } from "react";
import styled from "styled-components";

import { useFormValidations } from "../../../../shared/context/ValidationsProvider";

import { RbacActionRule, defaultRules } from "./types";
import { Rule } from "./Rule";

const Rules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;

const CenteredButton = styled(Button)`
  align-self: center;
`;

interface VisualEditorProps {
  value: RbacActionRule[];
  onChange: (v: RbacActionRule[]) => Promise<void>;
  readOnly?: boolean;
}

export const VisualEditor: React.FC<VisualEditorProps> = ({
  value,
  onChange,
  readOnly,
}) => {
  const bottomRef = useRef<HTMLSpanElement>(null);
  const { deleteError } = useFormValidations();

  const handleRuleChange = (s: RbacActionRule, index: number) => {
    onChange([...value].map((v, i) => (i === index ? s : v)));
  };
  const handleAddRule = async (s: RbacActionRule) => {
    await onChange([...value, s]);
    bottomRef.current?.scrollIntoView();
  };
  const handleDeleteRule = (index: number) => {
    onChange(remove(value, (_, i) => i !== index));
    deleteError((k) => k.startsWith(`instance[${index}]`));
  };

  return (
    <Rules>
      {value.map((r, i) => (
        <Rule
          key={i}
          index={i}
          rule={r}
          onChange={handleRuleChange}
          onDuplicate={handleAddRule}
          onDelete={value.length !== 1 ? handleDeleteRule : undefined}
          readOnly={readOnly}
        />
      ))}
      <CenteredButton
        variant="secondary"
        size="small"
        icon={Plus16}
        onClick={() => handleAddRule(defaultRules[0])}
        disabled={readOnly}
      >
        Add rule
      </CenteredButton>
      <span ref={bottomRef} />
    </Rules>
  );
};
