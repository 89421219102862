import { useMemo } from "react";

import { getBarChartData } from "@/pages/organization-settings/account/UsagePage/StackedBarChart/utils";
import { NodeCount } from "@/generated/accounts";
import { NUM_CLUSTERS_TO_DISPLAY } from "@/pages/organization-settings/account/UsagePage/StackedBarChart/constants";

export const useChartData = ({
  data,
  clusters,
  sortedMonths,
}: {
  data?: NodeCount[];
  clusters: string[];
  sortedMonths: string[];
}) => {
  const summarizedData = useMemo((): { [key: string]: number } | undefined => {
    if (!data?.length) {
      return undefined;
    }

    return data.reduce((acc: { [key: string]: number }, cur: NodeCount) => {
      const cluster = cur.clusterName;
      const nodeCount = cur.nodeCount;

      // Handle edge cases
      if (!cluster || !nodeCount) {
        return acc;
      }

      if (!acc[cluster]) {
        acc[cluster] = 0;
      }

      acc[cluster] += nodeCount;

      return acc;
    }, {});
  }, [data]);

  const { topClusters, otherClusters, keys } = useMemo(() => {
    if (!summarizedData) {
      return { topClusters: [], otherClusters: [], keys: [] };
    }

    if (clusters.length <= NUM_CLUSTERS_TO_DISPLAY) {
      return { topClusters: clusters, otherClusters: [], keys: clusters };
    }

    // TODO Lior: sort should be done in the backend
    // https://app.clickup.com/t/86c0uuc0y
    const sortedClusters = Object.entries(summarizedData)
      .filter(([key, value]) => {
        return clusters.includes(key);
      })
      .sort(([, firstValue], [, secondValue]) => {
        return secondValue - firstValue;
      });

    const topClusters = sortedClusters
      .slice(0, NUM_CLUSTERS_TO_DISPLAY)
      .map(([key]) => key);

    return {
      topClusters,
      otherClusters: sortedClusters
        .slice(NUM_CLUSTERS_TO_DISPLAY)
        .map(([key]) => key),
      keys: [...topClusters, "Others"],
    };
  }, [summarizedData, clusters]);

  const { data: chartData } = useMemo(
    () => getBarChartData(data, sortedMonths, topClusters, otherClusters),
    [data, topClusters, otherClusters, sortedMonths]
  );

  return { topClusters, otherClusters, chartData, chartKeys: keys };
};
