import React from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material/Chip/Chip";

import { Severity } from "../../../ReliabilityTypes";
import { SeverityToColorScheme } from "../../../constants/reliabilityConstants";
import { reliabilityArialLabels } from "../../../reliabilityArialLabels";
import { formatNumber } from "../../../../../shared/utils/formatNumber";

import { formatSeverity } from "./formatSeverity";

const StyledChip = styled(Chip)<{ severity: Severity }>`
  && {
    background-color: ${({ severity }) =>
      SeverityToColorScheme[severity]?.backgroundColor};
    color: ${({ severity }) => SeverityToColorScheme[severity]?.color};
    border-color: ${({ severity }) =>
      SeverityToColorScheme[severity]?.borderColor};
    height: 20px;
    > span {
      font-weight: 500;
    }
  }
`;

const {
  severities: { chip: severityChipAriaLabel },
} = reliabilityArialLabels;

type SeverityChipProps = ChipProps & {
  severity: Severity;
  count?: number;
  label?: string;
};

export const SeverityChip: React.FC<SeverityChipProps> = ({
  severity,
  count,
  label,
  ...rest
}) => {
  const labelText = `${
    count !== undefined ? `${formatNumber(count)} ` : ""
  }${formatSeverity(severity)}`;

  return (
    <StyledChip
      severity={severity}
      size={"small"}
      variant={"outlined"}
      label={labelText}
      aria-label={severityChipAriaLabel}
      {...rest}
    />
  );
};
