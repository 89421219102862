import { useMemo } from "react";

import {
  UseDeployEventsSearchParams,
  useSearchDeployEvents,
} from "../../../../../../../shared/hooks/resources-api/client/events/useSearchDeployEvents";

import { useGetEpochsFromViolation } from "./useGetEpochsFromViolation";

export const useGetDeployEventsByViolationEpochs = (komodorUid: string) => {
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation("milliseconds");

  const requestParams = useMemo<UseDeployEventsSearchParams>(
    () => ({
      fromEpoch: fromEpoch.toString(),
      toEpoch: toEpoch.toString(),
      filterOutAutoDeploys: true,
      fields: [
        "eventTime",
        "endEventTime",
        "status",
        "deploymentDiffKeys",
        "oldSpec",
        "newSpec",
        "generation",
        "id",
        "versionFrom",
        "versionTo",
        "deploymentName",
      ],
      order: "ASC",
      komodorUids: [komodorUid],
      enabled: !!komodorUid,
    }),
    [fromEpoch, komodorUid, toEpoch]
  );
  return useSearchDeployEvents(requestParams);
};
