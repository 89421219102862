import React, { useMemo } from "react";

import { EnlargedMetricsGraph } from "../../../../../Metrics/graphs/EnlargedMetricsGraph";
import {
  DefaultTimeframeOptions,
  MetricsAggregationType,
  MetricType,
} from "../../../../../Metrics/types";
import { MetricsPreferences } from "../../../../../Metrics/MetricsPreferences/MetricsPreferences";

import { useClusterMetrics } from "./useClusterMetrics";
import { ClusterGraph } from "./ClusterGraph";
import { useTransformData } from "./utils";
import { useClusterMetricsWithZoom } from "./useClusterMetricsWithZoom";
import { useClusterMetricsContext } from "./ClusterMetricsContext";

interface EnlargedClusterGraphProps {
  title: string;
  clusterName: string;
  type: MetricType;
  onClose: () => void;
  aggregationType: MetricsAggregationType;
}

export const EnlargedClusterGraph = ({
  clusterName,
  title,
  type,
  onClose,
}: EnlargedClusterGraphProps) => {
  const { aggregationType, setAggregationType, timeWindow, setTimeWindow } =
    useClusterMetricsContext();

  const { fromEpoch, toEpoch, graphProps } = useClusterMetricsWithZoom();
  const requestParams = useMemo(() => {
    return {
      clusterName,
      fromEpoch,
      toEpoch,
    };
  }, [clusterName, fromEpoch, toEpoch]);

  const { hasData, loading, error, data } = useClusterMetrics(requestParams);

  const transformedData = useTransformData(data);

  return (
    <EnlargedMetricsGraph
      title={title}
      controls={[
        <MetricsPreferences
          aggregationSelectorAriaLabel={"aggregationToggle"}
          aggregationMetric={aggregationType}
          setAggregationMetric={setAggregationType}
          timeOptions={DefaultTimeframeOptions}
          timeWindow={timeWindow}
          setTimeWindow={setTimeWindow}
        />,
      ]}
      onClose={onClose}
      hasGraphData={hasData}
      loading={loading}
      error={error}
      uid={undefined}
    >
      <ClusterGraph
        type={type}
        timelineTicksNum={10}
        data={transformedData[type][aggregationType] ?? []}
        title={title}
        hasData={hasData}
        {...graphProps}
        graphHeight={"460px"}
      />
    </EnlargedMetricsGraph>
  );
};
