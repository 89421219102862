import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { DISABLED_ITEM_OPACITY } from "../shared/constants";
import { freeSoloItemPrompt } from "../AdvancedMultiSelect/advancedMultiSelectConstants";
import { Checkbox } from "@mui/material";

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 4px 0;
    overflow: hidden;
    & > p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const DisabledMenuItem = styled(StyledMenuItem)`
  && {
    cursor: default;
    opacity: ${DISABLED_ITEM_OPACITY};
    width: fit-content;
    &:hover {
      background-color: unset;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)<{ $presentAsPlaceholder?: boolean }>`
  && {
    padding: 4px;
    visibility: ${({ $presentAsPlaceholder }) =>
      $presentAsPlaceholder ? "hidden" : "visible"};
  }
`;

interface SelectItemProps<T extends MuiSelectionOptionValue> {
  option: MuiSelectionOption<T>;
  onSelect?: (value: T) => void;
  selectedOptions?: MuiSelectionOption<T>[];
  customOptionElement?: (
    option: MuiSelectionOption<T>,
    isChecked: boolean
  ) => React.ReactNode;
}

export const MultiSelectItem = <T extends MuiSelectionOptionValue>({
  option,
  onSelect,
  selectedOptions,
  customOptionElement,
}: SelectItemProps<T>) => {
  const isFreeSoloOption = option.label.includes(freeSoloItemPrompt);
  const Item = option.disabled ? DisabledMenuItem : StyledMenuItem;
  const isChecked = !!selectedOptions?.find(
    (selectedOption) => selectedOption.value === option.value
  );

  return (
    <Tooltip
      title={option.disabledTooltipContent}
      placement={"top"}
      key={option.value}
    >
      <Item
        key={option.value}
        value={option.value}
        aria-label={option.label}
        {...(!option.disabled && {
          onClick: () => onSelect(option.value),
        })}
      >
        {customOptionElement ? (
          customOptionElement(option, isChecked)
        ) : (
          <>
            <StyledCheckbox
              checked={isChecked}
              $presentAsPlaceholder={isFreeSoloOption && !option.disabled}
            />
            <Typography variant={"body3"}>{option.label}</Typography>
          </>
        )}
      </Item>
    </Tooltip>
  );
};
