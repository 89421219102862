import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMetricsApiClient } from "../apiClient";
import {
  CostAllocationSummaryResponse,
  CostApiApiV1CostAllocationSummaryGetRequest,
  apiV1CostAllocationSummaryGetUrl,
} from "../../../../../generated/metricsApi";

import { useWorkspaceQueryKey } from "@/shared/hooks/workspaces-api/useWorkspaceQueryKey";

export const COST_ALLOCATION_SUMMARY_PATH = "/cost/allocation/summary";

const fetchAllocationSummary = async (
  apiClient: AxiosInstance,
  params: CostApiApiV1CostAllocationSummaryGetRequest
): Promise<CostAllocationSummaryResponse> => {
  const { data } = await apiClient.get(
    apiV1CostAllocationSummaryGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetAllocationSummary = (
  params: CostApiApiV1CostAllocationSummaryGetRequest,
  options?: { enabled?: boolean; keepPreviousData?: boolean }
) => {
  const apiClient = useMetricsApiClient();
  const keys = useWorkspaceQueryKey([COST_ALLOCATION_SUMMARY_PATH, params]);
  return useQuery(
    keys,
    () => fetchAllocationSummary(apiClient, params),
    options
  );
};
