import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { muiColors } from "@komodorio/design-system";

import { impactGroupIdentifierToText } from "../../constants/impactGroupIdentifierToText";
import { ImpactGroupIdentifier } from "../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../reliabilityArialLabels";

import { IReliabilityByScope } from "./violationsDrawerByScopeTypes";
import { ScopedSeverities } from "./ScopedSeverities";

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 6px;
  }
`;

const CloseIcon = styled(CloseOutlined)`
  && {
    font-size: 20px;
    color: ${muiColors.gray[500]};
  }
`;

type ViolationsDrawerByScopeHeaderProps = IReliabilityByScope & {
  onClose: () => void;
};

export const ViolationsDrawerByScopeHeader: React.FC<
  ViolationsDrawerByScopeHeaderProps
> = ({ scope, additionalConfig, onClose }) => {
  const title = useMemo(() => {
    if (scope.impactGroupId?.length) {
      const impactGroupIdentifier = scope
        .impactGroupId[0] as ImpactGroupIdentifier;
      return impactGroupIdentifierToText[impactGroupIdentifier].title;
    }
    return "Open reliability violations";
  }, [scope.impactGroupId]);

  const showSeverities = additionalConfig?.showGroupSeverities;

  return (
    <Container>
      <StyledIconButton size="small" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <Typography
        variant={"h3"}
        aria-label={reliabilityArialLabels.scopedViolationsDrawer.title}
      >
        {title}
      </Typography>
      {showSeverities && <ScopedSeverities />}
    </Container>
  );
};
