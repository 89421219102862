import React from "react";
import { get } from "lodash";
import { ClusterRoleBinding as ClusterRoleBindingType } from "kubernetes-types/rbac/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";

import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ClusterRoleBindingDetailsTab } from "../tabs/DescribeTab/ClusterRoleBinding";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ClusterRoleBinding implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Describe];
  readonly fullObj;
  readonly reference: ClusterRoleBindingType["roleRef"];
  readonly subjects: ClusterRoleBindingType["subjects"];
  readonly defaultTab;

  constructor(
    cluster: string,
    clusterRoleBinding: ClusterRoleBindingType,
    agentId: string
  ) {
    this.agentId = agentId;
    this.id = clusterRoleBinding.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = clusterRoleBinding.metadata?.namespace ?? "";
    this.name = clusterRoleBinding.metadata?.name ?? "";
    this.kind = clusterRoleBinding.kind ?? "";
    this.labels = clusterRoleBinding.metadata?.labels ?? {};
    this.annotations = clusterRoleBinding.metadata?.annotations ?? {};
    this.fullObj = clusterRoleBinding;
    this.reference = clusterRoleBinding.roleRef;
    this.subjects = clusterRoleBinding.subjects;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ClusterRoleBindingDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        displayName={get(this, "fullObj.spec.names.kind")}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
