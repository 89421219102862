import useClipboard from "react-use-clipboard";
import { palette } from "@komodorio/design-system";
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

const StyledModalContent = styled(ModalContent)`
  padding-bottom: 0;
`;
const InviteDescription = styled(Typography)`
  margin-bottom: 2rem;
`;
const BoldText = styled(Typography)`
  font-weight: 600;
`;
const StyledModalActions = styled(ModalActions)`
  padding-top: 0.5rem;
  padding-bottom: 1.2rem;
`;

const CopiedToast = styled.div`
  position: absolute;
  background: ${palette.darkBlue[900]};
  top: 7rem;
  right: 0.2rem;
  padding: 0.4rem;
  border-radius: 0.2rem;
`;

const shareLink = "https://hubs.li/Q01JF8pD0";

export const ShareDesktopModal: React.FC<{
  closeModal: () => void;
  isOpen: boolean;
}> = ({ isOpen, closeModal }) => {
  const [copied, copy] = useClipboard(shareLink, { successDuration: 3000 });

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} width="30rem">
      <ModalHeader>
        <Typography variant="headline">Share Komodor’s Desktop App</Typography>
      </ModalHeader>
      <StyledModalContent>
        <InviteDescription size="medium">
          Invite Kubernetes users to download the Komodor Desktop app
        </InviteDescription>
        <BoldText size="small">Invite with link </BoldText>
      </StyledModalContent>
      <StyledModalActions align="center">
        <Input disabled value={shareLink} />
        {copied && (
          <CopiedToast>
            <Typography color={palette.white[0]}>
              Link copied to clipboard!
            </Typography>
          </CopiedToast>
        )}
        <Button onClick={copy} size="small" variant="primary">
          Copy link
        </Button>
      </StyledModalActions>
    </Modal>
  );
};
