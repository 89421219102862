import { useState, useEffect } from "react";

import { MachineClusters } from "../../../shared/context/electron/electronApiContext";

export const useElectronClusters = (): MachineClusters | undefined => {
  const [clusters, setClusters] = useState<MachineClusters>();
  useEffect(() => {
    window.electronAPI?.getMachineClusters().then((machineClusters) => {
      if (!machineClusters) return;
      setClusters(machineClusters);
    });
  }, []);
  return clusters;
};
