import React from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import { useParams } from "react-router-dom";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeReadyCheck from "../NodeReadyCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import {
  GrayHighlight,
  NormalText,
  NoUnderlinedLink,
  StyledTable,
} from "../../../../common/styles";
import { SpacedRowFieldContainer } from "../../../../monitorsConfiguration/common/styles";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

const Row = styled.tr`
  line-height: 1rem;
`;

const FixedScrollableTable = styled(StyledTable)`
  tbody {
    display: block;
    max-height: 12.8rem;
    overflow-y: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  th {
    font-family: Roboto Mono;
  }
`;

const Roboto = styled.div`
  font-family: Roboto Mono;
`;

const NodeReadyDetails: React.FC<{
  check: NodeReadyCheck;
}> = ({ check }) => {
  const checkOutput = check.output;

  const { serviceId } = useParams<{ serviceId: string }>();

  return !check.passed ? (
    <>
      <br />
      <BoldGrayText>Error message</BoldGrayText>
      <NormalText>
        <GrayHighlight>{checkOutput?.readyMessage}</GrayHighlight>
      </NormalText>
      <br />
      {checkOutput?.pods && checkOutput?.pods?.length > 0 && (
        <>
          <BoldGrayText>Pods on the node</BoldGrayText>
          <FixedScrollableTable>
            <thead>
              <tr>
                <th>Pod name</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(
                checkOutput.pods,
                (p) =>
                  serviceId ? [p.serviceId === serviceId, p.name] : p.name,
                ["desc"]
              ).map((pod, index) => (
                <Row key={index}>
                  <td>
                    <SpacedRowFieldContainer>
                      <Roboto>{pod.name}</Roboto>
                      {pod?.serviceId && (
                        <NoUnderlinedLink
                          to={`/main/deep-dive/${pod.serviceId}`}
                        >
                          View Service
                        </NoUnderlinedLink>
                      )}
                    </SpacedRowFieldContainer>
                  </td>
                </Row>
              ))}
            </tbody>
          </FixedScrollableTable>
          <br />
        </>
      )}
      <HiddenPodsInfo hiddenPodsCount={checkOutput?.hiddenPods} />
      <NormalText>
        {checkOutput.lastReadyTime ? (
          <>
            The node became <b>NotReady</b>
          </>
        ) : (
          "The node was not ready since creation"
        )}
        . Node created: <b>{checkOutput.createdTime} ago</b>
      </NormalText>
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeReadyDetails;
