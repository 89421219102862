export const includedFeatures = [
  "Track K8s costs by team, dept, env, and app",
  "Analyze costs over time and spot trends",
  "Get recommendations to rightsize resource allocations",
  "Instantly apply recommendations",
  "Identify and remove idle resources",
  "Real-time spend alerts",
];

export const description =
  "Managing Kubernetes costs can be challenging, especially when you have multiple departments, teams, and applications running on different environments.";

export const freemiumDescription =
  "Komodor’s advanced cost capabilities are offered as part of Komodor’s paid plans and is included as part of the 14-day trial!";
