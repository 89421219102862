import React, { useState } from "react";
import { TooltipWrapper } from "react-tooltip";

import {
  ActionsUpgradeRequired,
  useActionsNeedUpgradeAgent,
} from "../common/actionsUpgradeRequired";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

export interface ActionsProps {
  agentId: string;
  cluster: string;
  onClick: () => void;
  Button: React.FC<{
    onClick: () => void;
    disabled?: boolean;
    ariaLabel?: string;
  }>;
  children?: React.ReactNode;
  minimalSupportedVersion?: string;
  disabled?: boolean;
  ariaLabel?: string;
  action?: keyof AgentProperties["actions"];
  disabledReason?: string;
}

const Action: React.FC<ActionsProps> = ({
  agentId,
  cluster,
  onClick,
  Button,
  children,
  minimalSupportedVersion,
  disabled,
  ariaLabel,
  action,
  disabledReason,
}) => {
  const [displayUpgradeRequired, setDisplayUpgradeRequired] = useState(false);

  const agentUpgradeRequiredForActions = useActionsNeedUpgradeAgent({
    agentId,
    cluster,
    minimalSupportedVersion,
    action,
  });

  const executeAction = () => {
    if (agentUpgradeRequiredForActions) {
      setDisplayUpgradeRequired(true);
    } else {
      onClick();
    }
  };

  const getTooltipMessage = () => {
    if (!disabled) {
      return ariaLabel || "Invoke action";
    }

    return disabledReason || "Action is disabled";
  };

  return (
    <>
      <TooltipWrapper
        tooltipId={DEFAULT_TOOLTIP}
        content={getTooltipMessage()}
        place="bottom"
      >
        <Button
          onClick={executeAction}
          disabled={Boolean(disabled)}
          ariaLabel={ariaLabel}
        />
      </TooltipWrapper>
      {children}
      {displayUpgradeRequired && (
        <ActionsUpgradeRequired
          handleClose={() => setDisplayUpgradeRequired(false)}
        />
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Action;
