import { ServicePort } from "kubernetes-types/core/v1.d";
import React from "react";
import { Minus16 } from "@komodorio/design-system/icons";

import { Column } from "../../../../common/table/DataTable";

export const portsColumns: Column<ServicePort>[] = [
  { name: "Name", selector: (u) => u.name || <Minus16 /> },
  { name: "Protocol", selector: (u) => u.protocol },
  { name: "Port", selector: (u) => u.port },
  { name: "Target Port", selector: (u) => u.targetPort },
];
