import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography as DeprecatedTypography } from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";
import {
  ArrowNorthEast16,
  ArrowSouthEast16,
} from "@komodorio/design-system/icons";
import Tooltip from "@mui/material/Tooltip";

import { HelmChartSummaryWithAge } from "../hooks/types";
import { getChartNameAndVersion } from "../utils";
import { versionEq, versionGt } from "../versionUtils";

import { SELECTED_CHART_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const SELECTED_WIDTH = 201;
const NONSELECTED_WIDTH = 208;
const MAX_VERSION_LENGTH = 7;

const Tile = styled.div<{ selected: boolean; color: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ selected }) => (selected ? SELECTED_WIDTH : NONSELECTED_WIDTH)}px;
  min-width: ${({ selected }) =>
    selected ? SELECTED_WIDTH : NONSELECTED_WIDTH}px;
  height: 64px;
  padding: 12px 14px;
  gap: 24px;
  background-color: ${({ selected }) =>
    selected ? palette.white : palette.gray[50]};
  margin: 24px 0;
  border-radius: 4px;
  cursor: pointer;
  border-style: solid;
  border-width: ${({ selected }) => (selected ? "1px 1px 1px 8px" : "1px")};
  border-color: ${({ selected, color }) =>
    selected ? `${color}` : palette.gray[200]};
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AgeContainer = styled(ContentRow)`
  gap: 4px;
`;

const StyledRevisionContainer = styled.div`
  gap: 2px;
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography).attrs({ variant: "overline" })`
  && {
    letter-spacing: unset;
  }
`;

const tooltipStyle = {
  tooltip: {
    sx: {
      "&&.MuiTooltip-tooltipPlacementTop": {
        marginBottom: "4px",
      },
    },
  },
};

const StyledTooltip = styled(Tooltip).attrs({
  arrow: false,
  placement: "top",
  componentsProps: tooltipStyle,
})``;

const LineThroughTypography = styled(StyledTypography)`
  text-decoration: line-through;
`;

const getVersionChangeContent = (
  currChartRevision: HelmChartSummaryWithAge,
  prevChartRevision?: HelmChartSummaryWithAge
): JSX.Element => {
  if (!prevChartRevision) return <span />;

  const { version: currentVersion } = getChartNameAndVersion(
    currChartRevision.version
  );
  const { version: prevVersion } = getChartNameAndVersion(
    prevChartRevision.version
  );

  const isLongLengthVersion = (version: string): boolean =>
    version.length > MAX_VERSION_LENGTH;

  const getVersionText = (version: string): string => {
    if (isLongLengthVersion(version))
      return version.slice(0, MAX_VERSION_LENGTH) + "...";
    return version;
  };

  if (versionEq(currentVersion, prevVersion)) {
    return (
      <StyledTooltip
        title={isLongLengthVersion(currentVersion) && currentVersion}
      >
        <StyledTypography>{getVersionText(currentVersion)}</StyledTypography>
      </StyledTooltip>
    );
  }

  const isHigherVersion = versionGt(currentVersion, prevVersion);

  return (
    <StyledRevisionContainer>
      <StyledTooltip title={isLongLengthVersion(prevVersion) && prevVersion}>
        <LineThroughTypography>
          {getVersionText(prevVersion)}
        </LineThroughTypography>
      </StyledTooltip>
      {isHigherVersion ? (
        <ArrowNorthEast16 fill={palette.gray["500"]} />
      ) : (
        <ArrowSouthEast16 fill={palette.gray["500"]} />
      )}
      <StyledTooltip
        title={isLongLengthVersion(currentVersion) && currentVersion}
      >
        <StyledTypography>{getVersionText(currentVersion)}</StyledTypography>
      </StyledTooltip>
    </StyledRevisionContainer>
  );
};

interface Props {
  revision: HelmChartSummaryWithAge;
  prevChartRevision?: HelmChartSummaryWithAge;
  color: string;
  setSelectedRevision: (revision: HelmChartSummaryWithAge) => void;
}

export const HistoryItem: React.FC<Props> = ({
  revision,
  prevChartRevision,
  color,
  setSelectedRevision,
}) => {
  const [selectedChartId, setChartId] = useStateInSearchParams(
    SELECTED_CHART_PARAM_KEY
  );

  return (
    <Tile
      color={color}
      key={revision.secretId}
      selected={revision.secretId === selectedChartId}
      onClick={() => {
        setChartId(revision.secretId);
        setSelectedRevision(revision);
      }}
    >
      <ContentRow>
        <DeprecatedTypography variant="title" size="medium" bold color={color}>
          {revision.status.toUpperCase()}
        </DeprecatedTypography>
        <ContentRow>
          <DeprecatedTypography size="medium">#</DeprecatedTypography>
          <DeprecatedTypography size="large" bold>
            {revision.revision}
          </DeprecatedTypography>
        </ContentRow>
      </ContentRow>
      <ContentRow>
        {getVersionChangeContent(revision, prevChartRevision)}
        <AgeContainer>
          <DeprecatedTypography size="small" color={palette.gray[600]}>
            AGE:{" "}
          </DeprecatedTypography>
          <DeprecatedTypography size="small" bold>
            {revision.age}
          </DeprecatedTypography>
        </AgeContainer>
      </ContentRow>
    </Tile>
  );
};
