import { useEffect, useMemo } from "react";

import {
  Params,
  useGetKomodorService,
} from "../../../shared/hooks/resources-api/client/komodor-services/useKomodorServices";
import { KomodorServicesApiApiV1KomodorServicesSearchGetRequest } from "../../../generated/resourcesApi";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import { useUpdateServicesByDiff } from "./useUpdateServicesByDiff";
import { useGetServicePollingIntervalInSeconds } from "./useGetServicePollingIntervalInSeconds";
import { FetchServicesOutput } from "./types";
import { useIsStaleResults } from "./useIsStaleResults";

type ServiceType = "services" | "jobs";

type ProcessServicesFromResourcesApiParams = {
  enableResourcesApi: boolean | undefined;
  requestFields: KomodorServicesApiApiV1KomodorServicesSearchGetRequest["fields"];
  type: ServiceType;
};

const kindMap: Record<ServiceType, string[]> = {
  services: ["DaemonSet", "Deployment", "Rollout", "StatefulSet"],
  jobs: ["Job", "CronJob"],
};

export const useProcessServicesFromResourcesApi = ({
  type,
  enableResourcesApi,
  requestFields,
}: ProcessServicesFromResourcesApiParams): FetchServicesOutput => {
  const { fetchJobsStatusesAsJson } = useOverridableFlags();
  const { servicesDictionary, servicesList, updateServices } =
    useUpdateServicesByDiff();
  const pollingInterval = useGetServicePollingIntervalInSeconds();
  const { isStale: isStaleResults, reset: resetIsStaleResults } =
    useIsStaleResults();
  const requestParams = useMemo(
    (): Params => ({
      fields: requestFields,
      kind: kindMap[type],
      ...(type === "jobs" && fetchJobsStatusesAsJson ? { status: true } : {}),
    }),
    [requestFields, type, fetchJobsStatusesAsJson]
  );

  const { data, isFetching, isInitialLoading, status, fetchMode } =
    useGetKomodorService(
      requestParams,
      isStaleResults,
      false,
      pollingInterval * 1000,
      enableResourcesApi
    );

  useEffect(() => {
    if (!isFetching && isStaleResults) {
      resetIsStaleResults();
    }
  }, [isFetching, isStaleResults, resetIsStaleResults]);

  const { services: processedServicesFromResourcesApi } =
    data?.servicesWithLastUpdatedAt ?? {};

  useEffect(() => {
    updateServices({
      services: processedServicesFromResourcesApi,
      resetCurrentData: isStaleResults || fetchMode === "full",
    });
  }, [
    fetchMode,
    isInitialLoading,
    isStaleResults,
    processedServicesFromResourcesApi,
    status,
    updateServices,
  ]);

  return {
    servicesDictionary,
    servicesList,
    fetchMode,
  };
};
