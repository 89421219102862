import { KubernetesResource } from "./KubernetesResource";

import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";

export const KubernetesNamespaceResource: KubernetesResource = {
  NameInK8S: "namespaces",
  Group: "",
  NavBarName: "Namespaces",
  PresentName: "Namespaces",
  Namespaced: false,
  UnnecessaryColumns: [],
  Kind: "Namespace",
  Headers: [{ name: "Name" }, { name: "Age" }, { name: "Labels" }],
  hasLabels: true,
  SupportedActions: [AvailableActions.Describe, AvailableActions.Delete],
  DatabaseFetch: true,
};
