import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAccountsApiClient } from "../apiClient";

import {
  Account,
  AccountsApiApiV1AccountsGetRequest,
  apiV1AccountsGetUrl,
} from "@/generated/accounts";
import { encodeBase64 } from "@/shared/utils/encodeBase64";

const ACCOUNTS_PATH = "/api/v1/accounts";

const getAccounts = async (
  apiClient: AxiosInstance,
  params: AccountsApiApiV1AccountsGetRequest
): Promise<Account[]> => {
  const { data } = await apiClient.get(
    apiV1AccountsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useListAccounts = ({
  enabled,
  params,
}: {
  enabled?: boolean;
  params: AccountsApiApiV1AccountsGetRequest;
}) => {
  const uniqueKey = encodeBase64(JSON.stringify(params));
  const apiClient = useAccountsApiClient();
  return useQuery(
    [ACCOUNTS_PATH, uniqueKey],
    () => getAccounts(apiClient, params),
    {
      enabled,
      cacheTime: 0, // Disable cache for this query
    }
  );
};
