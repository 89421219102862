/** TODO: create an enum / union for the types, and a dictionary mapping for the string value used */

export const hpaType = "HPA change";
export const pdbType = "PDB change";
export const k8sServiceResourcesType = "K8s service change";
export const ingressType = "Ingress change";
export const limitRangeType = "Limit range change";
export const networkPolicyType = "Network Policy change";
export const resourcesQuotaType = "Resource quota change";
export const customEventType = "Custom event";
export const agentDowntimeType = "Agent Issue";
