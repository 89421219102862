import React from "react";

import { AccountOptionsMenu } from "./components/AccountOptionsMenu/AccountOptionsMenu";
import { useShouldAllowAccountSwitcher } from "./hooks/useShouldAllowAccountSwitcher";

export const AccountSwitcher: React.FC = () => {
  const shouldShowAccountSwitcher = useShouldAllowAccountSwitcher();

  if (shouldShowAccountSwitcher) {
    return <AccountOptionsMenu />;
  }
  return null;
};
