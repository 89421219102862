import React, { memo, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { yellowHighlightText } from "../../Colors";

import {
  ColorCodeWordsRegex,
  ErrorCodingWords,
  HighlightColor,
  HighlightColorError,
  HighlightColorWarning,
  WarningCodingWords,
} from "./LogsViewer/LogsBlackText/utils";

const highlightStyle = css`
  border-radius: 2px;
  padding-inline: 0.5px;
`;

const Highlight = styled.span<{ backgroundColor?: string; color?: string }>`
  ${highlightStyle};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : yellowHighlightText};
  ${({ color }) => (color ? `color: ${color}` : "")}
`;

const SearchedWordStyled = styled.span`
  ${highlightStyle};
  background-color: ${muiColors.blue[500]};
  color: white;
`;

const SearchHighlightStyled = styled.span`
  ${highlightStyle};
  background-color: ${muiColors.blue[400]};
  color: ${muiColors.gray[800]};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum HighlightTypes {
  Simple,
  Highlight,
  Error,
  Warning,
  Keyword,
  Search,
  SearchedWord,
}

export interface TextHighlightType {
  type: HighlightTypes;
  value: string;
  matchIndex?: number;
  color?: string;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function highlightTerm(
  text: string,
  term: string,
  highlightType: HighlightTypes = HighlightTypes.Highlight
): TextHighlightType[] {
  if (!term) {
    return [{ type: HighlightTypes.Simple, value: text }];
  }

  const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return text.split(new RegExp(`(${escapedTerm})`, "i")).map((value, i) => {
    return i % 2 === 0
      ? { type: HighlightTypes.Simple, value: value }
      : {
          type: highlightType,
          value: value,
          matchIndex: Math.floor(i / 2),
        };
  });
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function highlightCodedWords(
  searchedParts: TextHighlightType[]
): TextHighlightType[] {
  return searchedParts.flatMap((p) => {
    if (p.type === HighlightTypes.Simple) {
      return p.value.split(ColorCodeWordsRegex).map((value, i) => {
        return i % 2 === 0
          ? { type: HighlightTypes.Simple, value: value }
          : {
              type: ErrorCodingWords.includes(value)
                ? HighlightTypes.Error
                : WarningCodingWords.includes(value)
                ? HighlightTypes.Warning
                : HighlightTypes.Keyword,
              value: value,
            };
      });
    } else {
      return [p];
    }
  });
}

const SearchedWord: React.FC<{ children?: React.ReactNode }> = memo(
  ({ children }) => {
    const myRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      myRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, []);
    return <SearchedWordStyled ref={myRef}>{children}</SearchedWordStyled>;
  }
);

export const BuildTextHighlights: React.FC<{
  parts: TextHighlightType[];
  overrideHighlightColor?: HighlightColor;
}> = memo(({ parts, overrideHighlightColor }) => (
  <>
    {parts.map((part, index) => {
      if (
        overrideHighlightColor &&
        part.type !== HighlightTypes.Search &&
        part.type !== HighlightTypes.SearchedWord
      ) {
        return (
          <Highlight
            color={overrideHighlightColor}
            backgroundColor="transparent"
            key={index}
          >
            {part.value}
          </Highlight>
        );
      }

      switch (part.type) {
        case HighlightTypes.Highlight:
          return (
            <Highlight className={"highlight"} key={index}>
              {part.value}
            </Highlight>
          );
        case HighlightTypes.Error:
          return (
            <Highlight
              color={HighlightColorError}
              backgroundColor="transparent"
              key={index}
            >
              {part.value}
            </Highlight>
          );
        case HighlightTypes.Warning:
          return (
            <Highlight
              color={HighlightColorWarning}
              backgroundColor="transparent"
              key={index}
            >
              {part.value}
            </Highlight>
          );
        case HighlightTypes.Keyword:
          return (
            <Highlight
              color={muiColors.gray[800]}
              backgroundColor={muiColors.blue[100]}
              key={index}
            >
              {part.value}
            </Highlight>
          );
        case HighlightTypes.Search:
          return (
            <SearchHighlightStyled key={index}>
              {part.value}
            </SearchHighlightStyled>
          );
        case HighlightTypes.SearchedWord:
          return <SearchedWord key={index}>{part.value}</SearchedWord>;
        default:
          return <React.Fragment key={index}>{part.value}</React.Fragment>;
      }
    })}
  </>
));

export const HighlightedText: React.FC<{ text: string; term: string }> = memo(
  ({ text, term }) => {
    const parts = highlightTerm(text, term);
    return <BuildTextHighlights parts={parts} />;
  }
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getMainLineHighlight = (
  parts: TextHighlightType[]
): HighlightColor | undefined => {
  if (
    parts.find((part) => ErrorCodingWords.includes(part.value.toLowerCase()))
  ) {
    return HighlightColorError;
  }
  if (
    parts.find((part) => WarningCodingWords.includes(part.value.toLowerCase()))
  ) {
    return HighlightColorWarning;
  }
  return undefined;
};
