import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";

import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";

import { DateTimeTitle, TooltipHourColor } from "./metricsTooltipStyles";

type TooltipDateTimeTitleProps = {
  label: number;
  dateOnly?: boolean;
};
export const TooltipDateTimeTitle: React.FC<TooltipDateTimeTitleProps> = ({
  label,
  dateOnly,
}) => {
  const { format } = useDateFormatter({
    timeZoneName: undefined,
  });

  const [date, year, time] = format(label).split(", ");

  return (
    <DateTimeTitle>
      <Typography color={TooltipHourColor}>{`${date}, ${year}`}</Typography>
      {!dateOnly && (
        <Typography bold color={TooltipHourColor}>
          {time}
        </Typography>
      )}
    </DateTimeTitle>
  );
};
