import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { checkTypeToLabel } from "../../../../reliability/constants/reliabilityConstants";
import { CheckType } from "../../../../../generated/reliabilityApi";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

import { StyledLinkWithPointer } from "./reliabilitySectionStyles";

const { ViolationItem } =
  AriaLabels.ClusterOverview.ReliabilitySection.Violations.ImpactGroup;

interface ImpactItemProps {
  violationCount: [CheckType, number];
  onItemClick?: (checkType: CheckType) => void;
}
export const ImpactItem: React.FC<ImpactItemProps> = ({
  violationCount,
  onItemClick,
}) => {
  const [checkType, count] = violationCount;
  const checkTypeLabel = checkTypeToLabel[checkType] ?? checkType;

  return (
    <Box
      className="item"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "13.5px 16px",
      }}
    >
      <StyledLinkWithPointer
        variant="body2"
        underline="hover"
        aria-label={ViolationItem}
        onClick={() => onItemClick?.(checkType)}
      >
        {checkTypeLabel}
      </StyledLinkWithPointer>
      <Typography variant="h5">{count}</Typography>
    </Box>
  );
};
