import React from "react";
import styled from "styled-components";
import { ResourceTableModelRow } from "komodor-types";

import { getHpaStatusGroupByStatusConditions } from "../../../statusMapper";
import { HpaStatusConditions } from "../../../enums";

import { StatusTag } from "./StatusTag";

interface StatusConditionsCellProps {
  cellName: string;
  row: ResourceTableModelRow | undefined;
}

const StyleGroupTags = styled.div`
  display: flex;
  gap: 8px;
`;

const statuses = [
  HpaStatusConditions.AbleToScale,
  HpaStatusConditions.ScalingActive,
  HpaStatusConditions.ScalingLimited,
];

export const StatusConditionsCell: React.FC<StatusConditionsCellProps> = ({
  cellName,
  row,
}) => {
  if (!row) return;
  return (
    <td key={cellName}>
      <StyleGroupTags>
        {statuses.map((status) => {
          const statusValue = row[status];
          if (!statusValue) return null;

          return (
            <StatusTag
              key={status}
              status={status}
              statusGroup={getHpaStatusGroupByStatusConditions(
                status,
                statusValue
              )}
            />
          );
        })}
      </StyleGroupTags>
    </td>
  );
};
