import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileDownload from "@mui/icons-material/FileDownload";

import { downloadCsvFile } from "./utils";
import { CsvData } from "./types";

export interface CsvExportProps {
  fileName: string;
  data: CsvData;
  ariaLabel?: string;
}

export const CsvExport: React.FC<CsvExportProps> = ({
  fileName,
  data,
  ariaLabel,
}) => {
  const onExport = () => {
    downloadCsvFile(fileName, data);
  };

  return (
    <Button onClick={onExport} aria-label={ariaLabel}>
      <FileDownload />
      <Typography variant="h6">Export</Typography>
    </Button>
  );
};
