import { NetworkMapGraphResponse } from "../../../../../generated/resourcesApi";

export const mockNodes3: NetworkMapGraphResponse = {
  nodes: [
    "Deployment|production|demo|correlation-engine",
    "Deployment|production|demo|event-puller-service",
    "Deployment|production|demo|rest-api",
    "Deployment|production|demo|auth",
    "Deployment|production|demo|web-ui",
  ],
  edges: [
    {
      source: "Deployment|production|demo|event-puller-service",
      destination: "Deployment|production|demo|correlation-engine",
    },
    {
      source: "Deployment|production|demo|correlation-engine",
      destination: "Deployment|production|demo|rest-api",
    },
    {
      source: "Deployment|production|demo|correlation-engine",
      destination: "Deployment|production|demo|auth",
    },
    {
      source: "Deployment|production|demo|correlation-engine",
      destination: "Deployment|production|demo|web-ui",
    },
  ],
};
