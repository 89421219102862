import { useQuery } from "@tanstack/react-query";

import {
  getWorkloadContainersMetricsUrl,
  MetricsApiGetWorkloadContainersMetricsRequest,
  WorkloadMetricsContainers,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_METRICS_WORKLOAD_CONTAINERS_MEMORY_PATH =
  "/workload/containers/memory";
export const GET_METRICS_WORKLOAD_CONTAINERS_CPU_PATH =
  "/workload/containers/cpu";

type GetWorkloadContainersMetricsParams = {
  params: MetricsApiGetWorkloadContainersMetricsRequest;
  enabled?: boolean;
};

export const useGetWorkloadContainersMetrics = ({
  params,
  enabled,
}: GetWorkloadContainersMetricsParams) => {
  const apiClient = useMetricsApiClient();
  const url = getWorkloadContainersMetricsUrl(
    params,
    apiClient.defaults.baseURL ?? ""
  );

  const queryKey =
    params.kind === "cpu"
      ? GET_METRICS_WORKLOAD_CONTAINERS_CPU_PATH
      : GET_METRICS_WORKLOAD_CONTAINERS_MEMORY_PATH;

  return useQuery(
    [queryKey, params],
    async () => await apiClient.get<WorkloadMetricsContainers>(url),
    { enabled: enabled }
  );
};
