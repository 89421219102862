/* eslint-disable max-lines */
import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";
import { Location } from "react-router-dom";

import { getCookieValue } from "../utils/cookie/cookie";
import { parseUrlUtms } from "../utils/url/urlUtms";
import { getAppConfig } from "../config/appConfig";
import { LocalStorageItem } from "../utils/localStorageSettings";
import useAnalyticsApi from "../context/analyticsProvider";
import { AnalyticEvents } from "../config/analyticsEvents";
import { formatUserMetadataForSegment } from "../utils/formatUserMetadataForSegment";
import { identifyTypes } from "../types/identifyTypes";

import { getDatadogReplayUrl, notifyDDError } from "./exceptionManagement";
import useUserMetadata from "./useUserMetadata/useUserMetadata";
import { useUserLoginInfo } from "./useUserMetadata/useUserLoginInfo";

export * from "../config/analyticsEvents";
export const useInitSegmentAnalytics = (
  location: Location,
  backendEvent: identifyTypes
): boolean => {
  const user = useUserLoginInfo();
  const userMetadata = useUserMetadata();
  const [initialized, setInitialized] = useState<boolean>(false);
  const analytics = useAnalyticsApi();

  if (!sessionStorage.getItem("userLoggedIn")) {
    sessionStorage.setItem("userLoggedIn", "true");
    analytics.dispatchEventViaBackend(
      AnalyticEvents.UserActions.User_Login.toLowerCase(),
      {},
      true
    );
  }

  useEffect(() => {
    const backend_identify = () => {
      const meta = document.createElement("meta");
      meta.httpEquiv = "Content-Security-Policy";
      meta.content = "upgrade-insecure-requests";
      document.getElementsByTagName("head")[0].appendChild(meta);
      analytics.identifySegment(
        {
          userId: user?.email,
          traits: {
            ...user,
            ...formatUserMetadataForSegment(userMetadata),
            ...parseUrlUtms(location.search),
          },
          context: {
            groupId: userMetadata?.accountSalesforceId, // for configuring Totango-Segment integration
          },
        },
        "identify",
        user?.email ?? ""
      );
    };

    const client_identify = () => {
      if (!window.analytics) return;
      if (!user?.email) return;
      const userId = user.email;
      const segmentWriteKey = getAppConfig().segmentWriteKey ?? "";
      window.analytics?.load(segmentWriteKey);
      window.analytics?.page();
      if (user.name?.includes("@")) delete user.name;
      window.analytics?.identify(
        userId,
        {
          ...user,
          ...formatUserMetadataForSegment(userMetadata),
          ...parseUrlUtms(location.search),
          groupId: userMetadata?.accountId,
        },
        undefined,
        undefined
      );
    };

    if (initialized) return;
    if (/HeadlessChrome/.test(navigator.userAgent)) return;
    if (!user || !user.email) return;
    if (user.email?.includes("@komodor-test")) return;
    try {
      if (backendEvent === identifyTypes.backend) {
        backend_identify();
      } else if (backendEvent === identifyTypes.client) {
        client_identify();
      } else {
        backend_identify();
        client_identify();
      }
    } catch (error) {
      notifyDDError({
        name: "Error initializing segment analytics",
        message: `${error}`,
      });
    }

    setInitialized(true);
  }, [analytics, backendEvent, initialized, location, user, userMetadata]);
  return initialized;
};

export const useInitDatadogRumAnalytics = (): boolean => {
  const user = useUserLoginInfo();
  const userMetadata = useUserMetadata();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) return;
    if (getAppConfig().env !== "production") {
      return;
    }
    if (!user?.email || user?.email.endsWith("komodor-test.com")) {
      return;
    }

    const datadogRumToken = getAppConfig().datadogRumToken;
    const datadogRumAppId = getAppConfig().datadogRumAppId;
    if (datadogRumToken && datadogRumAppId) {
      datadogRum.init({
        applicationId: datadogRumAppId,
        clientToken: datadogRumToken,
        site: "datadoghq.com",
        service: "web",
        env: getAppConfig().env,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        traceSampleRate: 100,
        allowedTracingUrls: [
          "https://app.komodor.com",
          "https://gql.app.komodor.com/v1/graphql",
        ],
        trackUserInteractions: true,
        defaultPrivacyLevel: "allow",
        trackResources: true,
        trackLongTasks: true,
        version: getAppConfig().version,
      });
    }

    datadogRum.setUser({
      id: user.sub,
      email: user.email,
      name: user.name,
    });
    datadogRum.setGlobalContextProperty(
      "accountName",
      userMetadata.accountName
    );

    setInitialized(true);
  }, [initialized, user, userMetadata]);

  return initialized;
};

interface HubspotField {
  objectTypeId: string;
  name: string;
  value: string;
}

interface HubspotPayload {
  fields: HubspotField[];
  context: {
    hutk: string;
  };
}

export const useInithubspotForm = (): boolean => {
  const user = useUserLoginInfo();
  const userMetadata = useUserMetadata();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (getAppConfig().env !== "production") return;
    if (!user || !user.email) return;
    if (user.email?.includes("@komodor-test")) return;
    const storedHubspotFormState = new LocalStorageItem("hubspotFormSent");
    const hsTokenCookieData = getCookieValue("hubspotutk");
    if (!hsTokenCookieData) return;

    const payload: HubspotPayload = {
      fields: [],
      context: {
        hutk: hsTokenCookieData,
      },
    };

    const addFieldIfNotEmpty = (name: string, value?: string) => {
      if (value) {
        payload.fields.push({
          objectTypeId: "0-1",
          name: name,
          value: value,
        });
      }
    };

    addFieldIfNotEmpty("email", user.email);
    addFieldIfNotEmpty("firstname", user.given_name);
    addFieldIfNotEmpty("lastname", user.family_name);
    addFieldIfNotEmpty("utm_source", userMetadata.utmSource);
    addFieldIfNotEmpty("utm_medium", userMetadata.utmMedium);
    addFieldIfNotEmpty("utm_campaign", userMetadata.utmCampaign);
    addFieldIfNotEmpty("utm_content", userMetadata.utmContent);
    addFieldIfNotEmpty("utm_term", userMetadata.utmTerm);

    if (!sessionStorage.getItem("userHubspotSessionLoggedIn")) {
      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${
          getAppConfig().hbsptPortalId
        }/${getAppConfig().hbsptFormId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        }
      ).then((response) => {
        if (!response.ok) {
          storedHubspotFormState.set("failed");
        }
        storedHubspotFormState.set("sent");
        setInitialized(true);
      });
      sessionStorage.setItem("userHubspotSessionLoggedIn", "true");
    }
    if (storedHubspotFormState.get() === "sent") {
      setInitialized(true);
    }
  }, [
    user,
    userMetadata.utmCampaign,
    userMetadata.utmContent,
    userMetadata.utmMedium,
    userMetadata.utmSource,
    userMetadata.utmTerm,
  ]);
  return initialized;
};
export const dispatchEvent = async (
  eventName: string,
  properties?: { [key: string]: unknown },
  sendEventToSegment = true
): Promise<void> => {
  if (getAppConfig().env === "development") return;
  return new Promise((resolve) => {
    if (/HeadlessChrome/.test(navigator.userAgent)) return;

    if (typeof properties === "undefined") properties = {};
    properties["accountname"] = window.komodor.userMetadata?.accountName;
    properties["sessionReplayURL"] = getDatadogReplayUrl();

    if (sendEventToSegment) sendToSegmentFromWebClient(eventName, properties);
  });
};

function sendToSegmentFromWebClient(
  eventName: string,
  properties: { [key: string]: unknown }
) {
  if (window.analytics) {
    if (typeof window.analytics?.user !== "function") return;
    if (window.analytics.user().id() === null) return;
    if (!Object.prototype.hasOwnProperty.call(properties, "email")) {
      properties["email"] = window.analytics.user().id();
    }
    window.analytics.track(eventName, properties);
  }
}
