import styled from "styled-components";
import React from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import { CsvData } from "../../../../../shared/components/CsvExport/types";

interface ExportButtonProps {
  fileName: string;
  ariaLabel: string;
  data?: CsvData;
}

interface GridToolbarContainerProps {
  exportButton: React.ReactElement<ExportButtonProps>;
  showExport: boolean;
}

const GridToolbarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomToolbar: React.FC<GridToolbarContainerProps> = ({
  exportButton,
  showExport,
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton size="large" />
      {showExport && <>{exportButton}</>}
    </GridToolbarContainer>
  );
};
