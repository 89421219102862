import pluralize from "pluralize";

import {
  ContainerUsageRequestData,
  ResourceType,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";
import {
  getMibNumber,
  shortVariation,
} from "../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";
import { formatNumber } from "../../../../../../../../../shared/utils/formatNumber";

import { ContainerResourcesData } from "./underProvisionedTypes";

export const getDiffByResourceType = (type: ResourceType, diff: number) => {
  if (type === ResourceType.Cpu)
    return `${formatNumber(shortVariation(diff))} millicores`;
  return `${formatNumber(getMibNumber(diff))} MiB`;
};

export const getRatio = (ratio: number) => shortVariation(ratio * 100);

type ContainerResourcesDataDiff = {
  cpu: number;
  memory: number;
};

export const getUnderProvisionedTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  if (!supportingData?.underProvisionedWorkloads?.containersData)
    return "Under provisioned workloads";

  const totalContainersDiff =
    supportingData.underProvisionedWorkloads.containersData.reduce<ContainerResourcesDataDiff>(
      (acc, container) => {
        const type = container.resourceType === "CPU" ? "cpu" : "memory";
        acc[type] += container.diff;
        return acc;
      },
      {
        cpu: 0,
        memory: 0,
      }
    );

  const cpuDiffInCores = totalContainersDiff.cpu / 1000;
  const memoryDiffInGib = totalContainersDiff.memory / Math.pow(1024, 3);
  const containerText =
    supportingData.underProvisionedWorkloads.containersData.length > 1
      ? "Containers are"
      : "Container is";
  const coresText = cpuDiffInCores
    ? `${formatNumber(cpuDiffInCores)} ${pluralize("Core", cpuDiffInCores)}`
    : "";
  const memoryText = memoryDiffInGib
    ? `${formatNumber(memoryDiffInGib)} GiB`
    : "";
  const comma = coresText && memoryText ? ", " : "";

  return `${containerText} under-provisioned by ${coresText}${comma}${memoryText}`;
};

export const unionContainersData = (
  containersData: ContainerUsageRequestData[] | undefined
): ContainerResourcesData => {
  if (!containersData) return {};

  return containersData.reduce<ContainerResourcesData>(
    (acc, { containerName, resourceType, ratio, diff }) => {
      return {
        ...acc,
        [containerName]: {
          ...acc[containerName],
          ...{
            [resourceType === ResourceType.Cpu ? "cpu" : "memory"]: {
              ratio: ratio,
              diff: diff,
            },
          },
        },
      };
    },
    {}
  );
};
