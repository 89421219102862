import React from "react";
import { Secret as SecretType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Secret as secretIcon } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import SecretDescribeTab from "../tabs/DescribeTab/secret/SecretDescribeTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class Secret implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions;
  readonly creationTimestamp;
  readonly type;
  readonly data;
  readonly fullObj: SecretType;
  readonly defaultTab;

  constructor(
    cluster: string,
    secret: SecretType,
    agentId: string,
    featureFlags: Record<string, unknown>
  ) {
    this.agentId = agentId;
    this.id = secret.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = secret.metadata?.namespace ?? "";
    this.name = secret.metadata?.name ?? "";
    this.kind = secret.kind ?? "";
    this.actions = [
      ...(featureFlags.editSecret ? [AvailableActions.Edit] : []),
      AvailableActions.Delete,
    ];
    this.labels = secret.metadata?.labels ?? {};
    this.annotations = secret.metadata?.annotations ?? {};
    this.icon = secretIcon;
    this.creationTimestamp = secret.metadata?.creationTimestamp ?? "";
    this.type = secret.type ?? "unknown";
    this.data = secret.data ?? {};
    this.fullObj = secret;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <SecretDescribeTab secret={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
