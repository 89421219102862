/* eslint-disable max-lines */
import { ReactNode } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { merge, cloneDeep } from "lodash";

import EventGroup from "../../../../../../common/EventGroup";
import {
  AppInsightsApiData,
  DomRect,
  MetricsSupportAgentInfo,
  OverviewMetricsData,
  TimeWindow,
} from "../types/overviewPageTypes";
import {
  RightSizingFiltersState,
  RightSizingRecommendationsModalState,
  RightSizingRecommendationsState,
  RightSizingRecommendationsStrategiesState,
  RightSizingSummaryState,
} from "../../../../../../CostOptimizationView/types/costOptimizationTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { initialState } from "./initialState";

type TimeWindowIntervals = TimeWindow[];

export type InsightsDrawerContent = {
  id: string;
  title: string;
  component: ReactNode;
  ariaLabel?: string;
};

export type UiElementsRect = {
  pageContainer?: Partial<DomRect>;
  overviewContainer?: Partial<DomRect>;
  insightsContainer?: Partial<DomRect>;
};

export type OverviewPageState = {
  eventItemDrawer: {
    eventItemDrawerOpen: boolean;
    currentEventItem?: EventGroup;
  };
  insightsDrawer: {
    insightsDrawerOpen: boolean;
    insightsDrawerContent?: InsightsDrawerContent;
  };
  cost: {
    rightSizingFilters: RightSizingFiltersState;
    rightSizingSummaryState: RightSizingSummaryState;
    rightSizingRecommendationsModalState: RightSizingRecommendationsModalState;
    rightSizingRecommendationsState: RightSizingRecommendationsState;
    rightSizingRecommendationsStrategiesState: RightSizingRecommendationsStrategiesState;
  };
  serviceResourceDrawer: {
    resourceDrawerOpen: boolean;
    resourceDrawerServiceId?: string;
  };
  timeWindowIntervals: TimeWindowIntervals;
  appInsightsData: AppInsightsApiData;
  overviewMetricsData: OverviewMetricsData;
  uiElementsRect: UiElementsRect;
  pageTransition: number;
  metricsSupportAgentInfo: MetricsSupportAgentInfo;
};

type OverviewPageActions = {
  eventItemDrawer: {
    openEventItemDrawer: (event: EventGroup) => void;
    closeEventItemDrawer: () => void;
  };
  cost: {
    setRightSizingSummaryState: (state: RightSizingSummaryState) => void;
    setRightSizingFilters: (filters: Partial<RightSizingFiltersState>) => void;
    setRightSizingRecommendationModal: (
      state: RightSizingRecommendationsModalState
    ) => void;
    setRightSizingRecommendationsState: (
      state: RightSizingRecommendationsState
    ) => void;
    setRightSizingRecommendationsStrategiesState: (
      state: RightSizingRecommendationsStrategiesState
    ) => void;
  };
  insightsDrawer: {
    openInsightsDrawer: (content: InsightsDrawerContent) => void;
    closeInsightsDrawer: () => void;
    setInsightsDrawerContent: (content?: InsightsDrawerContent) => void;
  };
  serviceResourceDrawer: {
    openServiceResourceDrawer: (serviceId: string) => void;
    closeServiceResourceDrawer: () => void;
  };
  setTimeWindowIntervals: (intervals: TimeWindowIntervals) => void;
  setAppInsightsData: <T extends keyof AppInsightsApiData>(
    key: T,
    data: AppInsightsApiData[T]
  ) => void;
  setOverviewMetricsData: <T extends keyof OverviewMetricsData>(
    key: T,
    data: OverviewMetricsData[T]
  ) => void;
  setUiElementsRect: (payload: Partial<UiElementsRect>) => void;
  setPageTransition: (val: number) => void;
  setMetricsSupportAgentInfo: (
    payload: Partial<MetricsSupportAgentInfo>
  ) => void;
};

export type OverviewPageStore = OverviewPageState & OverviewPageActions;

export const useOverviewPageStore = create(
  immer<OverviewPageStore>((set) => ({
    ...initialState,
    eventItemDrawer: {
      ...initialState.eventItemDrawer,
      openEventItemDrawer: (event: EventGroup) => {
        set((state) => ({
          eventItemDrawer: {
            ...state.eventItemDrawer,
            eventItemDrawerOpen: true,
            currentEventItem: event,
          },
        }));
      },
      closeEventItemDrawer: () => {
        set((state) => ({
          eventItemDrawer: {
            ...state.eventItemDrawer,
            eventItemDrawerOpen: false,
            currentEventItem: undefined,
          },
        }));
      },
    },
    insightsDrawer: {
      ...initialState.insightsDrawer,
      openInsightsDrawer: (content: InsightsDrawerContent) => {
        set((state) => ({
          insightsDrawer: {
            ...state.insightsDrawer,
            insightsDrawerOpen: true,
            insightsDrawerContent: content,
          },
        }));
      },
      closeInsightsDrawer: () => {
        set((state) => ({
          insightsDrawer: {
            ...state.insightsDrawer,
            insightsDrawerOpen: false,
          },
        }));
      },
      setInsightsDrawerContent: (content?: InsightsDrawerContent) => {
        set((state) => ({
          insightsDrawer: {
            ...state.insightsDrawer,
            insightsDrawerContent: content,
          },
        }));
      },
    },
    serviceResourceDrawer: {
      ...initialState.serviceResourceDrawer,
      openServiceResourceDrawer: (serviceId: string) => {
        set((state) => ({
          serviceResourceDrawer: {
            ...state.serviceResourceDrawer,
            resourceDrawerOpen: true,
            resourceDrawerServiceId: serviceId,
          },
        }));
      },
      closeServiceResourceDrawer: () => {
        set((state) => ({
          serviceResourceDrawer: {
            ...state.serviceResourceDrawer,
            resourceDrawerOpen: false,
            resourceDrawerServiceId: undefined,
          },
        }));
      },
    },
    cost: {
      ...initialState.cost,
      setRightSizingSummaryState: (state: RightSizingSummaryState) => {
        set((prevState) => ({
          cost: {
            ...prevState.cost,
            rightSizingSummaryState: state,
          },
        }));
      },
      setRightSizingFilters: (filters: Partial<RightSizingFiltersState>) => {
        set((prevState) => ({
          cost: {
            ...prevState.cost,
            rightSizingFilters: {
              ...prevState.cost.rightSizingFilters,
              ...filters,
            },
          },
        }));
      },
      setRightSizingRecommendationModal: (
        modalState: RightSizingRecommendationsModalState
      ) => {
        set((prevState) => ({
          cost: {
            ...prevState.cost,
            rightSizingRecommendationsModalState: modalState,
          },
        }));
      },
      setRightSizingRecommendationsStrategiesState: (
        strategiesState: RightSizingRecommendationsStrategiesState
      ) => {
        set((state) => ({
          cost: {
            ...state.cost,
            rightSizingRecommendationsStrategiesState: {
              ...strategiesState,
            },
          },
        }));
      },
      setRightSizingRecommendationsState: (
        state: RightSizingRecommendationsState
      ) => {
        set((prevState) => ({
          cost: {
            ...prevState.cost,
            rightSizingRecommendationsState: state,
          },
        }));
      },
    },
    setTimeWindowIntervals: (intervals: TimeWindowIntervals) => {
      set(() => ({
        timeWindowIntervals: intervals,
      }));
    },
    setAppInsightsData: <T extends keyof AppInsightsApiData>(
      key: T,
      data: AppInsightsApiData[T]
    ) => {
      set((state) => ({
        appInsightsData: {
          ...state.appInsightsData,
          [key]: data,
        },
      }));
    },
    setOverviewMetricsData: <T extends keyof OverviewMetricsData>(
      key: T,
      data: OverviewMetricsData[T]
    ) => {
      set((state) => ({
        overviewMetricsData: {
          ...state.overviewMetricsData,
          [key]: data,
        },
      }));
    },
    setUiElementsRect: (payload: Partial<UiElementsRect>) => {
      set((state) => ({
        uiElementsRect: merge(cloneDeep(state.uiElementsRect), payload),
      }));
    },
    setPageTransition: (val: number) => {
      set(() => ({
        pageTransition: val,
      }));
    },
    setMetricsSupportAgentInfo: (payload: Partial<MetricsSupportAgentInfo>) => {
      set((state) => ({
        metricsSupportAgentInfo: {
          ...state.metricsSupportAgentInfo,
          ...payload,
        },
      }));
    },
  }))
);

export const resetOverviewPageStore = () => {
  useOverviewPageStore.setState({
    eventItemDrawer: {
      ...useOverviewPageStore.getState().eventItemDrawer,
      currentEventItem: undefined,
      eventItemDrawerOpen: false,
    },
    insightsDrawer: {
      ...useOverviewPageStore.getState().insightsDrawer,
      insightsDrawerOpen: false,
      insightsDrawerContent: undefined,
    },
    serviceResourceDrawer: {
      ...useOverviewPageStore.getState().serviceResourceDrawer,
      resourceDrawerOpen: false,
      resourceDrawerServiceId: undefined,
    },
    timeWindowIntervals: [...initialState.timeWindowIntervals],
    appInsightsData: {
      ...initialState.appInsightsData,
    },
    overviewMetricsData: {
      ...initialState.overviewMetricsData,
    },
    metricsSupportAgentInfo: {
      ...initialState.metricsSupportAgentInfo,
    },
  });
};
