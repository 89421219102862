import React, { createContext, PropsWithChildren, useContext } from "react";

import { LocalStorageItem } from "../utils/localStorageSettings";
import { EXPLORE_JOBS_FILTERS_KEY } from "../config/urlSearchParamsKeys";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedJobsViewQS = new LocalStorageItem(EXPLORE_JOBS_FILTERS_KEY);

const JobsPageFiltersLocalStorageContext =
  createContext<LocalStorageItem>(storedJobsViewQS);

export const JobsPageFiltersLocalStorageProvider: React.FC<
  PropsWithChildren
> = ({ children }) => (
  <JobsPageFiltersLocalStorageContext.Provider value={storedJobsViewQS}>
    {children}
  </JobsPageFiltersLocalStorageContext.Provider>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useJobsPageFiltersLocalStorageContext = (): LocalStorageItem =>
  useContext(JobsPageFiltersLocalStorageContext);
