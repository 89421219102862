import React, { ReactNode } from "react";
import { Divider } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { getPageLabel } from "./hooks/getPageLabel";
import { StyledText } from "./TableStyles";

type PageLabelParameters = Parameters<typeof getPageLabel>;

type PageLabelProps = {
  itemOffset: PageLabelParameters[0];
  pageSize: PageLabelParameters[1];
  listSize: PageLabelParameters[2];
  accessory?: ReactNode;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledVerticalDivider = styled(Divider).attrs({
  variant: "vertical",
  spacing: "0.5rem",
})`
  height: 1.5rem;
`;

export const PageLabel: React.FC<PageLabelProps> = ({
  itemOffset,
  pageSize,
  listSize,
  accessory,
}) => (
  <Container>
    <StyledText>{getPageLabel(itemOffset, pageSize, listSize)}</StyledText>
    {!!accessory && (
      <>
        <StyledVerticalDivider />
        {accessory}
      </>
    )}
  </Container>
);
