import { INCLUDE_TYPE_FILTER_OPERATOR } from "../filterSectionConstants";
import { ReliabilityFilterCategories } from "../../../ReliabilityTypes";

import { ViolationCountBy } from "@/generated/reliabilityApi";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

export const useGetAllFiltersFromUrl = () => {
  const filtersData = useFiltersDataInUrl();

  return Object.entries(filtersData).reduce<ReliabilityFilterCategories>(
    (acc, [filterCategory, filter]) => {
      const violationFilterCategory = filterCategory as ViolationCountBy;
      const relevantFilters = filter
        .filter(
          (f) => f.operator === INCLUDE_TYPE_FILTER_OPERATOR && f.values.length
        )
        .flatMap((f) => f.values);

      if (relevantFilters.length) {
        acc[violationFilterCategory] = relevantFilters;
      }
      return acc;
    },
    {}
  );
};
