import { useMemo } from "react";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { HEALTH_SETTINGS_TABS } from "@/pages/organization-settings/configurations/HealthSettingPage/constants";

export const useHealthSettingsPages = () => {
  const permissions = useHasPermissions();

  const tabs = useMemo(
    () =>
      HEALTH_SETTINGS_TABS.map((tab) => {
        return {
          label: tab.label,
          value: tab.value,
          disabled: tab.permissions?.some(
            (permission) => !permissions[permission]
          ),
          tooltip: tab.tooltip,
        };
      }),
    [permissions]
  );

  const firstAvailableTab = tabs.find((tab) => !tab.disabled)?.value;

  return { tabs, firstAvailableTab };
};
