import React, { useState } from "react";
import styled from "styled-components";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { VIOLATIONS_TABLE_ROW_HEIGHT } from "../../../violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";
import { IgnoreRule } from "../../../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import { IgnoreRulesTableOverrides } from "../../ignoreChecksStyles";

import {
  useFilterIgnoreRulesBySearchTerm,
  useViolationsTableColumns,
} from "./hooks/ignoreChecksTableHooks";
import { EmptyResultsMessage } from "./EmptyResultsMessage";

const Container = styled.div`
  width: 100%;
  && {
    ${IgnoreRulesTableOverrides};
    .searchContainer {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${muiColors.gray[200]};
      margin-bottom: 8px;
      .searchInput {
        font-size: 16px;
      }
    }
    .MuiDataGrid-overlayWrapper {
      height: 200px;
      width: 100%;
      overflow: hidden;
    }
  }
`;

const {
  ignoreChecks: { drawerTable: drawerTableAriaLabel },
} = reliabilityArialLabels;

type IgnoreChecksTableProps = {
  ignoreRules: IgnoreRule[];
};

export const IgnoreChecksTable: React.FC<IgnoreChecksTableProps> = ({
  ignoreRules,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const columns = useViolationsTableColumns();
  const rows = useFilterIgnoreRulesBySearchTerm(ignoreRules, searchTerm);

  return (
    <Container>
      <SearchField
        onSearch={setSearchTerm}
        placeholder={"Search..."}
        value={searchTerm}
        width={"220px"}
        classNames={{ container: "searchContainer", input: "searchInput" }}
      />
      <DataGridPro
        columns={columns}
        rows={rows}
        rowHeight={VIOLATIONS_TABLE_ROW_HEIGHT}
        hideFooter
        disableColumnMenu
        slots={{
          noRowsOverlay: () => (
            <EmptyResultsMessage type={"no search results"} />
          ),
        }}
        aria-label={drawerTableAriaLabel}
      />
    </Container>
  );
};
