import deepDiff from "../../../../shared/utils/deepDiff";

import { Change } from "./getK8sDiff";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default interface K8sConfig {
  name: string;
  type: string;
  newData: Record<string, string>;
  oldData: Record<string, string>;
  changes: Change[];
  action: string;
}

export const prepareK8sConfig = (k8sConfig: unknown): K8sConfig[] => {
  if (!Array.isArray(k8sConfig)) {
    return [];
  }
  return (k8sConfig as Omit<K8sConfig, "changes">[])
    .map((config) => ({
      ...config,
      changes: deepDiff(config.oldData, config.newData).map((c) => ({
        name: c.path.join("."),
        previous: c.from ? String(c.from) : null,
        current: c.to ? String(c.to) : null,
      })),
    }))
    .filter(({ changes }) => changes.length);
};
