import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  User,
  UsersApiApiV1UsersIdGetRequest,
  apiV1UsersIdGetUrl,
} from "../../../../../generated/auth";

const USER_BY_ID_PATH = "/users/:id";

const fetchUserById = async (
  apiClient: AxiosInstance,
  params: UsersApiApiV1UsersIdGetRequest
): Promise<User> => {
  const { data } = await apiClient.get(
    apiV1UsersIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetUserById = (
  params: UsersApiApiV1UsersIdGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [USER_BY_ID_PATH, params],
    () => fetchUserById(apiClient, params),
    {
      enabled,
    }
  );
};
