import React from "react";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import { blueBrand, darkBlueBrand } from "../../Colors";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";

import background from "./background.svg";
import logo from "./logo.png";

const Content = styled.div`
  display: grid;
  margin: 5rem auto;
  padding: 3rem;
  grid-template-columns: 1fr 1fr;
  height: 22rem;
  grid-template-rows: repeat(3, 1fr);
  width: 45rem;
  padding: 3rem;
  color: ${darkBlueBrand};
`;
const Logo = styled.img`
  max-height: 23rem;
  object-fit: contain;
  grid-row: 1 / 4;
`;
const Error404 = styled.div`
  font-family: Roboto Slab;
  font-size: 120px;
  font-weight: 400;
  line-height: 120px;
`;
const ErrorMessage = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  align-self: center;
`;
const StyledLinkButton = styled(Link)`
  padding: 0.8rem 1rem;
  background-color: ${blueBrand};
  color: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  width: max-content;
  text-decoration: none;
  height: max-content;
  align-self: end;
  :hover {
    opacity: 0.9;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-repeat: no-repeat;
    background-image: url(${background});
    background-origin: content-box;
    background-position: center;
    background-position-y: 7rem;
  }
`;

export const PATH_NOT_FOUND = "We can't seem to find what you're looking for";

const PathNotFound: React.FC = () => {
  const flags = useOverridableFlags();
  const isFlagsLoaded = flags && Object.keys(flags).length > 0;
  return isFlagsLoaded ? (
    <>
      <GlobalStyle />
      <Content>
        <Logo alt="Path not found" src={logo} />
        <Error404 data-e2e-selector={"path-not-found"}>404</Error404>
        <ErrorMessage>{PATH_NOT_FOUND}</ErrorMessage>

        <StyledLinkButton to={"/main/services"}>
          Back to Service Explorer
        </StyledLinkButton>
      </Content>
    </>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PathNotFound;
