import { muiTheme } from "@komodorio/design-system";

export const lightMuiTooltipStyle = {
  tooltip: {
    sx: {
      bgcolor: muiTheme.palette.background.paper,
      whiteSpace: "pre-line",
      boxShadow: "0px 2px 8px 1px rgba(22, 24, 31, 0.15)",
      "& .MuiTooltip-arrow": {
        color: muiTheme.palette.background.paper,
      },
      color: muiTheme.palette.text.primary,
    },
  },
};
