import { immer } from "zustand/middleware/immer";
import { create } from "zustand";

import { LogsViewerStore } from "./types";
import { initialState } from "./initialState";

export const useLogsViewerStore = create(
  immer<LogsViewerStore>((set) => ({
    ...initialState,
    setLiveTailEnabledBySystem: (isEnabled: boolean) => {
      set(() => ({
        isLiveTailEnabledBySystem: isEnabled,
      }));
    },
    setLiveTailEnabledByUser: (isEnabled: boolean) => {
      set(() => ({
        isLiveTailEnabledByUser: isEnabled,
      }));
    },
    setLiveTailEnabled: (isEnabled: boolean) => {
      set(() => ({
        isLiveTailEnabledByUser: isEnabled,
        isLiveTailEnabledBySystem: isEnabled,
      }));
    },
    setAnalyzingLogs(isAnalyzing) {
      set(() => ({
        isAnalyzingLogs: isAnalyzing,
      }));
    },
  }))
);

export const resetLogsViewerStore = () => {
  useLogsViewerStore.setState(initialState);
};
