import { Skeleton } from "@komodorio/design-system/deprecated";
import React, { memo } from "react";
import styled from "styled-components";

import { getRandomInt } from "../../../../../shared/utils/helpers";

import { Row } from "./DescribeItem";

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0.5rem;
`;

export const DescribeLoader: React.FC = memo(() => (
  <LoaderContainer aria-busy="true" role="progressbar">
    {Array.from(Array(20).keys()).map((i) => (
      <Row key={i}>
        <Skeleton height="1rem" width={`${getRandomInt(30, 90)}%`} />
        <Skeleton height="1rem" width={`${getRandomInt(30, 90)}%`} />
      </Row>
    ))}
  </LoaderContainer>
));
