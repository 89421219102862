import React, { useCallback, useState } from "react";
import { Button } from "@komodorio/design-system/deprecated";

import { AgentStatus } from "../../../shared/context/electron/getClusterStatus";
import { useUpdateInstallation } from "../../../shared/hooks/accounts-service/client/installations/useUpdateInstallation";

const finalAgentStatuses = new Set([AgentStatus.CONNECTED, AgentStatus.FAILED]);

export const UntrackButton: React.FC<{
  clusterName: string;
  agentStatus: AgentStatus | undefined;
}> = ({ clusterName, agentStatus }) => {
  const [executingUntrack, setExecutingUntrack] = useState(false);
  const { mutateAsync: updateIntegration } = useUpdateInstallation();

  const onClick = useCallback(async () => {
    setExecutingUntrack(true);
    const apiKey = await window.electronAPI?.untrackCluster?.(clusterName);
    if (apiKey) {
      await updateIntegration({
        id: apiKey,
        installationUpdateRequest: { isDeleted: true },
      });
    }
  }, [clusterName, updateIntegration]);

  const disabled =
    executingUntrack || !agentStatus || !finalAgentStatuses.has(agentStatus);

  return (
    <Button
      variant="secondary"
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      Untrack
    </Button>
  );
};
