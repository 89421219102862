import { IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (): IStep => {
  return {
    type: AvailabilityInvestigationCheckType.Dependencies,
    visible: true,
    visited: false,
    disabled: false,
  };
};
