import {
  IconButton,
  Popover,
  MenuList,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Ellipsis16 } from "@komodorio/design-system/icons";
import { capitalize, words } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import SortTitle, { Direction } from "../../common/ProcessList/SortTitle";
import { rowContainerStyle } from "../../monitorsView/monitorsConfiguration/common/styles";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import Checkbox from "../controls/Checkbox";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KubernetesResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ZIndex } from "../../../constants/zIndex";

const IconContainer = styled.div`
  float: right;
`;

const StyledCheckBox = styled.input`
  margin: 0.2rem 0.6rem 0.2rem 0.4rem;
  cursor: pointer;
`;

const SelectOption = styled.div`
  padding: 0.4rem 0.2rem;
  ${rowContainerStyle};
  cursor: pointer;
`;

const LastThContainer = styled.th`
  width: 1rem;
`;

export interface InspectionTableHeader {
  name: string;
  displayName?: string;
  hide?: boolean; // by default it doesn't hide the header
}

const getCloneHeadersAfterChange = (
  header: InspectionTableHeader,
  headers: InspectionTableHeader[]
) => {
  const index = headers.findIndex((h) => h.name === header.name);
  headers[index].hide = !header.hide;
  const headersClone: InspectionTableHeader[] = Object.assign([], headers);
  headersClone.filter((h) => !h.hide);
  return headersClone;
};

interface ResourceListTableHeadProps {
  headers: InspectionTableHeader[];
  sortDirection: Direction;
  setSortDirection: (direction: Direction) => void;
  sortColumn: string;
  setSortColumn: (column: string) => void;
  setTableHeaders: React.Dispatch<
    React.SetStateAction<InspectionTableHeader[]>
  >;
  kubernetesResource: KubernetesResource;
  includeColumnSelector?: boolean;
  allRowsSelection?: {
    allRowsSelected: boolean;
    onAllRowsSelection: () => void;
  };
}

const ResourceListTableHead: React.FC<ResourceListTableHeadProps> = ({
  headers,
  sortDirection,
  setSortDirection,
  sortColumn,
  setSortColumn,
  setTableHeaders,
  kubernetesResource,
  allRowsSelection,
  includeColumnSelector = true,
}) => {
  const [columnsSelectorOpen, setColumnsSelectorOpen] = useState(false);

  const getEventMetadata = useCallback(
    (column: string) => ({
      column,
      resource: kubernetesResource.NameInK8S,
    }),
    [kubernetesResource.NameInK8S]
  );

  const handleSortClick = useCallback(
    (column: string) => {
      if (column === sortColumn) {
        setSortDirection(
          sortDirection === Direction.up ? Direction.down : Direction.up
        );
        return;
      }
      dispatchEvent(
        AnalyticEvents.Inspection.Inspection_resource_list_sort_column,
        getEventMetadata(column)
      );

      setSortDirection(Direction.down);
      setSortColumn(column);
    },
    [
      getEventMetadata,
      setSortColumn,
      setSortDirection,
      sortColumn,
      sortDirection,
    ]
  );

  const setHeaderShowClick = useCallback(
    (header: InspectionTableHeader) => {
      dispatchEvent(
        AnalyticEvents.Inspection.Inspection_resource_list_sort_column,
        {
          ...getEventMetadata(header.name),
          action: header.hide ? "show" : "hide",
        }
      );
      setTableHeaders(getCloneHeadersAfterChange(header, headers));
    },
    [getEventMetadata, headers, setTableHeaders]
  );
  const toTitle = (name: string) => words(name).map(capitalize).join(" ");

  const activeHeaders = useMemo(
    () => headers.filter((h) => !h.hide),
    [headers]
  );

  const popoverContent = (
    <MenuList width="11rem">
      {headers.map((h, i) => (
        <SelectOption onClick={() => setHeaderShowClick(h)} key={i}>
          <StyledCheckBox
            type={"checkbox"}
            id={"id"}
            checked={!h.hide}
            readOnly
          />
          <Typography size="medium">
            {h.displayName ?? toTitle(h.name)}
          </Typography>
        </SelectOption>
      ))}
    </MenuList>
  );

  return (
    <thead>
      <tr data-testid="table-headers">
        {activeHeaders.map((h, index) => {
          return (
            <th key={index}>
              {h.name === "Checkbox" ? (
                allRowsSelection ? (
                  <Checkbox
                    id="all-rows-selector"
                    fieldName=""
                    checked={allRowsSelection.allRowsSelected}
                    handleCheckboxChange={allRowsSelection.onAllRowsSelection}
                  />
                ) : (
                  <div>{h.displayName ?? toTitle(h.name)}</div>
                )
              ) : (
                <SortTitle
                  title={h.displayName ?? toTitle(h.name)}
                  direction={sortDirection}
                  onClick={() => handleSortClick(h.name)}
                  active={h.name === sortColumn}
                />
              )}
            </th>
          );
        })}
        {includeColumnSelector && (
          <LastThContainer>
            <IconContainer>
              <Popover
                isOpen={columnsSelectorOpen}
                handleClose={() => setColumnsSelectorOpen(false)}
                content={popoverContent}
                containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
              >
                <IconButton
                  noBorder
                  icon={Ellipsis16}
                  onClick={() => setColumnsSelectorOpen((prev) => !prev)}
                />
              </Popover>
            </IconContainer>
          </LastThContainer>
        )}
      </tr>
    </thead>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceListTableHead;
