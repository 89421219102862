import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";

import { CostStrategy } from "../../../../types/costOptimizationTypes";

const labels: Record<CostStrategy, string> = {
  moderate:
    "Right-sizing recommendations will be balanced between cost and availability",
  aggressive:
    "Right-sizing recommendations will prioritize cost savings over availability",
  conservative:
    "Right-sizing recommendations will prioritize availability over cost savings",
};

export const SelectStrategyLabel: React.FC<{ strategy: CostStrategy }> = ({
  strategy,
}) => (
  <>
    <Typography
      color={muiColors.gray[600]}
      fontSize={"14px"}
      variant={"subtitle1"}
    >
      {labels[strategy]}
    </Typography>
  </>
);
