import { AxiosResponse } from "axios";

import { EntityResourceResponse } from "@/generated/addonsApi";

export const selectWorkflowDag = (
  json: AxiosResponse<EntityResourceResponse, unknown>
) => {
  return {
    type: json?.data?.type,
    data: json?.data?.data,
  };
};
