import {
  Code,
  TextContainer,
  TextElementContainer,
} from "../../../violationDrawerStyles";

export const whyDescriptionText =
  "A Horizontal Pod Autoscaler (HPA) with a minimum replica count of 1 does not " +
  "provide redundancy, making the application vulnerable to pod failures and " +
  "creating a Single Point Of Failure (SPOF)";

export const getWhatDescription = () => (
  <TextElementContainer>
    <TextContainer>The HPA controller</TextContainer>
    <Code>spec.minReplicas</Code>
    <TextContainer>is set to 1.</TextContainer>
  </TextElementContainer>
);

export const getWhyDescription = () => (
  <TextContainer>{whyDescriptionText}</TextContainer>
);

export const howDescription = () => (
  <TextElementContainer>
    <TextContainer>Increase the </TextContainer>
    <Code>spec.minReplicas</Code>
    <TextContainer>to allow for at least 2 minReplicas</TextContainer>
  </TextElementContainer>
);
