export const getStringAfterLastDelimiter = (
  inputString: string,
  delimiter: string
): string => {
  const lastDelimiterIndex = inputString.lastIndexOf(delimiter);

  if (lastDelimiterIndex === -1) {
    return inputString;
  }

  return inputString.slice(lastDelimiterIndex + delimiter.length);
};
