import { useMemo } from "react";

import { METRICS_NODE_MEMORY_ENDPOINT } from "../../../../../../../../../../../../shared/hooks/metrics-api/requestResponseMaps";
import {
  MetricsWithAggregations,
  useMetricsWithAggregations,
  useNodesMetricsWithAggregations,
} from "../../../../../../../../../../../Metrics/useNodeMetrics";
import { useFetchWorkloadMetricsData } from "../../../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/hooks/fetchWorkloadMetricsHooks";
import { parseKomodorUid } from "../../../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetEpochsFromViolation } from "../../../../../../hooks/useGetEpochsFromViolation";

type MetricsDataParams = {
  nodeName: string;
  komodorUid: string | undefined;
};

type Response = {
  data: MetricsWithAggregations | undefined;
  loading: boolean;
  error: unknown | null;
};

export type NoisyNeighborMetrics = {
  nodeUsage: Response;
  nodeCapacity: Response;
  workloadUsage: Response;
};

export const useMetricsData = ({
  nodeName,
  komodorUid,
}: MetricsDataParams): NoisyNeighborMetrics => {
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();
  const { cluster, namespace, name, kind } =
    parseKomodorUid(komodorUid ?? "") ?? {};

  const params = useMemo(() => {
    return {
      nodeName,
      clusterName: cluster ?? "",
      pause: !cluster,
      fromEpoch,
      toEpoch,
    };
  }, [cluster, fromEpoch, nodeName, toEpoch]);

  const nodeParams = useMemo(() => {
    return {
      ...params,
      endTimestamp: toEpoch,
    };
  }, [params, toEpoch]);

  const workloadParams = useMemo(() => {
    return {
      ...params,
      namespace: namespace ?? "",
      komodorServiceName: name ?? "",
      komodorServiceKind: kind ?? "",
    };
  }, [params, namespace, name, kind]);

  const nodeUsage = useNodesMetricsWithAggregations({
    ...nodeParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    type: "usage",
  });
  const nodeCapacity = useNodesMetricsWithAggregations({
    ...nodeParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    type: "capacity",
  });

  const {
    data: workloadUsage,
    isLoading,
    error,
  } = useFetchWorkloadMetricsData({
    requestParams: workloadParams,
    type: "memory",
  });

  const formattedWorkloadData = useMetricsWithAggregations(workloadUsage?.data);

  return {
    nodeUsage,
    nodeCapacity,
    workloadUsage: { data: formattedWorkloadData, loading: isLoading, error },
  };
};
