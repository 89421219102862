import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";

import { User } from "@/generated/auth";
import { useUpdateUser } from "@/shared/hooks/auth-service/client/users/useUpdateUser";

export const RestoreUserModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  user: User;
  refreshUsers: () => void;
}> = ({ open, handleClose, user, refreshUsers }) => {
  const { mutateAsync: restoreUser, isLoading: restoreLoading } =
    useUpdateUser();

  const handleRestore = async () => {
    await restoreUser({
      id: user.id,
      updateUserRequest: {
        deletedAt: "",
      },
    });
    refreshUsers();
    handleClose();
  };
  return (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Restore User</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to restore this user?
          <br />
          <br />
          Name: <b>{user.displayName}</b>
          <br />
          Email: <b>{user.email}</b>
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleRestore}
          disabled={restoreLoading}
        >
          {restoreLoading ? "Restoring..." : "Restore"}
        </Button>
      </ModalActions>
    </Modal>
  );
};
