import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import MuiTypography from "@mui/material/Typography";
import { AlertTriangleOutlined16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ResourceType } from "../../../../types/appViewsResourcesTypes";
import { useGetRouteForResourceType } from "../../../../hooks/overviewPageHooks";
import { MouseEnterPopover } from "../../../../../../../../shared/components/MouseEnterPopover/MouseEnterPopover";
import { appViewAriaLabels } from "../../../../appViewAriaLabels";

import { ResourceTypeToName } from "./constants/resourceTypeToName";

const StyledLink = styled(Link)<{ $disabled?: boolean }>`
  &:any-link {
    display: flex;
    gap: 5px;
    align-items: center;
    color: ${palette.darkBlue["500"]};
    text-decoration: none;
    ${({ $disabled }) => $disabled && "pointer-events: none;"}
`;

const StyledPopoverContent = styled.div`
  padding: 12px;
  & > p:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const {
  overviewPage: {
    resourcesInventory: { unhealthyResources: unhealthyResourcesAriaLabel },
  },
} = appViewAriaLabels;

type UnhealthyItemsProps = {
  type: ResourceType;
  count: number;
};

export const UnhealthyItems: React.FC<UnhealthyItemsProps> = ({
  count,
  type,
}) => {
  const getRouteForResourceType = useGetRouteForResourceType();
  const unhealthyResourceRoute = getRouteForResourceType(type, true);

  const name = ResourceTypeToName[type];
  const verb = count === 1 ? "is" : "are";
  const text = `${count} ${name} ${verb} unhealthy`;
  const CTA = `View ${name}`;

  const triggerContent = (
    <StyledLink
      to={unhealthyResourceRoute ?? "/"}
      $disabled={!unhealthyResourceRoute}
      aria-label={unhealthyResourcesAriaLabel}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography>{count}</Typography>
      <AlertTriangleOutlined16 color={palette.pink["500"]} />
    </StyledLink>
  );

  const popoverContent = (
    <StyledPopoverContent>
      <MuiTypography variant={"body3"} color={"text.primary"}>
        {text}
      </MuiTypography>

      <MuiTypography variant={"body3"} color={"text.primary"}>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          to={unhealthyResourceRoute ?? "/"}
          $disabled={!unhealthyResourceRoute}
        >
          {CTA}
        </StyledLink>
      </MuiTypography>
    </StyledPopoverContent>
  );

  return (
    <MouseEnterPopover
      triggerElContent={triggerContent}
      popoverContent={popoverContent}
    />
  );
};
