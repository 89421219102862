import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isUndefined } from "lodash";

import {
  AddEditIgnoreRuleDialogAction,
  GroupsState,
  GroupByOptions,
  ReliabilityStore,
  ReliabilityNotification,
} from "../ReliabilityTypes";
import { initialGroupState } from "../constants/reliabilityConstants";

import { initialState } from "./initialState";

import { getInitialPaginationModel } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";

export const useReliabilityStore = create(
  immer<ReliabilityStore>((set) => ({
    ...initialState,
    setTopSectionIsShown: (isShown: boolean) => {
      set(() => ({
        topSectionIsShown: isShown,
      }));
    },
    setGroupExpandedState: (groupBy: GroupByOptions, groupId: string) => {
      set((state) => {
        const groupsState = state.violationsState.groups;
        const group = groupsState[groupBy][groupId];
        const groupData = isUndefined(group)
          ? {
              isExpanded: true,
              ...initialGroupState,
              paginationModel: getInitialPaginationModel(groupBy),
            }
          : { ...group, isExpanded: !group.isExpanded };

        state.violationsState.groups = {
          ...groupsState,
          [groupBy]: {
            ...groupsState[groupBy],
            [groupId]: groupData,
          },
        };
      });
    },
    setGroupState: ({ uid, groupBy, newState }: GroupsState) => {
      set((state) => {
        const groupsState = state.violationsState.groups;
        const group = groupsState[groupBy][uid];

        state.violationsState.groups = {
          ...groupsState,
          [groupBy]: {
            ...groupsState[groupBy],
            [uid]: { ...group, ...newState },
          },
        };
      });
    },
    setUngroupedTotalViolationsCount: (count: number | undefined) => {
      set((state) => {
        state.violationsState.ungroupedTotalViolationsCount = count;
      });
    },
    setAddEditIgnoreRuleDialogState: (
      newState: AddEditIgnoreRuleDialogAction
    ) => {
      set((state) => {
        state.addEditIgnoreRuleDialogState = {
          ...initialState.addEditIgnoreRuleDialogState,
          isOpen: newState.isOpen,
          ...(newState.checkType && { checkType: newState.checkType }),
          ...(newState.existingIgnoreRule && {
            existingIgnoreRule: newState.existingIgnoreRule,
          }),
        };
      });
    },
    setReliabilityNotification: (notification: ReliabilityNotification) => {
      set((state) => {
        return {
          reliabilityNotifications: [
            ...state.reliabilityNotifications,
            notification,
          ],
        };
      });
    },
    removeReliabilityNotification: (notification: ReliabilityNotification) => {
      set((state) => {
        return {
          reliabilityNotifications: state.reliabilityNotifications.filter(
            (message) => message.message !== notification.message
          ),
        };
      });
    },
    setHasSubscribedToAppViewChanges: (hasSubscribed: boolean) => {
      set((state) => {
        state.hasSubscribedToAppViewChanges = hasSubscribed;
      });
    },
  }))
);

export const resetReliabilityStore = () => {
  useReliabilityStore.setState({
    violationsState: initialState.violationsState,
    addEditIgnoreRuleDialogState: initialState.addEditIgnoreRuleDialogState,
    reliabilityNotifications: initialState.reliabilityNotifications,
  });
};
