import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

const StyledTypography = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

export const OverviewPageTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <StyledTypography variant="h4" color={muiColors.gray[800]}>
      {title}
    </StyledTypography>
  );
};
