import { CostRightSizingSummary } from "../../../../generated/metricsApi";
import { getServiceKind } from "../../../appView/sections/AppView/__tests__/mocks/overviewPage/testUtils";

import { getRandRange } from "./getRandRange";

const getRecommendation = (current: number, multiplier: number) => {
  if (Math.random() < 0.2) return current + 1;
  return Math.max(
    current - getRandRange(1, Math.random() * multiplier),
    current / 3
  );
};

export const generateMockCostSummaryRows = (
  rowsLength: number,
  clusterNames?: string[] | undefined
) => {
  return Array.from({
    length: rowsLength,
  }).map<CostRightSizingSummary>((_, i) => {
    const currentCpu = Math.random() * 10 + 1;
    const currentMemory = Math.random() * 10000;
    const clusterName = clusterNames
      ? clusterNames[i % clusterNames.length]
      : `cluster-name-${i}`;

    const isRand = Math.random() > 0.7;

    return {
      clusterName,
      containerName: `container-name-${i}`,
      namespace: `namespace-extremely-long-${i}`,
      service: `service-name-extremely-long-${i}`,
      komodorServiceId: `komodor-service-id-${i}`,
      cpuRequestCores: currentCpu,
      cpuRecommendation: getRecommendation(currentCpu, 10),
      memoryRequestsMiB: currentMemory,
      memoryRecommendation: getRecommendation(currentMemory, 10000),
      efficiency: Math.random() * 100,
      potentialSaving: isRand ? 0.9 : Math.random() * 100000,
      potentialSavingAggressive: (Math.random() + 2) * 100000,
      potentialSavingConservative: (Math.random() + 1) * 100000,
      potentialSavingModerate: Math.random() * 100000,
      id: `${i}`,
      optimizationScore: Math.random() * 100,
      // total cost
      cpuCost: isRand ? 0.4 : Math.random() * 100,
      memoryCost: isRand ? 0.5 : Math.random() * 100,
      //
      komodorServiceKind: getServiceKind(i),
    };
  });
};
