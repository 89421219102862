export enum ResourceResultType {
  KomodorService = "KOMODOR_SERVICE",
  RawResource = "RAW_RESOURCE",
}

export interface IResourceDrawerByData {
  cluster: string;
  namespace?: string;
  resourceType: string;
  resourceName: string;
  buildPreloadResource?: boolean;
  additionalData?: {
    id?: string;
    controlledBy?: string;
    calculatedStatus?: string;
    isCustomResource?: boolean;
  };
}

export interface IResourceDrawerByServiceId {
  serviceId: string;
}
