import React, { useCallback, useMemo, useState } from "react";
import { muiPalette } from "@komodorio/design-system";
import styled from "styled-components";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 1rem;
  min-width: 550px;
`;

const SettingsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const MultiSelectContainer = styled.div`
  .MuiPopover-root {
    max-height: 300px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const AccountsChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto;
  gap: 4px;
`;

const Footer = styled.div`
  display: flex;
  direction: rtl;
  gap: 1rem;
`;

interface ManagementModalProps {
  open: boolean;
  handleClose: () => void;
  accounts: MuiSelectionOption<string>[];
  currentFavoriteAccount: MuiSelectionOption<string>[];
  currentShouldShowDisabledAccounts: boolean;
  setShouldShowDisabledAccounts: (value: string) => void;
  setFavoriteAccounts: (value: string) => void;
}

export const ManagementModal: React.FC<ManagementModalProps> = ({
  open,
  handleClose,
  accounts,
  currentFavoriteAccount,
  currentShouldShowDisabledAccounts,
  setFavoriteAccounts,
  setShouldShowDisabledAccounts,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState<
    MuiSelectionOption<string>[]
  >(currentFavoriteAccount);
  const [showDisabledAccounts, setShowDisabledAccounts] = useState(
    currentShouldShowDisabledAccounts
  );

  const handleDelete = useCallback(
    (label: string) => {
      const newSelectedAccounts = [...selectedAccounts].filter(
        (account) => account.label !== label
      );
      setSelectedAccounts(newSelectedAccounts);
    },
    [selectedAccounts]
  );

  const accountsChips = useMemo(
    () =>
      selectedAccounts.map((account) => (
        <Chip
          key={account.value}
          label={account.label}
          onDelete={() => handleDelete(account.label)}
        />
      )),
    [handleDelete, selectedAccounts]
  );

  const onSave = () => {
    setShouldShowDisabledAccounts(JSON.stringify(showDisabledAccounts));
    setFavoriteAccounts(
      JSON.stringify(selectedAccounts.map((account) => account.label))
    );
    handleClose();
  };

  const handleAccountsChange = (options: MuiSelectionOption<string>[]) =>
    setSelectedAccounts(options);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container>
        <Typography variant="h2">Manage your accounts</Typography>
        <SettingsContainer>
          <MultiSelectContainer>
            <AdvancedMultiSelect
              options={accounts}
              placeholder="Choose your favorite accounts"
              width="300px"
              onChange={handleAccountsChange}
              value={selectedAccounts}
            />
          </MultiSelectContainer>
          <SwitchContainer>
            <Switch
              checked={showDisabledAccounts}
              onChange={() => setShowDisabledAccounts(!showDisabledAccounts)}
            />
            <Typography variant="body2" color={muiPalette.text?.secondary}>
              Show disabled accounts
            </Typography>
          </SwitchContainer>
        </SettingsContainer>
        <Typography variant="h3">
          {selectedAccounts.length > 0
            ? "Your favorite accounts:"
            : "No favorite accounts selected"}
        </Typography>
        <AccountsChipsContainer>{accountsChips}</AccountsChipsContainer>
        {showDisabledAccounts && (
          <Alert variant="standard" severity="warning">
            Caution: Enabling disabled accounts may impact the performance of
            the account switch flow. Turning it on could lead to slower response
            times and potential delays during the account switch process. Please
            consider the trade-offs before making this change.
          </Alert>
        )}
        <Footer>
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Footer>
      </Container>
    </Dialog>
  );
};
