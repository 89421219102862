import styled from "styled-components";
import { Refresh16 } from "@komodorio/design-system/icons";

export const LoadingSpinnerIcon = styled(Refresh16)`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
