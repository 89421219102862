export interface ClusterProps {
  name: string;
  displayName: string;
  isTracked: boolean;
  trackedAt: number | undefined;
  isLocal: boolean;
  index: number;
}

export const isTrackClusterAvailable = (cluster: ClusterProps): boolean => {
  return false;
  // return cluster.index !== undefined;
};

export interface DesktopUserClusters {
  tracked: ClusterProps[];
  untracked: ClusterProps[];
}

export const getDesktopUserClusters = async (): Promise<
  DesktopUserClusters | undefined
> => {
  const ipcResult = await window.electronAPI?.getClustersData();
  return ipcResult;
};
