import React, { useMemo, useState } from "react";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  createColumnHelper,
  Table as ReactTable,
  Row,
  OnChangeFn,
  SortingState,
} from "@tanstack/react-table";

import Checkbox from "../../../components/common/controls/Checkbox";
import SortTitle, {
  Direction,
} from "../../../components/common/ProcessList/SortTitle";
import { Dictionary } from "../../types/Dictionary";

import { Container } from "./internal/Container";
import { Table as StyledTable } from "./internal/Table";
import { Tr } from "./internal/Tr";
import { TableFooter } from "./internal/TableFooter";
import { Pagination } from "./internal/Pagination/Pagination";
import { PageSize } from "./internal/PageSize";

const NO_OP = () => {
  return;
};

interface TableProps {
  data: Dictionary<string>[];
  columns: { header: string; accessor: string }[];
  rowSelection?: {
    selectedRows: Record<string, boolean>;
    setSelectedRows: OnChangeFn<Record<string, boolean>>;
  };
  pageSize?: 10 | 25 | 50;
  onRowClickOverride?: (row: Dictionary<string>) => void;
  rowIdCalculationOverride?: (row: Dictionary<string>, index: number) => string;
  sortable?: boolean;
  fullWidth?: boolean;
  ariaLabel?: string;
}

/**
 * Dumb table component – any data it displays must be supplied through props. <br />
 * Do not add any data-fetching inside it! <br />
 * Also, do not add any component (e.g. drawer) inside it!
 */
export const Table: React.FC<TableProps> = ({
  data,
  columns,
  rowSelection,
  pageSize: initialPageSize,
  onRowClickOverride,
  rowIdCalculationOverride,
  sortable = true,
  fullWidth = true,
  ariaLabel,
}) => {
  const shouldUsePagination = !!initialPageSize;
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = useMemo(
    () => createColumnHelper<Dictionary<string>>(),
    []
  );
  const columnDefinitions = useMemo(
    () => [
      ...(rowSelection
        ? [
            {
              id: "select",
              header: ({
                table,
              }: {
                table: ReactTable<Dictionary<string>>;
              }) => (
                <Checkbox
                  id={"all-rows-selection-checkbox"}
                  fieldName=""
                  handleCheckboxChange={table.getToggleAllRowsSelectedHandler()}
                  checked={table.getIsAllRowsSelected()}
                />
              ),
              cell: ({ row }: { row: Row<Dictionary<string>> }) => (
                <Checkbox
                  id={`row-${row.id}-selection-checkbox`}
                  fieldName=""
                  handleCheckboxChange={row.getToggleSelectedHandler()}
                  checked={row.getIsSelected()}
                />
              ),
            },
          ]
        : []),
      ...columns.map((column) =>
        columnHelper.accessor(column.accessor, {
          header: ({ header }) =>
            sortable ? (
              <SortTitle
                title={column.header}
                active={!!header.column.getIsSorted()}
                onClick={header.column.getToggleSortingHandler() ?? NO_OP}
                direction={
                  {
                    asc: Direction.up,
                    desc: Direction.down,
                  }[header.column.getIsSorted() as string] ?? Direction.up
                }
              />
            ) : (
              <> {column.header} </>
            ),
          cell: (info) => info.getValue(),
        })
      ),
    ],
    [columnHelper, columns, rowSelection, sortable]
  );

  const table = useReactTable({
    data,
    columns: columnDefinitions,
    state: {
      rowSelection: rowSelection?.selectedRows,
      ...(sortable ? { sorting } : {}),
    },
    onRowSelectionChange: rowSelection?.setSelectedRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: shouldUsePagination
      ? getPaginationRowModel()
      : undefined,
    onSortingChange: sortable ? setSorting : undefined,
    getSortedRowModel: sortable ? getSortedRowModel() : undefined,
    getRowId: rowIdCalculationOverride,
    autoResetPageIndex: false,
  });

  return (
    <Container
      style={{ width: fullWidth ? "100%" : undefined }}
      aria-label={ariaLabel}
    >
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              onClick={
                onRowClickOverride
                  ? () => {
                      onRowClickOverride(row.original);
                    }
                  : rowSelection
                  ? row.getToggleSelectedHandler()
                  : undefined
              }
              clickable={!!onRowClickOverride || !!rowSelection}
              aria-label={ariaLabel ? `${ariaLabel} row` : undefined}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </Tr>
          ))}
        </tbody>
      </StyledTable>
      {shouldUsePagination && (
        <TableFooter>
          <PageSize
            value={table.getState().pagination.pageSize}
            onChange={(pageSize) => table.setPageSize(pageSize)}
          />
          <Pagination
            page={table.getState().pagination.pageIndex}
            setPage={table.setPageIndex}
            totalPages={table.getPageCount()}
          />
        </TableFooter>
      )}
    </Container>
  );
};
