import React from "react";
import styled from "styled-components";

import { RightSizingGridContainer } from "../../styles/CostOptimizationStyledComponents";

import { SelectStrategy } from "./SelectStrategy/SelectStrategy";
import { PotentialSavings } from "./PotentialSavings/PotentialSavings";
import { PotentialCostComparison } from "./PotentialCostComparison/PotentialCostComparison";

const SummaryCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  && > div {
    flex-grow: 1;
    min-height: 126px;
  }
`;

export const SummaryCards: React.FC = () => {
  return (
    <RightSizingGridContainer>
      <SummaryCardsContainer>
        <PotentialSavings />
        <PotentialCostComparison />
        <SelectStrategy />
      </SummaryCardsContainer>
    </RightSizingGridContainer>
  );
};
