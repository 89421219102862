import { Skeleton } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

const SkeletonContainer = styled.div`
  padding: 1rem;
  display: grid;
  row-gap: 3rem;
`;
const SkeletonItem = styled.div`
  display: grid;
  row-gap: 1rem;
`;
export const IssueSkeleton = (): JSX.Element => {
  return (
    <SkeletonContainer>
      <SkeletonItem>
        <Skeleton height={"1.5rem"} width={"20%"} />
        <Skeleton height={"1.5rem"} width={"60%"} />
        <Skeleton height={"1.5rem"} width={"80%"} />
      </SkeletonItem>
      <SkeletonItem>
        <Skeleton height={"1.5rem"} width={"30%"} />
        <Skeleton height={"1.5rem"} width={"60%"} />
        <Skeleton height={"1.5rem"} width={"90%"} />
      </SkeletonItem>
      <SkeletonItem>
        <Skeleton height={"1.5rem"} width={"20%"} />
        <Skeleton height={"1.5rem"} width={"80%"} />
        <Skeleton height={"1.5rem"} width={"90%"} />
      </SkeletonItem>
    </SkeletonContainer>
  );
};
