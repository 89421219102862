import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacCustomK8sActionsIdDeleteUrl,
  RbacCustomK8sAction,
  RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest,
} from "../../../../../generated/auth";

const RBAC_POLICY_ACTIONS_PATH = "/rbac-custom-k8s-actions/:id";

const deleteRbacCustomK8sAction = async (
  apiClient: AxiosInstance,
  params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest
): Promise<RbacCustomK8sAction> => {
  const { data } = await apiClient.delete(
    apiV1RbacCustomK8sActionsIdDeleteUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useDeleteRbacCustomK8sAction = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [RBAC_POLICY_ACTIONS_PATH],
    (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdDeleteRequest) =>
      deleteRbacCustomK8sAction(apiClient, params)
  );
};
