import { Container, Probe } from "kubernetes-types/core/v1.d";
import React, { useMemo } from "react";

import { DescribeTextItem } from "../../common/DescribeItem";
import { DescribeSectionDivider } from "../../common/DescribeSectionDivider";

type PartialProbeFields = {
  field: keyof Probe;
  display: string;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const PROBE_FIELDS: PartialProbeFields[] = [
  { field: "initialDelaySeconds", display: "Initial Delay Seconds" },
  { field: "timeoutSeconds", display: "Timeout Seconds" },
  { field: "periodSeconds", display: "Period Seconds" },
  { field: "successThreshold", display: "Success Threshold" },
  { field: "failureThreshold", display: "Failure Threshold" },
];

const getProbeFields = (probe: Probe, probeName: string) => {
  return PROBE_FIELDS.map(({ field, display }) => {
    const value = probe[field];
    if (value) {
      return (
        <DescribeTextItem
          key={field + probeName}
          name={display}
          value={String(value)}
        />
      );
    }
    return null;
  });
};

type ProbeFieldProps = {
  probe?: Probe;
  name: string;
};

const ProbeField: React.FC<ProbeFieldProps> = ({ probe, name }) => {
  return useMemo(() => {
    if (!probe) return null;
    return (
      <>
        <DescribeSectionDivider title={name.toUpperCase()} />
        {getProbeFields(probe, name)}
      </>
    );
  }, [name, probe]);
};

const PROBE_TYPES = {
  LIVENESS_PROBE: "Liveness Probe",
  READINESS_PROBE: "Readiness Probe",
};

export const ContainerProbes: React.FC<{ container: Container }> = ({
  container,
}) => {
  return container.readinessProbe || container.livenessProbe ? (
    <>
      <ProbeField
        probe={container.livenessProbe}
        name={PROBE_TYPES.LIVENESS_PROBE}
      />
      <ProbeField
        probe={container.readinessProbe}
        name={PROBE_TYPES.READINESS_PROBE}
      />
    </>
  ) : null;
};
