import { PathExtractor, PrefixTree, prefixTree } from "./toPathString";

const extractor = Symbol();
type StringPrefixTree = {
  [letter: string]: StringPrefixTree;
  [extractor]?: PathExtractor;
};
const createStringPrefixTree = (tree: PrefixTree) => {
  const root: StringPrefixTree = {};
  for (const key of Object.keys(tree)) {
    let node = root;
    for (let i = 0; i < key.length - 1; i++) {
      node[key[i]] = node[key[i]] ?? {};
      node = node[key[i]];
    }
    const nodeValue = tree[key];
    node[key[key.length - 1]] =
      nodeValue instanceof Function
        ? { [extractor]: nodeValue }
        : { ".": createStringPrefixTree(nodeValue) };
  }
  return root;
};

const stringPrefixTree = createStringPrefixTree(prefixTree);
const resolveStringPath = (stringPath: string): string | null => {
  let node = stringPrefixTree;
  let i = 0;
  while (node) {
    const extract = node[extractor];
    if (extract && (i === stringPath.length || stringPath[i] === ".")) {
      return extract(stringPath.substring(i + 1));
    }
    if (i === stringPath.length) return null;
    if ("*" in node) {
      if (stringPath[i] === ".") {
        node = node["*"];
      } else {
        i += 1;
      }
    } else {
      node = node[stringPath[i]];
      i += 1;
    }
  }
  return null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default resolveStringPath;
