import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { CollapsableContainer } from "@komodorio/design-system/komodor-ui";

import { useInvestigationModeStore } from "../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../shared/store/investigationModeStore/investigationModeSelectors";
import Resource from "../../ResourceView/resources";
import { BaseWorkflowCheck } from "../../monitorsView/useWorkflowsRuns";

import { messageEnrichers } from "./enrichersStore";
import { EnrichedErrorMessage } from "./EnrichedErrorMessage";

const ReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ShowHideMessageStyle = {
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    color: muiColors.gray[700],
  },
};

const ReasonsSection: React.FC<{
  errorMessages: string[];
  reasons: string[];
  titleExtraText?: React.ReactNode;
}> = ({ errorMessages, reasons, titleExtraText }) => {
  if (errorMessages.length === reasons.length) {
    return (
      <>
        {errorMessages.map((message, index) => (
          <span key={`reason-${index}`}>
            <ReasonContainer>
              <Typography variant={"h5"}>
                {reasons[index]}
                {titleExtraText}
              </Typography>
              <Typography variant={"body2"}>{message}</Typography>
            </ReasonContainer>
            {index < errorMessages.length - 1 && <br />}
          </span>
        ))}
      </>
    );
  }
  if (reasons.length > errorMessages.length) {
    return (
      <ReasonContainer>
        <Typography variant={"h5"}>
          {reasons.join(", ")}
          {titleExtraText}
        </Typography>
        {errorMessages.length > 0 && (
          <Typography variant={"body2"}>{errorMessages[0]}</Typography>
        )}
      </ReasonContainer>
    );
  }
  return (
    <ReasonContainer>
      <Typography variant={"h5"}>
        {reasons[0]}
        {titleExtraText}
      </Typography>
      {errorMessages.map((message, index) => (
        <Typography variant={"body2"} key={index}>
          {message}
        </Typography>
      ))}
    </ReasonContainer>
  );
};

export interface EnrichedMessageSectionProps {
  errorMessages: string[];
  reasons: string[];
  agentId: string | undefined;
  cluster: string;
  namespace: string;
  onClick?: (resource: Resource) => void;
  checks?: BaseWorkflowCheck[];
  titleExtraText?: React.ReactNode;
}

export const EnrichedMessageSection: React.FC<EnrichedMessageSectionProps> = ({
  errorMessages,
  reasons,
  agentId,
  cluster,
  namespace,
  onClick,
  checks,
  titleExtraText,
}) => {
  const issue = useInvestigationModeStore(issueSelector);
  const [showFullMessage, setShowFullMessage] = React.useState(false);

  const enrichedMessage = useMemo(
    () =>
      errorMessages?.length > 0
        ? messageEnrichers.enrich(errorMessages[0])
        : {
            messageTokens: [],
            originalMessage: "",
          },
    [errorMessages]
  );
  const showEnrichedMessage =
    enrichedMessage.messageTokens.length > 0 &&
    enrichedMessage.hasPrecedenceOverAIInvestigation;

  return (
    <div>
      {showEnrichedMessage && (
        <>
          <EnrichedErrorMessage
            enrichedMessage={enrichedMessage}
            agentId={agentId ?? ""}
            cluster={cluster}
            namespace={namespace}
            onClick={onClick}
            issue={issue}
            checks={checks}
          />
          <br />
        </>
      )}
      {(showFullMessage || !showEnrichedMessage) && (
        <CollapsableContainer>
          <ReasonsSection
            reasons={reasons}
            errorMessages={errorMessages}
            titleExtraText={titleExtraText}
          />
        </CollapsableContainer>
      )}
      {showEnrichedMessage && (
        <Typography
          variant={"body3"}
          color={muiColors.gray[500]}
          sx={{
            ...ShowHideMessageStyle,
            marginTop: showFullMessage ? "8px" : 0,
          }}
          onClick={() => setShowFullMessage(!showFullMessage)}
        >
          {showFullMessage ? "Hide" : "Show"} original message
        </Typography>
      )}
    </div>
  );
};
