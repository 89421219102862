import React from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodPhaseGroup from "../../../EventGroup/groupedPodEvent/PodPhaseGroup";

import { Section } from "./styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { StatusConditions } from "./StatusConditions";

export const ConditionsContainer = styled.div`
  display: grid;
  grid-template-columns: 164px 64px 320px;
  column-gap: 16px;
  justify-content: start;
  padding-top: 16px;
  /* Target the first child element and make it bold */
  & > :nth-child(-n + 3) {
    font-weight: bold;
  }
`;

export const LatestConditions: React.FC<{ eventGroup: PodPhaseGroup }> = ({
  eventGroup,
}) => {
  const statusConditions = eventGroup.statusConditions as {
    type: string;
    status: string;
    message?: string;
    lastTransitionTime: Date;
  }[];
  return (
    <Section>
      <StatusConditions
        title="Latest Conditions"
        statusConditions={statusConditions}
      />
    </Section>
  );
};
