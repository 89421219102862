import React from "react";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiTheme } from "@komodorio/design-system";

export const createNameComponent =
  ({ email, displayName }: { email?: string; displayName?: string }) =>
  (props: MenuItemProps) =>
    (
      <MenuItem
        disabled
        divider
        sx={{
          "&.MuiMenuItem-divider": {
            marginBottom: "8px",
          },
          "&.Mui-disabled": {
            opacity: 1,
          },
        }}
        {...props}
      >
        <Stack direction="column" rowGap={1}>
          <Typography variant="body2">{displayName}</Typography>
          <Typography variant="body3" color={muiTheme.palette.text.secondary}>
            {email}
          </Typography>
        </Stack>
      </MenuItem>
    );
