import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import {
  KubernetesTriple,
  KubernetesTripleGray,
} from "@komodorio/design-system/icons";
import { formatDistanceToNow } from "date-fns";

import { useDesktopClustersConnectionContext } from "../../../shared/context/electron/desktopAgentsProvider/desktopClustersConnectionProvider";
import { useDesktopAgentsStatusContext } from "../../../shared/context/electron/desktopAgentsProvider/desktopAgentsStatusesProvider";

import { StatusData } from "./StatusData";
import { UntrackButton } from "./UntrackButton";
import { TrackButton } from "./TrackButton";

const Container = styled.div`
  padding: 1rem;
  background-color: ${palette.white[0]};
  display: grid;
  grid-template-columns: auto 2fr 1fr 1fr 1fr auto;
  gap: 1.5rem;
  align-items: center;
`;

const useIsClusterConnected = (clusterName: string) => {
  const allClusterStatuses = useDesktopClustersConnectionContext();
  return allClusterStatuses?.[clusterName];
};

const useAgentStatus = (clusterName: string) => {
  const allAgentStatuses = useDesktopAgentsStatusContext();
  return allAgentStatuses?.[clusterName];
};

export const ClusterRow: React.FC<{
  clusterName: string;
  validClusterName: string;
  isLocal: boolean;
  trackedAt: number | null;
  portIndex: number | null;
}> = ({ clusterName, validClusterName, isLocal, trackedAt, portIndex }) => {
  const isClusterConnected = useIsClusterConnected(clusterName);
  const agentStatus = useAgentStatus(validClusterName);

  return (
    <Container>
      {trackedAt ? <KubernetesTriple /> : <KubernetesTripleGray />}
      <Metadata title="cluster name" value={clusterName} />
      <Metadata
        title="tracked at"
        value={
          trackedAt ? formatDistanceToNow(trackedAt, { addSuffix: true }) : "--"
        }
      />
      <Metadata title="type" value={isLocal ? "Local" : "Remote"} />
      <StatusData
        isClusterConnected={isClusterConnected}
        agentStatus={agentStatus}
      />
      {trackedAt ? (
        <UntrackButton clusterName={clusterName} agentStatus={agentStatus} />
      ) : (
        <TrackButton
          clusterName={clusterName}
          validClusterName={validClusterName}
          portIndex={portIndex}
        />
      )}
    </Container>
  );
};
