import { useCallback, useEffect, useState } from "react";

import {
  ClusterProps,
  getDesktopUserClusters,
} from "../../shared/context/electron/getDesktopUserClusters";
import { useDesktopAgentsStatusContext } from "../../shared/context/electron/desktopAgentsProvider/desktopAgentsStatusesProvider";

type ClustersInfo = Record<string, ClusterProps>;

export const useUpdatedClustersInfo = (): ClustersInfo | null => {
  const agentRunningStatus = useDesktopAgentsStatusContext();
  const [clustersInfo, setClustersInfo] = useState<ClustersInfo | null>(null);

  const updateTrackedClusters = useCallback(() => {
    getDesktopUserClusters().then((desktopUserClusters) => {
      if (!desktopUserClusters) return;
      const { tracked, untracked } = desktopUserClusters;
      const clusters = [...tracked, ...untracked];
      setClustersInfo(Object.fromEntries(clusters.map((c) => [c.name, c])));
    });
  }, []);

  useEffect(() => {
    updateTrackedClusters();
  }, [updateTrackedClusters, agentRunningStatus]);

  return clustersInfo;
};
