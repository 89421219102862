import { useCallback } from "react";

import { useOpenPolicyDrawer } from "../../../policies/policiesHooks";
import { MetadataItemContent } from "../violationDrawerTypes";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DisplayMode } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useCanEditPolicy } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useCanEditPolicy";

type UseAddPolicyLinkArgs = {
  policyName?: string;
  policyId?: string;
};

export const useAddPolicyLink = ({
  policyName,
  policyId,
}: UseAddPolicyLinkArgs) => {
  const onPolicyNameClick = useOpenPolicyDrawer();
  const { showReliabilityPolicies } = useOverridableFlags();
  const canEditPolicy = useCanEditPolicy(policyId);

  return useCallback(
    (metadataItems: MetadataItemContent[]) => {
      if (showReliabilityPolicies && policyName && policyId) {
        const displayMode: DisplayMode = canEditPolicy ? "edit" : "view";
        metadataItems.push({
          label: "policy",
          value: policyName,
          onClick: () => onPolicyNameClick(displayMode, policyId),
        });
      }
    },
    [
      canEditPolicy,
      onPolicyNameClick,
      policyId,
      policyName,
      showReliabilityPolicies,
    ]
  );
};
