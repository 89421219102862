import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { muiPalette } from "@komodorio/design-system";

import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
import PodPhaseGroup from "../../../../common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { PodPhaseDetails } from "../../../../common/ProcessList/details/PodPhaseDetails/PodPhaseSection";

const PodPhaseContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.5fr 1fr 1fr;
  align-items: center;
  column-gap: 1rem;
`;

export const PodPhase: React.FC<{ phase: PodPhaseGroup }> = ({ phase }) => {
  const Icon = phase.icon;
  const { format } = useDateFormatter({ timeZoneName: undefined });

  return (
    <Accordion
      sx={{
        overflow: "hidden",
        "&.MuiAccordion-root:first-of-type": {
          borderRadius: "0px",
        },
        "&.MuiAccordion-root:last-of-type": {
          borderRadius: "0px",
        },
        borderBottom: `1px solid ${muiPalette.divider}`,
      }}
      aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}`}
      id={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}`}
    >
      <AccordionSummary
        aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-summary`}
        sx={{ padding: "8px 16px" }}
        expandIcon={<ExpandMore />}
      >
        <PodPhaseContainer>
          <Icon
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-icon`}
            color={phase.fillColor}
          />
          <Metadata
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-phase`}
            title={"Phase"}
            value={phase.phase}
          />
          <Metadata
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-started`}
            title={"Started"}
            value={format(phase.startTime)}
          />
        </PodPhaseContainer>
      </AccordionSummary>
      <AccordionDetails
        aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-details`}
      >
        <PodPhaseDetails eventGroup={phase} />
      </AccordionDetails>
    </Accordion>
  );
};
