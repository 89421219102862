import React, { useMemo } from "react";
import styled from "styled-components";

import { DetailsExternalLink } from "../common";
import { blueBrand, blueForUI } from "../../../../../Colors";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.75rem;
`;

const Column = styled.div`
  display: grid;
  gap: 0.75rem;
`;

const PlayerContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 0 0.5rem;
`;

const InitialsContainer = styled.div`
  grid-row: span 2;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${blueForUI};
  border-radius: 0.25rem;
  color: ${blueBrand};
  font-weight: bold;
`;

const Initials = ({ name }: { name: string }) => {
  const initials = useMemo(() => {
    const words = name.split(/\s+/).filter((w) => w.length);
    if (!words.length) {
      return "";
    }
    if (words.length === 1) {
      return words[0][0];
    }
    return words[0][0] + words[words.length - 1][0];
  }, [name]);
  return <InitialsContainer>{initials}</InitialsContainer>;
};

export interface Player {
  name: string;
  type: string;
  url?: string;
}

const KeyValue = ({ player }: { player: Player }) => (
  <div>
    <div>{player.type}</div>
    <div>
      {player.url ? (
        <DetailsExternalLink
          noUnderline
          color="inherit"
          href={player.url}
          title={player.name}
        />
      ) : (
        <b>{player.name}</b>
      )}
    </div>
  </div>
);

const PlayerElement = ({ player }: { player: Player }) => (
  <PlayerContainer>
    <Initials name={player.name} />
    <KeyValue player={player} />
  </PlayerContainer>
);

export interface PlayersSectionProps {
  players: Player[];
  extra?: Player;
}

export const PlayersSection: React.FC<PlayersSectionProps> = ({
  players,
  extra,
}) => {
  return (
    <Container>
      <Column>
        {players.map((player) => (
          <PlayerElement key={player.type + player.name} player={player} />
        ))}
      </Column>
      {extra && (
        <Column>
          <KeyValue player={extra} />
        </Column>
      )}
    </Container>
  );
};
