import {
  Code,
  TextContainer,
  TextElementContainer,
} from "../../../violationDrawerStyles";

export const whyDescriptionText =
  "Having a single replica for a deployment can create a Single Point of Failure (SPOF) vulnerability - " +
  "the application may suffer downtime if that one replica goes down.";

export const getWhatDescription = () => (
  <TextElementContainer>
    <TextContainer>We detected that the</TextContainer>
    <Code>spec.replicas</Code>
    <TextContainer>
      is set to 1, indicating a lack of redundancy and fault tolerance.
    </TextContainer>
  </TextElementContainer>
);

export const getWhyDescription = () => (
  <TextContainer>{whyDescriptionText}</TextContainer>
);

export const howDescription = () => (
  <TextElementContainer>
    <TextContainer>
      Increase the number of replicas for your deployment. This can be done by
      modifying the
    </TextContainer>
    <Code>spec.replicas</Code>
    <TextContainer>field in your deployment YAML.</TextContainer>
  </TextElementContainer>
);
