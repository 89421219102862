import styled from "styled-components";
import { addMinutes, subHours, subMinutes } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import DeployEventGroup, {
  groupDeployEvents,
} from "../../../EventGroup/deployEvent/DeployEventGroup";
import { K8sDiff } from "../../../EventGroup/deployEvent/getK8sDiff";
import JobEventGroup from "../../../EventGroup/jobEvent/JobEventGroup";
import arrowBlue from "../../assets/arrow-blue.svg";
import { Timeframe } from "../../../../../shared/types/TimeWindow";
import useTimeWindowFromURL from "../../../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { BaseAction } from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import {
  EVENT_PARAM_KEY,
  RELATED_DEPLOY_FILTER_PARAM_KEY,
} from "../../../../../shared/config/urlSearchParamsKeys";
import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import {
  DEPLOY_EVENTS_SEARCH,
  KOMODOR_SERVICES_ID,
} from "../../../../../shared/hooks/resources-api/requestResponseMaps";
import { EventsApiApiV1DeploysEventsSearchGetRequest } from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../../../../shared/hooks/resources-api/client/apiClient";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { fetchLatestDeployUntil } from "../../../../../shared/hooks/resources-api/client/events/useSearchDeployEvents";

import DiffEntry from "./DiffEntry";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useRelatedDeploy = (
  eventGroup: JobEventGroup
): {
  relatedDeployEventGroup: DeployEventGroup | undefined;
} => {
  const [lastEvent] = eventGroup.events.slice(-1);
  return useServiceRelatedDeploy(eventGroup.serviceId, lastEvent.eventTime);
};
const DeployDateContainer = styled.div`
  margin-top: 0.5rem;
`;

const DeployDateSection = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
`;

const Value = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`;

const initialTimeWindow = {
  start: subHours(new Date(), 24),
  end: new Date(),
  timeframe: Timeframe.Last24Hours,
};
export const DeployDetailsDiff: React.FC<{
  k8sDiff: K8sDiff | undefined;
  eventGroup: DeployEventGroup;
}> = ({ k8sDiff, eventGroup }) => {
  const [, setTimeWindow] = useTimeWindowFromURL(initialTimeWindow);
  const [, setEventId] = useStateInSearchParams(EVENT_PARAM_KEY);
  const [, setFilter] = useStateInSearchParams(RELATED_DEPLOY_FILTER_PARAM_KEY);
  const { format } = useDateFormatter();
  const onEventClick = useCallback(() => {
    dispatchEvent(AnalyticEvents.DetailsDrawer.HealthEvent_DeployEvent_Click);
    setFilter(null);
    setTimeWindow({
      start: subMinutes(eventGroup.startTime, 30),
      end: addMinutes(eventGroup.endTime, 30),
      timeframe: Timeframe.Custom,
    });
    setEventId(eventGroup.id);
  }, [setTimeWindow, eventGroup, setEventId, setFilter]);
  const { changes } = k8sDiff ?? {
    changes: [],
    hiddenCount: 0,
  };
  const [updateDateText, updateDateTime] = useMemo(() => {
    if (!eventGroup.isCompleted) {
      return [`Started at `, format(eventGroup.startTime)];
    }
    return [`Last updated at `, format(eventGroup.endTime)];
  }, [format, eventGroup]);
  return (
    <>
      <Value>
        <BaseAction onClick={onEventClick}>{eventGroup.summary}</BaseAction> -{" "}
        {eventGroup.status}
      </Value>
      <DeployDateContainer>
        {updateDateText}
        <DeployDateSection>{updateDateTime}</DeployDateSection>
      </DeployDateContainer>
      {changes.length
        ? changes.map((c) => <DiffEntry key={c.name} change={c} />)
        : null}
    </>
  );
};

export const LatestDeployDetails: React.FC<{
  eventGroup: DeployEventGroup;
  k8sDiff: K8sDiff | undefined;
}> = ({ eventGroup, k8sDiff }) => {
  return (
    <EventDetailsSection icon={arrowBlue} title={"Latest deploy"}>
      <DeployDetailsDiff eventGroup={eventGroup} k8sDiff={k8sDiff} />
    </EventDetailsSection>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useServiceRelatedDeploy = (
  serviceId: string,
  endDate: Date | null
): {
  relatedDeployEventGroup: DeployEventGroup | undefined;
} => {
  const { data } = useResourcesAPIGet(KOMODOR_SERVICES_ID, {
    id: serviceId,
    fields: [
      "id",
      "accountId",
      "agentId",
      "inactive",
      "isDeleted",
      "k8sClusterName",
      "namespace",
      "displayName",
      "updatedAt",
      "createdAt",
      "kind",
    ],
  });

  const service = useMemo(() => data?.data?.[0], [data]);

  const params: EventsApiApiV1DeploysEventsSearchGetRequest | null =
    useMemo(() => {
      return {
        toEpoch: (endDate ?? new Date()).getTime().toString(),
        komodorUids: [
          buildKomodorUid({
            clusterName: service?.k8sClusterName ?? "",
            namespace: service?.namespace ?? "",
            resourceName: service?.displayName ?? "",
            kind: service?.kind ?? "",
          }),
        ],
        order: "DESC",
        limit: 1,
        fields: [
          "deploymentDiffKeys",
          "eventTime",
          "endEventTime",
          "updatedAt",
          "gitCompare",
          "id",
          "isEmptyDeployment",
          "services",
          "status",
          "statusMessage",
          "versionFrom",
          "versionTo",
          "changeType",
          "generation",
          "clusterName",
          "namespace",
          "deploymentName",
        ],
      };
    }, [service, endDate]);
  const apiClient = useResourcesApiClient();
  const { data: latestDeploys } = useQuery([DEPLOY_EVENTS_SEARCH, params], () =>
    fetchLatestDeployUntil(apiClient, params)
  );

  const relatedDeployEventGroup = useMemo(() => {
    if (!latestDeploys?.data || !latestDeploys?.data.length) {
      return;
    }
    const events = latestDeploys.data;
    return groupDeployEvents([events[0]], {})[0];
  }, [latestDeploys]);
  return {
    relatedDeployEventGroup,
  };
};
