import { muiColors, muiTheme } from "@komodorio/design-system";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { ChevronUp16, MagicAi24 } from "@komodorio/design-system/icons";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { datadogRum } from "@datadog/browser-rum";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

import { sendResourceInvestigationStateEvent } from "../ResourceView/AiInvestigation/common/analytics";
import { getDrawerStateForMinimizedSession } from "../ResourceView/AiInvestigation/common/utils";

import { MinimizedRow } from "./MinimizedRow";

import { dispatchEvent } from "@/shared/hooks/analytics";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useAiInvestigations } from "@/shared/context/aiInvestigations/useAiInvestigations";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { MinimizedSession } from "@/shared/context/aiInvestigations/AiInvestigationsProvider";

const ExpandButton = styled(IconButton)`
  &&.MuiButtonBase-root.MuiIconButton-root {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;

  &:hover ${ExpandButton} {
    &&: {
      color: ${muiColors.blue[500]};
    }
  }
`;

const Container = styled.div`
  background-color: ${muiColors.common.white};
  position: fixed;
  bottom: 0px;
  right: 32px;
  box-shadow: ${muiTheme.shadows[3]};
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid ${muiTheme.palette.divider};
  z-index: ${muiTheme.zIndex.drawer + 1};
  cursor: pointer;
  width: 600px;
`;

const MinimizedContainer = styled(Container)`
  width: 400px;
`;

const OpenCloseIcon = styled(ChevronUp16)<{ shouldRotate?: boolean }>`
  transform: ${({ shouldRotate }) =>
    shouldRotate ? "rotate(-180deg)" : "none"};
`;

export const AiInvestigationMinimizedSessions: React.FC = () => {
  const { investigationsQueries, removeMinimizedSession } =
    useAiInvestigations();
  const [isOpen, setIsOpen] = useState(false);
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onMaximize = useCallback(
    (minimizedSession: MinimizedSession) => {
      const drawer = getDrawerStateForMinimizedSession(minimizedSession);
      if (!drawer) {
        return;
      }
      pushDrawer(drawer);
      sendResourceInvestigationStateEvent(
        AnalyticEvents.KlaudiaAi.MinimizedInvestigationExpanded,
        minimizedSession.session,
        minimizedSession.sessionIdentifier,
        minimizedSession.sessionIdentifier.type
      );
      const data = { sessionIdentifier: minimizedSession.sessionIdentifier };
      const event = AnalyticEvents.KlaudiaAi.MinimizedInvestigationExpanded;
      datadogRum.addAction(event, data);
      dispatchEvent(event, data);
    },
    [pushDrawer]
  );

  const anyInProgress = useMemo(
    () =>
      investigationsQueries.some(
        (query) =>
          query.data?.session?.isComplete === false &&
          query.data?.session?.isStuck === false
      ),
    [investigationsQueries]
  );

  const allCompleted = useMemo(
    () =>
      investigationsQueries.every(
        (query) => query.data?.session?.isComplete,
        [investigationsQueries]
      ),
    [investigationsQueries]
  );

  const header = useMemo(
    () => (
      <Header
        className="expand-button"
        role="button"
        onClick={() => {
          setIsOpen((open) => !open);
          const data = { open: !open };
          const event = AnalyticEvents.KlaudiaAi.MinimizedInvestigationsToggled;
          datadogRum.addAction(event, data);
          dispatchEvent(event, data);
        }}
      >
        <MagicAi24 color={muiTheme.palette.text.primary} />
        <Typography variant="h5">Root cause analysis investigations</Typography>
        {investigationsQueries.length > 0 && (
          <Typography color={muiColors.gray[500]} variant="h5">
            ({investigationsQueries.length})
          </Typography>
        )}
        {!isOpen && anyInProgress && <CircularProgress size={16} />}
        {!isOpen && allCompleted && (
          <CheckCircleOutline
            sx={{ fontSize: "16px", color: muiColors.green[500] }}
          />
        )}
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <ExpandButton size="small">
            <OpenCloseIcon shouldRotate={isOpen} />
          </ExpandButton>
        </Box>
      </Header>
    ),
    [allCompleted, anyInProgress, investigationsQueries.length, isOpen]
  );

  if (investigationsQueries.length === 0) {
    return null;
  }

  if (!isOpen) {
    return (
      <MinimizedContainer
        aria-label={AriaLabels.AiInvestigator.Drawer.Minimized}
      >
        {header}
      </MinimizedContainer>
    );
  }

  return (
    <Container aria-label={AriaLabels.AiInvestigator.Drawer.Minimized}>
      {header}
      {isOpen && (
        <Box>
          <Divider />
          {investigationsQueries.map(({ data }) => {
            if (!data?.session) {
              return null;
            }
            return (
              <MinimizedRow
                key={data.sessionIdentifier.id}
                resource={data.sessionIdentifier}
                data={data.session}
                onMaximize={() => {
                  onMaximize(data);
                }}
                onRemove={(id) => {
                  removeMinimizedSession(id);
                  sendResourceInvestigationStateEvent(
                    AnalyticEvents.KlaudiaAi.MinimizedInvestigationRemoved,
                    data.session,
                    data.sessionIdentifier,
                    data.sessionIdentifier.type
                  );
                }}
              />
            );
          })}
        </Box>
      )}
    </Container>
  );
};
