import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  && {
    text-align: center;
  }
`;

export const MoreOptionsText: React.FC = () => {
  return (
    <StyledTypography variant={"body3"} color={"text.secondary"}>
      Remaining values not displayed
    </StyledTypography>
  );
};
