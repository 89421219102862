import React from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

const StyledLink = styled(Link)`
  cursor: pointer;
`;

type SummaryLinkProps = {
  text: string;
  onClick: () => void;
  className?: string;
};

export const SummaryLink: React.FC<SummaryLinkProps> = ({
  text,
  onClick,
  className,
}) => {
  return (
    <StyledLink onClick={onClick} className={className}>
      {text}
    </StyledLink>
  );
};
