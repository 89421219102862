import { useCallback, useMemo } from "react";

import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import useAtmResourcesListLiveResult from "../../../shared/hooks/ATM/useAtmResourcesListLiveResult";
import { useNamespacesFilter } from "../../../shared/hooks/ATM/useNamespacesAtm";
import { useParseResultToInspectionList } from "../../../shared/hooks/ATM/useParseInspectionResult";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListResponse } from "../InspectionViews/common";
import useAppendMetricsToResourceList from "../metrics/appendMetricsToResourceList";
import useInspectionMetrics from "../metrics/useInspectionMetrics";
import { resourceListAgentHasError } from "../ResourceListErrorState";
import useResourceListFromAgentTasks from "../tasks/useResourceListFromAgentTasks";
import { INSPECTION_ERROR } from "../../../shared/hooks/ATM/constants/atmResources";
import { useFilterByAppView } from "../../../shared/hooks/appViews/appViewFilterHooks";

import { useParseResourceTableModel } from "./useParseResourceList";

const RESOURCE_LIST_MAX_RETRIES_DEFAULT = 10;

interface AgentResourceInspectionProps {
  kubernetesResource: KubernetesResource;
  agentId: string | undefined;
  agentInfo: AgentProperties | null;
  labelSelector?: string;
  customColumns?: string[];
  namespace?: string;
  agentUpgradeRequired: boolean;
}

export interface AtmResourceInspectionProps {
  kubernetesResource: KubernetesResource;
  agentId: string;
  agentInfo: AgentProperties | null;
  cluster: string;
  isLiveData: boolean;
  shouldStop?: boolean;
  filterByAppViews?: boolean;
  fieldSelector?: string;
}

interface ResourceListResult {
  resourceList: ResourceListResponse;
  refresh: () => void;
}

export function useInspectionResourceListAgentTask({
  agentId,
  agentInfo,
  kubernetesResource,
  labelSelector = "",
  customColumns = [],
  namespace = "",
  agentUpgradeRequired,
}: AgentResourceInspectionProps): ResourceListResult {
  const { inspectionRetries } = useOverridableFlags();
  const filterByAppView = useFilterByAppView();
  const maxRetries = useMemo(
    () => (inspectionRetries as number) || RESOURCE_LIST_MAX_RETRIES_DEFAULT,
    [inspectionRetries]
  );
  const {
    metricsResult,
    errorBringingMetrics,
    isFetching: isFetchingMetrics,
  } = useInspectionMetrics({ kubernetesResource, agentInfo, agentId });
  const { result, failureMessage, retries, refresh } =
    useResourceListFromAgentTasks({
      agentId,
      kubernetesResource,
      agentUpgradeRequired,
      agentInfo,
      maxRetries,
      labelSelector,
      customColumns,
      namespace,
    });

  const filteredResourceList = filterByAppView({
    kubernetesResource,
    cluster: agentInfo?.clusterName,
    dataList: result?.rows,
  });
  const resourceList = useParseResourceTableModel(
    result && filteredResourceList
      ? { ...result, rows: filteredResourceList }
      : null
  );
  const resourceListWithMetrics = useAppendMetricsToResourceList(
    resourceList,
    metricsResult,
    isFetchingMetrics,
    errorBringingMetrics,
    kubernetesResource
  );

  const hasErrorFetching = useMemo(
    () =>
      resourceListAgentHasError(
        resourceListWithMetrics,
        failureMessage,
        retries,
        maxRetries
      ),
    [failureMessage, maxRetries, resourceListWithMetrics, retries]
  );
  resourceListWithMetrics.errorMessage =
    hasErrorFetching && !resourceListWithMetrics.errorMessage
      ? INSPECTION_ERROR
      : "";

  return {
    resourceList: resourceListWithMetrics,
    refresh,
  };
}

export function useInspectionResourceListAtm({
  agentId,
  kubernetesResource,
  agentInfo,
  cluster,
  isLiveData = false,
  shouldStop,
  filterByAppViews = true,
  fieldSelector,
}: AtmResourceInspectionProps): ResourceListResult {
  const filterByAppView = useFilterByAppView();
  const namespacesFilter = useNamespacesFilter(kubernetesResource.Namespaced);
  const {
    result,
    status,
    refresh: refreshFromATM,
    errorMessage,
  } = useAtmResourcesListLiveResult({
    resourceKind: kubernetesResource.Kind,
    agentId,
    cluster,
    shouldPause: shouldStop,
    namespaces: namespacesFilter,
    isLive: isLiveData,
    fieldSelector,
  });

  const {
    metricsResult,
    isFetching: isFetchingMetrics,
    errorBringingMetrics,
  } = useInspectionMetrics({ kubernetesResource, agentInfo, agentId });
  const resourceListFromATM = useParseResultToInspectionList(
    status,
    errorMessage,
    result?.data,
    result?.isPaginating
  );

  const filteredResourceList = filterByAppViews
    ? filterByAppView({
        kubernetesResource,
        cluster,
        dataList: resourceListFromATM?.rows,
      })
    : resourceListFromATM?.rows;

  const resourceListWithMetrics = useAppendMetricsToResourceList(
    { ...resourceListFromATM, rows: filteredResourceList || [] },
    metricsResult,
    isFetchingMetrics,
    errorBringingMetrics,
    kubernetesResource
  );

  const refresh = useCallback(() => {
    refreshFromATM();
  }, [refreshFromATM]);

  return {
    resourceList: resourceListWithMetrics,
    refresh,
  };
}
