export type PathExtractor = (v: string) => string;

export type PrefixTree = {
  [prefix: string]: PathExtractor | PrefixTree;
};

const containers: PrefixTree = {
  "*": {
    image: () => "image",
    imagePullPolicy: () => "imagePullPolicy",
    env: (v) => `env: ${v}`,
    command: () => "command",
    args: () => "args",
    resources: {
      requests: (v) => `res.request: ${v}`,
      limits: (v) => `res.limit: ${v}`,
    },
  },
};

const metadata: PrefixTree = {
  labels: (v) => `label: ${v}`,
  annotations: (v) => `annotation: ${v}`,
};

export const prefixTree: PrefixTree = {
  spec: {
    replicas: () => "replicas",
    selector: {
      matchLabels: (v) => `selector: ${v}`,
    },
    template: {
      metadata,
      spec: {
        containers,
        initContainers: containers,
        restartPolicy: () => "restartPolicy",
      },
    },
  },
  metadata,
};

const toPathString = (
  path: string[],
  node: PrefixTree | PathExtractor | null = prefixTree,
  i = 0
): string | null => {
  if (node instanceof Function) {
    return node(path[i] ?? "");
  }
  if (!node || i === path.length) {
    return null;
  }
  const key = "*" in node ? "*" : path[i];
  return toPathString(path, node[key] ?? null, i + 1);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default toPathString;
