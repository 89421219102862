import React, { useMemo } from "react";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import { Dictionary } from "../../../shared/types/Dictionary";
import { Alert } from "../Alert/Alert";
import { getAccuracyDataForClusters } from "../../../shared/utils/metricsAgentInfoAccuracyUtils";

import { RecommendationAccuracyText } from "./texts";

type RecommendationAccuracyProps = {
  clusters: Dictionary<string>;
  ariaLabel?: string;
  forceShowLabel?: boolean;
};
export const RecommendationAccuracy: React.FC<RecommendationAccuracyProps> = ({
  clusters,
  ariaLabel,
  forceShowLabel,
}) => {
  const shouldShowAlert = useMemo(() => {
    if (forceShowLabel) return true;
    const { clustersWithInsufficientTime } =
      getAccuracyDataForClusters(clusters);
    return clustersWithInsufficientTime.length > 0;
  }, [clusters, forceShowLabel]);

  if (!shouldShowAlert) return null;
  return (
    <Alert
      severity={"warning"}
      text={RecommendationAccuracyText}
      icon={WarningAmberOutlined as React.FC}
      showCloseButton
      ariaLabel={ariaLabel}
    />
  );
};
