import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

const BannerContext = createContext<boolean>(false);

const BannerUpdateContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useBanner = (): boolean => {
  return useContext(BannerContext);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useBannerUpdate = ():
  | Dispatch<SetStateAction<boolean>>
  | undefined => {
  return useContext(BannerUpdateContext);
};

export const BannerProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isBannerShown, setIsBannerShown] = useState<boolean>(false);

  return (
    <BannerContext.Provider value={isBannerShown}>
      <BannerUpdateContext.Provider value={setIsBannerShown}>
        {children}
      </BannerUpdateContext.Provider>
    </BannerContext.Provider>
  );
};
