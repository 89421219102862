import styled from "styled-components";

export const CommonSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  justify-content: start;
`;

export const Section = styled(CommonSection)`
  padding: 24px;
`;
