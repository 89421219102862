import { useCallback, useMemo } from "react";

import { INSPECTION_ATM_LIVE_DATA_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportMetricsRange } from "../../../shared/utils/agent/agent";
import { useInterval } from "../../common/useInterval";
import useInstallationSubscription, {
  Installation,
} from "../../integrations/management/installed/useInstallationSubscription";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";

import { MetricsTaskResult } from "./metricsTaskResultParser";
import { MonitoringProvider } from "./MonitoringProvider";
import useMetricsAgentTask, {
  BRINGING_METRICS_INTERVAL_IN_SECONDS,
} from "./useMetricsAgentTask";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

interface InspectionMetricsProps {
  kubernetesResource: KubernetesResource;
  agentInfo: AgentProperties | null;
  agentId: string | undefined;
}

const useInstallations = (): Installation[] | undefined => {
  const installations = useInstallationSubscription();
  return useMemo(() => installations.data, [installations.data]);
};

const useInspectionMetrics = ({
  kubernetesResource,
  agentInfo,
  agentId,
}: InspectionMetricsProps): {
  metricsResult: MetricsTaskResult | null;
  errorBringingMetrics: string | null;
  isFetching: boolean;
} => {
  const { metricsView } = useOverridableFlags();
  const upgradeRequired = useMemo(() => {
    if (!agentInfo) {
      return false;
    }
    return !doesAgentVersionSupportMetricsRange(agentInfo);
  }, [agentInfo]);

  const installations = useInstallations();

  const monitoringProvider = useMemo(
    () => new MonitoringProvider(kubernetesResource, agentInfo, installations),
    [agentInfo, kubernetesResource, installations]
  );

  const shouldBringMetrics = useMemo(
    () =>
      !!metricsView &&
      !!agentInfo &&
      !upgradeRequired &&
      !!monitoringProvider.getType(),
    [agentInfo, metricsView, monitoringProvider, upgradeRequired]
  );

  const { metricsResult, errorBringingMetrics, isFetching, refresh } =
    useMetricsAgentTask({
      agentId,
      shouldBringMetrics,
      monitoringProvider,
    });

  const [isAtmLiveData] = useStringifiedStateInSearchParams<boolean>(
    INSPECTION_ATM_LIVE_DATA_PARAM_KEY
  );
  const isLive = useMemo(() => isAtmLiveData ?? false, [isAtmLiveData]);

  const refreshCallback = useCallback(() => {
    if (isFetching || !isLive) {
      return;
    }
    refresh();
  }, [isFetching, isLive, refresh]);

  useInterval(refreshCallback, BRINGING_METRICS_INTERVAL_IN_SECONDS * 1_000);

  return {
    metricsResult,
    errorBringingMetrics,
    isFetching,
  };
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useInspectionMetrics;
