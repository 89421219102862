import React, { useMemo } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { isEmpty } from "lodash";

import { DescribeData } from "../common/DescribeData";

const ConfigMapUnitContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  color: ${palette.gray[800]};
`;

const ConfigMapValueContainer = styled.pre`
  display: flex;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  padding-block: 2px;
  padding-inline: 8px;
  background-color: ${palette.gray["50"]};
  line-height: 1.25rem;
  margin: 0;
  white-space: pre-wrap;
  overflow-x: auto;
`;

type ConfigMapData = Record<string, string>;

export const ConfigMapData: React.FC<{ configMapData: ConfigMapData }> = ({
  configMapData,
}) => {
  const configMapEntries = useMemo(
    () =>
      Object.keys(configMapData).map((key) => (
        <ConfigMapEntry key={key} name={key} value={configMapData[key]} />
      )),
    [configMapData]
  );

  if (isEmpty(configMapData)) return null;

  return <DescribeData>{configMapEntries}</DescribeData>;
};

const ConfigMapEntry: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => (
  <ConfigMapUnitContainer>
    <Typography variant="text" size="small">
      {name}
    </Typography>
    <ConfigMapValueContainer>{value}</ConfigMapValueContainer>
  </ConfigMapUnitContainer>
);
