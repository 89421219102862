import React, { useMemo } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";

import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import {
  selectRightSizingRecommendationsState,
  selectRightSizingRecommendationsStrategiesState,
  selectSetRightSizingModalStrategies,
  selectSetRightSizingRecommendationModal,
  selectSetRightSizingRecommendationsState,
} from "../../../../store/overviewPageStoreSelectors";
import { initialState } from "../../../../store/initialState";
import { CostStrategy } from "../../../../../../../../../CostOptimizationView/types/costOptimizationTypes";
import { useRowsContent } from "../../../../../../../../../CostOptimizationView/components/shared/RecommendationsModal/useRowsContent";
import { RecommendationsModal } from "../../../../../../../../../CostOptimizationView/components/shared/RecommendationsModal/RecommendationsModal";
import { getMonthlyAmount } from "../../../../../../../../../CostOptimizationView/utils/costOptimizationUtils";
import {
  useFetchRightSizingRecommendationData,
  useGetRowData,
  useGetRowStrategy,
} from "../costHooks";

export const AppsRightSizingRecommendationModal: React.FC<{
  rowId: string | undefined;
}> = ({ rowId }) => {
  useFetchRightSizingRecommendationData();

  const { data, error, loading, refresh } = useOverviewPageStore(
    selectRightSizingRecommendationsState
  );

  const setRightSizingRecommendationsStrategiesState = useOverviewPageStore(
    selectSetRightSizingModalStrategies
  );

  const setModalOptions = useOverviewPageStore(
    selectSetRightSizingRecommendationModal
  );

  const setRightSizingRecommendationsState = useOverviewPageStore(
    selectSetRightSizingRecommendationsState
  );

  const recommendationStrategies = useOverviewPageStore(
    selectRightSizingRecommendationsStrategiesState
  );

  const handleClose = () => {
    setModalOptions({ rowId: undefined });
    setRightSizingRecommendationsState(
      initialState.cost.rightSizingRecommendationsState
    );
  };

  const onChangeStrategy = (rowId: string, selected: OptionType<string>) => {
    setRightSizingRecommendationsStrategiesState({
      [rowId]: selected.value as CostStrategy,
    });
  };

  const rowData = useGetRowData(rowId);

  const modalStrategy = useGetRowStrategy(rowId);

  const rowsContent = useRowsContent({
    loading,
    error,
    strategy: modalStrategy,
    data,
    refresh,
  });

  const potentialSaving = useMemo(
    () => getMonthlyAmount({ rowData, recommendationStrategies }),
    [recommendationStrategies, rowData]
  );

  return (
    <RecommendationsModal
      modalStrategy={modalStrategy}
      containersData={data?.rows}
      onChangeStrategy={onChangeStrategy}
      rowsContent={rowsContent}
      open={!!rowId}
      handleClose={handleClose}
      rowData={rowData}
      isLoading={loading}
      potentialSaving={potentialSaving}
    />
  );
};
