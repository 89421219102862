import _ from "lodash";
import React, { useMemo } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import a11yLight from "react-syntax-highlighter/dist/cjs/styles/hljs/a11y-light";
import styled from "styled-components";
import YAML from "yaml";

import { gray13, grayAppBackground } from "../../../Colors";
import { DeployEventsResponse } from "../../../generated/resourcesApi";

const Container = styled.div`
  text-align: left;
  border: ${gray13} 0.1rem solid;
  border-radius: 0.5rem;
  line-height: 24px;
  overflow: hidden;
  font-size: 12px;
  font-family: "Roboto Mono";
  flex: 1;
`;

const DeploymentNameContainer = styled.div`
  background-color: ${grayAppBackground};
  padding: 0.5rem 1rem;
  font-family: "Roboto Mono";
  font-weight: 400;
`;

const SyntaxHighlighterContainer = styled.div`
  padding-left: 1.25rem;
  height: 100%;
  overflow: auto;
`;

interface specInterface {
  metadata: unknown;
  spec: unknown;
}

const StaticPreventionYaml: React.FC<{
  lastDeploy: DeployEventsResponse | undefined;
}> = ({ lastDeploy }) => {
  const yamlContent = useMemo(() => {
    const newSpec =
      lastDeploy?.data?.[0]?.newSpec &&
      JSON.parse(lastDeploy?.data?.[0]?.newSpec as unknown as string);
    if (!newSpec) return "";
    const spec = _.omit(newSpec as specInterface, [
      "metadata.managedFields",
      "status",
    ]);
    return YAML.stringify(spec);
  }, [lastDeploy?.data]);

  return (
    <Container data-e2e-selector={"best-practices-modal-yaml-viewer"}>
      <DeploymentNameContainer>
        {lastDeploy?.data?.[0]?.deploymentName &&
          `${lastDeploy?.data?.[0]?.deploymentName}.yaml`}
      </DeploymentNameContainer>

      <SyntaxHighlighterContainer>
        <SyntaxHighlighter
          lineProps={{
            style: {
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              lineHeight: "200px",
            },
          }}
          showLineNumbers={true}
          language="yaml"
          style={a11yLight}
        >
          {yamlContent as string}
        </SyntaxHighlighter>
      </SyntaxHighlighterContainer>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StaticPreventionYaml;
