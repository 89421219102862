import { useMemo } from "react";

import {
  getEpochsFromLastEvaluatedAt,
  TimeUnit,
} from "../violationDrawerUtils";

import { useViolation } from "./useViolation";

export const useGetEpochsFromViolation = (timeUnit?: TimeUnit) => {
  const { data } = useViolation();
  const violation = data?.data.violation;
  return useMemo(
    () =>
      getEpochsFromLastEvaluatedAt(violation?.lastEvaluatedAt ?? 0, timeUnit),
    [timeUnit, violation?.lastEvaluatedAt]
  );
};
