import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { gray10 } from "../../../Colors";
import { SpacedRowFieldContainer } from "../monitorsConfiguration/common/styles";

export const Label = styled.span`
  width: 6.5rem;
  font-weight: bold;
  color: ${gray10};
`;
export const SummaryCard = styled(SpacedRowFieldContainer)`
  cursor: pointer;
`;
export const ActionsContainer = styled.div.attrs({
  "aria-label": "suggested-actions",
})`
  margin-inline-start: 1.5rem;
  display: flex;
  justify-column: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;
`;
export const SubTitle = styled(Typography).attrs({
  variant: "title",
  size: "small",
})``;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
