import { useMemo } from "react";

import { useResourceUid } from "../../../../../../shared/hooks/resources/useResourceUid";
import { timeWindowToEpochs } from "../../../../../Metrics/utils";
import Resource from "../../../../resources/index";
import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { useFetchWorkloadMetricsData } from "../../hooks/fetchWorkloadMetricsHooks";
import { useGraphData } from "../../hooks/graphDataHooks";
import { KubernetesServiceTypeEnum } from "../../../../../../shared/types/ServiceVertex";
import { MetricType } from "../../../../../Metrics/types";

export const useMetricsGraphRequestParams = (
  resource: Resource,
  endTime?: number
) => {
  const { timeWindow, selectedAggregationMetric } =
    useWorkloadMetricsTabContext();
  const { cluster, name, namespace, kind } = resource;
  const { fromEpoch, toEpoch, toEpochMs } = useMemo(
    () =>
      timeWindowToEpochs(
        timeWindow.timeframe,
        endTime ? new Date(endTime) : undefined
      ),
    [timeWindow.timeframe, endTime]
  );

  const requestParams = useMemo(
    () => ({
      clusterName: cluster,
      namespace,
      komodorServiceName: name,
      /*
        KubernetesServiceTypeEnum.Rollout is an ugly fix for a bug in brain, which in case when pod's owner is replicaset,
        always maps it to Deployment: https://app.clickup.com/t/86bx47fta
     */
      komodorServiceKind:
        kind === KubernetesServiceTypeEnum.Rollout
          ? KubernetesServiceTypeEnum.Deployment
          : kind,
      fromEpoch,
      toEpoch,
    }),
    [cluster, namespace, name, kind, fromEpoch, toEpoch]
  );

  return {
    timeWindow,
    fromEpoch,
    toEpoch,
    requestParams,
    toEpochMs,
    selectedAggregationMetric,
  };
};

type WorkloadMetricsParams = {
  type: MetricType;
  resource: Resource;
  endTime?: number;
};

export const useWorkloadMetrics = ({
  type,
  resource,
  endTime,
}: WorkloadMetricsParams) => {
  const {
    requestParams,
    toEpochMs,
    selectedAggregationMetric,
    timeWindow,
    fromEpoch,
    toEpoch,
  } = useMetricsGraphRequestParams(resource, endTime);

  const uid = useResourceUid(resource);
  const {
    data: metricsData,
    isLoading,
    error,
  } = useFetchWorkloadMetricsData({
    requestParams,
    type,
  });

  const { graphProps, hasGraphData } = useGraphData(metricsData?.data);

  const hasLimit = metricsData?.data.limit?.some((dp) => dp.value > 0);

  return {
    loading: isLoading,
    error,
    graphProps,
    hasGraphData,
    uid,
    timeWindow,
    fromEpoch,
    toEpoch,
    now: endTime ?? toEpochMs,
    selectedAggregationMetric,
    hasLimit,
  };
};
