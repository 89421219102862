import { useMemo } from "react";

import { MonitorData, MonitorType } from "../../../generated/monitorsApi";
import {
  parseTriageData,
  parseWorkflowRun,
} from "../../monitorsView/useWorkflowsRuns";
import { useMonitorRunDataById } from "../../common/EventGroup/workflowIssues/useWorkflowIssues";
import { TriageDataDb } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { AvailabilityRunWithResults } from "../types";

import {
  AvailabilityInvestigationCheckType,
  AvailabilityInvestigationResult,
} from "./types";

export const useAvailabilityMonitorById = (
  runId: string
): AvailabilityRunWithResults | null => {
  const monitorIssue = useMonitorRunDataById(runId, MonitorType.Availability);

  return useMemo(() => {
    const monitorType = monitorIssue.data?.data?.type;
    if (!monitorIssue.data?.data || !monitorType) {
      return null;
    }
    return parseWorkflowRunWithResults(monitorIssue.data?.data);
  }, [monitorIssue.data?.data]);
};
export const parseWorkflowRunWithResults = (
  wr: MonitorData
): AvailabilityRunWithResults => {
  const parsedTriageData = parseTriageData(wr.triageData as TriageDataDb);
  return {
    ...parseWorkflowRun(wr),
    results: parseResults(wr.results),
    services: wr.services as string[],
    triageData: parsedTriageData,
  };
};

const parseResults = (results: unknown): AvailabilityInvestigationResult => {
  const availabilityResults = results as AvailabilityInvestigationResult;
  return {
    [AvailabilityInvestigationCheckType.Introduction]:
      availabilityResults.introduction,
    [AvailabilityInvestigationCheckType.Logs]: availabilityResults.logs,
    [AvailabilityInvestigationCheckType.UnhealthyPods]:
      availabilityResults.unhealthyPods,
    [AvailabilityInvestigationCheckType.CorrelatedDeploys]:
      availabilityResults.correlatedDeploys,
    [AvailabilityInvestigationCheckType.RelatedServicesDeploys]:
      availabilityResults.relatedServicesDeploys,
    [AvailabilityInvestigationCheckType.NodeIssues]:
      availabilityResults.nodeIssues,
    [AvailabilityInvestigationCheckType.NoisyNeighbors]:
      availabilityResults?.noisyNeighbors,
    [AvailabilityInvestigationCheckType.LimitDecreased]:
      availabilityResults?.limitDecreased,
    [AvailabilityInvestigationCheckType.MemoryLeak]:
      availabilityResults?.memoryLeak,
    [AvailabilityInvestigationCheckType.ReviewMemoryAllocation]:
      availabilityResults?.reviewMemoryAllocation,
    [AvailabilityInvestigationCheckType.KlaudiaSession]:
      availabilityResults?.klaudiaSession,
  };
};
