import { useCallback, useMemo } from "react";

import type Resource from "@/components/ResourceView/resources";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { KubernetesCronJobResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

const useFilterCronJobActions = () => {
  const { showSuspendResumeAction } = useOverridableFlags();

  return useCallback(
    (resource: Resource) => {
      if (resource.kind !== KubernetesCronJobResource.Kind) {
        return resource.actions;
      }
      return resource.actions.filter((action) => {
        if (
          action === AvailableActions.SuspendCronJob ||
          action === AvailableActions.ResumeCronJob
        ) {
          if (!showSuspendResumeAction) {
            return false;
          }

          const resourceWithSuspend = resource as Resource & {
            suspend: boolean | undefined;
          };

          if (action === AvailableActions.SuspendCronJob) {
            return resourceWithSuspend.suspend === false;
          }
          if (action === AvailableActions.ResumeCronJob) {
            return resourceWithSuspend.suspend === true;
          }
        }
        return true;
      });
    },
    [showSuspendResumeAction]
  );
};

export const useFilterAvailableActions = (resource: Resource) => {
  const filterCronJobActions = useFilterCronJobActions();

  return useMemo<AvailableActions[]>(() => {
    return filterCronJobActions(resource);
  }, [filterCronJobActions, resource]);
};
