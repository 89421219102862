import React from "react";
import { CustomResourceDefinitionCondition } from "kubernetes-types/apiextensions/v1";
import { CRD as crdIcon } from "@komodorio/design-system/icons";
import { ObjectMeta } from "kubernetes-types/meta/v1";

import { Header } from "../common/Header/Header";

import Resource, { ResourceTabName } from ".";

import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";

type CertManagerCertificateSpec = {
  dnsNames: string[];
  issuerRef: {
    name: string;
    group: string;
    kind: string;
  };
  secretName: string;
  usages: string[];
};

export interface CertManagerCertificateType {
  apiVersion?: string;
  kind?: string;
  metadata?: ObjectMeta;
  spec?: CertManagerCertificateSpec;
  status?: CustomResourceDefinitionCondition[];
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class CertManagerCertificate implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Edit, AvailableActions.Describe];
  readonly fullObj;
  readonly defaultTab;

  constructor(
    cluster: string,
    certificate: CertManagerCertificateType,
    agentId: string
  ) {
    this.agentId = agentId;
    this.id = certificate.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = certificate.metadata?.namespace ?? "";
    this.name = certificate.metadata?.name ?? "";
    this.kind = certificate.kind ?? "";
    this.labels = certificate.metadata?.labels ?? {};
    this.annotations = certificate.metadata?.annotations ?? {};
    this.icon = crdIcon;

    this.fullObj = certificate;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return <></>;
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
