import React, { useMemo } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { Dictionary } from "../../../../../../../../../../shared/types/Dictionary";
import { useCreationPageContext } from "../../../../context/CreationPageContext";

import { useMissingServiceIdentifierDetection } from "./useMissingServiceIdentifierDetection";
import { StyledSelect } from "./StyledSelect";
import { SelectContainer } from "./SelectContainer";
import { toSelectableOption } from "./utils";
import { VALUE_MISSING_ERROR } from "./constants";
import { PaddedTypography } from "./PaddedTypography";

const LimitedWidthSelect = styled(StyledSelect)`
  min-width: 167px;
  text-overflow: ellipsis;
`;

type SelectedLabel = Partial<{
  key: string;
  value: string;
}>;

interface LabelSelectorProps {
  labels: Dictionary<Set<string>>;
  selectedLabel: SelectedLabel;
  onLabelSelection: (selectedLabel: SelectedLabel) => void;
}

export const LabelSelector: React.FC<LabelSelectorProps> = ({
  labels,
  selectedLabel = {},
  onLabelSelection,
}) => {
  const { setErrorState } = useCreationPageContext();

  const [labelKeyMissing, labelValueMissing] =
    useMissingServiceIdentifierDetection(
      !!selectedLabel.key,
      !!selectedLabel.value
    );

  const labelKeyOptions = useMemo(
    () => Object.keys(labels).map(toSelectableOption),
    [labels]
  );

  const labelValueOptions = useMemo(
    () =>
      selectedLabel.key && labels[selectedLabel.key]
        ? Array.from(labels[selectedLabel.key])?.map(toSelectableOption)
        : [],
    [selectedLabel.key, labels]
  );

  const handleLabelKeySelection = ({ label: key }: OptionType<string>) => {
    onLabelSelection({ key });
    setErrorState({ servicesSelectedError: undefined });
  };

  const handleLabelValueSelection = ({ label: value }: OptionType<string>) => {
    onLabelSelection({ ...selectedLabel, value });
    setErrorState({ servicesSelectedError: undefined });
  };

  return (
    <SelectContainer>
      <LimitedWidthSelect
        options={labelKeyOptions}
        value={labelKeyOptions.find(
          (option) => option.label === selectedLabel.key
        )}
        onChange={handleLabelKeySelection}
        width="fit-content"
        searchable
        listMaxHeight="16rem"
        errorMessage={labelKeyMissing ? VALUE_MISSING_ERROR : undefined}
        placeholder="Select key"
      />
      {selectedLabel.key && (
        <PaddedTypography size="medium">:</PaddedTypography>
      )}
      <StyledSelect
        options={labelValueOptions}
        value={labelValueOptions.find(
          (option) => option.label === selectedLabel.value
        )}
        onChange={handleLabelValueSelection}
        searchable
        listMaxHeight="16rem"
        errorMessage={labelValueMissing ? VALUE_MISSING_ERROR : undefined}
        placeholder="Select value"
      />
    </SelectContainer>
  );
};
