import React, { useMemo } from "react";
import removeMarkdown from "remove-markdown";

import EventDetailsSection from "../components/EventDetailsSection";
import DetailsList from "../components/DetailsList";
import detailsIcon from "../../assets/launchdarkly-details.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LaunchDarklyEvent } from "../../../EventGroup/LaunchDarkly";

export interface LaunchDarklyDetailsProps {
  event: LaunchDarklyEvent;
}

const iconDimentions = { height: "50%", width: "50%" };

const useDetails = (event: LaunchDarklyEvent) => {
  return useMemo(() => {
    const result = [];
    if (event.description) {
      result.push({
        label: "",
        value: removeMarkdown(event.description),
      });
    }

    if (event.comment) {
      result.push({
        label: "Comment",
        value: `"${event.comment || " "}"`,
      });
    }
    return result;
  }, [event]);
};

export const LaunchDarklyDetails: React.FC<LaunchDarklyDetailsProps> = ({
  event,
}) => {
  const details = useDetails(event);
  if (!details.length) {
    return null;
  }
  return (
    <EventDetailsSection
      title="Details"
      icon={detailsIcon}
      iconDimensions={iconDimentions}
    >
      <DetailsList details={details} rows={2} />
    </EventDetailsSection>
  );
};
