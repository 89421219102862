import React, { useMemo } from "react";
import styled from "styled-components";

import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../shared/styles";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { InsightsDataTable } from "../../shared/components/InsightsDataTable/InsightsDataTable";
import { TOTAL_THROTTLED_TIME_THRESHOLD } from "../../insightsConstants";

import {
  explanationTitle1,
  explanationTitle2,
  explanationBlock1,
  explanationBlock2,
  getTableDescription,
} from "./ThrottledApplicationsTexts";
import {
  getThrottledApplicationRows,
  throttledApplicationsColumns,
} from "./tableUtils";

const TableContainer = styled.div`
  && {
    .MuiDataGrid-row {
      height: 80px;
    }
  }
`;

export const ThrottledApplicationsDrawerContent: React.FC = () => {
  const {
    throttledApplications: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const rows = useMemo(() => {
    if (!data?.affectedContainers) return [];
    return getThrottledApplicationRows(data.affectedContainers);
  }, [data?.affectedContainers]);

  return (
    <div>
      <InsightsDrawerTitle>{explanationTitle1}</InsightsDrawerTitle>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock1}
      </InsightsDrawerContent>
      <InsightsDrawerContent variant={"body2"}>
        {explanationBlock2}
      </InsightsDrawerContent>
      <InsightsDrawerTitle>{explanationTitle2}</InsightsDrawerTitle>
      <InsightsDrawerContent variant={"body2"}>
        {getTableDescription(TOTAL_THROTTLED_TIME_THRESHOLD)}
      </InsightsDrawerContent>
      <TableContainer>
        <InsightsDataTable columns={throttledApplicationsColumns} rows={rows} />
      </TableContainer>
    </div>
  );
};
