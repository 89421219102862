import {
  GridColDef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

export const columns: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: () => null,
  },
  {
    field: "pod",
    headerName: "Pod Name",
    flex: 1,
    sortable: false,
  },
  {
    field: "timeThrottled",
    headerName: "Time Throttled",
    valueGetter: ({ row }) =>
      `${row.hours}h (${Math.round(row.fraction * 100).toString()}%)`,
    flex: 1,
  },
];
