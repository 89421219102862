import React, { useMemo } from "react";
import styled from "styled-components";

import { blackGreyText } from "../../../../../Colors";
import Plus from "../../../assets/plus.svg?react";
import { ConditionVariables } from "../../../common/types";

import Selector from "./Selector";

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;
`;

const StyledButton = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  border-radius: 4px;
  padding: 0.1rem 0.25rem;
  :hover {
    background-color: #eceff2;
  }
`;

const BlackTextLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${blackGreyText};
`;
const BoldBlackTextLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: Roboto;
  color: ${blackGreyText};
`;

const PlusIcon = styled(Plus)<{ color: string }>`
  color: ${({ color }) => color};
`;

interface SelectorsGroupProps {
  id: string;
  options: string[];
  title: string;
  buttonText: string;
  buttonColor: string;
  values: Record<string, unknown>;
  handleChange: (values: Record<string, unknown>) => void;
  minSelectors: number;
  cluster?: string;
}

const SelectorsGroup: React.FC<SelectorsGroupProps> = ({
  id,
  options,
  title,
  buttonText,
  buttonColor,
  values,
  handleChange,
  minSelectors,
  cluster,
}) => {
  const availableValues = Object.fromEntries(
    Object.entries(values).filter(([key]) => key !== "condition")
  );

  const availableOptions = useMemo(() => {
    if (!availableValues) {
      return options;
    }

    return options.filter((o) => {
      return !Object.keys(availableValues).includes(o);
    });
  }, [availableValues, options]);

  const addSelector = () => {
    handleChange({
      ...values,
      [availableOptions[0]]: [""],
      condition: values["condition"] ?? ConditionVariables.OR,
    });
  };

  const removeSelector = (key: string) => {
    if (values) {
      const copy = { ...values };
      delete copy[key];
      if (Object.keys(copy).length === 1 && copy["condition"])
        delete copy["condition"];
      handleChange(copy);
    }
  };

  const handleSelectorChange = (
    key: string,
    value: unknown,
    oldKey?: string
  ) => {
    const copy = { ...values };
    if (oldKey) {
      delete copy[oldKey];
    }

    handleChange({
      ...copy,
      [key]: Array.isArray(value)
        ? value
        : (value as string).split(",").map((elem) => elem.trim()),
    });
  };

  const handleConditionChange = (condition: string) => {
    handleChange({ ...values, condition });
  };

  return (
    <>
      <BoldBlackTextLabel>{title}</BoldBlackTextLabel>
      <GroupContainer>
        {availableValues &&
          Object.entries(availableValues).map(([k, v], i) => {
            return (
              <>
                <Selector
                  id={`${id}-${k}`}
                  options={availableOptions}
                  key={k}
                  value={v as string}
                  selectorKey={k}
                  handleChange={handleSelectorChange}
                  condition={values["condition"] as string}
                  handleConditionChange={handleConditionChange}
                  handleClose={removeSelector}
                  enableDeletion={
                    Object.entries(availableValues).length > minSelectors
                  }
                  index={i}
                  cluster={cluster}
                />
              </>
            );
          })}

        {availableOptions.length !== 0 && (
          <StyledButton onClick={() => addSelector()}>
            <PlusIcon color={buttonColor} />
            <BlackTextLabel>{buttonText}</BlackTextLabel>
          </StyledButton>
        )}
      </GroupContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SelectorsGroup;
