import React, { useState } from "react";
import OpenInFull from "@mui/icons-material/OpenInFull";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { YamlEditor } from "../YamlView";

interface Props {
  yaml: string | undefined;
}

export const EnlargeYamlButton: React.FC<Props> = ({ yaml }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <OpenInFull />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <YamlEditor yaml={yaml} height="800px" />
        </DialogContent>
      </Dialog>
    </>
  );
};
