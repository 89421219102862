import {
  GridColDef,
  GridColumnsInitialState,
  GridSortingInitialState,
} from "@mui/x-data-grid-pro";

import {
  ApiServerUrlCell,
  ApiServerUrlEditState,
  IsSyncedCell,
} from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/cells";
import { RbacClusterStatusComputedData } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/rows";

export const columnsInitialState: GridColumnsInitialState = {
  columnVisibilityModel: {
    clusterName: true,
    isSynced: true,
    error: false,
    lastUpdatedKomodorRbac: false,
    lastUpdatedCluster: false,
    apiServerUrl: true,
  },
  orderedFields: [
    "clusterName",
    "isSynced",
    "error",
    "lastUpdatedKomodorRbac",
    "lastUpdatedCluster",
    "apiServerUrl",
  ],
  dimensions: {
    clusterName: {
      width: 300,
      maxWidth: -1,
    },
    isSynced: {
      width: 200,
      maxWidth: -1,
    },
    error: {
      width: 200,
      maxWidth: -1,
    },
    lastUpdatedKomodorRbac: {
      width: 300,
      maxWidth: -1,
    },
    lastUpdatedCluster: {
      width: 300,
      maxWidth: -1,
    },
    apiServerUrl: {
      minWidth: 300,
      flex: 1,
    },
  },
};

export const columnsInitialSorting: GridSortingInitialState = {
  sortModel: [
    {
      field: "isSynced",
      sort: "desc", // Boolean DESC == True first
    },
    {
      field: "clusterName",
      sort: "asc",
    },
  ],
};

export const getColumns: ({
  onApiServerUrlChange,
}: ApiServerUrlEditState) => GridColDef<RbacClusterStatusComputedData>[] = ({
  onApiServerUrlChange,
  setHasValidationError,
}) => {
  return [
    {
      field: "clusterName",
      headerName: "Cluster",
      type: "string",
    },
    {
      field: "isSynced",
      headerName: "Synchronized",
      headerAlign: "left",
      type: "boolean",
      renderCell: (params) => {
        return <IsSyncedCell {...params} />;
      },
    },
    {
      field: "error",
      headerName: "Error",
      type: "string",
    },
    {
      field: "lastUpdatedKomodorRbac",
      headerName: "Permissions Last Updated",
      type: "dateTime",
    },
    {
      field: "lastUpdatedCluster",
      headerName: "Permissions Last Synchronized",
      type: "dateTime",
    },
    {
      field: "apiServerUrl",
      headerName: "API Server URL",
      type: "string",
      renderCell: (params) => {
        return (
          <ApiServerUrlCell
            {...params}
            onApiServerUrlChange={onApiServerUrlChange}
            setHasValidationError={setHasValidationError}
          />
        );
      },
    },
  ];
};
