import EventGroup from "../../../../../common/EventGroup";
import { ucFirstWord } from "../../../../../../shared/utils/serviceHelpers";

import EventIcon from "./icons/event.svg?react";
import HiddenIcon from "./icons/hidden.svg?react";
import StatusIcon from "./icons/status.svg?react";
import DetailsIcon from "./icons/details.svg?react";

export const defaultHiddenTypes = new Set([
  "Automatic Deploy",
  "External Status",
]);

export const eventFilterSelectors: {
  [key: string]: (g: EventGroup) => string;
} = {
  t: (g) => g.type,
  s: (g) => g.status,
  d: (g) => g.details,
};

interface EventFilterDefinition {
  selectorKey: string;
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  formatLabel: (v: string) => string;
  filterOption: (v: string) => boolean;
}

export const eventFilterDefinitions: EventFilterDefinition[] = [
  {
    selectorKey: "t",
    title: "Event type",
    Icon: EventIcon,
    formatLabel: (v) => v,
    filterOption: (v) => !defaultHiddenTypes.has(v),
  },
  {
    selectorKey: "t",
    title: "Hidden type",
    Icon: HiddenIcon,
    formatLabel: (v) => v,
    filterOption: (v) => defaultHiddenTypes.has(v),
  },
  {
    selectorKey: "s",
    title: "Status",
    Icon: StatusIcon,
    formatLabel: ucFirstWord,
    filterOption: () => true,
  },
  {
    selectorKey: "d",
    title: "Details",
    Icon: DetailsIcon,
    formatLabel: (v) => v || "(empty)",
    filterOption: () => true,
  },
];
