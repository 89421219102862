import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { isUndefined } from "lodash";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";

import {
  ImpactGroupIdentifier,
  ViolationImpactDataAggregation,
} from "../../../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import {
  impactGroupItemsOrder,
  impactGroupToInfo,
} from "../../../../../constants/reliabilityConstants";

import { ImpactAggregationsTitle } from "./ImpactAggregationsTitle";
import { ImpactItem } from "./ImpactItem";

const Container = styled.fieldset`
  position: relative;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  padding: 12px;
  margin-top: 14px;
  width: fit-content;
  min-width: 136px;
`;

const ImpactItemsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 0 16px;
  }
`;

const {
  aggregations: { container: aggregationsContainerArialLabel },
} = reliabilityArialLabels;

type ImpactAggregationsProps = {
  impactDataAggregations: ViolationImpactDataAggregation[];
  groupIdentifier: string;
  className?: string;
};

export const ImpactAggregations: React.FC<ImpactAggregationsProps> = ({
  impactDataAggregations,
  groupIdentifier,
  className,
}) => {
  const impactGroupIdentifier = groupIdentifier as ImpactGroupIdentifier;

  const sortImpactDataAggregations = useCallback(
    (a: ViolationImpactDataAggregation, b: ViolationImpactDataAggregation) => {
      if (!impactGroupItemsOrder?.[impactGroupIdentifier]) return 0;
      const impactGroup = impactGroupItemsOrder?.[impactGroupIdentifier];
      const categoryA = impactGroup?.[a.category];
      const categoryB = impactGroup?.[b.category];
      if (isUndefined(categoryA) || isUndefined(categoryB)) return 0;
      return categoryA - categoryB;
    },
    [impactGroupIdentifier]
  );

  const content = useMemo(() => {
    const filteredImpactDataAggregations = impactDataAggregations?.filter(
      (impactDataAggregation) => {
        const { category } = impactDataAggregation;
        return !!impactGroupToInfo[category];
      }
    );

    return filteredImpactDataAggregations
      ?.sort(sortImpactDataAggregations)
      .map((impactDataAggregation, idx) => {
        const { category, value } = impactDataAggregation;
        const key = `${category}-${value}-${idx}`;
        return (
          <React.Fragment key={key}>
            <ImpactItem impactDataAggregation={impactDataAggregation} />
            {idx < filteredImpactDataAggregations.length - 1 && (
              <StyledDivider orientation="vertical" flexItem />
            )}
          </React.Fragment>
        );
      });
  }, [impactDataAggregations, sortImpactDataAggregations]);

  if (!impactDataAggregations || impactDataAggregations.length === 0) {
    return null;
  }

  return (
    <Container
      aria-label={aggregationsContainerArialLabel}
      className={className}
    >
      <ImpactAggregationsTitle />
      <ImpactItemsContainer>{content}</ImpactItemsContainer>
    </Container>
  );
};
