import yaml from "js-yaml";
import { subDays, subMilliseconds } from "date-fns";
import { isEqual } from "lodash";

import {
  AllocationFiltersTimeWindow,
  RightSizingRecommendationsState,
  RightSizingRecommendationsStrategiesState,
} from "../types/costOptimizationTypes";
import {
  CostRightSizingSummary,
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../generated/metricsApi";
import { getFixedNumber } from "../../../shared/utils/formatCurrency";
import { getStartOfDayInUtc } from "../../../shared/utils/dateUtils";
import { getRecommendationByStrategy } from "../components/shared/RecommendationsModal/getRecommendationsByStrategy";
import {
  hasServerResChanged,
  ServerRes,
} from "../../../shared/utils/hasServerResChanged";
import { CommonApiResponse } from "../../../shared/types/commonApiResponse";
import { isInitialCancelFetchState } from "../../../shared/hooks/common-api/utils";

import {
  areRequestParamsEqualToCurrentFilters,
  CostFiltersRequestParams,
} from "./areRequestParamsEqualToCurrentFilters";

type TimeWindowOption = AllocationFiltersTimeWindow;

const getFromEpochByTimeWindow = (
  timeWindow: TimeWindowOption,
  lastVisitStartTime: Date
): number => {
  switch (timeWindow) {
    case "30d":
      return subDays(lastVisitStartTime, 30).getTime();
    case "14d":
      return subDays(lastVisitStartTime, 14).getTime();
    case "7d":
      return subDays(lastVisitStartTime, 7).getTime();
    case "24h":
      return subDays(lastVisitStartTime, 1).getTime();
  }
};

type GetEpochFromTimeFrameReturnType = {
  fromEpoch: number;
  toEpoch: number;
};

export const getMonthlyAmount = ({
  recommendationStrategies,
  rowData,
}: {
  recommendationStrategies: RightSizingRecommendationsStrategiesState;
  rowData?: CostRightSizingSummary;
}) => {
  if (!rowData) return;
  const strategiesMapper = {
    conservative: rowData.potentialSavingConservative,
    aggressive: rowData.potentialSavingAggressive,
    moderate: rowData.potentialSavingModerate,
  };
  if (!recommendationStrategies[rowData.id]) return rowData.potentialSaving;

  return strategiesMapper[recommendationStrategies[rowData.id]];
};

export const getEpochFromTimeFrame = (
  timeWindow: TimeWindowOption,
  lastVisitStartTime: number
): GetEpochFromTimeFrameReturnType => {
  const startOfDayInUtc = getStartOfDayInUtc(lastVisitStartTime);

  return {
    fromEpoch: getFromEpochByTimeWindow(timeWindow, startOfDayInUtc),
    toEpoch: subMilliseconds(startOfDayInUtc, 1).getTime(),
  };
};

export const formatRowYaml = (
  row: RightSizingCostSummaryByService,
  strategy: OptimizationStrategy
) => {
  const { cpuRecommendation, memoryRecommendation } =
    getRecommendationByStrategy(row, strategy);

  const wantedCpuValue = Math.min(
    cpuRecommendation ?? 0,
    row.cpuRequestMiliCore ?? 0
  );
  const wantedMemoryValue = Math.min(
    memoryRecommendation ?? 0,
    row.memoryRequestMiB ?? 0
  );

  const formattedJson = {
    resources: {
      requests: {
        cpu: wantedCpuValue,
        memory: wantedMemoryValue,
      },
    },
  };

  try {
    return yaml.dump(formattedJson);
  } catch {
    return "";
  }
};

export const shouldUpdateRecommendationState = (
  prevRecommendationState: RightSizingRecommendationsState,
  newRecommendationState: RightSizingRecommendationsState
): boolean => {
  if (prevRecommendationState.loading !== newRecommendationState.loading) {
    return !isEqual(prevRecommendationState, newRecommendationState);
  }
  return false;
};

export const formatFixedNumber = (num: number): string => {
  if (!num) return "0";

  const fixed = getFixedNumber(num);
  return new Intl.NumberFormat("en-US").format(fixed);
};

type UpdateStoreWithResponseParams = {
  serverRes: CommonApiResponse<object>;
  currentState: ServerRes;
  params: CostFiltersRequestParams;
};

export const shouldUpdateStoreWithResponse = ({
  serverRes,
  currentState,
  params,
}: UpdateStoreWithResponseParams) => {
  return (
    hasServerResChanged(serverRes, currentState) &&
    !isInitialCancelFetchState(serverRes) &&
    areRequestParamsEqualToCurrentFilters(serverRes, params)
  );
};
