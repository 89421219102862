import React from "react";
import Typography from "@mui/material/Typography";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";
import { Details } from "./Details";
import { HpaReachedMaxContextProvider } from "./context/HpaReachedMaxContext";

export const HpaReachedMax: React.FC = () => {
  const { data } = useViolation();
  const { komodorUid, headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  if (!data?.data.violation || !komodorUid) return null;

  return (
    <HpaReachedMaxContextProvider>
      <DrawerBase
        header={headerProps}
        summary={{ data: summaryProps }}
        content={
          <OverviewContent
            whatDescription={
              <>
                {getWhatDescription}
                <Details violation={data.data.violation} />
              </>
            }
            whyDescription={getWhyDescription}
            howDescription={
              <Typography variant="body2" color="text.primary">
                {howDescription}
              </Typography>
            }
          />
        }
      />
    </HpaReachedMaxContextProvider>
  );
};
