import React, { useCallback, useState } from "react";
import { Legend } from "recharts";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { GraphContainerWithStatus } from "../../../../../../../../../Metrics/GraphContainerWithStatus";
import { useGetCorrelatedDeployEvents } from "../../../shared/metrics/hooks/useGetCorrelatedDeployEvents";
import { useFetchWorkloadReplicasMetricsData } from "../hooks/useFetchWorkloadReplicasMetricsData";
import { useGetGraphData } from "../hooks/useGetGraphData";

import { getLines, tooltipContent, legendItems } from "./GraphParts";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { Uid } from "@/shared/utils/generateUid";
import { MetricsGraphContainer } from "@/components/Metrics/graphs/MetricsGraphContainer";
import { GenericLegend } from "@/components/Metrics/GenericLegend/GenericLegend";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

type ReplicasOverTimeGraphProps = {
  komodorUid: string;
  fromEpoch: number;
  toEpoch: number;
};

const Y_AXIS_PROPS = {
  allowDecimals: false,
};

const GraphWrapper = styled.div`
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  padding: 16px 16px 0 16px;
`;

export const ReplicasOverTimeGraph: React.FC<ReplicasOverTimeGraphProps> = ({
  komodorUid,
  fromEpoch,
  toEpoch,
}) => {
  const [timeframe, setTimeframe] = useState({ fromEpoch, toEpoch });
  const { data, isFetching, error } = useFetchWorkloadReplicasMetricsData({
    fromEpoch: timeframe.fromEpoch,
    toEpoch: timeframe.toEpoch,
    komodorUid,
  });

  const { cluster = "" } = parseKomodorUid(komodorUid) ?? {};

  const graphContainerProps = {
    uid: {
      cluster,
    } as Uid,
    title: "Replicas over time",
    hasGraphData: !error && !isFetching && !!data?.data?.ready.length,
    error: error ? "Something went wrong" : null,
    loading: isFetching,
  };

  const graphData = useGetGraphData(data);
  const events = useGetCorrelatedDeployEvents({
    komodorUid: komodorUid,
    graphDataPoints: graphData,
  });

  const setTimeframeOnZoom = useCallback(
    (start: Date, end: Date) => {
      setTimeframe({
        fromEpoch: Math.round(start.getTime() / 1000),
        toEpoch: Math.round(end.getTime() / 1000),
      });
    },
    [setTimeframe]
  );

  return (
    <GraphWrapper>
      <GraphContainerWithStatus {...graphContainerProps}>
        <MetricsGraphContainer
          setTimeframe={setTimeframeOnZoom}
          tooltipContent={tooltipContent}
          ariaLabels={
            reliabilityArialLabels.violationDrawer.singlePointOfFailure
              .replicasOverTimeGraph
          }
          tickFormatter={(tick) => tick}
          label={"Replicas"}
          data={graphData}
          events={events}
          yAxisProps={Y_AXIS_PROPS}
        >
          {getLines()}
          <Legend content={<GenericLegend items={legendItems} />} />
        </MetricsGraphContainer>
      </GraphContainerWithStatus>
    </GraphWrapper>
  );
};
