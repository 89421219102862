import { useCallback } from "react";
import { subDays } from "date-fns";

import { TimeWindow } from "../constants/violationFiltersConstants";
import { useReliabilityStore } from "../store/reliabilityStore";
import { selectInitialTime } from "../store/reliabilityStoreSelectors";

export const useTimeWindowToEpoch = () => {
  const initialTime = useReliabilityStore(selectInitialTime);

  return useCallback(
    (timeWindow: string | undefined) => {
      let createdFromEpoch = 0;
      switch (timeWindow as TimeWindow) {
        case "1d": {
          createdFromEpoch = subDays(initialTime, 1).getTime();
          break;
        }
        case "7d": {
          createdFromEpoch = subDays(initialTime, 7).getTime();
          break;
        }
        case "14d": {
          createdFromEpoch = subDays(initialTime, 14).getTime();
          break;
        }
        case "30d": {
          createdFromEpoch = subDays(initialTime, 30).getTime();
          break;
        }
      }

      if (!createdFromEpoch) return undefined;

      return {
        createdFromEpoch: createdFromEpoch.toString(),
        createdToEpoch: initialTime.toString(),
      };
    },
    [initialTime]
  );
};
