import React, { useEffect, useState } from "react";

import { UpgradeRequiredModalStep } from "../../../../../../common/UpdateAgentModal/types";
import UpgradeRequired from "../../../../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { useCreationPageContext } from "../context/CreationPageContext";

export const CreationPageUpgradeAgent: React.FC = () => {
  const {
    errorState: { agentVersionNeedsUpgradeError },
    setErrorState,
  } = useCreationPageContext();
  const [open, setOpen] = useState(agentVersionNeedsUpgradeError);

  useEffect(() => {
    setOpen(agentVersionNeedsUpgradeError);
  }, [agentVersionNeedsUpgradeError]);

  const onClose = () => {
    setErrorState({ agentVersionNeedsUpgradeError: false });
  };

  if (!open) return null;
  return (
    <UpgradeRequired
      handleClose={onClose}
      updateMessage={null}
      upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES}
      desiredFeature="ATM live results data"
      initialStep={UpgradeRequiredModalStep.ShowCommand}
    />
  );
};
