import React, { useMemo } from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { Label } from "../../../resources";
import { lightMuiTooltipStyle } from "../../../../../shared/styles/tooltip";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { ZIndex } from "../../../../../constants/zIndex";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const TooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  gap: 8px;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const TooltipContent: React.FC<{ images: string[] }> = ({ images }) => {
  const content = useMemo(
    () =>
      images.map((image) => (
        <Typography key={image} variant="body3">
          {image}
        </Typography>
      )),
    [images]
  );
  return <TooltipContentContainer>{content}</TooltipContentContainer>;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const ImagesValue: React.FC<{ images: string[] }> = ({ images }) => {
  const [firstImage, ...restOfImages] = images;

  return (
    <Container>
      <Typography
        variant="h5"
        color="text.primary"
        aria-label={AriaLabels.ResourceView.Header.Metadata.Image}
      >
        {firstImage}
      </Typography>
      {images.length > 1 && (
        <Tooltip
          PopperProps={{
            style: { zIndex: ZIndex.AboveModal },
          }}
          title={<TooltipContent images={restOfImages} />}
          componentsProps={lightMuiTooltipStyle}
          placement="bottom"
          arrow={false}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            aria-label={AriaLabels.ResourceView.Header.Metadata.AdditionalImage}
          >
            +{restOfImages.length}
          </Typography>
        </Tooltip>
      )}
    </Container>
  );
};

export const useGetImageLabel = (
  images: (string | undefined)[] | undefined
): Label | undefined => {
  const filteredImages = images?.filter((image): image is string => !!image);
  if (!filteredImages || !filteredImages.length) return;

  return {
    name: "image",
    value: <ImagesValue images={filteredImages} />,
  };
};
