import { merge } from "lodash";
import { Node } from "react-flow-renderer";

import {
  NetworkMapGraphResponseEdgesInner,
  KomodorServicesResponseDataInner,
} from "../../../generated/resourcesApi";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import {
  X_TO_REDUCE,
  INITIAL_X,
  INITIAL_Y,
  SPACE_BETWEEN_NODES_Y,
  SPACE_BETWEEN_NODES_X,
} from "../constants";
import {
  ConnectionPoints,
  KomodorServicesResponseMapper,
  NodeData,
} from "../types";
import { Resource } from "../../ResourceView/tabs/DependenciesTab/DependenciesTab";

const getConnectionPoints = (
  komodorUid: string,
  edges: NetworkMapGraphResponseEdgesInner[]
): ConnectionPoints | undefined => {
  const sourceEdges = edges.filter((edge) => edge.source === komodorUid);
  const targetEdges = edges.filter((edge) => edge.destination === komodorUid);

  return {
    source: sourceEdges.length > 0,
    target: targetEdges.length > 0,
  };
};

const getResourceId = (
  nodeData: KomodorServicesResponseDataInner
): string | undefined => {
  const resourceIdFromHealthData = nodeData?.health_state?.id;
  const resourceIdFromDeployData = nodeData?.deploy_state?.id;

  return resourceIdFromHealthData || resourceIdFromDeployData;
};

export const createKomoUidToNodeDataMapper = (
  nodesData: KomodorServicesResponseDataInner[] | undefined
): KomodorServicesResponseMapper => {
  if (!nodesData) return {};
  return nodesData.reduce((acc, nodeData) => {
    const komodorUid = buildKomodorUid({
      kind: nodeData.kind || "",
      clusterName: nodeData.k8sClusterName || "",
      namespace: nodeData.namespace || "",
      resourceName: nodeData.displayName || "",
    });
    return { ...acc, [komodorUid]: nodeData };
  }, {} as KomodorServicesResponseMapper);
};

export const fitMapViewToDrawerArea = (
  node: Node<NodeData>
): Node<NodeData> => {
  return {
    ...node,
    position: {
      x: node.position.x - X_TO_REDUCE,
      y: node.position.y,
    },
  };
};

export const mapNodeConnectionPoints = (
  node: Node<NodeData>
): Node<NodeData> => {
  return {
    ...node,
    position: {
      x: node.position.x - X_TO_REDUCE,
      y: node.position.y,
    },
  };
};

export const getHighlightedNode = (
  node: Node<NodeData>,
  resource: Resource
): Node<NodeData> => {
  const { resourceDetails } = node.data;

  return {
    ...node,
    data: {
      ...node.data,
      cluster: resource.cluster,
      name: resource.name,
      namespace: resource.namespace,
      resourceDetails: {
        resourceId: node?.data?.resourceDetails?.resourceId,
        replicas: {
          desiredCount: resourceDetails.replicas.desiredCount,
          availableCount: resourceDetails.replicas.availableCount,
        },
        isHealthy:
          resource.healthy !== undefined
            ? resource.healthy
            : node.data.resourceDetails.isHealthy,
      },
    },
  };
};

export const overrideNodeWithResourceClusterAndNamespace = (
  resource: Resource,
  node: Node<NodeData>
): Node<NodeData> => {
  return merge(node, {
    data: {
      cluster: node.data?.cluster ?? resource.cluster,
      namespace: node.data?.namespace ?? resource.namespace,
    },
  } as Partial<Node<NodeData>>);
};

export const isNodeIdMatchUid = (
  komodorUid: string | undefined,
  nodeData: KomodorServicesResponseDataInner
): boolean => {
  const {
    kind = "",
    k8sClusterName = "",
    namespace = "",
    displayName = "",
  } = nodeData;
  const reConstructedUid = buildKomodorUid({
    kind,
    clusterName: k8sClusterName,
    namespace,
    resourceName: displayName,
  });
  return komodorUid === reConstructedUid;
};

export const exendNodeWithPosition = ({
  node,
  highlightedNode,
  leftNodesIds,
  rightNodesIds,
}: {
  node: Node<NodeData>;
  highlightedNode: Node<NodeData> | null;
  leftNodesIds: string[];
  rightNodesIds: string[];
}): Node<NodeData> => {
  if (node.id === highlightedNode?.id) {
    return { ...node, position: { x: INITIAL_X, y: INITIAL_Y } };
  }
  if (leftNodesIds.includes(node.id)) {
    const y =
      INITIAL_Y +
      (leftNodesIds.indexOf(node.id) - Math.floor(leftNodesIds.length / 2)) *
        SPACE_BETWEEN_NODES_Y;
    return { ...node, position: { x: INITIAL_X - SPACE_BETWEEN_NODES_X, y } };
  }
  if (rightNodesIds.includes(node.id)) {
    const y =
      INITIAL_Y +
      (rightNodesIds.indexOf(node.id) - Math.floor(rightNodesIds.length / 2)) *
        SPACE_BETWEEN_NODES_Y;
    return { ...node, position: { x: INITIAL_X + SPACE_BETWEEN_NODES_X, y } };
  }

  return node;
};

export const createNode = ({
  komodorUid,
  resource,
  uidToServiceMap,
  edges,
}: {
  komodorUid: string;
  resource: Resource;
  uidToServiceMap: KomodorServicesResponseMapper;
  edges: NetworkMapGraphResponseEdgesInner[];
}): Node<NodeData> => {
  const isHighlighted = isNodeIdMatchUid(komodorUid, {
    namespace: resource.namespace,
    k8sClusterName: resource.cluster,
    displayName: resource.name,
    kind: resource.kind,
  });
  const nodeData = uidToServiceMap[komodorUid];

  const position = { x: INITIAL_X, y: INITIAL_Y };

  const node = {
    id: komodorUid,
    type: "customInput",
    data: {
      namespace: nodeData?.namespace || "",
      kind: nodeData?.kind || "",
      cluster: resource?.cluster,
      name: nodeData?.displayName || "",
      isHighlighted,
      resourceDetails: {
        replicas: {
          desiredCount: nodeData?.deploy_state?.desiredReplicas || 0,
          availableCount: nodeData?.deploy_state?.readyReplicas || 0,
        },
        resourceId: getResourceId(nodeData),
        isHealthy: getIsHealthy(nodeData?.health_state?.healthStatus),
      },
      connectionPoints: getConnectionPoints(komodorUid, edges),
    },
    position,
  };

  return node as Node<NodeData>;
};

export const getNormalizedDependencyNodes = (
  resource: Resource,
  nodes: Node<NodeData>[] | undefined
): Node<NodeData>[] => {
  if (!nodes) return [];
  return nodes.map((node) => {
    if (node.data.isHighlighted) {
      return getHighlightedNode(node, resource);
    }

    return overrideNodeWithResourceClusterAndNamespace(resource, node);
  });
};

export const createGraphNodes = (
  nodes: string[],
  edges: NetworkMapGraphResponseEdgesInner[],
  resource: Resource,
  uidToServiceMap: KomodorServicesResponseMapper
): Node<NodeData>[] => {
  return nodes.map((komodorUid) =>
    createNode({ komodorUid, resource, uidToServiceMap, edges })
  );
};

const getIsHealthy = (healthStatus: string | undefined): boolean | null => {
  if (!healthStatus) return null;
  return healthStatus === "HEALTHY";
};
