import React from "react";

import { useAddonContext } from "../context/useAddonContext";

import { getUnsupportedWorkspaceText } from "./utils";

import { WorkspaceGenericEmptyState } from "@/components/workspaces/WorkspaceGenericEmptyState";

export const UnsupportedWorkspaceView: React.FC = () => {
  const { addonEntity } = useAddonContext();
  return (
    <WorkspaceGenericEmptyState
      title={getUnsupportedWorkspaceText(addonEntity)}
    />
  );
};
