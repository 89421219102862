import React from "react";

import { tickTextProps } from "./tickUtils";

export const TextGroup: React.FC<{
  x: number;
  y: number;
  fullText: string;
  children?: React.ReactNode;
}> = ({ x, y, fullText, children }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text {...tickTextProps}>{children}</text>
      <title>{fullText}</title>
    </g>
  );
};
