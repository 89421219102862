import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { useSelectedPodsForEvents } from "../../../eventsView/PodsSelector/SelectedPodsProvider";
import { useBuildKomodorUidsFromPodUids } from "../nativePodEvent/useNativePodEvents";
import { useGetGroupedPodEvents } from "../../../../shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { PodGroups } from "../../../../generated/resourcesApi";
import { deconstructUid } from "../../../../shared/utils/generateUid";

export const useGetGroupedPodEventForPodNames = (
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
  enabled: boolean
): { data: PodGroups[]; isFetching: boolean } => {
  const { selectedPodUidsForEvents } = useSelectedPodsForEvents();
  const podUids = useBuildKomodorUidsFromPodUids(
    selectedPodUidsForEvents?.map((pod) => deconstructUid(pod)) ?? null
  );

  const { data: groupedPodEventsData, isFetching } = useGetGroupedPodEvents(
    {
      komodorUids: podUids,
      fromEpoch: timeWindow?.start.getTime(),
      toEpoch: timeWindow?.end.getTime(),
    },
    enabled
  );

  return { data: groupedPodEventsData?.data ?? [], isFetching };
};
