import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";

import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import { selectUiElementsRect } from "../../../../store/overviewPageStoreSelectors";

const PAGINATION_SIZE_OPTIONS = [10, 20, 50];
const DEFAULT_PAGE_SIZE = PAGINATION_SIZE_OPTIONS[0];
const MIN_VIEW_WIDTH = 1200;

const StyledDataGrid = styled(DataGridPro)<{
  hidepagination: boolean;
  realignpagination: boolean;
}>`
  && {
    border-color: ${muiColors.gray[400]};
    & [role="row"] {
      box-shadow: 0 1px 0 ${muiColors.gray[400]};
      > .MuiDataGrid-cell {
        padding: 12px;
      }
    }
    .MuiDataGrid-virtualScrollerContent {
      border-top: 1px solid ${muiColors.gray[400]};
    }
    .MuiDataGrid-footerContainer {
      display: ${({ hidepagination }) =>
        hidepagination ? "space-between" : "flex"};
      border-top: 1px solid ${muiColors.gray[400]};
    }
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      outline: none;
    }
    .MuiDataGrid-footerContainer {
      justify-content: ${({ realignpagination }) =>
        realignpagination ? "flex-start" : "space-between"};
    }
  }
`;

type InsightsDataTableProps<T> = {
  rows: T[];
  columns: GridColDef[];
  props?: Omit<DataGridProProps, "rows" | "columns">;
};

export const InsightsDataTable = <T extends object>({
  columns,
  rows,
  props,
}: InsightsDataTableProps<T>) => {
  const { pageContainer } = useOverviewPageStore(selectUiElementsRect);
  const hidePagination = rows.length <= DEFAULT_PAGE_SIZE;
  const [realignPagination, setRealignPagination] = useState(false);

  useEffect(() => {
    if (!pageContainer?.width) return;
    setRealignPagination(pageContainer.width < MIN_VIEW_WIDTH);
  }, [pageContainer?.width]);

  return (
    <StyledDataGrid
      columns={columns}
      rows={rows}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: DEFAULT_PAGE_SIZE,
          },
        },
      }}
      pageSizeOptions={PAGINATION_SIZE_OPTIONS}
      sx={{
        fontSize: "12px",
      }}
      hidepagination={hidePagination}
      realignpagination={realignPagination}
      columnHeaderHeight={40}
      getRowHeight={() => "auto"} // allow line breaks and no text ellipsis
      autoHeight={true}
      {...props}
      pagination
    ></StyledDataGrid>
  );
};
