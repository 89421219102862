import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { APIDeprecationsSupportingDataDeprecatedAPIsInner } from "../../../../../../../../../../../../generated/reliabilityApi";
import { CopyApiButton } from "../ApiColumnValue";
import { Link } from "../../../../../../violationDrawerStyles";

import { ResourcesTable } from "./ResourcesTable";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${muiColors.gray[50]};
`;

const ApiUpdateContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const PreviousApi = styled(Typography).attrs({
  variant: "body2",
  color: "text.secondary",
})`
  text-decoration: line-through;
`;

const NoApiReplacementContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const NoApiTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  clusterName: string | undefined;
  api: APIDeprecationsSupportingDataDeprecatedAPIsInner;
}

export const ApiDeepView: React.FC<Props> = ({
  api: { api, replacementApi, resources, kind },
  clusterName,
}) => {
  return (
    <Container>
      {replacementApi ? (
        <ApiUpdate currentApi={api} replacementApi={replacementApi} />
      ) : (
        <NoApiReplacement />
      )}
      <ResourcesTable
        clusterName={clusterName}
        resources={resources}
        kind={kind}
      />
    </Container>
  );
};

const ApiUpdate: React.FC<{
  currentApi: string | undefined;
  replacementApi: string | undefined;
}> = ({ currentApi, replacementApi }) => {
  return (
    <ApiUpdateContainer>
      <Typography variant="h5" color="text.primary">
        API update:
      </Typography>
      <PreviousApi>{currentApi}</PreviousApi>
      <ArrowForward fontSize="small" style={{ color: muiColors.gray[500] }} />
      <Typography variant="body2" color="text.primary">
        {replacementApi}
      </Typography>
      <CopyApiButton api={replacementApi ?? ""} />
    </ApiUpdateContainer>
  );
};

const NoApiReplacement: React.FC = () => (
  <NoApiReplacementContainer>
    <InfoOutlined fontSize="small" />
    <NoApiTextContainer>
      <Typography variant="h5">The API has been removed</Typography>
      <Typography variant="body2">
        No replacement API is available. Find alternative solutions using the{" "}
        <Link href="https://kubernetes.io/docs/reference/using-api/deprecation-guide/">
          Deprecated API Migration Guide
        </Link>
      </Typography>
    </NoApiTextContainer>
  </NoApiReplacementContainer>
);
