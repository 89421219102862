import styled from "styled-components";

const Grid = styled.div<{
  gridTemplateColumns?: string;
  gridColumnGap?: string;
}>`
  display: grid;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns ? `grid-template-columns: ${gridTemplateColumns};` : ""}
  ${({ gridColumnGap }) =>
    gridColumnGap ? `grid-column-gap: ${gridColumnGap};` : ""}
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Grid;
