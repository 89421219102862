import React from "react";
import { muiColors } from "@komodorio/design-system";
import Link from "@mui/material/Link";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const InfoLink: React.FC<{ text: string; url: string }> = ({
  text,
  url,
}) => {
  return (
    <Container>
      <HelpOutlineOutlined sx={{ fontSize: 16, color: muiColors.gray[500] }} />
      <Link target="_blank" rel="noreferrer" href={url} variant="body2">
        {text}
      </Link>
    </Container>
  );
};
