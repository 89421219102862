import { useMemo, useState } from "react";

import useIsFreeTier from "@/components/Freemium/useIsFreeTier";
import { useIsTrialEnded } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { Integration } from "@/components/integrations/management/definitions";
import { isSandbox } from "@/shared/utils/sandbox";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";

export const useIntegrations = ({
  integration,
  isInstalled,
  newTab,
}: {
  integration: Integration;
  isInstalled?: boolean;
  newTab?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const isFreeTier = useIsFreeTier();
  const isTrialEnded = useIsTrialEnded();
  const { onInstallClick, allowMultiple, title } = integration;

  const { canManageIntegrations } = useHasPermissions();
  const shouldLockIntegrations = useMemo(() => {
    return (
      (isFreeTier || isTrialEnded) &&
      ![
        "Kubernetes Cluster",
        "Opsgenie",
        "Slack",
        "PagerDuty",
        "Custom Events",
      ].includes(title)
    );
  }, [isFreeTier, isTrialEnded, title]);

  const disabled =
    (isInstalled && !allowMultiple) || !canManageIntegrations || isSandbox();

  const onInstallHandler = onInstallClick
    ? () => onInstallClick(newTab)
    : () => setShowModal(true);

  const showCheck = isInstalled ?? false;

  return {
    shouldLockIntegrations,
    onInstallHandler,
    disabled,
    showCheck,
    showModal,
    closeModal,
  };
};
