import {
  Boolean,
  Dictionary,
  Record,
  String,
  Number,
  Array as RTArray,
} from "runtypes";

import { getStatusGroup, StatusGroups } from "../../common/statusMapper";
import getAgeFromDate from "../utils/getAgeFromDate";

const Labels = Dictionary(String);
const ContainerStatuses = RTArray(
  Record({ restartCount: Number, ready: Boolean, name: String })
);

export function buildLabels(podLabels: unknown): string {
  let labels = "";
  if (Labels.guard(podLabels)) {
    labels = Object.entries(podLabels)
      .map(([key, value]) => `${key}=${value}`)
      .join(",");
  }
  return labels;
}

export function buildContainersData(podContainerStatuses: unknown): {
  ready: string;
  restarts: string;
  containerNames: string;
} {
  if (!ContainerStatuses.guard(podContainerStatuses)) {
    return {
      ready: "0/0",
      restarts: "0",
      containerNames: "",
    };
  }
  const containerNames = podContainerStatuses
    .map((container) => container.name)
    .join(",");

  const readyAndRestart = podContainerStatuses.reduce(
    (output, container) => {
      output.ready += container.ready ? 1 : 0;
      output.restarts += container.restartCount;
      return output;
    },
    { restarts: 0, ready: 0 }
  );
  return {
    ready: `${readyAndRestart.ready}/${podContainerStatuses.length}`,
    restarts: readyAndRestart.restarts.toString(),
    containerNames,
  };
}

export function buildAge(
  status: string,
  createdAt: string,
  eventTime: string
): string {
  return getStatusGroup(status, "pods") === StatusGroups.neutral
    ? getAgeFromDate(new Date(createdAt), new Date(eventTime))
    : getAgeFromDate(new Date(createdAt));
}
