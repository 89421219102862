import React from "react";

import { Severities } from "../pages/violations/ViolationsBreakdown/Severities";
import { useGetCountByViolationFilters } from "../pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/violationTableHooks";

export const ScopedSeverities: React.FC = () => {
  const severities = useGetCountByViolationFilters();

  if (!severities) return null;

  return <Severities severities={severities} />;
};
