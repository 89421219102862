import React from "react";
import styled from "styled-components";

import { LinesLoader } from "../../components/common/loaders/Line";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 0;
  }
`;

export const PageLoader: React.FC = () => {
  return (
    <Container>
      <LinesLoader />
    </Container>
  );
};
