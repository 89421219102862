import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography, Tag } from "@komodorio/design-system/deprecated";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";

import {
  ActionTaskErrorType,
  AuthorizationDenied,
  BadRequestError,
} from "../../../shared/hooks/useAgentTask/useAgentTask";

export interface ErrorMessageProps {
  actions: string;
}

const Container = styled.div`
  text-decoration: none;
  color: inherit;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  width: calc(100% - 2rem);
  height: 3.5rem;
  cursor: default;
  border-radius: 4px;
  overflow: hidden;
  border-left: 0.4rem solid ${palette.pink["500"]};
  padding: 0.5rem;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
  display: flex;
`;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const waitEffect = async (): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
};

export const ErrorMessage: React.FC<{
  reason: AuthorizationDenied | BadRequestError;
}> = ({ reason }) => {
  if (reason.type === ActionTaskErrorType.AuthorizationDenied) {
    return (
      <ErrorMessageAuthorizationDenied reason={reason as AuthorizationDenied} />
    );
  }
  if (reason.type === ActionTaskErrorType.BadRequest) {
    return <ErrorMessageBadRequest reason={reason as BadRequestError} />;
  }
  return null;
};

const ErrorMessageAuthorizationDenied: React.FC<{
  reason: AuthorizationDenied;
}> = ({ reason }) => {
  return (
    <Container>
      <div>
        <AlertCircleOutlined24 color={palette.pink["500"]} />
      </div>
      <div>
        <Typography size="medium" variant="title" color={palette.gray["800"]}>
          Action failed!
        </Typography>
        <Typography size="small" color={palette.gray["800"]}>
          You are missing permissions to perform the following action(s):
        </Typography>
        <Typography>
          <TagsContainer>
            <Tag color={palette.gray["100"]}>{reason.action}</Tag>
            {`on`}
            <Tag color={palette.gray["100"]}>
              {`cluster: ${reason.resource.cluster}`}
            </Tag>
            {reason.resource.namespace !== undefined && (
              <Tag color={palette.gray["100"]}>
                {` namespace: ${reason.resource.namespace}`}
              </Tag>
            )}
          </TagsContainer>
        </Typography>
        <Typography size="small" color={palette.gray["800"]}>
          Contact your system administrator for more info
        </Typography>
      </div>
    </Container>
  );
};

const ErrorMessageBadRequest: React.FC<{
  reason: BadRequestError;
}> = ({ reason }) => {
  return (
    <Container>
      <div>
        <AlertCircleOutlined24 color={palette.pink["500"]} />
      </div>
      <div>
        <Typography size="medium" variant="title" color={palette.gray["800"]}>
          Action failed!
        </Typography>
        <Typography size="small" color={palette.gray["800"]}>
          Bad Request: {reason.message}
        </Typography>
      </div>
    </Container>
  );
};
