import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined
): IStep => {
  const limitDecreasedResult = issue?.results?.limitDecreased;
  const output = limitDecreasedResult?.output;
  const isDeploysExists = !!output?.decreasedInDeploy?.id;

  return {
    type: AvailabilityInvestigationCheckType.LimitDecreased,
    visible: !!limitDecreasedResult,
    visited: false,
    disabled: !isDeploysExists,
  };
};
