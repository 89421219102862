import React, { useState } from "react";
import styled from "styled-components";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import { ViolationEnlargedMetricsGraph } from "../../../shared/metrics/components/MetricsGraph/MetricsGraph";
import { CommonApiResponse } from "../../../../../../../../../../shared/types/commonApiResponse";
import { WorkloadMetricsContainers } from "../../../../../../../../../../generated/metricsApi";
import { MetricsAggregationType } from "../../../../../../../../../Metrics/types";

const Container = styled.div``;
const StyledButton = styled(Button)`
  && {
    gap: 8px;
  }
`;

const StyledOpenInNew = styled(OpenInNew)`
  && {
    font-size: 16px;
    cursor: pointer;
    color: ${muiColors.indigo[500]};
  }
`;

type MetricsGraphProps = {
  komodorUid: string;
  res: CommonApiResponse<WorkloadMetricsContainers>;
  containerName: string;
};

export const MetricsGraph: React.FC<MetricsGraphProps> = ({
  komodorUid,
  res,
  containerName,
}) => {
  const [isMetricsGraphEnlarged, setIsMetricsGraphEnlarged] = useState(false);
  const containerData = res.data?.containers?.find(
    (container) => container.containerName === containerName
  );

  const ViewUsageButton = () => {
    return (
      <StyledButton
        variant={"text"}
        onClick={() => setIsMetricsGraphEnlarged(true)}
      >
        <StyledOpenInNew />
        View CPU usage
      </StyledButton>
    );
  };

  return (
    <Container>
      {containerData && <ViewUsageButton />}
      {!containerData && (
        <Skeleton variant={"rounded"} width={"130px"} height={"32px"} />
      )}
      {isMetricsGraphEnlarged && (
        <ViolationEnlargedMetricsGraph
          komodorUid={komodorUid}
          onClose={() => setIsMetricsGraphEnlarged(false)}
          res={res}
          type={"cpu"}
          containerData={containerData}
          initialAggregation={MetricsAggregationType.Avg}
        />
      )}
    </Container>
  );
};
