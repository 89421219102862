import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  OptionType,
  Typography,
  MultiSelect,
  TextArea,
} from "@komodorio/design-system/deprecated";
import { useDebouncedCallback } from "use-debounce";
import { chunk } from "lodash";

import { Form, FormError, Z_INDEX_ABOVE_MODAL } from "../styles";
import { useFormValidations } from "../../../shared/context/ValidationsProvider";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { useGetRbacRoles } from "../../../shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";

import { useAddUser } from "./actions";

import { ariaLabels } from "@/components/Settings/Users/const";

// 5 is an arbitrary number.
// Adding a member to an account == 1 gql call == for lots of users, it's too slow
// In case someone decide to invites 100 people, we don't want to overwhelm the browser with that many requests at a time.
const INVITE_MEMBER_CHUNK_SIZE = 5;

type BulkUsersForm = {
  emails: string;
  roles: OptionType<string>[];
};

const defaultValue = { emails: "", roles: [] };

const commaSeparatedEmailRegex =
  /^(([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
const validateUserEmails = (emails: string | undefined) => {
  if (!emails) {
    return "Please enter email addresses";
  }
  if (emails.includes(" ")) {
    return "Spaces are not allowed"; // Allows regex to stay simplistic
  }
  if (!commaSeparatedEmailRegex.test(emails)) {
    return "One or more emails are invalid";
  }
  return;
};
const extractNameFromEmail = (email: string): string => {
  const splitEmail = email.split("@");
  if (splitEmail.length > 0) return splitEmail[0];
  return email;
};

const validateUserRoles = (roles: OptionType<string>[] | undefined) =>
  !roles?.length ? "Please select at least one role" : undefined;

const BulkUsersFormModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  refreshUsers: () => void;
}> = ({ open, handleClose, refreshUsers }) => {
  const [value, setValue] = useState<BulkUsersForm>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [isDefaultRolesInitiated, setIsDefaultRolesInitiated] =
    useState<boolean>(false);
  const { errors, setError } = useFormValidations();

  const { data: allRoles = [] } = useGetRbacRoles();
  const roleOptions = useMemo(
    () =>
      allRoles.map((r) => ({
        label: r.name,
        value: r.id,
      })),
    [allRoles]
  );

  useEffect(() => {
    if (value.roles.length !== 0 || isDefaultRolesInitiated) return;
    const defaultRole = allRoles.find((r) => r.isDefault);
    if (!defaultRole) return;
    const defaultRoleId = {
      label: defaultRole.name,
      value: defaultRole.id,
    };
    setValue({ ...value, roles: [defaultRoleId] });
    setIsDefaultRolesInitiated(true);
  }, [allRoles, value, isDefaultRolesInitiated]);

  const [addUser] = useAddUser();

  const handleSave = async () => {
    setLoading(true);
    const { emails, roles } = value;
    const emailsError = validateUserEmails(emails);
    const rolesError = validateUserRoles(roles);
    setError("emails", emailsError);
    setError("roles", rolesError);
    if (emailsError || rolesError) {
      return setLoading(false);
    }
    const newRoleIds = roles.map((r) => r.value);

    const failedInvitations: string[] = [];
    const emailsList = emails.split(",");
    const invitesPromises = emailsList.map((email) => {
      const cleanEmail = email.trim().toLowerCase();
      const bestEffortForName = extractNameFromEmail(cleanEmail);
      return addUser(
        {
          displayName: bestEffortForName,
          email: cleanEmail,
          rbacRoleIds: newRoleIds,
        },
        (email) => failedInvitations.push(email)
      );
    });

    const invitesChunks = chunk(invitesPromises, INVITE_MEMBER_CHUNK_SIZE);
    for (const chunk of invitesChunks) {
      await Promise.all(chunk);
    }
    dispatchEvent(AnalyticEvents.Settings.AddBulkUsers, {
      emails,
      roles: roles.map((r) => r.label),
      failedInvitations,
    });
    if (failedInvitations.length) {
      setError(
        "general",
        `Failed to invite one or more users: ${failedInvitations.join(
          ", "
        )}. They may already exist.`
      );
      refreshUsers();
      return setLoading(false);
    }
    refreshUsers();
    handleClose();
    setLoading(false);
  };

  const { callback: debouncedEmailValidation } = useDebouncedCallback(
    (emails: string) => setError("emails", validateUserEmails(emails)),
    1500
  );

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>
        <Typography variant="headline">Add Bulk Users</Typography>
      </ModalHeader>
      <ModalContent aria-label={ariaLabels.addBulkUsersModal.container}>
        <Form width="20rem">
          <TextArea
            size="medium"
            label="Emails"
            placeholder={
              "Enter comma-separated emails.\nExample: john@example.com,bob@example.com"
            }
            value={value.emails}
            onChange={(e) => {
              setValue({ ...value, emails: e.target.value });
              debouncedEmailValidation(e.target.value);
            }}
            errorMessage={errors["emails"]}
          />
          <MultiSelect
            size="medium"
            width="20rem"
            label="Roles"
            listMaxHeight="14rem"
            listZIndex={Z_INDEX_ABOVE_MODAL}
            options={roleOptions}
            value={value.roles}
            onChange={(selected) => {
              setValue({ ...value, roles: selected });
              setError("roles", validateUserRoles(selected));
            }}
            errorMessage={errors["roles"]}
          />
          <FormError errMsg={errors["general"]} />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default BulkUsersFormModal;
