import { ALL_TAB_VALUE } from "../context/IssuesTableContext";
import { useIssuesTableContext } from "../context/useIssuesTableContext";

export const useCheckTypeParam = () => {
  const { tabValue } = useIssuesTableContext();

  if (tabValue === ALL_TAB_VALUE) {
    return undefined;
  }
  return [tabValue];
};
