import { useMemo } from "react";

import { HealthType, useHealthType } from "./useHealthType";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type Pages = {
  violations: boolean;
  bestPractices: boolean;
  policies: boolean;
  ignoreChecks: boolean;
};

export const useReliabilityPages = (): Pages | undefined => {
  const {
    showReliabilityPolicies,
    reliabilityBestPracticesTab,
    reliabilityHealthManagement,
  } = useOverridableFlags();
  const healthType = useHealthType();

  return useMemo(() => {
    if (healthType === HealthType.INFRA) return undefined;

    const pages = {
      violations: true,
      bestPractices: !!reliabilityBestPracticesTab,
      policies: !!showReliabilityPolicies,
      ignoreChecks: true,
    };

    if (reliabilityHealthManagement) {
      pages.ignoreChecks = false;
      pages.policies = false;
    }

    return pages;
  }, [
    healthType,
    reliabilityBestPracticesTab,
    reliabilityHealthManagement,
    showReliabilityPolicies,
  ]);
};
