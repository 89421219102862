import { WorkloadMetricsSummary } from "../../../../../../../../../../generated/metricsApi";
import { ResourceType } from "../../../../../../../../../../generated/reliabilityApi";
import { ViolationSummaryItem } from "../../../shared/ViolationSummary/ViolationSummary";
import {
  cpuUnit,
  getMibNumber,
  getValue,
  memoryUnit,
} from "../../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";
import { UnderProvisionedValues } from "../underProvisionedTypes";
import { getDiffByResourceType, getRatio } from "../underProvisionedUtils";
import { formatNumber } from "../../../../../../../../../../shared/utils/formatNumber";

export const getMemorySummaryCb = ({ ratio, diff }: UnderProvisionedValues) => {
  return (
    summary: WorkloadMetricsSummary | undefined
  ): ViolationSummaryItem[] => {
    if (!summary) return [];
    const hasRequests = summary?.latestRequests > 0;
    const ratioValue = hasRequests
      ? ` (${formatNumber(getRatio(ratio))}%)`
      : "";

    return [
      {
        label: "P99 usage",
        value: getValue(getMibNumber(summary.p99Utilization), memoryUnit),
      },
      {
        label: "Requests",
        value: getValue(getMibNumber(summary?.latestRequests), memoryUnit),
      },
      {
        label: "Limits",
        value: getValue(getMibNumber(summary?.latestLimits), memoryUnit),
      },
      {
        label: "Under-provisioned by",
        value: `${getDiffByResourceType(
          ResourceType.Memory,
          diff
        )}${ratioValue}`,
      },
    ];
  };
};

export const getCpuSummaryCb = ({ ratio, diff }: UnderProvisionedValues) => {
  return (
    summary: WorkloadMetricsSummary | undefined
  ): ViolationSummaryItem[] => {
    if (!summary) return [];
    const hasRequests = summary?.latestRequests > 0;
    const ratioValue = hasRequests
      ? ` (${formatNumber(getRatio(ratio))}%)`
      : "";

    return [
      {
        label: "P99 usage",
        value: getValue(summary.p99Utilization, cpuUnit),
      },
      {
        label: "Requests",
        value: getValue(summary?.latestRequests, cpuUnit),
      },
      {
        label: "Limits",
        value: getValue(summary?.latestLimits, cpuUnit),
      },
      {
        label: "Under-provisioned by",
        value: `${getDiffByResourceType(ResourceType.Cpu, diff)}${ratioValue}`,
      },
    ];
  };
};
