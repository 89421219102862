import { JobState } from "./job";

export type ServiceReplicas = {
  ready: number;
  desired: number;
};

export type MatchExpressions = {
  key: string;
  operator: string;
  values?: string[];
}[];

export type Selector = {
  matchLabels: Record<string, string>;
  matchExpressions?: MatchExpressions;
};

export type KubernetesKindsMatchingService =
  | "Deployment"
  | "DaemonSet"
  | "StatefulSet"
  | "Rollout"
  | "Job"
  | "CronJob";

export interface ServiceMetadata {
  replicas: ServiceReplicas | undefined;
  serviceType: KubernetesKindsMatchingService | undefined;
  labels: Record<string, string>;
  annotations: Record<string, string>;
  replicaSetName: string | undefined;
  k8sUid: string | undefined;
  selector: Selector | undefined;
  jobState?: JobState;
  jobLastRunTime: Date | undefined;
}

export enum KubernetesServiceTypeEnum {
  Deployment = "Deployment",
  StatefulSet = "StatefulSet",
  DaemonSet = "DaemonSet",
  Rollout = "Rollout",
  Job = "Job",
  CronJob = "CronJob",
}
