import React from "react";
import { Team16 } from "@komodorio/design-system/icons";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "Users";

export const UsersDemoLink: React.FC = () => {
  return (
    <AppBarLink
      text={DISPLAY_NAME}
      route={"/settings/users"}
      icon={<Team16 />}
    />
  );
};
