import React from "react";

import {
  Code,
  TextContainer,
  TextElementContainer,
} from "../../../violationDrawerStyles";

export const getWhatDescription = () => (
  <TextElementContainer>
    <TextContainer>We detected that the</TextContainer>
    <Code>priorityClassName</Code>
    <TextContainer>is not set.</TextContainer>
  </TextElementContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Assigning pod PriorityClass ensures critical workloads receive necessary
    resources and are protected during resource contention, improving cluster
    stability and service continuity.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Make sure you have a PriorityClass defined and reference it in your
    workload’s YAML.
  </TextContainer>
);

export const getSecondHowDescription = () => (
  <TextContainer>
    In this example the PriorityClass resource named high-priority is defined
    with a priority value of 1000. References this PriorityClass using the
    priorityClassName field, assigning the high priority to the pod named
    high-priority-pod.
  </TextContainer>
);

export const yamlExample = `apiVersion: scheduling.k8s.io/v1
kind: PriorityClass
metadata:
  name: high-priority-nonpreempting
  value: 1000000
  preemptionPolicy: Never
  globalDefault: false
  description: "This priority class will not cause other pods to be preempted."`;
