import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FileDownload from "@mui/icons-material/FileDownload";
import styled from "styled-components";

import { getCsvData } from "../../../../../shared/components/CsvExport/useCsvData";
import { downloadCsvFile } from "../../../../../shared/components/CsvExport/utils";
import { useRightSizingRowsForExport } from "../hooks/rightSizingPaginatedTableHooks";
import { getRightSizingCsvHeaders, getValue } from "../RightSizingTable/csv";

const EXPORT_PAGE_SIZE = 10000;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 8px;
  }
`;

export interface CsvExportProps {
  fileName: string;
  ariaLabel?: string;
}

export const CsvPaginatedExport: React.FC<CsvExportProps> = ({
  fileName,
  ariaLabel,
}) => {
  const [exportIsClicked, setExportIsClicked] = useState(false);

  const { data, isFetching, refetch } = useRightSizingRowsForExport(
    EXPORT_PAGE_SIZE,
    false
  );

  useEffect(() => {
    if (!isFetching && data?.data.rows && exportIsClicked) {
      const csvData = getCsvData({
        getHeaders: getRightSizingCsvHeaders,
        getValue,
        rows: data?.data.rows,
      });
      if (csvData?.length > 0) {
        downloadCsvFile(fileName, csvData);
      }
      setExportIsClicked(false);
    }
  }, [data, fileName, exportIsClicked, setExportIsClicked, isFetching]);

  const onExport = () => {
    setExportIsClicked(true);
    refetch();
  };

  return (
    <Button onClick={onExport} aria-label={ariaLabel}>
      <FileDownload />
      <Typography variant="h6">Export</Typography>
      {isFetching && <StyledCircularProgress size={16} />}
    </Button>
  );
};
