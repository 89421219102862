import React from "react";

import { DescribePanel } from "../common/DescribeSection";
import { DescribeItem, DescribeTextItem } from "../common/DescribeItem";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVC from "../../../resources/pvc";
import { DescribeSectionDivider } from "../common/DescribeSectionDivider";
import { DescribeMainPanelData } from "../common/DescribeMainPanelData";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { PVCSelectors } from "./PVCSelectors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PVCRelatedResources } from "./PVCRelatedResources";

export interface PVCDescribeTabProps {
  resource: PVC;
}

export const PVCDescribeTab: React.FC<PVCDescribeTabProps> = ({ resource }) => {
  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeMainPanelData resource={resource} />
        <DescribeSectionDivider title={"RESOURCE INFORMATION"} />
        <DescribeItem name={"Status"}>{resource.renderStatus()}</DescribeItem>
        <DescribeTextItem
          name="Request Storage / Capacity"
          value={`${resource.requestStorage} / ${resource.capacityStorage}`}
        />
        <PVCRelatedResources resource={resource} />
        <DescribeTextItem name="Finalizers" value={resource.finalizers} />
        <DescribeTextItem name="Access Mode" value={resource.accessModes} />
        <PVCSelectors
          matchLabels={resource.matchLabels}
          matchExpressions={resource.matchExpressions}
        />
      </DescribePanel>
    </DescribeTabContentWrapper>
  );
};
