import { parseISO } from "date-fns";

import { useDateFormatter } from "@/shared/hooks/useDateFormatter";

export const useFormattedDate = (
  dateString: string,
  overrideOptions?: Intl.DateTimeFormatOptions
) => {
  const { format } = useDateFormatter(overrideOptions);

  return format(parseISO(dateString));
};
