import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";

import {
  ResolvedClustersResponse,
  WorkspacesApiApiV1WorkspacesClustersResolvePostRequest,
  apiV1WorkspacesClustersResolvePostUrl,
} from "@/generated/workspacesApi";

export const CLUSTER_GROUP_PREVIEW_PATH = "/clusters/resolve";

const fetchClusterGroupPreview = async (
  apiClient: AxiosInstance,
  variables: WorkspacesApiApiV1WorkspacesClustersResolvePostRequest
): Promise<ResolvedClustersResponse> => {
  const { data } = await apiClient.post<ResolvedClustersResponse>(
    apiV1WorkspacesClustersResolvePostUrl(
      variables,
      apiClient.defaults.baseURL ?? ""
    ),
    variables.resolveClustersRequest
  );
  return data;
};

export const useGetClusterGroupPreview = (
  variables: WorkspacesApiApiV1WorkspacesClustersResolvePostRequest,
  {
    enabled = false,
  }: {
    enabled?: boolean;
  }
) => {
  const apiClient = useWorkspacesApiClient();
  return useQuery(
    [CLUSTER_GROUP_PREVIEW_PATH, variables],
    () => fetchClusterGroupPreview(apiClient, variables),
    { enabled }
  );
};
