import {
  BasePolicy,
  DefaultPolicyID,
  PolicyListItem,
} from "@/generated/reliabilityApi";

export const isDefaultPolicy = (policy?: BasePolicy | PolicyListItem) =>
  policy?.priority === 0;

export const isDefaultPolicyById = (policyId?: string) =>
  policyId === DefaultPolicyID.KomodorDefault;
