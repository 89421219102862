import React, { useState } from "react";

import { useActiveAgent } from "../../../shared/hooks/useAgents";
import CustomResource, {
  CustomResourceBasic,
} from "../../ResourceView/resources/customResource";
import { ResourceDrawer } from "../../ResourceView/ResourceDrawer";

import { ActionButtonProps } from "./types";

import Action from ".";

export const CustomResourceDrawerButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  const agentId = useActiveAgent(resource.cluster) ?? "";

  const fullObj = resource.fullObj as CustomResourceBasic;
  fullObj.kind = `Drawer-${resource.kind}`; // This effects the tab key in url, in order to not conflict with tabs of the underlying resource

  const cr = new CustomResource(
    resource.cluster,
    fullObj as CustomResourceBasic,
    agentId,
    resource.kind,
    resource.kind
  );

  return (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={handleClick}
      Button={Button}
    >
      <ResourceDrawer open={open} resource={cr} onClose={handleClose} />
    </Action>
  );
};
