import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SettingsIcon from "@mui/icons-material/Settings";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";

interface SettingsButtonProps {
  options: {
    name: string;
    value: boolean;
    setValue: (value: boolean) => void;
  }[];
  icon?: React.ReactNode;
  iconColor?: string;
}

const OptionsContainer = styled.div`
  display: flex;
  padding: 0.5rem 2rem 0.5rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const SettingsButton: React.FC<SettingsButtonProps> = ({
  options,
  icon,
  iconColor,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedOptions(options.filter((o) => o.value).map((o) => o.name));
  }, [options]);

  const handleOptionChange =
    (option: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        options.find((o) => o.name === option)?.setValue(true);
      } else {
        options.find((o) => o.name === option)?.setValue(false);
      }
    };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        data-testid="SettingsButton"
        color="primary"
        onClick={handleClick}
      >
        {icon ?? <SettingsIcon style={{ color: iconColor }} />}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {open && (
          <Paper>
            <OptionsContainer>
              {options.map((option) => (
                <FormControlLabel
                  key={option.name}
                  control={
                    <Checkbox
                      data-testid={`${option.name}-checkbox`}
                      checked={selectedOptions.includes(option.name)}
                      onChange={handleOptionChange(option.name)}
                    />
                  }
                  label={option.name}
                />
              ))}
            </OptionsContainer>
          </Paper>
        )}
      </Popover>
    </div>
  );
};
