import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { KubernetesTripleGray as KubernetesIcon } from "@komodorio/design-system/icons";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { muiColors } from "@komodorio/design-system";

import { HeaderContainer } from "@/components/Settings/General/BetaFeatures/componentsStyles";
import { StrictExternalLink } from "@/components/common/Link/ExternalLink";

export const RbacClusterSyncFeatureInfo: React.FC = () => {
  return (
    <>
      <HeaderContainer>
        <KubernetesIcon />
        <Typography variant={"h4"}>RBAC Cluster Sync</Typography>
        <Tooltip
          placement={"top"}
          arrow={false}
          title={
            <StrictExternalLink
              href="https://help.komodor.com/hc/en-us/articles/22434069402386-RBAC-Cluster-sync"
              color={muiColors.common.white}
            >
              Learn more
            </StrictExternalLink>
          }
        >
          <InfoOutlined fontSize={"inherit"} />
        </Tooltip>
      </HeaderContainer>
      <Typography variant={"body2"}>
        Synchronize your RBAC policies from Komodor across all your clusters.
      </Typography>
    </>
  );
};
