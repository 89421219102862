import { muiColors } from "@komodorio/design-system";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { ALL_CLUSTERS } from "../../workspaces/WorkspacesTopBar/constants";
import {
  useNavigateToWorkspace,
  useWorkspaces,
} from "../../workspaces/WorkspacesTopBar/hooks";

import { partialListMessage } from "@/components/ClustersView/constants";

const MessageContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  border-block: 1px solid ${muiColors.gray[200]};
  padding: 16px;
`;

export const PartialListMessage: React.FC = () => {
  const navigateToWorkspace = useNavigateToWorkspace();
  const { isClustersGroupWorkspace } = useWorkspaces();

  return (
    <MessageContainer>
      <InfoOutlined sx={{ color: muiColors.gray[500] }} />
      <Typography variant="body2" color="text.secondary">
        {partialListMessage(isClustersGroupWorkspace)}
      </Typography>
      <Link
        variant="body2"
        underline="hover"
        onClick={() => navigateToWorkspace(undefined)}
        style={{ cursor: "pointer" }}
      >
        {ALL_CLUSTERS}
      </Link>
    </MessageContainer>
  );
};
