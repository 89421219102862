import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { ArrowRight16 } from "@komodorio/design-system/icons";

const ArrowCircleContainer = styled.div`
  width: 96%;
  height: 96%;
  border-radius: 50%;
  border: 3px solid ${palette.gray["400"]};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  svg {
    height: 100%;
    width: 100%;
  }
`;

export const ArrowCircleIcon: React.FC = () => {
  return (
    <ArrowCircleContainer>
      <ArrowRight16 />
    </ArrowCircleContainer>
  );
};
