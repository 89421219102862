import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { Modal } from "@komodorio/design-system/deprecated";

export const Content = styled.div`
  height: -webkit-fill-available;
  overflow-y: auto;
  position: relative;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 0.3rem;
`;
export const ResourceContainer = styled.div<{
  fullVh?: string;
  marginFromBottom?: string;
}>`
  margin-block: 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: ${({ fullVh, marginFromBottom }) =>
    `calc(${fullVh ?? 100}vh - ${marginFromBottom ?? 0})`};
`;
export const HelmTabContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-block: 1rem;
  row-gap: 1rem;
  height: calc(100vh - 22rem);
`;

export const StyledModal = styled(Modal)`
  background-color: ${theme.background.bgGrayLight};
`;

export const CopyButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-self: end;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  height: 100%;
`;

export const LoaderContainerTab = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MODAL_HEIGHT_PCT = "90";

export const Z_INDEX_ABOVE_MODAL = "10001";
