import { ViolationsGroupBy } from "../ReliabilityTypes";

import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { RELIABILITY_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Dictionary } from "@/shared/types/Dictionary";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

type ReliabilityPersistentState = {
  violationId?: string;
  checkType?: string;
  filters?: Dictionary<FilterOptionInUrl[]>;
  groupBy?: ViolationsGroupBy;
};

export const useReliabilityDataInUrl = () =>
  useStateInUrlAsJSON<ReliabilityPersistentState>(RELIABILITY_PARAM_KEY);
