import { useViolationsTableContext } from "../context/useViolationsTableContext";

import { CheckType } from "@/generated/reliabilityApi";

export const useGetCheckTypesParam = (checkTypes: CheckType[]): CheckType[] => {
  const { uid, violationsGroupBy } = useViolationsTableContext();

  // when we group by check type, the uid represents the check type
  return (
    violationsGroupBy === "CheckType" && uid ? [uid] : checkTypes
  ) as CheckType[];
};
