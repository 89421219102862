import { Container } from "kubernetes-types/core/v1.d";
import React from "react";
import styled from "styled-components";

import { DescribeSectionDivider } from "../../common/DescribeSectionDivider";
import { DescribeDataItem } from "../../common/DescribeItem";

const Resources = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
`;

export const ContainerResources: React.FC<{ container: Container }> = ({
  container,
}) => {
  if (!container.resources) return null;
  const { requests, limits } = container.resources;
  return (
    <>
      <DescribeSectionDivider title={"RESOURCES"} />
      <DescribeDataItem name="Requests">
        <Resources>
          {requests?.cpu && <div> CPU: {requests.cpu} </div>}
          {requests?.memory && <div> Memory: {requests.memory} </div>}
        </Resources>
      </DescribeDataItem>

      <DescribeDataItem name="Limits">
        <Resources>
          {limits?.cpu && <div> CPU: {limits.cpu} </div>}
          {limits?.memory && <div> Memory: {limits.memory} </div>}
        </Resources>
      </DescribeDataItem>
    </>
  );
};
