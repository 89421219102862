import { useMemo } from "react";

import { SEARCH_TYPE_FILTER_OPERATOR } from "../filterSectionConstants";
import { staticViolationFiltersOptions } from "../../../constants/violationFiltersConstants";

import {
  ViolationCountBy,
  ViolationsFilterGroupValue,
} from "@/generated/reliabilityApi";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

/* add checkbox options of values found in url but not returned from server */
/* scenario: search and check an option, and then remove search term */
/* add default static checkbox options not found in url */
/* scenario: option was deselected and no value returned from server, but we want to always keep it */

export const useGetMissingOptions = (
  filterCategory: ViolationCountBy,
  keys: string[] | undefined
) => {
  const filtersData = useFiltersDataInUrl();

  return useMemo<ViolationsFilterGroupValue[]>(() => {
    if (!keys) return [];

    const filters = filtersData?.[filterCategory] || [];
    let searchFilterValues: string[] = [];

    const filterGroups = filters.reduce<ViolationsFilterGroupValue[]>(
      (acc, curr) => {
        const isSearchFilter = curr.operator === SEARCH_TYPE_FILTER_OPERATOR;
        if (isSearchFilter) {
          searchFilterValues = curr.values;
          return acc;
        }

        const foundOptions = curr.values
          .filter((value) => {
            const isInData = !!keys.find((key) => key === value);
            if (searchFilterValues.length > 0) {
              return searchFilterValues.some((searchValue) =>
                value.includes(searchValue)
              );
            }
            return !isInData;
          })
          .map<ViolationsFilterGroupValue>((value) => ({
            key: value,
            value: 0,
          }));

        acc.push(...foundOptions);
        return acc;
      },
      []
    );

    const staticOptions = staticViolationFiltersOptions[filterCategory];
    if (staticOptions) {
      const missingOptions = staticOptions.filter((option) => {
        const isInKeys = !!keys.find((key) => key === option);
        const isInFilterGroups = !!filterGroups.find(
          (value) => value.key === option
        );
        return !isInFilterGroups && !isInKeys;
      });
      filterGroups.push(
        ...missingOptions.map<ViolationsFilterGroupValue>((value) => ({
          key: value,
          value: 0,
        }))
      );
    }

    return filterGroups;
  }, [filtersData, filterCategory, keys]);
};
