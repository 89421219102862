import Typography from "@mui/material/Typography";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";

export interface ClusterGroupConfirmationDialogProps {
  onClose: (confirmed: boolean) => void;
}

export function ClusterGroupConfirmationDialog({
  onClose,
}: ClusterGroupConfirmationDialogProps) {
  const handleOk = (ok: boolean) => {
    onClose(ok);
  };

  return (
    <ConfirmationDialog
      title="Please Note"
      okText="Save Cluster Group"
      cancelText="Cancel"
      onClose={handleOk}
      content={
        <Typography variant="body2">
          Currently, there are no clusters available with this pattern. However,
          it may include clusters that fall under this definition in the future
        </Typography>
      }
    />
  );
}
