import React from "react";

import { GenericErrorMessage } from "../../../shared/components/GenericErrorMessage";

import { RelatedResourcesPanel } from "./styles";

const RelatedResourceListError: React.FC = () => {
  return (
    <RelatedResourcesPanel>
      <GenericErrorMessage />
    </RelatedResourcesPanel>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RelatedResourceListError;
