import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import Resource from "../../../resources";
import { useMetricsGraphRequestParams } from "../components/graphs/useWorkloadMetrics";
import { useGetDatapoints } from "../components/MetricsPerContainer/metricsPerContainerHooks";
import { useResourceUid } from "../../../../../shared/hooks/resources/useResourceUid";
import { MetricType } from "../../../../Metrics/types";

import { useFetchWorkloadContainersMetricsData } from "./fetchWorkloadMetricsHooks";
import { useGraphData } from "./graphDataHooks";

type UseWorkloadContainerGraphProps = {
  resource: Resource;
  type: MetricType;
  wantedContainer: MuiSelectionOption<string> | undefined;
  endTime?: number;
};

export const useWorkloadContainerGraph = ({
  resource,
  type,
  wantedContainer,
  endTime,
}: UseWorkloadContainerGraphProps) => {
  const { requestParams, selectedAggregationMetric, timeWindow, toEpochMs } =
    useMetricsGraphRequestParams(resource, endTime);

  const { data, error, isFetching } = useFetchWorkloadContainersMetricsData({
    ...requestParams,
    kind: type,
  });

  const dataPoints = useGetDatapoints(wantedContainer, data?.data);

  const { graphProps, hasGraphData } = useGraphData(dataPoints);
  const hasLimit = dataPoints?.limit?.some((dp) => dp.value > 0);

  const uid = useResourceUid(resource);

  return {
    loading: isFetching,
    error,
    graphProps,
    wantedContainer,
    uid,
    hasGraphData,
    selectedAggregationMetric,
    timeWindow,
    toEpochMs,
    hasLimit,
  };
};
