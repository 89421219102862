import {
  RightSizingFiltersState,
  RightSizingRecommendationsModalState,
  RightSizingRecommendationsState,
  RightSizingRecommendationsStrategiesState,
  RightSizingSummaryState,
} from "../../../../../../CostOptimizationView/types/costOptimizationTypes";

import { OverviewPageStore } from "./overviewPageStore";

// getters
export const selectEventItemDrawer = (state: OverviewPageStore) =>
  state.eventItemDrawer;
export const selectTimeWindowIntervals = (state: OverviewPageStore) =>
  state.timeWindowIntervals;
export const selectInsightsDrawer = (state: OverviewPageStore) =>
  state.insightsDrawer;
export const selectAppInsightsData = (state: OverviewPageStore) =>
  state.appInsightsData;
export const selectServiceResourceDrawer = (state: OverviewPageStore) =>
  state.serviceResourceDrawer;
export const selectOverviewMetricsData = (state: OverviewPageStore) =>
  state.overviewMetricsData;
export const selectUiElementsRect = (state: OverviewPageStore) =>
  state.uiElementsRect;
export const selectPageTransition = (state: OverviewPageStore) =>
  state.pageTransition;
export const selectRightSizingRecommendationsStrategiesState = (
  state: OverviewPageStore
): RightSizingRecommendationsStrategiesState =>
  state.cost.rightSizingRecommendationsStrategiesState;
export const selectRightSizingRecommendationsState = (
  state: OverviewPageStore
): RightSizingRecommendationsState =>
  state.cost.rightSizingRecommendationsState;
export const selectMetricsSupportAgentInfo = (state: OverviewPageStore) =>
  state.metricsSupportAgentInfo;

// setters
export const selectOpenEventItemDrawer = (state: OverviewPageStore) =>
  state.eventItemDrawer.openEventItemDrawer;
export const selectCloseEventItemDrawer = (state: OverviewPageStore) =>
  state.eventItemDrawer.closeEventItemDrawer;
export const selectSetTimeWindowIntervals = (state: OverviewPageStore) =>
  state.setTimeWindowIntervals;
export const selectSetRightSizingSummaryState = (
  state: OverviewPageStore
): ((state: RightSizingSummaryState) => void) =>
  state.cost.setRightSizingSummaryState;
export const selectSetRightSizingRecommendationModal = (
  state: OverviewPageStore
): ((modalState: RightSizingRecommendationsModalState) => void) =>
  state.cost.setRightSizingRecommendationModal;
export const selectRightSizingFilters = (
  state: OverviewPageStore
): RightSizingFiltersState => state.cost.rightSizingFilters;
export const selectSetRightSizingModalStrategies = (
  state: OverviewPageStore
): ((strategies: RightSizingRecommendationsStrategiesState) => void) =>
  state.cost.setRightSizingRecommendationsStrategiesState;
export const selectSetRightSizingRecommendationsState = (
  state: OverviewPageStore
): ((state: RightSizingRecommendationsState) => void) =>
  state.cost.setRightSizingRecommendationsState;
export const selectRightSizingRecommendationModal = (
  state: OverviewPageStore
): RightSizingRecommendationsModalState =>
  state.cost.rightSizingRecommendationsModalState;
export const selectRightSizingSummaryState = (
  state: OverviewPageStore
): RightSizingSummaryState => state.cost.rightSizingSummaryState;
export const selectSetInsightsDrawerContent = (state: OverviewPageStore) =>
  state.insightsDrawer.setInsightsDrawerContent;
export const selectOpenInsightsDrawer = (state: OverviewPageStore) =>
  state.insightsDrawer.openInsightsDrawer;
export const selectCloseInsightsDrawer = (state: OverviewPageStore) =>
  state.insightsDrawer.closeInsightsDrawer;
export const selectSetAppInsightsData = (state: OverviewPageStore) =>
  state.setAppInsightsData;
export const selectOpenServiceResourceDrawer = (state: OverviewPageStore) =>
  state.serviceResourceDrawer.openServiceResourceDrawer;
export const selectCloseServiceResourceDrawer = (state: OverviewPageStore) =>
  state.serviceResourceDrawer.closeServiceResourceDrawer;
export const selectSetOverviewMetricsData = (state: OverviewPageStore) =>
  state.setOverviewMetricsData;
export const selectSetUiElementsRect = (state: OverviewPageStore) =>
  state.setUiElementsRect;
export const selectSetPageTransition = (state: OverviewPageStore) =>
  state.setPageTransition;
export const selectSetMetricsSupportAgentInfo = (state: OverviewPageStore) =>
  state.setMetricsSupportAgentInfo;
