import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";

import {
  apiV1RbacClusterStatusScheduleSyncPostUrl,
  RbacClusterScheduleSyncRequest,
} from "@/generated/auth";

const RBAC_CLUSTER_SCHEDULE_SYNC_PATH = "/rbac-cluster-status/schedule-sync";

const rbacClusterScheduleSync = async (
  apiClient: AxiosInstance,
  params: RbacClusterScheduleSyncRequest
): Promise<void> => {
  await apiClient.post(
    apiV1RbacClusterStatusScheduleSyncPostUrl(
      { rbacClusterScheduleSyncRequest: params },
      apiClient.defaults.baseURL ?? ""
    ),
    params
  );
};

export const useRbacClusterScheduleSync = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [RBAC_CLUSTER_SCHEDULE_SYNC_PATH],
    (params: RbacClusterScheduleSyncRequest) =>
      rbacClusterScheduleSync(apiClient, params)
  );
};
