import React, { useMemo } from "react";
import { format, parseISO } from "date-fns";

import { useMonitorsAPIGet } from "../../../../shared/hooks/monitors-api/client";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
import { MONITORS_SEARCH_ENDPOINT } from "../../../../shared/hooks/monitors-api/requestResponseMaps";
import { ASC } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { DeployCard } from "../../../InvestigationMode/availability/CorrelatedDeploys/DeployCard";
import { useDeploysById } from "../../../InvestigationMode/availability/CorrelatedDeploys/useDeploysById";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  AVG_LABEL,
  AVG_TEXT,
  DATE_KEY,
  IssuesAfterDeployGraphProps,
  LAST_VALUE_KEY,
  LAST_VALUE_LABEL,
  VALUE_KEY,
} from "./IssuesAfterDeployGraph";

const getDateKey = (date: Date): string => format(date, "MMM d");

interface IssuesAfterDeployData {
  abnormalAmount: { [_: string]: number | string | null }[];
  abnormalDuration: { [_: string]: number | string | null }[];
}

export const useIssuesAfterDeployGraphFetch = (
  props: IssuesAfterDeployGraphProps
): IssuesAfterDeployData => {
  const toDate = parseISO(props.issuesAfterDeploy.day);
  const fromDate = useMemo(
    () =>
      new Date(
        toDate.getFullYear(),
        toDate.getMonth(),
        toDate.getDate() - props.issuesAfterDeploy.num_days
      ),
    [props.issuesAfterDeploy.num_days, toDate]
  );

  const availabilityIssues = useMonitorsAPIGet(MONITORS_SEARCH_ENDPOINT, {
    types: [WorkflowConfigType.Availability],
    includeOpen: false,
    includeClose: true,
    serviceIds: [props.resource.id],
    order: ASC,
    fromEpoch: fromDate.getTime().toString(),
    toEpoch: toDate.getTime().toString(),
    fields: ["id", "closedAt", "eventTime"],
  });

  const issuesDurationByDate = useMemo(() => {
    const acc: { [_: string]: number[] } = {};
    const date = fromDate;
    const dates = [];
    while (date <= toDate) {
      acc[getDateKey(date)] = [];
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    availabilityIssues.data?.data.forEach((issue) => {
      const eventTime = parseISO(issue?.eventTime ?? "");
      const closedAt = parseISO(issue?.closedAt ?? "");
      const dateKey = getDateKey(eventTime);
      const duration = closedAt.getTime() - eventTime.getTime();
      acc[dateKey].push(duration);
    });
    return acc;
  }, [availabilityIssues.data?.data, fromDate, toDate]);

  const abnormalAmountData = useMemo(() => {
    if (!props.issuesAfterDeploy.count_triggered) {
      return [];
    }
    const dateAndAmount = Object.entries(issuesDurationByDate);
    return dateAndAmount.map(([date, durations], index) => {
      const isLastIndex = dateAndAmount.length - 1 === index;
      const valueKey = isLastIndex ? LAST_VALUE_KEY : VALUE_KEY;
      const amount = durations.length;
      return {
        [DATE_KEY]: date,
        [AVG_TEXT]: isLastIndex ? null : props.issuesAfterDeploy.count_norm,
        [AVG_LABEL]:
          index === 0
            ? `Avg: ${props.issuesAfterDeploy.count_norm} issues / day`
            : null,
        [valueKey]: amount,
        [LAST_VALUE_LABEL]: isLastIndex
          ? `${props.issuesAfterDeploy.count_current} issues`
          : "",
      };
    });
  }, [
    issuesDurationByDate,
    props.issuesAfterDeploy.count_current,
    props.issuesAfterDeploy.count_norm,
    props.issuesAfterDeploy.count_triggered,
  ]);

  const abnormalDurationData = useMemo(() => {
    if (!props.issuesAfterDeploy.duration_triggered) {
      return [];
    }
    const dateAndAmount = Object.entries(issuesDurationByDate);
    return dateAndAmount.map(([date, durations], index) => {
      const isLastIndex = dateAndAmount.length - 1 === index;
      const valueKey = isLastIndex ? LAST_VALUE_KEY : VALUE_KEY;
      const avg =
        durations.reduce((acc, curr) => acc + curr, 0) / durations.length;

      return {
        [DATE_KEY]: date,
        [AVG_TEXT]: isLastIndex ? null : props.issuesAfterDeploy.duration_norm,
        [AVG_LABEL]:
          index === 0
            ? `Avg: ${props.issuesAfterDeploy.duration_norm} ms / issue`
            : null,
        [valueKey]: avg,
        [LAST_VALUE_LABEL]: isLastIndex
          ? `${props.issuesAfterDeploy.duration_current} ms / issue`
          : "",
      };
    });
  }, [
    issuesDurationByDate,
    props.issuesAfterDeploy.duration_current,
    props.issuesAfterDeploy.duration_norm,
    props.issuesAfterDeploy.duration_triggered,
  ]);

  return {
    abnormalAmount: abnormalAmountData,
    abnormalDuration: abnormalDurationData,
  };
};

export const useDeployCardsById = (deployIds: string[]): JSX.Element[] => {
  const [deploys] = useDeploysById(deployIds);
  return useMemo(
    () =>
      deploys
        .sort(
          (a, b) =>
            new Date(b.eventTime ?? "").getTime() -
            new Date(a.eventTime ?? "").getTime()
        )
        .map((deploy, index) => {
          return (
            <DeployCard
              deploy={deploy}
              isCollapsed={deploys.length > 1 && index !== 0}
              canRollback={false}
              key={index}
            />
          );
        }),
    [deploys]
  );
};
