import { muiColors } from "@komodorio/design-system";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { workloadsMetricsAriaLabels } from "../../ariaLabel";

const SelectContainer = styled.div<{ disabled: boolean }>`
  & .dropDownTriggerField {
    ${({ disabled }) =>
      disabled &&
      `
    background-color: ${muiColors.gray[100]};
    pointer-events: none;
    cursor: not-allowed;
    `}
  }
`;

export const ContainerSelector = ({
  containers,
  wantedContainer,
  onChange,
}: {
  containers: MuiSelectionOption<string>[] | undefined;
  wantedContainer: MuiSelectionOption<string> | undefined;
  onChange: (option: MuiSelectionOption<string> | undefined) => void;
}) => {
  return (
    <SelectContainer disabled={!containers?.length}>
      <SingleSelect
        ariaLabel={
          workloadsMetricsAriaLabels.metricsPerContainer.containersSelect
        }
        options={containers ?? []}
        width="260px"
        value={wantedContainer ?? containers?.[0]}
        onChange={onChange}
        classNames={{ dropDownTriggerField: "dropDownTriggerField" }}
      />
    </SelectContainer>
  );
};
