export const unixTimeToMilliseconds = (unixTime: number): number => {
  const isUnixTimeInSeconds = unixTime.toString().length === 10;
  if (isUnixTimeInSeconds) {
    return unixTime * 1000;
  }
  return unixTime;
};

export const millisecondsToUnixTime = (time: number): number => {
  if (time.toString().length === 13) {
    return Math.floor(time / 1000);
  }
  return time;
};
