import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import { ManualActionInsightDataParsed } from "../../common/types";

import {
  LatestManualActionsTooltip,
  LATEST_ACTIONS_TOOLTIP,
} from "./ManualActionsTooltip";

export const LatestManualActionsInsight: React.FC<{
  latestManualActionsInsights: ManualActionInsightDataParsed[];
  setCorrelatedEvent: (event: EventGroup | null) => void;
  startTime: Date;
}> = ({ startTime, latestManualActionsInsights, setCorrelatedEvent }) => {
  const actionsLength = latestManualActionsInsights.length;

  return (
    <InsightContainer>
      <TooltipWrapper tooltipId={LATEST_ACTIONS_TOOLTIP}>
        <Insight>
          {actionsLength} correlated{" "}
          <DashedText>
            manual action
            {actionsLength > 1 ? "s" : ""}
          </DashedText>{" "}
          {actionsLength > 1 ? "were" : "was"} performed within 2 hours before
          the issue started
        </Insight>
      </TooltipWrapper>
      <LatestManualActionsTooltip
        manualActions={latestManualActionsInsights}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
      />
    </InsightContainer>
  );
};
