import React, { useState } from "react";
import { HelmChartSummary } from "komodor-types";

import { DescribeTextItem } from "../common/DescribeItem";
import ChartReleaseDrawer from "../../../../Inspection/Helm/drawers/ChartReleaseDrawer";

type DescribeTextWithHelmProps = {
  agentId: string;
  helmChartSummary: HelmChartSummary | undefined;
};

export const DescribeTextWithHelmChartDrawer: React.FC<
  DescribeTextWithHelmProps
> = ({ agentId, helmChartSummary }: DescribeTextWithHelmProps) => {
  const [open, setOpen] = useState(false);

  const chartSummary = helmChartSummary;
  if (!chartSummary || !chartSummary.name) return null;

  return (
    <>
      <DescribeTextItem
        name={"Helm Chart"}
        value={`${chartSummary.name}`}
        onValueClick={() => setOpen(true)}
      />
      <ChartReleaseDrawer
        open={open}
        chartSummary={helmChartSummary}
        onClose={() => setOpen(false)}
        agentId={agentId}
      />
    </>
  );
};
