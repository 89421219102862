import React, { useState } from "react";
import ShowChart from "@mui/icons-material/ShowChart";
import { muiColors } from "@komodorio/design-system";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";

import { SectionAccordion } from "../../../ClusterOverview/styles";
import { SectionAccordionSummary } from "../../../ClusterOverview/components/SectionAccordionSummary";
import {
  INVENTORY_SECTION_DISABLED_MESSAGE,
  INVENTORY_SECTION_TITLE,
} from "../../../ClusterOverview/components/InventorySection/constants";

import {
  CLUSTERS_FILTERS_PARAM_KEY,
  INVENTORY_SECTION_PREFIX,
} from "@/shared/config/urlSearchParamsKeys";
import { ClustersSummaryFilters } from "@/generated/workspacesApi";
import { useGetClusters } from "@/shared/hooks/workspaces-api/clusters/useGetClusters";
import {
  DEFAULT_PAGINATION_PARAMS,
  DEFAULT_SORTING_PARAMS,
} from "@/components/ClustersView/constants";
import { ClustersList } from "@/components/ClustersView/components/ClustersList/ClustersList";
import {
  CLUSTERS_GROUP_INVENTORY_SECTION_COLUMNS,
  LOADER_HEIGHT,
} from "@/components/ClustersView/ClustersGroupOverview/components/ClustersGroupInventorySection/constants";
import { Loader } from "@/components/ClustersView/styles";
import { ClusterFilters } from "@/components/ClustersView/components/ClusterFilters";
import {
  useGetClustersFilters,
  useGetClustersTotalCount,
} from "@/shared/hooks/workspaces-api/clusters/useGetClustersFilters";
import { EmptyTableResults } from "@/components/common/table/EmptyResults";
import { useStoreIsFetchingForReport } from "@/components/ClustersView/ClusterOverview/useReportClusterOverviewLoadingTime";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const ClustersGroupInventorySection: React.FC<{
  clusters: string[];
}> = ({ clusters }) => {
  const [expanded, setExpanded] = useState(true);
  const [filters, setFilters] =
    useStringifiedStateInSearchParams<ClustersSummaryFilters>(
      INVENTORY_SECTION_PREFIX + CLUSTERS_FILTERS_PARAM_KEY
    );
  const [version, setVersion] = useState(0);
  const resetFilters = () => {
    setVersion((version) => version + 1);
    setFilters(null);
  };

  const {
    data,
    isFetching: isLoadingClusters,
    isInitialLoading: isInitialLoadingClusters,
  } = useGetClusters(
    {
      filter: {
        clusterNames: clusters,
        ...DEFAULT_PAGINATION_PARAMS,
        ...DEFAULT_SORTING_PARAMS,
        ...filters,
      },
    },
    { staleTime: 0, keepPreviousData: true }
  );
  const clustersSummaries = data?.data ?? [];
  const totalResultsCount = data?.meta?.total ?? 0;
  const {
    data: clustersTotalCount,
    isFetching: isLoadingTotalCount,
    isInitialLoading: isInitialLoadingTotalCount,
  } = useGetClustersTotalCount(clusters);
  const { data: filtersData, isLoading: isLoadingFilters } =
    useGetClustersFilters(
      {
        filter: { clusterNames: clusters, ...filters },
      },
      { staleTime: 0 }
    );
  useStoreIsFetchingForReport(
    "isFetchingClustersList",
    isLoadingTotalCount || isLoadingClusters || isLoadingFilters
  );

  const isDisabled = !isInitialLoadingTotalCount && clustersTotalCount <= 0;
  const isExpanded = expanded && !isDisabled;

  return (
    <SectionAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={isDisabled}
    >
      <SectionAccordionSummary
        title={INVENTORY_SECTION_TITLE}
        icon={<ShowChart sx={{ color: muiColors.gray[500] }} />}
        isExpanded={isExpanded}
        isLoading={isLoadingTotalCount}
        isDisabled={isDisabled}
        disabledMessage={INVENTORY_SECTION_DISABLED_MESSAGE}
      />
      <AccordionDetails>
        {isLoadingTotalCount || isInitialLoadingClusters ? (
          <Loader sx={{ height: LOADER_HEIGHT }} />
        ) : (
          <Box sx={{ marginTop: "8px" }}>
            <ClusterFilters
              key={version}
              filtersData={filtersData}
              filters={filters}
              setFilters={setFilters}
            />
            <Box
              sx={{
                border: `1px solid ${muiColors.gray[300]}`,
                borderRadius: "4px",
              }}
            >
              <ClustersList
                clusters={clustersSummaries}
                isLoadingClusters={isLoadingClusters}
                filters={filters}
                setFilters={setFilters}
                totalResultsCount={totalResultsCount}
                columns={CLUSTERS_GROUP_INVENTORY_SECTION_COLUMNS}
                dataGridProps={{
                  slots: {
                    noRowsOverlay: () => (
                      <EmptyTableResults onClearFilters={resetFilters} />
                    ),
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </SectionAccordion>
  );
};
