import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Skeleton from "@mui/material/Skeleton";

import { BasicViolation } from "@/generated/reliabilityApi";
import { DependentViolationsIcon } from "@/components/reliability/components/shared/DependentViolationsIcon";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const CellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HeaderText = styled(Typography).attrs({ variant: "subtitle2" })`
  && {
    font-size: 14px;
  }
`;

const { dependent: dependentColumnAriaLabel } =
  reliabilityArialLabels.violationsTable.columns;

type Props = Pick<BasicViolation, "hasDependentViolations">;

export const DependentViolationsColumn: React.FC<Props> = ({
  hasDependentViolations,
}) => {
  return (
    <CellContainer aria-label={dependentColumnAriaLabel}>
      {hasDependentViolations && <DependentViolationsIcon />}
    </CellContainer>
  );
};

export const DependentViolationsColumnHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <HeaderText>Impact</HeaderText>
      <Tooltip
        title="The impact and/or risks that this best practice misconfiguration can cause to your services"
        placement="top"
      >
        <InfoOutlined fontSize="small" color="action" />
      </Tooltip>
    </HeaderContainer>
  );
};

export const DependentViolationsLoader: React.FC = () => {
  return (
    <CellContainer>
      <Skeleton variant="rounded" width={24} height={24} />
    </CellContainer>
  );
};
