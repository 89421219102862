import { PVC } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { KubernetesResource } from "./KubernetesResource";
import { AvailableActions } from "./AvailableActions";

export const KubernetesPVCResource: KubernetesResource = {
  NameInK8S: "persistentvolumeclaims",
  Group: "storage",
  NavBarName: "PVC",
  PresentName: "Persistent Volumes Claim",
  Namespaced: true,
  ShowStatusFilter: true,
  UnnecessaryColumns: [],
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Status" },
    { name: "Volume" },
    { name: "Capacity" },
    { name: "Volume Mode" },
    { name: "Access Modes" },
    { name: "Storage Class" },
  ],
  DefaultSort: {
    ColumnName: "status",
    Direction: Direction.up,
  },
  CustomSortOrder: {
    status: {
      Failed: 0,
      Released: 1,
      Bound: 2,
      Available: 3,
    },
  },
  SupportedActions: [AvailableActions.Delete],
  Kind: "PersistentVolumeClaim",
  Icon: PVC,
  hasLabels: true,
};
