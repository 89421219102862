import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
  margin-left: 10px;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
`;

const SkeletonLinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const OptionSkeleton: React.FC = () => {
  return (
    <OptionContainer>
      <Skeleton variant={"circular"} width={20} height={20} />
      <SkeletonLinesContainer>
        <Skeleton variant={"rounded"} width={120} height={20} />
        <Skeleton variant={"rounded"} width={400} height={18} />
      </SkeletonLinesContainer>
    </OptionContainer>
  );
};

export const OptionsFetchingState: React.FC = () => {
  return (
    <Container>
      <OptionSkeleton />
      <OptionSkeleton />
      <OptionSkeleton />
      <OptionSkeleton />
    </Container>
  );
};
