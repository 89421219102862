import { useQuery } from "@tanstack/react-query";

import {
  CpuThrottlingResponse,
  getWorkloadContainersCpuThrottlingUrl,
  MetricsApiGetWorkloadContainersCpuThrottlingRequest,
} from "../../../../generated/metricsApi";

import { useMetricsApiClient } from "./apiClient";

export const GET_WORKLOAD_THROTTLED_CONTAINERS_PATH =
  "/workload/containers/throttling";

export const useGetWorkloadThrottledContainers = (
  params: MetricsApiGetWorkloadContainersCpuThrottlingRequest,
  enabled?: boolean
) => {
  const apiClient = useMetricsApiClient();
  const url = getWorkloadContainersCpuThrottlingUrl(
    params,
    apiClient.defaults.baseURL ?? ""
  );

  return useQuery(
    [GET_WORKLOAD_THROTTLED_CONTAINERS_PATH, params],
    async () => await apiClient.get<CpuThrottlingResponse>(url),
    { enabled: enabled }
  );
};
