import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import { DESKTOP_CLUSTERS_ROUTE } from "../../../DesktopClustersView/DesktopClustersView";

import { NoConnectionIcon } from "./assets/NoConnectionIcon";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ErrorTitle } from "./ErrorTitle";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ErrorDescription } from "./ErrorDescription";

export interface ConnectionFailureEmptyStateErrorProps {
  noClustersTracked: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ClustersButton = styled(Button)`
  margin-top: 1.5rem;
`;

export const ConnectionFailureEmptyStateErrorPage: React.FC<
  ConnectionFailureEmptyStateErrorProps
> = ({ noClustersTracked }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <NoConnectionIcon />
      <ErrorTitle noClustersTracked={noClustersTracked} />
      <ErrorDescription noClustersTracked={noClustersTracked} />

      <ClustersButton
        variant="secondary"
        size="large"
        onClick={() => navigate(DESKTOP_CLUSTERS_ROUTE)}
      >
        Manage clusters
      </ClustersButton>
    </Container>
  );
};
