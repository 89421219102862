import { Array as RTArray, Record, String, Dictionary, Static } from "runtypes";

import {
  CheckRawResult,
  IgnoreCheckProps,
  SpecLevel,
  StaticPreventionBasicConfiguration,
  StaticPreventionResult,
} from "./StaticPreventionUtils";

const CheckResult = Dictionary(CheckRawResult);
type CheckResult = Static<typeof CheckResult>;

const PolarisResult = Record({
  polaris: Record({
    output: Record({
      Results: RTArray(
        Record({
          Name: String,
          Results: CheckResult,
          PodResult: Record({
            Name: String,
            Results: CheckResult,
            ContainerResults: RTArray(
              Record({
                Name: String,
                Results: CheckResult,
              })
            ),
          }),
        })
      ),
    }),
  }),
});

export enum ResourcesErrorTitle {
  CpuLimitMissing = "CPU Limits Missing",
  CpuRequestsMissing = "CPU Requests Missing",
  MemoryLimitMissing = "Memory Limits Missing",
  MemoryRequestsMissing = "Memory Requests Missing",
}
export const PolarisSupportedChecks: {
  [ID: string]: { title: string; whatChecked: string; whyCheckd: string };
} = {
  deploymentMissingReplicas: {
    title: "Deployment has 1 replica",
    whatChecked: "Check if there is only one replica for a deployment.",
    whyCheckd: "More than one replica recommended to be scheduled.",
  },
  cpuLimitsMissing: {
    title: ResourcesErrorTitle.CpuLimitMissing,
    whatChecked: "Check if resources.limits.cpu attribute is not configured.",
    whyCheckd:
      "Setting appropriate resource limits will ensure that your applications do not consume too many resources.",
  },
  cpuRequestsMissing: {
    title: ResourcesErrorTitle.CpuRequestsMissing,
    whatChecked: "Check if resources.requests.cpu attribute is not configured.",
    whyCheckd:
      "Setting appropriate resource requests will ensure that all your applications have sufficient compute resources.",
  },
  livenessProbeMissing: {
    title: "Liveness Probe Missing",
    whatChecked: "Check if a liveness probe is not configured for a pod.",
    whyCheckd:
      "Liveness probes are designed to ensure that an application stays in a healthy state. When a liveness probe fails, the pod will be restarted.",
  },
  memoryLimitsMissing: {
    title: ResourcesErrorTitle.MemoryLimitMissing,
    whatChecked:
      "Check if resources.limits.memory attribute is not configured.",
    whyCheckd:
      "Setting appropriate resource limits will ensure that your applications do not consume too many resources.",
  },
  memoryRequestsMissing: {
    title: ResourcesErrorTitle.MemoryRequestsMissing,
    whatChecked:
      "Check if resources.requests.memory attribute is not configured.",
    whyCheckd:
      "Setting appropriate resource requests will ensure that all your applications have sufficient compute resources.",
  },
  pullPolicyNotAlways: {
    title: "Pull Policy Not Always",
    whatChecked: "Check if an image pull policy is not always.",
    whyCheckd:
      "By default, an image will be pulled if it isn't already cached on the node which is attempting to run it. This can result in variations in images that are running per node, or potentially provide a way to gain access to an image without having direct access to the ImagePullSecret.",
  },
  readinessProbeMissing: {
    title: "Readiness Probe Missing",
    whatChecked: "Check if a readiness probe is not configured for a pod.",
    whyCheckd:
      "A readiness probe can ensure that the traffic is not sent to a pod until it is actually ready to receive traffic.",
  },
  tagNotSpecified: {
    title: "Tag Not Specified",
    whatChecked:
      "Check if an image tag is either not specified or the latest tag has not been used.",
    whyCheckd:
      "Docker's latest tag is applied by default to images where a tag hasn't been specified. Not specifying a specific version of an image can lead to a wide variety of problems.",
  },
  privilegeEscalationAllowed: {
    title: "Privilege Escalation Allowed",
    whatChecked:
      "Fails when securityContext.capabilities includes an insecure capability",
    whyCheckd:
      "The overall goal should be to ensure that containers are running with as minimal privileges as possible. This includes avoiding privilege escalation, not running containers with a root user, not giving excessive access to the host network, and using read only file systems wherever possible.",
  },
  runAsRootAllowed: {
    title: "Run As Root Allowed",
    whatChecked: "Fails when securityContext.runAsNonRoot is not true.",
    whyCheckd:
      "The overall goal should be to ensure that containers are running with as minimal privileges as possible. This includes avoiding privilege escalation, not running containers with a root user, not giving excessive access to the host network, and using read only file systems wherever possible.",
  },
  runAsPrivileged: {
    title: "Run As Privileged",
    whatChecked: "Fails when securityContext.privileged is true.",
    whyCheckd:
      "The overall goal should be to ensure that containers are running with as minimal privileges as possible. This includes avoiding privilege escalation, not running containers with a root user, not giving excessive access to the host network, and using read only file systems wherever possible.",
  },
  dangerousCapabilities: {
    title: "Dangerous Capabilities",
    whatChecked:
      "Fails when securityContext.capabilities includes an insecure capability",
    whyCheckd:
      "With Linux capabilities, you can grant certain privileges to a process without granting all the privileges of the root user. granting excessive capabilities is risky and not recommended.",
  },
  insecureCapabilities: {
    title: "Insecure Capabilities",
    whatChecked:
      "Fails when securityContext.capabilities includes an insecure capability",
    whyCheckd:
      "With Linux capabilities, you can grant certain privileges to a process without granting all the privileges of the root user. granting excessive capabilities is risky and not recommended.",
  },
};

export function generateUniqueSpecLevel(
  specLevel: SpecLevel,
  specName: string
): string {
  return specLevel === "container" ? specName : specLevel;
}

export function buildSpecLevelAndCheckId(
  uniqueSpecLevel: string,
  checkId: string
): string {
  return [uniqueSpecLevel, checkId].join(" ");
}

export function getIgnoredChecksFromConfiguration(
  workflowConfiguration: StaticPreventionBasicConfiguration
): IgnoreCheckProps[] {
  const now = new Date();
  return workflowConfiguration.variables.staticPrevention.polaris.ignoredChecks.filter(
    (ignoreCheckProps) => {
      if (!ignoreCheckProps.ignore) return false;
      if (ignoreCheckProps.expirationDate !== "forever") {
        const expirationDate = new Date(ignoreCheckProps.expirationDate);
        if (expirationDate < now) return false;
      }
      return true;
    }
  );
}

export const getResultsFromPolaris = (
  checksResults: unknown,
  configuration: StaticPreventionBasicConfiguration
): StaticPreventionResult => {
  const output: StaticPreventionResult = {
    checks: [],
    ignoredChecks: [],
    failedToParse: false,
  };
  if (!PolarisResult.guard(checksResults)) {
    output.failedToParse = true;
    return output;
  }
  const ignoredChecks = getIgnoredChecksFromConfiguration(configuration);
  const results = checksResults.polaris.output.Results[0];

  const handleCheck = (
    check: CheckRawResult,
    specName: string,
    specLevel: SpecLevel
  ) => {
    const ignoredCheck = ignoredChecks.some(
      (ic) =>
        ic.id === check.ID &&
        ic.specLevel.type === specLevel &&
        ic.specLevel.name === specName
    );
    const newCheck = { ...check, SpecName: specName, SpecLevel: specLevel };
    ignoredCheck
      ? output.ignoredChecks.push(newCheck)
      : output.checks.push(newCheck);
  };

  // Iterate workload output
  Object.values(results.Results).forEach((check) => {
    handleCheck(check, results.Name, "spec");
  });

  // Iterate pod output
  Object.values(results.PodResult.Results).forEach((check) => {
    handleCheck(check, results.PodResult.Name, "pod");
  });

  // Iterate containers output
  results.PodResult.ContainerResults.forEach((containerResult) =>
    Object.values(containerResult.Results).forEach((check) => {
      handleCheck(check, containerResult.Name, "container");
    })
  );

  return output;
};
