import React from "react";

import { PageName, usePages } from "@/components/AppBar/links/pages";
import { AppBarLink } from "@/components/AppBar/links/AppBarLink";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const name = PageName.ExternalDNS;

export const ExternalDNSLink: React.FC = () => {
  const { showExternalDns } = useOverridableFlags();
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
      tag={showExternalDns ? "new!" : "soon"}
    />
  ) : null;
};
