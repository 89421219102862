import React, { useState } from "react";
import { theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useSnapshotTabs } from "../common/useSnapshotTabs";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useScrollTracking } from "../common/useScrollTracking";
import { useDDRumStartViewAndAddTiming } from "../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { ResourceDrawer } from "../../../ResourceView/ResourceDrawer";
import Resource from "../../../ResourceView/resources";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import { UnhealthyPodsSnapshotOutput } from "./types";
import PodsContent from "./PodsContent";

export const UnhealthyPods: React.FC = () => {
  const issue = useInvestigationModeStore(issueSelector);
  const { nextStep, goToNextStep } = useNextStep();
  const [drawerResource, setDrawerResource] = useState<Resource | undefined>(
    undefined
  );
  const { firstSnapshot, lastSnapshot } =
    issue?.results.unhealthyPods.output ?? {};
  const [selectedSnapshot, SnapshotTabs] =
    useSnapshotTabs<UnhealthyPodsSnapshotOutput>(firstSnapshot, lastSnapshot);
  const elementRef = useScrollTracking<HTMLDivElement>();

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.investigationModeUnhealthyPods,
    addTimingParams: {
      enable: !!issue,
    },
  });

  if (!selectedSnapshot) {
    return null;
  }
  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Unhealthy Pods
        </Typography>
        <Typography variant="subtitle1" color={theme.foreground.fgSubtle}>
          Pod events, container logs and container metrics
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {SnapshotTabs}
        <PodsContent
          unhealthyPodsSnapshot={selectedSnapshot}
          onResourceClick={setDrawerResource}
        />
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.UnhealthyPodsStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
      <ResourceDrawer
        open={!!drawerResource}
        onClose={() => setDrawerResource(undefined)}
        resource={drawerResource}
      />
    </StepContainer>
  );
};
