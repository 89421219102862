import React, { useCallback, useMemo } from "react";
import { formatDistanceStrict } from "date-fns";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { getCustomDateLocale } from "../../../../../../../../../shared/utils/dateUtils";
import { selectOpenEventItemDrawer } from "../../../store/overviewPageStoreSelectors";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { gap } from "../../../shared/styles";
import DeployEventGroup from "../../../../../../../../common/EventGroup/deployEvent/DeployEventGroup";

import { getDeployDetails } from "./eventItemUtils";

export const StyledLink = styled(Link)`
  && {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - ${gap});
  gap: 10px;
  margin: ${gap} 0;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const TitleAndSummary = styled.div`
  flex-grow: 1;
  max-width: 384px;

  > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DateContainer = styled(Typography)`
  margin-left: auto;
  white-space: nowrap;
`;

const ServiceInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > p:not(:last-child) {
    margin-right: 4px;
  }
`;

type EventItemProps = {
  event: DeployEventGroup;
};
export const EventItem: React.FC<EventItemProps> = ({
  event,
}: EventItemProps) => {
  const openEventItemDrawer = useOverviewPageStore(selectOpenEventItemDrawer);
  const { status, summary, startTime, icon, fillColor, type } = event;
  const eventTitle = type === "Deploy" ? `${type} ${status}` : type;

  const formattedDate = useMemo(() => {
    return formatDistanceStrict(startTime, new Date(), {
      addSuffix: true,
      locale: getCustomDateLocale(),
    });
  }, [startTime]);

  const onEventClick = useCallback(() => {
    openEventItemDrawer(event);
  }, [event, openEventItemDrawer]);

  const serviceInfo = useMemo(() => {
    const { clusterName, namespace, slicedServiceId } = getDeployDetails(event);

    return (
      <ServiceInfoContainer>
        <Typography
          variant={"body3"}
          color={"text.secondary"}
          aria-label={"cluster name"}
        >{`${clusterName} /`}</Typography>
        <Typography
          variant={"body3"}
          color={"text.secondary"}
          aria-label={"namespace"}
        >
          {`${namespace} /`}
        </Typography>
        <StyledLink
          variant={"body3"}
          onClick={onEventClick}
          aria-label={"service name"}
        >{`${slicedServiceId}`}</StyledLink>
      </ServiceInfoContainer>
    );
  }, [event, onEventClick]);

  const Icon = icon;

  return (
    <Container>
      <IconContainer>
        <Icon fill={fillColor} />
      </IconContainer>
      <TitleAndSummary>
        <Typography variant={"h6"} color={"text.primary"}>
          {eventTitle}
        </Typography>
        {serviceInfo}
        <Typography variant={"body3"} color={"text.secondary"}>
          {summary}
        </Typography>
      </TitleAndSummary>
      <DateContainer variant={"body3"} color={"text.secondary"}>
        {formattedDate}
      </DateContainer>
    </Container>
  );
};
