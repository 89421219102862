export const cardTitle = "services are missing resource limits or requests";

export const explanationTitle = "What it means";
export const explanationBlock1 =
  "Some services within your workspace have not defined resource limits or requests in their configurations.";

export const explanationBlock2 =
  "Missing resource limits and requests can lead to resource contention and inefficient resource utilization within the cluster. Without resource limits, a service can consume excessive resources, potentially impacting the performance and availability of other services running on the same cluster.";

export const learnMoreText = "Learn more about resource requests and limits";

export const learnMoreUrl =
  "https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#how-pods-with-resource-limits-are-run";
