import { AvailabilityRunWithResults, IStep } from "../../types";
import { AvailabilityInvestigationCheckType } from "../types";

export const getStepInitStatus = (
  issue: AvailabilityRunWithResults | undefined
): IStep => {
  const noisyNeighborsResult = issue?.results?.noisyNeighbors;
  const output = noisyNeighborsResult?.output;

  return {
    type: AvailabilityInvestigationCheckType.NoisyNeighbors,
    visible: !!noisyNeighborsResult,
    visited: false,
    disabled:
      !!output && !output?.nodesInfo?.length && output?.isMetricsAllowed,
  };
};
