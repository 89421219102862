import { LocalStorageItem } from "../../localStorageSettings";

const storedLocalUserId = new LocalStorageItem("currentLocalUserId");

export const getAndClearStoredLocalUserId = (): string | null => {
  const value = storedLocalUserId.get();
  storedLocalUserId.remove();
  return value;
};

export const setStoredLocalUserId = (value: string): void =>
  storedLocalUserId.set(value);
