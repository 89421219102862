import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/Insight";
import { getColor } from "../../../../../Status";
import EventGroup from "../../../../../../EventGroup";
import DeployEventGroup, {
  groupDeployEvents,
} from "../../../../../../EventGroup/deployEvent/DeployEventGroup";
import { useDeployInsightByIdQuery } from "../../../../../../../../generated/graphql";
import { useQueryWithVariables } from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useOverridableFlags } from "../../../../../../../../shared/context/featureFlags/OverridableFlags";
import { DeployIssueDataParsed } from "../../common/types";

export const LATEST_DEPLOYS_TOOLTIP = "latest-deploys-tooltip";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useDeployEventGroupById = ({
  eventId,
}: {
  eventId?: string;
}): DeployEventGroup | undefined => {
  const flags = useOverridableFlags();
  const variables = useMemo(() => (eventId ? { eventId } : null), [eventId]);

  const minifiedRunById = useQueryWithVariables(
    useDeployInsightByIdQuery,
    variables
  );
  const correlatedDeployEvent = minifiedRunById?.event_deploy?.[0] ?? null;
  const correlatedDeployEventGroup = useMemo(() => {
    if (!correlatedDeployEvent) return undefined;

    const DeployEventGroups = groupDeployEvents([correlatedDeployEvent], flags);
    return DeployEventGroups?.[0];
  }, [correlatedDeployEvent, flags]);
  return correlatedDeployEventGroup;
};

export const LatestDeploysTooltip: React.FC<{
  deploys: DeployIssueDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  tooltipId: string;
  ariaLabel: string;
  ariaLabelLink: string;
}> = ({
  deploys,
  issueStartTime,
  setCorrelatedEvent,
  tooltipId,
  ariaLabel,
  ariaLabelLink,
}) => {
  const [eventId, setEventId] = useState<string>();
  const correlatedIssue = useDeployEventGroupById({
    eventId,
  });
  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedIssue) {
        setCorrelatedEvent(correlatedIssue);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedIssue, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedIssue) {
      setCorrelatedEvent(correlatedIssue);
    }
  }, [correlatedIssue, setCorrelatedEvent]);
  return (
    <Tooltip id={tooltipId} variant="light">
      <InsightTooltipContainer aria-label={ariaLabel}>
        {deploys.map((deploy, i) => (
          <React.Fragment key={deploy.id}>
            <InsightTooltipDetails>
              <Typography>Started</Typography>
              <Typography bold>
                {formatDistance(issueStartTime, deploy.startTime)} before
                current issue
              </Typography>
              <Typography>Change</Typography>
              <Typography bold>{deploy.summary}</Typography>
              <Typography>Status</Typography>
              <Typography variant="uppercase" color={getColor(deploy.status)}>
                {deploy.status}
              </Typography>
              <Link
                onClick={() => handleClick(deploy.id)}
                aria-label={ariaLabelLink}
              >
                View details
              </Link>
            </InsightTooltipDetails>
            {i < deploys.length - 1 && <Divider spacing="1rem" />}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
