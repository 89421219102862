import React from "react";

import { GroupByFilter } from "./GroupByFilter";
import { TimeWindowFilter } from "./TimeWindowFilter";

export const AllocationFilters: React.FC = () => {
  return (
    <>
      <GroupByFilter />
      <TimeWindowFilter />
    </>
  );
};
