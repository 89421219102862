import { useMemo } from "react";

import { useKeepSocketSessionAlive } from "../../../shared/sockets/useKeepSocketSessionAlive";
import { useInitiateSocketSession } from "../../../shared/sockets/useInitiateSocketSession";
import { SocketMessageType } from "../../../shared/sockets/types";

import { useHandleIncomingSocketMessage } from "./useHandleIncomingSocketMessage";
import { useHandleTerminalInputChanged } from "./useHandleTerminalInputChanged";
import { onReconnect, onReconnectFailure } from "./utils/handleWsReconnect";

export interface UsePodExecArgs {
  containerName: string;
  agentId: string;
  cluster: string;
  namespace: string;
  podName: string;
  identifier: string;
}

interface UsePodExecRes {
  onInputChanged: (input: string) => void;
  isSessionInitialized: boolean;
  isReconnecting: boolean;
}

export const usePodExec = ({
  agentId,
  cluster,
  namespace,
  podName,
  identifier,
  containerName,
}: UsePodExecArgs): UsePodExecRes => {
  const [refreshKeepAliveSession, closeKeepAliveSession] =
    useKeepSocketSessionAlive(identifier);

  const handleIncomingMessage = useHandleIncomingSocketMessage({
    identifier,
    closeKeepAliveSession,
  });

  const onInputChanged = useHandleTerminalInputChanged({
    identifier,
  });

  const initData = useMemo(
    () => ({
      cmd: `sh -c "command -v bash >/dev/null && exec bash || exec sh"`,
      podName,
      containerName,
      namespace,
    }),
    [containerName, namespace, podName]
  );

  const { isSessionInitialized, isReconnecting } = useInitiateSocketSession({
    identifier,
    agentId,
    cluster,
    namespace,
    podName,
    handleIncomingMessage,
    refreshKeepAliveSession,
    containerName,
    initData,
    initMessageType: SocketMessageType.PodExecInit,
    onReconnect,
    onReconnectFailure,
  });

  return { onInputChanged, isSessionInitialized, isReconnecting };
};
