import styled from "styled-components";

const Options = styled.div<{ open: boolean }>`
  ${({ open }) => (open ? "" : "display: none")};
  grid-area: content;
  padding: 0 1rem 1rem 3em;
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Options;
