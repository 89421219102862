import React from "react";
import styled from "styled-components";

import { useHasMetricsSupportedClusters } from "../hooks/overviewPageHooks";
import { gap } from "../shared/styles";
import { overviewMetricsAriaLabels } from "../../../appViewAriaLabels";

import { SingleOverviewMetric } from "./SingleOverviewMetric/SingleOverviewMetric";
import { useCostOptimizationTrendOverview } from "./hooks/useCostOptimizationTrendOverview";
import { useIssuesTrendOverview } from "./hooks/useIssuesTrendOverview";

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${gap};
  justify-content: space-between;
`;

const {
  costOptimizationTrend: costOptimizationAriaLabel,
  availabilityIssues: availabilityIssuesAriaLabel,
} = overviewMetricsAriaLabels;

export const OverviewMetrics: React.FC = () => {
  const issuesMetricsTrend = useIssuesTrendOverview();
  const costOptimizationTrend = useCostOptimizationTrendOverview();
  const hasMetricsSupportedClusters = useHasMetricsSupportedClusters();

  return (
    <FlexContainer>
      <SingleOverviewMetric
        {...issuesMetricsTrend}
        aria-label={availabilityIssuesAriaLabel}
      />
      <SingleOverviewMetric
        {...costOptimizationTrend}
        isDisabled={!hasMetricsSupportedClusters}
        loading={
          !hasMetricsSupportedClusters ? false : costOptimizationTrend.loading
        }
        aria-label={costOptimizationAriaLabel}
      />
    </FlexContainer>
  );
};
