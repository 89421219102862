import React from "react";
import { palette } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { RefreshButton } from "../../Actions/modals/DescribeModal/RefreshButton";
import { CloseButton } from "../Modal/CloseButton";
import { AriaLabels } from "../../../shared/config/ariaLabels";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LogsViewer, LogsViewerProps } from "./LogsViewer";

interface LogsViewerModalProps extends LogsViewerProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  onRefresh?: () => void;
  logsViewer?: JSX.Element;
}
const LogsViewerModal: React.FC<LogsViewerModalProps> = ({
  isOpen,
  handleCloseModal,
  onRefresh,
  logsViewer,
  ...logsViewerProps
}) => {
  const { podName, containerName } = logsViewerProps;
  const title =
    "Logs for " +
    (podName ? `pod: ${podName} ` : "") +
    (containerName ? `container: ${containerName}` : "");

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="xl"
      onClose={handleCloseModal}
      aria-label={AriaLabels.LogsViewer.LogsViewerModal.Modal}
    >
      <DialogTitle>
        {onRefresh ? <RefreshButton onClick={onRefresh} /> : null}
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <CloseButton onClose={handleCloseModal} />
      <DialogContent sx={{ height: "70vh" }} dividers={true}>
        {logsViewer !== undefined ? (
          logsViewer
        ) : (
          <LogsViewer
            {...logsViewerProps}
            toolbarBgColor={palette.white[0]}
            toolbarTopOffset="-1rem"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LogsViewerModal;
