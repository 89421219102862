import { useMemo } from "react";

import { useGetAllFiltersFromUrl } from "../components/FiltersSection/filtersStateHandler/useGetAllFiltersFromUrl";
import { ViolationCountBy } from "../../../generated/reliabilityApi";
import { useViolationsTableContext } from "../components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/context/useViolationsTableContext";

export const useScopeFilters = (): Partial<
  Record<ViolationCountBy, string[]>
> => {
  const { scope } = useViolationsTableContext();
  const filters = useGetAllFiltersFromUrl();

  return useMemo(() => ({ ...filters, ...scope }), [filters, scope]);
};
