import React from "react";
import styled from "styled-components";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { GraphsContainer, MainContainer } from "../../../MetricsTab/styles";
import { LoadingStateIds } from "../../workloadMetricsTabConstants";
import Resource from "../../../../resources/index";
import { useFetchContainersData } from "../../hooks/useFetchContainersData";

import { ContainerSelector } from "./ContainerSelector";
import { WorkloadContainerGraph } from "./WorkloadContainerGraph";

const Container = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type MetricsPerContainerProps = {
  resource: Resource;
  id?: string;
};

const shouldShowPerContainerGraph = (
  containers: MuiSelectionOption<string>[] | undefined
) => {
  return containers && containers.length > 1;
};

export const MetricsPerContainer: React.FC<MetricsPerContainerProps> = ({
  resource,
  id,
}) => {
  const { wantedContainer, setWantedContainer, containers } =
    useFetchContainersData({ resource });

  if (!shouldShowPerContainerGraph(containers)) {
    return null;
  }

  return (
    <Container>
      <Title>
        <Typography variant="h5" color="text.primary">
          Container metrics
        </Typography>
        <ContainerSelector
          containers={containers}
          wantedContainer={wantedContainer}
          onChange={setWantedContainer}
        />
      </Title>
      <GraphsContainer>
        <WorkloadContainerGraph
          type={"memory"}
          wantedContainer={wantedContainer}
          resource={resource}
          loadingStateId={LoadingStateIds.workloadContainersMemory}
          metricGraphProps={{
            syncId: id,
          }}
        />
        <WorkloadContainerGraph
          type={"cpu"}
          wantedContainer={wantedContainer}
          resource={resource}
          loadingStateId={LoadingStateIds.workloadContainersCPU}
          metricGraphProps={{
            syncId: id,
          }}
        />
      </GraphsContainer>
    </Container>
  );
};
