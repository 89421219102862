import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const EmptyMessageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type EmptyResultMessage = "no data" | "no search results";

type EmptyResultsMessageProps = {
  type: EmptyResultMessage;
};

const messages: Record<EmptyResultMessage, string> = {
  "no data": "No exceptions have been added.",
  "no search results":
    "No search results were found. Try changing your search.",
};

export const EmptyResultsMessage: React.FC<EmptyResultsMessageProps> = ({
  type,
}) => {
  return (
    <EmptyMessageContainer>
      <Typography variant={"body2"} color={"text.secondary"}>
        {messages[type]}
      </Typography>
    </EmptyMessageContainer>
  );
};
