import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  ArgoWorkflowsClustersResponse,
  apiV1CrdArgoworkflowsClustersGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

export const ARGO_WORKFLOWS_CLUSTERS = "/crd/argoworkflows/clusters";

const fetchArgoWorkflowsClusters = async (
  apiClient: AxiosInstance
): Promise<ArgoWorkflowsClustersResponse> => {
  const { data } = await apiClient.get(
    apiV1CrdArgoworkflowsClustersGetUrl(apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useArgoWorkflowClusters = (enabled?: boolean) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [ARGO_WORKFLOWS_CLUSTERS],
    () => fetchArgoWorkflowsClusters(apiClient),
    {
      enabled,
    }
  );
};
