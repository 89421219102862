import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import Komo from "../../../../../shared/assets/happyKomo.svg?react";

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
  margin-top: 6rem;
`;

export const EmptyState: React.FC<{ text: string }> = ({ text }) => {
  return (
    <EmptyStateContainer aria-label="empty-state">
      <Komo />
      <Typography
        variant={"title"}
        bold={true}
        color={palette.gray["600"]}
        size={"medium"}
        data-e2e-selector="empty-state-helm-data-title"
      >
        {text}
      </Typography>
    </EmptyStateContainer>
  );
};
