import { useCallback } from "react";

import { useOverviewPageStore } from "../../store/overviewPageStore";
import { selectTimeWindowIntervals } from "../../store/overviewPageStoreSelectors";
import { generateEmptyTimeStampDataPoints } from "../../../../__tests__/mocks/overviewPage/testUtils";

export const useGenerateEmptyDataPoints = () => {
  const timeWindowIntervals = useOverviewPageStore(selectTimeWindowIntervals);
  return useCallback(() => {
    return generateEmptyTimeStampDataPoints({
      fromEpoch: timeWindowIntervals[0].fromEpoch,
      toEpoch: timeWindowIntervals[1].toEpoch,
    });
  }, [timeWindowIntervals]);
};
