import styled from "styled-components";

import Checkbox from "../../../../../../common/controls/Checkbox";

const Option = styled(Checkbox)`
  margin: 0;
  input {
    margin: 0 0.5rem 0 0;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Option;
