import React from "react";
import { parseISO } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../../groupEvents";
import {
  Kubernetes_Service_ResourceFragment,
  Maybe,
} from "../../../../../generated/graphql";
import EventGroup, { EventBase } from "../..";
import { blueForUI, blueForUIText } from "../../../../../Colors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ServiceResourceDetails } from "../../../ProcessList/details/ServiceResourceDetailes";
import { k8sServiceResourcesType } from "../types";

import ConfigChangeScatterShape from "./../../shapes/config.svg?react";

export interface ServiceResourceEvent extends EventBase {
  readonly name: string;
  readonly namespace?: string;
  readonly clusterName?: Maybe<string>;
  readonly action: string;
}

const toServiceResourceEvent = (
  e: Kubernetes_Service_ResourceFragment,
  serviceId: string
): ServiceResourceEvent => ({
  id: e.id,
  serviceId,
  eventTime: parseISO(e.eventTime),
  type: k8sServiceResourcesType,
  name: e.name,
  namespace: e.namespace,
  clusterName: e.clusterName,
  action: e.action,
});

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class ServiceResourceEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigChangeScatterShape;

  readonly events: ServiceResourceEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly serviceId;
  readonly sendEventWhenClicked = true;

  constructor(e: ServiceResourceEvent) {
    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = k8sServiceResourcesType;
    this.summary = `${e.action} K8s service - ${e.clusterName}/${e.namespace}/${e.name}`;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.details = "";
    this.status = "Completed";
    this.serviceId = e.serviceId;
  }

  renderEventDetails(): JSX.Element {
    return <ServiceResourceDetails eventGroup={this} />;
  }
}

export const groupServiceResourceEvents = (
  events: Kubernetes_Service_ResourceFragment[],
  serviceId: string
): ServiceResourceEventGroup[] => {
  return mapAndSort(
    withServices(events, serviceId),
    toServiceResourceEvent
  ).map((e) => new ServiceResourceEventGroup(e));
};
