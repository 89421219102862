import styled from "styled-components";
import { palette } from "@komodorio/design-system";

export const TooltipHourColor = palette.gray["600"];
export const TooltipValuesColor = palette.black["0"];
export const TooltipValuesFormatColor = palette.gray["500"];
export const TooltipBackground = palette.white["0"];

export const MetricsTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1.5rem;
  background-color: ${TooltipBackground};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
`;

export const MetricsTooltipGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 0.25rem 1rem;
`;

export const DateTimeTitle = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

export const ValueContainer = styled.div`
  display: flex;
  column-gap: 0.2rem;
  align-items: baseline;
`;
