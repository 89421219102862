export enum ZIndex {
  AppBar = 100,
  EventDrawer = 20,
  ResourceDrawer = 4,
  AboveAppBar = 101,
  AboveModal = 10001,
  AboveEventDrawer = 21,
  TimelineChart = 15,
  AboveTimelineChart = 16,
  AboveMuiDrawer = 1201,
}
