import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { usePolicyDrawerContext } from "./context/usePolicyDrawerContext";
import { PolicyConfigurationsTabs } from "./policyDrawerTypes";
import { StyledDivider } from "./PolicyDrawerStyles";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  policies: {
    policiesDrawer: {
      tabs: {
        runtimeChecks: runtimeChecksAriaLabel,
        bestPracticeChecks: bestPracticeChecksAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

export const PolicyDrawerTabs: React.FC = () => {
  const { configurationsTab, setConfigurationsTab } = usePolicyDrawerContext();
  const { reliabilityBestPracticesTab } = useOverridableFlags();

  if (!reliabilityBestPracticesTab) return null;
  return (
    <div>
      <Tabs
        value={configurationsTab}
        onChange={(event, newValue) => setConfigurationsTab(newValue)}
      >
        <Tab
          aria-label={runtimeChecksAriaLabel}
          label="Runtime checks"
          value={PolicyConfigurationsTabs.dynamic}
        />
        <Tab
          aria-label={bestPracticeChecksAriaLabel}
          label="Best practice checks"
          value={PolicyConfigurationsTabs.static}
        />
      </Tabs>
      <StyledDivider $marginBottom={8} />
    </div>
  );
};
