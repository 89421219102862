import styled from "styled-components";

import { AttributesComparison } from "./AttributesComparison";
import { ComparisonTabs } from "./ComparisonTabs";
import { ServicesComparisonTabs } from "./constants";
import { useServicesComparisonContext } from "./useServicesComparisonContext";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";

const Container = styled.div`
  padding: 24px 0;
`;
export const ComparisonContent: React.FC = () => {
  const { selectedTab } = useServicesComparisonContext();
  return (
    <ResponsiveLayout>
      <ComparisonTabs />
      <Container>
        {selectedTab === ServicesComparisonTabs.Attributes ? (
          <AttributesComparison />
        ) : (
          <div>Metrics</div>
        )}
      </Container>
    </ResponsiveLayout>
  );
};
