import { ValueType } from "recharts/types/component/DefaultTooltipContent";

import { NAMES_MAX_LENGTH } from "./constants";
import { Dictionary } from "../../../types";

type ChartElement = Dictionary<string | number | null>;

export const getTooltipValue = (value: ValueType) => {
  return value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};

export const getText = (text: string, isLimited: boolean) => {
  if (!isLimited) return text;
  return text.length <= NAMES_MAX_LENGTH
    ? text
    : `${text.slice(0, NAMES_MAX_LENGTH)}...`;
};

export const getDisplayedChartData = ({
  chartData,
  hiddenSeries,
}: {
  chartData: ChartElement[];
  hiddenSeries: Dictionary<boolean>;
}) => {
  return chartData.map((chartElement) => {
    const fullElementData: ChartElement = {
      ...chartElement,
    };
    Object.keys(hiddenSeries).forEach((e) => {
      if (hiddenSeries[e]) {
        fullElementData[e] = null;
      }
    });
    return fullElementData;
  });
};
