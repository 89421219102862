import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

export type FooterButtonsState = "loading" | "disabled" | "enabled";

type IgnoreCheckViolationFooterProps = {
  onIgnore: () => void;
  onClose: () => void;
  state: FooterButtonsState;
};

export const IgnoreCheckViolationFooter: React.FC<
  IgnoreCheckViolationFooterProps
> = ({ onIgnore, onClose, state }) => {
  return (
    <Container>
      <Button variant={"text"} size={"large"} onClick={() => onClose()}>
        Cancel
      </Button>
      <LoadingButton
        variant={"contained"}
        size={"large"}
        onClick={() => onIgnore()}
        disabled={state === "disabled"}
        loading={state === "loading"}
      >
        Ignore check
      </LoadingButton>
    </Container>
  );
};
