import React, { useState } from "react";
import styled from "styled-components";
import ModeEdit from "@mui/icons-material/ModeEdit";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { IgnoreRule } from "../../../../../../../generated/reliabilityApi";
import { useSetAddEditIgnoreRuleDialogState } from "../../../../../hooks/reliabilityHooks";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import { useHasPermissions } from "../../../../../../../shared/hooks/useUserMetadata/rbac";

import { DeleteRuleDialog } from "./DeleteRuleDialog";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const {
  ignoreChecks: {
    deleteRuleIconButton: deleteRuleAriaLabel,
    editRuleIconButton: editRuleAriaLabel,
  },
} = reliabilityArialLabels;

type IgnoreChecksTableActionsProps = {
  ignoreRule: IgnoreRule;
};

export const IgnoreChecksTableActions: React.FC<
  IgnoreChecksTableActionsProps
> = ({ ignoreRule }) => {
  const [isDeleteRuleDialogOpen, setIsDeleteRuleDialogOpen] = useState(false);
  const setDialogState = useSetAddEditIgnoreRuleDialogState();

  const openEditRuleDialog = () =>
    setDialogState({
      isOpen: true,
      checkType: ignoreRule.checkType,
      existingIgnoreRule: ignoreRule,
    });

  const { canManageReliability } = useHasPermissions();
  const toolTipTitle = (type: string) =>
    canManageReliability ? type : `missing ${type} permissions`;

  return (
    <Container>
      <Tooltip title={toolTipTitle("Edit")} placement={"left"}>
        <span>
          <IconButton
            onClick={openEditRuleDialog}
            aria-label={editRuleAriaLabel}
            disabled={!canManageReliability}
          >
            <ModeEdit />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={toolTipTitle("Delete")} placement={"right"}>
        <span>
          <IconButton
            onClick={() => setIsDeleteRuleDialogOpen(true)}
            aria-label={deleteRuleAriaLabel}
            disabled={!canManageReliability}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
      <DeleteRuleDialog
        open={isDeleteRuleDialogOpen}
        onClose={() => setIsDeleteRuleDialogOpen(false)}
        ignoreRule={ignoreRule}
      />
    </Container>
  );
};
