import React from "react";
import styled from "styled-components";

import Button from "../../../../../common/controls/Button";
import { blueBrand, gray12, grayBorder } from "../../../../../../Colors";
import { handleBack, handleNext } from "../utils";
import { InstallationScriptStepsNumber, StepsNumber } from "../types";

const StyledButton = styled(Button)<{ invertedColors?: boolean }>`
  height: 2rem;
  ${({ invertedColors }) =>
    invertedColors
      ? `color: ${blueBrand}; background-color: white`
      : `color: white; background-color: ${blueBrand}`};

  border-radius: 0.25rem;
  :disabled {
    background: #dcdddf;
    color: ${gray12};
    cursor: not-allowed;
  }
  cursor: pointer;
`;

const StyledBorderButton = styled(StyledButton)`
  border: 1px solid ${grayBorder};
`;

type ButtonProps = {
  currentInstallStep: StepsNumber | InstallationScriptStepsNumber;
  isFirstInstallation?: boolean;
  setCurrentInstallStep: React.Dispatch<
    React.SetStateAction<StepsNumber | InstallationScriptStepsNumber>
  >;
};

export const BackButton: React.FC<
  ButtonProps & { isSupportedDemoAccount?: boolean }
> = ({ currentInstallStep, setCurrentInstallStep, isSupportedDemoAccount }) => (
  <StyledBorderButton
    data-e2e-selector="k8s-integration-back-button"
    invertedColors={true}
    onClick={() =>
      handleBack(
        currentInstallStep,
        setCurrentInstallStep,
        isSupportedDemoAccount
      )
    }
  >
    Back
  </StyledBorderButton>
);

export const WelcomeButton: React.FC<
  ButtonProps & {
    isSupportedDemoAccount?: boolean;
  }
> = ({ currentInstallStep, setCurrentInstallStep, isSupportedDemoAccount }) => {
  const handleClick = async () => {
    handleNext(
      currentInstallStep,
      setCurrentInstallStep,
      isSupportedDemoAccount
    );
  };
  return (
    <StyledButton
      data-e2e-selector="k8s-integration-welcome-button"
      onClick={handleClick}
    >
      Get Started
    </StyledButton>
  );
};

export const NextButton: React.FC<
  ButtonProps & {
    disabled: boolean;
    installKubernetes: () => Promise<string | null>;
  }
> = ({
  currentInstallStep,
  setCurrentInstallStep,
  disabled,
  isFirstInstallation,
  installKubernetes,
}) => {
  const handleClick = async () => {
    if (
      !isFirstInstallation &&
      currentInstallStep === StepsNumber.Cluster_Name
    ) {
      const res = await installKubernetes();
      if (!res) {
        return;
      }
    }
    handleNext(currentInstallStep, setCurrentInstallStep, undefined);
  };
  return (
    <StyledButton
      data-e2e-selector="k8s-integration-next-button"
      onClick={handleClick}
      disabled={disabled}
    >
      Next
    </StyledButton>
  );
};
