import React, { useMemo } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LoadingButton from "@mui/lab/LoadingButton";

import { useReliabilityStore } from "../../../../../store/reliabilityStore";
import { selectAddEditIgnoreRuleDialogState } from "../../../../../store/reliabilityStoreSelectors";
import { useSetAddEditIgnoreRuleDialogState } from "../../../../../hooks/reliabilityHooks";
import { initialState } from "../../../../../store/initialState";
import { notifyDDError } from "../../../../../../../shared/hooks/exceptionManagement";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";

import { useGenerateClustersMap } from "./hooks/useGenerateClustersMap";
import { ResourceMultiSelect } from "./ResourceMultiSelect";
import { useAddOrEditRule, useCreateIgnoreRule } from "./hooks/createRuleHooks";
import { useAddEditRuleContext } from "./context/useAddEditRuleContext";

const Container = styled.div`
  padding: 16px 24px;
  width: 900px;
  overflow: hidden;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
`;

const SelectorsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

const StyledKeyboardArrowRight = styled(KeyboardArrowRight)`
  margin-top: 30px;
`;

const {
  ignoreChecks: {
    editRuleButton: editRuleAriaLabel,
    addRuleButton: addRuleAriaLabel,
    cancelButton: cancelAriaLabel,
  },
} = reliabilityArialLabels;

export const AddEditRuleDialogContent: React.FC = () => {
  const { isOpen, existingIgnoreRule, checkType } = useReliabilityStore(
    selectAddEditIgnoreRuleDialogState
  );
  const { selectedClusterNames, selectedNamespaces, selectedServices } =
    useAddEditRuleContext();
  const setDialogState = useSetAddEditIgnoreRuleDialogState();
  const onClose = () =>
    setDialogState(initialState.addEditIgnoreRuleDialogState);

  const { executeMutation, isSendingRequest } = useAddOrEditRule();
  const createIgnoreRule = useCreateIgnoreRule();

  const onActionButtonClick = async () => {
    const ignoreRule = createIgnoreRule();
    if (!ignoreRule) {
      notifyDDError(
        new Error(`Invalid ignore rule created for checkType: ${checkType}`)
      );
      return;
    }

    const { error } = await executeMutation(ignoreRule);
    !error && onClose();
  };

  useGenerateClustersMap();

  const title = existingIgnoreRule ? "Edit exception" : "Add exception";
  const actionButtonLabel = existingIgnoreRule ? "Edit rule" : "Add rule";
  const ariaLabel = existingIgnoreRule ? editRuleAriaLabel : addRuleAriaLabel;

  const actionButtonEnabled = useMemo(() => {
    return (
      selectedClusterNames.length > 0 &&
      selectedNamespaces.length > 0 &&
      selectedServices.length > 0
    );
  }, [
    selectedClusterNames.length,
    selectedNamespaces.length,
    selectedServices.length,
  ]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <Container>
        <Title variant={"h3"}>{title}</Title>
        <Typography variant={"body2"}>
          The check will run on all resources, <b>except</b>
        </Typography>
        <SelectorsContainer>
          <ResourceMultiSelect
            type={"clusterNames"}
            disabled={isSendingRequest}
          />
          <StyledKeyboardArrowRight color={"action"} />
          <ResourceMultiSelect
            type={"namespaces"}
            disabled={isSendingRequest}
          />
          <StyledKeyboardArrowRight color={"action"} />
          <ResourceMultiSelect
            type={"shortResourceNames"}
            disabled={isSendingRequest}
          />
        </SelectorsContainer>

        <Footer>
          <Button
            variant={"outlined"}
            onClick={onClose}
            aria-label={cancelAriaLabel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            disabled={!actionButtonEnabled}
            onClick={onActionButtonClick}
            loading={isSendingRequest}
            aria-label={ariaLabel}
          >
            {actionButtonLabel}
          </LoadingButton>
        </Footer>
      </Container>
    </Dialog>
  );
};
