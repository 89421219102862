/* eslint-disable max-lines */
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import {
  Button,
  Divider,
  Input,
  MultiSelect,
  OptionType,
  Tag,
  Typography,
} from "@komodorio/design-system/deprecated";
import { InfoCircleOutlined16, Loader16 } from "@komodorio/design-system/icons";
import { ActionTypes, TaskType } from "komodor-types";
import {
  ActionMetadataHelmRepoAdd,
  ActionMetadataHelmRepoRemove,
} from "komodor-types/build/entities/AgentTask";
import { difference } from "lodash";

import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { WhiteContainer } from "../../../ResourceView/common/resourceUtils";
import { StyledDrawer } from "../../../ResourceView/ResourceDrawer";
import { useMultiClusterAgentTask } from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { invalidateChartRevisionsCache } from "../utils";
import { useCreateHelmRepo } from "../../../../shared/hooks/resources-api/client/helmRepos/useCreateHelmRepo";
import { useDeleteHelmRepo } from "../../../../shared/hooks/resources-api/client/helmRepos/useDeleteHelmRepo";

import { ButtonsContainer, Z_INDEX_ABOVE_MODAL } from "./styles";
import HelmDrawerHeader from "./upgrade/ChartUpgradeHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto 43fr auto 3fr;
  height: 100%;
`;
const StyledHeader = styled(HelmDrawerHeader)`
  width: 100%;
`;
const StyledMainContent = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 2rem;
  margin-top: 2rem;
`;
const StyledButtonsContainer = styled(ButtonsContainer)`
  width: 100%;
`;
const Note = styled(Tag)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
  align-items: center;
  margin: 0 1.5rem;
  color: ${theme.foreground.fgSubtle};
`;

export interface RepoEditDrawerProps {
  open: boolean;
  onClose: () => void;
  refresh: () => void;
  clustersList: string[];
  clustersSelected: string[];
  repoName: string;
  repoUrl: string;
}

const RepoEditDrawerContent: React.FC<RepoEditDrawerProps> = ({
  onClose,
  refresh,
  clustersList,
  clustersSelected,
  repoName,
  repoUrl,
}) => {
  const [clusterValue, setClustersValue] = useState<OptionType<string>[]>(
    clustersSelected.sort().map((el) => {
      return { label: el, value: el };
    })
  );
  const { mutateAsync: createHelmRepo } = useCreateHelmRepo();
  const { mutateAsync: deleteHelmRepo } = useDeleteHelmRepo();
  const [isRunningUpdateAgentTask, setRunningUpdateAgentTask] = useState(false);

  const {
    execute: executeMultiClusterAgentTask,
    isFetching: isRunningAgentTask,
  } = useMultiClusterAgentTask();

  const createRepos = useCallback(
    async (clusters: string[], repoName: string, repoUrl: string) => {
      for (const clusterName of clusters) {
        const helmRepo = await createHelmRepo({
          clusterName,
          repoName,
          repoUrl,
        });
        if (!helmRepo) {
          // Do nothing
        }
      }
      const metadata: ActionMetadataHelmRepoAdd = {
        cluster: "",
        repoName,
        repoUrl,
        type: ActionTypes.AddHelmRepo,
      };
      await executeMultiClusterAgentTask(
        TaskType.ACTION_COMMAND,
        metadata,
        clusters
      );
    },
    [createHelmRepo, executeMultiClusterAgentTask]
  );

  const deleteRepos = useCallback(
    async (clusters: string[], repoName: string, repoUrl: string) => {
      for (const clusterName of clusters) {
        const helmRepo = await deleteHelmRepo({
          clusterName,
          repoName,
          repoUrl,
        });
        if (!helmRepo) {
          // Do nothing
        }
      }
      const metadata: ActionMetadataHelmRepoRemove = {
        cluster: "",
        repoName,
        type: ActionTypes.RemoveHelmRepo,
      };
      await executeMultiClusterAgentTask(
        TaskType.ACTION_COMMAND,
        metadata,
        clusters
      );
    },
    [deleteHelmRepo, executeMultiClusterAgentTask]
  );

  const updateRepos = useCallback(
    async (clusters: string[], repoName: string) => {
      const metadata: ActionMetadataHelmRepoRemove = {
        cluster: "",
        repoName,
        type: ActionTypes.UpdateHelmRepo,
      };
      await executeMultiClusterAgentTask(
        TaskType.ACTION_COMMAND,
        metadata,
        clusters
      );
    },
    [executeMultiClusterAgentTask]
  );

  const clusterOptions = clustersList.map((c) => {
    return { label: c, value: c };
  });
  const currentClusters = clusterValue?.map((c) => c.value) ?? [];

  return (
    <Grid>
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledHeader title={"Edit Helm Repository"} onClose={onClose} />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <ResponsiveLayout>
        <StyledMainContent>
          <Typography variant={"text"} size={"large"}>
            Connect your repositories to enable upgrading Helm charts directly
            from Komodor
          </Typography>
          <Input
            label="Name"
            size="medium"
            width="20rem"
            value={repoName}
            disabled={true}
          />
          <Input
            label="URL"
            size="medium"
            width="20rem"
            value={repoUrl}
            disabled={true}
          />
          <MultiSelect
            label="Cluster(s)"
            width="20rem"
            size="medium"
            placeholder="Select the Cluster(s) to install the repo on"
            value={clusterValue}
            options={clusterOptions}
            onChange={(v) => setClustersValue(v)}
            listZIndex={Z_INDEX_ABOVE_MODAL}
            listMaxHeight="8rem"
          />
        </StyledMainContent>
      </ResponsiveLayout>
      {difference(clustersSelected, currentClusters).length > 0 && (
        <Note>
          <InfoCircleOutlined16 />
          <Typography>
            Unselecting clusters will remove the repository from those clusters
            when you click save.
          </Typography>
        </Note>
      )}
      <Divider />
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledButtonsContainer>
            <Button
              size="small"
              variant="primary"
              onClick={async () => {
                if (!isRunningAgentTask) {
                  await deleteRepos(
                    difference(clustersSelected, currentClusters),
                    repoName,
                    repoUrl
                  );
                  await createRepos(
                    difference(currentClusters, clustersSelected),
                    repoName,
                    repoUrl
                  );
                  invalidateChartRevisionsCache();
                  refresh();
                  onClose();
                }
              }}
              disabled={!(clusterValue && repoName && repoUrl)}
            >
              {isRunningAgentTask && !isRunningUpdateAgentTask ? (
                <Loader16 />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              size="small"
              variant="primary"
              disabled={
                !(clusterValue && repoName && repoUrl) ||
                !!difference(
                  clustersSelected,
                  clusterValue?.map((c) => c.value) ?? []
                ).length
              }
              onClick={async () => {
                if (!isRunningUpdateAgentTask) {
                  setRunningUpdateAgentTask(true);
                  await updateRepos(currentClusters, repoName);
                  invalidateChartRevisionsCache();
                  setRunningUpdateAgentTask(false);
                  refresh();
                  onClose();
                }
              }}
            >
              {isRunningUpdateAgentTask ? <Loader16 /> : "Update"}
            </Button>
            <Button size="small" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </StyledButtonsContainer>
        </ResponsiveLayout>
      </WhiteContainer>
    </Grid>
  );
};

const RepoEditDrawer: React.FC<RepoEditDrawerProps> = (props) => {
  return (
    <StyledDrawer
      open={props.open}
      width={"25%"}
      onOverlayClick={props.onClose}
      onEscKeyPress={props.onClose}
      zIndex={1}
    >
      <RepoEditDrawerContent
        open={props.open}
        repoName={props.repoName}
        onClose={props.onClose}
        refresh={props.refresh}
        clustersList={props.clustersList}
        clustersSelected={props.clustersSelected}
        repoUrl={props.repoUrl}
      />
    </StyledDrawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RepoEditDrawer;
