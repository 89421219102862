import {
  MetricsAllocatableColor,
  MetricsCapacityColor,
  MetricsLimitColor,
  MetricsRequestColor,
  MetricsUsageColor,
} from "../styles";

import { LegendItem } from "./GenericLegend";

const defaultLegendItems: LegendItem[] = [
  { color: MetricsRequestColor ?? "", label: "Requests" },
  { color: MetricsLimitColor ?? "", label: "Limits" },
];

export const defaultCpuLegendItems: LegendItem[] = [
  { color: MetricsUsageColor ?? "", label: "CPU usage" },
  ...defaultLegendItems,
];

export const defaultMemoryLegendItems: LegendItem[] = [
  { color: MetricsUsageColor ?? "", label: "Memory usage" },
  ...defaultLegendItems,
];

export const clusterLegendItems: LegendItem[] = [
  { color: MetricsUsageColor, label: "Usage" },
  { color: MetricsAllocatableColor, label: "Allocatable" },
  { color: MetricsCapacityColor, label: "Capacity" },
];
