import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResource } from "../../../useResource";
import { DescribeTextItem } from "../common/DescribeItem";
import { KubernetesNodesResource } from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";

import { DescribeTextItemWithResourceDrawer } from "./DescribeTextItemWithResourceDrawer";

export interface NodeResourceLinkProps {
  nodeName: string;
  cluster: string;
  agentId: string;
}
export const NodeResourceLink = ({
  nodeName,
  cluster,
  agentId,
}: NodeResourceLinkProps) => {
  const { resource } = useResource({
    resourceName: nodeName,
    resourceType: KubernetesNodesResource.Kind,
    cluster: cluster,
    agentId: agentId,
  });

  return resource ? (
    <DescribeTextItemWithResourceDrawer resource={resource} label="Node" />
  ) : (
    <DescribeTextItem name="Node" value={nodeName} />
  );
};
