export const POLICIES_TABLE_LOADING_STATE_ROWS = 5;
export const POLICIES_TABLE_ROW_HEIGHT = 56;
export const PRIORITY_CELL_COLUMN_WIDTH = 90;

export const HTTP_STATUS_CONFLICT = 409;

export const policyReorderMessages = {
  success: "Reordering successful",
  priorityTakenError: "Reordering failed. Priority value is taken",
  generalError: "Reordering failed. Please try again.",
};
