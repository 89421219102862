import React, { useEffect, useState } from "react";
import { Button } from "@komodorio/design-system/deprecated";
import { ArrowUp16 } from "@komodorio/design-system/icons";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HelmChart from "../../../Inspection/Helm/helmChart";
import { ActionButtonProps } from "../types";
import {
  NoRepositoriesConfiguredMessage,
  useCheckForAvailableCharts,
} from "../../../Inspection/Helm/hooks/useHelmCharts";
import { useHasPermissions } from "../../../../shared/hooks/useUserMetadata/rbac";
import { ButtonStylesProps } from "../../common/actionsStyleMap";
import ChartUpgradeDrawer from "../../../Inspection/Helm/drawers/ChartUpgradeDrawer";
import Action from "../index";

const checkVersionStateButtonStyle: ButtonStylesProps = {
  actionItemVariant: "default",
  buttonVariant: "secondary",
  disabled: false,
  icon: undefined,
  text: "Check version",
};

const noReposConfiguredButtonStyle: ButtonStylesProps = {
  buttonVariant: "secondary",
  actionItemVariant: "default",
  text: "No repos configured",
  disabled: true,
};

const changeVersionButtonStyle: ButtonStylesProps = {
  buttonVariant: "secondary",
  actionItemVariant: "default",
  icon: ArrowUp16,
  text: "Change version",
};

const noVersionsAvailableButtonStyle: ButtonStylesProps = {
  buttonVariant: "secondary",
  actionItemVariant: "default",
  text: "No versions available",
  disabled: true,
};

const checkingVersionsButtonStyle: ButtonStylesProps = {
  buttonVariant: "secondary",
  actionItemVariant: "default",
  text: "Checking versions...",
  disabled: true,
};

const getUpgradeChartButton = (
  styles: ButtonStylesProps
): React.FC<{
  onClick: () => void;
}> => {
  return ({ onClick }) => {
    return (
      <Button
        variant={styles.buttonVariant}
        icon={styles.icon}
        disabled={styles.disabled}
        onClick={onClick}
        name={styles.text}
      >
        {styles.text}
      </Button>
    );
  };
};

const UpgradeHelmChartButton: React.FC<ActionButtonProps> = ({ resource }) => {
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState<ButtonStylesProps>(
    checkVersionStateButtonStyle
  );

  const [versionsTimeout, setVersionsTimeout] = useState(false);
  const startTimer = () => {
    setVersionsTimeout(true);
    const timer = setTimeout(() => {
      setVersionsTimeout(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const currentChart = resource as HelmChart;

  const { availableCharts, errorMessage: availableVersionsError } =
    useCheckForAvailableCharts(currentChart);

  const { canManageHelm } = useHasPermissions();

  useEffect(() => {
    if (versionsTimeout) {
      return;
    }
    if (
      availableVersionsError
        ?.toLowerCase()
        ?.startsWith(NoRepositoriesConfiguredMessage)
    ) {
      setStyle(noReposConfiguredButtonStyle);
    } else if (availableCharts?.length === 0) {
      setStyle(noVersionsAvailableButtonStyle);
    } else if (availableCharts?.length) {
      setStyle(changeVersionButtonStyle);
    }
  }, [availableCharts?.length, availableVersionsError, versionsTimeout]);

  const onClick = (): void => {
    if (style.text === checkVersionStateButtonStyle.text) {
      startTimer();
      setStyle(checkingVersionsButtonStyle);
    } else if (style.text === changeVersionButtonStyle.text) {
      setOpen(true);
    }
  };

  return canManageHelm ? (
    <Action
      agentId={resource.agentId}
      cluster={resource.cluster}
      onClick={onClick}
      Button={getUpgradeChartButton(style)}
    >
      <ChartUpgradeDrawer
        allCharts={availableCharts ?? []}
        chart={currentChart}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Action>
  ) : null;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default UpgradeHelmChartButton;
