import { AccountPlan } from "komodor-types";
import { useEffect, useMemo } from "react";

import { useIsArgoWorkflowsEnabledForAccount } from "@/components/argoWorkflowsView/hooks";
import { useGetAccount } from "@/shared/hooks/accounts-service/client/account/useGetAccount";

export const useShouldShowArgoWorkflows = () => {
  const [cachedIsArgoWFEnabled, setCachedIsArgoWFEnabled] =
    useIsArgoWorkflowsEnabledForAccount();

  const { data: account } = useGetAccount(cachedIsArgoWFEnabled !== "true");

  const showArgoWorkflows = useMemo(
    () =>
      cachedIsArgoWFEnabled === "true" ||
      account?.plan === AccountPlan.free ||
      account?.plan === AccountPlan.trial ||
      account?.inPoc ||
      !!account?.features.argoWF,
    [
      cachedIsArgoWFEnabled,
      account?.plan,
      account?.inPoc,
      account?.features.argoWF,
    ]
  );

  useEffect(() => {
    setCachedIsArgoWFEnabled(showArgoWorkflows ? "true" : "false");
  }, [setCachedIsArgoWFEnabled, showArgoWorkflows]);

  return showArgoWorkflows;
};
