import { useCallback, useEffect, useState } from "react";
import { HelmChartSummary } from "komodor-types";

import Resource from "../../../resources";
import { useResource } from "../../../useResource";
import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";
import { useHelmChartByName } from "../../../../Inspection/Helm/hooks/useHelmChartByName";

import { OwnerResourcesParams } from "./types";
import {
  getCustomResourceOptions,
  getHelmChartName,
} from "./fetchResourceUtils";

export const useFetchResourceControlledBy = ({
  controlledResource,
  ownerMetadata,
}: OwnerResourcesParams): UseOwnerResourceDescribeTabReturnType => {
  const { showControlledByResourceDrawer } = useOverridableFlags();
  const { agentId, cluster, namespace } = controlledResource;
  const useResourceParams = {
    agentId,
    cluster,
    namespace,
  };

  const { resource: ownerResource, isFetching: isFetchingOwnerResource } =
    useResource({
      ...useResourceParams,
      resourceName: ownerMetadata?.name || "",
      resourceType: ownerMetadata?.kind || "",
      executeMutation: !!showControlledByResourceDrawer && !!ownerMetadata,
      ...getCustomResourceOptions(ownerMetadata),
    });

  const parentOwner = ownerResource?.ownerReferences?.[0];
  const shouldFetchParentResource =
    !!showControlledByResourceDrawer && !!parentOwner;

  const { resource: parentResource, isFetching: fetchingParentResource } =
    useResource({
      ...useResourceParams,
      resourceName: parentOwner?.name || "",
      resourceType: parentOwner?.kind || "",
      executeMutation: shouldFetchParentResource,
    });

  const isFetchingParentResource = shouldFetchParentResource
    ? fetchingParentResource
    : false;

  const helmChartData = useGetHelmChart(
    isFetchingParentResource ? undefined : parentResource || controlledResource
  );

  const { ownerResourceIsFetching, parentResourceIsFetching } =
    useUpdateFetchingState({
      isFetchingOwnerResource,
      isFetchingParentResource,
    });

  if (!showControlledByResourceDrawer) {
    return { isFetchingResources: false };
  }

  return {
    ownerResource,
    parentResource,
    helmChartData,
    isFetchingResources:
      ownerResourceIsFetching ||
      (shouldFetchParentResource ? parentResourceIsFetching : false),
    // helmChartIsFetching is ignored from reporting fetching state
  };
};

export const useGetHelmChart = (resource?: Resource): HelmChartSummary => {
  const helmChartName = getHelmChartName({
    labels: resource?.labels,
    annotations: resource?.annotations,
  });

  const chart = useHelmChartByName({
    cluster: resource?.cluster || "",
    chartName: helmChartName || "",
    namespace: resource?.namespace || "",
    pause: !helmChartName,
  });

  return chart;
};

type UseOwnerResourceDescribeTabReturnType = {
  ownerResource?: Resource;
  parentResource?: Resource;
  helmChartData?: HelmChartSummary;
  isFetchingResources: boolean;
};

type OwnerResourceFetchKey =
  | "isFetchingOwnerResource"
  | "isFetchingParentResource";
type OwnerResourceLoadingState = Record<OwnerResourceFetchKey, boolean>;
type UpdateFetchingStateOutput = {
  ownerResourceIsFetching: boolean;
  parentResourceIsFetching: boolean;
};

/** prevent fetching state from changing to false after it was set to true */
export const useUpdateFetchingState = ({
  isFetchingOwnerResource,
  isFetchingParentResource,
}: OwnerResourceLoadingState): UpdateFetchingStateOutput => {
  const [fetchingState, setFetchingState] = useState<OwnerResourceLoadingState>(
    {
      isFetchingOwnerResource: true,
      isFetchingParentResource: true,
    }
  );

  const updateFetchingState = useCallback(
    (resourceName: OwnerResourceFetchKey, isFetching: boolean) => {
      if (!fetchingState[resourceName] || isFetching) return;
      setFetchingState((prevState) => ({
        ...prevState,
        [resourceName]: isFetching,
      }));
    },
    [fetchingState]
  );

  useEffect(() => {
    updateFetchingState("isFetchingOwnerResource", isFetchingOwnerResource);
    updateFetchingState("isFetchingParentResource", isFetchingParentResource);
  }, [isFetchingOwnerResource, isFetchingParentResource, updateFetchingState]);

  return {
    ownerResourceIsFetching: fetchingState.isFetchingOwnerResource,
    parentResourceIsFetching: fetchingState.isFetchingParentResource,
  };
};
