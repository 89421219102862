import React, { memo } from "react";
import Skeleton from "@mui/material/Skeleton";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import styled from "styled-components";

import { getRandRange } from "../../../../../../../../../shared/utils/getRandRange";

import {
  ContentContainer,
  StyledTimeline,
  StyledTimelineDot,
} from "./historyStyles";
import { DotSeparator } from "./DotSeparator";

const StyledTimelineContent = styled(TimelineContent)`
  && {
    padding-top: 10px;
  }
`;

const FetchContent: React.FC<{ isLastItem?: boolean }> = memo(
  ({ isLastItem }) => {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <StyledTimelineDot />
          {!isLastItem && <TimelineConnector />}
        </TimelineSeparator>
        <StyledTimelineContent>
          <ContentContainer>
            <Skeleton width={150} height={14} />
            <DotSeparator />
            <Skeleton width={getRandRange(120, 200)} height={14} />
            <DotSeparator />
            <Skeleton width={120} height={14} />
          </ContentContainer>
        </StyledTimelineContent>
      </TimelineItem>
    );
  }
);
export const HistoryFetchingState: React.FC = () => {
  return (
    <StyledTimeline>
      <FetchContent />
      <FetchContent />
      <FetchContent />
      <FetchContent />
      <FetchContent />
      <FetchContent />
      <FetchContent isLastItem={true} />
    </StyledTimeline>
  );
};
