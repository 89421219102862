import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import Switcher from "../../components/common/controls/Switcher";

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  align-items: center;
  column-gap: 0.4rem;
`;

interface SelectedRowsOnlySwitchProps {
  checked: boolean;
  onToggle: () => void;
  selectedRowsNumber: number;
}

export const SelectedRowsOnlySwitch: React.FC<SelectedRowsOnlySwitchProps> = ({
  checked,
  onToggle,
  selectedRowsNumber,
}) => (
  <Grid>
    <Switcher
      checked={checked}
      handleSwitcherChange={onToggle}
      color={palette.darkBlue[500]}
    />
    <Typography>{`Show only selected rows (${selectedRowsNumber})`}</Typography>
  </Grid>
);
