import { RightSizingCostStatsResponse } from "../../../../../generated/metricsApi";

export const isCostComparisonDataInvalid = (
  data: RightSizingCostStatsResponse | undefined,
  error: string | null
) => {
  const currentCostPerMonth = data?.currentCostPerMonth ?? 0;
  const monthlySaving = data?.monthlySaving ?? 0;
  return error !== null || monthlySaving > currentCostPerMonth;
};
