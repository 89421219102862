import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacRolesIdGetUrl,
  RbacRole,
  RbacRolesApiApiV1RbacRolesIdGetRequest,
} from "../../../../../generated/auth";

const RBAC_ROLE_BY_ID_PATH = "/rbac-roles/:id";

const fetchRbacRoleById = async (
  apiClient: AxiosInstance,
  params: RbacRolesApiApiV1RbacRolesIdGetRequest
): Promise<RbacRole> => {
  const { data } = await apiClient.get(
    apiV1RbacRolesIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacRoleById = (
  params: RbacRolesApiApiV1RbacRolesIdGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_ROLE_BY_ID_PATH, params],
    () => fetchRbacRoleById(apiClient, params),
    {
      enabled,
    }
  );
};
