import EventGroup from "../../common/EventGroup";
import { ucFirstWord } from "../../../shared/utils/serviceHelpers";
import { KomodorServicesResponseDataInner } from "../../../generated/resourcesApi";

import selectDeployDetails from "./selectDeployDetails";
import selectAvailaiblityReasons, {
  selectStandalonePodReasons,
} from "./selectMonitorsReasons";

type Select = (
  service?: KomodorServicesResponseDataInner
) => string | undefined;
const yieldIfPresent = (select: Select) =>
  function* (_g: EventGroup, service?: KomodorServicesResponseDataInner) {
    const value = select(service);
    if (value) yield value;
  };

export enum IndependentFilter {
  EventTypes = "Event types",
  Statuses = "Statuses",
  DeployDetails = "Deploy details",
  AvailabilityReasons = "Availability reasons",
  StandalonePodReasons = "Workflow Pod reasons",
}
const eventsViewFiltersSelectors: {
  name: string;
  label: string;
  select(
    g: EventGroup,
    service?: KomodorServicesResponseDataInner
  ): Iterable<string>;
}[] = [
  {
    name: "Clusters",
    label: "cluster",
    select: yieldIfPresent((service) => service?.k8sClusterName),
  },
  {
    name: "Namespaces",
    label: "namespace",
    select: yieldIfPresent((service) => service?.namespace),
  },
  {
    name: "Services",
    label: "service",
    select: yieldIfPresent((service) => service?.displayName),
  },
  {
    name: IndependentFilter.EventTypes,
    label: "event type",
    *select(g: EventGroup): Iterable<string> {
      yield g.type;
    },
  },
  {
    name: IndependentFilter.Statuses,
    label: "status",
    *select(g: EventGroup): Iterable<string> {
      yield ucFirstWord(g.status);
    },
  },
  {
    name: IndependentFilter.DeployDetails,
    label: "deploy details",
    select: selectDeployDetails,
  },
  {
    name: IndependentFilter.AvailabilityReasons,
    label: "availability reasons",
    select: selectAvailaiblityReasons,
  },
  {
    name: IndependentFilter.StandalonePodReasons,
    label: "standalone pod reasons",
    select: selectStandalonePodReasons,
  },
];

export function* selectWithoutService(
  g: EventGroup,
  filterName: string
): Iterable<string> {
  const event = (g.events[0] ?? {}) as unknown as {
    clusterName: string;
    namespace: string;
  };
  const clusterNameProperty = "clusterName";
  const namespaceProperty = "namespace";
  if (filterName === "Clusters" && clusterNameProperty in event) {
    yield event.clusterName;
  } else if (
    filterName === "Namespaces" &&
    namespaceProperty in event &&
    !!event.namespace
  ) {
    yield event.namespace;
  }
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default eventsViewFiltersSelectors;
