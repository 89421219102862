import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacRolesApiApiV1RbacRolesIdPutRequest,
  RbacRole,
  apiV1RbacRolesIdPutUrl,
} from "../../../../../generated/auth";

const UPDATE_RBAC_ROLE_PATH = "/rbac-roles/:id";

const updateRbacRole = async (
  apiClient: AxiosInstance,
  params: RbacRolesApiApiV1RbacRolesIdPutRequest
): Promise<RbacRole> => {
  const { data } = await apiClient.put(
    apiV1RbacRolesIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.updateRbacRoleRequest
  );
  return data;
};

export const useUpdateRbacRole = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [UPDATE_RBAC_ROLE_PATH],
    (params: RbacRolesApiApiV1RbacRolesIdPutRequest) =>
      updateRbacRole(apiClient, params)
  );
};
