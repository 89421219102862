import { useCallback, useEffect, useState } from "react";

const TRANSITION_DURATION = 250;

type DrawerStackTransitionHandlerProps = {
  open: boolean;
  onClose: () => void;
};

export const useDrawerStackTransitionHandler = ({
  onClose,
  open,
}: DrawerStackTransitionHandlerProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDrawerIsOpen(open);
    }, 1);
  }, [open]);

  const internalOnClose = useCallback(() => {
    setDrawerIsOpen(false);
    const timeout = setTimeout(() => {
      onClose();
    }, TRANSITION_DURATION);
    return () => clearTimeout(timeout);
  }, [onClose]);

  return {
    drawerIsOpen,
    internalOnClose,
  };
};
