import React, { useMemo } from "react";
import styled from "styled-components";

import { InsightCard } from "../../shared/components/InsightCard";
import { insightCardTitles } from "../../insightsConstants";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import {
  useMemoizedDataBreakdownPerInterval,
  useOpenInsightsDrawer,
} from "../../shared/hooks/appInisghtsHooks";
import { AvailabilityIssuesResponse } from "../../../../../../../../../generated/applicationInsights";

import { AvailabilityIssuesDrawer } from "./AvailabilityIssuesDrawer";
import { useSplitToGraphData } from "./hooks/useSplitToGraphData";
import { getIssuesNumDetected } from "./AvailabilityIssuesTexts";

const Container = styled.div`
  height: 55px;
  width: 234px;
`;

const shouldDisplayAvailabilityIssues = (
  firstTotal?: number,
  secondTotal?: number,
  data?: AvailabilityIssuesResponse | undefined
) => {
  return firstTotal !== secondTotal || (data && data.sumByDay.length > 1);
};

export const ID = "Availability Issues";

export const AvailabilityIssues: React.FC = () => {
  const {
    availabilityIssues: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const openInsightsDrawer = useOpenInsightsDrawer();

  const dataPointsBreakdown = useMemoizedDataBreakdownPerInterval(
    data?.sumByDay
  );
  const {
    firstTotal = 0,
    secondTotal = 0,
    secondIntervalDuration = "0",
    trendPercentage = "0",
    isIncreased,
  } = dataPointsBreakdown ?? {};

  const InsightDescription = useMemo(() => {
    if (!data) return "";
    if (trendPercentage === "0") {
      return getIssuesNumDetected(secondIntervalDuration, secondTotal);
    }
    return `Issues ${
      isIncreased ? "increased" : "decreased"
    } by ${trendPercentage}%`;
  }, [data, isIncreased, secondIntervalDuration, secondTotal, trendPercentage]);

  const graph = useSplitToGraphData(false);

  const { title, icon } = insightCardTitles.availabilityIssues;

  if (!loading && !shouldDisplayAvailabilityIssues(firstTotal, secondTotal)) {
    return null;
  }

  const onCardClick = () => {
    openInsightsDrawer({
      title: InsightDescription,
      component: (
        <AvailabilityIssuesDrawer dataPointsBreakdown={dataPointsBreakdown} />
      ),
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={InsightDescription}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"oneDescriptionLineAndGraph"}
    >
      <Container>{graph}</Container>
    </InsightCard>
  );
};
