import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { CollapsibleHeaderActionButtonProps } from "../checkboxOptionsHandlerTypes";

const InvisibleButton = styled(Button)`
  && {
    opacity: 0;
    pointer-events: none;
  }
`;

const StyledButton = styled(Button)`
  && {
    white-space: nowrap;
  }
`;

export const CollapsibleHeaderActionButton: React.FC<
  CollapsibleHeaderActionButtonProps
> = ({
  hasChangedOptions,
  filteredOptionsNum,
  onSelectAll,
  onReset,
  searchTerm,
  isCollapseOpen,
}) => {
  if (searchTerm && filteredOptionsNum > 0 && isCollapseOpen) {
    return (
      <StyledButton variant={"text"} onClick={onSelectAll}>
        {`Select (${filteredOptionsNum})`}
      </StyledButton>
    );
  }

  if (hasChangedOptions) {
    return (
      <StyledButton variant={"text"} onClick={onReset}>
        Reset
      </StyledButton>
    );
  }

  return <InvisibleButton variant={"text"}>placeholder</InvisibleButton>;
};
