import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import { convertWorkspaceToTypedWorkspace } from "../utils";

import { WORKSPACES_PATH } from "./useGetWorkspaces";
import { WORKSPACE_BY_ID_PATH } from "./useGetWorkspaceById";

import {
  Workspace,
  WorkspacesApiApiV1WorkspacesIdPutRequest,
  apiV1WorkspacesIdPutUrl,
} from "@/generated/workspacesApi";

export const EDIT_WORKSPACE_PATH = "/workspaces/:id";

const editWorkspace = async (
  apiClient: AxiosInstance,
  variables: WorkspacesApiApiV1WorkspacesIdPutRequest
): Promise<Workspace> => {
  const { data } = await apiClient.put<Workspace>(
    apiV1WorkspacesIdPutUrl(variables, apiClient.defaults.baseURL ?? ""),
    variables.workspace
  );

  return data;
};

export const useEditWorkspace = () => {
  const apiClient = useWorkspacesApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: Workspace) => {
      const workspace = await editWorkspace(apiClient, {
        id: variables.id,
        workspace: variables,
      });
      return convertWorkspaceToTypedWorkspace(workspace);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([WORKSPACES_PATH]);
      queryClient.invalidateQueries([
        WORKSPACE_BY_ID_PATH,
        { id: variables.id },
      ]);
    },
  });
};
