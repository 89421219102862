import React, { useEffect, useState } from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Divider } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodPhaseGroup from "../../../EventGroup/././groupedPodEvent/PodPhaseGroup";
import { EventDetailsContainer } from "../common";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import {
  AnalyticEvents,
  dispatchEvent,
} from "../../../../../shared/hooks/analytics";
import { useDDRumStartViewAndAddTiming } from "../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import EventDrawer from "../../EventDrawer";
import EventGroup from "../../../EventGroup";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

import { PodWhereSection } from "./PodWhereSection";
import { HeaderSection } from "./HeaderSection";
import { LatestConditions } from "./LatestConditions";
import { PodPhaseSection } from "./PodPhaseSection";
import { NodeIssuesSection } from "./NodeIssuesSection";

import { useGetPodResource } from "@/components/common/ProcessList/details/PodPhaseDetails/useGetPodResource";

interface PodPhaseDetailsProps {
  eventGroup: PodPhaseGroup;
}

export const PodGroupDetails: React.FC<PodPhaseDetailsProps> = ({
  eventGroup,
}) => {
  const [showNodeIssueDrawerGroup, setShowNodeIssueDrawerGroup] = useState<
    EventGroup | undefined
  >(undefined);

  const podResource = useGetPodResource({
    clusterName: eventGroup.clusterName,
    namespace: eventGroup.namespace,
    podName: eventGroup.podName,
    podStatus: eventGroup.status,
    fromEpoch: eventGroup.startTime.getTime().toString(),
  });

  const queryParams = queryString.parse(useLocation().search);

  useEffect(() => {
    if (eventGroup.startTime && eventGroup.status) {
      dispatchEvent(AnalyticEvents.PodPhases.PodPhase_clicked, {
        type: "Pod phase",
        referer: queryParams?.referer,
        eventTime: eventGroup.startTime,
        phase: eventGroup.status,
        secondsTillOpen: differenceInSeconds(new Date(), eventGroup.startTime),
      });
    }
  }, [eventGroup.startTime, eventGroup.status, queryParams?.referer]);

  const [isFetchingEvents, setIsFetchingEvents] = useState(false);

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.eventDrawerPodGroup,
    addTimingParams: {
      enable: !isFetchingEvents,
    },
  });

  return (
    <EventDetailsContainer aria-label={AriaLabels.PodPhases.Drawer}>
      <HeaderSection eventGroup={eventGroup} />
      <Divider />
      <NodeIssuesSection
        podPhaseGroup={eventGroup}
        nodeName={
          (podResource?.fullObj.spec?.nodeName as string | undefined) ?? ""
        }
        onResourceClick={(eg) => setShowNodeIssueDrawerGroup(eg)}
      />
      <PodWhereSection podResource={podResource} />
      <EventDrawer
        open={!!showNodeIssueDrawerGroup}
        event={showNodeIssueDrawerGroup}
        onClose={() => {
          setShowNodeIssueDrawerGroup(undefined);
        }}
      >
        {showNodeIssueDrawerGroup?.renderEventDetails?.(() => {
          setShowNodeIssueDrawerGroup(undefined);
        })}
      </EventDrawer>
      {!!eventGroup.statusConditions.length && (
        <>
          <Divider />
          <LatestConditions eventGroup={eventGroup} />
        </>
      )}
      <Divider />
      <PodPhaseSection
        eventGroup={eventGroup}
        setIsFetchingEvents={setIsFetchingEvents}
      />
    </EventDetailsContainer>
  );
};
