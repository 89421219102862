import { useMemo } from "react";
import pluralize from "pluralize";

import { ALL_TAB_VALUE } from "../../context/IssuesTableContext";
import { getNameFromCheckType } from "../../realtimeUtils";
import { useIssuesTableContext } from "../../context/useIssuesTableContext";

import { getTotalViolationsFromCountDict } from "@/components/reliability/utils/reliabilityUtils";
import { CheckType } from "@/generated/reliabilityApi";

const ALL_TAB_LABEL = "All Issues";

type TabProps = {
  label: string;
  value: string;
  ariaLabel: string;
};

export const checkTypesTabOrder: CheckType[] = [
  CheckType.UnhealthyService,
  CheckType.UnhealthyWorkflow,
  CheckType.FailedJob,
  CheckType.UnhealthyNode,
  CheckType.UnhealthyPvc,
];

export const getCheckTypeTabOrder = (checkType: CheckType): number => {
  return checkTypesTabOrder.indexOf(checkType) ?? 0;
};

export const useIssuesTabsProps = () => {
  const { issuesBreakdown } = useIssuesTableContext();

  return useMemo<TabProps[]>(() => {
    const tabs: TabProps[] = [
      {
        label: `${ALL_TAB_LABEL} (${getTotalViolationsFromCountDict(
          issuesBreakdown ?? {}
        )})`,
        value: ALL_TAB_VALUE,
        ariaLabel: `${ALL_TAB_VALUE} tab`,
      },
    ];

    const sortedKeys = Object.keys(issuesBreakdown ?? {}).sort((a, b) => {
      return (
        getCheckTypeTabOrder(a as CheckType) -
        getCheckTypeTabOrder(b as CheckType)
      );
    });

    sortedKeys.forEach((key) => {
      const value = (issuesBreakdown ?? {})[key] ?? 0;
      const name = getNameFromCheckType(key as CheckType);

      if (!name || value === 0) return;
      tabs.push({
        label: `${pluralize(name, value)} (${value})`,
        value: key,
        ariaLabel: `${name} tab`,
      });
    });

    return tabs;
  }, [issuesBreakdown]);
};
