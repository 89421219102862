import { useState, useEffect, Dispatch } from "react";

const getSessionStorageOrDefault = (key: string, defaultValue: unknown) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

export const useSessionStorage: <T>(
  key: string,
  defaultValue: T
) => [T, Dispatch<T>] = (key, defaultValue) => {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const sessionStorageRemoveKeysByRegex = (regex: RegExp): void => {
  for (const key of Object.keys(sessionStorage)) {
    if (key.match(regex)) {
      sessionStorage.removeItem(key);
    }
  }
};
