import React, { useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner } from "../../../../../../../../../../../../generated/reliabilityApi";

import { EditYamlColumnHandler } from "./EditYamlColumnHandler";

export const useGetColumns = (
  clusterName: string | undefined,
  kind: string | undefined
) => {
  return useMemo(
    () => [
      { field: "name", headerName: "Resource name", flex: 1 },
      { field: "namespace", headerName: "Namespace", flex: 1 },
      {
        field: "",
        headerName: "",
        renderCell: (
          params: GridRenderCellParams<APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner>
        ) => (
          <EditYamlColumnHandler
            clusterName={clusterName}
            namespace={params.row.namespace}
            name={params.row.name}
            kind={kind}
          />
        ),
        sortable: false,
        filterable: false,
      },
    ],
    [clusterName, kind]
  );
};
