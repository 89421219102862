import React from "react";
import styled from "styled-components";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

import { lightBlue } from "../../../../../../Colors";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import {
  NormalText,
  NoUnderlinedLink,
  StyledFontTable,
  TableContainer,
} from "../../../../common/styles";
import Recommendations from "../../../../common/Recommendations";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ResourceConsumptionCheck from "../ResourceConsumptionCheck";
import cpuIcon from "../../../../assets/cpu.svg";
import memoryIcon from "../../../../assets/memory.svg";
import { Row } from "../../pvc/detailsComponents/PVCCreationDetails";
import HiddenPodsInfo from "../../../../common/HiddenPodsInfo";

const ResourceTitle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-block-start: 0.75rem;
`;

const Resource = styled.div<{ icon: string }>`
  padding-left: 1.5rem;

  font-size: 14px;
  font-weight: bold;

  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: left center;
`;

const ResourceMetricBar = styled.div`
  height: 2rem;
  background-color: ${lightBlue};
  border-radius: 4px;
  margin-block-start: 0.3rem;
`;

const ResourceMetricVal = styled.div<{ percents: string }>`
  width: ${({ percents }) => percents};
  background-color: ${lightBlue};
  mix-blend-mode: multiply;
  border-radius: 4px 0 0 4px;

  line-height: 2rem;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
`;

const CONSUMPTION_RECOMMENDATIONS = [
  "Adding more nodes",
  "Replace node with more memory",
  "Tune pod",
];

const ResourceConsumptionDetails: React.FC<{
  check: ResourceConsumptionCheck;
}> = ({ check }) => {
  const { serviceId } = useParams<{ serviceId: string }>();

  if (!check.output) {
    return null;
  }

  const {
    nodeMetrics: {
      memory: nodeMemoryUsed,
      cpu: nodeCpuUsed,
      allocatableMemory,
      allocatableCpu,
    },
    podsMetrics: { pods, hiddenPods },
  } = check.output;

  return (
    <>
      <br />
      <ResourceTitle>
        <Resource icon={cpuIcon}>CPU</Resource>
        <NormalText>{`${nodeCpuUsed.value}${nodeCpuUsed.unit} / ${allocatableCpu.value}${allocatableCpu.unit}`}</NormalText>
      </ResourceTitle>
      <ResourceMetricBar>
        <ResourceMetricVal percents={nodeCpuUsed.percentage}>
          {`(${nodeCpuUsed.percentage})`}
        </ResourceMetricVal>
      </ResourceMetricBar>
      <ResourceTitle>
        <Resource icon={memoryIcon}>Memory</Resource>
        <NormalText>{`${nodeMemoryUsed.value}${nodeMemoryUsed.unit} / ${allocatableMemory.value}${allocatableMemory.unit}`}</NormalText>
      </ResourceTitle>
      <ResourceMetricBar>
        <ResourceMetricVal percents={nodeMemoryUsed.percentage}>
          {`(${nodeMemoryUsed.percentage})`}
        </ResourceMetricVal>
      </ResourceMetricBar>
      <br />
      {pods.length > 0 && (
        <>
          <BoldGrayText>Top Consuming Pods</BoldGrayText>
          <TableContainer>
            <StyledFontTable>
              <thead>
                <tr>
                  <th>Pod name</th>
                  <th>CPU (cores)</th>
                  <th>Memory (bytes)</th>
                </tr>
              </thead>
              <tbody>
                {orderBy(
                  pods,
                  (p) =>
                    serviceId && p.serviceId
                      ? [p.serviceId === serviceId, p.name]
                      : p.name,
                  ["desc"]
                ).map((podMetric) => (
                  <Row>
                    <td>
                      <>
                        {podMetric.name}
                        {podMetric.serviceId && (
                          <>
                            {" "}
                            (
                            <NoUnderlinedLink
                              to={`/main/deep-dive/${podMetric.serviceId}`}
                            >
                              View Service
                            </NoUnderlinedLink>
                            )
                          </>
                        )}
                      </>
                    </td>
                    <td>{`${podMetric.totalCpu.value}${podMetric.totalCpu.unit}/${podMetric.totalCpu.percentage}`}</td>
                    <td>{`${podMetric.totalMemory.value}${podMetric.totalMemory.unit}/${podMetric.totalMemory.percentage}`}</td>
                  </Row>
                ))}
              </tbody>
            </StyledFontTable>
          </TableContainer>
        </>
      )}
      <HiddenPodsInfo hiddenPodsCount={hiddenPods} />
      <br />
      {!check.passed && (
        <>
          <Recommendations list={CONSUMPTION_RECOMMENDATIONS} />
        </>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourceConsumptionDetails;
