import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { subMilliseconds } from "date-fns";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";

import {
  resetOverviewPageStore,
  useOverviewPageStore,
} from "../store/overviewPageStore";
import {
  selectInsightsDrawer,
  selectMetricsSupportAgentInfo,
  selectSetMetricsSupportAgentInfo,
  selectSetTimeWindowIntervals,
  selectSetUiElementsRect,
} from "../store/overviewPageStoreSelectors";
import {
  MetricsSupportAgentInfo,
  TimeWindow,
} from "../types/overviewPageTypes";
import { timeWindowToDate } from "../utils/overviewPageUtils";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useWindowResize } from "../../../../../../../shared/hooks/useWindowResize";
import { useResizeObserver } from "../../../../../../../shared/hooks/useResizeObserver";
import { INSIGHTS_DRAWER_TRANSITION_DURATION } from "../Insights/insightsConstants";
import { getStartOfDayInUtc } from "../../../../../../../shared/utils/dateUtils";
import { useClusterOptionsByAppView } from "../../../../../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { doesAgentVersionSupportMetrics } from "../../../../../../../shared/utils/agent/doesAgentVersionSupportMetrics";
import { AppViewUrlParam } from "../../../../../types/urlParams";
import { isDevMode } from "../../../../../../../shared/utils/isDevMode";

export const useResetStoreOnAppViewId = () => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const setTimeWindowIntervals = useSetTimeWindowIntervals();
  useLayoutEffect(() => {
    resetOverviewPageStore();
    setTimeout(() => {
      // workaround for useAPIInternal() not clearing data on time window change
      // need to investigate further
      setTimeWindowIntervals();
    }, 100);
  }, [selectedAppViewId, setTimeWindowIntervals]);

  useEffect(() => {
    return () => {
      resetOverviewPageStore();
    };
  }, [selectedAppViewId]);
};

export const useSetAppViewFromUrlParams = () => {
  const { id } = useParams<AppViewUrlParam>();
  const initialLoad = useRef(false);
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);

  useEffect(() => {
    if (!initialLoad.current && id && !selectedAppViewId) {
      setSelectedAppViewId(id);
    }
    initialLoad.current = true;
  }, [id, selectedAppViewId, setSelectedAppViewId]);
};

const useSetTimeWindowIntervals = () => {
  const setTimeWindowIntervals = useOverviewPageStore(
    selectSetTimeWindowIntervals
  );

  return useCallback(() => {
    const now = new Date();

    const startOfDayInUtc = getStartOfDayInUtc(now.getTime());
    const firstIntervalEnd = timeWindowToDate("7d", startOfDayInUtc);

    const firstInterval: TimeWindow = {
      fromEpoch: timeWindowToDate("14d", startOfDayInUtc).getTime(),
      toEpoch: subMilliseconds(firstIntervalEnd, 1).getTime(),
    };
    const secondInterval: TimeWindow = {
      fromEpoch: firstIntervalEnd.getTime(),
      toEpoch: subMilliseconds(startOfDayInUtc, 1).getTime(),
    };

    setTimeWindowIntervals([firstInterval, secondInterval]);
  }, [setTimeWindowIntervals]);
};

type UpdateUiElementsRectParams = {
  pageRef: RefObject<HTMLDivElement>;
  overviewRef: RefObject<HTMLDivElement>;
  insightsRef: RefObject<HTMLDivElement>;
};

export const useUpdateUiElementsRect = ({
  pageRef,
  overviewRef,
  insightsRef,
}: UpdateUiElementsRectParams) => {
  const setUiElementsRect = useOverviewPageStore(selectSetUiElementsRect);
  const { insightsDrawerContent } = useOverviewPageStore(selectInsightsDrawer);

  const updateUiElements = useCallback(() => {
    const overviewRect = overviewRef.current?.getBoundingClientRect();
    const insightsRect = insightsRef.current?.getBoundingClientRect();
    const pageRect = pageRef.current?.getBoundingClientRect();
    if (!overviewRect?.width || !pageRect?.width) return;
    setUiElementsRect({
      overviewContainer: overviewRect,
      pageContainer: pageRect,
      insightsContainer: insightsRect,
    });
  }, [insightsRef, overviewRef, pageRef, setUiElementsRect]);

  const { callback: debouncedUpdateUiElements } = useDebouncedCallback(
    updateUiElements,
    INSIGHTS_DRAWER_TRANSITION_DURATION,
    { trailing: true }
  );

  // update component rectangles on window resize,
  // on overview content change
  // and every time the insights drawer closes

  useWindowResize(debouncedUpdateUiElements);
  useResizeObserver(overviewRef, debouncedUpdateUiElements);
  const hasDivRefs =
    pageRef.current && overviewRef.current && insightsRef.current;

  useEffect(() => {
    if (hasDivRefs && !insightsDrawerContent) {
      debouncedUpdateUiElements();
    }
  }, [debouncedUpdateUiElements, insightsDrawerContent, hasDivRefs]);
};

type SupportedClusterInfo = Pick<
  MetricsSupportAgentInfo,
  "supportedClusters" | "unsupportedClusters"
>;

export const useSupportedAgentVersionsForMetrics = () => {
  const {
    unsupportedClusters: currentUnsupportedClusters,
    supportedClusters: currentSupportedClusters,
  } = useOverviewPageStore(selectMetricsSupportAgentInfo);
  const setMetricsSupportAgentInfo = useOverviewPageStore(
    selectSetMetricsSupportAgentInfo
  );

  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const { supportedClusters, unsupportedClusters } = useMemo(
    () =>
      clusterOptions.reduce<SupportedClusterInfo>(
        (acc, curr) => {
          if (!doesAgentVersionSupportMetrics(curr)) {
            acc.unsupportedClusters.push(curr.clusterName);
          } else {
            acc.supportedClusters.push(curr.clusterName);
          }
          return acc;
        },
        { unsupportedClusters: [], supportedClusters: [] }
      ),
    [clusterOptions]
  );

  useEffect(() => {
    if (
      !isEqual(currentUnsupportedClusters, unsupportedClusters) ||
      !isEqual(currentSupportedClusters, supportedClusters)
    ) {
      setMetricsSupportAgentInfo({ unsupportedClusters, supportedClusters });
    }
  }, [
    currentSupportedClusters,
    currentUnsupportedClusters,
    setMetricsSupportAgentInfo,
    supportedClusters,
    unsupportedClusters,
  ]);
};

export const useHasFetchedMetricsClusterOptions = () => {
  const { unsupportedClusters, supportedClusters } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  return useMemo(() => {
    return unsupportedClusters.length > 0 || supportedClusters.length > 0;
  }, [supportedClusters.length, unsupportedClusters.length]);
};

export const useHasMetricsSupportedClusters = () => {
  const { supportedClusters } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  return supportedClusters.length > 0;
};

export const useHasFetchedMetricsDataAccuracy = () => {
  const { hasFetchedDataAccuracy } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  return hasFetchedDataAccuracy;
};

export const useHasSufficientMetricsDataAccuracy = () => {
  const { hasSufficientDataCollectionTime } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  return hasSufficientDataCollectionTime;
};

export const useHasMinimalMetricsDataAccuracy = () => {
  const { hasMinimalDataAccuracy } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );

  return hasMinimalDataAccuracy;
};

export const useHasFetchedMetricsClusterData = () => {
  const hasFetchedClusterOptions = useHasFetchedMetricsClusterOptions();
  const hasFetchedMetricsDataAccuracy = useHasFetchedMetricsDataAccuracy();
  return hasFetchedClusterOptions && hasFetchedMetricsDataAccuracy;
};

export const useHasSufficientMetricsData = () => {
  const hasMinimalMetricsDataAccuracy = useHasMinimalMetricsDataAccuracy();
  const hasMetricsSupportedClusters = useHasMetricsSupportedClusters();
  return hasMetricsSupportedClusters && hasMinimalMetricsDataAccuracy;
};
