import React from "react";
import { Alert } from "@komodorio/design-system/deprecated";

const HiddenPodsInfo: React.FC<{
  hiddenPodsCount?: number;
}> = ({ hiddenPodsCount }) => {
  if (!hiddenPodsCount || hiddenPodsCount === 0) {
    return null;
  }
  return (
    <>
      <Alert variant="info" style={{ width: "28rem" }}>
        {hiddenPodsCount} pods were hidden due to insufficient permissions
      </Alert>
      <br />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default HiddenPodsInfo;
