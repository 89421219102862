import { Select, SelectProps } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div<{ $fillWidth: boolean }>`
  ${({ $fillWidth }) => ($fillWidth ? `width: 100%;` : ``)};
  & > div {
    width: 100%;
  }
`;

export const StyledSelect: React.FC<SelectProps<string>> = ({
  options,
  width,
  ...props
}) => {
  if (!options.length) return null;

  return (
    <StyledContainer $fillWidth={!width}>
      <Select options={options} width={width} {...props} />
    </StyledContainer>
  );
};
