import React, { ReactElement } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { CollapsibleContainer } from "../../../../../../../../../../../shared/components/CollapsibleContainer/CollapsibleContainer";

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export interface CollapsibleContainerProps {
  containerName: string;
  content: ReactElement;
  button?: ReactElement;
  initialOpen?: boolean;
  ariaLabel?: string;
}
export const ReliabilityCollapsibleContainer: React.FC<
  CollapsibleContainerProps
> = ({ containerName, content, button, initialOpen = false, ariaLabel }) => {
  return (
    <CollapsibleContainer
      title={
        <Header>
          <Typography variant="body1" color="text.primary">
            Container:
          </Typography>
          <Typography variant="h4" color="text.primary">
            {containerName}
          </Typography>
        </Header>
      }
      actionSection={button}
      content={content}
      initialOpen={initialOpen}
      ariaLabel={ariaLabel}
    />
  );
};
