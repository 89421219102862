import React, { createContext, useContext, useState } from "react";

import { PodLogsInfoResponseProps } from "../../../shared/hooks/podLogs/types";

interface PodsLogsEarlyFetchProps {
  podsLogsInfoResponse: PodLogsInfoResponseProps;
  key: string;
}

const PodsLogsEarlyFetchContext = createContext<
  PodsLogsEarlyFetchProps | undefined
>(undefined);

const PodsLogsEarlyFetchUpdateContext = createContext<
  | React.Dispatch<React.SetStateAction<PodsLogsEarlyFetchProps | undefined>>
  | undefined
>(undefined);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const usePodsLogsEarlyFetch = ():
  | PodsLogsEarlyFetchProps
  | undefined => {
  return useContext(PodsLogsEarlyFetchContext);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const usePodsLogsEarlyFetchUpdate = ():
  | React.Dispatch<React.SetStateAction<PodsLogsEarlyFetchProps | undefined>>
  | undefined => {
  return useContext(PodsLogsEarlyFetchUpdateContext);
};

export const PodsLogsEarlyFetchProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [podsLogsEarlyFetchData, setPodsLogsEarlyFetchData] = useState<
    PodsLogsEarlyFetchProps | undefined
  >();

  return (
    <PodsLogsEarlyFetchContext.Provider value={podsLogsEarlyFetchData}>
      <PodsLogsEarlyFetchUpdateContext.Provider
        value={setPodsLogsEarlyFetchData}
      >
        {children}
      </PodsLogsEarlyFetchUpdateContext.Provider>
    </PodsLogsEarlyFetchContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getUniqKey = (
  cluster: string,
  namespace: string,
  name: string
): string => `${cluster}-${namespace}-${name}`;
