import { EnrichedMessage } from "../types";
import { StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";

export class ReadinessProbeFailedEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`Readiness probe failed.*`, "i"));
  }

  searchKey(): string {
    return "Readiness probe failed";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [new StringToken("Readiness probe failed.")],
    };
  }
}

export class LivenessProbeFailedEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`Liveness probe failed.*`, "i"));
  }

  searchKey(): string {
    return "Liveness probe failed";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [new StringToken("Liveness probe failed.")],
    };
  }
}

export class StartupProbeFailedEnricher extends BaseEnricher {
  constructor() {
    super(new RegExp(`Startup probe failed.*`, "i"));
  }

  searchKey(): string {
    return "Startup probe failed";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }

    return {
      originalMessage: errorMessage,
      messageTokens: [new StringToken("Startup probe failed.")],
    };
  }
}
