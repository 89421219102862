import React, { useMemo } from "react";
import { formatDistanceStrict } from "date-fns";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { blackGreyText, gray10 } from "../../../../../../../../Colors";
import { getCustomDateLocale } from "../../../../../../../../shared/utils/dateUtils";
import EventGroup from "../../../../../../../common/EventGroup";
import { useOverviewPageContext } from "../../context/overviewPageContext";

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 10px 0;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const TitleAndSummary = styled.div`
  flex-grow: 1;
  max-width: 184px;

  > div {
    white-space: nowrap;
    max-width: 13rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DateContainer = styled(Typography)`
  margin-left: auto;
`;

const ClickCaptureArea = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

type EventItemProps = {
  event: EventGroup;
  additionalRows?: string[];
};
export const EventItem: React.FC<EventItemProps> = ({
  event,
  additionalRows,
}: EventItemProps) => {
  const {
    eventItemDrawer: { openEventItemDrawer },
  } = useOverviewPageContext();
  const { status, summary, startTime, icon, fillColor, type } = event;
  const eventTitle = type === "Deploy" ? `${type} ${status}` : type;

  const formattedDate = useMemo(() => {
    return formatDistanceStrict(startTime, new Date(), {
      addSuffix: true,
      locale: getCustomDateLocale(),
    });
  }, [startTime]);

  const additionalText = additionalRows?.map((row) => (
    <Typography variant={"text"} size={"small"} color={gray10} key={row}>
      {row}
    </Typography>
  ));

  const Icon = icon;

  return (
    <Container>
      <IconContainer>
        <Icon fill={fillColor} />
      </IconContainer>
      <TitleAndSummary>
        <Typography variant={"title"} size="small" color={blackGreyText}>
          {eventTitle}
        </Typography>
        {additionalText}
        <Typography variant={"text"} size={"small"} color={gray10}>
          {summary}
        </Typography>
      </TitleAndSummary>
      <DateContainer variant={"text"} size={"small"} color={gray10}>
        {formattedDate}
      </DateContainer>
      <ClickCaptureArea
        onClick={() => openEventItemDrawer(event)}
        data-testid={"event-item-click-area"}
      />
    </Container>
  );
};
