import React, { useCallback } from "react";

import { GroupedViolations } from "../GroupedViolations";

import {
  ImpactGroupType,
  ViolationsAggregationGroup,
} from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { realTimeIssuesGroup } from "@/components/reliability/constants/dataDogReporting";
import { IssuesGroup } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesGroup/IssuesGroup";

const {
  realTimeGroups: { mainContainer: realTimeGroupsAriaLabel },
} = reliabilityArialLabels;

export const RealtimeGroups: React.FC = () => {
  const groupMapper = useCallback(
    (group: ViolationsAggregationGroup, idx: number) => (
      <IssuesGroup key={`${group.id}-${idx}`} group={group} />
    ),
    []
  );
  return (
    <GroupedViolations
      overrideImpactGroupType={{
        groupType: ImpactGroupType.Realtime,
        groupMapper,
        viewName: realTimeIssuesGroup,
        ariaLabel: realTimeGroupsAriaLabel,
      }}
    />
  );
};
