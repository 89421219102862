import React from "react";
import { WorkloadMonitor16 } from "@komodorio/design-system/icons";

import { clustersDemoRoutes } from "../../ClustersDemoViewV3/constants";
import { DEMO } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

export const WorkloadHealthDemoLink: React.FC = () => {
  return (
    <AppBarLink
      text="Workload Health"
      route={`${DEMO}/v3/${clustersDemoRoutes.workloadHealth}`}
      icon={<WorkloadMonitor16 />}
      end={true}
    />
  );
};
