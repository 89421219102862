import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { Dictionary } from "lodash";
import { useMemo } from "react";

import { ServiceInfo } from "@/shared/types/ServiceInfo";
const MAX_COMPARED_SERVICES = 10;
export const useComparedServicesOptions = (
  comparedServicesIds: MuiSelectionOption<string>[] | undefined,
  services: Dictionary<ServiceInfo>,
  baselineServiceId: MuiSelectionOption<string> | undefined
): MuiSelectionOption<string>[] => {
  return useMemo(
    () =>
      Object.values(services)
        .filter((service) => service.id !== baselineServiceId?.value)
        .sort((a, b) => {
          if (comparedServicesIds?.some((service) => service.value === a.id)) {
            return -1;
          }
          if (comparedServicesIds?.some((service) => service.value === b.id)) {
            return 1;
          }
          return 0;
        })
        .map((serviceInfo) => {
          return {
            label: `${serviceInfo.k8sCluster} / ${serviceInfo.env} / ${serviceInfo.title}`,
            value: serviceInfo.id,
            disabled:
              comparedServicesIds &&
              comparedServicesIds?.length >= MAX_COMPARED_SERVICES &&
              !comparedServicesIds.some(
                (service) => service.value === serviceInfo.id
              ),
          };
        }),
    [baselineServiceId?.value, comparedServicesIds, services]
  );
};

const getLabelFromService = (service: ServiceInfo): string => {
  return `${service.k8sCluster} / ${service.env} / ${service.title}`;
};

export const useBaselineServiceOptions = (
  baselineServiceId: MuiSelectionOption<string> | undefined,
  services: Dictionary<ServiceInfo>
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return Object.values(services)
      .sort((a, b) => {
        if (a.id === baselineServiceId?.value) {
          return -1;
        }
        if (b.id === baselineServiceId?.value) {
          return 1;
        }
        return 0;
      })
      .map((serviceInfo) => {
        return {
          label: getLabelFromService(serviceInfo),
          value: serviceInfo.id,
        };
      });
  }, [baselineServiceId?.value, services]);
};
