import React, { useMemo } from "react";

import { InsightCard } from "../../shared/components/InsightCard";
import { insightCardTitles } from "../../insightsConstants";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { ThrottledAppContainersResponse } from "../../../../../../../../../generated/metricsApi";
import { useOpenInsightsDrawer } from "../../shared/hooks/appInisghtsHooks";
import { useOverridableFlags } from "../../../../../../../../../shared/context/featureFlags/OverridableFlags";
import { useHasSufficientMetricsData } from "../../../hooks/overviewPageHooks";

import { getCardTitleDescription } from "./ThrottledApplicationsTexts";
import { ThrottledApplicationsDrawerContent } from "./ThrottledApplicationsDrawerContent";

export const ID = "Throttled Applications";

const useShouldDisplayThrottledApplications = (
  data?: ThrottledAppContainersResponse
) => {
  const { showThrottledAppViewContainersInsight } = useOverridableFlags();
  return (
    showThrottledAppViewContainersInsight &&
    data &&
    data.affectedContainers?.length > 0
  );
};

export const ThrottledApplications: React.FC = () => {
  const {
    throttledApplications: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const openInsightsDrawer = useOpenInsightsDrawer();
  const hasSufficientMetricsData = useHasSufficientMetricsData();

  const description = useMemo(
    () => getCardTitleDescription(data?.affectedContainers?.length),
    [data]
  );

  const shouldDisplayThrottledApplications =
    useShouldDisplayThrottledApplications(data);

  if (
    !hasSufficientMetricsData ||
    (!loading && !shouldDisplayThrottledApplications)
  )
    return null;

  const { title, icon } = insightCardTitles.throttledApplications;

  const onCardClick = () => {
    openInsightsDrawer({
      title: description,
      component: <ThrottledApplicationsDrawerContent />,
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={description}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"oneDescriptionLine"}
    />
  );
};
