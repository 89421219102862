import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material/Alert/Alert";

import { useReliabilityStore } from "../../../store/reliabilityStore";
import {
  selectReliabilityNotifications,
  selectRemoveReliabilityNotification,
} from "../../../store/reliabilityStoreSelectors";

import { ReliabilityNotification } from "@/components/reliability/ReliabilityTypes";

const AUTO_HIDE_DURATION = 5000;
const HIDE_SNACKBAR_DURATION = 250;

const StyledAlert = styled(Alert)<{ severity?: AlertColor }>`
  && {
    display: flex;
    align-items: center;
    ${({ severity }) => {
      return severity === "error" && `background: ${muiColors.pink[500]}`;
    }}
  
`;

export const ReliabilityNotificationsHandler: React.FC = () => {
  const notifications = useReliabilityStore(selectReliabilityNotifications);
  const removeNotification = useReliabilityStore(
    selectRemoveReliabilityNotification
  );
  const [isOpen, setIsOpen] = useState(false);

  const [currentNotification, setCurrentNotification] = useState<
    ReliabilityNotification | undefined
  >();

  const closeTimeout = useRef<NodeJS.Timeout | undefined>();

  const onClose = useCallback(
    (notificationToDelete?: ReliabilityNotification) => {
      const notification = notificationToDelete || currentNotification;
      if (notification) {
        setIsOpen(false);
        setTimeout(() => {
          setCurrentNotification(undefined);
          removeNotification(notification);
        }, HIDE_SNACKBAR_DURATION);
      }
    },
    [currentNotification, removeNotification]
  );

  useEffect(() => {
    if (
      notifications.length > 0 &&
      notifications[0].message !== currentNotification?.message
    ) {
      clearTimeout(closeTimeout.current);
      setCurrentNotification(notifications[0]);
      setIsOpen(true);
      closeTimeout.current = setTimeout(() => {
        onClose(notifications[0]);
      }, AUTO_HIDE_DURATION);
    }
  }, [currentNotification?.message, notifications, onClose]);

  const iconToUse = useMemo(() => {
    switch (currentNotification?.severity) {
      case "error":
        return <InfoOutlinedIcon />;
      case "info":
        return <CheckCircleOutlineIcon />;
      default:
        return false;
    }
  }, [currentNotification?.severity]);

  return (
    <Snackbar
      open={isOpen}
      onClose={() => onClose()}
      message={currentNotification?.message}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <StyledAlert
        severity={currentNotification?.severity}
        variant={"filled"}
        icon={iconToUse}
        elevation={4}
      >
        {currentNotification?.message}
      </StyledAlert>
    </Snackbar>
  );
};
