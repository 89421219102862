import yaml from "js-yaml";
import { diff, flattenChangeset } from "json-diff-ts";

export interface K8sResourceUnnecessaryFields {
  metadata: {
    annotations?: Record<string, unknown>;
    creationTimestamp?: string;
    resourceVersion?: string;
    uid?: string;
    managedFields?: Record<string, unknown>;
  };
  status?: Record<string, unknown>;
}

export const cleanResourceYaml = (resourceYaml: string): string => {
  try {
    const result = yaml.load(resourceYaml) as K8sResourceUnnecessaryFields;
    delete result?.metadata?.creationTimestamp;
    delete result?.metadata?.managedFields;
    delete result?.metadata?.resourceVersion;
    delete result?.metadata?.uid;
    delete result?.status;
    return yaml.dump(result);
  } catch {
    return "";
  }
};

export const prepareYamlForCommand = (yamlText: string): string =>
  JSON.stringify(yaml.load(yamlText) ?? "");

const notAllowedFields = [
  "$.kind",
  "$.status",
  "$.metadata.name",
  "$.metadata.namespace",
  "$.metadata.generation",
  "$.metadata.creationTimestamp",
  "$.metadata.resourceVersion",
  "$.metadata.uid",
];

export const validateYaml = (oldYaml: string, newYaml: string): void => {
  const oldJson = yaml.load(oldYaml);
  const newJson = yaml.load(newYaml);
  const changes = flattenChangeset(diff(oldJson, newJson));
  const notAllowedChanges = changes.filter(({ path }) =>
    notAllowedFields.includes(path)
  );
  if (notAllowedChanges.length) {
    throw new Error(
      `Fields: "${notAllowedChanges
        .map(({ path }) => path)
        .join('", "')}" can't be changed`
    );
  }
};

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const yamlToJson = (yamlText: string): unknown => {
  try {
    return yaml.load(yamlText);
  } catch (err) {
    return {};
  }
};
