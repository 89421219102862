import {
  ChangesSummaryNoChanges,
  getChangesDetails,
  getHiddenChangesText,
} from "./getChangesSummary";

export const getDeployCardChangesText = (
  deployDiffKeys: (string | null)[]
): string => {
  if (!deployDiffKeys.length) return ChangesSummaryNoChanges;
  const changesDetails = getChangesDetails(deployDiffKeys);
  const relevantChanges = changesDetails.changes;

  if (!relevantChanges.length) {
    return changesDetails.hiddenCount
      ? getHiddenChangesText(changesDetails.hiddenCount)
      : ChangesSummaryNoChanges;
  }
  const changesCount = changesDetails.hiddenCount + relevantChanges.length;
  return changesCount > 1
    ? `${relevantChanges[0]} +${changesCount - 1}`
    : relevantChanges[0];
};
