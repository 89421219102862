import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

export const useGetIsFieldStateInvalid = (configurationNames: string[]) => {
  const { getFieldState } = useFormContext();

  return useCallback(
    (index: number) => getFieldState(configurationNames[index]).invalid,
    [configurationNames, getFieldState]
  );
};
