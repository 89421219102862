import { useMemo } from "react";
import { get } from "lodash";
import { ResourceTableModelRow } from "komodor-types";

import { DeletedPodsResponse } from "../../../generated/resourcesApi";
import getAgeFromDate from "../utils/getAgeFromDate";

import { buildContainersData, buildLabels } from "./buildInspectionColumns";

export const useParsePodsResultFromAPI = (
  result: DeletedPodsResponse | undefined
): ResourceTableModelRow[] | null => {
  return useMemo(() => {
    if (!result) return null;
    return result.data.map<ResourceTableModelRow>(fromPodToRow);
  }, [result]);
};

// Return type not matching actual returned object type.
// TODO: Fix this.
export const fromPodToRow = (pod: unknown): ResourceTableModelRow => {
  const containerData = buildContainersData(get(pod, "containerStatuses", {}));
  const status = get(pod, "statusReason", null) ?? get(pod, "phase", "");
  const age = getAgeFromDate(new Date(get(pod, "eventTime", "")));

  return {
    id: get(pod, "id", ""),
    name: get(pod, "podName", ""),
    namespace: get(pod, "namespace", ""),
    age,
    node: get(pod, "nodeName", ""),
    labels: buildLabels(get(pod, "labels", {})),
    ip: get(pod, "ip", ""),
    status,
    controlledBy: get(pod, "resourceOwnerName", ""),
    controlledByRoot: get(pod, "resourceOwnerName", ""),
    readinessGates: get(pod, "readinessGates", ""),
    metadata: get(pod, "metadata", {}),
    spec: get(pod, "spec", {}),
    deletedPodStatus: get(pod, "status", ""),
    containerStatuses: get(pod, "containerStatuses", {}),
    ...containerData,
  };
};
