import React from "react";
import { muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import { ButtonProps } from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)`
  && {
    border-color: ${muiColors.blue[600]};
    max-width: 120px;
    min-width: 100px;
  }
`;

export const SaveButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton {...props} variant={"outlined"}>
      {props.children}
    </StyledButton>
  );
};
