import React, { useState } from "react";
import styled from "styled-components";

import Steps from "../../../common/Steps";
import { H3Poppins, H4Light } from "../../../common/typography";
import { blackGreyText, gray16 } from "../../../../Colors";
import { CloseIcon } from "../../../common/CloseIcon";

import StepInstallKomodorAgent from "./steps/InstallCommand";
import StepEnterClusterName from "./steps/EnterClusterName";
import StepCheckConnection from "./steps/CheckConnection";
import GetStarted from "./steps/GetStarted";
import StepsButtons from "./steps/StepsButtons";
import { StepsNumber } from "./steps/types";
import useIsAgentInstalled from "./useIsAgentInstalled/useIsAgentInstalled";
import StepAllSet from "./steps/StepAllSet";

const StyledCloseIcon = styled(CloseIcon)`
  grid-column-start: end;
  justify-self: end;
`;

const Container = styled.div<{ isFirstInstallation?: boolean }>`
  display: grid;
  grid-template-rows: [title] 2rem [body] 1fr [buttons];
  grid-row-gap: ${({ isFirstInstallation }) =>
    isFirstInstallation ? "1.5rem" : "2rem"};
  height: 27.5rem;
  width: 38rem;
`;

export const H4Lighter = styled(H4Light)`
  color: ${gray16};
  margin-top: 0.5rem;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-row: title / body;
  align-items: center;
  align-content: center;
  grid-template-columns: [left] auto [center] auto [end] auto;
`;

const StyledTitle = styled(H3Poppins)<{ middle?: boolean }>`
  color: ${blackGreyText};
  ${({ middle }) =>
    middle
      ? `grid-column-start: center;     
      justify-self: center;`
      : `grid-column-start: left`}
`;

const KubernetesInstallation: React.FC<{
  closeModalCallback: () => void;
  showWelcomeStep?: boolean;
}> = ({ closeModalCallback, showWelcomeStep = false }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [apiKey, setApiKey] = useState<string | null>(null);

  const isAgentInstalled = useIsAgentInstalled(apiKey);

  const [currentInstallStep, setCurrentInstallStep] = useState(
    showWelcomeStep ? 0 : 1
  );

  const [clusterName, setClusterName] = useState<string | null>(null);

  const [isClusterNameValid, setIsClusterNameValid] = useState(false);

  // numeric enums are duplicated
  const stepsLength = Object.keys(StepsNumber).length / 2;

  const getStepTitle = (
    currentInstallStep: number,
    isFirstInstallation: boolean
  ) => {
    return currentInstallStep === 0 && !isFirstInstallation
      ? "Welcome to Komodor"
      : "Set Up Komodor";
  };

  return (
    <Container isFirstInstallation={showWelcomeStep}>
      <HeaderContainer>
        <StyledTitle
          data-e2e-selector="k8s-integration-title"
          middle={currentInstallStep === 0}
        >
          {getStepTitle(currentInstallStep, showWelcomeStep)}
        </StyledTitle>
        {!showWelcomeStep && <StyledCloseIcon onClick={closeModalCallback} />}
      </HeaderContainer>
      <Steps curStep={currentInstallStep}>
        <GetStarted />
        <StepEnterClusterName
          isClusterNameValid={isClusterNameValid}
          clusterName={clusterName ?? ""}
          setClusterName={setClusterName}
          setIsClusterNameValid={setIsClusterNameValid}
          errorMessage={errorMessage}
        />
        <StepInstallKomodorAgent
          clusterName={clusterName ?? ""}
          apiKey={apiKey}
          errorMessage={errorMessage}
        />
        <StepCheckConnection
          isAgentInstalled={isAgentInstalled}
          clusterName={clusterName}
          closeModalCallback={closeModalCallback}
          errorMessage={errorMessage}
        />
        <StepAllSet />
      </Steps>
      <StepsButtons
        currentInstallStep={currentInstallStep}
        setCurrentInstallStep={setCurrentInstallStep}
        stepsLength={stepsLength}
        showWelcomeStep={showWelcomeStep}
        isClusterNameValid={isClusterNameValid}
        setApiKey={setApiKey}
        apiKey={apiKey}
        clusterName={clusterName}
        setErrorMessage={setErrorMessage}
        isSupportedDemoAccount={false}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default KubernetesInstallation;
