import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

import { Dictionary } from "@/shared/types/Dictionary";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

type IsFilterFetchingDataParams = {
  filterCategory: string;
  isFetching: boolean;
};

export const useIsFilterFetchingData = ({
  filterCategory,
  isFetching,
}: IsFilterFetchingDataParams) => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const lastFiltersInUrl = useRef(filtersDataInUrl);
  useEffect(() => {
    const allOtherFilters = Object.keys(filtersDataInUrl)
      .filter((key) => key !== filterCategory)
      .reduce<Dictionary<FilterOptionInUrl[]>>((acc, key) => {
        acc[key] = filtersDataInUrl[key];
        return acc;
      }, {});

    if (isEqual(lastFiltersInUrl.current, allOtherFilters)) {
      setIsFetchingData(false);
      return;
    }

    lastFiltersInUrl.current = allOtherFilters;
    setIsFetchingData(isFetching);
  }, [filterCategory, filtersDataInUrl, isFetching]);

  return { isFetchingData };
};
