import React from "react";

import { IconProps } from "../types";

export const WorkloadMonitor16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <g id="WorkloadHealth">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.8329 6.171L9.99996 3.62581L15.167 6.171L16.014 9.96666H13.85L12.4083 7.09166C12.125 6.525 11.2 6.525 10.9166 7.09166L8.33329 12.2667L7.40829 10.425C7.26663 10.1417 6.98329 9.96666 6.66663 9.96666H3.98592L4.8329 6.171ZM3.61402 11.6333L3.55675 11.89L7.13246 16.4762H12.8675L16.4432 11.89L16.3859 11.6333H13.3333C13.0166 11.6333 12.7333 11.4583 12.5916 11.175L11.6666 9.33333L9.07496 14.5083C8.93329 14.7917 8.64996 14.9667 8.33329 14.9667C8.01663 14.9667 7.73329 14.7917 7.58329 14.5083L6.14996 11.6333H3.61402ZM9.60608 1.75862C9.85499 1.63601 10.1449 1.63601 10.3938 1.75862L16.3486 4.69184C16.5976 4.81444 16.7783 5.04631 16.8398 5.32181L18.3105 11.9127C18.372 12.1882 18.3075 12.4773 18.1352 12.6982L14.0144 17.9837C13.8421 18.2047 13.5809 18.3333 13.3046 18.3333H6.69529C6.41902 18.3333 6.15779 18.2047 5.98555 17.9837L1.86469 12.6982C1.69244 12.4773 1.62792 12.1882 1.68939 11.9127L3.16011 5.32181C3.22159 5.04631 3.40236 4.81444 3.65127 4.69184L9.60608 1.75862Z"
      />
    </g>
  </svg>
);

WorkloadMonitor16.defaultProps = {
  fill: "currentColor",
};
