import axios from "axios";
import { useMemo } from "react";

import { useRequestHeaders } from "@/shared/hooks/common-api/headers";
import { getAppConfig } from "@/shared/config/appConfig";

const getAgentsServiceAPIBasePath = (): string => {
  return getAppConfig().agentsServiceAPIServerURL;
};

export const useAgentsApiClient = () => {
  const headers = useRequestHeaders();
  return useMemo(
    () =>
      axios.create({
        baseURL: getAgentsServiceAPIBasePath(),
        headers,
      }),
    [headers]
  );
};
