import React from "react";

import { useIsSupportedWorkspaceKind } from "../hooks/useIsSupportedWorkspaceKind";

import { UnsupportedWorkspaceView } from "./UnsupportedWorkspaceView";

export type IfSupportedTypeForWorkspaceKindProps = {
  children: React.ReactNode;
};

export const IfSupportedEntityForWorkspaceKind: React.FC<
  IfSupportedTypeForWorkspaceKindProps
> = ({ children }) => {
  const isSupported = useIsSupportedWorkspaceKind();
  return isSupported ? children : <UnsupportedWorkspaceView />;
};
