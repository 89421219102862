import { useContext } from "react";

import {
  RecommendationsModalContext,
  RecommendationsModalContextState,
} from "./RecommendationsModalContext";

export const useRecommendationsModalContext =
  (): RecommendationsModalContextState =>
    useContext(RecommendationsModalContext);
