import React from "react";
import styled from "styled-components";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

const Container = styled.div<LoaderContainerProps>`
  min-height: ${({ minHeight }) => minHeight ?? "200px"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoaderContainerProps = {
  minHeight?: string;
};

export const LoaderContainer: React.FC<LoaderContainerProps> = ({
  minHeight,
}) => {
  return (
    <Container minHeight={minHeight}>
      <LinesLoader />
    </Container>
  );
};
