import pluralize from "pluralize";

import { TextContainer } from "../../../violationDrawerStyles";

export const getDescriptionText = (numOfContainers: number) =>
  `${numOfContainers} ${pluralize(
    "container",
    numOfContainers
  )} of this workload are missing a Liveness probes`;

export const getWhatDescription = (containersName: string[]) => {
  const numOfContainers = containersName.length;

  return (
    <TextContainer>
      {getDescriptionText(numOfContainers)}
      <ul>
        {containersName.map((containerName) => (
          <li key={containerName}>{containerName}</li>
        ))}
      </ul>
    </TextContainer>
  );
};

export const getWhyDescription = () => (
  <TextContainer>
    <b>Liveness Probe:</b> This probe is used to determine if a container is
    running. If the liveness probe fails, the container is restarted. This helps
    to recover from a failed state.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Add a <b>livenessProbe</b> configuration to your container specification in
    the pod's YAML manifest.
  </TextContainer>
);
