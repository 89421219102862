import React from "react";

import { SecurityBestPracticesResponse } from "../../../../../../../../../generated/applicationInsights";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { insightCardTitles } from "../../insightsConstants";
import { InsightCard } from "../../shared/components/InsightCard";
import { useOpenInsightsDrawer } from "../../shared/hooks/appInisghtsHooks";
import { useOverridableFlags } from "../../../../../../../../../shared/context/featureFlags/OverridableFlags";

import { BestPracticesSecurityFailedDrawerContent } from "./BestPracticesSecurityFailedDrawerContent";
import { cardTitle } from "./securityFailedText";

export const ID = "Best Practices Security";

const shouldDisplayBestPracticesSecurity = (
  data?: SecurityBestPracticesResponse
) => {
  return data && data.rows?.length > 0;
};

export const BestPracticesSecurityFailed: React.FC = () => {
  const { showBestPractices } = useOverridableFlags();

  const {
    securityBestPractices: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const openInsightsDrawer = useOpenInsightsDrawer();

  if (
    (!loading && !shouldDisplayBestPracticesSecurity(data)) ||
    !showBestPractices
  )
    return null;

  const { title, icon } = insightCardTitles.securityBestPractices;
  const description = `${data?.rows?.length} ${cardTitle}`;

  const onCardClick = () => {
    openInsightsDrawer({
      title: description,
      component: <BestPracticesSecurityFailedDrawerContent />,
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={description}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"oneDescriptionLine"}
    />
  );
};
