import { useMemo } from "react";
import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { SecretFragment } from "../../../../../../../generated/graphql";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { CONFIGURATION_SECRETS_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";

export const useSecretsEventsFetch = (
  secretUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
): [SecretFragment[] | undefined, boolean] => {
  const secretNames = useMemo(
    () => compact(secretUids?.map((secretUid) => secretUid.split(";").at(-1))),
    [secretUids]
  );

  const uids = useMemo(
    () =>
      secretNames.map((resourceName) =>
        buildKomodorUid({
          resourceName,
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          kind: "Secret",
        })
      ),
    [clusterName, secretNames, namespace]
  );

  const { data: dataFromAPI, loading } = useResourcesAPIGet(
    CONFIGURATION_SECRETS_EVENT_SEARCH,
    {
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      komodorUids: uids,
      fields: [
        "action",
        "clusterName",
        "eventTime",
        "namespace",
        "id",
        "secretName",
        "k8sDefault",
        "services",
      ],
    },
    !uids.length
  );

  return [dataFromAPI?.data as SecretFragment[] | undefined, loading];
};
