import styled from "styled-components";

import { textBolderStyle } from "../../typography";

import dropdownArrow from "./dropdownArrow";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default styled.select`
  display: block;
  appearance: none;
  color: #3d4048;
  border: 1px solid #bcc0c8;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: #fff;

  ${textBolderStyle}
  ${dropdownArrow}

  :focus {
    outline: none;
    border-color: #007aff;
  }
`;
