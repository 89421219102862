import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { MultiSelect, OptionType } from "@komodorio/design-system/deprecated";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { Variables } from "../../common/types";
import {
  FormTextInput,
  ErrorMessage,
  lightStyle,
  FloatingContainer,
} from "../common/styles";
import Select from "../../../common/controls/Select";
import { useFormValidations } from "../../../../shared/context/ValidationsProvider";
import { validateDuration } from "../common/TriggerConditionsSection";
import { ZIndex } from "../../../../constants/zIndex";
import { InfoLink } from "../../../common/InfoLink";
import { AVAILABILITY_MONITOR_DOCS_LINK } from "../../../../shared/constants/docsLinks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ALL_CATEGORIES, All_CATEGORIES_CHAR, AvailabilityCategories } from ".";

const Container = styled.div`
  ${lightStyle};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 0.4rem;
`;

const StyledSelect = styled(Select)`
  margin-inline: 0.4rem;
`;

const isValidPositiveInteger = (value: string) => {
  const number = Number(value);
  if (isNaN(number) || value.includes(".") || number < 1) {
    return false;
  }
  return true;
};
const isValidPercentage = (value: string) => {
  if (value.endsWith("%")) {
    value = value.slice(0, -1);
  }
  return isValidPositiveInteger(value) && Number(value) <= 100;
};

enum UnitType {
  Percentage = "Percentage",
  Number = "Number",
}
const validateThreshold = (minAvailable: string, unitType: UnitType) => {
  if (unitType === UnitType.Percentage) {
    return !isValidPercentage(minAvailable)
      ? "Please enter a value between 1 to 100"
      : undefined;
  }
  return !isValidPositiveInteger(minAvailable)
    ? "Please enter a positive integer"
    : undefined;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const validateCategories = (
  categories: MuiSelectionOption<string>[]
) => {
  if (categories.length === 0) {
    return "Select at least one category. Categories not covered by any other rule will not be monitored, and won't affect the service's health.";
  }
  return undefined;
};

const MarginInline = styled.div`
  margin-inline: 0.4rem;
`;
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getCategoriesFromOptions = (
  selectedOptions: OptionType<string>[]
) => {
  const isAllCategories = !!selectedOptions.find(
    (c) => c.value === All_CATEGORIES_CHAR
  );
  return isAllCategories
    ? ALL_CATEGORIES
    : selectedOptions.map((categoryOption) => categoryOption.value);
};

export const AvailabilityTriggersSection: React.FC<{
  id: string;
  variables: Variables | undefined;
  handleChange: (variables: Variables) => void;
}> = ({ id, variables, handleChange }) => {
  const { errors, setError } = useFormValidations();
  const [
    thresholdValidationKey,
    durationValidationKey,
    categoriesValidationKey,
  ] = [`${id}-threshold`, `${id}-duration`, `${id}-categories`];
  const thresholdError = errors[thresholdValidationKey];
  const durationError = errors[durationValidationKey];
  const categoriesError = errors[categoriesValidationKey];

  const defaultThreshold = variables?.minAvailable?.toString() ?? "100%";

  const [unitType, setUnitType] = useState<UnitType>(
    defaultThreshold.endsWith("%") ? UnitType.Percentage : UnitType.Number
  );

  const [stringDuration, setStringDuration] = useState(
    variables?.duration?.toString() ?? ""
  );
  const handleDurationChange = (value: string) => {
    setStringDuration(value);
    const errorMessage = validateDuration(value);
    if (!errorMessage) {
      handleChange({ ...variables, duration: Number(value) });
    }
    setError(durationValidationKey, errorMessage);
  };

  const [threshold, setThreshold] = useState(defaultThreshold);
  const handleThresholdChange = (value: string, unitType: UnitType) => {
    setThreshold(value);
    const errorMessage = validateThreshold(value, unitType);
    if (!errorMessage) {
      handleChange({
        ...variables,
        minAvailable:
          unitType === UnitType.Percentage && !value.endsWith("%")
            ? `${value}%`
            : value,
      });
    }
    setError(thresholdValidationKey, errorMessage);
  };

  const handleUnitChange = (value: UnitType) => {
    setUnitType(value);
    handleThresholdChange(threshold, value);
  };

  const categories = useMemo(() => {
    return variables?.categories
      ? variables?.categories
      : AvailabilityCategories.map((c) => c.value);
  }, [variables?.categories]);

  const categoriesOptions = useMemo(() => {
    if (categories.some((c) => c === All_CATEGORIES_CHAR)) {
      return AvailabilityCategories.map((o) => ({
        ...o,
        disabled: o.value !== All_CATEGORIES_CHAR,
      }));
    }
    return AvailabilityCategories;
  }, [categories]);

  const handleCategoriesChange = useCallback(
    (selectedOptions: OptionType<string>[]) => {
      const errorMessage = validateCategories(selectedOptions);
      handleChange({
        ...variables,
        categories: getCategoriesFromOptions(selectedOptions),
      });
      setError(categoriesValidationKey, errorMessage);
    },
    [categoriesValidationKey, handleChange, setError, variables]
  );

  return (
    <>
      <Container>
        The
        <StyledSelect
          value={unitType}
          onChange={(e) => handleUnitChange(e.target.value as UnitType)}
        >
          <option value={UnitType.Percentage}>Percentage</option>
          <option value={UnitType.Number}>Number</option>
        </StyledSelect>
        of available replicas is&nbsp;<b>smaller than</b>
        <FormTextInput
          autoFocus
          isValid={!thresholdError}
          value={threshold}
          placeholder={unitType === UnitType.Percentage ? "e.g. 80%" : "e.g. 5"}
          onChange={(e) => handleThresholdChange(e.target.value, unitType)}
        />
        for more than
        <FormTextInput
          autoFocus
          isValid={!durationError}
          value={stringDuration}
          placeholder={"e.g. 30"}
          onChange={(e) => handleDurationChange(e.target.value)}
        />
        seconds, for categories:
        <MarginInline>
          <MultiSelect
            size="medium"
            width="17rem"
            value={categoriesOptions.filter((o) =>
              categories.includes(o.value)
            )}
            placeholder={"Select categories"}
            options={categoriesOptions}
            onChange={handleCategoriesChange}
            listMaxHeight="20rem"
            listZIndex={ZIndex.AboveEventDrawer.toString()}
          />
        </MarginInline>
      </Container>
      <FloatingContainer>
        <InfoLink
          url={AVAILABILITY_MONITOR_DOCS_LINK}
          text={"What are categories?"}
        />
      </FloatingContainer>
      <ErrorMessage>
        {durationError || thresholdError || categoriesError}
      </ErrorMessage>
    </>
  );
};
