import React, { useEffect, useState } from "react";

const getAbsoluteWidth = (element: Element) => {
  if (!element) return 0;
  const box = element.getBoundingClientRect();
  return box.x + box.width;
};

export const useGetMaxChildrenInContainer = (
  containerRef: React.RefObject<HTMLDivElement>,
  childrenContainerRef: React.RefObject<HTMLDivElement>
) => {
  const [maxChildren, setMaxChildren] = useState(0);

  useEffect(() => {
    if (containerRef.current && childrenContainerRef.current?.children.length) {
      const textChildren = childrenContainerRef.current.children;
      const containerWidth = getAbsoluteWidth(containerRef.current);
      let maxChildren = 0,
        index = 0;

      for (const child of textChildren) {
        const childWidth = getAbsoluteWidth(child);
        if (childWidth > containerWidth) {
          maxChildren = index;
          break;
        }
        index++;
      }
      setMaxChildren(maxChildren);
    }
  }, [containerRef, childrenContainerRef]);

  return maxChildren;
};
