import { useMemo } from "react";

import { GitCompare } from "../../../../../shared/types/git";

type GitCompares = GitCompare[] | undefined;
const useFilteredGitCompares = (gitCompares: GitCompares): GitCompares =>
  useMemo(() => {
    return gitCompares?.some((c) => c.group !== "default")
      ? gitCompares.filter((c) => c.group !== "default")
      : gitCompares;
  }, [gitCompares]);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useFilteredGitCompares;
