import { useMemo } from "react";

import {
  CheckItem,
  WEEK_AGO_WINDOW,
  infraRealtimeIssueTypes,
  infraReliabilityRiskTypes,
  workloadRealtimeIssueTypes,
  workloadReliabilityRiskTypes,
} from "./constants";

import { useGetGroups } from "@/shared/hooks/reliability-api/groups/useGetGroups";
import {
  GroupViolationsByOptions,
  ImpactGroupType,
  CheckCategory,
  ViolationCountBy,
  CheckType,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useGetViolationsOverTime } from "@/shared/hooks/reliability-api/violations/useGetViolationsOverTime";

type GraphDataPoint = {
  time: number;
  [CheckType.UnhealthyService]: number;
  [CheckType.UnhealthyWorkflow]: number;
  [CheckType.FailedJob]: number;
};

export function useReliabilityRisks(
  checkCategory: CheckCategory,
  clusters?: string[]
) {
  const { data, isLoading, error } = useGetGroups({
    groupViolationsBy: GroupViolationsByOptions.ImpactGroup,
    impactGroupType: [ImpactGroupType.Dynamic],
    checkCategory: [checkCategory],
    breakdownFactors: [ViolationCountBy.Severity],
    status: [ViolationStatus.Open, ViolationStatus.Confirmed],
    clusterName: clusters,
  });
  const groups = useMemo(
    () =>
      Object.entries(
        checkCategory === CheckCategory.Workload
          ? workloadReliabilityRiskTypes
          : infraReliabilityRiskTypes
      ).map(([impactGroupId, { label }]) => {
        const group = data?.data.groups.find(
          (group) => group.id === impactGroupId
        );
        return { label, group, impactGroupId };
      }),
    [checkCategory, data?.data.groups]
  );
  const isEmpty = useMemo(() => {
    return (
      !isLoading && groups.filter((group) => Boolean(group.group)).length === 0
    );
  }, [groups, isLoading]);

  return {
    numOfViolations: data?.data.numOfTotalViolations,
    groups,
    isEmpty,
    isLoading,
    error,
  };
}

export function useRealtimeIssues(
  checkCategory: CheckCategory,
  clusters?: string[]
) {
  const { data, isLoading, error } = useGetGroups({
    groupViolationsBy: GroupViolationsByOptions.ImpactGroup,
    impactGroupType: [ImpactGroupType.Realtime],
    checkCategory: [checkCategory],
    breakdownFactors: [ViolationCountBy.CheckType],
    status: [ViolationStatus.Open, ViolationStatus.Confirmed],
    clusterName: clusters,
  });
  const groups = useMemo(() => {
    return Object.entries(
      checkCategory === CheckCategory.Workload
        ? workloadRealtimeIssueTypes
        : infraRealtimeIssueTypes
    ).map(([checkType, { label, icon }]) => {
      const check =
        Object.entries(
          data?.data?.groups?.[0]?.breakdowns?.["checkType"] ?? {}
        ).find(([id]) => id === checkType)?.[1] ?? 0;
      return { checkType: checkType as CheckType, label, check, icon };
    });
  }, [checkCategory, data?.data?.groups]);

  const isEmpty = useMemo(() => {
    return (
      !isLoading && groups.filter((group) => Boolean(group.check)).length === 0
    );
  }, [groups, isLoading]);

  return {
    numOfViolations: data?.data.numOfTotalViolations,
    groups,
    isLoading,
    error,
    isEmpty,
  };
}

export function useIssuesOverTimeData(
  impactGroup: ImpactGroupType,
  checkCategory: CheckCategory,
  clusters?: string[]
) {
  const {
    data: graphData,
    error: graphDataError,
    isLoading: isLoadingGraphData,
  } = useGetViolationsOverTime({
    fromEpoch: WEEK_AGO_WINDOW.from.toString(),
    toEpoch: WEEK_AGO_WINDOW.to.toString(),
    impactGroupType: [impactGroup],
    breakdownFactor:
      impactGroup === ImpactGroupType.Realtime
        ? ViolationCountBy.CheckType
        : ViolationCountBy.ImpactGroupId,
    checkCategory: [checkCategory],
    clusterName: clusters,
  });
  const graphPoints = useMemo(() => {
    const points =
      graphData?.violations.map((point) => ({
        time: point.time,
        [point.key]: point.value,
      })) ?? [];

    const byDay = new Map<string, GraphDataPoint>();
    points.forEach((point) => {
      const time = point.time.toString();
      if (!byDay.has(time)) {
        byDay.set(time, point as GraphDataPoint);
      } else {
        const existingPoint = byDay.get(time);
        byDay.set(time, {
          ...existingPoint,
          ...(point as GraphDataPoint),
        });
      }
    });

    return Array.from(byDay.values());
  }, [graphData?.violations]);

  const issueTypes: Record<string, CheckItem> =
    checkCategory === CheckCategory.Workload
      ? impactGroup === ImpactGroupType.Realtime
        ? workloadRealtimeIssueTypes
        : workloadReliabilityRiskTypes
      : impactGroup === ImpactGroupType.Realtime
      ? infraRealtimeIssueTypes
      : infraReliabilityRiskTypes;

  return {
    graphPoints,
    issueTypes,
    isLoading: isLoadingGraphData,
    error: graphDataError,
  };
}
