import { useEffect, useMemo } from "react";
import { isEqual } from "lodash";

import { useClusterOptionsByAppView } from "../../../../../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { isDevMode } from "../../../../../../../shared/utils/isDevMode";
import {
  flattenEarliestRowsTimeResponse,
  getAccuracyDataForClusters,
  getIsNewCluster,
} from "../../../../../../../shared/utils/metricsAgentInfoAccuracyUtils";
import { useOverviewPageStore } from "../store/overviewPageStore";
import {
  selectMetricsSupportAgentInfo,
  selectSetMetricsSupportAgentInfo,
} from "../store/overviewPageStoreSelectors";
import { useFetchMetricsEarliestRowTime } from "../../../../../../../shared/hooks/useFetchMetricsEarliestRowTime";
import { useAppViewsResourcesStore } from "../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { selectClusters } from "../../../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";

export const useFetchAndSetAccuracyDataForMetrics = () => {
  const {
    hasSufficientDataCollectionTime,
    hasFetchedDataAccuracy,
    hasMinimalDataAccuracy,
  } = useOverviewPageStore(selectMetricsSupportAgentInfo);
  const setMetricsSupportAgentInfo = useOverviewPageStore(
    selectSetMetricsSupportAgentInfo
  );

  const appViewClusters = useAppViewsResourcesStore(selectClusters);

  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const clusterNames = useMemo(
    () => clusterOptions.map((cluster) => cluster.clusterName),
    [clusterOptions]
  );

  // assert app view clusters are set before fetching data
  const clusterNamesRequestParams =
    appViewClusters.size > 0 ? clusterNames : [];

  const { data, error } = useFetchMetricsEarliestRowTime(
    clusterNamesRequestParams
  );
  const clusterEarliestRecordData = useMemo(() => {
    return flattenEarliestRowsTimeResponse(data?.clusters);
  }, [data]);

  // divide clusters based on MINIMUM_DAYS_FOR_ACCURACY
  const { clustersWithSufficientTime, clustersWithInsufficientTime } =
    getAccuracyDataForClusters(clusterEarliestRecordData);

  // check if all clusters with clustersWithSufficientTime are considered new
  const hasMinimalMetricsDataAccuracy =
    !!clustersWithSufficientTime.length ||
    !Object.values(clustersWithInsufficientTime).every((cluster) =>
      getIsNewCluster(new Date(cluster))
    );

  useEffect(() => {
    if (error && !data) {
      // API call has failed
      const currentData = {
        hasSufficientDataCollectionTime,
        hasMinimalDataAccuracy,
        hasFetchedDataAccuracy,
      };

      // do not prevent fetching any metrics data
      const dataToUpdate = {
        hasSufficientDataCollectionTime: true,
        hasMinimalDataAccuracy: true,
        hasFetchedDataAccuracy: true,
      };

      if (!isEqual(currentData, dataToUpdate)) {
        setMetricsSupportAgentInfo(dataToUpdate);
      }
      return;
    }

    if (data?.clusters) {
      const hasSufficientData = clustersWithSufficientTime.length > 0;
      if (
        hasMinimalDataAccuracy !== hasMinimalMetricsDataAccuracy ||
        hasSufficientDataCollectionTime !== hasSufficientData ||
        !hasFetchedDataAccuracy
      ) {
        setMetricsSupportAgentInfo({
          hasSufficientDataCollectionTime: hasSufficientData,
          hasMinimalDataAccuracy: hasMinimalMetricsDataAccuracy,
          hasFetchedDataAccuracy: true,
          clustersWithInsufficientTime,
          clustersWithSufficientTime,
        });
      }
    }
  }, [
    hasMinimalMetricsDataAccuracy,
    clustersWithInsufficientTime,
    clustersWithSufficientTime,
    data,
    error,
    hasFetchedDataAccuracy,
    hasSufficientDataCollectionTime,
    setMetricsSupportAgentInfo,
    hasMinimalDataAccuracy,
  ]);
};
