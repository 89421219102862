import { Statement } from "komodor-types";
import _ from "lodash";
import React from "react";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { RbacPolicy, RbacPolicyType } from "../../../../generated/auth";
import { Validations } from "../../../../components/Settings/Users/types";

import { PolicyTypeChoice, validatePolicyType } from "./PolicyTypes";
import { SetStateFnRequired } from "./common";
import {
  parsePolicyTags,
  PolicyTagsInput,
  validatePolicyTags,
} from "./PolicyTags";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  defaultStatements,
  defaultStatementsNamespacePattern,
  defaultStatementsDynamicTags,
  PolicyForm,
} from "./PolicyFormModal";

interface DynamicTagsPolicyEditFieldsProps {
  errors: Validations;
  value: PolicyForm;
  policy?: RbacPolicy;
  setValue: SetStateFnRequired<PolicyForm>;
  setError: (key: string, error: string | undefined) => void;
}

export const DynamicTagsPolicyEditFields: React.FC<
  DynamicTagsPolicyEditFieldsProps
> = ({ errors, value, policy, setValue, setError }) => {
  const { showExtraPolicyTypes } = useOverridableFlags();

  return (
    <>
      {showExtraPolicyTypes ? (
        <>
          <PolicyTypeChoice
            errorMessage={errors["type"]}
            currentlyChecked={value.type}
            disabled={policy?.type === RbacPolicyType.DynamicTag}
            onChange={(newValue) => {
              let statementsToSet: Statement[];
              switch (newValue) {
                case RbacPolicyType.DynamicTag:
                  statementsToSet = defaultStatementsDynamicTags;
                  break;
                case RbacPolicyType.Wildcard:
                  statementsToSet = defaultStatementsNamespacePattern;
                  break;
                default:
                  statementsToSet = defaultStatements;
                  break;
              }

              setValue({
                ...value,
                type: newValue as RbacPolicyType,
                statements: statementsToSet,
              });
              setError("type", validatePolicyType(newValue));
            }}
          />
          {value.type === RbacPolicyType.DynamicTag ? (
            <PolicyTagsInput
              errorMessage={errors["tags"]}
              value={_.map(value.tags, (v, k) => {
                if (v === undefined) {
                  return k;
                }
                return `${k}:${v}`;
              })}
              onChange={(newValue) => {
                const parsedTags = parsePolicyTags(newValue);
                setValue({ ...value, tags: parsedTags });
                setError("tags", validatePolicyTags(newValue));
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
