import { useCallback, useState } from "react";
import { AccountPlan } from "komodor-types";

import { useCreateNewUserAccountMutation } from "../../../generated/graphql";
import { setStoredLocalUserId } from "../../utils/auth0/localEnv/storedLocalUserId";
import { setStoredAccountId } from "../../utils/auth0/localEnv/storedAccountId";
import { fetchIdentityProvider } from "../../utils/auth0/utils";
import { getIsFreemiumAccount } from "../../../components/Freemium/Utils/createFreeAccount";
import { useUserLoginInfo } from "../useUserMetadata/useUserLoginInfo";
import { useInithubspotForm } from "../analytics";

type useCreateNewUserAccountSignature = () => {
  createNewUserAccount: (accountName: string) => void;
  loading: boolean;
  error: Error | undefined;
  doneSuccessfully: boolean;
};

export const useCreateNewUserAccount: useCreateNewUserAccountSignature = () => {
  const user = useUserLoginInfo();
  useInithubspotForm();
  const [, executeMutation] = useCreateNewUserAccountMutation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [doneSuccessfully, setDoneSuccessfully] = useState(false);

  const createNewUserAccount = useCallback(
    (accountName: string) => {
      (async function () {
        setLoading(true);

        if (!user?.nickname || !user?.email) {
          setError(new Error("missing user identification parameters"));
          setLoading(false);
          return;
        }

        const res = await executeMutation({
          userDisplayName: user.nickname,
          userEmail: user.email,
          accountName: accountName,
          identityProvider: fetchIdentityProvider(user),
          plan: getIsFreemiumAccount() ? AccountPlan.free : AccountPlan.trial,
        });

        if (res.data?.createNewUserAccount?.success !== true) {
          setError(new Error("error"));
          setLoading(false);
          return;
        }
        const userId = res.data?.createNewUserAccount?.userId;
        const accountId = res.data?.createNewUserAccount?.accountId;
        if (!accountId || !userId) {
          setError(new Error("failed to create user and account"));
          setLoading(false);
          return;
        }

        setStoredLocalUserId(userId);
        setStoredAccountId(accountId);
        setLoading(false);
        setDoneSuccessfully(true);
      })();
    },
    [executeMutation, user]
  );

  return { createNewUserAccount, loading, error, doneSuccessfully };
};
