import { WorkloadMetricsDataPoints } from "../../../../../../../../../../generated/metricsApi";
import { Timeframe } from "../../../../../../../../../../shared/types/TimeWindow";
import {
  useGetLineList,
  useHasGraphData,
  useTransformWorkloadMetricsData,
} from "../../../../../../../../../Metrics/hooks/workload/workloadMetricsTabHooks";
import {
  MetricsAggregationType,
  MetricsGraphProps,
} from "../../../../../../../../../Metrics/types";
import { timelineTicksNum } from "../constants";

import { useGetCorrelatedDeployEvents } from "./useGetCorrelatedDeployEvents";

interface Props {
  data?: WorkloadMetricsDataPoints;
  aggregation: MetricsAggregationType;
  komodorUid?: string;
}

export const useGraphData = ({ data, aggregation, komodorUid }: Props) => {
  const transformedData = useTransformWorkloadMetricsData(
    data,
    Timeframe.Last7Days
  );
  const hasGraphData = useHasGraphData(data);
  const linesList = useGetLineList(aggregation, { recommendationBytes: true });
  const events = useGetCorrelatedDeployEvents({
    komodorUid: komodorUid ?? "",
    graphDataPoints: transformedData,
  });
  const graphProps: MetricsGraphProps = {
    data: transformedData,
    disableZoom: true,
    timelineTicksNum: timelineTicksNum,
    showLinesList: linesList,
    aggregationType: aggregation,
    events,
  };

  return {
    transformedData,
    hasGraphData,
    linesList,
    graphProps,
  };
};
