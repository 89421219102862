import React from "react";
import { Status } from "@komodorio/design-system/komodor-ui";

import { ExternalDnsRecordSyncStatusEnum } from "@/generated/addonsApi";
import { statusMap } from "@/components/k8sAddons/addons/externalDns/constants";

export const SyncStatus: React.FC<{
  status: ExternalDnsRecordSyncStatusEnum;
}> = ({ status }) => {
  const { label, color } = statusMap[status] ?? {
    label: "Unknown",
    color: "neutral",
  };
  return <Status label={label} color={color} size="small" />;
};
