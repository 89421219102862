import { useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import {
  dataDogViewNames,
  KOMODOR_LOADING_TIME,
} from "../../../shared/constants/datadog";
import {
  InspectionStore,
  useInspectionStore,
} from "../../../shared/store/inspection/inspectionStore";
import {
  selectedNamespacesSelector,
  setNamespacesSelector,
} from "../../../shared/store/inspection/inspectionSelectors";

type ReportLoadingTimeToDatadogParams = {
  kubernetesResourceName: string;
  clusterName: string;
  isFetching: boolean;
  hasError: boolean;
};

export const useReportLoadingTimeToDatadog = ({
  kubernetesResourceName,
  clusterName,
  isFetching,
  hasError,
}: ReportLoadingTimeToDatadogParams) => {
  const resourceName = useRef("");
  const hasReportedTiming = useRef(false);

  useEffect(() => {
    if (kubernetesResourceName !== resourceName.current) {
      datadogRum.startView(
        dataDogViewNames.getInspectionViewPage(kubernetesResourceName)
      );
      hasReportedTiming.current = false;
    }
    if (!hasReportedTiming.current) {
      if (
        hasError ||
        (resourceName.current === kubernetesResourceName && !isFetching)
      ) {
        datadogRum.addTiming(KOMODOR_LOADING_TIME);
        hasReportedTiming.current = true;
      }
    }

    resourceName.current = kubernetesResourceName;
  }, [clusterName, hasError, isFetching, kubernetesResourceName]);
};

export const useNamespacesFilter = (): [
  InspectionStore["selectedNamespaces"],
  InspectionStore["setSelectedNamespaces"]
] => {
  const selectedNamespaces = useInspectionStore(selectedNamespacesSelector);
  const setNamespaces = useInspectionStore(setNamespacesSelector);

  return [selectedNamespaces, setNamespaces];
};
