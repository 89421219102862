import React, { useMemo, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Close16, MagnifyingGlass16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { groupBy, map, uniq } from "lodash";

import { Table } from "../../../../shared/components/Table/Table";
import RepoAddDrawer from "../drawers/RepoAddDrawer";
import RepoEditDrawer from "../drawers/RepoEditDrawer";
import { useGetHelmRepos } from "../../../../shared/hooks/resources-api/client/helmRepos/useGetHelmRepos";
import { HelmRepo } from "../../../../generated/resourcesApi";

import { ReposEmptyState } from "./components/ReposEmptyState";

export interface ReposTabProps {
  clusters: string[];
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 43fr;
  height: 100%;
  margin-top: 1rem;
  gap: 1rem;
`;

const SearchRow = styled.div`
  display: flex;
  gap: 1rem;
`;

interface RepoModel {
  name: string;
  url: string;
  clusters: string[];
}

const HelmReposTab: React.FC<ReposTabProps> = ({ clusters }) => {
  const { data, isFetching, refresh } = useGetHelmRepos({
    fields: ["repoName", "repoUrl", "clusterName"],
  });
  const [filter, setFilter] = useState("");
  const [displayAddRepo, setDisplayAddRepo] = useState(false);
  const [displayEditRepo, setDisplayEditRepo] = useState(false);
  const [currentRepo, setCurrentRepo] = useState<RepoModel>();

  const existingClusters = useMemo(() => {
    if (data) {
      return Array.from(
        new Set(
          data.map((helmRepo: HelmRepo) => {
            return helmRepo.clusterName || "";
          })
        )
      );
    }
    return [];
  }, [data]);

  const allClusters = useMemo(() => {
    return Array.from(new Set(clusters.concat(existingClusters)));
  }, [clusters, existingClusters]);

  const repos = useMemo(() => {
    if (!data) return [];
    const grouped = groupBy(data, (item) => `${item.repoName}_${item.repoUrl}`);

    return map(grouped, (group, key) => {
      const [name, url] = key.split("_");
      return {
        name,
        url,
        clusters: uniq(map(group, "clusterName")).join(", "),
      };
    });
  }, [data]);

  const filteredRepos = useMemo(() => {
    if (filter) {
      return repos.filter((repo) => repo.name.includes(filter));
    }
    return repos;
  }, [repos, filter]);

  return (
    <>
      <Grid>
        <SearchRow>
          <Input
            width="20rem"
            size="medium"
            placeholder="Search"
            value={filter}
            icon={MagnifyingGlass16}
            onChange={(e) => setFilter(e.target.value)}
            trailingElement={
              filter ? <Close16 onClick={() => setFilter("")} /> : undefined
            }
          />
          <Button
            variant={"primary"}
            onClick={() => {
              setDisplayAddRepo(true);
            }}
          >
            Add Repository
          </Button>
        </SearchRow>
        <Divider />
        {!isFetching && repos.length > 0 && (
          <>
            <Typography>{`${filteredRepos.length} Repositories`}</Typography>
            <Table
              pageSize={10}
              onRowClickOverride={(row) => {
                setDisplayEditRepo(true);
                setCurrentRepo({
                  name: row.name,
                  url: row.url,
                  clusters: row.clusters.split(", "),
                });
              }}
              data={filteredRepos}
              columns={[
                {
                  header: "Name",
                  accessor: "name",
                },
                {
                  header: "URL",
                  accessor: "url",
                },
                {
                  header: "Clusters",
                  accessor: "clusters",
                },
              ]}
            />
          </>
        )}
        {!isFetching && repos.length === 0 && (
          <ReposEmptyState setOpen={() => setDisplayAddRepo(true)} />
        )}
      </Grid>
      <RepoAddDrawer
        open={displayAddRepo}
        onClose={() => setDisplayAddRepo(false)}
        clustersList={allClusters}
        refresh={refresh}
      />
      <RepoEditDrawer
        open={displayEditRepo}
        onClose={() => setDisplayEditRepo(false)}
        refresh={refresh}
        clustersList={allClusters}
        clustersSelected={currentRepo?.clusters ?? []}
        repoName={currentRepo?.name ?? ""}
        repoUrl={currentRepo?.url ?? ""}
      />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default HelmReposTab;
