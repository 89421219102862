import Typography from "@mui/material/Typography";

import { RichSessionData } from "../common/useEnrichedSession";

import { DetailsContainer, ResultLineContainer, ResultTitle } from "./styles";

export const SuggestedRemediation: React.FC<{
  className?: string;
  session: RichSessionData;
}> = ({ className, session }) => {
  return session.recommendation ? (
    <ResultLineContainer className={className}>
      <div>
        <ResultTitle sx={{ whiteSpace: "break-spaces" }}>
          {"Suggested\nRemediation:"}
        </ResultTitle>
      </div>
      <DetailsContainer>
        <Typography variant="body2">{session.recommendation}</Typography>
      </DetailsContainer>
    </ResultLineContainer>
  ) : null;
};
