import {
  Severity,
  ViolationsAggregationGroup,
  ViolationsSeveritiesMap,
} from "@/generated/reliabilityApi";
import { ViolationStatus } from "@/generated/workspacesApi";

export function getViolationCounts(
  group: ViolationsAggregationGroup
): ViolationStatus {
  const severityMap = (group.breakdowns?.severity ??
    {}) as unknown as ViolationsSeveritiesMap;

  return Object.values(Severity).reduce(
    (acc, severity) => {
      const severityCount = severityMap[severity] ?? 0;
      acc[severity as Severity] = { count: severityCount, reasons: [] };
      acc.count += acc.count + severityCount;
      return acc;
    },
    { count: 0 } as ViolationStatus
  );
}
