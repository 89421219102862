import { Token, TokenType } from "./types";

export class TokenBase implements Token {
  value: string;
  type: TokenType;

  constructor(value: string, type: TokenType) {
    this.value = value;
    this.type = type;
  }

  getValue(): string {
    return this.value;
  }

  getType(): TokenType {
    return this.type;
  }
}

export class K8sResourceToken extends TokenBase implements Token {
  resourceKind?: string;
  resourceName?: string;

  constructor(value: string, resourceKind?: string, resourceName?: string) {
    super(value, TokenType.K8sResource);
    this.resourceKind = resourceKind;
    this.resourceName = resourceName;
  }

  getResourceKind(): string | undefined {
    return this.resourceKind;
  }

  getResourceName(): string | undefined {
    return this.resourceName;
  }
}

export class StringToken extends TokenBase implements Token {
  constructor(value: string) {
    super(value, TokenType.String);
  }
}

export class NodeResourceToken extends TokenBase implements Token {
  searchMessage: string;

  constructor(value: string, searchMessage: string) {
    super(value, TokenType.NodeResource);
    this.searchMessage = searchMessage;
  }

  getSearchMessage(): string {
    return this.searchMessage;
  }
}
