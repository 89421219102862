import React, { useMemo } from "react";
import styled from "styled-components";
import { AlertTriangle16 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { MemoryVictim } from "../../../../../../../../../../../../generated/reliabilityApi";
import { EventData } from "../../../../../../../../../../../Metrics/types";

import { useMetricsData } from "./useMetricsData";
import { Graph } from "./Graph/Graph";
import { WorkloadsTable } from "./WorkloadsTable/WorkloadsTable";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
  background-color: white;
`;

interface Props {
  node: MemoryVictim;
  komodorUid: string | undefined;
}

export const NodeDeepView: React.FC<Props> = ({ node, komodorUid }) => {
  const metrics = useMetricsData({ nodeName: node.node, komodorUid });

  const eventsData = useMemo<EventData[]>(() => {
    const eventsTimestamps = node.nodeVictims.reduce<number[]>(
      (acc, cur) => [...acc, ...cur.victimTimestampsSec],
      []
    );

    return eventsTimestamps.map((timestampSec) => ({
      time: timestampSec * 1000,
      highlight: false,
      color: muiColors.gray[500],
      icon: AlertTriangle16,
    }));
  }, [node.nodeVictims]);

  return (
    <Container>
      <Graph data={metrics ?? []} events={eventsData} />
      <WorkloadsTable data={node.nodeVictims} />
    </Container>
  );
};
