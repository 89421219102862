import React, { useMemo } from "react";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";
import LivenessProbIcon from "../../shared/icons/liveness-probe-illustration.svg";
import { DiagramImg } from "../../../violationDrawerStyles";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { CheckType } from "@/generated/reliabilityApi";

export const LivenessProbeMissing: React.FC = () => {
  const { data } = useViolation();

  const {
    supportingData,
    headerProps,
    summaryProps,
    dependentByViolationsIds,
  } = useGetCommonDrawerProps(data?.data.violation);

  const { containerNames } =
    supportingData?.[CheckType.LivenessProbeMissing] || {};

  const whatDescription = useMemo(
    () => getWhatDescription(containerNames ?? []),
    [containerNames]
  );

  if (!data?.data.violation || !containerNames?.length) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {whatDescription}
              <ViolationsTableByIds
                title="Runtime impact"
                ids={dependentByViolationsIds}
                violationTableType={ViolationsTableType.riskAssessment}
              />
            </>
          }
          whyDescription={
            <>
              {getWhyDescription()}
              <DiagramImg src={LivenessProbIcon} />
            </>
          }
          howDescription={howDescription()}
        />
      }
    />
  );
};
