import React from "react";
import styled from "styled-components";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import { useGetCertificateData } from "./useGetCertificateData";
import {
  useCertificateRelatedResourceColumns,
  useCertificateRelatedResourceEmptyColumns,
  useCertificateRelatedResourceRows,
} from "./CertificateRelatedResourceTableHooks";

import { CertificateExpirationData } from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { generateEmptyRows } from "@/shared/utils/tableUtils";
import { CertificateFullRelatedResources } from "@/generated/addonsApi";

const {
  violationDrawer: {
    certificateExpiration: {
      certificateRelatedResourcesTable: relatedResourcesTableAriaLabel,
    },
  },
} = reliabilityArialLabels;

const StyledDataGrid = styled(DataGridPro)`
  && {
    background-color: #fff;
  }
`;

const Container = styled.div`
  padding: 16px;
`;

interface Props {
  certificateData: CertificateExpirationData;
}

export const CertificateRelatedResourcesTable: React.FC<Props> = ({
  certificateData,
}) => {
  const { certificateUid, certificateName } = certificateData;
  const { data, isLoading } = useGetCertificateData(
    certificateUid,
    certificateName
  );

  const columns = useCertificateRelatedResourceColumns();
  const emptyColumns = useCertificateRelatedResourceEmptyColumns();

  const columnsToUse = isLoading ? emptyColumns : columns;

  const rows = useCertificateRelatedResourceRows(
    data?.data.object.relatedResources
  );
  const emptyRows = generateEmptyRows(2);

  const rowsToUse = isLoading ? emptyRows : rows;

  return (
    <Container>
      <StyledDataGrid
        aria-label={relatedResourcesTableAriaLabel}
        rows={rowsToUse as CertificateFullRelatedResources[]}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        hideFooter
      />
    </Container>
  );
};
