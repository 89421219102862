import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Title = styled.h4`
  color: ${muiColors.gray[800]};
  font-size: 18px;
  font-weight: 700;
  margin-block: 0;
`;

type Props = {
  title: string;
  tooltipText: string;
};

export const SectionTitle: React.FC<Props> = ({ title, tooltipText }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Tooltip title={tooltipText} placement="top">
        <HelpOutline fontSize="small" color="action" />
      </Tooltip>
    </Container>
  );
};
