import { differenceBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useInterval } from "../../../components/common/useInterval";
import { KubernetesNamespaceResourceKind } from "../../../components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { INSPECTION_NAMESPACES_PARAM_KEY } from "../../config/urlSearchParamsKeys";

import useAtmListResources, {
  AtmResourceListResponseType,
} from "./requests/useAtmListResources";
import { ListResourcesResult } from "./useAtmResourcesListLiveResult";
import { AtmOutputType, Status } from "./useAtmRequest";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const InitIntervalMilliseconds = 500;

export type UseNamespaceAtmParams = {
  agentId: string;
  cluster: string;
  refetchUntilCompletion?: boolean;
};

const useNamespacesAtm = ({
  agentId,
  cluster,
  refetchUntilCompletion,
}: UseNamespaceAtmParams): ListResourcesResult => {
  const [namespacesResponse, setNamespacesResponse] =
    useState<AtmResourceListResponseType>();

  const handleResponse = useCallback(
    (responseJSON: AtmResourceListResponseType) => {
      setNamespacesResponse((prevState) => {
        if (
          differenceBy(responseJSON.data, prevState?.data ?? [], (n) => n.name)
            .length > 0
        ) {
          return responseJSON;
        }
        return prevState;
      });
    },
    []
  );
  const { execute, status, resetState, errorMessage, isFetching } =
    useAtmListResources(
      agentId,
      cluster,
      KubernetesNamespaceResourceKind,
      AtmOutputType.Wide,
      handleResponse,
      null,
      []
    );

  const resetStateCallback = useCallback(() => {
    resetState();
    setNamespacesResponse(undefined);
  }, [resetState]);

  useEffect(() => {
    resetStateCallback();
  }, [cluster, resetStateCallback]);
  useEffect(() => {
    if (status === Status.Initializing) {
      execute();
    }
  }, [execute, status]);

  const shouldRefetch = useMemo(() => {
    if (errorMessage) return true;
    return !!(
      refetchUntilCompletion &&
      !isFetching &&
      namespacesResponse === undefined
    );
  }, [errorMessage, isFetching, namespacesResponse, refetchUntilCompletion]);

  useInterval(execute, shouldRefetch ? InitIntervalMilliseconds : null);

  return {
    result: namespacesResponse,
    status,
    refresh: resetStateCallback,
    errorMessage,
  };
};

export const useNamespacesFilter = (
  isResourceNamespaced: boolean
): string[] | undefined => {
  const [filterNamespacesParam] = useStringifiedStateInSearchParams<string[]>(
    INSPECTION_NAMESPACES_PARAM_KEY
  );
  return useMemo(() => {
    if (!isResourceNamespaced) return undefined;
    if (filterNamespacesParam?.length) return filterNamespacesParam;
    return undefined;
  }, [isResourceNamespaced, filterNamespacesParam]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useNamespacesAtm;
