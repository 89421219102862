import React, { memo } from "react";
import styled from "styled-components";

import { useGetGroups } from "../../../../../shared/hooks/reliability-api/groups/useGetGroups";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import {
  Severity,
  ImpactGroupIdentifier,
  ViolationCountBy,
  ImpactGroupType,
  ViolationStatus,
} from "../../../../../generated/reliabilityApi";
import { useStoreIsFetchingForReport } from "../../useReportClusterOverviewLoadingTime";

import { ImpactGroup, ImpactGroupLoader } from "./ImpactGroup";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px 16px;
`;

const ViolationsLoader = memo(() => (
  <Container
    aria-label={AriaLabels.ClusterOverview.ReliabilitySection.Violations.Loader}
  >
    {new Array(3).fill(null).map((_, i) => (
      <ImpactGroupLoader key={i} />
    ))}
  </Container>
));

const groupsToDisplay = [
  ImpactGroupIdentifier.NodePressure,
  ImpactGroupIdentifier.DegradedService,
  ImpactGroupIdentifier.EolDeprecationOutdated,
];

export const Violations: React.FC<{
  clusters: string[];
  shouldShowLoader?: boolean;
}> = ({ clusters, shouldShowLoader }) => {
  const { data, isLoading } = useGetGroups({
    clusterName: clusters,
    groupViolationsBy: "impact-group",
    impactGroupType: [ImpactGroupType.Dynamic],
    breakdownFactors: [ViolationCountBy.Severity, ViolationCountBy.CheckType],
    status: [ViolationStatus.Open, ViolationStatus.Confirmed],
  });
  useStoreIsFetchingForReport("isFetchingReliabilityViolations", isLoading);

  const impactGroups = data?.data.groups;
  groupsToDisplay.forEach((groupId) => {
    if (!impactGroups?.find((group) => group.id === groupId)) {
      impactGroups?.push({
        id: groupId,
        impactDataAggregations: [],
        breakdowns: {
          [ViolationCountBy.Severity]: {
            [Severity.Low]: 0,
            [Severity.Medium]: 0,
            [Severity.High]: 0,
          },
          [ViolationCountBy.CheckType]: {},
        },
      });
    }
  });

  if (isLoading || shouldShowLoader) {
    return <ViolationsLoader />;
  }

  return (
    <Container>
      {impactGroups?.map((group) => (
        <ImpactGroup group={group} clusters={clusters} />
      ))}
    </Container>
  );
};
