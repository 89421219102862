import React from "react";
import styled from "styled-components";

import { darkGray, gray10 } from "../../../Colors";
import x from "../assets/x.svg";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { lightStyle, titleStyle } from "../monitorsConfiguration/common/styles";

const Container = styled("div")<{ showInfo?: boolean }>`
  display: grid;
  padding: 1rem 2rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  ${({ showInfo }) =>
    showInfo
      ? 'grid-template-areas:"info close" "title ."'
      : 'grid-template-areas:". ." "title close"'};
`;

const Info = styled.span`
  grid-area: info;
  ${lightStyle};
  white-space: break-spaces;
  color: ${gray10};
`;

const Title = styled.div`
  grid-area: title;
  ${titleStyle};
  line-height: 28px;
  color: ${darkGray};
`;

export const CloseIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  grid-area: close;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
  background-position: center;
  background-image: url(${x});
  background-repeat: no-repeat;
  cursor: pointer;
`;
const Header: React.FC<{
  title: string;
  closeModalCallback: () => void;
  currentStepNumber?: number;
  totalNumberOfSteps?: number;
  headerTitle?: string;
}> = ({
  title,
  closeModalCallback,
  currentStepNumber,
  totalNumberOfSteps,
  headerTitle = "Activate Workflow",
}) => {
  return (
    <Container showInfo={currentStepNumber !== undefined}>
      {currentStepNumber !== undefined && totalNumberOfSteps !== undefined && (
        <Info>{`${headerTitle} •  Step ${
          currentStepNumber + 1
        } of ${totalNumberOfSteps}`}</Info>
      )}
      <CloseIcon
        onClick={() => {
          closeModalCallback();
          dispatchEvent(
            AnalyticEvents.WorkflowsView.Configuration_Exit_Clicked,
            {
              stepTitle: title,
            }
          );
        }}
      />
      <Title>{title}</Title>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Header;
