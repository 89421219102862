export const explanationTitle1 = "What it means";
export const increasedExplanationBlock1 =
  "There has been a significant increase in the number of availability issues in the past 7 days compared to the previous 7 days. This suggests that there may be underlying issues or instability within one or more services that need investigation and resolution.";

export const decreasedExplanationBlock1 =
  "There has been a significant decrease in the number of availability issues in the past 7 days compared to the previous 7 days. This suggests that the application as a whole is stabilizing, the underlying infrastructure is well-configured or that loads are being handled correctly.";

export const explanationTitle2 = "What happened";

type ExplanationBlock2Params = {
  isIncreased: boolean;
  previous: number;
  current: number;
  trendPercentage: string;
};
export const getExplanationBlock2 = ({
  previous,
  current,
  isIncreased,
  trendPercentage,
}: ExplanationBlock2Params) =>
  previous === 0
    ? `The total number of issues has increased from ${previous} to ${current}.`
    : `The total number of issues has ${
        isIncreased ? "increased" : "decreased"
      } by ${trendPercentage}%, from ${previous} to ${current}.`;

export const getIssuesNumDetected = (
  intervalDuration: string,
  totalIssues: number
) => `Issues detected in the past ${intervalDuration}: ${totalIssues}`;
