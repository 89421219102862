import { useMemo } from "react";

import { TrackedFile } from "../../../../../shared/types/git";

type TrackedFiles = TrackedFile[] | null | undefined;
const useFilteredTrackedFiles = (trackedFiles: TrackedFiles): TrackedFiles =>
  useMemo(() => {
    return trackedFiles;
  }, [trackedFiles]);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useFilteredTrackedFiles;
