import React from "react";

import { PageName, usePages } from "./pages";
import { AppBarLink } from "./AppBarLink";

const name = PageName.ServiceAccounts;

export const ServiceAccountsLink: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink text={name} route={page.url} indentationLevel={2} />
  ) : null;
};
