import React, { useCallback, useMemo } from "react";
import { Legend, Line } from "recharts";

import { AriaLabels } from "../../../shared/config/ariaLabels";
import { sharedDashedLineProps, sharedStraightLineProps } from "../constants";
import { MetricName, MetricsGraphContainerProps, MetricType } from "../types";
import {
  MetricsCapacityColor,
  MetricsLimitColor,
  MetricsRequestColor,
  MetricsUsageColor,
  MetricsRecommendationColor,
} from "../styles";
import {
  CPUMetricsChartTooltip,
  MemoryMetricsChartTooltip,
} from "../Tooltip/ResourceTooltip";
import {
  formatGraphCPUTick,
  formatGraphMemoryTick,
  getLineNames,
  shouldShowLine,
} from "../utils";

import { MetricsGraphContainer } from "./MetricsGraphContainer";

type PartialMetricsGraphContainerProps = Omit<
  MetricsGraphContainerProps,
  "tooltipContent" | "tickFormatter" | "label" | "ariaLabels" | "children"
>;
type ResourceGraphProps = PartialMetricsGraphContainerProps & {
  type: MetricType;
  legendContent?: React.ReactElement;
  children?: React.ReactNode;
};

export const ResourceGraph: React.FC<ResourceGraphProps> = ({
  type,
  memoryUnit = "MiB",
  legendContent,
  ...props
}) => {
  const isCpu = type === "cpu";

  const shouldShowRecommendation = useMemo(() => {
    if (!props.data) return false;
    const requestBytes = props.data[props.data.length - 1].requestBytes ?? 0;
    const recommendationBytes =
      props.data[props.data.length - 1].recommendationBytes ?? 0;

    return recommendationBytes < requestBytes;
  }, [props.data]);

  const lineNames: MetricName[] = useMemo(() => {
    const allLineNames = getLineNames(props);
    if (!shouldShowRecommendation) {
      return allLineNames.filter(
        (lineName) => lineName !== "recommendationBytes"
      );
    }
    return allLineNames;
  }, [props, shouldShowRecommendation]);

  const TooltipComponent = isCpu
    ? CPUMetricsChartTooltip
    : MemoryMetricsChartTooltip;

  const tickFormatter = useCallback(
    (tick: string) => {
      return isCpu
        ? formatGraphCPUTick(tick)
        : formatGraphMemoryTick(tick, memoryUnit);
    },
    [isCpu, memoryUnit]
  );

  const label = isCpu ? "MilliCores" : memoryUnit;
  const ariaLabelKey = isCpu ? "CpuGraph" : "MemoryGraph";

  return (
    <MetricsGraphContainer
      {...props}
      tooltipContent={
        <TooltipComponent
          unit={!isCpu ? memoryUnit : undefined}
          aggregationType={props.aggregationType}
          lineNames={lineNames}
          getCustomTooltipEntries={props.getCustomTooltipEntries}
        />
      }
      tickFormatter={tickFormatter}
      ariaLabels={AriaLabels.MetricsAvailability[ariaLabelKey]}
      label={label}
    >
      {shouldShowLine(lineNames, "usageBytes") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].UsageLine}
          stroke={MetricsUsageColor}
          dataKey="usageBytes"
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "capacityBytes") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].CapacityLine}
          stroke={MetricsCapacityColor}
          dataKey="capacityBytes"
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "limitBytes") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].LimitLine}
          dataKey="limitBytes"
          stroke={MetricsLimitColor}
          {...sharedDashedLineProps}
        />
      )}
      {shouldShowLine(lineNames, "requestBytes") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].RequestLine}
          dataKey="requestBytes"
          stroke={MetricsRequestColor}
          {...sharedDashedLineProps}
        />
      )}
      {shouldShowLine(lineNames, "p90") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].P90Line}
          dataKey="p90"
          stroke={MetricsUsageColor}
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "p95") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].P95Line}
          dataKey="p95"
          stroke={MetricsUsageColor}
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "p99") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].P99Line}
          dataKey="p99"
          stroke={MetricsUsageColor}
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "max") && (
        <Line
          aria-label={AriaLabels.MetricsAvailability[ariaLabelKey].MaxLine}
          dataKey="max"
          stroke={MetricsUsageColor}
          {...sharedStraightLineProps}
        />
      )}
      {shouldShowLine(lineNames, "recommendationBytes") &&
        shouldShowRecommendation && (
          <Line
            aria-label={
              AriaLabels.MetricsAvailability[ariaLabelKey].RecommendationLine
            }
            dataKey="recommendationBytes"
            stroke={MetricsRecommendationColor}
            {...sharedStraightLineProps}
          />
        )}
      {legendContent && <Legend content={legendContent} />}
      {props.children}
    </MetricsGraphContainer>
  );
};
