import { useState, useEffect } from "react";

import { useDesktopAgentsStatusContext } from "../desktopAgentsProvider/desktopAgentsStatusesProvider";
import { AgentStatus } from "../getClusterStatus";
import { useKomodorServices } from "../../../hooks/useKomodorServices";

export const useShouldShowChooseClusters = (): boolean => {
  const agentRunningStatus = useDesktopAgentsStatusContext();
  const [shouldShowChooseClusters, setShouldShowChooseClusters] =
    useState(true);
  const services = useKomodorServices().services;

  useEffect(() => {
    if (!agentRunningStatus) return;
    const statuses = Object.values(agentRunningStatus);
    if (!statuses.length) return;
    const allAgentsFailed = statuses.every((status) => {
      return status === AgentStatus.FAILED;
    });
    const atLeastOneAgentConnected = statuses.some((status) => {
      return status === AgentStatus.CONNECTED;
    });
    if (allAgentsFailed || (atLeastOneAgentConnected && services?.length)) {
      setShouldShowChooseClusters(false);
    }
  }, [agentRunningStatus, services?.length]);

  return shouldShowChooseClusters;
};
