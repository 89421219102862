import React from "react";
import styled from "styled-components";

import { AllocationFilters } from "../AllocationPage/AllocationFilters/AllocationFilters";
import { ScopeFilterContainer } from "../shared/filters/ScopeFilterContainer";
import { useCostOptimizationDataInUrl } from "../../hooks/persistentStateHooks";
import { StyledFiltersContainer } from "../styles/CostOptimizationStyledComponents";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 63px;
`;

export const TopPageSection: React.FC = () => {
  const [{ tab }] = useCostOptimizationDataInUrl();
  const selectors = tab === "allocation" ? <AllocationFilters /> : null;

  return (
    <StyledContainer>
      <StyledFiltersContainer>
        <ScopeFilterContainer />
        {selectors}
      </StyledFiltersContainer>
    </StyledContainer>
  );
};
