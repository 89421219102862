import { parseISO } from "date-fns";

import { Event_Topology } from "../../../generated/graphql";
import { extractServiceName } from "../../../shared/utils/serviceHelpers";

import TopologyScatterShape from "./shapes/topology.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort } from "./groupEvents";

import EventGroup from ".";

export const kindToType = new Map([
  ["new_service", "Service Discovered"],
  ["new_communication", "Communication Detected"],
  ["new_job", "Job created"],
  ["new_cronjob", "CronJob created"],
]);

export const topologyColor = "#666";

export const groupTopologyEvents = (events: Event_Topology[]): EventGroup[] =>
  mapAndSort(events, (e, serviceId) => {
    const type = kindToType.get(e.kind) ?? "";
    const eventTime = parseISO(e.eventTime);
    const serviceName = extractServiceName(serviceId);
    return {
      backgroundColor: "#ecebeb",
      fillColor: topologyColor,
      icon: TopologyScatterShape,
      eventTime,
      events: [
        {
          id: e.id,
          eventTime,
          type,
          serviceId,
          clusterName: e.clusterName,
          namespace: e.namespace,
        },
      ],
      id: e.id,
      type,
      summary: `Discovered ${serviceName}`,
      startTime: eventTime,
      endTime: eventTime,
      details: "",
      status: "Discovered",
      serviceId,
      serviceName,
    };
  });
