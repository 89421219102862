import { Divider, Tabs, TabPanel } from "@komodorio/design-system/deprecated";
import { HelmChartSummary, ResourceTableModelRow } from "komodor-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { SELECTED_CHART_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { LinesLoader } from "../../common/loaders/Line";
import ResourceListTable from "../../common/ResourceListTable/ResourceListTable";
import { KubernetesResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import { useInspectionParams } from "../InspectionViews/common";
import ResourceListErrorState from "../ResourceListErrorState";
import { useFilteredClusters } from "../filters/useFilteredClusters";
import { Direction } from "../../common/ProcessList/SortTitle";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { useInterval } from "../../common/useInterval";
import { InspectionFilters } from "../filters/InspectionFilters";
import { AgentNotSupported } from "../AgentNotSupported";
import { useDDRumViewAndTimingListener } from "../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../shared/constants/datadog";

import HelmReposTab from "./tabs/HelmReposTab";
import { useParseHelmChartsResponse } from "./hooks/useHelmCharts";
import ChartReleaseDrawer from "./drawers/ChartReleaseDrawer";
import { invalidateChartRevisionsCache } from "./utils";
import { useHelmChartsFromAgent } from "./hooks/useHelmChartsFromAgent";
import { useSetInitialNamespace } from "./hooks/useSetInitialNamespace";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useNamespacesFilter } from "@/components/Inspection/InspectionViews/inpectionViewsHooks";
import { useIsAgentSupported } from "@/components/Inspection/Helm/hooks/useIsAgentSupported";
import { useActiveAgents } from "@/shared/hooks/useAgents";
import { AgentInfoToDefaultClusterName } from "@/components/Inspection/utils/useDefaultClusterForInspection";
import { useChartsByCluster } from "@/components/Inspection/Helm/hooks/useChartsByCluster";
import { UpdateHelmMessage } from "@/components/Inspection/Helm/UpdateHelmMessage";
import { useIsAwaitingNamespacesOnClusterChange } from "@/components/Inspection/Helm/hooks/useIsAwaitingNamespacesOnClusterChange";
import useNamespacesAtm from "@/shared/hooks/ATM/useNamespacesAtm";

export const Container = styled.div`
  margin: 1.5rem;
  margin-top: 1rem;
`;

enum HelmTabs {
  Releases = "Releases",
  Repos = "Repositories",
}

enum HelmTabsIndex {
  Releases = 0,
  Repos = 1,
}

const REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS = 600;

export const HelmCharts: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  const [currentTab, setCurrentTab] = useState(HelmTabsIndex.Releases);
  const [selectedNamespaces] = useNamespacesFilter();
  const [showUpdateMessage, setUpdateMessage] = useState(false);
  const { cluster, onClusterChange, agentId } =
    useInspectionParams(kubernetesResource);
  const { filteredClusterSuggestions } = useFilteredClusters(cluster ?? "");
  const { result: namespacesData } = useNamespacesAtm({
    agentId: agentId ?? "",
    cluster: cluster ?? "",
    refetchUntilCompletion: true,
  });
  const namespaces = useMemo(() => {
    return namespacesData?.data.map((n) => n.name).sort();
  }, [namespacesData?.data]);

  const lastRequestedNamespace = useRef<string | undefined>(undefined);
  const lastRequestAgentId = useRef<string | undefined>(agentId);
  const activeAgents = useActiveAgents() as AgentInfoToDefaultClusterName[];

  const { canManageHelm } = useHasPermissions();
  const { isAwaitingNamespacesChange, onClusterChangeCb } =
    useIsAwaitingNamespacesOnClusterChange(namespaces);

  const { initialNamespace, resetInitialNamespace } = useSetInitialNamespace(
    namespaces ?? [],
    isAwaitingNamespacesChange
  );

  const onClusterChangeEvent = useCallback(
    (cluster: string) => {
      onClusterChangeCb();
      resetInitialNamespace();
      onClusterChange(cluster);
    },
    [onClusterChange, resetInitialNamespace, onClusterChangeCb]
  );

  const [hasAllResults, setHasAllResults] = useState(false);

  // current support in hasura-actions is for a single namespace
  const namespaceToUse =
    selectedNamespaces.length === 1 ? selectedNamespaces[0] : undefined;

  const { execute, isFetching, data, errorMessage } = useHelmChartsFromAgent({
    cluster: cluster ?? "",
    agentId: agentId ?? "",
    namespace: namespaceToUse,
  });

  const resourceList = useParseHelmChartsResponse(
    errorMessage,
    isFetching,
    data
  );

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.getInspectionViewPage(
      kubernetesResource.NameInK8S
    ),
    addTimingParams: {
      enable: !isFetching,
    },
  });

  useEffect(() => {
    if (!namespaces || !initialNamespace || isAwaitingNamespacesChange) {
      return;
    }
    if (lastRequestedNamespace.current === namespaceToUse) {
      return;
    }

    const hasNamespaceResults = data?.some(
      (r) => r.namespace === namespaceToUse
    );

    const responseIsPerAgent = agentId === lastRequestAgentId.current;
    if (hasNamespaceResults && responseIsPerAgent) {
      return;
    }

    execute();
    setHasAllResults(namespaceToUse === undefined);
    lastRequestedNamespace.current = namespaceToUse;
    lastRequestAgentId.current = agentId;
  }, [
    execute,
    initialNamespace,
    resourceList.emptyResult,
    hasAllResults,
    namespaceToUse,
    data,
    agentId,
    namespaces,
    isAwaitingNamespacesChange,
  ]);

  const [showReleaseDrawer, setShowReleaseDrawer] = useState(false);
  const [selectedChart, setSelectedChart] = useState<HelmChartSummary>();

  const clusters = useMemo(() => {
    return activeAgents.map((agent) => ({
      label: agent.clusterName,
      value: agent.clusterName,
    }));
  }, [activeAgents]);

  useInterval(
    invalidateChartRevisionsCache,
    REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS * 1_000
  );

  const onRowClick = useCallback(
    (row: ResourceTableModelRow) => {
      const chart = data?.find((r) => r.secretId === row.secretId);
      if (chart) {
        setSelectedChart(chart);
        setShowReleaseDrawer(true);
      }
    },
    [data]
  );
  const [, setChartId] = useStateInSearchParams(SELECTED_CHART_PARAM_KEY);
  const closeDrawer = useCallback(() => {
    setSelectedChart(undefined);
    setShowReleaseDrawer(false);
    setChartId(null);
  }, [setChartId]);

  const chartsByCluster = useChartsByCluster({
    cluster: cluster ?? "",
    kubernetesResource,
    resourceList,
  });

  const isAgentSupported = useIsAgentSupported(activeAgents, cluster);

  const tabs = useMemo(() => {
    const presentTabs = [
      {
        label: HelmTabs.Releases,
      },
    ];
    if (canManageHelm) {
      presentTabs.push({
        label: HelmTabs.Repos,
      });
    }
    return presentTabs;
  }, [canManageHelm]);

  const isContentLoading =
    (!initialNamespace && namespaces === undefined) || isFetching;

  return (
    <Container>
      <Tabs
        value={currentTab}
        onChange={(index) => {
          setCurrentTab(index);
        }}
        tabs={tabs}
      />
      <Divider />
      <TabPanel index={HelmTabsIndex.Releases} value={currentTab}>
        <br />
        {cluster && (
          <InspectionFilters
            kubernetesResource={kubernetesResource}
            resourceList={resourceList}
            selectedCluster={cluster}
            onClusterChange={onClusterChangeEvent}
            namespaces={namespaces}
            clustersOptions={filteredClusterSuggestions}
          />
        )}
        <Divider />
        <br />
        {resourceList.errorMessage ? (
          <ResourceListErrorState
            resourceName={kubernetesResource.NameInK8S}
            errorMessage={resourceList.errorMessage}
            refreshCallback={execute}
          />
        ) : isAgentSupported ? (
          <ResourceListTable
            rows={chartsByCluster}
            resourceType={kubernetesResource}
            cluster={cluster ?? ""}
            refreshResults={() => null}
            overrideRowClick={onRowClick}
            initialSortOrder={{ column: "lastUpdated", order: Direction.down }}
            loadingProps={{
              isLoading: isContentLoading,
              loaderElement: <LinesLoader marginTop="100px" />,
            }}
          />
        ) : (
          <AgentNotSupported
            onUpgradeClicked={() => setUpdateMessage(true)}
            title="Komodor agent on this cluster does not support Helm."
          />
        )}
        {selectedChart && (
          <ChartReleaseDrawer
            open={showReleaseDrawer}
            chartSummary={selectedChart}
            onClose={closeDrawer}
            agentId={agentId ?? ""}
          />
        )}
        {showUpdateMessage && (
          <UpdateHelmMessage setUpdateMessage={setUpdateMessage} />
        )}
      </TabPanel>
      <TabPanel index={HelmTabsIndex.Repos} value={currentTab}>
        {canManageHelm && (
          <HelmReposTab clusters={clusters.map((el) => el.value)} />
        )}
      </TabPanel>
    </Container>
  );
};
