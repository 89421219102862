import React from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { AriaLabels } from "../../shared/config/ariaLabels";
import { ZIndex } from "../../constants/zIndex";

export interface MetricsLabelProps {
  labelText: string;
  tooltipText?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MetricsLabel: React.FC<MetricsLabelProps> = ({
  labelText,
  tooltipText,
}) => {
  return (
    <Container aria-label={AriaLabels.Metrics.Label}>
      <Typography variant="body3" color={muiColors.gray[500]}>
        {labelText}
      </Typography>
      {tooltipText && (
        <Tooltip
          placement={"top"}
          arrow={false}
          title={tooltipText}
          PopperProps={{
            style: { zIndex: ZIndex.AboveModal },
          }}
        >
          <InfoOutlined
            sx={{
              color: muiColors.gray[500],
              fontSize: "16px",
            }}
          />
        </Tooltip>
      )}
    </Container>
  );
};
