import { useCallback } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { Operator } from "@/generated/addonsApi";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useSetFiltersDataFromOptions = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();

  return useCallback(
    (
      filterCategory: string,
      selectedOptions: MuiSelectionOption<string>[] | undefined
    ) => {
      if (!selectedOptions) return;

      const values = selectedOptions.map((option) => option.value) ?? [];
      if (values.length === 0) {
        dataInUrl.filters && delete dataInUrl.filters[filterCategory];
        setDataInUrl({
          filters: dataInUrl.filters,
        });
        return;
      }

      setDataInUrl({
        filters: {
          ...dataInUrl.filters,
          [filterCategory]: [{ values, operator: Operator.In }],
        },
      });
    },
    [dataInUrl.filters, setDataInUrl]
  );
};
