import { useEffect, useMemo } from "react";
import { uniq } from "lodash";
import { Pod } from "kubernetes-types/core/v1";

import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { generateUid } from "../../shared/utils/generateUid";
import { useDefaultClusterForInspection } from "../Inspection/utils/useDefaultClusterForInspection";
import {
  EVENTS_VIEW_CLUSTER_PARAM_KEY,
  EVENTS_VIEW_PODS_LABEL_PARAM_KEY,
} from "../../shared/config/urlSearchParamsKeys";
import { useGetPodsState } from "../../shared/hooks/resources-api/client/useGetPodsState";

import { useSelectedPodsForEvents } from "./PodsSelector/SelectedPodsProvider";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

const useGetPodsFromLabel = (): void => {
  const { selectPodsByLabelKeyword } = useOverridableFlags();
  const [label, setLabel] = useStateInSearchParams(
    EVENTS_VIEW_PODS_LABEL_PARAM_KEY
  );
  const { selectedPodUidsForEvents, setSelectedPodUidsForEvents } =
    useSelectedPodsForEvents();

  const defaultClusterName = useDefaultClusterForInspection();
  const [urlClusterName] = useStateInSearchParams(
    EVENTS_VIEW_CLUSTER_PARAM_KEY
  );

  const clusterName = useMemo(
    () => urlClusterName ?? defaultClusterName,
    [defaultClusterName, urlClusterName]
  );
  const labelParts = useMemo(() => label?.split("=") ?? [], [label]);

  const enabled: boolean =
    !!selectPodsByLabelKeyword && labelParts.length >= 2 && !!clusterName;

  const { data } = useGetPodsState(
    {
      clusterName: (clusterName || defaultClusterName) ?? "",
      labels: { [labelParts[0]]: labelParts[1] },
      labelSelector: `${labelParts[0]}=${labelParts[1]}`,
      fields: ["json"],
    },
    enabled
  );

  const result = data?.data?.json as Pod[] | undefined;

  useEffect(() => {
    if (selectPodsByLabelKeyword && result) {
      const podNamesFromQuery =
        result.map((pod) =>
          generateUid({
            name: pod.metadata?.name ?? "",
            namespace: pod.metadata?.namespace ?? "",
            cluster: clusterName ?? "",
          })
        ) ?? [];
      const pods = uniq([
        ...(selectedPodUidsForEvents ?? []),
        ...podNamesFromQuery,
      ]);
      setLabel(null);
      if (podNamesFromQuery.length) setSelectedPodUidsForEvents(pods);
    }
  }, [
    clusterName,
    result,
    selectPodsByLabelKeyword,
    selectedPodUidsForEvents,
    setLabel,
    setSelectedPodUidsForEvents,
  ]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useGetPodsFromLabel;
