import React from "react";

import { DefaultAuditDetails } from "@/components/Audit/AuditDetailsCommon";
import { AuditData } from "@/components/Audit/types";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useGetRbacRoleById } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoleById";

const rejectRole = (roleNames: string[], roleName?: string) =>
  roleNames.filter((r) => r !== roleName);

const addRole = (roleNames: string[], roleName?: string) => {
  if (!roleName) {
    return roleNames;
  }
  return [...roleNames, roleName];
};

const addDetailsToData = ({
  data,
  roleName,
  currentUser,
  roleNames,
}: {
  data: AuditData;
  roleName?: string;
  roleNames: string[];
  currentUser?: string;
}) => {
  const newDetails = {
    user: currentUser,
    roles: roleNames,
  };

  const oldDetails = {
    user: currentUser,
    roles: data?.details?.new
      ? rejectRole(roleNames, roleName)
      : addRole(roleNames, roleName),
  };

  return {
    ...data,
    details: { new: newDetails, old: oldDetails },
  } as unknown as AuditData;
};

const Base: React.FC<{ data: AuditData }> = ({ data }) => {
  const userId = (data?.details?.new?.userId ||
    data?.details?.old?.userId) as string;
  const { data: user } = useGetUserById({ id: userId });
  const { email, rbacRoles: userRbacRoles } = user ?? {};

  const roleNames = (userRbacRoles || []).map((r) => r.name);

  const roleId = (data?.details?.new?.roleId ||
    data?.details?.old?.roleId) as string;
  const { data: role } = useGetRbacRoleById({ id: roleId });
  const { name } = role ?? {};

  const tmpData = addDetailsToData({
    data,
    roleName: name,
    roleNames,
    currentUser: email,
  });

  return <DefaultAuditDetails data={tmpData} />;
};

export const RbacUserRoleAuditDetails = React.memo(Base);
