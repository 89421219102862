import { useEffect, useRef } from "react";
import { QueryObserverBaseResult } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";

import { KOMODOR_LOADING_TIME } from "../../constants/datadog";

type ReportLoadingTimeToDatadogParams = {
  result: QueryObserverBaseResult;
  viewName: string;
  customTimingName?: string;
  customTimingTime?: number;
};
export const useReportDDRumWithReactQuery = ({
  viewName,
  result,
  customTimingName,
  customTimingTime,
}: ReportLoadingTimeToDatadogParams) => {
  const hasStartedView = useRef(false);
  const { isFetching, fetchStatus } = result;
  useEffect(() => {
    if (isFetching) {
      if (fetchStatus !== "idle" && !hasStartedView.current) {
        hasStartedView.current = true;
        datadogRum.startView(viewName);
      }
    } else {
      if (hasStartedView.current) {
        hasStartedView.current = false;
        datadogRum.addTiming(
          customTimingName ?? KOMODOR_LOADING_TIME,
          customTimingTime
        );
      }
    }
  }, [customTimingName, customTimingTime, fetchStatus, isFetching, viewName]);
};
