import { useMemo } from "react";
import { parseISO } from "date-fns";

import { ResourceListResponse } from "../../InspectionViews/common";

import useInspectionFilters from "@/components/Inspection/filters/useInspectionFilters";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { KubernetesResource } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

type UseChartsByClusterParams = {
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  cluster: string;
};

export const useChartsByCluster = ({
  cluster,
  kubernetesResource,
  resourceList,
}: UseChartsByClusterParams) => {
  const { formatToParts } = useDateFormatter({
    month: "2-digit",
    day: "2-digit",
  });

  const filteredTableResults = useInspectionFilters(
    resourceList,
    kubernetesResource
  );

  return useMemo(() => {
    return filteredTableResults
      .filter((row) => row?.cluster === cluster)
      .map((row) => {
        const dateParts = Object.fromEntries(
          formatToParts(parseISO(row?.lastUpdated ?? "")).map(
            ({ type, value }) => [type, value]
          )
        );
        const { day, month, year, hour, minute, second, dayPeriod } = dateParts;
        return {
          ...row,
          lastUpdated: `${year}-${month}-${day}, ${hour}:${minute}:${second} ${dayPeriod}`,
        };
      });
  }, [cluster, filteredTableResults, formatToParts]);
};
