import Paper from "@mui/material/Paper";
import styled from "styled-components";

import { LinesLoader } from "../../../../../../common/loaders/Line";

export const StyledPaper = styled(Paper).attrs({
  elevation: 0,
  variant: "outlined",
})`
  && {
    padding: 16px;
    box-shadow: none;
  }
`;

export const StyledLoader = styled(LinesLoader).attrs({ marginTop: "0" })``;

export const gap = `16px`;
