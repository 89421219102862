import React from "react";
import { palette, theme } from "@komodorio/design-system";
import { Tag, Typography } from "@komodorio/design-system/deprecated";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const greenTag = {
  bg: theme.background.bgGreenLight,
  fg: palette.green[900],
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const orangeTag = {
  bg: theme.background.bgYellowLight,
  fg: palette.orange[800],
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const pinkTag = {
  bg: theme.background.bgPinkLight,
  fg: palette.pink[700],
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const grayTag = {
  bg: theme.background.bgGray,
  fg: theme.foreground.fgPrimary,
};
const mapStatusToColor: {
  [key: string]: {
    bg: string | undefined;
    fg: string | undefined;
    border?: string | undefined;
  };
} = {
  RUNNING: greenTag,
  COMPLETED: greenTag,
  SUCCEEDED: greenTag,
  READY: greenTag,
  TRUE: greenTag,
  PENDING: orangeTag,
  WAITING: orangeTag,
  UNKNOWN: orangeTag,
  WARNING: pinkTag,
  ERROR: pinkTag,
  FAILED: pinkTag,
  NOTREADY: pinkTag,
  "NOT READY": pinkTag,
  FALSE: pinkTag,
  TERMINATED: grayTag,
  NORMAL: grayTag,
};

interface IProps {
  value: string | number | undefined;
  mapOverride?: {
    [key: string]: {
      bg: string | undefined;
      fg: string | undefined;
      border?: string | undefined;
    };
  };
}

export const StatusTag: React.FC<IProps> = ({ value, mapOverride = {} }) => {
  if (!value) return null;
  const status = String(value).toUpperCase();
  const map = { ...mapStatusToColor, ...mapOverride };
  const color = map[status] ?? grayTag;
  return (
    <Tag
      color={color.bg}
      aria-label="status tag"
      style={{ borderColor: color.border ?? color.bg }}
    >
      <Typography variant="uppercase" color={color.fg}>
        {status}
      </Typography>
    </Tag>
  );
};
