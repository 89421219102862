import React, { useState } from "react";
import { NodeProps } from "react-flow-renderer";
import styled from "styled-components";
import { TooltipWrapper } from "react-tooltip";

import { GraphNodeTooltip } from "../ResourceView/tabs/DependenciesTab/GraphNodeTooltip/GraphNodeTooltip";
import { useOpenIssueByResource } from "../OpenIssues/useOpenIssuesByResource";

import { NodeSourceHandle } from "./NodeSourceHandle";
import { NodeTargetHandle } from "./NodeTargetHandle";
import { NodeData } from "./types";
import { ResourceNodeTitle } from "./ResourceNodeTitle";
import { NodeHealthIndicator } from "./NodeHealthIndicator";
import { dependenciesGraphAriaLabels } from "./constants";

const NodeContainer = styled.div<{
  isHighlighted?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "white" : "none"};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  border-radius: 4px;
  column-gap: 1rem;
  flex-direction: column;
  width: 226px;
  height: 96px;
  justify-content: space-between;
  cursor: default;
`;

const HealthContainer = styled.div`
  align-self: flex-start;
`;

export const CustomInputNode: React.FC<NodeProps<NodeData>> = ({ data }) => {
  const {
    resourceDetails: { isHealthy },
  } = data;

  const [, setIsTooltipOpen] = useState(false);

  const openIssue = useOpenIssueByResource(
    data?.cluster,
    data?.namespace,
    data?.kind || "",
    data.name,
    data.resourceDetails.resourceId
  );

  return (
    <>
      <TooltipWrapper tooltipId={`${data.resourceDetails.resourceId}`}>
        <NodeContainer
          aria-label={dependenciesGraphAriaLabels.graphNode}
          isHighlighted={data.isHighlighted}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          {data?.connectionPoints?.source && <NodeSourceHandle />}
          {data?.connectionPoints?.target && <NodeTargetHandle />}
          <ResourceNodeTitle
            cluster={data.cluster}
            name={data.name}
            namespace={data.namespace}
          />
          {isHealthy !== null && (
            <HealthContainer>
              <NodeHealthIndicator isHealthy={isHealthy} />
            </HealthContainer>
          )}
        </NodeContainer>
        {!data.isHighlighted && (
          <GraphNodeTooltip
            nodeData={data}
            openIssue={openIssue}
            tooltipId={`${data.resourceDetails.resourceId}`}
            setIsTooltipOpen={setIsTooltipOpen}
          />
        )}
      </TooltipWrapper>
    </>
  );
};
