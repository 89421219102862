import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

import { severitiesList } from "../policyDrawerConstants";

import { CheckType } from "@/generated/reliabilityApi";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";

export const useClearConfigurationControlsErrors = () => {
  const { clearErrors, formState } = useFormContext();
  return useCallback(
    (checkType: CheckType) => {
      const errorFieldsToClear = severitiesList.reduce<string[]>(
        (acc, severity) => {
          const errorIdentifiers = getConfigurationFieldNamesByCheckTypeAsList(
            checkType,
            severity
          );

          errorIdentifiers.forEach((errorFieldIdentifier) => {
            const currentError = get(formState.errors, errorFieldIdentifier);
            currentError && acc.push(errorFieldIdentifier);
          });

          return acc;
        },
        []
      );
      errorFieldsToClear.length > 0 && clearErrors(errorFieldsToClear);
    },
    [clearErrors, formState.errors]
  );
};
