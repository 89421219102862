import { useCallback, useMemo } from "react";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { GridSortDirection } from "@mui/x-data-grid/models/gridSortModel";

import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import {
  DEFAULT_PAGINATION_SIZE,
  DEFAULT_SORT_BY_DIRECTION,
  DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/constants/table";
import {
  AddonTableUrlsState,
  AddonUrlState,
} from "@/components/k8sAddons/types";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";

const flipSorting = (sort: GridSortDirection) =>
  sort === "asc" ? "desc" : "asc";

export const useTablePaginationInUrl = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrlAsJSON<AddonUrlState>(
    K8S_ADD_ONS_FILTERS_PARAM_KEY
  );
  const { defaultSortModel } = useAddonContext();

  const [defaultSortByField, defaultSortByDirection]: [
    string,
    GridSortDirection
  ] = [
    defaultSortModel?.field ?? DEFAULT_SORT_BY_FIELD,
    defaultSortModel?.sort ?? DEFAULT_SORT_BY_DIRECTION,
  ];

  const paginationModel: GridPaginationModel = {
    pageSize: dataInUrl.tableState?.pageSize ?? DEFAULT_PAGINATION_SIZE,
    page: dataInUrl.tableState?.page ?? 0,
  };

  const sortModel: GridSortModel = useMemo(
    () => [
      {
        field: dataInUrl.tableState?.sortBy ?? defaultSortByField,
        sort: dataInUrl.tableState?.sortOrder ?? defaultSortByDirection,
      },
    ],
    [
      dataInUrl.tableState?.sortBy,
      dataInUrl.tableState?.sortOrder,
      defaultSortByDirection,
      defaultSortByField,
    ]
  );

  const setPaginationModel = useCallback(
    (model: Partial<GridPaginationModel>) => {
      setDataInUrl({
        tableState: {
          ...dataInUrl.tableState,
          ...model,
        },
      });
    },
    [dataInUrl.tableState, setDataInUrl]
  );

  const setSortModel = useCallback(
    (model: GridSortModel) => {
      const sortBy = model[0]?.field ?? sortModel[0].field;
      const isCurrentField = model[0]?.field === sortModel[0].field;
      /* dataGrid's sorting is inconsistent, manually flip sorting */
      const sortOrder = isCurrentField
        ? flipSorting(sortModel[0].sort)
        : DEFAULT_SORT_BY_DIRECTION;

      setDataInUrl({
        tableState: {
          page: 0,
          pageSize: dataInUrl.tableState?.pageSize ?? DEFAULT_PAGINATION_SIZE,
          sortBy,
          sortOrder,
        } as AddonTableUrlsState,
      });
    },
    [dataInUrl.tableState?.pageSize, setDataInUrl, sortModel]
  );

  return { paginationModel, setPaginationModel, sortModel, setSortModel };
};
