import { useCallback } from "react";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { useUpdateViolation } from "../../../../../../shared/hooks/reliability-api/violations/useUpdateViolation";
import { useReportError } from "../../../../hooks/useReportError";
import {
  BasicViolation,
  GetViolationResponse,
  GetViolationsResponse,
  ViolationStatus,
} from "../../../../../../generated/reliabilityApi";
import { useViolationsRequestParams } from "../../../pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/useViolationsRequestParams";
import { GET_VIOLATIONS_PATH } from "../../../../../../shared/hooks/reliability-api/violations/useGetViolations";
import { GET_VIOLATION_DATA_PATH } from "../../../../../../shared/hooks/reliability-api/violations/useGetViolationData";
import { useCurrentDrawerState } from "../../../../../../shared/context/drawersStack/helpers";
import { ViolationsDrawerType } from "../../../../../../shared/store/drawersStackStore/types";
import { GET_VIOLATION_HISTORY_PATH } from "../../../../../../shared/hooks/reliability-api/violations/useGetViolationHistory";
import { useWorkspaceQueryKey } from "../../../../../../shared/hooks/workspaces-api/useWorkspaceQueryKey";

type UpdateViolationStatusParams = {
  violation: BasicViolation;
  newStatus?: ViolationStatus;
};

export const useUpdateViolationStatusAndCachedData = () => {
  const { mutateAsync } = useUpdateViolation();
  const updateGetViolationsCachedData = useUpdateViolationCachedData();
  const reportError = useReportError();

  return useCallback(
    async ({ violation, newStatus }: UpdateViolationStatusParams) => {
      const updatedViolation: BasicViolation = {
        ...violation,
        status: newStatus,
      };
      let error = false;
      try {
        await mutateAsync({
          id: violation.id,
          body: updatedViolation,
        });
        updateGetViolationsCachedData(updatedViolation);
      } catch (e) {
        error = true;
        reportError(e, "updateViolationStatus");
      }

      return { error };
    },
    [mutateAsync, reportError, updateGetViolationsCachedData]
  );
};

const useUpdateViolationStatus = () => {
  const queryClient = useQueryClient();
  const violationsRequestParams = useViolationsRequestParams();
  const keys = useWorkspaceQueryKey([
    GET_VIOLATIONS_PATH,
    violationsRequestParams,
  ]);

  return useCallback(
    (updatedViolation: BasicViolation) => {
      queryClient.setQueryData<UseMutationResult<GetViolationsResponse>>(
        keys,
        (prevData) => {
          if (!prevData?.data?.violations) return prevData;
          const newData: UseMutationResult<GetViolationsResponse> = {
            ...prevData,
            data: {
              ...prevData.data,
              violations: prevData.data.violations.map(
                (violation: BasicViolation) =>
                  violation.id === updatedViolation.id
                    ? {
                        ...updatedViolation,
                      }
                    : violation
              ),
            },
          };
          return newData;
        }
      );
    },
    [keys, queryClient]
  );
};

const useUpdateViolationCachedData = () => {
  const updateViolationStatus = useUpdateViolationStatus();
  const updateViolationDataStatus = useUpdateViolationDataStatus();
  const clearViolationHistory = useClearViolationHistory();

  return useCallback(
    (updatedViolation: BasicViolation) => {
      updateViolationStatus(updatedViolation);
      updateViolationDataStatus(updatedViolation);
      clearViolationHistory(updatedViolation.id);
    },
    [clearViolationHistory, updateViolationDataStatus, updateViolationStatus]
  );
};
const useUpdateViolationDataStatus = () => {
  const currentDrawerState = useCurrentDrawerState<ViolationsDrawerType>();

  const queryClient = useQueryClient();

  return useCallback(
    (updatedViolation: BasicViolation) => {
      queryClient.setQueryData<UseMutationResult<GetViolationResponse>>(
        [GET_VIOLATION_DATA_PATH, { enable: true, id: updatedViolation.id }],
        (prevData) => {
          if (!prevData?.data?.violation) return prevData;
          const newData: UseMutationResult<GetViolationResponse> = {
            ...prevData,
            data: {
              ...prevData.data,
              violation: {
                ...prevData.data.violation,
                status: updatedViolation.status,
              },
            },
          };
          return newData;
        }
      );
      // invalidate violations table query in the background if the drawer is open
      if (currentDrawerState) {
        queryClient.invalidateQueries([GET_VIOLATIONS_PATH]);
      }
    },
    [currentDrawerState, queryClient]
  );
};

const useClearViolationHistory = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (violationId: string) => {
      queryClient.invalidateQueries([
        GET_VIOLATION_HISTORY_PATH,
        { id: violationId },
      ]);
    },
    [queryClient]
  );
};
