import { replace, split } from "lodash";

export const INVALID_ACCOUNT_NAME_ERROR =
  "Invalid name (use up to 40 chars composed of a-z, 0-9, -)";

export const isAccountNameRegexValid = (name: string): boolean => {
  // check if pure numbers
  if (/^[0-9]{3,40}$/.test(name)) {
    return false;
  }
  return /^[a-z0-9-]{3,40}$/.test(name);
};

export const isAccountNameUsable = (
  name: string
): { usable: boolean; errorMessage: string } => {
  const normalized = normalizeName(name);
  if (!isAccountNameRegexValid(normalized)) {
    return { usable: false, errorMessage: INVALID_ACCOUNT_NAME_ERROR };
  }
  return { usable: true, errorMessage: "" };
};

export const normalizeName = (name: string): string => {
  let final = name;
  [
    [/\s/g, "-"],
    ["_", "-"],
    [".", "-"],
  ].forEach(([pattern, replacement]) => {
    final = replace(final, pattern, replacement as string);
  });
  return final.toLowerCase();
};

export const accountNameSuggestionsFromUserData = (
  email: string,
  name: string
): string[] => {
  const suggestions: string[] = [];
  const pushIfDef = (value?: string | null) =>
    value && suggestions.push(replace(value, ".", "-").trim());

  pushIfDef(name);
  pushIfDef(extractDomainNameFromEmail(email));
  pushIfDef(extractNameFromEmail(email));

  return suggestions;
};

// const accountNameWithSalt = (accountName: string) => {
//   const randDigitsCount = 4;
//   const randDigits = Math.floor(
//     Math.random() * 10 ** randDigitsCount
//   ).toString();
//   return `${accountName}-${randDigits}`;
// };

export const calcValidSuggestions = (email: string, name: string): string[] => {
  // filter for the suggs that pass regex validation
  const suggs = accountNameSuggestionsFromUserData(email, name)
    // replace bad chars with dashes
    .map((sugg) => normalizeName(sugg));

  return suggs.filter((sugg) => sugg !== null && isAccountNameRegexValid(sugg));
};

export const extractNameFromEmail = (email: string): string => {
  try {
    const name = email.split("@")[0];
    return name ? name.replace(".", "-").replace("+", "-") : "";
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `Got an error while trying to extract account name from email`,
      {
        email,
        e,
      }
    );
    return "";
  }
};

export const extractDomainNameFromEmail = (email: string): string | null => {
  const [, domainFromEmail] = split(email, "@");
  if (!domainFromEmail) {
    return null;
  }
  const [companyName] = split(domainFromEmail, ".");
  const blacklist = ["gmail", "hotmail", "outlook"];
  if (blacklist.includes(companyName)) {
    return null;
  }
  return companyName;
};
