import {
  buildKomodorUid,
  useUidFromKomodorUid,
} from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";

export const useGetMetricsUid = () => {
  const { metricsParams } = useRecommendationsModalContext();
  const komodorUid = buildKomodorUid({
    clusterName: metricsParams?.clusterName ?? "",
    namespace: metricsParams?.namespace ?? "",
    resourceName: metricsParams?.komodorServiceName ?? "",
    kind: metricsParams?.komodorServiceKind ?? "",
  });
  return useUidFromKomodorUid(komodorUid);
};
