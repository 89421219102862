import React from "react";

import { hasLimitMetrics } from "../ResourceView/tabs/MetricsTab/utils";

import { MetricsLabel } from "./MetricsLabel";
import { MetricsData } from "./types";

const NoLimitTitle = "Limits are not shown";
const NoLimitTooltipText =
  "One or more container(s) are configured without limits";
const NoLimitPerOneContainerTooltipText = "Container does not have limit set";

export interface ConditionalNoLimitLabelProps {
  data: MetricsData;
  isMultipleContainers?: boolean;
}

export const ConditionalNoLimitLabel: React.FC<
  ConditionalNoLimitLabelProps
> = ({ data, isMultipleContainers }) => {
  return data.usage.length === 0 ||
    hasLimitMetrics(data) ? null : isMultipleContainers === false ? (
    <NoLimitPerOneContainerLabel />
  ) : (
    <NoLimitLabel />
  );
};

export const NoLimitLabel: React.FC = () => {
  return (
    <MetricsLabel labelText={NoLimitTitle} tooltipText={NoLimitTooltipText} />
  );
};

export const NoLimitPerOneContainerLabel: React.FC = () => {
  return (
    <MetricsLabel
      labelText={NoLimitTitle}
      tooltipText={NoLimitPerOneContainerTooltipText}
    />
  );
};
