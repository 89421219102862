import { useNavigate } from "react-router-dom";

import nodeAutoscalersImgSrc from "../../assets/Screen - Autoscalers.png";
import { TopBar } from "../TopBar";
import { RelativeContainer, SelectableRow } from "../../styles";

import { addonsDemoRoutes } from "./constants";

export const NodeAutoscalersView: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <TopBar page="nodeAutoscalers" />
      <img src={nodeAutoscalersImgSrc}></img>
      <SelectableRow
        height="6.4%"
        top="62.5%"
        onClick={() => {
          navigate(`./${addonsDemoRoutes.clusterAutoscaler}`);
        }}
      />
    </RelativeContainer>
  );
};
