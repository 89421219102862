import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

import { useClearConfigurationControlsErrors } from "./useClearConfigurationControlsErrors";

import { CheckType, Severity } from "@/generated/reliabilityApi";
import { convertNumberStringToNumber } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";
import { getConfigurationFieldNamesByCheckType } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import { configurationFieldsDefaultValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";

type OnControlValueChangeParams = {
  checkType: CheckType;
  values: string[];
  severity: Severity;
};
export const useOnControlValueChange = ({
  severity,
  values,
  checkType,
}: OnControlValueChangeParams) => {
  const { setValue } = useFormContext();
  const clearConfigurationControlsErrors =
    useClearConfigurationControlsErrors();
  const autoCompleteCheckOptions = useAutoCompleteMissingCheckOptions();

  return useCallback(
    (name: string, value: string) => {
      const valueToSet = convertNumberStringToNumber(value);
      autoCompleteCheckOptions({ checkType, values, severity });
      clearConfigurationControlsErrors(checkType);
      setValue(name, valueToSet);
    },
    [
      autoCompleteCheckOptions,
      checkType,
      clearConfigurationControlsErrors,
      setValue,
      severity,
      values,
    ]
  );
};

const useAutoCompleteMissingCheckOptions = () => {
  const { setValue } = useFormContext();

  return useCallback(
    ({ checkType, values, severity }: OnControlValueChangeParams) => {
      if (checkType === CheckType.DeprecatedApis) {
        const [state, versionsAhead] = values;
        const { stateField, versionsAheadField } =
          getConfigurationFieldNamesByCheckType(checkType, severity);

        if (!state) {
          setValue(
            stateField,
            get(configurationFieldsDefaultValues, stateField)
          );
        }
        if (!versionsAhead) {
          setValue(
            versionsAheadField,
            get(configurationFieldsDefaultValues, versionsAheadField)
          );
        }
      }
    },
    [setValue]
  );
};
