import styled from "styled-components";

import Table from "./Table";

export const StyledFontTableMin = styled(Table)`
  td,
  th {
    font-family: Roboto Mono;
    min-width: 2rem;
  }
  td {
    vertical-align: text-top;
  }
`;
