/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { compact, groupBy, isEmpty, uniq } from "lodash";
import { useLocation } from "react-router-dom";
import { differenceInMilliseconds, hoursToMilliseconds } from "date-fns";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  getStartTime,
  NonCustomTimeframe,
} from "../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/getUpdatedTimeWindow";
import { ProcessList } from "../common/ProcessList";
import { EventsLineChart } from "../common/EventsChart/LineChart";
import { Timeframe } from "../../shared/types/TimeWindow";
import { H2 } from "../common/typography";
import EmptyState from "../ResourceView/tabs/EventsTab/content/emptyState";
import useChartCallbacks from "../ResourceView/tabs/EventsTab/content/useChartCallbacks";
import useTimeWindowFromURL from "../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL";
import { dispatchEvent } from "../../shared/hooks/analytics";
import ResponsiveLayout from "../common/ResponsiveLayout";
import useAllServicesAttributes from "../servicesView/servicesAttributes/useAllServicesAttributes";
import FilterBar from "../../shared/components/FilterBar/FilterBar";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { LocalStorageItem } from "../../shared/utils/localStorageSettings";
import { DateTimeSelector } from "../common/DateTimeSelector";
import {
  TIMEFRAME_PARAM_KEY,
  EVENT_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
} from "../../shared/config/urlSearchParamsKeys";
import useLimitedEvents from "../Freemium/useLimitedEvents";
import { useIsTimewindowInAccountLimit } from "../Freemium/useIsTimewindowInAccountLimit";
import { useEventsViewStore } from "../../shared/store/eventsViewStore/eventsViewStore";
import {
  fetchingStateSelector,
  resetFetchingStateSelector,
  setFetchingStateSelector,
} from "../../shared/store/eventsViewStore/eventsViewSelectors";
import { useReportDDRumWithMultipleFetchings } from "../../shared/hooks/datadog-rum/useReportDDRumWithMultipleFetchings";
import { dataDogViewNames } from "../../shared/constants/datadog";

import useEventsFilters from "./FilterBarUtils/useEventsFilters";
import EventsViewSavedFilters from "./FilterBarUtils/savedFilters/EventsViewSavedFilters";
import useSavedFilters from "./FilterBarUtils/savedFilters/useSavedFilters";
import EventViewLoadingType, {
  MINIMUM_NUMBER_OF_REQUESTS_TO_SHOW_CIRCLE_LOADING as showCircleFrom,
} from "./EventViewLoadingType";
import useEventsPagination from "./useEventsPagination";
import { PodsSelectionControls } from "./PodsSelectionControls";
import useGetPodsFromLabel from "./useGetPodsFromLabel";
import useShrinkTimeLine from "./useFitTimeline";
import {
  Container,
  FiltersContainer,
  Panel,
  TitleContainer,
  TopContainer,
} from "./styledComponents";
import {
  SelectedPodsProvider,
  useSelectedPodsForEvents,
} from "./PodsSelector/SelectedPodsProvider";
import { eventsTimeframes } from "./utils";

import { EventsTimelineChart } from "@/components/common/EventsChart/TimelineChart/EventsTimelineChart";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useQueryStringInLocalStorage } from "@/shared/hooks/state/useQueryStringInLocalStorage";

const beforeReloadKey = "@komodor/beforeReloadUrl";
window.komodor = {
  isReloaded: window.location.href === sessionStorage.getItem(beforeReloadKey),
};
sessionStorage.removeItem(beforeReloadKey);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedEventsViewQS = new LocalStorageItem("eventsViewQS");

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useOnReload = (onReload: () => void): void => {
  const location = useLocation();
  useEffect(() => {
    sessionStorage.setItem(beforeReloadKey, window.location.href);
    if (window.komodor.isReloaded) {
      onReload();
      window.komodor.isReloaded = false;
    }
  }, [location, onReload]);
};

const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.5rem;
  align-items: center;
`;

const initialTimeWindow = (tf: NonCustomTimeframe) => ({
  start: getStartTime[tf](new Date()),
  end: new Date(),
  timeframe: tf,
});

const TIMELINE_MAX_EVENTS = 50;
const numberFormat = new Intl.NumberFormat();
const formatNumber = (n: number) => numberFormat.format(n);
const timeOptions = eventsTimeframes.slice();
const WARNING_OVER_4_HOURS = "Queries past 4 hours may take longer";
const EventsViewContent: React.FC = () => {
  const flags = useOverridableFlags();
  const {
    defaultTimeframeHalfHour,
    eventsViewPodsSelector,
    hideWarningInEventsView,
  } = flags;
  const [highlightedEvents, setHighlightedEvents] = useState([] as string[]);
  const [eventId, setEventId] = useStateInSearchParams(EVENT_PARAM_KEY);
  const [intentId, setIntentId] = useState<string | null>();

  const defaultTimeWindow = useMemo(() => {
    return defaultTimeframeHalfHour
      ? initialTimeWindow(Timeframe.Last30Minutes)
      : initialTimeWindow(Timeframe.LastHour);
  }, [defaultTimeframeHalfHour]);
  const [timeWindow, setTimeWindow] = useTimeWindowFromURL(defaultTimeWindow);

  useGetPodsFromLabel();
  const { selectedPodUidsForEvents, setSelectedPodUidsForEvents } =
    useSelectedPodsForEvents();

  const {
    paginationElement,
    allEventGroups,
    timeWindowsLength,
    numTimeWindowsFetched,
    isFetching: isFetchingData,
  } = useEventsPagination(timeWindow);

  const limitedEvents = useLimitedEvents(allEventGroups);
  const isLimitEvents = useIsTimewindowInAccountLimit(timeWindow);

  const { onChartMouseEnter, onChartMouseLeave, onChartClick } =
    useChartCallbacks(setHighlightedEvents, setEventId);

  useQueryStringInLocalStorage({
    item: storedEventsViewQS,
    toSessionStorage: [TIME_WINDOW_PARAM_KEY, TIMEFRAME_PARAM_KEY],
  });

  useOnReload(() => setTimeWindow({ timeframe: timeWindow.timeframe }));

  useEffect(() => {
    dispatchEvent(AnalyticEvents.EventsView.Loaded);
  }, []);

  const allServicesAttributes = useAllServicesAttributes();

  const serviceAttributesByService = useMemo(() => {
    return groupBy(allServicesAttributes, (attr) => attr.serviceId);
  }, [allServicesAttributes]);

  const { filteredEventGroups, categories, selected, setSelected } =
    useEventsFilters(
      limitedEvents ?? [],
      serviceAttributesByService,
      timeWindow
    );

  useShrinkTimeLine(filteredEventGroups, timeWindow, setTimeWindow);
  const serviceIds = useMemo(
    () => uniq(filteredEventGroups.map((g) => g.serviceId)),
    [filteredEventGroups]
  );

  const eventsTimelineMaxNumberOfEvents =
    (flags.eventsTimelineMaxNumberOfEvents as number) || TIMELINE_MAX_EVENTS;
  const EventsChart =
    limitedEvents &&
    (filteredEventGroups.length > eventsTimelineMaxNumberOfEvents ||
      serviceIds.length > 5)
      ? EventsLineChart
      : EventsTimelineChart;

  const { savedFilters, addSavedFilter, deleteSavedFilter } = useSavedFilters();
  const saveFilter = useCallback(
    async (name: string) => {
      if (name) {
        await addSavedFilter(name, selected);
        dispatchEvent(AnalyticEvents.FilterBar.Save_Filter, {
          filters: selected,
        });
      }
    },
    [selected, addSavedFilter]
  );

  const removeSwimlane = useCallback(
    (label: string) => {
      setSelectedPodUidsForEvents(
        selectedPodUidsForEvents
          ? selectedPodUidsForEvents.filter((podUid) => !podUid.includes(label))
          : []
      );
    },
    [selectedPodUidsForEvents, setSelectedPodUidsForEvents]
  );

  const removableSwimlaneLabels = useMemo(
    () =>
      compact(
        (selectedPodUidsForEvents ?? []).map((podUid) =>
          podUid.split(";").at(-1)
        )
      ),
    [selectedPodUidsForEvents]
  );

  const warningOnLoading = useMemo(() => {
    if (hideWarningInEventsView) return;
    const diff = differenceInMilliseconds(timeWindow.end, timeWindow.start);
    if (diff <= hoursToMilliseconds(4)) return;
    return WARNING_OVER_4_HOURS;
  }, [hideWarningInEventsView, timeWindow.end, timeWindow.start]);

  const isFetching = isFetchingData || isLimitEvents === undefined;

  const fetchingState = useEventsViewStore(fetchingStateSelector);
  const setFetchingState = useEventsViewStore(setFetchingStateSelector);
  const resetFetchingState = useEventsViewStore(resetFetchingStateSelector);

  useEffect(() => {
    setFetchingState({
      key: "isFetchingEvents",
      value: isFetching,
    });
  }, [isFetching, setFetchingState]);

  useEffect(() => {
    return () => resetFetchingState();
  }, [resetFetchingState]);

  useReportDDRumWithMultipleFetchings(
    fetchingState,
    dataDogViewNames.eventsView
  );

  return (
    <>
      {paginationElement}
      <TopContainer>
        <ResponsiveLayout>
          <Container>
            <FiltersContainer>
              {savedFilters.length > 0 &&
                numTimeWindowsFetched >= timeWindowsLength && (
                  <EventsViewSavedFilters
                    activeFilter={selected}
                    savedFilters={savedFilters}
                    applyFilter={setSelected}
                    deleteFilter={deleteSavedFilter}
                  />
                )}
              <FilterBar
                title="Filter events"
                isLoading={!limitedEvents}
                categories={categories}
                showSave
                saveFilter={!isEmpty(selected) ? saveFilter : undefined}
              />
            </FiltersContainer>
            <TitleContainer>
              <H2 data-e2e-selector="events-title">
                {numTimeWindowsFetched >= timeWindowsLength &&
                filteredEventGroups.length
                  ? `Found ${formatNumber(filteredEventGroups.length)} events`
                  : ""}
              </H2>
              <Controls>
                {!!eventsViewPodsSelector && (
                  <PodsSelectionControls timeWindow={timeWindow} />
                )}
                <Typography
                  variant="body3"
                  color="text.secondary"
                  sx={{ marginLeft: "1rem" }}
                >
                  Show events from:
                </Typography>
                <DateTimeSelector
                  timeWindow={timeWindow}
                  setTimeWindow={setTimeWindow}
                  options={timeOptions}
                  showLabel
                  warning={
                    hideWarningInEventsView ? undefined : WARNING_OVER_4_HOURS
                  }
                  showControls
                />
              </Controls>
            </TitleContainer>
            <div>
              {isFetching && (
                <EventViewLoadingType
                  timeWindowsLength={timeWindowsLength}
                  numTimeWindowsFetched={numTimeWindowsFetched}
                  showCircleFromNumber={showCircleFrom}
                  warning={warningOnLoading}
                />
              )}
              {!isFetching &&
                !filteredEventGroups.length &&
                isLimitEvents === false && (
                  <Panel>
                    <EmptyState
                      timeWindow={timeWindow}
                      setTimeWindow={setTimeWindow}
                    />
                  </Panel>
                )}
              {!isFetching &&
              (filteredEventGroups.length || isLimitEvents === true) ? (
                <>
                  <EventsChart
                    onMouseEnter={onChartMouseEnter}
                    onMouseLeave={onChartMouseLeave}
                    onClick={onChartClick}
                    eventGroups={filteredEventGroups}
                    timeWindow={timeWindow}
                    setTimeWindow={setTimeWindow}
                    highlightedId={intentId ?? undefined}
                    removableSwimlaneLabels={removableSwimlaneLabels}
                    removeSwimlane={removeSwimlane}
                  />
                  <br />
                  <ProcessList
                    highlightedEvents={highlightedEvents}
                    showServiceColumn={true}
                    eventGroups={filteredEventGroups}
                    eventId={eventId}
                    setEventId={setEventId}
                    setIntentId={setIntentId}
                    timeWindow={timeWindow}
                    setTimeWindow={setTimeWindow}
                  />
                </>
              ) : null}
            </div>
          </Container>
        </ResponsiveLayout>
      </TopContainer>
    </>
  );
};

const EventsView: React.FC = () => (
  <SelectedPodsProvider>
    <EventsViewContent />
  </SelectedPodsProvider>
);
// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EventsView;
