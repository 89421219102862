import { MetricType } from "../types";

import { LegendItem } from "./GenericLegend";
import {
  defaultCpuLegendItems,
  defaultMemoryLegendItems,
} from "./genericLegendConstants";

export const getDefaultLegendItemsByType = (type: MetricType): LegendItem[] => {
  return type === "cpu" ? defaultCpuLegendItems : defaultMemoryLegendItems;
};
