import React from "react";
import {
  ActionItem,
  Divider,
  MenuList,
} from "@komodorio/design-system/deprecated";
import { Pencil16, Trash16 } from "@komodorio/design-system/icons";

import { appViewAriaLabels } from "../../../../../appViewAriaLabels";

import { useAppViewActionsStateContext } from "./AppViewActionsStateProvider";

const {
  overviewPage: {
    header: { actionsMenu: actionsMenuAriaLabel },
  },
} = appViewAriaLabels;

type ActionsMenuProps = {
  onEdit: () => void;
};

export const ActionsMenu: React.FC<ActionsMenuProps> = ({ onEdit }) => {
  const { setDeletionDialogOpen } = useAppViewActionsStateContext();

  const openDeletionDialog = () => {
    setDeletionDialogOpen(true);
  };

  return (
    <MenuList aria-label={actionsMenuAriaLabel}>
      <ActionItem icon={Pencil16} onClick={onEdit}>
        Edit workspace
      </ActionItem>
      <Divider spacing="4px" />
      <ActionItem icon={Trash16} variant="danger" onClick={openDeletionDialog}>
        Delete workspace
      </ActionItem>
    </MenuList>
  );
};
