import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";

import {
  WorkflowConfigType,
  WorkflowConfiguration,
  MonitorSensor,
  SinksOptions,
} from "../../common/types";
import { RuleStepSectionContainer } from "../common/RuleStepSectionContainer";
import SinkSection from "../common/SinkSection";
import ScopeSection from "../common/ScopeSection";

import NotifyOnCategories from "@/components/monitorsView/monitorsConfiguration/common/SinkSection/NotifyOnCategories";
import { ALL_CATEGORIES } from "@/components/monitorsView/monitorsConfiguration/AvailabilityRuleSections";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const WorkflowSelectorTypes = ["namespaces", "annotations", "labels"];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const workflowDefaultRule: WorkflowConfiguration = {
  id: "",
  name: "",
  createdAt: new Date(),
  type: WorkflowConfigType.Workflow,
  active: true,
  sinksOptions: {
    notifyOn: ALL_CATEGORIES,
  },
  sensors: [{ cluster: "" }],
};

const WorkflowRuleSections: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleSensorChange = useCallback(
    (sensor: MonitorSensor) => {
      setRule((prev) => ({
        ...prev,
        sensors: [sensor],
      }));
    },
    [setRule]
  );
  const handleNotifyOnChange = useCallback(
    (sinksOptions: SinksOptions) => {
      setRule((prev) => ({
        ...prev,
        sinksOptions,
      }));
    },
    [setRule]
  );

  return (
    <>
      <RuleStepSectionContainer sectionNumber={2} title="Trigger conditions">
        <Typography variant={"body2"}>
          This monitor will be triggered whenever the pods containers are
          experiencing a failure
        </Typography>
      </RuleStepSectionContainer>
      <RuleStepSectionContainer sectionNumber={3} title="Select Scope:">
        <ScopeSection
          id="scope"
          sensor={rule.sensors[0] as MonitorSensor}
          selectorTypes={WorkflowSelectorTypes}
          handleChange={handleSensorChange}
        />
      </RuleStepSectionContainer>
      <RuleStepSectionContainer
        sectionNumber={4}
        title="Where do you want to receive notifications? (Optional)"
      >
        <NotifyOnCategories rule={rule} handleChange={handleNotifyOnChange} />
        <SinkSection rule={rule} setRule={setRule} />
      </RuleStepSectionContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WorkflowRuleSections;
