import styled from "styled-components";
import { palette } from "@komodorio/design-system";

export const Container = styled.div`
  background: white;
  overflow-x: auto;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  height: fit-content;
`;
