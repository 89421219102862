import React from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import K8sService from "../../../resources/service";
import { DescribePanel } from "../common/DescribeSection";
import {
  DescribeDateItem,
  DescribeTextItem,
  DescribeTitle,
  DescribeTableItems,
} from "../common/DescribeItem";
import {
  DescribeKeyValueItem,
  DescribeTagListItem,
} from "../common/DescribeTagListItem";
import DataTable, { Overflow } from "../../../../common/table/DataTable";
import { useSetPortForwardingData } from "../utils/useSetPortForwardingData";
import { SupportedResourceKindEnum } from "../../../../../shared/context/PortForwardProvider";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { portsColumns } from "./constants";
import { useGetIp } from "./useGetIp";

export const HOST_LABEL_DISPLAY_NAME = "Host";
export const IP_LABEL_DISPLAY_NAME = "Public IP";

interface ServiceDescribeTabProps {
  resource: K8sService;
}

const DataTableWrapper = styled.div`
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  box-shadow: unset;

  /* Without this hack there will be two layers - one without borders, while one with */
  ${Overflow} {
    border-radius: 4px;
  }
`;

export const ServiceDetailsTab: React.FC<ServiceDescribeTabProps> = ({
  resource,
}) => {
  useSetPortForwardingData(resource, SupportedResourceKindEnum.Service);

  const { hostKey, hostValue } = useGetIp(
    resource?.fullObj?.status?.loadBalancer?.ingress
  );

  const service = resource.fullObj;
  const { creationTimestamp, name, labels, annotations, namespace } =
    service.metadata ?? {};

  const {
    selector,
    type,
    sessionAffinity,
    clusterIP,
    clusterIPs,
    ipFamilies,
    ipFamilyPolicy,
    ports,
  } = service.spec ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeDateItem name="Created" value={creationTimestamp} />
        <DescribeTextItem name="Name" value={name} />
        <DescribeTextItem name="Namespace" value={namespace} />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        {selector ? (
          <DescribeKeyValueItem name="Selector" value={selector} />
        ) : (
          <DescribeTextItem name="Selector" />
        )}

        <DescribeTextItem name="Type" value={type} />

        {hostKey && hostValue && (
          <DescribeTextItem name={hostKey} value={hostValue} />
        )}

        <DescribeTextItem name="Session Affinity" value={sessionAffinity} />
      </DescribePanel>

      <DescribePanel>
        <DescribeTitle>Connection</DescribeTitle>
        <DescribeTextItem name="Connection" value={name} />
        <DescribeTextItem name="Cluster IP" value={clusterIP} />

        {clusterIPs ? (
          <DescribeTagListItem name="Cluster IPs" value={clusterIPs} />
        ) : (
          <DescribeTextItem name="Cluster IPs" />
        )}

        {ipFamilies ? (
          <DescribeTagListItem name="IP families" value={ipFamilies} />
        ) : (
          <DescribeTextItem name="IP families" />
        )}

        <DescribeTextItem name="IP family policy" value={ipFamilyPolicy} />
      </DescribePanel>

      {ports ? (
        <DescribeTableItems name="Ports">
          <DataTableWrapper>
            <DataTable
              data={ports}
              columns={portsColumns}
              getRowId={(item) => item.port}
              highlightedRows={false}
            />
          </DataTableWrapper>
        </DescribeTableItems>
      ) : (
        <DescribeTextItem name="Ports" />
      )}
    </DescribeTabContentWrapper>
  );
};
