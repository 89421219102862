import React, { Suspense, lazy } from "react";
import type { EditorProps } from "@komodorio/design-system/komodor-ui/editor";

import { CenteredLoader } from "@/shared/components/CenteredLoader";

const Editor = lazy(() =>
  import("@komodorio/design-system/komodor-ui/editor").then((module) => ({
    default: module.Editor,
  }))
);

export const LazyEditor: React.FC<EditorProps> = (props) => {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <div aria-label={"YAML editor"}>
        <Editor {...props} />
      </div>
    </Suspense>
  );
};
