import React, { useMemo } from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

import { getRandRange } from "../../../../../../../shared/utils/getRandRange";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledSkeleton = styled(Skeleton)`
  && {
    border-radius: 16px;
  }
`;

export const LoadingChips: React.FC = () => {
  const content = useMemo(() => {
    const totalWidth = 150;
    const firstWidth = getRandRange(50, 100);
    const secondWidth = totalWidth - firstWidth;
    const thirdWidth = 30;
    return (
      <Container>
        <StyledSkeleton variant="circular" width={firstWidth} height={21} />
        <StyledSkeleton variant="circular" width={secondWidth} height={21} />
        <StyledSkeleton variant="circular" width={thirdWidth} height={21} />
      </Container>
    );
  }, []);
  return <Container>{content}</Container>;
};
