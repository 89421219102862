import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ErrorOutline from "@mui/icons-material/ErrorOutline.js";
import { muiColors } from "@komodorio/design-system";

import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  height: 500px;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  background-color: ${muiColors.pink[25]};
`;

const StyledErrorIcon = styled(ErrorOutline)`
  color: ${muiColors.pink[700]};
`;

const {
  rightSizingPage: {
    recommendationModal: { containersError },
  },
} = costOptimizationAriaLabels;

interface Props {
  refreshCb: (() => void) | undefined;
}

export const RecommendationsModalFetchError: React.FC<Props> = ({
  refreshCb,
}) => {
  return (
    <Container aria-label={containersError}>
      <ErrorTextContainer>
        <StyledErrorIcon />
        <Typography variant="body2" color={muiColors.pink[700]}>
          Failed to load data. Please try again.
        </Typography>
      </ErrorTextContainer>
      {refreshCb && (
        <Button onClick={refreshCb} variant="contained">
          Refresh
        </Button>
      )}
    </Container>
  );
};
