import React from "react";
import { Link, Tooltip, Typography } from "@komodorio/design-system/deprecated";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

import EventGroup from "../../common/EventGroup";
import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/Insight";
import { getColor } from "../../common/ProcessList/Status";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { MonitorData } from "../../../generated/monitorsApi";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";

export const OPEN_NODE_ISSUE_ALERT_TOOLTIP = "open-node-issue-alert-tooltip";

export const OpenNodeIssueAlertTooltip: React.FC<{
  tooltipId: string;
  issue: MonitorData;
  correlatedIssue: WorkflowIssueEventGroup | undefined;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  zIndex?: number;
}> = ({ tooltipId, issue, correlatedIssue, setCorrelatedEvent, zIndex }) => {
  const status = "open";
  return (
    <Tooltip
      id={tooltipId}
      variant="light"
      place="bottom"
      positionStrategy="fixed"
      style={zIndex ? { zIndex } : undefined}
    >
      <InsightTooltipContainer
        aria-label={AriaLabels.OpenIssue.NodeIssueTooltip}
      >
        <InsightTooltipDetails>
          <Typography variant="title">Node Issue</Typography>
          <span />
          {issue?.eventTime && (
            <>
              <Typography>Started</Typography>
              <Typography bold>
                {formatDistanceToNowStrict(parseISO(issue.eventTime))} ago
              </Typography>
            </>
          )}
          <Typography>Status</Typography>
          <Typography variant="uppercase" color={getColor(status)}>
            {status}
          </Typography>
          <Link
            onClick={(e) => {
              e.stopPropagation();
              if (correlatedIssue) setCorrelatedEvent(correlatedIssue);
            }}
            aria-label={AriaLabels.OpenIssue.NodeIssueTooltipLink}
          >
            View details
          </Link>
        </InsightTooltipDetails>
      </InsightTooltipContainer>
    </Tooltip>
  );
};
