import React from "react";

import { DescribePanel } from "../common/DescribeSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Ingress from "../../../resources/ingress";
import { DescribeMainPanelData } from "../common/DescribeMainPanelData";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { IngressRules } from "./IngressRules";
import { LoadBalancerIngressPoints } from "./LoadBalancerIngressPoints";

interface IngressDescribeTabProps {
  resource: Ingress;
}

export const IngressDescribeTab: React.FC<IngressDescribeTabProps> = ({
  resource,
}) => {
  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeMainPanelData resource={resource} />
      </DescribePanel>
      <IngressRules rules={resource.rules} />
      <LoadBalancerIngressPoints lbIngress={resource.lbIngress} />
    </DescribeTabContentWrapper>
  );
};
