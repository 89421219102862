import { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { parseWorkflowRunWithResults } from "../../../monitorsView/useWorkflowsRuns";

import { MonitorRunResponse, useMonitorRunDataById } from "./useWorkflowIssues";

export const useWorkflowRunById = (
  runId: string,
  type: string
): MonitorRunResponse => {
  const monitorIssue = useMonitorRunDataById(runId, type);
  return useMemo(() => {
    return {
      data: monitorIssue.data?.data
        ? parseWorkflowRunWithResults(monitorIssue.data?.data)
        : null,
      loading: monitorIssue.loading,
    };
  }, [monitorIssue.data?.data, monitorIssue.loading]);
};
