/* eslint-disable max-lines */
import React, { useCallback, useMemo } from "react";
import Paper from "@mui/material/Paper";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { sortBy } from "lodash";

import { useUpdateUser } from "../../../shared/hooks/auth-service/client/users/useUpdateUser";
import { useCreateRbacUserRole } from "../../../shared/hooks/auth-service/client/rbacUserRoles/useCreateRbacUserRole";
import { useDeleteRbacUserRole } from "../../../shared/hooks/auth-service/client/rbacUserRoles/useDeleteRbacUserRole";
import { useUpdateRbacUserRole } from "../../../shared/hooks/auth-service/client/rbacUserRoles/useUpdateRbacUserRole";
import { RbacRole, User } from "../../../generated/auth";

import { ariaLabels, GENERAL_ERROR_MESSAGE } from "./const";
import { RoleWithUserExpiration, UserForm } from "./types";
import { getFilteredUserRoles } from "./utils";

import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "@/components/Settings/Roles/Roles";
import { useGetRbacRoles } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { getColumnDefinitions } from "@/components/Settings/Users/gridHelpers";
import { PAGE_SIZE_OPTIONS } from "@/pages/organization-settings/account/AgentsPage/constants";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useDataGridPagination } from "@/shared/hooks/dataGrid/useDataGridPagination";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";

export const useUserManagement = () => {
  const { mutateAsync: editUser, isLoading: editLoading } = useUpdateUser();
  const {
    mutateAsync: createRbacUserRole,
    isLoading: createRbacUserRoleLoading,
    isError: createRbacUserRoleError,
  } = useCreateRbacUserRole();
  const {
    mutateAsync: deleteRbacUserRole,
    isLoading: deleteRbacUserRoleLoading,
    isError: deleteRbacUserRoleError,
  } = useDeleteRbacUserRole();
  const {
    mutateAsync: updateRbacUserRole,
    isLoading: updateRbacUserRoleLoading,
    isError: updateRbacUserRoleError,
  } = useUpdateRbacUserRole();

  return {
    editUser,
    editLoading,
    createRbacUserRole,
    createRbacUserRoleLoading,
    createRbacUserRoleError,
    deleteRbacUserRole,
    deleteRbacUserRoleLoading,
    deleteRbacUserRoleError,
    updateRbacUserRole,
    updateRbacUserRoleLoading,
    updateRbacUserRoleError,
  };
};

export const useGetRoleWithUserExpiration = (user?: User) => {
  const { temporaryRoles, permanentRoles } = useMemo(() => {
    if (user) {
      const temporaryRoles = getFilteredUserRoles(user, true);
      const permanentRoles = getFilteredUserRoles(user, false);
      return { temporaryRoles, permanentRoles };
    }
    return { temporaryRoles: [], permanentRoles: [] };
  }, [user]);
  return { temporaryRoles, permanentRoles };
};

export const mapAvailableRoles = ({
  allRoles,
  currentRoles,
  isOnlyOneAccountAdmin,
}: {
  allRoles: RbacRole[];
  currentRoles: { label: string; value: string }[] | undefined;
  isOnlyOneAccountAdmin: boolean;
}) =>
  allRoles.map((role) => ({
    label: role.name,
    value: role.id,
    disabled:
      role.name === ACCOUNT_ADMIN_RBAC_ROLE &&
      isOnlyOneAccountAdmin &&
      currentRoles?.some(({ label }) => label === ACCOUNT_ADMIN_RBAC_ROLE),
  }));

export const useGetRolesOptions = (
  value: UserForm,
  isOnlyOneAccountAdmin: boolean
) => {
  const { data: allRoles = [] } = useGetRbacRoles();
  return useMemo(
    () =>
      mapAvailableRoles({
        allRoles,
        currentRoles: value.roles,
        isOnlyOneAccountAdmin,
      }),

    [allRoles, isOnlyOneAccountAdmin, value.roles]
  );
};

export const useHandleUserSave = (
  value: UserForm,
  timeValue: Date | null,
  setLoadingMutation: React.Dispatch<React.SetStateAction<boolean>>,
  user?: User
) => {
  const {
    editUser,
    createRbacUserRole,
    deleteRbacUserRole,
    updateRbacUserRole,
    createRbacUserRoleError,
    deleteRbacUserRoleError,
    updateRbacUserRoleError,
    createRbacUserRoleLoading,
    deleteRbacUserRoleLoading,
    updateRbacUserRoleLoading,
    editLoading,
  } = useUserManagement();
  const { setError } = useFormValidations();
  const { temporaryRoles } = useGetRoleWithUserExpiration(user);
  const { name, roles } = value;
  const newRoleIds = roles.map((r) => r.value);
  const temporaryRole = temporaryRoles?.[0];
  useMemo(() => {
    setLoadingMutation(
      createRbacUserRoleLoading ||
        deleteRbacUserRoleLoading ||
        updateRbacUserRoleLoading ||
        editLoading
    );
  }, [
    createRbacUserRoleLoading,
    deleteRbacUserRoleLoading,
    editLoading,
    setLoadingMutation,
    updateRbacUserRoleLoading,
  ]);

  const clbc = useCallback(async () => {
    const editUserAsync = async (user: User) => {
      await editUser({
        id: user.id,
        updateUserRequest: {
          displayName: name.trim(),
          rbacRoleIds: newRoleIds,
        },
      });
    };

    const createRbacUserRoleAsync = async (user: User) => {
      await createRbacUserRole({
        userId: user.id,
        roleId: value.tempRole.value,
        expiration: timeValue?.valueOf().toString(),
      });
    };

    const deleteRbacUserRoleAsync = async (
      user: User,
      temporaryRole: RoleWithUserExpiration
    ) => {
      await deleteRbacUserRole({
        userId: user.id,
        roleId: temporaryRole.id as string,
      });
    };

    const updateRbacUserRoleAsync = async (user: User) => {
      await updateRbacUserRole({
        userId: user.id,
        roleId: value.tempRole.value,
        expiration: timeValue?.valueOf().toString() as string, // update is called after checking timeValue is not null
      });
    };

    const handleSaveError = () => {
      if (
        createRbacUserRoleError ||
        deleteRbacUserRoleError ||
        updateRbacUserRoleError
      ) {
        setError("general", GENERAL_ERROR_MESSAGE);
      }
    };

    if (!user) return;
    try {
      await editUserAsync(user);
      if (!temporaryRole && value.tempRole.value !== "") {
        await createRbacUserRoleAsync(user);
        return handleSaveError();
      }
      if (temporaryRole && value.tempRole.value === "") {
        await deleteRbacUserRoleAsync(user, temporaryRole);
        return handleSaveError();
      }
      if (
        temporaryRole &&
        value.tempRole.value === temporaryRole.id &&
        timeValue
      ) {
        await updateRbacUserRoleAsync(user);
        return handleSaveError();
      }
      if (
        temporaryRole &&
        value.tempRole.value !== temporaryRole.id &&
        timeValue
      ) {
        await deleteRbacUserRoleAsync(user, temporaryRole);
        await createRbacUserRoleAsync(user);
        return handleSaveError();
      }
      return handleSaveError();
    } catch (e) {
      setError("general", GENERAL_ERROR_MESSAGE);
      return;
    }
  }, [
    createRbacUserRole,
    createRbacUserRoleError,
    deleteRbacUserRole,
    deleteRbacUserRoleError,
    editUser,
    name,
    newRoleIds,
    setError,
    temporaryRole,
    timeValue,
    updateRbacUserRole,
    updateRbacUserRoleError,
    user,
    value.tempRole.value,
  ]);
  return clbc;
};

export const useUsersPage = ({
  search,
  users,
  setUserToEdit,
  setUserToDelete,
  setUserToRestore,
  setUserToViewPermissions,
}: {
  search: string;
  users: User[] | undefined;
  setUserToEdit: (user: User) => void;
  setUserToDelete: (user: User) => void;
  setUserToRestore: (user: User) => void;
  setUserToViewPermissions: (user: User) => void;
}) => {
  const { ref, width } = usePageWidth();
  const { showTemporaryRoles: showTemporaryRolesFF } = useOverridableFlags();
  const { paginationModel, handlePaginationModelChange } =
    useDataGridPagination();

  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const filteredUsers = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return users
      ? sortBy(
          users.filter((u) => {
            return [u.displayName, u.email].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          [(u) => u.deletedAt !== null, (u) => u.displayName]
        )
      : [];
  }, [users, search]);

  const columns = useMemo(() => {
    const toReturn = getColumnDefinitions({
      onEdit: setUserToEdit,
      onDelete: setUserToDelete,
      onRestore: setUserToRestore,
      onViewPermissions: setUserToViewPermissions,
    });

    if (!showTemporaryRolesFF) {
      return toReturn.filter((column) => column.field !== "tempRoles");
    }

    return toReturn;
  }, [
    showTemporaryRolesFF,
    setUserToDelete,
    setUserToRestore,
    setUserToEdit,
    setUserToViewPermissions,
  ]);

  const tableContent = useMemo(() => {
    return (
      <Paper variant="elevation" elevation={1} sx={{ width, overflow: "auto" }}>
        <DataGridPro
          aria-label={ariaLabels.page.table}
          disableVirtualization
          pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          columns={columns}
          disableColumnMenu
          rows={filteredUsers.map((user) => {
            return {
              disabled: !!user.deletedAt,
              ...user,
              hovered: user.id === hoveredRowId,
            };
          })}
          slotProps={{
            row: {
              onMouseEnter: handleRowHovered,
              onMouseLeave: handleRowLeaved,
            },
          }}
        />
      </Paper>
    );
  }, [
    filteredUsers,
    width,
    paginationModel,
    handlePaginationModelChange,
    columns,
    hoveredRowId,
    handleRowLeaved,
    handleRowHovered,
  ]);

  return { tableContent, ref };
};
