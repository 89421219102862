import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { openPlansPage } from "../../../../shared/utils/freemium";

import { description, freemiumDescription } from "./texts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledButton = styled(Button).attrs({
  size: "large",
  variant: "contained",
})`
  width: fit-content;
`;

const Description = styled(Typography).attrs({
  variant: "body1",
  color: "text.secondary",
})``;

interface StaticPreviewDescriptionProps {
  isFreemiumAccount: boolean;
}

export const StaticPreviewDescription: React.FC<
  StaticPreviewDescriptionProps
> = ({ isFreemiumAccount }) => {
  return (
    <Container>
      <Description>
        {isFreemiumAccount ? freemiumDescription : description}
      </Description>
      {isFreemiumAccount && (
        <ButtonContainer>
          <StyledButton onClick={openPlansPage}>Pricing and plans</StyledButton>
        </ButtonContainer>
      )}
    </Container>
  );
};
