import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import { formatNumber } from "../../../utils/formatNumber";
import { OverflowTextStyle } from "../../../styles/styles";
import { hasTextEllipsis } from "../../../utils/hasTextEllipsis";
import { COLLAPSIBLE_CONTAINER_GAP } from "../checkboxHandlerConstants";
import { checkboxOptionsAriaLabels } from "../ariaLabels";

const Container = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - ${COLLAPSIBLE_CONTAINER_GAP * 2}px);
    gap: 8px;
  }
`;

const CheckboxAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  cursor: pointer;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }
`;

const TypographyWithTextEllipsis = styled(Typography)`
  && {
    ${OverflowTextStyle};
  }
`;

const optionAriaLabel = checkboxOptionsAriaLabels.singleOption;

type CheckboxSelectionOptionProps = {
  checked: boolean;
  value: string;
  label: string;
  onChange: (value: string, checked: boolean) => void;
  count?: number;
};

export const CheckboxSelectionOption: React.FC<
  CheckboxSelectionOptionProps
> = ({ checked, value, onChange, count, label }) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(hasTextEllipsis(labelRef.current));
  }, []);

  return (
    <Container aria-label={`${optionAriaLabel} ${value}`}>
      <CheckboxAndLabelContainer onClick={() => onChange(value, !checked)}>
        <StyledCheckbox checked={checked} aria-checked={checked} />
        <Tooltip title={showTooltip ? label : undefined} placement="right">
          <TypographyWithTextEllipsis
            variant={"body3"}
            color={"text.primary"}
            ref={labelRef}
          >
            {label}
          </TypographyWithTextEllipsis>
        </Tooltip>
      </CheckboxAndLabelContainer>
      {!!count && (
        <Typography variant={"body3"} color={"text.secondary"}>
          {formatNumber(count)}
        </Typography>
      )}
    </Container>
  );
};
