export enum OperatingSystem {
  Windows = "Windows",
  MacOS = "MacOS",
  Linux = "Linux",
  Unknown = "Unknown",
}

export const getOperatingSystem = (): OperatingSystem => {
  if (navigator.userAgent.indexOf("Win") !== -1) return OperatingSystem.Windows;
  if (navigator.userAgent.indexOf("Mac") !== -1) return OperatingSystem.MacOS;
  if (navigator.userAgent.indexOf("Linux") !== -1) return OperatingSystem.Linux;
  return OperatingSystem.Unknown;
};

export enum OpenBrowserCommand {
  Windows = "start",
  MacOS = "open",
  Linux = "xdg-open",
  Unknown = "Unknown",
}

export const getOpenBrowserCommand = (): string => {
  const os = getOperatingSystem();
  if (os === OperatingSystem.Windows) return OpenBrowserCommand.Windows;
  if (os === OperatingSystem.MacOS) return OpenBrowserCommand.MacOS;
  if (os === OperatingSystem.Linux) return OpenBrowserCommand.Linux;
  return OpenBrowserCommand.Unknown;
};
