import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useWorkspaceQueryKey } from "../workspaces-api/useWorkspaceQueryKey";

import { QueryOptions, useK8sAddonsApiClient } from "./apiClient";

import {
  AddonsApiGetEntityRequest,
  EntityResourceResponse,
  getEntityUrl,
} from "@/generated/addonsApi";

export const GET_ADDONS_FULL_LIVE_STATE =
  "/api/v1/addons/:addon/:entity/:name/:uid";

export const useGetAddonFullLiveState = <T>(
  params: AddonsApiGetEntityRequest,
  select: (json: AxiosResponse<EntityResourceResponse, unknown>) => T,
  queryOptions?: QueryOptions
) => {
  const apiClient = useK8sAddonsApiClient();

  const url = getEntityUrl(params, apiClient.defaults.baseURL ?? "");

  const keys = useWorkspaceQueryKey([url, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient.post<T>(
        getEntityUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.getEntityRequest }
      ),
    { select: select as (json: unknown) => T, ...queryOptions }
  );
};
