import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";

import {
  Bar,
  Text,
  Empty,
  List,
  Placeholder,
} from "../../../../../common/controls/Select/SingleSmartSelect";
import { useEscape } from "../../../../../../shared/hooks/useEscape";
import Overlay from "../../../../../common/Overlay";
import TextButton from "../../../../../common/TextButton";

import EventFiltersCategory, { CategoryInfo } from "./EventFiltersCategory";

const EVENTS_FILTER_MIN_WIDTH = "15rem";

const ClearSection = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div<{ minWidth: string }>`
  position: relative;
  max-width: 20rem;
  min-width: ${({ minWidth }) => `${minWidth}`};
`;

const MenuExpansion = styled.div<{ maxHeight?: number }>`
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow: auto;
  border-left: 1px solid #e0e0e0;
  mask-image: linear-gradient(
    to bottom,
    black calc(100% - 30px),
    transparent 100%
  );
`;

interface EventFiltersProps {
  categories: CategoryInfo[];
  onReset: (() => void) | undefined;
  menuExpansionContents?: JSX.Element;
}

const EventFilters: React.FC<EventFiltersProps> = ({
  categories,
  onReset,
  menuExpansionContents,
}) => {
  const [open, setOpen] = useState(false);
  const eventFiltersRef = useRef<HTMLDivElement>(null);

  useEscape(() => setOpen(false));

  const [openCategory, setOpenCategory] = useState(0);

  const displayedLabels = useMemo(() => {
    return categories
      .flatMap((c) => c.options.filter((o) => o.isSelected).map((o) => o.label))
      .join(", ");
  }, [categories]);

  return (
    <Container
      minWidth={EVENTS_FILTER_MIN_WIDTH}
      data-e2e-selector="event-filters"
    >
      <Bar onClick={() => setOpen(true)}>
        <Text>
          {displayedLabels || (
            <Placeholder>Event type, status, and details</Placeholder>
          )}
        </Text>
      </Bar>
      <Overlay open={open} onClick={() => setOpen(false)} />
      <List
        style={{
          visibility: open ? undefined : "hidden",
          ...(menuExpansionContents
            ? { display: "flex", flexDirection: "row" }
            : {}),
        }}
      >
        {categories.length ? (
          <>
            <div
              ref={eventFiltersRef}
              style={{
                minWidth: EVENTS_FILTER_MIN_WIDTH,
              }}
            >
              <ClearSection>
                <TextButton
                  className={onReset ? undefined : "disabled"}
                  onClick={onReset}
                >
                  clear all
                </TextButton>
              </ClearSection>
              {categories.map((category, i) => (
                <EventFiltersCategory
                  key={category.title}
                  info={category}
                  open={openCategory === i}
                  onClick={() => {
                    setOpenCategory((prev) => (prev === i ? -1 : i));
                  }}
                />
              ))}
            </div>
            {open && menuExpansionContents ? (
              <MenuExpansion maxHeight={eventFiltersRef.current?.clientHeight}>
                {menuExpansionContents}
              </MenuExpansion>
            ) : null}
          </>
        ) : (
          <Empty>
            <Placeholder>No options</Placeholder>
          </Empty>
        )}
      </List>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EventFilters;
