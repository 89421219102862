import { useMemo, useState } from "react";

import {
  METRICS_NODE_CPU_ENDPOINT,
  METRICS_NODE_MEMORY_ENDPOINT,
} from "../../shared/hooks/metrics-api/requestResponseMaps";
import {
  DatapointsMap,
  MetricsApiApiV1NodeMemoryTypeGetRequest,
  MetricsResponse,
} from "../../generated/metricsApi";
import { useMetricsAPIGet } from "../../shared/hooks/metrics-api/client";
import { Timeframe } from "../../shared/types/TimeWindow";

import {
  AggregatedMetricsResponse,
  dataKeyToMetricsDataKey,
  DataPoint,
  Metrics,
  MetricsTimeWindow,
  NodeMetricsRequestParams,
} from "./types";
import { calculateTimeframe, timeWindowToEpochs } from "./utils";
import { toDataPoint, useFormatMetricsResponse } from "./useMetrics";
import { useCustomMetricsParams } from "./useCustomMetricsParams";

export type MetricsWithAggregations = {
  usageBytes: DataPoint[];
  max: DataPoint[];
  p90: DataPoint[];
  p95: DataPoint[];
  p99: DataPoint[];
};

export const useNodeMetrics = (
  clusterName: string,
  nodeName: string,
  endTimestamp: number,
  isMetricsSupported: boolean,
  enableFetch = true
): Metrics => {
  const [prevTimeframe, setPrevTimeframe] = useState<MetricsTimeWindow>();

  const [timeframe, setTimeframe] = useState<MetricsTimeWindow>();

  const metricsRequestParams = useMemo(
    () => ({
      clusterName,
      nodeName,
      endTimestamp,
      pause: !clusterName || !nodeName || !isMetricsSupported || !enableFetch,
    }),
    [clusterName, nodeName, endTimestamp, isMetricsSupported, enableFetch]
  );

  const endDate = useMemo(
    () => new Date(metricsRequestParams.endTimestamp),
    [metricsRequestParams.endTimestamp]
  );

  const memoryOneHour = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.LastHour, endDate),
  });

  const cpuOneHour = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.LastHour, endDate),
  });

  const memoryFourHours = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last4Hours, endDate),
  });

  const cpuFourHours = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last4Hours, endDate),
  });

  const memoryOneDay = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last24Hours, endDate),
  });
  const memoryOneWeek = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last7Days, endDate),
  });
  const cpuOneDay = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last24Hours, endDate),
  });
  const cpuOneWeek = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_CPU_ENDPOINT,
    ...timeWindowToEpochs(Timeframe.Last7Days, endDate),
  });

  const customMetricsParams = useCustomMetricsParams(prevTimeframe, timeframe);

  const memoryCustom = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_MEMORY_ENDPOINT,
    ...customMetricsParams,
  });

  const cpuCustom = useNodesMetrics({
    ...metricsRequestParams,
    endpoint: METRICS_NODE_CPU_ENDPOINT,
    ...customMetricsParams,
  });

  return useMemo(
    () => ({
      memoryOneHour,
      memoryFourHours,
      memoryOneDay,
      memoryOneWeek,
      cpuOneHour,
      cpuFourHours,
      cpuOneDay,
      cpuOneWeek,
      memoryCustom,
      cpuCustom,
      timeframe,
      setTimeframe: (start?: Date, end?: Date) => {
        if (
          prevTimeframe === undefined ||
          calculateTimeframe(prevTimeframe?.start, prevTimeframe?.end) !==
            calculateTimeframe(start, end)
        ) {
          setPrevTimeframe(timeframe);
        }
        if (start && end) {
          setTimeframe({ start, end });
        } else {
          setTimeframe(undefined);
        }
      },
    }),
    [
      memoryOneHour,
      memoryFourHours,
      memoryOneDay,
      memoryOneWeek,
      cpuOneHour,
      cpuFourHours,
      cpuOneDay,
      cpuOneWeek,
      memoryCustom,
      cpuCustom,
      timeframe,
      prevTimeframe,
    ]
  );
};

type MetricsPerTypeParams = NodeMetricsRequestParams & {
  type: MetricsApiApiV1NodeMemoryTypeGetRequest["type"];
};
type MetricsPerTypeResponse = {
  loading: boolean;
  error: string | null;
  metrics?: DataPoint[];
};

export const useMetricsPerType = ({
  endpoint,
  clusterName,
  nodeName,
  endTimestamp,
  pause,
  type,
  ...rest
}: MetricsPerTypeParams): MetricsPerTypeResponse => {
  const { loading, data, error } = useMetricsAPIGet<MetricsResponse>(
    endpoint,
    {
      clusterName,
      nodeName,
      type,
      endTime: endTimestamp,
      ...rest,
    },
    pause
  );

  const metrics = useFormatMetricsResponse(data);
  return {
    loading,
    error,
    metrics,
  };
};

export const useNodesMetrics = (
  params: NodeMetricsRequestParams
): AggregatedMetricsResponse => {
  const { pause = false } = params;

  const {
    loading: usageLoading,
    metrics: usageMetrics,
    error: usageError,
  } = useMetricsPerType({ ...params, type: "usage" });

  const {
    loading: requestLoading,
    metrics: requestMetrics,
    error: requestError,
  } = useMetricsPerType({ ...params, type: "request" });

  const {
    loading: limitLoading,
    metrics: limitMetrics,
    error: limitError,
  } = useMetricsPerType({ ...params, type: "limit" });

  const {
    loading: capacityLoading,
    metrics: capacityMetrics,
    error: capacityError,
  } = useMetricsPerType({ ...params, type: "capacity" });

  return {
    paused: pause,
    loading: usageLoading || requestLoading || limitLoading || capacityLoading,
    error: usageError || requestError || limitError || capacityError,
    metrics: {
      usage: usageMetrics ?? [],
      request: requestMetrics ?? [],
      limit: limitMetrics ?? [],
      capacity: capacityMetrics ?? [],
    },
  };
};

export const useMetricsWithAggregations = (
  datapointsMap: DatapointsMap | undefined
) =>
  useMemo(
    () =>
      !datapointsMap
        ? undefined
        : Object.entries(datapointsMap ?? []).reduce<MetricsWithAggregations>(
            (acc, [key, value]) => {
              const metricsKey =
                dataKeyToMetricsDataKey[key as keyof DatapointsMap];
              if (!metricsKey) return acc;
              return {
                ...acc,
                [metricsKey]: value.map(toDataPoint),
              };
            },
            {
              usageBytes: [],
              max: [],
              p90: [],
              p95: [],
              p99: [],
            }
          ),
    [datapointsMap]
  );

export const useNodesMetricsWithAggregations = ({
  endpoint,
  endTimestamp,
  pause,
  type,
  ...rest
}: MetricsPerTypeParams) => {
  const { loading, data, error } = useMetricsAPIGet<MetricsResponse>(
    endpoint,
    {
      type,
      endTime: endTimestamp,
      ...rest,
    },
    pause
  );

  const metrics = useMetricsWithAggregations(data?.datapointsMap);

  return { error, loading, data: metrics };
};
