import { useKomodorServices } from "../../../hooks/useKomodorServices";
import { ServiceInfo } from "../../../types/ServiceInfo";
import { useDesktopAgentsStatusContext } from "../desktopAgentsProvider/desktopAgentsStatusesProvider";
import { AgentStatus } from "../getClusterStatus";

export const getIsAtleastOneAgentConnected = (
  agentStatusValues: AgentStatus[],
  services: ServiceInfo[]
): boolean => {
  return (
    agentStatusValues.includes(AgentStatus.CONNECTED) && !!services?.length
  );
};

export const useIsAtleastOneAgentConnected = (): boolean => {
  const services = useKomodorServices().services;
  const agentStatuses = useDesktopAgentsStatusContext();
  const agentStatusValues = Object.values(agentStatuses ?? {});
  const atleastOneAgentConnected = getIsAtleastOneAgentConnected(
    agentStatusValues,
    services ?? []
  );
  return atleastOneAgentConnected;
};
