import { useMemo } from "react";
import { Deployment } from "kubernetes-types/apps/v1";

import { DESC } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { getFormattedImage } from "../utils";
import { useGetDeploys } from "../../../../../shared/hooks/monitors-api/client/deploys/useGetDeploys";

type useImagesProps = {
  serviceId: string | undefined;
};

export const useGetContainerImages = ({
  serviceId,
}: useImagesProps): (string | undefined)[] | undefined => {
  const { data } = useGetDeploys(
    {
      serviceIds: [serviceId ?? ""],
      fields: ["newSpec"],
      order: DESC,
      pageSize: 1,
    },
    !!serviceId
  );

  const deploy = data?.data;
  return useMemo(() => {
    if (!deploy) {
      return;
    }
    if (!deploy[0]?.newSpec) {
      return;
    }

    try {
      const spec = JSON.parse(
        deploy[0]?.newSpec as unknown as string
      ) as Deployment;
      return spec?.spec?.template?.spec?.containers?.map((c) =>
        getFormattedImage(c.image)
      );
    } catch (e) {
      return;
    }
  }, [deploy]);
};
