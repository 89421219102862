import React, { useCallback } from "react";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { storedLastCluster } from "../utils/localStorage";
import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";

import {
  InputContainer,
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "./InspectionResourceListSearch";

const SelectContainer = styled.div`
  & .menuList {
    max-height: 9.8rem;
  }
  & .dropDownTriggerField {
    height: 37px;
    border-color: ${muiColors.gray[300]};
  }
`;

const InspectionResourceListClusterFilter: React.FC<{
  cluster: string | undefined;
  onClusterChange: (clusterName: string) => void;
  options: MuiSelectionOption<string>[];
}> = ({ cluster, onClusterChange, options }) => {
  const { isClusterWorkspace, isLoading } = useWorkspaces();

  const onChange = useCallback(
    (option: MuiSelectionOption<string> | undefined) => {
      if (!option) return;
      storedLastCluster.set(option.value);
      onClusterChange(option.value);
    },
    [onClusterChange]
  );
  return (
    <InspectionFilterContainer>
      <InspectionFilterDiv>Cluster</InspectionFilterDiv>
      <InputContainer>
        <SelectContainer>
          <SingleSelect
            enableSearch
            options={options}
            classNames={{
              dropDownTriggerField: "dropDownTriggerField",
              menuList: "menuList",
            }}
            customValueElement={(value) => (
              <Typography
                sx={{ paddingLeft: "4px" }}
                variant="body3"
                color={muiColors.gray[800]}
              >
                {value.value}
              </Typography>
            )}
            value={cluster ? { label: cluster, value: cluster } : undefined}
            onChange={onChange}
            disabled={!isLoading && isClusterWorkspace}
          />
        </SelectContainer>
      </InputContainer>
    </InspectionFilterContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionResourceListClusterFilter;
