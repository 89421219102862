import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { StyledLinearProgress } from "../../styles/CostOptimizationStyledComponents";
import { formatNumber } from "../../../../../shared/utils/formatNumber";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const LinearPercentageBar: React.FC<{ value: number }> = ({ value }) => {
  const formattedValue = parseFloat(formatNumber(value));
  return (
    <FlexDiv>
      <StyledLinearProgress
        value={formattedValue}
        variant="buffer"
        valueBuffer={100}
      />
      <Typography
        color={muiColors.gray[600]}
        sx={{ fontSize: 11, fontWeight: 500 }}
      >{`${formattedValue}%`}</Typography>
    </FlexDiv>
  );
};
