import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1ConfigurationsConfigMapsEventsSearchGetUrl,
  ConfigMapEventsResponse,
  EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";

const GET_CM_EVENTS_SEARCH = "/api/v1/configurations/config-maps/events/search";

type UseConfigMapEventsSearchParams =
  EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest & {
    enabled?: boolean;
  };

const fetchConfigMapEvents = async (
  apiClient: AxiosInstance,
  params: UseConfigMapEventsSearchParams
): Promise<ConfigMapEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1ConfigurationsConfigMapsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useConfigMapEvents = (params: UseConfigMapEventsSearchParams) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([GET_CM_EVENTS_SEARCH, params]);
  return useQuery(keys, () => fetchConfigMapEvents(apiClient, params), {
    enabled: params.enabled,
  });
};
