import { RbacRole } from "@/generated/auth";
import { ROLES_CELL_WIDTH } from "@/components/Settings/Users/const";
import { getTextWidth } from "@/shared/utils/getTextWidth";

export const FONT = "12px Roboto, sans-serif";
const ITEM_PADDING = 32; // padding + margin for each role
const HIDDEN_ROLES_CHIP_WIDTH = 40;

export const calculateFittingRoles = (
  roles: Array<RbacRole>,
  cellWidth: number = ROLES_CELL_WIDTH,
  reservedPlace: number = HIDDEN_ROLES_CHIP_WIDTH
): number => {
  const availableWidth = cellWidth - reservedPlace; // Remove the fixed component width

  let currentWidth = 0;
  let fittingRoles = 0;
  let index = 0;

  while (index < roles.length) {
    const roleTextWidth = getTextWidth(roles[index].name, FONT) + ITEM_PADDING;

    // Check if all the roles can fit the cell without overflowing and without the hidden roles chip
    if (
      currentWidth + roleTextWidth <= cellWidth &&
      fittingRoles + 1 === roles.length
    ) {
      fittingRoles = roles.length;
      index = roles.length; // No more roles can fit
    } else {
      if (currentWidth + roleTextWidth <= availableWidth) {
        currentWidth += roleTextWidth;
        fittingRoles++;
        index++;
      } else {
        index = roles.length; // No more roles can fit
      }
    }
  }

  return fittingRoles;
};
