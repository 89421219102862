import { useMemo } from "react";
import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { IngressFragment } from "../../../../../../../generated/graphql";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { NETWORK_INGRESS_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { IngressEventsResponse } from "../../../../../../../generated/resourcesApi";

export const useIngressEventsFetch = (
  ingressUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
): [IngressFragment[] | undefined, boolean] => {
  const shouldFetchData = useMemo(
    () => ingressUids?.length && !!clusterName && !!namespace,
    [clusterName, ingressUids?.length, namespace]
  );

  const ingressesNames = useMemo(
    () =>
      compact(ingressUids?.map((ingressUid) => ingressUid.split(";").at(-1))),
    [ingressUids]
  );

  const uids = useMemo(
    () =>
      ingressesNames.map((resourceName) =>
        buildKomodorUid({
          resourceName,
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          kind: "Ingress",
        })
      ),
    [clusterName, ingressesNames, namespace]
  );

  const { data, loading } = useResourcesAPIGet(
    NETWORK_INGRESS_EVENT_SEARCH,
    {
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      komodorUids: uids,
      fields: [
        "id",
        "name",
        "namespace",
        "clusterName",
        "eventTime",
        "services",
        "action",
      ],
    },
    !shouldFetchData
  );

  return [convertToIngressFragment(data), loading];
};

const convertToIngressFragment = (
  data: IngressEventsResponse | undefined
): IngressFragment[] | undefined => {
  return data?.data?.map((ingress) => {
    return ingress as IngressFragment;
  });
};
