import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ButtonProps } from "@mui/material";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  MultipleClustersUpgradeRequired,
  MultipleClustersUpgradeRequiredProps,
} from "./MultipleClustersUpgradeRequired";

const Container = styled.div`
  margin: 0 10px;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

export const unsupportedClustersContainerAriaLabel =
  "unsupported clusters list container";

export type UpgradeButtonAndModalForUnsupportedClustersProps = {
  unsupportedClusters: string[];
  buttonVariant?: ButtonProps["variant"];
} & Omit<MultipleClustersUpgradeRequiredProps, "handleClose">;

export const UpgradeButtonAndModalForUnsupportedClusters: React.FC<
  UpgradeButtonAndModalForUnsupportedClustersProps
> = ({ unsupportedClusters, buttonVariant = "contained", ...rest }) => {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Button variant={buttonVariant} onClick={() => setOpen(true)}>
        <StyledTypography variant={"h6"} color={"white"}>
          Update Agent
        </StyledTypography>
      </Button>
      {open && (
        <MultipleClustersUpgradeRequired
          {...rest}
          unsupportedClusters={unsupportedClusters}
          handleClose={() => setOpen(false)}
        />
      )}
    </Container>
  );
};
