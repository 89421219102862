import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { useLogsViewerStore } from "../../../components/common/LogsViewer/store/logsViewerStore";
import { setLiveTailEnabled } from "../../../components/common/LogsViewer/store/logsViewerStoreSelectors";

import { PodLogsInfoRequestProps, PodLogsInfoResponseProps } from "./types";
import { useGetLivePodLogs } from "./useGetLivePodLogs";
import { useGetPodLogs } from "./useGetPodLogs";
import { useShouldUseLivePodsLogs } from "./useShouldUseLivePodsLogs";

export const usePodLogsInfo = (
  props: PodLogsInfoRequestProps
): PodLogsInfoResponseProps => {
  const shouldUseLivePodLogs = useShouldUseLivePodsLogs(props.clusterName);
  const logsByDemand = useGetPodLogs({
    ...props,
    shouldExecuteFetchLogs: !shouldUseLivePodLogs,
  });

  const liveLogs = useGetLivePodLogs({
    ...props,
    shouldExecuteFetchLogs: shouldUseLivePodLogs,
  });

  const setLiveTailEnabledCB = useLogsViewerStore(setLiveTailEnabled);
  useEffect(() => {
    setLiveTailEnabledCB(shouldUseLivePodLogs);
    datadogRum.setGlobalContextProperty(
      "liveTailEnabled",
      shouldUseLivePodLogs
    );
  }, [setLiveTailEnabledCB, shouldUseLivePodLogs]);

  return shouldUseLivePodLogs ? liveLogs : logsByDemand;
};
