import { useMemo } from "react";

import { useMetricsAPIGet } from "../../shared/hooks/metrics-api/client";
import {
  Datapoint,
  MetricsApiApiV1PodContainerNameMemoryTypeGetRequest,
  MetricsResponse,
} from "../../generated/metricsApi";

import {
  AggregatedMetricsResponse,
  DataPoint,
  MetricsRequestParams,
} from "./types";
import { getLimitMetricsByContainersNum } from "./utils";

type MetricsPerTypeParams = MetricsRequestParams & {
  type: MetricsApiApiV1PodContainerNameMemoryTypeGetRequest["type"];
};
export type MetricsPerTypeResponse = {
  loading: boolean;
  error: string | null;
  metrics?: DataPoint[];
  containersNum?: number;
};

export const toDataPoint = (point: Datapoint): DataPoint => ({
  x: point.timestampMs,
  y: point.value,
});

export const useFormatMetricsResponse = (
  data: MetricsResponse | undefined
): DataPoint[] | undefined =>
  useMemo(() => data?.datapoints?.map(toDataPoint), [data]);

export const useMetricsPerType = ({
  endpoint,
  endTimestamp,
  clusterName,
  namespace,
  podName,
  containerName,
  pause,
  type,
  ...rest
}: MetricsPerTypeParams): MetricsPerTypeResponse => {
  const { loading, data, error } = useMetricsAPIGet<MetricsResponse>(
    endpoint,
    {
      clusterName,
      namespace,
      podName,
      containerName,
      type,
      endTime: endTimestamp,
      ...rest,
    },
    pause
  );

  const metrics = useFormatMetricsResponse(data);

  const containersNum = data?.numOfContainers;

  return {
    loading,
    error,
    metrics,
    containersNum,
  };
};

export const useMetrics = (
  params: MetricsRequestParams
): AggregatedMetricsResponse => {
  const { pause = false, expectedPodContainersNum } = params;

  const {
    loading: usageLoading,
    metrics: usageMetrics,
    error: usageError,
  } = useMetricsPerType({ ...params, type: "usage" });

  const {
    loading: requestLoading,
    metrics: requestMetrics,
    error: requestError,
  } = useMetricsPerType({
    ...params,
    type: "request",
    dataCompletionType: "latest",
  });

  const {
    loading: limitLoading,
    metrics: limitMetrics,
    error: limitError,
    containersNum: limitContainersNum,
  } = useMetricsPerType({
    ...params,
    type: "limit",
    dataCompletionType: "latest",
  });

  return {
    paused: pause,
    loading: usageLoading || requestLoading || limitLoading,
    error: usageError || requestError || limitError,
    metrics: {
      usage: usageMetrics ?? [],
      request: requestMetrics ?? [],
      limit: getLimitMetricsByContainersNum({
        limitMetrics,
        limitContainersNum,
        expectedPodContainersNum,
      }),
    },
    limitContainersNum,
  };
};
