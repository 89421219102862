import {
  RightSizingCostSummaryByService,
  RightSizingCostSummaryByServiceResponse,
} from "../../../../generated/metricsApi";

import { getRandRange } from "./getRandRange";

type Resource = "cpu" | "memory";

const getMultipler = (resource: Resource) => (resource === "cpu" ? 10 : 10_000);

const getRandomCurrentRequest = (resource: Resource) =>
  Math.floor(Math.random() * getMultipler(resource)) + 1;

const getRandomRecommendation = (
  currentCpuRequest: number,
  resource: Resource
) =>
  Math.floor(
    Math.max(
      currentCpuRequest -
        getRandRange(1, Math.random() * getMultipler(resource)),
      currentCpuRequest / 3
    )
  );

export const generateContainersWithNoRecommendations =
  (): RightSizingCostSummaryByService[] => {
    const currentCpuRequest = getRandomCurrentRequest("cpu");
    const currentMemoryRequest = getRandomCurrentRequest("memory");

    const cpuRequestRecommendation = getRandomRecommendation(
      currentCpuRequest,
      "cpu"
    );
    const memoryRecommendation = getRandomRecommendation(
      currentMemoryRequest,
      "memory"
    );

    const isCpuWithNoRecommendation = Math.random() > 0.5;

    return [
      {
        containerName: "short",
        cpuRequestMiliCore: currentCpuRequest,
        cpuMiliCoreRecommendationAggressive: isCpuWithNoRecommendation
          ? currentCpuRequest
          : cpuRequestRecommendation - 1,
        cpuMiliCoreRecommendationConservative: isCpuWithNoRecommendation
          ? currentCpuRequest
          : cpuRequestRecommendation + 1,
        cpuMiliCoreRecommendationModerate: isCpuWithNoRecommendation
          ? currentCpuRequest
          : cpuRequestRecommendation,
        memoryRequestMiB: currentMemoryRequest,
        memoryRequestMiBRecommendationAggressive: !isCpuWithNoRecommendation
          ? currentMemoryRequest
          : memoryRecommendation - 1,
        memoryRequestMiBRecommendationConservative: !isCpuWithNoRecommendation
          ? currentMemoryRequest
          : memoryRecommendation + 1,
        memoryRequestMiBRecommendationModerate: !isCpuWithNoRecommendation
          ? currentMemoryRequest
          : memoryRecommendation,
      },
      {
        containerName: "very-long-container-name",
        cpuRequestMiliCore: currentCpuRequest,
        cpuMiliCoreRecommendationAggressive: currentCpuRequest,
        cpuMiliCoreRecommendationConservative: currentCpuRequest,
        cpuMiliCoreRecommendationModerate: currentCpuRequest,
        memoryRequestMiB: currentMemoryRequest,
        memoryRequestMiBRecommendationAggressive: currentMemoryRequest,
        memoryRequestMiBRecommendationConservative: currentMemoryRequest,
        memoryRequestMiBRecommendationModerate: currentMemoryRequest,
      },
    ];
  };

export const generateMockCostRightSizingRecommendation = (
  arrayLength = 3
): RightSizingCostSummaryByServiceResponse => {
  const generatedContainers = Array.from({
    length: arrayLength - 2,
  }).map<RightSizingCostSummaryByService>((_, i) => {
    const currentCpuRequest = getRandomCurrentRequest("cpu");
    const currentMemoryRequest = getRandomCurrentRequest("memory");

    const cpuRequestRecommendation = getRandomRecommendation(
      currentCpuRequest,
      "cpu"
    );
    const memoryRecommendation = getRandomRecommendation(
      currentMemoryRequest,
      "memory"
    );

    return {
      containerName: `container-name-${i}`,
      cpuRequestMiliCore: Math.floor(currentCpuRequest),
      cpuMiliCoreRecommendationAggressive: cpuRequestRecommendation - 1,
      cpuMiliCoreRecommendationConservative: cpuRequestRecommendation + 1,
      cpuMiliCoreRecommendationModerate: cpuRequestRecommendation,
      memoryRequestMiB: Math.floor(currentMemoryRequest),
      memoryRequestMiBRecommendationAggressive: memoryRecommendation - 1,
      memoryRequestMiBRecommendationConservative: memoryRecommendation + 1,
      memoryRequestMiBRecommendationModerate: memoryRecommendation,
      podName: `pod-name-${i}`,
    };
  });

  return {
    rows: [
      ...generatedContainers,
      ...generateContainersWithNoRecommendations(),
    ],
  };
};
