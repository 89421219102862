import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { PopoverProps } from "@mui/material/Popover/Popover";

import { useFocusOnKeyDown } from "./useFocusOnKeyDown";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { InfiniteScrollContainer } from "./InfiniteScrollContainer";

export const MAX_POPOVER_HEIGHT = 700;
export const INITIAL_SCROLL_SIZE = 100;

const Container = styled.div`
  position: relative;
`;

const StickyDiv = styled.div`
  width: 100%;
  min-width: 320px;
  height: 40px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const StyledInputBase = styled(InputBase)`
  width: 100%;
  padding: 4px 4px 4px 20px;
`;

const StyledPopover = styled(Popover)`
  && {
    & .MuiPaper-root {
      overflow-y: hidden;
    }
  }
`;

type SearchablePopoverProps = {
  options: MuiSelectionOption<string>[];
  getMenuItems: (options: MuiSelectionOption<string>[]) => React.ReactNode;
  triggerElement: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onTriggerElementClick: () => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  popoverProps?: PopoverProps;
  searchPlaceHolder?: string;
};

/** PopoverMenuListHandler handles MenuList search, infinite scrolling, focus and keyboard events  */
export const PopoverMenuListHandler: React.FC<SearchablePopoverProps> = ({
  open,
  options,
  getMenuItems,
  triggerElement,
  onClose,
  onTriggerElementClick,
  containerProps,
  popoverProps,
  searchPlaceHolder = "Search...",
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const menuListContentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [enableSearch, setEnableSearch] = useState(false);

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    enableSearch && setSearchTerm(e.target.value);
  };

  useFocusOnKeyDown({
    menuListContentRef,
    inputRef,
    disableListener: !open,
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => setEnableSearch(true), 500);
    } else {
      setEnableSearch(false);
    }
  }, [open]);

  const handleClose = () => {
    setSearchTerm("");
    setScrollSize(INITIAL_SCROLL_SIZE);
    onClose();
  };

  const handleOpen = () => {
    onTriggerElementClick();
  };

  const onSetScrollSize = (scrollSize: number) => {
    open && setScrollSize(scrollSize);
  };

  const [scrollSize, setScrollSize] = useState<number>(INITIAL_SCROLL_SIZE);

  const filteredOptionsBySearch = useMemo(() => {
    if (!searchTerm) return options.slice(0, scrollSize);
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return options
      .filter((option) =>
        option.label.toLowerCase().includes(lowerCaseSearchTerm)
      )
      .sort((a, b) => a.label.indexOf(searchTerm) - b.label.indexOf(searchTerm))
      .slice(0, scrollSize);
  }, [options, scrollSize, searchTerm]);

  useEffect(() => {
    if (open) {
      setTimeout(() => inputRef.current?.focus(), 1);
    }
  }, [open]);

  return (
    <Container {...containerProps}>
      <div onClick={handleOpen} ref={divRef}>
        {triggerElement}
      </div>

      <StyledPopover
        open={open}
        anchorEl={divRef.current}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        sx={{
          maxHeight: MAX_POPOVER_HEIGHT,
        }}
        container={divRef.current?.parentElement}
        {...popoverProps}
      >
        <>
          <StickyDiv>
            <StyledInputBase
              placeholder={searchPlaceHolder}
              autoFocus
              onChange={onSearchTermChange}
              inputRef={inputRef}
              value={searchTerm}
            />
            <Divider />
          </StickyDiv>

          <MenuList>
            <InfiniteScrollContainer
              allOptionsNum={options.length}
              currentOptionsNum={filteredOptionsBySearch.length}
              scrollSize={scrollSize}
              setScrollSize={onSetScrollSize}
            >
              <div ref={menuListContentRef}>
                {getMenuItems(filteredOptionsBySearch)}
              </div>
            </InfiniteScrollContainer>
          </MenuList>
        </>
      </StyledPopover>
    </Container>
  );
};
