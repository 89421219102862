import React, { useState, useMemo } from "react";
import styled from "styled-components";

import { EnlargedMetricsGraph } from "../../../../../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import {
  WorkloadMetricsContainers,
  WorkloadMetricsContainersDataPoints,
} from "../../../../../../../../../../../generated/metricsApi";
import {
  MetricsAggregationType,
  MetricsGraphProps,
} from "../../../../../../../../../../Metrics/types";
import { GraphContainerWithStatus } from "../../../../../../../../../../Metrics/GraphContainerWithStatus";
import { CommonApiResponse } from "../../../../../../../../../../../shared/types/commonApiResponse";
import { ResourceGraph } from "../../../../../../../../../../Metrics/graphs/ResourceGraph";
import { useGraphData } from "../../hooks/useGraphData";
import { MetricsAggregationSelector } from "../../../../../../../../../../Metrics/MetricsPreferences/MetricsAggregationSelector";
import { graphHeight } from "../../constants";
import { GenericLegend } from "../../../../../../../../../../Metrics/GenericLegend/GenericLegend";
import { getDefaultLegendItemsByType } from "../../../../../../../../../../Metrics/GenericLegend/genericLegendUtils";
import { useUidFromKomodorUid } from "../../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useIsViewDeployEventDetailsClicked } from "../../hooks/useIsViewDeployEventDetailsClicked";

const Container = styled.div`
  width: 100%;
`;

const graphMapper = {
  cpu: {
    title: "CPU usage",
    selectorAriaLabel: "cpu metrics aggregation selector",
    renderGraph: (graphProps: MetricsGraphProps) => (
      <ResourceGraph
        type={"cpu"}
        legendContent={
          <GenericLegend items={getDefaultLegendItemsByType("cpu")} />
        }
        constrictHeightForEvents={false}
        {...graphProps}
      />
    ),
  },
  memory: {
    title: "Memory usage",
    selectorAriaLabel: "memory metrics aggregation selector",
    renderGraph: (graphProps: MetricsGraphProps) => (
      <ResourceGraph
        type={"memory"}
        constrictHeightForEvents={false}
        legendContent={
          <GenericLegend items={getDefaultLegendItemsByType("memory")} />
        }
        {...graphProps}
      />
    ),
  },
};

interface Props {
  komodorUid: string;
  res: CommonApiResponse<WorkloadMetricsContainers>;
  containerName: string;
  wantedAggregation: MetricsAggregationType;
  type: keyof typeof graphMapper;
  graphTitle?: string;
}

export const MetricsGraph: React.FC<Props> = ({
  komodorUid,
  res,
  containerName,
  wantedAggregation,
  type,
  graphTitle,
}) => {
  const uid = useUidFromKomodorUid(komodorUid);
  const [isMetricsGraphEnlarged, setIsMetricsGraphEnlarged] = useState(false);

  const containerData = useMemo(
    () =>
      res.data?.containers.find(
        (container) => container.containerName === containerName
      ),
    [containerName, res.data?.containers]
  );
  const { graphProps, hasGraphData } = useGraphData({
    data: containerData?.dataPoints,
    aggregation: wantedAggregation,
    komodorUid,
  });

  const graphDetails = graphMapper[type];

  return (
    <Container>
      {isMetricsGraphEnlarged && (
        <ViolationEnlargedMetricsGraph
          komodorUid={komodorUid}
          onClose={() => setIsMetricsGraphEnlarged(false)}
          res={res}
          type={type}
          containerData={containerData}
          initialAggregation={wantedAggregation}
        />
      )}
      <GraphContainerWithStatus
        graphHeight={graphHeight}
        uid={uid}
        hasGraphData={hasGraphData}
        error={res.error}
        loading={res.loading}
        title={graphTitle ?? graphDetails.title}
        onEnlargeGraph={() => setIsMetricsGraphEnlarged(true)}
      >
        {graphDetails.renderGraph(graphProps)}
      </GraphContainerWithStatus>
    </Container>
  );
};

export const ViolationEnlargedMetricsGraph = ({
  onClose,
  res,
  type,
  komodorUid,
  containerData,
  initialAggregation,
}: {
  onClose: () => void;
  res: CommonApiResponse<WorkloadMetricsContainers>;
  komodorUid: string;
  type: keyof typeof graphMapper;
  initialAggregation: MetricsAggregationType;
  containerData: WorkloadMetricsContainersDataPoints | undefined;
}) => {
  const [aggregation, setAggregation] =
    useState<MetricsAggregationType>(initialAggregation);

  const uid = useUidFromKomodorUid(komodorUid);

  const { graphProps } = useGraphData({
    data: containerData?.dataPoints,
    aggregation: aggregation,
    komodorUid,
  });

  const isViewDeployEventDetailsClicked = useIsViewDeployEventDetailsClicked();

  const graphDetails = graphMapper[type];
  return (
    <EnlargedMetricsGraph
      uid={uid}
      onClose={onClose}
      title={graphDetails.title}
      hasGraphData={Boolean(res.data)}
      error={res.error}
      loading={res.loading}
      controls={[
        <MetricsAggregationSelector
          ariaLabel={graphDetails.selectorAriaLabel}
          aggregationMetric={aggregation}
          setAggregationMetric={setAggregation}
        />,
      ]}
      shouldCloseOnClickCapture={isViewDeployEventDetailsClicked}
    >
      {graphDetails.renderGraph(graphProps)}
    </EnlargedMetricsGraph>
  );
};
