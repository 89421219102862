import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Popover, MenuList } from "@komodorio/design-system/deprecated";
import { Ellipsis24 } from "@komodorio/design-system/icons";
import MoreVert from "@mui/icons-material/MoreVert";

import type Resource from "../../../resources";
import { AvailableActions } from "../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CordonUncordonButton } from "../../../../Actions/buttons/CordonUncordonButton";
import { actionsMapping } from "../../../../Actions/common/actionsStyleMap";
import {
  getActionItem,
  getButton,
} from "../../../../Actions/common/getActionButtonStyle";
import UpgradeHelmChartButton from "../../../../Actions/buttons/helm/UpgradeHelmChartButton";
import { ZIndex } from "../../../../../constants/zIndex";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { AvailabilityResource } from "../../../../../shared/store/investigationModeStore/investigationModeStore";

import { useFilterAvailableActions } from "@/components/ResourceView/common/Header/Actions/headerActionHooks";

const MAX_BUTTONS = 3;

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  height: fit-content;
  align-items: center;
`;

const MenuContent: React.FC<{
  resource: Resource;
  actions: AvailableActions[];
  disableAll?: boolean;
  setIsActionActive: (isActionActive: boolean) => void;
}> = ({ resource, actions, disableAll, setIsActionActive }) => (
  <MenuList>
    {actions.map((action) => {
      const GenerateUI =
        action === AvailableActions.CordonUncordon
          ? CordonUncordonButton
          : action === AvailableActions.UpgradeHelmChart
          ? UpgradeHelmChartButton
          : actionsMapping[action].button;
      const actionItem = getActionItem(action, disableAll, resource);

      return (
        <GenerateUI
          key={action}
          resource={resource}
          Button={actionItem}
          setIsActionActive={setIsActionActive}
        />
      );
    })}
  </MenuList>
);

const ActionsPopover: React.FC<{
  resource: Resource;
  actions: AvailableActions[];
  disableAll?: boolean;
}> = ({ resource, actions, disableAll = false }) => {
  const [open, setOpen] = useState(false);
  const [isActionActive, setIsActionActive] = useState(false);

  const content = (
    <MenuContent
      actions={actions}
      resource={resource}
      disableAll={disableAll}
      setIsActionActive={setIsActionActive}
    />
  );

  const handleClose = () => {
    if (!isActionActive) {
      setOpen(false);
    }
  };

  return (
    <Popover
      containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
      content={content}
      handleClose={handleClose}
      isOpen={open}
      align="end"
    >
      <IconButton
        onClick={() => setOpen(true)}
        data-e2e-selector="more-actions-button"
      >
        <Ellipsis24 />
      </IconButton>
    </Popover>
  );
};

const ActionButtons: React.FC<{
  resource: Resource;
  actions: AvailableActions[];
  disableAll?: boolean;
}> = ({ resource, actions, disableAll = false }) => {
  return (
    <>
      {actions.map((action) => {
        const GenerateUI =
          action === AvailableActions.CordonUncordon
            ? CordonUncordonButton
            : action === AvailableActions.UpgradeHelmChart
            ? UpgradeHelmChartButton
            : actionsMapping[action].button;

        const button = getButton(action, disableAll, resource);

        return <GenerateUI key={action} resource={resource} Button={button} />;
      })}
    </>
  );
};

export const HeaderActions: React.FC<{
  resource: Resource;
  disableAll?: boolean;
}> = ({ resource, disableAll = false }) => {
  const actions = useFilterAvailableActions(resource);

  if (actions.length <= MAX_BUTTONS + 1)
    return (
      <Container>
        <ActionButtons
          resource={resource}
          actions={actions}
          disableAll={disableAll}
        />
      </Container>
    );

  const buttons = actions.slice(0, MAX_BUTTONS);
  const actionsItems = actions.slice(MAX_BUTTONS);

  return (
    <Container>
      <ActionButtons
        resource={resource}
        actions={buttons}
        disableAll={disableAll}
      />
      <ActionsPopover
        resource={resource}
        actions={actionsItems}
        disableAll={disableAll}
      />
    </Container>
  );
};

export const QuickActionsMenu: React.FC<{
  resource: AvailabilityResource;
  allowedActions: AvailableActions[];
  disableAll?: boolean;
}> = ({ resource, allowedActions, disableAll = false }) => {
  const [open, setOpen] = useState(false);
  const [isActionActive, setIsActionActive] = useState(false);

  const actions = resource.actions;

  const actionsItems = actions.filter((action) =>
    allowedActions.includes(action)
  );

  const handleClose = () => {
    if (!isActionActive) {
      setOpen(false);
    }
  };

  const content = (
    <MenuContent
      actions={actionsItems}
      resource={resource}
      disableAll={disableAll}
      setIsActionActive={setIsActionActive}
    />
  );

  if (actionsItems.length === 0) {
    return null;
  }

  return (
    <Tooltip
      title={
        resource?.isDeleted
          ? "Actions cannot be performed on deleted services"
          : undefined
      }
    >
      <div>
        <Popover
          containerStyle={{ zIndex: ZIndex.AboveModal.toString() }}
          content={content}
          handleClose={handleClose}
          isOpen={open}
          align="end"
        >
          <IconButton
            sx={{ padding: "4px" }}
            onClick={() => setOpen(true)}
            data-e2e-selector="actions-button"
            aria-label={AriaLabels.InvestigationMode.ActionsButton}
            disabled={resource?.isDeleted}
            color="primary"
            title="More actions"
          >
            <MoreVert />
          </IconButton>
        </Popover>
      </div>
    </Tooltip>
  );
};
