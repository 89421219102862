import { useEffect, useState } from "react";

export const useIsTabVisible = () => {
  const [tabVisible, setTabVisible] = useState(true);

  useEffect(() => {
    const callback = () =>
      setTabVisible(document.visibilityState === "visible");
    window.addEventListener("visibilitychange", callback);
    return () => {
      window.removeEventListener("visibilitychange", callback);
    };
  }, []);

  return tabVisible;
};
