import { useMemo } from "react";

import { getReasonType } from "../../../../../../../InvestigationMode/StepsLogic";
import { AvailabilityInvestigationIssueType } from "../../../../../../../InvestigationMode/availability/types";

export const useIssueReasonInsight = (issueType: string, subReason: string) => {
  const issueReason = useMemo(
    () => getReasonType(issueType, subReason),
    [issueType, subReason]
  );

  return {
    shouldShow:
      issueReason ===
      AvailabilityInvestigationIssueType.OOMKilledContainerLimit,
    text: "Container's limit reached",
  };
};
