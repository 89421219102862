import { useCallback, useEffect, useMemo, useState } from "react";

import { useCostOptimizationDataInUrl } from "../../../hooks/persistentStateHooks";
import { ClusterData } from "../../../../../shared/hooks/useGetAllClusters/useGetAllClusters";
import { useClusterOptionsByAppView } from "../../../../../shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { AllocationFiltersScope } from "../../../types/costOptimizationTypes";
import { initialState } from "../../../store/initialState";
import { isDevMode } from "../../../../../shared/utils/isDevMode";
import { useGetSupportedClustersData } from "../../../hooks/useSupportedAgentVersions";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  agentUpdateRequiredTooltipText,
  collectingDataTooltipText,
} from "./ScopeFilterContainer";
import { ScopeFilterOption } from "./types";

const getClusterDataByClusterNames = (
  clusterNames: string[],
  clusterOptions: ClusterData[]
): ClusterData[] => {
  return clusterNames.reduce<ClusterData[]>((acc, clusterName) => {
    const foundClusterData = clusterOptions.find(
      (cluster) => cluster.clusterName === clusterName
    );
    if (foundClusterData) {
      acc.push(foundClusterData);
    }
    return acc;
  }, []);
};

export const useGetScopeFilters = () => {
  const clusterOptions = useClusterOptionsByAppView(isDevMode());
  const [{ sharedFilters = {} }] = useCostOptimizationDataInUrl();
  const scopeFromUrl = sharedFilters?.scope;
  const [scopeFilters, setScopeFilters] = useState<AllocationFiltersScope>(
    initialState.sharedCostFilters.scope
  );
  const getSupportedClusters = useGetSupportedClusters();

  useEffect(() => {
    // update scope filters from url or from exising cluster options
    const scopeToUse: AllocationFiltersScope = scopeFromUrl?.clusters
      ? {
          clusters: getClusterDataByClusterNames(
            scopeFromUrl.clusters,
            clusterOptions
          ),
        }
      : { clusters: clusterOptions };

    setScopeFilters({ clusters: getSupportedClusters(scopeToUse.clusters) });
  }, [clusterOptions, getSupportedClusters, scopeFromUrl]);

  return scopeFilters;
};

const useGetSupportedClusters = () => {
  const getSupportedClustersData = useGetSupportedClustersData();

  return useCallback(
    (clusterOptions: ClusterData[]) => {
      const supportedClustersData = getSupportedClustersData(clusterOptions);
      return supportedClustersData.filter(
        ({ isSupported, isNew }) => isSupported && !isNew
      );
    },
    [getSupportedClustersData]
  );
};

export const useSupportedClusterOptionsByAppView = () => {
  const getSupportedClusters = useGetSupportedClusters();
  const clusterOptions = useClusterOptionsByAppView(isDevMode());

  return useMemo(
    () => getSupportedClusters(clusterOptions),
    [clusterOptions, getSupportedClusters]
  );
};

export const useGetMultiselectOptions = (clusterOptions: ClusterData[]) => {
  const getSupportedClustersData = useGetSupportedClustersData();

  return useMemo<ScopeFilterOption[]>(() => {
    const supportedClustersData = getSupportedClustersData(clusterOptions);

    return supportedClustersData.map(({ isSupported, isNew, clusterName }) => {
      const disabledTooltipContent = isSupported
        ? !isNew
          ? undefined
          : collectingDataTooltipText
        : agentUpdateRequiredTooltipText;

      const option = {
        label: clusterName,
        value: clusterName,
        disabled: !isSupported || isNew,
        disabledTooltipContent,
      };
      return { option, isClusterNew: isNew };
    });
  }, [clusterOptions, getSupportedClustersData]);
};
