import React, { useCallback } from "react";

import { ViolationsAggregationGroup } from "../../../../../../../generated/reliabilityApi";
import { GroupByOptions } from "../../../../../ReliabilityTypes";
import { useReliabilityStore } from "../../../../../store/reliabilityStore";
import {
  selectSetGroupExpandedState,
  selectViolationsState,
} from "../../../../../store/reliabilityStoreSelectors";

import { ViolationsGroupCard } from "./ViolationsGroupCard";
import { getCardTexts } from "./getCardTexts";

interface Props {
  group: ViolationsAggregationGroup;
  groupBy: GroupByOptions;
}
export const ViolationsGroup: React.FC<Props> = ({ group, groupBy }) => {
  const { id } = group;

  const { groups } = useReliabilityStore(selectViolationsState);
  const setIsGroupExpanded = useReliabilityStore(selectSetGroupExpandedState);
  const groupState = groups[groupBy];

  const isOpen = groupState[id]?.isExpanded;

  const onExpandClick = useCallback(() => {
    setIsGroupExpanded(groupBy, id);
  }, [groupBy, id, setIsGroupExpanded]);

  const { title } = getCardTexts(id, groupBy);

  return (
    <ViolationsGroupCard
      {...group}
      isOpen={isOpen}
      title={title}
      onExpandClick={onExpandClick}
      groupBy={groupBy}
    />
  );
};
