import { useEffect, useMemo, useState } from "react";

import {
  CostApiGetCostOptimizationTrendsRequest,
  CostOptimizationScoreTrendResponse,
  OptimizationStrategy,
  RightSizingCostSummaryResponse,
  ThrottledAppContainersResponse,
} from "../../../../../../../generated/metricsApi";
import {
  COST_OPTIMIZATION_TRENDS_ENDPOINT,
  COST_RIGHT_SIZING_SUMMARY_ENDPOINT,
  EndpointsRequestMap,
  EndpointsResponseMap as MetricsEndpointsResponseMap,
  THROTTLED_APPLICATIONS_ENDPOINT,
} from "../../../../../../../shared/hooks/metrics-api/requestResponseMaps";
import { EndpointsResponseType } from "../../../../../../../shared/hooks/common-api/types";
import {
  MetricsApiResponse,
  useMetricsAPIGet,
} from "../../../../../../../shared/hooks/metrics-api/client";
import { getBucketTimeFrame } from "../utils/overviewPageUtils";
import { mockCostOptimizationTrends } from "../../../__tests__/mocks/overviewPage/mockCostOptimizationTrends";
import { initialResponse } from "../store/initialState";
import {
  selectOverviewMetricsData,
  selectSetOverviewMetricsData,
  selectTimeWindowIntervals,
} from "../store/overviewPageStoreSelectors";
import { useOverviewPageStore } from "../store/overviewPageStore";
import { generateMockThrottledApplications } from "../../../__tests__/mocks/overviewPage/mockAppInsightsThrottledApplications";
import { TOTAL_THROTTLED_TIME_THRESHOLD } from "../Insights/insightsConstants";
import { useOverridableFlags } from "../../../../../../../shared/context/featureFlags/OverridableFlags";
import { isDevMode } from "../../../../../../../shared/utils/isDevMode";
import { hasServerResChanged } from "../../../../../../../shared/utils/hasServerResChanged";
import { mockPotentialSavingsResponse } from "../../../__tests__/mocks/overviewPage/mockPotentialSavings";

import {
  useSetMockDataEffect,
  useShouldPauseRequest,
  useTimeWindowAsRequestParam,
  useUpdateAppInsightsData,
} from "./fetchDataHooks";
import { useHasSufficientMetricsData } from "./overviewPageHooks";

export const useFetchMetricsData = () => {
  useFetchCostOptimizationTrends();
  useFetchPotentialCostSavings();
  useFetchThrottledApplications();
};

const useTimeWindowIntervalsRequestParam =
  (): CostApiGetCostOptimizationTrendsRequest => {
    const timeWindowIntervals = useOverviewPageStore(selectTimeWindowIntervals);
    const bucketTimeFrame = getBucketTimeFrame();
    return useMemo(
      () => ({
        costOptimizationInterval: timeWindowIntervals,
        bucketTimeFrame,
      }),
      [bucketTimeFrame, timeWindowIntervals]
    );
  };

const useMockData = <T extends object>(mockData?: T) => {
  const [res, setRes] = useState<MetricsApiResponse<T>>(initialResponse);
  const hasSufficientMetricsData = useHasSufficientMetricsData();
  useSetMockDataEffect({
    mockData,
    setRes,
    pauseRequest: !hasSufficientMetricsData,
  });
  return res;
};

export const useMetricsApiWithPauseLogic = <
  M extends { [key in keyof EndpointsResponseType]: M },
  T extends keyof MetricsEndpointsResponseMap = keyof MetricsEndpointsResponseMap
>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  shouldPauseRequest?: boolean
): MetricsApiResponse<M> => {
  const shouldPause = useShouldPauseRequest();
  const hasSufficientMetricsData = useHasSufficientMetricsData();
  return useMetricsAPIGet<M>(
    relativePath,
    parameters,
    shouldPauseRequest || shouldPause || !hasSufficientMetricsData
  );
};

const useFetchCostOptimizationTrends = () => {
  const timeWindowRequestParam = useTimeWindowIntervalsRequestParam();
  const currentOverviewMetricsData = useOverviewPageStore(
    selectOverviewMetricsData
  );
  const setOverviewMetricsData = useOverviewPageStore(
    selectSetOverviewMetricsData
  );
  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode()
        ? mockCostOptimizationTrends(timeWindowRequestParam)
        : undefined,
    [timeWindowRequestParam]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes =
    useMetricsApiWithPauseLogic<CostOptimizationScoreTrendResponse>(
      COST_OPTIMIZATION_TRENDS_ENDPOINT,
      timeWindowRequestParam
    );

  const dataToUse = isDevMode() ? mockData : serverRes;
  const storeKey = "costOptimizationTrends";

  useEffect(() => {
    if (hasServerResChanged(currentOverviewMetricsData[storeKey], dataToUse)) {
      setOverviewMetricsData(storeKey, dataToUse);
    }
  }, [currentOverviewMetricsData, dataToUse, setOverviewMetricsData]);
};

const useFetchPotentialCostSavings = () => {
  // simulate server responses
  const mockResponse = useMemo(
    () => (isDevMode() ? mockPotentialSavingsResponse() : undefined),
    []
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////!*!/

  // default strategy is moderate
  const strategy: OptimizationStrategy = "moderate";
  const requestParams = { ...useTimeWindowAsRequestParam(), strategy };
  const serverRes = useMetricsApiWithPauseLogic<RightSizingCostSummaryResponse>(
    COST_RIGHT_SIZING_SUMMARY_ENDPOINT,
    requestParams
  );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("potentialCostSavings", dataToUse);
};

const initialFetchState = {
  loading: false,
  data: undefined,
  error: null,
} as MetricsApiResponse<ThrottledAppContainersResponse>;

export const useFetchThrottledApplications = () => {
  const timeWindow = useTimeWindowAsRequestParam();
  const { showThrottledAppViewContainersInsight } = useOverridableFlags();

  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode() ? generateMockThrottledApplications(timeWindow) : undefined,
    [timeWindow]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes = useMetricsApiWithPauseLogic<ThrottledAppContainersResponse>(
    THROTTLED_APPLICATIONS_ENDPOINT,
    {
      totalThrottleTimeThreshold: TOTAL_THROTTLED_TIME_THRESHOLD,
      fromEpoch: timeWindow.fromEpoch,
      toEpoch: timeWindow.toEpoch,
    },
    !showThrottledAppViewContainersInsight
  );

  const dataToUse = !showThrottledAppViewContainersInsight
    ? initialFetchState
    : isDevMode()
    ? mockData
    : serverRes;

  useUpdateAppInsightsData("throttledApplications", dataToUse);
};
