import { MutableRefObject, useCallback } from "react";

import { useKeydown } from "../../hooks/useKeydown";

type FocusOnKeyDownParams = {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  menuListContentRef: MutableRefObject<HTMLDivElement | null>;
  disableListener: boolean;
};

export const useFocusOnKeyDown = ({
  inputRef,
  menuListContentRef,
  disableListener,
}: FocusOnKeyDownParams) => {
  const onKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        if (document.activeElement !== inputRef.current) return;
        const firstChild = menuListContentRef.current
          ?.childNodes[0] as HTMLElement;
        if (firstChild) {
          firstChild.focus();
          e.preventDefault();
        }
      }
    },
    [inputRef, menuListContentRef]
  );

  useKeydown({ cb: onKeydown, disableListener });
};
