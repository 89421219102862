import React from "react";
import styled from "styled-components";

export const Section = styled.div<{ topDivider?: boolean }>`
  padding: 1.3rem;
  ${({ topDivider = true }) => topDivider && "border-top: 1px solid #dfdddf;"}
`;

export const SectionContent = styled.div`
  margin-inline: 2.25rem 1rem;
  margin-top: 1rem;
`;

const SectionNumber = styled.div`
  width: 20px;
  height: 20px;
  left: 2px;
  top: 2px;

  color: #3b3d45;
  border-radius: 50%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #eceff2;
  border-radius: 56px;
  margin-right: 1rem;
`;

export const SectionTitle = styled.div<{ fontSize?: string }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize ?? "16px"};
`;

const SectionTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const RuleStepSectionContainer: React.FC<{
  sectionNumber: number;
  title: string;
  children?: React.ReactNode;
}> = ({ sectionNumber, title, children }) => {
  return (
    <Section>
      <SectionTitleRow>
        <SectionNumber>{sectionNumber}</SectionNumber>
        <SectionTitle>{title}</SectionTitle>
      </SectionTitleRow>
      <SectionContent>{children}</SectionContent>
    </Section>
  );
};
