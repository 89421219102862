export const TooltipMessage_ClusterOutOfSync =
  "This cluster is out of sync with the latest changes, please check back later - Policies are synchronized every minute";

export const TooltipMessage_ClusterNoPoliciesMatchingScope =
  "There are no matching policies that would be applied on this cluster";

export const tooltipMessageTemplate_ClusterEncounteredErrorWhileSyncing = (
  errorMessage: string
) =>
  `This cluster encountered an error while syncing the RBAC: ${errorMessage}`;

export const TooltipMessage_ClusterVersionOutOfDate =
  "This cluster is running an outdated version of the agent. Please update the agent to the latest helm chart version and enable the `rbac` capability";

export const TooltipMessage_InfoWhatIsDisplayed =
  "This list only displays clusters with an active agent and at least one matching RBAC policy";
