import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";
import { PodsOutput } from "../../CommonTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeHealthyUserPodsDetails from "./detailsComponents/NodeHealthyUserPodsDetails";
interface NodeHealthyUserPodsOutput {
  pods?: PodsOutput[];
  hiddenPods?: number;
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class NodeHealthyUserPodsCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.icon = check.passed ? "success" : "warn";
    this.title = "Pod health";
    this.description = `If some pods are unhealthy, it might be connected to the issue the node is facing. 
    If the node is under memory pressure and experiences OOM, this can result in containers getting killed due to Out of memory.`;
    this.shortOutput = check.passed
      ? "All pods are healthy"
      : "There are unhealthy pods on this node";
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={`<NODE>`}, metadata.namespace!=kube-system
        </GrayHighlight>
        to see which pods are not healthy.
      </>
    );
    this.question = "Are all the user pods healthy?";
    this.output = check.output as NodeHealthyUserPodsOutput;
    this.isFinding = true;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeHealthyUserPodsDetails check={this} />;
  }
}
