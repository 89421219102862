import { useMemo } from "react";

import { millisecondsToUnixTime } from "../../shared/utils/timeUtils";

import { calculateTimeframe } from "./utils";
import { zeroDate } from "./types";

interface CustomMetricsParams {
  fromEpoch: number;
  toEpoch: number;
  pause: boolean;
}

export const useCustomMetricsParams = (
  prevTimeframe: { start?: Date; end?: Date } | undefined,
  timeframe: { start?: Date; end?: Date } | undefined
): CustomMetricsParams => {
  return useMemo(() => {
    const isTimeframeChanged =
      calculateTimeframe(prevTimeframe?.start, prevTimeframe?.end) !==
      calculateTimeframe(timeframe?.start, timeframe?.end);

    const isZeroPreviousTimeframe =
      prevTimeframe?.start?.getTime() === zeroDate.getTime() ||
      prevTimeframe?.end?.getTime() === zeroDate.getTime();

    const timeWindow =
      isTimeframeChanged || isZeroPreviousTimeframe
        ? { start: timeframe?.start, end: timeframe?.end }
        : { start: prevTimeframe?.start, end: prevTimeframe?.end };

    const shouldFetchCustom =
      timeWindow?.start &&
      timeWindow?.end &&
      timeWindow?.start.getTime() !== zeroDate.getTime() &&
      timeWindow?.end.getTime() !== zeroDate.getTime();

    return {
      fromEpoch: timeWindow?.start
        ? millisecondsToUnixTime(timeWindow.start.getTime())
        : 0,
      toEpoch: timeWindow?.end
        ? millisecondsToUnixTime(timeWindow.end.getTime())
        : 0,
      pause: !shouldFetchCustom,
    };
  }, [
    prevTimeframe?.end,
    prevTimeframe?.start,
    timeframe?.end,
    timeframe?.start,
  ]);
};
