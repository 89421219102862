import React, { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { IgnoreRule } from "../../../../../../../../generated/reliabilityApi";
import { IgnoreChecksTableActions } from "../IgnoreChecksTableActions";
import { IgnoreChecksTextWithTooltip } from "../IgnoreChecksTextWithTooltip";

export const useViolationsTableColumns = (): GridColDef<IgnoreRule>[] => {
  return useMemo(
    () => [
      {
        field: "clusters",
        headerName: "Cluster",
        sortable: false,
        renderCell: (params) => (
          <IgnoreChecksTextWithTooltip
            ignoreRule={params.row}
            scopeType={"clusterNames"}
          />
        ),
        flex: 1,
      },
      {
        field: "namespaces",
        headerName: "Namespace",
        sortable: false,
        renderCell: (params) => (
          <IgnoreChecksTextWithTooltip
            ignoreRule={params.row}
            scopeType={"namespaces"}
          />
        ),
        flex: 1,
      },
      {
        field: "services",
        headerName: "Service",
        sortable: false,
        renderCell: (params) => (
          <IgnoreChecksTextWithTooltip
            ignoreRule={params.row}
            scopeType={"shortResourceNames"}
          />
        ),
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: (params) => (
          <IgnoreChecksTableActions ignoreRule={params.row} />
        ),
      },
    ],
    []
  );
};

export const useFilterIgnoreRulesBySearchTerm = (
  ignoreRules: IgnoreRule[],
  searchTerm: string
) => {
  return useMemo<IgnoreRule[]>(() => {
    return ignoreRules.filter((ignoreRule) => {
      const { clusterNames, namespaces, shortResourceNames } = ignoreRule.scope;
      return (
        !searchTerm ||
        clusterNames.join("").includes(searchTerm) ||
        namespaces?.join("").includes(searchTerm) ||
        shortResourceNames?.join("").includes(searchTerm)
      );
    });
  }, [ignoreRules, searchTerm]);
};
