import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";

import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { RbacRole } from "../../../generated/auth";
import { useDeleteRbacRole } from "../../../shared/hooks/auth-service/client/rbacRoles/useDeleteRbacRole";

const DeleteRoleModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  role: RbacRole;
  refreshRoles: () => void;
}> = ({ open, handleClose, role, refreshRoles }) => {
  const { mutateAsync: deleteRole, isLoading: deleteLoading } =
    useDeleteRbacRole();

  const handleDelete = async () => {
    const result = await deleteRole({ id: role.id });
    dispatchEvent(AnalyticEvents.Settings.DeleteRole, {
      roleName: result.name,
    });
    refreshRoles();
    handleClose();
  };

  return (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Delete Role</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to delete this role?
          <br />
          <br />
          Name: <b>{role.name}</b>
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          disabled={deleteLoading}
        >
          {deleteLoading ? "Deleting..." : "Delete"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeleteRoleModal;
