import { palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const StatusContainer = styled.div<{ clickable: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  transition: border-color 0.2s;

  ${({ clickable }) => !clickable && `pointer-events: none;`}
  :hover {
    cursor: pointer;
    border: 1px solid ${palette.blue[500]};
  }
`;
export const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StatusRow = styled.div<{ alignItems?: string }>`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
`;

export const StatusTitle = styled(Typography).attrs({
  variant: "overline2",
  color: "textSecondary",
  noWrap: true,
})`
  font-size: 11px;
`;

export const StatusValue = styled(Typography).attrs({
  variant: "body2",
  noWrap: true,
})``;
