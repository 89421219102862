import { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Resource } from "../../ResourceView/tabs/DependenciesTab/DependenciesTab";
import { getNetworkGraphData } from "../getNetworkGraphData";
import {
  NetworkMapGraphResponse,
  KomodorServicesResponse,
} from "../../../generated/resourcesApi";
import { createKomoUidToNodeDataMapper } from "../utils/nodeUtils";

export const useGetNormalizedNetworkMapNodes = ({
  resource,
  networkNodes,
  networkNodesData,
}: {
  resource: Resource;
  networkNodes: NetworkMapGraphResponse;
  networkNodesData: KomodorServicesResponse;
}) => {
  const uidToServiceMap = useMemo(
    () => createKomoUidToNodeDataMapper(networkNodesData?.data),
    [networkNodesData?.data]
  );

  return getNetworkGraphData({
    graphData: networkNodes,
    nodesData: networkNodesData.data || [],
    resource,
    uidToServiceMap,
  });
};
