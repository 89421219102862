import { RefObject, useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";

import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";
import {
  useReportEagerLoadingState,
  ReportEagerLoadingStateParams,
} from "../../../../shared/hooks/datadog-rum/useReportEagerLoadingState";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

type ReportLogsDDRumParams = {
  divRef: RefObject<HTMLDivElement>;
  fetching: boolean;
};

export const useReportLogsDDRum = ({
  divRef,
  fetching,
}: ReportLogsDDRumParams) => {
  const isDisplayed = useIsElementInViewport({ ref: divRef });

  const generateParams = useCallback(
    () => ({
      viewName: dataDogViewNames.resourceViewLogsTab,
      loadingState: { logs: fetching },
      isDisplayed,
    }),
    [fetching, isDisplayed]
  );

  const [stateParams, setStateParams] = useState<ReportEagerLoadingStateParams>(
    generateParams()
  );

  useEffect(() => {
    const newParams = generateParams();
    if (!isEqual(newParams, stateParams)) {
      setStateParams(newParams);
    }
  }, [fetching, generateParams, isDisplayed, stateParams]);

  const { resetTiming } = useReportEagerLoadingState(stateParams);

  useEffect(() => {
    if (fetching) {
      resetTiming();
    }
  }, [fetching, resetTiming]);
};
