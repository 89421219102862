import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const duration = 4_000;

export interface RecommendationsSubmissionIndicationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isAppliedSuccessfully: boolean;
}

export const RecommendationsSubmissionIndication: React.FC<
  RecommendationsSubmissionIndicationProps
> = ({ open, setOpen, isAppliedSuccessfully }) => {
  const snackbarText = isAppliedSuccessfully
    ? "Recommendations applied successfully"
    : "Update failed";

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={duration}
      message={snackbarText}
    >
      {!isAppliedSuccessfully ? (
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
