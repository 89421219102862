import { format, isToday } from "date-fns";

import { TimeStampDataPoints } from "../types";
import { getRelativeDiff } from "../../../../../../../../../shared/utils/numbers/getPercentage";

import { getMiddleIndex } from "./utils";
import { getFormattedDurationsFromTimestamps } from "./graphUtils";

const dateFormat = "MMMM d";

const getFormattedDates = (ts1: number, ts2: number) => {
  const secondEpoch = isToday(ts2) ? "today" : format(ts2, dateFormat);
  return `${format(ts1, dateFormat)} - ${secondEpoch}`;
};

type TotalsPerIntervalParams = {
  tsDataPoints: TimeStampDataPoints;
  fromIndex: number;
  toIndex: number;
};

const getTotalsPerInterval = ({
  tsDataPoints,
  toIndex,
  fromIndex,
}: TotalsPerIntervalParams) => {
  return tsDataPoints.slice(fromIndex, toIndex).reduce((acc, curr) => {
    acc += curr.value;
    return acc;
  }, 0);
};

export type DataPointsBreakdownPerIntervals = {
  firstInterval: string;
  secondInterval: string;
  firstIntervalDuration: string;
  secondIntervalDuration: string;
  firstTotal: number;
  secondTotal: number;
  firstIntervalAverage: number;
  secondIntervalAverage: number;
  trendPercentage: string;
  firstIntervalPercentage: number;
  secondIntervalPercentage: number;
  isIncreased: boolean;
};

export const getDataPointsBreakdownPerIntervals = (
  tsDataPoints: TimeStampDataPoints = []
): DataPointsBreakdownPerIntervals | undefined => {
  if (!tsDataPoints || tsDataPoints.length <= 1) return undefined;

  const {
    firstDuration: firstIntervalDuration = "",
    secondDuration: secondIntervalDuration = "",
  } = getFormattedDurationsFromTimestamps(tsDataPoints, "full") ?? {};

  const middleIndex = getMiddleIndex(tsDataPoints);
  const firstInterval = [tsDataPoints[0].ts, tsDataPoints[middleIndex - 1].ts];
  const secondInterval = [
    tsDataPoints[middleIndex].ts,
    tsDataPoints[tsDataPoints.length - 1].ts,
  ];

  const firstIntervalAsFormattedDateRange = getFormattedDates(
    firstInterval[0],
    firstInterval[1]
  );
  const secondIntervalAsFormattedDateRange = getFormattedDates(
    secondInterval[0],
    secondInterval[1]
  );

  const firstTotal = getTotalsPerInterval({
    tsDataPoints,
    fromIndex: 0,
    toIndex: middleIndex,
  });
  const secondTotal = getTotalsPerInterval({
    tsDataPoints,
    fromIndex: middleIndex,
    toIndex: tsDataPoints.length,
  });
  const firstIntervalAverage = firstTotal / middleIndex;
  const secondIntervalAverage =
    secondTotal / (tsDataPoints.length - middleIndex);

  const { percentageChange, isIncreased } = getRelativeDiff(
    firstTotal,
    secondTotal
  );
  const maxVal = Math.max(firstTotal, secondTotal);
  const firstIntervalPercentage = (firstTotal / maxVal) * 100;
  const secondIntervalPercentage = (secondTotal / maxVal) * 100;

  return {
    firstInterval: firstIntervalAsFormattedDateRange,
    secondInterval: secondIntervalAsFormattedDateRange,
    firstIntervalDuration,
    secondIntervalDuration,
    firstTotal,
    secondTotal,
    firstIntervalAverage,
    secondIntervalAverage,
    trendPercentage: percentageChange,
    firstIntervalPercentage,
    secondIntervalPercentage,
    isIncreased,
  };
};
