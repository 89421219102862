import { palette, theme } from "@komodorio/design-system";
import styled from "styled-components";

export const MetricsUsageColor = palette.darkBlue["500"];
export const MetricsUsageColor1 = palette.pink["700"];
export const MetricsUsageColor2 = palette.purple["300"];
export const MetricsUsageColor3 = palette.orange["600"];
export const MetricsUsageColor4 = palette.blue["400"];
export const MetricsCapacityColor = palette.orange["600"];
export const MetricsUsageContourColor = palette.darkBlue["500"];
export const MetricsRequestColor = palette.gray["400"];
export const MetricsLimitColor = palette.gray["600"];
export const MetricsAllocatableColor = palette.purple["400"];
export const GraphGridColor = "#EBEDF4";
export const GraphBackgroundColor = "#FBFCFF";
export const GraphContainerBorderColor = palette.gray["300"];
export const LegendLabelTextColor = theme.foreground.fgSubtle;
export const GraphContainerHeight = "25rem";
export const GraphMinimizedContainerHeight = "6rem";
export const MetricsRecommendationColor = palette.green["700"];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const GraphChartMargin = {
  right: 5,
  left: 5,
  bottom: 2,
};

export const MetricsChartContainer = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const BorderStyle = {
  border: ` ${palette.gray[200]} 1px solid`,
  borderRadius: "4px 4px 0px 0px",
};
