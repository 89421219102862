import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 560px;
  height: 354px;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledDialogActions = styled(DialogActions)`
  align-self: flex-end;
  margin-right: 16px;
  margin-bottom: 8px;
`;

interface DialogFeedbackProps {
  dialogLabel: string;
  submitButtonLabel: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onValueChange: (value: string) => void;
  isLoading: boolean;
}

export const DialogFeedback: React.FC<DialogFeedbackProps> = ({
  dialogLabel,
  submitButtonLabel,
  open,
  onClose,
  onSubmit,
  onValueChange,
  isLoading,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(false);

  return (
    <Dialog aria-label={dialogLabel} open={open} onClose={onClose}>
      <Container>
        <DialogTitle>
          <Typography variant="h3">Thank you for your feedback</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" marginTop="12px">
            We’re always working to improve Komodor and your feedback is a
            crucial part of achieving that
          </Typography>
          <Typography variant="h4" marginTop="24px">
            How can we improve this feature?
          </Typography>
          <TextField
            multiline
            rows={3}
            fullWidth
            onChange={(event) => {
              onValueChange(event.target.value);
              setButtonEnabled(!!event.target.value);
            }}
          />
        </DialogContent>
        <StyledDialogActions>
          <Button
            onClick={() => {
              onClose();
              setButtonEnabled(false);
            }}
          >
            Close
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => {
              onSubmit();
              setButtonEnabled(false);
            }}
            disabled={!buttonEnabled}
            aria-label={submitButtonLabel}
          >
            Submit feedback
          </LoadingButton>
        </StyledDialogActions>
      </Container>
    </Dialog>
  );
};
