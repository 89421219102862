import React from "react";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";

import { CostAllocationSummary } from "../../../../../generated/metricsApi";
import {
  formatCurrency,
  getFixedNumber,
} from "../../../../../shared/utils/formatCurrency";
import { formatPercentage } from "../../../../../shared/utils/formatPercentage";
import { LinearPercentageBar } from "../../shared/tableComponents/LinearPercentageBar";
import { SaveButton } from "../../shared/tableComponents/SaveButton";
import { HeaderCell } from "../../shared/tableComponents/HeaderCell";
import {
  ALLOCATION_TABLE_COLUMNS,
  COST_UTILIZATION_TOOLTIP_TEXT,
} from "../../../constants/costOptimizationConstants";
import { formatFixedNumber } from "../../../utils/costOptimizationUtils";
import { AllocationFiltersGroupBy } from "../../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { selectAllocationFilters } from "../../../store/costOptimizationStoreSelectors";
import { useOnTableCellClick } from "../../../hooks/tableHooks";
import { ServiceTextLink } from "../../shared/ServiceTextLink";
import { EllipsisContainerWithTooltip } from "../../../../../shared/components/EllipsisContainerWithTooltip";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";

import { getHasKind } from "./utils";

const addNamespaceColumn = (rows: CostAllocationSummary[]): GridColDef[] => {
  const hasNamespace = rows[0]?.namespace !== "";
  const { namespace } = ALLOCATION_TABLE_COLUMNS;

  return hasNamespace
    ? [
        {
          flex: 1,
          field: namespace.field,
          headerName: namespace.name,
          renderCell: (params: GridRenderCellParams) => {
            return <EllipsisContainerWithTooltip tooltipValue={params.value} />;
          },
        },
      ]
    : [];
};

const addServiceColumn = (rows: CostAllocationSummary[]): GridColDef[] => {
  const hasServiceName = rows[0]?.komodorServiceName !== "";
  const { komodorServiceName } = ALLOCATION_TABLE_COLUMNS;

  return hasServiceName
    ? [
        {
          flex: 1,
          field: komodorServiceName.field,
          headerName: komodorServiceName.name,
          renderCell: (params: GridRenderCellParams<CostAllocationSummary>) => {
            return (
              <EllipsisContainerWithTooltip tooltipValue={params.value}>
                <ServiceTextLink
                  id={params.row.komodorServiceId}
                  serviceName={params.value}
                  serviceKind={params.row.komodorServiceKind}
                />
              </EllipsisContainerWithTooltip>
            );
          },
        },
      ]
    : [];
};

const addClusterColumn = (rows: CostAllocationSummary[]): GridColDef[] => {
  const hasClusterName = rows[0]?.clusterName;
  const { clusterName } = ALLOCATION_TABLE_COLUMNS;

  return hasClusterName
    ? [
        {
          flex: 1,
          field: clusterName.field,
          headerName: clusterName.name,
          renderCell: (params: GridRenderCellParams) => {
            return <EllipsisContainerWithTooltip tooltipValue={params.value} />;
          },
        },
      ]
    : [];
};

const addKindColumn = (rows: CostAllocationSummary[]): GridColDef[] => {
  const hasKind = getHasKind(rows);
  const { komodorServiceKind } = ALLOCATION_TABLE_COLUMNS;

  return hasKind
    ? [
        {
          flex: 1,
          field: komodorServiceKind.field,
          headerName: komodorServiceKind.name,
        },
      ]
    : [];
};

const addIdleResourcesColumn = (
  rows: CostAllocationSummary[],
  groupBy: AllocationFiltersGroupBy
): GridColDef[] => {
  const hasIdleResourcesCost = rows[0]?.idleResourcesCost !== undefined;

  if (groupBy !== "clusterName" || !hasIdleResourcesCost) {
    return [];
  }
  const { idleResourcesCost } = ALLOCATION_TABLE_COLUMNS;
  return [
    {
      field: idleResourcesCost.field,
      headerName: idleResourcesCost.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        formatCurrency(params.value),
      filterable: false,
    },
  ];
};

export const useAllocationTableColumns = (rows?: CostAllocationSummary[]) => {
  const onTableCellClick = useOnTableCellClick();

  const { groupBy } = useCostOptimizationStore(selectAllocationFilters);
  const {
    memoryRequestGBHours,
    totalCost,
    shareOfCost,
    cpuRequestCoreHours,
    potentialSaving,
    optimizationScore,
  } = ALLOCATION_TABLE_COLUMNS;

  const columns: GridColDef[] = [
    ...addClusterColumn(rows ?? []),
    ...addNamespaceColumn(rows ?? []),
    ...addServiceColumn(rows ?? []),
    ...addKindColumn(rows ?? []),
    {
      field: shareOfCost.field,
      headerName: shareOfCost.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <LinearPercentageBar value={params.value} />;
      },
      filterable: false,
    },
    {
      field: cpuRequestCoreHours.field,
      headerName: cpuRequestCoreHours.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? formatFixedNumber(params.value) : 0,
      filterable: false,
    },
    {
      field: memoryRequestGBHours.field,
      headerName: memoryRequestGBHours.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams<CostAllocationSummary>) =>
        params.value ? formatFixedNumber(params.value) : 0,
      filterable: false,
    },
    ...addIdleResourcesColumn(rows ?? [], groupBy),
    {
      field: totalCost.field,
      headerName: totalCost.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        formatCurrency(params.value),
      filterable: false,
    },
    {
      field: optimizationScore.field,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        formatPercentage(params.value),
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell
          content={optimizationScore.name}
          description={COST_UTILIZATION_TOOLTIP_TEXT}
        />
      ),
      filterable: false,
    },
    {
      field: potentialSaving.field,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        getFixedNumber(params.value) <= 0 ? null : (
          <SaveButton
            onClick={() => onTableCellClick(params)}
            aria-label={
              costOptimizationAriaLabels.allocationPage.allocationTable
                .savingButton
            }
          >
            Save {formatCurrency(params.value)}
          </SaveButton>
        ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell
          content={potentialSaving.name}
          description="Recommendations for potential savings and optimized availability based on the recommended resource configuration"
        />
      ),
      filterable: false,
    },
  ];

  return columns;
};
