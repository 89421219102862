import React, { useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { BasicViolation } from "../../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { ActionType } from "../IgnoreCheckViolationDialog/IgnoreCheckViolationDialogTypes";
import { useHasPermissions } from "../../../../../../shared/hooks/useUserMetadata/rbac";

import { realtimeCheckTypes } from "@/components/reliability/constants/reliabilityConstants";

const staticActionOptions: ActionType[] = ["Ignore"];

const { violationOptionsMenu: violationOptionsMenuAriaLabel } =
  reliabilityArialLabels;

type ViolationMenuItemsParams = {
  violation: BasicViolation;
  violationLink?: string;
  onOptionSelected: (option: string) => void;
};

export const useViolationMenuItems = ({
  violation,
  violationLink,
  onOptionSelected,
}: ViolationMenuItemsParams): React.ReactElement[] => {
  const options = useMemo<ActionType[]>(() => {
    const actionTypes: ActionType[] = [
      ...staticActionOptions,
      ...(violationLink ? ["Copy URL" as ActionType] : []),
    ];

    if (realtimeCheckTypes.includes(violation.checkType)) {
      if (violation.status === "confirmed") {
        return ["Open"];
      }
      return ["Acknowledge"];
    }

    if (violation.status === "confirmed") {
      return ["Open", "Mark as resolved", ...actionTypes];
    }

    if (violation.status === "manually_resolved") {
      return ["Open", ...actionTypes];
    }

    return ["Mark as resolved", "Acknowledge", ...actionTypes];
  }, [violation.checkType, violation.status, violationLink]);

  const { canManageReliability } = useHasPermissions();

  return useMemo(() => {
    const disableTooltip = (option: string) =>
      option !== "Ignore" || (option === "Ignore" && canManageReliability);

    return options.map((option) => (
      <Tooltip
        title={"missing permissions"}
        placement={"left"}
        disableHoverListener={disableTooltip(option)}
        key={option}
      >
        <span>
          <MenuItem
            key={option}
            value={option}
            onClick={() => onOptionSelected(option)}
            aria-label={`${violationOptionsMenuAriaLabel} - ${option.toLocaleLowerCase()}`}
            disabled={option === "Ignore" && !canManageReliability}
          >
            {option}
          </MenuItem>
        </span>
      </Tooltip>
    ));
  }, [onOptionSelected, options, canManageReliability]);
};
