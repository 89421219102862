import styled from "styled-components";
import { muiColors, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { SetStateAction } from "react";

export const ResourceActions = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-self: start;
  margin-block-start: 1.35rem;
`;

export const Field = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 1rem;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  padding: 1rem;
`;

export const HorizontalContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
`;

export const PreservedHeight = styled.div<{ size?: string }>`
  height: ${({ size }) => size ?? "4.75rem"};
`;

export const ErrorMessage = styled(Typography).attrs({
  variant: "body2",
  color: muiColors.pink[500],
})``;

export const InformationMessage = styled(Typography).attrs({
  variant: "body3",
  color: muiColors.gray[700],
})`
  font-style: italic;
`;

export type SetStateFn<T> = React.Dispatch<SetStateAction<T | undefined>>;
export type SetStateFnRequired<T> = React.Dispatch<SetStateAction<T>>;
