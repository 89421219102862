import { useEffect, useMemo, useRef, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { Dictionary } from "../../../../../shared/types/Dictionary";
import {
  dataDogViewNames,
  KOMODOR_LOADING_TIME,
} from "../../../../../shared/constants/datadog";
import { usePodContainersRequestStatusContext } from "../context/PodContainersRequestStatusContext";
import { useMetricsTabContext } from "../context/MetricsTabContext";
import { Timeframe } from "../../../../../shared/types/TimeWindow";

type ReportDDRumMetricsParams = {
  graphLoadingState: Dictionary<boolean>;
  viewName: string;
  timeWindow: Timeframe;
  isDisplayed?: boolean;
};

export const useReportDDRumMetrics = ({
  isDisplayed,
  viewName,
  graphLoadingState,
  timeWindow,
}: ReportDDRumMetricsParams) => {
  const [hasReportedStartView, setHasReportedStartView] =
    useState<boolean>(false);
  const hasReportedAddTiming = useRef(false);
  const lastTimeWindow = useRef<Timeframe>(timeWindow);

  useEffect(() => {
    if (isDisplayed === false) return;
    const loadingStateAsList = Object.values(graphLoadingState);
    const allResourcesAreDoneLoading =
      !!loadingStateAsList.length &&
      loadingStateAsList.every((loading) => !loading);

    if (!hasReportedStartView) {
      setHasReportedStartView(true);
      datadogRum.startView(viewName);
    }

    if (lastTimeWindow.current !== timeWindow) return;

    if (!hasReportedAddTiming.current && allResourcesAreDoneLoading) {
      hasReportedAddTiming.current = true;
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
    }
  }, [
    graphLoadingState,
    hasReportedStartView,
    isDisplayed,
    lastTimeWindow,
    timeWindow,
    viewName,
  ]);

  useEffect(() => {
    if (timeWindow !== lastTimeWindow.current || isDisplayed === false) {
      setHasReportedStartView(false);
      hasReportedAddTiming.current = false;
      lastTimeWindow.current = timeWindow;
    }
  }, [isDisplayed, lastTimeWindow, timeWindow]);
};

export const useReportPodMetricsTabLoadingTime = (isDisplayed: boolean) => {
  const { cpuStatus, memoryStatus } = usePodContainersRequestStatusContext();
  const { timeWindow } = useMetricsTabContext();

  const params = useMemo(
    () => ({
      isDisplayed,
      timeWindow: timeWindow.timeframe,
      graphLoadingState: {
        cpuStatus: cpuStatus.isLoading,
        memoryStatus: memoryStatus.isLoading,
      },
      viewName: dataDogViewNames.getPodMetricsDrawer(timeWindow.timeframe),
    }),
    [cpuStatus.isLoading, isDisplayed, memoryStatus.isLoading, timeWindow]
  );

  useReportDDRumMetrics(params);
};
