import React from "react";
import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";

import { checkboxOptionsAriaLabels } from "../ariaLabels";

const Container = styled.div<{
  $height: number | undefined;
}>`
  && {
    height: ${({ $height }) => `${$height}px`};
  }
`;

const loadingAriaLabel = checkboxOptionsAriaLabels.loading;

type LoaderContainerProps = {
  height?: number;
};

export const LoaderContainer: React.FC<LoaderContainerProps> = ({ height }) => {
  return (
    <Container $height={height} aria-label={loadingAriaLabel}>
      <LinearProgress />
    </Container>
  );
};
