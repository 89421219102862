import styled from "styled-components";

import { gray10 } from "../../../../../Colors";
import { lightStyle } from "../styles";

export const WebhookGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

export const WebhookInfoButton = styled.a`
  ${lightStyle}
  color: ${gray10};
  cursor: pointer;
  text-decoration: underline;
`;

export const WebhookTest = styled.div`
  display: flex;
  align-items: center;
`;

export const ChannelLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.25rem;
`;

export const HeaderGrid = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem;
  gap: 0.3rem;
`;
