import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import WorkflowIssueEventGroup from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import EventDrawer from "../../../common/ProcessList/EventDrawer";
import { IssueBanner } from "../../../IssueBanner/IssueBanner";
import { OpenIssueAlertIcon } from "../../../OpenIssues/IndicationIconStyles";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

type StandalonePodIssueBannerProps = {
  issueEventGroup: WorkflowIssueEventGroup;
};
export const StandalonePodIssueBanner: React.FC<
  StandalonePodIssueBannerProps
> = ({ issueEventGroup }) => {
  const [isEventDrawerOpen, setIsEventDrawerOpen] = useState(false);

  const onCloseIssue = () => setIsEventDrawerOpen(false);

  return (
    <>
      <IssueBanner
        text={
          <Typography variant="body2">
            There is an ongoing{" "}
            <strong>
              {issueEventGroup.type} ({issueEventGroup.details})
            </strong>{" "}
            for this pod
          </Typography>
        }
        icon={<OpenIssueAlertIcon />}
        ariaLabel={AriaLabels.ResourceView.IssueBanner.Pod.StandalonePodIssue}
        bgColor={muiColors.pink[25]}
        onIssueClicked={() => {
          setIsEventDrawerOpen(true);
        }}
      />
      {isEventDrawerOpen ? (
        <EventDrawer open onClose={onCloseIssue} event={issueEventGroup}>
          {issueEventGroup.renderEventDetails(onCloseIssue)}
        </EventDrawer>
      ) : null}
    </>
  );
};
