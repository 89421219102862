import { createContext } from "react";

// Define the context type
interface ZendeskContextType {
  jwt: string | null;
  loading: boolean;
  error: Error | null;
}

// Default values for the context
const defaultContext: ZendeskContextType = {
  jwt: null,
  loading: true,
  error: null,
};

// Create the context
export const ZendeskContext = createContext<ZendeskContextType>(defaultContext);
