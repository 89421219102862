import styled from "styled-components";
import { palette } from "@komodorio/design-system";

import { textLightStyle, textStyle } from "../typography";

const blue = palette.blue[500];
const gray = palette.gray[600];

export const Container = styled.div`
  font-size: 0.75rem;
`;

export const Table = styled.table`
  position: relative;
  z-index: 15;
  width: 100%;
  border-spacing: 0 0.5rem;
  ${textStyle}
  th {
    background-color: transparent;
    ${textLightStyle}
    padding: 0 0.75rem;
    text-align: start;
    color: ${gray};
    white-space: nowrap;
  }
`;

export const Hidden = styled.div`
  display: none;
`;

export const MoreEventsLinkText = styled.div`
  color: ${blue};
  font-family: "Poppins";
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.6rem 1rem;
  border: ${blue};
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  justify-self: center;
`;

export const MoreEventsPanelText = styled.div`
  width: 100%;
  font-family: "Poppins";
  font-size: 1.1rem;
`;

export const MoreEventsPanelContainer = styled.div`
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  justify-content: center;
  padding: 4rem;
`;
