import React from "react";

import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { MissingRequestsOrLimitsBestPracticesResponse } from "../../../../../../../../../generated/applicationInsights";
import { InsightCard } from "../../shared/components/InsightCard";
import { insightCardTitles } from "../../insightsConstants";
import { useOpenInsightsDrawer } from "../../shared/hooks/appInisghtsHooks";
import { useOverridableFlags } from "../../../../../../../../../shared/context/featureFlags/OverridableFlags";

import { cardTitle } from "./missingResourcesLimitsTexts";
import { BestPracticesMissingResourcesLimitsModalContent } from "./BestPracticesMissingResourcesLimitsModalContent";

export const ID = "Best Practices Missing Resources Limits";

const shouldDisplayBestPracticesMissingResourcesLimits = (
  data?: MissingRequestsOrLimitsBestPracticesResponse
) => {
  return data && data?.rows?.length > 0;
};

export const BestPracticesMissingResourcesLimits: React.FC = () => {
  const { showBestPractices } = useOverridableFlags();
  const {
    bestPracticesMissingRequestsOrLimits: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const openInsightsDrawer = useOpenInsightsDrawer();

  if (
    (!loading && !shouldDisplayBestPracticesMissingResourcesLimits(data)) ||
    !showBestPractices
  ) {
    return null;
  }

  const { title, icon } =
    insightCardTitles.bestPracticesMissingRequestsOrLimits;
  const description = `${data?.rows?.length} ${cardTitle}`;

  const onCardClick = () => {
    openInsightsDrawer({
      title: description,
      component: <BestPracticesMissingResourcesLimitsModalContent />,
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={description}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"twoDescriptionLines"}
    ></InsightCard>
  );
};
