import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Integration } from "@/components/integrations/management/definitions";
import { useIntegrations } from "@/components/integrations/management/useIntegrations";
import { DEFAULT_TOOLTIP } from "@/shared/constants/tooltipIds";
import { isSandbox } from "@/shared/utils/sandbox";
import ModalFactory from "@/components/integrations/management/available/ModalFactory";
import { InstallCTA } from "@/components/integrations/management/available/InstallCTA";

export const Install: React.FC<{
  integration: Integration;
  isInstalled?: boolean;
  newTab?: boolean;
}> = ({ integration, isInstalled, newTab }) => {
  const { InstallationComponent, modalStyle, modalType } = integration;

  const {
    shouldLockIntegrations,
    onInstallHandler,
    disabled,
    showCheck,
    showModal,
    closeModal,
  } = useIntegrations({
    integration,
    isInstalled,
    newTab,
  });

  return (
    <>
      <TooltipWrapper
        tooltipId={DEFAULT_TOOLTIP}
        content={
          isSandbox() ? "This option is disabled in sandbox version" : undefined
        }
        place="top"
      >
        <InstallCTA
          showCheck={showCheck}
          onInstallHandler={onInstallHandler}
          disabled={disabled}
          title={integration.title}
          shouldLockIntegrations={shouldLockIntegrations}
          buttonText={integration.buttonText}
        />
      </TooltipWrapper>

      <ModalFactory
        modalType={modalType}
        closeModal={closeModal}
        isShow={showModal}
        modalStyle={modalStyle}
      >
        {InstallationComponent && (
          <InstallationComponent closeModalCallback={closeModal} />
        )}
      </ModalFactory>
    </>
  );
};
