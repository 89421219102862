import { LocalStorageItem } from "../../shared/utils/localStorageSettings";
import { storedEventsViewQS } from "../eventsView/EventsView";
import { storedServicesViewQS } from "../../shared/context/ServicesPageFiltersLocalStorageProvider";
import { storedJobsViewQS } from "../../shared/context/JobsPageFiltersLocalStorageProvider";
import { storedLastCluster } from "../Inspection/utils/localStorage";
import { storedLastAppViewsData } from "../../shared/store/appViewsStore/constants";

export const localStorageItemsToRemove: LocalStorageItem[] = [
  storedEventsViewQS,
  storedServicesViewQS,
  storedJobsViewQS,
  storedLastCluster,
  storedLastAppViewsData,
];
