import { useEffect, useMemo } from "react";

import { useOverviewPageStore } from "../store/overviewPageStore";
import {
  selectAppInsightsData,
  selectSetAppInsightsData,
  selectTimeWindowIntervals,
} from "../store/overviewPageStoreSelectors";
import { hasServerResChanged } from "../../../../../../../shared/utils/hasServerResChanged";
import {
  AppInsightsApiData,
  AppInsightsApiResponse,
} from "../types/overviewPageTypes";
import { initialResponse } from "../store/initialState";
import { getRandomInt } from "../../../../../../../shared/utils/helpers";
import { isDevMode } from "../../../../../../../shared/utils/isDevMode";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import { fetchAppViewServicesStateSelector } from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

export const useHasValidTimeWindow = () => {
  const timeWindowIntervals = useOverviewPageStore(selectTimeWindowIntervals);
  return useMemo(
    () => timeWindowIntervals[0].fromEpoch < timeWindowIntervals[1].toEpoch,
    [timeWindowIntervals]
  );
};

export const useShouldPauseRequest = () => {
  const fetchAppViewServicesState = useAppViewsStore(
    fetchAppViewServicesStateSelector
  );

  const { hasFinishedFetching, hasFetchError, noServicesFound } =
    fetchAppViewServicesState;
  const hasAppViewServices =
    hasFinishedFetching && !hasFetchError && !noServicesFound;

  const hasValidTimeWindow = useHasValidTimeWindow();
  return !hasValidTimeWindow || !hasAppViewServices || isDevMode();
};

type SetMockDataParams<T extends object> = {
  setRes: (res: AppInsightsApiResponse<T>) => void;
  mockData?: T;
  pauseRequest?: boolean;
};

export const useSetMockDataEffect = <T extends object>({
  mockData,
  setRes,
  pauseRequest,
}: SetMockDataParams<T>) => {
  const hasValidTimeWindow = useHasValidTimeWindow();

  useEffect(() => {
    if (!isDevMode() || !hasValidTimeWindow || pauseRequest) return;
    setRes({
      ...initialResponse,
      loading: true,
    });
    setTimeout(() => {
      setRes({
        ...initialResponse,
        loading: false,
        data: mockData,
      });
    }, getRandomInt(1000, 3000));
  }, [hasValidTimeWindow, mockData, pauseRequest, setRes]);
};

export const useTimeWindowAsRequestParam = () => {
  const timeWindowIntervals = useOverviewPageStore(selectTimeWindowIntervals);
  return useMemo(
    () => ({
      fromEpoch: timeWindowIntervals[0].fromEpoch,
      toEpoch: timeWindowIntervals[1].toEpoch,
      issuesCountFromEpoch: timeWindowIntervals[1].fromEpoch,
      issuesCountToEpoch: timeWindowIntervals[1].toEpoch,
    }),
    [timeWindowIntervals]
  );
};

export const useUpdateAppInsightsData = <T extends keyof AppInsightsApiData>(
  key: T,
  data: AppInsightsApiData[T]
) => {
  const setAppInsightsData = useOverviewPageStore(selectSetAppInsightsData);
  const currentInsightsData = useOverviewPageStore(selectAppInsightsData);

  useEffect(() => {
    if (hasServerResChanged(currentInsightsData[key], data)) {
      setAppInsightsData(key, data);
    }
  }, [currentInsightsData, data, key, setAppInsightsData]);
};
