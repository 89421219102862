import React, { useMemo } from "react";

import { ViolationSummary } from "../../../shared/ViolationSummary/ViolationSummary";
import { formatPercentage } from "../../../../../../../../../../shared/utils/formatPercentage";

import { HpaDataOutput } from "./useHpaData";
import { useGetMetricsRequestValue } from "./useGetMetricsRequestValue";

const summaryTitleProps: Pick<
  React.ComponentProps<typeof ViolationSummary>,
  "titleProps"
> = {
  titleProps: {
    color: "text.secondary",
  },
};

export const useMetricsDetails = (
  hpaData: HpaDataOutput["hpaData"] | undefined
) => {
  const { maxReplicas, minReplicas, scalingMetrics } = hpaData ?? {};
  const getUtilizationToRequestValue = useGetMetricsRequestValue();

  const hpaMetrics = maxReplicas !== undefined && minReplicas !== undefined && (
    <ViolationSummary
      title={"HPA details"}
      {...summaryTitleProps}
      items={[
        { value: minReplicas, label: "Min replicas" },
        { value: maxReplicas, label: "Max replicas" },
      ]}
      ariaLabel={"HPA details"}
    />
  );

  const metricsContent = useMemo(() => {
    if (!scalingMetrics) return null;
    return scalingMetrics.map((metric, idx) => {
      const detailsTitle = idx === 0 ? "Scaling metrics" : "";
      const entryTitle =
        metric.resourceType === "cpu"
          ? "Avg. CPU utilization (Requests)"
          : "Avg. Memory utilization (Requests)";

      const isPercentage = metric.metricType === "percentage";

      const requestValue = isPercentage
        ? getUtilizationToRequestValue({
            resourceType: metric.resourceType,
            metricValue: metric.value as number,
          })
        : "";

      const targetValue = isPercentage
        ? `${formatPercentage(metric.value as number)} ${requestValue} `
        : metric.value ?? "";

      if (!targetValue) return null;

      return (
        <React.Fragment key={idx}>
          <ViolationSummary
            title={detailsTitle}
            {...summaryTitleProps}
            items={[
              { value: entryTitle, label: `Scaling metric ${idx + 1}` },
              {
                value: targetValue,
                label: `Target value ${idx + 1}`,
              },
            ]}
            ariaLabel={`${metric.resourceType} scaling metrics`}
          />
        </React.Fragment>
      );
    });
  }, [getUtilizationToRequestValue, scalingMetrics]);

  return { hpaMetrics, metricsContent };
};
