import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  width: 320px;
  min-height: 450px;
  max-height: 620px;
`;

const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.borderGray};
  padding: 1rem;
  height: 20px;
  overflow: hidden;
`;

const ContentContainer = styled.div<{ $needsScrolling?: boolean }>`
  padding: 0 1rem;
  padding-right: ${({ $needsScrolling }) => ($needsScrolling ? "0" : "1rem")};
  flex-grow: 1;
  overflow: hidden;
`;

type ContentCardProps = {
  title: string;
  headerActionButton?: React.ReactNode;
  content: ReactNode;
  needsScrolling?: boolean;
};

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  headerActionButton,
  content,
  needsScrolling,
}) => {
  return (
    <Card aria-label={title}>
      <CardHeaderContainer>
        <Typography bold variant="title" size="medium">
          {title}
        </Typography>
        {headerActionButton}
      </CardHeaderContainer>
      <ContentContainer $needsScrolling={needsScrolling}>
        {content}
      </ContentContainer>
    </Card>
  );
};
