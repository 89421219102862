import { Record, Static, String } from "runtypes";

export const DeprecatedApisRecord = Record({
  kind: String,
  "deprecated-in": String,
  "removed-in": String,
  version: String,
  "replacement-api": String,
});
export type DeprecatedApisType = Static<typeof DeprecatedApisRecord>;
