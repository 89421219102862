import React from "react";
import Typography from "@mui/material/Typography";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { muiTheme } from "@komodorio/design-system";

import { WorkspaceKind } from "@/generated/workspacesApi";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";

export type WorkspaceDeletionDialogProps = {
  workspace: TypedWorkspace;
  onClose: (ok: boolean) => void;
};

const DeleteConfirmationDialog = styled(ConfirmationDialog)`
  && .MuiPaper-root {
    height: unset;
  }
  && .MuiButtonBase-root.MuiButton-contained {
    background-color: ${muiTheme.palette.error.main};
  }
`;

export const WorkspaceDeletionDialog: React.FC<
  WorkspaceDeletionDialogProps
> = ({ workspace, onClose }) => {
  const handleOk = (ok: boolean) => {
    onClose(ok);
  };
  return (
    <DeleteConfirmationDialog
      title={`Delete ${toTitleCase(workspace.readableKind)}?`}
      okText="Delete"
      cancelText="Cancel"
      onClose={handleOk}
      content={
        <Typography variant="body2">
          {workspace.kind === WorkspaceKind.ClustersGroup
            ? getClusterGroupDeletionMessage(workspace.name)
            : `Are you sure you want to delete this ${workspace.readableKind.toLowerCase()}?`}
        </Typography>
      }
    />
  );
};

function toTitleCase(str: string): string {
  return str
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase().concat(w.slice(1)))
    .join(" ");
}

function getClusterGroupDeletionMessage(name: string) {
  return (
    <>
      Are you sure you want to delete{" "}
      <q>
        <Typography
          variant="body2"
          color="primary.main"
          display="inline"
          fontWeight="bold"
        >
          {name}
        </Typography>
      </q>{" "}
      cluster group?
      <br />
      Deletion of the cluster group will only delete the group and won’t remove
      any clusters from the system.
    </>
  );
}
