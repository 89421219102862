import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ARGO_WORKFLOW_KOMODOR_KIND } from "@/components/ResourceView/resources/argoWorkflow";
import { buildServiceId } from "@/shared/utils/serviceHelpers";

type GeneralWorkflowNameParams = {
  engine: string;
  dag: string;
  run: string;
};

export const buildGeneralWorkflowName = ({
  engine,
  dag,
  run,
}: GeneralWorkflowNameParams): string => {
  return `${engine}>${dag}>${run}`;
};

export const parseGeneralWorkflowName = (
  name: string
): GeneralWorkflowNameParams | undefined => {
  const splitName = name.split(">");
  if (splitName.length !== 3) {
    return undefined;
  }
  return {
    engine: splitName[0],
    dag: splitName[1],
    run: splitName[2],
  };
};

export const isArgoWorkflow = (komodorUid: string): boolean => {
  const { kind } = parseKomodorUid(komodorUid) ?? {};
  return kind === ARGO_WORKFLOW_KOMODOR_KIND;
};

export const buildWorkflowServiceId = (
  accountName: string,
  clusterName: string,
  namespace: string,
  dagId: string,
  runId: string
): string => {
  return buildServiceId(
    accountName,
    clusterName,
    namespace,
    `${dagId}|${runId}`
  );
};
