import { useCallback } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { DataGridProPropsWithoutDefaultValue } from "@mui/x-data-grid-pro/models/dataGridProProps";

import { APIDeprecationsSupportingDataDeprecatedAPIsInner } from "../../../../../../../../../../../generated/reliabilityApi";
import { DEFAULT_PAGE_SIZE, PAGINATION_SIZE_OPTIONS } from "../DetailsUtils";

import { ApiDeepView } from "./ApiDeepView/ApiDeepView";

const Container = styled.div`
  && {
    .MuiDataGrid-root,
    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
    }
  }
`;

interface Props {
  rows: DataGridProPropsWithoutDefaultValue["rows"];
  columns: DataGridProPropsWithoutDefaultValue["columns"];
  clusterName: string | undefined;
}

export const APIsTable: React.FC<Props> = ({ rows, columns, clusterName }) => {
  const getDetailPanelContent = useCallback(
    (
      params: GridRowParams<APIDeprecationsSupportingDataDeprecatedAPIsInner>
    ) => <ApiDeepView clusterName={clusterName} api={params.row} />,
    [clusterName]
  );
  return (
    <Container>
      <DataGridPro
        rows={rows}
        columns={columns}
        getRowId={(row) => row?.api ?? ""}
        getDetailPanelContent={getDetailPanelContent}
        slots={{
          detailPanelExpandIcon: ChevronRight,
          detailPanelCollapseIcon: ExpandMore,
        }}
        pageSizeOptions={PAGINATION_SIZE_OPTIONS}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: DEFAULT_PAGE_SIZE,
            },
          },
        }}
        pagination
      />
    </Container>
  );
};
