import React from "react";

import { StyledPage } from "../styles/CostOptimizationStyledComponents";

import { useFetchRightSizingDataOnFiltersChange } from "./hooks/rightSizingHooks";
import { SummaryCards } from "./SummaryCards/SummaryCards";
import { MetricsGraphs } from "./MetricsGraphs/MetricsGraphs";
import { RightSizingTable } from "./RightSizingTable/RightSizingTable";
import { RightSizingPaginatedTable } from "./RightSizingPaginatedTable/RightSizingPaginatedTable";
import { useRightSizingTablePaginationMode } from "./hooks/commonHooks";

export const RightSizingPage: React.FC = () => {
  useFetchRightSizingDataOnFiltersChange();

  return (
    <StyledPage>
      <SummaryCards />
      <MetricsGraphs />
      {useRightSizingTablePaginationMode() ? (
        <RightSizingPaginatedTable />
      ) : (
        <RightSizingTable />
      )}
    </StyledPage>
  );
};
