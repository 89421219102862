import React from "react";
import Popover, { PopoverOrigin } from "@mui/material/Popover";

import { popoverTransitionDuration } from "../../../../../constants";

export type SelectPopoverProps = {
  anchorEl: Element | null;
  onClose: () => void;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  width?: string;
  height?: string;
  maxHeight?: string;
  minWidth?: string;
  container?: Element;
};

export const SelectPopover: React.FC<SelectPopoverProps> = ({
  anchorEl,
  onClose,
  onScroll,
  children,
  anchorOrigin,
  transformOrigin,
  width,
  height,
  maxHeight,
  minWidth,
  container,
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      slotProps={{
        paper: {
          ...(onScroll && {
            onScroll: (e) => {
              onScroll(e);
            },
          }),
          style: {
            minWidth: minWidth,
            width: minWidth ? undefined : width ?? container?.clientWidth,
            height: height,
            maxHeight: maxHeight,
          },
        },
      }}
      container={container}
      transitionDuration={popoverTransitionDuration}
    >
      {children}
    </Popover>
  );
};
