import React from "react";

import {
  ViolationsTableContextProvider,
  ViolationsTableContextProviderProps,
} from "./context/ViolationsTableContext";
import { ViolationsTableHandler } from "./ViolationsTableHandler";

type ViolationTableWithContextProps = Omit<
  ViolationsTableContextProviderProps,
  "children"
> & {
  className?: string;
};

export const ViolationTableWithContext: React.FC<
  ViolationTableWithContextProps
> = ({ className, ...rest }) => {
  return (
    <div className={className}>
      <ViolationsTableContextProvider {...rest}>
        <ViolationsTableHandler />
      </ViolationsTableContextProvider>
    </div>
  );
};
