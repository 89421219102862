import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  && {
    margin-left: 32px;
  }
`;

export const NoResultsText: React.FC = () => {
  return (
    <StyledTypography variant={"body3"} color={"text.secondary"}>
      No matching values
    </StyledTypography>
  );
};
