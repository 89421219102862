import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { OptionType } from "@komodorio/design-system/deprecated";
import { muiColors } from "@komodorio/design-system";

import { StyledInfoOutlinedIcon } from "../../../styles";
import { ModalSelectStrategy } from "../../../../../RightSizingPage/SummaryCards/SelectStrategy/ModalSelectStrategy";
import { OptimizationStrategy } from "../../../../../../../../generated/metricsApi";
import {
  STRATEGY_VALUE_TO_LABEL,
  STRATEGY_VALUE_TO_SUBLABEL,
} from "../../../../../../constants/costOptimizationConstants";
import { costOptimizationAriaLabels } from "../../../../../../constants/costOptimizationAriaLabels";

import { Savings, SummaryItem } from "./SummaryItems";
import { summaryTitles } from "./texts";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const StrategyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StrategySelectorTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledLink = styled(Link)`
  &:any-link {
    color: ${muiColors.indigo[500]};
  }
`;

const {
  rightSizingPage: {
    recommendationModal: {
      serviceDetails: {
        detailsServiceCluster: detailsServiceClusterAriaLabel,
        detailsServiceNamespace: detailsServiceNamespaceAriaLabel,
        detailsServiceKindAndName: detailsServiceKindAndNameAriaLabel,
      },
    },
  },
} = costOptimizationAriaLabels;

interface Props {
  rowId: string;
  serviceId: string;
  cluster: string;
  namespace: string;
  kind: string;
  serviceName: string;
  potentialSaving?: number;
  isStrategySelectorDisabled: boolean;
  strategy: OptimizationStrategy;
  onChangeStrategy?: (rowId: string, selected: OptionType<string>) => void;
}

export const Summary: React.FC<Props> = ({
  rowId,
  serviceId,
  cluster,
  namespace,
  kind,
  serviceName,
  potentialSaving = 0,
  isStrategySelectorDisabled,
  strategy: modalStrategy,
  onChangeStrategy,
}) => {
  const currentModalStrategy = useMemo(
    () => ({
      label: STRATEGY_VALUE_TO_LABEL[modalStrategy],
      subLabel: STRATEGY_VALUE_TO_SUBLABEL[modalStrategy],
      value: modalStrategy,
    }),
    [modalStrategy]
  );

  return (
    <Container>
      <SummaryItem
        ariaLabel={detailsServiceClusterAriaLabel}
        label={summaryTitles.cluster}
        value={cluster}
      />
      <SummaryItem
        ariaLabel={detailsServiceNamespaceAriaLabel}
        label={summaryTitles.namespace}
        value={namespace}
      />
      <SummaryItem
        ariaLabel={detailsServiceKindAndNameAriaLabel}
        label={kind}
        value={serviceName}
        serviceLink={
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to={`/services/${serviceId}`}
          >
            {serviceName}
          </StyledLink>
        }
      />
      <Savings potentialSaving={potentialSaving} />
      <StrategyContainer>
        <StrategySelectorTitle>
          <Typography variant="h5" color="text.primary">
            Optimization strategy
          </Typography>
          <Tooltip
            title=" We utilize Komodor metrics to assess the workload's memory and cpu
        requirements vs. their actual usage. And offer recommended changes based
        on the selected optimization strategy."
            placement="top"
          >
            <StyledInfoOutlinedIcon />
          </Tooltip>
        </StrategySelectorTitle>
        <ModalSelectStrategy
          width="150px"
          disabled={isStrategySelectorDisabled}
          value={currentModalStrategy}
          onChange={(selected) => {
            onChangeStrategy?.(rowId, selected);
          }}
        />
      </StrategyContainer>
    </Container>
  );
};
