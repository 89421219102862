import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import styled from "styled-components";

import { StrictExternalLink } from "../../../../common/Link/ExternalLink";

import { AriaLabels } from "@/shared/config/ariaLabels";
import {
  DialogContainer,
  DialogFooter,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";

const BulletsContainer = styled.ul`
  margin-top: 12px !important;
`;

export const KlaudiaAiConsentForm: React.FC<{
  isLoading: boolean;
  onClose: () => void;
  onEnable: () => void;
}> = ({ onClose, onEnable, isLoading }) => {
  const bullets = [
    <Typography variant="body2">
      This feature will become usable by <b>all users</b> in your account.
    </Typography>,
    <Typography variant="body2">
      Please review relevant{" "}
      <StrictExternalLink
        href={
          "https://help.komodor.com/hc/en-us/articles/22447540595218-Klaudia-AI-Powered-Kubernetes-Troubleshooting"
        }
      >
        docs
      </StrictExternalLink>{" "}
      around the feature security in advance.
    </Typography>,
    <Typography variant="body2">
      Only account admins may enable or disable this feature.
    </Typography>,
  ];

  return (
    <DialogContainer
      aria-label={
        AriaLabels.SettingsPage.AccountProfile.BetaFeatures.KlaudiaAiConsentForm
      }
    >
      <Typography variant={"h3"}>Enable Klaudia AI</Typography>
      <Stack spacing={4}>
        <Typography variant={"body2"}>
          Please read and approve the following:{" "}
        </Typography>
        <BulletsContainer>
          {bullets.map((bullet, index) => (
            <li key={`bullet-${index}`}>{bullet}</li>
          ))}
        </BulletsContainer>
      </Stack>
      <DialogFooter>
        <Button
          variant={"text"}
          size={"large"}
          sx={{
            width: "5rem",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant={"contained"}
          size={"large"}
          sx={{
            width: "9.5rem",
          }}
          onClick={onEnable}
          aria-label={
            AriaLabels.SettingsPage.AccountProfile.BetaFeatures
              .KlaudiaAiConsentFormEnableButton
          }
        >
          I understand, enable
        </LoadingButton>
      </DialogFooter>
    </DialogContainer>
  );
};
