import React from "react";

import { gray4 } from "../../../../../Colors";

export const iconMargin = "0 0.5rem 0 0.2rem";

const FilledCheck: React.FC<{ fillColor?: string }> = ({ fillColor }) => (
  <svg
    height="1rem"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: iconMargin }}
  >
    <path
      d="M6.76172 11.7695C9.91992 11.7695 12.5273 9.16211 12.5273 5.99805C12.5273 2.83984 9.91406 0.232422 6.75586 0.232422C3.59766 0.232422 0.990234 2.83984 0.990234 5.99805C0.990234 9.16211 3.59766 11.7695 6.76172 11.7695ZM6.14062 8.75195C5.91797 8.75195 5.74219 8.65234 5.57812 8.44727L4.23633 6.83008C4.13086 6.69531 4.07812 6.56055 4.07812 6.41406C4.07812 6.10938 4.31836 5.86914 4.62305 5.86914C4.80469 5.86914 4.94531 5.93359 5.08594 6.11523L6.12305 7.41602L8.37305 3.82422C8.50195 3.61914 8.66602 3.51367 8.85352 3.51367C9.14648 3.51367 9.41602 3.72461 9.41602 4.0293C9.41602 4.16406 9.35742 4.30469 9.26953 4.43359L6.67969 8.44141C6.55078 8.64062 6.35742 8.75195 6.14062 8.75195Z"
      fill={fillColor ? fillColor : gray4}
    />
  </svg>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default FilledCheck;
