import { noop } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  Validations,
  ValidationsContext,
} from "../../components/Settings/Users/types";

const defaultValue = {
  errors: {},
  setError: noop,
  deleteError: noop,
  clearErrors: noop,
};

const validationsContext =
  createContext<Required<ValidationsContext>>(defaultValue);

export const ValidationsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [errors, setErrors] = useState<Validations>(defaultValue.errors);

  const setError = useCallback((key: string, error: string | undefined) => {
    setErrors((prev) => ({
      ...prev,
      [key]: error,
    }));
  }, []);

  const deleteError = useCallback(
    (key: string | ((key: string) => boolean)) => {
      setErrors((prev) => {
        const copy = { ...prev };
        if (typeof key === "string") {
          delete copy[key];
        } else {
          Object.keys(copy).forEach((k) => {
            if (key(k)) {
              delete copy[k];
            }
          });
        }
        return { ...copy };
      });
    },
    []
  );

  const clearErrors = useCallback(() => {
    setErrors({});
  }, []);

  const value = useMemo(
    () => ({
      errors,
      setError,
      deleteError,
      clearErrors,
    }),
    [setError, deleteError, clearErrors, errors]
  );

  return (
    <validationsContext.Provider value={value}>
      {children}
    </validationsContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useFormValidations = (): ValidationsContext =>
  useContext(validationsContext);
