import { useMemo } from "react";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export function useIsAiEnabledForAccount() {
  const { klaudiaAi } = useOverridableFlags();
  const { data: features } = useAccountFeatures();
  return useMemo(() => {
    return Boolean(features.klaudiaAi) && Boolean(klaudiaAi);
  }, [features.klaudiaAi, klaudiaAi]);
}
