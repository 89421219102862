import { muiColors, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Check16, Copy16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";
import useClipboard from "react-use-clipboard";
import Dialog, { DialogProps } from "@mui/material/Dialog";

import { DataRow } from "../common/table/DataTable";

export const Z_INDEX_ABOVE_MODAL = "10001";

export const MyProfileContainer = styled.div<{ width?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  width: ${({ width }) => width ?? "100%"};
`;

export const Form = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: ${({ width }) => width ?? "100%"};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Filters = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Flex = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ActionsCol = styled.div`
  visibility: hidden;

  ${DataRow}:hover & {
    visibility: visible;
  }

  display: flex;
  float: right;
`;

export const PlanContainer = styled.div`
  padding: 0.5rem;
  background: ${muiColors.common.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 0.25rem;
  width: 14.5rem;
`;

const ErrorPanel = styled.div<{ box: boolean }>`
  margin-block-start: 1rem;
  padding: 0.5rem;
  ${({ box }) =>
    box
      ? `border: 1px solid ${theme.border.borderGray}; border-radius: 4px;`
      : `border-top: 1px solid ${theme.border.borderGray};`}
  min-height: 1rem;
  max-height: 4rem;
  overflow: auto;
`;
export const FormError: React.FC<{
  errMsg: string | undefined;
  isBoxStyle?: boolean;
}> = ({ errMsg, isBoxStyle = false }) => {
  if (!errMsg) {
    return null;
  }
  return (
    <ErrorPanel box={isBoxStyle}>
      <Typography color={theme.foreground.fgPink}>{errMsg}</Typography>
    </ErrorPanel>
  );
};

const Key = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
`;

const CopyIcon = styled(Copy16)`
  fill: ${theme.foreground.fgSubtle};

  :hover {
    fill: ${theme.foreground.fgDarkBlue};
  }
`;

interface CopyComponentProps {
  text: string;
  obfuscatedChar?: string;
}

export const CopyComponent: React.FC<CopyComponentProps> = ({
  text,
  obfuscatedChar,
}) => {
  const [copied, copy] = useClipboard(text, { successDuration: 3000 });
  const content = obfuscatedChar || text;

  return (
    <Key>
      {content}
      {copied ? (
        <Check16 fill={theme.border.borderGreen} />
      ) : (
        <CopyIcon onClick={copy} />
      )}
    </Key>
  );
};

export const CopyText: React.FC<{ text: string; obfuscatedChar?: string }> = ({
  text,
  obfuscatedChar,
}) => <CopyComponent text={text} obfuscatedChar={obfuscatedChar} />;

export const EmptyGridItem = styled.span``;

export const StyledDialog: React.FC<
  DialogProps & { keepSpaceForEditor?: boolean }
> = ({ ...props }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: props.keepSpaceForEditor
          ? {
              height: "80vh",
            }
          : {},
      }}
      sx={{
        borderRadius: "16px",
        "&& .MuiDialogContent-root": {
          paddingTop: "20px",
        },
      }}
      {...props}
    />
  );
};
