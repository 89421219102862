export const K8sResourceRegex = `((?:[a-z0-9](?:[-a-z0-9]*[a-z0-9])?/)?(?:[a-z0-9](?:[-a-z0-9]*[a-z0-9])?)(?:.[a-z0-9](?:[-a-z0-9]*[a-z0-9])?)*)`;

export interface IStore<T> {
  insert(value: T): void;
  get(key: string): T[];
}

export interface MessageEnricher {
  searchKey(): string;
  enrich(errorMessage: string): EnrichedMessage | null;
}

export interface IEnricher {
  enrich(errorMessage: string): EnrichedMessage | null;
}

export enum TokenType {
  String = "string",
  K8sResource = "k8sResource",
  NodeResource = "nodeResource",
}

export interface Token {
  getValue(): string;
  getType(): TokenType;
}

export interface EnrichedMessage {
  originalMessage: string;
  messageTokens: Token[];
  hasPrecedenceOverAIInvestigation?: boolean;
}
