import {
  CheckType,
  SyntheticSupportingData,
  ViolationSupportingData,
} from "@/generated/reliabilityApi";

export const getSyntheticSupportingData = (
  checkType: CheckType,
  supportingData: ViolationSupportingData | undefined
): SyntheticSupportingData | undefined => {
  switch (checkType) {
    case CheckType.SyntheticDegradedService:
      return supportingData?.syntheticDegradedService;
    case CheckType.SyntheticNodePressure:
      return supportingData?.syntheticNodePressure;
    case CheckType.SyntheticEolDeprecationOutdated:
      return supportingData?.syntheticEOLDeprecationOutdated;
    case CheckType.CascadingFailure:
      return supportingData?.cascadingFailure;
    default:
      return undefined;
  }
};

export const getSyntheticViolationTitle = (
  checkType: CheckType,
  supportingData: ViolationSupportingData | undefined
): string | undefined => {
  const syntheticSupportingData = getSyntheticSupportingData(
    checkType,
    supportingData
  );

  return syntheticSupportingData?.title;
};
