import styled from "styled-components";

import { blackGreyText, gray10 } from "../../Colors";
import { H3Light, TextLight, Text } from "../common/typography";

export const Panel = styled.div`
  display: flex;
  height: 100%;
  min-height: 30rem;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  margin: 24px;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
`;

export const StyledH3 = styled(H3Light)`
  color: ${gray10};
  font-size: 10px;
  line-height: 1.75rem;
`;

export const StyledText = styled(TextLight)`
  font-size: 20px;
  line-height: 1.25rem;
  color: ${blackGreyText};
`;

export const NoItemsContainer = styled(Text)`
  color: ${gray10};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-block-end: 4rem;
`;
