import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type StateInSearchParamsType = [
  string | null,
  (value: string | null, replace?: boolean) => void
];

export const useStateInSearchParams = (
  key: string,
  fromUrlLocation?: string
): StateInSearchParamsType => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = new URLSearchParams(location.search).get(key);

  const setState = useCallback(
    (value: string | null, replace = false) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      const newLocation = {
        pathname: fromUrlLocation ?? window.location.pathname,
        search: searchParams.toString(),
      };
      if (replace) {
        navigate(newLocation, { replace: true });
      } else {
        navigate(newLocation);
      }
    },
    [navigate, key, fromUrlLocation]
  );
  return [state, setState];
};
