import React from "react";
import { Tooltip, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import {
  FlexMessageSpan,
  OpinionatedSummary,
} from "../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/AvailabilitySummaryUtils";
import { AriaLabels } from "../../../../../../../shared/config/ariaLabels";

export const ISSUE_REASON_TOOLTIP = "issue-reason-tooltip";

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 0.5rem;
`;

export const IssueReasonTooltip: React.FC<{
  summary: OpinionatedSummary[0];
}> = ({ summary }) => {
  return summary ? (
    <Tooltip
      id={`${summary.reason}-${ISSUE_REASON_TOOLTIP}`}
      variant="light"
      place="bottom"
    >
      <TooltipContainer
        aria-label={AriaLabels.AvailabilityTriage.ReasonTooltip}
      >
        <Typography variant="title" size="large">
          {summary.reason}
        </Typography>
        {summary.customMessage && (
          <Typography size="medium">
            <FlexMessageSpan>{summary.customMessage}</FlexMessageSpan>
          </Typography>
        )}
      </TooltipContainer>
    </Tooltip>
  ) : null;
};
