import React, { useRef } from "react";
import styled from "styled-components";

import { MarginlessLineLoader } from "../../../../../common/loaders/MarginlessLineLoader";

import { OverviewMetrics } from "./OverviewMetrics/OverviewMetrics";
import { IssueItems } from "./IssueItems/IssueItems";
import { Insights } from "./Insights/Insights";
import { CardsContainer } from "./cardsContent/CardsContainer/CardsContainer";
import { InsightsDrawer } from "./Insights/InsightsDrawer/InsightsDrawer";
import { TransitionContainer } from "./Insights/TransitionContainer/TransitionContainer";
import { useFetchAppInsightsData } from "./hooks/fetchAppInsightsData";
import { ServiceResourceDrawer } from "./Insights/ServiceResourceDrawer/ServiceResourceDrawer";
import { OverviewPageTitle } from "./OverviewPageTitle";
import { useFetchMetricsData } from "./hooks/fetchMetricsData";
import {
  useResetStoreOnAppViewId,
  useSupportedAgentVersionsForMetrics,
  useSetAppViewFromUrlParams,
  useUpdateUiElementsRect,
  useHasFetchedMetricsClusterData,
} from "./hooks/overviewPageHooks";
import { useShouldRenderMockContent } from "./mocks/useShouldRenderMockContent";
import { MockAppScore } from "./mocks/MockAppScore";
import { UpgradeAgentVersionPrompt } from "./UpgradeAgentVersionPrompt/UpgradeAgentVersionPrompt";
import { useFetchAndSetAccuracyDataForMetrics } from "./hooks/fetchAndSetAccuracyData";
import { useOnPageDataLoaded } from "./hooks/useOnPageDataLoaded";

const MainContainer = styled.div`
  overflow: hidden;
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    box-sizing: border-box;
  }
`;

const ContentPadding = styled.div`
  padding: 24px 32px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1060px;
  flex-grow: 1;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverviewPage: React.FC = () => {
  const overviewRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const insightsRef = useRef<HTMLDivElement>(null);

  useResetStoreOnAppViewId();
  useSetAppViewFromUrlParams();
  useFetchAppInsightsData();
  useFetchMetricsData();
  useSupportedAgentVersionsForMetrics();
  useFetchAndSetAccuracyDataForMetrics();
  useOnPageDataLoaded();
  useUpdateUiElementsRect({
    pageRef,
    overviewRef,
    insightsRef,
  });

  const hasFetchedMetricsClusterData = useHasFetchedMetricsClusterData();
  const shouldRenderMockContent = useShouldRenderMockContent();

  if (!hasFetchedMetricsClusterData) {
    return (
      <LoaderContainer>
        <MarginlessLineLoader />
      </LoaderContainer>
    );
  }

  return (
    <MainContainer ref={pageRef}>
      <TransitionContainer>
        <OverviewContainer>
          <ContentPadding>
            <UpgradeAgentVersionPrompt />
            <FlexContainer>
              <ContentContainer ref={overviewRef}>
                {shouldRenderMockContent && <MockAppScore />}
                {!shouldRenderMockContent && (
                  <>
                    <OverviewPageTitle title={"Workspace Overview"} />
                    <OverviewMetrics />
                  </>
                )}
                <IssueItems />
                <CardsContainer />
              </ContentContainer>
              <Insights ref={insightsRef} />
            </FlexContainer>
          </ContentPadding>
        </OverviewContainer>
        <InsightsDrawer />
        <ServiceResourceDrawer />
      </TransitionContainer>
    </MainContainer>
  );
};
