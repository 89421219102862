import { Link, TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = () => (
  <TextContainer>
    The deployment reservation-details-service-preprod does not have a PDB (Pod
    Disruption Budget) configured.
  </TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Without a Pod Disruption Budget, a Single Point of Failure (SPOF)
    vulnerability will be created because there is no guarantee on the minimum
    number of pods that should remain available during voluntary disruptions
    (e.g., node maintenance).
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Define a PDB to ensure that a minimum number of pods are always available
    during disruptions.
  </TextContainer>
);

export const getSecondHowDescription = () => (
  <TextContainer>
    You can find the full explanation in the{" "}
    <Link href="https://kubernetes.io/docs/tasks/run-application/configure-pdb/#specifying-a-poddisruptionbudget">
      Kubernetes documentation
    </Link>
    . In this example, the PDB ensures that at least <b>2 pods</b> with the
    label app: <b>example-app</b> must be available during any voluntary
    disruption.
  </TextContainer>
);

export const yamlExample = `apiVersion: policy/v1
kind: PodDisruptionBudget
metadata:
   name: example-pdb
spec:
   minAvailable: 2
   selector:
     matchLabels:
       app: example-app`;
