import { useMemo } from "react";
import { compact } from "lodash";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { CONFIGURATION_CONFIGMAPS_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { ConfigMapEventsResponseDataInner } from "../../../../../../../generated/resourcesApi";

export const useConfigMapsEventsFetch = (
  configMapUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
): [ConfigMapEventsResponseDataInner[] | undefined, boolean] => {
  const configMapNames = useMemo(
    () =>
      compact(
        configMapUids?.map((configMapUid) => configMapUid.split(";").at(-1))
      ),
    [configMapUids]
  );

  const uids = useMemo(
    () =>
      configMapNames.map((resourceName) => {
        return buildKomodorUid({
          resourceName,
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          kind: "ConfigMap",
        });
      }),
    [clusterName, configMapNames, namespace]
  );

  const { data, loading } = useResourcesAPIGet(
    CONFIGURATION_CONFIGMAPS_EVENT_SEARCH,
    {
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      komodorUids: uids,
      fields: [
        "action",
        "clusterName",
        "eventTime",
        "namespace",
        "id",
        "configMapName",
        "k8sDefault",
        "services",
      ],
    },
    !uids.length
  );

  return [data?.data, loading];
};
