import React, { useEffect, useMemo, useState } from "react";
import { Button, Divider } from "@komodorio/design-system/deprecated";
import { Deploy, Loader16 } from "@komodorio/design-system/icons";
import { ActionMetadataHelmChartRevert } from "komodor-types/build/entities/AgentTask";
import { ActionTypes, HelmChartSummary, TaskType } from "komodor-types";
import styled from "styled-components";

import HelmChart from "../helmChart";
import { StyledDrawer } from "../../../ResourceView/ResourceDrawer";
import { WhiteContainer } from "../../../ResourceView/common/resourceUtils";
import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { KubernetesHelmResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import ManifestsTab from "../tabs/ManifestsTab";
import { useHelmChartSummaryToChart } from "../hooks/useHelmCharts";
import { LinesLoader } from "../../../common/loaders/Line";
import { taskResultToMessage } from "../utils";
import { ActionFiredMessage } from "../tabs/components/ActionFiredMessage";

import { HelmErrorMessage } from "./HelmErrorMessage";
import {
  StyledModal,
  MODAL_HEIGHT_PCT,
  Z_INDEX_ABOVE_MODAL,
  LoaderContainer,
} from "./styles";
import HelmDrawerHeader from "./upgrade/ChartUpgradeHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto 30fr auto 3fr;
  height: 100%;
  overflow: auto;
`;
const StyledHeader = styled(HelmDrawerHeader)`
  width: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 0.3rem;
`;
const StyledButtonsContainer = styled(ButtonsContainer)`
  width: 100%;
`;

export interface HelmChartRollbackDrawerProps {
  chart: HelmChart;
  open: boolean;
  onClose: () => void;
}

export interface HelmChartRollbackModalSummaryProps {
  chartSummary: HelmChartSummary;
  open: boolean;
  agentId: string;
  onClose: () => void;
}

const ChartRollbackDrawerContent: React.FC<{
  chart: HelmChart;
  onClose: () => void;
  showErrors?: boolean;
}> = ({ chart, onClose, showErrors }) => {
  const agentId = useActiveAgent(chart?.cluster) ?? "";
  const initialRevision = chart.revisions[chart.revisions.length - 2]?.revision;
  const [newRevision, setNewRevision] = useState<string>(initialRevision);
  const [done, setDone] = useState(false);
  const [commandSent, setCommandSent] = useState(false);
  const metadata: ActionMetadataHelmChartRevert = useMemo(() => {
    return {
      cluster: chart.cluster,
      chartName: chart.name,
      namespace: chart.namespace,
      revision: newRevision,
      type: ActionTypes.RevertHelmChart,
    };
  }, [chart.cluster, chart.name, chart.namespace, newRevision]);

  const {
    execute: rollback,
    result: rollbackResult,
    failureMessage,
    deniedObject,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  useEffect(() => {
    if (commandSent && !isFetching && !done) {
      setDone(true);

      const message = taskResultToMessage(
        rollbackResult as string,
        failureMessage,
        deniedObject
      );
      const pattern = new RegExp(`Rollback was a success`);
      const isSuccess = pattern.test(rollbackResult as string);
      chart.setLastActionResult({
        command: `Rollback to version ${newRevision}`,
        success: isSuccess,
        message: message,
        timestamp: new Date(),
      });
      if (!(showErrors && !isSuccess)) {
        onClose();
      }
    }
  }, [
    chart,
    chart.name,
    onClose,
    newRevision,
    rollbackResult,
    done,
    showErrors,
    failureMessage,
    deniedObject,
    isFetching,
    commandSent,
  ]);

  return (
    <Grid aria-label="helm-rollback">
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledHeader
            title={"Rollback"}
            icon={Deploy}
            onClose={onClose}
            labels={[
              { name: "type", value: KubernetesHelmResource.Kind },
              { name: "name", value: chart.name },
              { name: "cluster", value: chart.cluster },
              { name: "namespace", value: chart.namespace },
            ]}
          />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <ResponsiveLayout>
        {showErrors && done && chart.lastActionResult?.success === false && (
          <HelmErrorMessage result={chart.lastActionResult} />
        )}
        <ManifestsTab
          resource={chart}
          onRevisionChangeCallback={(revision) => {
            setNewRevision(revision);
          }}
          initialRevision={initialRevision}
          title={"Rollback to"}
        />
      </ResponsiveLayout>
      <Divider />
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledButtonsContainer>
            <Button
              size="small"
              variant="primary"
              onClick={() => {
                if (!isFetching) {
                  setCommandSent(true);
                  rollback();
                }
              }}
              disabled={newRevision === ""}
            >
              {isFetching ? <Loader16 /> : "Rollback"}
            </Button>
            <Button size="small" variant="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
            {isFetching && (
              <ActionFiredMessage
                locationType="drawer"
                isFetching={isFetching}
              />
            )}
          </StyledButtonsContainer>
        </ResponsiveLayout>
      </WhiteContainer>
    </Grid>
  );
};

const ChartRollbackDrawer: React.FC<HelmChartRollbackDrawerProps> = ({
  chart,
  open,
  onClose,
}) => {
  return (
    <StyledDrawer
      open={open}
      width={"60%"}
      onOverlayClick={onClose}
      zIndex={Number(Z_INDEX_ABOVE_MODAL)}
    >
      <ChartRollbackDrawerContent chart={chart} onClose={onClose} />
    </StyledDrawer>
  );
};

export const ChartRollbackModalForSummary: React.FC<
  HelmChartRollbackModalSummaryProps
> = ({ chartSummary, open, agentId, onClose }) => {
  const { data: chart, isFetching } = useHelmChartSummaryToChart(
    chartSummary,
    agentId
  );

  return (
    <StyledModal
      isOpen={open}
      width={"60%"}
      onRequestClose={onClose}
      height={`${MODAL_HEIGHT_PCT}%`}
    >
      {isFetching ? (
        <LoaderContainer>
          <LinesLoader marginTop="0" />
        </LoaderContainer>
      ) : chart !== undefined ? (
        <ChartRollbackDrawerContent
          chart={chart}
          onClose={onClose}
          showErrors={true}
        />
      ) : (
        "couldn't load chart"
      )}
    </StyledModal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ChartRollbackDrawer;
