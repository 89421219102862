import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ShortPanel } from "./styles";

export const Error: React.FC<{
  text: string;
  onRetryCallback: () => void;
}> = ({ text, onRetryCallback }) => {
  return (
    <ShortPanel center>
      <Typography fontWeight={500} fontSize="14px">
        {text}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        sx={{ lineHeight: "18px" }}
        onClick={onRetryCallback}
      >
        Retry
      </Button>
    </ShortPanel>
  );
};
