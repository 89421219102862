import {
  MetricsApiGetWorkloadContainersMetricsSummaryRequest,
  WorkloadMetricsContainersSummary,
} from "../../../../../../../../../../generated/metricsApi";
import { useMetricsAPIGet } from "../../../../../../../../../../shared/hooks/metrics-api/client";
import { METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT } from "../../../../../../../../../../shared/hooks/metrics-api/requestResponseMaps";

export const useFetchContainersSummary = (
  params: MetricsApiGetWorkloadContainersMetricsSummaryRequest,
  pause: boolean
) =>
  useMetricsAPIGet<WorkloadMetricsContainersSummary>(
    METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT,
    params,
    pause
  );
