import React, { Dispatch, useEffect, useMemo } from "react";
import styled from "styled-components";
import { muiColors, palette } from "@komodorio/design-system";
import {
  Skeleton,
  Typography as DeprecatedTypography,
} from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { formatDistanceToNow } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../resources/deployment";
import DaemonSet from "../resources/daemonset";
import StatefulSet from "../resources/statefulset";
import { useLatestServiceDeployQuery } from "../../../generated/graphql";
import { useQueryWithVariables } from "../../../shared/hooks/useQueryWithVariables";
import DeployEventGroup, {
  groupDeployEvents,
} from "../../common/EventGroup/deployEvent/DeployEventGroup";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import Rollout from "../resources/rollout";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { setFetchingStateSelector } from "../../../shared/store/resourceViewStore/resourceViewSelectors";
import { useResourceViewStore } from "../../../shared/store/resourceViewStore/resourceViewStore";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import {
  StatusColumn,
  StatusContainer,
  StatusTitle,
  StatusValue,
} from "./HeaderStyles";

const DeploymentStatusColorMapping = {
  started: palette.darkBlue[400],
  "in progress": palette.darkBlue[400],
  completed: palette.teal[800],
  failed: palette.pink[600],
  unknown: palette.gray[600],
};

const enum ServiceStatus {
  "DELETED" = "DELETED",
  "INACTIVE" = "INACTIVE",
  "HEALTHY" = "HEALTHY",
  "UNHEALTHY" = "UNHEALTHY",
}

const EmptyState = styled.div`
  display: flex;
  column-gap: 8px;
  max-width: 272px;
`;

const SquareSkeleton = styled(Skeleton)`
  border-radius: 4px;
`;

const StyledDeprecatedTypography = styled(DeprecatedTypography)`
  line-height: 21px;
`;

interface KomodorServiceHealthDeployProps {
  resource: Deployment | DaemonSet | StatefulSet | Rollout;
  setOpenMonitor: Dispatch<React.SetStateAction<boolean>>;
  setEventMonitor: Dispatch<
    React.SetStateAction<DeployEventGroup | WorkflowIssueEventGroup | undefined>
  >;
}

export const KomodorServiceHealthDeploy: React.FC<
  KomodorServiceHealthDeployProps
> = ({ resource, setOpenMonitor, setEventMonitor }) => {
  const { replicasOnlyAsAutomaticDeploys } = useOverridableFlags();
  const setFetchingState = useResourceViewStore(setFetchingStateSelector);

  const status = useMemo(() => {
    return resource.isDeleted
      ? ServiceStatus.DELETED
      : resource.inactive
      ? ServiceStatus.INACTIVE
      : resource.healthy
      ? ServiceStatus.HEALTHY
      : ServiceStatus.UNHEALTHY;
  }, [resource.healthy, resource.inactive, resource.isDeleted]);

  const deployQueryVars = useMemo(
    () => ({
      serviceId: resource.id,
      replicasOnly: replicasOnlyAsAutomaticDeploys ? "replicas_only" : "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      resource.id,
      status,
      resource.lastDeployStartDate,
      resource.lastDeployEndDate,
    ]
  );
  const latestServiceDeploy = useQueryWithVariables(
    useLatestServiceDeployQuery,
    deployQueryVars
  );

  const loading = !latestServiceDeploy?.event_deploy;

  const latestServiceDeployEventGroup = useMemo(() => {
    if (loading || !latestServiceDeploy?.event_deploy.length) {
      return;
    }
    const events = latestServiceDeploy.event_deploy;
    return groupDeployEvents([events[0]], {})[0];
  }, [latestServiceDeploy?.event_deploy, loading]);

  useEffect(() => {
    setFetchingState({
      key: "isFetchingLastDeploy",
      value: !latestServiceDeploy && !latestServiceDeployEventGroup,
    });
  }, [latestServiceDeploy, latestServiceDeployEventGroup, setFetchingState]);

  return (
    <>
      {loading ? (
        <SquareSkeleton height="54px" width="272px" />
      ) : latestServiceDeployEventGroup ? (
        <StatusContainer
          aria-label={AriaLabels.ResourceView.Header.ServiceHealth.LastDeploy}
          onClick={() => {
            setEventMonitor(latestServiceDeployEventGroup);
            setOpenMonitor(true);
          }}
          clickable
        >
          <StatusColumn>
            <StatusTitle>LAST DEPLOY</StatusTitle>
            <StyledDeprecatedTypography
              color={
                DeploymentStatusColorMapping[
                  latestServiceDeployEventGroup.status
                ]
              }
              bold
              size="medium"
            >
              {latestServiceDeployEventGroup.status.toUpperCase()}
            </StyledDeprecatedTypography>{" "}
          </StatusColumn>
          <StatusColumn>
            <StatusTitle>CHANGES</StatusTitle>
            <StatusValue>
              {latestServiceDeployEventGroup.deployCardDetails}
            </StatusValue>
          </StatusColumn>
          <StatusColumn>
            <StatusTitle>STARTED</StatusTitle>
            <StatusValue>
              {formatDistanceToNow(latestServiceDeployEventGroup.startTime, {
                addSuffix: true,
              })}
            </StatusValue>
          </StatusColumn>
        </StatusContainer>
      ) : (
        <StatusContainer
          clickable={false}
          aria-label={
            AriaLabels.ResourceView.Header.ServiceHealth.LastDeployEmptyState
          }
        >
          <EmptyState>
            <InfoOutlined
              sx={{ color: muiColors.gray[300], fontSize: "16px" }}
            />
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ fontSize: "13.2px", lineHeight: "20px" }}
              noWrap
            >
              No deploy info found.
              <br />
              Last deploy was more than 30 days ago.
            </Typography>
          </EmptyState>
        </StatusContainer>
      )}
    </>
  );
};
