import React from "react";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";
import { YamlView } from "../../shared/YamlView/YamlView";
import { useYamlFromKomodorUid } from "../../../hooks/useYamlFromKomodorUid";
import { useHighlightYamlLine } from "../../../hooks/useHighlightYamlLine";
import { EnlargeYamlButton } from "../../shared/YamlView/components/EnlargeYamlButton";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

const WANTED_HPA_YAML_LINE = "minReplicas: 1";

export const HpaMin1: React.FC = () => {
  const { data } = useViolation();

  const {
    headerProps,
    summaryProps,
    dependentByViolationsIds,
    supportingData,
    komodorUid,
  } = useGetCommonDrawerProps(data?.data.violation);

  const {
    cluster = "",
    namespace = "",
    name,
  } = parseKomodorUid(komodorUid ?? "") ?? {};

  const hpaUid = buildKomodorUid({
    resourceName: supportingData?.HPAMinAvailability?.hpaName ?? "",
    clusterName: cluster,
    namespace: namespace,
    kind: "HorizontalPodAutoscaler",
  });

  const yaml = useYamlFromKomodorUid({ komodorUid: hpaUid ?? "" });

  const highlightLine = useHighlightYamlLine({
    yaml: yaml,
    wantedString: WANTED_HPA_YAML_LINE,
  });

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription()}
              <ViolationsTableByIds
                title="Runtime impact"
                ids={dependentByViolationsIds}
                violationTableType={ViolationsTableType.riskAssessment}
              />
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={
            <>
              {howDescription()}
              <YamlView
                title={`${name}.yaml`}
                yaml={yaml}
                highlightLine={highlightLine}
                additionalContent={<EnlargeYamlButton yaml={yaml} />}
              />
            </>
          }
        />
      }
    />
  );
};
