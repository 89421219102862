import React, { useEffect, useMemo, useState } from "react";

import { APIDeprecationsSupportingData } from "../../../../../../../../../../generated/reliabilityApi";
import { DrawerContentContainer } from "../../../shared/DrawerBase/styles";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

import { VersionsTimeline } from "./VersionsTimeline/VersionsTimeline";
import {
  useAllVersionsWithSummary,
  useGetFollowingVersions,
  useGetVersionsSummary,
} from "./DetailsHooks";
import {
  compareSemanticVersions,
  fillMissingMinorVersions,
  getVersions,
} from "./DetailsUtils";
import { VersionsAPIs } from "./VersionsAPIs/VersionsAPIs";

interface Props {
  supportingData: APIDeprecationsSupportingData | undefined;
  clusterName: string | undefined;
}

export const Details: React.FC<Props> = ({ supportingData, clusterName }) => {
  const [selectedVersion, setSelectedVersion] = useState<string>();

  const versionsSummary = useGetVersionsSummary(supportingData?.deprecatedAPIs);

  const allVersions = useMemo(() => {
    const versions = getVersions(supportingData);

    const sortedVersions = [...versions].sort(compareSemanticVersions);

    const filledVersions = fillMissingMinorVersions(
      sortedVersions[0],
      supportingData?.clusterProviderMaxKnownVersion
    );

    return filledVersions;
  }, [supportingData]);

  const allVersionsWithSummary = useAllVersionsWithSummary(
    allVersions,
    versionsSummary
  );

  const followingVersions = useGetFollowingVersions(
    supportingData?.clusterVersion,
    allVersionsWithSummary
  );

  useEffect(
    () =>
      setSelectedVersion(
        followingVersions?.[followingVersions?.length - 1]?.version
      ),
    [followingVersions]
  );

  useReportDrawerLoadingTime(!!supportingData?.deprecatedAPIs);

  return (
    <DrawerContentContainer>
      <VersionsTimeline
        currentVersion={supportingData?.clusterVersion}
        followingVersions={followingVersions}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
      />
      <VersionsAPIs
        followingVersions={followingVersions}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        clusterName={clusterName}
      />
    </DrawerContentContainer>
  );
};
