import React, { useEffect } from "react";
import styled from "styled-components";
import { Divider } from "@komodorio/design-system/deprecated";

import { useCreationPageContext } from "../context/CreationPageContext";
import { ErrorPage } from "../ErrorPage";
import { CreationPageHeader } from "../CreationPageHeader/CreationPageHeader";
import { ServicesTable } from "../ServicesTable/ServicesTable";
import { CreationPageFooter } from "../CreationPageFooter/CreationPageFooter";
import {
  useIsEditMode,
  usePopulateDataInEditModeEffect,
  useResetCreationPageStateEffect,
} from "../hooks/creationPageHooks";
import {
  isAppViewDataComplete,
  useGetAppViewData,
} from "../../../hooks/overviewPageHooks";
import { PageLoader } from "../../../../../../../shared/components/PageLoader";
import { useOverridableFlags } from "../../../../../../../shared/context/featureFlags/OverridableFlags";
import { CreationPageUpgradeAgent } from "../CreationPageUpgradeAgent/CreationPageUpgradeAgent";
import {
  currentAppViewSelector,
  errorStateSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";

import { CreationPageScopingTabs } from "./CreationPageScopingTabs";

const PaddedContainer = styled.div`
  padding: 1.5rem;
`;

export const CreationPageContent: React.FC = () => {
  const isEditMode = useIsEditMode();
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const appViewError = useAppViewsStore(errorStateSelector);
  const {
    errorState: { savingAppViewError },
  } = useCreationPageContext();

  const getAppViewData = useGetAppViewData();
  const isAppViewDataReady = isAppViewDataComplete(currentAppView);
  const { enableAppViewDynamicScoping } = useOverridableFlags();
  usePopulateDataInEditModeEffect();
  useResetCreationPageStateEffect();

  useEffect(() => {
    if (isEditMode) {
      getAppViewData();
    } else {
      // create new App, clear selectedAppView
      setSelectedAppViewId(undefined);
    }
  }, [getAppViewData, isEditMode, setSelectedAppViewId]);

  const showError = savingAppViewError || appViewError;
  const showLoader = isEditMode && !isAppViewDataReady;
  const content = (
    <>
      <PaddedContainer>
        <CreationPageHeader />

        {enableAppViewDynamicScoping ? (
          <CreationPageScopingTabs />
        ) : (
          <>
            <Divider spacing="2rem" />
            <ServicesTable />
          </>
        )}
      </PaddedContainer>
      <CreationPageFooter />
      <CreationPageUpgradeAgent />
    </>
  );

  if (showError) {
    return <ErrorPage />;
  }
  if (showLoader) {
    return <PageLoader />;
  }
  return content;
};
