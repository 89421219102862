import React from "react";

import { InsightCard } from "../../shared/components/InsightCard";
import { insightCardTitles } from "../../insightsConstants";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import { selectAppInsightsData } from "../../../store/overviewPageStoreSelectors";
import { useOpenInsightsDrawer } from "../../shared/hooks/appInisghtsHooks";
import { useHasSufficientMetricsData } from "../../../hooks/overviewPageHooks";

import { PotentialCostSavingDrawerContent } from "./PotentialCostSavingDrawerContent/PotentialCostSavingDrawerContent";
import { useGetPotentialSavingDrawerDescription } from "./utils";
import { useShouldDisplayPotentialCostSavings } from "./costHooks";

export const ID = "Potential Cost Saving";

export const PotentialCostSaving: React.FC = () => {
  const {
    potentialCostSavings: { data, loading },
  } = useOverviewPageStore(selectAppInsightsData);

  const hasSufficientMetricsData = useHasSufficientMetricsData();

  const openInsightsDrawer = useOpenInsightsDrawer();

  const { title, icon } = insightCardTitles.potentialCostSavings;

  const description = useGetPotentialSavingDrawerDescription(data);

  const shouldDisplayPotentialCostSavings =
    useShouldDisplayPotentialCostSavings();

  if (
    !hasSufficientMetricsData ||
    (!loading && !shouldDisplayPotentialCostSavings)
  ) {
    return null;
  }

  const onCardClick = () => {
    openInsightsDrawer({
      title: description,
      component: <PotentialCostSavingDrawerContent />,
      ariaLabel: ID,
      id: ID,
    });
  };

  return (
    <InsightCard
      id={ID}
      ariaLabel={ID}
      title={title}
      icon={icon}
      description={description}
      onClick={onCardClick}
      loading={loading}
      loadingSkeletonFormation={"oneDescriptionLine"}
    />
  );
};
