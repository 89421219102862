import { useMemo } from "react";

import { GenericViolationFilterCategory } from "@/generated/reliabilityApi";
import { useCheckCategory } from "@/components/reliability/hooks/useCheckCategory";

export const useAddCheckCategoryFilter = (): GenericViolationFilterCategory => {
  const checkCategory = useCheckCategory();
  return useMemo(
    () => ({
      category: "checkCategory",
      operator: "include",
      value: checkCategory,
    }),
    [checkCategory]
  );
};
