import { palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

import { SAVINGS_COLOR } from "../../../../constants/costOptimizationConstants";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";

import { formatPotentialSavings } from "./formatPotentialSavings";

const StyledSavingRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.3rem;
`;

const labels = {
  monthly: {
    title: {
      large: "Potential Monthly Savings",
      small: "Monthly",
    },
    subtitle: "/ month",
  },
  yearly: {
    title: {
      large: "Potential Yearly Savings",
      small: "Yearly",
    },
    subtitle: "/ year",
  },
};

const fontSizes = {
  small: {
    period: "14px",
    amount: "20px",
    title: "12px",
  },
  large: {
    period: "14px",
    amount: "24px",
    title: "14px",
  },
};
export const PotentialSavingPeriod: React.FC<{
  variant: "monthly" | "yearly";
  size?: "small" | "large";
  amount: number | undefined;
}> = ({ variant, amount, size = "large" }) => {
  return (
    <div
      aria-label={
        costOptimizationAriaLabels.rightSizingPage.summaryCards.savingCard[
          variant
        ]
      }
    >
      <Typography fontSize={fontSizes[size].title} variant={"h5"}>
        {labels[variant].title[size]}
      </Typography>
      <StyledSavingRow>
        <Typography
          fontSize={fontSizes[size].amount}
          variant={"h2"}
          color={SAVINGS_COLOR}
        >
          {formatPotentialSavings(amount)}
        </Typography>
        <Typography
          color={palette.gray[600]}
          fontSize={fontSizes[size].period}
          variant={"subtitle1"}
        >
          {labels[variant].subtitle}
        </Typography>
      </StyledSavingRow>
    </div>
  );
};
