import React from "react";
import styled from "styled-components";

import { RecommendationData } from "../../types";

import { ResourceContainer } from "./ResourceContainer";

const ResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

interface ContainerContentProps {
  recommendationData: RecommendationData;
}

export const ContainerContent: React.FC<ContainerContentProps> = ({
  recommendationData,
}) => {
  return (
    <ResourcesWrapper>
      <ResourceContainer type={"cpu"} recommendationData={recommendationData} />
      <ResourceContainer
        type={"memory"}
        recommendationData={recommendationData}
      />
    </ResourcesWrapper>
  );
};
