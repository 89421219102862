import { OptionType, Select } from "@komodorio/design-system/deprecated";
import React, { useMemo } from "react";

import { costOptimizationAriaLabels as ariaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { ZIndex } from "../../../../../../constants/zIndex";
import {
  STRATEGY_OPTIONS,
  STRATEGY_VALUE_TO_LABEL,
  STRATEGY_VALUE_TO_SUBLABEL,
} from "../../../../constants/costOptimizationConstants";
import { commonSelectProps } from "../../../shared/commonSelectProps";

export const ModalSelectStrategy: React.FC<{
  disabled: boolean;
  value: OptionType<string>;
  onChange: (selected: OptionType<string>) => void;
  width?: string;
}> = ({ disabled, value, onChange, width = commonSelectProps.width }) => {
  const selectOptions = useMemo(
    () =>
      STRATEGY_OPTIONS.map((option) => ({
        label: STRATEGY_VALUE_TO_LABEL[option],
        subLabel: STRATEGY_VALUE_TO_SUBLABEL[option],
        value: option,
      })),
    []
  );

  return (
    <>
      <div>
        <Select
          width={width}
          disabled={disabled}
          listZIndex={`${ZIndex.AboveModal}`}
          aria-label={ariaLabels.rightSizingPage.strategy}
          value={value}
          placeholder={"Clusters"}
          options={selectOptions}
          onChange={onChange}
        />
      </div>
    </>
  );
};
