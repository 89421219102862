import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1WorkloadsDeletedPodsEventsSearchGetUrl,
  DeletedPodsResponse,
  EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest,
} from "../../../../generated/resourcesApi/api";

import { useResourcesApiClient } from "./apiClient";

const fetchDeletedPodsState = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest
) => {
  const { data } = await apiClient.get<DeletedPodsResponse>(
    apiV1WorkloadsDeletedPodsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetDeletedPodsState = (
  params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useResourcesApiClient();
  const url = apiV1WorkloadsDeletedPodsEventsSearchGetUrl(
    {},
    apiClient.defaults.baseURL ?? ""
  );
  return useQuery(
    [url, params],
    () => fetchDeletedPodsState(apiClient, params),
    options
  );
};
