import { useCallback, useMemo } from "react";

import { ServiceInfo } from "../../../types/ServiceInfo";
import { useListServiceAttributesForAccountQuery } from "../../../../generated/graphql";
import {
  AllServicesAttributes,
  useServiceAttributesForAccount,
} from "../../../../components/servicesView/servicesAttributes/service-attributes-fetch-hook";
import { HealthStatus, FilterCategories } from "../constants";

const pushFilter = (
  filtersList: FiltersList,
  category: string,
  option: string,
  serviceId: string
) => {
  if (!filtersList[category]) filtersList[category] = {};
  if (!filtersList[category][option]) filtersList[category][option] = [];
  filtersList[category][option].push(serviceId);
};

const addStaticFilters = (
  filtersList: FiltersList,
  services: ServiceInfo[],
  isJobs: boolean
) => {
  filtersList[FilterCategories.health] = {
    [HealthStatus.healthy]: [],
    [HealthStatus.unhealthy]: [],
  };
  filtersList[FilterCategories.ongoing] = { Deploy: [] };
  services.forEach((service) => {
    pushFilter(
      filtersList,
      FilterCategories.clusters,
      service.k8sCluster,
      service.id
    );
    pushFilter(
      filtersList,
      FilterCategories.namespaces,
      service.env,
      service.id
    );
    if (isJobs) {
      pushFilter(
        filtersList,
        FilterCategories.status,
        service.jobState,
        service.id
      );
    } else {
      if (service.healthy) {
        filtersList[FilterCategories.health][HealthStatus.healthy].push(
          service.id
        );
      } else {
        filtersList[FilterCategories.health][HealthStatus.unhealthy].push(
          service.id
        );
      }
    }
    if (service.currentlyDeploying) {
      filtersList[FilterCategories.ongoing]["Deploy"].push(service.id);
    }
    if (service.kind) {
      pushFilter(filtersList, FilterCategories.kind, service.kind, service.id);
    }
  });
};

export type FiltersList = Record<string, Record<string, string[]>>;
export const useFiltersList = (
  services: ServiceInfo[] | undefined,
  allServicesAttributes: AllServicesAttributes,
  isJobs = false
): [FiltersList | null, () => void] => {
  const [{ data: serviceAttsConfigs }, refresh] =
    useListServiceAttributesForAccountQuery();
  const attributesForAccount = useServiceAttributesForAccount(
    serviceAttsConfigs?.service_attributes_for_account
  );

  const refreshServiceAttsConfig = useCallback(() => {
    refresh({ requestPolicy: "network-only" });
  }, [refresh]);

  const filtersList = useMemo(() => {
    if (!services || !attributesForAccount || !allServicesAttributes) {
      return null;
    }
    const filtersList: FiltersList = {};
    addStaticFilters(filtersList, services, isJobs);
    const categories = Object.keys(attributesForAccount).filter(
      (c) => !(c in filtersList)
    );
    services.forEach((service) => {
      const serviceAttributes = allServicesAttributes[service.id];
      if (!serviceAttributes) return;
      categories.forEach((category) => {
        const attributeValue = serviceAttributes.find(({ key }) =>
          attributesForAccount[category].has(key)
        )?.value;
        if (!attributeValue) return;
        pushFilter(filtersList, category, attributeValue, service.id);
      });
    });

    return filtersList;
  }, [allServicesAttributes, attributesForAccount, isJobs, services]);

  return [filtersList, refreshServiceAttsConfig];
};
