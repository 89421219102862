import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import { TabValue } from "../../../../../ReliabilityTypes";
import { getAccumulatedViolations } from "../../../../../utils/reliabilityUtils";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import { formatNumber } from "../../../../../../../shared/utils/formatNumber";

import { useViolationsTableContext } from "./context/useViolationsTableContext";

import { Dictionary } from "@/shared/types/Dictionary";

const {
  violationsTable: {
    severityTab: severityTabAriaLabel,
    severityTabsContainer: severityTabsContainerAriaLabel,
  },
} = reliabilityArialLabels;

type ViolationsTableTabsProps = {
  severitiesCount: Dictionary<number> | undefined;
};

export const ViolationsTableTabs: React.FC<ViolationsTableTabsProps> = ({
  severitiesCount,
}) => {
  const { tabValue, setTabValue } = useViolationsTableContext();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as TabValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={onTabChange}
        aria-label={severityTabsContainerAriaLabel}
      >
        <Tab
          label={getTabLabel("all", severitiesCount)}
          value={"all"}
          aria-label={`${severityTabAriaLabel} - all`}
        />
        <Tab
          label={getTabLabel("high", severitiesCount)}
          value={"high"}
          aria-label={`${severityTabAriaLabel} - high`}
        />
        <Tab
          label={getTabLabel("medium", severitiesCount)}
          value={"medium"}
          aria-label={`${severityTabAriaLabel} - medium`}
        />
        <Tab
          label={getTabLabel("low", severitiesCount)}
          value={"low"}
          aria-label={`${severityTabAriaLabel} - low`}
        />
      </TabList>
    </TabContext>
  );
};

const tabLabelValueToLabel: Record<TabValue, string> = {
  all: "All Severities",
  high: "High",
  medium: "Medium",
  low: "Low",
};

const getTabLabel = (label: TabValue, violations?: Dictionary<number>) => {
  const severityLabel = tabLabelValueToLabel[label];
  if (!violations) {
    return `${severityLabel} `;
  }
  const severityNumber =
    label === "all"
      ? getAccumulatedViolations(violations)
      : violations[label] ?? 0;
  return `${severityLabel} (${formatNumber(severityNumber)})`;
};
