import { compact } from "lodash";
import { useMemo } from "react";

import { Kubernetes_Service_ResourceFragment } from "../../../../../../../generated/graphql";
import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { useResourcesAPIGet } from "../../../../../../../shared/hooks/resources-api/client";
import { NETWORK_SERVICE_EVENT_SEARCH } from "../../../../../../../shared/hooks/resources-api/requestResponseMaps";
import { buildKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { K8sServiceEventsResponse } from "../../../../../../../generated/resourcesApi";

export const useServicesEventsFetch = (
  servicesUids: string[] | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined
): [Kubernetes_Service_ResourceFragment[] | undefined, boolean] => {
  const servicesNames = useMemo(
    () =>
      compact(
        servicesUids?.map((servicesUid) => servicesUid.split(";").at(-1))
      ),
    [servicesUids]
  );

  const komodorUids = useMemo(
    () =>
      servicesNames.map((service) =>
        buildKomodorUid({
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          kind: "Service",
          resourceName: service,
        })
      ),
    [clusterName, namespace, servicesNames]
  );

  const { data, loading } = useResourcesAPIGet(
    NETWORK_SERVICE_EVENT_SEARCH,
    {
      komodorUids: komodorUids,
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      fields: [
        "id",
        "name",
        "namespace",
        "clusterName",
        "eventTime",
        "services",
        "action",
      ],
    },
    !timeWindow?.start || !timeWindow?.end || !komodorUids?.length
  );

  const servicesEvents = useMemo(
    () => convertApiResponseToFragment(data),
    [data]
  );

  return [servicesEvents, loading];
};

const convertApiResponseToFragment = (
  servicesEventsFromAPI: K8sServiceEventsResponse | undefined
): Kubernetes_Service_ResourceFragment[] | undefined => {
  return servicesEventsFromAPI?.data.map(
    (event) => event as Kubernetes_Service_ResourceFragment
  );
};
