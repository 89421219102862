import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export const BorderStyle = css`
  border-bottom: 1px solid ${muiColors.gray[200]};
`;

export const PaddingStyle = css`
  padding: 0 16px;
`;

export const IgnoreRuleContainer = styled.div`
  ${PaddingStyle};
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
`;

export const DrawerPadding = css`
  padding: 0 60px;
`;

export const StyledArrow = styled(KeyboardArrowRight)`
  && {
    color: ${muiColors.gray[500]};
    width: 16px;
    margin: -1px 8px 0 8px;
  }
`;

export const IgnoreRulesTableOverrides = css`
  && {
    .MuiDataGrid-root {
      border: none;
    }
    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
    }
    .MuiDataGrid-overlayWrapper {
      height: 200px;
      width: 100%;
      overflow: hidden;
    }
  }
`;
