import React from "react";
import styled from "styled-components";

import filterBar from "../assets/filter-bar-3.png";
import services from "../assets/services-1.png";

const FilterBarImage = styled.img`
  object-fit: scale-down;
  width: 90%;
  height: 50%;
  margin: 0 60px 12px 60px;
`;
const MainImage = styled.img`
  object-fit: scale-down;
  width: 90%;
  height: 50%;
  margin: 12px 60px;
`;

export const ServicesTab: React.FC = () => {
  return (
    <div>
      <FilterBarImage src={filterBar} />
      <MainImage src={services} />
    </div>
  );
};
