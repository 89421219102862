import React from "react";
import styled from "styled-components";

import MonitorsListClusterFilter from "./filters/MonitorsListClusterFilter";
import MonitorsListRuleTypeFilter from "./filters/MonitorsListRuleTypeFilter";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 1rem;
`;

const MonitorFilters: React.FC<{
  clusters: string[];
  cluster: string | undefined;
  setCluster: React.Dispatch<React.SetStateAction<string | undefined>>;
  ruleTypes: string[];
  ruleType: string | undefined;
  setRuleType: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ clusters, cluster, setCluster, ruleTypes, ruleType, setRuleType }) => {
  return (
    <Container>
      <MonitorsListClusterFilter
        clusters={clusters}
        cluster={cluster}
        setCluster={setCluster}
      />
      <MonitorsListRuleTypeFilter
        ruleTypes={ruleTypes}
        ruleType={ruleType}
        setRuleType={setRuleType}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MonitorFilters;
