import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useGetEntityLiveStateResetFilters } from "@/shared/hooks/k8s-add-ons/useGetAddonEntityLiveStateResetFilters";

const DEFAULT_RESET_FILTERS = ["cluster", "namespace"];

export const useGetLiveStateResetFilters = () => {
  const { addonType, addonEntity } = useAddonContext();

  const { data, isError } = useGetEntityLiveStateResetFilters({
    addon: addonType,
    entity: addonEntity,
  });

  if (isError) {
    // do not disable all live-state requests if this request fails
    return DEFAULT_RESET_FILTERS;
  }

  return data?.data.filters;
};
