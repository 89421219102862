import React from "react";
import styled from "styled-components";

import Header from "../../../../../monitorsView/common/Header";
import Divider from "../../../../../monitorsView/common/Divider";
import { Content } from "../../../../../monitorsView/monitorsConfiguration/common/styles";
import Footer from "../../../../../monitorsView/common/Footer";
import { IntegrationSteps } from "../utils";
import { StyledContainer } from "../styles";

import {
  FirstInstruction,
  SecondInstruction,
  ThirdInstruction,
  FourthInstruction,
} from "./Instructions";

const headerTitle = "Install Sentry Integration";

const InstructionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-row-gap: 1.5rem;
`;

const SentrySetup: React.FC<{
  closeModalCallback: () => void;
  setCurStep: React.Dispatch<React.SetStateAction<number>>;
  curStep: number;
  numOfSteps: number;
  webhookUrl: string;
}> = ({ closeModalCallback, setCurStep, curStep, numOfSteps, webhookUrl }) => {
  return (
    <StyledContainer>
      <Header
        closeModalCallback={closeModalCallback}
        title={IntegrationSteps.SentrySetup}
        currentStepNumber={curStep}
        totalNumberOfSteps={numOfSteps}
        headerTitle={headerTitle}
      />
      <Divider />
      <Content>
        <InstructionsGrid>
          <FirstInstruction />
          <SecondInstruction webhookUrl={webhookUrl} />
          <ThirdInstruction />
          <FourthInstruction />
        </InstructionsGrid>
      </Content>
      <Divider />
      <Footer
        backData={{ label: "Cancel", action: closeModalCallback }}
        nextData={{
          label: "Continue",
          action: () => {
            setCurStep(curStep + 1);
          },
        }}
      />
    </StyledContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SentrySetup;
