import { formatDuration, intervalToDuration } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey } from "../groupEvents";
import { shortEnLocale } from "../../../../shared/hooks/useDateFormatter";

import { AgentDowntimeRawKubernetesAgentInfo } from "./agentDowntimeTypes";

interface AgentInfo {
  clusterName: string;
  createdAt: string;
  lastSeenAlive: string;
}

export interface Downtime {
  startTime: string;
  endTime: string;
  services?: unknown;
  clusterName: string;
  duration: {
    milliseconds: number;
    readable: string;
  };
}

const SENSITIVITY_THRESHOLD = 300000;

export const getDowntimes = ({
  agentInfo,
}: {
  agentInfo: AgentInfo[];
}): Downtime[] => {
  const downtimes: Downtime[] = [];

  for (let i = 1; i < agentInfo.length; i++) {
    const current = agentInfo[i - 1];
    const previous = agentInfo[i];
    const currentTime = new Date(current.createdAt).getTime();
    const previousTime = new Date(previous.lastSeenAlive).getTime();
    const downtimeMillis = currentTime - previousTime;

    if (downtimeMillis > SENSITIVITY_THRESHOLD) {
      const downtime: Downtime = {
        startTime: previous.lastSeenAlive,
        endTime: current.createdAt,
        clusterName: current.clusterName,
        duration: {
          milliseconds: downtimeMillis,
          readable: getReadableDuration(
            previous.lastSeenAlive,
            current.createdAt
          ),
        },
      };
      downtimes.push(downtime);
    }
  }

  return downtimes;
};

const getReadableDuration = (start: string, end: string): string => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const duration = intervalToDuration({ start: startDate, end: endDate });
  const readableDuration = formatDuration(duration, {
    format: ["weeks", "days", "hours", "minutes"],
    locale: shortEnLocale,
  }).replace(/ /g, "");

  return readableDuration;
};

export const calculateAgentDowntimes = (
  events: AgentDowntimeRawKubernetesAgentInfo[] | undefined
): Downtime[] => {
  if (!events || !events.length) {
    return [];
  }

  const rawEventsLongerThan5mins = events.filter((event) => {
    const createdTime = new Date(event.createdAt).getTime();
    const lastSeenTime = new Date(event.lastSeenAlive).getTime();
    const downtimeMillis = lastSeenTime - createdTime;
    return downtimeMillis > SENSITIVITY_THRESHOLD;
  });

  const rawEventsSplitByCluster = groupEventsByKey(
    rawEventsLongerThan5mins,
    (event) => event.clusterName
  );

  const calculatedDowntimes = rawEventsSplitByCluster
    .map((eventsArrayByCluster) =>
      getDowntimes({ agentInfo: eventsArrayByCluster })
    )
    .flat();

  return calculatedDowntimes;
};
