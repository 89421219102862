import semver from "semver";

import {
  APIDeprecationsSupportingData,
  ViolationSupportingData,
} from "../../../../../../../../../../generated/reliabilityApi";

export const getVersion = (version: string) =>
  semver.coerce(version)?.version ?? "";

export const compareSemanticVersions = (a: string, b: string) => {
  const firstVersion = getVersion(a);
  const secondVersion = getVersion(b);

  return semver.gt(firstVersion, secondVersion) ? 1 : -1;
};

export const getVersions = (
  supportingData: APIDeprecationsSupportingData | undefined
) => {
  const versions = new Set<string>();
  if (!supportingData) return versions;
  supportingData.deprecatedAPIs.forEach((api) => {
    if (api.deprecatedInVersion) {
      versions.add(api.deprecatedInVersion);
    }
    if (api.removedInVersion) {
      versions.add(api.removedInVersion);
    }
  });
  versions.add(supportingData?.clusterVersion);

  return versions;
};

export const fillMissingMinorVersions = (
  minVersion: string | undefined,
  maxVersion: string | undefined
) => {
  if (!minVersion || !maxVersion) return [];
  const versions = [];
  const firstVersion = getVersion(minVersion);
  const lastVersion = getVersion(maxVersion);

  let currentVersion = firstVersion;
  while (semver.lte(currentVersion, lastVersion)) {
    versions.push(currentVersion);
    const fixedVersion = getVersion(currentVersion);
    currentVersion = semver.inc(fixedVersion, "minor") ?? lastVersion;
  }
  return versions;
};

export const getDeprecatedApiTitleFromVersion = (
  version: string | undefined
) => {
  if (!version) return "Kubernetes version upgrades API constraints";
  return `Kubernetes ${removePatchFromVersion(
    version
  )} version upgrades API constraints`;
};

export const getDeprecatedApiTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const version = supportingData?.deprecatedApis?.clusterVersion;
  return getDeprecatedApiTitleFromVersion(version);
};

const removePatchFromVersion = (version: string) => {
  const [major, minor] = version.split(".");
  return `${major}.${minor}`;
};

export const PAGINATION_SIZE_OPTIONS = [5, 10, 20];
export const DEFAULT_PAGE_SIZE = PAGINATION_SIZE_OPTIONS[0];
