import { v4 as uuid4 } from "uuid";

interface Args {
  messageType: string;
  data?: Record<string, string | number>;
  sessionId?: string;
}

export interface BasicSocketPayload {
  messageId: string;
  sessionId?: string;
  data?: Record<string, string | number>;
  timestamp: string;
  messageType: string;
}

export const generateSocketPayload = ({
  messageType,
  data = {},
  sessionId,
}: Args): BasicSocketPayload => ({
  messageId: uuid4(),
  messageType,
  timestamp: new Date().toISOString(),
  data,
  sessionId,
});
