import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography, Input, Button } from "@komodorio/design-system/deprecated";
import { ArrowRight24 } from "@komodorio/design-system/icons";
import { v4 } from "uuid";

import { useCreateSilentSignupMutation } from "../../../generated/graphql";
import logo from "../../../shared/assets/logo.svg";
import saveDesktopUserCredentials from "../../../shared/context/electron/setDesktopUserCredentials";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import ExternalLink from "../../common/Link/ExternalLink";
import { emailRegex } from "../../../components/Settings/Users/const";

import { passwordGenerator } from "./password";
import { getUniquenessPrefix } from "./utils/getUniquenessPrefix";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";

interface SilentSignupProps {
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const Background = styled.div`
  background-color: ${palette.darkBlue[900]};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2rem;
  border-radius: 0.5rem;
`;

const SubmitButton = styled(Button)`
  align-self: center;
`;

const Logo = styled.img.attrs({ src: logo, alt: "Komodor" })`
  width: 15rem;
`;

const Section = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

export const SilentSignup: React.FC<SilentSignupProps> = ({
  setAccessToken,
}) => {
  const [mail, setMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [internalError, setInternalError] = useState("");
  const [, executeSignup] = useCreateSilentSignupMutation();
  const userIsAnonymous = useIsAnonymousUser();

  const errorMessage = useMemo(() => {
    if (internalError) return `Internal error: ${internalError}`;
    if (mail === "") return;
    if (!emailRegex.test(mail)) return "Invalid mail";
    return;
  }, [internalError, mail]);

  const submitDisabled = !mail || !!errorMessage || isLoading;

  const runCreation = async () => {
    if (submitDisabled) return;
    dispatchEvent(AnalyticEvents.Desktop.Entered_User_ID);
    const userMail = mail.toLowerCase();
    const id = getUniquenessPrefix();
    const password = passwordGenerator();
    const installationId = v4();

    setIsLoading(true);
    const res = await executeSignup({
      mail: userMail,
      password: password,
      id: id,
      installationId: installationId,
    });
    setIsLoading(false);

    if (res.error) {
      const errorMessage =
        res.error.graphQLErrors?.[0]?.message ||
        res.error.message ||
        "unknown error";
      setInternalError(errorMessage);
      return;
    }

    const accessToken = res.data?.desktopSilentSignup?.jwt;
    setAccessToken(accessToken || null);
    if (!accessToken) return;

    saveDesktopUserCredentials(userMail, id, password, installationId);
    if (userIsAnonymous) {
      location.reload();
    }
  };

  return (
    <Background>
      <Container>
        <Logo />
        <Section gap="0.5rem">
          <Input
            label="Please enter your email"
            size="medium"
            width="20rem"
            placeholder="Email"
            onChange={(e) => {
              setInternalError("");
              setMail(e.target.value);
            }}
            errorMessage={errorMessage}
          />
          <Typography variant="text" size="small" color={palette.gray[600]}>
            (This will serve as your unique ID)
          </Typography>
        </Section>
        <Section gap="1rem">
          <SubmitButton
            variant="primary"
            size="large"
            disabled={submitDisabled}
            onClick={runCreation}
          >
            Continue {<ArrowRight24 />}
          </SubmitButton>
          <Typography variant="text" size="small" color={palette.gray[600]}>
            By signing up, you agree to Komodor's{" "}
            <ExternalLink href="https://komodor.com/terms-of-use/">
              Terms of Use
            </ExternalLink>
            .
          </Typography>
        </Section>
      </Container>
    </Background>
  );
};
