import { Pod, Service } from "kubernetes-types/core/v1.d";

import {
  AvailablePort,
  SupportedResourceKindEnum,
} from "../../../shared/context/PortForwardProvider";

const getPortsFromPod = (pod: Pod): AvailablePort[] => {
  if (!pod.spec?.containers) return [];

  return pod.spec.containers.flatMap((container) => {
    if (!container.ports) return [];

    return container.ports.map(({ containerPort, protocol, name }) => {
      return {
        port: containerPort,
        protocol,
        name,
      } as AvailablePort;
    });
  });
};

const getPortsFromService = (service: Service): AvailablePort[] => {
  if (!service.spec?.ports) return [];

  return service.spec.ports.map(({ port, name, protocol = "" }) => ({
    port,
    name,
    protocol,
  }));
};

export const getAvailablePortsFromResource = (
  resourceType: string,
  resource: unknown
): AvailablePort[] => {
  switch (resourceType) {
    case SupportedResourceKindEnum.Pod:
      return getPortsFromPod(resource as Pod);

    case SupportedResourceKindEnum.Service:
      return getPortsFromService(resource as Service);

    default:
      return [];
  }
};
