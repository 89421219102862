import React, { useState, MouseEvent, useMemo, useCallback } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Delete from "@mui/icons-material/DeleteOutline";
import { muiColors } from "@komodorio/design-system";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useFormContext } from "react-hook-form";

import { getCheckLabel } from "../../../../../utils/getCheckLabel";
import { AnimatedKeyboardArrowDown } from "../../../../../ReliabilityStyles";
import { useGetImpactGroupByCheckType } from "../hooks/useGetImpactGroupByCheckType";
import { checkTypeToConfigurationDescription } from "../policyDrawerConstants";
import { impactGroupIdentifierToText } from "../../../../../constants/impactGroupIdentifierToText";
import { useDeletePolicyConfiguration } from "../hooks/useDeletePolicyConfiguration";
import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";

import { ConfigurationControls } from "./ConfigurationControls";

import { CheckType, ImpactGroupIdentifier } from "@/generated/reliabilityApi";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useIsEditDefaultPolicyMode } from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerConfigurations/useIsEditDefaultPolicyMode";
import { getTableStyle } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getTableStyle";
import { CheckEnabledSwitch } from "@/components/reliability/components/pages/policies/PolicyDrawer/PolicyDrawerConfigurations/CheckEnabledSwitch";
import { getCheckTypeEnabledField } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getCheckTypeEnabledField";

const {
  policies: {
    policiesDrawer: {
      configurationItem: {
        container: configurationItemContainerAriaLabel,
        deleteButton: deleteButtonAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

const TopPartContainer = styled.div<{
  $isDynamic: boolean;
  $showEnabled?: boolean;
}>`
  && {
    align-items: center;
    cursor: pointer;
    width: 100%;
    ${({ $isDynamic, $showEnabled }) => getTableStyle($isDynamic, $showEnabled)}
  }
`;

const StyledDeleteIcon = styled(Delete)`
  && {
    color: ${muiColors.gray[500]};
  }
`;

const AnimatedArrow = styled(AnimatedKeyboardArrowDown)`
  && {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const getStyledFilter = (
  $showAsDisabled: boolean,
  $isContentDisabled: boolean
) => {
  if ($showAsDisabled) {
    return "brightness(1.2) saturate(0.5) opacity(0.7);";
  }
  if ($isContentDisabled) {
    return "grayscale(1)";
  }
  return "none";
};

const StyledAccordion = styled(Accordion)<{
  $disableMousePointer: boolean;
  $isContentDisabled: boolean;
  $showAsDisabled: boolean;
}>`
  &&& {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    background-color: ${({ $showAsDisabled }) =>
      $showAsDisabled && muiColors.gray[300]};
    transition: filter 0.3s;
    filter: ${({ $showAsDisabled, $isContentDisabled }) =>
      getStyledFilter($showAsDisabled, $isContentDisabled)};
    .MuiAccordionSummary-root {
      pointer-events: ${({ $disableMousePointer }) =>
        $disableMousePointer ? "none" : "auto"};
    }
  }
`;

const ContentContainer = styled.div`
  padding: 8px 0;
  margin-left: 40px;
`;

type ConfigurationItemProps = {
  checkType: CheckType;
};

export const ConfigurationItem: React.FC<ConfigurationItemProps> = ({
  checkType,
}) => {
  const itemLabel = getCheckLabel(checkType);
  const getImpactGroup = useGetImpactGroupByCheckType();
  const deletePolicyConfiguration = useDeletePolicyConfiguration();
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();
  const { watch } = useFormContext();
  const isDynamicChecksTab = useIsDynamicChecksTab();
  const isEditDefaultPolicyMode = useIsEditDefaultPolicyMode();
  const canDeleteConfiguration = !isEditDefaultPolicyMode;
  const showEnabledSwitch = isEditDefaultPolicyMode;
  const isCheckTypeEnabled = !!watch(getCheckTypeEnabledField(checkType));
  const initialExpandedState = useMemo(() => {
    if (!isDynamicChecksTab) return false;
    return isCheckTypeEnabled;
  }, [isCheckTypeEnabled, isDynamicChecksTab]);
  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const impactGroupTitle = useMemo(() => {
    const impactGroup = getImpactGroup(checkType);
    return (
      impactGroupIdentifierToText[impactGroup as ImpactGroupIdentifier]
        ?.title ?? ""
    );
  }, [checkType, getImpactGroup]);

  const { title, subTitle } = checkTypeToConfigurationDescription[checkType];

  const onRowClick = (e: MouseEvent) => {
    setIsExpanded((prev) => !prev);
  };

  const onDeleteClick = (e: MouseEvent) => {
    e.stopPropagation();
    deletePolicyConfiguration(checkType);
  };

  const enableDelete = !isSubmittingForm && !isReadOnlyMode;

  const onEnabledSwitchChange = useCallback(
    (enabled: boolean) => {
      if (enabled && !isExpanded) {
        setIsExpanded(true);
      }
    },
    [isExpanded]
  );

  const enabledControl = useMemo(() => {
    if (!showEnabledSwitch) return null;
    return (
      <CheckEnabledSwitch
        checkType={checkType}
        onChange={onEnabledSwitchChange}
      />
    );
  }, [checkType, onEnabledSwitchChange, showEnabledSwitch]);

  const showAsDisabled = isReadOnlyMode && !isCheckTypeEnabled;

  return (
    <StyledAccordion
      id={`configuration-item-${checkType}`}
      aria-label={`${configurationItemContainerAriaLabel}-${checkType}`}
      expanded={isDynamicChecksTab ? isExpanded : false}
      $disableMousePointer={isReadOnlyMode && !isCheckTypeEnabled}
      $isContentDisabled={!isCheckTypeEnabled}
      $showAsDisabled={showAsDisabled}
    >
      <AccordionSummary>
        <TopPartContainer
          $isDynamic={isDynamicChecksTab}
          $showEnabled={isEditDefaultPolicyMode}
          onClick={onRowClick}
        >
          {isDynamicChecksTab && (
            <AnimatedArrow open={isExpanded} color="action" />
          )}
          {!isDynamicChecksTab && isEditDefaultPolicyMode && <div />}
          <Typography variant={"body2"}>{itemLabel}</Typography>
          {enabledControl}
          <Typography variant={"body2"}>{impactGroupTitle}</Typography>
          {canDeleteConfiguration && (
            <IconButton
              onClick={onDeleteClick}
              disabled={!enableDelete}
              sx={{ opacity: enableDelete ? 1 : 0.3 }}
              aria-label={deleteButtonAriaLabel}
            >
              <StyledDeleteIcon fontSize={"medium"} />
            </IconButton>
          )}
        </TopPartContainer>
      </AccordionSummary>
      <AccordionDetails>
        <ContentContainer>
          <Typography variant={"body2"}>{title}</Typography>
          <Typography variant={"body3"} color={"text.secondary"}>
            {subTitle}
          </Typography>
          <ConfigurationControls
            checkType={checkType}
            disabled={!isCheckTypeEnabled}
          />
        </ContentContainer>
      </AccordionDetails>
    </StyledAccordion>
  );
};
