import {
  useMutation,
  useQuery,
  QueryObserverOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useReliabilityApiClient } from "../apiClient";

import {
  BasePolicyResponse,
  createPolicyUrl,
  DeletePolicyResponse,
  deletePolicyUrl,
  GetAllPoliciesResponse,
  getAllPoliciesUrl,
  GetPolicyResponse,
  getPolicyUrl,
  PoliciesApiCreatePolicyRequest,
  PoliciesApiDeletePolicyRequest,
  PoliciesApiGetPolicyRequest,
  PoliciesApiUpdatePolicyFieldsRequest,
  PoliciesApiUpdatePolicyRequest,
  updatePolicyFieldsUrl,
  updatePolicyUrl,
} from "@/generated/reliabilityApi";

export const RELIABILITY_POLICIES_PATH = "/api/v1/policies";
export const RELIABILITY_POLICIES_BY_ID_PATH = "/api/v1/policies/:id";

export const useGetAllPolicies = () => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [RELIABILITY_POLICIES_PATH],
    async () =>
      await apiClient.get<GetAllPoliciesResponse>(
        getAllPoliciesUrl(apiClient.defaults.baseURL ?? "")
      )
  );
};

export type QueryParams = Pick<
  QueryObserverOptions,
  "enabled" | "staleTime" | "cacheTime"
>;

export const useGetPolicyData = (
  params: PoliciesApiGetPolicyRequest,
  options?: QueryParams
): UseQueryResult<AxiosResponse<GetPolicyResponse>> => {
  const apiClient = useReliabilityApiClient();
  return useQuery(
    [RELIABILITY_POLICIES_PATH, params],
    async () =>
      await apiClient.get<GetPolicyResponse>(
        getPolicyUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    options
  );
};

export const useInsertPolicy = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [RELIABILITY_POLICIES_PATH],
    async (params: PoliciesApiCreatePolicyRequest) =>
      await apiClient.post<BasePolicyResponse>(
        createPolicyUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.createOrUpdatePolicyRequest.policy }
      )
  );
};

export const useUpdatePolicy = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [RELIABILITY_POLICIES_BY_ID_PATH],
    async (params: PoliciesApiUpdatePolicyRequest) =>
      await apiClient.put<BasePolicyResponse>(
        updatePolicyUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.createOrUpdatePolicyRequest.policy }
      )
  );
};

export const usePatchPolicy = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [RELIABILITY_POLICIES_BY_ID_PATH],
    async (params: PoliciesApiUpdatePolicyFieldsRequest) =>
      await apiClient.patch<BasePolicyResponse>(
        updatePolicyFieldsUrl(params, apiClient.defaults.baseURL ?? ""),
        { ...params.updatePolicyFieldsRequest.policy }
      )
  );
};

export const useDeletePolicy = () => {
  const apiClient = useReliabilityApiClient();
  return useMutation(
    [RELIABILITY_POLICIES_PATH],
    async (params: PoliciesApiDeletePolicyRequest) =>
      await apiClient.delete<DeletePolicyResponse>(
        deletePolicyUrl(params, apiClient.defaults.baseURL ?? "")
      )
  );
};
