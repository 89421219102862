import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MuiSelect from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";
import { muiColors, palette } from "@komodorio/design-system";
import { Input, Skeleton, Divider } from "@komodorio/design-system/deprecated";
import { MagnifyingGlass16 } from "@komodorio/design-system/icons";
import { formatDistanceToNow } from "date-fns";
import { useDebouncedCallback } from "use-debounce";

import { Header } from "../common/Header/Header";
import { Label } from "../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoWorkflowTemplate, {
  ARGO_WORKFLOW_TEMPLATE_KIND,
} from "../resources/argoWorkflowTemplate";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoClusterWorkflowTemplate, {
  ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND,
} from "../resources/argoClusterWorkflowTemplate";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoCronWorkflow, {
  ARGO_CRON_WORKFLOW_KIND,
} from "../resources/argoCronWorkflow";
import {
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
  WORKFLOW_RUN_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { ArgoStatus } from "../../argoWorkflowsView/ArgoStatus";
import { ArgoWorkflowParsedData } from "../../argoWorkflowsView/types";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { ARGO_WORKFLOW_KIND } from "../resources/argoWorkflow";
import { ArgoWorkflowsGroups } from "../../argoWorkflowsView/groupWorkflowsByOwner";
import { shortenString } from "../../../shared/utils/textUtils";
import { useArgoWorkflowsFromAtm } from "../../argoWorkflowsView/useArgoWorkflowsFromAtm";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useClearSearchParams } from "@/shared/hooks/state/useClearSearchParams";

interface ArgoWorkflowOwnerHeaderProps {
  resource:
    | ArgoWorkflowTemplate
    | ArgoClusterWorkflowTemplate
    | ArgoCronWorkflow;
  labels: Label[];
  leftActions?: JSX.Element;
}

export const ArgoWorkflowOwnerHeader: React.FC<
  ArgoWorkflowOwnerHeaderProps
> = ({ resource, labels, leftActions }) => {
  return (
    <>
      <Header
        status={undefined}
        resource={resource}
        labels={labels}
        leftActions={leftActions}
      />
      <ArgoWorkflowRunSwitcher resource={resource} />
    </>
  );
};

const SELECTOR_WIDTH = "25rem";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding-block: 1rem;
`;
const SelectorContainer = styled.div`
  display: grid;
  width: ${SELECTOR_WIDTH};
`;
const ArgoWorkflowRunMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  padding: 0.25rem 0.5rem;
`;
const ArgoWorkflowRunStatusRow = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

const PlaceholderOption = styled.div`
  padding-block: 0.5rem;
  text-align: center;
`;

type ArgoWorkflowOwnerKinds =
  | ArgoWorkflowTemplate
  | ArgoClusterWorkflowTemplate
  | ArgoCronWorkflow;

const createWorkflowRunsOptions = (
  argoWorkflowsGroups: ArgoWorkflowsGroups,
  resource: ArgoWorkflowOwnerKinds
) => {
  let runs: ArgoWorkflowParsedData[] = [];
  switch (resource.kind) {
    case ARGO_CRON_WORKFLOW_KIND:
      runs =
        argoWorkflowsGroups.cronWorkflows[resource.namespace]?.[
          resource.name
        ] ?? [];
      break;
    case ARGO_WORKFLOW_TEMPLATE_KIND:
      runs =
        argoWorkflowsGroups.workflowTemplates[resource.namespace]?.[
          resource.name
        ] ?? [];
      break;
    case ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND:
      runs = argoWorkflowsGroups.clusterWorkflowTemplates[resource.name] ?? [];
      break;
  }

  return runs.map((run) => ({
    label: buildKomodorUid({
      clusterName: resource.cluster,
      namespace: run.namespace,
      resourceName: run.name,
      kind: ARGO_WORKFLOW_KIND,
    }),
    value: run,
  }));
};

const ArgoWorkflowRunSwitcher: React.FC<{
  resource: ArgoWorkflowOwnerKinds;
}> = ({ resource }) => {
  const [selectedRunId, setSelectedRunId] = useStateInSearchParams(
    WORKFLOW_RUN_PARAM_KEY
  );
  const [search, setSearch] = useState("");
  const { callback: debouncedSetSearch } = useDebouncedCallback(
    (searchTerm: string) => setSearch(searchTerm),
    150
  );

  const { argoWorkflowsGroups, fetching } = useArgoWorkflowsFromAtm(
    resource.cluster
  );

  const workflowRuns = useMemo(
    () => createWorkflowRunsOptions(argoWorkflowsGroups, resource),
    [argoWorkflowsGroups, resource]
  );

  const filteredWorkflowRuns = useMemo(
    () => workflowRuns.filter(({ value }) => value.name.includes(search)),
    [search, workflowRuns]
  );

  useEffect(() => {
    if (
      workflowRuns.length &&
      (!selectedRunId ||
        !workflowRuns.find((run) => run.label === selectedRunId))
    ) {
      setSelectedRunId(workflowRuns[0].label, true);
    }
  }, [selectedRunId, setSelectedRunId, workflowRuns]);

  const selectedRunOption = useMemo(
    () =>
      workflowRuns.find((run) => run.label === selectedRunId) ??
      workflowRuns[0],
    [selectedRunId, workflowRuns]
  );

  const clearSearchParams = useClearSearchParams();
  const handleSelectorChange = (event: SelectChangeEvent<string>) => {
    clearSearchParams([EVENTS_TAB_TIME_WINDOW, EVENTS_TAB_TIMEFRAME]);
    setSelectedRunId(event.target.value);
  };

  return (
    <Container>
      <Typography variant={"body2"}>Showing run:</Typography>
      {fetching ? (
        <Skeleton width={SELECTOR_WIDTH} height="4.4rem" />
      ) : !workflowRuns.length || !selectedRunOption ? null : (
        <>
          <SelectorContainer>
            <MuiSelect
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
                PaperProps: {
                  style: {
                    maxHeight: "30rem",
                    minWidth: SELECTOR_WIDTH,
                  },
                },
              }}
              size={"small"}
              onChange={handleSelectorChange}
              value={selectedRunOption.label}
              renderValue={() => (
                <ArgoWorkflowRunStatus
                  value={selectedRunOption.value}
                  isSelected
                />
              )}
              maxRows={10}
              autoWidth
            >
              <Input
                placeholder="Search"
                icon={MagnifyingGlass16}
                size="medium"
                noBorder
                autoFocus
                value={search}
                onChange={(e) => debouncedSetSearch(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Divider />
              {filteredWorkflowRuns.length === 0 ? (
                <PlaceholderOption>
                  <Typography variant={"caption"} color={muiColors.gray[500]}>
                    No runs found
                  </Typography>
                </PlaceholderOption>
              ) : (
                filteredWorkflowRuns.map((option) => (
                  <MenuItem key={option.label} value={option.label} divider>
                    <ArgoWorkflowRunStatus value={option.value} />
                  </MenuItem>
                ))
              )}
            </MuiSelect>
          </SelectorContainer>
        </>
      )}
    </Container>
  );
};

const ArgoWorkflowRunStatus: React.FC<{
  value: ArgoWorkflowParsedData;
  isSelected?: boolean;
}> = ({ value, isSelected }) => {
  return (
    <ArgoWorkflowRunMenuItem>
      <ArgoWorkflowRunStatusRow>
        <Typography variant={"body2"} color={palette.black[0]}>
          {isSelected ? shortenString(value.name ?? "", 35, 5) : value.name}
        </Typography>
        <ArgoStatus status={value.status} size="small" />
      </ArgoWorkflowRunStatusRow>
      <Typography variant={"caption"} color={muiColors.gray[500]}>
        <ArgoWorkflowRunStatusRow>
          <span>Duration: {value.duration}</span>
          {value.startedAt ? (
            <>
              <span>•</span>
              <span>
                {formatDistanceToNow(value.startedAt, {
                  addSuffix: true,
                })}
              </span>
            </>
          ) : null}
        </ArgoWorkflowRunStatusRow>
      </Typography>
    </ArgoWorkflowRunMenuItem>
  );
};
