import { Limits } from "@komodorio/design-system/icons";

export const KubernetesLimitRangeResource = {
  NameInK8S: "limitranges",
  Group: "configuration",
  NavBarName: "Limit Ranges",
  PresentName: "Limit Ranges",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "LimitRange",
  Icon: Limits,
  hasLabels: true,
};
