import {
  Filters,
  JobsFilterCategory,
  ServicesFilterCategory,
} from "komodor-types";
import _ from "lodash";

import { FilterBarCategory } from "../../../shared/components/FilterBar/FilterBar";
import Selected from "../../../shared/components/FilterBar/Interfaces/Selected";

export type CheckedFilters = {
  [x: string]: string[];
};

export const asStringArrays = (o: Selected): CheckedFilters => {
  return _.mapValues(o, (values) => Object.keys(values));
};

export const calcServicesLength = (
  filters: Filters,
  checkedFilters: CheckedFilters
): number => {
  const clusterCategory = filters[ServicesFilterCategory.Cluster];
  const checkedClusters = checkedFilters[ServicesFilterCategory.Cluster];

  let length = 0;
  if (checkedClusters?.length) {
    checkedClusters.forEach((cluster) => {
      length += clusterCategory[cluster] ?? 0;
    });
  } else {
    Object.keys(clusterCategory).forEach((cluster) => {
      length += clusterCategory[cluster] ?? 0;
    });
  }
  return length;
};

const servicesCategoriesSortingOrder: Map<string, number> = new Map([
  [ServicesFilterCategory.Cluster, 0],
  [ServicesFilterCategory.Namespace, 1],
  [ServicesFilterCategory.Kind, 2],
  [ServicesFilterCategory.Health, 3],
]);
const jobsCategoriesSortingOrder: Map<string, number> = new Map([
  [JobsFilterCategory.Cluster, 0],
  [JobsFilterCategory.Namespace, 1],
  [JobsFilterCategory.Kind, 2],
  [JobsFilterCategory.Status, 3],
]);
const sortCategories = (categories: FilterBarCategory[]) => {
  const [mappedElements, otherElements] = _.partition(
    categories,
    ({ name }) =>
      servicesCategoriesSortingOrder.has(name) ||
      jobsCategoriesSortingOrder.has(name)
  );
  return _.concat(
    _.orderBy(
      mappedElements,
      ({ name }) =>
        servicesCategoriesSortingOrder.get(name) ??
        jobsCategoriesSortingOrder.get(name)
    ),
    _.orderBy(otherElements, ({ name }) => name)
  );
};
export const createFilterBarCategories = (
  filters: Filters,
  checkedFilters: CheckedFilters
): FilterBarCategory[] | undefined => {
  if (!filters) return;
  return sortCategories(
    Object.entries(filters)
      .map(([category, values]) => ({
        name: category,
        values: Object.entries(values)
          .filter(([value]) => value && value !== "null")
          .map(([value, count]) => ({
            value,
            count,
            checked: checkedFilters?.[category]?.includes(value) ?? false,
            onChange: () => null,
          })),
      }))
      .filter(({ values }) => values.length > 0)
  );
};
