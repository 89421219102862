import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { isEqual } from "lodash";

import {
  ConfigFormValidationState,
  CostOptimizationConfig,
} from "../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../store/costOptimizationStore";
import { selectConfigState } from "../../store/costOptimizationStoreSelectors";
import { initialSettingsConfiguration } from "../../store/initialState";
import { CenteredLoader } from "../../../../shared/components/CenteredLoader";
import { FetchError } from "../shared/errors/FetchError";

import { ALL_CLUSTER_NAMES_SELECTOR, inputFieldsOrder } from "./constants";
import { SettingField } from "./SettingField";
import { SettingsFooter } from "./SettingsFooter";
import {
  useCloseConfigModalOnUpdateEffect,
  useUpdateFormStateFromFetchedConfig,
  useUpdateSettingsConfigData,
} from "./costOptimizationSettingsHooks";
import { costOptimizationConfigToFormValidationState } from "./costOptimizationSettingsUtils";

const Content = styled.div`
  border: 1px solid ${muiColors.gray[200]};
  padding: 20px;
  margin: 32px;
  min-width: 600px;
  background-color: white;
  border-bottom: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

const LoaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

type SettingsFormProps = {
  onClose: () => void;
};

export const SettingsForm: React.FC<SettingsFormProps> = ({ onClose }) => {
  const { fetchState, updateState } =
    useCostOptimizationStore(selectConfigState);

  const [formState, setFormState] = useState<CostOptimizationConfig>(
    initialSettingsConfiguration
  );
  const lastSentFormState = useRef<CostOptimizationConfig>(formState);

  const updateInitialState = useCallback((config: CostOptimizationConfig) => {
    lastSentFormState.current = config;
    setFormState(config);
  }, []);

  const [formValidationState, setFormValidationState] =
    useState<ConfigFormValidationState>(
      costOptimizationConfigToFormValidationState(initialSettingsConfiguration)
    );

  useUpdateFormStateFromFetchedConfig(formState, updateInitialState);
  useCloseConfigModalOnUpdateEffect(onClose);

  const updateSettingsParams = useMemo(
    () => ({
      clusterNames: [ALL_CLUSTER_NAMES_SELECTOR],
      configuration: formState,
    }),
    [formState]
  );

  const updateSettingsConfigData =
    useUpdateSettingsConfigData(updateSettingsParams);

  const populateFormValidationState = useCallback((): boolean => {
    let hasErrors = false;
    Object.entries(formState).forEach(([key, value]) => {
      // if (typeof value === "boolean") return; // uncomment when allocateIdleResources is added
      const errorType = isNaN(value)
        ? "empty"
        : value <= 0
        ? "invalid"
        : undefined;
      if (errorType) hasErrors = true;
      setFormValidationState((prevState) => ({
        ...prevState,
        [key]: { error: errorType },
      }));
    });
    return hasErrors;
  }, [formState]);

  const hasFormErrors = useMemo(
    () =>
      Object.values(formValidationState).some((validation) => validation.error),
    [formValidationState]
  );

  useEffect(() => {
    hasFormErrors && populateFormValidationState();
  }, [hasFormErrors, populateFormValidationState]);

  const onFormStateChange = (
    key: keyof CostOptimizationConfig,
    val: number | boolean
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const hasFormContentChanged = useMemo(
    () => !isEqual(formState, lastSentFormState.current),
    [formState]
  );

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasError = populateFormValidationState();
    !hasError && updateSettingsConfigData();
  };

  const settingFields = useMemo(
    () =>
      inputFieldsOrder.map((setting) => (
        <SettingField
          settingsData={formState}
          validationState={formValidationState}
          settingProp={setting}
          key={setting}
          onChange={onFormStateChange}
        />
      )),
    [formState, formValidationState]
  );

  const getContent = () => {
    if (fetchState.loading) {
      return (
        <LoaderContainer>
          <CenteredLoader />
        </LoaderContainer>
      );
    }
    if (fetchState.error) {
      return (
        <ErrorContainer>
          <FetchError />
        </ErrorContainer>
      );
    }
    return settingFields;
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Content>{getContent()}</Content>
      {!fetchState.error && (
        <SettingsFooter
          state={updateState}
          enableSubmit={hasFormContentChanged}
          onCancel={onClose}
        />
      )}
    </form>
  );
};
