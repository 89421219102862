import { KubernetesNodesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";

export const fieldSelectorByResource = (
  resourceKind: string,
  resourceName: string
): string | undefined => {
  if (resourceKind === KubernetesNodesResource.Kind)
    return `spec.nodeName=${resourceName}`;
  else return undefined;
};
