export const initialFetchState = {
  loading: true,
  data: undefined,
  dataRequestParams: "",
  error: null,
};

export const cancelFetchState = {
  ...initialFetchState,
  loading: false,
};
