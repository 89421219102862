import { Static, Record, String } from "runtypes";

import { LocalStorageItem } from "./localStorageSettings";

const storedRedirectUrl = new LocalStorageItem("redirectUrl");

export const RedirectUrl = Record({
  pathname: String,
  search: String,
  hash: String,
});
export type RedirectUrl = Static<typeof RedirectUrl>;

export function storeRedirectUrl(
  redirectUrl: RedirectUrl,
  storedItem: LocalStorageItem = storedRedirectUrl
): void {
  storedItem.set(JSON.stringify(redirectUrl));
}

export function getStoredRedirectUrl(
  storedItem: LocalStorageItem = storedRedirectUrl
): RedirectUrl | undefined {
  const item = storedItem.get();
  if (item) {
    storedItem.remove();
    try {
      return RedirectUrl.check(JSON.parse(item));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  return; //this silences a  TS7030 error :facepalm:
}
