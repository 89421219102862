import pluralize from "pluralize";

import {
  AUDIT,
  CLUSTERS,
  COST_OPTIMIZATION,
  EVENTS,
  INSPECTION,
  INTEGRATIONS,
  JOBS,
  K8S_ADDONS,
  RELIABILITY,
  WORKLOAD_HEALTH,
  INFRA_HEALTH,
  SERVICES,
  SETTINGS,
  USER_SETTINGS,
  WORKSPACE,
  MONITORS,
  DRIFT_ANALYSIS,
} from "@/components/routes/routes";

export const toTitleCase = (str: string) => {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getRouteNameByIndex = (pathSplit: string[], index: number) => {
  if (pathSplit.length > index) {
    return pathSplit[index];
  }
  return "";
};

const OVERVIEW_PAGE_NAME = "Overview";
const WORKSPACE_MANAGEMENT_PAGE_NAME = "Workspace Management";

const UNGROUPED_RESOURCES_ROUTES = [
  "nodes",
  "helm",
  "custom-resources",
  "namespaces",
];
const ABBREVIATION_RESOURCES_ROUTES = ["pvc", "pv", "hpa", "pdb"];

const getRouteByPathname = (pathname: string) => {
  const pathSplit = pathname.split("/");
  return getRouteNameByIndex(pathSplit, 1) === "main"
    ? getRouteNameByIndex(pathSplit, 2)
    : getRouteNameByIndex(pathSplit, 1);
};

const ROUTES_WITH_WORKSPACE_SELECTOR = [
  INSPECTION,
  "deep-dive", //should be removed once deep-dive route is entirely removed
  SERVICES,
  JOBS,
  DRIFT_ANALYSIS,
  EVENTS,
  COST_OPTIMIZATION,
  RELIABILITY,
  WORKLOAD_HEALTH,
  INFRA_HEALTH,
  CLUSTERS,
  K8S_ADDONS,
  WORKSPACE, // Pay attention: routes with workspace that end with "new" or "edit" are not included here
];

export const isWorkspaceManagementRoute = (pathname: string) => {
  const route = getRouteByPathname(pathname);
  if (route !== WORKSPACE) {
    return false;
  }

  return pathname.endsWith("new") || pathname.endsWith("edit");
};

export const shouldShowWorkspaceSelector = (pathname: string) => {
  const route = getRouteByPathname(pathname);
  if (isWorkspaceManagementRoute(pathname)) {
    return false;
  }

  return ROUTES_WITH_WORKSPACE_SELECTOR.includes(route);
};

export const getBreadcrumbs = (pathname: string) => {
  const pathSplit = pathname.split("/");
  const route = getRouteByPathname(pathname);
  switch (route) {
    case INSPECTION: {
      const groupRoute = getRouteNameByIndex(pathSplit, 3);
      const resourceRoute = getRouteNameByIndex(pathSplit, 4);
      if (UNGROUPED_RESOURCES_ROUTES.includes(groupRoute)) {
        return [toTitleCase(groupRoute)];
      }
      return [
        toTitleCase(groupRoute),
        ABBREVIATION_RESOURCES_ROUTES.includes(resourceRoute)
          ? resourceRoute.toUpperCase() + "s"
          : pluralize(toTitleCase(resourceRoute)),
      ];
    }
    case K8S_ADDONS: {
      const resourceRoute = getRouteNameByIndex(pathSplit, 2);
      return [toTitleCase(resourceRoute)];
    }
    case WORKSPACE:
      if (pathname.endsWith("new") || pathname.endsWith("edit")) {
        return [WORKSPACE_MANAGEMENT_PAGE_NAME];
      }
      return [OVERVIEW_PAGE_NAME];
    case "deep-dive": //should be removed once deep-dive route is entirely removed
      return ["Services"];
    case SETTINGS:
    case INTEGRATIONS:
    case AUDIT:
    case MONITORS:
      return ["Organization Settings"];
    case USER_SETTINGS:
      return ["User Settings"];
    default:
      return [toTitleCase(route)];
  }
};
