import { ArgoWorkflowsGroups } from "../../../components/argoWorkflowsView/groupWorkflowsByOwner";

import { ArgoWorkflowsStore } from "./argoWorkflowsStore";

// getters
export const argoWorkflowsGroupsSelector = (
  state: ArgoWorkflowsStore
): ArgoWorkflowsGroups | undefined => state.argoWorkflowsGroups;

// setters
export const setArgoWorkflowsGroupsSelector = (
  state: ArgoWorkflowsStore
): ((groups: ArgoWorkflowsGroups | undefined) => void) =>
  state.setArgoWorkflowsGroups;
