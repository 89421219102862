import { TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = () => (
  <TextContainer>
    The service was identified as under-provisioned, the actual usage surpasses
    the allocated resources (requests).
  </TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Under-provisioning can lead to system instability, unexpected load, crashes,
    downtime, or other reliability issues. <br />
    To mitigate those risks, it’s essential to regularly monitor and adjust
    resources allocation based on the ever evolving requirements of the
    workload. Employing auto-scaling mechanisms, capacity planning, and
    performance testing can help with preventing or addressing
    under-provisioning issues.
  </TextContainer>
);

export const getHowDescription = () => (
  <TextContainer>Increase resources requests.</TextContainer>
);
