import { EnrichedMessage, K8sResourceRegex } from "../types";
import { StringToken } from "../tokens";

import { BaseEnricher } from "./baseEnricher";

export class ErrorLookingServiceAccount extends BaseEnricher {
  constructor() {
    super(
      new RegExp(
        `pods (.*) error looking up service account ${K8sResourceRegex}: .*`,
        "i"
      )
    );
  }

  searchKey(): string {
    return "pods";
  }

  enrich(errorMessage: string): EnrichedMessage | null {
    const match = this.regex().exec(errorMessage);
    if (!match) {
      return null;
    }
    return {
      hasPrecedenceOverAIInvestigation: true,
      originalMessage: errorMessage,
      messageTokens: [
        new StringToken(
          "One or more pods cannot run, because they require a service account which is missing or not available in the cluster"
        ),
      ],
    };
  }
}
