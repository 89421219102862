import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { InputFields } from "../policyDrawerTypes";

export const useTriggerAllFields = () => {
  const { trigger, getValues } = useFormContext<InputFields>();

  return useCallback(async () => {
    const values = getValues();
    const keys = Object.keys(values);
    await trigger(keys as (keyof InputFields)[]);
  }, [getValues, trigger]);
};
