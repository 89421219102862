import React, { Fragment, useMemo } from "react";
import { StatementResource } from "komodor-types";
import { theme } from "@komodorio/design-system";
import {
  IconButton,
  Input,
  MultiSelect,
  OptionType,
  Select,
} from "@komodorio/design-system/deprecated";
import { Plus16, Trash16 } from "@komodorio/design-system/icons";

import { Z_INDEX_ABOVE_MODAL } from "../../styles";
import { RbacPolicyType } from "../../../../generated/auth";
import { Validations } from "../../../../components/Settings/Users/types";

import { Field, ResourceActions } from "./common";

const ResourcesFields: React.FC<{
  resources: StatementResource[];
  clustersOptions: OptionType<string>[];
  namespacesOptions: (cluster: string) => OptionType<string>[];
  index: number;
  errors: Validations;
  isEditDisabled?: boolean;
  handleClusterChange: (cluster: string, resourceIndex: number) => void;
  handleNamespacesChange: (namespaces: string[], resourceIndex: number) => void;
  handleNamespacePatternChange: (
    namespacePattern: string,
    resourceIndex: number
  ) => void;
  handleAddResource: () => void;
  handleDeleteResource: (resourceIndex: number) => void;
  policyType: RbacPolicyType;
}> = ({
  resources,
  clustersOptions,
  namespacesOptions,
  index,
  errors,
  isEditDisabled,
  handleClusterChange,
  handleNamespacesChange,
  handleNamespacePatternChange,
  handleAddResource,
  handleDeleteResource,
  policyType,
}) => {
  const fields = useMemo(() => {
    const getClusterValue = (cluster: string) => {
      if (!cluster) return undefined;
      return (
        clustersOptions.find(
          (clusterOption) => cluster === clusterOption.value
        ) ?? {
          label: cluster,
          value: cluster,
        }
      );
    };
    return resources.map(({ cluster, namespaces, namespacePattern }, i) => (
      <Fragment key={i}>
        <Select
          width="12rem"
          listMaxHeight="14rem"
          listZIndex={Z_INDEX_ABOVE_MODAL}
          options={clustersOptions}
          label="Cluster"
          placeholder="Add cluster"
          value={getClusterValue(cluster)}
          onChange={(s) => handleClusterChange(s.value, i)}
          errorMessage={errors[`instance[${index}].resources[${i}].cluster`]}
          disabled={isEditDisabled}
        />
        {policyType === RbacPolicyType.Wildcard ? (
          <Input
            width="19.5rem"
            label="Namespace(s) pattern"
            placeholder="Pattern"
            value={namespacePattern}
            onChange={({ target }) => {
              handleNamespacePatternChange(target.value, i);
            }}
            errorMessage={
              errors[`instance[${index}].resources[${i}].namespacePattern`]
            }
          ></Input>
        ) : (
          <MultiSelect
            width="19.5rem"
            listMaxHeight="14rem"
            listZIndex={Z_INDEX_ABOVE_MODAL}
            tagsColor={theme.background.bgBlueLight}
            enableDeleteTags
            options={namespacesOptions(cluster)}
            label="Namespace(s)"
            placeholder="Add namespaces"
            value={namespaces?.map((n) => ({ label: n, value: n }))}
            onChange={(s) =>
              handleNamespacesChange(
                s.map(({ value }) => value),
                i
              )
            }
            disabled={isEditDisabled}
          />
        )}
        <ResourceActions>
          {resources.length !== 1 && (
            <IconButton
              icon={Trash16}
              noBorder
              onClick={() => handleDeleteResource(i)}
              disabled={isEditDisabled}
            />
          )}
          {resources.length - 1 === i && (
            <IconButton
              icon={Plus16}
              noBorder
              onClick={() => handleAddResource()}
              disabled={isEditDisabled}
            />
          )}
        </ResourceActions>
      </Fragment>
    ));
  }, [
    resources,
    clustersOptions,
    namespacesOptions,
    index,
    errors,
    isEditDisabled,
    handleClusterChange,
    handleNamespacesChange,
    handleNamespacePatternChange,
    handleAddResource,
    handleDeleteResource,
    policyType,
  ]);

  return <Field>{fields}</Field>;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ResourcesFields;
