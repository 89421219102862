import { useEffect, useState } from "react";

export const useRowsCount = (totalResults: number | undefined) => {
  const [rowCountState, setRowCountState] = useState(totalResults || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalResults !== undefined ? totalResults : prevRowCountState
    );
  }, [setRowCountState, totalResults]);

  return rowCountState;
};
