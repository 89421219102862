import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { ArgoWorkflowsGroups } from "../../../components/argoWorkflowsView/groupWorkflowsByOwner";

export type ArgoWorkflowsState = {
  argoWorkflowsGroups: ArgoWorkflowsGroups | undefined;
};

export type ArgoWorkflowsActions = {
  setArgoWorkflowsGroups: (groups: ArgoWorkflowsGroups | undefined) => void;
};

export type ArgoWorkflowsStore = ArgoWorkflowsState & ArgoWorkflowsActions;

export const initialState: ArgoWorkflowsState = {
  argoWorkflowsGroups: undefined,
};

export const useArgoWorkflowsStore = create(
  immer<ArgoWorkflowsStore>((set, get) => ({
    ...initialState,
    setArgoWorkflowsGroups: (groups: ArgoWorkflowsGroups | undefined) => {
      set({ argoWorkflowsGroups: groups });
    },
  }))
);

export const resetArgoWorkflowsStore = (): void => {
  useArgoWorkflowsStore.setState({
    ...initialState,
  });
};
