import React from "react";
import styled, { keyframes } from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

const Container = styled.div`
  height: 100vh;
  background-color: ${theme.background.bgGrayLight};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CircularAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const spinnerColor = palette.darkBlue[500];

export const SpinnerLoading = styled.div<{
  width?: string;
  height?: string;
  color?: string;
}>`
  display: inline-block;
  position: relative;
  width: ${({ width }) => (width ? width : "80px")};
  height: ${({ height }) => (height ? height : "80px")};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ width }) => (width ? width : "64px")};
    height: ${({ height }) => (height ? height : "64px")};
    border: 3px solid ${spinnerColor};
    border-radius: 50%;
    animation-name: ${CircularAnimation};
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    border-color: ${({ color }) =>
      `${color ? color : spinnerColor} transparent transparent transparent`};
  }
  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  ,
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  ,
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const SignupLoader: React.FC = () => {
  return (
    <Container>
      <SpinnerLoading>
        <div />
        <div />
        <div />
        <div />
      </SpinnerLoading>
      <Typography variant="headline" size="medium" color={palette.gray[800]}>
        Setting up Komodor
      </Typography>
      <Typography variant="headline" color={palette.gray[600]}>
        This can take a few minutes
      </Typography>
    </Container>
  );
};
