import pluralize from "pluralize";

import { INVALID_TIME, now } from "@/components/k8sAddons/constants/time";
import { ONE_DAY_IN_MS } from "@/shared/constants/time";
import { getStartOfDayInUtc } from "@/components/k8sAddons/utils/timeUtils";

export const getTimeElapsedStr = (targetDateString: string) => {
  const dateStrAsStartOfDay = getStartOfDayInUtc(
    new Date(targetDateString)
  ).getTime();

  const startOfDayInUtc = getStartOfDayInUtc(new Date(now)).getTime();

  if (dateStrAsStartOfDay.toString() === "NaN") {
    return {
      outputStr: "N/A",
      daysElapsed: INVALID_TIME,
      timeElapsedInMs: INVALID_TIME,
    };
  }

  const timeElapsedInMs = dateStrAsStartOfDay - startOfDayInUtc;
  const daysElapsed = Math.floor(timeElapsedInMs / ONE_DAY_IN_MS);
  let outputStr = "";

  if (daysElapsed === 0) {
    outputStr = `Today`;
  } else if (daysElapsed > 0) {
    outputStr = `${daysElapsed}d`;
  } else {
    const daysElapsedAbs = Math.abs(daysElapsed);
    outputStr = `${daysElapsedAbs} ${pluralize("day", daysElapsedAbs)} ago`;
  }

  return { outputStr, daysElapsed, timeElapsedInMs };
};
