import {
  CorrelatedDeploysOutput,
  RelatedServicesDeploysOutput,
} from "./CorrelatedDeploys/types";
import { IntroductionOutput } from "./Introduction/types";
import { LogsOutput } from "./Logs/types";
import { NodeIssuesOutput } from "./NodeIssues/types";
import { NoisyNeighborsOutput } from "./NoisyNeighbors/types";
import { UnhealthyPodsOutput } from "./UnhealthyPods/types";
import { LimitDecreasedOutput } from "./LimitDecreased/types";
import { MemoryLeakOutput } from "./MemoryLeak/types";
import { ReviewMemoryAllocationOutput } from "./ReviewMemoryAllocation/types";
import { AiInvestigationOutput } from "./AiInvestigation/types";

export const OOMIssueType = "OOMKilled";
export const EvictedIssueType = "Evicted";
export const GenericIssueType = "Generic";
export const SubReasonContainerLimitReached = "ContainerLimitReached";

export enum AvailabilityInvestigationIssueType {
  Generic,
  Evicted,
  OOMKilled,
  OOMKilledContainerLimit,
}

export enum AvailabilityInvestigationCheckType {
  Introduction = "introduction",
  UnhealthyPods = "unhealthyPods",
  Logs = "logs",
  CorrelatedDeploys = "correlatedDeploys",
  RelatedServicesDeploys = "relatedServicesDeploys",
  NodeIssues = "nodeIssues",
  NoisyNeighbors = "noisyNeighbors",
  LimitDecreased = "limitDecreased",
  MemoryLeak = "memoryLeak",
  ReviewMemoryAllocation = "reviewMemoryAllocation",
  Dependencies = "dependencies",
  KlaudiaSession = "klaudiaSession",
}

export interface AvailabilityInvestigationResult {
  [AvailabilityInvestigationCheckType.Introduction]: {
    type: string;
    output: IntroductionOutput;
  };
  [AvailabilityInvestigationCheckType.UnhealthyPods]: {
    type: string;
    output: UnhealthyPodsOutput;
  };
  [AvailabilityInvestigationCheckType.Logs]: {
    type: string;
    output: LogsOutput;
  };
  [AvailabilityInvestigationCheckType.CorrelatedDeploys]: {
    type: string;
    output: CorrelatedDeploysOutput;
  };
  [AvailabilityInvestigationCheckType.NodeIssues]: {
    type: string;
    output: NodeIssuesOutput;
  };
  [AvailabilityInvestigationCheckType.RelatedServicesDeploys]?: {
    type: string;
    output: RelatedServicesDeploysOutput;
  };
  [AvailabilityInvestigationCheckType.NoisyNeighbors]?: {
    type: string;
    output: NoisyNeighborsOutput;
  };
  [AvailabilityInvestigationCheckType.LimitDecreased]?: {
    type: string;
    output: LimitDecreasedOutput;
  };
  [AvailabilityInvestigationCheckType.MemoryLeak]?: {
    type: string;
    output: MemoryLeakOutput;
  };
  [AvailabilityInvestigationCheckType.ReviewMemoryAllocation]?: {
    type: string;
    output: ReviewMemoryAllocationOutput;
  };
  [AvailabilityInvestigationCheckType.KlaudiaSession]?: {
    type: string;
    output: AiInvestigationOutput;
  };
}
