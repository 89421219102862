import React from "react";

import Resource from "../../../resources";
import { DescribeTextItem } from "../common/DescribeItem";
import { useDrawersStackStore } from "../../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../../shared/store/drawersStackStore/drawersStackSelectors";
import {
  DrawerType,
  UrlStates,
} from "../../../../../shared/store/drawersStackStore/types";

import { OwnerResourcesParams } from "./types";

type DescribeTextItemWithResourceDrawerProps = {
  label: string;
  resource?: Resource;
  ownerMetadata?: OwnerResourcesParams["ownerMetadata"];
  urlStates?: UrlStates;
};
export const DescribeTextItemWithResourceDrawer: React.FC<
  DescribeTextItemWithResourceDrawerProps
> = ({ label, resource, ownerMetadata, urlStates }) => {
  const { name, kind } = resource || ownerMetadata || {};
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onValueClick = resource
    ? () => {
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster: resource.cluster,
          namespace: resource.namespace,
          resourceType: resource.kind,
          resourceName: resource.name,
          urlStates: urlStates,
        });
      }
    : undefined;

  if (!name || !kind) return null;

  return (
    <DescribeTextItem
      name={label}
      value={`${kind}/${name}`}
      onValueClick={onValueClick}
    />
  );
};
