import { ObjectReference } from "kubernetes-types/core/v1.d";
import React, { memo, useCallback } from "react";

import { KubernetesSecretsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ResourceDrawerByData } from "../../ResourceDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ServiceAccount from "../../resources/ServiceAccount";
import {
  DescribeDateItem,
  DescribeTextItem,
} from "../DescribeTab/common/DescribeItem";
import { DescribePanel } from "../DescribeTab/common/DescribeSection";
import { DescribeKeyValueItem } from "../DescribeTab/common/DescribeTagListItem";

import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import { useReferenceResourceDrawerData } from "./utils/useReferenceResourceDrawerData";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

interface ServiceAccountDetailsTabProps {
  resource: ServiceAccount;
}

const MountableSecretsList: React.FC<{
  mountableSecrets?: ObjectReference[];
  onSecretNameClicked: (name: string) => void;
}> = memo(({ mountableSecrets, onSecretNameClicked }) => {
  if (!mountableSecrets || !mountableSecrets.length) return null;

  const numOfMountableSecretsNames = mountableSecrets.filter(({ name }) =>
    Boolean(name)
  ).length;

  if (numOfMountableSecretsNames === 0) return null;

  return (
    <>
      <DescribeSectionDivider title="MOUNTABLE SECRETS" />
      {mountableSecrets.map(({ name, kind }) =>
        name ? (
          <div key={name}>
            <DescribeTextItem
              name="Name"
              value={name}
              onValueClick={() => onSecretNameClicked(name)}
            />

            {kind && <DescribeTextItem name="Type" value={kind} />}
          </div>
        ) : null
      )}
    </>
  );
});

export const ServiceAccountDetailsTab: React.FC<
  ServiceAccountDetailsTabProps
> = ({ resource }) => {
  const {
    openResourceDrawerIfNeeded,
    resourceDrawerData,
    clearResourceDrawerData,
  } = useReferenceResourceDrawerData();
  const serviceAccount = resource.fullObj;
  const { creationTimestamp, name, labels, annotations } =
    serviceAccount.metadata ?? {};

  const onSecretNameClicked = useCallback(
    (name: string) => {
      openResourceDrawerIfNeeded({
        actualResourceKind: KubernetesSecretsResource.Kind,
        resourceName: name,
        targetResourceKind: KubernetesSecretsResource.Kind,
      });
    },
    [openResourceDrawerIfNeeded]
  );

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        <MountableSecretsList
          mountableSecrets={serviceAccount.secrets}
          onSecretNameClicked={onSecretNameClicked}
        />

        {resourceDrawerData && (
          <ResourceDrawerByData
            cluster={resource.cluster}
            namespace=""
            resourceType={resourceDrawerData.resourceType}
            resourceName={resourceDrawerData.resourceName}
            open
            onClose={clearResourceDrawerData}
          />
        )}
      </DescribePanel>
    </DescribeTabContentWrapper>
  );
};
