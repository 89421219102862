import { differenceInSeconds } from "date-fns";

import { SocketState } from "../context/WebSocketsProvider";

import {
  TIMEOUT_TERMINATION_THRESHOLD,
  TIMEOUT_ZOMBIE_THRESHOLD,
} from "./constants/timeout";

export const getSocketState = (lastReceivedAck?: Date): SocketState => {
  const differenceInSecondsRes = differenceInSeconds(
    Date.now(),
    lastReceivedAck || Date.now()
  );

  if (differenceInSecondsRes > TIMEOUT_TERMINATION_THRESHOLD) {
    return SocketState.Died;
  }

  if (differenceInSecondsRes > TIMEOUT_ZOMBIE_THRESHOLD) {
    return SocketState.Zombie;
  }

  return SocketState.Alive;
};
