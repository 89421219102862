import { muiPalette } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 24px;
`;

interface NoChecksToShowPromptProps {
  isFiltered: boolean;
  cb: () => void;
}

export const NoChecksToShowPrompt: React.FC<NoChecksToShowPromptProps> = ({
  isFiltered,
  cb,
}) => {
  return (
    <Container>
      <Typography variant="body2" color={muiPalette.text?.primary}>
        {isFiltered
          ? "All checks of the selected types passed."
          : "All checks passed."}
      </Typography>
      <Button size="large" variant="text" onClick={cb}>
        {isFiltered ? "Show all check types" : "Show passed checks"}
      </Button>
    </Container>
  );
};
