import { useCallback } from "react";

import { useReliabilityStore } from "../store/reliabilityStore";
import { selectSetReliabilityNotification } from "../store/reliabilityStoreSelectors";
import { ReliabilityError } from "../ReliabilityTypes";
import { reliabilityErrors } from "../constants/reliabilityConstants";

import { notifyDDError } from "@/shared/hooks/exceptionManagement";

export const useReportError = () => {
  const setNotification = useReliabilityStore(selectSetReliabilityNotification);

  return useCallback(
    (error: unknown, errorReportKey: ReliabilityError) => {
      const { action, message } = reliabilityErrors[errorReportKey];

      notifyDDError(error as Error, { action });
      setNotification({ message, severity: "error" });
    },
    [setNotification]
  );
};
