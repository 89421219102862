import { Metadata } from "@komodorio/design-system/komodor-ui";
import React from "react";

import { Section, SectionName, FlexRow } from "../styles";
import ResourceNameTile from "../../../../../../../InvestigationMode/IssueSummary/ResourceNameTile";

export const WhereSection: React.FC<{
  resourceName: string;
  resourceKind: string;
  clusterName: string;
  namespace: string;
  onResourceClick?: () => void;
  resourceAriaLabel?: string;
  deletedAt?: Date;
}> = ({
  resourceName,
  resourceKind,
  clusterName,
  namespace,
  onResourceClick,
  resourceAriaLabel,
  deletedAt,
}) => {
  return (
    <Section>
      <SectionName>where</SectionName>
      <FlexRow>
        <ResourceNameTile
          deletedAt={deletedAt}
          resourceName={resourceName}
          resourceKind={resourceKind ?? "deployment"}
          onResourceClick={onResourceClick}
          ariaLabel={resourceAriaLabel}
        />
        <Metadata title="namespace" value={namespace} />
        <Metadata title="cluster" value={clusterName} />
      </FlexRow>
    </Section>
  );
};
