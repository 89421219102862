import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import { TimeStampDataPoint } from "../types/overviewPageTypes";

import { CustomTooltip } from "./CustomTooltip";
import { tickFormatter } from "./utils";
import { useTimestampIndexDictionary } from "./hooks/useTimestampIndexDictionary";
import { DataState } from "./overviewMetricsTypes";

const Container = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
`;

const GraphAbsoluteContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

type OverviewMetricsGraphProps = {
  data: TimeStampDataPoint[];
  xAxisTicks: number[];
  unit: string;
  dataState?: DataState;
};

const collectingDataMessage = () => (
  <text x={"50%"} y={15} fill={muiColors.gray["500"]} textAnchor="middle">
    <tspan fontSize="10" fontFamily={"Roboto"}>
      Data is being collected
    </tspan>
  </text>
);

export const OverviewMetricsGraph: React.FC<OverviewMetricsGraphProps> = ({
  data,
  xAxisTicks,
  unit,
  dataState,
}) => {
  const timestampIndexDictionary = useTimestampIndexDictionary(data);
  if (!data.length) return null;
  const isCollectingData = dataState === "collecting data";

  return (
    <Container>
      <GraphAbsoluteContainer>
        <ResponsiveContainer height="100%" width="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 15,
              left: 15,
              bottom: 0,
            }}
          >
            {isCollectingData ? (
              collectingDataMessage()
            ) : (
              <Tooltip
                wrapperStyle={{ top: 50 }}
                content={(props) => <CustomTooltip data={props} unit={unit} />}
              />
            )}

            <Line
              type="monotone"
              dataKey="value"
              dot={false}
              stroke={muiColors.gray["500"]}
              strokeWidth={1.5}
              isAnimationActive={false}
            />
            <XAxis
              dataKey={"ts"}
              ticks={xAxisTicks}
              tick={{ fontSize: 10, fill: muiColors.gray["500"] }}
              tickFormatter={(tick: string) =>
                tickFormatter({ tick, timestampIndexDictionary })
              }
              stroke={muiColors.gray["300"]}
            />
          </LineChart>
        </ResponsiveContainer>
      </GraphAbsoluteContainer>
    </Container>
  );
};
