import { useMemo } from "react";

import { getAppConfig } from "../../../config/appConfig";
import { useRequestHeaders } from "../../common-api/headers";
import AxiosClient from "../../axiosClient/client";

const getAiAPIBasePath = (): string => {
  return getAppConfig().aiServiceAPIServerURL;
};

export const useAiApiClient = () => {
  const headers = useRequestHeaders();

  return useMemo(() => {
    const client = new AxiosClient("ai-api", getAiAPIBasePath(), headers);
    return client.getInstance();
  }, [headers]);
};
