import { useMemo } from "react";

import { useNodeMetricsTabContext } from "../../context/NodeMetricsTabContext";
import { useReportDDRumMetrics } from "../../../MetricsTab/hooks/datadogRumHooks";
import { dataDogViewNames } from "../../../../../../shared/constants/datadog";

export const useReportTabLoadingTime = (resourceName: string) => {
  const { timeWindow, graphLoadingState } = useNodeMetricsTabContext();
  const params = useMemo(
    () => ({
      timeWindow: timeWindow.timeframe,
      graphLoadingState,
      viewName: dataDogViewNames.getMetricsTabDrawerByResource(
        resourceName,
        timeWindow.timeframe
      ),
    }),
    [graphLoadingState, resourceName, timeWindow]
  );

  useReportDDRumMetrics(params);
};
