import React, { useMemo, useRef } from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

import { IgnoreRule } from "../../../../../../../generated/reliabilityApi";
import { ALL_SELECTOR } from "../../../../../constants/reliabilityConstants";
import { OverflowTextStyle } from "../../../../../../../shared/styles/styles";
import { EllipsisContainerWithTooltip } from "../../../../../../../shared/components/EllipsisContainerWithTooltip";

import { useGetMaxChildrenInContainer } from "./hooks/useGetMaxChildrenInContainer";

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

const HiddenTextContainer = styled.div`
  display: flex;
  position: absolute;
  visibility: hidden;
`;

const TextContainer = styled.div<{ $additionalEntriesNumWidth: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ $additionalEntriesNumWidth }) =>
    $additionalEntriesNumWidth &&
    `> *:first-child {
        max-width: calc(100% - ${$additionalEntriesNumWidth}px);
        ${OverflowTextStyle};
      }
    `}
`;

const AdditionalEntriesText = styled(Typography)`
  && {
    padding-left: 4px;
  }
`;

type IgnoreChecksTextWithTooltipProps = {
  ignoreRule: IgnoreRule;
  scopeType: keyof IgnoreRule["scope"];
};

export const IgnoreChecksTextWithTooltip: React.FC<
  IgnoreChecksTextWithTooltipProps
> = ({ ignoreRule, scopeType }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const hiddenTextContainerRef = useRef<HTMLDivElement>(null);
  const additionalEntriesRef = useRef<HTMLDivElement>(null);
  const maxChildren = useGetMaxChildrenInContainer(
    divRef,
    hiddenTextContainerRef
  );

  const fullData = ignoreRule.scope[scopeType];
  const fullDataAsString = fullData.join(", ");
  const additionalEntries = Math.max(fullData.length - maxChildren - 1, 0);
  const isTruncated = additionalEntries > 0;

  const textToUse = fullData.length ? fullDataAsString : ALL_SELECTOR;
  const additionalEntriesNumWidth =
    additionalEntriesRef.current?.clientWidth || 0;

  const hiddenTextContent = useMemo(() => {
    return fullData.map((rule, idx) => (
      <Typography variant={"body2"} key={idx}>
        {idx < fullData.length - 1 ? `${rule},"` : rule}
      </Typography>
    ));
  }, [fullData]);

  const truncatedTextContent = (
    <Tooltip title={fullData.join(", ")}>
      <TextContainer $additionalEntriesNumWidth={additionalEntriesNumWidth}>
        <Typography variant={"body2"}>{textToUse}</Typography>
        <AdditionalEntriesText variant={"body2"}>
          {`+${additionalEntries}`}
        </AdditionalEntriesText>
      </TextContainer>
    </Tooltip>
  );

  const fullTextContent = (
    <EllipsisContainerWithTooltip tooltipValue={fullDataAsString}>
      {textToUse}
    </EllipsisContainerWithTooltip>
  );

  const textContentToUse = isTruncated ? truncatedTextContent : fullTextContent;

  return (
    <Container ref={divRef}>
      {textContentToUse}
      <HiddenTextContainer ref={hiddenTextContainerRef}>
        {hiddenTextContent}
        <AdditionalEntriesText variant={"body2"} ref={additionalEntriesRef}>
          +99
        </AdditionalEntriesText>
      </HiddenTextContainer>
    </Container>
  );
};
