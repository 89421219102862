import React, { useMemo, useState } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { useSetIsPaginating } from "../hooks/useSetIsPaginating";

import {
  Aggregation,
  GenericFilter,
  GetEntityAggregationsRequest,
} from "@/generated/addonsApi";
import { SeverityColor } from "@/components/k8sAddons/types";
import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";
import { ColoredTypography } from "@/components/k8sAddons/styles";
import { formatNumber } from "@/shared/utils/formatNumber";
import { useMostRecentUpdateRequestParam } from "@/components/k8sAddons/hooks/filters/useMostRecentUpdateRequestParam";
import { useRefetchIntervalFromContext } from "@/components/k8sAddons/hooks/useRefetchIntervalFromContext";
import { useGetLiveStateResetFilters } from "@/components/k8sAddons/hooks/filters/useGetLiveStateResetFilters";
import { k8sAriaLabels } from "@/components/k8sAddons/k8sAriaLabels";
import { useShouldRequestBeEnabled } from "@/components/k8sAddons/hooks/useShouldRequestBeEnabled";

export const DIVIDER_CLASS_NAME = "withDivider";

const ItemContainer = styled.div`
  min-width: 170px;
  padding: 0 16px;
  flex: 1 1 0;

  &.withDivider {
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

const StyledTitle = styled(ColoredTypography)`
  && {
    font-weight: 400;
  }
`;

const CircularProgressContainer = styled.div`
  margin-bottom: 4px;
  margin-top: 6px;
`;

const {
  dataAggregations: { item: aggregationItemAriaLabel },
} = k8sAriaLabels;

export type AggregationsListItemProps = {
  filters?: GenericFilter[];
  filterCategory: string;
  title: string;
  color: SeverityColor;
  aggregationType?: Aggregation;
};

export const AggregationsListItem: React.FC<AggregationsListItemProps> = ({
  filters,
  title,
  color,
  filterCategory,
  aggregationType = Aggregation.Count,
}) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity } = useAddonContext();
  const filtersListFromUrl = useFilterListFromUrlAsGenericFilters();
  const refetchInterval = useRefetchIntervalFromContext(isPaginating);
  const liveStateResetFilters = useGetLiveStateResetFilters();

  const { updateMostRecentDataFrom, getMostRecentDataFromToUse } =
    useMostRecentUpdateRequestParam(liveStateResetFilters ?? []);

  const requestParams = useMemo<GetEntityAggregationsRequest>(() => {
    const selfFilter = filters ?? [];
    return {
      mostRecentDataFrom: getMostRecentDataFromToUse(),
      groupBy: filterCategory,
      aggregation: aggregationType,
      filter: [...filtersListFromUrl, ...selfFilter],
    };
  }, [
    aggregationType,
    filters,
    filterCategory,
    filtersListFromUrl,
    getMostRecentDataFromToUse,
  ]);

  const { data, isLoading } = useGetAddonAggregations(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityAggregationsRequest: requestParams,
    },
    {
      keepPreviousData: true,
      refetchInterval,
      enabled: useShouldRequestBeEnabled(),
    }
  );

  useSetIsPaginating({
    fetchedIsPaginating: data?.data.isPaginating,
    setIsPaginating,
  });

  updateMostRecentDataFrom(data?.data.mostRecentUpdate);

  const content = !isLoading ? (
    <StyledTitle variant={"h3"} severity={color}>
      {formatNumber(data?.data.count ?? 0)}
    </StyledTitle>
  ) : (
    <CircularProgressContainer>
      <CircularProgress size={16} color={color} />
    </CircularProgressContainer>
  );

  return (
    <ItemContainer aria-label={`${title} ${aggregationItemAriaLabel}`}>
      {content}
      <Typography
        variant={"body2"}
        color={"text.secondary"}
        whiteSpace={"nowrap"}
        sx={{ marginTop: "4px" }}
      >
        {title}
      </Typography>
    </ItemContainer>
  );
};
