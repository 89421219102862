import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import {
  HTTPIngressRuleValue,
  IngressRule,
  HTTPIngressPath,
  IngressBackend,
} from "kubernetes-types/networking/v1.d";

import { DescribeData } from "../common/DescribeData";
import { Table } from "../../../../../shared/components/Table/Table";

const IngressRulesUnitContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  color: ${palette.gray[800]};
`;

export const IngressRules: React.FC<{ rules: Array<IngressRule> }> = ({
  rules,
}) => (
  <DescribeData title={"Rules"}>
    {rules &&
      rules.map(({ host, http }, index) => (
        <IngressRuleUnit key={host ?? index} name={host} value={http} />
      ))}
  </DescribeData>
);

const IngressRuleUnit: React.FC<{
  name?: string;
  value?: HTTPIngressRuleValue;
}> = ({ name, value }) => (
  <IngressRulesUnitContainer>
    <Typography variant="text" size="small">
      {`Host: ${name ?? "*"}`}
    </Typography>
    <Table
      data={value?.paths.map(parseRules) ?? []}
      columns={RULES_COLUMNS}
      sortable={false}
    />
  </IngressRulesUnitContainer>
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const RULES_COLUMNS = [
  {
    header: "Path",
    accessor: "path",
  },
  {
    header: "Backends",
    accessor: "backends",
  },
];

const parseRules = (rules: HTTPIngressPath) => {
  return {
    path: rules.path ?? "",
    backends: getBackends(rules.backend),
  };
};

const getBackends = (backends: IngressBackend) => {
  if (backends.service) {
    const port = backends.service?.port?.name ?? backends.service?.port?.number;
    return `${backends.service?.name ?? ""}:${port}`;
  }

  if (backends.resource) {
    return `APIGroup: ${backends.resource.apiGroup}, Kind: ${backends.resource.kind}, Name: ${backends.resource.name}`;
  }
  return "";
};
