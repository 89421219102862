import React from "react";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { DEFAULT_TOOLTIP } from "../../../../../shared/constants/tooltipIds";

const StyledLink = styled(Link)`
  text-decoration: none;
  display: contents;
`;

export const LinkableDataItem: React.FC<{
  route: string;
  isClickable: boolean;
  onClickCallback?: () => void;
  children?: React.ReactNode;
}> = ({ route, isClickable, onClickCallback, children }) => {
  return (
    <td
      onClick={() => {
        if (isClickable) {
          onClickCallback?.();
        }
      }}
    >
      <TooltipWrapper
        tooltipId={DEFAULT_TOOLTIP}
        content={
          route
            ? undefined
            : "We don’t support this type of resource at the moment"
        }
        place="top"
      >
        {isClickable ? (
          <StyledLink
            to={route}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {children ?? ""}
          </StyledLink>
        ) : (
          <>{children}</>
        )}
      </TooltipWrapper>
    </td>
  );
};
