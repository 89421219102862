import React from "react";

import EventGroup from "../../EventGroup";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";

export interface LabelProps {
  x: number;
  y: number;
  stroke: string;
  showLabel: boolean;
  isEndingLabel: boolean;
  isMidEventLabel: boolean;
  eventGroup: EventGroup;
  index: number;
  chartConfig: TimelineChartConfig;
  onMouseEnter: (() => void) | undefined;
  onMouseLeave: (() => void) | undefined;
  onClick: (() => void) | undefined;
}

export const CustomLabel = ({
  x,
  y,
  stroke,
  showLabel,
  isEndingLabel,
  isMidEventLabel,
  eventGroup,
  index,
  chartConfig,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: LabelProps) => {
  if (!showLabel || (index > 0 && !isMidEventLabel && !isEndingLabel)) {
    return <text />;
  }

  /* In case of ending label, we want to move an icon size to the left
     In x < 0 (mid-event label), we want to move a half icon size to the right
     In case of starting label - use the original x value
     Later, "dx" is adding a eventLabelXOffset px padding to the label
  */
  const startX = isEndingLabel
    ? x - chartConfig.eventDotWidth
    : x < 0
    ? chartConfig.eventLabelXOffset
    : x;

  return (
    <text
      x={startX}
      y={y}
      dx={chartConfig.eventLabelXOffset}
      dy={4}
      fill={stroke}
      fontSize={chartConfig.eventLabelFontSize}
      fontFamily={"roboto"}
      fontWeight={500}
      textAnchor={isEndingLabel ? "end" : "start"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      cursor={"pointer"}
    >
      {eventGroup.renderLineLabel
        ? eventGroup.renderLineLabel()
        : eventGroup.type}
    </text>
  );
};
