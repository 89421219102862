import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface HeaderCellProps {
  content: string;
  description: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  content,
  description,
}) => {
  return (
    <>
      <Tooltip title={description} placement="top">
        <Typography align="center" variant="h5">
          {content}
        </Typography>
      </Tooltip>
    </>
  );
};
