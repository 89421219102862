import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Role from "../../resources/role";
import {
  DescribeDateItem,
  DescribeTextItem,
} from "../DescribeTab/common/DescribeItem";
import { DescribePanel } from "../DescribeTab/common/DescribeSection";
import { DescribeKeyValueItem } from "../DescribeTab/common/DescribeTagListItem";

import { RoleRules } from "./common/RoleRules";
import { DescribeSectionDivider } from "./common/DescribeSectionDivider";
import { DescribeTabContentWrapper } from "./common/DescribeTabContentWrapper";

interface ClusterRoleDetailsTabProps {
  resource: Role;
}

export const RoleDetailsTab: React.FC<ClusterRoleDetailsTabProps> = ({
  resource,
}) => {
  const clusterRole = resource.fullObj;
  const { creationTimestamp, name, labels, annotations } =
    clusterRole.metadata ?? {};

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}
      </DescribePanel>

      <RoleRules rules={clusterRole.rules} />
    </DescribeTabContentWrapper>
  );
};
