import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid-pro";

import { getMibNumber } from "../MetricsSummary/MetricsSummaryUtils";

import { NotApplicable } from "./NotApplicable";
import { Category, MetricRowKeys, MetricRows, MetricsType } from "./types";
import { ColumnSkeleton } from "./ColumnSkeleton";

import { getRoundedNumber } from "@/shared/utils/numbers/getRoundedNumber";

const metricsTypeToTitle: Record<MetricsType, string> = {
  cpu: "CPU",
  memory: "Memory",
};

const categoryToTitle: Record<Category, string> = {
  limit: "Limit",
  requests: "Requests",
};

const MEMORY_UNIT = "MiB";
const CPU_UNIT = "mCores";

const shortVariation = (value: number) => getRoundedNumber(value, 2);

const columnsConfig = (metricsType: MetricsType, category: Category) => {
  return {
    name: {
      field: MetricRowKeys.name,
      headerName: "Container name",
      sortable: false,
      flex: 1,
    },
    notApplicableField: {
      field: "notApplicableField", // This column does not depend on the data
      headerName: `${metricsTypeToTitle[metricsType]} ${categoryToTitle[category]}`,
      sortable: false,
      flex: 1,
    },
    avg: {
      field: MetricRowKeys.avg,
      headerName: "Average",
      sortable: false,
      flex: 1,
    },
    p99: {
      field: MetricRowKeys.p99,
      headerName: "P99",
      sortable: false,
      flex: 1,
    },
    max: {
      field: MetricRowKeys.max,
      headerName: "Max",
      sortable: false,
      flex: 1,
    },
  };
};

export const metricsTableContentColumns = (
  metricsType: MetricsType,
  category: Category
): GridColDef<MetricRows>[] => {
  const formatValue = (value: number) => {
    switch (metricsType) {
      case "memory":
        return `${shortVariation(getMibNumber(value))} ${MEMORY_UNIT}`;
      case "cpu":
        return `${shortVariation(value)} ${CPU_UNIT}`;
    }
  };

  const columns = columnsConfig(metricsType, category);

  return [
    columns.name,
    {
      ...columns.notApplicableField,
      renderCell: () => {
        return <NotApplicable />;
      },
    },
    {
      ...columns.avg,
      valueFormatter: ({ value }) => formatValue(value),
    },
    {
      ...columns.p99,
      valueFormatter: ({ value }) => formatValue(value),
    },
    {
      ...columns.max,
      valueFormatter: ({ value }) => formatValue(value),
    },
  ];
};

export const columnsGroupingModel = (
  metricsType: MetricsType
): GridColumnGroupingModel => {
  return [
    {
      groupId: `${metricsTypeToTitle[metricsType]} Usage`,
      headerName: `${metricsTypeToTitle[metricsType]}  Usage`,
      children: [
        { field: MetricRowKeys.avg },
        { field: MetricRowKeys.p99 },
        { field: MetricRowKeys.max },
      ],
    },
  ];
};

export const getEmptyColumns = (
  metricsType: MetricsType,
  category: Category
) => {
  const columns = columnsConfig(metricsType, category);

  return [
    {
      ...columns.name,
      renderCell: () => {
        return <ColumnSkeleton />;
      },
    },
    {
      ...columns.notApplicableField,
      renderCell: () => {
        return <ColumnSkeleton />;
      },
    },
    {
      ...columns.avg,
      renderCell: () => {
        return <ColumnSkeleton />;
      },
    },
    {
      ...columns.p99,
      renderCell: () => {
        return <ColumnSkeleton />;
      },
    },
    {
      ...columns.max,
      renderCell: () => {
        return <ColumnSkeleton />;
      },
    },
  ];
};
