import {
  DEFAULT_BEST_PRACTICES_TAB_GROUP_BY,
  DEFAULT_VIOLATION_TAB_GROUP_BY,
} from "../constants/reliabilityConstants";

import { useReliabilityDataInUrl } from "./useReliabilityDataInUrl";
import { useSelectedTab } from "./useSelectedTab";

export const useDefaultGroupBy = () => {
  const selectedTab = useSelectedTab();

  return selectedTab === "violations"
    ? DEFAULT_VIOLATION_TAB_GROUP_BY
    : DEFAULT_BEST_PRACTICES_TAB_GROUP_BY;
};

export const useGetGroupBy = () => {
  const [{ groupBy }] = useReliabilityDataInUrl();

  const defaultGroupBy = useDefaultGroupBy();

  return groupBy || defaultGroupBy;
};
