import React, { useRef, useState } from "react";
import { palette } from "@komodorio/design-system";
import { Button } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { useDistanceFromWindowBottomCalculator } from "../hooks/useDistanceFromWindowBottomCalculator";
import { useCreationPageContext } from "../context/CreationPageContext";
import { CancellationDialog } from "../CancellationDialog";
import { useSaveAppView } from "../hooks/useSaveAppView";
import { useIsEditMode } from "../hooks/creationPageHooks";
import { useUpdateCurrentAppView } from "../hooks/useUpdateAppView";
import { LoadingSpinnerIcon } from "../../../../../../../shared/components/LoadingSpinnerIcon";

const Footer = styled.footer<{ shadowOpacity?: number }>`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${palette.gray[50]};
  box-shadow: 0 2px 8px 1px
    rgba(0, 0, 0, ${({ shadowOpacity }) => shadowOpacity});
  transition: box-shadow 0.2s;
  border: 1px solid ${palette.gray[200]};
`;

const MarginContainer = styled.div`
  margin: 1.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CreationPageFooter: React.FC = () => {
  const { isSavingAppView } = useCreationPageContext();
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const isEditMode = useIsEditMode();

  const openCancellationDialog = () => {
    setCancellationDialogOpen(true);
  };
  const closeCancellationDialog = () => {
    setCancellationDialogOpen(false);
  };

  const distanceFromWindowBottom = useDistanceFromWindowBottomCalculator();
  const footerShadowOpacity = Math.min(distanceFromWindowBottom / 200, 0.15);
  const buttonLabel = !isEditMode ? "Create workspace" : "Save changes";

  const saveAppView = useSaveAppView();
  const updateAppView = useUpdateCurrentAppView();

  const onSaveButtonClick = () => {
    if (!isEditMode) {
      saveAppView();
    } else {
      updateAppView();
    }
  };

  return (
    <>
      <Footer
        shadowOpacity={Math.min(0.15, footerShadowOpacity)}
        ref={footerRef}
      >
        <MarginContainer>
          <FlexContainer>
            <Button
              variant="secondary"
              size="large"
              onClick={openCancellationDialog}
            >
              Cancel
            </Button>
            <Button variant="primary" size="large" onClick={onSaveButtonClick}>
              {isSavingAppView && <LoadingSpinnerIcon />} {buttonLabel}
            </Button>
          </FlexContainer>
        </MarginContainer>
      </Footer>
      <div
        style={{
          height: footerRef.current?.offsetHeight,
        }}
      />
      <CancellationDialog
        isOpen={cancellationDialogOpen}
        close={closeCancellationDialog}
      />
    </>
  );
};
