import { useMemo } from "react";
import { isNumber } from "lodash";

import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

const defaultServicePollingIntervalSeconds = 15;

export const useGetServicePollingIntervalInSeconds = (): number => {
  const { webServicePollingIntervalSeconds } = useOverridableFlags();
  return useMemo(() => {
    if (isNumber(webServicePollingIntervalSeconds)) {
      return webServicePollingIntervalSeconds;
    }
    return defaultServicePollingIntervalSeconds;
  }, [webServicePollingIntervalSeconds]);
};
