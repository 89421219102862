import React from "react";
import { IconProps } from "@komodorio/design-system/icons";

export const CalendarIcon: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      width="240"
      height="167"
      viewBox="0 0 240 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.899 154.578L82.1708 113.93V2.12917L175.899 42.7768V154.578Z"
        fill="white"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M170.252 157.966L76.5246 117.318V5.51684L170.252 46.1645V157.966Z"
        fill="white"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M164.606 162.483L70.8784 121.835V10.034L164.606 50.6816V162.483Z"
        fill="white"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.2035 71.0135L64.1015 59.0612V41.653L91.2035 53.6053V71.0135Z"
        fill="white"
      />
      <path
        d="M158.395 167L118.307 148.44V134.252L158.395 152.811V167Z"
        fill="white"
      />
      <path
        d="M118.307 147.802L65.2324 125.217V111.028L118.307 133.613V147.802Z"
        fill="white"
      />
      <path
        d="M131.551 124.326L118 118.189V101L131.551 107.138V124.326Z"
        fill="#FC1683"
      />
      <path
        d="M77.6541 130.864L65.2324 125.218V59.721L77.6541 65.3672V130.864Z"
        fill="white"
      />
      <path
        d="M158.96 165.87L64.1031 124.922V12.2924L158.96 53.2411V165.87Z"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M145.409 79.0655L145.409 159.095"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M131.858 74.7447L131.858 153.449"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.307 68.1654L118.307 147.802"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.756 61.5868L104.756 142.156"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.2051 55.7685L91.2051 135.945"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.6542 50.6869L77.6542 130.127"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1031 109.408L158.96 152.32"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1031 93.5984L158.96 136.51"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1031 75.5304L158.96 118.442"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1031 59.721L158.96 102.633"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M158.96 84.5644L64.1031 43.4198V12.2924L158.96 53.4369V84.5644Z"
        fill="#1347FF"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1013 11.5779L82.7339 1L175.897 42.7823L158.394 52.9455L64.1013 11.5779Z"
        fill="#1347FF"
        stroke="#061431"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
