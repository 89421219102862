import React from "react";
import { Button, ButtonProps } from "@komodorio/design-system/deprecated";
import { Lock16 } from "@komodorio/design-system/icons";

import {
  freemiumModalNames,
  useFreemiumModalsUpdate,
} from "../../../../shared/hooks/useFreemiumModals";

export const FreemiumPoliciesButton: React.FC<ButtonProps> = ({
  onClick,
  icon,
  ...rest
}) => {
  const setOpenFreemiumModal = useFreemiumModalsUpdate();

  const onFreemiumButtonClick = () => {
    setOpenFreemiumModal &&
      setOpenFreemiumModal(freemiumModalNames.POLICIES_RESTRICTION);
  };
  return (
    <>
      <Button {...rest} icon={Lock16} onClick={onFreemiumButtonClick} />
    </>
  );
};
