import React, { useMemo } from "react";

import { DataPointsBreakdownPerIntervals } from "../../shared/utils/getDataPointsBreakdownPerIntervals";
import { InsightsDrawerContent } from "../../shared/styles";
import {
  ComparisonLineGraphsProps,
  ComparisonLinesGraph,
} from "../../shared/components/ComparisonLinesGraph/ComparisonLinesGraph";

import {
  getBreakDownText,
  getDescription,
  getFailedDeploysNumDetected,
} from "./FailedDeploysTexts";

const isTrendIncreasing = (a: number, b: number) => a < b;

export const useDataBreakdownDescription = (
  dataPointsBreakdown?: DataPointsBreakdownPerIntervals
) => {
  return useMemo(() => {
    if (!dataPointsBreakdown) return "";
    const {
      trendPercentage,
      secondTotal,
      secondIntervalDuration,
      isIncreased,
    } = dataPointsBreakdown;
    return trendPercentage !== "0"
      ? getDescription(parseFloat(trendPercentage), isIncreased)
      : getFailedDeploysNumDetected(secondIntervalDuration, secondTotal);
  }, [dataPointsBreakdown]);
};

type DrawerInfoData = {
  title: string;
  trendPercentage: string;
  firstTotal: number;
  secondTotal: number;
  isIncreasing: boolean;
};

export const useDrawerInfo = (
  dataPointsBreakdown?: DataPointsBreakdownPerIntervals
): DrawerInfoData | undefined => {
  const description = useDataBreakdownDescription(dataPointsBreakdown);
  return useMemo(() => {
    if (!dataPointsBreakdown) return undefined;
    const { trendPercentage, firstTotal, secondTotal } = dataPointsBreakdown;

    return {
      title: description,
      trendPercentage,
      firstTotal,
      secondTotal,
      isIncreasing: isTrendIncreasing(firstTotal, secondTotal),
    };
  }, [dataPointsBreakdown, description]);
};

type BreakdownDataComponentsParams = {
  drawerInfo?: DrawerInfoData;
  dataPointsBreakdownPerIntervals?: DataPointsBreakdownPerIntervals;
};

export const useBreakdownDataContent = ({
  drawerInfo,
  dataPointsBreakdownPerIntervals,
}: BreakdownDataComponentsParams) => {
  const breakdownText = useMemo(() => {
    if (!drawerInfo) return "";
    return getBreakDownText(drawerInfo);
  }, [drawerInfo]);

  const comparisonLinesGraph = useComparisonLinesGraph({
    data: dataPointsBreakdownPerIntervals,
    displayMode: "full",
  });

  return (
    <>
      <InsightsDrawerContent variant={"body2"}>
        {breakdownText}
      </InsightsDrawerContent>
      {comparisonLinesGraph}
    </>
  );
};

export const useComparisonLinesGraph = ({
  data,
  displayMode,
}: ComparisonLineGraphsProps) => {
  return useMemo(() => {
    const shouldDisplay =
      (data?.firstTotal ?? 0) > 0 && (data?.secondTotal ?? 0) > 0;

    if (!shouldDisplay) return null;
    return <ComparisonLinesGraph data={data} displayMode={displayMode} />;
  }, [data, displayMode]);
};
