import { useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { RightSizingCostSummaryByServiceResponse } from "../../../../../generated/metricsApi";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectSetRightSizingRecommendationsState,
  selectRightSizingRecommendationModal,
  selectRightSizingRecommendationsState,
  selectLastVisitTime,
} from "../../../store/costOptimizationStoreSelectors";
import { RIGHT_SIZING_RECOMMENDATION_DD_START_VIEW_NAME } from "../../../constants/costOptimizationConstants";
import { KOMODOR_LOADING_TIME } from "../../../../../shared/constants/datadog";
import { useFetchCostRightSizingRecommendationsData } from "../../shared/RecommendationsModal/recommendationsModalHooks";
import { useGetRowData } from "../RightSizingTable/rightSizingTableHooks";

export const useFetchRightSizingRecommendationData = (): void => {
  const setRightSizingRecommendationsState = useCostOptimizationStore(
    selectSetRightSizingRecommendationsState
  );

  const { rowId } = useCostOptimizationStore(
    selectRightSizingRecommendationModal
  );

  const prevRecommendationState = useCostOptimizationStore(
    selectRightSizingRecommendationsState
  );

  const lastVisitTime = useCostOptimizationStore(selectLastVisitTime);
  const rowData = useGetRowData(rowId);
  useFetchCostRightSizingRecommendationsData({
    rowData,
    prevRecommendationState,
    lastVisitTime,
    onDataFetched: setRightSizingRecommendationsState,
  });
};

export const useReportLoadTime = (
  data: RightSizingCostSummaryByServiceResponse | undefined,
  enabled: boolean
): void => {
  const hasReportedPageView = useRef(false);
  const shouldReportLoadingTime = useRef(false);

  useEffect(() => {
    if (!enabled) {
      hasReportedPageView.current = false;
      shouldReportLoadingTime.current = false;
      return;
    }
    if (!data?.rows?.length) {
      if (!hasReportedPageView.current) {
        hasReportedPageView.current = true;
        shouldReportLoadingTime.current = true;
        datadogRum.startView(RIGHT_SIZING_RECOMMENDATION_DD_START_VIEW_NAME);
      }
    } else if (shouldReportLoadingTime.current) {
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
      shouldReportLoadingTime.current = false;
    }
  }, [data?.rows?.length, enabled]);
};
