import { useMemo } from "react";

import { useClusterOptionsByAppView } from "@/shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { isDevMode } from "@/shared/utils/isDevMode";

export const useGetAllClusterNamesAsOptions = () => {
  const allClusters = useClusterOptionsByAppView(isDevMode());

  return useMemo(
    () =>
      allClusters.map((cluster) => ({
        label: cluster.clusterName,
        value: cluster.clusterName,
      })),
    [allClusters]
  );
};
