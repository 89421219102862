import React from "react";
import styled from "styled-components";
import { Pencil24, Team24 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import EventDetailsSection from "../common/ProcessList/details/components/EventDetailsSection";

import AuditObject from "./details/AuditObject";

export const AuditContainer = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : undefined)};
  color: #3d4048;
  height: inherit;
  width: inherit;
`;

const Headline = styled(Typography)`
  :first-of-type::first-letter {
    text-transform: capitalize;
  }
  padding: 32px;
`;

export const AuditDetailsContent: React.FC<{ obj: AuditObject }> = ({
  obj,
}) => {
  return (
    <AuditContainer>
      <Headline variant={"h3"}>{obj.renderAction()}</Headline>
      <Divider />
      <EventDetailsSection
        icon={Pencil24}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Changes"
      >
        {obj.renderDetails()}
      </EventDetailsSection>
      <EventDetailsSection
        icon={Team24}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="User"
      >
        {obj.data.user?.email}
      </EventDetailsSection>
    </AuditContainer>
  );
};
