import { useEffect, useRef, useCallback } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { KOMODOR_LOADING_TIME } from "../../constants/datadog";

type DDRumAddTimingParams = {
  enable?: boolean;
  customTimingName?: string;
  customTimingTime?: number;
};
export const useDDRumAddTiming = ({
  enable,
  customTimingName,
  customTimingTime,
}: DDRumAddTimingParams) => {
  const ddTimingWasAdded = useRef(false);

  useEffect(() => {
    if (enable && !ddTimingWasAdded.current) {
      datadogRum.addTiming(
        customTimingName ?? KOMODOR_LOADING_TIME,
        customTimingTime
      );
      ddTimingWasAdded.current = true;
    }
  }, [customTimingName, customTimingTime, enable]);

  const resetTiming = useCallback(() => {
    ddTimingWasAdded.current = false;
  }, []);

  return { resetTiming };
};

type DDRumStartViewAndAddTimingParams = {
  viewName: string;
  addTimingParams: DDRumAddTimingParams;
};
export const useDDRumStartViewAndAddTiming = ({
  viewName,
  addTimingParams,
}: DDRumStartViewAndAddTimingParams) => {
  const startViewWasTriggered = useRef(false);

  useEffect(() => {
    if (!startViewWasTriggered.current) {
      datadogRum.startView(viewName);
      startViewWasTriggered.current = true;
    }
  }, [viewName]);

  useDDRumAddTiming(addTimingParams);
};

export type DDRumViewAndTimingListenerParams = {
  isResourceFetching: boolean;
  viewName: string;
  addTimingParams: DDRumAddTimingParams;
};
export const useDDRumViewAndTimingListener = ({
  isResourceFetching,
  viewName,
  addTimingParams,
}: DDRumViewAndTimingListenerParams) => {
  const startViewWasTriggered = useRef(false);
  const { resetTiming } = useDDRumAddTiming(addTimingParams);

  useEffect(() => {
    if (!startViewWasTriggered.current && isResourceFetching) {
      startViewWasTriggered.current = true;
      resetTiming();
      datadogRum.startView(viewName);
    } else if (!isResourceFetching) {
      startViewWasTriggered.current = false;
    }
  }, [isResourceFetching, resetTiming, viewName]);
};
