import { DataState } from "../../../components/appView/types/DataState";
import { FetchAppViewServicesState } from "../../../components/appView/sections/AppView/types/appViewsTypes";
import { Dictionary } from "../../types/Dictionary";
import { Workspace, WorkspaceKind } from "../../../generated/workspacesApi";

import { AppViewsStore, initialState } from "./appViewsStore";

// getters
export const loadingStateSelector = (state: AppViewsStore): boolean =>
  state.dataState.loading;
export const errorStateSelector = (state: AppViewsStore): boolean =>
  state.dataState.error;
export const appViewIdSelector = (state: AppViewsStore): string | undefined =>
  state.selectedAppViewId;
export const appViewsByIdSelector = (
  state: AppViewsStore
): Dictionary<Workspace> | undefined => {
  return state.appViewsById;
};
export const selectedAppViewIdSelector = (
  state: AppViewsStore
): string | undefined => state.selectedAppViewId;
export const currentAppViewSelector = (state: AppViewsStore): Workspace =>
  state.selectedAppViewId && state.appViewsById
    ? state.appViewsById[state.selectedAppViewId] ?? initialState.currentAppView
    : initialState.currentAppView;
export const serviceIdsSelector = (state: AppViewsStore): string[] =>
  state.serviceIds;
export const servicesUIDsSelector = (state: AppViewsStore): string[] =>
  state.servicesUIDs;
export const fetchAppViewServicesStateSelector = (
  state: AppViewsStore
): FetchAppViewServicesState => state.fetchAppViewServicesState;

// setters
export const setSelectedAppViewIdSelector = (
  state: AppViewsStore
): ((appViewId: string | undefined, kind?: WorkspaceKind) => void) =>
  state.setSelectedAppViewId;
export const setAppViewsByIdSelector = (
  state: AppViewsStore
): ((appViews: Dictionary<Workspace>) => void) => state.setAppViewsById;
export const setDataStateSelector = (
  state: AppViewsStore
): ((state: Partial<DataState>) => void) => state.setDataState;
export const onAppViewUpsertSelector = (
  state: AppViewsStore
): ((appView: Workspace) => void) => state.onAppViewUpsert;
export const onAppViewDeletionSelector = (
  state: AppViewsStore
): ((appViewId: string) => void) => state.onAppViewDeletion;
export const setServiceIdsSelector = (
  state: AppViewsStore
): ((serviceIds: string[]) => void) => state.setServiceIds;
export const setServicesUIDsSelector = (
  state: AppViewsStore
): ((servicesUIDs: string[]) => void) => state.setServicesUIDs;
export const setFetchAppViewServicesStateSelector = (
  state: AppViewsStore
): ((state: Partial<FetchAppViewServicesState>) => void) =>
  state.setFetchAppViewServicesState;
