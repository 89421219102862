import { muiColors } from "@komodorio/design-system";

export const colors = [
  muiColors.teal[600],
  muiColors.teal[200],
  muiColors.gray[500],
  muiColors.pink[200],
  muiColors.green["A700"],
  muiColors.orange[100],
  muiColors.lightBlue[500],
  muiColors.deepPurple[200],
  muiColors.yellow[200],
  muiColors.gray[200],
];
