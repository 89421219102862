import React, { useMemo } from "react";
import { Job as JobType } from "kubernetes-types/batch/v1.d";

import { Header } from "../common/Header/Header";
import { Label } from "../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../resources/job";
import { JobState } from "../../../shared/types/job";
import WarningArea from "../../common/WarningArea";
import { useResourcesAPIPost } from "../../../shared/hooks/resources-api/client";
import {
  Json,
  buildKomodorUid,
} from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { JOB_STATE } from "../../../shared/hooks/resources-api/requestResponseMaps";

import { ResourceStatusEnum, StatusTag } from "./common";

interface JobHeaderProps {
  resource: Job;
  labels: Label[];
  leftActions?: JSX.Element;
}

export const JobHeader: React.FC<JobHeaderProps> = ({
  resource,
  labels,
  leftActions,
}) => {
  const uid = buildKomodorUid({
    kind: "Job",
    clusterName: resource.cluster,
    namespace: resource.namespace,
    resourceName: resource.name,
  });

  const { data: dataFromAPI } = useResourcesAPIPost(
    JOB_STATE,
    {
      komodorUids: [uid],
      agentId: resource.agentId ?? "",
      fields: [Json],
    },
    !resource.agentId
  );

  const jobSpec = (dataFromAPI?.data?.json?.[0] as JobType | undefined)?.spec;

  const parallelism = resource.parallelism ?? jobSpec?.parallelism;
  const completions = resource.completions ?? jobSpec?.completions;

  const failureData = useMemo(() => {
    const failureLabels = [];
    if (resource?.jobStatusReason) {
      failureLabels.push({
        name: "Failure reason",
        value: resource.jobStatusReason,
      });
    }
    if (resource?.jobStatusReason) {
      failureLabels.push({
        name: "Message",
        value: resource.jobStatusMessage,
      });
    }
    return failureLabels;
  }, [resource.jobStatusMessage, resource.jobStatusReason]);
  return (
    <>
      <Header
        status={
          <StatusTag
            status={
              resource.jobState !== JobState.failed
                ? ResourceStatusEnum.Healthy
                : ResourceStatusEnum.Failed
            }
          >
            {resource.jobState.toUpperCase()}
          </StatusTag>
        }
        resource={resource}
        labels={[
          ...labels,
          { name: "parallelism", value: parallelism?.toString() },
          { name: "completions", value: completions?.toString() },
        ]}
        leftActions={leftActions}
      />
      {resource.jobState === JobState.failed && failureData.length > 0 && (
        <>
          <WarningArea labels={failureData} />
          <br />
        </>
      )}
    </>
  );
};
