import { useCallback, useMemo } from "react";

import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import { Dictionary } from "../../../shared/types/Dictionary";

import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

const INITIAL_DICT = "{}";

type Props = {
  localStorageItem: LocalStorageItem;
  key: string;
};

type Response = {
  hideAlertVal: boolean;
  setHideAlertVal: () => void;
};

type HideAlertType = Dictionary<boolean>;

export const usePermanentHideAlertByString = ({
  localStorageItem,
  key,
}: Props): Response => {
  const [hideAlert, setHideAlert] = useStateInLocalStorage(
    localStorageItem,
    INITIAL_DICT
  );

  const setHideAlertVal = useCallback(() => {
    try {
      const prevDict = JSON.parse(hideAlert) as HideAlertType;
      prevDict[key] = true;

      setHideAlert(JSON.stringify(prevDict));
    } catch (e) {
      const newDict = { [key]: true };
      setHideAlert(JSON.stringify(newDict));
    }
  }, [hideAlert, key, setHideAlert]);

  const hideAlertVal = useMemo(() => {
    try {
      const dict = JSON.parse(hideAlert) as HideAlertType;
      return dict[key] || false;
    } catch (e) {
      return false;
    }
  }, [hideAlert, key]);

  return {
    hideAlertVal,
    setHideAlertVal,
  };
};
