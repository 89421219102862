import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const OverviewMetricsTitle = styled(Typography).attrs({
  variant: "h6",
})`
  white-space: nowrap;
`;

export const ScoreOverviewMetricsTitle = styled(OverviewMetricsTitle)`
  && {
    margin-bottom: 18px;
  }
`;
