export const resourcesColumns = [
  {
    header: "",
    accessor: "resourceFieldType",
    divider: true,
  },
  {
    header: "CPU",
    accessor: "cpu",
  },
  {
    header: "Memory",
    accessor: "memory",
  },
  {
    header: "Ephemeral Storage",
    accessor: "ephemeral-storage",
  },
  {
    header: "Pods",
    accessor: "pods",
  },
];

export const taintsColumns = [
  {
    header: "Key",
    accessor: "key",
  },
  {
    header: "Value",
    accessor: "value",
  },
  {
    header: "Effect",
    accessor: "effect",
  },
];
