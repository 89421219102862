import React, { useMemo } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { costOptimizationAriaLabels as ariaLabels } from "../../../constants/costOptimizationAriaLabels";
import { commonSelectProps } from "../commonSelectProps";

import { ScopeFilterOption } from "./types";
import { useDisabledOptions } from "./useDisabledOptions";

interface ScopeFilterProps {
  values: MuiSelectionOption<string>[];
  options: ScopeFilterOption[];
  onChange: (options: MuiSelectionOption<string>[]) => void;
  onClose: () => void;
  onOpen: () => void;
}

export const ScopeFilter: React.FC<ScopeFilterProps> = ({
  values,
  options,
  onChange,
  onClose,
  onOpen,
}) => {
  const multiSelectOptions = useMemo(
    () => options.map((option) => option.option),
    [options]
  );

  const getDisabledOptions = useDisabledOptions(options);

  return (
    <div aria-label={ariaLabels.allocationPage.filters.scope}>
      <AdvancedMultiSelect
        {...commonSelectProps}
        value={values}
        placeholder={"All Clusters"}
        options={multiSelectOptions}
        onChange={onChange}
        label={"Clusters:"}
        onClose={onClose}
        texts={{ hiddenItemsTitle: "Unavailable clusters" }}
        onOpen={onOpen}
        renderDisabledOptions={(_options) => getDisabledOptions()}
        disabled={multiSelectOptions.length <= 1}
      />
    </div>
  );
};
