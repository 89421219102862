import React from "react";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";
import { IconProps } from "@komodorio/design-system/icons";

import { blackGreyText } from "../../Colors";

const createControlIcon = (polygons: string[]) => (svgProps: IconProps) =>
  (
    <svg
      width="12"
      viewBox="0 0 4 4"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      fill="currentColor"
      {...svgProps}
    >
      {polygons.map((points) => (
        <polygon key={points} points={points} />
      ))}
    </svg>
  );

export const FBIcon: React.FC<IconProps> = createControlIcon([
  "0,2 2,0 2,4",
  "2,2 4,0 4,4",
]);

export const FFIcon: React.FC<IconProps> = createControlIcon([
  "0,0 0,4 2,2",
  "2,0 2,4 4,2",
]);

export const ControlButton = styled(Button).attrs({ variant: "secondary" })`
  padding: 9px;
  color: ${blackGreyText};
`;
