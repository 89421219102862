import React from "react";
import Typography from "@mui/material/Typography";

import { Integration } from "../definitions";
import IntegrationTile from "../IntegrationTile";

import { Install } from "./Install";

const AvailableIntegrationTile: React.FC<{
  integration: Integration;
  isInstalled?: boolean;
  newTab?: boolean;
  className?: string;
}> = ({ integration, isInstalled, newTab, className }) => {
  const { logo, title, description } = integration;
  return (
    <IntegrationTile
      logoWidth="72px"
      logo={logo}
      title={title}
      className={className}
    >
      <Typography variant="body2">{description}</Typography>
      <Install
        integration={integration}
        isInstalled={isInstalled}
        newTab={newTab}
      />
    </IntegrationTile>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AvailableIntegrationTile;
