import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { LDUser } from "launchdarkly-js-client-sdk";
import React, { useEffect } from "react";
import { User } from "@auth0/auth0-react";

import useUserMetadata from "../../hooks/useUserMetadata/useUserMetadata";
import { getAppConfig } from "../../config/appConfig";

import { OverridableFlags } from "./OverridableFlags";
import { useCustomFlagsAttributes } from "./CustomFlagsAttributes";

const IdentifiedLDProvider: React.FC<{
  auth0User: User | undefined;
  children?: React.ReactNode;
}> = ({ auth0User, children }) => {
  const isProductionEnv = getAppConfig().env === "production";
  const user = useUserMetadata();
  const ldClientSideId = getAppConfig().launchDarklyClient as string;
  const customAttributes = useCustomFlagsAttributes();
  const initialUserContext: LDUser = {
    key: auth0User?.sub ?? "anonymous",
    name: auth0User?.name ?? "anonymous",
    email: auth0User?.email ?? "anonymous@anonymo.us",
    custom: {
      account: user.accountName,
      accountId: user.accountId,
      isAdmin: user.isKomodorAdmin,
      accountOrigin: user.accountOrigin,
      ...customAttributes,
    },
  };
  return (
    <LDProvider
      clientSideID={ldClientSideId}
      options={{ bootstrap: "localStorage", streaming: isProductionEnv }}
      user={initialUserContext}
    >
      <DynamicUserUpdater initialUserContext={initialUserContext} />
      <OverridableFlags>{children}</OverridableFlags>
    </LDProvider>
  );
};

const DynamicUserUpdater: React.FC<{ initialUserContext: LDUser }> = ({
  initialUserContext,
}) => {
  const ldClient = useLDClient();
  const customAttributes = useCustomFlagsAttributes();

  useEffect(() => {
    if (ldClient) {
      const updatedUserContext: LDUser = {
        ...initialUserContext,
        custom: {
          ...initialUserContext.custom,
          ...customAttributes,
        },
      };
      ldClient.identify(updatedUserContext);
    }
  }, [ldClient, customAttributes, initialUserContext]);

  return null;
};

export { IdentifiedLDProvider };
