export const GENERAL_ERROR_MESSAGE =
  "Encountered an issue while saving the user. Please retry or contact support for assistance.";

export const emailRegex =
  /^(([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+$/;

export type timeTokens = "xMinutes" | "xHours" | "xDays" | "xMonths" | "xYears";

export const formatDistanceLocale = {
  xYears: "{{count}} yr",
  xMonths: "{{count}} mo",
  xMinutes: "{{count}} min",
  xHours: "{{count}} h",
  xDays: "{{count}} d",
};

export const PAST_DATE_PICKET_ERROR = "disablePast";
export const INVALID_DATE_PICKET_ERROR = "invalidDate";
export const MAX_DATE_PICKET_ERROR = "maxDate";

export const ariaLabels = {
  page: {
    search: "Search users",
    table: "Users table",
  },
  addUserModal: {
    container: "Add user modal",
  },
  addBulkUsersModal: {
    container: "Add bulk users modal",
  },
  table: {
    actionsCell: {
      container: "Actions cell",
      edit: "Edit user",
      delete: "Delete user",
      restore: "Restore user",
      viewPermissions: "View permissions",
    },
  },
  userPermissionsDrawer: {
    container: "Effective permissions drawer",
    filters: {
      action: "Action filter",
      policyName: "Policy name filter",
      roleName: "Role name filter",
      cluster: "Cluster filter",
      namespace: "Namespace filter",
    },
    table: "Effective permissions table",
  },
};

export const ROLES_CELL_WIDTH = 300;
