import { useMemo } from "react";

import { formatCurrency } from "../../../../../shared/utils/formatCurrency";

export const formatSavingPercent = (
  beforeAmount: number,
  afterAmount: number
) => {
  const percentDifference = (afterAmount / beforeAmount) * 100;
  const savings = 100 - percentDifference;
  return `${Math.round(savings)}% savings`;
};

export const formatSavingPerMonth = (amount: number) =>
  `${formatCurrency(amount)}/month`;

export const useCalculatedValues = (
  before: number,
  monthlySaving: number,
  unallocatedPrice: number
) => {
  return useMemo(() => {
    const after = before - monthlySaving;
    const percentDifference = (after / before) * 100;
    const savingsPercent = 100 - percentDifference;
    const savingsAsPercent = formatSavingPercent(before, after);
    const beforeSavings = formatSavingPerMonth(before);
    const afterSavings = formatSavingPerMonth(after);
    const allocatedBeforePercent = ((before - unallocatedPrice) / before) * 100;
    const allocatedAfterPercent = allocatedBeforePercent - savingsPercent;
    const unallocatedBeforePercent = (unallocatedPrice / before) * 100;
    return {
      savingsAsPercent,
      afterSavings,
      beforeSavings,
      savingsPercent,
      allocatedBeforePercent,
      allocatedAfterPercent,
      unallocatedBeforePercent,
    };
  }, [before, monthlySaving, unallocatedPrice]);
};
