import React, { useMemo } from "react";
import {
  DataGridProProps,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
  gridClasses,
  DataGridPro,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro";

import { WORKSPACE_ID_URL_PARAM } from "@/shared/store/appViewsStore/appViewsStore";
import {
  ClustersSummaryFilters,
  ClusterSummary,
  WorkspaceKind,
} from "@/generated/workspacesApi";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { dispatchEvent } from "@/shared/hooks/analytics";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import {
  DEFAULT_SORTING_MODEL,
  PAGINATION_SIZE_OPTIONS,
} from "@/components/ClustersView/constants";
import {
  fromSortModel,
  getPaginationModel,
  toSortModel,
} from "@/components/ClustersView/utils";
import { WORKSPACE_ROUTE } from "@/components/routes/routes";
import { availableColumnsForClustersTable } from "@/components/ClustersView/components/ClustersList/availableColumnsForClustersTable";
import { ColumnField } from "@/components/ClustersView/components/ClustersList/types";
import { TypedClusterWorkspace } from "@/shared/hooks/workspaces-api/types";

interface ClustersListProps {
  clusters: ClusterSummary[];
  isLoadingClusters: boolean;
  filters: ClustersSummaryFilters | null;
  setFilters: (
    newValue: ClustersSummaryFilters | null,
    replace?: boolean
  ) => void;
  totalResultsCount: number;
  columns: ColumnField[];
  columnsVisibility?: GridColumnVisibilityModel;
  dataGridProps?: Omit<DataGridProProps, "rows" | "columns">;
}
export const ClustersList: React.FC<ClustersListProps> = ({
  clusters,
  isLoadingClusters,
  filters,
  setFilters,
  totalResultsCount,
  columns,
  columnsVisibility,
  dataGridProps,
}) => {
  const { workspaces } = useWorkspaces();
  const clusterWorkspaces = useMemo(
    () =>
      workspaces?.filter(
        (w): w is TypedClusterWorkspace => w.kind === WorkspaceKind.Cluster
      ),
    [workspaces]
  );

  const paginationModel = useMemo<GridPaginationModel>(
    () => getPaginationModel(filters?.limit, filters?.offset),
    [filters]
  );

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setFilters({
      ...filters,
      offset: newPaginationModel.page * newPaginationModel.pageSize,
      limit: newPaginationModel.pageSize,
    });
  };

  const sortModel = useMemo<GridSortModel>(
    () =>
      filters?.sort === undefined
        ? DEFAULT_SORTING_MODEL
        : toSortModel(filters.sort),
    [filters]
  );

  const handleSortModelChange = (model: GridSortModel) => {
    setFilters({ ...filters, sort: fromSortModel(model) });
  };

  const handleRowClick = ({ row }: GridRowParams<ClusterSummary>) => {
    const workspaceId = clusterWorkspaces?.find(
      (w) => w.value.clusterName === row.clusterName
    )?.id;
    const workspaceIdParam = workspaceId
      ? `?${WORKSPACE_ID_URL_PARAM}=${workspaceId}`
      : "";
    dispatchEvent(AnalyticEvents.ClustersList.ClusterSelected, {
      clusterName: row.clusterName,
    });
    window.open(
      `${WORKSPACE_ROUTE}/${workspaceId}${workspaceIdParam}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const tableColumns = useMemo(
    () =>
      columns
        .map((c) =>
          availableColumnsForClustersTable.find((col) => col.field === c)
        )
        .filter((c) => c !== undefined) as GridColDef<ClusterSummary>[],
    [columns]
  );

  return (
    <DataGridPro
      autoHeight={true}
      rows={clusters}
      columns={tableColumns}
      onRowClick={handleRowClick}
      disableColumnMenu
      disableVirtualization
      pagination
      pageSizeOptions={PAGINATION_SIZE_OPTIONS}
      rowCount={totalResultsCount}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={paginationModel}
      loading={isLoadingClusters}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      columnVisibilityModel={columnsVisibility}
      sx={(theme) => ({
        border: "unset",
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
        "& .MuiTablePagination-root": {
          marginRight: "36px",
        },
        [`.${gridClasses.main}`]: {
          overflow: "unset",
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
        },
      })}
      aria-label={AriaLabels.ClustersView.ClustersTable}
      {...dataGridProps}
    />
  );
};
