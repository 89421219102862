import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

import { AriaLabels } from "@/shared/config/ariaLabels";
import {
  DialogContainer,
  DialogFooter,
  StyledLoadingButton,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";

const ConsentTextBullets = () => (
  <ul>
    <li>
      <div>
        Komodor will <b>not be able</b> to audit or track usage of users
        accessing a cluster using these kubeconfig credentials.
      </div>
    </li>
    <li>
      <div>
        Only account admins / feature admins may enable or disable this feature
        via this page.
      </div>
    </li>
    <li>
      <div>
        Users requires <b>get:kubeconfig</b> permission to use this feature.
      </div>
    </li>
  </ul>
);

export const ConsentFormDialog: React.FC<{
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  onEnable: () => void;
}> = ({ open, onClose, onEnable, isLoading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContainer
        aria-label={
          AriaLabels.SettingsPage.AccountProfile.BetaFeatures
            .RbacClusterSyncConsentForm
        }
      >
        <Typography variant={"h3"}>Enable RBAC Cluster Sync</Typography>
        <Typography variant={"body2"}>
          Please read and approve the following: <br />
          <ConsentTextBullets />
        </Typography>
        <DialogFooter>
          <Button
            variant={"text"}
            size={"large"}
            sx={{
              width: "5rem",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <StyledLoadingButton
            loading={isLoading}
            onClick={onEnable}
            aria-label={
              AriaLabels.SettingsPage.AccountProfile.BetaFeatures
                .RbacClusterSyncConsentFormEnableButton
            }
          >
            I understand, enable
          </StyledLoadingButton>
        </DialogFooter>
      </DialogContainer>
    </Dialog>
  );
};
