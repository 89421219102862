import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { FetchServiceDescribeQuery } from "../../../../generated/graphql";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import PodMessage from "../../../common/LogsViewer/LogsBlackText/PodMessage";

const PodMessageContainer = styled.div`
  margin: auto;
  margin-top: 0;
`;

const PodMessageTitleStyle = `
  font-weight: 500;
`;

const StyledPre = styled.pre`
  padding: 0.5rem 1rem;
  margin: 0;
  font-family: "Roboto Mono";
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

export const ServiceDescribeFailed: React.FC<{
  failureMessage: string;
  serviceId: string | undefined;
  serviceType: string;
}> = ({ failureMessage, serviceId, serviceType }) => {
  dispatchEvent(AnalyticEvents.ServiceView.ServiceDescribe_Failed, {
    serviceId: serviceId,
    failureMessage: failureMessage,
    serviceType,
  });
  return (
    <PodMessageContainer>
      <PodMessage
        icon="error"
        title="Information can’t be loaded"
        titleSecondLine="We suggest checking the agent connection."
        message={failureMessage}
        customFirstLineStyle={PodMessageTitleStyle}
        customSecondLineStyle={PodMessageTitleStyle}
      />
    </PodMessageContainer>
  );
};

export const ServiceDescribeSuccess: React.FC<{
  serviceDescribeRes: FetchServiceDescribeQuery | null;
  serviceId: string | undefined;
  serviceType: string;
  startMeasureLatency: number;
}> = ({ serviceDescribeRes, serviceId, serviceType, startMeasureLatency }) => {
  const [descriptionGotChanged, setDescriptionGotChanged] = useState(false);
  const description = useMemo(() => {
    setDescriptionGotChanged(true);
    return serviceDescribeRes?.service_description[0]?.description ?? null;
  }, [serviceDescribeRes?.service_description]);

  useEffect(() => {
    if (descriptionGotChanged) {
      setDescriptionGotChanged(false);
      dispatchEvent(AnalyticEvents.ServiceView.ServiceDescribe_Success, {
        serviceId: serviceId,
        serviceType,
      });
      dispatchEvent(AnalyticEvents.ServiceView.ServiceDescribe_Latency, {
        latecny: performance.now() - startMeasureLatency,
      });
    }
  }, [descriptionGotChanged, serviceId, serviceType, startMeasureLatency]);

  return <StyledPre>{description}</StyledPre>;
};
