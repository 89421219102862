import { useMemo } from "react";
import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  JobFragment,
  useImpactfulJobEventsQuery,
} from "../../../../../../generated/graphql";
import { useDataForFlag } from "../../../../useDataForFlag";
import { useQueryWithVariables } from "../../../../useQueryWithVariables";
import { TimeWindow } from "../../../../../types/TimeWindow";
import { useResourcesAPIGet } from "../../../client";
import { WORKLOADS_JOBS_EVENTS_SEARCH } from "../../../requestResponseMaps";
import {
  apiV1WorkloadsJobsEventsSearchGetUrl,
  EventsApiApiV1WorkloadsJobsEventsSearchGetRequest,
  JobEventsResponse,
} from "../../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../../apiClient";
import { useOverridableFlags } from "../../../../../context/featureFlags/OverridableFlags";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";

const fetchJobEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1WorkloadsJobsEventsSearchGetRequest
): Promise<JobEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1WorkloadsJobsEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetImpactfulJobEvents = (
  params: EventsApiApiV1WorkloadsJobsEventsSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [WORKLOADS_JOBS_EVENTS_SEARCH, params],
    () => fetchJobEvents(apiClient, params),
    { enabled }
  );
};

export const useJobEvents = (
  showJobEvents: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">
): JobFragment[] | null => {
  const keys = useWorkspaceQueryKey([WORKLOADS_JOBS_EVENTS_SEARCH, timeWindow]);
  const apiClient = useResourcesApiClient();
  const { data } = useQuery(
    keys,
    () =>
      fetchJobEvents(apiClient, {
        fromEpoch: timeWindow.start.getTime().toString(),
        toEpoch: timeWindow.end.getTime().toString(),
        fields: [
          "id",
          "uid",
          "name",
          "eventTime",
          "status",
          "cronJobUid",
          "services",
          "action",
          "selector",
          "clusterName",
          "namespace",
          "subEvents",
        ],
      }),
    { enabled: showJobEvents === true }
  );

  return useDataForFlag(showJobEvents, convertToJobFragment(data));
};

const convertToJobFragment = (
  data: JobEventsResponse | undefined
): JobFragment[] | undefined => {
  return data?.data?.map((job) => {
    return job as JobFragment;
  });
};

export const useJobEventsByServices = (
  showJobEvents: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  serviceIds: string[]
): JobFragment[] | null => {
  const { getImpactfulJobEventsFromResourcesApi } = useOverridableFlags();

  const variables = useMemo(() => {
    if (!showJobEvents || getImpactfulJobEventsFromResourcesApi) return null;
    return {
      from: timeWindow.start.toISOString(),
      to: timeWindow.end.toISOString(),
      services: serviceIds,
    };
  }, [
    showJobEvents,
    getImpactfulJobEventsFromResourcesApi,
    timeWindow.start,
    timeWindow.end,
    serviceIds,
  ]);

  const resourcesApiVariables = useMemo(() => {
    if (!getImpactfulJobEventsFromResourcesApi) return null;
    return {
      fromEpoch: timeWindow.start.getTime().toString(),
      toEpoch: timeWindow.end.getTime().toString(),
      serviceIds: serviceIds,
    };
  }, [getImpactfulJobEventsFromResourcesApi, timeWindow, serviceIds]);

  const { data: resourcesApiData } = useGetImpactfulJobEvents(
    { ...resourcesApiVariables },
    !!getImpactfulJobEventsFromResourcesApi
  );

  const result = useQueryWithVariables(useImpactfulJobEventsQuery, variables);
  return useDataForFlag(
    showJobEvents,
    getImpactfulJobEventsFromResourcesApi
      ? convertToJobFragment(resourcesApiData)
      : result?.event_job
  );
};

export const useJobEventsByUid = (
  timeWindow: Pick<TimeWindow, "start" | "end">,
  uid: string
): JobFragment[] | undefined => {
  const { data } = useResourcesAPIGet(
    WORKLOADS_JOBS_EVENTS_SEARCH,
    {
      fromEpoch: timeWindow?.start.getTime().toString(),
      toEpoch: timeWindow?.end.getTime().toString(),
      uid: uid,
      fields: [
        "id",
        "uid",
        "name",
        "eventTime",
        "status",
        "cronJobUid",
        "services",
        "action",
        "selector",
        "clusterName",
        "namespace",
        "subEvents",
      ],
    },
    !uid
  );

  return data?.data as JobFragment[] | undefined;
};
