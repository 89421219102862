import { useMemo } from "react";

import { useMemoizedDataBreakdownPerInterval } from "../../Insights/shared/hooks/appInisghtsHooks";
import { useOverviewPageStore } from "../../store/overviewPageStore";
import { selectAppInsightsData } from "../../store/overviewPageStoreSelectors";
import { SingleOverviewMetricProps } from "../overviewMetricsTypes";
import { getFormattedDurationsFromTimestamps } from "../../Insights/shared/utils/graphUtils";
import { getBucketTimeFrame } from "../../utils/overviewPageUtils";
import { getTrendData, getTrendSign, metricsUnits } from "../utils";

export const useIssuesTrendOverview = (): SingleOverviewMetricProps => {
  const {
    availabilityIssues: { data, loading, error },
  } = useOverviewPageStore(selectAppInsightsData);

  const {
    trendPercentage = "0",
    firstTotal = 0,
    secondTotal = 0,
    isIncreased = false,
  } = useMemoizedDataBreakdownPerInterval(data?.sumByDay) ?? {};

  const { firstDuration, secondDuration, totalDuration } = useMemo(
    () => getFormattedDurationsFromTimestamps(data?.sumByDay) ?? {},
    [data?.sumByDay]
  );

  const trendSign = getTrendSign(isIncreased);
  const isEmptyState = firstTotal + secondTotal === 0;
  const title = !isEmptyState
    ? `Issues, last ${firstDuration}`
    : "No issues found";

  return useMemo(
    () => ({
      loading,
      error,
      summaryData: {
        metricUnit: metricsUnits.issues,
        title,
        mainMetric: secondTotal.toString() ?? "",
        trendData: getTrendData({
          trend: trendPercentage,
          trendSign,
          isSuccess: trendSign === "-",
        }),
        timePeriod: `vs. prev ${secondDuration}`,
        isEmptyState,
      },
      graphData: {
        title: `Trend, ${totalDuration}`,
        data: data?.sumByDay ?? [],
        bucketTimeFrame: data?.bucketTimeFrame ?? getBucketTimeFrame(),
      },
    }),
    [
      data?.bucketTimeFrame,
      data?.sumByDay,
      error,
      isEmptyState,
      loading,
      secondDuration,
      secondTotal,
      title,
      totalDuration,
      trendPercentage,
      trendSign,
    ]
  );
};
