import { Edge, Node } from "react-flow-renderer";

import { Resource } from "../ResourceView/tabs/DependenciesTab/DependenciesTab";

import { NodeData } from "./types";
import { INITIAL_NODE_ID, INITIAL_Y, INITIAL_X } from "./constants";
import { getCenterPosition } from "./utils/graphUtils";

export const getInitialGraphData = (
  resource: Resource
): { nodes: Node<NodeData>[]; edges: Edge[] } => {
  const centerPosition = getCenterPosition();

  const x = centerPosition?.x || INITIAL_X;
  const y = centerPosition?.y || INITIAL_Y;

  return {
    edges: [],
    nodes: [
      {
        id: INITIAL_NODE_ID,
        type: "customInput",
        data: {
          namespace: resource.namespace,
          cluster: resource.cluster,
          name: resource.name,
          kind: resource.kind,
          isHighlighted: true,
          connectionPoints: {
            source: false,
            target: false,
          },
          resourceDetails: {
            resourceId: resource.id,
            isHealthy: !!resource.healthy,
            replicas: {
              desiredCount: resource?.replicas?.desired || 1,
              availableCount: resource?.replicas?.ready || 1,
            },
          },
        },
        position: {
          x,
          y,
        },
        draggable: true,
      },
    ],
  };
};
