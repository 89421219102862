// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DownloadOption } from "./DownloadOptions";

export const DOWNLOAD_OPTIONS: DownloadOption[] = [
  {
    id: 1,
    text: "Mac OS Intel Chip (bin)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_darwin_amd64.tar.gz",
  },
  {
    id: 2,
    text: "Mac OS M1 Chip (bin)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_darwin_arm64.tar.gz",
  },
  {
    id: 3,
    text: "Windows x64 (.exe)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_windows_amd64.tar.gz",
  },
  {
    id: 4,
    text: "Windows x32 (.exe)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_windows_386.tar.gz",
  },
  {
    id: 5,
    text: "Linux x32 (bin)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_linux_386.tar.gz",
  },
  {
    id: 6,
    text: "Linux x64 (bin)",
    downloadLink:
      "https://github.com/komodorio/komocli/releases/download/0.0.3/komocli_0.0.3_linux_amd64.tar.gz",
  },
];

export const DOWNLOAD_BUTTON_WIDTH = "180px";

export const PORT_VALIDATION_ERROR_MESSAGE = "Please use only numbers 0-9";
