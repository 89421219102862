import axios from "axios";
import { useMemo } from "react";

import { useRequestHeaders } from "../../common-api/headers";
import { getAppConfig } from "../../../config/appConfig";

export const useMetricsApiClient = () => {
  const headers = useRequestHeaders();

  const client = useMemo(
    () =>
      axios.create({
        baseURL: getAppConfig().metricsAPIServerURL,
        headers,
      }),
    [headers]
  );

  return client;
};
