import { CRD } from "@komodorio/design-system/icons";

export const KubernetesCRDResource = {
  NameInK8S: "customresourcedefinitions",
  Group: "crds",
  NavBarName: "CRDs",
  PresentName: "Custom Resources Definitions",
  Namespaced: false,
  UnnecessaryColumns: [],
  Kind: "CustomResourceDefinition",
  Icon: CRD,
  hasLabels: false,
  ignoreNavigation: true,
  Headers: [
    { name: "Resource" },
    { name: "Cluster" },
    { name: "Group" },
    { name: "Scope" },
    { name: "Age" },
  ],
  showGroupFilter: true,
};
