import { useEffect, useState } from "react";

const useFollowingState = <T>(original: T): T => {
  const [following, setFollowing] = useState(original);
  useEffect(() => {
    setFollowing(original);
  }, [original]);
  return following;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useFollowingState;
