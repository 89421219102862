import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { StyledInfoOutlinedIcon } from "../../../styles";
import { EllipsisContainerWithTooltip } from "../../../../../../../../shared/components/EllipsisContainerWithTooltip";
import { formatPotentialSavings } from "../../../../../RightSizingPage/SummaryCards/PotentialSavings/formatPotentialSavings";
import { costOptimizationAriaLabels } from "../../../../../../constants/costOptimizationAriaLabels";

import { savingsTitles } from "./texts";

const OPACITY_40_PERCENTAGE_IN_HEX_DECIMAL = "66";
const SAVING_TOOLTIP_CONTENT =
  "Projected savings are based on the estimated costs and the estimated future usage";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 160px;
`;

const Value = styled(Typography)<{ $isBold: boolean }>`
  ${({ $isBold }) =>
    $isBold &&
    `&& {
        font-weight: 700;
      }
      `}
`;

const SavingsContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background-color: ${muiColors.teal[50]}${OPACITY_40_PERCENTAGE_IN_HEX_DECIMAL};
`;

const Title = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ValueContainer = styled.div`
  font-size: 14px;
  max-width: 160px;
`;

const {
  rightSizingPage: {
    recommendationModal: {
      savings: { monthly: monthlyAriaLabel, yearly: yearlyAriaLabel },
    },
  },
} = costOptimizationAriaLabels;

interface Props {
  label: string;
  value: string | undefined;
  isSaving?: boolean;
  tooltipContent?: string;
  serviceLink?: React.ReactNode;
  ariaLabel: string;
}

export const SummaryItem: React.FC<Props> = ({
  label,
  value,
  isSaving = false,
  tooltipContent,
  serviceLink,
  ariaLabel,
}) => {
  return (
    <ItemContainer aria-label={ariaLabel}>
      <Title>
        <Typography
          variant="body3"
          color={isSaving ? "text.primary" : "text.secondary"}
        >
          {label}
        </Typography>
        {tooltipContent && (
          <Tooltip title={tooltipContent} placement="top">
            <StyledInfoOutlinedIcon />
          </Tooltip>
        )}
      </Title>
      <ValueContainer>
        <EllipsisContainerWithTooltip tooltipValue={value ?? ""}>
          {serviceLink ??
            (isSaving ? (
              <Value
                noWrap
                variant="body2"
                color={isSaving ? muiColors.teal[700] : "text.primary"}
                $isBold={isSaving}
              >
                {value}
              </Value>
            ) : undefined)}
        </EllipsisContainerWithTooltip>
      </ValueContainer>
    </ItemContainer>
  );
};

export const Savings: React.FC<{ potentialSaving: number }> = ({
  potentialSaving,
}) => {
  return (
    <SavingsContainer>
      <SummaryItem
        ariaLabel={monthlyAriaLabel}
        label={savingsTitles.monthly}
        value={formatPotentialSavings(potentialSaving)}
        tooltipContent={SAVING_TOOLTIP_CONTENT}
        isSaving
      />
      <SummaryItem
        ariaLabel={yearlyAriaLabel}
        label={savingsTitles.yearly}
        value={formatPotentialSavings(potentialSaving * 12)}
        tooltipContent={SAVING_TOOLTIP_CONTENT}
        isSaving
      />
    </SavingsContainer>
  );
};
