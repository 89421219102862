import React from "react";
import {
  Bar,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { muiColors } from "@komodorio/design-system";

import { AvgLabel, CurrentLabel } from "../Custom/CustomLabels";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomTooltip } from "../Custom/CustomTooltip";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomLegend } from "../Custom/CustomLegend";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  AVG_LABEL,
  AVG_TEXT,
  DATE_KEY,
  LAST_VALUE_KEY,
  LAST_VALUE_LABEL,
  VALUE_KEY,
} from "./IssuesAfterDeployGraph";

export type AmountOrDuration = "amount" | "duration";

const IssuesAfterDeployGraphChart: React.FC<{
  data: { [_: string]: number | string | null }[];
  numDays: number;
  type: AmountOrDuration;
}> = ({ data, numDays, type }) => {
  return (
    <ResponsiveContainer height="100%" width="100%">
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 10,
        }}
        barSize={30}
        barGap={-30}
      >
        <Bar
          dataKey={VALUE_KEY}
          radius={[5, 5, 0, 0]}
          fill={muiColors.gray[200]}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey={AVG_TEXT}
          stroke={muiColors.gray[600]}
          dot={false}
          strokeWidth={1}
          isAnimationActive={false}
        >
          <LabelList
            dataKey={AVG_LABEL}
            content={<AvgLabel />}
            position="top"
          />
        </Line>
        <XAxis dataKey={DATE_KEY} tick={{ fontSize: 12 }}></XAxis>
        <Bar
          dataKey={LAST_VALUE_KEY}
          radius={[5, 5, 0, 0]}
          fill={muiColors.pink[300]}
          isAnimationActive={false}
        >
          <LabelList
            dataKey={LAST_VALUE_LABEL}
            position="top"
            fill="black"
            content={<CurrentLabel />}
          />
        </Bar>
        <Legend
          align="left"
          content={<CustomLegend numDays={numDays} type={type} />}
        />
        <Tooltip
          content={(props) => (
            <CustomTooltip data={props} numDays={numDays} type={type} />
          )}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default IssuesAfterDeployGraphChart;
