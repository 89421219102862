import React, { useContext, createContext, ReactNode } from "react";

import type { RowSelectionController } from "../types";

const SelectedRowsContext = createContext<RowSelectionController | null>(null);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useSelectedRowsContext = (): RowSelectionController | null =>
  useContext(SelectedRowsContext);

interface SelectedRowsProviderProps {
  rowSelectionController: RowSelectionController;
  children?: ReactNode;
}

export const SelectedRowsProvider: React.FC<SelectedRowsProviderProps> = ({
  rowSelectionController,
  children,
}) => (
  <SelectedRowsContext.Provider value={rowSelectionController}>
    {children}
  </SelectedRowsContext.Provider>
);
