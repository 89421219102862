import React from "react";
import Typography from "@mui/material/Typography";

import { AuditData } from "@/components/Audit/types";

type KubeconfigAdditionalDetails = {
  clusters?: string[];
};

export const KubeconfigAuditDetails: React.FC<{ data: AuditData }> = ({
  data,
}) => {
  const details = data.additionalDetails as KubeconfigAdditionalDetails;
  if (!details || !details.clusters) {
    return null;
  }

  return (
    <>
      <Typography variant="h4">Clusters</Typography>
      <>
        {details.clusters.map((cluster, index) => (
          <Typography variant="body2" key={cluster}>
            {cluster}
          </Typography>
        ))}
      </>
    </>
  );
};
