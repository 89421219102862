import React from "react";
import styled from "styled-components";

import { GenericErrorMessage } from "../../../../shared/components/GenericErrorMessage";

const Container = styled.div`
  margin-top: 48px;
`;

/** temp implementation */
export const ErrorContainer: React.FC = () => {
  return (
    <Container>
      <GenericErrorMessage />
    </Container>
  );
};
