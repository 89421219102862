import React, { useCallback, useRef, useState } from "react";
import { ChevronDown16, ChevronRight16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { sleep } from "../../../../../shared/utils/sleep";

const ExpandedRow = styled.div`
  display: flex;
  padding-block: 0.5rem;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`;
const ExpandedContent = styled.div`
  padding-left: 1.5rem;
`;
export const DescribeExpandedItem: React.FC<{
  title: JSX.Element;
  children: React.ReactNode;
  startExpanded?: boolean;
  chevronStyle?: React.CSSProperties;
  ariaLabel?: string;
}> = ({ title, children, startExpanded, chevronStyle, ariaLabel }) => {
  const [expanded, setExpanded] = useState(startExpanded ?? false);
  const rowRef = useRef<HTMLDivElement>(null);

  const onExpandClick = useCallback(() => {
    (async () => {
      if (!expanded) {
        await sleep(25);
        rowRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    })();
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <>
      <ExpandedRow ref={rowRef} onClick={onExpandClick} aria-label={ariaLabel}>
        {expanded ? (
          <ChevronDown16 style={chevronStyle} />
        ) : (
          <ChevronRight16 style={chevronStyle} />
        )}
        {title}
      </ExpandedRow>
      {expanded && <ExpandedContent>{children}</ExpandedContent>}
    </>
  );
};
