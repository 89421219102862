import { Record, String, Number, Boolean, Array } from "runtypes";

export const POD_EVENT_TYPE = "Pod event";

const RawContainerState = Record({
  waiting: Record({
    message: String,
    reason: String,
  }).optional(),
  terminated: Record({
    reason: String,
    message: String.optional(),
    exitCode: Number,
  }).optional(),
  running: Record({
    startedAt: String.optional(),
  }).optional(),
});

const RawContainerStatus = Record({
  name: String,
  image: String,
  ready: Boolean,
  restartCount: Number,
  state: RawContainerState,
  lastState: RawContainerState,
});
const RawContainerStatusArray = Array(RawContainerStatus);

const RawStatusConditionsArray = Array(
  Record({
    type: String,
    status: String,
  })
);

export interface StatusCondition {
  type: string;
  reason?: string;
  status: boolean;
  message?: string;
}

export enum ContainerState {
  Ready = "Ready",
  Terminated = "Terminated",
  Waiting = "Waiting",
  Unrecognized = "Unrecognized",
  Running = "Running",
}
export interface ContainerStatus {
  name: string;
  state: ContainerState;
  stateReason: string;
  ready: boolean;
  restartCount: number;
  lastState: string;
  exitCode?: number | null;
}

export const statusConditions = (statuses: unknown): StatusCondition[] => {
  return RawStatusConditionsArray.guard(statuses)
    ? statuses
        .map((s) => ({
          ...s,
          status: s.status === "True",
        }))
        .sort((e1, e2) => (e1.status ? 1 : -1))
    : [];
};

export const buildContainerStatuses = (
  containers: unknown
): ContainerStatus[] => {
  return RawContainerStatusArray.guard(containers)
    ? containers.map((c) => {
        let state = ContainerState.Ready;
        let reason = "";
        let exitCode = null;
        if (!c.ready) {
          state = ContainerState.Unrecognized;
          if (c.state?.terminated) {
            state = ContainerState.Terminated;
            reason = c.state.terminated.reason;
            exitCode = c.state.terminated.exitCode;
          } else if (c.state?.waiting) {
            state = ContainerState.Waiting;
            reason = c.state.waiting.message ?? "";
          } else if (c.state?.running) {
            state = ContainerState.Running;
          }
        }
        const lastState = c?.lastState?.terminated?.reason ?? "";
        const restartCount = c.restartCount;
        return {
          name: c.name,
          state: state,
          stateReason: reason,
          ready: !!c.ready,
          restartCount: restartCount,
          exitCode: exitCode,
          lastState: lastState,
        };
      })
    : [];
};
