import pluralize from "pluralize";

import { Dictionary } from "../../../../../shared/types/Dictionary";

import {
  CRDOptions,
  isCRDControlledResourceKind,
  OwnerMetadata,
} from "./types";

export const extractResourceTypeFromApiVersion = (
  apiVersion: string | undefined,
  kind: string | undefined
): string => {
  if (!apiVersion || !kind) {
    return "";
  }

  return `${pluralize(kind?.toLowerCase() || "")}.${apiVersion?.split("/")[0]}`;
};

export const getCustomResourceOptions = (
  ownerMetadata: OwnerMetadata | undefined
): CRDOptions => {
  const isControlledByCustomResource = isCRDControlledResourceKind(
    ownerMetadata?.kind
  );

  if (!isControlledByCustomResource) {
    return {};
  }
  const resourceType = extractResourceTypeFromApiVersion(
    ownerMetadata?.apiVersion,
    ownerMetadata?.kind
  );
  return {
    resourceType,
    namespace: "",
    existData: { isCustomResource: true },
  };
};

export type GetHelmChartName = {
  labels?: Dictionary<string>;
  annotations?: Dictionary<string>;
};
export const getHelmChartName = ({
  labels = {},
  annotations = {},
}: GetHelmChartName) => {
  if (labels["app.kubernetes.io/managed-by"] === "Helm") {
    return (
      labels["app.kubernetes.io/instance"] ??
      annotations["meta.helm.sh/release-name"] ??
      labels["release"] ??
      labels["component"] ??
      labels["app"]
    );
  }
  return undefined;
};
