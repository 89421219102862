import { compactOptions } from "./formatNumber";

const MAXIMUM_FRACTION_DIGITS = 2;

export const defaultOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  currency: "USD",
  notation: "compact",
};

export const formatCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
    style: "currency",
  }).format(value);
};

export const getFixedNumber = (number: number): number =>
  Number(number.toFixed(MAXIMUM_FRACTION_DIGITS));

export const formatCurrencyIfSmallerThanOne = (
  num: number,
  options?: Intl.NumberFormatOptions
): string =>
  num < 1
    ? " < $1"
    : formatCurrency(Math.round(num), {
        ...compactOptions,
        ...options,
      });
