/* eslint-disable max-lines */
import {
  ActionTypes,
  HelmChartSummary,
  TaskType,
  ActionMetadataHelmRepoChartVersions,
} from "komodor-types";
import { useEffect, useMemo, useState } from "react";
import { String, Array, Dictionary } from "runtypes";

import { ResourceListResponse } from "../../InspectionViews/common";
import { HelmRepoSearchResultObject } from "../../../Actions/buttons/helm/types";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { useSessionStorage } from "../../../common/useSessionStorage";
import { chartToSessionStorageKey, getChartNameAndVersion } from "../utils";
import HelmChart from "../helmChart";
import { versionGt } from "../versionUtils";

import { useHelmChartRevisions } from "./useHelmChartRevisions";
import { useHelmChartInfo } from "./useHelmChartInfo";

export const NoRepositoriesConfiguredMessage =
  "error: no repositories configured";

interface HelmChartSummaryToChartResponse {
  execute: () => void;
  data: HelmChart;
  errorMessage: string;
  isFetching: boolean;
}

interface CheckForAvailableChartsResponse {
  availableCharts: HelmRepoSearchResultObject[] | undefined;
  errorMessage: string;
  isFetching: boolean;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const ResourceList = Array(Dictionary(String, String));

export const useParseHelmChartsResponse = (
  errorMessage: string,
  fetching: boolean,
  data?: HelmChartSummary[]
): ResourceListResponse => {
  return useMemo(() => {
    const rows = ResourceList.guard(data) ? data : [];
    const result: ResourceListResponse = {
      fetching,
      emptyResult: !errorMessage && !fetching && data?.length === 0,
      errorMessage: errorMessage,
      rows: rows,
    };

    return result;
  }, [data, errorMessage, fetching]);
};

export const useHelmChartSummaryToChart = (
  chartSummary: HelmChartSummary,
  agentId: string
): HelmChartSummaryToChartResponse => {
  const { data: lastChartInfo } = useHelmChartInfo(
    agentId,
    chartSummary.cluster,
    chartSummary.namespace,
    chartSummary.secretId
  );

  const {
    data: revisions,
    isFetching,
    execute,
    errorMessage,
  } = useHelmChartRevisions({
    agentId,
    cluster: chartSummary.cluster,
    namespace: chartSummary.namespace,
    chartName: chartSummary.name,
  });

  useEffect(() => {
    if (!revisions && !isFetching && !errorMessage) {
      execute();
    }
  });

  const chart = useMemo(() => {
    return new HelmChart({
      id: chartSummary.secretId,
      cluster: chartSummary.cluster,
      name: chartSummary.name,
      namespace: chartSummary.namespace,
      agentId,
      status: chartSummary.status,
      currentRevision: chartSummary.revision,
      appVersion: chartSummary.version,
      parsedManifest: lastChartInfo?.parsedManifest,
      manifest: lastChartInfo?.manifest,
      values: lastChartInfo?.values,
      notes: lastChartInfo?.notes,
      revisions: revisions?.map((r) => ({
        revision: r?.revision,
        id: r?.secretId,
      })),
    });
  }, [
    chartSummary.secretId,
    chartSummary.cluster,
    chartSummary.name,
    chartSummary.namespace,
    chartSummary.status,
    chartSummary.revision,
    chartSummary.version,
    agentId,
    revisions,
    lastChartInfo?.parsedManifest,
    lastChartInfo?.manifest,
    lastChartInfo?.values,
    lastChartInfo?.notes,
  ]);
  return {
    execute,
    errorMessage,
    isFetching: isFetching,
    data: chart,
  };
};

export const useCheckForAvailableCharts = (
  currentChart: HelmChart
): CheckForAvailableChartsResponse => {
  const [getChartsWasSent, setGetChartsWasSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const agentId = useActiveAgent(currentChart?.cluster) ?? "";

  const sessionKey = chartToSessionStorageKey(currentChart);

  const [storedValue, setStoredValue] = useSessionStorage<
    HelmRepoSearchResultObject[] | null
  >(sessionKey, null);

  const cluster = currentChart?.cluster;
  const { name: currentChartName, version: currentChartVersion } =
    getChartNameAndVersion(currentChart.appVersion);

  const metadata: ActionMetadataHelmRepoChartVersions = {
    chartName: currentChartName,
    cluster,
    type: ActionTypes.GetHelmRepoSearch,
  };
  const { execute: getCharts, result: getChartsResult } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );

  useEffect(() => {
    if (!getChartsWasSent && !storedValue) {
      setGetChartsWasSent(true);
      getCharts();
    }
  }, [getCharts, getChartsWasSent, storedValue]);

  useEffect(() => {
    try {
      if (getChartsResult) {
        const localParsedGetChartsResult: HelmRepoSearchResultObject[] =
          JSON.parse(getChartsResult as string);

        if (localParsedGetChartsResult.length) {
          const max = localParsedGetChartsResult.reduce((prev, current) =>
            versionGt(prev.version, current.version) ? prev : current
          );

          if (versionGt(max.version, currentChartVersion)) {
            //TODO: set value for newer_version_available
          }
        }
        setStoredValue(localParsedGetChartsResult);
      }
    } catch (err) {
      if (
        (getChartsResult as string)
          ?.toLowerCase()
          ?.startsWith(NoRepositoriesConfiguredMessage)
      ) {
        setErrorMessage(getChartsResult as string);
      } else {
        setErrorMessage("Failed to get chart results");
      }
      setStoredValue([]);
    }
  }, [currentChartVersion, getChartsResult, setStoredValue]);

  return {
    availableCharts: storedValue ?? undefined,
    errorMessage,
    isFetching:
      getChartsWasSent && errorMessage === "" && getChartsResult === "",
  };
};
