import { Link, TextContainer } from "../../../violationDrawerStyles";

export const whatDescription = (
  <TextContainer>
    Detected usage of deprecated/removed APIs in your cluster.
  </TextContainer>
);

export const getWhyDescription = (
  <TextContainer>
    As the Kubernetes API evolves, APIs are periodically reorganized or
    upgraded. When APIs evolve, the old API is deprecated and eventually
    removed.
    <br />
    <br />
    As part of upgrading your Kubernetes clusters, you will need to upgrade your
    resources accordingly.
    <br />
    <br />
    Learn more at{" "}
    <Link href="https://kubernetes.io/docs/reference/using-api/deprecation-policy/">
      Kubernetes Deprecation Policy.
    </Link>
  </TextContainer>
);

export const getHowDescription = (
  <TextContainer>
    Replace the existing APIs with the suggested replacement API version. If a
    specific resource was removed, find alternative solutions using the{" "}
    <Link href="https://kubernetes.io/docs/reference/using-api/deprecation-guide/">
      Kubernetes Deprecated API Migration Guide.
    </Link>{" "}
  </TextContainer>
);
