import { useCallback } from "react";
import { AxiosError } from "axios";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

import { ServerError } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useReportError } from "@/components/reliability/hooks/useReportError";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { CheckType } from "@/generated/reliabilityApi";

export const useHandleServerErrorMessages = () => {
  const { setError, getValues } = useFormContext();
  const reportError = useReportError();
  const scrollErrorIntoView = useScrollErrorIntoView();

  return useCallback(
    (error: unknown) => {
      try {
        const { response } = error as AxiosError;
        if (response) {
          const fieldNames: string[] = [];
          const { fieldErrors } = response.data as ServerError;
          const allValueFields = getValues();
          fieldErrors?.forEach(({ field, message }) => {
            const fieldNameToUse = getServerFieldToPolicyInputField(field);

            if (get(allValueFields, fieldNameToUse)) {
              setError(fieldNameToUse, {
                type: "server",
                message,
              });
              fieldNames.push(fieldNameToUse);
            }
          });
          scrollErrorIntoView(fieldNames);
        }
      } catch (e) {
        reportError("Failed to create policy", "createPolicy");
      }
    },
    [getValues, reportError, scrollErrorIntoView, setError]
  );
};

const getServerFieldToPolicyInputField = (serverField: string) => {
  if (serverField.startsWith("scope.clusterRegexes")) {
    return "scope.clusterRegexes";
  }
  return serverField;
};

const useSetFurthestElement = () => {
  const { submitFormRef } = usePolicyDrawerContext();
  return useCallback(
    (
      element: HTMLDivElement | HTMLFormElement | null | undefined,
      acc: FurthestElementFromViewport
    ) => {
      if (!submitFormRef?.current) return acc;
      if (element) {
        const rect = element.getBoundingClientRect();
        const submitFormRect = submitFormRef.current.getBoundingClientRect();
        const submitFormY = submitFormRect.y;
        const distanceFromSubmitForm = submitFormY - rect.y;
        const previousEntryElementY = acc.elementY ?? 0;
        const previousDistanceFromSubmitForm =
          submitFormY - previousEntryElementY;
        // current element is further from submit form button than previous one
        if (distanceFromSubmitForm > previousDistanceFromSubmitForm) {
          acc = { element: element, elementY: rect.y };
          return acc;
        }
        return acc;
      }
      return acc;
    },
    [submitFormRef]
  );
};

type FurthestElementFromViewport = {
  element?: HTMLElement;
  elementY?: number;
};

const useScrollErrorIntoView = () => {
  const { checkTypeConfigurationRefs, formDetailsRef } =
    usePolicyDrawerContext();
  const setFurthestElement = useSetFurthestElement();

  return useCallback(
    (errorFieldNames: string[]) => {
      const result = errorFieldNames.reduce<FurthestElementFromViewport>(
        (acc, errorFieldName) => {
          const checkType = errorFieldName.split(".")[1] as CheckType;
          if (!checkType) {
            // error is in form details
            return setFurthestElement(formDetailsRef?.current, acc);
          }
          const checkTypeElement =
            checkTypeConfigurationRefs[checkType]?.current;

          return setFurthestElement(checkTypeElement, acc);
        },
        {}
      );

      result.element?.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    [checkTypeConfigurationRefs, formDetailsRef, setFurthestElement]
  );
};
