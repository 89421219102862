import React from "react";

import { useDrawerStackTransitionHandler } from "@/shared/context/drawersStack/useDrawerStackTransitionHandler";

type TransitionHandlerProps = {
  open: boolean;
  onClose: () => void;
  children: (
    drawerIsOpen: boolean,
    internalOnClose: () => void
  ) => React.ReactNode;
};

export const TransitionHandler: React.FC<TransitionHandlerProps> = (props) => {
  const { open, onClose, children } = props;
  const { drawerIsOpen, internalOnClose } = useDrawerStackTransitionHandler({
    open,
    onClose,
  });

  return children(drawerIsOpen, internalOnClose);
};
