import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import clusterAutoscalerImgSrc from "../../assets/Screen - Autoscaler View.png";
import { TopBar } from "../TopBar";
import nodeTerminationsDrawer from "../../assets/Drawer - Autoscaler - Termination Impact Drawer.png";
import underutilizedDrawer from "../../assets/Drawer - Underutilized.png";
import { ClickableCard, CloseButton, RelativeContainer } from "../../styles";

import { MockDrawer } from "./MockDrawer";

export const DrawerCloseButton = styled(CloseButton)`
  width: 35px;
  height: 50px;
  top: 20px;
  left: 24px;
  border-radius: 4px;
`;

export const ClusterAutoscalerView: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  return (
    <RelativeContainer>
      <TopBar page="nodeAutoscalers" />
      <img src={clusterAutoscalerImgSrc}></img>
      <ClickableCard
        width="32px"
        height="32px"
        left="1.5%"
        top="6%"
        onClick={() => {
          navigate(`../`, { relative: "path" });
        }}
      ></ClickableCard>
      <ClickableCard
        width="21.1%"
        height="6.7%"
        left="3.2%"
        top="21.4%"
        onClick={() => {
          setDrawerImgSrc(nodeTerminationsDrawer);
        }}
      />
      <ClickableCard
        width="21.1%"
        height="6.7%"
        left="25%"
        top="21.4%"
        onClick={() => {
          setDrawerImgSrc(underutilizedDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <DrawerCloseButton onClick={() => setDrawerImgSrc(null)} />
        }
      />
    </RelativeContainer>
  );
};
