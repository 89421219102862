import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { TriageDataDb } from "../../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { useInsights } from "../../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/insights/useInsights";

import { SmartTextsJoin } from "./SmartTextsJoin";

interface IssueInsightsColProps {
  issueTriageData: TriageDataDb;
}
export const IssueInsightsCol: React.FC<IssueInsightsColProps> = ({
  issueTriageData,
}) => {
  const insights = useInsights(issueTriageData);

  if (insights.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
        <LightningBolt16 color={muiColors.orange[400]} />
        <Typography variant="h5">Insight</Typography>
      </Box>
      <SmartTextsJoin
        texts={insights}
        icon={<LightningBolt16 color={muiColors.orange[400]} />}
      />
    </>
  );
};
