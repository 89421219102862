import React from "react";
import styled from "styled-components";
import { Slack as SlackIcon } from "@komodorio/design-system/icons";

import { contactMessage } from "../../../../constants";

import useSlackInstallation from "./useSlackInstallation";

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 1rem;
  padding-top: 4rem;
  text-align: center;
`;

const Message = styled.div`
  white-space: pre-line;
`;

const wrongInputMessage = `The installation was cancelled because of wrong input.\n${contactMessage}`;

const messages: Record<string, string> = {
  query: wrongInputMessage,
  input: wrongInputMessage,
  slack:
    "The installation was canceled because of an error with Slack API.\nPlease make sure the app has been installed on Slack.",
  persistence: `The installation could not be saved.\n${contactMessage}`,
};

const generateErrorMessage = (errorCode: string) =>
  messages[errorCode] || `Unknown error code: ${errorCode}.\n${contactMessage}`;

const defaultMessage =
  "Installing Slack integration...\nDo not leave this page until the installation is complete.";

const SlackInstallation: React.FC = () => {
  const errorCode = useSlackInstallation();
  const message = errorCode ? generateErrorMessage(errorCode) : defaultMessage;
  return (
    <Container>
      <SlackIcon width="5rem" height="5rem" />
      <Message>{message}</Message>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SlackInstallation;
