import { addSeconds } from "date-fns";

import { useDurationFormatter } from "@/shared/hooks/useDateFormatter";
import { formatDuration } from "@/shared/utils/dateUtils";

const ZERO_DATE = new Date(0);

export const useFormattedDuration = (durationSeconds: number) => {
  const { formatShortDuration } = useDurationFormatter();

  return formatDuration(
    formatShortDuration,
    ZERO_DATE,
    addSeconds(ZERO_DATE, durationSeconds)
  );
};
