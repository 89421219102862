import { MouseEvent, useCallback } from "react";

import { reliabilityArialLabels } from "../../../../../../../../reliabilityArialLabels";

const viewDetailsArialLabel =
  reliabilityArialLabels.violationDrawer.metricsGraphs.deployEvent.viewDetails;

export const useIsViewDeployEventDetailsClicked = () => {
  return useCallback((e: MouseEvent) => {
    return (e.target as HTMLElement).ariaLabel === viewDetailsArialLabel;
  }, []);
};
