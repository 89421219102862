import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useClearSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearchParams = useCallback(
    (keys: string[]) => {
      const cleanSearchParams = [];
      for (const [key, value] of searchParams.entries()) {
        if (!keys.includes(key)) {
          cleanSearchParams.push([key, value]);
        }
      }
      setSearchParams(new URLSearchParams(cleanSearchParams));
    },
    [searchParams, setSearchParams]
  );

  return clearSearchParams;
};
