import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";
import MonetizationOnOutlined from "@mui/icons-material/MonetizationOnOutlined";
import Typography from "@mui/material/Typography";
import { sortBy } from "lodash";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { ContentBox, LoaderContainer, SectionAccordion } from "../../styles";
import { COST_OPTIMIZATION_ROUTE } from "../../../../routes/routes";
import { useGetAllocationSummary } from "../../../../../shared/hooks/metrics-api/client/cost/useGetAllocationSummary";
import { useAllocationTableColumns } from "../../../../CostOptimizationView/components/AllocationPage/AllocationTable/allocationTableHooks";
import { AllocationTableColumns } from "../../../../CostOptimizationView/types/tableTypes";
import { EmptyState } from "../../../../common/UpgradeAgentVersionAlert/EmptyState";
import { SectionAccordionSummary } from "../SectionAccordionSummary";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

import {
  COST_SECTION_EMPTY_MESSAGE,
  costSectionTableColumns,
  costSectionTableTitle,
  COST_SECTION_TITLE,
} from "./constants";
import { StatsSummary } from "./StatsSummary";
import { Stats } from "./Stats";

const toDate = new Date();
toDate.setUTCDate(toDate.getUTCDate() - 1);
toDate.setUTCHours(23, 59, 59, 999);

const fromDate = new Date();
fromDate.setUTCDate(fromDate.getUTCDate() - 30);
fromDate.setUTCHours(0, 0, 0, 0);

export const CostSection: React.FC<{
  clusters: string[];
}> = ({ clusters }) => {
  const isSingleCluster = clusters.length === 1;

  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const {
    data,
    isFetching: isLoadingAllocationSummary,
    isInitialLoading,
  } = useGetAllocationSummary(
    {
      scope: { clusters },
      fromEpoch: fromDate.valueOf(),
      toEpoch: toDate.valueOf(),
      groupBy: isSingleCluster ? "namespace" : "clusterName",
    },
    { keepPreviousData: true }
  );
  // useStoreIsFetchingForReport("isFetchingCostOptimization", isLoadingAllocationSummary); // comment out until cost timing will improve

  const top3ByPotentialSavings = useMemo(
    () => sortBy(data?.rows, (r) => -r.potentialSaving).slice(0, 3),
    [data?.rows]
  );
  const allColumns = useAllocationTableColumns(top3ByPotentialSavings);
  const columns = useMemo(
    () =>
      allColumns.filter((c) =>
        costSectionTableColumns(isSingleCluster).includes(
          c.field as AllocationTableColumns
        )
      ),
    [allColumns, isSingleCluster]
  );

  const isEmpty = !isInitialLoading && !data?.rows.length;
  const isDisabled = !isLoadingAllocationSummary && isEmpty;
  const isExpanded = !isEmpty && expanded;

  return (
    <SectionAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={isDisabled}
    >
      <SectionAccordionSummary
        title={COST_SECTION_TITLE}
        icon={<MonetizationOnOutlined sx={{ color: muiColors.gray[500] }} />}
        isExpanded={isExpanded}
        isLoading={isLoadingAllocationSummary}
        isEmpty={isEmpty}
        emptyMessage={COST_SECTION_EMPTY_MESSAGE}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", columnGap: "16px" }}>
            <Typography variant="body2">Last 30 days</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigate(COST_OPTIMIZATION_ROUTE);
              }}
              aria-label={AriaLabels.ClusterOverview.CostSection.ViewAllButton}
            >
              View All
            </Button>
          </Box>
          <StatsSummary data={data} isLoading={isLoadingAllocationSummary} />
        </Box>
      </SectionAccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: "16px",
          }}
        >
          <Stats data={data} isLoading={isLoadingAllocationSummary} />
          <ContentBox
            title={costSectionTableTitle(isSingleCluster)}
            padding="16px"
          >
            {isLoadingAllocationSummary ? (
              <LoaderContainer>
                <LinesLoader />
              </LoaderContainer>
            ) : !top3ByPotentialSavings.length ? (
              <Box sx={{ height: "206px" }}>
                <EmptyState title="This data is unavailable" />
              </Box>
            ) : (
              <DataGridPro
                rows={top3ByPotentialSavings}
                columns={columns}
                autoHeight={false}
                hideFooter
                sx={{ height: "unset", border: "none" }}
                disableVirtualization
              />
            )}
          </ContentBox>
        </Box>
      </AccordionDetails>
    </SectionAccordion>
  );
};
