import { useCallback, useEffect, useMemo } from "react";

import { generateMockCostAllocationSummary } from "../../../__tests__/mockData/mockCostAllocationSummary";
import { AllocationFiltersState } from "../../../types/costOptimizationTypes";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectAllocationFilters,
  selectSetAllocationMetricsState,
  selectSetAllocationSummaryState,
  selectLastVisitTime,
  selectSetAllocationFilters,
  selectAllocationSummaryState,
  selectAllocationMetricsState,
  selectAllocationStatsState,
  selectSetAllocationStatsState,
} from "../../../store/costOptimizationStoreSelectors";
import { generateMockCostAllocationMetrics } from "../../../__tests__/mockData/mockCostAllocationMetrics";
import { generateMockCostAllocationStats } from "../../../__tests__/mockData/mockCostAllocationStats";
import {
  COST_ALLOCATION_METRICS_ENDPOINT,
  COST_ALLOCATION_SUMMARY_ENDPOINT,
  COST_ALLOCATION_STATS_ENDPOINT,
} from "../../../../../shared/hooks/metrics-api/requestResponseMaps";
import {
  AllocationCostDataPoints,
  CostAllocationSummaryResponse,
  CostAllocationStatsResponse,
} from "../../../../../generated/metricsApi";
import {
  getEpochFromTimeFrame,
  shouldUpdateStoreWithResponse,
} from "../../../utils/costOptimizationUtils";
import { useCostOptimizationDataInUrl } from "../../../hooks/persistentStateHooks";
import { useScopeAsServerRequestParam } from "../../../hooks/useScopeAsServerRequestParam";
import { useShouldPauseRequest } from "../../../hooks/useShouldPauseRequest";
import { isDevMode } from "../../../../../shared/utils/isDevMode";
import { useMetricsApiWithRefreshLogic } from "../../../hooks/useMetricsApiWithRefreshLogic";
import { useSetMockDataEffect } from "../../../hooks/useSetMockDataEffect";

export const useFetchAllocationDataOnFiltersChange = (): void => {
  useFetchAllocationSummaryData();
  useFetchAllocationStatsData();
  useFetchAllocationMetricsData();
};

const useAllocationEpochs = () => {
  const allocationFilters = useCostOptimizationStore(selectAllocationFilters);
  const lastVisitTime = useCostOptimizationStore(selectLastVisitTime);
  const { timeWindow } = allocationFilters;
  return getEpochFromTimeFrame(timeWindow, lastVisitTime);
};

export const useFetchAllocationSummaryData = (): void => {
  const allocationFilters = useCostOptimizationStore(selectAllocationFilters);
  const scope = useScopeAsServerRequestParam();
  const setAllocationSummaryState = useCostOptimizationStore(
    selectSetAllocationSummaryState
  );
  const currentAllocationSummaryState = useCostOptimizationStore(
    selectAllocationSummaryState
  );

  const { groupBy } = allocationFilters;
  const { fromEpoch, toEpoch } = useAllocationEpochs();
  const shouldPause = useShouldPauseRequest();

  const params = useMemo(
    () => ({
      fromEpoch,
      toEpoch,
      groupBy,
      scope,
    }),
    [groupBy, scope, fromEpoch, toEpoch]
  );

  // simulate server responses
  const mockCostAllocationSummaryResponse = useMemo(
    () =>
      isDevMode()
        ? generateMockCostAllocationSummary({
            groupBy: params.groupBy,
            scope: params.scope,
          })
        : undefined,
    [params]
  );

  const res = useSetMockDataEffect<CostAllocationSummaryResponse>({
    mockData: mockCostAllocationSummaryResponse,
    requestParams: params,
  });
  /////////////////////////////

  const serverRes =
    useMetricsApiWithRefreshLogic<CostAllocationSummaryResponse>(
      COST_ALLOCATION_SUMMARY_ENDPOINT,
      params,
      shouldPause
    );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    if (
      shouldUpdateStoreWithResponse({
        serverRes: dataToUse,
        currentState: currentAllocationSummaryState,
        params,
      })
    ) {
      setAllocationSummaryState(dataToUse);
    }
  }, [
    currentAllocationSummaryState,
    dataToUse,
    params,
    serverRes,
    serverRes.data,
    setAllocationSummaryState,
  ]);
};

export const useFetchAllocationMetricsData = (): void => {
  const allocationFilters = useCostOptimizationStore(selectAllocationFilters);
  const scope = useScopeAsServerRequestParam();
  const currentAllocationMetricsState = useCostOptimizationStore(
    selectAllocationMetricsState
  );
  const setAllocationMetricsState = useCostOptimizationStore(
    selectSetAllocationMetricsState
  );

  const { timeWindow, groupBy } = allocationFilters;
  const { fromEpoch, toEpoch } = useAllocationEpochs();
  const shouldPause = useShouldPauseRequest();

  const params = useMemo(
    () => ({
      fromEpoch,
      toEpoch,
      groupBy,
      scope,
    }),
    [fromEpoch, groupBy, scope, toEpoch]
  );

  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode() && scope
        ? generateMockCostAllocationMetrics(timeWindow, groupBy)
        : undefined,
    [groupBy, scope, timeWindow]
  );

  const res = useSetMockDataEffect<AllocationCostDataPoints>({
    mockData: mockResponse,
    requestParams: params,
  });
  /////////////////////////////

  const serverRes = useMetricsApiWithRefreshLogic<AllocationCostDataPoints>(
    COST_ALLOCATION_METRICS_ENDPOINT,
    params,
    shouldPause
  );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    if (
      shouldUpdateStoreWithResponse({
        serverRes: dataToUse,
        currentState: currentAllocationMetricsState,
        params,
      })
    ) {
      setAllocationMetricsState(dataToUse);
    }
  }, [
    currentAllocationMetricsState,
    dataToUse,
    params,
    setAllocationMetricsState,
  ]);
};

export const useAllocationSetFilterState = () => {
  const [dataInUrl, setDataInUrl] = useCostOptimizationDataInUrl();
  const setAllocationFilters = useCostOptimizationStore(
    selectSetAllocationFilters
  );

  return useCallback(
    (
      filterKey: keyof AllocationFiltersState,
      value: AllocationFiltersState[typeof filterKey]
    ) => {
      setAllocationFilters({
        [filterKey]: value,
      });
      setDataInUrl({
        allocationFilters: {
          ...dataInUrl.allocationFilters,
          [filterKey]: value,
        },
      });
    },
    [dataInUrl.allocationFilters, setAllocationFilters, setDataInUrl]
  );
};

export const useFetchAllocationStatsData = () => {
  const scope = useScopeAsServerRequestParam();
  const currentAllocationStatsState = useCostOptimizationStore(
    selectAllocationStatsState
  );
  const setAllocationStatsState = useCostOptimizationStore(
    selectSetAllocationStatsState
  );

  const { fromEpoch, toEpoch } = useAllocationEpochs();
  const shouldPause = useShouldPauseRequest();

  const params = useMemo(
    () => ({
      fromEpoch,
      toEpoch,
      scope,
    }),
    [fromEpoch, scope, toEpoch]
  );

  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode() && scope ? generateMockCostAllocationStats() : undefined,
    [scope]
  );

  const res = useSetMockDataEffect<CostAllocationStatsResponse>({
    mockData: mockResponse,
    requestParams: params,
  });
  /////////////////////////////

  const serverRes = useMetricsApiWithRefreshLogic<CostAllocationStatsResponse>(
    COST_ALLOCATION_STATS_ENDPOINT,
    params,
    shouldPause
  );

  const dataToUse = isDevMode() ? res : serverRes;

  useEffect(() => {
    if (
      shouldUpdateStoreWithResponse({
        serverRes: dataToUse,
        currentState: currentAllocationStatsState,
        params,
      })
    ) {
      setAllocationStatsState(dataToUse);
    }
  }, [currentAllocationStatsState, dataToUse, params, setAllocationStatsState]);
};
