import {
  LivenessProbesMissingBestPracticesResponse,
  MissingRequestsOrLimitsBestPracticesResponse,
  SecurityBestPracticesResponse,
} from "../../../../../../../generated/applicationInsights";
import { getRandomInt } from "../../../../../../../shared/utils/helpers";

import { mockKomodorServices } from "./mockKomodorServices";

const getRandomServicesNum = () => Math.round(getRandomInt(2, 20));

export const mockBestPracticesLivenessProbesMissing =
  (): LivenessProbesMissingBestPracticesResponse => {
    const mocksServices = mockKomodorServices(getRandomServicesNum());
    return {
      rows: [...mocksServices],
    };
  };

export const mockBestPracticesMissingRequestsOrLimits =
  (): MissingRequestsOrLimitsBestPracticesResponse => {
    const mocksServices = mockKomodorServices(getRandomServicesNum());
    return {
      rows: [...mocksServices],
    };
  };

export const mockBestPracticesSecurity = (): SecurityBestPracticesResponse => {
  const mocksServices = mockKomodorServices(getRandomServicesNum());
  return {
    rows: mocksServices.map((service) => ({
      ...service,
      criticalIssues: Math.round(Math.random() * 5),
      warnings: Math.round(Math.random() * 10),
    })),
  };
};
