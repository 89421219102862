import styled from "styled-components";

import Table from "../../common/table/Table";
import { textStyle } from "../../common/typography";
import { gray10, gray5, gray13 } from "../../../Colors";

export const StyledTable = styled(Table)`
  th {
    border-bottom: 1px solid ${gray5};
    z-index: 0;
  }
`;

export const Container = styled.div`
  background: white;
  overflow-x: auto;
  border: 1px solid ${gray13};
  border-radius: 4px;
`;

export const TableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
  gap: 3rem;
`;

export const StyledText = styled.div`
  ${textStyle}
  color: ${gray10}
`;
