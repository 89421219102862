import { useMemo } from "react";

import useUserMetadata from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { buildServiceId } from "../../../../../shared/utils/serviceHelpers";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import { groupWorkflowIssuesEvents } from "../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WORKFLOW_CONFIG_TYPES } from "../../../EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../../monitorsView/common/types";
import { useGetMonitorIssues } from "../../../../../shared/hooks/monitors-api/client/issues/useGetMonitorIssues";
import { MonitorType } from "../../../../../generated/monitorsApi";

import type PodPhaseGroup from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";

export const useGetCorrelatedNodeIssues = (
  podPhaseGroup: PodPhaseGroup,
  timeWindow: Pick<TimeWindow, "start" | "end">
) => {
  const { accountName } = useUserMetadata();

  const serviceIds = useMemo(
    () => [
      buildServiceId(
        accountName,
        podPhaseGroup.clusterName,
        podPhaseGroup.namespace,
        podPhaseGroup.podName
      ),
    ],
    [accountName, podPhaseGroup]
  );

  const { data: monitorIssues, isLoading } = useGetMonitorIssues({
    types: [MonitorType.Node],
    fields: [
      "id",
      "type",
      "shortResourceName",
      "sourceEventId",
      "closeReason",
      "closedAt",
      "services",
      "workflowConfiguration",
      "resultsSummary",
      "cluster",
      "namespace",
      "eventTime",
      "reasons",
    ],
    includeOpen: true,
    includeClose: true,
    fromEpoch: timeWindow?.start.getTime().toString(),
    toEpoch: timeWindow?.end.getTime().toString(),
  });

  const issues = useMemo(() => monitorIssues?.data, [monitorIssues?.data]);

  const issuesEventGroups = groupWorkflowIssuesEvents(
    issues?.flatMap((e) => e) ?? [],
    serviceIds[0]
  );
  const nodeIssues = issuesEventGroups.filter(
    (issue) =>
      issue.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.NodeIssue]
  );
  return {
    nodeIssues,
    isLoading,
  };
};
