import { useMemo } from "react";

import { useActiveAgent } from "@/shared/hooks/useAgents";
import useResourceWithInterval from "@/components/ResourceView/useResource";
import { IResourceDrawerByData } from "@/components/ResourceView/types";
import { DrawerProps } from "@/shared/store/drawersStackStore/types";

export type ResourceDrawerByDataProps = DrawerProps & IResourceDrawerByData;

type UseResourceByDataProps = Pick<
  ResourceDrawerByDataProps,
  | "cluster"
  | "namespace"
  | "resourceType"
  | "resourceName"
  | "additionalData"
  | "buildPreloadResource"
>;

export const useResourceByData = ({
  cluster,
  namespace,
  resourceType,
  resourceName,
  additionalData,
  buildPreloadResource,
}: UseResourceByDataProps) => {
  const existData = useMemo(
    () =>
      buildPreloadResource && additionalData
        ? additionalData.isCustomResource
          ? additionalData
          : {
              kind: resourceType,
              cluster,
              metadata: {
                id: additionalData.id,
                name: resourceName,
                namespace,
                ownerReferences: [{ name: additionalData.controlledBy }],
              },
              calculatedStatus: additionalData.calculatedStatus,
            }
        : undefined,
    [
      additionalData,
      buildPreloadResource,
      cluster,
      namespace,
      resourceName,
      resourceType,
    ]
  );
  const agentId = useActiveAgent(cluster) ?? "";

  return useResourceWithInterval({
    agentId,
    cluster,
    namespace,
    resourceName,
    resourceType,
    existData,
  });
};
