import React from "react";
import { capitalize } from "lodash";
import Tooltip from "@mui/material/Tooltip";

import { CertificateStatusEnum, StatusCondition } from "@/generated/addonsApi";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";

export const ResourceStatus: React.FC<{
  status: StatusCondition | undefined;
}> = ({ status }) => {
  if (!status?.status) return null;

  return (
    <Tooltip title={status.message} placement={"top"}>
      <div>
        <AddonStatusChip
          color={
            certificateStatusToColor[status.status as CertificateStatusEnum] ??
            certificateStatusToColor["unknown"]
          }
          label={capitalize(status.status)}
        />
      </div>
    </Tooltip>
  );
};
