/* eslint-disable max-lines */
import { useEffect, useMemo } from "react";

import {
  IngressFragment,
  Kubernetes_Service_ResourceFragment,
  SecretFragment,
} from "../../../generated/graphql";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { relatedResources } from "../../ResourceView/tabs/EventsTab/content/RelatedResourcesSelector";
import {
  KubernetesHPAResource,
  KubernetesPodsResource,
  KubernetesResource,
  KubernetesConfigMapResource,
  KubernetesServiceResource,
  KubernetesIngressResource,
  KubernetesSecretsResource,
} from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import {
  ConfigMapEventsResponseDataInner,
  HpaEventsResponseDataInner,
  PodGroups,
} from "../../../generated/resourcesApi";
import { buildKomodorUid } from "../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetGroupedPodEvents } from "../../../shared/hooks/resources-api/client/events/useGetGroupedPodEvents";
import { setFetchingStateSelector } from "../../../shared/store/resourceViewStore/resourceViewSelectors";
import { useResourceViewStore } from "../../../shared/store/resourceViewStore/resourceViewStore";
import { NativePodFragment } from "../EventGroup/nativePodEvent/types";

import {
  isConfig_MapFragments,
  isHpaEventsResponseDataInners,
  isNativePodFragments,
  isKubernetes_Service_ResourceFragments,
  isIngressFragments,
  isSecretFragments,
} from "./typeGuards";

export const useRelatedResourcesEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
) => {
  const {
    nativePodEvents,
    isFetchingNativePodEvents,
    groupedNativePodEvents,
    isFetchingGroupedNativePodEvents,
  } = useNativePodEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [hpaEvents, isFetchingHpaEvents] = useHpaEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [configMapEvents, isFetchingConfigMapEvents] = useConfigMapsEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [kubernetesServiceEvents, isFetchingK8sServiceEvents] =
    useKubernetesServicesEvents(
      selectedResourceNamesForEvents,
      clusterName,
      namespace,
      timeWindow,
      resourceViewKind
    );

  const [ingressEvents, isFetchingIngressEvents] = useIngressEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const [secretEvents, isFetchingSecretEvents] = useSecretsEvents(
    selectedResourceNamesForEvents,
    clusterName,
    namespace,
    timeWindow,
    resourceViewKind
  );

  const setFetchingState = useResourceViewStore(setFetchingStateSelector);

  const isFetchingRelatedResourcesEvents =
    isFetchingNativePodEvents ||
    isFetchingGroupedNativePodEvents ||
    isFetchingHpaEvents ||
    isFetchingConfigMapEvents ||
    isFetchingK8sServiceEvents ||
    isFetchingIngressEvents ||
    isFetchingSecretEvents;

  useEffect(() => {
    setFetchingState({
      key: "isFetchingRelatedResourcesEvents",
      value: isFetchingRelatedResourcesEvents,
    });
  }, [isFetchingRelatedResourcesEvents, setFetchingState]);

  return useMemo(
    () => ({
      nativePodEvents,
      groupedNativePodEvents,
      hpaEvents,
      configMapEvents,
      kubernetesServiceEvents,
      ingressEvents,
      secretEvents,
    }),
    [
      nativePodEvents,
      groupedNativePodEvents,
      hpaEvents,
      configMapEvents,
      kubernetesServiceEvents,
      ingressEvents,
      secretEvents,
    ]
  );
};

const useResourceEventsFetch = (
  resourceType: KubernetesResource,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end"> | undefined,
  selectedResourceNames: string[] | undefined,
  disableFetch?: boolean
): [unknown[] | undefined, boolean] => {
  const useFetchRelatedResourceEvents =
    relatedResources.get(resourceType)?.useFetchEvents ??
    (() => [undefined, false]);

  return useFetchRelatedResourceEvents(
    selectedResourceNames,
    clusterName,
    namespace,
    timeWindow,
    disableFetch
  );
};

const useNativePodEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): {
  nativePodEvents: NativePodFragment[];
  isFetchingNativePodEvents: boolean;
  groupedNativePodEvents: PodGroups[];
  isFetchingGroupedNativePodEvents: boolean;
} => {
  const { podEventsInServiceView, groupPodsByPhases } = useOverridableFlags();

  const podsSearchParamsKey = relatedResources
    .get(KubernetesPodsResource)
    ?.searchParamKey(resourceViewKind);

  const selectedPodNames = useMemo(
    () => selectedResourceNamesForEvents?.[podsSearchParamsKey ?? ""] ?? [],
    [podsSearchParamsKey, selectedResourceNamesForEvents]
  );

  const [podEventsFromPg, isFetchingNativePodEvents] = useResourceEventsFetch(
    KubernetesPodsResource,
    clusterName,
    namespace,
    timeWindow,
    selectedPodNames,
    !!groupPodsByPhases
  );

  const {
    data: groupedPodEventsData,
    isFetching: isFetchingGroupedNativePodEvents,
  } = useGetGroupedPodEvents(
    {
      komodorUids: selectedPodNames.map((podName) => {
        const [, , name] = podName.split(";");
        return buildKomodorUid({
          kind: "Pod",
          clusterName: clusterName ?? "",
          namespace: namespace ?? "",
          resourceName: name ?? "",
        });
      }),
      fromEpoch: timeWindow.start.valueOf(),
      toEpoch: timeWindow.end.valueOf(),
    },
    !!groupPodsByPhases &&
      !!clusterName &&
      !!namespace &&
      !!selectedPodNames.length
  );

  return useMemo(
    () => ({
      nativePodEvents:
        !podEventsInServiceView ||
        !podEventsFromPg ||
        groupPodsByPhases ||
        !isNativePodFragments(podEventsFromPg)
          ? []
          : podEventsFromPg,
      isFetchingNativePodEvents,
      groupedNativePodEvents:
        groupPodsByPhases && groupedPodEventsData?.data
          ? groupedPodEventsData.data
          : [],
      isFetchingGroupedNativePodEvents,
    }),
    [
      groupPodsByPhases,
      groupedPodEventsData?.data,
      isFetchingGroupedNativePodEvents,
      isFetchingNativePodEvents,
      podEventsFromPg,
      podEventsInServiceView,
    ]
  );
};

const useHpaEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): [HpaEventsResponseDataInner[], boolean] => {
  const { hpaEventsInRelatedResources } = useOverridableFlags();

  const hpaSearchParamsKey = relatedResources
    .get(KubernetesHPAResource)
    ?.searchParamKey(resourceViewKind);

  const selectedHpaNames = useMemo(
    () => selectedResourceNamesForEvents?.[hpaSearchParamsKey ?? ""] ?? [],
    [hpaSearchParamsKey, selectedResourceNamesForEvents]
  );

  const [hpaEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesHPAResource,
    clusterName,
    namespace,
    timeWindow,
    selectedHpaNames
  );

  return useMemo(() => {
    return [
      !hpaEventsInRelatedResources ||
      !hpaEventsFromPg ||
      !isHpaEventsResponseDataInners(hpaEventsFromPg)
        ? []
        : hpaEventsFromPg,
      isFetching,
    ];
  }, [hpaEventsFromPg, hpaEventsInRelatedResources, isFetching]);
};

const useConfigMapsEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): [ConfigMapEventsResponseDataInner[], boolean] => {
  const configMapsSearchParamKey = relatedResources
    .get(KubernetesConfigMapResource)
    ?.searchParamKey(resourceViewKind);

  const selectedConfigMapNames = useMemo(
    () =>
      selectedResourceNamesForEvents?.[configMapsSearchParamKey ?? ""] ?? [],
    [configMapsSearchParamKey, selectedResourceNamesForEvents]
  );

  const [configMapEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesConfigMapResource,
    clusterName,
    namespace,
    timeWindow,
    selectedConfigMapNames
  );

  return useMemo(() => {
    return [
      !configMapEventsFromPg || !isConfig_MapFragments(configMapEventsFromPg)
        ? []
        : configMapEventsFromPg,
      isFetching,
    ];
  }, [configMapEventsFromPg, isFetching]);
};

const useKubernetesServicesEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): [Kubernetes_Service_ResourceFragment[], boolean] => {
  const kubernetesServiceSearchParamKey = relatedResources
    .get(KubernetesServiceResource)
    ?.searchParamKey(resourceViewKind);

  const selectedKubernetesServiceNames = useMemo(
    () =>
      selectedResourceNamesForEvents?.[kubernetesServiceSearchParamKey ?? ""] ??
      [],
    [kubernetesServiceSearchParamKey, selectedResourceNamesForEvents]
  );

  const [kubernetesServicesEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesServiceResource,
    clusterName,
    namespace,
    timeWindow,
    selectedKubernetesServiceNames
  );

  return useMemo(() => {
    return [
      !kubernetesServicesEventsFromPg ||
      !isKubernetes_Service_ResourceFragments(kubernetesServicesEventsFromPg)
        ? []
        : kubernetesServicesEventsFromPg,
      isFetching,
    ];
  }, [isFetching, kubernetesServicesEventsFromPg]);
};

const useIngressEvents = (
  selectedResourceNamesForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "end" | "start">,
  resourceViewKind: string
): [IngressFragment[], boolean] => {
  const ingressSearchParamKey = relatedResources
    .get(KubernetesIngressResource)
    ?.searchParamKey(resourceViewKind);

  const selectedIngressesNames = useMemo(
    () => selectedResourceNamesForEvents?.[ingressSearchParamKey ?? ""] ?? [],
    [ingressSearchParamKey, selectedResourceNamesForEvents]
  );

  const [ingressesEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesIngressResource,
    clusterName,
    namespace,
    timeWindow,
    selectedIngressesNames
  );

  return useMemo(() => {
    return [
      !ingressesEventsFromPg || !isIngressFragments(ingressesEventsFromPg)
        ? []
        : ingressesEventsFromPg,
      isFetching,
    ];
  }, [ingressesEventsFromPg, isFetching]);
};

const useSecretsEvents = (
  selectedResourceUidsForEvents: { [key: string]: string[] } | undefined,
  clusterName: string | undefined,
  namespace: string | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end">,
  resourceViewKind: string
): [SecretFragment[], boolean] => {
  const secretUidsSearchParamKey = relatedResources
    .get(KubernetesSecretsResource)
    ?.searchParamKey(resourceViewKind);

  const selectedSecretsNames = useMemo(
    () => selectedResourceUidsForEvents?.[secretUidsSearchParamKey ?? ""] ?? [],
    [secretUidsSearchParamKey, selectedResourceUidsForEvents]
  );

  const [secretEventsFromPg, isFetching] = useResourceEventsFetch(
    KubernetesSecretsResource,
    clusterName,
    namespace,
    timeWindow,
    selectedSecretsNames
  );

  return useMemo(() => {
    return [
      !secretEventsFromPg || !isSecretFragments(secretEventsFromPg)
        ? []
        : secretEventsFromPg,
      isFetching,
    ];
  }, [isFetching, secretEventsFromPg]);
};
