import Button from "@mui/material/Button";
import React from "react";

interface Props {
  setHide: () => void;
  ariaLabel: string;
}

export const PermanentHideAlert: React.FC<Props> = ({ setHide, ariaLabel }) => {
  return (
    <Button onClick={setHide} aria-label={ariaLabel}>
      Don’t show again
    </Button>
  );
};
