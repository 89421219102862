import { useMemo } from "react";
import {
  ActionMetadataUpdateContainersResources,
  ActionTypes,
  Container,
  TaskType,
} from "komodor-types";
import pluralize from "pluralize";
import { isUndefined } from "lodash";

import { CostRightSizingSummary } from "../../../../../../generated/metricsApi";
import { useActiveAgent } from "../../../../../../shared/hooks/useAgents";
import useAgentTask from "../../../../../../shared/hooks/useAgentTask/useAgentTask";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";
import { Scalar } from "../../../../../../shared/utils/numbers/quantityToScalar";
import { ContainersValues } from "../types";

const formatResource = (
  resource:
    | {
        memory?: Scalar;
        cpu?: Scalar;
      }
    | undefined
) => {
  if (isUndefined(resource)) return undefined;
  return {
    ...(resource.memory && {
      //add Mib suffix
      memory: `${Number(resource.memory)}Mi`,
    }),
    ...(resource.cpu && {
      //add millicores suffix
      cpu: `${Number(resource.cpu)}m`,
    }),
  };
};

const parseContaienrs = (containers: ContainersValues[]): Container[] => {
  return containers.map((container) => {
    return {
      ...container,
      resources: {
        ...(container.resources.limits && {
          limits: formatResource(container.resources.limits),
        }),
        ...(container.resources.requests && {
          requests: formatResource(container.resources.requests),
        }),
      },
    };
  });
};

export const useExecuteUpdateResources = (
  data: CostRightSizingSummary | undefined
) => {
  const { containers } = useRecommendationsModalContext();
  const {
    clusterName,
    namespace,
    service,
    komodorServiceKind,
    komodorServiceId,
  } = data ?? {};
  const agentId = useActiveAgent(clusterName) ?? "";

  const metadata = useMemo<ActionMetadataUpdateContainersResources>(
    () => ({
      cluster: clusterName ?? "",
      namespace: namespace ?? "",
      serviceId: komodorServiceId ?? "",
      resourceType: pluralize.singular(komodorServiceKind ?? ""),
      resourceName: service ?? "",
      type: ActionTypes.UpdateContainersResources,
      containers: JSON.stringify(parseContaienrs(containers)),
    }),
    [
      clusterName,
      containers,
      komodorServiceId,
      komodorServiceKind,
      namespace,
      service,
    ]
  );

  return useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);
};
