import React, { useState } from "react";

import EditModal from "../modals/EditModal/EditModal";

import { ActionButtonProps } from "./types";

import Action from ".";

export const EditButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setIsActionActive?.(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsActionActive?.(false);
  };

  return (
    <>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={handleClick}
        Button={Button}
      >
        <EditModal
          open={open}
          handleClose={handleClose}
          agentId={resource.agentId}
          resourceName={resource.name}
          resourceType={resource.kind}
          namespace={resource.namespace}
          cluster={resource.cluster}
        />
      </Action>
    </>
  );
};
