import { User } from "@auth0/auth0-react";
export interface desktopUserCredentials {
  mail: string;
  id: string;
  password: string;
}

interface desktopUser extends User {
  email: string;
  name: string;
  nickname: string;
  sub: string;
  password: string;
  id: string;
}

const formatElectronUserData = (
  electronUserData: desktopUserCredentials | undefined
): desktopUser | undefined => {
  if (!electronUserData) {
    return undefined;
  }
  const userName = electronUserData.mail.split("@")[0];
  return {
    email: electronUserData.mail,
    password: electronUserData.password,
    id: electronUserData.id,
    name: userName,
    nickname: userName,
    sub: `desktopUser | ${electronUserData.mail}`,
  };
};

const getDesktopUserCredentials = async (): Promise<
  desktopUser | undefined
> => {
  const ipcResult = await window.electronAPI?.getCredentials();
  const formattedIpcResult = formatElectronUserData(ipcResult);
  return formattedIpcResult;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default getDesktopUserCredentials;
