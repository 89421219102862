import { useCallback, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

import { CommonApiResponse } from "../../../shared/types/commonApiResponse";
import { initialFetchState } from "../../../shared/hooks/metrics-api/mockServerRes";
import { isDevMode } from "../../../shared/utils/isDevMode";
import { getRandomInt } from "../../../shared/utils/helpers";

import { useHasClustersScope } from "./useShouldPauseRequest";
import { useRefreshCallLogic } from "./useMetricsApiWithRefreshLogic";

type SetMockDataEffectParams<T extends object> = {
  mockData?: T;
  requestParams: object;
};

export const useSetMockDataEffect = <T extends object>({
  mockData,
  requestParams,
}: SetMockDataEffectParams<T>) => {
  const [res, setRes] = useState<CommonApiResponse<T>>(initialFetchState);
  const hasClustersScope = useHasClustersScope();
  const lastRequestParams = useRef<typeof requestParams>();
  const shouldExecute = isDevMode() && hasClustersScope;

  const generateApiFetch = useCallback(() => {
    if (!shouldExecute) return;
    setRes({
      ...initialFetchState,
      loading: true,
    });
    setTimeout(() => {
      setRes({
        ...initialFetchState,
        loading: false,
        data: mockData,
      });
    }, getRandomInt(1000, 3000));
  }, [mockData, shouldExecute]);

  useEffect(() => {
    if (!shouldExecute) return;
    if (isEqual(lastRequestParams.current, requestParams)) return;
    lastRequestParams.current = requestParams;
    generateApiFetch();
  }, [
    generateApiFetch,
    hasClustersScope,
    mockData,
    requestParams,
    shouldExecute,
  ]);

  useRefreshCallLogic(generateApiFetch);

  return res;
};
