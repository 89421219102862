import {
  Button,
  Modal,
  ModalActions,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import React from "react";

const DiscardDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleDiscard: () => void;
}> = ({ open, handleClose, handleDiscard }) => {
  return (
    <Modal isOpen={open} onRequestClose={handleClose} width="22rem" hideClose>
      <ModalHeader>
        <Typography variant="headline">Discard unsaved changes?</Typography>
      </ModalHeader>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" size="small" onClick={handleDiscard}>
          Discard
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DiscardDialog;
