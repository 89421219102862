import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Metadata } from "@komodorio/design-system/komodor-ui";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomEventDetailsProps } from "./CustomEventDetails";
import { SectionContainer } from "./styles";

const Container = styled(SectionContainer)`
  background: ${palette.gray[50]};
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const MetadataSection: React.FC<CustomEventDetailsProps> = ({
  event: { servicesNames, clusters, namespaces },
}) => {
  const hasServicesNames = servicesNames?.length;
  const hasClusters = clusters?.length;
  const hasNamespaces = namespaces?.length;
  const hasIdentifiableResources =
    hasServicesNames || hasClusters || hasNamespaces;

  return (
    <Container aria-label={"custom event metadata"}>
      <Typography color={palette.gray[500]} size={"small"} bold>
        WHERE
      </Typography>

      <FlexContainer>
        {!hasIdentifiableResources && (
          <Metadata title={"resources"} value={"All"} />
        )}
        {!!hasServicesNames && (
          <Metadata
            title={"service"}
            values={servicesNames}
            aria-label={"custom event service names"}
          />
        )}
        {!!hasClusters && (
          <Metadata
            title={"cluster"}
            values={clusters}
            aria-label={"custom event cluster names"}
          />
        )}
        {!!hasNamespaces && (
          <Metadata
            title={"namespace"}
            values={namespaces}
            aria-label={"custom event namespaces"}
          />
        )}
      </FlexContainer>
    </Container>
  );
};
