import { WorkloadMetricsDataPoints } from "../../../../generated/metricsApi";
import { MetricsData } from "../../types";

export const workloadDataKeyToMetricsDataKey: Record<
  keyof WorkloadMetricsDataPoints,
  keyof MetricsData
> = {
  p90Utilization: "p90",
  p95Utilization: "p95",
  p99Utilization: "p99",
  avgUtilization: "usage",
  maxUtilization: "max",
  limit: "limit",
  request: "request",
  conservativeRecommendation: "conservativeRecommendation",
  moderateRecommendation: "moderateRecommendation",
  aggressiveRecommendation: "aggressiveRecommendation",
};
