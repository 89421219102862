import { parseISO } from "date-fns";
import React, { useMemo } from "react";
import styled from "styled-components";

import {
  blackGreyText,
  gray10,
  gray13,
  grayAppBackground,
} from "../../../../../../Colors";
import { useDateFormatter } from "../../../../../../shared/hooks/useDateFormatter";
import { extractServiceName } from "../../../../../../shared/utils/serviceHelpers";
import BlueLabel from "../../../../../common/Label";
import Divider from "../../../../common/Divider";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadDescribeCheck from "../WorkloadDescribeCheck";
import SnapshotInfoDetail from "../../../../common/availabilityComponents/SnapshotInfoDetail";
import AvailabilityUpgradeAgent from "../../../../common/availabilityComponents/AvailabilityUpgradeAgent";

const Title = styled.div`
  color: ${gray10};
  display: inline;
  padding: 0 1rem;
  margin: 0.5rem 0;
`;

const CustomLabel = styled(BlueLabel)`
  margin-left: 0.5rem;
  background-color: ${grayAppBackground};
  font-family: Roboto;
  color: ${blackGreyText};
  width: fit-content;
`;

const ThinText = styled.span`
  font-weight: 400;
`;
const StyledPre = styled.pre`
  padding: 0 1rem;
  font-family: "Roboto Mono";
  font-size: 0.75rem;
  line-height: 1.125rem;
`;
const HeaderContainer = styled.div`
  height: fit-content;
`;

const LabelsContainer = styled.div`
  margin: 0.7rem;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  overflow: auto;
`;

const TopContainer = styled.div`
  border: 1px solid ${gray13};
  border-radius: 4px;
  max-height: 37rem;
  display: flex;
  flex-direction: column;
`;
const WorkloadDescribeDetails: React.FC<{
  check: WorkloadDescribeCheck;
}> = ({ check }) => {
  const { format } = useDateFormatter();

  const lastUpdated = useMemo(() => {
    const lastDeployEndTime = check.output ? check.output.latestDeployTime : "";

    if (!lastDeployEndTime) {
      return "";
    }
    return format(parseISO(lastDeployEndTime));
  }, [check.output, format]);

  if (!check.output) {
    return null;
  }

  const checkOutput = check.output;

  const currentImageTag = checkOutput.image ?? "";

  const title = `Workload Describe: ${extractServiceName(
    checkOutput.serviceId
  )}`;

  return (
    <>
      {check.output?.eventTime && (
        <SnapshotInfoDetail eventTime={check.output?.eventTime} />
      )}
      <TopContainer>
        <HeaderContainer>
          <br />
          <Title>
            <b>{title}</b>
          </Title>
          <LabelsContainer>
            {currentImageTag && (
              <CustomLabel>
                <ThinText>Image: </ThinText>
                {currentImageTag}
              </CustomLabel>
            )}
            {lastUpdated && (
              <CustomLabel>
                <ThinText>Last updated: </ThinText>
                {lastUpdated}
              </CustomLabel>
            )}
          </LabelsContainer>
          <Divider />
        </HeaderContainer>
        <ContentContainer>
          {checkOutput?.description?.length ? (
            <StyledPre>{checkOutput.description}</StyledPre>
          ) : checkOutput?.isWorkloadDescribeAllowed ? (
            <>
              <br />
              Could not fetch workload describe
            </>
          ) : (
            <>
              <AvailabilityUpgradeAgent />
              <br />
            </>
          )}
        </ContentContainer>
      </TopContainer>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default WorkloadDescribeDetails;
