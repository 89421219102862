import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:first-child:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export type EmptyTableResultsProps = {
  onClearFilters?: () => void;
};

export const EmptyTableResults: React.FC<EmptyTableResultsProps> = ({
  onClearFilters,
}) => {
  return (
    <Container>
      <Typography variant={"h4"} color={"text.secondary"}>
        No results found.
      </Typography>
      {onClearFilters && (
        <Button onClick={onClearFilters}>Clear filters</Button>
      )}
    </Container>
  );
};
