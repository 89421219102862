import { useFormContext } from "react-hook-form";
import { useEffect, useRef } from "react";
import { cloneDeep } from "lodash";

import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { PolicyConfigurations, PolicyScope } from "@/generated/reliabilityApi";
import { useGetFetchedPolicyData } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetFetchedPolicyData";
import { useReportError } from "@/components/reliability/hooks/useReportError";
import { transformConfigurationsToCheckedState } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";
import { isDefaultPolicy } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/isDefaultPolicy";

export const usePopulatePolicyById = () => {
  const {
    policyConfigurations,
    setAllPolicyConfigurations,
    setAllControlCheckedState,
    onClose,
  } = usePolicyDrawerContext();
  const { setValue } = useFormContext();
  const { policyId } = usePolicyDrawerContext();
  const valuesWereSet = useRef(false);
  const reportError = useReportError();

  const { data, isLoading, error } = useGetFetchedPolicyData();

  useEffect(() => {
    if (data?.data.policy && !valuesWereSet.current) {
      valuesWereSet.current = true;
      Object.entries(data.data.policy).forEach(([key, value]) => {
        const { key: keyToUse, value: valueToUse } = getPolicyFieldValue(
          key,
          value
        );
        setValue(keyToUse, valueToUse);

        if (keyToUse.startsWith("configurations")) {
          const configurationsCheckState =
            transformConfigurationsToCheckedState(data.data.policy);

          let policyConfigurations = value as PolicyConfigurations;
          // populate configurations state
          // do not drop disabled checks for default policy
          if (!isDefaultPolicy(data.data.policy)) {
            policyConfigurations = dropDisabledChecks(policyConfigurations);
          }

          if (configurationsCheckState) {
            setAllControlCheckedState(configurationsCheckState);
            setAllPolicyConfigurations(cloneDeep(policyConfigurations));
          }
        }
      }, []);
    } else if (error) {
      reportError(error, "fetchPolicyData");
      onClose();
    }
  }, [
    data?.data.policy,
    policyConfigurations,
    setAllControlCheckedState,
    setAllPolicyConfigurations,
    setValue,
    error,
    reportError,
    onClose,
  ]);

  return { isFetching: !!policyId && isLoading };
};

const getPolicyFieldValue = (key: string, value: unknown) => {
  if (key === "scope") {
    return {
      key: "scope.clusterRegexes",
      value: (value as PolicyScope).clusterRegexes,
    };
  }
  return { key, value };
};

const dropDisabledChecks = (configurations: PolicyConfigurations) => {
  return Object.entries(configurations).reduce((acc, [checkType, config]) => {
    if (config.enabled) {
      acc[checkType as keyof PolicyConfigurations] = config;
    }
    return acc;
  }, {} as PolicyConfigurations);
};
