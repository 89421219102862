import React, { useMemo } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Info from "@mui/icons-material/Info";

import { NoisyNeighborSupportingData } from "../../../../../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import { getNoisyNeighborAffectedDeploymentsAndJobs } from "./utils";

const Container = styled.div`
  display: flex;
  gap: 24px;
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetadataLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const {
  violationDrawer: {
    noisyNeighbors: {
      metadata: {
        noisyContainers,
        affectedNodes,
        totalVictims,
        affectedDeployments,
        affectedJobs,
      },
    },
  },
} = reliabilityArialLabels;

interface MetadataItemProps {
  label: string;
  value: number | undefined;
  ariaLabel: string;
  tooltipContent?: string;
}

const MetadataItem: React.FC<MetadataItemProps> = ({
  label,
  value,
  ariaLabel,
  tooltipContent,
}) => {
  if (!value) return null;
  return (
    <MetadataContainer>
      <Typography variant="h4" color="text.primary" aria-label={ariaLabel}>
        {value}
      </Typography>
      <MetadataLabelContainer>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        {tooltipContent && (
          <Tooltip title={tooltipContent} placement="top">
            <Info fontSize="small" color="action" />
          </Tooltip>
        )}
      </MetadataLabelContainer>
    </MetadataContainer>
  );
};

interface Props {
  supportingData: NoisyNeighborSupportingData | undefined;
}

export const NoisyNeighborMetadata: React.FC<Props> = ({ supportingData }) => {
  const { numOfDeployments, numOfJobs } = useMemo(
    () => getNoisyNeighborAffectedDeploymentsAndJobs(supportingData),
    [supportingData]
  );

  return (
    <Container>
      <MetadataItem
        ariaLabel={noisyContainers}
        label="Noisy containers"
        value={supportingData?.noisyContainers.length}
      />
      <Divider orientation="vertical" flexItem />
      <MetadataItem
        ariaLabel={affectedNodes}
        label="Affected nodes"
        value={supportingData?.totalAffectedNodes}
      />
      <Divider orientation="vertical" flexItem />
      <MetadataItem
        ariaLabel={totalVictims}
        label="Total victims"
        value={supportingData?.totalVictims}
        tooltipContent="Noisy neighbors are workloads that consume more resources than they have been allocated with (requests) in a way that generates pressure on a Node which eventually lead to termination/restarts of pods from other workloads."
      />
      {!!numOfDeployments && (
        <>
          <Divider orientation="vertical" flexItem />
          <MetadataItem
            ariaLabel={affectedDeployments}
            label="Affected deployments"
            value={numOfDeployments}
          />
        </>
      )}
      {!!numOfJobs && (
        <>
          <Divider orientation="vertical" flexItem />
          <MetadataItem
            ariaLabel={affectedJobs}
            label="Affected jobs"
            value={numOfJobs}
          />
        </>
      )}
    </Container>
  );
};
