import { muiTheme } from "@komodorio/design-system";

export const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export interface RowData {
  id: string;
  cluster: string;
  [key: string]: number | string;
}

export const CLUSTER_FIELD = "cluster";
export const GRID_MIN_HEIGHT = 370;
export const TOTAL_ROW_ID = "total";
export const TOTAL_ROW_CLASSNAME = "total-row";
export const HEADERS_ROW_CLASSNAME = "headers-row";
export const HEADERS_ROW_HEIGHT = 56;
export const GROUPS_ROW_CLASSNAME = "groups-row";
export const GROUPS_ROW_HEIGHT = 30;
export const ALL_HEADERS_HEIGHT = HEADERS_ROW_HEIGHT + GROUPS_ROW_HEIGHT;

export const TABLE_STYLES = {
  "&.MuiDataGrid-root": {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  [`& .${TOTAL_ROW_CLASSNAME}`]: {
    fontWeight: "bold",
  },
  [`& .${HEADERS_ROW_CLASSNAME}`]: {
    height: `${HEADERS_ROW_HEIGHT}px !important`,
    "& > *": { lineHeight: `${HEADERS_ROW_HEIGHT}px !important` },
  },
  "& .MuiDataGrid-columnHeaders": {
    maxHeight: `${ALL_HEADERS_HEIGHT}px !important`,
    minHeight: `${ALL_HEADERS_HEIGHT}px !important`,
  },
  "& .MuiDataGrid-overlayWrapperInner": {
    // 2px is the border width (top and bottom)
    height: `${GRID_MIN_HEIGHT - ALL_HEADERS_HEIGHT - 2}px !important`,
  },
  [`& .${GROUPS_ROW_CLASSNAME}`]: {
    textIndent: 10,
    padding: "0 !important",
    color: muiTheme.palette.text.secondary,
  },
};
