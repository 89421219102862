import { muiColors, palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Section = styled.div<{ grayBg?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 10fr max-content;
  column-gap: 1rem;
  justify-content: start;
  padding: 1.5rem;
  ${({ grayBg }) => (grayBg ? `background-color: ${palette.gray[50]}` : "")}
`;

export const SectionColumn = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 100%;
  row-gap: 1rem;
  padding: 1.5rem;
`;
export const SectionName = styled(Typography).attrs({
  variant: "uppercase",
  color: muiColors.gray[500],
})`
  && {
    font-size: 12px;
    padding-top: 0.25rem;
  }
`;
export const Header = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: start;
  gap: 0.5rem 1rem;
  & > *:nth-child(3) {
    grid-column: span 2;
  }
`;
export const Title = styled.div`
  display: flex;
  column-gap: 0.4rem;
  width: max-content;
`;
export const FlexRow = styled.div`
  display: flex;
  column-gap: 1.5rem;
`;
export const Tiles = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
export const DashedText = styled(Typography).attrs({
  size: "medium",
  bold: true,
})`
  text-decoration: underline dotted;
`;
