import { useMemo } from "react";
import semver from "semver";
import { uniqBy } from "lodash";

import { APIDeprecationsSupportingDataDeprecatedAPIsInner } from "../../../../../../../../../../generated/reliabilityApi";
import { Dictionary } from "../../../../../../../../../../shared/types/Dictionary";

import { getVersion } from "./DetailsUtils";
import { Summary, VersionSummary } from "./VersionsTimeline/types";

const MAX_ITEMS_IN_TIMELINE = 9;

export const useGetVersionsSummary = (
  apis: APIDeprecationsSupportingDataDeprecatedAPIsInner[] | undefined
) =>
  useMemo(() => {
    const versionsSummary = apis?.reduce<Dictionary<Summary>>((acc, curr) => {
      const deprecatedVersion = curr.deprecatedInVersion;
      const removedVersion = curr.removedInVersion;
      if (deprecatedVersion) {
        const formattedDeprecatedVersion = getVersion(deprecatedVersion);

        const accVersion = acc[formattedDeprecatedVersion];
        acc[formattedDeprecatedVersion] = {
          ...accVersion,
          apis: accVersion?.apis ? [...(accVersion?.apis ?? []), curr] : [curr],
          deprecated: accVersion?.deprecated ? accVersion.deprecated + 1 : 1,
        };
      }
      if (removedVersion) {
        const formattedRemovedVersion = getVersion(removedVersion);

        const accVersion = acc[formattedRemovedVersion];
        acc[formattedRemovedVersion] = {
          ...accVersion,
          apis: accVersion?.apis ? [...(accVersion?.apis ?? []), curr] : [curr],
          removed: accVersion?.removed ? accVersion.removed + 1 : 1,
        };
      }
      return acc;
    }, {});
    return versionsSummary;
  }, [apis]);

export const useAllVersionsWithSummary = (
  allVersions: string[],
  versionsSummary: Dictionary<Summary> | undefined
) =>
  useMemo(() => {
    const state: Summary = { deprecated: 0, removed: 0, apis: [] };
    return allVersions?.map((version) => {
      if (versionsSummary?.[version]) {
        state.deprecated += versionsSummary[version].deprecated ?? 0;
        state.removed += versionsSummary[version].removed ?? 0;
        state.apis = uniqBy(
          [...state.apis, ...versionsSummary[version].apis],
          "api"
        );
      }
      return { version: version, ...state };
    });
  }, [allVersions, versionsSummary]);

export const useGetFollowingVersions = (
  clusterVersion: string | undefined,
  allVersionsWithSummary: VersionSummary[]
): VersionSummary[] | undefined =>
  useMemo(() => {
    if (!clusterVersion) return;
    const currentVersion = getVersion(clusterVersion);
    return allVersionsWithSummary
      .filter((version) => semver.lt(currentVersion, version.version))
      .slice(0, MAX_ITEMS_IN_TIMELINE)
      .map((version) => ({
        ...version,
        version: `v${version.version}`,
      }));
  }, [allVersionsWithSummary, clusterVersion]);
