import React, { useState } from "react";
import styled from "styled-components";
import { Button, Popover } from "@komodorio/design-system/deprecated";
import { Ellipsis16 } from "@komodorio/design-system/icons";
import { useNavigate } from "react-router-dom";

import { appViewAriaLabels } from "../../../../appViewAriaLabels";
import { getEditAppViewRoute } from "../../../../utils/getRoutes";

import { AppViewActionsStateProvider } from "./internal/AppViewActionsStateProvider";
import { ActionsMenu } from "./internal/ActionsMenu";
import { AppViewDeletionDialog } from "./internal/AppViewDeletionDialog";

import { AddEditWorkspaceModal } from "@/components/workspaces/AddEditWorkspaceModal/AddEditWorkspaceModal";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { selectedAppViewIdSelector } from "@/shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "@/shared/store/appViewsStore/appViewsStore";

const SmallButton = styled(Button)`
  padding: 5px;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const {
  overviewPage: {
    header: { actionButton: actionButtonAriaLabel },
  },
} = appViewAriaLabels;

export const AppViewActionsButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isEditClusterGroupModalOpen, setIsEditClusterGroupModalOpen] =
    useState(false);
  const { isClustersGroupWorkspace, currentWorkspace } = useWorkspaces();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const navigate = useNavigate();

  const onEdit = () => {
    if (isClustersGroupWorkspace) {
      setIsEditClusterGroupModalOpen(true);
      return;
    }
    selectedAppViewId && navigate(getEditAppViewRoute(selectedAppViewId));
  };

  return (
    <AppViewActionsStateProvider>
      {isEditClusterGroupModalOpen && (
        <AddEditWorkspaceModal
          existingWorkspace={currentWorkspace}
          onClose={() => setIsEditClusterGroupModalOpen(false)}
        />
      )}
      <Popover
        isOpen={open}
        handleClose={() => {
          setOpen(false);
        }}
        content={<ActionsMenu onEdit={onEdit} />}
      >
        <SmallButton
          variant={"default"}
          size={"large"}
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
          icon={Ellipsis16}
          aria-label={actionButtonAriaLabel}
        />
      </Popover>
      <AppViewDeletionDialog />
    </AppViewActionsStateProvider>
  );
};
