import { useMemo } from "react";

import {
  GenericViolationFilterCategory,
  ImpactGroupType,
} from "@/generated/reliabilityApi";
import { useGetImpactGroupType } from "@/components/reliability/hooks/useSelectedTab";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useAddImpactGroupTypeFilter =
  (): GenericViolationFilterCategory => {
    const impactGroupType = useGetImpactGroupType();
    const { reliabilityHealthManagement } = useOverridableFlags();

    return useMemo(() => {
      const shouldAddRealTimeImpactGroupType =
        reliabilityHealthManagement &&
        impactGroupType === ImpactGroupType.Dynamic;

      const additionalImpactGroupValues = shouldAddRealTimeImpactGroupType
        ? [ImpactGroupType.Realtime]
        : [];

      return {
        category: "impactGroupType",
        operator: "include",
        value: [impactGroupType, ...additionalImpactGroupValues],
      };
    }, [impactGroupType, reliabilityHealthManagement]);
  };
