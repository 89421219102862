import { EnrichedMessage, MessageEnricher } from "../types";

export class BaseEnricher implements MessageEnricher {
  private readonly re: RegExp;

  constructor(regex: RegExp) {
    this.re = regex;
  }

  searchKey(): string {
    return "";
  }

  regex() {
    return this.re;
  }

  enrich(_: string): EnrichedMessage | null {
    return null;
  }
}
