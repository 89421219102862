import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { ServiceInfo } from "../../../../../shared/types/ServiceInfo";
import { KubernetesResource } from "../../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import { getStatusGroup } from "../../../statusMapper";
import EventGroup from "../../../EventGroup";
import { useOpenIssueByResource } from "../../../../OpenIssues/useOpenIssuesByResource";
import { useOpenIssueTooltip } from "../../../../OpenIssues/useOpenIssueTooltip";

import { StatusTag } from "./StatusTag";

interface StatusCellProps {
  cellName: string;
  cellData: string;
  resourceType: KubernetesResource;
  service: ServiceInfo | undefined;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  rowId: string;
}

export const StatusCell: React.FC<StatusCellProps> = ({
  cellName,
  cellData,
  resourceType,
  service,
  setCorrelatedEvent,
  rowId,
}) => {
  const statusGroup = getStatusGroup(cellData, resourceType.NameInK8S);

  const openIssue = useOpenIssueByResource(
    undefined,
    undefined,
    resourceType.Kind,
    undefined,
    service?.id
  );

  const [tooltip, tooltipId] = useOpenIssueTooltip(
    openIssue,
    service,
    setCorrelatedEvent,
    rowId
  );

  return (
    <td key={cellName}>
      {cellData && (
        <TooltipWrapper tooltipId={tooltipId}>
          <StatusTag status={cellData} statusGroup={statusGroup} />
        </TooltipWrapper>
      )}
      {openIssue && tooltip}
    </td>
  );
};
