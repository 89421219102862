import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { AddonUrlState } from "@/components/k8sAddons/types";

/** determine if fetching is triggered by filters change or not */
export const useIsTableFetchingNewData = (isFetching: boolean) => {
  const [dataInUrl] = useStateInUrlAsJSON<AddonUrlState>(
    K8S_ADD_ONS_FILTERS_PARAM_KEY
  );
  const [isFetchingData, setIsFetchingData] = useState(false);
  const lastDataInUrl = useRef(dataInUrl);
  useEffect(() => {
    if (isEqual(lastDataInUrl.current, dataInUrl)) {
      setIsFetchingData(false);
      return;
    }

    lastDataInUrl.current = dataInUrl;
    setIsFetchingData(isFetching);
  }, [dataInUrl, isFetching]);

  return { isFetchingData };
};
