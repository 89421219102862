import { LogsViewerStore } from "./types";

export const initialState: LogsViewerStore = {
  isLiveTailEnabledBySystem: false,
  isLiveTailEnabledByUser: false,
  isAnalyzingLogs: false,
  setLiveTailEnabledBySystem: () => undefined,
  setLiveTailEnabled: () => undefined,
  setLiveTailEnabledByUser: () => undefined,
  setAnalyzingLogs: () => undefined,
};
