import { useCallback } from "react";
import { AxiosError } from "axios";

import {
  useGetAllPolicies,
  usePatchPolicy,
} from "@/shared/hooks/reliability-api/policies/usePolicies";
import { PartialPolicy } from "@/generated/reliabilityApi";
import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectSetReliabilityNotification } from "@/components/reliability/store/reliabilityStoreSelectors";
import {
  HTTP_STATUS_CONFLICT,
  policyReorderMessages,
} from "@/components/reliability/components/pages/policies/AllPoliciesTable/policiesTableConstants";
import { useClearPolicyDataFromQueryCache } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useClearPolicyDataFromQueryCache";

export const useIsPriorityAvailable = () => {
  const { data: policiesData } = useGetAllPolicies();

  return useCallback(
    (newPriority: number, currentPriority: number) => {
      if (newPriority === currentPriority) return true;

      return !policiesData?.data.policies.some(
        (policy) => policy.priority === newPriority
      );
    },
    [policiesData?.data.policies]
  );
};

export const useUpdatePolicyPriority = () => {
  const { mutateAsync: patchPolicy } = usePatchPolicy();
  const setNotification = useReliabilityStore(selectSetReliabilityNotification);
  const { refetch: refetchAllPolicies } = useGetAllPolicies();
  const clearPolicyDataFromQueryCache = useClearPolicyDataFromQueryCache();

  return useCallback(
    async (policyId: string, partialPolicy: PartialPolicy) => {
      try {
        await patchPolicy({
          id: policyId,
          updatePolicyFieldsRequest: {
            policy: partialPolicy,
          },
        });
        await refetchAllPolicies();
        await clearPolicyDataFromQueryCache({ id: policyId });
        setNotification({
          severity: "info",
          message: policyReorderMessages.success,
        });
      } catch (error) {
        const { response } = error as AxiosError;
        const errorMessage =
          response?.status === HTTP_STATUS_CONFLICT
            ? policyReorderMessages.priorityTakenError
            : policyReorderMessages.generalError;

        setNotification({
          severity: "error",
          message: errorMessage,
        });
        return Promise.reject(error);
      }
    },
    [
      clearPolicyDataFromQueryCache,
      patchPolicy,
      refetchAllPolicies,
      setNotification,
    ]
  );
};
