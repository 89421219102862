import styled from "styled-components";
import Timeline from "@mui/lab/Timeline";
import TimelineDot from "@mui/lab/TimelineDot";

export const StyledTimeline = styled(Timeline)`
  && {
    padding: 0;
    & .MuiTimelineItem-root:before {
      flex: 0;
      padding: 0;
    }
  }
`;

export const StyledTimelineDot = styled(TimelineDot)`
  && {
    box-shadow: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
