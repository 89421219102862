import { useGetClusterNamesParam } from "../../ViolationTableWithContext/hooks/useGetClusterNamesParam";
import { useIssuesTableContext } from "../context/useIssuesTableContext";

import { useCheckTypeParam } from "./useCheckTypeParam";

import {
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useScopeFilters } from "@/components/reliability/hooks/useScopeFilters";
import { useTimeWindowToEpoch } from "@/components/reliability/hooks/useTimeWindowToEpoch";
import { useCheckCategory } from "@/components/reliability/hooks/useCheckCategory";

export const useRealtimeIssuesRequestParams =
  (): ViolationsApiGetViolationsRequest => {
    const {
      paginationModel: { page, pageSize },
    } = useIssuesTableContext();
    const timeWindowToEpoch = useTimeWindowToEpoch();

    const { namespace, resourceType, status, shortResourceName, createdAt } =
      useScopeFilters();

    const checkCategory = useCheckCategory();

    const checkType = useCheckTypeParam();
    const clusterNames = useGetClusterNamesParam();

    const epochs = timeWindowToEpoch(createdAt?.[0]);

    return {
      pageSize,
      offset: pageSize * page,
      impactGroupType: ["realtime"],
      status: status as ViolationStatus[],
      checkType,
      checkCategory,
      ...(resourceType?.length && { resourceType }),
      ...(clusterNames?.length && { clusterName: clusterNames }),
      ...(namespace?.length && { namespace }),
      ...(shortResourceName?.length && {
        shortResourceName,
      }),
      ...(!!epochs && { ...epochs }),
    };
  };
