import _, { isEmpty } from "lodash";
import React from "react";

import { DescribeKeyValueItem } from "./DescribeTagListItem";
import { DescribeSectionDivider } from "./DescribeSectionDivider";

type LabelsAnnotations = {
  [name: string]: string;
};

const hasValues = _.negate(isEmpty);

export const DescribeLabelsAndAnnotations: React.FC<{
  labels: LabelsAnnotations;
  annotations: LabelsAnnotations;
}> = ({ labels, annotations }) => {
  if (isEmpty(labels) && isEmpty(annotations)) return null;

  return (
    <>
      <DescribeSectionDivider title={"LABELS & ANNOTATIONS"} />
      {hasValues(labels) && (
        <DescribeKeyValueItem name="Labels" value={labels} />
      )}
      {hasValues(annotations) && (
        <DescribeKeyValueItem name="Annotations" value={annotations} />
      )}
    </>
  );
};
