import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import { whitelistAriaLabels } from "@/components/Settings/whitelistCIDR/whitelistAriaLabels";

interface VerificationAlertDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const VerificationDeleteDialog: React.FC<
  VerificationAlertDialogProps
> = (props: VerificationAlertDialogProps) => {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"sm"}
      aria-label={whitelistAriaLabels.dialogDeleteCIDR}
    >
      <DialogTitle>
        <Typography variant="h4">Remove CIDR</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent sx={{ padding: "0px 24px 20px 24px" }}>
        <Typography>Are you sure you wish to remove the CIDR?</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button onClick={onClose} size="medium" variant="outlined">
          Cancel
        </Button>

        <Button
          onClick={onConfirm}
          color="error"
          size="medium"
          variant="contained"
        >
          Yes, Remove the CIDR
        </Button>
      </DialogActions>
    </Dialog>
  );
};
