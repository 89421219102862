import { useMemo } from "react";

import {
  WorkloadMetricsDataPoints,
  WorkloadMetricsContainers,
  OptimizationStrategy,
  MetricsApiGetWorkloadContainersMetricsRequest,
  AggregationType,
} from "../../../../../../generated/metricsApi";
import {
  useGetLineList,
  useHasGraphData,
  useTransformWorkloadMetricsData,
} from "../../../../../Metrics/hooks/workload/workloadMetricsTabHooks";
import {
  MetricsAggregationType,
  MetricsGraphProps,
  MetricType,
} from "../../../../../Metrics/types";
import { timelineTicksNum } from "../constants";
import { MetricsParamsWithoutkind } from "../context/RecommendationsModalContext";
import { useGetWorkloadContainersMetrics } from "../../../../../../shared/hooks/metrics-api/client/useGetWorkloadContainersMetrics";
import { Timeframe } from "../../../../../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";

const TIME_WINDOW_LABEL = Timeframe.Last30Days;
const BIN_TIME_SECONDS = 3600 * 4;
const DEFAULT_AGGREGATION_TYPE = AggregationType.P99;

export const useGetContainerData = (
  data: WorkloadMetricsContainers | undefined,
  containerName: string
) =>
  useMemo(
    () =>
      data?.containers.find(
        (container) => container.containerName === containerName
      ),
    [containerName, data?.containers]
  );

type GraphDataProps = {
  data: WorkloadMetricsDataPoints | undefined;
  strategy: OptimizationStrategy;
  aggregation: MetricsAggregationType;
};

export const useGraphData = ({
  data,
  strategy,
  aggregation,
}: GraphDataProps) => {
  const transformedData = useTransformWorkloadMetricsData(
    data,
    TIME_WINDOW_LABEL,
    strategy
  );
  const hasGraphData = useHasGraphData(data);
  const linesList = useGetLineList(aggregation);
  const graphProps: MetricsGraphProps = {
    data: transformedData,
    disableZoom: true,
    timelineTicksNum: timelineTicksNum,
    showLinesList: linesList,
    aggregationType: aggregation,
  };

  return {
    hasGraphData,
    graphProps,
  };
};

interface GetGraphDataProps {
  containerName: string;
  metricsParams: MetricsParamsWithoutkind | undefined;
  type: MetricType;
  strategy: OptimizationStrategy;
  aggregation: MetricsAggregationType;
}

export const useFetchGraphData = ({
  containerName,
  metricsParams,
  type,
  strategy,
  aggregation,
}: GetGraphDataProps) => {
  const {
    applyRecommendationsModalBinSeconds,
    applyRecommendationsModalAggregation,
  } = useOverridableFlags();

  const params = useMemo<MetricsApiGetWorkloadContainersMetricsRequest>(() => {
    const binSeconds = !isNaN(Number(applyRecommendationsModalBinSeconds))
      ? Number(applyRecommendationsModalBinSeconds)
      : BIN_TIME_SECONDS;

    const aggregationType = applyRecommendationsModalAggregation
      ? (applyRecommendationsModalAggregation as AggregationType)
      : DEFAULT_AGGREGATION_TYPE;

    return {
      clusterName: metricsParams?.clusterName ?? "",
      namespace: metricsParams?.namespace ?? "",
      komodorServiceName: metricsParams?.komodorServiceName ?? "",
      komodorServiceKind: metricsParams?.komodorServiceKind ?? "",
      fromEpoch: metricsParams?.fromEpoch ?? 0,
      toEpoch: metricsParams?.toEpoch ?? 0,
      kind: type,
      binSeconds,
      aggregationType,
    };
  }, [
    applyRecommendationsModalAggregation,
    applyRecommendationsModalBinSeconds,
    metricsParams?.clusterName,
    metricsParams?.fromEpoch,
    metricsParams?.komodorServiceKind,
    metricsParams?.komodorServiceName,
    metricsParams?.namespace,
    metricsParams?.toEpoch,
    type,
  ]);

  const res = useGetWorkloadContainersMetrics({
    params,
    enabled: !!metricsParams,
  });

  const containerData = useGetContainerData(res.data?.data, containerName);

  const { graphProps, hasGraphData } = useGraphData({
    data: containerData?.dataPoints,
    strategy,
    aggregation,
  });

  return {
    graphProps,
    hasGraphData,
    error: res.error,
    loading: res.isLoading,
  };
};
