import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isEqual } from "lodash";

export type InspectionState = {
  selectedNamespaces: string[];
};

export type InspectionActions = {
  setSelectedNamespaces: (selectedNamespaces: string[]) => void;
};

export type InspectionStore = InspectionState & InspectionActions;

export const initialState: InspectionState = {
  selectedNamespaces: [],
};

export const useInspectionStore = create(
  immer<InspectionStore>((set) => ({
    ...initialState,
    setSelectedNamespaces: (namespaces: string[]) => {
      set((state) => {
        if (!isEqual(state.selectedNamespaces, namespaces)) {
          state.selectedNamespaces = namespaces;
        }
      });
    },
  }))
);
