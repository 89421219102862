export const selectAriaLabels = {
  multiSelect: "multi select",
  singleSelect: "select",
  enabledOptions: "enabled options",
  disabledOptions: "disabled options",
  menuList: "menu list",
  dropDownList: "open dropdown list",
  search: "enter search term",
  label: "select label",
  clearButton: "clear selection",
};
