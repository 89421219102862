import React from "react";
import styled from "styled-components";
import { muiColors, palette } from "@komodorio/design-system";

import infoIcon from "../assets/info.svg";
import { textStyle } from "../../typography";
import { addWBR } from "../../../../shared/utils/addWBR";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { blackGreyText, greenForUI, pinkForUI } from "../../../../Colors";

const LinkContainer = styled.a<{ textColor?: string; noUnderline: boolean }>`
  color: ${({ textColor }) => textColor ?? muiColors.indigo[500]};
  ${({ noUnderline }) => (noUnderline ? "text-decoration: none;" : undefined)}
  font-weight: 400;
  > svg {
    vertical-align: bottom;
    width: 1em;
  }
`;

const DisabledLinkContainer = styled(LinkContainer).attrs({
  noUnderline: true,
})`
  pointer-events: none;
  cursor: default;
  color: ${palette.gray[400]};
`;

const SmallText = styled.div`
  ${textStyle};
  line-height: 1rem;
  color: ${blackGreyText};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9rem;
`;

const Text = styled.u<{ noUnderLine: boolean }>`
  ${({ noUnderLine }) => (noUnderLine ? "text-decoration: none;" : "")}
`;

interface DetailsExternalLinkProps {
  href: string;
  title: string;
  color?: string;
  noUnderline?: boolean;
  id?: number | string;
  e2eSelector?: string;
  big?: boolean;
  dispatchLinkMetric?: () => void;
  disabled?: boolean;
}
export const DetailsExternalLink: React.FC<DetailsExternalLinkProps> = ({
  href,
  title,
  color,
  id,
  noUnderline = false,
  e2eSelector,
  big = true,
  disabled = false,
  dispatchLinkMetric,
}) => {
  const parsedTitle = addWBR(title ?? "");

  const linkProps = {
    textColor: color,
    key: id,
    noUnderline: noUnderline,
    href,
    target: href.startsWith("#") ? undefined : "_blank",
    rel: href.startsWith("#") ? undefined : "noopener, noreferrer",
    dataE2eSelector: e2eSelector,
    onClick: () => {
      dispatchLinkMetric && dispatchLinkMetric();
      dispatchEvent(AnalyticEvents.ProcessList.EventLink_Click, {
        url: href,
      });
    },
    children: big ? (
      <Text noUnderLine>{parsedTitle}</Text>
    ) : (
      <SmallText>{parsedTitle}</SmallText>
    ),
  };

  return disabled ? (
    <DisabledLinkContainer {...linkProps} />
  ) : (
    <LinkContainer {...linkProps} />
  );
};

export interface StyledEventDetailsContainerProps {
  hidden?: boolean;
  zIndex?: number;
}

export const EventDetailsContainer = styled.div<StyledEventDetailsContainerProps>`
  display: ${({ hidden }) => (hidden ? "none" : undefined)};
  color: #3d4048;
  height: inherit;
  z-index: ${({ zIndex }) =>
    zIndex ? zIndex : "auto"}; // Default to 'auto' if not specified
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e7e9f0;
`;

export const BaseAction = styled.span`
  color: #007aff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`;

export const Action = styled(BaseAction)`
  &::after {
    content: " ➜";
    display: inline-block;
    font-weight: normal;
    white-space: pre;
  }
`;

export const DisabledContent = styled.div`
  display: grid;
  gap: 0.1rem;
  justify-items: start;
`;

export const Info = styled.div`
  align-self: start;
  ::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: url(${infoIcon}) no-repeat;
  }

  position: relative;
  &::after {
    content: attr(data-info);
    visibility: hidden;
    position: absolute;
    right: 100%;
    top: 0;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.35rem;
    padding: 0.5rem;
    width: 17rem;
    background-color: white;
    transform: translateX(-0.5rem);
    ${textStyle}
  }

  &:hover {
    &::before {
      filter: brightness(0.5);
    }
    &::after {
      visibility: visible;
    }
  }
`;

const Label = styled.div`
  padding: 0.125rem 0.25rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const From = styled(Label)`
  background-color: ${pinkForUI};
  text-decoration-line: line-through;
`;

export const To = styled(Label)`
  background-color: ${greenForUI};
`;

export const Change = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  align-items: center;

  > * {
    margin: 0 0.25rem 0.25rem 0;
  }

  margin: 0 -0.25rem -0.25rem 0;

  &:nth-child(n + 2) {
    margin-block-start: 0.75rem;
  }
`;

export type ConfigChanges = {
  name: string;
  previous: string;
  current: string;
}[];
