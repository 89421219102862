import { ContainerStatus } from "kubernetes-types/core/v1";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

/**
  we are fetching logs for pod with ready containers or init containers. There are some fallbacks in case we can't determine,
  but if we know for sure who is the selected container, we will fetch only if its running field was set, or if it won't marked as waiting
 */
export const shouldFetchPodLogs = (
  containerStatuses: ContainerStatus[],
  initContainerStatuses: ContainerStatus[] = [],
  selectedContainerName?: string
): boolean => {
  if (!selectedContainerName) {
    return false;
  }

  const selectedContainerEntity = [
    ...containerStatuses,
    ...initContainerStatuses,
  ].find((container) => container.name === selectedContainerName);

  if (!selectedContainerEntity) {
    // don't block the operation if the data is not ready for further checks
    return true;
  }

  if (
    selectedContainerEntity.state?.running ||
    selectedContainerEntity.state?.terminated ||
    selectedContainerEntity.lastState?.terminated
  ) {
    return true;
  }

  if (selectedContainerEntity.state?.waiting) {
    return false;
  }

  // we can't determine, let's fetch
  return true;
};

export const useShouldFetchPodLogs = (
  containerStatuses: ContainerStatus[],
  initContainerStatuses: ContainerStatus[] = [],
  selectedContainerName?: string
): boolean => {
  const { podLogsFetchLogsOnlyIfExists } = useOverridableFlags();
  if (!podLogsFetchLogsOnlyIfExists) {
    return true;
  }

  return shouldFetchPodLogs(
    containerStatuses,
    initContainerStatuses,
    selectedContainerName
  );
};
