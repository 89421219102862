import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { GridColDef } from "@mui/x-data-grid-pro";

import { LoadingChips } from "../loadingMode/LoadingChips";
import { TableActions } from "../tableCells/TableActions";
import { LoadingActions } from "../loadingMode/LoadingActions";
import { ScopeCell } from "../tableCells/ScopeCell/ScopeCell";
import { PriorityCell } from "../tableCells/PriorityCell";
import { NameCell } from "../tableCells/NameCell";
import { UpdatedByCell } from "../tableCells/UpdatedByCell";

import { Dictionary } from "@/shared/types/Dictionary";
import { PolicyListItem } from "@/generated/reliabilityApi";
import { EllipsisContainerWithTooltip } from "@/shared/components/EllipsisContainerWithTooltip";
import { PRIORITY_CELL_COLUMN_WIDTH } from "@/components/reliability/components/pages/policies/AllPoliciesTable/policiesTableConstants";

const columnsConfig: Dictionary<GridColDef<PolicyListItem>> = {
  priority: {
    field: "priority",
    headerName: "Priority",
    sortable: false,
    width: PRIORITY_CELL_COLUMN_WIDTH,
  },
  name: {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
  },
  configurationsCount: {
    field: "configurationsCount",
    headerName: "Rule Count",
    sortable: false,
    width: 100,
  },
  scope: {
    field: "scope",
    headerName: "Scope",
    sortable: false,
    width: 250,
  },
  updatedBy: {
    field: "updatedBy",
    headerName: "Last updated by",
    sortable: false,
    width: 150,
  },
  actions: {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 120,
  },
};

export const usePoliciesTableColumns = (): GridColDef<PolicyListItem>[] => {
  return [
    {
      ...columnsConfig.priority,
      sortable: true,
      cellClassName: "priority-cell",
      renderCell: (params) => {
        return <PriorityCell {...params.row} />;
      },
    },
    {
      ...columnsConfig.name,
      renderCell: (params) => {
        return <NameCell {...params.row} />;
      },
    },
    {
      ...columnsConfig.configurationsCount,
    },
    {
      ...columnsConfig.scope,
      renderCell: (params) => {
        return <ScopeCell scope={params.row.scope} />;
      },
    },
    {
      renderCell: (params) => {
        return (
          <EllipsisContainerWithTooltip tooltipValue={params.value}>
            {params.value}
          </EllipsisContainerWithTooltip>
        );
      },
      ...columnsConfig.updatedBy,
    },
    {
      ...columnsConfig.actions,
      renderCell: (params) => {
        return <TableActions {...params.row} />;
      },
    },
  ];
};

export const useEmptyColumns = () => {
  return [
    {
      ...columnsConfig.priority,
      renderCell: () => {
        return <Skeleton variant="rounded" width={14} height={21} />;
      },
    },
    {
      ...columnsConfig.name,
      renderCell: () => {
        return (
          <div>
            <Skeleton
              variant="rounded"
              width={242}
              height={14}
              sx={{ marginBottom: "10px" }}
            />
            <Skeleton variant="rounded" width={150} height={10} />
          </div>
        );
      },
    },
    {
      ...columnsConfig.configurationsCount,
      renderCell: () => {
        return <Skeleton variant="rounded" width={10} height={20} />;
      },
    },
    {
      ...columnsConfig.scope,
      renderCell: () => {
        return <LoadingChips />;
      },
    },
    {
      ...columnsConfig.updatedBy,
      renderCell: () => {
        return <UpdatedByCell />;
      },
    },
    {
      ...columnsConfig.actions,
      renderCell: () => {
        return <LoadingActions />;
      },
    },
  ];
};
