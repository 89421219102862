import React, { useMemo } from "react";

import EventDetailsSection from "../components/EventDetailsSection";
import linkIcon from "../../assets/link.svg";
import CustomLinkComponent from "../components/LinkSection/CustomLink";
import { Link } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { External_Link_Context_Enum } from "../../../../../generated/graphql";
import { AddLink } from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/AddLink/AddLink";
import EditableExternalLink, {
  isEditableLink,
} from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/EditableExternalLink";
import {
  dispatchLinkMetric,
  showLink,
} from "../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/utils";
import { useHasPermissions } from "../../../../../shared/hooks/useUserMetadata/rbac";
import Resource from "../../../../ResourceView/resources";

interface RelatedLinksSectionProps {
  linksTemplates: Link[];
  resource: Resource | undefined;
  deploySpec?: Record<string, unknown>;
  startTime: Date;
  endTime: Date;
  failedPod?: string;
  isCompleted: boolean;
  clusterName?: string;
  namespace?: string;
  isPodEvent?: boolean;
}

const DrawerEventLinksSection: React.FC<RelatedLinksSectionProps> = ({
  linksTemplates,
  resource,
  deploySpec,
  startTime,
  endTime,
  failedPod,
  isCompleted,
  clusterName = null,
  namespace = null,
  isPodEvent = false,
}) => {
  const nowTime = useMemo(() => new Date(), []);
  const { canManageIntegrations } = useHasPermissions();

  return (
    <EventDetailsSection disabled={false} icon={linkIcon} title="Links">
      {linksTemplates.map((link: Link, index) => {
        return (
          showLink(link, External_Link_Context_Enum.Drawer) && (
            <EditableExternalLink
              link={link}
              isEditable={isEditableLink(link, canManageIntegrations)}
              context={External_Link_Context_Enum.Drawer}
              key={index}
            >
              <CustomLinkComponent
                key={link.text + link.url + index.toString()}
                linkTemplate={link}
                displayName={resource?.name}
                k8sClusterName={clusterName ? clusterName : resource?.cluster}
                namespace={namespace ? namespace : resource?.namespace}
                deploySpec={deploySpec}
                startTime={startTime}
                endTime={isCompleted ? endTime : nowTime}
                failedPod={failedPod}
                dispatchLinkMetric={dispatchLinkMetric(
                  External_Link_Context_Enum.Drawer,
                  link
                )}
                isPodEvent={isPodEvent}
              />
            </EditableExternalLink>
          )
        );
      })}
      {isPodEvent ? null : (
        <AddLink
          context={External_Link_Context_Enum.Drawer}
          k8sClusterName={resource?.cluster}
          namespace={resource?.namespace}
          serviceId={resource?.id}
          serviceDisplayName={resource?.name}
        />
      )}
    </EventDetailsSection>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DrawerEventLinksSection;
