import { useMemo } from "react";

import { ServiceInfo, Tag } from "../types/ServiceInfo";

export const useNamespaceFromService = (
  service?: ServiceInfo
): string | undefined => {
  return useMemo(() => {
    return getNamespaceFromService(service?.tags);
  }, [service?.tags]);
};

export const getNamespaceFromService = (
  tags: Tag[] | undefined
): string | undefined => {
  const tag = tags?.find((t) => t.label === "namespace");
  return tag?.value;
};
