import { ContainerState } from "kubernetes-types/core/v1.d";

export enum ContainerType {
  Regular = "regular",
  Init = "init",
}

export interface ContainerOption {
  label: string;
  value: string;
  containerType: ContainerType;
  state?: ContainerState;
  disabled: boolean;
}
