import { useEffect, useMemo, useState } from "react";

import { useMetricsAPIGet } from "../../../../../shared/hooks/metrics-api/client";
import {
  CostApiApiV1CostRightSizingSummaryAggregatedGetRequest,
  CostRightSizingSummary,
  RightSizingCostSummaryByServiceResponse,
} from "../../../../../generated/metricsApi";
import { COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT } from "../../../../../shared/hooks/metrics-api/requestResponseMaps";
import { isDevMode } from "../../../../../shared/utils/isDevMode";
import {
  getEpochFromTimeFrame,
  shouldUpdateRecommendationState,
} from "../../../utils/costOptimizationUtils";
import { RightSizingRecommendationsState } from "../../../types/costOptimizationTypes";
import { STATIC_TIME_FRAME } from "../../../constants/costOptimizationConstants";
import { generateMockCostRightSizingRecommendation } from "../../../__tests__/mockData/mockCostRightSizingRecommendation";

type FetchCostRightSizingRecommendationsData = {
  prevRecommendationState: RightSizingRecommendationsState;
  rowData: CostRightSizingSummary | undefined;
  lastVisitTime: number;
  onDataFetched: (data: RightSizingRecommendationsState) => void;
};

export const useFetchCostRightSizingRecommendationsData = ({
  rowData,
  prevRecommendationState,
  lastVisitTime,
  onDataFetched,
}: FetchCostRightSizingRecommendationsData) => {
  const mockCostRightSizingRecommendationResponse = useMemo(
    () =>
      isDevMode() ? generateMockCostRightSizingRecommendation() : undefined,
    []
  );

  const [res, setRes] = useState<RightSizingRecommendationsState>({
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (!isDevMode() || !rowData || !mockCostRightSizingRecommendationResponse)
      return;

    setRes({
      loading: true,
      error: null,
    });

    const timeout = setTimeout(() => {
      setRes({
        loading: false,
        error: null,
        data: mockCostRightSizingRecommendationResponse,
        refresh: () => undefined,
      });
    }, 2000);

    return () => clearTimeout(timeout);
  }, [mockCostRightSizingRecommendationResponse, rowData]);
  /////////////////////////////

  const { fromEpoch, toEpoch } = getEpochFromTimeFrame(
    STATIC_TIME_FRAME,
    lastVisitTime
  );

  const params = useMemo(
    (): CostApiApiV1CostRightSizingSummaryAggregatedGetRequest => ({
      komodorServiceName: rowData?.service || "",
      clusterName: rowData?.clusterName || "",
      namespace: rowData?.namespace || "",
      komodorServiceKind: rowData?.komodorServiceKind || "",
      fromEpoch,
      toEpoch,
    }),
    [
      rowData?.clusterName,
      rowData?.service,
      rowData?.namespace,
      rowData?.komodorServiceKind,
      fromEpoch,
      toEpoch,
    ]
  );

  const serverRes = useMetricsAPIGet<RightSizingCostSummaryByServiceResponse>(
    COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT,
    params,
    isDevMode() || !!prevRecommendationState.data?.rows || !rowData
  );

  useEffect(() => {
    const dataToUse = isDevMode() ? res : serverRes;
    const shouldUpdate = shouldUpdateRecommendationState(
      prevRecommendationState,
      dataToUse
    );
    if (shouldUpdate && dataToUse) {
      onDataFetched(dataToUse);
    }
  }, [prevRecommendationState, res, serverRes, onDataFetched]);
};
