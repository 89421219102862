import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";
import { LabeledSwitch } from "@komodorio/design-system/komodor-ui";

import { StrictExternalLink } from "../../../../common/Link/ExternalLink";

import { KlaudiaAiConsentForm } from "./KlaudiaAiConsentForm";
import { KlaudiaAiDisableForm } from "./KlaudiaAiDisableForm";

import { PlanContainer } from "@/components/Settings/styles";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { useUpdateAccountFeatures } from "@/shared/hooks/accounts-service/client/features/useUpdateFeatures";
import {
  HeaderContainer,
  InfoContainer,
} from "@/components/Settings/General/BetaFeatures/componentsStyles";

export const KlaudiaAi: React.FC = () => {
  const { data: features, refresh: refreshFeatures } = useAccountFeatures();
  const [isEnableModalOpen, setIsEnableModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);

  const klaudiaEnabled = features.klaudiaAi;

  const { isLoading, data, mutate } = useUpdateAccountFeatures({
    features: {
      ...features,
      ...{ klaudiaAi: !klaudiaEnabled as unknown as object },
    },
  });

  useEffect(() => {
    if (data) {
      setIsEnableModalOpen(false);
      setIsDisableModalOpen(false);
      refreshFeatures();
    }
  }, [data, refreshFeatures]);

  const handleEnableClose = () => {
    setIsEnableModalOpen(false);
  };

  const handleEnableClick = () => {
    setIsEnableModalOpen(true);
  };

  const handleEnable = () => {
    mutate();
  };

  const handleDisableClick = () => {
    setIsDisableModalOpen(true);
  };

  const handleDisable = () => {
    mutate();
  };

  const handleDisableClose = () => {
    setIsDisableModalOpen(false);
  };

  const handleSwitchCheck = React.useCallback((shouldEnable: boolean) => {
    if (shouldEnable) {
      handleEnableClick();
    } else {
      handleDisableClick();
    }
  }, []);

  return (
    <PlanContainer
      aria-label={AriaLabels.SettingsPage.AccountProfile.BetaFeatures.KlaudiaAi}
    >
      <HeaderContainer>
        <MagicAi24 />
        <Typography variant={"h4"}>Klaudia AI</Typography>
      </HeaderContainer>
      <Typography variant={"body2"}>
        Automate root cause detection with Komodor AI
      </Typography>
      <InfoContainer>
        <Typography variant={"body2"}>Powered by Klaudia AI</Typography>
        <Tooltip
          placement={"top"}
          arrow={false}
          title={
            <>
              Responses are generated by Klaudia AI and have not been vetted by
              humans.{" "}
              <StrictExternalLink
                href="https://help.komodor.com/hc/en-us/articles/22447540595218-Klaudia-AI-Powered-Kubernetes-Troubleshooting"
                color={muiColors.common.white}
              >
                Learn more about Klaudia AI
              </StrictExternalLink>
            </>
          }
        >
          <InfoOutlined fontSize={"inherit"} />
        </Tooltip>
      </InfoContainer>
      <LabeledSwitch
        label={klaudiaEnabled ? "Enabled" : "Disabled"}
        checked={klaudiaEnabled}
        onCheck={handleSwitchCheck}
      />
      <Dialog open={isEnableModalOpen} onClose={handleEnableClose}>
        <KlaudiaAiConsentForm
          isLoading={isLoading}
          onClose={handleEnableClose}
          onEnable={handleEnable}
        />
      </Dialog>
      <Dialog open={isDisableModalOpen} onClose={handleDisableClose}>
        <KlaudiaAiDisableForm
          isLoading={isLoading}
          onClose={handleDisableClose}
          onDisable={handleDisable}
        />
      </Dialog>
    </PlanContainer>
  );
};
