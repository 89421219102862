import { EmailDigestConfiguration } from "@/generated/emailDigestService";

export const initialEmailDigestionConfigurationState: EmailDigestConfiguration =
  {
    isSubscribed: true,
    frequency: "daily",
    scope: {
      clusters: [],
    },
    content: {
      failedDeploys: {
        isEnabled: true,
      },
      issues: {
        isEnabled: true,
      },
      reliability: {
        isEnabled: true,
        violations: {
          severities: {
            high: true,
            medium: true,
            low: true,
          },
        },
      },
    },
  };
