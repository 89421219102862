import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1InstallationsPostUrl,
  Installation,
  InstallationCreateRequest,
  InstallationsApiApiV1InstallationsPostRequest,
} from "../../../../../generated/accounts";
import { useAccountsApiClient } from "../apiClient";

export type InstallationCreateFnAsync = (
  vars: InstallationCreateRequest
) => Promise<Installation>;

const createInstallation = async (
  apiClient: AxiosInstance,
  params: InstallationsApiApiV1InstallationsPostRequest
): Promise<Installation> => {
  const { data } = await apiClient.post(
    apiV1InstallationsPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.installationCreateRequest
  );
  return data;
};

export const useCreateInstallation = () => {
  const apiClient = useAccountsApiClient();
  return useMutation(
    (vars: InstallationCreateRequest) =>
      createInstallation(apiClient, { installationCreateRequest: vars }),
    {}
  );
};
