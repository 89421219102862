import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { Props } from "recharts/types/component/Legend";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PREVIOUS_AVG_TEXT, CURRENT_AVG_TEXT } from "../ComparisonGraph";
import { ColorIndication } from "../../../styles";

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const LegendItemContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const CustomLegend: React.FC<Props> = (props) => {
  const valueMapper: Record<string, string> = useMemo(() => {
    return {
      value: "Issues",
      /** TODO: get dynamic time period, ask for proper text */
      [CURRENT_AVG_TEXT]: "This week’s average",
      [PREVIOUS_AVG_TEXT]: "Last week’s average",
    };
  }, []);
  const { payload } = props;

  const content = useMemo(
    () =>
      payload?.map((legendItem) => (
        <LegendItemContainer key={legendItem.value}>
          <ColorIndication color={legendItem.color} />
          <Typography variant="caption" color={muiColors.gray[600]}>
            {valueMapper[legendItem.value]}
          </Typography>
        </LegendItemContainer>
      )),
    [payload, valueMapper]
  );

  return <Container>{content}</Container>;
};
