export const PodStatuses = {
  CrashLoopBackOff: 0,
  CreateContainerConfigError: 0,
  CreateContainerError: 0,
  ErrImageNeverPull: 0,
  ErrImagePull: 0,
  Error: 0,
  ImageInspectError: 0,
  ImagePullBackOff: 0,
  InvalidImageName: 0,
  OOMKilled: 0,
  RunContainerError: 0,
  StartError: 0,
  Pending: 1,
  ContainerStatusUnknown: 1,
  ContainerCreating: 1,
  Unknown: 1,
  PodInitializing: 1,
  Deleted: 2,
  Evicted: 2,
  Completed: 3,
  Running: 3,
};
