import pluralize from "pluralize";

import { TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = (numOfContainers: number) => (
  <TextContainer>
    {`${numOfContainers} ${pluralize(
      "container",
      numOfContainers
    )} of this workload are missing CPU requests`}
  </TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Defining CPU requests in Kubernetes ensures efficient resource allocation
    and scheduling, prevents resource contention, and enhances cluster stability
    and performance by guaranteeing that containers receive the necessary
    resources while managing their upper limits.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Add CPU requests to your deployment’s containers
  </TextContainer>
);
