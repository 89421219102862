import { SeverityColor } from "@/components/k8sAddons/types";
import {
  CertificateControllerStatusEnum,
  StatusCondition,
  CertificateStatusEnum,
} from "@/generated/addonsApi";

export const certificateStatusToColor: Record<
  CertificateStatusEnum,
  SeverityColor
> = {
  [CertificateStatusEnum.Expired]: "error",
  [CertificateStatusEnum.Failed]: "error",
  [CertificateStatusEnum.Renewing]: "warning",
  [CertificateStatusEnum.Valid]: "success",
  [CertificateStatusEnum.Unknown]: "info",
};

export const certificateControllerStatusToColor: Record<
  CertificateControllerStatusEnum,
  SeverityColor
> = {
  [CertificateControllerStatusEnum.Healthy]: "success",
  [CertificateControllerStatusEnum.Unhealthy]: "error",
};

export const SEARCH_TABLE_PLACEHOLDER = "Search for certificates";

export const conditionPropertyToTitle: Record<keyof StatusCondition, string> = {
  type: "Type",
  status: "Status",
  reason: "Reason",
  message: "Message",
  lastTransitionTime: "Last updated",
  healthState: "Health state",
};

export const DEFAULT_CERT_MANAGER_SORT_BY_FIELD = "expiration";
export const DEFAULT_CERT_MANAGER_SORT_BY_DIRECTION = "asc";
