import { useCallback, useMemo, useState } from "react";
import { SxProps, Theme } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { MuiSelectionOption, MultiSelectPopover } from "../../Select";
import Button from "@mui/material/Button";
import ViewWeekSharpIcon from "@mui/icons-material/ViewWeekSharp";

interface ManageColumnsButtonProps {
  columns: GridColDef[];
  columnsVisibility: GridColumnVisibilityModel | undefined;
  setColumnsVisibility: (
    newColumnsVisibility: GridColumnVisibilityModel
  ) => void;
  sx?: SxProps<Theme>;
  untouchableColumns?: string[];
}

export const ManageColumnsButton: React.FC<ManageColumnsButtonProps> = ({
  columns,
  columnsVisibility,
  setColumnsVisibility,
  sx,
  untouchableColumns = [],
}) => {
  const [columnsAnchorEl, setColumnsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const options = useMemo(
    () =>
      columns
        .filter((c) => !untouchableColumns.includes(c.field) && !c.hidden)
        .map(({ headerName, field }) => {
          return { label: headerName ?? "", value: field ?? "" };
        }),
    [columns]
  );

  const value = useMemo(() => {
    if (!columnsVisibility || !Object.keys(columnsVisibility).length) {
      return options;
    }
    return options.filter((option) => columnsVisibility[option.value] ?? true);
  }, [columnsVisibility, options]);

  const onChange = useCallback(
    (selectedOptions: MuiSelectionOption<string>[]) => {
      setColumnsVisibility(
        options.reduce((acc, { value }) => {
          acc[value] = selectedOptions.some((option) => option.value === value);
          return acc;
        }, {} as GridColumnVisibilityModel)
      );
    },
    [options, setColumnsVisibility]
  );

  return (
    <>
      <Button
        startIcon={<ViewWeekSharpIcon />}
        sx={sx}
        onClick={(e) => setColumnsAnchorEl(e.currentTarget)}
      >
        Columns
      </Button>
      <MultiSelectPopover
        anchorEl={columnsAnchorEl}
        onClose={() => setColumnsAnchorEl(null)}
        options={options}
        value={value}
        onChange={onChange}
        texts={{
          searchPlaceholder: "Search",
        }}
        selectPopoverProps={{
          width: "auto",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "right" },
        }}
      />
    </>
  );
};
