import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { compact, isEqual } from "lodash";

export type StateInMultipleSearchParamsType = {
  searchParams: { [key: string]: string[] };
  setSearchParams: (
    newState: { [key: string]: string[] | null },
    replace?: boolean
  ) => void;
  clearSearchParams: () => void;
};

export const useStateInMultipleSearchParams = (
  keys: string[]
): StateInMultipleSearchParamsType => {
  const location = useLocation();
  const navigate = useNavigate();

  // A buffer-state is created, which is updated only when one of the search parameters in `keys` is updated.
  const [state, setSpecifiedKeyValues] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    // `currentKeyValues` is an object constructed only from the search parameters that are included in `keys`.
    const currentKeyValues = Object.fromEntries(
      Array.from(new URLSearchParams(location.search).entries())
        .filter(([key]) => keys.includes(key))
        .map(([key, value]) => [key, compact(value.split(","))])
    );

    if (!isEqual(state, currentKeyValues)) {
      setSpecifiedKeyValues(currentKeyValues);
    }
  }, [keys, location.search, state]);

  const setState = useCallback(
    (newState: { [key: string]: string[] | null }, replace = false) => {
      const searchParams = new URLSearchParams(window.location.search);

      Object.entries(newState).forEach(([key, values]) => {
        if (values === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, values.join(","));
        }
      });

      const newLocation = {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      };
      navigate(newLocation, { replace });
    },
    [navigate]
  );

  const clearSearchParams = useCallback(() => {
    setState(keys.reduce((params, p) => ({ ...params, [p]: null }), {}));
  }, [keys, setState]);

  return {
    searchParams: state,
    setSearchParams: setState,
    clearSearchParams,
  };
};
