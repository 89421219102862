import { useMemo } from "react";

import { messageEnrichers } from "@/components/InvestigationMode/messageEnrichment/enrichersStore";
import { AiInvestigationOutput } from "@/components/InvestigationMode/availability/AiInvestigation/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useGetLatestSessionForResource } from "@/shared/hooks/ai-api/client/sessions/useGetSession";
import { AvailabilityResource } from "@/shared/store/investigationModeStore/investigationModeStore";

export function useShowAiInvestigationSection(
  requestId: string,
  issue: AvailabilityResource | undefined,
  wfAiInvestigationOutput: AiInvestigationOutput | undefined,
  wfClosedAt: Date | null,
  errorMessages?: string[]
): { showSection: boolean; completedSessionId?: string } {
  const resource = useMemo(
    () => ({
      requestId,
      cluster: issue?.cluster ?? "",
      namespace: issue?.namespace ?? "",
      name: issue?.name ?? "",
      kind: issue?.kind ?? "",
    }),
    [issue?.cluster, issue?.kind, issue?.name, issue?.namespace, requestId]
  );
  const { data } = useGetLatestSessionForResource(resource, {
    enabled: !!resource,
    staleTime: 0,
    cacheTime: 0,
  });

  const {
    klaudiaAiSavedAvailabilityIssue,
    klaudiaAiAvailabilityIssueTriageDrawer,
  } = useOverridableFlags();
  const aiInvestigationSessionId = wfAiInvestigationOutput?.sessionId;
  const enrichedMessage = useMemo(() => {
    if (!errorMessages || errorMessages.length === 0) {
      return undefined;
    }

    return messageEnrichers.enrich(errorMessages[0]);
  }, [errorMessages]);

  if (klaudiaAiSavedAvailabilityIssue && aiInvestigationSessionId) {
    return {
      showSection: true,
      completedSessionId: aiInvestigationSessionId,
    };
  }

  if (data?.session) {
    return {
      showSection: true,
    };
  }

  if (wfClosedAt) {
    return { showSection: false };
  }

  if (
    klaudiaAiAvailabilityIssueTriageDrawer &&
    !enrichedMessage?.hasPrecedenceOverAIInvestigation
  ) {
    return { showSection: true };
  }

  return { showSection: false };
}
