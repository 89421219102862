import { ALL_CLUSTER_NAMES_SELECTOR } from "../../components/CostOptimizationSettings/constants";
import { CostOptimizationConfig } from "../../types/costOptimizationTypes";

type ConfigSettings = {
  clusterName: string;
  configuration: CostOptimizationConfig;
};

type ConfigSettingsResponse = {
  configurations: ConfigSettings[];
};
export const mockConfigSettings: ConfigSettingsResponse = {
  configurations: [],
};

export const mockConfigSettingsPopulated: ConfigSettingsResponse = {
  configurations: [
    {
      clusterName: ALL_CLUSTER_NAMES_SELECTOR,
      configuration: {
        monthlyCpuCost: 1234,
        monthlyMemoryCost: 4567,
      } as CostOptimizationConfig,
    },
  ],
};
