import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import {
  TimeWindowHistogram,
  HistogramProps,
  TimeWindow as HistogramTimeWindow,
} from "@komodorio/design-system/komodor-ui";
import { Typography } from "@komodorio/design-system/deprecated";

import { TimeWindow } from "../../../../../../../shared/types/TimeWindow";
import { useWindowResize } from "../../../../../../../shared/hooks/useWindowResize";

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1000px;
  height: 250px;
`;

const Title = styled(Typography)`
  margin: 30px 0;
`;

const EmptyContentContainer = styled(Typography)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type HistogramItemContainerProps = {
  data: HistogramProps["data"];
  timeWindow: TimeWindow;
  title: string;
  histogramProps?: Partial<HistogramProps>;
  fetching: boolean;
};

export const HistogramItemContainer: React.FC<HistogramItemContainerProps> = ({
  data,
  timeWindow,
  title,
  histogramProps,
  fetching,
}: HistogramItemContainerProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState([0, 0]);
  const onWindowResize = useCallback(() => {
    if (divRef.current) {
      const { clientWidth, clientHeight } = divRef.current;
      setSize([clientWidth, clientHeight]);
    }
  }, []);

  useWindowResize(onWindowResize);

  const isEmptyData = !fetching && data.length === 0;

  return (
    <StyledContainer ref={divRef}>
      <Title variant={"title"} bold size="medium">
        {title}
      </Title>
      <div style={{ width: size[0], height: Math.min(size[1], 220) }}>
        <TimeWindowHistogram
          {...histogramProps}
          data={data}
          timeWindow={timeWindow as unknown as HistogramTimeWindow}
        />
      </div>
      {isEmptyData && (
        <EmptyContentContainer size={"large"} color={palette.gray["600"]} bold>
          There are no events to show
        </EmptyContentContainer>
      )}
    </StyledContainer>
  );
};
