import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import { useState } from "react";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { ClusterGroupForm } from "./ClusterGroupForm";

import { Workspace } from "@/generated/workspacesApi";
import {
  TypedClustersGroupWorkspace,
  TypedWorkspace,
} from "@/shared/hooks/workspaces-api/types";

const StyledDialog = styled(Dialog)`
  display: flex;
  flex-direction: row;

  & .MuiDialog-container {
    width: 100%;
  }
`;

type AddEditWorkspaceModalProps = {
  existingWorkspace?: Workspace;
  onClose: (newWorkspace?: TypedWorkspace) => void;
};

export function AddEditWorkspaceModal({
  existingWorkspace,
  onClose,
}: AddEditWorkspaceModalProps) {
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);
  return (
    <StyledDialog
      open={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: "580px",
          height: "702px",
        },
      }}
      onClose={() => setIsConfirmationModalShown(true)}
    >
      {isConfirmationModalShown ? (
        <ConfirmationDialog
          title="Discard changes?"
          okText="Discard"
          cancelText="Cancel"
          onClose={(ok) => {
            if (ok) {
              onClose();
            }
            setIsConfirmationModalShown(false);
          }}
          content={
            <Typography variant="body2">
              Are you sure you want to discard unsaved changes?
            </Typography>
          }
        />
      ) : null}
      <ClusterGroupForm
        existingWorkspace={existingWorkspace as TypedClustersGroupWorkspace}
        onSuccess={onClose}
        onCancel={() => {
          setIsConfirmationModalShown(true);
        }}
      />
    </StyledDialog>
  );
}
