import React from "react";
import { TooltipProvider } from "react-tooltip";

import { EventsLimitProvider } from "../../components/Freemium/useEventsLimit";
import { ServiceAttributesProvider } from "../../components/servicesView/servicesAttributes/useAllServicesAttributes";
import { AgentsProvider } from "../hooks/useAgents";
import { BannerProvider } from "../hooks/useBanner";
import { FreemiumModalsProvider } from "../hooks/useFreemiumModals";
import { ServicesProvider } from "../hooks/useKomodorServices";
import { UserLoginInfoProvider } from "../hooks/useUserMetadata/useUserLoginInfo";

import { PodExecSessionsProvider } from "./PodExecSessionsProvider";
import { TimezoneProvider } from "./TimezoneProvider";
import { WebSocketsProvider } from "./WebSocketsProvider";
import { ServicesPageFiltersLocalStorageProvider } from "./ServicesPageFiltersLocalStorageProvider";
import { JobsPageFiltersLocalStorageProvider } from "./JobsPageFiltersLocalStorageProvider";
import { DesktopAgentsStatusesProvider } from "./electron/desktopAgentsProvider/desktopAgentsStatusesProvider";
import { DesktopClustersConnectionProvider } from "./electron/desktopAgentsProvider/desktopClustersConnectionProvider";
import { PortForwardProvider } from "./PortForwardProvider";
import { ThemeProvider } from "./ThemeProvider";
import { AiInvestigationsSessionsProvider } from "./aiInvestigations/AiInvestigationsProvider";

const AppProviders: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeProvider>
      <TimezoneProvider>
        <ServicesProvider>
          <ServicesPageFiltersLocalStorageProvider>
            <JobsPageFiltersLocalStorageProvider>
              <ServiceAttributesProvider>
                <AgentsProvider>
                  <TooltipProvider>
                    <UserLoginInfoProvider>
                      <BannerProvider>
                        <FreemiumModalsProvider>
                          <EventsLimitProvider>
                            <WebSocketsProvider>
                              <PodExecSessionsProvider>
                                <AiInvestigationsSessionsProvider>
                                  <DesktopAgentsStatusesProvider>
                                    <DesktopClustersConnectionProvider>
                                      <PortForwardProvider>
                                        {children}
                                      </PortForwardProvider>
                                    </DesktopClustersConnectionProvider>
                                  </DesktopAgentsStatusesProvider>
                                </AiInvestigationsSessionsProvider>
                              </PodExecSessionsProvider>
                            </WebSocketsProvider>
                          </EventsLimitProvider>
                        </FreemiumModalsProvider>
                      </BannerProvider>
                    </UserLoginInfoProvider>
                  </TooltipProvider>
                </AgentsProvider>
              </ServiceAttributesProvider>
            </JobsPageFiltersLocalStorageProvider>
          </ServicesPageFiltersLocalStorageProvider>
        </ServicesProvider>
      </TimezoneProvider>
    </ThemeProvider>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AppProviders;
