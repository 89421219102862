import { AllocationCostDataPoints } from "../../../../../../generated/metricsApi";
import { AllocationFiltersTimeWindow } from "../../../../types/costOptimizationTypes";
import { getDateKey } from "../../../../utils/graphUtils";

export const BAR_CHART_TIME_LABEL_NAME = "barChartTimeKey";

export interface BarChartData {
  [key: string]: number | string;
}

const othersKey = "Others";

const formatToBarChartData = (
  chartData: Record<string, { [key: string]: number }>
) => {
  const result: BarChartData[] = [];

  Object.entries(chartData).forEach(([key, value]) => {
    result.push({
      ...value,
      [BAR_CHART_TIME_LABEL_NAME]: Number(key),
    });
  });

  return result;
};

const getChartKeys = (data: BarChartData[]) => {
  const sumObj = data.reduce<{ [key: string]: number }>((acc, obj) => {
    delete obj[BAR_CHART_TIME_LABEL_NAME];
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === "number") {
        if (acc[key]) {
          acc[key] = Number(acc[key]) + value;
        } else {
          acc[key] = value;
        }
      }
    });
    return acc;
  }, {});

  const isContainOther = Boolean(sumObj[othersKey]);
  delete sumObj[othersKey];

  const sortedKeys = Object.entries(sumObj)
    .sort((a, b) => a[1] - b[1])
    .map((entry) => entry[0]);

  return isContainOther ? [othersKey, ...sortedKeys] : sortedKeys;
};

export const getBarChartData = (
  data: AllocationCostDataPoints | undefined,
  timeWindow: AllocationFiltersTimeWindow
) => {
  if (!data?.datapoints || !Object.keys(data.datapoints).length)
    return undefined;

  const barCharData = formatToBarChartData(data.datapoints);

  barCharData.sort(
    (a, b) =>
      Number(a[BAR_CHART_TIME_LABEL_NAME]) -
      Number(b[BAR_CHART_TIME_LABEL_NAME])
  );

  return {
    data: barCharData.map((item) => ({
      ...item,
      [BAR_CHART_TIME_LABEL_NAME]: getDateKey(
        Number(item[BAR_CHART_TIME_LABEL_NAME]),
        timeWindow
      ),
    })) as BarChartData[],
    chartKeys: getChartKeys(barCharData),
  };
};
