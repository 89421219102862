import styled from "styled-components";
import React from "react";
import { format } from "date-fns";
import Warning from "@mui/icons-material/Warning";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { getIsEolDatePassed, getTimeToEolString } from "../utils";
import { ValueTypography } from "../../../shared/ViolationSummary/ViolationSummary";

export const timeFormat = "MMM d ,yyyy";

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const EolDate: React.FC<{ eol: string }> = ({ eol }) => {
  const eolDate = new Date(eol);
  const isEolDatePassed = getIsEolDatePassed(eolDate);
  return (
    <Container>
      <ValueTypography>{format(eolDate, timeFormat)}</ValueTypography>
      <Warning fontSize="small" style={{ color: muiColors.pink[700] }} />
      <Typography variant="h5" color={muiColors.pink[700]}>
        {!isEolDatePassed && "in "}
        {getTimeToEolString(eolDate)}
        {isEolDatePassed && " ago"}
      </Typography>
    </Container>
  );
};

export const SuggestedVersion: React.FC<{
  suggestion: string;
  suggestionEOL: string;
}> = ({ suggestion, suggestionEOL }) => {
  const eolDate = new Date(suggestionEOL);

  return (
    <Container>
      <ValueTypography>v{suggestion}</ValueTypography>
      <Typography variant="body2" color="text.secondary">
        will reach EOL in {getTimeToEolString(eolDate)} (
        {format(eolDate, timeFormat)})
      </Typography>
    </Container>
  );
};
