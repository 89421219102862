import React, { useState } from "react";

import GuideTooltip, { SentryImageGuide } from "../GuideToolTip";
import { StyledLinkText } from "../styles";
import tokensCredentialsGuide from "../assets/token-credentials-guide.png";

const TokenGuideToolTip: React.FC = () => {
  const [showTokenHelp, setShowTokenHelp] = useState(false);
  return (
    <StyledLinkText>
      <GuideTooltip
        show={showTokenHelp}
        handleClose={() => {
          setShowTokenHelp(false);
        }}
      >
        <SentryImageGuide
          imageSource={tokensCredentialsGuide}
          maxHeight="10rem"
        />
      </GuideTooltip>
      <span
        onClick={() => {
          setShowTokenHelp(true);
        }}
      >
        Where do I get it?
      </span>
    </StyledLinkText>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TokenGuideToolTip;
