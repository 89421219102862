import React, { useMemo } from "react";

import { DescribeTextItem } from "@/components/ResourceView/tabs/DescribeTab/common/DescribeItem";
import {
  getWorkflowLabels,
  isWorkflowResource,
} from "@/components/k8sAddons/addons/workflows/workflowUtils";
import { Addon, Entity } from "@/generated/addonsApi";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { useOpenLiveStateDrawer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerHooks";
import { WORKFLOW_RUN_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";

export interface WorkflowResourceLinkProps {
  cluster: string;
  namespace: string;
  labels: Record<string, string>;
}
export const WorkflowResourceLink = ({
  cluster,
  namespace,
  labels,
}: WorkflowResourceLinkProps) => {
  const isWorkflowPod = isWorkflowResource(labels);
  const { engine, dagId, runId } = getWorkflowLabels(labels);

  const openLiveStateDrawer = useOpenLiveStateDrawer();

  const liveStateProps = useMemo(() => {
    return {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid:
        buildKomodorWorkflowId({
          cluster,
          namespace,
          engine: engine,
          dagId: dagId,
        }) ?? "",
      name: dagId,
      getEntityRequest: {
        clusterName: cluster,
      },
      clusterName: cluster,
      urlStates: {
        [WORKFLOW_RUN_PARAM_KEY]: runId,
      },
    };
  }, [cluster, namespace, engine, dagId, runId]);

  if (!isWorkflowPod) {
    return null;
  }

  const onValueClick = () => {
    openLiveStateDrawer(liveStateProps);
  };

  return (
    <DescribeTextItem
      name="Workflow"
      value={dagId}
      onValueClick={onValueClick}
    />
  );
};
