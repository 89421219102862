import React from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import PodsContentAtm from "@/components/ResourceView/tabs/PodsTab/PodsData";
import { Panel } from "@/components/Inspection/styles";

export const WorkflowPodsTab: React.FC<{ resource: WorkflowRun }> = ({
  resource,
}) => {
  const agentId = useActiveAgent(resource.cluster);

  if (!agentId) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return (
    <PodsContentAtm
      resource={resource}
      agentId={agentId}
      fetchDeletedPods
      deletedPodsTimeWindow={resource.timeWindow}
    />
  );
};
