export const ariaLabels = {
  getKubeConfButton: "Get Kubeconfig Button",
  timeRemaining: {
    dialog: "Time Remaining Dialog",
    title: "Time Remaining Title",
    content: "Time Remaining Content",
    contentTimer: "Time Remaining Content Timer",
    closeButton: "Time Remaining Close Button",
  },
};
