import { CertificateFullStatus } from "@/generated/addonsApi";

export const tooltipStatusTexts: Record<keyof CertificateFullStatus, string> = {
  notBefore:
    "The date and time when the certificate was issued and became active",
  renewalTime:
    "The date and time when the certificate is scheduled for renewal before expiration.",
  notAfter:
    "The date and time when the certificate will expire and needs renewal to maintain service availability.",
  conditions:
    "The status conditions of the Certificate resource. Hover over a condition status for more information.",
};
