import { RightSizingCostStatsResponse } from "../../../../generated/metricsApi";

import { getRandRange } from "./getRandRange";

export const generateMockCostRightSizingStats =
  (): RightSizingCostStatsResponse => {
    const currentCostPerMonth = Math.floor(Math.random() * 100000);
    const monthlySaving = getRandRange(
      currentCostPerMonth * 0.1,
      currentCostPerMonth * 0.5
    );
    const potentialSavingsModerate = monthlySaving;
    const potentialSavingsConservative = monthlySaving * 0.9;
    const potentialSavingsAggressive = monthlySaving * 1.1;

    const yearlySaving = monthlySaving * 12;

    const unallocatedResourcesPrice = getRandRange(
      monthlySaving * 0.3,
      monthlySaving * 0.7
    );

    return {
      currentCostPerMonth,
      monthlySaving,
      potentialSavingsModerate,
      potentialSavingsConservative,
      potentialSavingsAggressive,
      yearlySaving,
      unallocatedResourcesPrice,
    };
  };
