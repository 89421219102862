import { lazy, Lazy, object, string } from "yup";

import { ApiDeprecationState } from "@/generated/reliabilityApi";
import { policyValidationErrorMessages } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";

const { apiDeprecationStateErrorMessage } = policyValidationErrorMessages;

type ApiDeprecationObject = {
  high?: ApiDeprecationState | undefined;
  medium?: ApiDeprecationState | undefined;
  low?: ApiDeprecationState | undefined;
};

const validateWithPrecedingValue = (
  value: ApiDeprecationState | undefined,
  precedingValue: ApiDeprecationState | undefined
) => {
  return (
    !value ||
    !precedingValue ||
    value === "Deprecated" ||
    (value === "Removed" && precedingValue === "Removed")
  );
};

export const deprecatedApiStateSchema: Lazy<ApiDeprecationObject> = lazy(
  (values) => {
    return object().shape({
      high: string<ApiDeprecationState>(),
      medium: string<ApiDeprecationState>().test(
        "medium with high",
        apiDeprecationStateErrorMessage,
        function validate(value) {
          return validateWithPrecedingValue(value, values.high);
        }
      ),
      low: string<ApiDeprecationState>()
        .test(
          "low with high",
          apiDeprecationStateErrorMessage,
          function validate(value) {
            return validateWithPrecedingValue(value, values.high);
          }
        )
        .test(
          "low with medium",
          apiDeprecationStateErrorMessage,
          function validate(value) {
            return validateWithPrecedingValue(value, values.medium);
          }
        ),
    });
  }
);
