import React from "react";

import { ScopesContainer } from "../common/ScopeSection";
import ScopeTypeButton from "../common/ScopeSection/ScopeTypeButton";

const NodeScopeSection: React.FC = () => {
  return (
    <ScopesContainer>
      <ScopeTypeButton
        mainText="Cluster"
        subText="The rule will monitor the entire cluster"
        handleScopeChange={() => null}
        checked
      />
      <ScopeTypeButton
        mainText="Custom scope"
        subText="Select a specific scope to monitor"
        handleScopeChange={() => null}
        checked={false}
        disabled
      />
    </ScopesContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default NodeScopeSection;
