import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Circle from "@mui/icons-material/Circle";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import { CloudProvider } from "../../../shared/components/CloudProvider/CloudProvider";
import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import {
  ClusterSummary,
  ClusterProviderType,
} from "../../../generated/workspacesApi";

const Container = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: baseline;
  max-width: 192px;
`;

const InactiveIndication: React.FC<{
  inactive: boolean | undefined;
  lastSeenAlive: string | undefined;
}> = ({ inactive, lastSeenAlive }) => {
  const { format } = useDateFormatter();

  const circle = (
    <Circle
      sx={{
        height: "8px",
        width: "8px",
        color: muiColors.gray[400],
      }}
      aria-label={AriaLabels.ClustersView.InactiveDot}
    />
  );

  const tooltipContent = (
    <TooltipContainer>
      {circle}
      <Typography variant="body3">
        Komodor Agent Last Heartbeat: {format(new Date(lastSeenAlive ?? ""))}
      </Typography>
    </TooltipContainer>
  );

  return inactive ? (
    <LightTooltip
      title={tooltipContent}
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      <Box sx={{ padding: "4px" }}>{circle}</Box>
    </LightTooltip>
  ) : null;
};

export const ClusterCell: React.FC<{ cluster: ClusterSummary }> = ({
  cluster,
}) => {
  const { clusterName, inactive, lastSeenAlive, provider } = cluster;

  return (
    <Container>
      <InactiveIndication inactive={inactive} lastSeenAlive={lastSeenAlive} />
      <CloudProvider clusterProviderType={provider as ClusterProviderType} />
      <LightTooltip title={clusterName} placement="top" enterDelay={1000}>
        <Typography variant="body2">{clusterName}</Typography>
      </LightTooltip>
    </Container>
  );
};
