import { useMemo } from "react";
import { Record, String, Boolean, Null, Static, Unknown } from "runtypes";

import { useOverridableFlags } from "../../../context/featureFlags/OverridableFlags";
import { getATMGetResourceURL } from "../client";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  useAtmRequest,
  AtmOutputType,
  AtmRequestResult,
  Status,
} from "../useAtmRequest";

const GetResourceResponse = Record({
  data: Unknown,
  isPaginating: Boolean,
  mostRecentUpdate: String.Or(Null),
  sessionId: String,
  failureMessage: String.Or(Null),
});
export type AtmGetResourceResponseType = Static<typeof GetResourceResponse>;

export interface RequestGetResourceBody {
  namespace: string;
  agentId: string;
  clusterName: string;
  name: string;
  resourceKind: string;
  outputType: AtmOutputType;
}
const useAtmGetResource = (
  agentId: string,
  cluster: string,
  namespace: string | undefined = "",
  resourceKind: string,
  resourceName: string,
  outputType: AtmOutputType,
  handleResponse: (responseJSON: AtmGetResourceResponseType) => void
): AtmRequestResult => {
  const { inspectionAtm } = useOverridableFlags();

  const reqBody = useMemo<RequestGetResourceBody | null>(() => {
    if (!inspectionAtm || !cluster || !agentId) return null;
    return {
      agentId,
      clusterName: cluster,
      namespace,
      name: resourceName,
      resourceKind,
      outputType,
    };
  }, [
    agentId,
    cluster,
    inspectionAtm,
    namespace,
    outputType,
    resourceKind,
    resourceName,
  ]);

  const handleValidatedResponse = (response: AtmGetResourceResponseType) => {
    if (GetResourceResponse.guard(response)) {
      handleResponse(response);
      return true;
    }
    return false;
  };

  return useAtmRequest(
    getATMGetResourceURL(),
    JSON.stringify(reqBody),
    handleValidatedResponse
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useAtmGetResource;

export interface GetResourceParsedResponse {
  data: unknown;
  fetching: boolean;
  errorMessage: string;
  isPaginating?: boolean;
  emptyResult: boolean;
}
export const useParseAtmGetResourceResult = (
  status: Status,
  errorMessage: string,
  atmResult?: AtmGetResourceResponseType
): GetResourceParsedResponse => {
  return useMemo(
    () => ({
      fetching:
        status === Status.Initializing ||
        (!!atmResult?.isPaginating && atmResult?.data === null),
      emptyResult:
        status !== Status.Initializing &&
        !atmResult?.isPaginating &&
        atmResult?.data === null,
      isPaginating: atmResult?.isPaginating,
      errorMessage: errorMessage ?? atmResult?.failureMessage ?? "",
      data: atmResult?.data,
    }),
    [
      atmResult?.data,
      atmResult?.failureMessage,
      atmResult?.isPaginating,
      errorMessage,
      status,
    ]
  );
};
