import { useMemo } from "react";

import { FormattedAccount } from "./types";

import { Account } from "@/generated/accounts";

export const useGetParseState = <T>(state: string, defaultValue: T): T => {
  return useMemo(() => {
    try {
      const parsed = JSON.parse(state);
      return parsed ?? defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }, [defaultValue, state]);
};

export const getFormattedAccounts = (accounts: Account[]): FormattedAccount[] =>
  accounts.map((account) => ({
    label: account.displayName ?? account.name,
    value: account.id,
    isDisabled: account.disabled,
  }));
