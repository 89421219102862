import React, { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { NodesCountTable } from "@/components/Settings/General/NodesCount/NodesCountTable";
import { ariaLabels } from "@/pages/organization-settings/account/UsagePage/constants";
import { useUsagePage } from "@/pages/organization-settings/account/UsagePage/useUsagePage";
import {
  getDisplayedClustersNames,
  mapOptions,
} from "@/pages/organization-settings/account/UsagePage/utils";
import { NodeCountStackedBarChart } from "@/pages/organization-settings/account/UsagePage/StackedBarChart/NodeCountStackedBarChart";

export const UsagePage: React.FC = () => {
  const { ref, width } = usePageWidth();

  const [selectedClusters, setSelectedClusters] = useState<
    MuiSelectionOption<string>[]
  >([]);

  const { isError, isFetching, data, sortedMonths, clusters } = useUsagePage();

  const { clustersOptionsForDropdown, clustersForTable } = useMemo(() => {
    const clustersOptionsForDropdown = mapOptions(clusters as string[]);

    const clustersForTable = getDisplayedClustersNames(
      clustersOptionsForDropdown,
      selectedClusters
    );

    return { clustersOptionsForDropdown, clustersForTable };
  }, [selectedClusters, clusters]);

  return (
    <SettingsViewVerticalLayout title="Usage">
      <Stack direction="column" spacing={3} ref={ref} height="100%">
        <AdvancedMultiSelect
          label="Clusters"
          placeholder="Select Clusters"
          options={clustersOptionsForDropdown}
          ariaLabel={ariaLabels.filter}
          value={selectedClusters}
          onChange={setSelectedClusters}
          width="220px"
          selectPopoverProps={{
            maxHeight: "600px",
          }}
        />
        <Stack direction="column" rowGap={1}>
          <Typography variant="h4">
            Top Clusters by Average Node Count
          </Typography>
          <NodeCountStackedBarChart
            sortedMonths={sortedMonths}
            data={data}
            clusters={clustersForTable}
          />
        </Stack>
        <Stack direction="column" rowGap={1} flex="1 1 auto">
          <Typography variant="h4">Nodes Count</Typography>
          <NodesCountTable
            width={width}
            isError={isError}
            isFetching={isFetching}
            sortedMonths={sortedMonths}
            data={data}
            clusters={clustersForTable}
          />
        </Stack>
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
