import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Box from "@mui/material/Box";
import Warning from "@mui/icons-material/Warning";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import Button from "@mui/material/Button";
import ChevronRight from "@mui/icons-material/ChevronRight";

import WorkflowIssueEventGroup from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import EventDrawer from "../../../common/ProcessList/EventDrawer";
import { IssueBannerContainer } from "../../../IssueBanner/IssueBanner";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { TriageDataDb } from "../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { useInsights } from "../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/insights/useInsights";
import { SmartTextsJoin } from "../../../ClustersView/ClusterOverview/components/UnhealthyServicesSection/SmartTextsJoin";
import { useGetMonitorRunById } from "../../../../shared/hooks/monitors-api/client/monitors/useGetMonitorRunById";
import { WorkflowConfigType } from "../../../monitorsView/common/types";

type AvailabilityIssueBannerProps = {
  issueEventGroup: WorkflowIssueEventGroup;
};
export const AvailabilityIssueBanner: React.FC<
  AvailabilityIssueBannerProps
> = ({ issueEventGroup }) => {
  const [isEventDrawerOpen, setIsEventDrawerOpen] = useState(false);

  const { data, isLoading } = useGetMonitorRunById({
    id: issueEventGroup.id,
    type: WorkflowConfigType.Availability,
    fields: ["triageData"],
  });
  const insights = useInsights(data?.data?.triageData as TriageDataDb);

  if (isLoading) {
    return null;
  }

  const onCloseIssue = () => setIsEventDrawerOpen(false);

  return (
    <>
      <IssueBannerContainer
        aria-label={AriaLabels.ResourceView.IssueBanner.Pod.AvailabilityIssue}
        bgColor={muiColors.pink[25]}
        onClick={() => setIsEventDrawerOpen(true)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: "6px" }}>
            <Box
              sx={{ display: "flex", columnGap: "6px", alignItems: "center" }}
            >
              <Warning
                sx={{ width: 20, height: 20, color: muiColors.pink[600] }}
              />
              <Typography
                variant="overline2"
                sx={{ color: muiColors.pink[700] }}
              >
                The service controlling this pod is experiencing an Availability
                issue
              </Typography>
            </Box>
            {insights.length > 0 ? (
              <Box
                sx={{ display: "flex", columnGap: "6px", alignItems: "center" }}
              >
                <Typography variant="overline2" color="text.secondary">
                  Insights
                </Typography>
                <LightningBolt16 color={muiColors.orange[400]} />
                <SmartTextsJoin
                  texts={insights}
                  icon={<LightningBolt16 color={muiColors.orange[400]} />}
                />
              </Box>
            ) : null}
          </Box>
          <Button
            variant="outlined"
            size="small"
            endIcon={<ChevronRight width="10px" height="10px" />}
          >
            Investigate
          </Button>
        </Box>
      </IssueBannerContainer>
      {isEventDrawerOpen ? (
        <EventDrawer open onClose={onCloseIssue} event={issueEventGroup}>
          {issueEventGroup.renderEventDetails(onCloseIssue)}
        </EventDrawer>
      ) : null}
    </>
  );
};
