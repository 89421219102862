import React from "react";
import { addHours } from "date-fns";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { HeatmapGraphDataPoint } from "../../../../../../../../../../Metrics/graphs/HeatmapGraph/heatmapTypes";

import { formatFullDate, formatHour } from "./utils";

type Payload = {
  payload: HeatmapGraphDataPoint;
};

const TooltipContent = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;
`;

const Title = styled(Typography)`
  && {
    font-weight: 500;
    margin-right: 4px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomHeatmapGraphTooltip: React.FC<{ payload?: unknown }> = ({
  payload,
}) => {
  const payloadAsPayload = payload as Payload[];
  if (!payloadAsPayload || !payloadAsPayload.length) return null;
  const { value, time } = payloadAsPayload[0].payload;
  const nextHour = addHours(time, 1).getTime();

  return (
    <TooltipContent>
      <Typography variant={"h6"}>
        {formatFullDate(time)} - {formatHour(nextHour)}
      </Typography>
      <FlexContainer>
        <Title variant={"body3"} color={"text.secondary"}>
          Throttled time:
        </Title>
        <Typography variant={"body2"}>{value}%</Typography>
      </FlexContainer>
    </TooltipContent>
  );
};
