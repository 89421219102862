import React from "react";
import { NodeCondition, PodCondition } from "kubernetes-types/core/v1.d";
import styled from "styled-components";
import { Tag, Typography } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";

import { NodeConditionTooltip } from "../node/NodeConditionTooltip";

import { grayTag, greenTag, orangeTag, pinkTag } from "./StatusTag";
import { DescribeItem } from "./DescribeItem";
import { HpaCondition } from "./HpaCondition";
import { DescribeSectionDivider } from "./DescribeSectionDivider";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum ConditionType {
  // Nodes
  Ready = "Ready",
  MemoryPressure = "MemoryPressure",
  DiskPressure = "DiskPressure",
  PIDPressure = "PIDPressure",
  NetworkUnavailable = "NetworkUnavailable",
  // Pods
  Initialized = "Initialized",
  ContainersReady = "ContainersReady",
  PodScheduled = "PodScheduled",
  // Hpas
  AbleToScale = "AbleToScale",
  ScalingActive = "ScalingActive",
  ScalingLimited = "ScalingLimited",
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getConditionsColorsMap = (
  conditionType: ConditionType
): { [key: string]: { bg: string; fg: string } } => {
  switch (conditionType) {
    case ConditionType.NetworkUnavailable:
      return {
        TRUE: pinkTag,
        FALSE: greenTag,
      };
    case ConditionType.Ready:
    case ConditionType.Initialized:
    case ConditionType.ContainersReady:
    case ConditionType.PodScheduled:
      return {
        TRUE: greenTag,
        FALSE: pinkTag,
      };
    case ConditionType.ScalingActive:
      return {
        TRUE: greenTag,
        FALSE: pinkTag,
      };
    case ConditionType.AbleToScale:
      return {
        TRUE: greenTag,
        FALSE: pinkTag,
      };
    case ConditionType.ScalingLimited:
      return {
        TRUE: pinkTag,
        FALSE: greenTag,
      };
    case ConditionType.MemoryPressure:
    case ConditionType.DiskPressure:
    case ConditionType.PIDPressure:
    default:
      return {
        TRUE: orangeTag,
        FALSE: greenTag,
      };
  }
};

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  cursor: default;
  flex-wrap: wrap;
`;

export const DescribeConditions: React.FC<{
  conditions?: NodeCondition[] | PodCondition[] | HpaCondition[];
  shouldShowTooltip?: boolean;
}> = ({ conditions, shouldShowTooltip = true }) => {
  return conditions ? (
    <>
      <DescribeSectionDivider title={"CONDITIONS"} />

      <DescribeItem name={"Conditions"}>
        <Container>
          {conditions.map((condition) => (
            <div key={condition.type}>
              <TooltipWrapper tooltipId={"condition-tooltip" + condition.type}>
                <ConditionTag condition={condition} />
              </TooltipWrapper>
              {shouldShowTooltip && (
                <NodeConditionTooltip condition={condition} />
              )}
            </div>
          ))}
        </Container>
      </DescribeItem>
    </>
  ) : null;
};

const ConditionTag: React.FC<{ condition: NodeCondition }> = ({
  condition,
}) => {
  const { type, status } = condition;
  const statusUpper = String(status).toUpperCase();
  const map = getConditionsColorsMap(type as ConditionType);
  const color = map[statusUpper] ?? grayTag;
  return (
    <Tag color={color.bg} aria-label="status tag">
      <Typography variant="uppercase" color={color.fg}>
        {type}
      </Typography>
    </Tag>
  );
};
