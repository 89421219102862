import { useDDRumViewAndTimingListener } from "./datadogRumHooks";

export const useReportDDRumWithMultipleFetchings = (
  fetchings: Record<string, boolean | undefined>,
  viewName: string
) => {
  const fetchingsValues = Object.values(fetchings).filter(
    (f) => f !== undefined
  );
  const isFetching = !fetchingsValues.length
    ? true
    : fetchingsValues.some((f) => f);

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName,
    addTimingParams: {
      enable: !isFetching,
    },
  });
};
