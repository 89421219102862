import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  UsersApiApiV1UsersIdEffectivePermissionsGetRequest,
  EffectivePermission,
  apiV1UsersIdEffectivePermissionsGetUrl,
} from "../../../../../generated/auth";

const PATH = "/users/:id/effective-permissions";

const fetchUserEffectivePermissions = async (
  apiClient: AxiosInstance,
  params: UsersApiApiV1UsersIdEffectivePermissionsGetRequest
): Promise<EffectivePermission[]> => {
  const { data } = await apiClient.get(
    apiV1UsersIdEffectivePermissionsGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetUserEffectivePermissions = (
  params: UsersApiApiV1UsersIdEffectivePermissionsGetRequest,
  enabled?: boolean,
  forceRefetch?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [PATH, params],
    () => fetchUserEffectivePermissions(apiClient, params),
    {
      enabled,
      staleTime: forceRefetch ? 0 : undefined,
    }
  );
};
