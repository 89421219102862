import React from "react";
import MuiTypography from "@mui/material/Typography";
import styled from "styled-components";

export const SectionContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: ${({ width }) => width ?? "100%"};
`;

export const SettingsSectionLayout: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <SectionContainer width={"30rem"}>
      <MuiTypography variant="h3">{title}</MuiTypography>
      {children}
    </SectionContainer>
  );
};
