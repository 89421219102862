import { AxiosInstance } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  apiV1HelmReposPostUrl,
  CreateHelmRepoRequest,
  HelmRepo,
  HelmReposApiApiV1HelmReposPostRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

const createHelmRepo = async (
  apiClient: AxiosInstance,
  params: HelmReposApiApiV1HelmReposPostRequest
): Promise<HelmRepo> => {
  const { data } = await apiClient.post(
    apiV1HelmReposPostUrl(params, apiClient.defaults.baseURL ?? ""),
    params.createHelmRepoRequest
  );
  return data;
};

export const useCreateHelmRepo = () => {
  const apiClient = useResourcesApiClient();
  return useMutation((params: CreateHelmRepoRequest) =>
    createHelmRepo(apiClient, { createHelmRepoRequest: params })
  );
};
