import React, { useCallback } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  useCertificatesColumns,
  useCertificatesRows,
} from "./CertificatesTableHooks";
import { CertificateRelatedResourcesTable } from "./CertificateRelatedResourcesTable";

import {
  CertificateExpirationData,
  CertificateExpirationSupportingData,
} from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const {
  violationDrawer: {
    certificateExpiration: {
      CertificateExpirationTable: certificateExpirationTableAriaLabel,
    },
  },
} = reliabilityArialLabels;

interface Props {
  supportingData: CertificateExpirationSupportingData | undefined;
}

export const CertificatesTable: React.FC<Props> = ({ supportingData }) => {
  const rows = useCertificatesRows(supportingData?.certificates);
  const columns = useCertificatesColumns();

  const getDetailPanelContent = useCallback(
    (row: CertificateExpirationData) => {
      return <CertificateRelatedResourcesTable certificateData={row} />;
    },
    []
  );

  return (
    <Container>
      <Typography variant="h5">Related resources</Typography>
      <DataGridPro
        aria-label={certificateExpirationTableAriaLabel}
        rows={rows}
        columns={columns}
        getRowId={(row) => row?.certificateUid}
        getDetailPanelContent={({ row }) => getDetailPanelContent(row)}
        slots={{
          detailPanelExpandIcon: ChevronRight,
          detailPanelCollapseIcon: ExpandMore,
        }}
        hideFooter
      />
    </Container>
  );
};
