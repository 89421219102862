import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { useRbacClusterScheduleSync } from "@/shared/hooks/auth-service/client/rbacClusterStatus/useRbacClusterScheduleSync";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { ZIndex } from "@/constants/zIndex";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";

const NO_PERMISSIONS_MESSAGE =
  "You do not have the required permission to do that";
const NO_CLUSTERS_SELECTED_MESSAGE = "Select at least one cluster";

export const ToolbarActionRbacClusterScheduleSyncForClusters: React.FC<{
  clustersToSync: string[];
  pushToastMessage: (message: string) => void;
}> = ({ clustersToSync, pushToastMessage }) => {
  const { canManageKubeconfig } = useHasPermissions();
  const { mutateAsync: triggerRbacClusterSynchronization, isLoading } =
    useRbacClusterScheduleSync();
  const hasNoClustersSelected = clustersToSync.length === 0;

  const onClickForceSync = useCallback(() => {
    datadogRum.addAction(
      AnalyticEvents.RbacClusterSync.FeatureManualForceSync,
      {
        clusters: clustersToSync,
      }
    );
    triggerRbacClusterSynchronization({ clusters: clustersToSync })
      .finally(() => {
        pushToastMessage(
          "Scheduled synchronization of RBAC onto all clusters... It will take a few minutes to complete."
        );
      })
      .catch((error) => {
        pushToastMessage(
          `Failed to schedule synchronization of RBAC onto all clusters... Error: ${error}`
        );
      });
  }, [clustersToSync, pushToastMessage, triggerRbacClusterSynchronization]);

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      style={{ zIndex: ZIndex.AboveModal }}
      title={
        !canManageKubeconfig ? (
          <Typography variant="body2">{NO_PERMISSIONS_MESSAGE}</Typography>
        ) : hasNoClustersSelected ? (
          <Typography variant="body2">
            {NO_CLUSTERS_SELECTED_MESSAGE}
          </Typography>
        ) : undefined
      }
    >
      <span>
        {/* This span is necessary for the tooltip to get fired events, a disabled button does not fire events!*/}
        <Button
          variant="contained"
          color="primary"
          onClick={onClickForceSync}
          disabled={isLoading || hasNoClustersSelected || !canManageKubeconfig}
        >
          Force sync
        </Button>
      </span>
    </Tooltip>
  );
};
