import { useMemo } from "react";
import { LoadBalancerIngress } from "kubernetes-types/core/v1.d";

export const HOST_LABEL_DISPLAY_NAME = "Host";
export const IP_LABEL_DISPLAY_NAME = "Public IP";

export const useGetIp = (
  resourceIngress: LoadBalancerIngress[] | undefined
):
  | {
      hostKey: null;
      hostValue: null;
    }
  | {
      hostKey: string;
      hostValue: string;
    } =>
  useMemo(() => {
    const emptyHostProperty = { hostKey: null, hostValue: null };
    const ingressObj = resourceIngress?.[0];
    if (!ingressObj) return emptyHostProperty;

    if (ingressObj.hostname) {
      return {
        hostKey: HOST_LABEL_DISPLAY_NAME,
        hostValue: ingressObj.hostname,
      };
    }
    if (ingressObj.ip) {
      return { hostKey: IP_LABEL_DISPLAY_NAME, hostValue: ingressObj.ip };
    }
    return emptyHostProperty;
  }, [resourceIngress]);
