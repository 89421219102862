import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import { Divider } from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { InsightResult } from "../../generated/insights";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../ResourceView/resources/deployment";
import DaemonSet from "../ResourceView/resources/daemonset";
import StatefulSet from "../ResourceView/resources/statefulset";

import { InsightsIdentifiers } from "./InsightsIdentifiers";

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

const TitleContainer = styled.div`
  padding: 1rem 0 0 2rem;
`;

interface InsightDetailsProps {
  results: InsightResult[];
  resource: Deployment | DaemonSet | StatefulSet;
}

const InsightDetails: React.FC<InsightDetailsProps> = ({
  results,
  resource,
}) => {
  const insightType = useMemo(
    () => InsightsIdentifiers[results[0].insightType],
    [results]
  );

  const Content = insightType.content;
  const insightData = useMemo(
    () => insightType.aggregateInsightData(results),
    [insightType, results]
  );
  return (
    <Container>
      <TitleContainer>
        <Typography variant="h4" color={muiColors.gray[800]}>
          {insightType.descriptionCallback(insightData)}
        </Typography>
      </TitleContainer>
      <br />
      <Divider />
      <ContentContainer>
        <Typography variant="h5" color={muiColors.gray[900]}>
          What happened?
        </Typography>
        <Typography variant="body1" fontSize={14} color={muiColors.gray[900]}>
          {insightType.whatHappenedCallback(insightData)}
        </Typography>
        <br />
        <Content insightData={insightData} resource={resource} />
        <br />
        <Typography variant="h5" color={muiColors.gray[900]}>
          How to fix this
        </Typography>
        <Typography variant="body1" fontSize={14} color={muiColors.gray[900]}>
          {insightType.howToFixThis}
        </Typography>
        <br />
        <Divider />
        <br />
      </ContentContainer>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InsightDetails;
