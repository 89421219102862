import React from "react";
import styled from "styled-components";

import { useOverviewPageStore } from "../store/overviewPageStore";
import { selectMetricsSupportAgentInfo } from "../store/overviewPageStoreSelectors";
import { UpgradeAgentVersionAlert } from "../../../../../../common/UpgradeAgentVersionAlert/UpgradeAgentVersionAlert";
import { updateAgentVersionInfoText } from "../../../../../../common/UpgradeAgentVersionAlert/texts";
import { UpgradeButtonAndModal } from "../shared/components/UpgradeButtonAndModal";
import { LocalStorageItem } from "../../../../../../../shared/utils/localStorageSettings";
import { usePermanentHideAlert } from "../../../../../../common/UpgradeAgentVersionAlert/usePermanentHideAlert";

const hideUpgradeAgentAppsLS = new LocalStorageItem("hideUpgradeAgentApps");

const Container = styled.div`
  padding-bottom: 1rem;
`;

export const UpgradeAgentVersionPrompt: React.FC = () => {
  const { unsupportedClusters } = useOverviewPageStore(
    selectMetricsSupportAgentInfo
  );
  const { hideAlertComponent, hideAlertVal } = usePermanentHideAlert(
    hideUpgradeAgentAppsLS
  );
  if (!unsupportedClusters.length || hideAlertVal === "true") return null;
  return (
    <Container>
      <UpgradeAgentVersionAlert
        upgradeButton={
          <>
            {hideAlertComponent}
            <UpgradeButtonAndModal unsupportedClusters={unsupportedClusters} />
          </>
        }
        text={updateAgentVersionInfoText}
        severity={"warning"}
        showCloseButton={true}
      />
    </Container>
  );
};
