import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  apiV1RbacCustomK8sActionsIdPutUrl,
  RbacCustomK8sAction,
  RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest,
} from "../../../../../generated/auth";

const RBAC_POLICY_ACTIONS_PATH = "/rbac-custom-k8s-actions/:id";

const updateRbacCustomK8sAction = async (
  apiClient: AxiosInstance,
  params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest
): Promise<RbacCustomK8sAction> => {
  const { data } = await apiClient.put(
    apiV1RbacCustomK8sActionsIdPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params.updateRbacCustomK8sActionPayload
  );
  return data;
};

export const useUpdateRbacCustomK8sAction = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [RBAC_POLICY_ACTIONS_PATH],
    (params: RbacCustomK8sActionsApiApiV1RbacCustomK8sActionsIdPutRequest) =>
      updateRbacCustomK8sAction(apiClient, params)
  );
};
