import { useMemo } from "react";

import { BasicMetricsParams } from "../types";
import { parseKomodorUid } from "../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetEpochsFromViolation } from "../../../../hooks/useGetEpochsFromViolation";

export const useMetricsParams = (komodorUid: string) => {
  const { cluster, namespace, name, kind } = parseKomodorUid(komodorUid) ?? {};
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  const params = useMemo<BasicMetricsParams>(() => {
    return {
      clusterName: cluster ?? "",
      namespace: namespace ?? "",
      komodorServiceName: name ?? "",
      komodorServiceKind: kind ?? "",
      fromEpoch,
      toEpoch,
    };
  }, [cluster, fromEpoch, kind, name, namespace, toEpoch]);

  const isInvalidParams = useMemo(() => {
    const { clusterName, namespace, komodorServiceName, komodorServiceKind } =
      params;
    return (
      !clusterName || !namespace || !komodorServiceName || !komodorServiceKind
    );
  }, [params]);

  return { params, isInvalidParams };
};
