import { Button } from "@komodorio/design-system/deprecated";
import React, { useState } from "react";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";

import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";
import { isSandbox } from "../../../../shared/utils/sandbox";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";
import UpgradeAgentModal from "../UpgradeAgentModal";
import warnIcon from "../../assets/warnTriangle.svg";
import { gray13 } from "../../../../Colors";
import { smallLightStyle } from "../../monitorsConfiguration/common/styles";
import { useGetHelmInstallationCommand } from "../../../../shared/hooks/useGetHelmInstallationCommand";

const WarnIcon = styled.img.attrs({ src: warnIcon, alt: "warn" })`
  object-fit: contain;
`;

const WarnInfo = styled.div`
  ${smallLightStyle};
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid ${gray13};
  border-radius: 4px;
  width: inherit;
  padding: 0.5rem 0.7rem;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
const AvailabilityUpgradeAgent: React.FC = () => {
  const [showUpgradeAgent, setShowUpgradeAgent] = useState(false);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_POD_EVENTS
  );

  const handleButtonClick = () => {
    setShowUpgradeAgent(true);
  };
  const closeModalCallback = () => {
    setShowUpgradeAgent(false);
  };

  if (isElectronContext()) return null;

  return (
    <>
      {showUpgradeAgent && (
        <UpgradeAgentModal
          closeModalCallback={closeModalCallback}
          upgradeCommand={commandStr}
        />
      )}
      <WarnInfo>
        <WarnIcon />
        <FlexContainer>
          You should upgrade your agent to view full feature capabilities
          <TooltipWrapper
            tooltipId={DEFAULT_TOOLTIP}
            content={
              isSandbox()
                ? "This option is disabled in sandbox version"
                : undefined
            }
            place="left"
          >
            <Button
              variant="secondary"
              size="small"
              disabled={isSandbox()}
              onClick={handleButtonClick}
            >
              Upgrade Agent
            </Button>
          </TooltipWrapper>
        </FlexContainer>
      </WarnInfo>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AvailabilityUpgradeAgent;
