import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useWorkflowIssuesQuery } from "@/components/common/EventGroup/workflowIssues/useWorkflowIssues";
import { useGetEpochsFromViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetEpochsFromViolation";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { useSharedProcessListProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useSharedProcessListProps";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { ProcessListWrapper } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/ProcessListWrapper";
import { MonitorType } from "@/generated/monitorsApi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const containerAriaLabel =
  reliabilityArialLabels.violationDrawer.correlatedEvents;

type CorrelatedEventsProps = {
  className?: string;
  monitorIssuesTypes?: MonitorType[];
};
export const CorrelatedEvents: React.FC<CorrelatedEventsProps> = ({
  className,
  monitorIssuesTypes = [MonitorType.Availability],
}) => {
  const { data } = useViolation();
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  const komodorUid = data?.data.violation.komodorUid ?? "";

  const {
    eventId,
    setEventId,
    setIntentId,
    timeWindow,
    setTimeWindow,
    serviceIds,
  } = useSharedProcessListProps({
    komodorUids: [komodorUid],
    fromEpoch,
    toEpoch,
  });

  const [issues, isLoadingIssues] = useWorkflowIssuesQuery(
    timeWindow,
    serviceIds,
    monitorIssuesTypes
  );

  const issuesEventGroups = useMemo(() => {
    const flatIssues = issues?.flat() ?? [];
    return groupWorkflowIssuesEvents(flatIssues, serviceIds[0]);
  }, [issues, serviceIds]);

  const title = isLoadingIssues
    ? ""
    : `Correlated events (${issuesEventGroups.length})`;

  if (!isLoadingIssues && issuesEventGroups.length === 0) return null;

  return (
    <Container aria-label={containerAriaLabel} className={className}>
      <Typography variant="h4">{title}</Typography>
      <ProcessListWrapper
        isLoadingIssues={isLoadingIssues}
        processListProps={{
          setTimeWindow: (tw) => {
            setTimeWindow({ ...timeWindow, ...tw });
          },
          timeWindow,
          eventGroups: issuesEventGroups,
          eventId,
          setEventId,
          setIntentId,
          highlightedEvents: [],
          showServiceColumn: false,
          showMoreEventsPanel: true,
        }}
      />
    </Container>
  );
};
