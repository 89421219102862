import React from "react";
import styled from "styled-components";

import { gray10 } from "../../../../../Colors";
import { TextRegular, Text } from "../../../../common/typography";

import { WelcomePadding, StepContent } from "./steps.styles";

const KomodorVideo = "https://www.youtube.com/embed/Qgio3vF1sPE";

const StyledStepContent = styled(StepContent)`
  ${WelcomePadding}
  gap: 1.5rem;
`;
styled(Text)`
  color: ${gray10};
`;
const StepAllSet: React.FC = () => (
  <StyledStepContent>
    <div>
      <TextRegular>
        If this is your first time using the platform, you might want to watch
        the short demo video below or read the docs to maximize your Komodor
        experience.
      </TextRegular>
      <br />
    </div>
    <iframe
      width="auto"
      height="100%"
      src={KomodorVideo}
      title="Komodor onboarding video"
      allowFullScreen
    />
  </StyledStepContent>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default StepAllSet;
