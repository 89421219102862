import { useMemo, useState, useEffect, useRef } from "react";
import { isEqual } from "lodash";

import { generateEarliestRowTime } from "../../components/common/RecommendationAccuracy/mock/mockEarliestRowTime";
import { EarliestRowsTimeResponse } from "../../generated/metricsApi";
import { isDevMode } from "../utils/isDevMode";

import { MetricsApiResponse, useMetricsAPIGet } from "./metrics-api/client";
import { initialFetchState } from "./metrics-api/mockServerRes";
import { COST_EARLIEST_ROWS_TIME_ENDPOINT } from "./metrics-api/requestResponseMaps";

export const useFetchMetricsEarliestRowTime = (clusters: string[] = []) => {
  const shouldPause = !clusters?.length || isDevMode();
  const clustersRef = useRef<string[]>();

  const params = useMemo(
    () => ({
      clusterNames: clusters,
    }),
    [clusters]
  );

  // simulate server responses
  const mockCostEarliestRowTimeResponse = useMemo(
    () =>
      isDevMode()
        ? generateEarliestRowTime({ clusterNames: clusters })
        : undefined,
    [clusters]
  );

  const [res, setRes] = useState<
    MetricsApiResponse<EarliestRowsTimeResponse | { clusters: undefined }>
  >({
    ...initialFetchState,
    data: undefined,
  });

  useEffect(() => {
    if (!isDevMode()) return;
    if (isEqual(clustersRef.current, clusters)) return;
    clustersRef.current = clusters;
    setRes({
      ...initialFetchState,
      loading: true,
      data: undefined,
    });
    setTimeout(() => {
      setRes({
        ...initialFetchState,
        loading: false,
        data: mockCostEarliestRowTimeResponse,
      });
    }, 2000);
  }, [clusters, mockCostEarliestRowTimeResponse]);
  /////////////////////////////

  const serverRes = useMetricsAPIGet<EarliestRowsTimeResponse>(
    COST_EARLIEST_ROWS_TIME_ENDPOINT,
    params,
    shouldPause
  );

  return isDevMode() ? res : serverRes;
};
