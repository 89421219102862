import { useCallback } from "react";

import {
  SocketMessagePayload,
  SocketMessageType,
} from "../../../sockets/types";
import {
  SocketState,
  useWebSocketContext,
} from "../../../context/WebSocketsProvider";
import { extractPayloadFromMessage } from "../../../sockets/utils";
import { PodLogLine } from "../types";

import { tryParseLogs } from "./tryParseLogs";
import { UNKNOWN_EXIT_REASON } from "./constants";
import { handleUnknownTerminationReason } from "./handleUnknownTerminationReason";

interface Args {
  identifier: string;
  closeKeepAliveSession: () => void;
  setLogs: (logs: PodLogLine[], isSystemMessageLogs: boolean) => void;
  onError: (msg: string) => void;
  askForRetry: () => void;
}

type UseHandleIncomingSocketMessage = (
  message: MessageEvent<SocketMessagePayload>
) => void;

export const useHandleIncomingSocketMessage = ({
  identifier,
  closeKeepAliveSession,
  onError,
  setLogs,
  askForRetry,
}: Args): UseHandleIncomingSocketMessage => {
  const sockets = useWebSocketContext();

  const handleIncomingMessage = useCallback(
    (message: MessageEvent<SocketMessagePayload>) => {
      const {
        messageType,
        sessionId: lastReceivedSessionId,
        data,
      } = extractPayloadFromMessage(message);

      const { sessionId, retryCount = 0 } =
        sockets.getSocketContext(identifier) || {};

      switch (messageType) {
        case SocketMessageType.Ack:
          if (lastReceivedSessionId && !sessionId) {
            sockets.addContextToSocket(identifier, {
              sessionId: lastReceivedSessionId,
              keepAliveState: SocketState.Alive,
            });
          }

          break;

        case SocketMessageType.Stdout:
          if (retryCount > 0) {
            sockets.addContextToSocket(identifier, {
              retryCount: 0,
            });
          }

          if (data && data.out) {
            const logs = tryParseLogs(data.out);

            if (logs.length) {
              setLogs(logs, false);
            }
          }

          break;

        case SocketMessageType.Error:
          if (data && data.errorMessage) {
            onError(data.errorMessage);
          }

          break;

        case SocketMessageType.Termination:
          closeKeepAliveSession();
          sockets.addContextToSocket(identifier, {
            keepAliveState: SocketState.Died,
          });

          if (data && data.exitReason) {
            if (data.exitReason === UNKNOWN_EXIT_REASON) {
              handleUnknownTerminationReason({
                retryCount,
                setLogs,
                sockets,
                identifier,
                askForRetry,
              });
            } else {
              onError(data.exitReason);
            }
          }

          break;
      }
    },
    [sockets, identifier, closeKeepAliveSession, setLogs, onError, askForRetry]
  );

  return handleIncomingMessage;
};
