import React from "react";
import styled from "styled-components";

import { Title } from "../../../violationDrawerStyles";
import { howToFixText, whatText, whyText } from "../../../violationDrawerTexts";
import { sectionGap } from "../../../../../../../constants/reliabilityConstants";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  violationDrawer: { sections },
} = reliabilityArialLabels;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

type Section = "what" | "why" | "how";

type SectionProps = {
  titleText: string;
  ariaLabel: string;
};

type DrawerViolationContentProps = {
  type: Section;
  content: React.ReactNode;
};

const sectionsMapper: Record<Section, SectionProps> = {
  what: {
    titleText: whatText,
    ariaLabel: sections.what,
  },
  why: {
    titleText: whyText,
    ariaLabel: sections.why,
  },
  how: {
    titleText: howToFixText,
    ariaLabel: sections.how,
  },
};

export const DrawerViolationSection: React.FC<DrawerViolationContentProps> = ({
  type,
  content,
}) => {
  const { titleText, ariaLabel } = sectionsMapper[type];
  return (
    <Section aria-label={ariaLabel}>
      <Title>{titleText}</Title>
      {content}
    </Section>
  );
};
