import React from "react";
import {
  InfoCircleOutlined24,
  Team24,
  Pod24,
  Events24,
} from "@komodorio/design-system/icons";

import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import { DetailsGrid, usePreparedEvents } from "./common";

interface PodShellEventDetailsProps {
  eventGroup: ActionEventGroup;
}

export const PodShellEventDetails: React.FC<PodShellEventDetailsProps> = ({
  eventGroup,
}) => {
  const { events, cluster, namespace, resourceName, email, actionResult } =
    eventGroup;

  const preparedEvents = usePreparedEvents(events);

  const title = `Started pod shell on ${resourceName}`;

  const resourceDetails = [
    {
      title: `Pod.Container:`,
      data: `${resourceName}`,
    },
    {
      title: "Namespace:",
      data: namespace,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title} />
      <EventDetailsSection
        icon={Events24}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={Pod24}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Pod details"
      >
        <DetailsGrid>
          {resourceDetails.map((e, i) => (
            <React.Fragment key={e.title}>
              <div>{e.title}</div>
              <div>{e.data}</div>
            </React.Fragment>
          ))}
        </DetailsGrid>
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
