import { useCallback } from "react";

import {
  SocketState,
  useWebSocketContext,
} from "../../../shared/context/WebSocketsProvider";
import { notifyDDError } from "../../../shared/hooks/exceptionManagement";
import { generateSocketPayload } from "../../../shared/utils/generateSocketPayload";
import { SocketMessageType } from "../../../shared/sockets/types";
import { isSocketReadyToSend } from "../../../shared/sockets/isSocketReadyToSend";

export const useTerminalResize = (
  id: string
): ((change: { cols: number; rows: number }) => void) => {
  const sockets = useWebSocketContext();

  return useCallback(
    (change: { cols: number; rows: number }) => {
      const activeSocket = sockets.getSocket(id);
      const { sessionId, keepAliveState } = sockets.getSocketContext(id) || {};
      const isReady = isSocketReadyToSend(activeSocket);

      if (!activeSocket || !sessionId || !isReady) return;

      if (keepAliveState !== SocketState.Alive) {
        return;
      }

      const payload = generateSocketPayload({
        messageType: SocketMessageType.PodExecTerminalResize,
        sessionId,
        data: {
          width: change.cols,
          height: change.rows,
        },
      });

      try {
        activeSocket.send(JSON.stringify(payload));
      } catch (error) {
        notifyDDError({
          name: "pod exec - failed to send resize event",
          message: (error as Error).message,
        });
      }
    },
    [id, sockets]
  );
};
