import { useCallback } from "react";

import {
  useRemoveWorkflowConfigurationMutation,
  useUpdateActiveWorkflowConfigurationMutation,
} from "../../../../../generated/graphql";

export const useUpdateActiveWorkflowConfiguration = (): ((params: {
  id: string;
  active: boolean;
}) => Promise<boolean>) => {
  const updateActiveWorkflowConfiguration =
    useUpdateActiveWorkflowConfigurationMutation()[1];
  return useCallback(
    async (params) => {
      const { data } = await updateActiveWorkflowConfiguration(params);
      return !!data?.update_workflow_configuration_by_pk?.id;
    },
    [updateActiveWorkflowConfiguration]
  );
};

export const useRemoveWorkflowConfiguration = (): ((params: {
  id: string;
}) => Promise<boolean>) => {
  const removeWorkflowConfiguration =
    useRemoveWorkflowConfigurationMutation()[1];
  return useCallback(
    async (params) => {
      const { data } = await removeWorkflowConfiguration(params);
      return !!data?.update_workflow_configuration_by_pk?.id;
    },
    [removeWorkflowConfiguration]
  );
};
