import { useEffect } from "react";

import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { SegmentIntegrations } from "../../../shared/config/analyticsEvents";

export const useTooltipMetricsAnalytics = (): void => {
  const analytics = useAnalyticsApi();

  useEffect(() => {
    document.addEventListener(
      "mouseover",
      (e) => {
        const element = document.querySelector(
          `[aria-label='${AriaLabels.MetricsAvailability.Tooltip}']`
        );
        if (element) {
          analytics.dispatchEventViaBackend(
            AriaLabels.MetricsAvailability.Tooltip,
            {},
            true,
            false,
            [SegmentIntegrations.Hubspot]
          );
        }
      },
      { once: true }
    );
  }, [analytics]);
};
