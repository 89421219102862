import { ReliabilityStore } from "../ReliabilityTypes";

export const initialState: ReliabilityStore = {
  initialTime: Date.now(),
  hasSubscribedToAppViewChanges: false,
  violationsState: {
    groups: { Impact: {}, Cluster: {}, None: {}, CheckType: {} },
    ungroupedTotalViolationsCount: undefined,
  },
  addEditIgnoreRuleDialogState: {
    isOpen: false,
    checkType: undefined,
    existingIgnoreRule: undefined,
  },
  reliabilityNotifications: [],
  setTopSectionIsShown: () => undefined,
  setGroupExpandedState: () => undefined,
  setGroupState: () => undefined,
  setUngroupedTotalViolationsCount: () => undefined,
  setAddEditIgnoreRuleDialogState: () => undefined,
  setReliabilityNotification: () => undefined,
  removeReliabilityNotification: () => undefined,
  setHasSubscribedToAppViewChanges: () => undefined,
};
