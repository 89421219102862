import React from "react";

import {
  UpgradeButtonAndModalForUnsupportedClusters,
  UpgradeButtonAndModalForUnsupportedClustersProps,
} from "../../../../../../../../shared/components/UpgradeButtonAndModalForUnsupportedClusters";
import { UpgradeCommands } from "../../../../../../../common/upgradeAgentCta/UpgradeAgentCTA";

type UpgradeButtonAndModalProps = Pick<
  UpgradeButtonAndModalForUnsupportedClustersProps,
  "unsupportedClusters"
>;

export const UpgradeButtonAndModal: React.FC<UpgradeButtonAndModalProps> = ({
  unsupportedClusters,
}) => {
  return (
    <UpgradeButtonAndModalForUnsupportedClusters
      unsupportedClusters={unsupportedClusters}
      upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_METRICS}
      desiredFeature="metrics"
    />
  );
};
