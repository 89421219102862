import React, { createContext, PropsWithChildren } from "react";

export type FiltersInUrlContextState = {
  urlParamKey: string;
  /** search filter is unique b/c it filters its own category but not others */
  searchTypeFilterOperator: string;
};

type FiltersInUrlContextProps = PropsWithChildren<FiltersInUrlContextState>;

const initialState: FiltersInUrlContextState = {
  urlParamKey: "",
  searchTypeFilterOperator: "",
};

export const FiltersInUrlContext =
  createContext<FiltersInUrlContextState>(initialState);

export const FiltersContextProvider: React.FC<FiltersInUrlContextProps> = ({
  urlParamKey,
  searchTypeFilterOperator,
  children,
}) => {
  const contextState: FiltersInUrlContextState = {
    urlParamKey,
    searchTypeFilterOperator,
  };

  return (
    <FiltersInUrlContext.Provider value={contextState}>
      {children}
    </FiltersInUrlContext.Provider>
  );
};
