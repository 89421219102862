import { useCallback, useMemo } from "react";

import { buildKomodorUidFromResource } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { ResourceListResponse } from "../../../Inspection/InspectionViews/common";
import {
  useDeletedPodsProperties,
  useResourceListOfDeletedPods,
} from "../../../Inspection/historicalFetch/useResourceListFromDatabase";
import Resource from "../../resources";

import WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import Node from "@/components/ResourceView/resources/node";
import { TimeWindow } from "@/shared/types/TimeWindow";

interface Params {
  resource: Resource;
  resourceList: ResourceListResponse;
  refresh: () => void;
  fetchDeletedPods?: boolean;
  deletedPodsTimeWindow?: TimeWindow;
}
export const useAppendDeletedPodsByOwnerResource = ({
  resource,
  fetchDeletedPods,
  deletedPodsTimeWindow,
  resourceList,
  refresh,
}: Params): [ResourceListResponse, () => void] => {
  const {
    deletedPodsTimeWindowUrlParam,
    showDeletedPodsUrlParam: fetchDeletedPodsUrlParam,
  } = useDeletedPodsProperties();

  const params = useMemo(() => {
    if (resource instanceof WorkflowRun)
      return {
        clusterName: resource.cluster,
        namespace: resource.namespace,
        labels: `{${Object.entries(resource.labels)
          .map(([key, value]) => `"${key}":"${value}"`)
          .join(",")}}`,
      };
    if (resource instanceof Node)
      return { clusterName: resource.cluster, nodeNames: [resource.name] };
    const ownerKomodorUid = buildKomodorUidFromResource(resource);
    if (ownerKomodorUid) return { ownerKomodorUid };
    return null;
  }, [resource]);
  const {
    data: deletedPodsData,
    fetching: isDeletedPodsFetching,
    emptyResult: deletedPodsEmptyResult,
    refresh: refreshDeletedPods,
    errorMessage: deletedPodsErrorMessage,
  } = useResourceListOfDeletedPods(
    deletedPodsTimeWindow ?? deletedPodsTimeWindowUrlParam,
    params,
    !(fetchDeletedPods ?? fetchDeletedPodsUrlParam)
  );

  const refreshWithDeletedPods = useCallback(() => {
    refresh();
    if (fetchDeletedPods ?? fetchDeletedPodsUrlParam) {
      refreshDeletedPods();
    }
  }, [refresh, refreshDeletedPods, fetchDeletedPods, fetchDeletedPodsUrlParam]);

  return useMemo(() => {
    return [
      {
        ...resourceList,
        rows: deletedPodsData.concat(resourceList.rows),
        fetching: resourceList.fetching || isDeletedPodsFetching,
        emptyResult: resourceList.emptyResult && deletedPodsEmptyResult,
        errorMessage: resourceList.errorMessage || deletedPodsErrorMessage,
      },
      refreshWithDeletedPods,
    ];
  }, [
    deletedPodsData,
    deletedPodsEmptyResult,
    deletedPodsErrorMessage,
    isDeletedPodsFetching,
    refreshWithDeletedPods,
    resourceList,
  ]);
};
