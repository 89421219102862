import React from "react";
import styled from "styled-components";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";

import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";
import { ResourceUtilization } from "../../../generated/workspacesApi";

import { getTooltipUnits } from "./utils";
import { UpgradeClusterCta } from "./UpgradeCommandCta";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UpgradeClusterCtaAllocation: React.FC = () => {
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_METRICS
  );
  return <UpgradeClusterCta command={command} desiredFeature="metrics" />;
};

const StyledLinearProgress = styled(LinearProgress)`
  && {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${muiColors.gray[100]};
    & > span:nth-child(1) {
      background-color: ${muiColors.gray[700]};
    }
  }
`;
const LinearProgressWithLabel = (
  props: LinearProgressProps & { label: string; value: number }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Typography
        variant="body3"
        color="text.secondary"
        sx={{ minWidth: "27px" }}
      >
        {props.label}
      </Typography>
      <StyledLinearProgress variant="determinate" {...props} />
      <Typography variant="body3" color="text.secondary">{`${Math.round(
        props.value
      )}%`}</Typography>
    </Box>
  );
};

export const AllocationCell: React.FC<{
  resources: ResourceUtilization | undefined;
  inactive: boolean;
  isMetricsInstalled: boolean;
}> = ({ resources, inactive, isMetricsInstalled }) => {
  if (inactive) return <>N/A</>;
  if (!isMetricsInstalled) return <UpgradeClusterCtaAllocation />;
  if (!resources) return <>N/A</>;

  const {
    memoryUnit,
    memoryAllocated,
    memoryCapacity,
    cpuUnit,
    cpuAllocated,
    cpuCapacity,
  } = getTooltipUnits(resources);

  const tooltipContent = (
    <Container>
      <Typography variant="body3">Allocated/ Capacity</Typography>
      <Box sx={{ display: "flex", columnGap: "4px" }}>
        <Typography variant="body3" color="text.secondary">
          CPU
        </Typography>
        <Typography variant="body3">
          {cpuAllocated}/{cpuCapacity}
          {cpuUnit}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", columnGap: "4px" }}>
        <Typography variant="body3" color="text.secondary">
          Memory
        </Typography>
        <Typography variant="body3">
          {memoryAllocated}/{memoryCapacity}
          {memoryUnit}
        </Typography>
      </Box>
    </Container>
  );

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={tooltipContent}
      placement="top"
    >
      <Container>
        <LinearProgressWithLabel label="CPU" value={resources.cpu.percentage} />
        <LinearProgressWithLabel
          label="Mem"
          value={resources.memory.percentage}
        />
      </Container>
    </Tooltip>
  );
};
