import React, { ReactElement } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { Record, Unknown } from "runtypes";
import styled from "styled-components";

import {
  useDateFormatter,
  useDurationFormatter,
} from "../../../../shared/hooks/useDateFormatter";
import EventGroup from "../../EventGroup";
import { ZIndex } from "../../../../constants/zIndex";

export const TooltipContainer = styled.div`
  padding: 0.75rem;
  border-radius: 4px;
  width: 16rem;
  word-break: break-word;
  background-color: ${theme.background.bgWhite};
  color: ${theme.foreground.fgPrimary};
  box-shadow: 0 2px 8px 1px rgba(22, 24, 31, 0.15);
  z-index: ${ZIndex.AboveModal};
`;

export const TooltipRow = styled.div`
  display: grid;
  grid-template-columns: 6rem auto;
  column-gap: 0.5rem;
  cursor: pointer;
`;

export const TooltipHeader = styled.div`
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

export const TooltipSecondaryColor = muiColors.gray["500"];
export const TooltipPrimaryColor = muiColors.common.black;

const WithEventGroup = Record({
  payload: Record({
    eventGroup: Unknown,
  }),
});
const getEventGroup = (v: unknown) =>
  WithEventGroup.guard(v) ? v.payload.eventGroup : null;

export const EventsChartTooltipComponent: React.FC<{
  title: string;
  data: { key: string; value: ReactElement | string }[];
}> = ({ title, data }) => {
  return (
    <TooltipContainer>
      <TooltipHeader>
        <Typography variant={"h6"} color={TooltipSecondaryColor}>
          {title}
        </Typography>
      </TooltipHeader>
      {data.map((d) => {
        return d.key && d.value ? (
          <TooltipRow key={d.key}>
            <Typography variant={"body3"} color={TooltipSecondaryColor}>
              {d.key}:
            </Typography>
            {typeof d.value === "string" ? (
              <Typography variant={"body3"} color={TooltipPrimaryColor}>
                {d.value}
              </Typography>
            ) : (
              d.value
            )}
          </TooltipRow>
        ) : null;
      })}
    </TooltipContainer>
  );
};

export const EventsChartTooltip: React.FC<{
  payload?: unknown[];
}> = ({ payload }) => {
  const eventGroup = getEventGroup(payload?.[0]) as EventGroup;

  const { format } = useDateFormatter({ timeZoneName: undefined });
  const { formatShortDuration } = useDurationFormatter();

  return eventGroup?.renderTooltipContent
    ? eventGroup.renderTooltipContent({
        dateFormatter: format,
        durationFormatter: formatShortDuration,
      })
    : null;
};
