import { useOverridableFlags } from "../../../../../../../shared/context/featureFlags/OverridableFlags";
import { useAccountName } from "../../../../../../../shared/hooks/useAccountName";
import { appViewIdSelector } from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";

const appViewsToMock: Record<string, string[]> = {
  demo: ["6e2a5a00-a5db-441c-a49a-974e29bc01a7"],
};

export const useShouldRenderMockContent = () => {
  const accountName = useAccountName();
  const currentAppViewId = useAppViewsStore(appViewIdSelector);
  const { showMockAppViewScore } = useOverridableFlags();

  return Boolean(
    showMockAppViewScore &&
      appViewsToMock[accountName]?.includes(currentAppViewId || "")
  );
};
