import React from "react";

import { WorkloadGraph } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/components/graphs/WorkloadGraph";
import { LoadingStateIds } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabConstants";
import { useSharedGraphProps } from "../hooks/useSharedGraphProps";
import { ScalingMetric } from "../hpaReachedMaxTypes";
import { useSetRequestDataFromMetrics } from "../hooks/useSetRequestDataFromMetrics";
import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { useAverageUtilizationValuePerMetric } from "../hooks/useAverageUtilizationValuePerMetric";
import { useWorkloadMetrics } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/components/graphs/useWorkloadMetrics";
import { useGetCorrelatedDeployEvents } from "../../../shared/metrics/hooks/useGetCorrelatedDeployEvents";

export const SingleMetricsGraph: React.FC<ScalingMetric> = (props) => {
  const { requestValues, serviceKomodorUid } = useHpaReachedMaxContext();
  useSetRequestDataFromMetrics(props.resourceType);

  const requestValue = requestValues[props.resourceType] ?? 0;

  const averageUtilizationValuePerMetric =
    useAverageUtilizationValuePerMetric(props);

  const sharedGraphProps = useSharedGraphProps({
    ...props,
    ...averageUtilizationValuePerMetric,
    averageUtilizationValue: requestValue,
  });

  const { graphProps } = useWorkloadMetrics({
    type: props.resourceType,
    resource: sharedGraphProps.resource,
    endTime: sharedGraphProps.endTime,
  });

  const events = useGetCorrelatedDeployEvents({
    komodorUid: serviceKomodorUid ?? "",
    graphDataPoints: graphProps.data,
  });

  const propsWithEvents = {
    ...sharedGraphProps,
    metricGraphProps: {
      ...sharedGraphProps.metricGraphProps,
      events,
      constrictHeightForEvents: false,
    },
  };

  const loadingStateId =
    props.resourceType === "memory"
      ? LoadingStateIds.workLoadMemory
      : LoadingStateIds.workLoadCPU;

  return (
    <div>
      <WorkloadGraph
        {...propsWithEvents}
        loadingStateId={loadingStateId}
        type={props.resourceType}
      />
    </div>
  );
};
