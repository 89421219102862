import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Container } from "komodor-types";

import { ServiceTextLink } from "../../shared/components/InsightsDataTable/ServiceTextLink";
import {
  ThrottledApplicationColumns,
  ThrottledApplicationInterface,
} from "../../shared/types";
import { ThrottledAppContainersResponseAffectedContainersInner } from "../../../../../../../../../generated/metricsApi";
import { roundValueAsNumber } from "../../shared/utils/utils";

import { IncreaseLimitButton } from "./IncreaseLimitButton";

export const throttledApplicationsColumns: GridColDef<ThrottledApplicationColumns>[] =
  [
    {
      field: "serviceName",
      headerName: "Service name",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <ServiceTextLink serviceName={params.value} id={params.row.id} />
        );
      },
    },
    {
      field: "container",
      headerName: "Container",
      flex: 1,
    },
    {
      field: "aboveThrottleLimitTotal",
      headerName: "Time throttled",
      flex: 1,
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams) =>
        `${roundValueAsNumber(params.value)}%`,
    },
    {
      field: "actionButton",
      headerName: "",
      flex: 1,
      align: "right",
      maxWidth: 130,
      renderCell: (params: GridRenderCellParams) => (
        <IncreaseLimitButton rowData={params.row} />
      ),
    },
  ];

const buildContainerDataFromColumn = (
  column: ThrottledAppContainersResponseAffectedContainersInner
): Container => {
  return {
    name: column.containerName,
    resources: {
      limits: {
        cpu: column.limits.cpu.toString(),
        memory: column.limits.memory.toString(),
      },
      requests: {
        cpu: column.requests.cpu.toString(),
        memory: column.requests.memory.toString(),
      },
    },
  } as Container;
};

const getContainersByServiceId = (
  columns: ThrottledApplicationInterface[]
): Record<string, Container[]> => {
  return columns.reduce((acc, curr) => {
    if (!acc[curr.komodorServiceId]) {
      acc[curr.komodorServiceId] = [];
    }

    acc[curr.komodorServiceId].push(buildContainerDataFromColumn(curr));
    return acc;
  }, {} as Record<string, Container[]>);
};

export const getThrottledApplicationRows = (
  columns: ThrottledApplicationInterface[]
): ThrottledApplicationColumns[] => {
  const containersByServiceId = getContainersByServiceId(columns);

  return columns.map((column) => ({
    // TODO: since there could be multiple, identical komodorServiceId's in this list, we need to create a unique ID
    // and add a 'serviceId' property on type ServiceColumn which would be used in the ResourceDrawerByServiceId component
    id: column.komodorServiceId,
    serviceName: column.komodorServiceName,
    serviceKind: column.komodorServiceKind,
    clusterName: column.cluster,
    namespace: column.namespace,
    container: column.containerName,
    aboveThrottleLimitTotal: column.aboveThrottleLimitTotal,
    containers: containersByServiceId[column.komodorServiceId],
  }));
};
