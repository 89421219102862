import React, { useMemo } from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import { Severity } from "../../../../../ReliabilityTypes";
import { useConfigurationControls } from "../hooks/useConfigurationControls";
import { useFieldRegistrationByCheckedStatus } from "../hooks/useFieldRegistrationByCheckedStatus";
import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";

import { getSignByCheckType } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import { SeverityChip } from "@/components/reliability";
import { CheckType } from "@/generated/reliabilityApi";
import { useGetReadOnlyTextPerCheckType } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetReadOnlyTextPerCheckType";
import { useClearConfigurationControlsErrors } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useClearConfigurationControlsErrors";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
`;

const StyledArrow = styled(Typography)`
  && {
    margin: 0 8px;
  }
`;

const StyledSeverityChip = styled(SeverityChip)`
  width: 68px;
`;

const {
  policies: {
    policiesDrawer: {
      getConfigurationControlSeverityContainer:
        getConfigurationControlAriaLabel,
    },
  },
} = reliabilityArialLabels;

type SingleConfigurationControlProps = {
  severity: Severity;
  checkType: CheckType;
};

export const SingleConfigurationControl: React.FC<
  SingleConfigurationControlProps
> = ({ severity, checkType }) => {
  const { watch } = useFormContext();
  const {
    configurationsControlCheckedState,
    isSubmittingForm,
    setSingleControlCheckedState,
    isReadOnlyMode,
  } = usePolicyDrawerContext();
  const clearConfigurationControlsErrors =
    useClearConfigurationControlsErrors();

  const configurationNamesPerInput = useMemo(() => {
    return getConfigurationFieldNamesByCheckTypeAsList(checkType, severity);
  }, [checkType, severity]);

  const isCheckboxChecked =
    configurationsControlCheckedState[configurationNamesPerInput[0]] ?? true;

  const latestValues = useFieldRegistrationByCheckedStatus({
    configurationNamesPerInput,
    isChecked: isCheckboxChecked,
  });

  const disabled = isSubmittingForm || !isCheckboxChecked;

  const configurationControls = useConfigurationControls({
    checkType,
    configurationNames: configurationNamesPerInput,
    severity,
    values: configurationNamesPerInput.map((name) => {
      const value = watch(name);
      if (value || !latestValues[name]) {
        return value;
      }
      return latestValues[name];
    }),
    disabled,
  });

  const getReadOnlyTextPerCheckType = useGetReadOnlyTextPerCheckType();

  const onCheckBoxChange = () => {
    setSingleControlCheckedState({
      [configurationNamesPerInput[0]]: !isCheckboxChecked,
    });
    clearConfigurationControlsErrors(checkType);
  };

  const configurationsToShow = isReadOnlyMode
    ? getReadOnlyTextPerCheckType(checkType, configurationNamesPerInput)
    : configurationControls;

  if (isReadOnlyMode && !isCheckboxChecked) return null;

  return (
    <Container
      aria-label={getConfigurationControlAriaLabel(checkType, severity)}
    >
      {!isReadOnlyMode && (
        <Checkbox checked={isCheckboxChecked} onChange={onCheckBoxChange} />
      )}
      <StyledSeverityChip severity={severity} disabled={!isCheckboxChecked} />
      <StyledArrow variant={"body2"}>
        {getSignByCheckType(checkType)}
      </StyledArrow>
      {configurationsToShow}
    </Container>
  );
};
