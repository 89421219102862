import { useMemo } from "react";

import { doesAgentVersionSupportMetrics } from "../../shared/utils/agent/agent";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useActiveAgent } from "../../shared/hooks/useAgents";
import { useAgentInfoById } from "../../shared/hooks/useAgentInfo/useAgentInfo";

export const useIsMetricsDrawerSupported = (
  clusterName: string | undefined
): boolean | null => {
  const agentId = useActiveAgent(clusterName);
  const { agentProperties: agentInfo } = useAgentInfoById(agentId);
  const { metricsAvailabilityDrawerIgnoreAgentVersion } = useOverridableFlags();

  return useMemo(() => {
    if (
      !agentInfo ||
      metricsAvailabilityDrawerIgnoreAgentVersion === null ||
      metricsAvailabilityDrawerIgnoreAgentVersion === undefined
    ) {
      return null;
    }
    return !!(
      doesAgentVersionSupportMetrics(agentInfo) ||
      metricsAvailabilityDrawerIgnoreAgentVersion
    );
  }, [agentInfo, metricsAvailabilityDrawerIgnoreAgentVersion]);
};
