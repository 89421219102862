import React from "react";
import { capitalize, get } from "lodash";
import { InfoCircleOutlined24, Team24 } from "@komodorio/design-system/icons";

import { EventDetailsContainer } from "../../ProcessList/details/common";
import EventDetailsTitle from "../../ProcessList/details/components/EventDetailsTitle";
import EventDetailsSection from "../../ProcessList/details/components/EventDetailsSection";
import eventsIcon from "../../ProcessList/assets/events.svg";
import EventsGrid from "../../ProcessList/details/components/EventsGrid";
import K8sResourceDiffSection from "../../ProcessList/details/components/K8sResourceDiffSection";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

import eventPodIcon from "./assets/eventPod.svg";
import srcCodeIcon from "./assets/srcCode.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ActionEventGroup from "./ActionsEventGroup";
import {
  CommandText,
  SubTitle,
  DetailsGrid,
  usePreparedEvents,
} from "./common";

interface EditResourceEventDetailsProps {
  eventGroup: ActionEventGroup;
}

export const EditResourceEventDetails: React.FC<
  EditResourceEventDetailsProps
> = ({ eventGroup }) => {
  const {
    events,
    cluster,
    namespace,
    resourceType,
    resourceName,
    metadata,
    email,
    actionResult,
  } = eventGroup;

  const preparedEvents = usePreparedEvents(events);

  const resourceTypeCap = capitalize(resourceType);
  const title = `${resourceTypeCap} edited`;

  const command = `kubectl --namespace ${namespace} apply --force '<EDITED-YAML>'`;

  const oldJson = get(metadata, "data.oldJson");
  const newJson = get(metadata, "data.newJson");

  const resourceDetails = [
    {
      title: `${resourceTypeCap} name:`,
      data: `${resourceName}`,
    },
    {
      title: "Namespace:",
      data: namespace,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={title}>
        <SubTitle>
          {`${cluster}/${namespace}/${resourceType}/${resourceName}`}
        </SubTitle>
      </EventDetailsTitle>
      <EventDetailsSection
        icon={srcCodeIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Command"
      >
        <CommandText>{command}</CommandText>
      </EventDetailsSection>
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <K8sResourceDiffSection oldObj={oldJson} newObj={newJson} />
      <EventDetailsSection
        icon={eventPodIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title={resourceTypeCap}
      >
        <DetailsGrid>
          {resourceDetails.map(
            (e, i) =>
              e && (
                <React.Fragment key={i}>
                  <div>{e.title}</div>
                  <div>{e.data}</div>
                </React.Fragment>
              )
          )}
        </DetailsGrid>
      </EventDetailsSection>
      {!isElectronContext() && (
        <EventDetailsSection
          icon={Team24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="User"
        >
          {email}
        </EventDetailsSection>
      )}
      {actionResult && (
        <EventDetailsSection
          icon={InfoCircleOutlined24}
          iconDimensions={{ height: "50%", width: "50%" }}
          title="Result"
        >
          {actionResult}
        </EventDetailsSection>
      )}
    </EventDetailsContainer>
  );
};
