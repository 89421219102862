import useUserMetadata from "../../../shared/hooks/useUserMetadata/useUserMetadata";

export const useShouldAllowAccountSwitcher = () => {
  const { allowedAccounts, isKomodorAdmin } = useUserMetadata();

  // allowedAccounts is a string, which can be '{}' or '{ID1,ID2}'. that's why the > 2
  return Boolean(
    isKomodorAdmin || (allowedAccounts && allowedAccounts.length > 2)
  );
};
