import React, { useEffect } from "react";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";

import { UpgradeRequired } from "../../common/UpdateAgentModal/upgradeRequired";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { doesAgentVersionSupportActions } from "../../../shared/utils/agent/agent";
import { links } from "../../integrations/installation/kubernetes/steps/InstallCommand";
import { StrictExternalLink } from "../../common/Link/ExternalLink";
import { blueBrand } from "../../../Colors";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { useNeedUpgradeAgent } from "../../common/UpdateAgentModal/hooks";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";

const StyledExternalLink = styled(StrictExternalLink)`
  color: ${blueBrand};
`;

interface UseActionsNeedUpgradeAgentArgs {
  agentId: string | undefined;
  cluster: string | undefined;
  minimalSupportedVersion?: string;
  action?: keyof AgentProperties["actions"];
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActionsNeedUpgradeAgent = ({
  agentId,
  cluster,
  minimalSupportedVersion,
  action,
}: UseActionsNeedUpgradeAgentArgs): boolean => {
  return useNeedUpgradeAgent(agentId, cluster, (agentProps) =>
    doesAgentVersionSupportActions(agentProps, minimalSupportedVersion, action)
  );
};

export const UpdateMessage = (
  <Typography size="medium">
    An update to the Komodor agent is required to enable manual actions on this
    cluster.
    <br />
    <br />
    Learn more about{" "}
    <StyledExternalLink href={links.actionsUrl}>
      Komodor Actions{" "}
    </StyledExternalLink>
    &{" "}
    <StyledExternalLink href={links.defaultK8sPermissions}>
      required permissions
    </StyledExternalLink>
  </Typography>
);

export const ActionsUpgradeRequired: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  useEffect(() => {
    dispatchEvent(AnalyticEvents.Actions.Actions_upgrade_agent);
  }, []);

  return (
    <UpgradeRequired
      handleClose={handleClose}
      updateMessage={UpdateMessage}
      upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_ENABLE_ACTIONS}
      desiredFeature="actions"
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ActionsUpgradeRequired;
