import React from "react";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";

export const AuditUserAffectedResource: React.FC<{ userId: string }> = ({
  userId,
}) => {
  const { data: user, isLoading, error } = useGetUserById({ id: userId });
  if (isLoading) {
    return (
      <Typography variant="body3" color={muiColors.gray[600]}>
        Loading...
      </Typography>
    );
  }
  if (error) {
    return <Typography variant="body3">---</Typography>;
  }
  return <Typography variant="body3">{user?.email}</Typography>;
};
