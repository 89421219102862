import { createContext, useContext } from "react";

type LogsViewerContextType = {
  isInModal: boolean;
};

const LogsViewerContext = createContext<LogsViewerContextType>({
  isInModal: false,
});

export const LogsViewerProvider = LogsViewerContext.Provider;

export const useLogsViewerContext = () => {
  return useContext(LogsViewerContext);
};
