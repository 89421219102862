const defaultOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const formatPercentage = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
    style: "percent",
  }).format(value / 100);
};
