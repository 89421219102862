import React from "react";

import { useFormattedDate } from "@/components/k8sAddons/components/table/DateCell/useFormattedDate";

export const DateCell: React.FC<{
  dateString: string;
  overrideOptions?: Intl.DateTimeFormatOptions;
}> = ({ dateString, overrideOptions }) => {
  return useFormattedDate(dateString, overrideOptions);
};
