// this should cover every option of a modal / dataGrid options being open
const modalSelectors = [
  '[role="dialog"][open]',
  '[role="tooltip"].MuiDataGrid-panel',
  '[role="tooltip"].MuiDataGrid-menu',
  '[role="presentation"].MuiDialog-root',
  '[role="presentation"].MuiMenu-root',
  ".ReactModal__Overlay",
];

export const isModalOpen = () =>
  modalSelectors.some((selector) => document.querySelector(selector));
