import { useEffect, useMemo } from "react";

import { CostRightSizingSummary } from "../../../../../../generated/metricsApi";
import { getEpochFromTimeFrame } from "../../../../utils/costOptimizationUtils";
import { STATIC_TIME_FRAME } from "../../../../constants/costOptimizationConstants";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";
import { millisecondsToUnixTime } from "../../../../../../shared/utils/timeUtils";

export const useSaveMetricsParams = (data: CostRightSizingSummary) => {
  const { metricsParams, setMetricsParams } = useRecommendationsModalContext();

  const { clusterName, namespace, service, komodorServiceKind } = data;
  const { fromEpoch, toEpoch } = useMemo(
    () => getEpochFromTimeFrame(STATIC_TIME_FRAME, new Date().getTime()),
    []
  );

  useEffect(() => {
    if (!metricsParams) {
      setMetricsParams({
        clusterName,
        namespace,
        komodorServiceName: service,
        komodorServiceKind,
        fromEpoch: Math.floor(millisecondsToUnixTime(fromEpoch)),
        toEpoch: Math.floor(millisecondsToUnixTime(toEpoch)),
      });
    }
  }, [
    clusterName,
    fromEpoch,
    komodorServiceKind,
    metricsParams,
    namespace,
    service,
    setMetricsParams,
    toEpoch,
  ]);
};
