import { ApiGroupOption } from "./types";

export const apiGroupsOptions: ApiGroupOption[] = [
  { label: "core", value: "" },
  {
    label: "admissionregistration.k8s.io",
    value: "admissionregistration.k8s.io",
  },
  { label: "apiextensions.k8s.io", value: "apiextensions.k8s.io" },
  { label: "apiregistration.k8s.io", value: "apiregistration.k8s.io" },
  { label: "apps", value: "apps" },
  { label: "authentication.k8s.io", value: "authentication.k8s.io" },
  { label: "authorization.k8s.io", value: "authorization.k8s.io" },
  { label: "autoscaling", value: "autoscaling" },
  { label: "batch", value: "batch" },
  { label: "certificates.k8s.io", value: "certificates.k8s.io" },
  { label: "coordination.k8s.io", value: "coordination.k8s.io" },
  { label: "discovery.k8s.io", value: "discovery.k8s.io" },
  { label: "events.k8s.io", value: "events.k8s.io" },
  {
    label: "flowcontrol.apiserver.k8s.io",
    value: "flowcontrol.apiserver.k8s.io",
  },
  { label: "metrics.k8s.io", value: "metrics.k8s.io" },
  { label: "networking.k8s.io", value: "networking.k8s.io" },
  { label: "node.k8s.io", value: "node.k8s.io" },
  { label: "policy", value: "policy" },
  { label: "rbac.authorization.k8s.io", value: "rbac.authorization.k8s.io" },
  { label: "scheduling.k8s.io", value: "scheduling.k8s.io" },
  { label: "settings.k8s.io", value: "settings.k8s.io" },
  { label: "storage.k8s.io", value: "storage.k8s.io" },
];
