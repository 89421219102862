import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import { DeployIssueDataParsed } from "../../common/types";
import { LatestDeploysTooltip } from "../latestDeploysInsight/LatestDeploysTooltip";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";

const LIMIT_DECREASED_TOOLTIP = "limit-decreased-tooltip";

export const RecentLimitDecreased: React.FC<{
  limitDecreaseInsight: DeployIssueDataParsed;
  startTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
}> = ({ limitDecreaseInsight, setCorrelatedEvent, startTime }) => {
  return (
    <InsightContainer>
      <TooltipWrapper tooltipId={LIMIT_DECREASED_TOOLTIP}>
        <Insight>
          Correlated recent <DashedText>limit decrease</DashedText> was found
          prior the issue
        </Insight>
      </TooltipWrapper>
      <LatestDeploysTooltip
        deploys={[limitDecreaseInsight]}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
        tooltipId={LIMIT_DECREASED_TOOLTIP}
        ariaLabel={AriaLabels.AvailabilityTriage.LimitDecreaseInsightTooltip}
        ariaLabelLink={
          AriaLabels.AvailabilityTriage.LimitDecreaseInsightTooltipLink
        }
      />
    </InsightContainer>
  );
};
