import React from "react";
import { Button } from "@komodorio/design-system/deprecated";
import { Loader16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

interface Props {
  onClick(): void;
  loading: boolean;
}
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 2rem;
  right: 50%;
  transform: translateX(50%);
`;

export const LoadMoreButton: React.FC<Props> = ({ onClick, loading }) => {
  return (
    <Container>
      <Button
        variant="primary"
        onClick={!loading ? onClick : undefined}
        icon={loading ? Loader16 : undefined}
      >
        Load More
      </Button>
    </Container>
  );
};
