import { useMemo } from "react";

import { Timeframe } from "../../../../shared/types/TimeWindow";
import {
  Datapoint,
  OptimizationStrategy,
  WorkloadMetricsDataPoints,
} from "../../../../generated/metricsApi";
import { useTransformMetricsData } from "../../../ResourceView/tabs/MetricsTab/hooks/metricsTabHooks";
import {
  FilterOutMetricsMapper,
  GraphDataPoint,
  MetricName,
  MetricsAggregationType,
  MetricsData,
} from "../../types";
import { getLineList } from "../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabUtils";

import { workloadDataKeyToMetricsDataKey } from "./types";

const initialMetricsData: MetricsData = {
  limit: [],
  request: [],
  usage: [],
};

const useWorkloadMetricsDataToMetricsData = (
  workloadMetricsData?: WorkloadMetricsDataPoints
): MetricsData => {
  return useMemo(() => {
    if (!workloadMetricsData) return initialMetricsData;
    return Object.entries(workloadMetricsData).reduce<MetricsData>(
      (acc, [key, value]) => {
        if (!value) return acc;

        const metricsKey =
          workloadDataKeyToMetricsDataKey[
            key as keyof WorkloadMetricsDataPoints
          ];

        acc[metricsKey] = value.map((datapoint: Datapoint) => {
          return {
            x: datapoint.timestampMs,
            y: datapoint.value,
          };
        });
        return acc;
      },
      {
        ...initialMetricsData,
      }
    );
  }, [workloadMetricsData]);
};

export const useTransformWorkloadMetricsData = (
  workloadMetricsData: WorkloadMetricsDataPoints | undefined,
  timeWindow: Timeframe,
  recommendationStrategy?: OptimizationStrategy
): GraphDataPoint[] => {
  const metricsDataAsMetricsData =
    useWorkloadMetricsDataToMetricsData(workloadMetricsData);

  return useTransformMetricsData(
    metricsDataAsMetricsData,
    timeWindow,
    recommendationStrategy
  );
};

export const useHasGraphData = (data?: WorkloadMetricsDataPoints) => {
  return useMemo(() => {
    if (!data) return false;
    return Object.values(data).some((dataPoints) => dataPoints?.length > 0);
  }, [data]);
};

export const useGetLineList = (
  selectedAggregationMetric: MetricsAggregationType,
  filterOutMetricsMapper?: FilterOutMetricsMapper
): MetricName[] => {
  return useMemo(
    () => getLineList(selectedAggregationMetric, filterOutMetricsMapper),
    [filterOutMetricsMapper, selectedAggregationMetric]
  );
};
