import React, { useEffect, useState } from "react";
import {
  Typography,
  Modal,
  ModalActions,
  ModalContent,
  Button,
} from "@komodorio/design-system/deprecated";
import { CheckCircleOutlined24 } from "@komodorio/design-system/icons";
import { palette } from "@komodorio/design-system";
import MuiButton from "@mui/material/Button";
import styled from "styled-components";

import { INVESTIGATION } from "../../routes/routes";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { useInvestigationModeStore } from "../../../shared/store/investigationModeStore/investigationModeStore";
import { setShowResourceDrawerSelector } from "../../../shared/store/investigationModeStore/investigationModeSelectors";

const TIME_TO_SHOW_MESSAGE = 4_000;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  padding: 0 6rem;
`;

const Content = styled(ModalContent)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const InvestigationModeButtonsSpan = styled.span`
  display: flex;
  gap: 0.5rem;
`;

export const ActionSentMessage: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const isInvestigationMode = window.location.pathname.includes(
    `/${INVESTIGATION}/`
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Content>
        <Container>
          <CheckCircleOutlined24 fill={palette.green[700]} />
          <Typography variant="title" size="large" bold>
            Action successfully sent
          </Typography>
          <Typography variant="title" size="large">
            This may take a few minutes to complete
          </Typography>
        </Container>
        <Typography variant="text" size="medium" color={palette.gray[600]}>
          You can safely close this popup
        </Typography>
      </Content>
      <ModalActions align="center">
        {isInvestigationMode ? (
          <InvestigationModeButtons onClose={onClose} />
        ) : (
          <Button onClick={onClose} variant="primary">
            Close
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

const InvestigationModeButtons: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const setShowResourceDrawer = useInvestigationModeStore(
    setShowResourceDrawerSelector
  );

  return (
    <InvestigationModeButtonsSpan>
      <MuiButton
        variant="text"
        size="large"
        onClick={onClose}
        aria-label={AriaLabels.InvestigationMode.ContinueInvestigatingButton}
      >
        Continue investigating
      </MuiButton>
      <MuiButton
        variant="contained"
        size="large"
        onClick={() => {
          onClose();
          setShowResourceDrawer(true);
        }}
        aria-label={AriaLabels.InvestigationMode.ViewServiceButton}
      >
        View service
      </MuiButton>
    </InvestigationModeButtonsSpan>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useShouldShowActionSentMessage = (
  isFetching: boolean
): boolean => {
  const [showMessage, setShowMessage] = useState(false);
  const isInvestigationMode = window.location.pathname.includes(
    `/${INVESTIGATION}/`
  );

  useEffect(() => {
    if (!isFetching) return;
    if (isInvestigationMode) {
      setShowMessage(true);
      return;
    } else {
      const timer = setTimeout(
        () => setShowMessage(true),
        TIME_TO_SHOW_MESSAGE
      );
      return () => clearTimeout(timer);
    }
  }, [isFetching, isInvestigationMode]);

  return showMessage;
};
