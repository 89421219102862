import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";

const PAGINATION_INTERVAL = 200;

export const useRefetchIntervalFromContext = (
  isPaginating: boolean
): number => {
  const { refetchInterval } = useAddonContext();

  if (isPaginating) {
    return PAGINATION_INTERVAL;
  }

  return refetchInterval || 0;
};
