import React, { useEffect, useMemo, useRef } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { InspectionResourceListSearch } from "../filters/InspectionResourceListSearch";
import InspectionResourceListClusterFilter from "../filters/InspectionResourceListClusterFilter";
import InspectionResourceListLabelsFilter from "../filters/InspectionResourceListLabelsFilter";
import InspectionResourceListFilter from "../filters/InspectionResourceListFilter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { buildUniqueValues } from "../buildUniquefilterValues";
import { FilterContainer } from "../styles";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
import {
  INSPECTION_NAMESPACES_PARAM_KEY,
  INSPECTION_STATUS_PARAM_KEY,
  INSPECTION_GROUP_PARAM_KEY,
  INSPECTION_POD_IP_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceListResponse } from "../InspectionViews/common";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useNamespacesFilter } from "../InspectionViews/inpectionViewsHooks";

import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { NamespacesWorkspace } from "@/generated/workspacesApi";

export const InspectionFilters: React.FC<{
  kubernetesResource: KubernetesResource;
  resourceList: ResourceListResponse;
  selectedCluster?: string;
  onClusterChange: (clusterName: string) => void;
  namespaces?: string[];
  clustersOptions: MuiSelectionOption<string>[];
  labels?: { search?: string };
}> = ({
  selectedCluster,
  onClusterChange,
  resourceList,
  kubernetesResource,
  namespaces,
  clustersOptions,
  labels = {},
}) => {
  const { showIpFilter: ipFilterFF } = useOverridableFlags();
  const [selectedNamespaces, setSelectedNamespaces] = useNamespacesFilter();
  const { currentWorkspaceId, isNamespaceWorkspace, currentWorkspace } =
    useWorkspaces();
  const lastWorkspaceId = useRef<string | undefined>(currentWorkspaceId);

  useEffect(() => {
    if (isNamespaceWorkspace) {
      setSelectedNamespaces(
        (currentWorkspace?.value as NamespacesWorkspace).namespaces
      );
      return;
    }

    if (lastWorkspaceId.current !== currentWorkspaceId) {
      setSelectedNamespaces([]);
    }
  }, [
    currentWorkspace?.value,
    currentWorkspaceId,
    isNamespaceWorkspace,
    setSelectedNamespaces,
  ]);

  const { allNamespaces, allStatuses, allLabels, allGroups, allIps } = useMemo(
    () => buildUniqueValues(resourceList, { withGroups: true }),
    [resourceList]
  );

  const allNamespacesOptions =
    namespaces && namespaces.length > 0 ? namespaces : allNamespaces;

  const foundSelectedNamespaces = useMemo(() => {
    return selectedNamespaces.filter((ns) => allNamespacesOptions.includes(ns));
  }, [allNamespacesOptions, selectedNamespaces]);

  return (
    <FilterContainer>
      <InspectionResourceListSearch
        labelText={labels.search}
        resourceName={
          kubernetesResource.PresentName ?? kubernetesResource.NameInK8S
        }
      />

      <InspectionResourceListClusterFilter
        cluster={selectedCluster}
        onClusterChange={onClusterChange}
        options={clustersOptions}
      />

      {kubernetesResource.showGroupFilter && (
        <InspectionResourceListFilter
          items={allGroups as string[]}
          filterName={INSPECTION_GROUP_PARAM_KEY}
          filterTitle="Group"
        />
      )}

      {kubernetesResource.Namespaced && (
        <InspectionResourceListFilter
          items={allNamespacesOptions}
          filterName={INSPECTION_NAMESPACES_PARAM_KEY}
          filterTitle="Namespaces"
          isEnabled={!isNamespaceWorkspace}
          selectedFields={foundSelectedNamespaces}
          setSelectedFields={setSelectedNamespaces}
        />
      )}

      {kubernetesResource.ShowStatusFilter && (
        <InspectionResourceListFilter
          items={allStatuses}
          filterName={INSPECTION_STATUS_PARAM_KEY}
          filterTitle="Status"
        />
      )}

      {kubernetesResource.NameInK8S === "pods" && ipFilterFF === true && (
        <InspectionResourceListFilter
          items={allIps as string[]}
          filterName={INSPECTION_POD_IP_PARAM_KEY}
          filterTitle="IPs"
        />
      )}

      {kubernetesResource.hasLabels && (
        <InspectionResourceListLabelsFilter items={allLabels} />
      )}
    </FilterContainer>
  );
};
