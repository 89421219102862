import React, { useMemo } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { blackGreyText, blueBrand } from "../../../Colors";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import Banner, { bannerButtonStyle } from "../../common/Banner/Banner";
import { TextRegular } from "../../common/typography";
import { useAppMode } from "../../../shared/utils/sandbox";
import { isAgentsInstalledLocalStorage } from "../../../shared/hooks/useAgents";

import {
  SandboxStatusType,
  getAccountStatus,
  SandboxAccountStatuses,
} from "./utils";

import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";

const Title = styled(TextRegular)`
  font-weight: 500;
  color: ${blackGreyText};
`;

const StyledButton = styled.div`
  ${bannerButtonStyle};
  background-color: ${blueBrand};
  color: white;
`;

export const SandboxStatusBanner: React.FC<{
  accountStatus: SandboxStatusType;
  height: string;
}> = ({ accountStatus, height }) => {
  const { title, ctaText, event } = SandboxAccountStatuses[accountStatus];
  const navigateToAppMode = useAppMode();
  return (
    <Banner height={height}>
      <Title>{title}</Title>
      <StyledButton
        onClick={() => {
          dispatchEvent(event);
          navigateToAppMode();
        }}
      >
        {ctaText}
      </StyledButton>
    </Banner>
  );
};

const SandboxAccountBanner: React.FC<{ height: string }> = ({ height }) => {
  const [isAgentsInstalled] = useStateInLocalStorage(
    isAgentsInstalledLocalStorage,
    ""
  );

  const { isAuthenticated } = useAuth0();

  const accountStatus: SandboxStatusType = useMemo(
    () => getAccountStatus(isAgentsInstalled === "true", isAuthenticated),
    [isAgentsInstalled, isAuthenticated]
  );

  return <SandboxStatusBanner accountStatus={accountStatus} height={height} />;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default SandboxAccountBanner;
