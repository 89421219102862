import React from "react";

import { DrawerContentContainer } from "../../../shared/DrawerBase/styles";
import { NoisyNeighborSupportingData } from "../../../../../../../../../../generated/reliabilityApi";

import { AffectedNodes } from "./AffectedNodes/AffectedNodes";
import { Containers } from "./Containers/Containers";

interface Props {
  supportingData: NoisyNeighborSupportingData | undefined;
  komodorUid: string | undefined;
}

export const Details: React.FC<Props> = ({ supportingData, komodorUid }) => {
  return (
    <DrawerContentContainer>
      <Containers
        noisyContainers={supportingData?.noisyContainers}
        komodorUid={komodorUid}
      />
      <AffectedNodes
        nodes={supportingData?.memoryVictims}
        komodorUid={komodorUid}
      />
    </DrawerContentContainer>
  );
};
