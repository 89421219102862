import NodeStatus from "./NodeStatus";

class Node {
  private name: string;
  private status: NodeStatus;

  constructor(name: string, status: NodeStatus) {
    this.name = name;
    this.status = status;
  }

  getName(): string {
    return this.name;
  }

  getStatus(): string {
    return this.status.get();
  }
}

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Node;
