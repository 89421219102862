import React from "react";

import { useViolation } from "../../../hooks/useViolation";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import {
  useGetClusterSummaryData,
  useGetHeaderProps,
} from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { parseKomodorUid } from "../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { getHowDescription, getWhyDescription, whatDescription } from "./texts";
import { Details } from "./Details/Details";

export const DeprecatedApi: React.FC = () => {
  const { data } = useViolation();

  const { cluster } =
    parseKomodorUid(data?.data.violation?.komodorUid ?? "") ?? {};

  const violation = data?.data.violation;

  const supportingData = violation?.supportingData.deprecatedApis;

  const headerProps = useGetHeaderProps(violation);
  const summaryProps = useGetClusterSummaryData({
    komodorUid: violation?.komodorUid,
    clusterProvider: supportingData?.clusterProvider,
    policyName: violation?.policyName,
    policyId: violation?.policyId,
  });

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {whatDescription}
              <Details supportingData={supportingData} clusterName={cluster} />
            </>
          }
          whyDescription={getWhyDescription}
          howDescription={getHowDescription}
        />
      }
    />
  );
};
