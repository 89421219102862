import { useMemo } from "react";
import { get } from "lodash";

import Resource from "../../../../resources";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";
import { AgentProperties } from "../../../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportResourceListFromDB } from "../../../../../../shared/utils/agent/agent";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Job from "../../../../resources/job";
import CronJob from "../../../../resources/cronjob";

export const useRelatedResourcesSelectorRenderConfirmation = (
  resource: Resource,
  agentInfo: AgentProperties | null
): boolean => {
  const {
    relatedResourcesSelector,
    relatedResourcesSelectorInCronJobEventsView,
  } = useOverridableFlags();

  const isJobOfCronJob = resource instanceof Job && resource.controlledBy;

  const isCronJob = resource instanceof CronJob;

  const deletedOrInactiveKomodorService =
    get(resource, "isDeleted") || get(resource, "inactive");

  return useMemo(
    () =>
      !!relatedResourcesSelector &&
      !!agentInfo &&
      doesAgentVersionSupportResourceListFromDB(agentInfo) &&
      !isJobOfCronJob &&
      !(isCronJob && !relatedResourcesSelectorInCronJobEventsView) &&
      !deletedOrInactiveKomodorService,

    [
      agentInfo,
      deletedOrInactiveKomodorService,
      isCronJob,
      isJobOfCronJob,
      relatedResourcesSelector,
      relatedResourcesSelectorInCronJobEventsView,
    ]
  );
};
