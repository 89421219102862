import React from "react";
import { Scale24 } from "@komodorio/design-system/icons";
import Anchor from "@mui/icons-material/Anchor";
import LanguageOutlined from "@mui/icons-material/LanguageOutlined";

import { addonsDemoRoutes } from "../../ClustersDemoViewV3/components/addons/constants";
import { DEMO } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

export const AddonsDemoNodeAutoscalersLink: React.FC = () => {
  return (
    <AppBarLink
      text="Node Autoscalers"
      route={`${DEMO}/${addonsDemoRoutes.nodeAutoscalers}`}
      icon={<Scale24 width={20} height={20} />}
    />
  );
};

export const AddonsDemoExternalDNSLink: React.FC = () => {
  return (
    <AppBarLink
      text="External DNS"
      route={`${DEMO}/${addonsDemoRoutes.externalDNS}`}
      icon={<LanguageOutlined fontSize="small" />}
    />
  );
};

export const AddonsDemoCertManagerLink: React.FC = () => {
  return (
    <AppBarLink
      text="Cert-manager"
      route={`${DEMO}/${addonsDemoRoutes.certManager}`}
      icon={<Anchor fontSize="small" />}
    />
  );
};
