import React from "react";

import { ViolationsTableHandler } from "../pages/violations/ViolationsBreakdown/ViolationTableWithContext/ViolationsTableHandler";

import { IReliabilityByScope } from "./violationsDrawerByScopeTypes";
import { ScopedGroupAggregations } from "./ScopedGroupAggregations";
import { useScope } from "./hooks/useScope";

export const ViolationsDrawerByScopeContent: React.FC<IReliabilityByScope> = ({
  scope,
  additionalConfig,
}) => {
  const { hasImpactGroupId } = useScope(scope);

  const showImpactGroupAggregations =
    additionalConfig?.showImpactAggregations && hasImpactGroupId;

  return (
    <>
      {showImpactGroupAggregations && <ScopedGroupAggregations />}
      <ViolationsTableHandler />
    </>
  );
};
