import { useCallback } from "react";
import { GridRowParams } from "@mui/x-data-grid-pro";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import {
  BasicViolation,
  CheckType,
  RealTimeChecksSupportingData,
} from "@/generated/reliabilityApi";
import { Addon, Entity } from "@/generated/addonsApi";
import { buildServiceId } from "@/shared/utils/serviceHelpers";
import { useAccountName } from "@/shared/hooks/useAccountName";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { WORKFLOW_RUN_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";

const checkTypeToMonitorType: Partial<Record<CheckType, MonitorType>> = {
  [CheckType.UnhealthyService]: MonitorType.Availability,
  [CheckType.UnhealthyNode]: MonitorType.Node,
  [CheckType.UnhealthyPvc]: MonitorType.Pvc,
  [CheckType.FailedJob]: MonitorType.Job,
};

const useOpenEventDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const accountName = useAccountName();

  return useCallback(
    ({
      issueSupportingData,
      komodorUid,
      checkType,
    }: {
      issueSupportingData: RealTimeChecksSupportingData;
      komodorUid: string;
      checkType: CheckType;
    }) => {
      const eventType = checkTypeToMonitorType[checkType];

      const {
        cluster = "",
        namespace = "",
        name = "",
      } = parseKomodorUid(komodorUid) ?? {};

      const serviceId = buildServiceId(accountName, cluster, namespace, name);

      if (eventType) {
        pushDrawer({
          drawerType: DrawerType.EventDrawerById,
          eventIds: [issueSupportingData?.issueId],
          eventType,
          serviceId,
        });
      }
    },
    [accountName, pushDrawer]
  );
};

const useOpenWorkflowDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (issueSupportingData: RealTimeChecksSupportingData, komodorUid: string) => {
      // komodorUid contains the workflow id in komodorUid template
      const {
        kind: engine = "",
        cluster = "",
        namespace = "",
        name: dagId = "",
      } = parseKomodorUid(komodorUid) ?? {};

      const workflowUid = buildKomodorWorkflowId({
        cluster,
        namespace,
        engine,
        dagId,
      });

      pushDrawer({
        drawerType: DrawerType.K8sAddonLiveStateDrawer,
        addon: Addon.Workflow,
        entity: Entity.WorkflowDag,
        clusterName: cluster,
        uid: workflowUid,
        name: dagId,
        urlStates: {
          [WORKFLOW_RUN_PARAM_KEY]: issueSupportingData.issueId,
        },
      });
    },
    [pushDrawer]
  );
};

export const useOpenIssueDrawer = () => {
  const openEventDrawer = useOpenEventDrawer();
  const openWorkflowDrawer = useOpenWorkflowDrawer();

  return useCallback(
    (params: GridRowParams<BasicViolation>) => {
      const { checkType, komodorUid, supportingData } = params.row;

      const issueSupportingData = supportingData[
        checkType as keyof typeof supportingData
      ] as RealTimeChecksSupportingData;

      if (issueSupportingData) {
        if (checkType === CheckType.UnhealthyWorkflow) {
          openWorkflowDrawer(issueSupportingData, komodorUid);
        } else {
          openEventDrawer({ issueSupportingData, komodorUid, checkType });
        }
      }
    },
    [openEventDrawer, openWorkflowDrawer]
  );
};
