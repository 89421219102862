import { useMemo } from "react";

import { ResourceResultType } from "@/components/ResourceView/types";
import useResourceWithInterval from "@/components/ResourceView/useResource";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { jsonToYaml } from "@/shared/utils/yaml/yaml";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import Resource from "@/components/ResourceView/resources";

interface Props {
  komodorUid: string;
  resourceFormatter?: (resource: Resource | undefined) => object | undefined;
}

export const useYamlFromKomodorUid = ({
  komodorUid,
  resourceFormatter,
}: Props) => {
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const agentId = useActiveAgent(cluster) ?? "";

  const { resource } = useResourceWithInterval({
    agentId,
    cluster,
    namespace,
    resourceName: name,
    resourceType: kind,
    executeMutation: !!agentId && !!komodorUid,
    preferredResultType: ResourceResultType.RawResource,
  });

  return useMemo(() => {
    const wantedYaml = resourceFormatter
      ? resourceFormatter(resource)
      : resource?.fullObj;

    try {
      return jsonToYaml(JSON.stringify(wantedYaml));
    } catch (error) {
      notifyDDError(error as Error, {
        message: `Failed to parse YAML for container ${wantedYaml}`,
      });
      return;
    }
  }, [resource, resourceFormatter]);
};
