import { muiColors } from "@komodorio/design-system";

import { ConfirmationDialogStaticProps } from "@/components/reliability/components/pages/policies/shared/ConfirmationDialog";

export const getDeleteDialogTexts = (
  policyName: string
): ConfirmationDialogStaticProps => ({
  title: "Delete Policy?",
  textLine1: (
    <>
      Are you sure you want to{" "}
      <span style={{ color: muiColors.pink[600] }}>
        delete the {policyName} policy?
      </span>
    </>
  ),
  textLine2: "Deleted policies cannot be recovered",
  actionButtonLabel: "Delete policy",
  cancelButtonAriaLabel: "Cancel deletion",
});

export const policyTableSearchPlaceholder = "Policy name, cluster";
