import { GraphDataPoint, MultiLineItem } from "../types";
import {
  MetricsUsageColor,
  MetricsUsageColor1,
  MetricsUsageColor2,
  MetricsUsageColor3,
  MetricsUsageColor4,
} from "../styles";

export type PointsByTime = {
  [time: number]: Omit<GraphDataPoint, "time">;
};
export const commonMetrics = ["capacityBytes", "limitBytes", "requestBytes"];
const colors = [
  MetricsUsageColor,
  MetricsUsageColor1,
  MetricsUsageColor2,
  MetricsUsageColor3,
  MetricsUsageColor4,
];

export const getColorByIndex = (index: number) => colors[index % colors.length];

export function buildMetric(metric: string, item: MultiLineItem) {
  if (commonMetrics.includes(metric)) {
    return metric;
  }
  return `${item.name}|${item.index}|${metric}`;
}

export function isMultilineItem(str: string): boolean {
  return str.split("|").length === 3;
}

export function splitStringToItem(str: string): {
  name: string;
  index: number;
  metric: string;
} {
  const [name, index, metric] = str.split("|");
  return { name, index: parseInt(index), metric };
}

export function getPointsByTime(acc: PointsByTime, item: MultiLineItem) {
  item.data.forEach((cur) => {
    const time = cur.time;
    if (acc[time] === undefined) {
      acc[time] = {};
    }

    const updatedCur = Object.fromEntries(
      Object.entries(cur).map(([key, value]) => {
        if (value === null) {
          return ["", ""];
        }
        return [buildMetric(key, item), value];
      })
    );
    delete updatedCur[buildMetric("time", item)];

    acc[time] = Object.assign({}, acc[time], updatedCur);
    return acc;
  });

  return acc;
}
