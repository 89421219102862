import React from "react";

import { DescribePanel } from "../common/DescribeSection";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ConfigMap from "../../../resources/configMap";
import { DescribeMainPanelData } from "../common/DescribeMainPanelData";
import { DescribeTabContentWrapper } from "../common/DescribeTabContentWrapper";

import { ConfigMapData } from "./ConfigMapData";

interface ConfigMapDescribeTabProps {
  resource: ConfigMap;
}

export const ConfigMapDescribeTab: React.FC<ConfigMapDescribeTabProps> = ({
  resource,
}) => {
  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeMainPanelData resource={resource} />
      </DescribePanel>

      <ConfigMapData configMapData={resource.data} />
    </DescribeTabContentWrapper>
  );
};
