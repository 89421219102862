import React, { useMemo } from "react";
import styled from "styled-components";
import { isToday } from "date-fns";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { SumByDayDataSumByDayInner } from "../../../../../../../../../../../generated/applicationInsights";
import { getDateKey } from "../../../utils/utils";

interface MinimizedViewProps {
  previousDatapoints: SumByDayDataSumByDayInner[];
  currentDatapoints: SumByDayDataSumByDayInner[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const MinimizedView: React.FC<MinimizedViewProps> = ({
  previousDatapoints,
  currentDatapoints,
}) => {
  const [previousTimeRange, currentTimeRange] = useMemo(() => {
    const startPreviousTS = previousDatapoints[0].ts;
    const endPreviousTS = previousDatapoints[previousDatapoints.length - 1].ts;
    const startCurrentTS = currentDatapoints[0].ts;
    const endCurrentTS = currentDatapoints[currentDatapoints.length - 1].ts;

    return [
      `${getDateKey(startPreviousTS)}-${getDateKey(endPreviousTS)}`,
      `${getDateKey(startCurrentTS)}-${
        isToday(endCurrentTS) ? "today" : getDateKey(endCurrentTS)
      }`,
    ];
  }, [currentDatapoints, previousDatapoints]);
  return (
    <Container>
      <Typography variant="body3" color={muiColors.gray[500]}>
        {previousTimeRange}
      </Typography>
      <Typography variant="body3" color={muiColors.gray[500]}>
        {currentTimeRange}
      </Typography>
    </Container>
  );
};
