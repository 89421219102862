import {
  AppViewsResources,
  ResourcesTags,
  UpdateResourcesPayload,
} from "../../../components/appView/sections/AppView/types/appViewsResourcesTypes"; //move

import { AppViewsResourcesStore } from "./appViewsResourcesStore";

// getters
export const selectResources = (
  state: AppViewsResourcesStore
): AppViewsResources => state.resources;
export const selectIsLoadingResources = (
  state: AppViewsResourcesStore
): boolean => state.isLoadingResources;
export const selectTags = (state: AppViewsResourcesStore): ResourcesTags =>
  state.tags;
export const selectClusters = (state: AppViewsResourcesStore): Set<string> =>
  state.clusters;

// setters
export const selectUpdateResources = (
  state: AppViewsResourcesStore
): ((payload: UpdateResourcesPayload) => void) => state.updateResources;
export const selectSetTags = (
  state: AppViewsResourcesStore
): ((tags: ResourcesTags) => void) => state.setTags;
export const selectSetClusters = (
  state: AppViewsResourcesStore
): ((clusters: Set<string>) => void) => state.setClusters;
export const selectResetResources = (
  state: AppViewsResourcesStore
): ((options?: { isLoadingResources: boolean }) => void) =>
  state.resetResources;
