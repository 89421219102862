import React from "react";

import { IconProps } from "../types";

export const Scale24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M21.7801 16.28C21.9126 16.1379 21.9847 15.9498 21.9813 15.7555C21.9778 15.5612 21.8991 15.3758 21.7617 15.2384C21.6243 15.101 21.4389 15.0223 21.2446 15.0189C21.0503 15.0154 20.8623 15.0875 20.7201 15.22L17.5001 18.44L17.5001 3.75003C17.5001 3.55112 17.4211 3.36035 17.2804 3.2197C17.1398 3.07905 16.949 3.00003 16.7501 3.00003C16.5512 3.00003 16.3604 3.07905 16.2198 3.2197C16.0791 3.36035 16.0001 3.55112 16.0001 3.75003L16.0001 18.44L12.7801 15.22C12.7114 15.1463 12.6286 15.0872 12.5366 15.0462C12.4446 15.0053 12.3453 14.9832 12.2446 14.9814C12.1439 14.9797 12.0439 14.9982 11.9505 15.0359C11.8571 15.0736 11.7723 15.1298 11.7011 15.201C11.6298 15.2722 11.5737 15.357 11.536 15.4504C11.4982 15.5438 11.4797 15.6438 11.4815 15.7446C11.4833 15.8453 11.5053 15.9446 11.5463 16.0366C11.5873 16.1286 11.6464 16.2114 11.7201 16.28L16.2201 20.78C16.3607 20.9205 16.5513 20.9994 16.7501 20.9994C16.9488 20.9994 17.1395 20.9205 17.2801 20.78L21.7801 16.28ZM12.2801 7.72003C12.3538 7.78869 12.4129 7.87149 12.4539 7.96349C12.4949 8.05549 12.5169 8.1548 12.5187 8.25551C12.5205 8.35621 12.5019 8.45624 12.4642 8.54963C12.4265 8.64302 12.3703 8.72785 12.2991 8.79907C12.2279 8.87029 12.1431 8.92643 12.0497 8.96415C11.9563 9.00187 11.8563 9.0204 11.7556 9.01862C11.6549 9.01685 11.5556 8.9948 11.4636 8.95381C11.3716 8.91282 11.2888 8.85372 11.2201 8.78003L8.00009 5.56003L8.00009 20.25C8.00009 20.4489 7.92107 20.6397 7.78042 20.7804C7.63977 20.921 7.449 21 7.25009 21C7.05118 21 6.86041 20.921 6.71976 20.7804C6.57911 20.6397 6.50009 20.4489 6.50009 20.25L6.50009 5.56003L3.28009 8.78003C3.13792 8.91251 2.94987 8.98463 2.75557 8.98121C2.56127 8.97778 2.37588 8.89907 2.23847 8.76165C2.10106 8.62424 2.02234 8.43885 2.01892 8.24455C2.01549 8.05025 2.08761 7.8622 2.22009 7.72003L6.72009 3.22003C6.86072 3.07958 7.05134 3.00069 7.25009 3.00069C7.44884 3.00069 7.63946 3.07958 7.78009 3.22003L12.2801 7.72003Z" />
    </svg>
  );
};

Scale24.defaultProps = {
  fill: "currentColor",
};
