import { useEffect, useState } from "react";
import { CombinedError } from "urql";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { Static, Record, String } from "runtypes";

import { useCreateSlackInstallationMutation } from "../../../../generated/graphql";
import { getStoredRedirectUrl } from "../../../../shared/utils/redirectUrlService";
import { getCookieValue } from "../../../../shared/utils/cookie/cookie";

import { CookieKeys } from "@/shared/utils/cookie/cookieKeys";

const SlackResponseQueryParams = Record({ code: String, state: String });
const parseSlackQuery = (): Static<typeof SlackResponseQueryParams> | null => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  window.history.replaceState(
    {},
    document.title,
    window.location.href.split("?")[0]
  );
  return SlackResponseQueryParams.guard(query) ? query : null;
};

const extractErrorCode = (error?: CombinedError) =>
  error?.graphQLErrors?.[0].message ?? "unknown";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default (): string | null => {
  const createInstallation = useCreateSlackInstallationMutation()[1];
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const query = parseSlackQuery();
      if (!query) {
        return setErrorCode("query");
      }

      const { code, state } = query;
      if (state !== getCookieValue(CookieKeys.SLACK_STATE_KEY)) {
        return setErrorCode("state");
      }
      const result = await createInstallation({
        code,
        redirect_uri: `${window.location.origin}/installation/slack`,
      });
      if (result.error) {
        return setErrorCode(extractErrorCode(result.error));
      }

      const redirectUrl = getStoredRedirectUrl() ?? "/main/integration";
      navigate(redirectUrl, { replace: true });
    })();
  }, [navigate, createInstallation]);

  return errorCode;
};
