interface PodLogsIdentifierArgs {
  agentId?: string | null;
  namespace: string;
  podName: string;
  containerName?: string;
}

export const buildIdentifier = ({
  agentId = "",
  namespace = "",
  podName = "",
  containerName = "",
}: PodLogsIdentifierArgs) => {
  return `pod:logs${agentId ?? ""}-${namespace}-${podName}-${containerName}`;
};
