import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import Switcher from "../../../../common/controls/Switcher";

interface ShowChangesSwitcherProps {
  showChangesOnly: boolean;
  setShowChangesOnly: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}
const ShowChangesContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-self: end;
`;
const ShowChangesSwitcher: React.FC<ShowChangesSwitcherProps> = ({
  showChangesOnly,
  setShowChangesOnly,
  disabled,
}) => {
  return (
    <ShowChangesContainer>
      <Switcher
        checked={disabled ? false : showChangesOnly}
        handleSwitcherChange={() => setShowChangesOnly((prev) => !prev)}
        color={palette.blue[600]}
      />
      <Typography
        size="medium"
        variant="text"
        color={disabled ? palette.gray[600] : palette.black[0]}
      >
        Only show changes
      </Typography>
    </ShowChangesContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ShowChangesSwitcher;
