import { Job } from "@komodorio/design-system/icons";

import { Direction } from "../../../common/ProcessList/SortTitle";

import { AvailableActions } from "./AvailableActions";

export const KubernetesJobResource = {
  NameInK8S: "jobs",
  Group: "workloads",
  NavBarName: "Jobs",
  PresentName: "Jobs",
  Namespaced: true,
  ShowStatusFilter: false,
  UnnecessaryColumns: [],
  SupportedActions: [
    AvailableActions.Edit,
    AvailableActions.Delete,
    AvailableActions.JobRerun,
  ],
  Headers: [
    { name: "Name" },
    { name: "Status" },
    { name: "Namespace" },
    { name: "Age" },
    { name: "Images" },
    { name: "Duration" },
    { name: "Selector" },
    { name: "Containers" },
    { name: "Completions" },
  ],
  DefaultSort: {
    ColumnName: "Name",
    Direction: Direction.up,
  },
  Kind: "Job",
  Icon: Job,
  hasLabels: true,
};
