import React from "react";
import styled from "styled-components";

import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { overviewCardsOrder } from "../../../constants/costOptimizationConstants";

import { SingleCard } from "./SingleCard";

const StyledContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const OverviewCards: React.FC = () => {
  const content = overviewCardsOrder.map((key) => (
    <SingleCard dataKey={key} key={key} />
  ));

  return (
    <StyledContainer
      aria-label={
        costOptimizationAriaLabels.allocationPage.overviewCards.container
      }
    >
      {content}
    </StyledContainer>
  );
};
