import React, { useCallback } from "react";
import styled from "styled-components";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { ButtonProps } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { useGetAvailableCheckTypes } from "../hooks/useGetAvailableCheckTypes";
import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { useAllCheckLabels } from "../hooks/useGetAllCheckTypes";
import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";

import { SelectMenuButton } from "@/shared/components/SelectMenuButton";
import { useTriggerAllFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useTriggerAllFields";
import { CheckType } from "@/generated/reliabilityApi";
import { useAddSingleCheckState } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useAddSingleCheckState";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

export const SELECT_ALL_BUTTON_TEXT = "Add all available check configurations";

export const dynamicTabButtonText = "Add configuration";
export const staticTabButtonText = "Add best practice check";

const {
  policies: {
    policiesDrawer: { addConfigurationButton: addConfigurationButtonAriaLabel },
  },
} = reliabilityArialLabels;

const StyledSelectMenuButton = styled(SelectMenuButton)`
  .selectMenuButtonElement {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
`;

type AddCheckButtonProps = {
  buttonProps?: ButtonProps;
};

export const AddCheckButton: React.FC<AddCheckButtonProps> = ({
  buttonProps,
}) => {
  const remainingCheckLabels = useGetAvailableCheckTypes();
  const {
    policyConfigurations,
    setSinglePolicyConfiguration,
    setAllPolicyConfigurations,
    isReadOnlyMode,
  } = usePolicyDrawerContext();
  const { setValue } = useFormContext();
  const isDynamicChecksTab = useIsDynamicChecksTab();

  const allCheckLabels = useAllCheckLabels({ sort: true });
  const triggerAllFields = useTriggerAllFields();
  const addSingleCheckState = useAddSingleCheckState();

  const onSelectedAll = useCallback(() => {
    triggerAllFields();

    const allCheckLabelsValues: Record<string, object> = {};

    allCheckLabels.forEach((check) => {
      allCheckLabelsValues[check.value] = {};
      setValue(`configurations.${check.value}`, { enabled: true });
    });

    setAllPolicyConfigurations({
      ...policyConfigurations,
      ...allCheckLabelsValues,
    });
  }, [
    allCheckLabels,
    policyConfigurations,
    setAllPolicyConfigurations,
    setValue,
    triggerAllFields,
  ]);

  const onCheckTypeSelected = useCallback(
    (option: MuiSelectionOption<string>) => {
      triggerAllFields();
      addSingleCheckState(option.value as CheckType);

      setSinglePolicyConfiguration({
        [option.value]: {},
      });
      setValue(`configurations.${option.value}`, { enabled: true });
    },
    [
      addSingleCheckState,
      setSinglePolicyConfiguration,
      setValue,
      triggerAllFields,
    ]
  );

  const isDisabled = remainingCheckLabels.length === 0 || isReadOnlyMode;

  return (
    <StyledSelectMenuButton
      menuOptions={remainingCheckLabels}
      buttonText={
        isDynamicChecksTab ? dynamicTabButtonText : staticTabButtonText
      }
      ariaLabel={addConfigurationButtonAriaLabel}
      selectAllText={SELECT_ALL_BUTTON_TEXT}
      classes={{ button: "selectMenuButtonElement" }}
      onSelect={onCheckTypeSelected}
      onSelectAll={onSelectedAll}
      buttonProps={{
        ...buttonProps,
        disabled: isDisabled,
      }}
    />
  );
};
