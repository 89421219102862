import { muiColors, theme } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { Skeleton, Typography } from "@komodorio/design-system/deprecated";
import { Loader16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;

const TileTitle = styled(MuiTypography).attrs({ variant: "overline" })`
  && {
    font-weight: 500;
    color: ${muiColors.gray[500]};
  }
`;

const WithUnit = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 0.3rem;
`;

const CenterRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1rem auto auto;
  gap: 0.3rem;
  align-items: center;
`;

export const FetchingFade = styled.div<{ showAnimation?: boolean }>`
  ${({ showAnimation }) =>
    showAnimation && `animation: fadeOut 3s ease-in-out infinite;`}

  @keyframes fadeOut {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }
`;

export const ImpactTile: React.FC<{
  title: string;
  value: string;
  unit?: string;
  changeValue?: number;
  loading?: boolean;
}> = ({ title, value, unit, changeValue, loading }) => {
  return (
    <Tile>
      <CenterRowGrid>
        {loading ? <Loader16 color={theme.icon} /> : <div />}
        <WithUnit>
          <FetchingFade showAnimation={loading}>
            <MuiTypography variant="h3" color={theme.foreground.fgPrimary}>
              {value}
            </MuiTypography>
          </FetchingFade>
          {unit && (
            <MuiTypography variant="h6" color={muiColors.gray[500]}>
              {unit}
            </MuiTypography>
          )}
        </WithUnit>
        {changeValue && (
          <Typography
            size="medium"
            bold
            color={
              changeValue >= 0
                ? theme.foreground.fgPink
                : theme.foreground.fgGreen
            }
          >
            {changeValue > 0 ? "+" : "-"}
            {Math.abs(changeValue)}%
          </Typography>
        )}
      </CenterRowGrid>
      <CenterRowGrid>
        <div />
        <TileTitle>{title}</TileTitle>
      </CenterRowGrid>
    </Tile>
  );
};

export const ImpactTileSkeleton: React.FC = () => {
  return <Skeleton height="100%" width="9rem" />;
};
