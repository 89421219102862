import { WorkloadMetricsContainersRestartsDataPoints } from "../../../../../../../../../generated/metricsApi";
import {
  ContainerRestartsSupportingDataContainersInner,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";

export const getContainerRestartsTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const data = supportingData?.restartingContainers?.containers;
  const totalRestarts = getTotalContainerRestarts(data ?? []);

  return `${totalRestarts} containers restarts in the last 7 days`;
};

export const getTotalContainerRestarts = (
  containers: ContainerRestartsSupportingDataContainersInner[]
) => containers.reduce((acc, curr) => acc + curr.restarts, 0);

export const getOnlyRestartingContainersMetricsData = (
  restartingContainers: ContainerRestartsSupportingDataContainersInner[],
  workloadMetricsContainersRestarts:
    | WorkloadMetricsContainersRestartsDataPoints[]
    | undefined
) => {
  const restartingContainersNames = restartingContainers.map(
    (container) => container.name
  );
  return workloadMetricsContainersRestarts?.filter((container) =>
    restartingContainersNames.includes(container.containerName)
  );
};
