import { useCallback } from "react";

import { INCLUDE_TYPE_FILTER_OPERATOR } from "../filterSectionConstants";

import { separateFilterTypes } from "./filterStateHadlerUtils";

import { Option } from "@/shared/components/CheckboxOptionsHandler/checkboxOptionsHandlerTypes";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

export const useOnOptionChange = () => {
  const [, setDataInUrl] = useStateInUrl();
  const filtersData = useFiltersDataInUrl();

  return useCallback(
    (filterCategory: string, options: Option[]) => {
      const newOptions = options
        .filter((option) => option.checked)
        .map((option) => option.value);

      const { searchOptions } = separateFilterTypes(
        filtersData[filterCategory] || []
      );

      if (newOptions.length === 0 && searchOptions.length === 0) {
        delete filtersData[filterCategory];
        setDataInUrl({
          filters: filtersData,
        });
        return;
      }

      setDataInUrl({
        filters: {
          ...filtersData,
          [filterCategory]: [
            { values: newOptions, operator: INCLUDE_TYPE_FILTER_OPERATOR },
            ...searchOptions,
          ],
        },
      });
    },
    [filtersData, setDataInUrl]
  );
};
