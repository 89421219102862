import { useMemo } from "react";

import { Deploy } from "../../../../generated/monitorsApi";
import { useMonitorsAPIGet } from "../../../../shared/hooks/monitors-api/client";
import { DEPLOYS_SEARCH_ENDPOINT } from "../../../../shared/hooks/monitors-api/requestResponseMaps";

import { DeployByServiceIdParams } from "./types";

export const useDeployByServiceId = ({
  serviceIds,
  fromEpoch,
  toEpoch,
  limit = 0,
  fields = [],
}: DeployByServiceIdParams): Deploy[] => {
  const deployEvents = useMonitorsAPIGet(
    DEPLOYS_SEARCH_ENDPOINT,
    {
      serviceIds,
      fromEpoch,
      toEpoch,
      order: "DESC",
      pageSize: limit,
      fields,
    },
    serviceIds.length === 0
  );
  return useMemo(
    () => deployEvents.data?.data || [],
    [deployEvents.data?.data]
  );
};
