import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { capitalize } from "lodash";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { PaddedCard } from "../../styles/CostOptimizationStyledComponents";
import { ColorIndication, graphUnits } from "../../../utils/graphUtils";

import { Path } from "./Path";

interface ChartTooltipProps {
  data: TooltipProps<ValueType, NameType>;
  unit: string;
}

const ChartTooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Card = styled(PaddedCard)`
  display: flex;
  flex-direction: column;
  min-width: 12rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryContainer = styled(Container)`
  gap: 0.4rem;
`;

const ValueContainer = styled(Container)`
  gap: 0.2rem;
  margin-left: 0.3rem;
`;

const getTooltipValue = (value: ValueType) => {
  return value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};

export const ChartTooltip: React.FC<ChartTooltipProps> = ({ data, unit }) => {
  const content = useMemo(
    () =>
      data.payload?.map((resourceRow) => {
        const baseLable = resourceRow.name as string;
        const isPrice = unit === graphUnits.price;
        const categoryLabel = isPrice ? baseLable : capitalize(baseLable);
        return (
          <ChartTooltipItem key={resourceRow.dataKey}>
            <CategoryContainer>
              <ColorIndication color={resourceRow.color} />
              <Path value={categoryLabel} isLimitedPath />
            </CategoryContainer>
            <ValueContainer>
              {isPrice && (
                <Typography variant="caption" color={muiColors.gray[500]}>
                  {unit}
                </Typography>
              )}
              <Typography variant="body2">
                {getTooltipValue(resourceRow.value ?? "")}
              </Typography>
              {!isPrice && (
                <Typography variant="caption" color={muiColors.gray[500]}>
                  {unit}
                </Typography>
              )}
            </ValueContainer>
          </ChartTooltipItem>
        );
      }),
    [data.payload, unit]
  );

  return (
    <Card>
      <Typography variant="h4">{data.label}</Typography>
      {content}
    </Card>
  );
};
