import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useWorkspacesApiClient } from "../apiClient";
import {
  ClustersFiltersBody,
  ClustersFiltersResponse,
  apiV1ClustersFiltersSearchPostUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspaceQueryKey } from "../useWorkspaceQueryKey";

export const CLUSTERS_FILTERS_PATH = "/clusters-filters/search";

const fetchClustersFilters = async (
  apiClient: AxiosInstance,
  body?: ClustersFiltersBody
) => {
  const { data } = await apiClient.post<ClustersFiltersResponse>(
    apiV1ClustersFiltersSearchPostUrl(
      { clustersFiltersBody: body ?? {} },
      apiClient.defaults.baseURL ?? ""
    ),
    body ?? {}
  );

  return data.data;
};

export const useGetClustersFilters = (
  body?: ClustersFiltersBody,
  options?: {
    enabled?: boolean;
    staleTime?: number;
    keepPreviousData?: boolean;
  }
) => {
  const apiClient = useWorkspacesApiClient();
  const keys = useWorkspaceQueryKey([CLUSTERS_FILTERS_PATH, body]);
  return useQuery(keys, () => fetchClustersFilters(apiClient, body), options);
};

export const useGetClustersTotalCount = (clusterNames?: string[]) => {
  const data = useGetClustersFilters(
    {
      filter: {
        clusterNames,
        includeDisconnected: true,
      },
    },
    { staleTime: 0, keepPreviousData: true }
  );
  return {
    ...data,
    data:
      data.data?.values?.[0]?.values.reduce(
        (acc, curr) => acc + curr.count,
        0
      ) ?? 0,
  };
};
