import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest,
  RbacPolicy,
  apiV1RbacPoliciesIdDeleteUrl,
} from "../../../../../generated/auth";

const DELETE_RBAC_POLICY_PATH = "/rbac-policies/:id";

const deleteRbacPolicy = async (
  apiClient: AxiosInstance,
  params: RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest
): Promise<RbacPolicy> => {
  const { data } = await apiClient.delete(
    apiV1RbacPoliciesIdDeleteUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useDeleteRbacPolicy = () => {
  const apiClient = useAuthApiClient();
  return useMutation(
    [DELETE_RBAC_POLICY_PATH],
    (params: RbacPoliciesApiApiV1RbacPoliciesIdDeleteRequest) =>
      deleteRbacPolicy(apiClient, params)
  );
};
