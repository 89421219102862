import React from "react";

import { IconProps } from "../types";

export const Workloads24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M3.5 7V18C3.5 18.2761 3.72386 18.5 4 18.5H12.0205C12.0636 19.0218 12.1734 19.5246 12.3414 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V13.5278C21.5548 13.1293 21.0498 12.7964 20.5 12.544V7H3.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8832 16.9388L20.9743 17.5918V18.4082L21.8832 19.0612C21.9658 19.0612 21.9658 19.1429 21.8832 19.2245L21.057 20.6122C20.9743 20.6939 20.8917 20.6939 20.8091 20.6939L19.8176 20.3673C19.6937 20.449 19.5904 20.5102 19.4872 20.5714C19.3839 20.6327 19.2806 20.6939 19.1567 20.7755L18.9914 21.8367C18.9914 21.9184 18.9088 22 18.8262 22H17.1738C17.0912 22 17.0086 21.9184 16.9259 21.8367L16.7607 20.7755C16.5128 20.6939 16.265 20.5306 16.0997 20.3673L15.1083 20.7755C15.0257 20.7755 14.943 20.7755 14.8604 20.6939L14.0342 19.3061C13.9516 19.2245 14.0342 19.1429 14.1168 19.0612L15.0257 18.4082V17.5918L14.1168 16.9388C14.0342 16.8571 14.0342 16.7755 14.0342 16.6939L14.8604 15.3061C14.943 15.2245 15.0257 15.2245 15.1083 15.2245L16.0997 15.6327C16.2237 15.551 16.3269 15.4898 16.4302 15.4286C16.5335 15.3673 16.6368 15.3061 16.7607 15.2245L16.9259 14.1633C16.9259 14.0816 17.0086 14 17.1738 14H18.8262C18.9088 14 18.9914 14.0816 18.9914 14.1633L19.1567 15.2245C19.4045 15.3061 19.6524 15.4694 19.9003 15.6326L20.8917 15.2245C20.9743 15.2245 21.1396 15.2245 21.1396 15.3061L21.9658 16.6939C22.0484 16.7755 21.9658 16.8571 21.8832 16.9388ZM16.6781 17.9184C16.6781 18.5714 17.2564 19.1429 17.9174 19.1429C18.5783 19.1429 19.1567 18.5714 19.1567 17.9184C19.1567 17.2653 18.5783 16.6939 17.9174 16.6939C17.2564 16.6939 16.6781 17.2653 16.6781 17.9184Z"
      />
    </svg>
  );
};

Workloads24.defaultProps = {
  fill: "currentColor",
};
