import { useMemo } from "react";

import { MetricsAggregationType } from "../Metrics/types";
import { useInvestigationModeStore } from "../../shared/store/investigationModeStore/investigationModeStore";
import { issueTypeSelector } from "../../shared/store/investigationModeStore/investigationModeSelectors";

import { AvailabilityInvestigationIssueType } from "./availability/types";

export const useMetricsDefaultAggregationType = ():
  | MetricsAggregationType
  | undefined => {
  const issueType = useInvestigationModeStore(issueTypeSelector);

  return useMemo(() => {
    return issueType === AvailabilityInvestigationIssueType.OOMKilled ||
      issueType ===
        AvailabilityInvestigationIssueType.OOMKilledContainerLimit ||
      issueType === AvailabilityInvestigationIssueType.Evicted
      ? MetricsAggregationType.Max
      : undefined;
  }, [issueType]);
};
