import React from "react";
import { TooltipProps } from "recharts/types/component/Tooltip";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { formatNumber } from "../../../../../../../../../../../shared/utils/formatNumber";
import { CustomTooltipContainer } from "../../../../../../shared/graph/TooltipContainer";

interface CustomTooltipProps {
  data: TooltipProps<ValueType, NameType>;
}

const ChartTooltipMapper = {
  value: "Issues",
  previousAvg: "Last week’s average",
  currentAvg: "This week’s average",
};

const getTooltipTitle = (label: string) =>
  ChartTooltipMapper[label as keyof typeof ChartTooltipMapper];

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ data }) => {
  if (!data.payload) return null;
  return (
    <CustomTooltipContainer
      payload={data.payload}
      labelFormat={getTooltipTitle}
      valueFormat={formatNumber}
      tooltipTitle={data.label}
    />
  );
};
