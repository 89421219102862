import { useMemo } from "react";

import { isDefaultPolicyById } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/isDefaultPolicy";
import { useHasWritePermission } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasWritePermission";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useCanEditPolicy = (policyId: string | undefined) => {
  const hasWritePermissions = useHasWritePermission();
  const { reliabilityEditDefaultPolicy } = useOverridableFlags();
  // with the current API, we cannot know the priority of the policy;
  const policyIsDefault = isDefaultPolicyById(policyId);

  return useMemo(() => {
    if (!hasWritePermissions) {
      return false;
    }
    return !policyIsDefault || reliabilityEditDefaultPolicy;
  }, [hasWritePermissions, policyIsDefault, reliabilityEditDefaultPolicy]);
};
