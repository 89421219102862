import { useCallback } from "react";

import { removeTerminalInstance } from "../../../../PodExec/terminalInstance";
import { useCloseSocketSessionById } from "../../../../../shared/sockets/useCloseSocketById";

export const useHandleClosePodExecDrawer = (): ((id: string) => void) => {
  const closeSocket = useCloseSocketSessionById();

  const closeExecSession = useCallback(
    (id: string) => {
      closeSocket(id);
      removeTerminalInstance(id);
    },
    [closeSocket]
  );

  return closeExecSession;
};
