import pluralize from "pluralize";

import { TextContainer } from "../../../violationDrawerStyles";

export const getWhatDescription = (numOfContainers: number) => (
  <TextContainer>
    {`${numOfContainers} ${pluralize(
      "container",
      numOfContainers
    )} of this workload are missing memory limits`}
  </TextContainer>
);

export const getWhyDescription = () => (
  <TextContainer>
    Defining memory limits in Kubernetes prevents resource contention and
    ensures application and cluster stability by capping memory usage, thereby
    enabling predictable performance and effective resource management.
  </TextContainer>
);

export const howDescription = () => (
  <TextContainer>
    Add memory limits to your deployment’s containers
  </TextContainer>
);
