import { useMemo } from "react";

import { getInitialPaginationModel } from "../violationTableConstants";
import { useViolationsTableContext } from "../context/useViolationsTableContext";

import { initialGroupState } from "@/components/reliability/constants/reliabilityConstants";
import {
  Group,
  ViolationsGroupBy,
} from "@/components/reliability/ReliabilityTypes";

export const useInitialGroupState = (
  violationsGroupBy: ViolationsGroupBy
): Omit<Group, "isExpanded"> => {
  const { defaultPaginationModel } = useViolationsTableContext();

  return useMemo(() => {
    return {
      ...initialGroupState,
      paginationModel:
        defaultPaginationModel ?? getInitialPaginationModel(violationsGroupBy),
    };
  }, [defaultPaginationModel, violationsGroupBy]);
};
