import React, { useEffect, useMemo, useState } from "react";
import {
  GridComparatorFn,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import styled from "styled-components";

import {
  InsightsDrawerContent,
  InsightsDrawerTitle,
} from "../../../shared/styles";
import { ServicesTableTitle } from "../../../shared/components/InsightsDataTable/ServicesTableTitle";
import { InsightsDataTable } from "../../../shared/components/InsightsDataTable/InsightsDataTable";
import { getGenericServiceColumns } from "../../../shared/components/InsightsDataTable/insightDataTableUtils";
import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import {
  selectAppInsightsData,
  selectSetRightSizingSummaryState,
} from "../../../../store/overviewPageStoreSelectors";
import { initialFetchState } from "../../../../../../../../../../shared/hooks/metrics-api/mockServerRes";
import { CostSavingColumns } from "../../../shared/types";
import {
  title,
  explanationBlock,
  tableExplanation,
  potentialSavingColumnTooltip,
} from "../PotentialCostSavingsTexts";
import { PotentialSavingButton } from "../PotentialSavingButton";
import { TotalCostColumn } from "../TotalCostColumn";
import { getPotentialCostSavingRows } from "../utils";

import { AppsRecommendationModalContainer } from "./AppsRecommendationModalContainer";
import { InsightsRecommendationAccuracyPrompt } from "./InsightsRecommendationAccuracyPrompt";

const AlertContainer = styled.div`
  margin: 25px 0;
`;

const totalCostSort: GridComparatorFn = (v1, v2) => {
  return v1.totalCost - v2.totalCost;
};

const servicesColumnsWithButton =
  getGenericServiceColumns<CostSavingColumns>().concat(
    {
      field: "komodorServiceKind",
      headerName: "Kind",
      flex: 1,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <TotalCostColumn rowData={params} />
      ),
      sortComparator: totalCostSort,
    },
    {
      field: "potentialSavings",
      headerName: "Potential Savings",
      flex: 1,
      description: potentialSavingColumnTooltip,
      renderCell: (params: GridRenderCellParams) => (
        <PotentialSavingButton rowData={params.row} />
      ),
    }
  );

export const PotentialCostSavingDrawerContent: React.FC = () => {
  const {
    potentialCostSavings: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "potentialSavings",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const setRightSizingSummaryState = useOverviewPageStore(
    selectSetRightSizingSummaryState
  );

  const rows = useMemo(
    () => getPotentialCostSavingRows(data?.rows ?? []),
    [data?.rows]
  );

  useEffect(() => {
    setRightSizingSummaryState({
      ...initialFetchState,
      data,
    });
  }, [data, setRightSizingSummaryState]);

  return (
    <>
      <div>
        <InsightsDrawerTitle>{title}</InsightsDrawerTitle>
        <InsightsDrawerContent variant={"body2"}>
          {explanationBlock}
        </InsightsDrawerContent>

        <AlertContainer>
          <InsightsRecommendationAccuracyPrompt />
        </AlertContainer>

        <ServicesTableTitle />
        <InsightsDrawerContent variant={"body2"}>
          {tableExplanation}
        </InsightsDrawerContent>
        <InsightsDataTable
          columns={servicesColumnsWithButton}
          rows={rows}
          props={{
            sortModel,
            onSortModelChange,
          }}
        />
      </div>
      <AppsRecommendationModalContainer />
    </>
  );
};
