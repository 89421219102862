import { ListItemProps } from "./LogsListLine";

const MINIMAL_LINE_HEIGHT = 25;
const EVENT_LINE_HEIGHT = 100;
const TIMESTAMP_WIDTH = 200;
const CHAR_WIDTH = 8;

export const getEstimateLogHeight = (
  enableTextWrapping: boolean,
  log: ListItemProps["logs"][number],
  containerWidth: number
): number => {
  const baseLineHeight = log.isEvent ? EVENT_LINE_HEIGHT : MINIMAL_LINE_HEIGHT;
  if (!enableTextWrapping) {
    return baseLineHeight;
  }

  const numOfChars = log.parts.reduce(
    (acc, part) => acc + part.value.length,
    0
  );
  const lineLength = numOfChars * CHAR_WIDTH;
  const containerWidthWithoutTimestamp = containerWidth - TIMESTAMP_WIDTH;
  const numOfLines = Math.ceil(lineLength / containerWidthWithoutTimestamp) + 1;

  return baseLineHeight * numOfLines;
};

export const CONTAINER_ID = "list-container-inner";

export const getLogElementHeight = (idx: number): number | undefined => {
  const regularLogEl = document.querySelector(
    `#${CONTAINER_ID} > div:nth-child(${idx + 1}) > code`
  );
  const eventLogEl = document.querySelector(
    `#${CONTAINER_ID} > div:nth-child(${idx + 1}) > div`
  );
  const el = regularLogEl || eventLogEl;

  if (!el) {
    return;
  }

  return el.getBoundingClientRect().height;
};
