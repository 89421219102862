import React, { useMemo } from "react";
import styled from "styled-components";

import { sectionGap } from "../../../../../../../../constants/reliabilityConstants";
import { ReliabilityCollapsibleContainer } from "../../../shared/metrics/components/ReliabilityCollapsibleContainer/ReliabilityCollapsibleContainer";
import { useMetricsParams } from "../../../shared/metrics/hooks/useMetricsParams";
import {
  CheckType,
  ThrottledCPUSupportingDataContainersInner,
} from "../../../../../../../../../../generated/reliabilityApi";
import { getCollapsibleContainerProps } from "../../../../violationDrawerUtils";
import { reliabilityArialLabels } from "../../../../../../../../reliabilityArialLabels";
import { ViewContainerSpec } from "../../../shared/ViewContainerSpec/ViewContainerSpec";
import { useGetWorkloadThrottledContainers } from "../../../../../../../../../../shared/hooks/metrics-api/client/useGetWorkloadThrottledContainers";
import { useFetchContainersMetricsData } from "../../../shared/metrics/hooks/useFetchContainersMetricsData";
import { ViolationSummary } from "../../../shared/ViolationSummary/ViolationSummary";
import { useReportDrawerLoadingTime } from "../../../../hooks/useReportDrawerLoadingTime";

import { HeatMapGraphElements } from "./heatmapGraph/HeatMapGraphElements";
import { MetricsGraph } from "./MetricsGraph";
import { getSummaryItemsFromMetrics } from "./summaryUtils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const {
  violationDrawer: { graphsContainer: graphsContainerAriaLabel },
} = reliabilityArialLabels;

interface Props {
  komodorUid: string;
  supportingDataContainers: ThrottledCPUSupportingDataContainersInner[];
  checkType: CheckType;
}

export const Details: React.FC<Props> = ({
  komodorUid,
  supportingDataContainers,
  checkType,
}) => {
  const { params, isInvalidParams } = useMetricsParams(komodorUid);

  const { data: cpuThrottlingData, fetchStatus: cpuThrottlingDataFetchStatus } =
    useGetWorkloadThrottledContainers(params, !isInvalidParams);

  const cpuRes = useFetchContainersMetricsData(
    { ...params, kind: "cpu" },
    isInvalidParams
  );

  useReportDrawerLoadingTime(
    !cpuRes.loading && cpuThrottlingDataFetchStatus === "idle"
  );

  const content = useMemo(() => {
    return supportingDataContainers?.map((container, idx) => {
      const { container: containerName } = container;

      const correspondingContainerMetrics =
        cpuThrottlingData?.data?.containers?.find(
          (c) => c.containerName === containerName
        );

      const summaryItems = getSummaryItemsFromMetrics({
        cpuRes,
        containerData: container,
      });

      return (
        <ReliabilityCollapsibleContainer
          key={containerName}
          {...getCollapsibleContainerProps({
            name: containerName,
            index: idx,
            checkType,
          })}
          button={
            <ViewContainerSpec
              komodorUid={komodorUid}
              containerName={containerName}
            />
          }
          content={
            <Content>
              <ViolationSummary
                title={"CPU analysis"}
                ariaLabel={"CPU analysis"}
                items={summaryItems}
                loadingSkeletonsNum={3}
              />
              <HeatMapGraphElements container={correspondingContainerMetrics}>
                <MetricsGraph
                  res={cpuRes}
                  containerName={containerName}
                  komodorUid={komodorUid}
                />
              </HeatMapGraphElements>
            </Content>
          }
        />
      );
    });
  }, [
    supportingDataContainers,
    cpuThrottlingData?.data?.containers,
    cpuRes,
    komodorUid,
    checkType,
  ]);

  return <Container aria-label={graphsContainerAriaLabel}>{content}</Container>;
};
