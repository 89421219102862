import React from "react";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import styled from "styled-components";

import { formatCurrencyIfSmallerThanOne } from "../../../../../../../../../shared/utils/formatCurrency";

type SecurityFailedProps = {
  rowData: GridRenderCellParams;
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const TotalCostColumn: React.FC<SecurityFailedProps> = ({ rowData }) => {
  /** currently trend is disabled
  const [percentageValue, color] = useMemo(() => {
    const percentageValue = rowData.value.totalCostTrendPercentage;

    if (percentageValue > -1 && percentageValue < 1) {
      return [`\xB10`, muiColors.gray[600]];
    }
    return [
      Math.trunc(percentageValue),
      percentageValue < 0 ? muiColors.teal[700] : muiColors.pink[400],
    ];
  }, [rowData.value.totalCostTrendPercentage]);*/

  return (
    <Container>
      <Typography variant="body2">
        {formatCurrencyIfSmallerThanOne(rowData.value.totalCost)}
      </Typography>
      {/*<MuiTypography variant="body2" color={color}>
        {percentageValue}%
      </MuiTypography>*/}
    </Container>
  );
};
