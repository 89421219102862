import React from "react";

import { EmailSettingsContextProvider } from "@/components/Settings/email/context/EmailSettingsContext";
import { EmailSettingsContent } from "@/components/Settings/email/EmailSettingsContent";
import { SnackbarMessageContextProvider } from "@/components/Settings/email/context/SnackbarMessageContext";
import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";

export const EmailSettings: React.FC = () => {
  return (
    <UserSettingsWrapper title={"Email Settings"}>
      <EmailSettingsContextProvider>
        <SnackbarMessageContextProvider>
          <EmailSettingsContent />
        </SnackbarMessageContextProvider>
      </EmailSettingsContextProvider>
    </UserSettingsWrapper>
  );
};
