import React, { useEffect, useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { WorkloadMetricsTabContextProvider } from "../../context/WorkloadMetricsTabContext";
import { useWorkloadMetricsTabContext } from "../../context/useWorkloadMetricsTabContext";
import { GraphContainerWithStatus } from "../../../../../Metrics/GraphContainerWithStatus";
import { ResourceGraph } from "../../../../../Metrics/graphs/ResourceGraph";
import { NoLimitPerOneContainerLabel } from "../../../../../Metrics/NoLimitLabel";
import { WorkloadGraphProps } from "../../types";
import { useWorkloadContainerGraph } from "../../hooks/useWorkloadContainerGraph";

import { EnlargedContainerGraph } from "./EnlargedContainerGraph";

type ContainerWorkloadMemoryGraphProps = WorkloadGraphProps & {
  wantedContainer: MuiSelectionOption<string> | undefined;
};

export const WorkloadContainerGraph: React.FC<
  ContainerWorkloadMemoryGraphProps
> = ({ resource, loadingStateId, wantedContainer, type, metricGraphProps }) => {
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);
  const { setGraphLoadingState, initialLoadTime } =
    useWorkloadMetricsTabContext();
  const {
    graphProps,
    uid,
    error,
    loading,
    hasGraphData,
    selectedAggregationMetric,
    timeWindow,
    toEpochMs,
    hasLimit,
  } = useWorkloadContainerGraph({
    resource,
    type,
    wantedContainer,
    endTime: initialLoadTime,
  });

  useEffect(() => {
    setGraphLoadingState(loadingStateId, loading);
  }, [loading, loadingStateId, setGraphLoadingState]);

  const title = type === "cpu" ? "Cpu Usage" : "Memory Usage";
  const mergedProps = useMemo(() => {
    return { ...graphProps, ...metricGraphProps };
  }, [graphProps, metricGraphProps]);

  return (
    <>
      {isGraphEnlarged && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={timeWindow}
          givenAggregationMetric={selectedAggregationMetric}
        >
          <EnlargedContainerGraph
            initialWantedContainer={wantedContainer}
            resource={resource}
            endTime={toEpochMs}
            onClose={() => setIsGraphEnlarged(false)}
            type={type}
          />
        </WorkloadMetricsTabContextProvider>
      )}
      <GraphContainerWithStatus
        uid={uid}
        error={error}
        loading={loading}
        hasGraphData={hasGraphData}
        title={title}
        info={
          !loading && !error && !hasLimit ? (
            <NoLimitPerOneContainerLabel />
          ) : null
        }
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      >
        <ResourceGraph type={type} {...mergedProps} />
      </GraphContainerWithStatus>
    </>
  );
};
