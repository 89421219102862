import { useCallback } from "react";

import { useWebSocketContext } from "../../../shared/context/WebSocketsProvider";
import { generateSocketPayload } from "../../../shared/utils/generateSocketPayload";
import { SocketMessageType } from "../../../shared/sockets/types";
import { isSocketReadyToSend } from "../../../shared/sockets/isSocketReadyToSend";

interface Args {
  identifier: string;
}

type Res = (input: string) => void;

export const useHandleTerminalInputChanged = ({ identifier }: Args): Res => {
  const sockets = useWebSocketContext();

  const onInputChanged = useCallback(
    (input: string): void => {
      const activeSocket = sockets.getSocket(identifier);
      const { sessionId } = sockets.getSocketContext(identifier) || {};

      if (activeSocket && isSocketReadyToSend(activeSocket)) {
        const payloadToSend = generateSocketPayload({
          sessionId,
          messageType: SocketMessageType.Stdin,
          data: {
            input: input,
          },
        });

        activeSocket.send(JSON.stringify(payloadToSend));
      }
    },
    [identifier, sockets]
  );

  return onInputChanged;
};
