import React from "react";

import StyledMultiSelect from "../../monitorsView/common/StyledMultiSelect";

import { MultiSelectContainerInspection } from "./InspectionResourceListLabelsFilter";
import {
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "./InspectionResourceListSearch";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

type Props = {
  items: string[];
  filterName: string;
  filterTitle: string;
  selectedFields?: string[];
  setSelectedFields?: (fields: string[]) => void;
  isEnabled?: boolean;
};

const InspectionResourceListFilter: React.FC<Props> = ({
  items,
  filterName,
  filterTitle,
  selectedFields,
  setSelectedFields,
  isEnabled = true,
}) => {
  const [selectedFieldsInSearchParams, setSelectedFieldsInSearchParams] =
    useStringifiedStateInSearchParams<string[]>(filterName);

  const placeholder = "Select " + filterTitle.toLowerCase();

  return (
    <InspectionFilterContainer>
      <InspectionFilterDiv>{filterTitle}</InspectionFilterDiv>
      <MultiSelectContainerInspection>
        <StyledMultiSelect
          placeholder={placeholder}
          width={"100%"}
          options={items}
          values={selectedFields ?? selectedFieldsInSearchParams ?? []}
          onChange={(fields) =>
            setSelectedFields
              ? setSelectedFields(fields)
              : setSelectedFieldsInSearchParams(fields.length ? fields : null)
          }
          isEnabled={isEnabled}
          ariaLabel={placeholder}
        />
      </MultiSelectContainerInspection>
    </InspectionFilterContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InspectionResourceListFilter;
