// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useClustersRestrictionsData } from "./clusters";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { usePoliciesRestrictionsData } from "./policies";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useRolesRestrictionsData } from "./roles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useUsersRestrictionsData } from "./users";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useLockRestrictionsData } from "./lock";

export enum restrictionsNames {
  USERS = "users",
  CLUSTERS = "clusters",
  POLICIES = "policies",
  ROLES = "roles",
  LOCK = "lock",
}

export interface restrictionProps {
  modalTitle: string;
  modalContent: string;
  messageTitle?: string;
  messageContent?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const restrictionModalsContentMap = {
  [restrictionsNames.USERS]: useUsersRestrictionsData,
  [restrictionsNames.POLICIES]: usePoliciesRestrictionsData,
  [restrictionsNames.CLUSTERS]: useClustersRestrictionsData,
  [restrictionsNames.ROLES]: useRolesRestrictionsData,
  [restrictionsNames.LOCK]: useLockRestrictionsData,
};
