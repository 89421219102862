import React, { useCallback, useMemo, useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import Checkbox from "../common/controls/Checkbox";
import {
  gray11,
  greenForUIText,
  offWhite,
  pinkBrand,
  yellowForUI,
  yellowHighlightText,
} from "../../Colors";
import { dispatchEvent } from "../../shared/hooks/analytics";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { cleanResourceYaml } from "../Actions/modals/EditModal/utils";

import { Flexbox } from "./common";
import type { ServiceYamlFile } from "./common/types";

interface YamlComparerProps {
  yamls: (ServiceYamlFile | undefined)[];
}

const YamlComparer: React.FC<YamlComparerProps> = ({ yamls }) => {
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(true);
  const [showStatusDiff, setShowStatusDiff] = useState(false);

  const toggleShowStatusDiff = () => {
    setShowStatusDiff((prev) => !prev);
  };

  const yamlTransoformation = useCallback(
    (yaml: string): string => {
      if (!showStatusDiff) {
        yaml = cleanResourceYaml(yaml);
      }
      return yaml;
    },
    [showStatusDiff]
  );

  const toggleShowOnlyDifferences = () => {
    if (!showOnlyDifferences) {
      dispatchEvent(
        AnalyticEvents.ComparisonView.Comparison_view_show_diff_only_selected
      );
    }
    setShowOnlyDifferences((prevState) => !prevState);
  };

  const comparator = useMemo(
    () => (yamls.length > 0 ? yamls[0] : undefined),
    [yamls]
  );

  const comparand = useMemo(
    () => (yamls.length > 1 ? yamls[1] : undefined),
    [yamls]
  );

  const CheckboxesContainer = styled.div`
    margin: 1.5rem 0;
    display: flex;
    gap: 1.5rem;
  `;

  return (
    <>
      <CheckboxesContainer>
        <Checkbox
          checked={showOnlyDifferences}
          id="differences-only"
          fieldName="Show only differences"
          handleCheckboxChange={toggleShowOnlyDifferences}
        />

        <Checkbox
          checked={showStatusDiff}
          id="status-diff"
          fieldName="Show status diff"
          handleCheckboxChange={toggleShowStatusDiff}
        />
      </CheckboxesContainer>

      <ReactDiffViewer
        oldValue={yamlTransoformation(comparator?.content ?? "")}
        newValue={yamlTransoformation(comparand?.content ?? "")}
        splitView
        disableWordDiff
        showDiffOnly={showOnlyDifferences}
        leftTitle={
          <YamlHeader
            serviceName={comparator?.name ? comparator.name + ".yaml" : ""}
            serviceHealthy={comparator?.serviceHealthy}
          />
        }
        rightTitle={
          <YamlHeader
            serviceName={comparand?.name ? comparand.name + ".yaml" : ""}
            serviceHealthy={comparand?.serviceHealthy}
          />
        }
        styles={{
          variables: {
            light: {
              gutterBackgroundDark: offWhite,
              addedGutterBackground: yellowHighlightText,
              addedBackground: yellowForUI,
              removedGutterBackground: yellowHighlightText,
              removedBackground: yellowForUI,
            },
          },
          diffContainer: {
            border: `1px solid ${gray11}`,
            borderRadius: "0.25rem",
            borderCollapse: "separate",
            borderSpacing: 0,
            "& tr:first-child>td:first-child": {
              borderTopLeftRadius: "0.25rem",
            },
            "& tr:first-child>td:last-child": {
              borderTopRightRadius: "0.25rem",
            },
            "& tr:last-child>td:first-child": {
              borderBottomLeftRadius: "0.25rem",
            },
            "& tr:last-child>td:last-child": {
              borderBottomRightRadius: "0.25rem",
            },
          },
          contentText: {
            fontFamily: "Roboto Mono",
            fontSize: "12px",
            overflowWrap: "anywhere",
          },
          gutter: {
            backgroundColor: offWhite,
            cursor: "default !important",
          },
          emptyLine: {
            backgroundColor: offWhite,
          },
          marker: {
            "& pre": {
              visibility: "hidden",
            },
          },
        }}
      />
    </>
  );
};

const YamlHeader: React.FC<{
  serviceName: string | undefined;
  serviceHealthy: boolean | undefined;
}> = ({ serviceName, serviceHealthy }) => (
  <Flexbox justifyContent="space-between" style={{ width: "100%" }}>
    <Typography variant="text" mono>
      {serviceName}
    </Typography>
    <Typography
      variant="title"
      size="medium"
      color={serviceHealthy ? greenForUIText : pinkBrand}
    >
      {serviceHealthy === undefined
        ? null
        : serviceHealthy
        ? "HEALTHY"
        : "UNHEALTHY"}
    </Typography>
  </Flexbox>
);

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default YamlComparer;
