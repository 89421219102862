import React from "react";
import { Node as NodeType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Node as NodeIcon } from "@komodorio/design-system/icons";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeDescribeTab from "../tabs/DescribeTab/node/NodeDescribeTab";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import {
  ResourceStatusEnum,
  StatusTag as HeaderStatusTag,
} from "../headers/common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NodePodsTab } from "../tabs/PodsTab/NodePodsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NodeMetricsTab } from "../tabs/NodeMetricsTab/NodeMetricsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
import { StatusTag } from "../tabs/DescribeTab/common/StatusTag";

import Resource, { ResourceTabName } from ".";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class Node implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Metrics },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [
    AvailableActions.CordonUncordon,
    AvailableActions.Drain,
    AvailableActions.Describe,
  ];
  readonly ready;
  readonly unschedulable;
  readonly fullObj;
  readonly defaultTab;

  constructor(cluster: string, node: NodeType, agentId: string) {
    this.agentId = agentId;
    this.id = node.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = node.metadata?.namespace ?? "";
    this.name = node.metadata?.name ?? "";
    this.kind = node.kind ?? "";
    this.labels = node.metadata?.labels ?? {};
    this.annotations = node.metadata?.annotations ?? {};
    this.icon = NodeIcon;
    const nodeStatus = node.status?.conditions?.find(
      (condition) => condition.type === "Ready"
    )?.status;
    this.ready =
      nodeStatus === "True" ? true : nodeStatus === "False" ? false : undefined;
    this.unschedulable = node.spec?.unschedulable;
    this.fullObj = node;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <NodeDescribeTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <NodePodsTab resource={this} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <NodeMetricsTab node={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={3}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        status={
          this.ready !== undefined ? (
            <HeaderStatusTag
              status={
                this.ready
                  ? ResourceStatusEnum.Healthy
                  : ResourceStatusEnum.Failed
              }
            >
              {this.ready ? "READY" : "NOT READY"}
            </HeaderStatusTag>
          ) : undefined
        }
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
        ]}
        leftActions={leftActions}
      />
    );
  }

  renderStatus(): JSX.Element {
    return <StatusTag value={this.ready ? "READY" : "NOT READY"} />;
  }
}
