export enum WorkflowsRoutes {
  airflow = "airflow",
  argo = "argo",
  custom = "custom",
}

export enum WorkflowEngine {
  Airflow = "airflow",
  Argo = "argo",
}
export type WorkflowEngineType = WorkflowEngine | string;
