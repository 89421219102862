import {
  InsightResult,
  InsightsApiGetInsightsResultsByServiceRequest,
  InsightsApiGetInsightsResultsSingleRequest,
} from "../../../generated/insights";

export const INSIGHTS_RESULTS_BY_SERVICE_ENDPOINT = "/service-results";
export const INSIGHTS_RESULTS_BY_ID_ENDPOINT = "/results/:id";

export type EndpointsResponseMap = {
  [INSIGHTS_RESULTS_BY_SERVICE_ENDPOINT]: InsightResult[];
  [INSIGHTS_RESULTS_BY_ID_ENDPOINT]: InsightResult;
};

export type EndpointsRequestMap = {
  [INSIGHTS_RESULTS_BY_SERVICE_ENDPOINT]: InsightsApiGetInsightsResultsByServiceRequest;
  [INSIGHTS_RESULTS_BY_ID_ENDPOINT]: InsightsApiGetInsightsResultsSingleRequest;
};
