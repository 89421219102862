export const certificatesPageAriaLabels = {
  table: "Certificates page table",
  drawer: {
    mainContainer: "Certificate drawer",
    sections: {
      relatedResources: "Related resources section",
      status: "Status section",
      spec: "Spec section",
      annotations: "Annotations section",
      labels: "Labels section",
    },
    status: {
      info: "More info",
      statusConditions: "Status conditions",
      statusConditionsPopover: "Status conditions popover",
    },
    resourceLink: "resource link",
  },
};
