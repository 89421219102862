import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type ClusterOverviewState = {
  fetchingState: {
    isFetchingCluster: boolean | undefined;
    isFetchingWorkspace: boolean | undefined;
    isFetchingUnhealthyServicesCount: boolean | undefined;
    isFetchingOpenIssuesCountGraph: boolean | undefined;
    isFetchingTopUnhealthyServices: boolean | undefined;
    isFetchingReliabilityViolations: boolean | undefined;
    isFetchingReliabilityViolationsCount: boolean | undefined;
    isFetchingCostOptimization: boolean | undefined;
    isFetchingPermissionsForInventory: boolean | undefined;
    isFetchingClusterUsageMetrics: boolean | undefined;
    isFetchingClustersList: boolean | undefined;
  };
};

export type FetchingStateProps = ClusterOverviewState["fetchingState"];

export type ClusterOverviewActions = {
  setFetchingState: (fetchingState: {
    key: keyof FetchingStateProps;
    value: boolean;
  }) => void;
  resetFetchingState: () => void;
};

export type ClusterOverviewStore = ClusterOverviewState &
  ClusterOverviewActions;

export const initialState: ClusterOverviewState = {
  fetchingState: {
    isFetchingCluster: undefined,
    isFetchingWorkspace: undefined,
    isFetchingUnhealthyServicesCount: undefined,
    isFetchingOpenIssuesCountGraph: undefined,
    isFetchingTopUnhealthyServices: undefined,
    isFetchingReliabilityViolations: undefined,
    isFetchingReliabilityViolationsCount: undefined,
    isFetchingCostOptimization: undefined,
    isFetchingPermissionsForInventory: undefined,
    isFetchingClusterUsageMetrics: undefined,
    isFetchingClustersList: undefined,
  },
};

export const useClusterOverviewStore = create(
  immer<ClusterOverviewStore>((set, get) => ({
    ...initialState,
    setFetchingState: (fetchingState: {
      key: keyof FetchingStateProps;
      value: boolean;
    }) => {
      set((state) => ({
        fetchingState: {
          ...state.fetchingState,
          [fetchingState.key]: fetchingState.value,
        },
      }));
    },
    resetFetchingState: () => {
      set(() => ({
        fetchingState: {
          ...initialState.fetchingState,
        },
      }));
    },
  }))
);

export const resetClusterOverviewStore = (): void => {
  useClusterOverviewStore.setState({
    ...initialState,
  });
};
