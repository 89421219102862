import React from "react";
import {
  Input,
  TextArea,
  Typography,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import art from "../../../../../assets/app_view_art.svg";
import { useCreationPageContext } from "../context/CreationPageContext";
import { useIsEditMode } from "../hooks/creationPageHooks";
import { useVerifyAppName } from "../hooks/useVerifyCreationForm";

const Container = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  max-width: 800px;
`;

const FormContainer = styled(ColumnFlexContainer)`
  gap: 1rem;
  margin-top: 0.5rem;
  max-width: 70%;
`;

const ImageContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 150px;
  > img {
    max-width: 100%;
  }
`;
export const CreationPageHeader: React.FC = () => {
  const {
    appName,
    setAppName,
    description,
    errorState: { appNameError },
    setErrorState,
    setDescription,
  } = useCreationPageContext();
  const isEditMode = useIsEditMode();
  const verifyAppName = useVerifyAppName();
  const title = isEditMode ? "Edit Workspace" : "Create a new Workspace";

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const appName = event.target.value;
    if (verifyAppName(appName)) {
      setErrorState({ appNameError: undefined });
    }
    setAppName(appName);
  };

  const handleDescriptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (event) => {
    setDescription(event.target.value);
  };

  return (
    <Container>
      <ColumnFlexContainer>
        <Typography
          variant="headline"
          size="large"
          style={{ marginBottom: "1rem" }}
        >
          {title}
        </Typography>

        <Typography size="large" style={{ width: "35.25rem" }}>
          Komodor Workspace allow you to easily create custom contexts of your{" "}
          <br />
          Kubernetes resources for what’s important to you.
        </Typography>

        <FormContainer>
          <Input
            label="Workspace name"
            size="medium"
            value={appName}
            onChange={handleNameChange}
            errorMessage={appNameError}
            data-testid="app-name-input"
          />

          <TextArea
            label="Description (optional)"
            size="medium"
            placeholder="What this workspace represents"
            value={description}
            onChange={handleDescriptionChange}
            data-testid="description-input"
          />
        </FormContainer>
      </ColumnFlexContainer>
      <ImageContainer>
        <img src={art} alt="Create Workspace" />
      </ImageContainer>
    </Container>
  );
};
