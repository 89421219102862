import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { muiColors } from "@komodorio/design-system";

import { SummaryItem, useSummaryItems } from "./hooks/useSummaryItems";

import { SinglePointOfFailureSupportingData } from "@/generated/reliabilityApi";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

interface Props {
  supportingData: SinglePointOfFailureSupportingData | undefined;
}

const Item: React.FC<SummaryItem> = ({ title, value, ariaLabel }) => {
  return (
    <ItemContainer aria-label={ariaLabel}>
      <Typography variant="body1">{value}</Typography>
      <Typography variant="body2" color={muiColors.gray[500]}>
        {title}
      </Typography>
    </ItemContainer>
  );
};

export const WhatHappenedSummary: React.FC<Props> = ({ supportingData }) => {
  const { unavailableMinutes, correlatedIssues } = supportingData || {};

  const summaryItems = useSummaryItems(correlatedIssues, unavailableMinutes);

  const content = useMemo(() => {
    return summaryItems.map((item, index) => {
      return (
        <Fragment key={item.title}>
          <Item {...item} />
          {index < summaryItems.length - 1 && (
            <StyledDivider orientation="vertical" variant="middle" flexItem />
          )}
        </Fragment>
      );
    });
  }, [summaryItems]);

  if (!supportingData || !summaryItems.length) return null;

  return <Container>{content}</Container>;
};
