import React, { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import Autocomplete from "../../common/controls/Autocomplete";
import { Text } from "../../common/typography";
import { gray10 } from "../../../Colors";
import {
  InputContainer,
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "../../Inspection/filters/InspectionResourceListSearch";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  > span {
    color: ${gray10};
  }
`;
const PageSizes = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
];
const width = "3.5rem";
const paddingBottom = "5px";
const fontSize = "14px";

export const PageSize = ({
  onChange,
}: {
  onChange(size: number): void;
}): ReactElement => {
  const { control, watch } = useForm();
  const pageSize = watch("pageSize", 10);

  return (
    <Container>
      <Text style={{ fontSize }}>Show</Text>
      <InspectionFilterContainer style={{ width }}>
        <InspectionFilterDiv />
        <InputContainer style={{ width, paddingBottom }} inputHeight={"0.3rem"}>
          <Controller
            name="pageSize"
            control={control}
            defaultValue={pageSize}
            render={(f) => (
              <Autocomplete
                fieldname="pageSize"
                defaultSelected={PageSizes[0]}
                suggestions={PageSizes}
                onSelectedChange={onChange}
              />
            )}
          />
        </InputContainer>
      </InspectionFilterContainer>
      <Text style={{ fontSize }}>per page</Text>
    </Container>
  );
};
