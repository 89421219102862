import {
  CheckType,
  ViolationSupportingData,
} from "../../../../../../../../../../generated/reliabilityApi";
import { checkTypeToLabel } from "../../../../../../../../constants/reliabilityConstants";

export const getRequestsLimitsRatioTitle = (
  supportingData: ViolationSupportingData | undefined
): string => {
  const topViolationContainer =
    supportingData?.requestsLimitsRatio?.containersData?.[0];
  if (topViolationContainer) {
    return `${topViolationContainer.resourceType} limits are ${topViolationContainer.ratio} times higher than requests`;
  } else {
    return checkTypeToLabel[CheckType.RequestsLimitsRatio];
  }
};
