import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useMemo } from "react";
import styled from "styled-components";

import { DateTimeSelector } from "../common/DateTimeSelector";
import { AriaLabels } from "../../shared/config/ariaLabels";
import { Timeframe, TimeWindow } from "../../shared/types/TimeWindow";

import { MetricsAggregationType, MetricsTimeWindow } from "./types";

const AggregationSelectorStyle = {
  fontSize: "12px",
  height: "32px",
};

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export interface MetricsControlsProps {
  aggregationValue: string;
  handleAggregationChange: (value: MetricsAggregationType) => void;
  isThereDataBefore24hours: boolean;
  timeWindow: MetricsTimeWindow;
  metricsTimeframe: Timeframe;
  onMetricsTimeframeChange: (timeframe: Timeframe | undefined) => void;
  defaultOptionsOverride?: Timeframe[];
  labels?: Record<Timeframe, string>;
}

export const MetricsControls: React.FC<MetricsControlsProps> = ({
  aggregationValue,
  handleAggregationChange,
  timeWindow,
  metricsTimeframe,
  onMetricsTimeframeChange,
  isThereDataBefore24hours,
  defaultOptionsOverride,
  labels,
}) => {
  const defaultOptions: Timeframe[] = defaultOptionsOverride ?? [
    Timeframe.LastHour,
    Timeframe.Last4Hours,
    Timeframe.Last24Hours,
    ...(isThereDataBefore24hours ? [Timeframe.Last7Days] : []),
  ];

  const timeWindowProps = useMemo(() => {
    return {
      timeWindow: {
        start: timeWindow.start,
        end: timeWindow.end,
        timeframe: metricsTimeframe,
      },
      setTimeWindow: (tw: Partial<TimeWindow>) => {
        onMetricsTimeframeChange(tw?.timeframe);
      },
    };
  }, [timeWindow, metricsTimeframe, onMetricsTimeframeChange]);

  return (
    <HeaderButtons>
      {aggregationValue !== "" && (
        <MuiSelect
          variant="outlined"
          size={"small"}
          value={aggregationValue}
          onChange={(event) =>
            handleAggregationChange(
              event.target.value as MetricsAggregationType
            )
          }
          sx={AggregationSelectorStyle}
          aria-label={AriaLabels.MetricsAvailability.AggregationToggle}
        >
          <MenuItem value={MetricsAggregationType.Avg}>Avg.</MenuItem>
          <MenuItem value={MetricsAggregationType.P90}>P90</MenuItem>
          <MenuItem value={MetricsAggregationType.P95}>P95</MenuItem>
          <MenuItem value={MetricsAggregationType.P99}>P99</MenuItem>
          <MenuItem value={MetricsAggregationType.Max}>Max</MenuItem>
        </MuiSelect>
      )}
      <DateTimeSelector
        timeWindow={timeWindowProps.timeWindow}
        setTimeWindow={timeWindowProps.setTimeWindow}
        options={defaultOptions}
        labels={labels}
        forceRightAlign={false}
        showLabel
        disableCustomSelector
      />
    </HeaderButtons>
  );
};
