import React from "react";
import styled from "styled-components";

import { UnhealthyPodsSnapshotOutput } from "../UnhealthyPods/types";

import LivenessProbIcon from "./icons/liveness-probe-illustration.svg";
import ReadinessProbIcon from "./icons/readiness-probe-illustration.svg";
import { IntroductionSnapshotOutput } from "./types";

export const StyledImg = styled.img`
  border-radius: 2.6px;
  padding: 0.5rem;
  width: 55rem;
`;
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const transformSecondsToText = (seconds: number): string => {
  if (seconds > 120) {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;

    if (remainingSeconds === 0) {
      return `${minutes} minutes`;
    } else if (minutes === 1 && remainingSeconds === 1) {
      return `${minutes} minute and ${remainingSeconds} second`;
    } else if (minutes === 1) {
      return `${minutes} minute and ${remainingSeconds} seconds`;
    } else if (remainingSeconds === 1) {
      return `${minutes} minutes and ${remainingSeconds} second`;
    } else {
      return `${minutes} minutes and ${remainingSeconds} seconds`;
    }
  } else {
    if (seconds === 1) {
      return `${seconds} second`;
    } else {
      return `${seconds} seconds`;
    }
  }
};
export const OOMKilledReason = "OOMKilled";
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getExplanationCategory = (
  explanation: string,
  demoOomKilled?: boolean
): string => {
  if (
    (demoOomKilled &&
      explanation === "ContainerStatusUnknown - Exit code: 137") ||
    explanation === "NonZeroExitCode - Exit code: 137"
  ) {
    return OOMKilledReason;
  }

  switch (explanation) {
    case "OOMKilled - Exit code: 137":
    case "OOMKilled - Exit code: 0":
    case "OOMKilled - Exit code: 1":
    case "OOMKilled - Exit code: 2":
    case "OOMKilled":
    case "OOMKilled - Exit code: 143":
    case "OOMKilled - Exit code: 129":
    case "OOMKilled - Exit code: 247":
    case "OOMKilled - Exit code: 255":
    case "OOMKilled - Exit code: 4":
    case "OOMKilled - Exit code: 127":
      return OOMKilledReason;
    default:
      return explanation;
  }
};

export const ReadinessProbReason = "Readiness Probe Failed";
export const LivenessProbReason = "Liveness Probe Failed";
export const ReadinessProbMessagePrefix = "Readiness probe failed";
export const LivenessProbMessagePrefix = "Liveness probe failed";
export const UnhealthyReason = "Unhealthy - failed probes";
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const UnhealthyProbeReasons = [ReadinessProbReason, LivenessProbReason];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const updateUnhealthyReasonSnapshot = (
  snapshot: IntroductionSnapshotOutput | undefined | null,
  UnhealthyPodsSnapshot: UnhealthyPodsSnapshotOutput | undefined | null
): IntroductionSnapshotOutput | undefined => {
  if (
    !snapshot ||
    !snapshot.reasons?.some((reason: string) => reason === UnhealthyReason)
  ) {
    return snapshot ?? undefined;
  }

  const unhealthyReasonMessages = UnhealthyPodsSnapshot?.pods?.flatMap(
    (pod) => {
      if (pod.calculatedReason !== UnhealthyReason) {
        return [];
      }
      return (
        pod?.podEvents
          ?.filter(
            (event) =>
              event.reason === "Unhealthy" &&
              (event.message.startsWith(ReadinessProbMessagePrefix) ||
                event.message.startsWith(LivenessProbMessagePrefix))
          )
          .map((event) => {
            return {
              message: event.message,
              timestamp: new Date(event.lastTimestamp),
            };
          }) ?? []
      );
    }
  );

  if (
    unhealthyReasonMessages === undefined ||
    unhealthyReasonMessages.length === 0
  ) {
    return snapshot;
  }
  const snapshotCopy = { ...snapshot };

  const filteredMessages = filterMessagesByTimeFrame(unhealthyReasonMessages);

  snapshotCopy.reasons =
    snapshotCopy.reasons?.flatMap((reason: string) =>
      reason === UnhealthyReason
        ? getUnhealthyReasons(reason, filteredMessages)
        : [reason]
    ) ?? null;

  snapshotCopy.errorMessages = filteredMessages;

  return snapshotCopy;
};

const getUnhealthyReasons = (reason: string, messages: string[]): string[] => {
  const reasons: string[] = [];

  messages.forEach((message) => {
    if (message.startsWith(ReadinessProbMessagePrefix)) {
      reasons.push(ReadinessProbReason);
    } else if (message.startsWith(LivenessProbMessagePrefix)) {
      reasons.push(LivenessProbReason);
    }
  });

  return reasons.length > 0 ? reasons : [reason];
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getExplanationImage = (reason: string): JSX.Element => {
  switch (reason) {
    case ReadinessProbReason:
      return <StyledImg src={ReadinessProbIcon} />;
    case LivenessProbReason:
      return <StyledImg src={LivenessProbIcon} />;
    default:
      return <></>;
  }
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export function filterMessagesByTimeFrame(
  messages: { message: string; timestamp: Date }[],
  timeFrameInMinutes = 5
) {
  const mostRecentTimestamp = messages
    .map(function (e) {
      return e.timestamp;
    })
    .sort()
    .reverse()[0];

  const tenMinutesBefore = new Date(mostRecentTimestamp);
  tenMinutesBefore.setMinutes(
    mostRecentTimestamp.getMinutes() - timeFrameInMinutes
  );
  const filteredMessages = messages
    .filter(
      (message) =>
        message.timestamp >= tenMinutesBefore &&
        message.timestamp <= mostRecentTimestamp
    )
    .map((message) => message.message);

  filteredMessages?.sort((_, message) =>
    message.startsWith(LivenessProbMessagePrefix) ? 1 : -1
  );
  return filteredMessages;
}
