import React from "react";
import styled from "styled-components";
import { Drawer } from "@komodorio/design-system/deprecated";

import {
  ContentPadding,
  Divider,
  NormalText,
  StyledTitle,
} from "../../common/styles";
import { gray10 } from "../../../../Colors";
import CloseButton from "../../common/CloseButton";

import { CheckIcon } from "./CheckCard";

import WorkflowCheck from ".";

import { StateInSearchParamsType } from "@/shared/hooks/state/useStateInSearchParams";

const Header = styled.div`
  display: grid;
  grid-template-columns: 3rem auto 1rem;
  align-items: center;
  padding: 1.3rem 1.3rem 1.3rem 2rem;
`;

export const BoldGrayText = styled(NormalText)`
  font-weight: bold;
  color: ${gray10};
`;

export const BoldGrayTextWithPadding = styled(NormalText)`
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  color: ${gray10};
`;

const CheckDetailsDrawer: React.FC<{
  check: WorkflowCheck | undefined;
  setSelectedCheckType: StateInSearchParamsType[1];
}> = ({ check, setSelectedCheckType }) => {
  return (
    <Drawer
      open={!!check}
      width="40rem"
      direction="left"
      offset="25rem"
      openInScope
      zIndex={5}
    >
      {check && (
        <>
          <Header>
            <CheckIcon icon={check.icon} />
            <StyledTitle>{check.shortOutput}</StyledTitle>
            <CloseButton onClick={() => setSelectedCheckType(null)} />
          </Header>
          <Divider />
          <ContentPadding>
            {check.renderCheckDetails(setSelectedCheckType)}
            {check.action && (
              <>
                <br />
                <BoldGrayText>What did we check?</BoldGrayText>
                <NormalText>{check.action}</NormalText>
              </>
            )}
            {check.description && (
              <>
                <br />
                <BoldGrayText>Why did we check this?</BoldGrayText>
                <NormalText>{check.description}</NormalText>
              </>
            )}
          </ContentPadding>
        </>
      )}
    </Drawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CheckDetailsDrawer;
