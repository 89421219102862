import React, { useEffect, useState } from "react";
import { muiColors } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ArrowLeft24 } from "@komodorio/design-system/icons";
import { IconButton } from "@komodorio/design-system/deprecated";
import { AccountPlan } from "komodor-types";
import styled from "styled-components";

import useUserMetadata from "../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import useUpdateAccountPlan from "../../useUpdateAccountPlan";
import { useBulkUpdateUser } from "../../../../shared/hooks/auth-service/client/users/useBulkUpdateUser";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { TrialEndModalsType } from "./TrialEndModals";

interface DowngradeToFreeModalProps {
  setCurrentModal: React.Dispatch<React.SetStateAction<TrialEndModalsType>>;
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  margin-inline: 1rem;
  left: 0;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d8dbe3;
  padding: 1rem;
  background-color: ${muiColors.gray[25]};
  margin-top: 1rem;
`;

const List = styled.ul`
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  line-height: 150%;
`;

const Step = styled.li`
  font-size: 0.875rem;
`;

export const DowngradeToFreeModal: React.FC<DowngradeToFreeModalProps> = ({
  setCurrentModal,
}) => {
  const updateAccountPlan = useUpdateAccountPlan();
  const [isOpen, setIsOpen] = useState(true);
  const { isKomodorAdmin, accountId, userId } = useUserMetadata();
  const { mutateAsync: deleteAllUsersButCurrent } = useBulkUpdateUser();

  useEffect(() => {
    dispatchEvent(AnalyticEvents.UserManagement.TrialEndedModalOpened);
  }, []);

  const downgradeToFreeAndCloseModal = async () => {
    try {
      await deleteAllUsersButCurrent({
        excludedUserIds: [userId],
        deletedAt: new Date().toISOString(),
      });
    } catch (e) {
      return;
    }
    updateAccountPlan(accountId, AccountPlan.free);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={
        isKomodorAdmin
          ? () => {
              setIsOpen(false);
            }
          : undefined
      }
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledIconButton
          onClick={() => setCurrentModal(TrialEndModalsType.TrialExpired)}
          noBorder
          icon={ArrowLeft24}
        />
        <Typography variant="h2" style={{ marginLeft: "2rem" }}>
          Downgrade to free
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">Before you downgrade!</Typography>
        <Typography variant="body2" style={{ paddingTop: "1rem" }}>
          Komodor's freemium version is limited to a single user and lacks
          access to premium features and capabilities.
        </Typography>
        <Panel>
          <Typography
            color="#31374F"
            style={{ fontWeight: "700", fontSize: "14px" }}
          >
            When you downgrade:
          </Typography>
          <List>
            <Step>
              Event collection will be capped at <strong>10,000</strong>{" "}
              Kubernetes events per month.
            </Step>
            <Step>
              All users (apart from yours) will be <strong>deleted</strong> from
              this account.
            </Step>
            <Step>
              You will miss out on premium features like cost optimization and{" "}
              <br />
              access control.
            </Step>
            <Step>Connecting 3rd party tools to Komodor will be disabled.</Step>
          </List>
        </Panel>
      </DialogContent>
      <DialogActions style={{ columnGap: "0.5rem" }}>
        <Button
          onClick={downgradeToFreeAndCloseModal}
          color="primary"
          variant="text"
          style={{ fontSize: "14px" }}
        >
          Continue to downgrade
        </Button>
        <Button
          onClick={() => {
            dispatchEvent(
              AnalyticEvents.UserManagement.TrialEndedModalContactUsButtonClick
            );
          }}
          color="primary"
          variant="contained"
          style={{ fontSize: "14px" }}
          href="https://komodor.chilipiper.com/book/me/timi-petrov"
        >
          Contact Us
        </Button>
      </DialogActions>
    </Dialog>
  );
};
