import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import styled from "styled-components";

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 24px auto;
  max-width: 1440px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

export const SectionAccordion = styled(Accordion)`
  && {
    border-radius: 4px;
    position: unset;
    &.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionSummary-root.Mui-disabled {
      opacity: 1;
      background-color: white;
      border-radius: 4px;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionDetails-root {
      padding-top: 0;
    }
  }
`;

export const ContentBox = ({
  title,
  subtitle,
  children,
  padding = "8px",
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  padding?: string;
}) => (
  <Box
    sx={{
      border: `1px solid ${muiColors.gray[200]}`,
      borderRadius: "4px",
    }}
  >
    <Box
      sx={{ display: "flex", alignItems: "center", columnGap: "8px", padding }}
    >
      <Typography variant="h5" color="text.secondary">
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body3" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </Box>
    <Divider />
    {children}
  </Box>
);
