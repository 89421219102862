import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";
import {
  DeploysApiApiV1DeploysSearchGetRequest,
  DeploysResponse,
  apiV1DeploysSearchGetUrl,
} from "../../../../../generated/monitorsApi";

const DEPLOYS_SEARCH_PATH = "/deploys/search";

const fetchDeploys = async (
  apiClient: AxiosInstance,
  params: DeploysApiApiV1DeploysSearchGetRequest
): Promise<DeploysResponse> => {
  const { data } = await apiClient.get(
    apiV1DeploysSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetDeploys = (
  params: DeploysApiApiV1DeploysSearchGetRequest,
  enabled?: boolean
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [DEPLOYS_SEARCH_PATH, params],
    () => fetchDeploys(apiClient, params),
    {
      enabled,
    }
  );
};
