import { Record, Unknown } from "runtypes";

import { Installation } from "@/components/integrations/management/installed/useInstallationSubscription";
import { isSandbox } from "@/shared/utils/sandbox";

export const stringOrNull = (v: unknown) => (typeof v === "string" ? v : null);

export const HIDDEN_CHAR = String.fromCharCode(9679);

export const hiddenInstallationId = HIDDEN_CHAR.repeat(4);

const WebhookUrlConfig = Record({ webhookPath: Unknown });

export const webhookGetter = (installation: Installation) => {
  if (!WebhookUrlConfig.guard(installation.configuration)) {
    return null;
  }

  return stringOrNull(installation.configuration.webhookPath) ?? "N/A";
};

export const getWebhookRecord = (installation: Installation) => {
  const url = webhookGetter(installation);
  return url ? { "webhook URL": url } : null;
};

const ddConfig = Record({ ddApiKey: Unknown, ddAppKey: Unknown });

export const datadogConfigGetter = (installation: Installation) => {
  if (!ddConfig.guard(installation.configuration)) {
    return null;
  }

  const apiKey = isSandbox()
    ? hiddenInstallationId
    : (installation.configuration.ddApiKey as string);
  const appKey = isSandbox()
    ? hiddenInstallationId
    : (installation.configuration.ddAppKey as string);
  const maskedApiKey = "*".repeat(8) + apiKey.slice(-4);
  const maskedAppKey = "*".repeat(8) + appKey.slice(-4);

  return {
    apiKey: maskedApiKey,
    appKey: maskedAppKey,
  };
};
