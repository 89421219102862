import React, { ComponentProps, useState } from "react";
import { theme } from "@komodorio/design-system";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import UpgradeRequired from "../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";
import EmptyStateSVG from "../../../../shared/assets/komo.svg?react";
import { UpgradeRequiredModalStep } from "../../../common/UpdateAgentModal/types";

type TypographyProps = ComponentProps<typeof Typography>;

const ColoredMediumTypography: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      color={theme.foreground.fgSubtle}
      size={"medium"}
      style={{ display: "inline" }}
      {...props}
    />
  );
};

const OutdatedAgentMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StyledTypography = styled(Typography)`
  margin: 10px 0;
`;
const TypographyWithVerticalMargin = styled(ColoredMediumTypography)`
  margin: 8px 0;
`;
const TypographyWithHorizontalMargin = styled(ColoredMediumTypography)`
  margin: 0 4px;
`;
const TextContainer = styled.div`
  margin: 1rem 0;
  & > div {
    text-align: center;
  }
`;

/** TODO: this agent upgrade message should be identical in style to all others and made reusable */
export const CRDsErrorOutdatedAgent: React.FC<{
  unsupportedResource: string;
}> = ({ unsupportedResource }) => {
  const [showAgentRequireModal, setShowAgentRequireModal] = useState(false);

  return (
    <OutdatedAgentMessageContainer>
      <EmptyStateSVG />
      <TextContainer>
        <StyledTypography
          variant="headline"
          size="small"
          bold
          color={theme.foreground.fgSubtle}
        >
          Outdated Agent Version
        </StyledTypography>
        <TypographyWithVerticalMargin>
          The Komodor agent on this cluster does not support
          <TypographyWithHorizontalMargin bold>
            {unsupportedResource}
          </TypographyWithHorizontalMargin>
          inspection
        </TypographyWithVerticalMargin>
      </TextContainer>
      <Button
        variant="secondary"
        size="small"
        onClick={() => setShowAgentRequireModal(true)}
      >
        Update agent
      </Button>
      {showAgentRequireModal && (
        <UpgradeRequired
          handleClose={() => setShowAgentRequireModal(false)}
          updateMessage={null}
          upgradeCommand={
            UpgradeCommands.UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES
          }
          desiredFeature="ATM live results data"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </OutdatedAgentMessageContainer>
  );
};
