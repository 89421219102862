import { subHours } from "date-fns";
import { useMemo } from "react";

import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import { WORKLOADS_PODS_NATIVE_EVENT_SEARCH } from "../../../../../shared/hooks/resources-api/requestResponseMaps";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { NativePodFragment } from "../../../../common/EventGroup/nativePodEvent/types";

export const usePodDescribeEvents = (
  clusterName: string | undefined,
  namespace: string | undefined,
  podName: string | undefined,
  isTimeLimited = true
): NativePodFragment[] | undefined => {
  const uid = useMemo(() => {
    if (!podName || !namespace || !clusterName) return null;
    return buildKomodorUid({
      kind: "Pod",
      clusterName,
      namespace,
      resourceName: podName ?? "",
    });
  }, [clusterName, namespace, podName]);

  const apiVars = useMemo(() => {
    const to = new Date();
    const from = subHours(to, 1);

    return {
      komodorUids: [uid ?? ""],
      ...(isTimeLimited && {
        fromEpoch: from.getTime().toString(),
        toEpoch: to.getTime().toString(),
      }),
    };
  }, [isTimeLimited, uid]);

  const { data } = useResourcesAPIGet(
    WORKLOADS_PODS_NATIVE_EVENT_SEARCH,
    apiVars,
    !uid
  );

  return data?.data as NativePodFragment[] | undefined;
};
