import React, { useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";
import {
  AdvancedMultiSelect,
  SearchField,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { buildUniqueValues } from "../../../Inspection/buildUniquefilterValues";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
`;

const StyledInput = styled(SearchField)`
  height: 1rem;
`;

const toOptions = (values: string[] | undefined) =>
  values?.map((v) => ({ label: v, value: v })) ?? [];
const fromOptions = (options: { label: string; value: string }[]) =>
  options.map((o) => o.value);

export const Filters: React.FC<{
  resourceList: ResourceTableModelRow[];
  searchTermState: [string | null, (searchValue: string | null) => void];
  statusFilterState: [string[] | null, (status: string[] | null) => void];
  labelFilterState: [string[] | null, (label: string[] | null) => void];
  ariaLabel: string;
}> = ({
  resourceList,
  searchTermState: [searchTerm, setSearchTerm],
  statusFilterState: [statusFilter, setStatusFilter],
  labelFilterState: [labelFilter, setLabelFilter],
  ariaLabel,
}) => {
  const { statusOptions, labelOptions } = useMemo(() => {
    const { allStatuses, allLabels } = buildUniqueValues({
      rows: resourceList,
      fetching: false,
      errorMessage: "",
      emptyResult: false,
    });
    return {
      statusOptions: toOptions(allStatuses),
      labelOptions: toOptions(allLabels),
    };
  }, [resourceList]);

  return (
    <Container aria-label={ariaLabel}>
      <StyledInput
        label="Search"
        value={searchTerm ?? ""}
        onSearch={setSearchTerm}
        width={"15rem"}
        placeholder="Search"
      />
      <AdvancedMultiSelect
        options={statusOptions}
        value={toOptions(statusFilter ?? [])}
        label={"Status"}
        width="15rem"
        placeholder={"Select status"}
        onChange={(values) => setStatusFilter(fromOptions(values))}
      />
      <AdvancedMultiSelect
        options={labelOptions}
        value={toOptions(labelFilter ?? [])}
        label={"Label"}
        width="15rem"
        placeholder={"Select label"}
        onChange={(values) => setLabelFilter(fromOptions(values))}
      />
    </Container>
  );
};
