import { useFormContext } from "react-hook-form";

import { InputFields } from "../policyDrawerTypes";
import {
  detailFormErrors,
  MIN_POLICY_NAME_LENGTH,
} from "../policyDrawerConstants";

const {
  required: requiredErrorMessage,
  getMinLength: getMinLengthErrorMessage,
} = detailFormErrors;

export const useRegisterDetailFields = () => {
  const { register } = useFormContext<InputFields>();

  register("name", {
    required: { value: true, message: requiredErrorMessage },
    validate: {
      required: (value) => {
        return value.toString().trim().length > 0 || requiredErrorMessage;
      },
      minLength: (value) => {
        return (
          value.toString().trim().length >= MIN_POLICY_NAME_LENGTH ||
          getMinLengthErrorMessage(MIN_POLICY_NAME_LENGTH)
        );
      },
    },
  });
  register("priority", {
    required: { value: true, message: requiredErrorMessage },
  });
  register("description");

  register("scope.clusterRegexes", {
    required: { value: true, message: requiredErrorMessage },
    validate: {
      isValidRegex: (values) => {
        const invalidRegexEntry = values?.find((value) => {
          try {
            new RegExp(value);
            return false;
          } catch (e) {
            return true;
          }
        });
        return (
          !invalidRegexEntry ||
          detailFormErrors.getRegexError(invalidRegexEntry)
        );
      },
    },
  });
};
