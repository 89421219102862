import { useMemo } from "react";

import { Check, ImpactGroupIdentifier } from "@/generated/reliabilityApi";
import { ViolationsGroupBy } from "@/components/reliability/ReliabilityTypes";
import { getCheckLabel } from "@/components/reliability/utils/getCheckLabel";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";

const isRelevantChecks = (check: Check, isBestPracticesTab: boolean) => {
  if (
    (isBestPracticesTab && check.impactGroupId === "BestPractices") ||
    (!isBestPracticesTab && check.impactGroupId !== "BestPractices")
  )
    return true;
  return false;
};

type AllCheckProps = {
  checks: Check[] | undefined;
  violationsGroupBy: ViolationsGroupBy;
  impactGroupIdentifier: ImpactGroupIdentifier | undefined;
};

export const useAllChecks = ({
  checks,
  violationsGroupBy,
  impactGroupIdentifier,
}: AllCheckProps) => {
  const isBestPracticesTab = useIsBestPracticesTab();

  return useMemo(() => {
    if (!checks) return [];

    return checks.filter((check) => {
      if (!isRelevantChecks(check, isBestPracticesTab)) return false;
      const foundCheckLabel = !!getCheckLabel(check.type);
      if (impactGroupIdentifier && violationsGroupBy === "Impact") {
        return check.impactGroupId === impactGroupIdentifier && foundCheckLabel;
      }
      return foundCheckLabel;
    });
  }, [checks, impactGroupIdentifier, isBestPracticesTab, violationsGroupBy]);
};
