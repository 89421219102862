import { useMemo } from "react";

import { useReportDDRumMetrics } from "../../MetricsTab/hooks/datadogRumHooks";
import { useWorkloadMetricsTabContext } from "../context/useWorkloadMetricsTabContext";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

export const useReportTabLoadingTime = (
  resourceName: string,
  isDisplayed: boolean
) => {
  const { timeWindow, graphLoadingState } = useWorkloadMetricsTabContext();
  const params = useMemo(
    () => ({
      timeWindow: timeWindow.timeframe,
      graphLoadingState,
      viewName: dataDogViewNames.getMetricsTabDrawerByResource(
        resourceName,
        timeWindow.timeframe
      ),
      isDisplayed,
    }),
    [graphLoadingState, isDisplayed, resourceName, timeWindow]
  );

  useReportDDRumMetrics(params);
};
