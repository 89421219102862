import { useMemo } from "react";

export const useDataForFlag = <T>(
  flag: unknown,
  data: T[] | undefined
): T[] | null =>
  useMemo(() => {
    if (!flag) return [];
    return data ?? null;
  }, [data, flag]);
