import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import { AlertCircleOutlined24 } from "@komodorio/design-system/icons";

import useRetryDesktopSetup from "../../../shared/context/electron/useRetryDesktopSetup";

const RetryButton = styled(Button)`
  margin-top: 1.5rem;
`;

const Container = styled.div`
  height: 100vh;
  background-color: ${palette.gray[50]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;

export type DesktopErrorVariant =
  | "noKubectl"
  | "missingKubeConfigFile"
  | "kubeConfigInitGeneralError"
  | "agentDownloadFailed"
  | "failedInitEnv"
  | "machineTimeIsNotSynced";

const getRetryButton = (retrySetup: () => void) => {
  return (
    <RetryButton
      onClick={() => {
        retrySetup();
      }}
      variant="secondary"
    >
      Retry installation
    </RetryButton>
  );
};

const getErrorContent = (
  errorType: DesktopErrorVariant,
  retrySetup: () => void
) => {
  switch (errorType) {
    case "noKubectl":
    case "missingKubeConfigFile":
    case "kubeConfigInitGeneralError":
      return (
        <>
          {" "}
          <AlertCircleOutlined24
            width={37}
            height={37}
            color={palette.orange[500]}
          />
          <Typography
            variant="headline"
            size="medium"
            color={palette.gray[800]}
          >
            No clusters detected
          </Typography>
          <Typography variant="headline" size="small" color={palette.gray[600]}>
            Set up your cluster and retry running the installation
          </Typography>
          {getRetryButton(retrySetup)}
        </>
      );

    case "agentDownloadFailed":
    case "machineTimeIsNotSynced":
    case "failedInitEnv":
    default:
      return (
        <>
          {" "}
          <AlertCircleOutlined24
            width={37}
            height={37}
            color={palette.pink[500]}
          />
          <Typography
            variant="headline"
            size="medium"
            color={palette.gray[800]}
          >
            Setup failed
          </Typography>
          <Typography variant="headline" size="small" color={palette.gray[600]}>
            We we're unable to set up Komodor on your machine
          </Typography>
          {getRetryButton(retrySetup)}
        </>
      );
  }
};

export const DesktopErrorPage: React.FC<{ error: DesktopErrorVariant }> = ({
  error,
}) => {
  const { retrySetup } = useRetryDesktopSetup();
  return <Container>{getErrorContent(error, retrySetup)}</Container>;
};
