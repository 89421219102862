import { ENTITY_TABLE_LABELS } from "../constants/labels";

import { ALL_CLUSTERS } from "@/components/workspaces/WorkspacesTopBar/constants";
import { Entity } from "@/generated/addonsApi";

export const VIEW_UNAVAILABLE_TEXT =
  "view is unavailable for this type of workspace.";

export function getUnsupportedWorkspaceText(entity: Entity) {
  return (
    <>
      The {ENTITY_TABLE_LABELS[entity]} {VIEW_UNAVAILABLE_TEXT}
      <br />
      Please choose a different workspace or navigate to {ALL_CLUSTERS}.
    </>
  );
}
