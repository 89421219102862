import { Schema, validate, ValidatorResult } from "jsonschema";
import { Statement } from "komodor-types";

import { RbacPolicyType } from "@/generated/auth";

const policySchema: Schema = {
  type: "array",
  minItems: 1,
  maxItems: 1,
  items: {
    type: "object",
    required: ["actions", "resources"],
    additionalProperties: false,
    properties: {
      actions: {
        type: "array",
        minItems: 1,
        items: { type: "string" },
      },
      resources: {
        type: "array",
        minItems: 1,
        items: {
          type: "object",
          required: ["cluster"],
          additionalProperties: false,
          properties: {
            cluster: { type: "string", minLength: 1 },
            namespaces: {
              type: "array",
              items: { type: "string", minLength: 1 },
            },
          },
        },
      },
    },
  },
};

const dynamicTagPolicySchema: Schema = {
  type: "array",
  minItems: 1,
  maxItems: 1,
  items: {
    type: "object",
    required: ["actions", "resources"],
    additionalProperties: false,
    properties: {
      actions: {
        type: "array",
        minItems: 1,
        items: { type: "string" },
      },
      resources: {
        type: "array",
        // We just require an empty array for our backend to generate the statements, UI shouldn't handle this.
        minItems: 0,
        items: {
          type: "object",
          required: ["cluster"],
          additionalProperties: false,
          properties: {
            cluster: { type: "string", minLength: 1 },
            namespaces: {
              type: "array",
              items: { type: "string", minLength: 1 },
            },
          },
        },
      },
    },
  },
};

const namespacePatternTagPolicySchema: Schema = {
  type: "array",
  minItems: 1,
  maxItems: 1,
  items: {
    type: "object",
    required: ["actions", "resources"],
    additionalProperties: false,
    properties: {
      actions: {
        type: "array",
        minItems: 1,
        items: { type: "string" },
      },
      resources: {
        type: "array",
        // We just require an empty array for our backend to generate the statements, UI shouldn't handle this.
        minItems: 0,
        items: {
          type: "object",
          required: ["cluster", "namespacePattern"],
          additionalProperties: false,
          properties: {
            cluster: { type: "string", minLength: 1 },
            namespaces: {
              type: "array",
              items: { type: "string", minLength: 1 },
            },
            namespacePattern: {
              type: "string",
              pattern: "^[a-zA-Z0-9*-]*\\*+[a-zA-Z0-9*-]*$",
            },
          },
        },
      },
    },
  },
};

export const isValidPolicy = (
  json: unknown,
  policyType: RbacPolicyType
): ValidatorResult => {
  switch (policyType) {
    case RbacPolicyType.DynamicTag:
      return validate(json, dynamicTagPolicySchema);
    case RbacPolicyType.Wildcard:
      return validate(json, namespacePatternTagPolicySchema);
    default:
      return validate(json, policySchema);
  }
};

export const validateJsonStatements = (
  stringStatements: string,
  policyType: RbacPolicyType
): [string | undefined, Statement[] | undefined] => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(stringStatements);
    const { valid, errors } = isValidPolicy(parsedJson, policyType);
    if (!valid) {
      throw new Error(errors.map((e) => e.stack + ".").join(" "));
    }
  } catch (err) {
    return [(err as Error).message, undefined];
  }
  return [undefined, parsedJson];
};
export const validateStatements = (
  statements: Statement[],
  policyType: RbacPolicyType
) => {
  const { errors } = isValidPolicy(statements, policyType);
  return errors;
};
