import { v4 as uuid4 } from "uuid";

import {
  CheckType,
  IgnoreRule,
} from "../../../../../../generated/reliabilityApi";
import { parseKomodorUid } from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { IgnoreCheckRuleOption } from "./ignoreCheckRuleContants";
import { FooterButtonsState } from "./IgnoreCheckViolationFooter";

type CreateRuleFromOptionParams = {
  violationCheckType: CheckType;
  selectedOption: IgnoreCheckRuleOption;
  komodorUid: string;
};

export const createRuleFromOption = ({
  selectedOption,
  violationCheckType,
  komodorUid,
}: CreateRuleFromOptionParams): IgnoreRule => {
  const {
    cluster = "",
    namespace = "",
    name: serviceName = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const initialData = {
    id: uuid4(),
    checkType: violationCheckType,
  };

  switch (selectedOption) {
    case "all":
      return {
        ...initialData,
        scope: {
          clusterNames: [],
          namespaces: [],
          shortResourceNames: [],
        },
      };
    case "cluster":
      return {
        ...initialData,
        scope: {
          clusterNames: [cluster],
          namespaces: [],
          shortResourceNames: [],
        },
      };
    case "namespace":
      return {
        ...initialData,
        scope: {
          clusterNames: [cluster],
          namespaces: [namespace],
          shortResourceNames: [],
        },
      };
    case "service":
      return {
        ...initialData,
        scope: {
          clusterNames: [cluster],
          namespaces: [namespace],
          shortResourceNames: [serviceName],
        },
      };
  }
};

type FooterButtonStateParams = {
  isUpdatingRule: boolean;
  selectedIgnoreRuleOption: IgnoreCheckRuleOption | undefined;
};

export const getFooterButtonsState = ({
  selectedIgnoreRuleOption,
  isUpdatingRule,
}: FooterButtonStateParams): FooterButtonsState => {
  if (isUpdatingRule) {
    return "loading";
  }

  return selectedIgnoreRuleOption ? "enabled" : "disabled";
};
