import { useMemo } from "react";
import { intersection } from "lodash";

import { ImpactGroupType } from "@/generated/reliabilityApi";

type Props = {
  impactGroupTypeFilter: string[] | undefined;
  impactGroupTypeByTab: ImpactGroupType;
};

// get selected impact group type that is relevant to the tab
export const useRelevantImpactGroupType = ({
  impactGroupTypeFilter,
  impactGroupTypeByTab,
}: Props) => {
  return useMemo(
    () =>
      impactGroupTypeFilter
        ? intersection<ImpactGroupType>(
            [impactGroupTypeByTab],
            impactGroupTypeFilter as ImpactGroupType[]
          )
        : [impactGroupTypeByTab],
    [impactGroupTypeByTab, impactGroupTypeFilter]
  );
};
