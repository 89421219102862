// warning: this enum need to be in the same order as desktop app
export enum AgentStatus {
  STARTING,
  RUNNING,
  CONNECTED,
  FAILED,
  FAILED_ATTEMPT, // agent still try to connect to the server
  TERMINATING,
  TERMINATED,
}

export const startingLoadingStatuses = new Set([
  AgentStatus.STARTING,
  AgentStatus.RUNNING,
  AgentStatus.FAILED_ATTEMPT,
]);
