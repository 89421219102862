import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type MetricsDetailsSkeletonProps = {
  size: "small" | "large";
};

const sizesSmall = ["110px", "130px", "140px"];
const sizesLarge = ["160px", "300px", "210px"];

export const MetricsDetailsSkeleton: React.FC<MetricsDetailsSkeletonProps> = ({
  size,
}) => {
  const sizesToUse = size === "small" ? sizesSmall : sizesLarge;
  return (
    <Container>
      {sizesToUse.map((size, index) => (
        <Skeleton width={size} height={"24px"} key={index}></Skeleton>
      ))}
    </Container>
  );
};
