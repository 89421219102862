import React, { useCallback } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  DataGridPro,
  GridEventListener,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { MemoryVictim } from "../../../../../../../../../../../generated/reliabilityApi";
import {
  DEFAULT_PAGE_SIZE,
  PAGINATION_SIZE_OPTIONS,
} from "../../../DeprecatedApi/Details/DetailsUtils";
import { reliabilityArialLabels } from "../../../../../../../../../reliabilityArialLabels";

import { NodeDeepView } from "./NodeDeepView/NodeDeepView";
import { useGetAffectedNodesColumns } from "./useGetAffectedNodesColumns";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableContainer = styled.div`
  && {
    .MuiDataGrid-root,
    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
    }
  }
`;

const tableAriaLabel =
  reliabilityArialLabels.violationDrawer.noisyNeighbors.tables.affectedNodes;

interface Props {
  nodes: MemoryVictim[] | undefined;
  komodorUid: string | undefined;
}

export const AffectedNodes: React.FC<Props> = ({ nodes, komodorUid }) => {
  const apiRef = useGridApiRef();

  const onRowClick = useCallback<GridEventListener<"rowClick">>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef]
  );

  const getDetailPanelContent = useCallback(
    (params: GridRowParams<MemoryVictim>) => (
      <NodeDeepView node={params.row} komodorUid={komodorUid} />
    ),
    [komodorUid]
  );

  return (
    <Container>
      <Typography variant="h5" color="text.primary">
        Affected nodes
      </Typography>
      <TableContainer aria-label={tableAriaLabel}>
        <DataGridPro
          rows={nodes ?? []}
          columns={useGetAffectedNodesColumns()}
          apiRef={apiRef}
          getRowId={(row) => row.node}
          onRowClick={onRowClick}
          getDetailPanelContent={getDetailPanelContent}
          slots={{
            detailPanelExpandIcon: ChevronRight,
            detailPanelCollapseIcon: ExpandMore,
          }}
          pageSizeOptions={PAGINATION_SIZE_OPTIONS}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: DEFAULT_PAGE_SIZE,
              },
            },
          }}
          pagination
        />
      </TableContainer>
    </Container>
  );
};
