import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
const scrollPercentageIncrement = 25;

type AllowedTypes = HTMLDivElement;

export const useScrollTracking = <T extends AllowedTypes>() => {
  const analytics = useAnalyticsApi();
  const location = useLocation();
  const elementRef = useRef<T>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollPercentByIncrements, setScrollPercentByIncrements] = useState(0); //0, 25, 50, 75, 100

  const handleScroll = useCallback(() => {
    const containerElement = elementRef.current;
    if (!containerElement) return;
    const { scrollHeight, clientHeight, scrollTop } = containerElement;
    const isScrollable = scrollHeight > clientHeight;
    if (!isScrollable) return;
    const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (scrollPercent > 0 && !isScrolled) {
      setIsScrolled(true);
      analytics.dispatchEventViaBackend(
        AnalyticEvents.Scroll.IsScrolled,
        {
          path: location.pathname,
        },
        true,
        false
      );
    }
    const currentScrollPercentIncrement =
      Math.floor(scrollPercent / scrollPercentageIncrement) *
      scrollPercentageIncrement;

    if (currentScrollPercentIncrement > scrollPercentByIncrements) {
      setScrollPercentByIncrements(currentScrollPercentIncrement);
      analytics.dispatchEventViaBackend(
        AnalyticEvents.Scroll.ScrollDepth,
        {
          path: location.pathname,
          percent: currentScrollPercentIncrement,
        },
        true,
        false
      );
    }
  }, [analytics, isScrolled, location, scrollPercentByIncrements]);

  useEffect(() => {
    const containerElement = elementRef.current;
    if (!containerElement) return;
    containerElement.addEventListener("scroll", handleScroll);
    return () => containerElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return elementRef;
};
