import React from "react";

import { AuditData } from "../types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ManualActionAudit from "./AuditManualAction";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import RbacPolicyAudit from "./AuditRbacPolicy";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import RbacRoleAudit from "./AuditRbacRole";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import UserRoleAudit from "./AuditUserRole";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import RolePolicyAudit from "./AuditRolePolicy";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import UserAudit from "./AuditUser";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import MonitorAudit from "./AuditMonitor";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import IntegrationAudit from "./AuditIntegration";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodExecAudit from "./AuditPodExec";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PortForwardingAudit from "./AuditPortForwarding";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AppViewAudit from "./AuditWorkspaceView";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AccountAudit from "./AuditAccount";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import KubeconfigAudit from "./AuditKubeconfig";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WhitelistAudit from "./AuditWhitelistCIDR";

// eslint-disable-next-line import/order
import { Audit } from "@/generated/graphql";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AuditRbacClusterForceSync } from "@/components/Audit/details/AuditRbacClusterForceSync";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AuditGenericDefault } from "@/components/Audit/details/AuditGenericDefault";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default interface AuditObject {
  readonly data: Audit;
  readonly renderAffectedResources: () => React.ReactNode;
  readonly renderDetails: () => React.ReactNode;
  readonly renderAction: () => React.ReactNode;
  readonly renderEventType: () => React.ReactNode;
  readonly renderJson: () => Record<string, unknown>;
}

export const toAuditObject = (data: Audit): AuditObject | null => {
  const auditData = data as unknown as AuditData;

  switch (auditData.category) {
    case "Manual action": {
      return new ManualActionAudit(data);
    }
    case "pod_exec": {
      return new PodExecAudit(data);
    }
    case "port_forwarding": {
      return new PortForwardingAudit(data);
    }
    case "rbac_user_role": {
      return new UserRoleAudit(data);
    }
    case "rbac_role_policy": {
      return new RolePolicyAudit(data);
    }
    case "rbac_policy": {
      return new RbacPolicyAudit(data);
    }
    case "rbac_role": {
      return new RbacRoleAudit(data);
    }
    case "user": {
      return new UserAudit(data);
    }
    case "workflow_configuration": {
      return new MonitorAudit(data);
    }
    case "installation": {
      return new IntegrationAudit(data);
    }
    case "workspace":
    case "app_view": {
      return new AppViewAudit(data);
    }
    case "account": {
      return new AccountAudit(data);
    }
    case "kubeconfig": {
      return new KubeconfigAudit(data);
    }
    case "whitelist_cidr": {
      return new WhitelistAudit(data);
    }
    case "RBAC_CLUSTER_FORCE_SYNC": {
      return new AuditRbacClusterForceSync(data);
    }
    default: {
      return new AuditGenericDefault(data);
    }
  }
};
