import React, { useCallback, useEffect } from "react";

import { LinesLoader } from "../../../components/common/loaders/Line";

import useMarketplaceActivator from "./marketplaceActivatorProvider";

export const ActivateMarketplaceAccount: React.FC = () => {
  const [isDone, setIsDone] = React.useState(false);
  const { sendMarketplaceAccountActivationCallback } =
    useMarketplaceActivator();

  const callbackWithRedirect = useCallback(async () => {
    if (isDone) return;
    await sendMarketplaceAccountActivationCallback();
    setIsDone(true);
  }, [sendMarketplaceAccountActivationCallback, setIsDone, isDone]);

  useEffect(() => {
    callbackWithRedirect();
  }, [callbackWithRedirect]);

  return <LinesLoader />;
};
