import { useCallback } from "react";
import { cloneDeep } from "lodash";

import { Dictionary } from "../types/Dictionary";
import { ServiceInfo } from "../types/ServiceInfo";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { isServiceTypeJob } from "../utils/serviceHelpers";

export type ServicesDictionary = {
  services: Dictionary<ServiceInfo>;
  jobs: Dictionary<ServiceInfo>;
  all: Dictionary<ServiceInfo>;
};

export const initialStateAsDict: ServicesDictionary = {
  services: {},
  jobs: {},
  all: {},
};

export type FullServicesObject = {
  services: ServiceInfo[];
  jobs: ServiceInfo[];
  all: ServiceInfo[];
  servicesAsDictionary: ServicesDictionary;
};

export const useScopeServicesObject = () => {
  return useCallback(
    (servicesScope: string[], servicesAsDictionary: ServicesDictionary) => {
      return servicesScope.reduce<FullServicesObject>(
        (acc, curr) => {
          const foundService = servicesAsDictionary.all[curr];
          if (!foundService) return acc;
          const type: keyof ServicesDictionary = isServiceTypeJob(foundService)
            ? "jobs"
            : "services";

          // populate service or job
          acc[type].push(servicesAsDictionary[type][curr]);
          // populate "all"
          acc.all.push(servicesAsDictionary[type][curr]);
          // populate servicesAsDictionary of the same type
          acc.servicesAsDictionary[type][curr] =
            servicesAsDictionary[type][curr];
          // populate "all"
          acc.servicesAsDictionary.all[curr] = servicesAsDictionary[type][curr];

          return acc;
        },
        {
          services: [],
          jobs: [],
          all: [],
          servicesAsDictionary: cloneDeep(initialStateAsDict),
        }
      );
    },
    []
  );
};
