import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoRounded from "@mui/icons-material/InfoRounded";

import { ViolationImpactDataAggregation } from "../../../../../../../generated/reliabilityApi";
import { impactGroupToInfo } from "../../../../../constants/reliabilityConstants";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import { formatNumber } from "../../../../../../../shared/utils/formatNumber";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const {
  aggregations: {
    item: aggregationItemArialLabel,
    itemTooltip: aggregationItemTooltipArialLabel,
  },
} = reliabilityArialLabels;

export type ImpactItemProps = {
  impactDataAggregation: ViolationImpactDataAggregation;
};

export const ImpactItem: React.FC<ImpactItemProps> = ({
  impactDataAggregation,
}) => {
  const { category, value, unit } = impactDataAggregation;
  const { title, tooltipInfo, generateInformativeUnit } =
    impactGroupToInfo[category] || {};

  if (!title) {
    return null;
  }

  const formattedValue =
    unit === "time"
      ? formatNumber(value, { maximumFractionDigits: 1 })
      : formatNumber(value, { maximumFractionDigits: 0 });

  return (
    <div aria-label={aggregationItemArialLabel}>
      <FlexContainer>
        <Typography variant={"h5"}>
          {generateInformativeUnit?.(formattedValue) ?? formattedValue}
        </Typography>
        {unit === "time" && <Typography variant={"body2"}>hours</Typography>}
      </FlexContainer>
      <FlexContainer>
        <Typography variant={"body3"} color={"text.secondary"}>
          {title}
        </Typography>
        {tooltipInfo && (
          <Tooltip title={tooltipInfo} placement={"top"}>
            <InfoRounded
              color={"action"}
              fontSize={"small"}
              aria-label={aggregationItemTooltipArialLabel}
            />
          </Tooltip>
        )}
      </FlexContainer>
    </div>
  );
};
