import { useQuery } from "@tanstack/react-query";

import {
  GetViolationsOverTimeResponse,
  getViolationsOverTimeUrl,
  ViolationsApiGetViolationsOverTimeRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";
import { useWorkspaceQueryKey } from "../../workspaces-api/useWorkspaceQueryKey";

export const GET_VIOLATIONS_OVER_TIME_PATH = "/api/v1/violations/over-time";

export const useGetViolationsOverTime = (
  params: ViolationsApiGetViolationsOverTimeRequest,
  enable = true
) => {
  const apiClient = useReliabilityApiClient();
  const keys = useWorkspaceQueryKey([GET_VIOLATIONS_OVER_TIME_PATH, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient
        .get<GetViolationsOverTimeResponse>(
          getViolationsOverTimeUrl(params, apiClient.defaults.baseURL ?? "")
        )
        .then((r) => r.data),
    {
      enabled: enable,
    }
  );
};
