import { useDesktopAgentsStatusContext } from "../desktopAgentsProvider/desktopAgentsStatusesProvider";
import { AgentStatus, startingLoadingStatuses } from "../getClusterStatus";

export const getIsAgentsInProgress = (
  agentStatusValues: AgentStatus[]
): boolean => {
  return (
    !!agentStatusValues.length &&
    agentStatusValues.some((agentStatus) =>
      startingLoadingStatuses.has(agentStatus)
    )
  );
};

export const useIsAgentsInProgress = (): boolean => {
  const agentStatuses = useDesktopAgentsStatusContext();
  const agentStatusValues = Object.values(agentStatuses ?? {});
  const isAgentsConnecting = getIsAgentsInProgress(agentStatusValues);

  return isAgentsConnecting;
};
