import { parseISO } from "date-fns";
import { JobCondition, JobStatus } from "kubernetes-types/batch/v1.d";

import { JobState } from "../types/job";

const getJobState = (status: JobStatus, condition?: JobCondition): JobState => {
  if (status?.active) {
    return JobState.inprogress;
  }

  if (condition) {
    if (condition.type === "Complete") {
      return JobState.completed;
    } else if (condition.type === "Failed") {
      return JobState.failed;
    } else if (condition.type === "Suspended") {
      if (condition.status === "True") {
        return JobState.suspended;
      } else if (condition.status === "False") {
        return JobState.resumed;
      }
    }
  }

  return JobState.unknown;
};

export const extractJobsStatusandLastRunTime = (
  statusAsString?: string,
  status?: JobStatus
): {
  jobState: JobState;
  jobLastRunTime: Date | undefined;
  jobStatusReason?: string;
  jobStatusMessage?: string;
} => {
  const statusObj =
    status ??
    (statusAsString ? (JSON.parse(statusAsString) as JobStatus) : undefined);

  if (statusObj?.active) {
    return {
      jobState: getJobState(statusObj),
      jobLastRunTime: new Date(statusObj.startTime ?? ""),
    };
  }

  if (statusObj?.conditions) {
    const conditions = statusObj?.conditions.sort((a, b) => {
      return a?.lastProbeTime?.localeCompare(b?.lastProbeTime ?? "") ?? 0;
    });

    const lastCondition = conditions[conditions.length - 1];
    return {
      jobState: getJobState(statusObj, lastCondition),
      jobLastRunTime: new Date(lastCondition.lastTransitionTime ?? ""),
      jobStatusReason: lastCondition?.reason,
      jobStatusMessage: lastCondition?.message,
    };
  }
  return { jobState: JobState.noRuns, jobLastRunTime: undefined };
};

export const getMinTime = (
  first: string | null | undefined,
  second: string | null | undefined
): Date | undefined => {
  let result = undefined;

  if (first && !second) {
    result = first;
  } else if (second && !first) {
    result = second;
  } else if (first && second) {
    result = parseISO(first) <= parseISO(second) ? first : second;
  }

  if (result) {
    return new Date(result);
  }
  return undefined;
};
