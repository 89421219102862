/* tslint:disable */
/* eslint-disable */
/**
 * Application insights API
 * This is an API that serve data especially for the application overview page insights
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * A representation of availability issues data
 * @export
 * @interface AvailabilityIssuesResponse
 */
export interface AvailabilityIssuesResponse {
    /**
     * 
     * @type {Array<SumByDayDataSumByDayInner>}
     * @memberof AvailabilityIssuesResponse
     */
    'sumByDay': Array<SumByDayDataSumByDayInner>;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityIssuesResponse
     */
    'bucketTimeFrame': AvailabilityIssuesResponseBucketTimeFrameEnum;
    /**
     * 
     * @type {Array<IssuesTrendAllOfAffectedServicesInner>}
     * @memberof AvailabilityIssuesResponse
     */
    'affectedServices': Array<IssuesTrendAllOfAffectedServicesInner>;
}

export const AvailabilityIssuesResponseBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type AvailabilityIssuesResponseBucketTimeFrameEnum = typeof AvailabilityIssuesResponseBucketTimeFrameEnum[keyof typeof AvailabilityIssuesResponseBucketTimeFrameEnum];

        /**
 * 
 * @export
 * @interface BucketTimeFrame
 */
export interface BucketTimeFrame {
    /**
     * 
     * @type {string}
     * @memberof BucketTimeFrame
     */
    'bucketTimeFrame': BucketTimeFrameBucketTimeFrameEnum;
}

export const BucketTimeFrameBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type BucketTimeFrameBucketTimeFrameEnum = typeof BucketTimeFrameBucketTimeFrameEnum[keyof typeof BucketTimeFrameBucketTimeFrameEnum];

        /**
 * A representation of availability issues data
 * @export
 * @interface FailedDeploymentsResponse
 */
export interface FailedDeploymentsResponse {
    /**
     * 
     * @type {Array<SumByDayDataSumByDayInner>}
     * @memberof FailedDeploymentsResponse
     */
    'sumByDay': Array<SumByDayDataSumByDayInner>;
    /**
     * 
     * @type {string}
     * @memberof FailedDeploymentsResponse
     */
    'bucketTimeFrame': FailedDeploymentsResponseBucketTimeFrameEnum;
    /**
     * 
     * @type {Array<IssuesTrendAllOfAffectedServicesInner>}
     * @memberof FailedDeploymentsResponse
     */
    'affectedServices': Array<IssuesTrendAllOfAffectedServicesInner>;
}

export const FailedDeploymentsResponseBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type FailedDeploymentsResponseBucketTimeFrameEnum = typeof FailedDeploymentsResponseBucketTimeFrameEnum[keyof typeof FailedDeploymentsResponseBucketTimeFrameEnum];

        /**
 * 
 * @export
 * @interface IssuesTrend
 */
export interface IssuesTrend {
    /**
     * 
     * @type {Array<SumByDayDataSumByDayInner>}
     * @memberof IssuesTrend
     */
    'sumByDay': Array<SumByDayDataSumByDayInner>;
    /**
     * 
     * @type {string}
     * @memberof IssuesTrend
     */
    'bucketTimeFrame': IssuesTrendBucketTimeFrameEnum;
    /**
     * 
     * @type {Array<IssuesTrendAllOfAffectedServicesInner>}
     * @memberof IssuesTrend
     */
    'affectedServices': Array<IssuesTrendAllOfAffectedServicesInner>;
}

export const IssuesTrendBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type IssuesTrendBucketTimeFrameEnum = typeof IssuesTrendBucketTimeFrameEnum[keyof typeof IssuesTrendBucketTimeFrameEnum];

        /**
 * 
 * @export
 * @interface IssuesTrendAllOf
 */
export interface IssuesTrendAllOf {
    /**
     * 
     * @type {Array<IssuesTrendAllOfAffectedServicesInner>}
     * @memberof IssuesTrendAllOf
     */
    'affectedServices'?: Array<IssuesTrendAllOfAffectedServicesInner>;
}
        /**
 * 
 * @export
 * @interface IssuesTrendAllOfAffectedServicesInner
 */
export interface IssuesTrendAllOfAffectedServicesInner {
    /**
     * 
     * @type {number}
     * @memberof IssuesTrendAllOfAffectedServicesInner
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof IssuesTrendAllOfAffectedServicesInner
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof IssuesTrendAllOfAffectedServicesInner
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof IssuesTrendAllOfAffectedServicesInner
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof IssuesTrendAllOfAffectedServicesInner
     */
    'namespace': string;
}
        /**
 * A representation of a table row in the best practices liveness probe missing
 * @export
 * @interface LivenessProbesMissingBestPractices
 */
export interface LivenessProbesMissingBestPractices {
    /**
     * 
     * @type {string}
     * @memberof LivenessProbesMissingBestPractices
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof LivenessProbesMissingBestPractices
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof LivenessProbesMissingBestPractices
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof LivenessProbesMissingBestPractices
     */
    'namespace': string;
}
        /**
 * A representation of affected services by liveness probe missing
 * @export
 * @interface LivenessProbesMissingBestPracticesResponse
 */
export interface LivenessProbesMissingBestPracticesResponse {
    /**
     * 
     * @type {Array<LivenessProbesMissingBestPractices>}
     * @memberof LivenessProbesMissingBestPracticesResponse
     */
    'rows': Array<LivenessProbesMissingBestPractices>;
}
        /**
 * A representation of a table row in the best practices for missing requests or limits
 * @export
 * @interface MissingRequestsOrLimitsBestPractices
 */
export interface MissingRequestsOrLimitsBestPractices {
    /**
     * 
     * @type {string}
     * @memberof MissingRequestsOrLimitsBestPractices
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof MissingRequestsOrLimitsBestPractices
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof MissingRequestsOrLimitsBestPractices
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof MissingRequestsOrLimitsBestPractices
     */
    'namespace': string;
}
        /**
 * A representation of affected services by missing requests or limits best practices
 * @export
 * @interface MissingRequestsOrLimitsBestPracticesResponse
 */
export interface MissingRequestsOrLimitsBestPracticesResponse {
    /**
     * 
     * @type {Array<MissingRequestsOrLimitsBestPractices>}
     * @memberof MissingRequestsOrLimitsBestPracticesResponse
     */
    'rows': Array<MissingRequestsOrLimitsBestPractices>;
}
        /**
 * 
 * @export
 * @interface ResourceData
 */
export interface ResourceData {
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'namespace': string;
}
        /**
 * A representation of a table row in the security best practices
 * @export
 * @interface SecurityBestPractices
 */
export interface SecurityBestPractices {
    /**
     * 
     * @type {number}
     * @memberof SecurityBestPractices
     */
    'criticalIssues': number;
    /**
     * 
     * @type {number}
     * @memberof SecurityBestPractices
     */
    'warnings': number;
    /**
     * 
     * @type {string}
     * @memberof SecurityBestPractices
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityBestPractices
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityBestPractices
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityBestPractices
     */
    'namespace': string;
}
        /**
 * A representation of affected services by security best practices
 * @export
 * @interface SecurityBestPracticesResponse
 */
export interface SecurityBestPracticesResponse {
    /**
     * 
     * @type {Array<SecurityBestPractices>}
     * @memberof SecurityBestPracticesResponse
     */
    'rows': Array<SecurityBestPractices>;
}
        /**
 * 
 * @export
 * @interface SumByDayData
 */
export interface SumByDayData {
    /**
     * 
     * @type {Array<SumByDayDataSumByDayInner>}
     * @memberof SumByDayData
     */
    'sumByDay'?: Array<SumByDayDataSumByDayInner>;
}
        /**
 * 
 * @export
 * @interface SumByDayDataSumByDayInner
 */
export interface SumByDayDataSumByDayInner {
    /**
     * 
     * @type {number}
     * @memberof SumByDayDataSumByDayInner
     */
    'ts': number;
    /**
     * 
     * @type {number}
     * @memberof SumByDayDataSumByDayInner
     */
    'value': number;
}
    
        /**
* Get availability count issues by day per time window
* @summary Get availability issues count by day per time window
* @param params AppInsightsApiGetAvailabilityIssuesRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getAvailabilityIssuesUrl = (params: AppInsightsApiGetAvailabilityIssuesRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getAvailabilityIssues', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getAvailabilityIssues', 'toEpoch', params.toEpoch)
    // verify required parameter 'issuesCountFromEpoch' is not null or undefined
    assertParamExists('getAvailabilityIssues', 'issuesCountFromEpoch', params.issuesCountFromEpoch)
    // verify required parameter 'issuesCountToEpoch' is not null or undefined
    assertParamExists('getAvailabilityIssues', 'issuesCountToEpoch', params.issuesCountToEpoch)
    // verify required parameter 'bucketTimeFrame' is not null or undefined
    assertParamExists('getAvailabilityIssues', 'bucketTimeFrame', params.bucketTimeFrame)
    const localVarPath = `/api/v1/issues/availability`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.issuesCountFromEpoch !== undefined) {
            localVarQueryParameter['issuesCountFromEpoch'] = params.issuesCountFromEpoch;
    }
    if (params.issuesCountToEpoch !== undefined) {
            localVarQueryParameter['issuesCountToEpoch'] = params.issuesCountToEpoch;
    }
    if (params.bucketTimeFrame !== undefined) {
            localVarQueryParameter['bucketTimeFrame'] = params.bucketTimeFrame;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get failed deployments count by day per time window
* @summary Get failed deployments count by day per time window
* @param params AppInsightsApiGetFailedDeploymentsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getFailedDeploymentsUrl = (params: AppInsightsApiGetFailedDeploymentsRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getFailedDeployments', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getFailedDeployments', 'toEpoch', params.toEpoch)
    // verify required parameter 'issuesCountFromEpoch' is not null or undefined
    assertParamExists('getFailedDeployments', 'issuesCountFromEpoch', params.issuesCountFromEpoch)
    // verify required parameter 'issuesCountToEpoch' is not null or undefined
    assertParamExists('getFailedDeployments', 'issuesCountToEpoch', params.issuesCountToEpoch)
    // verify required parameter 'bucketTimeFrame' is not null or undefined
    assertParamExists('getFailedDeployments', 'bucketTimeFrame', params.bucketTimeFrame)
    const localVarPath = `/api/v1/issues/failed-deployments`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.issuesCountFromEpoch !== undefined) {
            localVarQueryParameter['issuesCountFromEpoch'] = params.issuesCountFromEpoch;
    }
    if (params.issuesCountToEpoch !== undefined) {
            localVarQueryParameter['issuesCountToEpoch'] = params.issuesCountToEpoch;
    }
    if (params.bucketTimeFrame !== undefined) {
            localVarQueryParameter['bucketTimeFrame'] = params.bucketTimeFrame;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the best practices insights for liveness probe missing
* @summary Get the best practices insights for liveness probe missing
* @param params AppInsightsApiGetLivenessProbesMissingBestPracticesRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getLivenessProbesMissingBestPracticesUrl = (params: AppInsightsApiGetLivenessProbesMissingBestPracticesRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/best-practices/liveness-probes-missing`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get the best practices insights regarding requests or limits
* @summary Get the best practices insights regarding missing requests or limits
* @param params AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getMissingRequestsOrLimitsBestPracticesUrl = (params: AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/best-practices/missing-requests-or-limits`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get the best practices insights regarding security
* @summary Get the best practices insights regarding security
* @param params AppInsightsApiGetSecurityBestPracticesRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getSecurityBestPracticesUrl = (params: AppInsightsApiGetSecurityBestPracticesRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/best-practices/security`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for getAvailabilityIssues operation in AppInsightsApi.
* @export
* @interface AppInsightsApiGetAvailabilityIssuesRequest
*/
export interface AppInsightsApiGetAvailabilityIssuesRequest {
    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly fromEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly toEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly issuesCountFromEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly issuesCountToEpoch: number

    /**
    * The time frame each bucket should represent
    * @type {'1h' | '24h' | '7d' | '30d'}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly bucketTimeFrame: '1h' | '24h' | '7d' | '30d'

    /**
    * Headers
    * @type {string}
    * @memberof AppInsightsApiGetAvailabilityIssues
    */
    readonly xAppViewId?: string
}

/**
* Request parameters for getFailedDeployments operation in AppInsightsApi.
* @export
* @interface AppInsightsApiGetFailedDeploymentsRequest
*/
export interface AppInsightsApiGetFailedDeploymentsRequest {
    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly fromEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly toEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly issuesCountFromEpoch: number

    /**
    * 
    * @type {number}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly issuesCountToEpoch: number

    /**
    * The time frame each bucket should represent
    * @type {'1h' | '24h' | '7d' | '30d'}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly bucketTimeFrame: '1h' | '24h' | '7d' | '30d'

    /**
    * Headers
    * @type {string}
    * @memberof AppInsightsApiGetFailedDeployments
    */
    readonly xAppViewId?: string
}

/**
* Request parameters for getLivenessProbesMissingBestPractices operation in AppInsightsApi.
* @export
* @interface AppInsightsApiGetLivenessProbesMissingBestPracticesRequest
*/
export interface AppInsightsApiGetLivenessProbesMissingBestPracticesRequest {
    /**
    * Headers
    * @type {string}
    * @memberof AppInsightsApiGetLivenessProbesMissingBestPractices
    */
    readonly xAppViewId?: string
}

/**
* Request parameters for getMissingRequestsOrLimitsBestPractices operation in AppInsightsApi.
* @export
* @interface AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest
*/
export interface AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest {
    /**
    * Headers
    * @type {string}
    * @memberof AppInsightsApiGetMissingRequestsOrLimitsBestPractices
    */
    readonly xAppViewId?: string
}

/**
* Request parameters for getSecurityBestPractices operation in AppInsightsApi.
* @export
* @interface AppInsightsApiGetSecurityBestPracticesRequest
*/
export interface AppInsightsApiGetSecurityBestPracticesRequest {
    /**
    * Headers
    * @type {string}
    * @memberof AppInsightsApiGetSecurityBestPractices
    */
    readonly xAppViewId?: string
}


    
