import { useState } from "react";

import certManagerImgSrc from "../../assets/Screen - Cert.png";
import { TopBar } from "../TopBar";
import certExpiresDrawer from "../../assets/Drawer - Cert Expire.png";
import { CloseButton, RelativeContainer, SelectableRow } from "../../styles";

import { MockDrawer } from "./MockDrawer";

export const CertManagerView: React.FC = () => {
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  return (
    <RelativeContainer>
      <TopBar page="certs" />
      <img src={certManagerImgSrc}></img>
      <SelectableRow
        height="5.6%"
        top="43.1%"
        onClick={() => {
          setDrawerImgSrc(certExpiresDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        onClose={() => setDrawerImgSrc(null)}
        imgSrc={drawerImgSrc}
        closeButton={<CloseButton onClick={() => setDrawerImgSrc(null)} />}
      ></MockDrawer>
    </RelativeContainer>
  );
};
