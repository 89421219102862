import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useAuthApiClient } from "../apiClient";
import {
  RbacPoliciesApiApiV1RbacPoliciesGetRequest,
  RbacPolicy,
  apiV1RbacPoliciesGetUrl,
} from "../../../../../generated/auth";

const RBAC_POLICIES_PATH = "/rbac-policies";

const fetchRbacPolicies = async (
  apiClient: AxiosInstance,
  params: RbacPoliciesApiApiV1RbacPoliciesGetRequest = {}
): Promise<RbacPolicy[]> => {
  const { data } = await apiClient.get(
    apiV1RbacPoliciesGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetRbacPolicies = (
  params?: RbacPoliciesApiApiV1RbacPoliciesGetRequest,
  enabled?: boolean
) => {
  const apiClient = useAuthApiClient();
  return useQuery(
    [RBAC_POLICIES_PATH, params],
    () => fetchRbacPolicies(apiClient, params),
    {
      enabled,
    }
  );
};
