import { get } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeployEvent } from "../../../EventGroup/deployEvent/DeployEventGroup";

export const useImageChanges = (
  spec: Record<string, unknown> | undefined,
  event: DeployEvent
): string[] => {
  const imagesDiffKeys =
    event.diffKeys?.filter((key) =>
      /^spec\.(template|jobTemplate\.spec\.template)\.spec\.containers\.\d+\.image$/.test(
        key
      )
    ) ?? [];

  return imagesDiffKeys
    .map((key) => get(spec, key, null))
    .filter(Boolean) as string[];
};
