import React from "react";

import { useOverridableFlags } from "../context/featureFlags/OverridableFlags";

const FeatureFlag: React.FC<{ name: string; children?: React.ReactNode }> = ({
  name,
  children,
}) => {
  const flags = useOverridableFlags();
  if (!flags[name]) {
    return null;
  }
  return <>{children}</>;
};

export { FeatureFlag };
