import React, { useCallback, useMemo } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { INVALID_DATAPOINT_VALUE } from "../hooks";
import { useGraphEvents } from "../../hooks/useGraphEvents";

import { HeatmapGraphProps } from "./heatmapTypes";
import { defaultMargins, marginsWithEvents } from "./heatmapGraphConstants";

export const HeatmapGraph: React.FC<HeatmapGraphProps> = ({
  data,
  colorScheme,
  tooltipProps,
  xAxisProps,
  yAxisProps,
  barChartProps,
  events,
}) => {
  const transformedData = useMemo(() => {
    return data.map((entry) => ({
      ...entry,
      value: entry.value === INVALID_DATAPOINT_VALUE ? null : entry.value,
      barValue: 100, // set constant bar value for display
    }));
  }, [data]);

  const getColor = useCallback(
    (value: number | null): string | undefined => {
      if (value === null) {
        return colorScheme[0].color;
      }

      for (const color of colorScheme) {
        if (value <= color.maxValue) return color.color;
      }
      return colorScheme[colorScheme.length - 1].color;
    },
    [colorScheme]
  );

  const barCells = useMemo(() => {
    return transformedData.map((entry, index) => {
      const color = getColor(entry.value);
      return <Cell key={`cell-${index}`} fill={color} />;
    });
  }, [getColor, transformedData]);

  const eventsOnGraph = useGraphEvents(events);
  const margins = eventsOnGraph?.length ? marginsWithEvents : defaultMargins;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={transformedData}
        barCategoryGap={-1}
        margin={margins}
        {...barChartProps}
      >
        {xAxisProps && <XAxis {...xAxisProps} />}
        {yAxisProps && <YAxis {...yAxisProps} />}
        {tooltipProps && (
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={-100}
            {...tooltipProps}
          />
        )}
        <Bar dataKey="barValue" fill="transparent" isAnimationActive={false}>
          {barCells}
        </Bar>
        {eventsOnGraph}
      </BarChart>
    </ResponsiveContainer>
  );
};
