import { WorkloadMetricsSummary } from "../../../../../../../../../../../generated/metricsApi";
import { ViolationSummaryItem } from "../../../../shared/ViolationSummary/ViolationSummary";
import {
  getMibNumber,
  getRatio,
  getValue,
} from "../../../../shared/metrics/components/MetricsSummary/MetricsSummaryUtils";

const memoryUnit = "MiB";

export const getSummaryCb = () => {
  return (
    summary: WorkloadMetricsSummary | undefined
  ): ViolationSummaryItem[] => {
    if (!summary) return [];

    const requestValue = getMibNumber(summary?.latestRequests);
    const limitValue = getMibNumber(summary?.latestLimits);
    const usageValue = getMibNumber(summary?.maxUtilization);

    return [
      {
        label: "Requests",
        value: getValue(requestValue, memoryUnit),
      },
      {
        label: "Limits",
        value: getValue(limitValue, memoryUnit),
      },
      {
        label: "Request to usage ratio",
        value: getRatio({ firstValue: requestValue, secondValue: usageValue }),
      },
    ];
  };
};
