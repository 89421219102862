import React from "react";
import styled from "styled-components";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { useGetEpochsFromViolation } from "../../../hooks/useGetEpochsFromViolation";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import { getWhyDescription, useHowDescription } from "./texts";
import { ReplicasOverTimeGraph } from "./ReplicasOverTimeGraph/ReplicasOverTimeGraph";
import { CorrelatedEvents } from "./CorrelatedEvents";
import { WhatHappenedSummary } from "./WhatHappenedSummary";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";

const SupportingDataContainer = styled.div`
  margin-bottom: 32px;
`;

export const SinglePointOfFailureDrawer: React.FC = () => {
  const { data } = useViolation();

  const { headerProps, summaryProps, supportingData } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  const singlePointOfFailureSupportingData =
    supportingData?.singlePointOfFailure;

  const howDescription = useHowDescription(
    singlePointOfFailureSupportingData?.correlatedViolations
  );

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              <WhatHappenedSummary
                supportingData={singlePointOfFailureSupportingData}
              />

              <SupportingDataContainer>
                <ReplicasOverTimeGraph
                  komodorUid={data?.data.violation.komodorUid}
                  fromEpoch={fromEpoch}
                  toEpoch={toEpoch}
                />
              </SupportingDataContainer>

              <SupportingDataContainer>
                <ViolationsTableByIds
                  title="Best practice misconfigurations"
                  ids={data.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              </SupportingDataContainer>

              <SupportingDataContainer>
                <CorrelatedEvents
                  komodorUid={data.data.violation.komodorUid}
                  fromEpoch={fromEpoch}
                  toEpoch={toEpoch}
                  correlatedIssues={
                    singlePointOfFailureSupportingData?.correlatedIssues ?? []
                  }
                  correlatedNodeEventIds={
                    singlePointOfFailureSupportingData?.correlatedNodeEventIds ??
                    []
                  }
                />
              </SupportingDataContainer>
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={howDescription}
        />
      }
    />
  );
};
