import {
  CheckType,
  ViolationSupportingData,
} from "../../../../../../../../../generated/reliabilityApi";
import { formatPercentage } from "../../../../../../../../../shared/utils/formatPercentage";
import { getCheckLabel } from "../../../../../../../utils/getCheckLabel";
import { MetricType } from "../../../../../../../../Metrics/types";
import { LegendItem } from "../../../../../../../../Metrics/GenericLegend/GenericLegend";
import {
  MetricsRequestColor,
  MetricsUsageColor,
} from "../../../../../../../../Metrics/styles";

import { UTILIZATION_METRIC_COLOR } from "./hpaReachedMaxConstants";

export const getHpaReachedMaxTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const fractionAtMax = supportingData?.HPAMax?.fractionAtMax;
  if (!fractionAtMax) return getCheckLabel(CheckType.HpaMax);

  return `HPA reached its limit ${formatPercentage(fractionAtMax * 100, {
    maximumFractionDigits: 0,
  })} of the time in the last 7 days`;
};

type GetRequestValueByPercentParams = {
  averageUtilizationValue: number;
  averageUtilizationPercent: number;
};

export const getRequestValueByPercent = ({
  averageUtilizationPercent,
  averageUtilizationValue,
}: GetRequestValueByPercentParams) => {
  const valueInBytes = averageUtilizationValue || 0;
  return (averageUtilizationPercent / 100) * valueInBytes;
};

export const getMetricsGraphTitle = (metricsType: MetricType) =>
  metricsType === "memory" ? "Memory usage (Avg)" : "CPU usage (Avg)";

export const getMetricsGenericLegendItems = (
  metricsType: MetricType
): LegendItem[] => {
  const usageLabel = metricsType === "cpu" ? "CPU usage" : "Memory usage";
  return [
    { color: MetricsUsageColor ?? "", label: usageLabel },
    { color: MetricsRequestColor ?? "", label: "Requests" },
    {
      color: UTILIZATION_METRIC_COLOR ?? "",
      label: "Scaling threshold",
    },
  ];
};
