import { useGetAccount } from "../../../../shared/hooks/accounts-service/client/account/useGetAccount";

export const useAccountFeatures = () => {
  const account = useGetAccount();
  return {
    data: account.data?.features ?? {},
    refresh: account.refetch,
    isLoading: account.isLoading,
  };
};
