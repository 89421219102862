import styled, { css } from "styled-components";

import { StepContent } from "../steps.styles";
import { blueBrand, gray2 } from "../../../../../../Colors";
import { H2 } from "../../../../../common/typography";

export const IconStyle = css`
  height: 3rem;
  margin-bottom: 0.5rem;
`;

export const StyledStepContent = styled(StepContent)`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const H2MessageTitle = styled(H2)`
  color: ${gray2};
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const StyledButton = styled.div`
  padding: 0.35rem 1rem;
  background-color: ${blueBrand};
  color: white;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;
