import { KubernetesResource } from "./KubernetesResource";

export const KubernetesRoleBindingsResource: KubernetesResource = {
  NameInK8S: "rolebindings",
  Group: "access-control",
  NavBarName: "Role Bindings",
  PresentName: "Role Bindings",
  Namespaced: true,
  UnnecessaryColumns: [],
  Kind: "RoleBinding",
  Headers: [
    { name: "Name" },
    { name: "Namespace" },
    { name: "Bindings" },
    { name: "Age" },
    { name: "Labels", hide: true },
    { name: "Annotations", hide: true },
  ],
  hasLabels: false,
};
