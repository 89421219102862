import React, { useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography, Button } from "@komodorio/design-system/deprecated";

import UpgradeRequired from "../../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { INSPECTION_USER_PERMISSIONS_ERROR } from "../../../../shared/hooks/ATM/constants/atmResources";
import { UpgradeRequiredModalStep } from "../../../common/UpdateAgentModal/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
  align-items: center;
  padding: 0 10rem;
  padding-top: 3rem;
`;

const RefreshButton = styled(Typography).attrs({
  role: "button",
  variant: "text",
  size: "large",
  color: palette.darkBlue["500"],
})`
  cursor: pointer;
`;

const StyledUpgradeButton = styled(Button)`
  margin-top: 10px;
`;

interface Props {
  errorMessage: string;
  crName: string | null;
  onRetry: () => void;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum InspectionErrorType {
  CouldNotFind = "CouldNotFind",
  MissingPermissions = "MissingPermissions",
  Unknown = "Unknown",
}

const classifyErrorByMessage = (errMsg: string): InspectionErrorType => {
  if (errMsg === "the server could not find the requested resource") {
    return InspectionErrorType.CouldNotFind;
  }

  if (errMsg === INSPECTION_USER_PERMISSIONS_ERROR) {
    return InspectionErrorType.MissingPermissions;
  }

  return InspectionErrorType.Unknown;
};

const InsufficientPermissionsMessage: React.FC<{ crName: string | null }> = ({
  crName,
}) => {
  const [showShowAgentRequiredModal, setShouldShowAgentRequiredModal] =
    useState(false);

  return (
    <>
      <Container>
        <Typography
          variant="title"
          size="large"
          bold
          color={palette.gray["800"]}
        >
          Insufficient permissions
        </Typography>
        <Typography variant="title" size="large" color={palette.gray["600"]}>
          The Komodor agent cannot read {crName || "custom"} resources. Please
          update the agent with the right permissions
        </Typography>

        <StyledUpgradeButton
          variant="secondary"
          size="small"
          onClick={() => setShouldShowAgentRequiredModal(true)}
        >
          Update agent
        </StyledUpgradeButton>
      </Container>

      {showShowAgentRequiredModal && (
        <UpgradeRequired
          handleClose={() => setShouldShowAgentRequiredModal(false)}
          updateMessage={
            <Typography size="medium">
              An update to the Komodor agent is required to list custom
              resources on this cluster.
            </Typography>
          }
          upgradeCommand={
            UpgradeCommands.UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES
          }
          desiredFeature="custom resources"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </>
  );
};

export const CRError: React.FC<Props> = ({ errorMessage, onRetry, crName }) => {
  const errType = classifyErrorByMessage(errorMessage);

  switch (errType) {
    case InspectionErrorType.MissingPermissions:
      return <InsufficientPermissionsMessage crName={crName} />;

    case InspectionErrorType.CouldNotFind:
    default:
      return (
        <Container>
          <Typography variant="title" size="large" bold>
            No resources found
          </Typography>
          <RefreshButton onClick={onRetry}>Refresh</RefreshButton>
        </Container>
      );
  }
};
