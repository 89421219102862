import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type EventsViewState = {
  fetchingState: {
    isFetchingEvents: boolean | undefined;
    isFetchingNativePodEvents: boolean | undefined;
  };
};

export type FetchingStateProps = EventsViewState["fetchingState"];

export type EventsViewActions = {
  setFetchingState: (fetchingState: {
    key: keyof FetchingStateProps;
    value: boolean;
  }) => void;
  resetFetchingState: () => void;
};

export type EventsViewStore = EventsViewState & EventsViewActions;

export const initialState: EventsViewState = {
  fetchingState: {
    isFetchingEvents: undefined,
    isFetchingNativePodEvents: undefined,
  },
};

export const useEventsViewStore = create(
  immer<EventsViewStore>((set, get) => ({
    ...initialState,
    setFetchingState: (fetchingState: {
      key: keyof FetchingStateProps;
      value: boolean;
    }) => {
      set((state) => ({
        fetchingState: {
          ...state.fetchingState,
          [fetchingState.key]: fetchingState.value,
        },
      }));
    },
    resetFetchingState: () => {
      set(() => ({
        fetchingState: {
          ...initialState.fetchingState,
        },
      }));
    },
  }))
);

export const resetEventsViewStore = (): void => {
  useEventsViewStore.setState({
    ...initialState,
  });
};
