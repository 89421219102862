import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

const LEFT_PADDING = "1rem";

export const SectionContainer = styled.div<{ isWarning?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  border: 1px solid
    ${({ isWarning }) =>
      isWarning ? muiColors.orange["600"] : muiColors.gray["200"]};
  border-radius: 4px 4px 0 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 1rem 0.5rem 0 ${LEFT_PADDING};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0.5rem 0 ${LEFT_PADDING};
  width: calc(100% - ${LEFT_PADDING});
`;

export const SimpleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SectionFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem ${LEFT_PADDING};
  align-items: start;
`;

export const DiffSection = styled.div`
  display: flex;
  max-height: 15rem;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: start;
`;

export const CardPadding = styled.div`
  padding-bottom: 8px;
`;
