import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";

import { PopoverMenuListHandler } from "../../../../shared/components/PopoverMenuListHandler/PopoverMenuListHandler";
import { OverflowTextStyle } from "../../../../shared/styles/styles";

import { AccountsListItems } from "./AccountsListItems";

const SelectedBar = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  max-width: 90px;
`;

const TypographyWithTextEllipsis = styled(Typography)`
  && {
    ${OverflowTextStyle};
    color: #ffffff;
  }
`;

const AnimatedKeyboardArrowDown = styled(KeyboardArrowDown)<{ open: boolean }>`
  && {
    transition: transform 0.2s;
    transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  }
`;

interface AccountsListProps {
  favoriteAccounts: MuiSelectionOption<string>[];
  options: MuiSelectionOption<string>[];
  onSelect: (value: MuiSelectionOption<string>) => void;
  value?: MuiSelectionOption<string>;
  onClose: () => void;
}

export const AccountsList: React.FC<AccountsListProps> = ({
  favoriteAccounts,
  options,
  onSelect,
  value,
  onClose,
}) => {
  const [menuListOpen, setMenuListOpen] = useState(false);

  const handleClose = () => {
    setMenuListOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setMenuListOpen(true);
  };

  const getItems = useCallback(
    (options: MuiSelectionOption<string>[]) => {
      return (
        <AccountsListItems
          options={options}
          onSelect={onSelect}
          favoriteAccounts={favoriteAccounts}
        />
      );
    },
    [favoriteAccounts, onSelect]
  );

  return (
    <PopoverMenuListHandler
      open={menuListOpen}
      options={options}
      getMenuItems={getItems}
      triggerElement={
        <SelectedBar>
          <TypographyWithTextEllipsis variant={"h5"}>
            {value?.label}
          </TypographyWithTextEllipsis>
          <AnimatedKeyboardArrowDown open={menuListOpen} />
        </SelectedBar>
      }
      containerProps={{
        id: "account list menu",
        "aria-label": "account list menu control",
      }}
      searchPlaceHolder={"Search account..."}
      onClose={handleClose}
      onTriggerElementClick={handleOpen}
    />
  );
};
