import React, { useState } from "react";
import { muiColors } from "@komodorio/design-system";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";

import { ContentBox, SectionAccordion } from "../../styles";
import { SectionAccordionSummary } from "../SectionAccordionSummary";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

import {
  UNHEALTHY_SERVICES_SECTION_EMPTY_MESSAGE,
  UNHEALTHY_SERVICES_SECTION_METRICS_TITLE,
  UNHEALTHY_SERVICES_SECTION_TABLE_TITLE,
  UNHEALTHY_SERVICES_SECTION_TITLE,
  UNHEALTHY_SERVICES_SECTION_VIEW_ALL_PATH,
} from "./constants";
import { OpenIssuesCountGraph } from "./OpenIssuesCountGraph";
import { TopIssuesTable } from "./TopIssuesTable";

import { useGetKomodorServicesAggregations } from "@/shared/hooks/resources-api/client/komodor-services/useGetKomodorServicesAggregations";
import {
  KomodorServicesAggregationGroupBy,
  KomodorServicesAggregationsDataType,
} from "@/generated/resourcesApi";
import { SummaryLoader } from "@/components/ClustersView/ClusterOverview/components/Summary";
import { useStoreIsFetchingForReport } from "@/components/ClustersView/ClusterOverview/useReportClusterOverviewLoadingTime";

export const UnhealthyServicesSection: React.FC<{
  clusters: string[];
}> = ({ clusters }) => {
  const isSingleCluster = clusters.length === 1;

  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const {
    data,
    isFetching: isLoadingUnhealthyServicesCount,
    isInitialLoading,
  } = useGetKomodorServicesAggregations(
    {
      clusterNames: clusters,
      groupBy: [KomodorServicesAggregationGroupBy.HealthStatus],
      type: [KomodorServicesAggregationsDataType.Services],
      unhealthyServicesOnly: true,
    },
    { staleTime: 0, keepPreviousData: true }
  );
  useStoreIsFetchingForReport(
    "isFetchingUnhealthyServicesCount",
    isLoadingUnhealthyServicesCount
  );

  const unhealthyServicesCount = data?.services.total ?? 0;

  const isEmpty = !isInitialLoading && unhealthyServicesCount === 0;
  const isDisabled = !isLoadingUnhealthyServicesCount && isEmpty;
  const isExpanded = !isEmpty && expanded;

  return (
    <SectionAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      disabled={isDisabled}
    >
      <SectionAccordionSummary
        title={UNHEALTHY_SERVICES_SECTION_TITLE}
        icon={<WarningAmberOutlined sx={{ color: muiColors.gray[500] }} />}
        isExpanded={isExpanded}
        isLoading={isLoadingUnhealthyServicesCount}
        isEmpty={isEmpty}
        emptyMessage={UNHEALTHY_SERVICES_SECTION_EMPTY_MESSAGE}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "16px",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigate(UNHEALTHY_SERVICES_SECTION_VIEW_ALL_PATH);
            }}
            aria-label={
              AriaLabels.ClusterOverview.UnhealthyServicesSection.ViewAllButton
            }
          >
            View All
          </Button>
          {isLoadingUnhealthyServicesCount ? (
            <SummaryLoader />
          ) : (
            <Chip
              label={`${unhealthyServicesCount.toLocaleString()} Unhealthy Services`}
            />
          )}
        </Box>
      </SectionAccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: "16px",
          }}
        >
          <ContentBox
            title={UNHEALTHY_SERVICES_SECTION_METRICS_TITLE}
            subtitle="Last 30 days"
          >
            <OpenIssuesCountGraph
              clusters={clusters}
              isSingleCluster={isSingleCluster}
              shouldShowLoader={isLoadingUnhealthyServicesCount}
            />
          </ContentBox>
          <ContentBox title={UNHEALTHY_SERVICES_SECTION_TABLE_TITLE}>
            <TopIssuesTable
              clusters={clusters}
              isSingleCluster={isSingleCluster}
              shouldShowLoader={isLoadingUnhealthyServicesCount}
            />
          </ContentBox>
        </Box>
      </AccordionDetails>
    </SectionAccordion>
  );
};
