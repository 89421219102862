import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/Insight";
import { getColor } from "../../../../../Status";
import EventGroup from "../../../../../../EventGroup";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import { useMinifiedMonitorEventGroupById } from "../../../../../../../monitorsView/useWorkflowsRuns";
import { NodeIssueDataParsed } from "../../common/types";

export const NODE_ISSUES_TOOLTIP = "node-issue-tooltip";

export const CorrelatedNodeIssuesTooltip: React.FC<{
  nodeIssues: NodeIssueDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  serviceId: string;
}> = ({ nodeIssues, issueStartTime, setCorrelatedEvent, serviceId }) => {
  const [eventId, setEventId] = useState<string>();
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: eventId ?? "",
    serviceId,
    type: "node",
  });
  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedIssue) {
        setCorrelatedEvent(correlatedIssue);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedIssue, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedIssue) {
      setCorrelatedEvent(correlatedIssue);
    }
  }, [correlatedIssue, setCorrelatedEvent]);
  return (
    <Tooltip id={NODE_ISSUES_TOOLTIP} variant="light">
      <InsightTooltipContainer
        aria-label={AriaLabels.AvailabilityTriage.NodeInsightTooltip}
      >
        {nodeIssues.map((issue, i) => (
          <React.Fragment key={issue.id}>
            <InsightTooltipDetails>
              <Typography>Started</Typography>
              <Typography bold>
                {formatDistance(issueStartTime, issue.startTime)} before current
                issue
              </Typography>
              <Typography>Status</Typography>
              <Typography variant="uppercase" color={getColor(issue.status)}>
                {issue.status}
              </Typography>
              <Link
                onClick={() => handleClick(issue.id)}
                aria-label={
                  AriaLabels.AvailabilityTriage.NodeInsightTooltipLink
                }
              >
                View details
              </Link>
            </InsightTooltipDetails>
            {i < nodeIssues.length - 1 && <Divider spacing="1rem" />}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
