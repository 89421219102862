import { AvailabilitySummaryOutput } from "../types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { SingleOpinionatedSummary } from "./AvailabilitySummaryUtils";
import { messages } from "./constants";

export const parseIssueSummary = (
  summary: AvailabilitySummaryOutput
): SingleOpinionatedSummary => ({
  reason: summary?.exitCode
    ? `${summary.reason} - Exit Code: ${summary.exitCode}`
    : summary.reason,
  errorMessage: summary?.errorMessage,
  count: summary?.count,
  customMessage:
    messages[
      summary?.reason + `${summary?.exitCode ? "-" + summary?.exitCode : ""}`
    ],
  containersLogs: summary?.containersLogs,
});
