import React from "react";
import { InfoCircleOutlined24 } from "@komodorio/design-system/icons";

import { EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AgentDowntimeEventGroup from "../../../EventGroup/agentDowntimeEvent/AgentDowntimeEventGroup";
import EventDetailsSection from "../components/EventDetailsSection";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";

interface AgentDowntimeEventDetailsProps {
  eventGroup: AgentDowntimeEventGroup;
}

const AgentDowntimeEventDetails: React.FC<AgentDowntimeEventDetailsProps> = ({
  eventGroup,
}) => {
  const { format } = useDateFormatter({ timeZoneName: undefined });
  const formattedStartTime = format(eventGroup.startTime);
  const formattedEndTime = format(eventGroup.endTime);
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary} />

      <EventDetailsSection
        icon={InfoCircleOutlined24}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Duration"
      >
        {eventGroup.details} between {formattedStartTime} and {formattedEndTime}
      </EventDetailsSection>
    </EventDetailsContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default AgentDowntimeEventDetails;
