import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";

import UpgradeRequired from "@/components/common/UpdateAgentModal/upgradeRequired";
import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";
import { UpgradeCommands } from "@/components/common/upgradeAgentCta/UpgradeAgentCTA";

type UpdateHelmMessageProps = {
  setUpdateMessage: (value: boolean) => void;
};

export const UpdateHelmMessage: React.FC<UpdateHelmMessageProps> = ({
  setUpdateMessage,
}) => {
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_ENABLE_HELM
  );
  return (
    <UpgradeRequired
      handleClose={() => setUpdateMessage(false)}
      updateMessage={UpdateMessage}
      upgradeCommand={commandStr}
      desiredFeature="helm"
    />
  );
};

const UpdateMessage = (
  <Typography size="medium">
    An update to the Komodor agent is required to enable Helm on this cluster.
    <br />
  </Typography>
);
