import { useQuery } from "@tanstack/react-query";

import {
  GetViolationsResponse,
  getViolationsUrl,
  ViolationsApiGetViolationsRequest,
} from "../../../../generated/reliabilityApi";
import { useReliabilityApiClient } from "../apiClient";
import { useWorkspaceQueryKey } from "../../workspaces-api/useWorkspaceQueryKey";

export const GET_VIOLATIONS_PATH = "/api/v1/violations";

export const useGetViolations = (
  params: ViolationsApiGetViolationsRequest,
  enable = true
) => {
  const apiClient = useReliabilityApiClient();
  const keys = useWorkspaceQueryKey([GET_VIOLATIONS_PATH, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient.get<GetViolationsResponse>(
        getViolationsUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    {
      enabled: enable,
    }
  );
};
