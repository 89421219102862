import { ImpactfulWorkflowIssuesByTypeQuery } from "../../../../../generated/graphql";
import WorkflowIssueEventGroup, {
  groupWorkflowIssuesEventsOld,
} from "../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../../shared/constants/systemWideServiceId";

export const getSortedGroupWorkflowIssueEvents = (
  data: ImpactfulWorkflowIssuesByTypeQuery
): WorkflowIssueEventGroup[] => {
  return groupWorkflowIssuesEventsOld(
    data.workflow_results,
    SYSTEM_WIDE_SERVICE_ID
  ).sort((a, b) => b.endTime.getTime() - a.endTime.getTime());
};
