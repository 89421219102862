import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  Agent,
  AgentsApiApiV1AgentsGetRequest,
  apiV1AgentsGetUrl,
} from "@/generated/agents";
import { useAgentsApiClient } from "@/shared/hooks/agents-api/apiClient";

const AGENTS_PATH = "/api/v1/agents";

const getAgents = async (
  apiClient: AxiosInstance,
  params: AgentsApiApiV1AgentsGetRequest
): Promise<Agent[]> => {
  const { data } = await apiClient.get(
    apiV1AgentsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

const generateKeys = (params: AgentsApiApiV1AgentsGetRequest) => [
  AGENTS_PATH,
  ...Object.entries(params).map(([key, value]) => `${key}-${value}`),
];

export const useListAgents = ({
  enabled,
  params,
}: {
  enabled?: boolean;
  params: AgentsApiApiV1AgentsGetRequest;
}) => {
  const apiClient = useAgentsApiClient();
  const keys = generateKeys(params);
  return useQuery(keys, () => getAgents(apiClient, params), {
    enabled,
  });
};
