import { useMemo } from "react";

import {
  OptimizationStrategy,
  RightSizingCostSummaryByService,
} from "../../../../../../generated/metricsApi";
import { getRecommendationData } from "../components/RecommendationContainer/getRecommendationData";

type Props = {
  containersData?: RightSizingCostSummaryByService[];
  modalStrategy: OptimizationStrategy;
};

export const useGetNumOfUpdatedContainers = ({
  containersData,
  modalStrategy,
}: Props): number | undefined => {
  return useMemo(() => {
    if (!containersData) return;

    return containersData.filter((container) => {
      const {
        currentCpu,
        cpuRecommendation,
        currentMemory,
        memoryRecommendation,
      } = getRecommendationData(container, modalStrategy);

      return (
        cpuRecommendation < currentCpu || memoryRecommendation < currentMemory
      );
    }).length;
  }, [containersData, modalStrategy]);
};
