import React, { useMemo } from "react";
import ReactDiffViewer, {
  DiffMethod,
  ReactDiffViewerProps,
  ReactDiffViewerStylesOverride,
} from "react-diff-viewer";
import YAML from "yaml";
import stringify from "json-stable-stringify";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const diffStyles = {
  diffContainer: { wordBreak: "break-all" },
} as const;

const DiffViewer = ({
  oldValue,
  newValue,
  overridingStyles,
  overridingProps,
}: {
  oldValue: string;
  newValue: string;
  overridingStyles?: ReactDiffViewerStylesOverride;
  overridingProps?: Omit<ReactDiffViewerProps, "oldValue" | "newValue">;
}) => (
  <ReactDiffViewer
    oldValue={oldValue}
    newValue={newValue}
    disableWordDiff
    compareMethod={DiffMethod.LINES}
    styles={{ ...diffStyles, ...overridingStyles }}
    {...overridingProps}
  />
);

type ObjectDiffProps = {
  oldObj: unknown;
  newObj: unknown;
  overridingStyles?: ReactDiffViewerStylesOverride;
  overridingProps?: Omit<ReactDiffViewerProps, "oldValue" | "newValue">;
};
const ObjectDiffAsYaml: React.FC<ObjectDiffProps> = ({
  oldObj,
  newObj,
  overridingStyles,
  overridingProps,
}) => {
  const oldValue = useMemo(
    () => YAML.stringify(oldObj, { indent: 2 }),
    [oldObj]
  );
  const newValue = useMemo(
    () => YAML.stringify(newObj, { indent: 2 }),
    [newObj]
  );
  return (
    <DiffViewer
      oldValue={oldValue}
      newValue={newValue}
      overridingStyles={overridingStyles}
      overridingProps={overridingProps}
    />
  );
};

const ObjectDiff: React.FC<ObjectDiffProps> = ({
  oldObj,
  newObj,
  overridingStyles,
  overridingProps,
}) => {
  const oldValue = useMemo(() => stringify(oldObj, { space: 2 }), [oldObj]);
  const newValue = useMemo(() => stringify(newObj, { space: 2 }), [newObj]);
  return (
    <DiffViewer
      oldValue={oldValue}
      newValue={newValue}
      overridingStyles={overridingStyles}
      overridingProps={overridingProps}
    />
  );
};

export { ObjectDiffAsYaml, ObjectDiff };
