import React, { useMemo } from "react";
import { Divider } from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";

import { FlexColumn } from "../../../../../../../InvestigationMode/availability/common/styles";
import { ContainerLog } from "../../../../../../../monitorsView/workflowRunView/checks/availability/types";
import { useIsMetricsDrawerSupported } from "../../../../../../../Metrics/metricsHooks";
import { Metrics } from "../../../../../../../Metrics/Metrics";
import { usePodMetrics } from "../../../../../../../Metrics/usePodMetrics";
import { SectionColumn, SectionName } from "../../common/styles";
import { useOverridableFlags } from "../../../../../../../../shared/context/featureFlags/OverridableFlags";
import { MetricsGraphType } from "../../../../../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../../../../../../InvestigationMode/metricsHooks";

const FailedContainerMetricsSection: React.FC<{
  failedContainer: ContainerLog;
  eventTime: Date;
  clusterName: string;
  namespace: string;
}> = ({ failedContainer, eventTime, clusterName, namespace }) => {
  const isMetricsSupported = useIsMetricsDrawerSupported(clusterName);
  const metricsAggType = useMetricsDefaultAggregationType();

  const eventTimeMilli = useMemo(() => eventTime.getTime(), [eventTime]);
  const podMetrics = usePodMetrics(
    clusterName,
    namespace,
    failedContainer?.podName ?? "",
    failedContainer?.name ?? "",
    eventTimeMilli,
    !!isMetricsSupported
  );
  const { metricsAvailabilityDrawer } = useOverridableFlags();

  const hideMetricsSection = useMemo(() => {
    return (
      !metricsAvailabilityDrawer ||
      !clusterName ||
      !namespace ||
      !failedContainer?.podName ||
      !failedContainer?.name ||
      podMetrics.memoryOneDay.metrics.usage.length === 0 ||
      podMetrics.cpuOneDay.metrics.usage.length === 0
    );
  }, [
    metricsAvailabilityDrawer,
    clusterName,
    namespace,
    failedContainer?.podName,
    failedContainer?.name,
    podMetrics.memoryOneDay.metrics.usage.length,
    podMetrics.cpuOneDay.metrics.usage.length,
  ]);

  if (hideMetricsSection) {
    return null;
  }
  return (
    <>
      <SectionColumn>
        <SectionName>failed container metrics</SectionName>
        <FlexColumn>
          <Typography>Container: {failedContainer.name}</Typography>
          <Metrics
            isMetricsSupported={!!isMetricsSupported}
            endTimestamp={eventTimeMilli}
            metrics={podMetrics}
            graphType={MetricsGraphType.CONTAINER}
            aggregationType={metricsAggType}
          />
        </FlexColumn>
      </SectionColumn>
      <Divider />
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default FailedContainerMetricsSection;
