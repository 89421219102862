import React, { ChangeEvent } from "react";
import { TextInput } from "@komodorio/design-system/komodor-ui";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import Box from "@mui/material/Box";

import { CIDRWhitelist } from "@/generated/accounts";
import { whitelistAriaLabels } from "@/components/Settings/whitelistCIDR/whitelistAriaLabels";

const StyledContainer = styled.div`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    .textArea {
      flex: 1;
    }
  }
`;

const IconButtonPlaceHolder = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

interface WhitelistLineProps {
  index: number;
  values: CIDRWhitelist;
  handleCIDRChange: (
    index: number,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof CIDRWhitelist
  ) => void;
  errors: { [key: string]: string | undefined };
  validator: (cidr: string, index: number) => void;
  removeCIDRGroup: (index: number) => void;
  isLast: boolean;
}

export const WhitelistRow: React.FC<WhitelistLineProps> = ({
  index,
  values,
  handleCIDRChange,
  validator,
  errors,
  removeCIDRGroup,
  isLast,
}) => {
  return (
    <StyledContainer aria-label={whitelistAriaLabels.rowCIDR}>
      <TextInput
        size="medium"
        label={index == 0 ? "CIDR" : ""}
        placeholder="e.g. 192.168.0.0/16"
        value={values.cidr}
        onChange={(e) => {
          handleCIDRChange(index, e, "cidr");
          validator(e.target.value, index);
        }}
        error={!!errors[index]}
        fullWidth
        helperText={errors[index] ?? undefined}
        aria-label={whitelistAriaLabels.rowEditCIDR}
        className="textArea"
      />
      <TextInput
        size="medium"
        label={index === 0 ? "Description (optional)" : ""}
        placeholder="e.g. vpn"
        value={values.description}
        onChange={(e) => handleCIDRChange(index, e, "description")}
        helperText={errors[index] ? " " : undefined}
        fullWidth
        aria-label={whitelistAriaLabels.rowEditDescription}
        className="textArea"
      />
      <IconButtonPlaceHolder>
        {!isLast && (
          <Box
            sx={{
              marginTop: index === 0 ? "20px" : "0", // Adjust the margin for the first button
            }}
          >
            <IconButton
              onClick={() => {
                removeCIDRGroup(index);
              }}
              sx={{
                marginRight: "30px",
                marginTop: errors[index] ? "-17px" : "0", // adjust button when error on input
              }}
              aria-label={whitelistAriaLabels.rowDeleteButton}
            >
              <DeleteIcon fontSize="small" color={"error"} />
            </IconButton>
          </Box>
        )}
      </IconButtonPlaceHolder>
    </StyledContainer>
  );
};
