import { useTablePaginationInUrl } from "@/components/k8sAddons/hooks/table/useTablePaginationInUrl";

export const useCommonLiveStateTableProps = (isFetchingData: boolean) => {
  const { sortModel, paginationModel, setPaginationModel, setSortModel } =
    useTablePaginationInUrl();

  return {
    isFetchingData,
    pageSize: paginationModel.pageSize,
    paginationModel,
    sortModel,
    onSortModelChange: setSortModel,
    onPaginationModelChange: setPaginationModel,
  };
};
