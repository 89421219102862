import { useCallback, useEffect, useState } from "react";

export const useIsAwaitingNamespacesOnClusterChange = (
  namespaces: string[] | undefined
) => {
  const [isAwaitingNamespacesChange, setIsAwaitingNamespacesChange] =
    useState(false);

  const onClusterChange = useCallback(() => {
    setIsAwaitingNamespacesChange(true);
  }, []);

  useEffect(() => {
    // assert namespaces from previous cluster are cleared and new namespaces are being fetched
    if (isAwaitingNamespacesChange && namespaces === undefined) {
      setIsAwaitingNamespacesChange(false);
    }
  }, [isAwaitingNamespacesChange, namespaces]);

  return {
    isAwaitingNamespacesChange,
    onClusterChangeCb: onClusterChange,
  };
};
