import { RefObject, useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { hasTextEllipsis } from "../utils/hasTextEllipsis";

import { useResizeObserver } from "./useResizeObserver";

export const useHasTextEllipsis = (
  containerRef: RefObject<HTMLDivElement>
): boolean => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onContainerResize = useCallback(() => {
    if (!containerRef.current) return;
    setShowTooltip(hasTextEllipsis(containerRef.current));
  }, [containerRef]);

  const { callback: debouncedOnContainerResize } = useDebouncedCallback(
    onContainerResize,
    300
  );

  useResizeObserver(containerRef, debouncedOnContainerResize);

  return showTooltip;
};
