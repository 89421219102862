import React from "react";
import { useNavigate } from "react-router-dom";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { Airflow } from "@komodorio/design-system/icons/logos/Airflow";
import { Argo } from "@komodorio/design-system/icons/logos/Argo";
import { Workflows24 } from "@komodorio/design-system/icons";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import Tab from "@mui/material/Tab";

import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { WorkflowsRoutes } from "@/components/k8sAddons/addons/workflows/types";

const tooltipText = (engineName: string) =>
  `Connect a cluster with ${engineName} to begin using this feature`;

export const WorkflowsTabs: React.FC<{
  tabValue: WorkflowsRoutes;
  engines: Record<WorkflowsRoutes, boolean>;
  children: React.ReactNode;
}> = ({ tabValue, engines, children }) => {
  const navigate = useNavigate();

  const onTabChange = (_: React.SyntheticEvent, newValue: WorkflowsRoutes) => {
    if (tabValue === newValue) return;
    navigate(`../${newValue}`);
  };

  return (
    <ResponsiveLayout>
      <TabContext value={tabValue}>
        <TabList onChange={onTabChange} sx={{ marginTop: "16px" }}>
          <Tab
            icon={<Airflow />}
            iconPosition="start"
            label={
              <LightTooltip
                title={
                  !engines[WorkflowsRoutes.airflow]
                    ? tooltipText("Airflow")
                    : ""
                }
                slotProps={{
                  popper: { sx: { maxWidth: "225px" } },
                }}
                placement="top"
              >
                <div>Airflow</div>
              </LightTooltip>
            }
            disabled={!engines[WorkflowsRoutes.airflow]}
            value={WorkflowsRoutes.airflow}
            sx={{
              minHeight: "unset",
              "&.MuiTab-root.Mui-disabled": { pointerEvents: "auto" },
            }}
            aria-label={`${WorkflowsRoutes.airflow} tab`}
          />
          <Tab
            icon={<Argo />}
            iconPosition="start"
            label={
              <LightTooltip
                title={
                  !engines[WorkflowsRoutes.argo]
                    ? tooltipText("Argo Workflow")
                    : ""
                }
                slotProps={{
                  popper: { sx: { maxWidth: "220px" } },
                }}
                placement="top"
              >
                <div>Argo Workflow</div>
              </LightTooltip>
            }
            disabled={!engines[WorkflowsRoutes.argo]}
            value={WorkflowsRoutes.argo}
            sx={{
              minHeight: "unset",
              "&.MuiTab-root.Mui-disabled": { pointerEvents: "auto" },
            }}
            aria-label={`${WorkflowsRoutes.argo} tab`}
          />
          <Tab
            icon={<Workflows24 />}
            iconPosition="start"
            label="Custom Workflow"
            value={WorkflowsRoutes.custom}
            sx={{ minHeight: "unset" }}
            aria-label={`${WorkflowsRoutes.custom} tab`}
          />
        </TabList>
      </TabContext>
      {children}
    </ResponsiveLayout>
  );
};
