import { useMemo } from "react";

import {
  CostRightSizingSummary,
  RightSizingCostSummaryResponse,
} from "../../../../../../../../../generated/metricsApi";
import { CostSavingColumns } from "../../shared/types";
import { getTwoDecimalsNumber } from "../../../../../../../../../shared/utils/formatNumber";
import {
  formatCompactNumber,
  getGenericTableColumnRow,
} from "../../shared/utils/utils";

export const useGetPotentialSavingDrawerDescription = (
  data: RightSizingCostSummaryResponse | undefined
) =>
  useMemo(() => {
    if (!data?.monthlySaving) return "";
    return `Monthly potential cost savings of $${formatCompactNumber(
      data.monthlySaving
    )}`;
  }, [data]);

const getRightSizingColumnToGenericTableColumn = ({
  service,
  namespace,
  clusterName,
  komodorServiceId,
}: CostRightSizingSummary) => {
  return {
    cluster: clusterName,
    namespace: namespace,
    komodorServiceName: service,
    komodorServiceId: komodorServiceId,
  };
};

export const getPotentialCostSavingRows = (
  rows: CostRightSizingSummary[]
): CostSavingColumns[] => {
  return rows
    .filter((row) => getTwoDecimalsNumber(row.potentialSaving) > 0)
    .map((row) => ({
      ...getGenericTableColumnRow(
        getRightSizingColumnToGenericTableColumn(row)
      ),
      komodorServiceKind: row.komodorServiceKind,
      potentialSavings: row.potentialSaving,
      totalCost: {
        totalCost: row.memoryCost + row.cpuCost,
        totalCostTrendPercentage: 0,
      },
    }));
};
