import { Button } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

const StepsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
`;

const Footer: React.FC<{
  backData: {
    label: string;
    action: () => void;
  };
  nextData?: {
    label: string;
    action: () => void;
  };
  isNextDisabled?: boolean;
}> = ({ backData, nextData, isNextDisabled = false }) => {
  return (
    <StepsButtonsContainer>
      <Button variant="secondary" onClick={backData.action}>
        {backData.label}
      </Button>
      {nextData && (
        <Button
          variant="primary"
          onClick={nextData.action}
          disabled={isNextDisabled}
        >
          {nextData.label}
        </Button>
      )}
    </StepsButtonsContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default Footer;
