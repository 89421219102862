import { IntercomEffectsStore } from "./intercomEffectsStore";

// getters
export const unreadMessagesCountSelector = (
  state: IntercomEffectsStore
): number => state.unreadMessagesCount;

// setters
export const setUnreadMessagesCountSelector = (
  state: IntercomEffectsStore
): ((count: number) => void) => state.setUnreadMessagesCount;
