type GetResourceIdentificationParams = {
  clusterName: string;
  namespace: string;
  resourceName: string;
  k8sResourceName: string;
};

export const getResourceIdentification = ({
  clusterName,
  resourceName,
  k8sResourceName,
  namespace,
}: GetResourceIdentificationParams): string => {
  if (k8sResourceName === "nodes") {
    return resourceName;
  }
  return `${clusterName}.${namespace}.${resourceName}`;
};
