import { PolicyConfigurations, PolicyScope } from "@/generated/reliabilityApi";

export type FormDetailsFields =
  | "name"
  | "priority"
  | "description"
  | "scope.clusterRegexes";

export type InputFields = {
  name: string;
  priority: number;
  description: string;
  scope: PolicyScope;
} & { configurations: PolicyConfigurations };

export type ServerFieldError = {
  message: string;
  field: string;
};

export type ServerError = {
  message: string;
  fieldErrors?: ServerFieldError[];
};

export type SubmitPolicyType = "create" | "update";

export type SeverityValues = {
  highValues?: unknown[];
  mediumValues?: unknown[];
  lowValues?: unknown[];
};

export type FormConfigurations = {
  configurations: PolicyConfigurations;
};

export const PolicyConfigurationsTabs = {
  dynamic: "Dynamic",
  static: "Static",
} as const;

type TypeofPolicyConfigurationsTabs = typeof PolicyConfigurationsTabs;

export type PolicyConfigurationsTabs =
  TypeofPolicyConfigurationsTabs[keyof typeof PolicyConfigurationsTabs];

export type DisplayMode = "view" | "edit";
