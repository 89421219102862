import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { DashedText } from "../styles";

import { BlastIcon } from "./assets/BlastIcon";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
`;

export const InsightText = styled(Typography).attrs({
  size: "large",
  variant: "text",
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.25rem;
`;

export const BoldText = styled(DashedText)`
  font-size: 1rem;
`;

export const BlastDemoInsight: React.FC<{
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}> = ({ children, onClick }) => (
  <Flex onClick={onClick}>
    <BlastIcon color={palette.orange[400]} />
    <InsightText>{children}</InsightText>
  </Flex>
);

export const LightningBoltDemoInsight: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Flex>
    <LightningBolt16 color={palette.orange[400]} />
    <InsightText>{children}</InsightText>
  </Flex>
);
