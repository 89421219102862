import { RefObject, useLayoutEffect } from "react";

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback: (entry: ResizeObserverEntry) => void
) => {
  useLayoutEffect(() => {
    const element = ref?.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      callback(entries[0]);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);

  return ref;
};
