import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  WorkspacesResponse,
  apiV1WorkspacesGetUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspacesApiClient } from "../apiClient";
import { convertWorkspaceToTypedWorkspace } from "../utils";

export const WORKSPACES_PATH = "/workspaces";

const fetchWorkspaces = async (
  apiClient: AxiosInstance
): Promise<WorkspacesResponse> => {
  const { data } = await apiClient.get<WorkspacesResponse>(
    apiV1WorkspacesGetUrl({}, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetWorkspaces = (options?: { enabled?: boolean }) => {
  const apiClient = useWorkspacesApiClient();

  return useQuery([WORKSPACES_PATH], () => fetchWorkspaces(apiClient), {
    ...options,
    select: (data) => data.data.map(convertWorkspaceToTypedWorkspace),
  });
};
