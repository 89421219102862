import { useCallback } from "react";

import { useTimeWindowToEpoch } from "../../../hooks/useTimeWindowToEpoch";
import {
  GREATER_THAN_FILTER_OPERATOR,
  LESS_THAN_FILTER_OPERATOR,
} from "../filterSectionConstants";

import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import {
  GenericViolationFilterCategory,
  ViolationCountBy,
} from "@/generated/reliabilityApi";

type AddCreatedAtFilterParams = {
  filter: FilterOptionInUrl;
  filterCategory: ViolationCountBy;
};

export const useAddCreatedAtFilter = () => {
  const timeWindowToEpoch = useTimeWindowToEpoch();
  return useCallback(
    ({ filter, filterCategory }: AddCreatedAtFilterParams) => {
      const filtersList: GenericViolationFilterCategory[] = [];
      const { createdFromEpoch, createdToEpoch } =
        timeWindowToEpoch(filter.values[0]) ?? {};
      createdFromEpoch &&
        filtersList.push({
          category: filterCategory,
          value: [createdFromEpoch.toString()],
          operator: GREATER_THAN_FILTER_OPERATOR,
        });
      createdToEpoch &&
        filtersList.push({
          category: filterCategory,
          value: [createdToEpoch.toString()],
          operator: LESS_THAN_FILTER_OPERATOR,
        });

      return filtersList;
    },
    [timeWindowToEpoch]
  );
};
