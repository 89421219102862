import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridRenderCellParams,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";

import { formatCurrencyIfSmallerThanOne } from "../../../../../shared/utils/formatCurrency";
import { formatPercentage } from "../../../../../shared/utils/formatPercentage";
import { SaveButton } from "../../shared/tableComponents/SaveButton";
import {
  RIGHT_SIZING_TABLE_COLUMNS,
  COST_UTILIZATION_TOOLTIP_TEXT,
} from "../../../constants/costOptimizationConstants";
import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import { CostRightSizingSummary } from "../../../../../generated/metricsApi";
import { selectRightSizingSummaryState } from "../../../store/costOptimizationStoreSelectors";
import { costOptimizationAriaLabels } from "../../../constants/costOptimizationAriaLabels";
import { useOnTableCellClick } from "../../../hooks/tableHooks";
import { ServiceTextLink } from "../../shared/ServiceTextLink";
import { EllipsisContainerWithTooltip } from "../../../../../shared/components/EllipsisContainerWithTooltip";
import { useRightSizingRows } from "../hooks/rightSizingPaginatedTableHooks";
import { useRightSizingTablePaginationMode } from "../hooks/commonHooks";

import { ValueToValueCell } from "./ValueToValueCell";

const ALLOWED_FILTERS = ["equals"];

export const useRightSizingTableColumns = (
  restrictFilterOperators?: boolean
) => {
  const onTableCellClick = useOnTableCellClick();

  const {
    clusterName,
    namespace,
    service,
    cpuRequestCores,
    memoryRequestsMiB,
    optimizationScore,
    potentialSaving,
    komodorServiceKind,
  } = RIGHT_SIZING_TABLE_COLUMNS;

  return useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: clusterName.field,
        headerName: clusterName.name,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <EllipsisContainerWithTooltip tooltipValue={params.value} />;
        },
      },
      {
        field: namespace.field,
        headerName: namespace.name,
        renderCell: (params: GridRenderCellParams) => {
          return <EllipsisContainerWithTooltip tooltipValue={params.value} />;
        },
        flex: 1,
      },
      {
        field: service.field,
        headerName: service.name,
        renderCell: (params: GridRenderCellParams<CostRightSizingSummary>) => {
          return (
            <EllipsisContainerWithTooltip tooltipValue={params.value}>
              <ServiceTextLink
                id={params.row.komodorServiceId}
                serviceName={params.value}
                serviceKind={params.row.komodorServiceKind}
              />
            </EllipsisContainerWithTooltip>
          );
        },
        flex: 1,
      },
      {
        field: komodorServiceKind.field,
        headerName: komodorServiceKind.name,
        flex: 1,
      },
      {
        field: cpuRequestCores.field,
        headerName: cpuRequestCores.name,
        description: cpuRequestCores?.fullName,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CostRightSizingSummary>) => (
          <ValueToValueCell
            value1={params.value}
            value2={params.row.cpuRecommendation}
          />
        ),
        filterable: false,
        sortable: false,
      },
      {
        field: memoryRequestsMiB.field,
        headerName: memoryRequestsMiB.name,
        description: memoryRequestsMiB?.fullName,
        minWidth: 125,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CostRightSizingSummary>) => (
          <ValueToValueCell
            value1={params.value}
            value2={params.row.memoryRecommendation}
            isAbbreviated={true}
          />
        ),
        filterable: false,
        sortable: false,
      },
      {
        field: optimizationScore.field,
        headerName: optimizationScore.name,
        description: COST_UTILIZATION_TOOLTIP_TEXT,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CostRightSizingSummary>) => (
          <Typography variant={"h5"}>
            {formatPercentage(params.value)}
          </Typography>
        ),
        filterable: false,
      },
      {
        field: potentialSaving.field,
        headerName: potentialSaving.name,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams) =>
          params.value <= 0 ? null : (
            <SaveButton
              onClick={() => onTableCellClick(params)}
              aria-label={
                costOptimizationAriaLabels.rightSizingPage.table.savingButton
              }
            >
              Save {formatCurrencyIfSmallerThanOne(params.value)}
            </SaveButton>
          ),
        filterable: false,
      },
    ];

    if (restrictFilterOperators) {
      restrictColumnsToAllowedOperators(columns);
    }

    return columns;
  }, [
    clusterName.field,
    clusterName.name,
    cpuRequestCores.field,
    cpuRequestCores?.fullName,
    cpuRequestCores.name,
    komodorServiceKind.field,
    komodorServiceKind.name,
    memoryRequestsMiB.field,
    memoryRequestsMiB?.fullName,
    memoryRequestsMiB.name,
    namespace.field,
    namespace.name,
    onTableCellClick,
    optimizationScore.field,
    optimizationScore.name,
    potentialSaving.field,
    potentialSaving.name,
    restrictFilterOperators,
    service.field,
    service.name,
  ]);
};

export const useGetRowData = (
  rowId: string | undefined
): CostRightSizingSummary | undefined => {
  const paginationMode = useRightSizingTablePaginationMode();
  const storeData = useCostOptimizationStore(selectRightSizingSummaryState);
  const paginationData = useRightSizingRows(false);
  const res = paginationMode ? paginationData?.data : storeData;
  return useMemo(() => {
    return res?.data?.rows?.find(
      (row: CostRightSizingSummary) => row.id === rowId
    );
  }, [res?.data?.rows, rowId]);
};

export const restrictColumnsToAllowedOperators = (columns: GridColDef[]) => {
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ALLOWED_FILTERS.includes(value)
  );

  columns.forEach((column) => {
    column["filterOperators"] = filterOperators;
  });
};
