import React, { useCallback } from "react";
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { muiPalette, palette } from "@komodorio/design-system";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { RbacCustomK8sAction } from "../../../generated/auth";
import { useDeleteRbacCustomK8sAction } from "../../../shared/hooks/auth-service/client/rbacActions/useDeleteRbacK8sCustomAction";
import { FormError } from "../styles";
import { LinesLoader } from "../../common/loaders/Line";
import { useGetActionUsedByPoliciesRbacCustomK8sActions } from "../../../shared/hooks/auth-service/client/rbacActions/useGetActionUsedByPoliciesRbacK8sCustomActions";

const policyWordSingular = "policy";
const policyWordPlural = "policies";

const DeleteModalContent: React.FC<{
  policiesUsedByAction?: string[];
}> = ({ policiesUsedByAction }) => {
  const navigate = useNavigate();

  const isInUse = policiesUsedByAction && policiesUsedByAction?.length > 0;
  const policyWord =
    policiesUsedByAction && policiesUsedByAction?.length > 1
      ? policyWordSingular
      : policyWordPlural;

  const goToFilteredPolicies = useCallback(() => {
    navigate(`/settings/policies?q=${policiesUsedByAction?.join("|")}`);
  }, [navigate, policiesUsedByAction]);

  if (isInUse) {
    return (
      <Box>
        <Typography variant={"body1"}>
          {`This action is attached to ${policiesUsedByAction.length} ${policyWord}:`}
        </Typography>
        <TextareaAutosize
          value={policiesUsedByAction?.join("\n")}
          minRows={5}
          maxRows={5}
          style={{
            width: "100%",
            resize: "none",
            margin: "0.5rem 0 1.5rem 0",
            lineHeight: "20px",
            background: palette.gray[50],
          }}
          readOnly={true}
        />
        <Box display="flex" flexDirection="row">
          <WarningAmberOutlined
            color={"action"}
            sx={{ marginRight: "0.5rem" }}
          />
          <Box>
            <Typography variant="body1">
              To delete this action, you'll first need to remove it from the
              attached policies
            </Typography>
            <Button
              variant="outlined"
              style={{ marginTop: "0.5rem" }}
              onClick={goToFilteredPolicies}
            >
              View policies
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant={"body1"}>
        {`This action is not attached to any ${policyWord}.`}
      </Typography>
      <Typography variant="subtitle2">
        Are you sure you want to{" "}
        <Typography color={muiPalette.error?.main} component={"span"}>
          permanently delete
        </Typography>{" "}
        this action?
      </Typography>
    </Box>
  );
};

export const DeleteCustomActionModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  element: RbacCustomK8sAction;
  refetch: () => void;
}> = ({ open, handleClose, element, refetch }) => {
  const {
    data: policiesUsedByAction,
    isLoading: usedByIsLoading,
    error: usedByError,
  } = useGetActionUsedByPoliciesRbacCustomK8sActions({
    action: element.action,
  });

  const {
    mutateAsync: deleteAction,
    isLoading: deleteLoading,
    isError: deleteError,
  } = useDeleteRbacCustomK8sAction();

  const handleDelete = async () => {
    let success = false;
    try {
      await deleteAction({ id: element.id });
      success = true;
    } catch (err) {
      return;
    } finally {
      dispatchEvent(AnalyticEvents.Settings.DeleteAction, {
        actionName: element.action,
        success,
      });
    }
    refetch();
    handleClose();
  };

  const allowedToDelete =
    !usedByIsLoading && !usedByError && policiesUsedByAction?.length === 0;

  return (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="h3">Delete action?</Typography>
        <Typography variant="subtitle2">{element.action}</Typography>
      </ModalHeader>
      <ModalContent style={{ minWidth: "600px", width: "25vw" }}>
        {usedByIsLoading && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2">Checking for usages...</Typography>
            <LinesLoader />
          </Box>
        )}
        {!!usedByError && (
          <FormError
            errMsg={
              "Failed to validate if action is used by policies. Please try again or contact support for assistance."
            }
            isBoxStyle
          />
        )}
        {!usedByIsLoading && !usedByError && (
          <DeleteModalContent policiesUsedByAction={policiesUsedByAction} />
        )}
        {deleteError && (
          <FormError
            errMsg={
              "Failed to delete this action. Please try again or contact support for assistance."
            }
            isBoxStyle
          />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            color: muiPalette.primary?.contrastText,
          }}
          color={"error"}
          onClick={handleDelete}
          disabled={!allowedToDelete}
        >
          {deleteLoading ? "Deleting..." : "Delete"}
        </Button>
      </ModalActions>
    </Modal>
  );
};
