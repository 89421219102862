import { useMemo, useState } from "react";

import { useAppInsightsAPIGet } from "../../../../../../../shared/hooks/app-insights-api/client";
import {
  AVAILABILITY_ISSUES_ENDPOINT,
  BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT,
  BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT,
  BEST_PRACTICES_SECURITY_ENDPOINT,
  EndpointsResponseMap,
  FAILED_DEPLOYMENTS_ENDPOINT,
} from "../../../../../../../shared/hooks/app-insights-api/requestResponseMaps";
import { getBucketTimeFrame } from "../utils/overviewPageUtils";
import {
  mockBestPracticesLivenessProbesMissing,
  mockBestPracticesMissingRequestsOrLimits,
  mockBestPracticesSecurity,
} from "../../../__tests__/mocks/overviewPage/mockAppInsightsBestPractices";
import { AppInsightsApiResponse } from "../types/overviewPageTypes";
import {
  AvailabilityIssuesResponse,
  FailedDeploymentsResponse,
  LivenessProbesMissingBestPracticesResponse,
  MissingRequestsOrLimitsBestPracticesResponse,
  SecurityBestPracticesResponse,
} from "../../../../../../../generated/applicationInsights";
import { initialResponse } from "../store/initialState";
import { generateMockAvailabilityIssuesTrends } from "../../../__tests__/mocks/overviewPage/mockAppInsightsAvailabilityIssues";
import { EndpointsResponseType } from "../../../../../../../shared/hooks/common-api/types";
import { generateMockFailedDeploymentsTrends } from "../../../__tests__/mocks/overviewPage/mockAppInsightsFailedDeplyments";
import { isDevMode } from "../../../../../../../shared/utils/isDevMode";

import {
  useSetMockDataEffect,
  useShouldPauseRequest,
  useTimeWindowAsRequestParam,
  useUpdateAppInsightsData,
} from "./fetchDataHooks";

export const useFetchAppInsightsData = () => {
  useFetchBestPracticesLivenessProbesMissing();
  useFetchSecurityBestPractices();
  useFetchBestPracticesMissingRequestsOrLimits();
  useFetchAvailabilityIssues();
  useFetchFailedDeploymentsTrends();
};

const useMockData = <T extends object>(mockData?: T) => {
  const [res, setRes] = useState<AppInsightsApiResponse<T>>(initialResponse);
  useSetMockDataEffect({ mockData, setRes });
  return res;
};

const useApiWithTimeWindow = <
  M extends { [key in keyof EndpointsResponseType]: M },
  T extends keyof EndpointsResponseMap = keyof EndpointsResponseMap
>(
  relativePath: T
): AppInsightsApiResponse<M> => {
  const timeWindow = useTimeWindowAsRequestParam();
  const bucketTimeFrame = getBucketTimeFrame();
  const shouldPause = useShouldPauseRequest();

  return useAppInsightsAPIGet<M>(
    relativePath,
    { ...timeWindow, bucketTimeFrame },
    shouldPause
  );
};

export const useFetchBestPracticesLivenessProbesMissing = () => {
  // simulate server responses
  const timeWindow = useTimeWindowAsRequestParam();
  const mockResponse = useMemo(
    () =>
      timeWindow && isDevMode()
        ? mockBestPracticesLivenessProbesMissing()
        : undefined,
    [timeWindow]
  );
  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes =
    useApiWithTimeWindow<LivenessProbesMissingBestPracticesResponse>(
      BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT
    );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("bestPracticesLivenessProbesMissing", dataToUse);
};

export const useFetchSecurityBestPractices = () => {
  // simulate server responses
  const timeWindow = useTimeWindowAsRequestParam();
  const mockResponse = useMemo(
    () => (timeWindow && isDevMode() ? mockBestPracticesSecurity() : undefined),
    [timeWindow]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes = useApiWithTimeWindow<SecurityBestPracticesResponse>(
    BEST_PRACTICES_SECURITY_ENDPOINT
  );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("securityBestPractices", dataToUse);
};

export const useFetchBestPracticesMissingRequestsOrLimits = () => {
  // simulate server responses
  const timeWindow = useTimeWindowAsRequestParam();
  const mockResponse = useMemo(
    () =>
      timeWindow && isDevMode()
        ? mockBestPracticesMissingRequestsOrLimits()
        : undefined,
    [timeWindow]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes =
    useApiWithTimeWindow<MissingRequestsOrLimitsBestPracticesResponse>(
      BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT
    );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("bestPracticesMissingRequestsOrLimits", dataToUse);
};

export const useFetchAvailabilityIssues = () => {
  const timeWindow = useTimeWindowAsRequestParam();

  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode()
        ? generateMockAvailabilityIssuesTrends(timeWindow)
        : undefined,
    [timeWindow]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes = useApiWithTimeWindow<AvailabilityIssuesResponse>(
    AVAILABILITY_ISSUES_ENDPOINT
  );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("availabilityIssues", dataToUse);
};

export const useFetchFailedDeploymentsTrends = () => {
  const timeWindow = useTimeWindowAsRequestParam();

  // simulate server responses
  const mockResponse = useMemo(
    () =>
      isDevMode() ? generateMockFailedDeploymentsTrends(timeWindow) : undefined,
    [timeWindow]
  );

  const mockData = useMockData(mockResponse);
  /////////////////////////////*/

  const serverRes = useApiWithTimeWindow<FailedDeploymentsResponse>(
    FAILED_DEPLOYMENTS_ENDPOINT
  );

  const dataToUse = isDevMode() ? mockData : serverRes;
  useUpdateAppInsightsData("failedDeployments", dataToUse);
};
