import { muiColors, palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import { IssuesAfterDeployType } from "../../models/IssuesAfterDeploy";
import DaemonSet from "../../../ResourceView/resources/daemonset";
import Deployment from "../../../ResourceView/resources/deployment";
import StatefulSet from "../../../ResourceView/resources/statefulset";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useIssuesAfterDeployGraphFetch } from "./issuesAfterDeployHooks";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import IssuesAfterDeployGraphChart from "./IssuesAfterDeployGraphChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 216px;
  background-color: white;
  padding: 0 1rem;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

export const DATE_KEY = "dateKey";
export const VALUE_KEY = "value";
export const LAST_VALUE_KEY = "lastValue";
export const LAST_VALUE_LABEL = "lastValueLabel";
export const AVG_TEXT = "avgText";
export const AVG_LABEL = "avgLabel";

export interface IssuesAfterDeployGraphProps {
  issuesAfterDeploy: IssuesAfterDeployType;
  resource: Deployment | DaemonSet | StatefulSet;
}

const IssuesAfterDeployGraph: React.FC<IssuesAfterDeployGraphProps> = ({
  issuesAfterDeploy,
  resource,
}) => {
  const { abnormalAmount, abnormalDuration } = useIssuesAfterDeployGraphFetch({
    issuesAfterDeploy,
    resource,
  });

  return (
    <Container>
      {issuesAfterDeploy.count_triggered && (
        <GraphContainer>
          <br />
          <Typography
            size="medium"
            variant="title"
            color={palette.gray["800"]}
          >{`Average number of Availability Issues (${issuesAfterDeploy.num_days}d)`}</Typography>
          <IssuesAfterDeployGraphChart
            data={abnormalAmount}
            numDays={issuesAfterDeploy.num_days}
            type="amount"
          />
        </GraphContainer>
      )}
      {issuesAfterDeploy.duration_triggered && (
        <GraphContainer>
          <br />
          <Typography
            size="medium"
            variant="title"
            color={palette.gray["800"]}
          >{`Average duration of Availability Issues (${issuesAfterDeploy.num_days}d)`}</Typography>
          <IssuesAfterDeployGraphChart
            data={abnormalDuration}
            numDays={issuesAfterDeploy.num_days}
            type="duration"
          />
        </GraphContainer>
      )}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default IssuesAfterDeployGraph;
