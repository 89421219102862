import React from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { useOverviewPageStore } from "../../../../store/overviewPageStore";
import { selectOpenServiceResourceDrawer } from "../../../../store/overviewPageStoreSelectors";

const StyledLink = styled(Link)`
  cursor: pointer;
`;

type ServiceTextLinkProps = {
  id: string;
  serviceName: string;
};

export const ServiceTextLink: React.FC<ServiceTextLinkProps> = ({
  serviceName,
  id,
}) => {
  const openServiceResourceDrawer = useOverviewPageStore(
    selectOpenServiceResourceDrawer
  );
  const onClick = () => {
    openServiceResourceDrawer(id);
  };

  return <StyledLink onClick={onClick}>{serviceName}</StyledLink>;
};
