import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  CustomEventResponse,
  EventsApiApiV1CustomServiceEventsSearchGetRequest,
  apiV1CustomServiceEventsSearchGetUrl,
} from "../../../../../../generated/resourcesApi";
import { useWorkspaceQueryKey } from "../../../../workspaces-api/useWorkspaceQueryKey";
import { CUSTOM_SERVICE_EVENT_SEARCH } from "../../../requestResponseMaps";
import { useResourcesApiClient } from "../../apiClient";

const fetchCustomServiceEvents = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1CustomServiceEventsSearchGetRequest
): Promise<CustomEventResponse> => {
  const { data } = await apiClient.get(
    apiV1CustomServiceEventsSearchGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useCustomServiceEvents = (
  params: EventsApiApiV1CustomServiceEventsSearchGetRequest,
  options: { enabled?: boolean } = { enabled: true }
) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([CUSTOM_SERVICE_EVENT_SEARCH, params]);
  return useQuery(
    keys,
    () => fetchCustomServiceEvents(apiClient, params),
    options
  );
};
