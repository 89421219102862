import styled from "styled-components";
import { theme, palette } from "@komodorio/design-system";

export const WIDTH = "42.5rem";
export const Result = styled.div`
  margin-block-start: 1rem;
  padding: 0.5rem;
  border: 1px solid ${theme.border.borderGray};
  border-radius: 4px;
  min-height: 1rem;
  max-height: 4rem;
  max-width: calc(${WIDTH} - 1rem);
  overflow: auto;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.15rem 0.5rem;
  justify-content: start;
  color: ${palette.gray[800]}
  > :nth-child(2n + 2) {
  }
`;
