import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { reliabilityArialLabels } from "../../../reliabilityArialLabels";

import { IgnoreChecksOverviewTable } from "./IgnoreChecksOverviewTable/IgnoreChecksOverviewTable";

const Container = styled.div`
  width: 800px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 24px;
  }
`;

const {
  ignoreChecks: { checksContainer: checksContainerAriaLabel },
} = reliabilityArialLabels;

export const IgnoreChecksOverview: React.FC = () => {
  return (
    <Container aria-label={checksContainerAriaLabel}>
      <Title variant={"body2"}>
        This is the list of all the checks that Komodor performs on your
        environments
      </Title>

      <IgnoreChecksOverviewTable />
    </Container>
  );
};
