import { Duration } from "duration-converter";

const DAY_IN_SECONDS = 60 * 60 * 24;
const HOUR_IN_SECONDS = 60 * 60;
const MINUTE_IN_SECONDS = 60;

const getAgeFromDate = (createdAt: Date, toDate?: Date): string => {
  let age = "";
  const endDate = toDate?.getTime() ?? Date.now();
  let seconds = Duration.fromMilliseconds(
    endDate - createdAt.getTime()
  ).Seconds;

  const days = Math.floor(seconds / DAY_IN_SECONDS);
  if (days > 0) {
    age += days.toString() + "d";
    seconds -= days * DAY_IN_SECONDS;
    if (days > 7) {
      // More than 7 days not specifying other things
      return age;
    }
  }

  const hours = Math.floor(seconds / HOUR_IN_SECONDS);
  if (hours > 0) {
    age += hours.toString() + "h";
    seconds -= hours * HOUR_IN_SECONDS;
    if (hours > 7) {
      // More than 7 hours not specifying other things
      return age;
    }
  }
  if (days > 0) {
    return age;
  }

  const minutes = Math.floor(seconds / MINUTE_IN_SECONDS);
  if (minutes > 0) {
    age += minutes.toString() + "m";
    seconds -= minutes * MINUTE_IN_SECONDS;
    if (minutes > 7) {
      // More than 7 minutes not specifying other things
      return age;
    }
  }
  if (hours > 0) {
    return age;
  }

  seconds = Math.ceil(seconds);
  if (seconds > 0) {
    age += seconds.toString() + "s";
  }
  return age;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default getAgeFromDate;
