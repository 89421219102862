import React, { useMemo } from "react";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Close from "@mui/icons-material/Close";
import { muiColors } from "@komodorio/design-system";

import { Specs } from "../../../EventGroup/deployEvent/getSpecsForDiff";
import { ObjectDiffAsYaml } from "../../../ObjectDiff";
import { useResourceObjectAsYaml } from "../../../../ResourceView/tabs/ResourceYAMLTab";

import { LazyEditor } from "@/components/common/LazyEditor";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChipsContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-block: 0.5rem;
`;

const Padding = styled.div`
  padding-top: 1px;
`;

const splitOnLast = (s: string, sep: string) => {
  const index = s.lastIndexOf(sep);
  const a = s.substring(0, index);
  const b = s.substring(index + 1);
  return [a, b];
};

const getVersionDiff = (versionFrom: string, versionTo: string) => {
  const [fromDockerRepo, fromDockerTag] = splitOnLast(versionFrom, ":");
  const [toDockerRepo, toDockerTag] = splitOnLast(versionTo, ":");

  if (fromDockerRepo === toDockerRepo) {
    return {
      from: fromDockerTag,
      to: toDockerTag,
    };
  }
  return {
    from: versionFrom,
    to: versionTo,
  };
};

const Title: React.FC<{
  versionFrom: string;
  versionTo: string;
  serviceName: string;
  onClose: () => void;
  isFirstDeployment?: boolean;
}> = ({ versionFrom, versionTo, serviceName, onClose, isFirstDeployment }) => {
  const { from, to } = useMemo(() => {
    return getVersionDiff(versionFrom, versionTo);
  }, [versionFrom, versionTo]);

  return (
    <TitleContainer>
      <Typography variant="h2">
        {isFirstDeployment ? (
          <>
            YAML for <b>{serviceName}</b>
          </>
        ) : (
          <>
            Changes in <b>{serviceName}</b> from <b>{from}</b> to <b>{to}</b>
          </>
        )}
      </Typography>
      <IconButton onClick={onClose} sx={{ color: muiColors.gray[400] }}>
        <Close />
      </IconButton>
    </TitleContainer>
  );
};

interface K8sDiffModalProps {
  open: boolean;
  onClose: () => void;
  specs: Specs | null;
  versionFrom: string;
  versionTo: string;
  serviceName: string;
  clusterName?: string;
  namespace?: string;
  isFirstDeployment?: boolean;
}
const K8sDiffModal: React.FC<K8sDiffModalProps> = ({
  open,
  onClose,
  specs,
  versionFrom,
  versionTo,
  serviceName,
  clusterName,
  namespace,
  isFirstDeployment,
}) => {
  const { oldSpec: oldObj, newSpec: newObj } = specs ?? {};

  const newObjYaml = useResourceObjectAsYaml(newObj, {
    dropManagedFields: true,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Title
          versionFrom={versionFrom}
          versionTo={versionTo}
          serviceName={serviceName}
          onClose={onClose}
          isFirstDeployment={isFirstDeployment}
        />
        <ChipsContainer>
          {clusterName && (
            <Chip
              size="small"
              label={
                <>
                  cluster name: <b>{clusterName}</b>
                </>
              }
            />
          )}
          {namespace && (
            <Chip
              size="small"
              label={
                <>
                  namespace: <b>{namespace}</b>
                </>
              }
            />
          )}
        </ChipsContainer>
      </DialogTitle>
      <DialogContent>
        {isFirstDeployment ? (
          <Padding>
            <LazyEditor
              width="70vw"
              height="70vh"
              readOnly
              mode="yaml"
              value={newObjYaml}
            />
          </Padding>
        ) : (
          <ObjectDiffAsYaml oldObj={oldObj} newObj={newObj} />
        )}
      </DialogContent>
    </Dialog>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default K8sDiffModal;
