import React, { KeyboardEvent } from "react";
import styled from "styled-components";

import { textStyle } from "../typography";

const iconRules = (icon: string) => `
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-position: 3px center;
  padding-left: 1.8rem;
`;

const StyledInput = styled.input<{ icon?: string; backgroundColor?: string }>`
  padding: 0.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#f8f8f8"};
  color: #3d4246;
  border: 1px solid #bcc0c8;
  border-radius: 0.25rem;
  width: -webkit-fill-available;
  ${textStyle}
  &:focus {
    outline: none;
    border-color: #007aff;
  }

  ${({ icon }) => icon && iconRules(icon)}
`;

interface InputProps {
  placeholder?: string;
  icon?: string;
  value: string;
  onChange: (newValue: string) => void;
  backgroundColor?: string;
  autoFocus?: boolean;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => Promise<void>;
  className?: string;
  dataE2ESelector?: string;
}

export const Input: React.FC<InputProps> = ({
  placeholder,
  icon,
  value,
  onChange,
  backgroundColor,
  autoFocus,
  onKeyPress,
  className,
  dataE2ESelector,
}) => (
  <StyledInput
    icon={icon}
    value={value}
    onChange={(event) => onChange(event.target.value)}
    placeholder={placeholder}
    backgroundColor={backgroundColor}
    autoFocus={autoFocus}
    onKeyPress={onKeyPress}
    className={className}
    data-e2e-selector={dataE2ESelector}
  />
);
