import React, { useMemo } from "react";
import { TooltipWrapper } from "react-tooltip";
import styled from "styled-components";

import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import {
  isMetricsRatioColumn,
  isMetricsUsageColumns,
  parseRatioStringToRatio,
} from "../../../Inspection/metrics/buildMetricFromResult";

const NarrowDiv = styled.div`
  width: min-content;
`;

function toPercentage(usage: number, upperBound: number | null) {
  if (upperBound === null) {
    return "N/A";
  }
  return Math.floor((usage / upperBound) * 100).toString() + "%";
}

export interface MetricRatioCellProps {
  cellData: string;
  cellColumn: string;
}

interface ContentAndTooltip {
  cellContent: string;
  tooltipContent: string;
}

const buildCellContentAndTooltipForRatio = (
  cellData: string,
  cellColumn: string
): ContentAndTooltip => {
  let cellContent = "N/A";
  let tooltipContent = isMetricsRatioColumn(cellColumn)
    ? "There is no requests/limit configured"
    : "There is no usage/allocation configured";
  const ratio = parseRatioStringToRatio(cellData);
  const hasRatio = ratio.usage !== null && ratio.upperBound;

  if (hasRatio) {
    tooltipContent = `${ratio.description}<br /><strong>${ratio.usage}/${ratio.upperBound} ${ratio.units}</strong>`;
    cellContent = toPercentage(ratio.usage || 0, ratio.upperBound);
  }
  return { cellContent, tooltipContent };
};

const buildCellContentAndTooltipForUsage = (
  cellData: string
): ContentAndTooltip => {
  let tooltipContent = "metrics not available";
  if (cellData !== "N/A") {
    tooltipContent = `${cellData} in usage`;
  }
  return { cellContent: cellData, tooltipContent };
};

export const MetricsCell: React.FC<MetricRatioCellProps> = ({
  cellData,
  cellColumn,
}) =>
  useMemo(() => {
    const { cellContent, tooltipContent } = isMetricsUsageColumns(cellColumn)
      ? buildCellContentAndTooltipForUsage(cellData)
      : buildCellContentAndTooltipForRatio(cellData, cellColumn);

    return (
      <td key={cellContent} aria-label={AriaLabels.Inspection.Pod.MetricsCell}>
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} html={tooltipContent}>
          <NarrowDiv>{cellContent}</NarrowDiv>
        </TooltipWrapper>
      </td>
    );
  }, [cellData, cellColumn]);
