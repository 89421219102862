import styled from "styled-components";

import x from "./x.svg";

export const CloseIcon = styled.div<{ titleMargin?: number }>`
  width: 1.5rem;
  height: 1.5rem;

  opacity: 1;
  &:hover {
    opacity: 1;
  }

  background-image: url(${x});
  background-repeat: no-repeat;
  ${({ titleMargin }) =>
    titleMargin
      ? `
  margin-bottom: ${titleMargin}rem;
  margin-top: ${titleMargin}rem;`
      : ""}

  cursor: pointer;
`;
