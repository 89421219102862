import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";

import { useClearAllFilters } from "@/components/k8sAddons/hooks/filters/useClearAllFilters";
import { ADD_ON_TABLE_PAGE_SIZE_OPTIONS } from "@/components/k8sAddons/constants/table";
import { NoResultsFound } from "@/components/k8sAddons/components/NoResultsFound";

export const useCommonDataGridProps = (
  error: unknown
): Partial<DataGridProProps> => {
  const onClearFilters = useClearAllFilters();
  return {
    disableColumnMenu: true,
    pagination: true,
    paginationMode: "server",
    disableColumnResize: true,
    disableVirtualization: true,
    pageSizeOptions: ADD_ON_TABLE_PAGE_SIZE_OPTIONS,
    slots: {
      noResultsOverlay: () => (
        <NoResultsFound onClearFilters={onClearFilters} isError={!!error} />
      ),
      noRowsOverlay: () => (
        <NoResultsFound onClearFilters={onClearFilters} isError={!!error} />
      ),
    },
  };
};
