import { differenceInDays, isAfter, startOfToday, sub } from "date-fns";

import { Dictionary } from "../types/Dictionary";
import { EarliestRowsTimeRecord } from "../../generated/metricsApi";

export const MINIMUM_DAYS_FOR_ACCURACY = 7;

const hasAccurateData = (dateStr: string) =>
  differenceInDays(new Date(), new Date(dateStr)) >= MINIMUM_DAYS_FOR_ACCURACY;

type GetAccuracyDataForClustersOutput = {
  clustersWithSufficientTime: string[];
  clustersWithInsufficientTime: string[];
};

export const getAccuracyDataForClusters = (
  clusters: Dictionary<string> = {}
) => {
  return Object.values(clusters).reduce<GetAccuracyDataForClustersOutput>(
    (acc, dateStr) => {
      if (!dateStr) return acc;
      if (hasAccurateData(dateStr)) {
        acc.clustersWithSufficientTime.push(dateStr);
      } else {
        acc.clustersWithInsufficientTime.push(dateStr);
      }
      return acc;
    },
    {
      clustersWithSufficientTime: [],
      clustersWithInsufficientTime: [],
    }
  );
};

export const flattenEarliestRowsTimeResponse = (
  clusters?: Dictionary<EarliestRowsTimeRecord>
) => {
  if (!clusters) return undefined;

  // convert from { clusterName: { earliestRecordTimeInHourlyNodesTable: Date }} to { clusterName: Date }
  return Object.entries(clusters).reduce<Dictionary<string>>(
    (acc, [clusterName, { earliestRecordTimeInHourlyNodesTable }]) => {
      acc[clusterName] = earliestRecordTimeInHourlyNodesTable;
      return acc;
    },
    {}
  );
};

const getEarliestDateToShowCollectingDataAlert = () => {
  const today = startOfToday();
  return sub(today, { hours: 1 });
};

export const getIsNewCluster = (earliestRowData: Date) => {
  return isAfter(earliestRowData, getEarliestDateToShowCollectingDataAlert());
};
