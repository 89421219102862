import React from "react";

import { isOldDrawerStateMechanismContext } from "./useIsOldDrawerStateMechanism";
import { useDrawerStackIndex } from "./useDrawerStackIndex";

// This provider is used to determine if a drawer is being used with the new drawers stack mechanism or not.
// Once all drawers are opened by the drawers stack, this provider can be removed.
export const IsOldDrawerStateMechanismProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const drawersStackStateMechanismIndex = useDrawerStackIndex();

  return (
    <isOldDrawerStateMechanismContext.Provider
      value={drawersStackStateMechanismIndex === undefined}
    >
      {children}
    </isOldDrawerStateMechanismContext.Provider>
  );
};
