import React from "react";

import { IconProps } from "../types";

export const Play16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M6 10.9597C6 11.379 6.48497 11.6121 6.81235 11.3502L10.512 8.39049C10.7622 8.19033 10.7622 7.80978 10.512 7.60962L6.81235 4.64993C6.48497 4.38803 6 4.62111 6 5.04037V10.9597Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8Z"
      />
    </svg>
  );
};

Play16.defaultProps = {
  fill: "currentColor",
};
