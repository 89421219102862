import React, { useMemo } from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { muiColors } from "@komodorio/design-system";

import { k8sAriaLabels } from "@/components/k8sAddons/k8sAriaLabels";
import { GenericErrorMessage } from "@/shared/components/GenericErrorMessage";
import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { AddonUrlState } from "@/components/k8sAddons/types";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const StyledErrorIcon = styled(ErrorOutlineIcon)`
  && {
    width: 32px;
    height: 32px;
    fill: ${muiColors.gray[500]};
  }
`;

type NoResultsFoundProps = {
  onClearFilters: () => void;
  isError: boolean;
};

export const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  onClearFilters,
  isError,
}) => {
  const [dataInUrl] = useStateInUrlAsJSON<AddonUrlState>(
    K8S_ADD_ONS_FILTERS_PARAM_KEY
  );
  const ariaLabel = isError
    ? k8sAriaLabels.errorMessageContainer
    : k8sAriaLabels.noResultsFound;

  const hasFilters = useMemo(() => {
    const generalFilters =
      dataInUrl.filters && Object.keys(dataInUrl.filters).length > 0;
    const tableFilters =
      dataInUrl.tableState &&
      Object.values(dataInUrl.tableState).filter(Boolean).length > 0;

    return generalFilters || tableFilters;
  }, [dataInUrl.filters, dataInUrl.tableState]);

  const content = isError ? (
    <GenericErrorMessage />
  ) : (
    <>
      <StyledErrorIcon />
      <Typography variant={"body2"} color={"text.secondary"}>
        No results found
      </Typography>
      {hasFilters && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={onClearFilters}
        >
          Clear filters
        </Button>
      )}
    </>
  );
  return <Container aria-label={ariaLabel}>{content}</Container>;
};
