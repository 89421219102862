import React from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";

const Tile = styled.div<{ checked: boolean; isLoading: boolean }>`
  word-break: break-all;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: ${theme.background.bgWhite};
  border: 1px solid
    ${({ checked }) =>
      checked ? theme.border.borderDarkBlue : theme.border.borderGray};
  border-radius: 0.25rem;
  cursor: ${({ isLoading }) => (isLoading ? "not-allowed" : "pointer")};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "auto")};
`;

const StyledCheckBox = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  border: 1px solid ${theme.border.borderGrayDarker};
  border-radius: 2px;
  width: 0.875rem;
  height: 0.875rem;
`;

const ClusterPickerTile: React.FC<{
  cluster: string;
  checked: boolean;
  isLoading: boolean;
  handleClusterClicked: () => void;
}> = ({ isLoading, cluster, checked, handleClusterClicked }) => {
  return (
    <Tile
      isLoading={isLoading}
      checked={checked}
      key={cluster}
      onClick={handleClusterClicked}
    >
      <StyledCheckBox id={cluster} checked={checked} />
      {cluster}
    </Tile>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ClusterPickerTile;
