import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { formatDistanceStrict } from "date-fns";
import styled from "styled-components";

import { NodeData } from "../../../../ResourceDependenciesPage/types";
import { NodeHealthIndicator } from "../../../../ResourceDependenciesPage/NodeHealthIndicator";
import { MonitorData } from "../../../../../generated/monitorsApi";
import { getCustomDateLocale } from "../../../../../shared/utils/dateUtils";
import { dependenciesGraphAriaLabels } from "../../../../ResourceDependenciesPage/constants";
import {
  getResourceLink,
  isCrdKind,
} from "../../../../ResourceDependenciesPage/utils/graphUtils";

import { RowContainer } from "./RowContainer";
import { ResourceAttribute } from "./ResourceAttribute";

interface NodeTooltipContentProps {
  nodeData: NodeData;
  openIssue: MonitorData | undefined;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  width: 246px;
  cursor: default;
`;

export const NodeTooltipContent: React.FC<NodeTooltipContentProps> = ({
  nodeData,
  openIssue,
}) => {
  const isCrd = useMemo(() => {
    return isCrdKind(nodeData?.kind || "");
  }, [nodeData.kind]);

  const issueTime = useMemo(() => {
    if (!openIssue || !openIssue.eventTime) {
      return "";
    }
    return formatDistanceStrict(new Date(openIssue.eventTime), new Date(), {
      addSuffix: true,
      locale: getCustomDateLocale(),
    });
  }, [openIssue]);

  const resourceLink = useMemo(() => getResourceLink(nodeData), [nodeData]);

  return (
    <ContentContainer>
      <RowContainer
        aria-label={dependenciesGraphAriaLabels.tooltip.kindAndName}
      >
        <ResourceAttribute
          variant="h5"
          topContent={nodeData.name}
          bottomContent={nodeData.kind}
        />
      </RowContainer>

      <RowContainer
        aria-label={dependenciesGraphAriaLabels.tooltip.clusterAndNamespace}
      >
        <ResourceAttribute
          variant="h5"
          topContent={nodeData.cluster}
          bottomContent="CLUSTER"
        />
        <ResourceAttribute
          variant="h5"
          topContent={nodeData.namespace}
          bottomContent="NAMESPACE"
        />
      </RowContainer>

      {!isCrd && (
        <RowContainer aria-label={dependenciesGraphAriaLabels.tooltip.replicas}>
          <ResourceAttribute
            topContent={
              <Typography variant="overline2" color={muiColors.gray[600]}>
                Health
              </Typography>
            }
            bottomContent={
              <Typography variant="overline2" color={muiColors.gray[600]}>
                Replicas
              </Typography>
            }
            color={muiColors.gray[600]}
            variant="body3"
          />

          <ResourceAttribute color={muiColors.gray[600]} variant="body3" />
          <ResourceAttribute>
            <NodeHealthIndicator
              noPadding
              isHealthy={nodeData.resourceDetails.isHealthy}
            />
            <Typography color={muiColors.gray[700]} variant="body3">
              {nodeData.resourceDetails.replicas.availableCount}/
              {nodeData.resourceDetails.replicas.desiredCount}
            </Typography>
          </ResourceAttribute>
        </RowContainer>
      )}

      {openIssue && (
        <RowContainer aria-label={dependenciesGraphAriaLabels.tooltip.issue}>
          <ResourceAttribute
            topContent={
              <Typography variant="overline2" color={muiColors.gray[600]}>
                Started
              </Typography>
            }
            bottomContent={
              <Typography variant="overline2" color={muiColors.gray[600]}>
                Reason
              </Typography>
            }
            color={muiColors.gray[600]}
            variant="body3"
          />

          <ResourceAttribute>
            <Typography color={muiColors.gray[900]} variant="body3">
              {issueTime}
            </Typography>

            <Typography color={muiColors.gray[900]} variant="body3">
              {openIssue.reasons?.length && openIssue.reasons[0]}
            </Typography>
          </ResourceAttribute>
        </RowContainer>
      )}

      {!isCrd && (
        <RowContainer noBorder>
          <Link
            variant={"body2"}
            underline="none"
            href={resourceLink}
            target={"_blank"}
          >
            Go to resource
          </Link>
        </RowContainer>
      )}
    </ContentContainer>
  );
};
