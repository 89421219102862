import {
  AppInsightsApiGetAvailabilityIssuesRequest,
  AppInsightsApiGetFailedDeploymentsRequest,
  AppInsightsApiGetLivenessProbesMissingBestPracticesRequest,
  AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest,
  AppInsightsApiGetSecurityBestPracticesRequest,
  AvailabilityIssuesResponse,
  FailedDeploymentsResponse,
  getAvailabilityIssuesUrl,
  getFailedDeploymentsUrl,
  getLivenessProbesMissingBestPracticesUrl,
  getMissingRequestsOrLimitsBestPracticesUrl,
  getSecurityBestPracticesUrl,
  LivenessProbesMissingBestPracticesResponse,
  MissingRequestsOrLimitsBestPracticesResponse,
  SecurityBestPracticesResponse,
} from "../../../generated/applicationInsights";

export const BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT =
  "liveness-probes-missing";

export const BEST_PRACTICES_SECURITY_ENDPOINT = "security";

export const BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT =
  "missing-requests-or-limits";

export const AVAILABILITY_ISSUES_ENDPOINT = "availability";

export const FAILED_DEPLOYMENTS_ENDPOINT = "failed-deployments";

export const EndpointRequestPathMap = {
  [BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT]:
    getLivenessProbesMissingBestPracticesUrl,
  [BEST_PRACTICES_SECURITY_ENDPOINT]: getSecurityBestPracticesUrl,
  [BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT]:
    getMissingRequestsOrLimitsBestPracticesUrl,
  [AVAILABILITY_ISSUES_ENDPOINT]: getAvailabilityIssuesUrl,
  [FAILED_DEPLOYMENTS_ENDPOINT]: getFailedDeploymentsUrl,
};

export type EndpointsResponseMap = {
  [BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT]: LivenessProbesMissingBestPracticesResponse;
  [BEST_PRACTICES_SECURITY_ENDPOINT]: SecurityBestPracticesResponse;
  [BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT]: MissingRequestsOrLimitsBestPracticesResponse;
  [AVAILABILITY_ISSUES_ENDPOINT]: AvailabilityIssuesResponse;
  [FAILED_DEPLOYMENTS_ENDPOINT]: FailedDeploymentsResponse;
};

export type EndpointsRequestMap = {
  [BEST_PRACTICES_LIVENESS_PROBES_MISSING_ENDPOINT]: AppInsightsApiGetLivenessProbesMissingBestPracticesRequest;
  [BEST_PRACTICES_SECURITY_ENDPOINT]: AppInsightsApiGetSecurityBestPracticesRequest;
  [BEST_PRACTICES_MISSING_REQUESTS_OR_LIMITS_ENDPOINT]: AppInsightsApiGetMissingRequestsOrLimitsBestPracticesRequest;
  [AVAILABILITY_ISSUES_ENDPOINT]: AppInsightsApiGetAvailabilityIssuesRequest;
  [FAILED_DEPLOYMENTS_ENDPOINT]: AppInsightsApiGetFailedDeploymentsRequest;
};
