import { Typography } from "@komodorio/design-system/deprecated";
import React from "react";
import styled from "styled-components";

import Switcher from "../../common/controls/Switcher";

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 0.4rem;
`;

export const DeletedPodsSwitch: React.FC<{
  checked: boolean;
  onToggle: () => void;
  disabled?: boolean;
}> = ({ checked, onToggle, disabled = false }) => (
  <Grid>
    <Switcher
      aria-label="deleted-pods"
      checked={disabled ? false : checked}
      handleSwitcherChange={disabled ? () => Function.prototype() : onToggle}
    />
    <Typography>Show deleted pods</Typography>
  </Grid>
);
