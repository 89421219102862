import { useQuery } from "@tanstack/react-query";

import {
  GetViolationsStatsResponse,
  getImpactGroupsUrl,
  ImpactGroupsApiGetImpactGroupsRequest,
} from "../../../../generated/reliabilityApi";
import { QueryOptions, useReliabilityApiClient } from "../apiClient";
import { useWorkspaceQueryKey } from "../../workspaces-api/useWorkspaceQueryKey";

export const GET_GROUPS_PATH = "/api/v1/violations/stats";

export const useGetGroups = (
  params: ImpactGroupsApiGetImpactGroupsRequest,
  queryOptions?: QueryOptions
) => {
  const apiClient = useReliabilityApiClient();
  const keys = useWorkspaceQueryKey([GET_GROUPS_PATH, params]);

  return useQuery(
    keys,
    async () =>
      await apiClient.get<GetViolationsStatsResponse>(
        getImpactGroupsUrl(params, apiClient.defaults.baseURL ?? "")
      ),
    { staleTime: Infinity, ...queryOptions }
  );
};
