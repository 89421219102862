import {
  ApiV1CostAllocationOverTimeGetScopeParameter,
  CostAllocationSummary,
  CostAllocationSummaryResponse,
} from "../../../../generated/metricsApi";
import { AllocationFiltersGroupBy } from "../../types/costOptimizationTypes";
import { TABLE_IDLE_ENTRY } from "../../constants/costOptimizationConstants";

import { getRandRange } from "./getRandRange";

type MockCostAllocationSummary = {
  rowsNum?: number;
  groupBy?: AllocationFiltersGroupBy;
  scope?: ApiV1CostAllocationOverTimeGetScopeParameter;
};

type GenerateSingleRowDataParams = {
  id: string;
  groupBy: AllocationFiltersGroupBy;
  scope?: ApiV1CostAllocationOverTimeGetScopeParameter;
};

const generateSingleRowData = ({
  id,
  groupBy,
  scope,
}: GenerateSingleRowDataParams): CostAllocationSummary => {
  const isNumber = id && !isNaN(Number(id));
  const clustersList = scope?.clusters ?? [];
  const clusterName =
    isNumber && clustersList.length
      ? clustersList[Number(id) % clustersList.length]
      : `cluster-name-${id}`;
  const namespace = `namespace-${id}`;
  const resourceKind = "Deployment";
  return {
    clusterName,
    komodorServiceKind: groupBy === "komodorServiceName" ? resourceKind : "",
    komodorServiceName: `${
      groupBy === "komodorServiceName" ? `service-${id}` : ""
    }`,
    namespace: `${
      groupBy === "namespace" || groupBy === "komodorServiceName"
        ? namespace
        : ""
    }`,
    cpuRequestCoreHours: Math.random() * 10000,
    efficiency: Math.random() * 100,
    memoryRequestGBHours: Math.random() * 10000,
    potentialSaving: Math.random() * 100000,
    shareOfCost: Math.random() * 100,
    totalCost: Math.random() * 1000000,
    idleResourcesCost: Math.random() * 1000000,
    id: `${id}`,
    optimizationScore: Math.random() * 100,
    komodorServiceId: `test-account.${clusterName}-${namespace}.${resourceKind}`,
  };
};

export const generateMockCostAllocationSummary = (
  params?: MockCostAllocationSummary
): CostAllocationSummaryResponse => {
  const { rowsNum = 50, groupBy = "clusterName", scope } = params || {};
  const rows = Array.from({
    length: rowsNum,
  }).map<CostAllocationSummary>((_, i) =>
    generateSingleRowData({ id: i.toString(), groupBy, scope })
  );
  if (groupBy !== "clusterName") {
    const emptyRow = generateSingleRowData({ id: "_empty_", groupBy, scope });
    const idleRow = generateSingleRowData({
      id: TABLE_IDLE_ENTRY,
      groupBy,
      scope,
    });
    rows.push({
      ...idleRow,
      clusterName: TABLE_IDLE_ENTRY,
      namespace: TABLE_IDLE_ENTRY,
      komodorServiceName: TABLE_IDLE_ENTRY,
      komodorServiceKind: "",
      komodorServiceId: "",
      potentialSaving: 0,
    });
    rows.push({
      ...emptyRow,
    });
  }
  return {
    totalCost: getRandRange(100000, 200000),
    totalEfficiency: getRandRange(10, 90),
    totalPotentialSaving: getRandRange(20000, 600000),
    totalOptimizationScore: getRandRange(10, 90),
    rows,
  };
};
