import { useMemo } from "react";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { useAllActiveAgentInfos } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportLivePodLogs } from "../../../../shared/utils/agent/agent";

export const useShouldShowLogsUpgradeAgentAlert = (clusterName: string) => {
  const { useLivePodLogs } = useOverridableFlags();
  const agents = useAllActiveAgentInfos();

  const isAgentVersionSupportLiveLogs = useMemo(() => {
    const agentInfo = agents?.find(
      (agent) => agent.clusterName === clusterName
    );

    return !!(agentInfo && doesAgentVersionSupportLivePodLogs(agentInfo));
  }, [agents, clusterName]);

  return agents && useLivePodLogs && !isAgentVersionSupportLiveLogs;
};
