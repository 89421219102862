import { Statement as StatementType } from "komodor-types";
import { remove, sortBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Statement } from "./Statement";

import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { useGetRbacPolicyActions } from "@/shared/hooks/auth-service/client/rbacActions/useGetRbacPolicyActions";
import { RbacPolicyType } from "@/generated/auth";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useGetRbacCustomK8sActions } from "@/shared/hooks/auth-service/client/rbacActions/useGetRbacK8sCustomActions";

const Statements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;

interface VisualEditorProps {
  value: StatementType[];
  onChange: (v: StatementType[]) => Promise<void>;
  readOnly?: boolean;
  policyType: RbacPolicyType;
}

const VisualEditor: React.FC<VisualEditorProps> = ({
  value,
  onChange,
  readOnly,
  policyType,
}) => {
  const { deleteError } = useFormValidations();

  const { data: policyActions } = useGetRbacPolicyActions();
  const { data: policyCustomActions } = useGetRbacCustomK8sActions();
  const policyActionsOptions = useMemo(
    () => [
      {
        label: "All Actions (actions: *)",
        value: "*",
      },
      ...sortBy(
        policyCustomActions?.map((pa) => ({
          label: pa.action,
          value: pa.action,
        })) ?? [],
        ["label"]
      ),
      ...sortBy(
        policyActions?.map((pa) => ({
          label: pa.action,
          value: pa.action,
        })) ?? [],
        ["label"]
      ),
    ],
    [policyActions, policyCustomActions]
  );
  const allServices = useKomodorServices().all;
  const clustersOptions = useMemo(() => {
    return [
      {
        label: "All (cluster: *)",
        value: "*",
      },
      ...[...new Set(allServices?.map((service) => service.k8sCluster))].map(
        (n) => ({ label: n, value: n })
      ),
    ];
  }, [allServices]);
  const namespacesOptions = useCallback(
    (cluster: string) => {
      return [
        ...new Set(
          allServices
            ?.filter((s) => s.k8sCluster === cluster || cluster === "*")
            .map((s) => s.env)
        ),
      ].map((n) => ({ label: n, value: n }));
    },
    [allServices]
  );

  const handleStatementChange = (s: StatementType, index: number) => {
    onChange([...value].map((v, i) => (i === index ? s : v)));
  };
  const handleDeleteStatement = (index: number) => {
    onChange(remove(value, (_, i) => i !== index));
    deleteError((k) => k.startsWith(`instance[${index}]`));
  };

  return (
    <Statements>
      {value.map((s, i) => (
        <Statement
          key={i}
          index={i}
          statement={s}
          onChange={handleStatementChange}
          onDelete={value.length !== 1 ? handleDeleteStatement : undefined}
          actionsOptions={policyActionsOptions}
          clustersOptions={clustersOptions}
          namespacesOptions={namespacesOptions}
          readOnly={readOnly}
          policyType={policyType}
        />
      ))}
    </Statements>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default VisualEditor;
