import { format } from "date-fns";

import { PodLogLine } from "../../../../shared/hooks/podLogs/types";

export function downloadContentToTextFile(
  content: string,
  filename: string
): void {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getLogsFromFetch(podLogsRes: PodLogLine[]): string {
  return podLogsRes
    .map(({ logTime, message }) => `[ ${logTime} ]: ${message}`)
    .join("\n");
}

export function formatLogTime(utcDate: Date): string {
  const pattern = "d-M-yyyy HH:mm:ss.SSS";
  return format(utcDate, pattern);
}

const isValidDate = (d: Date): boolean => {
  return !Number.isNaN(d.getTime());
};

export function padFollowingZerosToLogTime(logTime: string): string {
  const utcDate = new Date(logTime);
  const editedUtcDate = new Date(logTime + "Z");

  return formatLogTime(isValidDate(utcDate) ? utcDate : editedUtcDate);
}
