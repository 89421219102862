import { useCallback, useMemo } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";
import { GridFilterModel } from "@mui/x-data-grid-pro";

import { useCostOptimizationStore } from "../../../store/costOptimizationStore";
import {
  selectRightSizingFilters,
  selectRightSizingPaginatedTableState,
  selectSetRightSizingPaginatedTableState,
} from "../../../store/costOptimizationStoreSelectors";
import { useScopeAsServerRequestParam } from "../../../hooks/useScopeAsServerRequestParam";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "../../../constants/costOptimizationConstants";
import {
  CostApiApiV1CostRightSizingRowsGetRequest,
  CostFilterByColumnEnum,
  CostFilterByOperatorEnum,
} from "../../../../../generated/metricsApi";
import { useGetRightSizingRows } from "../../../../../shared/hooks/metrics-api/client/useGetRightSizingRows";
import { initialPaginatedTableResponse } from "../../../store/initialState";
import { RightSizingPaginatedTableState } from "../../../types/costOptimizationTypes";
import { useHasClustersScope } from "../../../hooks/useShouldPauseRequest";
import { getNewTokens } from "../RightSizingPaginatedTable/rightSizingPaginatedTableUtils";

import { useRightSizingEpochs } from "./commonHooks";

export const useGetPaginationModel = (): GridPaginationModel => {
  const { pageSize, pageIndex } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );

  return useMemo(
    () => ({
      pageSize: pageSize ?? DEFAULT_PAGINATION_PAGE_SIZE,
      page: pageIndex ?? 0,
    }),
    [pageSize, pageIndex]
  );
};

const useGetToken = (): string | undefined => {
  const { tokens, pageIndex } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );

  return tokens ? tokens[pageIndex] : undefined;
};

export const useGetParams = (): CostApiApiV1CostRightSizingRowsGetRequest => {
  const scope = useScopeAsServerRequestParam();
  const { strategy } = useCostOptimizationStore(selectRightSizingFilters);

  const { fromEpoch, toEpoch } = useRightSizingEpochs();

  const { pageSize, orderBy, filterBy } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );

  const token = useGetToken();

  return useMemo(() => {
    return {
      strategy,
      scope,
      fromEpoch,
      toEpoch,
      pageSize,
      paginationToken: token,
      orderBy,
      ...(filterBy?.value && { filterBy }),
    };
  }, [fromEpoch, toEpoch, scope, strategy, pageSize, token, orderBy, filterBy]);
};

export const useRightSizingRows = (enabled: boolean) => {
  const { pageIndex } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );
  const params = useGetParams();

  return useGetRightSizingRows({ params, pageIndex, enabled });
};

export const useRightSizingRowsForExport = (
  pageSize: number,
  enabled: boolean
) => {
  const params = useGetParams();
  const exportParams = useMemo(() => {
    return { ...params, pageSize, paginationToken: undefined };
  }, [params, pageSize]);

  return useGetRightSizingRows({ params: exportParams, pageIndex: 0, enabled });
};

export const useOnFilterChange = () => {
  const setRightSizingPaginatedTableState = useCostOptimizationStore(
    selectSetRightSizingPaginatedTableState
  );

  return useCallback(
    (model: GridFilterModel) => {
      /** TODO: Paginated table filters are currently not shared with Allocation table filters */
      if (model.items[0]) {
        const { field, operator, value } = model.items[0];
        setRightSizingPaginatedTableState({
          ...initialPaginatedTableResponse,
          filterBy: {
            column: field as CostFilterByColumnEnum,
            operator: operator as CostFilterByOperatorEnum,
            value: value,
          },
        });
      } else {
        setRightSizingPaginatedTableState(initialPaginatedTableResponse);
      }
    },
    [setRightSizingPaginatedTableState]
  );
};

export const useShouldEnableFetchingRightSizingRows = () => {
  const { filterBy } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );
  return useHasClustersScope() && (!filterBy || !!filterBy.value);
};

export const useRightSizingRowsEnabled = () => {
  const enabled = useShouldEnableFetchingRightSizingRows();
  return useRightSizingRows(enabled);
};

export const useSetPaginationModel = () => {
  const { pageIndex, pageSize, tokens } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );
  const setRightSizingPaginatedTableState = useCostOptimizationStore(
    selectSetRightSizingPaginatedTableState
  );

  const { data } = useRightSizingRowsEnabled();

  return useCallback(
    (newModel: GridPaginationModel) => {
      const pageSizeChanged = newModel.pageSize !== pageSize;
      const pageIndexChanged = newModel.page !== pageIndex;
      let newState: Partial<RightSizingPaginatedTableState> = {
        ...(pageIndexChanged && { pageIndex: newModel.page }),
        ...(pageSizeChanged && { pageSize: newModel.pageSize }),
      };

      if (pageIndexChanged) {
        newState = {
          pageIndex: newModel.page,
          tokens: getNewTokens(
            newModel.page,
            tokens,
            data?.data.paginationToken
          ),
        };
      }

      if (pageSizeChanged) {
        newState = {
          ...initialPaginatedTableResponse,
          pageSize: newModel.pageSize,
        };
      }

      setRightSizingPaginatedTableState(newState);
    },
    [
      pageSize,
      pageIndex,
      setRightSizingPaginatedTableState,
      tokens,
      data?.data.paginationToken,
    ]
  );
};

export const useGetFilterModel = (): GridFilterModel => {
  const { filterBy } = useCostOptimizationStore(
    selectRightSizingPaginatedTableState
  );
  return useMemo(() => {
    return filterBy
      ? {
          items: [
            {
              field: filterBy?.column,
              operator: filterBy?.operator,
              value: filterBy?.value,
            },
          ],
        }
      : { items: [] };
  }, [filterBy]);
};
