import React from "react";
import { Nodes16 } from "@komodorio/design-system/icons";

import { clustersDemoRoutes } from "../../ClustersDemoViewV3/constants";
import { DEMO } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

export const InfraHealthDemoLink: React.FC = () => {
  return (
    <AppBarLink
      text="Infrastructure Health"
      route={`${DEMO}/v3/${clustersDemoRoutes.infraHealth}`}
      icon={<Nodes16 />}
      end={true}
    />
  );
};
