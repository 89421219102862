import { palette } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { Typography } from "@komodorio/design-system/deprecated";
import { forwardRef, useMemo } from "react";
import { uniq } from "lodash";

import { Container, FlexColumn } from "../common/styles";
import { aOrAnForInvestigationErrors } from "../../../../shared/utils/textUtils";

import explanations from "./explanations";
import {
  getExplanationCategory,
  getExplanationImage,
  OOMKilledReason,
  UnhealthyProbeReasons,
} from "./utils";
import { OOMKilledExplanation, ProbExplanation } from "./IntroductionCards";

export const IntroductionExplanations = forwardRef<
  HTMLDivElement,
  {
    reasons: string[];
    subReason?: string;
    showSubReason?: boolean;
  }
>(({ reasons, subReason, showSubReason }, ref) => {
  const reasonExplanations = useMemo(
    () =>
      uniq(reasons.map((reason) => getExplanationCategory(reason)))
        .filter((reason) =>
          Object.keys(explanations).find((e) => reason.includes(e))
        )
        .map((reason) => {
          return { reason, explanation: explanations[reason] };
        })
        .filter((reason) => !!reason.explanation),
    [reasons]
  );

  return reasonExplanations.length > 0 ? (
    <>
      {reasonExplanations.map((reasonExplanation) => (
        <IntroductionExplanation
          ref={ref}
          reason={reasonExplanation.reason}
          explanation={reasonExplanation.explanation}
          subReason={subReason}
          showSubReason={showSubReason}
        />
      ))}
    </>
  ) : null;
});

const IntroductionExplanation = forwardRef<
  HTMLDivElement,
  {
    reason: string;
    explanation: string;
    subReason?: string;
    showSubReason?: boolean;
  }
>(({ reason, explanation, subReason, showSubReason }, ref) => {
  return explanation ? (
    <Container ref={ref}>
      <>
        <FlexColumn>
          <MuiTypography variant="h3">
            What is {aOrAnForInvestigationErrors(reason)} error?
          </MuiTypography>
          {getExplanationImage(reason)}
          <Typography
            size="large"
            color={palette.gray[700]}
            style={{ whiteSpace: "break-spaces" }}
          >
            {explanation}
          </Typography>
        </FlexColumn>
        {showSubReason && reason === OOMKilledReason && (
          <OOMKilledExplanation subReason={subReason} />
        )}
        {UnhealthyProbeReasons.includes(reason) && <ProbExplanation />}
      </>
    </Container>
  ) : null;
});
