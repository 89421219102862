import React, { useCallback, useEffect, useMemo } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useGetViolationChecks } from "../../../../../../../../shared/hooks/reliability-api/violations/useGetViolationChecks";
import { useViolationsTableContext } from "../context/useViolationsTableContext";
import { CheckType } from "../../../../../../../../generated/reliabilityApi";
import { notifyDDError } from "../../../../../../../../shared/hooks/exceptionManagement";
import { reliabilityArialLabels } from "../../../../../../reliabilityArialLabels";
import { getCheckLabel } from "../../../../../../utils/getCheckLabel";
import { useReliabilityStore } from "../../../../../../store/reliabilityStore";
import {
  selectSetGroupState,
  selectViolationsState,
} from "../../../../../../store/reliabilityStoreSelectors";

import { MultiSelectContainer } from "./MultiSelectContainer";
import { useAllChecks } from "./useAllChecks";

const {
  violationsTable: { checkTypeFilter: checkFilterAriaLabel },
} = reliabilityArialLabels;

export const ChecksSelector: React.FC = () => {
  const { uid, violationsGroupBy, impactGroupIdentifier } =
    useViolationsTableContext();
  const { data, error } = useGetViolationChecks();

  const { groups } = useReliabilityStore(selectViolationsState);

  const checkTypes = useMemo(
    () => groups[violationsGroupBy][uid ?? ""]?.checkTypes ?? [],
    [groups, uid, violationsGroupBy]
  );
  const setGroupsState = useReliabilityStore(selectSetGroupState);

  useEffect(() => {
    if (error) {
      notifyDDError(error as Error);
    }
  }, [error]);

  const allChecks = useAllChecks({
    checks: data?.data?.checks,
    violationsGroupBy,
    impactGroupIdentifier,
  });

  const options = useMemo(() => {
    return allChecks
      .map((check) => {
        return {
          label: getCheckLabel(check.type),
          value: check.type,
        };
      })
      .filter(({ label }) => !!label);
  }, [allChecks]);

  const currentValues = useMemo(() => {
    if (!checkTypes) return [];
    return checkTypes
      .filter((checkType) => {
        return !!allChecks.find((orgCheck) => orgCheck.type === checkType);
      })
      .map((checkType) => {
        return {
          label: getCheckLabel(checkType),
          value: checkType,
        };
      });
  }, [allChecks, checkTypes]);

  const onChange = useCallback(
    (options: MuiSelectionOption<string>[]) => {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          checkTypes: options.map((option) => option.value as CheckType),
        },
      });
    },
    [uid, setGroupsState, violationsGroupBy]
  );

  return (
    <MultiSelectContainer
      options={options}
      value={currentValues}
      placeholder={"All check types"}
      width={"250px"}
      onChange={onChange}
      ariaLabel={checkFilterAriaLabel}
      selectPopoverProps={{
        width: "330px",
      }}
    />
  );
};
