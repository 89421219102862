import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";
import { formatDistanceStrict } from "date-fns";

import {
  Timeframe,
  TimeWindow,
  TimeWindowProps,
} from "../../../shared/types/TimeWindow";
import { SingleSmartSelect } from "../controls/Select/SingleSmartSelect";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import TimeWindowControls from "../../eventsView/TimeWindowControls";

import CustomRangeSelector from "./CustomRangeSelector";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const formatRange = (
  formatDate: (d: Date) => string,
  d1: Date,
  d2: Date
): string => `${formatDate(d1)} ➜ ${formatDate(d2)}`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const defaultOptions: Timeframe[] = [
  Timeframe.Last30Minutes,
  Timeframe.LastHour,
  Timeframe.Last4Hours,
  Timeframe.Last24Hours,
  Timeframe.Last48Hours,
  Timeframe.Last7Days,
  Timeframe.Custom,
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const defaultLabels = {
  [Timeframe.Last30Minutes]: "Last 30 minutes",
  [Timeframe.LastHour]: "Last hour",
  [Timeframe.Last4Hours]: "Last 4 hours",
  [Timeframe.Last24Hours]: "Last 24 hours",
  [Timeframe.Last48Hours]: "Last 48 hours",
  [Timeframe.Last7Days]: "Last 7 days",
  [Timeframe.Last30Days]: "Last 30 days",
  [Timeframe.Custom]: "Custom dates",
};
interface DateTimeSelectorProps extends TimeWindowProps {
  className?: string;
  disabled?: boolean;
  options?: Timeframe[];
  labels?: Record<Timeframe, string>;
  forceRightAlign?: boolean;
  showLabel?: boolean;
  warning?: string;
  showControls?: boolean;
  disableCustomSelector?: boolean;
}

const WidthSingleSmartSelect = styled(SingleSmartSelect)`
  min-width: 15rem;
  max-width: 22.5rem;
  justify-content: start;
  &.noBackground {
    min-width: 1rem;
  }
  &.workspaceView {
    min-width: 1rem;
  }
  &.forceRightAlign {
    right: 0;
  }
  cursor: pointer;
`;

const calcDaysBetween = (endDate: Date, startDate: Date) =>
  Math.floor(
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
      1000 /
      60 /
      60 /
      24
  );

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const dateFormatterOverrideOptions: Intl.DateTimeFormatOptions = {
  year: undefined,
  second: undefined,
  timeZoneName: undefined,
};

export const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  timeWindow,
  setTimeWindow,
  className,
  options = defaultOptions,
  labels = defaultLabels,
  disabled = false,
  forceRightAlign = true,
  showLabel = false,
  warning,
  showControls,
  disableCustomSelector,
}) => {
  const [customOpen, setCustomOpen] = useState(
    timeWindow.timeframe === Timeframe.Custom && !disableCustomSelector
  );
  const isSentDefaultTimeAnalytics = useRef(false);

  const { format } = useDateFormatter(dateFormatterOverrideOptions);

  useEffect(() => {
    if (isSentDefaultTimeAnalytics.current) return;

    dispatchEvent(AnalyticEvents.TimePicker.Default, {
      page: window.location.pathname,
      selected: calcDaysBetween(timeWindow.end, timeWindow.start) + " days",
    });

    isSentDefaultTimeAnalytics.current = true;
  }, [timeWindow, isSentDefaultTimeAnalytics]);

  const selectedOption = useMemo(
    () => ({
      label:
        timeWindow.timeframe === Timeframe.Custom
          ? formatRange(format, timeWindow.start, timeWindow.end)
          : labels[timeWindow.timeframe],
      value: timeWindow.timeframe,
    }),
    [format, labels, timeWindow.end, timeWindow.start, timeWindow.timeframe]
  );

  const handleCommitTimeSelection = useCallback(
    (timeWindow: Partial<TimeWindow>) => {
      dispatchEvent(AnalyticEvents.TimePicker.Select, {
        page: window.location.pathname,
        selected:
          labels[timeWindow.timeframe || Timeframe.Custom] +
          (timeWindow.end && timeWindow.start
            ? " - " +
              calcDaysBetween(timeWindow.end, timeWindow.start) +
              " days"
            : ""),
      });
      setTimeWindow(timeWindow);
    },
    [labels, setTimeWindow]
  );

  const label = useMemo(() => {
    if (!showLabel || timeWindow.timeframe !== Timeframe.Custom) return;
    const distance = formatDistanceStrict(timeWindow.start, timeWindow.end);
    const parts = distance.split(" ");
    if (parts.length !== 2) return "";
    return `${parts[0]}${parts[1][0]}`;
  }, [showLabel, timeWindow.end, timeWindow.start, timeWindow.timeframe]);

  const classNames = forceRightAlign
    ? `forceRightAlign ${className}`
    : className;

  return (
    <>
      <WidthSingleSmartSelect
        className={classNames}
        placeholder="Select timeframe"
        options={options.map((o) => ({
          label: labels[o],
          value: o,
        }))}
        value={selectedOption}
        onChange={(selected, preventClose) => {
          if (selected.value === Timeframe.Custom) {
            preventClose();
            setCustomOpen(true);
          } else {
            if (customOpen) {
              setCustomOpen(false);
            }
            handleCommitTimeSelection({
              timeframe: selected.value as Timeframe,
            });
          }
        }}
        e2eSelector="datetime-picker"
        disabled={disabled}
        label={label}
      >
        {customOpen
          ? (close) => (
              <CustomRangeSelector
                initialTimeWindow={timeWindow}
                onApply={(tw) => {
                  handleCommitTimeSelection(tw);
                  close();
                }}
                warning={warning}
              />
            )
          : undefined}
      </WidthSingleSmartSelect>
      {showControls && (
        <TimeWindowControls
          timeWindow={timeWindow}
          setTimeWindow={setTimeWindow}
        />
      )}
    </>
  );
};
