import React, { ChangeEvent } from "react";
import styled, { css } from "styled-components";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";

import { InputFields } from "../policyDrawerTypes";
import { FormFieldContainer } from "../PolicyDrawerStyles";
import { HelperTextTypography } from "../HelperTextTypography";
import { NumberTextField } from "../PolicyDrawerConfigurations/NumberTextField";

import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { convertNumberStringToNumber } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";

const InputStyle = css<{ $maxWidth?: number; $showAsDisabled?: boolean }>`
  && {
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "none")};
    background-color: ${({ $showAsDisabled }) =>
      $showAsDisabled ? muiColors.gray[100] : muiColors.common.white};
    width: 100%;
    & > div {
      height: 32px;
    }
    input {
      font-size: 14px;
      &::placeholder {
        opacity: 1;
        color: ${muiColors.gray[500]};
      }
    }
  }
`;

const StyledInput = styled(TextField)<InputFieldStyleProps>`
  ${InputStyle};
`;

const StyledNumberTextField = styled(NumberTextField)<InputFieldStyleProps>`
  ${InputStyle};
`;

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  color: ${muiColors.gray[500]};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

type InputFieldStyleProps = {
  $maxWidth?: number;
  $showAsDisabled?: boolean;
};

type InputFieldProps = {
  inputType: keyof InputFields;
  label: string;
  maxWidth?: number;
  type?: "text" | "number";
  placeholder?: string;
  helperText?: string;
  tooltipContent?: React.ReactNode;
  showAsDisabled?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
  inputType,
  label,
  maxWidth,
  placeholder,
  type = "text",
  tooltipContent,
  showAsDisabled,
}) => {
  const { setValue, trigger, watch, getFieldState } =
    useFormContext<InputFields>();
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();

  const onInputFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(inputType, e.target.value);
    trigger(inputType);
  };

  const onNumberFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(inputType, convertNumberStringToNumber(e.target.value));
    trigger(inputType);
  };

  const value = watch(inputType);
  const error = getFieldState(inputType)?.error;

  const commonInputProps = {
    $maxWidth: maxWidth,
    $showAsDisabled: showAsDisabled,
    size: "small" as BaseTextFieldProps["size"],
    placeholder: placeholder,
    value: value ?? "",
    disabled: isSubmittingForm || showAsDisabled,
    inputProps: {
      "aria-label": `${label} input field`,
    },
    error: Boolean(error),
  };

  const content = isReadOnlyMode ? (
    <Typography variant={"body2"} sx={{ marginTop: "6px" }}>
      {value as string}
    </Typography>
  ) : (
    <>
      {type === "text" ? (
        <StyledInput {...commonInputProps} onChange={onInputFieldChange} />
      ) : (
        <StyledNumberTextField
          {...commonInputProps}
          onChange={onNumberFieldChange}
          allowDecimal={false}
          allowNegative={false}
          max={99999999}
        />
      )}

      <HelperTextTypography errorMessage={error?.message} />
    </>
  );

  return (
    <FormFieldContainer>
      <FlexContainer>
        <Typography variant={"h5"} color={"text.secondary"}>
          {label}
        </Typography>
        {tooltipContent && (
          <Tooltip title={tooltipContent} placement={"right"}>
            <StyledHelpOutlineIcon />
          </Tooltip>
        )}
      </FlexContainer>
      <Box width={"100%"} aria-label={`${label} container`}>
        {content}
      </Box>
    </FormFieldContainer>
  );
};
