import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography, Divider } from "@komodorio/design-system/deprecated";

import { useElectronClusters } from "../DesktopApp/clustersSelector/useDesktopAppClusters";

import { ClusterRow } from "./ClusterRow/ClusterRow";
import { useUpdatedClustersInfo } from "./useUpdatedClustersInfo";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export * from "./utils/route";

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const GroupTitle = styled(Typography).attrs({
  variant: "title",
  size: "large",
  color: palette.gray[600],
})``;

export const DesktopClustersView: React.FC = () => {
  const allClusters = useElectronClusters();
  const clustersInfo = useUpdatedClustersInfo();

  const [trackedClusters, untrackedClsuters] = useMemo(() => {
    if (!allClusters || !clustersInfo) {
      return [[], []];
    }
    const trackedClusters: ReactElement[] = [];
    const untrackedClsuters: ReactElement[] = [];
    Object.entries(allClusters).forEach(([clusterName, validClusterName]) => {
      const isLocal = clustersInfo[clusterName]?.isLocal ?? false;
      const trackedAt = clustersInfo[clusterName]?.trackedAt ?? null;
      const portIndex = clustersInfo[clusterName]?.index ?? null;
      const list = trackedAt ? trackedClusters : untrackedClsuters;
      list.push(
        <ClusterRow
          key={clusterName}
          clusterName={clusterName}
          validClusterName={validClusterName}
          isLocal={isLocal}
          trackedAt={trackedAt}
          portIndex={portIndex}
        />
      );
    });
    return [trackedClusters, untrackedClsuters];
  }, [allClusters, clustersInfo]);

  return (
    <Container>
      <HeaderContainer>
        <Typography variant="headline" size="medium">
          Clusters Management
        </Typography>
      </HeaderContainer>
      <Divider spacing="1rem" />
      {trackedClusters.length ? (
        <>
          <GroupTitle>Tracked</GroupTitle>
          <GroupContainer>{trackedClusters}</GroupContainer>
        </>
      ) : null}
      {untrackedClsuters.length ? (
        <>
          <GroupTitle>Untracked</GroupTitle>
          <GroupContainer>{untrackedClsuters}</GroupContainer>
        </>
      ) : null}
    </Container>
  );
};
