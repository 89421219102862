export const emailSettingsAriaLabels = {
  mainContainer: "email settings main container",
  isSubscribed: "subscribe to the Komodor digest",
  emailAddress: "email address",
  frequency: "frequency of the Komodor digest",
  scope: "digest scope",
  content: {
    failedDeploys: {
      isEnabled: "failed deploys",
    },
    issues: {
      isEnabled: "unhealthy services",
    },
    reliability: {
      isEnabled: "reliability violations",
      violations: {
        severities: {
          high: "high severities",
          medium: "medium severities",
          low: "low severities",
        },
      },
    },
  },
};
