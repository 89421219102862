import React from "react";
import { Wrench16 } from "@komodorio/design-system/icons";

import { KubernetesCRDResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";

import { InspectionAppBarLink } from "./InspectionAppBarLink";
import { AppBarLink } from "./AppBarLink";
import { PageName, usePages } from "./pages";

export const CrdsInspectionLink: React.FC = () => (
  <InspectionAppBarLink
    resource={KubernetesCRDResource}
    icon={<Wrench16 />}
    indentationLevel={1}
  />
);

const name = PageName.CustomResources;

export const CrdsLink: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
      indentationLevel={1}
    />
  ) : null;
};
