import React from "react";
import { Tag, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { iconPropsBySeverity } from "../utils";
import CustomEventGroup from "../CustomEventGroup";

type SeverityTagProps = { event: CustomEventGroup };

const TagWithBorder = styled(Tag)<{ borderColor: string }>`
  border: 1px solid ${({ borderColor }) => borderColor};
`;

export const SeverityTag: React.FC<SeverityTagProps> = ({
  event: { severity },
}: SeverityTagProps) => {
  const { backgroundColor, fillColor } = iconPropsBySeverity[severity];

  return (
    <TagWithBorder
      color={backgroundColor}
      variant="round"
      borderColor={fillColor}
      aria-label={"severity tag"}
    >
      <Typography variant="uppercase" color={fillColor}>
        {severity}
      </Typography>
    </TagWithBorder>
  );
};
