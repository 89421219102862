import React, { useMemo } from "react";

import {
  WorkloadMetricsContainersSummary,
  WorkloadMetricsSummary,
} from "../../../../../../../../../../../generated/metricsApi";
import { CommonApiResponse } from "../../../../../../../../../../../shared/types/commonApiResponse";
import {
  ViolationSummary,
  ViolationSummaryItem,
} from "../../../ViolationSummary/ViolationSummary";
export interface MetricsSummaryProps {
  res: CommonApiResponse<WorkloadMetricsContainersSummary>;
  containerName: string;
  getSummaryItems: (
    summary: WorkloadMetricsSummary | undefined
  ) => ViolationSummaryItem[];
  title: string;
}

export const MetricsSummary: React.FC<MetricsSummaryProps> = ({
  res,
  containerName,
  getSummaryItems,
  title,
}) => {
  const summaryItems = useMemo(() => {
    const containerData = res.data?.containers.find(
      (container) => container.containerName === containerName
    );
    return getSummaryItems(containerData?.summary);
  }, [containerName, getSummaryItems, res.data?.containers]);

  return (
    <ViolationSummary
      title={title}
      items={summaryItems}
      ariaLabel={title}
      metadataStyle="break lines"
    />
  );
};
