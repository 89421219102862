import { muiColors } from "@komodorio/design-system";

export const colorScheme1 = {
  color1: muiColors.gray[400],
  color2: muiColors.orange[500],
};

export const colorScheme2 = {
  color1: muiColors.gray[400],
  color2: muiColors.teal[600],
};
