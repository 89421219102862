import { muiColors } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { parseISO } from "date-fns";
import React, { useMemo } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridValueFormatterParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";

import { StatusCondition } from "../../../EventGroup/groupedPodEvent/types";
import { dateComparator } from "../../../../../shared/utils/dateUtils";
import { useDateFormatter } from "../../../../../shared/hooks/useDateFormatter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodPhaseGroup, {
  nativePodEventToPodEvent,
  PodEventData,
  podEventToPodEventData,
} from "../../../EventGroup/groupedPodEvent/PodPhaseGroup";
import {
  PodEventsResponse,
  PodNativeEventsResponse,
} from "../../../../../generated/resourcesApi";

import { getMessageFromConditions } from "./utils";

const multiIncludes = (text: string, values: string[]): boolean => {
  const re = new RegExp(values.join("|"));
  return re.test(text);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
function CustomDetailPanelToggle(
  props: Pick<GridRenderCellParams, "id" | "value">
) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);
  if (!hasDetail) {
    return null;
  }

  return (
    <ExpandMore
      sx={{
        transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
        transition: (theme) =>
          theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
      }}
      color="action"
      fontSize="small"
      aria-label={isExpanded ? "Close" : "Open"}
    />
  );
}

export const useEventsTableColumns = (
  eventGroup: PodPhaseGroup,
  fetchedEvents: PodEventData[]
) => {
  const { format } = useDateFormatter({
    timeZoneName: undefined,
    fractionalSecondDigits: 3,
  });

  const events = fetchedEvents.length ? fetchedEvents : eventGroup.events;

  return [
    {
      field: "event",
      headerName: `Event (${events.length})`,
      flex: 1,
      fontSize: "12px",
    },
    {
      field: "first occurrence",
      headerName: "First occurrence",
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) =>
        params.value ? format(params.value) : "",
      sortComparator: (a: Date, b: Date) => dateComparator(a, b),
    },
    {
      field: "last occurrence",
      headerName: "Last occurrence",
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) =>
        params.value ? format(params.value) : "",
      sortComparator: (a: Date, b: Date) => dateComparator(a, b),
    },
    {
      field: "message",
      headerName: "Message",
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <MuiTypography
            sx={{ whiteSpace: "pre-wrap" }}
            variant={"body2"}
            color={
              multiIncludes(params.value.toLowerCase(), [
                "error",
                "failed",
                "didn't",
              ])
                ? muiColors.pink[800]
                : undefined
            }
          >
            {params.value}
          </MuiTypography>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, // Already contains the right field
      renderCell: (params: GridRenderCellParams) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
  ];
};

export const useEventsTableRows = (
  eventGroup: PodPhaseGroup,
  fetchedEvents: PodEventData[]
) => {
  const events = fetchedEvents.length ? fetchedEvents : eventGroup.events;
  return events.map((event) => {
    if (event.eventKind === "PodEvent") {
      return {
        id: event.id,
        kind: event.eventKind,
        event: `Status change - ${
          event?.reason || event?.statusReason || "Condition change"
        }`,
        "first occurrence": event.eventTime,
        "last occurrence": event.eventTime,
        message:
          event.message ??
          event.waitingMessage ??
          getMessageFromConditions(
            event.statusConditions as StatusCondition[]
          ) ??
          "",
        statusConditions: event.statusConditions,
        containerStatuses: (event.containerStatus ?? []).concat(
          ...(event.initContainerStatus ?? [])
        ),
      };
    }

    return {
      id: event.id,
      kind: event.eventKind,
      event: `${event.reason}${
        event.count && event.count > 1 ? ` (${event.count})` : ""
      }`,
      "first occurrence": parseISO(event.firstTimestamp ?? ""),
      "last occurrence": parseISO(event.lastTimestamp ?? ""),
      message: event?.message ?? "",
    };
  });
};

export const useFetchedEvents = (
  nativePodEvents: PodNativeEventsResponse | undefined,
  podEvents: PodEventsResponse | undefined,
  eventGroup: PodPhaseGroup
) => {
  return useMemo(() => {
    return [
      ...(nativePodEvents?.data?.map((e) =>
        nativePodEventToPodEvent(e, eventGroup.serviceId)
      ) ?? []),
      ...(podEvents?.data?.map((e) =>
        podEventToPodEventData(e, eventGroup.serviceId)
      ) ?? []),
    ];
  }, [eventGroup.serviceId, nativePodEvents?.data, podEvents?.data]);
};
