import { useViolations } from "../../../../../../hooks/reliabilityHooks";
import { useReportDDRumWithReactQuery } from "../../../../../../../../shared/hooks/datadog-rum/useReportDDRumWithReactQuery";
import {
  getExpandedImpactGroupViewName,
  violationsGroupByNone,
} from "../../../../../../constants/dataDogReporting";

import { useViolationsRequestParams } from "./useViolationsRequestParams";

import { useHealthType } from "@/components/reliability/hooks/useHealthType";

export const useReportTableLoadingTime = (isGroupedViolations = false) => {
  const { impactGroupId } = useViolationsRequestParams();
  const violationsRes = useViolations();
  const healthType = useHealthType();

  useReportDDRumWithReactQuery({
    result: violationsRes,
    viewName:
      impactGroupId || isGroupedViolations
        ? getExpandedImpactGroupViewName(healthType)
        : violationsGroupByNone,
  });
};
