import React, { useEffect, useMemo, useState } from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { MachineClusters } from "../../../shared/context/electron/electronApiContext";

import KomodorLogoWithText from "./komodor-logo-with-text.svg?react";
import ClusterPickerTile from "./clusterPickerTile";

const OverflowContainer = styled.div`
  overflow-y: auto;
`;

const ClustersContainer = styled.div`
  padding-top: 6.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const ClustersPickerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  gap: 1rem;
  padding: 1rem;
`;

const SelectDeselect = styled(Typography)`
  display: flex;
  gap: 0.3rem;
`;

const Seperator = styled.span`
  color: ${palette.gray[600]};
`;

const SelectButton = styled.span<{ disabled: boolean }>`
  color: ${({ disabled }) =>
    disabled ? palette.darkBlue[100] : palette.darkBlue[500]};

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const ClustersPicker: React.FC<{
  clusters: MachineClusters | undefined;
  isLoading: boolean;
  setSelectedContexts: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ isLoading, clusters, setSelectedContexts }) => {
  const [checkedState, setCheckedState] = useState<boolean[]>([]);

  useEffect(() => {
    setCheckedState(new Array(Object.keys(clusters ?? {})?.length).fill(true));
  }, [clusters]);

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((checked, index) =>
      index === position ? !checked : checked
    );
    setCheckedState(updatedCheckedState);
  };

  const disableSelectAll = useMemo(
    () => checkedState.every((checked) => checked) || isLoading,
    [checkedState, isLoading]
  );

  const disableDiselectAll = useMemo(
    () => checkedState.every((checked) => !checked) || isLoading,
    [checkedState, isLoading]
  );

  const selectAll = () => {
    if (disableSelectAll) {
      return;
    }
    const updatedCheckedState = checkedState.map(() => true);
    setCheckedState(updatedCheckedState);
  };

  const deselectAll = () => {
    if (disableDiselectAll) {
      return;
    }
    const updatedCheckedState = checkedState.map(() => false);
    setCheckedState(updatedCheckedState);
  };

  useEffect(() => {
    const clustersContexts = Object.keys(clusters ?? {});
    const selectedClusters = clustersContexts?.filter(
      (_, i) => checkedState[i]
    );
    setSelectedContexts(selectedClusters ?? []);
  }, [checkedState, clusters, setSelectedContexts]);

  return (
    <>
      <SelectDeselect size="medium">
        <SelectButton disabled={disableSelectAll} onClick={selectAll}>
          Select all
        </SelectButton>{" "}
        <Seperator>|</Seperator>{" "}
        <SelectButton disabled={disableDiselectAll} onClick={deselectAll}>
          Deselect all
        </SelectButton>
      </SelectDeselect>
      <ClustersPickerContainer>
        {Object.keys(clusters ?? {})?.map((cluster, index) => (
          <ClusterPickerTile
            key={cluster}
            cluster={cluster}
            isLoading={isLoading}
            checked={checkedState[index]}
            handleClusterClicked={() => handleOnChange(index)}
          />
        ))}
      </ClustersPickerContainer>
    </>
  );
};

const ClustersSelectors: React.FC<{
  clusters: MachineClusters | undefined;
  isLoading: boolean;
  setSelectedContexts: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ isLoading, clusters, setSelectedContexts }) => {
  return (
    <OverflowContainer>
      <ClustersContainer>
        <KomodorLogoWithText />
        <Typography size="medium">
          Select <b>all</b> the clusters you wish to track* (you can always
          change this later)
        </Typography>

        <ClustersPicker
          isLoading={isLoading}
          clusters={clusters}
          setSelectedContexts={setSelectedContexts}
        />
      </ClustersContainer>
    </OverflowContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ClustersSelectors;
