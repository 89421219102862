import { isMatch } from "lodash";

import { notifyDDError } from "../../../../../shared/hooks/exceptionManagement";
import { ServiceInfo } from "../../../../../shared/types/ServiceInfo";
import { tryParseLabel } from "../utils/tryParseLabel";

import {
  LabelsWorkspace,
  NamespacesWorkspace,
  ServiceIdWorkspace,
  WorkspaceKind,
  WorkspaceValue,
} from "@/generated/workspacesApi";

type GetServiceMatchByIdentifierParams = {
  service: ServiceInfo;
  type: WorkspaceKind;
  workspaceValue: WorkspaceValue;
};

export const getServiceMatchByIdentifiers = ({
  type,
  service,
  workspaceValue,
}: GetServiceMatchByIdentifierParams): boolean => {
  switch (type) {
    case WorkspaceKind.ServiceId:
      return (
        (workspaceValue as ServiceIdWorkspace).serviceIds?.some(
          (serviceId) => serviceId == service.id
        ) ?? false
      );
    case WorkspaceKind.Namespace:
      return (
        (workspaceValue as NamespacesWorkspace).namespaces?.some(
          (namespace) => namespace == service.env
        ) ?? false
      );
    case WorkspaceKind.Label:
      try {
        return (
          (workspaceValue as LabelsWorkspace).labels?.some((lable) => {
            const labelIdentifier = tryParseLabel(lable);
            return service.labels
              ? isMatch(service.labels, labelIdentifier)
              : false;
          }) ?? false
        );
      } catch (error) {
        notifyDDError(error as Error);

        return false;
      }
    /* TODO: add support for other type matchers */
  }
  return false;
};
