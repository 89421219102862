import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { allMonths } from "@/components/Settings/General/NodesCount/consts";

export const mapOptions = (clusters: string[]) =>
  clusters.map((clusterName: string) => ({
    label: clusterName,
    value: clusterName,
  }));

/**
 * Filters and maps clusters for the table based on selected clusters.
 *
 * @param {MuiSelectionOption<string>[]} clustersOptionsForDropdown - The list of cluster options for the dropdown.
 * @param {MuiSelectionOption<string>[]} selectedClusters - The list of selected clusters.
 * @returns {string[]} - The list of cluster names to be displayed in the table.
 */
export const getDisplayedClustersNames = (
  clustersOptionsForDropdown: MuiSelectionOption<string>[],
  selectedClusters: MuiSelectionOption<string>[]
): string[] => {
  return clustersOptionsForDropdown
    .filter(({ value }) => {
      if (!selectedClusters.length) return true;
      return selectedClusters.some(
        (selectedCluster) => selectedCluster.value === value
      );
    })
    .map(({ value }) => value);
};

export const getCurrentMonthIndex = () =>
  allMonths.indexOf(new Date().toLocaleString("default", { month: "short" }));
