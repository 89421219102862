import React, { useCallback } from "react";

export const useDataGridHoverRow = () => {
  const [hoveredRowId, setHoveredRowId] = React.useState<string | null>(null);

  const handleRowHovered = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const rowId = event.currentTarget?.dataset?.id;
      if (rowId && rowId !== hoveredRowId) {
        setHoveredRowId(rowId);
      }
    },
    [setHoveredRowId, hoveredRowId]
  );

  const handleRowLeaved = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const rowId = event.currentTarget?.dataset?.id;
      if (rowId === hoveredRowId) {
        setHoveredRowId(null);
      }

      if (!rowId) {
        setHoveredRowId(null);
      }
    },
    [hoveredRowId, setHoveredRowId]
  );

  return {
    hoveredRowId,
    handleRowHovered,
    handleRowLeaved,
  };
};
