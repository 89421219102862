import { NodeEventsResponseDataInner } from "../../../../generated/resourcesApi";

export enum NodeStatus {
  completed = "resolved",
  unready = "not ready",
  unknown = "unknown",
  deleted = "deleted",
}

export enum ReadyStatus {
  ready = "ready",
  unready = "not ready",
  unknown = "unknown",
}

export enum Pressure {
  low = "low",
  sufficient = "sufficient",
  unknown = "N/A",
}

export const parseNodeStatus = (e: NodeEventsResponseDataInner): NodeStatus =>
  e.isDeleted
    ? NodeStatus.deleted
    : e.ready === "Unknown"
    ? NodeStatus.unknown
    : e.ready === "True"
    ? NodeStatus.completed
    : NodeStatus.unready;

export const getFormattedReadyStatus = (ready: string): ReadyStatus =>
  ready === "Unknown"
    ? ReadyStatus.unknown
    : ready === "True"
    ? ReadyStatus.ready
    : ReadyStatus.unready;

export const getFormattedPressure = (pressure: string | undefined): Pressure =>
  !pressure || pressure === "Unknown"
    ? Pressure.unknown
    : pressure === "True"
    ? Pressure.low
    : Pressure.sufficient;

export const getFormmatedCpu = (
  event_node: NodeEventsResponseDataInner
): string =>
  ((event_node.capacity as Record<string, unknown>) ?? {})["cpu"] as string;

export const parseReadyMessage = (e: NodeEventsResponseDataInner): string => {
  if (!e.readyMessage) {
    return "";
  }
  if (e.readyMessage?.startsWith("Kubelet stopped posting node status")) {
    return "The node is currently not responding, restart the node in order to resolve the issue.";
  }
  return e.readyMessage;
};

export const parseReadyReason = (e: NodeEventsResponseDataInner): string => {
  if (!e.readyReason) {
    return "";
  }
  if (e.readyReason === "NodeStatusUnknown") {
    return "Node is currently unresponsive";
  }
  return e.readyReason;
};
