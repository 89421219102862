import { AvailableActions } from "../inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

export const useAvailableActions = (
  actions: AvailableActions[] | undefined,
  kind: string
) => {
  const { editSecret } = useOverridableFlags();

  switch (kind) {
    case "Secret":
      return editSecret
        ? actions
        : actions?.filter((action) => action !== AvailableActions.Edit);
    default:
      return actions;
  }
};
