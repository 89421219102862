import { get } from "lodash";

import { formatQuantityWithPrettyBinaryUnits } from "@/shared/utils/formatQuantity";

interface ConcreteMetric {
  averageUtilization?: number;
  averageValue?: string;
  type?: string;
}

interface CurrentMetric {
  type: string;
  resource?: {
    name: string;
    current: ConcreteMetric;
  };
}

interface TargetMetric {
  type: string;
  resource: {
    name: string;
    target: ConcreteMetric;
  };
}

export interface MergedMetric {
  current: CurrentMetric | undefined;
  target: TargetMetric | undefined;
}

export const mergeMetrics = (
  currentMetrics: CurrentMetric[] | null,
  targetMetrics: TargetMetric[] | null
): {
  [name: string]: MergedMetric;
} => {
  const metricsResult = (currentMetrics || []).reduce((res, current) => {
    if (!current.resource?.name) return res;
    res[current.resource.name] = {
      current,
      target: undefined,
    };
    return res;
  }, {} as { [name: string]: MergedMetric });

  (targetMetrics || []).forEach((m) => {
    if (m.resource?.name) {
      if (metricsResult[m.resource.name]) {
        metricsResult[m.resource.name].target = m;
      } else {
        metricsResult[m.resource.name] = {
          target: m,
          current: undefined,
        };
      }
    }
  });

  return metricsResult;
};

export const getMetricSummaryText = (mergedMetric: MergedMetric): string => {
  const currentAverageUtilization = get(
    mergedMetric,
    "current.resource.current.averageUtilization"
  );
  let currentAverageValue = get(
    mergedMetric,
    "current.resource.current.averageValue"
  );

  if (
    get(mergedMetric, "current.resource.name") === "memory" &&
    currentAverageValue
  ) {
    currentAverageValue =
      formatQuantityWithPrettyBinaryUnits(currentAverageValue);
  }

  const targetAverageUtilization = get(
    mergedMetric,
    "target.resource.target.averageUtilization"
  );

  try {
    let current =
      currentAverageUtilization && currentAverageValue
        ? `${currentAverageUtilization}% (${currentAverageValue})`
        : "<unknown>";

    let target = targetAverageUtilization
      ? `${targetAverageUtilization}%`
      : "<unknown>";

    if (get(mergedMetric, "target.resource.name") === "memory") {
      if (current === "<unknown>") current = currentAverageValue || "<unknown>";

      if (target === "<unknown>")
        target = get(
          mergedMetric,
          "target.resource.target.averageValue",
          "<unknown>"
        );
    }

    if (current.includes("undefined")) current = "<unknown>";
    if (target.includes("undefined")) target = "<unknown>";

    if (current === "<unknown>" && target === "<unknown>") {
      return "<unknown>";
    }

    return `${current} / ${target}`;
  } catch (error) {
    return "<unknown>";
  }
};
