import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

const Container = styled.div<{ enabled: boolean }>`
  pointer-events: ${({ enabled }) => (enabled ? "auto" : "none")};
  opacity: ${({ enabled }) => (enabled ? "1" : "0.5")};
`;

type Props = React.ComponentProps<typeof AdvancedMultiSelect<string>>;

export const MultiSelectContainer: React.FC<Props> = (props) => {
  const { value, onChange, options } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentValues, setCurrentValues] = useState<
    MuiSelectionOption<string>[]
  >(value || []);

  const onOptionsChange = useCallback(
    (options: MuiSelectionOption<string>[]) => {
      setCurrentValues(options);
      if (!isOpen) onChange?.(options);
    },
    [isOpen, onChange]
  );

  // invoke onChange when dropdown is closed
  const onClose = useCallback(() => {
    setIsOpen(false);
    onChange?.(currentValues);
  }, [currentValues, onChange]);

  return (
    <Container enabled={!!options.length}>
      <AdvancedMultiSelect
        {...props}
        onChange={onOptionsChange}
        onClose={onClose}
        onOpen={() => setIsOpen(true)}
      />
    </Container>
  );
};
