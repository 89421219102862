import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { isEmpty } from "lodash";

import { decodeBase64 } from "../../../../../shared/utils/decodeBase64";
import { DescribeData } from "../common/DescribeData";

const SecretUnitContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  color: ${palette.gray[800]};
`;

const SecretValueContainer = styled.div`
  display: flex;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  padding-block: 6px;
  padding-inline: 8px;
  gap: 8px;
  color: ${palette.gray[600]};
  white-space: pre-wrap;
`;

type SecretData = Record<string, string>;

export const SecretData: React.FC<{ secretData: SecretData }> = ({
  secretData,
}) =>
  isEmpty(secretData) ? null : (
    <DescribeData>
      {Object.keys(secretData).map((key) => (
        <SecretEntry key={key} name={key} value={secretData[key]} />
      ))}
    </DescribeData>
  );

const SecretEntry: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => (
  <SecretUnitContainer>
    <Typography variant="text" size="small">
      {name}
    </Typography>
    <SecretValueContainer>
      <Typography variant="text" size="small">
        {decodeBase64(value)}
      </Typography>
    </SecretValueContainer>
  </SecretUnitContainer>
);
