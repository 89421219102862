import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

type CollapsibleHeaderProps = {
  title: string;
  count?: number;
};

export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({
  title,
  count,
}) => {
  return (
    <Container>
      <Typography variant={"h5"}>{title}</Typography>
      {count !== undefined && (
        <Typography variant={"h5"} color={"text.secondary"}>
          {`(${count})`}
        </Typography>
      )}
    </Container>
  );
};
