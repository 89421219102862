import React, { useMemo, useState } from "react";
import { isEmpty, last } from "lodash";
import styled from "styled-components";

import { Action } from "../common";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LaunchDarklyEvent } from "../../../EventGroup/LaunchDarkly";
import Modal from "../../../Modal";
import { ObjectDiff } from "../../../ObjectDiff";
import DiffEntry from "../deployEvent/DiffEntry";
import deepDiff from "../../../../../shared/utils/deepDiff";
import EventDetailsSection from "../components/EventDetailsSection";
import editIcon from "../../assets/edit.svg";

interface LaunchDarklyChangesProps {
  event: LaunchDarklyEvent;
}

const MarginTop = styled.div`
  margin-block-start: 1.25rem;
`;

const iconDimentions = { height: "50%", width: "50%" };

const useChanges = (event: LaunchDarklyEvent) => {
  return useMemo(() => {
    if (isEmpty(event.previousVersion)) {
      return [];
    }

    if (isEmpty(event.currentVersion)) {
      return [];
    }

    const prev = event.previousVersion as Record<string, unknown>;
    const curr = event.currentVersion as Record<string, unknown>;
    const diff = deepDiff(prev, curr);
    return diff.map((c) => {
      return {
        name: last(c.path) || "",
        previous: `${c.from ?? ""}`,
        current: `${c.to ?? ""}`,
      };
    });
  }, [event]);
};

export const LaunchDarklyChanges: React.FC<LaunchDarklyChangesProps> = ({
  event,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const changes = useChanges(event);
  if (!changes.length) {
    return null;
  }
  return (
    <>
      <EventDetailsSection
        title="Changes"
        icon={editIcon}
        iconDimensions={iconDimentions}
      >
        {changes.map((c) => (
          <DiffEntry key={c.name} change={c} />
        ))}
        <MarginTop>
          <Action onClick={() => setModalOpen(true)}>View diff</Action>
        </MarginTop>
      </EventDetailsSection>
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        title={event.name || ""}
      >
        <ObjectDiff
          oldObj={event.previousVersion}
          newObj={event.currentVersion}
        />
      </Modal>
    </>
  );
};
