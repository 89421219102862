import Drawer from "@mui/material/Drawer";

type MockDrawerProps = {
  open: boolean;
  onClose: () => void;
  imgSrc: string | null;
  children?: React.ReactNode;
  closeButton: React.ReactNode;
};
export const MockDrawer: React.FC<MockDrawerProps> = ({
  open,
  onClose,
  imgSrc,
  children,
  closeButton,
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      slotProps={{
        backdrop: {
          sx: {
            background: "none",
          },
        },
      }}
      PaperProps={{
        sx: {
          overflowY: "scroll",
        },
      }}
    >
      {imgSrc && <img src={imgSrc} width={907} />}
      {closeButton}
      {children}
    </Drawer>
  );
};
