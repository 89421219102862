import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ConnectionFailureEmptyStateErrorProps } from "./ConnectionFailureEmptyStateErrorPage";
import { descriptions } from "./constants";

const getDecription = (noClustersTracked: boolean) => {
  if (noClustersTracked) {
    return descriptions.noClustersTracked;
  }
  return descriptions.failedClustersConnection;
};

export const ErrorDescription: React.FC<
  ConnectionFailureEmptyStateErrorProps
> = ({ noClustersTracked }) => {
  return (
    <Typography variant="headline" size="small" color={palette.gray[800]}>
      {getDecription(noClustersTracked)}
    </Typography>
  );
};
