import { ResourceTableModelRow } from "komodor-types";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { camelCase, has } from "lodash";
import { useMemo } from "react";

import {
  KubernetesServiceTypeEnum,
  MatchExpressions,
  Selector,
} from "../types/ServiceVertex";
import { PodObject } from "../types/pod";

export const customColumns = [
  "CREATION_TIMESTAMP:.metadata.creationTimestamp",
  "CONTAINER_NAMES:.spec.containers[*].name",
  "CONTAINER_READY:.status.containerStatuses[*].ready",
  "CONTAINER_RESTART_COUNTS:.status.containerStatuses[*].restartCount",
  "CONTAINER_WAITING_MESSAGES:.status.containerStatuses[*].state.waiting.message",
  "CONTAINER_WAITING_REASONS:.status.containerStatuses[*].state.waiting.reason",
  "INIT_CONTAINER_NAMES:.spec.initContainers[*].name",
  "INIT_CONTAINER_RESTART_COUNTS:.status.initContainerStatuses[*].restartCount",
  "INIT_CONTAINER_READY:.status.initContainerStatuses[*].ready",
  "INIT_CONTAINER_WAITING_MESSAGES:.status.initContainerStatuses[*].state.waiting.message",
  "INIT_CONTAINER_WAITING_REASONS:.status.initContainerStatuses[*].state.waiting.reason",
  "START_TIME:.status.startTime",
];

export const removeColumnsPodLogs = [
  "creationTimestamp",
  "namespace",
  "controlledBy",
  "containerNames",
  "containerReady",
  "containerRestartCounts",
  "containerWaitingMessages",
  "containerWaitingReasons",
  "initContainerNames",
  "initContainerReady",
  "initContainerRestartCounts",
  "initContainerWaitingMessages",
  "initContainerWaitingReasons",
  "startTime",
];

// read about this here https://app.clickup.com/t/2k5dpp3?comment=888741335
// delete this code when this is not needed anymore
export const customColumnsProps = customColumns
  .map((c) => c.split(":")[0])
  .map(camelCase);
export function tableRowToPod(r: ResourceTableModelRow): PodObject | undefined {
  try {
    const pod = JSON.parse(JSON.stringify(r));
    customColumnsProps.forEach((c) => {
      if (!has(pod, c) || !pod[c]) {
        pod[c] = "";
      }
    });
    return pod;
  } catch {
    return undefined;
  }
}

export const concatMatchLabels = (
  matchLabels: Record<string, string>
): string => {
  return Object.entries(matchLabels)
    .map(([label, value]) => `${label}=${value}`)
    .join(",");
};
export const concatMatchExpressions = (
  matchExpressions: MatchExpressions
): string => {
  return matchExpressions
    .map((exp) =>
      exp.operator === "Exists"
        ? exp.key
        : exp.operator === "DoesNotExist"
        ? "!" + exp.key
        : exp?.values
        ? `${exp.key} ${exp.operator.toLowerCase()} (${exp.values.join(",")})`
        : exp.key
    )
    .join(",");
};

type LabelSelectorType = LabelSelector | Selector | undefined;
export const useLabelSelector = (selector: LabelSelectorType): string => {
  return useMemo(() => getLabelSelector(selector), [selector]);
};

export const getLabelSelector = (selector: LabelSelectorType) => {
  if (!selector?.matchLabels && !selector?.matchExpressions) {
    return "";
  }
  return selector?.matchLabels
    ? concatMatchLabels(selector?.matchLabels ?? {})
    : selector?.matchExpressions
    ? concatMatchExpressions(selector?.matchExpressions ?? {})
    : "";
};

export const extractServiceNameFromPodName = (
  podName: string,
  serviceType: KubernetesServiceTypeEnum | undefined
): string => {
  const sliceEnd =
    serviceType === KubernetesServiceTypeEnum.Deployment ||
    serviceType === KubernetesServiceTypeEnum.Rollout ||
    serviceType === KubernetesServiceTypeEnum.CronJob
      ? -2
      : -1;

  return podName.split("-").slice(0, sliceEnd).join("-");
};
