import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { AriaLabels } from "../../../../shared/config/ariaLabels";

import { NoisyPod } from "./types";
import NoisyPodRow from "./NoisyPodRow";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 16px;
  gap: 4px;
`;

export const ExpandableSection: React.FC<{
  items: NoisyPod[];
  header: JSX.Element;
  startExpanded?: boolean;
}> = ({ items, header, startExpanded }) => {
  const [isExpanded, setIsExpanded] = React.useState(startExpanded ?? false);

  return (
    <div>
      <HeaderContainer
        aria-label={
          AriaLabels.InvestigationMode.NoisyNeighborsStep
            .ExpandableSectionHeader
        }
        style={{
          cursor: "pointer",
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {items.length > 0 && (
          <IconButton
            size="medium"
            aria-label={
              AriaLabels.InvestigationMode.NoisyNeighborsStep
                .ExpandableSectionIcon
            }
          >
            <ExpandMore
              sx={{
                transform: `rotateZ(${isExpanded ? 0 : 270}deg)`,
                transition: (theme) =>
                  theme.transitions.create("transform", {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize="inherit"
            />
          </IconButton>
        )}
        <div>{header}</div>
      </HeaderContainer>
      {isExpanded &&
        items.map((pod, index) => <NoisyPodRow pod={pod} key={index} />)}
    </div>
  );
};
