export const resourcesOptions: string[] = [
  "bindings",
  "componentstatuses",
  "configmaps",
  "endpoints",
  "events",
  "limitranges",
  "namespaces",
  "nodes",
  "persistentvolumeclaims",
  "persistentvolumes",
  "pods",
  "podtemplates",
  "replicationcontrollers",
  "resourcequotas",
  "secrets",
  "serviceaccounts",
  "services",
  "mutatingwebhookconfigurations",
  "validatingwebhookconfigurations",
  "customresourcedefinitions",
  "apiservices",
  "controllerrevisions",
  "daemonsets",
  "deployments",
  "replicasets",
  "statefulsets",
  "selfsubjectreviews",
  "tokenreviews",
  "localsubjectaccessreviews",
  "selfsubjectaccessreviews",
  "selfsubjectrulesreviews",
  "subjectaccessreviews",
  "horizontalpodautoscalers",
  "cronjobs",
  "jobs",
  "certificatesigningrequests",
  "leases",
  "endpointslices",
  "flowschemas",
  "prioritylevelconfigurations",
  "ingressclasses",
  "ingresses",
  "networkpolicies",
  "runtimeclasses",
  "poddisruptionbudgets",
  "clusterrolebindings",
  "clusterroles",
  "rolebindings",
  "roles",
  "priorityclasses",
  "csidrivers",
  "csinodes",
  "csistoragecapacities",
  "storageclasses",
  "volumeattachments",
];
