import React from "react";

import { IconProps } from "../types";

export const Person16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26638 6.5C9.09481 6.5 9.76638 5.82843 9.76638 5C9.76638 4.17157 9.09481 3.5 8.26638 3.5C7.43795 3.5 6.76638 4.17157 6.76638 5C6.76638 5.82843 7.43795 6.5 8.26638 6.5ZM8.26638 8C9.92323 8 11.2664 6.65685 11.2664 5C11.2664 3.34315 9.92323 2 8.26638 2C6.60953 2 5.26638 3.34315 5.26638 5C5.26638 6.65685 6.60953 8 8.26638 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51792 13.4269C4.53385 13.4385 4.5553 13.4513 4.58297 13.4633C4.63441 13.4855 4.69723 13.5 4.76638 13.5H11.7664C11.8355 13.5 11.8984 13.4855 11.9498 13.4633C11.9775 13.4513 11.9989 13.4385 12.0148 13.4269C11.4235 11.6472 9.91151 10.5 8.26638 10.5C6.62125 10.5 5.10922 11.6473 4.51792 13.4269ZM3.06949 13.0307C3.81652 10.6843 5.86115 9 8.26638 9C10.6716 9 12.7162 10.6843 13.4633 13.0307C13.7984 14.0832 12.8709 15 11.7664 15H4.76638C3.66181 15 2.73439 14.0832 3.06949 13.0307Z"
      />
    </svg>
  );
};

Person16.defaultProps = {
  fill: "currentColor",
};
