import { CheckType } from "@/generated/reliabilityApi";

export const getSuffixByCheckType = (checkType: CheckType) => {
  switch (checkType) {
    case CheckType.ThrottledCpu:
    case CheckType.HpaMax:
      return ["%"];
    case CheckType.DeprecatedApis:
      return ["in", "Kubernetes versions"];
    case CheckType.UnderProvisionedWorkloads:
      return ["mCores", "or", "MB"];
    case CheckType.KubernetesEndOfLife:
      return ["months"];
    case CheckType.SinglePointOfFailure:
      return ["minutes"];
    case CheckType.CertificateExpiration:
      return ["days"];
    default:
      return [""];
  }
};
