import React from "react";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import { Cog24 } from "@komodorio/design-system/icons/system/Cog24";
import { ContextMenu } from "@komodorio/design-system/komodor-ui";

import { useSettings } from "@/components/TopBar/hooks/useSettings";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const SettingsButton: React.FC = () => {
  const { items } = useSettings();

  const { TopBar } = AriaLabels;

  return (
    <ContextMenu
      id="organization settings"
      tooltip="Organization settings"
      items={items}
      LinkComponent={NavLink}
      buttonComponent={IconButton}
      ariaLabels={{
        button: TopBar.SettingsButton,
        menu: TopBar.SettingsMenu,
      }}
    >
      <Cog24 />
    </ContextMenu>
  );
};
