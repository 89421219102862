import Selected from "../../../../../shared/components/FilterBar/Interfaces/Selected";
import { EVENTS_VIEW_PATH } from "../components/OverviewPageOld/cardsContent/CardsContainer/internal/constants";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";

type GeneratePathFromFiltersParams = {
  filters: Selected;
  timeWindow: TimeWindow;
};
export const generatePathFromFilters = ({
  filters,
  timeWindow,
}: GeneratePathFromFiltersParams) => {
  const path = EVENTS_VIEW_PATH;
  const searchParams = new URLSearchParams({
    filters: JSON.stringify(filters),
    timeWindow: `${timeWindow.start.getTime()}-${timeWindow.end.getTime()}`,
    timeframe: "custom",
  }).toString();

  return `${path}?${searchParams}`;
};
