import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { EventItemDrawer } from "../EventItemDrawer/EventItemDrawer";
import EventGroup from "../../../../../../common/EventGroup";

type OverviewPageState = {
  eventItemDrawer: {
    openEventItemDrawer: (event: EventGroup) => void;
    closeEventItemDrawer: () => void;
  };
};
const initialState: OverviewPageState = {
  eventItemDrawer: {
    openEventItemDrawer: () => undefined,
    closeEventItemDrawer: () => undefined,
  },
};

const OverviewPageContext = createContext<OverviewPageState>(initialState);

export const OverviewPageContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [eventItemDrawerOpen, setEventItemDrawerOpen] =
    useState<boolean>(false);
  const currentEvent = useRef<EventGroup>();

  const openEventItemDrawer = useCallback((event: EventGroup) => {
    setEventItemDrawerOpen(true);
    currentEvent.current = event;
  }, []);

  const closeEventItemDrawer = useCallback(() => {
    setEventItemDrawerOpen(false);
  }, []);

  const state: OverviewPageState = {
    eventItemDrawer: {
      openEventItemDrawer,
      closeEventItemDrawer,
    },
  };

  return (
    <OverviewPageContext.Provider value={state}>
      {children}
      <EventItemDrawer
        event={currentEvent.current}
        open={eventItemDrawerOpen}
      />
    </OverviewPageContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useOverviewPageContext = (): OverviewPageState =>
  useContext(OverviewPageContext);
