import { useMemo } from "react";

import { CostRightSizingSummary } from "../../../../../../../../../generated/metricsApi";
import { useOverviewPageStore } from "../../../store/overviewPageStore";
import {
  selectAppInsightsData,
  selectRightSizingFilters,
  selectRightSizingRecommendationModal,
  selectRightSizingRecommendationsState,
  selectRightSizingRecommendationsStrategiesState,
  selectRightSizingSummaryState,
  selectSetRightSizingRecommendationsState,
} from "../../../store/overviewPageStoreSelectors";
import { CostStrategy } from "../../../../../../../../CostOptimizationView/types/costOptimizationTypes";
import { useFetchCostRightSizingRecommendationsData } from "../../../../../../../../CostOptimizationView/components/shared/RecommendationsModal/recommendationsModalHooks";

export const MINIMUM_POTENTIAL_COST_SAVING = 1;

export const useShouldDisplayPotentialCostSavings = (): boolean => {
  const {
    potentialCostSavings: { data },
  } = useOverviewPageStore(selectAppInsightsData);

  return (data?.monthlySaving ?? 0) >= MINIMUM_POTENTIAL_COST_SAVING;
};

export const useGetRowData = (
  rowId: string | undefined
): CostRightSizingSummary | undefined => {
  const { data } = useOverviewPageStore(selectRightSizingSummaryState);
  return useMemo(() => {
    return data?.rows?.find((row: CostRightSizingSummary) => row.id === rowId);
  }, [data, rowId]);
};

export const useGetRowStrategy = (rowId: string | undefined): CostStrategy => {
  const { strategy: pageStrategy } = useOverviewPageStore(
    selectRightSizingFilters
  );
  const recommendationStrategies = useOverviewPageStore(
    selectRightSizingRecommendationsStrategiesState
  );

  return useMemo(() => {
    if (!rowId) {
      return pageStrategy;
    }
    return recommendationStrategies[rowId] || pageStrategy;
  }, [rowId, pageStrategy, recommendationStrategies]);
};

export const useFetchRightSizingRecommendationData = (): void => {
  const setRightSizingRecommendationsState = useOverviewPageStore(
    selectSetRightSizingRecommendationsState
  );

  const { rowId } = useOverviewPageStore(selectRightSizingRecommendationModal);
  const rowData = useGetRowData(rowId);
  const prevRecommendationState = useOverviewPageStore(
    selectRightSizingRecommendationsState
  );

  const lastVisitTime = useMemo(() => new Date().getTime(), []);

  useFetchCostRightSizingRecommendationsData({
    rowData,
    prevRecommendationState,
    lastVisitTime,
    onDataFetched: setRightSizingRecommendationsState,
  });
};
