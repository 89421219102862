import React from "react";
import styled from "styled-components";
import { muiPalette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import Komo from "../../../../../../shared/assets/komo.svg?react";
import { appViewAriaLabels } from "../../appViewAriaLabels";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  max-width: 330px;
  text-align: center;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
`;

type ApplicationScopingErrorMessageProps = {
  cta: React.ReactNode | string;
  title: string;
  subtitle?: string;
};

const {
  applicationsScopingErrorPage: { errorMessage: errorMessageAriaLabel },
} = appViewAriaLabels;

export const ApplicationScopingErrorMessage: React.FC<
  ApplicationScopingErrorMessageProps
> = ({ cta, subtitle, title }) => {
  return (
    <Container aria-label={errorMessageAriaLabel}>
      <ContentContainer>
        <Komo />
        <StyledTitle variant={"h4"}>{title}</StyledTitle>
        {subtitle && (
          <Typography variant={"body2"} color={muiPalette.text?.secondary}>
            {subtitle}
          </Typography>
        )}
        <p />
        <Typography variant={"body2"} color={muiPalette.text?.secondary}>
          {cta}
        </Typography>
      </ContentContainer>
    </Container>
  );
};
