import React from "react";

import { useWorkloadMetricsTabContext } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";
import { MetricsAggregationSelector } from "../../../../../../../../../Metrics/MetricsPreferences/MetricsAggregationSelector";
import { workloadsMetricsAriaLabels } from "../../../../../../../../../ResourceView/tabs/WorkloadMetricsTab/ariaLabel";

export const MetricsAggregationSelectorWrapper: React.FC = () => {
  const { selectedAggregationMetric, setSelectedAggregationMetric } =
    useWorkloadMetricsTabContext();

  return (
    <MetricsAggregationSelector
      aggregationMetric={selectedAggregationMetric}
      setAggregationMetric={setSelectedAggregationMetric}
      ariaLabel={workloadsMetricsAriaLabels.aggregationToggle}
    />
  );
};
