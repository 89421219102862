import {
  GenericViolationFilterCategory,
  ViolationCountBy,
} from "@/generated/reliabilityApi";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useShouldEnableApiCall = (
  filterBy: GenericViolationFilterCategory[] | undefined,
  countBy: ViolationCountBy
) => {
  const [dataInUrl] = useStateInUrl();
  const { reliabilityHealthManagement } = useOverridableFlags();

  if (
    !reliabilityHealthManagement &&
    countBy === ViolationCountBy.ImpactGroupType
  )
    return false;

  return dataInUrl.filters !== undefined && filterBy !== undefined;
};
