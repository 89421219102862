import React from "react";

import { useDateFormatter } from "../../../../shared/hooks/useDateFormatter";
import { ONE_MINUTE_IN_MS } from "../../../../shared/constants/time";

import { TickProps, calculateOffset } from "./tickUtils";
import { TextGroup } from "./TextGroup";
import { TSpan } from "./TSpan";

export type TickComponent = React.FC<TickProps>;

const LAST_TICK_OFFSET_IN_MINUTES = 5;
const MAX_MS_WITH_SECONDS = ONE_MINUTE_IN_MS * 5;

const useTickPropsData = ({
  x,
  y,
  payload,
  index,
  visibleTicksCount,
  orientation,
  lastTickXOffset,
}: TickProps) => {
  const { formatToParts, format } = useDateFormatter();
  if (!x || !y || !payload || !Number.isFinite(payload.value)) {
    return null;
  }
  const eventDate = new Date(payload.value);
  const parts = new Map(
    formatToParts(eventDate).map(({ type, value }) => [type, value])
  );
  const xOffset = calculateOffset(index, visibleTicksCount, lastTickXOffset);
  let yOffset = 0;
  if (orientation === "top") {
    yOffset = -25;
  }
  return {
    formattedDate: format(eventDate),
    xOffset,
    yOffset,
    parts,
  };
};

export const DateTimeTick: TickComponent = (props) => {
  const {
    parts,
    xOffset = 0,
    yOffset = 0,
    formattedDate,
  } = useTickPropsData(props) ?? {};
  if (!parts || !props.x || !props.y || !formattedDate) {
    return null;
  }

  const formattedText = getLastTickText(props);
  if (formattedText) {
    return (
      <SimpleTextTick
        text={formattedText}
        x={props.x + xOffset}
        y={props.y + yOffset}
        title={formattedDate}
      />
    );
  }

  const dateText = (["month", "day"] as Intl.DateTimeFormatPartTypes[])
    .filter((part) => props.includedParts?.includes(part))
    .map((part) => parts.get(part))
    .join(" ");
  const timeText = (
    ["hour", "minute", "second"] as Intl.DateTimeFormatPartTypes[]
  )
    .map((part) =>
      props.includedParts?.includes(part) ? parts.get(part) : undefined
    )
    .filter((part) => part)
    .join(":");

  return (
    <TextGroup
      x={props.x + xOffset}
      y={props.y + yOffset}
      fullText={formattedDate}
    >
      {dateText && <TSpan dy={12} text={dateText} />}
      {timeText && (
        <TSpan dy={12} text={timeText + ` ${parts.get("dayPeriod")}`} />
      )}
    </TextGroup>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getTickComponent = (
  startTime: Date,
  endTime: Date
): TickComponent => {
  const startDate = startTime.getDate();
  const endDate = endTime.getDate();
  const timeRange = endTime.getTime() - startTime.getTime();

  const includedFormatParts: Intl.DateTimeFormatPartTypes[] =
    startDate !== endDate
      ? timeRange <= MAX_MS_WITH_SECONDS
        ? ["month", "day", "hour", "minute", "second"]
        : ["month", "day", "hour", "minute"]
      : timeRange <= MAX_MS_WITH_SECONDS
      ? ["hour", "minute", "second"]
      : ["hour", "minute"];

  return (props) => (
    <DateTimeTick {...props} includedParts={includedFormatParts} />
  );
};

const SimpleTextTick: React.FC<{
  x: number;
  y: number;
  text: string;
  title: string;
}> = ({ x, y, text, title }) => {
  return (
    <TextGroup x={x} y={y} fullText={title}>
      <tspan
        x={0}
        dy={12}
        textAnchor="right"
        style={{ fontWeight: 700, userSelect: "none" }}
      >
        {text}
      </tspan>
    </TextGroup>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const lastTickFormatter = (value: string | number | Date): string => {
  const isTickNumber = typeof value === "number";
  const isTickAlmostNow =
    isTickNumber &&
    Math.abs(value - Date.now()) <
      LAST_TICK_OFFSET_IN_MINUTES * ONE_MINUTE_IN_MS;
  if (isTickAlmostNow) {
    return "now";
  }
  return "";
};

const getLastTickText = (props: TickProps) => {
  const isLastTick = props.index === (props.visibleTicksCount ?? 0) - 1;
  if (
    props.tickFormatter &&
    props.payload?.value &&
    isLastTick &&
    props.index
  ) {
    return props.tickFormatter(props.payload?.value, props.index);
  }
  return null;
};
