import React from "react";
import { Link, Typography } from "@komodorio/design-system/deprecated";
import Tooltip from "@mui/material/Tooltip";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

import EventGroup from "../../common/EventGroup";
import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/Insight";
import { getColor } from "../../common/ProcessList/Status";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { ServiceStatus } from "../../ResourceView/headers/KomodorServiceHealthAvailability";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { MonitorData } from "../../../generated/monitorsApi";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { lightMuiTooltipStyle } from "../../../shared/styles/tooltip";

export const CurrentAvailabilityHealthTooltip: React.FC<{
  issue?: MonitorData;
  correlatedIssue: WorkflowIssueEventGroup | undefined;
  setCorrelatedEvent?: (event: EventGroup | null) => void;
  service: ServiceInfo | undefined;
  children: React.ReactElement;
}> = ({ issue, correlatedIssue, setCorrelatedEvent, service, children }) => {
  const status = (
    service?.healthy === undefined
      ? "inactive/deleted"
      : service.healthy
      ? ServiceStatus.HEALTHY
      : ServiceStatus.UNHEALTHY
  ).toLowerCase();

  return (
    <Tooltip
      placement="bottom"
      componentsProps={lightMuiTooltipStyle}
      arrow={false}
      title={
        <InsightTooltipContainer
          aria-label={AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltip}
        >
          <InsightTooltipDetails>
            <Typography variant="title">Current Availability Health</Typography>
            <span />
            {issue?.eventTime && (
              <>
                <Typography>Started</Typography>
                <Typography bold>
                  {formatDistanceToNowStrict(parseISO(issue.eventTime))} ago
                </Typography>
              </>
            )}
            <Typography>Status</Typography>
            <Typography variant="uppercase" color={getColor(status)}>
              {status}
            </Typography>
            {service?.replicas && (
              <>
                <Typography>Replicas</Typography>
                <Typography bold>
                  {service.replicas.ready}/{service.replicas.desired}
                </Typography>
              </>
            )}
            {issue?.reasons?.length ? (
              <>
                <Typography>Reason</Typography>
                <Typography bold>{issue.reasons.join(", ")}</Typography>
              </>
            ) : null}
            {correlatedIssue && setCorrelatedEvent && (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  setCorrelatedEvent(correlatedIssue);
                }}
                aria-label={
                  AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltipLink
                }
              >
                View details
              </Link>
            )}
          </InsightTooltipDetails>
        </InsightTooltipContainer>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
