import { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { CustomResourceColumnDefinition } from "kubernetes-types/apiextensions/v1.d";

import useAtmGetResource, {
  AtmGetResourceResponseType,
} from "../../../../../shared/hooks/ATM/requests/useAtmGetResource";
import { AtmOutputType } from "../../../../../shared/hooks/ATM/useAtmRequest";
import { KubernetesCRDResource } from "../../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";

const SEARCH_IN_ALL_NAMESPACES = "";

interface UseGetCRDPrinterColumnsArgs {
  agentId: string;
  cluster: string;
  crdName?: string;
}

type UseGetCRDPrinterColumnsRes = CustomResourceColumnDefinition[] | undefined;

// Gets CRD by given name and extracts its additionalPrinterColumns
export const useGetCRDPrinterColumns = ({
  agentId,
  cluster,
  crdName,
}: UseGetCRDPrinterColumnsArgs): UseGetCRDPrinterColumnsRes => {
  const [atmRes, setAtmRes] = useState<AtmGetResourceResponseType>();
  const { execute, errorMessage } = useAtmGetResource(
    agentId,
    cluster,
    SEARCH_IN_ALL_NAMESPACES,
    KubernetesCRDResource.Kind,
    crdName || "",
    AtmOutputType.Json,
    (response: AtmGetResourceResponseType) => setAtmRes(response)
  );

  useEffect(() => {
    if (errorMessage || atmRes?.failureMessage) {
      return;
    }

    if (!atmRes?.data) {
      execute();
    }
  }, [execute, crdName, atmRes?.data, errorMessage, atmRes?.failureMessage]);

  return useMemo(() => {
    if (!atmRes?.data) {
      return;
    }

    return get(
      atmRes,
      "data.spec.versions[0].additionalPrinterColumns"
    ) as CustomResourceColumnDefinition[];
  }, [atmRes]);
};
