type KomodorWorkflowId = {
  cluster: string;
  namespace: string;
  engine: string;
  dagId: string;
};
export const parseKomodorWorkflowId = (
  komodorWorkflowId: string
): KomodorWorkflowId => {
  const [cluster, namespace, engine, dagId] = komodorWorkflowId.split("|");
  return {
    cluster,
    namespace,
    engine,
    dagId,
  };
};

export const buildKomodorWorkflowId = ({
  cluster,
  namespace,
  engine,
  dagId,
}: KomodorWorkflowId): string => {
  return `${cluster}|${namespace}|${engine}|${dagId}`;
};
