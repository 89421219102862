import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { supportedStaticCheckTypes } from "../policyDrawerConstants";

import { InputFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { CheckType } from "@/generated/reliabilityApi";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const useCanSubmitForm = () => {
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  const { formState, getValues } = useFormContext<InputFields>();
  const { policyConfigurations } = usePolicyDrawerContext();

  const isStaticCheckConfigurationsEmpty = useMemo(
    () =>
      Object.keys(policyConfigurations).filter((checkType) =>
        supportedStaticCheckTypes.includes(checkType as CheckType)
      ).length === 0,
    [policyConfigurations]
  );

  const isStaticCheckConditionsMet =
    !isStaticCheckConfigurationsEmpty && reliabilityBestPracticesTab;

  return (
    Object.keys(formState.errors).length === 0 &&
    (Object.keys(getValues().configurations ?? {}).length > 0 ||
      isStaticCheckConditionsMet)
  );
};
