import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { Dictionary } from "../../../shared/types/Dictionary";

import { ServiceInfoWithIndex } from "./types";

export const servicesListToDictionary = (
  services: ServiceInfo[] | undefined
): Dictionary<ServiceInfoWithIndex> => {
  return (
    services?.reduce<Dictionary<ServiceInfoWithIndex>>((acc, service, idx) => {
      acc[service.id] = { ...services[idx], index: idx };
      return acc;
    }, {}) ?? {}
  );
};
