import { ValidationError } from "yup";
import { useCallback } from "react";

import { ApiDeprecationState, CheckType } from "@/generated/reliabilityApi";
import { Severity } from "@/components/reliability/ReliabilityTypes";
import { getConfigurationFieldNamesByCheckType } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import { SeverityValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useSetFieldError } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useSetFieldError";
import { useValidateNumericFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useValidateNumericFields";
import { deprecatedApiStateSchema } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/apiDeprecationStateValidationSchema";

type ValidateDeprecatedApisRecord = Partial<
  Record<keyof SeverityValues, { type: ApiDeprecationState; value: number }>
>;

export const useValidateDeprecatedApiFields = () => {
  const validateNumericFields = useValidateNumericFields();
  const validatedDeprecatedApisState = useValidatedDeprecatedApisState();
  return useCallback(
    (values: SeverityValues) => {
      const filteredValues = Object.entries(values)
        .filter(([, value]) => !value.includes(undefined))
        .reduce<ValidateDeprecatedApisRecord>(
          (acc, [key, [apiState, value]]) => {
            acc[key as keyof SeverityValues] = {
              type: apiState as ApiDeprecationState,
              value: value as number,
            };
            return acc;
          },
          {}
        );

      const { stateField, versionsAheadField } =
        getConfigurationFieldNamesByCheckType(CheckType.DeprecatedApis);

      const { highValues, mediumValues, lowValues } = filteredValues;

      validateNumericFields({
        configurationName: versionsAheadField,
        highNumber: highValues?.value ?? NaN,
        mediumNumber: mediumValues?.value ?? NaN,
        lowNumber: lowValues?.value ?? NaN,
        isReverseSeverityOrder: true,
      });

      validatedDeprecatedApisState({
        values: filteredValues,
        configurationName: stateField,
      });
    },
    [validateNumericFields, validatedDeprecatedApisState]
  );
};

type ValidatedDeprecatedApisStateParams = {
  configurationName: string;
  values: ValidateDeprecatedApisRecord;
};

const useValidatedDeprecatedApisState = () => {
  const setFieldError = useSetFieldError();

  return useCallback(
    async ({
      values,
      configurationName,
    }: ValidatedDeprecatedApisStateParams) => {
      try {
        await deprecatedApiStateSchema.validate({
          high: values.highValues?.type,
          medium: values.mediumValues?.type,
          low: values.lowValues?.type,
        });
      } catch (err) {
        const validationError = err as ValidationError;
        setFieldError({
          configurationName,
          severity: validationError.params?.path as Severity,
          errorMessage: validationError.message,
        });
      }
    },
    [setFieldError]
  );
};
