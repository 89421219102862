import { useCallback } from "react";
import { isEqual } from "lodash";

import {
  servicesUIDsSelector,
  setServicesUIDsSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";

export const useSetServicesUIDs = () => {
  const currentServiceUID = useAppViewsStore(servicesUIDsSelector);
  const setServiceUIDs = useAppViewsStore(setServicesUIDsSelector);

  return useCallback(
    (servicesUid?: string[]) => {
      if (servicesUid && !isEqual(currentServiceUID, servicesUid)) {
        setServiceUIDs(servicesUid);
      }
    },
    [currentServiceUID, setServiceUIDs]
  );
};
