import styled from "styled-components";
import { palette } from "@komodorio/design-system";

export const Tr = styled.tr<{ clickable?: boolean }>`
  td {
    background-color: white;
  }
  cursor: ${({ clickable = true }) => (clickable ? "pointer" : "default")};
  &.deleted td {
    background-color: ${palette.gray[50]};
  }
  &:hover td {
    ${({ clickable = true }) => (clickable ? palette.blue[100] : 0)};
  }
`;
