import React from "react";
import styled from "styled-components";

import { BasicViolation } from "../../../../../../../../../generated/reliabilityApi";
import { SeverityChip } from "../../../../../../shared/SeverityChip/SeverityChip";

const Container = styled.div``;
const StyledSeverityChip = styled(SeverityChip)`
  && {
    width: 74px;
  }
`;
type SeverityColumnProps = Pick<BasicViolation, "severity">;

export const SeverityColumn: React.FC<SeverityColumnProps> = ({ severity }) => {
  return (
    <Container>
      <StyledSeverityChip severity={severity} />
    </Container>
  );
};
