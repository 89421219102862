import { useMemo } from "react";
import { format } from "date-fns";
import Skeleton from "@mui/material/Skeleton";
import { GridColDef } from "@mui/x-data-grid-pro";

import { TitleWithPath } from "../../../../../shared/table/ReliabilityTitleColumn";
import { TypeColumn } from "../IssuesTable/columns/TypeColumn";
import { formatDurationFromNow } from "../realtimeUtils";
import { StatusColumn } from "../../../../../shared/table/StatusColumn";
import { HorizentalContainer } from "../IssuesTable/columns/columnsUtils";

import { BasicViolation } from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";
import { getViolationTitle } from "@/components/reliability/utils/reliabilityUtils";

export const columnsConfig: Dictionary<GridColDef<BasicViolation>> = {
  type: {
    field: "checkType",
    headerName: "Type",
    width: 120,
    sortable: false,
  },
  issue: {
    field: "issue",
    headerName: "Issue",
    sortable: false,
    flex: 1,
  },
  started: {
    field: "createdAt",
    headerName: "Started",
    sortable: false,
    width: 120,
  },
  duration: {
    field: "duration",
    headerName: "Duration",
    sortable: false,
    width: 120,
  },
  status: {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 200,
  },
};

export const useIssuesColumns = (): GridColDef<BasicViolation>[] => {
  return useMemo(
    () => [
      {
        ...columnsConfig.type,
        renderCell: ({ row: { checkType } }) => {
          return <TypeColumn checkType={checkType} />;
        },
      },
      {
        ...columnsConfig.issue,
        renderCell: ({ row: { checkType, komodorUid, supportingData } }) => {
          return (
            <TitleWithPath
              title={getViolationTitle(checkType, supportingData)}
              komodorUid={komodorUid}
            />
          );
        },
      },
      {
        ...columnsConfig.started,
        valueGetter: (params) => {
          return params.value
            ? format(params.value * 1000, "MMM dd, yyyy")
            : "";
        },
      },
      {
        ...columnsConfig.duration,
        valueGetter: ({ row: { createdAt } }) => {
          return formatDurationFromNow(createdAt);
        },
      },
      {
        ...columnsConfig.status,
        renderCell: ({ row }) => {
          return <StatusColumn {...row} />;
        },
      },
    ],
    []
  );
};

export const useEmptyColumns = (): GridColDef<BasicViolation>[] => {
  return useMemo(
    () => [
      {
        ...columnsConfig.type,
        renderCell: () => {
          return (
            <HorizentalContainer>
              <Skeleton variant="rounded" width={18} height={18} />
              <Skeleton variant="rounded" width={70} height={18} />
            </HorizentalContainer>
          );
        },
      },
      {
        ...columnsConfig.issue,
        renderCell: () => {
          return (
            <div>
              <Skeleton
                variant="rounded"
                width={242}
                height={14}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton variant="rounded" width={159} height={10} />
            </div>
          );
        },
      },
      {
        ...columnsConfig.started,
        renderCell: () => {
          return <Skeleton variant="rounded" width={100} height={10} />;
        },
      },
      {
        ...columnsConfig.duration,
        renderCell: () => {
          return <Skeleton variant="rounded" width={65} height={10} />;
        },
      },
      {
        ...columnsConfig.status,
        renderCell: () => {
          return <Skeleton variant="rounded" width={80} height={10} />;
        },
      },
    ],
    []
  );
};
