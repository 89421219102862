import { KubernetesServiceTypeEnum } from "../../../../../../../../../shared/types/ServiceVertex";
import { ServiceKindState } from "../../../../../types/appViewsResourcesTypes";

export const serviceKindToStateType: Partial<
  Record<KubernetesServiceTypeEnum, keyof ServiceKindState>
> = {
  [KubernetesServiceTypeEnum.Deployment]: "deployments",
  [KubernetesServiceTypeEnum.StatefulSet]: "statefulsets",
  [KubernetesServiceTypeEnum.DaemonSet]: "daemonsets",
  [KubernetesServiceTypeEnum.Rollout]: "rollouts",
  [KubernetesServiceTypeEnum.Job]: "jobs",
  [KubernetesServiceTypeEnum.CronJob]: "cronjobs",
};
