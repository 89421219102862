import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { isNumber, isString } from "lodash";
import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";

import { getRandRange } from "../../../../../../../../../shared/utils/getRandRange";

const Container = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

const SummaryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SingleLineItem = styled.div`
  display: flex;
  gap: 8px;
`;

const BreakLinesItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelTypography = styled(Typography)`
  && {
    white-space: nowrap;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  && {
    margin-bottom: 6px;
  }
`;

export const ValueTypography = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
})``;

export type ViolationSummaryValue = string | number | ReactElement;

export type ViolationSummaryItem = {
  label: string;
  value: ViolationSummaryValue;
};

const getValue = (value: ViolationSummaryValue) =>
  isNumber(value) || isString(value) ? (
    <ValueTypography>{value}</ValueTypography>
  ) : (
    value
  );

interface Props {
  title?: string;
  items: ViolationSummaryItem[];
  ariaLabel?: string;
  titleProps?: React.ComponentProps<typeof Typography>;
  itemProps?: React.ComponentProps<typeof Typography>;
  loadingSkeletonsNum?: number;
  metadataStyle?: "single line" | "break lines";
}

export const ViolationSummary: React.FC<Props> = ({
  title,
  titleProps,
  items,
  itemProps,
  ariaLabel,
  loadingSkeletonsNum,
  metadataStyle = "single line",
}) => {
  const isBreakLinesStyle = metadataStyle === "break lines";
  const summaryItems = useMemo(() => {
    const ItemComponent = isBreakLinesStyle ? BreakLinesItem : SingleLineItem;

    return items.map((item) => (
      <ItemComponent key={item.label}>
        <LabelTypography
          variant={isBreakLinesStyle ? "body2" : "h5"}
          color={isBreakLinesStyle ? "text.secondary" : "text.primary"}
          {...itemProps}
        >
          {item.label}
          {!isBreakLinesStyle && ":"}
        </LabelTypography>
        {getValue(item.value)}
      </ItemComponent>
    ));
  }, [isBreakLinesStyle, itemProps, items]);

  const isLoading = !!loadingSkeletonsNum && items.length === 0;
  const loadingContent = useMemo(() => {
    if (!isLoading) return null;
    return Array.from({ length: loadingSkeletonsNum ?? 0 }).map((_, index) => (
      <StyledSkeleton
        key={index}
        variant={"rounded"}
        width={`${Math.round(getRandRange(140, 220))}px`}
      />
    ));
  }, [isLoading, loadingSkeletonsNum]);

  return (
    <Container aria-label={ariaLabel} gap={isBreakLinesStyle ? 24 : 8}>
      {title && (
        <Typography
          variant="h5"
          color={isBreakLinesStyle ? "text.primary" : "text.secondary"}
          {...titleProps}
        >
          {title}:
        </Typography>
      )}
      <SummaryList>
        {summaryItems}
        {loadingContent}
      </SummaryList>
    </Container>
  );
};
