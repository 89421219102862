// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowConfigType } from "../../../monitorsView/common/types";

export const WORKFLOW_CONFIG_TYPES = {
  [WorkflowConfigType.PVC]: "PVC issue",
  [WorkflowConfigType.NodeIssue]: "Node issue",
  [WorkflowConfigType.Availability]: "Availability issue",
  [WorkflowConfigType.Job]: "Job issue",
  [WorkflowConfigType.CronJob]: "CronJob issue",
  [WorkflowConfigType.Deploy]: "Deploy",
  [WorkflowConfigType.Pod]: "Standalone Pod issue",
  [WorkflowConfigType.Workflow]: "Workflow Pod issue",
};
