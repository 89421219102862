import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { ServiceInfo } from "../../../../shared/types/ServiceInfo";
import { KubernetesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import EventGroup from "../../EventGroup";
import { getHealthStatusGroup } from "../../statusMapper";
import { useOpenIssueTooltip } from "../../../OpenIssues/useOpenIssueTooltip";
import { useOpenIssueByResource } from "../../../OpenIssues/useOpenIssuesByResource";

import { StatusTag } from "./StatusCell/StatusTag";

interface HealthCellProps {
  resourceType: KubernetesResource;
  service: ServiceInfo | undefined;
  cellName: string;
  cellData: string;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  rowId: string;
}
export const HealthCell: React.FC<HealthCellProps> = ({
  resourceType,
  service,
  cellName,
  cellData,
  setCorrelatedEvent,
  rowId,
}) => {
  const openIssue = useOpenIssueByResource(
    undefined,
    undefined,
    resourceType.Kind,
    undefined,
    service?.id
  );

  const [tooltip, tooltipId] = useOpenIssueTooltip(
    openIssue,
    service,
    setCorrelatedEvent,
    rowId
  );

  const statusGroup = getHealthStatusGroup(cellData);
  return (
    <td key={cellName}>
      {cellData && (
        <TooltipWrapper tooltipId={tooltipId}>
          <StatusTag status={cellData} statusGroup={statusGroup} uppercase />
        </TooltipWrapper>
      )}
      {openIssue && tooltip}
    </td>
  );
};
