import React from "react";

import { PageName, usePages } from "./pages";
import { AppBarLink } from "./AppBarLink";

const name = PageName.k8sAddonsCertManager;

export const K8sAddonCertManager: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
    />
  ) : null;
};
