import React from "react";
import styled from "styled-components";

import { TextContainer } from "../../../violationDrawerStyles";

const HowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HowListContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
`;

export const WhyDescription: React.FC = () => (
  <TextContainer>
    Noisy neighbors are workloads that consume more resources than they have
    been allocated with (requests) in a way that generates pressure on a node
    which eventually lead to termination/restarts of pods from other workloads
    and potentially availability issues/downtime of those workloads.
  </TextContainer>
);

export const HowDescription: React.FC = () => (
  <HowContainer>
    <TextContainer>
      Try implementing resource management strategies and optimizing the cluster
      environment. Here are several approaches to mitigate noisy neighbor
      issues:
    </TextContainer>
    <HowListContainer>
      <TextContainer>1.</TextContainer>{" "}
      <TextContainer>
        <b>Resource Limits and Requests:</b> <br />
        Set appropriate resource limits and requests for CPU and memory in your
        pod specifications. This helps Kubernetes allocate resources more
        effectively and prevents a single pod from monopolizing resources.
      </TextContainer>
      <TextContainer>2.</TextContainer>
      <TextContainer>
        <b>Quality of Service (QoS) Classes:</b>
        <br /> Use Kubernetes Quality of Service classes to categorize pods
        based on their resource requirements. Pods can be classified as
        Guaranteed, Burstable, or BestEffort. This allows the scheduler to make
        intelligent decisions when placing pods on nodes.
      </TextContainer>
      <TextContainer> 3. </TextContainer>
      <TextContainer>
        <b>Horizontal Pod Autoscaling (HPA):</b> <br />
        Implement Horizontal Pod Autoscaling to dynamically adjust the number of
        pod replicas based on resource utilization. This helps distribute the
        workload and prevents resource contention.
      </TextContainer>
    </HowListContainer>
  </HowContainer>
);
