import React, { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import { useGetDeployEventsQuery } from "../../../../../../../../generated/graphql";
import { useCurrentAppViewContext } from "../../../../../../context/currentAppViewContext";
import DeployEventGroup, {
  groupDeployEvents,
} from "../../../../../../../common/EventGroup/deployEvent/DeployEventGroup";
import { EventItem } from "../EventItem/EventItem";
import { CardContentDisplayHandler } from "../CardContentDisplayHandler/CardContentDisplayHandler";
import { ArrowCircleIcon } from "../emptyStateIcons/ArrowCircle";
import { getServiceIdFromDeployEventGroup } from "../../../../utils/getServiceId";
import { useAppViewsStore } from "../../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  serviceIdsSelector,
} from "../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

export const RecentDeploys: React.FC = () => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const { timeWindow } = useCurrentAppViewContext();
  const [deployEvents, setDeployEvents] = useState<DeployEventGroup[]>();

  const variables = useMemo(() => {
    if (serviceIds.length === 0) return undefined;
    return {
      serviceIds,
      from: timeWindow.start.toISOString(),
      to: timeWindow.end.toISOString(),
    };
  }, [serviceIds, timeWindow.end, timeWindow.start]);

  //TODO: migrate to DEPLOY_EVENTS_SEARCH
  const [{ data, fetching }, refresh] = useGetDeployEventsQuery({
    variables,
    pause: !!deployEvents,
  });

  useEffect(() => {
    refresh();
  }, [currentAppView, refresh]);

  useEffect(() => {
    if (!data) return;
    const deploys: DeployEventGroup[] = groupDeployEvents(
      data.event_deploy,
      {}
    );
    const sortedByEndDateDescending = deploys
      .sort((a, b) => b.endTime.getTime() - a.endTime.getTime())
      .filter(
        (deployEvent) =>
          deployEvent.endTime.getTime() >= timeWindow.start.getTime()
      );
    if (!isEqual(sortedByEndDateDescending, deployEvents)) {
      setDeployEvents(sortedByEndDateDescending);
    }
  }, [data, deployEvents, timeWindow.start]);

  const content = useMemo(
    () =>
      deployEvents?.map((deployEvent) => {
        const serviceId = getServiceIdFromDeployEventGroup(deployEvent);
        return (
          <EventItem
            event={deployEvent}
            additionalRows={serviceId ? [serviceId] : undefined}
            key={deployEvent.id}
          />
        );
      }),
    [deployEvents]
  );

  return (
    <CardContentDisplayHandler
      fetching={fetching}
      isEmptyState={content?.length === 0}
      emptyStateDisplay={{
        icon: <ArrowCircleIcon />,
        dataType: "recent deploys",
      }}
    >
      {content}
    </CardContentDisplayHandler>
  );
};
