const shufflePassword = (prevPassword: string): string => {
  const letters = prevPassword.split("");

  for (let i = prevPassword.length - 1; i > 0; i--) {
    const randomPosition = Math.floor(Math.random() * (i + 1));
    const tmp = letters[i];
    letters[i] = letters[randomPosition];
    letters[randomPosition] = tmp;
  }
  return letters.join("");
};

export const passwordGenerator = (): string => {
  const PASSWORD_LENGTH = 42;
  const smallLeters = "abcdefghijklmnopqrstuvwxyz";
  const bigLeters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const digits = "0123456789";
  const charset = smallLeters + bigLeters + digits;

  let retVal = "";
  retVal += smallLeters.charAt(Math.floor(Math.random() * smallLeters.length));
  retVal += bigLeters.charAt(Math.floor(Math.random() * bigLeters.length));
  retVal += digits.charAt(Math.floor(Math.random() * digits.length));

  for (let i = 3; i < PASSWORD_LENGTH; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return shufflePassword(retVal);
};
