import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Typography,
} from "@komodorio/design-system/deprecated";
import { Deploy, Loader16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import {
  ActionTypes,
  TaskType,
  ActionMetadataHelmChartUninstall,
} from "komodor-types";

import { WhiteContainer } from "../../../ResourceView/common/resourceUtils";
import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { KubernetesHelmResource } from "../../inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { StyledDrawer } from "../../../ResourceView/ResourceDrawer";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import HelmChart from "../helmChart";
import { useHelmChartSummaryToChart } from "../hooks/useHelmCharts";
import { LinesLoader } from "../../../common/loaders/Line";
import { taskResultToMessage } from "../utils";
import { ActionFiredMessage } from "../tabs/components/ActionFiredMessage";

import { HelmErrorMessage } from "./HelmErrorMessage";
import { LoaderContainer, StyledModal, Z_INDEX_ABOVE_MODAL } from "./styles";
import HelmDrawerHeader from "./upgrade/ChartUpgradeHeader";
import { HelmChartRollbackModalSummaryProps } from "./ChartRollbackDrawer";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto 43fr auto 3fr;
  height: 100%;
`;
const StyledHeader = styled(HelmDrawerHeader)`
  width: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 0.3rem;
`;
const StyledButtonsContainer = styled(ButtonsContainer)`
  width: 100%;
`;

export interface HelmChartUninstallDrawerProps {
  chart: HelmChart;
  open: boolean;
  onClose: () => void;
}

const ChartUninstallDrawerContent: React.FC<{
  chart: HelmChart;
  onClose: () => void;
  showErrors?: boolean;
}> = ({ chart, onClose, showErrors }) => {
  const [done, setDone] = useState(false);
  const [commandSent, setCommandSent] = useState(false);
  const agentId = useActiveAgent(chart?.cluster) ?? "";
  const metadata: ActionMetadataHelmChartUninstall = useMemo(() => {
    return {
      cluster: chart.cluster,
      chartName: chart.name,
      namespace: chart.namespace,
      type: ActionTypes.UninstallHelmChart,
    };
  }, [chart.cluster, chart.name, chart.namespace]);

  const {
    execute: uninstall,
    result: uninstallResult,
    failureMessage,
    deniedObject,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  useEffect(() => {
    if (commandSent && !isFetching && !done) {
      setDone(true);
      const message = taskResultToMessage(
        uninstallResult as string,
        failureMessage,
        deniedObject
      );
      const pattern = new RegExp(`release "${chart.name}" uninstalled`);
      const isSuccess = pattern.test(uninstallResult as string);
      chart.setLastActionResult({
        command: `Uninstall version ${chart.currentRevision}`,
        success: isSuccess,
        message: message,
        timestamp: new Date(),
      });
      if (!(showErrors && !isSuccess)) {
        onClose();
      }
    }
  }, [
    chart,
    chart.name,
    commandSent,
    deniedObject,
    done,
    failureMessage,
    isFetching,
    onClose,
    showErrors,
    uninstallResult,
  ]);

  return (
    <Grid aria-label="helm-uninstall">
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledHeader
            title={"Uninstall"}
            icon={Deploy}
            onClose={onClose}
            labels={[
              { name: "type", value: KubernetesHelmResource.Kind },
              { name: "name", value: chart.name },
              { name: "cluster", value: chart.cluster },
              { name: "namespace", value: chart.namespace },
            ]}
          />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <ResponsiveLayout>
        {chart.renderResources(
          <Typography variant={"text"} size={"medium"}>
            The following resources will be deleted from the <b>{chart.name}</b>{" "}
            namespace
          </Typography>
        )}
      </ResponsiveLayout>
      <Divider />
      <WhiteContainer>
        <ResponsiveLayout>
          <StyledButtonsContainer>
            <Button
              size="small"
              variant="danger"
              onClick={() => {
                if (!isFetching) {
                  setCommandSent(true);
                  uninstall();
                }
              }}
            >
              {isFetching ? <Loader16 /> : "Uninstall"}
            </Button>
            <Button size="small" variant="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
            {isFetching && (
              <ActionFiredMessage
                locationType="drawer"
                isFetching={isFetching}
              />
            )}
            {showErrors &&
              done &&
              chart.lastActionResult?.success === false && (
                <HelmErrorMessage result={chart.lastActionResult} />
              )}
          </StyledButtonsContainer>
        </ResponsiveLayout>
      </WhiteContainer>
    </Grid>
  );
};

const ChartUninstallDrawer: React.FC<HelmChartUninstallDrawerProps> = ({
  chart,
  open,
  onClose,
}) => {
  return (
    <StyledDrawer
      open={open}
      width={"60%"}
      onOverlayClick={onClose}
      zIndex={Number(Z_INDEX_ABOVE_MODAL)}
    >
      <ChartUninstallDrawerContent chart={chart} onClose={onClose} />
    </StyledDrawer>
  );
};

export const ChartUninstallModalForSummary: React.FC<
  HelmChartRollbackModalSummaryProps
> = ({ chartSummary, open, agentId, onClose }) => {
  const { data: chart, isFetching } = useHelmChartSummaryToChart(
    chartSummary,
    agentId
  );
  return (
    <StyledModal
      isOpen={open}
      width={"60%"}
      onRequestClose={onClose}
      height={"90%"}
    >
      {isFetching ? (
        <LoaderContainer>
          <LinesLoader marginTop="0" />
        </LoaderContainer>
      ) : chart !== undefined ? (
        <ChartUninstallDrawerContent
          chart={chart}
          onClose={onClose}
          showErrors={true}
        />
      ) : (
        "couldn't load chart"
      )}
    </StyledModal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ChartUninstallDrawer;
