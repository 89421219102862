import { useCallback } from "react";

import { createMarketplaceActivationUrl } from "../../utils/marketplace/createActivationUrl";

import { MarketplaceActivationState } from "./localState";

export const useActivateMarketplaceAccount = () => {
  return useCallback(
    async (
      activationState: MarketplaceActivationState,
      accessToken: string
    ) => {
      return await fetch(createMarketplaceActivationUrl(activationState), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    []
  );
};
