const isElectronContext = (): boolean => {
  return !!window.electronAPI;
};

export const isDesktopAuth = (): boolean => {
  return !!window.electronAPI?.isDesktopAuth;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default isElectronContext;
