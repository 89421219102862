import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Shell24, ChevronUp24 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

import { usePodExecSessionsContext } from "../../shared/context/PodExecSessionsProvider";

import { Row, FlexContainer, MiddleText } from "./MinimizedRowSharedComponents";
import { MinimizedShellText } from "./MinimizedShellText";

const NotificationNumber = styled.span`
  color: ${palette.gray["900"]};
  background-color: white;
  border-radius: 6px;
  padding: 2px 4px;
  margin-inline-start: 0.5rem;
`;

const OpenCloseIcon = styled(ChevronUp24)<{ $shouldRotate?: boolean }>`
  transform: ${({ $shouldRotate }) =>
    $shouldRotate ? "rotate(-180deg)" : "none"};
`;

const FlexAndAlign = styled(FlexContainer)`
  align-items: center;
  margin-right: 0.5rem;
`;

interface ShellRowProps {
  sessionId: string;
  onClick: () => void;
  numOfOpenSessions: number;
  isOpen: boolean;
}

export const MinimizedShellRowHeader: React.FC<ShellRowProps> = ({
  sessionId,
  onClick,
  numOfOpenSessions,
  isOpen,
}) => {
  const { getSessionProps } = usePodExecSessionsContext();
  const { podName, containerName } = getSessionProps(sessionId);

  const handleClick = () => {
    onClick();
  };

  const getOpenCloseIconAriaLabel = () => {
    if (isOpen && numOfOpenSessions > 1) {
      return "hide pod exec minimized containers";
    }

    return numOfOpenSessions === 1
      ? "maximize window pod exec session"
      : "show pod exec minimized containers";
  };

  return (
    <Row
      aria-label={
        numOfOpenSessions > 1
          ? "open minimized exec sessions menu"
          : `open ${containerName} exec session`
      }
    >
      <FlexAndAlign>
        <Shell24 color="white" />
        {numOfOpenSessions > 1 ? (
          <>
            <MiddleText>Running Shells</MiddleText>
            <NotificationNumber>
              <Typography bold size="medium">
                {numOfOpenSessions}
              </Typography>
            </NotificationNumber>
          </>
        ) : (
          <MinimizedShellText podName={podName} containerName={containerName} />
        )}
      </FlexAndAlign>
      <FlexContainer>
        <OpenCloseIcon
          $shouldRotate={isOpen && numOfOpenSessions > 1}
          color="white"
          onClick={handleClick}
          role="button"
          aria-label={getOpenCloseIconAriaLabel()}
        />
      </FlexContainer>
    </Row>
  );
};
