/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { AccountPlan, ServicesSortStrategy } from "komodor-types";

import KubernetesInstallationModal from "../../integrations/installation/kubernetes/KubernetesInstallationModal";
import Select from "../../common/controls/Select";
import { H2 } from "../../common/typography";
import { LinesLoader } from "../../common/loaders/Line";
import useUserMetadata, {
  useIsTrialEnded,
} from "../../../shared/hooks/useUserMetadata/useUserMetadata";
import FilterBar from "../../../shared/components/FilterBar/FilterBar";
import { useIsAgentConnected } from "../../../shared/hooks/useIsAgentConnected";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import {
  EXPLORE_SERVICES_FILTER_TERM_PARAM_KEY,
  EXPLORE_SERVICES_SORT_PARAM_KEY,
  FILTERS_BAR_FILTERS_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { TrialEndedModal } from "../../trialEndedModal/TrialEndedModal";
import useFreemiumModals, {
  useFreemiumModalsUpdate,
  freemiumModalNames,
} from "../../../shared/hooks/useFreemiumModals";
import useTiersVisible from "../../Freemium/useTiersVisible";
import IntersectionDetector from "../../common/IntersectionDetector";
import ServiceTile from "../ServiceTile";
import Selected from "../../../shared/components/FilterBar/Interfaces/Selected";
import { useInterval } from "../../common/useInterval";
import {
  Container,
  Content,
  GroupTitle,
  ServicesGrid,
  ServicesHeaderArea,
  ServicesSortGrid,
  Sidebar,
  StyledInput,
} from "../ExploreKomodorServices";
import { ServicesLoader } from "../ServicesLoader";
import { useDDRumViewAndTimingListener } from "../../../shared/hooks/datadog-rum/datadogRumHooks";
import { AgentNotInstalledState } from "../AgentNotInstalledState";
import { KomodorServicesEmptyState } from "../KomodorServicesEmptyState";
import { KomodorServiceType } from "../types";
import { K8S_INTEGRATION_MODAL_INITIAL_OPEN } from "../../../constants/index";
import { dataDogViewNames } from "../../../shared/constants/datadog";

import useServicesFilters from "./useServicesFilters";
import useServicesByPage from "./useServicesByPage";
import { asStringArrays } from "./utils";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const servicesStrategyNames = Object.values(ServicesSortStrategy);

const PaginatedExploreServices: React.FC<{
  setShowLinearLoader: (show: boolean) => void;
}> = ({ setShowLinearLoader }) => {
  const { accountDisabled, accountPlan } = useUserMetadata();
  const [longLoadingState, setLongLoadingState] = useState(false);
  const [debouncedLongLoadingState] = useDebounce(
    longLoadingState,
    longLoadingState ? 1000 : 0
  );
  const [filterTerm, setFilterTerm] = useStateInSearchParams(
    EXPLORE_SERVICES_FILTER_TERM_PARAM_KEY
  );
  const { callback: debouncedSetFilterTerm } = useDebouncedCallback(
    setFilterTerm,
    600
  );
  const [sortStrategy, setSortStrategy] = useStateInSearchParams(
    EXPLORE_SERVICES_SORT_PARAM_KEY
  );

  const [selectedFilters, setSelectedFilters] =
    useStringifiedStateInSearchParams<Selected>(FILTERS_BAR_FILTERS_PARAM_KEY);
  const checkedFilters = useMemo(
    () => (selectedFilters ? asStringArrays(selectedFilters) : {}),
    [selectedFilters]
  );

  const {
    categories,
    servicesLength,
    fetching: isFetchingFilters,
    updateFilters,
  } = useServicesFilters();

  const [page, setPage] = useState(1);
  const {
    services: servicesByPage,
    fetching: isFetchingServices,
    updateServices,
  } = useServicesByPage();

  const isIntervalUpdate = useRef(false);
  useEffect(() => {
    updateFilters(checkedFilters, filterTerm);
  }, [checkedFilters, filterTerm, updateFilters]);
  useEffect(() => {
    isIntervalUpdate.current = false;
    updateServices(
      checkedFilters,
      filterTerm,
      sortStrategy as ServicesSortStrategy,
      page
    );
  }, [checkedFilters, page, sortStrategy, filterTerm, updateServices]);
  useInterval(() => {
    if (!isFetchingServices && !isFetchingFilters) {
      isIntervalUpdate.current = true;
      updateFilters(checkedFilters, filterTerm);
      updateServices(
        checkedFilters,
        filterTerm,
        sortStrategy as ServicesSortStrategy,
        page
      );
    }
  }, 15000);

  const isLoadingAnUpdate = useMemo(
    () =>
      !!servicesByPage &&
      !isIntervalUpdate.current &&
      (isFetchingServices || isFetchingFilters),
    [isFetchingFilters, isFetchingServices, servicesByPage]
  );
  useEffect(() => {
    setShowLinearLoader(isLoadingAnUpdate);
    setLongLoadingState(isLoadingAnUpdate);
  }, [isLoadingAnUpdate, setShowLinearLoader]);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const namespaceCount = useMemo(() => {
    const count = new Map<string, number>();
    servicesByPage?.forEach((s) => {
      count.set(s.env, (count.get(s.env) ?? 0) + 1);
    });
    return count;
  }, [servicesByPage]);

  let currentEnv = "";

  const isAgentConnected = useIsAgentConnected();
  const isAgentNotInstalledState = useMemo(() => {
    return !servicesByPage?.length && isAgentConnected === false;
  }, [servicesByPage, isAgentConnected]);
  const [isInstallationModalOpen, setInstallationModalOpen] = useState(false);
  const { canManageIntegrations } = useHasPermissions();
  const isTrialEnded = useIsTrialEnded();
  useEffect(() => {
    if (
      isAgentNotInstalledState &&
      sessionStorage.getItem(K8S_INTEGRATION_MODAL_INITIAL_OPEN) === "false" &&
      !isTrialEnded &&
      canManageIntegrations
    ) {
      setInstallationModalOpen(true);
    }
  }, [canManageIntegrations, isAgentNotInstalledState, isTrialEnded]);
  const handleModalClose = () => {
    sessionStorage.setItem(K8S_INTEGRATION_MODAL_INITIAL_OPEN, "true");
    setInstallationModalOpen(false);
  };

  const tiersVisible = useTiersVisible();
  const updateOpenFreemiumModal = useFreemiumModalsUpdate();
  const currentFreemiumModal = useFreemiumModals();
  const isTrialEndedAndActiveAccount = isTrialEnded && !accountDisabled;
  useEffect(() => {
    if (!tiersVisible) return;
    if (!isTrialEndedAndActiveAccount) return;
    if (accountPlan !== AccountPlan.trial) return;
    if (currentFreemiumModal) return;
    updateOpenFreemiumModal &&
      updateOpenFreemiumModal(freemiumModalNames.TRIAL_ENDED);
  }, [
    currentFreemiumModal,
    tiersVisible,
    isTrialEndedAndActiveAccount,
    updateOpenFreemiumModal,
    accountPlan,
  ]);
  const getOldTrialEndModal = () => {
    if (!isTrialEndedAndActiveAccount) return null;
    if (tiersVisible) return null;
    if (accountPlan !== AccountPlan.trial) return null;

    return <TrialEndedModal />;
  };

  const isFetching = isFetchingServices || isFetchingFilters || !servicesByPage;
  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching && !isIntervalUpdate.current,
    viewName: dataDogViewNames.paginatedExploreServices,
    addTimingParams: {
      enable: !isFetching,
    },
  });

  return (
    <Container>
      {getOldTrialEndModal()}
      <Content>
        <Sidebar>
          <FilterBar
            title={"Filter services"}
            categories={categories ?? []}
            isLoading={debouncedLongLoadingState}
            inputFilter={
              <StyledInput
                id="servicesTextFilter"
                placeholder="Search name"
                defaultValue={filterTerm ?? ""}
                data-e2e-selector={"exploreServiceSearchBox"}
                onChange={(c) => debouncedSetFilterTerm(c.target.value)}
                ref={searchInputRef}
              />
            }
          />
        </Sidebar>
        {isAgentNotInstalledState && canManageIntegrations ? (
          <AgentNotInstalledState
            komodorServiceType={KomodorServiceType.service}
            setOpen={setInstallationModalOpen}
          />
        ) : (
          <>
            <ServicesHeaderArea>
              <H2 data-e2e-selector="services-title">
                Services {servicesLength ? `(${servicesLength})` : <>&nbsp;</>}
              </H2>
              <ServicesSortGrid>
                <label htmlFor="servicesSort">Sort by</label>
                <Select
                  id="servicesSort"
                  onChange={(e) => {
                    setSortStrategy(e.target.value);
                  }}
                  value={sortStrategy ? sortStrategy : servicesStrategyNames[0]}
                >
                  {servicesStrategyNames.map((strategy) => (
                    <option key={strategy} value={strategy}>
                      {strategy}
                    </option>
                  ))}
                </Select>
              </ServicesSortGrid>
            </ServicesHeaderArea>
            {!servicesByPage || debouncedLongLoadingState ? (
              <ServicesLoader />
            ) : !servicesByPage.length ? (
              <KomodorServicesEmptyState
                komodorServiceType={KomodorServiceType.service}
                isFiltering={!!filterTerm || !!selectedFilters}
                onResetFilters={() => {
                  setSelectedFilters(null);
                  setFilterTerm(null);
                  if (searchInputRef.current) {
                    searchInputRef.current.value = "";
                  }
                }}
              />
            ) : (
              <div>
                <ServicesGrid tileWidth={"16rem"}>
                  {servicesByPage.map((service) => {
                    if (sortStrategy === ServicesSortStrategy.Namespace) {
                      if (currentEnv !== service.env) {
                        currentEnv = service.env;
                        return [
                          <GroupTitle key={service.env}>
                            <H2>
                              namespace: {service.env} (
                              {namespaceCount.get(service.env)})
                            </H2>
                          </GroupTitle>,
                          <ServiceTile
                            key={service.id}
                            service={service}
                            filterTerm={
                              !isLoadingAnUpdate ? filterTerm ?? "" : ""
                            }
                          />,
                        ];
                      }
                    }
                    return (
                      <ServiceTile
                        key={service.id}
                        service={service}
                        filterTerm={!isLoadingAnUpdate ? filterTerm ?? "" : ""}
                      />
                    );
                  })}
                </ServicesGrid>
                {servicesByPage.length < servicesLength ? (
                  isFetchingServices ? (
                    <LinesLoader />
                  ) : (
                    <IntersectionDetector
                      onShow={() => setPage((prev) => prev + 1)}
                    />
                  )
                ) : null}
              </div>
            )}
          </>
        )}
      </Content>
      <KubernetesInstallationModal
        closeModalCallback={handleModalClose}
        isOpen={isInstallationModalOpen}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default PaginatedExploreServices;
