import styled from "styled-components";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";

export const PaddedCard = styled(Card)`
  && {
    padding: 1rem;
  }
`;

export const LabelsFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  width: 100px;
  margin-right: 8px;
  & > span:nth-child(2) {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  & > span:nth-child(3) {
    background-color: ${({ theme }) => theme.palette.grey[400]};
  }
`;

export const StyledPage = styled.div`
  & > div:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSizingGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(485px, 1fr));
  grid-gap: 1em;
`;

export const StyledPaddedCard = styled(Card)`
  padding: 24px 16px;
  min-width: 200px;
`;

export const RightSizingPaddedCard = styled(StyledPaddedCard)`
  padding: 16px;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
export const GraphTitleContainer = styled(FlexContainer)`
  gap: 0.25rem;
`;

export const DataGridContainer = styled.div`
  width: 100%;
  && {
    .MuiDataGrid-overlayWrapper {
      height: 200px;
      width: 100%;
      overflow: hidden;
    }
  }
`;
