import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
  display: flex;
  & > * {
    margin: 8px;
  }
`;

export const LoadingActions: React.FC = () => {
  return (
    <Container>
      <Skeleton variant="rounded" width={20} height={20} />
      <Skeleton variant="rounded" width={20} height={20} />
    </Container>
  );
};
