import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";
import { palette } from "@komodorio/design-system";
import {
  ActionTypes,
  TaskType,
  ActionMetadataCordonUncordon,
} from "komodor-types";
import { useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import styled from "styled-components";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { extractPathFromLocation } from "../../common/utils";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { ErrorMessage } from "../../common/ErrorMessage";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";

interface CordonUncordonModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  nodeName: string;
  cluster: string;
  agentId: string;
  isUncordon: boolean;
  afterEffect?: () => void;
}

const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.15rem 0.5rem;
  justify-content: start;
  color: ${palette.gray[800]};
  > :nth-child(2n + 2) {
  }
`;

const CordonUncordonModal: React.FC<CordonUncordonModalProps> = ({
  isModalOpen,
  handleClose,
  nodeName,
  cluster,
  agentId,
  isUncordon,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location = useLocation();

  const actionType = isUncordon ? ActionTypes.Uncordon : ActionTypes.Cordon;
  const actionTypeCap = capitalize(actionType);

  const metadata: ActionMetadataCordonUncordon = {
    cluster: cluster,
    type: actionType,
    resourceType: "node",
    resourceName: nodeName,
  };

  const serviceDetails = [
    {
      title: "Node name:",
      data: nodeName,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  const { execute, result, deniedObject } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );
  const analytics = useAnalyticsApi();

  const executeAndReport = () => {
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: actionType,
        path: extractPathFromLocation(location),
        resourceType: "node",
      },
      true
    );
  };

  useEffect(() => {
    if (result && !deniedObject) {
      afterEffect?.();
      handleClose();
    }
  }, [afterEffect, deniedObject, handleClose, result]);

  useEffect(() => {
    if (deniedObject) {
      setActionSent(false);
      setShowErrorMessage(true);
    }
  }, [deniedObject]);

  return (
    <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">{actionTypeCap} Node</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          This action will <b>{actionType}</b> the following node:
          <br />
          <br />
          <ModalGrid>
            {serviceDetails.map((e, i) => (
              <React.Fragment key={i}>
                <Typography size="medium" color={palette.gray[600]}>
                  {e.title}
                </Typography>
                <Typography size="medium" color={palette.gray[800]}>
                  {e.data}
                </Typography>
              </React.Fragment>
            ))}
          </ModalGrid>
        </Typography>
        {showErrorMessage && deniedObject !== undefined && (
          <ErrorMessage reason={deniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          disabled={actionSent}
          onClick={() => {
            executeAndReport();
            setActionSent(true);
          }}
        >
          {actionTypeCap}
          {actionSent ? "..." : ""}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CordonUncordonModal;
