import { ExportToCsv } from "export-to-csv-fix-source-map";

export const generateCsv = (
  data: Record<string, unknown>[],
  filename: string
): void => {
  // Add space before any column that starts with =, +, -, @ to prevent Excel from interpreting it as a formula
  data = data.map((item) => {
    for (const key in item) {
      const value = item[key];
      if (typeof value === "string" && /^[=+\-@]/.test(value)) {
        item[key] = " " + value;
      }
    }
    return item;
  });

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Komodor audit",
    filename: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
};
