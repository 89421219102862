import React from "react";

import { StyledPage } from "../styles/CostOptimizationStyledComponents";

import { OverviewCards } from "./OverviewCards/OverviewCards";
import { useFetchAllocationDataOnFiltersChange } from "./hooks/allocationHooks";
import { AllocationGraph } from "./AllocationGraph/AllocationGraph";
import { AllocationTable } from "./AllocationTable/AllocationTable";

export const AllocationPage: React.FC = () => {
  useFetchAllocationDataOnFiltersChange();

  return (
    <StyledPage>
      <OverviewCards />
      <AllocationGraph />
      <AllocationTable />
    </StyledPage>
  );
};
