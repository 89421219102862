import styled, { css } from "styled-components";

export const StepContent = styled.div`
  display: flex;
  gap: 1.25rem;
  height: 100%;
  flex-direction: column;
`;

export const PreInstallError = styled.p`
  white-space: pre-line;
`;

export const WelcomePadding = css`
  padding: 0 4rem;
`;
