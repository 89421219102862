import { get, union } from "lodash";

import {
  BaseNamesExtractor,
  extractNamesFromContainers,
  extractNamesFromVolumes,
} from "./BaseNamesExtractor";

export class SecretNamesExtractor extends BaseNamesExtractor {
  resourceType = "secret";
  volumeName = "secretName";

  extractNamesFromService(spec: Record<string, unknown> | undefined): string[] {
    const volumes = get(spec, "spec.template.spec.volumes") as
      | Record<string, unknown>[]
      | undefined;
    const containers = get(spec, "spec.template.spec.containers") as
      | Record<string, unknown>[]
      | undefined;

    return union(
      extractNamesFromContainers(containers, this),
      extractNamesFromVolumes(volumes, this)
    );
  }
}
