import React, { useEffect, useState } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Node from "../../../../../../ResourceView/resources/node";
import { useNodesMetrics } from "../../../../../../Metrics/useNodeMetrics";
import { METRICS_NODES_CPU_ENDPOINT } from "../../../../../../../shared/hooks/metrics-api/requestResponseMaps";
import { useTransformMetricsData } from "../../../../MetricsTab/hooks/metricsTabHooks";
import {
  NodeMetricsTabContextProvider,
  useNodeMetricsTabContext,
} from "../../../context/NodeMetricsTabContext";
import { getCommonGraphContainerWithStatusProps } from "../../../../MetricsTab/utils";
import { timelineTicksNum } from "../../../../MetricsTab/constants";
import { GraphContainerWithStatus } from "../../../../../../Metrics/GraphContainerWithStatus";
import { ResourceGraph } from "../../../../../../Metrics/graphs/ResourceGraph";
import { MetricsRequestParams } from "../../types";
import { useResourceUid } from "../../../../../../../shared/hooks/resources/useResourceUid";
import { EnlargedMetricsGraph } from "../../../../../../Metrics/graphs/EnlargedMetricsGraph";
import { ConditionalNoLimitLabel } from "../../../../../../Metrics/NoLimitLabel";
import { MetricsTimeSelector } from "../../MetricsPreferences/MetricsTimeSelector";
import { GraphProps } from "../../MetricsPreferences/types";
import { useNodeMetricsWithZoom } from "../../hooks/useMetricsWithZoom";

/** TODO: NodeCpuGraph and NodeMemoryGraph are very similar, consider refactoring them into a single component */
export const NodesCpuGraph: React.FC<{
  node: Node;
  requestParams: MetricsRequestParams;
  id?: string;
  graphProps?: GraphProps;
}> = ({ node, requestParams, id, graphProps }) => {
  const [isCpuGraphEnlarged, setIsCpuGraphEnlarged] = useState(false);
  const { cpuData, res, timeWindow, uid } = useNodesCpuMetrics(
    node,
    requestParams
  );

  return (
    <>
      {isCpuGraphEnlarged && (
        <NodeMetricsTabContextProvider givenTimeWindow={timeWindow}>
          <EnlargedCpuGraph
            node={node}
            requestParams={requestParams}
            onClose={() => setIsCpuGraphEnlarged(false)}
          />
        </NodeMetricsTabContextProvider>
      )}
      <GraphContainerWithStatus
        {...getCommonGraphContainerWithStatusProps(res)}
        uid={uid}
        title={"Cpu Usage"}
        info={<ConditionalNoLimitLabel data={res.metrics} />}
        onEnlargeGraph={() => setIsCpuGraphEnlarged(true)}
      >
        <ResourceGraph
          type={"cpu"}
          data={cpuData}
          timelineTicksNum={timelineTicksNum}
          graphHeight={"15rem"}
          syncId={id}
          {...graphProps}
        />
      </GraphContainerWithStatus>
    </>
  );
};

export const EnlargedCpuGraph = ({
  node,
  requestParams,
  onClose,
}: {
  node: Node;
  requestParams: MetricsRequestParams;
  onClose: () => void;
}) => {
  const { fromEpoch, toEpoch, toEpochMs, graphProps } =
    useNodeMetricsWithZoom();

  const { res, uid, cpuData } = useNodesCpuMetrics(node, {
    ...requestParams,
    fromEpoch,
    toEpoch,
    toEpochMs,
  });

  return (
    <EnlargedMetricsGraph
      title="Cpu Usage"
      controls={[<MetricsTimeSelector />]}
      onClose={onClose}
      uid={uid}
      hasGraphData={cpuData.length > 0}
      loading={res.loading}
      error={res.error}
      info={<ConditionalNoLimitLabel data={res.metrics} />}
    >
      <ResourceGraph
        type={"cpu"}
        data={cpuData}
        timelineTicksNum={timelineTicksNum}
        {...graphProps}
      />
    </EnlargedMetricsGraph>
  );
};

const useNodesCpuMetrics = (
  node: Node,
  requestParams: MetricsRequestParams
) => {
  const { timeWindow, setGraphLoadingState } = useNodeMetricsTabContext();

  const uid = useResourceUid(node);

  const res = useNodesMetrics({
    ...requestParams,
    endpoint: METRICS_NODES_CPU_ENDPOINT,
  });

  const cpuData = useTransformMetricsData(res.metrics, timeWindow.timeframe);

  useEffect(() => {
    setGraphLoadingState("cpu", res.loading);
  }, [res.loading, setGraphLoadingState]);

  return {
    uid,
    timeWindow,
    res,
    cpuData,
  };
};
