export const columns = [
  {
    name: "name",
    clickable: false,
  },
  {
    name: "namespace",
    clickable: false,
  },
  {
    name: "status",
    clickable: false,
  },
  {
    name: "age",
    clickable: false,
  },
  {
    name: "controlledBy",
    clickable: false,
  },
  {
    name: "node",
    clickable: false,
  },
];
