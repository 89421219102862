import { differenceInSeconds } from "date-fns";
import React, { createContext, useCallback, useMemo, useContext } from "react";

import { notifyDDError } from "../hooks/exceptionManagement";
import {
  BasicSocketPayload,
  generateSocketPayload,
} from "../utils/generateSocketPayload";

interface AddSocketArgs {
  id: string;
  path: { host: string; path: string };
  accessToken: string | null;
  pingIntervalTime?: number;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum SocketState {
  Zombie = "zombie",
  Alive = "alive",
  Died = "died",
}

interface SocketContext {
  sessionId?: string;
  pingIntervalId?: NodeJS.Timeout;
  checkPongIntervalId?: NodeJS.Timeout;
  lastKeepAliveMessageId?: string;
  lastKeepAliveAck?: Date;
  keepAliveState?: SocketState;
  lastPingMessageId?: string;
  lastPongAck?: Date;
  retryCount?: number;
}

export interface SocketsManager {
  getSocket: (id: string) => WebSocket | undefined;
  removeSocket: (id: string, withContextDeletion?: boolean) => void;
  addSocket: (args: AddSocketArgs) => WebSocket | undefined;
  addContextToSocket: (id: string, ctx: SocketContext) => void;
  getSocketContext: (id: string) => SocketContext | undefined;
  removeSocketContext: (id: string) => void;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const wsContext = createContext<SocketsManager>({
  addSocket: () => ({} as WebSocket),
  removeSocket: () => undefined,
  getSocket: () => undefined,
  addContextToSocket: () => undefined,
  getSocketContext: () => undefined,
  removeSocketContext: () => undefined,
});

const map = new Map<string, WebSocket>();
const wsContextMap = new Map<string, SocketContext>();
const PING_INTERVAL = 10;
const PING_INTERVAL_TIME = 1000 * PING_INTERVAL;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum WebSocketCloseCode {
  ClientSideTermination = 3000, // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close#parameters
  BackendServiceRestart = 3001,
  AgentTermination = 3002,
}

export const WebSocketsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const getSocket = useCallback((id: string): WebSocket | undefined => {
    return map.get(id);
  }, []);

  const addContextToSocket = useCallback((id: string, ctx: SocketContext) => {
    if (!map.has(id)) {
      return;
    }

    const existingContext = wsContextMap.get(id);

    wsContextMap.set(id, {
      ...(existingContext || {}),
      ...ctx,
    });
  }, []);

  const removeSocket = useCallback(
    (id: string, withContextDeletion = true): void => {
      const connection = map.get(id);
      const { pingIntervalId, checkPongIntervalId } =
        wsContextMap.get(id) || {};

      if (withContextDeletion) {
        wsContextMap.delete(id);
      } else {
        addContextToSocket(id, {
          lastPingMessageId: undefined,
          lastPongAck: undefined,
        });
      }

      map.delete(id);

      if (pingIntervalId) {
        clearTimeout(pingIntervalId);
      }

      if (checkPongIntervalId) {
        clearTimeout(checkPongIntervalId);
      }

      if (connection && connection.readyState !== connection.CLOSED) {
        connection.close(WebSocketCloseCode.ClientSideTermination);
      }
    },
    [addContextToSocket]
  );

  const getSocketContext = useCallback(
    (id: string) => wsContextMap.get(id),
    []
  );

  const removeSocketContext = useCallback((id: string) => {
    wsContextMap.delete(id);
  }, []);

  const handlePingPongTimeout = useCallback(
    (id: string, pingIntervalTime = PING_INTERVAL, timeoutThreshold = 5) => {
      const checkPongIntervalId = setInterval(() => {
        const { lastPongAck } = getSocketContext(id) || {};
        const activeSocket = getSocket(id);
        const differenceInSecondsRes = differenceInSeconds(
          Date.now(),
          lastPongAck || Date.now()
        );

        if (
          differenceInSecondsRes >
          pingIntervalTime / 1000 + timeoutThreshold // the time it takes to send a ping + threshold
        ) {
          activeSocket?.close(WebSocketCloseCode.BackendServiceRestart);
        }
      }, pingIntervalTime);

      addContextToSocket(id, { checkPongIntervalId });
    },
    [addContextToSocket, getSocket, getSocketContext]
  );

  const startPingPong = useCallback(
    (id: string, pingIntervalTime = PING_INTERVAL_TIME) => {
      const pingIntervalId = setInterval(() => {
        const connection = getSocket(id);
        const { sessionId } = getSocketContext(id) || {};
        try {
          if (!sessionId) return;

          const payload = generateSocketPayload({
            messageType: "ping",
            sessionId,
          });
          connection?.send(JSON.stringify(payload));

          addContextToSocket(id, { lastPingMessageId: payload.messageId });
        } catch (error) {
          notifyDDError({
            name: "pod exec - startPingPong",
            message: (error as Error).message,
          });
        }
      }, pingIntervalTime);

      addContextToSocket(id, { pingIntervalId });

      const connection = getSocket(id);

      connection?.addEventListener("message", (event) => {
        const { lastPingMessageId } = getSocketContext(id) || {};
        const parsedMessage: BasicSocketPayload = JSON.parse(event.data);
        if (
          parsedMessage.messageType &&
          parsedMessage.messageType === "ack" &&
          parsedMessage.data?.ackedMessageID === lastPingMessageId
        ) {
          addContextToSocket(id, { lastPongAck: new Date() });
        }
      });

      handlePingPongTimeout(id, pingIntervalTime, 5);
    },
    [addContextToSocket, getSocket, getSocketContext, handlePingPongTimeout]
  );

  const addSocket = useCallback(
    ({ id, path, pingIntervalTime }: AddSocketArgs): WebSocket | undefined => {
      if (map.has(id)) {
        return undefined;
      }

      try {
        const newConnection = new WebSocket(path.host + path.path);

        map.set(id, newConnection);

        startPingPong(id, pingIntervalTime);
        return newConnection;
      } catch (error) {
        notifyDDError({
          name: "pod_exec_addSocket",
          message: (error as Error).message || "Failed to add socket",
        });
      }

      return undefined;
    },
    [startPingPong]
  );

  const context: SocketsManager = useMemo(
    () => ({
      getSocket,
      addSocket,
      removeSocket,
      addContextToSocket,
      getSocketContext,
      removeSocketContext,
    }),
    [
      getSocket,
      addSocket,
      removeSocket,
      addContextToSocket,
      getSocketContext,
      removeSocketContext,
    ]
  );

  return <wsContext.Provider value={context}>{children}</wsContext.Provider>;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useWebSocketContext = (): SocketsManager => {
  return useContext(wsContext);
};
