import React from "react";
import styled from "styled-components";

import { LinesLoader } from "./Line";

const StyledLoader = styled(LinesLoader)`
  margin-right: auto;
  margin-left: auto;
  width: 100px;
`;

export const MarginlessLineLoader: React.FC = () => {
  return <StyledLoader marginTop={"0"} />;
};
