import React, { ReactNode } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import { StyledPaper } from "../appView/sections/AppView/components/OverviewPage/shared/styles";
import {
  InsightCardSkeleton,
  SkeletonFormation,
} from "../appView/sections/AppView/components/OverviewPage/Insights/shared/components/InsightCardSkeleton/InsightCardSkeleton";
import { IconProps } from "../appView/sections/AppView/components/OverviewPage/types/overviewPageTypes";

export const InsightContainer = styled(StyledPaper)<{
  selected: boolean;
  $loading: boolean;
}>`
  && {
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 255px;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    pointer-events: ${({ $loading }) => ($loading ? "none" : "auto")};
    transition: border-color 0.2s linear;

    border: ${({ selected }) =>
      `1px solid ${selected ? muiColors.indigo[500] : muiColors.gray[200]}`};
    & > div:first-child {
      flex-grow: 1;
    }
  }
`;

export const StyledArrowIcon = styled(ArrowForwardIos)`
  && {
    height: 10px;
    width: 10px;
    color: ${muiColors.gray[500]};
  }
`;

export const InsightCardTitle = styled.div`
  display: flex;
  align-items: center;
  height: 13px;
  margin-bottom: 10px;
  gap: 6px;
`;

export const StyledIconContainer = styled.div`
  color: ${muiColors.gray[400]};
  width: 16px;
  height: 16px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledInsightCardTitle = styled(Typography)`
  text-transform: uppercase;
  height: 15px;
`;

export const DynamicContentContainer = styled.div`
  margin-top: 14px;
`;

interface InsightsCardProps {
  title: string;
  loading: boolean;
  icon: React.FC<IconProps>;
  description: string;
  selected: boolean;
  onClick?: () => void;
  children?: ReactNode;
  ariaLabel?: string;
  loadingSkeletonFormation?: SkeletonFormation;
}

const InsightsCard: React.FC<InsightsCardProps> = ({
  title,
  loading,
  icon,
  description,
  selected,
  onClick,
  children,
  ariaLabel,
  loadingSkeletonFormation,
}) => {
  const ariaLabelWithLoading = !loading
    ? `insight card for ${ariaLabel}`
    : `loading content for ${ariaLabel}`;

  const Icon = icon;
  const content = (
    <>
      <div>
        <InsightCardTitle>
          <StyledIconContainer>
            <Icon />
          </StyledIconContainer>
          <StyledInsightCardTitle
            variant={"overline2"}
            color={muiColors.gray[500]}
          >
            {title}
          </StyledInsightCardTitle>
        </InsightCardTitle>
        <Typography variant={"h5"}>{description}</Typography>
        {children && (
          <DynamicContentContainer>{children}</DynamicContentContainer>
        )}
      </div>

      <StyledArrowIcon />
    </>
  );

  const loadingContent = (
    <InsightCardSkeleton
      formation={loadingSkeletonFormation ?? "twoDescriptionLinesAndGraph"}
    />
  );
  return (
    <InsightContainer
      onClick={onClick}
      selected={selected}
      $loading={false}
      {...(ariaLabel && { "aria-label": ariaLabelWithLoading })}
    >
      {loading ? loadingContent : content}
    </InsightContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default InsightsCard;
