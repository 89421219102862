import {
  apiV1PodContainerNameMemoryTypeGetUrl,
  apiV1PodContainerNameCpuTypeGetUrl,
  MetricsResponse,
  MetricsApiApiV1PodContainerNameCpuTypeGetRequest,
  MetricsApiApiV1PodContainerNameMemoryTypeGetRequest,
  MetricsApiApiV1PodMemoryTypeGetRequest,
  MetricsApiApiV1PodCpuTypeGetRequest,
  apiV1PodMemoryTypeGetUrl,
  apiV1PodCpuTypeGetUrl,
  apiV1CostAllocationSummaryGetUrl,
  apiV1PodTopConsumingContainerNameKindTypeGetUrl,
  CostAllocationSummaryResponse,
  CostApiApiV1CostAllocationSummaryGetRequest,
  apiV1CostAllocationOverTimeGetUrl,
  CostApiApiV1CostAllocationOverTimeGetRequest,
  AllocationCostDataPoints,
  RightSizingCostSummaryResponse,
  apiV1CostRightSizingSummaryGetUrl,
  apiV1CostRightSizingOverTimeGetUrl,
  CostApiApiV1CostRightSizingOverTimeGetRequest,
  CostApiApiV1CostRightSizingSummaryGetRequest,
  RightSizingCostDataPoints,
  apiV1CostRightSizingSummaryAggregatedGetUrl,
  CostApiApiV1CostRightSizingSummaryAggregatedGetRequest,
  ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest,
  RightSizingCostSummaryByServiceResponse,
  TopPodsResponse,
  apiV1NodeMemoryTypeGetUrl,
  apiV1NodeCpuTypeGetUrl,
  MetricsApiApiV1NodeMemoryTypeGetRequest,
  MetricsApiApiV1NodeCpuTypeGetRequest,
  CostApiGetCostOptimizationTrendsRequest,
  getCostOptimizationTrendsUrl,
  CostOptimizationScoreTrendResponse,
  apiV1CostEarliestRowsTimeGetUrl,
  EarliestRowsTimeResponse,
  CostApiApiV1CostEarliestRowsTimeGetRequest,
  getThrottledAppContainersUrl,
  ThrottledAppContainersResponse,
  AvailabilityApiGetThrottledAppContainersRequest,
  getNodePodsCountUrl,
  MetricsApiGetNodePodsCountRequest,
  apiV1FullMetricsPodContainerNameMemoryGetUrl,
  apiV1FullMetricsPodContainerNameCpuGetUrl,
  FullContainerMetricsDataPoints,
  MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest,
  MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest,
  WorkloadMetricsDataPoints,
  getThrottledContainersPercentageUrl,
  ThrottledContainerAggregation,
  AvailabilityApiGetThrottledContainersPercentageRequest,
  getWorkloadContainersMetricsUrl,
  WorkloadMetricsContainers,
  MetricsApiGetWorkloadContainersMetricsRequest,
  CostAllocationStatsResponse,
  apiV1CostAllocationStatsGetUrl,
  CostApiApiV1CostAllocationStatsGetRequest,
  apiV1CostRightSizingStatsGetUrl,
  RightSizingCostStatsResponse,
  CostApiApiV1CostRightSizingStatsGetRequest,
  getWorkloadContainersMetricsSummaryUrl,
  WorkloadMetricsContainersSummary,
  MetricsApiGetWorkloadContainersMetricsSummaryRequest,
  apiV1CostRightSizingRowsGetUrl,
  RightSizingCostRowsResponse,
  CostApiApiV1CostRightSizingRowsGetRequest,
} from "../../../generated/metricsApi";

export const METRICS_CONTAINER_MEMORY_ENDPOINT = "/pod/:containerName/memory/";
export const METRICS_CONTAINER_CPU_ENDPOINT = "/pod/:containerName/cpu/";
export const METRICS_FULL_CONTAINER_MEMORY_ENDPOINT =
  "/fullMetrics/pod/:containerName/memory";
export const METRICS_FULL_CONTAINER_CPU_ENDPOINT =
  "/fullMetrics/pod/:containerName/cpu";
export const METRICS_NODES_MEMORY_ENDPOINT = "/node/memory/";
export const METRICS_NODES_CPU_ENDPOINT = "/node/cpu/";
export const METRICS_NODE_PODS_COUNT_ENDPOINT = "/node/pods/count/";

export const METRICS_POD_MEMORY_ENDPOINT = "/pod/memory/";
export const METRICS_POD_CPU_ENDPOINT = "/pod/cpu/";

export const METRICS_NODE_MEMORY_ENDPOINT = "/node/memory/usage";
export const METRICS_NODE_CPU_ENDPOINT = "/node/cpu/usage";

export const METRICS_WORKLOAD_CONTAINERS_ENDPOINT =
  "/workload/containers/:kind";

export const METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT =
  "/workload/containers/:kind/summary";

export const COST_ALLOCATION_SUMMARY_ENDPOINT = "/cost/allocation/summary";
export const COST_ALLOCATION_METRICS_ENDPOINT = "/cost/allocation/over-time";
export const COST_RIGHT_SIZING_SUMMARY_ENDPOINT = "/cost/right-sizing/summary";
export const COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT =
  "/cost/right-sizing/summary/aggregated";
export const COST_RIGHT_SIZING_METRICS_ENDPOINT =
  "/cost/right-sizing/over-time";
export const COST_RIGHT_SIZING_STATS_ENDPOINT = "/cost/right-sizing/stats";
export const POD_NAMES_ENDPOINT = "/pod/top/:containerName/:kind/:type";
export const COST_OPTIMIZATION_TRENDS_ENDPOINT =
  "/cost/trends/optimization-score";

export const COST_EARLIEST_ROWS_TIME_ENDPOINT = "/cost/earliest-rows-time";
export const THROTTLED_APPLICATIONS_ENDPOINT =
  "/availability/trends/throttled-containers";
export const THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT =
  "/availability/trends/throttled-container/aggregation";
export const COST_ALLOCATION_STATS_ENDPOINT = "/cost/allocation/stats";
export const COST_RIGHT_SIZING_ROWS_ENDPOINT = "/cost/right-sizing/rows";

export const EndpointRequestPathMap = {
  [METRICS_CONTAINER_MEMORY_ENDPOINT]: apiV1PodContainerNameMemoryTypeGetUrl,
  [METRICS_CONTAINER_CPU_ENDPOINT]: apiV1PodContainerNameCpuTypeGetUrl,
  [METRICS_NODES_MEMORY_ENDPOINT]: apiV1NodeMemoryTypeGetUrl,
  [METRICS_NODES_CPU_ENDPOINT]: apiV1NodeCpuTypeGetUrl,
  [METRICS_NODE_PODS_COUNT_ENDPOINT]: getNodePodsCountUrl,
  [METRICS_POD_MEMORY_ENDPOINT]: apiV1PodMemoryTypeGetUrl,
  [METRICS_POD_CPU_ENDPOINT]: apiV1PodCpuTypeGetUrl,
  [METRICS_NODE_MEMORY_ENDPOINT]: apiV1NodeMemoryTypeGetUrl,
  [METRICS_NODE_CPU_ENDPOINT]: apiV1NodeCpuTypeGetUrl,
  [METRICS_WORKLOAD_CONTAINERS_ENDPOINT]: getWorkloadContainersMetricsUrl,
  [METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT]:
    getWorkloadContainersMetricsSummaryUrl,
  [COST_ALLOCATION_SUMMARY_ENDPOINT]: apiV1CostAllocationSummaryGetUrl,
  [COST_ALLOCATION_METRICS_ENDPOINT]: apiV1CostAllocationOverTimeGetUrl,
  [COST_RIGHT_SIZING_SUMMARY_ENDPOINT]: apiV1CostRightSizingSummaryGetUrl,
  [COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT]:
    apiV1CostRightSizingSummaryAggregatedGetUrl,
  [COST_RIGHT_SIZING_METRICS_ENDPOINT]: apiV1CostRightSizingOverTimeGetUrl,
  [COST_RIGHT_SIZING_STATS_ENDPOINT]: apiV1CostRightSizingStatsGetUrl,
  [POD_NAMES_ENDPOINT]: apiV1PodTopConsumingContainerNameKindTypeGetUrl,
  [COST_OPTIMIZATION_TRENDS_ENDPOINT]: getCostOptimizationTrendsUrl,
  [COST_EARLIEST_ROWS_TIME_ENDPOINT]: apiV1CostEarliestRowsTimeGetUrl,
  [THROTTLED_APPLICATIONS_ENDPOINT]: getThrottledAppContainersUrl,
  [THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT]:
    getThrottledContainersPercentageUrl,
  [METRICS_FULL_CONTAINER_MEMORY_ENDPOINT]:
    apiV1FullMetricsPodContainerNameMemoryGetUrl,
  [METRICS_FULL_CONTAINER_CPU_ENDPOINT]:
    apiV1FullMetricsPodContainerNameCpuGetUrl,
  [COST_ALLOCATION_STATS_ENDPOINT]: apiV1CostAllocationStatsGetUrl,
  [COST_RIGHT_SIZING_ROWS_ENDPOINT]: apiV1CostRightSizingRowsGetUrl,
};

export type EndpointsResponseType =
  | MetricsResponse
  | FullContainerMetricsDataPoints
  | CostAllocationSummaryResponse
  | WorkloadMetricsDataPoints
  | CostAllocationStatsResponse
  | RightSizingCostRowsResponse;

export type EndpointsResponseMap = {
  [METRICS_CONTAINER_MEMORY_ENDPOINT]: MetricsResponse;
  [METRICS_CONTAINER_CPU_ENDPOINT]: MetricsResponse;
  [METRICS_NODES_MEMORY_ENDPOINT]: MetricsResponse;
  [METRICS_NODES_CPU_ENDPOINT]: MetricsResponse;
  [METRICS_NODE_PODS_COUNT_ENDPOINT]: MetricsResponse;
  [METRICS_POD_MEMORY_ENDPOINT]: MetricsResponse;
  [METRICS_POD_CPU_ENDPOINT]: MetricsResponse;
  [METRICS_NODE_MEMORY_ENDPOINT]: MetricsResponse;
  [METRICS_NODE_CPU_ENDPOINT]: MetricsResponse;
  [METRICS_WORKLOAD_CONTAINERS_ENDPOINT]: WorkloadMetricsContainers;
  [METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT]: WorkloadMetricsContainersSummary;
  [COST_ALLOCATION_SUMMARY_ENDPOINT]: CostAllocationSummaryResponse;
  [COST_ALLOCATION_METRICS_ENDPOINT]: AllocationCostDataPoints;
  [COST_RIGHT_SIZING_SUMMARY_ENDPOINT]: RightSizingCostSummaryResponse;
  [COST_RIGHT_SIZING_METRICS_ENDPOINT]: RightSizingCostDataPoints;
  [COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT]: RightSizingCostSummaryByServiceResponse;
  [COST_RIGHT_SIZING_STATS_ENDPOINT]: RightSizingCostStatsResponse;
  [POD_NAMES_ENDPOINT]: TopPodsResponse;
  [COST_OPTIMIZATION_TRENDS_ENDPOINT]: CostOptimizationScoreTrendResponse;
  [COST_EARLIEST_ROWS_TIME_ENDPOINT]: EarliestRowsTimeResponse;
  [THROTTLED_APPLICATIONS_ENDPOINT]: ThrottledAppContainersResponse;
  [THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT]: ThrottledContainerAggregation;
  [METRICS_FULL_CONTAINER_MEMORY_ENDPOINT]: FullContainerMetricsDataPoints;
  [METRICS_FULL_CONTAINER_CPU_ENDPOINT]: FullContainerMetricsDataPoints;
  [COST_ALLOCATION_STATS_ENDPOINT]: CostAllocationStatsResponse;
  [COST_RIGHT_SIZING_ROWS_ENDPOINT]: RightSizingCostRowsResponse;
};

export type EndpointsRequestMap = {
  [METRICS_CONTAINER_MEMORY_ENDPOINT]: MetricsApiApiV1PodContainerNameMemoryTypeGetRequest;
  [METRICS_CONTAINER_CPU_ENDPOINT]: MetricsApiApiV1PodContainerNameCpuTypeGetRequest;
  [METRICS_NODES_MEMORY_ENDPOINT]: MetricsApiApiV1NodeMemoryTypeGetRequest;
  [METRICS_NODES_CPU_ENDPOINT]: MetricsApiApiV1NodeCpuTypeGetRequest;
  [METRICS_NODE_PODS_COUNT_ENDPOINT]: MetricsApiGetNodePodsCountRequest;
  [METRICS_POD_MEMORY_ENDPOINT]: MetricsApiApiV1PodMemoryTypeGetRequest;
  [METRICS_POD_CPU_ENDPOINT]: MetricsApiApiV1PodCpuTypeGetRequest;
  [METRICS_NODE_MEMORY_ENDPOINT]: MetricsApiApiV1NodeMemoryTypeGetRequest;
  [METRICS_NODE_CPU_ENDPOINT]: MetricsApiApiV1NodeCpuTypeGetRequest;
  [METRICS_WORKLOAD_CONTAINERS_ENDPOINT]: MetricsApiGetWorkloadContainersMetricsRequest;
  [METRICS_WORKLOAD_CONTAINERS_SUMMARY_ENDPOINT]: MetricsApiGetWorkloadContainersMetricsSummaryRequest;
  [COST_ALLOCATION_SUMMARY_ENDPOINT]: CostApiApiV1CostAllocationSummaryGetRequest;
  [COST_ALLOCATION_METRICS_ENDPOINT]: CostApiApiV1CostAllocationOverTimeGetRequest;
  [COST_RIGHT_SIZING_SUMMARY_ENDPOINT]: CostApiApiV1CostRightSizingSummaryGetRequest;
  [COST_RIGHT_SIZING_METRICS_ENDPOINT]: CostApiApiV1CostRightSizingOverTimeGetRequest;
  [COST_RIGHT_SIZING_RECOMMENDATION_ENDPOINT]: CostApiApiV1CostRightSizingSummaryAggregatedGetRequest;
  [POD_NAMES_ENDPOINT]: ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest;
  [COST_OPTIMIZATION_TRENDS_ENDPOINT]: CostApiGetCostOptimizationTrendsRequest;
  [COST_EARLIEST_ROWS_TIME_ENDPOINT]: CostApiApiV1CostEarliestRowsTimeGetRequest;
  [THROTTLED_APPLICATIONS_ENDPOINT]: AvailabilityApiGetThrottledAppContainersRequest;
  [THROTTLED_CONTAINERS_AGGREGATED_ENDPOINT]: AvailabilityApiGetThrottledContainersPercentageRequest;
  [METRICS_FULL_CONTAINER_MEMORY_ENDPOINT]: MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest;
  [METRICS_FULL_CONTAINER_CPU_ENDPOINT]: MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest;
  [COST_ALLOCATION_STATS_ENDPOINT]: CostApiApiV1CostAllocationStatsGetRequest;
  [COST_RIGHT_SIZING_STATS_ENDPOINT]: CostApiApiV1CostRightSizingStatsGetRequest;
  [COST_RIGHT_SIZING_ROWS_ENDPOINT]: CostApiApiV1CostRightSizingRowsGetRequest;
};
