import { useCallback, useMemo } from "react";

import {
  InsightsDrawerContent,
  useOverviewPageStore,
} from "../../../store/overviewPageStore";
import { selectOpenInsightsDrawer } from "../../../store/overviewPageStoreSelectors";
import { getDataPointsBreakdownPerIntervals } from "../utils/getDataPointsBreakdownPerIntervals";
import { TimeStampDataPoints } from "../types";

export const useMemoizedDataBreakdownPerInterval = (
  dataPoints?: TimeStampDataPoints
) => {
  return useMemo(() => {
    return getDataPointsBreakdownPerIntervals(dataPoints);
  }, [dataPoints]);
};

type OpenInsightsDrawerParams = InsightsDrawerContent;

export const useOpenInsightsDrawer = () => {
  const openInsightsDrawer = useOverviewPageStore(selectOpenInsightsDrawer);

  return useCallback(
    (params: OpenInsightsDrawerParams) => {
      openInsightsDrawer(params);
    },
    [openInsightsDrawer]
  );
};
