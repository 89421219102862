import React from "react";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled, { css } from "styled-components";

import { RightSizingPaddedCard } from "../../../styles/CostOptimizationStyledComponents";
import { useCalculatedValues } from "../../hooks/potentialCostComparisonHooks";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";
import { UNALLOCATED_BAR_TOOLTIP } from "../../../CostOptimizationSettings/constants";

import Lines from "./Lines.png";

interface PotentialCostComparisonProps {
  before: number | undefined;
  monthlySaving: number | undefined;
  unallocatedPrice?: number | undefined;
}

const getBarBorderRadius = (unallocatedPercent: number) =>
  unallocatedPercent > 0 ? "0px 4px 4px 0px" : "4px";

const Bar = styled.div`
  height: 21px;
  border-radius: 4px;
`;

const BarContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

const PercentageBarWrapper = styled.div<{ percent: number }>`
  flex: 0 0 ${(props) => props.percent}%;
`;

const Spacer = styled.div`
  width: 10%;
  margin-right: 5px;
`;

const MainBarWrapper = styled.div`
  width: 65%;
`;

const AfterBarWrapper = styled(MainBarWrapper)`
  display: flex;
`;

const SavingsBarWrapper = styled.div`
  width: 65%;
  display: flex;
  justify-content: flex-end;
`;

const BorderRadiusStyle = css`
  border-radius: 4px 0 0 4px;
`;

const UnallocatedBefore = styled(Bar)<{ percent: number }>`
  background-color: ${muiColors.gray[300]};
  background-image: url("${Lines}");
  opacity: 0.5;
  ${BorderRadiusStyle};
  width: ${(props) => props.percent}%;
`;

const AllocatedBefore = styled(Bar)<{
  percent: number;
  unallocatedPercent: number;
}>`
  background-color: ${muiColors.gray[300]};
  border-radius: ${(props) => getBarBorderRadius(props.unallocatedPercent)};
  width: ${(props) => props.percent}%;
`;

const SavingsContainer = styled.div<{ percent: number }>`
  width: ${(props) => props.percent}%;
  display: flex;
  flex-direction: column;
`;

const SavingsBar = styled(Bar)`
  border: 1px solid ${muiColors.gray[300]};
  border-radius: 0;
  border-bottom: none;
  width: 100%;
`;

const AfterBarUnallocated = styled(Bar)`
  background-color: ${muiColors.teal[600]};
  opacity: 0.6;
  background-image: url("${Lines}");
  width: 100%;
  ${BorderRadiusStyle};
`;

const AfterBarAllocated = styled(Bar)<{ unallocatedPercent: number }>`
  background-color: ${muiColors.teal[600]};
  border-radius: ${(props) => getBarBorderRadius(props.unallocatedPercent)};
  width: 100%;
`;

const UnallocatedBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled(Typography)`
  color: ${muiColors.gray[600]};
  padding-left: 8px;
  && {
    font-size: 12px;
  }
`;

const SavingsLabel = styled(Label)`
  align-self: center;
  white-space: nowrap;
`;

const {
  savings: savingsAriaLabel,
  beforeAmount: beforeAmountAriaLabel,
  afterAmount: afterAmountAriaLabel,
} = costOptimizationAriaLabels.rightSizingPage.summaryCards
  .potentialCostComparison;

export const PotentialCostComparisonBars: React.FC<
  PotentialCostComparisonProps
> = ({ before = 0, monthlySaving = 0, unallocatedPrice = 0 }) => {
  const {
    afterSavings,
    beforeSavings,
    savingsAsPercent,
    savingsPercent,
    allocatedBeforePercent,
    unallocatedBeforePercent,
    allocatedAfterPercent,
  } = useCalculatedValues(before, monthlySaving, unallocatedPrice);

  return (
    <RightSizingPaddedCard>
      <Typography variant={"h5"}>Potential Cost Comparison</Typography>
      <BarContainer aria-label={savingsAriaLabel}>
        <Spacer />
        {savingsPercent > 0 && (
          <SavingsBarWrapper>
            <SavingsContainer percent={savingsPercent}>
              <SavingsLabel>{savingsAsPercent}</SavingsLabel>
              <SavingsBar />
            </SavingsContainer>
          </SavingsBarWrapper>
        )}
        <Spacer />
      </BarContainer>
      <BarContainer aria-label={beforeAmountAriaLabel}>
        <Spacer>
          <Label color={muiColors.gray["800"]}>Before</Label>
        </Spacer>
        <MainBarWrapper>
          <UnallocatedBarWrapper>
            {unallocatedBeforePercent > 0 && (
              <Tooltip title={UNALLOCATED_BAR_TOOLTIP} placement="top">
                <UnallocatedBefore percent={unallocatedBeforePercent} />
              </Tooltip>
            )}
            <AllocatedBefore
              unallocatedPercent={unallocatedBeforePercent}
              percent={allocatedBeforePercent}
            />
          </UnallocatedBarWrapper>
        </MainBarWrapper>
        <Spacer>
          <Label>{beforeSavings}</Label>
        </Spacer>
      </BarContainer>
      <BarContainer aria-label={afterAmountAriaLabel}>
        <Spacer>
          <Label color={muiColors.gray["800"]}>After</Label>
        </Spacer>
        <AfterBarWrapper>
          {unallocatedBeforePercent > 0 && (
            <Tooltip title={UNALLOCATED_BAR_TOOLTIP} placement="bottom">
              <PercentageBarWrapper percent={unallocatedBeforePercent}>
                <AfterBarUnallocated />
              </PercentageBarWrapper>
            </Tooltip>
          )}
          <PercentageBarWrapper percent={allocatedAfterPercent}>
            <AfterBarAllocated unallocatedPercent={allocatedAfterPercent} />
          </PercentageBarWrapper>
        </AfterBarWrapper>
        <Spacer>
          <Label>{afterSavings}</Label>
        </Spacer>
      </BarContainer>
    </RightSizingPaddedCard>
  );
};
