import { useQuery } from "@tanstack/react-query";

import { getAppConfig } from "../../config/appConfig";
import { CommonApiResponse } from "../../types/commonApiResponse";

import {
  EndpointRequestPathMap,
  EndpointsRequestMap,
  EndpointsResponseMap,
  EndpointsResponseType,
} from "./requestResponseMaps";
import { useMetricsApiClient } from "./client/apiClient";

export type MetricsApiResponse<M extends object> = ReturnType<
  typeof useMetricsAPIGet<M>
>;

export const useMetricsAPIGet = <
  M extends { [key in keyof EndpointsResponseType]: M },
  T extends keyof EndpointsResponseMap = keyof EndpointsResponseMap
>(
  relativePath: T,
  parameters: EndpointsRequestMap[T],
  pause = false
): CommonApiResponse<M> => {
  const url = EndpointRequestPathMap[relativePath](
    parameters as never,
    getAPIBasePath()
  );
  const apiClient = useMetricsApiClient();
  const res = useQuery(
    [url, parameters],
    async () => await apiClient.get<M>(url),
    {
      enabled: !pause,
    }
  );
  return {
    loading: res.isLoading,
    error: res.error as string,
    refresh: res.refetch,
    dataRequestParams: "",
    data: res.data?.data,
  };
};

const getAPIBasePath = (): string => {
  return getAppConfig().metricsAPIServerURL ?? "";
};
